function CrossIcon({ size, color = '#6C577A' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size || '13'}
			height={size || '13'}
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M1 1L13 13"
				stroke={color}
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1 13L13 1"
				stroke={color}
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default CrossIcon;

import { Slider, Box } from '@mui/material';

const maxPossibleFlightPrice = 10000;
const minPossibleFlightPrice = 0;

function PriceFilter({
	setMaxPriceFilter = () => {},
	maxPriceFilter,
	maxPrice,
}) {
	return (
		<div>
			{!maxPrice && maxPrice === maxPriceFilter ? (
				<div className="text-sm" style={{ color: '#8ab4f8' }}>
					All Prices
				</div>
			) : (
				<div className="text-sm" style={{ color: '#307dfa' }}>
					up to $
					{maxPriceFilter === -1 ? maxPossibleFlightPrice : maxPriceFilter}
				</div>
			)}

			<Box sx={{ width: 300, padding: '0 4px' }}>
				<Slider
					defaultValue={maxPrice <= 0 ? maxPossibleFlightPrice : maxPrice}
					aria-label="Default"
					valueLabelDisplay="auto"
					min={minPossibleFlightPrice}
					max={maxPossibleFlightPrice}
					onChange={(_, value) => {
						setMaxPriceFilter(value);
					}}
				/>
			</Box>
		</div>
	);
}

export default PriceFilter;

import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import ClearIcon from '@mui/icons-material/Clear';
import { Skeleton } from '@mui/material';
import isEmpty from '@utils/isEmpty';

function MyTrips({ myTripsOpen = false }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const contentFetchedRef = useRef(false);
	const trips = useSelector((state) => state.ui_states.myTrips);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const [loading, setLoading] = useState(false);
	const [hoverIndex, setHoverIndex] = useState(-1);

	useEffect(() => {
		async function fetchTrips() {
			if (
				!contentFetchedRef.current &&
				(!trips || trips.length === 0) &&
				myTripsOpen
			) {
				contentFetchedRef.current = true;
				try {
					setLoading(true);
					const res = await axios.get('/api/get_trips_for_user');
					const { data } = res;
					dispatch({ type: 'UPDATE_MY_TRIPS', payload: data });

					setLoading(false);
				} catch (error) {
					console.error('Failed to fetch trips:', error);
					setLoading(false);
					// Optionally handle errors, e.g., updating state to show an error message
				}
			}
		}

		fetchTrips();
	}, []); // Dependencies array remains empty if effect only needs to run once on mount

	const loadTrip = (trip) => {
		sendMessageEvent({
			event: GoogleEventTypes.MY_TRIPS_TRIP_SELECTED,
			chat_session_id: chatSessionId,
		});
		navigate(`/chat/${trip.chat_session_id}`);
	};

	const archiveTrip = async (trip) => {
		try {
			setLoading(true);
			dispatch({ type: 'UPDATE_MY_TRIPS', payload: [] });
			contentFetchedRef.current = false;

			await axios.post(`/api/archive_trip_for_user`, {
				chat_session_id: trip.chat_session_id,
			});

			const res = await axios.get('/api/get_trips_for_user');
			const { data } = res;
			dispatch({ type: 'UPDATE_MY_TRIPS', payload: data });

			setLoading(false);
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<div className="flex flex-col space-y-2 p-4">
			<div className="p-2 font-medium text-lg">My Trips</div>
			{isEmpty(trips) && loading ? (
				<div className="flex flex-col gap-4">
					{[...Array(10).keys()].map((ind) => {
						return <Skeleton key={ind} variant="rounded" height={60} />;
					})}
				</div>
			) : null}

			{trips.map((trip, ix) => {
				const currentClassName =
					chatSessionId != null && trip.chat_session_id === chatSessionId
						? 'font-medium'
						: '';

				return (
					<div
						className="flex flex-row space-x-2 p-2 cursor-pointer hover:bg-gray-100 rounded-lg items-center justify-between"
						key={`myTrips: ${trip.chat_session_id}`}
						onClick={() => loadTrip(trip)}
						role="presentation"
						onMouseEnter={() => { setHoverIndex(ix); }}
						onMouseLeave={() => { setHoverIndex(-1); }}
					>
						<div className="flex flex-row space-x-2 items-center">
							{trip.image && (
								<img
									src={trip.image.url}
									alt={trip.image.title}
									className="object-cover max-w-none rounded-sm w-8 h-8"
									referrerPolicy="no-referrer"
								/>
							)}
							<div className={`flex ${currentClassName}`}>
								{trip.tripTitle || 'Untitled Trip'}
							</div>
						</div>

						{hoverIndex == ix
							? <div
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();

									archiveTrip(trip);
								}}
								role="presentation"
								className="hover:scale-110"
							>
								<ClearIcon style={{ color: '#7750e5' }} />
							</div>
							: null
						}
					</div>
				);
			})}
		</div>
	);
}

export default MyTrips;

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TransitionOverviewWidget from '../TransitionOverview/TransitionOverviewWidget';
import FlightsSection from './FlightsSection';
import HotelsSection from './HotelsSection';
import { useSelector } from 'react-redux';
import { getTotalPriceObject } from '@helpers/hotelsQueryHelper';

function BookNowComponent({ setShowBookNowScreen = () => [] }) {
	
	const { activeTabIndex = 0, selectedFlightByDateOption, selectedHotels } = useSelector(({ ui_states = {} }) => ({
		activeTabIndex: ui_states.active_tab_index,
		selectedFlightByDateOption: ui_states.selectedFlightByDateOption,
		selectedHotels: ui_states.selectedHotels,
	}));
	const { tripLocationDetails } = useSelector(
		({ tripLocationDetails: tripLocations = {} }) => ({
			tripLocationDetails: tripLocations,
		}),
	);

	const { numTravellers } = tripLocationDetails;
	const filteredSelectedHotels = selectedHotels.filter(
		(hotelData) => !!Object.keys(hotelData || {}).length,
	);
	const totalHotelPriceObject = getTotalPriceObject(filteredSelectedHotels);
	const totalHotelPrice = totalHotelPriceObject.price;

	const flightPricePerPerson =
		(selectedFlightByDateOption[activeTabIndex] ?? {})?.totalPrice ?? 0;

	return (
		<div className="bg-white">
			<TransitionOverviewWidget view="book_now" />

			<div className="p-8 gap-8">
				<div
					className="flex justify-between items-center pb-4"
					style={{ borderBottom: '1px solid #E1DEE3' }}
				>
					<div
						className="flex gap-1 items-center cursor-pointer"
						role="presentation"
						onClick={() => setShowBookNowScreen(false)}
					>
						<ArrowBackIosIcon fontSize="small" />

						<div
							style={{ color: '#301345', fontSize: '20px', fontWeight: 600 }}
						>
							Book trip
						</div>
					</div>

					<div className="flex gap-2 items-center font-semibold text-lg">
						<div style={{ color: '#301345' }}>Entire trip price :</div><div
							className="flex gap-2 items-end"
							style={{ color: '#7750E5', fontWeight: '500' }}
						>
							<div> {`$${parseInt(totalHotelPrice / numTravellers + flightPricePerPerson)}`} </div>
							<div className="text-sm pb-[3px]">/person</div>
						</div>
					</div>
				</div>

				<FlightsSection />

				<HotelsSection />
			</div>
		</div>
	);
}

export default BookNowComponent;

import useGetAuthorizationChecked from '../hooks/useGetAuthorizationChecked';
import styles from './styles.module.css';

function SessionCheck({ children }) {
	const isLoginRequired = process.env.REACT_APP_LOGIN_REQUIRED === 'true';

	if (!isLoginRequired) {
		return children;
	}

	const { sessionInitialized } = useGetAuthorizationChecked();

	if (!sessionInitialized) {
		return (
			<div className={styles.container}>
				<iframe
					title="loading"
					src="https://lottie.host/embed/62506393-cbfc-46ef-92d4-fa6d75c1f263/9NW0xlIQGn.lottie"
					width={200}
					height={200}
				/>
			</div>
		);
	}
	return children;
}

export default SessionCheck;

function getNextOpeningTime(openingHours) {
	const now = new Date();
	const currentDayIndex = now.getDay();
	const currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes

	// Find the current day's opening hours
	const todayOpeningHours = openingHours[currentDayIndex];

	// Check if the establishment is open today
	if (todayOpeningHours && todayOpeningHours.open !== 'Closed') {
		const [openHours, openMinutes] = todayOpeningHours.open
			.split(':')
			.map(Number);
		const openingTime = openHours * 60 + openMinutes; // Convert opening time to minutes

		// If the current time is before the opening time, return the opening time
		if (currentTime < openingTime) {
			return todayOpeningHours.open;
		}
	}

	// Find the next opening time for the following days
	for (let i = 1; i <= 7; i += 1) {
		const nextDayIndex = (currentDayIndex + i) % 7;
		const nextDay = openingHours[nextDayIndex];
		if (nextDay && nextDay.open !== 'Closed') {
			return nextDay.open;
		}
	}

	return false; // If no opening hours are found
}

export default getNextOpeningTime;

import InstagramComponent from '@commons/Inspiration/InstagramComponent';

function InspirationAttachment({ attachmentData }) {
	return (
		<div
			className="flex flex-wrap justify-start w-full max-w-full"
			style={{ marginLeft: '-8px' }}
		>
			{attachmentData.map((attachment) => {
				const { url = '', scrape_results = '' } = attachment || {};

				return (
					<div className="w-full m-2" style={{ maxWidth: '384px' }}>
						<InstagramComponent
							url={url}
							scrape_results={scrape_results}
							isExpandable={false}
						/>
					</div>
				);
			})}
		</div>
	);
}

export default InspirationAttachment;

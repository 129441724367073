import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import TextInputController from '@commons/forms/page-components/controlled/TextInputController';
import EditIcon from '@commons/icons/EditIcon';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import useHandleLoginForm from './useHandleLoginForm';

const MARKETING_EMAIL = 'marketing@coinvent.ai';

function LoginForm({ setShowLoginLayover = () => {} }) {
	const navigate = useNavigate();
	const {
		controls,
		onSubmitForm,
		errors,
		control,
		loading = false,
		handleSubmit,
		currentStage = '',
		setCurrentStage = () => {},
		watch = () => {},
		setErrorMessage = () => {},
		errorMessage = '',
		showResetPasswordMessage,
		setShowResetPasswordMessage,
	} = useHandleLoginForm({ setShowLoginLayover });

	const subHeading =
		currentStage === 'email' ? (
			'Enter your email to login'
		) : (
			<div className="flex items-center gap-1 text-sm">
				Enter your password for{' '}
				<div
					style={{ color: '#7750E5' }}
					role="presentation"
					className="flex gap-1 items-center cursor-pointer"
					onClick={() => {
						setCurrentStage('email');
						setErrorMessage('');
					}}
				>
					{watch('email')}
					<EditIcon width="16" height="16" />
				</div>
			</div>
		);

	const handleFormSubmit = async (event) => {
		event.preventDefault();

		if (currentStage === 'email') {
			setCurrentStage('password');
		} else {
			handleSubmit(onSubmitForm)();
		}
	};

	const handleKeyDown = async (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();

			if (currentStage === 'email') {
				setCurrentStage('password');
			} else {
				handleSubmit(onSubmitForm)();
			}
		}
	};

	const buttonTitle = currentStage === 'email' ? 'Login with Email' : 'Submit';

	return (
		<form className={styles.form}>
			<div className={styles.title}>Login to AiRial</div>

			<div className={styles.sub_heading}>{subHeading}</div>

			{controls.map((controlItems) => {
				const { icon: ActiveIcon, show, ...restControls } = controlItems;

				if (!show) {
					return null;
				}

				return (
					<div
						className="flex flex-col gap-2"
						style={{ width: '360px' }}
						key={restControls.name}
					>
						<TextInputController
							{...restControls}
							control={control}
							error={errors[restControls.name]}
							onKeyDown={handleKeyDown}
							size="sm"
						/>
					</div>
				);
			})}

			<div
				className="text-sm cursor-pointer my-1"
				style={{
					color: '#7E6DF1',
					width: '360px',
				}}
				role="presentation"
				onClick={() => setShowResetPasswordMessage((prev) => !prev)}
			>
				{showResetPasswordMessage ? (
					<div>
						Please contact our team at{' '}
						<a
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}
							style={{ color: '#DF74B1' }}
							href={`mailto:${MARKETING_EMAIL}`}
						>
							{MARKETING_EMAIL}
						</a>
						<div className="mt-2">
							We are currently working on automating the process. Thank you for
							your patience.
						</div>
					</div>
				) : (
					'Forgot password?'
				)}
			</div>

			<Button
				variant="contained"
				className={styles.submit_button}
				type="button"
				onClick={handleFormSubmit}
				disabled={loading}
				style={{ marginTop: '4px' }}
			>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<CircularProgress size={20} color="inherit" />
						<span style={{ marginLeft: '8px' }}>Loading...</span>
					</Box>
				) : (
					<div>{buttonTitle}</div>
				)}
			</Button>

			{errorMessage ? (
				<div
					className="flex flex-col gap-2 items-center mb-2"
					style={{ width: '360px' }}
				>
					<Alert severity="error">{errorMessage}</Alert>
				</div>
			) : null}

			<div className={styles.privacy_policy}>
				By Logging In, you agree to our{' '}
				<div className={styles.bold_text}>Terms of Service</div> and{' '}
				<div className={styles.bold_text} style={{ margin: 'auto' }}>
					Privacy Policy
				</div>
			</div>

			<div
				className="flex gap-3 items-center mt-8"
				style={{ fontSize: '15px' }}
			>
				<div style={{ color: '#442A57', fontWeight: '400' }}>
					Create Account?
				</div>

				<div
					style={{ color: '#7750E5' }}
					className="cursor-pointer font-semibold"
					role="presentation"
					onClick={() => navigate('signup')}
				>
					Sign Up
				</div>
			</div>
		</form>
	);
}

export default LoginForm;

import Axios from 'axios';

const request = Axios.create({
	// baseURL: process.env.REACT_APP_BACKEND_URL,
	timeout: 30000, // Timeout in milliseconds
	withCredentials: true,
	// headers: {
	// 	'Content-Type': 'application/json',
	// },
});

request.interceptors.request.use(
	(config) => {
		// we can modify the request config here after the login setup
		// config.headers.Authorization = `Bearer ${yourAuthToken}`;

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export { request };

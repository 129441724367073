import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '@helpers/Enums';
import isEmpty from '@utils/isEmpty';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import {
	getFlightQueryFromTripTransitions,
	handleFindFlights,
} from '@helpers/FlightsQueryHelper';
import { useLocation } from 'react-router-dom';
import ItineraryCardComponent from '../ItineraryCardComponent/ItineraryCardComponent';

function ItineraryListComponent({ itineraries, flightsLoading }) {
	const dispatch = useDispatch();
	const location = useLocation();

	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);

	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);

	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const { travelSets = [] } = tripTransitionsDetails;

	const handleShowTripControls = () => {
		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_TRIP_CONTROLS_FROM_FLIGHTS_SECTION,
			chat_session_id: chatSessionId,
		});
		dispatch({
			type: 'UPDATE_SHOW_TRIP_CONTROLS',
			payload: true,
		});
	};

	const handleRetry = () => {
		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_REFRESH_FLIGHTS,
			chat_session_id: chatSessionId,
		});

		const flightQuery = getFlightQueryFromTripTransitions(
			tripTransitionsDetails,
			tripLocationDetails.numTravellers,
		);
		handleFindFlights({
			flightQuery,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery: {
				locations: {
					startLocation: tripLocationDetails.startLocation,
					endLocation: tripLocationDetails.endLocation,
					places: tripLocationDetails.places,
				},
				travelSets,
				chat_history: llmInteractionHistory,
			},
			shouldUpdateFlightsAndDayPlans: true,
			tripData: {
				NUM_TRAVELLERS: tripLocationDetails.numTravellers,
				hotelFilters: tripLocationDetails.hotelFilters,
				travelSets,
				LOCATIONS: tripLocationDetails.places,
				HOTEL_REQUIREMENT: tripLocationDetails.hotelReqs,
				hotelSortCriterion,
			},
			sortOption: flightSortOption,
			gMapsData,
			curActiveTabIndex: activeTabIndex,
		});
	};

	const loadFlights = flightsLoading === LoadingState.LOADED && !itineraries;

	if (loadFlights) {
		return (
			<div className="mt-4">
				The flights are not loaded for this date option. Apologies for the
				inconvenience. Please Click{' '}
				{
					<span
						className="text-primary-600 cursor-pointer"
						onClick={handleRetry}
						role="presentation"
					>
						here
					</span>
				}{' '}
				to retry. If that does not work, please add airports or change the modes
				of transport from the{' '}
				{
					<span
						className="text-primary-600 cursor-pointer"
						onClick={handleShowTripControls}
						role="presentation"
					>
						Edit Trip
					</span>
				}{' '}
				page.
			</div>
		);
	}

	if (isEmpty(itineraries)) {
		return (
			<div className="text-center py-4 bg-neutrals-card_background rounded-lg shadow-lg">
				No flights matching the search criteria were found.
			</div>
		);
	}

	return (
		<div className="space-y-4 text-[13px]">
			{itineraries.map((itinerary, i) => (
				<ItineraryCardComponent
					key={i}
					itinerary={itinerary}
					itineraryIndex={i}
				/>
			))}
		</div>
	);
}

export default ItineraryListComponent;

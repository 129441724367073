import { useEffect, useState, useMemo } from 'react';
import Button from '@mui/material/Button';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '@utils/isEmpty';
import { getContentUsingId } from '@utils/InspirationUtils';
import { fetchScrapeBatch } from '@helpers/discoverInspirationHelper';
import CardsLoadingComponent from '../../components/SearchResultsV1/OverallResults/CardsLoadingComponent';
import SocialMediaPlayableThumbnailComponent from './SocialMediaPlayableThumbnailComponent';

export const LoadingState = {
	LOADING_URL: 'LOADING_URL',
	LOADING_PLACES: 'LOADING_PLACES',
	LOADED: 'LOADED',
};

export default function InspirationThumbnailHorizontalSlider({
	tabIndex,
	contentUrls = [],
	onContinue,
	isCurrentViewLandingPage = false,
	initialTabData = {},
}) {
	const dispatch = useDispatch();
	const discoverInspirationScrapeResults =
		useSelector((state) => state.ui_states.discoverInspirationScrapeResults) ||
		{};
	const selectedInspirationData =
		useSelector((state) => state.ui_states.selectedInspirationData) || {};
	const selectedInspirationDataLoadingState =
		useSelector(
			(state) => state.ui_states.selectedInspirationDataLoadingState,
		) || {};

	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const initialSelectedStates = useMemo(() => {
		return contentUrls.reduce((acc, id) => {
			acc[id] = false;
			return acc;
		}, {});
	}, [tabIndex, contentUrls]);
	const [selected, setSelected] = useState(initialSelectedStates);
	const [expandedContentUrl, setExpandedContentUrl] = useState(null);

	useEffect(() => {
		setSelected(initialSelectedStates);
	}, [tabIndex]);

	const processedLinks = new Set();

	const scrapeResults = isCurrentViewLandingPage
		? initialTabData?.[tabIndex]?.content || {}
		: discoverInspirationScrapeResults;

	const getData = async () => {
		const urlsToFetch = contentUrls
			.map((contentUrl) => {
				if (
					isEmpty(discoverInspirationScrapeResults[contentUrl]) &&
					!processedLinks[contentUrl]
				) {
					processedLinks.add(contentUrl);
					return contentUrl;
				}
				return null;
			})
			.filter((contentUrl) => contentUrl !== null);

		try {
			fetchScrapeBatch(urlsToFetch, dispatch);
		} catch (error) {
			// console.error('Failed to fetch content:', error);
		}
	};

	useEffect(() => {
		if (!isCurrentViewLandingPage) {
			getData();
		}
	}, []);

	const handleResponse = (contentId, response) => {
		if (response.status === 'success') {
			if (response.payload?.url) {
				dispatch({
					type: 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE',
					payload: {
						link: contentId,
						data: LoadingState.LOADING_PLACES,
					},
				});
			}

			dispatch({
				type: 'UPDATE_SELECTED_INSPIRATION_DATA',
				payload: {
					link: contentId,
					data: response.payload,
				},
			});
		}
	};

	const onFinished = (contentId) => {
		if (contentId != null) {
			dispatch({
				type: 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE',
				payload: {
					link: contentId,
					data: LoadingState.LOADED,
				},
			});
		}
	};

	const toggleSelected = async (contentUrl) => {
		setSelected((prevSelected) => {
			const newSelected = { ...prevSelected };
			newSelected[contentUrl] = !newSelected[contentUrl];
			return newSelected;
		});

		maybePrefetch(contentUrl);
	};

	const maybePrefetch = (contentUrl) => {
		const contentId = scrapeResults[contentUrl]?.payload?.uuid;

		if (
			!selectedInspirationData[contentId] &&
			!selectedInspirationDataLoadingState[contentId]
		) {
			try {
				dispatch({
					type: 'UPDATE_SELECTED_INSPIRATION_DATA',
					payload: {
						link: contentId,
						data: {},
					},
				});

				dispatch({
					type: 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE',
					payload: {
						link: contentId,
						data: LoadingState.LOADING_URL,
					},
				});

				getContentUsingId(
					contentId,
					(response) => handleResponse(contentId, response),
					() => onFinished(contentId),
				);
			} catch (error) {
				console.log('error', error);
			}
		}
	};

	const clearSelected = () => {
		setSelected(initialSelectedStates);
	};

	const getSelectedContentIds = () => {
		return contentUrls
			.filter((contentUrl) => selected[contentUrl])
			.map((url) => scrapeResults[url]?.payload?.uuid)
			.filter((uuid) => !!uuid);
	};

	const handlePlayClick = (event, contentUrl) => {
		event.stopPropagation();
		setExpandedContentUrl(contentUrl);
	};

	const hasSelected = Object.values(selected).some((isSelected) => isSelected);

	return (
		<div className="flex flex-col space-y-4">
			<div className="flex flex-row space-x-4 overflow-auto">
				{contentUrls.map((contentUrl) => {
					const contentData = scrapeResults[contentUrl];

					const isSelected = selected[contentUrl];
					const displayUrl =
						contentData?.payload?.scrape_results?.azure_display_url ||
						contentData?.payload?.scrape_results?.display_url;

					if (!displayUrl) {
						return (
							<div className="flex-none">
								<CardsLoadingComponent width="180px" height="320px" />
							</div>
						);
					}

					return (
						<SocialMediaPlayableThumbnailComponent
							url={contentUrl}
							scrape_results={contentData.payload.scrape_results}
							isSelected={isSelected}
							isExpanded={contentUrl === expandedContentUrl}
							toggleSelected={() => toggleSelected(contentUrl)}
							onExpand={() => {
								setExpandedContentUrl(contentUrl);
								maybePrefetch(contentUrl);
							}}
							onCollapse={() => setExpandedContentUrl(null)}
							key={`SOCIALMEDIA: ${contentUrl}`}
						/>
					);
				})}
			</div>
			<div className="flex flex-row space-x-2">
				<div className="mb-4">
					<Button
						variant="text"
						type="button"
						style={{
							textTransform: 'unset',
						}}
						onClick={clearSelected}
					>
						Clear Selection
					</Button>
					<Button
						variant="contained"
						type="button"
						className={hasSelected ? 'gradient-button ' : 'disabled-button'}
						style={{
							textTransform: 'unset',
							width: '200px',
						}}
						onClick={() => {
							sendMessageEvent({
								event: GoogleEventTypes.DISCOVER_INSPIRATION_SELECTED,
								chat_session_id: chatSessionId,
							});
							onContinue(getSelectedContentIds());
						}}
						disabled={!hasSelected}
					>
						Continue
					</Button>
				</div>
			</div>
		</div>
	);
}

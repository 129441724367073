import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import InspirationPanel from '@commons/Inspiration/InspirationPanel';
import { maybeAddInspirationAfterDrawerClose } from '@helpers/ChatUtils';
import MyTrips from '@commons/Trips/MyTrips';
import LandingPage from '../components/LandingPageV2/LandingPage';
import FirstPage from '../components/FirstPageV1/SearchResults';

const COMPONENT_MAPPING = {
	0: LandingPage,
	1: FirstPage,
};

function HomePageV1({ pageId }) {
	const {
		inspirationDrawerInfo,
		chatSessionId,
		llmInteractionHistory,
		myTripsOpen,
	} = useSelector(({ ui_states = {} }) => ({
		inspirationDrawerInfo: ui_states.inspirationDrawerInfo,
		chatSessionId: ui_states.chatSessionId,
		llmInteractionHistory: ui_states.llmInteractionHistory,
		myTripsOpen: ui_states.myTripsOpen,
	}));

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { gMapsData = {} } = useSelector(({ ui_states = {} }) => ({
		gMapsData: ui_states.gMapsData,
	}));

	const closeInspirationDrawer = (inspirationData) => {
		maybeAddInspirationAfterDrawerClose(
			inspirationData,
			dispatch,
			chatSessionId,
			llmInteractionHistory,
			inspirationDrawerInfo?.source,
			gMapsData,
			navigate,
		);
		dispatch({
			type: 'UPDATE_ADD_INSPIRATION_DRAWER',
			payload: {
				show: false,
				source: null,
			},
		});
	};

	const closeMyTrips = () => {
		dispatch({
			type: 'UPDATE_MY_TRIPS_OPEN',
			payload: false,
		});
	};
	const ActiveComponent = COMPONENT_MAPPING[pageId] || LandingPage;

	return (
		<div className="flex flex-col font-sans">
			<div className="flex-grow bg-gradient-to-br from-background_gradient-from via-from-background_gradient-via to-background_gradient-to overflow-auto">
				<ActiveComponent />
			</div>

			<Drawer
				anchor="right"
				open={inspirationDrawerInfo?.show}
				onClose={() => closeInspirationDrawer(null)}
				PaperProps={{
					sx: { width: '66%' },
				}}
			>
				<InspirationPanel
					source={inspirationDrawerInfo?.source}
					onClose={closeInspirationDrawer}
				/>
			</Drawer>
			{myTripsOpen ? (
				<Drawer
					anchor="left"
					open={myTripsOpen}
					onClose={closeMyTrips}
					PaperProps={{
						sx: { width: '24rem', left: '72px' },
					}}
					sx={{
						'& .MuiBackdrop-root': {
							backgroundColor: 'transparent',
						},
					}}
				>
					<MyTrips onClose={closeMyTrips} myTripsOpen={myTripsOpen} />
				</Drawer>
			) : null}
		</div>
	);
}

export default HomePageV1;

import React from 'react';
import BaseDropdown from './BaseDropdown.js';
import NumPassengersDropdown from './NumPassengersDropdown.js';

export const TripType = Object.freeze({
	ONE_WAY: 'One Way',
	ROUND_TRIP: 'Round trip',
	MULTI_CITY: 'Multi City',
});

export const TripClass = Object.freeze({
	ECONOMY: 'Economy',
	PREMIUM_ECONOMY: 'Premium Economy',
	BUSINESS: 'Business',
	FIRST: 'First',
});

export default function TripOptionsBar({ flightFormState, setFlightFormState, textColor }) {
	const { tripClass } = flightFormState;
	const onTripClassSelected = (key) => {
		setFlightFormState(
			(prevFlightFormState) => ({
				...prevFlightFormState,
				modified: true,
				tripClass: key,
			})
		);
	};

	return (
		<div className="flex items-center gap-3 mb-4" style={{ color: '#301345' }}>
			<div className="text-sm ml-1">Filters</div>

			<div
				style={{ border: '1px solid #CEC8D2', borderRadius: '32px' }}
				className="px-1"
			>
				<BaseDropdown
					buttonId="flight_form_trip_class_dropdown"
					dropdownText={TripClass[tripClass]}
					dropDownOptions={Object.entries(TripClass)}
					onOptionSelected={onTripClassSelected}
					textColor={textColor}
				/>
			</div>

			<NumPassengersDropdown
				flightFormState={flightFormState}
				setFlightFormState={setFlightFormState}
				textColor={textColor}
			/>
		</div>
	);
}

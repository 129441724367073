function ArrowRight() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
			<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E3DAFA" />
			<path
				d="M17.25 26.5L22.75 20L17.25 13.5"
				stroke="#7750E5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ArrowRight;

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';

export default function BaseDropdown({
	dropdownText,
	dropDownOptions,
	onOptionSelected,
	textColor,
	buttonId,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const onOptionSelectedInternal = (key) => {
		onOptionSelected(key);
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				id={buttonId}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={<ExpandMoreIcon />}
				sx={{
					color: textColor,
					textTransform: 'none',
					fontSize: 13,
				}}
			>
				{dropdownText}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'trip-type-dropdown-button',
				}}
				sx={{
					'.MuiMenu-list': {
						// Set font size for menu items
						'& .MuiMenuItem-root': {
							fontSize: '13px',
						},
					},
					'.MuiMenuItem-root': {
						alignItems: 'right',
						'& .MuiSvgIcon-root': {
							marginRight: '4px',
						},
					},
				}}
			>
				{dropDownOptions.map(([key, value]) => (
					<MenuItem
						id={`${buttonId}_option`}
						key={key}
						value={key}
						onClick={() => onOptionSelectedInternal(key)}
					>
						<div
							style={{
								visibility: value === dropdownText ? 'visible' : 'hidden',
							}}
						>
							{' '}
							<CheckIcon fontSize="small" />{' '}
						</div>
						{value}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}

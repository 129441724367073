import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem } from '@mui/material';

export const SortOptions = Object.freeze({
	BEST: 'Best',
	PRICE: 'Price',
	DURATION: 'Duration',
});

function FlightsSortSelector({ tabsData = {} }) {
	const dispatch = useDispatch();

	const { active_tab_index = 0, selectedOption } = useSelector(
		({ ui_states = {} }) => ({
			selectedOption: ui_states.sort_option,
			active_tab_index: ui_states.active_tab_index,
		}),
	);

	const handleOptionChange = (e) => {
		dispatch({ type: 'UPDATE_SORT_OPTION', payload: e.target.value });
	};

	if ((tabsData[active_tab_index]?.content || []).length < 2) {
		return null;
	}

	return (
		<div className="flex flex-row items-center space-x-2 text-sm">
			<div>Sort</div>

			<div>
				<Select
					value={selectedOption}
					onChange={handleOptionChange}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
				>
					{Object.keys(SortOptions).map((key) => (
						<MenuItem value={SortOptions[key]}>{key}</MenuItem>
					))}
				</Select>
			</div>
		</div>
	);
}

export default FlightsSortSelector;

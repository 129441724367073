function CarIcon({ width, height, stroke, applyActualWidth }) {
	return (
		<div style={{ marginTop: '1.5px' }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={applyActualWidth ? width || '25' : '18'}
				height={applyActualWidth ? height || '25' : '18'}
				viewBox="0 0 32 32"
				fill="none"
			>
				<path
					d="M8.99343 22.5884C10.3576 22.5884 11.4634 21.4826 11.4634 20.1184C11.4634 18.7543 10.3576 17.6484 8.99343 17.6484C7.62929 17.6484 6.52344 18.7543 6.52344 20.1184C6.52344 21.4826 7.62929 22.5884 8.99343 22.5884Z"
					stroke={stroke || '#311245'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M21.5247 22.5884C22.8888 22.5884 23.9947 21.4826 23.9947 20.1184C23.9947 18.7543 22.8888 17.6484 21.5247 17.6484C20.1605 17.6484 19.0547 18.7543 19.0547 20.1184C19.0547 21.4826 20.1605 22.5884 21.5247 22.5884Z"
					stroke={stroke || '#311245'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M4.55219 20.5902C4.20219 20.2902 3.99219 19.8502 3.99219 19.3902V16.0302C3.99219 15.2302 4.47219 14.5102 5.21219 14.2102C5.94219 13.9102 6.56219 13.4002 7.00219 12.7402L8.16219 10.9802C8.81219 10.0002 9.90219 9.41016 11.0822 9.41016H15.6822C16.4422 9.41016 17.1822 9.66016 17.7922 10.1202L21.4422 12.8702C21.5622 12.9602 21.6922 13.0302 21.8322 13.0802L25.7022 14.5002C27.0822 15.0102 28.0022 16.3202 28.0022 17.7902V19.3202C28.0022 20.0102 27.4422 20.5702 26.7522 20.5702H25.9622"
					stroke={stroke || '#311245'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.5391 20.5703H17.0591"
					stroke={stroke || '#311245'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.57031 14.3984H19.4403"
					stroke={stroke || '#311245'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.3906 11.4883V14.3983"
					stroke={stroke || '#311245'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default CarIcon;

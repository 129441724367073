import { convertActivityToItineraryDayCardsData } from './DayCardUtils';

export function getUpdatedAndRemovedDayCardsData(
	updatedDayPlans,
	originalDayPlans,
	gMapsData,
	inspirationPlaceNames = [],
) {
	// Function to extract place names and Google IDs along with corresponding activities into two maps

	const addToMaps = (placeName, activity, placesByName, placesByGoogleId) => {
		const googleId = gMapsData[placeName]?.[0]?.[0]?.google_id;
		activity.googleId = googleId;

		// Map place names and Google IDs to their respective activities
		if (!placesByName.has(placeName)) {
			placesByName.set(placeName, activity);
		}
		if (googleId && !placesByGoogleId.has(googleId)) {
			placesByGoogleId.set(googleId, activity);
		}
	};

	const getPlacesInfo = (dayPlans) => {
		const placesByName = new Map();
		const placesByGoogleId = new Map();

		dayPlans.flatMap((dayPlan) =>
			(dayPlan.SCHEDULE || []).forEach((activity) => {
				const placeName = activity?.PLACE_NAME;
				if (placeName) {
					addToMaps(placeName, activity, placesByName, placesByGoogleId);
				}
			}),
		);

		return { placesByName, placesByGoogleId };
	};

	const getInspirationPlacesInfo = (inspirationPlaceNames) => {
		const placesByName = new Map();
		const placesByGoogleId = new Map();
		inspirationPlaceNames.map((placeName) =>
			addToMaps(placeName, { name: placeName }, placesByName, placesByGoogleId),
		);

		return { placesByName, placesByGoogleId };
	};

	const updatedPlaces = getPlacesInfo(updatedDayPlans);
	const originalPlaces = getPlacesInfo(originalDayPlans);
	const inspirationPlaces = getInspirationPlacesInfo(inspirationPlaceNames);

	// Helper function to determine added or removed places
	const findDifferences = (updatedMaps, originalMaps) => {
		const differences = new Set();
		const updatedNames = [...updatedMaps.placesByName.keys()];

		// Check for added places by name and Google ID
		updatedNames.forEach((name) => {
			if (
				!originalMaps.placesByName.has(name) &&
				updatedMaps.placesByName.get(name).googleId &&
				!originalMaps.placesByGoogleId.has(
					updatedMaps.placesByName.get(name).googleId,
				)
			) {
				differences.add(updatedMaps.placesByName.get(name));
			}
		});

		return differences;
	};

	const findIntersection = (updatedPlaces, inspiredPlaces) => {
		const intersection = new Set();
		const updatedNames = [...updatedPlaces.placesByName.keys()];

		// Check for added places by name and Google ID
		updatedNames.forEach((name) => {
			if (
				updatedPlaces.placesByName.get(name).googleId &&
				inspiredPlaces.placesByGoogleId.has(
					updatedPlaces.placesByName.get(name).googleId,
				)
			) {
				intersection.add(updatedPlaces.placesByName.get(name));
			}
		});

		return intersection;
	};

	// Determine added and removed places
	const addedPlaces = findDifferences(updatedPlaces, originalPlaces);
	const removedPlaces = findDifferences(originalPlaces, updatedPlaces);
	const inspiredPlaces = findIntersection(updatedPlaces, inspirationPlaces);

	// Convert Sets of activities to lists of day card data
	const addedDayCardsDataList = Array.from(addedPlaces).map((activity) =>
		convertActivityToItineraryDayCardsData(activity),
	);

	const removedDayCardsDataList = Array.from(removedPlaces).map((activity) =>
		convertActivityToItineraryDayCardsData(activity),
	);

	const inspiredDayCardsDataList = Array.from(inspiredPlaces).map((activity) =>
		convertActivityToItineraryDayCardsData(activity),
	);

	return {
		addedDayCardsDataList,
		removedDayCardsDataList,
		inspiredDayCardsDataList,
	};
}

export function updateDayCardsDataListWithNewTags(
	dayCardsDataList,
	addedDayCardsDataList,
	inspiredDayCardsDataList = [],
) {
	dayCardsDataList.forEach((dayCardData) => {
		const addedDayCardData = addedDayCardsDataList.find(
			(addedData) => addedData.placeName === dayCardData.placeName,
		);
		const inspiredDayCardData = inspiredDayCardsDataList.find(
			(addedData) => addedData.placeName === dayCardData.placeName,
		);
		if (addedDayCardData) {
			dayCardData.hasNewTag = true;
		}
		if (inspiredDayCardData) {
			dayCardData.hasInspiredTag = true;
		}
	});
}

/* eslint-disable default-param-last */
const initialState = {
	_initialized: false,
	user_preferences: {},
};

export default function profileReducer(state = initialState, action) {
	switch (action.type) {
		case 'UPDATE_PROFILE_REDUCER':
			return { ...state, ...action.payload };
		case 'LOGOUT_USER':
			return action.payload;
		default:
			return state;
	}
}

function TiktokIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<rect width="24" height="24" fill="white" />
			<path
				d="M18.5175 4.33125V8.21625C18.24 8.26125 17.9475 8.29125 17.6475 8.29125C17.1 8.29125 16.5675 8.20875 16.0575 8.05125V15.7162C16.0575 18.6412 13.695 21.0037 10.77 21.0037C7.84504 21.0037 5.48254 18.6412 5.48254 15.7162C5.48254 12.7912 7.86004 10.4287 10.77 10.4287V14.1412C9.90754 14.1412 9.19504 14.8462 9.19504 15.7162C9.19504 16.5862 9.90754 17.2912 10.77 17.2912C11.6325 17.2912 12.36 16.5862 12.36 15.7162V2.99625H16.0575C16.0575 3.86625 16.77 4.58625 17.6475 4.58625C17.9775 4.58625 18.2625 4.48875 18.5175 4.33125Z"
				stroke="url(#paint0_linear_145_92)"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_145_92"
					x1="12"
					y1="2.99625"
					x2="12"
					y2="21.0037"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DC1F90" />
					<stop offset="1" stopColor="#7750E5" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default TiktokIcon;

// Mobile, Alabama Trip - obscure airport issue [May 18]

const directOverviewState = {
	ui_states: {
		pageId: 1,
		tripTitle: 'Alabama road trip in June',
		currentChatMessage: '',
		chatMessages: {
			messages: [
				{
					sender: 'airial',
					message:
						"I'm AiRial, your online travel booking assistant. Simply describe your trip and I can help you book all your flights, \n    accommodation and plan your trip conveniently. Where do you want to go?",
				},
				{
					sender: 'user',
					message:
						'I want to plan a trip to Mobile, Alabama. Also suggest 3 more places to visit in Alabama. 8-day trip starting between June 10 and June 12.',
				},
				{
					sender: 'airial',
					message:
						'Alabama has much to offer! Please answer the following questions to help me plan a diverse and exciting trip for you.',
					widget: {
						widgetType: 'preferences_widget',
						widgetData: {
							CONTENT: [
								'What activities are you interested in for your Alabama trip? :: Outdoor adventures :: Cultural experiences :: Food and dining :: Historical sites :: Other (specify)',
								'Anything else I should know?',
							],
							TYPE: 'clarification_widget',
						},
						shouldShow: false,
					},
					STAGE: 'pre_location_confirmation_widget',
				},
				{
					sender: 'user',
					message: 'Trip Preferences Submitted!',
					widget: {
						widgetType: 'preferences_widget',
						widgetData: {
							CONTENT: [
								'What activities are you interested in for your Alabama trip? :: Outdoor adventures :: Cultural experiences :: Food and dining :: Historical sites :: Other (specify)',
								'Anything else I should know?',
							],
							TYPE: 'clarification_widget',
						},
						shouldShow: true,
					},
				},
				{
					sender: 'airial',
					message:
						'Please confirm or edit these locations that I picked out for you, click on generate and watch your personalized dream trip come to life!',
					widget: {
						widgetType: 'location_widget',
						widgetData: {
							recommendedPlaces: [
								{
									source: 'adobe_stock',
									placeName: 'Mobile, Alabama',
									subtitle: 'Mobile, Alabama',
									id: '8c38839c-23b9-4875-b0be-2686a949c4ee',
									name: 'Mobile, Alabama',
									photos: [
										{
											url: 'https://t3.ftcdn.net/jpg/02/25/43/30/240_F_225433065_o72NyW9C21AN3minmq9FAnXFijXMuCiY.jpg',
											title:
												'Aerial View of Downtown Mobile, Alabama, USA Skyline',
											placeName: 'Mobile, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/01/10/12/54/240_F_110125468_EtHfMSeXvag8IdpyqDKfGmqtYFvLdcyi.jpg',
											title: 'Mobile Alabama Skyline',
											placeName: 'Mobile, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/00/36/15/10/240_F_36151063_XUqoWpSBvY1x6PrY7cuJdoYPwjxqLgfi.jpg',
											title: 'Downtown Mobile Alabama',
											placeName: 'Mobile, Alabama',
										},
									],
									reason: 'You specified this',
								},
								{
									source: 'adobe_stock',
									placeName: 'Birmingham, Alabama',
									subtitle: 'Birmingham, Alabama',
									id: '52b17870-1810-48e4-a8f6-e3d8e53f7465',
									name: 'Birmingham, Alabama',
									photos: [
										{
											url: 'https://t4.ftcdn.net/jpg/04/00/59/15/240_F_400591543_z5QzT8YTaBpFpiXuVKn4ub9gr7hTcLfS.jpg',
											title:
												'Birmingham, Alabama, USA Downtown Drone Skyline Aerial Panorama',
											placeName: 'Birmingham, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/01/67/91/79/240_F_167917994_9Ffg6HoevvQXWlnhY4NFetkuWamEUveT.jpg',
											title: 'Birmingham, Alabama, USA downtown city skyline.',
											placeName: 'Birmingham, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/03/12/65/73/240_F_312657359_owjzdAWZqswqrLyHYBXBWEZehyLlJtMy.jpg',
											title: 'Birmingham, Alabama City Skyline',
											placeName: 'Birmingham, Alabama',
										},
									],
									reason: 'Cultural experiences',
								},
								{
									source: 'adobe_stock',
									placeName: 'Huntsville, Alabama',
									subtitle: 'Huntsville, Alabama',
									id: '505f0290-8ae5-476e-b77a-c4487c00e7b3',
									name: 'Huntsville, Alabama',
									photos: [
										{
											url: 'https://t3.ftcdn.net/jpg/02/01/63/36/240_F_201633681_mmn7HXyGFrPP6fFWUyLPJ0P6uQEq2TPe.jpg',
											title: 'Huntsville, Alabama, USA Skyline',
											placeName: 'Huntsville, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/00/65/57/46/240_F_65574631_y6hHsO6245f3qvOVZortGRrdqhSdnKkc.jpg',
											title: 'Cityscape scene of downtown Huntsville, Alabama',
											placeName: 'Huntsville, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/03/07/79/67/240_F_307796709_KmybvswFGEC1sdTzVfh9HcMnZa0H82JJ.jpg',
											title:
												'Dusk Over The Downtown Urban City Center of Huntsville Alabama',
											placeName: 'Huntsville, Alabama',
										},
									],
									reason: 'Outdoor adventures',
								},
								{
									source: 'adobe_stock',
									placeName: 'Montgomery, Alabama',
									subtitle: 'Montgomery, Alabama',
									id: 'f4ae9d11-2068-4a9d-8a34-fb230a050902',
									name: 'Montgomery, Alabama',
									photos: [
										{
											url: 'https://t4.ftcdn.net/jpg/02/25/43/31/240_F_225433113_DrIar1nr4j7G5V68jjPtfOMBPUZhW0da.jpg',
											title:
												'Aerial View of Downtown Montgomery, Alabama, USA Skyline',
											placeName: 'Montgomery, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/02/97/81/92/240_F_297819214_3Y5DyBvf5Vk48pG1fXBFkjUkNulUuedo.jpg',
											title:
												'Montgomery, Alabama, USA with the State Capitol at dawn.',
											placeName: 'Montgomery, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/02/96/80/54/240_F_296805441_MXpPpQyDsUHY30sxSGKI6JNF8oEiiYuv.jpg',
											title:
												'Dexter Avenue leads to the classic statehouse in downtown Montgomery Alabama',
											placeName: 'Montgomery, Alabama',
										},
									],
									reason: 'Historical sites',
								},
							],
							alternativePlaces: [
								{
									source: 'adobe_stock',
									placeName: 'Gulf Shores, Alabama',
									subtitle: 'Gulf Shores, Alabama',
									id: '1f512f6b-b583-46aa-845f-cbc4676b28ed',
									name: 'Gulf Shores, Alabama',
									photos: [
										{
											url: 'https://t4.ftcdn.net/jpg/06/91/17/29/240_F_691172927_cNRixEe7gvyt6ilwTllRkzekHSy37O3G.jpg',
											title:
												'A Beautiful White Sand Beach on the Coastline at Golden Hour',
											placeName: 'Gulf Shores, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/05/25/98/06/240_F_525980619_3kEHVJwLVwPckUMcaMm9UMi2fCesF8sq.jpg',
											title: 'Orange Beach, Alabama in July',
											placeName: 'Gulf Shores, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/04/53/22/83/240_F_453228333_mmJP8lw3FM9FxdK2gKUkWjFnSQt7Cevo.jpg',
											title:
												'Large Great Blue Heron on the Beach in Gulf Shores, Alabama',
											placeName: 'Gulf Shores, Alabama',
										},
									],
									reason: 'Beach activities',
								},
								{
									source: 'adobe_stock',
									placeName: 'Tuscaloosa, Alabama',
									subtitle: 'Tuscaloosa, Alabama',
									id: '698fa9d5-ec41-4060-b6d0-e3c01d857870',
									name: 'Tuscaloosa, Alabama',
									photos: [
										{
											url: 'https://t3.ftcdn.net/jpg/05/85/57/60/240_F_585576009_xmxJxetaBGSiyj2myla4L92KmUis1FMs.jpg',
											title: 'Aerial View of Tuscaloosa, Alabama',
											placeName: 'Tuscaloosa, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/04/26/34/25/240_F_426342534_LzFL4Awwkl6GKDrbReNZ220jaZ0lcsno.jpg',
											title:
												'Mulberry Fork is a tributary of the Black Warrior River in Alabama',
											placeName: 'Tuscaloosa, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/07/92/78/75/240_F_792787504_400iHZL7o89wR3WP4SCmfFRuSnJ7R9ME.jpg',
											title:
												'Tuscaloosa Alabama - May 6 2018 An Imax movie theater',
											placeName: 'Tuscaloosa, Alabama',
										},
									],
									reason: 'College town with cultural attractions',
								},
								{
									source: 'adobe_stock',
									placeName: 'Florence, Alabama',
									subtitle: 'Florence, Alabama',
									id: 'e81504e6-dce8-4934-b034-fb2b6ef4cb86',
									name: 'Florence, Alabama',
									photos: [
										{
											url: 'https://t3.ftcdn.net/jpg/02/33/67/72/240_F_233677299_aq58vs5FgLfuj0kBjnFDXTKCMbtLNp3n.jpg',
											title:
												'Wilson Park Fountain Downtown Florence Alabama Spring Sunny with Two Concrete Benches on either Side',
											placeName: 'Florence, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/04/09/54/10/240_F_409541060_hdhlsIgPjl1eR1ZZinkRJAMMgCRtvCSS.jpg',
											title:
												'Sunset over the bridge on the Tennessee River in Florence',
											placeName: 'Florence, Alabama',
										},
										{
											url: 'https://t4.ftcdn.net/jpg/01/70/15/49/240_F_170154982_bggGMm0wLA4nntJXI2HljFmBAaywe0W5.jpg',
											title:
												'Tennessee river at sunset from bank of park in Florence Alabama.',
											placeName: 'Florence, Alabama',
										},
									],
									reason: 'Arts and music scene',
								},
								{
									source: 'adobe_stock',
									placeName: 'Decatur, Alabama',
									subtitle: 'Decatur, Alabama',
									id: '99d55199-a730-4aee-810d-2663e83efb48',
									name: 'Decatur, Alabama',
									photos: [
										{
											url: 'https://t3.ftcdn.net/jpg/05/05/47/58/240_F_505475871_2zNhTFHMJZpHXmQi8xqqx3q3YEXOtzey.jpg',
											title:
												'Beautiful shot of a Steamboat Bill Bridge across the Tennessee River in Decatur, Alabama',
											placeName: 'Decatur, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/00/71/15/80/240_F_71158066_5qn5oeQ14Y8U3Rt15UsJRxWkzB7BpdEk.jpg',
											title: 'Pond in Alabama and wooden foot bridge',
											placeName: 'Decatur, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/00/03/49/68/240_F_3496833_zPnoTceIScHR6c8012QR6TdHwxc812.jpg',
											title: 'group of hot air balloons',
											placeName: 'Decatur, Alabama',
										},
									],
									reason: 'Outdoor recreational activities',
								},
								{
									source: 'adobe_stock',
									placeName: 'Fairhope, Alabama',
									subtitle: 'Fairhope, Alabama',
									id: 'bb066d68-130a-4d4e-b517-7d8dd586bb6f',
									name: 'Fairhope, Alabama',
									photos: [
										{
											url: 'https://t3.ftcdn.net/jpg/05/27/93/30/240_F_527933022_D6eZssIzAMFiiAKFW2uO2V6sGdX2Hv60.jpg',
											title: 'The Fairhope, Alabama pier on Mobile Bay',
											placeName: 'Fairhope, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/04/14/96/84/240_F_414968452_oWTExlsYDgfFG8X9MhC4tmiCp47UhHPL.jpg',
											title: 'A cool sunset view at Fairhope, Alabama ',
											placeName: 'Fairhope, Alabama',
										},
										{
											url: 'https://t3.ftcdn.net/jpg/05/21/51/98/240_F_521519893_uqjXeaYUSHTkxptOjdBof83pOQRHcQUA.jpg',
											title: 'Fishing at Sunset in Fairhope, Alabama',
											placeName: 'Fairhope, Alabama',
										},
									],
									reason: 'Quaint town with art galleries',
								},
							],
							END_LOCATION: 'San Francisco',
							IS_ORDER_FLEXIBLE: 'Yes',
							NUM_TRAVELLERS: 1,
							START_LOCATION: 'San Francisco',
						},
						shouldShow: true,
					},
					STAGE: 'post_location_confirmation_widget',
				},
			],
			isTyping: false,
		},
		initialParse: {},
		loading: 0,
		loadingState: {
			flightsLoading: 'init',
			hotelsLoading: 'init',
			itineraryLoading: 'init',
		},
		llmInteractionHistory: [
			{
				CONTENT:
					'I want to plan a trip to Mobile, Alabama. Also suggest 3 more places to visit in Alabama. 8-day trip starting between June 10 and June 12.',
				DISPLAY_INFO: {},
				LABEL: 'User',
				SENDER: 'User',
			},
			{
				CONTENT:
					'Alabama has much to offer! Please answer the following questions to help me plan a diverse and exciting trip for you.',
				DISPLAY_INFO: {},
				LABEL: 'Assistant',
				SENDER: 'Assistant',
			},
			{
				CONTENT:
					'[\n  "What activities are you interested in for your Alabama trip? :: Outdoor adventures :: Cultural experiences :: Food and dining :: Historical sites :: Other (specify)",\n  "Anything else I should know?"\n]',
				DISPLAY_INFO: {},
				LABEL: 'Assistant-initiated Clarification Widget',
				SENDER: 'Assistant',
			},
			{
				CONTENT:
					'[\n  "What activities are you interested in for your Alabama trip? :: Outdoor adventures :: Cultural experiences",\n  "Anything else I should know? :: Unspecified"\n]',
				LABEL: 'User Response for Clarification Widget',
				SENDER: 'User',
			},
			{
				CONTENT:
					'Please confirm or edit these locations that I picked out for you, click on generate and watch your personalized dream trip come to life!',
				DISPLAY_INFO: {},
				LABEL: 'Assistant',
				SENDER: 'Assistant',
			},
			{
				CONTENT:
					'{\n  "NUM_TRAVELLERS": 1,\n  "START_LOCATION": "San Francisco",\n  "RECOMMENDED_PLACES": [\n    {\n      "name": "Mobile, Alabama",\n      "reason": "You specified this"\n    },\n    {\n      "name": "Birmingham, Alabama",\n      "reason": "Cultural experiences"\n    },\n    {\n      "name": "Huntsville, Alabama",\n      "reason": "Outdoor adventures"\n    },\n    {\n      "name": "Montgomery, Alabama",\n      "reason": "Historical sites"\n    }\n  ],\n  "ALTERNATIVE_PLACES": [\n    {\n      "name": "Gulf Shores, Alabama",\n      "reason": "Beach activities"\n    },\n    {\n      "name": "Tuscaloosa, Alabama",\n      "reason": "College town with cultural attractions"\n    },\n    {\n      "name": "Florence, Alabama",\n      "reason": "Arts and music scene"\n    },\n    {\n      "name": "Decatur, Alabama",\n      "reason": "Outdoor recreational activities"\n    },\n    {\n      "name": "Fairhope, Alabama",\n      "reason": "Quaint town with art galleries"\n    }\n  ],\n  "END_LOCATION": "San Francisco",\n  "IS_ORDER_FLEXIBLE": "Yes"\n}',
				DISPLAY_INFO: {},
				LABEL: 'Assistant-initiated Location Confirmation Widget',
				SENDER: 'Assistant',
			},
		],
		interactionStage: 'post_location_confirmation_widget',
		flight_results: null,
		hotel_results: null,
		day_plans: [],
		oldDayPlansForServer: [],
		active_tab_index: 0,
		bestTabIndex: -1,
		sort_option: 'Best',
		flightFormCollapisbleOpen: false,
		chosen_option: -1,
		maxPossibleFlightPrice: 0,
		minPossibleFlightPrice: null,
		selectedFlight: {},
		selectedHotels: [],
		selectedFlightByDateOption: [],
		gMapsData: {},
		questionWidget: {
			submitted: true,
			expanded: false,
		},
		locationConfirmationWidget: {
			submitted: false,
			expanded: true,
		},
		majorUpdateConfirmationWidgets: [],
		isMidReportGenerated: false,
		isChatViewCompressed: false,
		addedCitiesImages: [],
		isItineraryLoaded: false,
		inspirationDrawerInfo: {
			show: false,
			source: null,
		},
		isHeaderAnimationCompleted: false,
		initialAnimationCompleted: false,
		showTripControls: false,
		attachmentCardData: {},
	},
	tripTransitions: {
		maxPrice: 0,
		transitionReqs: [],
		travelSets: [],
		tripType: 'MULTI_CITY',
		tripClass: 'ECONOMY',
	},
	tripLocationDetails: {
		numTravellers: 1,
		budget: '$$',
		numPlaces: null,
		startLocation: '',
		places: [],
		endLocation: '',
		hotelFilters: [
			{
				AMENITIES: [],
				LATITUDE: '',
				LOCALITY: 'N/A',
				LONGITUDE: '',
				MAX_PRICE: -1,
				MIN_STAR_RATING: -1,
				NUM_ROOMS: 1,
			},
		],
		hotelReqs: [],
		hotelSets: [],
	},
	profile: {
		_initialized: false,
	},
};

export default directOverviewState;

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';

const schema = Yup.object().shape({
	email: Yup.string().required('Email is required'),
	password: Yup.string().required('Password is required'),
});

axios.defaults.withCredentials = true;

const useHandleLoginForm = () => {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const {
		control,
		formState: { errors },
		handleSubmit = () => {},
		watch,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const [loading, setLoading] = useState(false);
	const [showResetPasswordMessage, setShowResetPasswordMessage] =
		useState(false);
	const [currentStage, setCurrentStage] = useState('email');
	const [errorMessage, setErrorMessage] = useState('');

	const INPUT_MAPPING = [
		{
			type: 'email',
			id: 'email',
			name: 'email',
			label: 'Email',
			placeholder: 'email@domain.com',
			show: currentStage === 'email',
			autoComplete: 'username',
		},
		{
			type: 'password',
			id: 'password',
			name: 'password',
			placeholder: 'Enter password',
			label: 'Password',
			show: currentStage === 'password',
		},
	];

	const onSubmitForm = async (values) => {
		setLoading(true);

		try {
			await axios.post('/auth/login', values);

			const res = await axios.get('/auth/get_user_session');

			dispatch({
				type: 'UPDATE_PROFILE_REDUCER',
				payload: res?.data,
			});

			setLoading(false);

			navigate('/');
		} catch (error) {
			setLoading(false);

			if (error?.response?.data?.error) {
				setErrorMessage(error?.response?.data?.error);
			}
		}
	};

	return {
		controls: INPUT_MAPPING,
		onSubmitForm,
		errors,
		navigate,
		control,
		loading,
		handleSubmit,
		currentStage,
		setCurrentStage,
		watch,
		errorMessage,
		setErrorMessage,
		showResetPasswordMessage,
		setShowResetPasswordMessage,
	};
};

export default useHandleLoginForm;

/* eslint-disable max-len */
import {
	ChatMessageWidgetType,
	ChatMessageSender,
	InteractionStage,
	LocationWidgetSource,
	PostReportUpdateType,
	PostReportIntent,
	SortOptions,
	TripUpdateType,
	MainTripPageView,
	MessageLabel,
	LoadingState,
} from '@helpers/Enums';
import isEmpty from '@utils/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import {
	getFlightQueryFromTripTransitions,
	handleFindFlights,
} from '@helpers/FlightsQueryHelper';
import {
	dispatchUpdateChatMessages,
	dispatchAddChatMessage,
} from '@helpers/ChatUtils';
import { formatDate } from '@helpers/DateUtils';
import { afterLocationConfirmationReport } from '@helpers/GenerateTripHelper';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { parse, Allow } from 'partial-json';
import axios from 'axios';
import { updateTripOnServer } from '../redux/SaveTripMiddleware';
import { processDayPlansChanges } from './DayPlansUtils';
import { GoogleEventTypes, sendMessageEvent } from './GoogleAnalyticsHelper';
import { supportedUpdateTypesToPostActions } from './TripSaveAndLoadHelper';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const FAILURE_ACK_MESSAGE =
	'Something went wrong. Please check your internet connection and try again. If this is an issue on our end, we apologize for the inconvenience.';

function levenshteinDistance(a, b) {
	if (a.length === 0) return b.length;
	if (b.length === 0) return a.length;

	const matrix = [];

	// Initialize the first row
	for (let i = 0; i <= b.length; i += 1) {
		matrix[i] = [i];
	}

	// Initialize the first column
	for (let j = 0; j <= a.length; j += 1) {
		matrix[0][j] = j;
	}

	// Calculate the distance
	for (let i = 1; i <= b.length; i += 1) {
		for (let j = 1; j <= a.length; j += 1) {
			const cost = a[j - 1] === b[i - 1] ? 0 : 1;
			matrix[i][j] = Math.min(
				matrix[i - 1][j] + 1, // deletion
				matrix[i][j - 1] + 1, // insertion
				matrix[i - 1][j - 1] + cost, // substitution
			);
		}
	}

	return matrix[b.length][a.length];
}

export async function getPlacesData(place) {
	const name = typeof place === 'string' ? place : place.name;
	const useAdobeForLocationWidget =
		(process.env.REACT_APP_USE_ADOBE_FOR_LOCATION_WIDGET || 'false') === 'true';
	const adobeStockApiKey = process.env.REACT_APP_ADOBE_STOCK_API_KEY;

	if (useAdobeForLocationWidget && adobeStockApiKey != null) {
		try {
			const encodedSearchWords = encodeURIComponent(name);
			const url = `https://stock.adobe.io/Rest/Media/1/Search/Files?locale=en_US&search_parameters[words]=${encodedSearchWords}&search_parameters[limit]=3&
            search_parameters[thumbnail_size]=240&search_parameters[filters][content_type:photo]=1`;
			const headers = {
				'x-api-key': adobeStockApiKey,
				'x-product': 'SFL',
			};

			const response = await fetch(url, { method: 'GET', headers });

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const jsonResponse = await response.json();
			if (jsonResponse.files && jsonResponse.files.length > 0) {
				return {
					source: LocationWidgetSource.ADOBE_STOCK,
					placeName: name,
					subtitle: name,
					id: uuidv4(),
					name,
					photos: jsonResponse.files.map((file) => ({
						url: file.thumbnail_url,
						getUrl: () => file.thumbnail_url,
						title: file.title,
						placeName: name,
					})),
					...(typeof place === 'string' ? {} : { reason: place.reason }),
				};
			}
		} catch (error) {
			console.error('Fetching Adobe Stock photos failed', error);
		}
	}

	// Fallback to Google
	const service = new window.google.maps.places.PlacesService(
		document.createElement('div'),
	);

	const results = await new Promise((resolve, reject) => {
		service.textSearch(
			{
				query: name,
			},
			(result, status) => {
				if (
					status === window.google.maps.places.PlacesServiceStatus.OK &&
					result &&
					result.length > 0
				) {
					resolve(result);
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					resolve([]);
				}
			},
		);
	});

	if (results.length == 0) {
		return {
			id: null,
			placeName: name,
			subtitle: null,
			source: LocationWidgetSource.GOOGLE,
			...(typeof place === 'string' ? {} : { reason: place.reason }),
		};
	}

	const bestMatch = findBestMatch(name, results);

	if (!bestMatch) {
		return {
			source: LocationWidgetSource.GOOGLE,
			id: new Date().getTime(),
			placeName: name,
			subtitle: name,
		};
	}

	return {
		...bestMatch,
		id: bestMatch.place_id,
		placeName: name,
		subtitle: bestMatch.formatted_address,
		source: LocationWidgetSource.GOOGLE,
		...(typeof place === 'string' ? {} : { reason: place.reason }),
	};
}

export function findBestMatch(placeName, results = []) {
	let bestMatch = null;
	let minDistance = Infinity;

	for (let i = 0; i < results.length; i += 1) {
		const distance = levenshteinDistance(
			placeName.toLowerCase(),
			results[i].name.toLowerCase(),
		);
		if (distance < minDistance) {
			minDistance = distance;
			bestMatch = results[i];
		}
	}

	return bestMatch || results[0] || null;
}

export async function handleFetchPreLC(
	postFormData,
	dispatch,
	navigate,
	chatSessionId,
) {
	try {
		const res = await axios.post(
			'/api/temp_pre_location_clarification',
			postFormData,
		);
		const response = res?.data;

		const {
			CHAT_HISTORY: chat_history,
			CHAT_RESPONSE: chat_response,
			TITLE: title = '',
			STAGE: stage,
			WIDGET: widget,
			CHAT_SESSION_ID: newChatSessionId,
		} = response;
		if (!chatSessionId) {
			chatSessionId = newChatSessionId;
			dispatch({
				type: 'UPDATE_CHAT_SESSION_ID',
				payload: chatSessionId,
			});
			// Invalidate My trips
			dispatch({ type: 'UPDATE_MY_TRIPS', payload: [] });
			dispatch({
				type: 'UPDATE_NAVIGATING_FROM_LANDING_PAGE_TO_CHAT',
				payload: true,
			});
			navigate(`/chat/${chatSessionId}`, { replace: true });
		}
		sendMessageEvent({
			event: GoogleEventTypes.PRE_LC_MESSAGE,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_INTERACTION_STAGE',
			payload: stage,
		});

		if (
			stage === InteractionStage.POST_LOCATION_CONFIRMATION_WIDGET &&
			chat_response.includes('confirm or edit these locations')
		) {
			const { CONTENT: content } = widget;

			const {
				RECOMMENDED_PLACES: recommended_places = [],
				ALTERNATIVE_PLACES: alternative_places = [],
				...restContent
			} = content;

			const recommendedPlacesWithData = await Promise.all(
				recommended_places.map(async (place) => {
					const data = await getPlacesData(place);
					return data;
				}),
			);

			const alternativePlacesWithData = await Promise.all(
				alternative_places.map(async (place) => {
					const data = await getPlacesData(place);
					return data;
				}),
			);

			dispatch({
				type: 'UPDATE_LOCATIONS_REDUCER',
				payload: {
					numTravellers: restContent.NUM_TRAVELLERS || 1,
				},
			});
			const locationMessage = {
				sender: ChatMessageSender.AIRIAL,
				message: chat_response,
				widget: {
					widgetType: ChatMessageWidgetType.LOCATION_WIDGET,
					widgetData: {
						recommendedPlaces: recommendedPlacesWithData,
						alternativePlaces: alternativePlacesWithData,
						...restContent,
					},
					shouldShow: true,
				},
				STAGE: stage,
			};

			dispatch({
				type: 'UPDATE_LLM_INTERACTION_HISTORY',
				payload: chat_history,
			});

			dispatchAddChatMessage(
				dispatch,
				TripUpdateType.LOCATION_CONFIRMATION_ASSISTANT_INITIATED,
				{
					messages: [locationMessage],
					isTyping: false,
				},
			);

			return;
		}

		if (title !== '') {
			dispatch({
				type: 'UPDATE_TRIP_TITLE',
				payload: title,
			});
		}

		const airialMessage = {
			sender: ChatMessageSender.AIRIAL,
			message: chat_response,
			...(!isEmpty(widget)
				? {
						widget: {
							widgetType: ChatMessageWidgetType.PREFERENCES_WIDGET,
							widgetData: widget,
							shouldShow: true,
						},
					}
				: {}),
			STAGE: stage,
		};

		dispatch({
			type: 'UPDATE_LLM_INTERACTION_HISTORY',
			payload: chat_history,
		});

		dispatchAddChatMessage(dispatch, TripUpdateType.PRE_LC_ASSISTANT_RESPONSE, {
			messages: [airialMessage],
			isTyping: false,
		});
	} catch (error) {
		console.error('Error fetching pre-LC:', error);
		handleChatResponseError(error, null, dispatch, false);
	}
}

export function getPostReportDayPlansContext(
	curDayPlanResults,
	activeItineraryDayTab,
) {
	const { DATE = '', location: loc = '' } =
		curDayPlanResults?.[activeItineraryDayTab] || {};
	const user_currently_viewing = `The user is currently viewing the plan for Day ${activeItineraryDayTab + 1}, ${DATE} at ${loc}.`;

	return {
		user_currently_viewing,
		day_plans: curDayPlanResults,
	};
}

export function getTravelSetsAfterTripGeneration(
	dispatch,
	responsePayload,
	tripDetailsStateFromMidReport,
) {
	const { FLIGHT_QUERY = {}, TRAVEL_DATES = [] } = responsePayload;

	const {
		FLIGHTSETS = [],
		TRIP_CLASS = '',
		MAX_PRICE = '',
		TRIP_TYPE = '',
	} = FLIGHT_QUERY;

	const { FLIGHT_REQUIREMENT = [], TRANSPORTATION_REQUIREMENT } =
		tripDetailsStateFromMidReport;

	const DATES = TRAVEL_DATES.map((travelDateSetArray) =>
		travelDateSetArray.map(({ DATE }) => DATE),
	);

	const isFlightTransportationPresent = !!FLIGHT_REQUIREMENT.filter(
		(flightRequired) => flightRequired,
	).length;

	let travelSets;

	if (isFlightTransportationPresent) {
		travelSets = FLIGHTSETS.map((flightSet, mainIndex) => {
			let num = 0;

			return FLIGHT_REQUIREMENT.reduce((acc, current, index) => {
				if (current && flightSet[num]) {
					let flightDetails = {
						fromAirport: flightSet[num].FROM_AIRPORT,
						toAirport: flightSet[num].TO_AIRPORT,
						dateOfJourney: flightSet[num].DATE_OF_JOURNEY,
						maxStops: flightSet[num].MAX_STOPS,
						maxDuration: flightSet[num].MAX_DURATION,
					};
					if (flightSet[num]?.DEP_TIME_RANGE_START) {
						flightDetails['depTimeRangeStart'] = flightSet[num].DEP_TIME_RANGE_START;
					}
					if (flightSet[num]?.DEP_TIME_RANGE_END) {
						flightDetails['depTimeRangeEnd'] = flightSet[num].DEP_TIME_RANGE_END;
					}
					if (flightSet[num]?.ARR_TIME_RANGE_START) {
						flightDetails['arrTimeRangeStart'] = flightSet[num].ARR_TIME_RANGE_START;
					}
					if (flightSet[num]?.ARR_TIME_RANGE_END) {
						flightDetails['arrTimeRangeEnd'] = flightSet[num].ARR_TIME_RANGE_END;
					}
					acc.push({
						type: 'FLIGHT',
						flightDetails,
						nonFlightDetails: null,
					});
					num += 1;
				} else {
					acc.push({
						type: (TRANSPORTATION_REQUIREMENT[index] || 'CAR').toUpperCase(),
						flightDetails: null,
						nonFlightDetails: {
							dateOfJourney: formatDate(DATES[mainIndex][index]),
							timeOfDeparture: null,
							dateOfArrival: null,
							timeOfArrival: null,
						},
					});
				}
				return acc;
			}, []);
		});

		dispatch({
			type: 'UPDATE_TRANSITIONS_REDUCER',
			payload: {
				maxPrice: MAX_PRICE,
				transitionReqs: TRANSPORTATION_REQUIREMENT,
				travelSets,
				tripType: TRIP_TYPE,
				tripClass: TRIP_CLASS,
			},
		});

		dispatch({
			type: 'UPDATE_HOTEL_RESULTS',
			payload: Array(travelSets.length).fill(null),
		});

		dispatch({
			type: 'UPDATE_DAY_PLAN_RESULTS',
			payload: Array(travelSets.length).fill(null),
		});
	} else {
		travelSets = DATES.map((dateSet) => {
			return dateSet.map((date, index) => {
				return {
					type: (TRANSPORTATION_REQUIREMENT[index] || 'CAR').toUpperCase(),
					flightDetails: null,
					nonFlightDetails: {
						dateOfJourney: formatDate(date),
						timeOfDeparture: null,
						dateOfArrival: null,
						timeOfArrival: null,
					},
				};
			});
		});

		dispatch({
			type: 'UPDATE_TRANSITIONS_REDUCER',
			payload: {
				transitionReqs: TRANSPORTATION_REQUIREMENT,
				travelSets,
			},
		});

		dispatch({
			type: 'UPDATE_HOTEL_RESULTS',
			payload: Array(travelSets.length).fill(null),
		});

		dispatch({
			type: 'UPDATE_DAY_PLAN_RESULTS',
			payload: Array(travelSets.length).fill(null),
		});
	}

	return travelSets;
}

// Replicating this from the trip generation flow but this is not aligned with best practices AFAIK. Fix.
let tripDetailsState = {};

function handleResponsePostReport(
	response,
	dispatch,
	location,
	majorUpdateConfirmationWidgets,
	currentUserMessage,
	chatMessages,
	updatedLlmInteractionHistory,
	chatSessionId,
	attachment,
	dayPlans,
	activeTabIndex,
	additionalMajorUpdateData = {},
	currentReduxState,
) {
	if (tripDetailsState?.status === 'ERROR') {
		return;
	}

	try {
		handleResponsePostReportImpl(
			response,
			dispatch,
			location,
			majorUpdateConfirmationWidgets,
			currentUserMessage,
			chatMessages,
			chatSessionId,
			attachment,
			dayPlans,
			activeTabIndex,
			additionalMajorUpdateData,
		);
	} catch (error) {
		console.error('Caught error in handleResponsePostReport:', error);
		// TODO: Not ideal to use global variables.
		tripDetailsState = {
			status: 'ERROR',
		};
		handleChatResponseError(
			'ERROR',
			chatMessages,
			dispatch,
			true,
			currentReduxState,
			updatedLlmInteractionHistory,
		);
	}
}

function handleResponsePostReportImpl(
	response,
	dispatch,
	location,
	majorUpdateConfirmationWidgets,
	currentUserMessage,
	chatMessages,
	chatSessionId,
	attachment,
	dayPlans,
	activeTabIndex,
	additionalMajorUpdateData = {},
) {
	const { MODE: mode } = response;

	if (mode === 'mid_report') {
		// TODO: Once the mid-report comes in, the topbar animation should start, ending with the topbar moving to the top-right.

		const {
			INPUT_STAGE: input_stage,
			TRIP_SUMMARY_CHANGED: trip_summary_changed = false,
			TRIP_SUMMARY: trip_summary = null,
		} = response;

		if (
			input_stage !== InteractionStage.POST_REPORT_MAJOR_UPDATE_CONFIRMATION
		) {
			return;
		}

		// TODO: Instead of modifying these directly in the chat message history, this should be a separate state that the widget subscribes to, similar to major-update-confirmation.
		const updatedChatMessages = chatMessages.map((item, index) => {
			if (
				index === chatMessages.length - 1 &&
				item?.widget?.widgetType === 'trip_generation_updates_widget'
			) {
				return {
					...item,
					widget: {
						...item.widget,
						widgetData: [
							{
								id: 'identifying_route_and_connections',
								message: 'Identifying the route and the connections.',
								status: 1,
							},
							{
								id: 'finding_travel_dates',
								message: 'Finding the best travel dates.',
								status: 0,
							},
						],
						shouldShow: true,
					},
				};
			}

			return item;
		});

		const {
			TRANSPORTATION_REQUIREMENT: transportation_requirement,
			HOTEL_REQUIREMENT: hotelRequirement = [],
			ORDERED_PLACES: ordered_places = [],
			START_LOCATION: startLocationFromBackend = '',
			END_LOCATION: endLocationFromBackend = '',
		} = trip_summary;

		tripDetailsState = {
			FLIGHT_REQUIREMENT: transportation_requirement.map(
				(item) => item === 'flight',
			),
			HOTEL_REQUIREMENT: hotelRequirement,
			LOCATIONS: ordered_places,
			SOURCE: startLocationFromBackend,
			DESTINATION: endLocationFromBackend,
			TRANSPORTATION_REQUIREMENT: transportation_requirement,
		};

		if (!trip_summary_changed) {
			return;
		}

		dispatch({
			type: 'UPDATE_OVERALL_TRIP_LOAD_STATUS',
			payload: false,
		});

		dispatch({
			type: 'UPDATE_LOADING_STATE',
			payload: {
				flightsLoading: LoadingState.LOADING,
				hotelsLoading: LoadingState.INIT,
				itineraryLoading: LoadingState.INIT,
			},
		});

		// TODO: Clear screen and add top-bar animation. (DONE)
		// TODO: Both these updates should roll back if there is an exception in the post-report flow. (DONE)
		dispatch({
			type: 'UPDATE_LOCATIONS_REDUCER',
			payload: {
				endLocation: endLocationFromBackend,
				startLocation: startLocationFromBackend,
				places: ordered_places,
				hotelReqs: hotelRequirement,
				numPlaces: ordered_places.length,
			},
		});
		dispatch({
			type: 'UPDATE_TRANSITIONS_REDUCER',
			payload: {
				transitionReqs: transportation_requirement,
			},
		});

		dispatch({
			type: 'INITIAL_ANIMATION_COMPLETED',
			payload: false,
		});
		dispatch({
			type: 'UPDATE_HEADER_ANIMATION',
			payload: false,
		});

		dispatchUpdateChatMessages(
			dispatch,
			TripUpdateType.POST_REPORT_MAJOR_UPDATE_MID_REPORT,
			{
				messages: updatedChatMessages,
				isTyping: true,
			},
		);

		return;
	}

	if (mode === 'report') {
		const {
			STAGE: stage,
			CHAT_RESPONSE: chat_response,
			CHAT_HISTORY: llmInteractionHistory,
			UPDATE_TYPE: update_type,
			INTENTS: intents,
			PAYLOAD: payload = null,
		} = response;

		dispatch({
			type: 'UPDATE_LLM_INTERACTION_HISTORY',
			payload: llmInteractionHistory,
		});
		dispatch({
			type: 'UPDATE_INTERACTION_STAGE',
			payload: stage,
		});

		if (
			// Checks whether this is a major update confirmed by the user and is not a placeholder report response.
			update_type === PostReportUpdateType.MAJOR &&
			stage === InteractionStage.POST_REPORT &&
			payload
		) {
			const {
				NUM_TRAVELLERS = 1,
				FLIGHT_QUERY = {},
				HOTEL_FILTERS = [],
				TRAVEL_DATES = [],
			} = payload;

			const {
				FLIGHT_REQUIREMENT = [],
				HOTEL_REQUIREMENT = [],
				LOCATIONS = [],
				SOURCE,
				DESTINATION,
			} = tripDetailsState;

			const DATES = TRAVEL_DATES.map((travelDateSetArray) =>
				travelDateSetArray.map(({ DATE }) => DATE),
			);

			const isFlightTransportationPresent = !!FLIGHT_REQUIREMENT.filter(
				(flightRequired) => flightRequired,
			).length;

			// TODO: Instead of modifying these directly in the chat message history, this should be a separate state that the widget subscribes to, similar to major-update-confirmation.
			const updatedChatMessages = chatMessages.map((item, index) => {
				if (
					index === chatMessages.length - 1 &&
					item?.widget?.widgetType === 'trip_generation_updates_widget'
				) {
					return {
						...item,
						widget: {
							...item?.widget,
							widgetData: [
								{
									id: 'identifying_route_and_connections',
									message: 'Identifying the route and the connections.',
									status: 1,
								},
								{
									id: 'finding_travel_dates',
									message: `Finding the best travel dates.${
										DATES.length > 1 ? ` Found ${DATES.length} options.` : ''
									}`,
									status: 1,
								},
								...(isFlightTransportationPresent
									? [
											{
												id: 'searching_flights',
												message: 'Searching for flights.',
												status: 0,
											},
										]
									: []),
								{
									id: 'searching_hotels',
									message: 'Searching for hotels.',
									status: 0,
								},
								{
									id: 'creating_itinerary',
									message: 'Creating an itinerary.',
									status: 0,
								},
							],
							shouldShow: true,
						},
					};
				}

				return item;
			});

			let hotelNum = 0;
			const hotelFilters = HOTEL_REQUIREMENT.reduce((acc, current) => {
				if (current && HOTEL_FILTERS[hotelNum]) {
					acc.push(HOTEL_FILTERS[hotelNum]);
					hotelNum += 1;
				} else {
					acc.push(null);
				}
				return acc;
			}, []);
			dispatch({
				type: 'UPDATE_HOTEL_FILTERS',
				payload: hotelFilters,
			});

			// TODO: Do we want a separate event for post-report, for this and {flights, hotels, day plans}-loaded updates?
			sendMessageEvent({
				event: GoogleEventTypes.DATE_TABS_LOADED,
				chat_session_id: chatSessionId,
			});

			const travelSets = getTravelSetsAfterTripGeneration(
				dispatch,
				payload,
				tripDetailsState,
			);

			dispatchUpdateChatMessages(
				dispatch,
				TripUpdateType.LOCATION_CONFIRMATION_ASSISTANT_REPORT,
				{
					messages: updatedChatMessages,
					isTyping: false,
				},
			);

			const {
				inspirationContentIds,
				hotelSortCriterion,
				flightSortOption,
				gMapsData,
			} = additionalMajorUpdateData;

			afterLocationConfirmationReport(
				dispatch,
				location,
				chatSessionId,
				NUM_TRAVELLERS,
				inspirationContentIds,
				HOTEL_REQUIREMENT,
				LOCATIONS,
				SOURCE,
				DESTINATION,
				hotelFilters,
				FLIGHT_QUERY,
				llmInteractionHistory,
				isFlightTransportationPresent,
				hotelSortCriterion,
				flightSortOption,
				travelSets,
				gMapsData,
			);

			return;
		}

		const newChatMessage = {
			sender: ChatMessageSender.AIRIAL,
			message: chat_response,
			...(update_type === PostReportUpdateType.MAJOR &&
			stage === InteractionStage.POST_REPORT_MAJOR_UPDATE_CONFIRMATION
				? {
						widget: {
							widgetType:
								ChatMessageWidgetType.POST_REPORT_MAJOR_UPDATE_CONFIRMATION,
							widgetData: {
								index: majorUpdateConfirmationWidgets.length,
								userMessage: currentUserMessage,
								attachment,
								intents,
							},
							shouldShow: true,
						},
					}
				: {}),
		};

		if (
			update_type === PostReportUpdateType.MAJOR &&
			stage === InteractionStage.POST_REPORT_MAJOR_UPDATE_CONFIRMATION
		) {
			majorUpdateConfirmationWidgets.push({
				cancelOrConfirm: false,
				submitted: false,
			});
			dispatch({
				type: 'UPDATE_MAJOR_UPDATE_CONFIRMATION_WIDGETS',
				payload: majorUpdateConfirmationWidgets,
			});
		}

		if (update_type === PostReportUpdateType.MINOR) {
			const {
				PAYLOAD: {
					TRIP_LOCATIONS: trip_locations,
					TRIP_TRANSITIONS: trip_transitions,
					DAY_PLANS_CHANGES: day_plans_changes,
				},
			} = response;

			dispatch({
				type: 'UPDATE_LOCATIONS_REDUCER',
				payload: trip_locations,
			});
			dispatch({
				type: 'UPDATE_TRANSITIONS_REDUCER',
				payload: trip_transitions,
			});
			processDayPlansChanges(
				activeTabIndex,
				dayPlans,
				day_plans_changes,
				dispatch,
			);

			// TODO: Day plans changes will get invalidated if the flight filters have also changed. Fix.
			let updateFlights = false;
			let updateHotels = false;
			for (const intent of intents) {
				if (
					intent === PostReportIntent.CHANGE_FLIGHT_FILTERS ||
					intent === PostReportIntent.CHANGE_AIRPORTS
				) {
					updateFlights = true;
				} else if (intent === PostReportIntent.CHANGE_HOTEL_FILTERS) {
					updateHotels = true;
				}
			}

			if (updateFlights) {
				const flightQuery = getFlightQueryFromTripTransitions(
					trip_transitions,
					trip_locations.numTravellers,
				);

				dispatch({ type: 'UPDATE_SORT_OPTION', payload: SortOptions.BEST });
				handleFindFlights({
					flightQuery,
					dispatch,
					chatSessionId,
					location,
					dayPlansQuery: {
						locations: {
							startLocation: trip_locations.startLocation,
							endLocation: trip_locations.endLocation,
							places: trip_locations.places,
						},
						travelSets: trip_transitions.travelSets,
						chat_history: llmInteractionHistory,
					},
					shouldUpdateFlightsAndDayPlans: true,
					tripData: {
						NUM_TRAVELLERS: trip_locations.numTravellers,
						hotelFilters: trip_locations.hotelFilters,
						travelSets: trip_transitions.travelSets,
						LOCATIONS: trip_locations.places,
						HOTEL_REQUIREMENT: trip_locations.hotelReqs, // TODO: Need to pass hotelSortCriterion
					}, // TODO: Need to pass gMapsData
				});
			} else if (updateHotels) {
				handleFindHotels({
					dispatch,
					chatSessionId,
					location,
					hotel_set_data: {
						hotel_datesets: trip_locations.hotelSets,
						hotelFilters: trip_locations.hotelFilters,
						NUM_TRAVELLERS: trip_locations.numTravellers,
						HOTEL_REQUIREMENT: trip_locations.hotelReqs,
					}, // TODO: Need to pass tabIndex and hotelSortCriterion // TODO: Need to pass gMapsData
				});
			}
		}

		dispatchAddChatMessage(
			dispatch,
			TripUpdateType.POST_REPORT_ASSISTANT_REPORT,
			{
				messages: [newChatMessage],
				isTyping: false,
			},
		);
	}
}

const handleStreamResponsePostReport = (
	response,
	dispatch,
	location,
	majorUpdateConfirmationWidgets,
	currentUserMessage,
	chatMessages,
	updatedLlmInteractionHistory,
	chatSessionId,
	attachment,
	dayPlans,
	activeTabIndex,
	additionalMajorUpdateData,
	currentReduxState,
) => {
	if (response.ok) {
		const reader = response.body.getReader();
		let accumulatedChunks = '';
		let completeJSONObjectsHandled = 0;

		function readChunk() {
			reader.read().then(({ done, value }) => {
				if (done) {
					return;
				}

				const chunkText = new TextDecoder().decode(value);
				if (chunkText === 'ERROR') {
					handleChatResponseError(
						'ERROR',
						chatMessages,
						dispatch,
						true,
						currentReduxState,
						updatedLlmInteractionHistory,
					);
					return;
				}
				accumulatedChunks += chunkText;
				const parsedJSON = parse(accumulatedChunks, Allow.ARR);
				if (parsedJSON && parsedJSON.length > completeJSONObjectsHandled) {
					for (
						let i = completeJSONObjectsHandled;
						i < parsedJSON.length;
						i += 1
					) {
						handleResponsePostReport(
							parsedJSON[i],
							dispatch,
							location,
							majorUpdateConfirmationWidgets,
							currentUserMessage,
							chatMessages,
							updatedLlmInteractionHistory,
							chatSessionId,
							attachment,
							dayPlans,
							activeTabIndex,
							additionalMajorUpdateData,
							currentReduxState,
						);
					}
					completeJSONObjectsHandled = parsedJSON.length;
				}
				readChunk(); // Recursively read the next chunk
			});
		}

		readChunk(); // Start reading chunks
	}
};

export async function handleFetchPostReport(
	postFormData,
	dispatch,
	location,
	majorUpdateConfirmationWidgets,
	currentUserMessage,
	chatMessages,
	updatedLlmInteractionHistory,
	chatSessionId,
	attachment,
	dayPlans,
	activeTabIndex,
	additionalMajorUpdateData,
	currentReduxState,
) {
	tripDetailsState = {};
	sendMessageEvent({
		event: GoogleEventTypes.POST_REPORT_MESSAGE,
		chat_session_id: chatSessionId,
	});
	fetch(`${backendURL}/api/post_report`, {
		method: 'POST',
		body: postFormData,
		timeout: 10000,
	})
		.then((response) => {
			handleStreamResponsePostReport(
				response,
				dispatch,
				location,
				majorUpdateConfirmationWidgets,
				currentUserMessage,
				chatMessages,
				updatedLlmInteractionHistory,
				chatSessionId,
				attachment,
				dayPlans,
				activeTabIndex,
				additionalMajorUpdateData,
				currentReduxState,
			);
		})
		.catch((error) => {
			handleChatResponseError(error, chatMessages, dispatch, false);
		});
}

export async function handleLookupPlacesOfInterest({ data = {} }) {
	try {
		const postFormData = new FormData();
		postFormData.append('attached_card', JSON.stringify(data));

		const res = await fetch(`${backendURL}/api/lookup_places_of_interest`, {
			method: 'POST',
			body: postFormData,
			timeout: 10000,
		});

		const response = await res.json();

		return response;
	} catch (error) {
		console.log('error', error);
		return [];
	}
}

export async function handleLookupAirports({ city_list = [] }) {
	try {
		const postFormData = new FormData();
		postFormData.append('city_list', JSON.stringify(city_list));
		const res = await fetch(`${backendURL}/api/lookup_airports`, {
			method: 'POST',
			body: postFormData,
			timeout: 10000,
		});

		const response = await res.json();
		const airportCodes = response?.[0] || [];

		if (
			!airportCodes.length ||
			airportCodes[0].includes('airport') ||
			airportCodes[0].includes('N/A')
		) {
			return [];
		}

		return airportCodes;
	} catch (error) {
		console.log('error', error);
		return [];
	}
}

export function handleChatResponseError(
	error,
	chatMessages,
	dispatch,
	isReduxUpdateRequired,
	currentReduxState,
	llmInteractionHistory,
) {
	if (isReduxUpdateRequired && !isEmpty(currentReduxState || {})) {
		const { ui_states = {} } = currentReduxState;
		let updatedChatMessages = chatMessages;

		if (
			updatedChatMessages[updatedChatMessages.length - 1]?.widget?.widgetType ==
			ChatMessageWidgetType.TRIP_GENERATION_UPDATES_WIDGET
		) {
			updatedChatMessages = updatedChatMessages.slice(0, -3);
		}

		updatedChatMessages.push({
			sender: ChatMessageSender.AIRIAL,
			message: FAILURE_ACK_MESSAGE,
			widget: {},
		});

		// Removes the failed message as well as its confirmation flow from the llm interaction history.
		let i = llmInteractionHistory.length - 1;
		for (; i >= 0; i--) {
			if (llmInteractionHistory[i]?.LABEL === MessageLabel.USER) {
				break;
			}
		}
		if (i === -1) {
			return;
		}
		const updatedLlmInteractionHistory = llmInteractionHistory.slice(0, i);

		const updatedMajorUpdateConfirmationWidgets =
			ui_states.majorUpdateConfirmationWidgets.map((state) =>
				state.submitted
					? state
					: {
							cancelOrConfirm: true,
							submitted: true,
						},
			);

		const updatedUiStates = {
			...ui_states,
			chatMessages: { messages: updatedChatMessages, isTyping: false },
			llmInteractionHistory: updatedLlmInteractionHistory,
			majorUpdateConfirmationWidgets: updatedMajorUpdateConfirmationWidgets,
			currentChatMessage: '',
		};

		const restoreState = {
			...currentReduxState,
			ui_states: updatedUiStates,
		};

		dispatch({
			type: 'SET_ENTIRE_STATE',
			payload: restoreState,
		});

		dispatch({
			type: 'UPDATE_OVERALL_TRIP_LOAD_STATUS',
			payload: true,
		});

		dispatch({
			type: 'UPDATE_LOADING_STATE',
			payload: {
				flightsLoading: LoadingState.LOADED,
				hotelsLoading: LoadingState.LOADED,
				itineraryLoading: LoadingState.LOADED,
			},
		});

		updateTripOnServer({
			...restoreState,
			ui_states: {
				...updatedUiStates,
				flight_results: null,
				myTrips: [],
				gMapsData: {},
			},
			checkpoint: TripUpdateType.ASSISTANT_ERROR,
		});

		return;
	}

	const airialMessage = {
		sender: ChatMessageSender.AIRIAL,
		message: FAILURE_ACK_MESSAGE,
	};
	dispatchAddChatMessage(dispatch, TripUpdateType.ASSISTANT_ERROR, {
		messages: [airialMessage],
		isTyping: false,
	});
}

export async function loadTripStateFromSessionId(
	dispatch,
	location,
	chatSessionId,
	navigate,
	profile,
	setLoading,
) {
	try {
		const response = await fetch(
			`${backendURL}/api/get_airial_travel_session?chat_session_id=${chatSessionId}`,
		);
		const data = await response.json();
		const reduxState = data.redux_state;

		const { tripLocationDetails = {}, ui_states = {} } = reduxState || {};

		const {
			hotelFilters = [],
			hotelSets = [],
			hotelReqs = [],
		} = tripLocationDetails;

		const {
			active_tab_index,
			day_plans = [],
			interactionStage = '',
		} = ui_states;

		const filteredHotelFilters = hotelFilters.filter(
			(hotelFilter) => hotelFilter,
		);

		const hotelsErrorCondition =
			filteredHotelFilters.length !==
				(hotelSets?.[0] || []).filter((hotelSet) => hotelSet).length &&
			filteredHotelFilters.length !== hotelReqs.filter((req) => req).length;

		const dayPlansErrorCondition = !Array.isArray(
			day_plans?.[active_tab_index],
		);

		const isPostReportView = [
			InteractionStage.POST_REPORT,
			InteractionStage.POST_REPORT_MAJOR_UPDATE_CONFIRMATION,
		].includes(interactionStage);

		// if (isPostReportView && (hotelsErrorCondition || dayPlansErrorCondition)) {
		// 	navigate('/error');
		// 	return;
		// }

		dispatch({
			type: 'SET_ENTIRE_STATE',
			payload: {
				...reduxState,
				ui_states: {
					showMyTripsPopover: true,
					showTripControlsPopover: true,
					showDayPlansPopover: true,
					showChatBoxAttachmentPopover: true,
					...reduxState.ui_states,
					mainTripPageView: MainTripPageView.OVERVIEW,
					chatMessages: {
						...reduxState.ui_states.chatMessages,
						isTyping: false,
					},
				},
				profile,
			},
		});

		if (
			reduxState.checkpoint &&
			supportedUpdateTypesToPostActions[reduxState.checkpoint]
		) {
			supportedUpdateTypesToPostActions[reduxState.checkpoint](
				reduxState,
				dispatch,
				location,
			);
		}

		setLoading(false);
	} catch (error) {
		console.error('Loading chat messages failed', error);
	}
}

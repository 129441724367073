function MinusIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 16 16"
			fill="none"
		>
			<circle cx="8" cy="8" r="7" stroke="#7750E5" strokeWidth="1" />
			<path
				d="M4.5 8H11.5"
				stroke="#36174D"
				strokeWidth="0.75"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default MinusIcon;

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MapComponent from '@commons/MapComponent';
import ItineraryDayCards from '@commons/Itinerary/ItineraryDayCards';
import { formatDate } from '@helpers/DateUtils';
import {
	convertScheduleToItineraryDayCardsData,
	getFormattedGMapsData,
} from '@utils/DayCardUtils';
import Drawer from '@mui/material/Drawer';
import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
	InspirationDrawerSource,
	LoadingState,
	MainTripPageView,
} from '@helpers/Enums';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import AddInspirationButton from '@commons/Inspiration/AddInspirationButton';
import {
	getUpdatedAndRemovedDayCardsData,
	updateDayCardsDataListWithNewTags,
} from '@utils/DayPlansUtils';
import isEmpty from '@utils/isEmpty';
import ItineraryHeader from '../../../commons/Itinerary/ItineraryHeader';
import styles from './styles.module.css';
import AddActivity from './AddActivity';
import TabChangeInfoComponent from '../OverallResults/TabChangeInfoComponent';

function DayPlans({
	setCurrentView,
	tabChanged = false,
	handleUpdateHotelsAndItinerary = () => {},
}) {
	const dispatch = useDispatch();

	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const dayPlans = useSelector((state) => state.ui_states.day_plans) || [];
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const inspirationPlaceNames =
		useSelector((state) => state.ui_states.inspirationPlaceNames) || [];

	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const loadingState = useSelector((state) => state.ui_states.loadingState);

	const activeItineraryDayTab =
		useSelector((state) => state.ui_states.activeItineraryDayTab) || 0;
	const setActiveItineraryDayTab = (index) => {
		dispatch({ type: 'UPDATE_ACTIVE_ITINERARY_DAY_TAB', payload: index });
	};
	const [activeMarker, setActiveMarker] = useState(null);
	const [view, setView] = useState('list');
	const [addActivityDetails, setAddActivityDetails] = useState({});

	const curDayPlanResults = dayPlans[active_tab_index] || [];

	const { itineraryLoading = LoadingState.INIT } = loadingState;
	const handleViewChange = (event, nextView) => {
		if (nextView !== null) {
			setView(nextView);
		}
	};

	const handleAddInspirationDrawer = (value) => {
		dispatch({
			type: 'UPDATE_ADD_INSPIRATION_DRAWER',
			payload: {
				show: value,
				source: InspirationDrawerSource.CHAT,
			},
		});

		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_ADD_INSPIRATION,
			chat_session_id: chatSessionId,
		});
	};

	const allDates = curDayPlanResults.map(({ DATE }) => DATE);

	const dayItinerary = curDayPlanResults?.[activeItineraryDayTab] || {};
	const { DATE = '', location = '' } = dayItinerary;
	const schedule = dayItinerary.SCHEDULE;
	const places = schedule?.map((activity) => activity.PLACE_NAME);
	const formattedGMapsData = getFormattedGMapsData(places, gMapsData);

	const { addedDayCardsDataList, inspiredDayCardsDataList } =
		getUpdatedAndRemovedDayCardsData(
			curDayPlanResults,
			curDayPlanResults,
			gMapsData,
			inspirationPlaceNames,
		);

	const getItineraryWithMap = () => {
		const { SCHEDULE = [] } = dayItinerary;
		const dayCardsDataList = convertScheduleToItineraryDayCardsData(SCHEDULE);
		updateDayCardsDataListWithNewTags(
			dayCardsDataList,
			addedDayCardsDataList,
			inspiredDayCardsDataList,
		);

		if (!allDates.length) {
			return null;
		}
		return (
			<div className="flex flex-1 gap-3 w-full overflow-hidden">
				<div className="w-5/12 ml-4 flex flex-col flex-1">
					<ItineraryHeader
						setActiveTab={setActiveItineraryDayTab}
						activeTab={activeItineraryDayTab}
						isLast={curDayPlanResults.length - 1 === activeItineraryDayTab}
						isFirst={activeItineraryDayTab === 0}
						dayPlan={dayItinerary}
					/>
					{/* <div>
							<div className="flex flex-row space-x-2">
								<div className="font-medium text-secondary_highlight">
									Day {activeTab + 1}
								</div>
								<div>•</div>
								{DATE && <div>{formatDate(DATE, 'MMM dd')}</div>}
							</div>
							<div className="font-medium">{location}</div>
						</div> */}
					{schedule && (
						<ItineraryDayCards
							dayCardsDataList={dayCardsDataList}
							onPlaceClicked={setActiveMarker}
							dayIndex={activeItineraryDayTab}
							sparkEnabled
							highlightCardEnabled
							formattedGMapsData={formattedGMapsData}
							places={places}
						/>
					)}
				</div>
				<div
					className="w-7/12 overflow-hidden h-full pr-2"
					style={{ position: 'relative', width: 'calc(58.33% - 30px)' }}
				>
					<div className="h-full rounded-3xl overflow-hidden">
						{Object.keys(formattedGMapsData).length && (
							<MapComponent
								gMapsDataMap={formattedGMapsData}
								activeMarker={activeMarker}
								setActiveMarker={setActiveMarker}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	const getHorizontalItineraryView = () => {
		if (isEmpty(curDayPlanResults)) {
			return null;
		}

		return (
			<div
				className="flex flex-row overflow-auto space-x-8 h-full"
				style={{ width: '100%' }}
			>
				{curDayPlanResults?.map((dayPlan, index) => {
					const { DATE, SCHEDULE = [], location = '' } = dayPlan;
					const dayCardsDataList =
						convertScheduleToItineraryDayCardsData(SCHEDULE);

					updateDayCardsDataListWithNewTags(
						dayCardsDataList,
						addedDayCardsDataList,
						inspiredDayCardsDataList,
					);
					return (
						<div
							className="h-full space-y-2"
							style={{
								width: 'calc(100% / 3.5)',
								minWidth: 'max(350px, calc(100% / 3.5))',
							}}
							key={`Horizontal Itinerary col: ${index}`}
						>
							<div>
								<div className="flex flex-row space-x-2">
									<div className="font-medium text-secondary_highlight">
										Day {index + 1}
									</div>
									<div>•</div>
									{DATE && <div>{formatDate(DATE, 'MMM dd')}</div>}
								</div>
								<div className="font-medium">{location}</div>
							</div>
							<ItineraryDayCards
								dayCardsDataList={dayCardsDataList}
								dayIndex={index}
								sparkEnabled
								highlightCardEnabled
							/>

							<div
								role="presentation"
								className="flex items-center cursor-pointer mt-2 ml-4 font-semibold gap-2"
								style={{ color: '#7750E5' }}
								onClick={() => {
									setAddActivityDetails({ location, index });
								}}
							>
								+ Add Activity
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className="flex flex-col flex-1 w-full gap-6 overflow-hidden h-full">
			<div className="flex justify-between items-center mr-6">
				<div
					className="flex gap-1 items-center cursor-pointer"
					role="presentation"
					onClick={() => setCurrentView(MainTripPageView.OVERVIEW)}
				>
					<ArrowBackIosIcon fontSize="small" o />
					<div className="text-2xl flex-1 font-semibold">Itinerary</div>
				</div>
				<div className="flex items-center gap-4">
					<div className={`rounded-3xl ${styles.shadow}`}>
						<AddInspirationButton
							onAddInspirationClicked={() => handleAddInspirationDrawer(true)}
							tiktokText="Add Inspiration"
							igText="Add Inspiration"
							smallerVersion
						/>
					</div>
					<ToggleButtonGroup value={view} exclusive onChange={handleViewChange}>
						<ToggleButton value="list" aria-label="list">
							<ListIcon />
						</ToggleButton>
						<ToggleButton value="map" aria-label="map">
							<MapIcon />
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>

			<Drawer
				anchor="right"
				open={!isEmpty(addActivityDetails)}
				onClose={() => setAddActivityDetails({})}
				PaperProps={{
					sx: { width: 'calc(30% - 22px)', minWidth: '300px' },
				}}
			>
				<AddActivity
					addActivityDetails={addActivityDetails}
					setAddActivityDetails={setAddActivityDetails}
				/>
			</Drawer>

			{view === 'list' ? getHorizontalItineraryView() : getItineraryWithMap()}

			{isEmpty(curDayPlanResults) &&
			tabChanged &&
			itineraryLoading !== LoadingState.LOADING ? (
				<div className="font-normal text-red-900 w-full text-center">
					<TabChangeInfoComponent
						handleUpdateHotelsAndItinerary={handleUpdateHotelsAndItinerary}
					/>
				</div>
			) : null}
		</div>
	);
}

export default DayPlans;

/* eslint-disable no-inner-declarations */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapComponent from '@commons/MapComponent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import ItineraryDayCards from '@commons/Itinerary/ItineraryDayCards';
import SendIcon from '@mui/icons-material/Send';
import {
	getFormattedGMapsData,
	getAllPlacesDataFromContentPayloads,
} from '@utils/DayCardUtils';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { maybePopulateMessage } from '@utils/DemoMessageHotkeyUtils';
import { getContentUsingId, getContentUsingUrl } from '@utils/InspirationUtils';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import IconButton from '@mui/material/IconButton';
import { checkLink } from '@utils/LinkUtils';
import { InspirationDrawerSource } from '@helpers/Enums';
import CardsLoadingComponent from '../../components/SearchResultsV1/OverallResults/CardsLoadingComponent';
import InstagramComponent from './InstagramComponent';
import { InspirationLoadingDots } from './InspirationLoadingDots';

const TEXT_INPUT_PLACEHOLDER = '(Optional) Add these on Day 3';
const LANDING_PAGE_TEXT_INPUT_PLACEHOLDER = 'Describe your trip...';

const filterObject = (obj, predicate) => {
	return Object.fromEntries(
		Object.entries(obj).filter(([key, value]) => predicate(key, value)),
	);
};

export const LoadingState = {
	LOADING_URL: 'LOADING_URL',
	LOADING_PLACES: 'LOADING_PLACES',
	LOADED: 'LOADED',
};

export const AddInspirationLoadingState = {
	VALIDATING: 'VALIDATING',
	ERROR: 'ERROR',
	LOADED: 'LOADED',
};

export default function ViewInspiration({
	initialContentIds,
	onBack,
	onNext,
	state = {},
	onStateChange = () => {},
	source,
}) {
	const dispatch = useDispatch();

	const { gMapsData = {} } = useSelector(({ ui_states = {} }) => ({
		gMapsData: ui_states.gMapsData,
	}));
	const selectedInspirationData =
		useSelector((reduxState) => reduxState.ui_states.selectedInspirationData) ||
		{};
	const selectedInspirationDataLoadingState =
		useSelector(
			(reduxState) => reduxState.ui_states.selectedInspirationDataLoadingState,
		) || {};

	const [contentIds, setContentIds] = useState(
		state.contentIds || initialContentIds,
	);
	const [activeMarker, setActiveMarker] = useState(null);
	const [additionalInstruction, setAdditionalInstruction] = useState(
		state.additionalInstruction || '',
	);
	const [contentPayloads, setContentPayloads] = useState(
		filterObject(selectedInspirationData, (key) => contentIds.includes(key)),
	);
	const loadingStates = filterObject(
		selectedInspirationDataLoadingState,
		(key) => contentIds.includes(key),
	);
	const [addInspirationInputValue, setAddInspirationInputValue] = useState('');
	const [addInspirationLoadingState, setAddInspirationLoadingState] = useState(
		AddInspirationLoadingState.LOADED,
	);
	const [showAll, setShowAll] = useState(false);

	const initialFetchedStates = initialContentIds.reduce((acc, id) => {
		acc[id] = false;
		return acc;
	}, {});
	const contentFetchedRef = useRef(
		state.contentFetched || initialFetchedStates,
	);

	const handleAddInspirationInputChange = (e) => {
		setAddInspirationInputValue(e.target.value);
	};

	const handleAddInspirationClick = () => {
		const url = addInspirationInputValue;
		const linkCheck = checkLink(url);
		if (!linkCheck.isValid) {
			setAddInspirationLoadingState(AddInspirationLoadingState.ERROR);
			return;
		}
		setAddInspirationLoadingState(AddInspirationLoadingState.VALIDATING);
		fetchContentWithUrl(url);
		setAddInspirationInputValue('');
	};

	const hasParticularLoadingState = (targetState) => {
		return Object.values(loadingStates).some((s) => s === targetState);
	};

	const hasAllParticularLoadingState = (targetState) => {
		return Object.values(loadingStates).every((s) => s === targetState);
	};

	const handleAdditionalInstructionChange = (event) => {
		setAdditionalInstruction(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			onNext({
				contentPayloads,
				additionalInstruction,
			});
		}
		maybePopulateMessage(event, setAdditionalInstruction);
	};

	const handleResponse = (contentId, response) => {
		if (response.status === 'success') {
			contentPayloads[contentId] = response.payload;
			if (response.payload?.url) {
				dispatch({
					type: 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE',
					payload: {
						link: contentId,
						data: LoadingState.LOADING_PLACES,
					},
				});
			}
			setContentPayloads(contentPayloads);
		}
	};

	const onFinished = (contentId) => {
		if (contentId != null) {
			dispatch({
				type: 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE',
				payload: {
					link: contentId,
					data: LoadingState.LOADED,
				},
			});
		}
	};

	const fetchContentWithUrl = async (url) => {
		let contentId = null;
		getContentUsingUrl(
			url,
			(response) => {
				if (response.status === 'success') {
					contentId = response.payload.uuid;
					if (!contentIds.includes(contentId)) {
						contentIds.push(contentId);
						setContentIds(contentIds);
					}
					response.payload.url = url;
					handleResponse(contentId, response);
				}
			},
			() => {
				setAddInspirationLoadingState(AddInspirationLoadingState.LOADED);
				onFinished(contentId);
			},
		);
	};

	const getData = async () => {
		if (Object.values(contentFetchedRef.current).includes(false)) {
			const fetchPromises = contentIds
				.map((contentId) => {
					if (
						!contentFetchedRef.current[contentId] &&
						!selectedInspirationData[contentId] &&
						!selectedInspirationDataLoadingState[contentId]
					) {
						contentFetchedRef.current[contentId] = true;

						dispatch({
							type: 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE',
							payload: {
								link: contentId,
								data: LoadingState.LOADING_URL,
							},
						});

						return getContentUsingId(
							contentId,
							(response) => handleResponse(contentId, response),
							() => onFinished(contentId),
						);
					}
					return null;
				})
				.filter((promise) => promise !== null);

			try {
				await Promise.all(fetchPromises);
			} catch (error) {
				console.error('Failed to fetch content:', error);
			}
		}
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		setContentPayloads((prev) => ({
			...prev,
			...filterObject(selectedInspirationData || {}, (key) =>
				contentIds.includes(key),
			),
		}));
	}, [selectedInspirationData]);

	useEffect(() => {
		onStateChange({
			contentIds,
			contentPayloads,
			contentFetchedRef,
			additionalInstruction,
		});
	}, [contentIds, contentPayloads, additionalInstruction]);

	const allPlacesData = getAllPlacesDataFromContentPayloads(
		contentPayloads,
		gMapsData,
	);
	const allPlaceNames = allPlacesData.map((item) => item.placeName);
	const formattedGMapsData = getFormattedGMapsData(allPlaceNames, gMapsData);
	const limitedContent = contentIds.slice(0, 2);
	const contentToShow = showAll ? contentIds : limitedContent;

	return (
		<div className="flex flex-col h-full">
			<div className="flex flex-row justify-between p-4 border-b">
				<div className="flex gap-1 items-center">
					<ArrowBackIosIcon
						className="cursor-pointer"
						fontSize="small"
						onClick={() => onBack()}
					/>
					<div className="text-2xl font-semibold">Adding Inspiration</div>
				</div>
			</div>
			<div className="flex flex-grow w-full overflow-auto mt-4">
				<div
					className="w-2/5 h-full space-y-4 px-4 "
					style={{ minWidth: '428px' }}
				>
					{contentToShow.map((contentId) => {
						const payload = contentPayloads[contentId] || {};
						const { scrape_results = null, summary = {}, url = null } = payload;
						const { places = [] } = summary;
						return url ? (
							<InstagramComponent
								url={url}
								scrape_results={scrape_results}
								collapseEmbed={places.length > 0}
							/>
						) : (
							<CardsLoadingComponent width="100%" height="80px" />
						);
					})}

					{contentIds.length > 2 && !showAll && (
						<div className="flex flex-row justify-center">
							<Button
								variant="outlined"
								style={{
									textTransform: 'unset',
									width: '200px',
									borderRadius: '24px',
								}}
								onClick={() => setShowAll(true)}
							>
								{`+ ${(contentIds.length - 2).toString()} more`}
							</Button>
						</div>
					)}

					<div className="relative">
						<TextareaAutosize
							value={addInspirationInputValue}
							onChange={handleAddInspirationInputChange}
							minRows={1}
							maxRows={4}
							placeholder="Add More"
							className="border rounded-lg w-full py-2 px-10 resize-none box-border overflow-y-auto focus:outline-gray-300"
						/>
						<div className="absolute bottom-2 left-1 flex flex-row items-center">
							<IconButton
								size="small"
								disabled={addInspirationInputValue === ''}
							>
								<InsertLinkIcon style={{ color: '#8b5cf6' }} />
							</IconButton>
						</div>
						<div className="absolute bottom-2 right-1 flex flex-row items-center">
							<IconButton
								size="small"
								onClick={handleAddInspirationClick}
								disabled={addInspirationInputValue === ''}
								style={{
									color:
										addInspirationInputValue === '' ? '#b2b2b2' : '#8b5cf6', // Gray when disabled, purple otherwise
								}}
							>
								<SendIcon />
							</IconButton>
						</div>
					</div>
					{addInspirationLoadingState ===
						AddInspirationLoadingState.VALIDATING && <InspirationLoadingDots />}
					{addInspirationLoadingState === AddInspirationLoadingState.ERROR && (
						<div className="font-medium text-red-500">
							Unable to proceed. Please try again
						</div>
					)}
					{allPlacesData.length > 0 && (
						<div className="flex flex-1 flex-col space-y-4 overflow-y-auto mb-4 ">
							<div className="text-m font-medium ">
								Here are the places we found in your inspiration
							</div>
							<ItineraryDayCards
								dayCardsDataList={allPlacesData}
								shouldAnimate
								showMore={false}
							/>
						</div>
					)}
					{hasParticularLoadingState(LoadingState.LOADING_PLACES) && (
						<CardsLoadingComponent width="100%" height="80px" />
					)}
					{hasAllParticularLoadingState(LoadingState.LOADED) &&
						allPlacesData.length === 0 && (
							<div className="text-m font-medium ">
								Unable to find places in the content
							</div>
						)}
				</div>
				<div className="w-3/5 h-full overflow-hidden">
					<div className="h-full rounded-l-3xl overflow-hidden">
						<MapComponent
							gMapsDataMap={formattedGMapsData}
							activeMarker={activeMarker}
							setActiveMarker={setActiveMarker}
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-row p-4 space-x-4 upward-shadow">
				<TextareaAutosize
					minRows={1}
					maxRows={4}
					placeholder={
						source === InspirationDrawerSource.LANDING_PAGE
							? LANDING_PAGE_TEXT_INPUT_PLACEHOLDER
							: TEXT_INPUT_PLACEHOLDER
					}
					value={additionalInstruction}
					onChange={handleAdditionalInstructionChange}
					onKeyDown={handleKeyDown}
					className="w-full p-2 overflow-y-auto focus:outline-none border rounded-lg flex-grow"
				/>
				<Button
					variant="contained"
					type="button"
					className={
						hasAllParticularLoadingState(LoadingState.LOADED)
							? 'gradient-button '
							: 'disabled-button'
					}
					style={{
						textTransform: 'unset',
						width: '200px',
					}}
					// eslint-disable-next-line react/jsx-boolean-value
					// disabled={
					// 	!contentPayloads || Object.keys(contentPayloads).length === 0
					// }
					onClick={() =>
						onNext({
							contentPayloads,
							additionalInstruction,
						})
					}
				>
					{source === InspirationDrawerSource.LANDING_PAGE
						? 'Create Trip'
						: 'Next'}
				</Button>
			</div>
		</div>
	);
}

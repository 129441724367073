import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadTripStateFromSessionId } from '@helpers/ModelApiUtils';
import { useDispatch, useSelector } from 'react-redux';
import HomePageV1 from './HomePageV1';

function ChatHome() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const navigatingFromLandingPageToChat = useSelector(
		(state) => state.ui_states.navigatingFromLandingPageToChat,
	);

	const profile = useSelector((state) => state.profile);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (id && !navigatingFromLandingPageToChat) {
			loadTripStateFromSessionId(
				dispatch,
				location,
				id,
				navigate,
				profile,
				setLoading,
			);

			return;
		}

		setLoading(false);
	}, [id]);

	useEffect(() => {
		if (id && navigatingFromLandingPageToChat) {
			dispatch({
				type: 'UPDATE_NAVIGATING_FROM_LANDING_PAGE_TO_CHAT',
				payload: false,
			});
		}
	}, [navigatingFromLandingPageToChat, id]);

	if (loading) {
		return (
			<div
				className="flex items-center justify-center"
				style={{ width: '100vw', height: '100vh' }}
			>
				<iframe
					title="loading"
					src="https://lottie.host/embed/62506393-cbfc-46ef-92d4-fa6d75c1f263/9NW0xlIQGn.lottie"
					width={200}
					height={200}
				/>
			</div>
		);
	}
	return <HomePageV1 pageId={1} />;
}

export default ChatHome;

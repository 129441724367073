import { supportedUpdateTypesToPostActions } from '@helpers/TripSaveAndLoadHelper';

export const backendURL = process.env.REACT_APP_BACKEND_URL;

export const saveTripMiddleware = (store) => (next) => (action) => {
	const result = next(action);
	const actionsToSave = ['CHECKPOINT'];

	if (actionsToSave.includes(action.type)) {
		const updateType = action.payload;
		if (Object.keys(supportedUpdateTypesToPostActions).includes(updateType)) {
			const currentState = store.getState();
			updateTripOnServer({
				...currentState,
				ui_states: {
					...currentState.ui_states,
					flight_results: null,
					myTrips: [],
					gMapsData: {},
				},
				checkpoint: updateType,
			});
		}
	}

	return result;
};

export function updateTripOnServer(reduxState) {
	const sessionId = reduxState?.ui_states?.chatSessionId;
	if (sessionId) {
		const postFormData = new FormData();
		postFormData.append('chat_session_id', sessionId);
		postFormData.append('redux_state', JSON.stringify(reduxState));

		fetch(`${backendURL}/api/update_trip`, {
			method: 'POST',
			body: postFormData,
			timeout: 10000,
		}).catch((error) => console.error('Error:', error));
	}
}

import { useDispatch, useSelector } from 'react-redux';

import { LoadingState, MainTripPageView } from '@helpers/Enums';
import {
	handleFindFlights,
	getFlightQueryFromTripTransitions,
} from '@helpers/FlightsQueryHelper';
import TooltipWrapper from '@helpers/TooltipWrapper';
import { useLocation } from 'react-router-dom';
import airportsMap from '@helpers/TooltipHelper';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import Logos from './Logos';
import StopsInfo from './StopsInfo';
import CardsLoadingComponent from './CardsLoadingComponent';
import styles from './styles.module.css';

const printNumStops = (stops) => {
	if (stops === 0) {
		return 'Nonstop';
	}
	if (stops === 1) {
		return '1 stop';
	}
	return `${stops} stops`;
};

function FlightWidgets({ setCurrentView = () => {} }) {
	const dispatch = useDispatch();
	const location = useLocation();

	const flight_results =
		useSelector((state) => state.ui_states.flight_results) || [];
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const isTripFullyLoaded = useSelector(
		(state) => state.ui_states.isTripFullyLoaded ?? true,
	);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const loadingState = useSelector((state) => state.ui_states.loadingState);
	const selectedFlightByDateOption = useSelector(
		(state) => state.ui_states.selectedFlightByDateOption,
	);

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const { travelSets = [], transitionReqs } = tripTransitionsDetails;

	const isFlightTransportationPresent = transitionReqs.includes('flight');

	const { flightsLoading = LoadingState.INIT } = loadingState;
	const { legs = [], totalPrice = 0 } =
		selectedFlightByDateOption[activeTabIndex] ?? {};

	const formatDate = (inputDate) => {
		const [year, month, day] = inputDate.split('-').map(Number);
		const dateObject = new Date(year, month - 1, day);
		const options = { weekday: 'short', month: 'short', day: 'numeric' };
		return dateObject.toLocaleDateString('en-US', options);
	};

	if (
		flightsLoading === LoadingState.INIT ||
		!isTripFullyLoaded ||
		!isFlightTransportationPresent
	) {
		return null;
	}

	const handleShowTripControls = () => {
		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_TRIP_CONTROLS_FROM_FLIGHTS_SECTION,
			chat_session_id: chatSessionId,
		});
		dispatch({
			type: 'UPDATE_SHOW_TRIP_CONTROLS',
			payload: true,
		});
	};

	const handleRetry = () => {
		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_REFRESH_FLIGHTS,
			chat_session_id: chatSessionId,
		});

		const flightQuery = getFlightQueryFromTripTransitions(
			tripTransitionsDetails,
			tripLocationDetails.numTravellers,
		);
		handleFindFlights({
			flightQuery,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery: {
				locations: {
					startLocation: tripLocationDetails.startLocation,
					endLocation: tripLocationDetails.endLocation,
					places: tripLocationDetails.places,
				},
				travelSets,
				chat_history: llmInteractionHistory,
			},
			shouldUpdateFlightsAndDayPlans: true,
			tripData: {
				NUM_TRAVELLERS: tripLocationDetails.numTravellers,
				hotelFilters: tripLocationDetails.hotelFilters,
				travelSets,
				LOCATIONS: tripLocationDetails.places,
				HOTEL_REQUIREMENT: tripLocationDetails.hotelReqs,
				hotelSortCriterion,
			},
			sortOption: flightSortOption,
			gMapsData,
			curActiveTabIndex: activeTabIndex,
		});
	};

	const noFlights =
		flightsLoading === LoadingState.ERROR ||
		(flightsLoading === LoadingState.LOADED &&
			!Object.keys(selectedFlightByDateOption[activeTabIndex] ?? {}).length &&
			flight_results[activeTabIndex]);

	const loadFlights =
		flightsLoading === LoadingState.LOADED &&
		!Object.keys(selectedFlightByDateOption[activeTabIndex] ?? {}).length &&
		!flight_results[activeTabIndex];

	return (
		<div className="flex flex-col gap-4">
			<div className="flex justify-between">
				<div className="font-semibold text-2xl flex items-center gap-3">
					Flights :
					{(flightsLoading === LoadingState.LOADED ||
						flightsLoading === LoadingState.LOADING) &&
						!noFlights && (
							<div
								className="flex gap-2 items-end"
								style={{ color: '#7750E5', fontWeight: '500' }}
							>
								<div>
									{flightsLoading === LoadingState.LOADING
										? 'Searching...'
										: `$${parseInt(totalPrice, 10)}`}
								</div>
								{flightsLoading !== LoadingState.LOADED ? null : (
									<div className="text-sm pb-[3px]">/person</div>
								)}
							</div>
						)}
				</div>

				{flightsLoading === LoadingState.LOADED &&
				!!flight_results[activeTabIndex] ? (
					<div
						className="flex gap-2 items-center cursor-pointer text-highlight font-medium"
						role="presentation"
						onClick={() => {
							sendMessageEvent({
								event: GoogleEventTypes.OVERVIEW_PAGE_SEE_ALL_FLIGHTS,
								chat_session_id: chatSessionId,
							});
							setCurrentView(MainTripPageView.FLIGHTS);
						}}
					>
						Edit
					</div>
				) : null}

				{flightsLoading === LoadingState.LOADED &&
				!flight_results[activeTabIndex] ? (
					<div
						className="flex gap-2 items-center cursor-pointer text-highlight font-medium"
						role="presentation"
						onClick={handleRetry}
					>
						Refresh to Edit
					</div>
				) : null}
			</div>

			{noFlights ? (
				<div>
					Unable to find flights. Apologies for the inconvenience. The beta
					version can make some mistakes. Click{' '}
					{
						<span
							className="text-primary-600 cursor-pointer"
							onClick={handleRetry}
							role="presentation"
						>
							here
						</span>
					}{' '}
					to retry. If that does not work, please add airports from the{' '}
					{
						<span
							className="text-primary-600 cursor-pointer"
							onClick={() => {
								sendMessageEvent({
									event: GoogleEventTypes.OVERVIEW_PAGE_SEE_ALL_FLIGHTS,
									chat_session_id: chatSessionId,
								});

								setCurrentView(MainTripPageView.FLIGHTS);
							}}
							role="presentation"
						>
							Edit Flights
						</span>
					}{' '}
					page or change the modes of transport from the{' '}
					{
						<span
							className="text-primary-600 cursor-pointer"
							onClick={handleShowTripControls} // TODO : add event
							role="presentation"
						>
							Edit Trip
						</span>
					}{' '}
					page.
				</div>
			) : null}

			{loadFlights ? (
				<div>
					The flights are not loaded for this date option. Apologies for the
					inconvenience. Please Click{' '}
					{
						<span
							className="text-primary-600 cursor-pointer"
							onClick={handleRetry}
							role="presentation"
						>
							here
						</span>
					}{' '}
					to retry. If that does not work, please add airports from the{' '}
					{
						<span
							className="text-primary-600 cursor-pointer"
							onClick={() => {
								sendMessageEvent({
									event: GoogleEventTypes.OVERVIEW_PAGE_SEE_ALL_FLIGHTS,
									chat_session_id: chatSessionId,
								});

								setCurrentView(MainTripPageView.FLIGHTS);
							}}
							role="presentation"
						>
							Edit Flights
						</span>
					}{' '}
					page or change the modes of transport from the{' '}
					{
						<span
							className="text-primary-600 cursor-pointer"
							onClick={handleShowTripControls} // TODO : add event
							role="presentation"
						>
							Edit Trip
						</span>
					}{' '}
					page.
				</div>
			) : null}

			{flightsLoading === LoadingState.LOADING && (
				<CardsLoadingComponent height="94px" length={2} />
			)}
			{flightsLoading === LoadingState.LOADED && (
				<div className="flex gap-3 overflow-auto">
					{legs.map((item) => {
						const {
							departureDate = '',
							routes = [],
							departureTime = '',
							source,
							arrivalTime = '',
							destination,
							dateChange,
							duration,
							stops,
						} = item;

						const stopDots = Array.from({ length: stops }, () => (
							<div
								className="relative rounded-lg red-dot mx-1"
								style={{
									backgroundColor: '#DC1F90',
									width: '5px',
									height: '5px',
								}}
							/>
						));

						let airlines = routes.map((route) => route.airline);
						airlines = [...new Set(airlines)].slice(0, 4);

						return (
							<div className={styles.card}>
								<div className="text-gray-500 text-xs">
									{formatDate(departureDate)}
								</div>

								<div className="flex gap-2 mt-2 h-9 w-full">
									<Logos airlines={airlines} />

									<div
										className="flex justify-between"
										style={{ width: '280px' }}
									>
										<div className="flex flex-col" style={{ gap: '2px' }}>
											<div
												className="text-xs font-medium"
												style={{ color: '#301345' }}
											>
												{departureTime}
											</div>
											<div
												className="text-xs font-medium"
												style={{ color: '#442A57' }}
											>
												{source}
											</div>
										</div>

										<div
											className="flex flex-col items-center text-xs"
											style={{ width: '120px', color: '#301345' }}
										>
											<div style={{ fontWeight: 400 }}>{duration}</div>
											<div
												className="flex flex-row items-center my-1 w-full justify-center"
												style={{
													borderBottom: '1px solid #7F6D8B',
													height: '1px',
													width: '132px',
												}}
											>
												{stopDots}
											</div>
											<div className="flex justify-center items-center">
												<div
													className="mx-1"
													style={{ color: stops ? '#DC1F90' : 'green' }}
												>
													{printNumStops(stops)}
												</div>
												<StopsInfo routes={routes} />
											</div>
										</div>

										<div
											className="flex flex-col items-center"
											style={{ gap: '2px' }}
										>
											<div
												className="text-xs font-medium"
												style={{ color: '#301345', marginRight: '-4px' }}
											>
												{arrivalTime}
												<sup
													className={`mb-2 -top-3 ${
														dateChange > 0 ? 'visible' : 'invisible'
													}`}
													style={{ color: '#dc1f90' }}
												>
													{`+${dateChange.toString()}`}
												</sup>
											</div>

											<TooltipWrapper tooltipContent={airportsMap[destination]}>
												<div
													className="text-xs font-medium"
													style={{ color: '#442A57' }}
												>
													{destination}
												</div>
											</TooltipWrapper>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default FlightWidgets;

/* eslint-disable react/no-unescaped-entities */
import ItinerarySingleDayCard from '@commons/ItinerarySingleDayCard/ItinerarySingleDayCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageSlider from '@commons/ImageSlider';
import LocationIcon from '@commons/icons/LocationIcon';
import CheckCircleselected from '@commons/icons/CheckCircleSelected';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { ChatMessageSender } from '@helpers/Enums';
import styles from './styles.module.css';

function AttachmentResponseDrawerOverview({
	attachmentAiResponse = [],
	attachmentCardData = {},
	handleCloseAttachmentDrawer = () => {},
}) {
	const dispatch = useDispatch();

	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const dayPlans = useSelector((state) => state.ui_states.day_plans);

	const curDayPlanResults = dayPlans[active_tab_index] || [];

	const chatMessages = useSelector((state) => state.ui_states.chatMessages);

	const [selectedCard, setSelectedCard] = useState(0);

	const { data = {} } = attachmentCardData || {};

	const { dayIndex, index } = data;

	const processedPlaces = new Set();

	useEffect(() => {
		const promises = []; // This will hold all the fetch promises

		attachmentAiResponse?.forEach((res) => {
			if (
				res.placeName &&
				!gMapsData[res.placeName] &&
				!processedPlaces.has(res.placeName)
			) {
				processedPlaces.add(res.placeName);
				const promise = axios
					.get(`${process.env.REACT_APP_BACKEND_URL}/api/get_photos`, {
						params: { query: res.placeName },
					})
					.then((response) => {
						dispatchUpdate(res.placeName, response.data);
					})
					.catch((error) => {
						console.error('Error fetching place data:', error);
						dispatchUpdate(res.name, {});
					});

				promises.push(promise);
			}
		});

		if (promises.length > 0) {
			Promise.all(promises).finally(() => {
				console.log('All fetch operations are complete.', attachmentAiResponse);
			});
		}

		function dispatchUpdate(placeName, responseData) {
			dispatch({
				type: 'UPDATE_GMAPS_DATA',
				payload: {
					placeName,
					data: responseData,
				},
			});
		}
	}, [attachmentAiResponse, gMapsData, dispatch]);

	const onClickAddToTrip = () => {
		const updatedDayPlans = curDayPlanResults.map((plan, dInd) => {
			if (dInd !== dayIndex) {
				return plan;
			}

			const { SCHEDULE, ...rest } = plan;

			const updatedSchedule = SCHEDULE.map((dPlan, ind) => {
				if (ind !== index + 1) {
					return dPlan;
				}

				return {
					...dPlan,
					NAME: attachmentAiResponse[selectedCard].name,
					PLACE_NAME: attachmentAiResponse[selectedCard].placeName,
					DESCRIPTION:
						attachmentAiResponse[selectedCard]?.description ||
						'Have some amazing local food. Experience wide range of cousine',
					COORDINATES: `${attachmentAiResponse[selectedCard]?.geoCode.latitude}, ${attachmentAiResponse[selectedCard]?.geoCode.longitude}`,
					hasNewTag: true,
				};
			});

			if (index + 1 === SCHEDULE.length) {
				updatedSchedule.push({
					NAME: attachmentAiResponse[selectedCard].name,
					PLACE_NAME: attachmentAiResponse[selectedCard].placeName,
					DESCRIPTION:
						attachmentAiResponse[selectedCard]?.description ||
						'Have some amazing local food. Experience wide range of cousine',
					COORDINATES: `${attachmentAiResponse[selectedCard]?.geoCode.latitude}, ${attachmentAiResponse[selectedCard]?.geoCode.longitude}`,
					hasNewTag: true,
					TIME_START: SCHEDULE[index].TIME_START,
					TIME_END: SCHEDULE[index].TIME_END,
				});
			}

			return { ...rest, SCHEDULE: updatedSchedule };
		});

		dispatch({
			type: 'UPDATE_DAY_PLANS_FOR_DATE_TAB',
			payload: {
				dayPlans: updatedDayPlans,
				tabIndex: active_tab_index,
			},
		});

		const updatedChatMessages = chatMessages.messages;
		updatedChatMessages.push({
			sender: ChatMessageSender.AIRIAL,
			message:
				'I have now updated the planned activities as per your instructions.',
		});

		handleCloseAttachmentDrawer();
	};

	if (!attachmentAiResponse.length) {
		return null;
	}

	const googleData =
		gMapsData[attachmentAiResponse[selectedCard].placeName]?.[0]?.[0] || {};

	const { tags = [] } = attachmentAiResponse[selectedCard] || {};

	const {
		photos_data = [],
		name = '',
		full_address = '',
		description,
	} = googleData;

	const photoData = photos_data.map(({ original_photo_url }, ind) => {
		return {
			src: original_photo_url,
			alt: `Photo_${ind}`,
		};
	});

	return (
		<div className="flex flex-col" style={{ color: '#36174D' }}>
			<div
				className="flex gap-1 items-center cursor-pointer px-6 py-5"
				style={{
					borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
					height: '72px',
				}}
				role="presentation"
			>
				<ArrowBackIosIcon fontSize="small" />

				<div style={{ color: '#301345', fontSize: '20px', fontWeight: 600 }}>
					Restaurants
				</div>
			</div>

			<div className="flex h-full">
				<div
					className="w-1/2 flex flex-col p-6 overflow-auto"
					style={{
						borderRight: '1px solid #7750E5',
						maxHeight: 'calc(100vh - 72px)',
					}}
				>
					<div className="flex gap-2 items-center font-medium">
						<div style={{ color: '#DC1F90' }}>Day {dayIndex + 1}</div>
					</div>

					<div className="mt-6">
						<div className="text-lg">After you visit:</div>

						<ItinerarySingleDayCard dayCardData={data} view="attachment_flow" />

						<div className="text-lg mt-6">
							Here are the best restaurants around:
						</div>

						<div className="flex flex-col gap-4">
							{attachmentAiResponse.map(
								(
									{ name: pName, placeName, geoCode = {}, description: des },
									ind,
								) => {
									return (
										<div
											role="presentation"
											onClick={() => setSelectedCard(ind)}
											className={selectedCard === ind && styles.selected_card}
										>
											<ItinerarySingleDayCard
												dayCardData={{
													name: pName,
													placeName,
													coordinates: `${geoCode.latitude}, ${geoCode.longitude}`,
													description:
														des ||
														gMapsData[placeName]?.[0]?.[0]?.description ||
														'Traditional tapas are served at this vibrant hangout',
												}}
												view="attachment_flow"
												selectedCard={selectedCard === ind}
											/>
										</div>
									);
								},
							)}
						</div>
					</div>
				</div>
				<div className="w-1/2 p-6 flex flex-col gap-5">
					<ImageSlider data={photoData} height="330px" width="100%" />
					<div className="font-semibold text-xl">{name}</div>

					<div className="flex gap-2 items-center">
						<LocationIcon stroke="#7750E5" width="16" height="16" />

						<div>{full_address}</div>
					</div>

					<div className="flex gap-4 items-center">
						{tags.map((tag, ind) => {
							if (ind > 2) {
								return null;
							}

							return (
								<div
									key={tag}
									className="px-3 py-2 font-medium flex gap-2"
									style={{
										border: '1px solid #F0ECFC',
										background: '#F0ECFC',
										borderRadius: '20px',
									}}
								>
									{tag}

									<CheckCircleselected />
								</div>
							);
						})}
					</div>

					<div>{description}</div>

					<Button
						type="button"
						className={styles.add_to_trip_button}
						onClick={onClickAddToTrip}
					>
						<AddIcon /> Add to trip
					</Button>
				</div>
			</div>
		</div>
	);
}

export default AttachmentResponseDrawerOverview;

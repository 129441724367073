/* eslint-disable no-inner-declarations */

const handleStreamResponse = (response, handleResponse, onFinished) => {
	if (response.ok) {
		const reader = response.body.getReader();
		let accumulatedText = '';

		function readChunk() {
			reader.read().then(({ done, value }) => {
				if (done) {
					// Handle the final accumulated text here
					handleFinalChunk(accumulatedText);
					return;
				}

				const chunkText = new TextDecoder().decode(value, {
					stream: true,
				});
				accumulatedText += chunkText;

				// Process complete JSON objects in accumulatedText
				processAccumulatedText();

				readChunk();
			});
		}

		function processAccumulatedText() {
			// Try to find complete JSON objects and process them
			let braceCount = 0;
			let startIndex = 0;

			for (let i = 0; i < accumulatedText.length; i += 1) {
				const char = accumulatedText[i];

				if (char === '{') {
					braceCount += 1;
				} else if (char === '}') {
					braceCount -= 1;
					if (braceCount === 0) {
						// Found a complete JSON object
						const jsonStr = accumulatedText.substring(startIndex, i + 1);
						try {
							const parsedJSON = JSON.parse(jsonStr);
							handleResponse(parsedJSON);
						} catch (e) {
							console.error('Parsing failed for chunk', jsonStr);
							// Handle parsing error
						}
						startIndex = i + 1;
					}
				}
			}

			// Keep the remaining incomplete part
			accumulatedText = accumulatedText.substring(startIndex);
		}

		function handleFinalChunk() {
			// Process any remaining JSON objects in the final chunk
			processAccumulatedText();
			if (accumulatedText.length > 0) {
				console.error('Final chunk contains incomplete JSON', accumulatedText);
				// Handle incomplete JSON in final chunk
			}
			onFinished();
		}

		readChunk();
	}
};

export const getContentUsingId = async (
	contentId,
	handleResponse,
	onFinished,
) => {
	try {
		const backendURL = process.env.REACT_APP_BACKEND_URL;

		const postFormData = new FormData();
		postFormData.append('id', contentId);
		await fetch(`${backendURL}/api/id_map_content`, {
			method: 'POST',
			body: postFormData,
		})
			.then((response) => {
				handleStreamResponse(response, handleResponse, onFinished);
			})
			.catch((error) => {
				console.log(error);
			});
	} catch (error) {
		console.log('error', error);
	}
};

export const getContentUsingUrl = async (url, handleResponse, onFinished) => {
	try {
		const backendURL = process.env.REACT_APP_BACKEND_URL;

		const postFormData = new FormData();
		postFormData.append('link', url);
		await fetch(`${backendURL}/api/map_content`, {
			method: 'POST',
			body: postFormData,
		})
			.then((response) => {
				handleStreamResponse(response, handleResponse, onFinished);
			})
			.catch((error) => {
				console.log(error);
			});
	} catch (error) {
		console.log('error', error);
	}
};

import { useSelector } from 'react-redux';
import { LoadingState, MainTripPageView } from '@helpers/Enums';
import FlightsLoadingComponent from '@commons/FlightsLoadingComponent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { sortFlights } from '@commons/Flights/FlightsUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import ItineraryListComponent from './ItineraryListComponent/ItineraryListComponent';
import CollapsibleFlightFormV1 from './CollapsibleFlightFormV1';
import FiltersSection from './FiltersSection';

const filtersData = ['Stops', 'Airlines', 'Price', 'Times', 'Duration'];

function Flights({ setCurrentView }) {
	const flight_results =
		useSelector((state) => state.ui_states.flight_results) || [];
	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const sort_option = useSelector((state) => state.ui_states.sort_option);
	const loading = useSelector((state) => state.ui_states.loadingState);

	const { flightsLoading = LoadingState.INIT } = loading;

	// if (!flight_results?.length) {
	// 	return null;
	// }

	let tabsData = flight_results
		.filter((item) => !!item)
		.map((f) => ({
			title: f.dateOptionLabel,
			content: f.itineraries,
		}));

	tabsData = sortFlights(tabsData, sort_option);

	return (
		<div className="flex flex-col w-full pr-6">
			<div
				className="flex gap-1 items-center cursor-pointer"
				style={{ marginBottom: '24px' }}
				role="presentation"
				onClick={() => {
					sendMessageEvent({
						event: GoogleEventTypes.CHANGE_VIEW_FROM_FLIGHTS_TO_MAIN_PAGE,
						chat_session_id: chatSessionId,
					});

					setCurrentView(MainTripPageView.OVERVIEW);
				}}
			>
				<ArrowBackIosIcon fontSize="small" />

				<div style={{ color: '#301345', fontSize: '20px', fontWeight: 600 }}>
					Flights
				</div>
			</div>

			<CollapsibleFlightFormV1 />

			<div className="flex flex-row w-full justify-between">
				<FiltersSection filtersData={filtersData} tabsData={tabsData} />
			</div>

			{flightsLoading !== LoadingState.LOADING ? (
				<ItineraryListComponent
					itineraries={tabsData[active_tab_index]?.content}
					flightsLoading={flightsLoading}
				/>
			) : (
				<FlightsLoadingComponent />
			)}
		</div>
	);
}

export default Flights;

function isValidHttpUrl(string) {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}

	return url.protocol === 'http:' || url.protocol === 'https:';
}

export function checkLink(link) {
	// Remove / at the end of link if present
	link = link.replace(/\/$/, '');

	const instagramPattern = /instagram\.com/i;
	const tiktokPattern = /tiktok\.com/i;

	if (!isValidHttpUrl(link)) {
		return { isValid: false, isInstagram: false, isTikTok: false };
	}

	const isInstagram = instagramPattern.test(link);
	const isTikTok = tiktokPattern.test(link);

	return { isValid: true, isInstagram, isTikTok };
}

import { useCallback, useRef, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import axios from 'axios';

const customStyles = {
	control: (provided, state) => ({
		...provided,
		border: 'none',
		boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
		borderRadius: state.isFocused ? '8px 8px 0px 0px' : '8px',
		minWidth: '200px',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: 'unset',
		color: state.isSelected ? '#7750E5' : '#92849D',
		cursor: 'pointer',
	}),
	menu: (provided) => ({
		...provided,
		border: 'none',
	}),
};

function AutoCompleteLocationSelect({ handleSelect = () => {}, value }) {
	const debounceTimeout = useRef(null);

	// const [searchTerm, setSearchTerm] = useState('');
	const [showSelectedOption, setShowSelectedOption] = useState(true);

	const handleFocus = () => {
		setShowSelectedOption(false);
	};

	const handleBlur = () => {
		setShowSelectedOption(true);
	};

	const fetchOptions = async (inputValue) => {
		if (!inputValue) {
			return [];
		}

		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/search_place`,
				{
					params: {
						q: inputValue,
						limit: 5,
					},
				},
			);

			const options = response.data.features.map((feature) => {
				const {
					name,
					state,
					countrycode = '',
					country = '',
				} = feature.properties;

				const { coordinates = [] } = feature.geometry;

				if (state && countrycode === 'US') {
					return { place: `${name}, ${state}`, coordinates };
				}

				if (country) {
					return { place: `${name}, ${country}`, coordinates };
				}

				return { place: name, coordinates };
			});

			const filteredPlaces = [...new Set(options)];

			return filteredPlaces.map(({ place, coordinates }) => ({
				value: place,
				label: place,
				coordinates,
			}));
		} catch (error) {
			return [];
		}
	};

	const debouncedFetchOptions = useCallback((inputValue, callback) => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}
		debounceTimeout.current = setTimeout(() => {
			fetchOptions(inputValue).then(callback);
		}, 600);
	}, []);

	return (
		<AsyncCreatableSelect
			styles={customStyles}
			placeholder=" Type City Name Here..."
			loadOptions={debouncedFetchOptions}
			// onInputChange={setSearchTerm}
			onChange={(val) => {
				setShowSelectedOption(true);
				handleSelect(val);
			}}
			value={showSelectedOption && !!value && { label: value, value }}
			defaultOptions={!!value && [{ label: value, value }]}
			cacheOptions
			onFocus={handleFocus}
			onBlur={handleBlur}
		/>
	);
}

export default AutoCompleteLocationSelect;

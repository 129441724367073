function FlightsLoadingComponent() {
	return (
		<div className="flex flex-col space-y-8 mt-4">
			{Array(4)
				.fill(null)
				.map(() => (
					<div className="bg-white rounded-lg shadow-lg space-y-2">
						<div className="flex flex-row w-full p-4">
							<div className="flex flex-col flex-5 space-y-4">
								<div className="flex flex-grow shimmer h-4 w-14 rounded-md" />
								<div className="flex flex-row items-center">
									<div className="flex w-10 h-10 shimmer rounded-md" />
									<div className="flex flex-grow shimmer h-8 rounded-md mx-8" />
								</div>
							</div>
							<div className="flex flex-col flex-3 items-center justify-center space-y-4">
								<div className="flex shimmer h-4 w-6 rounded-md" />
								<div className="flex shimmer h-4 w-12 rounded-md" />
							</div>
						</div>
					</div>
				))}
		</div>
	);
}

export default FlightsLoadingComponent;

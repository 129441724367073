import axios from 'axios';
import { parse, Allow } from 'partial-json';

export const fetchScrapeBatch = (
	contentUrls,
	dispatch,
	onFinished = () => {},
) => {
	if (contentUrls.length > 0) {
		fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/get_scrape_from_url_batch`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ urls: contentUrls }),
				timeout: 10000,
			},
		)
			.then((response) => {
				handleStreamResponse(response);
			})
			.catch((error) => {
				// console.error('Error fetching place data:', error);
			})
			.finally(() => {
				onFinished();
			});
	}

	const handleStreamResponse = (response) => {
		if (response.ok) {
			const reader = response.body.getReader();
			let accumulatedChunks = '';

			// eslint-disable-next-line no-inner-declarations
			function readChunk() {
				reader.read().then(({ done, value }) => {
					if (done) {
						return;
					}

					const chunkText = new TextDecoder().decode(value);
					if (chunkText === 'ERROR') {
						return;
					}
					accumulatedChunks += chunkText;
					const parsedJSON = parse(accumulatedChunks, Allow.ARR);
					if (parsedJSON) {
						parsedJSON.forEach((result) => {
							dispatchUpdate(result.url, result.data);
						});
					}
					readChunk(); // Recursively read the next chunk
				});
			}

			readChunk(); // Start reading chunks
		}
	};

	function dispatchUpdate(contentUrl, data) {
		dispatch({
			type: 'UPDATE_DISCOVER_INSPIRATION_SCRAPE_RESULTS',
			payload: {
				link: contentUrl,
				data: data || {},
			},
		});
	}
};

const discoverInspirationHelper = async ({ places, dispatch = () => {} }) => {
	const tabNames = places.map((place) => place.split(',')[0]);

	const fetchUrls = tabNames.map(async (placeName) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/discover_inspiration`,
				{
					params: { place: placeName },
				},
			);

			dispatch({
				type: 'UPDATE_DISCOVER_INSPIRATION_URLS',
				payload: {
					placeName,
					data: response?.data || [],
				},
			});

			fetchScrapeBatch(response?.data || [], dispatch);
		} catch (error) {
			dispatch({
				type: 'UPDATE_DISCOVER_INSPIRATION_URLS',
				payload: {
					placeName,
					data: [],
				},
			});
		}
	});

	Promise.all(fetchUrls).finally(() => {
		console.log('All fetch operations are complete.');
	});
};

export default discoverInspirationHelper;

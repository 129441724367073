function RightIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
		>
			<path
				d="M8.33301 16.334L13.6663 11.0007"
				stroke="#7750E5"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M8.33301 5.66699L13.6663 11.0003"
				stroke="#7750E5"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default RightIcon;

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useDayPlansQuery from '@hooks/dayPlansQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ItineraryDayCards from '@commons/Itinerary/ItineraryDayCards';
import { fetchDayPlans } from '@helpers/dayPlansQueryHelper';
import {
	convertScheduleToItineraryDayCardsData,
	getAllPlacesDataFromContentPayloads,
} from '@utils/DayCardUtils';
import { formatDate } from '@helpers/DateUtils';
import Button from '@mui/material/Button';
import {
	getUpdatedAndRemovedDayCardsData,
	updateDayCardsDataListWithNewTags,
} from '@utils/DayPlansUtils';
import LoadingDots from '@commons/LoadingDots';
import {
	ChatMessageSender,
	ChatMessageAttachmentType,
	TripUpdateType,
} from '@helpers/Enums';
import {
	dispatchAddChatMessage,
	getDefaultInspirationMessage,
} from '@helpers/ChatUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { Skeleton } from '@mui/material';
import isEmpty from '@utils/isEmpty';
import ItineraryDiffComponent from './ItineraryDiffComponent';
import InspirationItineraryTabs from './InspirationItineraryTabs';

const TAB_NAMES = ['Updated Itinerary', 'Old Itinerary', 'Changes'];

export default function InspirationItinerary({
	contentIds,
	onBack,
	onClose,
	inspirationData = {},
}) {
	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false);
	const { dayPlansQuery } = useDayPlansQuery(contentIds);

	const originalDayPlans =
		useSelector((state) => state.ui_states.day_plans) || [];
	const gMapsData = useSelector((state) => state.ui_states.gMapsData) || [];
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const curDayPlanResults = originalDayPlans[active_tab_index] || [];

	const [updatedDayPlans, setUpdatedDayPlans] = useState([]);
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const allPlacesData = getAllPlacesDataFromContentPayloads(
		inspirationData.contentPayloads,
		gMapsData,
	);
	const inspirationPlaceNames = allPlacesData.map((item) => item.placeName);

	const handleStreamResponse = (response) => {
		setUpdatedDayPlans(response?.DAYS || []);
	};

	const getData = async () => {
		if (updatedDayPlans !== [] && !dataFetchedRef.current) {
			dataFetchedRef.current = true;
			try {
				await fetchDayPlans(
					dispatch,
					dayPlansQuery,
					chatSessionId,
					handleStreamResponse,
				);
			} catch (error) {
				console.log('error', error);
			}
		}
	};

	useEffect(() => {
		getData();
	}, []);

	let dayPlans = updatedDayPlans;
	if (activeTabIndex === 1) {
		dayPlans = curDayPlanResults;
	}

	const onUpdateItinerary = () => {
		dispatch({
			type: 'UPDATE_INSPIRATION_PLACE_NAMES',
			payload: inspirationPlaceNames,
		});
		dispatch({
			type: 'UPDATE_DAY_PLANS_FOR_DATE_TAB',
			payload: {
				dayPlans: updatedDayPlans,
				tabIndex: active_tab_index,
			},
		});

		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_INSPIRATION_CONFIRMED,
			chat_session_id: chatSessionId,
		});

		const attachmentData = Object.entries(inspirationData.contentPayloads).map(
			([, item]) => {
				const { scrape_results = null, url = null } = item;
				return {
					url,
					scrape_results,
				};
			},
		);
		const urls = Object.entries(inspirationData.contentPayloads)
			.map(([, item]) => item?.url)
			.filter((url) => !!url);

		const userMessage = {
			sender: ChatMessageSender.USER,
			message: getDefaultInspirationMessage(urls),
			attachment: {
				attachmentType: ChatMessageAttachmentType.INSPIRATION,
				attachmentData,
			},
		};
		const airialMessage = {
			sender: ChatMessageSender.AIRIAL,
			message: "I've updated your itinerary with the content you provided.",
		};
		dispatchAddChatMessage(
			dispatch,
			TripUpdateType.ITINERARY_INSPIRATION_UPDATE_COMPLETE,
			{
				messages: [userMessage, airialMessage],
				isTyping: false,
			},
		);
		onClose();
	};

	const loadingUpdatedDayPlans = activeTabIndex === 0 && dayPlans.length === 0;

	const {
		addedDayCardsDataList,
		removedDayCardsDataList,
		inspiredDayCardsDataList,
	} = getUpdatedAndRemovedDayCardsData(
		updatedDayPlans,
		curDayPlanResults,
		gMapsData,
		inspirationPlaceNames,
	);

	return (
		<div className="flex flex-col space-y-4 h-full px-4">
			<div className="flex flex-row justify-between py-4 border-b">
				<div className="flex gap-1 items-center">
					<ArrowBackIosIcon
						className="cursor-pointer"
						fontSize="medium"
						onClick={() => onBack()}
					/>
					<div className="text-2xl font-semibold">Add Inspiration</div>
				</div>
			</div>
			<div className="flex flex-1 flex-col space-y-4 h-full overflow-auto">
				<InspirationItineraryTabs
					tabNames={TAB_NAMES}
					activeTabIndex={activeTabIndex}
					setActiveTabIndex={setActiveTabIndex}
				/>
				{activeTabIndex <= 1 && (
					<div
						className="flex flex-row flex-1 overflow-auto space-x-4"
						style={{ width: '100%' }}
					>
						{loadingUpdatedDayPlans && (
							<div className="flex space-x-2">
								<div className="text-m font-medium">
									Updating your Itinerary{' '}
								</div>
								<LoadingDots />
							</div>
						)}
						{dayPlans?.map((dayPlan, index) => {
							const { DATE, SCHEDULE = [] } = dayPlan;

							const PLACE_NAME_SPLIT = (
								SCHEDULE[0]?.PLACE_NAME ||
								SCHEDULE[1]?.PLACE_NAME ||
								''
							).split(', ');

							const LOCATION = PLACE_NAME_SPLIT[PLACE_NAME_SPLIT.length - 1];

							const dayCardsDataList =
								convertScheduleToItineraryDayCardsData(SCHEDULE);
							if (activeTabIndex === 0) {
								updateDayCardsDataListWithNewTags(
									dayCardsDataList,
									addedDayCardsDataList,
									inspiredDayCardsDataList,
								);
							}
							return (
								<div
									className="h-full space-y-2"
									style={{
										width: 'calc(100% / 3.5)',
										minWidth: 'max(350px, calc(100% / 3.5))',
									}}
									key={`Day: ${index.toString()}`}
								>
									<div className="flex flex-col gap-1">
										<div className="flex flex-row space-x-2">
											<div className="font-medium text-secondary_highlight">
												Day {index + 1}
											</div>
											<div>•</div>
											{DATE ? (
												<div>{formatDate(DATE, 'MMM dd')}</div>
											) : (
												<Skeleton width="80px" />
											)}
										</div>
										{LOCATION ? (
											<div className="font-medium">{LOCATION}</div>
										) : (
											<div className="font-medium">No Location</div>
										)}
									</div>
									<ItineraryDayCards
										dayCardsDataList={dayCardsDataList}
										highlightCardEnabled
									/>
								</div>
							);
						})}
					</div>
				)}
				{activeTabIndex === 2 && (
					<ItineraryDiffComponent
						updatedDayPlans={updatedDayPlans}
						originalDayPlans={curDayPlanResults}
						gMapsData={gMapsData}
					/>
				)}
			</div>

			<div className="flex p-4 justify-end w-full upward-shadow">
				<div>
					<Button
						variant="contained"
						type="button"
						className="gradient-button"
						style={{
							textTransform: 'unset',
							width: '300px',
						}}
						onClick={onUpdateItinerary}
					>
						Update Itinerary
					</Button>
				</div>
			</div>
		</div>
	);
}

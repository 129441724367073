import { fetchDayPlansAndDispatch } from '@helpers/dayPlansQueryHelper';

const KEY_TO_MESSAGE_DICT = {
	1: 'Plan a 2-week Spain trip for me and 3 friends.',
	2: 'This should be in September, starting on a Saturday. I want to spend a week visiting coastal towns in southern Spain and 3 days each in Barcelona and Madrid. I will have a car in Spain and will stay with a friend in Barcelona.',
	3: 'I am interested in soccer - want to visit the iconic Barcelona and Madrid stadiums.',
	4: 'https://www.tiktok.com/@threedaytravel/video/7140420095502748934?q=barcelona%20things%20to%20do&t=1713638014041',
	5: 'Can you add a place to eat after this? Something with vegetarian options.',
};

export function maybePopulateMessage(event, populate) {
	if (event.metaKey && KEY_TO_MESSAGE_DICT.hasOwnProperty(event.key)) {
		event.preventDefault();
		populate(KEY_TO_MESSAGE_DICT[event.key]);
	}
}

export async function maybeFetchDayPlans(
	event,
	dispatch,
	dayPlansQuery,
	chatSessionId,
	activeTabIndex,
) {
	if (event.metaKey && event.key === 'i') {
		event.preventDefault();
		await fetchDayPlansAndDispatch(
			dispatch,
			dayPlansQuery,
			chatSessionId,
			activeTabIndex,
		);
	}
}

export async function maybeTriggerDemoAttachCardFlow(
	event,
	demoAttachCardFlow,
) {
	if (event.metaKey && event.key === 'r') {
		event.preventDefault();
		await demoAttachCardFlow();
	}
}

export const populateCurrentChatMessageWithAnimation = (
	message,
	dispatch,
	shouldAnimate = false,
) => {
	if (!shouldAnimate) {
		dispatch({
			type: 'UPDATE_CURRENT_CHAT_MESSAGE',
			payload: message,
		});
		return;
	}

	let index = 0;

	const intervalId = setInterval(() => {
		if (index < message.length) {
			dispatch({
				type: 'UPDATE_CURRENT_CHAT_MESSAGE',
				payload: message.substring(0, index + 1),
			});
			index += 1;
		} else {
			clearInterval(intervalId);
		}
	}, 25);
};

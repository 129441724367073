import { useState } from 'react';
import FiltersDropdown from './FiltersDropdown';

import './DateTabs.css';
import HotelsSortSelector from './HotelsSortSelector';

function HotelFilters({ POSSIBLE_FILTERS }) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className="flex gap-5 items-center">
			<div className="tabs-container">
				<div className="tabs-scrollable">
					<div
						className="flex flex-row space-x-2 items-center"
						style={{ color: '#301345' }}
					>
						<div className="text-sm">Filters</div>

						{POSSIBLE_FILTERS.map((filterName) => (
							<FiltersDropdown
								key={filterName}
								filterName={filterName}
								setAnchorEl={setAnchorEl}
								anchorEl={anchorEl}
								handleClose={handleClose}
							/>
						))}
					</div>
				</div>
			</div>

			<HotelsSortSelector />
		</div>
	);
}

export default HotelFilters;

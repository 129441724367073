import { useState } from 'react';
import { Typography, Collapse } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import convertToDayObjects from '@utils/convertToDayObjects';
import startCase from '@utils/startCase';
import getNextOpeningTime from '@utils/getNextOpeningTime';

const daysMapping = {
	0: 'sunday',
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thursday',
	5: 'friday',
	6: 'saturday',
};

function getOpenTimesText(open, close, isOpen24Hours) {
	if (isOpen24Hours) {
		return 'Open 24 hours';
	}

	if (open.toLowerCase() === 'closed') {
		return 'Closed';
	}

	return `${open} - ${close}`;
}

function OpeningHours({ openingHours }) {
	const [showMore, setShowMore] = useState(false);

	const { weekday_text = [], isOpen = () => {} } = openingHours;

	const open_now = isOpen() || false;

	const handleShowMore = () => {
		setShowMore(!showMore);
	};

	const today = new Date().getDay();

	if (!weekday_text.length) {
		return null;
	}

	const convertedDays = convertToDayObjects(weekday_text);

	const nextOpeningDay = getNextOpeningTime(convertedDays);

	const { close: closeTime, open: openTime } = convertedDays.find(
		({ day }) => day === daysMapping[today],
	);

	const isOpen24Hours = openTime.includes('24');

	return (
		<div className="mt-2 px-3">
			<Typography variant="body1" gutterBottom>
				<div className="flex items-center text-xs" style={{ gap: '10px' }}>
					<AccessTimeIcon fontSize="small" style={{ color: '#1B72E8' }} />

					{open_now ? (
						<div className="text-xs flex" style={{ gap: '6px' }}>
							<div style={{ color: '#188038' }}>Open</div> ⋅
							{isOpen24Hours ? (
								<div>Open 24 hours</div>
							) : (
								<div>Closes {closeTime}</div>
							)}
						</div>
					) : (
						<div className="text-xs">
							{nextOpeningDay ? (
								<div className="text-xs flex" style={{ gap: '6px' }}>
									<div style={{ color: '#d93025' }}>Closed</div> ⋅
									<div>Opens {nextOpeningDay}</div>
								</div>
							) : (
								'Temparorily Closed'
							)}
						</div>
					)}

					<div
						role="presentation"
						style={{ marginLeft: 'auto', cursor: 'pointer' }}
						onClick={handleShowMore}
					>
						{`Show ${showMore ? 'less' : 'all'}`}
					</div>
				</div>
			</Typography>

			<Collapse in={showMore}>
				<div
					className="text-xs font-normal p-2 rounded-md flex flex-col mt-1"
					style={{
						backgroundColor: 'whitesmoke',
						gap: '3px',
					}}
				>
					{convertedDays.map(({ day, open, close }) => {
						return (
							<div
								key={day}
								className="flex gap-2"
								style={{
									fontWeight: day === daysMapping[today] ? '700' : '400',
								}}
							>
								<div style={{ width: '100px' }}>{startCase(day)}</div>

								<div>{getOpenTimesText(open, close, isOpen24Hours)}</div>
							</div>
						);
					})}
				</div>
			</Collapse>
		</div>
	);
}

export default OpeningHours;

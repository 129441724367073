import dayjs from 'dayjs';
import { useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Drawer from '@mui/material/Drawer';
import EditPencil from '@commons/icons/EditPencil';
import FlightIcon from '@commons/icons/FlightIcon';
import TrainIcon from '@commons/icons/TrainIcon';
import CarIcon from '@commons/icons/CarIcon';
import {
	DirectionsBoatOutlined,
	DirectionsBusOutlined,
} from '@mui/icons-material';
import HailIcon from '@mui/icons-material/Hail';
import DeleteIcon from '@commons/icons/DeleteIcon';
import { handleLookupAirports } from '@helpers/ModelApiUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import AddOrEditCityDrawer from './AddOrEditCityDrawer/AddOrEditCityDrawer';

function decrementDays(dateString, noOfDays) {
	let date = dayjs(dateString);
	date = date.subtract(noOfDays, 'day');

	return date.format('YYYY-MM-DD');
}

const ICON_MAPPING = {
	FLIGHT: FlightIcon,
	CAR: CarIcon,
	FERRY: DirectionsBoatOutlined,
	TRAIN: TrainIcon,
	BUS: DirectionsBusOutlined,
	INTERNAL_TRASNPORT: HailIcon,
	BOAT: DirectionsBoatOutlined,
};

function TripDetailsWidget({
	tripDetails = [],
	setTripDetails = () => {},
	setTripModificationDetails = () => {},
	localActiveTabIndex,
	numTravellers,
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const [hoveredPlace, setHoveredPlace] = useState(null);
	const [hoveredTransit, setHoveredTransit] = useState(null);
	const [editDetails, setEditDetails] = useState(null);

	const handleMouseEnter = (index) => {
		setHoveredPlace(index);
	};

	const handleMouseLeave = () => {
		setHoveredPlace(null);
	};

	const handleMouseEnterOnTransit = (index) => {
		setHoveredTransit(index);
	};

	const handleMouseLeaveOnTransit = () => {
		setHoveredTransit(null);
	};

	const onClickRemoveLocation = async (index) => {
		const toAirportOfNext = await handleLookupAirports({
			city_list: [tripDetails[0][index + 1].location],
		});

		if (!toAirportOfNext.length) {
			return;
		}

		const updatedTripDetails = tripDetails.map((curTripData) => {
			return curTripData.reduce((acc, cur, ind) => {
				if (ind === index) {
					return acc;
				}

				if (ind < index - 1) {
					return [...acc, cur];
				}

				let dateOfJourney =
					cur?.travelData?.flightDetails?.dateOfJourney ||
					cur?.travelData?.nonFlightDetails?.dateOfJourney;
				let destination = cur?.travelData?.destination;
				let toAirport = cur?.travelData?.flightDetails?.toAirport;

				if (ind === index - 1) {
					toAirport = toAirportOfNext;
					destination = curTripData[index + 1].location;
				}

				if (ind >= index) {
					dateOfJourney = decrementDays(
						dateOfJourney,
						Math.abs(curTripData[index].noOfNights),
					);
				}

				return [
					...acc,
					{
						...cur,
						travelData:
							cur.type === 'end'
								? null
								: {
										...cur.travelData,
										destination,
										flightDetails: ['flight', 'FLIGHT'].includes(
											cur.travelData.type,
										)
											? {
													...cur.travelData.flightDetails,
													dateOfJourney,
													toAirport,
												}
											: undefined,
										nonFlightDetails: !['flight', 'FLIGHT'].includes(
											cur.travelData.type,
										)
											? {
													...cur.travelData.nonFlightDetails,
													dateOfJourney,
												}
											: undefined,
									},
					},
				];
			}, []);
		});

		setTripDetails(updatedTripDetails);
		setTripModificationDetails((prev) => ({
			...prev,
			modified: true,
			transportChanged: true,
			messages: [
				...(prev.messages || []),
				`${tripDetails[0][index].location} has been removed from locations`,
			],
		}));
	};

	return (
		<div
			className="p-4 rounded-md flex flex-col gap-4"
			style={{ backgroundColor: '#F7F6FC' }}
		>
			{(tripDetails[localActiveTabIndex] || []).map(
				(
					{ type, location, text, travelData, noOfNights, hotelFilters },
					index,
				) => {
					let ActiveIcon = null;

					if (travelData) {
						ActiveIcon = ICON_MAPPING[travelData?.type] || FlightIcon;
					}
					return (
						<div className="flex flex-col gap-4" key={`${location}_${index}`}>
							<div
								onMouseEnter={() => handleMouseEnter(index)}
								onMouseLeave={() => handleMouseLeave(index)}
								style={{
									border: '1px solid #D5C9F7',
									backgroundColor: '#fff',
								}}
								className="flex flex-col rounded-lg"
							>
								<div className="p-4 text-sm flex flex-col gap-3">
									<div className="flex justify-between">
										<div
											className="font-semibold"
											style={{
												color: hoveredPlace === index ? '#7F6D8B' : '#301345',
											}}
										>
											{location}
										</div>
										<div className="flex gap-2 items-center">
											<div style={{ opacity: '0.5', fontWeight: '400' }}>
												{['start', 'end'].includes(type)
													? text
													: `${noOfNights} Nights`}
											</div>

											{hoveredPlace === index ? (
												<div
													role="presentation"
													className="cursor-pointer"
													onClick={() => {
														sendMessageEvent({
															event:
																GoogleEventTypes.TRIP_CONTROLS_EDIT_LOCATION,
															chat_session_id: chatSessionId,
														});
														setEditDetails({ type: 'edit_city', index });
													}}
												>
													<EditPencil size="18" />
												</div>
											) : null}

											{hoveredPlace === index &&
											!['start', 'end'].includes(type) ? (
												<div
													role="presentation"
													className="cursor-pointer"
													onClick={() => {
														sendMessageEvent({
															event:
																GoogleEventTypes.TRIP_CONTROLS_REMOVE_LOCATION,
															chat_session_id: chatSessionId,
														});
														onClickRemoveLocation(index);
													}}
												>
													<DeleteIcon />
												</div>
											) : null}
										</div>
									</div>

									{hotelFilters ? ( // should we use number of travellers for every place??
										<div className="flex gap-1">
											<div>Hotel</div> · <div>{numTravellers} Adults</div> ·
											<div>{hotelFilters.NUM_ROOMS} Rooms</div>
										</div>
									) : null}

									{!['end', 'start'].includes(type) && !hotelFilters ? (
										<div>No Hotel</div>
									) : null}
								</div>

								{hoveredPlace === index && travelData ? (
									<div
										role="presentation"
										onClick={() => {
											sendMessageEvent({
												event: GoogleEventTypes.TRIP_CONTROLS_ADD_LOCATION,
												chat_session_id: chatSessionId,
											});
											setEditDetails({ type: 'add_city', index });
										}}
										style={{ transition: 'bottom 0.3s ease', width: '100%' }}
									>
										<button type="button" className={styles.add_city_button}>
											<AddCircleRoundedIcon fontSize="small" /> Add City
										</button>
									</div>
								) : null}
							</div>

							{travelData ? (
								<div
									key={location}
									className="p-4 rounded-lg text-sm flex flex-col gap-3"
									style={{
										border:
											hoveredTransit === index ? '1px solid #7750E5' : 'none',
										color: hoveredTransit === index ? '#7F6D8B' : '#574068',
										backgroundColor: '#F0ECFC',
									}}
									onMouseEnter={() => handleMouseEnterOnTransit(index)}
									onMouseLeave={() => handleMouseLeaveOnTransit(index)}
								>
									<div className="flex justify-center">
										<div className="flex gap-2 items-center text-nowrap">
											<div className="w-10 text-center">
												{dayjs(
													travelData.flightDetails?.dateOfJourney ||
														travelData.nonFlightDetails?.dateOfJourney,
												).format('D MMM')}
											</div>
											<ActiveIcon
												fontSize="small"
												style={{ color: '#DC1F90' }}
											/>

											{hoveredTransit === index ? (
												<div
													role="presentation"
													className="cursor-pointer"
													onClick={() => {
														sendMessageEvent({
															event:
																GoogleEventTypes.TRIP_CONTROLS_EDIT_TRANSIT,
															chat_session_id: chatSessionId,
														});
														setEditDetails({ type: 'edit_transit', index });
													}}
												>
													<EditPencil size="22" />
												</div>
											) : null}
										</div>
									</div>
								</div>
							) : null}
						</div>
					);
				},
			)}

			<Drawer
				anchor="right"
				open={!!editDetails}
				onClose={() => setEditDetails(null)}
			>
				<AddOrEditCityDrawer
					setEditDetails={setEditDetails}
					setTripDetails={setTripDetails}
					editDetails={editDetails}
					tripDetails={tripDetails}
					setTripModificationDetails={setTripModificationDetails}
					localActiveTabIndex={localActiveTabIndex}
				/>
			</Drawer>
		</div>
	);
}

export default TripDetailsWidget;

import TravellersDetails from './TravellersDetails';

function RightSectionMainComponent({
	numTravellers,
	setNumTravellers,
	setTripModificationDetails,
}) {
	return (
		<div className="flex flex-col gap-4">
			<TravellersDetails
				numTravellers={numTravellers}
				setNumTravellers={setNumTravellers}
				setTripModificationDetails={setTripModificationDetails}
			/>
		</div>
	);
}

export default RightSectionMainComponent;

import { Button } from '@mui/base';
import InfoIcon from '@commons/icons/InfoIcon';
import styles from './styles.module.css';

function TabChangeInfoComponent({ handleUpdateHotelsAndItinerary = () => {} }) {
	return (
		<div className=" flex flex-col gap-4 items-center m-1">
			<div style={{ color: '#7750E5' }} className="flex items-center gap-2">
				<InfoIcon />
				Click “Update Hotels & Itinerary” to find hotels and day plans for this
				date option{' '}
			</div>

			<Button
				className={styles.submit_button}
				onClick={handleUpdateHotelsAndItinerary}
			>
				Update Hotels & Itinerary
			</Button>
		</div>
	);
}

export default TabChangeInfoComponent;

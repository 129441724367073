import { cloneElement } from 'react';
import { get_id } from './TooltipHelper'; 

const TooltipWrapper = ({ children, tooltipContent, place = 'bottom' }) => {
    const tooltip_id = get_id();
    // Clone the element to add custom props
    const elementWithTooltipId = cloneElement(children, {
        'data-tooltip-id': tooltip_id,
        'data-tooltip-content': tooltipContent,
        'data-tooltip-place': place
    });

    return elementWithTooltipId;
};

export default TooltipWrapper;

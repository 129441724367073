import React from 'react';
import { FormControl, MenuItem, Select, styled } from '@mui/material';
import { formatDates } from '@helpers/DateUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledSelect = styled(Select)(({ theme }) => ({
	borderRadius: '20px',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#D9D9D9',
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: '#7750E5',
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: '#D9D9D9',
		borderWidth: '1px',
	},
}));

function CustomSelect({ value, onChange, hotel_datesets, hotelFilters }) {
	return (
		<FormControl variant="outlined" sx={{ width: 'fit-content' }}>
			<StyledSelect
				value={value}
				size="small"
				displayEmpty
				IconComponent={KeyboardArrowDownIcon}
			>
				{hotelFilters
					.filter((hotelFilter) => hotelFilter)
					.map((hotelDetails, index) => {
						const { COORDINATES_LABEL = '' } = hotelDetails || {};
						const finalPlace = COORDINATES_LABEL.split(',')[0];

						return (
							<MenuItem value={index}>
								<div
									className="flex items-center gap-2 cursor-pointer pr-3"
									role="presentation"
									onClick={() => onChange(index)}
									style={{
										color: '#301345',
									}}
								>
									<div className="text-sm font-medium">{finalPlace}</div>

									<div
										className="flex text-sm items-center gap-2"
										style={{ marginTop: '2px' }}
									>
										·
										<div className="font-normal text-xs">
											{formatDates(
												hotel_datesets[index].CHECK_IN_DATE,
												hotel_datesets[index].CHECK_OUT_DATE,
											)}
										</div>
									</div>
								</div>
							</MenuItem>
						);
					})}
			</StyledSelect>
		</FormControl>
	);
}

export default CustomSelect;

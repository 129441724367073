const SUGGESTIONS = [
    "3+ star hotels with a pool.",
    "Add a moderate hike on Day 2."
]

export default function PostReportChatSuggestions({ handleSuggestionClick }) {
    return (
        <div className='flex gap-4 justify-center'>
            {SUGGESTIONS.map(
                suggestion => <div
                    className='py-2 px-4 rounded-md w-[140px] text-center cursor-pointer'
                    style={{
                        backgroundColor: '#fff',
                        border: '1px solid #7750E570'
                    }}
                    onClick={handleSuggestionClick(suggestion)}
                >
                    {suggestion}
                </div>
            )}
        </div>
    );
}

import CloseIcon from '@mui/icons-material/Close';

function ComingSoon({ handleClose }) {
	return (
		<div className="flex flex-col gap-4" style={{ minWidth: '160px' }}>
			<div className="flex justify-between">
				<div className="text-lg">Airlines</div>
				<CloseIcon onClick={handleClose} />
			</div>

			<div>Coming Soon!</div>
		</div>
	);
}

export default ComingSoon;

import React from "react";
import "../../css/TypingIndicator.css";

const TypingIndicator = () => {
    const numberOfDots = 3;
    const dotElements = Array.from({ length: numberOfDots }).map((_, index) => (
        <div key={index} className="dot" />
    ));

    return (
        <div className="bg-primary-100 rounded-xl rounded-bl-none px-3 py-2 max-w-[85%] mt-3 float-left clear-both border border-primary-500 border-solid border-1 ml-4">
            <div className="typing">
                {dotElements}
            </div>
        </div>
    );
};

export default TypingIndicator;

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import styles from './styles.module.css';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	textAlign: 'center',
};

function ModalComponent({
	open,
	handleClose = () => {},
	setSuggestOrder = () => {},
}) {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={style}
				style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
			>
				<div
					style={{ color: '#301345', fontSize: '16px' }}
					className="font-semibold"
				>
					Looking to change the order of cities?
				</div>
				<div style={{ color: '#301345', fontSize: '16px' }}>
					AiRial can find the best route for you with flight prices, travel
					times, hotel preferences etc.
				</div>

				<div className="flex justify-between">
					<Button
						variant="text"
						style={{
							color: '#7750E5',
							textTransform: 'unset',
							fontWeight: 500,
							fontSize: '16px',
						}}
						onClick={handleClose}
					>
						Suggest the best possible route
					</Button>

					<Button
						style={{
							textTransform: 'unset',
						}}
						className={styles.styled_button}
						variant="contained"
						onClick={() => {
							setSuggestOrder('fixed');
							handleClose();
						}}
					>
						I’ll choose the order
					</Button>
				</div>
			</Box>
		</Modal>
	);
}

export default ModalComponent;

import { useState } from 'react';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import styles from './styles.module.css';

const COMPONENT_MAPPING = {
	login: LoginForm,
	signup: SignUpForm,
};

function AuthForm({ setShowLoginLayover = () => {} }) {
	const [activeTab, setActiveTab] = useState('login');

	const ActiveComponent = COMPONENT_MAPPING[activeTab] || SignUpForm;

	return (
		<div className={styles.form_container}>
			<ActiveComponent
				setActiveTab={setActiveTab}
				setShowLoginLayover={setShowLoginLayover}
			/>
		</div>
	);
}

export default AuthForm;

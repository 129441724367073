function InfoIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M12.5 7.75V13"
				stroke="#7750E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.5802 8.58003V15.42C21.5802 16.54 20.9802 17.58 20.0102 18.15L14.0702 21.58C13.1002 22.14 11.9002 22.14 10.9202 21.58L4.98016 18.15C4.01016 17.59 3.41016 16.55 3.41016 15.42V8.58003C3.41016 7.46003 4.01016 6.41999 4.98016 5.84999L10.9202 2.42C11.8902 1.86 13.0902 1.86 14.0702 2.42L20.0102 5.84999C20.9802 6.41999 21.5802 7.45003 21.5802 8.58003Z"
				stroke="#7750E5"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5 16.2002V16.3002"
				stroke="#7750E5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default InfoIcon;

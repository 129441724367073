import React, { useState, useEffect } from 'react';

function ImageLoader({ gMapsData, placeName, onError, width="6rem", height="6rem", usePhotoId=false, styles={} }) {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [usePhotoIdState, setUsePhotoIdState] = useState(usePhotoId);
    const [photoUrl, setPhotoUrl] = useState(() => {
        const photos = gMapsData[placeName]?.[0]?.[0]?.photos_data || [];
        return photos.length > 0 ? photos[0].original_photo_url : null;
    });

    useEffect(() => {
        const photos = gMapsData[placeName]?.[0]?.[0]?.photos_data || [];
        if (usePhotoIdState && photos.length > 0 && photos[0]["photo_id"]) {
            setPhotoUrl(photos[0]["photo_id"]);
        }
        else if (photos.length > 0 && photoIndex < photos.length) {
            setPhotoUrl(photos[photoIndex].original_photo_url);
        } else {
            setPhotoUrl(null);
            onError();
        }
    }, [photoIndex, usePhotoIdState, gMapsData, placeName]);

    const handleImgError = () => {
        if (usePhotoIdState) {
            setUsePhotoIdState(false);
        } else {
            setPhotoIndex(prevIndex => prevIndex + 1);
        }
    };
    return photoUrl ? (
            <img
                src={photoUrl}
                alt={`${placeName}`}
                style={{ width: width, height: height, ...(styles || {}) }}
                className="object-cover max-w-none rounded-l-sm"
                onError={handleImgError}
                referrerPolicy="no-referrer"
            />
        ) : null;
}

export default ImageLoader;

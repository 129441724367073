import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Slider, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handleFindFlights } from '@helpers/FlightsQueryHelper';

function DurationFilter({
	handleClose = () => {},
	setAnchorEl = () => {},
	durantionFilter,
	setDurantionFilter,
	activeTabIndex,
}) {
	const { tripTransitionsDetails } = useSelector(
		({ tripTransitions = {} }) => ({
			tripTransitionsDetails: tripTransitions,
		}),
	);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);

	const { tripLocationDetails } = useSelector(
		({ tripLocationDetails: tripLocations = {} }) => ({
			tripLocationDetails: tripLocations,
		}),
	);

	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);

	const {
		travelSets = [],
		maxPrice,
		tripType,
		tripClass,
	} = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		hotelFilters = [],
		hotelReqs = [],
		numTravellers,
	} = tripLocationDetails;

	const FLIGHTSETS = travelSets.map((travelSet) => {
		return travelSet.reduce((acc, { type, flightDetails = {} }) => {
			if (['flight', 'FLIGHT'].includes(type)) {
				return [
					...acc,
					{
						DATE_OF_JOURNEY: flightDetails?.dateOfJourney,
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}

			return acc;
		}, []);
	});

	const data_v2 = {
		NUM_PASSENGERS: numTravellers,
		MAX_PRICE: maxPrice,
		FLIGHTSETS,
		TRIP_TYPE: tripType,
		TRIP_CLASS: tripClass,
	};

	const dispatch = useDispatch();
	const location = useLocation();

	const handleClearFilter = () => {
		const updatedFlightSetsData = data_v2.FLIGHTSETS.map((flightSet) => {
			return flightSet.map((item) => {
				return {
					...item,
					MAX_DURATION: -1,
				};
			});
		});

		const updatedTravelSets = travelSets.map((travelSet) => {
			return travelSet.reduce((acc, cur) => {
				const { type } = cur || {};

				if (['flight', 'FLIGHT'].includes(type)) {
					return [
						...acc,
						{
							...cur,
							flightDetails: {
								...cur.flightDetails,
								maxDuration: -1,
							},
						},
					];
				}

				return [...acc, cur];
			}, []);
		});

		dispatch({
			type: 'UPDATE_TRANSITIONS_REDUCER',
			payload: {
				travelSets: updatedTravelSets,
			},
		});

		const flightSetData = {
			...data_v2,
			FLIGHTSETS: updatedFlightSetsData,
		};

		handleClose();

		handleFindFlights({
			flightQuery: flightSetData,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery: {
				locations: {
					startLocation,
					endLocation,
					places,
				},
				chat_history: llmInteractionHistory,
			},
			shouldUpdateFlightsAndDayPlans: true,
			tripData: {
				NUM_TRAVELLERS: numTravellers,
				hotelFilters,
				travelSets: updatedTravelSets,
				LOCATIONS: places,
				HOTEL_REQUIREMENT: hotelReqs,
				hotelSortCriterion,
			},
			sortOption: flightSortOption,
			gMapsData,
			curActiveTabIndex: activeTabIndex,
		});
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex justify-between">
				<div className="text-lg">Duration</div>
				<CloseIcon onClick={handleClose} />
			</div>

			{data_v2.FLIGHTSETS[0].map(({ MAX_DURATION }, ix) => {
				return (
					<div className="text-sm mt-1">
						{`Flight ${(ix + 1).toString()}`}

						<Box sx={{ width: 300, padding: '0 4px' }}>
							<Slider
								defaultValue={MAX_DURATION <= 0 ? 38 : MAX_DURATION}
								aria-label="Default"
								valueLabelDisplay="auto"
								min={0}
								max={38}
								onChange={(_, value) => {
									setDurantionFilter((prev) =>
										prev.map((val, index) => {
											if (index === ix) {
												return value;
											}

											return val;
										}),
									);
								}}
							/>
						</Box>
					</div>
				);
			})}

			<div className="flex ml-auto mt-2">
				<Button
					sx={{ textTransform: 'unset' }}
					onClick={handleClearFilter}
					variant="text"
					size="small"
				>
					Clear
				</Button>

				<Button
					sx={{ textTransform: 'unset', backgroundColor: '#5b21b6' }}
					variant="contained"
					size="small"
					onClick={() => {
						const updatedFlightSetsData = data_v2.FLIGHTSETS.map(
							(flightSet) => {
								return flightSet.map((item, index) => {
									return {
										...item,
										MAX_DURATION:
											durantionFilter[index] === 38
												? -1
												: durantionFilter[index],
									};
								});
							},
						);

						const updatedTravelSets = travelSets.map((travelSet) => {
							let i = 0;
							return travelSet.reduce((acc, cur) => {
								const { type } = cur || {};

								if (['flight', 'FLIGHT'].includes(type)) {
									const curIndex = i;
									i += 1;

									return [
										...acc,
										{
											...cur,
											flightDetails: {
												...cur.flightDetails,
												maxDuration:
													durantionFilter[curIndex] === 38
														? -1
														: durantionFilter[curIndex],
											},
										},
									];
								}

								return [...acc, cur];
							}, []);
						});

						dispatch({
							type: 'UPDATE_TRANSITIONS_REDUCER',
							payload: {
								travelSets: updatedTravelSets,
							},
						});

						const flightSetData = {
							...data_v2,
							FLIGHTSETS: updatedFlightSetsData,
						};

						handleFindFlights({
							flightQuery: flightSetData,
							dispatch,
							chatSessionId,
							location,
							dayPlansQuery: {
								locations: {
									startLocation,
									endLocation,
									places,
								},
								chat_history: llmInteractionHistory,
							},
							shouldUpdateFlightsAndDayPlans: true,
							tripData: {
								NUM_TRAVELLERS: numTravellers,
								hotelFilters,
								travelSets: updatedTravelSets,
								LOCATIONS: places,
								HOTEL_REQUIREMENT: hotelReqs,
								hotelSortCriterion,
							},
							sortOption: flightSortOption,
							gMapsData,
							curActiveTabIndex: activeTabIndex,
						});

						setAnchorEl(null);
					}}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

export default DurationFilter;

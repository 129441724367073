export const SortOptions = Object.freeze({
	BEST: 'Best',
	PRICE: 'Price',
	DURATION: 'Duration',
});

export const MainTripPageView = {
	OVERVIEW: 'overview',
	FLIGHTS: 'flights',
	HOTELS: 'hotels',
	ITINERARY: 'itinerary',
};

export const LoadingState = {
	INIT: 'init',
	LOADING: 'loading',
	LOADED: 'loaded',
	ERROR: 'error',
};

export const HotelReviewSummaryStages = {
	INIT: 'init',
	ERROR: 'error',
	LOADING: 'loading',
	MID_REPORT_LOADED: 'mid_report_loaded',
	REPORT: 'report',
};

export const HotelSortCriterion = {
	BEST: 'best',
	CHEAPEST: 'cheapest',
	CLOSEST: 'closest',
	STAR_RATING: 'star_rating',
};

export const MessageLabel = {
	ASSISTANT: 'Assistant',
	USER: 'User',
	CLARIFICATION_WIDGET_ASSISTANT: 'Assistant-initiated Clarification Widget',
	CLARIFICATION_WIDGET_USER: 'User Response for Clarification Widget',
	LOCATION_CONFIRMATION_WIDGET_ASSISTANT:
		'Assistant-initiated Location Confirmation Widget',
	LOCATION_CONFIRMATION_WIDGET_USER: 'Locations confirmed by the user',
	MAJOR_UPDATE_CONFIRMATION_WIDGET_ASSISTANT:
		'Assistant-initiated Major Update Confirmation Widget',
	MAJOR_UPDATE_CONFIRMATION_WIDGET_USER:
		'User Response for Major Update Confirmation Widget',
	INSPIRATION_ATTACHMENT_USER: 'Summary of Video Attachment added by User',
};

export const InteractionStage = {
	PRE_CLARIFICATION_WIDGET: 'pre_clarification_widget',
	PRE_LOCATION_CONFIRMATION_WIDGET: 'pre_location_confirmation_widget',
	POST_LOCATION_CONFIRMATION_WIDGET: 'post_location_confirmation_widget',
	POST_REPORT: 'post_report',
	POST_REPORT_MAJOR_UPDATE_CONFIRMATION:
		'post_report_major_update_confirmation',
};

export const PostReportIntent = {
	QUESTION: 'QUESTION',
	CHANGE_NUM_PASSENGERS: 'CHANGE_NUM_PASSENGERS',
	CHANGE_FLIGHT_FILTERS: 'CHANGE_FLIGHT_FILTERS',
	CHANGE_HOTEL_FILTERS: 'CHANGE_HOTEL_FILTERS',
	CHANGE_ACTIVITIES: 'CHANGE_ACTIVITIES',
	CHANGE_TRANSPORT_HOTEL_REQS: 'CHANGE_TRANSPORT_HOTEL_REQS',
	CHANGE_OR_REORDER_CITIES: 'CHANGE_OR_REORDER_CITIES',
	CHANGE_TRANSPORT_DATES: 'CHANGE_TRANSPORT_DATES',
	CHANGE_TRANSPORT_TIMES: 'CHANGE_TRANSPORT_TIMES',
	CHANGE_AIRPORTS: 'CHANGE_AIRPORTS',
	NONE: 'NONE',
};

export const LocationWidgetSource = {
	GOOGLE: 'google',
	ADOBE_STOCK: 'adobe_stock',
};

export const PostReportUpdateType = {
	NOOP: 'NOOP',
	MINOR: 'MINOR',
	MAJOR: 'MAJOR',
};

export const ChatMessageSender = {
	SYSTEM: 'system',
	USER: 'user',
	AIRIAL: 'airial',
};

export const ChatMessageWidgetType = {
	PREFERENCES_WIDGET: 'preferences_widget',
	LOCATION_WIDGET: 'location_widget',
	TRIP_GENERATION_UPDATES_WIDGET: 'trip_generation_updates_widget',
	POST_REPORT_MAJOR_UPDATE_CONFIRMATION:
		'post_report_major_update_confirmation',
};

export const ChatMessageAttachmentType = {
	DAY_PLANS_CARD: 'day_plans_card',
	FLIGHTS_CARD: 'flights_card',
	HOTELS_CARD: 'hotels_card',
	REEL_THUMBNAIL: 'reel_thumbnail',
	TIKTOK_THUMBNAIL: 'tiktok_thumbnail',
	INSPIRATION: 'inspiration',
};
export const InspirationDrawerSource = {
	LANDING_PAGE: 'landing_page',
	OVERVIEW: 'overview',
	CHAT: 'chat',
};

export const TripUpdateType = {
	// User messages waiting for assistant
	PRE_LC_USER_SUBMITTED: 'PRE_LC_USER_SUBMITTED',
	QUESTION_WIDGET_USER_SUBMITTED: 'QUESTION_WIDGET_USER_SUBMITTED',
	LOCATION_CONFIRMATION_USER_SUBMITTED: 'LOCATION_CONFIRMATION_USER_SUBMITTED',
	POST_REPORT_USER_SUBMITTED: 'POST_REPORT_USER_SUBMITTED',
	EDIT_TRIP_USER_SUBMITTED: 'EDIT_TRIP_USER_SUBMITTED',
	POST_REPORT_MAJOR_UPDATE_USER_CONFIRMATION:
		'POST_REPORT_MAJOR_UPDATE_USER_CONFIRMATION',

	// Assistant Responded but more actions needed to make stable state
	LOCATION_CONFIRMATION_ASSISTANT_MID_REPORT:
		'LOCATION_CONFIRMATION_ASSISTANT_MID_REPORT',
	LOCATION_CONFIRMATION_ASSISTANT_REPORT:
		'LOCATION_CONFIRMATION_ASSISTANT_REPORT',

	// Significant changes but more actions needed to make stable state
	OVERVIEW_FLIGHT_SELECTED: 'OVERVIEW_FLIGHT_SELECTED',
	FLIGHT_SELECTION_CHANGED_USER: 'FLIGHT_SELECTION_CHANGED_USER',

	// Stable States after this without any actions
	PRE_LC_ASSISTANT_RESPONSE: 'PRE_LC_ASSISTANT_RESPONSE',
	LOCATION_CONFIRMATION_ASSISTANT_INITIATED:
		'LOCATION_CONFIRMATION_ASSISTANT_INITIATED',
	POST_REPORT_MAJOR_UPDATE_USER_CANCEL: 'POST_REPORT_MAJOR_UPDATE_USER_CANCEL',
	POST_REPORT_MAJOR_UPDATE_MID_REPORT: 'POST_REPORT_MAJOR_UPDATE_MID_REPORT',
	POST_REPORT_ASSISTANT_REPORT: 'POST_REPORT_ASSISTANT_REPORT',
	DEMO_ATTACH_FLOW: 'DEMO_ATTACH_FLOW',
	ITINERARY_INSPIRATION_UPDATE_COMPLETE:
		'ITINERARY_INSPIRATION_UPDATE_COMPLETE',
	HOTEL_SELECTION_CHANGED_USER: 'HOTEL_SELECTION_CHANGED_USER',
	SINGLE_DAY_CARD_UPDATED: 'SINGLE_DAY_CARD_UPDATED',
	ASSISTANT_ERROR: 'ASSISTANT_ERROR',

	// Kinda tricky: Day plans or Hotels might be in partial state but whatever I guess
	OVERVIEW_HOTEL_SELECTED: 'OVERVIEW_HOTEL_SELECTED',
	DAY_PLANS_LOADED: 'DAY_PLANS_LOADED',
};

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { getPlacesData } from '@helpers/ModelApiUtils';
import AutoCompleteLocationSelect from '@commons/forms/page-components/components/AutoCompleteLocationSelect';
import { useDispatch } from 'react-redux';
import CardSlider from './CardSlider';

function AddAnotherCity({
	isAddAnotherOpen = false,
	setIsAddAnotherOpen = () => {},
	setItems = () => {},
	alternatives = [],
	setAlternatives = () => {},
	places = [],
}) {
	const dispatch = useDispatch();

	const handleSelect = async (option) => {
		if (!option) {
			return;
		}

		const { value = '' } = option || {};

		const placesWithData = await getPlacesData(value);

		dispatch({
			type: 'UPDATE_LC_WIDGET_PLACES',
			payload: [...places, { ...(placesWithData || {}), newlyAdded: true }],
		});
	};

	if (!isAddAnotherOpen) {
		return null;
	}

	return (
		<div
			className="rounded-lg flex flex-col gap-2 p-4"
			style={{ border: '1px #CDBAFF solid' }}
		>
			<div
				className="font-semibold flex justify-between cursor-pointer mb-3"
				style={{ color: '#301345', fontSize: '16px' }}
			>
				Add another city
				<CancelSharpIcon
					onClick={() => setIsAddAnotherOpen(false)}
					fontSize="small"
				/>
			</div>

			<AutoCompleteLocationSelect handleSelect={handleSelect} />

			<div
				style={{ color: '#574068', fontSize: '16px' }}
				className="mt-5 font-light"
			>
				Suggested Cities
			</div>

			<CardSlider
				cards={alternatives}
				setItems={setItems}
				places={places}
				setAlternatives={setAlternatives}
			/>
		</div>
	);
}

export default AddAnotherCity;

function CollapsibleFlightFormShimmerComponent() {
	return (
		<div className="bg-neutrals-card_background rounded-lg shadow-lg space-y-2">
			<div className="flex flex-col w-full p-4 space-y-4">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row items-center space-x-4">
						<div className="flex shimmer h-8 w-8 rounded-full" />
						<div className="flex shimmer h-4 w-24 rounded-md" />
					</div>
					<div className="flex shimmer h-4 w-24 rounded-md" />
				</div>
				<div className="flex flex-row items-center space-x-4">
					<div className="flex shimmer h-4 w-20 rounded-md" />
					<div className="flex shimmer h-4 w-20 rounded-md" />
					<div className="flex shimmer h-4 w-8 rounded-md" />
				</div>
				<div className="flex flex-row items-center space-x-4">
					<div className="flex flex-grow shimmer h-6 w-12 rounded-md" />
					<div className="flex flex-grow shimmer h-6 w-12 rounded-md" />
					<div className="flex flex-grow shimmer h-6 w-8 rounded-md" />
				</div>
				<div className="flex shimmer h-4 w-16 rounded-md self-center" />
			</div>
		</div>
	);
}

export default CollapsibleFlightFormShimmerComponent;

function PersonIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
		>
			<path
				d="M12.995 14.6441C15.0172 14.6441 16.6565 13.0061 16.6565 10.9856C16.6565 8.9651 15.0172 7.32715 12.995 7.32715C10.9728 7.32715 9.3335 8.9651 9.3335 10.9856C9.3335 13.0061 10.9728 14.6441 12.995 14.6441Z"
				stroke="#7750E5"
				strokeWidth="1.50063"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.4389 20.3919C24.0496 18.3528 25 15.7838 25 12.995C25 6.37776 19.6278 1 12.995 1C6.36223 1 1 6.37776 1 12.995C1 15.7838 1.95039 18.3528 3.56106 20.3919"
				stroke="#7750E5"
				strokeWidth="1.50063"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.7983 22.1212C18.7074 23.9105 15.9762 25 12.995 25C10.0138 25 7.29263 23.9205 5.20176 22.1212C6.70238 19.3524 9.6236 17.4731 12.995 17.4731C16.3664 17.4731 19.2976 19.3524 20.7983 22.1212Z"
				stroke="#7750E5"
				strokeWidth="1.50063"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default PersonIcon;

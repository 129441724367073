import { checkLink } from '@utils/LinkUtils';
import {
	ChatMessageAttachmentType,
	InteractionStage,
	InspirationDrawerSource,
	ChatMessageSender,
	MessageLabel,
	TripUpdateType,
} from '@helpers/Enums';
import { getAllPlacesDataFromContentPayloads } from '@utils/DayCardUtils';
import { handleFetchPreLC, handleFetchPostReport } from './ModelApiUtils';

export const backendURL = process.env.REACT_APP_BACKEND_URL;

export const cleanUrl = (url) => {
	const parsedUrl = new URL(url);
	return parsedUrl.origin + parsedUrl.pathname;
};

export const sendMessage = async (
	dispatch,
	navigate,
	currentChatMessage,
	chatSessionId,
	llmInteractionHistory,
	attachment = {},
	message_label = 'User',
	updateType = TripUpdateType.PRE_LC_USER_SUBMITTED,
) => {
	const userMessage = {
		sender: ChatMessageSender.USER,
		message: currentChatMessage,
		attachment,
	};

	const postFormData = new FormData();

	postFormData.append('user_message', currentChatMessage);
	postFormData.append('chat_history', JSON.stringify(llmInteractionHistory));
	postFormData.append(
		'interaction_stage',
		InteractionStage.PRE_CLARIFICATION_WIDGET,
	);
	postFormData.append(
		'attachment_info',
		JSON.stringify({
			message_label,
			display_info: attachment,
		}),
	);
	if (chatSessionId) {
		postFormData.append('chat_session_id', chatSessionId);
	}

	dispatch({ type: 'UPDATE_PAGE_ID', payload: 1 });
	dispatch({ type: 'UPDATE_CURRENT_CHAT_MESSAGE', payload: '' });

	dispatchAddChatMessage(dispatch, updateType, {
		messages: [userMessage],
		isTyping: true,
	});

	await handleFetchPreLC(postFormData, dispatch, navigate, chatSessionId);
};

export const sendMessagePostReport = async (
	dispatch,
	currentChatMessage,
	chatMessages,
	chatSessionId,
	llmInteractionHistory,
	attachment,
	tripLocations,
	tripTransitions,
	postReportDayPlansContext,
	activeTabIndex,
	location,
	majorUpdateConfirmationWidgets,
	currentReduxState,
) => {
	const userMessage = {
		sender: ChatMessageSender.USER,
		message: currentChatMessage,
		...(attachment
			? {
					attachment: {
						attachmentType: attachment.type,
						attachmentData: attachment,
						shouldShow: true,
					},
				}
			: {}),
	};
	dispatch({ type: 'UPDATE_CURRENT_CHAT_MESSAGE', payload: '' });

	dispatch({
		type: 'UPDATE_ATTACHMENT_CARD_DATA',
		payload: {
			...attachment,
			show: false,
		},
	});

	const updatedLlmInteractionHistory = [
		...llmInteractionHistory,
		{
			SENDER: 'User',
			LABEL: MessageLabel.USER,
			CONTENT: currentChatMessage,
		},
	];

	let updatedChatMessages = chatMessages;
	updatedChatMessages.push(userMessage);
	dispatch({
		type: 'UPDATE_CHAT_MESSAGES',
		payload: {
			messages: updatedChatMessages,
			isTyping: true,
		},
	});

	const postFormData = new FormData();
	postFormData.append('user_message', currentChatMessage);
	postFormData.append(
		'attachment',
		attachment ? JSON.stringify(attachment.data, undefined, 2) : null,
	);
	postFormData.append(
		'chat_history',
		JSON.stringify(updatedLlmInteractionHistory),
	);
	postFormData.append('interaction_stage', InteractionStage.POST_REPORT);
	postFormData.append('trip_locations', JSON.stringify(tripLocations));
	postFormData.append('trip_transitions', JSON.stringify(tripTransitions));
	postFormData.append(
		'day_plans_context',
		JSON.stringify(postReportDayPlansContext),
	);
	postFormData.append('chat_session_id', chatSessionId);

	handleFetchPostReport(
		postFormData,
		dispatch,
		location,
		majorUpdateConfirmationWidgets,
		currentChatMessage,
		updatedChatMessages,
		updatedLlmInteractionHistory,
		chatSessionId,
		attachment,
		postReportDayPlansContext.day_plans,
		activeTabIndex,
		null,
		currentReduxState,
	);
};

export const getAttachmentTypeFromLink = (url) => {
	const finalLink = cleanUrl(url);
	const { isInstagram } = checkLink(finalLink);
	return isInstagram
		? ChatMessageAttachmentType.REEL_THUMBNAIL
		: ChatMessageAttachmentType.TIKTOK_THUMBNAIL;
};

export const getDefaultInspirationMessage = (urls, stage = 'overview') => {
	const platforms = urls.map((url) => getPlatform(url));
	const uniquePlatforms = [...new Set(platforms)];

	const platformsString =
		uniquePlatforms.length > 1
			? `${uniquePlatforms.slice(0, -1).join(', ')} and ${uniquePlatforms[uniquePlatforms.length - 1]}`
			: uniquePlatforms.join('');
	if (stage === 'overview') {
		return `Add places from ${platformsString} to my itinerary`;
	}
	return `I want to plan a trip with these places from: ${platformsString}`;
};

export const getPlatform = (url) => {
	const finalLink = cleanUrl(url);
	const { isInstagram, isTikTok } = checkLink(finalLink);
	let platform;
	if (isInstagram) {
		platform = 'Instagram Reel';
	} else if (isTikTok) {
		platform = 'TikTok';
	} else {
		platform = 'Link';
	}
	return platform;
};

export const maybeAddInspirationAfterDrawerClose = async (
	inspirationData,
	dispatch,
	chatSessionId,
	llmInteractionHistory,
	source,
	gMapsData,
	navigate,
) => {
	if (source === InspirationDrawerSource.LANDING_PAGE && inspirationData) {
		const { additionalInstruction = '', contentPayloads = [] } =
			inspirationData;
		const allPlacesData = getAllPlacesDataFromContentPayloads(
			contentPayloads,
			gMapsData,
		);
		const contentIds = Object.entries(contentPayloads).map(
			([, item]) => item?.uuid,
		);
		const placeNames = allPlacesData
			.map((item) => `<${item.placeName}>`)
			.join(', ');
		const messageSuffix = `${additionalInstruction.trim() == '' ? '' : ` Additional instructions: ${additionalInstruction}`}.`;
		const message = `I want to plan a trip using these places sourced from Tiktok: ${placeNames}.${messageSuffix}`;

		const attachmentData = Object.entries(contentPayloads).map(([, item]) => {
			const { scrape_results = null, url = null } = item;
			return {
				url,
				scrape_results,
			};
		});
		const urls = Object.entries(contentPayloads)
			.map(([, item]) => item?.url)
			.filter((url) => !!url);

		const attachment = {
			label: 'INSPIRATION_ATTACHMENT_USER',
			attachmentType: ChatMessageAttachmentType.INSPIRATION,
			attachmentData,
			attachmentMessage:
				additionalInstruction || getDefaultInspirationMessage(urls),
		};
		const placeNamesList = allPlacesData.map((item) => item.placeName);

		dispatch({
			type: 'UPDATE_INSPIRATION_PLACE_NAMES',
			payload: placeNamesList,
		});
		dispatch({ type: 'ADD_INSPIRATION_CONTENT_IDS', payload: contentIds });

		navigate(`/chat`);
		await sendMessage(
			dispatch,
			navigate,
			message,
			chatSessionId,
			llmInteractionHistory,
			attachment,
			MessageLabel.INSPIRATION_ATTACHMENT_USER,
		);
	}
};

export async function dispatchAddChatMessage(dispatch, updateType, payload) {
	dispatch({
		type: 'ADD_CHAT_MESSAGE',
		payload,
	});
	dispatch({
		type: 'CHECKPOINT',
		payload: updateType,
	});
}

// TODO: Figure out how we can remove making an AI call to update the entire chat history. This is a bad paradigm
export async function dispatchUpdateChatMessages(
	dispatch,
	updateType,
	payload,
) {
	dispatch({
		type: 'UPDATE_CHAT_MESSAGES',
		payload,
	});
	dispatch({
		type: 'CHECKPOINT',
		payload: updateType,
	});
}

import axios from 'axios';
import { useState } from 'react';

const useGetHotelBookingLink = () => {
	const [loading, setLoading] = useState(false);

	const handleBookHotel = ({
		name,
		hotelDateSetDetails,
		hotelFilterDetails,
		numTravellers,
	}) => {
		const backendURL = process.env.REACT_APP_BACKEND_URL;

		const { CHECK_IN_DATE = '', CHECK_OUT_DATE = '' } = hotelDateSetDetails;

		const { NUM_ROOMS } = hotelFilterDetails;
		setLoading(true);

		axios
			.get(`${backendURL}/api/hotel_booking_link`, {
				params: { hotel_name: name },
			})
			.then((response) => {
				const { data: responseData = [] } = response;
				const { link } = responseData[0] || {};
				console.log('link', link);

				setLoading(false);
				window.open(
					`${link}?checkin=${CHECK_IN_DATE}&checkout=${CHECK_OUT_DATE}&group_adults=${numTravellers}&no_rooms=${NUM_ROOMS}`,
					'_blank',
					'noreferrer',
				);
			})
			.catch((error) => {
				console.error('Error:', error);

				setLoading(false);
			});
	};

	return {
		handleBookHotel,
		loading,
	};
};

export default useGetHotelBookingLink;

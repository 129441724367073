/* eslint-disable no-inner-declarations */
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import {
	ChatMessageSender,
	ChatMessageWidgetType,
	InteractionStage,
	MessageLabel,
	TripUpdateType,
} from '@helpers/Enums';
import { useLocation } from 'react-router-dom';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import {
	handleChatResponseError,
	getTravelSetsAfterTripGeneration,
} from '@helpers/ModelApiUtils';
import MinusIcon from '@commons/icons/MinusIcon';
import PlusIcon from '@commons/icons/PlusIcon';
import { dispatchUpdateChatMessages } from '@helpers/ChatUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import EditPencil from '@commons/icons/EditPencil';
import AutoCompleteLocationSelect from '@commons/forms/page-components/components/AutoCompleteLocationSelect';
import CrossIcon from '@commons/icons/CrossIcon';
import { parse, Allow } from 'partial-json';
import { afterLocationConfirmationReport } from '@helpers/GenerateTripHelper';
import discoverInspirationHelper from '@helpers/discoverInspirationHelper';
import isEmpty from '@utils/isEmpty';
import { GridProvider } from './GridProvider';
import DragItem from './DragItem';
import { Grid, GridItem } from './Grid';
import Card from './Card';
import AddAnotherCity from './AddAnotherCity';
import ModalComponent from './DragWarning/Modal';
import styles from './styles.module.css';

export const TRIP_GENERATION_MESSAGE =
	'I am now generating an incredible trip personalized for you. Currently this can take around a minute but it will be worth the wait! Thanks for your patience.';

function LocationConfirmation({ widgetData = {} }) {
	const {
		recommendedPlaces = [],
		alternativePlaces = [],
		START_LOCATION = '',
		END_LOCATION = '',
		IS_ORDER_FLEXIBLE: isOrderFlexible = 'Yes',
	} = widgetData;

	const dispatch = useDispatch();
	const location = useLocation();

	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const locationConfirmationWidgetState = useSelector(
		(state) => state.ui_states.locationConfirmationWidget,
	);
	const inspirationContentIds = useSelector(
		(state) => state.ui_states.inspirationContentIds,
	);
	const predictedNumTravellers = useSelector(
		(state) => state.tripLocationDetails.numTravellers,
	);
	const originalItemNames = recommendedPlaces.map(({ name }) => name);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const { responses = {} } = locationConfirmationWidgetState || {};

	const [alternatives, setAlternatives] = useState(alternativePlaces);
	const [isAddAnotherOpen, setIsAddAnotherOpen] = useState(false);
	// const [selectedBudget, setSelectedBudget] = useState('$'); // TODO: consume this
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [showEditStartLocation, setShowEditStartLocation] = useState(false);
	const [showEditEndLocation, setShowEditEndLocation] = useState(false);

	const {
		places = [],
		endLocation,
		numTravellers,
		startLocation,
		suggestOrder,
	} = responses || {};

	const isOrderSuggested = suggestOrder === 'suggest';

	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);

	const handleMoveItem = (updatedItems) => {
		if (locationConfirmationWidgetState.submitted) {
			return;
		}

		dispatch({
			type: 'UPDATE_LC_WIDGET_PLACES',
			payload: updatedItems,
		});
	};
	const setItemsIfNotSubmitted = (updatedItems) => {
		if (locationConfirmationWidgetState.submitted) {
			return;
		}

		dispatch({
			type: 'UPDATE_LC_WIDGET_PLACES',
			payload: updatedItems,
		});
	};
	const setAlternativesIfNotSubmitted = (updatedAlternatives) => {
		if (locationConfirmationWidgetState.submitted) {
			return;
		}
		setAlternatives(updatedAlternatives);
	};
	const setShowWarningModalIfNotSubmitted = (updatedShowWarningModal) => {
		if (locationConfirmationWidgetState.submitted) {
			return;
		}
		setShowWarningModal(updatedShowWarningModal);
	};

	// Not aligned with best practices AFAIK. Fix.
	// Fix will be trickier once handleReponse moves into a utils function since useState won't work as desired with a state variable which keeps updating.
	let tripDetailsState = {};

	const handleResponse = (response) => {
		const { MODE = '' } = response;

		if (MODE === 'mid_report') {
			let updatedChatMessages = chatMessages.messages;

			// TODO: Instead of modifying these directly in the chat message history, this should be a separate state that the widget subscribes to, similar to major-update-confirmation.
			updatedChatMessages = updatedChatMessages.map((item) => {
				if (item?.widget?.widgetType === 'trip_generation_updates_widget') {
					return {
						...item,
						widget: {
							...item?.widget,
							widgetData: [
								{
									id: 'identifying_route_and_connections',
									message: 'Identifying the route and the connections.',
									status: 1,
								},
								{
									id: 'finding_travel_dates',
									message: 'Finding the best travel dates.',
									status: 0,
								},
							],
							shouldShow: true,
						},
					};
				}

				return item;
			});

			const { FLIGHT_HOTEL_REQ = {} } = response;

			const {
				TRANSPORTATION_REQUIREMENT,
				HOTEL_REQUIREMENT: hotelRequirement = [],
				ORDERED_PLACES = [],
				START_LOCATION: startLocationFromBackend = '',
				END_LOCATION: endLocationFromBackend = '',
			} = FLIGHT_HOTEL_REQ;

			dispatch({
				type: 'UPDATE_LOCATIONS_REDUCER',
				payload: {
					endLocation: endLocationFromBackend,
					startLocation: startLocationFromBackend,
					places: ORDERED_PLACES,
					hotelReqs: hotelRequirement,
					numPlaces: ORDERED_PLACES.length,
				},
			});

			tripDetailsState = {
				FLIGHT_REQUIREMENT: TRANSPORTATION_REQUIREMENT.map((item) =>
					['flight', 'FLIGHT'].includes(item),
				),
				HOTEL_REQUIREMENT: hotelRequirement,
				LOCATIONS: ORDERED_PLACES,
				SOURCE: startLocationFromBackend,
				DESTINATION: endLocationFromBackend,
				TRANSPORTATION_REQUIREMENT,
			};

			dispatch({
				type: 'UPDATE_MID_REPORT_STATUS',
				payload: true,
			});

			dispatch({
				type: 'UPDATE_CHAT_VIEW_STATUS',
				payload: true,
			});

			dispatch({
				type: 'UPDATE_TRANSITIONS_REDUCER',
				payload: {
					transitionReqs: TRANSPORTATION_REQUIREMENT,
				},
			});

			dispatchUpdateChatMessages(
				dispatch,
				TripUpdateType.LOCATION_CONFIRMATION_ASSISTANT_MID_REPORT,
				{
					messages: updatedChatMessages,
					isTyping: true,
				},
			);

			return;
		}

		if (MODE === 'report') {
			const { PAYLOAD, CHAT_RESPONSE, STAGE: stage } = response;

			dispatch({
				type: 'UPDATE_LLM_INTERACTION_HISTORY',
				payload: response?.CHAT_HISTORY,
			});

			dispatch({
				type: 'UPDATE_INTERACTION_STAGE',
				payload: stage,
			});

			const {
				FLIGHT_QUERY = {},
				HOTEL_FILTERS = [],
				TRAVEL_DATES = [],
			} = PAYLOAD;

			const {
				FLIGHT_REQUIREMENT = [],
				HOTEL_REQUIREMENT = [],
				LOCATIONS = [],
				SOURCE,
				DESTINATION,
			} = tripDetailsState;

			const DATES = TRAVEL_DATES.map((travelDateSetArray) =>
				travelDateSetArray.map(({ DATE }) => DATE),
			);

			const isFlightTransportationPresent = !!FLIGHT_REQUIREMENT.filter(
				(flightRequired) => flightRequired,
			).length;

			let updatedChatMessages = chatMessages.messages;
			// TODO: Instead of modifying these directly in the chat message history, this should be a separate state that the widget subscribes to, similar to major-update-confirmation.
			updatedChatMessages = updatedChatMessages.map((item) => {
				if (item?.widget?.widgetType === 'trip_generation_updates_widget') {
					return {
						...item,
						widget: {
							...item?.widget,
							widgetData: [
								{
									id: 'identifying_route_and_connections',
									message: 'Identifying the route and the connections.',
									status: 1,
								},
								{
									id: 'finding_travel_dates',
									message: `Finding the best travel dates.${
										DATES.length > 1 ? ` Found ${DATES.length} options.` : ''
									}`,
									status: 1,
								},
								...(isFlightTransportationPresent
									? [
											{
												id: 'searching_flights',
												message: 'Searching for flights.',
												status: 0,
											},
										]
									: []),
								{
									id: 'searching_hotels',
									message: 'Searching for hotels.',
									status: 0,
								},
								{
									id: 'creating_itinerary',
									message: 'Creating an itinerary.',
									status: 0,
								},
							],
							shouldShow: true,
						},
					};
				}

				return item;
			});
			updatedChatMessages.push({
				sender: ChatMessageSender.AIRIAL,
				message: CHAT_RESPONSE,
			});

			let hotelNum = 0;
			const hotelFilters = HOTEL_REQUIREMENT.reduce((acc, current) => {
				if (current && HOTEL_FILTERS[hotelNum]) {
					acc.push(HOTEL_FILTERS[hotelNum]);
					hotelNum += 1;
				} else {
					acc.push(null);
				}
				return acc;
			}, []);
			dispatch({
				type: 'UPDATE_HOTEL_FILTERS',
				payload: hotelFilters,
			});

			sendMessageEvent({
				event: GoogleEventTypes.DATE_TABS_LOADED,
				chat_session_id: chatSessionId,
			});

			const travelSets = getTravelSetsAfterTripGeneration(
				dispatch,
				PAYLOAD,
				tripDetailsState,
			);

			dispatchUpdateChatMessages(
				dispatch,
				TripUpdateType.LOCATION_CONFIRMATION_ASSISTANT_REPORT,
				{
					messages: updatedChatMessages,
					isTyping: false,
				},
			);

			afterLocationConfirmationReport(
				dispatch,
				location,
				chatSessionId,
				numTravellers,
				inspirationContentIds,
				HOTEL_REQUIREMENT,
				LOCATIONS,
				SOURCE,
				DESTINATION,
				hotelFilters,
				FLIGHT_QUERY,
				response.CHAT_HISTORY,
				isFlightTransportationPresent,
				hotelSortCriterion,
				flightSortOption,
				travelSets,
				gMapsData,
			);
		}
	};

	const handleStreamResponse = (response) => {
		if (response.ok) {
			const reader = response.body.getReader();
			let accumulatedChunks = '';
			let completeJSONObjectsHandled = 0;

			function readChunk() {
				reader.read().then(({ done, value }) => {
					if (done) {
						return;
					}

					const chunkText = new TextDecoder().decode(value);
					if (chunkText === 'ERROR') {
						handleChatResponseError(
							'ERROR',
							chatMessages.messages,
							dispatch,
							false,
						);
						return;
					}
					accumulatedChunks += chunkText;
					const parsedJSON = parse(accumulatedChunks, Allow.ARR);
					if (parsedJSON && parsedJSON.length > completeJSONObjectsHandled) {
						for (
							let i = completeJSONObjectsHandled;
							i < parsedJSON.length;
							i += 1
						) {
							handleResponse(parsedJSON[i]);
						}
						completeJSONObjectsHandled = parsedJSON.length;
					}
					readChunk(); // Recursively read the next chunk
				});
			}

			readChunk(); // Start reading chunks
		}
	};

	const handleGenerateTrip = () => {
		const label = MessageLabel.LOCATION_CONFIRMATION_WIDGET_USER;

		const selectedPlaces = places.map(({ placeName }) => placeName);
		const userConfirmedLocations = {
			NUM_LOCATIONS: places.length,
			START_LOCATION: startLocation,
			SELECTED_PLACES: selectedPlaces,
			END_LOCATION: endLocation,
			IS_ORDER_FLEXIBLE: suggestOrder === 'suggest' ? 'Yes' : 'No',
		};

		discoverInspirationHelper({ places: selectedPlaces, dispatch });

		const addedCityImages = places.reduce((acc, cur) => {
			return [...acc, ...(cur.photos || [])];
		}, []);

		dispatch({
			type: 'UPDATE_ADDED_CITIES_IMAGES',
			payload: addedCityImages.sort(() => 0.5 - Math.random()), // random shuffle
		});

		const updatedLlmHistory = [
			...llmInteractionHistory,
			{
				SENDER: 'User',
				LABEL: label,
				CONTENT: JSON.stringify(userConfirmedLocations, undefined, 2),
			},
		];

		dispatch({
			type: 'UPDATE_LLM_INTERACTION_HISTORY',
			payload: updatedLlmHistory,
		});

		const updatedChatMessages = chatMessages.messages;

		const chatMessagesLength = chatMessages.messages.length;

		(updatedChatMessages[chatMessagesLength - 1]?.widget || {}).shouldShow =
			false;

		updatedChatMessages.push({
			sender: ChatMessageSender.USER,
			message: 'Destinations Confirmed!',
			widget: {
				...chatMessages.messages[chatMessagesLength - 1]?.widget,
				shouldShow: true,
			},
		});

		updatedChatMessages.push({
			sender: ChatMessageSender.AIRIAL,
			message: TRIP_GENERATION_MESSAGE,
			widget: {
				widgetType: ChatMessageWidgetType.TRIP_GENERATION_UPDATES_WIDGET,
				widgetData: [
					{
						id: 'identifying_route_and_connections',
						message: 'Identifying the route and the connections.',
						status: 0,
					},
				],
				shouldShow: true,
			},
		});

		dispatch({
			type: 'UPDATE_LOCATION_CONFIRMATION_WIDGET',
			payload: { submitted: true, expanded: false },
		});

		dispatchUpdateChatMessages(
			dispatch,
			TripUpdateType.LOCATION_CONFIRMATION_USER_SUBMITTED,
			{
				messages: updatedChatMessages,
				isTyping: true,
			},
		);
		setIsAddAnotherOpen(false);
		setShowWarningModal(false);

		sendMessageEvent({
			event: GoogleEventTypes.POST_LC_MESSAGE,
			chat_session_id: chatSessionId,
		});

		const confirmedLocations = {
			NUM_TRAVELLERS: numTravellers, // TODO: change this to the input from the form
			...userConfirmedLocations,
		};

		const postFormData = new FormData();

		postFormData.append(
			'confirmed_locations',
			JSON.stringify(confirmedLocations),
		);
		postFormData.append('chat_history', JSON.stringify(updatedLlmHistory));
		postFormData.append(
			'interaction_stage',
			InteractionStage.POST_LOCATION_CONFIRMATION_WIDGET,
		);
		postFormData.append('chat_session_id', chatSessionId);

		const backendURL = process.env.REACT_APP_BACKEND_URL;

		dispatch({
			type: 'UPDATE_OVERALL_TRIP_LOAD_STATUS',
			payload: false,
		});

		fetch(`${backendURL}/api/temp_post_location_clarification`, {
			method: 'POST',
			body: postFormData,
			timeout: 10000,
		})
			.then((response) => {
				handleStreamResponse(response);
			})
			.catch((error) => {
				handleChatResponseError(error, chatMessages.messages, dispatch, false);
			});
	};

	const onClickExpandToggle = () => {
		dispatch({
			type: 'UPDATE_LOCATION_CONFIRMATION_WIDGET',
			payload: { expanded: !locationConfirmationWidgetState.expanded },
		});
	};

	const handleSelectStartLocation = async (option) => {
		if (!option) {
			return;
		}

		const { value = '' } = option || {};

		if (startLocation === endLocation) {
			dispatch({
				type: 'UPDATE_LC_WIDGET_END_LOCATION',
				payload: value,
			});
		}

		dispatch({
			type: 'UPDATE_LC_WIDGET_START_LOCATION',
			payload: value,
		});
		setShowEditStartLocation(false);
	};

	const handleSelectEndLocation = async (option) => {
		if (!option) {
			return;
		}

		const { value = '' } = option || {};

		dispatch({
			type: 'UPDATE_LC_WIDGET_END_LOCATION',
			payload: value,
		});
		setShowEditEndLocation(false);
	};

	const onRadioClick = (event) => {
		if (locationConfirmationWidgetState.submitted) {
			return;
		}

		dispatch({
			type: 'UPDATE_LC_WIDGET_SUGGEST_ORDER',
			payload: event.target.value,
		});
	};

	const setSuggestOrder = (value) => {
		if (locationConfirmationWidgetState.submitted) {
			return;
		}

		dispatch({
			type: 'UPDATE_LC_WIDGET_SUGGEST_ORDER',
			payload: value,
		});
	};

	const IconToShowInStartLocation = showEditStartLocation
		? CrossIcon
		: EditPencil;

	const IconToShowInEndLocation = showEditEndLocation ? CrossIcon : EditPencil;

	useEffect(() => {
		if (isEmpty(responses)) {
			dispatch({
				type: 'UPDATE_LOCATION_CONFIRMATION_WIDGET',
				payload: {
					responses: {
						places: recommendedPlaces,
						numTravellers: predictedNumTravellers,
						startLocation: START_LOCATION,
						endLocation: END_LOCATION,
						suggestOrder: isOrderFlexible === 'Yes' ? 'suggest' : 'fixed',
					},
				},
			});
		}
	}, []);

	const expandButton = (
		<>
			<div className="font-medium" style={{ color: '#7750E5' }}>
				Expand
			</div>
			<IoIosArrowDown className="h-5 w-5" style={{ color: '#7750E5' }} />
		</>
	);

	const collapseButton = (
		<>
			<div className="font-medium" style={{ color: '#7750E5' }}>
				Collapse
			</div>
			<IoIosArrowUp className="h-5 w-5" style={{ color: '#7750E5' }} />
		</>
	);

	const expandedWidget = (
		<div
			className="rounded-lg flex flex-col gap-4 p-4"
			style={{
				boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
				backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
				margin: isChatViewCompressed ? 0 : '0 32px',
			}}
		>
			{locationConfirmationWidgetState.submitted ? (
				<div
					className="flex flex-row justify-between px-2 pb-2"
					style={{ borderBottom: '1px solid #F0ECFC' }}
				>
					<div className="font-semibold" style={{ color: '#574068' }}>
						Locations to Visit
					</div>
					<div
						role="presentation"
						className="flex gap-1 align-center cursor-pointer"
						onClick={onClickExpandToggle}
					>
						{collapseButton}
					</div>
				</div>
			) : null}

			<div
				className="flex pb-4 px-1 mb-1 justify-between flex-wrap gap-6"
				style={{ borderBottom: '1px solid #F0ECFC', color: '#301345' }}
			>
				<div className="flex gap-5 items-center">
					<div>No. of passengers</div>

					<div className="flex gap-2 items-center text-black">
						<button
							type="button"
							aria-label="minus"
							onClick={() =>
								dispatch({
									type: 'UPDATE_LC_WIDGET_NUM_OF_TRAVELLERS',
									payload:
										numTravellers === 1 ? numTravellers : numTravellers - 1,
								})
							}
						>
							<MinusIcon />
						</button>

						{numTravellers}

						<button
							type="button"
							aria-label="plus"
							onClick={() => {
								dispatch({
									type: 'UPDATE_LC_WIDGET_NUM_OF_TRAVELLERS',
									payload: numTravellers + 1,
								});
							}}
						>
							<PlusIcon />
						</button>
					</div>
				</div>
				{/* 
				<div style={{ borderRight: '2px solid #DDD8EC' }} />

				<div className="flex gap-5 items-center text-sm">
					<div>Budget</div>

					{['$', '$$', '$$$'].map((curOption) => {
						const isSelected = curOption === selectedBudget;

						return (
							<div
								className={`max-w-max px-4 py-2 border ${isSelected ? 'bg-primary-100' : 'bg-white'} border-primary-100 rounded-md font-medium
						 cursor-pointer text-sm`}
								onClick={() => setSelectedBudget(curOption)}
								role="presentation"
								style={{ color: '#301345' }}
							>
								{curOption}
							</div>
						);
					})}
				</div> */}
			</div>

			<div
				className="rounded-lg text-sm px-3 py-2 items-center flex bg-white gap-1 cursor-pointer"
				style={{
					width: 'max-content',
					...(startLocation !== START_LOCATION
						? {
								background:
									'linear-gradient(white, white) padding-box, linear-gradient(to right, #DC1F90, #BAA7F2) border-box',
								border: '1px solid transparent',
							}
						: { border: '1px solid #CDBAFF' }),
				}}
			>
				<div>Start :</div>
				{showEditStartLocation ? (
					<div className="mx-2">
						<AutoCompleteLocationSelect
							handleSelect={handleSelectStartLocation}
							value={startLocation}
						/>
					</div>
				) : (
					<div className="font-semibold">{startLocation}</div>
				)}

				<div
					className="cursor-pointer ml-2"
					role="presentation"
					onClick={() => {
						setShowEditStartLocation((prev) => !prev);
						setShowEditEndLocation(false);
					}}
				>
					<IconToShowInStartLocation size="18" />
				</div>
			</div>

			<GridProvider items={places} onMoveItem={handleMoveItem}>
				{({ moveItem }) => (
					<Grid>
						{places.map((item, index) => (
							<div
								className="flex flex-col gap-2"
								style={{
									width: isChatViewCompressed
										? 'calc(50% - 9px)'
										: 'calc(25% - 9px)',
									minWidth: '150px',
								}}
							>
								{!isOrderSuggested ? (
									<div
										className="px-4 py-3 rounded-lg font-bold"
										style={{
											backgroundColor: '#F1EEFC',
											color: '#7750E5',
											fontSize: '20px',
										}}
									>
										{index + 1}
									</div>
								) : null}

								<DragItem
									key={item.id}
									id={item.id}
									onMoveItem={moveItem}
									isOrderSuggested={isOrderSuggested}
									setShowWarningModal={setShowWarningModalIfNotSubmitted}
								>
									<GridItem>
										<Card
											details={item}
											setItems={setItemsIfNotSubmitted}
											setAlternatives={setAlternativesIfNotSubmitted}
											isCityAdded={!originalItemNames.includes(item.name)}
											noRemoveOption={places.length <= 1}
											places={places}
										/>
									</GridItem>
								</DragItem>
							</div>
						))}

						{locationConfirmationWidgetState.submitted ||
						isAddAnotherOpen ? null : (
							<div
								className="flex flex-col gap-2 cursor-pointer"
								style={{
									width: isChatViewCompressed
										? 'calc(50% - 9px)'
										: 'calc(25% - 9px)',
									minWidth: '150px',
								}}
							>
								{isOrderSuggested ? null : (
									<div
										className="h-11 w-full rounded-lg font-bold"
										style={{
											backgroundColor: '#F1EEFC',
										}}
									/>
								)}
								<div
									className="p-1 rounded-lg"
									style={{
										border: '1px #7750E5 dotted',
										width: '100%',
										height: '154px',
									}}
									onClick={() => setIsAddAnotherOpen(true)}
									role="presentation"
								>
									<div
										style={{
											width: '100%',
											height: '100%',
											paddingTop: '32px',
											gap: '2px',
										}}
										className="bg-white rounded-lg text-center flex flex-col"
									>
										<div>
											<AddIcon style={{ color: '#7750E5' }} fontSize="small" />
										</div>

										<div className="font-semibold">Add Another</div>
										<div style={{ color: '#7F6D8B' }} className="text-xs">
											(View {alternativePlaces.length} more suggestions)
										</div>
									</div>
								</div>
							</div>
						)}
					</Grid>
				)}
			</GridProvider>

			<ModalComponent
				open={showWarningModal}
				handleClose={() => setShowWarningModalIfNotSubmitted(false)}
				setSuggestOrder={setSuggestOrder}
			/>

			<AddAnotherCity
				isAddAnotherOpen={isAddAnotherOpen}
				setIsAddAnotherOpen={setIsAddAnotherOpen}
				setItems={setItemsIfNotSubmitted}
				alternatives={alternatives}
				places={places}
				setAlternatives={setAlternativesIfNotSubmitted}
			/>

			<div
				className="rounded-lg text-sm px-3 py-2 items-center flex bg-white gap-1 cursor-pointer"
				style={{
					width: 'max-content',
					...(endLocation !== END_LOCATION
						? {
								background:
									'linear-gradient(white, white) padding-box, linear-gradient(to right, #DC1F90, #BAA7F2) border-box',
								border: '1px solid transparent',
							}
						: { border: '1px solid #CDBAFF' }),
				}}
			>
				<div>End :</div>
				{showEditEndLocation ? (
					<div className="mx-2">
						<AutoCompleteLocationSelect
							handleSelect={handleSelectEndLocation}
							value={endLocation}
						/>
					</div>
				) : (
					<div className="font-semibold">{endLocation}</div>
				)}

				<div
					className="cursor-pointer ml-2"
					role="presentation"
					onClick={() => setShowEditEndLocation((prev) => !prev)}
				>
					<IconToShowInEndLocation size="18" />
				</div>
			</div>

			<div
				style={{ borderTop: '1px solid #DDD8EC' }}
				className="pt-2 flex items-center justify-between"
				key={suggestOrder}
			>
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					defaultValue="female"
					value={suggestOrder}
					onChange={onRadioClick}
					name="radio-buttons-group"
				>
					<FormControlLabel
						value="suggest"
						control={<Radio size="small" />}
						label={
							<div className="text-sm font-medium" style={{ color: '#301345' }}>
								Suggest the best possible route
							</div>
						}
					/>
					<FormControlLabel
						value="fixed"
						control={<Radio size="small" />}
						label={
							<div className="text-sm font-medium" style={{ color: '#301345' }}>
								I’ll choose the order
							</div>
						}
					/>
				</RadioGroup>

				{locationConfirmationWidgetState.submitted ? null : (
					<Button
						variant="contained"
						type="button"
						className={styles.generate_trip_button}
						onClick={handleGenerateTrip}
						style={{
							textTransform: 'unset',
						}}
					>
						Generate Trip
					</Button>
				)}
			</div>
		</div>
	);

	const collapsedWidget = (
		<div
			className="flex flex-row justify-between px-6 rounded-lg py-4"
			style={{
				boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
				backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
				margin: isChatViewCompressed ? 0 : '0 32px',
			}}
		>
			<div className="font-medium" style={{ color: '#574068' }}>
				Locations to Visit
			</div>
			<div
				role="presentation"
				className="flex gap-1 align-center cursor-pointer"
				onClick={onClickExpandToggle}
			>
				{expandButton}
			</div>
		</div>
	);

	return locationConfirmationWidgetState.expanded
		? expandedWidget
		: collapsedWidget;
}

export default LocationConfirmation;

import TooltipWrapper from '@helpers/TooltipWrapper';
import airlinesArray from '@helpers/airlines';

const getLogoWithTooltip = (
	logos,
	logoIndex,
	className,
	airlinesMap,
	airlines,
) => {
	return (
		<TooltipWrapper tooltipContent={airlinesMap[airlines[logoIndex]]}>
			<img className={className} src={logos[logoIndex]} alt="logo" />
		</TooltipWrapper>
	);
};

function Logos({ airlines }) {
	const logos = airlines.map(
		(airline) =>
			`//images.kiwi.com/airlines/64x64/${airline}.png?default=airline.png`,
	);
	const count = logos.length;
	const logoSize = count === 1 ? 'w-8 h-8' : 'w-4 h-4';
	const airlinesMap = airlinesArray.reduce((acc, curr) => {
		acc[curr.id] = curr.name;
		return acc;
	}, {});

	const renderLogos = () => {
		if (count === 1) {
			return getLogoWithTooltip(logos, 0, logoSize, airlinesMap, airlines);
		}
		if (count === 2) {
			return (
				<div className="relative w-9 h-9">
					{getLogoWithTooltip(
						logos,
						0,
						`${logoSize} absolute top-0 left-0`,
						airlinesMap,
						airlines,
					)}
					{getLogoWithTooltip(
						logos,
						1,
						`${logoSize} absolute bottom-0 right-0`,
						airlinesMap,
						airlines,
					)}
				</div>
			);
		}

		return (
			<div className="grid grid-cols-2 grid-rows-2 gap-1">
				{getLogoWithTooltip(logos, 0, logoSize, airlinesMap, airlines)}
				{getLogoWithTooltip(logos, 1, logoSize, airlinesMap, airlines)}
				{getLogoWithTooltip(logos, 2, logoSize, airlinesMap, airlines)}
				{count === 4 &&
					getLogoWithTooltip(logos, 3, logoSize, airlinesMap, airlines)}
			</div>
		);
	};

	return <div className="w-9 h-9">{renderLogos()}</div>;
}

export default Logos;

import React from 'react';

function MapPointer({ number }) {
	return (
		<div className="place-marker">
			<svg
				width="24"
				height="24"
				viewBox="0 0 36 36"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M18.2383 29.2264C16.0688 29.2264 13.2425 26.534 10.8554 23.6459C7.43141 19.5035 5.39844 17.0846 5.39844 12.853C5.39844 6.12138 11.1591 0.643555 18.2383 0.643555C23.5097 0.643555 28.0501 3.68096 30.0252 8.01169C31.4027 11.0322 30.95 14.5778 29.5995 17.6105C28.5779 19.9044 27.1761 21.8798 25.2821 24.2554C24.2914 25.4982 25.9799 23.377 25.2821 24.2554C23.631 26.3342 20.8062 29.2264 18.2383 29.2264Z"
					fill="#6537ef"
				/>
				<path
					d="M25.2821 24.2554C27.1761 21.8798 28.5779 19.9044 29.5995 17.6105C30.95 14.5778 31.4027 11.0322 30.0252 8.01169C28.0501 3.68096 23.5097 0.643555 18.2383 0.643555C11.1591 0.643555 5.39844 6.12138 5.39844 12.853C5.39844 17.0846 7.43141 19.5035 10.8554 23.6459C13.2425 26.534 16.0688 29.2264 18.2383 29.2264C20.8062 29.2264 23.631 26.3342 25.2821 24.2554ZM25.2821 24.2554C24.2914 25.4982 25.9799 23.377 25.2821 24.2554Z"
					stroke="white"
					strokeWidth="0.5"
				/>
				<path
					d="M21.135 32.9542C21.135 34.3269 20.0222 35.4397 18.6495 35.4397C17.2768 35.4397 16.1641 34.3269 16.1641 32.9542C16.1641 31.5815 17.2768 30.4688 18.6495 30.4688C20.0222 30.4688 21.135 31.5815 21.135 32.9542Z"
					fill="#6537ef"
					stroke="white"
					strokeWidth="0.5"
				/>
				{number && (
					<text
						x="50%"
						y="50%"
						dy="0px"
						fontSize="14"
						fontFamily="sans-serif"
						fontWeight="600"
						textAnchor="middle"
						fill="#FFF"
					>
						{number}
					</text>
				)}
			</svg>
		</div>
	);
}

export default MapPointer;

import {
	formatDate,
	incrementOneDay,
	decrementOneDay,
} from '@helpers/DateUtils';

const ARRIVAL_OFFSET = 4;
const DEPARTURE_OFFSET = 23;

const hotelDateSetHelper = (
	locations,
	hotelRequirement,
	flightLegs,
	travelSets,
) => {
	let num = 0;
	const resultArray = travelSets.reduce((acc, travelDetail) => {
		const { type = '', nonFlightDetails, flightDetails } = travelDetail;

		if (['flight', 'FLIGHT'].includes(type) && flightLegs[num]) {
			acc.push(flightLegs[num]);
		}

		if (['flight', 'FLIGHT'].includes(type) && !flightLegs[num]) {
			const { dateOfJourney } = flightDetails || {};

			acc.push({
				arrivalDate: formatDate(dateOfJourney),
				departureDate: formatDate(dateOfJourney),
				departureTime: '10:00',
				arrivalTime: '14:00',
			});
		}

		if (!['flight', 'FLIGHT'].includes(type)) {
			const { dateOfArrival, dateOfJourney, timeOfArrival, timeOfDeparture } =
				nonFlightDetails || {};

			acc.push({
				arrivalDate: formatDate(dateOfArrival || dateOfJourney),
				departureDate: formatDate(dateOfJourney),
				departureTime: timeOfDeparture || '10:00',
				arrivalTime: timeOfArrival || '14:00',
			});
		}

		if (['flight', 'FLIGHT'].includes(type)) {
			num += 1;
		}

		return acc;
	}, []);

	const hotelDateSets = locations.reduce((acc, _, ind) => {
		if (!hotelRequirement[ind]) {
			return [...acc, null];
		}

		const { arrivalDate, arrivalTime = '' } = resultArray[ind];

		const { departureDate, departureTime } = resultArray[ind + 1] || {};

		const [arrivalHourInString] = arrivalTime.split(':');
		const [departureHourInString] = (departureTime || '').split(':');

		const arrivalHour = Number(arrivalHourInString);
		const departureHour = Number(departureHourInString);

		if (ind < (resultArray || []).length - 1) {
			return [
				...acc,
				{
					CHECK_IN_DATE:
						arrivalHour < ARRIVAL_OFFSET
							? decrementOneDay(arrivalDate)
							: arrivalDate,
					CHECK_OUT_DATE:
						departureHour > DEPARTURE_OFFSET
							? incrementOneDay(departureDate)
							: departureDate,
				},
			];
		}

		return acc;
	}, []);

	return hotelDateSets;
};

export default hotelDateSetHelper;

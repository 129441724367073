import ArrowDownIcon from '@commons/icons/ArrowDownIcon';
import { useState } from 'react';

function TransitTravelMode({
	transit_details = {},
	transit = null,
	distance,
	duration,
}) {
	const {
		arrival_stop = {},
		arrival_time: transit_arrival_time = {},
		departure_stop = {},
		departure_time: transit_departure_time = {},
		line = {},
		num_stops = 0,
		headsign = '',
		trip_short_name = '',
	} = transit || transit_details;

	const {
		color = '',
		vehicle = {},
		text_color = '',
		short_name = '',
		agencies = [],
	} = line;

	const { icon = '' } = vehicle;
	const { name: agencyName = '', url = '', phone = '' } = agencies[0] || {};

	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="flex flex-col" style={{ lineHeight: '16px' }}>
			<div className="flex items-center gap-2">
				<div
					style={{
						whiteSpace: 'nowrap',
						width: '72px',
						minWidth: '72px',
					}}
					className="flex justify-center"
				>
					{transit_departure_time.text}
				</div>

				<div className="flex items-center gap-4">
					<div
						style={{
							width: '10px',
							height: '10px',
							border: '2px solid #202124',
							backgroundColor: '#fff',
							borderRadius: '50%',
							marginLeft: '-2px',
							zIndex: '99',
						}}
					/>
					<h2 className="font-semibold">{departure_stop?.name}</h2>
				</div>
			</div>

			<div className="flex gap-2">
				<div
					className="flex items-center justify-center"
					style={{
						whiteSpace: 'nowrap',
						width: '72px',
						minWidth: '72px',
						marginBottom: '14px',
					}}
				>
					<img width="20px" height="20px" src={icon} alt="icon" />
				</div>

				<div className="flex w-full">
					<div
						style={{
							backgroundColor: color.includes('fff') ? '#071458' : color,
							backgroundPosition: 'left top',
							backgroundRepeat: 'repeat-y',
							backgroundSize: '5px 10px',
							minWidth: '6px',
							marginTop: '-8px',
							marginBottom: '-8px',
							maxWidth: '6px',
						}}
					/>

					<div
						className="flex flex-col ml-4 py-4 gap-3 w-full my-5"
						style={{
							borderTop: '1px solid #E3DAFA',
							borderBottom: '1px solid #E3DAFA',
						}}
					>
						<div className="flex">
							<div
								className="px-2 py-1 rounded-md text-sm"
								style={{
									color: color.includes('fff') ? '#fff' : text_color,
									background: color.includes('fff') ? '#071458' : color,
								}}
							>
								{short_name}
							</div>
						</div>

						{trip_short_name && headsign ? (
							<div className="text-sm font-normal opacity-80">
								{trip_short_name}, {headsign}
							</div>
						) : null}
						<div
							className="flex items-center cursor-pointer gap-1"
							role="presentation"
							onClick={() => {
								setIsOpen((prev) => !prev);
							}}
						>
							<ArrowDownIcon style={{ color: '#7F6D8B' }} />

							<div className="text-sm" style={{ color: '#7F6D8B' }}>
								About {duration?.text}, {distance.text} ({num_stops} stops)
							</div>
						</div>

						{agencyName && isOpen ? (
							<div className="flex gap-1 text-sm ml-1 items-center whitespace-nowrap flex-wrap">
								Service run by
								<a
									className="flex gap-2 items-center cursor-pointer text-highlight font-medium hover:underline"
									target="_blank"
									href={url}
									rel="noreferrer"
								>
									{agencyName}
								</a>
								{phone ? <div>call at {phone}</div> : null}
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div className="flex items-center gap-2">
				<div
					style={{
						whiteSpace: 'nowrap',
						width: '72px',
						minWidth: '72px',
					}}
					className="flex justify-center"
				>
					{transit_arrival_time.text}
				</div>

				<div className="flex items-center gap-4">
					<div
						style={{
							width: '10px',
							height: '10px',
							border: '2px solid #202124',
							backgroundColor: '#fff',
							borderRadius: '50%',
							marginLeft: '-2px',
						}}
					/>
					<h2 className="font-semibold">{arrival_stop?.name}</h2>
				</div>
			</div>
		</div>
	);
}

export default TransitTravelMode;

import { useSelector } from 'react-redux';
import { LoadingState } from '@helpers/Enums';
import FlightWidgets from './FlightWidgets';
import HotelWidgets from './HotelWidgets';
import ItineraryOverview from './ItineraryOverview/ItineraryOverview';
import TripGenerationSlideshow from './TripGenerationSlideshow/TripGenerationSlideshow';
import TabChangeInfoComponent from './TabChangeInfoComponent';

function ResultsOverview({
	setCurrentView = () => {},
	tabChanged = false,
	handleUpdateHotelsAndItinerary = () => {},
}) {
	const loadingState = useSelector((state) => state.ui_states.loadingState);
	const isTripFullyLoaded = useSelector(
		(state) => state.ui_states.isTripFullyLoaded ?? true,
	);
	const { itineraryLoading = LoadingState.INIT } = loadingState;

	return (
		<div className="flex flex-col gap-6">
			<FlightWidgets setCurrentView={setCurrentView} />
			{tabChanged ? (
				<TabChangeInfoComponent
					handleUpdateHotelsAndItinerary={handleUpdateHotelsAndItinerary}
				/>
			) : null}
			<HotelWidgets setCurrentView={setCurrentView} tabChanged={tabChanged} />
			<TripGenerationSlideshow
				isItineraryLoaded={
					itineraryLoading === LoadingState.LOADED && isTripFullyLoaded
				}
			/>
			<ItineraryOverview
				setCurrentView={setCurrentView}
				tabChanged={tabChanged}
			/>
		</div>
	);
}

export default ResultsOverview;

import { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import styles from './styles.module.css';
import Topbar from '../Topbar/Topbar';

function Layout({ children }) {
	const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

	return (
		<div className={styles.container}>
			<Topbar />
			<main className={styles.children_container}>{children}</main>
			<Navbar
				isNavbarExpanded={isNavbarExpanded}
				setIsNavbarExpanded={setIsNavbarExpanded}
			/>
		</div>
	);
}

export default Layout;

function FlightIcon({ width, height, stroke }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '20'}
			height={height || '20'}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M17.4936 4.12498C17.4957 4.12708 17.4958 4.13048 17.4938 4.13268L14.5094 7.36975C14.3299 7.56441 14.0611 7.65002 13.8021 7.59501L5.68568 5.87085C5.43729 5.81809 5.17907 5.89459 4.99951 6.07415L4.49257 6.58109C4.14417 6.92949 4.22064 7.51348 4.64702 7.76042L10.0541 10.8921C10.4683 11.1319 10.5549 11.6931 10.2323 12.0466L7.08151 15.4997C6.90374 15.6945 6.63669 15.7816 6.37822 15.7291L4.75217 15.3989C4.50565 15.3489 4.25045 15.4257 4.07258 15.6036L3.78439 15.8918C3.43696 16.2392 3.51187 16.8213 3.93593 17.0694L5.65801 18.0771C5.76907 18.1421 5.86155 18.2346 5.92654 18.3457L6.93424 20.0677C7.18239 20.4918 7.76446 20.5667 8.11189 20.2193L8.40008 19.9311C8.57795 19.7532 8.65481 19.498 8.60474 19.2515L8.27453 17.6254C8.22204 17.367 8.30918 17.0999 8.504 16.9222L11.9571 13.7714C12.3106 13.4488 12.8717 13.5354 13.1116 13.9495L16.2432 19.3566C16.4902 19.783 17.0742 19.8595 17.4226 19.5111L17.9295 19.0042C18.1091 18.8246 18.1856 18.5664 18.1328 18.318L16.4087 10.2015C16.3536 9.94253 16.4393 9.67372 16.6339 9.49426L19.8749 6.50625C20.5274 5.91375 20.9024 5.06625 20.9024 4.18125C20.9024 3.58125 20.4149 3.09375 19.8149 3.09375C18.9317 3.09375 18.0858 3.45979 17.4934 4.11733C17.4915 4.11952 17.4915 4.12289 17.4936 4.12498V4.12498Z"
				stroke={stroke || '#574068'}
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default FlightIcon;

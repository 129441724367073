import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import InfoBannerContent from '@commons/InfoBannerContent';
import { fetchPhotosBatch } from '@helpers/PhotosQueryHelper';
import ItinerarySingleDayCard from '../ItinerarySingleDayCard/ItinerarySingleDayCard';
import styles from './styles.module.css';

function ItineraryDayCards({
	dayCardsDataList,
	onPlaceClicked = (placeName) => {},
	shouldAnimate = false,
	dayIndex = 0,
	sparkEnabled = false,
	highlightCardEnabled = false,
	showMore = true,
	showEducationPopver = false,
	isLoaded = false,
	formattedGMapsData = {},
	places = [],
}) {
	const dispatch = useDispatch();
	const { gMapsData = {} } = useSelector(({ ui_states = {} }) => ({
		gMapsData: ui_states.gMapsData,
	}));

	const showTripControlsPopover = useSelector(
		(state) => state.ui_states.showTripControlsPopover,
	);

	const showDayPlansPopover = useSelector(
		(state) => state.ui_states.showDayPlansPopover,
	);

	const showUserEducationPopoverForDayPlans =
		useSelector(
			(state) =>
				state.profile?.user_preferences?.show_user_education_popover
					?.show_day_plans_popover,
		) ?? true;

	const [processedPlaces, setProcessedPlaces] = useState(new Set()); // Tracks PLACE_NAMEs of activities processed
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);

		dispatch({
			type: 'UPDATE_DAY_PLANS_POPOVER',
			payload: false,
		});
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		if (!showUserEducationPopoverForDayPlans && !showTripControlsPopover) {
			dispatch({
				type: 'UPDATE_DAY_PLANS_POPOVER',
				payload: false,
			});
		}

		if (
			!showDayPlansPopover ||
			!showUserEducationPopoverForDayPlans ||
			showTripControlsPopover ||
			!showEducationPopver ||
			!dayCardsDataList.length ||
			!isLoaded
		) {
			return;
		}

		const element = document.getElementById('element');

		element.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'nearest',
		});

		setTimeout(() => {
			const defaultAnchorEl = document.getElementById('first-card');
			setAnchorEl(defaultAnchorEl);
		}, 500);
	}, [showTripControlsPopover, isLoaded]);

	useEffect(() => {
		const placesToFetch = [];

		dayCardsDataList?.forEach((data) => {
			if (
				data.placeName &&
				!gMapsData[data.placeName] &&
				data.description &&
				!processedPlaces.has(data.placeName)
			) {
				processedPlaces.add(data.placeName);
				placesToFetch.push(data.placeName);
			}
		});

		fetchPhotosBatch(placesToFetch, dispatch);
	}, [dayCardsDataList, gMapsData, dispatch, processedPlaces]);

	const getDayCard = (data, index) => {
		return shouldAnimate ? (
			<div
				className={styles.card}
				style={{
					animationDuration: `${(index + 1) * 200}ms`,
					animationDelay: `${index * 100}ms`,
				}}
				key={`day_card_${data.name}_${data.timeStart}`}
			>
				<ItinerarySingleDayCard
					dayCardData={data}
					index={index}
					onPlaceClicked={onPlaceClicked}
					dayIndex={dayIndex}
					sparkEnabled={sparkEnabled}
					highlightCardEnabled={highlightCardEnabled}
					showMore={showMore}
					placeIndex={Object.keys(formattedGMapsData).indexOf(places[index])}
				/>
			</div>
		) : (
			<div
				id={showEducationPopver && !dayIndex && !index ? 'first-card' : null}
			>
				<ItinerarySingleDayCard
					dayCardData={data}
					index={index}
					onPlaceClicked={onPlaceClicked}
					key={`day_card_${data.name}_${data.timeStart}`}
					dayIndex={dayIndex}
					sparkEnabled={sparkEnabled}
					highlightCardEnabled={highlightCardEnabled}
					showMore={showMore}
					highlightCardForUserEducation={
						showEducationPopver && !dayIndex && !index && open
					}
					placeIndex={Object.keys(formattedGMapsData).indexOf(places[index])}
				/>
			</div>
		);
	};

	return (
		<div className="mt-2 flex flex-1 flex-col overflow-auto pb-4" id="element">
			{dayCardsDataList?.map((data, index) => getDayCard(data, index))}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						style: { marginLeft: 12 },
					},
				}}
			>
				<InfoBannerContent
					mainText="Manage your itinerary with ease: view your schedule, make edits, add new plans, and delete any items as needed"
					subText={null}
					title="Edit your Itinerary from here."
					handleClose={handleClose}
					popoverName="show_day_plans_popover"
				/>
			</Popover>
		</div>
	);
}

export default ItineraryDayCards;

import { useSelector, useDispatch } from 'react-redux';
import CheckCircleDefault from '@commons/icons/CheckCircleDefault';
import CheckCircleselected from '@commons/icons/CheckCircleSelected';
import { TripUpdateType } from '@helpers/Enums';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { useLocation } from 'react-router-dom';
import hotelDateSetHelper from '@helpers/hotelDateSetHelper';
import { fetchDayPlansAndDispatch } from '@helpers/dayPlansQueryHelper';
import styles from './styles.module.css';

function FlightPriceComponent({ itinerary = {}, isCardSelected = false }) {
	const dispatch = useDispatch();
	const location = useLocation();

	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const {
		hotelFilters = [],
		hotelReqs = [],
		hotelSets,
		numTravellers,
		places = [],
		startLocation,
		endLocation,
	} = tripLocationDetails;

	const { travelSets = [] } = tripTransitionsDetails;

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const selectedFlightByDateOption = useSelector(
		(state) => state.ui_states.selectedFlightByDateOption,
	);

	const roundTo2Places = (number) => {
		return Math.round((number + Number.EPSILON) * 100) / 100;
	};

	const handleBookNow = () => {
		if (itinerary.legs[0].bookingLink != null) {
			sendMessageEvent({
				event: GoogleEventTypes.FLIGHTS_VIEW_BOOK_SELECTED_FLIGHT,
				chat_session_id: chatSessionId,
			});

			window.open(itinerary.legs[0].bookingLink, '_blank');
		}
	};

	const handleSelect = () => {
		if (!isCardSelected) {
			sendMessageEvent({
				event: GoogleEventTypes.FLIGHTS_VIEW_CHANGE_SELECTED_FLIGHT,
				chat_session_id: chatSessionId,
			});

			dispatch({
				type: 'UPDATE_SELECTED_FLIGHT_BY_DATE_OPTION',
				payload: selectedFlightByDateOption.map(
					(selectedFlightForDateOption, index) =>
						index === activeTabIndex ? itinerary : selectedFlightForDateOption,
				),
			});
			dispatch({
				type: 'UPDATE_SELECTED_FLIGHT',
				payload: itinerary,
			});
			dispatch({
				type: 'CHECKPOINT',
				payload: TripUpdateType.FLIGHT_SELECTION_CHANGED_USER,
			});

			const hotelDateSets = hotelDateSetHelper(
				places,
				hotelReqs,
				itinerary?.legs || [],
				travelSets[activeTabIndex],
			);

			const isDateSetsEqual =
				JSON.stringify(hotelDateSets) === JSON.stringify(hotelSets[0]);

			if (!isDateSetsEqual) {
				handleFindHotels({
					dispatch,
					location,
					hotel_set_data: {
						hotel_datesets: [hotelDateSets],
						hotelFilters,
						NUM_TRAVELLERS: numTravellers,
						HOTEL_REQUIREMENT: hotelReqs,
						sortCriterion: hotelSortCriterion,
					},
					tabIndex: activeTabIndex,
					gMapsData,
					chatSessionId,
				});
			}

			const dayPlansQuery = {
				locations: {
					startLocation,
					endLocation,
					places,
				},
				chat_history: llmInteractionHistory,
			};

			fetchDayPlansAndDispatch(
				dispatch,
				{
					...dayPlansQuery,
					travelSets: travelSets[activeTabIndex],
					flightSelections: (
						selectedFlightByDateOption[activeTabIndex]?.legs || []
					).map(
						({
							arrivalDate,
							arrivalTime,
							destination,
							source,
							departureTime,
							departureDate,
						}) => ({
							fromAirport: source,
							toAirport: destination,
							departureDate,
							departureTime,
							arrivalDate,
							arrivalTime,
						}),
					),
				},
				chatSessionId,
				activeTabIndex,
			);
		}
	};

	const IconToShow = isCardSelected ? CheckCircleselected : CheckCircleDefault;

	return (
		<div
			className="flex flex-col items-center gap-3 m-auto py-4"
			style={{
				borderLeft: '2px solid #F0ECFC',
				paddingLeft: '20px',
			}}
		>
			<div style={{ color: '#301345', fontSize: '12px' }}>
				<span
					style={{ fontSize: '24px', marginRight: '2px' }}
				>{`$${roundTo2Places(itinerary.totalPrice)}/`}</span>
				Total
			</div>

			<button
				id="flight_option_select"
				className={`${styles.select_button} ${isCardSelected && styles.selected}`}
				onClick={handleSelect}
				type="button"
				style={{ display: 'flex' }}
			>
				<div className="flex items-center gap-1" style={{ margin: 'auto' }}>
					{isCardSelected ? 'Selected' : 'Select'}
					<IconToShow />
				</div>
			</button>

			<button
				id="flight_option_select"
				className={styles.book_now_button}
				onClick={handleBookNow}
				type="button"
			>
				Book now
			</button>
		</div>
	);
}

export default FlightPriceComponent;

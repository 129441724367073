import React, { useState, useEffect } from 'react';
import TiktokIcon from '@commons/icons/TiktokIcon';
import InstagramIcon from '@commons/icons/InstagramIcon';
import styles from './styles.module.css';

function AddInspirationButton({
	onAddInspirationClicked,
	tiktokText,
	igText,
	smallerVersion = false,
}) {
	const [isTikTok, setIsTikTok] = useState(true);
	const [flip, setFlip] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setFlip((prev) => !prev);
			setTimeout(() => {
				setIsTikTok((prev) => !prev);
			}, 300);
		}, 4000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div
			id="add_inspiration_button"
			style={{ width: smallerVersion ? '180px' : '200px' }}
			className={`${styles.tiktok_button} space-x-2 cursor-pointer ${smallerVersion ? 'p-2' : 'p-3'}`}
			onClick={onAddInspirationClicked}
			role="presentation"
		>
			<div className={styles.flipCard}>
				<div className={`${styles.flipCardInner} ${flip ? styles.flip : ''}`}>
					<div className={styles.flipCardFront}>
						<TiktokIcon />
					</div>
					<div className={styles.flipCardBack}>
						<InstagramIcon />
					</div>
				</div>
			</div>
			<div className={styles.button_text}>{isTikTok ? tiktokText : igText}</div>
		</div>
	);
}

export default AddInspirationButton;

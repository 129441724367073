import React, { useState, useEffect } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useSelector } from 'react-redux';

export default function TripGenerationUpdatesWidget({ widgetData = {} }) {
	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);

	const isFullyLoaded = !widgetData.map((x) => x.status).includes(0);
	const [expanded, setExpanded] = useState(!isFullyLoaded);

	useEffect(() => {
		if (isFullyLoaded) {
			setTimeout(() => {
				setExpanded(false);
			}, 2000);
		}
	}, [isFullyLoaded]);

	const onClickExpandToggle = () => {
		setExpanded(!expanded);
	};

	const expandButton = (
		<>
			<div className="font-medium" style={{ color: '#7750E5' }}>
				Expand
			</div>
			<IoIosArrowDown className="h-5 w-5" style={{ color: '#7750E5' }} />
		</>
	);

	const collapseButton = (
		<>
			<div className="font-medium" style={{ color: '#7750E5' }}>
				Collapse
			</div>
			<IoIosArrowUp className="h-5 w-5" style={{ color: '#7750E5' }} />
		</>
	);

	const widgetTitle = isFullyLoaded ? 'Trip Generated' : 'Generating Trip';

	const expandedWidget = (
		<div
			style={{
				boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
				color: '#301345',
				backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
				margin: isChatViewCompressed ? 0 : '0 32px',
			}}
			className="rounded-lg px-5 py-4"
		>
			<div
				className="flex flex-row justify-between"
				style={{ borderBottom: '1px solid #F0ECFC' }}
			>
				<div className="font-semibold pb-2" style={{ color: '#574068' }}>
					{widgetTitle}
				</div>
				<div
					role="presentation"
					className="flex gap-1 align-center cursor-pointer"
					onClick={onClickExpandToggle}
				>
					{collapseButton}
				</div>
			</div>

			<div className="flex flex-col gap-4 mt-4">
				{widgetData.map(({ message: msg, status: sts, id: idx }) => {
					return (
						<div key={idx} className="flex justify-between">
							<div>{msg}</div>
							{sts === 1 ? (
								<div className="font-semibold" style={{ color: '#574068' }}>
									Done
								</div>
							) : null}
						</div>
					);
				})}
			</div>
		</div>
	);

	const collapsedWidget = (
		<div
			style={{
				boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
				color: '#301345',
				backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
				margin: isChatViewCompressed ? 0 : '0 32px',
			}}
			className="flex flex-row justify-between rounded-lg px-4 py-4"
		>
			<div className="font-medium" style={{ color: '#574068' }}>
				{widgetTitle}
			</div>
			<div
				role="presentation"
				className="flex gap-1 align-center cursor-pointer"
				onClick={onClickExpandToggle}
			>
				{expandButton}
			</div>
		</div>
	);

	return expanded ? expandedWidget : collapsedWidget;
}

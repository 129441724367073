import { HotelSortCriterion } from '@helpers/Enums';

/* eslint-disable default-param-last */
const initialState = {
	numTravellers: 1,
	budget: '$$',
	numPlaces: null,
	startLocation: '',
	places: [],
	endLocation: '',
	hotelFilters: [
		{
			AMENITIES: [],
			LATITUDE: '',
			LOCALITY: 'N/A',
			LONGITUDE: '',
			MAX_PRICE: -1,
			MIN_STAR_RATING: -1,
			NUM_ROOMS: 1,
		},
	],
	hotelReqs: [],
	hotelSortCriterion: HotelSortCriterion.BEST,
	hotelSets: [],
};

export default function locationsReducer(state = initialState, action) {
	switch (action.type) {
		case 'RESET_STATE':
			return { ...initialState };
		case 'UPDATE_NUM_TRAVELLERS':
			return { ...state, numTravellers: action.payload };
		case 'UPDATE_LOCATIONS_REDUCER':
			return { ...state, ...action.payload };
		case 'UPDATE_HOTEL_DATESETS_V1':
			return { ...state, hotelSets: action.payload };
		case 'UPDATE_HOTEL_FILTERS':
			return { ...state, hotelFilters: action.payload };
		case 'UPDATE_HOTEL_SORT_OPTION':
			return { ...state, hotelSortCriterion: action.payload };
		default:
			return state;
	}
}

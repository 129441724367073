import NumberInputController from '@commons/forms/page-components/controlled/NumberInputController';
import TextInputController from '@commons/forms/page-components/controlled/TextInputController';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useState } from 'react';
import { handleLookupAirports } from '@helpers/ModelApiUtils';
import ToggleSwitchController from '@commons/forms/page-components/controlled/ToggleSwitchController';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useSelector } from 'react-redux';
import AutoCompleteLocationSelect from '@commons/forms/page-components/components/AutoCompleteLocationSelect';
import axios from 'axios';
import styles from './styles.module.css';

function incrementDays(dateString, noOfDays) {
	let date = dayjs(dateString);
	date = date.add(noOfDays, 'day');

	return date.format('YYYY-MM-DD');
}

const schema = Yup.object().shape({
	no_of_nights: Yup.string().required('This is required'),
});

const controls = [
	{
		label: 'Location Name',
		name: 'city_name',
		type: 'location_select',
	},
	{
		label: 'No. of nights',
		name: 'no_of_nights',
		type: 'input_number',
	},
	{
		label: 'Hotel Required',
		name: 'hotel_required',
		type: 'toggle_switch',
	},
];

const CONTROLLER_MAPPING = {
	text: TextInputController,
	input_number: NumberInputController,
	toggle_switch: ToggleSwitchController,
};

function AddCityToTrip({
	setEditDetails = () => {},
	setTripDetails = () => {},
	tripDetails = [],
	index = 0,
	setTripModificationDetails = () => {},
	localActiveTabIndex = 0,
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const [airportError, setAirPortError] = useState({
		error: false,
		message: '',
	});
	const [selectedCityName, setSelectedCityName] = useState('');
	const [coordinatesCache, setCoordinatesCache] = useState({});

	const handleSelectLocation = async (option) => {
		if (!option) {
			return;
		}

		const { value = '', coordinates } = option || {};
		setCoordinatesCache((prev) => ({ ...prev, [value]: coordinates }));

		setSelectedCityName(value);
	};

	const {
		control,
		formState: { errors },
		handleSubmit = () => {},
	} = useForm({
		defaultValues: {
			no_of_nights: 2,
			hotel_required: true,
		},
		resolver: yupResolver(schema),
	});

	const tripDetailsForDateOption = tripDetails[localActiveTabIndex];

	const onSubmit = async (values) => {
		sendMessageEvent({
			event: GoogleEventTypes.TRIP_CONTROLS_CONFIRM_ADD_LOCATION_CHANGES,
			chat_session_id: chatSessionId,
		});

		try {
			const { no_of_nights = '', hotel_required = false } = values;

			const airportCodes = await handleLookupAirports({
				city_list: [selectedCityName],
			});

			let coordinatesOfLocation = [];

			if (hotel_required && !coordinatesCache[selectedCityName]) {
				const response = await axios.get(
					`${process.env.REACT_APP_BACKEND_URL}/api/search_place`,
					{
						params: {
							q: selectedCityName,
							limit: 5,
						},
					},
				);

				if (!response.data.features) {
					setAirPortError({
						error: true,
						message: 'The City you mentioned does not exist',
					});
					return;
				}

				const { coordinates = [] } =
					response?.data?.features?.[0]?.geometry || {};

				coordinatesOfLocation = coordinates;
			}

			if (!airportCodes.length) {
				setAirPortError({
					error: true,
					message: 'The City you mentioned does not have any airport',
				});
				return;
			}

			const noOfNights = Number(no_of_nights);

			let hotelFilters = null;

			if (hotel_required) {
				const [lng, lat] =
					coordinatesCache[selectedCityName] || coordinatesOfLocation || [];

				hotelFilters = {
					NUM_ROOMS: 1,
					MIN_STAR_RATING: -1,
					MAX_PRICE: -1,
					AMENITIES: [],
					LOCALITY: 'N/A',
					COORDINATES_LABEL: selectedCityName,
					LATITUDE: lat,
					LONGITUDE: lng,
				};
			}

			const updatedTripDetails = tripDetails.map((curTripData) => {
				return curTripData.reduce((acc, cur, ind) => {
					if (ind < index || ind === curTripData.length - 1) {
						return [...acc, cur];
					}

					const finalDate = incrementDays(
						cur.travelData?.flightDetails?.dateOfJourney ||
							cur.travelData?.nonFlightDetails?.dateOfJourney,
						noOfNights,
					);

					if (ind === index) {
						return [
							...acc,
							{
								...cur,
								travelData: {
									...(cur.travelData || {}),
									flightDetails: {
										...(cur.travelData.flightDetails || {}),
										toAirport: airportCodes,
									},
									destination: selectedCityName,
								},
							},
							{
								id: 'place',
								location: selectedCityName,
								text: `${no_of_nights} Days`,
								type: 'stop',
								hotelFilters,
								hotelReq: hotel_required,
								noOfNights,
								travelData: {
									flightDetails: {
										fromAirport: airportCodes,
										toAirport: cur.travelData.flightDetails.toAirport,
										dateOfJourney: finalDate,
										maxStops: -1,
									},
									nonFlightDetails: null,
									source: selectedCityName,
									type: 'FLIGHT',
									destination: curTripData[ind + 1].location,
								},
							},
						];
					}

					return [
						...acc,
						{
							...cur,
							travelData: {
								...(cur.travelData || {}),
								flightDetails: ['flight', 'FLIGHT'].includes(
									cur.travelData.type,
								)
									? {
											...cur.travelData.flightDetails,
											dateOfJourney: finalDate,
										}
									: null,
								nonFlightDetails: !['flight', 'FLIGHT'].includes(
									cur.travelData.type,
								)
									? {
											...cur.travelData.nonFlightDetails,
											dateOfJourney: finalDate,
										}
									: null,
							},
						},
					];
				}, []);
			});

			setTripDetails(updatedTripDetails);
			setTripModificationDetails({
				modified: true,
				transportChanged: true,
				messages: [],
			});
			setEditDetails(null);
		} catch (error) {
			console.log('error', error);
			setAirPortError({
				error: true,
				message: 'There is something wrong with the network',
			});
		}
	};

	return (
		<div className="flex flex-col justify-between" style={{ height: '90vh' }}>
			<div className="flex flex-col" style={{ gap: '20px' }}>
				<div
					className="flex gap-2 items-center cursor-pointer text-2xl"
					style={{
						borderBottom: '1px solid #E1DEE3',
						paddingBottom: '20px',
					}}
					role="presentation"
					onClick={() => setEditDetails(null)}
				>
					<ArrowBackIosIcon fontSize="small" />

					<div style={{ color: '#301345', fontWeight: 500 }}>Add City</div>
				</div>

				<div
					style={{
						border: '1px solid #D5C9F7',
						backgroundColor: '#fff',
					}}
					className="flex flex-col rounded-lg"
				>
					<div className="p-4 flex flex-col gap-3">
						<div style={{ color: '#574068' }} className="flex justify-between">
							<div>previous</div>
							<div>{tripDetailsForDateOption[index]?.location}</div>
						</div>
					</div>
				</div>

				<div
					className="rounded-lg flex flex-col"
					style={{
						border: '1px solid #7750E5',
						gap: '32px',
						padding: '24px 16px',
					}}
				>
					{controls.map((controlItems) => {
						const { type = '', ...restControls } = controlItems;

						const { name = '', label = '' } = restControls;

						const ActiveComponent = CONTROLLER_MAPPING[type];

						if (type === 'location_select') {
							return (
								<div
									className="flex justify-between items-center py-3"
									style={{ height: '72px' }}
								>
									<div className="font-semibold">{label}</div>

									<div style={{ width: '224px' }}>
										<AutoCompleteLocationSelect
											handleSelect={handleSelectLocation}
											value={selectedCityName}
										/>
									</div>
								</div>
							);
						}

						if (['no_of_nights', 'hotel_required'].includes(name)) {
							return (
								<div className="flex justify-between items-center">
									<div className="font-semibold">{label}</div>

									<ActiveComponent
										{...restControls}
										control={control}
										error={errors[restControls.name]}
									/>
								</div>
							);
						}

						return (
							<div className="flex flex-col gap-2">
								<ActiveComponent
									{...restControls}
									control={control}
									error={errors[restControls.name]}
								/>
							</div>
						);
					})}
				</div>

				{index < tripDetailsForDateOption.length - 1 ? (
					<div
						style={{
							border: '1px solid #D5C9F7',
							backgroundColor: '#fff',
						}}
						className="flex flex-col rounded-lg"
					>
						<div className="p-4 flex flex-col gap-3">
							<div
								style={{ color: '#574068' }}
								className="flex justify-between"
							>
								<div>{tripDetailsForDateOption[index + 1].location}</div>
								<div>Next</div>
							</div>
						</div>
					</div>
				) : null}

				{airportError.error ? (
					<div style={{ color: '#DC1F41' }}>{airportError.message}</div>
				) : null}
			</div>

			<Button
				variant="contained"
				type="button"
				className={styles.submit_button}
				onClick={handleSubmit(onSubmit)}
				style={{
					textTransform: 'unset',
				}}
			>
				Generate Trip
			</Button>
		</div>
	);
}

export default AddCityToTrip;

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TypingIndicator from '@commons/TypingIndicator';
import LocationConfirmationWidget from '@commons/LocationConfirmation/LocationConfirmationWidget';
import AiRialIcon from '@commons/icons/AiRialIcon';
import PersonIcon from '@mui/icons-material/Person';
import ItinerarySingleDayCard from '@commons/ItinerarySingleDayCard/ItinerarySingleDayCard';
import isEmpty from '@utils/isEmpty';
import {
	ChatMessageWidgetType,
	ChatMessageAttachmentType,
} from '@helpers/Enums';
import InspirationAttachment from '@commons/Attachments/InspirationAttachment';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import QuestionWidget from './QuestionWidget';
import TripGenerationUpdatesWidget from './TripGenerationUpdatesWidget';
import MajorUpdateConfirmationWidget from './MajorUpdateConfirmationWidget';
import ChatSuggestions from './ChatSuggestions/ChatSuggestions';

const WIDGET_COMPONENT_MAPPING = {
	[ChatMessageWidgetType.PREFERENCES_WIDGET]: QuestionWidget,
	[ChatMessageWidgetType.LOCATION_WIDGET]: LocationConfirmationWidget,
	[ChatMessageWidgetType.TRIP_GENERATION_UPDATES_WIDGET]:
		TripGenerationUpdatesWidget,
	[ChatMessageWidgetType.POST_REPORT_MAJOR_UPDATE_CONFIRMATION]:
		MajorUpdateConfirmationWidget,
};

const ATTACHMENT_COMPONENT_MAPPING = {
	[ChatMessageAttachmentType.DAY_PLANS_CARD]: ItinerarySingleDayCard,
	[ChatMessageAttachmentType.INSPIRATION]: InspirationAttachment,
};

function WidgetComponent({ widget }) {
	const { widgetType = '', widgetData = {}, shouldShow = true } = widget || {};

	if (!shouldShow || !widgetType) {
		return null;
	}

	if (!isEmpty(widget)) {
		const ActiveComponent = WIDGET_COMPONENT_MAPPING[widgetType] || null;
		return <ActiveComponent widgetData={widgetData} />;
	}

	return null;
}

function AttachmentComponent({ attachment, isChatViewCompressed }) {
	const {
		attachmentType = '',
		attachmentData = {},
		shouldShow = true,
	} = attachment || {};

	const propsMapping = {
		[ChatMessageAttachmentType.DAY_PLANS_CARD]: {
			dayCardData: attachmentData?.data,
		},
		[ChatMessageAttachmentType.INSPIRATION]: {
			attachmentData,
		},
	};

	const activeProps = propsMapping[attachmentType];

	if (!shouldShow) {
		return null;
	}

	if (!isEmpty(attachment)) {
		const ActiveComponent =
			ATTACHMENT_COMPONENT_MAPPING[attachmentType] || null;

		return (
			<div className={isChatViewCompressed ? '' : 'ml-8'}>
				<ActiveComponent {...activeProps} marginRequired={false} />
			</div>
		);
	}

	return null;
}

function ChatMessage({ messageData, isChatViewCompressed, index, length }) {
	const { sender, message, widget = {}, attachment = {} } = messageData || {};
	const { attachmentMessage } = attachment;

	const borderColor = isChatViewCompressed ? '#CEC8D2' : '#F0ECFC';

	const bgColor =
		sender === 'airial' ? 'bg-primary-500' : 'bg-backgrounds-avatar';

	const textColor =
		sender === 'airial'
			? 'text-primary-700 font-semibold'
			: 'text-black font-semibold';

	if (['airial', 'user'].includes(sender)) {
		return (
			<div
				className="mx-6 py-5 flex flex-col gap-3"
				style={
					index !== length - 1
						? { borderBottom: `1px solid ${borderColor}` }
						: {}
				}
			>
				<div className="flex flex-row space-x-2 items-center">
					<div
						className={`w-6 h-6 rounded-full flex items-center justify-center ${bgColor}`}
					>
						{/* sender === 'airial' ? <AiRialIcon /> : <UserIcon />
						TODO: This is right as per the design but is temporarily reverted for being too purple. Fix. */}
						{sender === 'airial' ? (
							<AiRialIcon />
						) : (
							<PersonIcon style={{ fontSize: 'medium', color: 'white' }} />
						)}
					</div>

					<div className={`${textColor}`}>
						{sender === 'airial' ? 'AiRial' : 'You'}
					</div>
				</div>

				{attachmentMessage || message ? (
					<div
						style={{
							margin: isChatViewCompressed ? 0 : '0 32px',
							color: '#574068',
							fontWeight: 500,
						}}
						className="leading-normal"
					>
						{attachmentMessage || message}
					</div>
				) : null}

				<WidgetComponent widget={widget} />
				<AttachmentComponent
					attachment={attachment}
					isChatViewCompressed={isChatViewCompressed}
				/>
			</div>
		);
	}

	return (
		<div
			className="mx-6 py-2 text-center font-medium"
			style={{ fontSize: '14px' }}
		>
			{message}
		</div>
	);
}

export default function ChatFeed() {
	const dispatch = useDispatch();
	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const attachmentCardData = useSelector(
		(state) => state.ui_states.attachmentCardData,
	);
	const chatFeedRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			// Scroll to the bottom of the chat feed container
			if (chatFeedRef?.current?.scrollHeight) {
				chatFeedRef.current.scrollTop = chatFeedRef.current.scrollHeight;
			}
		}, 100);
	}, [chatMessages.messages.length, chatMessages.isTyping, attachmentCardData]);

	const { messages } = chatMessages;

	let typingIndicator = null;
	if (chatMessages.isTyping) {
		typingIndicator = <TypingIndicator />;
	}

	return (
		<div className="flex-grow overflow-auto my-2" ref={chatFeedRef}>
			{isChatViewCompressed ? (
				<div
					role="presentation"
					className="cursor-pointer absolute transition duration-300 ease-in-out transform hover:bg-blue-500 hover:scale-110"
					style={{
						top: '22px',
						right: '8px',
						padding: '6px',
						borderRadius: '50%',
						background: '#fff',
						transform: 'rotateY(180deg)',
						zIndex: '999',
					}}
					onClick={() => {
						sendMessageEvent({
							event: GoogleEventTypes.CLOSE_CHAT_FEED,
							chat_session_id: chatSessionId,
						});

						dispatch({
							type: 'UPDATE_CHAT_BOX_STATUS',
							payload: true,
						});
					}}
				>
					<KeyboardTabIcon fontSize="small" />
				</div>
			) : null}

			{messages.map((messageData, index) => {
				return (
					<ChatMessage
						key={index}
						isChatViewCompressed={isChatViewCompressed}
						messageData={messageData}
						index={index}
						length={messages.length}
					/>
				);
			})}
			{typingIndicator}
			<ChatSuggestions />
		</div>
	);
}

import { formatDates } from '@helpers/DateUtils';

function HotelTabs({ hotel_datesets, hotelFilters, value, onChange }) {
	return (
		<div className="flex gap-2 my-2 overflow-auto">
			{hotelFilters
				.filter((hotelFilter) => hotelFilter)
				.map((hotelDetails, index) => {
					const isSelected = value === index;

					const { COORDINATES_LABEL = '' } = hotelDetails || {};
					const finalPlace = COORDINATES_LABEL.split(',')[0];

					return (
						<div
							className="flex items-center gap-1 cursor-pointer text-sm"
							role="presentation"
							onClick={() => onChange(index)}
							style={{
								color: isSelected ? '#7750E5' : '#301345',
								border: isSelected
									? '1px solid rgb(109 40 217)'
									: '1px solid #CEC8D2',
								textTransform: 'unset',
								backgroundColor: '#fff',
								borderRadius: '32px',
								whiteSpace: 'nowrap',
								padding: '6px 12px',
							}}
						>
							<strong>{finalPlace}</strong>
							{isSelected ? (
								<div>
									·{' '}
									{formatDates(
										hotel_datesets[index].CHECK_IN_DATE,
										hotel_datesets[index].CHECK_OUT_DATE,
									)}
								</div>
							) : null}
						</div>
					);
				})}
		</div>
	);
}

export default HotelTabs;

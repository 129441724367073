import { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from '@utils/isEmpty';
import ImageLoader from './ImageLoader';

export default function TripGenerationSlideshow({ isItineraryLoaded = false }) {
	const addedCitiesImages = useSelector(
		(state) => state.ui_states.addedCitiesImages,
	);
	const [activeImage, setActiveImage] = useState(0);

	const sixImages = addedCitiesImages.filter((image) => image.url).slice(0, 6);

	const isImagesPresent = sixImages.some((item) => {
		return !isEmpty(item.url || item.getUrl);
	});

	if (isItineraryLoaded || !isImagesPresent) {
		return null;
	}

	return (
		<div
			className="fixed bottom-0 right-0 m-4"
			style={{ display: 'flex', justifyContent: 'flex-end', zIndex: '99' }}
		>
			<ImageLoader
				sixImages={sixImages}
				setActiveImage={setActiveImage}
				activeImage={activeImage}
			/>
		</div>
	);
}

import './dist/output.css';
import { Route, Routes } from 'react-router-dom';
import AddInspiration from './pages/AddInspiration';
import Authentication from './pages/Authentication/AuthForm';
import ViewInspiration from './pages/ViewInspiration';
import HomePageV1 from './pages/HomePageV1';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import ChatHome from './pages/ChatHome';
import './App.css';
import InvalidTrip from './pages/ErrorPage/InvalidTrip';

function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="*" element={<HomePageV1 pageId={0} />} />
				<Route path="/add_inspiration" element={<AddInspiration />} />
				<Route path="/login" element={<Authentication activeTab="login" />} />
				<Route
					path="/signup-form"
					element={<Authentication activeTab="signup" />}
				/>
				<Route path="/signup" element={<SignUpPage />} />
				<Route path="/view_inspiration/:id" element={<ViewInspiration />} />
				<Route path="/chat" element={<ChatHome />} />
				<Route path="/error" element={<InvalidTrip />} />
				<Route path="/chat/:id" element={<ChatHome />} />
			</Routes>
		</div>
	);
}

export default App;

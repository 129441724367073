import React, { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import './Carousel.css';
import { CgSearchLoading, CgUnavailable } from 'react-icons/cg';

function Carousel({
	data = [],
	height,
	width,
	flexBasis,
	isImagesLoaded = true,
	isImagesEmpty = false,
	TransitionIcon,
	isTransportCard = false,
}) {
	const [slide, setSlide] = useState(0);
	const [isImageHovered, setIsImageHovered] = useState(false);

	const nextSlide = () => {
		setSlide(slide === data.length - 1 ? 0 : slide + 1);
	};

	const prevSlide = () => {
		setSlide(slide === 0 ? data.length - 1 : slide - 1);
	};

	if (isTransportCard) {
		return (
			<div
				className="flex items-center justify-center rounded-xl"
				style={{
					backgroundColor: 'rgba(48, 19, 69, 0.04)',
					height: height || '180px',
					width: width || '28%',
					maxHeight: height || '180px',
					flexBasis: flexBasis || undefined,
				}}
			>
				<TransitionIcon
					style={{ fontSize: 48 }}
					className="self-center h-15 w-15"
					width="60px"
					height="60px"
					applyActualWidth
				/>
			</div>
		);
	}

	if (!isImagesLoaded) {
		return (
			<div
				className="flex items-center justify-center rounded-xl"
				style={{
					backgroundColor: 'rgba(48, 19, 69, 0.04)',
					height: height || '180px',
					width: width || '28%',
					maxHeight: height || '180px',
					flexBasis: flexBasis || undefined,
				}}
			>
				<CgSearchLoading
					fontSize="large"
					className="self-center h-12 w-12"
					applyActualWidth
				/>
			</div>
		);
	}

	if (isImagesEmpty) {
		return (
			<div
				className="flex items-center justify-center flex-col gap-4 rounded-xl"
				style={{
					backgroundColor: 'rgba(48, 19, 69, 0.04)',
					height: height || '180px',
					width: width || '28%',
					maxHeight: height || '180px',
					flexBasis: flexBasis || undefined,
				}}
			>
				<CgUnavailable
					fontSize="large"
					className="self-center h-12 w-12"
					applyActualWidth
				/>

				<div>No Images found</div>
			</div>
		);
	}

	return (
		<div
			className="carousel"
			style={{
				height: height || '180px',
				width: width || '28%',
				maxHeight: height || '180px',
				flexBasis: flexBasis || undefined,
			}}
			onMouseEnter={() => setIsImageHovered(true)}
			onMouseLeave={() => setIsImageHovered(false)}
		>
			{isImageHovered && data.length > 1 && (
				<div className="icon-wrapper arrow-left">
					<NavigateBeforeIcon
						style={{ color: '#000' }}
						onClick={prevSlide}
						fontSize="small"
					/>
				</div>
			)}

			{data.map((item, idx) => {
				return (
					<div
						key={item.src}
						className={`${slide === idx ? 'slide' : 'slide slide-hidden'}`}
					>
						<img
							src={item.src}
							alt={item.alt}
							key={idx}
							style={{
								height: '100%',
								objectFit: 'cover',
								width: '100%',
								borderRadius: '10px',
							}}
						/>
					</div>
				);
			})}

			{isImageHovered && data.length > 1 && (
				<div className="icon-wrapper arrow-right">
					<NavigateNextIcon
						style={{ color: '#000' }}
						onClick={nextSlide}
						fontSize="small"
					/>
				</div>
			)}

			<span className="indicators">
				{data.map((_, idx) => {
					return (
						<div
							role="presentation"
							key={idx}
							className={
								slide === idx ? 'indicator' : 'indicator indicator-inactive'
							}
							onClick={() => setSlide(idx)}
						/>
					);
				})}
			</span>
		</div>
	);
}

export default Carousel;

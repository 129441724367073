import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import {
	MessageLabel,
	ChatMessageSender,
	InteractionStage,
	TripUpdateType,
	ChatMessageWidgetType,
} from '@helpers/Enums';
import {
	handleFetchPostReport,
	getPostReportDayPlansContext,
} from '@helpers/ModelApiUtils';
import { dispatchUpdateChatMessages } from '@helpers/ChatUtils';
import styles from './styles.module.css';

const MAJOR_UPDATE_CANCELLATION_FOLLOW_UP =
	'Ok, I have cancelled the update. Please let me know if there anything else that you need.';
const USING_PLACEHOLDER_RESPONSE = false;
const TRIP_GENERATION_MESSAGE =
	'I am now updating your trip as desired. Currently this can take around a minute but it will be worth the wait! Thanks for your patience.';

export default function MajorUpdateConfirmationWidget({ widgetData = {} }) {
	const dispatch = useDispatch();
	const location = useLocation();

	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const currentReduxState = useSelector((state) => state);

	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const tripLocations = useSelector((state) => state.tripLocationDetails);
	const tripTransitions = useSelector((state) => state.tripTransitions);

	const { index } = widgetData;
	const majorUpdateConfirmationWidgets = useSelector(
		(state) => state.ui_states.majorUpdateConfirmationWidgets,
	);
	const { cancelOrConfirm = 'Cancel', submitted = false } =
		majorUpdateConfirmationWidgets?.[index] ?? {};

	const dayPlans = useSelector((state) => state.ui_states.day_plans) || [];
	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const curDayPlanResults = dayPlans[active_tab_index] || [];
	const activeItineraryDayTab =
		useSelector((state) => state.ui_states.activeItineraryDayTab) || 0;
	const postReportDayPlansContext = getPostReportDayPlansContext(
		curDayPlanResults,
		activeItineraryDayTab,
	);

	const inspirationContentIds = useSelector(
		(state) => state.ui_states.inspirationContentIds,
	);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const handleSubmit = (confirm) => async () => {
		const updatedState = {
			cancelOrConfirm: confirm,
			submitted: true,
		};
		dispatch({
			type: 'UPDATE_MAJOR_UPDATE_CONFIRMATION_WIDGETS',
			payload: majorUpdateConfirmationWidgets.map((state, ix) =>
				ix === index ? updatedState : state,
			),
		});

		let updatedLlmInteractionHistory;
		if (confirm) {
			updatedLlmInteractionHistory = [
				...llmInteractionHistory,
				{
					// TODO: Merge this with ChatMessageSender, make backend/frontend enums consistent
					SENDER: 'User',
					LABEL: MessageLabel.MAJOR_UPDATE_CONFIRMATION_WIDGET_USER,
					CONTENT: 'Confirm',
				},
			];
		} else {
			// Removes the cancelled message as well as its confirmation flow from the llm interaction history.
			let i = llmInteractionHistory.length - 1;
			for (; i >= 0; i--) {
				if (llmInteractionHistory[i]?.LABEL === MessageLabel.USER) {
					break;
				}
			}
			if (i === -1) {
				return [];
			}
			updatedLlmInteractionHistory = llmInteractionHistory.slice(0, i);
		}

		const widget =
			chatMessages.messages[chatMessages.messages.length - 1]?.widget;

		const updatedChatMessages = chatMessages.messages.filter(
			(_, ind) => ind !== chatMessages.messages.length - 1,
		);

		updatedChatMessages[updatedChatMessages.length - 1] = {
			...updatedChatMessages[updatedChatMessages.length - 1],
			widget: {
				...widget,
				shouldShow: true,
			},
		};

		if (confirm) {
			if (!USING_PLACEHOLDER_RESPONSE) {
				// TODO: At this stage, the entire screen should clear or be blurred out.
				// A flow identical to the trip generation flow can play out with the slideshow and the disclaimer and the sequence of shimmers.

				updatedChatMessages.push({
					sender: ChatMessageSender.AIRIAL,
					message: TRIP_GENERATION_MESSAGE,
					widget: {
						widgetType: ChatMessageWidgetType.TRIP_GENERATION_UPDATES_WIDGET,
						widgetData: [
							{
								id: 'identifying_route_and_connections',
								message: 'Identifying the route and the connections.',
								status: 0,
							},
						],
						shouldShow: true,
					},
				});
			}

			dispatch({
				type: 'UPDATE_LLM_INTERACTION_HISTORY',
				payload: updatedLlmInteractionHistory,
			});
			dispatch({
				type: 'UPDATE_CHAT_MESSAGES',
				payload: {
					messages: updatedChatMessages,
					isTyping: true,
				},
			});

			const { attachment } = widget.widgetData;
			const postFormData = new FormData();
			postFormData.append('user_message', widget.widgetData.userMessage);
			postFormData.append(
				'attachment',
				attachment ? JSON.stringify(attachment.data, undefined, 2) : null,
			);
			postFormData.append(
				'chat_history',
				JSON.stringify(updatedLlmInteractionHistory),
			);
			postFormData.append(
				'interaction_stage',
				InteractionStage.POST_REPORT_MAJOR_UPDATE_CONFIRMATION,
			);
			postFormData.append('trip_locations', JSON.stringify(tripLocations));
			postFormData.append('trip_transitions', JSON.stringify(tripTransitions));
			postFormData.append(
				'user_intents',
				JSON.stringify(widget.widgetData.intents),
			);
			postFormData.append(
				'day_plans_context',
				JSON.stringify(postReportDayPlansContext),
			);
			postFormData.append('chat_session_id', chatSessionId);

			handleFetchPostReport(
				postFormData,
				dispatch,
				location,
				majorUpdateConfirmationWidgets,
				widget.widgetData.userMessage,
				updatedChatMessages,
				updatedLlmInteractionHistory,
				chatSessionId,
				null,
				null,
				null,
				{
					inspirationContentIds,
					hotelSortCriterion,
					flightSortOption,
					gMapsData,
				},
				currentReduxState,
			);
		} else {
			updatedChatMessages.push({
				sender: ChatMessageSender.AIRIAL,
				message: MAJOR_UPDATE_CANCELLATION_FOLLOW_UP,
				widget: null,
			});

			dispatch({
				type: 'UPDATE_LLM_INTERACTION_HISTORY',
				payload: updatedLlmInteractionHistory,
			});
			dispatchUpdateChatMessages(
				dispatch,
				TripUpdateType.POST_REPORT_MAJOR_UPDATE_USER_CANCEL,
				{
					messages: updatedChatMessages,
					isTyping: false,
				},
			);
		}
	};

	return (
		<div>
			<div
				className="rounded-lg py-4"
				style={{
					boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
					backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
					margin: isChatViewCompressed ? 0 : '0 32px',
				}}
			>
				{submitted ? (
					<div className="font-medium px-4" style={{ color: '#574068' }}>
						{`Major Update ${cancelOrConfirm ? 'Confirmed' : 'Cancelled'}`}
					</div>
				) : (
					<div className="flex justify-center gap-6">
						<Button
							variant="text"
							style={{
								color: '#7750E5',
								textTransform: 'unset',
								fontWeight: 500,
								fontSize: '16px',
								cursor: 'pointer',
							}}
							onClick={handleSubmit(false)}
						>
							Cancel
						</Button>
						<Button
							style={{
								textTransform: 'unset',
								cursor: 'pointer',
							}}
							className={styles.submit_button}
							variant="contained"
							onClick={handleSubmit(true)}
						>
							Confirm
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

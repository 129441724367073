function getMessageIdForKey(key) {
	if (key === 'flightsLoading') {
		return 'searching_flights';
	}
	if (key === 'hotelsLoading') {
		return 'searching_hotels';
	}
	return 'creating_itinerary';
}

export function markTripGenerationComplete(loadingStateKey, chatMessages) {
	const messageId = getMessageIdForKey(loadingStateKey);
	return chatMessages.map((item) => {
		if (item?.widget?.widgetType === 'trip_generation_updates_widget') {
			return {
				...item,
				widget: {
					...item?.widget,
					widgetData: item?.widget?.widgetData?.map((message) => {
						if (message.id === messageId) {
							return {
								...message,
								status: 1,
							};
						}
						return message;
					}),
				},
			};
		}
		return item;
	});
}

import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalenderIcon from '@commons/icons/CalenderIcon';
import ImageLoader from '@commons/Itinerary/ImageLoader';
import { formatDates } from '@helpers/DateUtils';
import { getTotalPriceObject } from '@helpers/hotelsQueryHelper';
import { CgUnavailable } from 'react-icons/cg';
import { LoadingState, MainTripPageView } from '@helpers/Enums';
import { Skeleton } from '@mui/material';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import {
	fetchPhotosBatch,
	getPlaceNameForHotel,
} from '@helpers/PhotosQueryHelper';
import CardsLoadingComponent from './CardsLoadingComponent';
import styles from './styles.module.css';

// const MAPPING = {
// 	EUR: 'Є',
// 	USD: '$',
// };

function HotelWidgets({ setCurrentView = () => {}, tabChanged }) {
	const dispatch = useDispatch();
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const loadingState = useSelector((state) => state.ui_states.loadingState);
	const selectedHotels = useSelector((state) => state.ui_states.selectedHotels);
	const hotelFilters = useSelector(
		(state) => state.tripLocationDetails.hotelFilters,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const isTripFullyLoaded = useSelector(
		(state) => state.ui_states.isTripFullyLoaded ?? true,
	);
	const hotelSets = useSelector((state) => state.tripLocationDetails.hotelSets);

	const hotelReqs = useSelector((state) => state.tripLocationDetails.hotelReqs);
	const [processedPlaces, setProcessedPlaces] = useState(new Set());

	const filteredSelectedHotels = selectedHotels.filter(
		(hotelData) => !!Object.keys(hotelData || {}).length,
	);

	useEffect(() => {
		const placesToFetch = [];

		filteredSelectedHotels?.forEach((hotelData, index) => {
			if (hotelData && hotelData.hotel && hotelData.hotel.name) {
				const placeName = getPlaceNameForHotel(
					hotelData.hotel,
					hotelFilters,
					index,
				);
				if (!processedPlaces.has(placeName) && !gMapsData[placeName]) {
					placesToFetch.push(placeName);
					processedPlaces.add(placeName);
				}
			}
		});
		fetchPhotosBatch(placesToFetch, dispatch);
	}, [filteredSelectedHotels]);

	const { hotelsLoading = LoadingState.INIT } = loadingState;

	if (hotelsLoading === LoadingState.INIT || !isTripFullyLoaded) {
		return null;
	}

	const totalPriceObject = getTotalPriceObject(filteredSelectedHotels);

	const { price } = totalPriceObject;

	let ind = 0;
	return (
		<div className="flex flex-col gap-4">
			<div
				className="flex justify-between"
				style={{
					opacity: tabChanged && '24%',
					filter: tabChanged && 'blur(2px)',
				}}
			>
				<div className="font-semibold flex text-2xl items-center gap-3">
					Hotels :
					<div
						className="flex gap-2 items-end"
						style={{ color: '#7750E5', fontWeight: '500' }}
					>
						<div>
							{
								hotelsLoading === LoadingState.LOADING
									? 'Searching...'
									: `$${price}` /* `${MAPPING[curr] || curr}${price}` */
							}
						</div>
						{hotelsLoading === LoadingState.LOADING ? null : (
							<div className="text-sm pb-[3px]">total</div>
						)}
					</div>
				</div>

				<div
					className="flex gap-2 items-center cursor-pointer text-highlight font-medium"
					role="presentation"
					onClick={() => {
						sendMessageEvent({
							event: GoogleEventTypes.OVERVIEW_PAGE_SEE_ALL_HOTELS,
							chat_session_id: chatSessionId,
						});
						setCurrentView(MainTripPageView.HOTELS);
					}}
				>
					Edit
				</div>
			</div>

			{hotelsLoading === LoadingState.LOADING ? (
				<CardsLoadingComponent length={2} />
			) : (
				<div className="flex gap-3 overflow-auto pb-2 pr-6">
					{selectedHotels.map((hotelData, index) => {
						if (!hotelReqs[index]) {
							return null;
						}

						const noHotelSelected = !Object.keys(hotelData || {}).length;

						const { hotel = {} } = hotelData || {};
						const { name = '', hotelId = '' } = hotel;

						const placeName = getPlaceNameForHotel(hotel, hotelFilters, ind);
						ind += 1;
						const photoUrl =
							gMapsData[placeName]?.[0]?.[0]?.photos_data[0]
								?.original_photo_url;

						return (
							<div
								key={`overview_hotel_${hotelId}_${index}`}
								className={styles.card}
								style={{ display: 'flex', padding: '0px' }}
							>
								{photoUrl ? (
									<ImageLoader
										gMapsData={gMapsData}
										placeName={placeName}
										width="6rem"
										height="6rem"
										usePhotoId
										onError={() => {}}
										styles={{
											...(tabChanged
												? {
														opacity: '24%',
														filter: 'blur(2px)',
														borderRadius: '7px 0 0 7px',
													}
												: { borderRadius: '7px 0 0 7px' }),
										}}
										opacity={tabChanged ? '24%' : '100%'}
									/>
								) : (
									<div
										className="flex h-24 w-24 items-center justify-center"
										style={{ backgroundColor: 'rgba(48, 19, 69, 0.04)' }}
									>
										<CgUnavailable
											fontSize="large"
											className="self-center h-8 w-8"
											width="40px"
											height="40px"
											applyActualWidth
										/>
									</div>
								)}

								<div
									className="flex flex-col gap-2 py-2 px-4"
									style={{
										opacity: tabChanged && '24%',
										filter: tabChanged && 'blur(2px)',
									}}
								>
									<div
										className={
											noHotelSelected
												? 'font-normal text-red-900'
												: 'font-semibold'
										}
										style={{
											maxWidth: '212px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{noHotelSelected ? 'Not Found' : name || gMapsData?.name}
									</div>

									<div
										className="flex gap-1 items-center text-xs font-medium"
										style={{
											color: '#DC1F90',
										}}
									>
										<LocationOnOutlinedIcon style={{ fontSize: '16' }} />
										{hotelFilters[index]?.COORDINATES_LABEL.split(',')[0]}
									</div>

									<div className="ml-0.5 flex gap-1.5 items-center text-xs font-medium">
										<CalenderIcon />
										{(hotelSets?.[0] || [])[index] ? (
											formatDates(
												(hotelSets?.[0] || [])[index]?.CHECK_IN_DATE,
												(hotelSets?.[0] || [])[index]?.CHECK_OUT_DATE,
											)
										) : (
											<Skeleton width="80px" height="24px" />
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default HotelWidgets;

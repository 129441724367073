// Function to convert the array to an array of objects with the desired format
function convertToDayObjects(daysArray) {
	const formattedDays = [];

	const regex = /([A-Za-z]+):\s*(.*)/;

	daysArray.forEach((dayString) => {
		const match = dayString.match(regex);

		if (match) {
			const day = match[1].toLowerCase();
			const timeStr = match[2];

			if (timeStr !== 'closed') {
				const [open, close] = timeStr.split('–').map((str) => str.trim());

				formattedDays.push({ day, open, close });
			} else {
				formattedDays.push({ day, open: 'Closed', close: 'Closed' });
			}
		}
	});

	return formattedDays;
}

export default convertToDayObjects;

import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import MapComponent from '@commons/MapComponent';
import { InstagramEmbed } from 'react-social-media-embed';
import InfoComponent from '@commons/MapComponent/InfoComponent';
import PlaceDetails from '../../components/PlaceDetails';

export default function ViewInspiration() {
	const { id = '' } = useParams();

	const [payload, setPayload] = useState({});
	const [centerPosition, setCenterPosition] = useState({ lat: 0, lng: 0 });
	const [zoomLevel, setZoomLevel] = useState(4);
	const [embedUrl, setEmbedUrl] = useState(
		'https://www.instagram.com/reel/Crecv2_gcjs/',
	);
	const [activeMarker, setActiveMarker] = useState(null);

	const handleResponse = (response) => {
		if (response.status === 'success') {
			setPayload(response.payload);
		}
	};

	const handleStreamResponse = (response) => {
		if (response.ok) {
			const reader = response.body.getReader();
			let accumulatedText = '';

			function readChunk() {
				reader.read().then(({ done, value }) => {
					if (done) {
						// Handle the final accumulated text here
						handleFinalChunk(accumulatedText);
						return;
					}

					const chunkText = new TextDecoder().decode(value, { stream: true });
					accumulatedText += chunkText;

					// Process complete JSON objects in accumulatedText
					processAccumulatedText();

					readChunk();
				});
			}

			function processAccumulatedText() {
				// Try to find complete JSON objects and process them
				let braceCount = 0;
				let startIndex = 0;

				for (let i = 0; i < accumulatedText.length; i++) {
					const char = accumulatedText[i];

					if (char === '{') {
						braceCount++;
					} else if (char === '}') {
						braceCount--;
						if (braceCount === 0) {
							// Found a complete JSON object
							const jsonStr = accumulatedText.substring(startIndex, i + 1);
							try {
								const parsedJSON = JSON.parse(jsonStr);
								handleResponse(parsedJSON);
							} catch (e) {
								console.error('Parsing failed for chunk', jsonStr);
								// Handle parsing error
							}
							startIndex = i + 1;
						}
					}
				}

				// Keep the remaining incomplete part
				accumulatedText = accumulatedText.substring(startIndex);
			}

			function handleFinalChunk() {
				// Process any remaining JSON objects in the final chunk
				processAccumulatedText();
				if (accumulatedText.length > 0) {
					console.error(
						'Final chunk contains incomplete JSON',
						accumulatedText,
					);
					// Handle incomplete JSON in final chunk
				}
			}

			readChunk();
		}
	};

	const getData = async () => {
		try {
			const backendURL = process.env.REACT_APP_BACKEND_URL;

			const postFormData = new FormData();
			postFormData.append('id', id);

			await fetch(`${backendURL}/api/id_map_content`, {
				method: 'POST',
				body: postFormData,
			})
				.then((response) => {
					handleStreamResponse(response);
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log('error', error);
		}
	};

	const { gmaps_data = [], summary = {} } = payload;

	const { places = [] } = summary;

	const placesData = places.map((item, index) => ({
		...item,
		...gmaps_data?.[index],
	}));

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className="relative h-screen">
			<div
				className="absolute top-0 right-0 h-full bg-gray-400"
				style={{ width: '66%', right: 0 }}
			>
				{payload.gmaps_data && (
					<div className="h-full">
						<MapComponent
							gmaps_data={payload.gmaps_data}
							setActiveMarker={setActiveMarker}
							activeMarker={activeMarker}
							setZoomLevel={setZoomLevel}
							zoomLevel={zoomLevel}
							centerPosition={centerPosition}
							setCenterPosition={setCenterPosition}
						/>
					</div>
				)}
			</div>

			<div
				className="absolute"
				style={{ left: 'calc(34% + 12px)', bottom: '8px' }}
			>
				<InfoComponent
					activeMarkerData={gmaps_data?.[activeMarker]}
					setActiveMarker={setActiveMarker}
					setZoomLevel={setZoomLevel}
				/>
			</div>

			<div
				className="absolute top-0 left-0 h-full bg-gray-200"
				style={{ width: '34%', background: '#fff' }}
			>
				<div className="h-full p-2">
					<div
						className="flex flex-1 flex-col space-y-4 overflow-y-auto"
						style={{ maxHeight: 'calc(100vh - 24px)' }}
					>
						<PlaceDetails
							data={placesData}
							setActiveMarker={setActiveMarker}
							activeMarker={activeMarker}
							setZoomLevel={setZoomLevel}
							gmaps_data={payload.gmaps_data}
							setCenterPosition={setCenterPosition}
						/>

						<div className="flex justify-center">
							{embedUrl && (
								<InstagramEmbed
									url={embedUrl}
									key={embedUrl}
									captioned
									width={328}
									debug
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { HotelSortCriterion } from '@helpers/Enums';
import startCase from '@utils/startCase';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { styled } from '@mui/material';

const StyledSelect = styled(Select)(() => ({
	borderRadius: '20px',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#D9D9D9',
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: '#D9D9D9',
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: '1px solid #D9D9D9',
	},
}));

function HotelSortSelector() {
	const dispatch = useDispatch();
	const location = useLocation();
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const {
		hotelFilters = [],
		hotelReqs = [],
		hotelSets,
		hotelSortCriterion,
		numTravellers,
	} = tripLocationDetails;

	const handleOptionChange = (e) => {
		sendMessageEvent({
			event: GoogleEventTypes.CHANGE_HOTELS_SORT_CRITERIA,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_HOTEL_SORT_OPTION',
			payload: e.target.value,
		});

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: hotelSets,
				hotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: e.target.value,
			},
			tabIndex: activeTabIndex,
			gMapsData,
			chatSessionId,
		});
	};

	return (
		<div>
			<StyledSelect
				value={hotelSortCriterion}
				size="small"
				onChange={handleOptionChange}
				IconComponent={KeyboardArrowDownIcon}
				displayEmpty
				sx={{ fontSize: '14px', color: '#301345' }}
			>
				{Object.keys(HotelSortCriterion).map((key) => (
					<MenuItem value={HotelSortCriterion[key]}>
						<div className="flex items-center gap-2">
							<div className="font-normal opacity-80"> sort :</div>
							<div className="font-medium">{startCase(key)}</div>
						</div>
					</MenuItem>
				))}
			</StyledSelect>
		</div>
	);
}

export default HotelSortSelector;

import { Slider, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const MAX_PRICE = 1000;

function PriceFilter({ handleClose = () => {} }) {
	const dispatch = useDispatch();

	const location = useLocation();

	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);

	const {
		hotelFilters = [],
		hotelReqs = [],
		hotelSets,
		numTravellers,
	} = tripLocationDetails;

	const selectedMaxPrice =
		(hotelFilters.filter((hotelFilter) => hotelFilter)[0] || {}).MAX_PRICE ||
		-1;

	const [maxPrice, setMaxPrice] = useState(
		selectedMaxPrice <= 0 ? MAX_PRICE : selectedMaxPrice,
	);

	const handleChangeFilter = ({ action }) => {
		const updatedHotelFilters = hotelFilters.map((item) => {
			if (!item) {
				return null;
			}

			return {
				...item,
				MAX_PRICE: action === 'clear' || maxPrice === MAX_PRICE ? -1 : maxPrice,
			};
		});

		dispatch({
			type: 'UPDATE_HOTEL_FILTERS',
			payload: updatedHotelFilters,
		});

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: hotelSets,
				hotelFilters: updatedHotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: hotelSortCriterion,
			},
			tabIndex: activeTabIndex,
			gMapsData,
			chatSessionId,
		});

		handleClose();
	};

	return (
		<div className="flex flex-col gap-2">
			<div className="flex justify-between">
				<div className="text-lg">Price</div>
				<CloseIcon onClick={handleClose} />
			</div>

			{maxPrice === MAX_PRICE ? (
				<div className="text-sm mt-4" style={{ color: '#8ab4f8' }}>
					All Prices
				</div>
			) : (
				<div className="text-sm mt-4" style={{ color: '#307dfa' }}>
					up to ${maxPrice}
				</div>
			)}

			<Box sx={{ width: 300, padding: '0 4px' }}>
				<Slider
					defaultValue={maxPrice}
					aria-label="Default"
					valueLabelDisplay="auto"
					min={0}
					max={MAX_PRICE}
					onChange={(_, value) => {
						setMaxPrice(value);
					}}
				/>
			</Box>

			<div className="flex ml-auto mt-2">
				<Button
					sx={{ textTransform: 'unset' }}
					onClick={() => handleChangeFilter({ action: 'clear' })}
					variant="text"
					size="small"
				>
					Clear
				</Button>

				<Button
					sx={{ textTransform: 'unset', backgroundColor: '#5b21b6' }}
					variant="contained"
					size="small"
					disabled={
						selectedMaxPrice === maxPrice ||
						(maxPrice === MAX_PRICE && selectedMaxPrice === -1)
					}
					onClick={() => handleChangeFilter({ action: 'apply' })}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

export default PriceFilter;


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

function InspirationItineraryTabs({
    tabNames,
	activeTabIndex,
	setActiveTabIndex,
}) {
    const MyTab = styled(Tab)(({ theme }) => ({}));
    const handleTabClick = (event, index) => {
        setActiveTabIndex(index);
    };

    return <Tabs
        value={activeTabIndex}
        onChange={handleTabClick}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        sx={{
            '& .Mui-selected': {
                color: '#6d28d9',
            },
            '.MuiTabs-scrollButtons.Mui-disabled': {
                opacity: 0.3,
            },
            '.MuiTabs-indicator': {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                height: '3px',
            },
            '.MuiTabs-indicatorSpan': {
                width: '90%',
                backgroundColor: '#6d28d9',
            },
            '.MuiTab-root': {
                px: 2,
                py: 1,
            },
        }}
    >
        {tabNames.map((tab, ix) => (
            <MyTab id="date_tab" label={tab} sx={{ textTransform: 'none' }} />
        ))}
    </Tabs>
}

export default InspirationItineraryTabs;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ItineraryDayCards from '@commons/Itinerary/ItineraryDayCards';
import {
	convertScheduleToItineraryDayCardsData,
	getFormattedGMapsData,
} from '@utils/DayCardUtils';

import MapComponent from '@commons/MapComponent';
import {
	InspirationDrawerSource,
	LoadingState,
	MainTripPageView,
} from '@helpers/Enums';
import Drawer from '@mui/material/Drawer';
import AddInspirationButton from '@commons/Inspiration/AddInspirationButton';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import {
	getUpdatedAndRemovedDayCardsData,
	updateDayCardsDataListWithNewTags,
} from '@utils/DayPlansUtils';
import isEmpty from '@utils/isEmpty';
import AddActivity from '../../DayPlans/AddActivity';
import styles from './styles.module.css';
import ItineraryLoadingComponent from './ItineraryLoadingComponent';
import ItineraryHeader from '../../../../commons/Itinerary/ItineraryHeader';

const calculateNumberOfDays = (startDate, endDate) => {
	// TODO: confirm this whole condition for showing how many days are loaded
	if (!startDate || !endDate) {
		return null;
	}

	const start = new Date(startDate);
	const end = new Date(endDate);

	const differenceInTime = end.getTime() - start.getTime();

	const differenceInDays = differenceInTime / (1000 * 3600 * 24);

	return differenceInDays + 1;
};

function ItineraryOverview({ setCurrentView = () => {}, tabChanged }) {
	const dispatch = useDispatch();
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const dayPlans = useSelector((state) => state.ui_states.day_plans) || [];
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const loadingState = useSelector((state) => state.ui_states.loadingState);
	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const travelSets =
		useSelector((state) => state.tripTransitions.travelSets) || [];

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const isTripFullyLoaded = useSelector(
		(state) => state.ui_states.isTripFullyLoaded ?? true,
	);
	const activeItineraryDayTab =
		useSelector((state) => state.ui_states.activeItineraryDayTab) || 0;

	const inspirationPlaceNames =
		useSelector((state) => state.ui_states.inspirationPlaceNames) || [];

	const setActiveItineraryDayTab = (index) => {
		dispatch({ type: 'UPDATE_ACTIVE_ITINERARY_DAY_TAB', payload: index });
	};

	const [activeMarker, setActiveMarker] = useState(null);
	const [addActivityDetails, setAddActivityDetails] = useState({});

	const curDayPlanResults = dayPlans[active_tab_index] || [];

	const dayItinerary = curDayPlanResults?.[activeItineraryDayTab] || {};

	const activeTravelSets = travelSets[activeTabIndex] || [];

	const { itineraryLoading = LoadingState.INIT } = loadingState;
	const schedule = dayItinerary.SCHEDULE;
	const places = schedule?.map((activity) => activity.PLACE_NAME);
	const formattedGMapsData = getFormattedGMapsData(places, gMapsData);
	let fallbackCenter = schedule?.[0]?.COORDINATES;
	if (fallbackCenter) {
		fallbackCenter = fallbackCenter
			.split(',')
			.map((coord) => parseFloat(coord));
		if (fallbackCenter.length !== 2) {
			fallbackCenter = null;
		}
		if (fallbackCenter) {
			fallbackCenter = {
				lat: fallbackCenter[0],
				lng: fallbackCenter[1],
			};
		}
	}

	if (itineraryLoading === LoadingState.INIT || !isTripFullyLoaded) {
		return null;
	}

	const travelSetsLength = activeTravelSets.length;
	const startDate = curDayPlanResults?.[0]?.DATE || null;

	const endDate =
		activeTravelSets[travelSetsLength - 1]?.nonFlightDetails?.dateOfJourney ||
		activeTravelSets[travelSetsLength - 1]?.flightDetails?.dateOfJourney;

	const numberOfDays = calculateNumberOfDays(startDate, endDate);

	const onAddInspirationClicked = () => {
		dispatch({
			type: 'UPDATE_ADD_INSPIRATION_DRAWER',
			payload: {
				show: true,
				source: InspirationDrawerSource.OVERVIEW,
			},
		});

		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_ADD_INSPIRATION,
			chat_session_id: chatSessionId,
		});
	};

	const { addedDayCardsDataList, inspiredDayCardsDataList } =
		getUpdatedAndRemovedDayCardsData(
			curDayPlanResults,
			curDayPlanResults,
			gMapsData,
			inspirationPlaceNames,
		);

	const { SCHEDULE = [], location = '' } = dayItinerary;

	const dayCardsDataList = convertScheduleToItineraryDayCardsData(SCHEDULE);
	updateDayCardsDataListWithNewTags(
		dayCardsDataList,
		addedDayCardsDataList,
		inspiredDayCardsDataList,
	);

	return (
		<div
			className="flex flex-col gap-4 mb-4"
			style={{
				opacity: tabChanged && '24%',
				filter: tabChanged && 'blur(2px)',
			}}
		>
			<div className="flex justify-between">
				<div className="flex items-center gap-3 text-2xl">
					<div className="font-semibold">Itinerary </div>
					{itineraryLoading === LoadingState.LOADING ? (
						<div
							className="flex gap-2 items-end"
							style={{ color: '#7750E5', fontWeight: '500' }}
						>
							Generating...
						</div>
					) : (
						<div className={`rounded-3xl ${styles.shadow}`}>
							<AddInspirationButton
								onAddInspirationClicked={onAddInspirationClicked}
								tiktokText="Add Inspiration"
								igText="Add Inspiration"
								smallerVersion
							/>
						</div>
					)}
				</div>

				<div
					className="flex gap-2 items-center cursor-pointer text-highlight font-medium"
					role="presentation"
					onClick={() => {
						sendMessageEvent({
							event: GoogleEventTypes.OVERVIEW_PAGE_SEE_ALL_ITINERARIES,
							chat_session_id: chatSessionId,
						});
						setCurrentView(MainTripPageView.ITINERARY);
					}}
				>
					See All
				</div>
			</div>

			{[LoadingState.LOADED, LoadingState.LOADING].includes(
				itineraryLoading,
			) && (
				<div className="flex items-stretch gap-4">
					<div className="w-1/2 pr-3">
						<ItineraryHeader
							setActiveTab={setActiveItineraryDayTab}
							activeTab={activeItineraryDayTab}
							isLast={numberOfDays - 1 === activeItineraryDayTab}
							numberOfDays={numberOfDays}
							isFirst={activeItineraryDayTab === 0}
							dayPlan={curDayPlanResults[activeItineraryDayTab]}
							loadedDays={curDayPlanResults.length}
						/>

						{dayItinerary.SCHEDULE ? (
							<div>
								<ItineraryDayCards
									dayCardsDataList={dayCardsDataList}
									dayIndex={activeItineraryDayTab}
									sparkEnabled
									highlightCardEnabled
									showEducationPopver
									isLoaded={curDayPlanResults.length === numberOfDays}
									places={places}
									formattedGMapsData={formattedGMapsData}
								/>
								<div
									role="presentation"
									className="flex items-center cursor-pointer mt-2 ml-2 font-semibold gap-2"
									style={{ color: '#7750E5' }}
									onClick={() => {
										sendMessageEvent({
											event: GoogleEventTypes.ITINERARY_OPEN_ADD_ACTIVITY,
											chat_session_id: chatSessionId,
										});
										setAddActivityDetails({
											location,
											index: activeItineraryDayTab,
										});
									}}
								>
									+ Add Activity
								</div>
							</div>
						) : (
							<ItineraryLoadingComponent />
						)}
					</div>

					<div className="w-1/2 rounded-3xl overflow-hidden">
						<MapComponent
							gMapsDataMap={formattedGMapsData}
							activeMarker={activeMarker}
							setActiveMarker={setActiveMarker}
							fallbackCenter={fallbackCenter}
							styles={{
								width: '100%',
								height: '600px',
							}}
						/>
					</div>
				</div>
			)}

			<Drawer
				anchor="right"
				open={!isEmpty(addActivityDetails)}
				onClose={() => setAddActivityDetails({})}
				PaperProps={{
					sx: { width: 'calc(30% - 22px)', minWidth: '300px' },
				}}
			>
				<AddActivity
					addActivityDetails={addActivityDetails}
					setAddActivityDetails={setAddActivityDetails}
				/>
			</Drawer>

			{itineraryLoading === LoadingState.ERROR && (
				<div>There was an error in loading the itinerary</div>
			)}
		</div>
	);
}

export default ItineraryOverview;

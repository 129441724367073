import { useSelector } from 'react-redux';
import FlightRowComponent from './FlightRowComponent';
import FlightPriceComponent from './FlightPriceComponent';

function ItineraryCardComponent({ itinerary, itineraryIndex }) {
	const selectedFlight = useSelector(
		(state) => state.ui_states?.selectedFlight,
	);

	// Note the initial preliminary check is to avoid most needless string comparisons.
	const isCardSelected =
		selectedFlight.quality === itinerary.quality
			? JSON.stringify(itinerary) === JSON.stringify(selectedFlight)
			: false;

	return (
		<div
			className="bg-white rounded-2xl space-y-2 p-4"
			style={{
				border: isCardSelected ? '2px solid #AD95EF' : '1px solid #F0ECFC',
			}}
		>
			<div className="flex justify-between flex-col md:flex-row">
				<div
					className="flex flex-col flex-3 space-y-2"
					style={{ paddingRight: '24px' }}
				>
					{itinerary.legs.map((leg, i) => (
						<FlightRowComponent
							leg={leg}
							numLegs={itinerary.legs.length}
							itineraryIndex={itineraryIndex}
							legIndex={i}
						/>
					))}
				</div>

				<FlightPriceComponent
					itinerary={itinerary}
					isCardSelected={isCardSelected}
				/>
			</div>
		</div>
	);
}

export default ItineraryCardComponent;

import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

function InvalidTrip() {
	const navigate = useNavigate();

	return (
		<div className={styles.main_component}>
			<div className="flex gap-2 justify-center">
				<div className={styles.text}>
					Something went wrong in loading this trip.
					<div
						role="presentation"
						onClick={() => navigate('/')}
						className="flex items-center cursor-pointer mx-1"
						style={{ color: '#7750E5', gap: '6px' }}
					>
						Click here
					</div>
					to go the main page
				</div>
			</div>
		</div>
	);
}

export default InvalidTrip;

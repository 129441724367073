import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import styles from './styles.module.css';
import StopsInfo from '../OverallResults/StopsInfo';
import Logos from '../OverallResults/Logos';

const printNumStops = (stops) => {
	if (stops === 0) {
		return 'Nonstop';
	}
	if (stops === 1) {
		return '1 stop';
	}
	return `${stops} stops`;
};

function FlightsSection() {
	const selectedFlightByDateOption = useSelector(
		(state) => state.ui_states.selectedFlightByDateOption,
	);
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const { legs = [], totalPrice = 0 } =
		selectedFlightByDateOption[activeTabIndex] ?? {};

	const formatDate = (inputDate) => {
		const [year, month, day] = inputDate.split('-').map(Number);
		const dateObject = new Date(year, month - 1, day);
		const options = { weekday: 'short', month: 'short', day: 'numeric' };
		return dateObject.toLocaleDateString('en-US', options);
	};

	const handleBookNow = () => {
		if (legs?.[0]?.bookingLink != null) {
			window.open(legs[0].bookingLink, '_blank');
		}
	};

	return (
		<div className="flex flex-col gap-3 mt-6" style={{ color: '#301345' }}>
			<div className="flex justify-between">
				<div
					className="font-medium text-xl flex items-center justify-between"
					style={{ width: '100%' }}
				>
					Flights
					<div className="flex gap-4 items-center">
						<div className="flex gap-2 items-end font-semibold">
							<div>${totalPrice}</div>

							<div className="text-sm pb-[3px]">/person</div>
						</div>

						<Button
							variant="outlined"
							type="button"
							className={styles.book_button}
							onClick={() => handleBookNow()}
						>
							Book Now
						</Button>
					</div>
				</div>
			</div>

			<div className="flex gap-3 overflow-auto">
				{legs.map((item) => {
					const {
						departureDate = '',
						routes = [],
						departureTime = '',
						source,
						arrivalTime = '',
						destination,
						duration,
						stops,
					} = item;

					const stopDots = Array.from({ length: stops }, () => (
						<div
							className="relative rounded-lg red-dot mx-1"
							style={{
								backgroundColor: '#DC1F90',
								width: '5px',
								height: '5px',
							}}
						/>
					));

					let airlines = routes.map((route) => route.airline);
					airlines = [...new Set(airlines)].slice(0, 4);

					return (
						<div className={styles.card}>
							<div className="text-gray-500 text-xs">
								{formatDate(departureDate)}
							</div>

							<div className="flex gap-2 mt-2 h-9 w-full">
								<Logos airlines={airlines} />

								<div
									className="flex justify-between"
									style={{ width: '280px' }}
								>
									<div className="flex flex-col" style={{ gap: '2px' }}>
										<div
											className="text-xs font-medium"
											style={{ color: '#301345' }}
										>
											{departureTime}
										</div>
										<div
											className="text-xs font-medium"
											style={{ color: '#442A57' }}
										>
											{source}
										</div>
									</div>

									<div
										className="flex flex-col items-center text-xs"
										style={{ width: '120px', color: '#301345' }}
									>
										<div style={{ fontWeight: 400 }}>{duration}</div>
										<div
											className="flex flex-row items-center my-1 w-full justify-center"
											style={{
												borderBottom: '1px solid #7F6D8B',
												height: '1px',
												width: '132px',
											}}
										>
											{stopDots}
										</div>
										<div className="flex justify-center items-center">
											<div
												className="mx-1"
												style={{ color: stops ? '#DC1F90' : 'green' }}
											>
												{printNumStops(stops)}
											</div>
											<StopsInfo routes={routes} />
										</div>
									</div>

									<div
										className="flex flex-col"
										style={{ gap: '2px', textAlign: 'end' }}
									>
										<div
											className="text-xs font-medium"
											style={{ color: '#301345' }}
										>
											{arrivalTime}
										</div>
										<div
											className="text-xs font-medium"
											style={{ color: '#442A57' }}
										>
											{destination}
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default FlightsSection;

import axios from 'axios';

export const GoogleEventTypes = {
	// User added messages
	PRE_LC_MESSAGE: 'pre_lc_message',
	POST_LC_MESSAGE: 'post_lc_message',
	POST_REPORT_MESSAGE: 'post_report_message',

	// Trip Loading
	DATE_TABS_LOADED: 'date_tabs_loaded',
	FLIGHTS_LOADED: 'flights_loaded',
	HOTELS_LOADED: 'hotels_loaded',
	DAY_PLANS_LOADED: 'day_plans_loaded',

	// Landing Page inspiration
	LANDING_PAGE_ADD_INSPIRATION: 'landing_page_add_inspiration',
	LANDING_PAGE_VIEW_INSPIRATION: 'landing_page_view_inspiration',
	LANDING_PAGE_INSPIRATION_CONFIRMED: 'landing_page_inspiration_confirmed',

	// Overview Page inspiration
	OVERVIEW_PAGE_ADD_INSPIRATION: 'overview_page_add_inspiration',
	OVERVIEW_PAGE_VIEW_INSPIRATION: 'overview_page_view_inspiration',
	OVERVIEW_PAGE_INSPIRATION_CONFIRMED: 'overview_page_inspiration_confirmed',
	OVERVIEW_PAGE_NEW_ITINERARY: 'overview_page_new_itinerary',

	// Overview page actions
	OVERVIEW_PAGE_SEE_ALL_FLIGHTS: 'overview_page_see_all_flights',
	OVERVIEW_PAGE_SEE_ALL_HOTELS: 'overview_page_see_all_hotels',
	OVERVIEW_PAGE_TRIP_CONTROLS_FROM_FLIGHTS_SECTION:
		'overview_page_trip_controls_from_flights_section',
	OVERVIEW_PAGE_REFRESH_FLIGHTS: 'overview_page_refresh_flights',
	OVERVIEW_PAGE_SEE_ALL_ITINERARIES: 'overview_page_see_all_itineraries',
	CHANGE_DATE_OPTION: 'change_date_option',
	UPDATE_HOTELS_AND_ITINERARY_FOR_DATE_OPTION:
		'update_hotels_and_itinerary_for_date_option',

	// Itinerary Internals
	ITINERARY_REMOVE_ATTACHED_ACTIVITY_CARD:
		'itinerary_remove_attached_activity_card',
	ITINERARY_ATTACH_ACTIVITY_CARD: 'itinerary_attach_activity_card',
	ITINERARY_ACTIVITY_CARD_MORE_INFO: 'itinerary_activity_card_more_info',
	ITINERARY_OPEN_ADD_ACTIVITY: 'itinerary_open_add_activity',
	ITINERARY_CONFIRM_ADD_ACTIVITY: 'itinerary_confirm_add_activity',
	ITINERARY_NEXT_DAY: 'itinerary_next_day',
	ITINERARY_PREVIOUS_DAY: 'itinerary_previous_day',
	ITINERARY_REMOVE_ACTIVITY: 'itinerary_remove_activity',
	ITINERARY_UPDATE_ACTIVITY: 'itinerary_update_activity',

	// Flights Internals
	FLIGHTS_VIEW_CHANGE_SELECTED_FLIGHT: 'flights_view_change_selected_flight',
	FLIGHTS_VIEW_BOOK_SELECTED_FLIGHT: 'flights_view_book_selected_flight',
	FLIGHTS_VIEW_OPEN_TRIP_CONTROLS_FOR_EDIT:
		'flights_view_open_trip_controls_for_edit',
	CHANGE_VIEW_FROM_FLIGHTS_TO_MAIN_PAGE:
		'change_view_from_flights_to_main_page',
	FLIGHTS_VIEW_SEARCH_FOR_FLIGHTS: 'flights_view_search_for_flights',

	// Hotels Internals
	HOTELS_VIEW_CHANGE_SELECTED_HOTEL_OF_LOCATION:
		'hotels_view_change_selected_hotel_of_location',
	HOTELS_VIEW_BOOK_HOTEL_OF_LOCATION: 'hotels_view_book_hotel_of_location',
	HOTELS_VIEW_SWITCH_LOCATION: 'hotels_view_switch_location',
	HOTELS_VIEW_SEE_MORE_HOTELS_OF_LOCATION:
		'hotels_view_see_more_hotels_of_location',
	CHANGE_VIEW_FROM_HOTELS_TO_MAIN_PAGE: 'change_view_from_hotels_to_main_page',
	CHANGE_HOTELS_SORT_CRITERIA: 'change_hotels_sort_criteria',

	// Trip controls
	OPEN_TRIP_CONTROLS: 'open_trip_controls',
	CLOSE_TRIP_CONTROLS: 'close_trip_controls',
	TRIP_CONTROLS_EDIT_LOCATION: 'trip_controls_edit_location',
	TRIP_CONTROLS_CONFIRM_EDIT_LOCATION_CHANGES:
		'trip_controls_confirm_edit_location_changes',
	TRIP_CONTROLS_EDIT_TRANSIT: 'trip_controls_edit_transit',
	TRIP_CONTROLS_CONFIRM_EDIT_TRANSIT_CHANGES:
		'trip_controls_confirm_edit_transit_changes',
	TRIP_CONTROLS_REMOVE_LOCATION: 'trip_controls_remove_location',
	TRIP_CONTROLS_ADD_LOCATION: 'trip_controls_add_location',
	TRIP_CONTROLS_CONFIRM_ADD_LOCATION_CHANGES:
		'trip_controls_confirm_add_location_changes',
	TRIP_CONTROLS_GENERATE_TRIP_BUTTON: 'trip_controls_generate_trip_button',
	TRIP_CONTROLS_CHANGE_DATE_OPTION: 'trip_controls_change_date_option',
	TRIP_CONTROLS_INCREASE_TRAVELLERS: 'trip_controls_increase_travellers',
	TRIP_CONTROLS_DECREASE_TRAVELLERS: 'trip_controls_decrease_travellers',

	// Chat Feed
	CLOSE_CHAT_FEED: 'close_chat_feed',
	OPEN_CHAT_FEED: 'open_chat_feed',

	// Inspiration Internals
	EXTERNAL_INSPIRATION_LINK_ADDED: 'external_inspiration_link_added',
	DISCOVER_INSPIRATION_SELECTED: 'discover_inspiration_selected',

	// Navbar
	MY_TRIPS_CLICKED: 'my_trips_clicked',
	MY_TRIPS_TRIP_SELECTED: 'my_trips_trip_selected',
};

export const sendMessageEvent = (eventParams) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window.dataLayer.push(eventParams);
	}

	axios
		.post('/api/add_event', {
			eventParams,
		})
		.then((response) => {})
		.catch((error) => {});
};

function SearchSvg() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" rx="16" fill="#7750E5" />
			<path
				d="M13.1165 20.233C17.0468 20.233 20.233 17.0468 20.233 13.1165C20.233 9.18616 17.0468 6 13.1165 6C9.18616 6 6 9.18616 6 13.1165C6 17.0468 9.18616 20.233 13.1165 20.233Z"
				stroke="white"
				strokeWidth="1.24997"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.4834 8.5249C15.8834 8.94156 17.0167 9.9832 17.5583 11.3165"
				stroke="white"
				strokeWidth="1.24997"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.3687 19.7949C20.9326 19.3588 20.2269 19.3575 19.7925 19.792C19.358 20.2264 19.3594 20.9321 19.7954 21.3682L23.6373 25.21C24.0734 25.6461 24.7791 25.6474 25.2135 25.213C25.648 24.7786 25.6466 24.0729 25.2106 23.6368L21.3687 19.7949Z"
				stroke="white"
				strokeWidth="1.24997"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
export default SearchSvg;

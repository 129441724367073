import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';
import airportsArray from '@helpers/airports';

import './styles.css';

const offlineLocations = airportsArray.map((airport) => {
	return {
		name: airport.name,
		display: airport.display,
		city: airport.city,
	};
});

const getSuggestionsOffline = (value) => {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;

	const filteredList =
		inputLength === 0
			? []
			: offlineLocations.filter(
					(loc) =>
						loc.name.toLowerCase().slice(0, inputLength) === inputValue ||
						loc.display.toLowerCase().slice(0, inputLength) === inputValue ||
						loc.city.toLowerCase().slice(0, inputLength) === inputValue,
				);

	return filteredList.map((loc) => {
		return {
			name: `${loc.name} (${loc.code})`,
			country: loc.country,
		};
	});
};

const onSuggestionsFetched = ({
	inputValue,
	suggestions,
	setSuggestionsCache = () => {},
	setSuggestions = () => {},
}) => {
	setSuggestionsCache((prevCache) => ({
		...prevCache,
		[inputValue]: suggestions,
	}));
	setSuggestions(suggestions);
};

const loadSuggestions = ({ value, setSuggestionsCache, setSuggestions }) => {
	let cancel;

	// Cancel the previous request before making a new request
	if (cancel !== undefined) {
		cancel();
	}

	const backendURL = process.env.REACT_APP_BACKEND_URL;
	const queryParams = new URLSearchParams();
	queryParams.append('term', value);

	axios
		.get(`${backendURL}/api/search_locations?${queryParams.toString()}`, {
			cancelToken: new axios.CancelToken((c) => {
				cancel = c;
			}),
		})
		.then((response) => {
			onSuggestionsFetched({
				inputValue: value,
				suggestions: response.data,
				setSuggestionsCache,
				setSuggestions,
			});
		})
		.catch((error) => {
			if (axios.isCancel(error)) {
				console.log('Request canceled:', error.message);
			} else {
				console.log('Another error happened:', error.message);
			}
		});
};

function AirportAutoCompleteSelect(props) {
	const {
		curValue = [],
		text = '',
		onSuggestionSelected,
		removeSelectedItem,
	} = props || {};

	const [value, setValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [suggestionsCache, setSuggestionsCache] = useState({});

	const onSuggestionsFetchRequested = ({ value: newValue }) => {
		if (suggestionsCache[newValue]) {
			setSuggestions(suggestionsCache[newValue]);
		} else {
			const shouldUseOfflineSuggestions =
				process.env.REACT_APP_USE_OFFLINE_LOCATION_SUGGESTIONS === 'true';
			if (shouldUseOfflineSuggestions) {
				onSuggestionsFetched({
					inputValue: newValue,
					suggestions: getSuggestionsOffline(newValue),
					setSuggestionsCache,
					setSuggestions,
				});
			} else {
				loadSuggestions({
					value: newValue,
					setSuggestionsCache,
					setSuggestions,
				});
			}
		}
	};

	const getSuggestionValue = (suggestion) => suggestion.name;

	const renderSuggestion = (suggestion) => {
		return (
			<div id="flight_form_auto_complete_suggestion">
				{suggestion.name}
				<span className="ml-2" style={{ color: 'grey', fontSize: '12px' }}>
					{suggestion.country}
				</span>
			</div>
		);
	};

	const onChange = (event, { newValue }) => {
		setValue(newValue);
	};

	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const renderInputComponent = (inputProps) => {
		return (
			<div
				className="input-tag-container bg-white rounded"
				style={{
					boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.05)',
					height: '48px',
					padding: '4px 8px 4px 12px',
				}}
			>
				<div style={{ color: '#92849D', fontWeight: 400, fontSize: '14px' }}>
					{text}
				</div>
				{curValue.map((item, index) => (
					<div className="tag mr-2 rounded flex items-center" key={index}>
						{item}
						<RxCross2
							id="flight_form_remove_airport"
							className="text-neutrals-dark_background_text h-4 w-4 ml-1 cursor-pointer 
              hover:text-neutrals-hover_dark_background_text z-30 hover:animate-spin-quarter"
							onClick={() => removeSelectedItem(index)}
							style={{ color: '#9273EA' }}
						/>
					</div>
				))}
				<input {...inputProps} className="hidden-input h-auto" />
			</div>
		);
	};

	const inputProps = {
		value,
		onChange,
	};

	return (
		<Autosuggest
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={getSuggestionValue}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps}
			renderInputComponent={renderInputComponent}
			onSuggestionSelected={(prop) => {
				onSuggestionSelected(prop);
				setValue('');
			}}
		/>
	);
}

export default AirportAutoCompleteSelect;

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FlightIcon from '@commons/icons/FlightIcon';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import CarIcon from '@commons/icons/CarIcon';
import StyledFlightIcon from '@commons/icons/StyledFlightIcon';
import { handleLookupAirports } from '@helpers/ModelApiUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { incrementOneDay, decrementOneDay } from '@helpers/DateUtils';
import styles from './styles.module.css';
import FlightTransit from './FlightTransit/FlightTransit';
import NonFlightTransit from './NonFlightTransit';

const parseDate = (dateString) => {
	const [year, month, day] = dateString.split('-').map(Number);
	return new Date(year, month - 1, day);
};

const parseTime = (timeString) => {
	if (!timeString) return null;
	const [hours, minutes] = timeString.split(':').map(Number);
	return new Date(0, 0, 0, hours, minutes);
};

const formatDate = (date) => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
};

const formatTimeToNum = (time) => {
	const hours = time.getHours().toString().padStart(2, '0');
	const minutes = time.getMinutes().toString().padStart(2, '0');
	return `${hours}:${minutes}`;
};

// function decrementDays(dateString, noOfDays) {
// 	let date = dayjs(dateString);
// 	date = date.subtract(noOfDays, 'day');

// 	return date.format('YYYY-MM-DD');
// }

// function incrementDays(dateString, noOfDays) {
// 	let date = dayjs(dateString);
// 	date = date.add(noOfDays, 'day');

// 	return date.format('YYYY-MM-DD');
// }

function formatTime(timeString) {
	const [hours] = timeString.split(':');

	const parsedHours = parseInt(hours, 10);

	return parsedHours;
}

const maxPossibleFlightPrice = 10000;

const TABS_MAPPING = [
	{
		label: 'Flight',
		value: 'FLIGHT',
		icon: FlightIcon,
	},
	{
		label: 'Non Flight',
		value: 'NON_FLIGHT',
		icon: CarIcon,
	},
];

const COMPONENT_MAPPING = {
	FLIGHT: FlightTransit,
	NON_FLIGHT: NonFlightTransit,
};

function EditTransitComponent({
	setEditDetails = () => {},
	setTripDetails = () => {},
	tripDetails = [],
	index = 0,
	setTripModificationDetails = () => {},
	localActiveTabIndex = 0,
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);

	const { maxPrice } = tripTransitionsDetails;
	const { travelData = {} } = tripDetails[localActiveTabIndex][index] || {};
	const { travelData: prevTravelData = {} } =
		tripDetails[localActiveTabIndex][index - 1] || {};
	const { travelData: NextTravelData = {} } =
		tripDetails[localActiveTabIndex][index + 1] || {};

	const {
		source = '',
		destination = '',
		type = '',
		flightDetails = {},
		nonFlightDetails = {},
	} = travelData;

	const {
		DEP_TIME_RANGE_START,
		DEP_TIME_RANGE_END,
		ARR_TIME_RANGE_START,
		ARR_TIME_RANGE_END,
		fromAirport: initialFromAirport,
		toAirport: initialToAirport,
	} = flightDetails || {};

	const initialTimeOfJourney = parseTime(nonFlightDetails?.timeOfDeparture);

	const initialTimeOfArrival = parseTime(nonFlightDetails?.timeOfArrival);

	const initialDateOfJourney = parseDate(
		nonFlightDetails?.dateOfJourney || flightDetails?.dateOfJourney,
	);

	const minDate = incrementOneDay(
		prevTravelData?.nonFlightDetails?.dateOfArrival ||
			prevTravelData?.flightDetails?.dateOfArrival ||
			prevTravelData?.nonFlightDetails?.dateOfJourney ||
			prevTravelData?.flightDetails?.dateOfJourney,
	);
	const maxDate = decrementOneDay(
		NextTravelData?.nonFlightDetails?.dateOfArrival ||
			NextTravelData?.flightDetails?.dateOfArrival ||
			NextTravelData?.nonFlightDetails?.dateOfJourney ||
			NextTravelData?.flightDetails?.dateOfJourney,
	);

	const initialDateOfArrival = parseDate(
		nonFlightDetails?.dateOfArrival ||
			nonFlightDetails?.dateOfJourney ||
			flightDetails?.dateOfJourney,
	);

	const [value, setValue] = useState(
		['flight', 'FLIGHT'].includes(type) ? 'FLIGHT' : 'NON_FLIGHT',
	);
	const [maxPriceFilter, setMaxPriceFilter] = useState(
		maxPrice <= 0 ? maxPossibleFlightPrice : maxPrice,
	);
	const [timeFilter, setTimeFilter] = useState([
		[
			formatTime(DEP_TIME_RANGE_START || '00:00'),
			formatTime(DEP_TIME_RANGE_END || '24:00'),
		],
		[
			formatTime(ARR_TIME_RANGE_START || '00:00'),
			formatTime(ARR_TIME_RANGE_END || '24:00'),
		],
	]);
	const [durationFilter, setDurationFilter] = useState(
		flightDetails?.MAX_DURATION || 38,
	);

	const initialFlightScheduleDateOfJourney =
		flightDetails?.dateOfJourney || nonFlightDetails?.dateOfJourney;

	const initialIsChooseTimeChecked =
		!initialTimeOfJourney || !initialTimeOfArrival;

	const [flightDeparture, setFlightDeparture] = useState(
		initialFlightScheduleDateOfJourney,
	);
	const [isChooseTimeChecked, setIsChooseTimeChecked] = useState(
		initialIsChooseTimeChecked,
	);
	const [finalAirportDetails, setFinalAirportDetails] = useState({
		fromAirport: initialFromAirport,
		toAirport: initialToAirport,
	});

	const initialNonFlightScheduleDateOfJourney = new Date(
		initialDateOfJourney.getFullYear(),
		initialDateOfJourney.getMonth(),
		initialDateOfJourney.getDate(),
		initialTimeOfJourney ? initialTimeOfJourney.getHours() : 10,
		initialTimeOfJourney ? initialTimeOfJourney.getMinutes() : 0,
	);

	const initialNonFlightScheduleDateOfArrival = new Date(
		initialDateOfArrival.getFullYear(),
		initialDateOfArrival.getMonth(),
		initialDateOfArrival.getDate(),
		initialTimeOfArrival ? initialTimeOfArrival.getHours() : 14,
		initialTimeOfArrival ? initialTimeOfArrival.getMinutes() : 0,
	);

	const [nonFlightSchedule, setNonFlightSchedule] = useState({
		dateOfJourney: initialNonFlightScheduleDateOfJourney,
		dateOfArrival: initialNonFlightScheduleDateOfArrival,
		modeOfTransport: ['flight', 'FLIGHT'].includes(type) ? 'CAR' : type,
	});

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	const propsMapping = {
		FLIGHT: {
			setMaxPriceFilter,
			maxPriceFilter,
			maxPrice,
			timeFilter,
			setTimeFilter,
			tripDetails,
			index,
			durationFilter,
			setDurationFilter,
			flightDeparture,
			setFlightDeparture,
			localActiveTabIndex,
			minDate,
			maxDate,
			finalAirportDetails,
			setFinalAirportDetails,
		},
		NON_FLIGHT: {
			nonFlightSchedule,
			setNonFlightSchedule,
			isChooseTimeChecked,
			setIsChooseTimeChecked,
			minDate,
			maxDate,
		},
	};

	const handleSubmit = async () => {
		sendMessageEvent({
			event: GoogleEventTypes.TRIP_CONTROLS_CONFIRM_EDIT_TRANSIT_CHANGES,
			chat_session_id: chatSessionId,
		});

		const { modeOfTransport } = nonFlightSchedule;

		const initialArrivalDate = dayjs(
			flightDetails?.dateOfJourney ||
				nonFlightDetails?.dateOfArrival ||
				nonFlightDetails?.dateOfJourney,
		);

		const updatedArrivalDate = dayjs(
			value === 'FLIGHT'
				? flightDeparture
				: nonFlightSchedule?.dateOfArrival || nonFlightSchedule?.dateOfJourney,
		);

		const differenceInDays = updatedArrivalDate.diff(initialArrivalDate, 'day');

		if (
			type === (value === 'FLIGHT' ? 'FLIGHT' : modeOfTransport) &&
			isChooseTimeChecked === initialIsChooseTimeChecked
		) {
			if (
				value === 'NON_FLIGHT' &&
				initialNonFlightScheduleDateOfJourney.getTime() ===
					nonFlightSchedule.dateOfJourney.getTime() &&
				initialNonFlightScheduleDateOfArrival.getTime() ===
					nonFlightSchedule.dateOfArrival.getTime()
			) {
				setEditDetails(null);
				return;
			}

			if (
				value === 'FLIGHT' &&
				new Date(flightDeparture).getTime() ===
					new Date(initialFlightScheduleDateOfJourney).getTime()
			) {
				setEditDetails(null);
				return;
			}
		}

		const updatedTripDetailsPromises = tripDetails[localActiveTabIndex].map(
			async (cur, ind) => {
				let dateOfJourney =
					cur?.travelData?.flightDetails?.dateOfJourney ||
					cur?.travelData?.nonFlightDetails?.dateOfJourney;
				let nightsCount = cur?.noOfNights;
				let dateOfArrival = cur?.travelData?.nonFlightDetails?.dateOfArrival;
				let timeOfArrival =
					cur?.travelData?.nonFlightDetails?.timeOfArrival || null;
				let timeOfDeparture =
					cur?.travelData?.nonFlightDetails?.timeOfDeparture || null;
				let curFlightDetails = cur?.travelData?.flightDetails;

				if (ind === index) {
					nightsCount += differenceInDays;
					if (value === 'FLIGHT') {
						dateOfJourney = flightDeparture;
					}

					if (value !== 'FLIGHT') {
						dateOfJourney = formatDate(nonFlightSchedule?.dateOfJourney);
						dateOfArrival = formatDate(nonFlightSchedule?.dateOfArrival);
						timeOfArrival = !isChooseTimeChecked
							? formatTimeToNum(nonFlightSchedule?.dateOfArrival)
							: null;
						timeOfDeparture = !isChooseTimeChecked
							? formatTimeToNum(nonFlightSchedule?.dateOfJourney)
							: null;
					}
				}

				if (ind === index + 1) {
					nightsCount -= differenceInDays;
				}

				// if (ind > index) {  // not moving back the dates
				// 	if (differenceInDays > 0) {
				// 		dateOfJourney = incrementDays(dateOfJourney, differenceInDays);

				// 		if (dateOfArrival) {
				// 			dateOfArrival = incrementDays(dateOfArrival, differenceInDays);
				// 		}
				// 	}

				// 	if (differenceInDays < 0) {
				// 		dateOfJourney = decrementDays(
				// 			dateOfJourney,
				// 			Math.abs(differenceInDays),
				// 		);

				// 		if (dateOfArrival) {
				// 			dateOfArrival = decrementDays(
				// 				dateOfArrival,
				// 				Math.abs(differenceInDays),
				// 			);
				// 		}
				// 	}
				// }

				if (ind === index && value === 'FLIGHT') {
					let { fromAirport, toAirport } = curFlightDetails || {};

					if (!fromAirport) {
						fromAirport = await handleLookupAirports({
							city_list: [cur?.travelData?.source],
						});
					}
					if (!toAirport) {
						toAirport = await handleLookupAirports({
							city_list: [cur?.travelData?.destination],
						});
					}

					curFlightDetails = {
						...(curFlightDetails || {}),
						dateOfJourney,
						maxStops: -1,
						fromAirport,
						toAirport,
					};
				}

				if (ind === index) {
					return {
						...cur,
						noOfNights: ['start', 'end'].includes(cur.type)
							? undefined
							: nightsCount,
						travelData:
							cur.type === 'end'
								? null
								: {
										...cur.travelData,
										type: value === 'FLIGHT' ? 'FLIGHT' : modeOfTransport,
										flightDetails: value === 'FLIGHT' ? curFlightDetails : null,
										nonFlightDetails:
											value !== 'FLIGHT'
												? {
														...cur.travelData.nonFlightDetails,
														dateOfArrival,
														timeOfArrival,
														dateOfJourney,
														timeOfDeparture,
													}
												: null,
									},
					};
				}

				return {
					...cur,
					noOfNights: ['start', 'end'].includes(cur.type)
						? undefined
						: nightsCount,
					travelData:
						cur.type === 'end'
							? null
							: {
									...cur.travelData,
									flightDetails: ['flight', 'FLIGHT'].includes(
										cur.travelData.type,
									)
										? { ...curFlightDetails, dateOfJourney }
										: null,
									nonFlightDetails: !['flight', 'FLIGHT'].includes(
										cur.travelData.type,
									)
										? {
												...cur.travelData.nonFlightDetails,
												dateOfJourney,
											}
										: null,
								},
				};
			},
		);

		const promiseResults = await Promise.all(updatedTripDetailsPromises);

		const updatedTripDetails = tripDetails.map((curTripData, dateIndex) => {
			if (dateIndex !== localActiveTabIndex) {
				return curTripData.map((cur, ind) => {
					if (ind !== index) {
						return cur;
					}

					const originalDateOfJourney =
						cur?.travelData?.flightDetails?.dateOfJourney ||
						cur?.travelData?.nonFlightDetails?.dateOfJourney;

					const originalDateOfArrival =
						cur?.travelData?.nonFlightDetails?.dateOfArrival ||
						cur?.travelData?.flightDetails?.dateOfJourney;

					return {
						...cur,
						travelData:
							cur.type === 'end'
								? null
								: {
										...cur.travelData,
										type: value === 'FLIGHT' ? 'FLIGHT' : modeOfTransport,
										flightDetails:
											value === 'FLIGHT'
												? {
														...promiseResults[ind].travelData.flightDetails,
														dateOfJourney: originalDateOfJourney,
													}
												: null,
										nonFlightDetails:
											value !== 'FLIGHT'
												? cur?.travelData?.nonFlightDetails ?? {
														dateOfArrival: originalDateOfArrival,
														timeOfArrival:
															promiseResults[ind].travelData?.nonFlightDetails
																?.timeOfArrival || null,
														dateOfJourney: originalDateOfJourney,
														timeOfDeparture:
															promiseResults[ind].travelData?.nonFlightDetails
																?.timeOfDeparture || null,
													}
												: null,
									},
					};
				});
			}

			return promiseResults;
		});

		setTripDetails(updatedTripDetails);
		setTripModificationDetails({
			modified: true,
			transportChanged: true,
			messages: [],
		});
		setEditDetails(null);
	};

	const ActiveComponent = COMPONENT_MAPPING[value];
	const activeComponentProps = propsMapping[value];

	return (
		<div
			className="flex flex-col justify-between"
			style={{ height: '90vh', color: '#301345' }}
		>
			<div className="flex flex-col gap-2">
				<div
					className="flex gap-2 items-center cursor-pointer text-2xl"
					style={{
						borderBottom: '1px solid #E1DEE3',
						paddingBottom: '20px',
					}}
					role="presentation"
					onClick={() => setEditDetails(null)}
				>
					<ArrowBackIosIcon fontSize="small" />

					<div style={{ color: '#301345', fontWeight: 500 }}>Edit transit</div>
				</div>

				<div
					className="flex items-center"
					style={{
						width: '100%',
						borderBottom: '1px solid #E1DEE3',
						paddingBottom: '28px',
						paddingTop: '20px',
					}}
				>
					<div>{source}</div>
					<div
						className="flex-grow"
						style={{ borderBottom: '1px solid #F7D1E9', marginLeft: '60px' }}
					/>
					<div
						style={{
							width: '24px',
							height: '24px',
							borderRadius: '50%',
							backgroundColor: '#F7D1E9',
							alignItems: 'center',
							display: 'flex',
						}}
					>
						<div style={{ margin: 'auto' }}>
							<StyledFlightIcon />
						</div>
					</div>
					<div
						className="flex-grow"
						style={{ borderBottom: '1px solid #F7D1E9', marginRight: '60px' }}
					/>
					<div>{destination}</div>
				</div>

				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						className="lowercase"
						value={value}
						onChange={handleChange}
						textColor="primary"
						indicatorColor="primary"
						aria-label="primary tabs example"
						variant="fullWidth"
					>
						{TABS_MAPPING.map((tab) => {
							const { label, value: tabValue, icon: ActiveIcon } = tab;

							return (
								<Tab
									sx={{ textTransform: 'none', fontSize: '14px' }}
									key={tabValue}
									value={tabValue}
									icon={
										<div style={{ marginRight: '8px' }}>
											<ActiveIcon />
										</div>
									}
									iconPosition="start"
									label={label}
								/>
							);
						})}
					</Tabs>
				</Box>

				<ActiveComponent {...activeComponentProps} />

				<div className="text-sm my-4">
					This information is needed to help us find your hotels and day plans
				</div>
			</div>

			<Button
				variant="contained"
				type="button"
				className={styles.submit_button}
				onClick={handleSubmit}
				style={{
					textTransform: 'unset',
				}}
			>
				Confirm changes
			</Button>
		</div>
	);
}

export default EditTransitComponent;

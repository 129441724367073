import { useDispatch, useSelector } from 'react-redux';
import { getTransportIcon } from '@utils/IconUtils';
import FlightIcon from '@commons/icons/FlightIcon';
import startCase from '@utils/startCase';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Popover, Snackbar, TextField } from '@mui/material';
import InfoBannerContent from '@commons/InfoBannerContent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import EditPencil from '@commons/icons/EditPencil';
import CrossIcon from '@commons/icons/CrossIcon';
import DoneIcon from '@mui/icons-material/Done';
import styles from './styles.module.css';

const NUM_OF_PLACES_TO_SHOW_INCL_START_LOCATION = 6;

const getMaxWidth = ({ locationsLength }) => {
	if (locationsLength - 2 > 3) {
		return 100;
	}

	return 150;
};

function Topbar() {
	const dispatch = useDispatch();
	const pageId = useSelector((state) => state.ui_states.pageId);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const showTripControlsPopover = useSelector(
		(state) => state.ui_states.showTripControlsPopover,
	);
	const showMyTripsPopover = useSelector(
		(state) => state.ui_states.showMyTripsPopover,
	);
	const showTripControls = useSelector(
		(state) => state.ui_states.showTripControls,
	);
	const isTripFullyLoaded =
		useSelector((state) => state.ui_states.isTripFullyLoaded) ?? true;

	const showUserEducationPopoverForTopbar =
		useSelector(
			(state) =>
				state.profile?.user_preferences?.show_user_education_popover
					?.show_trip_controls_popover,
		) ?? true;

	const isMidReportGenerated = useSelector(
		(state) => state.ui_states.isMidReportGenerated,
	);
	const initialTripTitle = useSelector((state) => state.ui_states.tripTitle);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);

	const isHeaderAnimationCompleted = useSelector(
		(state) => state.ui_states.isHeaderAnimationCompleted,
	);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const { travelSets = [], transitionReqs } = tripTransitionsDetails;

	const [anchorEl, setAnchorEl] = useState(null);
	const [isTitleHovered, setIsTitleHovered] = useState(false);
	const [isTitleEditActive, setIsTitleEditActive] = useState(false);
	const [showErrorToast, setShowErrorToast] = useState(false);
	const [tripTitle, setTripTitle] = useState(initialTripTitle);

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		numTravellers,
	} = tripLocationDetails;

	const transitionIcons = transitionReqs.map(
		(x) => getTransportIcon(x.toUpperCase()) || FlightIcon,
	);
	const travelDetails = travelSets[0]; // temp
	const finalData = [
		{ location: startLocation, id: 'place', type: 'start' },
		...places
			.filter((place) => ![startLocation, endLocation].includes(place))
			.map((place) => ({ location: place, id: 'place', type: 'stop' })),
		{ location: endLocation, id: 'place', type: 'end' },
	].reduce((acc, cur, ind) => {
		const { type = '' } = cur;
		if (['start', 'end'].includes(type)) {
			return [...acc, { ...cur, text: startCase(type) }];
		}
		if (!travelSets.length) {
			return [...acc, { ...cur, text: null }];
		}

		let text = '';
		try {
			const startDateDetails = travelDetails[ind - 1];
			const endDateDetails = travelDetails[ind];
			const startDate = dayjs(
				(startDateDetails.flightDetails || startDateDetails.nonFlightDetails)
					.dateOfJourney,
			);
			const endDate = dayjs(
				(endDateDetails.flightDetails || endDateDetails.nonFlightDetails)
					.dateOfJourney,
			);
			const differenceInDays = endDate.diff(startDate, 'day');
			text = `${differenceInDays} Days`;
		} catch (error) {
			console.error('Caught error in Topbar:', error);
		}

		return [...acc, { ...cur, text }];
	}, []);

	const handleShowTripControls = () => {
		if (!isTripFullyLoaded) {
			return;
		}

		sendMessageEvent({
			event: GoogleEventTypes.OPEN_TRIP_CONTROLS,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_SHOW_TRIP_CONTROLS',
			payload: !showTripControls,
		});
	};

	const handleCloseTripControls = () => {
		sendMessageEvent({
			event: GoogleEventTypes.CLOSE_TRIP_CONTROLS,
			chat_session_id: chatSessionId,
		});
		dispatch({
			type: 'UPDATE_SHOW_TRIP_CONTROLS',
			payload: false,
		});
	};

	const locationsLength = finalData.length;

	const maxWidth = useMemo(
		() => getMaxWidth({ locationsLength }),
		[locationsLength],
	);

	const handleClose = () => {
		setAnchorEl(null);

		dispatch({
			type: 'UPDATE_TRIP_CONTROLS_POPOVER',
			payload: false,
		});
	};

	const handleCloseToast = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowErrorToast(false);
	};

	const handleUpdateTitle = async () => {
		const backendURL = process.env.REACT_APP_BACKEND_URL;

		const postFormData = new FormData();

		postFormData.append('trip_title', tripTitle);
		postFormData.append('chat_session_id', chatSessionId);

		try {
			const response = await fetch(`${backendURL}/api/update_trip_title`, {
				method: 'POST',
				body: postFormData,
				timeout: 10000,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			setTripTitle(tripTitle);
			setIsTitleEditActive(false);
			setIsTitleHovered(false);
		} catch (error) {
			setShowErrorToast(true);
		}
	};

	const open = Boolean(anchorEl);

	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		if (!showUserEducationPopoverForTopbar && !showMyTripsPopover) {
			dispatch({
				type: 'UPDATE_TRIP_CONTROLS_POPOVER',
				payload: false,
			});
		}

		if (
			!isHeaderAnimationCompleted ||
			!showTripControlsPopover ||
			!showUserEducationPopoverForTopbar ||
			showMyTripsPopover
		) {
			return;
		}

		setTimeout(() => {
			const defaultAnchorEl = document.getElementById('popover-anchor');
			setAnchorEl(defaultAnchorEl);
		}, 1000);
	}, [isHeaderAnimationCompleted, showMyTripsPopover, showTripControlsPopover]);

	useEffect(() => {
		if (initialTripTitle) {
			setTripTitle(initialTripTitle);
		}
	}, [initialTripTitle]);

	if (!pageId || !isMidReportGenerated) {
		return null;
	}

	if (showTripControls) {
		return (
			<div
				className={styles.header_container}
				role="presentation"
				onClick={handleCloseTripControls}
			>
				<ArrowBackIosIcon fontSize="small" />

				<div style={{ color: '#301345', fontSize: '20px', fontWeight: 600 }}>
					Edit Trip
				</div>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			{isTitleEditActive ? (
				<div className="flex items-center gap-5" style={{ width: '34%' }}>
					<TextField
						id="outlined-basic"
						label=""
						variant="outlined"
						size="small"
						style={{ width: '100%' }}
						value={tripTitle}
						onChange={(e) => setTripTitle(e.target.value)}
						onKeyDown={(event) => {
							if (event.key === 'Enter' && !event.shiftKey) {
								event.preventDefault();
								handleUpdateTitle();
							}
						}}
					/>

					<div
						className="cursor-pointer"
						role="presentation"
						onClick={() => {
							setIsTitleEditActive(false);
							setTripTitle(initialTripTitle);
							setIsTitleHovered(false);
						}}
					>
						<CrossIcon size="15" color="red" />
					</div>

					<div
						className="cursor-pointer"
						role="presentation"
						onClick={handleUpdateTitle}
					>
						<DoneIcon style={{ fontSize: '26px', color: 'green' }} />
					</div>
				</div>
			) : (
				<div
					className={`${styles.heading} ${isHeaderAnimationCompleted && styles.complete}`}
					onMouseEnter={() => setIsTitleHovered(true)}
					onMouseLeave={() => {
						if (isTitleEditActive) {
							return;
						}
						setIsTitleHovered(false);
					}}
					role="presentation"
					onClick={() => setIsTitleEditActive(true)}
				>
					<div className={styles.heading_text}>
						{!tripTitle ? 'Untitled Trip' : tripTitle}
					</div>

					{isTitleHovered ? (
						<div className="w-5">
							<EditPencil size="20" />
						</div>
					) : null}
				</div>
			)}

			{isHeaderAnimationCompleted ? (
				<div id="popover-anchor" className={styles.widget_container}>
					<div
						role="presentation"
						className={`${styles.widget} ${anchorEl && styles.highlight} ${!isTripFullyLoaded && styles.disable}`}
						onClick={handleShowTripControls}
					>
						{finalData.map(({ location }, index) => {
							const ActiveIcon = transitionIcons[index] || FlightIcon;

							if (
								index === NUM_OF_PLACES_TO_SHOW_INCL_START_LOCATION &&
								index !== locationsLength - 1
							) {
								const remainingPlaces =
									locationsLength -
									NUM_OF_PLACES_TO_SHOW_INCL_START_LOCATION -
									1;

								return (
									<div
										className="flex mx-2 items-center"
										style={{ gap: '6px' }}
									>
										<div
											className="w-2 h-2"
											style={{
												width: '5px',
												height: '5px',
												borderRadius: '2.5px',
												background: '#92849D',
												marginTop: '2px',
											}}
										/>
										+{remainingPlaces}
										<div
											className="w-1 h-1"
											style={{
												width: '5px',
												height: '5px',
												borderRadius: '2.5px',
												background: '#92849D',
												marginTop: '2px',
											}}
										/>
									</div>
								);
							}

							if (
								index > NUM_OF_PLACES_TO_SHOW_INCL_START_LOCATION &&
								index !== locationsLength - 1
							) {
								return null;
							}

							return (
								<div key={`${location}_${index}`} className={styles.card}>
									<div className={styles.location} style={{ maxWidth }}>
										{location.split(',')[0]}
									</div>

									{index < transitionIcons.length &&
									(index !== NUM_OF_PLACES_TO_SHOW_INCL_START_LOCATION - 1 ||
										locationsLength ===
											NUM_OF_PLACES_TO_SHOW_INCL_START_LOCATION + 1) ? (
										<div style={{ margin: '0 12px' }}>
											<ActiveIcon
												width="14px"
												height="14px"
												sx={{ width: '14px', height: '14px' }}
												stroke="#DC1F90"
											/>
										</div>
									) : null}
								</div>
							);
						})}
					</div>

					<div className="font-bold text-lg" style={{ color: '#301345' }}>
						·
					</div>

					<div
						className="flex gap-2 items-center text-sm font-medium"
						style={{ color: '#301345' }}
					>
						{numTravellers} adults
					</div>
				</div>
			) : null}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				slotProps={{
					paper: {
						style: { marginTop: 8 },
					},
				}}
			>
				<InfoBannerContent
					mainText="Every major aspect of your trip - dates, destinations, modes of transport and more - can be edited from here."
					subText={null}
					title="Edit your trip from here."
					handleClose={handleClose}
					popoverName="show_trip_controls_popover"
				/>
			</Popover>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showErrorToast}
				autoHideDuration={5000}
				onClose={handleCloseToast}
			>
				<Alert
					onClose={handleCloseToast}
					severity="error"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Unable to proceed. Please try again!
				</Alert>
			</Snackbar>
		</div>
	);
}

export default Topbar;

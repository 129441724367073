function MyTripsIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 32 32"
			fill="none"
		>
			<rect width="32" height="32" fill="white" />
			<path
				d="M23.325 5.49997C23.3278 5.50278 23.3279 5.50731 23.3252 5.51023L19.346 9.82633C19.1067 10.0859 18.7483 10.2 18.403 10.1267L7.58103 7.8278C7.24984 7.75745 6.90554 7.85946 6.66613 8.09887L5.99022 8.77478C5.52568 9.23932 5.62765 10.018 6.19615 10.3472L13.4056 14.5228C13.9578 14.8426 14.0733 15.5908 13.6432 16.0621L9.44213 20.6662C9.2051 20.926 8.84904 21.0422 8.50442 20.9722L6.33635 20.5319C6.00766 20.4651 5.66739 20.5676 5.43023 20.8048L5.04597 21.189C4.58274 21.6523 4.68261 22.4284 5.24803 22.7592L7.54413 24.1028C7.69222 24.1895 7.81552 24.3128 7.90218 24.4609L9.24577 26.757C9.57663 27.3224 10.3527 27.4223 10.816 26.959L11.2002 26.5748C11.4374 26.3376 11.5399 25.9973 11.4731 25.6687L11.0328 23.5006C10.9628 23.156 11.079 22.7999 11.3388 22.5629L15.9429 18.3618C16.4142 17.9317 17.1624 18.0472 17.4822 18.5994L21.6578 25.8089C21.987 26.3774 22.7657 26.4793 23.2302 26.0148L23.9061 25.3389C24.1455 25.0995 24.2476 24.7552 24.1772 24.424L21.8783 13.602C21.805 13.2567 21.9191 12.8983 22.1787 12.659L26.5 8.675C27.37 7.885 27.87 6.755 27.87 5.575C27.87 4.775 27.22 4.125 26.42 4.125C25.2423 4.125 24.1145 4.61306 23.3247 5.48977C23.3221 5.4927 23.3222 5.49718 23.325 5.49997V5.49997Z"
				stroke="#36174D"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default MyTripsIcon;

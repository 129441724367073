import { findBestFlight, sortFlights } from '@commons/Flights/FlightsUtils';
import { LoadingState, SortOptions } from '@helpers/Enums';
import { handleFindFlightsImp } from '@helpers/FlightsQueryHelper';

const flightQueryForSingleDateTab = ({
	tripData,
	existingFlightResults,
	payload,
	flightQuery,
	curActiveTabIndex,
	sortOption,
	dispatch,
}) => {
	const { travelSets } = tripData || {};

	const allResults = [
		...payload,
		...(existingFlightResults || Array(travelSets.length - 1).fill(null)),
	];

	let tabsData = allResults.map((f, index) => ({
		title: f?.dateOptionLabel || '',
		content: f?.itineraries || [],
		flightset: flightQuery.FLIGHTSETS[index],
		disableDatesWithMessage: null,
	}));

	tabsData = sortFlights(tabsData, sortOption);

	const { tabIndex } = findBestFlight(tabsData);

	const selectedTabIndex =
		curActiveTabIndex !== -1 ? curActiveTabIndex : tabIndex;

	dispatch({
		type: 'UPDATE_ACTIVE_TAB_INDEX',
		payload: selectedTabIndex,
	});
	dispatch({
		type: 'UPDATE_BEST_TAB_INDEX',
		payload: tabIndex,
	});
	dispatch({
		type: 'UPDATE_SELECTED_FLIGHT_FOR_DATE_OPTION',
		payload: {
			index: 0,
			selectedFlight: tabsData[0]?.content?.[0] || [],
		},
	});
	dispatch({
		type: 'UPDATE_FLIGHT_RESULTS',
		payload: allResults,
	});
	dispatch({
		type: 'UPDATE_LOADING_STATE',
		payload: {
			flightsLoading: LoadingState.LOADED,
		},
	});

	dispatch({
		type: 'UPDATE_SELECTED_FLIGHT',
		payload: tabsData[selectedTabIndex]?.content?.[0] || {},
	});

	return { tabsData, selectedTabIndex };
};

export const handleFindFlights = ({
	flightQuery,
	dispatch,
	chatSessionId,
	location,
	dayPlansQuery,
	tripData = {},
	sortOption = SortOptions.BEST,
	gMapsData = {},
	curActiveTabIndex = -1,
	existingFlightResults = [],
}) => {
	try {
		handleFindFlightsImp({
			flightQuery,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery,
			shouldUpdateFlightsAndDayPlans: true,
			tripData,
			sortOption,
			gMapsData,
			curActiveTabIndex,
			queryFunction: flightQueryForSingleDateTab,
			existingFlightResults,
		});
	} catch (error) {
		console.log('error', error);
	}
};

/* eslint-disable default-param-last */
const initialState = {
	maxPrice: 0,
	transitionReqs: [],
	travelSets: [],
	tripType: 'MULTI_CITY',
	tripClass: 'ECONOMY',
};

export default function transitionsReducer(state = initialState, action) {
	switch (action.type) {
		case 'RESET_STATE':
			return { ...initialState };
		case 'UPDATE_TRANSITIONS_REDUCER':
			return { ...state, ...action.payload };
		case 'MODIFY_TRIP_CLASS':
			return { ...state, tripClass: action.payload };
		case 'MODIFY_TRAVEL_SETS':
			return { ...state, travelSets: action.payload };
		default:
			return state;
	}
}

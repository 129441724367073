export const discoverCuratedData = {
	Europe: {
		'https://www.tiktok.com/@kelseyinlondon/video/7135853061704092933': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7135853061704092933.jpg',
					everything: {
						stats: {
							playCount: 6800000,
						},
					},
				},
				uuid: '1d9e5a38-9a8c-45c8-be2c-0604f2540c19',
			},
		},
		'https://www.tiktok.com/@volga_londonstories/video/7250573241255857434': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7250573241255857434.jpg',
					everything: {
						stats: {
							playCount: 5600000,
						},
					},
				},
				uuid: '1eaf160e-05b0-446a-a339-ab10246c46a6',
			},
		},
		'https://www.tiktok.com/@travelllbae/video/7214583091682102534': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7214583091682102534.jpg',
					everything: {
						stats: {
							playCount: 5700000,
						},
					},
				},
				uuid: '3320bd49-9a54-4d0e-ad3f-9370c9bb3c0d',
			},
		},
		'https://www.tiktok.com/@dashakofman/video/7192235623346900266': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7192235623346900266.jpg',
					everything: {
						stats: {
							playCount: 4300000,
						},
					},
				},
				uuid: '5c6cdde8-7152-40f7-bfd2-6f6937a0dfaf',
			},
		},
		'https://www.tiktok.com/@ashleycrumtravel/video/7233874619344669979': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7233874619344669979.jpg',
					everything: {
						stats: {
							playCount: 3500000,
						},
					},
				},
				uuid: '4b08bfae-60ae-4318-843e-c85224d8c781',
			},
		},
		'https://www.tiktok.com/@outannabout/video/7234950277239983387': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7234950277239983387.jpg',
					everything: {
						stats: {
							playCount: 3000000,
						},
					},
				},
				uuid: 'a03ae3c2-18a6-4fac-91a5-092fe45858d9',
			},
		},
		'https://www.tiktok.com/@svetlova_gatavo/video/7325022766221118752': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7325022766221118752.jpg',
					everything: {
						stats: {
							playCount: 2800000,
						},
					},
				},
				uuid: '5b97b3c0-89cf-442b-8a2b-5ed39f6fb576',
			},
		},
		'https://www.tiktok.com/@jyptravels/video/7256081141411695899': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7256081141411695899.jpg',
					everything: {
						stats: {
							playCount: 2700000,
						},
					},
				},
				uuid: 'c6304ea9-ba0c-4311-959d-fd8a193c7b4b',
			},
		},
		'https://www.tiktok.com/@ameliastraveldiaries/video/7200696133524393222': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7200696133524393222.jpg',
					everything: {
						stats: {
							playCount: 2100000,
						},
					},
				},
				uuid: '28359219-88a2-4603-a5ca-e34bb7d0ac2f',
			},
		},
		'https://www.tiktok.com/@milesjones/video/7271024356304637226': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7271024356304637226.jpg',
					everything: {
						stats: {
							playCount: 2100000,
						},
					},
				},
				uuid: '1687a6ca-ba65-4d0d-93e7-7e7f9d229907',
			},
		},
		'https://www.tiktok.com/@haylsa/video/7152802660847078662': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7152802660847078662.jpg',
					everything: {
						stats: {
							playCount: 2000000,
						},
					},
				},
				uuid: '36a80a86-2a72-457f-b06d-eae9aa47a85b',
			},
		},
		'https://www.tiktok.com/@amyenvoyage/video/7139202153167523078': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7139202153167523078.jpg',
					everything: {
						stats: {
							playCount: 1700000,
						},
					},
				},
				uuid: 'db4afb3e-ac84-4b08-be44-de540a6b7a6c',
			},
		},
		'https://www.tiktok.com/@thetarynhart/video/7272463736932142379': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7272463736932142379.jpg',
					everything: {
						stats: {
							playCount: 1600000,
						},
					},
				},
				uuid: 'c6302f48-5182-4c10-a2aa-ee9fd92834f8',
			},
		},
		'https://www.tiktok.com/@morganlyates/video/7227546838205762858': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7227546838205762858.jpg',
					everything: {
						stats: {
							playCount: 1400000,
						},
					},
				},
				uuid: 'ee6503c1-288d-4af6-894e-6c18b61a6369',
			},
		},
		'https://www.tiktok.com/@butlarii/video/7207549127238978821': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7207549127238978821.jpg',
					everything: {
						stats: {
							playCount: 1400000,
						},
					},
				},
				uuid: 'b41daedb-457c-4669-b011-8f801330066c',
			},
		},
		'https://www.tiktok.com/@zoe_laix/video/7333150071925558530': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7333150071925558530.jpg',
					everything: {
						stats: {
							playCount: 1300000,
						},
					},
				},
				uuid: '93b6a7e8-8ebc-4902-bef7-2d1d4058189a',
			},
		},
		'https://www.tiktok.com/@trip.com/video/7229363734953708801': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7229363734953708801.jpg',
					everything: {
						stats: {
							playCount: 1300000,
						},
					},
				},
				uuid: '9b3c6d21-c31a-410c-8f8d-ed9dceb1a732',
			},
		},
		'https://www.tiktok.com/@london.explores/video/7280996697331862789': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7280996697331862789.jpg',
					everything: {
						stats: {
							playCount: 1300000,
						},
					},
				},
				uuid: 'ce3f6a07-41f9-4b9b-b694-d0e0de54ddde',
			},
		},
		'https://www.tiktok.com/@nikkionherway/video/7144424703854251306': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7144424703854251306.jpg',
					everything: {
						stats: {
							playCount: 1300000,
						},
					},
				},
				uuid: '37bdb24a-4e18-4761-b216-50f1923d9e96',
			},
		},
		'https://www.tiktok.com/@autumn_gruber/video/7219400694107753770': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7219400694107753770.jpg',
					everything: {
						stats: {
							playCount: 1200000,
						},
					},
				},
				uuid: 'd5aa0e58-caec-4545-91db-11a0a9fa78a5',
			},
		},
		'https://www.tiktok.com/@kickbackwithkita/video/7191859933577530666': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7191859933577530666.jpg',
					everything: {
						stats: {
							playCount: 1100000,
						},
					},
				},
				uuid: '64da742c-7600-443f-846c-d769bf7554b5',
			},
		},
		'https://www.tiktok.com/@muogramm/video/7083069294258539781': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7083069294258539781.jpg',
					everything: {
						stats: {
							playCount: 1100000,
						},
					},
				},
				uuid: 'e76e6828-3393-4386-824a-53a7de9fe95b',
			},
		},
		'https://www.tiktok.com/@stippl.io/video/7215606307859057926': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7215606307859057926.jpg',
					everything: {
						stats: {
							playCount: 1100000,
						},
					},
				},
				uuid: '6acc1b51-91fa-48f6-bb21-da5a4dd83e42',
			},
		},
	},
	Asia: {
		'https://www.tiktok.com/@lukemcameron/video/7327921790594944263': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7327921790594944263.jpg',
					everything: {
						stats: {
							playCount: 8100000,
						},
					},
				},
				uuid: 'be7aa550-99b8-438f-ba09-8acee0fbce34',
			},
		},
		'https://www.tiktok.com/@twosometravellers/video/7296783483811302674': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7296783483811302674.jpg',
					everything: {
						stats: {
							playCount: 4100000,
						},
					},
				},
				uuid: '09d41b3d-c4e6-4c93-ad6a-1adc08c593b7',
			},
		},
		'https://www.tiktok.com/@lucyjessicaa/video/7270852129034751265': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7270852129034751265.jpg',
					everything: {
						stats: {
							playCount: 3100000,
						},
					},
				},
				uuid: 'a9453ba9-125e-488e-b682-a34a09ef8c16',
			},
		},
		'https://www.tiktok.com/@montanadarby/video/7170355954280680710': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7170355954280680710.jpg',
					everything: {
						stats: {
							playCount: 2800000,
						},
					},
				},
				uuid: 'ab9acdfe-6765-4985-bcc2-e57b2b07f3ec',
			},
		},
		'https://www.tiktok.com/@travel.expectations/video/7295091402189360426': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7295091402189360426.jpg',
					everything: {
						stats: {
							playCount: 2500000,
						},
					},
				},
				uuid: 'ab6a798d-e83b-47d8-bacc-1bc25d54d318',
			},
		},
		'https://www.tiktok.com/@megtomos/video/7207301778658970885': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7207301778658970885.jpg',
					everything: {
						stats: {
							playCount: 1500000,
						},
					},
				},
				uuid: '2fb08efe-ebbc-4c3b-906c-fc2e2ca7120e',
			},
		},
		'https://www.tiktok.com/@nickybuhr/video/7319086223585250562': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7319086223585250562.jpg',
					everything: {
						stats: {
							playCount: 1200000,
						},
					},
				},
				uuid: '97724ad8-078c-4403-a772-f80f9d872fc3',
			},
		},
		'https://www.tiktok.com/@biancainmelbourne/video/7280156463845133569': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7280156463845133569.jpg',
					everything: {
						stats: {
							playCount: 1100000,
						},
					},
				},
				uuid: 'ccc8f964-d5f7-4a12-a6a9-ac02c4600018',
			},
		},
		'https://www.tiktok.com/@stippl.io/video/7236450631936920859': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7236450631936920859.jpg',
					everything: {
						stats: {
							playCount: 933400,
						},
					},
				},
				uuid: '98ec30c9-78d6-43de-bc06-24337d2e644b',
			},
		},
		'https://www.tiktok.com/@maddiefreemann/video/7269686870861204769': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7269686870861204769.jpg',
					everything: {
						stats: {
							playCount: 821300,
						},
					},
				},
				uuid: '76d8605d-b89f-4a60-bf09-c5acef5d0a63',
			},
		},
		'https://www.tiktok.com/@brandneweats/video/7234139602808737026': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7234139602808737026.jpg',
					everything: {
						stats: {
							playCount: 793000,
						},
					},
				},
				uuid: '5b0c7e0c-050e-4e78-9b8c-937cba2af36a',
			},
		},
		'https://www.tiktok.com/@taking.you.places/video/7303675001679531297': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7303675001679531297.jpg',
					everything: {
						stats: {
							playCount: 783500,
						},
					},
				},
				uuid: '07fef5ce-0493-416b-8dba-d28356fa4f9b',
			},
		},
		'https://www.tiktok.com/@jeaniemarie.wild/video/7257144861600615726': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7257144861600615726.jpg',
					everything: {
						stats: {
							playCount: 774000,
						},
					},
				},
				uuid: '8af7056c-6b02-4d4a-b45b-a788bcbb67e7',
			},
		},
		'https://www.tiktok.com/@dfajourn/video/7229689448621952282': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7229689448621952282.jpg',
					everything: {
						stats: {
							playCount: 773100,
						},
					},
				},
				uuid: '78a5c937-5281-46e7-a894-f20718828580',
			},
		},
		'https://www.tiktok.com/@erinnstravels/video/7227920387345091867': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7227920387345091867.jpg',
					everything: {
						stats: {
							playCount: 706500,
						},
					},
				},
				uuid: 'b1a97ef7-354d-47e3-a2f9-e23d4f76e09b',
			},
		},
		'https://www.tiktok.com/@cyncynti/video/7280025502964813057': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7280025502964813057.jpg',
					everything: {
						stats: {
							playCount: 620100,
						},
					},
				},
				uuid: '0fd7c277-90ab-41c9-9699-9c08cd7dc2fd',
			},
		},
		'https://www.tiktok.com/@maya_b__/video/7188731283453824261': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7188731283453824261.jpg',
					everything: {
						stats: {
							playCount: 492900,
						},
					},
				},
				uuid: 'fa428706-d9b7-4aa7-9b28-8871d6139dff',
			},
		},
		'https://www.tiktok.com/@two.peas.in.a.passport/video/7280821708561452334':
			{
				payload: {
					scrape_results: {
						azure_display_url:
							'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7280821708561452334.jpg',
						everything: {
							stats: {
								playCount: 487900,
							},
						},
					},
					uuid: 'cb9fa7bf-94fe-49e5-abd9-d500ca5d9885',
				},
			},
		'https://www.tiktok.com/@adventure/video/7290320781672779010': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7290320781672779010.jpg',
					everything: {
						stats: {
							playCount: 367700,
						},
					},
				},
				uuid: '9385865e-4205-404c-ae45-37c684ca897e',
			},
		},
		'https://www.tiktok.com/@cyncynti/video/7250111134810918146': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7250111134810918146.jpg',
					everything: {
						stats: {
							playCount: 351800,
						},
					},
				},
				uuid: '3754d831-f1e5-4cdc-98c4-553e824f7e2e',
			},
		},
		'https://www.tiktok.com/@lis.ell/video/7337349589520944417': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7337349589520944417.jpg',
					everything: {
						stats: {
							playCount: 343400,
						},
					},
				},
				uuid: '0586c494-35f3-4942-a043-719aa36cccc9',
			},
		},
		'https://www.tiktok.com/@trippingmillennial/video/7194849267461426478': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7194849267461426478.jpg',
					everything: {
						stats: {
							playCount: 340100,
						},
					},
				},
				uuid: '506e3013-f2b6-4627-8526-2bbf387db720',
			},
		},
		'https://www.tiktok.com/@taiwanvisuals/video/7285595343578811654': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7285595343578811654.jpg',
					everything: {
						stats: {
							playCount: 334500,
						},
					},
				},
				uuid: 'c941ed08-8a8f-4b12-a3fa-4f9226cb1ac0',
			},
		},
		'https://www.tiktok.com/@femidaallarakah/video/7328811594727558433': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7328811594727558433.jpg',
					everything: {
						stats: {
							playCount: 330500,
						},
					},
				},
				uuid: '5bd8206e-f147-41f7-969a-7b7594c9a436',
			},
		},
		'https://www.tiktok.com/@iwantalmondss/video/7294151984339356930': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7294151984339356930.jpg',
					everything: {
						stats: {
							playCount: 327800,
						},
					},
				},
				uuid: '4b19d56a-a73d-4147-882a-82aa0b88058f',
			},
		},
	},
	Africa: {
		'https://www.tiktok.com/@saltyluxe_/video/7322914359166946562': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7322914359166946562.jpg',
					everything: {
						stats: {
							playCount: 2700000,
						},
					},
				},
				uuid: '559ee388-6ec9-43a0-b031-9af034cbc057',
			},
		},
		'https://www.tiktok.com/@majth2/video/7294314850497432875': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7294314850497432875.jpg',
					everything: {
						stats: {
							playCount: 2400000,
						},
					},
				},
				uuid: 'bd64d46d-a54a-4121-8dd4-a738d6316cdd',
			},
		},
		'https://www.tiktok.com/@travelguyss/video/7226429158388223259': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7226429158388223259.jpg',
					everything: {
						stats: {
							playCount: 2300000,
						},
					},
				},
				uuid: '059c6754-2bfa-4d0c-9f9d-c90d817ae4a0',
			},
		},
		'https://www.tiktok.com/@i.wonderalot/video/7215367616431410475': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7215367616431410475.jpg',
					everything: {
						stats: {
							playCount: 2100000,
						},
					},
				},
				uuid: '00bba4d8-84c6-4897-b4fd-07c2da2c5869',
			},
		},
		'https://www.tiktok.com/@campsbaygirl/video/7365885542199758088': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7365885542199758088.jpg',
					everything: {
						stats: {
							playCount: 1300000,
						},
					},
				},
				uuid: '6ba44d59-6754-4da6-9355-b88453b665af',
			},
		},
		'https://www.tiktok.com/@black_nomads/video/7205896377262624046': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7205896377262624046.jpg',
					everything: {
						stats: {
							playCount: 1200000,
						},
					},
				},
				uuid: '5d61db29-72f6-467a-9ab6-9938e7c1fc73',
			},
		},
		'https://www.tiktok.com/@meghanorourkee/video/7239779492640738606': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7239779492640738606.jpg',
					everything: {
						stats: {
							playCount: 1100000,
						},
					},
				},
				uuid: 'a0a691b5-c16e-4e7a-bfe7-d1d998e7f69d',
			},
		},
		'https://www.tiktok.com/@nicolemsunderland/video/7250472110005472554': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7250472110005472554.jpg',
					everything: {
						stats: {
							playCount: 1000000,
						},
					},
				},
				uuid: '4ffae2f0-0f54-47a5-9b9c-eb69e4a1694f',
			},
		},
		'https://www.tiktok.com/@prince_edja/video/7212368427703422214': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7212368427703422214.jpg',
					everything: {
						stats: {
							playCount: 851300,
						},
					},
				},
				uuid: '8e7638f5-3129-4dcf-b14d-bd71e8bc37b1',
			},
		},
		'https://www.tiktok.com/@oneworld2023/video/7132091338983509254': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7132091338983509254.jpg',
					everything: {
						stats: {
							playCount: 765400,
						},
					},
				},
				uuid: '5259f600-f9ee-46ff-9f3d-4eed91140693',
			},
		},
		'https://www.tiktok.com/@sibahlemaunye/video/7311227081709456645': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7311227081709456645.jpg',
					everything: {
						stats: {
							playCount: 593000,
						},
					},
				},
				uuid: '774f18fb-3648-4100-87f8-cf7c1de12f7c',
			},
		},
		'https://www.tiktok.com/@s0lidaddy/video/7324439268510747910': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7324439268510747910.jpg',
					everything: {
						stats: {
							playCount: 521700,
						},
					},
				},
				uuid: 'e38b5ef7-d064-42e6-8e86-c7722c7c1487',
			},
		},
		'https://www.tiktok.com/@lilmsawkward/video/7154015995055721771': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7154015995055721771.jpg',
					everything: {
						stats: {
							playCount: 463900,
						},
					},
				},
				uuid: 'f32dc86a-67f2-4cc8-be2b-574c3ba7af43',
			},
		},
		'https://www.tiktok.com/@ymscoo/video/7217117694103948587': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7217117694103948587.jpg',
					everything: {
						stats: {
							playCount: 394300,
						},
					},
				},
				uuid: '3ab046f0-af25-4c27-9e6a-6478919e3f8d',
			},
		},
		'https://www.tiktok.com/@countingcountries/video/7342150147096300843': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7342150147096300843.jpg',
					everything: {
						stats: {
							playCount: 375000,
						},
					},
				},
				uuid: '6c6135cf-b808-4dc7-992f-c599b265c37b',
			},
		},
		'https://www.tiktok.com/@sedesworld/video/7275295811712388394': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7275295811712388394.jpg',
					everything: {
						stats: {
							playCount: 368500,
						},
					},
				},
				uuid: 'ab98956f-925e-44e5-b774-512a80aeffc0',
			},
		},
		'https://www.tiktok.com/@jess.shl/video/7119959213635423493': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7119959213635423493.jpg',
					everything: {
						stats: {
							playCount: 309100,
						},
					},
				},
				uuid: '6e2c9e0f-d842-47ee-b441-7a21b98c8458',
			},
		},
		'https://www.tiktok.com/@thestuzio/video/7308398644191972613': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7308398644191972613.jpg',
					everything: {
						stats: {
							playCount: 290000,
						},
					},
				},
				uuid: 'faf87845-17af-4e6c-a348-54190c33a520',
			},
		},
		'https://www.tiktok.com/@globalexporer7799/video/7308327357931212034': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7308327357931212034.jpg',
					everything: {
						stats: {
							playCount: 263200,
						},
					},
				},
				uuid: 'ca734660-f32a-4472-aa46-014af6c71e22',
			},
		},
		'https://www.tiktok.com/@top_10_explorer/video/7300830714138725639': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7300830714138725639.jpg',
					everything: {
						stats: {
							playCount: 240100,
						},
					},
				},
				uuid: '7dee0117-ec44-47a0-9c2e-76fccdf3de46',
			},
		},
		'https://www.tiktok.com/@claudeslife/video/7198252893131001093': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7198252893131001093.jpg',
					everything: {
						stats: {
							playCount: 210000,
						},
					},
				},
				uuid: '9873d0b4-a2ea-465c-b329-2c5282317e33',
			},
		},
		'https://www.tiktok.com/@missyounginternational/video/7200757247650172203':
			{
				payload: {
					scrape_results: {
						azure_display_url:
							'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7200757247650172203.jpg',
						everything: {
							stats: {
								playCount: 188400,
							},
						},
					},
					uuid: '9ecf217a-40c0-4d86-8ee8-70781dc19d6b',
				},
			},
		'https://www.tiktok.com/@saltyluxe_/video/7307715169906412801': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7307715169906412801.jpg',
					everything: {
						stats: {
							playCount: 181600,
						},
					},
				},
				uuid: 'f9233e4f-b4a4-4529-a714-2080fc976833',
			},
		},
		'https://www.tiktok.com/@angelawchan/video/7250151154406133038': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7250151154406133038.jpg',
					everything: {
						stats: {
							playCount: 165600,
						},
					},
				},
				uuid: '1faab51c-1408-4ae1-b7f2-f6e8ff16af7c',
			},
		},
		'https://www.tiktok.com/@aureliestoryy/video/7286772393798896928': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7286772393798896928.jpg',
					everything: {
						stats: {
							playCount: 160900,
						},
					},
				},
				uuid: '8f4b5b5e-61d4-4710-98a8-27987a15cd99',
			},
		},
	},
	'South America': {
		'https://www.tiktok.com/@riana.metzger/video/7174910626723007786': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7174910626723007786.jpg',
					everything: {
						stats: {
							playCount: 747300,
						},
					},
				},
				uuid: '002ad42f-ebd3-4534-9149-20408e41c7d8',
			},
		},
		'https://www.tiktok.com/@baileyturvey/video/7264237792287313184': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7264237792287313184.jpg',
					everything: {
						stats: {
							playCount: 578300,
						},
					},
				},
				uuid: 'e7bb5572-354f-4962-bea4-c03c6958ccbb',
			},
		},
		'https://www.tiktok.com/@chrissiecough/video/7248651617455705371': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7248651617455705371.jpg',
					everything: {
						stats: {
							playCount: 418600,
						},
					},
				},
				uuid: '6365e402-2bf8-458c-9e4b-43add313ef0f',
			},
		},
		'https://www.tiktok.com/@a.o_adventures/video/7243523141681548571': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7243523141681548571.jpg',
					everything: {
						stats: {
							playCount: 405300,
						},
					},
				},
				uuid: '6d660f6b-dd08-4e06-a6f3-d2694dddd80d',
			},
		},
		'https://www.tiktok.com/@piorotravel/video/7318460109284232480': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7318460109284232480.jpg',
					everything: {
						stats: {
							playCount: 344200,
						},
					},
				},
				uuid: 'c884ff7c-dd0d-4384-aa47-6fcc10c6b7ce',
			},
		},
		'https://www.tiktok.com/@brookepereiraa/video/7319624772860628231': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7319624772860628231.jpg',
					everything: {
						stats: {
							playCount: 250400,
						},
					},
				},
				uuid: '35076afb-9412-4065-bf65-e9c8393de365',
			},
		},
		'https://www.tiktok.com/@meredithonthemap/video/7292393402145082670': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7292393402145082670.jpg',
					everything: {
						stats: {
							playCount: 229800,
						},
					},
				},
				uuid: 'eae363c8-83d9-4cf4-b522-ad7b0ff2d48e',
			},
		},
		'https://www.tiktok.com/@macs_explore/video/7107723964977335598': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7107723964977335598.jpg',
					everything: {
						stats: {
							playCount: 217500,
						},
					},
				},
				uuid: '4eefafab-d7f2-467e-b83a-2233bc111d93',
			},
		},
		'https://www.tiktok.com/@visit/video/7177495361815203078': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7177495361815203078.jpg',
					everything: {
						stats: {
							playCount: 204300,
						},
					},
				},
				uuid: '6afe180c-78a7-422b-a3eb-75862775523d',
			},
		},
		'https://www.tiktok.com/@livandlew_/video/7290438316862737697': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7290438316862737697.jpg',
					everything: {
						stats: {
							playCount: 191800,
						},
					},
				},
				uuid: '3b4968b9-57c0-4f5a-afbd-3f2245a5ae3d',
			},
		},
		'https://www.tiktok.com/@wise_strategies/video/7301329987494333727': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7301329987494333727.jpg',
					everything: {
						stats: {
							playCount: 186000,
						},
					},
				},
				uuid: '6153c23a-8a39-4970-9de8-339ee01583c8',
			},
		},
		'https://www.tiktok.com/@travelwithtolu/video/7166515488049188102': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7166515488049188102.jpg',
					everything: {
						stats: {
							playCount: 184500,
						},
					},
				},
				uuid: '558da681-5e38-41a6-aa56-ddcb0a5bb7dd',
			},
		},
		'https://www.tiktok.com/@cococamillee/video/7355634996901711146': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7355634996901711146.jpg',
					everything: {
						stats: {
							playCount: 164100,
						},
					},
				},
				uuid: '5fb2b834-6aec-4523-8a54-530f9baedce0',
			},
		},
		'https://www.tiktok.com/@fivefeet.triathlete/video/7330055758572227883': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7330055758572227883.jpg',
					everything: {
						stats: {
							playCount: 160400,
						},
					},
				},
				uuid: '71d58843-1c7f-4157-a7d5-1e8bc5dcbbcb',
			},
		},
		'https://www.tiktok.com/@thekemoymartin/video/7197906019077377323': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7197906019077377323.jpg',
					everything: {
						stats: {
							playCount: 147300,
						},
					},
				},
				uuid: '3e7a1136-00dd-4734-88cd-7fde7da39aeb',
			},
		},
		'https://www.tiktok.com/@travelllbae/video/7217853044682214683': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7217853044682214683.jpg',
					everything: {
						stats: {
							playCount: 144000,
						},
					},
				},
				uuid: '18700c20-5cf2-4a9b-b6ee-c0ac640fe8eb',
			},
		},
		'https://www.tiktok.com/@blacklatinhistory/video/7290195740800879915': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7290195740800879915.jpg',
					everything: {
						stats: {
							playCount: 143000,
						},
					},
				},
				uuid: 'c366753a-72db-44ed-a597-8fd420434ee3',
			},
		},
		'https://www.tiktok.com/@sandymakessense/video/7183026001415294214': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7183026001415294214.jpg',
					everything: {
						stats: {
							playCount: 138500,
						},
					},
				},
				uuid: 'e48a9e58-d9a0-4150-adca-d0493d602281',
			},
		},
		'https://www.tiktok.com/@jadenversluis/video/7238754558246161670': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7238754558246161670.jpg',
					everything: {
						stats: {
							playCount: 132400,
						},
					},
				},
				uuid: '25154502-3f11-40c7-a8f2-e6fa8314bc38',
			},
		},
		'https://www.tiktok.com/@thebougiebackpackerr/video/7228318593614646570': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7228318593614646570.jpg',
					everything: {
						stats: {
							playCount: 120700,
						},
					},
				},
				uuid: '68d18a3e-9f9b-44f6-af9b-3208703fe484',
			},
		},
		'https://www.tiktok.com/@hollylonsdale16/video/7278746426253839649': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7278746426253839649.jpg',
					everything: {
						stats: {
							playCount: 117200,
						},
					},
				},
				uuid: '468feaa3-6f0d-465d-b250-60de1221ddcb',
			},
		},
		'https://www.tiktok.com/@audreyfoster/video/7331368887306472747': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7331368887306472747.jpg',
					everything: {
						stats: {
							playCount: 114600,
						},
					},
				},
				uuid: '7f4e0849-cb07-4d1f-8c96-95f1d0e5236d',
			},
		},
		'https://www.tiktok.com/@baileyturvey/video/7179330627152497925': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7179330627152497925.jpg',
					everything: {
						stats: {
							playCount: 109900,
						},
					},
				},
				uuid: 'b1a8629e-c8e0-441b-9a0b-02227bffd435',
			},
		},
	},
	Caribbean: {
		'https://www.tiktok.com/@nikkiclaudio/video/7220210428574436654': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7220210428574436654.jpg',
					everything: {
						stats: {
							playCount: 1900000,
						},
					},
				},
				uuid: '0e24fd55-63db-4f06-bd8c-7588dd882a9f',
			},
		},
		'https://www.tiktok.com/@marieletanneur/video/7226409515313302811': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7226409515313302811.jpg',
					everything: {
						stats: {
							playCount: 1700000,
						},
					},
				},
				uuid: 'bb3cb8ca-f2a7-4453-9c70-d7af49051d97',
			},
		},
		'https://www.tiktok.com/@richardkrisak/video/7271267899782729002': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7271267899782729002.jpg',
					everything: {
						stats: {
							playCount: 1700000,
						},
					},
				},
				uuid: '4cc58737-5215-4aa0-b90f-d1dd3abbd833',
			},
		},
		'https://www.tiktok.com/@nicolemsunderland/video/7340119603563089195': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7340119603563089195.jpg',
					everything: {
						stats: {
							playCount: 1600000,
						},
					},
				},
				uuid: 'fa2ead97-69c3-4f30-a78a-159a3983bf75',
			},
		},
		'https://www.tiktok.com/@maldivosaur/video/7201553144827006214': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7201553144827006214.jpg',
					everything: {
						stats: {
							playCount: 938300,
						},
					},
				},
				uuid: '35e4ff59-c514-4077-8401-31e273c9da6a',
			},
		},
		'https://www.tiktok.com/@queenthaingz/video/7234694457193483563': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7234694457193483563.jpg',
					everything: {
						stats: {
							playCount: 889500,
						},
					},
				},
				uuid: '2650291d-26af-4ec2-b300-a7b937513125',
			},
		},
		'https://www.tiktok.com/@thetraveldoc1/video/7265310369483099435': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7265310369483099435.jpg',
					everything: {
						stats: {
							playCount: 862100,
						},
					},
				},
				uuid: '53fb91e6-8ec8-4a41-9cad-f331bc53f9b3',
			},
		},
		'https://www.tiktok.com/@shermanstravel/video/7326195337532575019': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7326195337532575019.jpg',
					everything: {
						stats: {
							playCount: 776400,
						},
					},
				},
				uuid: '79d4c652-4e94-4e28-b866-a7092c05a8a9',
			},
		},
		'https://www.tiktok.com/@kajainrgb/video/6997400455231868166': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/6997400455231868166.jpg',
					everything: {
						stats: {
							playCount: 765400,
						},
					},
				},
				uuid: '07c95a67-748d-4196-a84e-88d0ba36df50',
			},
		},
		'https://www.tiktok.com/@kxnglascogvogoodvibes/video/7222090541465472262': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7222090541465472262.jpg',
					everything: {
						stats: {
							playCount: 751100,
						},
					},
				},
				uuid: '7d6648ff-42fe-4fc5-b17d-2d72d801b458',
			},
		},
		'https://www.tiktok.com/@getready2travel/video/7076934214003805483': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7076934214003805483.jpg',
					everything: {
						stats: {
							playCount: 554100,
						},
					},
				},
				uuid: '45865c26-6e91-4d73-9229-a1356b33996b',
			},
		},
		'https://www.tiktok.com/@followourcompass/video/7217583248342256938': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7217583248342256938.jpg',
					everything: {
						stats: {
							playCount: 538900,
						},
					},
				},
				uuid: '078afd69-4acd-48f8-a51a-7a19b06c0c73',
			},
		},
		'https://www.tiktok.com/@its_lien_/video/7236813418106244379': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7236813418106244379.jpg',
					everything: {
						stats: {
							playCount: 521600,
						},
					},
				},
				uuid: '1931a4c9-4f69-4bfd-8982-0fe12fe270c0',
			},
		},
		'https://www.tiktok.com/@courtney_chaffin/video/7209541059859320107': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7209541059859320107.jpg',
					everything: {
						stats: {
							playCount: 495200,
						},
					},
				},
				uuid: 'e52dd5ac-dcaa-4cdc-b3b4-09569a1c5f3b',
			},
		},
		'https://www.tiktok.com/@itsmoodylove/video/7243821230464257323': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7243821230464257323.jpg',
					everything: {
						stats: {
							playCount: 485100,
						},
					},
				},
				uuid: '43ce4ab3-8022-4cae-8d3b-e781f6286c22',
			},
		},
		'https://www.tiktok.com/@samarajoly/video/7325793662179380526': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7325793662179380526.jpg',
					everything: {
						stats: {
							playCount: 478300,
						},
					},
				},
				uuid: '35465183-fa5a-46db-aeb9-04f5ac794985',
			},
		},
		'https://www.tiktok.com/@staycaribbean/video/7201975878266522886': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7201975878266522886.jpg',
					everything: {
						stats: {
							playCount: 453900,
						},
					},
				},
				uuid: '115424ca-63a0-47c8-a08c-9a35187f8e8a',
			},
		},
		'https://www.tiktok.com/@samarajoly/video/7228614738257677610': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7228614738257677610.jpg',
					everything: {
						stats: {
							playCount: 374600,
						},
					},
				},
				uuid: '7e77f677-d12d-4597-9b49-08636965b75d',
			},
		},
		'https://www.tiktok.com/@trendytraveler/video/7146381198019906859': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7146381198019906859.jpg',
					everything: {
						stats: {
							playCount: 360300,
						},
					},
				},
				uuid: '5d8dd01b-a660-4896-b8ec-fffb95a7922d',
			},
		},
		'https://www.tiktok.com/@trendytraveler/video/7309168031865916714': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7309168031865916714.jpg',
					everything: {
						stats: {
							playCount: 277200,
						},
					},
				},
				uuid: 'c396c694-f5c4-4d98-97c3-a8c445f451b4',
			},
		},
		'https://www.tiktok.com/@affordable.travel/video/7367116436994673963': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7367116436994673963.jpg',
					everything: {
						stats: {
							playCount: 267600,
						},
					},
				},
				uuid: '4408e61c-3003-44d2-a32e-6e310ebbd848',
			},
		},
		'https://www.tiktok.com/@itsprimaaaaa/video/7333766218605677866': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7333766218605677866.jpg',
					everything: {
						stats: {
							playCount: 257400,
						},
					},
				},
				uuid: 'ec3b03a4-3b14-4d5c-b33d-88184a4bc271',
			},
		},
		'https://www.tiktok.com/@jamaicapoppin/video/7319378711671901486': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7319378711671901486.jpg',
					everything: {
						stats: {
							playCount: 246100,
						},
					},
				},
				uuid: '95a44dfe-b8c7-44bc-8da4-85c5dfc5a348',
			},
		},
		'https://www.tiktok.com/@realtalk_adventures/video/7177729650615995690': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7177729650615995690.jpg',
					everything: {
						stats: {
							playCount: 238200,
						},
					},
				},
				uuid: 'c6a3add1-a31b-4dbd-ad43-f1e70931b637',
			},
		},
		'https://www.tiktok.com/@girlwithapassport/video/7261379277583699243': {
			payload: {
				scrape_results: {
					azure_display_url:
						'https://coinventmediastorage.blob.core.windows.net/media-storage-container/7261379277583699243.jpg',
					everything: {
						stats: {
							playCount: 237500,
						},
					},
				},
				uuid: 'e530ac7c-1710-438f-b951-706b75717de9',
			},
		},
	},
};

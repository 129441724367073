import airportsArray from './airports';

export const get_id = (...args) => {
	return `tooltip-${args.join('-')}`;
};

const airportsMap = airportsArray.reduce((acc, curr) => {
	acc[curr.value] = curr.name;
	return acc;
}, {});

export default airportsMap;

import { useDispatch, useSelector } from 'react-redux';
// import isEmpty from '@utils/isEmpty';
import { useNavigate } from 'react-router-dom';
import { Button, Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import InfoBannerContent from '@commons/InfoBannerContent';
import TooltipWrapper from '@helpers/TooltipWrapper';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import AiRialSmallIcon from '../icons/AiRialSmallIcon';
import MyTripsIcon from '../icons/MyTripsIcon';
// import MyCollectionIcon from '../icons/MyCollectionIcon';
import styles from './styles.module.css';
import ArrowRight from '../icons/ArrowRight';
import LeftArrow from '../icons/LeftArrow';
import PersonIcon from '../icons/PersonIcon';

function Navbar({ isNavbarExpanded = false, setIsNavbarExpanded = () => {} }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const pageId = useSelector((state) => state.ui_states.pageId);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const showMyTripsPopover = useSelector(
		(state) => state.ui_states.showMyTripsPopover,
	);

	const showUserEducationPopoverForNavbar =
		useSelector(
			(state) =>
				state.profile?.user_preferences?.show_user_education_popover
					?.show_my_trips_popover,
		) ?? true;

	const [anchorEl, setAnchorEl] = useState(null);
	// const { _initialized, ...profile } =
	// 	useSelector((state) => state.profile) || {};

	// const isLoginRequired = process.env.REACT_APP_LOGIN_REQUIRED === 'true';

	// const isProfilePresent = !!profile?.email;

	// // if (!isProfilePresent && isLoginRequired) {
	// // 	return null;
	// // }

	const openMyTrips = () => {
		sendMessageEvent({
			event: GoogleEventTypes.MY_TRIPS_CLICKED,
			chat_session_id: chatSessionId,
		});
		dispatch({
			type: 'UPDATE_MY_TRIPS_OPEN',
			payload: true,
		});
	};

	const NAVIGATIONS = [
		{
			label: 'My trips',
			value: 'my_trips',
			icon: MyTripsIcon,
			id: 'my-trips',
			onClickIcon: openMyTrips,
			toolTipContent: 'View your trips',
		},
		// {
		// 	label: 'My collection',
		// 	value: 'my_collection',
		// 	icon: MyCollectionIcon,
		// 	onClickIcon: () => {},
		// },
	];

	const handleClose = () => {
		setAnchorEl(null);

		dispatch({
			type: 'UPDATE_MY_TRIPS_POPOVER',
			payload: false,
		});
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		if (!showUserEducationPopoverForNavbar) {
			dispatch({
				type: 'UPDATE_MY_TRIPS_POPOVER',
				payload: false,
			});
		}

		if (!pageId || !showMyTripsPopover || !showUserEducationPopoverForNavbar) {
			return;
		}

		setTimeout(() => {
			const defaultAnchorEl = document.getElementById('my-trips');
			setAnchorEl(defaultAnchorEl);
		}, 2000);
	}, [pageId, showMyTripsPopover]);

	return (
		<div
			className={`${styles.container} ${isNavbarExpanded && styles.expanded}`}
			style={{
				color: '#36174D',
			}}
		>
			<nav>
				<div className={styles.bg_nav} />
				<div className={styles.inner_container}>
					<div className={styles.brand_logo}>
						<div role="presentation" className={styles.logo}>
							{!isNavbarExpanded ? (
								<div className={styles.iconContainer}>
									<div className={`${styles.icon} ${styles.iconNormal}`}>
										<AiRialSmallIcon />
									</div>

									<div
										role="presentation"
										className={`${styles.icon} ${styles.iconHover}`}
										onClick={() => setIsNavbarExpanded(true)}
									>
										<ArrowRight />
									</div>
								</div>
							) : (
								<div className="flex gap-1 items-center pl-3 pr-2">
									<div
										role="presentation"
										onClick={() => navigate('/')}
										style={{
											color: '#7750E5',
											fontWeight: '600',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											fontSize: '18px',
										}}
									>
										AiRial
									</div>
									<div
										role="presentation"
										onClick={() => navigate('/')}
										style={{
											color: '#7750E5',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											fontSize: '18px',
										}}
									>
										by CoInvent AI
									</div>

									<div
										style={{ marginLeft: 'auto' }}
										role="presentation"
										onClick={() => setIsNavbarExpanded(false)}
									>
										<LeftArrow />
									</div>
								</div>
							)}
						</div>
					</div>

					<div
						className="flex flex-col justify-between"
						style={{ height: 'calc(100% - 32px' }}
					>
						<div className="flex flex-col gap-6 items-start py-3 px-6">
							{NAVIGATIONS.map(
								({
									label,
									value,
									icon: ActiveIcon,
									onClickIcon,
									toolTipContent,
									...rest
								}) => {
									if (!isNavbarExpanded) {
										return (
											<TooltipWrapper
												tooltipContent={toolTipContent}
												key={value}
											>
												<div
													role="presentation"
													className={`cursor-pointer ${anchorEl && value === 'my_trips' && styles.highlight_icon}`}
													onClick={onClickIcon}
													{...rest}
												>
													<ActiveIcon key={value} />
												</div>
											</TooltipWrapper>
										);
									}

									return (
										<div
											role="presentation"
											className="flex gap-2 cursor-pointer"
											onClick={onClickIcon}
											key={value}
										>
											<ActiveIcon />
											<div
												className={styles.label}
												style={{
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													transition: 'clip-path 0.5s ease',
												}}
											>
												{label}
											</div>
										</div>
									);
								},
							)}

							{isNavbarExpanded && pageId ? (
								<Button
									variant="contained"
									type="button"
									className="gradient-button"
									style={{
										textTransform: 'unset',
										width: '100%',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
									}}
									onClick={() => {
										navigate('/');
										setIsNavbarExpanded(false);
									}}
								>
									New Trip
								</Button>
							) : null}
						</div>

						<div className="flex flex-col gap-10 cursor-pointer py-3 px-6">
							{!isNavbarExpanded ? (
								<div
									style={{ margin: 'auto' }}
									onClick={() => setIsNavbarExpanded(true)}
									role="presentation"
								>
									<ArrowRight />
								</div>
							) : (
								<div
									className="flex gap-2"
									onClick={() => setIsNavbarExpanded(false)}
									role="presentation"
								>
									<LeftArrow />
									<div className={styles.label}>Collapse</div>
								</div>
							)}

							{!isNavbarExpanded ? (
								<div style={{ margin: 'auto' }}>
									<PersonIcon />
								</div>
							) : (
								<div className="flex gap-2">
									<PersonIcon />
									<div className={styles.label}>My profile</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</nav>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						style: { marginLeft: 20 }, // Add the gap here
					},
				}}
			>
				<InfoBannerContent
					mainText="Every trip that you previously generated and every conversation that you had with AiRial is saved here."
					subText={null}
					title="All trips are saved here."
					handleClose={handleClose}
					popoverName="show_my_trips_popover"
				/>
			</Popover>
		</div>
	);
}

export default Navbar;

import React, { useState } from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { checkLink } from '@utils/LinkUtils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { maybePopulateMessage } from '@utils/DemoMessageHotkeyUtils';
import axios from 'axios';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useSelector } from 'react-redux';
import { InspirationLoadingDots } from './InspirationLoadingDots';
import DiscoverInspiration from './DiscoverInspiration';

export default function AddInspirationPage({
	onContentsSelected,
	onBack,
	addInspirationState = {},
	onAddInspirationStateChange = () => {},
	source,
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const [inputValue, setInputValue] = useState(
		addInspirationState.inputValue || '',
	);
	const [showToast, setShowToast] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [failedValidation, setFailedValidation] = useState(false);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
		onAddInspirationStateChange({
			...addInspirationState,
			inputValue: e.target.value,
		});
	};

	const handleButtonClick = async () => {
		const url = inputValue;
		const linkCheck = checkLink(url);

		if (!linkCheck.isValid) {
			setShowToast(true);
			return;
		}

		try {
			setIsLoading(true);
			setFailedValidation(false);
			sendMessageEvent({
				event: GoogleEventTypes.EXTERNAL_INSPIRATION_LINK_ADDED,
				chat_session_id: chatSessionId,
			});

			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/link_uuid`,
				{
					params: { link: cleanUrl(url) },
				},
			);
			const { data } = response;
			setIsLoading(false);

			const { payload: resultPayload = {}, status = '' } = data;

			if (status === 'success') {
				const { uuid = '' } = resultPayload;
				onContentsSelected([uuid]);
			} else {
				setShowToast(true);
				setFailedValidation(true);
			}
		} catch (error) {
			setIsLoading(false);
			setFailedValidation(true);
		}
	};

	function cleanUrl(url) {
		const parsedUrl = new URL(url);
		return parsedUrl.origin + parsedUrl.pathname;
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowToast(false);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			handleButtonClick();
		}
		maybePopulateMessage(event, setInputValue);
	};

	return (
		<div className="flex flex-col h-screen px-8 mb-4">
			<div className="flex flex-row justify-between py-6 border-b">
				<div className="flex gap-1 items-center">
					<ArrowBackIosIcon
						className="cursor-pointer"
						fontSize="medium"
						onClick={() => onBack()}
					/>
					<div className="text-2xl font-semibold">Add Inspiration</div>
				</div>
			</div>
			<div className="flex flex-col justify-center mt-4 space-y-4">
				<div className="text-l font-medium">Add External Inspiration</div>
				<div className="relative w-full flex flex-col space-y-2">
					<div className="text-neutrals-secondary_text">
						Add a link to your favorite Travel TikToks or Instagram reels and we
						will update your itinerary with activities from the content
					</div>
					<TextareaAutosize
						value={inputValue}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						minRows={1}
						maxRows={4}
						placeholder="Paste URL"
						className="border rounded-lg w-full py-2 px-10 resize-none box-border overflow-y-auto focus:outline-gray-300"
					/>
					<div className="absolute bottom-1 left-1 flex flex-row items-center">
						<IconButton size="small" disabled={inputValue === ''}>
							<InsertLinkIcon style={{ color: '#8b5cf6' }} />
						</IconButton>
					</div>
					<div className="absolute bottom-1 right-1 flex flex-row items-center">
						<IconButton
							size="small"
							onClick={handleButtonClick}
							disabled={inputValue === ''}
							style={{
								color: inputValue === '' ? '#b2b2b2' : '#8b5cf6', // Gray when disabled, purple otherwise
							}}
						>
							<SendIcon />
						</IconButton>
					</div>

					<Snackbar
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						open={showToast}
						autoHideDuration={6000}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							severity="error"
							variant="filled"
							sx={{ width: '100%' }}
						>
							The provided link is Invalid!
						</Alert>
					</Snackbar>
				</div>
				{isLoading && <InspirationLoadingDots />}
				{failedValidation && (
					<div className="font-medium text-red-500">
						Unable to proceed. Please try again
					</div>
				)}

				<div className="text-l font-medium">Or Discover...</div>
				<DiscoverInspiration
					onContinue={onContentsSelected}
					source={source}
					discoverInspirationState={
						addInspirationState.discoverInspirationState
					}
					onDiscoverInspirationStateChange={(newState) =>
						onAddInspirationStateChange({
							...addInspirationState,
							discoverInspirationState: newState,
						})
					}
				/>
			</div>
		</div>
	);
}

import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import startCase from '@utils/startCase';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { handleFindFlights } from '@helpers/FlightsQueryHelper';
import hotelDateSetHelper from '@helpers/hotelDateSetHelper';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import TravelDates from '@commons/TravelDates/TravelDates';
import {
	ChatMessageSender,
	LoadingState,
	TripUpdateType,
} from '@helpers/Enums';
import { formatDates } from '@helpers/DateUtils';
import { dispatchAddChatMessage } from '@helpers/ChatUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import isEmpty from '@utils/isEmpty';
import { fetchDayPlansAndDispatch } from '@helpers/dayPlansQueryHelper';
import TripDetailsWidget from './TripDetailsWidget';
import styles from './styles.module.css';
import RightSectionMainComponent from './RightSection/RightSectionMainComponent';

function TripOverview() {
	const dispatch = useDispatch();
	const location = useLocation();

	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const dayPlans = useSelector((state) => state.ui_states.day_plans) || [];
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const selectedFlightByDateOption = useSelector(
		(state) => state.ui_states.selectedFlightByDateOption,
	);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);

	const {
		travelSets = [],
		maxPrice,
		tripType,
		tripClass,
	} = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		hotelFilters = [],
		hotelReqs = [],
		numTravellers: initialNumTravellers,
	} = tripLocationDetails;

	const initialTripData = travelSets.map((indTravelData) => {
		return [
			{ location: startLocation, id: 'place', type: 'start' },
			...places
				.filter((place) => ![startLocation, endLocation].includes(place))
				.map((place) => ({ location: place, id: 'place', type: 'stop' })),
			{ location: endLocation, id: 'place', type: 'end' },
		].reduce((acc, cur, ind) => {
			const { type = '', location: loc = '' } = cur;

			if (!['start', 'end'].includes(type)) {
				const startDateDetails = indTravelData[ind - 1];
				const endDateDetails = indTravelData[ind];

				const startDate = dayjs(
					(startDateDetails.flightDetails || startDateDetails.nonFlightDetails)
						.dateOfJourney,
				);
				const endDate = dayjs(
					(endDateDetails?.flightDetails || endDateDetails.nonFlightDetails)
						.dateOfJourney,
				);

				const differenceInDays = endDate.diff(startDate, 'day');

				return [
					...acc,
					{
						...cur,
						noOfNights: differenceInDays,
						travelData: {
							type: endDateDetails.type,
							source: loc,
							destination:
								places.filter(
									(place) => ![startLocation, endLocation].includes(place),
								)[ind] || endLocation,
							flightDetails: ['flight', 'FLIGHT'].includes(endDateDetails.type)
								? endDateDetails.flightDetails
								: null,
							nonFlightDetails: !['flight', 'FLIGHT'].includes(
								endDateDetails.type,
							)
								? endDateDetails.nonFlightDetails
								: null,
						},
						hotelFilters: hotelReqs[ind - 1] ? hotelFilters[ind - 1] : null,
						hotelReq: hotelReqs[ind - 1],
					},
				];
			}

			return [
				...acc,
				{
					...cur,
					text: startCase(type),
					travelData:
						type === 'start'
							? {
									type: indTravelData[0].type,
									source: startLocation,
									destination: places.filter(
										(place) => ![startLocation, endLocation].includes(place),
									)[0],
									flightDetails: ['flight', 'FLIGHT'].includes(
										indTravelData[0].type,
									)
										? indTravelData[0].flightDetails
										: null,
									nonFlightDetails: !['flight', 'FLIGHT'].includes(
										indTravelData[0].type,
									)
										? indTravelData[0].nonFlightDetails
										: null,
								}
							: null,
					hotelFilters: null,
				},
			];
		}, []);
	});

	const [tripDetails, setTripDetails] = useState(initialTripData);
	const [localActiveTabIndex, setLocalActiveTabIndex] =
		useState(activeTabIndex);
	const [tripModificationDetails, setTripModificationDetails] = useState({
		modified: false,
		transportChanged: false,
		messages: [],
	});
	const [numTravellers, setNumTravellers] = useState(initialNumTravellers);

	const travelDates = tripDetails.map((travelSet) => {
		return travelSet.reduce(
			(acc, curr) => {
				if (!curr?.travelData) {
					return acc;
				}

				let dateOfJourney;
				if (
					['flight', 'FLIGHT'].includes(curr.travelData.type) &&
					curr.travelData.flightDetails
				) {
					dateOfJourney = curr.travelData.flightDetails.dateOfJourney;
				} else if (curr.travelData.nonFlightDetails) {
					dateOfJourney = curr.travelData.nonFlightDetails.dateOfJourney;
				}

				if (!dateOfJourney) return acc;

				if (!acc.startDate || dayjs(dateOfJourney).isBefore(acc.startDate)) {
					acc.startDate = dateOfJourney;
				}
				if (!acc.endDate || dayjs(dateOfJourney).isAfter(acc.endDate)) {
					acc.endDate = dateOfJourney;
				}
				return acc;
			},
			{ startDate: null, endDate: null },
		);
	});

	const travelDatesInString = useMemo(
		() =>
			travelDates.map(({ startDate, endDate }) =>
				formatDates(startDate, endDate, true),
			),
		[travelDates],
	);

	const handleCloseTripControls = () => {
		sendMessageEvent({
			event: GoogleEventTypes.CLOSE_TRIP_CONTROLS,
			chat_session_id: chatSessionId,
		});
		dispatch({
			type: 'UPDATE_SHOW_TRIP_CONTROLS',
			payload: false,
		});
	};

	const handleTabClick = (index) => {
		sendMessageEvent({
			event: GoogleEventTypes.TRIP_CONTROLS_CHANGE_DATE_OPTION,
			chat_session_id: chatSessionId,
		});
		setLocalActiveTabIndex(index);
	};

	const handleDiscardChanges = () => {
		setTripDetails(initialTripData);
		setTripModificationDetails({
			modified: false,
			transportChanged: false,
			messages: [],
		});
		setNumTravellers(initialNumTravellers);
		setLocalActiveTabIndex(activeTabIndex);
	};

	const handleGenerateTrip = () => {
		sendMessageEvent({
			event: GoogleEventTypes.TRIP_CONTROLS_GENERATE_TRIP_BUTTON,
			chat_session_id: chatSessionId,
		});

		try {
			const { transportChanged = false, messages } = tripModificationDetails;

			const tripDetailsOfDateSet = tripDetails[localActiveTabIndex];

			const hotelRequirement = tripDetailsOfDateSet.reduce((acc, cur) => {
				if (['start', 'end'].includes(cur?.type)) {
					return acc;
				}

				return [...acc, !!cur?.hotelFilters];
			}, []);

			const hotelFiltersFinal = tripDetailsOfDateSet.reduce((acc, cur) => {
				if (['start', 'end'].includes(cur?.type)) {
					return acc;
				}

				if (!cur?.hotelFilters) {
					return [...acc, null];
				}

				return [...acc, cur?.hotelFilters];
			}, []);

			const locations = tripDetailsOfDateSet.reduce((acc, cur) => {
				if (['start', 'end'].includes(cur?.type)) {
					return acc;
				}

				return [...acc, cur?.location];
			}, []);

			const updatedStartLocation = tripDetailsOfDateSet.find(
				({ type }) => type === 'start',
			).location;

			const updatedEndLocation = tripDetailsOfDateSet.find(
				({ type }) => type === 'end',
			).location;

			const filteredTripDetails = tripDetailsOfDateSet.filter(
				(detail) => !!detail.travelData,
			);

			const updatedTravelSets = tripDetails.map((tripDetailForDateOption) => {
				return tripDetailForDateOption.reduce((acc, cur) => {
					const { travelData = {} } = cur;

					if (!travelData) {
						return acc;
					}

					const { type = '', flightDetails, nonFlightDetails } = travelData;

					return [
						...acc,
						{
							type,
							flightDetails,
							nonFlightDetails,
						},
					];
				}, []);
			});

			const updatedFlightSets = tripDetails.reduce((acc, cur) => {
				return [
					...acc,
					cur.reduce((accumultaor, { travelData = {} }) => {
						if (!travelData || !travelData?.flightDetails) {
							return accumultaor;
						}

						const { flightDetails } = travelData;

						const {
							fromAirport,
							toAirport,
							dateOfJourney,
							maxStops,
							maxDuration,
						} = flightDetails;

						return [
							...accumultaor,
							{
								FROM_AIRPORT: fromAirport,
								TO_AIRPORT: toAirport,
								DATE_OF_JOURNEY: dateOfJourney,
								MAX_STOPS: maxStops,
								MAX_DURATION: maxDuration,
							},
						];
					}, []),
				];
			}, []);

			const transportRequirement = filteredTripDetails.map((detail) => {
				const { travelData = {} } = detail;

				const { type = '' } = travelData;

				return type.toLowerCase();
			});

			const dayPlansQuery = {
				locations: {
					startLocation,
					endLocation,
					places: locations,
				},
				chat_history: llmInteractionHistory,
			};

			dispatch({
				type: 'UPDATE_LOCATIONS_REDUCER',
				payload: {
					numTravellers,
				},
			});
			dispatch({
				type: 'UPDATE_ACTIVE_TAB_INDEX',
				payload: localActiveTabIndex,
			});

			const isFlightTransportationPresent =
				transportRequirement.includes('flight');

			if (transportChanged) {
				dispatch({
					type: 'UPDATE_LOCATIONS_REDUCER',
					payload: {
						endLocation: updatedEndLocation,
						startLocation: updatedStartLocation,
						places: locations,
					},
				});

				dispatch({
					type: 'UPDATE_TRANSITIONS_REDUCER',
					payload: {
						transitionReqs: transportRequirement,
						travelSets: updatedTravelSets,
					},
				});
			}

			if (transportChanged && isFlightTransportationPresent) {
				const flightQuery = {
					NUM_PASSENGERS: numTravellers,
					MAX_PRICE: maxPrice,
					FLIGHTSETS: updatedFlightSets,
					TRIP_TYPE: tripType,
					TRIP_CLASS: tripClass,
				};

				// Special case where we want all components to show loading state even though we start with Flights query
				dispatch({
					type: 'UPDATE_LOADING_STATE',
					payload: {
						flightsLoading: LoadingState.LOADING,
						hotelsLoading: LoadingState.LOADING,
						itineraryLoading: LoadingState.LOADING,
					},
				});

				handleFindFlights({
					flightQuery,
					dispatch,
					chatSessionId,
					location,
					dayPlansQuery,
					shouldUpdateFlightsAndDayPlans: true,
					tripData: {
						NUM_TRAVELLERS: numTravellers,
						hotelFilters: hotelFiltersFinal,
						travelSets: updatedTravelSets,
						LOCATIONS: locations,
						HOTEL_REQUIREMENT: hotelRequirement,
						hotelSortCriterion,
					},
					sortOption: flightSortOption,
					gMapsData,
					curActiveTabIndex: localActiveTabIndex,
				});
			} else {
				if (isEmpty(dayPlans[localActiveTabIndex])) {
					fetchDayPlansAndDispatch(
						dispatch,
						{
							...dayPlansQuery,
							travelSets: updatedTravelSets[localActiveTabIndex],
							flightSelections: (
								selectedFlightByDateOption[localActiveTabIndex]?.legs || []
							).map(
								({
									arrivalDate,
									arrivalTime,
									destination,
									source,
									departureTime,
									departureDate,
								}) => ({
									fromAirport: source,
									toAirport: destination,
									departureDate,
									departureTime,
									arrivalDate,
									arrivalTime,
								}),
							),
						},
						chatSessionId,
						localActiveTabIndex,
					);
				}

				const hotelDateSets = hotelDateSetHelper(
					locations,
					hotelRequirement,
					selectedFlightByDateOption[localActiveTabIndex]?.legs || [],
					updatedTravelSets[localActiveTabIndex],
				);

				handleFindHotels({
					dispatch,
					location,
					hotel_set_data: {
						hotel_datesets: [hotelDateSets],
						hotelFilters: hotelFiltersFinal,
						NUM_TRAVELLERS: numTravellers,
						HOTEL_REQUIREMENT: hotelRequirement,
						sortCriterion: hotelSortCriterion,
					},
					tabIndex: localActiveTabIndex,
					gMapsData,
					chatSessionId,
				});
			}

			const systemMessages = messages.map((message) => ({
				sender: ChatMessageSender.SYSTEM,
				message,
				status: 0,
			}));

			handleCloseTripControls();

			dispatchAddChatMessage(
				dispatch,
				TripUpdateType.EDIT_TRIP_USER_SUBMITTED,
				{
					messages: [systemMessages],
					isTyping: false,
				},
			);
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<div
			className="m-auto h-full"
			style={{ maxWidth: '888px', color: '#301345' }}
		>
			<TravelDates
				activeTabIndex={localActiveTabIndex}
				handleTabClick={handleTabClick}
				travelDatesInString={travelDatesInString}
			/>

			<div
				className="flex gap-4 justify-between"
				style={{
					padding: '24px',
					maxHeight: 'calc(100vh - 236px)',
					overflow: 'auto',
				}}
			>
				<div style={{ width: 'calc(50% - 16px)' }}>
					<TripDetailsWidget
						setTripDetails={setTripDetails}
						tripDetails={tripDetails}
						setTripModificationDetails={setTripModificationDetails}
						localActiveTabIndex={localActiveTabIndex}
						numTravellers={numTravellers}
					/>
				</div>

				<div style={{ width: 'calc(50% - 8px)' }}>
					<RightSectionMainComponent
						numTravellers={numTravellers}
						setNumTravellers={setNumTravellers}
						setTripModificationDetails={setTripModificationDetails}
					/>
				</div>
			</div>

			{tripModificationDetails?.modified && (
				<div
					style={{
						width: '100%',
						boxShadow: '0px -9px 34px 0px rgba(0, 0, 0, 0.08)',
						background: '#fff',
						zIndex: '9',
						borderRadius: '12px',
					}}
				>
					<div
						style={{
							width: '888px',
							margin: 'auto',
							padding: '16px',
						}}
						className="flex gap-4"
					>
						<div>
							You have made changes to your Trip. Click on the Search button to
							re-generate your trip.
						</div>

						<Button
							variant="outlined"
							type="button"
							className={styles.reset_trip_button}
							onClick={handleDiscardChanges}
							style={{
								textTransform: 'unset',
								whiteSpace: 'nowrap',
								width: '240px',
							}}
						>
							Discard Changes
						</Button>

						<Button
							variant="contained"
							type="button"
							className={styles.generate_trip_button}
							onClick={handleGenerateTrip}
							style={{
								textTransform: 'unset',
								whiteSpace: 'nowrap',
							}}
						>
							Search
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

export default TripOverview;

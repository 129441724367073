import { useDispatch, useSelector } from 'react-redux';
import CheckCircleselected from '@commons/icons/CheckCircleSelected';
import CheckCircleDefault from '@commons/icons/CheckCircleDefault';
import { useEffect, useRef, useState } from 'react';
import AiRialSmallIcon from '@commons/core/components/icons/AiRialSmallIcon';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import isEmpty from '@utils/isEmpty';
import ArrowRight from '@commons/icons/ArrowRight';
import { BsPlus } from 'react-icons/bs';
import { Popover, Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CustomDateRangePicker from '@commons/forms/page-components/components/DateRangePicker';
import dayjs from 'dayjs';
import { formatDate } from '@helpers/DateUtils';
import { useLocation } from 'react-router-dom';
import hotelDateSetHelper from '@helpers/hotelDateSetHelper';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { fetchDayPlansAndDispatch } from '@helpers/dayPlansQueryHelper';
import { handleFindFlights } from './helpers/handleFindFlights';
import styles from './styles.module.css';

function TravelDates({
	travelDatesInString = [],
	handleTabClick,
	activeTabIndex,
}) {
	const dispatch = useDispatch();
	const location = useLocation();

	const scrollDivRef = useRef(null);

	const bestTabIndex = useSelector((state) => state.ui_states.bestTabIndex);
	const isTripFullyLoaded =
		useSelector((state) => state.ui_states.isTripFullyLoaded) ?? true;
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const selectedFlightByDateOption = useSelector(
		(state) => state.ui_states.selectedFlightByDateOption,
	);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const isChatBoxClosed = useSelector(
		(state) => state.ui_states.isChatBoxClosed,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);
	const flightResults = useSelector((state) => state.ui_states.flight_results);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const tripLocations = useSelector((state) => state.tripLocationDetails);
	const tripTransitions = useSelector((state) => state.tripTransitions);
	const showTripControls = useSelector(
		(state) => state.ui_states.showTripControls,
	);
	const hotelResults = useSelector((state) => state.ui_states.hotel_results);
	const dayPlans = useSelector((state) => state.ui_states.day_plans);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);

	const { transitionReqs, travelSets, maxPrice, tripClass, tripType } =
		tripTransitions;

	const {
		hotelFilters,
		numTravellers,
		startLocation,
		endLocation,
		places,
		hotelReqs = [],
	} = tripLocations;

	const [isLoading, setIsLoading] = useState(!isTripFullyLoaded);
	const [isAddDatesLoading, setIsAddDatesLoading] = useState(false);
	const [isXDirectionOverflow, setIsXDirectionOverflow] = useState(false);
	const [isLeft, setIsLeft] = useState(true);
	const [isRight, setIsRight] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const [
		isChatBoxCloseAnimationCompleted,
		setIsChatBoxCloseAnimationCompleted,
	] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	});

	const handleDateChange = (range) => {
		setDateRange(range);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSubmit = async () => {
		const backendURL = process.env.REACT_APP_BACKEND_URL;

		setIsAddDatesLoading(true);

		const postFormData = new FormData();
		postFormData.append('trip_locations', JSON.stringify(tripLocations));
		postFormData.append('trip_transitions', JSON.stringify(tripTransitions));
		postFormData.append(
			'date_option_tba',
			JSON.stringify({
				START_DATE: dayjs(dateRange.startDate).format('YYYY-MM-DD'),
				END_DATE: dayjs(dateRange.endDate).format('YYYY-MM-DD'),
			}),
		);

		try {
			const res = await fetch(`${backendURL}/api/add_date_option`, {
				method: 'POST',
				body: postFormData,
				timeout: 10000,
			});

			const response = await res.json();

			const { DATETIME } = response;

			const newFlightSet = travelSets[0].reduce((acc, travelData, index) => {
				if (!travelData || !travelData?.flightDetails) {
					return acc;
				}

				const { flightDetails } = travelData;

				return [
					...acc,
					{
						DATE_OF_JOURNEY: formatDate(
							dayjs(DATETIME[0]?.[index]?.DATE).format('YYYY-MM-DD'),
						),
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}, []);

			const flightQuery = {
				NUM_PASSENGERS: numTravellers,
				MAX_PRICE: maxPrice,
				FLIGHTSETS: [newFlightSet],
				TRIP_TYPE: tripType,
				TRIP_CLASS: tripClass,
			};

			const FLIGHT_REQUIREMENT = transitionReqs.map((item) =>
				['flight', 'FLIGHT'].includes(item),
			);

			const isFlightTransportationPresent = transitionReqs.includes('flight');

			let newTravelSets;

			if (isFlightTransportationPresent) {
				newTravelSets = [newFlightSet].map((flightSet, mainIndex) => {
					let num = 0;

					return FLIGHT_REQUIREMENT.reduce((acc, current, index) => {
						if (current && flightSet[num]) {
							const flightDetails = {
								fromAirport: flightSet[num].FROM_AIRPORT,
								toAirport: flightSet[num].TO_AIRPORT,
								dateOfJourney: flightSet[num].DATE_OF_JOURNEY,
								maxStops: flightSet[num].MAX_STOPS,
								maxDuration: flightSet[num].MAX_DURATION,
							};
							if (flightSet[num]?.DEP_TIME_RANGE_START) {
								flightDetails.depTimeRangeStart =
									flightSet[num].DEP_TIME_RANGE_START;
							}
							if (flightSet[num]?.DEP_TIME_RANGE_END) {
								flightDetails.depTimeRangeEnd =
									flightSet[num].DEP_TIME_RANGE_END;
							}
							if (flightSet[num]?.ARR_TIME_RANGE_START) {
								flightDetails.arrTimeRangeStart =
									flightSet[num].ARR_TIME_RANGE_START;
							}
							if (flightSet[num]?.ARR_TIME_RANGE_END) {
								flightDetails.arrTimeRangeEnd =
									flightSet[num].ARR_TIME_RANGE_END;
							}
							acc.push({
								type: 'FLIGHT',
								flightDetails,
								nonFlightDetails: null,
							});
							num += 1;
						} else {
							acc.push({
								type: (transitionReqs[index] || 'CAR').toUpperCase(),
								flightDetails: null,
								nonFlightDetails: {
									dateOfJourney: formatDate(DATETIME[mainIndex][index]?.DATE),
									timeOfDeparture: null,
									dateOfArrival: null,
									timeOfArrival: null,
								},
							});
						}
						return acc;
					}, []);
				});
			} else {
				newTravelSets = DATETIME.map((dateSet) => {
					return dateSet.map((date, index) => {
						return {
							type: (transitionReqs[index] || 'CAR').toUpperCase(),
							flightDetails: null,
							nonFlightDetails: {
								dateOfJourney: formatDate(date?.DATE),
								timeOfDeparture: null,
								dateOfArrival: null,
								timeOfArrival: null,
							},
						};
					});
				});
			}

			const combinedTravelSets = [...newTravelSets, ...travelSets];

			dispatch({
				type: 'MODIFY_TRAVEL_SETS',
				payload: combinedTravelSets,
			});

			dispatch({
				type: 'UPDATE_HOTEL_RESULTS',
				payload: [null, ...(hotelResults || [])],
			});
			dispatch({
				type: 'UPDATE_DAY_PLAN_RESULTS',
				payload: [null, ...(dayPlans || [])],
			});

			dispatch({
				type: 'UPDATE_FLIGHT_RESULTS',
				payload: !isEmpty(flightResults)
					? [null, ...(flightResults || [])]
					: Array(combinedTravelSets.length).fill(null),
			});

			dispatch({
				type: 'UPDATE_SELECTED_FLIGHT_BY_DATE_OPTION',
				payload: [
					null,
					...(selectedFlightByDateOption || [
						Array(combinedTravelSets.length - 1).fill(null),
					]),
				],
			});

			const dayPlansQuery = {
				locations: {
					startLocation,
					endLocation,
					places,
				},
				travelSets: combinedTravelSets,
				chat_history: llmInteractionHistory,
			};
			setIsAddDatesLoading(false);
			handleClose();

			if (isFlightTransportationPresent) {
				handleFindFlights({
					flightQuery,
					dispatch,
					chatSessionId,
					location,
					dayPlansQuery,
					existingFlightResults: flightResults,
					tripData: {
						NUM_TRAVELLERS: numTravellers,
						hotelFilters,
						travelSets: combinedTravelSets,
						LOCATIONS: places,
						HOTEL_REQUIREMENT: hotelReqs,
						hotelSortCriterion,
					},
					sortOption: flightSortOption,
					gMapsData,
					curActiveTabIndex: 0,
				});
			} else {
				dispatch({
					type: 'MODIFY_TRAVEL_SETS',
					payload: combinedTravelSets,
				});

				dispatch({
					type: 'UPDATE_ACTIVE_TAB_INDEX',
					payload: 0,
				});

				const hotelDateSets = hotelDateSetHelper(
					places,
					hotelReqs,
					[],
					combinedTravelSets[0],
				);

				handleFindHotels({
					dispatch,
					location,
					hotel_set_data: {
						hotel_datesets: [hotelDateSets],
						hotelFilters,
						NUM_TRAVELLERS: numTravellers,
						HOTEL_REQUIREMENT: hotelReqs,
						sortCriterion: hotelSortCriterion,
					},
					tabIndex: 0,
					gMapsData,
					chatSessionId,
				});

				fetchDayPlansAndDispatch(
					dispatch,
					{
						...dayPlansQuery,
						travelSets: combinedTravelSets[0],
						flightSelections: [],
					},
					chatSessionId,
					0,
				);
			}
		} catch (error) {
			setIsAddDatesLoading(false);
			console.error('Caught error in Topbar:', error);
		}
	};

	useEffect(() => {
		if (isTripFullyLoaded) {
			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
		} else {
			setIsLoading(true);
		}
	}, [isTripFullyLoaded]);

	useEffect(() => {
		if (isChatBoxClosed) {
			setTimeout(() => {
				setIsChatBoxCloseAnimationCompleted(true);
			}, 300);
		} else {
			setIsChatBoxCloseAnimationCompleted(false);
		}
	}, [isChatBoxClosed]);

	useEffect(() => {
		if (isEmpty(travelDatesInString)) {
			return;
		}
		const element = document.getElementById('dates-tab');
		const selectedDiv = document.getElementById('selected-div');

		if (selectedDiv) {
			selectedDiv.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		}

		if (element) {
			setIsXDirectionOverflow(element.scrollWidth > element.clientWidth);
		}
	}, [travelDatesInString]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollDiv = scrollDivRef.current;
			if (!scrollDiv) return;

			const isScrolledToEnd =
				scrollDiv.scrollLeft + scrollDiv.clientWidth >= scrollDiv.scrollWidth;
			setIsRight(isScrolledToEnd);
			setIsLeft(scrollDiv.scrollLeft === 0);
		};

		const scrollDiv = scrollDivRef.current;
		if (scrollDiv) {
			scrollDiv.addEventListener('scroll', handleScroll);
		}

		return () => {
			scrollDiv?.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (isLoading) {
		return (
			<div>
				<div
					className="flex items-center gap-4"
					style={{
						margin: '22px 22px 0',
						paddingBottom: '22px',
					}}
				>
					{[...Array(4)].map((number) => {
						return (
							<div key={number} className="flex items-center">
								<div className={styles.shimmer_container} />
							</div>
						);
					})}
				</div>

				<div
					className={`${styles.loadingText} ${!isTripFullyLoaded ? `${styles.fadeIn} ${styles.pulse}` : styles.fadeOut}`}
				>
					We are picking out every detail of a dream trip personalized for you.
					Your trip will be ready shortly, usually within a minute. This will be
					worth the wait! Thank you for your patience.
				</div>
			</div>
		);
	}

	const open = Boolean(anchorEl);

	return (
		<div className="flex gap-52 items-center pb-3 pt-4 px-6 overflow-x-hidden">
			<button
				id="flight_option_select"
				className={`${styles.select_button} ${isChatBoxClosed && isChatBoxCloseAnimationCompleted && !showTripControls ? styles.show : styles.remove}`}
				type="button"
				onClick={() => {
					sendMessageEvent({
						event: GoogleEventTypes.OPEN_CHAT_FEED,
						chat_session_id: chatSessionId,
					});
					dispatch({
						type: 'UPDATE_CHAT_BOX_STATUS',
						payload: false,
					});
				}}
			>
				<div className="flex items-center gap-2" style={{ margin: 'auto' }}>
					<AiRialSmallIcon width={20} />
					AiRial Chat
				</div>
			</button>

			<div className="flex items-center w-full">
				<div
					id="dates-tab"
					className="flex items-center gap-3 overflow-x-hidden py-2"
					style={{ maxWidth: 'calc(100% - 268px)' }}
					ref={scrollDivRef}
				>
					{travelDatesInString.map((dateRangeString, index) => {
						const isSelected = index === activeTabIndex;

						return (
							<div
								className="flex flex-col items-center relative"
								id={isSelected ? 'selected-div' : null}
							>
								<div
									className="py-2 px-3 font-medium cursor-pointer flex gap-2 items-center"
									style={{
										border: isSelected
											? '1px solid #7750E5'
											: '1px solid #B9B1C0',
										borderRadius: '32px',
										color: '#301345',
										background: isSelected
											? 'linear-gradient(0deg, #F0ECFC 0%, #F0ECFC 100%), #7750E5'
											: '#fff',
										fontSize: '13px',
									}}
									onClick={() => handleTabClick(index)}
									role="presentation"
								>
									{isSelected ? (
										<CheckCircleselected width="19.5" height="19.5" />
									) : (
										<CheckCircleDefault width="20.5" height="19.5" />
									)}

									<div className="text-center whitespace-nowrap min-w-[92px]">
										{dateRangeString}
									</div>
								</div>

								{index === bestTabIndex ? (
									<div
										className="text-xxs font-semibold absolute z-10 rounded-lg"
										style={{
											color: '#fff',
											marginTop: '-8px',
											top: 0,
											backgroundColor: '#7750E5',
											padding: '1px 6px',
										}}
									>
										BEST FLIGHT!
									</div>
								) : null}
							</div>
						);
					})}
				</div>

				<div
					className="flex items-center gap-3 cursor-pointer min-w-[73px] h-13 pl-4"
					style={{
						color: '#7750E5',
					}}
				>
					{isXDirectionOverflow ? (
						<button
							type="button"
							aria-label="Next slide"
							onClick={() => {
								const element = document.getElementById('dates-tab');

								element.scrollTo({
									left: element.scrollLeft - 158,
									behavior: 'smooth',
								});
							}}
							style={{
								transform: 'rotateY(180deg)',
								opacity: isLeft ? '0.5' : '1',
							}}
						>
							<ArrowRight />
						</button>
					) : null}
					{isXDirectionOverflow ? (
						<button
							type="button"
							aria-label="Prev slide"
							onClick={() => {
								const element = document.getElementById('dates-tab');

								element.scrollTo({
									left: element.scrollLeft + 158,
									behavior: 'smooth',
								});
							}}
							style={{
								opacity: isRight ? '0.5' : '1',
							}}
						>
							<ArrowRight />
						</button>
					) : null}

					<button
						type="button"
						style={{ borderLeft: '1px solid #E3DAFA' }}
						onClick={handlePopoverOpen}
						className="pl-2 flex whitespace-nowrap items-center py-1"
					>
						<BsPlus size="24" /> Add Custom Dates
					</button>
				</div>
			</div>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				slotProps={{
					paper: {
						style: { marginTop: 8 },
					},
				}}
			>
				<div className="flex flex-col gap-3 items-end">
					<CustomDateRangePicker onChange={handleDateChange} />

					<div className="flex gap-4 items-center p-4">
						<div
							className={styles.select_button}
							role="presentation"
							onClick={handleClose}
						>
							Cancel
						</div>

						<Button
							className="gradient-button"
							style={{ width: '150px' }}
							onClick={handleSubmit}
						>
							{isAddDatesLoading ? (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<CircularProgress size={20} color="inherit" />
									<span style={{ marginLeft: '8px' }}>Loading...</span>
								</Box>
							) : (
								<div>Submit</div>
							)}
						</Button>
					</div>
				</div>
			</Popover>
		</div>
	);
}

export default TravelDates;

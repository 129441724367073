function CheckCircleselected({ width, height }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '24'}
			height={height || '24'}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill="#7750E5"
			/>
			<path
				d="M16.125 9.75L10.6219 15L7.875 12.375"
				stroke="white"
				strokeWidth="1.63636"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default CheckCircleselected;

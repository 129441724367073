import { useSelector } from 'react-redux';

const useFlightsQuery = () => {
	const tripLocationDetails = useSelector(
		(state) => state.tripLocationDetails || {},
	);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);

	const { travelSets = [] } = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		numTravellers,
		hotelFilters = [],
		hotelReqs = [],
		hotelSortCriterion,
	} = tripLocationDetails;

	return {
		dayPlansQuery: {
			locations: {
				startLocation,
				endLocation,
				places,
			},
			chat_history: llmInteractionHistory,
		},
		tripData: {
			NUM_TRAVELLERS: numTravellers,
			hotelFilters: hotelFilters.filter((item) => item !== null),
			travelSets,
			LOCATIONS: places,
			HOTEL_REQUIREMENT: hotelReqs,
			hotelSortCriterion,
		},
	};
};

export default useFlightsQuery;

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
	ChatMessageSender,
	InteractionStage,
	MessageLabel,
	TripUpdateType,
} from '@helpers/Enums';
import { handleFetchPreLC } from '@helpers/ModelApiUtils';
import Button from '@mui/material/Button';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { maybePopulateMessage } from '@utils/DemoMessageHotkeyUtils';
import { dispatchUpdateChatMessages } from '@helpers/ChatUtils';
import OptionsComponent from './OptionsComponent';
import styles from './styles.module.css';

const OPEN_ANSWER_PLACEHOLDER = "I'd like to do a hike and a guided food tour.";

function QuestionWidget({ widgetData = {} }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);
	const questionWidgetState = useSelector(
		(state) => state.ui_states.questionWidget,
	);

	const { responses = [] } = questionWidgetState;

	const { CONTENT: content = [] } = widgetData || {};

	const [widgetQuestions, setWidgetQuestions] = useState(
		content.map((text, index) => {
			let question = '';
			let options = null;
			let answer = '';

			if (text.includes('::')) {
				const splits = text.split('::');
				question = splits[0].trim();
				options = splits.slice(1).map((option) => {
					if (option.trim().startsWith('*')) {
						const finalOption = option.trim().substring(1);
						answer = finalOption;
						return finalOption;
					}
					return option.trim();
				});

				if (responses[index]) {
					answer = responses[index];
				}
			} else {
				question = text.trim();
				answer = responses[index] || '';
			}

			return {
				options,
				answer,
				question,
			};
		}),
	);

	const onClickExpandToggle = () => {
		dispatch({
			type: 'UPDATE_QUESTION_WIDGET',
			payload: { expanded: !questionWidgetState.expanded },
		});
	};

	const onClickSubmit = async () => {
		const userAnswers = widgetQuestions.map(({ answer, question }) => {
			return answer.trim() === ''
				? `${question} :: Unspecified`
				: `${question} :: ${answer}`;
		});

		const label = MessageLabel.CLARIFICATION_WIDGET_USER;

		const updatedLlmHistory = [
			...llmInteractionHistory,
			{
				SENDER: 'User',
				LABEL: label,
				CONTENT: JSON.stringify(userAnswers, undefined, 2),
			},
		];

		const updatedChatMessages = chatMessages.messages.map((item, ind) => {
			if (ind === chatMessages.messages.length - 1) {
				return {
					...item,
					widget: { ...item?.widget, shouldShow: false },
				};
			}

			return item;
		});

		updatedChatMessages.push({
			sender: ChatMessageSender.USER,
			message: 'Trip Preferences Submitted!',
			widget: chatMessages.messages[chatMessages.messages.length - 1]?.widget,
		});

		dispatch({
			type: 'UPDATE_QUESTION_WIDGET',
			payload: { submitted: true, expanded: false },
		});

		dispatchUpdateChatMessages(
			dispatch,
			TripUpdateType.QUESTION_WIDGET_USER_SUBMITTED,
			{
				messages: updatedChatMessages,
				isTyping: true,
			},
		);

		const postFormData = new FormData();

		postFormData.append('user_message', '');
		postFormData.append('chat_history', JSON.stringify(updatedLlmHistory));
		postFormData.append(
			'interaction_stage',
			InteractionStage.PRE_LOCATION_CONFIRMATION_WIDGET,
		);
		if (chatSessionId) {
			postFormData.append('chat_session_id', chatSessionId);
			postFormData.append(
				'question_widget_answers',
				JSON.stringify(userAnswers),
			);
		}

		await handleFetchPreLC(postFormData, dispatch, navigate, chatSessionId);
	};

	useEffect(() => {
		if (!questionWidgetState.responses) {
			dispatch({
				type: 'UPDATE_QUESTION_WIDGET',
				payload: { responses: Array(content.length).fill(null) },
			});
		}
	}, []);

	const expandButton = (
		<>
			<div className="font-medium" style={{ color: '#7750E5' }}>
				Expand
			</div>
			<IoIosArrowDown className="h-5 w-5" style={{ color: '#7750E5' }} />
		</>
	);

	const collapseButton = (
		<>
			<div className="font-medium" style={{ color: '#7750E5' }}>
				Collapse
			</div>
			<IoIosArrowUp className="h-5 w-5" style={{ color: '#7750E5' }} />
		</>
	);

	const expandedWidget = (
		<div>
			<div
				className="flex flex-col gap-6 rounded-lg py-4"
				style={{
					boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
					backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
					margin: isChatViewCompressed ? 0 : '0 32px',
				}}
			>
				{questionWidgetState.submitted ? (
					<div
						className="flex flex-row justify-between mx-6 pb-2"
						style={{ borderBottom: '1px solid #F0ECFC' }}
					>
						<div className="font-semibold" style={{ color: '#574068' }}>
							Trip Preferences
						</div>
						<div
							role="presentation"
							className="flex gap-1 align-center cursor-pointer"
							onClick={onClickExpandToggle}
						>
							{collapseButton}
						</div>
					</div>
				) : null}
				{widgetQuestions.map((data, index) => {
					const { options, answer, question } = data;

					let openAnswerComponent;
					let optionsComponent;
					if (options == null) {
						const populateOpenAnswer = (value) => {
							setWidgetQuestions((prev) =>
								prev.map((cItem, cIndex) => {
									if (cIndex !== index) {
										return cItem;
									}

									return {
										...cItem,
										answer: value,
									};
								}),
							);

							dispatch({
								type: 'UPDATE_QUESTION_WIDGET',
								payload: {
									responses: responses.map((item, ind) => {
										if (ind === index) {
											return value;
										}

										return item;
									}),
								},
							});
						};

						const onOpenAnswerChange = (event) => {
							if (questionWidgetState.submitted) {
								return;
							}
							populateOpenAnswer(event.target.value);
						};

						const onOpenAnswerKeyDown = (event) => {
							maybePopulateMessage(event, populateOpenAnswer);
						};

						openAnswerComponent = (
							<div className="px-4 py-2 w-full">
								<input
									type="text"
									value={answer}
									onChange={onOpenAnswerChange}
									onKeyDown={onOpenAnswerKeyDown}
									className="w-full border border-gray-300 rounded p-2 focus:border-purple-300 outline-none"
									placeholder={OPEN_ANSWER_PLACEHOLDER}
								/>
							</div>
						);
					} else {
						optionsComponent = (
							<OptionsComponent
								options={options}
								answer={answer}
								index={index}
								setWidgetQuestions={setWidgetQuestions}
							/>
						);
					}

					return (
						<div
							key={question}
							style={{
								margin: '0 16px',
								borderBottom: '1px solid #F0ECFC',
								paddingBottom: '6px',
							}}
						>
							<div className="ml-2 font-medium" style={{ color: '#574068' }}>
								{question}
							</div>
							{options == null ? openAnswerComponent : optionsComponent}
						</div>
					);
				})}

				{questionWidgetState.submitted ? null : (
					<div className="flex">
						<Button
							variant="contained"
							type="button"
							className={styles.submit_button}
							onClick={onClickSubmit}
							style={{
								marginLeft: 'auto',
								marginRight: '20px',
								textTransform: 'unset',
							}}
						>
							Submit
						</Button>
					</div>
				)}
			</div>
		</div>
	);

	const collapsedWidget = (
		<div
			className="flex flex-row justify-between px-6 rounded-lg py-4"
			style={{
				boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
				backgroundColor: isChatViewCompressed ? '#fff' : '#FBF9FE',
				margin: isChatViewCompressed ? 0 : '0 32px',
			}}
		>
			<div className="font-medium" style={{ color: '#574068' }}>
				Trip Preferences
			</div>
			<div
				className="flex gap-1 align-center cursor-pointer"
				onClick={onClickExpandToggle}
				role="presentation"
			>
				{expandButton}
			</div>
		</div>
	);

	return questionWidgetState.expanded ? expandedWidget : collapsedWidget;
}

export default QuestionWidget;

import React, { useState } from 'react';
import Header from '@commons/Header';
import MapComponent from '@commons/MapComponent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { InstagramEmbed } from 'react-social-media-embed';
import { useRequest } from '@commons/request';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function AddInspiration(props) {
	const navigate = useNavigate();

	const [inputValue, setInputValue] = useState('');
	const [payload, setPayload] = useState({});
	const [embedUrl, setEmbedUrl] = useState('');
	const [showToast, setShowToast] = useState(false);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const [{ loading, data: userData }, trigger] = useRequest(
		{
			method: 'GET',
			url: '/api/link_uuid',
		},
		{ manual: true },
	);

	const handleButtonClick = async () => {
		const url = inputValue;
		const linkCheck = checkLink(url);

		if (!linkCheck.isValid) {
			setShowToast(true);
			return;
		}

		if (linkCheck.isInstagram) {
			setEmbedUrl(cleanUrl(url));
		}

		const postFormData = new FormData();
		postFormData.append('link', inputValue);

		try {
			const { data = {} } = await trigger({
				params: { link: inputValue },
			});

			const { payload: resultPayload = {}, status = '' } = data;

			if (status === 'success') {
				const { uuid = '' } = resultPayload;

				navigate(`/view_inspiration/${uuid}`);
			}
		} catch (error) {
			console.log(error);
		}
		// fetch(`${backendURL}/api/map_content`, {
		// 	method: 'POST',
		// 	body: postFormData,
		// })
		// 	.then((response) => {
		// 		handleStreamResponse(response);
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 	});
	};

	function cleanUrl(url) {
		// Parse the URL
		const parsedUrl = new URL(url);

		// Reconstruct the URL without the query (search) and fragment (hash)
		return parsedUrl.origin + parsedUrl.pathname;
	}

	function checkLink(link) {
		// Regular expression for validating a general URL
		const urlPattern = new RegExp(
			'^(https?:\\/\\/)?' + // validate protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i',
		); // fragment locator

		// Regular expression for checking if it's an Instagram link
		const instagramPattern =
			/(?:http(s)?:\/\/)?(?:www.)?instagram.com\/(?!.*(?:\/\w+\/\w+\/)).*/;

		// Check if the link is a valid URL
		if (!urlPattern.test(link)) {
			return { isValid: false, isInstagram: false };
		}

		// Check if it's an Instagram link
		const isInstagram = instagramPattern.test(link);

		return { isValid: true, isInstagram };
	}

	const handleStreamResponse = (response) => {
		if (response.ok) {
			const reader = response.body.getReader();
			let accumulatedText = '';

			function readChunk() {
				reader.read().then(({ done, value }) => {
					if (done) {
						// Handle the final accumulated text here
						handleFinalChunk(accumulatedText);
						return;
					}

					const chunkText = new TextDecoder().decode(value, { stream: true });
					accumulatedText += chunkText;

					// Process complete JSON objects in accumulatedText
					processAccumulatedText();

					readChunk();
				});
			}

			function processAccumulatedText() {
				// Try to find complete JSON objects and process them
				let braceCount = 0;
				let startIndex = 0;

				for (let i = 0; i < accumulatedText.length; i++) {
					const char = accumulatedText[i];

					if (char === '{') {
						braceCount++;
					} else if (char === '}') {
						braceCount--;
						if (braceCount === 0) {
							// Found a complete JSON object
							const jsonStr = accumulatedText.substring(startIndex, i + 1);
							try {
								const parsedJSON = JSON.parse(jsonStr);
								handleResponse(parsedJSON);
							} catch (e) {
								console.error('Parsing failed for chunk', jsonStr);
								// Handle parsing error
							}
							startIndex = i + 1;
						}
					}
				}

				// Keep the remaining incomplete part
				accumulatedText = accumulatedText.substring(startIndex);
			}

			function handleFinalChunk() {
				// Process any remaining JSON objects in the final chunk
				processAccumulatedText();
				if (accumulatedText.length > 0) {
					console.error(
						'Final chunk contains incomplete JSON',
						accumulatedText,
					);
					// Handle incomplete JSON in final chunk
				}
			}

			const handleResponse = (response) => {
				if (response.status === 'success') {
					console.log('Processed chunk', response.payload);
					setPayload(response.payload);
				} else {
				}
			};

			readChunk();
		}
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setShowToast(false);
	};

	return (
		<div className="flex flex-col h-screen font-sans">
			<Header />
			<div className="flex-grow bg-gradient-to-br from-background_gradient-from via-from-background_gradient-via to-background_gradient-to px-4">
				<h1 className="text-4xl font-bold text-center">Add Inspiration</h1>
				<div className="flex justify-center mt-4">
					<div className="relative w-full max-w-4xl flex flex-col">
						<TextareaAutosize
							onChange={handleInputChange}
							minRows={1}
							maxRows={1}
							placeholder="Add link"
							className="border rounded-lg w-full p-2 pr-20 resize-none box-border overflow-y-auto focus:outline-gray-300"
						/>
						<div className="absolute bottom-1 right-1 flex flex-row items-center">
							<IconButton
								size="small"
								onClick={handleButtonClick}
								disabled={inputValue === ''}
							>
								<SendIcon style={{ color: '#8b5cf6' }} />
							</IconButton>
						</div>

						<Snackbar
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							open={showToast}
							autoHideDuration={6000}
							onClose={handleClose}
						>
							<Alert
								onClose={handleClose}
								severity="error"
								variant="filled"
								sx={{ width: '100%' }}
							>
								The provided link is Invalid!
							</Alert>
						</Snackbar>
					</div>
				</div>
				<div className="flex flex-row justify-center mt-4 space-x-10">
					<div
						className="flex flex-1 flex-col space-y-4 ml-24 max-w-1/2 overflow-y-auto"
						style={{ maxHeight: 'calc(100vh - 258px)' }}
					>
						<div className="flex justify-center">
							{embedUrl && (
								<InstagramEmbed
									url={embedUrl}
									key={embedUrl}
									captioned
									width={328}
									debug
								/>
							)}
						</div>
						{payload.summary &&
							payload.summary.places &&
							payload.summary.places.map((place) => (
								<div
									className="flex flex-col space-x-4 bg-white p-4 rounded-2xl"
									key={place.place}
								>
									<p className="text-lg">{place.place}</p>
									<p className="text-sm">{place.one_liner}</p>
									<p className="text-sm break-words">{place.recs}</p>
								</div>
							))}
					</div>
					{payload.gmaps_data && (
						<div className="flex flex-1">
							<div id="map" />
							<MapComponent gmaps_data={payload.gmaps_data} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

import {
	ChatMessageSender,
	SortOptions,
	InteractionStage,
	LoadingState,
	MainTripPageView,
} from '@helpers/Enums';
import { markTripGenerationComplete } from '@utils/LoadingStateUtils';

const INITIAL_MESSAGE = `I'm AiRial, your online travel booking assistant. Simply describe your trip and I can help you book all your flights, 
    accommodation and plan your trip conveniently. Where do you want to go?`;

function convertToMinutes(time) {
	const [hours, minutes] = time.split(':').map(Number);
	return hours * 60 + minutes;
}

const initialState = {
	pageId: 0,
	chatSessionId: null,
	tripTitle: '',
	currentChatMessage: '',
	chatMessages: {
		messages: [
			{
				sender: ChatMessageSender.AIRIAL,
				message: INITIAL_MESSAGE,
			},
		],
		isTyping: false,
	},
	mainTripPageView: MainTripPageView.OVERVIEW,
	loading: 0,
	loadingState: {
		flightsLoading: LoadingState.INIT,
		hotelsLoading: LoadingState.INIT,
		itineraryLoading: LoadingState.INIT,
	},
	llmInteractionHistory: [],
	interactionStage: InteractionStage.PRE_CLARIFICATION_WIDGET,
	flight_results: null,
	hotel_results: [],
	day_plans: [],
	oldDayPlansForServer: [],
	active_tab_index: 0,
	bestTabIndex: -1,
	activeItineraryDayTab: 0,
	sort_option: SortOptions.BEST,
	flightFormCollapisbleOpen: false,
	chosen_option: -1,
	maxPossibleFlightPrice: 0,
	minPossibleFlightPrice: Infinity,
	selectedFlight: {},
	selectedHotels: [],
	selectedFlightByDateOption: [],
	gMapsData: {},
	questionWidget: {
		submitted: false,
		expanded: true,
	},
	locationConfirmationWidget: {
		submitted: false,
		expanded: true,
	},
	majorUpdateConfirmationWidgets: [],
	isMidReportGenerated: false,
	isChatViewCompressed: false,
	addedCitiesImages: [],
	inspirationDrawerInfo: {
		show: false,
		source: null,
	},
	isHeaderAnimationCompleted: false,
	initialAnimationCompleted: false,
	showTripControls: false,
	attachmentCardData: {},
	inspirationContentIds: [],
	inspirationPlaceNames: [],
	myTripsOpen: false,
	myTrips: [],
	navigatingFromLandingPageToChat: false,
	showMyTripsPopover: true,
	showTripControlsPopover: true,
	showDayPlansPopover: true,
	showChatBoxAttachmentPopover: true,
	isChatBoxClosed: false,
	isTripFullyLoaded: false,
	discoverInspirationUrls: {},
	discoverInspirationScrapeResults: {},
	selectedInspirationData: {},
	selectedInspirationDataLoadingState: {},
	hotelReviewSummary: {},
	hotelReviewSummaryLoadingState: [],
};

// eslint-disable-next-line default-param-last
function uiReducer(state = initialState, action) {
	let new_active_tab_index = 0;

	switch (action.type) {
		case 'RESET_STATE':
			return { ...initialState };
		case 'UPDATE_NAVIGATING_FROM_LANDING_PAGE_TO_CHAT':
			return { ...state, navigatingFromLandingPageToChat: action.payload };
		case 'UPDATE_MY_TRIPS_OPEN':
			return { ...state, myTripsOpen: action.payload };
		case 'UPDATE_MY_TRIPS':
			return { ...state, myTrips: action.payload };
		case 'UPDATE_CURRENT_CHAT_MESSAGE':
			return { ...state, currentChatMessage: action.payload };
		case 'UPDATE_PAGE_ID':
			return { ...state, pageId: action.payload };
		case 'UPDATE_TRIP_TITLE':
			return { ...state, tripTitle: action.payload };
		case 'UPDATE_SELECTED_FLIGHT':
			return { ...state, selectedFlight: action.payload };
		case 'UPDATE_SELECTED_HOTELS':
			return { ...state, selectedHotels: action.payload };
		case 'UPDATE_SELECTED_FLIGHT_BY_DATE_OPTION':
			return { ...state, selectedFlightByDateOption: action.payload };
		case 'UPDATE_SELECTED_FLIGHT_FOR_DATE_OPTION':
			return {
				...state,
				selectedFlightByDateOption: state.selectedFlightByDateOption.map(
					(item, index) => {
						if (index === action.payload.index) {
							return action.payload.selectedFlight;
						}

						return item;
					},
				),
			};
		case 'UPDATE_CHAT_MESSAGES':
			return { ...state, chatMessages: action.payload };
		case 'ADD_CHAT_MESSAGE':
			const isTypingUpdate =
				action.payload.isTyping !== undefined
					? { isTyping: action.payload.isTyping }
					: {};

			return {
				...state,
				chatMessages: {
					...state.chatMessages,
					messages: [
						...state.chatMessages.messages,
						...action.payload.messages,
					],
					...isTypingUpdate,
				},
			};
		case 'UPDATE_LLM_INTERACTION_HISTORY':
			return { ...state, llmInteractionHistory: action.payload };
		case 'UPDATE_INTERACTION_STAGE':
			return { ...state, interactionStage: action.payload };
		case 'UPDATE_LOADING':
			return { ...state, loading: action.payload };
		case 'UPDATE_MAIN_TRIP_PAGE_VIEW':
			return { ...state, mainTripPageView: action.payload };
		case 'UPDATE_CHAT_SESSION_ID':
			return { ...state, chatSessionId: action.payload };
		case 'UPDATE_LOADING_STATE':
			let { messages } = state.chatMessages;
			const loadingStateUpdates = action.payload;
			for (const [key, value] of Object.entries(loadingStateUpdates)) {
				if (
					state.loadingState[key] === LoadingState.LOADING &&
					value === LoadingState.LOADED
				) {
					messages = markTripGenerationComplete(key, messages);
				}
			}
			return {
				...state,
				chatMessages: {
					...state.chatMessages,
					messages,
				},
				loadingState: {
					...state.loadingState,
					...loadingStateUpdates,
				},
			};
		case 'UPDATE_FLIGHT_RESULTS':
			return { ...state, flight_results: action.payload };
		case 'UPDATE_HOTEL_RESULTS':
			return { ...state, hotel_results: action.payload };
		case 'UPDATE_HOTELS_FOR_DATE_TAB':
			return {
				...state,
				hotel_results: state.hotel_results.map((item, index) => {
					if (index === action.payload.tabIndex) {
						return action.payload.hotelResults;
					}

					return item;
				}),
			};
		case 'UPDATE_DAY_PLAN_RESULTS':
			return { ...state, day_plans: action.payload };
		case 'UPDATE_DAY_PLANS_FOR_DATE_TAB':
			return {
				...state,
				day_plans: state.day_plans.map((item, index) => {
					if (index === action.payload.tabIndex) {
						return action.payload.dayPlans;
					}

					return item;
				}),
			};
		case 'UPDATE_SINGLE_DAY_CARD':
			return {
				...state,
				day_plans: state.day_plans.map((item, index) => {
					if (index === action.payload.tabIndex) {
						return item.map(({ SCHEDULE, ...restDayPlans }, ind) => {
							if (ind === action.payload.dayIndex) {
								const initialSchedule = SCHEDULE.map((schedule, cardInd) => {
									if (action.payload.cardIndex === cardInd) {
										return {
											...schedule,
											...action.payload.updateDetails,
										};
									}

									return schedule;
								});

								const sortedSchedule = initialSchedule.sort(
									(a, b) =>
										convertToMinutes(a.TIME_START) -
										convertToMinutes(b.TIME_START),
								);

								return {
									...restDayPlans,
									SCHEDULE: sortedSchedule,
								};
							}
							return { ...restDayPlans, SCHEDULE };
						});
					}
					return item;
				}),
			};
		case 'DELETE_SINGLE_DAY_CARD':
			return {
				...state,
				day_plans: state.day_plans.map((item, index) => {
					if (index === action.payload.tabIndex) {
						return item.map(({ SCHEDULE, ...restDayPlans }, ind) => {
							if (ind === action.payload.dayIndex) {
								return {
									...restDayPlans,
									SCHEDULE: SCHEDULE.reduce((acc, cur, cardInd) => {
										if (action.payload.cardIndex === cardInd) {
											return acc;
										}

										return [...acc, cur];
									}, []),
								};
							}
							return { ...restDayPlans, SCHEDULE };
						});
					}
					return item;
				}),
			};
		case 'ADD_SINGLE_DAY_CARD':
			return {
				...state,
				day_plans: state.day_plans.map((item, index) => {
					if (index === action.payload.tabIndex) {
						return item.map(({ SCHEDULE, ...restDayPlans }, ind) => {
							if (ind === action.payload.dayIndex) {
								const sortedSchedule = [
									...SCHEDULE,
									action.payload.activityDetails,
								].sort(
									(a, b) =>
										convertToMinutes(a.TIME_START) -
										convertToMinutes(b.TIME_START),
								);

								return {
									...restDayPlans,
									SCHEDULE: sortedSchedule,
								};
							}
							return { ...restDayPlans, SCHEDULE };
						});
					}
					return item;
				}),
			};
		case 'UPDATE_OLD_DAY_PLANS_FOR_SERVER':
			return { ...state, oldDayPlansForServer: action.payload };
		case 'UPDATE_ACTIVE_TAB_INDEX':
			return { ...state, active_tab_index: action.payload };
		case 'UPDATE_BEST_TAB_INDEX':
			return { ...state, bestTabIndex: action.payload };
		case 'UPDATE_ACTIVE_ITINERARY_DAY_TAB':
			return { ...state, activeItineraryDayTab: action.payload };
		case 'UPDATE_MAX_FLIGHT_PRICE':
			return { ...state, maxPossibleFlightPrice: action.payload };
		case 'UPDATE_MIN_FLIGHT_PRICE':
			return { ...state, minPossibleFlightPrice: action.payload };
		case 'UPDATE_SORT_OPTION':
			return { ...state, sort_option: action.payload };
		case 'TOGGLE_FLIGHT_FORM_COLLAPSIBLE':
			return {
				...state,
				flightFormCollapisbleOpen: !state.flightFormCollapisbleOpen,
			};
		case 'UPDATE_CHOSEN_OPTION': // CHECK
			if (action.payload === -1) {
				if (state.chosen_option !== -1) {
					new_active_tab_index = state.chosen_option + 1;
				} else {
					new_active_tab_index = state.active_tab_index;
				}
			}
			return {
				...state,
				chosen_option: action.payload,
				active_tab_index: new_active_tab_index,
			};
		case 'UPDATE_GMAPS_DATA': {
			const { placeName, data } = action.payload;
			return {
				...state,
				gMapsData: {
					...state.gMapsData,
					[placeName]: data,
				},
			};
		}
		case 'UPDATE_HOTEL_REVIEW_SUMMARY': {
			return {
				...state,
				hotelReviewSummary: {
					...state.hotelReviewSummary,
					...action.payload,
				},
			};
		}
		case 'UPDATE_HOTEL_REVIEW_SUMMARY_LOADING_STATE': {
			const { index, data } = action.payload;

			return {
				...state,
				hotelReviewSummaryLoadingState:
					state.hotelReviewSummaryLoadingState.map((item, ind) => {
						if (index === ind) {
							return data;
						}

						return item;
					}),
			};
		}
		case 'UPDATE_COMPLETE_HOTEL_REVIEW_SUMMARY_LOADING_STATE': {
			return {
				...state,
				hotelReviewSummaryLoadingState: action.payload,
			};
		}
		case 'UPDATE_DISCOVER_INSPIRATION_URLS': {
			const { placeName, data } = action.payload;
			return {
				...state,
				discoverInspirationUrls: {
					...state.discoverInspirationUrls,
					[placeName]: data,
				},
			};
		}
		case 'UPDATE_DISCOVER_INSPIRATION_SCRAPE_RESULTS': {
			const { link, data } = action.payload;
			return {
				...state,
				discoverInspirationScrapeResults: {
					...state.discoverInspirationScrapeResults,
					[link]: data,
				},
			};
		}
		case 'UPDATE_SELECTED_INSPIRATION_DATA': {
			const { link, data } = action.payload;
			return {
				...state,
				selectedInspirationData: {
					...state.selectedInspirationData,
					[link]: data,
				},
			};
		}
		case 'UPDATE_SELECTED_INSPIRATION_DATA_LOADING_STATE': {
			const { link, data } = action.payload;
			return {
				...state,
				selectedInspirationDataLoadingState: {
					...state.selectedInspirationDataLoadingState,
					[link]: data,
				},
			};
		}
		case 'UPDATE_QUESTION_WIDGET':
			return {
				...state,
				questionWidget: { ...state.questionWidget, ...action.payload },
			};
		case 'UPDATE_LOCATION_CONFIRMATION_WIDGET':
			return {
				...state,
				locationConfirmationWidget: {
					...state.locationConfirmationWidget,
					...action.payload,
				},
			};
		case 'UPDATE_LC_WIDGET_NUM_OF_TRAVELLERS':
			return {
				...state,
				locationConfirmationWidget: {
					...state.locationConfirmationWidget,
					responses: {
						...(state.locationConfirmationWidget.responses || {}),
						numTravellers: action.payload,
					},
				},
			};
		case 'UPDATE_LC_WIDGET_START_LOCATION':
			return {
				...state,
				locationConfirmationWidget: {
					...state.locationConfirmationWidget,
					responses: {
						...(state.locationConfirmationWidget.responses || {}),
						startLocation: action.payload,
					},
				},
			};
		case 'UPDATE_LC_WIDGET_END_LOCATION':
			return {
				...state,
				locationConfirmationWidget: {
					...state.locationConfirmationWidget,
					responses: {
						...(state.locationConfirmationWidget.responses || {}),
						endLocation: action.payload,
					},
				},
			};
		case 'UPDATE_LC_WIDGET_SUGGEST_ORDER':
			return {
				...state,
				locationConfirmationWidget: {
					...state.locationConfirmationWidget,
					responses: {
						...(state.locationConfirmationWidget.responses || {}),
						suggestOrder: action.payload,
					},
				},
			};
		case 'UPDATE_LC_WIDGET_PLACES':
			return {
				...state,
				locationConfirmationWidget: {
					...state.locationConfirmationWidget,
					responses: {
						...(state.locationConfirmationWidget.responses || {}),
						places: action.payload,
					},
				},
			};
		case 'UPDATE_MAJOR_UPDATE_CONFIRMATION_WIDGETS':
			return {
				...state,
				majorUpdateConfirmationWidgets: action.payload,
			};
		case 'UPDATE_MID_REPORT_STATUS':
			return {
				...state,
				isMidReportGenerated: action.payload,
			};
		case 'UPDATE_CHAT_VIEW_STATUS':
			return {
				...state,
				isChatViewCompressed: action.payload,
			};
		case 'UPDATE_CHAT_BOX_STATUS':
			return {
				...state,
				isChatBoxClosed: action.payload,
			};
		case 'UPDATE_ADDED_CITIES_IMAGES':
			return {
				...state,
				addedCitiesImages: action.payload,
			};
		case 'UPDATE_ADD_INSPIRATION_DRAWER':
			return {
				...state,
				inspirationDrawerInfo: action.payload,
			};
		case 'UPDATE_HEADER_ANIMATION':
			return {
				...state,
				isHeaderAnimationCompleted: action.payload,
			};
		case 'INITIAL_ANIMATION_COMPLETED':
			return {
				...state,
				initialAnimationCompleted: action.payload,
			};
		case 'UPDATE_SHOW_TRIP_CONTROLS':
			return {
				...state,
				showTripControls: action.payload,
			};
		case 'UPDATE_ATTACHMENT_CARD_DATA':
			return {
				...state,
				attachmentCardData: action.payload,
			};
		case 'ADD_INSPIRATION_CONTENT_IDS':
			return {
				...state,
				inspirationContentIds: [
					...state.inspirationContentIds,
					...action.payload,
				],
			};
		case 'UPDATE_INSPIRATION_PLACE_NAMES':
			return {
				...state,
				inspirationPlaceNames: action.payload,
			};
		case 'UPDATE_SINGLE_ACTIVITY': {
			const { date, activity } = action.payload;
			const updatedDayPlans = state.day_plans.map(
				(dayPlanForDatesOption, index) =>
					index !== state.active_tab_index
						? dayPlanForDatesOption
						: dayPlanForDatesOption.map((dayPlan) => {
								if (dayPlan.DATE === date) {
									// Check if the activity already exists
									const index = dayPlan.SCHEDULE.findIndex(
										(a) => a.NAME === activity.NAME,
									);
									const newSchedule = [...dayPlan.SCHEDULE];
									if (index !== -1) {
										// Update existing activity
										newSchedule[index] = { ...newSchedule[index], ...activity };
									} else {
										// Add new activity
										newSchedule.push(activity);
									}
									return { ...dayPlan, SCHEDULE: newSchedule };
								}
								return dayPlan;
							}),
			);

			return { ...state, day_plans: updatedDayPlans };
		}
		case 'UPDATE_MY_TRIPS_POPOVER':
			return {
				...state,
				showMyTripsPopover: action.payload,
			};
		case 'UPDATE_TRIP_CONTROLS_POPOVER':
			return {
				...state,
				showTripControlsPopover: action.payload,
			};
		case 'UPDATE_DAY_PLANS_POPOVER':
			return {
				...state,
				showDayPlansPopover: action.payload,
			};
		case 'UPDATE_CHAT_BOX_ATTACHMENT_POPOVER':
			return {
				...state,
				showChatBoxAttachmentPopover: action.payload,
			};
		case 'UPDATE_OVERALL_TRIP_LOAD_STATUS':
			return {
				...state,
				isTripFullyLoaded: action.payload,
			};
		default:
			return state;
	}
}

export default uiReducer;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BookNowComponent from '../SearchResultsV1/BookNowScreen/BookNowComponent';
import ChatPanel from '../ChatPanel/Panel';
import styles from './styles.module.css';
import TripOverview from '../SearchResultsV1/EditTrip/TripOverview';
import OverviewPage from '../SearchResultsV1/Overview';

function SearchResults() {
	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);
	const isChatBoxClosed = useSelector(
		(state) => state.ui_states.isChatBoxClosed,
	);

	const showTripControls = useSelector(
		(state) => state.ui_states.showTripControls,
	);

	const [showBookNowScreen, setShowBookNowScreen] = useState(false);
	const [
		isChatBoxCloseAnimationCompleted,
		setIsChatBoxCloseAnimationCompleted,
	] = useState(true);
	const [isMainViewExpandActive, setIsMainViewExpandActive] = useState(true);
	const [isChatBoxAnimationCompleted, setIsChatBoxAnimationCompleted] =
		useState(true);

	useEffect(() => {
		if (isChatViewCompressed) {
			setTimeout(() => {
				setIsChatBoxAnimationCompleted(true);
			}, 1000);
		} else {
			setIsChatBoxAnimationCompleted(false);
		}
	}, [isChatViewCompressed]);

	useEffect(() => {
		if (isChatBoxClosed) {
			setTimeout(() => {
				setIsChatBoxCloseAnimationCompleted(true);
			}, 500);
		} else {
			setIsChatBoxCloseAnimationCompleted(false);
		}
	}, [isChatBoxClosed]);

	useEffect(() => {
		if (isChatBoxClosed) {
			setIsMainViewExpandActive(true);
		} else {
			setIsMainViewExpandActive(false);
		}
	}, [isChatBoxClosed]);

	useEffect(() => {
		window.addEventListener('error', (e) => {
			if (
				e.message ===
				'ResizeObserver loop completed with undelivered notifications.'
			) {
				const resizeObserverErrDiv = document.getElementById(
					'webpack-dev-server-client-overlay-div',
				);
				const resizeObserverErr = document.getElementById(
					'webpack-dev-server-client-overlay',
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute('style', 'display: none');
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute('style', 'display: none');
				}
			}
		});
	}, []);

	if (showBookNowScreen) {
		return (
			<div className={styles.container}>
				<div
					id="component1"
					className={`${styles.component} ${styles['component1-full']} ${styles.book_now}`}
				>
					<BookNowComponent setShowBookNowScreen={setShowBookNowScreen} />
				</div>
			</div>
		);
	}

	if (showTripControls) {
		return (
			<div className={styles.container}>
				<div
					id="component1"
					className={`${styles.component} ${styles['component1-full']}`}
				>
					<TripOverview />
				</div>
			</div>
		);
	}

	let chatFeedClassName = 'component1-full';

	if (isChatBoxClosed) {
		chatFeedClassName = 'component1-close';
	} else if (isChatViewCompressed) {
		chatFeedClassName = 'component1-expanded';
	}

	return (
		<div
			className={`${styles.container} ${!isChatViewCompressed && styles.full_container}`}
		>
			{!isChatBoxCloseAnimationCompleted || !isChatBoxClosed ? (
				<div
					id="component1"
					className={`${styles.component} ${styles.chat_feed} ${styles[chatFeedClassName]}`}
				>
					<div className={styles.chat_panel}>
						<ChatPanel />
					</div>
				</div>
			) : null}

			{isChatViewCompressed && isChatBoxAnimationCompleted && (
				<div
					id="component2"
					className={`${styles.component} ${isMainViewExpandActive ? styles['component2-full'] : styles['component2-expanded']}`}
				>
					<OverviewPage setShowBookNowScreen={setShowBookNowScreen} />
				</div>
			)}
		</div>
	);
}

export default SearchResults;

const startCase = (str) => {
	if (typeof str !== 'string') {
		throw new Error('Input must be a string');
	}

	return str
		.replace(/_/g, ' ') // Replace underscores with spaces, for snake case
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export default startCase;

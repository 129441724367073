export function Grid({ children }) {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				gap: '12px',
			}}
		>
			{children}
		</div>
	);
}

export function GridItem({ forwardedRef, children }) {
	return (
		<div
			ref={forwardedRef}
			style={{
				cursor: 'pointer',
			}}
		>
			{children}
		</div>
	);
}

import { Button, Grid, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
	BeachAccess,
	FitnessCenter,
	LocalParking,
	Pets,
	Pool,
	Restaurant,
	RoomService,
	Wifi,
	ChildFriendly,
} from '@mui/icons-material';
import { useState } from 'react';

import './Amenities.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { useLocation } from 'react-router-dom';

const amenitiesList = [
	{ name: 'Wifi', value: 'WIFI', icon: <Wifi /> },
	{ name: 'Swimming Pool', value: 'SWIMMING_POOL', icon: <Pool /> },
	{ name: 'Fitness Center', value: 'FITNESS_CENTER', icon: <FitnessCenter /> },
	{ name: 'Restaurant', value: 'RESTAURANT', icon: <Restaurant /> },
	{ name: 'Parking', value: 'PARKING', icon: <LocalParking /> },
	{ name: 'Pets Allowed', value: 'PETS_ALLOWED', icon: <Pets /> },
	{ name: 'Beach access', value: 'BEACH', icon: <BeachAccess /> },
	{ name: 'Valet Parking', value: 'VALET_PARKING', icon: <LocalParking /> },
	{ name: 'Kids Welcome', value: 'KIDS_WELCOME', icon: <ChildFriendly /> },
	{ name: 'Room Service', value: 'ROOM_SERVICE', icon: <RoomService /> },
];

function Amenities({ handleClose }) {
	const dispatch = useDispatch();

	const location = useLocation();

	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const {
		hotelFilters = [],
		hotelReqs = [],
		hotelSets,
		numTravellers,
	} = tripLocationDetails;

	const [selectedAmenities, setSelectedAmenities] = useState(
		(hotelFilters.filter((hotelFilter) => hotelFilter)[0] || {}).AMENITIES ||
			[],
	);

	const handleAmenityToggle = (amenityName) => {
		if (selectedAmenities.includes(amenityName)) {
			setSelectedAmenities(
				selectedAmenities.filter((item) => item !== amenityName),
			);
		} else {
			setSelectedAmenities([...selectedAmenities, amenityName]);
		}
	};

	const isSelected = (amenityName) => {
		return selectedAmenities.includes(amenityName);
	};

	const handleApplyAmenities = () => {
		const updatedHotelFilters = hotelFilters.map((item) => {
			if (!item) {
				return null;
			}

			return {
				...item,
				AMENITIES: selectedAmenities,
			};
		});

		dispatch({
			type: 'UPDATE_HOTEL_FILTERS',
			payload: updatedHotelFilters,
		});

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: hotelSets,
				hotelFilters: updatedHotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: hotelSortCriterion,
			},
			tabIndex: activeTabIndex,
			gMapsData,
			chatSessionId,
		});

		handleClose();
	};

	const handleClearFilter = () => {
		dispatch({
			type: 'UPDATE_HOTEL_AMENITIES',
			payload: [],
		});

		const updatedHotelFilters = hotelFilters.map((item) => {
			if (!item) {
				return null;
			}

			return {
				...item,
				AMENITIES: [],
			};
		});

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: hotelSets,
				hotelFilters: updatedHotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: hotelSortCriterion,
			},
			tabIndex: activeTabIndex,
			gMapsData,
			chatSessionId,
		});
	};

	return (
		<div
			className="flex flex-col gap-4"
			style={{ maxWidth: '300px', maxHeight: '450px' }}
		>
			<div className="flex justify-between">
				<div className="text-lg">Amenities</div>
				<CloseIcon onClick={handleClose} />
			</div>

			<div className="ml-4">
				<Grid
					container
					spacing={2}
					style={{
						border: '1px solid #D9DCE0',
						marginTop: '4px',
					}}
				>
					{amenitiesList.map(({ value, name, icon }, index) => (
						<Grid item xs={6} key={index}>
							<Paper
								elevation={isSelected(value) ? 4 : 0}
								onClick={() => handleAmenityToggle(value)}
								className={`p-4 text-center flex flex-col cursor-pointer ${isSelected(value) ? 'bg-gray-200' : ''}`}
								style={{
									backgroundColor: isSelected(value) ? '#E7F0FE' : 'inherit',
									color: isSelected(value) ? '#5288DD' : 'inherit',
								}}
							>
								<IconButton
									style={{
										color: isSelected(value) ? '#5288DD' : 'inherit',
									}}
								>
									{icon}
								</IconButton>
								<span className="text-sm">{name}</span>
							</Paper>
						</Grid>
					))}
				</Grid>
			</div>

			<div
				className="flex ml-auto"
				style={{
					position: 'sticky',
					bottom: 0,
					borderTop: '1px solid #D9DCE0',
					width: 'calc(100% + 32px)',
					marginLeft: '-16px',
					justifyContent: 'flex-end',
					backgroundColor: '#fff',
					padding: '12px 16px',
				}}
			>
				<Button
					sx={{ textTransform: 'unset' }}
					onClick={handleClearFilter}
					variant="text"
					size="small"
				>
					Clear
				</Button>

				<Button
					sx={{ textTransform: 'unset', backgroundColor: '#5b21b6' }}
					variant="contained"
					size="small"
					onClick={handleApplyAmenities}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

export default Amenities;

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Slider, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handleFindFlights } from '@helpers/FlightsQueryHelper';

function PriceFilter({
	handleClose = () => {},
	setAnchorEl = () => {},
	maxPriceFilter,
	setMaxPriceFilter = () => {},
	activeTabIndex,
}) {
	const dispatch = useDispatch();
	const location = useLocation();

	const { maxPossibleFlightPrice, minPossibleFlightPrice } = useSelector(
		(state) => state.ui_states,
	);

	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);

	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const {
		travelSets = [],
		maxPrice,
		tripType,
		tripClass,
	} = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		hotelFilters = [],
		hotelReqs = [],
		numTravellers,
		hotelSortCriterion = '',
	} = tripLocationDetails;

	const FLIGHTSETS = travelSets.map((travelSet) => {
		return travelSet.reduce((acc, { type, flightDetails = {} }) => {
			if (['flight', 'FLIGHT'].includes(type)) {
				return [
					...acc,
					{
						DATE_OF_JOURNEY: flightDetails?.dateOfJourney,
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}

			return acc;
		}, []);
	});

	const data_v2 = {
		NUM_PASSENGERS: numTravellers,
		MAX_PRICE: maxPrice,
		FLIGHTSETS,
		TRIP_TYPE: tripType,
		TRIP_CLASS: tripClass,
	};

	const handleClearFilter = () => {
		dispatch({
			type: 'UPDATE_TRANSITIONS_REDUCER',
			payload: {
				maxPrice: -1,
			},
		});

		const flightSetData = {
			...data_v2,
			MAX_PRICE: -1,
		};

		handleClose();

		handleFindFlights({
			flightQuery: flightSetData,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery: {
				locations: {
					startLocation,
					endLocation,
					places,
				},
				chat_history: llmInteractionHistory,
			},
			shouldUpdateFlightsAndDayPlans: true,
			tripData: {
				NUM_TRAVELLERS: numTravellers,
				hotelFilters,
				travelSets,
				LOCATIONS: places,
				HOTEL_REQUIREMENT: hotelReqs,
				hotelSortCriterion,
			},
			sortOption: flightSortOption,
			gMapsData,
			curActiveTabIndex: activeTabIndex,
		});
	};

	return (
		<div className="flex flex-col gap-2">
			<div className="flex justify-between">
				<div className="text-lg">Price</div>
				<CloseIcon onClick={handleClose} />
			</div>

			{!maxPrice && maxPrice === maxPriceFilter ? (
				<div className="text-sm mt-4" style={{ color: '#8ab4f8' }}>
					All Prices
				</div>
			) : (
				<div className="text-sm mt-4" style={{ color: '#307dfa' }}>
					up to $
					{maxPriceFilter === -1 ? maxPossibleFlightPrice : maxPriceFilter}
				</div>
			)}

			<Box sx={{ width: 300, padding: '0 4px' }}>
				<Slider
					defaultValue={maxPrice <= 0 ? maxPossibleFlightPrice : maxPrice}
					aria-label="Default"
					valueLabelDisplay="auto"
					min={minPossibleFlightPrice}
					max={maxPossibleFlightPrice}
					onChange={(_, value) => {
						setMaxPriceFilter(value);
					}}
				/>
			</Box>

			<div className="flex ml-auto mt-2">
				<Button
					sx={{ textTransform: 'unset' }}
					onClick={handleClearFilter}
					variant="text"
					size="small"
				>
					Clear
				</Button>

				<Button
					sx={{ textTransform: 'unset', backgroundColor: '#5b21b6' }}
					variant="contained"
					size="small"
					onClick={() => {
						dispatch({
							type: 'UPDATE_TRANSITIONS_REDUCER',
							payload: {
								maxPrice: maxPriceFilter,
							},
						});

						const flightSetData = {
							...data_v2,
							MAX_PRICE: maxPriceFilter,
						};

						handleFindFlights({
							flightQuery: flightSetData,
							dispatch,
							chatSessionId,
							location,
							dayPlansQuery: {
								locations: {
									startLocation,
									endLocation,
									places,
								},
								chat_history: llmInteractionHistory,
							},
							shouldUpdateFlightsAndDayPlans: true,
							tripData: {
								NUM_TRAVELLERS: numTravellers,
								hotelFilters,
								travelSets,
								LOCATIONS: places,
								HOTEL_REQUIREMENT: hotelReqs,
								hotelSortCriterion,
							},
							sortOption: flightSortOption,
							gMapsData,
							curActiveTabIndex: activeTabIndex,
						});

						setAnchorEl(null);
					}}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

export default PriceFilter;

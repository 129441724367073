import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';

function Carousel({ data }) {
	const [slide, setSlide] = useState(0);
	const [isImageHovered, setIsImageHovered] = useState(false);

	const nextSlide = () => {
		setSlide(slide === data.length - 1 ? 0 : slide + 1);
	};

	const prevSlide = () => {
		setSlide(slide === 0 ? data.length - 1 : slide - 1);
	};

	useEffect(() => {
		let interval;

		if (!isImageHovered) {
			interval = setInterval(() => {
				nextSlide();
			}, 3500);
		}

		return () => clearInterval(interval);
	}, [isImageHovered, slide]);

	return (
		<div
			className={styles.map_carousel}
			onMouseEnter={() => setIsImageHovered(true)}
			onMouseLeave={() => setIsImageHovered(false)}
		>
			{isImageHovered && (
				<div className={`${styles.map_icon_wrapper} ${styles.arrow_left}`}>
					<NavigateBeforeIcon
						style={{ color: '#fff' }}
						onClick={prevSlide}
						fontSize="small"
					/>
				</div>
			)}

			{data.map((item, idx) => (
				<div
					key={item.src}
					className={`${styles.map_slide} ${slide !== idx && styles.slide_hidden}`}
				>
					<img
						src={item.src}
						alt={item.alt}
						key={idx}
						style={{ margin: 'auto', objectFit: 'cover' }}
					/>
				</div>
			))}

			{isImageHovered && (
				<div className={`${styles.map_icon_wrapper} ${styles.arrow_right}`}>
					<NavigateNextIcon
						style={{ color: '#fff' }}
						onClick={nextSlide}
						fontSize="small"
					/>
				</div>
			)}

			<span className={`${styles.indicators}`}>
				{data.map((_, idx) => (
					<div
						role="presentation"
						key={idx}
						className={`${styles.indicator} ${slide !== idx && styles.indicator_inactive}`}
						onClick={() => setSlide(idx)}
					/>
				))}
			</span>
		</div>
	);
}

export default Carousel;

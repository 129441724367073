import { parse, Allow } from 'partial-json';

export const fetchPhotosBatch = (
	placesToFetch,
	dispatch,
	onFinished = () => {},
) => {
	if (placesToFetch.length > 0) {
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get_photos_batch`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ queries: placesToFetch }),
			timeout: 10000,
		})
			.then((response) => {
				handleStreamResponse(response);
			})
			.catch((error) => {
				// console.error('Error fetching place data:', error);
			})
			.finally(() => {
				onFinished();
			});
	}

	const handleStreamResponse = (response) => {
		if (response.ok) {
			const reader = response.body.getReader();
			let accumulatedChunks = '';

			// eslint-disable-next-line no-inner-declarations
			function readChunk() {
				reader.read().then(({ done, value }) => {
					if (done) {
						return;
					}

					const chunkText = new TextDecoder().decode(value);
					if (chunkText === 'ERROR') {
						return;
					}
					accumulatedChunks += chunkText;
					const parsedJSON = parse(accumulatedChunks, Allow.ARR);
					if (parsedJSON) {
						parsedJSON.forEach((result) => {
							dispatchUpdate(result.placeName, result.data);
						});
					}
					readChunk(); // Recursively read the next chunk
				});
			}

			readChunk(); // Start reading chunks
		}
	};

	function dispatchUpdate(placeName, data) {
		dispatch({
			type: 'UPDATE_GMAPS_DATA',
			payload: {
				placeName,
				data,
			},
		});
	}
};

export const getPlaceNameForHotel = (hotelData, hotelFilters, index) => {
	const requiredHotelFilters = hotelFilters.filter(
		(hotelFilter) => hotelFilter,
	);

	const { COORDINATES_LABEL = '' } = requiredHotelFilters?.[index] || {};
	return `${hotelData?.name}, ${COORDINATES_LABEL.split(',')[0]}`;
};

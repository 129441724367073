import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';

function OptionsComponent({ options, answer, index, setWidgetQuestions }) {
	const dispatch = useDispatch();
	const questionWidgetState = useSelector(
		(state) => state.ui_states.questionWidget,
	);

	const { responses = [] } = questionWidgetState;

	const onChangeOption = ({ option }) => {
		if (questionWidgetState.submitted) {
			return;
		}
		const answers =
			answer.trim() === '' ? [] : answer.split('::').map((x) => x.trim());
		const optionSelected = answers.includes(option);

		const newAnswers = optionSelected
			? answers.filter((x) => x !== option)
			: [...answers, option];

		const userResponse = newAnswers.join(' :: ');

		setWidgetQuestions((prev) =>
			prev.map((cItem, cIndex) => {
				if (cIndex !== index) {
					return cItem;
				}

				return {
					...cItem,
					answer: userResponse,
				};
			}),
		);

		dispatch({
			type: 'UPDATE_QUESTION_WIDGET',
			payload: {
				responses: responses.map((item, ind) => {
					if (ind === index) {
						return userResponse;
					}

					return item;
				}),
			},
		});
	};

	return (
		<div className="flex flex-wrap">
			{options.map((option, ind) => {
				const answers =
					answer.trim() === '' ? [] : answer.split('::').map((x) => x.trim());
				const optionSelected = answers.includes(option);

				return (
					<div
						key={ind}
						className={`max-w-max px-4 py-2 border ${optionSelected ? 'bg-primary-100' : 'bg-white'} border-purple-200 rounded-md m-2 font-medium
						 cursor-pointer ${optionSelected ? styles.fadeIn : null}`}
						onClick={() => onChangeOption({ option })}
						role="presentation"
						style={{ color: '#301345' }}
					>
						{option}
					</div>
				);
			})}
		</div>
	);
}

export default OptionsComponent;

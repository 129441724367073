export function convertTimeFormat12Hr(time) {
	let [hours, minutes] = time.split(':');
	hours = parseInt(hours);
	const suffix = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12 || 12;

	return `${hours}:${minutes} ${suffix}`;
}

export const getTimeString = ({ timeStart, timeEnd }) => {
	const convertedStartTime = convertTimeFormat12Hr(timeStart);
	const convertedEndTime = convertTimeFormat12Hr(timeEnd);

	if (timeStart && convertedStartTime && timeEnd && convertedEndTime) {
		return `${convertedStartTime} -
	${convertedEndTime}`;
	}

	if (timeStart && convertedStartTime) {
		return convertedStartTime;
	}

	return null;
};

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import AirportAutoCompleteSelect from '@commons/forms/page-components/components/DestinationAutoCompleteTextBox/AirportAutoCompleteSelect';
import PriceFilter from './PriceFilter';
import TimesFilter from './TimesFilter';
import DurationFilter from './DurationFilter';

function FlightTransit({
	setMaxPriceFilter,
	maxPriceFilter,
	maxPrice,
	timeFilter,
	setTimeFilter,
	tripDetails,
	index,
	durationFilter,
	setDurationFilter,
	flightDeparture,
	setFlightDeparture,
	localActiveTabIndex,
	minDate,
	maxDate,
	finalAirportDetails,
	setFinalAirportDetails,
}) {
	const MAPPING = [
		{
			component: PriceFilter,
			label: 'Price',
			props: {
				setMaxPriceFilter,
				maxPriceFilter,
				maxPrice,
			},
		},
		{
			component: TimesFilter,
			label: 'Time',
			props: {
				timeFilter,
				setTimeFilter,
				tripDetails,
				index,
				localActiveTabIndex,
			},
		},
		{
			component: DurationFilter,
			label: 'Duration',
			props: {
				durationFilter,
				setDurationFilter,
			},
		},
	];

	return (
		<div className="flex flex-col gap-4 pt-4" style={{ color: '#301345' }}>
			{/* <AirportAutoCompleteSelect
				text="From Airport"
				curValue={finalAirportDetails?.fromAirport}
				onSuggestionSelected={(event, { suggestion }) => {
					setFinalAirportDetails((prev) => ({
						...prev,
						fromAirport: [
							...(prev?.fromAirport || []),
							suggestion.code ?? suggestion.display,
						],
					}));
				}}
				removeSelectedItem={(ind) => {
					setFinalAirportDetails((prev) => {
						const newSelectedItems = [...(prev.fromAirport || [])];
						newSelectedItems.splice(ind, 1);

						return {
							...prev,
							fromAirport: newSelectedItems,
						};
					});
				}}
			/>

			<AirportAutoCompleteSelect
				text="To Airport"
				curValue={finalAirportDetails?.toAirport}
				onSuggestionSelected={(event, { suggestion }) => {
					setFinalAirportDetails((prev) => ({
						...prev,
						toAirport: [
							...(prev?.toAirport || []),
							suggestion.code ?? suggestion.display,
						],
					}));
				}}
				removeSelectedItem={(ind) => {
					setFinalAirportDetails((prev) => {
						const newSelectedItems = [...(prev.toAirport || [])];
						newSelectedItems.splice(ind, 1);

						return {
							...prev,
							toAirport: newSelectedItems,
						};
					});
				}}
			/> */}

			<div className="flex flex-col gap-1 text-sm my-2">
				<div>Departure</div>

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DesktopDatePicker
						value={dayjs(flightDeparture)}
						onChange={(newValue) =>
							setFlightDeparture(newValue.format('YYYY-MM-DD'))
						}
						minDate={dayjs(new Date(minDate))}
						maxDate={maxDate ? dayjs(new Date(maxDate)) : undefined}
						sx={{
							width: '100%',
							'& .MuiInputBase-input': {
								padding: '8px 0px 8px 8px',
								height: '24px',
							},
							'& .MuiInputAdornment-root': { margin: '0px' },
						}}
					/>
				</LocalizationProvider>
			</div>

			<div
				className="rounded-lg flex flex-col p-4 gap-4"
				style={{
					border: '1px solid #7750E5',
				}}
			>
				<div>Filters</div>

				{MAPPING.map(({ component: ActiveComponent, props, label }, ind) => {
					return (
						<Accordion key={label}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`panel${ind}-content`}
								id={`panel${ind}-header`}
							>
								{label}
							</AccordionSummary>
							<AccordionDetails>
								<ActiveComponent {...props} />
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>
		</div>
	);
}

export default FlightTransit;

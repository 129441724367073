import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
	palette: {
		mode: 'light',
	},
});

function TextInputController(props) {
	const { name, control, rules, value, type, InputProps, onKeyDown, ...rest } = props;

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Controller
			key={name}
			control={control}
			name={name}
			defaultValue={value}
			rules={rules}
			render={({ field }) => (
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<TextField
						{...field}
						{...rest}
						id={name}
						type={showPassword ? 'text' : type}
						variant="outlined"
						InputProps={{
							...InputProps,
							endAdornment: type === 'password' && (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? (
											<VisibilityOff fontSize="small" />
										) : (
											<Visibility fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						onChange={(e) => {
							field.onChange(e.target.value);
						}}
						onKeyDown={onKeyDown}
						onBlur={() => {
							field.onBlur();
						}}
						error={!!rest.error}
						helperText={rest.error?.message}
					/>
				</ThemeProvider>
			)}
		/>
	);
}

export default TextInputController;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SearchSvg from './SearchSvg';

const DATA = [
	{
		src: 'https://ik.imagekit.io/mew0x2ztv/paris-1.jpeg?updatedAt=1714537113738',
		title: 'Multi-city Eurotrip with my friends in September.',
		text: 'Chicago to Paris next Friday or Saturday with 2 friends. From there to Marseille, two French Riviera towns and Amsterdam. 16-day trip. We will travel by train in France and would love to see the Van Gogh museum.',
		alt: 'Photo by Max van den Oetelaar on Unsplash',
		transform: 'translate(0%, -5%)'
	},
	{
		src: 'https://ik.imagekit.io/mew0x2ztv/seattle-2.jpeg?updatedAt=1714538631141',
		title: 'A memorable emerald city family trip awaits.',
		text: 'Thursday evening to Sunday evening weekend return trip from SFO to Seattle, non-stop, in the first or second week of December with my parents. One day in the city and one in Olympic national park. We are staying with family in Seattle.',
		alt: 'Photo by izayah ramos on Unsplash',
		transform: 'translate(0%, -10%)'
	},
	{
		src: 'https://ik.imagekit.io/mew0x2ztv/hawaii-4.jpeg?updatedAt=1714539455369',
		title: 'Romantic Hawaii long weekend getaway for two.',
		text: 'Denver to Big Island, Hawaii with my husband; leave on Thursday night or Friday morning; return Sunday night or Monday morning. Interested in a dolphin-watching cruise and scuba diving. Find a beach access hotel for us.',
		alt: 'Photo by Stephen Plopper on Unsplash',
		transform: 'translate(0%, -30%)'
	},
	{
		src: 'https://ik.imagekit.io/mew0x2ztv/egypt-1.jpeg?updatedAt=1714538351687',
		title: 'Once-in-a-lifetime Egyptian experience.',
		text: '9-day Friday to Sunday round trip for 2 from New York to Egypt any week in February. We want to explore the pyramids of course. We also want to visit Cairo and one or two more cities in Egypt.',
		alt: 'Photo by Spencer Davis on Unsplash',
		transform: 'translate(0%, -10%)'
	},
];

export default function CardsSection() {
	const dispatch = useDispatch();

	const [progress, setProgress] = useState(0);
	const [slideVisible, setSlideVisible] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => {
			setSlideVisible((visible) => {
				if (visible === DATA.length - 1) {
					return 0;
				}

				return visible + 1;
			});

			setProgress(0);
		}, 5000);

		return () => clearTimeout(timer);
	}, [slideVisible]);

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prevProgress) => {
				if (prevProgress >= 100) {
					clearInterval(interval);
					return 100;
				}
				return prevProgress + 1;
			});
		}, 50);
		return () => clearInterval(interval);
	}, [slideVisible]);

	const handleClick = (message) => () => {
		dispatch({ type: 'UPDATE_CURRENT_CHAT_MESSAGE', payload: message });
	};
	const handleTitleClick = (index) => () => {
		setSlideVisible(index);
		setProgress(0);
	};
	const { src, text, alt = '', transform } = DATA[slideVisible];

	const newCardsSection = (
		<div
			className="rounded-lg bg-white bg-opacity-80"
			style={{ width: '800px', height: '352px' }}
		>
			<div className="flex w-full h-full">
				<div
					className="flex flex-col px-4 py-6 justify-between h-full"
					style={{ width: '220px' }}
				>
					{DATA.map(({ title: cardTitle }, index) => {
						return (
							<div
								className="flex justify-between rounded-lg cursor-pointer w-full h-16 px-4 text-xs items-center"
								key={cardTitle}
								style={{
									color: '#574068',
									...(index === slideVisible
										? {
												background:
													'linear-gradient(white, white) padding-box, linear-gradient(to right, #DC1F90, #BAA7F2) border-box',
												border: '1.5px solid transparent',
												// border: '1.5px solid #DC1F90',
												fontWeight: 500,
											}
										: { border: '1px solid #BAA7F2' }),
								}}
								onClick={handleTitleClick(index)}
								role="presentation"
							>
								{cardTitle}
							</div>
						);
					})}
				</div>
				<div
					className="h-full flex flex-col gap-4 py-6 pr-4"
					style={{ width: '580px' }}
				>
					<div
						className="flex items-center p-3 gap-3 w-full h-[72px] rounded-lg text-sm cursor-pointer"
						style={{
							backgroundColor: 'rgba(119, 80, 229, 0.05)',
							color: '#442A57',
						}}
						onClick={handleClick(text)}
						role="presentation"
					>
						<div className="w-[500px]">{text}</div>
						<SearchSvg />
					</div>
					<div
						className="w-full h-[216px] rounded-lg overflow-hidden"
					>
						<img
							src={src}
							alt={alt}
							className="w-full"
							style={{
								objectFit: 'cover',
								transform: transform
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return newCardsSection;
}

function DeleteIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="32" height="32" fill="white" />
			<path
				d="M21.81 11.525V24.255C21.81 25.355 20.92 26.255 19.81 26.255H12.18C11.08 26.255 10.18 25.365 10.18 24.255V11.525"
				stroke="url(#paint0_linear_866_54858)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.92999 9.52502H23.07"
				stroke="url(#paint1_linear_866_54858)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.16 7.52499C13.16 6.54499 13.96 5.745 14.94 5.745H17.06C18.04 5.745 18.84 6.54499 18.84 7.52499"
				stroke="url(#paint2_linear_866_54858)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.98 14.615V21.855"
				stroke="url(#paint3_linear_866_54858)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.93 14.615V21.855"
				stroke="url(#paint4_linear_866_54858)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_866_54858"
					x1="16.0013"
					y1="10.0461"
					x2="16.0013"
					y2="27.7339"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7750E5" />
					<stop offset="1" stopColor="#DC1F90" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_866_54858"
					x1="16.0077"
					y1="9.42462"
					x2="16.0077"
					y2="10.6254"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7750E5" />
					<stop offset="1" stopColor="#DC1F90" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_866_54858"
					x1="16.0031"
					y1="5.56628"
					x2="16.0031"
					y2="7.70371"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7750E5" />
					<stop offset="1" stopColor="#DC1F90" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_866_54858"
					x1="14.4805"
					y1="13.8881"
					x2="14.4805"
					y2="22.5819"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7750E5" />
					<stop offset="1" stopColor="#DC1F90" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_866_54858"
					x1="18.4305"
					y1="13.8881"
					x2="18.4305"
					y2="22.5819"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#7750E5" />
					<stop offset="1" stopColor="#DC1F90" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default DeleteIcon;

import React, { useEffect, useState } from 'react';

export function InspirationLoadingDots() {
	const [dots, setDots] = useState('');

	useEffect(() => {
		const intervalId = setInterval(() => {
			setDots((prevDots) => (prevDots.length === 3 ? '' : `${prevDots}.`));
		}, 500); // Change dot every 500ms

		return () => clearInterval(intervalId); // Clean up the interval on component unmount
	}, []);

	return <div className="flex">Validating link {dots}</div>;
}

import React from 'react';
import { Controller } from 'react-hook-form';
import AndroidToggleSwitch from '../components/AndroidToggleSwitch';

function ToggleSwitchController(props) {
	const { name, control, rules, value } = props;

	return (
		<Controller
			key={name}
			control={control}
			name={name}
			defaultValue={value}
			rules={rules}
			render={({ field: { onChange, value: newValue, ref, onBlur } }) => (
				<AndroidToggleSwitch
					checked={newValue}
					onChange={onChange}
					inputRef={ref}
					onBlur={() => {
						onBlur();
					}}
				/>
			)}
		/>
	);
}

export default ToggleSwitchController;

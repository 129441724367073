import { red } from '@mui/material/colors';
import { TripUpdateType } from './Enums';
import { postLocationConfirmationReport } from './GenerateTripHelper';

const NOOP = (reduxState, dispatch, location) => {};

export const supportedUpdateTypesToPostActions = {
	// Stable States after this without any actions
	[TripUpdateType.PRE_LC_ASSISTANT_RESPONSE]: NOOP,
	[TripUpdateType.LOCATION_CONFIRMATION_ASSISTANT_INITIATED]: NOOP,
	[TripUpdateType.POST_REPORT_MAJOR_UPDATE_USER_CANCEL]: NOOP,
	[TripUpdateType.POST_REPORT_ASSISTANT_REPORT]: NOOP,
	[TripUpdateType.DEMO_ATTACH_FLOW]: NOOP,
	[TripUpdateType.ITINERARY_INSPIRATION_UPDATE_COMPLETE]: NOOP,
	[TripUpdateType.HOTEL_SELECTION_CHANGED_USER]: NOOP,
	[TripUpdateType.SINGLE_DAY_CARD_UPDATED]: NOOP,
	[TripUpdateType.ASSISTANT_ERROR]: NOOP,

	// Kinda tricky= Day plans or Hotels might be in partial state but whatever I guess
	[TripUpdateType.OVERVIEW_HOTEL_SELECTED]: NOOP,
	[TripUpdateType.DAY_PLANS_LOADED]: NOOP,

	// Assistant Responded but more actions needed to make stable state
	[TripUpdateType.LOCATION_CONFIRMATION_ASSISTANT_REPORT]: (
		reduxState,
		dispatch,
		location,
	) => postLocationConfirmationReport(reduxState, dispatch, location),
};

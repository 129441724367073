import airportsMap from '@helpers/TooltipHelper';
import TooltipWrapper from '@helpers/TooltipWrapper';

const calculateTimeDifference = (dateTime1, dateTime2) => {
	const date1 = new Date(dateTime1);
	const date2 = new Date(dateTime2);

	let diffInMilliseconds = Math.abs(date2 - date1);

	const millisecondsInADay = 24 * 60 * 60 * 1000;
	const millisecondsInAnHour = 60 * 60 * 1000;
	const millisecondsInAMinute = 60 * 1000;

	const days = Math.floor(diffInMilliseconds / millisecondsInADay);
	diffInMilliseconds %= millisecondsInADay;

	const hours = Math.floor(diffInMilliseconds / millisecondsInAnHour);
	diffInMilliseconds %= millisecondsInAnHour;

	const minutes = Math.floor(diffInMilliseconds / millisecondsInAMinute);

	let output = '';

	if (days > 0) {
		output += `${days} d `;
	}

	if (hours > 0) {
		output += `${hours} h `;
	}

	output += `${minutes} m`;
	output += ' layover';
	return output;
};

const getStopWithTooltip = (routes, stopIndex) => {
	const isLastStop = stopIndex === routes.length - 2;
	return (
		<div>
			<TooltipWrapper
				tooltipContent={`${calculateTimeDifference(
					routes[stopIndex].utc_arrival,
					routes[stopIndex + 1].utc_departure,
				)}. ${airportsMap[routes[stopIndex].flyTo]} (${
					routes[stopIndex].flyTo
				})`}
			>
				<p style={{ color: '#301345' }}>
					{' '}
					{routes[stopIndex].flyTo + (isLastStop ? '' : ', ')}{' '}
				</p>
			</TooltipWrapper>
		</div>
	);
};

const getAllStops = (routes) => {
	const allStops = [];
	for (let stopIndex = 0; stopIndex < routes.length - 1; stopIndex += 1) {
		allStops.push(getStopWithTooltip(routes, stopIndex));
	}
	return allStops;
};

function StopsInfo({ routes }) {
	return <div className="flex flex-row">{getAllStops(routes)}</div>;
}

export default StopsInfo;

import { useSelector, useDispatch } from 'react-redux';
import { getTimeString } from '@utils/DateTimeUtils';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { getTransportIcon, TRANSITION_ICON_MAPPING } from '@utils/IconUtils';
import { useEffect, useState } from 'react';
import TooltipWrapper from '@helpers/TooltipWrapper';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { PiTiktokLogoLight } from 'react-icons/pi';
import { ChatMessageAttachmentType } from '@helpers/Enums';
import ImageLoader from '@commons/Itinerary/ImageLoader';
import AISparkIconItinerary from '@commons/icons/AISparkIconItinerary';
// import CheckCircleselected from '@commons/icons/CheckCircleSelected';
// import startCase from '@utils/startCase';
import Drawer from '@mui/material/Drawer';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import EditPencil from '@commons/icons/EditPencil';
import styles from './styles.module.css';
import SingleItineraryDetails from './SingleItineraryDetails';
import MapPointer from '../icons/MapPointer';

function TagToShow({
	highlightCard,
	view,
	hasNewTag,
	hasInspiredTag,
	highlightCardDescription,
	newTagLabel,
}) {
	if (highlightCard && view !== 'chat_box') {
		return (
			<div className="bg-primary-500 text-white text-xs px-2 py-1 rounded">
				<TooltipWrapper
					tooltipContent={
						highlightCardDescription || 'Added from your preferences'
					}
				>
					<TipsAndUpdatesIcon fontSize="small" />
				</TooltipWrapper>
			</div>
		);
	}

	if (hasNewTag && !highlightCard && !hasInspiredTag && view !== 'chat_box') {
		return (
			<div
				className="font-extrabold rounded"
				style={{
					backgroundColor: '#efeafc',
					color: '#6537ef',
					padding: '2px 6px',
					fontSize: '10px',
				}}
			>
				{newTagLabel || 'NEW'}
			</div>
		);
	}

	if (hasInspiredTag && !highlightCard && view !== 'chat_box') {
		return (
			<div className="bg-secondary_highlight  text-white text-xs px-2 py-1 rounded">
				<PiTiktokLogoLight />
			</div>
		);
	}

	return null;
}

function ItinerarySingleDayCard({
	dayCardData,
	index,
	onPlaceClicked = (placeName) => {},
	view = '',
	dayIndex,
	selectedCard = false,
	sparkEnabled = false,
	highlightCardEnabled = false,
	marginRequired = true,
	showMore = true,
	highlightCardForUserEducation = false,
	placeIndex = -1,
}) {
	const dispatch = useDispatch();

	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const {
		description = '',
		name = '',
		placeName = '',
		timeStart = null,
		timeEnd = null,
		hasNewTag = false,
		transport = null,
		type = '',
		hasInspiredTag = false,
		userRequested = null,
		userRequestedDescription = null,
		routeInfo = null,
	} = dayCardData;

	const { legs = [] } = routeInfo || {};

	const {
		start_address = '',
		end_address = '',
		departure_time,
		arrival_time,
		duration = {},
	} = legs[0] || {};

	const [startAddressFirstLine] = start_address.split(', ');
	const [endAddressFirstLine] = end_address.split(', ');

	const photoUrl =
		gMapsData[placeName]?.[0]?.[0]?.photos_data[0]?.original_photo_url;

	// const { reviews_tags = [] } = gMapsData[placeName]?.[0]?.[0] || {};

	const [usePhoto, setUsePhoto] = useState(true);
	const [showDayPlanDrawer, setShowDayPlanDrawer] = useState(false);

	const [isHovered, setIsHovered] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [animating, setAnimating] = useState(false);

	const TransitionIcon = transport
		? getTransportIcon(transport)
		: type == 'transfer'
			? TRANSITION_ICON_MAPPING.INTERNAL_TRASNPORT
			: null;

	const highlightCard = highlightCardEnabled && userRequested === 'SPECIFIC';
	let borderClass = 'border border-card_border';
	if (hasInspiredTag) {
		borderClass = 'border border-secondary_highlight shadow-lg';
	} else if (hasNewTag || highlightCard || selectedCard) {
		borderClass = 'border border-primary-500 shadow-lg';
	}
	const isTransfer = type === 'transfer' || transport;
	const showTime = timeStart && timeEnd;

	const onClickRemoveFromAttach = () => {
		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_REMOVE_ATTACHED_ACTIVITY_CARD,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_ATTACHMENT_CARD_DATA',
			payload: { show: false },
		});
	};

	const onAttachActivity = (dayCardData, dayIndex, index) => {
		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_ATTACH_ACTIVITY_CARD,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_CHAT_BOX_STATUS',
			payload: false,
		});

		setTimeout(() => {
			dispatch({
				type: 'UPDATE_ATTACHMENT_CARD_DATA',
				payload: {
					data: { ...dayCardData, dayIndex, index },
					type: ChatMessageAttachmentType.DAY_PLANS_CARD,
					show: true,
				},
			});
		}, 100);
	};

	useEffect(() => {
		let timer;
		if (isHovered) {
			timer = setTimeout(() => {
				setShowDetails(true);
			}, 500);
		} else {
			setShowDetails(false);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [isHovered]);

	// const handleToggleEditActivity = () => {
	// 	setAnimating(true);

	// 	setTimeout(() => {
	// 		setShowEditComponent((prev) => !prev);
	// 		setAnimating(false);
	// 	}, 200);
	// };

	const handleMouseEnter = () => {
		if (!showDayPlanDrawer && view !== 'chat_box') {
			setIsHovered(true);
		}
	};

	const handleMouseLeave = () => {
		if (!showDayPlanDrawer) {
			setIsHovered(false);
		}
	};

	const showTimeOrDescription = () => {
		if (showTime) {
			return getTimeString({ timeStart, timeEnd });
		}
		if (!showDetails) {
			return description || gMapsData[placeName]?.[0]?.[0]?.description;
		}
		return null;
	};

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className={`${styles.card} ${borderClass} w-full`}
			style={{
				paddingRight: marginRequired && '8px',
				marginTop: marginRequired && '16px',
				backgroundColor: highlightCardForUserEducation && '#f0ecfc',
			}}
		>
			<div
				role="presentation"
				className={`relative flex w-full gap-2 rounded-lg bg-white ${styles.card_content}`}
				style={{
					backgroundColor: highlightCardForUserEducation && '#f0ecfc',
				}}
			>
				{view === 'chat_box' ? (
					<div
						style={{
							width: '22px',
							height: '22px',
							boxShadow: '0px 3.207px 5.346px 0px rgba(0, 0, 0, 0.12)',
							borderRadius: '50%',
						}}
						onClick={onClickRemoveFromAttach}
						role="presentation"
						className="absolute top-0 right-[-4px] transform translate-y-[-50%] translate-x-[40%] bg-white"
					>
						<ClearRoundedIcon fontSize="20px" />
					</div>
				) : null}

				{usePhoto && photoUrl ? (
					<ImageLoader
						gMapsData={gMapsData}
						placeName={placeName}
						onError={() => setUsePhoto(true)}
						height="80px"
						width="80px"
					/>
				) : (
					<div
						className="flex h-20 w-20 items-center justify-center"
						style={{ backgroundColor: 'rgba(48, 19, 69, 0.04)' }}
					>
						{TransitionIcon && (
							<TransitionIcon
								fontSize="large"
								className="self-center h-8 w-8"
								width="40px"
								height="40px"
								applyActualWidth
							/>
						)}
					</div>
				)}
				<div
					className="flex flex-col flex-1 px-1 text-start gap-2 m-auto py-2"
					style={{ maxHeight: '80px' }}
				>
					<div
						className="flex items-center justify-between w-full gap-2"
						style={{ minHeight: '22px' }}
					>
						<div className={styles.main_heading}>
							{routeInfo
								? `${startAddressFirstLine} to ${endAddressFirstLine}`
								: name}
						</div>

						<div className="flex items-center gap-3">
							<TagToShow
								highlightCard={highlightCard}
								view={view}
								hasNewTag={hasNewTag}
								hasInspiredTag={hasInspiredTag}
								highlightCardDescription={userRequestedDescription}
								newTagLabel={
									userRequested === 'GENERAL'
										? userRequestedDescription || 'PREFERENCE'
										: null
								}
							/>

							{isHovered && view !== 'chat_box' && sparkEnabled && (
								<div
									className="text-white text-xs rounded"
									role="presentation"
									onClick={() => onAttachActivity(dayCardData, dayIndex, index)}
								>
									<TooltipWrapper tooltipContent="Attach this activity">
										<AISparkIconItinerary />
									</TooltipWrapper>
								</div>
							)}
						</div>
					</div>

					<div className="flex justify-between items-center h-6">
						<div className="flex flex-col">
							{routeInfo ? (
								<div className="text-sm text-secondary_text font-medium flex items-center gap-2">
									<div
										className="font-semibold overflow-hidden whitespace-nowrap"
										style={{
											whiteSpace: 'normal',
											display: '-webkit-box',
											WebkitLineClamp: '1',
											WebkitBoxOrient: 'vertical',
										}}
									>
										{departure_time.text} - {arrival_time.text}
									</div>
									({duration.text})
								</div>
							) : (
								<div
									className="overflow-hidden whitespace-nowrap text-sm text-secondary_text font-medium"
									style={{
										whiteSpace: 'normal',
										display: '-webkit-box',
										WebkitLineClamp: '1',
										WebkitBoxOrient: 'vertical',
									}}
								>
									{(showTime || !showDetails) && showTimeOrDescription()}
								</div>
							)}
							{!showTime && !showDetails && (
								<div
									className={`${styles.description} ${showDetails ? styles.show : ''}`}
								>
									<div className="text-sm text-secondary_text font-medium">
										{description || gMapsData[placeName]?.[0]?.[0]?.description}
									</div>
								</div>
							)}
						</div>

						<div className="flex items-center gap-2">
							{isHovered && showMore && sparkEnabled ? (
								<div
									className="cursor-pointer"
									role="presentation"
									onClick={() => {
										sendMessageEvent({
											event: GoogleEventTypes.ITINERARY_ACTIVITY_CARD_MORE_INFO,
											chat_session_id: chatSessionId,
										});

										setShowDayPlanDrawer((prev) => !prev);
										setIsHovered(false);
									}}
								>
									<EditPencil size="18" style={{ color: '#6537ef' }} />
								</div>
							) : null}

							{placeIndex > -1 ? <MapPointer number={placeIndex + 1} /> : null}
						</div>
					</div>
				</div>
			</div>

			<Drawer
				anchor="right"
				open={showDayPlanDrawer}
				onClose={() => setShowDayPlanDrawer(false)}
				PaperProps={{
					sx: { width: 'calc(30% - 22px)', minWidth: '300px' },
				}}
			>
				<div
					className={`${styles.drawerContent} ${animating ? styles.animateOut : styles.animateIn}`}
				>
					{/* {showEditComponent ? (
						<EditActivity handleToggleEditActivity={handleToggleEditActivity} />
					) : ( */}
					<SingleItineraryDetails
						dayCardData={dayCardData}
						gMapsData={gMapsData[placeName]?.[0]?.[0]}
						onClose={() => setShowDayPlanDrawer(false)}
						onAttachActivity={() =>
							onAttachActivity(dayCardData, dayIndex, index)
						}
						dayIndex={dayIndex}
						index={index}
						TransitionIcon={TransitionIcon}
						// handleSelectEditActivity={handleToggleEditActivity}
					/>
					{/* )} */}
				</div>
			</Drawer>

			<div
				className={`${styles.card_details} ${showDetails ? styles.show : ''}`}
			>
				<div className="text-sm text-secondary_text font-medium">
					{description || gMapsData[placeName]?.[0]?.[0]?.description}
				</div>
			</div>
		</div>
	);
}

export default ItinerarySingleDayCard;

import React, { useState } from 'react';
import { DateRangePicker, DefinedRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

function CustomDateRangePicker({ onChange }) {
	const [state, setState] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const handleSelect = (ranges) => {
		const { startDate, endDate } = ranges.selection;
		setState([ranges.selection]);
		onChange({
			startDate,
			endDate,
		});
	};

	return (
		<div
			style={{
				boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
				width: 'fit-content',
				borderRadius: '8px',
			}}
		>
			<DateRangePicker
				onChange={handleSelect}
				showSelectionPreview
				moveRangeOnFirstSelection={false}
				months={2}
				ranges={state}
				direction="horizontal"
				renderStaticRangeLabel={() => <DefinedRange />}
			/>
		</div>
	);
}

export default CustomDateRangePicker;

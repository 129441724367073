/* eslint-disable no-inner-declarations */
import { useDispatch, useSelector } from 'react-redux';
import { HotelReviewSummaryStages, InteractionStage } from '@helpers/Enums';
import { parse, Allow } from 'partial-json';
import isEmpty from '@utils/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styles from './styles.module.css';
import AISparkIcon from './icons/AISparkIcon';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const TIME_GAP = 4;

const MAPPING = {
	'mid-report': HotelReviewSummaryStages.MID_REPORT_LOADED,
	report: HotelReviewSummaryStages.REPORT,
};

function handleResponsePostReportImpl({
	response,
	dispatch,
	currentLoc,
	setIsTimerActive = () => {},
	setSeconds = () => {},
}) {
	const { MODE, PAYLOAD } = response;

	dispatch({
		type: 'UPDATE_HOTEL_REVIEW_SUMMARY_LOADING_STATE',
		payload: {
			index: currentLoc,
			data: {
				state: MAPPING[MODE],
			},
		},
	});

	dispatch({
		type: 'UPDATE_HOTEL_REVIEW_SUMMARY',
		payload: PAYLOAD,
	});

	if (MODE === HotelReviewSummaryStages.REPORT) {
		setIsTimerActive(false);
		setSeconds(0);
	}
}

function handleResponsePostReport({
	response,
	dispatch,
	currentLoc,
	setShowToast = () => {},
	setIsTimerActive = () => {},
	setShowLoadingtexts = () => {},
	setSeconds = () => {},
}) {
	try {
		handleResponsePostReportImpl({
			response,
			dispatch,
			currentLoc,
			setIsTimerActive,
			setSeconds,
		});
	} catch (error) {
		console.error('Caught error in handleResponsePostReport:', error);
		setShowToast(true);
		setIsTimerActive(false);
		setShowLoadingtexts(false);
		setSeconds(0);
		dispatch({
			type: 'UPDATE_HOTEL_REVIEW_SUMMARY_LOADING_STATE',
			payload: {
				index: currentLoc,
				data: {
					state: HotelReviewSummaryStages.ERROR,
				},
			},
		});
	}
}

const handleStreamResponsePostReport = ({
	response,
	dispatch,
	currentLoc,
	setShowToast = () => {},
	setIsTimerActive = () => {},
	setSeconds = () => {},
	setShowLoadingtexts = () => {},
}) => {
	if (response.ok) {
		const reader = response.body.getReader();
		let accumulatedChunks = '';
		let completeJSONObjectsHandled = 0;

		function readChunk() {
			reader.read().then(({ done, value }) => {
				if (done) {
					return;
				}

				const chunkText = new TextDecoder().decode(value);
				if (chunkText === 'ERROR') {
					setShowToast(true);
					setShowLoadingtexts(false);
					setIsTimerActive(false);
					setSeconds(0);
					dispatch({
						type: 'UPDATE_HOTEL_REVIEW_SUMMARY_LOADING_STATE',
						payload: {
							index: currentLoc,
							data: {
								state: HotelReviewSummaryStages.ERROR,
							},
						},
					});
					return;
				}
				accumulatedChunks += chunkText;
				const parsedJSON = parse(accumulatedChunks, Allow.ARR);
				if (parsedJSON && parsedJSON.length > completeJSONObjectsHandled) {
					for (
						let i = completeJSONObjectsHandled;
						i < parsedJSON.length;
						i += 1
					) {
						handleResponsePostReport({
							response: parsedJSON[i],
							dispatch,
							currentLoc,
							setShowToast,
							setIsTimerActive,
							setSeconds,
							setShowLoadingtexts,
						});
					}
					completeJSONObjectsHandled = parsedJSON.length;
				}
				readChunk(); // Recursively read the next chunk
			});
		}

		readChunk(); // Start reading chunks
	}
};

function HelpMeChoose({
	firstSixHotelsWithPlaceName,
	currentLoc,
	setIsFinalLoadingCompletedForReviews = () => {},
}) {
	const dispatch = useDispatch();

	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const hotelReviewSummaryLoadingState = useSelector(
		(state) => state.ui_states.hotelReviewSummaryLoadingState,
	);

	const [showToast, setShowToast] = useState(false);
	const [showLoadingtexts, setShowLoadingtexts] = useState(false);
	const [seconds, setSeconds] = useState(0);
	const [isTimerActive, setIsTimerActive] = useState(false);

	const handleGetReviewSummary = () => {
		setIsTimerActive(true);
		setShowLoadingtexts(true);

		if (isEmpty(hotelReviewSummaryLoadingState)) {
			dispatch({
				type: 'UPDATE_COMPLETE_HOTEL_REVIEW_SUMMARY_LOADING_STATE',
				payload: Array(firstSixHotelsWithPlaceName.length).fill({
					state: HotelReviewSummaryStages.INIT,
				}),
			});
		}

		dispatch({
			type: 'UPDATE_HOTEL_REVIEW_SUMMARY_LOADING_STATE',
			payload: {
				index: currentLoc,
				data: {
					state: HotelReviewSummaryStages.LOADING,
				},
			},
		});

		const postFormData = new FormData();

		postFormData.append('interaction_stage', InteractionStage.POST_REPORT);
		postFormData.append('chat_session_id', chatSessionId);
		postFormData.append(
			'hotels_data',
			JSON.stringify(firstSixHotelsWithPlaceName[currentLoc]),
		);

		fetch(`${backendURL}/api/hotel_review_summary`, {
			method: 'POST',
			body: postFormData,
			timeout: 10000,
		})
			.then((response) => {
				handleStreamResponsePostReport({
					response,
					dispatch,
					currentLoc,
					setShowToast,
					setIsTimerActive,
					setSeconds,
					setShowLoadingtexts,
				});
			})
			.catch((error) => {
				console.log('error', error);
				setShowToast(true);
				setIsTimerActive(false);
				setShowLoadingtexts(false);
				setSeconds(0);

				dispatch({
					type: 'UPDATE_HOTEL_REVIEW_SUMMARY_LOADING_STATE',
					payload: {
						index: currentLoc,
						data: {
							state: HotelReviewSummaryStages.ERROR,
						},
					},
				});
			});
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowToast(false);
	};

	const currLoadingState = hotelReviewSummaryLoadingState?.[currentLoc]?.state;

	const loadingTextArray = useMemo(
		() => [
			{
				text: 'Searching the web...',
				show:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) > 1,
				completed:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) >
						2 || seconds > TIME_GAP * 1 - 1,
				id: 1,
			},
			{
				text: 'Extracting hotel data...',
				show:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) > 1,
				completed:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) >
						2 || seconds > TIME_GAP * 2 - 1,
				id: 2,
			},
			{
				text: 'Summarizing guest reviews...',
				show:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) > 1,
				completed:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) >
						2 || seconds > TIME_GAP * 3 - 1,
				id: 3,
			},
			{
				text: 'Inferring highlights for each hotel...',
				show:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) >
						2 || seconds > TIME_GAP * 3 - 1,
				completed:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) >
						3 || seconds > TIME_GAP * 4 - 1,
				id: 4,
			},
			{
				text: 'Collating results...',
				show:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) >
						2 || seconds > TIME_GAP * 4 - 1,
				completed:
					Object.values(HotelReviewSummaryStages).indexOf(currLoadingState) > 3,
				id: 5,
			},
		],
		[currLoadingState, seconds],
	);

	useEffect(() => {
		if (
			[
				HotelReviewSummaryStages.REPORT,
				HotelReviewSummaryStages.MID_REPORT_LOADED,
			].includes(currLoadingState)
		) {
			setIsFinalLoadingCompletedForReviews(true);
		} else {
			setIsFinalLoadingCompletedForReviews(false);
		}

		if (HotelReviewSummaryStages.REPORT === currLoadingState) {
			setTimeout(() => {
				setShowLoadingtexts(false);
			}, 2000);
		}
	}, [currLoadingState, currentLoc]);

	useEffect(() => {
		let interval = null;
		if (isTimerActive) {
			interval = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds + 1);
			}, 1000);
		} else if (!isTimerActive && seconds !== 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [isTimerActive, seconds]);

	if (
		currLoadingState === HotelReviewSummaryStages.REPORT &&
		!showLoadingtexts
	) {
		return (
			<div className={`${styles.container} flex items-center justify-between`}>
				<div className="flex gap-3 items-center">
					<AISparkIcon />
					<div className={styles.button_text}>Help Me Choose</div>
				</div>

				<div
					role="presentation"
					className="flex gap-6 items-center cursor-pointer"
					onClick={() => setShowLoadingtexts(true)}
				>
					<div className="flex items-center gap-3" style={{ color: 'green' }}>
						Done!
						{/* <DoneIcon
							fontSize="small"
							style={{ color: 'green', marginBottom: '1px' }}
						/> */}
					</div>

					<ExpandMoreIcon style={{ color: '#DC1F90' }} />
				</div>
			</div>
		);
	}

	if (
		[
			HotelReviewSummaryStages.MID_REPORT_LOADED,
			HotelReviewSummaryStages.REPORT,
			HotelReviewSummaryStages.LOADING,
		].includes(currLoadingState)
	) {
		return (
			<div className={styles.container}>
				<div
					className="flex items-center justify-between pb-3"
					style={{ borderBottom: '1px solid #36174d28' }}
				>
					<div className="flex gap-3 items-center">
						<AISparkIcon color="#DC1F90" />
						<div className={styles.button_text}>Help Me Choose</div>
					</div>

					{currLoadingState === HotelReviewSummaryStages.REPORT ? (
						<ExpandLessIcon
							className="cursor-pointer"
							onClick={() => setShowLoadingtexts(false)}
							style={{ color: '#DC1F90' }}
						/>
					) : null}
				</div>

				<div
					className="pt-3 flex flex-col gap-2"
					style={{ color: '#574068', fontWeight: '400', opacity: '0.8' }}
				>
					{loadingTextArray.map(({ text, show, completed, id }) => {
						if (!show) {
							return null;
						}

						return (
							<div key={id} className="flex justify-between items-center">
								<div>{text}</div>

								{completed ? (
									<DoneIcon fontSize="small" style={{ color: 'green' }} />
								) : null}
							</div>
						);
					})}
				</div>

				{HotelReviewSummaryStages.REPORT === currLoadingState ? (
					<div style={{ color: 'green', marginTop: '16px' }}>Done!</div>
				) : null}
			</div>
		);
	}

	return (
		<div>
			<button
				type="button"
				className={styles.help_me_choose_button}
				onClick={handleGetReviewSummary}
			>
				<AISparkIcon color="#DC1F90" />
				<div className={styles.button_text}>Help Me Choose</div>
			</button>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showToast}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					severity="error"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Unable to proceed. Please try again!
				</Alert>
			</Snackbar>
		</div>
	);
}

export default HelpMeChoose;

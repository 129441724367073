import AddCityToTrip from './AddCityToTrip';
import EditCityOfTrip from './EditCityOfTrip';
import EditTransitComponent from './EditTransit/EditTransitComponent';

const MAPPING = {
	add_city: AddCityToTrip,
	edit_city: EditCityOfTrip,
	edit_transit: EditTransitComponent,
};

const TYPES_POSSIBLE = Object.keys(MAPPING);

function AddOrEditCityDrawer({
	setEditDetails = () => {},
	setTripDetails = () => {},
	editDetails = {},
	tripDetails = [],
	setTripModificationDetails = () => {},
	localActiveTabIndex,
}) {
	const { type = '', index = 0 } = editDetails || {};

	if (!TYPES_POSSIBLE.includes(type)) {
		return null;
	}

	const ActiveComponent = MAPPING[type];

	return (
		<div style={{ padding: '24px 28px', width: '500px' }}>
			<ActiveComponent
				setEditDetails={setEditDetails}
				index={index}
				setTripDetails={setTripDetails}
				tripDetails={tripDetails}
				setTripModificationDetails={setTripModificationDetails}
				localActiveTabIndex={localActiveTabIndex}
			/>
		</div>
	);
}

export default AddOrEditCityDrawer;

export class ItineraryDayCardsData {
	constructor(
		name,
		description,
		placeName,
		timeStart = null,
		timeEnd = null,
		hasNewTag = false,
		transport = null,
		type = '',
		coordinates,
		userRequested = null,
		userRequestedDescription = null,
		routeInfo = null,
	) {
		this.name = name;
		this.description = description;
		this.placeName = placeName;
		this.timeStart = timeStart;
		this.timeEnd = timeEnd;
		this.hasNewTag = hasNewTag;
		this.transport = transport;
		this.type = type;
		this.coordinates = coordinates;
		this.hasNewTag = hasNewTag;
		this.userRequested = userRequested;
		this.userRequestedDescription = userRequestedDescription;
		this.routeInfo = routeInfo;
	}
}

export function convertActivityToItineraryDayCardsData(activity) {
	const {
		DESCRIPTION = '',
		NAME = '',
		PLACE_NAME = '',
		TIME_START = '',
		TIME_END = '',
		TRANSPORT = null,
		TYPE = '',
		USER_REQUESTED = null,
		GENERAL_DESCRIPTION = null,
		SPECIFIC_DESCRIPTION = null,
		COORDINATES,
		hasNewTag = false,
		ROUTE_INFO = null,
	} = activity;
	return new ItineraryDayCardsData(
		NAME,
		DESCRIPTION,
		PLACE_NAME,
		TIME_START,
		TIME_END,
		hasNewTag,
		TRANSPORT,
		TYPE,
		COORDINATES,
		USER_REQUESTED,
		GENERAL_DESCRIPTION || SPECIFIC_DESCRIPTION,
		ROUTE_INFO,
	);
}

export function convertScheduleToItineraryDayCardsData(schedule) {
	schedule = schedule.filter((activity) => activity);
	return schedule.map((activity, index) =>
		convertActivityToItineraryDayCardsData(activity),
	);
}

export function getFormattedGMapsData(places, gMapsData) {
	const formattedGMapsData = {};
	let label = 1;
	places?.forEach((placeName) => {
		if (placeName) {
			const data = gMapsData[placeName]?.[0]?.[0];
			if (data) {
				formattedGMapsData[placeName] = {
					position: { lat: data.latitude, lng: data.longitude },
					name: data.name,
					adr_address: data.full_address,
					types: [data.type],
					rating: data.rating,
					user_ratings_total: data.reviews,
					website: data.site,
					opening_hours: data.working_hours_old_format,
					international_phone_number: data.phone,
					url: data.location_link,
					description: data.description,
					photoUrls: data.photos_data?.map((data) => data?.original_photo_url),
					label: label.toString(),
				};
			}
			label++;
		}
	});
	return formattedGMapsData;
}

export const dedupeItineraryCardsData = (itineraryCardsData, gMapsData) => {
	const dedupedItineraryCardsData = [];
	const seenPlaceNames = new Set();
	const seenGMapsPlaceNames = new Set();
	itineraryCardsData.forEach((card) => {
		const { placeName } = card;
		const googleId = gMapsData[placeName]?.[0]?.[0]?.google_id;
		if (
			!seenPlaceNames.has(placeName) &&
			(googleId == null || !seenGMapsPlaceNames.has(googleId))
		) {
			seenPlaceNames.add(placeName);
			seenGMapsPlaceNames.add(googleId);
			dedupedItineraryCardsData.push(card);
		}
	});
	return dedupedItineraryCardsData;
};

export const getAllPlacesDataFromContentPayloads = (
	contentPayLoads,
	gMapsData,
) => {
	if (!contentPayLoads) {
		return [];
	}

	const allPlacesData = [];
	Object.entries(contentPayLoads).forEach(([, payload]) => {
		const { summary = {} } = payload;
		const { places = [] } = summary;

		const placesData = places.map((item) => {
			const { place = '', description = '', one_liner = '' } = item;
			return new ItineraryDayCardsData(place, description || one_liner, place);
		});

		allPlacesData.push(...placesData);
	});

	return dedupeItineraryCardsData(allPlacesData, gMapsData);
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from 'react-redux';
import AISparkIcon from '../HotelCard/icons/AISparkIcon';
import styles from './styles.module.css';

function Accordion({
	hotelReviewSummary = {},
	isReviewsAccordionOpen,
	setIsReviewsAccordionOpen,
}) {
	const isChatBoxClosed = useSelector(
		(state) => state.ui_states.isChatBoxClosed,
	);

	const {
		HIGHLIGHT = 'Review summary',
		SHORT_SUMMARY = '',
		NEGATIVE_TAGS = [],
		POSITIVE_TAGS = [],
		REVIEWS_URL = '',
	} = hotelReviewSummary || {};

	const toggleAccordion = () => {
		setIsReviewsAccordionOpen((prev) => !prev);
	};

	return (
		<div
			className={`${styles.accordion} ${isReviewsAccordionOpen ? styles.accordion_open : ''}`}
		>
			<div
				className={styles.header}
				onClick={toggleAccordion}
				role="presentation"
			>
				<div className="flex items-center gap-3" style={{ maxWidth: '75%' }}>
					<div style={{ minWidth: '20px' }}>
						<AISparkIcon />
					</div>
					<h3>{HIGHLIGHT}</h3>
				</div>

				<div className="flex items-center gap-2 text-sm">
					{isChatBoxClosed ? (
						<div className={styles.summary_text}>AI Review Summary</div>
					) : null}
					{isReviewsAccordionOpen ? (
						<ExpandLessIcon style={{ color: '#DC1F90' }} />
					) : (
						<ExpandMoreIcon style={{ color: '#DC1F90' }} />
					)}
				</div>
			</div>
			<div
				className={`${styles.content} ${isReviewsAccordionOpen ? styles.open : ''}`}
			>
				<p>{SHORT_SUMMARY}</p>

				<div className="flex items-center flex-wrap gap-2">
					{(POSITIVE_TAGS || []).map((tag) => {
						return (
							<div
								key={tag}
								className="inline-block px-3 py-1 border border-green-500 rounded-lg bg-green-300"
							>
								{tag}
							</div>
						);
					})}

					{(NEGATIVE_TAGS || []).map((tag) => {
						return (
							<div
								key={tag}
								className="inline-block px-3 py-1 border border-red-500 rounded-lg bg-red-300"
							>
								{tag}
							</div>
						);
					})}

					<a
						href={REVIEWS_URL}
						target="_blank"
						className="ml-4 cursor-pointer text-highlight font-medium"
						rel="noreferrer"
					>
						View All Reviews
					</a>
				</div>
			</div>
		</div>
	);
}

export default Accordion;

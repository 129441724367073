import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './styles.module.css';

function Card({
	details = {},
	isAddLocationCard = false,
	isCityAdded = false,
	setItems = () => {},
	setAlternatives = () => {},
	noRemoveOption = false,
	places = [],
}) {
	const {
		placeName: displayName,
		name,
		photos,
		reason = '',
		newlyAdded = false,
	} = details;

	const imageUrl = photos?.[0]?.url ?? photos?.[0]?.getUrl?.();

	return (
		<div
			className="flex flex-col justify-between rounded-lg cursor-pointer pl-2 pb-2 pr-2 pt-3"
			style={{
				height: '156px',
				...(!isAddLocationCard && isCityAdded
					? {
							background:
								'linear-gradient(white, white) padding-box, linear-gradient(to right, #DC1F90, #BAA7F2) border-box',
							border: '1px solid transparent',
						}
					: { border: '1px solid #BAA7F2' }),
			}}
		>
			<div
				style={{
					color: '#301345',
					fontSize: '16px',
					fontWeight: '600',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div className={styles.main_heading}>{displayName}</div>

				{isAddLocationCard ? (
					<AddRoundedIcon
						fontSize="small"
						style={{ color: isCityAdded ? '#292D32' : '#7750E5' }}
						onClick={() => {
							setItems([...places, details]);
							setAlternatives((prev) =>
								prev.filter(({ name: currName }) => currName !== name),
							);
						}}
					/>
				) : null}

				{!isAddLocationCard && !noRemoveOption ? (
					<RemoveIcon
						fontSize="small"
						style={{ color: isCityAdded ? '#292D32' : '#7750E5' }}
						onClick={() => {
							setItems(
								places.filter(({ name: currName }) => currName !== name),
							);
							setAlternatives((prev) => [...prev, details]);
						}}
					/>
				) : null}
			</div>
			{!reason && !newlyAdded ? null : (
				<div
					style={{
						color: '#6C577A',
						fontSize: '12px',
						fontWeight: '400',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						maxWidth: '200px',
						whiteSpace: 'nowrap',
					}}
				>
					{newlyAdded ? 'Newly Added' : reason}
				</div>
			)}

			{imageUrl ? (
				<img
					src={imageUrl}
					alt="location_image"
					style={{
						height: '84px',
						width: '100%',
						objectFit: 'cover',
						borderRadius: '8px',
						maxWidth: 'none',
					}}
				/>
			) : null}
		</div>
	);
}

export default Card;

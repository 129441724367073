function AiRialIcon() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="16" cy="16" r="16" fill="#7750E5" />
			<g clipPath="url(#clip0_997_16094)">
				<path
					d="M23.998 15.9854L19.5047 18.432C19.058 18.6787 18.6914 19.0454 18.4447 19.492L16.0114 23.9987L13.5647 19.5054C13.318 19.0587 12.9514 18.692 12.5047 18.4454L7.99805 16.012L12.4914 13.5654C12.938 13.3187 13.3047 12.952 13.5514 12.5054L15.9847 7.99869L18.4314 12.492C18.678 12.9387 19.0447 13.3054 19.4914 13.552L23.9914 15.9854H23.998Z"
					fill="white"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M23.9983 10.132L22.7983 10.7854C22.6783 10.852 22.5783 10.952 22.5183 11.0654L21.8716 12.2654L21.2183 11.0654C21.1516 10.9454 21.0516 10.8454 20.9383 10.7854L19.7383 10.1387L20.9383 9.48537C21.0583 9.4187 21.1583 9.3187 21.2183 9.20537L21.8649 8.00537L22.5183 9.20537C22.5849 9.32537 22.6783 9.42537 22.7983 9.48537L23.9983 10.132Z"
					fill="white"
					stroke="white"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M22.666 20.9971L22.1026 21.3039C22.0463 21.3352 21.9993 21.3821 21.9712 21.4353L21.6676 21.9987L21.3609 21.4353C21.3296 21.379 21.2826 21.332 21.2294 21.3039L20.666 21.0003L21.2294 20.6935C21.2857 20.6622 21.3327 20.6153 21.3609 20.5621L21.6645 19.9987L21.9712 20.5621C22.0025 20.6184 22.0463 20.6654 22.1026 20.6935L22.666 20.9971Z"
					fill="white"
					stroke="white"
					strokeWidth="0.333333"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_997_16094">
					<rect
						width="17"
						height="17"
						fill="white"
						transform="translate(7.49805 7.49869)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default AiRialIcon;

import { useState } from 'react';
import { InspirationDrawerSource } from '@helpers/Enums';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useSelector } from 'react-redux';
import AddInspirationPage from './AddInspirationPage';
import ViewInspiration from './ViewInspiration';
import InspirationItinerary from './InspirationItinerary';

const PAGE = {
	ADD_INSPIRATION: 'add_inspiration',
	VIEW_INSPIRATION: 'view_inspiration',
	INSPIRATION_ITINERARY: 'inspiration_itinerary',
};

function InspirationPanel({ source, onClose }) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const [currentPage, setCurrentPage] = useState(PAGE.ADD_INSPIRATION);
	const [contentIds, setContentIds] = useState(null);
	const [viewInspirationState, setViewInspirationState] = useState({});
	const [addInspirationState, setAddInspirationState] = useState({});
	const [inspirationData, setInspirationData] = useState({});

	const onContentsSelected = (uuids) => {
		setContentIds(uuids);
		goToViewInspiration();
	};

	const goToViewInspiration = () => {
		setViewInspirationState({});
		setCurrentPage(PAGE.VIEW_INSPIRATION);

		let event = GoogleEventTypes.OVERVIEW_PAGE_VIEW_INSPIRATION;
		if (source === InspirationDrawerSource.LANDING_PAGE) {
			event = GoogleEventTypes.LANDING_PAGE_VIEW_INSPIRATION;
		}
		sendMessageEvent({
			event,
			chat_session_id: chatSessionId,
		});
	};

	const backToViewInspiration = () => {
		setCurrentPage(PAGE.VIEW_INSPIRATION);
	};

	const onViewInspirationNextClicked = (data) => {
		if (source === InspirationDrawerSource.LANDING_PAGE) {
			sendMessageEvent({
				event: GoogleEventTypes.LANDING_PAGE_INSPIRATION_CONFIRMED,
				chat_session_id: chatSessionId,
			});
			onClose(data);
		} else {
			sendMessageEvent({
				event: GoogleEventTypes.OVERVIEW_PAGE_NEW_ITINERARY,
				chat_session_id: chatSessionId,
			});
			goToInspirationItinerary(data);
		}
	};

	const goToInspirationItinerary = (data) => {
		setInspirationData(data);
		setCurrentPage(PAGE.INSPIRATION_ITINERARY);
	};

	if (currentPage === PAGE.ADD_INSPIRATION) {
		return (
			<AddInspirationPage
				onContentsSelected={onContentsSelected}
				onBack={onClose}
				onAddInspirationStateChange={setAddInspirationState}
				addInspirationState={addInspirationState}
				source={source}
			/>
		);
	}
	if (currentPage === PAGE.VIEW_INSPIRATION) {
		return (
			<ViewInspiration
				initialContentIds={contentIds}
				onBack={() => setCurrentPage(PAGE.ADD_INSPIRATION)}
				onNext={onViewInspirationNextClicked}
				onStateChange={setViewInspirationState}
				state={viewInspirationState}
				source={source}
			/>
		);
	}
	if (currentPage === PAGE.INSPIRATION_ITINERARY) {
		return (
			<InspirationItinerary
				contentIds={contentIds}
				onBack={() => backToViewInspiration()}
				onClose={onClose}
				inspirationData={inspirationData}
			/>
		);
	}
}

export default InspirationPanel;

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { formatDate } from '@helpers/DateUtils';
import { Skeleton } from '@mui/material';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useSelector } from 'react-redux';

function ItineraryHeader({
	activeTab,
	setActiveTab,
	isLast,
	isFirst,
	dayPlan = {},
	numberOfDays = null,
	loadedDays = 1,
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const { DATE, location = '' } = dayPlan;

	const onClickPrevious = () => {
		if (isFirst) {
			return;
		}

		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_PREVIOUS_DAY,
			chat_session_id: chatSessionId,
		});
		setActiveTab(activeTab - 1);
	};

	const onClickNext = () => {
		if (isLast) {
			return;
		}

		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_NEXT_DAY,
			chat_session_id: chatSessionId,
		});
		setActiveTab(activeTab + 1);
	};
	return (
		<div className="flex justify-between">
			<div className="flex flex-col gap-1">
				<div className="flex flex-row space-x-2">
					<div className="font-medium text-secondary_highlight">
						Day {activeTab + 1}
					</div>
					<div>•</div>
					{DATE ? (
						<div>{formatDate(DATE, 'MMM dd')}</div>
					) : (
						<Skeleton width="80px" />
					)}
				</div>
				{location ? (
					<div className="font-medium">{location}</div>
				) : (
					<Skeleton
						width="144px"
						height={32}
						style={{ marginBottom: '-8px' }}
					/>
				)}
			</div>

			<div className="flex flex-col justify-between items-end gap-2">
				<div className="flex gap-4">
					<div
						role="presentation"
						onClick={onClickPrevious}
						style={{
							border: '1px solid #7750E5',
							borderRadius: '50%',
							width: '20px',
							height: '20px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
							opacity: isFirst ? 0.64 : 1,
						}}
					>
						<ArrowBackIosIcon
							style={{ color: '#7750E5', marginLeft: '5px', width: '12px' }}
						/>
					</div>

					<div
						role="presentation"
						onClick={onClickNext}
						style={{
							border: '1px solid #7750E5',
							borderRadius: '50%',
							width: '20px',
							height: '20px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
							opacity: isLast ? 0.64 : 1,
						}}
					>
						<ArrowForwardIosIcon
							style={{ color: '#7750E5', marginLeft: '4px', width: '12px' }}
						/>
					</div>
				</div>

				{numberOfDays && numberOfDays !== loadedDays ? (
					<div className="text-sm" style={{ color: '#7750E5' }}>
						{loadedDays} / {numberOfDays} days loaded
					</div>
				) : null}
			</div>
		</div>
	);
}

export default ItineraryHeader;

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberInputController from '@commons/forms/page-components/controlled/NumberInputController';
import TextInputController from '@commons/forms/page-components/controlled/TextInputController';
import dayjs from 'dayjs';
import { useState } from 'react';
import { handleLookupAirports } from '@helpers/ModelApiUtils';
import ToggleSwitchController from '@commons/forms/page-components/controlled/ToggleSwitchController';
import { useSelector } from 'react-redux';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import AutoCompleteLocationSelect from '@commons/forms/page-components/components/AutoCompleteLocationSelect';
import axios from 'axios';
import styles from './styles.module.css';

function decrementDays(dateString, noOfDays) {
	let date = dayjs(dateString);
	date = date.subtract(noOfDays, 'day');

	return date.format('YYYY-MM-DD');
}

function incrementDays(dateString, noOfDays) {
	let date = dayjs(dateString);
	date = date.add(noOfDays, 'day');

	return date.format('YYYY-MM-DD');
}

const CONTROLLER_MAPPING = {
	text: TextInputController,
	input_number: NumberInputController,
	toggle_switch: ToggleSwitchController,
	location_select: AutoCompleteLocationSelect,
};

const schema = Yup.object().shape({
	no_of_nights: Yup.string().required('This is required'),
});

function EditCityOfTrip({
	setEditDetails = () => {},
	setTripDetails = () => {},
	tripDetails = [],
	index = 0,
	setTripModificationDetails = () => {},
	localActiveTabIndex = 0,
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const tripDetailsOfDateSet = tripDetails[localActiveTabIndex];

	const { type: locationType = '' } = tripDetailsOfDateSet[index];

	const {
		control,
		formState: { errors },
		handleSubmit = () => {},
	} = useForm({
		defaultValues: {
			no_of_nights: Number(tripDetailsOfDateSet[index].noOfNights),
			hotel_required: !!tripDetailsOfDateSet[index]?.hotelFilters,
		},
		resolver: yupResolver(schema),
	});

	const controls = [
		{
			label: 'Location Name',
			name: 'city_name',
			type: 'location_select',
			show: true,
		},
		{
			label: 'No. of nights',
			name: 'no_of_nights',
			type: 'input_number',
			show: !['start', 'end'].includes(locationType),
		},
		{
			label: 'Hotel Required',
			name: 'hotel_required',
			type: 'toggle_switch',
			show: !['start', 'end'].includes(locationType),
		},
	];

	const [airportError, setAirPortError] = useState({
		error: false,
		message: '',
	});
	const [selectedCityName, setSelectedCityName] = useState(
		tripDetailsOfDateSet[index].location,
	);
	let coordinatesCache = {};

	const handleSelectLocation = async (option) => {
		if (!option) {
			return;
		}

		const { value = '', coordinates } = option || {};
		coordinatesCache = { ...coordinatesCache, [value]: coordinates };
		setSelectedCityName(value);
	};

	const onSubmit = async (values) => {
		sendMessageEvent({
			event: GoogleEventTypes.TRIP_CONTROLS_CONFIRM_EDIT_LOCATION_CHANGES,
			chat_session_id: chatSessionId,
		});

		const { no_of_nights = '', hotel_required = false } = values;

		if (
			hotel_required &&
			!tripDetailsOfDateSet[index]?.hotelFilters &&
			!coordinatesCache[selectedCityName]
		) {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_URL}/api/search_place`,
				{
					params: {
						q: selectedCityName,
						limit: 5,
					},
				},
			);

			if (!response.data.features) {
				setAirPortError({
					error: true,
					message: 'The City you mentioned does not exist',
				});
				return;
			}

			const { coordinates = [] } =
				response?.data?.features?.[0]?.geometry || {};

			coordinatesCache = {
				...coordinatesCache,
				[selectedCityName]: coordinates,
			};
		}

		const isAirportChanged =
			selectedCityName !== tripDetailsOfDateSet[index].location;

		let airportCodes = [];

		if (isAirportChanged) {
			const airportCodesFinal = await handleLookupAirports({
				city_list: [selectedCityName],
			});

			if (!airportCodesFinal.length) {
				setAirPortError({
					error: true,
					message: 'The City you mentioned does not have any airport',
				});
				return;
			}

			setAirPortError({
				error: false,
				message: '',
			});

			airportCodes = airportCodesFinal;
		}

		const noOfNights = Number(no_of_nights);

		const differenceInDays =
			noOfNights - tripDetailsOfDateSet[index].noOfNights;

		if (
			!isAirportChanged &&
			!(hotel_required !== !!tripDetailsOfDateSet[index]?.hotelFilters) &&
			!differenceInDays
		) {
			setEditDetails(null);
			return;
		}

		const updatedTripDetails = tripDetails.map((curTripData, dateIndex) => {
			return curTripData.map((cur, ind) => {
				let { location } = cur;
				let dateOfJourney =
					cur?.travelData?.flightDetails?.dateOfJourney ||
					cur?.travelData?.nonFlightDetails?.dateOfJourney;
				let destination = cur?.travelData?.destination;
				let source = cur?.travelData?.source;
				let nightsCount = cur?.noOfNights;
				let hotelFilters = cur?.hotelFilters;
				let fromAirport = cur?.travelData?.flightDetails?.fromAirport;
				let toAirport = cur?.travelData?.flightDetails?.toAirport;
				let hotelReq = cur?.hotelReq;

				if (ind === index) {
					location = selectedCityName;
					fromAirport = isAirportChanged ? airportCodes : fromAirport;
					source = selectedCityName;
					hotelReq = hotel_required;
				}

				if (ind === index && dateIndex === localActiveTabIndex) {
					nightsCount = noOfNights;
				}

				if (ind === index - 1) {
					destination = selectedCityName;
					toAirport = isAirportChanged ? airportCodes : toAirport;
				}

				if (
					ind === index &&
					(!hotelFilters || selectedCityName !== curTripData[index].location) &&
					hotel_required
				) {
					const [lng, lat] = coordinatesCache[location] || [];

					hotelFilters = {
						NUM_ROOMS: 1,
						MIN_STAR_RATING: -1,
						MAX_PRICE: -1,
						AMENITIES: [],
						LOCALITY: 'N/A',
						COORDINATES_LABEL: source,
						LATITUDE: lat,
						LONGITUDE: lng,
					};
				}

				if (ind === index && hotelFilters && !hotel_required) {
					hotelFilters = null;
				}

				if (ind >= index && dateIndex === localActiveTabIndex) {
					if (differenceInDays > 0) {
						dateOfJourney = incrementDays(dateOfJourney, differenceInDays);
					}

					if (differenceInDays < 0) {
						dateOfJourney = decrementDays(
							dateOfJourney,
							Math.abs(differenceInDays),
						);
					}
				}

				return {
					...cur,
					hotelReq,
					location,
					hotelFilters,
					noOfNights: ['start', 'end'].includes(cur.type)
						? undefined
						: nightsCount,
					travelData:
						cur.type === 'end'
							? null
							: {
									...cur.travelData,
									source,
									destination,
									flightDetails: ['flight', 'FLIGHT'].includes(
										cur.travelData.type,
									)
										? {
												...cur.travelData.flightDetails,
												dateOfJourney,
												fromAirport,
												toAirport,
											}
										: undefined,
									nonFlightDetails: !['flight', 'FLIGHT'].includes(
										cur.travelData.type,
									)
										? {
												...cur.travelData.nonFlightDetails,
												dateOfJourney,
											}
										: undefined,
								},
				};
			});
		});

		const messages = [];

		if (isAirportChanged) {
			messages.push(
				`${tripDetailsOfDateSet[index].location} has been changed to ${selectedCityName}!`,
			);
		}

		if (hotel_required !== !!tripDetailsOfDateSet[index]?.hotelFilters) {
			messages.push(
				hotel_required
					? `Hotel has been added at ${selectedCityName}!`
					: `Hotel has been removed at ${selectedCityName}!`,
			);
		}

		setTripDetails(updatedTripDetails);
		setTripModificationDetails((prev) => ({
			...prev,
			modified: true,
			...(isAirportChanged || differenceInDays
				? { transportChanged: true }
				: {}),
			messages: [...(prev.messages || []), ...messages],
		}));
		setEditDetails(null);
	};

	return (
		<div
			className="flex flex-col justify-between"
			style={{ height: '90vh', color: '#301345' }}
		>
			<div className="flex flex-col" style={{ gap: '20px' }}>
				<div
					className="flex gap-2 items-center cursor-pointer text-2xl"
					style={{
						borderBottom: '1px solid #E1DEE3',
						paddingBottom: '20px',
					}}
					role="presentation"
					onClick={() => setEditDetails(null)}
				>
					<ArrowBackIosIcon fontSize="small" />

					<div style={{ color: '#301345', fontWeight: 500 }}>
						Edit Destination
					</div>
				</div>

				<div
					className="rounded-lg flex flex-col"
					style={{
						border: '1px solid #7750E5',
						padding: '24px 16px',
					}}
				>
					{controls.map((controlItems) => {
						const { type = '', show, ...restControls } = controlItems;

						const { name = '', label = '' } = restControls;
						const ActiveComponent = CONTROLLER_MAPPING[type];

						if (!show) {
							return null;
						}

						if (type === 'location_select') {
							return (
								<div
									className="flex justify-between items-center py-3"
									style={{ height: '72px' }}
								>
									<div className="font-semibold">{label}</div>

									<div style={{ width: '224px' }}>
										<AutoCompleteLocationSelect
											handleSelect={handleSelectLocation}
											value={selectedCityName}
										/>
									</div>
								</div>
							);
						}

						if (['no_of_nights', 'hotel_required'].includes(name)) {
							return (
								<div
									className="flex justify-between items-center"
									style={{
										padding: '20px 0',
										borderBottom:
											name === 'no_of_nights' ? '1px solid #E1DEE3' : 'none',
									}}
								>
									<div className="font-semibold">{label}</div>

									<ActiveComponent
										{...restControls}
										control={control}
										error={errors[restControls.name]}
									/>
								</div>
							);
						}

						return (
							<div
								className="flex flex-col gap-2"
								style={{
									padding: '20px 0',
									borderBottom: '1px solid #E1DEE3',
								}}
							>
								<ActiveComponent
									{...restControls}
									control={control}
									error={errors[restControls.name]}
								/>
							</div>
						);
					})}
				</div>

				{airportError.error ? (
					<div style={{ color: '#DC1F41' }}>{airportError.message}</div>
				) : null}
			</div>

			<Button
				variant="contained"
				type="button"
				className={styles.submit_button}
				onClick={handleSubmit(onSubmit)}
				style={{
					textTransform: 'unset',
				}}
			>
				Update Trip
			</Button>
		</div>
	);
}

export default EditCityOfTrip;

import isEmpty from '@utils/isEmpty';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ArrowDownIcon from '@commons/icons/ArrowDownIcon';
import TransitTravelMode from './TransitTravelMode';

function DirectionsOverview({ routeInfo = {} }) {
	if (isEmpty(routeInfo)) {
		return null;
	}

	const { legs = [] } = routeInfo || {};

	const {
		steps = [],
		start_address,
		end_address,
		departure_time,
		arrival_time,
	} = legs[0] || {};

	const [startAddressFirstLine, ...startAddressSecondLine] =
		start_address.split(', ');
	const [endAddressFirstLine, ...endAddressSecondLine] =
		end_address.split(', ');

	if (isEmpty(steps)) {
		return null;
	}

	return (
		<div className="flex flex-col mt-3" style={{ color: '#301345' }}>
			<div className="flex gap-2">
				<div
					style={{ whiteSpace: 'nowrap', width: '72px', minWidth: '72px' }}
					className="flex justify-center"
				>
					{departure_time?.text}
				</div>

				<div className="flex">
					<div
						style={{
							backgroundImage:
								'url(https://maps.gstatic.com/tactile/sidepanel/leg-bullet-2x.png)',
							backgroundPosition: 'left bottom',
							backgroundRepeat: 'repeat-y',
							backgroundSize: '5px 10px',
							minWidth: '10px',
							maxWidth: '10px',
							marginTop: '6px',
						}}
					>
						<img
							className="waypoint-icon"
							src="https://maps.gstatic.com/tactile/directions/text_mode/waypoint-non-last-2x-v3.png"
							alt="icon"
							style={{ marginLeft: '-2px' }}
						/>
					</div>

					<div className="flex flex-col ml-4" style={{ gap: '2px' }}>
						<div className="font-semibold">{startAddressFirstLine}</div>

						<div className="font-normal" style={{ color: '#7F6D8B' }}>
							{startAddressSecondLine.join(', ')}
						</div>
					</div>
				</div>
			</div>

			<div className="flex gap-2">
				<div
					style={{
						height: '21px',
						width: '72px',
						minWidth: '72px',
						marginTop: '-6px',
					}}
					className="flex justify-center"
				/>

				<div className="flex">
					<div
						style={{
							minWidth: '10px',
							maxWidth: '10px',
							height: '21px',
						}}
					>
						<div
							style={{
								backgroundImage:
									'url(https://maps.gstatic.com/tactile/sidepanel/leg-bullet-2x.png)',
								backgroundPosition: 'left bottom',
								backgroundRepeat: 'repeat-y',
								backgroundSize: '5px 10px',
								minWidth: '10px',
								maxWidth: '10px',
								height: '21px',
							}}
						/>
					</div>
				</div>
			</div>

			{steps.map((step) => {
				const {
					travel_mode = '',
					transit = null,
					distance,
					duration,
					transit_details = {},
				} = step || {};

				if (
					['BICYCLING', 'DRIVING', 'WALKING'].includes(travel_mode) &&
					isEmpty(transit || transit_details)
				) {
					return (
						<div className="flex gap-2">
							<div
								className="flex items-center justify-center"
								style={{
									whiteSpace: 'nowrap',
									width: '72px',
									minWidth: '72px',
									marginBottom: '14px',
								}}
							>
								<DirectionsWalkIcon />
							</div>

							<div className="flex w-full">
								<div
									style={{
										backgroundImage:
											'url(https://maps.gstatic.com/tactile/sidepanel/leg-bullet-2x.png)',
										backgroundPosition: 'left top',
										backgroundRepeat: 'repeat-y',
										backgroundSize: '5px 10px',
										minWidth: '10px',
										maxWidth: '10px',
									}}
								/>

								<div
									className="flex flex-col ml-4 py-4 gap-1 w-full mb-5"
									style={{
										borderTop: '1px solid #E3DAFA',
										borderBottom: '1px solid #E3DAFA',
									}}
								>
									<div className="text-lg">Walk</div>
									<div
										className="flex items-center cursor-pointer gap-1"
										role="presentation"
									>
										<ArrowDownIcon style={{ color: '#7F6D8B' }} />

										<div className="text-sm" style={{ color: '#7F6D8B' }}>
											About {duration?.text}, {distance.text}
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				}

				return (
					<TransitTravelMode
						transit_details={transit_details}
						transit={transit}
						distance={distance}
						duration={duration}
					/>
				);
			})}

			<div className="flex gap-2">
				<div
					style={{
						height: '38px',
						width: '72px',
						minWidth: '72px',
						marginTop: '-6px',
					}}
					className="flex justify-center"
				/>

				<div className="flex">
					<div
						style={{
							minWidth: '10px',
							maxWidth: '10px',
							height: '38px',
						}}
					>
						<div
							style={{
								backgroundImage:
									'url(https://maps.gstatic.com/tactile/sidepanel/leg-bullet-2x.png)',
								backgroundPosition: 'left top',
								backgroundRepeat: 'repeat-y',
								backgroundSize: '5px 10px',
								minWidth: '10px',
								maxWidth: '10px',
								height: '38px',
							}}
						/>
					</div>
				</div>
			</div>

			<div className="flex gap-2">
				<div
					style={{
						whiteSpace: 'nowrap',
						width: '72px',
						minWidth: '72px',
						marginTop: '-6px',
					}}
					className="flex justify-center"
				>
					{arrival_time?.text}
				</div>

				<div className="flex">
					<div
						style={{
							minWidth: '10px',
							maxWidth: '10px',
						}}
					>
						<img
							className="waypoint-icon"
							src="https://maps.gstatic.com/tactile/directions/text_mode/waypoint-non-last-2x-v3.png"
							alt="icon"
							style={{ marginLeft: '-2px' }}
						/>
					</div>

					<div
						className="flex flex-col ml-4"
						style={{ gap: '2px', marginTop: '-6px' }}
					>
						<div className="font-semibold">{endAddressFirstLine}</div>

						<div className="font-normal" style={{ color: '#7F6D8B' }}>
							{endAddressSecondLine.join(', ')}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DirectionsOverview;

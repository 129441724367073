const directOverviewState = {
  "ui_states": {
    "pageId": 1,
    "tripTitle": "2-week Spain road trip",
    "currentChatMessage": "",
    "chatMessages": {
      "messages": [
        {
          "sender": "airial",
          "message": "I'm AiRial, your online travel booking assistant. Simply describe your trip and I can help you book all your flights, \n    accommodation and plan your trip conveniently. Where do you want to go?"
        },
        {
          "sender": "user",
          "message": "Plan a 2-week Spain trip for me and 3 friends."
        },
        {
          "sender": "airial",
          "message": "Spain is an incredible destination with its vibrant culture, delicious cuisine, and stunning architecture. When would you and your friends like to take this 2-week trip? Having a rough timeframe will help me plan the perfect itinerary.",
          "STAGE": "pre_clarification_widget"
        },
        {
          "sender": "user",
          "message": "This should be in September, starting on a Saturday. I want to spend a week visiting coastal towns in southern Spain and 3 days each in Barcelona and Madrid. I will have a car in Spain and will stay with a friend in Barcelona.",
          "attachment": {}
        },
        {
          "sender": "airial",
          "message": "Spain is a treasure trove of experiences. Please answer the following questions to help me tailor your trip to your preferences.",
          "widget": {
            "widgetType": "preferences_widget",
            "widgetData": {
              "CONTENT": [
                "What are your main interests for the Spain trip? :: Beaches :: Cultural landmarks :: Food and wine :: Outdoor activities :: Nightlife :: Other (specify)",
                "Anything else I should know?"
              ],
              "TYPE": "clarification_widget"
            },
            "shouldShow": false
          },
          "STAGE": "pre_location_confirmation_widget"
        },
        {
          "sender": "user",
          "message": "Trip Preferences Submitted!",
          "widget": {
            "widgetType": "preferences_widget",
            "widgetData": {
              "CONTENT": [
                "What are your main interests for the Spain trip? :: Beaches :: Cultural landmarks :: Food and wine :: Outdoor activities :: Nightlife :: Other (specify)",
                "Anything else I should know?"
              ],
              "TYPE": "clarification_widget"
            },
            "shouldShow": true
          }
        },
        {
          "sender": "airial",
          "message": "Please confirm or edit these locations that I picked out for you, click on generate and watch your personalized dream trip come to life!",
          "widget": {
            "widgetType": "location_widget",
            "widgetData": {
              "recommendedPlaces": [
                {
                  "source": "adobe_stock",
                  "placeName": "Marbella, Spain",
                  "subtitle": "Marbella, Spain",
                  "id": "84f1eda1-a102-4657-a9a3-141c49230ea5",
                  "name": "Marbella, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/04/61/88/44/240_F_461884428_7esm5kuQ4WfQLuh1HG9w7RVHvbPThTYw.jpg",
                      "title": "Aerial panoramic view of Puerto Banus marina with luxury yachts, Marbella, Spain",
                      "placeName": "Marbella, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/00/51/05/240_F_510533_l6pqVWBLVfgCM1OYDEtYYiEtP4wx70.jpg",
                      "title": "marbella arch in san pedro in spain",
                      "placeName": "Marbella, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/24/25/66/240_F_224256612_1RKuEPIyjjUBtHU4OhzWbnf0IiWiZYhR.jpg",
                      "title": "Puerto Banus Marina",
                      "placeName": "Marbella, Spain"
                    }
                  ],
                  "reason": "Coastal town"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Malaga, Spain",
                  "subtitle": "Malaga, Spain",
                  "id": "213ebbc3-a0b1-42b9-85c3-456832c1b39d",
                  "name": "Malaga, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/17/59/80/240_F_117598013_o6oihHtCfzNi5fOvUjrFmS83pKHMISmx.jpg",
                      "title": "Malaga, Spain Old Town Skyline",
                      "placeName": "Malaga, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/14/53/42/240_F_114534203_jH4MIeGCXpJxLIFNDKdIyhOmr0ysAawk.jpg",
                      "title": "Malaga in Andalusia, Spain",
                      "placeName": "Malaga, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/38/36/20/240_F_138362042_sqhEyI1IAMo97FqT1U0D6DPHxhjIopxX.jpg",
                      "title": "Cityscape of Malaga city. Bull Ring, Plaza la Malagueta, Andalusia, Spain",
                      "placeName": "Malaga, Spain"
                    }
                  ],
                  "reason": "Coastal town"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Barcelona, Spain",
                  "subtitle": "Barcelona, Spain",
                  "id": "1314295e-0ec3-45d4-a3a1-e2d2a909b716",
                  "name": "Barcelona, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/47/26/25/240_F_47262519_JBji4zbizuOybaNXaBFAlYyawW3zx066.jpg",
                      "title": "Park Guell in Barcelona, Spain",
                      "placeName": "Barcelona, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/57/06/78/240_F_257067850_JWnLNo00M2XPZkJDuM3dkA1PYO6TaLVj.jpg",
                      "title": "View of Park Geull at sunset",
                      "placeName": "Barcelona, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/03/11/13/41/240_F_311134116_5nQvEcDX8e10K40ipHqcFm9zXgoA7TI2.jpg",
                      "title": "BARCELONA, SPAIN - SEPTEMBER 15,2015 :  Sagrada Familia  in  Barcelona. Sagrada  - the most known the buildings created by Antoni Gaudi.",
                      "placeName": "Barcelona, Spain"
                    }
                  ],
                  "reason": "You specified this"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Madrid, Spain",
                  "subtitle": "Madrid, Spain",
                  "id": "f6870915-89e9-4190-9d5b-2a15e90a9adb",
                  "name": "Madrid, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/02/29/52/63/240_F_229526382_6ZoOxMUlkB4Vniw6xkURNHPzFGOH6SH3.jpg",
                      "title": "Madrid city skyline gran via street twilight , Spain",
                      "placeName": "Madrid, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/03/18/15/240_F_103181516_8GJmbgeG3mkTrPfXQ15g7nc2mHYhIXaG.jpg",
                      "title": "Madrid, Spain cityscape at Calle de Alcala and Gran Via.",
                      "placeName": "Madrid, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/04/15/61/06/240_F_415610620_fFvrstpdhv7dLGI8V9Lch0XtWbPIz8dV.jpg",
                      "title": "Madrid Spain, sunrise city skyline at Cibeles Fountain Town Square",
                      "placeName": "Madrid, Spain"
                    }
                  ],
                  "reason": "You specified this"
                }
              ],
              "alternativePlaces": [
                {
                  "source": "adobe_stock",
                  "placeName": "Seville, Spain",
                  "subtitle": "Seville, Spain",
                  "id": "193eb549-84cb-4b7b-aa0b-aa1c87fb3b3b",
                  "name": "Seville, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/15/13/44/240_F_115134473_7dArpywLvq8sFEsyLEhK197RQchgJy0H.jpg",
                      "title": "Plaza de Espana (Spain square) in Seville, Andalusia",
                      "placeName": "Seville, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/12/60/42/240_F_112604266_DDKRYfj8EXC2SoFrB70Yam1N2S07EymI.jpg",
                      "title": "Spain Square (Plaza de Espana). Seville, Spain.",
                      "placeName": "Seville, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/03/05/31/08/240_F_305310830_P0qC5nCoWlcKcmy1H8YhYFevJmGTbyvq.jpg",
                      "title": "Scenes from Seville Spain",
                      "placeName": "Seville, Spain"
                    }
                  ],
                  "reason": "Cultural landmarks"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Valencia, Spain",
                  "subtitle": "Valencia, Spain",
                  "id": "d65025ff-9545-4548-9eb9-291441b17619",
                  "name": "Valencia, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/03/22/34/88/240_F_322348885_iPMfhiDfhUimz9Gnedo7N2ueg25qH8Kq.jpg",
                      "title": "Panoramic view of Plaza de la Virgen (Square of Virgin Saint Mary) and Valencia old town",
                      "placeName": "Valencia, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/34/38/57/240_F_134385787_JTMFqkusN3g4AN6S1duUjKqk71eClxGv.jpg",
                      "title": "Spain. Valencia,  Peniscola. View of the sea from a height of Pope Luna's Castle. The medieval castle of the Knights Templar on the beach. Beautiful view of the sea and the bay. Mediterranean Sea. ",
                      "placeName": "Valencia, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/32/16/24/240_F_132162400_10zwPUhBncmcSQ8mPWOQ2ojzWUrcc1RU.jpg",
                      "title": "Valencia Spain Virgin Square",
                      "placeName": "Valencia, Spain"
                    }
                  ],
                  "reason": "Beaches"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Cadiz, Spain",
                  "subtitle": "Cadiz, Spain",
                  "id": "8f6c0f83-d30a-44c6-99ee-563026316160",
                  "name": "Cadiz, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/60/82/70/240_F_160827005_l7qpL4zfNkIlIAdzrP7o53GcIl4oUwi6.jpg",
                      "title": "Aerial panoramic view of the old city rooftops and Cathedral de Santa Cruz in the morning from tower Tavira in Cadiz, Andalusia, Spain",
                      "placeName": "Cadiz, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/95/38/85/240_F_95388517_V2BY9XlQxSdupRr3jNAeRjqQOGZZMQgi.jpg",
                      "title": "Old Cathedral. Cadiz, Spain",
                      "placeName": "Cadiz, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/80/64/76/240_F_180647642_QVsW9Pf7Vc6l5KkYoKoMjS8z3Mc3nUS4.jpg",
                      "title": "Cathedral of Cadiz at the atlantic coastline. Panorama of Cadiz",
                      "placeName": "Cadiz, Spain"
                    }
                  ],
                  "reason": "Beaches"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Ibiza, Spain",
                  "subtitle": "Ibiza, Spain",
                  "id": "e3beb45f-206c-48fd-88b7-8a6176fd5557",
                  "name": "Ibiza, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/58/29/69/240_F_158296956_Gqko3F5tjearwL8Hrzm8GCDwWCs4aP2h.jpg",
                      "title": "View of Cala d'Hort beach with sunbeds and umbrellas and beautiful azure blue sea water, Ibiza island, Spain",
                      "placeName": "Ibiza, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/09/69/13/240_F_109691358_RpEjn1rYNSxfzuKOiSTXaEWBMnlpIwXa.jpg",
                      "title": "Colorful Ibiza Old Town Buildings and Port",
                      "placeName": "Ibiza, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/00/46/38/16/240_F_46381683_kYpxfpiNxwhV6VC3xFKwoYYoNZDe82Yj.jpg",
                      "title": "Panorama of Ibiza, Spain",
                      "placeName": "Ibiza, Spain"
                    }
                  ],
                  "reason": "Beaches and nightlife"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "San Sebastian, Spain",
                  "subtitle": "San Sebastian, Spain",
                  "id": "80908df1-3d39-430b-9bef-264a74f78dfe",
                  "name": "San Sebastian, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/63/46/81/240_F_63468106_hS16ree1yRsAaAk0GpLLoc3ne00N5n7d.jpg",
                      "title": "San sebastian",
                      "placeName": "San Sebastian, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/57/12/89/240_F_157128957_lSChRSsRYBAT0Q6mrL1rjoeG6wm0pfPR.jpg",
                      "title": "san sebastian old town views",
                      "placeName": "San Sebastian, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/74/74/96/240_F_274749671_8vtQf49s5UkBtvzrPzTfDC4w6afEua8t.jpg",
                      "title": "Aerial view of turquoise bay of San Sebastian or Donostia with beach La Concha, Basque country, Spain",
                      "placeName": "San Sebastian, Spain"
                    }
                  ],
                  "reason": "Beaches and food"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Bilbao, Spain",
                  "subtitle": "Bilbao, Spain",
                  "id": "131b536f-f299-4255-9c0d-7dbd67a7257d",
                  "name": "Bilbao, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/34/74/93/240_F_134749388_fR0LPMfYP9Wb4e9TsJLOlcrOn7JzR3kw.jpg",
                      "title": "bilbao riverbank on sunny day, spain",
                      "placeName": "Bilbao, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/82/59/78/240_F_282597824_aapyApS983osm6V9Hbu4QUryqoFU9bUa.jpg",
                      "title": "San Anton church in Bilbao",
                      "placeName": "Bilbao, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/00/81/48/240_F_200814892_7c4zMTNxVttBoZ9KwWJqXw2gpJwOZDaX.jpg",
                      "title": "Ribera market in the spanish city Bilbao",
                      "placeName": "Bilbao, Spain"
                    }
                  ],
                  "reason": "Cultural landmarks"
                }
              ],
              "END_LOCATION": "San Francisco",
              "IS_ORDER_FLEXIBLE": "No",
              "NUM_TRAVELLERS": 4,
              "START_LOCATION": "San Francisco"
            },
            "shouldShow": false
          },
          "STAGE": "post_location_confirmation_widget"
        },
        {
          "sender": "user",
          "message": "Destinations Confirmed!",
          "widget": {
            "widgetType": "location_widget",
            "widgetData": {
              "recommendedPlaces": [
                {
                  "source": "adobe_stock",
                  "placeName": "Marbella, Spain",
                  "subtitle": "Marbella, Spain",
                  "id": "84f1eda1-a102-4657-a9a3-141c49230ea5",
                  "name": "Marbella, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/04/61/88/44/240_F_461884428_7esm5kuQ4WfQLuh1HG9w7RVHvbPThTYw.jpg",
                      "title": "Aerial panoramic view of Puerto Banus marina with luxury yachts, Marbella, Spain",
                      "placeName": "Marbella, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/00/51/05/240_F_510533_l6pqVWBLVfgCM1OYDEtYYiEtP4wx70.jpg",
                      "title": "marbella arch in san pedro in spain",
                      "placeName": "Marbella, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/24/25/66/240_F_224256612_1RKuEPIyjjUBtHU4OhzWbnf0IiWiZYhR.jpg",
                      "title": "Puerto Banus Marina",
                      "placeName": "Marbella, Spain"
                    }
                  ],
                  "reason": "Coastal town"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Malaga, Spain",
                  "subtitle": "Malaga, Spain",
                  "id": "213ebbc3-a0b1-42b9-85c3-456832c1b39d",
                  "name": "Malaga, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/17/59/80/240_F_117598013_o6oihHtCfzNi5fOvUjrFmS83pKHMISmx.jpg",
                      "title": "Malaga, Spain Old Town Skyline",
                      "placeName": "Malaga, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/14/53/42/240_F_114534203_jH4MIeGCXpJxLIFNDKdIyhOmr0ysAawk.jpg",
                      "title": "Malaga in Andalusia, Spain",
                      "placeName": "Malaga, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/38/36/20/240_F_138362042_sqhEyI1IAMo97FqT1U0D6DPHxhjIopxX.jpg",
                      "title": "Cityscape of Malaga city. Bull Ring, Plaza la Malagueta, Andalusia, Spain",
                      "placeName": "Malaga, Spain"
                    }
                  ],
                  "reason": "Coastal town"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Barcelona, Spain",
                  "subtitle": "Barcelona, Spain",
                  "id": "1314295e-0ec3-45d4-a3a1-e2d2a909b716",
                  "name": "Barcelona, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/47/26/25/240_F_47262519_JBji4zbizuOybaNXaBFAlYyawW3zx066.jpg",
                      "title": "Park Guell in Barcelona, Spain",
                      "placeName": "Barcelona, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/57/06/78/240_F_257067850_JWnLNo00M2XPZkJDuM3dkA1PYO6TaLVj.jpg",
                      "title": "View of Park Geull at sunset",
                      "placeName": "Barcelona, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/03/11/13/41/240_F_311134116_5nQvEcDX8e10K40ipHqcFm9zXgoA7TI2.jpg",
                      "title": "BARCELONA, SPAIN - SEPTEMBER 15,2015 :  Sagrada Familia  in  Barcelona. Sagrada  - the most known the buildings created by Antoni Gaudi.",
                      "placeName": "Barcelona, Spain"
                    }
                  ],
                  "reason": "You specified this"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Madrid, Spain",
                  "subtitle": "Madrid, Spain",
                  "id": "f6870915-89e9-4190-9d5b-2a15e90a9adb",
                  "name": "Madrid, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/02/29/52/63/240_F_229526382_6ZoOxMUlkB4Vniw6xkURNHPzFGOH6SH3.jpg",
                      "title": "Madrid city skyline gran via street twilight , Spain",
                      "placeName": "Madrid, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/03/18/15/240_F_103181516_8GJmbgeG3mkTrPfXQ15g7nc2mHYhIXaG.jpg",
                      "title": "Madrid, Spain cityscape at Calle de Alcala and Gran Via.",
                      "placeName": "Madrid, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/04/15/61/06/240_F_415610620_fFvrstpdhv7dLGI8V9Lch0XtWbPIz8dV.jpg",
                      "title": "Madrid Spain, sunrise city skyline at Cibeles Fountain Town Square",
                      "placeName": "Madrid, Spain"
                    }
                  ],
                  "reason": "You specified this"
                }
              ],
              "alternativePlaces": [
                {
                  "source": "adobe_stock",
                  "placeName": "Seville, Spain",
                  "subtitle": "Seville, Spain",
                  "id": "193eb549-84cb-4b7b-aa0b-aa1c87fb3b3b",
                  "name": "Seville, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/15/13/44/240_F_115134473_7dArpywLvq8sFEsyLEhK197RQchgJy0H.jpg",
                      "title": "Plaza de Espana (Spain square) in Seville, Andalusia",
                      "placeName": "Seville, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/12/60/42/240_F_112604266_DDKRYfj8EXC2SoFrB70Yam1N2S07EymI.jpg",
                      "title": "Spain Square (Plaza de Espana). Seville, Spain.",
                      "placeName": "Seville, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/03/05/31/08/240_F_305310830_P0qC5nCoWlcKcmy1H8YhYFevJmGTbyvq.jpg",
                      "title": "Scenes from Seville Spain",
                      "placeName": "Seville, Spain"
                    }
                  ],
                  "reason": "Cultural landmarks"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Valencia, Spain",
                  "subtitle": "Valencia, Spain",
                  "id": "d65025ff-9545-4548-9eb9-291441b17619",
                  "name": "Valencia, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/03/22/34/88/240_F_322348885_iPMfhiDfhUimz9Gnedo7N2ueg25qH8Kq.jpg",
                      "title": "Panoramic view of Plaza de la Virgen (Square of Virgin Saint Mary) and Valencia old town",
                      "placeName": "Valencia, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/34/38/57/240_F_134385787_JTMFqkusN3g4AN6S1duUjKqk71eClxGv.jpg",
                      "title": "Spain. Valencia,  Peniscola. View of the sea from a height of Pope Luna's Castle. The medieval castle of the Knights Templar on the beach. Beautiful view of the sea and the bay. Mediterranean Sea. ",
                      "placeName": "Valencia, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/32/16/24/240_F_132162400_10zwPUhBncmcSQ8mPWOQ2ojzWUrcc1RU.jpg",
                      "title": "Valencia Spain Virgin Square",
                      "placeName": "Valencia, Spain"
                    }
                  ],
                  "reason": "Beaches"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Cadiz, Spain",
                  "subtitle": "Cadiz, Spain",
                  "id": "8f6c0f83-d30a-44c6-99ee-563026316160",
                  "name": "Cadiz, Spain",
                  "photos": [
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/60/82/70/240_F_160827005_l7qpL4zfNkIlIAdzrP7o53GcIl4oUwi6.jpg",
                      "title": "Aerial panoramic view of the old city rooftops and Cathedral de Santa Cruz in the morning from tower Tavira in Cadiz, Andalusia, Spain",
                      "placeName": "Cadiz, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/95/38/85/240_F_95388517_V2BY9XlQxSdupRr3jNAeRjqQOGZZMQgi.jpg",
                      "title": "Old Cathedral. Cadiz, Spain",
                      "placeName": "Cadiz, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/01/80/64/76/240_F_180647642_QVsW9Pf7Vc6l5KkYoKoMjS8z3Mc3nUS4.jpg",
                      "title": "Cathedral of Cadiz at the atlantic coastline. Panorama of Cadiz",
                      "placeName": "Cadiz, Spain"
                    }
                  ],
                  "reason": "Beaches"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Ibiza, Spain",
                  "subtitle": "Ibiza, Spain",
                  "id": "e3beb45f-206c-48fd-88b7-8a6176fd5557",
                  "name": "Ibiza, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/58/29/69/240_F_158296956_Gqko3F5tjearwL8Hrzm8GCDwWCs4aP2h.jpg",
                      "title": "View of Cala d'Hort beach with sunbeds and umbrellas and beautiful azure blue sea water, Ibiza island, Spain",
                      "placeName": "Ibiza, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/09/69/13/240_F_109691358_RpEjn1rYNSxfzuKOiSTXaEWBMnlpIwXa.jpg",
                      "title": "Colorful Ibiza Old Town Buildings and Port",
                      "placeName": "Ibiza, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/00/46/38/16/240_F_46381683_kYpxfpiNxwhV6VC3xFKwoYYoNZDe82Yj.jpg",
                      "title": "Panorama of Ibiza, Spain",
                      "placeName": "Ibiza, Spain"
                    }
                  ],
                  "reason": "Beaches and nightlife"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "San Sebastian, Spain",
                  "subtitle": "San Sebastian, Spain",
                  "id": "80908df1-3d39-430b-9bef-264a74f78dfe",
                  "name": "San Sebastian, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/00/63/46/81/240_F_63468106_hS16ree1yRsAaAk0GpLLoc3ne00N5n7d.jpg",
                      "title": "San sebastian",
                      "placeName": "San Sebastian, Spain"
                    },
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/57/12/89/240_F_157128957_lSChRSsRYBAT0Q6mrL1rjoeG6wm0pfPR.jpg",
                      "title": "san sebastian old town views",
                      "placeName": "San Sebastian, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/74/74/96/240_F_274749671_8vtQf49s5UkBtvzrPzTfDC4w6afEua8t.jpg",
                      "title": "Aerial view of turquoise bay of San Sebastian or Donostia with beach La Concha, Basque country, Spain",
                      "placeName": "San Sebastian, Spain"
                    }
                  ],
                  "reason": "Beaches and food"
                },
                {
                  "source": "adobe_stock",
                  "placeName": "Bilbao, Spain",
                  "subtitle": "Bilbao, Spain",
                  "id": "131b536f-f299-4255-9c0d-7dbd67a7257d",
                  "name": "Bilbao, Spain",
                  "photos": [
                    {
                      "url": "https://t4.ftcdn.net/jpg/01/34/74/93/240_F_134749388_fR0LPMfYP9Wb4e9TsJLOlcrOn7JzR3kw.jpg",
                      "title": "bilbao riverbank on sunny day, spain",
                      "placeName": "Bilbao, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/82/59/78/240_F_282597824_aapyApS983osm6V9Hbu4QUryqoFU9bUa.jpg",
                      "title": "San Anton church in Bilbao",
                      "placeName": "Bilbao, Spain"
                    },
                    {
                      "url": "https://t3.ftcdn.net/jpg/02/00/81/48/240_F_200814892_7c4zMTNxVttBoZ9KwWJqXw2gpJwOZDaX.jpg",
                      "title": "Ribera market in the spanish city Bilbao",
                      "placeName": "Bilbao, Spain"
                    }
                  ],
                  "reason": "Cultural landmarks"
                }
              ],
              "END_LOCATION": "San Francisco",
              "IS_ORDER_FLEXIBLE": "No",
              "NUM_TRAVELLERS": 4,
              "START_LOCATION": "San Francisco"
            },
            "shouldShow": true
          }
        },
        {
          "sender": "airial",
          "message": "I am now generating your trip. This will be worth the wait!",
          "widget": {
            "widgetType": "trip_generation_updates_widget",
            "widgetData": [
              {
                "id": "identifying_route_and_connections",
                "message": "Identifying the route and the connections.",
                "status": 1
              },
              {
                "id": "finding_travel_dates",
                "message": "Finding the best travel dates. Found 4 options.",
                "status": 1
              },
              {
                "id": "searching_flights",
                "message": "Searching for flights.",
                "status": 1
              },
              {
                "id": "searching_hotels",
                "message": "Searching for hotels.",
                "status": 1
              },
              {
                "id": "creating_itinerary",
                "message": "Creating an itinerary.",
                "status": 1
              }
            ],
            "shouldShow": true
          }
        },
        {
          "sender": "airial",
          "message": "I've now created a memorable trip personalized for you. The trip is fully customizable via the chat or via the visuals on the right. If you are happy with the plan, you can proceed to book immediately."
        }
      ],
      "isTyping": false
    },
    "initialParse": {},
    "loading": 0,
    "loadingState": {
      "flightsLoading": 'loaded',
      "hotelsLoading": 'loaded',
      "itineraryLoading": 'loaded'
    },
    "llmInteractionHistory": [
      {
        "CONTENT": "Plan a 2-week Spain trip for me and 3 friends.",
        "DISPLAY_INFO": {},
        "LABEL": "User",
        "SENDER": "User"
      },
      {
        "CONTENT": "Spain is an incredible destination with its vibrant culture, delicious cuisine, and stunning architecture. When would you and your friends like to take this 2-week trip? Having a rough timeframe will help me plan the perfect itinerary.",
        "DISPLAY_INFO": {},
        "LABEL": "Assistant",
        "SENDER": "Assistant"
      },
      {
        "CONTENT": "This should be in September, starting on a Saturday. I want to spend a week visiting coastal towns in southern Spain and 3 days each in Barcelona and Madrid. I will have a car in Spain and will stay with a friend in Barcelona.",
        "DISPLAY_INFO": {},
        "LABEL": "User",
        "SENDER": "User"
      },
      {
        "CONTENT": "Spain is a treasure trove of experiences. Please answer the following questions to help me tailor your trip to your preferences.",
        "DISPLAY_INFO": {},
        "LABEL": "Assistant",
        "SENDER": "Assistant"
      },
      {
        "CONTENT": "[\n  \"What are your main interests for the Spain trip? :: Beaches :: Cultural landmarks :: Food and wine :: Outdoor activities :: Nightlife :: Other (specify)\",\n  \"Anything else I should know?\"\n]",
        "DISPLAY_INFO": {},
        "LABEL": "Assistant-initiated Clarification Widget",
        "SENDER": "Assistant"
      },
      {
        "CONTENT": "[\n  \"What are your main interests for the Spain trip? :: Beaches :: Cultural landmarks\",\n  \"Anything else I should know? :: I am interested in soccer - want to visit the iconic Barcelona and Madrid stadiums.\"\n]",
        "LABEL": "User Response for Clarification Widget",
        "SENDER": "User"
      },
      {
        "CONTENT": "Please confirm or edit these locations that I picked out for you, click on generate and watch your personalized dream trip come to life!",
        "DISPLAY_INFO": {},
        "LABEL": "Assistant",
        "SENDER": "Assistant"
      },
      {
        "CONTENT": "{\n  \"NUM_TRAVELLERS\": 4,\n  \"START_LOCATION\": \"San Francisco\",\n  \"RECOMMENDED_PLACES\": [\n    {\n      \"name\": \"Marbella, Spain\",\n      \"reason\": \"Coastal town\"\n    },\n    {\n      \"name\": \"Malaga, Spain\",\n      \"reason\": \"Coastal town\"\n    },\n    {\n      \"name\": \"Barcelona, Spain\",\n      \"reason\": \"You specified this\"\n    },\n    {\n      \"name\": \"Madrid, Spain\",\n      \"reason\": \"You specified this\"\n    }\n  ],\n  \"ALTERNATIVE_PLACES\": [\n    {\n      \"name\": \"Seville, Spain\",\n      \"reason\": \"Cultural landmarks\"\n    },\n    {\n      \"name\": \"Valencia, Spain\",\n      \"reason\": \"Beaches\"\n    },\n    {\n      \"name\": \"Cadiz, Spain\",\n      \"reason\": \"Beaches\"\n    },\n    {\n      \"name\": \"Ibiza, Spain\",\n      \"reason\": \"Beaches and nightlife\"\n    },\n    {\n      \"name\": \"San Sebastian, Spain\",\n      \"reason\": \"Beaches and food\"\n    },\n    {\n      \"name\": \"Bilbao, Spain\",\n      \"reason\": \"Cultural landmarks\"\n    }\n  ],\n  \"END_LOCATION\": \"San Francisco\",\n  \"IS_ORDER_FLEXIBLE\": \"No\"\n}",
        "DISPLAY_INFO": {},
        "LABEL": "Assistant-initiated Location Confirmation Widget",
        "SENDER": "Assistant"
      },
      {
        "SENDER": "User",
        "LABEL": "Locations confirmed by the user",
        "CONTENT": "{\n  \"NUM_LOCATIONS\": 5,\n  \"START_LOCATION\": \"San Francisco\",\n  \"SELECTED_PLACES\": [\n    \"Barcelona, Spain\",\n    \"Marbella, Spain\",\n    \"Malaga, Spain\",\n    \"Madrid, Spain\",\n    \"Seville, Spain\"\n  ],\n  \"END_LOCATION\": \"San Francisco\",\n  \"IS_ORDER_FLEXIBLE\": \"No\"\n}"
      },
      {
        "SENDER": "Assistant",
        "LABEL": "Assistant",
        "CONTENT": "I've now created a memorable trip personalized for you. The trip is fully customizable via the chat or via the visuals on the right. If you are happy with the plan, you can proceed to book immediately.",
        "DISPLAY_INFO": {}
      }
    ],
    "interactionStage": "post_report",
    "flight_results": [
      {
        "dateOptionLabel": "2024-09-07 to 2024-09-23",
        "itineraries": [
          {
            "allPassengerTotalPrice": 833,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpT2Usm9iuizNMf2YtzsFfOJDVvGrRbNC1Y4k-XVsDSX2lLErudMVIiJZEj39z9lWpFF6JyZ5xUUMLks3-EmRbmySaMPGTYDZQNiMoBxs_e0c57Qs2JfDPFwoa9XzBBy56a_XbGUGbu7-RaU2SfNjuV_XjZb1vaTV_TOkZ7bNu02NCblXHTHSUYQ_JNkpJrE6y6REvLyY9JiOVoZKvNWld9m3d4ZOiBE5ctrPdqdklaCYk_vcdjAG9Q0TuUoowRYvXI5az7vA4mVAnSuKQ7TlbUghE3Hv9g1jkDKs1635PvXR-jSPOTme8ZMqXZdqVXme9-OPH3fVTuInzKLeMrN36qoRIZwURV--UOEdJOgL9KRY9PF4kfhNW4L6bjRU4UtAwuTifkyyV9ZD7Aod1ypAwr5TpHYve0dgUVNezHRJTc0jZAdCtqO8J14dFruqMYYpdgeG--GZBlZUrlqqEJ_He2MSKKdcNVuRRHmiiPZ9B275WvXobnt2-FzGB1ZlzZGc2CPLkIvhgbjtEKvaoed25PwEhi-yeyJYsWIbJ_0WGlw4vQM-2IfM3jkJDF9Esfh5NhP_q1DZghbkOclA1rlwVahK0Iw8hraIyqi9TrnkW6vF2JP1DhZpUWyL32D6TMKguBtsailJBjv6v3zLxQv0dqXkD4lxZWXibOGKkX4gIrn3LNdp7O7TFvCYNhx0BISdXp8nTQxlXlBqkCEtJIeiPAhdVDU3KElP_s62GmAZALacYwprYJRKGC0ZOcd7mDO8yq-xWeYlWiKozmsUXNT6Hov9eh_RvS59s60oVQq6pVhOLKJO10xWgnb54v4DdPWG2dKlCy4UCnsXyJpj1bWU4QEuTQit0SQK9U5M_mL-6gHfzk9mvPUAhX7ODnVKzrfw0fLEXF7HxYXdO89ak-u7J4wO7dAtGCqT3-rHIwoCcotGB3WfSL3gKFjsPvTGW9fLsV_HUxg_RSNV54WPBYRiN0QE382NaSXP3sz7H-hlF2V0T5wvz-mSt9r2FHoy9jD48LIcQnpXqXGMvMWvgoxNOYPoHVyyAwElB6eGU6itMZl8W4a13qdnSFLTs-wp5ZP3oeevfWw5Uk2oY1nHBD35lPL8vR_zsXWn64XqD9YN4VkmB3yoE463x3rqQTrKLhrXfV8BcAhaBknRq70MCYTGeH5br2lmVXGIyABT4Ed9xF7ora150QqKlSj59PafW2dIrKtwzOwKKW9yNSHt2CVKQK9E1nDqS-ncvH93oulBnV12NANCJqFDP9Z47xvBujsG",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpT2Usm9iuizNMf2YtzsFfOJDVvGrRbNC1Y4k-XVsDSX2lLErudMVIiJZEj39z9lWpFF6JyZ5xUUMLks3-EmRbmySaMPGTYDZQNiMoBxs_e0c57Qs2JfDPFwoa9XzBBy56a_XbGUGbu7-RaU2SfNjuV_XjZb1vaTV_TOkZ7bNu02NCblXHTHSUYQ_JNkpJrE6y6REvLyY9JiOVoZKvNWld9m3d4ZOiBE5ctrPdqdklaCYk_vcdjAG9Q0TuUoowRYvXI5az7vA4mVAnSuKQ7TlbUghE3Hv9g1jkDKs1635PvXR-jSPOTme8ZMqXZdqVXme9-OPH3fVTuInzKLeMrN36qoRIZwURV--UOEdJOgL9KRY9PF4kfhNW4L6bjRU4UtAwuTifkyyV9ZD7Aod1ypAwr5TpHYve0dgUVNezHRJTc0jZAdCtqO8J14dFruqMYYpdgeG--GZBlZUrlqqEJ_He2MSKKdcNVuRRHmiiPZ9B275WvXobnt2-FzGB1ZlzZGc2CPLkIvhgbjtEKvaoed25PwEhi-yeyJYsWIbJ_0WGlw4vQM-2IfM3jkJDF9Esfh5NhP_q1DZghbkOclA1rlwVahK0Iw8hraIyqi9TrnkW6vF2JP1DhZpUWyL32D6TMKguBtsailJBjv6v3zLxQv0dqXkD4lxZWXibOGKkX4gIrn3LNdp7O7TFvCYNhx0BISdXp8nTQxlXlBqkCEtJIeiPAhdVDU3KElP_s62GmAZALacYwprYJRKGC0ZOcd7mDO8yq-xWeYlWiKozmsUXNT6Hov9eh_RvS59s60oVQq6pVhOLKJO10xWgnb54v4DdPWG2dKlCy4UCnsXyJpj1bWU4QEuTQit0SQK9U5M_mL-6gHfzk9mvPUAhX7ODnVKzrfw0fLEXF7HxYXdO89ak-u7J4wO7dAtGCqT3-rHIwoCcotGB3WfSL3gKFjsPvTGW9fLsV_HUxg_RSNV54WPBYRiN0QE382NaSXP3sz7H-hlF2V0T5wvz-mSt9r2FHoy9jD48LIcQnpXqXGMvMWvgoxNOYPoHVyyAwElB6eGU6itMZl8W4a13qdnSFLTs-wp5ZP3oeevfWw5Uk2oY1nHBD35lPL8vR_zsXWn64XqD9YN4VkmB3yoE463x3rqQTrKLhrXfV8BcAhaBknRq70MCYTGeH5br2lmVXGIyABT4Ed9xF7ora150QqKlSj59PafW2dIrKtwzOwKKW9yNSHt2CVKQK9E1nDqS-ncvH93oulBnV12NANCJqFDP9Z47xvBujsG",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpT2Usm9iuizNMf2YtzsFfOJDVvGrRbNC1Y4k-XVsDSX2lLErudMVIiJZEj39z9lWpFF6JyZ5xUUMLks3-EmRbmySaMPGTYDZQNiMoBxs_e0c57Qs2JfDPFwoa9XzBBy56a_XbGUGbu7-RaU2SfNjuV_XjZb1vaTV_TOkZ7bNu02NCblXHTHSUYQ_JNkpJrE6y6REvLyY9JiOVoZKvNWld9m3d4ZOiBE5ctrPdqdklaCYk_vcdjAG9Q0TuUoowRYvXI5az7vA4mVAnSuKQ7TlbUghE3Hv9g1jkDKs1635PvXR-jSPOTme8ZMqXZdqVXme9-OPH3fVTuInzKLeMrN36qoRIZwURV--UOEdJOgL9KRY9PF4kfhNW4L6bjRU4UtAwuTifkyyV9ZD7Aod1ypAwr5TpHYve0dgUVNezHRJTc0jZAdCtqO8J14dFruqMYYpdgeG--GZBlZUrlqqEJ_He2MSKKdcNVuRRHmiiPZ9B275WvXobnt2-FzGB1ZlzZGc2CPLkIvhgbjtEKvaoed25PwEhi-yeyJYsWIbJ_0WGlw4vQM-2IfM3jkJDF9Esfh5NhP_q1DZghbkOclA1rlwVahK0Iw8hraIyqi9TrnkW6vF2JP1DhZpUWyL32D6TMKguBtsailJBjv6v3zLxQv0dqXkD4lxZWXibOGKkX4gIrn3LNdp7O7TFvCYNhx0BISdXp8nTQxlXlBqkCEtJIeiPAhdVDU3KElP_s62GmAZALacYwprYJRKGC0ZOcd7mDO8yq-xWeYlWiKozmsUXNT6Hov9eh_RvS59s60oVQq6pVhOLKJO10xWgnb54v4DdPWG2dKlCy4UCnsXyJpj1bWU4QEuTQit0SQK9U5M_mL-6gHfzk9mvPUAhX7ODnVKzrfw0fLEXF7HxYXdO89ak-u7J4wO7dAtGCqT3-rHIwoCcotGB3WfSL3gKFjsPvTGW9fLsV_HUxg_RSNV54WPBYRiN0QE382NaSXP3sz7H-hlF2V0T5wvz-mSt9r2FHoy9jD48LIcQnpXqXGMvMWvgoxNOYPoHVyyAwElB6eGU6itMZl8W4a13qdnSFLTs-wp5ZP3oeevfWw5Uk2oY1nHBD35lPL8vR_zsXWn64XqD9YN4VkmB3yoE463x3rqQTrKLhrXfV8BcAhaBknRq70MCYTGeH5br2lmVXGIyABT4Ed9xF7ora150QqKlSj59PafW2dIrKtwzOwKKW9yNSHt2CVKQK9E1nDqS-ncvH93oulBnV12NANCJqFDP9Z47xvBujsG",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1139.19,
            "totalPrice": 833
          },
          {
            "allPassengerTotalPrice": 837,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbmxHU9Nx7B9QyPz95VqkXOPxscjx4aTkmBMkmr0C1xXPxxtWJXkU8-ZNaC1GAIr7OdMe_IFt3n78V-zZVbyZejhb6lOfGtKIvI9790c4d-SmS3_otTOcTg3KQNM5ZQzdT6hthyBKF2eShdaZq_0ZvGFXPWTNBYnG8Ex5MT2b_NzWYPz0UuZGSWRr7n40y1YHX42tP__xRhQykm_WyuK2GO0BI-OQti38j9kNdicejCgpjRtuZT3AeRVrQUQpC65_KUKCTch_e1gPBd1Av4jkjdi-K3LK22BJjx4hLrA9EB9xUFOq--yesXK2l-baHKh3dDF-Ktvg7T7Fy7r8neMP7kj1COunn-wHgv8vPUyL3HKijVKHkDaPqrWIzjFWKttZUYnS9H_sYH-g3q43aX2r3vraWB81pRMukiPwg3VHkCSkGtjJ65RTlNQsonsKSWYEet_txpKeCsa5s7EqoynpzJPAR2FcNV4b24WJGKDxF2ZIus6ZYSgpdm6KVMzbtaB38evAWjxcWU33xcUYkJP044XCXHDciQbOA69AhQ_UwlfgOF_iNAj5coFfDN7rQhwunuWKpm5K9houhNpElc3yMWEVukERxFvUQGlXpW3961XkC0bP8hCYEKacUfgPaOolFF0LbUz_n1EJ0Ek9zcuZvJ8GM4oHmDJTmmt7E5uRM_YoNFyIgihRH1WNyjH8GYyopmAlxtypDFubNUPZMdu9sLBQJ10qBbyR-_fxyRhJSUs-yhp72J4YLMw9yZL5sKm9IqvfYxfORn-QgroIWhY3A2-XmY4zi_qOTgjJsSAi-snu-wECiYIf5PT06sMBz3H1kePYyGv3MPpghWazA71N0caA-UwIRr9aDoLW0W8VKpYbZnP8mfZxzIWjEKAyRQc3CyUXbK6IipaXP3IhI6H0oJ0HX-P3_Vu2po3aKQsu9FNGDZeYmFbCNJ__tYkUTmLzMEoT7qtRUktm8GkzKdORiUmC1-PfBUIf5bbHg9qzs5I1GdG6wjrfynMPRr7W0ek5oj9YxocKIvtb_oRYpSnwIaawS67fAY4Ec8SbpIjFf0hgpz8aHVH-4sdEHpqDI41GPTJ6XV9FrAdmwwwUMLi2RyE2iEjWAF3KJxj8USAPiXsyqvuAcmdxprExEPfsPugcHvpA4Zp5eKXIpIfz1N22Ftxst9Y8yGvbx1FMYLwFp_ns_nGDXumobXEd-ZOm7WdYKX5D0uz7Uaa4cP-MhV6MoxYKi3SUT4LPuajPeQrQRsonG-ZQZ-WoiPWdl3nfgwHfaXBHGjDgPW1LB_NiLDPVrP4Gq7bF5c8551DLFGqdXHE=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbmxHU9Nx7B9QyPz95VqkXOPxscjx4aTkmBMkmr0C1xXPxxtWJXkU8-ZNaC1GAIr7OdMe_IFt3n78V-zZVbyZejhb6lOfGtKIvI9790c4d-SmS3_otTOcTg3KQNM5ZQzdT6hthyBKF2eShdaZq_0ZvGFXPWTNBYnG8Ex5MT2b_NzWYPz0UuZGSWRr7n40y1YHX42tP__xRhQykm_WyuK2GO0BI-OQti38j9kNdicejCgpjRtuZT3AeRVrQUQpC65_KUKCTch_e1gPBd1Av4jkjdi-K3LK22BJjx4hLrA9EB9xUFOq--yesXK2l-baHKh3dDF-Ktvg7T7Fy7r8neMP7kj1COunn-wHgv8vPUyL3HKijVKHkDaPqrWIzjFWKttZUYnS9H_sYH-g3q43aX2r3vraWB81pRMukiPwg3VHkCSkGtjJ65RTlNQsonsKSWYEet_txpKeCsa5s7EqoynpzJPAR2FcNV4b24WJGKDxF2ZIus6ZYSgpdm6KVMzbtaB38evAWjxcWU33xcUYkJP044XCXHDciQbOA69AhQ_UwlfgOF_iNAj5coFfDN7rQhwunuWKpm5K9houhNpElc3yMWEVukERxFvUQGlXpW3961XkC0bP8hCYEKacUfgPaOolFF0LbUz_n1EJ0Ek9zcuZvJ8GM4oHmDJTmmt7E5uRM_YoNFyIgihRH1WNyjH8GYyopmAlxtypDFubNUPZMdu9sLBQJ10qBbyR-_fxyRhJSUs-yhp72J4YLMw9yZL5sKm9IqvfYxfORn-QgroIWhY3A2-XmY4zi_qOTgjJsSAi-snu-wECiYIf5PT06sMBz3H1kePYyGv3MPpghWazA71N0caA-UwIRr9aDoLW0W8VKpYbZnP8mfZxzIWjEKAyRQc3CyUXbK6IipaXP3IhI6H0oJ0HX-P3_Vu2po3aKQsu9FNGDZeYmFbCNJ__tYkUTmLzMEoT7qtRUktm8GkzKdORiUmC1-PfBUIf5bbHg9qzs5I1GdG6wjrfynMPRr7W0ek5oj9YxocKIvtb_oRYpSnwIaawS67fAY4Ec8SbpIjFf0hgpz8aHVH-4sdEHpqDI41GPTJ6XV9FrAdmwwwUMLi2RyE2iEjWAF3KJxj8USAPiXsyqvuAcmdxprExEPfsPugcHvpA4Zp5eKXIpIfz1N22Ftxst9Y8yGvbx1FMYLwFp_ns_nGDXumobXEd-ZOm7WdYKX5D0uz7Uaa4cP-MhV6MoxYKi3SUT4LPuajPeQrQRsonG-ZQZ-WoiPWdl3nfgwHfaXBHGjDgPW1LB_NiLDPVrP4Gq7bF5c8551DLFGqdXHE=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbmxHU9Nx7B9QyPz95VqkXOPxscjx4aTkmBMkmr0C1xXPxxtWJXkU8-ZNaC1GAIr7OdMe_IFt3n78V-zZVbyZejhb6lOfGtKIvI9790c4d-SmS3_otTOcTg3KQNM5ZQzdT6hthyBKF2eShdaZq_0ZvGFXPWTNBYnG8Ex5MT2b_NzWYPz0UuZGSWRr7n40y1YHX42tP__xRhQykm_WyuK2GO0BI-OQti38j9kNdicejCgpjRtuZT3AeRVrQUQpC65_KUKCTch_e1gPBd1Av4jkjdi-K3LK22BJjx4hLrA9EB9xUFOq--yesXK2l-baHKh3dDF-Ktvg7T7Fy7r8neMP7kj1COunn-wHgv8vPUyL3HKijVKHkDaPqrWIzjFWKttZUYnS9H_sYH-g3q43aX2r3vraWB81pRMukiPwg3VHkCSkGtjJ65RTlNQsonsKSWYEet_txpKeCsa5s7EqoynpzJPAR2FcNV4b24WJGKDxF2ZIus6ZYSgpdm6KVMzbtaB38evAWjxcWU33xcUYkJP044XCXHDciQbOA69AhQ_UwlfgOF_iNAj5coFfDN7rQhwunuWKpm5K9houhNpElc3yMWEVukERxFvUQGlXpW3961XkC0bP8hCYEKacUfgPaOolFF0LbUz_n1EJ0Ek9zcuZvJ8GM4oHmDJTmmt7E5uRM_YoNFyIgihRH1WNyjH8GYyopmAlxtypDFubNUPZMdu9sLBQJ10qBbyR-_fxyRhJSUs-yhp72J4YLMw9yZL5sKm9IqvfYxfORn-QgroIWhY3A2-XmY4zi_qOTgjJsSAi-snu-wECiYIf5PT06sMBz3H1kePYyGv3MPpghWazA71N0caA-UwIRr9aDoLW0W8VKpYbZnP8mfZxzIWjEKAyRQc3CyUXbK6IipaXP3IhI6H0oJ0HX-P3_Vu2po3aKQsu9FNGDZeYmFbCNJ__tYkUTmLzMEoT7qtRUktm8GkzKdORiUmC1-PfBUIf5bbHg9qzs5I1GdG6wjrfynMPRr7W0ek5oj9YxocKIvtb_oRYpSnwIaawS67fAY4Ec8SbpIjFf0hgpz8aHVH-4sdEHpqDI41GPTJ6XV9FrAdmwwwUMLi2RyE2iEjWAF3KJxj8USAPiXsyqvuAcmdxprExEPfsPugcHvpA4Zp5eKXIpIfz1N22Ftxst9Y8yGvbx1FMYLwFp_ns_nGDXumobXEd-ZOm7WdYKX5D0uz7Uaa4cP-MhV6MoxYKi3SUT4LPuajPeQrQRsonG-ZQZ-WoiPWdl3nfgwHfaXBHGjDgPW1LB_NiLDPVrP4Gq7bF5c8551DLFGqdXHE=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1140.97,
            "totalPrice": 837
          },
          {
            "allPassengerTotalPrice": 837,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoOCS8J-K0-60FK9XOjWu1hvcaP05YEsBa5eBqSYiBbBNhUC0IIWtjUlnAF69FtH80jeHa8zAPWof7DVouistJZUnpJ2h3kLIQXdfjlV8A0f6gMWmHjO8sCeDfRTDKqSbMNSKx41SA-7DmL1Y0C99U-bcSsn3FAZRGgtlSjl-I8k04NEDMh5NO7WhNa97B7gekY5oFBqNBL7RDedB0DdeV0GL0XGGe6-XOOdVb3rAsZQO5OUWwHWqPZWrxGo00ROMBKTpu8-YyLuIi5sLw0JsNDwOOpGRRSRCwPqwQUfZwuzZPXih08wZ5o7Nqrtrc50y-LpJRHQVDUHPyshmXmJjvkVGjcOH5RiV3qkoa7YEpWmb-6HEm2G-YE0HdldMr_yGmFhtydwuDIa4k0spKt6K3Eb5jz_PWIKQNhkfVqdg8RvGT0RqCuNv-1_Bi6gw9C3kjbJEjvc3SaRnzok8_8Yig3R9sm_PYBRDsD2KJcJ-45MB-ubm428r_4P6GQVXS58jf4V4-FNyN2FxyFw7674y2N8mHtWR3N7_uQSiFmF3twllIwkvCb5euHu6TLOI7c2u85CPNOTuUGluHV1qDoBPxUhPUYSpH2EJFdeGjec_fg9rbeI8aY_CgmJ8samb81txp7QAQVNBL_AsllPaJVPR9EgfYhSikZARroszq9I6w5-sBpJnmCCfGveDXWP7oxUx55CSDHWmyqAfvhs5RrCtI_xdkz-5G8I9wzLhroD8a-YJ2EG1K4XHIiH2F8E-MqIRsS0P1MuyFFmtt6XRSe5rjWibDayIAbp0GPU-b6_ysK9Ul9jjwjef93H6sBT4H-h63qp08i5XXegYlyGq6kYlP-rSNRUKmzst-5jbBneyvaDdpiEmqsk724VcjdYBBv7JJ6RauQV1vgdm1euXhJFrb1Sc646m98coYARe-nyjrkeFXxCCxdfzW959YH7pGV9jRX1K_wYGwZjHPGnaRtXTRun7Rk9lNTFLjVh5A8mqxrSvKrFkue1afwpqKF5C9r6lvYpK98FML7-Bn0ylzD42138wu8OBjKB2cDngMAXVrtSWatXtOGWV2BUd6wkxOA-4CnpZl7JPBqD9IpzlCvmmdvflJGPfL8JsliXq9R6juneB5zRwl3FzCnZ6QcgtlZ39jIg9HbZqvMvuYNtGnTCbeHngwvWrcVlE50_0dN4gATtiEvWmpzKsN1wICc88P9S00yOc6F9WqJ58cI-jgFlq2ZVjSkcdSXp_Ir23xfX4fxI2fheMEFLXXBJpfDEZzfEuErpooIg8uNeYhRiNxxxvkarSwt0afvpryoQuATgc3c8=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoOCS8J-K0-60FK9XOjWu1hvcaP05YEsBa5eBqSYiBbBNhUC0IIWtjUlnAF69FtH80jeHa8zAPWof7DVouistJZUnpJ2h3kLIQXdfjlV8A0f6gMWmHjO8sCeDfRTDKqSbMNSKx41SA-7DmL1Y0C99U-bcSsn3FAZRGgtlSjl-I8k04NEDMh5NO7WhNa97B7gekY5oFBqNBL7RDedB0DdeV0GL0XGGe6-XOOdVb3rAsZQO5OUWwHWqPZWrxGo00ROMBKTpu8-YyLuIi5sLw0JsNDwOOpGRRSRCwPqwQUfZwuzZPXih08wZ5o7Nqrtrc50y-LpJRHQVDUHPyshmXmJjvkVGjcOH5RiV3qkoa7YEpWmb-6HEm2G-YE0HdldMr_yGmFhtydwuDIa4k0spKt6K3Eb5jz_PWIKQNhkfVqdg8RvGT0RqCuNv-1_Bi6gw9C3kjbJEjvc3SaRnzok8_8Yig3R9sm_PYBRDsD2KJcJ-45MB-ubm428r_4P6GQVXS58jf4V4-FNyN2FxyFw7674y2N8mHtWR3N7_uQSiFmF3twllIwkvCb5euHu6TLOI7c2u85CPNOTuUGluHV1qDoBPxUhPUYSpH2EJFdeGjec_fg9rbeI8aY_CgmJ8samb81txp7QAQVNBL_AsllPaJVPR9EgfYhSikZARroszq9I6w5-sBpJnmCCfGveDXWP7oxUx55CSDHWmyqAfvhs5RrCtI_xdkz-5G8I9wzLhroD8a-YJ2EG1K4XHIiH2F8E-MqIRsS0P1MuyFFmtt6XRSe5rjWibDayIAbp0GPU-b6_ysK9Ul9jjwjef93H6sBT4H-h63qp08i5XXegYlyGq6kYlP-rSNRUKmzst-5jbBneyvaDdpiEmqsk724VcjdYBBv7JJ6RauQV1vgdm1euXhJFrb1Sc646m98coYARe-nyjrkeFXxCCxdfzW959YH7pGV9jRX1K_wYGwZjHPGnaRtXTRun7Rk9lNTFLjVh5A8mqxrSvKrFkue1afwpqKF5C9r6lvYpK98FML7-Bn0ylzD42138wu8OBjKB2cDngMAXVrtSWatXtOGWV2BUd6wkxOA-4CnpZl7JPBqD9IpzlCvmmdvflJGPfL8JsliXq9R6juneB5zRwl3FzCnZ6QcgtlZ39jIg9HbZqvMvuYNtGnTCbeHngwvWrcVlE50_0dN4gATtiEvWmpzKsN1wICc88P9S00yOc6F9WqJ58cI-jgFlq2ZVjSkcdSXp_Ir23xfX4fxI2fheMEFLXXBJpfDEZzfEuErpooIg8uNeYhRiNxxxvkarSwt0afvpryoQuATgc3c8=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoOCS8J-K0-60FK9XOjWu1hvcaP05YEsBa5eBqSYiBbBNhUC0IIWtjUlnAF69FtH80jeHa8zAPWof7DVouistJZUnpJ2h3kLIQXdfjlV8A0f6gMWmHjO8sCeDfRTDKqSbMNSKx41SA-7DmL1Y0C99U-bcSsn3FAZRGgtlSjl-I8k04NEDMh5NO7WhNa97B7gekY5oFBqNBL7RDedB0DdeV0GL0XGGe6-XOOdVb3rAsZQO5OUWwHWqPZWrxGo00ROMBKTpu8-YyLuIi5sLw0JsNDwOOpGRRSRCwPqwQUfZwuzZPXih08wZ5o7Nqrtrc50y-LpJRHQVDUHPyshmXmJjvkVGjcOH5RiV3qkoa7YEpWmb-6HEm2G-YE0HdldMr_yGmFhtydwuDIa4k0spKt6K3Eb5jz_PWIKQNhkfVqdg8RvGT0RqCuNv-1_Bi6gw9C3kjbJEjvc3SaRnzok8_8Yig3R9sm_PYBRDsD2KJcJ-45MB-ubm428r_4P6GQVXS58jf4V4-FNyN2FxyFw7674y2N8mHtWR3N7_uQSiFmF3twllIwkvCb5euHu6TLOI7c2u85CPNOTuUGluHV1qDoBPxUhPUYSpH2EJFdeGjec_fg9rbeI8aY_CgmJ8samb81txp7QAQVNBL_AsllPaJVPR9EgfYhSikZARroszq9I6w5-sBpJnmCCfGveDXWP7oxUx55CSDHWmyqAfvhs5RrCtI_xdkz-5G8I9wzLhroD8a-YJ2EG1K4XHIiH2F8E-MqIRsS0P1MuyFFmtt6XRSe5rjWibDayIAbp0GPU-b6_ysK9Ul9jjwjef93H6sBT4H-h63qp08i5XXegYlyGq6kYlP-rSNRUKmzst-5jbBneyvaDdpiEmqsk724VcjdYBBv7JJ6RauQV1vgdm1euXhJFrb1Sc646m98coYARe-nyjrkeFXxCCxdfzW959YH7pGV9jRX1K_wYGwZjHPGnaRtXTRun7Rk9lNTFLjVh5A8mqxrSvKrFkue1afwpqKF5C9r6lvYpK98FML7-Bn0ylzD42138wu8OBjKB2cDngMAXVrtSWatXtOGWV2BUd6wkxOA-4CnpZl7JPBqD9IpzlCvmmdvflJGPfL8JsliXq9R6juneB5zRwl3FzCnZ6QcgtlZ39jIg9HbZqvMvuYNtGnTCbeHngwvWrcVlE50_0dN4gATtiEvWmpzKsN1wICc88P9S00yOc6F9WqJ58cI-jgFlq2ZVjSkcdSXp_Ir23xfX4fxI2fheMEFLXXBJpfDEZzfEuErpooIg8uNeYhRiNxxxvkarSwt0afvpryoQuATgc3c8=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1141.63,
            "totalPrice": 837
          },
          {
            "allPassengerTotalPrice": 840,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqJdVomVz7D8d0FkyRqwIWxuATT5dkAVjme8kTM8D6kvppo6d9wJLYRFx3Pf9Y4q21qSEDUA0FfuVkZlGhN1vN-xfK2YjpthW6ccORcauTpZuY_xJX8Bcwpo0q3zlD712d9VX19ZRXul2TnzzgXd3QSC2mpzPw9inm0d9sbGRwCHZg3O2AUJ1dXBprcWy5gxOeVC00A2kg3Hu0IUOd19VcxnVdPCt8WBCDoG4PBUYqR10fU-IP5H1sdGYGjNl3n9fEfAD7GmY_AxQXW4dCg4080iD4GaHZgXAstCCjN_VpkmBmDaH7wliPg4Q-cK_ldG8yEjGv7jHIZsnUsDVw2qLzQB47P3lwIm_97WE1Br2F7XTY46app6z8IzEeDDWU9gwqZbPYfcCqpbuMHZmEEEsVl7BI9pkODOhdnxeVeBst6sNgBe5XVYXnSrSgN1cooLfzKyf1_0k7EmZ7Z08dqvRt7kHnljGdzsBdXOWm-AkSWNtFGksLVXQXjxdmxFE4rkF_kj6HTOE9YrXUrYAl_xKvGQoX87rmn30na1Dj8uEY8t5KdzvD9ea37LRQmfDAIpgeQHd1hoq5N7jlbaOSa0VYhUwseorYdSK82MOiOB-DuyDhd9YFUfuVmTVneQnv33BScT4XoGN4HGuGKhNVzII-z2FqVOAEXlILK5gHaoVB1cZRR7XTc4FOKZy0JsVEZnOAHfQWPStMj6t12a6Tz5BmjALG2ixu1igsxUVCDTk00QCt_HXVwcZLGTeSLBYgmAbY9bpMKMIFMsHf6em2uWPhgW1yEJ9aKGianHZ9veuJmlWZnkULgv8IBmkBFCJGXqCAD1lpuSLkutBXR5i9SwlvXuM4xnayj9Uruh8jMFac49kGKRQ834hrwqXV9c4H69asm4oj6GNaZzK1h4zzod77zjgsbdRakMQ4w6pzU9aBMdcJkgniBodxjL1SX6Rx12sbSpbdK_XliqxAtPpz8HL1Hv6xupSb282PGDRf8kzb4SKQkbnQlqJNVWIpXewo9WZVOYVcRAdqv1gJ40So73LyHMa_lPsjGqPM1DhnYbe8l0e5dt1QPTPSVnbPgtpOY8loFbjRXnijNYjwbmH436LSLBuUZGo_ixCniudHTYxRwpumcujPo9KPlSSPgJC21vXdDEg-4WcQbpYWQZD1zJtybOBhlbsIaO3DZ_ZlcCiYCFptH8WGpt4W686uhWWIEhuh6wCYo7L8PDt3nqHgr_-9kPNgu6jgb7e7vSRlcMwEuFkK80lK2jibJ7fiJz8_xgd",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqJdVomVz7D8d0FkyRqwIWxuATT5dkAVjme8kTM8D6kvppo6d9wJLYRFx3Pf9Y4q21qSEDUA0FfuVkZlGhN1vN-xfK2YjpthW6ccORcauTpZuY_xJX8Bcwpo0q3zlD712d9VX19ZRXul2TnzzgXd3QSC2mpzPw9inm0d9sbGRwCHZg3O2AUJ1dXBprcWy5gxOeVC00A2kg3Hu0IUOd19VcxnVdPCt8WBCDoG4PBUYqR10fU-IP5H1sdGYGjNl3n9fEfAD7GmY_AxQXW4dCg4080iD4GaHZgXAstCCjN_VpkmBmDaH7wliPg4Q-cK_ldG8yEjGv7jHIZsnUsDVw2qLzQB47P3lwIm_97WE1Br2F7XTY46app6z8IzEeDDWU9gwqZbPYfcCqpbuMHZmEEEsVl7BI9pkODOhdnxeVeBst6sNgBe5XVYXnSrSgN1cooLfzKyf1_0k7EmZ7Z08dqvRt7kHnljGdzsBdXOWm-AkSWNtFGksLVXQXjxdmxFE4rkF_kj6HTOE9YrXUrYAl_xKvGQoX87rmn30na1Dj8uEY8t5KdzvD9ea37LRQmfDAIpgeQHd1hoq5N7jlbaOSa0VYhUwseorYdSK82MOiOB-DuyDhd9YFUfuVmTVneQnv33BScT4XoGN4HGuGKhNVzII-z2FqVOAEXlILK5gHaoVB1cZRR7XTc4FOKZy0JsVEZnOAHfQWPStMj6t12a6Tz5BmjALG2ixu1igsxUVCDTk00QCt_HXVwcZLGTeSLBYgmAbY9bpMKMIFMsHf6em2uWPhgW1yEJ9aKGianHZ9veuJmlWZnkULgv8IBmkBFCJGXqCAD1lpuSLkutBXR5i9SwlvXuM4xnayj9Uruh8jMFac49kGKRQ834hrwqXV9c4H69asm4oj6GNaZzK1h4zzod77zjgsbdRakMQ4w6pzU9aBMdcJkgniBodxjL1SX6Rx12sbSpbdK_XliqxAtPpz8HL1Hv6xupSb282PGDRf8kzb4SKQkbnQlqJNVWIpXewo9WZVOYVcRAdqv1gJ40So73LyHMa_lPsjGqPM1DhnYbe8l0e5dt1QPTPSVnbPgtpOY8loFbjRXnijNYjwbmH436LSLBuUZGo_ixCniudHTYxRwpumcujPo9KPlSSPgJC21vXdDEg-4WcQbpYWQZD1zJtybOBhlbsIaO3DZ_ZlcCiYCFptH8WGpt4W686uhWWIEhuh6wCYo7L8PDt3nqHgr_-9kPNgu6jgb7e7vSRlcMwEuFkK80lK2jibJ7fiJz8_xgd",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqJdVomVz7D8d0FkyRqwIWxuATT5dkAVjme8kTM8D6kvppo6d9wJLYRFx3Pf9Y4q21qSEDUA0FfuVkZlGhN1vN-xfK2YjpthW6ccORcauTpZuY_xJX8Bcwpo0q3zlD712d9VX19ZRXul2TnzzgXd3QSC2mpzPw9inm0d9sbGRwCHZg3O2AUJ1dXBprcWy5gxOeVC00A2kg3Hu0IUOd19VcxnVdPCt8WBCDoG4PBUYqR10fU-IP5H1sdGYGjNl3n9fEfAD7GmY_AxQXW4dCg4080iD4GaHZgXAstCCjN_VpkmBmDaH7wliPg4Q-cK_ldG8yEjGv7jHIZsnUsDVw2qLzQB47P3lwIm_97WE1Br2F7XTY46app6z8IzEeDDWU9gwqZbPYfcCqpbuMHZmEEEsVl7BI9pkODOhdnxeVeBst6sNgBe5XVYXnSrSgN1cooLfzKyf1_0k7EmZ7Z08dqvRt7kHnljGdzsBdXOWm-AkSWNtFGksLVXQXjxdmxFE4rkF_kj6HTOE9YrXUrYAl_xKvGQoX87rmn30na1Dj8uEY8t5KdzvD9ea37LRQmfDAIpgeQHd1hoq5N7jlbaOSa0VYhUwseorYdSK82MOiOB-DuyDhd9YFUfuVmTVneQnv33BScT4XoGN4HGuGKhNVzII-z2FqVOAEXlILK5gHaoVB1cZRR7XTc4FOKZy0JsVEZnOAHfQWPStMj6t12a6Tz5BmjALG2ixu1igsxUVCDTk00QCt_HXVwcZLGTeSLBYgmAbY9bpMKMIFMsHf6em2uWPhgW1yEJ9aKGianHZ9veuJmlWZnkULgv8IBmkBFCJGXqCAD1lpuSLkutBXR5i9SwlvXuM4xnayj9Uruh8jMFac49kGKRQ834hrwqXV9c4H69asm4oj6GNaZzK1h4zzod77zjgsbdRakMQ4w6pzU9aBMdcJkgniBodxjL1SX6Rx12sbSpbdK_XliqxAtPpz8HL1Hv6xupSb282PGDRf8kzb4SKQkbnQlqJNVWIpXewo9WZVOYVcRAdqv1gJ40So73LyHMa_lPsjGqPM1DhnYbe8l0e5dt1QPTPSVnbPgtpOY8loFbjRXnijNYjwbmH436LSLBuUZGo_ixCniudHTYxRwpumcujPo9KPlSSPgJC21vXdDEg-4WcQbpYWQZD1zJtybOBhlbsIaO3DZ_ZlcCiYCFptH8WGpt4W686uhWWIEhuh6wCYo7L8PDt3nqHgr_-9kPNgu6jgb7e7vSRlcMwEuFkK80lK2jibJ7fiJz8_xgd",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1144.12,
            "totalPrice": 840
          },
          {
            "allPassengerTotalPrice": 841,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00006a3c1f0e_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gir8TOt-UqIXhJdpKNepZImr4mshjy8c5PtiHghj4AN60HYX18SNSl7cKQvgcYcKXTtreYn2LqTH4Ww_tnpiBpjilqj3kAcniyqX5GUuY-eWQ3aARIEufo7L8F6neRo_SRBb4VmNaPEEXgTMlGsA5sn8bs54xec-DK6TbXJAa8-XD4Mo7qGGhBmepZzdCeNUQKyRjpX6Yc-LiuEjNRAK4DnTaogDbBsNoWbQiLEIcl-Z0cAzN-ILHlXTlDrKZXlg-FzLqW4OjvCxIep6cds6Kd9-sQvJTzgzpPbwEfNxhhTf1DP_xOUAMOSqjSnzyP_pkz33H0R_9S0ikGCxIQBith30CGpu_8D5_JnubSIis-97GeVwEp0wfhTF9ZuLrk_Mztptg15Ri0fshHQ4BFWXA9GlEQpnfRujJgVnYzmtTfl72R0a6HGTqLL6pEo2fm379q6NRLKSV7lX8u5E1yrVfV2phopXjfLP_C5Qt7-2S0mGXEEjh7ZmUd5rvgi6srlqXsMpCuFGjG9OB9jzwyk8cAS2MMVTqbEOVhWxUEd6PgZXpEfU6eS529oZFbETsJ1bDRmcXNCMeh27jZ5hxVXxFN0v3ZLzruu2e9LvnFTBoXEdqAAumvUqaR9nlJtLQygGkqInok11qTb_cGlBVeYU6AoVWYRF7bbtvoVqx-Yz8ChMvba8TzOkVX8GmuK106BIGQYUWSG8N-SO6VF0PWp8zlNnOtTGwN_kBR-P2CxOyG0Yic-EjRWFZ8qjOLu--jBGcxDzs47XwCTWhkPTUNGMKC5rNAa2Hl8Yr5d_Zolcsr9WvGnMLVxYjZJWd9O1WZTXChMisGoFYIY_BeGXcEG5hw3ZSWpqWLWPthOuSfIWWc_fMu0qn_01em3XaXaOM0UKMtaJbDWQD5eBIn1FWUyTKFxRd-dOcxfD-GF5URhaakqUrwbEIyBtc6l22g_cwwkXlo11bulmTbT0kpIVYYb34Z1rufYEitEWLhgdxrMISrdDYGHCLunKfVEkgFuSBCXv90hVXalFF4IUTa1nkJJYmJKY0WaVs5M-sMC0Agms60w3047Ja13PyE69-f1VNOsLyGAoTt6aiBJxkkCmAX0JciwlI5NRoSZfgIJhRYmtG-nY3UDSqIvRf4eAptkf_prL6Ld9K2aFTAhnCpdvVZ96Q3vCGedsWKlYWcHz8zHg4y4gkUdnQbcK9R45FKYayYnN82QJSY-pA8eLsM6znS2-Bs1jb94-ks9FuXwsk7F6gnLiycxPXK72_GJRR9T8vR9tG",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00006a3c1f0e_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gir8TOt-UqIXhJdpKNepZImr4mshjy8c5PtiHghj4AN60HYX18SNSl7cKQvgcYcKXTtreYn2LqTH4Ww_tnpiBpjilqj3kAcniyqX5GUuY-eWQ3aARIEufo7L8F6neRo_SRBb4VmNaPEEXgTMlGsA5sn8bs54xec-DK6TbXJAa8-XD4Mo7qGGhBmepZzdCeNUQKyRjpX6Yc-LiuEjNRAK4DnTaogDbBsNoWbQiLEIcl-Z0cAzN-ILHlXTlDrKZXlg-FzLqW4OjvCxIep6cds6Kd9-sQvJTzgzpPbwEfNxhhTf1DP_xOUAMOSqjSnzyP_pkz33H0R_9S0ikGCxIQBith30CGpu_8D5_JnubSIis-97GeVwEp0wfhTF9ZuLrk_Mztptg15Ri0fshHQ4BFWXA9GlEQpnfRujJgVnYzmtTfl72R0a6HGTqLL6pEo2fm379q6NRLKSV7lX8u5E1yrVfV2phopXjfLP_C5Qt7-2S0mGXEEjh7ZmUd5rvgi6srlqXsMpCuFGjG9OB9jzwyk8cAS2MMVTqbEOVhWxUEd6PgZXpEfU6eS529oZFbETsJ1bDRmcXNCMeh27jZ5hxVXxFN0v3ZLzruu2e9LvnFTBoXEdqAAumvUqaR9nlJtLQygGkqInok11qTb_cGlBVeYU6AoVWYRF7bbtvoVqx-Yz8ChMvba8TzOkVX8GmuK106BIGQYUWSG8N-SO6VF0PWp8zlNnOtTGwN_kBR-P2CxOyG0Yic-EjRWFZ8qjOLu--jBGcxDzs47XwCTWhkPTUNGMKC5rNAa2Hl8Yr5d_Zolcsr9WvGnMLVxYjZJWd9O1WZTXChMisGoFYIY_BeGXcEG5hw3ZSWpqWLWPthOuSfIWWc_fMu0qn_01em3XaXaOM0UKMtaJbDWQD5eBIn1FWUyTKFxRd-dOcxfD-GF5URhaakqUrwbEIyBtc6l22g_cwwkXlo11bulmTbT0kpIVYYb34Z1rufYEitEWLhgdxrMISrdDYGHCLunKfVEkgFuSBCXv90hVXalFF4IUTa1nkJJYmJKY0WaVs5M-sMC0Agms60w3047Ja13PyE69-f1VNOsLyGAoTt6aiBJxkkCmAX0JciwlI5NRoSZfgIJhRYmtG-nY3UDSqIvRf4eAptkf_prL6Ld9K2aFTAhnCpdvVZ96Q3vCGedsWKlYWcHz8zHg4y4gkUdnQbcK9R45FKYayYnN82QJSY-pA8eLsM6znS2-Bs1jb94-ks9FuXwsk7F6gnLiycxPXK72_GJRR9T8vR9tG",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00006a3c1f0e_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gir8TOt-UqIXhJdpKNepZImr4mshjy8c5PtiHghj4AN60HYX18SNSl7cKQvgcYcKXTtreYn2LqTH4Ww_tnpiBpjilqj3kAcniyqX5GUuY-eWQ3aARIEufo7L8F6neRo_SRBb4VmNaPEEXgTMlGsA5sn8bs54xec-DK6TbXJAa8-XD4Mo7qGGhBmepZzdCeNUQKyRjpX6Yc-LiuEjNRAK4DnTaogDbBsNoWbQiLEIcl-Z0cAzN-ILHlXTlDrKZXlg-FzLqW4OjvCxIep6cds6Kd9-sQvJTzgzpPbwEfNxhhTf1DP_xOUAMOSqjSnzyP_pkz33H0R_9S0ikGCxIQBith30CGpu_8D5_JnubSIis-97GeVwEp0wfhTF9ZuLrk_Mztptg15Ri0fshHQ4BFWXA9GlEQpnfRujJgVnYzmtTfl72R0a6HGTqLL6pEo2fm379q6NRLKSV7lX8u5E1yrVfV2phopXjfLP_C5Qt7-2S0mGXEEjh7ZmUd5rvgi6srlqXsMpCuFGjG9OB9jzwyk8cAS2MMVTqbEOVhWxUEd6PgZXpEfU6eS529oZFbETsJ1bDRmcXNCMeh27jZ5hxVXxFN0v3ZLzruu2e9LvnFTBoXEdqAAumvUqaR9nlJtLQygGkqInok11qTb_cGlBVeYU6AoVWYRF7bbtvoVqx-Yz8ChMvba8TzOkVX8GmuK106BIGQYUWSG8N-SO6VF0PWp8zlNnOtTGwN_kBR-P2CxOyG0Yic-EjRWFZ8qjOLu--jBGcxDzs47XwCTWhkPTUNGMKC5rNAa2Hl8Yr5d_Zolcsr9WvGnMLVxYjZJWd9O1WZTXChMisGoFYIY_BeGXcEG5hw3ZSWpqWLWPthOuSfIWWc_fMu0qn_01em3XaXaOM0UKMtaJbDWQD5eBIn1FWUyTKFxRd-dOcxfD-GF5URhaakqUrwbEIyBtc6l22g_cwwkXlo11bulmTbT0kpIVYYb34Z1rufYEitEWLhgdxrMISrdDYGHCLunKfVEkgFuSBCXv90hVXalFF4IUTa1nkJJYmJKY0WaVs5M-sMC0Agms60w3047Ja13PyE69-f1VNOsLyGAoTt6aiBJxkkCmAX0JciwlI5NRoSZfgIJhRYmtG-nY3UDSqIvRf4eAptkf_prL6Ld9K2aFTAhnCpdvVZ96Q3vCGedsWKlYWcHz8zHg4y4gkUdnQbcK9R45FKYayYnN82QJSY-pA8eLsM6znS2-Bs1jb94-ks9FuXwsk7F6gnLiycxPXK72_GJRR9T8vR9tG",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1145.27,
            "totalPrice": 841
          },
          {
            "allPassengerTotalPrice": 844,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gn23BULXMejDC0sTBmywveKfEcS6PS1sqdQ2yUK6D8XNMSdpvLVuDCFXaUxgrUjpyxRBgTbnbP12YCJl4JOzgDotFpNP4wTcxJY06DJLyaBuOVr_B_rrUWc61rv5KHY6rUMBlMCRPFuTpPMfYDp9mWyPN0vtJ_Di8Op4h7MyL9HU8Ncn4gFoJaNw-gJyzgo-l9SuMaEhKWBtRa6atAZ-gXm-rr71-9GN3eDFpB4vXrROqMaS-h_a6UgWILgouZwk45V7PZBoVA5GdtKM5ACzg_ThAHazWZfh0Hhup_dBadiqWxqogK6cCxPsLg_O_cRGy1siryzHwhOk_MVT0VMGR4TurO1U9sU03JwAjgPEODHMM9tsFUnCN79KzSvkEWhF5I7UWs9qL217ELSgHE9M9ASOhVqfXYd7Dng8GwVI9FeykLQ6rOKC4uhcFusrl0hCIoXDoHa8c1OLbtUyzh0jGkgPhWNHTLBvLqZDh3MDP7P4JSlQc9ndx9BCNg8tkieVb8ApiM8nEn-O8_vbq0f1T3XIn1oYJ6wGI7dccXV3vFwBbbhDCezPyLlDIQUAWECKc5WvZO8rQLtkyzcTKjZtyOjskDAg_2PtUrZ2JsNXIgK8TrFWcyWIewe7iEY_ijwU24wsW1meVdhfLZG7aokn1R7Q4dcFoiWuZgLFM8_g87VidlSCWcQeNTGnTTf89PV2HuVDy5sEkQqSGeE1HnOEeSKWehPJCx3RUKerjc-pICvMGI1al6Q7X8iXR1y-HmnlB6Fz8G2h4__1XQpJeEifAVapKpF2GQofG3Kyh_FxK4zT61_miPb_qBGMr0TKn2UCKAm3Oy72C6nOt8o9ubS-7aUa7x2SY8WUfgTZ_s79883vrEffb8a9fIilQXlgAnlhYNHIGb2Pz-fkI2PqjwGXiaeKXcHtfOIflwbbEflB887Hyrjd98SnaTWEFcGY0XK5E9Wjjj8vlj1x7E3dI3SbPdjO8ZjDOuX0W-58BmJV2ffG8mTHWmBmlAytdw4HRxhenoAsHJDlTgS4xC2vWCLnZ9pxH06uxMWeYJ7UN4uvDUO7qLe0cDyJPIRZrTrFBOxi6ImSy_V8QkTyduEBYRDXAY48_mu_pyZIIcZfa_VohMpQJXcN7un3D90wYwaVnRGSxaQ_EhUdSD2EmWotTsx9C0IvIvfSuXRWhcxZgIh7LYBSZ8uXQD2S1MWny9M9MLZNH",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gn23BULXMejDC0sTBmywveKfEcS6PS1sqdQ2yUK6D8XNMSdpvLVuDCFXaUxgrUjpyxRBgTbnbP12YCJl4JOzgDotFpNP4wTcxJY06DJLyaBuOVr_B_rrUWc61rv5KHY6rUMBlMCRPFuTpPMfYDp9mWyPN0vtJ_Di8Op4h7MyL9HU8Ncn4gFoJaNw-gJyzgo-l9SuMaEhKWBtRa6atAZ-gXm-rr71-9GN3eDFpB4vXrROqMaS-h_a6UgWILgouZwk45V7PZBoVA5GdtKM5ACzg_ThAHazWZfh0Hhup_dBadiqWxqogK6cCxPsLg_O_cRGy1siryzHwhOk_MVT0VMGR4TurO1U9sU03JwAjgPEODHMM9tsFUnCN79KzSvkEWhF5I7UWs9qL217ELSgHE9M9ASOhVqfXYd7Dng8GwVI9FeykLQ6rOKC4uhcFusrl0hCIoXDoHa8c1OLbtUyzh0jGkgPhWNHTLBvLqZDh3MDP7P4JSlQc9ndx9BCNg8tkieVb8ApiM8nEn-O8_vbq0f1T3XIn1oYJ6wGI7dccXV3vFwBbbhDCezPyLlDIQUAWECKc5WvZO8rQLtkyzcTKjZtyOjskDAg_2PtUrZ2JsNXIgK8TrFWcyWIewe7iEY_ijwU24wsW1meVdhfLZG7aokn1R7Q4dcFoiWuZgLFM8_g87VidlSCWcQeNTGnTTf89PV2HuVDy5sEkQqSGeE1HnOEeSKWehPJCx3RUKerjc-pICvMGI1al6Q7X8iXR1y-HmnlB6Fz8G2h4__1XQpJeEifAVapKpF2GQofG3Kyh_FxK4zT61_miPb_qBGMr0TKn2UCKAm3Oy72C6nOt8o9ubS-7aUa7x2SY8WUfgTZ_s79883vrEffb8a9fIilQXlgAnlhYNHIGb2Pz-fkI2PqjwGXiaeKXcHtfOIflwbbEflB887Hyrjd98SnaTWEFcGY0XK5E9Wjjj8vlj1x7E3dI3SbPdjO8ZjDOuX0W-58BmJV2ffG8mTHWmBmlAytdw4HRxhenoAsHJDlTgS4xC2vWCLnZ9pxH06uxMWeYJ7UN4uvDUO7qLe0cDyJPIRZrTrFBOxi6ImSy_V8QkTyduEBYRDXAY48_mu_pyZIIcZfa_VohMpQJXcN7un3D90wYwaVnRGSxaQ_EhUdSD2EmWotTsx9C0IvIvfSuXRWhcxZgIh7LYBSZ8uXQD2S1MWny9M9MLZNH",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gn23BULXMejDC0sTBmywveKfEcS6PS1sqdQ2yUK6D8XNMSdpvLVuDCFXaUxgrUjpyxRBgTbnbP12YCJl4JOzgDotFpNP4wTcxJY06DJLyaBuOVr_B_rrUWc61rv5KHY6rUMBlMCRPFuTpPMfYDp9mWyPN0vtJ_Di8Op4h7MyL9HU8Ncn4gFoJaNw-gJyzgo-l9SuMaEhKWBtRa6atAZ-gXm-rr71-9GN3eDFpB4vXrROqMaS-h_a6UgWILgouZwk45V7PZBoVA5GdtKM5ACzg_ThAHazWZfh0Hhup_dBadiqWxqogK6cCxPsLg_O_cRGy1siryzHwhOk_MVT0VMGR4TurO1U9sU03JwAjgPEODHMM9tsFUnCN79KzSvkEWhF5I7UWs9qL217ELSgHE9M9ASOhVqfXYd7Dng8GwVI9FeykLQ6rOKC4uhcFusrl0hCIoXDoHa8c1OLbtUyzh0jGkgPhWNHTLBvLqZDh3MDP7P4JSlQc9ndx9BCNg8tkieVb8ApiM8nEn-O8_vbq0f1T3XIn1oYJ6wGI7dccXV3vFwBbbhDCezPyLlDIQUAWECKc5WvZO8rQLtkyzcTKjZtyOjskDAg_2PtUrZ2JsNXIgK8TrFWcyWIewe7iEY_ijwU24wsW1meVdhfLZG7aokn1R7Q4dcFoiWuZgLFM8_g87VidlSCWcQeNTGnTTf89PV2HuVDy5sEkQqSGeE1HnOEeSKWehPJCx3RUKerjc-pICvMGI1al6Q7X8iXR1y-HmnlB6Fz8G2h4__1XQpJeEifAVapKpF2GQofG3Kyh_FxK4zT61_miPb_qBGMr0TKn2UCKAm3Oy72C6nOt8o9ubS-7aUa7x2SY8WUfgTZ_s79883vrEffb8a9fIilQXlgAnlhYNHIGb2Pz-fkI2PqjwGXiaeKXcHtfOIflwbbEflB887Hyrjd98SnaTWEFcGY0XK5E9Wjjj8vlj1x7E3dI3SbPdjO8ZjDOuX0W-58BmJV2ffG8mTHWmBmlAytdw4HRxhenoAsHJDlTgS4xC2vWCLnZ9pxH06uxMWeYJ7UN4uvDUO7qLe0cDyJPIRZrTrFBOxi6ImSy_V8QkTyduEBYRDXAY48_mu_pyZIIcZfa_VohMpQJXcN7un3D90wYwaVnRGSxaQ_EhUdSD2EmWotTsx9C0IvIvfSuXRWhcxZgIh7LYBSZ8uXQD2S1MWny9M9MLZNH",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1141.53,
            "totalPrice": 844
          },
          {
            "allPassengerTotalPrice": 846,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00002401a9db_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfmnG3O4S0-RIhqCa3q3J8dk3VsN18dpFzwqW0WnZGlWPNfnOFe0QbAiT_BhaXZELBC6IX8-c_6jJqzNvFHyYK3MXgKM9rwFwe5md-eB9THm31sVSTV8WsJDSV6gg_iVMwaePCqx1pBmdKdHQBBN-5JyUsBa5kyKHLkNTARVH2H88Jh8unutxi1wri1nhiHybW2vJQoaTMEoK_ET5LW2isMiGTD2kOowVrq6vJlum_ztlFtcEfLToY1Is4GqVwrOGt5gLD6ZPPfa92-i9LbGod2Q9nzfIHEAjtIR3jHtcTW8AyC1OGeNRrkpusBZOwl0RJvLeosF1s_870onO-4_DNiexILASvWzjU9USIG-xXy1fQAZUqS4OdsCPguGLPn0HajBPcZi9LmA-bkV9J2osMaFjBCgCSCNa3jclPl27i2XpKjW6JgqTrvK-hvcoz63g8rUq0oQl3NRYhe0KxIDmMKArgRBlXjwS-BlM3DliE_D6mngbkuP68GYXS_RVI5fG-NF2dHzm9XZmttnv4Y82GXKRBSATmmWmY_BJR8oUpqgGXm_HoN_0mnvN6NUAO1RlAN-HugxsuhA1vsJKot77gxPm2SA5EW4Z9v__XPmW4rAeHkGLPweDOLng_PFhfn6WijlBBElLNpGw1TMCT1vODc4BJCQFjT6avDnpklfw-5ohbr3jJGQmehl_unSo5moDzyclpCj885uJnXZPTCwEuaLuEiln24soO0-KoX_zO-X9YWiRT48X57xOCp4EjjVBDkrt4qJOPUWvk1P3NF3kYoa7kwJiCIfK33C3bTmm0WOGX-7tO0CdWsXutI0r2ViB0syKsZ1sYSyBq89kxHdZXl-rZFwWEizNlyyGCnR1oqB6gD6Pn3EHOeg6mFMDmJqcrYteqcOvRtjdWEcDzejuGu8r0djYnJImYNmQSQCV0cS-QoMJ_yvfsMqlK68W3CWfEjXixeNci3eYgqAO23CMptlVTez7FXLSOj8uRLkWjMAOBoJOUDElG-sSVQL_4ffUcsekuZZCg8WtaVLUKGt16KX_d92og5wEPyUqbHTa9_p0_LMgvo8C82OUqBhwW7CHFQpXU8xKnqIa-LsepDdm5L8PdzakH2EnZ55gC_jpJ452Og23nbEBIZPwHO4eEKDFxSA5MGJm6Kn4OJVz_pPYFwkACv8CzddCyX3wbxZKt5EaEHRvsRbWFhdlS_R9l0rQYtiltqOrqD_-EFn-PYRapEVjbQv-2whTtQ0a1t2LX3ba1yGJ40PtQ-K58QBsNDaDzEKRODoAnnOtq3NyFJeMnnbYyGpR2XvkCYKcbBBvzI0=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00002401a9db_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfmnG3O4S0-RIhqCa3q3J8dk3VsN18dpFzwqW0WnZGlWPNfnOFe0QbAiT_BhaXZELBC6IX8-c_6jJqzNvFHyYK3MXgKM9rwFwe5md-eB9THm31sVSTV8WsJDSV6gg_iVMwaePCqx1pBmdKdHQBBN-5JyUsBa5kyKHLkNTARVH2H88Jh8unutxi1wri1nhiHybW2vJQoaTMEoK_ET5LW2isMiGTD2kOowVrq6vJlum_ztlFtcEfLToY1Is4GqVwrOGt5gLD6ZPPfa92-i9LbGod2Q9nzfIHEAjtIR3jHtcTW8AyC1OGeNRrkpusBZOwl0RJvLeosF1s_870onO-4_DNiexILASvWzjU9USIG-xXy1fQAZUqS4OdsCPguGLPn0HajBPcZi9LmA-bkV9J2osMaFjBCgCSCNa3jclPl27i2XpKjW6JgqTrvK-hvcoz63g8rUq0oQl3NRYhe0KxIDmMKArgRBlXjwS-BlM3DliE_D6mngbkuP68GYXS_RVI5fG-NF2dHzm9XZmttnv4Y82GXKRBSATmmWmY_BJR8oUpqgGXm_HoN_0mnvN6NUAO1RlAN-HugxsuhA1vsJKot77gxPm2SA5EW4Z9v__XPmW4rAeHkGLPweDOLng_PFhfn6WijlBBElLNpGw1TMCT1vODc4BJCQFjT6avDnpklfw-5ohbr3jJGQmehl_unSo5moDzyclpCj885uJnXZPTCwEuaLuEiln24soO0-KoX_zO-X9YWiRT48X57xOCp4EjjVBDkrt4qJOPUWvk1P3NF3kYoa7kwJiCIfK33C3bTmm0WOGX-7tO0CdWsXutI0r2ViB0syKsZ1sYSyBq89kxHdZXl-rZFwWEizNlyyGCnR1oqB6gD6Pn3EHOeg6mFMDmJqcrYteqcOvRtjdWEcDzejuGu8r0djYnJImYNmQSQCV0cS-QoMJ_yvfsMqlK68W3CWfEjXixeNci3eYgqAO23CMptlVTez7FXLSOj8uRLkWjMAOBoJOUDElG-sSVQL_4ffUcsekuZZCg8WtaVLUKGt16KX_d92og5wEPyUqbHTa9_p0_LMgvo8C82OUqBhwW7CHFQpXU8xKnqIa-LsepDdm5L8PdzakH2EnZ55gC_jpJ452Og23nbEBIZPwHO4eEKDFxSA5MGJm6Kn4OJVz_pPYFwkACv8CzddCyX3wbxZKt5EaEHRvsRbWFhdlS_R9l0rQYtiltqOrqD_-EFn-PYRapEVjbQv-2whTtQ0a1t2LX3ba1yGJ40PtQ-K58QBsNDaDzEKRODoAnnOtq3NyFJeMnnbYyGpR2XvkCYKcbBBvzI0=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00002401a9db_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfmnG3O4S0-RIhqCa3q3J8dk3VsN18dpFzwqW0WnZGlWPNfnOFe0QbAiT_BhaXZELBC6IX8-c_6jJqzNvFHyYK3MXgKM9rwFwe5md-eB9THm31sVSTV8WsJDSV6gg_iVMwaePCqx1pBmdKdHQBBN-5JyUsBa5kyKHLkNTARVH2H88Jh8unutxi1wri1nhiHybW2vJQoaTMEoK_ET5LW2isMiGTD2kOowVrq6vJlum_ztlFtcEfLToY1Is4GqVwrOGt5gLD6ZPPfa92-i9LbGod2Q9nzfIHEAjtIR3jHtcTW8AyC1OGeNRrkpusBZOwl0RJvLeosF1s_870onO-4_DNiexILASvWzjU9USIG-xXy1fQAZUqS4OdsCPguGLPn0HajBPcZi9LmA-bkV9J2osMaFjBCgCSCNa3jclPl27i2XpKjW6JgqTrvK-hvcoz63g8rUq0oQl3NRYhe0KxIDmMKArgRBlXjwS-BlM3DliE_D6mngbkuP68GYXS_RVI5fG-NF2dHzm9XZmttnv4Y82GXKRBSATmmWmY_BJR8oUpqgGXm_HoN_0mnvN6NUAO1RlAN-HugxsuhA1vsJKot77gxPm2SA5EW4Z9v__XPmW4rAeHkGLPweDOLng_PFhfn6WijlBBElLNpGw1TMCT1vODc4BJCQFjT6avDnpklfw-5ohbr3jJGQmehl_unSo5moDzyclpCj885uJnXZPTCwEuaLuEiln24soO0-KoX_zO-X9YWiRT48X57xOCp4EjjVBDkrt4qJOPUWvk1P3NF3kYoa7kwJiCIfK33C3bTmm0WOGX-7tO0CdWsXutI0r2ViB0syKsZ1sYSyBq89kxHdZXl-rZFwWEizNlyyGCnR1oqB6gD6Pn3EHOeg6mFMDmJqcrYteqcOvRtjdWEcDzejuGu8r0djYnJImYNmQSQCV0cS-QoMJ_yvfsMqlK68W3CWfEjXixeNci3eYgqAO23CMptlVTez7FXLSOj8uRLkWjMAOBoJOUDElG-sSVQL_4ffUcsekuZZCg8WtaVLUKGt16KX_d92og5wEPyUqbHTa9_p0_LMgvo8C82OUqBhwW7CHFQpXU8xKnqIa-LsepDdm5L8PdzakH2EnZ55gC_jpJ452Og23nbEBIZPwHO4eEKDFxSA5MGJm6Kn4OJVz_pPYFwkACv8CzddCyX3wbxZKt5EaEHRvsRbWFhdlS_R9l0rQYtiltqOrqD_-EFn-PYRapEVjbQv-2whTtQ0a1t2LX3ba1yGJ40PtQ-K58QBsNDaDzEKRODoAnnOtq3NyFJeMnnbYyGpR2XvkCYKcbBBvzI0=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1148.63,
            "totalPrice": 846
          },
          {
            "allPassengerTotalPrice": 848,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7fd9ZPcTp9uUJtDwZmuYER26hC3eIWwxPHmasAxJu_Wuh6eech2mGCwVrZFBcY31PXdI7lbGI_uGC6QrjlbaNfCIu4hxS9HN9QQR03sQihZboxrJx3Oi8anynjfLkt6BW_14eWlukRJxbr4-ed3Lgudr0CuGKU6mOHmuYMrtpZxYYgqDuJyPKoGfHn2W6h67BbinkrTe1z_nOdTJcITtoa9lT6VDSO6Xw8uoLnRwwidSf0lhnrmVPlAb8T8B23td7c8bUpmXML404HvqiUeENIg59nhBqo1Uu7MMvhb9_D50A1SokbWUdIXYT41rI_aHiueslQrrf55khapUAs0GR6NR6YPFQm8pGopwnKq62JnrgyGA59kOxh3B9YfL-ctTBUaDYW7dfKzxem30S3haMMHn27iNmf6CK8StCkFBAp_WBoo3A8EFcQnr55dVW0Oq8HGFSs9hC39xxcSDSnHRWRaFiBeigoSIdYVZOwxzX0ljKvf4PBe5fey-B8-b5e7hhvxynmDTtNK1o7nqNtNkHfrMTZqJYpnARJQxouCvd0PMTChPLndlZ-sopEkS6K-uKwT2wzRXVWO-8oZ8QgpRa4JLvpDyoqvj_O-c62N1RLNnCPJCltjIKhXVjVxs0R3l9shzMwpTNK2fmGWA9_bdUHyStS3CZEYeFb0FaP0DbMmmNm7E-cl8iEIKuCba7JKueSx8Y3BQPuOAM-bPqG1T6dl1QNi-rSW516sqkFQpP13t9Ma94mb4NNGuTr8IBzWsa0aP9DwM6Bjv2BRa4g6TY7uornmP5n90WVSM0KQ5FniI_Kmwfuvfcqf6oCO7NjeqeHZKVbRWd-j_S5cKr8um7Kui_b53tB4rFnaJR5I4nywzl0NSU6cNmvnHs5zmdVfgFeUJH0SiwWm-QTaQjH0QmpsLgacbvHCAuuoaZFn05O14riJQ0GAbtsIOzYnwtvLa7P5IkspIRv0bXdcMnkI-pRduCZSE05Gei4LpAduGkOw8yMsSEwh1agtUuwZImuP9lhcV9YhhY6dS6-y-XxEl5YUxpSKrWGjjEs3tKZzBHb5ik-uVrZk0xDn1xw3Ls30IoKbKP6AdY4ywM6atDCLXpahD4Qh43NCZY0aviFyOlDwNSU_yB9aGP4Nk7UAyQUJGqINvvEnz7PzSR0JRlhPxL1HvEVS0dXgDYBjen2xOMMDj_q-N8-9OZAodinz3saUi0njHLu7r79SCKXQM5O8GVw==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7fd9ZPcTp9uUJtDwZmuYER26hC3eIWwxPHmasAxJu_Wuh6eech2mGCwVrZFBcY31PXdI7lbGI_uGC6QrjlbaNfCIu4hxS9HN9QQR03sQihZboxrJx3Oi8anynjfLkt6BW_14eWlukRJxbr4-ed3Lgudr0CuGKU6mOHmuYMrtpZxYYgqDuJyPKoGfHn2W6h67BbinkrTe1z_nOdTJcITtoa9lT6VDSO6Xw8uoLnRwwidSf0lhnrmVPlAb8T8B23td7c8bUpmXML404HvqiUeENIg59nhBqo1Uu7MMvhb9_D50A1SokbWUdIXYT41rI_aHiueslQrrf55khapUAs0GR6NR6YPFQm8pGopwnKq62JnrgyGA59kOxh3B9YfL-ctTBUaDYW7dfKzxem30S3haMMHn27iNmf6CK8StCkFBAp_WBoo3A8EFcQnr55dVW0Oq8HGFSs9hC39xxcSDSnHRWRaFiBeigoSIdYVZOwxzX0ljKvf4PBe5fey-B8-b5e7hhvxynmDTtNK1o7nqNtNkHfrMTZqJYpnARJQxouCvd0PMTChPLndlZ-sopEkS6K-uKwT2wzRXVWO-8oZ8QgpRa4JLvpDyoqvj_O-c62N1RLNnCPJCltjIKhXVjVxs0R3l9shzMwpTNK2fmGWA9_bdUHyStS3CZEYeFb0FaP0DbMmmNm7E-cl8iEIKuCba7JKueSx8Y3BQPuOAM-bPqG1T6dl1QNi-rSW516sqkFQpP13t9Ma94mb4NNGuTr8IBzWsa0aP9DwM6Bjv2BRa4g6TY7uornmP5n90WVSM0KQ5FniI_Kmwfuvfcqf6oCO7NjeqeHZKVbRWd-j_S5cKr8um7Kui_b53tB4rFnaJR5I4nywzl0NSU6cNmvnHs5zmdVfgFeUJH0SiwWm-QTaQjH0QmpsLgacbvHCAuuoaZFn05O14riJQ0GAbtsIOzYnwtvLa7P5IkspIRv0bXdcMnkI-pRduCZSE05Gei4LpAduGkOw8yMsSEwh1agtUuwZImuP9lhcV9YhhY6dS6-y-XxEl5YUxpSKrWGjjEs3tKZzBHb5ik-uVrZk0xDn1xw3Ls30IoKbKP6AdY4ywM6atDCLXpahD4Qh43NCZY0aviFyOlDwNSU_yB9aGP4Nk7UAyQUJGqINvvEnz7PzSR0JRlhPxL1HvEVS0dXgDYBjen2xOMMDj_q-N8-9OZAodinz3saUi0njHLu7r79SCKXQM5O8GVw==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7fd9ZPcTp9uUJtDwZmuYER26hC3eIWwxPHmasAxJu_Wuh6eech2mGCwVrZFBcY31PXdI7lbGI_uGC6QrjlbaNfCIu4hxS9HN9QQR03sQihZboxrJx3Oi8anynjfLkt6BW_14eWlukRJxbr4-ed3Lgudr0CuGKU6mOHmuYMrtpZxYYgqDuJyPKoGfHn2W6h67BbinkrTe1z_nOdTJcITtoa9lT6VDSO6Xw8uoLnRwwidSf0lhnrmVPlAb8T8B23td7c8bUpmXML404HvqiUeENIg59nhBqo1Uu7MMvhb9_D50A1SokbWUdIXYT41rI_aHiueslQrrf55khapUAs0GR6NR6YPFQm8pGopwnKq62JnrgyGA59kOxh3B9YfL-ctTBUaDYW7dfKzxem30S3haMMHn27iNmf6CK8StCkFBAp_WBoo3A8EFcQnr55dVW0Oq8HGFSs9hC39xxcSDSnHRWRaFiBeigoSIdYVZOwxzX0ljKvf4PBe5fey-B8-b5e7hhvxynmDTtNK1o7nqNtNkHfrMTZqJYpnARJQxouCvd0PMTChPLndlZ-sopEkS6K-uKwT2wzRXVWO-8oZ8QgpRa4JLvpDyoqvj_O-c62N1RLNnCPJCltjIKhXVjVxs0R3l9shzMwpTNK2fmGWA9_bdUHyStS3CZEYeFb0FaP0DbMmmNm7E-cl8iEIKuCba7JKueSx8Y3BQPuOAM-bPqG1T6dl1QNi-rSW516sqkFQpP13t9Ma94mb4NNGuTr8IBzWsa0aP9DwM6Bjv2BRa4g6TY7uornmP5n90WVSM0KQ5FniI_Kmwfuvfcqf6oCO7NjeqeHZKVbRWd-j_S5cKr8um7Kui_b53tB4rFnaJR5I4nywzl0NSU6cNmvnHs5zmdVfgFeUJH0SiwWm-QTaQjH0QmpsLgacbvHCAuuoaZFn05O14riJQ0GAbtsIOzYnwtvLa7P5IkspIRv0bXdcMnkI-pRduCZSE05Gei4LpAduGkOw8yMsSEwh1agtUuwZImuP9lhcV9YhhY6dS6-y-XxEl5YUxpSKrWGjjEs3tKZzBHb5ik-uVrZk0xDn1xw3Ls30IoKbKP6AdY4ywM6atDCLXpahD4Qh43NCZY0aviFyOlDwNSU_yB9aGP4Nk7UAyQUJGqINvvEnz7PzSR0JRlhPxL1HvEVS0dXgDYBjen2xOMMDj_q-N8-9OZAodinz3saUi0njHLu7r79SCKXQM5O8GVw==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1143.31,
            "totalPrice": 848
          },
          {
            "allPassengerTotalPrice": 848,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9hus-bNCcYfiUE10HKzQlyg6fIRBH0ny0QlU3duK_9JLdluIxTGWBoixy1F5U35HcJCvdYkHPYVlCH57ZW0AHo8JLqi5-8m0vREbznbfdvngloejckkH_ffVnmS6X55H3Ub7yNRwG0kA9cn-Mb5h7kDI14Cd8EXpwlz1REv3qU2Fom0IiodjPoNDZjX25DhKoln9NacvLq_ex768i3Oq3tOS4VoAQsfVLXyu_v5ammfJ_TKGWQQnDLh11DJoxuoYfbKJWBk2ltlzRKlhqtK_PiQAWqn2uoGSLabcQR3pPp9o5jnD_2IFIj29qc0eKEV4a-Me61Hploh3x8ljMlGiS_az634jtgA4yt3TWbcaMcbr-lSo6jR6nWnpJuxdVfG5UKRuXa5A6tkrFVdjOdvwLp-cJJeMTvtYAc0w4jCOopjMOK3Ov4ZiocY4tOjMLm06Lo-uGvOFdnydNvsIdsFAjLsuZ8HCCAcnWG9qzbjyRb5G21DlNavd9mN-jqWdz8YRPSNIXwnotn-DLD4GtYzjHzZj_Rxxofeo3BMtIzEB44-l5IjYq0Qqwh8__MbtaKWTbuNWE_lzILR2ogSl9mGGfAQDGelLbK_d3yM7qodqp8hWnybbYnbtIY9oW9uE13bJCXO02lLWr4OuMGQN6NbURkaigneLHbZ2IHs1K-M6BWOfa67OtUNIaniDpETpbM1R45gbrdjG1yFMuqw9zut9vxWK7d_Bj8s7mmGsUYZPBF29D7plCjRELC-EMM_tWnw85sfiKQ6Ai7ACf2d6iFySWICz54z-4xgEVnvthGR3pVRrZqFi8NVJ1SoV15e8I23Aa_1_bUL0uuNBVT2y6XSmpjDSLHyRteqGEvtLljv5xd1e1xaasyaL-qoXIpX0QyWp29noviJ08hp2N7n1-R79k17JCyINJlXJJNlwR2CKywuOQRu_SloZYBxwJ5sCbRxLCsgEhm9wByL8AmTNky_Ym_WzJz3MKNHHjbQ9vDvV48ZuyY9FRjndrmAEpjdb95dhOUw-gzTLflqrXJp8d5jgas4YogB2UWFSYFAwszLbAFc4Ft2NKHJz2gzGsIC1enEfGWp6vadwoawAqsRZrqVbVMhj_juYGacSzO4qzYksASZz2LqxNXFwSVIWFWwsciflLbFgfU1SuoL_wjBVHLNs9AfuDOcsh5lMtiYgZGJJ5lZH9yvvJQ5oi-ewkr6eUVer3NlOtDBzOQ2gvMsd4GgJTA==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9hus-bNCcYfiUE10HKzQlyg6fIRBH0ny0QlU3duK_9JLdluIxTGWBoixy1F5U35HcJCvdYkHPYVlCH57ZW0AHo8JLqi5-8m0vREbznbfdvngloejckkH_ffVnmS6X55H3Ub7yNRwG0kA9cn-Mb5h7kDI14Cd8EXpwlz1REv3qU2Fom0IiodjPoNDZjX25DhKoln9NacvLq_ex768i3Oq3tOS4VoAQsfVLXyu_v5ammfJ_TKGWQQnDLh11DJoxuoYfbKJWBk2ltlzRKlhqtK_PiQAWqn2uoGSLabcQR3pPp9o5jnD_2IFIj29qc0eKEV4a-Me61Hploh3x8ljMlGiS_az634jtgA4yt3TWbcaMcbr-lSo6jR6nWnpJuxdVfG5UKRuXa5A6tkrFVdjOdvwLp-cJJeMTvtYAc0w4jCOopjMOK3Ov4ZiocY4tOjMLm06Lo-uGvOFdnydNvsIdsFAjLsuZ8HCCAcnWG9qzbjyRb5G21DlNavd9mN-jqWdz8YRPSNIXwnotn-DLD4GtYzjHzZj_Rxxofeo3BMtIzEB44-l5IjYq0Qqwh8__MbtaKWTbuNWE_lzILR2ogSl9mGGfAQDGelLbK_d3yM7qodqp8hWnybbYnbtIY9oW9uE13bJCXO02lLWr4OuMGQN6NbURkaigneLHbZ2IHs1K-M6BWOfa67OtUNIaniDpETpbM1R45gbrdjG1yFMuqw9zut9vxWK7d_Bj8s7mmGsUYZPBF29D7plCjRELC-EMM_tWnw85sfiKQ6Ai7ACf2d6iFySWICz54z-4xgEVnvthGR3pVRrZqFi8NVJ1SoV15e8I23Aa_1_bUL0uuNBVT2y6XSmpjDSLHyRteqGEvtLljv5xd1e1xaasyaL-qoXIpX0QyWp29noviJ08hp2N7n1-R79k17JCyINJlXJJNlwR2CKywuOQRu_SloZYBxwJ5sCbRxLCsgEhm9wByL8AmTNky_Ym_WzJz3MKNHHjbQ9vDvV48ZuyY9FRjndrmAEpjdb95dhOUw-gzTLflqrXJp8d5jgas4YogB2UWFSYFAwszLbAFc4Ft2NKHJz2gzGsIC1enEfGWp6vadwoawAqsRZrqVbVMhj_juYGacSzO4qzYksASZz2LqxNXFwSVIWFWwsciflLbFgfU1SuoL_wjBVHLNs9AfuDOcsh5lMtiYgZGJJ5lZH9yvvJQ5oi-ewkr6eUVer3NlOtDBzOQ2gvMsd4GgJTA==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9hus-bNCcYfiUE10HKzQlyg6fIRBH0ny0QlU3duK_9JLdluIxTGWBoixy1F5U35HcJCvdYkHPYVlCH57ZW0AHo8JLqi5-8m0vREbznbfdvngloejckkH_ffVnmS6X55H3Ub7yNRwG0kA9cn-Mb5h7kDI14Cd8EXpwlz1REv3qU2Fom0IiodjPoNDZjX25DhKoln9NacvLq_ex768i3Oq3tOS4VoAQsfVLXyu_v5ammfJ_TKGWQQnDLh11DJoxuoYfbKJWBk2ltlzRKlhqtK_PiQAWqn2uoGSLabcQR3pPp9o5jnD_2IFIj29qc0eKEV4a-Me61Hploh3x8ljMlGiS_az634jtgA4yt3TWbcaMcbr-lSo6jR6nWnpJuxdVfG5UKRuXa5A6tkrFVdjOdvwLp-cJJeMTvtYAc0w4jCOopjMOK3Ov4ZiocY4tOjMLm06Lo-uGvOFdnydNvsIdsFAjLsuZ8HCCAcnWG9qzbjyRb5G21DlNavd9mN-jqWdz8YRPSNIXwnotn-DLD4GtYzjHzZj_Rxxofeo3BMtIzEB44-l5IjYq0Qqwh8__MbtaKWTbuNWE_lzILR2ogSl9mGGfAQDGelLbK_d3yM7qodqp8hWnybbYnbtIY9oW9uE13bJCXO02lLWr4OuMGQN6NbURkaigneLHbZ2IHs1K-M6BWOfa67OtUNIaniDpETpbM1R45gbrdjG1yFMuqw9zut9vxWK7d_Bj8s7mmGsUYZPBF29D7plCjRELC-EMM_tWnw85sfiKQ6Ai7ACf2d6iFySWICz54z-4xgEVnvthGR3pVRrZqFi8NVJ1SoV15e8I23Aa_1_bUL0uuNBVT2y6XSmpjDSLHyRteqGEvtLljv5xd1e1xaasyaL-qoXIpX0QyWp29noviJ08hp2N7n1-R79k17JCyINJlXJJNlwR2CKywuOQRu_SloZYBxwJ5sCbRxLCsgEhm9wByL8AmTNky_Ym_WzJz3MKNHHjbQ9vDvV48ZuyY9FRjndrmAEpjdb95dhOUw-gzTLflqrXJp8d5jgas4YogB2UWFSYFAwszLbAFc4Ft2NKHJz2gzGsIC1enEfGWp6vadwoawAqsRZrqVbVMhj_juYGacSzO4qzYksASZz2LqxNXFwSVIWFWwsciflLbFgfU1SuoL_wjBVHLNs9AfuDOcsh5lMtiYgZGJJ5lZH9yvvJQ5oi-ewkr6eUVer3NlOtDBzOQ2gvMsd4GgJTA==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1143.98,
            "totalPrice": 848
          },
          {
            "allPassengerTotalPrice": 851,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnAxcira5n_YSodOk8ULy8dkVujDxw63lR_smeJMd1YPLWpwFeZNRwG2g-AkAIAB7rCvBKqmBLsHWYyLiif-S139BebJeB_gfjGyq7Lm846D07gs0cz2Zjd0xwtYxoeSSVncGT-EBZhC4OAVvUpX6oNwsRnSUoW8v46oOOcjXdxoYGOgAoitnF9Jje7aDVxfGSbXScnGr6MELMdhJCvP54LXA-PtFsBo-Q4nX3Z3cKpEeXn5bx4WantCy1FRhB0FIk50DHFxcp3B51-YOSr-IgwIw5sCc8AMo0P2SB_xs2IODRGF8xXfcRKZ3v65gOUYwFCtBp3T9MqyPE6NdDHPuBlOoLf7aBq4waZNuddODZXwfETjsdBBBsAiEcUt_e7duOzrvZbzvTJfcP78Feg3i4TectWWJA_Via1cn0tjr_IJsa6g9F1KMBN_qL63TnHTMY-ykmT8jVQ1Cbw-l_MhYNgS8OKbB7Ws5y7cH4v_gHwToSPt6x9HJbhidVjzxCwciue4mieZj6EUifzMOlLKfHPQSPtx70GkkQGfgl_NAzGb6_sbr0DWe38OnmhrOPgNtXm2i5sxH9OFj8ecUpUlonNhoXha1g4VjjCVs7aQKflYw8CsZZOZdZ6U5wf7bQ-xi40Q2gn8Sho6GRdfI7Yqg_nnHhW5LYUDA3CvibY2PQffyXr68cZH_1_-X8L0YrPCllEDI2UCcXd3vy9ojuQvwrHA1FMt12SpDt8Xbalfbi6Zd1aICrKQ3_kmau7brep9wypBNEZYulhf-XqPXR-kTIw2AFIKiY2ta-Bh_7UICnZDKPz4J_3UJ-1Vt6ryDrtoRkuiwCYo34Lgw-7CqA-g5F1hdze-B009gsj2d5VXjrmNbPrLRLpxfZ2laAvcmf9ilQ9XKt-Os2IllDumFCq_ouo4dADo5tcdmbICABb01ywFRvEtJ2QKN9-ClyK5JfbEff-3k38fdFnXhm5m6b8fmn0EQmr9j8_OrS_Nw3bY9LqC22yZ4gptclZm9pA3IvJ8p9i-5CqWY5da7uwqiSvwNKNhkUDfdAIeUr4fPZzsfMzL3ND0xhnGpbwNLCnYb_aIHWAGFv6i8k0Ain9kboEQySxLgbDTlWfIcK7oHOWP8aTLYyWAPNrDqJoLNaEvyFhkd88UKbHTLkAsK6vpqtEhzpf-LjiQk9nqFV_ub11CcEqUl8pVWFNt7cmVEvNuBIfXN",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnAxcira5n_YSodOk8ULy8dkVujDxw63lR_smeJMd1YPLWpwFeZNRwG2g-AkAIAB7rCvBKqmBLsHWYyLiif-S139BebJeB_gfjGyq7Lm846D07gs0cz2Zjd0xwtYxoeSSVncGT-EBZhC4OAVvUpX6oNwsRnSUoW8v46oOOcjXdxoYGOgAoitnF9Jje7aDVxfGSbXScnGr6MELMdhJCvP54LXA-PtFsBo-Q4nX3Z3cKpEeXn5bx4WantCy1FRhB0FIk50DHFxcp3B51-YOSr-IgwIw5sCc8AMo0P2SB_xs2IODRGF8xXfcRKZ3v65gOUYwFCtBp3T9MqyPE6NdDHPuBlOoLf7aBq4waZNuddODZXwfETjsdBBBsAiEcUt_e7duOzrvZbzvTJfcP78Feg3i4TectWWJA_Via1cn0tjr_IJsa6g9F1KMBN_qL63TnHTMY-ykmT8jVQ1Cbw-l_MhYNgS8OKbB7Ws5y7cH4v_gHwToSPt6x9HJbhidVjzxCwciue4mieZj6EUifzMOlLKfHPQSPtx70GkkQGfgl_NAzGb6_sbr0DWe38OnmhrOPgNtXm2i5sxH9OFj8ecUpUlonNhoXha1g4VjjCVs7aQKflYw8CsZZOZdZ6U5wf7bQ-xi40Q2gn8Sho6GRdfI7Yqg_nnHhW5LYUDA3CvibY2PQffyXr68cZH_1_-X8L0YrPCllEDI2UCcXd3vy9ojuQvwrHA1FMt12SpDt8Xbalfbi6Zd1aICrKQ3_kmau7brep9wypBNEZYulhf-XqPXR-kTIw2AFIKiY2ta-Bh_7UICnZDKPz4J_3UJ-1Vt6ryDrtoRkuiwCYo34Lgw-7CqA-g5F1hdze-B009gsj2d5VXjrmNbPrLRLpxfZ2laAvcmf9ilQ9XKt-Os2IllDumFCq_ouo4dADo5tcdmbICABb01ywFRvEtJ2QKN9-ClyK5JfbEff-3k38fdFnXhm5m6b8fmn0EQmr9j8_OrS_Nw3bY9LqC22yZ4gptclZm9pA3IvJ8p9i-5CqWY5da7uwqiSvwNKNhkUDfdAIeUr4fPZzsfMzL3ND0xhnGpbwNLCnYb_aIHWAGFv6i8k0Ain9kboEQySxLgbDTlWfIcK7oHOWP8aTLYyWAPNrDqJoLNaEvyFhkd88UKbHTLkAsK6vpqtEhzpf-LjiQk9nqFV_ub11CcEqUl8pVWFNt7cmVEvNuBIfXN",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnAxcira5n_YSodOk8ULy8dkVujDxw63lR_smeJMd1YPLWpwFeZNRwG2g-AkAIAB7rCvBKqmBLsHWYyLiif-S139BebJeB_gfjGyq7Lm846D07gs0cz2Zjd0xwtYxoeSSVncGT-EBZhC4OAVvUpX6oNwsRnSUoW8v46oOOcjXdxoYGOgAoitnF9Jje7aDVxfGSbXScnGr6MELMdhJCvP54LXA-PtFsBo-Q4nX3Z3cKpEeXn5bx4WantCy1FRhB0FIk50DHFxcp3B51-YOSr-IgwIw5sCc8AMo0P2SB_xs2IODRGF8xXfcRKZ3v65gOUYwFCtBp3T9MqyPE6NdDHPuBlOoLf7aBq4waZNuddODZXwfETjsdBBBsAiEcUt_e7duOzrvZbzvTJfcP78Feg3i4TectWWJA_Via1cn0tjr_IJsa6g9F1KMBN_qL63TnHTMY-ykmT8jVQ1Cbw-l_MhYNgS8OKbB7Ws5y7cH4v_gHwToSPt6x9HJbhidVjzxCwciue4mieZj6EUifzMOlLKfHPQSPtx70GkkQGfgl_NAzGb6_sbr0DWe38OnmhrOPgNtXm2i5sxH9OFj8ecUpUlonNhoXha1g4VjjCVs7aQKflYw8CsZZOZdZ6U5wf7bQ-xi40Q2gn8Sho6GRdfI7Yqg_nnHhW5LYUDA3CvibY2PQffyXr68cZH_1_-X8L0YrPCllEDI2UCcXd3vy9ojuQvwrHA1FMt12SpDt8Xbalfbi6Zd1aICrKQ3_kmau7brep9wypBNEZYulhf-XqPXR-kTIw2AFIKiY2ta-Bh_7UICnZDKPz4J_3UJ-1Vt6ryDrtoRkuiwCYo34Lgw-7CqA-g5F1hdze-B009gsj2d5VXjrmNbPrLRLpxfZ2laAvcmf9ilQ9XKt-Os2IllDumFCq_ouo4dADo5tcdmbICABb01ywFRvEtJ2QKN9-ClyK5JfbEff-3k38fdFnXhm5m6b8fmn0EQmr9j8_OrS_Nw3bY9LqC22yZ4gptclZm9pA3IvJ8p9i-5CqWY5da7uwqiSvwNKNhkUDfdAIeUr4fPZzsfMzL3ND0xhnGpbwNLCnYb_aIHWAGFv6i8k0Ain9kboEQySxLgbDTlWfIcK7oHOWP8aTLYyWAPNrDqJoLNaEvyFhkd88UKbHTLkAsK6vpqtEhzpf-LjiQk9nqFV_ub11CcEqUl8pVWFNt7cmVEvNuBIfXN",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1146.47,
            "totalPrice": 851
          },
          {
            "allPassengerTotalPrice": 853,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00006a3c1f0e_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gr15lzbFwUf8R7o73QRNlCcUb6myDmWnRhS8UEcJ5Cf_DgNrcPYN1qvx1TxlYkDYI0v4XFW0Hdxk-wRvio6j8CSXE0-MkkcdsdpZ2A-4D918K9zi6n38P1qcZdLbYzJw9d0PNJaRtD8XZmqmT8_dl5K5Nb_BN2p0QNmg_zKUr3LmB4eNt8IjVVt7IH7s7rNcTYqtkjqsSy3lkFlOZlGxxphDe56xVbmveG0VPuD1V7c6Py_S28vRO6huIVptuvZJT0fWD-LaBK6hIZ4F3Dp8P13_D5gIgJTutO-y0SE26e9cj3ZNUGintMzRovRCvcLI3tOCx1lg202yd32wQBELn4E40_fZkuDPfXrOiOlKC8lmbecWRMQj_k9bMRSbm7LT69srLRXGQRZL3bp1-Csj0p8-pMK1v1YFUXU1fip5LGoYLbWJxOk4Wdj4_HkeW6WK8W5I8u7XI3VfS3L6y680nPaHLB6fs5neTQmyezukTEX-9O97fhSkmSUPIbP4S2qJ-qel7H4NgPoHvp6xfjoozOTh4D_szydCcqkJCaiqpiFhTz4J-VIB3PV5Khdwu_hRI3m8AWzZO5PGpwv6KJ7C_C24Ahz11-pEE0gciBvji_UC82YfXe03w6ibB9j8IXhI6zoOzJePF5KkEc2oD2TjJcrwGJt4LIbGByuRxDLmRGk_u-tYwREGkqlmAOeeS29Zh3ltW-TV2K_J4zr2ddAn77g3XHxMoEI3Amjun5a82zUeUiqFbtFQNeuMwGLMDp9hr2MmcCphdCel6mlNGtHZljJwFBwLhRxDiNE5K3VA2hDHc1yiY2EIQj8E1ij8kG3iPypyl-mE3fqmmpIbeD2rK3XX2nf2U2ykcG7tIZ7tBOvdhAAg1pewOjBxNmNZx9_QQ6n-y0sDMgy0CxJjQ9nqphisorHu0Vsl-ohpFK5DbPok_cSsXth_h3DeJb7-UptypqWd873L5eVlbpTLGRK25Q4fGPP4oVSQcHdODP1XuuUOrR1aP_vk_oxyv14AJFU6vrLAdHD_fhRsH4j6RxIR2iEwJ8EE5GCrpnlb2Lg2PcqQiIwHXfNFm2AUypemUuSq2OUcqJNOjSUn4nZ9uaUAxbqGXO6nj56gA-HHHSWJhs5AGKlaeBRNc_JEvT4WzaKCOmj8w28NOWXgp-BSrQI8C1IOWWJ-Aqm5Ytwqdj8Qgh1hhIdY9ix-mG-BnAMF1Pduw",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00006a3c1f0e_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gr15lzbFwUf8R7o73QRNlCcUb6myDmWnRhS8UEcJ5Cf_DgNrcPYN1qvx1TxlYkDYI0v4XFW0Hdxk-wRvio6j8CSXE0-MkkcdsdpZ2A-4D918K9zi6n38P1qcZdLbYzJw9d0PNJaRtD8XZmqmT8_dl5K5Nb_BN2p0QNmg_zKUr3LmB4eNt8IjVVt7IH7s7rNcTYqtkjqsSy3lkFlOZlGxxphDe56xVbmveG0VPuD1V7c6Py_S28vRO6huIVptuvZJT0fWD-LaBK6hIZ4F3Dp8P13_D5gIgJTutO-y0SE26e9cj3ZNUGintMzRovRCvcLI3tOCx1lg202yd32wQBELn4E40_fZkuDPfXrOiOlKC8lmbecWRMQj_k9bMRSbm7LT69srLRXGQRZL3bp1-Csj0p8-pMK1v1YFUXU1fip5LGoYLbWJxOk4Wdj4_HkeW6WK8W5I8u7XI3VfS3L6y680nPaHLB6fs5neTQmyezukTEX-9O97fhSkmSUPIbP4S2qJ-qel7H4NgPoHvp6xfjoozOTh4D_szydCcqkJCaiqpiFhTz4J-VIB3PV5Khdwu_hRI3m8AWzZO5PGpwv6KJ7C_C24Ahz11-pEE0gciBvji_UC82YfXe03w6ibB9j8IXhI6zoOzJePF5KkEc2oD2TjJcrwGJt4LIbGByuRxDLmRGk_u-tYwREGkqlmAOeeS29Zh3ltW-TV2K_J4zr2ddAn77g3XHxMoEI3Amjun5a82zUeUiqFbtFQNeuMwGLMDp9hr2MmcCphdCel6mlNGtHZljJwFBwLhRxDiNE5K3VA2hDHc1yiY2EIQj8E1ij8kG3iPypyl-mE3fqmmpIbeD2rK3XX2nf2U2ykcG7tIZ7tBOvdhAAg1pewOjBxNmNZx9_QQ6n-y0sDMgy0CxJjQ9nqphisorHu0Vsl-ohpFK5DbPok_cSsXth_h3DeJb7-UptypqWd873L5eVlbpTLGRK25Q4fGPP4oVSQcHdODP1XuuUOrR1aP_vk_oxyv14AJFU6vrLAdHD_fhRsH4j6RxIR2iEwJ8EE5GCrpnlb2Lg2PcqQiIwHXfNFm2AUypemUuSq2OUcqJNOjSUn4nZ9uaUAxbqGXO6nj56gA-HHHSWJhs5AGKlaeBRNc_JEvT4WzaKCOmj8w28NOWXgp-BSrQI8C1IOWWJ-Aqm5Ytwqdj8Qgh1hhIdY9ix-mG-BnAMF1Pduw",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00006a3c1f0e_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gr15lzbFwUf8R7o73QRNlCcUb6myDmWnRhS8UEcJ5Cf_DgNrcPYN1qvx1TxlYkDYI0v4XFW0Hdxk-wRvio6j8CSXE0-MkkcdsdpZ2A-4D918K9zi6n38P1qcZdLbYzJw9d0PNJaRtD8XZmqmT8_dl5K5Nb_BN2p0QNmg_zKUr3LmB4eNt8IjVVt7IH7s7rNcTYqtkjqsSy3lkFlOZlGxxphDe56xVbmveG0VPuD1V7c6Py_S28vRO6huIVptuvZJT0fWD-LaBK6hIZ4F3Dp8P13_D5gIgJTutO-y0SE26e9cj3ZNUGintMzRovRCvcLI3tOCx1lg202yd32wQBELn4E40_fZkuDPfXrOiOlKC8lmbecWRMQj_k9bMRSbm7LT69srLRXGQRZL3bp1-Csj0p8-pMK1v1YFUXU1fip5LGoYLbWJxOk4Wdj4_HkeW6WK8W5I8u7XI3VfS3L6y680nPaHLB6fs5neTQmyezukTEX-9O97fhSkmSUPIbP4S2qJ-qel7H4NgPoHvp6xfjoozOTh4D_szydCcqkJCaiqpiFhTz4J-VIB3PV5Khdwu_hRI3m8AWzZO5PGpwv6KJ7C_C24Ahz11-pEE0gciBvji_UC82YfXe03w6ibB9j8IXhI6zoOzJePF5KkEc2oD2TjJcrwGJt4LIbGByuRxDLmRGk_u-tYwREGkqlmAOeeS29Zh3ltW-TV2K_J4zr2ddAn77g3XHxMoEI3Amjun5a82zUeUiqFbtFQNeuMwGLMDp9hr2MmcCphdCel6mlNGtHZljJwFBwLhRxDiNE5K3VA2hDHc1yiY2EIQj8E1ij8kG3iPypyl-mE3fqmmpIbeD2rK3XX2nf2U2ykcG7tIZ7tBOvdhAAg1pewOjBxNmNZx9_QQ6n-y0sDMgy0CxJjQ9nqphisorHu0Vsl-ohpFK5DbPok_cSsXth_h3DeJb7-UptypqWd873L5eVlbpTLGRK25Q4fGPP4oVSQcHdODP1XuuUOrR1aP_vk_oxyv14AJFU6vrLAdHD_fhRsH4j6RxIR2iEwJ8EE5GCrpnlb2Lg2PcqQiIwHXfNFm2AUypemUuSq2OUcqJNOjSUn4nZ9uaUAxbqGXO6nj56gA-HHHSWJhs5AGKlaeBRNc_JEvT4WzaKCOmj8w28NOWXgp-BSrQI8C1IOWWJ-Aqm5Ytwqdj8Qgh1hhIdY9ix-mG-BnAMF1Pduw",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1147.62,
            "totalPrice": 853
          },
          {
            "allPassengerTotalPrice": 857,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00002401a9db_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfLgzFSIkGHX4EcDguVWJZRAOSXGalKprlseLQr6_WiXyNTV-MKv5mMBQ7RInhSE5RJO9VXpQNrPoyhKyYvmJepBsdraaw5HDzyzS2hvUOVU8HpSVkBvPCxwEdGb1RvXkTzXM3dfjUH7Wyun9iQ4spuGZNGhw2j78lIC8Vx2MwSY9Trtvq9fnGuf9Xx37K2njXTqKzu3XlF_gDaD4UJ92XC2RtjRfZFMlppQUCZAvXqee3fBvpXD1xLm11YB_OPk-K8xNvcm3NeMCBrhDahGwq1uhbli_eqsN_gkK4wCQDCgIpLI26P4L3cu6WkZJ8S1jJkHBYmHBk2oM_1rhzDPLjr8luAR7U0Az4jUtvgLx7YdBsjChSQa_yUsXq9NatFquT-DBVYDH0aS_1-N63JPUrJlSskclC5UmpQ6KfGsxOi2FzSwPHnAi-hl_1bF46GAwdraajGwA_ZdwEtMtxOzfZt705LT_kEr2Dowey8VJ6lbkCeawGL1wsUegifcci2I0vfh2ymAlxgmQkqhUbOi45uRxV4JtRWyqFHt6oC6f813JOQtFHtgZs0xIdzfzPqFb5xAcP3B773fSZQir8mApfm7SNu8B5-tD0OjEN7dNPpRhpY4Z2nlpbvIiuhe62AmmwKZ3WQJuG5yrz_pNhTEEENrpUcLJ96DtCsu37FbO3wuQMfjA8DmjnCEMkTTeV79Xxyow_oyNG3b_UHiaDO0nhLxvzwfZPqKEecnxoOt5njPt1KuCV5ydq4Hg_3cpEMjCIOwuh5DNkyIGZd9VuFUFi1epbRazQHec_WVN6pSVHr3K-UsQfiTpwlg84rEjPWJY7VjwV47j8bfkod2JT6VF9vSykTAIOXKvLjmWdmpmtsY5rK7Bd7V1Kg7Y_utXYTcMxmw7_x9vhJPEkCssRY5vtjEtbnaHBaFrYVpoGoSkBUESj3glxtwenLCY9gegEt6Lzb8JYgQ9RColkC1J84D3umhaLuWgT19jc_2-3tI6BJu0S5J2t-h_IynEuGwkkWCyMKTJd1EOTIi-HweNK69UD8ah5NsvRUEeV7yTuJjVjQu4OOCTbGHA4eyqTIKHNwb6uu2sajrd-scmxD4j_7t4nMJtxZnPLE47R-T6B5Ppw-zSkcD2LSnt3VAfi9-jpg1CTQSPQ0Vy7acrpWvt16X8zEmILWHiDBqln2RefMxp3cDLsMBn4DcWN7F8hTRfIPUQdIF4fGACYm0fII8CUDts2g==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00002401a9db_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfLgzFSIkGHX4EcDguVWJZRAOSXGalKprlseLQr6_WiXyNTV-MKv5mMBQ7RInhSE5RJO9VXpQNrPoyhKyYvmJepBsdraaw5HDzyzS2hvUOVU8HpSVkBvPCxwEdGb1RvXkTzXM3dfjUH7Wyun9iQ4spuGZNGhw2j78lIC8Vx2MwSY9Trtvq9fnGuf9Xx37K2njXTqKzu3XlF_gDaD4UJ92XC2RtjRfZFMlppQUCZAvXqee3fBvpXD1xLm11YB_OPk-K8xNvcm3NeMCBrhDahGwq1uhbli_eqsN_gkK4wCQDCgIpLI26P4L3cu6WkZJ8S1jJkHBYmHBk2oM_1rhzDPLjr8luAR7U0Az4jUtvgLx7YdBsjChSQa_yUsXq9NatFquT-DBVYDH0aS_1-N63JPUrJlSskclC5UmpQ6KfGsxOi2FzSwPHnAi-hl_1bF46GAwdraajGwA_ZdwEtMtxOzfZt705LT_kEr2Dowey8VJ6lbkCeawGL1wsUegifcci2I0vfh2ymAlxgmQkqhUbOi45uRxV4JtRWyqFHt6oC6f813JOQtFHtgZs0xIdzfzPqFb5xAcP3B773fSZQir8mApfm7SNu8B5-tD0OjEN7dNPpRhpY4Z2nlpbvIiuhe62AmmwKZ3WQJuG5yrz_pNhTEEENrpUcLJ96DtCsu37FbO3wuQMfjA8DmjnCEMkTTeV79Xxyow_oyNG3b_UHiaDO0nhLxvzwfZPqKEecnxoOt5njPt1KuCV5ydq4Hg_3cpEMjCIOwuh5DNkyIGZd9VuFUFi1epbRazQHec_WVN6pSVHr3K-UsQfiTpwlg84rEjPWJY7VjwV47j8bfkod2JT6VF9vSykTAIOXKvLjmWdmpmtsY5rK7Bd7V1Kg7Y_utXYTcMxmw7_x9vhJPEkCssRY5vtjEtbnaHBaFrYVpoGoSkBUESj3glxtwenLCY9gegEt6Lzb8JYgQ9RColkC1J84D3umhaLuWgT19jc_2-3tI6BJu0S5J2t-h_IynEuGwkkWCyMKTJd1EOTIi-HweNK69UD8ah5NsvRUEeV7yTuJjVjQu4OOCTbGHA4eyqTIKHNwb6uu2sajrd-scmxD4j_7t4nMJtxZnPLE47R-T6B5Ppw-zSkcD2LSnt3VAfi9-jpg1CTQSPQ0Vy7acrpWvt16X8zEmILWHiDBqln2RefMxp3cDLsMBn4DcWN7F8hTRfIPUQdIF4fGACYm0fII8CUDts2g==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00002401a9db_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfLgzFSIkGHX4EcDguVWJZRAOSXGalKprlseLQr6_WiXyNTV-MKv5mMBQ7RInhSE5RJO9VXpQNrPoyhKyYvmJepBsdraaw5HDzyzS2hvUOVU8HpSVkBvPCxwEdGb1RvXkTzXM3dfjUH7Wyun9iQ4spuGZNGhw2j78lIC8Vx2MwSY9Trtvq9fnGuf9Xx37K2njXTqKzu3XlF_gDaD4UJ92XC2RtjRfZFMlppQUCZAvXqee3fBvpXD1xLm11YB_OPk-K8xNvcm3NeMCBrhDahGwq1uhbli_eqsN_gkK4wCQDCgIpLI26P4L3cu6WkZJ8S1jJkHBYmHBk2oM_1rhzDPLjr8luAR7U0Az4jUtvgLx7YdBsjChSQa_yUsXq9NatFquT-DBVYDH0aS_1-N63JPUrJlSskclC5UmpQ6KfGsxOi2FzSwPHnAi-hl_1bF46GAwdraajGwA_ZdwEtMtxOzfZt705LT_kEr2Dowey8VJ6lbkCeawGL1wsUegifcci2I0vfh2ymAlxgmQkqhUbOi45uRxV4JtRWyqFHt6oC6f813JOQtFHtgZs0xIdzfzPqFb5xAcP3B773fSZQir8mApfm7SNu8B5-tD0OjEN7dNPpRhpY4Z2nlpbvIiuhe62AmmwKZ3WQJuG5yrz_pNhTEEENrpUcLJ96DtCsu37FbO3wuQMfjA8DmjnCEMkTTeV79Xxyow_oyNG3b_UHiaDO0nhLxvzwfZPqKEecnxoOt5njPt1KuCV5ydq4Hg_3cpEMjCIOwuh5DNkyIGZd9VuFUFi1epbRazQHec_WVN6pSVHr3K-UsQfiTpwlg84rEjPWJY7VjwV47j8bfkod2JT6VF9vSykTAIOXKvLjmWdmpmtsY5rK7Bd7V1Kg7Y_utXYTcMxmw7_x9vhJPEkCssRY5vtjEtbnaHBaFrYVpoGoSkBUESj3glxtwenLCY9gegEt6Lzb8JYgQ9RColkC1J84D3umhaLuWgT19jc_2-3tI6BJu0S5J2t-h_IynEuGwkkWCyMKTJd1EOTIi-HweNK69UD8ah5NsvRUEeV7yTuJjVjQu4OOCTbGHA4eyqTIKHNwb6uu2sajrd-scmxD4j_7t4nMJtxZnPLE47R-T6B5Ppw-zSkcD2LSnt3VAfi9-jpg1CTQSPQ0Vy7acrpWvt16X8zEmILWHiDBqln2RefMxp3cDLsMBn4DcWN7F8hTRfIPUQdIF4fGACYm0fII8CUDts2g==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1150.98,
            "totalPrice": 857
          },
          {
            "allPassengerTotalPrice": 862,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000c3b984dc_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gas9glk6WSGYqG_3MCPGLrrjqZ6kBpnFypc3MHRBkyFAYZ0Fi798AN1I697eqeu0RODHYIC0kDuSS0-Zzcz6KkQ7dipaoU7DiX-SWlGZaqpm-sgtHXRzXR_6ZVaVhpnqKNkOqxXLCgweX02FCDwdE5DoRXMT6xtYyZaZY1ZipzEpeuYW0BXQyaav_ZDft0Z7nhhTOSlVOcZim_jGl3q0KA6CdE-1Nl4cHf4nBhfay3uj4HYJmOJUq6HjBaXxMxV5e23qUdrHQvOLn5kh69ToqLyL0jyboHufn5J_vJelB-MsU5pYEUl2G5HX-aMBg6sRigxK6QlWKpu5-tgNHk7HpSZsT-uipneaja3womYKMN5bbbkBVuXKaW212KZQT70hk5cZP3fy9bw_u0HNypMkMwlodR2uASzw85sUPnEarrgRJklswVG5II5SrELocRpdgzbm1UWyUKDjkeo0H0s-fvZdAe2CCu--uEoBAK0Aj5-ZaSHL876dIhzoJPinLTuff2webmx2vCam2zPjsYp3XzLtZClZ8gKdssWIe5XWGUh_7oHlSD_9uyHYUSylipxhaenCXeBNM-_O3mEOHsW6RnsM6otR2KRf3Yyl0CUA9ngJ8mqvusIR3cwih6Cv_g5Fhry0SbDzvcFvbxKkx1z8gsn3iAOjLr9cdHu09JhDVWnMl4YTBrgAEqB8TbJByzRFmccTqJ0w1g_F0YBjlTm6AQlYMmGCppnOamD4TZ-qpxk0sB83TicPaSvE7Oj-M1Er63qNOs5xHd_PIBc9sZcNIa0XjEFz5_-2u67dhizctE1rIJQpNJZBZRFFhWsZWZtrcvsnBps3D8UWMPbDRc5a6DAMnOj6oms7VWe1MV97gritFjl0tIGbiKct7AZd9lYQwOJO1-WHSIZI04_bN1g9DFrtxqRVHGrJ308XpZXQqi_YFjf68wkaLFuJevmteFjNYuUCUoR-0B6OiBDAv2jpL6Lbm9rfzZ218gxlvhQglTmVN7m-d_Lbw8xej5lGjF_HNvVL8wLxwt4cXvdNljutq1o4I_Ig62Tbe-y3J8Wzm43GDwKYNaymcSDNgQqEat3HfmMEm7sTenngHcT6eYTPMTyTDRDCwjn5WcFip7frV8vceZb5tRNU3pl82CXcLDsBF8b1jpwIP2SqnNazsDKG4RISWwrXPJCGyVttJY4O1N-ExAnABvHU92gPRFkm_CBz6H1BP61FpqgUuTlmdEhs3XXBxhCe4qdrmiFTYlll70X0XzQuYxpU6HQwZxyfHV8QpUk-mbYS_-6vvqap4rjS-v41ZSN7dpBK7eTEMWIkeM4m0hV1GoHQx3br97t7rMswY",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000c3b984dc_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gas9glk6WSGYqG_3MCPGLrrjqZ6kBpnFypc3MHRBkyFAYZ0Fi798AN1I697eqeu0RODHYIC0kDuSS0-Zzcz6KkQ7dipaoU7DiX-SWlGZaqpm-sgtHXRzXR_6ZVaVhpnqKNkOqxXLCgweX02FCDwdE5DoRXMT6xtYyZaZY1ZipzEpeuYW0BXQyaav_ZDft0Z7nhhTOSlVOcZim_jGl3q0KA6CdE-1Nl4cHf4nBhfay3uj4HYJmOJUq6HjBaXxMxV5e23qUdrHQvOLn5kh69ToqLyL0jyboHufn5J_vJelB-MsU5pYEUl2G5HX-aMBg6sRigxK6QlWKpu5-tgNHk7HpSZsT-uipneaja3womYKMN5bbbkBVuXKaW212KZQT70hk5cZP3fy9bw_u0HNypMkMwlodR2uASzw85sUPnEarrgRJklswVG5II5SrELocRpdgzbm1UWyUKDjkeo0H0s-fvZdAe2CCu--uEoBAK0Aj5-ZaSHL876dIhzoJPinLTuff2webmx2vCam2zPjsYp3XzLtZClZ8gKdssWIe5XWGUh_7oHlSD_9uyHYUSylipxhaenCXeBNM-_O3mEOHsW6RnsM6otR2KRf3Yyl0CUA9ngJ8mqvusIR3cwih6Cv_g5Fhry0SbDzvcFvbxKkx1z8gsn3iAOjLr9cdHu09JhDVWnMl4YTBrgAEqB8TbJByzRFmccTqJ0w1g_F0YBjlTm6AQlYMmGCppnOamD4TZ-qpxk0sB83TicPaSvE7Oj-M1Er63qNOs5xHd_PIBc9sZcNIa0XjEFz5_-2u67dhizctE1rIJQpNJZBZRFFhWsZWZtrcvsnBps3D8UWMPbDRc5a6DAMnOj6oms7VWe1MV97gritFjl0tIGbiKct7AZd9lYQwOJO1-WHSIZI04_bN1g9DFrtxqRVHGrJ308XpZXQqi_YFjf68wkaLFuJevmteFjNYuUCUoR-0B6OiBDAv2jpL6Lbm9rfzZ218gxlvhQglTmVN7m-d_Lbw8xej5lGjF_HNvVL8wLxwt4cXvdNljutq1o4I_Ig62Tbe-y3J8Wzm43GDwKYNaymcSDNgQqEat3HfmMEm7sTenngHcT6eYTPMTyTDRDCwjn5WcFip7frV8vceZb5tRNU3pl82CXcLDsBF8b1jpwIP2SqnNazsDKG4RISWwrXPJCGyVttJY4O1N-ExAnABvHU92gPRFkm_CBz6H1BP61FpqgUuTlmdEhs3XXBxhCe4qdrmiFTYlll70X0XzQuYxpU6HQwZxyfHV8QpUk-mbYS_-6vvqap4rjS-v41ZSN7dpBK7eTEMWIkeM4m0hV1GoHQx3br97t7rMswY",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000c3b984dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000c3b984dc_0",
                    "local_arrival": "2024-09-17T13:45:00.000Z",
                    "local_departure": "2024-09-17T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-17T11:45:00.000Z",
                    "utc_departure": "2024-09-17T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000c3b984dc_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gas9glk6WSGYqG_3MCPGLrrjqZ6kBpnFypc3MHRBkyFAYZ0Fi798AN1I697eqeu0RODHYIC0kDuSS0-Zzcz6KkQ7dipaoU7DiX-SWlGZaqpm-sgtHXRzXR_6ZVaVhpnqKNkOqxXLCgweX02FCDwdE5DoRXMT6xtYyZaZY1ZipzEpeuYW0BXQyaav_ZDft0Z7nhhTOSlVOcZim_jGl3q0KA6CdE-1Nl4cHf4nBhfay3uj4HYJmOJUq6HjBaXxMxV5e23qUdrHQvOLn5kh69ToqLyL0jyboHufn5J_vJelB-MsU5pYEUl2G5HX-aMBg6sRigxK6QlWKpu5-tgNHk7HpSZsT-uipneaja3womYKMN5bbbkBVuXKaW212KZQT70hk5cZP3fy9bw_u0HNypMkMwlodR2uASzw85sUPnEarrgRJklswVG5II5SrELocRpdgzbm1UWyUKDjkeo0H0s-fvZdAe2CCu--uEoBAK0Aj5-ZaSHL876dIhzoJPinLTuff2webmx2vCam2zPjsYp3XzLtZClZ8gKdssWIe5XWGUh_7oHlSD_9uyHYUSylipxhaenCXeBNM-_O3mEOHsW6RnsM6otR2KRf3Yyl0CUA9ngJ8mqvusIR3cwih6Cv_g5Fhry0SbDzvcFvbxKkx1z8gsn3iAOjLr9cdHu09JhDVWnMl4YTBrgAEqB8TbJByzRFmccTqJ0w1g_F0YBjlTm6AQlYMmGCppnOamD4TZ-qpxk0sB83TicPaSvE7Oj-M1Er63qNOs5xHd_PIBc9sZcNIa0XjEFz5_-2u67dhizctE1rIJQpNJZBZRFFhWsZWZtrcvsnBps3D8UWMPbDRc5a6DAMnOj6oms7VWe1MV97gritFjl0tIGbiKct7AZd9lYQwOJO1-WHSIZI04_bN1g9DFrtxqRVHGrJ308XpZXQqi_YFjf68wkaLFuJevmteFjNYuUCUoR-0B6OiBDAv2jpL6Lbm9rfzZ218gxlvhQglTmVN7m-d_Lbw8xej5lGjF_HNvVL8wLxwt4cXvdNljutq1o4I_Ig62Tbe-y3J8Wzm43GDwKYNaymcSDNgQqEat3HfmMEm7sTenngHcT6eYTPMTyTDRDCwjn5WcFip7frV8vceZb5tRNU3pl82CXcLDsBF8b1jpwIP2SqnNazsDKG4RISWwrXPJCGyVttJY4O1N-ExAnABvHU92gPRFkm_CBz6H1BP61FpqgUuTlmdEhs3XXBxhCe4qdrmiFTYlll70X0XzQuYxpU6HQwZxyfHV8QpUk-mbYS_-6vvqap4rjS-v41ZSN7dpBK7eTEMWIkeM4m0hV1GoHQx3br97t7rMswY",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1160.97,
            "totalPrice": 862
          },
          {
            "allPassengerTotalPrice": 865,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHMb85ZtLE0butl66IjrKbPrVQ5E6x9Te1cQ874h9XxwG7PtUvZo2WWgy4O-iDI4d5sO2JyB5s3ZrVuDKMvFSpqSTgkheptjpug-6-Ig-1owFRsjcpCpBThD76c9KK7L_hXYVm8kYsrfJ279rw8BR3P74durxlHvLedDgCs8lDhBttS8CXra7oUlFzXHw8BxobviwJyK0zYph-JxKLsfX427cC9YDEEAlKXlSTCfasamwoZ5obEdXhnL0LyRgLfNynagllrF6bU4ZXNicSLSyoSuQshH5xaPXNwZmUJVAlTJBVOc2mQua7MCfNJgsCbELJq7xqRXUWaRQV2RpeKAkmRFvbS-3_BDUDJmsgP7z3_5qnChWFBA7948sDlkNQ37Ixa_f-c3Nr_jK0qUcZ8u-dNMrayTWkGRzhZsh40AYBmzOGu9gt9N9uLfqIsvJ0dacaXcDMhssckZC9gqbVOLQqvFe_BkS9URVVWqS8p6Hb0IHV2so_bPuCNQG_-JJv38601WCI36eOyyzVOuakj6vfKDarLIfwmWR-AXW_8ZR_Xyd_XiKh8y8xW2Bf0tJKYkEaAymCRVvq9tEct64wY4zEFmHA1bod2i6L6CUVU87MVN901Q4vR9F9S6Mi-vFgmeTAN8vrlLBbUfLDETPZvGCj0-frIoXmZJSX4CDPR0wQELEKDhgYmob7zbyeM6LWk0JvxJUpSrczrf5W_jpzlLEZHz-gWMOlbwre-_tzfTSjbEgAEhjsxApVQbsNBsEWvyJp2QjGWU-nHVDf891bH8e2CuiPpGCTGm7N9pGfuOpo4yfc6Nviz3bPujuM45IPw4a0fUIIPiHPNKdbdC63wgVKClyMcQJgCiqWkaDQypKx9KAUXLgzJu6m-4wLSjxflyWTzBZ1MSHPrJ8mUVZmQ1D_9-ngJ_fostmFdBITGukrYBeiASHl3qhFVbWAvTB6jSTOO8bI0NXDqjEUHu4FACvjlZ8Ay8XFiF_bdgRCtBBADYG3RBFuULtcKCf4bbUSOQt_q84pbbPfU97K2D-mhKrGxjPWDW3EnMspTBZkmfj8iudf2Mwav4WTqxI4YehS0WxdiMCbagI4Fna-JrULfiYUHh8YGrwjOiznAuH7gg6W9IJwQYmgfvAU2fluau_KcMtiQeqlE5cEaejCoLPWLrIuz53jvB8niSFZssW9LVVTapUUNoX1EREPUq_gHfgm_I33zd-lRIlZqvU64gOCZdm-Akv5OAB-nrl50MHneC_KD5lfVKxKbNL4NiJtxY0yuWP0fpGcr9_Z36TX6LJMuOFWgOL1O27GpIho7RHX1KljpDQgK-znGjYlrD9N22idFU6zK5GNBbMVdH4zwOZz4QbkA==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHMb85ZtLE0butl66IjrKbPrVQ5E6x9Te1cQ874h9XxwG7PtUvZo2WWgy4O-iDI4d5sO2JyB5s3ZrVuDKMvFSpqSTgkheptjpug-6-Ig-1owFRsjcpCpBThD76c9KK7L_hXYVm8kYsrfJ279rw8BR3P74durxlHvLedDgCs8lDhBttS8CXra7oUlFzXHw8BxobviwJyK0zYph-JxKLsfX427cC9YDEEAlKXlSTCfasamwoZ5obEdXhnL0LyRgLfNynagllrF6bU4ZXNicSLSyoSuQshH5xaPXNwZmUJVAlTJBVOc2mQua7MCfNJgsCbELJq7xqRXUWaRQV2RpeKAkmRFvbS-3_BDUDJmsgP7z3_5qnChWFBA7948sDlkNQ37Ixa_f-c3Nr_jK0qUcZ8u-dNMrayTWkGRzhZsh40AYBmzOGu9gt9N9uLfqIsvJ0dacaXcDMhssckZC9gqbVOLQqvFe_BkS9URVVWqS8p6Hb0IHV2so_bPuCNQG_-JJv38601WCI36eOyyzVOuakj6vfKDarLIfwmWR-AXW_8ZR_Xyd_XiKh8y8xW2Bf0tJKYkEaAymCRVvq9tEct64wY4zEFmHA1bod2i6L6CUVU87MVN901Q4vR9F9S6Mi-vFgmeTAN8vrlLBbUfLDETPZvGCj0-frIoXmZJSX4CDPR0wQELEKDhgYmob7zbyeM6LWk0JvxJUpSrczrf5W_jpzlLEZHz-gWMOlbwre-_tzfTSjbEgAEhjsxApVQbsNBsEWvyJp2QjGWU-nHVDf891bH8e2CuiPpGCTGm7N9pGfuOpo4yfc6Nviz3bPujuM45IPw4a0fUIIPiHPNKdbdC63wgVKClyMcQJgCiqWkaDQypKx9KAUXLgzJu6m-4wLSjxflyWTzBZ1MSHPrJ8mUVZmQ1D_9-ngJ_fostmFdBITGukrYBeiASHl3qhFVbWAvTB6jSTOO8bI0NXDqjEUHu4FACvjlZ8Ay8XFiF_bdgRCtBBADYG3RBFuULtcKCf4bbUSOQt_q84pbbPfU97K2D-mhKrGxjPWDW3EnMspTBZkmfj8iudf2Mwav4WTqxI4YehS0WxdiMCbagI4Fna-JrULfiYUHh8YGrwjOiznAuH7gg6W9IJwQYmgfvAU2fluau_KcMtiQeqlE5cEaejCoLPWLrIuz53jvB8niSFZssW9LVVTapUUNoX1EREPUq_gHfgm_I33zd-lRIlZqvU64gOCZdm-Akv5OAB-nrl50MHneC_KD5lfVKxKbNL4NiJtxY0yuWP0fpGcr9_Z36TX6LJMuOFWgOL1O27GpIho7RHX1KljpDQgK-znGjYlrD9N22idFU6zK5GNBbMVdH4zwOZz4QbkA==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHMb85ZtLE0butl66IjrKbPrVQ5E6x9Te1cQ874h9XxwG7PtUvZo2WWgy4O-iDI4d5sO2JyB5s3ZrVuDKMvFSpqSTgkheptjpug-6-Ig-1owFRsjcpCpBThD76c9KK7L_hXYVm8kYsrfJ279rw8BR3P74durxlHvLedDgCs8lDhBttS8CXra7oUlFzXHw8BxobviwJyK0zYph-JxKLsfX427cC9YDEEAlKXlSTCfasamwoZ5obEdXhnL0LyRgLfNynagllrF6bU4ZXNicSLSyoSuQshH5xaPXNwZmUJVAlTJBVOc2mQua7MCfNJgsCbELJq7xqRXUWaRQV2RpeKAkmRFvbS-3_BDUDJmsgP7z3_5qnChWFBA7948sDlkNQ37Ixa_f-c3Nr_jK0qUcZ8u-dNMrayTWkGRzhZsh40AYBmzOGu9gt9N9uLfqIsvJ0dacaXcDMhssckZC9gqbVOLQqvFe_BkS9URVVWqS8p6Hb0IHV2so_bPuCNQG_-JJv38601WCI36eOyyzVOuakj6vfKDarLIfwmWR-AXW_8ZR_Xyd_XiKh8y8xW2Bf0tJKYkEaAymCRVvq9tEct64wY4zEFmHA1bod2i6L6CUVU87MVN901Q4vR9F9S6Mi-vFgmeTAN8vrlLBbUfLDETPZvGCj0-frIoXmZJSX4CDPR0wQELEKDhgYmob7zbyeM6LWk0JvxJUpSrczrf5W_jpzlLEZHz-gWMOlbwre-_tzfTSjbEgAEhjsxApVQbsNBsEWvyJp2QjGWU-nHVDf891bH8e2CuiPpGCTGm7N9pGfuOpo4yfc6Nviz3bPujuM45IPw4a0fUIIPiHPNKdbdC63wgVKClyMcQJgCiqWkaDQypKx9KAUXLgzJu6m-4wLSjxflyWTzBZ1MSHPrJ8mUVZmQ1D_9-ngJ_fostmFdBITGukrYBeiASHl3qhFVbWAvTB6jSTOO8bI0NXDqjEUHu4FACvjlZ8Ay8XFiF_bdgRCtBBADYG3RBFuULtcKCf4bbUSOQt_q84pbbPfU97K2D-mhKrGxjPWDW3EnMspTBZkmfj8iudf2Mwav4WTqxI4YehS0WxdiMCbagI4Fna-JrULfiYUHh8YGrwjOiznAuH7gg6W9IJwQYmgfvAU2fluau_KcMtiQeqlE5cEaejCoLPWLrIuz53jvB8niSFZssW9LVVTapUUNoX1EREPUq_gHfgm_I33zd-lRIlZqvU64gOCZdm-Akv5OAB-nrl50MHneC_KD5lfVKxKbNL4NiJtxY0yuWP0fpGcr9_Z36TX6LJMuOFWgOL1O27GpIho7RHX1KljpDQgK-znGjYlrD9N22idFU6zK5GNBbMVdH4zwOZz4QbkA==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e160000a6145884",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e160000a6145884_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:55:00.000Z",
                    "utc_departure": "2024-09-24T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e160000ef37d710",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e160000ef37d710_0",
                    "local_arrival": "2024-09-24T14:40:00.000Z",
                    "local_departure": "2024-09-24T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:40:00.000Z",
                    "utc_departure": "2024-09-24T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e16000082afdaf7",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e16000082afdaf7_0",
                    "local_arrival": "2024-09-24T18:35:00.000Z",
                    "local_departure": "2024-09-24T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:35:00.000Z",
                    "utc_departure": "2024-09-24T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1161.52,
            "totalPrice": 865
          },
          {
            "allPassengerTotalPrice": 869,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZdMjOF1px_xfkLM5Ef4xkMVkNyCc9PmOJlnSXAXxqInEPUmzdAFQR4EcBEhx2vaovJIJZVeaGIoHupX2TsjJeDPDzDdoFf3QqP1_qpOGvLaVxSj-5eS4H45XualMNyFmq3qOxUeJGiPzefk8fEHw73HDOfjrSS3bMQB6ySZ5IN2dvhK_xRGaECvcdBk3_QC2vPvWhb13wc8o6B2ZJ0OVghaGnUADk8lOsQ8WvikXkA0bPEcWbcd8TbDUXGxQmB7xln1rSZMl2kO5VoolANIlII7IiczYPgXqPwH05Lp9h6YS1jHN_QshN3peZWx1TtCoCE1xRNQ1DrBtHlfSL79lX3c3cUj91VL-gaXGdoECZ3bKo5UEHZCsTEQw0vo1eCXz_EEE6DN1XETyp5mPP7t5R9sfOha0wC5obj7T64Tw1Idiw5UgMuR07Rh7m7hXSe-I3YAfe4736qDhkHuveM5Vy-2Aqx2np-rBls26mdAdmUmbfrkNoYN-UIQP9DWnVlA8zgIsQZ-jaLXm68_bgo2ZQok-0fwpku4I3d9zML6fU6Ig4qlUnfAta_1sZc-mKVjgolV9mMr_BRMjscp_7y9u9tU3DgEKp6dodRNvLvH1iP6oDmP7hfIU6reV4g5zDKkLjVvoicvFARUV6yHm0DT1QOE39EWcSywkTx-QU7CfQ-jypM2TORXWCcZWua3B5u6rOAUQu0xK0Kx54IQHvxJ3vC9_ehDcWZ54Rc302j76it5QXakm4j7nqbjoJIG2II2qFHRQVoqYadgAuUq-Sky_ZCJlBLwXjLZySvVwbjylvrgbLu_oHqokRNLTvE9JaWXtOkZX4v1vdCZSjwmP4ztUtQ4uNBcnHeYQJVnK9yFVJeRq3iLKTDHJS2IrXCOEPCfltX8yT4JvE4Szj3eWQLh6AoaAXZ3713KJh5E0DWJmTFn_5Tb6up9FUCdBtqbHLdzmiEXYRXfU_ANPVXlfQFX7cAMT6BPlf0KCPqkKk1oUI3MbOUIFtI7D6deval9bvXvEL5nccMkZgkpumKTCUlG8PDrXbe0MJ85DxxnNvNsx0XyTYbNdND0NJ_4FVCN6EUCxK_5mwsRpU6RDBziI6v1LDmfXKdLJBHjPsZcFE0bc9NDGPJJ73p9PVrfFYTuQdX9KmbEHClPwWaQKg0dOV8y1rOcXw7n2mISj1-OAeM270U7FlwH0tFaRLS6YesWus21fZ-2deBJfJcudWMOUexhmLH3A8KNTLAuTNI5mzI-9e-5-0L2vHFTNAMW7emR5QVMDXwc7rTwFPqD6R4VReLgmmzSXKUYvZ3oGAtH-hMoqk8r5MnqxxkXX6dMWhgqpZ_gwYOBWb6hL8FU78f6IHJIZlvWBbtZWPkzi2iWHtT637mI=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZdMjOF1px_xfkLM5Ef4xkMVkNyCc9PmOJlnSXAXxqInEPUmzdAFQR4EcBEhx2vaovJIJZVeaGIoHupX2TsjJeDPDzDdoFf3QqP1_qpOGvLaVxSj-5eS4H45XualMNyFmq3qOxUeJGiPzefk8fEHw73HDOfjrSS3bMQB6ySZ5IN2dvhK_xRGaECvcdBk3_QC2vPvWhb13wc8o6B2ZJ0OVghaGnUADk8lOsQ8WvikXkA0bPEcWbcd8TbDUXGxQmB7xln1rSZMl2kO5VoolANIlII7IiczYPgXqPwH05Lp9h6YS1jHN_QshN3peZWx1TtCoCE1xRNQ1DrBtHlfSL79lX3c3cUj91VL-gaXGdoECZ3bKo5UEHZCsTEQw0vo1eCXz_EEE6DN1XETyp5mPP7t5R9sfOha0wC5obj7T64Tw1Idiw5UgMuR07Rh7m7hXSe-I3YAfe4736qDhkHuveM5Vy-2Aqx2np-rBls26mdAdmUmbfrkNoYN-UIQP9DWnVlA8zgIsQZ-jaLXm68_bgo2ZQok-0fwpku4I3d9zML6fU6Ig4qlUnfAta_1sZc-mKVjgolV9mMr_BRMjscp_7y9u9tU3DgEKp6dodRNvLvH1iP6oDmP7hfIU6reV4g5zDKkLjVvoicvFARUV6yHm0DT1QOE39EWcSywkTx-QU7CfQ-jypM2TORXWCcZWua3B5u6rOAUQu0xK0Kx54IQHvxJ3vC9_ehDcWZ54Rc302j76it5QXakm4j7nqbjoJIG2II2qFHRQVoqYadgAuUq-Sky_ZCJlBLwXjLZySvVwbjylvrgbLu_oHqokRNLTvE9JaWXtOkZX4v1vdCZSjwmP4ztUtQ4uNBcnHeYQJVnK9yFVJeRq3iLKTDHJS2IrXCOEPCfltX8yT4JvE4Szj3eWQLh6AoaAXZ3713KJh5E0DWJmTFn_5Tb6up9FUCdBtqbHLdzmiEXYRXfU_ANPVXlfQFX7cAMT6BPlf0KCPqkKk1oUI3MbOUIFtI7D6deval9bvXvEL5nccMkZgkpumKTCUlG8PDrXbe0MJ85DxxnNvNsx0XyTYbNdND0NJ_4FVCN6EUCxK_5mwsRpU6RDBziI6v1LDmfXKdLJBHjPsZcFE0bc9NDGPJJ73p9PVrfFYTuQdX9KmbEHClPwWaQKg0dOV8y1rOcXw7n2mISj1-OAeM270U7FlwH0tFaRLS6YesWus21fZ-2deBJfJcudWMOUexhmLH3A8KNTLAuTNI5mzI-9e-5-0L2vHFTNAMW7emR5QVMDXwc7rTwFPqD6R4VReLgmmzSXKUYvZ3oGAtH-hMoqk8r5MnqxxkXX6dMWhgqpZ_gwYOBWb6hL8FU78f6IHJIZlvWBbtZWPkzi2iWHtT637mI=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZdMjOF1px_xfkLM5Ef4xkMVkNyCc9PmOJlnSXAXxqInEPUmzdAFQR4EcBEhx2vaovJIJZVeaGIoHupX2TsjJeDPDzDdoFf3QqP1_qpOGvLaVxSj-5eS4H45XualMNyFmq3qOxUeJGiPzefk8fEHw73HDOfjrSS3bMQB6ySZ5IN2dvhK_xRGaECvcdBk3_QC2vPvWhb13wc8o6B2ZJ0OVghaGnUADk8lOsQ8WvikXkA0bPEcWbcd8TbDUXGxQmB7xln1rSZMl2kO5VoolANIlII7IiczYPgXqPwH05Lp9h6YS1jHN_QshN3peZWx1TtCoCE1xRNQ1DrBtHlfSL79lX3c3cUj91VL-gaXGdoECZ3bKo5UEHZCsTEQw0vo1eCXz_EEE6DN1XETyp5mPP7t5R9sfOha0wC5obj7T64Tw1Idiw5UgMuR07Rh7m7hXSe-I3YAfe4736qDhkHuveM5Vy-2Aqx2np-rBls26mdAdmUmbfrkNoYN-UIQP9DWnVlA8zgIsQZ-jaLXm68_bgo2ZQok-0fwpku4I3d9zML6fU6Ig4qlUnfAta_1sZc-mKVjgolV9mMr_BRMjscp_7y9u9tU3DgEKp6dodRNvLvH1iP6oDmP7hfIU6reV4g5zDKkLjVvoicvFARUV6yHm0DT1QOE39EWcSywkTx-QU7CfQ-jypM2TORXWCcZWua3B5u6rOAUQu0xK0Kx54IQHvxJ3vC9_ehDcWZ54Rc302j76it5QXakm4j7nqbjoJIG2II2qFHRQVoqYadgAuUq-Sky_ZCJlBLwXjLZySvVwbjylvrgbLu_oHqokRNLTvE9JaWXtOkZX4v1vdCZSjwmP4ztUtQ4uNBcnHeYQJVnK9yFVJeRq3iLKTDHJS2IrXCOEPCfltX8yT4JvE4Szj3eWQLh6AoaAXZ3713KJh5E0DWJmTFn_5Tb6up9FUCdBtqbHLdzmiEXYRXfU_ANPVXlfQFX7cAMT6BPlf0KCPqkKk1oUI3MbOUIFtI7D6deval9bvXvEL5nccMkZgkpumKTCUlG8PDrXbe0MJ85DxxnNvNsx0XyTYbNdND0NJ_4FVCN6EUCxK_5mwsRpU6RDBziI6v1LDmfXKdLJBHjPsZcFE0bc9NDGPJJ73p9PVrfFYTuQdX9KmbEHClPwWaQKg0dOV8y1rOcXw7n2mISj1-OAeM270U7FlwH0tFaRLS6YesWus21fZ-2deBJfJcudWMOUexhmLH3A8KNTLAuTNI5mzI-9e-5-0L2vHFTNAMW7emR5QVMDXwc7rTwFPqD6R4VReLgmmzSXKUYvZ3oGAtH-hMoqk8r5MnqxxkXX6dMWhgqpZ_gwYOBWb6hL8FU78f6IHJIZlvWBbtZWPkzi2iWHtT637mI=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e160000a6145884",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e160000a6145884_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:55:00.000Z",
                    "utc_departure": "2024-09-24T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e160000ef37d710",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e160000ef37d710_0",
                    "local_arrival": "2024-09-24T14:40:00.000Z",
                    "local_departure": "2024-09-24T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:40:00.000Z",
                    "utc_departure": "2024-09-24T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e16000082afdaf7",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e16000082afdaf7_0",
                    "local_arrival": "2024-09-24T18:35:00.000Z",
                    "local_departure": "2024-09-24T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:35:00.000Z",
                    "utc_departure": "2024-09-24T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1163.29,
            "totalPrice": 869
          },
          {
            "allPassengerTotalPrice": 869,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvXkDSyqU-5bVhk1E3eVdXRjpS2U7Jk6aBLp916Jg9WIjOqzitubgSPp0XD4k3zqt6dwyXXg1bBph9POmPwoNcqtoh--v-jknkPIi5WP-PxroUsv7lw1sxwSH0gzy3wgOvvH57nAkGSMtUfpd0KtHuRWzWxMXxl340e0S7C9ONuZR66fo0oiV2r6N1cgtMmAMoKR1RR4vFXgs-eKKYU2kb6Nrxc--rCCW9fWUG5B7ISj4VfF1-Xt5U3jTVqBiwkDedKV0aloqXrW6iYbZ82Frofd0dk0A6pCsmbaLWMfuHEH459HuApetNpLtIEdCQpUVTOPOccOZjIGAFA8GHLH8AWQGayf7xpaJqs9r95sryk0evbYj1qzTaCDJDijGB-mOrMrCaepEC_cEry1oBSDBqF3PHBrWiX0kKL3hKsiFaVB-pPjNZ8bp6C6S4SBhO_jRdkVYqANQyhaytZm5gL2Nf7-MWDJn_KG7NvCnKRLupCG1w91Fz-AbUP2XFqzU1QaruiKHsD7no_cDrlji7ctn5-WojsZiJsBLLMVDM30O-9eAhPo_5fEapSNa5De2bZqWxNJbgyMjmmPeQMiyXOf8K5hxp1uchyO5KGxecOkXbTT5HR7jSpFG_LXkFr47loqTL_mcpblNfPyVuN8Sfiw_Oubgxxc0x4tAAvxPaAsGqyWetxrfkJAbIFGdrNSXHffjpuVbxERuHAB69QXOmtwdddxKQC7g14MOTrFrgxSg1XorRhYDkem9EQpNM1xPWyjgGu-qKlXgoT0LkK6Gk46-uqKTHwvH3p-TFnEdyAJPzgc6QBLVvs3ODQ9SOMpCzdeJQAm-YcU1OtjDhBfxTIvl9GgIEjo-jPvKbvK1yTTTy5euYFK9mLKmarC9i-Lkbp8hS5ZvVcWb83rilppQlg6CeZxHgNHp-srhPc6HG9CbDPQxEZcGGuTf7jqscO3XHNV-fRIAO50y4dt6O4RwVw9naS8XcMnqzyckoqwXL_apdr4gDb-ZtqYy_MzFe4j4f9X5qF0jX9goz_Dk9dsgAvSvOdkFfwWIU7oh-z-lkGQAI4r3tZKk9UXoaEtzhtJjiOmRpmeB9pyKxH2h1vNS38lR9Z_xqmJiRvrq9VqYtyisFzaYb3yU4Xfaqr80LGbXA5Kx5MYiWXEZ20dhfP97LJ53RTY9OE8gBdbWV59ph-bjzawKuhFHR5WeCdQAiFGqBQ0zf-W380k6gM0WvyL6ntOJtFax0Q1hQ0AMT1Xhyfu7HrrNqTuoeX-3PpnCxrpIW-lENY2AuDsdVK-vx7SCyBadhBot_LrCG_B65GNpQyPyHEhTaxAobTjGrZTSrh154E7JjVpix9u1en8cGhEwicrIQ9EDJ7Mco42j411ACtmIBDk=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvXkDSyqU-5bVhk1E3eVdXRjpS2U7Jk6aBLp916Jg9WIjOqzitubgSPp0XD4k3zqt6dwyXXg1bBph9POmPwoNcqtoh--v-jknkPIi5WP-PxroUsv7lw1sxwSH0gzy3wgOvvH57nAkGSMtUfpd0KtHuRWzWxMXxl340e0S7C9ONuZR66fo0oiV2r6N1cgtMmAMoKR1RR4vFXgs-eKKYU2kb6Nrxc--rCCW9fWUG5B7ISj4VfF1-Xt5U3jTVqBiwkDedKV0aloqXrW6iYbZ82Frofd0dk0A6pCsmbaLWMfuHEH459HuApetNpLtIEdCQpUVTOPOccOZjIGAFA8GHLH8AWQGayf7xpaJqs9r95sryk0evbYj1qzTaCDJDijGB-mOrMrCaepEC_cEry1oBSDBqF3PHBrWiX0kKL3hKsiFaVB-pPjNZ8bp6C6S4SBhO_jRdkVYqANQyhaytZm5gL2Nf7-MWDJn_KG7NvCnKRLupCG1w91Fz-AbUP2XFqzU1QaruiKHsD7no_cDrlji7ctn5-WojsZiJsBLLMVDM30O-9eAhPo_5fEapSNa5De2bZqWxNJbgyMjmmPeQMiyXOf8K5hxp1uchyO5KGxecOkXbTT5HR7jSpFG_LXkFr47loqTL_mcpblNfPyVuN8Sfiw_Oubgxxc0x4tAAvxPaAsGqyWetxrfkJAbIFGdrNSXHffjpuVbxERuHAB69QXOmtwdddxKQC7g14MOTrFrgxSg1XorRhYDkem9EQpNM1xPWyjgGu-qKlXgoT0LkK6Gk46-uqKTHwvH3p-TFnEdyAJPzgc6QBLVvs3ODQ9SOMpCzdeJQAm-YcU1OtjDhBfxTIvl9GgIEjo-jPvKbvK1yTTTy5euYFK9mLKmarC9i-Lkbp8hS5ZvVcWb83rilppQlg6CeZxHgNHp-srhPc6HG9CbDPQxEZcGGuTf7jqscO3XHNV-fRIAO50y4dt6O4RwVw9naS8XcMnqzyckoqwXL_apdr4gDb-ZtqYy_MzFe4j4f9X5qF0jX9goz_Dk9dsgAvSvOdkFfwWIU7oh-z-lkGQAI4r3tZKk9UXoaEtzhtJjiOmRpmeB9pyKxH2h1vNS38lR9Z_xqmJiRvrq9VqYtyisFzaYb3yU4Xfaqr80LGbXA5Kx5MYiWXEZ20dhfP97LJ53RTY9OE8gBdbWV59ph-bjzawKuhFHR5WeCdQAiFGqBQ0zf-W380k6gM0WvyL6ntOJtFax0Q1hQ0AMT1Xhyfu7HrrNqTuoeX-3PpnCxrpIW-lENY2AuDsdVK-vx7SCyBadhBot_LrCG_B65GNpQyPyHEhTaxAobTjGrZTSrh154E7JjVpix9u1en8cGhEwicrIQ9EDJ7Mco42j411ACtmIBDk=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvXkDSyqU-5bVhk1E3eVdXRjpS2U7Jk6aBLp916Jg9WIjOqzitubgSPp0XD4k3zqt6dwyXXg1bBph9POmPwoNcqtoh--v-jknkPIi5WP-PxroUsv7lw1sxwSH0gzy3wgOvvH57nAkGSMtUfpd0KtHuRWzWxMXxl340e0S7C9ONuZR66fo0oiV2r6N1cgtMmAMoKR1RR4vFXgs-eKKYU2kb6Nrxc--rCCW9fWUG5B7ISj4VfF1-Xt5U3jTVqBiwkDedKV0aloqXrW6iYbZ82Frofd0dk0A6pCsmbaLWMfuHEH459HuApetNpLtIEdCQpUVTOPOccOZjIGAFA8GHLH8AWQGayf7xpaJqs9r95sryk0evbYj1qzTaCDJDijGB-mOrMrCaepEC_cEry1oBSDBqF3PHBrWiX0kKL3hKsiFaVB-pPjNZ8bp6C6S4SBhO_jRdkVYqANQyhaytZm5gL2Nf7-MWDJn_KG7NvCnKRLupCG1w91Fz-AbUP2XFqzU1QaruiKHsD7no_cDrlji7ctn5-WojsZiJsBLLMVDM30O-9eAhPo_5fEapSNa5De2bZqWxNJbgyMjmmPeQMiyXOf8K5hxp1uchyO5KGxecOkXbTT5HR7jSpFG_LXkFr47loqTL_mcpblNfPyVuN8Sfiw_Oubgxxc0x4tAAvxPaAsGqyWetxrfkJAbIFGdrNSXHffjpuVbxERuHAB69QXOmtwdddxKQC7g14MOTrFrgxSg1XorRhYDkem9EQpNM1xPWyjgGu-qKlXgoT0LkK6Gk46-uqKTHwvH3p-TFnEdyAJPzgc6QBLVvs3ODQ9SOMpCzdeJQAm-YcU1OtjDhBfxTIvl9GgIEjo-jPvKbvK1yTTTy5euYFK9mLKmarC9i-Lkbp8hS5ZvVcWb83rilppQlg6CeZxHgNHp-srhPc6HG9CbDPQxEZcGGuTf7jqscO3XHNV-fRIAO50y4dt6O4RwVw9naS8XcMnqzyckoqwXL_apdr4gDb-ZtqYy_MzFe4j4f9X5qF0jX9goz_Dk9dsgAvSvOdkFfwWIU7oh-z-lkGQAI4r3tZKk9UXoaEtzhtJjiOmRpmeB9pyKxH2h1vNS38lR9Z_xqmJiRvrq9VqYtyisFzaYb3yU4Xfaqr80LGbXA5Kx5MYiWXEZ20dhfP97LJ53RTY9OE8gBdbWV59ph-bjzawKuhFHR5WeCdQAiFGqBQ0zf-W380k6gM0WvyL6ntOJtFax0Q1hQ0AMT1Xhyfu7HrrNqTuoeX-3PpnCxrpIW-lENY2AuDsdVK-vx7SCyBadhBot_LrCG_B65GNpQyPyHEhTaxAobTjGrZTSrh154E7JjVpix9u1en8cGhEwicrIQ9EDJ7Mco42j411ACtmIBDk=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e160000a6145884",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e160000a6145884_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:55:00.000Z",
                    "utc_departure": "2024-09-24T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e160000ef37d710",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e160000ef37d710_0",
                    "local_arrival": "2024-09-24T14:40:00.000Z",
                    "local_departure": "2024-09-24T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:40:00.000Z",
                    "utc_departure": "2024-09-24T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e16000082afdaf7",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e16000082afdaf7_0",
                    "local_arrival": "2024-09-24T18:35:00.000Z",
                    "local_departure": "2024-09-24T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:35:00.000Z",
                    "utc_departure": "2024-09-24T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1163.96,
            "totalPrice": 869
          },
          {
            "allPassengerTotalPrice": 872,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GueSJgOdTeypDspC9SnjYoBRi9wplUu27r1uqBuMSTpRJqVvhdD4Gu30jcvDwfwv6QoVTNNnUyaRxQxPZdTOXAyEC28JuTvfsfnVMntI3S6VnhDqfiUbEn-U3UlYSRwxRu-b6GZB9g0LIGvddpJ9FH_iXEadgyqhIbF5zZxh3pW2gT9siNrkA8URg2iiOp41x1ic_ri7HCEYJUYwGQJkVaNbT4Io2bEkDSXYhT40IVsOHNjr0z5BzgiNJWBuiXhKJTS4x0JnPtjcn-704H-5XNeNfy_DU3vjraUcTUUVC1FUd-eSDuSot5XFisk5cQF65GP78PqG0vFej1ojLuYDPMlfbw82O7Z5pKRcrZxqA3Qw9TXb1lkSrtuS2Mxad9o6grFX03jAueNrXwkOqSNjNqx4M1GVH9NCkdcwvMe5o6LKYs3U_Ri3Onbe5H5dA58giBQH5_R9WqqJsgdsB8DbvTkTpwtbnr1epBwm-YcgQcWu6i3ptShUNJbUqRsY59Ry5tBESkhd86Nw6Hf3hzz99b3WCeUVA-82VqQzSjiF1SVGpPg3r-3FqzL4S1jZ1LI-o97wzcz_BgVg9a0KCStE7wdIY5ExVC7eJgZvBkcF1IxVfBbEe9nuYjsas1AtVRDWW-1hJO50navReSCWVVrS_z5afxA0u-eQXtWNXV8IJKOha-saNvWLoWe57KY-mgq1gtFQDKpQg_5xnOVIzvfpzdCVnY88fvDVPUNtR-vbBPGnGAC4HNmqoNjy5OD4OAbIBuKSNP0p_kwVLH-2ib8xuYTsah3ZftMywjBOzZXVcgIx8F3onc9om7vgEtjLfn6ZdPBHe6zM05n9Oxhhx3KB6egvi24gCX2d0K_89rYS1EVYL1k5TFQdglaWYGlix0qQa0z0IrI4Qz4PKcDV-SXNDEo8QRQvhPIRmnRviyeoBYv4d4jnGEelrs2vU6g_NwAr7l1_MinM9qlTU_BUltytAr3ft0Rhl2ES4kxjqv25BqiT3gs70JZ8nMePehLXMN3eRSpgUtV8AoSYmV_vK5uSQVdUf_2atArXXdaH6YoJeOZ5XWVlqeSJOarpOGtYrW7YKKThu65YGmW3FtnfO0S6y9v1gR6aqwNKksU7jtUUs6nYjJWexARUA-TimrXqUgA-u-fQQxa3_G5HJNu6nele7Ss5Ph-PtPZpcCE-6E8BDDp4qeHgwoXLSOxcL-_9OLVxP0m_zp0bqbUmHpQ5jPqlC2NPvoLszcWDWulZEQc9hBEY3jEtioqcC07blpnGe__xe5pjXPCA7LWQK2DEDHBW1aLQm1VutAsiBJgEegv521nyxX5r_xebksNdSO44bqSQflXV2DKiN3Iwdo4De5L_Z27KKAU28lJD98toFQYsS7BM=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GueSJgOdTeypDspC9SnjYoBRi9wplUu27r1uqBuMSTpRJqVvhdD4Gu30jcvDwfwv6QoVTNNnUyaRxQxPZdTOXAyEC28JuTvfsfnVMntI3S6VnhDqfiUbEn-U3UlYSRwxRu-b6GZB9g0LIGvddpJ9FH_iXEadgyqhIbF5zZxh3pW2gT9siNrkA8URg2iiOp41x1ic_ri7HCEYJUYwGQJkVaNbT4Io2bEkDSXYhT40IVsOHNjr0z5BzgiNJWBuiXhKJTS4x0JnPtjcn-704H-5XNeNfy_DU3vjraUcTUUVC1FUd-eSDuSot5XFisk5cQF65GP78PqG0vFej1ojLuYDPMlfbw82O7Z5pKRcrZxqA3Qw9TXb1lkSrtuS2Mxad9o6grFX03jAueNrXwkOqSNjNqx4M1GVH9NCkdcwvMe5o6LKYs3U_Ri3Onbe5H5dA58giBQH5_R9WqqJsgdsB8DbvTkTpwtbnr1epBwm-YcgQcWu6i3ptShUNJbUqRsY59Ry5tBESkhd86Nw6Hf3hzz99b3WCeUVA-82VqQzSjiF1SVGpPg3r-3FqzL4S1jZ1LI-o97wzcz_BgVg9a0KCStE7wdIY5ExVC7eJgZvBkcF1IxVfBbEe9nuYjsas1AtVRDWW-1hJO50navReSCWVVrS_z5afxA0u-eQXtWNXV8IJKOha-saNvWLoWe57KY-mgq1gtFQDKpQg_5xnOVIzvfpzdCVnY88fvDVPUNtR-vbBPGnGAC4HNmqoNjy5OD4OAbIBuKSNP0p_kwVLH-2ib8xuYTsah3ZftMywjBOzZXVcgIx8F3onc9om7vgEtjLfn6ZdPBHe6zM05n9Oxhhx3KB6egvi24gCX2d0K_89rYS1EVYL1k5TFQdglaWYGlix0qQa0z0IrI4Qz4PKcDV-SXNDEo8QRQvhPIRmnRviyeoBYv4d4jnGEelrs2vU6g_NwAr7l1_MinM9qlTU_BUltytAr3ft0Rhl2ES4kxjqv25BqiT3gs70JZ8nMePehLXMN3eRSpgUtV8AoSYmV_vK5uSQVdUf_2atArXXdaH6YoJeOZ5XWVlqeSJOarpOGtYrW7YKKThu65YGmW3FtnfO0S6y9v1gR6aqwNKksU7jtUUs6nYjJWexARUA-TimrXqUgA-u-fQQxa3_G5HJNu6nele7Ss5Ph-PtPZpcCE-6E8BDDp4qeHgwoXLSOxcL-_9OLVxP0m_zp0bqbUmHpQ5jPqlC2NPvoLszcWDWulZEQc9hBEY3jEtioqcC07blpnGe__xe5pjXPCA7LWQK2DEDHBW1aLQm1VutAsiBJgEegv521nyxX5r_xebksNdSO44bqSQflXV2DKiN3Iwdo4De5L_Z27KKAU28lJD98toFQYsS7BM=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af22f54e160000a6145884_0%7C22f524464e160000ef37d710_0%7C244610a14e16000082afdaf7_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GueSJgOdTeypDspC9SnjYoBRi9wplUu27r1uqBuMSTpRJqVvhdD4Gu30jcvDwfwv6QoVTNNnUyaRxQxPZdTOXAyEC28JuTvfsfnVMntI3S6VnhDqfiUbEn-U3UlYSRwxRu-b6GZB9g0LIGvddpJ9FH_iXEadgyqhIbF5zZxh3pW2gT9siNrkA8URg2iiOp41x1ic_ri7HCEYJUYwGQJkVaNbT4Io2bEkDSXYhT40IVsOHNjr0z5BzgiNJWBuiXhKJTS4x0JnPtjcn-704H-5XNeNfy_DU3vjraUcTUUVC1FUd-eSDuSot5XFisk5cQF65GP78PqG0vFej1ojLuYDPMlfbw82O7Z5pKRcrZxqA3Qw9TXb1lkSrtuS2Mxad9o6grFX03jAueNrXwkOqSNjNqx4M1GVH9NCkdcwvMe5o6LKYs3U_Ri3Onbe5H5dA58giBQH5_R9WqqJsgdsB8DbvTkTpwtbnr1epBwm-YcgQcWu6i3ptShUNJbUqRsY59Ry5tBESkhd86Nw6Hf3hzz99b3WCeUVA-82VqQzSjiF1SVGpPg3r-3FqzL4S1jZ1LI-o97wzcz_BgVg9a0KCStE7wdIY5ExVC7eJgZvBkcF1IxVfBbEe9nuYjsas1AtVRDWW-1hJO50navReSCWVVrS_z5afxA0u-eQXtWNXV8IJKOha-saNvWLoWe57KY-mgq1gtFQDKpQg_5xnOVIzvfpzdCVnY88fvDVPUNtR-vbBPGnGAC4HNmqoNjy5OD4OAbIBuKSNP0p_kwVLH-2ib8xuYTsah3ZftMywjBOzZXVcgIx8F3onc9om7vgEtjLfn6ZdPBHe6zM05n9Oxhhx3KB6egvi24gCX2d0K_89rYS1EVYL1k5TFQdglaWYGlix0qQa0z0IrI4Qz4PKcDV-SXNDEo8QRQvhPIRmnRviyeoBYv4d4jnGEelrs2vU6g_NwAr7l1_MinM9qlTU_BUltytAr3ft0Rhl2ES4kxjqv25BqiT3gs70JZ8nMePehLXMN3eRSpgUtV8AoSYmV_vK5uSQVdUf_2atArXXdaH6YoJeOZ5XWVlqeSJOarpOGtYrW7YKKThu65YGmW3FtnfO0S6y9v1gR6aqwNKksU7jtUUs6nYjJWexARUA-TimrXqUgA-u-fQQxa3_G5HJNu6nele7Ss5Ph-PtPZpcCE-6E8BDDp4qeHgwoXLSOxcL-_9OLVxP0m_zp0bqbUmHpQ5jPqlC2NPvoLszcWDWulZEQc9hBEY3jEtioqcC07blpnGe__xe5pjXPCA7LWQK2DEDHBW1aLQm1VutAsiBJgEegv521nyxX5r_xebksNdSO44bqSQflXV2DKiN3Iwdo4De5L_Z27KKAU28lJD98toFQYsS7BM=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e160000a6145884",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e160000a6145884_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:55:00.000Z",
                    "utc_departure": "2024-09-24T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e160000ef37d710",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e160000ef37d710_0",
                    "local_arrival": "2024-09-24T14:40:00.000Z",
                    "local_departure": "2024-09-24T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:40:00.000Z",
                    "utc_departure": "2024-09-24T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e16000082afdaf7",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e16000082afdaf7_0",
                    "local_arrival": "2024-09-24T18:35:00.000Z",
                    "local_departure": "2024-09-24T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:35:00.000Z",
                    "utc_departure": "2024-09-24T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1166.45,
            "totalPrice": 872
          },
          {
            "allPassengerTotalPrice": 874,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000c3b984dc_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLe3SOwwOTSqwKldh8npWNzYKJfrCcF2DhKeyX0mUU4tJlwbrGyQPQIDjGQW4oLz0tjBJFQbO4DJQrf6XxffVgrwu_oKgjRtoP4-qAft_gCdETgxxAs0nevM3-fkiGm72UFmf6kQwnl2p6ZmRrIn2TeMPC489m7ECe3N7KnUuhp9ikkBpj1XVGHt0QDMd1bsbtxw8WRV-XK-0SWemBdxKN_BhrhUKJLnA10PoVL75HV8DVAYQWlKjzfDOY0E9zitwo0RkSi2WBkHWJzL7HYS6gM4ZCJXaTTxzQrSEk1UAmI4AkOMkzSIdpJEkNqS1Baq83Y-ymW9XwmGqRHZTpYdEQknNOYB3nmhbSfNk2Bw4fpZy9NbDovrXDm8p67zaUux9b9ZI2O1u2O6E7nxPlpah5gLazXdzLoqOej7mBs70PY_CXdNoLuC2imev1uIgcfAftBy4-UE5Fy74eFm1R5MEcfqUnZlmmwZ0BDYdYhRtDStMCryoSicgGyNTzHCIao7c1J_-vWpLDxFwAyybDjUmvROzthAmBh1fSZ8je07c5id-prkPzDJZcrmb6mL9YLJijsqanw65vtQCWwtDZhmhRrtCw89WkbJVt_FbXyhz7EehR3BGWYhOaq2xCAfgmQSyd6TA-Lk69j0S4NVhM7fZoJ5qCHt1gS3C1pChWGJ111T93q-UqW-MVUAt86sKWkPO97CBxSXnCi-J8xk3kE1qkEN2vHuqhNbBxekStTF-cVU20tvZCq1cylZf6Rw5J6BIkKSt-iNfn8VGB_vCzWjr7r4dtqLkjfAQ1baGlKlYJxQplL6m2asdP8g1fnC8PDlnLdK8TN5GOUWLMa36lt7L5HIF814RN6HxorDijV1HzGr1l050WNfxHtcucovgCsVL2gC022qxkMeDim4XKbdsDU_2h-odIP_UVcgffXcCRkTKw8jbKcL9sjPg44HaUgCyq6lbmUqHeh9eYMkqzbJTp8_cq2c-wJy4N6IzNJ4d3-bB40Y5U4HyDqWtjnALVVdF1Aj_tLxkocByTFBLxMAyIYtkrUoG7juNFFvWgKJj6-0aGq2GTPUxgLvRmm5YMtoVcxAwUplCvHRawKkItuV7y9iItEauHfAZm9NYw8qkzO3JCXQP86ipKWTuk2ElIj_sY5mst8FZv7HTyA0x36PkDIkfFXGeSDaQry1YxuUtxhbCU2n0uqe8dBbpyOybXOQlb_KaJU_q1PkDlunqf_ZiszEe1x56cC1NZS1A3avBQcw=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "05:50",
                "destination": "BCN",
                "duration": "25h20m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Oakland",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e05000086879fbe",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1349,
                    "flyFrom": "OAK",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e05000086879fbe_0",
                    "local_arrival": "2024-09-07T07:19:00.000Z",
                    "local_departure": "2024-09-07T05:50:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "1349",
                    "return": 0,
                    "utc_arrival": "2024-09-07T14:19:00.000Z",
                    "utc_departure": "2024-09-07T12:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000c3b984dc_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLe3SOwwOTSqwKldh8npWNzYKJfrCcF2DhKeyX0mUU4tJlwbrGyQPQIDjGQW4oLz0tjBJFQbO4DJQrf6XxffVgrwu_oKgjRtoP4-qAft_gCdETgxxAs0nevM3-fkiGm72UFmf6kQwnl2p6ZmRrIn2TeMPC489m7ECe3N7KnUuhp9ikkBpj1XVGHt0QDMd1bsbtxw8WRV-XK-0SWemBdxKN_BhrhUKJLnA10PoVL75HV8DVAYQWlKjzfDOY0E9zitwo0RkSi2WBkHWJzL7HYS6gM4ZCJXaTTxzQrSEk1UAmI4AkOMkzSIdpJEkNqS1Baq83Y-ymW9XwmGqRHZTpYdEQknNOYB3nmhbSfNk2Bw4fpZy9NbDovrXDm8p67zaUux9b9ZI2O1u2O6E7nxPlpah5gLazXdzLoqOej7mBs70PY_CXdNoLuC2imev1uIgcfAftBy4-UE5Fy74eFm1R5MEcfqUnZlmmwZ0BDYdYhRtDStMCryoSicgGyNTzHCIao7c1J_-vWpLDxFwAyybDjUmvROzthAmBh1fSZ8je07c5id-prkPzDJZcrmb6mL9YLJijsqanw65vtQCWwtDZhmhRrtCw89WkbJVt_FbXyhz7EehR3BGWYhOaq2xCAfgmQSyd6TA-Lk69j0S4NVhM7fZoJ5qCHt1gS3C1pChWGJ111T93q-UqW-MVUAt86sKWkPO97CBxSXnCi-J8xk3kE1qkEN2vHuqhNbBxekStTF-cVU20tvZCq1cylZf6Rw5J6BIkKSt-iNfn8VGB_vCzWjr7r4dtqLkjfAQ1baGlKlYJxQplL6m2asdP8g1fnC8PDlnLdK8TN5GOUWLMa36lt7L5HIF814RN6HxorDijV1HzGr1l050WNfxHtcucovgCsVL2gC022qxkMeDim4XKbdsDU_2h-odIP_UVcgffXcCRkTKw8jbKcL9sjPg44HaUgCyq6lbmUqHeh9eYMkqzbJTp8_cq2c-wJy4N6IzNJ4d3-bB40Y5U4HyDqWtjnALVVdF1Aj_tLxkocByTFBLxMAyIYtkrUoG7juNFFvWgKJj6-0aGq2GTPUxgLvRmm5YMtoVcxAwUplCvHRawKkItuV7y9iItEauHfAZm9NYw8qkzO3JCXQP86ipKWTuk2ElIj_sY5mst8FZv7HTyA0x36PkDIkfFXGeSDaQry1YxuUtxhbCU2n0uqe8dBbpyOybXOQlb_KaJU_q1PkDlunqf_ZiszEe1x56cC1NZS1A3avBQcw=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000c3b984dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000c3b984dc_0",
                    "local_arrival": "2024-09-17T13:45:00.000Z",
                    "local_departure": "2024-09-17T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-17T11:45:00.000Z",
                    "utc_departure": "2024-09-17T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e05000086879fbe_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000c3b984dc_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLe3SOwwOTSqwKldh8npWNzYKJfrCcF2DhKeyX0mUU4tJlwbrGyQPQIDjGQW4oLz0tjBJFQbO4DJQrf6XxffVgrwu_oKgjRtoP4-qAft_gCdETgxxAs0nevM3-fkiGm72UFmf6kQwnl2p6ZmRrIn2TeMPC489m7ECe3N7KnUuhp9ikkBpj1XVGHt0QDMd1bsbtxw8WRV-XK-0SWemBdxKN_BhrhUKJLnA10PoVL75HV8DVAYQWlKjzfDOY0E9zitwo0RkSi2WBkHWJzL7HYS6gM4ZCJXaTTxzQrSEk1UAmI4AkOMkzSIdpJEkNqS1Baq83Y-ymW9XwmGqRHZTpYdEQknNOYB3nmhbSfNk2Bw4fpZy9NbDovrXDm8p67zaUux9b9ZI2O1u2O6E7nxPlpah5gLazXdzLoqOej7mBs70PY_CXdNoLuC2imev1uIgcfAftBy4-UE5Fy74eFm1R5MEcfqUnZlmmwZ0BDYdYhRtDStMCryoSicgGyNTzHCIao7c1J_-vWpLDxFwAyybDjUmvROzthAmBh1fSZ8je07c5id-prkPzDJZcrmb6mL9YLJijsqanw65vtQCWwtDZhmhRrtCw89WkbJVt_FbXyhz7EehR3BGWYhOaq2xCAfgmQSyd6TA-Lk69j0S4NVhM7fZoJ5qCHt1gS3C1pChWGJ111T93q-UqW-MVUAt86sKWkPO97CBxSXnCi-J8xk3kE1qkEN2vHuqhNbBxekStTF-cVU20tvZCq1cylZf6Rw5J6BIkKSt-iNfn8VGB_vCzWjr7r4dtqLkjfAQ1baGlKlYJxQplL6m2asdP8g1fnC8PDlnLdK8TN5GOUWLMa36lt7L5HIF814RN6HxorDijV1HzGr1l050WNfxHtcucovgCsVL2gC022qxkMeDim4XKbdsDU_2h-odIP_UVcgffXcCRkTKw8jbKcL9sjPg44HaUgCyq6lbmUqHeh9eYMkqzbJTp8_cq2c-wJy4N6IzNJ4d3-bB40Y5U4HyDqWtjnALVVdF1Aj_tLxkocByTFBLxMAyIYtkrUoG7juNFFvWgKJj6-0aGq2GTPUxgLvRmm5YMtoVcxAwUplCvHRawKkItuV7y9iItEauHfAZm9NYw8qkzO3JCXQP86ipKWTuk2ElIj_sY5mst8FZv7HTyA0x36PkDIkfFXGeSDaQry1YxuUtxhbCU2n0uqe8dBbpyOybXOQlb_KaJU_q1PkDlunqf_ZiszEe1x56cC1NZS1A3avBQcw=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1163.31,
            "totalPrice": 874
          },
          {
            "allPassengerTotalPrice": 887,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "23:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e25c34e050000416baa5c_0%7C25c301af4e060000a6bc0c13_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZ1yA9wfoeqt6JzR5E784Prv_NwYK7OcmB3gS64RE7vUkwHBj5jqbNvSFv9PNRPVL6IrHxENGtG-4H4M0rfO87bx-QrG57KCHkEBhx2JYRqY3Eql_7NXKH1zs45ShndRNeY2RTk15FgAE9xAw-aVGn3hz4PaD6umBSu0U-4p_Ky1rY8cqC5jA1mNFLKF6BBqbX2fYYxHKTnwx5_G_2rrDUvIcBLvxaMjjhB_5VNJZ7pNbouDx2MR_sPYSAcUHiRH9wpXRI77PnxBNPbacNjqWnZepGPZIFt2p-59GsAhl-XF0PC9WYNR2lRndukM859vcHIxu0tvhyWiXDry6uqv2atPSn1vhW6VxPNrNq-O_8Hf6g73Z3yTneuQbTEAa9vdGYBKrZeRmk6NvwYW6o82Q818Hpknk824K6uQVlxNqLhSKwOmMAp4-O9vwPk6pzpZAwQx3FR4SMFN4IEcTEqXxQ4_fZRGA-IVX_0bcY-3aw7Bssat-qfGF17M4xmqaqoviErFvqfPlqRuLFPiDBRLhb7lumrtWBahmEEZn7owqLj1UGOh3HagoastCp_Xna0UCR0N4TTX4xDsz3Or6YXk2Ow_mw_K9ugOKaBYHnme_xxEhVJgsaUw4fCt52d7TcNXtKi6D3-SQemgrg2M7AYuX55juXZUqtZUmSDSzX_hyJwpswUNxP3kJr4ht_DqA8zti7cWwj7_wN8mZw-wDEvFxrPOsscd5oIpBDhpuwQ7sMW4uOJEiavysAoUGno0lk0luVQZa18X0RpSWPOtBdEkR1AOCOWGpxhxqcVf8TscTR1jZAHL6QkhRRfcMhX0a0QbezFNF7qseBJGRWoEgDuXRmfvyQoAr_e5bbpK-i6e73BdbgObKeDALkwGDhvIzIcJnKnDETZS7j_q_ivo1dmbtZYENrtClhKFeONQC9oKCq52qAf4FZmgXrhevDY5LgcKNVPOLtxUuECMEwfatK3Ja1KXQ0gn8bLkRNvcbRpoJpQcAQQ0hMtJYNRcSkkWh_2QfK4V0QDjMfSDuw-aQpjtcGwXtyOoVUzUf_CN49dcD1-BC48LUFPkmHb3gMwWVCOczmvaeL-xnIxYneUmFRKWXVvH8qG8LPXArXgwd-qxMcHuQXNDHKnMfPGgxrhKwbmPjPaR8XLdKjg8iU4EXwYPEQkYSVxmcWOYdzSWAF_oNJ4eijW9w5Cbf16yugCwuxUZKHFgrH15AtzpKoUL2-rwLgeQCtd6CLlopwGrCAxaD6-MzJIXnxFiW4h9pU7my87Vo3QYtYn4nNTRMQAx19I2gug==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "23h29m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "N0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e25c34e050000416baa5c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 312,
                    "flyFrom": "LAX",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "243e25c34e050000416baa5c_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T21:10:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "25c301af4e060000a6bc0c13",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 8247,
                    "flyFrom": "CDG",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "25c301af4e060000a6bc0c13_0",
                    "local_arrival": "2024-09-08T23:10:00.000Z",
                    "local_departure": "2024-09-08T21:15:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "8247",
                    "return": 0,
                    "utc_arrival": "2024-09-08T21:10:00.000Z",
                    "utc_departure": "2024-09-08T19:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e25c34e050000416baa5c_0%7C25c301af4e060000a6bc0c13_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZ1yA9wfoeqt6JzR5E784Prv_NwYK7OcmB3gS64RE7vUkwHBj5jqbNvSFv9PNRPVL6IrHxENGtG-4H4M0rfO87bx-QrG57KCHkEBhx2JYRqY3Eql_7NXKH1zs45ShndRNeY2RTk15FgAE9xAw-aVGn3hz4PaD6umBSu0U-4p_Ky1rY8cqC5jA1mNFLKF6BBqbX2fYYxHKTnwx5_G_2rrDUvIcBLvxaMjjhB_5VNJZ7pNbouDx2MR_sPYSAcUHiRH9wpXRI77PnxBNPbacNjqWnZepGPZIFt2p-59GsAhl-XF0PC9WYNR2lRndukM859vcHIxu0tvhyWiXDry6uqv2atPSn1vhW6VxPNrNq-O_8Hf6g73Z3yTneuQbTEAa9vdGYBKrZeRmk6NvwYW6o82Q818Hpknk824K6uQVlxNqLhSKwOmMAp4-O9vwPk6pzpZAwQx3FR4SMFN4IEcTEqXxQ4_fZRGA-IVX_0bcY-3aw7Bssat-qfGF17M4xmqaqoviErFvqfPlqRuLFPiDBRLhb7lumrtWBahmEEZn7owqLj1UGOh3HagoastCp_Xna0UCR0N4TTX4xDsz3Or6YXk2Ow_mw_K9ugOKaBYHnme_xxEhVJgsaUw4fCt52d7TcNXtKi6D3-SQemgrg2M7AYuX55juXZUqtZUmSDSzX_hyJwpswUNxP3kJr4ht_DqA8zti7cWwj7_wN8mZw-wDEvFxrPOsscd5oIpBDhpuwQ7sMW4uOJEiavysAoUGno0lk0luVQZa18X0RpSWPOtBdEkR1AOCOWGpxhxqcVf8TscTR1jZAHL6QkhRRfcMhX0a0QbezFNF7qseBJGRWoEgDuXRmfvyQoAr_e5bbpK-i6e73BdbgObKeDALkwGDhvIzIcJnKnDETZS7j_q_ivo1dmbtZYENrtClhKFeONQC9oKCq52qAf4FZmgXrhevDY5LgcKNVPOLtxUuECMEwfatK3Ja1KXQ0gn8bLkRNvcbRpoJpQcAQQ0hMtJYNRcSkkWh_2QfK4V0QDjMfSDuw-aQpjtcGwXtyOoVUzUf_CN49dcD1-BC48LUFPkmHb3gMwWVCOczmvaeL-xnIxYneUmFRKWXVvH8qG8LPXArXgwd-qxMcHuQXNDHKnMfPGgxrhKwbmPjPaR8XLdKjg8iU4EXwYPEQkYSVxmcWOYdzSWAF_oNJ4eijW9w5Cbf16yugCwuxUZKHFgrH15AtzpKoUL2-rwLgeQCtd6CLlopwGrCAxaD6-MzJIXnxFiW4h9pU7my87Vo3QYtYn4nNTRMQAx19I2gug==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e25c34e050000416baa5c_0%7C25c301af4e060000a6bc0c13_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZ1yA9wfoeqt6JzR5E784Prv_NwYK7OcmB3gS64RE7vUkwHBj5jqbNvSFv9PNRPVL6IrHxENGtG-4H4M0rfO87bx-QrG57KCHkEBhx2JYRqY3Eql_7NXKH1zs45ShndRNeY2RTk15FgAE9xAw-aVGn3hz4PaD6umBSu0U-4p_Ky1rY8cqC5jA1mNFLKF6BBqbX2fYYxHKTnwx5_G_2rrDUvIcBLvxaMjjhB_5VNJZ7pNbouDx2MR_sPYSAcUHiRH9wpXRI77PnxBNPbacNjqWnZepGPZIFt2p-59GsAhl-XF0PC9WYNR2lRndukM859vcHIxu0tvhyWiXDry6uqv2atPSn1vhW6VxPNrNq-O_8Hf6g73Z3yTneuQbTEAa9vdGYBKrZeRmk6NvwYW6o82Q818Hpknk824K6uQVlxNqLhSKwOmMAp4-O9vwPk6pzpZAwQx3FR4SMFN4IEcTEqXxQ4_fZRGA-IVX_0bcY-3aw7Bssat-qfGF17M4xmqaqoviErFvqfPlqRuLFPiDBRLhb7lumrtWBahmEEZn7owqLj1UGOh3HagoastCp_Xna0UCR0N4TTX4xDsz3Or6YXk2Ow_mw_K9ugOKaBYHnme_xxEhVJgsaUw4fCt52d7TcNXtKi6D3-SQemgrg2M7AYuX55juXZUqtZUmSDSzX_hyJwpswUNxP3kJr4ht_DqA8zti7cWwj7_wN8mZw-wDEvFxrPOsscd5oIpBDhpuwQ7sMW4uOJEiavysAoUGno0lk0luVQZa18X0RpSWPOtBdEkR1AOCOWGpxhxqcVf8TscTR1jZAHL6QkhRRfcMhX0a0QbezFNF7qseBJGRWoEgDuXRmfvyQoAr_e5bbpK-i6e73BdbgObKeDALkwGDhvIzIcJnKnDETZS7j_q_ivo1dmbtZYENrtClhKFeONQC9oKCq52qAf4FZmgXrhevDY5LgcKNVPOLtxUuECMEwfatK3Ja1KXQ0gn8bLkRNvcbRpoJpQcAQQ0hMtJYNRcSkkWh_2QfK4V0QDjMfSDuw-aQpjtcGwXtyOoVUzUf_CN49dcD1-BC48LUFPkmHb3gMwWVCOczmvaeL-xnIxYneUmFRKWXVvH8qG8LPXArXgwd-qxMcHuQXNDHKnMfPGgxrhKwbmPjPaR8XLdKjg8iU4EXwYPEQkYSVxmcWOYdzSWAF_oNJ4eijW9w5Cbf16yugCwuxUZKHFgrH15AtzpKoUL2-rwLgeQCtd6CLlopwGrCAxaD6-MzJIXnxFiW4h9pU7my87Vo3QYtYn4nNTRMQAx19I2gug==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1167.51,
            "totalPrice": 887
          },
          {
            "allPassengerTotalPrice": 899,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "23:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e25c34e050000416baa5c_0%7C25c301af4e060000a6bc0c13_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVMmICJPNJTXGrBWweMZteL8bPSYsvcnqSOx_EkAKuhZKJ55UEhtblXYNbnDxWOA3V0JJCG1KdB9ycTOXySJUQFsMCpxmx3o3vABZZy4B3RJW9_B6N0TQGT02Pe4vYgQOA_xRQ8RE4PnzWNQU13r48P4LrEEeBWYKRTMtpPHusnDk0GE_gH6JL__3JhCoOUsK5C5nbi9eAhGQyWTqt0oFmzl0P15MbsQq1h3aVe3vAO7Or_Z333MQsEWgJILg8tGpwyqfJYAF5AMrBO8ohzTGSu92r3nizwCoSJWhY03iggUlkfnunmEM1DozXMTSATFEYagIVQLqD8NzzBBsONAIlEn7nP8zRcm1ZVALASSXyBKnKmC95eCMH-DlS7sqiJP2kdCMuoxCk_7J8WAGpiIXd0j4bDy5BZ2IeRw0dRUx4aFRnjmRDx0Om5Qy-SdEUX8woAuLuz4xg36ZpLDNwjVZ742nyBaUSF1flxdDFLnqIPk6TS1b4HjbUaBM1_wRiUKcJHsjv-90u66LBVP4yDYSo9Ji13Qe__HR11ni3e-8zQyZA8_1KIbsQfKYyQNXMMofbiWaABIJrroLw-kwS5P7zEwr6F-CdlDnMn3ijhMBXpGXpv5A21xjCbSAiZPvwP2MG4pd1ZUes8NijuWlI8XE_VsoPh5tTpfFo8LuzEKNrVZvxoP081AQEDITgiLITQfWoG0TEPZ8rNmjyab97n0H08ApnHMpLNVPxHmrMoBwyAQTQQ6_5cImf8rhVwWzW3FbwocV__eHvPPMMzNVm_y_u5RCuRaCtqyVFrMxLbGPZUx_NKbb8AyNMpmDaUTAAgUTn4LAcfJ_5cTIMk3J_WSCYRpSYQ2MecRKBsEA5q8yKybcQXZwOoTMrDi3GB4urKtouzUAesCY4ECLLS_FcxporxWE0GtyCMF-kLg4toInoUYATyHbk58ZviPAz4k2vTDnXo82W4vktq0vbeOggKd00m8OTZwH6dTFBJ8A59vOVJvhGnp-OhKcj8TvdQONVM1wPNFxtjFJi-7eIyTGvOibArQZvWmGCLjNXjmSeOSXr7yViLIj_D0cEqZRvLYEg6B3AaJ6xLvw4HGkgcCdRGFjkvj5JWJVwX9xPx61qlK5toio3jQ9SW8GGB_0ZLtq3TDWMhgUYKv55HO4FF0lvzrhi3wU9gclsTtszBT3SMf77JALQKkvn_HbSjIK2y8VLM63",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "23h29m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "N0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e25c34e050000416baa5c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 312,
                    "flyFrom": "LAX",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "243e25c34e050000416baa5c_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T21:10:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "25c301af4e060000a6bc0c13",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 8247,
                    "flyFrom": "CDG",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "25c301af4e060000a6bc0c13_0",
                    "local_arrival": "2024-09-08T23:10:00.000Z",
                    "local_departure": "2024-09-08T21:15:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "8247",
                    "return": 0,
                    "utc_arrival": "2024-09-08T21:10:00.000Z",
                    "utc_departure": "2024-09-08T19:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e25c34e050000416baa5c_0%7C25c301af4e060000a6bc0c13_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVMmICJPNJTXGrBWweMZteL8bPSYsvcnqSOx_EkAKuhZKJ55UEhtblXYNbnDxWOA3V0JJCG1KdB9ycTOXySJUQFsMCpxmx3o3vABZZy4B3RJW9_B6N0TQGT02Pe4vYgQOA_xRQ8RE4PnzWNQU13r48P4LrEEeBWYKRTMtpPHusnDk0GE_gH6JL__3JhCoOUsK5C5nbi9eAhGQyWTqt0oFmzl0P15MbsQq1h3aVe3vAO7Or_Z333MQsEWgJILg8tGpwyqfJYAF5AMrBO8ohzTGSu92r3nizwCoSJWhY03iggUlkfnunmEM1DozXMTSATFEYagIVQLqD8NzzBBsONAIlEn7nP8zRcm1ZVALASSXyBKnKmC95eCMH-DlS7sqiJP2kdCMuoxCk_7J8WAGpiIXd0j4bDy5BZ2IeRw0dRUx4aFRnjmRDx0Om5Qy-SdEUX8woAuLuz4xg36ZpLDNwjVZ742nyBaUSF1flxdDFLnqIPk6TS1b4HjbUaBM1_wRiUKcJHsjv-90u66LBVP4yDYSo9Ji13Qe__HR11ni3e-8zQyZA8_1KIbsQfKYyQNXMMofbiWaABIJrroLw-kwS5P7zEwr6F-CdlDnMn3ijhMBXpGXpv5A21xjCbSAiZPvwP2MG4pd1ZUes8NijuWlI8XE_VsoPh5tTpfFo8LuzEKNrVZvxoP081AQEDITgiLITQfWoG0TEPZ8rNmjyab97n0H08ApnHMpLNVPxHmrMoBwyAQTQQ6_5cImf8rhVwWzW3FbwocV__eHvPPMMzNVm_y_u5RCuRaCtqyVFrMxLbGPZUx_NKbb8AyNMpmDaUTAAgUTn4LAcfJ_5cTIMk3J_WSCYRpSYQ2MecRKBsEA5q8yKybcQXZwOoTMrDi3GB4urKtouzUAesCY4ECLLS_FcxporxWE0GtyCMF-kLg4toInoUYATyHbk58ZviPAz4k2vTDnXo82W4vktq0vbeOggKd00m8OTZwH6dTFBJ8A59vOVJvhGnp-OhKcj8TvdQONVM1wPNFxtjFJi-7eIyTGvOibArQZvWmGCLjNXjmSeOSXr7yViLIj_D0cEqZRvLYEg6B3AaJ6xLvw4HGkgcCdRGFjkvj5JWJVwX9xPx61qlK5toio3jQ9SW8GGB_0ZLtq3TDWMhgUYKv55HO4FF0lvzrhi3wU9gclsTtszBT3SMf77JALQKkvn_HbSjIK2y8VLM63",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e25c34e050000416baa5c_0%7C25c301af4e060000a6bc0c13_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVMmICJPNJTXGrBWweMZteL8bPSYsvcnqSOx_EkAKuhZKJ55UEhtblXYNbnDxWOA3V0JJCG1KdB9ycTOXySJUQFsMCpxmx3o3vABZZy4B3RJW9_B6N0TQGT02Pe4vYgQOA_xRQ8RE4PnzWNQU13r48P4LrEEeBWYKRTMtpPHusnDk0GE_gH6JL__3JhCoOUsK5C5nbi9eAhGQyWTqt0oFmzl0P15MbsQq1h3aVe3vAO7Or_Z333MQsEWgJILg8tGpwyqfJYAF5AMrBO8ohzTGSu92r3nizwCoSJWhY03iggUlkfnunmEM1DozXMTSATFEYagIVQLqD8NzzBBsONAIlEn7nP8zRcm1ZVALASSXyBKnKmC95eCMH-DlS7sqiJP2kdCMuoxCk_7J8WAGpiIXd0j4bDy5BZ2IeRw0dRUx4aFRnjmRDx0Om5Qy-SdEUX8woAuLuz4xg36ZpLDNwjVZ742nyBaUSF1flxdDFLnqIPk6TS1b4HjbUaBM1_wRiUKcJHsjv-90u66LBVP4yDYSo9Ji13Qe__HR11ni3e-8zQyZA8_1KIbsQfKYyQNXMMofbiWaABIJrroLw-kwS5P7zEwr6F-CdlDnMn3ijhMBXpGXpv5A21xjCbSAiZPvwP2MG4pd1ZUes8NijuWlI8XE_VsoPh5tTpfFo8LuzEKNrVZvxoP081AQEDITgiLITQfWoG0TEPZ8rNmjyab97n0H08ApnHMpLNVPxHmrMoBwyAQTQQ6_5cImf8rhVwWzW3FbwocV__eHvPPMMzNVm_y_u5RCuRaCtqyVFrMxLbGPZUx_NKbb8AyNMpmDaUTAAgUTn4LAcfJ_5cTIMk3J_WSCYRpSYQ2MecRKBsEA5q8yKybcQXZwOoTMrDi3GB4urKtouzUAesCY4ECLLS_FcxporxWE0GtyCMF-kLg4toInoUYATyHbk58ZviPAz4k2vTDnXo82W4vktq0vbeOggKd00m8OTZwH6dTFBJ8A59vOVJvhGnp-OhKcj8TvdQONVM1wPNFxtjFJi-7eIyTGvOibArQZvWmGCLjNXjmSeOSXr7yViLIj_D0cEqZRvLYEg6B3AaJ6xLvw4HGkgcCdRGFjkvj5JWJVwX9xPx61qlK5toio3jQ9SW8GGB_0ZLtq3TDWMhgUYKv55HO4FF0lvzrhi3wU9gclsTtszBT3SMf77JALQKkvn_HbSjIK2y8VLM63",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1169.85,
            "totalPrice": 899
          },
          {
            "allPassengerTotalPrice": 907,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_0AWpWv0xI1UpMKv8dGV2IMCgYDYuHDNssEy9tyEuBMcmrpV_8CwTUAcVwRY6HLaTjISMuvPN9GkI4gShnSY_bcWuVND-lLvxZJ7OYsoMwuaF4RwFxjVMxvtReVh-2OoO28Ui-hWhgV4E7OLUoVDaVf4iYMgKO5ka0se-J203Eq6pI0aWNccpmqtrwZXW2JoLV-BWDZmkof33syHmt_fhIAyZfZzARHLUfy1zqfPOaXz6ivMp3LZ4iWEo_5Ggax5gZFNCUd4n44dJ_FzI6rtmncIPk_lijWW-zGCghSXK60_y5fGo0NeoNyLWocju0PlSoTMnbc80Xnh6W49q2ABOpLozSG8UhvtQ6i-oVBX288-_vLmdSexvb0fZd86Bghm8wcb7zmA-EIlAewcQvgy_hmS3fKbwug7e1J8oUvmsKRVfC40Mf5xOH43iaoBYPEpNjkSDtuKszhkIIpQgKEoFBhaHEWxgsNJSa-s-lrKkpEU1W8Y291g7ZWyqYq2FBhVpvdQ8ye14931V29sxLdV5ryf7L5z5pqM1RQEeLs_WrXQfZaP_Pk8I70rMUlPUeMN59Af2P3Cf2HM_wkvxXkeZqrzplB-w_YxipqELZtG3oMsbkEG_X_M8qH-7OhwLAQD6i71WoQS9pn5fEdb_J8vcmv6yxOnw24FrIa8eeYXGH-FXwPXOs8Tge6wyy88pyE6vlHIuOz8_PwyTkYv0QeGKiv-GwFoiXHuBX0NxdtxauiPWJOusmKT5RDekdfmjgWKKvpaSTWEOgGksZ7D1SmQrgg5MpuOXmhgDGsBOKOA4L-1pheIuvY_VWKQcMp-j98qrmlpLIzglid_VFn9fG33AbVjFkuwC1VlKpkQAiy2-CANMj5B-OKNuZnm6VtrDsydsei2XsGSlCXYPZ87l4upG2aHU1DwGfkn3jPWZvzOtabnhOT5zB1QftgwAT3uLA-RILMyjTm0YeujYJH02RkxEGOEvodrQODbz-3QDT6oszxUzQlECIdmy0kR0bbnjix5NI3xvqs7RIDPKBwMBmSEFgRMVMknz7jmDL0g6i2OYqVPJakI9lf0AR5xuOApcEqD6F1WPN6lrF7zOSz2g-8lGkxDd--IgEmbLmo82rJVFZ0nVo7zYQtYjNp9v8oyFZsIypYxYDICNipMoPOd1jAKZVl_efqDQovg-S5HHr3GCrGgYJ05LAz8jg0top0S_wWdgSg5B_rt07FYlFizcaZWt5hIDkipChFBSiT6M1BhNED3BQRStTX95CUuvFERNtuzVcPwCImmlY6nm6kTsvXnRwPqE4gcGd4RemPEbBdmqds=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_0AWpWv0xI1UpMKv8dGV2IMCgYDYuHDNssEy9tyEuBMcmrpV_8CwTUAcVwRY6HLaTjISMuvPN9GkI4gShnSY_bcWuVND-lLvxZJ7OYsoMwuaF4RwFxjVMxvtReVh-2OoO28Ui-hWhgV4E7OLUoVDaVf4iYMgKO5ka0se-J203Eq6pI0aWNccpmqtrwZXW2JoLV-BWDZmkof33syHmt_fhIAyZfZzARHLUfy1zqfPOaXz6ivMp3LZ4iWEo_5Ggax5gZFNCUd4n44dJ_FzI6rtmncIPk_lijWW-zGCghSXK60_y5fGo0NeoNyLWocju0PlSoTMnbc80Xnh6W49q2ABOpLozSG8UhvtQ6i-oVBX288-_vLmdSexvb0fZd86Bghm8wcb7zmA-EIlAewcQvgy_hmS3fKbwug7e1J8oUvmsKRVfC40Mf5xOH43iaoBYPEpNjkSDtuKszhkIIpQgKEoFBhaHEWxgsNJSa-s-lrKkpEU1W8Y291g7ZWyqYq2FBhVpvdQ8ye14931V29sxLdV5ryf7L5z5pqM1RQEeLs_WrXQfZaP_Pk8I70rMUlPUeMN59Af2P3Cf2HM_wkvxXkeZqrzplB-w_YxipqELZtG3oMsbkEG_X_M8qH-7OhwLAQD6i71WoQS9pn5fEdb_J8vcmv6yxOnw24FrIa8eeYXGH-FXwPXOs8Tge6wyy88pyE6vlHIuOz8_PwyTkYv0QeGKiv-GwFoiXHuBX0NxdtxauiPWJOusmKT5RDekdfmjgWKKvpaSTWEOgGksZ7D1SmQrgg5MpuOXmhgDGsBOKOA4L-1pheIuvY_VWKQcMp-j98qrmlpLIzglid_VFn9fG33AbVjFkuwC1VlKpkQAiy2-CANMj5B-OKNuZnm6VtrDsydsei2XsGSlCXYPZ87l4upG2aHU1DwGfkn3jPWZvzOtabnhOT5zB1QftgwAT3uLA-RILMyjTm0YeujYJH02RkxEGOEvodrQODbz-3QDT6oszxUzQlECIdmy0kR0bbnjix5NI3xvqs7RIDPKBwMBmSEFgRMVMknz7jmDL0g6i2OYqVPJakI9lf0AR5xuOApcEqD6F1WPN6lrF7zOSz2g-8lGkxDd--IgEmbLmo82rJVFZ0nVo7zYQtYjNp9v8oyFZsIypYxYDICNipMoPOd1jAKZVl_efqDQovg-S5HHr3GCrGgYJ05LAz8jg0top0S_wWdgSg5B_rt07FYlFizcaZWt5hIDkipChFBSiT6M1BhNED3BQRStTX95CUuvFERNtuzVcPwCImmlY6nm6kTsvXnRwPqE4gcGd4RemPEbBdmqds=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_0AWpWv0xI1UpMKv8dGV2IMCgYDYuHDNssEy9tyEuBMcmrpV_8CwTUAcVwRY6HLaTjISMuvPN9GkI4gShnSY_bcWuVND-lLvxZJ7OYsoMwuaF4RwFxjVMxvtReVh-2OoO28Ui-hWhgV4E7OLUoVDaVf4iYMgKO5ka0se-J203Eq6pI0aWNccpmqtrwZXW2JoLV-BWDZmkof33syHmt_fhIAyZfZzARHLUfy1zqfPOaXz6ivMp3LZ4iWEo_5Ggax5gZFNCUd4n44dJ_FzI6rtmncIPk_lijWW-zGCghSXK60_y5fGo0NeoNyLWocju0PlSoTMnbc80Xnh6W49q2ABOpLozSG8UhvtQ6i-oVBX288-_vLmdSexvb0fZd86Bghm8wcb7zmA-EIlAewcQvgy_hmS3fKbwug7e1J8oUvmsKRVfC40Mf5xOH43iaoBYPEpNjkSDtuKszhkIIpQgKEoFBhaHEWxgsNJSa-s-lrKkpEU1W8Y291g7ZWyqYq2FBhVpvdQ8ye14931V29sxLdV5ryf7L5z5pqM1RQEeLs_WrXQfZaP_Pk8I70rMUlPUeMN59Af2P3Cf2HM_wkvxXkeZqrzplB-w_YxipqELZtG3oMsbkEG_X_M8qH-7OhwLAQD6i71WoQS9pn5fEdb_J8vcmv6yxOnw24FrIa8eeYXGH-FXwPXOs8Tge6wyy88pyE6vlHIuOz8_PwyTkYv0QeGKiv-GwFoiXHuBX0NxdtxauiPWJOusmKT5RDekdfmjgWKKvpaSTWEOgGksZ7D1SmQrgg5MpuOXmhgDGsBOKOA4L-1pheIuvY_VWKQcMp-j98qrmlpLIzglid_VFn9fG33AbVjFkuwC1VlKpkQAiy2-CANMj5B-OKNuZnm6VtrDsydsei2XsGSlCXYPZ87l4upG2aHU1DwGfkn3jPWZvzOtabnhOT5zB1QftgwAT3uLA-RILMyjTm0YeujYJH02RkxEGOEvodrQODbz-3QDT6oszxUzQlECIdmy0kR0bbnjix5NI3xvqs7RIDPKBwMBmSEFgRMVMknz7jmDL0g6i2OYqVPJakI9lf0AR5xuOApcEqD6F1WPN6lrF7zOSz2g-8lGkxDd--IgEmbLmo82rJVFZ0nVo7zYQtYjNp9v8oyFZsIypYxYDICNipMoPOd1jAKZVl_efqDQovg-S5HHr3GCrGgYJ05LAz8jg0top0S_wWdgSg5B_rt07FYlFizcaZWt5hIDkipChFBSiT6M1BhNED3BQRStTX95CUuvFERNtuzVcPwCImmlY6nm6kTsvXnRwPqE4gcGd4RemPEbBdmqds=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1157.6,
            "totalPrice": 907
          },
          {
            "allPassengerTotalPrice": 911,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBGh1qEYyRecsgMtSg8YMz9zwYzNjHr7GxjzwMo1qBER0JpxVbQnKbbbiileQPIhLo5JWL5td8WwuJpxQoETEJw81ZY0G7qIT8XIsU2onpfFJ1lIO6MGlMUhMwAPwlK7Fo2FNOHdlz1TANSP_i4t_N5xKq5xaROYEW1vxJdYeGRaoT_9lUkFzdvs-miztezsfaUbqgeL_Feth6XxO4R_aebhYlwOMN2QUrZsK0YZF-QC-G08AMmz6_VFW0UeTzACzn4k49Cjs81-37DfcEdNdPj1i_P8KIvNuJ7dU0F8kuDyzxia3VJJODR4e6aaMWUH21s5JBjL3GL8ObklIMqm6fEHHJ-9ZL9E-D8g1tm1WCkz0yZFccA7JpkUuE35B8MYup2Y3CktUOdurJhq2dm_obiJlOlTdBIKZH1wvysDCQRLmEDdtEh0UhQgRsuZ1tsfRErw0r6TIAsDs2SI_k_dFLL3cNWmJGPG8xX_XbFHvUjF-kpwnUJa11goY_1dM9xQ96Ioktw3viYo1pKbqhe9l1azV-YUsePCm6e5N5j7J7koyTTTwu9XpISz76Gg1Dp9YlgypOsmIP_8KbU8XtK94WucGQpaLMlWPfY3Yah95nWboWo-ChnSeTHI7eTnw4Lz814hNEoALZZXZm68GX5ZoChhQEq14PZtJDqBhy_wmFCUmiNm5hMPAa3ndtEt-G3_Rz6s_Ns-vRSca-CjRk7Vn_GAT2Y8gj4CGvMHpr5SU5_Bjtj5w0nUfdS8IbpXgAHLtnwmUZxsTjufHN505gJtxhRwt1bOKurZxFtrovsmFxC7wBD3MuJ28ZtUTh3tKSmKOUZ_85KOdQgeF75yPIpno039EzfEwUyylT0aBJdyeZ5vMF68lRBq55Hao0Pa-wXfmWsTGBjNesUVgoIKL7LM_6jNbrJI5zMWmv1BWDPcu5tFj7RYjIxzf3QJLZyRWxEBVh9HuMd7NxoCWrJ317-KIC2OauC37NjJPTHKhcwV23j5Ad7mjiDpbBce4TK3XAT5mbcHBdxVDNf0H-ouORZ8rviC5c0YlLV_xzXXZ14sDr54rbHdsbodvaXwdg8biJViB3AdlePXtS5oG-oD4SXJjg4RVQUK_2ITTzsUoLxY_I21edAqRWl8teGixuMlF-ppZJsKeTfhL22RM_OAU09iazCAcio2r6rFYpf4i3I5TuXUuaEcbDzvU4C6PvO_yhXHUUWpsEH6qTPgLJL-jS8gRRLgcLWnvscTq9VHsQ3juD8N28W8FgPwhGtYFYUVaBRAfQTM0TjMujw4TvXVFDC7nabr_9MWZNArHqyNwbn6ZT4-hXBg7cHX5F_MfZp0vtOD_",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBGh1qEYyRecsgMtSg8YMz9zwYzNjHr7GxjzwMo1qBER0JpxVbQnKbbbiileQPIhLo5JWL5td8WwuJpxQoETEJw81ZY0G7qIT8XIsU2onpfFJ1lIO6MGlMUhMwAPwlK7Fo2FNOHdlz1TANSP_i4t_N5xKq5xaROYEW1vxJdYeGRaoT_9lUkFzdvs-miztezsfaUbqgeL_Feth6XxO4R_aebhYlwOMN2QUrZsK0YZF-QC-G08AMmz6_VFW0UeTzACzn4k49Cjs81-37DfcEdNdPj1i_P8KIvNuJ7dU0F8kuDyzxia3VJJODR4e6aaMWUH21s5JBjL3GL8ObklIMqm6fEHHJ-9ZL9E-D8g1tm1WCkz0yZFccA7JpkUuE35B8MYup2Y3CktUOdurJhq2dm_obiJlOlTdBIKZH1wvysDCQRLmEDdtEh0UhQgRsuZ1tsfRErw0r6TIAsDs2SI_k_dFLL3cNWmJGPG8xX_XbFHvUjF-kpwnUJa11goY_1dM9xQ96Ioktw3viYo1pKbqhe9l1azV-YUsePCm6e5N5j7J7koyTTTwu9XpISz76Gg1Dp9YlgypOsmIP_8KbU8XtK94WucGQpaLMlWPfY3Yah95nWboWo-ChnSeTHI7eTnw4Lz814hNEoALZZXZm68GX5ZoChhQEq14PZtJDqBhy_wmFCUmiNm5hMPAa3ndtEt-G3_Rz6s_Ns-vRSca-CjRk7Vn_GAT2Y8gj4CGvMHpr5SU5_Bjtj5w0nUfdS8IbpXgAHLtnwmUZxsTjufHN505gJtxhRwt1bOKurZxFtrovsmFxC7wBD3MuJ28ZtUTh3tKSmKOUZ_85KOdQgeF75yPIpno039EzfEwUyylT0aBJdyeZ5vMF68lRBq55Hao0Pa-wXfmWsTGBjNesUVgoIKL7LM_6jNbrJI5zMWmv1BWDPcu5tFj7RYjIxzf3QJLZyRWxEBVh9HuMd7NxoCWrJ317-KIC2OauC37NjJPTHKhcwV23j5Ad7mjiDpbBce4TK3XAT5mbcHBdxVDNf0H-ouORZ8rviC5c0YlLV_xzXXZ14sDr54rbHdsbodvaXwdg8biJViB3AdlePXtS5oG-oD4SXJjg4RVQUK_2ITTzsUoLxY_I21edAqRWl8teGixuMlF-ppZJsKeTfhL22RM_OAU09iazCAcio2r6rFYpf4i3I5TuXUuaEcbDzvU4C6PvO_yhXHUUWpsEH6qTPgLJL-jS8gRRLgcLWnvscTq9VHsQ3juD8N28W8FgPwhGtYFYUVaBRAfQTM0TjMujw4TvXVFDC7nabr_9MWZNArHqyNwbn6ZT4-hXBg7cHX5F_MfZp0vtOD_",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBGh1qEYyRecsgMtSg8YMz9zwYzNjHr7GxjzwMo1qBER0JpxVbQnKbbbiileQPIhLo5JWL5td8WwuJpxQoETEJw81ZY0G7qIT8XIsU2onpfFJ1lIO6MGlMUhMwAPwlK7Fo2FNOHdlz1TANSP_i4t_N5xKq5xaROYEW1vxJdYeGRaoT_9lUkFzdvs-miztezsfaUbqgeL_Feth6XxO4R_aebhYlwOMN2QUrZsK0YZF-QC-G08AMmz6_VFW0UeTzACzn4k49Cjs81-37DfcEdNdPj1i_P8KIvNuJ7dU0F8kuDyzxia3VJJODR4e6aaMWUH21s5JBjL3GL8ObklIMqm6fEHHJ-9ZL9E-D8g1tm1WCkz0yZFccA7JpkUuE35B8MYup2Y3CktUOdurJhq2dm_obiJlOlTdBIKZH1wvysDCQRLmEDdtEh0UhQgRsuZ1tsfRErw0r6TIAsDs2SI_k_dFLL3cNWmJGPG8xX_XbFHvUjF-kpwnUJa11goY_1dM9xQ96Ioktw3viYo1pKbqhe9l1azV-YUsePCm6e5N5j7J7koyTTTwu9XpISz76Gg1Dp9YlgypOsmIP_8KbU8XtK94WucGQpaLMlWPfY3Yah95nWboWo-ChnSeTHI7eTnw4Lz814hNEoALZZXZm68GX5ZoChhQEq14PZtJDqBhy_wmFCUmiNm5hMPAa3ndtEt-G3_Rz6s_Ns-vRSca-CjRk7Vn_GAT2Y8gj4CGvMHpr5SU5_Bjtj5w0nUfdS8IbpXgAHLtnwmUZxsTjufHN505gJtxhRwt1bOKurZxFtrovsmFxC7wBD3MuJ28ZtUTh3tKSmKOUZ_85KOdQgeF75yPIpno039EzfEwUyylT0aBJdyeZ5vMF68lRBq55Hao0Pa-wXfmWsTGBjNesUVgoIKL7LM_6jNbrJI5zMWmv1BWDPcu5tFj7RYjIxzf3QJLZyRWxEBVh9HuMd7NxoCWrJ317-KIC2OauC37NjJPTHKhcwV23j5Ad7mjiDpbBce4TK3XAT5mbcHBdxVDNf0H-ouORZ8rviC5c0YlLV_xzXXZ14sDr54rbHdsbodvaXwdg8biJViB3AdlePXtS5oG-oD4SXJjg4RVQUK_2ITTzsUoLxY_I21edAqRWl8teGixuMlF-ppZJsKeTfhL22RM_OAU09iazCAcio2r6rFYpf4i3I5TuXUuaEcbDzvU4C6PvO_yhXHUUWpsEH6qTPgLJL-jS8gRRLgcLWnvscTq9VHsQ3juD8N28W8FgPwhGtYFYUVaBRAfQTM0TjMujw4TvXVFDC7nabr_9MWZNArHqyNwbn6ZT4-hXBg7cHX5F_MfZp0vtOD_",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1159.37,
            "totalPrice": 911
          },
          {
            "allPassengerTotalPrice": 911,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1XIIyFvzI5I0Kk4y0fNE3TblcyPsxOGps6F02HOijYuPVXHhZPMbDokJH2JHs_RrdNh_gUjDUHQbqhGfxPfkVEQwG3x4T3BXmabdWOIfgKLTFbq7GYyFAuZNVFS3lC8VzjTs6OvzfJcLa4x85VOlGnj0bLGQUx-vjdi7NtVxZlXzl8tJa1x1wiKHeYjYAjOLyhz6NAXiM_K5Qpj25fTVbbfZ03X2DYU7TjFBVgkbev0NevSrUPBxOBSYc9qYPNdxzZruwlbIP9UdihfrtwYBRX6dzS8BUe4Z83b_16TfZIlpsMVeRBY-y7QsU-Gf5xE6jfuE9ZvZFpPHAxEW7bBYCz8iVQLgWAfEndj-Plsh0ibeT2YSVTL0uQWiD98IiPR2UO0Ijv90g_U3WixiGL0Y-ZoaGhu6hNxLLSaEpbh1R_KBZeG52ZkTn4Yf1kKK9xgb4qX0iWZhjwsszw0C1HEIz2PWA8L8gRMepN8J5hJAA5CoKsPSYgzCjXgy4FL8UqPXcQd6qmHSFhuLsLQ7zpPxntShGqsP1gSZU9vwYjArqbhr32ve3mS2d9M6-qkiFaIXKQlJouDPu-N2oVmssEkrLbotO0v3DHbdZfvcqLitATNv0sbFGuqPkUawEc5iA-RbL7vUCX0xvB8hElDoGIZsvug_yyo5UY3k37TZpVm71eP_IvU3k3iStZbzurEuoLWVl5m0g9DWsljF6NlPIyJY33KlUXem5q0vtuf1UHf9UbTWfU2wM7yGvAWas9m-XuOhEdUkzAgK9xCdfMfI7rG3q5qtqP4VoHEeg-G2dinj2JaijSuvB8oWE6N5Yhp286NY05wJKxkn2SbQnh_oZPUfuIV9cBXkazGJhNv6hgqjSq2T2tb5rsUh6fqLI55NVb8SFzCUdaraDjYJk9fosFgFRApjpSUS1AyvR41GcIHRNK7ezrrPZACYHKRZfGiNmCKIZUQ9IhGwXeXc1JPrAj0Bbg4Ld3dPabxRsGIOmxEmIlMoj76qqBh8Fd3Lp5CzDc29wyKW3Ni-2Ws5rgvLMz2bbmljf_YRXOBVOUtegPuVG9qn4sr3eiE8mURpegQIWsotoJxsM0p00JLoV_eTTghMJSEzGOY8DFO5i0DGMSzWVS0dgnf0NevPdn5znfWK0frpg3A8hxRtnDUDQksVmfi9el6a5Jj1nP7oC2dJK9A_OKaJTFdhNHN1t_OVUuQZj4wFB2JcWlaza9MQRcquR2L56My_yMwar0Kxw85eD_7IOH7CK7CENznUUPvOWBXR31FTG7LTkNf3Kscuy09u81gzwvYwPM9mYs7v2ON35mUJVBpzjf7TkXPGZRFkezGTW35u",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1XIIyFvzI5I0Kk4y0fNE3TblcyPsxOGps6F02HOijYuPVXHhZPMbDokJH2JHs_RrdNh_gUjDUHQbqhGfxPfkVEQwG3x4T3BXmabdWOIfgKLTFbq7GYyFAuZNVFS3lC8VzjTs6OvzfJcLa4x85VOlGnj0bLGQUx-vjdi7NtVxZlXzl8tJa1x1wiKHeYjYAjOLyhz6NAXiM_K5Qpj25fTVbbfZ03X2DYU7TjFBVgkbev0NevSrUPBxOBSYc9qYPNdxzZruwlbIP9UdihfrtwYBRX6dzS8BUe4Z83b_16TfZIlpsMVeRBY-y7QsU-Gf5xE6jfuE9ZvZFpPHAxEW7bBYCz8iVQLgWAfEndj-Plsh0ibeT2YSVTL0uQWiD98IiPR2UO0Ijv90g_U3WixiGL0Y-ZoaGhu6hNxLLSaEpbh1R_KBZeG52ZkTn4Yf1kKK9xgb4qX0iWZhjwsszw0C1HEIz2PWA8L8gRMepN8J5hJAA5CoKsPSYgzCjXgy4FL8UqPXcQd6qmHSFhuLsLQ7zpPxntShGqsP1gSZU9vwYjArqbhr32ve3mS2d9M6-qkiFaIXKQlJouDPu-N2oVmssEkrLbotO0v3DHbdZfvcqLitATNv0sbFGuqPkUawEc5iA-RbL7vUCX0xvB8hElDoGIZsvug_yyo5UY3k37TZpVm71eP_IvU3k3iStZbzurEuoLWVl5m0g9DWsljF6NlPIyJY33KlUXem5q0vtuf1UHf9UbTWfU2wM7yGvAWas9m-XuOhEdUkzAgK9xCdfMfI7rG3q5qtqP4VoHEeg-G2dinj2JaijSuvB8oWE6N5Yhp286NY05wJKxkn2SbQnh_oZPUfuIV9cBXkazGJhNv6hgqjSq2T2tb5rsUh6fqLI55NVb8SFzCUdaraDjYJk9fosFgFRApjpSUS1AyvR41GcIHRNK7ezrrPZACYHKRZfGiNmCKIZUQ9IhGwXeXc1JPrAj0Bbg4Ld3dPabxRsGIOmxEmIlMoj76qqBh8Fd3Lp5CzDc29wyKW3Ni-2Ws5rgvLMz2bbmljf_YRXOBVOUtegPuVG9qn4sr3eiE8mURpegQIWsotoJxsM0p00JLoV_eTTghMJSEzGOY8DFO5i0DGMSzWVS0dgnf0NevPdn5znfWK0frpg3A8hxRtnDUDQksVmfi9el6a5Jj1nP7oC2dJK9A_OKaJTFdhNHN1t_OVUuQZj4wFB2JcWlaza9MQRcquR2L56My_yMwar0Kxw85eD_7IOH7CK7CENznUUPvOWBXR31FTG7LTkNf3Kscuy09u81gzwvYwPM9mYs7v2ON35mUJVBpzjf7TkXPGZRFkezGTW35u",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1XIIyFvzI5I0Kk4y0fNE3TblcyPsxOGps6F02HOijYuPVXHhZPMbDokJH2JHs_RrdNh_gUjDUHQbqhGfxPfkVEQwG3x4T3BXmabdWOIfgKLTFbq7GYyFAuZNVFS3lC8VzjTs6OvzfJcLa4x85VOlGnj0bLGQUx-vjdi7NtVxZlXzl8tJa1x1wiKHeYjYAjOLyhz6NAXiM_K5Qpj25fTVbbfZ03X2DYU7TjFBVgkbev0NevSrUPBxOBSYc9qYPNdxzZruwlbIP9UdihfrtwYBRX6dzS8BUe4Z83b_16TfZIlpsMVeRBY-y7QsU-Gf5xE6jfuE9ZvZFpPHAxEW7bBYCz8iVQLgWAfEndj-Plsh0ibeT2YSVTL0uQWiD98IiPR2UO0Ijv90g_U3WixiGL0Y-ZoaGhu6hNxLLSaEpbh1R_KBZeG52ZkTn4Yf1kKK9xgb4qX0iWZhjwsszw0C1HEIz2PWA8L8gRMepN8J5hJAA5CoKsPSYgzCjXgy4FL8UqPXcQd6qmHSFhuLsLQ7zpPxntShGqsP1gSZU9vwYjArqbhr32ve3mS2d9M6-qkiFaIXKQlJouDPu-N2oVmssEkrLbotO0v3DHbdZfvcqLitATNv0sbFGuqPkUawEc5iA-RbL7vUCX0xvB8hElDoGIZsvug_yyo5UY3k37TZpVm71eP_IvU3k3iStZbzurEuoLWVl5m0g9DWsljF6NlPIyJY33KlUXem5q0vtuf1UHf9UbTWfU2wM7yGvAWas9m-XuOhEdUkzAgK9xCdfMfI7rG3q5qtqP4VoHEeg-G2dinj2JaijSuvB8oWE6N5Yhp286NY05wJKxkn2SbQnh_oZPUfuIV9cBXkazGJhNv6hgqjSq2T2tb5rsUh6fqLI55NVb8SFzCUdaraDjYJk9fosFgFRApjpSUS1AyvR41GcIHRNK7ezrrPZACYHKRZfGiNmCKIZUQ9IhGwXeXc1JPrAj0Bbg4Ld3dPabxRsGIOmxEmIlMoj76qqBh8Fd3Lp5CzDc29wyKW3Ni-2Ws5rgvLMz2bbmljf_YRXOBVOUtegPuVG9qn4sr3eiE8mURpegQIWsotoJxsM0p00JLoV_eTTghMJSEzGOY8DFO5i0DGMSzWVS0dgnf0NevPdn5znfWK0frpg3A8hxRtnDUDQksVmfi9el6a5Jj1nP7oC2dJK9A_OKaJTFdhNHN1t_OVUuQZj4wFB2JcWlaza9MQRcquR2L56My_yMwar0Kxw85eD_7IOH7CK7CENznUUPvOWBXR31FTG7LTkNf3Kscuy09u81gzwvYwPM9mYs7v2ON35mUJVBpzjf7TkXPGZRFkezGTW35u",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1160.04,
            "totalPrice": 911
          },
          {
            "allPassengerTotalPrice": 914,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f000084327e22_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfWdghlwIrXKPPZA8UqEFUBv6SZXsqVF4uUIaLua0PwV4f1baxcYP3W3mmXmFP3bkVyoNN3u7dqDJWkpGwHUxWyAMT2p_GMh06gykQ-zlbf8T77Eklo4xmcq0p8O2t6uYsJVrz_ELoC3luoEOrsd3UfOnUddqnyrbCCSbYOvyKajyWJHGfoPjQqBXzNcklhEG2jJM0ddRb3hepEezduyN4t9QWYhm7sw3UCoYzGTmJ2ynOhkjyjEFKgQR3PTFLki89vOyx66hicodnOB0WkqsAAk7_FddqrUiCioh5ojQ_o65ZXiy8YmFT3Y4DdBOmIqn1zObUULdN86kbVUmj-ewBnj-8r27T4LXhqnQKsOgKxh5WnoIQ5FqDcxJBcozfz0moJr7y4bHED_rhcrUToVLMfrvMM66rshV5iFLFMebp0_P84o3OE0QHOvJVn9aB5wuAO6ac63CnxgVBwTaHed12qXqm-PF7DmoXB-yP5GU4br6d0FoDNcJG9FmGSAntkVQxEHjquQO2w7hnDcdXrTsydOU08rmYdg02lcF9L36qhj7LcNO94TqpUOp1I3ZG4eU2Puq5x4ZQ9jK3ConeagUHRSjAJQYYjj-5Qtb7ZClQK_1Uk8zOm-UaDpOIyZMv7zwrtVJTqIduLbeJcxaAJ1GvgE9HCuSakrZQVXWXckozn5sQu5bXKJw9aYYbzjp_973bcSivqgVkCqLWXClBUfw54oMBwrm-2afZ8rsNAsMDY9XDm4yyVCkaFdb4QOeXVZ3fplx7IE_UF-MzzSetFLW8aDS-n-6ts2-Xs53fo5N6-hh9wQ9lV0YMsworQ3Old6pHTbSwjPFQ4BsHerX_eyFNj2k_iqJmEAnc-n4oLgQ0rhjnPOnlhDNRxQ8XKYpgm87cmx7Kz_oMvpkB7DN8-t5qzDZyfd0gOPiMJP8SNhX3fJKnjVqxee_TNwzGgLccfOOhbbPl2Gl-aqSzgTlQ8tkIi-YFfa08UB26WmdSeIMQDpORbhqNd9_HPS0jZV2-NaknhbZRLr_HgFDkdgZTYWu0XPbj5hGzBHo4no5UGGUB2TwMGQJ5OHoV94p8GGPislpEgv8mYWkS2N0Ohpl3F3pxUoyocwRtMG8F3j1kRlyANyhnA-Is5hRvUmswVtzqD6ZoHaERf3go6OaWrnW_yzGYltAS8foNiDn_BOO5aSaX8o_mzfDFpltvnjkGeEPFCSAwG1VxrxdQbCB--5922bioeAwOiowjb0uURhkI-afzslEWYx9YpPbe5XQktvFDf4lLqXs959HMYH4vwwWuJvsCOzN5Zv6BQm0-YPP6rRIZcNeRhxWfjCzrAhM_KjucwR1",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f000084327e22_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfWdghlwIrXKPPZA8UqEFUBv6SZXsqVF4uUIaLua0PwV4f1baxcYP3W3mmXmFP3bkVyoNN3u7dqDJWkpGwHUxWyAMT2p_GMh06gykQ-zlbf8T77Eklo4xmcq0p8O2t6uYsJVrz_ELoC3luoEOrsd3UfOnUddqnyrbCCSbYOvyKajyWJHGfoPjQqBXzNcklhEG2jJM0ddRb3hepEezduyN4t9QWYhm7sw3UCoYzGTmJ2ynOhkjyjEFKgQR3PTFLki89vOyx66hicodnOB0WkqsAAk7_FddqrUiCioh5ojQ_o65ZXiy8YmFT3Y4DdBOmIqn1zObUULdN86kbVUmj-ewBnj-8r27T4LXhqnQKsOgKxh5WnoIQ5FqDcxJBcozfz0moJr7y4bHED_rhcrUToVLMfrvMM66rshV5iFLFMebp0_P84o3OE0QHOvJVn9aB5wuAO6ac63CnxgVBwTaHed12qXqm-PF7DmoXB-yP5GU4br6d0FoDNcJG9FmGSAntkVQxEHjquQO2w7hnDcdXrTsydOU08rmYdg02lcF9L36qhj7LcNO94TqpUOp1I3ZG4eU2Puq5x4ZQ9jK3ConeagUHRSjAJQYYjj-5Qtb7ZClQK_1Uk8zOm-UaDpOIyZMv7zwrtVJTqIduLbeJcxaAJ1GvgE9HCuSakrZQVXWXckozn5sQu5bXKJw9aYYbzjp_973bcSivqgVkCqLWXClBUfw54oMBwrm-2afZ8rsNAsMDY9XDm4yyVCkaFdb4QOeXVZ3fplx7IE_UF-MzzSetFLW8aDS-n-6ts2-Xs53fo5N6-hh9wQ9lV0YMsworQ3Old6pHTbSwjPFQ4BsHerX_eyFNj2k_iqJmEAnc-n4oLgQ0rhjnPOnlhDNRxQ8XKYpgm87cmx7Kz_oMvpkB7DN8-t5qzDZyfd0gOPiMJP8SNhX3fJKnjVqxee_TNwzGgLccfOOhbbPl2Gl-aqSzgTlQ8tkIi-YFfa08UB26WmdSeIMQDpORbhqNd9_HPS0jZV2-NaknhbZRLr_HgFDkdgZTYWu0XPbj5hGzBHo4no5UGGUB2TwMGQJ5OHoV94p8GGPislpEgv8mYWkS2N0Ohpl3F3pxUoyocwRtMG8F3j1kRlyANyhnA-Is5hRvUmswVtzqD6ZoHaERf3go6OaWrnW_yzGYltAS8foNiDn_BOO5aSaX8o_mzfDFpltvnjkGeEPFCSAwG1VxrxdQbCB--5922bioeAwOiowjb0uURhkI-afzslEWYx9YpPbe5XQktvFDf4lLqXs959HMYH4vwwWuJvsCOzN5Zv6BQm0-YPP6rRIZcNeRhxWfjCzrAhM_KjucwR1",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f000084327e22_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfWdghlwIrXKPPZA8UqEFUBv6SZXsqVF4uUIaLua0PwV4f1baxcYP3W3mmXmFP3bkVyoNN3u7dqDJWkpGwHUxWyAMT2p_GMh06gykQ-zlbf8T77Eklo4xmcq0p8O2t6uYsJVrz_ELoC3luoEOrsd3UfOnUddqnyrbCCSbYOvyKajyWJHGfoPjQqBXzNcklhEG2jJM0ddRb3hepEezduyN4t9QWYhm7sw3UCoYzGTmJ2ynOhkjyjEFKgQR3PTFLki89vOyx66hicodnOB0WkqsAAk7_FddqrUiCioh5ojQ_o65ZXiy8YmFT3Y4DdBOmIqn1zObUULdN86kbVUmj-ewBnj-8r27T4LXhqnQKsOgKxh5WnoIQ5FqDcxJBcozfz0moJr7y4bHED_rhcrUToVLMfrvMM66rshV5iFLFMebp0_P84o3OE0QHOvJVn9aB5wuAO6ac63CnxgVBwTaHed12qXqm-PF7DmoXB-yP5GU4br6d0FoDNcJG9FmGSAntkVQxEHjquQO2w7hnDcdXrTsydOU08rmYdg02lcF9L36qhj7LcNO94TqpUOp1I3ZG4eU2Puq5x4ZQ9jK3ConeagUHRSjAJQYYjj-5Qtb7ZClQK_1Uk8zOm-UaDpOIyZMv7zwrtVJTqIduLbeJcxaAJ1GvgE9HCuSakrZQVXWXckozn5sQu5bXKJw9aYYbzjp_973bcSivqgVkCqLWXClBUfw54oMBwrm-2afZ8rsNAsMDY9XDm4yyVCkaFdb4QOeXVZ3fplx7IE_UF-MzzSetFLW8aDS-n-6ts2-Xs53fo5N6-hh9wQ9lV0YMsworQ3Old6pHTbSwjPFQ4BsHerX_eyFNj2k_iqJmEAnc-n4oLgQ0rhjnPOnlhDNRxQ8XKYpgm87cmx7Kz_oMvpkB7DN8-t5qzDZyfd0gOPiMJP8SNhX3fJKnjVqxee_TNwzGgLccfOOhbbPl2Gl-aqSzgTlQ8tkIi-YFfa08UB26WmdSeIMQDpORbhqNd9_HPS0jZV2-NaknhbZRLr_HgFDkdgZTYWu0XPbj5hGzBHo4no5UGGUB2TwMGQJ5OHoV94p8GGPislpEgv8mYWkS2N0Ohpl3F3pxUoyocwRtMG8F3j1kRlyANyhnA-Is5hRvUmswVtzqD6ZoHaERf3go6OaWrnW_yzGYltAS8foNiDn_BOO5aSaX8o_mzfDFpltvnjkGeEPFCSAwG1VxrxdQbCB--5922bioeAwOiowjb0uURhkI-afzslEWYx9YpPbe5XQktvFDf4lLqXs959HMYH4vwwWuJvsCOzN5Zv6BQm0-YPP6rRIZcNeRhxWfjCzrAhM_KjucwR1",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1162.53,
            "totalPrice": 914
          },
          {
            "allPassengerTotalPrice": 916,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00006a3c1f0e_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGB3ZNrZzu8ZfU3sADrmTd8ePDcWyAzsGLiel6yJ29qeZQ0LptP9hxmInX1ZoiMrCT0qeNQ_hRHz0sUXQEmQ_BAX24dTbuUgP9C12wtaQ7uomYX40RVTo1AivXMh-s87EFIxMv_vaQCwmNwL46Wad0QheEAq67R2K995Y6ukCe4LgwBL-hmsdvYoCtLqaGc_6VuLgopvddOidUbQG-8D3bzgyyvdHsjlTWli7wptzzgMJU5jA2Q-eHHI3ObPxDZrRRVWtejlZmXpqwxHIKVoeVYcbdngmp7jPlTrYZnQLh4bxu01lk6gd92_BXeLPkZ1vWn_utIoQ5_4zb3ZhsfQ03yiIMoYzNOjcC317uGciFnZy9k7CfYOjY3X9w_1xAV8HS9BQr-AIGGJPTsvw9iiJC66pXjpw9P8-cdjNWRqSUxEIE1D9si5lO5MGIAyslukGbSl2R6rOSuqpL8WHxqzIRNUIPTlYFkJJ68T0QyQpMJIowEZwb7hHb1qWaKLGhriFiPOm5Gt7l0jJncWcKpXP-CMyf_zL1cbFmG6ZmUls1KkcioPEQWTutzMhB7hj1CP_Ki7SwUvCyfq_8LBkrY3IGWfyEA1PzUfrvl8fPmWwCL8sPtXCeZg4j2etTL2GYYxwilKxWSOHb95Cqmw-MEJgZYLRaB9a5INpDZ_sYZ8qOXmwr8OBSVUd4pNkq4zM0KG-nMzmzNcwzZeC96LeWgokMakZW0nbYCSYdrAztg9ReJ56Ic8MR-SfKj2wBX6rSR-UwWsQzRblbv2FIerJ97erFWUbhE-CAdi2sztuHaQAxxQUsQsEkw4l-KsKMcCKKAUO0nk5BYjNI5vwRf_JxQPPqHRCSoiEDMNNgUqhJQ-QSTGEXVn4-wpI79UA9ioQcntVgEwIpW85zdGyjOtxLKxfyorxh7BiYz73iVqat-QEbtsTsR7C-aKuMc-_4g21DevDSTSYSvi0p3BnTeje1gP3WBGkCRag6W-MQjN2Iq7k860utH7Px4CczUCkw-npIjuX_h7BvUsyVsh4nOaUMswgyCoJ5hWjrnOGjDS5QZ-zJ4D0RtYMzRqeQLK60Ti69aKomoTFtZ3K8Rthx2HS78HCvC_cclxXeKqT0wiQiPoLau7_eO4pXnCLqkXLWldJGW3hSyi0ZTLmXGffFGUB47A1YQA0-CahGQOFM64HFBq6MDzAE3CAqQvVRbws4oLtfBRGmLKU2ETqhGkTLhGpbXzE8b9GsNuOtBa_56w9ZR27Kpfts5vV-F6wCuViOBkmJNCkWHAO4Jna-OhIcpUYThB5quCNxW41z_K99QZ-rCXTNGhHNBoOfqLAY79NYB3j_EXY",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00006a3c1f0e_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGB3ZNrZzu8ZfU3sADrmTd8ePDcWyAzsGLiel6yJ29qeZQ0LptP9hxmInX1ZoiMrCT0qeNQ_hRHz0sUXQEmQ_BAX24dTbuUgP9C12wtaQ7uomYX40RVTo1AivXMh-s87EFIxMv_vaQCwmNwL46Wad0QheEAq67R2K995Y6ukCe4LgwBL-hmsdvYoCtLqaGc_6VuLgopvddOidUbQG-8D3bzgyyvdHsjlTWli7wptzzgMJU5jA2Q-eHHI3ObPxDZrRRVWtejlZmXpqwxHIKVoeVYcbdngmp7jPlTrYZnQLh4bxu01lk6gd92_BXeLPkZ1vWn_utIoQ5_4zb3ZhsfQ03yiIMoYzNOjcC317uGciFnZy9k7CfYOjY3X9w_1xAV8HS9BQr-AIGGJPTsvw9iiJC66pXjpw9P8-cdjNWRqSUxEIE1D9si5lO5MGIAyslukGbSl2R6rOSuqpL8WHxqzIRNUIPTlYFkJJ68T0QyQpMJIowEZwb7hHb1qWaKLGhriFiPOm5Gt7l0jJncWcKpXP-CMyf_zL1cbFmG6ZmUls1KkcioPEQWTutzMhB7hj1CP_Ki7SwUvCyfq_8LBkrY3IGWfyEA1PzUfrvl8fPmWwCL8sPtXCeZg4j2etTL2GYYxwilKxWSOHb95Cqmw-MEJgZYLRaB9a5INpDZ_sYZ8qOXmwr8OBSVUd4pNkq4zM0KG-nMzmzNcwzZeC96LeWgokMakZW0nbYCSYdrAztg9ReJ56Ic8MR-SfKj2wBX6rSR-UwWsQzRblbv2FIerJ97erFWUbhE-CAdi2sztuHaQAxxQUsQsEkw4l-KsKMcCKKAUO0nk5BYjNI5vwRf_JxQPPqHRCSoiEDMNNgUqhJQ-QSTGEXVn4-wpI79UA9ioQcntVgEwIpW85zdGyjOtxLKxfyorxh7BiYz73iVqat-QEbtsTsR7C-aKuMc-_4g21DevDSTSYSvi0p3BnTeje1gP3WBGkCRag6W-MQjN2Iq7k860utH7Px4CczUCkw-npIjuX_h7BvUsyVsh4nOaUMswgyCoJ5hWjrnOGjDS5QZ-zJ4D0RtYMzRqeQLK60Ti69aKomoTFtZ3K8Rthx2HS78HCvC_cclxXeKqT0wiQiPoLau7_eO4pXnCLqkXLWldJGW3hSyi0ZTLmXGffFGUB47A1YQA0-CahGQOFM64HFBq6MDzAE3CAqQvVRbws4oLtfBRGmLKU2ETqhGkTLhGpbXzE8b9GsNuOtBa_56w9ZR27Kpfts5vV-F6wCuViOBkmJNCkWHAO4Jna-OhIcpUYThB5quCNxW41z_K99QZ-rCXTNGhHNBoOfqLAY79NYB3j_EXY",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00006a3c1f0e_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGB3ZNrZzu8ZfU3sADrmTd8ePDcWyAzsGLiel6yJ29qeZQ0LptP9hxmInX1ZoiMrCT0qeNQ_hRHz0sUXQEmQ_BAX24dTbuUgP9C12wtaQ7uomYX40RVTo1AivXMh-s87EFIxMv_vaQCwmNwL46Wad0QheEAq67R2K995Y6ukCe4LgwBL-hmsdvYoCtLqaGc_6VuLgopvddOidUbQG-8D3bzgyyvdHsjlTWli7wptzzgMJU5jA2Q-eHHI3ObPxDZrRRVWtejlZmXpqwxHIKVoeVYcbdngmp7jPlTrYZnQLh4bxu01lk6gd92_BXeLPkZ1vWn_utIoQ5_4zb3ZhsfQ03yiIMoYzNOjcC317uGciFnZy9k7CfYOjY3X9w_1xAV8HS9BQr-AIGGJPTsvw9iiJC66pXjpw9P8-cdjNWRqSUxEIE1D9si5lO5MGIAyslukGbSl2R6rOSuqpL8WHxqzIRNUIPTlYFkJJ68T0QyQpMJIowEZwb7hHb1qWaKLGhriFiPOm5Gt7l0jJncWcKpXP-CMyf_zL1cbFmG6ZmUls1KkcioPEQWTutzMhB7hj1CP_Ki7SwUvCyfq_8LBkrY3IGWfyEA1PzUfrvl8fPmWwCL8sPtXCeZg4j2etTL2GYYxwilKxWSOHb95Cqmw-MEJgZYLRaB9a5INpDZ_sYZ8qOXmwr8OBSVUd4pNkq4zM0KG-nMzmzNcwzZeC96LeWgokMakZW0nbYCSYdrAztg9ReJ56Ic8MR-SfKj2wBX6rSR-UwWsQzRblbv2FIerJ97erFWUbhE-CAdi2sztuHaQAxxQUsQsEkw4l-KsKMcCKKAUO0nk5BYjNI5vwRf_JxQPPqHRCSoiEDMNNgUqhJQ-QSTGEXVn4-wpI79UA9ioQcntVgEwIpW85zdGyjOtxLKxfyorxh7BiYz73iVqat-QEbtsTsR7C-aKuMc-_4g21DevDSTSYSvi0p3BnTeje1gP3WBGkCRag6W-MQjN2Iq7k860utH7Px4CczUCkw-npIjuX_h7BvUsyVsh4nOaUMswgyCoJ5hWjrnOGjDS5QZ-zJ4D0RtYMzRqeQLK60Ti69aKomoTFtZ3K8Rthx2HS78HCvC_cclxXeKqT0wiQiPoLau7_eO4pXnCLqkXLWldJGW3hSyi0ZTLmXGffFGUB47A1YQA0-CahGQOFM64HFBq6MDzAE3CAqQvVRbws4oLtfBRGmLKU2ETqhGkTLhGpbXzE8b9GsNuOtBa_56w9ZR27Kpfts5vV-F6wCuViOBkmJNCkWHAO4Jna-OhIcpUYThB5quCNxW41z_K99QZ-rCXTNGhHNBoOfqLAY79NYB3j_EXY",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1163.68,
            "totalPrice": 916
          },
          {
            "allPassengerTotalPrice": 918,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3BgL9pPc2OuNt0AwQDZaP-sHRupPTUdXz5e14nM53Xx2E1WU4okLKO-Z8vzRkeZCncp2EvoFPlTEbkS_3Ki_RrYUuTXZzm5E9G0Yg3974P9s8NQqNQCr5e5br-aA1GQchlNoJABOa0aK_5H7KUZ8whw-vVe6IDJ9TCWvX0seEavUcln7TC8nd6JN9AEyqzfRT65YLSVRag_3PSZpqdYLdlOwmliOubJWgR_jIGYBO2Ju7UbSdbON5atgqfMpc3jsyaja910PAr01Y1JwFJKLRUxLnu6xuRRDbTC3tfiKV3ITS-8Pk8KJnQ-QAhIhljIAd-44-OvHFoG9vJ0n3eVfEIffXX8F8du-xkTSZ3QOyQrphhZ0Tv9lZflpZEUI72O8gyaVp1_VlJXh4Q6gGPh-5d5bp2L8ZwpmfaDZcTECS3PTl50EY5AAnlI62-6zuubua3EPYKfZA273gH3MTT50dQEDBHDgiAObmhfnBtWwU0hDehvG5-BETgeq358KtYFB2sPKlKUhnaD02CM1RmKC2ZDmXMnrliacFJ4oks6n70NtjKBzICl9jUHF8pEuRiWt8KXvSHTQsUZx6eb_l0jIKetwAsGRQN68b4VkO_e8p96x2HCX-EFw7oplM68lHbinmPbL_3wKPqNpLBBU6uiDuJhkcBGUQIPnBEOT_L-IYV-5TGsEZyA7Glc70vTDn0wmE0Q0ZtyV_GfrQjoQMBjhR3lwkjg5Qyb8cyFthen84rD6D561hPscq_jBJBq2gT1w9fftdg3yF65oSZ1E_x44kJ_EA7Cs9rJo177z02fhiUyNiqe5QbbfWfQ7DQ7pEh9SJjbP7o_TIywfISe_-cg643UQOO0PCh36kitz3Ajx3ROgWSKNaOAD7OmwfwbhshMLpNUe5xFEG58Vxby7YyBmwHW8Rn21ReEQypNY9og_LQPL72Q2JzhsxPqxRLapZigfd-U1725N0qte5geTuSlA_GGnQ3skQyPiCbmFuHLEwCD6Qt-MTcFjUTjH8h-erI9ZgNl5uy9rin10xZT4gfJenmmZLuiXFIqImjjfy2F9hj2JcQ88Hr-v0O71NiPNQL1ryfe-kMIMnmKaDm6y0IRWUZVFqH8RSRvqI0od2on2s9bb9TvZRsXU4JS9TkvFBNKRskLCilUjllolkJlfPvTG794d3ChcXapVVXaSicPGpoThMX0w0BdnQTVu9acdgWVsvcR71PIixd7gX2l_iwcJVO4BLaorfs1TilVniwmAN8w=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3BgL9pPc2OuNt0AwQDZaP-sHRupPTUdXz5e14nM53Xx2E1WU4okLKO-Z8vzRkeZCncp2EvoFPlTEbkS_3Ki_RrYUuTXZzm5E9G0Yg3974P9s8NQqNQCr5e5br-aA1GQchlNoJABOa0aK_5H7KUZ8whw-vVe6IDJ9TCWvX0seEavUcln7TC8nd6JN9AEyqzfRT65YLSVRag_3PSZpqdYLdlOwmliOubJWgR_jIGYBO2Ju7UbSdbON5atgqfMpc3jsyaja910PAr01Y1JwFJKLRUxLnu6xuRRDbTC3tfiKV3ITS-8Pk8KJnQ-QAhIhljIAd-44-OvHFoG9vJ0n3eVfEIffXX8F8du-xkTSZ3QOyQrphhZ0Tv9lZflpZEUI72O8gyaVp1_VlJXh4Q6gGPh-5d5bp2L8ZwpmfaDZcTECS3PTl50EY5AAnlI62-6zuubua3EPYKfZA273gH3MTT50dQEDBHDgiAObmhfnBtWwU0hDehvG5-BETgeq358KtYFB2sPKlKUhnaD02CM1RmKC2ZDmXMnrliacFJ4oks6n70NtjKBzICl9jUHF8pEuRiWt8KXvSHTQsUZx6eb_l0jIKetwAsGRQN68b4VkO_e8p96x2HCX-EFw7oplM68lHbinmPbL_3wKPqNpLBBU6uiDuJhkcBGUQIPnBEOT_L-IYV-5TGsEZyA7Glc70vTDn0wmE0Q0ZtyV_GfrQjoQMBjhR3lwkjg5Qyb8cyFthen84rD6D561hPscq_jBJBq2gT1w9fftdg3yF65oSZ1E_x44kJ_EA7Cs9rJo177z02fhiUyNiqe5QbbfWfQ7DQ7pEh9SJjbP7o_TIywfISe_-cg643UQOO0PCh36kitz3Ajx3ROgWSKNaOAD7OmwfwbhshMLpNUe5xFEG58Vxby7YyBmwHW8Rn21ReEQypNY9og_LQPL72Q2JzhsxPqxRLapZigfd-U1725N0qte5geTuSlA_GGnQ3skQyPiCbmFuHLEwCD6Qt-MTcFjUTjH8h-erI9ZgNl5uy9rin10xZT4gfJenmmZLuiXFIqImjjfy2F9hj2JcQ88Hr-v0O71NiPNQL1ryfe-kMIMnmKaDm6y0IRWUZVFqH8RSRvqI0od2on2s9bb9TvZRsXU4JS9TkvFBNKRskLCilUjllolkJlfPvTG794d3ChcXapVVXaSicPGpoThMX0w0BdnQTVu9acdgWVsvcR71PIixd7gX2l_iwcJVO4BLaorfs1TilVniwmAN8w=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3BgL9pPc2OuNt0AwQDZaP-sHRupPTUdXz5e14nM53Xx2E1WU4okLKO-Z8vzRkeZCncp2EvoFPlTEbkS_3Ki_RrYUuTXZzm5E9G0Yg3974P9s8NQqNQCr5e5br-aA1GQchlNoJABOa0aK_5H7KUZ8whw-vVe6IDJ9TCWvX0seEavUcln7TC8nd6JN9AEyqzfRT65YLSVRag_3PSZpqdYLdlOwmliOubJWgR_jIGYBO2Ju7UbSdbON5atgqfMpc3jsyaja910PAr01Y1JwFJKLRUxLnu6xuRRDbTC3tfiKV3ITS-8Pk8KJnQ-QAhIhljIAd-44-OvHFoG9vJ0n3eVfEIffXX8F8du-xkTSZ3QOyQrphhZ0Tv9lZflpZEUI72O8gyaVp1_VlJXh4Q6gGPh-5d5bp2L8ZwpmfaDZcTECS3PTl50EY5AAnlI62-6zuubua3EPYKfZA273gH3MTT50dQEDBHDgiAObmhfnBtWwU0hDehvG5-BETgeq358KtYFB2sPKlKUhnaD02CM1RmKC2ZDmXMnrliacFJ4oks6n70NtjKBzICl9jUHF8pEuRiWt8KXvSHTQsUZx6eb_l0jIKetwAsGRQN68b4VkO_e8p96x2HCX-EFw7oplM68lHbinmPbL_3wKPqNpLBBU6uiDuJhkcBGUQIPnBEOT_L-IYV-5TGsEZyA7Glc70vTDn0wmE0Q0ZtyV_GfrQjoQMBjhR3lwkjg5Qyb8cyFthen84rD6D561hPscq_jBJBq2gT1w9fftdg3yF65oSZ1E_x44kJ_EA7Cs9rJo177z02fhiUyNiqe5QbbfWfQ7DQ7pEh9SJjbP7o_TIywfISe_-cg643UQOO0PCh36kitz3Ajx3ROgWSKNaOAD7OmwfwbhshMLpNUe5xFEG58Vxby7YyBmwHW8Rn21ReEQypNY9og_LQPL72Q2JzhsxPqxRLapZigfd-U1725N0qte5geTuSlA_GGnQ3skQyPiCbmFuHLEwCD6Qt-MTcFjUTjH8h-erI9ZgNl5uy9rin10xZT4gfJenmmZLuiXFIqImjjfy2F9hj2JcQ88Hr-v0O71NiPNQL1ryfe-kMIMnmKaDm6y0IRWUZVFqH8RSRvqI0od2on2s9bb9TvZRsXU4JS9TkvFBNKRskLCilUjllolkJlfPvTG794d3ChcXapVVXaSicPGpoThMX0w0BdnQTVu9acdgWVsvcR71PIixd7gX2l_iwcJVO4BLaorfs1TilVniwmAN8w=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1159.94,
            "totalPrice": 918
          },
          {
            "allPassengerTotalPrice": 920,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00002401a9db_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G12TvJH2o6UQ0CPdm5GUq4o0oNsQT0vlfaZ0prCRDegWwA13Lg0PNA0x3LXUsTlUpc_u5XJ6vG2ttOm1qW4U6NjCdqMQ4Pkh2J189Lt8ggNeb-jCm4nsg58KUUt1WlHYUyqgS5fqEiD7rgmyp83zdb6ebvlNj86YHVJ4vcxMYqrQCKC2yAITKn3lfOwXjegFcPFk5PLPb4sS3CtXwxUsDDE3VHsjiVeKIynxLtQ8TzlEfvSRoTULaou0EI3FzCQIHP9OmIwC7MEyCL0hPhnWBtjOS4T7Ckgw8or5ZpYb5XzRCTQXYBwAGixSxFA0LpfvAQA5RMDjcZwSH73jmenFMQp-nM4Lpz4RFgvT8gfIYpLQTE29uJ0CHrdiVhUy_p1xKBjh9q6feTg7RJoKWqkS0cEiSu7wKowUVtNZ1BNDCSCLsmwZbpH1FjMkoOH8cxyF7oIRz0he8qwXiO1k9FABGLl07ftRPr1SjLelGQBhCqD37Ky0twz8m1i5CsRiSigictGLniJt6zaCywwZaYf3-HyLm_ZBF9f0Bqf05byREs2AMlOWr1GvTGO2OLf_DqXito4Bbo5iZjTVwuoOakcfKsOffIIE-Kn03xma-8wl7XOhKtBJIvit5dTzUv4f0f7wYCKccMQv-LyWNItIvIJr8HuUBVHIXh_Hu3e6KwJpQXrioayK0HAxc9aob7iBk7cQWWkKZIWZyxy-IC2tRQ-UzjGTUanISoW_fZSAbU8Ir0oxmEBcRhk2q_q23Kp-SYKwa6qTHGh2qI6CD_MDXWo4uHuLoKAQKUvxDXL3aBEjV8nSmLuz2C3UZmYJS8auwLuuo5NBRG8xHIBJaFkf1BhwwctHATTjCyVNYLoY7mHIKBTr3YZWsr_p5KGEq8-pAqC-gMVvHsF1XMfDg7-kXWBu0Ovo2LqZ5kESqhPnmVOwcIeYS-BfaTQkdQG3tcOfc8DipqBz-3Mx3dichQG9HrmQ7hTlewz3fYJS-G8ZOtUdYtNWXHGMqFACO0UGH3MncFsZmg1pjIT_alinztODBRuyD_Jbpk8iiW_i6ZnLNhiMk1E0gT11uGSffxxeY01wM9Jz20kL_t9cCwMjI_Y1ifumC4eW1Gur-Jqbdh8tVmFXdmByoE6vVr1_pWFELhaNIGdEVlDSBtZUFEyUBuqjVZdw1Yzvkjh-VBW4urQKUr7azNGdcM-WW7UZZZuATSChG7revRZD6haADddPRBbsC9asfn19VCSPUJnRzjq6CmqDF97Nma5DoxA3lE26P6T3QW6vyViR6Gf4FTOmVPYYBEMLNp9Q6Z8exKSMlXuPAnr_LQisNA6EWu5uE8TeEq_OGePh07wEB0KtgB0E6I-3cYbdXkg==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00002401a9db_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G12TvJH2o6UQ0CPdm5GUq4o0oNsQT0vlfaZ0prCRDegWwA13Lg0PNA0x3LXUsTlUpc_u5XJ6vG2ttOm1qW4U6NjCdqMQ4Pkh2J189Lt8ggNeb-jCm4nsg58KUUt1WlHYUyqgS5fqEiD7rgmyp83zdb6ebvlNj86YHVJ4vcxMYqrQCKC2yAITKn3lfOwXjegFcPFk5PLPb4sS3CtXwxUsDDE3VHsjiVeKIynxLtQ8TzlEfvSRoTULaou0EI3FzCQIHP9OmIwC7MEyCL0hPhnWBtjOS4T7Ckgw8or5ZpYb5XzRCTQXYBwAGixSxFA0LpfvAQA5RMDjcZwSH73jmenFMQp-nM4Lpz4RFgvT8gfIYpLQTE29uJ0CHrdiVhUy_p1xKBjh9q6feTg7RJoKWqkS0cEiSu7wKowUVtNZ1BNDCSCLsmwZbpH1FjMkoOH8cxyF7oIRz0he8qwXiO1k9FABGLl07ftRPr1SjLelGQBhCqD37Ky0twz8m1i5CsRiSigictGLniJt6zaCywwZaYf3-HyLm_ZBF9f0Bqf05byREs2AMlOWr1GvTGO2OLf_DqXito4Bbo5iZjTVwuoOakcfKsOffIIE-Kn03xma-8wl7XOhKtBJIvit5dTzUv4f0f7wYCKccMQv-LyWNItIvIJr8HuUBVHIXh_Hu3e6KwJpQXrioayK0HAxc9aob7iBk7cQWWkKZIWZyxy-IC2tRQ-UzjGTUanISoW_fZSAbU8Ir0oxmEBcRhk2q_q23Kp-SYKwa6qTHGh2qI6CD_MDXWo4uHuLoKAQKUvxDXL3aBEjV8nSmLuz2C3UZmYJS8auwLuuo5NBRG8xHIBJaFkf1BhwwctHATTjCyVNYLoY7mHIKBTr3YZWsr_p5KGEq8-pAqC-gMVvHsF1XMfDg7-kXWBu0Ovo2LqZ5kESqhPnmVOwcIeYS-BfaTQkdQG3tcOfc8DipqBz-3Mx3dichQG9HrmQ7hTlewz3fYJS-G8ZOtUdYtNWXHGMqFACO0UGH3MncFsZmg1pjIT_alinztODBRuyD_Jbpk8iiW_i6ZnLNhiMk1E0gT11uGSffxxeY01wM9Jz20kL_t9cCwMjI_Y1ifumC4eW1Gur-Jqbdh8tVmFXdmByoE6vVr1_pWFELhaNIGdEVlDSBtZUFEyUBuqjVZdw1Yzvkjh-VBW4urQKUr7azNGdcM-WW7UZZZuATSChG7revRZD6haADddPRBbsC9asfn19VCSPUJnRzjq6CmqDF97Nma5DoxA3lE26P6T3QW6vyViR6Gf4FTOmVPYYBEMLNp9Q6Z8exKSMlXuPAnr_LQisNA6EWu5uE8TeEq_OGePh07wEB0KtgB0E6I-3cYbdXkg==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00002401a9db_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G12TvJH2o6UQ0CPdm5GUq4o0oNsQT0vlfaZ0prCRDegWwA13Lg0PNA0x3LXUsTlUpc_u5XJ6vG2ttOm1qW4U6NjCdqMQ4Pkh2J189Lt8ggNeb-jCm4nsg58KUUt1WlHYUyqgS5fqEiD7rgmyp83zdb6ebvlNj86YHVJ4vcxMYqrQCKC2yAITKn3lfOwXjegFcPFk5PLPb4sS3CtXwxUsDDE3VHsjiVeKIynxLtQ8TzlEfvSRoTULaou0EI3FzCQIHP9OmIwC7MEyCL0hPhnWBtjOS4T7Ckgw8or5ZpYb5XzRCTQXYBwAGixSxFA0LpfvAQA5RMDjcZwSH73jmenFMQp-nM4Lpz4RFgvT8gfIYpLQTE29uJ0CHrdiVhUy_p1xKBjh9q6feTg7RJoKWqkS0cEiSu7wKowUVtNZ1BNDCSCLsmwZbpH1FjMkoOH8cxyF7oIRz0he8qwXiO1k9FABGLl07ftRPr1SjLelGQBhCqD37Ky0twz8m1i5CsRiSigictGLniJt6zaCywwZaYf3-HyLm_ZBF9f0Bqf05byREs2AMlOWr1GvTGO2OLf_DqXito4Bbo5iZjTVwuoOakcfKsOffIIE-Kn03xma-8wl7XOhKtBJIvit5dTzUv4f0f7wYCKccMQv-LyWNItIvIJr8HuUBVHIXh_Hu3e6KwJpQXrioayK0HAxc9aob7iBk7cQWWkKZIWZyxy-IC2tRQ-UzjGTUanISoW_fZSAbU8Ir0oxmEBcRhk2q_q23Kp-SYKwa6qTHGh2qI6CD_MDXWo4uHuLoKAQKUvxDXL3aBEjV8nSmLuz2C3UZmYJS8auwLuuo5NBRG8xHIBJaFkf1BhwwctHATTjCyVNYLoY7mHIKBTr3YZWsr_p5KGEq8-pAqC-gMVvHsF1XMfDg7-kXWBu0Ovo2LqZ5kESqhPnmVOwcIeYS-BfaTQkdQG3tcOfc8DipqBz-3Mx3dichQG9HrmQ7hTlewz3fYJS-G8ZOtUdYtNWXHGMqFACO0UGH3MncFsZmg1pjIT_alinztODBRuyD_Jbpk8iiW_i6ZnLNhiMk1E0gT11uGSffxxeY01wM9Jz20kL_t9cCwMjI_Y1ifumC4eW1Gur-Jqbdh8tVmFXdmByoE6vVr1_pWFELhaNIGdEVlDSBtZUFEyUBuqjVZdw1Yzvkjh-VBW4urQKUr7azNGdcM-WW7UZZZuATSChG7revRZD6haADddPRBbsC9asfn19VCSPUJnRzjq6CmqDF97Nma5DoxA3lE26P6T3QW6vyViR6Gf4FTOmVPYYBEMLNp9Q6Z8exKSMlXuPAnr_LQisNA6EWu5uE8TeEq_OGePh07wEB0KtgB0E6I-3cYbdXkg==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1167.04,
            "totalPrice": 920
          },
          {
            "allPassengerTotalPrice": 922,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8cTpUAKldKRxlUvsxze9ZNxy74UivVcWkuLsLYjza6Hv9bcXBr_O8Ip2V4_vReFB231qOi3y9HsyD5PImIToDju45iUgwYgroiXFZtAzQ5K2yEGtYkNvQfO8ngXvoAKP1UfJ-PoC37wfmuuGHYcoT4aK-JD2bmkiS_2Fk6hawH6nuaX_yiSk0kVO1bk586S_zPpC5ntMUtbQpHM9HAkkSfcjoQ0pae-GvGvo4Yq9-05MtHONX1L8HQHwNG2BD2p1GX5IcbiczuE8slo3jYKhxhli_FvFL24iFs16zx8NMDFgqcnZYq8bNGJlMCpHEWq4QUuuj5E4Yiru4LZvd3n5BWd51CIiiv5qfk8gm0tobIHzqIvmDKos1w9bMerlrotRetPYev6u_AKjYg3saxkJ1WPMIsbjHu7pwmUxaclzcYC3OXUw93Xww8CHDQn0RxKaRXYYa4F6gI0AdYaScxPDK1pRzSdwFs-xOiTo_DCra51otDq1hITsgPLALsSMspYahMYnnKMr4Z-hXGZ0tft3BmYuEQLBYXm6oHRjFPPR1o7JkFwWEwgjeYOIvgay8OZvnSbZi-3dqLBiCPDZ6QhYXuLYdeq5BdLcMPXPiLYiGo3TDtZh7uVx-7BtUbwooKOlRhlVCecUDM0zqaHHzvK0lQY2oKZVVeJY5YzhWfMY_czPisGXYyXkNZWpAF7rGk5jP4aTOjkGo0gm_xm__MGHRfI9Jqb7ceskivWkRW1o9nC5oTuMCCtTU3fOeScSXBBVqtXrAoWi6upJzurVZ_aQpHigJMUA7n2IvpJewhCPT_g_6kdCty5VAx-_rpsMKIzlR5BIa5CSs71EhJTDYlXueaouj2qqLKsOE9Hv7N9vc9DFUrFqF1ni5TSXnZweV4f0B9t1haHD3EohC0aiyr9QfSk-HzjlsmhliovA9A1OJQqXtzZ4cc-bzRIC-8Mv19KYCkXNiL_xuFbdHpdz8MPbRRsSZhDNVCyhXkOVqXeCLoOnC5yrEJoap47NaOvlUoHiSnwnKNFudryVouUDdIWp0IHMQ41BkPg-B6dkqn4jBzJT52UFb-A4IBYpaMb-FiM7ztq0DPN39ixKk-bu9QciVT_m7XCcvjaSdqqonWWjXBluBG6Fi_LdokvNQHi3vscCFOOHrRxWbz8JfDz1PA66Xg0DzEeriOUZy4S3Jpk-zZZLQ_b4vHgt7kjT3oCrGBMQYdCB9eevlNMWKfTl9NryYRNvVWYAUm6c_dfDZ2vPQDhAc4Ww7o48FSthSV3E4Lg8",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8cTpUAKldKRxlUvsxze9ZNxy74UivVcWkuLsLYjza6Hv9bcXBr_O8Ip2V4_vReFB231qOi3y9HsyD5PImIToDju45iUgwYgroiXFZtAzQ5K2yEGtYkNvQfO8ngXvoAKP1UfJ-PoC37wfmuuGHYcoT4aK-JD2bmkiS_2Fk6hawH6nuaX_yiSk0kVO1bk586S_zPpC5ntMUtbQpHM9HAkkSfcjoQ0pae-GvGvo4Yq9-05MtHONX1L8HQHwNG2BD2p1GX5IcbiczuE8slo3jYKhxhli_FvFL24iFs16zx8NMDFgqcnZYq8bNGJlMCpHEWq4QUuuj5E4Yiru4LZvd3n5BWd51CIiiv5qfk8gm0tobIHzqIvmDKos1w9bMerlrotRetPYev6u_AKjYg3saxkJ1WPMIsbjHu7pwmUxaclzcYC3OXUw93Xww8CHDQn0RxKaRXYYa4F6gI0AdYaScxPDK1pRzSdwFs-xOiTo_DCra51otDq1hITsgPLALsSMspYahMYnnKMr4Z-hXGZ0tft3BmYuEQLBYXm6oHRjFPPR1o7JkFwWEwgjeYOIvgay8OZvnSbZi-3dqLBiCPDZ6QhYXuLYdeq5BdLcMPXPiLYiGo3TDtZh7uVx-7BtUbwooKOlRhlVCecUDM0zqaHHzvK0lQY2oKZVVeJY5YzhWfMY_czPisGXYyXkNZWpAF7rGk5jP4aTOjkGo0gm_xm__MGHRfI9Jqb7ceskivWkRW1o9nC5oTuMCCtTU3fOeScSXBBVqtXrAoWi6upJzurVZ_aQpHigJMUA7n2IvpJewhCPT_g_6kdCty5VAx-_rpsMKIzlR5BIa5CSs71EhJTDYlXueaouj2qqLKsOE9Hv7N9vc9DFUrFqF1ni5TSXnZweV4f0B9t1haHD3EohC0aiyr9QfSk-HzjlsmhliovA9A1OJQqXtzZ4cc-bzRIC-8Mv19KYCkXNiL_xuFbdHpdz8MPbRRsSZhDNVCyhXkOVqXeCLoOnC5yrEJoap47NaOvlUoHiSnwnKNFudryVouUDdIWp0IHMQ41BkPg-B6dkqn4jBzJT52UFb-A4IBYpaMb-FiM7ztq0DPN39ixKk-bu9QciVT_m7XCcvjaSdqqonWWjXBluBG6Fi_LdokvNQHi3vscCFOOHrRxWbz8JfDz1PA66Xg0DzEeriOUZy4S3Jpk-zZZLQ_b4vHgt7kjT3oCrGBMQYdCB9eevlNMWKfTl9NryYRNvVWYAUm6c_dfDZ2vPQDhAc4Ww7o48FSthSV3E4Lg8",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8cTpUAKldKRxlUvsxze9ZNxy74UivVcWkuLsLYjza6Hv9bcXBr_O8Ip2V4_vReFB231qOi3y9HsyD5PImIToDju45iUgwYgroiXFZtAzQ5K2yEGtYkNvQfO8ngXvoAKP1UfJ-PoC37wfmuuGHYcoT4aK-JD2bmkiS_2Fk6hawH6nuaX_yiSk0kVO1bk586S_zPpC5ntMUtbQpHM9HAkkSfcjoQ0pae-GvGvo4Yq9-05MtHONX1L8HQHwNG2BD2p1GX5IcbiczuE8slo3jYKhxhli_FvFL24iFs16zx8NMDFgqcnZYq8bNGJlMCpHEWq4QUuuj5E4Yiru4LZvd3n5BWd51CIiiv5qfk8gm0tobIHzqIvmDKos1w9bMerlrotRetPYev6u_AKjYg3saxkJ1WPMIsbjHu7pwmUxaclzcYC3OXUw93Xww8CHDQn0RxKaRXYYa4F6gI0AdYaScxPDK1pRzSdwFs-xOiTo_DCra51otDq1hITsgPLALsSMspYahMYnnKMr4Z-hXGZ0tft3BmYuEQLBYXm6oHRjFPPR1o7JkFwWEwgjeYOIvgay8OZvnSbZi-3dqLBiCPDZ6QhYXuLYdeq5BdLcMPXPiLYiGo3TDtZh7uVx-7BtUbwooKOlRhlVCecUDM0zqaHHzvK0lQY2oKZVVeJY5YzhWfMY_czPisGXYyXkNZWpAF7rGk5jP4aTOjkGo0gm_xm__MGHRfI9Jqb7ceskivWkRW1o9nC5oTuMCCtTU3fOeScSXBBVqtXrAoWi6upJzurVZ_aQpHigJMUA7n2IvpJewhCPT_g_6kdCty5VAx-_rpsMKIzlR5BIa5CSs71EhJTDYlXueaouj2qqLKsOE9Hv7N9vc9DFUrFqF1ni5TSXnZweV4f0B9t1haHD3EohC0aiyr9QfSk-HzjlsmhliovA9A1OJQqXtzZ4cc-bzRIC-8Mv19KYCkXNiL_xuFbdHpdz8MPbRRsSZhDNVCyhXkOVqXeCLoOnC5yrEJoap47NaOvlUoHiSnwnKNFudryVouUDdIWp0IHMQ41BkPg-B6dkqn4jBzJT52UFb-A4IBYpaMb-FiM7ztq0DPN39ixKk-bu9QciVT_m7XCcvjaSdqqonWWjXBluBG6Fi_LdokvNQHi3vscCFOOHrRxWbz8JfDz1PA66Xg0DzEeriOUZy4S3Jpk-zZZLQ_b4vHgt7kjT3oCrGBMQYdCB9eevlNMWKfTl9NryYRNvVWYAUm6c_dfDZ2vPQDhAc4Ww7o48FSthSV3E4Lg8",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1161.72,
            "totalPrice": 922
          },
          {
            "allPassengerTotalPrice": 922,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDMRhivgnPL33ryvdE056sNx-olEdZP89YMn7E5oL9c93d7OQZw_mijNZUJniaQW4nyE4j1E8MA_Iwr0w5qXxxBxfbKXE4HLi-1vUnuV338VibzI_8-iEpKccIDwjtysDz9u15m8xxPmzL1cW9QIwmYJHgTBCDuZPCjlUtZRMMwug44zKc2bPEXYpY7keB-rTUOPYSWZ_LYsYx109rPbcTh1zGPNiJG7UOEGwciszLeAk78k917gGEuc9oSQ9VZbAD5xYh8ErNKyJNKxfPdxqMBLgiDeq03odGOP09DKYJgnwWQc3HIjSSM-emlZFigtS7nrqseE3bjkP3iutRMtjSLAWWDOT_oAKd2wUQGQof3aSh8yWLDkKpqAnhng3vC0xZ7ulbfyUCCaPNT6tbMq06ivgxY11MgVlCPdq6aXO-r7P4zKGEgthPD2X5W2iplIFaSFgYS7lZYv1J9FdVpllQc79xd1sgaUJ45kU1fjm-m0UyY0tDNE8F-mnK2-KWlg1ueFg3EyJfq0_GJ-AwTn3Udvx7mHI599kqwJas6Zqrmo_Juj1hmpNtpWVPgMFm03-xl3ayRdYP7Iw-BqmA9k8ebsHoVVqxOyVwphdVXjrPk69v795AVQDZceiXOAAuSxtJghQCRl65hLxuMhy4A6DlGKosTfUSB16L_iZzBfmc87PxwGKd9RrJgcS5cm_fGoU51jakd1tQ3ZnmCU3pv-wbPwHjujZxQTvmbvlQLWcJLYSX4pb-GNmUDcinDu9Gda8To970uHeB0SM-lVrojMe11XLkJq3uCnimREUtqDw_7IcHzw0DURTQtuP1ErIluYdEtQQEsnBkFzflAu3dFUCrUcuc1P7cOAPERS8s4fxAMkoX82NwUgMS3n3NoESYZJv7iAzFXVz3JVc2BSWbemEJrbRM74vJIaW4x3rQcVjUwFuk80zQeJdp5hKa-Gtsp6tsjetvBKqAh99xHCCUdcJmnlp80AwJsTYpG0Pl7ufmwST7gANi4TWi7ovn-poCvTupRy2I6c2SgF1HeydTzq20XSD9oTF7dzBokhsYb1jBMsHzRFW8wk7bL0EaeBnU2xALAg5Nsz6x98hA8DEliRGKfElk749TBTs09NFVVhQ7JfolqJZw6SSFV8j_ITYBpKRXYWlFZBSv64lFeMwv_hxzpxm9sQR4wlzxPq4IWI4CW3tjeYFIBSJTfRm3-LOxD-Sr_bhKiXPwWOP73gwLc4WfmEgVIt6gVKf_9ryb2H8Zaskk-u0PrgNOF-VrAMwkiwb",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDMRhivgnPL33ryvdE056sNx-olEdZP89YMn7E5oL9c93d7OQZw_mijNZUJniaQW4nyE4j1E8MA_Iwr0w5qXxxBxfbKXE4HLi-1vUnuV338VibzI_8-iEpKccIDwjtysDz9u15m8xxPmzL1cW9QIwmYJHgTBCDuZPCjlUtZRMMwug44zKc2bPEXYpY7keB-rTUOPYSWZ_LYsYx109rPbcTh1zGPNiJG7UOEGwciszLeAk78k917gGEuc9oSQ9VZbAD5xYh8ErNKyJNKxfPdxqMBLgiDeq03odGOP09DKYJgnwWQc3HIjSSM-emlZFigtS7nrqseE3bjkP3iutRMtjSLAWWDOT_oAKd2wUQGQof3aSh8yWLDkKpqAnhng3vC0xZ7ulbfyUCCaPNT6tbMq06ivgxY11MgVlCPdq6aXO-r7P4zKGEgthPD2X5W2iplIFaSFgYS7lZYv1J9FdVpllQc79xd1sgaUJ45kU1fjm-m0UyY0tDNE8F-mnK2-KWlg1ueFg3EyJfq0_GJ-AwTn3Udvx7mHI599kqwJas6Zqrmo_Juj1hmpNtpWVPgMFm03-xl3ayRdYP7Iw-BqmA9k8ebsHoVVqxOyVwphdVXjrPk69v795AVQDZceiXOAAuSxtJghQCRl65hLxuMhy4A6DlGKosTfUSB16L_iZzBfmc87PxwGKd9RrJgcS5cm_fGoU51jakd1tQ3ZnmCU3pv-wbPwHjujZxQTvmbvlQLWcJLYSX4pb-GNmUDcinDu9Gda8To970uHeB0SM-lVrojMe11XLkJq3uCnimREUtqDw_7IcHzw0DURTQtuP1ErIluYdEtQQEsnBkFzflAu3dFUCrUcuc1P7cOAPERS8s4fxAMkoX82NwUgMS3n3NoESYZJv7iAzFXVz3JVc2BSWbemEJrbRM74vJIaW4x3rQcVjUwFuk80zQeJdp5hKa-Gtsp6tsjetvBKqAh99xHCCUdcJmnlp80AwJsTYpG0Pl7ufmwST7gANi4TWi7ovn-poCvTupRy2I6c2SgF1HeydTzq20XSD9oTF7dzBokhsYb1jBMsHzRFW8wk7bL0EaeBnU2xALAg5Nsz6x98hA8DEliRGKfElk749TBTs09NFVVhQ7JfolqJZw6SSFV8j_ITYBpKRXYWlFZBSv64lFeMwv_hxzpxm9sQR4wlzxPq4IWI4CW3tjeYFIBSJTfRm3-LOxD-Sr_bhKiXPwWOP73gwLc4WfmEgVIt6gVKf_9ryb2H8Zaskk-u0PrgNOF-VrAMwkiwb",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDMRhivgnPL33ryvdE056sNx-olEdZP89YMn7E5oL9c93d7OQZw_mijNZUJniaQW4nyE4j1E8MA_Iwr0w5qXxxBxfbKXE4HLi-1vUnuV338VibzI_8-iEpKccIDwjtysDz9u15m8xxPmzL1cW9QIwmYJHgTBCDuZPCjlUtZRMMwug44zKc2bPEXYpY7keB-rTUOPYSWZ_LYsYx109rPbcTh1zGPNiJG7UOEGwciszLeAk78k917gGEuc9oSQ9VZbAD5xYh8ErNKyJNKxfPdxqMBLgiDeq03odGOP09DKYJgnwWQc3HIjSSM-emlZFigtS7nrqseE3bjkP3iutRMtjSLAWWDOT_oAKd2wUQGQof3aSh8yWLDkKpqAnhng3vC0xZ7ulbfyUCCaPNT6tbMq06ivgxY11MgVlCPdq6aXO-r7P4zKGEgthPD2X5W2iplIFaSFgYS7lZYv1J9FdVpllQc79xd1sgaUJ45kU1fjm-m0UyY0tDNE8F-mnK2-KWlg1ueFg3EyJfq0_GJ-AwTn3Udvx7mHI599kqwJas6Zqrmo_Juj1hmpNtpWVPgMFm03-xl3ayRdYP7Iw-BqmA9k8ebsHoVVqxOyVwphdVXjrPk69v795AVQDZceiXOAAuSxtJghQCRl65hLxuMhy4A6DlGKosTfUSB16L_iZzBfmc87PxwGKd9RrJgcS5cm_fGoU51jakd1tQ3ZnmCU3pv-wbPwHjujZxQTvmbvlQLWcJLYSX4pb-GNmUDcinDu9Gda8To970uHeB0SM-lVrojMe11XLkJq3uCnimREUtqDw_7IcHzw0DURTQtuP1ErIluYdEtQQEsnBkFzflAu3dFUCrUcuc1P7cOAPERS8s4fxAMkoX82NwUgMS3n3NoESYZJv7iAzFXVz3JVc2BSWbemEJrbRM74vJIaW4x3rQcVjUwFuk80zQeJdp5hKa-Gtsp6tsjetvBKqAh99xHCCUdcJmnlp80AwJsTYpG0Pl7ufmwST7gANi4TWi7ovn-poCvTupRy2I6c2SgF1HeydTzq20XSD9oTF7dzBokhsYb1jBMsHzRFW8wk7bL0EaeBnU2xALAg5Nsz6x98hA8DEliRGKfElk749TBTs09NFVVhQ7JfolqJZw6SSFV8j_ITYBpKRXYWlFZBSv64lFeMwv_hxzpxm9sQR4wlzxPq4IWI4CW3tjeYFIBSJTfRm3-LOxD-Sr_bhKiXPwWOP73gwLc4WfmEgVIt6gVKf_9ryb2H8Zaskk-u0PrgNOF-VrAMwkiwb",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1162.38,
            "totalPrice": 922
          },
          {
            "allPassengerTotalPrice": 923,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGzNGVUYxJN0wAqcwpX3qw56ewGYrAPro4J6oiIj3nGoQNNaZ-81TOx8E5bjEB32JWF97eIpxjxltfsJhvV-wzI9Q0CC0O0tvBf1m6xiI6Ul2w4YyjVWczrzVz0YFedTnzXbZOa1DWHK6Bt068MBzkVJBB2HPIc9mSVILLOqxmvvVwK51sO212Sjv0MAd-5oblEVM5X5MLH_JmoMsb3nthHHyOBqTowyFMlaFgmQ1YAxjH8Rocz-WOpQDNhD_ta9OOJbDLnZKGmYdMeX8HBShrTI6pHqKi8AVKjmEDfEyOnvBGSIX7kGJ3SNInSlnM86pqlaZXV2e8iHzS9-7hM1R4G2dVrsv3oAP5A04TDQpdk4sB2SlM5GpWabgtM3Z2Z2W7ruRPDQwQQXys0fCQkSlSfaWsu0nySCGQ4GAo09uaHddrPpADhUDtXTyhDK--xYHw4DpyRDLvwheR3Ss2lmo37_IowQSpvinXLpUBN-JttFbpIKSYwnZP-yKn5DIIzR6ZC1dTG3vvjreqFAxITZN0MpXt5dvjEphYiNr1f9u1SvEKqN5AZM1nQV_VfBzSff0PN7emObbUBp-wVrvfIzEZXZLqvLJXuaH3-ek1ZnKEp5tjAMOAJwyO59sKHiFG1D9_plj1pLO6TXIbH7_0Oyws6sgFKsoIPzW2B0Qow4b96Mx3OsHt5YJ7vTsD3KRipvJBwH9T5BFLTXnBeVnRHMO18Nljhi0I_Vbp0CW6NQ8M6Gzjfxtoi0d0TIbJaD3FdaNuarOqyGc20D6ln7FjBJZ1CyuR6I8IKYbdnlT2qaT9jzx3pDo-I-zNnjUPFUavO6SQqJNWr6_0Hkz40Pva6UTl1vm7408CE927oYcQkUyhPK9ZQ0PhvqiFhgmP7LnY-D25fE-rg9aIqchYriz-N1RO7UwgL3g6EwIPxCq3eEzYJtuaQiBuh0IcyaM82DRJi5B2je0yI-pD9n3q1G0pgCVntXRN_r4u32xZfP5p33tI3ugUbIYtIsNMuhIdkn2293yyJLUFW8ekDdJBkM7D9JgDRc9c4URYTO2iN1299uG5AwUizQ1_Rkdndg3EKxCo2FhRWfRiEWa5dIwRUYz8NVpidnhyvZHyGnyNsb3vvOw0a7YYhgI4IetGBpPBTFeIidHXXucX0sCJkbdfnoBkpQ2MBiOiZsRyhMwMSvHkaHGb-G8LMtYZUBsf6zIypfS8ReRI_989CK2SXobXGp7GUHRCWgYC2Goiuni1Ul7Pv91NRVaF3Kvml2p82hCgE7pdy4frg_M2CL8v6B6luG6dBcnjp2Codt5wAZvhix-xM_xe-e6DC-EwD4GccjMXi74nTLo",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "10:29",
                "destination": "BCN",
                "duration": "20h41m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e0500007ab1c10e",
                    "equipment": null,
                    "fare_basis": "SH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3424,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e0500007ab1c10e_0",
                    "local_arrival": "2024-09-07T12:05:00.000Z",
                    "local_departure": "2024-09-07T10:29:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3424",
                    "return": 0,
                    "utc_arrival": "2024-09-07T19:05:00.000Z",
                    "utc_departure": "2024-09-07T17:29:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGzNGVUYxJN0wAqcwpX3qw56ewGYrAPro4J6oiIj3nGoQNNaZ-81TOx8E5bjEB32JWF97eIpxjxltfsJhvV-wzI9Q0CC0O0tvBf1m6xiI6Ul2w4YyjVWczrzVz0YFedTnzXbZOa1DWHK6Bt068MBzkVJBB2HPIc9mSVILLOqxmvvVwK51sO212Sjv0MAd-5oblEVM5X5MLH_JmoMsb3nthHHyOBqTowyFMlaFgmQ1YAxjH8Rocz-WOpQDNhD_ta9OOJbDLnZKGmYdMeX8HBShrTI6pHqKi8AVKjmEDfEyOnvBGSIX7kGJ3SNInSlnM86pqlaZXV2e8iHzS9-7hM1R4G2dVrsv3oAP5A04TDQpdk4sB2SlM5GpWabgtM3Z2Z2W7ruRPDQwQQXys0fCQkSlSfaWsu0nySCGQ4GAo09uaHddrPpADhUDtXTyhDK--xYHw4DpyRDLvwheR3Ss2lmo37_IowQSpvinXLpUBN-JttFbpIKSYwnZP-yKn5DIIzR6ZC1dTG3vvjreqFAxITZN0MpXt5dvjEphYiNr1f9u1SvEKqN5AZM1nQV_VfBzSff0PN7emObbUBp-wVrvfIzEZXZLqvLJXuaH3-ek1ZnKEp5tjAMOAJwyO59sKHiFG1D9_plj1pLO6TXIbH7_0Oyws6sgFKsoIPzW2B0Qow4b96Mx3OsHt5YJ7vTsD3KRipvJBwH9T5BFLTXnBeVnRHMO18Nljhi0I_Vbp0CW6NQ8M6Gzjfxtoi0d0TIbJaD3FdaNuarOqyGc20D6ln7FjBJZ1CyuR6I8IKYbdnlT2qaT9jzx3pDo-I-zNnjUPFUavO6SQqJNWr6_0Hkz40Pva6UTl1vm7408CE927oYcQkUyhPK9ZQ0PhvqiFhgmP7LnY-D25fE-rg9aIqchYriz-N1RO7UwgL3g6EwIPxCq3eEzYJtuaQiBuh0IcyaM82DRJi5B2je0yI-pD9n3q1G0pgCVntXRN_r4u32xZfP5p33tI3ugUbIYtIsNMuhIdkn2293yyJLUFW8ekDdJBkM7D9JgDRc9c4URYTO2iN1299uG5AwUizQ1_Rkdndg3EKxCo2FhRWfRiEWa5dIwRUYz8NVpidnhyvZHyGnyNsb3vvOw0a7YYhgI4IetGBpPBTFeIidHXXucX0sCJkbdfnoBkpQ2MBiOiZsRyhMwMSvHkaHGb-G8LMtYZUBsf6zIypfS8ReRI_989CK2SXobXGp7GUHRCWgYC2Goiuni1Ul7Pv91NRVaF3Kvml2p82hCgE7pdy4frg_M2CL8v6B6luG6dBcnjp2Codt5wAZvhix-xM_xe-e6DC-EwD4GccjMXi74nTLo",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGzNGVUYxJN0wAqcwpX3qw56ewGYrAPro4J6oiIj3nGoQNNaZ-81TOx8E5bjEB32JWF97eIpxjxltfsJhvV-wzI9Q0CC0O0tvBf1m6xiI6Ul2w4YyjVWczrzVz0YFedTnzXbZOa1DWHK6Bt068MBzkVJBB2HPIc9mSVILLOqxmvvVwK51sO212Sjv0MAd-5oblEVM5X5MLH_JmoMsb3nthHHyOBqTowyFMlaFgmQ1YAxjH8Rocz-WOpQDNhD_ta9OOJbDLnZKGmYdMeX8HBShrTI6pHqKi8AVKjmEDfEyOnvBGSIX7kGJ3SNInSlnM86pqlaZXV2e8iHzS9-7hM1R4G2dVrsv3oAP5A04TDQpdk4sB2SlM5GpWabgtM3Z2Z2W7ruRPDQwQQXys0fCQkSlSfaWsu0nySCGQ4GAo09uaHddrPpADhUDtXTyhDK--xYHw4DpyRDLvwheR3Ss2lmo37_IowQSpvinXLpUBN-JttFbpIKSYwnZP-yKn5DIIzR6ZC1dTG3vvjreqFAxITZN0MpXt5dvjEphYiNr1f9u1SvEKqN5AZM1nQV_VfBzSff0PN7emObbUBp-wVrvfIzEZXZLqvLJXuaH3-ek1ZnKEp5tjAMOAJwyO59sKHiFG1D9_plj1pLO6TXIbH7_0Oyws6sgFKsoIPzW2B0Qow4b96Mx3OsHt5YJ7vTsD3KRipvJBwH9T5BFLTXnBeVnRHMO18Nljhi0I_Vbp0CW6NQ8M6Gzjfxtoi0d0TIbJaD3FdaNuarOqyGc20D6ln7FjBJZ1CyuR6I8IKYbdnlT2qaT9jzx3pDo-I-zNnjUPFUavO6SQqJNWr6_0Hkz40Pva6UTl1vm7408CE927oYcQkUyhPK9ZQ0PhvqiFhgmP7LnY-D25fE-rg9aIqchYriz-N1RO7UwgL3g6EwIPxCq3eEzYJtuaQiBuh0IcyaM82DRJi5B2je0yI-pD9n3q1G0pgCVntXRN_r4u32xZfP5p33tI3ugUbIYtIsNMuhIdkn2293yyJLUFW8ekDdJBkM7D9JgDRc9c4URYTO2iN1299uG5AwUizQ1_Rkdndg3EKxCo2FhRWfRiEWa5dIwRUYz8NVpidnhyvZHyGnyNsb3vvOw0a7YYhgI4IetGBpPBTFeIidHXXucX0sCJkbdfnoBkpQ2MBiOiZsRyhMwMSvHkaHGb-G8LMtYZUBsf6zIypfS8ReRI_989CK2SXobXGp7GUHRCWgYC2Goiuni1Ul7Pv91NRVaF3Kvml2p82hCgE7pdy4frg_M2CL8v6B6luG6dBcnjp2Codt5wAZvhix-xM_xe-e6DC-EwD4GccjMXi74nTLo",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1173.05,
            "totalPrice": 923
          },
          {
            "allPassengerTotalPrice": 925,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGobJAdVkekpNnR70f4zdDHZ5Vgr6Y32zbOFvPcb7Ij6UjxesVgbOv7plZi1mEK0jTGnQLhnGpqBXNbTmRVnoaoc-J1HdwGMDQM-5ulzLqknOAsziPXbVxsF527qpe9ZVzTA4WYYI2wGPVCfoboKZFA5c4QXdX0UwmFeQg3P7Zxtwcj9xcflHapm1j-Ol7KrXHD_HqLZxZDNVXGsebceIjBM2TB6CV8bKXai7N16fDLsTmuliebOeGQzSQd-xwxdAc0wsAoYj-nhGqkBaYX-aJrOlHMHMIsmpzRxPLv9usjVjORuhvHdZi20ZBny-vmiF6cW8j_cnT5ej49DUIgn6HGAUDXGLYAHD6L1DxYfnfHFo1KMejhv-q_VWhnGl5c5DGZu7ZM0LuTO7IarhL8OVxQs4WvAQkH3pLVyhf2iR4pg8An1c6iz0AppGhtxXO7ie7R5GetueuHTOQy4JYpi4adO18OPakd89u2sI-Q1E1F1o5nCxFGk9XSZaWk849baiQbN0avzArVUBv4ci0kSqOo5YMSCEKf3_KGw0XZQNDwEJ1e8JYTpRMu79NO2ny6e4MnuHKdA1PdsUQUjrFeu338h2hZk6Sen698gc7SVzXbD6gvfPq2uW4b6F60B_BZear4nIGSAj3NM6s_LLPXPtYYEoznMhz3PrAoQt93BIY9FvL08YYFK35H5DNvo-4XA9PWUE2A0HWxlXKZmSr0Gbv_pASl2serhjuohEBF6tcosGtmfnNQV5Z3KUFgR1CEGJmfxCA3Z6GTz2efZjT8XZBl7-fu_-hLOqcrMVCLY50CTvMQ_FWyShpYDdh6khma7n2f2PFUzsnYsZyYSvRW4qKpLPpcQpIrO7FRttnFazb2eLPkOFXD4AETlX5oNMZa7k6BYe_J2maTy38J7AQJCHu3ikCKy-av2XYixdnGPYgwNiX5358Y7VADyyWhqCCHBFp3cCm1VkdJ7IuwGqlgXzY1Rk8U7tAMbVSTDQInUIvsIHsjIoAbzFZ9olhxwUjRhLrax3fctHk3rhJ7VFxD3GAzfi8k0FUF5TOZ_yIybXr5lgOSOZG4JWT9b4hLl098OXd4LMCXbOEh4msaAitTeYQsk0d9wPN00E0cVI8nVmLOOKfD1zWurQVRbixkDBSjd2PRMd-t61KNB8mPJNpzOIbpLBHeFtbjay4S-Dzim_9BWu9mt_C7kwQOSicsvVlRlR96kpb1PcssFPlljH0AgCI-aYiBbUMJMbzukaVagzbsgB8Th_q7qj4ChWIA4iRIQk",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGobJAdVkekpNnR70f4zdDHZ5Vgr6Y32zbOFvPcb7Ij6UjxesVgbOv7plZi1mEK0jTGnQLhnGpqBXNbTmRVnoaoc-J1HdwGMDQM-5ulzLqknOAsziPXbVxsF527qpe9ZVzTA4WYYI2wGPVCfoboKZFA5c4QXdX0UwmFeQg3P7Zxtwcj9xcflHapm1j-Ol7KrXHD_HqLZxZDNVXGsebceIjBM2TB6CV8bKXai7N16fDLsTmuliebOeGQzSQd-xwxdAc0wsAoYj-nhGqkBaYX-aJrOlHMHMIsmpzRxPLv9usjVjORuhvHdZi20ZBny-vmiF6cW8j_cnT5ej49DUIgn6HGAUDXGLYAHD6L1DxYfnfHFo1KMejhv-q_VWhnGl5c5DGZu7ZM0LuTO7IarhL8OVxQs4WvAQkH3pLVyhf2iR4pg8An1c6iz0AppGhtxXO7ie7R5GetueuHTOQy4JYpi4adO18OPakd89u2sI-Q1E1F1o5nCxFGk9XSZaWk849baiQbN0avzArVUBv4ci0kSqOo5YMSCEKf3_KGw0XZQNDwEJ1e8JYTpRMu79NO2ny6e4MnuHKdA1PdsUQUjrFeu338h2hZk6Sen698gc7SVzXbD6gvfPq2uW4b6F60B_BZear4nIGSAj3NM6s_LLPXPtYYEoznMhz3PrAoQt93BIY9FvL08YYFK35H5DNvo-4XA9PWUE2A0HWxlXKZmSr0Gbv_pASl2serhjuohEBF6tcosGtmfnNQV5Z3KUFgR1CEGJmfxCA3Z6GTz2efZjT8XZBl7-fu_-hLOqcrMVCLY50CTvMQ_FWyShpYDdh6khma7n2f2PFUzsnYsZyYSvRW4qKpLPpcQpIrO7FRttnFazb2eLPkOFXD4AETlX5oNMZa7k6BYe_J2maTy38J7AQJCHu3ikCKy-av2XYixdnGPYgwNiX5358Y7VADyyWhqCCHBFp3cCm1VkdJ7IuwGqlgXzY1Rk8U7tAMbVSTDQInUIvsIHsjIoAbzFZ9olhxwUjRhLrax3fctHk3rhJ7VFxD3GAzfi8k0FUF5TOZ_yIybXr5lgOSOZG4JWT9b4hLl098OXd4LMCXbOEh4msaAitTeYQsk0d9wPN00E0cVI8nVmLOOKfD1zWurQVRbixkDBSjd2PRMd-t61KNB8mPJNpzOIbpLBHeFtbjay4S-Dzim_9BWu9mt_C7kwQOSicsvVlRlR96kpb1PcssFPlljH0AgCI-aYiBbUMJMbzukaVagzbsgB8Th_q7qj4ChWIA4iRIQk",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f000084327e22_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGobJAdVkekpNnR70f4zdDHZ5Vgr6Y32zbOFvPcb7Ij6UjxesVgbOv7plZi1mEK0jTGnQLhnGpqBXNbTmRVnoaoc-J1HdwGMDQM-5ulzLqknOAsziPXbVxsF527qpe9ZVzTA4WYYI2wGPVCfoboKZFA5c4QXdX0UwmFeQg3P7Zxtwcj9xcflHapm1j-Ol7KrXHD_HqLZxZDNVXGsebceIjBM2TB6CV8bKXai7N16fDLsTmuliebOeGQzSQd-xwxdAc0wsAoYj-nhGqkBaYX-aJrOlHMHMIsmpzRxPLv9usjVjORuhvHdZi20ZBny-vmiF6cW8j_cnT5ej49DUIgn6HGAUDXGLYAHD6L1DxYfnfHFo1KMejhv-q_VWhnGl5c5DGZu7ZM0LuTO7IarhL8OVxQs4WvAQkH3pLVyhf2iR4pg8An1c6iz0AppGhtxXO7ie7R5GetueuHTOQy4JYpi4adO18OPakd89u2sI-Q1E1F1o5nCxFGk9XSZaWk849baiQbN0avzArVUBv4ci0kSqOo5YMSCEKf3_KGw0XZQNDwEJ1e8JYTpRMu79NO2ny6e4MnuHKdA1PdsUQUjrFeu338h2hZk6Sen698gc7SVzXbD6gvfPq2uW4b6F60B_BZear4nIGSAj3NM6s_LLPXPtYYEoznMhz3PrAoQt93BIY9FvL08YYFK35H5DNvo-4XA9PWUE2A0HWxlXKZmSr0Gbv_pASl2serhjuohEBF6tcosGtmfnNQV5Z3KUFgR1CEGJmfxCA3Z6GTz2efZjT8XZBl7-fu_-hLOqcrMVCLY50CTvMQ_FWyShpYDdh6khma7n2f2PFUzsnYsZyYSvRW4qKpLPpcQpIrO7FRttnFazb2eLPkOFXD4AETlX5oNMZa7k6BYe_J2maTy38J7AQJCHu3ikCKy-av2XYixdnGPYgwNiX5358Y7VADyyWhqCCHBFp3cCm1VkdJ7IuwGqlgXzY1Rk8U7tAMbVSTDQInUIvsIHsjIoAbzFZ9olhxwUjRhLrax3fctHk3rhJ7VFxD3GAzfi8k0FUF5TOZ_yIybXr5lgOSOZG4JWT9b4hLl098OXd4LMCXbOEh4msaAitTeYQsk0d9wPN00E0cVI8nVmLOOKfD1zWurQVRbixkDBSjd2PRMd-t61KNB8mPJNpzOIbpLBHeFtbjay4S-Dzim_9BWu9mt_C7kwQOSicsvVlRlR96kpb1PcssFPlljH0AgCI-aYiBbUMJMbzukaVagzbsgB8Th_q7qj4ChWIA4iRIQk",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1164.87,
            "totalPrice": 925
          },
          {
            "allPassengerTotalPrice": 927,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00006a3c1f0e_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gfh92kh9qXrCICQUrJLqceOXqM1cOhxrWDCGOClrOw2iEL1mPOGvhyPnj92qV4oKzFxe5771an2kRhRJNFF5MzvSBOmGCi460tXOgAlZSDylzYFnfFgrTMHpPwlx4zD1Oas40-wp0ijyGI2xD7rIxIp0akylUnFdYOi7rLwhvT7LoHZkKYqVO9njFJPcbKBMJz8YMGB1feyHPOWESLrz_eJhsYi8kefwqIlR3dYjsFDzqdxyp6IG7wxtaJGwp0SQCdVWUpYHok76y0wZzB5ANpwHby8iEdVEZluiDZP_qdD4qWTjJRbB6l3TA0JEiVdirFHKuv67rYxnG1FRMqx_X4_JW_jHOElvILuAgc8nHjq4GgMHIkAHp9L32ccv4pM6u_Ep2SOWVMW_YQW5A5k1dGo9D-PmDjRaGUU5c-qNTzI8NEMMkcMx0nkCJEFfPbFZhcgENE_PnBU-LUxrsKuD1byW75g2kIYV3axK0IZBJzAYNio96yEO6n15ItefvjKndFaIIi2RItMuiMbukBU9pFCUxTCZery150-a-AREXeu_Mxkc30SxYMusvDHxrAlloQlHVKj1iJd8gEajuVE962AlBM7EVYv3WiLH6A9oPF7dqWDvoFTOUsrMsyZMbIsFoyKQC3iS0H0pu0-rd43FsYtg0zd0RNS0V8wHFSlNVehBUmcvI6r3l0yIusSJjU8FbGo8b0pzXf1ABgNL2Owwtca1iGA8s4y2BUGDCi3ZDnDEurwnfYiOJEferYIOyA0B-bi2j9BAdb-WvwLK-WWD2j6SQwk6qcG_sc877J-7j9-LjFrpbaoD8hXDDlkIFUrM8h_fGV_29UbgZK0svr5ZylFIdBwp5yxQeP5_LfAz9MusXVMvdSw2ZywsPhfI5g7yFIZOLDBvwISwQRv9Ljd6LK93zgNInX0xsBG4yTObkEbfy9Kk-YFS6Oaa-K60lxSrUaH4ubKRYDyiuYakmsI28JCNDrmFFv9emJlyB-YmEFu5SniF5p7cP7nG4ayblBhSuDoQ4nsJbEPa2956hw1viiOiQ64rIXoBImwEVmbO0PDUBH8C6SSbxS5yzEmth3EtgT4ccFgKSbM0lwbX53Yhcaj9apDs1xegaKkw8Q3q6XkWQKDLH7qKaX8883gUpWbaRohyE448-sTJ0lfjQKrhP0jhXwZowdOViynrrsorsble4mcoSnnpYIInjHVl8JRihZUX6faTNBmi8ddoZ5EfdvYVWubqtZdL33M0VFfg2Ug5iRzwpWOwDJAU-dNb_0pyA",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00006a3c1f0e_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gfh92kh9qXrCICQUrJLqceOXqM1cOhxrWDCGOClrOw2iEL1mPOGvhyPnj92qV4oKzFxe5771an2kRhRJNFF5MzvSBOmGCi460tXOgAlZSDylzYFnfFgrTMHpPwlx4zD1Oas40-wp0ijyGI2xD7rIxIp0akylUnFdYOi7rLwhvT7LoHZkKYqVO9njFJPcbKBMJz8YMGB1feyHPOWESLrz_eJhsYi8kefwqIlR3dYjsFDzqdxyp6IG7wxtaJGwp0SQCdVWUpYHok76y0wZzB5ANpwHby8iEdVEZluiDZP_qdD4qWTjJRbB6l3TA0JEiVdirFHKuv67rYxnG1FRMqx_X4_JW_jHOElvILuAgc8nHjq4GgMHIkAHp9L32ccv4pM6u_Ep2SOWVMW_YQW5A5k1dGo9D-PmDjRaGUU5c-qNTzI8NEMMkcMx0nkCJEFfPbFZhcgENE_PnBU-LUxrsKuD1byW75g2kIYV3axK0IZBJzAYNio96yEO6n15ItefvjKndFaIIi2RItMuiMbukBU9pFCUxTCZery150-a-AREXeu_Mxkc30SxYMusvDHxrAlloQlHVKj1iJd8gEajuVE962AlBM7EVYv3WiLH6A9oPF7dqWDvoFTOUsrMsyZMbIsFoyKQC3iS0H0pu0-rd43FsYtg0zd0RNS0V8wHFSlNVehBUmcvI6r3l0yIusSJjU8FbGo8b0pzXf1ABgNL2Owwtca1iGA8s4y2BUGDCi3ZDnDEurwnfYiOJEferYIOyA0B-bi2j9BAdb-WvwLK-WWD2j6SQwk6qcG_sc877J-7j9-LjFrpbaoD8hXDDlkIFUrM8h_fGV_29UbgZK0svr5ZylFIdBwp5yxQeP5_LfAz9MusXVMvdSw2ZywsPhfI5g7yFIZOLDBvwISwQRv9Ljd6LK93zgNInX0xsBG4yTObkEbfy9Kk-YFS6Oaa-K60lxSrUaH4ubKRYDyiuYakmsI28JCNDrmFFv9emJlyB-YmEFu5SniF5p7cP7nG4ayblBhSuDoQ4nsJbEPa2956hw1viiOiQ64rIXoBImwEVmbO0PDUBH8C6SSbxS5yzEmth3EtgT4ccFgKSbM0lwbX53Yhcaj9apDs1xegaKkw8Q3q6XkWQKDLH7qKaX8883gUpWbaRohyE448-sTJ0lfjQKrhP0jhXwZowdOViynrrsorsble4mcoSnnpYIInjHVl8JRihZUX6faTNBmi8ddoZ5EfdvYVWubqtZdL33M0VFfg2Ug5iRzwpWOwDJAU-dNb_0pyA",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00006a3c1f0e_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gfh92kh9qXrCICQUrJLqceOXqM1cOhxrWDCGOClrOw2iEL1mPOGvhyPnj92qV4oKzFxe5771an2kRhRJNFF5MzvSBOmGCi460tXOgAlZSDylzYFnfFgrTMHpPwlx4zD1Oas40-wp0ijyGI2xD7rIxIp0akylUnFdYOi7rLwhvT7LoHZkKYqVO9njFJPcbKBMJz8YMGB1feyHPOWESLrz_eJhsYi8kefwqIlR3dYjsFDzqdxyp6IG7wxtaJGwp0SQCdVWUpYHok76y0wZzB5ANpwHby8iEdVEZluiDZP_qdD4qWTjJRbB6l3TA0JEiVdirFHKuv67rYxnG1FRMqx_X4_JW_jHOElvILuAgc8nHjq4GgMHIkAHp9L32ccv4pM6u_Ep2SOWVMW_YQW5A5k1dGo9D-PmDjRaGUU5c-qNTzI8NEMMkcMx0nkCJEFfPbFZhcgENE_PnBU-LUxrsKuD1byW75g2kIYV3axK0IZBJzAYNio96yEO6n15ItefvjKndFaIIi2RItMuiMbukBU9pFCUxTCZery150-a-AREXeu_Mxkc30SxYMusvDHxrAlloQlHVKj1iJd8gEajuVE962AlBM7EVYv3WiLH6A9oPF7dqWDvoFTOUsrMsyZMbIsFoyKQC3iS0H0pu0-rd43FsYtg0zd0RNS0V8wHFSlNVehBUmcvI6r3l0yIusSJjU8FbGo8b0pzXf1ABgNL2Owwtca1iGA8s4y2BUGDCi3ZDnDEurwnfYiOJEferYIOyA0B-bi2j9BAdb-WvwLK-WWD2j6SQwk6qcG_sc877J-7j9-LjFrpbaoD8hXDDlkIFUrM8h_fGV_29UbgZK0svr5ZylFIdBwp5yxQeP5_LfAz9MusXVMvdSw2ZywsPhfI5g7yFIZOLDBvwISwQRv9Ljd6LK93zgNInX0xsBG4yTObkEbfy9Kk-YFS6Oaa-K60lxSrUaH4ubKRYDyiuYakmsI28JCNDrmFFv9emJlyB-YmEFu5SniF5p7cP7nG4ayblBhSuDoQ4nsJbEPa2956hw1viiOiQ64rIXoBImwEVmbO0PDUBH8C6SSbxS5yzEmth3EtgT4ccFgKSbM0lwbX53Yhcaj9apDs1xegaKkw8Q3q6XkWQKDLH7qKaX8883gUpWbaRohyE448-sTJ0lfjQKrhP0jhXwZowdOViynrrsorsble4mcoSnnpYIInjHVl8JRihZUX6faTNBmi8ddoZ5EfdvYVWubqtZdL33M0VFfg2Ug5iRzwpWOwDJAU-dNb_0pyA",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1166.02,
            "totalPrice": 927
          },
          {
            "allPassengerTotalPrice": 927,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_4cuMGyGiiOmyPBKgNRnKhjqCVC8SJ5gJxKhEX_9XEpbrax3ZoqDZdu4BDVsUf3rjy7fnydTaiDPznK7HKPE2ka29mlDJ7O6v51b_Td7d6PCc0AiIalLHO42PZktkobHT5WIlwju1pxtMuf7Nz-FqCGxCcw8gy8wYDj-U4uBpgOcc8-FiJrjkmNdsaEExoBqJHOEpGTZ8EXuCzQ_bHFBBh9F-cWwLrOTn3OwRVYthE4K8aYmEvQoBLqqOtErsPio5BreUkQ8Mgv5KKdgQdvCJ0R8R2Ol0a2Wv4yBnUxr3-WCKmxDtRCV8KLtHjCjIMQpq6TRC5uPi4fQwNIOr1Kzo1-4k3hMqQfQZR77RfhBUfmKNWH4QvTddFklE2UNR3L2gX5LETmsdDv-ktFlMkOpO9OnZj6gdCOu5gpmg-WHKo6bm_FRVt4SZiEqrl7fEXwlkf256QJK2fUsYg82nMR3WDgETEEnUuPgGEAMXZM7__Y2XB5bDvBUQNd9E3PqKIsMvG-qCdB0rVxPHMWg_jC89aoGQsD9DddV_03gf5uOptfFtwJvTEcX8elOMExJ4pi0rEh5UkurvXHmYQBveplBdSZA0y5K-HOX0OF_w5PFZYe8s1ub1P13G4rCF6tC-0FFVdPiK-exrfLynmRVyVWsnnRaduzdG0it7AuqEUC6_UiK9vacI7uqispsQ5b2UUyygtR8_HuwOJlfxvmoF0EFAFkxDxOVh9xi9Yx79kbewTwc9QVkm5dDAV97cQipxknMeVzUMCqeEjfQb1RVlun9dMEmZ9iiz1OPS9mRG038Jw57qfKoNcX2dH8TuSaW4rlX7bOON-9ihMWZHPhqSzkq3i69ft7T_Acg6yNzSSzIcR94HQ72pNPcqZ-tKZU0rclBJDEGjWkbletZ-hjBs-2t6FMxFbvOsmEqyZOhkkbPouwTLbsr8hKmTiHOc4ePVVDhk4JGOKIfLmJ2pJrehB-aaBSbr28ZP21Xe4pL9JaR5a3k21egyo79EIOJuDAq5plrdVAw7GSFbR6DZNjCYcf6dcVvODiSQGkmiuXcPeiFB2trSlQzgDAI8PpyVQyfWonEikoO2rGS421BsRhUlj4nJeueuUIExAXhJF9l8TWd9n7EyX5618pijz2ozLEMLUTHJIyKAZD3Nh-Zi93ShoNkC6WC1MLt1BAKiLqXMwQw6lB7jqvMRqkX1mCQY4QFMUaZKl1ilTmEAT6uks134LNDvUQ8OL_eXQEL-GH2B-YrkXcvn9pd30g4jIgWY8ubNjiUzBuM3Lz_ZrHovUEZzR-PCkkt8SvfGQxuA3aZ94lYlgOi2ys59NetSLI8P7MUjNQE",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "10:29",
                "destination": "BCN",
                "duration": "20h41m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e0500007ab1c10e",
                    "equipment": null,
                    "fare_basis": "SH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3424,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e0500007ab1c10e_0",
                    "local_arrival": "2024-09-07T12:05:00.000Z",
                    "local_departure": "2024-09-07T10:29:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3424",
                    "return": 0,
                    "utc_arrival": "2024-09-07T19:05:00.000Z",
                    "utc_departure": "2024-09-07T17:29:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_4cuMGyGiiOmyPBKgNRnKhjqCVC8SJ5gJxKhEX_9XEpbrax3ZoqDZdu4BDVsUf3rjy7fnydTaiDPznK7HKPE2ka29mlDJ7O6v51b_Td7d6PCc0AiIalLHO42PZktkobHT5WIlwju1pxtMuf7Nz-FqCGxCcw8gy8wYDj-U4uBpgOcc8-FiJrjkmNdsaEExoBqJHOEpGTZ8EXuCzQ_bHFBBh9F-cWwLrOTn3OwRVYthE4K8aYmEvQoBLqqOtErsPio5BreUkQ8Mgv5KKdgQdvCJ0R8R2Ol0a2Wv4yBnUxr3-WCKmxDtRCV8KLtHjCjIMQpq6TRC5uPi4fQwNIOr1Kzo1-4k3hMqQfQZR77RfhBUfmKNWH4QvTddFklE2UNR3L2gX5LETmsdDv-ktFlMkOpO9OnZj6gdCOu5gpmg-WHKo6bm_FRVt4SZiEqrl7fEXwlkf256QJK2fUsYg82nMR3WDgETEEnUuPgGEAMXZM7__Y2XB5bDvBUQNd9E3PqKIsMvG-qCdB0rVxPHMWg_jC89aoGQsD9DddV_03gf5uOptfFtwJvTEcX8elOMExJ4pi0rEh5UkurvXHmYQBveplBdSZA0y5K-HOX0OF_w5PFZYe8s1ub1P13G4rCF6tC-0FFVdPiK-exrfLynmRVyVWsnnRaduzdG0it7AuqEUC6_UiK9vacI7uqispsQ5b2UUyygtR8_HuwOJlfxvmoF0EFAFkxDxOVh9xi9Yx79kbewTwc9QVkm5dDAV97cQipxknMeVzUMCqeEjfQb1RVlun9dMEmZ9iiz1OPS9mRG038Jw57qfKoNcX2dH8TuSaW4rlX7bOON-9ihMWZHPhqSzkq3i69ft7T_Acg6yNzSSzIcR94HQ72pNPcqZ-tKZU0rclBJDEGjWkbletZ-hjBs-2t6FMxFbvOsmEqyZOhkkbPouwTLbsr8hKmTiHOc4ePVVDhk4JGOKIfLmJ2pJrehB-aaBSbr28ZP21Xe4pL9JaR5a3k21egyo79EIOJuDAq5plrdVAw7GSFbR6DZNjCYcf6dcVvODiSQGkmiuXcPeiFB2trSlQzgDAI8PpyVQyfWonEikoO2rGS421BsRhUlj4nJeueuUIExAXhJF9l8TWd9n7EyX5618pijz2ozLEMLUTHJIyKAZD3Nh-Zi93ShoNkC6WC1MLt1BAKiLqXMwQw6lB7jqvMRqkX1mCQY4QFMUaZKl1ilTmEAT6uks134LNDvUQ8OL_eXQEL-GH2B-YrkXcvn9pd30g4jIgWY8ubNjiUzBuM3Lz_ZrHovUEZzR-PCkkt8SvfGQxuA3aZ94lYlgOi2ys59NetSLI8P7MUjNQE",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_4cuMGyGiiOmyPBKgNRnKhjqCVC8SJ5gJxKhEX_9XEpbrax3ZoqDZdu4BDVsUf3rjy7fnydTaiDPznK7HKPE2ka29mlDJ7O6v51b_Td7d6PCc0AiIalLHO42PZktkobHT5WIlwju1pxtMuf7Nz-FqCGxCcw8gy8wYDj-U4uBpgOcc8-FiJrjkmNdsaEExoBqJHOEpGTZ8EXuCzQ_bHFBBh9F-cWwLrOTn3OwRVYthE4K8aYmEvQoBLqqOtErsPio5BreUkQ8Mgv5KKdgQdvCJ0R8R2Ol0a2Wv4yBnUxr3-WCKmxDtRCV8KLtHjCjIMQpq6TRC5uPi4fQwNIOr1Kzo1-4k3hMqQfQZR77RfhBUfmKNWH4QvTddFklE2UNR3L2gX5LETmsdDv-ktFlMkOpO9OnZj6gdCOu5gpmg-WHKo6bm_FRVt4SZiEqrl7fEXwlkf256QJK2fUsYg82nMR3WDgETEEnUuPgGEAMXZM7__Y2XB5bDvBUQNd9E3PqKIsMvG-qCdB0rVxPHMWg_jC89aoGQsD9DddV_03gf5uOptfFtwJvTEcX8elOMExJ4pi0rEh5UkurvXHmYQBveplBdSZA0y5K-HOX0OF_w5PFZYe8s1ub1P13G4rCF6tC-0FFVdPiK-exrfLynmRVyVWsnnRaduzdG0it7AuqEUC6_UiK9vacI7uqispsQ5b2UUyygtR8_HuwOJlfxvmoF0EFAFkxDxOVh9xi9Yx79kbewTwc9QVkm5dDAV97cQipxknMeVzUMCqeEjfQb1RVlun9dMEmZ9iiz1OPS9mRG038Jw57qfKoNcX2dH8TuSaW4rlX7bOON-9ihMWZHPhqSzkq3i69ft7T_Acg6yNzSSzIcR94HQ72pNPcqZ-tKZU0rclBJDEGjWkbletZ-hjBs-2t6FMxFbvOsmEqyZOhkkbPouwTLbsr8hKmTiHOc4ePVVDhk4JGOKIfLmJ2pJrehB-aaBSbr28ZP21Xe4pL9JaR5a3k21egyo79EIOJuDAq5plrdVAw7GSFbR6DZNjCYcf6dcVvODiSQGkmiuXcPeiFB2trSlQzgDAI8PpyVQyfWonEikoO2rGS421BsRhUlj4nJeueuUIExAXhJF9l8TWd9n7EyX5618pijz2ozLEMLUTHJIyKAZD3Nh-Zi93ShoNkC6WC1MLt1BAKiLqXMwQw6lB7jqvMRqkX1mCQY4QFMUaZKl1ilTmEAT6uks134LNDvUQ8OL_eXQEL-GH2B-YrkXcvn9pd30g4jIgWY8ubNjiUzBuM3Lz_ZrHovUEZzR-PCkkt8SvfGQxuA3aZ94lYlgOi2ys59NetSLI8P7MUjNQE",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1174.83,
            "totalPrice": 927
          },
          {
            "allPassengerTotalPrice": 927,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gz6wf5-VY2r5WbiYWA1kFCM5cmH0Aq1W7-q5KWgwcvxQzU09VP0pRYz0a-IxMW1H1_5vhYxXNk8-ykfAamR60BcGAg4z_nelG7Q5cjgZeUq1L1z7hb4NZ7adqjH3LzBdmzh1rcHrhfIjFPLXKTXan8EBYz4xU0pAgT5OHJOusl2U8754mvBfmg8--kPQ18Iy5rZBRx2H3q3Xc2AhZeG20ozEMBbReNhtmUOuLnCQIXEnatw2FDsu5bWVE1uaeWZrzECQQCR7Bkf6EvLaR4QTcatIyeXo5xVSDwpOlMc1sowvErmURnSs0b_qvUwmg8KhO6V1_AicE9KFl7zrQOS7fMYWphh7It_a7o5ITm9gfpdlNQr0ygDT2Ekwbppi3O9Uj_Mgqg2x-vY1JmzFp8XsJLhJVGQQ29Nyd-AAa1AKmOsyMTg5oV5_2SC0p6WEATKmK8r_jKnzd30W2LJSD7yayuc4j7e4da5KO4wqTb7U0bKhk7ASldOs4vObEXNHsetV5jx4pOAHKd70pc_Fjyp4Pk-SYH1RTwvCYwCVIfIkHeCh-HUcJsrQhxMaSbkNbo9QAg24Fje_48Bw8rg5aDSj4maGS-w889XRkOXov7pmEePap37ILftA2TPdteX5WfKAj9y3Rw0ExVzPbbQiBxvz-ngs6fAaubsg3esqTk7rqv-ogd8lC3EVXTpQUfyH-Q4NkB7UjPeP5LLf_dW5jcRTzicApVjp3cU5gaGU0FQ4ru_eokLxodp9n9cNIcvnLrXTIRwOprHT3C0OJqhhFpNZaO_FTFL1Gf3Rjcil-PzEl5LZ3896wfh7I8Yja8ZfPU47L0gZNZ_wJxdJZpvUiwpvOdUF1mc_8Ss21hC3VUQxwLsTS8xTdr_zmSMmOs6-brSHPotoWMdsJWhm0aZh3GYfpbZL8IK_NdE5B0jD1gU7aG_e5jk2oTexmrLqD39_mWEYsHxv7xYYoyfnvyYr6Q3raPzFofzMfyTFrSabNplODQIL6BrZBd-Q6taPDtLsKFODzJGGXMQEN7IcxmEvUqEzzmWmSzCjJroKdKZIpYyQUNtBDkKumkxXE_OQqj8RhWhCXwyCcWbAOunwZnr45Sy8heNFd3NSHyRK-a0i-0E6_3q471BOEPl4gNw7LVCGrIWdI171EpYPTbDycPqwjkA-2wJIDoQC-uZ-9_4q1LV84xgvXDfmRX6hJirqd5ZBkGKftQbkOYqmVhWCHzzW2R3dcFHqzF4kPzdsS2DQ0fs6HLtGyUEwS0riB-uwj9OQcYhNjsGW8xgBuVGSWDLNJNwFSS2FGxyqAWf2klmQloSMXnKHbRsjzDCNbuE229kExNDBD",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "10:29",
                "destination": "BCN",
                "duration": "20h41m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e0500007ab1c10e",
                    "equipment": null,
                    "fare_basis": "SH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3424,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e0500007ab1c10e_0",
                    "local_arrival": "2024-09-07T12:05:00.000Z",
                    "local_departure": "2024-09-07T10:29:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3424",
                    "return": 0,
                    "utc_arrival": "2024-09-07T19:05:00.000Z",
                    "utc_departure": "2024-09-07T17:29:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gz6wf5-VY2r5WbiYWA1kFCM5cmH0Aq1W7-q5KWgwcvxQzU09VP0pRYz0a-IxMW1H1_5vhYxXNk8-ykfAamR60BcGAg4z_nelG7Q5cjgZeUq1L1z7hb4NZ7adqjH3LzBdmzh1rcHrhfIjFPLXKTXan8EBYz4xU0pAgT5OHJOusl2U8754mvBfmg8--kPQ18Iy5rZBRx2H3q3Xc2AhZeG20ozEMBbReNhtmUOuLnCQIXEnatw2FDsu5bWVE1uaeWZrzECQQCR7Bkf6EvLaR4QTcatIyeXo5xVSDwpOlMc1sowvErmURnSs0b_qvUwmg8KhO6V1_AicE9KFl7zrQOS7fMYWphh7It_a7o5ITm9gfpdlNQr0ygDT2Ekwbppi3O9Uj_Mgqg2x-vY1JmzFp8XsJLhJVGQQ29Nyd-AAa1AKmOsyMTg5oV5_2SC0p6WEATKmK8r_jKnzd30W2LJSD7yayuc4j7e4da5KO4wqTb7U0bKhk7ASldOs4vObEXNHsetV5jx4pOAHKd70pc_Fjyp4Pk-SYH1RTwvCYwCVIfIkHeCh-HUcJsrQhxMaSbkNbo9QAg24Fje_48Bw8rg5aDSj4maGS-w889XRkOXov7pmEePap37ILftA2TPdteX5WfKAj9y3Rw0ExVzPbbQiBxvz-ngs6fAaubsg3esqTk7rqv-ogd8lC3EVXTpQUfyH-Q4NkB7UjPeP5LLf_dW5jcRTzicApVjp3cU5gaGU0FQ4ru_eokLxodp9n9cNIcvnLrXTIRwOprHT3C0OJqhhFpNZaO_FTFL1Gf3Rjcil-PzEl5LZ3896wfh7I8Yja8ZfPU47L0gZNZ_wJxdJZpvUiwpvOdUF1mc_8Ss21hC3VUQxwLsTS8xTdr_zmSMmOs6-brSHPotoWMdsJWhm0aZh3GYfpbZL8IK_NdE5B0jD1gU7aG_e5jk2oTexmrLqD39_mWEYsHxv7xYYoyfnvyYr6Q3raPzFofzMfyTFrSabNplODQIL6BrZBd-Q6taPDtLsKFODzJGGXMQEN7IcxmEvUqEzzmWmSzCjJroKdKZIpYyQUNtBDkKumkxXE_OQqj8RhWhCXwyCcWbAOunwZnr45Sy8heNFd3NSHyRK-a0i-0E6_3q471BOEPl4gNw7LVCGrIWdI171EpYPTbDycPqwjkA-2wJIDoQC-uZ-9_4q1LV84xgvXDfmRX6hJirqd5ZBkGKftQbkOYqmVhWCHzzW2R3dcFHqzF4kPzdsS2DQ0fs6HLtGyUEwS0riB-uwj9OQcYhNjsGW8xgBuVGSWDLNJNwFSS2FGxyqAWf2klmQloSMXnKHbRsjzDCNbuE229kExNDBD",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b813a24e150000dcaf586c_0%7C13a201af4e160000939ccfde_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gz6wf5-VY2r5WbiYWA1kFCM5cmH0Aq1W7-q5KWgwcvxQzU09VP0pRYz0a-IxMW1H1_5vhYxXNk8-ykfAamR60BcGAg4z_nelG7Q5cjgZeUq1L1z7hb4NZ7adqjH3LzBdmzh1rcHrhfIjFPLXKTXan8EBYz4xU0pAgT5OHJOusl2U8754mvBfmg8--kPQ18Iy5rZBRx2H3q3Xc2AhZeG20ozEMBbReNhtmUOuLnCQIXEnatw2FDsu5bWVE1uaeWZrzECQQCR7Bkf6EvLaR4QTcatIyeXo5xVSDwpOlMc1sowvErmURnSs0b_qvUwmg8KhO6V1_AicE9KFl7zrQOS7fMYWphh7It_a7o5ITm9gfpdlNQr0ygDT2Ekwbppi3O9Uj_Mgqg2x-vY1JmzFp8XsJLhJVGQQ29Nyd-AAa1AKmOsyMTg5oV5_2SC0p6WEATKmK8r_jKnzd30W2LJSD7yayuc4j7e4da5KO4wqTb7U0bKhk7ASldOs4vObEXNHsetV5jx4pOAHKd70pc_Fjyp4Pk-SYH1RTwvCYwCVIfIkHeCh-HUcJsrQhxMaSbkNbo9QAg24Fje_48Bw8rg5aDSj4maGS-w889XRkOXov7pmEePap37ILftA2TPdteX5WfKAj9y3Rw0ExVzPbbQiBxvz-ngs6fAaubsg3esqTk7rqv-ogd8lC3EVXTpQUfyH-Q4NkB7UjPeP5LLf_dW5jcRTzicApVjp3cU5gaGU0FQ4ru_eokLxodp9n9cNIcvnLrXTIRwOprHT3C0OJqhhFpNZaO_FTFL1Gf3Rjcil-PzEl5LZ3896wfh7I8Yja8ZfPU47L0gZNZ_wJxdJZpvUiwpvOdUF1mc_8Ss21hC3VUQxwLsTS8xTdr_zmSMmOs6-brSHPotoWMdsJWhm0aZh3GYfpbZL8IK_NdE5B0jD1gU7aG_e5jk2oTexmrLqD39_mWEYsHxv7xYYoyfnvyYr6Q3raPzFofzMfyTFrSabNplODQIL6BrZBd-Q6taPDtLsKFODzJGGXMQEN7IcxmEvUqEzzmWmSzCjJroKdKZIpYyQUNtBDkKumkxXE_OQqj8RhWhCXwyCcWbAOunwZnr45Sy8heNFd3NSHyRK-a0i-0E6_3q471BOEPl4gNw7LVCGrIWdI171EpYPTbDycPqwjkA-2wJIDoQC-uZ-9_4q1LV84xgvXDfmRX6hJirqd5ZBkGKftQbkOYqmVhWCHzzW2R3dcFHqzF4kPzdsS2DQ0fs6HLtGyUEwS0riB-uwj9OQcYhNjsGW8xgBuVGSWDLNJNwFSS2FGxyqAWf2klmQloSMXnKHbRsjzDCNbuE229kExNDBD",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:05",
                "destination": "SFO",
                "duration": "29h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e150000dcaf586c",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e150000dcaf586c_0",
                    "local_arrival": "2024-09-23T23:40:00.000Z",
                    "local_departure": "2024-09-23T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-23T21:40:00.000Z",
                    "utc_departure": "2024-09-23T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Barcelona",
                    "combination_id": "13a201af4e160000939ccfde",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3901,
                    "flyFrom": "PMI",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "13a201af4e160000939ccfde_0",
                    "local_arrival": "2024-09-24T08:55:00.000Z",
                    "local_departure": "2024-09-24T08:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3901",
                    "return": 0,
                    "utc_arrival": "2024-09-24T06:55:00.000Z",
                    "utc_departure": "2024-09-24T06:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1175.49,
            "totalPrice": 927
          },
          {
            "allPassengerTotalPrice": 931,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "BF",
                  ""
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "20:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00002401a9db_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gi3fZyp_h8Z4LbSnOEd-_qP-mySCBp-3kDhejdnViNCWDCvcv_d5A-EsYO5w4S2FkfH8SGhay8KZbGFQNlPV_KDMglElp7vPvLeLVbJ6zMqwYK8C9tLZMYAkaLigyQxrMC38cHGB3gtJ2L9FIPtBDuYwruQJODh5oFppMqMaFsZWjDnnxM-4QB6CLpI9gw0-ir6I5mX5g_nBL1ePyma--KmY41UpMzqwZQcWpzguL-n1Itwe4-lVpQaOSlOeGTuqxsuTGTmIrTVn96OoscXhAAhSeoGvixIfghDKSI2i5Q4_iI56KlOA6geJeTJU63W7GiQfYdPpTa-uKGsp41xhdNpNRw7Ha_wSHfVkcMAvq6-pZ02b8mJQzE5fVPD4dlcrGuutcXYsmczT2X_SMnJmiNwxun4V9CgouR-19a6nJI6kpgB3nazjgkgvh8iltvMEs77dnYWi1QqhYB7riG-TKYNrzrYTfrGvEWfR2WCIsHt726boL61CMssvgx9TsDUvwJ_2udahuifHH7K1BFyesWihcB7EntPpQ0WcrQ6UJcDK_xwXs2XOzkkxh3VTM7yd022eqsJhhuUvDd2pV2QtBG4sYjUBVr6EfgCFbqVF2gqr-PZ6tC9giDzpQxDG4of49zSRmVUyCmxFzFAvOoWiz8yE6t43SO4OXyRRx-x9Almb-XU8YyKgpEMlwXIl3q2ilIJfI3WIpDjp3tq0elx2VFTD9xvd74DnI1Rg38Bqs_G08rDmHhyMcUFhvsyvIykXnjVQa94fdxfTNmJzPWt0xVcPqwlAXLQCG-WpBbNLsL_K35MNIzzYKdx7yWR5SEWTWfMwAZESGCeLCOHZbQyzk38pofAmfZPAI5wiz31L-9KK8RZqmxofYWMQJ9mME1Ptyq1ZzS8T2hv3PFlFcxnQyBrL30a4tLcW9Imi93XfXz6zpCZdX0hD8_5ccAt8b3UkfYJWFGV8eBMH4l87SJ-1nSsFocFMYcfYcflXt08sj60m_lw15xtPUtLEKAgcaS9zPZmZm-i0y7PbrCaipYyY3-Z_UMpHy6pqi5BPQ8Z6m_HgRSLMltaz6_V2RUeUnnT1mVTfhAT5xDkNhVXlM5Mkuuu4cGbi9uKAtGgd_3VSVz1oNIxBCQWTGr1xZrL0NELNL-NySY594OCCSFVtCRCoVlexPXG0bfAYg_t39T57x2j8OiY87c1oUPN0kbtuomIhVmk9UzlrcOI2sxIybQE2mirhI8anwQx2cJMks0NFPr9A=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "20h19m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e05000089d67935",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e05000089d67935_0",
                    "local_arrival": "2024-09-07T16:10:00.000Z",
                    "local_departure": "2024-09-07T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-07T23:10:00.000Z",
                    "utc_departure": "2024-09-07T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "BF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Paris",
                    "combination_id": "243e0a7c4e050000f972b38a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 731,
                    "flyFrom": "LAX",
                    "flyTo": "ORY",
                    "guarantee": true,
                    "id": "243e0a7c4e050000f972b38a_0",
                    "local_arrival": "2024-09-08T15:35:00.000Z",
                    "local_departure": "2024-09-07T19:45:00.000Z",
                    "operating_carrier": "BF",
                    "operating_flight_no": "731",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:35:00.000Z",
                    "utc_departure": "2024-09-08T02:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TO",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Paris",
                    "cityTo": "Barcelona",
                    "combination_id": "0a7c01af4e0600009b53f04e",
                    "equipment": null,
                    "fare_basis": "RBATO",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4758,
                    "flyFrom": "ORY",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "0a7c01af4e0600009b53f04e_0",
                    "local_arrival": "2024-09-08T20:00:00.000Z",
                    "local_departure": "2024-09-08T18:20:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T18:00:00.000Z",
                    "utc_departure": "2024-09-08T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00002401a9db_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gi3fZyp_h8Z4LbSnOEd-_qP-mySCBp-3kDhejdnViNCWDCvcv_d5A-EsYO5w4S2FkfH8SGhay8KZbGFQNlPV_KDMglElp7vPvLeLVbJ6zMqwYK8C9tLZMYAkaLigyQxrMC38cHGB3gtJ2L9FIPtBDuYwruQJODh5oFppMqMaFsZWjDnnxM-4QB6CLpI9gw0-ir6I5mX5g_nBL1ePyma--KmY41UpMzqwZQcWpzguL-n1Itwe4-lVpQaOSlOeGTuqxsuTGTmIrTVn96OoscXhAAhSeoGvixIfghDKSI2i5Q4_iI56KlOA6geJeTJU63W7GiQfYdPpTa-uKGsp41xhdNpNRw7Ha_wSHfVkcMAvq6-pZ02b8mJQzE5fVPD4dlcrGuutcXYsmczT2X_SMnJmiNwxun4V9CgouR-19a6nJI6kpgB3nazjgkgvh8iltvMEs77dnYWi1QqhYB7riG-TKYNrzrYTfrGvEWfR2WCIsHt726boL61CMssvgx9TsDUvwJ_2udahuifHH7K1BFyesWihcB7EntPpQ0WcrQ6UJcDK_xwXs2XOzkkxh3VTM7yd022eqsJhhuUvDd2pV2QtBG4sYjUBVr6EfgCFbqVF2gqr-PZ6tC9giDzpQxDG4of49zSRmVUyCmxFzFAvOoWiz8yE6t43SO4OXyRRx-x9Almb-XU8YyKgpEMlwXIl3q2ilIJfI3WIpDjp3tq0elx2VFTD9xvd74DnI1Rg38Bqs_G08rDmHhyMcUFhvsyvIykXnjVQa94fdxfTNmJzPWt0xVcPqwlAXLQCG-WpBbNLsL_K35MNIzzYKdx7yWR5SEWTWfMwAZESGCeLCOHZbQyzk38pofAmfZPAI5wiz31L-9KK8RZqmxofYWMQJ9mME1Ptyq1ZzS8T2hv3PFlFcxnQyBrL30a4tLcW9Imi93XfXz6zpCZdX0hD8_5ccAt8b3UkfYJWFGV8eBMH4l87SJ-1nSsFocFMYcfYcflXt08sj60m_lw15xtPUtLEKAgcaS9zPZmZm-i0y7PbrCaipYyY3-Z_UMpHy6pqi5BPQ8Z6m_HgRSLMltaz6_V2RUeUnnT1mVTfhAT5xDkNhVXlM5Mkuuu4cGbi9uKAtGgd_3VSVz1oNIxBCQWTGr1xZrL0NELNL-NySY594OCCSFVtCRCoVlexPXG0bfAYg_t39T57x2j8OiY87c1oUPN0kbtuomIhVmk9UzlrcOI2sxIybQE2mirhI8anwQx2cJMks0NFPr9A=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e05000089d67935_0%7C243e0a7c4e050000f972b38a_0%7C0a7c01af4e0600009b53f04e_0%7C14140a224e0f00002401a9db_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gi3fZyp_h8Z4LbSnOEd-_qP-mySCBp-3kDhejdnViNCWDCvcv_d5A-EsYO5w4S2FkfH8SGhay8KZbGFQNlPV_KDMglElp7vPvLeLVbJ6zMqwYK8C9tLZMYAkaLigyQxrMC38cHGB3gtJ2L9FIPtBDuYwruQJODh5oFppMqMaFsZWjDnnxM-4QB6CLpI9gw0-ir6I5mX5g_nBL1ePyma--KmY41UpMzqwZQcWpzguL-n1Itwe4-lVpQaOSlOeGTuqxsuTGTmIrTVn96OoscXhAAhSeoGvixIfghDKSI2i5Q4_iI56KlOA6geJeTJU63W7GiQfYdPpTa-uKGsp41xhdNpNRw7Ha_wSHfVkcMAvq6-pZ02b8mJQzE5fVPD4dlcrGuutcXYsmczT2X_SMnJmiNwxun4V9CgouR-19a6nJI6kpgB3nazjgkgvh8iltvMEs77dnYWi1QqhYB7riG-TKYNrzrYTfrGvEWfR2WCIsHt726boL61CMssvgx9TsDUvwJ_2udahuifHH7K1BFyesWihcB7EntPpQ0WcrQ6UJcDK_xwXs2XOzkkxh3VTM7yd022eqsJhhuUvDd2pV2QtBG4sYjUBVr6EfgCFbqVF2gqr-PZ6tC9giDzpQxDG4of49zSRmVUyCmxFzFAvOoWiz8yE6t43SO4OXyRRx-x9Almb-XU8YyKgpEMlwXIl3q2ilIJfI3WIpDjp3tq0elx2VFTD9xvd74DnI1Rg38Bqs_G08rDmHhyMcUFhvsyvIykXnjVQa94fdxfTNmJzPWt0xVcPqwlAXLQCG-WpBbNLsL_K35MNIzzYKdx7yWR5SEWTWfMwAZESGCeLCOHZbQyzk38pofAmfZPAI5wiz31L-9KK8RZqmxofYWMQJ9mME1Ptyq1ZzS8T2hv3PFlFcxnQyBrL30a4tLcW9Imi93XfXz6zpCZdX0hD8_5ccAt8b3UkfYJWFGV8eBMH4l87SJ-1nSsFocFMYcfYcflXt08sj60m_lw15xtPUtLEKAgcaS9zPZmZm-i0y7PbrCaipYyY3-Z_UMpHy6pqi5BPQ8Z6m_HgRSLMltaz6_V2RUeUnnT1mVTfhAT5xDkNhVXlM5Mkuuu4cGbi9uKAtGgd_3VSVz1oNIxBCQWTGr1xZrL0NELNL-NySY594OCCSFVtCRCoVlexPXG0bfAYg_t39T57x2j8OiY87c1oUPN0kbtuomIhVmk9UzlrcOI2sxIybQE2mirhI8anwQx2cJMks0NFPr9A=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1169.38,
            "totalPrice": 931
          },
          {
            "allPassengerTotalPrice": 934,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAMfxkKdaap1Ym1yJh87KxKCRjma3Uxt9gj8go2eVuCsH7R304Tm9evyovfHB23vDieTiqr9UEEM-kDeoKNPBNpCv6Jw06AdHwlY76dEbXMHOoiIRA6RkrWsOP7OXoof6gdf91r51V40UvDG0dk9Ylc-NCBG-CtX9piQBItO-8c1vRLbzl8xLBdRrbja2chcysE73lFLrF1QADyK2bz223wUgT61Ke_LcK-ApHpdzNSmytMjUegxkDzRXIxDgvPkxFu6f3IDZw6YauhO_y46Up450lR4Rkm7QCsPJaJPguRDzrX60vlhHeNeQqjd5W_NCNVU4YtHuPdTiaWejg-8neeqndjV7wskkQEPr7e_yRQEYuCNihRj4kX54ONF5WKsUYwEdqjX9mEOim_ocr1AGSh233yYXvZTBo1vwA0qB_HEAXwLUP-l2n_Fxs7g84elJvWE3j3FA9myZlcjRtENg3p9LT3q1xZWKC1y4rjGeJV89Q7nPyzb9JpLOIHBsb7_3KS-0IH3zAVO6xoYTlHApaTsL7wkA93Ko9NsE9wyqQZ2ANI4jEh7IBt5fM4z-n0mTIx1wcoVsfc59-Yiz1qvVzro-tL4bh0z3LRHdwHkqgy90ta8fnOU_ZQGnQcfTknq84PkiHWu4AvCUgp_rR2QaNNkAb8b7rLTpfJOjsJEU-hMZJQvtbeH868yr5UfaDffwhuJUPoEqDM429ZSU5CFE4hMzFi8BpuDe2yZApVpha6L4AfF382MNUKgoUpIbenbjlXyEEfLf4xh9wl5hgqMhOjhFt69t8OZYZfSK7_NyQU6IVJQwO5vYfJBIg6Y3J-tow2TJXsxNRfwkCxGLY5FV7a9aqEZdd9ILlmD3tzp6K-ghLnflgtwOsyMuURUr0lcqsVEsSnxIf37SmdasZlwDIzkBxL1i18QXV_4R726MYblxb5sCTURbVVYzZLxdSzamm1rMk1o2RPf0HOpu0Cco7d8bZIKkspTnu1AzGJ1LlXARmE7G9TH3FMvyRfWFYDVhI1J8kyYMqbpMEC-OBsL1spAPE-7OTfV3W6Ku_1YTCl9W7OG_vKIsJnNVT_g0M5nrHwweVzaoqsfiCbiSu0ixQISx_19Ud9F1dtDYmtrHMprH9krodx-UMbvdHRt5Tait3YPC2tMlS92QVIRT3qsO-oP801jiak06HqOuNTCx_nA1Axhcv3PUngsRVojJT0LMs1hc0of4aHSmysme9Wpw7eWpo-BAhEP2w2AZaA8U3KI=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "10:29",
                "destination": "BCN",
                "duration": "20h41m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e0500007ab1c10e",
                    "equipment": null,
                    "fare_basis": "SH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3424,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e0500007ab1c10e_0",
                    "local_arrival": "2024-09-07T12:05:00.000Z",
                    "local_departure": "2024-09-07T10:29:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3424",
                    "return": 0,
                    "utc_arrival": "2024-09-07T19:05:00.000Z",
                    "utc_departure": "2024-09-07T17:29:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAMfxkKdaap1Ym1yJh87KxKCRjma3Uxt9gj8go2eVuCsH7R304Tm9evyovfHB23vDieTiqr9UEEM-kDeoKNPBNpCv6Jw06AdHwlY76dEbXMHOoiIRA6RkrWsOP7OXoof6gdf91r51V40UvDG0dk9Ylc-NCBG-CtX9piQBItO-8c1vRLbzl8xLBdRrbja2chcysE73lFLrF1QADyK2bz223wUgT61Ke_LcK-ApHpdzNSmytMjUegxkDzRXIxDgvPkxFu6f3IDZw6YauhO_y46Up450lR4Rkm7QCsPJaJPguRDzrX60vlhHeNeQqjd5W_NCNVU4YtHuPdTiaWejg-8neeqndjV7wskkQEPr7e_yRQEYuCNihRj4kX54ONF5WKsUYwEdqjX9mEOim_ocr1AGSh233yYXvZTBo1vwA0qB_HEAXwLUP-l2n_Fxs7g84elJvWE3j3FA9myZlcjRtENg3p9LT3q1xZWKC1y4rjGeJV89Q7nPyzb9JpLOIHBsb7_3KS-0IH3zAVO6xoYTlHApaTsL7wkA93Ko9NsE9wyqQZ2ANI4jEh7IBt5fM4z-n0mTIx1wcoVsfc59-Yiz1qvVzro-tL4bh0z3LRHdwHkqgy90ta8fnOU_ZQGnQcfTknq84PkiHWu4AvCUgp_rR2QaNNkAb8b7rLTpfJOjsJEU-hMZJQvtbeH868yr5UfaDffwhuJUPoEqDM429ZSU5CFE4hMzFi8BpuDe2yZApVpha6L4AfF382MNUKgoUpIbenbjlXyEEfLf4xh9wl5hgqMhOjhFt69t8OZYZfSK7_NyQU6IVJQwO5vYfJBIg6Y3J-tow2TJXsxNRfwkCxGLY5FV7a9aqEZdd9ILlmD3tzp6K-ghLnflgtwOsyMuURUr0lcqsVEsSnxIf37SmdasZlwDIzkBxL1i18QXV_4R726MYblxb5sCTURbVVYzZLxdSzamm1rMk1o2RPf0HOpu0Cco7d8bZIKkspTnu1AzGJ1LlXARmE7G9TH3FMvyRfWFYDVhI1J8kyYMqbpMEC-OBsL1spAPE-7OTfV3W6Ku_1YTCl9W7OG_vKIsJnNVT_g0M5nrHwweVzaoqsfiCbiSu0ixQISx_19Ud9F1dtDYmtrHMprH9krodx-UMbvdHRt5Tait3YPC2tMlS92QVIRT3qsO-oP801jiak06HqOuNTCx_nA1Axhcv3PUngsRVojJT0LMs1hc0of4aHSmysme9Wpw7eWpo-BAhEP2w2AZaA8U3KI=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f0000744aea56_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAMfxkKdaap1Ym1yJh87KxKCRjma3Uxt9gj8go2eVuCsH7R304Tm9evyovfHB23vDieTiqr9UEEM-kDeoKNPBNpCv6Jw06AdHwlY76dEbXMHOoiIRA6RkrWsOP7OXoof6gdf91r51V40UvDG0dk9Ylc-NCBG-CtX9piQBItO-8c1vRLbzl8xLBdRrbja2chcysE73lFLrF1QADyK2bz223wUgT61Ke_LcK-ApHpdzNSmytMjUegxkDzRXIxDgvPkxFu6f3IDZw6YauhO_y46Up450lR4Rkm7QCsPJaJPguRDzrX60vlhHeNeQqjd5W_NCNVU4YtHuPdTiaWejg-8neeqndjV7wskkQEPr7e_yRQEYuCNihRj4kX54ONF5WKsUYwEdqjX9mEOim_ocr1AGSh233yYXvZTBo1vwA0qB_HEAXwLUP-l2n_Fxs7g84elJvWE3j3FA9myZlcjRtENg3p9LT3q1xZWKC1y4rjGeJV89Q7nPyzb9JpLOIHBsb7_3KS-0IH3zAVO6xoYTlHApaTsL7wkA93Ko9NsE9wyqQZ2ANI4jEh7IBt5fM4z-n0mTIx1wcoVsfc59-Yiz1qvVzro-tL4bh0z3LRHdwHkqgy90ta8fnOU_ZQGnQcfTknq84PkiHWu4AvCUgp_rR2QaNNkAb8b7rLTpfJOjsJEU-hMZJQvtbeH868yr5UfaDffwhuJUPoEqDM429ZSU5CFE4hMzFi8BpuDe2yZApVpha6L4AfF382MNUKgoUpIbenbjlXyEEfLf4xh9wl5hgqMhOjhFt69t8OZYZfSK7_NyQU6IVJQwO5vYfJBIg6Y3J-tow2TJXsxNRfwkCxGLY5FV7a9aqEZdd9ILlmD3tzp6K-ghLnflgtwOsyMuURUr0lcqsVEsSnxIf37SmdasZlwDIzkBxL1i18QXV_4R726MYblxb5sCTURbVVYzZLxdSzamm1rMk1o2RPf0HOpu0Cco7d8bZIKkspTnu1AzGJ1LlXARmE7G9TH3FMvyRfWFYDVhI1J8kyYMqbpMEC-OBsL1spAPE-7OTfV3W6Ku_1YTCl9W7OG_vKIsJnNVT_g0M5nrHwweVzaoqsfiCbiSu0ixQISx_19Ud9F1dtDYmtrHMprH9krodx-UMbvdHRt5Tait3YPC2tMlS92QVIRT3qsO-oP801jiak06HqOuNTCx_nA1Axhcv3PUngsRVojJT0LMs1hc0of4aHSmysme9Wpw7eWpo-BAhEP2w2AZaA8U3KI=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1175.39,
            "totalPrice": 934
          },
          {
            "allPassengerTotalPrice": 938,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNC-Vlups96D0tU2DPZiIDI1ysEAYH1iMITSDpbl6EGa57757NyxkIV0DdaWshHEqUsFVAM7W84_bQYbG-I1AuNOG5frGKg4t0LD0SFf3mIvsfw0DCQNc5bDWF20ZD2wd3alKmgH7xkz1A_rXNjMpuxWw3EwDjuINHK3IUcmEImigsdf263wr4KBbEL03OUu7OHGodkbbnQoPVOlQUNpqjn7gmCD4tShgbpbjWrXnAVzSDtwuo2oEKawzQdVuDyFtOz1MBCzVEZgjQObvAmgoudIRhNfoEfrc1kEnV9DCQO1nZ0XhkICoikGqHbUtYMMIH899SVUlUzEjyX0wjAmwHRfhcvLgpMJj-NKTRedPnMPRlucJ2sUR0N729DIU07jjjMQAQmNXVG55OZAGoUA5p9dMkVZWnoR6PP-d9cJNmcarPZ_hwPK2jUek-KcgPervZzgKsyfEvQMPTrxvmZVPDyfcBz8luOgnAa-7YM1mUNZYGSjYGZNTHQgFXlJhEA3HMlYHGwvw1EysHQukdIunQIvltqPJRax1Ryh8SpUtfIqvp8bUH-D1jWrCPYG8v7L2LoLu17O9IUA2N62wDqyy-75OQokTuLbEzccD-60VM4edx8iGlK_Ui0UoFmwUE0RnaY5M--DqfEzMvbGCJvFLxEoLYz_5qqHoMUHzhdoFC5kjVYeVYtO0YvXFejzuuP7TlwpSRGW08V1pk0jP99eCqSdfhcAr8q2W7bm35P2BINpJ_WwEj-sW7FDnhdtB_fa0hbnkbprJaVoiiCtmEH_uspO0hKxserTsThEjNz8BcxHksaFX9DJ_9dON-AQLxZ9DFzL6G-r3FpzS2rHfJdNqynOA6WLjkvfcPB0M-UzvOsHCPvKR0n9Ipj5aetFCttnBNV2J8MfJ3D5Ujs9ke6K554G6cziYEBTJsNOi1Ppj4dF-Cf8aTDaVlIj0Vj7tUDKz8mpblHlsrlamVnlE68bisTj2iqKCzPAm14TsiS9dCSkjGNBzObBrVXwYsaMXr1MdV61U3WHzri03egtqYkazBA3Uvro33-b-eernbUn5CpzD3x8yZjyyRjUYvf1XxTkSYgpQsSNhGSYfSElEeE8fMD-1TzFpDBsBQYEJYoxpXssu2JEOVxT3c5ubYuiUoMB0CtuWTNUvmpEuKVuIh_tYupktJDfDnwQG6b1TkGrKRg6Rhq8cqnfgv3-gpZmBs0hnhZFoAd-98RF697hF0RcgZlrEp6vAcwXSxdxrrZFllq9NQaBkr4h7fc7G6-IVizgD",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "10:29",
                "destination": "BCN",
                "duration": "20h41m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e0500007ab1c10e",
                    "equipment": null,
                    "fare_basis": "SH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3424,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e0500007ab1c10e_0",
                    "local_arrival": "2024-09-07T12:05:00.000Z",
                    "local_departure": "2024-09-07T10:29:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3424",
                    "return": 0,
                    "utc_arrival": "2024-09-07T19:05:00.000Z",
                    "utc_departure": "2024-09-07T17:29:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNC-Vlups96D0tU2DPZiIDI1ysEAYH1iMITSDpbl6EGa57757NyxkIV0DdaWshHEqUsFVAM7W84_bQYbG-I1AuNOG5frGKg4t0LD0SFf3mIvsfw0DCQNc5bDWF20ZD2wd3alKmgH7xkz1A_rXNjMpuxWw3EwDjuINHK3IUcmEImigsdf263wr4KBbEL03OUu7OHGodkbbnQoPVOlQUNpqjn7gmCD4tShgbpbjWrXnAVzSDtwuo2oEKawzQdVuDyFtOz1MBCzVEZgjQObvAmgoudIRhNfoEfrc1kEnV9DCQO1nZ0XhkICoikGqHbUtYMMIH899SVUlUzEjyX0wjAmwHRfhcvLgpMJj-NKTRedPnMPRlucJ2sUR0N729DIU07jjjMQAQmNXVG55OZAGoUA5p9dMkVZWnoR6PP-d9cJNmcarPZ_hwPK2jUek-KcgPervZzgKsyfEvQMPTrxvmZVPDyfcBz8luOgnAa-7YM1mUNZYGSjYGZNTHQgFXlJhEA3HMlYHGwvw1EysHQukdIunQIvltqPJRax1Ryh8SpUtfIqvp8bUH-D1jWrCPYG8v7L2LoLu17O9IUA2N62wDqyy-75OQokTuLbEzccD-60VM4edx8iGlK_Ui0UoFmwUE0RnaY5M--DqfEzMvbGCJvFLxEoLYz_5qqHoMUHzhdoFC5kjVYeVYtO0YvXFejzuuP7TlwpSRGW08V1pk0jP99eCqSdfhcAr8q2W7bm35P2BINpJ_WwEj-sW7FDnhdtB_fa0hbnkbprJaVoiiCtmEH_uspO0hKxserTsThEjNz8BcxHksaFX9DJ_9dON-AQLxZ9DFzL6G-r3FpzS2rHfJdNqynOA6WLjkvfcPB0M-UzvOsHCPvKR0n9Ipj5aetFCttnBNV2J8MfJ3D5Ujs9ke6K554G6cziYEBTJsNOi1Ppj4dF-Cf8aTDaVlIj0Vj7tUDKz8mpblHlsrlamVnlE68bisTj2iqKCzPAm14TsiS9dCSkjGNBzObBrVXwYsaMXr1MdV61U3WHzri03egtqYkazBA3Uvro33-b-eernbUn5CpzD3x8yZjyyRjUYvf1XxTkSYgpQsSNhGSYfSElEeE8fMD-1TzFpDBsBQYEJYoxpXssu2JEOVxT3c5ubYuiUoMB0CtuWTNUvmpEuKVuIh_tYupktJDfDnwQG6b1TkGrKRg6Rhq8cqnfgv3-gpZmBs0hnhZFoAd-98RF697hF0RcgZlrEp6vAcwXSxdxrrZFllq9NQaBkr4h7fc7G6-IVizgD",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00008b95d602_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNC-Vlups96D0tU2DPZiIDI1ysEAYH1iMITSDpbl6EGa57757NyxkIV0DdaWshHEqUsFVAM7W84_bQYbG-I1AuNOG5frGKg4t0LD0SFf3mIvsfw0DCQNc5bDWF20ZD2wd3alKmgH7xkz1A_rXNjMpuxWw3EwDjuINHK3IUcmEImigsdf263wr4KBbEL03OUu7OHGodkbbnQoPVOlQUNpqjn7gmCD4tShgbpbjWrXnAVzSDtwuo2oEKawzQdVuDyFtOz1MBCzVEZgjQObvAmgoudIRhNfoEfrc1kEnV9DCQO1nZ0XhkICoikGqHbUtYMMIH899SVUlUzEjyX0wjAmwHRfhcvLgpMJj-NKTRedPnMPRlucJ2sUR0N729DIU07jjjMQAQmNXVG55OZAGoUA5p9dMkVZWnoR6PP-d9cJNmcarPZ_hwPK2jUek-KcgPervZzgKsyfEvQMPTrxvmZVPDyfcBz8luOgnAa-7YM1mUNZYGSjYGZNTHQgFXlJhEA3HMlYHGwvw1EysHQukdIunQIvltqPJRax1Ryh8SpUtfIqvp8bUH-D1jWrCPYG8v7L2LoLu17O9IUA2N62wDqyy-75OQokTuLbEzccD-60VM4edx8iGlK_Ui0UoFmwUE0RnaY5M--DqfEzMvbGCJvFLxEoLYz_5qqHoMUHzhdoFC5kjVYeVYtO0YvXFejzuuP7TlwpSRGW08V1pk0jP99eCqSdfhcAr8q2W7bm35P2BINpJ_WwEj-sW7FDnhdtB_fa0hbnkbprJaVoiiCtmEH_uspO0hKxserTsThEjNz8BcxHksaFX9DJ_9dON-AQLxZ9DFzL6G-r3FpzS2rHfJdNqynOA6WLjkvfcPB0M-UzvOsHCPvKR0n9Ipj5aetFCttnBNV2J8MfJ3D5Ujs9ke6K554G6cziYEBTJsNOi1Ppj4dF-Cf8aTDaVlIj0Vj7tUDKz8mpblHlsrlamVnlE68bisTj2iqKCzPAm14TsiS9dCSkjGNBzObBrVXwYsaMXr1MdV61U3WHzri03egtqYkazBA3Uvro33-b-eernbUn5CpzD3x8yZjyyRjUYvf1XxTkSYgpQsSNhGSYfSElEeE8fMD-1TzFpDBsBQYEJYoxpXssu2JEOVxT3c5ubYuiUoMB0CtuWTNUvmpEuKVuIh_tYupktJDfDnwQG6b1TkGrKRg6Rhq8cqnfgv3-gpZmBs0hnhZFoAd-98RF697hF0RcgZlrEp6vAcwXSxdxrrZFllq9NQaBkr4h7fc7G6-IVizgD",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1177.17,
            "totalPrice": 938
          },
          {
            "allPassengerTotalPrice": 938,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKUmJPArimrJH7-v1frIW-VDFJR52FYLDqYVprfVPaN0nghisRca8_qJzMoyxOr3CdQ_cYE2UM5o7k1adqoOxQ0Sz9AbY-L__gGeSicft_cRu5BYcHS8jW1zm8-ML8wgutt-I3ubVhPTpi2Fa7ehQtd2Yg1yHbLvLpTJ0JhPoP7JFu0YAi2R4IMw8xiRsgUWsyRoKR7Ozili5dCHaUND_6J3AOcHvSJKNpj0vG4UGsNfC-XH12-Yz2L2lfbBxv8Y1sAheDKFBaMrP2XLGKy-872GJDS5A_sWzi0PWr268qQimQSHyPafhoDqw6m5CNVke-SvvnbmBTTdBXPsKkGDeRa_b6msM19a_VFQ8RgSDueUL-s0KaNqwx4aOMQbNgDqj3jRax4Kr7qOYOhCu7nylLC8BEnAZOkc82x3dq2CSO4rCr2ZkgCIjG46cUmF6iIl4vyYV5pgZq5VXpNtu6XfA5eo_br1A09s1BIszQEaLaLY_W90eSZbjz_2q0BIRThnCp-eqOB6wFR-phg7d2BHkkh30GnCqWtWlwz4IxHHeXHCywgv_W8ExQ3CQRdcqHXHlge4QoUw1sDW4B0-jYWuAb2Zz7MZ5EOanR6UFF5zcX268p_NjuUB__CbD1p_8EGsqSUTRhBQk7_YLLF16hyeYo9RWdmUm40ump1wkz9xT0QJMQIOIPFFrKXPmKiPZZLcwc7ZMHh005Y6glUMMOVcvioMANlgacGFSOHgLnFojDAMkbT7NWZM0HUzGWaddzTNOCmJsNc3iyV-ozujd8GxBOggv568CZWjhped4NtmKlq30ilt3cq0dIcA6RjyG7EwTQBvUTihrMycsGLk4V5Gaogh75XwglwRZws7yiR6btsUH7Tn_h0LfX5NVbOYUpSSsrLhfoc0S7O98Rzd5XhemBKLNwppR2KpoQegg6KkCoJ9PvSmCzb7g6IOWBD5QVVJ-HYiFOSAkVnlpI6RDAsM5KIEXEWJds_o99iGABplA9-Kf8l3aBKcvVRboO-jLo4WhJkKBaGw9pBY6zAl_rV43fxJV2NO5RQLLoxsdKQx7XkF0qs6KAOtij_l4GLk4W81Tmlaohh1mtzAujyDHaYIW2a6BASNptmTGo4ny8NtrGavCj0zYPgarE7zu4qX-wR0KY7TyHZfeb1qt5Tqh4fXaCjw7w0DmnFMvdEVgTbAccmo3jPRfmt0fqdHfxDouDvEm4-iVA_yq_BcpEwfy4wrpirBSfk1Gvm2AgU4WUmhgpSQ=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "10:29",
                "destination": "BCN",
                "duration": "20h41m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e0500007ab1c10e",
                    "equipment": null,
                    "fare_basis": "SH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3424,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e0500007ab1c10e_0",
                    "local_arrival": "2024-09-07T12:05:00.000Z",
                    "local_departure": "2024-09-07T10:29:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3424",
                    "return": 0,
                    "utc_arrival": "2024-09-07T19:05:00.000Z",
                    "utc_departure": "2024-09-07T17:29:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "LON",
                    "cityFrom": "Los Angeles",
                    "cityTo": "London",
                    "combination_id": "243e22f54e0500000464eaf4",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 712,
                    "flyFrom": "LAX",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "243e22f54e0500000464eaf4_0",
                    "local_arrival": "2024-09-08T10:55:00.000Z",
                    "local_departure": "2024-09-07T16:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T09:55:00.000Z",
                    "utc_departure": "2024-09-07T23:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0600008a6aff96",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0600008a6aff96_0",
                    "local_arrival": "2024-09-08T16:10:00.000Z",
                    "local_departure": "2024-09-08T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:10:00.000Z",
                    "utc_departure": "2024-09-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKUmJPArimrJH7-v1frIW-VDFJR52FYLDqYVprfVPaN0nghisRca8_qJzMoyxOr3CdQ_cYE2UM5o7k1adqoOxQ0Sz9AbY-L__gGeSicft_cRu5BYcHS8jW1zm8-ML8wgutt-I3ubVhPTpi2Fa7ehQtd2Yg1yHbLvLpTJ0JhPoP7JFu0YAi2R4IMw8xiRsgUWsyRoKR7Ozili5dCHaUND_6J3AOcHvSJKNpj0vG4UGsNfC-XH12-Yz2L2lfbBxv8Y1sAheDKFBaMrP2XLGKy-872GJDS5A_sWzi0PWr268qQimQSHyPafhoDqw6m5CNVke-SvvnbmBTTdBXPsKkGDeRa_b6msM19a_VFQ8RgSDueUL-s0KaNqwx4aOMQbNgDqj3jRax4Kr7qOYOhCu7nylLC8BEnAZOkc82x3dq2CSO4rCr2ZkgCIjG46cUmF6iIl4vyYV5pgZq5VXpNtu6XfA5eo_br1A09s1BIszQEaLaLY_W90eSZbjz_2q0BIRThnCp-eqOB6wFR-phg7d2BHkkh30GnCqWtWlwz4IxHHeXHCywgv_W8ExQ3CQRdcqHXHlge4QoUw1sDW4B0-jYWuAb2Zz7MZ5EOanR6UFF5zcX268p_NjuUB__CbD1p_8EGsqSUTRhBQk7_YLLF16hyeYo9RWdmUm40ump1wkz9xT0QJMQIOIPFFrKXPmKiPZZLcwc7ZMHh005Y6glUMMOVcvioMANlgacGFSOHgLnFojDAMkbT7NWZM0HUzGWaddzTNOCmJsNc3iyV-ozujd8GxBOggv568CZWjhped4NtmKlq30ilt3cq0dIcA6RjyG7EwTQBvUTihrMycsGLk4V5Gaogh75XwglwRZws7yiR6btsUH7Tn_h0LfX5NVbOYUpSSsrLhfoc0S7O98Rzd5XhemBKLNwppR2KpoQegg6KkCoJ9PvSmCzb7g6IOWBD5QVVJ-HYiFOSAkVnlpI6RDAsM5KIEXEWJds_o99iGABplA9-Kf8l3aBKcvVRboO-jLo4WhJkKBaGw9pBY6zAl_rV43fxJV2NO5RQLLoxsdKQx7XkF0qs6KAOtij_l4GLk4W81Tmlaohh1mtzAujyDHaYIW2a6BASNptmTGo4ny8NtrGavCj0zYPgarE7zu4qX-wR0KY7TyHZfeb1qt5Tqh4fXaCjw7w0DmnFMvdEVgTbAccmo3jPRfmt0fqdHfxDouDvEm4-iVA_yq_BcpEwfy4wrpirBSfk1Gvm2AgU4WUmhgpSQ=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e0500007ab1c10e_0%7C243e22f54e0500000464eaf4_0%7C22f501af4e0600008a6aff96_0%7C14140a224e0f00007c80866f_0%7C04b801af4e150000a17d6ae7_0%7C01af0f9c4e160000429ffbd8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKUmJPArimrJH7-v1frIW-VDFJR52FYLDqYVprfVPaN0nghisRca8_qJzMoyxOr3CdQ_cYE2UM5o7k1adqoOxQ0Sz9AbY-L__gGeSicft_cRu5BYcHS8jW1zm8-ML8wgutt-I3ubVhPTpi2Fa7ehQtd2Yg1yHbLvLpTJ0JhPoP7JFu0YAi2R4IMw8xiRsgUWsyRoKR7Ozili5dCHaUND_6J3AOcHvSJKNpj0vG4UGsNfC-XH12-Yz2L2lfbBxv8Y1sAheDKFBaMrP2XLGKy-872GJDS5A_sWzi0PWr268qQimQSHyPafhoDqw6m5CNVke-SvvnbmBTTdBXPsKkGDeRa_b6msM19a_VFQ8RgSDueUL-s0KaNqwx4aOMQbNgDqj3jRax4Kr7qOYOhCu7nylLC8BEnAZOkc82x3dq2CSO4rCr2ZkgCIjG46cUmF6iIl4vyYV5pgZq5VXpNtu6XfA5eo_br1A09s1BIszQEaLaLY_W90eSZbjz_2q0BIRThnCp-eqOB6wFR-phg7d2BHkkh30GnCqWtWlwz4IxHHeXHCywgv_W8ExQ3CQRdcqHXHlge4QoUw1sDW4B0-jYWuAb2Zz7MZ5EOanR6UFF5zcX268p_NjuUB__CbD1p_8EGsqSUTRhBQk7_YLLF16hyeYo9RWdmUm40ump1wkz9xT0QJMQIOIPFFrKXPmKiPZZLcwc7ZMHh005Y6glUMMOVcvioMANlgacGFSOHgLnFojDAMkbT7NWZM0HUzGWaddzTNOCmJsNc3iyV-ozujd8GxBOggv568CZWjhped4NtmKlq30ilt3cq0dIcA6RjyG7EwTQBvUTihrMycsGLk4V5Gaogh75XwglwRZws7yiR6btsUH7Tn_h0LfX5NVbOYUpSSsrLhfoc0S7O98Rzd5XhemBKLNwppR2KpoQegg6KkCoJ9PvSmCzb7g6IOWBD5QVVJ-HYiFOSAkVnlpI6RDAsM5KIEXEWJds_o99iGABplA9-Kf8l3aBKcvVRboO-jLo4WhJkKBaGw9pBY6zAl_rV43fxJV2NO5RQLLoxsdKQx7XkF0qs6KAOtij_l4GLk4W81Tmlaohh1mtzAujyDHaYIW2a6BASNptmTGo4ny8NtrGavCj0zYPgarE7zu4qX-wR0KY7TyHZfeb1qt5Tqh4fXaCjw7w0DmnFMvdEVgTbAccmo3jPRfmt0fqdHfxDouDvEm4-iVA_yq_BcpEwfy4wrpirBSfk1Gvm2AgU4WUmhgpSQ=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e150000a17d6ae7",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e150000a17d6ae7_0",
                    "local_arrival": "2024-09-24T00:35:00.000Z",
                    "local_departure": "2024-09-23T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-23T22:35:00.000Z",
                    "utc_departure": "2024-09-23T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e160000429ffbd8",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e160000429ffbd8_0",
                    "local_arrival": "2024-09-24T18:10:00.000Z",
                    "local_departure": "2024-09-24T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-25T01:10:00.000Z",
                    "utc_departure": "2024-09-24T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1177.84,
            "totalPrice": 938
          },
          {
            "allPassengerTotalPrice": 1122,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GC6y5bfOdkY-DzFV6fnj6hQc4zdrLqysZ8NNTj76XXR4foJDHrsA1LIRq3vVf7YK4VFXzwifqy5TvH8AWzfMMyUroB_dlHHnb_weiW0BKHy1EwKs-djpvFDFWgiK03UzAnH15RxWIiiYigTtwo4SVsCliwSJY4hHuAeSJf5EJ-k2XOmOXW8iskCeSQfxhMBwNnJL-BY5uC4SG--k_sLPt5B28l4vWXsmORpiRXrN1hEx90h2izCmfOdMit-hgdN1Fq_2EQK5yuvm8_xP4X2KhgOCN5Bh2ztqQC-3zzPwRjD8Vg6JUdFBWxWwJWOdM_HN7lleCsIoP02tqMxIA7Vt_P9K-DexLggZXsh8Jo399N1iXDoSWaM0yIG9HX5vxWXvQPeWMkukZD5UkJsZ0htLN2kUCreau6rZ8mtp1Dw2Lv6Pk6Ahq8SE5U4SNtzpArFeqSQI5zoETnfzOvIeNuSgn1JwazhqN-F-Srf1LKFGJFxxb15BTdZK4Ux5vbOy85jDnYDMar-BdSbzZ9Vv8jrX9Fc72Xvcg4cbA5A32PuSzuUP5BSHhOoDrAUC2U_3qLMHOgNP9OH7yLs1bdtYRoZJTWxQkz6r5bJ-nE8zMkKdtG-9QuSzRX_9uGkteLbtXF-8pmKnVMy1glREKu7ZgfF4n1IG6nfY6VMeFzRPUW1CM46JvWj828RV3pmwwtcbLldR7wkAMsM6V12syLPBJ9E57Nth109mkEhh5T-_BAsaKlXliZRkVixp21Wp8OPX6VRhfct-geSz3qkEBgw0v2fM9Mu3tZSsY73IbrRAwq7Atg_ci0lYXJy5cTfitVtDXEKu_QWgwfhz2WmEv9MRDMdJX_-Trr-yV5Opw0lleUe6Js5TFlaxdrItq8QFZhL25LIKxEZ3l2J_iGqQ_J4PhVCpgf9Ffsy_1WX4qN618Sm3mlgISHNAhQSsy6otEjEd09UPVbE8P_llE5gnCMoImC3_UIRsiXQFReCz4v4ignKNpc-n12rEgkfF0CYVHefLt5INhQY9M5jnJieDuWl3Z43jYFj2gMmzMvyeKPZ5EiAHhSD4=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GC6y5bfOdkY-DzFV6fnj6hQc4zdrLqysZ8NNTj76XXR4foJDHrsA1LIRq3vVf7YK4VFXzwifqy5TvH8AWzfMMyUroB_dlHHnb_weiW0BKHy1EwKs-djpvFDFWgiK03UzAnH15RxWIiiYigTtwo4SVsCliwSJY4hHuAeSJf5EJ-k2XOmOXW8iskCeSQfxhMBwNnJL-BY5uC4SG--k_sLPt5B28l4vWXsmORpiRXrN1hEx90h2izCmfOdMit-hgdN1Fq_2EQK5yuvm8_xP4X2KhgOCN5Bh2ztqQC-3zzPwRjD8Vg6JUdFBWxWwJWOdM_HN7lleCsIoP02tqMxIA7Vt_P9K-DexLggZXsh8Jo399N1iXDoSWaM0yIG9HX5vxWXvQPeWMkukZD5UkJsZ0htLN2kUCreau6rZ8mtp1Dw2Lv6Pk6Ahq8SE5U4SNtzpArFeqSQI5zoETnfzOvIeNuSgn1JwazhqN-F-Srf1LKFGJFxxb15BTdZK4Ux5vbOy85jDnYDMar-BdSbzZ9Vv8jrX9Fc72Xvcg4cbA5A32PuSzuUP5BSHhOoDrAUC2U_3qLMHOgNP9OH7yLs1bdtYRoZJTWxQkz6r5bJ-nE8zMkKdtG-9QuSzRX_9uGkteLbtXF-8pmKnVMy1glREKu7ZgfF4n1IG6nfY6VMeFzRPUW1CM46JvWj828RV3pmwwtcbLldR7wkAMsM6V12syLPBJ9E57Nth109mkEhh5T-_BAsaKlXliZRkVixp21Wp8OPX6VRhfct-geSz3qkEBgw0v2fM9Mu3tZSsY73IbrRAwq7Atg_ci0lYXJy5cTfitVtDXEKu_QWgwfhz2WmEv9MRDMdJX_-Trr-yV5Opw0lleUe6Js5TFlaxdrItq8QFZhL25LIKxEZ3l2J_iGqQ_J4PhVCpgf9Ffsy_1WX4qN618Sm3mlgISHNAhQSsy6otEjEd09UPVbE8P_llE5gnCMoImC3_UIRsiXQFReCz4v4ignKNpc-n12rEgkfF0CYVHefLt5INhQY9M5jnJieDuWl3Z43jYFj2gMmzMvyeKPZ5EiAHhSD4=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GC6y5bfOdkY-DzFV6fnj6hQc4zdrLqysZ8NNTj76XXR4foJDHrsA1LIRq3vVf7YK4VFXzwifqy5TvH8AWzfMMyUroB_dlHHnb_weiW0BKHy1EwKs-djpvFDFWgiK03UzAnH15RxWIiiYigTtwo4SVsCliwSJY4hHuAeSJf5EJ-k2XOmOXW8iskCeSQfxhMBwNnJL-BY5uC4SG--k_sLPt5B28l4vWXsmORpiRXrN1hEx90h2izCmfOdMit-hgdN1Fq_2EQK5yuvm8_xP4X2KhgOCN5Bh2ztqQC-3zzPwRjD8Vg6JUdFBWxWwJWOdM_HN7lleCsIoP02tqMxIA7Vt_P9K-DexLggZXsh8Jo399N1iXDoSWaM0yIG9HX5vxWXvQPeWMkukZD5UkJsZ0htLN2kUCreau6rZ8mtp1Dw2Lv6Pk6Ahq8SE5U4SNtzpArFeqSQI5zoETnfzOvIeNuSgn1JwazhqN-F-Srf1LKFGJFxxb15BTdZK4Ux5vbOy85jDnYDMar-BdSbzZ9Vv8jrX9Fc72Xvcg4cbA5A32PuSzuUP5BSHhOoDrAUC2U_3qLMHOgNP9OH7yLs1bdtYRoZJTWxQkz6r5bJ-nE8zMkKdtG-9QuSzRX_9uGkteLbtXF-8pmKnVMy1glREKu7ZgfF4n1IG6nfY6VMeFzRPUW1CM46JvWj828RV3pmwwtcbLldR7wkAMsM6V12syLPBJ9E57Nth109mkEhh5T-_BAsaKlXliZRkVixp21Wp8OPX6VRhfct-geSz3qkEBgw0v2fM9Mu3tZSsY73IbrRAwq7Atg_ci0lYXJy5cTfitVtDXEKu_QWgwfhz2WmEv9MRDMdJX_-Trr-yV5Opw0lleUe6Js5TFlaxdrItq8QFZhL25LIKxEZ3l2J_iGqQ_J4PhVCpgf9Ffsy_1WX4qN618Sm3mlgISHNAhQSsy6otEjEd09UPVbE8P_llE5gnCMoImC3_UIRsiXQFReCz4v4ignKNpc-n12rEgkfF0CYVHefLt5INhQY9M5jnJieDuWl3Z43jYFj2gMmzMvyeKPZ5EiAHhSD4=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1358.83,
            "totalPrice": 1122
          },
          {
            "allPassengerTotalPrice": 1126,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8xY7jbjQ9yQ_gCR3k4KwDtwF1fwMx4D9TbOnXfTDsKuyFWjL0YRChYWBD7r33dB59WYplpq4y2lI6itojKw2a0YT7pvkgAKQutodnxIicTeexqoSnA1Jdx5DriMv-M7Iinow9PgBEJs2jzwyT2Wgah-Sg8Y5rEB1_8g6oFiLBKs-qa5r5STLZgZiLSmtyfZ0-kqOFMJ8YveiSykoH8zR1C3VHdTWlFV9aezhRNyfzEb5Kawj71JKM2THszn_ve-lStEhZMnhRH9Zr1JuGtLiYmM0ObmLEv9P-PrSagW9MUmuzns4Psab0-lWjn2o1SIozxZRoop90mckU_YUsJQx3RftsilER_SMpnoQK3KGAJ0CuXzY2lelkOzmP9QFq9pwF1pVAiP0i-OBrQwfNhOkESW7w7UgI-JSfAyfP0-axvdLyzf6n4ok8w7dcmwRChINL-lg4F13-JPPpELO5ZxFbsovG1l-nGA4MPN1F0a6YJFlxJSWS2vnO8AFxAGH_OHKSuI2Yo3P3-9kOeg8ruu7ep0wJNreIBaYbRCOfQLnS4FTQCL5Ku4Y7lhR82bv9vKc0ITxjYHrl3IfbauAoH0Wv7_f6I9aWVBdAH4ehN2vEnPy-uR1vZqgHzYbCSH34sxbuEGqDNh2NXDC7P_W-6eIE5Xj9D8Q82UgzEAVF3lxwUUQtlYzl7VchcrI4M-R6tIMqDm5NqMjsXjNZHy4BzrCBncdg_6mj5nwJnNMGg7hK788l6_sdqqkq6i3oI85dVT4yJIYFKS1gpOLK8xVQ7sDt1EC3NJa6h-ce6Pi_5Nigdm1NYz5lOA269h0JbNPOa_SxOZNekFT0pBbqIvVPM4ssGA8_m9KNVrybA23KLD-aNgwI2ft3hXz47TmNnNGSVcPCVsKaboP-lmLIOC7krYZ5i9YHroHQKtBlYcOHeBhQgTEnsX4Jf5mLEA2fuZfhVrw65ntmmff0qB87CYs1L2wMontKcSMTWi7vwT7xGb7d04LEnGLjko0_mhjOyO04TU_e8zV7SNbTe5qJdhacxJrTFQKiGYj-WLjK7IJioKrDVU2JP5A0OIHHYeydNLEWGqwtg4twLYE8LZGU0Z0Gcfxzg==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8xY7jbjQ9yQ_gCR3k4KwDtwF1fwMx4D9TbOnXfTDsKuyFWjL0YRChYWBD7r33dB59WYplpq4y2lI6itojKw2a0YT7pvkgAKQutodnxIicTeexqoSnA1Jdx5DriMv-M7Iinow9PgBEJs2jzwyT2Wgah-Sg8Y5rEB1_8g6oFiLBKs-qa5r5STLZgZiLSmtyfZ0-kqOFMJ8YveiSykoH8zR1C3VHdTWlFV9aezhRNyfzEb5Kawj71JKM2THszn_ve-lStEhZMnhRH9Zr1JuGtLiYmM0ObmLEv9P-PrSagW9MUmuzns4Psab0-lWjn2o1SIozxZRoop90mckU_YUsJQx3RftsilER_SMpnoQK3KGAJ0CuXzY2lelkOzmP9QFq9pwF1pVAiP0i-OBrQwfNhOkESW7w7UgI-JSfAyfP0-axvdLyzf6n4ok8w7dcmwRChINL-lg4F13-JPPpELO5ZxFbsovG1l-nGA4MPN1F0a6YJFlxJSWS2vnO8AFxAGH_OHKSuI2Yo3P3-9kOeg8ruu7ep0wJNreIBaYbRCOfQLnS4FTQCL5Ku4Y7lhR82bv9vKc0ITxjYHrl3IfbauAoH0Wv7_f6I9aWVBdAH4ehN2vEnPy-uR1vZqgHzYbCSH34sxbuEGqDNh2NXDC7P_W-6eIE5Xj9D8Q82UgzEAVF3lxwUUQtlYzl7VchcrI4M-R6tIMqDm5NqMjsXjNZHy4BzrCBncdg_6mj5nwJnNMGg7hK788l6_sdqqkq6i3oI85dVT4yJIYFKS1gpOLK8xVQ7sDt1EC3NJa6h-ce6Pi_5Nigdm1NYz5lOA269h0JbNPOa_SxOZNekFT0pBbqIvVPM4ssGA8_m9KNVrybA23KLD-aNgwI2ft3hXz47TmNnNGSVcPCVsKaboP-lmLIOC7krYZ5i9YHroHQKtBlYcOHeBhQgTEnsX4Jf5mLEA2fuZfhVrw65ntmmff0qB87CYs1L2wMontKcSMTWi7vwT7xGb7d04LEnGLjko0_mhjOyO04TU_e8zV7SNbTe5qJdhacxJrTFQKiGYj-WLjK7IJioKrDVU2JP5A0OIHHYeydNLEWGqwtg4twLYE8LZGU0Z0Gcfxzg==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8xY7jbjQ9yQ_gCR3k4KwDtwF1fwMx4D9TbOnXfTDsKuyFWjL0YRChYWBD7r33dB59WYplpq4y2lI6itojKw2a0YT7pvkgAKQutodnxIicTeexqoSnA1Jdx5DriMv-M7Iinow9PgBEJs2jzwyT2Wgah-Sg8Y5rEB1_8g6oFiLBKs-qa5r5STLZgZiLSmtyfZ0-kqOFMJ8YveiSykoH8zR1C3VHdTWlFV9aezhRNyfzEb5Kawj71JKM2THszn_ve-lStEhZMnhRH9Zr1JuGtLiYmM0ObmLEv9P-PrSagW9MUmuzns4Psab0-lWjn2o1SIozxZRoop90mckU_YUsJQx3RftsilER_SMpnoQK3KGAJ0CuXzY2lelkOzmP9QFq9pwF1pVAiP0i-OBrQwfNhOkESW7w7UgI-JSfAyfP0-axvdLyzf6n4ok8w7dcmwRChINL-lg4F13-JPPpELO5ZxFbsovG1l-nGA4MPN1F0a6YJFlxJSWS2vnO8AFxAGH_OHKSuI2Yo3P3-9kOeg8ruu7ep0wJNreIBaYbRCOfQLnS4FTQCL5Ku4Y7lhR82bv9vKc0ITxjYHrl3IfbauAoH0Wv7_f6I9aWVBdAH4ehN2vEnPy-uR1vZqgHzYbCSH34sxbuEGqDNh2NXDC7P_W-6eIE5Xj9D8Q82UgzEAVF3lxwUUQtlYzl7VchcrI4M-R6tIMqDm5NqMjsXjNZHy4BzrCBncdg_6mj5nwJnNMGg7hK788l6_sdqqkq6i3oI85dVT4yJIYFKS1gpOLK8xVQ7sDt1EC3NJa6h-ce6Pi_5Nigdm1NYz5lOA269h0JbNPOa_SxOZNekFT0pBbqIvVPM4ssGA8_m9KNVrybA23KLD-aNgwI2ft3hXz47TmNnNGSVcPCVsKaboP-lmLIOC7krYZ5i9YHroHQKtBlYcOHeBhQgTEnsX4Jf5mLEA2fuZfhVrw65ntmmff0qB87CYs1L2wMontKcSMTWi7vwT7xGb7d04LEnGLjko0_mhjOyO04TU_e8zV7SNbTe5qJdhacxJrTFQKiGYj-WLjK7IJioKrDVU2JP5A0OIHHYeydNLEWGqwtg4twLYE8LZGU0Z0Gcfxzg==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1360.61,
            "totalPrice": 1126
          },
          {
            "allPassengerTotalPrice": 1126,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPsoTufBaRCJ_-RRXLvEXnllUNFdQOvm3BX4iqCwMpMOQAFMazEe1rVF51eyHCp-jLHj4L3zRBCkOZBMZCZmliIgQeHQNp7F-ujlF7m_bUbHetoDDmbkJDL-rlFOLw0kjlG8Y0SG_P9RfCt1K6DvL867qHRwv_yrwmpi4TA0lpvk0X2BqlUWZj9GkubJ14zq3P0TbQoHCoaqxp4-GIuipPsApdCxjd-oY9aw3DSWWRr-8WoH15nxIEA1h_F8EdzgxnuZkM2a-K6O0m9fWE--Qfl_G_4BXfDElEpe-buRz5eL15sPwLV63vq8nvDm_no0ZTZnpS_17HsLlsdKyy6XtnGSrVhawOj7la1n1rPsNBk3MBx0-7uI_mZl43mmdHh3xmZVw4pmzPu4Lg0S-SScZmUSgN84TcYEBLxfkesy0cnf-jiRqOzUrgqv14p51BwD-wl9S6MG4lrjOl-w6cqympdJ_tQB4PIwZe5qO3_QgraiPRkSqz0reITrXflcyN9aKBnIBcAQt-44rQnf_Xar41eRwtMLf-0bBn7-fdIWMaWVrZdnzR9BlCTzgyJvR8WvLLqwlIcyY0lDNNgs2bTK1E31FrObueRTlIXT6xdThee_wWczN2G_YhB29hT118ooh8GdF-G_J7C86huxAxw4bBOqSktw9s84vjwRi3mlcFVp3dsu9CFLLIUXdgc_O39mN5OdVuC_zv0jiHjnYwVEibL-3akV0xxorpmUEdcMbZzwx5srA7GlIlGQOdM7a8E8YE-JgJY-yZB9qJ2AwVQ29GB6BS5yIiqXq6Si-flZD_qCRU6SJGWPXRVVlVBa7Z3np2WGViLD4wzlpGC0iUWts5MQmHiDJycxTTmxEcQeBmVXvSD-6iHsXQDUZUjMF6Ixe85dUlpZJ2H19uNKWFN35sWg6vNVdL4LYv63CIfcMvo0EA2MKUu7usaAha9Ee4QxKRhC2nbcfVVnp5DkGszKMh5WAHUYCmY-nN4neT6zMLcIWy881K1xmWH713j4sc-NyN1fW_cQlEJ_VirOV63ZGQHxme_roBpuQlgskjDcXzqVNGBBzNxqgQMl8KKB62jB0EJABgZCtsOckt8g-5P8TQQ==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPsoTufBaRCJ_-RRXLvEXnllUNFdQOvm3BX4iqCwMpMOQAFMazEe1rVF51eyHCp-jLHj4L3zRBCkOZBMZCZmliIgQeHQNp7F-ujlF7m_bUbHetoDDmbkJDL-rlFOLw0kjlG8Y0SG_P9RfCt1K6DvL867qHRwv_yrwmpi4TA0lpvk0X2BqlUWZj9GkubJ14zq3P0TbQoHCoaqxp4-GIuipPsApdCxjd-oY9aw3DSWWRr-8WoH15nxIEA1h_F8EdzgxnuZkM2a-K6O0m9fWE--Qfl_G_4BXfDElEpe-buRz5eL15sPwLV63vq8nvDm_no0ZTZnpS_17HsLlsdKyy6XtnGSrVhawOj7la1n1rPsNBk3MBx0-7uI_mZl43mmdHh3xmZVw4pmzPu4Lg0S-SScZmUSgN84TcYEBLxfkesy0cnf-jiRqOzUrgqv14p51BwD-wl9S6MG4lrjOl-w6cqympdJ_tQB4PIwZe5qO3_QgraiPRkSqz0reITrXflcyN9aKBnIBcAQt-44rQnf_Xar41eRwtMLf-0bBn7-fdIWMaWVrZdnzR9BlCTzgyJvR8WvLLqwlIcyY0lDNNgs2bTK1E31FrObueRTlIXT6xdThee_wWczN2G_YhB29hT118ooh8GdF-G_J7C86huxAxw4bBOqSktw9s84vjwRi3mlcFVp3dsu9CFLLIUXdgc_O39mN5OdVuC_zv0jiHjnYwVEibL-3akV0xxorpmUEdcMbZzwx5srA7GlIlGQOdM7a8E8YE-JgJY-yZB9qJ2AwVQ29GB6BS5yIiqXq6Si-flZD_qCRU6SJGWPXRVVlVBa7Z3np2WGViLD4wzlpGC0iUWts5MQmHiDJycxTTmxEcQeBmVXvSD-6iHsXQDUZUjMF6Ixe85dUlpZJ2H19uNKWFN35sWg6vNVdL4LYv63CIfcMvo0EA2MKUu7usaAha9Ee4QxKRhC2nbcfVVnp5DkGszKMh5WAHUYCmY-nN4neT6zMLcIWy881K1xmWH713j4sc-NyN1fW_cQlEJ_VirOV63ZGQHxme_roBpuQlgskjDcXzqVNGBBzNxqgQMl8KKB62jB0EJABgZCtsOckt8g-5P8TQQ==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPsoTufBaRCJ_-RRXLvEXnllUNFdQOvm3BX4iqCwMpMOQAFMazEe1rVF51eyHCp-jLHj4L3zRBCkOZBMZCZmliIgQeHQNp7F-ujlF7m_bUbHetoDDmbkJDL-rlFOLw0kjlG8Y0SG_P9RfCt1K6DvL867qHRwv_yrwmpi4TA0lpvk0X2BqlUWZj9GkubJ14zq3P0TbQoHCoaqxp4-GIuipPsApdCxjd-oY9aw3DSWWRr-8WoH15nxIEA1h_F8EdzgxnuZkM2a-K6O0m9fWE--Qfl_G_4BXfDElEpe-buRz5eL15sPwLV63vq8nvDm_no0ZTZnpS_17HsLlsdKyy6XtnGSrVhawOj7la1n1rPsNBk3MBx0-7uI_mZl43mmdHh3xmZVw4pmzPu4Lg0S-SScZmUSgN84TcYEBLxfkesy0cnf-jiRqOzUrgqv14p51BwD-wl9S6MG4lrjOl-w6cqympdJ_tQB4PIwZe5qO3_QgraiPRkSqz0reITrXflcyN9aKBnIBcAQt-44rQnf_Xar41eRwtMLf-0bBn7-fdIWMaWVrZdnzR9BlCTzgyJvR8WvLLqwlIcyY0lDNNgs2bTK1E31FrObueRTlIXT6xdThee_wWczN2G_YhB29hT118ooh8GdF-G_J7C86huxAxw4bBOqSktw9s84vjwRi3mlcFVp3dsu9CFLLIUXdgc_O39mN5OdVuC_zv0jiHjnYwVEibL-3akV0xxorpmUEdcMbZzwx5srA7GlIlGQOdM7a8E8YE-JgJY-yZB9qJ2AwVQ29GB6BS5yIiqXq6Si-flZD_qCRU6SJGWPXRVVlVBa7Z3np2WGViLD4wzlpGC0iUWts5MQmHiDJycxTTmxEcQeBmVXvSD-6iHsXQDUZUjMF6Ixe85dUlpZJ2H19uNKWFN35sWg6vNVdL4LYv63CIfcMvo0EA2MKUu7usaAha9Ee4QxKRhC2nbcfVVnp5DkGszKMh5WAHUYCmY-nN4neT6zMLcIWy881K1xmWH713j4sc-NyN1fW_cQlEJ_VirOV63ZGQHxme_roBpuQlgskjDcXzqVNGBBzNxqgQMl8KKB62jB0EJABgZCtsOckt8g-5P8TQQ==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1361.27,
            "totalPrice": 1126
          },
          {
            "allPassengerTotalPrice": 1129,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGAjWoMlTk7w9EH2slE71XC5JO6FShxk6e3F8yFJh35kGzhvyo65dxSWtBTUi7vISD2SkvhLyTK48wu4zWD1mjiE5U-StYDTNyWj27rNU7nAE6YvCrbXbrGaZNuQBYo5o8jkRDNST7E8luZvysVQjRjgd4Gwho2tGtEKZbzRiS0uf0MpBGpFC74lwxrNEFZfqxtXwldHx8n2_7N7LPdR45gtd_YaWZELNMHmh8L0NSOnQGURdWxL2lGpIEzPJeBWnZNDfglRTdwB8XBDQwbL7wUdFupdmgFyKoZ4QAXmnJ8MwiSVVXSwW9iOh5vZE13IOkV-sm3b_k332CeFppLmU11ezXrx9BvKh-zDg7Pz9ayiV4s2FVm5PWbRDtmlL777VytTEOxuaitIdDnH6SXX6PAQcUnOI2JbLbyDibMzx2OxyrXN4-8c6e-YgdHwYvWFaD-Z3heTLSdRp38M4kKrA3vXYzS1Ok_um9CmAWJgQ5NwZ8j7g0LH2Sd8qzpv72A4W4eZClcoCt4jAsdL7-RYBOw7hmuHvjR3XKDgr10z2wDx8yvMKulyft1CT-RpRBZGHfacHPDnVdiTL3DspQFtG8Body2MxEJ1qjd9a0dfW7mpzP4W6G72sS_owqa5u5pYX6csJ2hB2X2GGGM8FoOT14W3QjbxSbAMXNtGdOoMkW5SX8qDBldYJpmxragZ4d2TUvMLaf_3L_0KCyrFC-mGc67lgYn_VvaySwaXtRLeq2PxkwK88pcElfEtw6k6RSHI00b9a692UtpgA84VUDd7WUt2pQlQKeAiNBfsh9qrsYAn2mHYsdHoqfKA1BRMlhBplpCBPrUiZwKZmAyuILdtmWg5QcXdPwFrMkZV_AM9jxsX317xmOFpgaaTaTRf-C9x57ZXPQBnWMQcB_pa-wz4mmZW4Q9OPfBGs7YbPx8KA6ApQsLJLHdCg3FwHTGlGU8PzuMTO88B5wG4VBo8MaQGwhBcJq0kiZLsjYS73Zt1YMfEKFdrM-_navBMpnmwEBKr3yXn7DCuoh6Dh3pp8kzilsg==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGAjWoMlTk7w9EH2slE71XC5JO6FShxk6e3F8yFJh35kGzhvyo65dxSWtBTUi7vISD2SkvhLyTK48wu4zWD1mjiE5U-StYDTNyWj27rNU7nAE6YvCrbXbrGaZNuQBYo5o8jkRDNST7E8luZvysVQjRjgd4Gwho2tGtEKZbzRiS0uf0MpBGpFC74lwxrNEFZfqxtXwldHx8n2_7N7LPdR45gtd_YaWZELNMHmh8L0NSOnQGURdWxL2lGpIEzPJeBWnZNDfglRTdwB8XBDQwbL7wUdFupdmgFyKoZ4QAXmnJ8MwiSVVXSwW9iOh5vZE13IOkV-sm3b_k332CeFppLmU11ezXrx9BvKh-zDg7Pz9ayiV4s2FVm5PWbRDtmlL777VytTEOxuaitIdDnH6SXX6PAQcUnOI2JbLbyDibMzx2OxyrXN4-8c6e-YgdHwYvWFaD-Z3heTLSdRp38M4kKrA3vXYzS1Ok_um9CmAWJgQ5NwZ8j7g0LH2Sd8qzpv72A4W4eZClcoCt4jAsdL7-RYBOw7hmuHvjR3XKDgr10z2wDx8yvMKulyft1CT-RpRBZGHfacHPDnVdiTL3DspQFtG8Body2MxEJ1qjd9a0dfW7mpzP4W6G72sS_owqa5u5pYX6csJ2hB2X2GGGM8FoOT14W3QjbxSbAMXNtGdOoMkW5SX8qDBldYJpmxragZ4d2TUvMLaf_3L_0KCyrFC-mGc67lgYn_VvaySwaXtRLeq2PxkwK88pcElfEtw6k6RSHI00b9a692UtpgA84VUDd7WUt2pQlQKeAiNBfsh9qrsYAn2mHYsdHoqfKA1BRMlhBplpCBPrUiZwKZmAyuILdtmWg5QcXdPwFrMkZV_AM9jxsX317xmOFpgaaTaTRf-C9x57ZXPQBnWMQcB_pa-wz4mmZW4Q9OPfBGs7YbPx8KA6ApQsLJLHdCg3FwHTGlGU8PzuMTO88B5wG4VBo8MaQGwhBcJq0kiZLsjYS73Zt1YMfEKFdrM-_navBMpnmwEBKr3yXn7DCuoh6Dh3pp8kzilsg==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGAjWoMlTk7w9EH2slE71XC5JO6FShxk6e3F8yFJh35kGzhvyo65dxSWtBTUi7vISD2SkvhLyTK48wu4zWD1mjiE5U-StYDTNyWj27rNU7nAE6YvCrbXbrGaZNuQBYo5o8jkRDNST7E8luZvysVQjRjgd4Gwho2tGtEKZbzRiS0uf0MpBGpFC74lwxrNEFZfqxtXwldHx8n2_7N7LPdR45gtd_YaWZELNMHmh8L0NSOnQGURdWxL2lGpIEzPJeBWnZNDfglRTdwB8XBDQwbL7wUdFupdmgFyKoZ4QAXmnJ8MwiSVVXSwW9iOh5vZE13IOkV-sm3b_k332CeFppLmU11ezXrx9BvKh-zDg7Pz9ayiV4s2FVm5PWbRDtmlL777VytTEOxuaitIdDnH6SXX6PAQcUnOI2JbLbyDibMzx2OxyrXN4-8c6e-YgdHwYvWFaD-Z3heTLSdRp38M4kKrA3vXYzS1Ok_um9CmAWJgQ5NwZ8j7g0LH2Sd8qzpv72A4W4eZClcoCt4jAsdL7-RYBOw7hmuHvjR3XKDgr10z2wDx8yvMKulyft1CT-RpRBZGHfacHPDnVdiTL3DspQFtG8Body2MxEJ1qjd9a0dfW7mpzP4W6G72sS_owqa5u5pYX6csJ2hB2X2GGGM8FoOT14W3QjbxSbAMXNtGdOoMkW5SX8qDBldYJpmxragZ4d2TUvMLaf_3L_0KCyrFC-mGc67lgYn_VvaySwaXtRLeq2PxkwK88pcElfEtw6k6RSHI00b9a692UtpgA84VUDd7WUt2pQlQKeAiNBfsh9qrsYAn2mHYsdHoqfKA1BRMlhBplpCBPrUiZwKZmAyuILdtmWg5QcXdPwFrMkZV_AM9jxsX317xmOFpgaaTaTRf-C9x57ZXPQBnWMQcB_pa-wz4mmZW4Q9OPfBGs7YbPx8KA6ApQsLJLHdCg3FwHTGlGU8PzuMTO88B5wG4VBo8MaQGwhBcJq0kiZLsjYS73Zt1YMfEKFdrM-_navBMpnmwEBKr3yXn7DCuoh6Dh3pp8kzilsg==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1363.76,
            "totalPrice": 1129
          },
          {
            "allPassengerTotalPrice": 1130,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gp3foi8FT5urdR6q-A_y8uJozjZB6k9EgKZmNimmHs_pmBQwzO3jIp-TBREP1XLz0OttvAdfEWY-1KepiM5MgN0ucKSkuRS0CRRST7gy64QkDpV0MoC6bfPJuy5QJ5eAtOdfOVO9gGmhZMtoRhVjck07rMSG9OudCtOo-GZKLjR0gJIem9J9p--4bddn-aIbhs1iFhPzhwukjOox9Gfwgi0WPWoK7gXdlqcUXnpPhGTddYIA7LMurxvZcWax3P4doUujOgVN8sbMAWvHzT21QYqoiY226hoajTijW1Pahq3ZKvhbn-hOAw6qejJdZ5vsFEsVphdmrFZj8ELblNcyoQHZQrva5DG11FHazOf6WUuLYM_E7e7wi4yWTdgZG-mC7YuGMB61QS9ELeAiIWJw7q1HqnIz9xNtXJX1rmHX9kps6A7b2sviBWFACVKCaf58DzNmw_euE62asY3EFnBsRxWN2f1EKmzWB0guFiz3sPKqbwOGWvIsvDbRUCIYw1DzYHThn9NT8kuEWeAbPrU0ZybYv9Dsi18t0v9ociLsb99c38tn6265g8O50zAK-tCMZn9B14gBZiwy7KsmIxwi8gdpJbMlEFs7LNjHRwh2gHFxDAiNvunuLQ0MyobxdkytufwdGdVKwN4PDbRiTbQWgSQPf01BHHNNu5NATkqVUyqauuz1Gd1tnoKSAcdSnlLRh3tU4qus5BuK_yBmJfC3W0lib8jlpEBcM6Zpgod538ftiXk2Vfc0YMQGzQnEXRKg0TVhrbpL1g_G_gTPPr5qORfjhoqEFiYbZrVTulZ9Lq3mRdHgTbgSxSWTMJV0qEcNQsf0PJAe8eL7HG8Fhy-Ypv6Z7rKN8w15P9zfyLeSwhl3jR2Ol8GQxJ62OK3p_kAdnxTDNveJTM2oaUZH2gjiABpsnBCwQFnwX9EPwIxlc1HN_umBGsFLZRtM3ZUWK2QpL3TBLV9AVGJ5ZYIux6J2c-tA5WwdZ52uqOfnTuI1xwAx1Q3oxql64YRTaiFctZfJVQzMCZj24Bb_-mHJ0P1KFl7DQaddbkbeUy8sRYhooGgI=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gp3foi8FT5urdR6q-A_y8uJozjZB6k9EgKZmNimmHs_pmBQwzO3jIp-TBREP1XLz0OttvAdfEWY-1KepiM5MgN0ucKSkuRS0CRRST7gy64QkDpV0MoC6bfPJuy5QJ5eAtOdfOVO9gGmhZMtoRhVjck07rMSG9OudCtOo-GZKLjR0gJIem9J9p--4bddn-aIbhs1iFhPzhwukjOox9Gfwgi0WPWoK7gXdlqcUXnpPhGTddYIA7LMurxvZcWax3P4doUujOgVN8sbMAWvHzT21QYqoiY226hoajTijW1Pahq3ZKvhbn-hOAw6qejJdZ5vsFEsVphdmrFZj8ELblNcyoQHZQrva5DG11FHazOf6WUuLYM_E7e7wi4yWTdgZG-mC7YuGMB61QS9ELeAiIWJw7q1HqnIz9xNtXJX1rmHX9kps6A7b2sviBWFACVKCaf58DzNmw_euE62asY3EFnBsRxWN2f1EKmzWB0guFiz3sPKqbwOGWvIsvDbRUCIYw1DzYHThn9NT8kuEWeAbPrU0ZybYv9Dsi18t0v9ociLsb99c38tn6265g8O50zAK-tCMZn9B14gBZiwy7KsmIxwi8gdpJbMlEFs7LNjHRwh2gHFxDAiNvunuLQ0MyobxdkytufwdGdVKwN4PDbRiTbQWgSQPf01BHHNNu5NATkqVUyqauuz1Gd1tnoKSAcdSnlLRh3tU4qus5BuK_yBmJfC3W0lib8jlpEBcM6Zpgod538ftiXk2Vfc0YMQGzQnEXRKg0TVhrbpL1g_G_gTPPr5qORfjhoqEFiYbZrVTulZ9Lq3mRdHgTbgSxSWTMJV0qEcNQsf0PJAe8eL7HG8Fhy-Ypv6Z7rKN8w15P9zfyLeSwhl3jR2Ol8GQxJ62OK3p_kAdnxTDNveJTM2oaUZH2gjiABpsnBCwQFnwX9EPwIxlc1HN_umBGsFLZRtM3ZUWK2QpL3TBLV9AVGJ5ZYIux6J2c-tA5WwdZ52uqOfnTuI1xwAx1Q3oxql64YRTaiFctZfJVQzMCZj24Bb_-mHJ0P1KFl7DQaddbkbeUy8sRYhooGgI=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gp3foi8FT5urdR6q-A_y8uJozjZB6k9EgKZmNimmHs_pmBQwzO3jIp-TBREP1XLz0OttvAdfEWY-1KepiM5MgN0ucKSkuRS0CRRST7gy64QkDpV0MoC6bfPJuy5QJ5eAtOdfOVO9gGmhZMtoRhVjck07rMSG9OudCtOo-GZKLjR0gJIem9J9p--4bddn-aIbhs1iFhPzhwukjOox9Gfwgi0WPWoK7gXdlqcUXnpPhGTddYIA7LMurxvZcWax3P4doUujOgVN8sbMAWvHzT21QYqoiY226hoajTijW1Pahq3ZKvhbn-hOAw6qejJdZ5vsFEsVphdmrFZj8ELblNcyoQHZQrva5DG11FHazOf6WUuLYM_E7e7wi4yWTdgZG-mC7YuGMB61QS9ELeAiIWJw7q1HqnIz9xNtXJX1rmHX9kps6A7b2sviBWFACVKCaf58DzNmw_euE62asY3EFnBsRxWN2f1EKmzWB0guFiz3sPKqbwOGWvIsvDbRUCIYw1DzYHThn9NT8kuEWeAbPrU0ZybYv9Dsi18t0v9ociLsb99c38tn6265g8O50zAK-tCMZn9B14gBZiwy7KsmIxwi8gdpJbMlEFs7LNjHRwh2gHFxDAiNvunuLQ0MyobxdkytufwdGdVKwN4PDbRiTbQWgSQPf01BHHNNu5NATkqVUyqauuz1Gd1tnoKSAcdSnlLRh3tU4qus5BuK_yBmJfC3W0lib8jlpEBcM6Zpgod538ftiXk2Vfc0YMQGzQnEXRKg0TVhrbpL1g_G_gTPPr5qORfjhoqEFiYbZrVTulZ9Lq3mRdHgTbgSxSWTMJV0qEcNQsf0PJAe8eL7HG8Fhy-Ypv6Z7rKN8w15P9zfyLeSwhl3jR2Ol8GQxJ62OK3p_kAdnxTDNveJTM2oaUZH2gjiABpsnBCwQFnwX9EPwIxlc1HN_umBGsFLZRtM3ZUWK2QpL3TBLV9AVGJ5ZYIux6J2c-tA5WwdZ52uqOfnTuI1xwAx1Q3oxql64YRTaiFctZfJVQzMCZj24Bb_-mHJ0P1KFl7DQaddbkbeUy8sRYhooGgI=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1364.91,
            "totalPrice": 1130
          },
          {
            "allPassengerTotalPrice": 1134,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZpHh5ILhhgLtdgSkGXKjyrQLvMItS25ubT-j7MkaADJTH30yTPnhMyRMtx-88DdP8xRlaltuM7VBq03UuS8vz7GlKC70zcUZCSxmhfTu-AJ26PMEpQq2QXGHT2nVDYsweJMi04Pu5n53GxexrSwVevJLRg6_UH0laIp-ucXk73uBy9VlmQm7n-8BkfYkOkiOvDts9j9KbzF9r_MI4ZJor0m9wfmq6UrAsD_fZP2qV4Y5LQIA4E6enzMdTS4_hgD4Pi9SCTfeLTAtpTsZc7iYdFOsOVwOGwJfwN2e4wSBT1-y5zW_0sf3LhoNLtqMm16VDRo1Ol8TSxEkQvEcBWa1d5h_5ZLvunuxEIuKUrRA8sig6_YI7jb7-CiJfEhqT8BEpsaeOz8x25hrwdTZfeF7iqolZtx7KAudjDwx6pHdG3RCDWLNtwtkmRWt-0nb3kPTpQUIn3mXMESKDlcjJ6Bfw8RIKz19A4vxKW-oID0CY-U9bMDa0A6jkOV2StpI9zR2thFpeoxUFhiGJCHwJcIDWpO4GyFn87Rbgr62Qk5TzBtn6v20NnXq5QK1gXOAIJzljPxvX1I7GkfgzEDxx6XsSQ8vgM5OLrvlOLO8_arpNt5D32V45tdX4gOuhpC3BFpQqn7NNwbhRA1aQr-urQL_HfWIkRCuuGAZheZUdw0TFpW9V370OqtxhWmu_oZxi6zh6p5tCAw3q-_1Ou5xJCHDlPDK_q2O8HQLwi12vEurOb7m-g_PVIk65pmqRfZSLDRei74X1z1PaQaLjLcrHg6mKqsakWKh2CZODZH4OkRbYzaFLoNxe55uXLi-YFJWDyQUJpOmB3sI7n2_PBuJmXcPuZz_AndGmP5b_BGptFV-5l0qTwfr-TcxRFnWr99-P-wl5B-MVkuMJoPlsGMjbRWJaKkbJGl2i9xZEZET_tUKwR2WY4LgWsUcMsZH7C7pLkud2-W8UytGXDmu61fGZGojBfPBcLB1inWh7pMTpaS_VoPxJDPINIRoQsNkucWL0Qk69aM9qRGO7sc50j_HxwQ22rlqHoApYhefx6rQakvx8QXSYBR1PHp7FOyoI1kQedqu4ATHK4KuK_GStIiqulwMj8jSSDGaAuO4GXhWS_HE5s4=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZpHh5ILhhgLtdgSkGXKjyrQLvMItS25ubT-j7MkaADJTH30yTPnhMyRMtx-88DdP8xRlaltuM7VBq03UuS8vz7GlKC70zcUZCSxmhfTu-AJ26PMEpQq2QXGHT2nVDYsweJMi04Pu5n53GxexrSwVevJLRg6_UH0laIp-ucXk73uBy9VlmQm7n-8BkfYkOkiOvDts9j9KbzF9r_MI4ZJor0m9wfmq6UrAsD_fZP2qV4Y5LQIA4E6enzMdTS4_hgD4Pi9SCTfeLTAtpTsZc7iYdFOsOVwOGwJfwN2e4wSBT1-y5zW_0sf3LhoNLtqMm16VDRo1Ol8TSxEkQvEcBWa1d5h_5ZLvunuxEIuKUrRA8sig6_YI7jb7-CiJfEhqT8BEpsaeOz8x25hrwdTZfeF7iqolZtx7KAudjDwx6pHdG3RCDWLNtwtkmRWt-0nb3kPTpQUIn3mXMESKDlcjJ6Bfw8RIKz19A4vxKW-oID0CY-U9bMDa0A6jkOV2StpI9zR2thFpeoxUFhiGJCHwJcIDWpO4GyFn87Rbgr62Qk5TzBtn6v20NnXq5QK1gXOAIJzljPxvX1I7GkfgzEDxx6XsSQ8vgM5OLrvlOLO8_arpNt5D32V45tdX4gOuhpC3BFpQqn7NNwbhRA1aQr-urQL_HfWIkRCuuGAZheZUdw0TFpW9V370OqtxhWmu_oZxi6zh6p5tCAw3q-_1Ou5xJCHDlPDK_q2O8HQLwi12vEurOb7m-g_PVIk65pmqRfZSLDRei74X1z1PaQaLjLcrHg6mKqsakWKh2CZODZH4OkRbYzaFLoNxe55uXLi-YFJWDyQUJpOmB3sI7n2_PBuJmXcPuZz_AndGmP5b_BGptFV-5l0qTwfr-TcxRFnWr99-P-wl5B-MVkuMJoPlsGMjbRWJaKkbJGl2i9xZEZET_tUKwR2WY4LgWsUcMsZH7C7pLkud2-W8UytGXDmu61fGZGojBfPBcLB1inWh7pMTpaS_VoPxJDPINIRoQsNkucWL0Qk69aM9qRGO7sc50j_HxwQ22rlqHoApYhefx6rQakvx8QXSYBR1PHp7FOyoI1kQedqu4ATHK4KuK_GStIiqulwMj8jSSDGaAuO4GXhWS_HE5s4=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZpHh5ILhhgLtdgSkGXKjyrQLvMItS25ubT-j7MkaADJTH30yTPnhMyRMtx-88DdP8xRlaltuM7VBq03UuS8vz7GlKC70zcUZCSxmhfTu-AJ26PMEpQq2QXGHT2nVDYsweJMi04Pu5n53GxexrSwVevJLRg6_UH0laIp-ucXk73uBy9VlmQm7n-8BkfYkOkiOvDts9j9KbzF9r_MI4ZJor0m9wfmq6UrAsD_fZP2qV4Y5LQIA4E6enzMdTS4_hgD4Pi9SCTfeLTAtpTsZc7iYdFOsOVwOGwJfwN2e4wSBT1-y5zW_0sf3LhoNLtqMm16VDRo1Ol8TSxEkQvEcBWa1d5h_5ZLvunuxEIuKUrRA8sig6_YI7jb7-CiJfEhqT8BEpsaeOz8x25hrwdTZfeF7iqolZtx7KAudjDwx6pHdG3RCDWLNtwtkmRWt-0nb3kPTpQUIn3mXMESKDlcjJ6Bfw8RIKz19A4vxKW-oID0CY-U9bMDa0A6jkOV2StpI9zR2thFpeoxUFhiGJCHwJcIDWpO4GyFn87Rbgr62Qk5TzBtn6v20NnXq5QK1gXOAIJzljPxvX1I7GkfgzEDxx6XsSQ8vgM5OLrvlOLO8_arpNt5D32V45tdX4gOuhpC3BFpQqn7NNwbhRA1aQr-urQL_HfWIkRCuuGAZheZUdw0TFpW9V370OqtxhWmu_oZxi6zh6p5tCAw3q-_1Ou5xJCHDlPDK_q2O8HQLwi12vEurOb7m-g_PVIk65pmqRfZSLDRei74X1z1PaQaLjLcrHg6mKqsakWKh2CZODZH4OkRbYzaFLoNxe55uXLi-YFJWDyQUJpOmB3sI7n2_PBuJmXcPuZz_AndGmP5b_BGptFV-5l0qTwfr-TcxRFnWr99-P-wl5B-MVkuMJoPlsGMjbRWJaKkbJGl2i9xZEZET_tUKwR2WY4LgWsUcMsZH7C7pLkud2-W8UytGXDmu61fGZGojBfPBcLB1inWh7pMTpaS_VoPxJDPINIRoQsNkucWL0Qk69aM9qRGO7sc50j_HxwQ22rlqHoApYhefx6rQakvx8QXSYBR1PHp7FOyoI1kQedqu4ATHK4KuK_GStIiqulwMj8jSSDGaAuO4GXhWS_HE5s4=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1368.27,
            "totalPrice": 1134
          },
          {
            "allPassengerTotalPrice": 1150,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcASav_X9xUrAVNtP4KEICMbJJCpDdK4eFB5Qd7LLs1RHSNz0JWAQBHqE7kzgEn-d7ETMmLyx-QvzvksGgzIV67Rifs8Wp9bVACdAEBP5Al9LKohgWO5z63z2wI8LD3gtpVexJyGIYOEq6KG0lD32ygWWCLNwndVXIOMc-qaoXhPpHR73rXZ8uO8FwjTFrYrUnhkoN9J82pNIbk_2Zd9HEo804-3Fb3xIMZuzME14TXFuveeD-oTddgMwTj-K9eNyt3I81Gy0vH1VSbhy_Sb60FNU1GADmvv7uAb5VGRc4AJ9_2--uBuHq-94UMs0M3hqPxXzgaHGpSo9WRIkZUCoQpzCd-9i8oHSg9hS13mCmzQoXps3GEKt7k47WDiQQPdxGZJIf-VbJAOR2nasumZpGdiYEKIKP0TvWm5m_KVX2q7G2T0xlBXdOtJhnpJMHU5I9AX4lWvusjTsr2H03I96PCn4xg_NXkglRbDYr7ae7LGTdChg6kgLI_wWYxWAp7KCe01lJtMAFb21VaXNUlGus_w9YdieIvma1I9jUmmJjMMIeqpxNtv2IUJkkreVeIAUDGZg1sgM9mLUqbzi7Rt3we1irIsS0DqF_e0XQvPQ830TsUL-wVNm_FqLY5cguq6vFLB038hs3VN3VHr8qQ0QFJVZsK8i4YlWyqx6swFjhjMHKg1iAeZc3AG8b-8ZKgSe-tA-C0YNri5dvg-icNXypalfAst0n0qIhslLuv867QUxdWkkomDplTDlT-lAJS_WivrWfAtz8kjuebONW6S2IAZwUT1A4Jp7lq36xKhFfqiDGm7wJDhKwNIfi7SyRZ0ChedNbDqyDnykoSnnKGrVk2srn6T0F51MIPg22jgmpRLWIy6tzSJ5oNHZrcIcz6usIByiZRa7xUJFAqskdgVKCkxUevlqdR124_6Jj-b9qTBu2qxBvzEuf1rQ6DK16Mvh2INcQAqTxxZswmd5wSOCriMr4A0pfhnjf7duRrVUjhDh-JYz-7h4T7X4Q9YM8UGv0TDDAwplUXh5a4Vayhumo6WsH_XAY7NJbwIVbDjVWGR9GG38Aycnp-GIOXM4QSZRIRFwlSWSPhVB4-LuQKqEzw==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcASav_X9xUrAVNtP4KEICMbJJCpDdK4eFB5Qd7LLs1RHSNz0JWAQBHqE7kzgEn-d7ETMmLyx-QvzvksGgzIV67Rifs8Wp9bVACdAEBP5Al9LKohgWO5z63z2wI8LD3gtpVexJyGIYOEq6KG0lD32ygWWCLNwndVXIOMc-qaoXhPpHR73rXZ8uO8FwjTFrYrUnhkoN9J82pNIbk_2Zd9HEo804-3Fb3xIMZuzME14TXFuveeD-oTddgMwTj-K9eNyt3I81Gy0vH1VSbhy_Sb60FNU1GADmvv7uAb5VGRc4AJ9_2--uBuHq-94UMs0M3hqPxXzgaHGpSo9WRIkZUCoQpzCd-9i8oHSg9hS13mCmzQoXps3GEKt7k47WDiQQPdxGZJIf-VbJAOR2nasumZpGdiYEKIKP0TvWm5m_KVX2q7G2T0xlBXdOtJhnpJMHU5I9AX4lWvusjTsr2H03I96PCn4xg_NXkglRbDYr7ae7LGTdChg6kgLI_wWYxWAp7KCe01lJtMAFb21VaXNUlGus_w9YdieIvma1I9jUmmJjMMIeqpxNtv2IUJkkreVeIAUDGZg1sgM9mLUqbzi7Rt3we1irIsS0DqF_e0XQvPQ830TsUL-wVNm_FqLY5cguq6vFLB038hs3VN3VHr8qQ0QFJVZsK8i4YlWyqx6swFjhjMHKg1iAeZc3AG8b-8ZKgSe-tA-C0YNri5dvg-icNXypalfAst0n0qIhslLuv867QUxdWkkomDplTDlT-lAJS_WivrWfAtz8kjuebONW6S2IAZwUT1A4Jp7lq36xKhFfqiDGm7wJDhKwNIfi7SyRZ0ChedNbDqyDnykoSnnKGrVk2srn6T0F51MIPg22jgmpRLWIy6tzSJ5oNHZrcIcz6usIByiZRa7xUJFAqskdgVKCkxUevlqdR124_6Jj-b9qTBu2qxBvzEuf1rQ6DK16Mvh2INcQAqTxxZswmd5wSOCriMr4A0pfhnjf7duRrVUjhDh-JYz-7h4T7X4Q9YM8UGv0TDDAwplUXh5a4Vayhumo6WsH_XAY7NJbwIVbDjVWGR9GG38Aycnp-GIOXM4QSZRIRFwlSWSPhVB4-LuQKqEzw==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000c3b984dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000c3b984dc_0",
                    "local_arrival": "2024-09-17T13:45:00.000Z",
                    "local_departure": "2024-09-17T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-17T11:45:00.000Z",
                    "utc_departure": "2024-09-17T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcASav_X9xUrAVNtP4KEICMbJJCpDdK4eFB5Qd7LLs1RHSNz0JWAQBHqE7kzgEn-d7ETMmLyx-QvzvksGgzIV67Rifs8Wp9bVACdAEBP5Al9LKohgWO5z63z2wI8LD3gtpVexJyGIYOEq6KG0lD32ygWWCLNwndVXIOMc-qaoXhPpHR73rXZ8uO8FwjTFrYrUnhkoN9J82pNIbk_2Zd9HEo804-3Fb3xIMZuzME14TXFuveeD-oTddgMwTj-K9eNyt3I81Gy0vH1VSbhy_Sb60FNU1GADmvv7uAb5VGRc4AJ9_2--uBuHq-94UMs0M3hqPxXzgaHGpSo9WRIkZUCoQpzCd-9i8oHSg9hS13mCmzQoXps3GEKt7k47WDiQQPdxGZJIf-VbJAOR2nasumZpGdiYEKIKP0TvWm5m_KVX2q7G2T0xlBXdOtJhnpJMHU5I9AX4lWvusjTsr2H03I96PCn4xg_NXkglRbDYr7ae7LGTdChg6kgLI_wWYxWAp7KCe01lJtMAFb21VaXNUlGus_w9YdieIvma1I9jUmmJjMMIeqpxNtv2IUJkkreVeIAUDGZg1sgM9mLUqbzi7Rt3we1irIsS0DqF_e0XQvPQ830TsUL-wVNm_FqLY5cguq6vFLB038hs3VN3VHr8qQ0QFJVZsK8i4YlWyqx6swFjhjMHKg1iAeZc3AG8b-8ZKgSe-tA-C0YNri5dvg-icNXypalfAst0n0qIhslLuv867QUxdWkkomDplTDlT-lAJS_WivrWfAtz8kjuebONW6S2IAZwUT1A4Jp7lq36xKhFfqiDGm7wJDhKwNIfi7SyRZ0ChedNbDqyDnykoSnnKGrVk2srn6T0F51MIPg22jgmpRLWIy6tzSJ5oNHZrcIcz6usIByiZRa7xUJFAqskdgVKCkxUevlqdR124_6Jj-b9qTBu2qxBvzEuf1rQ6DK16Mvh2INcQAqTxxZswmd5wSOCriMr4A0pfhnjf7duRrVUjhDh-JYz-7h4T7X4Q9YM8UGv0TDDAwplUXh5a4Vayhumo6WsH_XAY7NJbwIVbDjVWGR9GG38Aycnp-GIOXM4QSZRIRFwlSWSPhVB4-LuQKqEzw==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1380.61,
            "totalPrice": 1150
          },
          {
            "allPassengerTotalPrice": 1188,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxjwDQ_DsHIRqpmZKm6VPki_2ixLwUDCL45cXwQPmCkghVIVhCDQLkJc89PLkvSyU27Cp8XCUuBkWP8ralhdhOL-IjevrbojefKpmtS2Z14ZIqKOQ9K_r3uWfJ6QKjZuid8YBlvJ8Y1vEGs__8AbNhI87W0sa4kOOh8urILnxSZmk4bM6rZZMI3ZxtRr5g5ASNdqfThm_MogIziPM_qx7AZW7BlgdT95iHWijZG54l1E3BhtGH-lIpQh-fZDyLaBfJYPe6GM3REozGTqdP2gSXNWIQOJmZJG_R7eldsR18ODsxeKqF8o6t7J38FbIkJvlsv0sww27O5_zeWCfZFJNGizYt3gq4iaXdro1HP9cirXmHs9C3CbXgXuzCGZ3LZccnfjjTiJczsBS67Iv01ou1LAmif7gQgBJ6S5-BvobzXzkseqoguYgUVvKCycG8W32Mv25JJLKhpdGzsS1k-VUetXd7aev3sD1OjmDjfwPMs-3XmJFosEy9k2lm6UVWZ0f3NNqRzMrf7HpIkABJL3_d-axALiC9kPsq-iwd2N_-09bSoiTzbwUaD2CklYSLzd1sg6srpfflLz_TsKk3kxzc3FKyM6M1tQjBECIfcCr-4C3TMY23v4grqfwU0YxGq3-x20nR1VJ1pMs9GTyTe1LbjwnFXGBwXxUEjMhPnYAd7ag161a0dCOThuGMzBAKPorNzv5edwfU_0EriG440h_jpPQFiJD6x0RUZPb7mkyk6gS6Wh1CeJ-rul593I9EzcCOwHtJm77jgaT3W-XTKggNaM5jGRog9nMbBu13C8bDoAMb9u3diuFbm3teiRxHXOTt3MBDDs99jyp0xOTljJCQ6LDpmM_FPn9TdnychB6o-xOI0y67PfHks4wNF1NswCUBuXPMbUCpmVcmZrtfmqEDcBu5SFnwyJFH4VYeRcmPiUTl7LpaFvS-d9avhlvePp5TqjUdrl7ugSZsvzGkFGk7cQAKuo4cJaAsZN6a1x-OU0pApPo7A-W_BO8ao_wBSzuI_iheYSRzGGgqxJw-6vfxaeUWK1UhfN56bx12459QoshNYRnvz310PTwRzfoqCfF9c6WdKi6Va91ufbIqbauhg==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-09-18",
                "arrivalTime": "13:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxjwDQ_DsHIRqpmZKm6VPki_2ixLwUDCL45cXwQPmCkghVIVhCDQLkJc89PLkvSyU27Cp8XCUuBkWP8ralhdhOL-IjevrbojefKpmtS2Z14ZIqKOQ9K_r3uWfJ6QKjZuid8YBlvJ8Y1vEGs__8AbNhI87W0sa4kOOh8urILnxSZmk4bM6rZZMI3ZxtRr5g5ASNdqfThm_MogIziPM_qx7AZW7BlgdT95iHWijZG54l1E3BhtGH-lIpQh-fZDyLaBfJYPe6GM3REozGTqdP2gSXNWIQOJmZJG_R7eldsR18ODsxeKqF8o6t7J38FbIkJvlsv0sww27O5_zeWCfZFJNGizYt3gq4iaXdro1HP9cirXmHs9C3CbXgXuzCGZ3LZccnfjjTiJczsBS67Iv01ou1LAmif7gQgBJ6S5-BvobzXzkseqoguYgUVvKCycG8W32Mv25JJLKhpdGzsS1k-VUetXd7aev3sD1OjmDjfwPMs-3XmJFosEy9k2lm6UVWZ0f3NNqRzMrf7HpIkABJL3_d-axALiC9kPsq-iwd2N_-09bSoiTzbwUaD2CklYSLzd1sg6srpfflLz_TsKk3kxzc3FKyM6M1tQjBECIfcCr-4C3TMY23v4grqfwU0YxGq3-x20nR1VJ1pMs9GTyTe1LbjwnFXGBwXxUEjMhPnYAd7ag161a0dCOThuGMzBAKPorNzv5edwfU_0EriG440h_jpPQFiJD6x0RUZPb7mkyk6gS6Wh1CeJ-rul593I9EzcCOwHtJm77jgaT3W-XTKggNaM5jGRog9nMbBu13C8bDoAMb9u3diuFbm3teiRxHXOTt3MBDDs99jyp0xOTljJCQ6LDpmM_FPn9TdnychB6o-xOI0y67PfHks4wNF1NswCUBuXPMbUCpmVcmZrtfmqEDcBu5SFnwyJFH4VYeRcmPiUTl7LpaFvS-d9avhlvePp5TqjUdrl7ugSZsvzGkFGk7cQAKuo4cJaAsZN6a1x-OU0pApPo7A-W_BO8ao_wBSzuI_iheYSRzGGgqxJw-6vfxaeUWK1UhfN56bx12459QoshNYRnvz310PTwRzfoqCfF9c6WdKi6Va91ufbIqbauhg==",
                "dateChange": 1,
                "departureDate": "2024-09-17",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "14h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "14140a224e0f00001c0980aa",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "14140a224e0f00001c0980aa_0",
                    "local_arrival": "2024-09-18T00:20:00.000Z",
                    "local_departure": "2024-09-17T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-09-17T22:20:00.000Z",
                    "utc_departure": "2024-09-17T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00001c0980aa",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 1004,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00001c0980aa_1",
                    "local_arrival": "2024-09-18T13:25:00.000Z",
                    "local_departure": "2024-09-18T11:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1004",
                    "return": 0,
                    "utc_arrival": "2024-09-18T11:25:00.000Z",
                    "utc_departure": "2024-09-18T09:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxjwDQ_DsHIRqpmZKm6VPki_2ixLwUDCL45cXwQPmCkghVIVhCDQLkJc89PLkvSyU27Cp8XCUuBkWP8ralhdhOL-IjevrbojefKpmtS2Z14ZIqKOQ9K_r3uWfJ6QKjZuid8YBlvJ8Y1vEGs__8AbNhI87W0sa4kOOh8urILnxSZmk4bM6rZZMI3ZxtRr5g5ASNdqfThm_MogIziPM_qx7AZW7BlgdT95iHWijZG54l1E3BhtGH-lIpQh-fZDyLaBfJYPe6GM3REozGTqdP2gSXNWIQOJmZJG_R7eldsR18ODsxeKqF8o6t7J38FbIkJvlsv0sww27O5_zeWCfZFJNGizYt3gq4iaXdro1HP9cirXmHs9C3CbXgXuzCGZ3LZccnfjjTiJczsBS67Iv01ou1LAmif7gQgBJ6S5-BvobzXzkseqoguYgUVvKCycG8W32Mv25JJLKhpdGzsS1k-VUetXd7aev3sD1OjmDjfwPMs-3XmJFosEy9k2lm6UVWZ0f3NNqRzMrf7HpIkABJL3_d-axALiC9kPsq-iwd2N_-09bSoiTzbwUaD2CklYSLzd1sg6srpfflLz_TsKk3kxzc3FKyM6M1tQjBECIfcCr-4C3TMY23v4grqfwU0YxGq3-x20nR1VJ1pMs9GTyTe1LbjwnFXGBwXxUEjMhPnYAd7ag161a0dCOThuGMzBAKPorNzv5edwfU_0EriG440h_jpPQFiJD6x0RUZPb7mkyk6gS6Wh1CeJ-rul593I9EzcCOwHtJm77jgaT3W-XTKggNaM5jGRog9nMbBu13C8bDoAMb9u3diuFbm3teiRxHXOTt3MBDDs99jyp0xOTljJCQ6LDpmM_FPn9TdnychB6o-xOI0y67PfHks4wNF1NswCUBuXPMbUCpmVcmZrtfmqEDcBu5SFnwyJFH4VYeRcmPiUTl7LpaFvS-d9avhlvePp5TqjUdrl7ugSZsvzGkFGk7cQAKuo4cJaAsZN6a1x-OU0pApPo7A-W_BO8ao_wBSzuI_iheYSRzGGgqxJw-6vfxaeUWK1UhfN56bx12459QoshNYRnvz310PTwRzfoqCfF9c6WdKi6Va91ufbIqbauhg==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1520.7,
            "totalPrice": 1188
          },
          {
            "allPassengerTotalPrice": 1198,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlZRhpU-669fjxyhlXPeGU5_jrnMcadjUmtThjp7ohNZQ2rDlGB0cDRpmmDZfs641g24JZk1pSWXB1hYwVMJVy1hw71p3Um3nZxbOHrocTNP5sosaUe6INBFDHhRHWPI63pWoywbbgbAeEemkwPd262stOQM_vRMsHHG4o_12wwDKIFGxR-ShIbd3Rfyoi0wvfFOvLjkeiEMugnPa93n4Gp_c2TjkNo1Xyh7LbaBfI9K-BI8W9-amrVpdivdXEK9GcD6wbYyf6ufIfqoA2jwvXfppJTm0OZAKooZQEAr5pCKB0r7cL5ytF6GVcRmssgAfj1XzsrCbEh8Tv9lB-Ff2IuA_0St2UFvCuqCaOPfzfgUvIV5XV7WeujD5kruNDoXwhCMIQsoDVcaPlIR8A1_4sNvo-b_1zIiJ5OnOYqvF692hOl0YGMRUcmXjFHOn4Rw8zRNFV5mB2n2jeHa2l7Io48gTdFoV2W0MiyGvzUiB020Vx5jafC7jJklZz7N63pMSNfYDgucN30NX4DUyUrkrvGNcL5ShvppM0Lu_f2xuKwD50p4y1U9TdlpxFnhrfSDGnTsRLO75r2cBskoHKzT_QMKCIp4lzkXI2sJHySlK76tnWn97MYFm_zHQ1pcyNBzMsINVzCVVaD9jLvew9NWk8I5rQGt462pczxDARHq8_1HFFsN-KV8L7cvUTPcortbJveJzbGAOfUUBBZfJQzYld3A_c6928enIOajLjomYZ6cU80iQ7mtXPuaRIt1HnLRuge-xL1Ew5GKdJPjCk5ciXGoCP72K_bdTV5Sr6Et2hfYvduvOW4VFEzcCbKc-6p1kiyV_WOX_itWrcFPBdpkfuvd3KUw74MeSATujxnXvTp5BgtTPBp6_vGsSwwhdnDhkO0Vin6Tk5gzBVBBtHq-QNQWG_16tabmJDElRk-aPO6ykmUX7oV3WBvwk6pEgm2cq2-BOzCVNWav62fbzkP8GQVArwubHoU7mULq9oK1PzVfU64o6IOg0hFYMNGSv9ZT7vfbvAYGTxv-1s-8-YljH8F9B9odCXZ4fUROptH327P5CKrOSzGxvVVsCCfQ1j7Kwn6Fy5CO3RK7B0MIXAkfUnjYMqIeC_IxHro7mf-jvjqA=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_0",
                    "local_arrival": "2024-09-07T16:31:00.000Z",
                    "local_departure": "2024-09-07T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-07T22:31:00.000Z",
                    "utc_departure": "2024-09-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000029aaaf4",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000029aaaf4_1",
                    "local_arrival": "2024-09-08T12:25:00.000Z",
                    "local_departure": "2024-09-07T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:25:00.000Z",
                    "utc_departure": "2024-09-08T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-09-18",
                "arrivalTime": "08:50",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlZRhpU-669fjxyhlXPeGU5_jrnMcadjUmtThjp7ohNZQ2rDlGB0cDRpmmDZfs641g24JZk1pSWXB1hYwVMJVy1hw71p3Um3nZxbOHrocTNP5sosaUe6INBFDHhRHWPI63pWoywbbgbAeEemkwPd262stOQM_vRMsHHG4o_12wwDKIFGxR-ShIbd3Rfyoi0wvfFOvLjkeiEMugnPa93n4Gp_c2TjkNo1Xyh7LbaBfI9K-BI8W9-amrVpdivdXEK9GcD6wbYyf6ufIfqoA2jwvXfppJTm0OZAKooZQEAr5pCKB0r7cL5ytF6GVcRmssgAfj1XzsrCbEh8Tv9lB-Ff2IuA_0St2UFvCuqCaOPfzfgUvIV5XV7WeujD5kruNDoXwhCMIQsoDVcaPlIR8A1_4sNvo-b_1zIiJ5OnOYqvF692hOl0YGMRUcmXjFHOn4Rw8zRNFV5mB2n2jeHa2l7Io48gTdFoV2W0MiyGvzUiB020Vx5jafC7jJklZz7N63pMSNfYDgucN30NX4DUyUrkrvGNcL5ShvppM0Lu_f2xuKwD50p4y1U9TdlpxFnhrfSDGnTsRLO75r2cBskoHKzT_QMKCIp4lzkXI2sJHySlK76tnWn97MYFm_zHQ1pcyNBzMsINVzCVVaD9jLvew9NWk8I5rQGt462pczxDARHq8_1HFFsN-KV8L7cvUTPcortbJveJzbGAOfUUBBZfJQzYld3A_c6928enIOajLjomYZ6cU80iQ7mtXPuaRIt1HnLRuge-xL1Ew5GKdJPjCk5ciXGoCP72K_bdTV5Sr6Et2hfYvduvOW4VFEzcCbKc-6p1kiyV_WOX_itWrcFPBdpkfuvd3KUw74MeSATujxnXvTp5BgtTPBp6_vGsSwwhdnDhkO0Vin6Tk5gzBVBBtHq-QNQWG_16tabmJDElRk-aPO6ykmUX7oV3WBvwk6pEgm2cq2-BOzCVNWav62fbzkP8GQVArwubHoU7mULq9oK1PzVfU64o6IOg0hFYMNGSv9ZT7vfbvAYGTxv-1s-8-YljH8F9B9odCXZ4fUROptH327P5CKrOSzGxvVVsCCfQ1j7Kwn6Fy5CO3RK7B0MIXAkfUnjYMqIeC_IxHro7mf-jvjqA=",
                "dateChange": 1,
                "departureDate": "2024-09-17",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "10h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "14140a224e0f0000f56a259f",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "14140a224e0f0000f56a259f_0",
                    "local_arrival": "2024-09-18T00:20:00.000Z",
                    "local_departure": "2024-09-17T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-09-17T22:20:00.000Z",
                    "utc_departure": "2024-09-17T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000f56a259f",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 1002,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000f56a259f_1",
                    "local_arrival": "2024-09-18T08:50:00.000Z",
                    "local_departure": "2024-09-18T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1002",
                    "return": 0,
                    "utc_arrival": "2024-09-18T06:50:00.000Z",
                    "utc_departure": "2024-09-18T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000029aaaf4_0%7C0f9c01af4e050000029aaaf4_1%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlZRhpU-669fjxyhlXPeGU5_jrnMcadjUmtThjp7ohNZQ2rDlGB0cDRpmmDZfs641g24JZk1pSWXB1hYwVMJVy1hw71p3Um3nZxbOHrocTNP5sosaUe6INBFDHhRHWPI63pWoywbbgbAeEemkwPd262stOQM_vRMsHHG4o_12wwDKIFGxR-ShIbd3Rfyoi0wvfFOvLjkeiEMugnPa93n4Gp_c2TjkNo1Xyh7LbaBfI9K-BI8W9-amrVpdivdXEK9GcD6wbYyf6ufIfqoA2jwvXfppJTm0OZAKooZQEAr5pCKB0r7cL5ytF6GVcRmssgAfj1XzsrCbEh8Tv9lB-Ff2IuA_0St2UFvCuqCaOPfzfgUvIV5XV7WeujD5kruNDoXwhCMIQsoDVcaPlIR8A1_4sNvo-b_1zIiJ5OnOYqvF692hOl0YGMRUcmXjFHOn4Rw8zRNFV5mB2n2jeHa2l7Io48gTdFoV2W0MiyGvzUiB020Vx5jafC7jJklZz7N63pMSNfYDgucN30NX4DUyUrkrvGNcL5ShvppM0Lu_f2xuKwD50p4y1U9TdlpxFnhrfSDGnTsRLO75r2cBskoHKzT_QMKCIp4lzkXI2sJHySlK76tnWn97MYFm_zHQ1pcyNBzMsINVzCVVaD9jLvew9NWk8I5rQGt462pczxDARHq8_1HFFsN-KV8L7cvUTPcortbJveJzbGAOfUUBBZfJQzYld3A_c6928enIOajLjomYZ6cU80iQ7mtXPuaRIt1HnLRuge-xL1Ew5GKdJPjCk5ciXGoCP72K_bdTV5Sr6Et2hfYvduvOW4VFEzcCbKc-6p1kiyV_WOX_itWrcFPBdpkfuvd3KUw74MeSATujxnXvTp5BgtTPBp6_vGsSwwhdnDhkO0Vin6Tk5gzBVBBtHq-QNQWG_16tabmJDElRk-aPO6ykmUX7oV3WBvwk6pEgm2cq2-BOzCVNWav62fbzkP8GQVArwubHoU7mULq9oK1PzVfU64o6IOg0hFYMNGSv9ZT7vfbvAYGTxv-1s-8-YljH8F9B9odCXZ4fUROptH327P5CKrOSzGxvVVsCCfQ1j7Kwn6Fy5CO3RK7B0MIXAkfUnjYMqIeC_IxHro7mf-jvjqA=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1493.04,
            "totalPrice": 1198
          },
          {
            "allPassengerTotalPrice": 1267,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G6VCZvwuspFWwLoyLAus_PIn_FsrCAosu4zQSHIBPdSv_usGsyZAbTZhyHYBumKvqRGwP3OPJzEPdqUvHgIuaYM3PBI0Y9NBsw-KYgjOhni1v3EbbV9sBmLGf1rAJtyOyhtJ6zyiyPfkS2KMCYjPxdjJ1sH9vxpZuwURHvE2OThY_xBzXqFKF4ke4Yd0wQJ5GYv2eZtnCUYQkqwamAttWpPxVebI8_i1FeMqf0TU9VE_9LYejS1z5ivIyNwqlEoWH_5C4QJeVb9XSfpAyJAR4KavRUKdT1jIyG0HYm0x3GA5gp8SvhETi9kin2xQeYMhGMB9pFVxL-TDUBTQdWojVfh5KCxYZo-L6a1dbqEd2KMiIO0joROePuXo4jhiYbnPK-atlRnrDcgfCnQlSJ_PyEsKPoKE3t8Nt4JEUm_8mXJtriHw8kBBOk9w8-FrPfEvIVxoX-cz49Rxl99Mn8Y5AEMDh9fYdVEzqkBrgWhK5A8UYm77yBN5-OwvEQj4DKK2MEJJSPCaZvTHHA8RnwN6lo__o62U6cKq6e1xszoCOwoTzCEXxhaqqmss-_PsFjkliSjdmTqDJ4S19kQX2RT0Im5lRcZuGTgTU4Gwr5TVhhQ_6txx0Nr9ahzykfzIA5LOdC-kkMvTE2rC8GF1WjaGInDFMEvANiEYdZ5pVGTUIzPg-8r8agRdrv7e_2meLOXerq5WFojFvB4WmQcJGcO6oUEZjTg95VSVU9fqMfAO4RxaI8PjtnY3paXHnGpb-2ExGL8cdNH8dLwysbS6W0ySWGtUq7HJkhzmJKXI7G-DjKLpSgc3IhLst3gzUXsahaD5ZU5NRCSNxPmJwGUjEs7TKZv4PhpUuiGs6N5H7vUgAzCsAhchU19Ats45RBb9P0k-IZoa6gvQuNmo5L5PuzwpveVoU1phOJa5QeEyKg_DMARmIvoZrIbeC3-CrQ8mSRnu8DXTYibuCMY1rPvnE6FSsvBcni-CQZY7XeH4_B6V_AUAjpCkxQ6I8Lag0VkQWYyAleHm_G-XnMp_c5GXzaXFsvQ==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G6VCZvwuspFWwLoyLAus_PIn_FsrCAosu4zQSHIBPdSv_usGsyZAbTZhyHYBumKvqRGwP3OPJzEPdqUvHgIuaYM3PBI0Y9NBsw-KYgjOhni1v3EbbV9sBmLGf1rAJtyOyhtJ6zyiyPfkS2KMCYjPxdjJ1sH9vxpZuwURHvE2OThY_xBzXqFKF4ke4Yd0wQJ5GYv2eZtnCUYQkqwamAttWpPxVebI8_i1FeMqf0TU9VE_9LYejS1z5ivIyNwqlEoWH_5C4QJeVb9XSfpAyJAR4KavRUKdT1jIyG0HYm0x3GA5gp8SvhETi9kin2xQeYMhGMB9pFVxL-TDUBTQdWojVfh5KCxYZo-L6a1dbqEd2KMiIO0joROePuXo4jhiYbnPK-atlRnrDcgfCnQlSJ_PyEsKPoKE3t8Nt4JEUm_8mXJtriHw8kBBOk9w8-FrPfEvIVxoX-cz49Rxl99Mn8Y5AEMDh9fYdVEzqkBrgWhK5A8UYm77yBN5-OwvEQj4DKK2MEJJSPCaZvTHHA8RnwN6lo__o62U6cKq6e1xszoCOwoTzCEXxhaqqmss-_PsFjkliSjdmTqDJ4S19kQX2RT0Im5lRcZuGTgTU4Gwr5TVhhQ_6txx0Nr9ahzykfzIA5LOdC-kkMvTE2rC8GF1WjaGInDFMEvANiEYdZ5pVGTUIzPg-8r8agRdrv7e_2meLOXerq5WFojFvB4WmQcJGcO6oUEZjTg95VSVU9fqMfAO4RxaI8PjtnY3paXHnGpb-2ExGL8cdNH8dLwysbS6W0ySWGtUq7HJkhzmJKXI7G-DjKLpSgc3IhLst3gzUXsahaD5ZU5NRCSNxPmJwGUjEs7TKZv4PhpUuiGs6N5H7vUgAzCsAhchU19Ats45RBb9P0k-IZoa6gvQuNmo5L5PuzwpveVoU1phOJa5QeEyKg_DMARmIvoZrIbeC3-CrQ8mSRnu8DXTYibuCMY1rPvnE6FSsvBcni-CQZY7XeH4_B6V_AUAjpCkxQ6I8Lag0VkQWYyAleHm_G-XnMp_c5GXzaXFsvQ==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G6VCZvwuspFWwLoyLAus_PIn_FsrCAosu4zQSHIBPdSv_usGsyZAbTZhyHYBumKvqRGwP3OPJzEPdqUvHgIuaYM3PBI0Y9NBsw-KYgjOhni1v3EbbV9sBmLGf1rAJtyOyhtJ6zyiyPfkS2KMCYjPxdjJ1sH9vxpZuwURHvE2OThY_xBzXqFKF4ke4Yd0wQJ5GYv2eZtnCUYQkqwamAttWpPxVebI8_i1FeMqf0TU9VE_9LYejS1z5ivIyNwqlEoWH_5C4QJeVb9XSfpAyJAR4KavRUKdT1jIyG0HYm0x3GA5gp8SvhETi9kin2xQeYMhGMB9pFVxL-TDUBTQdWojVfh5KCxYZo-L6a1dbqEd2KMiIO0joROePuXo4jhiYbnPK-atlRnrDcgfCnQlSJ_PyEsKPoKE3t8Nt4JEUm_8mXJtriHw8kBBOk9w8-FrPfEvIVxoX-cz49Rxl99Mn8Y5AEMDh9fYdVEzqkBrgWhK5A8UYm77yBN5-OwvEQj4DKK2MEJJSPCaZvTHHA8RnwN6lo__o62U6cKq6e1xszoCOwoTzCEXxhaqqmss-_PsFjkliSjdmTqDJ4S19kQX2RT0Im5lRcZuGTgTU4Gwr5TVhhQ_6txx0Nr9ahzykfzIA5LOdC-kkMvTE2rC8GF1WjaGInDFMEvANiEYdZ5pVGTUIzPg-8r8agRdrv7e_2meLOXerq5WFojFvB4WmQcJGcO6oUEZjTg95VSVU9fqMfAO4RxaI8PjtnY3paXHnGpb-2ExGL8cdNH8dLwysbS6W0ySWGtUq7HJkhzmJKXI7G-DjKLpSgc3IhLst3gzUXsahaD5ZU5NRCSNxPmJwGUjEs7TKZv4PhpUuiGs6N5H7vUgAzCsAhchU19Ats45RBb9P0k-IZoa6gvQuNmo5L5PuzwpveVoU1phOJa5QeEyKg_DMARmIvoZrIbeC3-CrQ8mSRnu8DXTYibuCMY1rPvnE6FSsvBcni-CQZY7XeH4_B6V_AUAjpCkxQ6I8Lag0VkQWYyAleHm_G-XnMp_c5GXzaXFsvQ==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1459.23,
            "totalPrice": 1267
          },
          {
            "allPassengerTotalPrice": 1270,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbLzMTEUG0ns7ARZ3KNdnAjJU8kJ9Rebq5FN88wp2UFVKZ3lhP_K5V6ezPy6gL7DF1ulj_306AmspRF-YQNV_AJ4XTJdjnlgktsWuL1dLNqV1WRBObPLt3nSH_9Y67S8XyMttAcr_pi0C9X7bRXMgABWtwIWvoGNPlHmHUCp5xQ05etAzOWBBHlVwEp4y2Bo7fB1jA2KIyOr2gAstqy2pj96l6la7gpAiPl3pIU2J5icljxGHGeHLIZ6sAUnDsjN0toueOVyFvv1cRslQwc4WFLeeTr4Z43SOE63_VjB3n2IuaFp9HJe78NX51ySq9TpvKJ51-kAad4PdXE_X2MwbPzFnDWDvRbT0HT3VEleikAQA8xpsWaltLUavw7zr93kuM3wG-gw6K_3i2ed9GRjLcrhRhVvDVS48UYdMK0mhuNazPA5LgPF1dXfASXWnzxrtAG5AigeXzm7tRA71YYQMHL7BBBLoHYkiAMdX69kNIWSG_OGJOCS0kSy3PR3XLZshD7EJSlVynYxAyaqI-f_-R8MKfJ4GKu5iEjr5vx99xF2fLRyhsevYsUVnR99i5M-zBGzho2recH4_e82rN2VW3Hhn-LVMbe1fw_Ozt5kd-XFlvh4ol0N_dq-mZl0raJ2G06kbw7ZzhwrgevmOhIoOopu8NI8kXNzPR7SoGP9a5XQO4Q_XHrrbXBHgO2IpEyd-LczfDnorEVpCVwE3Hb2pUdbSWuqs0hcH3yxTwRNDwU0uMA-mK96Rp9eg39PRFI6CwOapVNVUuArv-uzExa1rZCkbbh8z7XtifhY-1knniRAPeilpePaZGLXfB3P3HT834YkdggUOjq9IxdEzDPv87SOVyPvz2oGyUV_KYpJFk2CPxryKjD-ijkUmeo_pfO9hyCz3BAFo0Dni-DfRBwODu4PnY7vyWDTNR-7lQPcO3DFHm2pMyLLWB23u6DtIRc7MT2szRoc1MtyjH8Lwzy2lNLg9Hsg8TuTTNtGZ5LUS5lDcWlDcLoJWqWlljjcgGWY-LUv52GoGbkze7AcBLj8ogw==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbLzMTEUG0ns7ARZ3KNdnAjJU8kJ9Rebq5FN88wp2UFVKZ3lhP_K5V6ezPy6gL7DF1ulj_306AmspRF-YQNV_AJ4XTJdjnlgktsWuL1dLNqV1WRBObPLt3nSH_9Y67S8XyMttAcr_pi0C9X7bRXMgABWtwIWvoGNPlHmHUCp5xQ05etAzOWBBHlVwEp4y2Bo7fB1jA2KIyOr2gAstqy2pj96l6la7gpAiPl3pIU2J5icljxGHGeHLIZ6sAUnDsjN0toueOVyFvv1cRslQwc4WFLeeTr4Z43SOE63_VjB3n2IuaFp9HJe78NX51ySq9TpvKJ51-kAad4PdXE_X2MwbPzFnDWDvRbT0HT3VEleikAQA8xpsWaltLUavw7zr93kuM3wG-gw6K_3i2ed9GRjLcrhRhVvDVS48UYdMK0mhuNazPA5LgPF1dXfASXWnzxrtAG5AigeXzm7tRA71YYQMHL7BBBLoHYkiAMdX69kNIWSG_OGJOCS0kSy3PR3XLZshD7EJSlVynYxAyaqI-f_-R8MKfJ4GKu5iEjr5vx99xF2fLRyhsevYsUVnR99i5M-zBGzho2recH4_e82rN2VW3Hhn-LVMbe1fw_Ozt5kd-XFlvh4ol0N_dq-mZl0raJ2G06kbw7ZzhwrgevmOhIoOopu8NI8kXNzPR7SoGP9a5XQO4Q_XHrrbXBHgO2IpEyd-LczfDnorEVpCVwE3Hb2pUdbSWuqs0hcH3yxTwRNDwU0uMA-mK96Rp9eg39PRFI6CwOapVNVUuArv-uzExa1rZCkbbh8z7XtifhY-1knniRAPeilpePaZGLXfB3P3HT834YkdggUOjq9IxdEzDPv87SOVyPvz2oGyUV_KYpJFk2CPxryKjD-ijkUmeo_pfO9hyCz3BAFo0Dni-DfRBwODu4PnY7vyWDTNR-7lQPcO3DFHm2pMyLLWB23u6DtIRc7MT2szRoc1MtyjH8Lwzy2lNLg9Hsg8TuTTNtGZ5LUS5lDcWlDcLoJWqWlljjcgGWY-LUv52GoGbkze7AcBLj8ogw==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbLzMTEUG0ns7ARZ3KNdnAjJU8kJ9Rebq5FN88wp2UFVKZ3lhP_K5V6ezPy6gL7DF1ulj_306AmspRF-YQNV_AJ4XTJdjnlgktsWuL1dLNqV1WRBObPLt3nSH_9Y67S8XyMttAcr_pi0C9X7bRXMgABWtwIWvoGNPlHmHUCp5xQ05etAzOWBBHlVwEp4y2Bo7fB1jA2KIyOr2gAstqy2pj96l6la7gpAiPl3pIU2J5icljxGHGeHLIZ6sAUnDsjN0toueOVyFvv1cRslQwc4WFLeeTr4Z43SOE63_VjB3n2IuaFp9HJe78NX51ySq9TpvKJ51-kAad4PdXE_X2MwbPzFnDWDvRbT0HT3VEleikAQA8xpsWaltLUavw7zr93kuM3wG-gw6K_3i2ed9GRjLcrhRhVvDVS48UYdMK0mhuNazPA5LgPF1dXfASXWnzxrtAG5AigeXzm7tRA71YYQMHL7BBBLoHYkiAMdX69kNIWSG_OGJOCS0kSy3PR3XLZshD7EJSlVynYxAyaqI-f_-R8MKfJ4GKu5iEjr5vx99xF2fLRyhsevYsUVnR99i5M-zBGzho2recH4_e82rN2VW3Hhn-LVMbe1fw_Ozt5kd-XFlvh4ol0N_dq-mZl0raJ2G06kbw7ZzhwrgevmOhIoOopu8NI8kXNzPR7SoGP9a5XQO4Q_XHrrbXBHgO2IpEyd-LczfDnorEVpCVwE3Hb2pUdbSWuqs0hcH3yxTwRNDwU0uMA-mK96Rp9eg39PRFI6CwOapVNVUuArv-uzExa1rZCkbbh8z7XtifhY-1knniRAPeilpePaZGLXfB3P3HT834YkdggUOjq9IxdEzDPv87SOVyPvz2oGyUV_KYpJFk2CPxryKjD-ijkUmeo_pfO9hyCz3BAFo0Dni-DfRBwODu4PnY7vyWDTNR-7lQPcO3DFHm2pMyLLWB23u6DtIRc7MT2szRoc1MtyjH8Lwzy2lNLg9Hsg8TuTTNtGZ5LUS5lDcWlDcLoJWqWlljjcgGWY-LUv52GoGbkze7AcBLj8ogw==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1461.01,
            "totalPrice": 1270
          },
          {
            "allPassengerTotalPrice": 1270,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTEWuB_DStRHNi_tmqlBaRplABxhAX3FramOXHHqT7hr5ktCT116P3S1UCnonyanNL3Wz6b5jhLkIuzkUcTX083MWunkjMtb7QuwAV1UKUMYHVMbbC9JmDe-6HZw4KAe_xB_YbhenK7fDeGkze_ZtbW1rylIaK9DaygZz_g371sxk0w-f_al4YmmvMhCqllhgPuwnehOurdqPE_sksNqGhtqQBfVpXbOfn3cG5pxLgyyVb9Npb1ZvXqkMaTVGl9UNjcOaVTcz8VZsBHnTBCRRsCBZqBJk5uF2F78Rr1Ug4yWj7slab1V5ZS-6TyxQ1DhoUHfmX7qSoRjuK42AI2t_WMM_giJnM6uV4eWjx0AjdeRn4CKMoz9Fi8S1FHiQvW3hwhsMo-MsRl_y_OnhiNZBdX09n6ZFGJWWsYTWyyVN_KjZWxDRZETe3a-MIzC4wWy7ssEBPvhzo8dInswXbO-GZy5tlyQDZbT77AC86ntGpE2QtMbzS0ClQtHUoSHcLe44bSXZQmq4FnWd_bMbl18oMEAageH38B5RwrvjCN1K0DMb4Rg55iApdsmpZ5cTmmNxbei_GNoNO3fn4a1BjIK9EfyR9NQCc9jYa4yMo3z-qq8GvVi0RmU_1ldeaKjIwTKPQrRk3xzl0qZXsycKUj9rB-D7HKDUbQR3Vhr3gt-Tfp4cPFIQ4B_4-im2GHEQRaaigzziTcDauKAIJWVo_OofhlvJpW3hosDMrerpV0s9yhX6hXKHfy871DDkN41yv2N5wBETnOs25F3TjXG2HwgEcZRdCXaJRTkufqF6gQHBcPOaFIGh09XooSp7R7vYNiEE2je1MllE9bymEaLRw0jj8aTy714DJY9F5kCpMOf1Tf3KjfCAOyhiXEvu45YE3nGPAGa-Tr7FcXtOaP95JwM6JBoQTmnWWk3zLZPtsmIWgXRnH4eNCuVJPYZgJXn60v1qp55J9mYzKLv7nTbwR9kTudcsbqCjTQ2uKVm6sSw615sR8rB8enUjgRy40zI-XMZuhz1Nc1Z9qgQ_ZPIgtG0eww==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTEWuB_DStRHNi_tmqlBaRplABxhAX3FramOXHHqT7hr5ktCT116P3S1UCnonyanNL3Wz6b5jhLkIuzkUcTX083MWunkjMtb7QuwAV1UKUMYHVMbbC9JmDe-6HZw4KAe_xB_YbhenK7fDeGkze_ZtbW1rylIaK9DaygZz_g371sxk0w-f_al4YmmvMhCqllhgPuwnehOurdqPE_sksNqGhtqQBfVpXbOfn3cG5pxLgyyVb9Npb1ZvXqkMaTVGl9UNjcOaVTcz8VZsBHnTBCRRsCBZqBJk5uF2F78Rr1Ug4yWj7slab1V5ZS-6TyxQ1DhoUHfmX7qSoRjuK42AI2t_WMM_giJnM6uV4eWjx0AjdeRn4CKMoz9Fi8S1FHiQvW3hwhsMo-MsRl_y_OnhiNZBdX09n6ZFGJWWsYTWyyVN_KjZWxDRZETe3a-MIzC4wWy7ssEBPvhzo8dInswXbO-GZy5tlyQDZbT77AC86ntGpE2QtMbzS0ClQtHUoSHcLe44bSXZQmq4FnWd_bMbl18oMEAageH38B5RwrvjCN1K0DMb4Rg55iApdsmpZ5cTmmNxbei_GNoNO3fn4a1BjIK9EfyR9NQCc9jYa4yMo3z-qq8GvVi0RmU_1ldeaKjIwTKPQrRk3xzl0qZXsycKUj9rB-D7HKDUbQR3Vhr3gt-Tfp4cPFIQ4B_4-im2GHEQRaaigzziTcDauKAIJWVo_OofhlvJpW3hosDMrerpV0s9yhX6hXKHfy871DDkN41yv2N5wBETnOs25F3TjXG2HwgEcZRdCXaJRTkufqF6gQHBcPOaFIGh09XooSp7R7vYNiEE2je1MllE9bymEaLRw0jj8aTy714DJY9F5kCpMOf1Tf3KjfCAOyhiXEvu45YE3nGPAGa-Tr7FcXtOaP95JwM6JBoQTmnWWk3zLZPtsmIWgXRnH4eNCuVJPYZgJXn60v1qp55J9mYzKLv7nTbwR9kTudcsbqCjTQ2uKVm6sSw615sR8rB8enUjgRy40zI-XMZuhz1Nc1Z9qgQ_ZPIgtG0eww==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTEWuB_DStRHNi_tmqlBaRplABxhAX3FramOXHHqT7hr5ktCT116P3S1UCnonyanNL3Wz6b5jhLkIuzkUcTX083MWunkjMtb7QuwAV1UKUMYHVMbbC9JmDe-6HZw4KAe_xB_YbhenK7fDeGkze_ZtbW1rylIaK9DaygZz_g371sxk0w-f_al4YmmvMhCqllhgPuwnehOurdqPE_sksNqGhtqQBfVpXbOfn3cG5pxLgyyVb9Npb1ZvXqkMaTVGl9UNjcOaVTcz8VZsBHnTBCRRsCBZqBJk5uF2F78Rr1Ug4yWj7slab1V5ZS-6TyxQ1DhoUHfmX7qSoRjuK42AI2t_WMM_giJnM6uV4eWjx0AjdeRn4CKMoz9Fi8S1FHiQvW3hwhsMo-MsRl_y_OnhiNZBdX09n6ZFGJWWsYTWyyVN_KjZWxDRZETe3a-MIzC4wWy7ssEBPvhzo8dInswXbO-GZy5tlyQDZbT77AC86ntGpE2QtMbzS0ClQtHUoSHcLe44bSXZQmq4FnWd_bMbl18oMEAageH38B5RwrvjCN1K0DMb4Rg55iApdsmpZ5cTmmNxbei_GNoNO3fn4a1BjIK9EfyR9NQCc9jYa4yMo3z-qq8GvVi0RmU_1ldeaKjIwTKPQrRk3xzl0qZXsycKUj9rB-D7HKDUbQR3Vhr3gt-Tfp4cPFIQ4B_4-im2GHEQRaaigzziTcDauKAIJWVo_OofhlvJpW3hosDMrerpV0s9yhX6hXKHfy871DDkN41yv2N5wBETnOs25F3TjXG2HwgEcZRdCXaJRTkufqF6gQHBcPOaFIGh09XooSp7R7vYNiEE2je1MllE9bymEaLRw0jj8aTy714DJY9F5kCpMOf1Tf3KjfCAOyhiXEvu45YE3nGPAGa-Tr7FcXtOaP95JwM6JBoQTmnWWk3zLZPtsmIWgXRnH4eNCuVJPYZgJXn60v1qp55J9mYzKLv7nTbwR9kTudcsbqCjTQ2uKVm6sSw615sR8rB8enUjgRy40zI-XMZuhz1Nc1Z9qgQ_ZPIgtG0eww==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1461.67,
            "totalPrice": 1270
          },
          {
            "allPassengerTotalPrice": 1273,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkGH-vq_J0Pq6BgpEiUwKhCJ7ylbGX1tQ-Moj9zzDHvewMsxqcpUnnb8blrZvs_DS74A-QDJeBgEAdEC-pqy8ucHD24vRe6D3K7oZ4mqJPlsBfg2zWBJhQYi7j5bHk1JRJbijjOXpEK76e5FmloXfsxiHNLIlK8-P4uEqSeNcU_69LQ_5T8oETNVWlllw1f9lokEXddR79L6FGcI6G0VbJcDVxNyn8d-9U7e7CThiMfv6sjP4XUTcTzQyy3P7Ya_NZ51_IBmgcroXZTOSqVfcYWqLzE-c-HXdiSk7j6-IXgb3XZmrp72nKotYs9j9Cvnp9CXf3pits2xkXxm3xpJhcGGaQsy28Ude-gYGY3ywLgDbyKEDjlTLTaeQietP32M1HAfZWSNZSX-zIwJk0hAV2BoX71X3XU_cUL7CJsKpAcjFrgozkZ0jUFDWe1RIh3zCNft9gtnrnnm6xgqUF5EAZYasqAeLNC5OCqOrm4VoSgZsOzH_Cp1FCErA-nlf-Cybo9LtM7Kkb8brsHGHKiwAuZQbD7Sc6SaDjUfRSiGCEg-ANQlskjLQfcZzm4UTk4kFgNcJwhByRNIT7Yq6E29BsEvl4Yj1nQw-lQq0pNWKrIVZMqBVuS9US1es6xh9TgNOQDHdmysaLzIpu4RzGGM5YiQ9xD5Z2F0mh9sSzOpkSqPB4KY6SSKXWvzTO7r1RzQiCbh9rvCD5qtzzrVT1m6m2ta-rY4oXBClpUfz57vxATYQMPaK7_ujeyYJ_Uet7zIWdah3z3AA5J16ypukfqv6LWE0Zn3a1kZ5WwINtWIFUM9v0oQZ33QY6zueBq4RV-D1jtzucoTO0sgj9Uh8XgqEmYgK5cKUsS360Rr-aYFfOXhqTGGOBtT6tC8hb9tkMxedgZbl1qH0FbIGAf8-1PWDt453hpfOi-iCHVy4BiFnkAKWyAK7QK9TSTvoWLPTHjQ3fFoDACx4QJ4Jej7JAqPINhBBwVOJOtzC8BYmweVELjc=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkGH-vq_J0Pq6BgpEiUwKhCJ7ylbGX1tQ-Moj9zzDHvewMsxqcpUnnb8blrZvs_DS74A-QDJeBgEAdEC-pqy8ucHD24vRe6D3K7oZ4mqJPlsBfg2zWBJhQYi7j5bHk1JRJbijjOXpEK76e5FmloXfsxiHNLIlK8-P4uEqSeNcU_69LQ_5T8oETNVWlllw1f9lokEXddR79L6FGcI6G0VbJcDVxNyn8d-9U7e7CThiMfv6sjP4XUTcTzQyy3P7Ya_NZ51_IBmgcroXZTOSqVfcYWqLzE-c-HXdiSk7j6-IXgb3XZmrp72nKotYs9j9Cvnp9CXf3pits2xkXxm3xpJhcGGaQsy28Ude-gYGY3ywLgDbyKEDjlTLTaeQietP32M1HAfZWSNZSX-zIwJk0hAV2BoX71X3XU_cUL7CJsKpAcjFrgozkZ0jUFDWe1RIh3zCNft9gtnrnnm6xgqUF5EAZYasqAeLNC5OCqOrm4VoSgZsOzH_Cp1FCErA-nlf-Cybo9LtM7Kkb8brsHGHKiwAuZQbD7Sc6SaDjUfRSiGCEg-ANQlskjLQfcZzm4UTk4kFgNcJwhByRNIT7Yq6E29BsEvl4Yj1nQw-lQq0pNWKrIVZMqBVuS9US1es6xh9TgNOQDHdmysaLzIpu4RzGGM5YiQ9xD5Z2F0mh9sSzOpkSqPB4KY6SSKXWvzTO7r1RzQiCbh9rvCD5qtzzrVT1m6m2ta-rY4oXBClpUfz57vxATYQMPaK7_ujeyYJ_Uet7zIWdah3z3AA5J16ypukfqv6LWE0Zn3a1kZ5WwINtWIFUM9v0oQZ33QY6zueBq4RV-D1jtzucoTO0sgj9Uh8XgqEmYgK5cKUsS360Rr-aYFfOXhqTGGOBtT6tC8hb9tkMxedgZbl1qH0FbIGAf8-1PWDt453hpfOi-iCHVy4BiFnkAKWyAK7QK9TSTvoWLPTHjQ3fFoDACx4QJ4Jej7JAqPINhBBwVOJOtzC8BYmweVELjc=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkGH-vq_J0Pq6BgpEiUwKhCJ7ylbGX1tQ-Moj9zzDHvewMsxqcpUnnb8blrZvs_DS74A-QDJeBgEAdEC-pqy8ucHD24vRe6D3K7oZ4mqJPlsBfg2zWBJhQYi7j5bHk1JRJbijjOXpEK76e5FmloXfsxiHNLIlK8-P4uEqSeNcU_69LQ_5T8oETNVWlllw1f9lokEXddR79L6FGcI6G0VbJcDVxNyn8d-9U7e7CThiMfv6sjP4XUTcTzQyy3P7Ya_NZ51_IBmgcroXZTOSqVfcYWqLzE-c-HXdiSk7j6-IXgb3XZmrp72nKotYs9j9Cvnp9CXf3pits2xkXxm3xpJhcGGaQsy28Ude-gYGY3ywLgDbyKEDjlTLTaeQietP32M1HAfZWSNZSX-zIwJk0hAV2BoX71X3XU_cUL7CJsKpAcjFrgozkZ0jUFDWe1RIh3zCNft9gtnrnnm6xgqUF5EAZYasqAeLNC5OCqOrm4VoSgZsOzH_Cp1FCErA-nlf-Cybo9LtM7Kkb8brsHGHKiwAuZQbD7Sc6SaDjUfRSiGCEg-ANQlskjLQfcZzm4UTk4kFgNcJwhByRNIT7Yq6E29BsEvl4Yj1nQw-lQq0pNWKrIVZMqBVuS9US1es6xh9TgNOQDHdmysaLzIpu4RzGGM5YiQ9xD5Z2F0mh9sSzOpkSqPB4KY6SSKXWvzTO7r1RzQiCbh9rvCD5qtzzrVT1m6m2ta-rY4oXBClpUfz57vxATYQMPaK7_ujeyYJ_Uet7zIWdah3z3AA5J16ypukfqv6LWE0Zn3a1kZ5WwINtWIFUM9v0oQZ33QY6zueBq4RV-D1jtzucoTO0sgj9Uh8XgqEmYgK5cKUsS360Rr-aYFfOXhqTGGOBtT6tC8hb9tkMxedgZbl1qH0FbIGAf8-1PWDt453hpfOi-iCHVy4BiFnkAKWyAK7QK9TSTvoWLPTHjQ3fFoDACx4QJ4Jej7JAqPINhBBwVOJOtzC8BYmweVELjc=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1464.16,
            "totalPrice": 1273
          },
          {
            "allPassengerTotalPrice": 1275,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAQiWS3lBCUPiwqbiKEvOuLzh8nxvAE_EZ-JLm8I9ZsLKQBwAG69L6HYQyE0y-Jkh_d8xxtXmYpeewPtCRbphyxpLuVndSyHWAN_r2mjoV6jixcqqZl2COoqVl8QWId7-0ox3tmnfRXp1s3O4pJqf8Mv7uiVz_FL6deAYAcn3YNZQnXjGTYYrUT-6wLuQx9zj1KDHYZWpMVc_Bs4Pe71XSstdgmnXZV3qLHMWmHYDWTvvOU8TfR6pwfTJffngAowfJtpwpsdLimaT5eOmiWqXyJQNSGAhRmKwz3XExfRdckfa6ZX_XgQoAm92Mo7iLAdAmtA0DmDrcu7Y6TVeCLwEgRXEgv8GO-MkQf3ZD9EknAAx7jttGib7uw_Ku8q8i0YWIc4Hl_84Lnfj-VyAt5r5EqvC3NnvfubPiB1hU8TZyX-Rqn-XFFhCzCqCHcXVvvfXyfBRrSz63gwHHcAXhTaWU3mwTwh3pKRl95Tg0c3oEX866SYW7Nrg4VR-XWZDi61u6JGz4_gKyALKE2IeRDZ-ju5TvSfZGeOMXjEos3UExaTllwr62twVoXlfhBkr7DQE8nJZJkjyVvARNfquL5ImspTGp3WKU3ev1BMF_QKLYkPb6OUe29hCmRNw9JA7cKf_ZjS82SJj5I84Gw6wKmxRhIW0_HExNslLWG8nE2wyvfWlE48NUcWt6DnmvzP5mxuDsmNup2nI6K4gb7MhKH3Jdxq_Q-WC46L1imKGU_vOEk8XLe1fdVWKETXfKsVoz4NsxOH5XCcZdyOGrHonssq3cxxFBa0ygM02clms2nz2JZUtO16LaS-PEVuIOApmTBMhhmvf7qer0rmon2IWRjmihdiTBKoyWEwbOsPFq00Xb2HNGxtc7kxrE81InMnz1h3IKMnC0M4dfvCyNlT_0J6JXfTQEmOsELsaBINui3UvV-bqaqjNkdAG727IoRdjUwO4e0OoBFlE8RYWUEKqxGD0oGbBsE1I7o__YKHcaeEUobY4ahnGlTlbbdyMJm7OEAsyRmbVhxzGOsHmGaxaG_6fpQ==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAQiWS3lBCUPiwqbiKEvOuLzh8nxvAE_EZ-JLm8I9ZsLKQBwAG69L6HYQyE0y-Jkh_d8xxtXmYpeewPtCRbphyxpLuVndSyHWAN_r2mjoV6jixcqqZl2COoqVl8QWId7-0ox3tmnfRXp1s3O4pJqf8Mv7uiVz_FL6deAYAcn3YNZQnXjGTYYrUT-6wLuQx9zj1KDHYZWpMVc_Bs4Pe71XSstdgmnXZV3qLHMWmHYDWTvvOU8TfR6pwfTJffngAowfJtpwpsdLimaT5eOmiWqXyJQNSGAhRmKwz3XExfRdckfa6ZX_XgQoAm92Mo7iLAdAmtA0DmDrcu7Y6TVeCLwEgRXEgv8GO-MkQf3ZD9EknAAx7jttGib7uw_Ku8q8i0YWIc4Hl_84Lnfj-VyAt5r5EqvC3NnvfubPiB1hU8TZyX-Rqn-XFFhCzCqCHcXVvvfXyfBRrSz63gwHHcAXhTaWU3mwTwh3pKRl95Tg0c3oEX866SYW7Nrg4VR-XWZDi61u6JGz4_gKyALKE2IeRDZ-ju5TvSfZGeOMXjEos3UExaTllwr62twVoXlfhBkr7DQE8nJZJkjyVvARNfquL5ImspTGp3WKU3ev1BMF_QKLYkPb6OUe29hCmRNw9JA7cKf_ZjS82SJj5I84Gw6wKmxRhIW0_HExNslLWG8nE2wyvfWlE48NUcWt6DnmvzP5mxuDsmNup2nI6K4gb7MhKH3Jdxq_Q-WC46L1imKGU_vOEk8XLe1fdVWKETXfKsVoz4NsxOH5XCcZdyOGrHonssq3cxxFBa0ygM02clms2nz2JZUtO16LaS-PEVuIOApmTBMhhmvf7qer0rmon2IWRjmihdiTBKoyWEwbOsPFq00Xb2HNGxtc7kxrE81InMnz1h3IKMnC0M4dfvCyNlT_0J6JXfTQEmOsELsaBINui3UvV-bqaqjNkdAG727IoRdjUwO4e0OoBFlE8RYWUEKqxGD0oGbBsE1I7o__YKHcaeEUobY4ahnGlTlbbdyMJm7OEAsyRmbVhxzGOsHmGaxaG_6fpQ==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAQiWS3lBCUPiwqbiKEvOuLzh8nxvAE_EZ-JLm8I9ZsLKQBwAG69L6HYQyE0y-Jkh_d8xxtXmYpeewPtCRbphyxpLuVndSyHWAN_r2mjoV6jixcqqZl2COoqVl8QWId7-0ox3tmnfRXp1s3O4pJqf8Mv7uiVz_FL6deAYAcn3YNZQnXjGTYYrUT-6wLuQx9zj1KDHYZWpMVc_Bs4Pe71XSstdgmnXZV3qLHMWmHYDWTvvOU8TfR6pwfTJffngAowfJtpwpsdLimaT5eOmiWqXyJQNSGAhRmKwz3XExfRdckfa6ZX_XgQoAm92Mo7iLAdAmtA0DmDrcu7Y6TVeCLwEgRXEgv8GO-MkQf3ZD9EknAAx7jttGib7uw_Ku8q8i0YWIc4Hl_84Lnfj-VyAt5r5EqvC3NnvfubPiB1hU8TZyX-Rqn-XFFhCzCqCHcXVvvfXyfBRrSz63gwHHcAXhTaWU3mwTwh3pKRl95Tg0c3oEX866SYW7Nrg4VR-XWZDi61u6JGz4_gKyALKE2IeRDZ-ju5TvSfZGeOMXjEos3UExaTllwr62twVoXlfhBkr7DQE8nJZJkjyVvARNfquL5ImspTGp3WKU3ev1BMF_QKLYkPb6OUe29hCmRNw9JA7cKf_ZjS82SJj5I84Gw6wKmxRhIW0_HExNslLWG8nE2wyvfWlE48NUcWt6DnmvzP5mxuDsmNup2nI6K4gb7MhKH3Jdxq_Q-WC46L1imKGU_vOEk8XLe1fdVWKETXfKsVoz4NsxOH5XCcZdyOGrHonssq3cxxFBa0ygM02clms2nz2JZUtO16LaS-PEVuIOApmTBMhhmvf7qer0rmon2IWRjmihdiTBKoyWEwbOsPFq00Xb2HNGxtc7kxrE81InMnz1h3IKMnC0M4dfvCyNlT_0J6JXfTQEmOsELsaBINui3UvV-bqaqjNkdAG727IoRdjUwO4e0OoBFlE8RYWUEKqxGD0oGbBsE1I7o__YKHcaeEUobY4ahnGlTlbbdyMJm7OEAsyRmbVhxzGOsHmGaxaG_6fpQ==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1465.31,
            "totalPrice": 1275
          },
          {
            "allPassengerTotalPrice": 1279,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GEaAQ9WSvz_eWr7qwiqKW4hlJy3KwHUmcEOQfqi2iqP6uGrHZdO-YUb1aZEzHUbxKO8eJpAd6mNOuqvzIvvsrr3sZHEK7FWYGZrPqbqpSjWlj6wp9EalWoe9wDJS_DjiBGgrz6Ti3HHzLGvGBGl11hmC6ro_DF8siCRQQGZII5zJMPPP3wgq3EtbvJja7t8nwZqLNR4hsu537liMrASGmfphv38EySDPjRzTLr3W6-hVuKxUj8_47QjJKlkuayft5T4Htki_9Qv0740MLMMJ5D8sGrsOpElmd0QZqCVfPCU0JQ22Cerdo9kV_tcfVjubk0-4GIPWOgnN5hWJg7fc9i9AyIEDRmaP6Eft5AdgdAInCfsAP7HCvDrhQvIJ1HzL2qiueErOzRsl8O1X8yLF2332fRIHogDwrjcOCK9WNitp4_GNJ3ZJzSozlDJNCPcE1WWsH4Zad_VkZGhhF2U7h9KiHfI0SaF7stSrXe8AQb_K6uk4rftmzXJ65bpQpRj5RNeKsU9eeYgcK_NYe8MQ-Ysv-ewWZMnbpQqmZAD6KD55__0JMKh0YOJesf-L1f1bGAUYrZTq9Ta6ryE9ZZ5i150woGbvph5kfXbkVIXG7EvdxqD6T4i7FHsZFrPCONxAFEo9HELk-cRJdTnbCEbWHHDQPfve3DppLpcctCYCK9lAfOWwtAqgJ6GeT8CvWs7bADXUdY29k7xBnfqyDuAX15DKn_rExS14aJVXi3cgFkM6YkTdy6DK2R7XZv7dXlpCmG0H8g0mx9QXwN1ODHV5DixejokrEeR_KMyRSEju-YtUNQ9UqJgTnu6q3cBA3XQ23aFnV7C1UJVRt-P_JSWcYAz2ckSNtV-eVR3o11j2yKkx2puGiQsqr0W2ZR1wZsMowlZdeDtALvPh4hjtzZVShnKa33djRgbMWCjFD-MTklq60juFCyfnkI4COwzgw5giXeuqZGspBtm81f4f5mV53c4FYxqLVdjYuUieP2T_2xglg5DkzICVSoXfOlmKdntMZKaNW1Ie3ZtblnOGOJ5bqrw==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GEaAQ9WSvz_eWr7qwiqKW4hlJy3KwHUmcEOQfqi2iqP6uGrHZdO-YUb1aZEzHUbxKO8eJpAd6mNOuqvzIvvsrr3sZHEK7FWYGZrPqbqpSjWlj6wp9EalWoe9wDJS_DjiBGgrz6Ti3HHzLGvGBGl11hmC6ro_DF8siCRQQGZII5zJMPPP3wgq3EtbvJja7t8nwZqLNR4hsu537liMrASGmfphv38EySDPjRzTLr3W6-hVuKxUj8_47QjJKlkuayft5T4Htki_9Qv0740MLMMJ5D8sGrsOpElmd0QZqCVfPCU0JQ22Cerdo9kV_tcfVjubk0-4GIPWOgnN5hWJg7fc9i9AyIEDRmaP6Eft5AdgdAInCfsAP7HCvDrhQvIJ1HzL2qiueErOzRsl8O1X8yLF2332fRIHogDwrjcOCK9WNitp4_GNJ3ZJzSozlDJNCPcE1WWsH4Zad_VkZGhhF2U7h9KiHfI0SaF7stSrXe8AQb_K6uk4rftmzXJ65bpQpRj5RNeKsU9eeYgcK_NYe8MQ-Ysv-ewWZMnbpQqmZAD6KD55__0JMKh0YOJesf-L1f1bGAUYrZTq9Ta6ryE9ZZ5i150woGbvph5kfXbkVIXG7EvdxqD6T4i7FHsZFrPCONxAFEo9HELk-cRJdTnbCEbWHHDQPfve3DppLpcctCYCK9lAfOWwtAqgJ6GeT8CvWs7bADXUdY29k7xBnfqyDuAX15DKn_rExS14aJVXi3cgFkM6YkTdy6DK2R7XZv7dXlpCmG0H8g0mx9QXwN1ODHV5DixejokrEeR_KMyRSEju-YtUNQ9UqJgTnu6q3cBA3XQ23aFnV7C1UJVRt-P_JSWcYAz2ckSNtV-eVR3o11j2yKkx2puGiQsqr0W2ZR1wZsMowlZdeDtALvPh4hjtzZVShnKa33djRgbMWCjFD-MTklq60juFCyfnkI4COwzgw5giXeuqZGspBtm81f4f5mV53c4FYxqLVdjYuUieP2T_2xglg5DkzICVSoXfOlmKdntMZKaNW1Ie3ZtblnOGOJ5bqrw==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GEaAQ9WSvz_eWr7qwiqKW4hlJy3KwHUmcEOQfqi2iqP6uGrHZdO-YUb1aZEzHUbxKO8eJpAd6mNOuqvzIvvsrr3sZHEK7FWYGZrPqbqpSjWlj6wp9EalWoe9wDJS_DjiBGgrz6Ti3HHzLGvGBGl11hmC6ro_DF8siCRQQGZII5zJMPPP3wgq3EtbvJja7t8nwZqLNR4hsu537liMrASGmfphv38EySDPjRzTLr3W6-hVuKxUj8_47QjJKlkuayft5T4Htki_9Qv0740MLMMJ5D8sGrsOpElmd0QZqCVfPCU0JQ22Cerdo9kV_tcfVjubk0-4GIPWOgnN5hWJg7fc9i9AyIEDRmaP6Eft5AdgdAInCfsAP7HCvDrhQvIJ1HzL2qiueErOzRsl8O1X8yLF2332fRIHogDwrjcOCK9WNitp4_GNJ3ZJzSozlDJNCPcE1WWsH4Zad_VkZGhhF2U7h9KiHfI0SaF7stSrXe8AQb_K6uk4rftmzXJ65bpQpRj5RNeKsU9eeYgcK_NYe8MQ-Ysv-ewWZMnbpQqmZAD6KD55__0JMKh0YOJesf-L1f1bGAUYrZTq9Ta6ryE9ZZ5i150woGbvph5kfXbkVIXG7EvdxqD6T4i7FHsZFrPCONxAFEo9HELk-cRJdTnbCEbWHHDQPfve3DppLpcctCYCK9lAfOWwtAqgJ6GeT8CvWs7bADXUdY29k7xBnfqyDuAX15DKn_rExS14aJVXi3cgFkM6YkTdy6DK2R7XZv7dXlpCmG0H8g0mx9QXwN1ODHV5DixejokrEeR_KMyRSEju-YtUNQ9UqJgTnu6q3cBA3XQ23aFnV7C1UJVRt-P_JSWcYAz2ckSNtV-eVR3o11j2yKkx2puGiQsqr0W2ZR1wZsMowlZdeDtALvPh4hjtzZVShnKa33djRgbMWCjFD-MTklq60juFCyfnkI4COwzgw5giXeuqZGspBtm81f4f5mV53c4FYxqLVdjYuUieP2T_2xglg5DkzICVSoXfOlmKdntMZKaNW1Ie3ZtblnOGOJ5bqrw==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1468.67,
            "totalPrice": 1279
          },
          {
            "allPassengerTotalPrice": 1292,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoBAt2Kho2ho-5BYqcVbpp2KYlB_Z4qvmfJYIoJ8QUNFF8_BU45qBbJcU0zeUR6j1W0zOKRH_tMGz-HxGk4ivf2rAC7Qhx6gp9gBoPR5bNVixkwadHlj9UssqXWynxVZu0XwUDJBlChqP4p71hMQQg69r4hOHGGL6bM0zzdIMk4qSe3GOnoRup-3vlEDz8i1TIjmmGtOY-rgSVojRHKJDSbSyhNgOudVbUXb0okXx_O9LJ3VEgvdmEFeaNN0f5Fayqjsa2bWzC9XyA1-P4UwAc_iAozkfJH7j5BbvGIjjTK0Y7M5MlqAEkAhXYZ9t3kgTQG9F4msAbCxzEjtM1nhTjlayXcWMhMRrrfB5SgXiNWT1n7UBanL5EseUtknqC2dB1z264FmmFzNuL363LZJFfk-TbTvc63GEhipNIwaetPU5K7XBFj-p9IcHlGdUDHUG6r0CbiiUU8JmfoueRpecISWxjDXgR0P1wmXrE7jTxeBpa7bwbFl70IT3wUn4UGK374W-EMqM7sC3YTikXJ7oTzat_-h13m2_L3ho1d4ms-rpDz0sqQnVPy-yibHdp0LXGAUdSSJFcW0T4_U5pTBljMgGYVmQI6-xWDID6a-x5TCE0VOUgRLYEZbWHNecoTzcmjQQPWz1CWqIkArw6iaLuruhBsN8YRxdh9iOpkNzmCQGpiPWb9feAfJeCY1raFHX9CViFplNGXDaHMWI7hK6WU5uNCfCRVGxldLHG66UWJzbUKa8eTHu0Fiub3wcpFtSJDRnk5zAJL7YIJAmp9mbAtlQBBSc7w3t1l1_LsZZ2wELuD4XINGU-BsvJZLnDKvnNDLneglztlIH64S83VFdUUbPHaihDBaJcFcoPokYJ3vsW04jwCKTyef9gr6HVw3uGkhuaGmTnDZrJVOaKsWPCSEEG-Icui8VhaN1-yGOLLv5xi41s9kggodKCYGpvQLjYlkkCgJdMkDMXg5iZQSO35eL8QMDxdTncNOtlo2DTd_vaxhR_VQ1Wsr0JE6VuSodV0yccVp0BkjdAC2337eGosdQARcZEcGGts59aReSVUQ=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoBAt2Kho2ho-5BYqcVbpp2KYlB_Z4qvmfJYIoJ8QUNFF8_BU45qBbJcU0zeUR6j1W0zOKRH_tMGz-HxGk4ivf2rAC7Qhx6gp9gBoPR5bNVixkwadHlj9UssqXWynxVZu0XwUDJBlChqP4p71hMQQg69r4hOHGGL6bM0zzdIMk4qSe3GOnoRup-3vlEDz8i1TIjmmGtOY-rgSVojRHKJDSbSyhNgOudVbUXb0okXx_O9LJ3VEgvdmEFeaNN0f5Fayqjsa2bWzC9XyA1-P4UwAc_iAozkfJH7j5BbvGIjjTK0Y7M5MlqAEkAhXYZ9t3kgTQG9F4msAbCxzEjtM1nhTjlayXcWMhMRrrfB5SgXiNWT1n7UBanL5EseUtknqC2dB1z264FmmFzNuL363LZJFfk-TbTvc63GEhipNIwaetPU5K7XBFj-p9IcHlGdUDHUG6r0CbiiUU8JmfoueRpecISWxjDXgR0P1wmXrE7jTxeBpa7bwbFl70IT3wUn4UGK374W-EMqM7sC3YTikXJ7oTzat_-h13m2_L3ho1d4ms-rpDz0sqQnVPy-yibHdp0LXGAUdSSJFcW0T4_U5pTBljMgGYVmQI6-xWDID6a-x5TCE0VOUgRLYEZbWHNecoTzcmjQQPWz1CWqIkArw6iaLuruhBsN8YRxdh9iOpkNzmCQGpiPWb9feAfJeCY1raFHX9CViFplNGXDaHMWI7hK6WU5uNCfCRVGxldLHG66UWJzbUKa8eTHu0Fiub3wcpFtSJDRnk5zAJL7YIJAmp9mbAtlQBBSc7w3t1l1_LsZZ2wELuD4XINGU-BsvJZLnDKvnNDLneglztlIH64S83VFdUUbPHaihDBaJcFcoPokYJ3vsW04jwCKTyef9gr6HVw3uGkhuaGmTnDZrJVOaKsWPCSEEG-Icui8VhaN1-yGOLLv5xi41s9kggodKCYGpvQLjYlkkCgJdMkDMXg5iZQSO35eL8QMDxdTncNOtlo2DTd_vaxhR_VQ1Wsr0JE6VuSodV0yccVp0BkjdAC2337eGosdQARcZEcGGts59aReSVUQ=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoBAt2Kho2ho-5BYqcVbpp2KYlB_Z4qvmfJYIoJ8QUNFF8_BU45qBbJcU0zeUR6j1W0zOKRH_tMGz-HxGk4ivf2rAC7Qhx6gp9gBoPR5bNVixkwadHlj9UssqXWynxVZu0XwUDJBlChqP4p71hMQQg69r4hOHGGL6bM0zzdIMk4qSe3GOnoRup-3vlEDz8i1TIjmmGtOY-rgSVojRHKJDSbSyhNgOudVbUXb0okXx_O9LJ3VEgvdmEFeaNN0f5Fayqjsa2bWzC9XyA1-P4UwAc_iAozkfJH7j5BbvGIjjTK0Y7M5MlqAEkAhXYZ9t3kgTQG9F4msAbCxzEjtM1nhTjlayXcWMhMRrrfB5SgXiNWT1n7UBanL5EseUtknqC2dB1z264FmmFzNuL363LZJFfk-TbTvc63GEhipNIwaetPU5K7XBFj-p9IcHlGdUDHUG6r0CbiiUU8JmfoueRpecISWxjDXgR0P1wmXrE7jTxeBpa7bwbFl70IT3wUn4UGK374W-EMqM7sC3YTikXJ7oTzat_-h13m2_L3ho1d4ms-rpDz0sqQnVPy-yibHdp0LXGAUdSSJFcW0T4_U5pTBljMgGYVmQI6-xWDID6a-x5TCE0VOUgRLYEZbWHNecoTzcmjQQPWz1CWqIkArw6iaLuruhBsN8YRxdh9iOpkNzmCQGpiPWb9feAfJeCY1raFHX9CViFplNGXDaHMWI7hK6WU5uNCfCRVGxldLHG66UWJzbUKa8eTHu0Fiub3wcpFtSJDRnk5zAJL7YIJAmp9mbAtlQBBSc7w3t1l1_LsZZ2wELuD4XINGU-BsvJZLnDKvnNDLneglztlIH64S83VFdUUbPHaihDBaJcFcoPokYJ3vsW04jwCKTyef9gr6HVw3uGkhuaGmTnDZrJVOaKsWPCSEEG-Icui8VhaN1-yGOLLv5xi41s9kggodKCYGpvQLjYlkkCgJdMkDMXg5iZQSO35eL8QMDxdTncNOtlo2DTd_vaxhR_VQ1Wsr0JE6VuSodV0yccVp0BkjdAC2337eGosdQARcZEcGGts59aReSVUQ=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1496.22,
            "totalPrice": 1292
          },
          {
            "allPassengerTotalPrice": 1294,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDUWdqVmhSYx8fLn1yFmTeMe117W6ZoEktCbVzegzQHUFhSWISyoBCXknreudYobtt-GZR2Si58JZWPMcosHy4zys1IDcFjUtuQKX2YEOhGlbCQKYPgaXFjX6KyWAj5BUMz1bjB4O6bAhNUAjfMmVjCY2kjrkdyzTYoCyR3XTLhaTOMrUTUUVCazlO3cQ-pLBuhdOO-a6gqQtgMhyGKJY5bF35wsB-bmOujBYwFtbVcliXc5gHnMCJ2eVDImob7omrawt8JfzxYDwFedclcqfX_5fRaywYFHQaMCBZarDV5y4eb4i6gCki-Es1ZG3qsUY0Fx6rzKtm971iT4qbodd5xymZ4iQataNxJVymNORM92blSjX2-gO2675jqAgj7-JbLX-Zabufg1XPk86ugx9T0YwcM4ZJUoN7WTkBROgX1YpBT9pirqeizaTCywrBpMBKBUqupv6q9tO2cOdIxxZoRXr6kH5UHs6wVnwT8qju9R3gaPGxKWxYt9K8Z44C5ik-iDZ9HwPvjL9-RLNhjHNcdtOaFarVUaZacTnf6Lmzgbn6dAYiJiVS03XShV1GGV6-Ft9gTvha0429aVVpn2YPEUSKkIXVoL1N_J-eEXqwTC2ystAsLUdBs5sOalsqv0uJE-WfYfibnouWq4UDeEK8Pz7Jln2P0Jema57o102kMFV9kOihFlp16DGwZKOI05ImZ7JjOIZQMAgZL-zxU9kSn1L-IAQqULggwn-oUj_vg93vmx44UtV-d5-cJxbl87XRsGoPQec4vdlA4H75OeEiUwEqxq9fxChtBfiUHe-C0AG2jFx-LYb3GQSdS5nZ2DNt2e8Op7K5zVIMLwjNyu-hAOzzJ7VecbALvmjYtkJwqMbkUtuQWOZL20_h2qlYOgEFdOTBfNe431Al30AjLYqhfVHhH8wBmFwTEXCzCR1Fz-Cfqo2YXWs8DuXzXRIcu6USdFx-VEiAPr-z5pqFXd1JYBBpYswA2c3lwBX01vrxwOp_3cYXuUGvT5fhQCvNPov",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDUWdqVmhSYx8fLn1yFmTeMe117W6ZoEktCbVzegzQHUFhSWISyoBCXknreudYobtt-GZR2Si58JZWPMcosHy4zys1IDcFjUtuQKX2YEOhGlbCQKYPgaXFjX6KyWAj5BUMz1bjB4O6bAhNUAjfMmVjCY2kjrkdyzTYoCyR3XTLhaTOMrUTUUVCazlO3cQ-pLBuhdOO-a6gqQtgMhyGKJY5bF35wsB-bmOujBYwFtbVcliXc5gHnMCJ2eVDImob7omrawt8JfzxYDwFedclcqfX_5fRaywYFHQaMCBZarDV5y4eb4i6gCki-Es1ZG3qsUY0Fx6rzKtm971iT4qbodd5xymZ4iQataNxJVymNORM92blSjX2-gO2675jqAgj7-JbLX-Zabufg1XPk86ugx9T0YwcM4ZJUoN7WTkBROgX1YpBT9pirqeizaTCywrBpMBKBUqupv6q9tO2cOdIxxZoRXr6kH5UHs6wVnwT8qju9R3gaPGxKWxYt9K8Z44C5ik-iDZ9HwPvjL9-RLNhjHNcdtOaFarVUaZacTnf6Lmzgbn6dAYiJiVS03XShV1GGV6-Ft9gTvha0429aVVpn2YPEUSKkIXVoL1N_J-eEXqwTC2ystAsLUdBs5sOalsqv0uJE-WfYfibnouWq4UDeEK8Pz7Jln2P0Jema57o102kMFV9kOihFlp16DGwZKOI05ImZ7JjOIZQMAgZL-zxU9kSn1L-IAQqULggwn-oUj_vg93vmx44UtV-d5-cJxbl87XRsGoPQec4vdlA4H75OeEiUwEqxq9fxChtBfiUHe-C0AG2jFx-LYb3GQSdS5nZ2DNt2e8Op7K5zVIMLwjNyu-hAOzzJ7VecbALvmjYtkJwqMbkUtuQWOZL20_h2qlYOgEFdOTBfNe431Al30AjLYqhfVHhH8wBmFwTEXCzCR1Fz-Cfqo2YXWs8DuXzXRIcu6USdFx-VEiAPr-z5pqFXd1JYBBpYswA2c3lwBX01vrxwOp_3cYXuUGvT5fhQCvNPov",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000c3b984dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000c3b984dc_0",
                    "local_arrival": "2024-09-17T13:45:00.000Z",
                    "local_departure": "2024-09-17T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-17T11:45:00.000Z",
                    "utc_departure": "2024-09-17T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDUWdqVmhSYx8fLn1yFmTeMe117W6ZoEktCbVzegzQHUFhSWISyoBCXknreudYobtt-GZR2Si58JZWPMcosHy4zys1IDcFjUtuQKX2YEOhGlbCQKYPgaXFjX6KyWAj5BUMz1bjB4O6bAhNUAjfMmVjCY2kjrkdyzTYoCyR3XTLhaTOMrUTUUVCazlO3cQ-pLBuhdOO-a6gqQtgMhyGKJY5bF35wsB-bmOujBYwFtbVcliXc5gHnMCJ2eVDImob7omrawt8JfzxYDwFedclcqfX_5fRaywYFHQaMCBZarDV5y4eb4i6gCki-Es1ZG3qsUY0Fx6rzKtm971iT4qbodd5xymZ4iQataNxJVymNORM92blSjX2-gO2675jqAgj7-JbLX-Zabufg1XPk86ugx9T0YwcM4ZJUoN7WTkBROgX1YpBT9pirqeizaTCywrBpMBKBUqupv6q9tO2cOdIxxZoRXr6kH5UHs6wVnwT8qju9R3gaPGxKWxYt9K8Z44C5ik-iDZ9HwPvjL9-RLNhjHNcdtOaFarVUaZacTnf6Lmzgbn6dAYiJiVS03XShV1GGV6-Ft9gTvha0429aVVpn2YPEUSKkIXVoL1N_J-eEXqwTC2ystAsLUdBs5sOalsqv0uJE-WfYfibnouWq4UDeEK8Pz7Jln2P0Jema57o102kMFV9kOihFlp16DGwZKOI05ImZ7JjOIZQMAgZL-zxU9kSn1L-IAQqULggwn-oUj_vg93vmx44UtV-d5-cJxbl87XRsGoPQec4vdlA4H75OeEiUwEqxq9fxChtBfiUHe-C0AG2jFx-LYb3GQSdS5nZ2DNt2e8Op7K5zVIMLwjNyu-hAOzzJ7VecbALvmjYtkJwqMbkUtuQWOZL20_h2qlYOgEFdOTBfNe431Al30AjLYqhfVHhH8wBmFwTEXCzCR1Fz-Cfqo2YXWs8DuXzXRIcu6USdFx-VEiAPr-z5pqFXd1JYBBpYswA2c3lwBX01vrxwOp_3cYXuUGvT5fhQCvNPov",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1481.01,
            "totalPrice": 1294
          },
          {
            "allPassengerTotalPrice": 1295,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNOLmcT82S3ixXg-qmiPIpAS6SvCWdvoR-ZtFbzY9uRZ02h1aJuGHAxhWwyh45Vq31YBgst1PYOxYvTZx4GpdRYk8XYyPpj-yDAH1DBGGVqfVa5VMjw0-CER3UuLitlipHLe5RAFxP__N7GZCurLRRjf35UzyCMBxsGpETZ4ltN25uyv4LmHXGfMFfmanhQqr7IzgDfLiaTXo9Y5QPZIAe0TCknBPWxpBk1mPph59HdNyVFSWxOkXwmVoCqftEi2B7wDrHnq_hyK-Qra9KokzJiyoHoTyEMPqKNvRShYMFshTrsTwCGMHeGc7AfxliA1ZCbEwVOm3QWSRlmo86UixAY3ZvYtQ4HIHKZcE_OcOUW6Imuz0RLRpu9qXYd6EhTj6oH7nELbMiAETad82wwkH6ATfF_rkKUZX5tMJKhZRMCgOM52Tyd0hWiWJeUR4EBnp6imwPr49dcvMt5WdVqoCBs3qF7hPbKGrZOihpLIMhn5FSiCNCMzyCUnt5BRRyI_BfT2hVSPtNl_khZvvu-aKYHyDKAbat6pMPhpjaped4odQ18JyHhvu5AIqtpkTgcnUGFwknjblD1ShBKPONmU5SxvvOpOeScYoxkAqOmgL0B6sQDRbQ8T9fxc2_cSVt8ifRB1bz5aZusLVbcSsZQefGINS5bfoz7BHXHMB9827atN67UQ3a6sro7FItsSNqGghmH6w13AkJmwUNDKcU-1mPmBCHLD_yQrrk-ZaQueEZc5TE8_Utm8apBDAj1lg8gVZqUlU5GZFAQGL8eAUWNGTRXlkUKCFRS5tx2S0Vs7210oQCQkAgDuBxnP2AC7oWoBMOISS4W0avFNeEecgzP3Gafs4wZ6uHIW2hW7FP2BUTzqg6I2NM-wp0GJ8hqf7CZBlOowSEPFXDF1RjShEWVS4qYvtczZXjOK7jDjJeznEpgsXlhAF0jhm4n9QBcWf_vj_ucdSCT6zyTq7cLZnVpmZDLwyk3AqthTbFW_5cBwA77p2otcwKUWFZm5jbG_gIBW_OWBPvAEcOIhMYwIhddE818mZbpS5Y7PnD9QQKtuUuHZpVqTI-gVoAnaOHvGhWtVn",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNOLmcT82S3ixXg-qmiPIpAS6SvCWdvoR-ZtFbzY9uRZ02h1aJuGHAxhWwyh45Vq31YBgst1PYOxYvTZx4GpdRYk8XYyPpj-yDAH1DBGGVqfVa5VMjw0-CER3UuLitlipHLe5RAFxP__N7GZCurLRRjf35UzyCMBxsGpETZ4ltN25uyv4LmHXGfMFfmanhQqr7IzgDfLiaTXo9Y5QPZIAe0TCknBPWxpBk1mPph59HdNyVFSWxOkXwmVoCqftEi2B7wDrHnq_hyK-Qra9KokzJiyoHoTyEMPqKNvRShYMFshTrsTwCGMHeGc7AfxliA1ZCbEwVOm3QWSRlmo86UixAY3ZvYtQ4HIHKZcE_OcOUW6Imuz0RLRpu9qXYd6EhTj6oH7nELbMiAETad82wwkH6ATfF_rkKUZX5tMJKhZRMCgOM52Tyd0hWiWJeUR4EBnp6imwPr49dcvMt5WdVqoCBs3qF7hPbKGrZOihpLIMhn5FSiCNCMzyCUnt5BRRyI_BfT2hVSPtNl_khZvvu-aKYHyDKAbat6pMPhpjaped4odQ18JyHhvu5AIqtpkTgcnUGFwknjblD1ShBKPONmU5SxvvOpOeScYoxkAqOmgL0B6sQDRbQ8T9fxc2_cSVt8ifRB1bz5aZusLVbcSsZQefGINS5bfoz7BHXHMB9827atN67UQ3a6sro7FItsSNqGghmH6w13AkJmwUNDKcU-1mPmBCHLD_yQrrk-ZaQueEZc5TE8_Utm8apBDAj1lg8gVZqUlU5GZFAQGL8eAUWNGTRXlkUKCFRS5tx2S0Vs7210oQCQkAgDuBxnP2AC7oWoBMOISS4W0avFNeEecgzP3Gafs4wZ6uHIW2hW7FP2BUTzqg6I2NM-wp0GJ8hqf7CZBlOowSEPFXDF1RjShEWVS4qYvtczZXjOK7jDjJeznEpgsXlhAF0jhm4n9QBcWf_vj_ucdSCT6zyTq7cLZnVpmZDLwyk3AqthTbFW_5cBwA77p2otcwKUWFZm5jbG_gIBW_OWBPvAEcOIhMYwIhddE818mZbpS5Y7PnD9QQKtuUuHZpVqTI-gVoAnaOHvGhWtVn",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNOLmcT82S3ixXg-qmiPIpAS6SvCWdvoR-ZtFbzY9uRZ02h1aJuGHAxhWwyh45Vq31YBgst1PYOxYvTZx4GpdRYk8XYyPpj-yDAH1DBGGVqfVa5VMjw0-CER3UuLitlipHLe5RAFxP__N7GZCurLRRjf35UzyCMBxsGpETZ4ltN25uyv4LmHXGfMFfmanhQqr7IzgDfLiaTXo9Y5QPZIAe0TCknBPWxpBk1mPph59HdNyVFSWxOkXwmVoCqftEi2B7wDrHnq_hyK-Qra9KokzJiyoHoTyEMPqKNvRShYMFshTrsTwCGMHeGc7AfxliA1ZCbEwVOm3QWSRlmo86UixAY3ZvYtQ4HIHKZcE_OcOUW6Imuz0RLRpu9qXYd6EhTj6oH7nELbMiAETad82wwkH6ATfF_rkKUZX5tMJKhZRMCgOM52Tyd0hWiWJeUR4EBnp6imwPr49dcvMt5WdVqoCBs3qF7hPbKGrZOihpLIMhn5FSiCNCMzyCUnt5BRRyI_BfT2hVSPtNl_khZvvu-aKYHyDKAbat6pMPhpjaped4odQ18JyHhvu5AIqtpkTgcnUGFwknjblD1ShBKPONmU5SxvvOpOeScYoxkAqOmgL0B6sQDRbQ8T9fxc2_cSVt8ifRB1bz5aZusLVbcSsZQefGINS5bfoz7BHXHMB9827atN67UQ3a6sro7FItsSNqGghmH6w13AkJmwUNDKcU-1mPmBCHLD_yQrrk-ZaQueEZc5TE8_Utm8apBDAj1lg8gVZqUlU5GZFAQGL8eAUWNGTRXlkUKCFRS5tx2S0Vs7210oQCQkAgDuBxnP2AC7oWoBMOISS4W0avFNeEecgzP3Gafs4wZ6uHIW2hW7FP2BUTzqg6I2NM-wp0GJ8hqf7CZBlOowSEPFXDF1RjShEWVS4qYvtczZXjOK7jDjJeznEpgsXlhAF0jhm4n9QBcWf_vj_ucdSCT6zyTq7cLZnVpmZDLwyk3AqthTbFW_5cBwA77p2otcwKUWFZm5jbG_gIBW_OWBPvAEcOIhMYwIhddE818mZbpS5Y7PnD9QQKtuUuHZpVqTI-gVoAnaOHvGhWtVn",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1498,
            "totalPrice": 1295
          },
          {
            "allPassengerTotalPrice": 1295,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCeXLfakAvLHgCo0a5apr9cvB-Kq3cRX46YJyiHhIJe32IPJEARojLzD9Ik7xSafHsH9qI2yrR1ERpdfRc86J--MaCz5ljP34jYJv3h5YuBP904oG7VXLNgn8n3onNt5FVHOt7APRpZ36ibYSf-TLn8VxMxEiw4iXP9GCa9iam7fd8Gcow8kHFBpTd4EC28PCYhS2wD7tGwB6MUTNPMgcgQa40bvRppWHB4PmW5gfOzDr3pqr2T6WY4vv8WK0EMR1xkaG0RaIQQIGFBi6i9GpLGS9lNSLiPY6ID2fwOHbdxWrQPPzfN3kKPTk-ODhOOCJG_sn0IslEKQsSIQ9cv8ObpVQHmJlHbEj_-UJ6Zkm5zoSuMWcEUZ5OxbZp7CNS1UvnhvxyD8qJVb7vMb34X3YOnAKYXAXBEBXiOgu3hV1mIL67EYEe2X6X59ZtNCpSh-AL5o9oTiMv_ESiQrJdKxTC4iFN24kHvMlu2-P3V2uJl_s5EOIbQ0vq2baEvSPeHQi7sKeitElUFfvEqhVvm061QeBFSijTUsryURX5QXM_r6bx83HWqsQsbdnxGvYQtCJt61jwN5XqXccLduifggsIetPWU5bhshslUFtcWnQz5Cm1qQxr0CZi4He9OqzJ7KQwf4CV5oLZ9HnvPbQqZ2KpjukctPOJ16H9zs98KSiPBjM0yZ0nFu01vIZYvf0sM8px2Okw8uGPQXH9qqBlCm2NCrkuf6TqPOFst4P3Crab_zoQePk-9HHnYdkHF-2XryNxXQpXbgN-5hHMEDmFCYRVMnTe6XxXUdbciYIlSFdHbNLdgCV68bsZfN2t73xHKPXWjqRlXYeIXKwQNxfVH0vkXxUtdFpXhdqlo8EwwB-CylNAbhqWlxIZeepi8sCdeu3NvdjNCSymyffgzExDGCXiBshdZMzF_gNt4RhDEigofdiR6a9tlb85lkMJ_RHDuK5P9EHvVHdk89PJf-ZSy15IJq2DSvU1yqTuHaN3GRLWk5_NzcFpKdBd6HaCNuA4oORi2fqeZDtXaVYrHntOAQ_phKINrOUIAJFd0SI1QAldb5ujB_UarWmD2ZHkAT72RBo",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCeXLfakAvLHgCo0a5apr9cvB-Kq3cRX46YJyiHhIJe32IPJEARojLzD9Ik7xSafHsH9qI2yrR1ERpdfRc86J--MaCz5ljP34jYJv3h5YuBP904oG7VXLNgn8n3onNt5FVHOt7APRpZ36ibYSf-TLn8VxMxEiw4iXP9GCa9iam7fd8Gcow8kHFBpTd4EC28PCYhS2wD7tGwB6MUTNPMgcgQa40bvRppWHB4PmW5gfOzDr3pqr2T6WY4vv8WK0EMR1xkaG0RaIQQIGFBi6i9GpLGS9lNSLiPY6ID2fwOHbdxWrQPPzfN3kKPTk-ODhOOCJG_sn0IslEKQsSIQ9cv8ObpVQHmJlHbEj_-UJ6Zkm5zoSuMWcEUZ5OxbZp7CNS1UvnhvxyD8qJVb7vMb34X3YOnAKYXAXBEBXiOgu3hV1mIL67EYEe2X6X59ZtNCpSh-AL5o9oTiMv_ESiQrJdKxTC4iFN24kHvMlu2-P3V2uJl_s5EOIbQ0vq2baEvSPeHQi7sKeitElUFfvEqhVvm061QeBFSijTUsryURX5QXM_r6bx83HWqsQsbdnxGvYQtCJt61jwN5XqXccLduifggsIetPWU5bhshslUFtcWnQz5Cm1qQxr0CZi4He9OqzJ7KQwf4CV5oLZ9HnvPbQqZ2KpjukctPOJ16H9zs98KSiPBjM0yZ0nFu01vIZYvf0sM8px2Okw8uGPQXH9qqBlCm2NCrkuf6TqPOFst4P3Crab_zoQePk-9HHnYdkHF-2XryNxXQpXbgN-5hHMEDmFCYRVMnTe6XxXUdbciYIlSFdHbNLdgCV68bsZfN2t73xHKPXWjqRlXYeIXKwQNxfVH0vkXxUtdFpXhdqlo8EwwB-CylNAbhqWlxIZeepi8sCdeu3NvdjNCSymyffgzExDGCXiBshdZMzF_gNt4RhDEigofdiR6a9tlb85lkMJ_RHDuK5P9EHvVHdk89PJf-ZSy15IJq2DSvU1yqTuHaN3GRLWk5_NzcFpKdBd6HaCNuA4oORi2fqeZDtXaVYrHntOAQ_phKINrOUIAJFd0SI1QAldb5ujB_UarWmD2ZHkAT72RBo",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCeXLfakAvLHgCo0a5apr9cvB-Kq3cRX46YJyiHhIJe32IPJEARojLzD9Ik7xSafHsH9qI2yrR1ERpdfRc86J--MaCz5ljP34jYJv3h5YuBP904oG7VXLNgn8n3onNt5FVHOt7APRpZ36ibYSf-TLn8VxMxEiw4iXP9GCa9iam7fd8Gcow8kHFBpTd4EC28PCYhS2wD7tGwB6MUTNPMgcgQa40bvRppWHB4PmW5gfOzDr3pqr2T6WY4vv8WK0EMR1xkaG0RaIQQIGFBi6i9GpLGS9lNSLiPY6ID2fwOHbdxWrQPPzfN3kKPTk-ODhOOCJG_sn0IslEKQsSIQ9cv8ObpVQHmJlHbEj_-UJ6Zkm5zoSuMWcEUZ5OxbZp7CNS1UvnhvxyD8qJVb7vMb34X3YOnAKYXAXBEBXiOgu3hV1mIL67EYEe2X6X59ZtNCpSh-AL5o9oTiMv_ESiQrJdKxTC4iFN24kHvMlu2-P3V2uJl_s5EOIbQ0vq2baEvSPeHQi7sKeitElUFfvEqhVvm061QeBFSijTUsryURX5QXM_r6bx83HWqsQsbdnxGvYQtCJt61jwN5XqXccLduifggsIetPWU5bhshslUFtcWnQz5Cm1qQxr0CZi4He9OqzJ7KQwf4CV5oLZ9HnvPbQqZ2KpjukctPOJ16H9zs98KSiPBjM0yZ0nFu01vIZYvf0sM8px2Okw8uGPQXH9qqBlCm2NCrkuf6TqPOFst4P3Crab_zoQePk-9HHnYdkHF-2XryNxXQpXbgN-5hHMEDmFCYRVMnTe6XxXUdbciYIlSFdHbNLdgCV68bsZfN2t73xHKPXWjqRlXYeIXKwQNxfVH0vkXxUtdFpXhdqlo8EwwB-CylNAbhqWlxIZeepi8sCdeu3NvdjNCSymyffgzExDGCXiBshdZMzF_gNt4RhDEigofdiR6a9tlb85lkMJ_RHDuK5P9EHvVHdk89PJf-ZSy15IJq2DSvU1yqTuHaN3GRLWk5_NzcFpKdBd6HaCNuA4oORi2fqeZDtXaVYrHntOAQ_phKINrOUIAJFd0SI1QAldb5ujB_UarWmD2ZHkAT72RBo",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1498.66,
            "totalPrice": 1295
          },
          {
            "allPassengerTotalPrice": 1298,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcL9efdmrCuo744U8Fp777OnNnQFXPjCykntBvOr_9ADhUSA3SQCgN5O0wVIisCApzcBoF-bB8IfY8JgnkXrMF6k9CNqqoitgnOH6d9S_i7rbRcQRG2lSZB0DCiL-h2PFiISUKNDxaWl8gxAuzUqs7_-nX5z7BUizy5c1v8jXwpmHczvd15PbvPQaLN_FMSPHoeWZEgyHHBmP58k5phWf4NS6gJ9LvKC_TOuw8GnnsTlRJ1asIDGwDUnByjL4mUvTUdJeLxFKqNifvQj1463GBDdZvd8L1ic5przna6wTM1QT3Evlyw-TrugTud0GJVIwPVccwSCJOXZSEBhrimV59adM7J22vCr40OAUpOWK9bz1U4w8xoR3EC_tuDrDqj-kCgC8bW0koVRTBohNTqSKhiSxgHr_NqH8WDmUc6AJ4PiPjfPg_3nX-hUmiSH-6mwT7kR2N1Uhgv1F1UxGrljI9xIPF8Avu5Ju3B-T36Tzh59UMNI38cIDOSqRNyAqN4um2Oslsv1ydMWm8sercfvMVwOvgE09TnPlTSf2a487z5ye_iXKEjLTO6sgsOTFbX_150YyQEm5XfXXhs3g-uvdtBFhQWq0-amW-y9Xu1DF8ErkzqpVmqUYWL80xrTH29E9QEToUE9HJs9W32nWxs1haDmHrdSIgJ1ifQTvMGM5jyuBOM6hcm-AR6s0tfyNQNUrbOp7h_z9yrg5nQHrKVkoffXovx4dLM1BjVgx0kbmnajCgRTNXNp1-zi8rfDihT8Msvfd0kY9_Lbq7fbnpE7Xa8c65D_iY9fO3hpeiiadlBsCpBSEtlAb8SJWbwTW-5X07yRox1GxrrgbDtL75Bkp30umptp4CpDcXLpJsJ-wKz2lIva6D4QD3cWWStGI9w3lSgXZx3Mw1_Z6hsLBryRVYVO9NuBBoPo0jOUM11QWgqajZpy62bmcxopg2Vl3WWDxaHMIfv4yrlwEkvOJac0_LmGPZrtgAcjkSTeNq6wUoZyRR3NvxkPdT9EEgJt1TVL0",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcL9efdmrCuo744U8Fp777OnNnQFXPjCykntBvOr_9ADhUSA3SQCgN5O0wVIisCApzcBoF-bB8IfY8JgnkXrMF6k9CNqqoitgnOH6d9S_i7rbRcQRG2lSZB0DCiL-h2PFiISUKNDxaWl8gxAuzUqs7_-nX5z7BUizy5c1v8jXwpmHczvd15PbvPQaLN_FMSPHoeWZEgyHHBmP58k5phWf4NS6gJ9LvKC_TOuw8GnnsTlRJ1asIDGwDUnByjL4mUvTUdJeLxFKqNifvQj1463GBDdZvd8L1ic5przna6wTM1QT3Evlyw-TrugTud0GJVIwPVccwSCJOXZSEBhrimV59adM7J22vCr40OAUpOWK9bz1U4w8xoR3EC_tuDrDqj-kCgC8bW0koVRTBohNTqSKhiSxgHr_NqH8WDmUc6AJ4PiPjfPg_3nX-hUmiSH-6mwT7kR2N1Uhgv1F1UxGrljI9xIPF8Avu5Ju3B-T36Tzh59UMNI38cIDOSqRNyAqN4um2Oslsv1ydMWm8sercfvMVwOvgE09TnPlTSf2a487z5ye_iXKEjLTO6sgsOTFbX_150YyQEm5XfXXhs3g-uvdtBFhQWq0-amW-y9Xu1DF8ErkzqpVmqUYWL80xrTH29E9QEToUE9HJs9W32nWxs1haDmHrdSIgJ1ifQTvMGM5jyuBOM6hcm-AR6s0tfyNQNUrbOp7h_z9yrg5nQHrKVkoffXovx4dLM1BjVgx0kbmnajCgRTNXNp1-zi8rfDihT8Msvfd0kY9_Lbq7fbnpE7Xa8c65D_iY9fO3hpeiiadlBsCpBSEtlAb8SJWbwTW-5X07yRox1GxrrgbDtL75Bkp30umptp4CpDcXLpJsJ-wKz2lIva6D4QD3cWWStGI9w3lSgXZx3Mw1_Z6hsLBryRVYVO9NuBBoPo0jOUM11QWgqajZpy62bmcxopg2Vl3WWDxaHMIfv4yrlwEkvOJac0_LmGPZrtgAcjkSTeNq6wUoZyRR3NvxkPdT9EEgJt1TVL0",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcL9efdmrCuo744U8Fp777OnNnQFXPjCykntBvOr_9ADhUSA3SQCgN5O0wVIisCApzcBoF-bB8IfY8JgnkXrMF6k9CNqqoitgnOH6d9S_i7rbRcQRG2lSZB0DCiL-h2PFiISUKNDxaWl8gxAuzUqs7_-nX5z7BUizy5c1v8jXwpmHczvd15PbvPQaLN_FMSPHoeWZEgyHHBmP58k5phWf4NS6gJ9LvKC_TOuw8GnnsTlRJ1asIDGwDUnByjL4mUvTUdJeLxFKqNifvQj1463GBDdZvd8L1ic5przna6wTM1QT3Evlyw-TrugTud0GJVIwPVccwSCJOXZSEBhrimV59adM7J22vCr40OAUpOWK9bz1U4w8xoR3EC_tuDrDqj-kCgC8bW0koVRTBohNTqSKhiSxgHr_NqH8WDmUc6AJ4PiPjfPg_3nX-hUmiSH-6mwT7kR2N1Uhgv1F1UxGrljI9xIPF8Avu5Ju3B-T36Tzh59UMNI38cIDOSqRNyAqN4um2Oslsv1ydMWm8sercfvMVwOvgE09TnPlTSf2a487z5ye_iXKEjLTO6sgsOTFbX_150YyQEm5XfXXhs3g-uvdtBFhQWq0-amW-y9Xu1DF8ErkzqpVmqUYWL80xrTH29E9QEToUE9HJs9W32nWxs1haDmHrdSIgJ1ifQTvMGM5jyuBOM6hcm-AR6s0tfyNQNUrbOp7h_z9yrg5nQHrKVkoffXovx4dLM1BjVgx0kbmnajCgRTNXNp1-zi8rfDihT8Msvfd0kY9_Lbq7fbnpE7Xa8c65D_iY9fO3hpeiiadlBsCpBSEtlAb8SJWbwTW-5X07yRox1GxrrgbDtL75Bkp30umptp4CpDcXLpJsJ-wKz2lIva6D4QD3cWWStGI9w3lSgXZx3Mw1_Z6hsLBryRVYVO9NuBBoPo0jOUM11QWgqajZpy62bmcxopg2Vl3WWDxaHMIfv4yrlwEkvOJac0_LmGPZrtgAcjkSTeNq6wUoZyRR3NvxkPdT9EEgJt1TVL0",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1501.15,
            "totalPrice": 1298
          },
          {
            "allPassengerTotalPrice": 1300,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0V_h5TL0uv2qIR7MbdPc8l-LYT1GtbP2GJcEyxOrvebuVUoJj6OTW7bhRhXxhxvPntaW_zzM8CMvMDZv6pJx_Mquu2d5ukZwod98t2qQ5zfpNnl5SINKm2uJxxpPlAV-d0YETFhzWvmgkNLE21AZyari0_7-HdSkLYQMw46bZKra_eolAOiXAtgArO6ArmIDAEvzdaQd5hUr_wVpSremuxyPLF723Abld8yQyQEXHP8jQaX3zB5DT2sXBue0rBQ_Jb4_m9mOQC_fgqcRy8OIt1FfE62y13clLJ_XtiPXWeNVnC86QpfojlhKtyJqR20lt47TGTIEHF8lWG3Zp9jax8AkJRYiO8QJI6b6a0RVAIzKdqyYga29-96FZHfCwcpFloS0ctSzsf2DXt33CJNMJf0hzYPfmvhG23XTNI4JypX9uPUHhWfpvUQyi9g37LOnmDjJsDjCgSb4EY35_NWTfRHaRmbhUDXLXZFWE0aOexocJPaWD6pvCWkj59bUR1vQWlHTXb5q8qwLr5tQAspJEqA9PkR8qivrSRR4AsrBiyMfbi25WYJs5PYQxNBhdmekWdkCbGZ9rcrI6rmc76EFhUxa1-VPKgv1eD9ZaWnCBZnDwFfNyva04JrJt9tbUARrDKXLr7fOxE0jzCXZQvd4E16Q7ouCXcHbNsTEmhN8amtUKWvCOaWwsthNw7P0vL-19oZTF-TrIwVSfNmk2gGPnsHNlU8z5pOQ1bkNwNHTvQc03y876-t4u1h4tsKTkoBWrBNRbq7CDG9_yruHuyFVRXh2-UMGQp6kaULfshTo0uCTXLsi0vABkaiZ4j7NXD4tcUTGgoH0RVtBK456btNEgeDXj8uY_s6p4P6Vz3uGt1_bhKa0J3Q6pMeQWJLm3UTM-xWvo48Ex9LfnzcVJcOTeRL2m3ue-2TizdJ79Waq1VUlKbWBU_Ki7BwMFU-nvBKs0dyBj15tN-K57rSW_spGL5vCp7LU07mTN38oJexbeekFnBk88Hn4PV5Q0hXn_VAoBRj7jGPdllPsSezlL7u5WA==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0V_h5TL0uv2qIR7MbdPc8l-LYT1GtbP2GJcEyxOrvebuVUoJj6OTW7bhRhXxhxvPntaW_zzM8CMvMDZv6pJx_Mquu2d5ukZwod98t2qQ5zfpNnl5SINKm2uJxxpPlAV-d0YETFhzWvmgkNLE21AZyari0_7-HdSkLYQMw46bZKra_eolAOiXAtgArO6ArmIDAEvzdaQd5hUr_wVpSremuxyPLF723Abld8yQyQEXHP8jQaX3zB5DT2sXBue0rBQ_Jb4_m9mOQC_fgqcRy8OIt1FfE62y13clLJ_XtiPXWeNVnC86QpfojlhKtyJqR20lt47TGTIEHF8lWG3Zp9jax8AkJRYiO8QJI6b6a0RVAIzKdqyYga29-96FZHfCwcpFloS0ctSzsf2DXt33CJNMJf0hzYPfmvhG23XTNI4JypX9uPUHhWfpvUQyi9g37LOnmDjJsDjCgSb4EY35_NWTfRHaRmbhUDXLXZFWE0aOexocJPaWD6pvCWkj59bUR1vQWlHTXb5q8qwLr5tQAspJEqA9PkR8qivrSRR4AsrBiyMfbi25WYJs5PYQxNBhdmekWdkCbGZ9rcrI6rmc76EFhUxa1-VPKgv1eD9ZaWnCBZnDwFfNyva04JrJt9tbUARrDKXLr7fOxE0jzCXZQvd4E16Q7ouCXcHbNsTEmhN8amtUKWvCOaWwsthNw7P0vL-19oZTF-TrIwVSfNmk2gGPnsHNlU8z5pOQ1bkNwNHTvQc03y876-t4u1h4tsKTkoBWrBNRbq7CDG9_yruHuyFVRXh2-UMGQp6kaULfshTo0uCTXLsi0vABkaiZ4j7NXD4tcUTGgoH0RVtBK456btNEgeDXj8uY_s6p4P6Vz3uGt1_bhKa0J3Q6pMeQWJLm3UTM-xWvo48Ex9LfnzcVJcOTeRL2m3ue-2TizdJ79Waq1VUlKbWBU_Ki7BwMFU-nvBKs0dyBj15tN-K57rSW_spGL5vCp7LU07mTN38oJexbeekFnBk88Hn4PV5Q0hXn_VAoBRj7jGPdllPsSezlL7u5WA==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0V_h5TL0uv2qIR7MbdPc8l-LYT1GtbP2GJcEyxOrvebuVUoJj6OTW7bhRhXxhxvPntaW_zzM8CMvMDZv6pJx_Mquu2d5ukZwod98t2qQ5zfpNnl5SINKm2uJxxpPlAV-d0YETFhzWvmgkNLE21AZyari0_7-HdSkLYQMw46bZKra_eolAOiXAtgArO6ArmIDAEvzdaQd5hUr_wVpSremuxyPLF723Abld8yQyQEXHP8jQaX3zB5DT2sXBue0rBQ_Jb4_m9mOQC_fgqcRy8OIt1FfE62y13clLJ_XtiPXWeNVnC86QpfojlhKtyJqR20lt47TGTIEHF8lWG3Zp9jax8AkJRYiO8QJI6b6a0RVAIzKdqyYga29-96FZHfCwcpFloS0ctSzsf2DXt33CJNMJf0hzYPfmvhG23XTNI4JypX9uPUHhWfpvUQyi9g37LOnmDjJsDjCgSb4EY35_NWTfRHaRmbhUDXLXZFWE0aOexocJPaWD6pvCWkj59bUR1vQWlHTXb5q8qwLr5tQAspJEqA9PkR8qivrSRR4AsrBiyMfbi25WYJs5PYQxNBhdmekWdkCbGZ9rcrI6rmc76EFhUxa1-VPKgv1eD9ZaWnCBZnDwFfNyva04JrJt9tbUARrDKXLr7fOxE0jzCXZQvd4E16Q7ouCXcHbNsTEmhN8amtUKWvCOaWwsthNw7P0vL-19oZTF-TrIwVSfNmk2gGPnsHNlU8z5pOQ1bkNwNHTvQc03y876-t4u1h4tsKTkoBWrBNRbq7CDG9_yruHuyFVRXh2-UMGQp6kaULfshTo0uCTXLsi0vABkaiZ4j7NXD4tcUTGgoH0RVtBK456btNEgeDXj8uY_s6p4P6Vz3uGt1_bhKa0J3Q6pMeQWJLm3UTM-xWvo48Ex9LfnzcVJcOTeRL2m3ue-2TizdJ79Waq1VUlKbWBU_Ki7BwMFU-nvBKs0dyBj15tN-K57rSW_spGL5vCp7LU07mTN38oJexbeekFnBk88Hn4PV5Q0hXn_VAoBRj7jGPdllPsSezlL7u5WA==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1502.3,
            "totalPrice": 1300
          },
          {
            "allPassengerTotalPrice": 1304,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGYBY8v3u1rMyDagh7nIOLhq130DJG1y_rFmcQsIqAF3Panwm8eaNBAHzTJ2NLhMNGbJNo-KzYRc9t8GXiiWlMItg-iI_xdJ4SHBUygEe-VtvoZONzpXSxjr7pDK6d02AHx1mdKPpqnfzDq7eSRB1_hRFWuPntVTuvPbrfpYAso-KD2UO8FEk-4_xBiQjJM4sJtS6VQENz49Ben5G1NwxM1Go1Hk8SdeK2x2FBWLKOWF87hwZSD7tpSxEOh4V-DXINEYs7QgPWLgArvli82-9RVtdS6o8FYSIfLwAtbBAw8-a7vt4D8rWXRPuTNNHxcl62mYFxis5QNck0laB4XqgsyC83yFYGSPSK4XdQCFvRiGWJnSF9Ham9eIMdx4NLM2BJF6zw6W9_c1pjNDONu4HJ0-1NhnshK_1iRlD-YOwwHZtGXPddFtxGIy854YuP2edvCg8t9WfTm9MTHbn8XA5l1W9KfWBWR8vFMCgsDeqT0_XSnX6earoMl4Li4tQizBodyughvdHOrQGIxETnQHYsYTEFaJUAQ0fiAUIIDNhfOgGXnHIXS4jQbyqEGkNgk35BRmzEEOjmaAMp5yMkieef0RPDvVu_LQRo_dUmxQ-XBPJeEUU5elZtNtrDnauvBQnqYvz85cOmHNZzd5PdDOLDDbW-sTlPJUZ9MIzh2ZY0CYWc8PYGavYMpmKHE3tGALoagbPyoNk7zHyxdq552l9V65vkRxpPSY3mdNgaCrTVbhB0G4igfV0WXtSKH56SnTlqM7m4ge0oFvSSx_VaYZ_Tm5gYYo3TG7OtR6_OSDyho2hx5H5dmFy0885NnMVcTSt-81MS8304nnlok9giU3yqxlm5UTj1qBNyAj3D74RnhTWMFR3MGjYdj1JvGi-lUe3d5iMhRFFcizkfhsDiYD-cu6vrd_VmLmKBwp-l4HZKbU3U_SoNSNf6R5yf7oC60NUQ8gWHt_XCdjgzFSwUZip396gbdDE90W89xUnjboBaiAuUWuDxoA_RdzrAR-lwVWtNWTJLAVaI1sGow5z20v7d4fV-DLkJlBnzTYk1V_lxXTp0De7IaRz7IXaIEzwhWIH",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGYBY8v3u1rMyDagh7nIOLhq130DJG1y_rFmcQsIqAF3Panwm8eaNBAHzTJ2NLhMNGbJNo-KzYRc9t8GXiiWlMItg-iI_xdJ4SHBUygEe-VtvoZONzpXSxjr7pDK6d02AHx1mdKPpqnfzDq7eSRB1_hRFWuPntVTuvPbrfpYAso-KD2UO8FEk-4_xBiQjJM4sJtS6VQENz49Ben5G1NwxM1Go1Hk8SdeK2x2FBWLKOWF87hwZSD7tpSxEOh4V-DXINEYs7QgPWLgArvli82-9RVtdS6o8FYSIfLwAtbBAw8-a7vt4D8rWXRPuTNNHxcl62mYFxis5QNck0laB4XqgsyC83yFYGSPSK4XdQCFvRiGWJnSF9Ham9eIMdx4NLM2BJF6zw6W9_c1pjNDONu4HJ0-1NhnshK_1iRlD-YOwwHZtGXPddFtxGIy854YuP2edvCg8t9WfTm9MTHbn8XA5l1W9KfWBWR8vFMCgsDeqT0_XSnX6earoMl4Li4tQizBodyughvdHOrQGIxETnQHYsYTEFaJUAQ0fiAUIIDNhfOgGXnHIXS4jQbyqEGkNgk35BRmzEEOjmaAMp5yMkieef0RPDvVu_LQRo_dUmxQ-XBPJeEUU5elZtNtrDnauvBQnqYvz85cOmHNZzd5PdDOLDDbW-sTlPJUZ9MIzh2ZY0CYWc8PYGavYMpmKHE3tGALoagbPyoNk7zHyxdq552l9V65vkRxpPSY3mdNgaCrTVbhB0G4igfV0WXtSKH56SnTlqM7m4ge0oFvSSx_VaYZ_Tm5gYYo3TG7OtR6_OSDyho2hx5H5dmFy0885NnMVcTSt-81MS8304nnlok9giU3yqxlm5UTj1qBNyAj3D74RnhTWMFR3MGjYdj1JvGi-lUe3d5iMhRFFcizkfhsDiYD-cu6vrd_VmLmKBwp-l4HZKbU3U_SoNSNf6R5yf7oC60NUQ8gWHt_XCdjgzFSwUZip396gbdDE90W89xUnjboBaiAuUWuDxoA_RdzrAR-lwVWtNWTJLAVaI1sGow5z20v7d4fV-DLkJlBnzTYk1V_lxXTp0De7IaRz7IXaIEzwhWIH",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGYBY8v3u1rMyDagh7nIOLhq130DJG1y_rFmcQsIqAF3Panwm8eaNBAHzTJ2NLhMNGbJNo-KzYRc9t8GXiiWlMItg-iI_xdJ4SHBUygEe-VtvoZONzpXSxjr7pDK6d02AHx1mdKPpqnfzDq7eSRB1_hRFWuPntVTuvPbrfpYAso-KD2UO8FEk-4_xBiQjJM4sJtS6VQENz49Ben5G1NwxM1Go1Hk8SdeK2x2FBWLKOWF87hwZSD7tpSxEOh4V-DXINEYs7QgPWLgArvli82-9RVtdS6o8FYSIfLwAtbBAw8-a7vt4D8rWXRPuTNNHxcl62mYFxis5QNck0laB4XqgsyC83yFYGSPSK4XdQCFvRiGWJnSF9Ham9eIMdx4NLM2BJF6zw6W9_c1pjNDONu4HJ0-1NhnshK_1iRlD-YOwwHZtGXPddFtxGIy854YuP2edvCg8t9WfTm9MTHbn8XA5l1W9KfWBWR8vFMCgsDeqT0_XSnX6earoMl4Li4tQizBodyughvdHOrQGIxETnQHYsYTEFaJUAQ0fiAUIIDNhfOgGXnHIXS4jQbyqEGkNgk35BRmzEEOjmaAMp5yMkieef0RPDvVu_LQRo_dUmxQ-XBPJeEUU5elZtNtrDnauvBQnqYvz85cOmHNZzd5PdDOLDDbW-sTlPJUZ9MIzh2ZY0CYWc8PYGavYMpmKHE3tGALoagbPyoNk7zHyxdq552l9V65vkRxpPSY3mdNgaCrTVbhB0G4igfV0WXtSKH56SnTlqM7m4ge0oFvSSx_VaYZ_Tm5gYYo3TG7OtR6_OSDyho2hx5H5dmFy0885NnMVcTSt-81MS8304nnlok9giU3yqxlm5UTj1qBNyAj3D74RnhTWMFR3MGjYdj1JvGi-lUe3d5iMhRFFcizkfhsDiYD-cu6vrd_VmLmKBwp-l4HZKbU3U_SoNSNf6R5yf7oC60NUQ8gWHt_XCdjgzFSwUZip396gbdDE90W89xUnjboBaiAuUWuDxoA_RdzrAR-lwVWtNWTJLAVaI1sGow5z20v7d4fV-DLkJlBnzTYk1V_lxXTp0De7IaRz7IXaIEzwhWIH",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1505.66,
            "totalPrice": 1304
          },
          {
            "allPassengerTotalPrice": 1319,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGh-S_14PQsQk1YRkS3b2DrhoUbN2VdwEaRMrp23ymUeGH9xgkpVtxI2CzPRYXYvCRobXWmW16a3ZYQYNNZa5F3bheurzTaajSKJ2XD0d0jSNBr8LfUcl4AST2LPK_wKpQ0X2fBsmHNq9nhqfLDkpgzrouutH376p3bICg5CQ6VD3odsRetTHN2JBateVDff-U_dS-jly1YuFnc0xIHaa1NKxjS_xhDLQlWdv6uTmkVDYNaJyaxtT_wXetu6OWSoo8SgkZqkFtreS3Wy4yyO0LuCA7IUEckBQ_AQ0mvNXULDjDLFknOuPS4wCMPNsf0ODBnFS947ZYYnFJ4JmYnpCcxuvPbDuJ6XBLAL530RWWiTUyBvL98qP_lGj61MSqn7alxo0p1mihL7wuk_jaSe0-TPZbVVwwfRDoEPzdZ_tjNNVIk36K4-zEbIJ-pz0qJfSQEQ_sXoimSVaNkOJyNPVUAdLi_ZFbaeT83D4F32uxIfO70VmF73Q5KgP9mZa-jtLFwy_kUetnpvAv24hMM4Cd3F7c1nKP-MNR_aSIBEmyFsxxXEBj9cuUPfaIlSvM_TekVuEd8aBu54PjjxS_FHVgGccpTD-D264zwHJ4vx2QVJthRK_i5tRLpJeZJkkc8YYhRQ8vpKFUiL4dk0ItQvlBc9ecDHe91kNCl_nwpLGCgSe57mN7tPVp7exXuYowDJc4ANfDWvaSannv5bO86BpAbPTFs4_5hkAYJJNeerxDFQklrXQ-Ry8j7vqVxOWgN6Qjou3fqngMVVGFMjdq-iB1VHMbHwokCGLKcbQ0czf_bBxSEtc6M7hkQZp8wDa8RaMzjAe6i4Gmn9g510IhDF2dZll8MHN4Ep3iy4AndRVe-eHTFxQA6QyqZjMGGOljvCtTYdLu9U0LG893osUnoO4K0B_VcFKRny37WL6okMijGdq7lfphDWJXZbUbGcu-JZmxaylwSczYJ_n6sJppXgujZ8k-ncW0cCF6rPklLQNwn9Vi47ngCbAvJZ7NQiMzA2HMDdOdmTlcLbyUe3YM-cRiR6WHBLd-nb3FINYjvpYCyg=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGh-S_14PQsQk1YRkS3b2DrhoUbN2VdwEaRMrp23ymUeGH9xgkpVtxI2CzPRYXYvCRobXWmW16a3ZYQYNNZa5F3bheurzTaajSKJ2XD0d0jSNBr8LfUcl4AST2LPK_wKpQ0X2fBsmHNq9nhqfLDkpgzrouutH376p3bICg5CQ6VD3odsRetTHN2JBateVDff-U_dS-jly1YuFnc0xIHaa1NKxjS_xhDLQlWdv6uTmkVDYNaJyaxtT_wXetu6OWSoo8SgkZqkFtreS3Wy4yyO0LuCA7IUEckBQ_AQ0mvNXULDjDLFknOuPS4wCMPNsf0ODBnFS947ZYYnFJ4JmYnpCcxuvPbDuJ6XBLAL530RWWiTUyBvL98qP_lGj61MSqn7alxo0p1mihL7wuk_jaSe0-TPZbVVwwfRDoEPzdZ_tjNNVIk36K4-zEbIJ-pz0qJfSQEQ_sXoimSVaNkOJyNPVUAdLi_ZFbaeT83D4F32uxIfO70VmF73Q5KgP9mZa-jtLFwy_kUetnpvAv24hMM4Cd3F7c1nKP-MNR_aSIBEmyFsxxXEBj9cuUPfaIlSvM_TekVuEd8aBu54PjjxS_FHVgGccpTD-D264zwHJ4vx2QVJthRK_i5tRLpJeZJkkc8YYhRQ8vpKFUiL4dk0ItQvlBc9ecDHe91kNCl_nwpLGCgSe57mN7tPVp7exXuYowDJc4ANfDWvaSannv5bO86BpAbPTFs4_5hkAYJJNeerxDFQklrXQ-Ry8j7vqVxOWgN6Qjou3fqngMVVGFMjdq-iB1VHMbHwokCGLKcbQ0czf_bBxSEtc6M7hkQZp8wDa8RaMzjAe6i4Gmn9g510IhDF2dZll8MHN4Ep3iy4AndRVe-eHTFxQA6QyqZjMGGOljvCtTYdLu9U0LG893osUnoO4K0B_VcFKRny37WL6okMijGdq7lfphDWJXZbUbGcu-JZmxaylwSczYJ_n6sJppXgujZ8k-ncW0cCF6rPklLQNwn9Vi47ngCbAvJZ7NQiMzA2HMDdOdmTlcLbyUe3YM-cRiR6WHBLd-nb3FINYjvpYCyg=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000c3b984dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000c3b984dc_0",
                    "local_arrival": "2024-09-17T13:45:00.000Z",
                    "local_departure": "2024-09-17T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-17T11:45:00.000Z",
                    "utc_departure": "2024-09-17T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGh-S_14PQsQk1YRkS3b2DrhoUbN2VdwEaRMrp23ymUeGH9xgkpVtxI2CzPRYXYvCRobXWmW16a3ZYQYNNZa5F3bheurzTaajSKJ2XD0d0jSNBr8LfUcl4AST2LPK_wKpQ0X2fBsmHNq9nhqfLDkpgzrouutH376p3bICg5CQ6VD3odsRetTHN2JBateVDff-U_dS-jly1YuFnc0xIHaa1NKxjS_xhDLQlWdv6uTmkVDYNaJyaxtT_wXetu6OWSoo8SgkZqkFtreS3Wy4yyO0LuCA7IUEckBQ_AQ0mvNXULDjDLFknOuPS4wCMPNsf0ODBnFS947ZYYnFJ4JmYnpCcxuvPbDuJ6XBLAL530RWWiTUyBvL98qP_lGj61MSqn7alxo0p1mihL7wuk_jaSe0-TPZbVVwwfRDoEPzdZ_tjNNVIk36K4-zEbIJ-pz0qJfSQEQ_sXoimSVaNkOJyNPVUAdLi_ZFbaeT83D4F32uxIfO70VmF73Q5KgP9mZa-jtLFwy_kUetnpvAv24hMM4Cd3F7c1nKP-MNR_aSIBEmyFsxxXEBj9cuUPfaIlSvM_TekVuEd8aBu54PjjxS_FHVgGccpTD-D264zwHJ4vx2QVJthRK_i5tRLpJeZJkkc8YYhRQ8vpKFUiL4dk0ItQvlBc9ecDHe91kNCl_nwpLGCgSe57mN7tPVp7exXuYowDJc4ANfDWvaSannv5bO86BpAbPTFs4_5hkAYJJNeerxDFQklrXQ-Ry8j7vqVxOWgN6Qjou3fqngMVVGFMjdq-iB1VHMbHwokCGLKcbQ0czf_bBxSEtc6M7hkQZp8wDa8RaMzjAe6i4Gmn9g510IhDF2dZll8MHN4Ep3iy4AndRVe-eHTFxQA6QyqZjMGGOljvCtTYdLu9U0LG893osUnoO4K0B_VcFKRny37WL6okMijGdq7lfphDWJXZbUbGcu-JZmxaylwSczYJ_n6sJppXgujZ8k-ncW0cCF6rPklLQNwn9Vi47ngCbAvJZ7NQiMzA2HMDdOdmTlcLbyUe3YM-cRiR6WHBLd-nb3FINYjvpYCyg=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1518,
            "totalPrice": 1319
          },
          {
            "allPassengerTotalPrice": 1332,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GshlY452IZLtVpd1HFwcu8GaxwyPgzTTwhtP3Zxqewt-IDHZmR2kntsF_yRPWol1H5uHwTD1Zn3q0ndf75ewNnuqOTuoECOesOQ4mfhygXe3W27Yl05bQUDSeSDe-YDVCZKysEQdE7F4jMtC2gBnlsZMID8SHZ9hP3M5zCB1ocX23P2MdgHzzXApUDnHV_0Bqys1QUT_2PsDhbXhisf-PGYOIuXbqesYMcCvUyw1sSukSQWsrKlxbG6XG7-Yuy_OA3TdnkLyRb6Huryryq6GHpmAPDIGlH3_VnZc97WsTtgYEjKAHJIykdoKUnQvuOmd0jhL7un2fI4WsEHtJ7oDqFh1Kq8U4FvS7prTHYSfP2_kGxsrwiqeTSCp8CNGgf7jmThEP5qoEGLqTWai064gSJ-htg4SL0dd1xVnjBcAH8B7txImkNE8AQ-1KTdwmhJbQkoCFm_aFYKQRGDUCEUwrCgtT4aMu-zdWaS4dRIRJB8ZY1qNQiNwMAnMSYuYgHES1TADqHX3BgVIDC67Yra8ZB38jK5h1QkEPb64nLiPykp21OKlr4eM7tp2G-OpmUoWWKtBV6FOHf-HWvMnLFTWbUDz9BVZamYJpU7v0xmEIwnDVNyPSMzaS66FGvW929znHViJ1Vo5EHckubTVbxUgQ8D708sOtuIBebyWT1I0rozSolJP_km3vsqWDO-_RJoc3M7dgfPTElOOPfbjVYOchqFlVcvg-YPJ7AnQUpJ8gBww6mbSRVWAa80QriPWE3jAn_zB406l75sQuQuwccKu-yVE_BMtFGsmFuprqN3EdfpqXdl8wD8EmYN_Q8Qgqi2tE5Q_hjsNFqzkqifGBDQlEqJPdpsWwUbndbXuV0RwkR1XSw9tY32qsx9pEeLMAmbgEN38f6r-qreA1CJc_CChBDbEFwwClvIKwyvVpVSbgrvTGeQXS4OHIhhXnJJXLboJFHDfwrJg-wc3fWWpOFTLsMk6oIjK-UPCxNWzuunn3zlIJJIIcRCG7SqYCVJEXYM7jy2ipkAsyPthuaIiqF7T2IQ==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-09-18",
                "arrivalTime": "13:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GshlY452IZLtVpd1HFwcu8GaxwyPgzTTwhtP3Zxqewt-IDHZmR2kntsF_yRPWol1H5uHwTD1Zn3q0ndf75ewNnuqOTuoECOesOQ4mfhygXe3W27Yl05bQUDSeSDe-YDVCZKysEQdE7F4jMtC2gBnlsZMID8SHZ9hP3M5zCB1ocX23P2MdgHzzXApUDnHV_0Bqys1QUT_2PsDhbXhisf-PGYOIuXbqesYMcCvUyw1sSukSQWsrKlxbG6XG7-Yuy_OA3TdnkLyRb6Huryryq6GHpmAPDIGlH3_VnZc97WsTtgYEjKAHJIykdoKUnQvuOmd0jhL7un2fI4WsEHtJ7oDqFh1Kq8U4FvS7prTHYSfP2_kGxsrwiqeTSCp8CNGgf7jmThEP5qoEGLqTWai064gSJ-htg4SL0dd1xVnjBcAH8B7txImkNE8AQ-1KTdwmhJbQkoCFm_aFYKQRGDUCEUwrCgtT4aMu-zdWaS4dRIRJB8ZY1qNQiNwMAnMSYuYgHES1TADqHX3BgVIDC67Yra8ZB38jK5h1QkEPb64nLiPykp21OKlr4eM7tp2G-OpmUoWWKtBV6FOHf-HWvMnLFTWbUDz9BVZamYJpU7v0xmEIwnDVNyPSMzaS66FGvW929znHViJ1Vo5EHckubTVbxUgQ8D708sOtuIBebyWT1I0rozSolJP_km3vsqWDO-_RJoc3M7dgfPTElOOPfbjVYOchqFlVcvg-YPJ7AnQUpJ8gBww6mbSRVWAa80QriPWE3jAn_zB406l75sQuQuwccKu-yVE_BMtFGsmFuprqN3EdfpqXdl8wD8EmYN_Q8Qgqi2tE5Q_hjsNFqzkqifGBDQlEqJPdpsWwUbndbXuV0RwkR1XSw9tY32qsx9pEeLMAmbgEN38f6r-qreA1CJc_CChBDbEFwwClvIKwyvVpVSbgrvTGeQXS4OHIhhXnJJXLboJFHDfwrJg-wc3fWWpOFTLsMk6oIjK-UPCxNWzuunn3zlIJJIIcRCG7SqYCVJEXYM7jy2ipkAsyPthuaIiqF7T2IQ==",
                "dateChange": 1,
                "departureDate": "2024-09-17",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "14h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "14140a224e0f00001c0980aa",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "14140a224e0f00001c0980aa_0",
                    "local_arrival": "2024-09-18T00:20:00.000Z",
                    "local_departure": "2024-09-17T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-09-17T22:20:00.000Z",
                    "utc_departure": "2024-09-17T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00001c0980aa",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 1004,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00001c0980aa_1",
                    "local_arrival": "2024-09-18T13:25:00.000Z",
                    "local_departure": "2024-09-18T11:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1004",
                    "return": 0,
                    "utc_arrival": "2024-09-18T11:25:00.000Z",
                    "utc_departure": "2024-09-18T09:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GshlY452IZLtVpd1HFwcu8GaxwyPgzTTwhtP3Zxqewt-IDHZmR2kntsF_yRPWol1H5uHwTD1Zn3q0ndf75ewNnuqOTuoECOesOQ4mfhygXe3W27Yl05bQUDSeSDe-YDVCZKysEQdE7F4jMtC2gBnlsZMID8SHZ9hP3M5zCB1ocX23P2MdgHzzXApUDnHV_0Bqys1QUT_2PsDhbXhisf-PGYOIuXbqesYMcCvUyw1sSukSQWsrKlxbG6XG7-Yuy_OA3TdnkLyRb6Huryryq6GHpmAPDIGlH3_VnZc97WsTtgYEjKAHJIykdoKUnQvuOmd0jhL7un2fI4WsEHtJ7oDqFh1Kq8U4FvS7prTHYSfP2_kGxsrwiqeTSCp8CNGgf7jmThEP5qoEGLqTWai064gSJ-htg4SL0dd1xVnjBcAH8B7txImkNE8AQ-1KTdwmhJbQkoCFm_aFYKQRGDUCEUwrCgtT4aMu-zdWaS4dRIRJB8ZY1qNQiNwMAnMSYuYgHES1TADqHX3BgVIDC67Yra8ZB38jK5h1QkEPb64nLiPykp21OKlr4eM7tp2G-OpmUoWWKtBV6FOHf-HWvMnLFTWbUDz9BVZamYJpU7v0xmEIwnDVNyPSMzaS66FGvW929znHViJ1Vo5EHckubTVbxUgQ8D708sOtuIBebyWT1I0rozSolJP_km3vsqWDO-_RJoc3M7dgfPTElOOPfbjVYOchqFlVcvg-YPJ7AnQUpJ8gBww6mbSRVWAa80QriPWE3jAn_zB406l75sQuQuwccKu-yVE_BMtFGsmFuprqN3EdfpqXdl8wD8EmYN_Q8Qgqi2tE5Q_hjsNFqzkqifGBDQlEqJPdpsWwUbndbXuV0RwkR1XSw9tY32qsx9pEeLMAmbgEN38f6r-qreA1CJc_CChBDbEFwwClvIKwyvVpVSbgrvTGeQXS4OHIhhXnJJXLboJFHDfwrJg-wc3fWWpOFTLsMk6oIjK-UPCxNWzuunn3zlIJJIIcRCG7SqYCVJEXYM7jy2ipkAsyPthuaIiqF7T2IQ==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1621.1,
            "totalPrice": 1332
          },
          {
            "allPassengerTotalPrice": 1343,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKU4O3pgSQW-oP_y1q5XVhks7aoVGUI54_tBKoYoN-Oq04QqAAUeQaKBChhVuBotaqezrCNK7SXWFFJ1DZtDXi_hnIadeFc-6kAUnFuM_jaakiE7LYHqYWajQgIw4BlpQaAm7fUKZHsO9bbi8PapLiAcGGcbNuJNAOpx_Z_8BCeN_uHqlnUR6Dh9Lb1-MAcZOIWGOb5ieL0V0p_6Oc8O6BYQhgnyY3WbzBzs3QHz7HoWAtNzZW61ahu0YYCgMbi1EZKgBKU02xXPGGwzBjnDxI6J3bT1OBYO7xGICvTw7YY8dxj5PXfU-tdZDOah-E_zDjk73OKn0A9UhrSl3uhRVTJPumMdRlRdC_475zu8jyhWxlpltKyftTGTMdsqwQ4FlRrhRCs4fTPlwbB4ieyFFYylIq60NdwH1DctgyU4LH53TAVmREDkCnGC4OqHzEfvGKJL4VV--PqpcV1IscVV2DAZoGrtqgM0juIUPOJXM0hODw_SDkYa1qps7hYfBYsw1uHBbEhdJRz6D-iIZadukwo_d14Uns_mtBEDFiOilbZyWpXekSQZdwbyw2hI1E9M38MzhnB_5ExPZw58eCOnxblm4BKXMIhaqfdH5ZFOJutRkszJBuwjWgzdGToGPrKEIyu4KqbR-QfEJhYTngi_VoG2D3OVoSFV2v3lbNlN9GKURmOwwmghOsGGeXW6jgJpkQR1e9k9lEAvvtSMuAglLxIwxJdAqb_LA1yqbWdZylUGIL9poN40lIXw8xlRTYky0nPwS0nxVS82hcSqKstGRHPv6_pT9Z7ThlX_Am83cPNx9gU3sr-MbNO9FM5C5iKpo2Z3XG8gsYq1emld7tC3mKIyUmYWe2yozzRYkVGZXfX9z9Kr3fT0tcCVTv6UJ2QSNCoER7AgUQxRcTSAk-vrAad-x9R4p7OTZvAjQy-EvjtDTr1DY6Di3zkE4BlcVzEnEtsZAy3Su09HdVxrvaHUX37-Z8ZX6MIOv0FDDmC0jUjkEpiw73BnKdh_dFi7PHDWzxgtHWuG-SOJK5GmRBXXeJ-dGv7dyQO7GxjC8vcWgjP4=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f44ef0bb",
                    "equipment": null,
                    "fare_basis": "PIL2D4WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f44ef0bb_0",
                    "local_arrival": "2024-09-08T17:00:00.000Z",
                    "local_departure": "2024-09-07T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-08T15:00:00.000Z",
                    "utc_departure": "2024-09-08T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-09-18",
                "arrivalTime": "08:50",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKU4O3pgSQW-oP_y1q5XVhks7aoVGUI54_tBKoYoN-Oq04QqAAUeQaKBChhVuBotaqezrCNK7SXWFFJ1DZtDXi_hnIadeFc-6kAUnFuM_jaakiE7LYHqYWajQgIw4BlpQaAm7fUKZHsO9bbi8PapLiAcGGcbNuJNAOpx_Z_8BCeN_uHqlnUR6Dh9Lb1-MAcZOIWGOb5ieL0V0p_6Oc8O6BYQhgnyY3WbzBzs3QHz7HoWAtNzZW61ahu0YYCgMbi1EZKgBKU02xXPGGwzBjnDxI6J3bT1OBYO7xGICvTw7YY8dxj5PXfU-tdZDOah-E_zDjk73OKn0A9UhrSl3uhRVTJPumMdRlRdC_475zu8jyhWxlpltKyftTGTMdsqwQ4FlRrhRCs4fTPlwbB4ieyFFYylIq60NdwH1DctgyU4LH53TAVmREDkCnGC4OqHzEfvGKJL4VV--PqpcV1IscVV2DAZoGrtqgM0juIUPOJXM0hODw_SDkYa1qps7hYfBYsw1uHBbEhdJRz6D-iIZadukwo_d14Uns_mtBEDFiOilbZyWpXekSQZdwbyw2hI1E9M38MzhnB_5ExPZw58eCOnxblm4BKXMIhaqfdH5ZFOJutRkszJBuwjWgzdGToGPrKEIyu4KqbR-QfEJhYTngi_VoG2D3OVoSFV2v3lbNlN9GKURmOwwmghOsGGeXW6jgJpkQR1e9k9lEAvvtSMuAglLxIwxJdAqb_LA1yqbWdZylUGIL9poN40lIXw8xlRTYky0nPwS0nxVS82hcSqKstGRHPv6_pT9Z7ThlX_Am83cPNx9gU3sr-MbNO9FM5C5iKpo2Z3XG8gsYq1emld7tC3mKIyUmYWe2yozzRYkVGZXfX9z9Kr3fT0tcCVTv6UJ2QSNCoER7AgUQxRcTSAk-vrAad-x9R4p7OTZvAjQy-EvjtDTr1DY6Di3zkE4BlcVzEnEtsZAy3Su09HdVxrvaHUX37-Z8ZX6MIOv0FDDmC0jUjkEpiw73BnKdh_dFi7PHDWzxgtHWuG-SOJK5GmRBXXeJ-dGv7dyQO7GxjC8vcWgjP4=",
                "dateChange": 1,
                "departureDate": "2024-09-17",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "10h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "14140a224e0f0000f56a259f",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "14140a224e0f0000f56a259f_0",
                    "local_arrival": "2024-09-18T00:20:00.000Z",
                    "local_departure": "2024-09-17T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-09-17T22:20:00.000Z",
                    "utc_departure": "2024-09-17T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000f56a259f",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 1002,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000f56a259f_1",
                    "local_arrival": "2024-09-18T08:50:00.000Z",
                    "local_departure": "2024-09-18T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1002",
                    "return": 0,
                    "utc_arrival": "2024-09-18T06:50:00.000Z",
                    "utc_departure": "2024-09-18T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKU4O3pgSQW-oP_y1q5XVhks7aoVGUI54_tBKoYoN-Oq04QqAAUeQaKBChhVuBotaqezrCNK7SXWFFJ1DZtDXi_hnIadeFc-6kAUnFuM_jaakiE7LYHqYWajQgIw4BlpQaAm7fUKZHsO9bbi8PapLiAcGGcbNuJNAOpx_Z_8BCeN_uHqlnUR6Dh9Lb1-MAcZOIWGOb5ieL0V0p_6Oc8O6BYQhgnyY3WbzBzs3QHz7HoWAtNzZW61ahu0YYCgMbi1EZKgBKU02xXPGGwzBjnDxI6J3bT1OBYO7xGICvTw7YY8dxj5PXfU-tdZDOah-E_zDjk73OKn0A9UhrSl3uhRVTJPumMdRlRdC_475zu8jyhWxlpltKyftTGTMdsqwQ4FlRrhRCs4fTPlwbB4ieyFFYylIq60NdwH1DctgyU4LH53TAVmREDkCnGC4OqHzEfvGKJL4VV--PqpcV1IscVV2DAZoGrtqgM0juIUPOJXM0hODw_SDkYa1qps7hYfBYsw1uHBbEhdJRz6D-iIZadukwo_d14Uns_mtBEDFiOilbZyWpXekSQZdwbyw2hI1E9M38MzhnB_5ExPZw58eCOnxblm4BKXMIhaqfdH5ZFOJutRkszJBuwjWgzdGToGPrKEIyu4KqbR-QfEJhYTngi_VoG2D3OVoSFV2v3lbNlN9GKURmOwwmghOsGGeXW6jgJpkQR1e9k9lEAvvtSMuAglLxIwxJdAqb_LA1yqbWdZylUGIL9poN40lIXw8xlRTYky0nPwS0nxVS82hcSqKstGRHPv6_pT9Z7ThlX_Am83cPNx9gU3sr-MbNO9FM5C5iKpo2Z3XG8gsYq1emld7tC3mKIyUmYWe2yozzRYkVGZXfX9z9Kr3fT0tcCVTv6UJ2QSNCoER7AgUQxRcTSAk-vrAad-x9R4p7OTZvAjQy-EvjtDTr1DY6Di3zkE4BlcVzEnEtsZAy3Su09HdVxrvaHUX37-Z8ZX6MIOv0FDDmC0jUjkEpiw73BnKdh_dFi7PHDWzxgtHWuG-SOJK5GmRBXXeJ-dGv7dyQO7GxjC8vcWgjP4=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1593.44,
            "totalPrice": 1343
          },
          {
            "allPassengerTotalPrice": 1357,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFWSKuyA52EsjFPzGO59xqZSsKRIvIPTmBcbPttm_teGu4yhmtLO_-bq1KmjNWupd0bX5tcJi3f_-XTt6GVBI5oW8oRN1an_3WfLG1K7ilEMgRKp93drzNRKFE1nd8XILvNDfXklWar5m8OhpehXC0-D8GWzbiO4sysbjwC4qMiY7akbNVzOmjt2ADF74TPuMqA_32Qab5_i3O-aI1h6a1CokBSNeJXCIlENUdf9ZUM92BBjitZ5gaePuR9PQGRecQsOKk6Fgusavqq4slWj1Da6WCxgEIc5oBPdP6X6WxYnKkZ8IFMHBUYG27IynaqpJy41O0LlQsft0r7zCoc560Jb-iCSJskb_slmWbwz44JxuPEZp_0L_VGnauJgMxI_RTkLWZ5ds9Grvgvj5Pzn6i2RwVv6RI8AL_quYgcFIGXXQDWpQL0c412NTxGEhI95DMnavWCviVcHXVg6QM1racvv1lBPcrb8--V6ER7LdeUJUBdpH1Fq_OckrZIzGuv0CSp7LMsegngeROK9q-5HGWVK-BvLvFaqlrhZ6nhFX4sg67NKRtyKRwuuyQOeFvg64U9pRu1hfaMePBBTIGB_W1dOLD1j65_adCdDSYBhiNRqQV-q-8hnfJ-_3jPi00aUL8fYrFAvT--yDGsrYxz6G0IMZ5qML7rY-sWIHgox-9QevrPnyXBqQWUFZz2_L-vPqMVN2Qt7canxFoZu_7_0RndQoINka9TELyTuiArIPjGNiFJu08qae5PCe8gnQsoaU1vKf_PaKX-_QmRzLpf7upFQNFumoxAgMITzTP9y4WHVvuJ10O2otCoNCiG5dHFkq_3qnQ-0uTqDu0qN4JSxGN6UAeKY7kRayzyjrZvSHY5idvnOfRZt9nQv382Ba15yq5EKFZO5bIp0TqCh0pAZqp1c-ARxzRTSefSYsJyLIyYxcVMbLDPau6IgGKqJlDu_V6Oo-GnxDHxX2UoLyASL8NN5LmOPZiYFqERpAxnk00GBOzEDlUyCHA7lZnYadDl7PrsmFZzwIcBTWXz4wKi92BeqDWLcGcxV3GrZlTjtMrvoq1UUtPNtZY-JgeI76hlAx",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-09-18",
                "arrivalTime": "13:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFWSKuyA52EsjFPzGO59xqZSsKRIvIPTmBcbPttm_teGu4yhmtLO_-bq1KmjNWupd0bX5tcJi3f_-XTt6GVBI5oW8oRN1an_3WfLG1K7ilEMgRKp93drzNRKFE1nd8XILvNDfXklWar5m8OhpehXC0-D8GWzbiO4sysbjwC4qMiY7akbNVzOmjt2ADF74TPuMqA_32Qab5_i3O-aI1h6a1CokBSNeJXCIlENUdf9ZUM92BBjitZ5gaePuR9PQGRecQsOKk6Fgusavqq4slWj1Da6WCxgEIc5oBPdP6X6WxYnKkZ8IFMHBUYG27IynaqpJy41O0LlQsft0r7zCoc560Jb-iCSJskb_slmWbwz44JxuPEZp_0L_VGnauJgMxI_RTkLWZ5ds9Grvgvj5Pzn6i2RwVv6RI8AL_quYgcFIGXXQDWpQL0c412NTxGEhI95DMnavWCviVcHXVg6QM1racvv1lBPcrb8--V6ER7LdeUJUBdpH1Fq_OckrZIzGuv0CSp7LMsegngeROK9q-5HGWVK-BvLvFaqlrhZ6nhFX4sg67NKRtyKRwuuyQOeFvg64U9pRu1hfaMePBBTIGB_W1dOLD1j65_adCdDSYBhiNRqQV-q-8hnfJ-_3jPi00aUL8fYrFAvT--yDGsrYxz6G0IMZ5qML7rY-sWIHgox-9QevrPnyXBqQWUFZz2_L-vPqMVN2Qt7canxFoZu_7_0RndQoINka9TELyTuiArIPjGNiFJu08qae5PCe8gnQsoaU1vKf_PaKX-_QmRzLpf7upFQNFumoxAgMITzTP9y4WHVvuJ10O2otCoNCiG5dHFkq_3qnQ-0uTqDu0qN4JSxGN6UAeKY7kRayzyjrZvSHY5idvnOfRZt9nQv382Ba15yq5EKFZO5bIp0TqCh0pAZqp1c-ARxzRTSefSYsJyLIyYxcVMbLDPau6IgGKqJlDu_V6Oo-GnxDHxX2UoLyASL8NN5LmOPZiYFqERpAxnk00GBOzEDlUyCHA7lZnYadDl7PrsmFZzwIcBTWXz4wKi92BeqDWLcGcxV3GrZlTjtMrvoq1UUtPNtZY-JgeI76hlAx",
                "dateChange": 1,
                "departureDate": "2024-09-17",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "14h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "14140a224e0f00001c0980aa",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "14140a224e0f00001c0980aa_0",
                    "local_arrival": "2024-09-18T00:20:00.000Z",
                    "local_departure": "2024-09-17T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-09-17T22:20:00.000Z",
                    "utc_departure": "2024-09-17T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00001c0980aa",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 1004,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00001c0980aa_1",
                    "local_arrival": "2024-09-18T13:25:00.000Z",
                    "local_departure": "2024-09-18T11:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1004",
                    "return": 0,
                    "utc_arrival": "2024-09-18T11:25:00.000Z",
                    "utc_departure": "2024-09-18T09:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f00001c0980aa_0%7C14140a224e0f00001c0980aa_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFWSKuyA52EsjFPzGO59xqZSsKRIvIPTmBcbPttm_teGu4yhmtLO_-bq1KmjNWupd0bX5tcJi3f_-XTt6GVBI5oW8oRN1an_3WfLG1K7ilEMgRKp93drzNRKFE1nd8XILvNDfXklWar5m8OhpehXC0-D8GWzbiO4sysbjwC4qMiY7akbNVzOmjt2ADF74TPuMqA_32Qab5_i3O-aI1h6a1CokBSNeJXCIlENUdf9ZUM92BBjitZ5gaePuR9PQGRecQsOKk6Fgusavqq4slWj1Da6WCxgEIc5oBPdP6X6WxYnKkZ8IFMHBUYG27IynaqpJy41O0LlQsft0r7zCoc560Jb-iCSJskb_slmWbwz44JxuPEZp_0L_VGnauJgMxI_RTkLWZ5ds9Grvgvj5Pzn6i2RwVv6RI8AL_quYgcFIGXXQDWpQL0c412NTxGEhI95DMnavWCviVcHXVg6QM1racvv1lBPcrb8--V6ER7LdeUJUBdpH1Fq_OckrZIzGuv0CSp7LMsegngeROK9q-5HGWVK-BvLvFaqlrhZ6nhFX4sg67NKRtyKRwuuyQOeFvg64U9pRu1hfaMePBBTIGB_W1dOLD1j65_adCdDSYBhiNRqQV-q-8hnfJ-_3jPi00aUL8fYrFAvT--yDGsrYxz6G0IMZ5qML7rY-sWIHgox-9QevrPnyXBqQWUFZz2_L-vPqMVN2Qt7canxFoZu_7_0RndQoINka9TELyTuiArIPjGNiFJu08qae5PCe8gnQsoaU1vKf_PaKX-_QmRzLpf7upFQNFumoxAgMITzTP9y4WHVvuJ10O2otCoNCiG5dHFkq_3qnQ-0uTqDu0qN4JSxGN6UAeKY7kRayzyjrZvSHY5idvnOfRZt9nQv382Ba15yq5EKFZO5bIp0TqCh0pAZqp1c-ARxzRTSefSYsJyLIyYxcVMbLDPau6IgGKqJlDu_V6Oo-GnxDHxX2UoLyASL8NN5LmOPZiYFqERpAxnk00GBOzEDlUyCHA7lZnYadDl7PrsmFZzwIcBTWXz4wKi92BeqDWLcGcxV3GrZlTjtMrvoq1UUtPNtZY-JgeI76hlAx",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1658.09,
            "totalPrice": 1357
          },
          {
            "allPassengerTotalPrice": 1367,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnelcGgf2Xg1UE6Y6yJZoRqN-D3xSpQmK-AIaB_aN56LWzkmK2H6wSdopGHsaC8pPIEK5YoU1zuFuPTRRgfIROKV5L-qA2-6eIhjwMLpFx9qM15dVbhEaPEJtBhbXPxWyPLi75y4N8Ug__zpZLocw7xqdcezHAqo7EInVUAP9A5BsOXdCh-1E2A4gY_R3IcWv1Sg7tgj-kpQLo8PIu9lEyuyVG5oap4gMlHBZX6CxoVi0t2g8UGsY7f7Z6DGIU8o4zYTg_euyuV1QRC8YtJY6CJtjXnv6xEh_coYxuwojfFUGKzhq3w2WPZh0K73MF6JWN_Ic41euNBO4i4wmGprTZQ5Icovg5DRZRnNTVm7bCI2gFH5CphnnN3o1f12_nEo4jfi0BiwHqqFBJyFAjIFOrPrABLkDUmnHamTgjmF5NPUClxDHT4DSz-F8la0n1ZP79_TlUvldjmG4qokNnyuqnsSS11CV6SFvtW68Q6fqVKJDH3g23y7ff0Vby1Kr1hUZFqFUGLvn9KrqAh1b3e0AbyW9aymTDhR65tTaHQMKytKWmkyeqy-GFKT3j7jsFmK3wRBR_i72fMScVF1yI23XLnXqm0gfr_ZWPyDv_BoNwX-jSCjIlrti7ScaSzw-5hsdIJb9u3Ut8HaB1eLvmXkKkzoKTTDgWBw6DaQ97gMx_VXLnQufZZiwymwt2UxDXSrF66FbZzYs8OGHH0diPmLgbt8oHL5hk6EUjsQhO-K-YNRqkXY3-ZVpHwbfjaeRo-6ELwRdrtTlsqn3bWdrjdM911fePa7KQ5qrki1Nd-O4mg3Dy_Hf3LUHoFUk2n00o0CuBfQ18DzxJnqyKWjRSqHFha8lsr6v41hOO_aPut-2d-yzB_9KzcY6aLXRY3lOtBx1fEYUF5mbCf9dSUE9WjhKHzLDZFMw3P_43xheE3461bav1EGMKGmNV8iSGNNHKBP2C7YFrhgizSUhTBQWvVjdf1A_uuMIgC1znSSskJCoDKbi8IWRi6AT5V6vgrmQ-QSsIZJCGysirbmWYfQ9m6OZuQw4wVGD3ed0ugP1x4H2DLPhQYFn-ZPMhazMIJ4KWHRC",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_0",
                    "local_arrival": "2024-09-08T12:20:00.000Z",
                    "local_departure": "2024-09-07T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-08T10:20:00.000Z",
                    "utc_departure": "2024-09-07T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0500002e11635b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0500002e11635b_1",
                    "local_arrival": "2024-09-08T15:25:00.000Z",
                    "local_departure": "2024-09-08T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-08T13:25:00.000Z",
                    "utc_departure": "2024-09-08T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-09-18",
                "arrivalTime": "08:50",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnelcGgf2Xg1UE6Y6yJZoRqN-D3xSpQmK-AIaB_aN56LWzkmK2H6wSdopGHsaC8pPIEK5YoU1zuFuPTRRgfIROKV5L-qA2-6eIhjwMLpFx9qM15dVbhEaPEJtBhbXPxWyPLi75y4N8Ug__zpZLocw7xqdcezHAqo7EInVUAP9A5BsOXdCh-1E2A4gY_R3IcWv1Sg7tgj-kpQLo8PIu9lEyuyVG5oap4gMlHBZX6CxoVi0t2g8UGsY7f7Z6DGIU8o4zYTg_euyuV1QRC8YtJY6CJtjXnv6xEh_coYxuwojfFUGKzhq3w2WPZh0K73MF6JWN_Ic41euNBO4i4wmGprTZQ5Icovg5DRZRnNTVm7bCI2gFH5CphnnN3o1f12_nEo4jfi0BiwHqqFBJyFAjIFOrPrABLkDUmnHamTgjmF5NPUClxDHT4DSz-F8la0n1ZP79_TlUvldjmG4qokNnyuqnsSS11CV6SFvtW68Q6fqVKJDH3g23y7ff0Vby1Kr1hUZFqFUGLvn9KrqAh1b3e0AbyW9aymTDhR65tTaHQMKytKWmkyeqy-GFKT3j7jsFmK3wRBR_i72fMScVF1yI23XLnXqm0gfr_ZWPyDv_BoNwX-jSCjIlrti7ScaSzw-5hsdIJb9u3Ut8HaB1eLvmXkKkzoKTTDgWBw6DaQ97gMx_VXLnQufZZiwymwt2UxDXSrF66FbZzYs8OGHH0diPmLgbt8oHL5hk6EUjsQhO-K-YNRqkXY3-ZVpHwbfjaeRo-6ELwRdrtTlsqn3bWdrjdM911fePa7KQ5qrki1Nd-O4mg3Dy_Hf3LUHoFUk2n00o0CuBfQ18DzxJnqyKWjRSqHFha8lsr6v41hOO_aPut-2d-yzB_9KzcY6aLXRY3lOtBx1fEYUF5mbCf9dSUE9WjhKHzLDZFMw3P_43xheE3461bav1EGMKGmNV8iSGNNHKBP2C7YFrhgizSUhTBQWvVjdf1A_uuMIgC1znSSskJCoDKbi8IWRi6AT5V6vgrmQ-QSsIZJCGysirbmWYfQ9m6OZuQw4wVGD3ed0ugP1x4H2DLPhQYFn-ZPMhazMIJ4KWHRC",
                "dateChange": 1,
                "departureDate": "2024-09-17",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "10h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "14140a224e0f0000f56a259f",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "14140a224e0f0000f56a259f_0",
                    "local_arrival": "2024-09-18T00:20:00.000Z",
                    "local_departure": "2024-09-17T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-09-17T22:20:00.000Z",
                    "utc_departure": "2024-09-17T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000f56a259f",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 1002,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000f56a259f_1",
                    "local_arrival": "2024-09-18T08:50:00.000Z",
                    "local_departure": "2024-09-18T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1002",
                    "return": 0,
                    "utc_arrival": "2024-09-18T06:50:00.000Z",
                    "utc_departure": "2024-09-18T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0500002e11635b_0%7C0f9c01af4e0500002e11635b_1%7C14140a224e0f0000f56a259f_0%7C14140a224e0f0000f56a259f_1%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnelcGgf2Xg1UE6Y6yJZoRqN-D3xSpQmK-AIaB_aN56LWzkmK2H6wSdopGHsaC8pPIEK5YoU1zuFuPTRRgfIROKV5L-qA2-6eIhjwMLpFx9qM15dVbhEaPEJtBhbXPxWyPLi75y4N8Ug__zpZLocw7xqdcezHAqo7EInVUAP9A5BsOXdCh-1E2A4gY_R3IcWv1Sg7tgj-kpQLo8PIu9lEyuyVG5oap4gMlHBZX6CxoVi0t2g8UGsY7f7Z6DGIU8o4zYTg_euyuV1QRC8YtJY6CJtjXnv6xEh_coYxuwojfFUGKzhq3w2WPZh0K73MF6JWN_Ic41euNBO4i4wmGprTZQ5Icovg5DRZRnNTVm7bCI2gFH5CphnnN3o1f12_nEo4jfi0BiwHqqFBJyFAjIFOrPrABLkDUmnHamTgjmF5NPUClxDHT4DSz-F8la0n1ZP79_TlUvldjmG4qokNnyuqnsSS11CV6SFvtW68Q6fqVKJDH3g23y7ff0Vby1Kr1hUZFqFUGLvn9KrqAh1b3e0AbyW9aymTDhR65tTaHQMKytKWmkyeqy-GFKT3j7jsFmK3wRBR_i72fMScVF1yI23XLnXqm0gfr_ZWPyDv_BoNwX-jSCjIlrti7ScaSzw-5hsdIJb9u3Ut8HaB1eLvmXkKkzoKTTDgWBw6DaQ97gMx_VXLnQufZZiwymwt2UxDXSrF66FbZzYs8OGHH0diPmLgbt8oHL5hk6EUjsQhO-K-YNRqkXY3-ZVpHwbfjaeRo-6ELwRdrtTlsqn3bWdrjdM911fePa7KQ5qrki1Nd-O4mg3Dy_Hf3LUHoFUk2n00o0CuBfQ18DzxJnqyKWjRSqHFha8lsr6v41hOO_aPut-2d-yzB_9KzcY6aLXRY3lOtBx1fEYUF5mbCf9dSUE9WjhKHzLDZFMw3P_43xheE3461bav1EGMKGmNV8iSGNNHKBP2C7YFrhgizSUhTBQWvVjdf1A_uuMIgC1znSSskJCoDKbi8IWRi6AT5V6vgrmQ-QSsIZJCGysirbmWYfQ9m6OZuQw4wVGD3ed0ugP1x4H2DLPhQYFn-ZPMhazMIJ4KWHRC",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1630.43,
            "totalPrice": 1367
          },
          {
            "allPassengerTotalPrice": 1391,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7EcaDi2qNTsybJLESQAlU2dYksCaGUGwemOomiVXW1AqsR2h68qYQyvdbOjdJUnesABkwbgr4H_rmaWHBsA8wfs21FTHHx9ZXoewB0MAfEk5qeDg16qgMVSiFBoaGgLS0LU6F2xlugzQ39Qt9UpMT-2hqos7zXs1nRbYrMgnzqB28pDfoXzaW4oEP3vU-4fJifsVEM5eKRnTmjG5dL9GBaHrGIN_q3y0tCV47FTonQqNlaJZ9Op5gc4tW3PFPasYsLQ7UhfXgShU2dhIAjKbdUZGLBkUgb7bKR38MOPUESgwTcfkvTzMjq0YvP9O_7QAcqFjw_gQPI5nfv6mApHQZGjkkUSBk8vJIcOa7Wtl56Q5FVqmmQJPtfAt-5SS8iO1u82pAdoeAwLBZo_E060gvjHoOYnBq64SWIqPFYWVKxMH5-dps577R6lU_s8gODhrN5fyDPWYdhcCo3ZuCLP91FAifwARJSaXSAYmnSB_6eaGfNJhCzZ5hRt2Jr88nD0gGbLHhoELUhR38J2FcWWXLnRQv-SsUSKn8vLhw_9-3fhGmhk6J-hHkvZWWasXqEdhwTBwr4yiLVIvg5XoszrGLgUYBicttwhoqkX2E64QyjBr-HlibEauJSUuuyMoLnMtz3S03Yus-eQzxdZZ1o0JJNh3JpBkpA1CV_EGMdqKgnFJCtOWQePxhP7GtHV6FUot19Myqn-fOwqGucPwzml8TyLIRYTrD-PvLeCyjresP_h_LTcXJxmzBjziBFNc2GTVs3sJ8_CAiBisHIHtUjFc6klriGZ0U69hK6ng_zDM53laIIZl0phEC5x-L2pZ_OhBQEUT5SO5bbm6MDWVFvkV59VwFIekX2Cn9_vfjMQAWLMCrNYz0V0ybCbZVvzUDIDZB3dpSltGCMUppoK2EO_3rZVyUDGPjBWtzkth1QiR4cAFMZH7SlTOsI1dcyhdAMRqRVX3GjXehnVL3f568sS1QR9mrOgADXjLKiRbgaZ0Z0biVRV2kpwH-cbzTEcu5rSXlCrV1duucFpd2kGKYjMqOqs2Cm2qAaMsa3aowY93GXk=",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7EcaDi2qNTsybJLESQAlU2dYksCaGUGwemOomiVXW1AqsR2h68qYQyvdbOjdJUnesABkwbgr4H_rmaWHBsA8wfs21FTHHx9ZXoewB0MAfEk5qeDg16qgMVSiFBoaGgLS0LU6F2xlugzQ39Qt9UpMT-2hqos7zXs1nRbYrMgnzqB28pDfoXzaW4oEP3vU-4fJifsVEM5eKRnTmjG5dL9GBaHrGIN_q3y0tCV47FTonQqNlaJZ9Op5gc4tW3PFPasYsLQ7UhfXgShU2dhIAjKbdUZGLBkUgb7bKR38MOPUESgwTcfkvTzMjq0YvP9O_7QAcqFjw_gQPI5nfv6mApHQZGjkkUSBk8vJIcOa7Wtl56Q5FVqmmQJPtfAt-5SS8iO1u82pAdoeAwLBZo_E060gvjHoOYnBq64SWIqPFYWVKxMH5-dps577R6lU_s8gODhrN5fyDPWYdhcCo3ZuCLP91FAifwARJSaXSAYmnSB_6eaGfNJhCzZ5hRt2Jr88nD0gGbLHhoELUhR38J2FcWWXLnRQv-SsUSKn8vLhw_9-3fhGmhk6J-hHkvZWWasXqEdhwTBwr4yiLVIvg5XoszrGLgUYBicttwhoqkX2E64QyjBr-HlibEauJSUuuyMoLnMtz3S03Yus-eQzxdZZ1o0JJNh3JpBkpA1CV_EGMdqKgnFJCtOWQePxhP7GtHV6FUot19Myqn-fOwqGucPwzml8TyLIRYTrD-PvLeCyjresP_h_LTcXJxmzBjziBFNc2GTVs3sJ8_CAiBisHIHtUjFc6klriGZ0U69hK6ng_zDM53laIIZl0phEC5x-L2pZ_OhBQEUT5SO5bbm6MDWVFvkV59VwFIekX2Cn9_vfjMQAWLMCrNYz0V0ybCbZVvzUDIDZB3dpSltGCMUppoK2EO_3rZVyUDGPjBWtzkth1QiR4cAFMZH7SlTOsI1dcyhdAMRqRVX3GjXehnVL3f568sS1QR9mrOgADXjLKiRbgaZ0Z0biVRV2kpwH-cbzTEcu5rSXlCrV1duucFpd2kGKYjMqOqs2Cm2qAaMsa3aowY93GXk=",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000744aea56",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000744aea56_0",
                    "local_arrival": "2024-09-17T14:35:00.000Z",
                    "local_departure": "2024-09-17T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-17T12:35:00.000Z",
                    "utc_departure": "2024-09-17T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f0000744aea56_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7EcaDi2qNTsybJLESQAlU2dYksCaGUGwemOomiVXW1AqsR2h68qYQyvdbOjdJUnesABkwbgr4H_rmaWHBsA8wfs21FTHHx9ZXoewB0MAfEk5qeDg16qgMVSiFBoaGgLS0LU6F2xlugzQ39Qt9UpMT-2hqos7zXs1nRbYrMgnzqB28pDfoXzaW4oEP3vU-4fJifsVEM5eKRnTmjG5dL9GBaHrGIN_q3y0tCV47FTonQqNlaJZ9Op5gc4tW3PFPasYsLQ7UhfXgShU2dhIAjKbdUZGLBkUgb7bKR38MOPUESgwTcfkvTzMjq0YvP9O_7QAcqFjw_gQPI5nfv6mApHQZGjkkUSBk8vJIcOa7Wtl56Q5FVqmmQJPtfAt-5SS8iO1u82pAdoeAwLBZo_E060gvjHoOYnBq64SWIqPFYWVKxMH5-dps577R6lU_s8gODhrN5fyDPWYdhcCo3ZuCLP91FAifwARJSaXSAYmnSB_6eaGfNJhCzZ5hRt2Jr88nD0gGbLHhoELUhR38J2FcWWXLnRQv-SsUSKn8vLhw_9-3fhGmhk6J-hHkvZWWasXqEdhwTBwr4yiLVIvg5XoszrGLgUYBicttwhoqkX2E64QyjBr-HlibEauJSUuuyMoLnMtz3S03Yus-eQzxdZZ1o0JJNh3JpBkpA1CV_EGMdqKgnFJCtOWQePxhP7GtHV6FUot19Myqn-fOwqGucPwzml8TyLIRYTrD-PvLeCyjresP_h_LTcXJxmzBjziBFNc2GTVs3sJ8_CAiBisHIHtUjFc6klriGZ0U69hK6ng_zDM53laIIZl0phEC5x-L2pZ_OhBQEUT5SO5bbm6MDWVFvkV59VwFIekX2Cn9_vfjMQAWLMCrNYz0V0ybCbZVvzUDIDZB3dpSltGCMUppoK2EO_3rZVyUDGPjBWtzkth1QiR4cAFMZH7SlTOsI1dcyhdAMRqRVX3GjXehnVL3f568sS1QR9mrOgADXjLKiRbgaZ0Z0biVRV2kpwH-cbzTEcu5rSXlCrV1duucFpd2kGKYjMqOqs2Cm2qAaMsa3aowY93GXk=",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1587.61,
            "totalPrice": 1391
          },
          {
            "allPassengerTotalPrice": 1394,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyyhmQ9JpYsEWa48OzyYCFRg7WZD4NPDL9XbZ3ZxYQ1Dx2UYKHkEUc-DYd5JW_mtsq72jG7HXJIKj5XnsdQCuP0AAhlGw1nWniRT2kh9vK-B_3RmDUWvrjuWUma3l2usv4hhc0eYiGMlelLfgBWJgdRai79X0E0DHgLyF7lVHvybJDvjT59njYDVvdqw6ho-201tMFiVeQM-1EbJJolLosNamBEPLdicvldLQDzTx6XNJ6lLCdHKTt27GjfWRnj3aAYlWRqkcxIfOYbhwBYI5z09VEw2whLVXCNMw7OHxj6VumDQH9ISjkHwVDe6aE__KWJXGva5jTg8nCOL7mxxcvs77uIxJzmiLp6SJygR-D1kYbYYWAFycEs7BFcQngsh3-ApXS2JGrrJyNRRrA9FzTp83EVjsgWIPF7VJ_0cQD7N0L2666c9oaD007-5ttogM-uxFHAEUTVFg3mgW8n-0y2sjhNILz-Z9f4EbtdTdNW4S5edOGcv9QpbYgz24m52VNNyNyhabSd0t5dzM5heD55voANwhzYkk-ZC5EHexlhtQKm19p2QmCl7Q4Q0u2maGJumtIGiHX_kBwKrIgKRfhHtS-V1LbGFyWwqG72jlkYnpXUnpVnTsAFoBdInshrPFwbTwLNwZJjk_WyEVMMoBmXsTvhTz2yO92uWLQZFqNXTFydIiNS__-dvdUn8ouBMvhvV5gJHjiyh5dQii08TbJLux9Zuz9AIQZ1dYSWtEtixMm9S079OTWJzchPhpkklu5p7RxGETWz00d-omW5o59U2Yap4KycgthSvz4V9lStfRBr-lvsxFRLGH8IGXze_TLgeuHygZHJ9oMyrqgQJ2Jv5bff2avPT9mJAGd_6Hh9URA7gLKwV5D3FPvY3OybWhWqHuSBmcam6aefoId7rDb5oy--fhmP8eyVWW0JyEV9kIx3-MUse4ARD0KfZOBmXxYTN4hmzK0rM_cDYVqWOTphCggrORWFW9JR6qpSdijsjj9uQBVayCfZvqNBg_OVZzQ4JoOh4mTfSMzOd3FvJ34kWJeL6lhCST7jgzCA2y_dC2LG7yL_elRj7XYlxQfxE8",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyyhmQ9JpYsEWa48OzyYCFRg7WZD4NPDL9XbZ3ZxYQ1Dx2UYKHkEUc-DYd5JW_mtsq72jG7HXJIKj5XnsdQCuP0AAhlGw1nWniRT2kh9vK-B_3RmDUWvrjuWUma3l2usv4hhc0eYiGMlelLfgBWJgdRai79X0E0DHgLyF7lVHvybJDvjT59njYDVvdqw6ho-201tMFiVeQM-1EbJJolLosNamBEPLdicvldLQDzTx6XNJ6lLCdHKTt27GjfWRnj3aAYlWRqkcxIfOYbhwBYI5z09VEw2whLVXCNMw7OHxj6VumDQH9ISjkHwVDe6aE__KWJXGva5jTg8nCOL7mxxcvs77uIxJzmiLp6SJygR-D1kYbYYWAFycEs7BFcQngsh3-ApXS2JGrrJyNRRrA9FzTp83EVjsgWIPF7VJ_0cQD7N0L2666c9oaD007-5ttogM-uxFHAEUTVFg3mgW8n-0y2sjhNILz-Z9f4EbtdTdNW4S5edOGcv9QpbYgz24m52VNNyNyhabSd0t5dzM5heD55voANwhzYkk-ZC5EHexlhtQKm19p2QmCl7Q4Q0u2maGJumtIGiHX_kBwKrIgKRfhHtS-V1LbGFyWwqG72jlkYnpXUnpVnTsAFoBdInshrPFwbTwLNwZJjk_WyEVMMoBmXsTvhTz2yO92uWLQZFqNXTFydIiNS__-dvdUn8ouBMvhvV5gJHjiyh5dQii08TbJLux9Zuz9AIQZ1dYSWtEtixMm9S079OTWJzchPhpkklu5p7RxGETWz00d-omW5o59U2Yap4KycgthSvz4V9lStfRBr-lvsxFRLGH8IGXze_TLgeuHygZHJ9oMyrqgQJ2Jv5bff2avPT9mJAGd_6Hh9URA7gLKwV5D3FPvY3OybWhWqHuSBmcam6aefoId7rDb5oy--fhmP8eyVWW0JyEV9kIx3-MUse4ARD0KfZOBmXxYTN4hmzK0rM_cDYVqWOTphCggrORWFW9JR6qpSdijsjj9uQBVayCfZvqNBg_OVZzQ4JoOh4mTfSMzOd3FvJ34kWJeL6lhCST7jgzCA2y_dC2LG7yL_elRj7XYlxQfxE8",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00008b95d602",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00008b95d602_0",
                    "local_arrival": "2024-09-17T09:45:00.000Z",
                    "local_departure": "2024-09-17T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-17T07:45:00.000Z",
                    "utc_departure": "2024-09-17T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyyhmQ9JpYsEWa48OzyYCFRg7WZD4NPDL9XbZ3ZxYQ1Dx2UYKHkEUc-DYd5JW_mtsq72jG7HXJIKj5XnsdQCuP0AAhlGw1nWniRT2kh9vK-B_3RmDUWvrjuWUma3l2usv4hhc0eYiGMlelLfgBWJgdRai79X0E0DHgLyF7lVHvybJDvjT59njYDVvdqw6ho-201tMFiVeQM-1EbJJolLosNamBEPLdicvldLQDzTx6XNJ6lLCdHKTt27GjfWRnj3aAYlWRqkcxIfOYbhwBYI5z09VEw2whLVXCNMw7OHxj6VumDQH9ISjkHwVDe6aE__KWJXGva5jTg8nCOL7mxxcvs77uIxJzmiLp6SJygR-D1kYbYYWAFycEs7BFcQngsh3-ApXS2JGrrJyNRRrA9FzTp83EVjsgWIPF7VJ_0cQD7N0L2666c9oaD007-5ttogM-uxFHAEUTVFg3mgW8n-0y2sjhNILz-Z9f4EbtdTdNW4S5edOGcv9QpbYgz24m52VNNyNyhabSd0t5dzM5heD55voANwhzYkk-ZC5EHexlhtQKm19p2QmCl7Q4Q0u2maGJumtIGiHX_kBwKrIgKRfhHtS-V1LbGFyWwqG72jlkYnpXUnpVnTsAFoBdInshrPFwbTwLNwZJjk_WyEVMMoBmXsTvhTz2yO92uWLQZFqNXTFydIiNS__-dvdUn8ouBMvhvV5gJHjiyh5dQii08TbJLux9Zuz9AIQZ1dYSWtEtixMm9S079OTWJzchPhpkklu5p7RxGETWz00d-omW5o59U2Yap4KycgthSvz4V9lStfRBr-lvsxFRLGH8IGXze_TLgeuHygZHJ9oMyrqgQJ2Jv5bff2avPT9mJAGd_6Hh9URA7gLKwV5D3FPvY3OybWhWqHuSBmcam6aefoId7rDb5oy--fhmP8eyVWW0JyEV9kIx3-MUse4ARD0KfZOBmXxYTN4hmzK0rM_cDYVqWOTphCggrORWFW9JR6qpSdijsjj9uQBVayCfZvqNBg_OVZzQ4JoOh4mTfSMzOd3FvJ34kWJeL6lhCST7jgzCA2y_dC2LG7yL_elRj7XYlxQfxE8",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1589.39,
            "totalPrice": 1394
          },
          {
            "allPassengerTotalPrice": 1394,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GwZOO-67V6h5t-RiytWjesKeZPRoJ0k95SCMLYBUweLyaKcbJFgwKO-QMFivfcU4msOjIMDgripvnGIoZ02ztJg6bGYQjfEfp3BfUNsx-dwN8jOJKpFdCAM7udmZ6r2xfhpeeqgj1rgek1lxwF8bdO-pJXp0gg_-bWicq6tVNorT7OdfsukUw-6136ZASG5DojV-x6yStpNbUGaq5mKlPHE9xV0JW6FQcO4s-z_QVrprQ1PU-Xy77AassoNMk4KJWeOlEuOUanN28MKZ4BXvu7Vv9i_hp6i0o6Ccbe2IiXD_6RqTG6YJStuZ4BWAx7TC6qReLWAycfE3Gi2p_B4d6foLO8yHeXZTkqsNkjLWn61CWJu41HqBwALeFA58cq95VLtuwljAf3n3KSE5yFFepB58lG_QT7MxBRIvqKspXqKq5e0ylh_2Lg_2WrFD6PFKSHI4Zp5btDcIRFQWqWVKJOvI_16lpAFAQ_6dCdXFO8lLAibRyc3v-NBUvHkPTJVmiTVj5WZJf-yrHF5Sd78WXEIthR8vJly0cmZC7wi-CRuKKX2K8TlJPIVQHbUKhY4fHqnyLy5FBHMbbzRGkgGCszBL2hbcWitvT-zIfS-imQpHQKTz40CT0t3xtKESAKEzeerIybbNNkZSkjTw6wn4rvTwOw75ZlMsyJ4Zp_ycwtXOQGhovwwKsBso89bNuuvppoGtwAt8cSjTarQ1yCJ-J3C7_I0dXsfTumD6W0ApWe9AxTer6--x7mViI7X0orX3xXQ9BU-KpDJl0OxK8mzZXm8YpD_8Zoq4QPS-VLQEzRrkHfufCYXJGlSu5run4cL0lBNmOn5G4vp5F7Il5Z9-IvkPaBnufEA5eyI1FP5mXmXd2hOGPdTmeJBNTlbO0_kGUjFz_2W-NtqqOuccJa3kwi2WIHFdmgIM1yWdVSetz0U1BTyZVuOy-Hk9op3Yz_ROfpR4D0zZ_vexgfUiXIMAGZnwczuX2HF6Z8RXS1GroycutM-gf1uh62280QkaNIoXYy67DHEPJkKmdfruxR74ut_BO0umBaLRgsvZKc5DGfU2ODIWb4dAHvHpfBcZOpnRq",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GwZOO-67V6h5t-RiytWjesKeZPRoJ0k95SCMLYBUweLyaKcbJFgwKO-QMFivfcU4msOjIMDgripvnGIoZ02ztJg6bGYQjfEfp3BfUNsx-dwN8jOJKpFdCAM7udmZ6r2xfhpeeqgj1rgek1lxwF8bdO-pJXp0gg_-bWicq6tVNorT7OdfsukUw-6136ZASG5DojV-x6yStpNbUGaq5mKlPHE9xV0JW6FQcO4s-z_QVrprQ1PU-Xy77AassoNMk4KJWeOlEuOUanN28MKZ4BXvu7Vv9i_hp6i0o6Ccbe2IiXD_6RqTG6YJStuZ4BWAx7TC6qReLWAycfE3Gi2p_B4d6foLO8yHeXZTkqsNkjLWn61CWJu41HqBwALeFA58cq95VLtuwljAf3n3KSE5yFFepB58lG_QT7MxBRIvqKspXqKq5e0ylh_2Lg_2WrFD6PFKSHI4Zp5btDcIRFQWqWVKJOvI_16lpAFAQ_6dCdXFO8lLAibRyc3v-NBUvHkPTJVmiTVj5WZJf-yrHF5Sd78WXEIthR8vJly0cmZC7wi-CRuKKX2K8TlJPIVQHbUKhY4fHqnyLy5FBHMbbzRGkgGCszBL2hbcWitvT-zIfS-imQpHQKTz40CT0t3xtKESAKEzeerIybbNNkZSkjTw6wn4rvTwOw75ZlMsyJ4Zp_ycwtXOQGhovwwKsBso89bNuuvppoGtwAt8cSjTarQ1yCJ-J3C7_I0dXsfTumD6W0ApWe9AxTer6--x7mViI7X0orX3xXQ9BU-KpDJl0OxK8mzZXm8YpD_8Zoq4QPS-VLQEzRrkHfufCYXJGlSu5run4cL0lBNmOn5G4vp5F7Il5Z9-IvkPaBnufEA5eyI1FP5mXmXd2hOGPdTmeJBNTlbO0_kGUjFz_2W-NtqqOuccJa3kwi2WIHFdmgIM1yWdVSetz0U1BTyZVuOy-Hk9op3Yz_ROfpR4D0zZ_vexgfUiXIMAGZnwczuX2HF6Z8RXS1GroycutM-gf1uh62280QkaNIoXYy67DHEPJkKmdfruxR74ut_BO0umBaLRgsvZKc5DGfU2ODIWb4dAHvHpfBcZOpnRq",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00007c80866f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00007c80866f_0",
                    "local_arrival": "2024-09-17T18:15:00.000Z",
                    "local_departure": "2024-09-17T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:15:00.000Z",
                    "utc_departure": "2024-09-17T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00007c80866f_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GwZOO-67V6h5t-RiytWjesKeZPRoJ0k95SCMLYBUweLyaKcbJFgwKO-QMFivfcU4msOjIMDgripvnGIoZ02ztJg6bGYQjfEfp3BfUNsx-dwN8jOJKpFdCAM7udmZ6r2xfhpeeqgj1rgek1lxwF8bdO-pJXp0gg_-bWicq6tVNorT7OdfsukUw-6136ZASG5DojV-x6yStpNbUGaq5mKlPHE9xV0JW6FQcO4s-z_QVrprQ1PU-Xy77AassoNMk4KJWeOlEuOUanN28MKZ4BXvu7Vv9i_hp6i0o6Ccbe2IiXD_6RqTG6YJStuZ4BWAx7TC6qReLWAycfE3Gi2p_B4d6foLO8yHeXZTkqsNkjLWn61CWJu41HqBwALeFA58cq95VLtuwljAf3n3KSE5yFFepB58lG_QT7MxBRIvqKspXqKq5e0ylh_2Lg_2WrFD6PFKSHI4Zp5btDcIRFQWqWVKJOvI_16lpAFAQ_6dCdXFO8lLAibRyc3v-NBUvHkPTJVmiTVj5WZJf-yrHF5Sd78WXEIthR8vJly0cmZC7wi-CRuKKX2K8TlJPIVQHbUKhY4fHqnyLy5FBHMbbzRGkgGCszBL2hbcWitvT-zIfS-imQpHQKTz40CT0t3xtKESAKEzeerIybbNNkZSkjTw6wn4rvTwOw75ZlMsyJ4Zp_ycwtXOQGhovwwKsBso89bNuuvppoGtwAt8cSjTarQ1yCJ-J3C7_I0dXsfTumD6W0ApWe9AxTer6--x7mViI7X0orX3xXQ9BU-KpDJl0OxK8mzZXm8YpD_8Zoq4QPS-VLQEzRrkHfufCYXJGlSu5run4cL0lBNmOn5G4vp5F7Il5Z9-IvkPaBnufEA5eyI1FP5mXmXd2hOGPdTmeJBNTlbO0_kGUjFz_2W-NtqqOuccJa3kwi2WIHFdmgIM1yWdVSetz0U1BTyZVuOy-Hk9op3Yz_ROfpR4D0zZ_vexgfUiXIMAGZnwczuX2HF6Z8RXS1GroycutM-gf1uh62280QkaNIoXYy67DHEPJkKmdfruxR74ut_BO0umBaLRgsvZKc5DGfU2ODIWb4dAHvHpfBcZOpnRq",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1590.06,
            "totalPrice": 1394
          },
          {
            "allPassengerTotalPrice": 1397,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcEXTdIjsETh42oNRIrsfvGAA-kJlqfu1mraFrF5inMrPfAW9Q7SZhTYPm-80A6ty1DLRqh2bxzzZe5FveHqMC7-A1PZLjo5mODc50orB7MoKOG8DKvDNFBE6P0pxS3lZeFO_UsgVckFT6cKeoCD9M-5hrz2xnz8wrcyOT7xR7Eru7L3YPRGABpADLR6SkSztm_5b_U7ubmpdM665xDw_GBIA_F1lE0Qd-X7NvI6lzc5VfUNCSOivDubdURRbrmhkLmdN7xEEwd3EKxFqi6jIgZusYxo3_-KoXYUbXxDjhCO8lMMj6qB4ppZmZYE9h-_VMD19yzzVWmAeBki0yZBCoPBRk6aWDCjLnItt-wiAGo5ytKMdumZn5Ir0QdOC8h8iUHVDqYgOL4g3Y8Iky5vB3mRjVm92k4bhLvcNh2e4WYpxLkXoW3gxI_Uu-UxfNYYJjR-9y-crHtL3CYtTzuM4UHaiemjg6Ge4xUIRnH_97KguCz5ceHo5CBbUIVgOZPAvQOqtA4bTHx5tnjg2hd0DE3zegacy0BJPenMRnP_Mahrr4Skw9C6P1dDLrsczdzy596qtit0f2oalIYPmvkg0O2D2XWPUSSx6UopXZmQ7QV7SGrMUCDiB2hai2ga5uW3HCfHhUjd-j6HPUptGzalnUgnzkUeLbOMfZ60H4GoilsL7-2nFIX_aYUeIQRkPUV2qYoGnUJQIas-McrpC6FF2imHFYpf76qdpvR7oH0q9zvY4KJaprM_6LpYVtKryFtyvf24aWnAjQQs3_v2AZ-np8jVkn_xu-Bw1JbzR-M1K6Zwk3IQft4Sp9UG47bvCjPixIpkav8tG1m30tM_I_tQN0fxeOdIMEbsRQykYd92NxdQsfcUGRlnYqilWUUkePE1sleLZ7V9TOYcL4jE4eUztngdolxyaheOF4PIIQTDTQOazD8wGXfwuJXqvnXzwiJHRdZ_LMmsCp5DNCW9wI-hLmgURL-K-BWgmOg3U15lkxB5oNFUkDdD_hHRYWIkQhLTbiT9BjWC3ywbrXUAT112exg==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcEXTdIjsETh42oNRIrsfvGAA-kJlqfu1mraFrF5inMrPfAW9Q7SZhTYPm-80A6ty1DLRqh2bxzzZe5FveHqMC7-A1PZLjo5mODc50orB7MoKOG8DKvDNFBE6P0pxS3lZeFO_UsgVckFT6cKeoCD9M-5hrz2xnz8wrcyOT7xR7Eru7L3YPRGABpADLR6SkSztm_5b_U7ubmpdM665xDw_GBIA_F1lE0Qd-X7NvI6lzc5VfUNCSOivDubdURRbrmhkLmdN7xEEwd3EKxFqi6jIgZusYxo3_-KoXYUbXxDjhCO8lMMj6qB4ppZmZYE9h-_VMD19yzzVWmAeBki0yZBCoPBRk6aWDCjLnItt-wiAGo5ytKMdumZn5Ir0QdOC8h8iUHVDqYgOL4g3Y8Iky5vB3mRjVm92k4bhLvcNh2e4WYpxLkXoW3gxI_Uu-UxfNYYJjR-9y-crHtL3CYtTzuM4UHaiemjg6Ge4xUIRnH_97KguCz5ceHo5CBbUIVgOZPAvQOqtA4bTHx5tnjg2hd0DE3zegacy0BJPenMRnP_Mahrr4Skw9C6P1dDLrsczdzy596qtit0f2oalIYPmvkg0O2D2XWPUSSx6UopXZmQ7QV7SGrMUCDiB2hai2ga5uW3HCfHhUjd-j6HPUptGzalnUgnzkUeLbOMfZ60H4GoilsL7-2nFIX_aYUeIQRkPUV2qYoGnUJQIas-McrpC6FF2imHFYpf76qdpvR7oH0q9zvY4KJaprM_6LpYVtKryFtyvf24aWnAjQQs3_v2AZ-np8jVkn_xu-Bw1JbzR-M1K6Zwk3IQft4Sp9UG47bvCjPixIpkav8tG1m30tM_I_tQN0fxeOdIMEbsRQykYd92NxdQsfcUGRlnYqilWUUkePE1sleLZ7V9TOYcL4jE4eUztngdolxyaheOF4PIIQTDTQOazD8wGXfwuJXqvnXzwiJHRdZ_LMmsCp5DNCW9wI-hLmgURL-K-BWgmOg3U15lkxB5oNFUkDdD_hHRYWIkQhLTbiT9BjWC3ywbrXUAT112exg==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f000084327e22",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f000084327e22_0",
                    "local_arrival": "2024-09-17T18:55:00.000Z",
                    "local_departure": "2024-09-17T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-17T16:55:00.000Z",
                    "utc_departure": "2024-09-17T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f000084327e22_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GcEXTdIjsETh42oNRIrsfvGAA-kJlqfu1mraFrF5inMrPfAW9Q7SZhTYPm-80A6ty1DLRqh2bxzzZe5FveHqMC7-A1PZLjo5mODc50orB7MoKOG8DKvDNFBE6P0pxS3lZeFO_UsgVckFT6cKeoCD9M-5hrz2xnz8wrcyOT7xR7Eru7L3YPRGABpADLR6SkSztm_5b_U7ubmpdM665xDw_GBIA_F1lE0Qd-X7NvI6lzc5VfUNCSOivDubdURRbrmhkLmdN7xEEwd3EKxFqi6jIgZusYxo3_-KoXYUbXxDjhCO8lMMj6qB4ppZmZYE9h-_VMD19yzzVWmAeBki0yZBCoPBRk6aWDCjLnItt-wiAGo5ytKMdumZn5Ir0QdOC8h8iUHVDqYgOL4g3Y8Iky5vB3mRjVm92k4bhLvcNh2e4WYpxLkXoW3gxI_Uu-UxfNYYJjR-9y-crHtL3CYtTzuM4UHaiemjg6Ge4xUIRnH_97KguCz5ceHo5CBbUIVgOZPAvQOqtA4bTHx5tnjg2hd0DE3zegacy0BJPenMRnP_Mahrr4Skw9C6P1dDLrsczdzy596qtit0f2oalIYPmvkg0O2D2XWPUSSx6UopXZmQ7QV7SGrMUCDiB2hai2ga5uW3HCfHhUjd-j6HPUptGzalnUgnzkUeLbOMfZ60H4GoilsL7-2nFIX_aYUeIQRkPUV2qYoGnUJQIas-McrpC6FF2imHFYpf76qdpvR7oH0q9zvY4KJaprM_6LpYVtKryFtyvf24aWnAjQQs3_v2AZ-np8jVkn_xu-Bw1JbzR-M1K6Zwk3IQft4Sp9UG47bvCjPixIpkav8tG1m30tM_I_tQN0fxeOdIMEbsRQykYd92NxdQsfcUGRlnYqilWUUkePE1sleLZ7V9TOYcL4jE4eUztngdolxyaheOF4PIIQTDTQOazD8wGXfwuJXqvnXzwiJHRdZ_LMmsCp5DNCW9wI-hLmgURL-K-BWgmOg3U15lkxB5oNFUkDdD_hHRYWIkQhLTbiT9BjWC3ywbrXUAT112exg==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1592.55,
            "totalPrice": 1397
          },
          {
            "allPassengerTotalPrice": 1399,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GrEITzZ3dP1zZkl9afpJ7J0nbggteAkUmZWSVARFtRiLd_ilYXuhA6lHTQsuikYlMLKIJ4zyskWZuF3apvDxDHHkQ_bFmuapYEdcPxhZURkbGi4WJGlaWyrMR6NV47gRii-WuRMhP3KRy2syAIooyRlkNPzdDEBxEh_4ZIbfFA7DAB_h2sHpKhF-ECS7JUvhUotGLwnaXr1vSq2rychBo1LkQQtTNyMY42Pt5c9FFwBVorTfWbICr_VlR86MVsyRNJRcZzIoCop886EE0wGxAMNWbArwllwp6QNF-8LDhV-N9k2eHABMxxc1bJ6OUubjxM2wywbmaTrrHdaq70BJcgTfkwOgk1PJiO5pMXJ7tQHY2-EXFAGy71qQdNghQDV-ISgwu0t9aAu3TTx5rdtd6_CKN2aI8Ms8s-a5ZZBShX01rFKajTC1IhqJ6za5uivb62DnQ00g5DF2b7IkDABOgi3GWCM_0ATLyhFsPD8IUAkYrMXXmFA1IkmTCn5LZ98DZqLrVBbUwN5ebzEENsCoGDq8YOOz-D56pcuevXPNSqvFoENmoS8mNvbLyJYzRDJZCgVusIdDoZqIZgJ9PrsGf9DDKSigEoTIpP3Fs8Sxw_jnMjgIP-mIxJ6kw9UN-iEtuM1cz0_iHUqhuBQcXV2XoaqTgcs90l2vrMXOUrMxjOwoK4gC_05z9bMjphx4NqPZXTUmbr7M31HC206MlhRN1lk_g0QYom4FDH1mcLLu-soVqcTB21kAUcXG7Us-NF9Mjk-drILnpFLf0KzFG-PmU7AvbYgSxPYDAOTxGwT8qoDYAvJuX2v2DRr0SDmHGtawNJOtOzynS47Zu4nMMgUIOF5TH6wKuTJETJZ-jOMvE54P6n-lCM7i1TI8ACCf5gNPDkBMvPDpHkrV7xGetxlxXJvl9JNBYmNoY0Ff4KDjTNK03rl4UFSNsCMIvN3XURj3Kt8htvjqhA3-VpGiE5TIIClQlnbjaWu-mYrrQ1RNeIY_t--Hq18et2Dtws7OZQVj4Ee3fTcbZy0cXuSRnp83E0A==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GrEITzZ3dP1zZkl9afpJ7J0nbggteAkUmZWSVARFtRiLd_ilYXuhA6lHTQsuikYlMLKIJ4zyskWZuF3apvDxDHHkQ_bFmuapYEdcPxhZURkbGi4WJGlaWyrMR6NV47gRii-WuRMhP3KRy2syAIooyRlkNPzdDEBxEh_4ZIbfFA7DAB_h2sHpKhF-ECS7JUvhUotGLwnaXr1vSq2rychBo1LkQQtTNyMY42Pt5c9FFwBVorTfWbICr_VlR86MVsyRNJRcZzIoCop886EE0wGxAMNWbArwllwp6QNF-8LDhV-N9k2eHABMxxc1bJ6OUubjxM2wywbmaTrrHdaq70BJcgTfkwOgk1PJiO5pMXJ7tQHY2-EXFAGy71qQdNghQDV-ISgwu0t9aAu3TTx5rdtd6_CKN2aI8Ms8s-a5ZZBShX01rFKajTC1IhqJ6za5uivb62DnQ00g5DF2b7IkDABOgi3GWCM_0ATLyhFsPD8IUAkYrMXXmFA1IkmTCn5LZ98DZqLrVBbUwN5ebzEENsCoGDq8YOOz-D56pcuevXPNSqvFoENmoS8mNvbLyJYzRDJZCgVusIdDoZqIZgJ9PrsGf9DDKSigEoTIpP3Fs8Sxw_jnMjgIP-mIxJ6kw9UN-iEtuM1cz0_iHUqhuBQcXV2XoaqTgcs90l2vrMXOUrMxjOwoK4gC_05z9bMjphx4NqPZXTUmbr7M31HC206MlhRN1lk_g0QYom4FDH1mcLLu-soVqcTB21kAUcXG7Us-NF9Mjk-drILnpFLf0KzFG-PmU7AvbYgSxPYDAOTxGwT8qoDYAvJuX2v2DRr0SDmHGtawNJOtOzynS47Zu4nMMgUIOF5TH6wKuTJETJZ-jOMvE54P6n-lCM7i1TI8ACCf5gNPDkBMvPDpHkrV7xGetxlxXJvl9JNBYmNoY0Ff4KDjTNK03rl4UFSNsCMIvN3XURj3Kt8htvjqhA3-VpGiE5TIIClQlnbjaWu-mYrrQ1RNeIY_t--Hq18et2Dtws7OZQVj4Ee3fTcbZy0cXuSRnp83E0A==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00006a3c1f0e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00006a3c1f0e_0",
                    "local_arrival": "2024-09-17T10:25:00.000Z",
                    "local_departure": "2024-09-17T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-17T08:25:00.000Z",
                    "utc_departure": "2024-09-17T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00006a3c1f0e_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GrEITzZ3dP1zZkl9afpJ7J0nbggteAkUmZWSVARFtRiLd_ilYXuhA6lHTQsuikYlMLKIJ4zyskWZuF3apvDxDHHkQ_bFmuapYEdcPxhZURkbGi4WJGlaWyrMR6NV47gRii-WuRMhP3KRy2syAIooyRlkNPzdDEBxEh_4ZIbfFA7DAB_h2sHpKhF-ECS7JUvhUotGLwnaXr1vSq2rychBo1LkQQtTNyMY42Pt5c9FFwBVorTfWbICr_VlR86MVsyRNJRcZzIoCop886EE0wGxAMNWbArwllwp6QNF-8LDhV-N9k2eHABMxxc1bJ6OUubjxM2wywbmaTrrHdaq70BJcgTfkwOgk1PJiO5pMXJ7tQHY2-EXFAGy71qQdNghQDV-ISgwu0t9aAu3TTx5rdtd6_CKN2aI8Ms8s-a5ZZBShX01rFKajTC1IhqJ6za5uivb62DnQ00g5DF2b7IkDABOgi3GWCM_0ATLyhFsPD8IUAkYrMXXmFA1IkmTCn5LZ98DZqLrVBbUwN5ebzEENsCoGDq8YOOz-D56pcuevXPNSqvFoENmoS8mNvbLyJYzRDJZCgVusIdDoZqIZgJ9PrsGf9DDKSigEoTIpP3Fs8Sxw_jnMjgIP-mIxJ6kw9UN-iEtuM1cz0_iHUqhuBQcXV2XoaqTgcs90l2vrMXOUrMxjOwoK4gC_05z9bMjphx4NqPZXTUmbr7M31HC206MlhRN1lk_g0QYom4FDH1mcLLu-soVqcTB21kAUcXG7Us-NF9Mjk-drILnpFLf0KzFG-PmU7AvbYgSxPYDAOTxGwT8qoDYAvJuX2v2DRr0SDmHGtawNJOtOzynS47Zu4nMMgUIOF5TH6wKuTJETJZ-jOMvE54P6n-lCM7i1TI8ACCf5gNPDkBMvPDpHkrV7xGetxlxXJvl9JNBYmNoY0Ff4KDjTNK03rl4UFSNsCMIvN3XURj3Kt8htvjqhA3-VpGiE5TIIClQlnbjaWu-mYrrQ1RNeIY_t--Hq18et2Dtws7OZQVj4Ee3fTcbZy0cXuSRnp83E0A==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1593.7,
            "totalPrice": 1399
          },
          {
            "allPassengerTotalPrice": 1403,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GB4ykjC67PxklBUioaij5scGbmqWJPDqm3JYuooW6ZyS7wsxx0qkAfv69MiHiyVp6bO6nsSsIV94h9M7qOXiKyUkWFu_oYAbOA74sbwb4miWPMM8kgdv5Cs2BTcKIrXZ4t4dbX0xTEGdqWYG8JhG1HqUV1G89X_Q7Y8WR1x3yBexhS241XRTP3HJkT-xX33gGTnguxRNVDhE1ItKqGhXI_CYDf3GOJTRdtW6Io0JG6YCxteqoUMrYeq6aFomsUWB-caxUHOacWEGCDylvhATUL0E_pUtoOUiHkN93tLW4F5RErqcnyaMgdeT3l46ES-puDTRIzkJQ8BAcx2FMbPBb7j05kdCZTzTajWQKyJn7NN2h_bCMqXudPVcCbHXSQZpRlVGKzT3G1OgQ-xtGTcM8XdGC2BoxzF7PfeAowFOFFB70dEp_NbgcuvxufeAKFLdijD75TnAYZF9KhfGN4csdgcBEzidf4wMKF9e0sbNon800wxsiRo388kVdbY6UA3xtGc6yLi7JtaZ_owAAPVbLISLz7yI1cN0iJarPBx-L00-nwWQaqCZ3kY7u4ql6SzFd5UcIRttdajmXZfuYs1H7xMVPPIeN7GD0ialAjmDqNq4jfCwRzaICgZvDeLinjWgjGu0aGA6__jMIAX10vd6zwoR23zBFu1nvEiNfdQZwz1EkrMIhdmU7RpM63Np1-d6eqLVKCLyJo7krEQHdCj-9-OWwD8_6gLgpGfsQC_S9fdzr_GhIRwJ447hRbBEOYokRAE24o8j2q29WB9vNRDuVdsS_HG2MoYNFRrftpruxdQEmKOv12aDqT58fw_vUjP2RZH4OOh1Iep25Z2xjETkfzjBJHPPY00WFvl9m06gehPmlM_VUy918QzLuR4EPiq_DbmXMLSgDQRGn8cMEodGQySYuUXQ1Amx09pX5V6x8Rnu1AARjW2WpF065_1UxKSDdhZiz9YI3GVqDxS2y5sy368iS9uR53SGRM2B05dW7plB_1LSy0RoPfdngFN1DFQXiEwSvqh9fRbTB-YT5augt0z9VcsWwBlQwpbQ4sgGI6N0EefjHmNdb2ukGd8F-GOv-",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GB4ykjC67PxklBUioaij5scGbmqWJPDqm3JYuooW6ZyS7wsxx0qkAfv69MiHiyVp6bO6nsSsIV94h9M7qOXiKyUkWFu_oYAbOA74sbwb4miWPMM8kgdv5Cs2BTcKIrXZ4t4dbX0xTEGdqWYG8JhG1HqUV1G89X_Q7Y8WR1x3yBexhS241XRTP3HJkT-xX33gGTnguxRNVDhE1ItKqGhXI_CYDf3GOJTRdtW6Io0JG6YCxteqoUMrYeq6aFomsUWB-caxUHOacWEGCDylvhATUL0E_pUtoOUiHkN93tLW4F5RErqcnyaMgdeT3l46ES-puDTRIzkJQ8BAcx2FMbPBb7j05kdCZTzTajWQKyJn7NN2h_bCMqXudPVcCbHXSQZpRlVGKzT3G1OgQ-xtGTcM8XdGC2BoxzF7PfeAowFOFFB70dEp_NbgcuvxufeAKFLdijD75TnAYZF9KhfGN4csdgcBEzidf4wMKF9e0sbNon800wxsiRo388kVdbY6UA3xtGc6yLi7JtaZ_owAAPVbLISLz7yI1cN0iJarPBx-L00-nwWQaqCZ3kY7u4ql6SzFd5UcIRttdajmXZfuYs1H7xMVPPIeN7GD0ialAjmDqNq4jfCwRzaICgZvDeLinjWgjGu0aGA6__jMIAX10vd6zwoR23zBFu1nvEiNfdQZwz1EkrMIhdmU7RpM63Np1-d6eqLVKCLyJo7krEQHdCj-9-OWwD8_6gLgpGfsQC_S9fdzr_GhIRwJ447hRbBEOYokRAE24o8j2q29WB9vNRDuVdsS_HG2MoYNFRrftpruxdQEmKOv12aDqT58fw_vUjP2RZH4OOh1Iep25Z2xjETkfzjBJHPPY00WFvl9m06gehPmlM_VUy918QzLuR4EPiq_DbmXMLSgDQRGn8cMEodGQySYuUXQ1Amx09pX5V6x8Rnu1AARjW2WpF065_1UxKSDdhZiz9YI3GVqDxS2y5sy368iS9uR53SGRM2B05dW7plB_1LSy0RoPfdngFN1DFQXiEwSvqh9fRbTB-YT5augt0z9VcsWwBlQwpbQ4sgGI6N0EefjHmNdb2ukGd8F-GOv-",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f00002401a9db",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f00002401a9db_0",
                    "local_arrival": "2024-09-17T22:20:00.000Z",
                    "local_departure": "2024-09-17T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-17T20:20:00.000Z",
                    "utc_departure": "2024-09-17T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f00002401a9db_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GB4ykjC67PxklBUioaij5scGbmqWJPDqm3JYuooW6ZyS7wsxx0qkAfv69MiHiyVp6bO6nsSsIV94h9M7qOXiKyUkWFu_oYAbOA74sbwb4miWPMM8kgdv5Cs2BTcKIrXZ4t4dbX0xTEGdqWYG8JhG1HqUV1G89X_Q7Y8WR1x3yBexhS241XRTP3HJkT-xX33gGTnguxRNVDhE1ItKqGhXI_CYDf3GOJTRdtW6Io0JG6YCxteqoUMrYeq6aFomsUWB-caxUHOacWEGCDylvhATUL0E_pUtoOUiHkN93tLW4F5RErqcnyaMgdeT3l46ES-puDTRIzkJQ8BAcx2FMbPBb7j05kdCZTzTajWQKyJn7NN2h_bCMqXudPVcCbHXSQZpRlVGKzT3G1OgQ-xtGTcM8XdGC2BoxzF7PfeAowFOFFB70dEp_NbgcuvxufeAKFLdijD75TnAYZF9KhfGN4csdgcBEzidf4wMKF9e0sbNon800wxsiRo388kVdbY6UA3xtGc6yLi7JtaZ_owAAPVbLISLz7yI1cN0iJarPBx-L00-nwWQaqCZ3kY7u4ql6SzFd5UcIRttdajmXZfuYs1H7xMVPPIeN7GD0ialAjmDqNq4jfCwRzaICgZvDeLinjWgjGu0aGA6__jMIAX10vd6zwoR23zBFu1nvEiNfdQZwz1EkrMIhdmU7RpM63Np1-d6eqLVKCLyJo7krEQHdCj-9-OWwD8_6gLgpGfsQC_S9fdzr_GhIRwJ447hRbBEOYokRAE24o8j2q29WB9vNRDuVdsS_HG2MoYNFRrftpruxdQEmKOv12aDqT58fw_vUjP2RZH4OOh1Iep25Z2xjETkfzjBJHPPY00WFvl9m06gehPmlM_VUy918QzLuR4EPiq_DbmXMLSgDQRGn8cMEodGQySYuUXQ1Amx09pX5V6x8Rnu1AARjW2WpF065_1UxKSDdhZiz9YI3GVqDxS2y5sy368iS9uR53SGRM2B05dW7plB_1LSy0RoPfdngFN1DFQXiEwSvqh9fRbTB-YT5augt0z9VcsWwBlQwpbQ4sgGI6N0EefjHmNdb2ukGd8F-GOv-",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1597.06,
            "totalPrice": 1403
          },
          {
            "allPassengerTotalPrice": 1418,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-08",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gp1i7HRvw-YFCxeN_IFfo1PcVBv1stJ0bPxH4WKY5q0BkrD_R7Y-rS9ORt68KGh07OIJD9IGCm5Bw9yJ57DIU0Bn4gbqHbmDy6dsEcLKIb9JxcphXt8b1pj07FTuxmSc8xETHfLPDKhBys9RcwSIuGW_dKM2K1SR9iaCghvcptWERlVeYu6YOrYvh86uZ_w7Uc2UZKfsr8-ShVLyddcj6EZzDEYNejfQmV1sT6G8B_ee7QDucmhLMLZNAZH872riTSo61BDEman32scC915p1m_bV69hJMM4s6OeZgEJ93nvFo4Pcw8G44MGOSDrYqi4Yry9271NKsCZGNaxX_1rX9dyxApJtf2gRRpagOXEADHyX0qCsXPFC-2zXvFp5Vw6ymIlrKFvdlWHRCIwBILPvVF8rf6CDbWfMn6QO4Z76PRY-16wyKbIiIsLePD3Fh-uXog1p2o6RlR7p9SnzFz-1Q2PfejdNCpL9lEpk8Qgzr8euOViY0IGw5mxgrZn-n2AtYOtW0eXV3Yo-DOksSEE7mqPlESPsyI2quCa1gCc1cb3uziNsUK9UNmiuO_9bsgdiYW2qL-MaQ0vIiUWc3r4NmZkdvoKWXj4bjK_1CnjjVd2yprrDNKAyDBJCgRz5HmhjORG_uKGzL9R7jDnmAOsoil1USskkEGY6MHBK5u5x23CjEdfBUa5dMGrKQOSCGzbJNlKJn3OVsiox9GfUy6qQ2ge6swH9cksP_hha46Sac60V0PmXZZRBlfLVpIoE2cnksNf3-i7IVy7zJOZlfzP3pl43N2npQPHurw3tCp96mnGeLqqRhRxPZBiBnjixtN_lrm1fOe7ZMTRo4oExfzQVBYarDtDjm8r3Muf86IwPVdlmCedD0oDTjZ0caO5px6RTpqbo-ZBYNBYl3rpaKUj98xi1u9aFi_LD2Pbs71FUzHm4qh9KtWHs0Lw1t8IezhtUOsWPH6RSBD-v-7rS6raiuBoSQyND6l4BPSuUWog76ar6iIPFv1UmYgeMVX8jZR6FPXQIizqEp_w2LevhswxOPQ==",
                "dateChange": 1,
                "departureDate": "2024-09-07",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_0",
                    "local_arrival": "2024-09-08T12:15:00.000Z",
                    "local_departure": "2024-09-07T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-08T11:15:00.000Z",
                    "utc_departure": "2024-09-08T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e050000f8bceca6",
                    "equipment": null,
                    "fare_basis": "WH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e050000f8bceca6_1",
                    "local_arrival": "2024-09-08T16:55:00.000Z",
                    "local_departure": "2024-09-08T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-08T14:55:00.000Z",
                    "utc_departure": "2024-09-08T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-17",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gp1i7HRvw-YFCxeN_IFfo1PcVBv1stJ0bPxH4WKY5q0BkrD_R7Y-rS9ORt68KGh07OIJD9IGCm5Bw9yJ57DIU0Bn4gbqHbmDy6dsEcLKIb9JxcphXt8b1pj07FTuxmSc8xETHfLPDKhBys9RcwSIuGW_dKM2K1SR9iaCghvcptWERlVeYu6YOrYvh86uZ_w7Uc2UZKfsr8-ShVLyddcj6EZzDEYNejfQmV1sT6G8B_ee7QDucmhLMLZNAZH872riTSo61BDEman32scC915p1m_bV69hJMM4s6OeZgEJ93nvFo4Pcw8G44MGOSDrYqi4Yry9271NKsCZGNaxX_1rX9dyxApJtf2gRRpagOXEADHyX0qCsXPFC-2zXvFp5Vw6ymIlrKFvdlWHRCIwBILPvVF8rf6CDbWfMn6QO4Z76PRY-16wyKbIiIsLePD3Fh-uXog1p2o6RlR7p9SnzFz-1Q2PfejdNCpL9lEpk8Qgzr8euOViY0IGw5mxgrZn-n2AtYOtW0eXV3Yo-DOksSEE7mqPlESPsyI2quCa1gCc1cb3uziNsUK9UNmiuO_9bsgdiYW2qL-MaQ0vIiUWc3r4NmZkdvoKWXj4bjK_1CnjjVd2yprrDNKAyDBJCgRz5HmhjORG_uKGzL9R7jDnmAOsoil1USskkEGY6MHBK5u5x23CjEdfBUa5dMGrKQOSCGzbJNlKJn3OVsiox9GfUy6qQ2ge6swH9cksP_hha46Sac60V0PmXZZRBlfLVpIoE2cnksNf3-i7IVy7zJOZlfzP3pl43N2npQPHurw3tCp96mnGeLqqRhRxPZBiBnjixtN_lrm1fOe7ZMTRo4oExfzQVBYarDtDjm8r3Muf86IwPVdlmCedD0oDTjZ0caO5px6RTpqbo-ZBYNBYl3rpaKUj98xi1u9aFi_LD2Pbs71FUzHm4qh9KtWHs0Lw1t8IezhtUOsWPH6RSBD-v-7rS6raiuBoSQyND6l4BPSuUWog76ar6iIPFv1UmYgeMVX8jZR6FPXQIizqEp_w2LevhswxOPQ==",
                "dateChange": 0,
                "departureDate": "2024-09-17",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e0f0000c3b984dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e0f0000c3b984dc_0",
                    "local_arrival": "2024-09-17T13:45:00.000Z",
                    "local_departure": "2024-09-17T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-17T11:45:00.000Z",
                    "utc_departure": "2024-09-17T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f8bceca6_0%7C0f9c01af4e050000f8bceca6_1%7C14140a224e0f0000c3b984dc_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gp1i7HRvw-YFCxeN_IFfo1PcVBv1stJ0bPxH4WKY5q0BkrD_R7Y-rS9ORt68KGh07OIJD9IGCm5Bw9yJ57DIU0Bn4gbqHbmDy6dsEcLKIb9JxcphXt8b1pj07FTuxmSc8xETHfLPDKhBys9RcwSIuGW_dKM2K1SR9iaCghvcptWERlVeYu6YOrYvh86uZ_w7Uc2UZKfsr8-ShVLyddcj6EZzDEYNejfQmV1sT6G8B_ee7QDucmhLMLZNAZH872riTSo61BDEman32scC915p1m_bV69hJMM4s6OeZgEJ93nvFo4Pcw8G44MGOSDrYqi4Yry9271NKsCZGNaxX_1rX9dyxApJtf2gRRpagOXEADHyX0qCsXPFC-2zXvFp5Vw6ymIlrKFvdlWHRCIwBILPvVF8rf6CDbWfMn6QO4Z76PRY-16wyKbIiIsLePD3Fh-uXog1p2o6RlR7p9SnzFz-1Q2PfejdNCpL9lEpk8Qgzr8euOViY0IGw5mxgrZn-n2AtYOtW0eXV3Yo-DOksSEE7mqPlESPsyI2quCa1gCc1cb3uziNsUK9UNmiuO_9bsgdiYW2qL-MaQ0vIiUWc3r4NmZkdvoKWXj4bjK_1CnjjVd2yprrDNKAyDBJCgRz5HmhjORG_uKGzL9R7jDnmAOsoil1USskkEGY6MHBK5u5x23CjEdfBUa5dMGrKQOSCGzbJNlKJn3OVsiox9GfUy6qQ2ge6swH9cksP_hha46Sac60V0PmXZZRBlfLVpIoE2cnksNf3-i7IVy7zJOZlfzP3pl43N2npQPHurw3tCp96mnGeLqqRhRxPZBiBnjixtN_lrm1fOe7ZMTRo4oExfzQVBYarDtDjm8r3Muf86IwPVdlmCedD0oDTjZ0caO5px6RTpqbo-ZBYNBYl3rpaKUj98xi1u9aFi_LD2Pbs71FUzHm4qh9KtWHs0Lw1t8IezhtUOsWPH6RSBD-v-7rS6raiuBoSQyND6l4BPSuUWog76ar6iIPFv1UmYgeMVX8jZR6FPXQIizqEp_w2LevhswxOPQ==",
                "dateChange": 1,
                "departureDate": "2024-09-23",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_0",
                    "local_arrival": "2024-09-23T16:30:00.000Z",
                    "local_departure": "2024-09-23T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-23T15:30:00.000Z",
                    "utc_departure": "2024-09-23T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e150000d4f94b56",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e150000d4f94b56_1",
                    "local_arrival": "2024-09-24T14:05:00.000Z",
                    "local_departure": "2024-09-24T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:05:00.000Z",
                    "utc_departure": "2024-09-24T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1609.39,
            "totalPrice": 1418
          }
        ]
      },
      {
        "dateOptionLabel": "2024-09-14 to 2024-09-30",
        "itineraries": [
          {
            "allPassengerTotalPrice": 800,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "NK",
                  "IB"
                ],
                "arrivalDate": "2024-09-16",
                "arrivalTime": "16:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=076e243e4e0c00006b2e0782_0%7C076e243e4e0c00006b2e0782_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SJC&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GXNp7WDkXCyUDbLrpmT4XlzN-MUmpFaUJE3fPP8fFOsPiLoLE1rqb7Nep1Ezf2wpNazL3NzWDNgcLveIsvg4GSvZ_aAmPLjQkMD_brHYKIyhb5QXxNdG3IbcBNZloDRuXdvoDKoO9PcYisPV5QFvlN-7dTWjXgK_0e9VML27Bx7NYRKq1hgNz21eKvg5RRNFqEGlXWg7Ix_KAlQGcop4Mv4IcjTwrb-Iq-WaikGKEnXCkcg6leXiNPRbKNZnAxqhrhgnZhRhJPHa6dBH3ODejvhva_KRyOWGAkAZ2-S1IA6uK505FtLg1IuQ9fBkmW108BKUlEQ7RioCStVj9oZNRMoN_SQ3tJZ--oiPklJElWzjAZdZ0sBRBpxncOx9ZVyQP6DfsNWlaSHZgr_3uDaJ4kYsdQ0HKnrqKHlXIkcSgvMy5_6zcrJkn0P4Dy8HOry_DefO4D5qYE4mMBFdXAnaKnkWv0oR-xafHebKDk5hASgF63C_B11wms0ax2Z7aSKZlkThS6RBOafJ2fQfA_yLStAwkhkUlhiUtK8ysieOCynZKSXOSE-ctBP40csMKyV594oKc6t47AhClrbIo2Gqb6iBG7MuvzC_7S1OHwYGR-LOU6JW3IUVRQGCpJbzy25EL3_BfaidYOhmdMO3ssozin15qHAS3GcmLMhZD885sHqMzqZPiVWZoDY_s0-R34udab5hnvi7jOApTVl3Ckt_kWI8ikVdKxah9lfjJL2XBD4jt0rTMWUXjaTFGMVdoqK8zIVan49VQiTPwFg7JcAz0s9dyS-SHEYaieovAPUNLgBT1gi2mGcmjEYnv0UZ3jw_k5-ut6blxPjHLrKwtKzUCFdtONilyLdS-PoSNAw40K1tHGdjDN3TRdO1ReYYRr46UVrASdr1974reDnnWjXlz_WtMqRVFE-Y05XJBfXYVfafqrAEy9PglhmGFKSDcz2mpa99RYwE5d_N8GM1L8ZdBYGVetQ_0A-LkCjsP33o1-VHtv7-nybSGvezUk-CnXACAe0QNkkRsNqKccvVb4E8TJj4_H8V_Rvfkux4Ousc2ThdJzUvDgM3FqfdaZ491QFuMHdzyNTXFufeH9y-80YaIAd3C-9hEqy4-3XDrG0n0FbFTqcL9HSPB98wjlGCwCERJQ8BGTaEBbFiKyfQj0hi2Hg==",
                "dateChange": 2,
                "departureDate": "2024-09-14",
                "departureTime": "16:44",
                "destination": "BCN",
                "duration": "38h56m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SJC",
                    "cityCodeTo": "LAS",
                    "cityFrom": "San Jose",
                    "cityTo": "Las Vegas",
                    "combination_id": "076e243e4e0c00006b2e0782",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 222,
                    "flyFrom": "SJC",
                    "flyTo": "LAS",
                    "guarantee": false,
                    "id": "076e243e4e0c00006b2e0782_0",
                    "local_arrival": "2024-09-14T18:15:00.000Z",
                    "local_departure": "2024-09-14T16:44:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "222",
                    "return": 0,
                    "utc_arrival": "2024-09-15T01:15:00.000Z",
                    "utc_departure": "2024-09-14T23:44:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Los Angeles",
                    "combination_id": "076e243e4e0c00006b2e0782",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 397,
                    "flyFrom": "LAS",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "076e243e4e0c00006b2e0782_1",
                    "local_arrival": "2024-09-14T22:04:00.000Z",
                    "local_departure": "2024-09-14T20:43:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "397",
                    "return": 0,
                    "utc_arrival": "2024-09-15T05:04:00.000Z",
                    "utc_departure": "2024-09-15T03:43:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e0d00003fc34aec",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e0d00003fc34aec_0",
                    "local_arrival": "2024-09-16T16:40:00.000Z",
                    "local_departure": "2024-09-15T19:55:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "2624",
                    "return": 0,
                    "utc_arrival": "2024-09-16T14:40:00.000Z",
                    "utc_departure": "2024-09-16T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SJC",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=076e243e4e0c00006b2e0782_0%7C076e243e4e0c00006b2e0782_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SJC&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GXNp7WDkXCyUDbLrpmT4XlzN-MUmpFaUJE3fPP8fFOsPiLoLE1rqb7Nep1Ezf2wpNazL3NzWDNgcLveIsvg4GSvZ_aAmPLjQkMD_brHYKIyhb5QXxNdG3IbcBNZloDRuXdvoDKoO9PcYisPV5QFvlN-7dTWjXgK_0e9VML27Bx7NYRKq1hgNz21eKvg5RRNFqEGlXWg7Ix_KAlQGcop4Mv4IcjTwrb-Iq-WaikGKEnXCkcg6leXiNPRbKNZnAxqhrhgnZhRhJPHa6dBH3ODejvhva_KRyOWGAkAZ2-S1IA6uK505FtLg1IuQ9fBkmW108BKUlEQ7RioCStVj9oZNRMoN_SQ3tJZ--oiPklJElWzjAZdZ0sBRBpxncOx9ZVyQP6DfsNWlaSHZgr_3uDaJ4kYsdQ0HKnrqKHlXIkcSgvMy5_6zcrJkn0P4Dy8HOry_DefO4D5qYE4mMBFdXAnaKnkWv0oR-xafHebKDk5hASgF63C_B11wms0ax2Z7aSKZlkThS6RBOafJ2fQfA_yLStAwkhkUlhiUtK8ysieOCynZKSXOSE-ctBP40csMKyV594oKc6t47AhClrbIo2Gqb6iBG7MuvzC_7S1OHwYGR-LOU6JW3IUVRQGCpJbzy25EL3_BfaidYOhmdMO3ssozin15qHAS3GcmLMhZD885sHqMzqZPiVWZoDY_s0-R34udab5hnvi7jOApTVl3Ckt_kWI8ikVdKxah9lfjJL2XBD4jt0rTMWUXjaTFGMVdoqK8zIVan49VQiTPwFg7JcAz0s9dyS-SHEYaieovAPUNLgBT1gi2mGcmjEYnv0UZ3jw_k5-ut6blxPjHLrKwtKzUCFdtONilyLdS-PoSNAw40K1tHGdjDN3TRdO1ReYYRr46UVrASdr1974reDnnWjXlz_WtMqRVFE-Y05XJBfXYVfafqrAEy9PglhmGFKSDcz2mpa99RYwE5d_N8GM1L8ZdBYGVetQ_0A-LkCjsP33o1-VHtv7-nybSGvezUk-CnXACAe0QNkkRsNqKccvVb4E8TJj4_H8V_Rvfkux4Ousc2ThdJzUvDgM3FqfdaZ491QFuMHdzyNTXFufeH9y-80YaIAd3C-9hEqy4-3XDrG0n0FbFTqcL9HSPB98wjlGCwCERJQ8BGTaEBbFiKyfQj0hi2Hg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=076e243e4e0c00006b2e0782_0%7C076e243e4e0c00006b2e0782_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SJC&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GXNp7WDkXCyUDbLrpmT4XlzN-MUmpFaUJE3fPP8fFOsPiLoLE1rqb7Nep1Ezf2wpNazL3NzWDNgcLveIsvg4GSvZ_aAmPLjQkMD_brHYKIyhb5QXxNdG3IbcBNZloDRuXdvoDKoO9PcYisPV5QFvlN-7dTWjXgK_0e9VML27Bx7NYRKq1hgNz21eKvg5RRNFqEGlXWg7Ix_KAlQGcop4Mv4IcjTwrb-Iq-WaikGKEnXCkcg6leXiNPRbKNZnAxqhrhgnZhRhJPHa6dBH3ODejvhva_KRyOWGAkAZ2-S1IA6uK505FtLg1IuQ9fBkmW108BKUlEQ7RioCStVj9oZNRMoN_SQ3tJZ--oiPklJElWzjAZdZ0sBRBpxncOx9ZVyQP6DfsNWlaSHZgr_3uDaJ4kYsdQ0HKnrqKHlXIkcSgvMy5_6zcrJkn0P4Dy8HOry_DefO4D5qYE4mMBFdXAnaKnkWv0oR-xafHebKDk5hASgF63C_B11wms0ax2Z7aSKZlkThS6RBOafJ2fQfA_yLStAwkhkUlhiUtK8ysieOCynZKSXOSE-ctBP40csMKyV594oKc6t47AhClrbIo2Gqb6iBG7MuvzC_7S1OHwYGR-LOU6JW3IUVRQGCpJbzy25EL3_BfaidYOhmdMO3ssozin15qHAS3GcmLMhZD885sHqMzqZPiVWZoDY_s0-R34udab5hnvi7jOApTVl3Ckt_kWI8ikVdKxah9lfjJL2XBD4jt0rTMWUXjaTFGMVdoqK8zIVan49VQiTPwFg7JcAz0s9dyS-SHEYaieovAPUNLgBT1gi2mGcmjEYnv0UZ3jw_k5-ut6blxPjHLrKwtKzUCFdtONilyLdS-PoSNAw40K1tHGdjDN3TRdO1ReYYRr46UVrASdr1974reDnnWjXlz_WtMqRVFE-Y05XJBfXYVfafqrAEy9PglhmGFKSDcz2mpa99RYwE5d_N8GM1L8ZdBYGVetQ_0A-LkCjsP33o1-VHtv7-nybSGvezUk-CnXACAe0QNkkRsNqKccvVb4E8TJj4_H8V_Rvfkux4Ousc2ThdJzUvDgM3FqfdaZ491QFuMHdzyNTXFufeH9y-80YaIAd3C-9hEqy4-3XDrG0n0FbFTqcL9HSPB98wjlGCwCERJQ8BGTaEBbFiKyfQj0hi2Hg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1215.54,
            "totalPrice": 800
          },
          {
            "allPassengerTotalPrice": 804,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "NK",
                  "IB"
                ],
                "arrivalDate": "2024-09-16",
                "arrivalTime": "16:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gxhcn32H8prSdmxE2U9K83emIg4DeRREm4Ls2ajKC4RSAzN3aMr5pBhVOErOODHO5YScTkQDVV134rjFhcOv2OehFkrR6RVqVSQ0G55ubugO641lPJ9PvYTFGbgLV7nQ-gP5f6TgO7L-AIv9Hp2iQ_z1t-mYTnsUPLYp02gxICe9FIPJUl-iRA3eybgVIlHTNuGLXWtFHtQ-pka7smZRKnVbgvAZHKgHVNFhcE0rWJNxA54SCdrVPjTB-Ak9NofctaGIWtw01RX5sG37sYNLNNFAnPZUWdYdXcUECriAJEmOde6b5VR7RIB4FIoDpR8HFqjYzenjDD82TclM9zmNR_y6qShcEAs8v2_J3PjnLwfENH_gXC72NPkK2kIKe6pumigVIqa4X11nEjlTem6pW1BHFbqc8q2fkS6QLkOxrYifExKIkMy3DwNMIzlKr_kB7F0Qo4OuQCfGFM_8ojypmHQwPs3jsXGoUzoHJS9rou3vorYoOnB4A-fvRmJpioiWZNl1bNxLHRSP-fz2MWU70oLAVCg6BHuhnvFAANecSLeoWwnVFU5LVL4ci9yIJaPknHcnvVvvgk4WJMvpQkX9X8ucDQXci633xXq0ocHRr0pFi5dy5cqfIccy9c-4jFPD3pxK3ZDald_UkA071QWvJj3wpcICYf7tHn6F4LMW6C83ffO7KedFWFquCEm9Un0WTyuZWxtZdT0_YllvL49j6PC2ASyTKLoXKG17hYJHWp_fvDUF-Yvr-UzFCpMQdlZncantYsu17hR9GS5CY2f6bcnjoIy_1UU2wL2Qy6VE16PKLwROeGBNZXXuHNC8T3SIm6FII2TGRbK7aW15DFDIYlYrIj2gYPtKloZf86OGAeWirW7Aqep9xVxKZQLgf_saVsBoIOQHTynWEYjkYfGJoDO4ltx2D9W23ZOamRPJkwi66B848OGpf17kbDk3X47NdxIIAolLlBGyEk-BxUt0rq37jp3LsGSov9K8phTC55-Bk1kgTEDb15lnMQSTq0ydDx2EpbjusIfD-HS-wzRyySwwEq_s43iSWTz9gK31G2CkeejJxQ8X9-VDqh3TwJp26c3YC9uNmL272Pk_7Bw9wr6tb38iU1APxSwBjxKOdLX9D3kUMX5RisqSGweBEPfKdLcbRehB_Mru9OGnnn1oA3fXtiNYSf9PIcdRgG8t81ss=",
                "dateChange": 2,
                "departureDate": "2024-09-14",
                "departureTime": "19:25",
                "destination": "BCN",
                "duration": "36h15m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAS",
                    "cityFrom": "Oakland",
                    "cityTo": "Las Vegas",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2257,
                    "flyFrom": "OAK",
                    "flyTo": "LAS",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_0",
                    "local_arrival": "2024-09-14T20:59:00.000Z",
                    "local_departure": "2024-09-14T19:25:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2257",
                    "return": 0,
                    "utc_arrival": "2024-09-15T03:59:00.000Z",
                    "utc_departure": "2024-09-15T02:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2048,
                    "flyFrom": "LAS",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_1",
                    "local_arrival": "2024-09-14T23:06:00.000Z",
                    "local_departure": "2024-09-14T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2048",
                    "return": 0,
                    "utc_arrival": "2024-09-15T06:06:00.000Z",
                    "utc_departure": "2024-09-15T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e0d00003fc34aec",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e0d00003fc34aec_0",
                    "local_arrival": "2024-09-16T16:40:00.000Z",
                    "local_departure": "2024-09-15T19:55:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "2624",
                    "return": 0,
                    "utc_arrival": "2024-09-16T14:40:00.000Z",
                    "utc_departure": "2024-09-16T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gxhcn32H8prSdmxE2U9K83emIg4DeRREm4Ls2ajKC4RSAzN3aMr5pBhVOErOODHO5YScTkQDVV134rjFhcOv2OehFkrR6RVqVSQ0G55ubugO641lPJ9PvYTFGbgLV7nQ-gP5f6TgO7L-AIv9Hp2iQ_z1t-mYTnsUPLYp02gxICe9FIPJUl-iRA3eybgVIlHTNuGLXWtFHtQ-pka7smZRKnVbgvAZHKgHVNFhcE0rWJNxA54SCdrVPjTB-Ak9NofctaGIWtw01RX5sG37sYNLNNFAnPZUWdYdXcUECriAJEmOde6b5VR7RIB4FIoDpR8HFqjYzenjDD82TclM9zmNR_y6qShcEAs8v2_J3PjnLwfENH_gXC72NPkK2kIKe6pumigVIqa4X11nEjlTem6pW1BHFbqc8q2fkS6QLkOxrYifExKIkMy3DwNMIzlKr_kB7F0Qo4OuQCfGFM_8ojypmHQwPs3jsXGoUzoHJS9rou3vorYoOnB4A-fvRmJpioiWZNl1bNxLHRSP-fz2MWU70oLAVCg6BHuhnvFAANecSLeoWwnVFU5LVL4ci9yIJaPknHcnvVvvgk4WJMvpQkX9X8ucDQXci633xXq0ocHRr0pFi5dy5cqfIccy9c-4jFPD3pxK3ZDald_UkA071QWvJj3wpcICYf7tHn6F4LMW6C83ffO7KedFWFquCEm9Un0WTyuZWxtZdT0_YllvL49j6PC2ASyTKLoXKG17hYJHWp_fvDUF-Yvr-UzFCpMQdlZncantYsu17hR9GS5CY2f6bcnjoIy_1UU2wL2Qy6VE16PKLwROeGBNZXXuHNC8T3SIm6FII2TGRbK7aW15DFDIYlYrIj2gYPtKloZf86OGAeWirW7Aqep9xVxKZQLgf_saVsBoIOQHTynWEYjkYfGJoDO4ltx2D9W23ZOamRPJkwi66B848OGpf17kbDk3X47NdxIIAolLlBGyEk-BxUt0rq37jp3LsGSov9K8phTC55-Bk1kgTEDb15lnMQSTq0ydDx2EpbjusIfD-HS-wzRyySwwEq_s43iSWTz9gK31G2CkeejJxQ8X9-VDqh3TwJp26c3YC9uNmL272Pk_7Bw9wr6tb38iU1APxSwBjxKOdLX9D3kUMX5RisqSGweBEPfKdLcbRehB_Mru9OGnnn1oA3fXtiNYSf9PIcdRgG8t81ss=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gxhcn32H8prSdmxE2U9K83emIg4DeRREm4Ls2ajKC4RSAzN3aMr5pBhVOErOODHO5YScTkQDVV134rjFhcOv2OehFkrR6RVqVSQ0G55ubugO641lPJ9PvYTFGbgLV7nQ-gP5f6TgO7L-AIv9Hp2iQ_z1t-mYTnsUPLYp02gxICe9FIPJUl-iRA3eybgVIlHTNuGLXWtFHtQ-pka7smZRKnVbgvAZHKgHVNFhcE0rWJNxA54SCdrVPjTB-Ak9NofctaGIWtw01RX5sG37sYNLNNFAnPZUWdYdXcUECriAJEmOde6b5VR7RIB4FIoDpR8HFqjYzenjDD82TclM9zmNR_y6qShcEAs8v2_J3PjnLwfENH_gXC72NPkK2kIKe6pumigVIqa4X11nEjlTem6pW1BHFbqc8q2fkS6QLkOxrYifExKIkMy3DwNMIzlKr_kB7F0Qo4OuQCfGFM_8ojypmHQwPs3jsXGoUzoHJS9rou3vorYoOnB4A-fvRmJpioiWZNl1bNxLHRSP-fz2MWU70oLAVCg6BHuhnvFAANecSLeoWwnVFU5LVL4ci9yIJaPknHcnvVvvgk4WJMvpQkX9X8ucDQXci633xXq0ocHRr0pFi5dy5cqfIccy9c-4jFPD3pxK3ZDald_UkA071QWvJj3wpcICYf7tHn6F4LMW6C83ffO7KedFWFquCEm9Un0WTyuZWxtZdT0_YllvL49j6PC2ASyTKLoXKG17hYJHWp_fvDUF-Yvr-UzFCpMQdlZncantYsu17hR9GS5CY2f6bcnjoIy_1UU2wL2Qy6VE16PKLwROeGBNZXXuHNC8T3SIm6FII2TGRbK7aW15DFDIYlYrIj2gYPtKloZf86OGAeWirW7Aqep9xVxKZQLgf_saVsBoIOQHTynWEYjkYfGJoDO4ltx2D9W23ZOamRPJkwi66B848OGpf17kbDk3X47NdxIIAolLlBGyEk-BxUt0rq37jp3LsGSov9K8phTC55-Bk1kgTEDb15lnMQSTq0ydDx2EpbjusIfD-HS-wzRyySwwEq_s43iSWTz9gK31G2CkeejJxQ8X9-VDqh3TwJp26c3YC9uNmL272Pk_7Bw9wr6tb38iU1APxSwBjxKOdLX9D3kUMX5RisqSGweBEPfKdLcbRehB_Mru9OGnnn1oA3fXtiNYSf9PIcdRgG8t81ss=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1196.85,
            "totalPrice": 804
          },
          {
            "allPassengerTotalPrice": 808,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "NK",
                  "IB"
                ],
                "arrivalDate": "2024-09-16",
                "arrivalTime": "16:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywijTYezKOSZU-DUFLA5gGdN0xCXjTxw5s8h-DjqM0RYuge0hQc1ujKQYDtml3RdUq4QzjC3MngLZLw88UoFR0eQ99S0f9rF7ZHCyF_qalRIAahQeAXu6CECopbRJjQJKEVUuVlv8QGGcPIVULQbaiEX5646V8P7roG6-2xDS8jKCpbn1WPugexI6ijctMU-mJPVN-XXjA-aXofdYuy_gv2AZHR99lZbCLbYGBsRizUlePrugc0CLeNs2HTYu2Y9-BwRs_WcqjeV-_3-B8rplBTHZza8koNJLRpKriu8PNKx51FyvbERILd3IsIiqp5Ceqywr1klh2OPiAIAsPklklL2H-SOWK-WSimMNms8zm4HGGU7sKx9qv6BzHHK1hgPrQhillIiGr3Iyl73wbY2QCxXxk0Bp4kNzS9i3yVUjbVoWWdIQfOvx_sQ9O8GtVk31yGLR0K1fzO19FB51ix-esTm7ZxO-2m4MM3x7cLkKMhS4Cfb8wcdhYPY3iZZu3IANht7qqepCMMTlHsuzLb1lbvgsh0-hxdjhVl_hetRAg_8Gxv4LJvDx-Waw1IdVtq-MLwj5ruNPjefaIAlT98CV2qW-UK-HsHFeJak85P9sRIbT0lQd4wq9vStd0ys6UNIJfnG5PY3lAps17UvsodhX-ri_JdOdijJ9pmO7-Z9oofj2BAwpiX7YI69-GZ9IdJN4FLU8-RPvTFJy9GRXa2I5ifYWiawco3slUhg5zp-y9od5XcX4vYl4ZMllCbHKdm8qmggdmVZAaSMLK7Dcfh3DfGow5aDP8a9k4fJwG-zi2c8JbJyG9jkgWnUzb4_09Ba4OpFlpJDt4IoKlQA7OiUMIEnS0LGv035b16_TA9d_y3uv8INIlOAexgUImtKrHt9tWBFyE7JCs6TUqx-htom1KrXuztJiQI8WfzjnCRJInU2eBQHtu4PO3PGYUitFt_ZwxZe7ACH7EaEfH-W1mPwQmHng9Xjl72s6EpK7FBXU1O9nVtvSKaLWStbY0-bsuxTphP-PgM9H_NotHv3Vd59li-eqDhqdpVJIeglE5w1LmZyb7owdmZMLJrLuUTFFKqWVvfbJkTu7GwRoOtOjVTdVcGV8H0cYQ7McSJl77fsXLTgu-EPymVjmJ0xBEAt3yTmwkZTLkPwgMmJPsYKY62d2OIKFtOIo-8T5F9wynYsMhY=",
                "dateChange": 2,
                "departureDate": "2024-09-14",
                "departureTime": "19:25",
                "destination": "BCN",
                "duration": "36h15m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAS",
                    "cityFrom": "Oakland",
                    "cityTo": "Las Vegas",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2257,
                    "flyFrom": "OAK",
                    "flyTo": "LAS",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_0",
                    "local_arrival": "2024-09-14T20:59:00.000Z",
                    "local_departure": "2024-09-14T19:25:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2257",
                    "return": 0,
                    "utc_arrival": "2024-09-15T03:59:00.000Z",
                    "utc_departure": "2024-09-15T02:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2048,
                    "flyFrom": "LAS",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_1",
                    "local_arrival": "2024-09-14T23:06:00.000Z",
                    "local_departure": "2024-09-14T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2048",
                    "return": 0,
                    "utc_arrival": "2024-09-15T06:06:00.000Z",
                    "utc_departure": "2024-09-15T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e0d00003fc34aec",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e0d00003fc34aec_0",
                    "local_arrival": "2024-09-16T16:40:00.000Z",
                    "local_departure": "2024-09-15T19:55:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "2624",
                    "return": 0,
                    "utc_arrival": "2024-09-16T14:40:00.000Z",
                    "utc_departure": "2024-09-16T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywijTYezKOSZU-DUFLA5gGdN0xCXjTxw5s8h-DjqM0RYuge0hQc1ujKQYDtml3RdUq4QzjC3MngLZLw88UoFR0eQ99S0f9rF7ZHCyF_qalRIAahQeAXu6CECopbRJjQJKEVUuVlv8QGGcPIVULQbaiEX5646V8P7roG6-2xDS8jKCpbn1WPugexI6ijctMU-mJPVN-XXjA-aXofdYuy_gv2AZHR99lZbCLbYGBsRizUlePrugc0CLeNs2HTYu2Y9-BwRs_WcqjeV-_3-B8rplBTHZza8koNJLRpKriu8PNKx51FyvbERILd3IsIiqp5Ceqywr1klh2OPiAIAsPklklL2H-SOWK-WSimMNms8zm4HGGU7sKx9qv6BzHHK1hgPrQhillIiGr3Iyl73wbY2QCxXxk0Bp4kNzS9i3yVUjbVoWWdIQfOvx_sQ9O8GtVk31yGLR0K1fzO19FB51ix-esTm7ZxO-2m4MM3x7cLkKMhS4Cfb8wcdhYPY3iZZu3IANht7qqepCMMTlHsuzLb1lbvgsh0-hxdjhVl_hetRAg_8Gxv4LJvDx-Waw1IdVtq-MLwj5ruNPjefaIAlT98CV2qW-UK-HsHFeJak85P9sRIbT0lQd4wq9vStd0ys6UNIJfnG5PY3lAps17UvsodhX-ri_JdOdijJ9pmO7-Z9oofj2BAwpiX7YI69-GZ9IdJN4FLU8-RPvTFJy9GRXa2I5ifYWiawco3slUhg5zp-y9od5XcX4vYl4ZMllCbHKdm8qmggdmVZAaSMLK7Dcfh3DfGow5aDP8a9k4fJwG-zi2c8JbJyG9jkgWnUzb4_09Ba4OpFlpJDt4IoKlQA7OiUMIEnS0LGv035b16_TA9d_y3uv8INIlOAexgUImtKrHt9tWBFyE7JCs6TUqx-htom1KrXuztJiQI8WfzjnCRJInU2eBQHtu4PO3PGYUitFt_ZwxZe7ACH7EaEfH-W1mPwQmHng9Xjl72s6EpK7FBXU1O9nVtvSKaLWStbY0-bsuxTphP-PgM9H_NotHv3Vd59li-eqDhqdpVJIeglE5w1LmZyb7owdmZMLJrLuUTFFKqWVvfbJkTu7GwRoOtOjVTdVcGV8H0cYQ7McSJl77fsXLTgu-EPymVjmJ0xBEAt3yTmwkZTLkPwgMmJPsYKY62d2OIKFtOIo-8T5F9wynYsMhY=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywijTYezKOSZU-DUFLA5gGdN0xCXjTxw5s8h-DjqM0RYuge0hQc1ujKQYDtml3RdUq4QzjC3MngLZLw88UoFR0eQ99S0f9rF7ZHCyF_qalRIAahQeAXu6CECopbRJjQJKEVUuVlv8QGGcPIVULQbaiEX5646V8P7roG6-2xDS8jKCpbn1WPugexI6ijctMU-mJPVN-XXjA-aXofdYuy_gv2AZHR99lZbCLbYGBsRizUlePrugc0CLeNs2HTYu2Y9-BwRs_WcqjeV-_3-B8rplBTHZza8koNJLRpKriu8PNKx51FyvbERILd3IsIiqp5Ceqywr1klh2OPiAIAsPklklL2H-SOWK-WSimMNms8zm4HGGU7sKx9qv6BzHHK1hgPrQhillIiGr3Iyl73wbY2QCxXxk0Bp4kNzS9i3yVUjbVoWWdIQfOvx_sQ9O8GtVk31yGLR0K1fzO19FB51ix-esTm7ZxO-2m4MM3x7cLkKMhS4Cfb8wcdhYPY3iZZu3IANht7qqepCMMTlHsuzLb1lbvgsh0-hxdjhVl_hetRAg_8Gxv4LJvDx-Waw1IdVtq-MLwj5ruNPjefaIAlT98CV2qW-UK-HsHFeJak85P9sRIbT0lQd4wq9vStd0ys6UNIJfnG5PY3lAps17UvsodhX-ri_JdOdijJ9pmO7-Z9oofj2BAwpiX7YI69-GZ9IdJN4FLU8-RPvTFJy9GRXa2I5ifYWiawco3slUhg5zp-y9od5XcX4vYl4ZMllCbHKdm8qmggdmVZAaSMLK7Dcfh3DfGow5aDP8a9k4fJwG-zi2c8JbJyG9jkgWnUzb4_09Ba4OpFlpJDt4IoKlQA7OiUMIEnS0LGv035b16_TA9d_y3uv8INIlOAexgUImtKrHt9tWBFyE7JCs6TUqx-htom1KrXuztJiQI8WfzjnCRJInU2eBQHtu4PO3PGYUitFt_ZwxZe7ACH7EaEfH-W1mPwQmHng9Xjl72s6EpK7FBXU1O9nVtvSKaLWStbY0-bsuxTphP-PgM9H_NotHv3Vd59li-eqDhqdpVJIeglE5w1LmZyb7owdmZMLJrLuUTFFKqWVvfbJkTu7GwRoOtOjVTdVcGV8H0cYQ7McSJl77fsXLTgu-EPymVjmJ0xBEAt3yTmwkZTLkPwgMmJPsYKY62d2OIKFtOIo-8T5F9wynYsMhY=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1198.62,
            "totalPrice": 808
          },
          {
            "allPassengerTotalPrice": 808,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "NK",
                  "IB"
                ],
                "arrivalDate": "2024-09-16",
                "arrivalTime": "16:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GmtPE0nYUAx8WEUU-Lz6t13K1bjrp6r4IX9XuB-oSAc0tV6T19BEuJaqKfnL0RgzbiOXcbkdlAg-NDmPSK_lqmNEH0PD0_rfrL_CTvCIm55ysZPbBCStobyLlBeHyRb1wb-Mhl8HypNv0wQsd1HPWGq1o2IAYzbvtj7OX8NEZ-AktVtN5ZKnyHWdcee2lwiCYZ8xt9g9xTr4xGh2VnS9jsPwdHUN-3QX9qV9TFa6-F-CQhm2mrYX2zkypP1OU9rMjejZb73UQpTxR_h5KEVCbGBE1zgeVNC0ADdrNpkfkxOlcXLSOGDsFPL4q1pttarIyV0d9R2Cr9Rrpdfsg0Huc6Bq2sBMRHqYFFev7ADIXzXHGD4zE-bFA50ZNZRk3IiBFypGCF9mZBPyWLAW8-Ch1TQ4_RBUBXHqZ-DLf9wbQUgVahbmor0riYvQYBRWrZC9uAn2TPsH3P4xUH61L4ezsuwlzbcjt9Cx11oJuS6OOsM4T-1LFkxwYC-MQ3ILmMosaWT_VR3KXkfZQ0AmeoHzm5NfDy8tYkCK6AyCjaHZ0XPNjXQykOQ2ML2yL7NiAntBlf5fsxsTIVX19apQa4SHZ_SYW-9B0KATxNPmE__o4N2Y8JaBeEcWI33Pvl1BzO917xSSrAvcAhO3mlqP96sk0RW6SZ2lN8VyTVrSZNfpo6mC75rEbx_bvgInrCaykqZjokveeeABOVw_i5QfVarrUFVXqF0Kr3PZRPHs566vfgWyGmjqYRDoVuVjHPOlNtm_AYWv_kv-XpNWDAeCQPQmmMtOPkTvDzz_TDOkC9w4aV2EER1uaLqiY2iXR-YwLSdaO-tgtjmnnf9RFSee-jRX4CYk56GbYNo6skeKwAAFgFAyFy83YHi30tU8hLVsm1eSKI9Ym-tkpWcm2JrWnoAdnxUMCluP5XvMTOEGqCGD-DQnLoX6C8AsBKlLK6oVIgSVHBG8hFvh7vcKbW5OxR85JbWKZpybL9UTw0gQKvBAEfgH6p_claag34FBGRLtS8-gFJHMf3SHAMNw9r9tjayuRn81hAHSzBNeZ2q2y0qvMLVle5CHSW2JSTFaoQeUlOwjnmKViAZFHNKTXLUB8H-xZ22pP8oCyoz4UgLmPtltxcJ9_IvM5ELUhj4SruvlX-bsZ0IHYi5QYaw5HCWN_Yun3Jkj3d6YVF_tc1uChmNIKxH4sZSlMxg99sAZw5AfsOrFE",
                "dateChange": 2,
                "departureDate": "2024-09-14",
                "departureTime": "19:25",
                "destination": "BCN",
                "duration": "36h15m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAS",
                    "cityFrom": "Oakland",
                    "cityTo": "Las Vegas",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2257,
                    "flyFrom": "OAK",
                    "flyTo": "LAS",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_0",
                    "local_arrival": "2024-09-14T20:59:00.000Z",
                    "local_departure": "2024-09-14T19:25:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2257",
                    "return": 0,
                    "utc_arrival": "2024-09-15T03:59:00.000Z",
                    "utc_departure": "2024-09-15T02:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2048,
                    "flyFrom": "LAS",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_1",
                    "local_arrival": "2024-09-14T23:06:00.000Z",
                    "local_departure": "2024-09-14T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2048",
                    "return": 0,
                    "utc_arrival": "2024-09-15T06:06:00.000Z",
                    "utc_departure": "2024-09-15T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e0d00003fc34aec",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e0d00003fc34aec_0",
                    "local_arrival": "2024-09-16T16:40:00.000Z",
                    "local_departure": "2024-09-15T19:55:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "2624",
                    "return": 0,
                    "utc_arrival": "2024-09-16T14:40:00.000Z",
                    "utc_departure": "2024-09-16T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GmtPE0nYUAx8WEUU-Lz6t13K1bjrp6r4IX9XuB-oSAc0tV6T19BEuJaqKfnL0RgzbiOXcbkdlAg-NDmPSK_lqmNEH0PD0_rfrL_CTvCIm55ysZPbBCStobyLlBeHyRb1wb-Mhl8HypNv0wQsd1HPWGq1o2IAYzbvtj7OX8NEZ-AktVtN5ZKnyHWdcee2lwiCYZ8xt9g9xTr4xGh2VnS9jsPwdHUN-3QX9qV9TFa6-F-CQhm2mrYX2zkypP1OU9rMjejZb73UQpTxR_h5KEVCbGBE1zgeVNC0ADdrNpkfkxOlcXLSOGDsFPL4q1pttarIyV0d9R2Cr9Rrpdfsg0Huc6Bq2sBMRHqYFFev7ADIXzXHGD4zE-bFA50ZNZRk3IiBFypGCF9mZBPyWLAW8-Ch1TQ4_RBUBXHqZ-DLf9wbQUgVahbmor0riYvQYBRWrZC9uAn2TPsH3P4xUH61L4ezsuwlzbcjt9Cx11oJuS6OOsM4T-1LFkxwYC-MQ3ILmMosaWT_VR3KXkfZQ0AmeoHzm5NfDy8tYkCK6AyCjaHZ0XPNjXQykOQ2ML2yL7NiAntBlf5fsxsTIVX19apQa4SHZ_SYW-9B0KATxNPmE__o4N2Y8JaBeEcWI33Pvl1BzO917xSSrAvcAhO3mlqP96sk0RW6SZ2lN8VyTVrSZNfpo6mC75rEbx_bvgInrCaykqZjokveeeABOVw_i5QfVarrUFVXqF0Kr3PZRPHs566vfgWyGmjqYRDoVuVjHPOlNtm_AYWv_kv-XpNWDAeCQPQmmMtOPkTvDzz_TDOkC9w4aV2EER1uaLqiY2iXR-YwLSdaO-tgtjmnnf9RFSee-jRX4CYk56GbYNo6skeKwAAFgFAyFy83YHi30tU8hLVsm1eSKI9Ym-tkpWcm2JrWnoAdnxUMCluP5XvMTOEGqCGD-DQnLoX6C8AsBKlLK6oVIgSVHBG8hFvh7vcKbW5OxR85JbWKZpybL9UTw0gQKvBAEfgH6p_claag34FBGRLtS8-gFJHMf3SHAMNw9r9tjayuRn81hAHSzBNeZ2q2y0qvMLVle5CHSW2JSTFaoQeUlOwjnmKViAZFHNKTXLUB8H-xZ22pP8oCyoz4UgLmPtltxcJ9_IvM5ELUhj4SruvlX-bsZ0IHYi5QYaw5HCWN_Yun3Jkj3d6YVF_tc1uChmNIKxH4sZSlMxg99sAZw5AfsOrFE",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GmtPE0nYUAx8WEUU-Lz6t13K1bjrp6r4IX9XuB-oSAc0tV6T19BEuJaqKfnL0RgzbiOXcbkdlAg-NDmPSK_lqmNEH0PD0_rfrL_CTvCIm55ysZPbBCStobyLlBeHyRb1wb-Mhl8HypNv0wQsd1HPWGq1o2IAYzbvtj7OX8NEZ-AktVtN5ZKnyHWdcee2lwiCYZ8xt9g9xTr4xGh2VnS9jsPwdHUN-3QX9qV9TFa6-F-CQhm2mrYX2zkypP1OU9rMjejZb73UQpTxR_h5KEVCbGBE1zgeVNC0ADdrNpkfkxOlcXLSOGDsFPL4q1pttarIyV0d9R2Cr9Rrpdfsg0Huc6Bq2sBMRHqYFFev7ADIXzXHGD4zE-bFA50ZNZRk3IiBFypGCF9mZBPyWLAW8-Ch1TQ4_RBUBXHqZ-DLf9wbQUgVahbmor0riYvQYBRWrZC9uAn2TPsH3P4xUH61L4ezsuwlzbcjt9Cx11oJuS6OOsM4T-1LFkxwYC-MQ3ILmMosaWT_VR3KXkfZQ0AmeoHzm5NfDy8tYkCK6AyCjaHZ0XPNjXQykOQ2ML2yL7NiAntBlf5fsxsTIVX19apQa4SHZ_SYW-9B0KATxNPmE__o4N2Y8JaBeEcWI33Pvl1BzO917xSSrAvcAhO3mlqP96sk0RW6SZ2lN8VyTVrSZNfpo6mC75rEbx_bvgInrCaykqZjokveeeABOVw_i5QfVarrUFVXqF0Kr3PZRPHs566vfgWyGmjqYRDoVuVjHPOlNtm_AYWv_kv-XpNWDAeCQPQmmMtOPkTvDzz_TDOkC9w4aV2EER1uaLqiY2iXR-YwLSdaO-tgtjmnnf9RFSee-jRX4CYk56GbYNo6skeKwAAFgFAyFy83YHi30tU8hLVsm1eSKI9Ym-tkpWcm2JrWnoAdnxUMCluP5XvMTOEGqCGD-DQnLoX6C8AsBKlLK6oVIgSVHBG8hFvh7vcKbW5OxR85JbWKZpybL9UTw0gQKvBAEfgH6p_claag34FBGRLtS8-gFJHMf3SHAMNw9r9tjayuRn81hAHSzBNeZ2q2y0qvMLVle5CHSW2JSTFaoQeUlOwjnmKViAZFHNKTXLUB8H-xZ22pP8oCyoz4UgLmPtltxcJ9_IvM5ELUhj4SruvlX-bsZ0IHYi5QYaw5HCWN_Yun3Jkj3d6YVF_tc1uChmNIKxH4sZSlMxg99sAZw5AfsOrFE",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1199.29,
            "totalPrice": 808
          },
          {
            "allPassengerTotalPrice": 815,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "NK",
                  "IB"
                ],
                "arrivalDate": "2024-09-16",
                "arrivalTime": "16:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gbtz6o8Al4oUFEwk2-a7enoqyCqVhQ4hAfLY1_zBELMY5y00tHHi6vAzu3ho8pBrZu_ZVe5bkka3NW-hebg2Tq91G-IZTLnO87p0K7qqssWwrL3AY4qFxI73Jv_L8VQGupf-N15gXCFdA5p0GmfIInIWxnte5Jb4jcq9cLrA_VcbLUsZt63cdVZe_qw1Wa7Utt8jOvu9XaomuZ0GRmrK4BJrcr7eCpRL6VVX1mMICis58URqcgla3G26PFyxgufbNolU6TKbz9Oix2xiCsVSBRcX-IyC-4lOwRspuPN2SGZSR_wW5nxoPZ6-CBXyZ6PBDIn3Ji5ibv9_8BfE1mcCCEzuy27HRiPYiTp3lnbZmYEjdTFsXeVWMPvoqF8uE3u_fad50hDHIWR7oVUXUKk0VZMVN0TpV96l2zioVNdqLjfkkxp5lcKo0igvkt-Rk3KEi6uNyrsGlxk8K2_ugiPlkDF0Gn0nmBiFABHfwgjh9Bl3Tyic0Es0T5431gTi3DW3i98oh_ytE89bdDNCiGSzm4tyGSsiuDXJ0wV079jD78TUXX-4y8YWaQ_42O2CSdTSW4-OZqMq5OMgx-GY0NlZfG6e4RrVLvTaLABHCjGKfRCv8YqcjImb-LEiiXlwHKEA-WsX2P5Zsw2Vf2EGInKObM0Ui-eLFgCyiPCAh_AveDIuwehTDDsTkRE6wlJ574WjGwPec1MuAgkC9puvvYngvsOF1k6oVvxFZKc-n07MheJ8Y0TfuCDd0ex1_nrh6IP6RdzVXW_f8h26ZbaNHqvIpkE8Ba8ze9NO_ICXVucgkjapXZGg_8AKwK0G6QhDkM0varUcz0QdiyG8M2NLF9AqGjUfY90I60938hIPybC7aFvvW0sAHj5sa5p-9xeWgXf-FF7-iXNdd-ovBoNLe8yuRAkkisEoXcz-o8d-XOjZqwo6LAGHJhmA0z7OYNqf4OGK0D1s0U52rX3hRaj4yk4ybwK7PjmWVJAAx2fATGnGDwg0SacbNiSu1PX6GbIMxCZnlRYgr2ga_KXK_eVqJCMZwIRftNmcof1Z7KKTZYbOk6goz8PkrIEuUKEmlTOil-uBmAWoZ23uK60ypMMIuc03cuTCbUF-DnHf_nbSXthndLZgU6tMBs1vEmC-3ye6VVJTJCzXrU5YciOK1FMzdA2gtKtng8sjfTfF-BzZGt9jj7GI=",
                "dateChange": 2,
                "departureDate": "2024-09-14",
                "departureTime": "19:25",
                "destination": "BCN",
                "duration": "36h15m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAS",
                    "cityFrom": "Oakland",
                    "cityTo": "Las Vegas",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2257,
                    "flyFrom": "OAK",
                    "flyTo": "LAS",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_0",
                    "local_arrival": "2024-09-14T20:59:00.000Z",
                    "local_departure": "2024-09-14T19:25:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2257",
                    "return": 0,
                    "utc_arrival": "2024-09-15T03:59:00.000Z",
                    "utc_departure": "2024-09-15T02:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2048,
                    "flyFrom": "LAS",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_1",
                    "local_arrival": "2024-09-14T23:06:00.000Z",
                    "local_departure": "2024-09-14T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2048",
                    "return": 0,
                    "utc_arrival": "2024-09-15T06:06:00.000Z",
                    "utc_departure": "2024-09-15T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e0d00003fc34aec",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e0d00003fc34aec_0",
                    "local_arrival": "2024-09-16T16:40:00.000Z",
                    "local_departure": "2024-09-15T19:55:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "2624",
                    "return": 0,
                    "utc_arrival": "2024-09-16T14:40:00.000Z",
                    "utc_departure": "2024-09-16T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gbtz6o8Al4oUFEwk2-a7enoqyCqVhQ4hAfLY1_zBELMY5y00tHHi6vAzu3ho8pBrZu_ZVe5bkka3NW-hebg2Tq91G-IZTLnO87p0K7qqssWwrL3AY4qFxI73Jv_L8VQGupf-N15gXCFdA5p0GmfIInIWxnte5Jb4jcq9cLrA_VcbLUsZt63cdVZe_qw1Wa7Utt8jOvu9XaomuZ0GRmrK4BJrcr7eCpRL6VVX1mMICis58URqcgla3G26PFyxgufbNolU6TKbz9Oix2xiCsVSBRcX-IyC-4lOwRspuPN2SGZSR_wW5nxoPZ6-CBXyZ6PBDIn3Ji5ibv9_8BfE1mcCCEzuy27HRiPYiTp3lnbZmYEjdTFsXeVWMPvoqF8uE3u_fad50hDHIWR7oVUXUKk0VZMVN0TpV96l2zioVNdqLjfkkxp5lcKo0igvkt-Rk3KEi6uNyrsGlxk8K2_ugiPlkDF0Gn0nmBiFABHfwgjh9Bl3Tyic0Es0T5431gTi3DW3i98oh_ytE89bdDNCiGSzm4tyGSsiuDXJ0wV079jD78TUXX-4y8YWaQ_42O2CSdTSW4-OZqMq5OMgx-GY0NlZfG6e4RrVLvTaLABHCjGKfRCv8YqcjImb-LEiiXlwHKEA-WsX2P5Zsw2Vf2EGInKObM0Ui-eLFgCyiPCAh_AveDIuwehTDDsTkRE6wlJ574WjGwPec1MuAgkC9puvvYngvsOF1k6oVvxFZKc-n07MheJ8Y0TfuCDd0ex1_nrh6IP6RdzVXW_f8h26ZbaNHqvIpkE8Ba8ze9NO_ICXVucgkjapXZGg_8AKwK0G6QhDkM0varUcz0QdiyG8M2NLF9AqGjUfY90I60938hIPybC7aFvvW0sAHj5sa5p-9xeWgXf-FF7-iXNdd-ovBoNLe8yuRAkkisEoXcz-o8d-XOjZqwo6LAGHJhmA0z7OYNqf4OGK0D1s0U52rX3hRaj4yk4ybwK7PjmWVJAAx2fATGnGDwg0SacbNiSu1PX6GbIMxCZnlRYgr2ga_KXK_eVqJCMZwIRftNmcof1Z7KKTZYbOk6goz8PkrIEuUKEmlTOil-uBmAWoZ23uK60ypMMIuc03cuTCbUF-DnHf_nbSXthndLZgU6tMBs1vEmC-3ye6VVJTJCzXrU5YciOK1FMzdA2gtKtng8sjfTfF-BzZGt9jj7GI=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gbtz6o8Al4oUFEwk2-a7enoqyCqVhQ4hAfLY1_zBELMY5y00tHHi6vAzu3ho8pBrZu_ZVe5bkka3NW-hebg2Tq91G-IZTLnO87p0K7qqssWwrL3AY4qFxI73Jv_L8VQGupf-N15gXCFdA5p0GmfIInIWxnte5Jb4jcq9cLrA_VcbLUsZt63cdVZe_qw1Wa7Utt8jOvu9XaomuZ0GRmrK4BJrcr7eCpRL6VVX1mMICis58URqcgla3G26PFyxgufbNolU6TKbz9Oix2xiCsVSBRcX-IyC-4lOwRspuPN2SGZSR_wW5nxoPZ6-CBXyZ6PBDIn3Ji5ibv9_8BfE1mcCCEzuy27HRiPYiTp3lnbZmYEjdTFsXeVWMPvoqF8uE3u_fad50hDHIWR7oVUXUKk0VZMVN0TpV96l2zioVNdqLjfkkxp5lcKo0igvkt-Rk3KEi6uNyrsGlxk8K2_ugiPlkDF0Gn0nmBiFABHfwgjh9Bl3Tyic0Es0T5431gTi3DW3i98oh_ytE89bdDNCiGSzm4tyGSsiuDXJ0wV079jD78TUXX-4y8YWaQ_42O2CSdTSW4-OZqMq5OMgx-GY0NlZfG6e4RrVLvTaLABHCjGKfRCv8YqcjImb-LEiiXlwHKEA-WsX2P5Zsw2Vf2EGInKObM0Ui-eLFgCyiPCAh_AveDIuwehTDDsTkRE6wlJ574WjGwPec1MuAgkC9puvvYngvsOF1k6oVvxFZKc-n07MheJ8Y0TfuCDd0ex1_nrh6IP6RdzVXW_f8h26ZbaNHqvIpkE8Ba8ze9NO_ICXVucgkjapXZGg_8AKwK0G6QhDkM0varUcz0QdiyG8M2NLF9AqGjUfY90I60938hIPybC7aFvvW0sAHj5sa5p-9xeWgXf-FF7-iXNdd-ovBoNLe8yuRAkkisEoXcz-o8d-XOjZqwo6LAGHJhmA0z7OYNqf4OGK0D1s0U52rX3hRaj4yk4ybwK7PjmWVJAAx2fATGnGDwg0SacbNiSu1PX6GbIMxCZnlRYgr2ga_KXK_eVqJCMZwIRftNmcof1Z7KKTZYbOk6goz8PkrIEuUKEmlTOil-uBmAWoZ23uK60ypMMIuc03cuTCbUF-DnHf_nbSXthndLZgU6tMBs1vEmC-3ye6VVJTJCzXrU5YciOK1FMzdA2gtKtng8sjfTfF-BzZGt9jj7GI=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1204.98,
            "totalPrice": 815
          },
          {
            "allPassengerTotalPrice": 816,
            "legs": [
              {
                "airlines": [
                  "NK",
                  "NK",
                  "IB"
                ],
                "arrivalDate": "2024-09-16",
                "arrivalTime": "16:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4-l7ttYUHVfBpafEBXC7kmxDSt4e9cSmMGTk_6tCSUhW46HRyZ-DMpZ3TAuNv-yJoRFJe09ei880lxQsi06vs3FvTDQsIvC4OavTuTdx-Gd9KLDDN4rdNOE-c750JI5be0GfoHRwn3HnRqscgki4sQS6OoVC_NNIMcoH9w0zTFWABqt4EfezJzeDYBwY9rXBGSWjNHe-cl0aPW-HmN0AZB3ba0Y7oGfIfgmu2U5GX8FbFr3kPAhSfYEO878p9hJPBxR-wVUOowQGF0DcY6V7bLOK9p66XKJZJ0tabXqorn0Mojxsr8ytnyKXVnvYobFTZ6XWFgldJ4EqXVO_xIykdhFOIMMS7oM26I70KpqDmR-8U-NY4WddGhN3Gtp6XW-tplOcOQvQs8u7fnt4X-GSLOj6AEqo2SLS3jAoDzNztt6ZLvZ10b1Kr9_OYx-Z4OqK4V5gL1hZ7S1xtljFWsUL3LTWDO6U0ERp_tnSpviJXtgpQ48_lA5gbSCvOXu3U9V4R716fxq0MFlVRf4eddyxOhhX_Ck9uPxD-3SR4B3x9sfPh6W4HreWOK3y6gcrLzPOAnl9pVKBXvBRzVJTDRUvnnZAOUPFpapbK5We5qSVvUgFnkEK3kS04ZOKrhW7yqOyng6_JfZBXDdXQyklICsw2VdjywNnl09pH-ZavTo0c2W5aCHSJZ0cCFcsqEZyhx1k2E5yCnYDwty-Dycx5bu0l2d42BFcWKDh2A9cOPJpTvH0zBSZCPksr2Px4c-ooltKWfkaEmA5jcxj81j2ncejJ6x2TqkuppMKiulZsyidoBihi1nDUsz6Q881KK03vrI8tun_VNJd_okmgLvA_u_ZsiTi0rr1hVnD0hzRoB9Bi5M4YkgqQldklgsr7S8eo3OBmaPOFVnxPqVitjdij0AwxVWe6QrktHlLt4V2sKjqYn3xc81nJd2uhLG3ySmwXHjnXEnDAJvJbfF3jIP45-ZPLxHqvys3C20jBUJHGDZ_hXXYnW5vtTPHIjDYe-irgXNoTDxFAPirRz4x1iFgD1z0u6N2d9gix7HHjnqqePpyqVIUnefxu-rRDsy87_zqZAZiggXIvZAjk5r2M142xUwZzkCVmWl1wSE3WrOqxfVR8nfPdKgSkh8XudlyGLrtohF_-NjZfs_NCrq3mSIlPplCifFHnMcaNjZB7jUuGRB4elk=",
                "dateChange": 2,
                "departureDate": "2024-09-14",
                "departureTime": "19:25",
                "destination": "BCN",
                "duration": "36h15m",
                "routes": [
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "OAK",
                    "cityCodeTo": "LAS",
                    "cityFrom": "Oakland",
                    "cityTo": "Las Vegas",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2257,
                    "flyFrom": "OAK",
                    "flyTo": "LAS",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_0",
                    "local_arrival": "2024-09-14T20:59:00.000Z",
                    "local_departure": "2024-09-14T19:25:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2257",
                    "return": 0,
                    "utc_arrival": "2024-09-15T03:59:00.000Z",
                    "utc_departure": "2024-09-15T02:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Los Angeles",
                    "combination_id": "10a1243e4e0c000095ae8c1d",
                    "equipment": null,
                    "fare_basis": "WA3NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2048,
                    "flyFrom": "LAS",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "10a1243e4e0c000095ae8c1d_1",
                    "local_arrival": "2024-09-14T23:06:00.000Z",
                    "local_departure": "2024-09-14T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2048",
                    "return": 0,
                    "utc_arrival": "2024-09-15T06:06:00.000Z",
                    "utc_departure": "2024-09-15T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e0d00003fc34aec",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e0d00003fc34aec_0",
                    "local_arrival": "2024-09-16T16:40:00.000Z",
                    "local_departure": "2024-09-15T19:55:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "2624",
                    "return": 0,
                    "utc_arrival": "2024-09-16T14:40:00.000Z",
                    "utc_departure": "2024-09-16T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "OAK",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4-l7ttYUHVfBpafEBXC7kmxDSt4e9cSmMGTk_6tCSUhW46HRyZ-DMpZ3TAuNv-yJoRFJe09ei880lxQsi06vs3FvTDQsIvC4OavTuTdx-Gd9KLDDN4rdNOE-c750JI5be0GfoHRwn3HnRqscgki4sQS6OoVC_NNIMcoH9w0zTFWABqt4EfezJzeDYBwY9rXBGSWjNHe-cl0aPW-HmN0AZB3ba0Y7oGfIfgmu2U5GX8FbFr3kPAhSfYEO878p9hJPBxR-wVUOowQGF0DcY6V7bLOK9p66XKJZJ0tabXqorn0Mojxsr8ytnyKXVnvYobFTZ6XWFgldJ4EqXVO_xIykdhFOIMMS7oM26I70KpqDmR-8U-NY4WddGhN3Gtp6XW-tplOcOQvQs8u7fnt4X-GSLOj6AEqo2SLS3jAoDzNztt6ZLvZ10b1Kr9_OYx-Z4OqK4V5gL1hZ7S1xtljFWsUL3LTWDO6U0ERp_tnSpviJXtgpQ48_lA5gbSCvOXu3U9V4R716fxq0MFlVRf4eddyxOhhX_Ck9uPxD-3SR4B3x9sfPh6W4HreWOK3y6gcrLzPOAnl9pVKBXvBRzVJTDRUvnnZAOUPFpapbK5We5qSVvUgFnkEK3kS04ZOKrhW7yqOyng6_JfZBXDdXQyklICsw2VdjywNnl09pH-ZavTo0c2W5aCHSJZ0cCFcsqEZyhx1k2E5yCnYDwty-Dycx5bu0l2d42BFcWKDh2A9cOPJpTvH0zBSZCPksr2Px4c-ooltKWfkaEmA5jcxj81j2ncejJ6x2TqkuppMKiulZsyidoBihi1nDUsz6Q881KK03vrI8tun_VNJd_okmgLvA_u_ZsiTi0rr1hVnD0hzRoB9Bi5M4YkgqQldklgsr7S8eo3OBmaPOFVnxPqVitjdij0AwxVWe6QrktHlLt4V2sKjqYn3xc81nJd2uhLG3ySmwXHjnXEnDAJvJbfF3jIP45-ZPLxHqvys3C20jBUJHGDZ_hXXYnW5vtTPHIjDYe-irgXNoTDxFAPirRz4x1iFgD1z0u6N2d9gix7HHjnqqePpyqVIUnefxu-rRDsy87_zqZAZiggXIvZAjk5r2M142xUwZzkCVmWl1wSE3WrOqxfVR8nfPdKgSkh8XudlyGLrtohF_-NjZfs_NCrq3mSIlPplCifFHnMcaNjZB7jUuGRB4elk=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=10a1243e4e0c000095ae8c1d_0%7C10a1243e4e0c000095ae8c1d_1%7C243e01af4e0d00003fc34aec_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=OAK&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4-l7ttYUHVfBpafEBXC7kmxDSt4e9cSmMGTk_6tCSUhW46HRyZ-DMpZ3TAuNv-yJoRFJe09ei880lxQsi06vs3FvTDQsIvC4OavTuTdx-Gd9KLDDN4rdNOE-c750JI5be0GfoHRwn3HnRqscgki4sQS6OoVC_NNIMcoH9w0zTFWABqt4EfezJzeDYBwY9rXBGSWjNHe-cl0aPW-HmN0AZB3ba0Y7oGfIfgmu2U5GX8FbFr3kPAhSfYEO878p9hJPBxR-wVUOowQGF0DcY6V7bLOK9p66XKJZJ0tabXqorn0Mojxsr8ytnyKXVnvYobFTZ6XWFgldJ4EqXVO_xIykdhFOIMMS7oM26I70KpqDmR-8U-NY4WddGhN3Gtp6XW-tplOcOQvQs8u7fnt4X-GSLOj6AEqo2SLS3jAoDzNztt6ZLvZ10b1Kr9_OYx-Z4OqK4V5gL1hZ7S1xtljFWsUL3LTWDO6U0ERp_tnSpviJXtgpQ48_lA5gbSCvOXu3U9V4R716fxq0MFlVRf4eddyxOhhX_Ck9uPxD-3SR4B3x9sfPh6W4HreWOK3y6gcrLzPOAnl9pVKBXvBRzVJTDRUvnnZAOUPFpapbK5We5qSVvUgFnkEK3kS04ZOKrhW7yqOyng6_JfZBXDdXQyklICsw2VdjywNnl09pH-ZavTo0c2W5aCHSJZ0cCFcsqEZyhx1k2E5yCnYDwty-Dycx5bu0l2d42BFcWKDh2A9cOPJpTvH0zBSZCPksr2Px4c-ooltKWfkaEmA5jcxj81j2ncejJ6x2TqkuppMKiulZsyidoBihi1nDUsz6Q881KK03vrI8tun_VNJd_okmgLvA_u_ZsiTi0rr1hVnD0hzRoB9Bi5M4YkgqQldklgsr7S8eo3OBmaPOFVnxPqVitjdij0AwxVWe6QrktHlLt4V2sKjqYn3xc81nJd2uhLG3ySmwXHjnXEnDAJvJbfF3jIP45-ZPLxHqvys3C20jBUJHGDZ_hXXYnW5vtTPHIjDYe-irgXNoTDxFAPirRz4x1iFgD1z0u6N2d9gix7HHjnqqePpyqVIUnefxu-rRDsy87_zqZAZiggXIvZAjk5r2M142xUwZzkCVmWl1wSE3WrOqxfVR8nfPdKgSkh8XudlyGLrtohF_-NjZfs_NCrq3mSIlPplCifFHnMcaNjZB7jUuGRB4elk=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1206.29,
            "totalPrice": 816
          },
          {
            "allPassengerTotalPrice": 907,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5sgQyX2a0XFv44VTW6q2eLkdo14lwqofpeAONCPssc43xLQ41zuOnXLi9ySPdIBBW8DxX8rVfCnwV-9CYb_Sy4kNHs3clSFZ9JUKiNN8z09IMXDpWfkD-FWBnNQB9C8GNScziqLWPgB6DEvCvY6SBow2esrWyTFqRFeJprAFSooScmigKVSJPSzJSTlyzkHACpVGtXQmb6V3f9R0evaMlBgh6n1YFxct1YRdfhg_Hn5PapHDRW8HXenPOH_lGXO-szz_-AGV1d4PwMktR5XibCZIgRw3KmecpfUUEn7uC34Fl7GgYBLX8S7cotljUZ5lA4FWvfetq4sXk_pvLkGfV7UTWegzquN-0KXdjqHhS0GhOX1GoL3s1M6K4ApvqEKNSNFsvcxfNahY_tsIaEhaRIaz_TJBUp1k__rt8lQJ_VuqCC3Qaj5FFnsKkvrriu5xJEQ7z_puINTX5K2NPyv29fywBaTqUnSOoiju_sVNgygCOmSnFWt1VfD_HilsY-XQWgavhEDPnNyA50AhQp3uyyNQkLukYp0WAdS3_AWYk8N9FQJwzbJ4KIxqh8NmOcaDysD_lUe1guZZ4makkCzzsuwfhXzGVFQ6kszJAP5KRBW4CPHhxXIzV152lhPglmJxSLFt0O2ADpNjiPAKBnO7LoCK6Rj0I3em_IwND0-9mNfLua7iEe_c-F619qCMGaTZKpJ2FQNVCAy7ajZwpfCwqTncNkykVLEgdkMDzRMpa6ytFI9i4YDuuCbScEmYxPxsZSFoEr6w1KItyisY-2UF454NzwlsEBaagqLJnnBI4T1IRSFyLt1GGJ4f3kti7-H9P7ztjzT-u0Xsra5n6DIAWWTG_kpAKLPdww8kkKH9SASaPefWrRTWNCHOFZvwE_Zz2GiJRqHNtLgk5NvY3F22_Vy_Q_Zd18gxW8UMVNEQ30TiS3XcjkUCs6K1rSurlcCYXf8G-RlB89Fk37aRLQXoZTUpDFSHgGYbSzsEtZrZTgSoI7Vdz9bDqAyzJBR9cjX50pGjhr72xP2W-wvbIeucyJmTdj7XEGVEaZ5p4rAj6s-58w8lVE-TNyONztS9K7drv6F4oqPYgTZw13_PArYBbCYSL9Ode_Py4Fw3y-ezZ5YJaSCF7ZlUcP_jw6qW8qmslZlMqxSB3ZerMYye8bNQbg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5sgQyX2a0XFv44VTW6q2eLkdo14lwqofpeAONCPssc43xLQ41zuOnXLi9ySPdIBBW8DxX8rVfCnwV-9CYb_Sy4kNHs3clSFZ9JUKiNN8z09IMXDpWfkD-FWBnNQB9C8GNScziqLWPgB6DEvCvY6SBow2esrWyTFqRFeJprAFSooScmigKVSJPSzJSTlyzkHACpVGtXQmb6V3f9R0evaMlBgh6n1YFxct1YRdfhg_Hn5PapHDRW8HXenPOH_lGXO-szz_-AGV1d4PwMktR5XibCZIgRw3KmecpfUUEn7uC34Fl7GgYBLX8S7cotljUZ5lA4FWvfetq4sXk_pvLkGfV7UTWegzquN-0KXdjqHhS0GhOX1GoL3s1M6K4ApvqEKNSNFsvcxfNahY_tsIaEhaRIaz_TJBUp1k__rt8lQJ_VuqCC3Qaj5FFnsKkvrriu5xJEQ7z_puINTX5K2NPyv29fywBaTqUnSOoiju_sVNgygCOmSnFWt1VfD_HilsY-XQWgavhEDPnNyA50AhQp3uyyNQkLukYp0WAdS3_AWYk8N9FQJwzbJ4KIxqh8NmOcaDysD_lUe1guZZ4makkCzzsuwfhXzGVFQ6kszJAP5KRBW4CPHhxXIzV152lhPglmJxSLFt0O2ADpNjiPAKBnO7LoCK6Rj0I3em_IwND0-9mNfLua7iEe_c-F619qCMGaTZKpJ2FQNVCAy7ajZwpfCwqTncNkykVLEgdkMDzRMpa6ytFI9i4YDuuCbScEmYxPxsZSFoEr6w1KItyisY-2UF454NzwlsEBaagqLJnnBI4T1IRSFyLt1GGJ4f3kti7-H9P7ztjzT-u0Xsra5n6DIAWWTG_kpAKLPdww8kkKH9SASaPefWrRTWNCHOFZvwE_Zz2GiJRqHNtLgk5NvY3F22_Vy_Q_Zd18gxW8UMVNEQ30TiS3XcjkUCs6K1rSurlcCYXf8G-RlB89Fk37aRLQXoZTUpDFSHgGYbSzsEtZrZTgSoI7Vdz9bDqAyzJBR9cjX50pGjhr72xP2W-wvbIeucyJmTdj7XEGVEaZ5p4rAj6s-58w8lVE-TNyONztS9K7drv6F4oqPYgTZw13_PArYBbCYSL9Ode_Py4Fw3y-ezZ5YJaSCF7ZlUcP_jw6qW8qmslZlMqxSB3ZerMYye8bNQbg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5sgQyX2a0XFv44VTW6q2eLkdo14lwqofpeAONCPssc43xLQ41zuOnXLi9ySPdIBBW8DxX8rVfCnwV-9CYb_Sy4kNHs3clSFZ9JUKiNN8z09IMXDpWfkD-FWBnNQB9C8GNScziqLWPgB6DEvCvY6SBow2esrWyTFqRFeJprAFSooScmigKVSJPSzJSTlyzkHACpVGtXQmb6V3f9R0evaMlBgh6n1YFxct1YRdfhg_Hn5PapHDRW8HXenPOH_lGXO-szz_-AGV1d4PwMktR5XibCZIgRw3KmecpfUUEn7uC34Fl7GgYBLX8S7cotljUZ5lA4FWvfetq4sXk_pvLkGfV7UTWegzquN-0KXdjqHhS0GhOX1GoL3s1M6K4ApvqEKNSNFsvcxfNahY_tsIaEhaRIaz_TJBUp1k__rt8lQJ_VuqCC3Qaj5FFnsKkvrriu5xJEQ7z_puINTX5K2NPyv29fywBaTqUnSOoiju_sVNgygCOmSnFWt1VfD_HilsY-XQWgavhEDPnNyA50AhQp3uyyNQkLukYp0WAdS3_AWYk8N9FQJwzbJ4KIxqh8NmOcaDysD_lUe1guZZ4makkCzzsuwfhXzGVFQ6kszJAP5KRBW4CPHhxXIzV152lhPglmJxSLFt0O2ADpNjiPAKBnO7LoCK6Rj0I3em_IwND0-9mNfLua7iEe_c-F619qCMGaTZKpJ2FQNVCAy7ajZwpfCwqTncNkykVLEgdkMDzRMpa6ytFI9i4YDuuCbScEmYxPxsZSFoEr6w1KItyisY-2UF454NzwlsEBaagqLJnnBI4T1IRSFyLt1GGJ4f3kti7-H9P7ztjzT-u0Xsra5n6DIAWWTG_kpAKLPdww8kkKH9SASaPefWrRTWNCHOFZvwE_Zz2GiJRqHNtLgk5NvY3F22_Vy_Q_Zd18gxW8UMVNEQ30TiS3XcjkUCs6K1rSurlcCYXf8G-RlB89Fk37aRLQXoZTUpDFSHgGYbSzsEtZrZTgSoI7Vdz9bDqAyzJBR9cjX50pGjhr72xP2W-wvbIeucyJmTdj7XEGVEaZ5p4rAj6s-58w8lVE-TNyONztS9K7drv6F4oqPYgTZw13_PArYBbCYSL9Ode_Py4Fw3y-ezZ5YJaSCF7ZlUcP_jw6qW8qmslZlMqxSB3ZerMYye8bNQbg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1144.96,
            "totalPrice": 907
          },
          {
            "allPassengerTotalPrice": 911,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhReJCQX7BehHCkgwrjlQO95zcCDt2yniFEVnUDY0h6a8evssKuMUqojjijLHsyCP0X97_ElBXIcb57aguG9pBTFqAl4mBuNATPQclMe-kTDIqoK5jJxjxu4qQA1ZvLoJ9WMTafWB_rg5stmBzdQKb8S07TTSBD8vw3bYYS5IUcXBpUTyIdH30Ny9jVOKnvU0-Jc4KLnSSRmSe3HKyBy7YmikVE4d_suFLbKNV4CBpHnY6WrX3vKSdVKnVz9HvIkE2_EL-UkHagqEu9akBD8rpQCv9X9gFCvKRDwhSV7USgWsX3ColhYlw9a_Ew3hkyeT7eMTg5IaF2LsuVA3_pAtOClO0WO175pTmnaOqGTx3yD_YCWa1_OknxyjecX1dWg8X9InlbJg8vjxm4S1WgIzsgfeleAQG9VQdq-UIsfERULGa1UWnBaULNW9Ub0bpyS7fwAqAmqscym_zSfJ00wAQ2Tq_lRKAgvh6Eb4I81KiFjZg5zeYr1Q6un6GAdClhwNtZtY-1dCGjXhIDtgoFCEetBCJOaJ1SmRalYxrvKj0xJjvGtLAqqq6ax4yO_yZzwQphkfyN-kAkW8amZCVZfRUYB_ysrQexl2d9E3eyXzS9RYxpnu9vzufuKMnCGkKfFy6xzpDFlu615QRip92qyMhwxPedIo0lJINp_v-HvbMnPrpzwzr0CwaTSdtTgGC_PxNoreg0f3MBQBZAjOxgitpwZ9Ci7UDEXBY-omZjZNbY4gHLpgui5pEsx-TqAc_NbggsbwsWNPN2DPuUDn2Esriq7gZvP9C4YUQhdmuBmugMiBmlOB1guYsgj6zPoB9GJC9-utVar_9JVxpepVPO98zB7aPTPod3x_K7xvVETXJOS-igPoYpgymSxyUAHdE7ZDS35-Z9jUzaawB75x-aZYKbWmA519KeWWYbNF6atFAx9aI5LlABKpR8UtR0DZh68bOqXyXyK4wR8l-wzV_iSjgQztHt1y3mF8Lk3diZldrGt_55t7jf2Am0dUBQImDE0ZpB5KqxgDDv6nWsCAZdOc2sZhgzVGX3VrHgLefPX850RDlu2BB-lCvvGfmdAEUno65GjajbtLmtN6vAr7OE8QfAosAoJTfHTOJkLYxzVvEnaCikNutpHccdfTVSRET7nos3US8XmJKKc1ZpF4N2O-WYwDddFwixdf8DNtG76eOxw=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhReJCQX7BehHCkgwrjlQO95zcCDt2yniFEVnUDY0h6a8evssKuMUqojjijLHsyCP0X97_ElBXIcb57aguG9pBTFqAl4mBuNATPQclMe-kTDIqoK5jJxjxu4qQA1ZvLoJ9WMTafWB_rg5stmBzdQKb8S07TTSBD8vw3bYYS5IUcXBpUTyIdH30Ny9jVOKnvU0-Jc4KLnSSRmSe3HKyBy7YmikVE4d_suFLbKNV4CBpHnY6WrX3vKSdVKnVz9HvIkE2_EL-UkHagqEu9akBD8rpQCv9X9gFCvKRDwhSV7USgWsX3ColhYlw9a_Ew3hkyeT7eMTg5IaF2LsuVA3_pAtOClO0WO175pTmnaOqGTx3yD_YCWa1_OknxyjecX1dWg8X9InlbJg8vjxm4S1WgIzsgfeleAQG9VQdq-UIsfERULGa1UWnBaULNW9Ub0bpyS7fwAqAmqscym_zSfJ00wAQ2Tq_lRKAgvh6Eb4I81KiFjZg5zeYr1Q6un6GAdClhwNtZtY-1dCGjXhIDtgoFCEetBCJOaJ1SmRalYxrvKj0xJjvGtLAqqq6ax4yO_yZzwQphkfyN-kAkW8amZCVZfRUYB_ysrQexl2d9E3eyXzS9RYxpnu9vzufuKMnCGkKfFy6xzpDFlu615QRip92qyMhwxPedIo0lJINp_v-HvbMnPrpzwzr0CwaTSdtTgGC_PxNoreg0f3MBQBZAjOxgitpwZ9Ci7UDEXBY-omZjZNbY4gHLpgui5pEsx-TqAc_NbggsbwsWNPN2DPuUDn2Esriq7gZvP9C4YUQhdmuBmugMiBmlOB1guYsgj6zPoB9GJC9-utVar_9JVxpepVPO98zB7aPTPod3x_K7xvVETXJOS-igPoYpgymSxyUAHdE7ZDS35-Z9jUzaawB75x-aZYKbWmA519KeWWYbNF6atFAx9aI5LlABKpR8UtR0DZh68bOqXyXyK4wR8l-wzV_iSjgQztHt1y3mF8Lk3diZldrGt_55t7jf2Am0dUBQImDE0ZpB5KqxgDDv6nWsCAZdOc2sZhgzVGX3VrHgLefPX850RDlu2BB-lCvvGfmdAEUno65GjajbtLmtN6vAr7OE8QfAosAoJTfHTOJkLYxzVvEnaCikNutpHccdfTVSRET7nos3US8XmJKKc1ZpF4N2O-WYwDddFwixdf8DNtG76eOxw=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhReJCQX7BehHCkgwrjlQO95zcCDt2yniFEVnUDY0h6a8evssKuMUqojjijLHsyCP0X97_ElBXIcb57aguG9pBTFqAl4mBuNATPQclMe-kTDIqoK5jJxjxu4qQA1ZvLoJ9WMTafWB_rg5stmBzdQKb8S07TTSBD8vw3bYYS5IUcXBpUTyIdH30Ny9jVOKnvU0-Jc4KLnSSRmSe3HKyBy7YmikVE4d_suFLbKNV4CBpHnY6WrX3vKSdVKnVz9HvIkE2_EL-UkHagqEu9akBD8rpQCv9X9gFCvKRDwhSV7USgWsX3ColhYlw9a_Ew3hkyeT7eMTg5IaF2LsuVA3_pAtOClO0WO175pTmnaOqGTx3yD_YCWa1_OknxyjecX1dWg8X9InlbJg8vjxm4S1WgIzsgfeleAQG9VQdq-UIsfERULGa1UWnBaULNW9Ub0bpyS7fwAqAmqscym_zSfJ00wAQ2Tq_lRKAgvh6Eb4I81KiFjZg5zeYr1Q6un6GAdClhwNtZtY-1dCGjXhIDtgoFCEetBCJOaJ1SmRalYxrvKj0xJjvGtLAqqq6ax4yO_yZzwQphkfyN-kAkW8amZCVZfRUYB_ysrQexl2d9E3eyXzS9RYxpnu9vzufuKMnCGkKfFy6xzpDFlu615QRip92qyMhwxPedIo0lJINp_v-HvbMnPrpzwzr0CwaTSdtTgGC_PxNoreg0f3MBQBZAjOxgitpwZ9Ci7UDEXBY-omZjZNbY4gHLpgui5pEsx-TqAc_NbggsbwsWNPN2DPuUDn2Esriq7gZvP9C4YUQhdmuBmugMiBmlOB1guYsgj6zPoB9GJC9-utVar_9JVxpepVPO98zB7aPTPod3x_K7xvVETXJOS-igPoYpgymSxyUAHdE7ZDS35-Z9jUzaawB75x-aZYKbWmA519KeWWYbNF6atFAx9aI5LlABKpR8UtR0DZh68bOqXyXyK4wR8l-wzV_iSjgQztHt1y3mF8Lk3diZldrGt_55t7jf2Am0dUBQImDE0ZpB5KqxgDDv6nWsCAZdOc2sZhgzVGX3VrHgLefPX850RDlu2BB-lCvvGfmdAEUno65GjajbtLmtN6vAr7OE8QfAosAoJTfHTOJkLYxzVvEnaCikNutpHccdfTVSRET7nos3US8XmJKKc1ZpF4N2O-WYwDddFwixdf8DNtG76eOxw=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1146.74,
            "totalPrice": 911
          },
          {
            "allPassengerTotalPrice": 911,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsplLbyu1zgOgj7-EYYLWoG0egzbTIX27_VPNxgZYlsBng-Pm4DjywQ3rVUUDcfRf9DMhSMHjnRN0orWa6NZ2qEhG41i3RJl7-OpYyvxEDn3KqxGAd8dvF1oeFsTJ8SmZgo73MlFWvpN75NI1tTBpl8P0iuY57jKXxoEPE0n8Pf9cy3fGQs7_RdkxFt10o7ZZAosL1op0SCGp5SOV8hrhl7t_bqwfn0NcUDhFmZ-h81Kz9CG1w8R2gDR49Sr6shXJT7RjMkg-etYLiHwrnWBrghhb4wwmzZ6XHAoWNuX-HY2kyinYD3bF4UOZ4Ztf9s2nUWOwDlsBBQOdVOM9vGr1jKxWd6IsJWe_732I-l1LX-gL9ReVDKsv8zUrENtYQSOPpV-mmqFqx-sk64aleAnxBUuNSoON2KIcl28z-kGwTom60wj1-rN6epxXJBaWmLoOeEfjdbF8zBZAVnPqmXwprrbdLbj_Y__0bZRw9ttLCo7Vn80j1QLUus0cxvTMGbBwd0f0g8pX7Oh2fBw37Ci0JVZbj_L9SpTmL38WpX4yz4BXuGMRFzPvZpJs4hhbB61f5s2mKREZ-Ajt6iLAuz7hXOP9dJmgtVHnZqEUDHFUiTOj07zklFeR3KTCPObx2ugQWl04_HKJ1GC3pV0ii8iJukuGmh0BR-hmH6ZHIB8sSHrKxrOvt8Pc1q_5DRDSkR_JzvJ3MCjwIs1P7uREKn6BrJLhyW_08_feUG-sQhGcW1ceZmLApt-7vfbhahhRjG8_t6cCWzHTPGGTKxLabssmyThG0iKLXs3sepsUuizxeT4ktNnpuUlkXHYinXnjBojPR8ICF6zC6INfoSscr8u1-TTK0MgApl0OMPJxPbKkssvxMjfO8Edgo7Z6by8yzu0-w4eG1y2X26uXgHoJxiW2nc1cefNZzdV4eYuDVHMAevTVBtr_AyRqqIWQ2VlJduLZa6wA_QP-EI2bvjsh5IpQYaxq9HvcjtXJlggJqPIoXcflDk3ulNDrYATaYAveGMPTkUdekr17N4gfqf-ANvnTtVftzoB2B9j2xh-eUOTxfJWX4JKeUSslNs1MbeKd0daHQ59dE2HpGEZe5_KO4dD1vy9ZaVehdhJBQVnDw-TQFAZw3xyKQsoPlAt-nXiWycong6O9QoXrL06n9MQuswbJXMdkqxgAWUmBL3gbyvcxs-22hd7upxvJ2iByNGMb7CPJ",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsplLbyu1zgOgj7-EYYLWoG0egzbTIX27_VPNxgZYlsBng-Pm4DjywQ3rVUUDcfRf9DMhSMHjnRN0orWa6NZ2qEhG41i3RJl7-OpYyvxEDn3KqxGAd8dvF1oeFsTJ8SmZgo73MlFWvpN75NI1tTBpl8P0iuY57jKXxoEPE0n8Pf9cy3fGQs7_RdkxFt10o7ZZAosL1op0SCGp5SOV8hrhl7t_bqwfn0NcUDhFmZ-h81Kz9CG1w8R2gDR49Sr6shXJT7RjMkg-etYLiHwrnWBrghhb4wwmzZ6XHAoWNuX-HY2kyinYD3bF4UOZ4Ztf9s2nUWOwDlsBBQOdVOM9vGr1jKxWd6IsJWe_732I-l1LX-gL9ReVDKsv8zUrENtYQSOPpV-mmqFqx-sk64aleAnxBUuNSoON2KIcl28z-kGwTom60wj1-rN6epxXJBaWmLoOeEfjdbF8zBZAVnPqmXwprrbdLbj_Y__0bZRw9ttLCo7Vn80j1QLUus0cxvTMGbBwd0f0g8pX7Oh2fBw37Ci0JVZbj_L9SpTmL38WpX4yz4BXuGMRFzPvZpJs4hhbB61f5s2mKREZ-Ajt6iLAuz7hXOP9dJmgtVHnZqEUDHFUiTOj07zklFeR3KTCPObx2ugQWl04_HKJ1GC3pV0ii8iJukuGmh0BR-hmH6ZHIB8sSHrKxrOvt8Pc1q_5DRDSkR_JzvJ3MCjwIs1P7uREKn6BrJLhyW_08_feUG-sQhGcW1ceZmLApt-7vfbhahhRjG8_t6cCWzHTPGGTKxLabssmyThG0iKLXs3sepsUuizxeT4ktNnpuUlkXHYinXnjBojPR8ICF6zC6INfoSscr8u1-TTK0MgApl0OMPJxPbKkssvxMjfO8Edgo7Z6by8yzu0-w4eG1y2X26uXgHoJxiW2nc1cefNZzdV4eYuDVHMAevTVBtr_AyRqqIWQ2VlJduLZa6wA_QP-EI2bvjsh5IpQYaxq9HvcjtXJlggJqPIoXcflDk3ulNDrYATaYAveGMPTkUdekr17N4gfqf-ANvnTtVftzoB2B9j2xh-eUOTxfJWX4JKeUSslNs1MbeKd0daHQ59dE2HpGEZe5_KO4dD1vy9ZaVehdhJBQVnDw-TQFAZw3xyKQsoPlAt-nXiWycong6O9QoXrL06n9MQuswbJXMdkqxgAWUmBL3gbyvcxs-22hd7upxvJ2iByNGMb7CPJ",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsplLbyu1zgOgj7-EYYLWoG0egzbTIX27_VPNxgZYlsBng-Pm4DjywQ3rVUUDcfRf9DMhSMHjnRN0orWa6NZ2qEhG41i3RJl7-OpYyvxEDn3KqxGAd8dvF1oeFsTJ8SmZgo73MlFWvpN75NI1tTBpl8P0iuY57jKXxoEPE0n8Pf9cy3fGQs7_RdkxFt10o7ZZAosL1op0SCGp5SOV8hrhl7t_bqwfn0NcUDhFmZ-h81Kz9CG1w8R2gDR49Sr6shXJT7RjMkg-etYLiHwrnWBrghhb4wwmzZ6XHAoWNuX-HY2kyinYD3bF4UOZ4Ztf9s2nUWOwDlsBBQOdVOM9vGr1jKxWd6IsJWe_732I-l1LX-gL9ReVDKsv8zUrENtYQSOPpV-mmqFqx-sk64aleAnxBUuNSoON2KIcl28z-kGwTom60wj1-rN6epxXJBaWmLoOeEfjdbF8zBZAVnPqmXwprrbdLbj_Y__0bZRw9ttLCo7Vn80j1QLUus0cxvTMGbBwd0f0g8pX7Oh2fBw37Ci0JVZbj_L9SpTmL38WpX4yz4BXuGMRFzPvZpJs4hhbB61f5s2mKREZ-Ajt6iLAuz7hXOP9dJmgtVHnZqEUDHFUiTOj07zklFeR3KTCPObx2ugQWl04_HKJ1GC3pV0ii8iJukuGmh0BR-hmH6ZHIB8sSHrKxrOvt8Pc1q_5DRDSkR_JzvJ3MCjwIs1P7uREKn6BrJLhyW_08_feUG-sQhGcW1ceZmLApt-7vfbhahhRjG8_t6cCWzHTPGGTKxLabssmyThG0iKLXs3sepsUuizxeT4ktNnpuUlkXHYinXnjBojPR8ICF6zC6INfoSscr8u1-TTK0MgApl0OMPJxPbKkssvxMjfO8Edgo7Z6by8yzu0-w4eG1y2X26uXgHoJxiW2nc1cefNZzdV4eYuDVHMAevTVBtr_AyRqqIWQ2VlJduLZa6wA_QP-EI2bvjsh5IpQYaxq9HvcjtXJlggJqPIoXcflDk3ulNDrYATaYAveGMPTkUdekr17N4gfqf-ANvnTtVftzoB2B9j2xh-eUOTxfJWX4JKeUSslNs1MbeKd0daHQ59dE2HpGEZe5_KO4dD1vy9ZaVehdhJBQVnDw-TQFAZw3xyKQsoPlAt-nXiWycong6O9QoXrL06n9MQuswbJXMdkqxgAWUmBL3gbyvcxs-22hd7upxvJ2iByNGMb7CPJ",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1147.41,
            "totalPrice": 911
          },
          {
            "allPassengerTotalPrice": 915,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C141413a24e160000d28c9b38_0%7C13a20a224e170000ea3df829_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzYhAKoVgIj-S2XIucBBtPZP9eQGjr03CtLskE2vcWTGbQdEM3OXXq6IDbpzuhhHRganfZ-Ojd1gyW903Lt-porSh-CYuZl1LN8MyhcesMS7kbmgra-Fo5VRgTfmujTYt3rrt3q19y1ZMhHToEGr6gl5M0U661Ti01CUu1Wu1smRiMdGh5Qc6F6BnBRr9V_sUPa_kxl7DQGReiFN_pGcNIoCe8U2wAAF_z9NhoOE7GZNcF4LD7IGvdotr2-ba6Xe-FENj4eBuyF3dZoOkww6MKYAZYzi_RXpVkdVtvvAFsXbsvuLSMCGWzKX3zSEjdUDCvT1qWhavn22GuOcb7R83nG8EgEn25gtggRlacAn5lof6ZI8KPKu9E-JH6w_W-NPRdQwutORuAvGAUh9QLtTsF6dd9zfZqxUA5RW0XYIx8pfoDWerYRvceS3gq6_hVlTpvM3FT-yT9eglEgYeew2hXzeY2mt9_2eZDO39Q3zm7zFl3XLgsojwmkt72C1k5ZJHIUpGL7HtDiXyBX60ImmXNdaXiJOPNvKlsODSFXRWZsz--oxQ4acvZS1S9ijBhm_wxXH0YXhV-Ga9qeBk33vkXEXNNJzgd5C5UUPyKJDsfSNF4b7ri_G6WUVAnu3FkwxUWNZDnNzNrXoX2ijKg4JVvfQE4FEmNB8tnBLfk6qofpNABNyB-fartSJQTZYGSZlZCnSlLlRpIXvq0nk3EcoU8pe2qmpvt-El2AH-mXdsFbhG33sKJvyK_xxyrBk_Wv0i-CmQfmDxUSLnY1xh9d26gbyifEP0gWRkzKeV1bXP1kvlJBEnZDhs-wwzsNkY5wiFIDP5e4-I9K3neu0TwhcvhfvInNIkmpRINEW_8-FloAP6Y-VFOOr2rjta2AkEBY4Tyev1KZprAStnC5oyBPjDVh4Od-a0jjKmGA6fU3IMSCYcUoVsgJEi5hbwGKFUckxtCnrQdt4TxiJeF72RJ5QcpK1hd9fdB9uUn0k_7hUzXYpIoshb0rzJP5BDf5T6gXL444SQHNbMZssTAGZoFCRisS-v9wLUlRzA-kT7UtrRllkYDfXy_835T5sNtlNcwR_T8Xs_L1ed4NcageM-fv3T9M_x73NiurGEYL0WlDGX41hwnM0IyyYxW35OH9cJ4493jkZmK3z0udYDsL2sG7MtP1qbMtSqXZQ9ixa9hzIm7TTrV02he6sVM0zw9tKaLz74A2PRqkFb0VO8YCmXZY8WgaoTu7M8rdDroT83YqGL4jM=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-09-25",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C141413a24e160000d28c9b38_0%7C13a20a224e170000ea3df829_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzYhAKoVgIj-S2XIucBBtPZP9eQGjr03CtLskE2vcWTGbQdEM3OXXq6IDbpzuhhHRganfZ-Ojd1gyW903Lt-porSh-CYuZl1LN8MyhcesMS7kbmgra-Fo5VRgTfmujTYt3rrt3q19y1ZMhHToEGr6gl5M0U661Ti01CUu1Wu1smRiMdGh5Qc6F6BnBRr9V_sUPa_kxl7DQGReiFN_pGcNIoCe8U2wAAF_z9NhoOE7GZNcF4LD7IGvdotr2-ba6Xe-FENj4eBuyF3dZoOkww6MKYAZYzi_RXpVkdVtvvAFsXbsvuLSMCGWzKX3zSEjdUDCvT1qWhavn22GuOcb7R83nG8EgEn25gtggRlacAn5lof6ZI8KPKu9E-JH6w_W-NPRdQwutORuAvGAUh9QLtTsF6dd9zfZqxUA5RW0XYIx8pfoDWerYRvceS3gq6_hVlTpvM3FT-yT9eglEgYeew2hXzeY2mt9_2eZDO39Q3zm7zFl3XLgsojwmkt72C1k5ZJHIUpGL7HtDiXyBX60ImmXNdaXiJOPNvKlsODSFXRWZsz--oxQ4acvZS1S9ijBhm_wxXH0YXhV-Ga9qeBk33vkXEXNNJzgd5C5UUPyKJDsfSNF4b7ri_G6WUVAnu3FkwxUWNZDnNzNrXoX2ijKg4JVvfQE4FEmNB8tnBLfk6qofpNABNyB-fartSJQTZYGSZlZCnSlLlRpIXvq0nk3EcoU8pe2qmpvt-El2AH-mXdsFbhG33sKJvyK_xxyrBk_Wv0i-CmQfmDxUSLnY1xh9d26gbyifEP0gWRkzKeV1bXP1kvlJBEnZDhs-wwzsNkY5wiFIDP5e4-I9K3neu0TwhcvhfvInNIkmpRINEW_8-FloAP6Y-VFOOr2rjta2AkEBY4Tyev1KZprAStnC5oyBPjDVh4Od-a0jjKmGA6fU3IMSCYcUoVsgJEi5hbwGKFUckxtCnrQdt4TxiJeF72RJ5QcpK1hd9fdB9uUn0k_7hUzXYpIoshb0rzJP5BDf5T6gXL444SQHNbMZssTAGZoFCRisS-v9wLUlRzA-kT7UtrRllkYDfXy_835T5sNtlNcwR_T8Xs_L1ed4NcageM-fv3T9M_x73NiurGEYL0WlDGX41hwnM0IyyYxW35OH9cJ4493jkZmK3z0udYDsL2sG7MtP1qbMtSqXZQ9ixa9hzIm7TTrV02he6sVM0zw9tKaLz74A2PRqkFb0VO8YCmXZY8WgaoTu7M8rdDroT83YqGL4jM=",
                "dateChange": 1,
                "departureDate": "2024-09-24",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e160000d28c9b38",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e160000d28c9b38_0",
                    "local_arrival": "2024-09-24T23:35:00.000Z",
                    "local_departure": "2024-09-24T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-09-24T21:35:00.000Z",
                    "utc_departure": "2024-09-24T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e170000ea3df829",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e170000ea3df829_0",
                    "local_arrival": "2024-09-25T07:40:00.000Z",
                    "local_departure": "2024-09-25T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-09-25T05:40:00.000Z",
                    "utc_departure": "2024-09-25T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C141413a24e160000d28c9b38_0%7C13a20a224e170000ea3df829_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzYhAKoVgIj-S2XIucBBtPZP9eQGjr03CtLskE2vcWTGbQdEM3OXXq6IDbpzuhhHRganfZ-Ojd1gyW903Lt-porSh-CYuZl1LN8MyhcesMS7kbmgra-Fo5VRgTfmujTYt3rrt3q19y1ZMhHToEGr6gl5M0U661Ti01CUu1Wu1smRiMdGh5Qc6F6BnBRr9V_sUPa_kxl7DQGReiFN_pGcNIoCe8U2wAAF_z9NhoOE7GZNcF4LD7IGvdotr2-ba6Xe-FENj4eBuyF3dZoOkww6MKYAZYzi_RXpVkdVtvvAFsXbsvuLSMCGWzKX3zSEjdUDCvT1qWhavn22GuOcb7R83nG8EgEn25gtggRlacAn5lof6ZI8KPKu9E-JH6w_W-NPRdQwutORuAvGAUh9QLtTsF6dd9zfZqxUA5RW0XYIx8pfoDWerYRvceS3gq6_hVlTpvM3FT-yT9eglEgYeew2hXzeY2mt9_2eZDO39Q3zm7zFl3XLgsojwmkt72C1k5ZJHIUpGL7HtDiXyBX60ImmXNdaXiJOPNvKlsODSFXRWZsz--oxQ4acvZS1S9ijBhm_wxXH0YXhV-Ga9qeBk33vkXEXNNJzgd5C5UUPyKJDsfSNF4b7ri_G6WUVAnu3FkwxUWNZDnNzNrXoX2ijKg4JVvfQE4FEmNB8tnBLfk6qofpNABNyB-fartSJQTZYGSZlZCnSlLlRpIXvq0nk3EcoU8pe2qmpvt-El2AH-mXdsFbhG33sKJvyK_xxyrBk_Wv0i-CmQfmDxUSLnY1xh9d26gbyifEP0gWRkzKeV1bXP1kvlJBEnZDhs-wwzsNkY5wiFIDP5e4-I9K3neu0TwhcvhfvInNIkmpRINEW_8-FloAP6Y-VFOOr2rjta2AkEBY4Tyev1KZprAStnC5oyBPjDVh4Od-a0jjKmGA6fU3IMSCYcUoVsgJEi5hbwGKFUckxtCnrQdt4TxiJeF72RJ5QcpK1hd9fdB9uUn0k_7hUzXYpIoshb0rzJP5BDf5T6gXL444SQHNbMZssTAGZoFCRisS-v9wLUlRzA-kT7UtrRllkYDfXy_835T5sNtlNcwR_T8Xs_L1ed4NcageM-fv3T9M_x73NiurGEYL0WlDGX41hwnM0IyyYxW35OH9cJ4493jkZmK3z0udYDsL2sG7MtP1qbMtSqXZQ9ixa9hzIm7TTrV02he6sVM0zw9tKaLz74A2PRqkFb0VO8YCmXZY8WgaoTu7M8rdDroT83YqGL4jM=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1217.11,
            "totalPrice": 915
          },
          {
            "allPassengerTotalPrice": 918,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_4vCmV_E6etLVJ6XCo860kD_b-X8rDrS0D8ltSf0s4vqH0rX6LZHN6RmdjLaMV6-OA6J5aOEV2_ZzyRMtiPbYqzi6pDkZTJGimBt25TcaA9_LlfauWu90gRVVCvvvqx0GvMDfJ0puErif4JXxizVzAu0r51e1giSxFHAkiYf3ANr9NV7J2k156eN3tXOpTnRvdg-r5zLLg5wXIrr2B_3kduzs5XpjteX6apB5YJ8Pz1GPj3meVtM2hzPIcKDbcAbbH5VQ6E1jnOqaM307756gQtdHUkOSuEGqW99dNj0SreQBeVPdCKPydyhIo0x6V0-K5RLDd4OqIREDC0o0CGnr7vgHqhwv21cpjLHCDSAcOQgwHe1vhE09o5RqXZiioK9H9sEi6goDcANaZNwhpfgbMs29NQpo_gV3gPuoGr5to8mJJrMcTnDieWnEe54pTvjayj-dyi6O0A9tBaNqvei4KCMn73dIQrfTO06HY8Qh_LDtVxkoWkG72GePgBYa0gsSIAwQGOu3x9WCTK5-lM3vCho4DraYY_bFH-w4zIT_8uYxelFLZjU7TWJRWNYbclNak9EHzCs3jyF3X5SF47KqXShmxbryeqgSBHj-zYhIiZHlfeXYRE9-8smPFN_B_bP_ACf_gpXWWOWCM47eqZhZX4JddQ7FXXCVmf5G-heW9dLridhRdjdj4y3Z7tTqHy4fx_tZZEhabbFBnkbQKYrv5enYbyCwJkzpnI4zPWBDlbW8-uIbX2H_DHpx8KbHVwG8QZtynNErhPCYI32xLDm6GMD4wYg-VLC_GbcrWT1GTWnabcFRSx4ZK9RaRFmvhWOw6e2b_TUHWApOuAoEbLdV8_VQkkGEOOs5xIFb1_nGCP0VHBOJntB5M_IFowLXkTqa4TI4hfRLeLTSa2WbBDifTbOI43dU8FWmRKmm96vfqaMZ1sTHxa1w0eBCAvFea2wT45ANAjfABab8LUQoxKTjhGHWaAjn-5FR08428I0Z4gXwPs3lIvkIIEjD3gOL_Hp48LRM2MRdc1GWyQlBiayQRdqbomBrPyc_Wu8yajItr6ry9F2s8r8nRT7AZt7VylhVsc_5yOXFx25Emmh0Ea55pHyYuvTVrGfSYx_n17iDn0sOjO889UXdsq4-KYeqF-3NaN0XIr2gM8o11VkOw2aEQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_4vCmV_E6etLVJ6XCo860kD_b-X8rDrS0D8ltSf0s4vqH0rX6LZHN6RmdjLaMV6-OA6J5aOEV2_ZzyRMtiPbYqzi6pDkZTJGimBt25TcaA9_LlfauWu90gRVVCvvvqx0GvMDfJ0puErif4JXxizVzAu0r51e1giSxFHAkiYf3ANr9NV7J2k156eN3tXOpTnRvdg-r5zLLg5wXIrr2B_3kduzs5XpjteX6apB5YJ8Pz1GPj3meVtM2hzPIcKDbcAbbH5VQ6E1jnOqaM307756gQtdHUkOSuEGqW99dNj0SreQBeVPdCKPydyhIo0x6V0-K5RLDd4OqIREDC0o0CGnr7vgHqhwv21cpjLHCDSAcOQgwHe1vhE09o5RqXZiioK9H9sEi6goDcANaZNwhpfgbMs29NQpo_gV3gPuoGr5to8mJJrMcTnDieWnEe54pTvjayj-dyi6O0A9tBaNqvei4KCMn73dIQrfTO06HY8Qh_LDtVxkoWkG72GePgBYa0gsSIAwQGOu3x9WCTK5-lM3vCho4DraYY_bFH-w4zIT_8uYxelFLZjU7TWJRWNYbclNak9EHzCs3jyF3X5SF47KqXShmxbryeqgSBHj-zYhIiZHlfeXYRE9-8smPFN_B_bP_ACf_gpXWWOWCM47eqZhZX4JddQ7FXXCVmf5G-heW9dLridhRdjdj4y3Z7tTqHy4fx_tZZEhabbFBnkbQKYrv5enYbyCwJkzpnI4zPWBDlbW8-uIbX2H_DHpx8KbHVwG8QZtynNErhPCYI32xLDm6GMD4wYg-VLC_GbcrWT1GTWnabcFRSx4ZK9RaRFmvhWOw6e2b_TUHWApOuAoEbLdV8_VQkkGEOOs5xIFb1_nGCP0VHBOJntB5M_IFowLXkTqa4TI4hfRLeLTSa2WbBDifTbOI43dU8FWmRKmm96vfqaMZ1sTHxa1w0eBCAvFea2wT45ANAjfABab8LUQoxKTjhGHWaAjn-5FR08428I0Z4gXwPs3lIvkIIEjD3gOL_Hp48LRM2MRdc1GWyQlBiayQRdqbomBrPyc_Wu8yajItr6ry9F2s8r8nRT7AZt7VylhVsc_5yOXFx25Emmh0Ea55pHyYuvTVrGfSYx_n17iDn0sOjO889UXdsq4-KYeqF-3NaN0XIr2gM8o11VkOw2aEQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_4vCmV_E6etLVJ6XCo860kD_b-X8rDrS0D8ltSf0s4vqH0rX6LZHN6RmdjLaMV6-OA6J5aOEV2_ZzyRMtiPbYqzi6pDkZTJGimBt25TcaA9_LlfauWu90gRVVCvvvqx0GvMDfJ0puErif4JXxizVzAu0r51e1giSxFHAkiYf3ANr9NV7J2k156eN3tXOpTnRvdg-r5zLLg5wXIrr2B_3kduzs5XpjteX6apB5YJ8Pz1GPj3meVtM2hzPIcKDbcAbbH5VQ6E1jnOqaM307756gQtdHUkOSuEGqW99dNj0SreQBeVPdCKPydyhIo0x6V0-K5RLDd4OqIREDC0o0CGnr7vgHqhwv21cpjLHCDSAcOQgwHe1vhE09o5RqXZiioK9H9sEi6goDcANaZNwhpfgbMs29NQpo_gV3gPuoGr5to8mJJrMcTnDieWnEe54pTvjayj-dyi6O0A9tBaNqvei4KCMn73dIQrfTO06HY8Qh_LDtVxkoWkG72GePgBYa0gsSIAwQGOu3x9WCTK5-lM3vCho4DraYY_bFH-w4zIT_8uYxelFLZjU7TWJRWNYbclNak9EHzCs3jyF3X5SF47KqXShmxbryeqgSBHj-zYhIiZHlfeXYRE9-8smPFN_B_bP_ACf_gpXWWOWCM47eqZhZX4JddQ7FXXCVmf5G-heW9dLridhRdjdj4y3Z7tTqHy4fx_tZZEhabbFBnkbQKYrv5enYbyCwJkzpnI4zPWBDlbW8-uIbX2H_DHpx8KbHVwG8QZtynNErhPCYI32xLDm6GMD4wYg-VLC_GbcrWT1GTWnabcFRSx4ZK9RaRFmvhWOw6e2b_TUHWApOuAoEbLdV8_VQkkGEOOs5xIFb1_nGCP0VHBOJntB5M_IFowLXkTqa4TI4hfRLeLTSa2WbBDifTbOI43dU8FWmRKmm96vfqaMZ1sTHxa1w0eBCAvFea2wT45ANAjfABab8LUQoxKTjhGHWaAjn-5FR08428I0Z4gXwPs3lIvkIIEjD3gOL_Hp48LRM2MRdc1GWyQlBiayQRdqbomBrPyc_Wu8yajItr6ry9F2s8r8nRT7AZt7VylhVsc_5yOXFx25Emmh0Ea55pHyYuvTVrGfSYx_n17iDn0sOjO889UXdsq4-KYeqF-3NaN0XIr2gM8o11VkOw2aEQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1153.1,
            "totalPrice": 918
          },
          {
            "allPassengerTotalPrice": 920,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYV39Ixu63TozOJj9VXq_5KnUcvLElxI54l4qFFbndKbQdFZPDsxc0z8fB4YDRTnC_kqcnZQ7voMq4K_5Dkg63LQu1SExbw5e1H7op3iKqGxo6pze1ECDH-wjzc0gXd6vDaKllbBBSmo9QQHzyojvZQTf7BXR016FhGftMkpIab2JpiGV5R0bmPBFvH_Wx0MdUOvZkiPwDkCP15BRpdDhvyoeKEO13ZI9vBwKiedVEaecVg4z2ZvyyAVCBWfWMpRuF7Qc4Ekw1t1mIz0BnC-tBQxsYVm0RZR0xctgUR8kSewI_R1DmhkxFN7Uq3lJcm_LUdPD7z-_aWGW_gqppkcYr4vyK1zBXoNjkmfzL_oDwwE2LEyqCj2y9zySJZg4xxsRvwmoQj7vGLzu-nBARqTyMkBtwkh7_sCWAkWxzIw6OVtxv_IFrTLlGiFSFMJvHTkTVGu68eti_K_wl3QC7znZbo6av2g96giklHxNxl1vu5RIOy63iqu4-mtEKEphmX_8bPjFd-RW98zSTsP4YryuAML81sxwboig5PcjG8LIzhMqkDWRQvsmGEgLGKJR6z5I0ZD7lLRVF1lg5Azcnz2Xx-LZiwNPEWuDPdBkCqDFT_D1wnx4zfroaQPbbtzgJQiG9sFQ4IG-2qG6LkfOc9c5TiktMt-eZPwEx72kM2h0OliyBH--Jgb6QDmVGP0rLFoN7skAgCm7iL-KkwELH94fQ-LVKhZIRpyYLgBEquvnjhat1xxTFghaDDkHDUYPbuY1VCka7XWSrdarnsKJjSI1B0rB7-pG8DfqBO7kRzS0n9AJopoJotW1kHeO-dIEp8TmL6qD7QUhmP_oZoIY768zzBzkLx8BLiu5m0VHmyRYWW79R8iaWel3y_wNlSqCJYgD0yBOacm0n4be1P5rnWTryITRLJsPCE0aPwkuSP_XwLApk23rWy98bFsR_iGSK4-jgUE9OtBZLDyvomya3wxBobp1Jbprhqa3vaDgTms1Pgo1QQRNgDO09HchJS-_y5XevxCiyL6epr_hox-MGoBLo5RBhv5zILVfkMLBcXozgDsYWHztNbC1e6grNLtO6yDTqddzioO68OybcXjAmvTe6XnFXw4gV6fFvTJVfsy_WofwoNrSIclTtCAwvgqVacXh_9v7bhBwqSxkmZVsMUDCLT8MeWS3hxBFLTWC1R7_g6w=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYV39Ixu63TozOJj9VXq_5KnUcvLElxI54l4qFFbndKbQdFZPDsxc0z8fB4YDRTnC_kqcnZQ7voMq4K_5Dkg63LQu1SExbw5e1H7op3iKqGxo6pze1ECDH-wjzc0gXd6vDaKllbBBSmo9QQHzyojvZQTf7BXR016FhGftMkpIab2JpiGV5R0bmPBFvH_Wx0MdUOvZkiPwDkCP15BRpdDhvyoeKEO13ZI9vBwKiedVEaecVg4z2ZvyyAVCBWfWMpRuF7Qc4Ekw1t1mIz0BnC-tBQxsYVm0RZR0xctgUR8kSewI_R1DmhkxFN7Uq3lJcm_LUdPD7z-_aWGW_gqppkcYr4vyK1zBXoNjkmfzL_oDwwE2LEyqCj2y9zySJZg4xxsRvwmoQj7vGLzu-nBARqTyMkBtwkh7_sCWAkWxzIw6OVtxv_IFrTLlGiFSFMJvHTkTVGu68eti_K_wl3QC7znZbo6av2g96giklHxNxl1vu5RIOy63iqu4-mtEKEphmX_8bPjFd-RW98zSTsP4YryuAML81sxwboig5PcjG8LIzhMqkDWRQvsmGEgLGKJR6z5I0ZD7lLRVF1lg5Azcnz2Xx-LZiwNPEWuDPdBkCqDFT_D1wnx4zfroaQPbbtzgJQiG9sFQ4IG-2qG6LkfOc9c5TiktMt-eZPwEx72kM2h0OliyBH--Jgb6QDmVGP0rLFoN7skAgCm7iL-KkwELH94fQ-LVKhZIRpyYLgBEquvnjhat1xxTFghaDDkHDUYPbuY1VCka7XWSrdarnsKJjSI1B0rB7-pG8DfqBO7kRzS0n9AJopoJotW1kHeO-dIEp8TmL6qD7QUhmP_oZoIY768zzBzkLx8BLiu5m0VHmyRYWW79R8iaWel3y_wNlSqCJYgD0yBOacm0n4be1P5rnWTryITRLJsPCE0aPwkuSP_XwLApk23rWy98bFsR_iGSK4-jgUE9OtBZLDyvomya3wxBobp1Jbprhqa3vaDgTms1Pgo1QQRNgDO09HchJS-_y5XevxCiyL6epr_hox-MGoBLo5RBhv5zILVfkMLBcXozgDsYWHztNbC1e6grNLtO6yDTqddzioO68OybcXjAmvTe6XnFXw4gV6fFvTJVfsy_WofwoNrSIclTtCAwvgqVacXh_9v7bhBwqSxkmZVsMUDCLT8MeWS3hxBFLTWC1R7_g6w=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYV39Ixu63TozOJj9VXq_5KnUcvLElxI54l4qFFbndKbQdFZPDsxc0z8fB4YDRTnC_kqcnZQ7voMq4K_5Dkg63LQu1SExbw5e1H7op3iKqGxo6pze1ECDH-wjzc0gXd6vDaKllbBBSmo9QQHzyojvZQTf7BXR016FhGftMkpIab2JpiGV5R0bmPBFvH_Wx0MdUOvZkiPwDkCP15BRpdDhvyoeKEO13ZI9vBwKiedVEaecVg4z2ZvyyAVCBWfWMpRuF7Qc4Ekw1t1mIz0BnC-tBQxsYVm0RZR0xctgUR8kSewI_R1DmhkxFN7Uq3lJcm_LUdPD7z-_aWGW_gqppkcYr4vyK1zBXoNjkmfzL_oDwwE2LEyqCj2y9zySJZg4xxsRvwmoQj7vGLzu-nBARqTyMkBtwkh7_sCWAkWxzIw6OVtxv_IFrTLlGiFSFMJvHTkTVGu68eti_K_wl3QC7znZbo6av2g96giklHxNxl1vu5RIOy63iqu4-mtEKEphmX_8bPjFd-RW98zSTsP4YryuAML81sxwboig5PcjG8LIzhMqkDWRQvsmGEgLGKJR6z5I0ZD7lLRVF1lg5Azcnz2Xx-LZiwNPEWuDPdBkCqDFT_D1wnx4zfroaQPbbtzgJQiG9sFQ4IG-2qG6LkfOc9c5TiktMt-eZPwEx72kM2h0OliyBH--Jgb6QDmVGP0rLFoN7skAgCm7iL-KkwELH94fQ-LVKhZIRpyYLgBEquvnjhat1xxTFghaDDkHDUYPbuY1VCka7XWSrdarnsKJjSI1B0rB7-pG8DfqBO7kRzS0n9AJopoJotW1kHeO-dIEp8TmL6qD7QUhmP_oZoIY768zzBzkLx8BLiu5m0VHmyRYWW79R8iaWel3y_wNlSqCJYgD0yBOacm0n4be1P5rnWTryITRLJsPCE0aPwkuSP_XwLApk23rWy98bFsR_iGSK4-jgUE9OtBZLDyvomya3wxBobp1Jbprhqa3vaDgTms1Pgo1QQRNgDO09HchJS-_y5XevxCiyL6epr_hox-MGoBLo5RBhv5zILVfkMLBcXozgDsYWHztNbC1e6grNLtO6yDTqddzioO68OybcXjAmvTe6XnFXw4gV6fFvTJVfsy_WofwoNrSIclTtCAwvgqVacXh_9v7bhBwqSxkmZVsMUDCLT8MeWS3hxBFLTWC1R7_g6w=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1154.41,
            "totalPrice": 920
          },
          {
            "allPassengerTotalPrice": 936,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gl7E79pxZV-ThpRvMfDOigZnEEIO47r-XaS9dIKM4pfAxFQ2WqgthKLrSZRkHu1eSt9PCZx0wpBGERiUd1QvVBBVhJV4joLduCfM6W5uzZbMP8b4PMBNAK9dGdZD20wpMOkEXNaR7A_kX2vcVgQVlTKtyG09BU24352KaJrPFGNGSH4OlkspkUMpKM3KNKUP4w7Ixz0yNcmFjkFBdlvwbf1GSYAhyFex3a_VRyOgf2YD0_TSvCakx-8l2yrMg2UBEuIZ1kRZ5YcQzDNyZ_XUtQ1eSsPYnXAmk7G2H-Nb6FUjzFQWq0JYd3Avmh0GaUrb7oBPp2snIv_3dEUr-D6A61_kC2qbpoAT0p3QEZq6HnbL47kHCq_F60ZjiHONnqYS82HHihYOFUMOmQq2w22m8nrKaNfUZXmtXeeoOUCeBSvM1z1ay14bdxf_c4bFmLvSgcGtFfGw_ouese0n1OluGwYgQsaI900n3a-iINcVQLOltB7H57bU1gqQmasjz0zm6GqJGHif5eUvMBQELFJjD35JddgxAYesX77RXiOCV-Sm78zXVakllCawZif-wV6DmN4DkQIW4D5yjAVBWW-tcj7U9UDfYheVBDKOQJmuxc6ldJfj-jEiO6RTfPdOJe2sdTQJW3nth_kuFq_JUHxkNAo_3iyKK-yLwhXryCusHANZ_SRd5AF7lS3xk_-hsOTLBVKHq4Bma5YZBT3uRywTF-mga_iwiy-YxNxttdN98U3zpWOPJ4w7zVrDcA9YqPqAvCmFoxoBcnS5PuC529ASQrgv_5b3y6MRsA0dOc1G-fhO9j8C8lf9KCJXysFtH3IYYCROwEGU1ctjrRKbPvKL2CnN1Oih9wOgrVcmiFrmDNkddF2ALYsakYiqbvEzLB6-DfCGxstQCFWjUmXZQ8mUVwKS-XnmE-XwYkRZVW5a8b5lscI0yvMrvPMHPcceqK4YNVNfjDtSV8lR8D7I9dS2Fz9ZnL8-FcJjv8wsG6ga9phMK4P_MkkZnk6xZJRQn885OhDt4fSxAFaf4g0hPxLO7sdtBfTiDJCX3mvikPvR94ShnCZYtam_X5cFFtPrPlpvakkVESlELm0zyCcsY7IZ0x4gRmJp5_TtRaroPIdQXU-rjgqixknn1G8ucg_60dipIxEBhKM9k_fOdoNsglf-lZdnEUgiY5KFCtJJAjdyHQA4=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gl7E79pxZV-ThpRvMfDOigZnEEIO47r-XaS9dIKM4pfAxFQ2WqgthKLrSZRkHu1eSt9PCZx0wpBGERiUd1QvVBBVhJV4joLduCfM6W5uzZbMP8b4PMBNAK9dGdZD20wpMOkEXNaR7A_kX2vcVgQVlTKtyG09BU24352KaJrPFGNGSH4OlkspkUMpKM3KNKUP4w7Ixz0yNcmFjkFBdlvwbf1GSYAhyFex3a_VRyOgf2YD0_TSvCakx-8l2yrMg2UBEuIZ1kRZ5YcQzDNyZ_XUtQ1eSsPYnXAmk7G2H-Nb6FUjzFQWq0JYd3Avmh0GaUrb7oBPp2snIv_3dEUr-D6A61_kC2qbpoAT0p3QEZq6HnbL47kHCq_F60ZjiHONnqYS82HHihYOFUMOmQq2w22m8nrKaNfUZXmtXeeoOUCeBSvM1z1ay14bdxf_c4bFmLvSgcGtFfGw_ouese0n1OluGwYgQsaI900n3a-iINcVQLOltB7H57bU1gqQmasjz0zm6GqJGHif5eUvMBQELFJjD35JddgxAYesX77RXiOCV-Sm78zXVakllCawZif-wV6DmN4DkQIW4D5yjAVBWW-tcj7U9UDfYheVBDKOQJmuxc6ldJfj-jEiO6RTfPdOJe2sdTQJW3nth_kuFq_JUHxkNAo_3iyKK-yLwhXryCusHANZ_SRd5AF7lS3xk_-hsOTLBVKHq4Bma5YZBT3uRywTF-mga_iwiy-YxNxttdN98U3zpWOPJ4w7zVrDcA9YqPqAvCmFoxoBcnS5PuC529ASQrgv_5b3y6MRsA0dOc1G-fhO9j8C8lf9KCJXysFtH3IYYCROwEGU1ctjrRKbPvKL2CnN1Oih9wOgrVcmiFrmDNkddF2ALYsakYiqbvEzLB6-DfCGxstQCFWjUmXZQ8mUVwKS-XnmE-XwYkRZVW5a8b5lscI0yvMrvPMHPcceqK4YNVNfjDtSV8lR8D7I9dS2Fz9ZnL8-FcJjv8wsG6ga9phMK4P_MkkZnk6xZJRQn885OhDt4fSxAFaf4g0hPxLO7sdtBfTiDJCX3mvikPvR94ShnCZYtam_X5cFFtPrPlpvakkVESlELm0zyCcsY7IZ0x4gRmJp5_TtRaroPIdQXU-rjgqixknn1G8ucg_60dipIxEBhKM9k_fOdoNsglf-lZdnEUgiY5KFCtJJAjdyHQA4=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gl7E79pxZV-ThpRvMfDOigZnEEIO47r-XaS9dIKM4pfAxFQ2WqgthKLrSZRkHu1eSt9PCZx0wpBGERiUd1QvVBBVhJV4joLduCfM6W5uzZbMP8b4PMBNAK9dGdZD20wpMOkEXNaR7A_kX2vcVgQVlTKtyG09BU24352KaJrPFGNGSH4OlkspkUMpKM3KNKUP4w7Ixz0yNcmFjkFBdlvwbf1GSYAhyFex3a_VRyOgf2YD0_TSvCakx-8l2yrMg2UBEuIZ1kRZ5YcQzDNyZ_XUtQ1eSsPYnXAmk7G2H-Nb6FUjzFQWq0JYd3Avmh0GaUrb7oBPp2snIv_3dEUr-D6A61_kC2qbpoAT0p3QEZq6HnbL47kHCq_F60ZjiHONnqYS82HHihYOFUMOmQq2w22m8nrKaNfUZXmtXeeoOUCeBSvM1z1ay14bdxf_c4bFmLvSgcGtFfGw_ouese0n1OluGwYgQsaI900n3a-iINcVQLOltB7H57bU1gqQmasjz0zm6GqJGHif5eUvMBQELFJjD35JddgxAYesX77RXiOCV-Sm78zXVakllCawZif-wV6DmN4DkQIW4D5yjAVBWW-tcj7U9UDfYheVBDKOQJmuxc6ldJfj-jEiO6RTfPdOJe2sdTQJW3nth_kuFq_JUHxkNAo_3iyKK-yLwhXryCusHANZ_SRd5AF7lS3xk_-hsOTLBVKHq4Bma5YZBT3uRywTF-mga_iwiy-YxNxttdN98U3zpWOPJ4w7zVrDcA9YqPqAvCmFoxoBcnS5PuC529ASQrgv_5b3y6MRsA0dOc1G-fhO9j8C8lf9KCJXysFtH3IYYCROwEGU1ctjrRKbPvKL2CnN1Oih9wOgrVcmiFrmDNkddF2ALYsakYiqbvEzLB6-DfCGxstQCFWjUmXZQ8mUVwKS-XnmE-XwYkRZVW5a8b5lscI0yvMrvPMHPcceqK4YNVNfjDtSV8lR8D7I9dS2Fz9ZnL8-FcJjv8wsG6ga9phMK4P_MkkZnk6xZJRQn885OhDt4fSxAFaf4g0hPxLO7sdtBfTiDJCX3mvikPvR94ShnCZYtam_X5cFFtPrPlpvakkVESlELm0zyCcsY7IZ0x4gRmJp5_TtRaroPIdQXU-rjgqixknn1G8ucg_60dipIxEBhKM9k_fOdoNsglf-lZdnEUgiY5KFCtJJAjdyHQA4=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1166.74,
            "totalPrice": 936
          },
          {
            "allPassengerTotalPrice": 946,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ga-f9jkHwDquHDGNj7cyELnb468oiWehx7x6bvPm6SkMI3cyl88qXnMkG8wPvnuyJC4p1aLeUv7N52F6_RLl0gSpxD39ZEtgTHim6W5lVfrvyYPcljkfNm8sc77nmgfB9pa4ZZIdjfTrwKM2retdUxtOwUHCbUEymDbM_GjPeRu8cvuMgFQqWD-HPNzecVZ6TVMXHCfhgrkF4wNmzJiuewfyWEgRrXNbW9X6fYTfaLwCs4dUvWZvPe6L4MOVaVksDH-5Wku9MSDP8T5tCX8LNv2F0mekQ1-U7CrHV6DGdZv2qwTLixHBnrNH3BNeDl6D-HL3GSJy-XIlHjVyJKaQGR-RBCSSegDl2MKRu1STxUulO3vcW5GV0RVd6aSBE_ZV1FC40Fppgg2vY3ygC0T8-QY3UiXgM4Wt0kO4OP289YUFmqemN6fdx1E-_xoQyWQEgNLh5mfcWax7ubsxP1LyJGuE2Y_1ZyJbmZgS5Cj0fWpgOz7xQLk31ktIoyXxAGfVSJ9cX6kxIfKtfnwvWGoel3XpTjhUduh9YqvEhnkNgV41PZCkLWisREaz_OVNq0cetV4nGz4ShNUNv8VMGH4qVL4RrgGq92Uog-HIxVPJL1pekNJBKRalcf8LTGqxPdrh7boms2aV2HBCPKKKPsQsUKcOQ03mkCkyzH6ZSPxP_d82WbPHpLmY4WA6MuvaCG2QxHa0cL-EDTeCUg6_n4EHRUvg8cv7T1jBZ7aJ3ipwLHnlfu5RbPkxPm73dXis0W5w0TdYxVMwKuydmu5a3PTjTEgnJ2qUXfT-hVpMDX7bzXVlIgYtY2GguZ7D_6Yu_nil4f3Hn1zVV1wQnnKsyyAyrfCAr9viEiM3HjD_wywVdsbhEUfqIaI8_boINx5JZZQoWluPh36mjEEEyJmAvusTSnGj-xCr4h6w1QLd7UTien-Hvwg_DfcoBx_ut8WbXyTci9eVECAx7uh-1MFepthV_ivMhATpx84NbAHrJpVnTPJQfqV7j5WmXGOsHu0dVT_l4ZVrrLG6tLKQvM_DGj_bp8U-_WlzFRLP6MnQOJ9VIx8iWdSZ8REUAA-NeWga5qS1CdjFg9sxvieMcCoVC1-VI0CH0DEmfbOaV_U9wsF6aHO4FUiWmYSlSFkD_Ao202GveXkKOiJL35gaMWqOBeMral4RLEB5XA0f71eIzyHv5sWg=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ga-f9jkHwDquHDGNj7cyELnb468oiWehx7x6bvPm6SkMI3cyl88qXnMkG8wPvnuyJC4p1aLeUv7N52F6_RLl0gSpxD39ZEtgTHim6W5lVfrvyYPcljkfNm8sc77nmgfB9pa4ZZIdjfTrwKM2retdUxtOwUHCbUEymDbM_GjPeRu8cvuMgFQqWD-HPNzecVZ6TVMXHCfhgrkF4wNmzJiuewfyWEgRrXNbW9X6fYTfaLwCs4dUvWZvPe6L4MOVaVksDH-5Wku9MSDP8T5tCX8LNv2F0mekQ1-U7CrHV6DGdZv2qwTLixHBnrNH3BNeDl6D-HL3GSJy-XIlHjVyJKaQGR-RBCSSegDl2MKRu1STxUulO3vcW5GV0RVd6aSBE_ZV1FC40Fppgg2vY3ygC0T8-QY3UiXgM4Wt0kO4OP289YUFmqemN6fdx1E-_xoQyWQEgNLh5mfcWax7ubsxP1LyJGuE2Y_1ZyJbmZgS5Cj0fWpgOz7xQLk31ktIoyXxAGfVSJ9cX6kxIfKtfnwvWGoel3XpTjhUduh9YqvEhnkNgV41PZCkLWisREaz_OVNq0cetV4nGz4ShNUNv8VMGH4qVL4RrgGq92Uog-HIxVPJL1pekNJBKRalcf8LTGqxPdrh7boms2aV2HBCPKKKPsQsUKcOQ03mkCkyzH6ZSPxP_d82WbPHpLmY4WA6MuvaCG2QxHa0cL-EDTeCUg6_n4EHRUvg8cv7T1jBZ7aJ3ipwLHnlfu5RbPkxPm73dXis0W5w0TdYxVMwKuydmu5a3PTjTEgnJ2qUXfT-hVpMDX7bzXVlIgYtY2GguZ7D_6Yu_nil4f3Hn1zVV1wQnnKsyyAyrfCAr9viEiM3HjD_wywVdsbhEUfqIaI8_boINx5JZZQoWluPh36mjEEEyJmAvusTSnGj-xCr4h6w1QLd7UTien-Hvwg_DfcoBx_ut8WbXyTci9eVECAx7uh-1MFepthV_ivMhATpx84NbAHrJpVnTPJQfqV7j5WmXGOsHu0dVT_l4ZVrrLG6tLKQvM_DGj_bp8U-_WlzFRLP6MnQOJ9VIx8iWdSZ8REUAA-NeWga5qS1CdjFg9sxvieMcCoVC1-VI0CH0DEmfbOaV_U9wsF6aHO4FUiWmYSlSFkD_Ao202GveXkKOiJL35gaMWqOBeMral4RLEB5XA0f71eIzyHv5sWg=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ga-f9jkHwDquHDGNj7cyELnb468oiWehx7x6bvPm6SkMI3cyl88qXnMkG8wPvnuyJC4p1aLeUv7N52F6_RLl0gSpxD39ZEtgTHim6W5lVfrvyYPcljkfNm8sc77nmgfB9pa4ZZIdjfTrwKM2retdUxtOwUHCbUEymDbM_GjPeRu8cvuMgFQqWD-HPNzecVZ6TVMXHCfhgrkF4wNmzJiuewfyWEgRrXNbW9X6fYTfaLwCs4dUvWZvPe6L4MOVaVksDH-5Wku9MSDP8T5tCX8LNv2F0mekQ1-U7CrHV6DGdZv2qwTLixHBnrNH3BNeDl6D-HL3GSJy-XIlHjVyJKaQGR-RBCSSegDl2MKRu1STxUulO3vcW5GV0RVd6aSBE_ZV1FC40Fppgg2vY3ygC0T8-QY3UiXgM4Wt0kO4OP289YUFmqemN6fdx1E-_xoQyWQEgNLh5mfcWax7ubsxP1LyJGuE2Y_1ZyJbmZgS5Cj0fWpgOz7xQLk31ktIoyXxAGfVSJ9cX6kxIfKtfnwvWGoel3XpTjhUduh9YqvEhnkNgV41PZCkLWisREaz_OVNq0cetV4nGz4ShNUNv8VMGH4qVL4RrgGq92Uog-HIxVPJL1pekNJBKRalcf8LTGqxPdrh7boms2aV2HBCPKKKPsQsUKcOQ03mkCkyzH6ZSPxP_d82WbPHpLmY4WA6MuvaCG2QxHa0cL-EDTeCUg6_n4EHRUvg8cv7T1jBZ7aJ3ipwLHnlfu5RbPkxPm73dXis0W5w0TdYxVMwKuydmu5a3PTjTEgnJ2qUXfT-hVpMDX7bzXVlIgYtY2GguZ7D_6Yu_nil4f3Hn1zVV1wQnnKsyyAyrfCAr9viEiM3HjD_wywVdsbhEUfqIaI8_boINx5JZZQoWluPh36mjEEEyJmAvusTSnGj-xCr4h6w1QLd7UTien-Hvwg_DfcoBx_ut8WbXyTci9eVECAx7uh-1MFepthV_ivMhATpx84NbAHrJpVnTPJQfqV7j5WmXGOsHu0dVT_l4ZVrrLG6tLKQvM_DGj_bp8U-_WlzFRLP6MnQOJ9VIx8iWdSZ8REUAA-NeWga5qS1CdjFg9sxvieMcCoVC1-VI0CH0DEmfbOaV_U9wsF6aHO4FUiWmYSlSFkD_Ao202GveXkKOiJL35gaMWqOBeMral4RLEB5XA0f71eIzyHv5sWg=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1175.11,
            "totalPrice": 946
          },
          {
            "allPassengerTotalPrice": 951,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJQtExYmsVA1q6Al_T6jOjh4plzrRi5gYEuw6w0CFnltUNz06CHVKJJwsCc8e-qk_pXXC3ftNFOKAuaRESKA2DyQY4cQyPsitbylw7WpKXlD9DMnhgQY7dKC7S4NNKsyTbvuEQrM3Q6U8H1KzKH1dC1cTw6at7Xcs88VxMiDRab2Pmu15lDeUFlXaYvwmRIOWQ-wRMqPIf7eVbb2xoChFwMNyrm5CqPWGMUhPpI7ayxHGI6h2OAqZYY9pYNG2rqDpLzjNi9QLOuJ5d_LXiEGudjIqkv8kslA0LCzE3_VIO0cnoZBI_VXkpU327oEtUO_qkr6bSP3u6iANDVSfUb7e4GflAJEYsjUMDYZ3Pv2VUlrZq7M3PIBC3VWhd356k0T6kg-qVkt2oROZ0QdHfZVIE8kbSziVDBNOS4Dt-2hAF9LZl3yV2e430NuvcJhsibNhaDxcAcJ_glzAMXOAjovxPQz_p89s5Nvk-wE46ELlw_UPUvSG-H9j9ObVIKHW4lbojTbxGPYgKZEbAMQLGWYNG54fzSsz9epVIuEvpsy7RJGoG5p8HT0u-z0vlPkNGd3_BzrqOcQsj8bIUfo6cOj5ZJrcceN8dKmjBe0uOE7oB_SdCnPIMEPF8FLgnDP_rvV_ulnzEQ-tUmpN0-b17YKk3zRFUqkctCIFdWIZFKQiD2dzdbr6lzeYE5DuTyoHreqxVBHVqQrSgOagLES3IcwHiK4PklGbCM46N49BwGoYOZGpn2iKFfQbZ3CtcmhsgIuSKs8qX85AoukIHa_W-p30YlK_PNyzSxaGGAwK7yUDL5MJpBtj3HZNcYIvm1HYNE9711fc7WWRvaqr8oTqUnxR7eHfyBJXVUK3wN21qbCL1eux5wDrLRNjc4gdZLeySXFluVvAQX_3ng_vFgflGAezX3YJZvaxIbNe4DDevcdk2H-IbT9_iO_ZiT415G97HkEXziyX6pv9wBgB5qVgmAzALgcD14AQWeKqMDDoDHwVjvDW2Q07QdWGbVZgAnW3q1rvs27EM81PBjWfUp1J17sKqmvboUOlmqRDw_ULvj1HNvQU88Xfkavn3LZo-2XiFza0ZB0ifzNklmetWEstj-n_SKaKsNJ5w1KI0ieeO9KG84BLFY4zTuGHlwlr9IGED80yurmRb8KqYmjHudgn_azddKZTGkbUKiyrKiiEHRdUxgZwaz9mgvDMXWs0uF3VgC9pmurR1eEYooftmKTdPHpCOtxrRjfpuP_kn1re0Ey8PUW2Ldt8i_cS_VtT_F5EC1OjMQP_6yHJOdolF7OmAee-T_pRpowtuUyHv8HIL7FxD5jYSSuqrH3uCWNczFDdb5KF",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJQtExYmsVA1q6Al_T6jOjh4plzrRi5gYEuw6w0CFnltUNz06CHVKJJwsCc8e-qk_pXXC3ftNFOKAuaRESKA2DyQY4cQyPsitbylw7WpKXlD9DMnhgQY7dKC7S4NNKsyTbvuEQrM3Q6U8H1KzKH1dC1cTw6at7Xcs88VxMiDRab2Pmu15lDeUFlXaYvwmRIOWQ-wRMqPIf7eVbb2xoChFwMNyrm5CqPWGMUhPpI7ayxHGI6h2OAqZYY9pYNG2rqDpLzjNi9QLOuJ5d_LXiEGudjIqkv8kslA0LCzE3_VIO0cnoZBI_VXkpU327oEtUO_qkr6bSP3u6iANDVSfUb7e4GflAJEYsjUMDYZ3Pv2VUlrZq7M3PIBC3VWhd356k0T6kg-qVkt2oROZ0QdHfZVIE8kbSziVDBNOS4Dt-2hAF9LZl3yV2e430NuvcJhsibNhaDxcAcJ_glzAMXOAjovxPQz_p89s5Nvk-wE46ELlw_UPUvSG-H9j9ObVIKHW4lbojTbxGPYgKZEbAMQLGWYNG54fzSsz9epVIuEvpsy7RJGoG5p8HT0u-z0vlPkNGd3_BzrqOcQsj8bIUfo6cOj5ZJrcceN8dKmjBe0uOE7oB_SdCnPIMEPF8FLgnDP_rvV_ulnzEQ-tUmpN0-b17YKk3zRFUqkctCIFdWIZFKQiD2dzdbr6lzeYE5DuTyoHreqxVBHVqQrSgOagLES3IcwHiK4PklGbCM46N49BwGoYOZGpn2iKFfQbZ3CtcmhsgIuSKs8qX85AoukIHa_W-p30YlK_PNyzSxaGGAwK7yUDL5MJpBtj3HZNcYIvm1HYNE9711fc7WWRvaqr8oTqUnxR7eHfyBJXVUK3wN21qbCL1eux5wDrLRNjc4gdZLeySXFluVvAQX_3ng_vFgflGAezX3YJZvaxIbNe4DDevcdk2H-IbT9_iO_ZiT415G97HkEXziyX6pv9wBgB5qVgmAzALgcD14AQWeKqMDDoDHwVjvDW2Q07QdWGbVZgAnW3q1rvs27EM81PBjWfUp1J17sKqmvboUOlmqRDw_ULvj1HNvQU88Xfkavn3LZo-2XiFza0ZB0ifzNklmetWEstj-n_SKaKsNJ5w1KI0ieeO9KG84BLFY4zTuGHlwlr9IGED80yurmRb8KqYmjHudgn_azddKZTGkbUKiyrKiiEHRdUxgZwaz9mgvDMXWs0uF3VgC9pmurR1eEYooftmKTdPHpCOtxrRjfpuP_kn1re0Ey8PUW2Ldt8i_cS_VtT_F5EC1OjMQP_6yHJOdolF7OmAee-T_pRpowtuUyHv8HIL7FxD5jYSSuqrH3uCWNczFDdb5KF",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJQtExYmsVA1q6Al_T6jOjh4plzrRi5gYEuw6w0CFnltUNz06CHVKJJwsCc8e-qk_pXXC3ftNFOKAuaRESKA2DyQY4cQyPsitbylw7WpKXlD9DMnhgQY7dKC7S4NNKsyTbvuEQrM3Q6U8H1KzKH1dC1cTw6at7Xcs88VxMiDRab2Pmu15lDeUFlXaYvwmRIOWQ-wRMqPIf7eVbb2xoChFwMNyrm5CqPWGMUhPpI7ayxHGI6h2OAqZYY9pYNG2rqDpLzjNi9QLOuJ5d_LXiEGudjIqkv8kslA0LCzE3_VIO0cnoZBI_VXkpU327oEtUO_qkr6bSP3u6iANDVSfUb7e4GflAJEYsjUMDYZ3Pv2VUlrZq7M3PIBC3VWhd356k0T6kg-qVkt2oROZ0QdHfZVIE8kbSziVDBNOS4Dt-2hAF9LZl3yV2e430NuvcJhsibNhaDxcAcJ_glzAMXOAjovxPQz_p89s5Nvk-wE46ELlw_UPUvSG-H9j9ObVIKHW4lbojTbxGPYgKZEbAMQLGWYNG54fzSsz9epVIuEvpsy7RJGoG5p8HT0u-z0vlPkNGd3_BzrqOcQsj8bIUfo6cOj5ZJrcceN8dKmjBe0uOE7oB_SdCnPIMEPF8FLgnDP_rvV_ulnzEQ-tUmpN0-b17YKk3zRFUqkctCIFdWIZFKQiD2dzdbr6lzeYE5DuTyoHreqxVBHVqQrSgOagLES3IcwHiK4PklGbCM46N49BwGoYOZGpn2iKFfQbZ3CtcmhsgIuSKs8qX85AoukIHa_W-p30YlK_PNyzSxaGGAwK7yUDL5MJpBtj3HZNcYIvm1HYNE9711fc7WWRvaqr8oTqUnxR7eHfyBJXVUK3wN21qbCL1eux5wDrLRNjc4gdZLeySXFluVvAQX_3ng_vFgflGAezX3YJZvaxIbNe4DDevcdk2H-IbT9_iO_ZiT415G97HkEXziyX6pv9wBgB5qVgmAzALgcD14AQWeKqMDDoDHwVjvDW2Q07QdWGbVZgAnW3q1rvs27EM81PBjWfUp1J17sKqmvboUOlmqRDw_ULvj1HNvQU88Xfkavn3LZo-2XiFza0ZB0ifzNklmetWEstj-n_SKaKsNJ5w1KI0ieeO9KG84BLFY4zTuGHlwlr9IGED80yurmRb8KqYmjHudgn_azddKZTGkbUKiyrKiiEHRdUxgZwaz9mgvDMXWs0uF3VgC9pmurR1eEYooftmKTdPHpCOtxrRjfpuP_kn1re0Ey8PUW2Ldt8i_cS_VtT_F5EC1OjMQP_6yHJOdolF7OmAee-T_pRpowtuUyHv8HIL7FxD5jYSSuqrH3uCWNczFDdb5KF",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1191.81,
            "totalPrice": 951
          },
          {
            "allPassengerTotalPrice": 951,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gcv4_YKmxX1rnj7IeqrNvu6mICZ05Chyl686kTrUP-t52taDQs6Q4Y_ongiGxt89Ow0LTQLz0jJMhJxLRvdmgM0F9NqL6_mugMF0dhvTbo_WHeXQpDbu02fDFBseMCgd6rGuF6BhM6bZJMs6WkWiTOUSPGlbJLiU8HA_A4uq9gxvUeCD5TkAfHm9yYAv5tlXylMPNAGJKXUs1tVB8jw8fo2lCU2i3QGZWTpyRL94_XMxWYNbtUQmKey3l1GfDXpOF4RbyW_ldL_KQTJq68RNn2o6GOc2rkg4RFAkRsFywx4OoPgzao7r2Ss-IRDlgEDwqlFSRQ8kpy9rhiFE3v7e6vz7aiK6UNyBisKQxonM2fgEEa4UuL7TDnTURQjbq4d7KnUhF9DdoFxx6CdpPTylDkwvQusr4Jixp6fOtHBjn5gAeS6HO_8ktZ69ELRQxWRrm9DweiP2R1h1zPKNGHP7UwATo0Dc6YMrNddyg_pA1l9W7LQdykdSvEjXT1U7CXZ_FztGDgeWjorXygcxH7_m3oV6L_wQ2aoSMvMAnfmO15TL9vkEYA04ylXA3OaBrsoaEs_APh4QjqUPd6PDvGihXanWVafHKIiH9iAVcv--QfvjmuRo_MkAbA_SHU3sbIc0nENY-EClPpA-GfNtweXQcN5xRv5an3utVA_rmuSCzZX2D9_9udWCRPLdWLCQxal1QPfFm5W5oCJpPPDrreY3WBbHvJjae8MJCP6tjwEIzydj7Ul67sNkZPh58HBthIyxIS7238hVyr_kCv5Km7PPwL0eYLLXq0sPICk498a3B0FMF9F9qKzgdVLw6JleknL-KD1ifC6qeFqBAxhILwfsAE0Mh8NUO0wp1ZWanxZ9d6jHamfBVdeMMBkpnAQCwum0QRjinK5UwoAgYoroQrQVTVEFyY41DUlJCMLVh5eHIv74TcRPvlwGgcOOZn53RmDX3eYqtrdzyOxIUM1nkvoGQ30Oe6ImdZh0EhHpNEde2oT27v5xaINsY1WfRmBspwKGq7KrPvZw_X79pOQ7wZZdOJLn3h6HFDAmz5t1fFoITLYl-RGLN3B8AjcYKrTvAzqkWHhAhB3bqVfpHLHf_Y7dMkgaGDhn3_DBazf9UC2gIZhy7m6PdhMqXefaCsph3e9ZNtg0DY3XzssNKZaIDSiJ_93e_IcO4TKOkkC954FFfCIpPRPqoRtDDAePT3nG_paaGuZImvT1J9EgFuVgiEK_JE2h6182mVuN5JVzE0Zfz1_JV9GZA_xGhpbnpT2EWLL_J2ICqCNn_OI-SRXqEa8sKsKa9FB_YQjeti-eqz_9phQA=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gcv4_YKmxX1rnj7IeqrNvu6mICZ05Chyl686kTrUP-t52taDQs6Q4Y_ongiGxt89Ow0LTQLz0jJMhJxLRvdmgM0F9NqL6_mugMF0dhvTbo_WHeXQpDbu02fDFBseMCgd6rGuF6BhM6bZJMs6WkWiTOUSPGlbJLiU8HA_A4uq9gxvUeCD5TkAfHm9yYAv5tlXylMPNAGJKXUs1tVB8jw8fo2lCU2i3QGZWTpyRL94_XMxWYNbtUQmKey3l1GfDXpOF4RbyW_ldL_KQTJq68RNn2o6GOc2rkg4RFAkRsFywx4OoPgzao7r2Ss-IRDlgEDwqlFSRQ8kpy9rhiFE3v7e6vz7aiK6UNyBisKQxonM2fgEEa4UuL7TDnTURQjbq4d7KnUhF9DdoFxx6CdpPTylDkwvQusr4Jixp6fOtHBjn5gAeS6HO_8ktZ69ELRQxWRrm9DweiP2R1h1zPKNGHP7UwATo0Dc6YMrNddyg_pA1l9W7LQdykdSvEjXT1U7CXZ_FztGDgeWjorXygcxH7_m3oV6L_wQ2aoSMvMAnfmO15TL9vkEYA04ylXA3OaBrsoaEs_APh4QjqUPd6PDvGihXanWVafHKIiH9iAVcv--QfvjmuRo_MkAbA_SHU3sbIc0nENY-EClPpA-GfNtweXQcN5xRv5an3utVA_rmuSCzZX2D9_9udWCRPLdWLCQxal1QPfFm5W5oCJpPPDrreY3WBbHvJjae8MJCP6tjwEIzydj7Ul67sNkZPh58HBthIyxIS7238hVyr_kCv5Km7PPwL0eYLLXq0sPICk498a3B0FMF9F9qKzgdVLw6JleknL-KD1ifC6qeFqBAxhILwfsAE0Mh8NUO0wp1ZWanxZ9d6jHamfBVdeMMBkpnAQCwum0QRjinK5UwoAgYoroQrQVTVEFyY41DUlJCMLVh5eHIv74TcRPvlwGgcOOZn53RmDX3eYqtrdzyOxIUM1nkvoGQ30Oe6ImdZh0EhHpNEde2oT27v5xaINsY1WfRmBspwKGq7KrPvZw_X79pOQ7wZZdOJLn3h6HFDAmz5t1fFoITLYl-RGLN3B8AjcYKrTvAzqkWHhAhB3bqVfpHLHf_Y7dMkgaGDhn3_DBazf9UC2gIZhy7m6PdhMqXefaCsph3e9ZNtg0DY3XzssNKZaIDSiJ_93e_IcO4TKOkkC954FFfCIpPRPqoRtDDAePT3nG_paaGuZImvT1J9EgFuVgiEK_JE2h6182mVuN5JVzE0Zfz1_JV9GZA_xGhpbnpT2EWLL_J2ICqCNn_OI-SRXqEa8sKsKa9FB_YQjeti-eqz_9phQA=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gcv4_YKmxX1rnj7IeqrNvu6mICZ05Chyl686kTrUP-t52taDQs6Q4Y_ongiGxt89Ow0LTQLz0jJMhJxLRvdmgM0F9NqL6_mugMF0dhvTbo_WHeXQpDbu02fDFBseMCgd6rGuF6BhM6bZJMs6WkWiTOUSPGlbJLiU8HA_A4uq9gxvUeCD5TkAfHm9yYAv5tlXylMPNAGJKXUs1tVB8jw8fo2lCU2i3QGZWTpyRL94_XMxWYNbtUQmKey3l1GfDXpOF4RbyW_ldL_KQTJq68RNn2o6GOc2rkg4RFAkRsFywx4OoPgzao7r2Ss-IRDlgEDwqlFSRQ8kpy9rhiFE3v7e6vz7aiK6UNyBisKQxonM2fgEEa4UuL7TDnTURQjbq4d7KnUhF9DdoFxx6CdpPTylDkwvQusr4Jixp6fOtHBjn5gAeS6HO_8ktZ69ELRQxWRrm9DweiP2R1h1zPKNGHP7UwATo0Dc6YMrNddyg_pA1l9W7LQdykdSvEjXT1U7CXZ_FztGDgeWjorXygcxH7_m3oV6L_wQ2aoSMvMAnfmO15TL9vkEYA04ylXA3OaBrsoaEs_APh4QjqUPd6PDvGihXanWVafHKIiH9iAVcv--QfvjmuRo_MkAbA_SHU3sbIc0nENY-EClPpA-GfNtweXQcN5xRv5an3utVA_rmuSCzZX2D9_9udWCRPLdWLCQxal1QPfFm5W5oCJpPPDrreY3WBbHvJjae8MJCP6tjwEIzydj7Ul67sNkZPh58HBthIyxIS7238hVyr_kCv5Km7PPwL0eYLLXq0sPICk498a3B0FMF9F9qKzgdVLw6JleknL-KD1ifC6qeFqBAxhILwfsAE0Mh8NUO0wp1ZWanxZ9d6jHamfBVdeMMBkpnAQCwum0QRjinK5UwoAgYoroQrQVTVEFyY41DUlJCMLVh5eHIv74TcRPvlwGgcOOZn53RmDX3eYqtrdzyOxIUM1nkvoGQ30Oe6ImdZh0EhHpNEde2oT27v5xaINsY1WfRmBspwKGq7KrPvZw_X79pOQ7wZZdOJLn3h6HFDAmz5t1fFoITLYl-RGLN3B8AjcYKrTvAzqkWHhAhB3bqVfpHLHf_Y7dMkgaGDhn3_DBazf9UC2gIZhy7m6PdhMqXefaCsph3e9ZNtg0DY3XzssNKZaIDSiJ_93e_IcO4TKOkkC954FFfCIpPRPqoRtDDAePT3nG_paaGuZImvT1J9EgFuVgiEK_JE2h6182mVuN5JVzE0Zfz1_JV9GZA_xGhpbnpT2EWLL_J2ICqCNn_OI-SRXqEa8sKsKa9FB_YQjeti-eqz_9phQA=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e1d00003d8fdc2f",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e1d00003d8fdc2f_0",
                    "local_arrival": "2024-10-01T23:15:00.000Z",
                    "local_departure": "2024-10-01T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-02T06:15:00.000Z",
                    "utc_departure": "2024-10-02T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1229.15,
            "totalPrice": 951
          },
          {
            "allPassengerTotalPrice": 955,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH0hpCug_GKqlANiVjvg37ns6Xk3-7Z9jyC2_uSRpXoNSwarOz7NUL_zmAgko0CFwhYwfMK05gRQRk1ohhRb8I_Zh1ktBjC2M5crKMK7ya73awBRYgcPHvwXegv1HkQGCrJmc7p8m_bacZIjvRI6iTi6c3obmZ4D6S-ZvSPuieNxt5nRlREgD8pQfKpBXW_GKXLNIepLl1lVpnYxfgeYpmkBTjnVDCLe6FH3FpxFFYuJpYMpu8pyrYB2821FHhxbAKTjqgJzTHS0DspvGCAiqgPiDAMwuXsAeTxw3bq2RfR-gZSi6vYskrfco2qHVbDXGYbBT_BxXp5JIrk91DHzkWTnyrxIJxZS6vIeziD38f5gExAsVoN4LoPw8jqebWFj-dv5wes_TbXVPiatVHJep1k-hl0e0jeDGV5lQS7lkW6Fo7hvyXW1rXc1ekmLpeE2zrETaUuiIU1eveGQtQ_SskiwrlO569hVqF32AFC-eSVwzT-JdKK5KMFzrmrOIr_gOdP9MVQp-dW-qhEvW2ZJ03HDaRJfWrGgofYEoFa5COHUUm0eiPUyfpa4h6iXwqAIyZAH5PUMQtRCnD-XcpNLyMDsSTKFflhBq9yL1yDO-POijF4P-kNZPlUP2F_-tNSfVkcGxO53oPfh4oaeCGAtWmajvfdRxAemEvyj4oIdDztFYmA7I2e8172PS1o-ZzTDgYxmwhwHdyPC12o8OjVMsK0XEsoa5EoZ2lx2qbc6HuX1_DLXWBqk-kZU3x9v7n_bwwGkjTNzqb5ZRQo905h7VeEoNGJXwSjkh6bM3RFP1Ub7i6R0jlmfMIsQ3LkqFw4Q_c5oSvGNPOZnxOGIzddj_l_ak5qyhvtuHgHv32__5Wl8phS-wGfQ2t4EDy0Z4-8ilgfrJEQ6CJ0BTwbPLxc4P6etbak_hFTt7KXH0XexpwZqS-twiSNBaebGoOcwpHH9i4F8CviyGbT1TOxfuwbv02IoVeT-Ti_S9FX53nwIx-AnqLZc-mcyTl7GYKfMWa5LK1pffo_CLpAW5ms5lM436p1gIA0KP0kjV58qzmrtC9yMgyKEOqm5P_aHiQ60HyOtjUcFsjWj-UhGkxlNlvohIQ_AdR3Vya5msYcFks8uISUuiy4T5ih4H5UDDx-N_upuuoEOEE6KX36VW6O0FNF1HEXXGV5r0gVPmHpK_WVFU1HFzH50Me4NoOtycS5fDFgNhkfdK4Raj7NxOrKXsu2qqbtOWi164KldCALjK0TlJ__NuzxyTcE0ykOsuyO6qdhGPhyI_FtBmZFwjiF4hPyoxds9f5WahV4McjqMoyO1D_1R6l1m6GP1vty135wVRPY_QCo3Q0ACCoMk9nxg1C8iRVw==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH0hpCug_GKqlANiVjvg37ns6Xk3-7Z9jyC2_uSRpXoNSwarOz7NUL_zmAgko0CFwhYwfMK05gRQRk1ohhRb8I_Zh1ktBjC2M5crKMK7ya73awBRYgcPHvwXegv1HkQGCrJmc7p8m_bacZIjvRI6iTi6c3obmZ4D6S-ZvSPuieNxt5nRlREgD8pQfKpBXW_GKXLNIepLl1lVpnYxfgeYpmkBTjnVDCLe6FH3FpxFFYuJpYMpu8pyrYB2821FHhxbAKTjqgJzTHS0DspvGCAiqgPiDAMwuXsAeTxw3bq2RfR-gZSi6vYskrfco2qHVbDXGYbBT_BxXp5JIrk91DHzkWTnyrxIJxZS6vIeziD38f5gExAsVoN4LoPw8jqebWFj-dv5wes_TbXVPiatVHJep1k-hl0e0jeDGV5lQS7lkW6Fo7hvyXW1rXc1ekmLpeE2zrETaUuiIU1eveGQtQ_SskiwrlO569hVqF32AFC-eSVwzT-JdKK5KMFzrmrOIr_gOdP9MVQp-dW-qhEvW2ZJ03HDaRJfWrGgofYEoFa5COHUUm0eiPUyfpa4h6iXwqAIyZAH5PUMQtRCnD-XcpNLyMDsSTKFflhBq9yL1yDO-POijF4P-kNZPlUP2F_-tNSfVkcGxO53oPfh4oaeCGAtWmajvfdRxAemEvyj4oIdDztFYmA7I2e8172PS1o-ZzTDgYxmwhwHdyPC12o8OjVMsK0XEsoa5EoZ2lx2qbc6HuX1_DLXWBqk-kZU3x9v7n_bwwGkjTNzqb5ZRQo905h7VeEoNGJXwSjkh6bM3RFP1Ub7i6R0jlmfMIsQ3LkqFw4Q_c5oSvGNPOZnxOGIzddj_l_ak5qyhvtuHgHv32__5Wl8phS-wGfQ2t4EDy0Z4-8ilgfrJEQ6CJ0BTwbPLxc4P6etbak_hFTt7KXH0XexpwZqS-twiSNBaebGoOcwpHH9i4F8CviyGbT1TOxfuwbv02IoVeT-Ti_S9FX53nwIx-AnqLZc-mcyTl7GYKfMWa5LK1pffo_CLpAW5ms5lM436p1gIA0KP0kjV58qzmrtC9yMgyKEOqm5P_aHiQ60HyOtjUcFsjWj-UhGkxlNlvohIQ_AdR3Vya5msYcFks8uISUuiy4T5ih4H5UDDx-N_upuuoEOEE6KX36VW6O0FNF1HEXXGV5r0gVPmHpK_WVFU1HFzH50Me4NoOtycS5fDFgNhkfdK4Raj7NxOrKXsu2qqbtOWi164KldCALjK0TlJ__NuzxyTcE0ykOsuyO6qdhGPhyI_FtBmZFwjiF4hPyoxds9f5WahV4McjqMoyO1D_1R6l1m6GP1vty135wVRPY_QCo3Q0ACCoMk9nxg1C8iRVw==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH0hpCug_GKqlANiVjvg37ns6Xk3-7Z9jyC2_uSRpXoNSwarOz7NUL_zmAgko0CFwhYwfMK05gRQRk1ohhRb8I_Zh1ktBjC2M5crKMK7ya73awBRYgcPHvwXegv1HkQGCrJmc7p8m_bacZIjvRI6iTi6c3obmZ4D6S-ZvSPuieNxt5nRlREgD8pQfKpBXW_GKXLNIepLl1lVpnYxfgeYpmkBTjnVDCLe6FH3FpxFFYuJpYMpu8pyrYB2821FHhxbAKTjqgJzTHS0DspvGCAiqgPiDAMwuXsAeTxw3bq2RfR-gZSi6vYskrfco2qHVbDXGYbBT_BxXp5JIrk91DHzkWTnyrxIJxZS6vIeziD38f5gExAsVoN4LoPw8jqebWFj-dv5wes_TbXVPiatVHJep1k-hl0e0jeDGV5lQS7lkW6Fo7hvyXW1rXc1ekmLpeE2zrETaUuiIU1eveGQtQ_SskiwrlO569hVqF32AFC-eSVwzT-JdKK5KMFzrmrOIr_gOdP9MVQp-dW-qhEvW2ZJ03HDaRJfWrGgofYEoFa5COHUUm0eiPUyfpa4h6iXwqAIyZAH5PUMQtRCnD-XcpNLyMDsSTKFflhBq9yL1yDO-POijF4P-kNZPlUP2F_-tNSfVkcGxO53oPfh4oaeCGAtWmajvfdRxAemEvyj4oIdDztFYmA7I2e8172PS1o-ZzTDgYxmwhwHdyPC12o8OjVMsK0XEsoa5EoZ2lx2qbc6HuX1_DLXWBqk-kZU3x9v7n_bwwGkjTNzqb5ZRQo905h7VeEoNGJXwSjkh6bM3RFP1Ub7i6R0jlmfMIsQ3LkqFw4Q_c5oSvGNPOZnxOGIzddj_l_ak5qyhvtuHgHv32__5Wl8phS-wGfQ2t4EDy0Z4-8ilgfrJEQ6CJ0BTwbPLxc4P6etbak_hFTt7KXH0XexpwZqS-twiSNBaebGoOcwpHH9i4F8CviyGbT1TOxfuwbv02IoVeT-Ti_S9FX53nwIx-AnqLZc-mcyTl7GYKfMWa5LK1pffo_CLpAW5ms5lM436p1gIA0KP0kjV58qzmrtC9yMgyKEOqm5P_aHiQ60HyOtjUcFsjWj-UhGkxlNlvohIQ_AdR3Vya5msYcFks8uISUuiy4T5ih4H5UDDx-N_upuuoEOEE6KX36VW6O0FNF1HEXXGV5r0gVPmHpK_WVFU1HFzH50Me4NoOtycS5fDFgNhkfdK4Raj7NxOrKXsu2qqbtOWi164KldCALjK0TlJ__NuzxyTcE0ykOsuyO6qdhGPhyI_FtBmZFwjiF4hPyoxds9f5WahV4McjqMoyO1D_1R6l1m6GP1vty135wVRPY_QCo3Q0ACCoMk9nxg1C8iRVw==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1193.59,
            "totalPrice": 955
          },
          {
            "allPassengerTotalPrice": 955,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GazU5O_sb--ZcY9gH1Kx-jxDAhs0aPHDbnMh1bfftGmTKB6aGb1wKytXUiNlcSz_q1kb4DtR_X0TTFThPXn6KIQuHNWdj34L6V5xPfRbpxjZhBPhH5MKo7xEjAUeR2JEhaHr8tzOZDoUoxGkvup_DwaTaxCFT5aQLhoirUYZ2xunrXWWdqoGiocIp7H0SCcR8RnsiIxtSTDCEmf4FcT6j8oScYpWs2eAIMKcmSQKD2J6FIW1oKRnbr3X0Ay5mkwN_g6Xb07MA1_MhDOLk4_XYm6LDhNa889qS4wFgFDQKIsKAwfspinnrx8y0KI0bc3ena4YObmCTr3skqdetx0dHbmfbu5plgw4mwABXK_1thLvbdXIr02Ij1CXN9UzAe-XDdNf6UjGNs3qae_idQ1p04lAEMMytlD3-GyNdRSDMVPSrHAw4akfrUNIo2BJotJCogFgSoSl5wg6gZvh1pNVWHKFRnthCMEyKSgBSNS3AgeU-ZBzfWIcwYE-WQoA5NBPTuA5ChAHxQSO9uXKKXqZ-JX08CtXJ5-bsBpnIw-CVHk6xDtuv5kQaIdaSB6IEyO2tSAjBohlR3lUCp868dEzLgzzWVxsMexAXldVzUiIglDlk6i_XPhm4Iw0opkndiq3Kqp4RN3VYq7umpMc7u5VvJqeW2mFWVNmf1nR_hAhB73f1DtPlBgKRPl7pwYZMLutG-heGOyytCvUcPZlSP2k9BuNKA3-d7wJQOh_SZiqLUG2vBMPZe_j1Fde-lRB1GQIngx30o02Rzodv_jlqSr2jYVZN9_r-fSRohq2APcofrrWjPjyHLki8_qPP45k5nZZ6rmW6xbFZf-ZuaTfsfGvXcDhVOqRW6j4IsdXZ_QxYVPk2hkqjV2XxYt76jrj9ypSIGqTRBUm0s4aLo-hLtv4OoFQx-ph2tmULSQWLQ4AgG2_uGo9-2pvB_2BJmvmoL_U0uRBY0vAB7IPid7zYMJewcM5skCshCtYToW7pmE_7fJgvLRO3U-K-MTJaqEuFL3__LUWDnNCATW7KTShzCDwc7sgPJ3IWntuILt1JeL0ERLudgcwucVffUUOqsMMYnWxSYw3C0z39GQRkXryRObx1tmfWclji3yDfHX-lRXob92NetPqHu7LxoYzNTf3edskepSGb-A4YoStU12wFxG19MRdaDnZGz_eAgJOvtf9NtLE1xOZCyf7BG0UT4cu78mto4uR0BPzE2UCMVk21VnluWI5B-N_FavDBXLvlNF-pk7NCEhBBXXIlwNkHkv9QAMP4n2S7ilcIssdWu1oltKTS-6nX-kDB2i4vD22smBYLE1Cww5YaA6rqkAgt2BEBY9xOs3Ogn5IHoToNnm-qTPrc0qAcKVzYU15fnoiFNYtOnDU=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GazU5O_sb--ZcY9gH1Kx-jxDAhs0aPHDbnMh1bfftGmTKB6aGb1wKytXUiNlcSz_q1kb4DtR_X0TTFThPXn6KIQuHNWdj34L6V5xPfRbpxjZhBPhH5MKo7xEjAUeR2JEhaHr8tzOZDoUoxGkvup_DwaTaxCFT5aQLhoirUYZ2xunrXWWdqoGiocIp7H0SCcR8RnsiIxtSTDCEmf4FcT6j8oScYpWs2eAIMKcmSQKD2J6FIW1oKRnbr3X0Ay5mkwN_g6Xb07MA1_MhDOLk4_XYm6LDhNa889qS4wFgFDQKIsKAwfspinnrx8y0KI0bc3ena4YObmCTr3skqdetx0dHbmfbu5plgw4mwABXK_1thLvbdXIr02Ij1CXN9UzAe-XDdNf6UjGNs3qae_idQ1p04lAEMMytlD3-GyNdRSDMVPSrHAw4akfrUNIo2BJotJCogFgSoSl5wg6gZvh1pNVWHKFRnthCMEyKSgBSNS3AgeU-ZBzfWIcwYE-WQoA5NBPTuA5ChAHxQSO9uXKKXqZ-JX08CtXJ5-bsBpnIw-CVHk6xDtuv5kQaIdaSB6IEyO2tSAjBohlR3lUCp868dEzLgzzWVxsMexAXldVzUiIglDlk6i_XPhm4Iw0opkndiq3Kqp4RN3VYq7umpMc7u5VvJqeW2mFWVNmf1nR_hAhB73f1DtPlBgKRPl7pwYZMLutG-heGOyytCvUcPZlSP2k9BuNKA3-d7wJQOh_SZiqLUG2vBMPZe_j1Fde-lRB1GQIngx30o02Rzodv_jlqSr2jYVZN9_r-fSRohq2APcofrrWjPjyHLki8_qPP45k5nZZ6rmW6xbFZf-ZuaTfsfGvXcDhVOqRW6j4IsdXZ_QxYVPk2hkqjV2XxYt76jrj9ypSIGqTRBUm0s4aLo-hLtv4OoFQx-ph2tmULSQWLQ4AgG2_uGo9-2pvB_2BJmvmoL_U0uRBY0vAB7IPid7zYMJewcM5skCshCtYToW7pmE_7fJgvLRO3U-K-MTJaqEuFL3__LUWDnNCATW7KTShzCDwc7sgPJ3IWntuILt1JeL0ERLudgcwucVffUUOqsMMYnWxSYw3C0z39GQRkXryRObx1tmfWclji3yDfHX-lRXob92NetPqHu7LxoYzNTf3edskepSGb-A4YoStU12wFxG19MRdaDnZGz_eAgJOvtf9NtLE1xOZCyf7BG0UT4cu78mto4uR0BPzE2UCMVk21VnluWI5B-N_FavDBXLvlNF-pk7NCEhBBXXIlwNkHkv9QAMP4n2S7ilcIssdWu1oltKTS-6nX-kDB2i4vD22smBYLE1Cww5YaA6rqkAgt2BEBY9xOs3Ogn5IHoToNnm-qTPrc0qAcKVzYU15fnoiFNYtOnDU=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GazU5O_sb--ZcY9gH1Kx-jxDAhs0aPHDbnMh1bfftGmTKB6aGb1wKytXUiNlcSz_q1kb4DtR_X0TTFThPXn6KIQuHNWdj34L6V5xPfRbpxjZhBPhH5MKo7xEjAUeR2JEhaHr8tzOZDoUoxGkvup_DwaTaxCFT5aQLhoirUYZ2xunrXWWdqoGiocIp7H0SCcR8RnsiIxtSTDCEmf4FcT6j8oScYpWs2eAIMKcmSQKD2J6FIW1oKRnbr3X0Ay5mkwN_g6Xb07MA1_MhDOLk4_XYm6LDhNa889qS4wFgFDQKIsKAwfspinnrx8y0KI0bc3ena4YObmCTr3skqdetx0dHbmfbu5plgw4mwABXK_1thLvbdXIr02Ij1CXN9UzAe-XDdNf6UjGNs3qae_idQ1p04lAEMMytlD3-GyNdRSDMVPSrHAw4akfrUNIo2BJotJCogFgSoSl5wg6gZvh1pNVWHKFRnthCMEyKSgBSNS3AgeU-ZBzfWIcwYE-WQoA5NBPTuA5ChAHxQSO9uXKKXqZ-JX08CtXJ5-bsBpnIw-CVHk6xDtuv5kQaIdaSB6IEyO2tSAjBohlR3lUCp868dEzLgzzWVxsMexAXldVzUiIglDlk6i_XPhm4Iw0opkndiq3Kqp4RN3VYq7umpMc7u5VvJqeW2mFWVNmf1nR_hAhB73f1DtPlBgKRPl7pwYZMLutG-heGOyytCvUcPZlSP2k9BuNKA3-d7wJQOh_SZiqLUG2vBMPZe_j1Fde-lRB1GQIngx30o02Rzodv_jlqSr2jYVZN9_r-fSRohq2APcofrrWjPjyHLki8_qPP45k5nZZ6rmW6xbFZf-ZuaTfsfGvXcDhVOqRW6j4IsdXZ_QxYVPk2hkqjV2XxYt76jrj9ypSIGqTRBUm0s4aLo-hLtv4OoFQx-ph2tmULSQWLQ4AgG2_uGo9-2pvB_2BJmvmoL_U0uRBY0vAB7IPid7zYMJewcM5skCshCtYToW7pmE_7fJgvLRO3U-K-MTJaqEuFL3__LUWDnNCATW7KTShzCDwc7sgPJ3IWntuILt1JeL0ERLudgcwucVffUUOqsMMYnWxSYw3C0z39GQRkXryRObx1tmfWclji3yDfHX-lRXob92NetPqHu7LxoYzNTf3edskepSGb-A4YoStU12wFxG19MRdaDnZGz_eAgJOvtf9NtLE1xOZCyf7BG0UT4cu78mto4uR0BPzE2UCMVk21VnluWI5B-N_FavDBXLvlNF-pk7NCEhBBXXIlwNkHkv9QAMP4n2S7ilcIssdWu1oltKTS-6nX-kDB2i4vD22smBYLE1Cww5YaA6rqkAgt2BEBY9xOs3Ogn5IHoToNnm-qTPrc0qAcKVzYU15fnoiFNYtOnDU=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1194.26,
            "totalPrice": 955
          },
          {
            "allPassengerTotalPrice": 955,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GflIIIK_XjbTSrTURmDLMMP_mErOvp4qaf0fb-z09J0PuAmbh6v11BI4iNmlnBBQbjLgqw1gAeBLlav6ncfuEv9kKmnKM5cDNBiwKLu3VsECpT1XmLyxAir5cG7qLPF_EY_FO8ald5kWf6hBYD68liGr-obRerfm1HDXizmidU8AJexUF9HktS_bzTUlxhb-_7ydyoSHsJ7D1EDsPdq3s6Pu34-KJoRjCDucI_0ZXK-8nVrhjoKovN661GTQ4yIY2V0KRVt1T2GcCx5FDFV7S0DYtZGN-A8j_aP7THIudImRIAhppn0NKOSPFCb9JMm1a2kGapR4QvrFV0mz0oaBS1tOjCRLhvTfXKujscliP8oedf1WY1MnPfJfAgFqJRrugusIJ7GfUxChxU_KjXxgrS43NTrkbkB44addeMyIb-wPQSL9PxE7LkeXQKeyYOYbinX2_cxkmHGXDmtCnXXivf0R-stlSh2MeorfPSxg7XgVAJ8l1V2YJ-w8rJs_EyEtzj4oAhif2HILiwN-fntzY4cvrqo5rZByqDWmBrmc3QSXRwLAZFSYyIe2LI1ZVWE8LjDeMRChWkuGAA2L8FBe1fMifCB1oBEgo9D5Nvz73pIpRaQnI13dSZZMnEQw74VmgOca7UtQS88ceZ4HRplokKyjn5uUsXjICalUoWDvM29oOl-ppOdXodkp6WY8DnIW1LkZ1Rn0d9HCZiNVdmFPP3v0xFwuy6X6i4s-5grN-q-W4s67_1X61zGEc-eDPMNrPAI1axfAwCl6130F4WniRZIOiZ_UN6fQy-rKDeiZ5SSguOK82XLnYBwfsTtRD2Q-qjiPAdWj6IH95qk-Ptb67xzmy2aZaKqKmjJo8xphekCXk0W7GvxdvEv8YC5ECvtngKozTRytcqDg_CbfOKce3qCy-Q0xTpnA_5S4dDhKVmEhnKQe4TqNxugAA-pZOGYTDf-vqht3PMf2Yo6bnz-fyCCmGl-5QcCockg0loaR_HdmgHlrEop109RtuWv1NKspxsGDCXtZgb0oW14OtzFgsC1rr1jGG3f3v8R25cHxzTFGuHCupTvH8Ohss58PmZ3mLPWc4tU_YQtRfJpDZMxECh3eqNMe5f7Pf2BnRcRQZn7FZkqBrgndh2aNbCxlzG8dfw0WTf6xVxsRJkEacNRL85wYop2AJVlPGZqHvVppA4zlGp3hY_l0oeeG2wvuO-aPa-_8GV6c5_RPerloD31cOkKYh3hXevffMA31eZjys6r_K9MiuRAJYbIBUegxziXWeR8cuu5OfNMDWI9RKNUG4iS12AHP0DqrwUT5jc08G-oNq8CqFlgOMj23BGo2G9je5VUl4O6Yq8L5H9UPHm7IzIw==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GflIIIK_XjbTSrTURmDLMMP_mErOvp4qaf0fb-z09J0PuAmbh6v11BI4iNmlnBBQbjLgqw1gAeBLlav6ncfuEv9kKmnKM5cDNBiwKLu3VsECpT1XmLyxAir5cG7qLPF_EY_FO8ald5kWf6hBYD68liGr-obRerfm1HDXizmidU8AJexUF9HktS_bzTUlxhb-_7ydyoSHsJ7D1EDsPdq3s6Pu34-KJoRjCDucI_0ZXK-8nVrhjoKovN661GTQ4yIY2V0KRVt1T2GcCx5FDFV7S0DYtZGN-A8j_aP7THIudImRIAhppn0NKOSPFCb9JMm1a2kGapR4QvrFV0mz0oaBS1tOjCRLhvTfXKujscliP8oedf1WY1MnPfJfAgFqJRrugusIJ7GfUxChxU_KjXxgrS43NTrkbkB44addeMyIb-wPQSL9PxE7LkeXQKeyYOYbinX2_cxkmHGXDmtCnXXivf0R-stlSh2MeorfPSxg7XgVAJ8l1V2YJ-w8rJs_EyEtzj4oAhif2HILiwN-fntzY4cvrqo5rZByqDWmBrmc3QSXRwLAZFSYyIe2LI1ZVWE8LjDeMRChWkuGAA2L8FBe1fMifCB1oBEgo9D5Nvz73pIpRaQnI13dSZZMnEQw74VmgOca7UtQS88ceZ4HRplokKyjn5uUsXjICalUoWDvM29oOl-ppOdXodkp6WY8DnIW1LkZ1Rn0d9HCZiNVdmFPP3v0xFwuy6X6i4s-5grN-q-W4s67_1X61zGEc-eDPMNrPAI1axfAwCl6130F4WniRZIOiZ_UN6fQy-rKDeiZ5SSguOK82XLnYBwfsTtRD2Q-qjiPAdWj6IH95qk-Ptb67xzmy2aZaKqKmjJo8xphekCXk0W7GvxdvEv8YC5ECvtngKozTRytcqDg_CbfOKce3qCy-Q0xTpnA_5S4dDhKVmEhnKQe4TqNxugAA-pZOGYTDf-vqht3PMf2Yo6bnz-fyCCmGl-5QcCockg0loaR_HdmgHlrEop109RtuWv1NKspxsGDCXtZgb0oW14OtzFgsC1rr1jGG3f3v8R25cHxzTFGuHCupTvH8Ohss58PmZ3mLPWc4tU_YQtRfJpDZMxECh3eqNMe5f7Pf2BnRcRQZn7FZkqBrgndh2aNbCxlzG8dfw0WTf6xVxsRJkEacNRL85wYop2AJVlPGZqHvVppA4zlGp3hY_l0oeeG2wvuO-aPa-_8GV6c5_RPerloD31cOkKYh3hXevffMA31eZjys6r_K9MiuRAJYbIBUegxziXWeR8cuu5OfNMDWI9RKNUG4iS12AHP0DqrwUT5jc08G-oNq8CqFlgOMj23BGo2G9je5VUl4O6Yq8L5H9UPHm7IzIw==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GflIIIK_XjbTSrTURmDLMMP_mErOvp4qaf0fb-z09J0PuAmbh6v11BI4iNmlnBBQbjLgqw1gAeBLlav6ncfuEv9kKmnKM5cDNBiwKLu3VsECpT1XmLyxAir5cG7qLPF_EY_FO8ald5kWf6hBYD68liGr-obRerfm1HDXizmidU8AJexUF9HktS_bzTUlxhb-_7ydyoSHsJ7D1EDsPdq3s6Pu34-KJoRjCDucI_0ZXK-8nVrhjoKovN661GTQ4yIY2V0KRVt1T2GcCx5FDFV7S0DYtZGN-A8j_aP7THIudImRIAhppn0NKOSPFCb9JMm1a2kGapR4QvrFV0mz0oaBS1tOjCRLhvTfXKujscliP8oedf1WY1MnPfJfAgFqJRrugusIJ7GfUxChxU_KjXxgrS43NTrkbkB44addeMyIb-wPQSL9PxE7LkeXQKeyYOYbinX2_cxkmHGXDmtCnXXivf0R-stlSh2MeorfPSxg7XgVAJ8l1V2YJ-w8rJs_EyEtzj4oAhif2HILiwN-fntzY4cvrqo5rZByqDWmBrmc3QSXRwLAZFSYyIe2LI1ZVWE8LjDeMRChWkuGAA2L8FBe1fMifCB1oBEgo9D5Nvz73pIpRaQnI13dSZZMnEQw74VmgOca7UtQS88ceZ4HRplokKyjn5uUsXjICalUoWDvM29oOl-ppOdXodkp6WY8DnIW1LkZ1Rn0d9HCZiNVdmFPP3v0xFwuy6X6i4s-5grN-q-W4s67_1X61zGEc-eDPMNrPAI1axfAwCl6130F4WniRZIOiZ_UN6fQy-rKDeiZ5SSguOK82XLnYBwfsTtRD2Q-qjiPAdWj6IH95qk-Ptb67xzmy2aZaKqKmjJo8xphekCXk0W7GvxdvEv8YC5ECvtngKozTRytcqDg_CbfOKce3qCy-Q0xTpnA_5S4dDhKVmEhnKQe4TqNxugAA-pZOGYTDf-vqht3PMf2Yo6bnz-fyCCmGl-5QcCockg0loaR_HdmgHlrEop109RtuWv1NKspxsGDCXtZgb0oW14OtzFgsC1rr1jGG3f3v8R25cHxzTFGuHCupTvH8Ohss58PmZ3mLPWc4tU_YQtRfJpDZMxECh3eqNMe5f7Pf2BnRcRQZn7FZkqBrgndh2aNbCxlzG8dfw0WTf6xVxsRJkEacNRL85wYop2AJVlPGZqHvVppA4zlGp3hY_l0oeeG2wvuO-aPa-_8GV6c5_RPerloD31cOkKYh3hXevffMA31eZjys6r_K9MiuRAJYbIBUegxziXWeR8cuu5OfNMDWI9RKNUG4iS12AHP0DqrwUT5jc08G-oNq8CqFlgOMj23BGo2G9je5VUl4O6Yq8L5H9UPHm7IzIw==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e1d00003d8fdc2f",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e1d00003d8fdc2f_0",
                    "local_arrival": "2024-10-01T23:15:00.000Z",
                    "local_departure": "2024-10-01T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-02T06:15:00.000Z",
                    "utc_departure": "2024-10-02T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1230.92,
            "totalPrice": 955
          },
          {
            "allPassengerTotalPrice": 955,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GliQgpmO6uQ5TQ6GixfkIyd-CPBUCoWiKyLYMta84xO9vg8R7kX38kXkCBlCQNia-oCBJvG2rO7aPcrE9AK9B8RgbI5esWYxrfSRL4BSSKC2GHFhb_720TN4jAfV9yAh_w6_7omgAVUHgyRtmagUmebfWXCWGJIapAGPXaqVX6-h3VyLIsz7BgYyomkqRKctx0u6LLPwbtPdhnHKEAKVOxvd_GIUa_oILh_yOwdrPVX2t8YrI1kLb52Ng4RXJJ6B8V5aekZgH8Zz0sjldeNFOmiBBVwrz16pQrELrC7nChJ787AwkD_k95J2WJta-JfccsRo9957BwQZVNH6HwPIGYy5cfu4mkPUh6gKNRudIJACyqomjMquiG8qseA2djwCt1JtapHp35ygz37en0SyB95lEobedkTuXycjxnf7dx8q8ZzeEi3_vgon3QcTxfBvGmC06Qn6Ipvoyr_DLDarJsdtaQDB359Nck7tRLKncC32CfZTyMCHoK6sWNgbs0gmW_08E14zLFoG93kEhd8AyDcE5p_F84VIUIzth466ZnAbspft658gB0HA3XiPnthsnU2iWzjP1H2uBHrwBAgEoTtdBbP6vRqhyNtkhKR8xTRqXEQZCXMiFeObtd4Qk19pKLy4Ee5Mom7_eMOTsYPLhXSlhjwbMGS6tR_V6DdCtzbfl887vYaxniYyhjveqU-wBKsm2NRa_rEcfVW5taQbUPWovOTi1afXHZqSBbAgaPS7C5T-epeZyZAUDbfXQQFc1kpPS9XGXiIxsJuiKGm2ODQQOyE5cMEBdBOvRpEJk0LKzoWgRuFcwGKTQMEYufUXqsCjEFqGHMfx7FDX6NNuRW1ruRC6Zzd-ZQovDI7ExI2HsvuqZEaCWXeAfFZowtbg5Vjrn2uj0ttyNqiqC2I5CcgOU6L-9Z8r4K3BF7CKJjXCZIdr-LGX2g7amDCI7nNsMHQxVbnI24SP1AVWeAfCf57Wqy7wqDhMYRgTof2VGplx6SFoNJE-d0GQxubnxm82BcjaruD8hBIzrx7up1YLVNOWKJKEfTQG1ALeXKWx0vi7EEhalyShtG1KDZ2QDEppZGFZD8VkLYX7kMHP-bVfEOkmuCFfMhmCV-RuOT_czuSOkqvW4W5gnYV9bLesK9GE9hEZpz__81gfq3U6LweyeCvRycDVNsT97e4vXs50nJxVRD4hPzo7NUvUfO3A3bfj483CZRu-TC2c8FRKH1R2k5dk-ImGChUnbpJwG-dY5t4FBYbktRUbRu04xwFhkCBaDsw109TZfc6VkxCoerT07SubK1X7D_6axU1zh3zwUxbzi8TaCI9dFSL0ElNyDddRXqXw72vs_Y1UbRn7BgRyQxg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GliQgpmO6uQ5TQ6GixfkIyd-CPBUCoWiKyLYMta84xO9vg8R7kX38kXkCBlCQNia-oCBJvG2rO7aPcrE9AK9B8RgbI5esWYxrfSRL4BSSKC2GHFhb_720TN4jAfV9yAh_w6_7omgAVUHgyRtmagUmebfWXCWGJIapAGPXaqVX6-h3VyLIsz7BgYyomkqRKctx0u6LLPwbtPdhnHKEAKVOxvd_GIUa_oILh_yOwdrPVX2t8YrI1kLb52Ng4RXJJ6B8V5aekZgH8Zz0sjldeNFOmiBBVwrz16pQrELrC7nChJ787AwkD_k95J2WJta-JfccsRo9957BwQZVNH6HwPIGYy5cfu4mkPUh6gKNRudIJACyqomjMquiG8qseA2djwCt1JtapHp35ygz37en0SyB95lEobedkTuXycjxnf7dx8q8ZzeEi3_vgon3QcTxfBvGmC06Qn6Ipvoyr_DLDarJsdtaQDB359Nck7tRLKncC32CfZTyMCHoK6sWNgbs0gmW_08E14zLFoG93kEhd8AyDcE5p_F84VIUIzth466ZnAbspft658gB0HA3XiPnthsnU2iWzjP1H2uBHrwBAgEoTtdBbP6vRqhyNtkhKR8xTRqXEQZCXMiFeObtd4Qk19pKLy4Ee5Mom7_eMOTsYPLhXSlhjwbMGS6tR_V6DdCtzbfl887vYaxniYyhjveqU-wBKsm2NRa_rEcfVW5taQbUPWovOTi1afXHZqSBbAgaPS7C5T-epeZyZAUDbfXQQFc1kpPS9XGXiIxsJuiKGm2ODQQOyE5cMEBdBOvRpEJk0LKzoWgRuFcwGKTQMEYufUXqsCjEFqGHMfx7FDX6NNuRW1ruRC6Zzd-ZQovDI7ExI2HsvuqZEaCWXeAfFZowtbg5Vjrn2uj0ttyNqiqC2I5CcgOU6L-9Z8r4K3BF7CKJjXCZIdr-LGX2g7amDCI7nNsMHQxVbnI24SP1AVWeAfCf57Wqy7wqDhMYRgTof2VGplx6SFoNJE-d0GQxubnxm82BcjaruD8hBIzrx7up1YLVNOWKJKEfTQG1ALeXKWx0vi7EEhalyShtG1KDZ2QDEppZGFZD8VkLYX7kMHP-bVfEOkmuCFfMhmCV-RuOT_czuSOkqvW4W5gnYV9bLesK9GE9hEZpz__81gfq3U6LweyeCvRycDVNsT97e4vXs50nJxVRD4hPzo7NUvUfO3A3bfj483CZRu-TC2c8FRKH1R2k5dk-ImGChUnbpJwG-dY5t4FBYbktRUbRu04xwFhkCBaDsw109TZfc6VkxCoerT07SubK1X7D_6axU1zh3zwUxbzi8TaCI9dFSL0ElNyDddRXqXw72vs_Y1UbRn7BgRyQxg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GliQgpmO6uQ5TQ6GixfkIyd-CPBUCoWiKyLYMta84xO9vg8R7kX38kXkCBlCQNia-oCBJvG2rO7aPcrE9AK9B8RgbI5esWYxrfSRL4BSSKC2GHFhb_720TN4jAfV9yAh_w6_7omgAVUHgyRtmagUmebfWXCWGJIapAGPXaqVX6-h3VyLIsz7BgYyomkqRKctx0u6LLPwbtPdhnHKEAKVOxvd_GIUa_oILh_yOwdrPVX2t8YrI1kLb52Ng4RXJJ6B8V5aekZgH8Zz0sjldeNFOmiBBVwrz16pQrELrC7nChJ787AwkD_k95J2WJta-JfccsRo9957BwQZVNH6HwPIGYy5cfu4mkPUh6gKNRudIJACyqomjMquiG8qseA2djwCt1JtapHp35ygz37en0SyB95lEobedkTuXycjxnf7dx8q8ZzeEi3_vgon3QcTxfBvGmC06Qn6Ipvoyr_DLDarJsdtaQDB359Nck7tRLKncC32CfZTyMCHoK6sWNgbs0gmW_08E14zLFoG93kEhd8AyDcE5p_F84VIUIzth466ZnAbspft658gB0HA3XiPnthsnU2iWzjP1H2uBHrwBAgEoTtdBbP6vRqhyNtkhKR8xTRqXEQZCXMiFeObtd4Qk19pKLy4Ee5Mom7_eMOTsYPLhXSlhjwbMGS6tR_V6DdCtzbfl887vYaxniYyhjveqU-wBKsm2NRa_rEcfVW5taQbUPWovOTi1afXHZqSBbAgaPS7C5T-epeZyZAUDbfXQQFc1kpPS9XGXiIxsJuiKGm2ODQQOyE5cMEBdBOvRpEJk0LKzoWgRuFcwGKTQMEYufUXqsCjEFqGHMfx7FDX6NNuRW1ruRC6Zzd-ZQovDI7ExI2HsvuqZEaCWXeAfFZowtbg5Vjrn2uj0ttyNqiqC2I5CcgOU6L-9Z8r4K3BF7CKJjXCZIdr-LGX2g7amDCI7nNsMHQxVbnI24SP1AVWeAfCf57Wqy7wqDhMYRgTof2VGplx6SFoNJE-d0GQxubnxm82BcjaruD8hBIzrx7up1YLVNOWKJKEfTQG1ALeXKWx0vi7EEhalyShtG1KDZ2QDEppZGFZD8VkLYX7kMHP-bVfEOkmuCFfMhmCV-RuOT_czuSOkqvW4W5gnYV9bLesK9GE9hEZpz__81gfq3U6LweyeCvRycDVNsT97e4vXs50nJxVRD4hPzo7NUvUfO3A3bfj483CZRu-TC2c8FRKH1R2k5dk-ImGChUnbpJwG-dY5t4FBYbktRUbRu04xwFhkCBaDsw109TZfc6VkxCoerT07SubK1X7D_6axU1zh3zwUxbzi8TaCI9dFSL0ElNyDddRXqXw72vs_Y1UbRn7BgRyQxg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e1d00003d8fdc2f",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e1d00003d8fdc2f_0",
                    "local_arrival": "2024-10-01T23:15:00.000Z",
                    "local_departure": "2024-10-01T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-02T06:15:00.000Z",
                    "utc_departure": "2024-10-02T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1231.59,
            "totalPrice": 955
          },
          {
            "allPassengerTotalPrice": 957,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G67epNhqUpGDP0n15OaJx5TngkrbXPCUdLZ27PboeNu948DsMwChtIZTQfDJ4gLPtx5tEWjZ2Qp1mVKf50D0EhdeHLBnQ_6AlKq_0vO3_PFGBShjdPDOT5x_lCuUYwc07mjTz181cTVTBvhSPF79ZM4Xwe_4cTTaW3vZOblR4rBsmJ5kLHeIDOJp-HLEcUmTseEja5L9xKp2jwnhVbrn_sAaGUNxgW9UBlsdBDcD_FwShA7kxufX6Eo8pzSQ3oBJm8wz8tnYYXRl5e7F7Hrj-_ssOqQppv5dI2I0WE8XniP7yccpQ4gUv8tO6CbMtQEANjriob0l4q1HJG1iabTbJJE7GzUc2kDQFW6ZILT4S-EpSJ4KVTP3-fHec9IxhE4_REJ3_M-6YLfJPtuiS2_O5ya8QpzwMdNHRRhSLmlPP7GJ5x09pfsr6JBnYFCTz3Wu6AFu3XkKm9dtwKiiVd0cgMQU__l-KIbVZCtOtnPnT6O7yF4yJogkEv5ZXMtO5UwDIWfdFviCYHYyo6bu5O7L0HJAcGjST-_T1s-d9Xe1SDw4hZ-HylIasy8HqVNMVmdRtNBF3n5EwfhBgJlY0Bfa9w1z2MYDwu2s-JtJKg05kWYX-vaijdP4Abtf32WgwQMAf5yN4_OmEgMKevUkt6e7HcYxJ4ZCDpJ4Yel9g4Sp3tTF3M7P2PjPoLB-7K-HHQs20YmftVZDg4D3dN2Qh57d6KKrizqf8Ng9W6ItZbl9eH2B2MXtE3QzYZvs5b5H5Ppi4E4zIMu2svA9sXDdCOSI5NIZAt3vXfwrGTdLO9XhOcCAjPVLr9VFJlXLeyAPgM-FeAnu3kNQtWyVWjNXmtbBERe6-d6Sdn9MFHOmZD6TcYvOa-1M-V4L29MoTfXDeGslKoiowEKP5k5mpgQEqheHMz9lEPW3mlrQUxs3j4a6Hcv31GdtPwaNZ4ZkgKuofAWrzB9bH0VpcHJACjgmW7pzU-Du_O0m6uyvTraIKfoclSBQGM0WympJcLbRPYVFnuoZl2-vwu4dYY0MxL6PW_PeOAfNTDRq9JkxDSeWGlDWjtrZrGqg3w_PWJesDqXYpgmFwePHY9CRIpEJAXF_cA10amDOQG5LHn-KXOns57kMKfkAFPo0XKonjSyc0UuEtBGXNr02qpUHeOAK5-eu348WCkNIJ61whV4PB6A0OPJ3gRl2g-K9csJZi3-5TFmRgvPl9IDITeeiQ9xh2r8BuI5j9wfK8DBKiW185WYeePllCD4U=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G67epNhqUpGDP0n15OaJx5TngkrbXPCUdLZ27PboeNu948DsMwChtIZTQfDJ4gLPtx5tEWjZ2Qp1mVKf50D0EhdeHLBnQ_6AlKq_0vO3_PFGBShjdPDOT5x_lCuUYwc07mjTz181cTVTBvhSPF79ZM4Xwe_4cTTaW3vZOblR4rBsmJ5kLHeIDOJp-HLEcUmTseEja5L9xKp2jwnhVbrn_sAaGUNxgW9UBlsdBDcD_FwShA7kxufX6Eo8pzSQ3oBJm8wz8tnYYXRl5e7F7Hrj-_ssOqQppv5dI2I0WE8XniP7yccpQ4gUv8tO6CbMtQEANjriob0l4q1HJG1iabTbJJE7GzUc2kDQFW6ZILT4S-EpSJ4KVTP3-fHec9IxhE4_REJ3_M-6YLfJPtuiS2_O5ya8QpzwMdNHRRhSLmlPP7GJ5x09pfsr6JBnYFCTz3Wu6AFu3XkKm9dtwKiiVd0cgMQU__l-KIbVZCtOtnPnT6O7yF4yJogkEv5ZXMtO5UwDIWfdFviCYHYyo6bu5O7L0HJAcGjST-_T1s-d9Xe1SDw4hZ-HylIasy8HqVNMVmdRtNBF3n5EwfhBgJlY0Bfa9w1z2MYDwu2s-JtJKg05kWYX-vaijdP4Abtf32WgwQMAf5yN4_OmEgMKevUkt6e7HcYxJ4ZCDpJ4Yel9g4Sp3tTF3M7P2PjPoLB-7K-HHQs20YmftVZDg4D3dN2Qh57d6KKrizqf8Ng9W6ItZbl9eH2B2MXtE3QzYZvs5b5H5Ppi4E4zIMu2svA9sXDdCOSI5NIZAt3vXfwrGTdLO9XhOcCAjPVLr9VFJlXLeyAPgM-FeAnu3kNQtWyVWjNXmtbBERe6-d6Sdn9MFHOmZD6TcYvOa-1M-V4L29MoTfXDeGslKoiowEKP5k5mpgQEqheHMz9lEPW3mlrQUxs3j4a6Hcv31GdtPwaNZ4ZkgKuofAWrzB9bH0VpcHJACjgmW7pzU-Du_O0m6uyvTraIKfoclSBQGM0WympJcLbRPYVFnuoZl2-vwu4dYY0MxL6PW_PeOAfNTDRq9JkxDSeWGlDWjtrZrGqg3w_PWJesDqXYpgmFwePHY9CRIpEJAXF_cA10amDOQG5LHn-KXOns57kMKfkAFPo0XKonjSyc0UuEtBGXNr02qpUHeOAK5-eu348WCkNIJ61whV4PB6A0OPJ3gRl2g-K9csJZi3-5TFmRgvPl9IDITeeiQ9xh2r8BuI5j9wfK8DBKiW185WYeePllCD4U=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G67epNhqUpGDP0n15OaJx5TngkrbXPCUdLZ27PboeNu948DsMwChtIZTQfDJ4gLPtx5tEWjZ2Qp1mVKf50D0EhdeHLBnQ_6AlKq_0vO3_PFGBShjdPDOT5x_lCuUYwc07mjTz181cTVTBvhSPF79ZM4Xwe_4cTTaW3vZOblR4rBsmJ5kLHeIDOJp-HLEcUmTseEja5L9xKp2jwnhVbrn_sAaGUNxgW9UBlsdBDcD_FwShA7kxufX6Eo8pzSQ3oBJm8wz8tnYYXRl5e7F7Hrj-_ssOqQppv5dI2I0WE8XniP7yccpQ4gUv8tO6CbMtQEANjriob0l4q1HJG1iabTbJJE7GzUc2kDQFW6ZILT4S-EpSJ4KVTP3-fHec9IxhE4_REJ3_M-6YLfJPtuiS2_O5ya8QpzwMdNHRRhSLmlPP7GJ5x09pfsr6JBnYFCTz3Wu6AFu3XkKm9dtwKiiVd0cgMQU__l-KIbVZCtOtnPnT6O7yF4yJogkEv5ZXMtO5UwDIWfdFviCYHYyo6bu5O7L0HJAcGjST-_T1s-d9Xe1SDw4hZ-HylIasy8HqVNMVmdRtNBF3n5EwfhBgJlY0Bfa9w1z2MYDwu2s-JtJKg05kWYX-vaijdP4Abtf32WgwQMAf5yN4_OmEgMKevUkt6e7HcYxJ4ZCDpJ4Yel9g4Sp3tTF3M7P2PjPoLB-7K-HHQs20YmftVZDg4D3dN2Qh57d6KKrizqf8Ng9W6ItZbl9eH2B2MXtE3QzYZvs5b5H5Ppi4E4zIMu2svA9sXDdCOSI5NIZAt3vXfwrGTdLO9XhOcCAjPVLr9VFJlXLeyAPgM-FeAnu3kNQtWyVWjNXmtbBERe6-d6Sdn9MFHOmZD6TcYvOa-1M-V4L29MoTfXDeGslKoiowEKP5k5mpgQEqheHMz9lEPW3mlrQUxs3j4a6Hcv31GdtPwaNZ4ZkgKuofAWrzB9bH0VpcHJACjgmW7pzU-Du_O0m6uyvTraIKfoclSBQGM0WympJcLbRPYVFnuoZl2-vwu4dYY0MxL6PW_PeOAfNTDRq9JkxDSeWGlDWjtrZrGqg3w_PWJesDqXYpgmFwePHY9CRIpEJAXF_cA10amDOQG5LHn-KXOns57kMKfkAFPo0XKonjSyc0UuEtBGXNr02qpUHeOAK5-eu348WCkNIJ61whV4PB6A0OPJ3gRl2g-K9csJZi3-5TFmRgvPl9IDITeeiQ9xh2r8BuI5j9wfK8DBKiW185WYeePllCD4U=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1202.87,
            "totalPrice": 957
          },
          {
            "allPassengerTotalPrice": 961,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxHoHZsGWjKTfbuePCH3DM6P4pVBpcOo2ITUQPN9iA2qtUjWnfHVhbLwTooDoCWS-Gh_0jJFP_sYy09OJoDfjLtGReoAb3cQu2Kx8UP9a2J1fZM9JmZQiRAHEdZDM4sIBBKv53bpkYoaNQrl8OULn_eSrtg3zMe46a98MhPoAoaKsgCJo5LCi4O8gKilZtvaetE_uVuDc813Pv6w0Mn2YO10w-y5vd5hY3OIU3GZ76lxHtuQL2mOSd6Ff-kO5ivIEaprBLGYtPMIS9Mpb0WXKKeEDw4CWAW_zaENUCwRIHhrFJbvdrOaquzkiRBzAQITrW-0vrCtBMvdU9uNatn1UZIWuo3xiRBhEgJZ43DoDGshK6yok6YHyNHyj1Ja9sFZc7qSOxZnf8P56GlJrmThCvcfyTyM4Y_9DgWPDjheAYF1P8xv1uU2HM2VyJ_FX_T5aO3Ymzs4EWNJs9BzqnpBB5E7Y2VeL42NyU0NkQSIETvLTRTs17KLdhS_GyunHFj1usTVnTOtVlokVGQGXMATqdEVxJQWyb90XyrWlkUG7xsjdquIvzoijLXy6_6_YQMpqHT8njqCqy0-kKp4sSFZaTMbBESZv6N7hRfBXLjJ5Z7qwYeKlJUKIHpTtbZu-drjqWowunhSZEa_QDxHMspXnLevMb_UamDmnn1QezCWXqUlNqWx2OVNOVxtGDY-0r_fkxdIfUIg5gMQVTP6Vn2Um8JtUrDvbg4UVGXdlYqwLf9P5lxMYLttYsohZytG9SWSJHYJzVndXt3ZectGm0rS2CQOZu4PQ6fF78Co7dCiaTkMuPsAmhHJimjWIMppmV6xNqe-Jyj78FU1u6bwvvQP7z1EfR8BIuOoSDzugiwnWQ9-wQJoFs9I9lCKnEyPAaxZ0TZZ1FtFLUqVE0DZ7T6Lv-WuYFvcVKIKl3fGrKE5DZP45GZNfFUMIiyJJds0-3Nrbl20_7aKxpkuPNZqLMzAxvwhZWNLREIzjNaDqPSLyRCLfYyiF3uli5yFA07xCMS-Cg1H4sfILcezehHRw4sgDhnh3HDLtAHbWIf9wHNFkD_koOxDlQ_SqSRVHL-GA0BLYoZeuQ3N3Wo10cOWlKXplFCu-JwWK9XY00myjo4VZA6-C2LhYKI4YdjMfM5x4ugAuE4Z3L3U4FbHQ7pp7J07dLORqwQhm1Kd_e8z3OeZ-SANOkumDHBdtLnDG-cMXGfzvj4MBY-tmCVzRci2uTeGMzszGGdROmHzP2pGKgCLnSKL1JtzEr_A3FGyyVJInhymi",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxHoHZsGWjKTfbuePCH3DM6P4pVBpcOo2ITUQPN9iA2qtUjWnfHVhbLwTooDoCWS-Gh_0jJFP_sYy09OJoDfjLtGReoAb3cQu2Kx8UP9a2J1fZM9JmZQiRAHEdZDM4sIBBKv53bpkYoaNQrl8OULn_eSrtg3zMe46a98MhPoAoaKsgCJo5LCi4O8gKilZtvaetE_uVuDc813Pv6w0Mn2YO10w-y5vd5hY3OIU3GZ76lxHtuQL2mOSd6Ff-kO5ivIEaprBLGYtPMIS9Mpb0WXKKeEDw4CWAW_zaENUCwRIHhrFJbvdrOaquzkiRBzAQITrW-0vrCtBMvdU9uNatn1UZIWuo3xiRBhEgJZ43DoDGshK6yok6YHyNHyj1Ja9sFZc7qSOxZnf8P56GlJrmThCvcfyTyM4Y_9DgWPDjheAYF1P8xv1uU2HM2VyJ_FX_T5aO3Ymzs4EWNJs9BzqnpBB5E7Y2VeL42NyU0NkQSIETvLTRTs17KLdhS_GyunHFj1usTVnTOtVlokVGQGXMATqdEVxJQWyb90XyrWlkUG7xsjdquIvzoijLXy6_6_YQMpqHT8njqCqy0-kKp4sSFZaTMbBESZv6N7hRfBXLjJ5Z7qwYeKlJUKIHpTtbZu-drjqWowunhSZEa_QDxHMspXnLevMb_UamDmnn1QezCWXqUlNqWx2OVNOVxtGDY-0r_fkxdIfUIg5gMQVTP6Vn2Um8JtUrDvbg4UVGXdlYqwLf9P5lxMYLttYsohZytG9SWSJHYJzVndXt3ZectGm0rS2CQOZu4PQ6fF78Co7dCiaTkMuPsAmhHJimjWIMppmV6xNqe-Jyj78FU1u6bwvvQP7z1EfR8BIuOoSDzugiwnWQ9-wQJoFs9I9lCKnEyPAaxZ0TZZ1FtFLUqVE0DZ7T6Lv-WuYFvcVKIKl3fGrKE5DZP45GZNfFUMIiyJJds0-3Nrbl20_7aKxpkuPNZqLMzAxvwhZWNLREIzjNaDqPSLyRCLfYyiF3uli5yFA07xCMS-Cg1H4sfILcezehHRw4sgDhnh3HDLtAHbWIf9wHNFkD_koOxDlQ_SqSRVHL-GA0BLYoZeuQ3N3Wo10cOWlKXplFCu-JwWK9XY00myjo4VZA6-C2LhYKI4YdjMfM5x4ugAuE4Z3L3U4FbHQ7pp7J07dLORqwQhm1Kd_e8z3OeZ-SANOkumDHBdtLnDG-cMXGfzvj4MBY-tmCVzRci2uTeGMzszGGdROmHzP2pGKgCLnSKL1JtzEr_A3FGyyVJInhymi",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxHoHZsGWjKTfbuePCH3DM6P4pVBpcOo2ITUQPN9iA2qtUjWnfHVhbLwTooDoCWS-Gh_0jJFP_sYy09OJoDfjLtGReoAb3cQu2Kx8UP9a2J1fZM9JmZQiRAHEdZDM4sIBBKv53bpkYoaNQrl8OULn_eSrtg3zMe46a98MhPoAoaKsgCJo5LCi4O8gKilZtvaetE_uVuDc813Pv6w0Mn2YO10w-y5vd5hY3OIU3GZ76lxHtuQL2mOSd6Ff-kO5ivIEaprBLGYtPMIS9Mpb0WXKKeEDw4CWAW_zaENUCwRIHhrFJbvdrOaquzkiRBzAQITrW-0vrCtBMvdU9uNatn1UZIWuo3xiRBhEgJZ43DoDGshK6yok6YHyNHyj1Ja9sFZc7qSOxZnf8P56GlJrmThCvcfyTyM4Y_9DgWPDjheAYF1P8xv1uU2HM2VyJ_FX_T5aO3Ymzs4EWNJs9BzqnpBB5E7Y2VeL42NyU0NkQSIETvLTRTs17KLdhS_GyunHFj1usTVnTOtVlokVGQGXMATqdEVxJQWyb90XyrWlkUG7xsjdquIvzoijLXy6_6_YQMpqHT8njqCqy0-kKp4sSFZaTMbBESZv6N7hRfBXLjJ5Z7qwYeKlJUKIHpTtbZu-drjqWowunhSZEa_QDxHMspXnLevMb_UamDmnn1QezCWXqUlNqWx2OVNOVxtGDY-0r_fkxdIfUIg5gMQVTP6Vn2Um8JtUrDvbg4UVGXdlYqwLf9P5lxMYLttYsohZytG9SWSJHYJzVndXt3ZectGm0rS2CQOZu4PQ6fF78Co7dCiaTkMuPsAmhHJimjWIMppmV6xNqe-Jyj78FU1u6bwvvQP7z1EfR8BIuOoSDzugiwnWQ9-wQJoFs9I9lCKnEyPAaxZ0TZZ1FtFLUqVE0DZ7T6Lv-WuYFvcVKIKl3fGrKE5DZP45GZNfFUMIiyJJds0-3Nrbl20_7aKxpkuPNZqLMzAxvwhZWNLREIzjNaDqPSLyRCLfYyiF3uli5yFA07xCMS-Cg1H4sfILcezehHRw4sgDhnh3HDLtAHbWIf9wHNFkD_koOxDlQ_SqSRVHL-GA0BLYoZeuQ3N3Wo10cOWlKXplFCu-JwWK9XY00myjo4VZA6-C2LhYKI4YdjMfM5x4ugAuE4Z3L3U4FbHQ7pp7J07dLORqwQhm1Kd_e8z3OeZ-SANOkumDHBdtLnDG-cMXGfzvj4MBY-tmCVzRci2uTeGMzszGGdROmHzP2pGKgCLnSKL1JtzEr_A3FGyyVJInhymi",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1204.65,
            "totalPrice": 961
          },
          {
            "allPassengerTotalPrice": 961,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Go1bsD3Iv_yl-LMYAd2XP4cyaA41HOTVn4nwuLum8aEmoFWhNcoLjHcuukIosu1BsC_Zl066O1-g4-97XwaJ8BMceR6QgJhpN_jGf4M3cH0_dK77_sNKHk_QXrKUWo9K7OgrGtZlbgke5Cxp7eHs1wjZQgyzSNj6DCDvCkk3TuuEZYf0IYvPR-jp3vHZoCRkntVngU3h06Sx9oQI-bwEMLk6W6NW5UmKI8wkl_zl56KVHmzvnHwIAljSu0Iim2g8Xo5Pn5taGQ96sCl_liFG-4doJAXtYnXN7en-8OLH49r_t-zt0caKslDHo6smhswy44Dakq7EnV3uvd4qAN8PW2PWcOeqjWXiKkIbgAdsH8trH5pH03g2ypjScudW3U8xIMi9Qruy1xnZEeJFCTkDKxJUqgVL-J0VxMXEbXCovcvXb8eXTZ0BxUsj2QGySfMhPVRtSoBgxABxR8MArjqV8GgoNetnc3_lqt0dnKhqW8RdYUUQK_shq2vLXmKUgPCyA7kTBNoWnoZAwwqoFOPb183LKpDTfzRNPOPIonaAvNcjN7Zqi-nKAzCe9RSsqjkj71AehoFdAX1QzqOXXpwz4i3458KvtlrADGM4GDKVmD7lyqq25yeD3w0ca_ofZoXGdDkxzmpNZ3beND2IJO_wcSAi7dWK75nIk3Q3lbNWhaUNkOsYE9GHMOVgUwUa3OXIZefjkI-sVBIAT8FrXt8jbtDJ4j3UqEnNbnc1sqBmJOYqf6qCuxm56vZrWNng-0YrLoTMDIqfkvsUB4pfbKQjMqpB6hnfDc-RRFkIgvu22ezHKPFNU0UIKJJbqW_3d7dWq_HIHqygSPWdd7ctgq1SPaQUmR_HNQvC37oI_32Woon3EVa61a1egPxHoUb8e2e0FgikWBdqDZO2F1QjuXVTxMBERdwlF3jL9r56E3B_IErcKgKi5d40w3Xp9CmqM9QXIFhuF9Y6N-6s4rNb6PhYrntXj-RfiguaYfYOkasUpBU6VDPS7k86FQ0dAK8d7iyQziwW3gaeqyT6xirQFhR3nhtbeklUlWcImE9b-A3A67yo5vhQy4kmQu0z5-fX1hpX751XD1BBkzPNVfFIfuJo3lWABc3zoPEQFSfqqHO0esEAAMsr2kq4nSBCnop9ulEkSkjrTtkcRIHofxjeszkCaNYltX0rvdCaBJEZzsOJ8gH9V0MmaWZXzgVHLvIlr4wMxI0-tQKZ9hk5ienh6BcC_zul_VrFd50gmPj20XVfqqfqm2tFNxNxsatVWtFDY4bhh",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Go1bsD3Iv_yl-LMYAd2XP4cyaA41HOTVn4nwuLum8aEmoFWhNcoLjHcuukIosu1BsC_Zl066O1-g4-97XwaJ8BMceR6QgJhpN_jGf4M3cH0_dK77_sNKHk_QXrKUWo9K7OgrGtZlbgke5Cxp7eHs1wjZQgyzSNj6DCDvCkk3TuuEZYf0IYvPR-jp3vHZoCRkntVngU3h06Sx9oQI-bwEMLk6W6NW5UmKI8wkl_zl56KVHmzvnHwIAljSu0Iim2g8Xo5Pn5taGQ96sCl_liFG-4doJAXtYnXN7en-8OLH49r_t-zt0caKslDHo6smhswy44Dakq7EnV3uvd4qAN8PW2PWcOeqjWXiKkIbgAdsH8trH5pH03g2ypjScudW3U8xIMi9Qruy1xnZEeJFCTkDKxJUqgVL-J0VxMXEbXCovcvXb8eXTZ0BxUsj2QGySfMhPVRtSoBgxABxR8MArjqV8GgoNetnc3_lqt0dnKhqW8RdYUUQK_shq2vLXmKUgPCyA7kTBNoWnoZAwwqoFOPb183LKpDTfzRNPOPIonaAvNcjN7Zqi-nKAzCe9RSsqjkj71AehoFdAX1QzqOXXpwz4i3458KvtlrADGM4GDKVmD7lyqq25yeD3w0ca_ofZoXGdDkxzmpNZ3beND2IJO_wcSAi7dWK75nIk3Q3lbNWhaUNkOsYE9GHMOVgUwUa3OXIZefjkI-sVBIAT8FrXt8jbtDJ4j3UqEnNbnc1sqBmJOYqf6qCuxm56vZrWNng-0YrLoTMDIqfkvsUB4pfbKQjMqpB6hnfDc-RRFkIgvu22ezHKPFNU0UIKJJbqW_3d7dWq_HIHqygSPWdd7ctgq1SPaQUmR_HNQvC37oI_32Woon3EVa61a1egPxHoUb8e2e0FgikWBdqDZO2F1QjuXVTxMBERdwlF3jL9r56E3B_IErcKgKi5d40w3Xp9CmqM9QXIFhuF9Y6N-6s4rNb6PhYrntXj-RfiguaYfYOkasUpBU6VDPS7k86FQ0dAK8d7iyQziwW3gaeqyT6xirQFhR3nhtbeklUlWcImE9b-A3A67yo5vhQy4kmQu0z5-fX1hpX751XD1BBkzPNVfFIfuJo3lWABc3zoPEQFSfqqHO0esEAAMsr2kq4nSBCnop9ulEkSkjrTtkcRIHofxjeszkCaNYltX0rvdCaBJEZzsOJ8gH9V0MmaWZXzgVHLvIlr4wMxI0-tQKZ9hk5ienh6BcC_zul_VrFd50gmPj20XVfqqfqm2tFNxNxsatVWtFDY4bhh",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Go1bsD3Iv_yl-LMYAd2XP4cyaA41HOTVn4nwuLum8aEmoFWhNcoLjHcuukIosu1BsC_Zl066O1-g4-97XwaJ8BMceR6QgJhpN_jGf4M3cH0_dK77_sNKHk_QXrKUWo9K7OgrGtZlbgke5Cxp7eHs1wjZQgyzSNj6DCDvCkk3TuuEZYf0IYvPR-jp3vHZoCRkntVngU3h06Sx9oQI-bwEMLk6W6NW5UmKI8wkl_zl56KVHmzvnHwIAljSu0Iim2g8Xo5Pn5taGQ96sCl_liFG-4doJAXtYnXN7en-8OLH49r_t-zt0caKslDHo6smhswy44Dakq7EnV3uvd4qAN8PW2PWcOeqjWXiKkIbgAdsH8trH5pH03g2ypjScudW3U8xIMi9Qruy1xnZEeJFCTkDKxJUqgVL-J0VxMXEbXCovcvXb8eXTZ0BxUsj2QGySfMhPVRtSoBgxABxR8MArjqV8GgoNetnc3_lqt0dnKhqW8RdYUUQK_shq2vLXmKUgPCyA7kTBNoWnoZAwwqoFOPb183LKpDTfzRNPOPIonaAvNcjN7Zqi-nKAzCe9RSsqjkj71AehoFdAX1QzqOXXpwz4i3458KvtlrADGM4GDKVmD7lyqq25yeD3w0ca_ofZoXGdDkxzmpNZ3beND2IJO_wcSAi7dWK75nIk3Q3lbNWhaUNkOsYE9GHMOVgUwUa3OXIZefjkI-sVBIAT8FrXt8jbtDJ4j3UqEnNbnc1sqBmJOYqf6qCuxm56vZrWNng-0YrLoTMDIqfkvsUB4pfbKQjMqpB6hnfDc-RRFkIgvu22ezHKPFNU0UIKJJbqW_3d7dWq_HIHqygSPWdd7ctgq1SPaQUmR_HNQvC37oI_32Woon3EVa61a1egPxHoUb8e2e0FgikWBdqDZO2F1QjuXVTxMBERdwlF3jL9r56E3B_IErcKgKi5d40w3Xp9CmqM9QXIFhuF9Y6N-6s4rNb6PhYrntXj-RfiguaYfYOkasUpBU6VDPS7k86FQ0dAK8d7iyQziwW3gaeqyT6xirQFhR3nhtbeklUlWcImE9b-A3A67yo5vhQy4kmQu0z5-fX1hpX751XD1BBkzPNVfFIfuJo3lWABc3zoPEQFSfqqHO0esEAAMsr2kq4nSBCnop9ulEkSkjrTtkcRIHofxjeszkCaNYltX0rvdCaBJEZzsOJ8gH9V0MmaWZXzgVHLvIlr4wMxI0-tQKZ9hk5ienh6BcC_zul_VrFd50gmPj20XVfqqfqm2tFNxNxsatVWtFDY4bhh",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1205.32,
            "totalPrice": 961
          },
          {
            "allPassengerTotalPrice": 962,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsfTX5hp-e4nozFocQi9CJk242rzsgEN9yYGrNm4-2i2RbZ4uikVYSWTwvZHmRlBKaXbPGLXC5zNlGqYcEv5BI-nPUKwd1eZHUf2gt7ksRsvbVtngt6zVFnDDqLb9wHcLc8vy-Av_vmU0iCYB7aqF0Y2tLOCjYXEZZwz3IFa4PrVWho-sb8NKg2LebzUc298B31wFjLjzQVnV_CTdkseYXiurguaoeQtzK2u1WFP0_WDqZBMWHKL90-3KTr1VEAwniwzCM0B7k2_i0NErbJN1Um-2WSwQc6eksmFRLT_Z-jJdVkIgB8CY1YGUExv25n-4N9IDRABy8hhnLfCjv6M55lnh0JKG5PNhJDl7zk6OHYblh7AlIZ9lbjGgVa99bgPIa5vLQQv4R7BypcapKjzDOkozRRWo-e5jAe6YjzKBxPwm9e7-sCs_MgKRri3n0aK9xqBu6umyANPo-N8xNCtAfgJ4X34BOswkLo-f7tdMFLUKtb5xl6AkfOUMm5c71vzxAn_W14elcppEjgKHsJUy5k2Hh9KnhYiv4PjwA-50A6gcvbrkS3EC36P_pq_y9T90VUb5qAUIlVpkxbbEmlnbXbeeRwEVYAcrBDOSlwU5ylQ7mY0tV5eG6Kih1sTyEAqPCRhWF2JpwvWYh2oF_qzhgUczqtK6C65jLv0aMCpMq0RIsUSTEhDOMXbIAdorB7I0zjnxuYtz9Sb5GZ4Qe1uhy7Pms1BH3rjNOaFBH9sGNL7a5mzVKE_Bl8P3MwK2Lp5NdhBhYa1RZ0sLKKdDYHxxt4scLpa8T4GNWvp6hxlBjTEpZTUjktsg1atgduyXn8GVwlHZ8pFe1TGvoIpi8u5HUwbQhlfb-LkjBgpw2f0XNWtS7YME8jEhwpe-bIw4f5LsVYAmuXZvm9MY62gdLdxx3DLMvBqWKa2E22uBycncEWi_bWPV2rKZbbkSmqjbTYfGs29SSg1LpQOu7Ppp9-3B5wkvrKje_GyFHynHxkfOEWUnY8fkFBGNMIztg9J5OMg4J_R0yWMwzavrFNUixNZufU_aiXYWHgfJzZUaNZxEyBXhOJZ5y98jt1iPfnX666cxRJoZKeLY5on5FpLwGGG88glo1Gw7mc2Vn8J9ShDRaV8ym7JLhLCjKotZclGXqJBf3qKEqmxAWlwm3towUkMW3Tt_lc8YcF6Gf1YBKE0rkaG7H1uv5fZDmJMaJFibY8WcSDnNdSmOECt7rACexCGh5YI5-haCAEs-NpW0Ak4AUV-wkmW1AiUgRa1QSrzGbRBHyE2jhDeSauMxHD7PfzIBFN32uzWGSUgKxRyZzc-L1lrcbWqrcKwObccutQSW8lgrwfRsm84-sQmtINzoEdNLBh0YVjOzBLK9n6C1LMIfk9A=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsfTX5hp-e4nozFocQi9CJk242rzsgEN9yYGrNm4-2i2RbZ4uikVYSWTwvZHmRlBKaXbPGLXC5zNlGqYcEv5BI-nPUKwd1eZHUf2gt7ksRsvbVtngt6zVFnDDqLb9wHcLc8vy-Av_vmU0iCYB7aqF0Y2tLOCjYXEZZwz3IFa4PrVWho-sb8NKg2LebzUc298B31wFjLjzQVnV_CTdkseYXiurguaoeQtzK2u1WFP0_WDqZBMWHKL90-3KTr1VEAwniwzCM0B7k2_i0NErbJN1Um-2WSwQc6eksmFRLT_Z-jJdVkIgB8CY1YGUExv25n-4N9IDRABy8hhnLfCjv6M55lnh0JKG5PNhJDl7zk6OHYblh7AlIZ9lbjGgVa99bgPIa5vLQQv4R7BypcapKjzDOkozRRWo-e5jAe6YjzKBxPwm9e7-sCs_MgKRri3n0aK9xqBu6umyANPo-N8xNCtAfgJ4X34BOswkLo-f7tdMFLUKtb5xl6AkfOUMm5c71vzxAn_W14elcppEjgKHsJUy5k2Hh9KnhYiv4PjwA-50A6gcvbrkS3EC36P_pq_y9T90VUb5qAUIlVpkxbbEmlnbXbeeRwEVYAcrBDOSlwU5ylQ7mY0tV5eG6Kih1sTyEAqPCRhWF2JpwvWYh2oF_qzhgUczqtK6C65jLv0aMCpMq0RIsUSTEhDOMXbIAdorB7I0zjnxuYtz9Sb5GZ4Qe1uhy7Pms1BH3rjNOaFBH9sGNL7a5mzVKE_Bl8P3MwK2Lp5NdhBhYa1RZ0sLKKdDYHxxt4scLpa8T4GNWvp6hxlBjTEpZTUjktsg1atgduyXn8GVwlHZ8pFe1TGvoIpi8u5HUwbQhlfb-LkjBgpw2f0XNWtS7YME8jEhwpe-bIw4f5LsVYAmuXZvm9MY62gdLdxx3DLMvBqWKa2E22uBycncEWi_bWPV2rKZbbkSmqjbTYfGs29SSg1LpQOu7Ppp9-3B5wkvrKje_GyFHynHxkfOEWUnY8fkFBGNMIztg9J5OMg4J_R0yWMwzavrFNUixNZufU_aiXYWHgfJzZUaNZxEyBXhOJZ5y98jt1iPfnX666cxRJoZKeLY5on5FpLwGGG88glo1Gw7mc2Vn8J9ShDRaV8ym7JLhLCjKotZclGXqJBf3qKEqmxAWlwm3towUkMW3Tt_lc8YcF6Gf1YBKE0rkaG7H1uv5fZDmJMaJFibY8WcSDnNdSmOECt7rACexCGh5YI5-haCAEs-NpW0Ak4AUV-wkmW1AiUgRa1QSrzGbRBHyE2jhDeSauMxHD7PfzIBFN32uzWGSUgKxRyZzc-L1lrcbWqrcKwObccutQSW8lgrwfRsm84-sQmtINzoEdNLBh0YVjOzBLK9n6C1LMIfk9A=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsfTX5hp-e4nozFocQi9CJk242rzsgEN9yYGrNm4-2i2RbZ4uikVYSWTwvZHmRlBKaXbPGLXC5zNlGqYcEv5BI-nPUKwd1eZHUf2gt7ksRsvbVtngt6zVFnDDqLb9wHcLc8vy-Av_vmU0iCYB7aqF0Y2tLOCjYXEZZwz3IFa4PrVWho-sb8NKg2LebzUc298B31wFjLjzQVnV_CTdkseYXiurguaoeQtzK2u1WFP0_WDqZBMWHKL90-3KTr1VEAwniwzCM0B7k2_i0NErbJN1Um-2WSwQc6eksmFRLT_Z-jJdVkIgB8CY1YGUExv25n-4N9IDRABy8hhnLfCjv6M55lnh0JKG5PNhJDl7zk6OHYblh7AlIZ9lbjGgVa99bgPIa5vLQQv4R7BypcapKjzDOkozRRWo-e5jAe6YjzKBxPwm9e7-sCs_MgKRri3n0aK9xqBu6umyANPo-N8xNCtAfgJ4X34BOswkLo-f7tdMFLUKtb5xl6AkfOUMm5c71vzxAn_W14elcppEjgKHsJUy5k2Hh9KnhYiv4PjwA-50A6gcvbrkS3EC36P_pq_y9T90VUb5qAUIlVpkxbbEmlnbXbeeRwEVYAcrBDOSlwU5ylQ7mY0tV5eG6Kih1sTyEAqPCRhWF2JpwvWYh2oF_qzhgUczqtK6C65jLv0aMCpMq0RIsUSTEhDOMXbIAdorB7I0zjnxuYtz9Sb5GZ4Qe1uhy7Pms1BH3rjNOaFBH9sGNL7a5mzVKE_Bl8P3MwK2Lp5NdhBhYa1RZ0sLKKdDYHxxt4scLpa8T4GNWvp6hxlBjTEpZTUjktsg1atgduyXn8GVwlHZ8pFe1TGvoIpi8u5HUwbQhlfb-LkjBgpw2f0XNWtS7YME8jEhwpe-bIw4f5LsVYAmuXZvm9MY62gdLdxx3DLMvBqWKa2E22uBycncEWi_bWPV2rKZbbkSmqjbTYfGs29SSg1LpQOu7Ppp9-3B5wkvrKje_GyFHynHxkfOEWUnY8fkFBGNMIztg9J5OMg4J_R0yWMwzavrFNUixNZufU_aiXYWHgfJzZUaNZxEyBXhOJZ5y98jt1iPfnX666cxRJoZKeLY5on5FpLwGGG88glo1Gw7mc2Vn8J9ShDRaV8ym7JLhLCjKotZclGXqJBf3qKEqmxAWlwm3towUkMW3Tt_lc8YcF6Gf1YBKE0rkaG7H1uv5fZDmJMaJFibY8WcSDnNdSmOECt7rACexCGh5YI5-haCAEs-NpW0Ak4AUV-wkmW1AiUgRa1QSrzGbRBHyE2jhDeSauMxHD7PfzIBFN32uzWGSUgKxRyZzc-L1lrcbWqrcKwObccutQSW8lgrwfRsm84-sQmtINzoEdNLBh0YVjOzBLK9n6C1LMIfk9A=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1199.95,
            "totalPrice": 962
          },
          {
            "allPassengerTotalPrice": 962,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH3esQqMep7QDwEjkJnQ7bEHWGCAXWw01yTnAc8vxu7p3gk8xAr6p8sNeUbzna1IP0CoVEN8rgLnRB6gARCjUKX1bdJj2IhkJ8QtIGErIQBXN89z4zELiGFkwoRWvXfUCZ-PAHd4eulYgj6LkC3q05t26LsLp0qZG04KDmOrbSadRTgRrLkELO3-a8nXE59tTN_AM8KQGrDR4eos5DzVgNiCfAgafDrSzZbcrF5q4yZjfN_iScb2fcupH5p6cn2eet_je-G46IOF6NHHYrwC_oSSx4Pwd8PCS60Q0l4bQrmCqrmBfXvvgfl2K8Xjt25_v1HMMaUyjhi2v1xOnjhlIOzrQNWmfPi5biKlXtQZ5Xv8Vgsm4E3O5GGz3WoUxL6Zb-Q8Y4MLFUuLVq-zHoZT8cRyR94R736h4vpqm95PaHbT8kY8-07mRZZg5YWrfC7zOTr3bWk9ytn7NOsBJh6I15ANnM3unRxCsqi2DnWHopruvOAWMEHwS9LcTO5ZU9QeUtGVmKttaHl1Nwr_5skD50TmXG1zVrqlN0ebX-ldRqH6S_QUvmawZ-h0R6mqgFWQlVso8v5IsPYsdhkpVOdkLcbJKIDettXI1Qt_DXG5N53zj-M-64GBS9wMFFuOh3joe7GCvLfDI5a0E0GuN6MynSSHKNPmwnIH3G-aEEUexM7wuFHO1n2-CqxREZSUvJj3ph3RIEO04rPEDZAMCwMEZIiZNCJBYlH2asdigrXiYV9knvrI4v1Ab_g5MfRhM5GCEq1ptCeD_MNw1w4v9zaplZpI-CSp1wRKqhVs5wjXTz8zIqbSFNetcpSalgRNEhmp3GyHTZH9M4YHE4lR7it2fatJy1bEcIGBH-fryZEeYkdsbS9PM0mEipkPZtmFTVqRoZhBcAb4WMfoqH8iqKdKp9La1-Hi3fY3_XsHbWy_ohNwChGjS_8dwbyR5-1DNYAdRvbDSztFsuRABV4nL7uEJqmNtmndK_RlkQO0Aio_vCgvK6Gp4Ky2xj_uXS2It9lymrVIknUpav7uS4VxXKxfTa6gmhaMrQzz510bqAHw-rVFtyrQZENi2-EyWyf1A_pju2G4KSYXnGherihQF2TaN6s-nhu9QWZjO0Vw9j1TvgFfgYSRVHqNA2XmJ91JzWTWEvfUhAaTJNY-P8goDxlYWlLfD6YC7d4cPNc1w8HUL0vxOJL5reXgWTcwEb6mK9DAqwBd4-GdTRIsAeJQVHSjDbeuiOqQvdMvMGTaPiVX_g3BOIOLQ2SFnJDdy4EZonQyKpyPMwZaA3XSYuNS4k9DSoQSdEjUz4x_yro_f7oH16BBO5jlcWX5KSYPCvk-Oc3pc",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH3esQqMep7QDwEjkJnQ7bEHWGCAXWw01yTnAc8vxu7p3gk8xAr6p8sNeUbzna1IP0CoVEN8rgLnRB6gARCjUKX1bdJj2IhkJ8QtIGErIQBXN89z4zELiGFkwoRWvXfUCZ-PAHd4eulYgj6LkC3q05t26LsLp0qZG04KDmOrbSadRTgRrLkELO3-a8nXE59tTN_AM8KQGrDR4eos5DzVgNiCfAgafDrSzZbcrF5q4yZjfN_iScb2fcupH5p6cn2eet_je-G46IOF6NHHYrwC_oSSx4Pwd8PCS60Q0l4bQrmCqrmBfXvvgfl2K8Xjt25_v1HMMaUyjhi2v1xOnjhlIOzrQNWmfPi5biKlXtQZ5Xv8Vgsm4E3O5GGz3WoUxL6Zb-Q8Y4MLFUuLVq-zHoZT8cRyR94R736h4vpqm95PaHbT8kY8-07mRZZg5YWrfC7zOTr3bWk9ytn7NOsBJh6I15ANnM3unRxCsqi2DnWHopruvOAWMEHwS9LcTO5ZU9QeUtGVmKttaHl1Nwr_5skD50TmXG1zVrqlN0ebX-ldRqH6S_QUvmawZ-h0R6mqgFWQlVso8v5IsPYsdhkpVOdkLcbJKIDettXI1Qt_DXG5N53zj-M-64GBS9wMFFuOh3joe7GCvLfDI5a0E0GuN6MynSSHKNPmwnIH3G-aEEUexM7wuFHO1n2-CqxREZSUvJj3ph3RIEO04rPEDZAMCwMEZIiZNCJBYlH2asdigrXiYV9knvrI4v1Ab_g5MfRhM5GCEq1ptCeD_MNw1w4v9zaplZpI-CSp1wRKqhVs5wjXTz8zIqbSFNetcpSalgRNEhmp3GyHTZH9M4YHE4lR7it2fatJy1bEcIGBH-fryZEeYkdsbS9PM0mEipkPZtmFTVqRoZhBcAb4WMfoqH8iqKdKp9La1-Hi3fY3_XsHbWy_ohNwChGjS_8dwbyR5-1DNYAdRvbDSztFsuRABV4nL7uEJqmNtmndK_RlkQO0Aio_vCgvK6Gp4Ky2xj_uXS2It9lymrVIknUpav7uS4VxXKxfTa6gmhaMrQzz510bqAHw-rVFtyrQZENi2-EyWyf1A_pju2G4KSYXnGherihQF2TaN6s-nhu9QWZjO0Vw9j1TvgFfgYSRVHqNA2XmJ91JzWTWEvfUhAaTJNY-P8goDxlYWlLfD6YC7d4cPNc1w8HUL0vxOJL5reXgWTcwEb6mK9DAqwBd4-GdTRIsAeJQVHSjDbeuiOqQvdMvMGTaPiVX_g3BOIOLQ2SFnJDdy4EZonQyKpyPMwZaA3XSYuNS4k9DSoQSdEjUz4x_yro_f7oH16BBO5jlcWX5KSYPCvk-Oc3pc",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH3esQqMep7QDwEjkJnQ7bEHWGCAXWw01yTnAc8vxu7p3gk8xAr6p8sNeUbzna1IP0CoVEN8rgLnRB6gARCjUKX1bdJj2IhkJ8QtIGErIQBXN89z4zELiGFkwoRWvXfUCZ-PAHd4eulYgj6LkC3q05t26LsLp0qZG04KDmOrbSadRTgRrLkELO3-a8nXE59tTN_AM8KQGrDR4eos5DzVgNiCfAgafDrSzZbcrF5q4yZjfN_iScb2fcupH5p6cn2eet_je-G46IOF6NHHYrwC_oSSx4Pwd8PCS60Q0l4bQrmCqrmBfXvvgfl2K8Xjt25_v1HMMaUyjhi2v1xOnjhlIOzrQNWmfPi5biKlXtQZ5Xv8Vgsm4E3O5GGz3WoUxL6Zb-Q8Y4MLFUuLVq-zHoZT8cRyR94R736h4vpqm95PaHbT8kY8-07mRZZg5YWrfC7zOTr3bWk9ytn7NOsBJh6I15ANnM3unRxCsqi2DnWHopruvOAWMEHwS9LcTO5ZU9QeUtGVmKttaHl1Nwr_5skD50TmXG1zVrqlN0ebX-ldRqH6S_QUvmawZ-h0R6mqgFWQlVso8v5IsPYsdhkpVOdkLcbJKIDettXI1Qt_DXG5N53zj-M-64GBS9wMFFuOh3joe7GCvLfDI5a0E0GuN6MynSSHKNPmwnIH3G-aEEUexM7wuFHO1n2-CqxREZSUvJj3ph3RIEO04rPEDZAMCwMEZIiZNCJBYlH2asdigrXiYV9knvrI4v1Ab_g5MfRhM5GCEq1ptCeD_MNw1w4v9zaplZpI-CSp1wRKqhVs5wjXTz8zIqbSFNetcpSalgRNEhmp3GyHTZH9M4YHE4lR7it2fatJy1bEcIGBH-fryZEeYkdsbS9PM0mEipkPZtmFTVqRoZhBcAb4WMfoqH8iqKdKp9La1-Hi3fY3_XsHbWy_ohNwChGjS_8dwbyR5-1DNYAdRvbDSztFsuRABV4nL7uEJqmNtmndK_RlkQO0Aio_vCgvK6Gp4Ky2xj_uXS2It9lymrVIknUpav7uS4VxXKxfTa6gmhaMrQzz510bqAHw-rVFtyrQZENi2-EyWyf1A_pju2G4KSYXnGherihQF2TaN6s-nhu9QWZjO0Vw9j1TvgFfgYSRVHqNA2XmJ91JzWTWEvfUhAaTJNY-P8goDxlYWlLfD6YC7d4cPNc1w8HUL0vxOJL5reXgWTcwEb6mK9DAqwBd4-GdTRIsAeJQVHSjDbeuiOqQvdMvMGTaPiVX_g3BOIOLQ2SFnJDdy4EZonQyKpyPMwZaA3XSYuNS4k9DSoQSdEjUz4x_yro_f7oH16BBO5jlcWX5KSYPCvk-Oc3pc",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e1d00003d8fdc2f",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e1d00003d8fdc2f_0",
                    "local_arrival": "2024-10-01T23:15:00.000Z",
                    "local_departure": "2024-10-01T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-02T06:15:00.000Z",
                    "utc_departure": "2024-10-02T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1237.28,
            "totalPrice": 962
          },
          {
            "allPassengerTotalPrice": 964,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4j7QVuG7SmeW1_eSXWV7vqyk7Q0XRkyZ67KE2rCPKzYpRlnrMVlbZ0rwdjsX29FWZJ1YkRauWX0yz4iwsGayNcSoHROAzz2l4JUdo222okPMg3mrWRODXhrjjfpLOf4Rc1rIlMX4ywR17r-30-2APSYwths9pcjUTRb1muPTUgyGzJc593NJlLbm-tJf_y3AAUi0ZWMxrPVKrVnZ7YV5lCHxmpQg9ChIr_Lk1Wvp_ItpjvzRIDuquMfSJ19evvBN4W7slsa64OHBxb6sSJ7ydzv132L3Wp_IBWIIc6upd5RaWkF1SNdrOhqpL1wtzuhxqgOkn1dGh9V8NZhdRIKari9fGbBo_EgbStJKab-UFI6I8eekc2U_ocNn2lGKggyN4nGdpLtdFQynvJ_jDipZqFuKQUpXMh71j1AbCuCkRfx9XvwIU3Zy4fd2u849A3CCYyD5_MgwV0qY3-eTnAY6lyQzU377GEt1HbvnYDdpBlQrW2pOdgUUJxpdeqaKAQpO7jX6AjuRDnieGZfoB-cdQPSUf9AsTZ6zfxIGyw4JVg-Hi_LPEACQLqJJ6FeMBylv19nLsWvU6A25As-6mq8MPKtJlJe7Liakpzi6o8NPbB05BZYQKM0VlzFOVnnE84sxiQkxkP8XksYVeUspuAKi68B6937X-Ixrm6xxBHJ0vhhwnxUWeU9Fl1ICjD3m8fa94dWKX0lQYUfuJjH8KPnhuVNj1D1Af5gAHsZYcsP68y_rAk4SpHVJyHAhyCbkeC9mca7sQwGLJDcq0VjQR54K_gB1TZPIy8PWTrCBVNRCuJCSYmxpSZUyqMRmIha6CEOr4sMyLkOGlUXlBJCf6yOmHKxnAYYNKHu2KOQlq8PQUydU5RhNc5_9NE9Gyty4DBjvuYiw0t1ma_m2twEA05IVf9ih5ekpKzcLXFP-4aJOS3j2xzVw8lEVm6qa4BH-JzUEjzmIdt8Vlx2tcAGbeYrIS3Sn3Cla6f9zEaorqXRjI9uQ-BlFDgjjEgnlImyr5iqZU7ttbUfLJzeuQcDbSp7m904i28xGujXb72iQj-alggLbiScG-2D6_NFwWmOoboq6E3uVQJzULZS13Lo823pwnLIUtw1dcoTF4400F55xj8kLyAY409BQL_TqpyONY8u81urRpfViIs13UdVRNAuNi-35Jerdxf2maDh_ipy0GnmxncgsYmsUOof9hWEgnp8HmTjuT_fMd7iJQo9g2EyCBN7FSu8T6HuUPdW5h63SD8rEyX-bEhQoiwDWykxXBeLuUbuv-3ASk-_0R82r_B4BqryrqtdW7pyB9pPzpkurGSdYF7VNIUD3zcK2b25YuSLt9yVucj812f1V3_A9BEjg9gWArdHmdbjiy9v6aRI7dF8=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4j7QVuG7SmeW1_eSXWV7vqyk7Q0XRkyZ67KE2rCPKzYpRlnrMVlbZ0rwdjsX29FWZJ1YkRauWX0yz4iwsGayNcSoHROAzz2l4JUdo222okPMg3mrWRODXhrjjfpLOf4Rc1rIlMX4ywR17r-30-2APSYwths9pcjUTRb1muPTUgyGzJc593NJlLbm-tJf_y3AAUi0ZWMxrPVKrVnZ7YV5lCHxmpQg9ChIr_Lk1Wvp_ItpjvzRIDuquMfSJ19evvBN4W7slsa64OHBxb6sSJ7ydzv132L3Wp_IBWIIc6upd5RaWkF1SNdrOhqpL1wtzuhxqgOkn1dGh9V8NZhdRIKari9fGbBo_EgbStJKab-UFI6I8eekc2U_ocNn2lGKggyN4nGdpLtdFQynvJ_jDipZqFuKQUpXMh71j1AbCuCkRfx9XvwIU3Zy4fd2u849A3CCYyD5_MgwV0qY3-eTnAY6lyQzU377GEt1HbvnYDdpBlQrW2pOdgUUJxpdeqaKAQpO7jX6AjuRDnieGZfoB-cdQPSUf9AsTZ6zfxIGyw4JVg-Hi_LPEACQLqJJ6FeMBylv19nLsWvU6A25As-6mq8MPKtJlJe7Liakpzi6o8NPbB05BZYQKM0VlzFOVnnE84sxiQkxkP8XksYVeUspuAKi68B6937X-Ixrm6xxBHJ0vhhwnxUWeU9Fl1ICjD3m8fa94dWKX0lQYUfuJjH8KPnhuVNj1D1Af5gAHsZYcsP68y_rAk4SpHVJyHAhyCbkeC9mca7sQwGLJDcq0VjQR54K_gB1TZPIy8PWTrCBVNRCuJCSYmxpSZUyqMRmIha6CEOr4sMyLkOGlUXlBJCf6yOmHKxnAYYNKHu2KOQlq8PQUydU5RhNc5_9NE9Gyty4DBjvuYiw0t1ma_m2twEA05IVf9ih5ekpKzcLXFP-4aJOS3j2xzVw8lEVm6qa4BH-JzUEjzmIdt8Vlx2tcAGbeYrIS3Sn3Cla6f9zEaorqXRjI9uQ-BlFDgjjEgnlImyr5iqZU7ttbUfLJzeuQcDbSp7m904i28xGujXb72iQj-alggLbiScG-2D6_NFwWmOoboq6E3uVQJzULZS13Lo823pwnLIUtw1dcoTF4400F55xj8kLyAY409BQL_TqpyONY8u81urRpfViIs13UdVRNAuNi-35Jerdxf2maDh_ipy0GnmxncgsYmsUOof9hWEgnp8HmTjuT_fMd7iJQo9g2EyCBN7FSu8T6HuUPdW5h63SD8rEyX-bEhQoiwDWykxXBeLuUbuv-3ASk-_0R82r_B4BqryrqtdW7pyB9pPzpkurGSdYF7VNIUD3zcK2b25YuSLt9yVucj812f1V3_A9BEjg9gWArdHmdbjiy9v6aRI7dF8=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4j7QVuG7SmeW1_eSXWV7vqyk7Q0XRkyZ67KE2rCPKzYpRlnrMVlbZ0rwdjsX29FWZJ1YkRauWX0yz4iwsGayNcSoHROAzz2l4JUdo222okPMg3mrWRODXhrjjfpLOf4Rc1rIlMX4ywR17r-30-2APSYwths9pcjUTRb1muPTUgyGzJc593NJlLbm-tJf_y3AAUi0ZWMxrPVKrVnZ7YV5lCHxmpQg9ChIr_Lk1Wvp_ItpjvzRIDuquMfSJ19evvBN4W7slsa64OHBxb6sSJ7ydzv132L3Wp_IBWIIc6upd5RaWkF1SNdrOhqpL1wtzuhxqgOkn1dGh9V8NZhdRIKari9fGbBo_EgbStJKab-UFI6I8eekc2U_ocNn2lGKggyN4nGdpLtdFQynvJ_jDipZqFuKQUpXMh71j1AbCuCkRfx9XvwIU3Zy4fd2u849A3CCYyD5_MgwV0qY3-eTnAY6lyQzU377GEt1HbvnYDdpBlQrW2pOdgUUJxpdeqaKAQpO7jX6AjuRDnieGZfoB-cdQPSUf9AsTZ6zfxIGyw4JVg-Hi_LPEACQLqJJ6FeMBylv19nLsWvU6A25As-6mq8MPKtJlJe7Liakpzi6o8NPbB05BZYQKM0VlzFOVnnE84sxiQkxkP8XksYVeUspuAKi68B6937X-Ixrm6xxBHJ0vhhwnxUWeU9Fl1ICjD3m8fa94dWKX0lQYUfuJjH8KPnhuVNj1D1Af5gAHsZYcsP68y_rAk4SpHVJyHAhyCbkeC9mca7sQwGLJDcq0VjQR54K_gB1TZPIy8PWTrCBVNRCuJCSYmxpSZUyqMRmIha6CEOr4sMyLkOGlUXlBJCf6yOmHKxnAYYNKHu2KOQlq8PQUydU5RhNc5_9NE9Gyty4DBjvuYiw0t1ma_m2twEA05IVf9ih5ekpKzcLXFP-4aJOS3j2xzVw8lEVm6qa4BH-JzUEjzmIdt8Vlx2tcAGbeYrIS3Sn3Cla6f9zEaorqXRjI9uQ-BlFDgjjEgnlImyr5iqZU7ttbUfLJzeuQcDbSp7m904i28xGujXb72iQj-alggLbiScG-2D6_NFwWmOoboq6E3uVQJzULZS13Lo823pwnLIUtw1dcoTF4400F55xj8kLyAY409BQL_TqpyONY8u81urRpfViIs13UdVRNAuNi-35Jerdxf2maDh_ipy0GnmxncgsYmsUOof9hWEgnp8HmTjuT_fMd7iJQo9g2EyCBN7FSu8T6HuUPdW5h63SD8rEyX-bEhQoiwDWykxXBeLuUbuv-3ASk-_0R82r_B4BqryrqtdW7pyB9pPzpkurGSdYF7VNIUD3zcK2b25YuSLt9yVucj812f1V3_A9BEjg9gWArdHmdbjiy9v6aRI7dF8=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1201.26,
            "totalPrice": 964
          },
          {
            "allPassengerTotalPrice": 964,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFIEsTR-qnb7rPg78lWKbLeoUv3088eODpR2qeekctYZs6bmpPnTWJzxnpnSiXYUD0bt6sExEZw1Z6ONyNRMPddUk2Fa0svb4YtKtNEuXGPTZJkk4-TkcQ2Ksq432DZCzQ_r5Hvf5Z1TX_hnTXsGkfO24YEjcJSGb1PB2UPAJToWqMQqyAs6808tZpg1gztOHrFEF1m2WcBQxIxjJ7DIunrzC2Szd_cziV5zpAJaUZ27GK8OoOQEYsZP4g3DxLEIiw97Wb2ZxqzQvUwu6qVB-q7ylxrrRA_XZYrbkprgEg6liLvVqlOjWPGVD873ppCKva4P44Dr1olfXYvlKxOVMZbt-q9cMXVPaMQheQ1u5GfO6g0E75HRZCFRoNeO_gvYpkq6_ySN4KuUsQogqt_f9HqDOGl6jxpyxMjZWCWuZ9kUYu8ZodL3dzXlPB5qQQIRE04qIUP5zhh4nOSnfFJvTf49IMYvxhpp0qHM1rBb0Ah0bea6tHOus7s5LOVD2jwoNrQ0T9-Guoz0tfM1Bre0sbKhLml0TD-xemtv14e289UeAW5yIeNgpsPuC7CUN0_oT0ouFrF5zRjbUEgem33rkVJQzETXUL3CzmSNYXeNx1WXkCUN4dy-UM-8wl6wfQ-hbcT7FopKVrEE4F5p2YpzkV3oco6WCtLLVliqoFRKKw_iuSGBM62cmvvogv3QcvTP58CmfESgDIjk-Xy3Z0V0owSVVRdK6fXllhHcPt8q9wBJV97Ok-SVPa29LWgExE0rYL7RYqXJx7_W6PQxu89RKnNKN3fxB6Cb3KwFwn5ZVsCjxKk7unxRSxm-mxjRZ0-Fs0DtcHpfzLc4VH2_hfLeRjGxi6dPBjBBsWBvjGncrIoh_BjHiv4zfs7NluIxy8PONtVSFnYZG3PNVlUA1kjmtkczPokpBCVHrsC7g6xSHwXVu6UNCKvJY3DXRTIeoM1KcfvNb848guWio6haStqjevoWVEbRnQAggDClu3hPSPlt1K5RoIdvcK1HL2dYmVpFbXNc8qLo66puoSZfRY5onvTRJqp7h0FfiLGpHb5fV8WdEwdLehOrLUif_2IPLO932h38Yhbxo5dn8InlZh1pAKy4LlDJWd79giXPz7OYM8mfId7FqxkcOiGgd4nTMRNf0k9K3bAaRuFIFuPVB_DEgFyyT0sqCUXHH3_rHsjm1rqwCCkVxNixUxGiudCn5I3nQFcnK44ivM2VElBES8-R6yPPHDAVR7oeOjgPjcSz10AupQjB6-ocvq7r_cjkhjCnJRPPkbH9xuaUrefuyv4ZG-JLmNyXZ2Lv4_ZnDlwkFzceVxzXnTsWwFSsOUgMZTEVbfloSdBd6PVV1V7ue31TWHQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFIEsTR-qnb7rPg78lWKbLeoUv3088eODpR2qeekctYZs6bmpPnTWJzxnpnSiXYUD0bt6sExEZw1Z6ONyNRMPddUk2Fa0svb4YtKtNEuXGPTZJkk4-TkcQ2Ksq432DZCzQ_r5Hvf5Z1TX_hnTXsGkfO24YEjcJSGb1PB2UPAJToWqMQqyAs6808tZpg1gztOHrFEF1m2WcBQxIxjJ7DIunrzC2Szd_cziV5zpAJaUZ27GK8OoOQEYsZP4g3DxLEIiw97Wb2ZxqzQvUwu6qVB-q7ylxrrRA_XZYrbkprgEg6liLvVqlOjWPGVD873ppCKva4P44Dr1olfXYvlKxOVMZbt-q9cMXVPaMQheQ1u5GfO6g0E75HRZCFRoNeO_gvYpkq6_ySN4KuUsQogqt_f9HqDOGl6jxpyxMjZWCWuZ9kUYu8ZodL3dzXlPB5qQQIRE04qIUP5zhh4nOSnfFJvTf49IMYvxhpp0qHM1rBb0Ah0bea6tHOus7s5LOVD2jwoNrQ0T9-Guoz0tfM1Bre0sbKhLml0TD-xemtv14e289UeAW5yIeNgpsPuC7CUN0_oT0ouFrF5zRjbUEgem33rkVJQzETXUL3CzmSNYXeNx1WXkCUN4dy-UM-8wl6wfQ-hbcT7FopKVrEE4F5p2YpzkV3oco6WCtLLVliqoFRKKw_iuSGBM62cmvvogv3QcvTP58CmfESgDIjk-Xy3Z0V0owSVVRdK6fXllhHcPt8q9wBJV97Ok-SVPa29LWgExE0rYL7RYqXJx7_W6PQxu89RKnNKN3fxB6Cb3KwFwn5ZVsCjxKk7unxRSxm-mxjRZ0-Fs0DtcHpfzLc4VH2_hfLeRjGxi6dPBjBBsWBvjGncrIoh_BjHiv4zfs7NluIxy8PONtVSFnYZG3PNVlUA1kjmtkczPokpBCVHrsC7g6xSHwXVu6UNCKvJY3DXRTIeoM1KcfvNb848guWio6haStqjevoWVEbRnQAggDClu3hPSPlt1K5RoIdvcK1HL2dYmVpFbXNc8qLo66puoSZfRY5onvTRJqp7h0FfiLGpHb5fV8WdEwdLehOrLUif_2IPLO932h38Yhbxo5dn8InlZh1pAKy4LlDJWd79giXPz7OYM8mfId7FqxkcOiGgd4nTMRNf0k9K3bAaRuFIFuPVB_DEgFyyT0sqCUXHH3_rHsjm1rqwCCkVxNixUxGiudCn5I3nQFcnK44ivM2VElBES8-R6yPPHDAVR7oeOjgPjcSz10AupQjB6-ocvq7r_cjkhjCnJRPPkbH9xuaUrefuyv4ZG-JLmNyXZ2Lv4_ZnDlwkFzceVxzXnTsWwFSsOUgMZTEVbfloSdBd6PVV1V7ue31TWHQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C2446076e4e1d00003d8fdc2f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFIEsTR-qnb7rPg78lWKbLeoUv3088eODpR2qeekctYZs6bmpPnTWJzxnpnSiXYUD0bt6sExEZw1Z6ONyNRMPddUk2Fa0svb4YtKtNEuXGPTZJkk4-TkcQ2Ksq432DZCzQ_r5Hvf5Z1TX_hnTXsGkfO24YEjcJSGb1PB2UPAJToWqMQqyAs6808tZpg1gztOHrFEF1m2WcBQxIxjJ7DIunrzC2Szd_cziV5zpAJaUZ27GK8OoOQEYsZP4g3DxLEIiw97Wb2ZxqzQvUwu6qVB-q7ylxrrRA_XZYrbkprgEg6liLvVqlOjWPGVD873ppCKva4P44Dr1olfXYvlKxOVMZbt-q9cMXVPaMQheQ1u5GfO6g0E75HRZCFRoNeO_gvYpkq6_ySN4KuUsQogqt_f9HqDOGl6jxpyxMjZWCWuZ9kUYu8ZodL3dzXlPB5qQQIRE04qIUP5zhh4nOSnfFJvTf49IMYvxhpp0qHM1rBb0Ah0bea6tHOus7s5LOVD2jwoNrQ0T9-Guoz0tfM1Bre0sbKhLml0TD-xemtv14e289UeAW5yIeNgpsPuC7CUN0_oT0ouFrF5zRjbUEgem33rkVJQzETXUL3CzmSNYXeNx1WXkCUN4dy-UM-8wl6wfQ-hbcT7FopKVrEE4F5p2YpzkV3oco6WCtLLVliqoFRKKw_iuSGBM62cmvvogv3QcvTP58CmfESgDIjk-Xy3Z0V0owSVVRdK6fXllhHcPt8q9wBJV97Ok-SVPa29LWgExE0rYL7RYqXJx7_W6PQxu89RKnNKN3fxB6Cb3KwFwn5ZVsCjxKk7unxRSxm-mxjRZ0-Fs0DtcHpfzLc4VH2_hfLeRjGxi6dPBjBBsWBvjGncrIoh_BjHiv4zfs7NluIxy8PONtVSFnYZG3PNVlUA1kjmtkczPokpBCVHrsC7g6xSHwXVu6UNCKvJY3DXRTIeoM1KcfvNb848guWio6haStqjevoWVEbRnQAggDClu3hPSPlt1K5RoIdvcK1HL2dYmVpFbXNc8qLo66puoSZfRY5onvTRJqp7h0FfiLGpHb5fV8WdEwdLehOrLUif_2IPLO932h38Yhbxo5dn8InlZh1pAKy4LlDJWd79giXPz7OYM8mfId7FqxkcOiGgd4nTMRNf0k9K3bAaRuFIFuPVB_DEgFyyT0sqCUXHH3_rHsjm1rqwCCkVxNixUxGiudCn5I3nQFcnK44ivM2VElBES8-R6yPPHDAVR7oeOjgPjcSz10AupQjB6-ocvq7r_cjkhjCnJRPPkbH9xuaUrefuyv4ZG-JLmNyXZ2Lv4_ZnDlwkFzceVxzXnTsWwFSsOUgMZTEVbfloSdBd6PVV1V7ue31TWHQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e1d00003d8fdc2f",
                    "equipment": null,
                    "fare_basis": "WA14NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e1d00003d8fdc2f_0",
                    "local_arrival": "2024-10-01T23:15:00.000Z",
                    "local_departure": "2024-10-01T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-02T06:15:00.000Z",
                    "utc_departure": "2024-10-02T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1238.59,
            "totalPrice": 964
          },
          {
            "allPassengerTotalPrice": 969,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gd0K6GAjenKexH-fmIAJLQdWwVj_Fe2qbpS247brjwxPRChYXR2TZ2F55dXSVWe6T8o9qSeARoLBGfUZ6jKqL71vQ2jAWRdimmAIyJ4UkYcEOvKCFj1_1kME3IYPSEphDlDXFX0Q7RtO25u0rayzFTm0JZoqDw8pxJX5JpdmL9lugCPK1wKRP4EzEYxkQph5ec_rmYXuNpp2SQwATrfrpraTNgvXAzHJpJfi0Sy7e_5BLcGI2_s2XKnpq2Po0evbmVh4QOk1f4m00UMkGisKTDRprAAAXJFdrBzzblBRcAzWseLaoJXzyHX3UNCzRwOpLEpVHVJYnrVGBBMwJNsi6i7o-fya5GOo3vpdG-B5QWe5ZjX4ydRQMmmKn6Rp5tQ-SZhycsHHMvmoJIMaBLYAgGa5-hB4ocLgGz1_pm1Hf8NdGLd_frjdaHYAt_PaAcbQfwSFMyHzrmCRRBlIFT6HgY7S3oTFcExPAg7Ruq1JQX-LoG4eCCANx2xOSMTKZr4fn-TQTooryxx8l2G163v8Cw_JBCcz73rsIvUcU1qjj-1phqjzxbZosLXrVO09a_BoI63wjELK6EGpl29ZIe63Wa5QhiW-u1Maph1TKaSB_IJfnlOvTuF0IY0HhStnPjHAM5qjDCcJtMpqKRFkHXa1q2m6fPoHwMMjU0766YgvFGLhPhzZLtia8u6kV2GUnAUXZJKq2HNhta4Qx4I36jBkDLF_Mvd3JPRJi7yazHmJAVs_cC5NW70Ki9e8r4N9B3IgHp5CdOeDg4DZpxMXQKplbMNqBpuM-8L0bpa6_FWm4coZKqaZsROw4A7mgYFg_UiujSyY-t6ryaYjekEPUgjsj_472nwbwmSZJxVfc3XNLiHaVqMH_Yee_HwMwRKay0y1beMjNOJXsYDZVDajSXw36g5JhOeWLNHIZBRmnOEWRDkWlgdeW-MWBVYYUVlraCl2lYtlKnvJhjljrzL15-EA4MEngaVFYK747v8dW2X8QN0W--foWli8jBZx0L_tZ2c2SfaQf0AR3u0_MP3JCdOCWYxkozsgmH_Y_dG9Oz1KriOgYeSJhPFYnOZASquaZ6Z2-vOr_q2DVUNKPXmiUJNOHHaBXEN3ohXD2DdKfrCEwyeV0tqMYMjf2bLIFm9JM7mr3NnjvzT2V7XyHMKVSsHLLMRJKuFBD6VzqeV0G26HPTn6KWNNu9y64tO2VWToZnb-VjYLHbHa5GIpzNYhCwctYXuzTLpPDFmaPSqBnq-QHwOc=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gd0K6GAjenKexH-fmIAJLQdWwVj_Fe2qbpS247brjwxPRChYXR2TZ2F55dXSVWe6T8o9qSeARoLBGfUZ6jKqL71vQ2jAWRdimmAIyJ4UkYcEOvKCFj1_1kME3IYPSEphDlDXFX0Q7RtO25u0rayzFTm0JZoqDw8pxJX5JpdmL9lugCPK1wKRP4EzEYxkQph5ec_rmYXuNpp2SQwATrfrpraTNgvXAzHJpJfi0Sy7e_5BLcGI2_s2XKnpq2Po0evbmVh4QOk1f4m00UMkGisKTDRprAAAXJFdrBzzblBRcAzWseLaoJXzyHX3UNCzRwOpLEpVHVJYnrVGBBMwJNsi6i7o-fya5GOo3vpdG-B5QWe5ZjX4ydRQMmmKn6Rp5tQ-SZhycsHHMvmoJIMaBLYAgGa5-hB4ocLgGz1_pm1Hf8NdGLd_frjdaHYAt_PaAcbQfwSFMyHzrmCRRBlIFT6HgY7S3oTFcExPAg7Ruq1JQX-LoG4eCCANx2xOSMTKZr4fn-TQTooryxx8l2G163v8Cw_JBCcz73rsIvUcU1qjj-1phqjzxbZosLXrVO09a_BoI63wjELK6EGpl29ZIe63Wa5QhiW-u1Maph1TKaSB_IJfnlOvTuF0IY0HhStnPjHAM5qjDCcJtMpqKRFkHXa1q2m6fPoHwMMjU0766YgvFGLhPhzZLtia8u6kV2GUnAUXZJKq2HNhta4Qx4I36jBkDLF_Mvd3JPRJi7yazHmJAVs_cC5NW70Ki9e8r4N9B3IgHp5CdOeDg4DZpxMXQKplbMNqBpuM-8L0bpa6_FWm4coZKqaZsROw4A7mgYFg_UiujSyY-t6ryaYjekEPUgjsj_472nwbwmSZJxVfc3XNLiHaVqMH_Yee_HwMwRKay0y1beMjNOJXsYDZVDajSXw36g5JhOeWLNHIZBRmnOEWRDkWlgdeW-MWBVYYUVlraCl2lYtlKnvJhjljrzL15-EA4MEngaVFYK747v8dW2X8QN0W--foWli8jBZx0L_tZ2c2SfaQf0AR3u0_MP3JCdOCWYxkozsgmH_Y_dG9Oz1KriOgYeSJhPFYnOZASquaZ6Z2-vOr_q2DVUNKPXmiUJNOHHaBXEN3ohXD2DdKfrCEwyeV0tqMYMjf2bLIFm9JM7mr3NnjvzT2V7XyHMKVSsHLLMRJKuFBD6VzqeV0G26HPTn6KWNNu9y64tO2VWToZnb-VjYLHbHa5GIpzNYhCwctYXuzTLpPDFmaPSqBnq-QHwOc=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gd0K6GAjenKexH-fmIAJLQdWwVj_Fe2qbpS247brjwxPRChYXR2TZ2F55dXSVWe6T8o9qSeARoLBGfUZ6jKqL71vQ2jAWRdimmAIyJ4UkYcEOvKCFj1_1kME3IYPSEphDlDXFX0Q7RtO25u0rayzFTm0JZoqDw8pxJX5JpdmL9lugCPK1wKRP4EzEYxkQph5ec_rmYXuNpp2SQwATrfrpraTNgvXAzHJpJfi0Sy7e_5BLcGI2_s2XKnpq2Po0evbmVh4QOk1f4m00UMkGisKTDRprAAAXJFdrBzzblBRcAzWseLaoJXzyHX3UNCzRwOpLEpVHVJYnrVGBBMwJNsi6i7o-fya5GOo3vpdG-B5QWe5ZjX4ydRQMmmKn6Rp5tQ-SZhycsHHMvmoJIMaBLYAgGa5-hB4ocLgGz1_pm1Hf8NdGLd_frjdaHYAt_PaAcbQfwSFMyHzrmCRRBlIFT6HgY7S3oTFcExPAg7Ruq1JQX-LoG4eCCANx2xOSMTKZr4fn-TQTooryxx8l2G163v8Cw_JBCcz73rsIvUcU1qjj-1phqjzxbZosLXrVO09a_BoI63wjELK6EGpl29ZIe63Wa5QhiW-u1Maph1TKaSB_IJfnlOvTuF0IY0HhStnPjHAM5qjDCcJtMpqKRFkHXa1q2m6fPoHwMMjU0766YgvFGLhPhzZLtia8u6kV2GUnAUXZJKq2HNhta4Qx4I36jBkDLF_Mvd3JPRJi7yazHmJAVs_cC5NW70Ki9e8r4N9B3IgHp5CdOeDg4DZpxMXQKplbMNqBpuM-8L0bpa6_FWm4coZKqaZsROw4A7mgYFg_UiujSyY-t6ryaYjekEPUgjsj_472nwbwmSZJxVfc3XNLiHaVqMH_Yee_HwMwRKay0y1beMjNOJXsYDZVDajSXw36g5JhOeWLNHIZBRmnOEWRDkWlgdeW-MWBVYYUVlraCl2lYtlKnvJhjljrzL15-EA4MEngaVFYK747v8dW2X8QN0W--foWli8jBZx0L_tZ2c2SfaQf0AR3u0_MP3JCdOCWYxkozsgmH_Y_dG9Oz1KriOgYeSJhPFYnOZASquaZ6Z2-vOr_q2DVUNKPXmiUJNOHHaBXEN3ohXD2DdKfrCEwyeV0tqMYMjf2bLIFm9JM7mr3NnjvzT2V7XyHMKVSsHLLMRJKuFBD6VzqeV0G26HPTn6KWNNu9y64tO2VWToZnb-VjYLHbHa5GIpzNYhCwctYXuzTLpPDFmaPSqBnq-QHwOc=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1211.01,
            "totalPrice": 969
          },
          {
            "allPassengerTotalPrice": 970,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIerwRZwHBlE-xb9xJ_nTIrseKk_RHhgT8QEnZr3NKzGpj5USXu7rfNQyxIcjW4bERTfZO2DlumpNAos3Q_J4Z2RaNyUMEHggwgMNz6_4kPumujB_HxlHcjtskUIovA7Sf9-z0Rq9tYfgYU6NXMsayRRcJFuqnlHVKsvD3ImJ3-WglStPrxe-yvzhewt0gzyfe_AFoyqCz2eElBT0cbtMTgEVYXuMZvohOhUkwC5frHT9PnVUxny6SuZBO593_FE2nWy_63OifWhg_SIpl5xemdPVELF0o35R7p_PHFnd61mMXzbcbUhbyi5PtDfBlu3taFtfxYlTMmZjWkM3bDhBCbRGI51KeL0LKwfBRIOSGQNd6GeEUCScc7p8f87I2fkF1n0vMJpNLCNZIxfiLst1UMhcy4Xk2Z_l0q_VE2IbXDqe4nAWRdAQdfAyTSXjOBp_Lf2UHOkMFgNE2O2wkUVOhXCPc0Q3HceXEFnDmyyhJ4xUX5_sjolL-eU8PEyqzZw3qnaKyQuSP1rkljtvbXMPlqPf3q-uR_uSIZswej1tap9p8doFD9ierk0ohEhVeo0WccCkgL0i8DCCpZTOgH8_5BiLq_9trUG__qo_6FuemRa-DGaSn18N1pw712haMRfhQKPGPR4Hh5DOHjKh5ybF63X1jz_vskpfKuqba6Gr08KN36JT-Gm_4u2o907PCymPvxiAkoubAz6CZuCvvMBQoZ4SiUo2ZJaUz_YtFCDnH1BDakHSC-P6rXKEHFVTSPqR0wDj0rSJ2KdWNJrkdR1v1hbVMhTYXK0TW-5-wXDZvjC4Q5HT_h4D3Ig_SKvpnFdv1o4VTlMw7IsvJTwDI5GCR1v1EUcV058_QjXMDiQgCxBZEIP_UedycTccVH-RSaPGJqVKVn3kgB2pa111nL8c2mzgVES0sSic_ktFhcEeYq2rLM2BQgqZFZAEtVdcTHXo8s6uA5catAZactq4BrccKc5tu4-aDaOb3NVheuaOovu6JUU50EWSLMzj1saJ2Z4kaCGMOkJxKhENtrgELBRiiO4EFyBxh9HKt1inykTNC7NAxI8_RJSIAiMkrKBfnDZj6BC7SLUauLUygCAZiqFWAI7zZwXNh5-rVQ9V89deaVmE-nWEHw4eaSSTSUcvL-JAy1ed0ifxIwHzktXZYUahCORZDfW-qKJ0jxvY282zKzDBVr6C4WsBQe-SAIuRvv6FexCk1zM_ChCvhyR4DbdNqJaCZzuqyCNDGLHptEuJepkUcbKZydMNSgDzQ8fcjiR_",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIerwRZwHBlE-xb9xJ_nTIrseKk_RHhgT8QEnZr3NKzGpj5USXu7rfNQyxIcjW4bERTfZO2DlumpNAos3Q_J4Z2RaNyUMEHggwgMNz6_4kPumujB_HxlHcjtskUIovA7Sf9-z0Rq9tYfgYU6NXMsayRRcJFuqnlHVKsvD3ImJ3-WglStPrxe-yvzhewt0gzyfe_AFoyqCz2eElBT0cbtMTgEVYXuMZvohOhUkwC5frHT9PnVUxny6SuZBO593_FE2nWy_63OifWhg_SIpl5xemdPVELF0o35R7p_PHFnd61mMXzbcbUhbyi5PtDfBlu3taFtfxYlTMmZjWkM3bDhBCbRGI51KeL0LKwfBRIOSGQNd6GeEUCScc7p8f87I2fkF1n0vMJpNLCNZIxfiLst1UMhcy4Xk2Z_l0q_VE2IbXDqe4nAWRdAQdfAyTSXjOBp_Lf2UHOkMFgNE2O2wkUVOhXCPc0Q3HceXEFnDmyyhJ4xUX5_sjolL-eU8PEyqzZw3qnaKyQuSP1rkljtvbXMPlqPf3q-uR_uSIZswej1tap9p8doFD9ierk0ohEhVeo0WccCkgL0i8DCCpZTOgH8_5BiLq_9trUG__qo_6FuemRa-DGaSn18N1pw712haMRfhQKPGPR4Hh5DOHjKh5ybF63X1jz_vskpfKuqba6Gr08KN36JT-Gm_4u2o907PCymPvxiAkoubAz6CZuCvvMBQoZ4SiUo2ZJaUz_YtFCDnH1BDakHSC-P6rXKEHFVTSPqR0wDj0rSJ2KdWNJrkdR1v1hbVMhTYXK0TW-5-wXDZvjC4Q5HT_h4D3Ig_SKvpnFdv1o4VTlMw7IsvJTwDI5GCR1v1EUcV058_QjXMDiQgCxBZEIP_UedycTccVH-RSaPGJqVKVn3kgB2pa111nL8c2mzgVES0sSic_ktFhcEeYq2rLM2BQgqZFZAEtVdcTHXo8s6uA5catAZactq4BrccKc5tu4-aDaOb3NVheuaOovu6JUU50EWSLMzj1saJ2Z4kaCGMOkJxKhENtrgELBRiiO4EFyBxh9HKt1inykTNC7NAxI8_RJSIAiMkrKBfnDZj6BC7SLUauLUygCAZiqFWAI7zZwXNh5-rVQ9V89deaVmE-nWEHw4eaSSTSUcvL-JAy1ed0ifxIwHzktXZYUahCORZDfW-qKJ0jxvY282zKzDBVr6C4WsBQe-SAIuRvv6FexCk1zM_ChCvhyR4DbdNqJaCZzuqyCNDGLHptEuJepkUcbKZydMNSgDzQ8fcjiR_",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIerwRZwHBlE-xb9xJ_nTIrseKk_RHhgT8QEnZr3NKzGpj5USXu7rfNQyxIcjW4bERTfZO2DlumpNAos3Q_J4Z2RaNyUMEHggwgMNz6_4kPumujB_HxlHcjtskUIovA7Sf9-z0Rq9tYfgYU6NXMsayRRcJFuqnlHVKsvD3ImJ3-WglStPrxe-yvzhewt0gzyfe_AFoyqCz2eElBT0cbtMTgEVYXuMZvohOhUkwC5frHT9PnVUxny6SuZBO593_FE2nWy_63OifWhg_SIpl5xemdPVELF0o35R7p_PHFnd61mMXzbcbUhbyi5PtDfBlu3taFtfxYlTMmZjWkM3bDhBCbRGI51KeL0LKwfBRIOSGQNd6GeEUCScc7p8f87I2fkF1n0vMJpNLCNZIxfiLst1UMhcy4Xk2Z_l0q_VE2IbXDqe4nAWRdAQdfAyTSXjOBp_Lf2UHOkMFgNE2O2wkUVOhXCPc0Q3HceXEFnDmyyhJ4xUX5_sjolL-eU8PEyqzZw3qnaKyQuSP1rkljtvbXMPlqPf3q-uR_uSIZswej1tap9p8doFD9ierk0ohEhVeo0WccCkgL0i8DCCpZTOgH8_5BiLq_9trUG__qo_6FuemRa-DGaSn18N1pw712haMRfhQKPGPR4Hh5DOHjKh5ybF63X1jz_vskpfKuqba6Gr08KN36JT-Gm_4u2o907PCymPvxiAkoubAz6CZuCvvMBQoZ4SiUo2ZJaUz_YtFCDnH1BDakHSC-P6rXKEHFVTSPqR0wDj0rSJ2KdWNJrkdR1v1hbVMhTYXK0TW-5-wXDZvjC4Q5HT_h4D3Ig_SKvpnFdv1o4VTlMw7IsvJTwDI5GCR1v1EUcV058_QjXMDiQgCxBZEIP_UedycTccVH-RSaPGJqVKVn3kgB2pa111nL8c2mzgVES0sSic_ktFhcEeYq2rLM2BQgqZFZAEtVdcTHXo8s6uA5catAZactq4BrccKc5tu4-aDaOb3NVheuaOovu6JUU50EWSLMzj1saJ2Z4kaCGMOkJxKhENtrgELBRiiO4EFyBxh9HKt1inykTNC7NAxI8_RJSIAiMkrKBfnDZj6BC7SLUauLUygCAZiqFWAI7zZwXNh5-rVQ9V89deaVmE-nWEHw4eaSSTSUcvL-JAy1ed0ifxIwHzktXZYUahCORZDfW-qKJ0jxvY282zKzDBVr6C4WsBQe-SAIuRvv6FexCk1zM_ChCvhyR4DbdNqJaCZzuqyCNDGLHptEuJepkUcbKZydMNSgDzQ8fcjiR_",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1212.32,
            "totalPrice": 970
          },
          {
            "allPassengerTotalPrice": 975,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "19:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYVhg4PcjhpLJimAPB9VLfis18xz-EYl2htEvJ4lXxLsnh9j-rI2hx_SiqteOHo7h9me-1jN3ZFkF1F6SKFkmCKO3Soj2ZG3irnFdf5N2uDSOKYSGwJy7Es_I7D-VYSzISfol8HrUnfT59fP9zKzUMWhkC_wgg8F5CC1_D2JZ4h9QjrFn3hnwOUYoAALHgfJuxwlmhj1eyW_-5sSsibmH_siHYvdb-zFhFxr_bFpudQIToKtwuW8veZfBzzH5hC3Fq94R2al3hf9FFrT7y9sXqaZ06W2DRtaAuaR4zXY-h9Hpt34l4pxa1AiSRHdElJaKL28LVpFV2-zI-4_sQ1x93iCqNgJ9ErNmGcQ6FEF4cg0YyUK5e12jW_-iNtLZ6olMy0b22LGuqKDVbsS6pwzJ_MXsv92LCg24B4JtVX8fgkE__bbQdnkhZbK4y6oKk2QVsE55bhy-i35FCV5W6SE76s7Uq-3GJY7B29QrPDG6YHodTrw6sUOmng4BAJoH3uUr3T496NJmMlTdTbG2T08Q7nuvbxFbqcP4Z-xgLBrBqe9EYoFtuY0p0msyofKf9LAP04kZzf9zpUZHP62jM5CqKXoUw3c6IKorTCDcpVTb3Nzg6Cvr-GJoDQbUg-0LGq85gg7FLTbAeApNiSP7SEZFeijuuEVG_OYMS4JjzvAoPxZVwjzgyKfym_SJMeO5ATgIZwKCHYEdCHU4icHXcX8DLzsoUZB8za34nbX2_PU5WrL5EcuOwaLMenFjdFhVWSxFIyhG2qUE9UlgV_mH7whKTxSALmDY4WolgyS2hilVKwp0iIjcDIwTtLVKl9VZPV93Hr0LGszGNGS3l0as2hShVFIMw-Q-B-TXrW9ihOtYTFAeaomYWYo8BrskH3ZGdSTzMXGLFAg-GGaim2j9TTwtI6gd4Qk4cv_8xMc5-O4CQ7x856BQCW_80o1IQV2JLmmmmoXZo8LS2-UwqQP3cdOxj77rA6-pAEw95ATKuYcV3PaNmZ2X5OUHUPnzO8T0IZMOVJ551_49Qj_9QoVQIrPgbxQLyGCS3ChdvF75lq4jaH2MenS4NRCWLYlUObW-tiAspETVreLkkWb6cjJARlQ8Mt0hJ13HGCDsk8dWlDFv6JSd7p-PxtBq7njznjM_2NZ5_lky1Vsiv9FZ7riwwnLTa9HjMI9ahZxxkcuMJYahs0mHPP1fI7tVgQkFnKdKpN8i6FIavd_5E5p8120-wvVnEyjBRunyZb2V5Yup4-ljQck=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h9m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d0000932b321c",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7827,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d0000932b321c_0",
                    "local_arrival": "2024-09-15T19:25:00.000Z",
                    "local_departure": "2024-09-15T16:10:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7827",
                    "return": 0,
                    "utc_arrival": "2024-09-15T17:25:00.000Z",
                    "utc_departure": "2024-09-15T15:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYVhg4PcjhpLJimAPB9VLfis18xz-EYl2htEvJ4lXxLsnh9j-rI2hx_SiqteOHo7h9me-1jN3ZFkF1F6SKFkmCKO3Soj2ZG3irnFdf5N2uDSOKYSGwJy7Es_I7D-VYSzISfol8HrUnfT59fP9zKzUMWhkC_wgg8F5CC1_D2JZ4h9QjrFn3hnwOUYoAALHgfJuxwlmhj1eyW_-5sSsibmH_siHYvdb-zFhFxr_bFpudQIToKtwuW8veZfBzzH5hC3Fq94R2al3hf9FFrT7y9sXqaZ06W2DRtaAuaR4zXY-h9Hpt34l4pxa1AiSRHdElJaKL28LVpFV2-zI-4_sQ1x93iCqNgJ9ErNmGcQ6FEF4cg0YyUK5e12jW_-iNtLZ6olMy0b22LGuqKDVbsS6pwzJ_MXsv92LCg24B4JtVX8fgkE__bbQdnkhZbK4y6oKk2QVsE55bhy-i35FCV5W6SE76s7Uq-3GJY7B29QrPDG6YHodTrw6sUOmng4BAJoH3uUr3T496NJmMlTdTbG2T08Q7nuvbxFbqcP4Z-xgLBrBqe9EYoFtuY0p0msyofKf9LAP04kZzf9zpUZHP62jM5CqKXoUw3c6IKorTCDcpVTb3Nzg6Cvr-GJoDQbUg-0LGq85gg7FLTbAeApNiSP7SEZFeijuuEVG_OYMS4JjzvAoPxZVwjzgyKfym_SJMeO5ATgIZwKCHYEdCHU4icHXcX8DLzsoUZB8za34nbX2_PU5WrL5EcuOwaLMenFjdFhVWSxFIyhG2qUE9UlgV_mH7whKTxSALmDY4WolgyS2hilVKwp0iIjcDIwTtLVKl9VZPV93Hr0LGszGNGS3l0as2hShVFIMw-Q-B-TXrW9ihOtYTFAeaomYWYo8BrskH3ZGdSTzMXGLFAg-GGaim2j9TTwtI6gd4Qk4cv_8xMc5-O4CQ7x856BQCW_80o1IQV2JLmmmmoXZo8LS2-UwqQP3cdOxj77rA6-pAEw95ATKuYcV3PaNmZ2X5OUHUPnzO8T0IZMOVJ551_49Qj_9QoVQIrPgbxQLyGCS3ChdvF75lq4jaH2MenS4NRCWLYlUObW-tiAspETVreLkkWb6cjJARlQ8Mt0hJ13HGCDsk8dWlDFv6JSd7p-PxtBq7njznjM_2NZ5_lky1Vsiv9FZ7riwwnLTa9HjMI9ahZxxkcuMJYahs0mHPP1fI7tVgQkFnKdKpN8i6FIavd_5E5p8120-wvVnEyjBRunyZb2V5Yup4-ljQck=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYVhg4PcjhpLJimAPB9VLfis18xz-EYl2htEvJ4lXxLsnh9j-rI2hx_SiqteOHo7h9me-1jN3ZFkF1F6SKFkmCKO3Soj2ZG3irnFdf5N2uDSOKYSGwJy7Es_I7D-VYSzISfol8HrUnfT59fP9zKzUMWhkC_wgg8F5CC1_D2JZ4h9QjrFn3hnwOUYoAALHgfJuxwlmhj1eyW_-5sSsibmH_siHYvdb-zFhFxr_bFpudQIToKtwuW8veZfBzzH5hC3Fq94R2al3hf9FFrT7y9sXqaZ06W2DRtaAuaR4zXY-h9Hpt34l4pxa1AiSRHdElJaKL28LVpFV2-zI-4_sQ1x93iCqNgJ9ErNmGcQ6FEF4cg0YyUK5e12jW_-iNtLZ6olMy0b22LGuqKDVbsS6pwzJ_MXsv92LCg24B4JtVX8fgkE__bbQdnkhZbK4y6oKk2QVsE55bhy-i35FCV5W6SE76s7Uq-3GJY7B29QrPDG6YHodTrw6sUOmng4BAJoH3uUr3T496NJmMlTdTbG2T08Q7nuvbxFbqcP4Z-xgLBrBqe9EYoFtuY0p0msyofKf9LAP04kZzf9zpUZHP62jM5CqKXoUw3c6IKorTCDcpVTb3Nzg6Cvr-GJoDQbUg-0LGq85gg7FLTbAeApNiSP7SEZFeijuuEVG_OYMS4JjzvAoPxZVwjzgyKfym_SJMeO5ATgIZwKCHYEdCHU4icHXcX8DLzsoUZB8za34nbX2_PU5WrL5EcuOwaLMenFjdFhVWSxFIyhG2qUE9UlgV_mH7whKTxSALmDY4WolgyS2hilVKwp0iIjcDIwTtLVKl9VZPV93Hr0LGszGNGS3l0as2hShVFIMw-Q-B-TXrW9ihOtYTFAeaomYWYo8BrskH3ZGdSTzMXGLFAg-GGaim2j9TTwtI6gd4Qk4cv_8xMc5-O4CQ7x856BQCW_80o1IQV2JLmmmmoXZo8LS2-UwqQP3cdOxj77rA6-pAEw95ATKuYcV3PaNmZ2X5OUHUPnzO8T0IZMOVJ551_49Qj_9QoVQIrPgbxQLyGCS3ChdvF75lq4jaH2MenS4NRCWLYlUObW-tiAspETVreLkkWb6cjJARlQ8Mt0hJ13HGCDsk8dWlDFv6JSd7p-PxtBq7njznjM_2NZ5_lky1Vsiv9FZ7riwwnLTa9HjMI9ahZxxkcuMJYahs0mHPP1fI7tVgQkFnKdKpN8i6FIavd_5E5p8120-wvVnEyjBRunyZb2V5Yup4-ljQck=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1211.55,
            "totalPrice": 975
          },
          {
            "allPassengerTotalPrice": 975,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "20:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G40yNBuXGnsekCG6rty17JLvVQ1jjTJXIRCOh4ljMHkzCHcwqmm-hJ_Cbv1cHhG77A4CC-hHVWLFz6jSgVFBc8Bzbgy5yTpkENjrQrNpAlZ7S4eQaQg-ExaBs6Wj09JBcHVsgUFarWsVGtzoB-N5a9a8m9eGriqEuZ_tKYme_4BcLdUK4L6_CcFtq3PEAHUlH8yn_Tz4dxt2R9dzdzwr_VF9STSDWMUuLHeEQqWvL4INlNo_gviXSnedbRWww6Zh8wskumVxkMeVFT00Ft7faDX9E_GYD_zEKvly0V_WKReOvqTUYBCX6o5XnHt7TgjmwQyZgn5tysqYtijvVkTvOT3Hx8N8yOSPiiMOsktciFGkWwN4fP67fuOkbxNiGmED0oUEicl2xkWNLzkRp_seZ6k5GmuxxMXjnK6GK6ZO-c2onVHMFjpJhTCXJhwMnCnTuH1KAErwDtXfdVm8VOjw1XTza66tjh5iuL8JJILG334Wz5kZGEpbBt-n2qhKfZzg0NodYYLxwgtIn7SQbm1_efesSmVMPUzBkTe-Krcs-kPff-WU8YiTebiNY80PkhnCmLgx8B-GHSudGLCcV7KXClf8G4afVYe1ioMJ70OPjSRvdcTmBr5qj-wsjUc5hyHCnJ4bMVOt-6zGfvsON4t7teDxCCvYzfWU5LG806bR7evJ9rsQGurRy5Wi1pVQ9afualOPzB0U5sI91lH57T0Csp-J8eGvSLQFJxyAiS1xskcbTB8-lPUw6ScuHtuAtQBXcUzHbsafY8lkkPCNz4sBnDEW-imPni32oodV2y0yc2JzRLww8RtqQobflNuFYmrQnKHI5VtjIwOLf-Qy82SbsRPxpSpp-GJkWU8H0Wz-r2xUSvi7-iPj5zUHGj5aGXErLiOuI5E0gJsb5viwmW8buhN9I4P_CFNWRhNrmRCu1iVn1U28VXneV2HIBmMOe4a5Bvi1t-VeuUM0lxOc5Jr5sQGlEU_Hfcy3JMlgKN0l1a6Tro4oQU4nb2j0I_XJK_GXPgttLCJN3DyXsPEItCF4JuRMTz9aZ04gBBH_M5DkiSVulpkEVX4aw2mM54ylhLiCkdGFLqfNdYWUXVaglz8qehrmAGy7GkImVKN78DvpEnpV4SfcqxVO5MyUAuWB0jKKOX0G0nbno1xNtWXISf_-qSI5I4hEEkjLRSW5TqokAyF6RSwEe0dJgjsQFDZgmH0khT5GVgQMQX9Lxh9W6EdJvc_qsVRvCRtH3H3blxVlV4qc=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h49m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d000052833457",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 6026,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d000052833457_0",
                    "local_arrival": "2024-09-15T20:05:00.000Z",
                    "local_departure": "2024-09-15T16:50:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "6026",
                    "return": 0,
                    "utc_arrival": "2024-09-15T18:05:00.000Z",
                    "utc_departure": "2024-09-15T15:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G40yNBuXGnsekCG6rty17JLvVQ1jjTJXIRCOh4ljMHkzCHcwqmm-hJ_Cbv1cHhG77A4CC-hHVWLFz6jSgVFBc8Bzbgy5yTpkENjrQrNpAlZ7S4eQaQg-ExaBs6Wj09JBcHVsgUFarWsVGtzoB-N5a9a8m9eGriqEuZ_tKYme_4BcLdUK4L6_CcFtq3PEAHUlH8yn_Tz4dxt2R9dzdzwr_VF9STSDWMUuLHeEQqWvL4INlNo_gviXSnedbRWww6Zh8wskumVxkMeVFT00Ft7faDX9E_GYD_zEKvly0V_WKReOvqTUYBCX6o5XnHt7TgjmwQyZgn5tysqYtijvVkTvOT3Hx8N8yOSPiiMOsktciFGkWwN4fP67fuOkbxNiGmED0oUEicl2xkWNLzkRp_seZ6k5GmuxxMXjnK6GK6ZO-c2onVHMFjpJhTCXJhwMnCnTuH1KAErwDtXfdVm8VOjw1XTza66tjh5iuL8JJILG334Wz5kZGEpbBt-n2qhKfZzg0NodYYLxwgtIn7SQbm1_efesSmVMPUzBkTe-Krcs-kPff-WU8YiTebiNY80PkhnCmLgx8B-GHSudGLCcV7KXClf8G4afVYe1ioMJ70OPjSRvdcTmBr5qj-wsjUc5hyHCnJ4bMVOt-6zGfvsON4t7teDxCCvYzfWU5LG806bR7evJ9rsQGurRy5Wi1pVQ9afualOPzB0U5sI91lH57T0Csp-J8eGvSLQFJxyAiS1xskcbTB8-lPUw6ScuHtuAtQBXcUzHbsafY8lkkPCNz4sBnDEW-imPni32oodV2y0yc2JzRLww8RtqQobflNuFYmrQnKHI5VtjIwOLf-Qy82SbsRPxpSpp-GJkWU8H0Wz-r2xUSvi7-iPj5zUHGj5aGXErLiOuI5E0gJsb5viwmW8buhN9I4P_CFNWRhNrmRCu1iVn1U28VXneV2HIBmMOe4a5Bvi1t-VeuUM0lxOc5Jr5sQGlEU_Hfcy3JMlgKN0l1a6Tro4oQU4nb2j0I_XJK_GXPgttLCJN3DyXsPEItCF4JuRMTz9aZ04gBBH_M5DkiSVulpkEVX4aw2mM54ylhLiCkdGFLqfNdYWUXVaglz8qehrmAGy7GkImVKN78DvpEnpV4SfcqxVO5MyUAuWB0jKKOX0G0nbno1xNtWXISf_-qSI5I4hEEkjLRSW5TqokAyF6RSwEe0dJgjsQFDZgmH0khT5GVgQMQX9Lxh9W6EdJvc_qsVRvCRtH3H3blxVlV4qc=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G40yNBuXGnsekCG6rty17JLvVQ1jjTJXIRCOh4ljMHkzCHcwqmm-hJ_Cbv1cHhG77A4CC-hHVWLFz6jSgVFBc8Bzbgy5yTpkENjrQrNpAlZ7S4eQaQg-ExaBs6Wj09JBcHVsgUFarWsVGtzoB-N5a9a8m9eGriqEuZ_tKYme_4BcLdUK4L6_CcFtq3PEAHUlH8yn_Tz4dxt2R9dzdzwr_VF9STSDWMUuLHeEQqWvL4INlNo_gviXSnedbRWww6Zh8wskumVxkMeVFT00Ft7faDX9E_GYD_zEKvly0V_WKReOvqTUYBCX6o5XnHt7TgjmwQyZgn5tysqYtijvVkTvOT3Hx8N8yOSPiiMOsktciFGkWwN4fP67fuOkbxNiGmED0oUEicl2xkWNLzkRp_seZ6k5GmuxxMXjnK6GK6ZO-c2onVHMFjpJhTCXJhwMnCnTuH1KAErwDtXfdVm8VOjw1XTza66tjh5iuL8JJILG334Wz5kZGEpbBt-n2qhKfZzg0NodYYLxwgtIn7SQbm1_efesSmVMPUzBkTe-Krcs-kPff-WU8YiTebiNY80PkhnCmLgx8B-GHSudGLCcV7KXClf8G4afVYe1ioMJ70OPjSRvdcTmBr5qj-wsjUc5hyHCnJ4bMVOt-6zGfvsON4t7teDxCCvYzfWU5LG806bR7evJ9rsQGurRy5Wi1pVQ9afualOPzB0U5sI91lH57T0Csp-J8eGvSLQFJxyAiS1xskcbTB8-lPUw6ScuHtuAtQBXcUzHbsafY8lkkPCNz4sBnDEW-imPni32oodV2y0yc2JzRLww8RtqQobflNuFYmrQnKHI5VtjIwOLf-Qy82SbsRPxpSpp-GJkWU8H0Wz-r2xUSvi7-iPj5zUHGj5aGXErLiOuI5E0gJsb5viwmW8buhN9I4P_CFNWRhNrmRCu1iVn1U28VXneV2HIBmMOe4a5Bvi1t-VeuUM0lxOc5Jr5sQGlEU_Hfcy3JMlgKN0l1a6Tro4oQU4nb2j0I_XJK_GXPgttLCJN3DyXsPEItCF4JuRMTz9aZ04gBBH_M5DkiSVulpkEVX4aw2mM54ylhLiCkdGFLqfNdYWUXVaglz8qehrmAGy7GkImVKN78DvpEnpV4SfcqxVO5MyUAuWB0jKKOX0G0nbno1xNtWXISf_-qSI5I4hEEkjLRSW5TqokAyF6RSwEe0dJgjsQFDZgmH0khT5GVgQMQX9Lxh9W6EdJvc_qsVRvCRtH3H3blxVlV4qc=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1216.88,
            "totalPrice": 975
          },
          {
            "allPassengerTotalPrice": 979,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gxq_tm_1Wf-warPdVJBKuhI5S9LIvHFJxZSU3mEG6L9wFkXDRGh3tG2GV0jvcRZk07SZzXiT4WHLMBmuiirOMVaid0NXuiOxqzNLujcQeJB-Gso2AZlfpf8yRIYWxJitWEGt4X831pjb2hZqdLNXFcxXyFYfq_6_-CE08RqPe-SvRu-xFoqR3okRrACdpTYpw_MRWxYJHHkr7oPqZx871bhy7CILa-h73Qvc8JA0-60rUmq2LolgMNxgPGUDaZ3dIxZX3oRyInq9PWZu7R2i6nHi_EUsiXJbjz1yrgRgfVr6z9NAAgLAvsDPh8e-OyPf2fLcSNpT3ir_t_j_UEjuUjbGrMaQ0V8Mu_VAHxnw5G7_n8IVQ1kPiwot7pn6t2nJWErfaA27Jqah-wyZ4ptvWO2zRynCczl7NjD6L_pK0gtVz9MiiR4CUJqIynLfheQAlNe2wW7O1X9MIGDoXgDRNKB4Xc0uugbw39JI7LnFl4RFWaaSqvnf7WfC5wFjIdX5xzHicRWn5Y312idLnyE7TNPPiRG9VpazB4iRZBR1iW2_IuSvXrbAs9GGZhKBHV_ud6H2wVxsdIR9VeKobOLcX4C_mF99WpLCX34LATNOW45BTxMa8qTcj7iysjJchTIGNZuAlahcwKlLR4oh72tjq5JA3pJUFrVttSALLv8j5GYc0vVSlppvDMsQ-_m40u0pV7HqCEB3ljoYrUyhYOAFigaPK44ImpSApVxzj9bBi2uAXrydEklvL_NRj8qYLsh4IFkqfiH7Ej_UYfsem1j9UtsAk-eLudD9bs-9fK6bwdx3kMkCQ3b0LxEZlNAS07BVs_ZuFiXYmg9CypxOMW3KoxcTNXmDnVrOXiwrhj-u8vDlV2wC2dJsUYQsDD-SMJHR4-MijFrFso0OLybvMu8UGjpcWwHuOBwrWlIVHZp3bVhzMMcNA_4z16sNrBETPLkjS6G-axg28c7nx7GeNFDSxdJnqhlj9ua3u-SWFkXM3mM7HF1XJFiwksyluqbVE-z3emMe5Op0w3YvGNcYgE-3tJ7W9UkH6DNY31fAY0snzXXtcRQeTKvmlhvk-wr9GATaANzZh6uKbSQyuu77YfNyFvSfKCxbfffhW9vQdDyyVPSwXEBXmOjjSjfYqLn4Jk-A1vKNfwqqbsZAPafpYJEVukVDZsCs2oL6TKBqhgGbcwV-w6TqlocRUd4Qe2GAReIeFiqu8mLFegPJuWsPfZVwQWgnF-4WOaTQG4AZFQcHcqM3UGMrA5fuKIVz6w5oKV6_QjLscVFRzRUKGQVzNYaUQCvRB4kZRhkn5HOHvyP6CBPVdmboMI_mm8GQJ-WlOQY5A",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gxq_tm_1Wf-warPdVJBKuhI5S9LIvHFJxZSU3mEG6L9wFkXDRGh3tG2GV0jvcRZk07SZzXiT4WHLMBmuiirOMVaid0NXuiOxqzNLujcQeJB-Gso2AZlfpf8yRIYWxJitWEGt4X831pjb2hZqdLNXFcxXyFYfq_6_-CE08RqPe-SvRu-xFoqR3okRrACdpTYpw_MRWxYJHHkr7oPqZx871bhy7CILa-h73Qvc8JA0-60rUmq2LolgMNxgPGUDaZ3dIxZX3oRyInq9PWZu7R2i6nHi_EUsiXJbjz1yrgRgfVr6z9NAAgLAvsDPh8e-OyPf2fLcSNpT3ir_t_j_UEjuUjbGrMaQ0V8Mu_VAHxnw5G7_n8IVQ1kPiwot7pn6t2nJWErfaA27Jqah-wyZ4ptvWO2zRynCczl7NjD6L_pK0gtVz9MiiR4CUJqIynLfheQAlNe2wW7O1X9MIGDoXgDRNKB4Xc0uugbw39JI7LnFl4RFWaaSqvnf7WfC5wFjIdX5xzHicRWn5Y312idLnyE7TNPPiRG9VpazB4iRZBR1iW2_IuSvXrbAs9GGZhKBHV_ud6H2wVxsdIR9VeKobOLcX4C_mF99WpLCX34LATNOW45BTxMa8qTcj7iysjJchTIGNZuAlahcwKlLR4oh72tjq5JA3pJUFrVttSALLv8j5GYc0vVSlppvDMsQ-_m40u0pV7HqCEB3ljoYrUyhYOAFigaPK44ImpSApVxzj9bBi2uAXrydEklvL_NRj8qYLsh4IFkqfiH7Ej_UYfsem1j9UtsAk-eLudD9bs-9fK6bwdx3kMkCQ3b0LxEZlNAS07BVs_ZuFiXYmg9CypxOMW3KoxcTNXmDnVrOXiwrhj-u8vDlV2wC2dJsUYQsDD-SMJHR4-MijFrFso0OLybvMu8UGjpcWwHuOBwrWlIVHZp3bVhzMMcNA_4z16sNrBETPLkjS6G-axg28c7nx7GeNFDSxdJnqhlj9ua3u-SWFkXM3mM7HF1XJFiwksyluqbVE-z3emMe5Op0w3YvGNcYgE-3tJ7W9UkH6DNY31fAY0snzXXtcRQeTKvmlhvk-wr9GATaANzZh6uKbSQyuu77YfNyFvSfKCxbfffhW9vQdDyyVPSwXEBXmOjjSjfYqLn4Jk-A1vKNfwqqbsZAPafpYJEVukVDZsCs2oL6TKBqhgGbcwV-w6TqlocRUd4Qe2GAReIeFiqu8mLFegPJuWsPfZVwQWgnF-4WOaTQG4AZFQcHcqM3UGMrA5fuKIVz6w5oKV6_QjLscVFRzRUKGQVzNYaUQCvRB4kZRhkn5HOHvyP6CBPVdmboMI_mm8GQJ-WlOQY5A",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gxq_tm_1Wf-warPdVJBKuhI5S9LIvHFJxZSU3mEG6L9wFkXDRGh3tG2GV0jvcRZk07SZzXiT4WHLMBmuiirOMVaid0NXuiOxqzNLujcQeJB-Gso2AZlfpf8yRIYWxJitWEGt4X831pjb2hZqdLNXFcxXyFYfq_6_-CE08RqPe-SvRu-xFoqR3okRrACdpTYpw_MRWxYJHHkr7oPqZx871bhy7CILa-h73Qvc8JA0-60rUmq2LolgMNxgPGUDaZ3dIxZX3oRyInq9PWZu7R2i6nHi_EUsiXJbjz1yrgRgfVr6z9NAAgLAvsDPh8e-OyPf2fLcSNpT3ir_t_j_UEjuUjbGrMaQ0V8Mu_VAHxnw5G7_n8IVQ1kPiwot7pn6t2nJWErfaA27Jqah-wyZ4ptvWO2zRynCczl7NjD6L_pK0gtVz9MiiR4CUJqIynLfheQAlNe2wW7O1X9MIGDoXgDRNKB4Xc0uugbw39JI7LnFl4RFWaaSqvnf7WfC5wFjIdX5xzHicRWn5Y312idLnyE7TNPPiRG9VpazB4iRZBR1iW2_IuSvXrbAs9GGZhKBHV_ud6H2wVxsdIR9VeKobOLcX4C_mF99WpLCX34LATNOW45BTxMa8qTcj7iysjJchTIGNZuAlahcwKlLR4oh72tjq5JA3pJUFrVttSALLv8j5GYc0vVSlppvDMsQ-_m40u0pV7HqCEB3ljoYrUyhYOAFigaPK44ImpSApVxzj9bBi2uAXrydEklvL_NRj8qYLsh4IFkqfiH7Ej_UYfsem1j9UtsAk-eLudD9bs-9fK6bwdx3kMkCQ3b0LxEZlNAS07BVs_ZuFiXYmg9CypxOMW3KoxcTNXmDnVrOXiwrhj-u8vDlV2wC2dJsUYQsDD-SMJHR4-MijFrFso0OLybvMu8UGjpcWwHuOBwrWlIVHZp3bVhzMMcNA_4z16sNrBETPLkjS6G-axg28c7nx7GeNFDSxdJnqhlj9ua3u-SWFkXM3mM7HF1XJFiwksyluqbVE-z3emMe5Op0w3YvGNcYgE-3tJ7W9UkH6DNY31fAY0snzXXtcRQeTKvmlhvk-wr9GATaANzZh6uKbSQyuu77YfNyFvSfKCxbfffhW9vQdDyyVPSwXEBXmOjjSjfYqLn4Jk-A1vKNfwqqbsZAPafpYJEVukVDZsCs2oL6TKBqhgGbcwV-w6TqlocRUd4Qe2GAReIeFiqu8mLFegPJuWsPfZVwQWgnF-4WOaTQG4AZFQcHcqM3UGMrA5fuKIVz6w5oKV6_QjLscVFRzRUKGQVzNYaUQCvRB4kZRhkn5HOHvyP6CBPVdmboMI_mm8GQJ-WlOQY5A",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:05",
                "destination": "OAK",
                "duration": "29h30m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e1c000068b2193b",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e1c000068b2193b_0",
                    "local_arrival": "2024-09-30T23:40:00.000Z",
                    "local_departure": "2024-09-30T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-30T21:40:00.000Z",
                    "utc_departure": "2024-09-30T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "LON",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "London",
                    "combination_id": "13a222f54e1d000084885915",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8094,
                    "flyFrom": "PMI",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "13a222f54e1d000084885915_0",
                    "local_arrival": "2024-10-01T08:35:00.000Z",
                    "local_departure": "2024-10-01T07:00:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:35:00.000Z",
                    "utc_departure": "2024-10-01T05:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1211.94,
            "totalPrice": 979
          },
          {
            "allPassengerTotalPrice": 979,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "19:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPt4Ab8EAG8qDYl3T-ybF65lKPwZVxkoECzfj10LUVc6Rc_TN2kYXg0jA2k-Lzl7AUPmSavNk05H6id94upr-ciSpC1H-loleXubF2RbN9X5Xdnmjk3QBxpUy9i8WVSnnQYj0Y0ARRrqv5o7rBEjyr7T9V6erc64n5s5cyCs6OSwrFQIjp6eDX2xEwhowTs-gtQfuHYX5Nm1HT6mJm4nXEKgsgJopYEhufilI6oT9GX7FcG5e0hLojIaXk6NI1efvuVwSFTyCfxJkMBrOvloYvfTgZAUPIgTigSUDtL2KrMiGNUEeFrSoxzgf0Iffq8WZLkDmqKoWtA5rO5oOOUNOZU_3nUGqfphxWVlMPsbSmbj5ZbiZe171eKtoOq0TF5TYiqrqqTYzHNNj5VvAz8YOPrYAXbY2ghAyCl4spQ1TMkR9PmyYcOhuqnQ6WcpOHiXLmb3FG9r6Qm7QkG3HPAkGgjAgObAEoGFtIjCEIUA6A2v8sTiVhBM0ij2xNMDnvWdDb3pbsdtaM2wmfmMqUac038w60bAhyK0_fFreZfCYRX0i_AG5sh87mB0vv1FOX2rMA9mFJDabLEamPvBw1IUUrmihTzcE2zkcz6b5S9njTzMre_McqTIp-6mDAnNGL8lCDSkRXvPBAPV_tRQQ3B_RaT7wYeyU0607AePmZI4AMbwULp6CYhhJ2ZLRca1uW8ywH2XZDjSlm8elusEYLWGvdMaH5bBNxEpY1OxctTOlJlXr4hCFJMgJXBv7GFsJG7vl9CAEr3v9vsBR5GIlgD34uT5yJW4fCx0InjbMGsLlw7m7HN_9yjoyYPdGYVZY1Mhtpm4h9kae3AMP1qXdOinW7j-tWf7Eyv2W4gdv4EMjVyVJ1Vgd2YVcrieSK0fiLZ474klMpYUnT01ozPPXZkNAlqCfRYoVW1RrMjrttYn7jX5rNnwgl6PWlzUiQnWudJhux1eplpVqoWkxnTz5gbQxU9omccgrVrn5RX42CTSoAoK3SkzbPA9qkS49aUZ92ZqvKdCQyvVMjQTgIL7uu1GkYWrKDkXnzwYRU-q6dGHjXPSWu2LV8G0WOW0Dxg7fCKwYKK-pmwA-yimQJRXsFdYhZnM8iDYRKDboc6siXp5UkI92KxXb0CcKM6ev4ixteHovsc-n5ixbuzo_zRmHyO2IaShd5o5Cu3wb0YleI7Em1a0RnSCMV55fLRPE2_Emr-wDImZiyhQbpAFScMLdlvK8qapOj7UUsWfh0ddcmZblNbl7hlSXVGMsKvX82OsP9wU-Ky0yU4QnYSSJW8qYebpXMg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h9m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d0000932b321c",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7827,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d0000932b321c_0",
                    "local_arrival": "2024-09-15T19:25:00.000Z",
                    "local_departure": "2024-09-15T16:10:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7827",
                    "return": 0,
                    "utc_arrival": "2024-09-15T17:25:00.000Z",
                    "utc_departure": "2024-09-15T15:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPt4Ab8EAG8qDYl3T-ybF65lKPwZVxkoECzfj10LUVc6Rc_TN2kYXg0jA2k-Lzl7AUPmSavNk05H6id94upr-ciSpC1H-loleXubF2RbN9X5Xdnmjk3QBxpUy9i8WVSnnQYj0Y0ARRrqv5o7rBEjyr7T9V6erc64n5s5cyCs6OSwrFQIjp6eDX2xEwhowTs-gtQfuHYX5Nm1HT6mJm4nXEKgsgJopYEhufilI6oT9GX7FcG5e0hLojIaXk6NI1efvuVwSFTyCfxJkMBrOvloYvfTgZAUPIgTigSUDtL2KrMiGNUEeFrSoxzgf0Iffq8WZLkDmqKoWtA5rO5oOOUNOZU_3nUGqfphxWVlMPsbSmbj5ZbiZe171eKtoOq0TF5TYiqrqqTYzHNNj5VvAz8YOPrYAXbY2ghAyCl4spQ1TMkR9PmyYcOhuqnQ6WcpOHiXLmb3FG9r6Qm7QkG3HPAkGgjAgObAEoGFtIjCEIUA6A2v8sTiVhBM0ij2xNMDnvWdDb3pbsdtaM2wmfmMqUac038w60bAhyK0_fFreZfCYRX0i_AG5sh87mB0vv1FOX2rMA9mFJDabLEamPvBw1IUUrmihTzcE2zkcz6b5S9njTzMre_McqTIp-6mDAnNGL8lCDSkRXvPBAPV_tRQQ3B_RaT7wYeyU0607AePmZI4AMbwULp6CYhhJ2ZLRca1uW8ywH2XZDjSlm8elusEYLWGvdMaH5bBNxEpY1OxctTOlJlXr4hCFJMgJXBv7GFsJG7vl9CAEr3v9vsBR5GIlgD34uT5yJW4fCx0InjbMGsLlw7m7HN_9yjoyYPdGYVZY1Mhtpm4h9kae3AMP1qXdOinW7j-tWf7Eyv2W4gdv4EMjVyVJ1Vgd2YVcrieSK0fiLZ474klMpYUnT01ozPPXZkNAlqCfRYoVW1RrMjrttYn7jX5rNnwgl6PWlzUiQnWudJhux1eplpVqoWkxnTz5gbQxU9omccgrVrn5RX42CTSoAoK3SkzbPA9qkS49aUZ92ZqvKdCQyvVMjQTgIL7uu1GkYWrKDkXnzwYRU-q6dGHjXPSWu2LV8G0WOW0Dxg7fCKwYKK-pmwA-yimQJRXsFdYhZnM8iDYRKDboc6siXp5UkI92KxXb0CcKM6ev4ixteHovsc-n5ixbuzo_zRmHyO2IaShd5o5Cu3wb0YleI7Em1a0RnSCMV55fLRPE2_Emr-wDImZiyhQbpAFScMLdlvK8qapOj7UUsWfh0ddcmZblNbl7hlSXVGMsKvX82OsP9wU-Ky0yU4QnYSSJW8qYebpXMg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPt4Ab8EAG8qDYl3T-ybF65lKPwZVxkoECzfj10LUVc6Rc_TN2kYXg0jA2k-Lzl7AUPmSavNk05H6id94upr-ciSpC1H-loleXubF2RbN9X5Xdnmjk3QBxpUy9i8WVSnnQYj0Y0ARRrqv5o7rBEjyr7T9V6erc64n5s5cyCs6OSwrFQIjp6eDX2xEwhowTs-gtQfuHYX5Nm1HT6mJm4nXEKgsgJopYEhufilI6oT9GX7FcG5e0hLojIaXk6NI1efvuVwSFTyCfxJkMBrOvloYvfTgZAUPIgTigSUDtL2KrMiGNUEeFrSoxzgf0Iffq8WZLkDmqKoWtA5rO5oOOUNOZU_3nUGqfphxWVlMPsbSmbj5ZbiZe171eKtoOq0TF5TYiqrqqTYzHNNj5VvAz8YOPrYAXbY2ghAyCl4spQ1TMkR9PmyYcOhuqnQ6WcpOHiXLmb3FG9r6Qm7QkG3HPAkGgjAgObAEoGFtIjCEIUA6A2v8sTiVhBM0ij2xNMDnvWdDb3pbsdtaM2wmfmMqUac038w60bAhyK0_fFreZfCYRX0i_AG5sh87mB0vv1FOX2rMA9mFJDabLEamPvBw1IUUrmihTzcE2zkcz6b5S9njTzMre_McqTIp-6mDAnNGL8lCDSkRXvPBAPV_tRQQ3B_RaT7wYeyU0607AePmZI4AMbwULp6CYhhJ2ZLRca1uW8ywH2XZDjSlm8elusEYLWGvdMaH5bBNxEpY1OxctTOlJlXr4hCFJMgJXBv7GFsJG7vl9CAEr3v9vsBR5GIlgD34uT5yJW4fCx0InjbMGsLlw7m7HN_9yjoyYPdGYVZY1Mhtpm4h9kae3AMP1qXdOinW7j-tWf7Eyv2W4gdv4EMjVyVJ1Vgd2YVcrieSK0fiLZ474klMpYUnT01ozPPXZkNAlqCfRYoVW1RrMjrttYn7jX5rNnwgl6PWlzUiQnWudJhux1eplpVqoWkxnTz5gbQxU9omccgrVrn5RX42CTSoAoK3SkzbPA9qkS49aUZ92ZqvKdCQyvVMjQTgIL7uu1GkYWrKDkXnzwYRU-q6dGHjXPSWu2LV8G0WOW0Dxg7fCKwYKK-pmwA-yimQJRXsFdYhZnM8iDYRKDboc6siXp5UkI92KxXb0CcKM6ev4ixteHovsc-n5ixbuzo_zRmHyO2IaShd5o5Cu3wb0YleI7Em1a0RnSCMV55fLRPE2_Emr-wDImZiyhQbpAFScMLdlvK8qapOj7UUsWfh0ddcmZblNbl7hlSXVGMsKvX82OsP9wU-Ky0yU4QnYSSJW8qYebpXMg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1213.32,
            "totalPrice": 979
          },
          {
            "allPassengerTotalPrice": 979,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "19:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkIZmOgKvHI6lxzgTJhVkl9ZdhKleqXMDzZ7UOgFDBU5DCgOhtJoTeSgon9hoJ_LdUg695jLH5HktZOU_p0SwF_FjRaECNLxUSevDfVepmLlmM9d0bEhCQ3J_13yM3TsggyVvRu6bCM-rS3-rUuF0zomT5pqUY6oL3glWxOUs1axEKCJb4afzVJBCGlSizh31168ANDYfgYBH5HQvovXtUIK5BrkryBlGH1P19lc3zDULdh0fQi2B6B3Mnj4UxdrYn1e7sWZExOKzXAexYN8mZDRcl_0sIcHHohl6shCQlnA4pyMKiusVc7U6gJTP0wX9Utsp8DFIIIitlKSAMw6_AMflpbT4MvuzXgTiJWQc_VzOyiGWoK4rJNz-yXliYspvm9_jVOzyq_Bi9qKAwJ4Qv2zoeOynwQgH7Ln4cUOVg6LkieP1NtHEsTYeqWEhgjgYkCMKdkz391alEaj9BDVDYc40AHgbjTqltYE-KRjfWAdDoxeQQ5TvY2wRGO3WExri7Ub2b1mhvD2_-FYRQsg0GOoQ87nGarMPF56FznOm4uI7oFJiAQuPcSKdSLQZKIBgWAJ4AVY3LIO9as7RDDM2q6kXbzTI_aJwws7_d_xFv8-yMNys5hGnwal6YGRi3iofatIE4BVq-M3Zbc-dS4szYZtwAM-kmgAk4dY_VKsKiJsqV3buFgHzH16jg70O4R6Z7XgOZsw6uWazF3HC7Vi8foZ3_2mjAEWQ_zjNxLK5ikgMI21WVH1Tuk45Z1nrllra_Ud3Tj1uhELlBO6vWhV0ZYOR3PC2h3Yyy6o9DbK2pagdsoGKi2aNdcnNQqZSHDWr31yNxuu4wuOqkLD6uSbIBi576BKvIVdO4l3wv6VA_HWZGP3mfOOzvYYHSWBgr8MMSFLoOEn9E7oj_76oGOQqy4lfyF-8qIAwpEe43cZdUgqVMmkB27knaOawHK11fsfeW-soEocyQ1iWe96rmQ-j9GzqzRHhQRu4bcAlvMM8DOWZzogiGogJy_lb2yl_bo8kVyxSPL_8Mqog3R0zJEyvC4VAi_h_FxzGkgxc0cU_IzJ-whLnmcbd5iBQfF14HfCob_hkDSPBVABCDNPVPjsjOBIvTRW5boy75-5ICXgry39t7FMdg_lutnWoWhZdpBjbjeVP39CPh48USYjpThX7ZRBBv5H5YJnFV6G3vF-52j0NmdEZPfslkU9Hi_FcivKxDwjVK_shv8TDV3sEGXOk0bz9PEHrsqNb2qjFYl_OCajnQrh23S6xTJDEd_KGP8tXBuvNV3sLbuRiKDI0U_MdsA==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h9m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d0000932b321c",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7827,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d0000932b321c_0",
                    "local_arrival": "2024-09-15T19:25:00.000Z",
                    "local_departure": "2024-09-15T16:10:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7827",
                    "return": 0,
                    "utc_arrival": "2024-09-15T17:25:00.000Z",
                    "utc_departure": "2024-09-15T15:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkIZmOgKvHI6lxzgTJhVkl9ZdhKleqXMDzZ7UOgFDBU5DCgOhtJoTeSgon9hoJ_LdUg695jLH5HktZOU_p0SwF_FjRaECNLxUSevDfVepmLlmM9d0bEhCQ3J_13yM3TsggyVvRu6bCM-rS3-rUuF0zomT5pqUY6oL3glWxOUs1axEKCJb4afzVJBCGlSizh31168ANDYfgYBH5HQvovXtUIK5BrkryBlGH1P19lc3zDULdh0fQi2B6B3Mnj4UxdrYn1e7sWZExOKzXAexYN8mZDRcl_0sIcHHohl6shCQlnA4pyMKiusVc7U6gJTP0wX9Utsp8DFIIIitlKSAMw6_AMflpbT4MvuzXgTiJWQc_VzOyiGWoK4rJNz-yXliYspvm9_jVOzyq_Bi9qKAwJ4Qv2zoeOynwQgH7Ln4cUOVg6LkieP1NtHEsTYeqWEhgjgYkCMKdkz391alEaj9BDVDYc40AHgbjTqltYE-KRjfWAdDoxeQQ5TvY2wRGO3WExri7Ub2b1mhvD2_-FYRQsg0GOoQ87nGarMPF56FznOm4uI7oFJiAQuPcSKdSLQZKIBgWAJ4AVY3LIO9as7RDDM2q6kXbzTI_aJwws7_d_xFv8-yMNys5hGnwal6YGRi3iofatIE4BVq-M3Zbc-dS4szYZtwAM-kmgAk4dY_VKsKiJsqV3buFgHzH16jg70O4R6Z7XgOZsw6uWazF3HC7Vi8foZ3_2mjAEWQ_zjNxLK5ikgMI21WVH1Tuk45Z1nrllra_Ud3Tj1uhELlBO6vWhV0ZYOR3PC2h3Yyy6o9DbK2pagdsoGKi2aNdcnNQqZSHDWr31yNxuu4wuOqkLD6uSbIBi576BKvIVdO4l3wv6VA_HWZGP3mfOOzvYYHSWBgr8MMSFLoOEn9E7oj_76oGOQqy4lfyF-8qIAwpEe43cZdUgqVMmkB27knaOawHK11fsfeW-soEocyQ1iWe96rmQ-j9GzqzRHhQRu4bcAlvMM8DOWZzogiGogJy_lb2yl_bo8kVyxSPL_8Mqog3R0zJEyvC4VAi_h_FxzGkgxc0cU_IzJ-whLnmcbd5iBQfF14HfCob_hkDSPBVABCDNPVPjsjOBIvTRW5boy75-5ICXgry39t7FMdg_lutnWoWhZdpBjbjeVP39CPh48USYjpThX7ZRBBv5H5YJnFV6G3vF-52j0NmdEZPfslkU9Hi_FcivKxDwjVK_shv8TDV3sEGXOk0bz9PEHrsqNb2qjFYl_OCajnQrh23S6xTJDEd_KGP8tXBuvNV3sLbuRiKDI0U_MdsA==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkIZmOgKvHI6lxzgTJhVkl9ZdhKleqXMDzZ7UOgFDBU5DCgOhtJoTeSgon9hoJ_LdUg695jLH5HktZOU_p0SwF_FjRaECNLxUSevDfVepmLlmM9d0bEhCQ3J_13yM3TsggyVvRu6bCM-rS3-rUuF0zomT5pqUY6oL3glWxOUs1axEKCJb4afzVJBCGlSizh31168ANDYfgYBH5HQvovXtUIK5BrkryBlGH1P19lc3zDULdh0fQi2B6B3Mnj4UxdrYn1e7sWZExOKzXAexYN8mZDRcl_0sIcHHohl6shCQlnA4pyMKiusVc7U6gJTP0wX9Utsp8DFIIIitlKSAMw6_AMflpbT4MvuzXgTiJWQc_VzOyiGWoK4rJNz-yXliYspvm9_jVOzyq_Bi9qKAwJ4Qv2zoeOynwQgH7Ln4cUOVg6LkieP1NtHEsTYeqWEhgjgYkCMKdkz391alEaj9BDVDYc40AHgbjTqltYE-KRjfWAdDoxeQQ5TvY2wRGO3WExri7Ub2b1mhvD2_-FYRQsg0GOoQ87nGarMPF56FznOm4uI7oFJiAQuPcSKdSLQZKIBgWAJ4AVY3LIO9as7RDDM2q6kXbzTI_aJwws7_d_xFv8-yMNys5hGnwal6YGRi3iofatIE4BVq-M3Zbc-dS4szYZtwAM-kmgAk4dY_VKsKiJsqV3buFgHzH16jg70O4R6Z7XgOZsw6uWazF3HC7Vi8foZ3_2mjAEWQ_zjNxLK5ikgMI21WVH1Tuk45Z1nrllra_Ud3Tj1uhELlBO6vWhV0ZYOR3PC2h3Yyy6o9DbK2pagdsoGKi2aNdcnNQqZSHDWr31yNxuu4wuOqkLD6uSbIBi576BKvIVdO4l3wv6VA_HWZGP3mfOOzvYYHSWBgr8MMSFLoOEn9E7oj_76oGOQqy4lfyF-8qIAwpEe43cZdUgqVMmkB27knaOawHK11fsfeW-soEocyQ1iWe96rmQ-j9GzqzRHhQRu4bcAlvMM8DOWZzogiGogJy_lb2yl_bo8kVyxSPL_8Mqog3R0zJEyvC4VAi_h_FxzGkgxc0cU_IzJ-whLnmcbd5iBQfF14HfCob_hkDSPBVABCDNPVPjsjOBIvTRW5boy75-5ICXgry39t7FMdg_lutnWoWhZdpBjbjeVP39CPh48USYjpThX7ZRBBv5H5YJnFV6G3vF-52j0NmdEZPfslkU9Hi_FcivKxDwjVK_shv8TDV3sEGXOk0bz9PEHrsqNb2qjFYl_OCajnQrh23S6xTJDEd_KGP8tXBuvNV3sLbuRiKDI0U_MdsA==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1213.99,
            "totalPrice": 979
          },
          {
            "allPassengerTotalPrice": 979,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "20:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gx2UdgRzxrT3qNTo2Bzfz3HF0K7jTN_mB_t2HVbXnuFuMSTSA69TVBnbei7Ck0HzL06DAUzmMT5POShQMuvZjFh1sUOjThE-IrA6GBykRUQMprfsFWRL54IkqEy-de2z2MksWo-xKYxaxLqXXHg6HFVKfq_73klqa8FzfE1F_nSb8l9Ic13nBHXdWUtAZsu1ssoY3FvonvT6DjUzTVmM0Lx_epGrFqWPpjqpkoCDkXTOjI-WY_P_PVHJkYYd4z_UfFwSXb9dQey76Dci2YnEYf7BFHemnubx3a-4rBMBwj6uwa_X7RqkvI44V4EStCMlWPFC8unJMxjLOGVhm6PeKwdnmNXG34LWWoTRi47BVRflfOGN4xpYQspv4IoDI7PJOE-3w0yjxft4vJwkV-co3Cj_iNRq6hTH9keWoAlT4H0dJxbc3Wu6-78L4-S6WG_0B7ATm61aztEIAEyye9Vo9zeoW423-fikM1l3SwtSv9bKfWCJ0Vbal4f_UjtgfaymdWNBnHTXkX6o-BxYUh6h8Zqv-etNxoqHXGR5J-ZquuSDAf3ddMMlzcg0rs61V3CfCb-6SVUIzp83F5CwBnsMpSHU24zItB8oYPu0A60zNuFdc4m21ybMTNXJJ8x27T55NnptI96RuMX7H7EBMSLTfqjbgnIj8RwcvSqiur9ZJWf4inUByDkPWg0PFp8MICLR48UJidlXHo_QjZdv6HWBFsFyakeYHokyI8kieXFgNqkjQi-3befzQOuPhi1_3nM57bmesBP7MjlCQ3FaYrM-SpqE3pNax2gzhVGaD2ri9IhQEa5pJ_QcbOkLhbPdY9rfGtZZWZUzAAZd_U_9lq8Qgf_bmvvq59qwbPvlROFHX_k7lyHa8xMfX5m2Msm1Ew_Gv4EG1CX564aaJTrttAI2EX_wNhb-4Eo-b6m6dTR8g7aaWQHkO3DnPtlrcvkmffw95N5Z7CT8rCbgsbAeZV2VdclcIzjjtr5m2VUlsI59X4XcjjC6N8Qq_HnCpkrIdDPWf2l9HSOPwzK9muPP_AIiD2bpjPm4akOjEVLeipCQoJIkEeoOKhzpYf6AASId8iUDdbjOxR9ttvJNnJdF9E8BG6c7nVIWn98NoOKX0Cnp7E5VBp3srhUC6DIWoz5-RJgBFza0VnjK7rQCHWPC6ym7Lk5t3tKvFKuVcW6sGBl2RPaA1TymCWhJMsacDpPHZDPKYA_Z7q3D076s0MWyC7mP1bMGp-i4vlp2fWhVzYtkLUkak7DTgeKHn3EFSipSCtU7W",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h49m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d000052833457",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 6026,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d000052833457_0",
                    "local_arrival": "2024-09-15T20:05:00.000Z",
                    "local_departure": "2024-09-15T16:50:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "6026",
                    "return": 0,
                    "utc_arrival": "2024-09-15T18:05:00.000Z",
                    "utc_departure": "2024-09-15T15:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gx2UdgRzxrT3qNTo2Bzfz3HF0K7jTN_mB_t2HVbXnuFuMSTSA69TVBnbei7Ck0HzL06DAUzmMT5POShQMuvZjFh1sUOjThE-IrA6GBykRUQMprfsFWRL54IkqEy-de2z2MksWo-xKYxaxLqXXHg6HFVKfq_73klqa8FzfE1F_nSb8l9Ic13nBHXdWUtAZsu1ssoY3FvonvT6DjUzTVmM0Lx_epGrFqWPpjqpkoCDkXTOjI-WY_P_PVHJkYYd4z_UfFwSXb9dQey76Dci2YnEYf7BFHemnubx3a-4rBMBwj6uwa_X7RqkvI44V4EStCMlWPFC8unJMxjLOGVhm6PeKwdnmNXG34LWWoTRi47BVRflfOGN4xpYQspv4IoDI7PJOE-3w0yjxft4vJwkV-co3Cj_iNRq6hTH9keWoAlT4H0dJxbc3Wu6-78L4-S6WG_0B7ATm61aztEIAEyye9Vo9zeoW423-fikM1l3SwtSv9bKfWCJ0Vbal4f_UjtgfaymdWNBnHTXkX6o-BxYUh6h8Zqv-etNxoqHXGR5J-ZquuSDAf3ddMMlzcg0rs61V3CfCb-6SVUIzp83F5CwBnsMpSHU24zItB8oYPu0A60zNuFdc4m21ybMTNXJJ8x27T55NnptI96RuMX7H7EBMSLTfqjbgnIj8RwcvSqiur9ZJWf4inUByDkPWg0PFp8MICLR48UJidlXHo_QjZdv6HWBFsFyakeYHokyI8kieXFgNqkjQi-3befzQOuPhi1_3nM57bmesBP7MjlCQ3FaYrM-SpqE3pNax2gzhVGaD2ri9IhQEa5pJ_QcbOkLhbPdY9rfGtZZWZUzAAZd_U_9lq8Qgf_bmvvq59qwbPvlROFHX_k7lyHa8xMfX5m2Msm1Ew_Gv4EG1CX564aaJTrttAI2EX_wNhb-4Eo-b6m6dTR8g7aaWQHkO3DnPtlrcvkmffw95N5Z7CT8rCbgsbAeZV2VdclcIzjjtr5m2VUlsI59X4XcjjC6N8Qq_HnCpkrIdDPWf2l9HSOPwzK9muPP_AIiD2bpjPm4akOjEVLeipCQoJIkEeoOKhzpYf6AASId8iUDdbjOxR9ttvJNnJdF9E8BG6c7nVIWn98NoOKX0Cnp7E5VBp3srhUC6DIWoz5-RJgBFza0VnjK7rQCHWPC6ym7Lk5t3tKvFKuVcW6sGBl2RPaA1TymCWhJMsacDpPHZDPKYA_Z7q3D076s0MWyC7mP1bMGp-i4vlp2fWhVzYtkLUkak7DTgeKHn3EFSipSCtU7W",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gx2UdgRzxrT3qNTo2Bzfz3HF0K7jTN_mB_t2HVbXnuFuMSTSA69TVBnbei7Ck0HzL06DAUzmMT5POShQMuvZjFh1sUOjThE-IrA6GBykRUQMprfsFWRL54IkqEy-de2z2MksWo-xKYxaxLqXXHg6HFVKfq_73klqa8FzfE1F_nSb8l9Ic13nBHXdWUtAZsu1ssoY3FvonvT6DjUzTVmM0Lx_epGrFqWPpjqpkoCDkXTOjI-WY_P_PVHJkYYd4z_UfFwSXb9dQey76Dci2YnEYf7BFHemnubx3a-4rBMBwj6uwa_X7RqkvI44V4EStCMlWPFC8unJMxjLOGVhm6PeKwdnmNXG34LWWoTRi47BVRflfOGN4xpYQspv4IoDI7PJOE-3w0yjxft4vJwkV-co3Cj_iNRq6hTH9keWoAlT4H0dJxbc3Wu6-78L4-S6WG_0B7ATm61aztEIAEyye9Vo9zeoW423-fikM1l3SwtSv9bKfWCJ0Vbal4f_UjtgfaymdWNBnHTXkX6o-BxYUh6h8Zqv-etNxoqHXGR5J-ZquuSDAf3ddMMlzcg0rs61V3CfCb-6SVUIzp83F5CwBnsMpSHU24zItB8oYPu0A60zNuFdc4m21ybMTNXJJ8x27T55NnptI96RuMX7H7EBMSLTfqjbgnIj8RwcvSqiur9ZJWf4inUByDkPWg0PFp8MICLR48UJidlXHo_QjZdv6HWBFsFyakeYHokyI8kieXFgNqkjQi-3befzQOuPhi1_3nM57bmesBP7MjlCQ3FaYrM-SpqE3pNax2gzhVGaD2ri9IhQEa5pJ_QcbOkLhbPdY9rfGtZZWZUzAAZd_U_9lq8Qgf_bmvvq59qwbPvlROFHX_k7lyHa8xMfX5m2Msm1Ew_Gv4EG1CX564aaJTrttAI2EX_wNhb-4Eo-b6m6dTR8g7aaWQHkO3DnPtlrcvkmffw95N5Z7CT8rCbgsbAeZV2VdclcIzjjtr5m2VUlsI59X4XcjjC6N8Qq_HnCpkrIdDPWf2l9HSOPwzK9muPP_AIiD2bpjPm4akOjEVLeipCQoJIkEeoOKhzpYf6AASId8iUDdbjOxR9ttvJNnJdF9E8BG6c7nVIWn98NoOKX0Cnp7E5VBp3srhUC6DIWoz5-RJgBFza0VnjK7rQCHWPC6ym7Lk5t3tKvFKuVcW6sGBl2RPaA1TymCWhJMsacDpPHZDPKYA_Z7q3D076s0MWyC7mP1bMGp-i4vlp2fWhVzYtkLUkak7DTgeKHn3EFSipSCtU7W",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1218.66,
            "totalPrice": 979
          },
          {
            "allPassengerTotalPrice": 979,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "20:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGpKH9w5Q29xpnIrHxDyoXULT4vSWsUD4rVSdBcYIcwXHxfnhdDuWjU1vRwpfL_UJijgQGHZ4BSbPyjZW8GySYxwta7CnjJpOvXVdW7li3VwZUUqyNgoXJYi3UqwMKfnag6-5e-srR-RKgHiRvMPaaJUXxZhmMnAdDHRZB5smJ7L2JL4achQEiv3nyArTMbPIziUeIhCmjoATx8-KEClKULbxVp-txjlLdgM7AsEVvaWFF237s3ogGqBdBuZ9xO0lgl1JB9X-bMw7vNNyzgDZLnQsTtCfagacs-FZsVyLfk5SOVlz16bUJEkHGPSSkRW5CqVAzBCmJFSKtoGmohGpleAUk6IGuGR4_rG0gqHLuXBkP_MKvPSq0bSzfd5958gfRNwYF2bXCj51iQ5mlroFF7mgLu4BoFkjuWOdaV4kWngJJ5cfhMjQO1TYvEyumWrA5digq2t9kNc2hMPdgae5N9h6F1e08XRjwADIr33KAF8ZgkmWt2lxY3zZVGWyCIT3eGxcS4drJF_wNoj0DM9F5J8w3y5o9we7Wyg_g4sphAuphkddXaR8c7LW1-1VKD5dtdEf6J796dRtZB_omG7iClacz1JLxBvdn6odYUP9v9YhWxNHISDS-UroHFceQurdk774CxRGEF28oog5Ipuqw4cXgoApu8COtsObWqNzbNXqI39KKymkxRiZlKWqmwVXp9wxdrnhnXU3-kYUK0ZsGI6NYGXg7NsRup6sb5pteQHXTvj-ZY2tqjr-jPb57CbzPBSqv3b-iMD1ojNUBBi7AMSjY3lowHAEn4xYZygCCMGbV4hPTA_l-TeA2xtaHr5UjsBaTYrj6MOs-LSGlMPpFCURRxJs0XFJ98oE5jzwEDQ2pX97moHHVfX5E8kxQeLE6U_Z2vGJQAqu586V6evXcFNn9o_uVIRnChVeTav1FLqwW0arn7eb_K3es2_ACCtA12-HeWbVlQ9NkcdLlp-jHBaoRRGoahb6XBAlURtFFDx6niFFXYKQKnCd_MYKKsWbCUyw0uW-9kLxbplBMSZ4zejdnzyOfOgPFiEpJIdH7zs4OVq16KeTtL2-wqnK3PEOQuBS_kFNduSdEZyrMAAzL0ghGCSeFT94OLqszIezJ45lCNddUxEeQbRTJiwKCPoI2Cb_J-I4JdrwLPaAOqLPq5hi9ohU3xoSUe9xKCRHdy5pEhxCT0UsU9dpH-Sb-jl80VNO4mSAHYPSdImr7k6539KVy_s1RjQ0fv_OXn4iMhjvjM0Wri0bW2KX3mksPCDYFzVxTqvfWONvk1lomysBKw==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h49m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d000052833457",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 6026,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d000052833457_0",
                    "local_arrival": "2024-09-15T20:05:00.000Z",
                    "local_departure": "2024-09-15T16:50:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "6026",
                    "return": 0,
                    "utc_arrival": "2024-09-15T18:05:00.000Z",
                    "utc_departure": "2024-09-15T15:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGpKH9w5Q29xpnIrHxDyoXULT4vSWsUD4rVSdBcYIcwXHxfnhdDuWjU1vRwpfL_UJijgQGHZ4BSbPyjZW8GySYxwta7CnjJpOvXVdW7li3VwZUUqyNgoXJYi3UqwMKfnag6-5e-srR-RKgHiRvMPaaJUXxZhmMnAdDHRZB5smJ7L2JL4achQEiv3nyArTMbPIziUeIhCmjoATx8-KEClKULbxVp-txjlLdgM7AsEVvaWFF237s3ogGqBdBuZ9xO0lgl1JB9X-bMw7vNNyzgDZLnQsTtCfagacs-FZsVyLfk5SOVlz16bUJEkHGPSSkRW5CqVAzBCmJFSKtoGmohGpleAUk6IGuGR4_rG0gqHLuXBkP_MKvPSq0bSzfd5958gfRNwYF2bXCj51iQ5mlroFF7mgLu4BoFkjuWOdaV4kWngJJ5cfhMjQO1TYvEyumWrA5digq2t9kNc2hMPdgae5N9h6F1e08XRjwADIr33KAF8ZgkmWt2lxY3zZVGWyCIT3eGxcS4drJF_wNoj0DM9F5J8w3y5o9we7Wyg_g4sphAuphkddXaR8c7LW1-1VKD5dtdEf6J796dRtZB_omG7iClacz1JLxBvdn6odYUP9v9YhWxNHISDS-UroHFceQurdk774CxRGEF28oog5Ipuqw4cXgoApu8COtsObWqNzbNXqI39KKymkxRiZlKWqmwVXp9wxdrnhnXU3-kYUK0ZsGI6NYGXg7NsRup6sb5pteQHXTvj-ZY2tqjr-jPb57CbzPBSqv3b-iMD1ojNUBBi7AMSjY3lowHAEn4xYZygCCMGbV4hPTA_l-TeA2xtaHr5UjsBaTYrj6MOs-LSGlMPpFCURRxJs0XFJ98oE5jzwEDQ2pX97moHHVfX5E8kxQeLE6U_Z2vGJQAqu586V6evXcFNn9o_uVIRnChVeTav1FLqwW0arn7eb_K3es2_ACCtA12-HeWbVlQ9NkcdLlp-jHBaoRRGoahb6XBAlURtFFDx6niFFXYKQKnCd_MYKKsWbCUyw0uW-9kLxbplBMSZ4zejdnzyOfOgPFiEpJIdH7zs4OVq16KeTtL2-wqnK3PEOQuBS_kFNduSdEZyrMAAzL0ghGCSeFT94OLqszIezJ45lCNddUxEeQbRTJiwKCPoI2Cb_J-I4JdrwLPaAOqLPq5hi9ohU3xoSUe9xKCRHdy5pEhxCT0UsU9dpH-Sb-jl80VNO4mSAHYPSdImr7k6539KVy_s1RjQ0fv_OXn4iMhjvjM0Wri0bW2KX3mksPCDYFzVxTqvfWONvk1lomysBKw==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGpKH9w5Q29xpnIrHxDyoXULT4vSWsUD4rVSdBcYIcwXHxfnhdDuWjU1vRwpfL_UJijgQGHZ4BSbPyjZW8GySYxwta7CnjJpOvXVdW7li3VwZUUqyNgoXJYi3UqwMKfnag6-5e-srR-RKgHiRvMPaaJUXxZhmMnAdDHRZB5smJ7L2JL4achQEiv3nyArTMbPIziUeIhCmjoATx8-KEClKULbxVp-txjlLdgM7AsEVvaWFF237s3ogGqBdBuZ9xO0lgl1JB9X-bMw7vNNyzgDZLnQsTtCfagacs-FZsVyLfk5SOVlz16bUJEkHGPSSkRW5CqVAzBCmJFSKtoGmohGpleAUk6IGuGR4_rG0gqHLuXBkP_MKvPSq0bSzfd5958gfRNwYF2bXCj51iQ5mlroFF7mgLu4BoFkjuWOdaV4kWngJJ5cfhMjQO1TYvEyumWrA5digq2t9kNc2hMPdgae5N9h6F1e08XRjwADIr33KAF8ZgkmWt2lxY3zZVGWyCIT3eGxcS4drJF_wNoj0DM9F5J8w3y5o9we7Wyg_g4sphAuphkddXaR8c7LW1-1VKD5dtdEf6J796dRtZB_omG7iClacz1JLxBvdn6odYUP9v9YhWxNHISDS-UroHFceQurdk774CxRGEF28oog5Ipuqw4cXgoApu8COtsObWqNzbNXqI39KKymkxRiZlKWqmwVXp9wxdrnhnXU3-kYUK0ZsGI6NYGXg7NsRup6sb5pteQHXTvj-ZY2tqjr-jPb57CbzPBSqv3b-iMD1ojNUBBi7AMSjY3lowHAEn4xYZygCCMGbV4hPTA_l-TeA2xtaHr5UjsBaTYrj6MOs-LSGlMPpFCURRxJs0XFJ98oE5jzwEDQ2pX97moHHVfX5E8kxQeLE6U_Z2vGJQAqu586V6evXcFNn9o_uVIRnChVeTav1FLqwW0arn7eb_K3es2_ACCtA12-HeWbVlQ9NkcdLlp-jHBaoRRGoahb6XBAlURtFFDx6niFFXYKQKnCd_MYKKsWbCUyw0uW-9kLxbplBMSZ4zejdnzyOfOgPFiEpJIdH7zs4OVq16KeTtL2-wqnK3PEOQuBS_kFNduSdEZyrMAAzL0ghGCSeFT94OLqszIezJ45lCNddUxEeQbRTJiwKCPoI2Cb_J-I4JdrwLPaAOqLPq5hi9ohU3xoSUe9xKCRHdy5pEhxCT0UsU9dpH-Sb-jl80VNO4mSAHYPSdImr7k6539KVy_s1RjQ0fv_OXn4iMhjvjM0Wri0bW2KX3mksPCDYFzVxTqvfWONvk1lomysBKw==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1219.32,
            "totalPrice": 979
          },
          {
            "allPassengerTotalPrice": 980,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlBU2c6MbWo0MvBnbKJ0s2UPLTxCWLV71y-qxsDvzZQI23cRLxO5e_dHNMtbT4p_tGPKJEis6ypBKWJ10Q16USTlZ2WuBySGOC1Qg1Vny5mNw7L1K8IaWa25OL9QGJQFPaHuvWWNFUqnfwPtu54T3qzE6evsNE-zp89f9HIJEEhkYG6RXQ138bLTV7yppNN0V35S3VBaZOKN8U5dkZEurhG4xhh1NiLgHBZUdFeMbF5pskUOdbfLUQRdpdeRwi36Ve14XP6RFkkwdQRDMxzJq83ij9PFYAbR9Dj4aBUnmYYKmgvZ290EtoizaMAKWtDd2mi4fxHQkF3TLltANROaErysdvVBGq1ppjwNyc0HYBfMz_wbaxR7Jmj440eCG1NcgU8Dcmc-dEmCkcIx1-FAUqoS7Kz3F6jGlXxVb4GzUp0DVMEJYUEsSlvZP-lssUehkgQloZ_-W3fmg8GJcIVmwxoXQ8rCJlym4D8y9pu88RR8gtfK5rnKq0PuoBc-8-GIOV7dEwW2So4e6rFCBbI__VZGjSVAy64w5ZGdWFBQHZhuUmAbhoHYfA24p6qyVIeA2c-ZE_9LziNZc4SFZOju-wDBT28yGoVmqFAjY8hjLO__OVOnCELqDWmDB9fXsnDz16PGkwrxVzQwmZ3VPpUI1_9MS4PlCYbvmAy3o4cRTbVktqKNQ1ax9jg94G5h8Dao1GQGoc9qmQ2Wg1A-ILy7ahmZrc5uPECkQ-LW5tnvCThH6Nu6WancGCY_onA-uoWA_C9wa3FupEbhymya57sG85CbcbWO5n3v8DB-UM_PXKopqvCRNlddQ7_uZY2Xgx8fLgh-5QQv3d5ViLTOC6AHNf6B3SBWBDa1bKPf-3OPDo-xc-tmXggYx0hTeUh0BEDUGufeOMdl7YwBHGozt0y-KgciasisVDl3-SGExU88QnOe8TVyaB0ikUoMCb4uFhBNT7vt7OLgmr5HzF2Z2C26NhiH4cpf_fLeCG0IwNVvTtBbmoowCmn9aALenJGDB6re_bgq0MiDcEYQKYibTHoJFFdjcuM3qFFKeyIMDMlcco_xYb7vvy4zkDmLbcCb_ulYEiuRTMEbd_1o2m_n3IsbuHhI9tdVcMus3CJPDWyeHPlnh8KutqhHLCo3l_zmsbPvjbxpVa-xUsxA0Gy-tiatz4x68KejejnQvKsv3-fHmt-tjGLEuLR3Zh0dLIx34IbgcK92iVua47cnxSrKxNDxcPnU_mXOwzb8sQ5hI-tiGFxgUO5K02bZjYTeoJ6jUvBS4HMPkMR80HSrNflZGWICoxlrzI04kcnRE9_RgVWQ1mGAv3X7vyPvEV89h45qr8OJG",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlBU2c6MbWo0MvBnbKJ0s2UPLTxCWLV71y-qxsDvzZQI23cRLxO5e_dHNMtbT4p_tGPKJEis6ypBKWJ10Q16USTlZ2WuBySGOC1Qg1Vny5mNw7L1K8IaWa25OL9QGJQFPaHuvWWNFUqnfwPtu54T3qzE6evsNE-zp89f9HIJEEhkYG6RXQ138bLTV7yppNN0V35S3VBaZOKN8U5dkZEurhG4xhh1NiLgHBZUdFeMbF5pskUOdbfLUQRdpdeRwi36Ve14XP6RFkkwdQRDMxzJq83ij9PFYAbR9Dj4aBUnmYYKmgvZ290EtoizaMAKWtDd2mi4fxHQkF3TLltANROaErysdvVBGq1ppjwNyc0HYBfMz_wbaxR7Jmj440eCG1NcgU8Dcmc-dEmCkcIx1-FAUqoS7Kz3F6jGlXxVb4GzUp0DVMEJYUEsSlvZP-lssUehkgQloZ_-W3fmg8GJcIVmwxoXQ8rCJlym4D8y9pu88RR8gtfK5rnKq0PuoBc-8-GIOV7dEwW2So4e6rFCBbI__VZGjSVAy64w5ZGdWFBQHZhuUmAbhoHYfA24p6qyVIeA2c-ZE_9LziNZc4SFZOju-wDBT28yGoVmqFAjY8hjLO__OVOnCELqDWmDB9fXsnDz16PGkwrxVzQwmZ3VPpUI1_9MS4PlCYbvmAy3o4cRTbVktqKNQ1ax9jg94G5h8Dao1GQGoc9qmQ2Wg1A-ILy7ahmZrc5uPECkQ-LW5tnvCThH6Nu6WancGCY_onA-uoWA_C9wa3FupEbhymya57sG85CbcbWO5n3v8DB-UM_PXKopqvCRNlddQ7_uZY2Xgx8fLgh-5QQv3d5ViLTOC6AHNf6B3SBWBDa1bKPf-3OPDo-xc-tmXggYx0hTeUh0BEDUGufeOMdl7YwBHGozt0y-KgciasisVDl3-SGExU88QnOe8TVyaB0ikUoMCb4uFhBNT7vt7OLgmr5HzF2Z2C26NhiH4cpf_fLeCG0IwNVvTtBbmoowCmn9aALenJGDB6re_bgq0MiDcEYQKYibTHoJFFdjcuM3qFFKeyIMDMlcco_xYb7vvy4zkDmLbcCb_ulYEiuRTMEbd_1o2m_n3IsbuHhI9tdVcMus3CJPDWyeHPlnh8KutqhHLCo3l_zmsbPvjbxpVa-xUsxA0Gy-tiatz4x68KejejnQvKsv3-fHmt-tjGLEuLR3Zh0dLIx34IbgcK92iVua47cnxSrKxNDxcPnU_mXOwzb8sQ5hI-tiGFxgUO5K02bZjYTeoJ6jUvBS4HMPkMR80HSrNflZGWICoxlrzI04kcnRE9_RgVWQ1mGAv3X7vyPvEV89h45qr8OJG",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlBU2c6MbWo0MvBnbKJ0s2UPLTxCWLV71y-qxsDvzZQI23cRLxO5e_dHNMtbT4p_tGPKJEis6ypBKWJ10Q16USTlZ2WuBySGOC1Qg1Vny5mNw7L1K8IaWa25OL9QGJQFPaHuvWWNFUqnfwPtu54T3qzE6evsNE-zp89f9HIJEEhkYG6RXQ138bLTV7yppNN0V35S3VBaZOKN8U5dkZEurhG4xhh1NiLgHBZUdFeMbF5pskUOdbfLUQRdpdeRwi36Ve14XP6RFkkwdQRDMxzJq83ij9PFYAbR9Dj4aBUnmYYKmgvZ290EtoizaMAKWtDd2mi4fxHQkF3TLltANROaErysdvVBGq1ppjwNyc0HYBfMz_wbaxR7Jmj440eCG1NcgU8Dcmc-dEmCkcIx1-FAUqoS7Kz3F6jGlXxVb4GzUp0DVMEJYUEsSlvZP-lssUehkgQloZ_-W3fmg8GJcIVmwxoXQ8rCJlym4D8y9pu88RR8gtfK5rnKq0PuoBc-8-GIOV7dEwW2So4e6rFCBbI__VZGjSVAy64w5ZGdWFBQHZhuUmAbhoHYfA24p6qyVIeA2c-ZE_9LziNZc4SFZOju-wDBT28yGoVmqFAjY8hjLO__OVOnCELqDWmDB9fXsnDz16PGkwrxVzQwmZ3VPpUI1_9MS4PlCYbvmAy3o4cRTbVktqKNQ1ax9jg94G5h8Dao1GQGoc9qmQ2Wg1A-ILy7ahmZrc5uPECkQ-LW5tnvCThH6Nu6WancGCY_onA-uoWA_C9wa3FupEbhymya57sG85CbcbWO5n3v8DB-UM_PXKopqvCRNlddQ7_uZY2Xgx8fLgh-5QQv3d5ViLTOC6AHNf6B3SBWBDa1bKPf-3OPDo-xc-tmXggYx0hTeUh0BEDUGufeOMdl7YwBHGozt0y-KgciasisVDl3-SGExU88QnOe8TVyaB0ikUoMCb4uFhBNT7vt7OLgmr5HzF2Z2C26NhiH4cpf_fLeCG0IwNVvTtBbmoowCmn9aALenJGDB6re_bgq0MiDcEYQKYibTHoJFFdjcuM3qFFKeyIMDMlcco_xYb7vvy4zkDmLbcCb_ulYEiuRTMEbd_1o2m_n3IsbuHhI9tdVcMus3CJPDWyeHPlnh8KutqhHLCo3l_zmsbPvjbxpVa-xUsxA0Gy-tiatz4x68KejejnQvKsv3-fHmt-tjGLEuLR3Zh0dLIx34IbgcK92iVua47cnxSrKxNDxcPnU_mXOwzb8sQ5hI-tiGFxgUO5K02bZjYTeoJ6jUvBS4HMPkMR80HSrNflZGWICoxlrzI04kcnRE9_RgVWQ1mGAv3X7vyPvEV89h45qr8OJG",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e1d00000c82eb81",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e1d00000c82eb81_0",
                    "local_arrival": "2024-10-01T08:55:00.000Z",
                    "local_departure": "2024-10-01T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:55:00.000Z",
                    "utc_departure": "2024-10-01T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1205.78,
            "totalPrice": 980
          },
          {
            "allPassengerTotalPrice": 980,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gf_D0Eap4Q7zjCcS0zuus9ZeHLBCSqiumAePO0qiNYpwR8dXRJq6LzvpmMH4kAKgerApXsrDtPl37VFCo_sZSc_jrGozbwY2zJigWX_tfF6LJDTCSDDVz1O0nKcVTpl_pRyUy1GZse9T3Hc385y7aNbZARmt2vR9ih-KfsuP8MX7b4pnBWP0Us7NI6m5ARaD4Lzqjdn2IRpZbo50ip_Sn_d3kITVnjMecmyGqGl-swQ8ToMpgodF8wboL6aodKYXQM4fEAVdqn_3hBBs0s3nzQFlK8dW_EDnEX618NSwHK4u5cPANlNr7SoRAsHgbnH_0p2gBlRrQO6FtTexhnqSvjA5uLZJ8BM3r6Ab0CsunH7pqww6vT8bKUuG913afrlstAPyBgOlAwzuktswsE5UFiJVKpqI5J07dlvPobBwhUZXad5MNH97D5cTLRY4ENtzAscXLMLTfTVf9j8JruZ9Oo6ZaBz18NXTP6wSRVgUFHyTqZZqWO0LsK9iW3VVQ9BxpM-0O3C1SFq7ZLqsTUC11q95OLFbSL-svaam8X1yitAjUGHe_OiJx4ks9DyPW317mldple7qVbphBwOOrZbbYoBVHxt3jHvj1wcTtRcc1xabnLhvtoE7pFw2wDxO6iphCouy8BITSyuC_EmBJpQqeiRonDuNGARic_W2kvM7f9R3j0rJKWjgm1wS-NzXtUQ1_WMiwb3bKU19nlclXKE9UXpv0bJWgasfFIjwreIifj6GmssDnCjsydboGx8daqRX2-CjI452IV9rM1a7yPC3FjPFgUTEJ9pMj2mE2zZoENvUwL-lhz-HyJ61Tbw6TUVcTdMXpnAA0Gwtsty5CDnRNJZqj1nFV_I1fMmFHclZlt4BNoPf7lxBdjiGv_gzQz8k6cPdaivatQA5dM4s1oGY0fwwg8rx3aeZR7baoNoKFIt2tNzPE1h5L6mKC6Ey7zSQh-RH1TNpj5-xVpMgH4YhjlvFnQCdU6hnl3L3xZp2M5dQJeI7Ik2a7R5wV_r8t83A8MAH52in-6MGYqwFVApRXsJI7IqXmgn0oy9b_Wh6vCSovK_8Gu00fB8Az3K-vzigT9fSbBkKHWG7Melc0sHtfAljjTORFdwVek2VrfY_6_4eSupGV12V4RsmWKiSngC2GLrLemn78ktJlxS69wFz8yKXY8SjsEAvye1SlRb7-CiVkp9_GQ-b3EyvS9iwHAG1_r3kAe1upaLnsTPkN8Mv3auYVEJ86PNVImc899gHdz7lRZ2dc2IYib7sC7jTDDFSZA5JZucq1TeSIUb8NsEGIrtNrXC3KLg8lYr-FQhRME6UjbskQCWRg2MbykNg5Cs7SIKhWyWhfRAanXm5NpiyRVRj5t3NvGw3LDgSE-nEclrw=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gf_D0Eap4Q7zjCcS0zuus9ZeHLBCSqiumAePO0qiNYpwR8dXRJq6LzvpmMH4kAKgerApXsrDtPl37VFCo_sZSc_jrGozbwY2zJigWX_tfF6LJDTCSDDVz1O0nKcVTpl_pRyUy1GZse9T3Hc385y7aNbZARmt2vR9ih-KfsuP8MX7b4pnBWP0Us7NI6m5ARaD4Lzqjdn2IRpZbo50ip_Sn_d3kITVnjMecmyGqGl-swQ8ToMpgodF8wboL6aodKYXQM4fEAVdqn_3hBBs0s3nzQFlK8dW_EDnEX618NSwHK4u5cPANlNr7SoRAsHgbnH_0p2gBlRrQO6FtTexhnqSvjA5uLZJ8BM3r6Ab0CsunH7pqww6vT8bKUuG913afrlstAPyBgOlAwzuktswsE5UFiJVKpqI5J07dlvPobBwhUZXad5MNH97D5cTLRY4ENtzAscXLMLTfTVf9j8JruZ9Oo6ZaBz18NXTP6wSRVgUFHyTqZZqWO0LsK9iW3VVQ9BxpM-0O3C1SFq7ZLqsTUC11q95OLFbSL-svaam8X1yitAjUGHe_OiJx4ks9DyPW317mldple7qVbphBwOOrZbbYoBVHxt3jHvj1wcTtRcc1xabnLhvtoE7pFw2wDxO6iphCouy8BITSyuC_EmBJpQqeiRonDuNGARic_W2kvM7f9R3j0rJKWjgm1wS-NzXtUQ1_WMiwb3bKU19nlclXKE9UXpv0bJWgasfFIjwreIifj6GmssDnCjsydboGx8daqRX2-CjI452IV9rM1a7yPC3FjPFgUTEJ9pMj2mE2zZoENvUwL-lhz-HyJ61Tbw6TUVcTdMXpnAA0Gwtsty5CDnRNJZqj1nFV_I1fMmFHclZlt4BNoPf7lxBdjiGv_gzQz8k6cPdaivatQA5dM4s1oGY0fwwg8rx3aeZR7baoNoKFIt2tNzPE1h5L6mKC6Ey7zSQh-RH1TNpj5-xVpMgH4YhjlvFnQCdU6hnl3L3xZp2M5dQJeI7Ik2a7R5wV_r8t83A8MAH52in-6MGYqwFVApRXsJI7IqXmgn0oy9b_Wh6vCSovK_8Gu00fB8Az3K-vzigT9fSbBkKHWG7Melc0sHtfAljjTORFdwVek2VrfY_6_4eSupGV12V4RsmWKiSngC2GLrLemn78ktJlxS69wFz8yKXY8SjsEAvye1SlRb7-CiVkp9_GQ-b3EyvS9iwHAG1_r3kAe1upaLnsTPkN8Mv3auYVEJ86PNVImc899gHdz7lRZ2dc2IYib7sC7jTDDFSZA5JZucq1TeSIUb8NsEGIrtNrXC3KLg8lYr-FQhRME6UjbskQCWRg2MbykNg5Cs7SIKhWyWhfRAanXm5NpiyRVRj5t3NvGw3LDgSE-nEclrw=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gf_D0Eap4Q7zjCcS0zuus9ZeHLBCSqiumAePO0qiNYpwR8dXRJq6LzvpmMH4kAKgerApXsrDtPl37VFCo_sZSc_jrGozbwY2zJigWX_tfF6LJDTCSDDVz1O0nKcVTpl_pRyUy1GZse9T3Hc385y7aNbZARmt2vR9ih-KfsuP8MX7b4pnBWP0Us7NI6m5ARaD4Lzqjdn2IRpZbo50ip_Sn_d3kITVnjMecmyGqGl-swQ8ToMpgodF8wboL6aodKYXQM4fEAVdqn_3hBBs0s3nzQFlK8dW_EDnEX618NSwHK4u5cPANlNr7SoRAsHgbnH_0p2gBlRrQO6FtTexhnqSvjA5uLZJ8BM3r6Ab0CsunH7pqww6vT8bKUuG913afrlstAPyBgOlAwzuktswsE5UFiJVKpqI5J07dlvPobBwhUZXad5MNH97D5cTLRY4ENtzAscXLMLTfTVf9j8JruZ9Oo6ZaBz18NXTP6wSRVgUFHyTqZZqWO0LsK9iW3VVQ9BxpM-0O3C1SFq7ZLqsTUC11q95OLFbSL-svaam8X1yitAjUGHe_OiJx4ks9DyPW317mldple7qVbphBwOOrZbbYoBVHxt3jHvj1wcTtRcc1xabnLhvtoE7pFw2wDxO6iphCouy8BITSyuC_EmBJpQqeiRonDuNGARic_W2kvM7f9R3j0rJKWjgm1wS-NzXtUQ1_WMiwb3bKU19nlclXKE9UXpv0bJWgasfFIjwreIifj6GmssDnCjsydboGx8daqRX2-CjI452IV9rM1a7yPC3FjPFgUTEJ9pMj2mE2zZoENvUwL-lhz-HyJ61Tbw6TUVcTdMXpnAA0Gwtsty5CDnRNJZqj1nFV_I1fMmFHclZlt4BNoPf7lxBdjiGv_gzQz8k6cPdaivatQA5dM4s1oGY0fwwg8rx3aeZR7baoNoKFIt2tNzPE1h5L6mKC6Ey7zSQh-RH1TNpj5-xVpMgH4YhjlvFnQCdU6hnl3L3xZp2M5dQJeI7Ik2a7R5wV_r8t83A8MAH52in-6MGYqwFVApRXsJI7IqXmgn0oy9b_Wh6vCSovK_8Gu00fB8Az3K-vzigT9fSbBkKHWG7Melc0sHtfAljjTORFdwVek2VrfY_6_4eSupGV12V4RsmWKiSngC2GLrLemn78ktJlxS69wFz8yKXY8SjsEAvye1SlRb7-CiVkp9_GQ-b3EyvS9iwHAG1_r3kAe1upaLnsTPkN8Mv3auYVEJ86PNVImc899gHdz7lRZ2dc2IYib7sC7jTDDFSZA5JZucq1TeSIUb8NsEGIrtNrXC3KLg8lYr-FQhRME6UjbskQCWRg2MbykNg5Cs7SIKhWyWhfRAanXm5NpiyRVRj5t3NvGw3LDgSE-nEclrw=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1213.59,
            "totalPrice": 980
          },
          {
            "allPassengerTotalPrice": 983,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gg8lAfSx743R_LdcbJawA7QIGtXM0Iv5ZywkJGQu1PdIvvuRTtyYlE4XsL05mRsQQK4cjWCC5SoLXMVC-DsYXRLCketUB6XIh9IwI-vRmn3FbAi2HMtUhKCImI32b_cfptTv3TOYtVWEQx4NSnED-EGnom9BIe7T448zQOVDoCam2WfNyJFdrOYnD5ZdQ_u3gq4QTy8F9dgmWaSRfrhv3le4rgtOLTEoy7g1OvIxZOcWV7DoWS-ttMU7_VX4t21gci4SwU4EnlZqyvTocYqoRor0vYQhzpkJkpLWnAKZCIDgJVX_ZgS3MRnGRw6ZAwTcP9c6oSdLQw0Jh3aptFibtxTSVZyqmhps3ZwWhH7al7Csa9GAeEnw8qAXgcl07O01deP28Yn49fiSJg1DSyJpD3UHPm66a9rzA3rRlrE02a3GcdI5ytNTfvxf1WVmq2c7RBYlvIRE0fnu1xqAjabmV3ixua_dZGRLdQxGb983zkWt7eDaWDhGeNdAcRFxZgCg2jaMPHF_MfGzGimOFWDB_jk3rDjnsV4Vtp6Pqm6XDZysOtgZ3MYfzoc3agtWm3J6reaT1pBpn5_XImTWFnHLOMundAlr7jcdsrEA-udKsNFVRkD2dLJUl-VQ_bKfiisoJytJ_RaFIzlO-QmAHffLea4TqqQlY8h73gQTH2-P-Yg1W7T4lTiD6MLulN4LW2o9EDd89EM_wY3vO7ajex-x1IslUZ0GFU91_9h5dg7HzBlpKzpnKRYl6bomN0_wOXIO-LHoMN4_At0lrct36S1RYtIwM4PXqjwF0yV-_jahWDBXGk7ar1cB7c-JdyYYhAS6TYG0NcntceldKbchizNPrqdBZxSmxlN1SxxOcb8qnFYPgKmpVw7kdO9yjdUkZLZkiA4nYYBh9RjlTIJlRGTuPuMtP3OFtH5X8sYHhza4ymKkGC50PkkFjqtBM71NknxE95uM-yeLOuKm8lDBc1hzUihWmbnFWLtBOSaOvI7wB-WnpG8870hJXsAeldZGXffF_OCM6WXjtZLdpXF1YPYAEt3Tjaaeinz_Ov9c5zDoRhQKFE1KoFCwYZDUK7o-TH8lvB7FT54CT2-FVI0avsjGxCOitOJizLZ4PVtOCOKT6H1eIbWU2-O2lbJvJVuoTU8o7UNBaGcBAlidfcNvMSxx9o3arnGOEUAosJ-5SdwU48V1tYLD__2q7zwe55QTgSJfg10BMIkIQvsVXhKdkr71X3884fWijRbNtbbKixSqirGzPTS4EvYiKqM1zn0ZEvodOUgxgZkFUt_lPCfFzI9IXULTVmCnR7klbXAkWy2G_xXefpOEGAxZBdpe04W1fC67O95cdK2UTbXzTHCqPc84Eng==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gg8lAfSx743R_LdcbJawA7QIGtXM0Iv5ZywkJGQu1PdIvvuRTtyYlE4XsL05mRsQQK4cjWCC5SoLXMVC-DsYXRLCketUB6XIh9IwI-vRmn3FbAi2HMtUhKCImI32b_cfptTv3TOYtVWEQx4NSnED-EGnom9BIe7T448zQOVDoCam2WfNyJFdrOYnD5ZdQ_u3gq4QTy8F9dgmWaSRfrhv3le4rgtOLTEoy7g1OvIxZOcWV7DoWS-ttMU7_VX4t21gci4SwU4EnlZqyvTocYqoRor0vYQhzpkJkpLWnAKZCIDgJVX_ZgS3MRnGRw6ZAwTcP9c6oSdLQw0Jh3aptFibtxTSVZyqmhps3ZwWhH7al7Csa9GAeEnw8qAXgcl07O01deP28Yn49fiSJg1DSyJpD3UHPm66a9rzA3rRlrE02a3GcdI5ytNTfvxf1WVmq2c7RBYlvIRE0fnu1xqAjabmV3ixua_dZGRLdQxGb983zkWt7eDaWDhGeNdAcRFxZgCg2jaMPHF_MfGzGimOFWDB_jk3rDjnsV4Vtp6Pqm6XDZysOtgZ3MYfzoc3agtWm3J6reaT1pBpn5_XImTWFnHLOMundAlr7jcdsrEA-udKsNFVRkD2dLJUl-VQ_bKfiisoJytJ_RaFIzlO-QmAHffLea4TqqQlY8h73gQTH2-P-Yg1W7T4lTiD6MLulN4LW2o9EDd89EM_wY3vO7ajex-x1IslUZ0GFU91_9h5dg7HzBlpKzpnKRYl6bomN0_wOXIO-LHoMN4_At0lrct36S1RYtIwM4PXqjwF0yV-_jahWDBXGk7ar1cB7c-JdyYYhAS6TYG0NcntceldKbchizNPrqdBZxSmxlN1SxxOcb8qnFYPgKmpVw7kdO9yjdUkZLZkiA4nYYBh9RjlTIJlRGTuPuMtP3OFtH5X8sYHhza4ymKkGC50PkkFjqtBM71NknxE95uM-yeLOuKm8lDBc1hzUihWmbnFWLtBOSaOvI7wB-WnpG8870hJXsAeldZGXffF_OCM6WXjtZLdpXF1YPYAEt3Tjaaeinz_Ov9c5zDoRhQKFE1KoFCwYZDUK7o-TH8lvB7FT54CT2-FVI0avsjGxCOitOJizLZ4PVtOCOKT6H1eIbWU2-O2lbJvJVuoTU8o7UNBaGcBAlidfcNvMSxx9o3arnGOEUAosJ-5SdwU48V1tYLD__2q7zwe55QTgSJfg10BMIkIQvsVXhKdkr71X3884fWijRbNtbbKixSqirGzPTS4EvYiKqM1zn0ZEvodOUgxgZkFUt_lPCfFzI9IXULTVmCnR7klbXAkWy2G_xXefpOEGAxZBdpe04W1fC67O95cdK2UTbXzTHCqPc84Eng==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gg8lAfSx743R_LdcbJawA7QIGtXM0Iv5ZywkJGQu1PdIvvuRTtyYlE4XsL05mRsQQK4cjWCC5SoLXMVC-DsYXRLCketUB6XIh9IwI-vRmn3FbAi2HMtUhKCImI32b_cfptTv3TOYtVWEQx4NSnED-EGnom9BIe7T448zQOVDoCam2WfNyJFdrOYnD5ZdQ_u3gq4QTy8F9dgmWaSRfrhv3le4rgtOLTEoy7g1OvIxZOcWV7DoWS-ttMU7_VX4t21gci4SwU4EnlZqyvTocYqoRor0vYQhzpkJkpLWnAKZCIDgJVX_ZgS3MRnGRw6ZAwTcP9c6oSdLQw0Jh3aptFibtxTSVZyqmhps3ZwWhH7al7Csa9GAeEnw8qAXgcl07O01deP28Yn49fiSJg1DSyJpD3UHPm66a9rzA3rRlrE02a3GcdI5ytNTfvxf1WVmq2c7RBYlvIRE0fnu1xqAjabmV3ixua_dZGRLdQxGb983zkWt7eDaWDhGeNdAcRFxZgCg2jaMPHF_MfGzGimOFWDB_jk3rDjnsV4Vtp6Pqm6XDZysOtgZ3MYfzoc3agtWm3J6reaT1pBpn5_XImTWFnHLOMundAlr7jcdsrEA-udKsNFVRkD2dLJUl-VQ_bKfiisoJytJ_RaFIzlO-QmAHffLea4TqqQlY8h73gQTH2-P-Yg1W7T4lTiD6MLulN4LW2o9EDd89EM_wY3vO7ajex-x1IslUZ0GFU91_9h5dg7HzBlpKzpnKRYl6bomN0_wOXIO-LHoMN4_At0lrct36S1RYtIwM4PXqjwF0yV-_jahWDBXGk7ar1cB7c-JdyYYhAS6TYG0NcntceldKbchizNPrqdBZxSmxlN1SxxOcb8qnFYPgKmpVw7kdO9yjdUkZLZkiA4nYYBh9RjlTIJlRGTuPuMtP3OFtH5X8sYHhza4ymKkGC50PkkFjqtBM71NknxE95uM-yeLOuKm8lDBc1hzUihWmbnFWLtBOSaOvI7wB-WnpG8870hJXsAeldZGXffF_OCM6WXjtZLdpXF1YPYAEt3Tjaaeinz_Ov9c5zDoRhQKFE1KoFCwYZDUK7o-TH8lvB7FT54CT2-FVI0avsjGxCOitOJizLZ4PVtOCOKT6H1eIbWU2-O2lbJvJVuoTU8o7UNBaGcBAlidfcNvMSxx9o3arnGOEUAosJ-5SdwU48V1tYLD__2q7zwe55QTgSJfg10BMIkIQvsVXhKdkr71X3884fWijRbNtbbKixSqirGzPTS4EvYiKqM1zn0ZEvodOUgxgZkFUt_lPCfFzI9IXULTVmCnR7klbXAkWy2G_xXefpOEGAxZBdpe04W1fC67O95cdK2UTbXzTHCqPc84Eng==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:05",
                "destination": "OAK",
                "duration": "29h30m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e1c000068b2193b",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e1c000068b2193b_0",
                    "local_arrival": "2024-09-30T23:40:00.000Z",
                    "local_departure": "2024-09-30T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-30T21:40:00.000Z",
                    "utc_departure": "2024-09-30T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "LON",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "London",
                    "combination_id": "13a222f54e1d000084885915",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8094,
                    "flyFrom": "PMI",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "13a222f54e1d000084885915_0",
                    "local_arrival": "2024-10-01T08:35:00.000Z",
                    "local_departure": "2024-10-01T07:00:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:35:00.000Z",
                    "utc_departure": "2024-10-01T05:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1213.72,
            "totalPrice": 983
          },
          {
            "allPassengerTotalPrice": 983,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gm0idkI3kBSf3HVd3LPZ1Q0q9WUn8ZosJpvWAtzTUYyhBooOm_yYr9o_1lp9u58up0QT4hAB71CoJWK8xGMF3pR-b84FD2qmRzk8ARa_mvAMj6Hstn0_Gb_N37y3ztkE7tOZG5qu2rgeHHFPPxM7maCl0e9BreDTAEITM-bs7eN_h_Q0g7ne_jYhn0HgVkGu3KE8Wm_2UDj4MzP9QpqqGKGleWpnGOHAxwXcQEzu2x944MpCu3UJYyNUJ6ldSQafLGM77Krnrlehhf0Zfth380SmGVO48IKKHC1EMwhOc7h-1Xs8SyWvcyee9qVFC4NCgQbUhxWykSkl41cHd8azr9yka6UBmNrOEtscsxUH5bCPyn_GMdsQSuYjzaKV2ZTwEJZihgLOb3zol7uoTdYYVOwHS3F1wIgjDluy4QNE4pEkHGFroP9foXEwG3Ytfqt7SghgSdZ2InrklTYeKAOQbTFe6YcKuQfaEAymjBQi9G8gniohqPiDHYzjGVkrDnPVBIcl-JoBHeORhZgN8J_yX-MS_WkLA0sp7thx_6XBj5_mpStbkoywjc4p-InwB2DreaMfbMTFVp5zGg7EDFG139dV7SxRpCAzDtIp-bgrKjdLd8Tw39Z6HqES2rMLPKLJOkiO0L18fTx_dkdDjmJCysinkPsEIvCShj2iErIt8KsShedkM1uhvZjHGL6vlZGUgp5xo-thWc94lQ83t2sK_F-MrJ6KuUwotC8r2nPLUEkx_3WQXRIIHhb-gz0ZChkjcHXBK22UToBIJWj3hhuXSVowWBzP0bAGFaXS62OnWRwxIfq_uUIDDBzydP3sjfRKELBx7LsJf0bOC3T_jvsnpNMpRIDlUVQq_o8HdfY0YGXxislWxeIBMy59-av1K-6sdgABRfp9UvWt0nzttQDaJu06fohEolq1Jj6tSBnAikcOvzUI_o-MN6abhbfLXz37_SbYsipGh33ebGVHNuyabbV4YT6cLro37IRofHVf2kQAQh9fEXj93ZUk2KVUv0fiVcARAFnBc36nZqEt4rLR6wDL6W-w7bLwVzVVf9lrKW3PdaFGc8EeLHbPbHRwVphKIHUWX8EavprUtU6S3aKOe5gaysWEelY4GBS2O6JjahhMY7wqSyz6Qxj3aj5sAmXwc4Rng1GbU9MFZf33PWuWBYT5WJ_H_uguvOVdVsF-QjnZ8qmp1-EC747zr5Ot70lqj-Kmm-Rv-5lqKViclT_-xZsgntIU0C2xds0pZMESKUcfqV2VlACwtsLoZTu8OfN6RdlmcKO_q0A51OE7Sko5Dfeum16auKa8NaMEt7A8fycEtzvhAfPAy6J7wDu3BQdpPOVHE2gDq_Ae29hRtK2fOMg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gm0idkI3kBSf3HVd3LPZ1Q0q9WUn8ZosJpvWAtzTUYyhBooOm_yYr9o_1lp9u58up0QT4hAB71CoJWK8xGMF3pR-b84FD2qmRzk8ARa_mvAMj6Hstn0_Gb_N37y3ztkE7tOZG5qu2rgeHHFPPxM7maCl0e9BreDTAEITM-bs7eN_h_Q0g7ne_jYhn0HgVkGu3KE8Wm_2UDj4MzP9QpqqGKGleWpnGOHAxwXcQEzu2x944MpCu3UJYyNUJ6ldSQafLGM77Krnrlehhf0Zfth380SmGVO48IKKHC1EMwhOc7h-1Xs8SyWvcyee9qVFC4NCgQbUhxWykSkl41cHd8azr9yka6UBmNrOEtscsxUH5bCPyn_GMdsQSuYjzaKV2ZTwEJZihgLOb3zol7uoTdYYVOwHS3F1wIgjDluy4QNE4pEkHGFroP9foXEwG3Ytfqt7SghgSdZ2InrklTYeKAOQbTFe6YcKuQfaEAymjBQi9G8gniohqPiDHYzjGVkrDnPVBIcl-JoBHeORhZgN8J_yX-MS_WkLA0sp7thx_6XBj5_mpStbkoywjc4p-InwB2DreaMfbMTFVp5zGg7EDFG139dV7SxRpCAzDtIp-bgrKjdLd8Tw39Z6HqES2rMLPKLJOkiO0L18fTx_dkdDjmJCysinkPsEIvCShj2iErIt8KsShedkM1uhvZjHGL6vlZGUgp5xo-thWc94lQ83t2sK_F-MrJ6KuUwotC8r2nPLUEkx_3WQXRIIHhb-gz0ZChkjcHXBK22UToBIJWj3hhuXSVowWBzP0bAGFaXS62OnWRwxIfq_uUIDDBzydP3sjfRKELBx7LsJf0bOC3T_jvsnpNMpRIDlUVQq_o8HdfY0YGXxislWxeIBMy59-av1K-6sdgABRfp9UvWt0nzttQDaJu06fohEolq1Jj6tSBnAikcOvzUI_o-MN6abhbfLXz37_SbYsipGh33ebGVHNuyabbV4YT6cLro37IRofHVf2kQAQh9fEXj93ZUk2KVUv0fiVcARAFnBc36nZqEt4rLR6wDL6W-w7bLwVzVVf9lrKW3PdaFGc8EeLHbPbHRwVphKIHUWX8EavprUtU6S3aKOe5gaysWEelY4GBS2O6JjahhMY7wqSyz6Qxj3aj5sAmXwc4Rng1GbU9MFZf33PWuWBYT5WJ_H_uguvOVdVsF-QjnZ8qmp1-EC747zr5Ot70lqj-Kmm-Rv-5lqKViclT_-xZsgntIU0C2xds0pZMESKUcfqV2VlACwtsLoZTu8OfN6RdlmcKO_q0A51OE7Sko5Dfeum16auKa8NaMEt7A8fycEtzvhAfPAy6J7wDu3BQdpPOVHE2gDq_Ae29hRtK2fOMg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gm0idkI3kBSf3HVd3LPZ1Q0q9WUn8ZosJpvWAtzTUYyhBooOm_yYr9o_1lp9u58up0QT4hAB71CoJWK8xGMF3pR-b84FD2qmRzk8ARa_mvAMj6Hstn0_Gb_N37y3ztkE7tOZG5qu2rgeHHFPPxM7maCl0e9BreDTAEITM-bs7eN_h_Q0g7ne_jYhn0HgVkGu3KE8Wm_2UDj4MzP9QpqqGKGleWpnGOHAxwXcQEzu2x944MpCu3UJYyNUJ6ldSQafLGM77Krnrlehhf0Zfth380SmGVO48IKKHC1EMwhOc7h-1Xs8SyWvcyee9qVFC4NCgQbUhxWykSkl41cHd8azr9yka6UBmNrOEtscsxUH5bCPyn_GMdsQSuYjzaKV2ZTwEJZihgLOb3zol7uoTdYYVOwHS3F1wIgjDluy4QNE4pEkHGFroP9foXEwG3Ytfqt7SghgSdZ2InrklTYeKAOQbTFe6YcKuQfaEAymjBQi9G8gniohqPiDHYzjGVkrDnPVBIcl-JoBHeORhZgN8J_yX-MS_WkLA0sp7thx_6XBj5_mpStbkoywjc4p-InwB2DreaMfbMTFVp5zGg7EDFG139dV7SxRpCAzDtIp-bgrKjdLd8Tw39Z6HqES2rMLPKLJOkiO0L18fTx_dkdDjmJCysinkPsEIvCShj2iErIt8KsShedkM1uhvZjHGL6vlZGUgp5xo-thWc94lQ83t2sK_F-MrJ6KuUwotC8r2nPLUEkx_3WQXRIIHhb-gz0ZChkjcHXBK22UToBIJWj3hhuXSVowWBzP0bAGFaXS62OnWRwxIfq_uUIDDBzydP3sjfRKELBx7LsJf0bOC3T_jvsnpNMpRIDlUVQq_o8HdfY0YGXxislWxeIBMy59-av1K-6sdgABRfp9UvWt0nzttQDaJu06fohEolq1Jj6tSBnAikcOvzUI_o-MN6abhbfLXz37_SbYsipGh33ebGVHNuyabbV4YT6cLro37IRofHVf2kQAQh9fEXj93ZUk2KVUv0fiVcARAFnBc36nZqEt4rLR6wDL6W-w7bLwVzVVf9lrKW3PdaFGc8EeLHbPbHRwVphKIHUWX8EavprUtU6S3aKOe5gaysWEelY4GBS2O6JjahhMY7wqSyz6Qxj3aj5sAmXwc4Rng1GbU9MFZf33PWuWBYT5WJ_H_uguvOVdVsF-QjnZ8qmp1-EC747zr5Ot70lqj-Kmm-Rv-5lqKViclT_-xZsgntIU0C2xds0pZMESKUcfqV2VlACwtsLoZTu8OfN6RdlmcKO_q0A51OE7Sko5Dfeum16auKa8NaMEt7A8fycEtzvhAfPAy6J7wDu3BQdpPOVHE2gDq_Ae29hRtK2fOMg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:05",
                "destination": "OAK",
                "duration": "29h30m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e1c000068b2193b",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e1c000068b2193b_0",
                    "local_arrival": "2024-09-30T23:40:00.000Z",
                    "local_departure": "2024-09-30T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-30T21:40:00.000Z",
                    "utc_departure": "2024-09-30T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "LON",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "London",
                    "combination_id": "13a222f54e1d000084885915",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8094,
                    "flyFrom": "PMI",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "13a222f54e1d000084885915_0",
                    "local_arrival": "2024-10-01T08:35:00.000Z",
                    "local_departure": "2024-10-01T07:00:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:35:00.000Z",
                    "utc_departure": "2024-10-01T05:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1214.39,
            "totalPrice": 983
          },
          {
            "allPassengerTotalPrice": 984,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ge7mlQWiRpI2F-BRNIdz30RB146OdLf3ovVZGn2Y7WOE79bs0Q2GRSKshCtvrOhP3b9xMCWr8ogwDrtia-i1J53apISEVYj-QK7Ibb0B-se1npBGVJmEkZ0FNTdY5BR0WxTHli-yRMQoFqUur_hQMQeiy2Pwd9RCTECE7mvKdhLreWK6uYfKwZxhjKnP8C9JtsM6qa53qfrG2wkNyhVeEtKgrGcnq_kI8xgvCyU17UKIH8GdgatfKKn3eS-HnSP2TIh79dwQ3k9Ux_AMCqroKUce7q4KY-lTW6jM1g1cGtIVoJiwMrwMGJKwnPPsUIlOJ1jspPm1nLCv-7TSvIHDv2-9Ln7DhuNaoN4KghuNk6AoS3E1jjvVFMZ2OQeVR-UfLU-_oSMrc1-jaNcW-ZTX_9ACYJlfTYFXlNVmNj-x4j5Iq_oYp6CllLJRGBvw247TcsIAYE-irkUwbApbP6YjJDEZRJS_5UpSy7YHsVy3EApPqPkvdMQl0fuecqYmsyFiaE7Ne5rDC6BSHa3Za1GnyNXalugaFh-HgeiT4PutgUOaWM8dxkoXk96BSauU21EPAMbBtirH0GbPIPeQXMzJ7AhtbOvSgAa6zZ6gSgsqeERAoh4KRi4tNQe1RGih_40km1LN0PwSFkmFjNIlN1zN9aW0CMlGV2-h7eg-dVgdUffh1IN4D1CI66QpnTI2dKKepNWYCUb9M1HSZmahNaihnB9n6IzbGUw-AquYooVg4N5b2xs8QYJY_Byf90RGXonqdnPwH0Wyc-AS8RFlZVZqNNuAp1iYc2RRt0JF6IMkhF6ceVtY9WiHWdcpzTamzeqEa1nLDR7SY4ArE_v_VVmxP7pYdpG6dXX9HV_Ef1rVv_tp7ECYRWfP2VMuxAU_DyGs0-yd5I7CmUiD5yipOWiiLYcqQQmBFbBTOdZfM6E9negkfuF-3cfo17RJtJbo1hRGPila-_DapxOBAQNLpqH3vtKx192_Mh89q6dIJL1EnW7rQcXFJjIFrMp6gijPwU04J4iyw-QHgLINOZlMugBs58_RRA5HAgICLtlUWDRq90dyBa1-3uW1Es5blT22S44QWoe6u13vHVZHShJhCF154A8XEpyUd_QXYt3dB2kM061clRtvzLZKZ-VZzQ0raUCi4N3nxkRACCVwwEuUa0zZqNRWT4qcrw99PnY9XGbQX9D6VMZoQXahuhB-r_Q7gZNMKpQ9rZWemEJ_W9eZuDbSaXTjcCdCildC4H-dy4jGo3ijR5e8Ff9CiLPnBQcVcHtMGBEACXVNUg3gVJ8iDAXTQ7DvK6D1u7MJ3xuoKN1jTUPkVDIkQVtdgEq_9CGVd_vwJK_VrhAfVRhGh-IxUoeYz2g==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ge7mlQWiRpI2F-BRNIdz30RB146OdLf3ovVZGn2Y7WOE79bs0Q2GRSKshCtvrOhP3b9xMCWr8ogwDrtia-i1J53apISEVYj-QK7Ibb0B-se1npBGVJmEkZ0FNTdY5BR0WxTHli-yRMQoFqUur_hQMQeiy2Pwd9RCTECE7mvKdhLreWK6uYfKwZxhjKnP8C9JtsM6qa53qfrG2wkNyhVeEtKgrGcnq_kI8xgvCyU17UKIH8GdgatfKKn3eS-HnSP2TIh79dwQ3k9Ux_AMCqroKUce7q4KY-lTW6jM1g1cGtIVoJiwMrwMGJKwnPPsUIlOJ1jspPm1nLCv-7TSvIHDv2-9Ln7DhuNaoN4KghuNk6AoS3E1jjvVFMZ2OQeVR-UfLU-_oSMrc1-jaNcW-ZTX_9ACYJlfTYFXlNVmNj-x4j5Iq_oYp6CllLJRGBvw247TcsIAYE-irkUwbApbP6YjJDEZRJS_5UpSy7YHsVy3EApPqPkvdMQl0fuecqYmsyFiaE7Ne5rDC6BSHa3Za1GnyNXalugaFh-HgeiT4PutgUOaWM8dxkoXk96BSauU21EPAMbBtirH0GbPIPeQXMzJ7AhtbOvSgAa6zZ6gSgsqeERAoh4KRi4tNQe1RGih_40km1LN0PwSFkmFjNIlN1zN9aW0CMlGV2-h7eg-dVgdUffh1IN4D1CI66QpnTI2dKKepNWYCUb9M1HSZmahNaihnB9n6IzbGUw-AquYooVg4N5b2xs8QYJY_Byf90RGXonqdnPwH0Wyc-AS8RFlZVZqNNuAp1iYc2RRt0JF6IMkhF6ceVtY9WiHWdcpzTamzeqEa1nLDR7SY4ArE_v_VVmxP7pYdpG6dXX9HV_Ef1rVv_tp7ECYRWfP2VMuxAU_DyGs0-yd5I7CmUiD5yipOWiiLYcqQQmBFbBTOdZfM6E9negkfuF-3cfo17RJtJbo1hRGPila-_DapxOBAQNLpqH3vtKx192_Mh89q6dIJL1EnW7rQcXFJjIFrMp6gijPwU04J4iyw-QHgLINOZlMugBs58_RRA5HAgICLtlUWDRq90dyBa1-3uW1Es5blT22S44QWoe6u13vHVZHShJhCF154A8XEpyUd_QXYt3dB2kM061clRtvzLZKZ-VZzQ0raUCi4N3nxkRACCVwwEuUa0zZqNRWT4qcrw99PnY9XGbQX9D6VMZoQXahuhB-r_Q7gZNMKpQ9rZWemEJ_W9eZuDbSaXTjcCdCildC4H-dy4jGo3ijR5e8Ff9CiLPnBQcVcHtMGBEACXVNUg3gVJ8iDAXTQ7DvK6D1u7MJ3xuoKN1jTUPkVDIkQVtdgEq_9CGVd_vwJK_VrhAfVRhGh-IxUoeYz2g==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ge7mlQWiRpI2F-BRNIdz30RB146OdLf3ovVZGn2Y7WOE79bs0Q2GRSKshCtvrOhP3b9xMCWr8ogwDrtia-i1J53apISEVYj-QK7Ibb0B-se1npBGVJmEkZ0FNTdY5BR0WxTHli-yRMQoFqUur_hQMQeiy2Pwd9RCTECE7mvKdhLreWK6uYfKwZxhjKnP8C9JtsM6qa53qfrG2wkNyhVeEtKgrGcnq_kI8xgvCyU17UKIH8GdgatfKKn3eS-HnSP2TIh79dwQ3k9Ux_AMCqroKUce7q4KY-lTW6jM1g1cGtIVoJiwMrwMGJKwnPPsUIlOJ1jspPm1nLCv-7TSvIHDv2-9Ln7DhuNaoN4KghuNk6AoS3E1jjvVFMZ2OQeVR-UfLU-_oSMrc1-jaNcW-ZTX_9ACYJlfTYFXlNVmNj-x4j5Iq_oYp6CllLJRGBvw247TcsIAYE-irkUwbApbP6YjJDEZRJS_5UpSy7YHsVy3EApPqPkvdMQl0fuecqYmsyFiaE7Ne5rDC6BSHa3Za1GnyNXalugaFh-HgeiT4PutgUOaWM8dxkoXk96BSauU21EPAMbBtirH0GbPIPeQXMzJ7AhtbOvSgAa6zZ6gSgsqeERAoh4KRi4tNQe1RGih_40km1LN0PwSFkmFjNIlN1zN9aW0CMlGV2-h7eg-dVgdUffh1IN4D1CI66QpnTI2dKKepNWYCUb9M1HSZmahNaihnB9n6IzbGUw-AquYooVg4N5b2xs8QYJY_Byf90RGXonqdnPwH0Wyc-AS8RFlZVZqNNuAp1iYc2RRt0JF6IMkhF6ceVtY9WiHWdcpzTamzeqEa1nLDR7SY4ArE_v_VVmxP7pYdpG6dXX9HV_Ef1rVv_tp7ECYRWfP2VMuxAU_DyGs0-yd5I7CmUiD5yipOWiiLYcqQQmBFbBTOdZfM6E9negkfuF-3cfo17RJtJbo1hRGPila-_DapxOBAQNLpqH3vtKx192_Mh89q6dIJL1EnW7rQcXFJjIFrMp6gijPwU04J4iyw-QHgLINOZlMugBs58_RRA5HAgICLtlUWDRq90dyBa1-3uW1Es5blT22S44QWoe6u13vHVZHShJhCF154A8XEpyUd_QXYt3dB2kM061clRtvzLZKZ-VZzQ0raUCi4N3nxkRACCVwwEuUa0zZqNRWT4qcrw99PnY9XGbQX9D6VMZoQXahuhB-r_Q7gZNMKpQ9rZWemEJ_W9eZuDbSaXTjcCdCildC4H-dy4jGo3ijR5e8Ff9CiLPnBQcVcHtMGBEACXVNUg3gVJ8iDAXTQ7DvK6D1u7MJ3xuoKN1jTUPkVDIkQVtdgEq_9CGVd_vwJK_VrhAfVRhGh-IxUoeYz2g==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e1d00000c82eb81",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e1d00000c82eb81_0",
                    "local_arrival": "2024-10-01T08:55:00.000Z",
                    "local_departure": "2024-10-01T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:55:00.000Z",
                    "utc_departure": "2024-10-01T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1207.55,
            "totalPrice": 984
          },
          {
            "allPassengerTotalPrice": 984,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0H3IeBwYVp9465c5u7v6qGonsiaE_k5zqH8qVb1c6xXB-xe0Qc6Yf-6We0zt0OwJ08-F-HvG3iLNc1PzQ5y25CQmOrgIjbQOi9RBj_hBYIDVr0CppUUWuyJ0HGEsmATzIQhil12xsO9FR3CzXdkntBfyb4ODh1n3f-ATYxQ8RBggvohcWruKrnTqmklM4bJlg33zfEz2N9D_f0Mi77tyFeSzTyyCSaAU3-mWkNO8jb56i4UWjOi2xlr-uZB9H4VUjg2-r9L_mv-iU0W0SsJ2oC3rcJ8eM9SIeIkiHZsMEdGlnxE4ZK6_5vSWlF2MCvKaEz9IUSa8Fn74Cv9JEvRAGJRNWel8s7a-100Prke22a3Hlwbayc7SBnP87bc7loVAtOoDPO4QuqviyOlDvfXPCldFgn8g6_phggNusbezS6x2c8w5zaIqhBK_g96A1kjmJM8NZ71MNMKtVAYYpMZnEK-i4hQfFcywSQlmmL-HSkFl2QGXZaqttaLF0FGtpDpKyOk7X-pOOitfZ8EYquVO9YbtnGPxab6uVG0Tkvpkf4htSO-Cac0h0uIUNdS4gM5mK-jcqfBncjhLN46tKvZQcOaRxOVa9yRTASyHmuWZ07sLa3ZFNfm7Ly8MWlC_wGd8zrqK0_NfkrByRbrJC_NqNAYcITwzyTDZqmfUpLAoSVkkMEaQsWIjPAPK3ianmAlhR6A_KZQMUhYfJD7zxuFHwYZUZwtH3wXSz9VaCCzZqndF5dNyXuZbS4BOfFWeUldZafjpReDWmFpP5o0U0gw3RAmkXE6iFvB071mPQ7EpChmoDEuI1jm7KIcjxooC9A-265yrCXZjXsACqtZGoR3BLkgDIZn8bL4WDMXefH7ive5WSAez7myDR2uRQwXlHUr57xEE__yimY0CnLv_sf4Vd4_uzspyPqBvWNoKn28U-on1aIP8u-1ZidY1b1SwPo_VDV8kpgCEuqrEktE6qMmKwTmv3DeBWAKi9rUej94CWDfIYS1STenHuQBRl4scBjmzO3FSp2cOxEA9_kYtT51DWVeG7hk_p-XFgq0dPA_q3YtBgDIW1c-Ui6of6TFBTmY58-rmvW0X6Cj7EmK6msJI311ZePxQb0Xt7dgoKKV5RWo60fT1tT8F7gf0-vGmyLba7a4OUyDryuYLqPQGzp5zSQRwQxUZQuik37jo2ZZMmyX21EDLT-LPydo0YOl4MV4xpCzb0mo4FPbc_82yBAe9qZ3H9B-0_a8fvxnYf1SqDzx55M_r8PqbkEvGSVKi1l1E-BltDpsRl9Glt_BYSAr4ef18iMg2lUUf9Y_LCxuWTmAWqApWj5mY_JYOw0W3ZjL_UFW8IWXFjdtzctwpCJIaJgXEwYVdw7--jWZYDxx0ObA=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0H3IeBwYVp9465c5u7v6qGonsiaE_k5zqH8qVb1c6xXB-xe0Qc6Yf-6We0zt0OwJ08-F-HvG3iLNc1PzQ5y25CQmOrgIjbQOi9RBj_hBYIDVr0CppUUWuyJ0HGEsmATzIQhil12xsO9FR3CzXdkntBfyb4ODh1n3f-ATYxQ8RBggvohcWruKrnTqmklM4bJlg33zfEz2N9D_f0Mi77tyFeSzTyyCSaAU3-mWkNO8jb56i4UWjOi2xlr-uZB9H4VUjg2-r9L_mv-iU0W0SsJ2oC3rcJ8eM9SIeIkiHZsMEdGlnxE4ZK6_5vSWlF2MCvKaEz9IUSa8Fn74Cv9JEvRAGJRNWel8s7a-100Prke22a3Hlwbayc7SBnP87bc7loVAtOoDPO4QuqviyOlDvfXPCldFgn8g6_phggNusbezS6x2c8w5zaIqhBK_g96A1kjmJM8NZ71MNMKtVAYYpMZnEK-i4hQfFcywSQlmmL-HSkFl2QGXZaqttaLF0FGtpDpKyOk7X-pOOitfZ8EYquVO9YbtnGPxab6uVG0Tkvpkf4htSO-Cac0h0uIUNdS4gM5mK-jcqfBncjhLN46tKvZQcOaRxOVa9yRTASyHmuWZ07sLa3ZFNfm7Ly8MWlC_wGd8zrqK0_NfkrByRbrJC_NqNAYcITwzyTDZqmfUpLAoSVkkMEaQsWIjPAPK3ianmAlhR6A_KZQMUhYfJD7zxuFHwYZUZwtH3wXSz9VaCCzZqndF5dNyXuZbS4BOfFWeUldZafjpReDWmFpP5o0U0gw3RAmkXE6iFvB071mPQ7EpChmoDEuI1jm7KIcjxooC9A-265yrCXZjXsACqtZGoR3BLkgDIZn8bL4WDMXefH7ive5WSAez7myDR2uRQwXlHUr57xEE__yimY0CnLv_sf4Vd4_uzspyPqBvWNoKn28U-on1aIP8u-1ZidY1b1SwPo_VDV8kpgCEuqrEktE6qMmKwTmv3DeBWAKi9rUej94CWDfIYS1STenHuQBRl4scBjmzO3FSp2cOxEA9_kYtT51DWVeG7hk_p-XFgq0dPA_q3YtBgDIW1c-Ui6of6TFBTmY58-rmvW0X6Cj7EmK6msJI311ZePxQb0Xt7dgoKKV5RWo60fT1tT8F7gf0-vGmyLba7a4OUyDryuYLqPQGzp5zSQRwQxUZQuik37jo2ZZMmyX21EDLT-LPydo0YOl4MV4xpCzb0mo4FPbc_82yBAe9qZ3H9B-0_a8fvxnYf1SqDzx55M_r8PqbkEvGSVKi1l1E-BltDpsRl9Glt_BYSAr4ef18iMg2lUUf9Y_LCxuWTmAWqApWj5mY_JYOw0W3ZjL_UFW8IWXFjdtzctwpCJIaJgXEwYVdw7--jWZYDxx0ObA=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0H3IeBwYVp9465c5u7v6qGonsiaE_k5zqH8qVb1c6xXB-xe0Qc6Yf-6We0zt0OwJ08-F-HvG3iLNc1PzQ5y25CQmOrgIjbQOi9RBj_hBYIDVr0CppUUWuyJ0HGEsmATzIQhil12xsO9FR3CzXdkntBfyb4ODh1n3f-ATYxQ8RBggvohcWruKrnTqmklM4bJlg33zfEz2N9D_f0Mi77tyFeSzTyyCSaAU3-mWkNO8jb56i4UWjOi2xlr-uZB9H4VUjg2-r9L_mv-iU0W0SsJ2oC3rcJ8eM9SIeIkiHZsMEdGlnxE4ZK6_5vSWlF2MCvKaEz9IUSa8Fn74Cv9JEvRAGJRNWel8s7a-100Prke22a3Hlwbayc7SBnP87bc7loVAtOoDPO4QuqviyOlDvfXPCldFgn8g6_phggNusbezS6x2c8w5zaIqhBK_g96A1kjmJM8NZ71MNMKtVAYYpMZnEK-i4hQfFcywSQlmmL-HSkFl2QGXZaqttaLF0FGtpDpKyOk7X-pOOitfZ8EYquVO9YbtnGPxab6uVG0Tkvpkf4htSO-Cac0h0uIUNdS4gM5mK-jcqfBncjhLN46tKvZQcOaRxOVa9yRTASyHmuWZ07sLa3ZFNfm7Ly8MWlC_wGd8zrqK0_NfkrByRbrJC_NqNAYcITwzyTDZqmfUpLAoSVkkMEaQsWIjPAPK3ianmAlhR6A_KZQMUhYfJD7zxuFHwYZUZwtH3wXSz9VaCCzZqndF5dNyXuZbS4BOfFWeUldZafjpReDWmFpP5o0U0gw3RAmkXE6iFvB071mPQ7EpChmoDEuI1jm7KIcjxooC9A-265yrCXZjXsACqtZGoR3BLkgDIZn8bL4WDMXefH7ive5WSAez7myDR2uRQwXlHUr57xEE__yimY0CnLv_sf4Vd4_uzspyPqBvWNoKn28U-on1aIP8u-1ZidY1b1SwPo_VDV8kpgCEuqrEktE6qMmKwTmv3DeBWAKi9rUej94CWDfIYS1STenHuQBRl4scBjmzO3FSp2cOxEA9_kYtT51DWVeG7hk_p-XFgq0dPA_q3YtBgDIW1c-Ui6of6TFBTmY58-rmvW0X6Cj7EmK6msJI311ZePxQb0Xt7dgoKKV5RWo60fT1tT8F7gf0-vGmyLba7a4OUyDryuYLqPQGzp5zSQRwQxUZQuik37jo2ZZMmyX21EDLT-LPydo0YOl4MV4xpCzb0mo4FPbc_82yBAe9qZ3H9B-0_a8fvxnYf1SqDzx55M_r8PqbkEvGSVKi1l1E-BltDpsRl9Glt_BYSAr4ef18iMg2lUUf9Y_LCxuWTmAWqApWj5mY_JYOw0W3ZjL_UFW8IWXFjdtzctwpCJIaJgXEwYVdw7--jWZYDxx0ObA=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e1d00000c82eb81",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e1d00000c82eb81_0",
                    "local_arrival": "2024-10-01T08:55:00.000Z",
                    "local_departure": "2024-10-01T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:55:00.000Z",
                    "utc_departure": "2024-10-01T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1208.22,
            "totalPrice": 984
          },
          {
            "allPassengerTotalPrice": 986,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "19:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRTgpRl4Ewlw6gFyU80tJYAVGEnGZmhfpxRpIUYMyAR0XhhUs9s8lYB3ylA6X_VHgF6pSmSv8qptpIa029xurLFUvlC-E6bEnXRj7MJRopvvUJQud4rDtC68KLZritWCOsQxGeyyp9VizxTCzygr_a_BBmIxqS0Y1CUhTCIB9bxMA5pZsCknL_YAMy1sg8ZW_XhlZOjQf575GfWQAAp69tOrWxaWd6oQQ-qYmUsFpH3q_DFv0PJroTLcukOePWje1IgbVs-JLMgdDNCK2zgQ8zwdQC0WZvDZgndaTqCNpWWzT1NfKvAFWUtr7yR6axxvqCabcfSsDaHQ9vDOELod9iHs-a7tPp24sq3zI7V_e61ihRJCq54cEivXtremOPQBhRAXdR-_ypZtXyba7dN7wYvyxpKfdoO4OIIuCwiDhBzmyNn2eA8IGI6m58BrGbNbkQNAS1P_-4z7csCHSoWyKiMbAYDwn7MzS07UeGqwJeWdoTWQ-uOIQA2Zq6q0LUznR8xw-CY3mfb9OQ1q-MQC1jSPCtH137KHkg3LGMxwD93Oxt4vZKCsn7cYaeoGvfjoLxRTy8KW0A0gWmgoz4Rc15Ea4XqMigcZeLN6hm0julAb4otXH2KTSHGjL8uysZH049p8mCeqA5fUnEvZVUxpasXEQCsRMBNvUh4aOgMNv1nA9yRHiI4ptN757ND9AayiJ4sD51XKQZ7BviUtjBrjENJcOR1YI3-b3WkUGQ7Y8pXHWGtpX68_G2N65bjucKZ4pSqzhzClvi-U8hDgjK0b141uFJMwo6ucRxIuSOINu7iNN3V0vpbDkCYP-6jQ1zhrNRb2Xl9QSpP6HArL5BlZPjNOcYT7-A4HYcHZDZhVSSyPE6Dkl4-jINgXwSF72cgwWTuzDbpNd0j8bXqskNvESltAy6g6AOg36ovgI24YlKA5Hb0mmgI1rZweHzQgTjJ1uy-ptCXww2P1C6YG7RgIsKMRVokpQRxRZFIptdBSlqKx78V2EW-HsuCiK1n3mD19HsPoUP25vDtsRVLnhSP0Wr3bXSXV3M24_uuHZGCH-v5tYFPJJx-8651aHnyiUYX3D8RC7_OEONy9YuuvjkWW7m7jL1xOknIdZWsDtIlLENtlS_FK4av6aMKpek-0o8gvgNmfNje8FjSdnW2NZz9t53wSqWJR_a5-bpIEhOzloJumbmTexTRLBDgn1bvSz-F_NTPFwqU3rYSBr63KzynnCtKc6a2HkiMm7Vx4lPoh1YPy0Tt4A2frQdFnLbkoQEzTa",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h9m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d0000932b321c",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7827,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d0000932b321c_0",
                    "local_arrival": "2024-09-15T19:25:00.000Z",
                    "local_departure": "2024-09-15T16:10:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7827",
                    "return": 0,
                    "utc_arrival": "2024-09-15T17:25:00.000Z",
                    "utc_departure": "2024-09-15T15:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRTgpRl4Ewlw6gFyU80tJYAVGEnGZmhfpxRpIUYMyAR0XhhUs9s8lYB3ylA6X_VHgF6pSmSv8qptpIa029xurLFUvlC-E6bEnXRj7MJRopvvUJQud4rDtC68KLZritWCOsQxGeyyp9VizxTCzygr_a_BBmIxqS0Y1CUhTCIB9bxMA5pZsCknL_YAMy1sg8ZW_XhlZOjQf575GfWQAAp69tOrWxaWd6oQQ-qYmUsFpH3q_DFv0PJroTLcukOePWje1IgbVs-JLMgdDNCK2zgQ8zwdQC0WZvDZgndaTqCNpWWzT1NfKvAFWUtr7yR6axxvqCabcfSsDaHQ9vDOELod9iHs-a7tPp24sq3zI7V_e61ihRJCq54cEivXtremOPQBhRAXdR-_ypZtXyba7dN7wYvyxpKfdoO4OIIuCwiDhBzmyNn2eA8IGI6m58BrGbNbkQNAS1P_-4z7csCHSoWyKiMbAYDwn7MzS07UeGqwJeWdoTWQ-uOIQA2Zq6q0LUznR8xw-CY3mfb9OQ1q-MQC1jSPCtH137KHkg3LGMxwD93Oxt4vZKCsn7cYaeoGvfjoLxRTy8KW0A0gWmgoz4Rc15Ea4XqMigcZeLN6hm0julAb4otXH2KTSHGjL8uysZH049p8mCeqA5fUnEvZVUxpasXEQCsRMBNvUh4aOgMNv1nA9yRHiI4ptN757ND9AayiJ4sD51XKQZ7BviUtjBrjENJcOR1YI3-b3WkUGQ7Y8pXHWGtpX68_G2N65bjucKZ4pSqzhzClvi-U8hDgjK0b141uFJMwo6ucRxIuSOINu7iNN3V0vpbDkCYP-6jQ1zhrNRb2Xl9QSpP6HArL5BlZPjNOcYT7-A4HYcHZDZhVSSyPE6Dkl4-jINgXwSF72cgwWTuzDbpNd0j8bXqskNvESltAy6g6AOg36ovgI24YlKA5Hb0mmgI1rZweHzQgTjJ1uy-ptCXww2P1C6YG7RgIsKMRVokpQRxRZFIptdBSlqKx78V2EW-HsuCiK1n3mD19HsPoUP25vDtsRVLnhSP0Wr3bXSXV3M24_uuHZGCH-v5tYFPJJx-8651aHnyiUYX3D8RC7_OEONy9YuuvjkWW7m7jL1xOknIdZWsDtIlLENtlS_FK4av6aMKpek-0o8gvgNmfNje8FjSdnW2NZz9t53wSqWJR_a5-bpIEhOzloJumbmTexTRLBDgn1bvSz-F_NTPFwqU3rYSBr63KzynnCtKc6a2HkiMm7Vx4lPoh1YPy0Tt4A2frQdFnLbkoQEzTa",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRTgpRl4Ewlw6gFyU80tJYAVGEnGZmhfpxRpIUYMyAR0XhhUs9s8lYB3ylA6X_VHgF6pSmSv8qptpIa029xurLFUvlC-E6bEnXRj7MJRopvvUJQud4rDtC68KLZritWCOsQxGeyyp9VizxTCzygr_a_BBmIxqS0Y1CUhTCIB9bxMA5pZsCknL_YAMy1sg8ZW_XhlZOjQf575GfWQAAp69tOrWxaWd6oQQ-qYmUsFpH3q_DFv0PJroTLcukOePWje1IgbVs-JLMgdDNCK2zgQ8zwdQC0WZvDZgndaTqCNpWWzT1NfKvAFWUtr7yR6axxvqCabcfSsDaHQ9vDOELod9iHs-a7tPp24sq3zI7V_e61ihRJCq54cEivXtremOPQBhRAXdR-_ypZtXyba7dN7wYvyxpKfdoO4OIIuCwiDhBzmyNn2eA8IGI6m58BrGbNbkQNAS1P_-4z7csCHSoWyKiMbAYDwn7MzS07UeGqwJeWdoTWQ-uOIQA2Zq6q0LUznR8xw-CY3mfb9OQ1q-MQC1jSPCtH137KHkg3LGMxwD93Oxt4vZKCsn7cYaeoGvfjoLxRTy8KW0A0gWmgoz4Rc15Ea4XqMigcZeLN6hm0julAb4otXH2KTSHGjL8uysZH049p8mCeqA5fUnEvZVUxpasXEQCsRMBNvUh4aOgMNv1nA9yRHiI4ptN757ND9AayiJ4sD51XKQZ7BviUtjBrjENJcOR1YI3-b3WkUGQ7Y8pXHWGtpX68_G2N65bjucKZ4pSqzhzClvi-U8hDgjK0b141uFJMwo6ucRxIuSOINu7iNN3V0vpbDkCYP-6jQ1zhrNRb2Xl9QSpP6HArL5BlZPjNOcYT7-A4HYcHZDZhVSSyPE6Dkl4-jINgXwSF72cgwWTuzDbpNd0j8bXqskNvESltAy6g6AOg36ovgI24YlKA5Hb0mmgI1rZweHzQgTjJ1uy-ptCXww2P1C6YG7RgIsKMRVokpQRxRZFIptdBSlqKx78V2EW-HsuCiK1n3mD19HsPoUP25vDtsRVLnhSP0Wr3bXSXV3M24_uuHZGCH-v5tYFPJJx-8651aHnyiUYX3D8RC7_OEONy9YuuvjkWW7m7jL1xOknIdZWsDtIlLENtlS_FK4av6aMKpek-0o8gvgNmfNje8FjSdnW2NZz9t53wSqWJR_a5-bpIEhOzloJumbmTexTRLBDgn1bvSz-F_NTPFwqU3rYSBr63KzynnCtKc6a2HkiMm7Vx4lPoh1YPy0Tt4A2frQdFnLbkoQEzTa",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1219.68,
            "totalPrice": 986
          },
          {
            "allPassengerTotalPrice": 986,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "20:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GeBTn_aMasG9P4Ggwk7XkByftk39PgDy7-9NOw6EqS8cLTXQCyNLc1RbY63zmUdau4DeCmmNlCg2aNXEGUAsbjHQhKqJE6n9RJyYTZuL15Tf4tlCPw4UD7ngUQadYzROCBkX3xZ6SKHuPuniLEVEdMr_uTu_JhIA7qlcu6qAtXHZZ6Ai4qZMC62LLxSxHnqGif1eSb4-4GzHVFHZaRfqxIQ3db2bW2TcT5bViMuUsrG3SyzHEMLVtod2QbiZ0spHWf_-UGPyxsU2Tc-GuXiG8zRxOsymOBzciZU-baJjOW9pbAmOoFrl3CVvqPzJKfJdXiYRielLq6UToEuqYt7KH1pimm0iX54g2jRkPcb1TLURTeYrgDe04HfRdY1as5uXbD0-431xWUVYAqHKJ8cJrgH0fbzXPo9oVBohdPdVyUTzaJKZPO3lXr5jBEWZhwKLO-ocxey6hzcv11dR-qkTHy_eqyemfUD_sJCafVGP5SnNeAeSuOVBuMUfw4Kdsn92xomxmIhc3CzsS9spofDF85GUdY9NqCVrOo0cM-80mvqCVP2Mbi_SxENvYHTkxDiyadR-7HGiAmNpg7vKAsjcp9jtvwFRTNA1OayRy7WSsEswV4TQnYuj_dbEQTw4KEG3gADfgXV14yFOj6KEzVvECs6dB-S0a-wpQAzqNJcz-c5bQAbclZg2W91bsjRbmbIKIe-foayT0MVV1ezmAQFgNtAI5CACEPotOIMqub930geMCkoo8XG3koamkqatVTcozjYAlzOTB4J9J6uG8wXCtU3jz5fKvcsqTtyRMcu9TPM5lEVu2TYs7H5gR36sgWQIb_XCJYcza51YlNazGCUrkecsRAS11rPIDBNeOPxctyxA8indN1MuiOA5pxb8jegV3Kc91E6q9uXVE49X_usVzRBYWretz_6ij4NIifsj7YNUT9LwMwEq2-nzklbRi453cLP_JDvoopPe4S0lS5-9WKcLVFkrFbspzRx9gASK0sLDWCOVArQSf2CZQI8cqeq9IzyLQ4Ef_rp4CVq8l2F4N6rOOF_Znu0Wfm-3kb9Jan7j3C3mpFv_4EnUKkUSCV48WiYabyWkNDLcvJTBXVwUVh9SXnsmHc3seEP5cuPs6Hv9zqw04-dU2BXLoRuye9Hcov_XIVtRjkIVIhkBT9t2MzE7o7Uf4Q6T0OUWFzvBJ3dcpO08F7VTHN7ruFkTmgEAEykCu_PhYMbz0q1hQOq4Hm0LbiMio2gHJO9u53uZ9y-4QIR0fsPmo92T8dzj1Ujz4",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h49m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d000052833457",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 6026,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d000052833457_0",
                    "local_arrival": "2024-09-15T20:05:00.000Z",
                    "local_departure": "2024-09-15T16:50:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "6026",
                    "return": 0,
                    "utc_arrival": "2024-09-15T18:05:00.000Z",
                    "utc_departure": "2024-09-15T15:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GeBTn_aMasG9P4Ggwk7XkByftk39PgDy7-9NOw6EqS8cLTXQCyNLc1RbY63zmUdau4DeCmmNlCg2aNXEGUAsbjHQhKqJE6n9RJyYTZuL15Tf4tlCPw4UD7ngUQadYzROCBkX3xZ6SKHuPuniLEVEdMr_uTu_JhIA7qlcu6qAtXHZZ6Ai4qZMC62LLxSxHnqGif1eSb4-4GzHVFHZaRfqxIQ3db2bW2TcT5bViMuUsrG3SyzHEMLVtod2QbiZ0spHWf_-UGPyxsU2Tc-GuXiG8zRxOsymOBzciZU-baJjOW9pbAmOoFrl3CVvqPzJKfJdXiYRielLq6UToEuqYt7KH1pimm0iX54g2jRkPcb1TLURTeYrgDe04HfRdY1as5uXbD0-431xWUVYAqHKJ8cJrgH0fbzXPo9oVBohdPdVyUTzaJKZPO3lXr5jBEWZhwKLO-ocxey6hzcv11dR-qkTHy_eqyemfUD_sJCafVGP5SnNeAeSuOVBuMUfw4Kdsn92xomxmIhc3CzsS9spofDF85GUdY9NqCVrOo0cM-80mvqCVP2Mbi_SxENvYHTkxDiyadR-7HGiAmNpg7vKAsjcp9jtvwFRTNA1OayRy7WSsEswV4TQnYuj_dbEQTw4KEG3gADfgXV14yFOj6KEzVvECs6dB-S0a-wpQAzqNJcz-c5bQAbclZg2W91bsjRbmbIKIe-foayT0MVV1ezmAQFgNtAI5CACEPotOIMqub930geMCkoo8XG3koamkqatVTcozjYAlzOTB4J9J6uG8wXCtU3jz5fKvcsqTtyRMcu9TPM5lEVu2TYs7H5gR36sgWQIb_XCJYcza51YlNazGCUrkecsRAS11rPIDBNeOPxctyxA8indN1MuiOA5pxb8jegV3Kc91E6q9uXVE49X_usVzRBYWretz_6ij4NIifsj7YNUT9LwMwEq2-nzklbRi453cLP_JDvoopPe4S0lS5-9WKcLVFkrFbspzRx9gASK0sLDWCOVArQSf2CZQI8cqeq9IzyLQ4Ef_rp4CVq8l2F4N6rOOF_Znu0Wfm-3kb9Jan7j3C3mpFv_4EnUKkUSCV48WiYabyWkNDLcvJTBXVwUVh9SXnsmHc3seEP5cuPs6Hv9zqw04-dU2BXLoRuye9Hcov_XIVtRjkIVIhkBT9t2MzE7o7Uf4Q6T0OUWFzvBJ3dcpO08F7VTHN7ruFkTmgEAEykCu_PhYMbz0q1hQOq4Hm0LbiMio2gHJO9u53uZ9y-4QIR0fsPmo92T8dzj1Ujz4",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GeBTn_aMasG9P4Ggwk7XkByftk39PgDy7-9NOw6EqS8cLTXQCyNLc1RbY63zmUdau4DeCmmNlCg2aNXEGUAsbjHQhKqJE6n9RJyYTZuL15Tf4tlCPw4UD7ngUQadYzROCBkX3xZ6SKHuPuniLEVEdMr_uTu_JhIA7qlcu6qAtXHZZ6Ai4qZMC62LLxSxHnqGif1eSb4-4GzHVFHZaRfqxIQ3db2bW2TcT5bViMuUsrG3SyzHEMLVtod2QbiZ0spHWf_-UGPyxsU2Tc-GuXiG8zRxOsymOBzciZU-baJjOW9pbAmOoFrl3CVvqPzJKfJdXiYRielLq6UToEuqYt7KH1pimm0iX54g2jRkPcb1TLURTeYrgDe04HfRdY1as5uXbD0-431xWUVYAqHKJ8cJrgH0fbzXPo9oVBohdPdVyUTzaJKZPO3lXr5jBEWZhwKLO-ocxey6hzcv11dR-qkTHy_eqyemfUD_sJCafVGP5SnNeAeSuOVBuMUfw4Kdsn92xomxmIhc3CzsS9spofDF85GUdY9NqCVrOo0cM-80mvqCVP2Mbi_SxENvYHTkxDiyadR-7HGiAmNpg7vKAsjcp9jtvwFRTNA1OayRy7WSsEswV4TQnYuj_dbEQTw4KEG3gADfgXV14yFOj6KEzVvECs6dB-S0a-wpQAzqNJcz-c5bQAbclZg2W91bsjRbmbIKIe-foayT0MVV1ezmAQFgNtAI5CACEPotOIMqub930geMCkoo8XG3koamkqatVTcozjYAlzOTB4J9J6uG8wXCtU3jz5fKvcsqTtyRMcu9TPM5lEVu2TYs7H5gR36sgWQIb_XCJYcza51YlNazGCUrkecsRAS11rPIDBNeOPxctyxA8indN1MuiOA5pxb8jegV3Kc91E6q9uXVE49X_usVzRBYWretz_6ij4NIifsj7YNUT9LwMwEq2-nzklbRi453cLP_JDvoopPe4S0lS5-9WKcLVFkrFbspzRx9gASK0sLDWCOVArQSf2CZQI8cqeq9IzyLQ4Ef_rp4CVq8l2F4N6rOOF_Znu0Wfm-3kb9Jan7j3C3mpFv_4EnUKkUSCV48WiYabyWkNDLcvJTBXVwUVh9SXnsmHc3seEP5cuPs6Hv9zqw04-dU2BXLoRuye9Hcov_XIVtRjkIVIhkBT9t2MzE7o7Uf4Q6T0OUWFzvBJ3dcpO08F7VTHN7ruFkTmgEAEykCu_PhYMbz0q1hQOq4Hm0LbiMio2gHJO9u53uZ9y-4QIR0fsPmo92T8dzj1Ujz4",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1225.01,
            "totalPrice": 986
          },
          {
            "allPassengerTotalPrice": 987,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBasPZFjx0ubDn2EcvYz37b6eldL0vssk6ECs-drWtq2fWFKstOvTNQcV8PI0D4tMstCBkkayZLDf1fLJ-frKIQUY_YQNYA8R4Bvpb4pGz1qd5CbsO_EWgMlOuVzhQwm_Xp03bnfGthFpRvQU8-t-P9bJcCs8oRT-V9MySBqlard7MqKRCIAZ36st2gkX39rt2VCrYhtbwB66miKcQxxeOHMyqQmNlb-3lrphpW-5M-z-ZDYxeD4z5vSwZAfP58dcMLjUBMecYSB1gISs-cTv1trZO2wzMi-v-1jAjjCFU-4t9_wRKa7Uot-r5BbulbQB3WkAc73dgnrY4pOmmkTUJSexL4KelKRss1xg7jB6AouF6rk5obic0dt2yWxSjiGWgU4Dp2Tc3gJ-MbSatKz_RfnQn_Q0rBi4Q0VYmSsoAzLIRJKYMEwYHK_yIepdd5kMCeBTQikX1VmCu6sXT3XnUfrlRkDdv7-Ttj3NCp0us6ThFm123Q5QDCRFfzn59J-MpPok8E659aNREBXa5zPZqIsqIjqEyXik8V9EsnHsPweHFloiLRdqjhbQEIs3kUCZhMp5tBuAgotvpnrDrxdtUjPdw0nkPqtSjDGR_AM_7DLNMtPVCHKVtqeM2pUe13zhEHvmWxQGnc2vo7RWWtXx5CEWoidm82Swp0Ee6YbUJCDO9HB3dnUNao-Fnpp3LED4iOi6LtZuQr70K0pWLO67fsC2Cru9tBfpzP_7r-Y8uA-PNyLDPdy26Rl3477UW6W1cnHr9m47rGZ4j5eEXvBAdUss9M-asFlQqUFEKVD-5FOxoOkBX_1DG-Zgxaj4VG__SBF50B3P-QKs-vq7HrCcTHx0UeLiqVtjJkv5WLrbgq3JC6l3Xh0y3MjfunRUxSutUGgB22UHbaYX-wA0LZpjwabVpL8w2SRdYlgKOAutaYXI66ipWesyjkeSkWEpE8TNocN-X77V9vP_A0pwCpt-fDVx2GzSuqP_SMjeQ_jq2XtKAlL3IUS-1P4Gkol1dXw98X-6xBOddk87uY0b44__KyPABfHwii6GFNB-XERmAnTNIBA0ftkX7xnt4OqQZVmnCkSPnttu1XGvH6uXIEpAkr39KTmZTOksCdnWpbSt7dinpNkQsQcX0Tp_pvTOki6B2X6ZhFF_8Bqeu1Wa123JCAnTi8NCi8se4vQ3qr7NwqsUyy9XlUC4Lp1uVcte7J7SdIIzRzYeDjLY3bW6LlfKEOKRKIL33xBcGFKjHCjtI6IxFiSTO9snfrUDPtGMgCc4",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBasPZFjx0ubDn2EcvYz37b6eldL0vssk6ECs-drWtq2fWFKstOvTNQcV8PI0D4tMstCBkkayZLDf1fLJ-frKIQUY_YQNYA8R4Bvpb4pGz1qd5CbsO_EWgMlOuVzhQwm_Xp03bnfGthFpRvQU8-t-P9bJcCs8oRT-V9MySBqlard7MqKRCIAZ36st2gkX39rt2VCrYhtbwB66miKcQxxeOHMyqQmNlb-3lrphpW-5M-z-ZDYxeD4z5vSwZAfP58dcMLjUBMecYSB1gISs-cTv1trZO2wzMi-v-1jAjjCFU-4t9_wRKa7Uot-r5BbulbQB3WkAc73dgnrY4pOmmkTUJSexL4KelKRss1xg7jB6AouF6rk5obic0dt2yWxSjiGWgU4Dp2Tc3gJ-MbSatKz_RfnQn_Q0rBi4Q0VYmSsoAzLIRJKYMEwYHK_yIepdd5kMCeBTQikX1VmCu6sXT3XnUfrlRkDdv7-Ttj3NCp0us6ThFm123Q5QDCRFfzn59J-MpPok8E659aNREBXa5zPZqIsqIjqEyXik8V9EsnHsPweHFloiLRdqjhbQEIs3kUCZhMp5tBuAgotvpnrDrxdtUjPdw0nkPqtSjDGR_AM_7DLNMtPVCHKVtqeM2pUe13zhEHvmWxQGnc2vo7RWWtXx5CEWoidm82Swp0Ee6YbUJCDO9HB3dnUNao-Fnpp3LED4iOi6LtZuQr70K0pWLO67fsC2Cru9tBfpzP_7r-Y8uA-PNyLDPdy26Rl3477UW6W1cnHr9m47rGZ4j5eEXvBAdUss9M-asFlQqUFEKVD-5FOxoOkBX_1DG-Zgxaj4VG__SBF50B3P-QKs-vq7HrCcTHx0UeLiqVtjJkv5WLrbgq3JC6l3Xh0y3MjfunRUxSutUGgB22UHbaYX-wA0LZpjwabVpL8w2SRdYlgKOAutaYXI66ipWesyjkeSkWEpE8TNocN-X77V9vP_A0pwCpt-fDVx2GzSuqP_SMjeQ_jq2XtKAlL3IUS-1P4Gkol1dXw98X-6xBOddk87uY0b44__KyPABfHwii6GFNB-XERmAnTNIBA0ftkX7xnt4OqQZVmnCkSPnttu1XGvH6uXIEpAkr39KTmZTOksCdnWpbSt7dinpNkQsQcX0Tp_pvTOki6B2X6ZhFF_8Bqeu1Wa123JCAnTi8NCi8se4vQ3qr7NwqsUyy9XlUC4Lp1uVcte7J7SdIIzRzYeDjLY3bW6LlfKEOKRKIL33xBcGFKjHCjtI6IxFiSTO9snfrUDPtGMgCc4",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBasPZFjx0ubDn2EcvYz37b6eldL0vssk6ECs-drWtq2fWFKstOvTNQcV8PI0D4tMstCBkkayZLDf1fLJ-frKIQUY_YQNYA8R4Bvpb4pGz1qd5CbsO_EWgMlOuVzhQwm_Xp03bnfGthFpRvQU8-t-P9bJcCs8oRT-V9MySBqlard7MqKRCIAZ36st2gkX39rt2VCrYhtbwB66miKcQxxeOHMyqQmNlb-3lrphpW-5M-z-ZDYxeD4z5vSwZAfP58dcMLjUBMecYSB1gISs-cTv1trZO2wzMi-v-1jAjjCFU-4t9_wRKa7Uot-r5BbulbQB3WkAc73dgnrY4pOmmkTUJSexL4KelKRss1xg7jB6AouF6rk5obic0dt2yWxSjiGWgU4Dp2Tc3gJ-MbSatKz_RfnQn_Q0rBi4Q0VYmSsoAzLIRJKYMEwYHK_yIepdd5kMCeBTQikX1VmCu6sXT3XnUfrlRkDdv7-Ttj3NCp0us6ThFm123Q5QDCRFfzn59J-MpPok8E659aNREBXa5zPZqIsqIjqEyXik8V9EsnHsPweHFloiLRdqjhbQEIs3kUCZhMp5tBuAgotvpnrDrxdtUjPdw0nkPqtSjDGR_AM_7DLNMtPVCHKVtqeM2pUe13zhEHvmWxQGnc2vo7RWWtXx5CEWoidm82Swp0Ee6YbUJCDO9HB3dnUNao-Fnpp3LED4iOi6LtZuQr70K0pWLO67fsC2Cru9tBfpzP_7r-Y8uA-PNyLDPdy26Rl3477UW6W1cnHr9m47rGZ4j5eEXvBAdUss9M-asFlQqUFEKVD-5FOxoOkBX_1DG-Zgxaj4VG__SBF50B3P-QKs-vq7HrCcTHx0UeLiqVtjJkv5WLrbgq3JC6l3Xh0y3MjfunRUxSutUGgB22UHbaYX-wA0LZpjwabVpL8w2SRdYlgKOAutaYXI66ipWesyjkeSkWEpE8TNocN-X77V9vP_A0pwCpt-fDVx2GzSuqP_SMjeQ_jq2XtKAlL3IUS-1P4Gkol1dXw98X-6xBOddk87uY0b44__KyPABfHwii6GFNB-XERmAnTNIBA0ftkX7xnt4OqQZVmnCkSPnttu1XGvH6uXIEpAkr39KTmZTOksCdnWpbSt7dinpNkQsQcX0Tp_pvTOki6B2X6ZhFF_8Bqeu1Wa123JCAnTi8NCi8se4vQ3qr7NwqsUyy9XlUC4Lp1uVcte7J7SdIIzRzYeDjLY3bW6LlfKEOKRKIL33xBcGFKjHCjtI6IxFiSTO9snfrUDPtGMgCc4",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1224.65,
            "totalPrice": 987
          },
          {
            "allPassengerTotalPrice": 988,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "19:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gb5m7i9mMLR-Y2Usc5OLVmcGowyOblicS4FHrajrhc6Kkt-8tvl1tqYp0QURYJgBFf19aF6j50ry1qjNJZJB2CdtoaW39XaxcT7RA2xbMdt5UhWG08nW-1K5qdaA0VhBmq2s5z4AZgArTrXcHeUzuMjLtd1qThsvQWConvR1P1sU6w66NXijT2oUs25OmE8_NQotARWvXLh4vV1G4R2g63K-Cc0DW9Aq0ZWE8khfmrEDeN8ZPZegJVV3v6o9CzzImZjMuqKeDnXMSF05I4YZReqZKiMq1VnvI_P9MFI4lj3nb7moQgrDPaDCvziKYGlCBxPU80fJnifMb0UIrVaPu6IXJzQDrbSxb96QkBguGGl6WiPjhGbNtIZnaPIbvuWmfDxlVGfnDKT1m7iDG9WTuWtF6crkimzKbTQ2tXobS8mJVQB26QGMqoPVhqowsD1UoHb_dHlK28OwH1x8ymJfz0yq7nuTnKXjrzFvxbIrwlCRNZ8PHnEwOfJGl88V_BJeGwKAds9XkcuIu_desAkyjzIZ9KI0JV1KkH3Fy965a_EkEg1d7wFr4ThWwCrTIjOBBxDVj8A5KoVmRg3RhjraINQRuPgqxDtK11MQBHrYJn6Q-bTE7BlxpWJjm2zlb5uHi8NrLuP5j0hfV96C0CMz4tlD4KsAdamwRfgKYYDQY-9NDH9nOfXwIEyEupAOBuqg3P3NHVf-91F6lLsUk9_uqQ5eOb8DnVA-M_f8No55YH0VmyMhXqtrvQvHNib-I5AZlhpU0pBpTlH1Un34YaHN2rd3C3sciIqMOpK2Qkh6TyxptCc6APFuQ3K2NJTr1kBFTRFTKaLH_apCMUEMwczM8ZDN2odvwFl17vSjLeQaKPOGQ0htsujbW0DtQtjKWI3O6lN5qVe3V8i0UVD0P8h9UfjzfdRxFlduHm6k9iP60o2-TfRpegSSB-2gfFSQYahmwqUZD6WEtwFwIefF9GRL4SK9Cqv3fxFc7BpXMl8f3-ZdTA8g3O_uRU0NRpOn0P7kRghyDX4H3xida8MAeBhr7OLjd18cPq396pH-sufkDbYH9I11xNaynnZm84jpDnhcUdIebfMaXHd5pl6Kh4qjBf7R-WUNvxE1-ta6eliE70Td4gaSUIIDGBNHLA6l-mEaE8bJHlZq5YzfqeHlzOOubhRACcOz2fRFc8028hWBASGQXFHA8Nv9vn2hUtN9ObaTxdYCgrB_A5o66dnodG3oiiLAQ0C2hYuCAbgh3KMZ_vPa5NQ93Y9YzhabCZ-zVSerm",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h9m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d0000932b321c",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7827,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d0000932b321c_0",
                    "local_arrival": "2024-09-15T19:25:00.000Z",
                    "local_departure": "2024-09-15T16:10:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7827",
                    "return": 0,
                    "utc_arrival": "2024-09-15T17:25:00.000Z",
                    "utc_departure": "2024-09-15T15:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gb5m7i9mMLR-Y2Usc5OLVmcGowyOblicS4FHrajrhc6Kkt-8tvl1tqYp0QURYJgBFf19aF6j50ry1qjNJZJB2CdtoaW39XaxcT7RA2xbMdt5UhWG08nW-1K5qdaA0VhBmq2s5z4AZgArTrXcHeUzuMjLtd1qThsvQWConvR1P1sU6w66NXijT2oUs25OmE8_NQotARWvXLh4vV1G4R2g63K-Cc0DW9Aq0ZWE8khfmrEDeN8ZPZegJVV3v6o9CzzImZjMuqKeDnXMSF05I4YZReqZKiMq1VnvI_P9MFI4lj3nb7moQgrDPaDCvziKYGlCBxPU80fJnifMb0UIrVaPu6IXJzQDrbSxb96QkBguGGl6WiPjhGbNtIZnaPIbvuWmfDxlVGfnDKT1m7iDG9WTuWtF6crkimzKbTQ2tXobS8mJVQB26QGMqoPVhqowsD1UoHb_dHlK28OwH1x8ymJfz0yq7nuTnKXjrzFvxbIrwlCRNZ8PHnEwOfJGl88V_BJeGwKAds9XkcuIu_desAkyjzIZ9KI0JV1KkH3Fy965a_EkEg1d7wFr4ThWwCrTIjOBBxDVj8A5KoVmRg3RhjraINQRuPgqxDtK11MQBHrYJn6Q-bTE7BlxpWJjm2zlb5uHi8NrLuP5j0hfV96C0CMz4tlD4KsAdamwRfgKYYDQY-9NDH9nOfXwIEyEupAOBuqg3P3NHVf-91F6lLsUk9_uqQ5eOb8DnVA-M_f8No55YH0VmyMhXqtrvQvHNib-I5AZlhpU0pBpTlH1Un34YaHN2rd3C3sciIqMOpK2Qkh6TyxptCc6APFuQ3K2NJTr1kBFTRFTKaLH_apCMUEMwczM8ZDN2odvwFl17vSjLeQaKPOGQ0htsujbW0DtQtjKWI3O6lN5qVe3V8i0UVD0P8h9UfjzfdRxFlduHm6k9iP60o2-TfRpegSSB-2gfFSQYahmwqUZD6WEtwFwIefF9GRL4SK9Cqv3fxFc7BpXMl8f3-ZdTA8g3O_uRU0NRpOn0P7kRghyDX4H3xida8MAeBhr7OLjd18cPq396pH-sufkDbYH9I11xNaynnZm84jpDnhcUdIebfMaXHd5pl6Kh4qjBf7R-WUNvxE1-ta6eliE70Td4gaSUIIDGBNHLA6l-mEaE8bJHlZq5YzfqeHlzOOubhRACcOz2fRFc8028hWBASGQXFHA8Nv9vn2hUtN9ObaTxdYCgrB_A5o66dnodG3oiiLAQ0C2hYuCAbgh3KMZ_vPa5NQ93Y9YzhabCZ-zVSerm",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gb5m7i9mMLR-Y2Usc5OLVmcGowyOblicS4FHrajrhc6Kkt-8tvl1tqYp0QURYJgBFf19aF6j50ry1qjNJZJB2CdtoaW39XaxcT7RA2xbMdt5UhWG08nW-1K5qdaA0VhBmq2s5z4AZgArTrXcHeUzuMjLtd1qThsvQWConvR1P1sU6w66NXijT2oUs25OmE8_NQotARWvXLh4vV1G4R2g63K-Cc0DW9Aq0ZWE8khfmrEDeN8ZPZegJVV3v6o9CzzImZjMuqKeDnXMSF05I4YZReqZKiMq1VnvI_P9MFI4lj3nb7moQgrDPaDCvziKYGlCBxPU80fJnifMb0UIrVaPu6IXJzQDrbSxb96QkBguGGl6WiPjhGbNtIZnaPIbvuWmfDxlVGfnDKT1m7iDG9WTuWtF6crkimzKbTQ2tXobS8mJVQB26QGMqoPVhqowsD1UoHb_dHlK28OwH1x8ymJfz0yq7nuTnKXjrzFvxbIrwlCRNZ8PHnEwOfJGl88V_BJeGwKAds9XkcuIu_desAkyjzIZ9KI0JV1KkH3Fy965a_EkEg1d7wFr4ThWwCrTIjOBBxDVj8A5KoVmRg3RhjraINQRuPgqxDtK11MQBHrYJn6Q-bTE7BlxpWJjm2zlb5uHi8NrLuP5j0hfV96C0CMz4tlD4KsAdamwRfgKYYDQY-9NDH9nOfXwIEyEupAOBuqg3P3NHVf-91F6lLsUk9_uqQ5eOb8DnVA-M_f8No55YH0VmyMhXqtrvQvHNib-I5AZlhpU0pBpTlH1Un34YaHN2rd3C3sciIqMOpK2Qkh6TyxptCc6APFuQ3K2NJTr1kBFTRFTKaLH_apCMUEMwczM8ZDN2odvwFl17vSjLeQaKPOGQ0htsujbW0DtQtjKWI3O6lN5qVe3V8i0UVD0P8h9UfjzfdRxFlduHm6k9iP60o2-TfRpegSSB-2gfFSQYahmwqUZD6WEtwFwIefF9GRL4SK9Cqv3fxFc7BpXMl8f3-ZdTA8g3O_uRU0NRpOn0P7kRghyDX4H3xida8MAeBhr7OLjd18cPq396pH-sufkDbYH9I11xNaynnZm84jpDnhcUdIebfMaXHd5pl6Kh4qjBf7R-WUNvxE1-ta6eliE70Td4gaSUIIDGBNHLA6l-mEaE8bJHlZq5YzfqeHlzOOubhRACcOz2fRFc8028hWBASGQXFHA8Nv9vn2hUtN9ObaTxdYCgrB_A5o66dnodG3oiiLAQ0C2hYuCAbgh3KMZ_vPa5NQ93Y9YzhabCZ-zVSerm",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1220.99,
            "totalPrice": 988
          },
          {
            "allPassengerTotalPrice": 988,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "20:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJy2nVL69bLoO5iyabCQTUzgfb1W-XkicNlBq1xZ-2ev94eIfzi-aWGBWEUJr3fF-mSGuPQUC3cCTeiuJh9PDCtMNre3NpvEmPu-s7MtGIOQOfWJJNJnN7oZdmto5Y3s2Nxw6ixiTQkQ_-yaaBI9x3TJZ6ffFtgw2itG4MxecJecSujEHXJrwn0kjIldorblQ2Kxgu16igSkzE6tA8s5hgGuKzekgLZp3Jne9aN1nbrIX_MU7F8u15zyEJk6jw3c7QyDDSo7v_oKVFr-NIWKFZL01hHBvEMifSNMxgUObeZ0vLNamzxlo-l_wsk7x7vXAKgVNKxL-4JtjIl_9_QOGhrwo0UKe98MJWKK1f40r1Y4htO6WCFKMvXicLN7BxKf8dQTtS2VnFUv_MKbozIlTcAlH1kghHSzwFjpBHqjeIs75Vk54pqNJZyj92I093bxOzMVhPfzcQGnM_2wNw78JsX2ZpeOP_UmLKOfWK0Wji_FuFZ6CL_vuErz1ioUDvIO14qFgY2KbKiLPgP6FlLZjb359p6Rg1LoQDNQEEpUERvvqnw90djhe5CVZSptiWAzpmnVlluWcmlqxU0eHm0aCvjQ_boevEXa0BiYjnY7aCC6nxNbVOpqbOQl2qsSPOlvk6Pyf7nyt9rze7ukYcmHz00SIj8PRkKQQzp14VueoWN7WiSa2991r7EicxcwL3b3y67su0x8WyrQgcEEtSxsdKYEKmevCL_yADf8NjFpOBHggbxcF5zooNdhM8rftfzDmILY7bsqDDNpqwf7fUAn_4QRjr6HWAA6WODCgOwouEXVZbd9jlcUEwHIQxjg3TFPkWDmDyHsbvjfrrDfzbK4HPdbJMQkeoquDOjJUZvDC4hG-kSCAPyDSAxEXrhrKd0Sd6Z348rgGM-HfyMSmVWtXK7PmQw3KEJyeepfkizIcyYqfK-mKF_Efiq3rqXSc-QuJbHiCYD6G2wHmdE4EGTbWVIsymjUJ5SY_9swtOfsZtqVynU2XAofAQv2UReq6dsHOrqAgE_C4zAK0FnnVadkcRtNS5RyopJe3s_deqYcglUYL8glETjnSsy8aVLCJrPDiOzhtX8USPiZgkaDg0bWuJ60yKvK3koh0GNjijUol72oSP4DaWX_-i2KzZTJ7H3JGEuucpNE_Cix7ypFa3oP9oGkdid3h_cPC2jMsxTFZ0NVT0fuOtdGulrgZMvWhvPnIV3FVIEn-n5irXXqS42KSSocjLSydM0X5OGRThKPJZjJJM4OY8QtlLGmvbVvGLXiz",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h49m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d000052833457",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 6026,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d000052833457_0",
                    "local_arrival": "2024-09-15T20:05:00.000Z",
                    "local_departure": "2024-09-15T16:50:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "6026",
                    "return": 0,
                    "utc_arrival": "2024-09-15T18:05:00.000Z",
                    "utc_departure": "2024-09-15T15:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJy2nVL69bLoO5iyabCQTUzgfb1W-XkicNlBq1xZ-2ev94eIfzi-aWGBWEUJr3fF-mSGuPQUC3cCTeiuJh9PDCtMNre3NpvEmPu-s7MtGIOQOfWJJNJnN7oZdmto5Y3s2Nxw6ixiTQkQ_-yaaBI9x3TJZ6ffFtgw2itG4MxecJecSujEHXJrwn0kjIldorblQ2Kxgu16igSkzE6tA8s5hgGuKzekgLZp3Jne9aN1nbrIX_MU7F8u15zyEJk6jw3c7QyDDSo7v_oKVFr-NIWKFZL01hHBvEMifSNMxgUObeZ0vLNamzxlo-l_wsk7x7vXAKgVNKxL-4JtjIl_9_QOGhrwo0UKe98MJWKK1f40r1Y4htO6WCFKMvXicLN7BxKf8dQTtS2VnFUv_MKbozIlTcAlH1kghHSzwFjpBHqjeIs75Vk54pqNJZyj92I093bxOzMVhPfzcQGnM_2wNw78JsX2ZpeOP_UmLKOfWK0Wji_FuFZ6CL_vuErz1ioUDvIO14qFgY2KbKiLPgP6FlLZjb359p6Rg1LoQDNQEEpUERvvqnw90djhe5CVZSptiWAzpmnVlluWcmlqxU0eHm0aCvjQ_boevEXa0BiYjnY7aCC6nxNbVOpqbOQl2qsSPOlvk6Pyf7nyt9rze7ukYcmHz00SIj8PRkKQQzp14VueoWN7WiSa2991r7EicxcwL3b3y67su0x8WyrQgcEEtSxsdKYEKmevCL_yADf8NjFpOBHggbxcF5zooNdhM8rftfzDmILY7bsqDDNpqwf7fUAn_4QRjr6HWAA6WODCgOwouEXVZbd9jlcUEwHIQxjg3TFPkWDmDyHsbvjfrrDfzbK4HPdbJMQkeoquDOjJUZvDC4hG-kSCAPyDSAxEXrhrKd0Sd6Z348rgGM-HfyMSmVWtXK7PmQw3KEJyeepfkizIcyYqfK-mKF_Efiq3rqXSc-QuJbHiCYD6G2wHmdE4EGTbWVIsymjUJ5SY_9swtOfsZtqVynU2XAofAQv2UReq6dsHOrqAgE_C4zAK0FnnVadkcRtNS5RyopJe3s_deqYcglUYL8glETjnSsy8aVLCJrPDiOzhtX8USPiZgkaDg0bWuJ60yKvK3koh0GNjijUol72oSP4DaWX_-i2KzZTJ7H3JGEuucpNE_Cix7ypFa3oP9oGkdid3h_cPC2jMsxTFZ0NVT0fuOtdGulrgZMvWhvPnIV3FVIEn-n5irXXqS42KSSocjLSydM0X5OGRThKPJZjJJM4OY8QtlLGmvbVvGLXiz",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJy2nVL69bLoO5iyabCQTUzgfb1W-XkicNlBq1xZ-2ev94eIfzi-aWGBWEUJr3fF-mSGuPQUC3cCTeiuJh9PDCtMNre3NpvEmPu-s7MtGIOQOfWJJNJnN7oZdmto5Y3s2Nxw6ixiTQkQ_-yaaBI9x3TJZ6ffFtgw2itG4MxecJecSujEHXJrwn0kjIldorblQ2Kxgu16igSkzE6tA8s5hgGuKzekgLZp3Jne9aN1nbrIX_MU7F8u15zyEJk6jw3c7QyDDSo7v_oKVFr-NIWKFZL01hHBvEMifSNMxgUObeZ0vLNamzxlo-l_wsk7x7vXAKgVNKxL-4JtjIl_9_QOGhrwo0UKe98MJWKK1f40r1Y4htO6WCFKMvXicLN7BxKf8dQTtS2VnFUv_MKbozIlTcAlH1kghHSzwFjpBHqjeIs75Vk54pqNJZyj92I093bxOzMVhPfzcQGnM_2wNw78JsX2ZpeOP_UmLKOfWK0Wji_FuFZ6CL_vuErz1ioUDvIO14qFgY2KbKiLPgP6FlLZjb359p6Rg1LoQDNQEEpUERvvqnw90djhe5CVZSptiWAzpmnVlluWcmlqxU0eHm0aCvjQ_boevEXa0BiYjnY7aCC6nxNbVOpqbOQl2qsSPOlvk6Pyf7nyt9rze7ukYcmHz00SIj8PRkKQQzp14VueoWN7WiSa2991r7EicxcwL3b3y67su0x8WyrQgcEEtSxsdKYEKmevCL_yADf8NjFpOBHggbxcF5zooNdhM8rftfzDmILY7bsqDDNpqwf7fUAn_4QRjr6HWAA6WODCgOwouEXVZbd9jlcUEwHIQxjg3TFPkWDmDyHsbvjfrrDfzbK4HPdbJMQkeoquDOjJUZvDC4hG-kSCAPyDSAxEXrhrKd0Sd6Z348rgGM-HfyMSmVWtXK7PmQw3KEJyeepfkizIcyYqfK-mKF_Efiq3rqXSc-QuJbHiCYD6G2wHmdE4EGTbWVIsymjUJ5SY_9swtOfsZtqVynU2XAofAQv2UReq6dsHOrqAgE_C4zAK0FnnVadkcRtNS5RyopJe3s_deqYcglUYL8glETjnSsy8aVLCJrPDiOzhtX8USPiZgkaDg0bWuJ60yKvK3koh0GNjijUol72oSP4DaWX_-i2KzZTJ7H3JGEuucpNE_Cix7ypFa3oP9oGkdid3h_cPC2jMsxTFZ0NVT0fuOtdGulrgZMvWhvPnIV3FVIEn-n5irXXqS42KSSocjLSydM0X5OGRThKPJZjJJM4OY8QtlLGmvbVvGLXiz",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1226.32,
            "totalPrice": 988
          },
          {
            "allPassengerTotalPrice": 990,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GWDE8Oem-XGr5FU_T6OLRtLJQJR0rsrOvP2xWmd_4dB5xweZP_9UMFKCsU27ZsTVPldd8vKvxvPApgXTO8KUl3yzQl4h2PsccS0wcCYLFwM9Rn8y84RKfjgL0HnbNqkQrJRcU8pSQE7aXnH3ODbc31SDS9qZAGYltwJPX4UtMrJvD3wDQ6NbqldS-BzoJ7rwkCtq8bjxvBAqGvxG7xAbEvMADq5JlmcF-uHqoqshD9XiB0rKI1tVTLaLNcr5yZYwV0eCZh__VeOPHrcAl-zrELvuh0yaOb-OeXYwHUhyUOnrVo6IE3wf3qcqb8HaQorcZSeeJpedSsbufInVtq1PZ_0a0QixevOWBwhlOELQYBgABJGbBvAt_sH4V7JSzgi4I4q_v2u4wdtZGqOQL6pJ_qOD3DMsI6uPKyCB_GDFNIVhsypauvCF-amHr94_VHLb963AcJGUB1KCXCf_q9tFRlpO9PghS8fPoQG2aEMFrIw0I2GCm22JTAPMojlHMpNpvUAAknkILbrhKLZEDGcjZbi5LAZ6LYcSulxSJ1ERucRIbvUryV21EfkmfKIEdYuDyJZTbQafTs7vbG_VHzDzWI1CMjw_qG1qeJw2-txNaYTOqq4iBeInHKijnUIpXOSCbCz0COZgnurLsV4XKdC79UIaLDG19KFZgsCaKxy27aZMP0FsQwfubsu9i41mWidH_sCiS9A6lmjdD98P7icRwtBJgrjj342PWg0aDMiOvvYCF8GKQRJsNgY1WA2uc52pR26MkvRN1bbju-ftNDGV7XzbDZmqXOBLonUHOA5K5F_iiGHi6KfWHZQtJHGV1CHYh1auY3P5qpBrlWoOl9OSq6kJI_BBQHCIEfezk7hA7uTfBPabmLtpWvS15ehwZOJA1qMCHYh179Oa59dMDpYB6XeBuNFznw5WlW_IHrf5kgYg0-_jTfr_3Z3KeQuHj72_zGb3qxviX-HwVIwqh6JgV--QTEZHgRYslbcWncYG1az4HhveUX6A8WKi4WaRDQGjgy02ar5bHx8yQ0k3jHSjc1ey-s_ws72HXPLJxlQKOJcCzCQQCKq0QVmKpizcnpYO6r8vZW72TQhdMyix_h7Bj-pvGQVT7SNf4lwP1xNKiclJa9uqBfcFLoDbFck9NaWlAZSXAgXE_c9YA7PjNNrQgEzueJgWAElHteUrd6vwACmPhzsfT07QKxb7VGQcoHzkynvfZ4AHME8YS4sAn5gQTkWEeyUwmoiNU1407iaEcZUlAz8CDjog8BN2x2ZFQI70gmrolA6jh9KLYz_47apdWBtzhqM20Jo7kYfCrrXviI9yt09AIsvccq4tFQnPuh_IOnsyfFRfnmZPUqCABUzRxNA==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GWDE8Oem-XGr5FU_T6OLRtLJQJR0rsrOvP2xWmd_4dB5xweZP_9UMFKCsU27ZsTVPldd8vKvxvPApgXTO8KUl3yzQl4h2PsccS0wcCYLFwM9Rn8y84RKfjgL0HnbNqkQrJRcU8pSQE7aXnH3ODbc31SDS9qZAGYltwJPX4UtMrJvD3wDQ6NbqldS-BzoJ7rwkCtq8bjxvBAqGvxG7xAbEvMADq5JlmcF-uHqoqshD9XiB0rKI1tVTLaLNcr5yZYwV0eCZh__VeOPHrcAl-zrELvuh0yaOb-OeXYwHUhyUOnrVo6IE3wf3qcqb8HaQorcZSeeJpedSsbufInVtq1PZ_0a0QixevOWBwhlOELQYBgABJGbBvAt_sH4V7JSzgi4I4q_v2u4wdtZGqOQL6pJ_qOD3DMsI6uPKyCB_GDFNIVhsypauvCF-amHr94_VHLb963AcJGUB1KCXCf_q9tFRlpO9PghS8fPoQG2aEMFrIw0I2GCm22JTAPMojlHMpNpvUAAknkILbrhKLZEDGcjZbi5LAZ6LYcSulxSJ1ERucRIbvUryV21EfkmfKIEdYuDyJZTbQafTs7vbG_VHzDzWI1CMjw_qG1qeJw2-txNaYTOqq4iBeInHKijnUIpXOSCbCz0COZgnurLsV4XKdC79UIaLDG19KFZgsCaKxy27aZMP0FsQwfubsu9i41mWidH_sCiS9A6lmjdD98P7icRwtBJgrjj342PWg0aDMiOvvYCF8GKQRJsNgY1WA2uc52pR26MkvRN1bbju-ftNDGV7XzbDZmqXOBLonUHOA5K5F_iiGHi6KfWHZQtJHGV1CHYh1auY3P5qpBrlWoOl9OSq6kJI_BBQHCIEfezk7hA7uTfBPabmLtpWvS15ehwZOJA1qMCHYh179Oa59dMDpYB6XeBuNFznw5WlW_IHrf5kgYg0-_jTfr_3Z3KeQuHj72_zGb3qxviX-HwVIwqh6JgV--QTEZHgRYslbcWncYG1az4HhveUX6A8WKi4WaRDQGjgy02ar5bHx8yQ0k3jHSjc1ey-s_ws72HXPLJxlQKOJcCzCQQCKq0QVmKpizcnpYO6r8vZW72TQhdMyix_h7Bj-pvGQVT7SNf4lwP1xNKiclJa9uqBfcFLoDbFck9NaWlAZSXAgXE_c9YA7PjNNrQgEzueJgWAElHteUrd6vwACmPhzsfT07QKxb7VGQcoHzkynvfZ4AHME8YS4sAn5gQTkWEeyUwmoiNU1407iaEcZUlAz8CDjog8BN2x2ZFQI70gmrolA6jh9KLYz_47apdWBtzhqM20Jo7kYfCrrXviI9yt09AIsvccq4tFQnPuh_IOnsyfFRfnmZPUqCABUzRxNA==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GWDE8Oem-XGr5FU_T6OLRtLJQJR0rsrOvP2xWmd_4dB5xweZP_9UMFKCsU27ZsTVPldd8vKvxvPApgXTO8KUl3yzQl4h2PsccS0wcCYLFwM9Rn8y84RKfjgL0HnbNqkQrJRcU8pSQE7aXnH3ODbc31SDS9qZAGYltwJPX4UtMrJvD3wDQ6NbqldS-BzoJ7rwkCtq8bjxvBAqGvxG7xAbEvMADq5JlmcF-uHqoqshD9XiB0rKI1tVTLaLNcr5yZYwV0eCZh__VeOPHrcAl-zrELvuh0yaOb-OeXYwHUhyUOnrVo6IE3wf3qcqb8HaQorcZSeeJpedSsbufInVtq1PZ_0a0QixevOWBwhlOELQYBgABJGbBvAt_sH4V7JSzgi4I4q_v2u4wdtZGqOQL6pJ_qOD3DMsI6uPKyCB_GDFNIVhsypauvCF-amHr94_VHLb963AcJGUB1KCXCf_q9tFRlpO9PghS8fPoQG2aEMFrIw0I2GCm22JTAPMojlHMpNpvUAAknkILbrhKLZEDGcjZbi5LAZ6LYcSulxSJ1ERucRIbvUryV21EfkmfKIEdYuDyJZTbQafTs7vbG_VHzDzWI1CMjw_qG1qeJw2-txNaYTOqq4iBeInHKijnUIpXOSCbCz0COZgnurLsV4XKdC79UIaLDG19KFZgsCaKxy27aZMP0FsQwfubsu9i41mWidH_sCiS9A6lmjdD98P7icRwtBJgrjj342PWg0aDMiOvvYCF8GKQRJsNgY1WA2uc52pR26MkvRN1bbju-ftNDGV7XzbDZmqXOBLonUHOA5K5F_iiGHi6KfWHZQtJHGV1CHYh1auY3P5qpBrlWoOl9OSq6kJI_BBQHCIEfezk7hA7uTfBPabmLtpWvS15ehwZOJA1qMCHYh179Oa59dMDpYB6XeBuNFznw5WlW_IHrf5kgYg0-_jTfr_3Z3KeQuHj72_zGb3qxviX-HwVIwqh6JgV--QTEZHgRYslbcWncYG1az4HhveUX6A8WKi4WaRDQGjgy02ar5bHx8yQ0k3jHSjc1ey-s_ws72HXPLJxlQKOJcCzCQQCKq0QVmKpizcnpYO6r8vZW72TQhdMyix_h7Bj-pvGQVT7SNf4lwP1xNKiclJa9uqBfcFLoDbFck9NaWlAZSXAgXE_c9YA7PjNNrQgEzueJgWAElHteUrd6vwACmPhzsfT07QKxb7VGQcoHzkynvfZ4AHME8YS4sAn5gQTkWEeyUwmoiNU1407iaEcZUlAz8CDjog8BN2x2ZFQI70gmrolA6jh9KLYz_47apdWBtzhqM20Jo7kYfCrrXviI9yt09AIsvccq4tFQnPuh_IOnsyfFRfnmZPUqCABUzRxNA==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:05",
                "destination": "OAK",
                "duration": "29h30m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e1c000068b2193b",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e1c000068b2193b_0",
                    "local_arrival": "2024-09-30T23:40:00.000Z",
                    "local_departure": "2024-09-30T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-30T21:40:00.000Z",
                    "utc_departure": "2024-09-30T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "LON",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "London",
                    "combination_id": "13a222f54e1d000084885915",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8094,
                    "flyFrom": "PMI",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "13a222f54e1d000084885915_0",
                    "local_arrival": "2024-10-01T08:35:00.000Z",
                    "local_departure": "2024-10-01T07:00:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:35:00.000Z",
                    "utc_departure": "2024-10-01T05:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1220.08,
            "totalPrice": 990
          },
          {
            "allPassengerTotalPrice": 990,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLzWKLQInrR1MxcJ_0YWi8zdgWwlWWHRxClWz6HIfdyW3xZzNZcmykyIbVAsPmQdvIPF1pr49MKdEaKa_CnEnq0jlhZtdXj364kAe10Men22I3yJGHQyJXPB7Frg43tmU6zkA3qeXSU9nbYJhU9rPqUNVfCBcFabz2Ibn-dLnMUn_pPsVMUq8cpmOLDpcLgCKRFWG9exkqMJBkSvOZmd4ZXnQqrcx4Olc-XVqFKQWonxuXVsGRl-yx3pYHAA5K3bV-aZMTASEnf4EHc1ORpgEx8yHkaT89y9VDzi3Rsts-vEew92oBv6oexFGHx_arv6ZKQirYY46dLShKQ55GiDEuILFGwmmJU5SLwF_YzXSY6ICPNAj-AglWQh5UHT1C6BwcimhHmZU2zz-3mZvM4bwu8Cs06MkIv3QMHUoezJ_ha19HRuO9BubfvLPjs_3dzON18k6S4pXT3I_jpkN57VmwqgdxRDUuNVeWdt4REurCgwhNyKDQhiKAaW_EJh934MlKzKLvNf52m-ClDmvc9o__PqXIwzAsfQij8vLWkNjkf6FKKlQnuq6G0FLCEqjrIa1CqeX5ItSFGdqP9Xq7Nm4igQEolbwqOKHKuaMy_NQei-d7YAireuaO4SWY_ccdA3TsYCKn8YSALtY4qbMWIn1EyMlU61IBqzkRX4w86tn8befR2tXMBoRGT87UEclSOiy9l2-e8GQ00UYUjAk8K1DPJJQAAxRYSBcpZNZDj_8odxZN_XAaauPc1QnP43IC75yPDh0yr1DFQV38UEik556AFD8T-eDUHV6NcemDMvB7YATNRklQ9yC64JYnNPy0COlr3b5t-Cc-7n_keNM9GxvMI6ayprmAMC_lm9ASowUZBHdAdWP99qbYg8T8DPaIZRlY2Zrhuw7D-WdQcr8dfRzFsSiS75AUApurPd-KokjZeND8AuYSviMKyOPm2CcngYsFAhnfZcIaFD0IH22VasezmXwYguqt1CnOw1KGmA3Ipb5G9neHTCd7AzMG_UbbA1eJihZPLlqBs6fTl6Ue8W-lt7nGNLDZojqQLu25TyV3tNznsqG6SG1r77MSSeAq8d2IHJnZIOmZTgb8KE-EO5NH8tIRXK-41Ih3YCc4qjjTejgIWGpvNpCYv29Cd0Hde2KLB87CQtsmNuqXwoWwARlTMT_itlA2VZOYhmDYI9ekxn8YGMw1tpd6JGp7JKeZbrP9W-o7_qbHA_HNYvyqUi5mYiksWDRXnhk190bNtroXn-jpmJU0UcWuBM_QpCgLHtvw0KVD-aWF_g2DMmRh5E1k1Qr1qAoufwnUpFVMjW6bStis7hiuknP8r4LoSmNc6Yo8d_jeboIf4BsaoY7SzN3zth4gFHblvQfFQLAvCrZXyk=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLzWKLQInrR1MxcJ_0YWi8zdgWwlWWHRxClWz6HIfdyW3xZzNZcmykyIbVAsPmQdvIPF1pr49MKdEaKa_CnEnq0jlhZtdXj364kAe10Men22I3yJGHQyJXPB7Frg43tmU6zkA3qeXSU9nbYJhU9rPqUNVfCBcFabz2Ibn-dLnMUn_pPsVMUq8cpmOLDpcLgCKRFWG9exkqMJBkSvOZmd4ZXnQqrcx4Olc-XVqFKQWonxuXVsGRl-yx3pYHAA5K3bV-aZMTASEnf4EHc1ORpgEx8yHkaT89y9VDzi3Rsts-vEew92oBv6oexFGHx_arv6ZKQirYY46dLShKQ55GiDEuILFGwmmJU5SLwF_YzXSY6ICPNAj-AglWQh5UHT1C6BwcimhHmZU2zz-3mZvM4bwu8Cs06MkIv3QMHUoezJ_ha19HRuO9BubfvLPjs_3dzON18k6S4pXT3I_jpkN57VmwqgdxRDUuNVeWdt4REurCgwhNyKDQhiKAaW_EJh934MlKzKLvNf52m-ClDmvc9o__PqXIwzAsfQij8vLWkNjkf6FKKlQnuq6G0FLCEqjrIa1CqeX5ItSFGdqP9Xq7Nm4igQEolbwqOKHKuaMy_NQei-d7YAireuaO4SWY_ccdA3TsYCKn8YSALtY4qbMWIn1EyMlU61IBqzkRX4w86tn8befR2tXMBoRGT87UEclSOiy9l2-e8GQ00UYUjAk8K1DPJJQAAxRYSBcpZNZDj_8odxZN_XAaauPc1QnP43IC75yPDh0yr1DFQV38UEik556AFD8T-eDUHV6NcemDMvB7YATNRklQ9yC64JYnNPy0COlr3b5t-Cc-7n_keNM9GxvMI6ayprmAMC_lm9ASowUZBHdAdWP99qbYg8T8DPaIZRlY2Zrhuw7D-WdQcr8dfRzFsSiS75AUApurPd-KokjZeND8AuYSviMKyOPm2CcngYsFAhnfZcIaFD0IH22VasezmXwYguqt1CnOw1KGmA3Ipb5G9neHTCd7AzMG_UbbA1eJihZPLlqBs6fTl6Ue8W-lt7nGNLDZojqQLu25TyV3tNznsqG6SG1r77MSSeAq8d2IHJnZIOmZTgb8KE-EO5NH8tIRXK-41Ih3YCc4qjjTejgIWGpvNpCYv29Cd0Hde2KLB87CQtsmNuqXwoWwARlTMT_itlA2VZOYhmDYI9ekxn8YGMw1tpd6JGp7JKeZbrP9W-o7_qbHA_HNYvyqUi5mYiksWDRXnhk190bNtroXn-jpmJU0UcWuBM_QpCgLHtvw0KVD-aWF_g2DMmRh5E1k1Qr1qAoufwnUpFVMjW6bStis7hiuknP8r4LoSmNc6Yo8d_jeboIf4BsaoY7SzN3zth4gFHblvQfFQLAvCrZXyk=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLzWKLQInrR1MxcJ_0YWi8zdgWwlWWHRxClWz6HIfdyW3xZzNZcmykyIbVAsPmQdvIPF1pr49MKdEaKa_CnEnq0jlhZtdXj364kAe10Men22I3yJGHQyJXPB7Frg43tmU6zkA3qeXSU9nbYJhU9rPqUNVfCBcFabz2Ibn-dLnMUn_pPsVMUq8cpmOLDpcLgCKRFWG9exkqMJBkSvOZmd4ZXnQqrcx4Olc-XVqFKQWonxuXVsGRl-yx3pYHAA5K3bV-aZMTASEnf4EHc1ORpgEx8yHkaT89y9VDzi3Rsts-vEew92oBv6oexFGHx_arv6ZKQirYY46dLShKQ55GiDEuILFGwmmJU5SLwF_YzXSY6ICPNAj-AglWQh5UHT1C6BwcimhHmZU2zz-3mZvM4bwu8Cs06MkIv3QMHUoezJ_ha19HRuO9BubfvLPjs_3dzON18k6S4pXT3I_jpkN57VmwqgdxRDUuNVeWdt4REurCgwhNyKDQhiKAaW_EJh934MlKzKLvNf52m-ClDmvc9o__PqXIwzAsfQij8vLWkNjkf6FKKlQnuq6G0FLCEqjrIa1CqeX5ItSFGdqP9Xq7Nm4igQEolbwqOKHKuaMy_NQei-d7YAireuaO4SWY_ccdA3TsYCKn8YSALtY4qbMWIn1EyMlU61IBqzkRX4w86tn8befR2tXMBoRGT87UEclSOiy9l2-e8GQ00UYUjAk8K1DPJJQAAxRYSBcpZNZDj_8odxZN_XAaauPc1QnP43IC75yPDh0yr1DFQV38UEik556AFD8T-eDUHV6NcemDMvB7YATNRklQ9yC64JYnNPy0COlr3b5t-Cc-7n_keNM9GxvMI6ayprmAMC_lm9ASowUZBHdAdWP99qbYg8T8DPaIZRlY2Zrhuw7D-WdQcr8dfRzFsSiS75AUApurPd-KokjZeND8AuYSviMKyOPm2CcngYsFAhnfZcIaFD0IH22VasezmXwYguqt1CnOw1KGmA3Ipb5G9neHTCd7AzMG_UbbA1eJihZPLlqBs6fTl6Ue8W-lt7nGNLDZojqQLu25TyV3tNznsqG6SG1r77MSSeAq8d2IHJnZIOmZTgb8KE-EO5NH8tIRXK-41Ih3YCc4qjjTejgIWGpvNpCYv29Cd0Hde2KLB87CQtsmNuqXwoWwARlTMT_itlA2VZOYhmDYI9ekxn8YGMw1tpd6JGp7JKeZbrP9W-o7_qbHA_HNYvyqUi5mYiksWDRXnhk190bNtroXn-jpmJU0UcWuBM_QpCgLHtvw0KVD-aWF_g2DMmRh5E1k1Qr1qAoufwnUpFVMjW6bStis7hiuknP8r4LoSmNc6Yo8d_jeboIf4BsaoY7SzN3zth4gFHblvQfFQLAvCrZXyk=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1221.96,
            "totalPrice": 990
          },
          {
            "allPassengerTotalPrice": 991,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gu7Z6lyS12595sntkbDMk_QttxxjQohakyLL70IJpEmbdWzrjtN1OkVAKMdWX1HWhzsgZEa-JPLl13MdcZjWSBPrdH4ou1LBsmaeIpwHBTXYRk02vw-hWavQE_xUJk3NV_j7WiqigHokaPdCLFULdpQNEsPIkQX3yVXzIS3O8Xau7LdF3CICZGK6FvbxqYxZ1R7Gnl4c0nXCAjhNOvqpEb9rL5WjN2VDgei7xSrExIIMUzpvALF8Wtso4irFavbwXrd-48Ol1ouqumJeGYb657mtyhVTtc9xzkqhx0Y-vcuedfKbxB9-3IHBHJ7TOatlQ0bbUuDs_VBbkFaOFtbHiffgNB1IvP-lZjC7DN7o4KVF9Js5JMNzGjgbeCZvWKhnfw4Hfpo6aNA5X3wiw7K9w_JBDJ1Ftt-Cu7zn1ySTp2W_ccfKpZT_eXGQIgTOVYfRUHwg6sY8058ZpNJMxirKmyJT6bDZ36K_1Dfzu-Ldx7GfjVHKgAgm053vuxrOrxC_wBRi4PDxDbYRmbMCwRxtvPf0nW5ofuBW61k2wsYk9eGcJAPaFeO4kYzM1Od-7F4UPdzJdK0ysxpwOqJLvZ9hWyrqIE1EvtrA4oXF67SxdfjliLAPIb_BbKK-gb8W1f7zz2rh5lw59KTOExW6E-cuumyMb3d6o9hWYjvRPEt2zAlmLRqtT1iAxMZrTGVjuJFaLrOjXmM1WGIm0ctBqnLpidVtOZR-ebRokaLl3Oe-uJvWoobvp5zZotKjdOusJ0tszeDdsIYmhV_Uwes349CoR-yus0z1igdWUzD1xHBW-c0oKK8fRS5bBAUnPJMTCceUvwrTkTMAv6LL8j4CilmtqlSoH0UUdf9eoAtkEqOOl9oFofYnSZhC0dmutOfFR7IW3TlfLrCZKkxhaLJOA4SUEDjvlKoR1FaOZVHSpYqNVl2cO7spA1JP06yFRBmpGPyUGpKP1mAxMJGG3R7UdhATDyiLDJtMUknAcu0FF-ELDDqZNFydd6Mpgcx5wcwK3mYo3_Pr0oeT9X71Ty8wDDWDZ62yt5EBn2dL0Xs-gKkJJlfFGozdC3BafDSfwPJoKDeuzz5746Z9DqwmCKsUms7t1mZyxAjV4hVQxFVGMI6xdA5zITrVV72_awujEhRKAJWynNhqplqrOA318hoGAMuT8ec1w5pHDy2TOKKl8k2ADDKI3_SZmLTmIvGYsDJRhqi6FO16aRGcLFIKTMYuc9jsvNY2ifkg5L4ivkY7tW6hlzjNB4ZL8x_eUslI77Xv965CpHUypWTylc1bE4j4F7ZpSJTvY6-4nlQXlf-45jfaRdRwCNJ7e7bUDnt9ZNsdwqdjj4ZvY6ObH6v0eZ19pqHyOew==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gu7Z6lyS12595sntkbDMk_QttxxjQohakyLL70IJpEmbdWzrjtN1OkVAKMdWX1HWhzsgZEa-JPLl13MdcZjWSBPrdH4ou1LBsmaeIpwHBTXYRk02vw-hWavQE_xUJk3NV_j7WiqigHokaPdCLFULdpQNEsPIkQX3yVXzIS3O8Xau7LdF3CICZGK6FvbxqYxZ1R7Gnl4c0nXCAjhNOvqpEb9rL5WjN2VDgei7xSrExIIMUzpvALF8Wtso4irFavbwXrd-48Ol1ouqumJeGYb657mtyhVTtc9xzkqhx0Y-vcuedfKbxB9-3IHBHJ7TOatlQ0bbUuDs_VBbkFaOFtbHiffgNB1IvP-lZjC7DN7o4KVF9Js5JMNzGjgbeCZvWKhnfw4Hfpo6aNA5X3wiw7K9w_JBDJ1Ftt-Cu7zn1ySTp2W_ccfKpZT_eXGQIgTOVYfRUHwg6sY8058ZpNJMxirKmyJT6bDZ36K_1Dfzu-Ldx7GfjVHKgAgm053vuxrOrxC_wBRi4PDxDbYRmbMCwRxtvPf0nW5ofuBW61k2wsYk9eGcJAPaFeO4kYzM1Od-7F4UPdzJdK0ysxpwOqJLvZ9hWyrqIE1EvtrA4oXF67SxdfjliLAPIb_BbKK-gb8W1f7zz2rh5lw59KTOExW6E-cuumyMb3d6o9hWYjvRPEt2zAlmLRqtT1iAxMZrTGVjuJFaLrOjXmM1WGIm0ctBqnLpidVtOZR-ebRokaLl3Oe-uJvWoobvp5zZotKjdOusJ0tszeDdsIYmhV_Uwes349CoR-yus0z1igdWUzD1xHBW-c0oKK8fRS5bBAUnPJMTCceUvwrTkTMAv6LL8j4CilmtqlSoH0UUdf9eoAtkEqOOl9oFofYnSZhC0dmutOfFR7IW3TlfLrCZKkxhaLJOA4SUEDjvlKoR1FaOZVHSpYqNVl2cO7spA1JP06yFRBmpGPyUGpKP1mAxMJGG3R7UdhATDyiLDJtMUknAcu0FF-ELDDqZNFydd6Mpgcx5wcwK3mYo3_Pr0oeT9X71Ty8wDDWDZ62yt5EBn2dL0Xs-gKkJJlfFGozdC3BafDSfwPJoKDeuzz5746Z9DqwmCKsUms7t1mZyxAjV4hVQxFVGMI6xdA5zITrVV72_awujEhRKAJWynNhqplqrOA318hoGAMuT8ec1w5pHDy2TOKKl8k2ADDKI3_SZmLTmIvGYsDJRhqi6FO16aRGcLFIKTMYuc9jsvNY2ifkg5L4ivkY7tW6hlzjNB4ZL8x_eUslI77Xv965CpHUypWTylc1bE4j4F7ZpSJTvY6-4nlQXlf-45jfaRdRwCNJ7e7bUDnt9ZNsdwqdjj4ZvY6ObH6v0eZ19pqHyOew==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gu7Z6lyS12595sntkbDMk_QttxxjQohakyLL70IJpEmbdWzrjtN1OkVAKMdWX1HWhzsgZEa-JPLl13MdcZjWSBPrdH4ou1LBsmaeIpwHBTXYRk02vw-hWavQE_xUJk3NV_j7WiqigHokaPdCLFULdpQNEsPIkQX3yVXzIS3O8Xau7LdF3CICZGK6FvbxqYxZ1R7Gnl4c0nXCAjhNOvqpEb9rL5WjN2VDgei7xSrExIIMUzpvALF8Wtso4irFavbwXrd-48Ol1ouqumJeGYb657mtyhVTtc9xzkqhx0Y-vcuedfKbxB9-3IHBHJ7TOatlQ0bbUuDs_VBbkFaOFtbHiffgNB1IvP-lZjC7DN7o4KVF9Js5JMNzGjgbeCZvWKhnfw4Hfpo6aNA5X3wiw7K9w_JBDJ1Ftt-Cu7zn1ySTp2W_ccfKpZT_eXGQIgTOVYfRUHwg6sY8058ZpNJMxirKmyJT6bDZ36K_1Dfzu-Ldx7GfjVHKgAgm053vuxrOrxC_wBRi4PDxDbYRmbMCwRxtvPf0nW5ofuBW61k2wsYk9eGcJAPaFeO4kYzM1Od-7F4UPdzJdK0ysxpwOqJLvZ9hWyrqIE1EvtrA4oXF67SxdfjliLAPIb_BbKK-gb8W1f7zz2rh5lw59KTOExW6E-cuumyMb3d6o9hWYjvRPEt2zAlmLRqtT1iAxMZrTGVjuJFaLrOjXmM1WGIm0ctBqnLpidVtOZR-ebRokaLl3Oe-uJvWoobvp5zZotKjdOusJ0tszeDdsIYmhV_Uwes349CoR-yus0z1igdWUzD1xHBW-c0oKK8fRS5bBAUnPJMTCceUvwrTkTMAv6LL8j4CilmtqlSoH0UUdf9eoAtkEqOOl9oFofYnSZhC0dmutOfFR7IW3TlfLrCZKkxhaLJOA4SUEDjvlKoR1FaOZVHSpYqNVl2cO7spA1JP06yFRBmpGPyUGpKP1mAxMJGG3R7UdhATDyiLDJtMUknAcu0FF-ELDDqZNFydd6Mpgcx5wcwK3mYo3_Pr0oeT9X71Ty8wDDWDZ62yt5EBn2dL0Xs-gKkJJlfFGozdC3BafDSfwPJoKDeuzz5746Z9DqwmCKsUms7t1mZyxAjV4hVQxFVGMI6xdA5zITrVV72_awujEhRKAJWynNhqplqrOA318hoGAMuT8ec1w5pHDy2TOKKl8k2ADDKI3_SZmLTmIvGYsDJRhqi6FO16aRGcLFIKTMYuc9jsvNY2ifkg5L4ivkY7tW6hlzjNB4ZL8x_eUslI77Xv965CpHUypWTylc1bE4j4F7ZpSJTvY6-4nlQXlf-45jfaRdRwCNJ7e7bUDnt9ZNsdwqdjj4ZvY6ObH6v0eZ19pqHyOew==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e1d00000c82eb81",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e1d00000c82eb81_0",
                    "local_arrival": "2024-10-01T08:55:00.000Z",
                    "local_departure": "2024-10-01T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:55:00.000Z",
                    "utc_departure": "2024-10-01T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1213.91,
            "totalPrice": 991
          },
          {
            "allPassengerTotalPrice": 992,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3RrghSGJFnNi4gL2cYAMg4WO67Ye1A4hXYtAMJydzoSeniBNEHUxm4bXmlvwdtFUnOtn9VVbEYI2UO1Wq4Uwnk3gQDp3eRpIL3W4j4Fbufk4ng1W_xBGh35O6zYWtskFW4QGKBP3x2QWvrjQUc_wRl_GGf2oVHyf5LPcgecJBX6IQEWfipz0BLuIJ_68w8dWJYSrJlDlyj59W9iNTgCqXSqeZqbHfDU_w5UqTNmYnPkVt-NPJMCzWIC0CgnPJH0Zhlc_loXUYAEqAqvaJ4jDP8shbnFBksuOOA3Nnb7Zkppv8BX2Nz_Dn5NnaX5L2qmNxLglc2xhp263PXEWT90PGw-acRM8njJMEI373wlq6zj7KtwWf80krZHjZWVqT7G5HsxVY9xdv7NswpgL1a1N0ke3mzMtwe9jeD4SLpxG4y-8-ARdNWK0kZ9CpyJA3wBT78fvlo57SIjRXzZItqorcrcALRgVW8R022GKs3nbcN4nNLXASsZVR_7kVKnnatdW2SWANPzBQAyqjXGgf5yHdMZDWODvvhxs7ZaFjTu_t68VDCnP4Bhj7w7QujeSZ01cYve_X0NLn-zOcRqH8SN_DaOguWeHPlf6jll4fvrYgZKyXqqBcDbdTHAAmv-o0QKVY8UvCD5IhGovsUXdlecSN_bi1gxx00jN48HH0Y8jOKCFfUcwh3wBHURhHHj82XVHQ7cUAREfMbA-lilFlvyt5538pNmQJhxOk7MfLIIOQzYsMtoWexIJPM7NF5hvdTL7ZUFCMZGVT6rseE46JsBlnqLP3vvkF4NbO-fXTt1QF-SiLHRNL4vwFiSOO9EhbLhncQF87A0y8w7XuRXpTQWvXYfSvbJKrQWF--SRb1iU_zhFE4HD1lY-CjMWSjXJOtMeAMN6Uh5xrrFP1rINCSnwcsh2U7zFH9uFZvgDiy-CHogVTz3xZnBi3q5PGL3-oae3PydNROLIcsGKLR5CqRilBADIuNuDaQxL98wtY-b_bqtEFJBwrQsHqqE9IUMNH6AmUHl_i1VWrl3Rvr-RVxYuzuQzFEcpaQYjNwP8H0N64ctECzoZaE_0Xys6msFECcABUez0uR0MC6Kz1sodpWidPeIh62az5qUk0OnM3TWtfp--JdgmsurYxcwEJpOgu6hk9NtEgwZRkHXstnxSKKRaHv4zj_8MKWQvb8sBoj2evZBKOkxyRWf8gdtaXslXkwVhibSOvOxOrGbx3ZI9BdUIHwNgTpPp7RQ9xbwKbNN--4XpU7D6ioYPXCJ2vkl7eMEGg7tbzcyFQs2SpKKc6O6qSUWzwZr_dyuGf6jYf7jaJ8lsciQGN3AvHOU_TT6GjPWe-6TSo39jrt1F90_y31kQbQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3RrghSGJFnNi4gL2cYAMg4WO67Ye1A4hXYtAMJydzoSeniBNEHUxm4bXmlvwdtFUnOtn9VVbEYI2UO1Wq4Uwnk3gQDp3eRpIL3W4j4Fbufk4ng1W_xBGh35O6zYWtskFW4QGKBP3x2QWvrjQUc_wRl_GGf2oVHyf5LPcgecJBX6IQEWfipz0BLuIJ_68w8dWJYSrJlDlyj59W9iNTgCqXSqeZqbHfDU_w5UqTNmYnPkVt-NPJMCzWIC0CgnPJH0Zhlc_loXUYAEqAqvaJ4jDP8shbnFBksuOOA3Nnb7Zkppv8BX2Nz_Dn5NnaX5L2qmNxLglc2xhp263PXEWT90PGw-acRM8njJMEI373wlq6zj7KtwWf80krZHjZWVqT7G5HsxVY9xdv7NswpgL1a1N0ke3mzMtwe9jeD4SLpxG4y-8-ARdNWK0kZ9CpyJA3wBT78fvlo57SIjRXzZItqorcrcALRgVW8R022GKs3nbcN4nNLXASsZVR_7kVKnnatdW2SWANPzBQAyqjXGgf5yHdMZDWODvvhxs7ZaFjTu_t68VDCnP4Bhj7w7QujeSZ01cYve_X0NLn-zOcRqH8SN_DaOguWeHPlf6jll4fvrYgZKyXqqBcDbdTHAAmv-o0QKVY8UvCD5IhGovsUXdlecSN_bi1gxx00jN48HH0Y8jOKCFfUcwh3wBHURhHHj82XVHQ7cUAREfMbA-lilFlvyt5538pNmQJhxOk7MfLIIOQzYsMtoWexIJPM7NF5hvdTL7ZUFCMZGVT6rseE46JsBlnqLP3vvkF4NbO-fXTt1QF-SiLHRNL4vwFiSOO9EhbLhncQF87A0y8w7XuRXpTQWvXYfSvbJKrQWF--SRb1iU_zhFE4HD1lY-CjMWSjXJOtMeAMN6Uh5xrrFP1rINCSnwcsh2U7zFH9uFZvgDiy-CHogVTz3xZnBi3q5PGL3-oae3PydNROLIcsGKLR5CqRilBADIuNuDaQxL98wtY-b_bqtEFJBwrQsHqqE9IUMNH6AmUHl_i1VWrl3Rvr-RVxYuzuQzFEcpaQYjNwP8H0N64ctECzoZaE_0Xys6msFECcABUez0uR0MC6Kz1sodpWidPeIh62az5qUk0OnM3TWtfp--JdgmsurYxcwEJpOgu6hk9NtEgwZRkHXstnxSKKRaHv4zj_8MKWQvb8sBoj2evZBKOkxyRWf8gdtaXslXkwVhibSOvOxOrGbx3ZI9BdUIHwNgTpPp7RQ9xbwKbNN--4XpU7D6ioYPXCJ2vkl7eMEGg7tbzcyFQs2SpKKc6O6qSUWzwZr_dyuGf6jYf7jaJ8lsciQGN3AvHOU_TT6GjPWe-6TSo39jrt1F90_y31kQbQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af22f54e1d00000c82eb81_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3RrghSGJFnNi4gL2cYAMg4WO67Ye1A4hXYtAMJydzoSeniBNEHUxm4bXmlvwdtFUnOtn9VVbEYI2UO1Wq4Uwnk3gQDp3eRpIL3W4j4Fbufk4ng1W_xBGh35O6zYWtskFW4QGKBP3x2QWvrjQUc_wRl_GGf2oVHyf5LPcgecJBX6IQEWfipz0BLuIJ_68w8dWJYSrJlDlyj59W9iNTgCqXSqeZqbHfDU_w5UqTNmYnPkVt-NPJMCzWIC0CgnPJH0Zhlc_loXUYAEqAqvaJ4jDP8shbnFBksuOOA3Nnb7Zkppv8BX2Nz_Dn5NnaX5L2qmNxLglc2xhp263PXEWT90PGw-acRM8njJMEI373wlq6zj7KtwWf80krZHjZWVqT7G5HsxVY9xdv7NswpgL1a1N0ke3mzMtwe9jeD4SLpxG4y-8-ARdNWK0kZ9CpyJA3wBT78fvlo57SIjRXzZItqorcrcALRgVW8R022GKs3nbcN4nNLXASsZVR_7kVKnnatdW2SWANPzBQAyqjXGgf5yHdMZDWODvvhxs7ZaFjTu_t68VDCnP4Bhj7w7QujeSZ01cYve_X0NLn-zOcRqH8SN_DaOguWeHPlf6jll4fvrYgZKyXqqBcDbdTHAAmv-o0QKVY8UvCD5IhGovsUXdlecSN_bi1gxx00jN48HH0Y8jOKCFfUcwh3wBHURhHHj82XVHQ7cUAREfMbA-lilFlvyt5538pNmQJhxOk7MfLIIOQzYsMtoWexIJPM7NF5hvdTL7ZUFCMZGVT6rseE46JsBlnqLP3vvkF4NbO-fXTt1QF-SiLHRNL4vwFiSOO9EhbLhncQF87A0y8w7XuRXpTQWvXYfSvbJKrQWF--SRb1iU_zhFE4HD1lY-CjMWSjXJOtMeAMN6Uh5xrrFP1rINCSnwcsh2U7zFH9uFZvgDiy-CHogVTz3xZnBi3q5PGL3-oae3PydNROLIcsGKLR5CqRilBADIuNuDaQxL98wtY-b_bqtEFJBwrQsHqqE9IUMNH6AmUHl_i1VWrl3Rvr-RVxYuzuQzFEcpaQYjNwP8H0N64ctECzoZaE_0Xys6msFECcABUez0uR0MC6Kz1sodpWidPeIh62az5qUk0OnM3TWtfp--JdgmsurYxcwEJpOgu6hk9NtEgwZRkHXstnxSKKRaHv4zj_8MKWQvb8sBoj2evZBKOkxyRWf8gdtaXslXkwVhibSOvOxOrGbx3ZI9BdUIHwNgTpPp7RQ9xbwKbNN--4XpU7D6ioYPXCJ2vkl7eMEGg7tbzcyFQs2SpKKc6O6qSUWzwZr_dyuGf6jYf7jaJ8lsciQGN3AvHOU_TT6GjPWe-6TSo39jrt1F90_y31kQbQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e1d00000c82eb81",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e1d00000c82eb81_0",
                    "local_arrival": "2024-10-01T08:55:00.000Z",
                    "local_departure": "2024-10-01T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:55:00.000Z",
                    "utc_departure": "2024-10-01T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1215.22,
            "totalPrice": 992
          },
          {
            "allPassengerTotalPrice": 992,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKktv0oYeJdBXdIBD-fPrR2-Bu2SMO91sEJxhmkhGjiq3jOjboFYD-S7PmnHdyVLC-74dbf12np1ezjkrvggPpiVybyxUFYp-AgQXJ5hbpWXzQHdZ-AbWclR_l_JmeD4DeurdAL1o2ur7qWr_raf06TB3_mjpeCEPYsEJ0oYvVvBrZ4PXdP-MMe1-biCwVrE3CzGecSrALpbbuce376TwaeMdecz-oLeKbYws5m8ZQDJ8wi-kD0jm7hP7h7-BwhPJIPCtsJLxLyV5PvL4G8vQmZn-wnqdEQt4LkwEqYajxJI03p858rrsF7pEsPaNbJ1_U0Go1jcJvDI1f4j4i5Qz0e1IFoncuZA63ivOnP7oTjjnbAl6HCQQmuCnLb5pVSvkQDg3rMT5eRja4ATfsJ7DtMHlYpsiQPNFOKbx6OuL3dFo9pWit90WmHEOD-xkaotpjjacrBO_EeAD6ymrYR8Zlbzcpn2gY8GeCBsqFdDGLXBgIe9xgvBD7KNIkQ-HzruOH8R56ivNqR86l54D0PggMcW_thawSywUGSYcidCArfpS1QZz9GneQhwK4tX7rIYnb7bzWvkUBWBrQod_xMBRmIhBmZUMW91ddow_4GtHbIbzqJK4v2uXCWsli9PG5U1Jm3w0WzgsaOIdoW6YzSSF6bCrrAh9f-jLi9QSeFrpiRQjgA-0lBDiBD6Ghcfzpz6LZJbltY2ACpBktjDVpZ5EJaiZrmJvgQhEJLiyj6tbSit4nsMzHPjeX5zfQcFvTTqgs1TYoePkvkxjt7plKi2YRKy6wJqwtCfyN_dkHt1p0uyGl8oHNcaNFF3MGGahHX4UTPg4is88_N7macbnr4NRpcYKZ-qwSXwqhY_xoGC_ipRCqLGB1yNYGn45S47mIKxxwMRXretCTSVCSAbA6sFQTvwNG9y0r19IG5INbITH0RkgKPjdKlS-yDNg_XOOp-AqzzT9PiGEeEw6sXoIB24fcoQAH8Sp4YRj42Ixr3n-SExZ4Kbn2kcgbvhk0YF6Tbn2JHJ7wUKB0DigN23Xa2NHQISdmfJ1ZLjNJNWkg-IFsOkeKMCHqBTOECnTCLE-JmYJ_T-NWIaRR1rKstfivDifEjvXRumjllrNcOAWkJ7s-dGPsO3xWacjWG9lCOJWsK0CtK9Ki4gIT_10oMtUi66lW2RQBIU8n9hTa6Jfk04eiDiKY3ZcgEKG0ttTuZngkXYsx6L_cY6R2YMJo8v7fbYoH4mVTyyATLv1nR9GzTv-35x7EKu5GmHMMyPfqBd8g36Xr3mTlv78VWWuFiipXNObr4azgdRg203vcWBnYX6bAL-fZduTtvziC73KR89y85xtGkz9AZWNE6c_9YrASTDk3Q==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKktv0oYeJdBXdIBD-fPrR2-Bu2SMO91sEJxhmkhGjiq3jOjboFYD-S7PmnHdyVLC-74dbf12np1ezjkrvggPpiVybyxUFYp-AgQXJ5hbpWXzQHdZ-AbWclR_l_JmeD4DeurdAL1o2ur7qWr_raf06TB3_mjpeCEPYsEJ0oYvVvBrZ4PXdP-MMe1-biCwVrE3CzGecSrALpbbuce376TwaeMdecz-oLeKbYws5m8ZQDJ8wi-kD0jm7hP7h7-BwhPJIPCtsJLxLyV5PvL4G8vQmZn-wnqdEQt4LkwEqYajxJI03p858rrsF7pEsPaNbJ1_U0Go1jcJvDI1f4j4i5Qz0e1IFoncuZA63ivOnP7oTjjnbAl6HCQQmuCnLb5pVSvkQDg3rMT5eRja4ATfsJ7DtMHlYpsiQPNFOKbx6OuL3dFo9pWit90WmHEOD-xkaotpjjacrBO_EeAD6ymrYR8Zlbzcpn2gY8GeCBsqFdDGLXBgIe9xgvBD7KNIkQ-HzruOH8R56ivNqR86l54D0PggMcW_thawSywUGSYcidCArfpS1QZz9GneQhwK4tX7rIYnb7bzWvkUBWBrQod_xMBRmIhBmZUMW91ddow_4GtHbIbzqJK4v2uXCWsli9PG5U1Jm3w0WzgsaOIdoW6YzSSF6bCrrAh9f-jLi9QSeFrpiRQjgA-0lBDiBD6Ghcfzpz6LZJbltY2ACpBktjDVpZ5EJaiZrmJvgQhEJLiyj6tbSit4nsMzHPjeX5zfQcFvTTqgs1TYoePkvkxjt7plKi2YRKy6wJqwtCfyN_dkHt1p0uyGl8oHNcaNFF3MGGahHX4UTPg4is88_N7macbnr4NRpcYKZ-qwSXwqhY_xoGC_ipRCqLGB1yNYGn45S47mIKxxwMRXretCTSVCSAbA6sFQTvwNG9y0r19IG5INbITH0RkgKPjdKlS-yDNg_XOOp-AqzzT9PiGEeEw6sXoIB24fcoQAH8Sp4YRj42Ixr3n-SExZ4Kbn2kcgbvhk0YF6Tbn2JHJ7wUKB0DigN23Xa2NHQISdmfJ1ZLjNJNWkg-IFsOkeKMCHqBTOECnTCLE-JmYJ_T-NWIaRR1rKstfivDifEjvXRumjllrNcOAWkJ7s-dGPsO3xWacjWG9lCOJWsK0CtK9Ki4gIT_10oMtUi66lW2RQBIU8n9hTa6Jfk04eiDiKY3ZcgEKG0ttTuZngkXYsx6L_cY6R2YMJo8v7fbYoH4mVTyyATLv1nR9GzTv-35x7EKu5GmHMMyPfqBd8g36Xr3mTlv78VWWuFiipXNObr4azgdRg203vcWBnYX6bAL-fZduTtvziC73KR89y85xtGkz9AZWNE6c_9YrASTDk3Q==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKktv0oYeJdBXdIBD-fPrR2-Bu2SMO91sEJxhmkhGjiq3jOjboFYD-S7PmnHdyVLC-74dbf12np1ezjkrvggPpiVybyxUFYp-AgQXJ5hbpWXzQHdZ-AbWclR_l_JmeD4DeurdAL1o2ur7qWr_raf06TB3_mjpeCEPYsEJ0oYvVvBrZ4PXdP-MMe1-biCwVrE3CzGecSrALpbbuce376TwaeMdecz-oLeKbYws5m8ZQDJ8wi-kD0jm7hP7h7-BwhPJIPCtsJLxLyV5PvL4G8vQmZn-wnqdEQt4LkwEqYajxJI03p858rrsF7pEsPaNbJ1_U0Go1jcJvDI1f4j4i5Qz0e1IFoncuZA63ivOnP7oTjjnbAl6HCQQmuCnLb5pVSvkQDg3rMT5eRja4ATfsJ7DtMHlYpsiQPNFOKbx6OuL3dFo9pWit90WmHEOD-xkaotpjjacrBO_EeAD6ymrYR8Zlbzcpn2gY8GeCBsqFdDGLXBgIe9xgvBD7KNIkQ-HzruOH8R56ivNqR86l54D0PggMcW_thawSywUGSYcidCArfpS1QZz9GneQhwK4tX7rIYnb7bzWvkUBWBrQod_xMBRmIhBmZUMW91ddow_4GtHbIbzqJK4v2uXCWsli9PG5U1Jm3w0WzgsaOIdoW6YzSSF6bCrrAh9f-jLi9QSeFrpiRQjgA-0lBDiBD6Ghcfzpz6LZJbltY2ACpBktjDVpZ5EJaiZrmJvgQhEJLiyj6tbSit4nsMzHPjeX5zfQcFvTTqgs1TYoePkvkxjt7plKi2YRKy6wJqwtCfyN_dkHt1p0uyGl8oHNcaNFF3MGGahHX4UTPg4is88_N7macbnr4NRpcYKZ-qwSXwqhY_xoGC_ipRCqLGB1yNYGn45S47mIKxxwMRXretCTSVCSAbA6sFQTvwNG9y0r19IG5INbITH0RkgKPjdKlS-yDNg_XOOp-AqzzT9PiGEeEw6sXoIB24fcoQAH8Sp4YRj42Ixr3n-SExZ4Kbn2kcgbvhk0YF6Tbn2JHJ7wUKB0DigN23Xa2NHQISdmfJ1ZLjNJNWkg-IFsOkeKMCHqBTOECnTCLE-JmYJ_T-NWIaRR1rKstfivDifEjvXRumjllrNcOAWkJ7s-dGPsO3xWacjWG9lCOJWsK0CtK9Ki4gIT_10oMtUi66lW2RQBIU8n9hTa6Jfk04eiDiKY3ZcgEKG0ttTuZngkXYsx6L_cY6R2YMJo8v7fbYoH4mVTyyATLv1nR9GzTv-35x7EKu5GmHMMyPfqBd8g36Xr3mTlv78VWWuFiipXNObr4azgdRg203vcWBnYX6bAL-fZduTtvziC73KR89y85xtGkz9AZWNE6c_9YrASTDk3Q==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:05",
                "destination": "OAK",
                "duration": "29h30m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e1c000068b2193b",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e1c000068b2193b_0",
                    "local_arrival": "2024-09-30T23:40:00.000Z",
                    "local_departure": "2024-09-30T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-30T21:40:00.000Z",
                    "utc_departure": "2024-09-30T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "LON",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "London",
                    "combination_id": "13a222f54e1d000084885915",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8094,
                    "flyFrom": "PMI",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "13a222f54e1d000084885915_0",
                    "local_arrival": "2024-10-01T08:35:00.000Z",
                    "local_departure": "2024-10-01T07:00:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:35:00.000Z",
                    "utc_departure": "2024-10-01T05:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1221.39,
            "totalPrice": 992
          },
          {
            "allPassengerTotalPrice": 993,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140bce4e160000908a78be_0%7C0bce0a224e16000035d6ccaa_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gt0begN7Tzmz951ilJysF8K1pUmAS_3ciudLY_UZgXm8us45Gs1P5vEHsUF9tZnxOwyL_z0yDxiY3DnngdCOimqgckRkmY1F0bLhry6fSk4Q-TD8mghXJ5wmEIN62PfIsyMyOj1Vp5JpssJUTd_YHM5uDxGzOR0SwIl5F-T_PPtzi816tU3umRP4nXUypgtmkXN78m9kBAd_seVjQGY-hP3W53yF5QcVebD3FbobtZYX3zDss-c8zUClR3U0OIA3pKWO1gqtSU3A6MMGI9960BhPvcvcpeOUu37mgzYp3-PHvmmiOUaWRlHA8VqKVAw2nLsXRZvqgcnBbDZoIkteVfsQ0fFhBqfadcKmWCbmDEm3JEifBGlsYGOQtlE_HY-_57aLOy-qz8LNLb5wzUy1Qa8QbMrDPBWRtv4Q7E6vKYbgQ8WS_UIG9zPrNekT-TwSGvGl7YA6m0EXbV_7Gr9BwDvnCWmt571f0pVok8q3BbqTURNLKrrPQoTa9oPHxq36dGSAurgDadhwS86XocMyq_mXZqJ4tVmr_p70MiUEn4KDWkYrECl9B_ndH9hYdMqlchgIBeYvPUpYgUb_3WKGgJL6l7mgaAYY4A3QTXlLYCc_ziaEGFRFIu9LHYnUzh0CWe_DEQUgdOnlTMuy0R1Q2Sd6W6Q15IJBG04hBreccbsp9gLiDTs6enoIGbrSz-ZQKzTOUoXCjtw6o0_r_3kWzNzJFYtGNK_VcZKAn2nmMDfKXBVf46TMrDPy8VCm6_gjzZSG8dzr6RFdKUcKiLnCzRiyO10xxO624JLlDKNneEWC5uoLkeibHGMnjBLC9wA13ICwvtnDkd9DBGWhQ7sw6qV-NUY4Hl1JV_G9eCJsWi1pumjpqrRwE2JzEbo-h-1oTpLL6QpJRUWD0FEG4WIekrkRx_JqUXgw7tKyaPutiqTrzKZisTPIxuZEfTwgDn2yEinJ88qV08OnHfJeEq1lTYyRAUR4o_qtfvfLetf60J7gnjVwOZtr6IXPzl8YM_ivOcx0HDl0vFegyvbmSP9toterBcRx7MxBRE7-9_Pv6VDmZiSCNsw_BrKFh5dajm6hm5s98bLTvWPvqdUMdNujf4_qTNQOGvqz6PopGAR_4JVKKk_WQgakI1eIRJGm8P4ERvE44EqxF5DdY9nrCcJ6hobP0hRoFngTQdWS4_YOi1rAxAeYzoQABsNr4GANwxgEGtNC3zZ2X6aCv4pZjQCouTkqRxrDWqYy33Yt6tAQCF0d1sZbJKq5ow-geSnZ3MErX",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140bce4e160000908a78be_0%7C0bce0a224e16000035d6ccaa_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gt0begN7Tzmz951ilJysF8K1pUmAS_3ciudLY_UZgXm8us45Gs1P5vEHsUF9tZnxOwyL_z0yDxiY3DnngdCOimqgckRkmY1F0bLhry6fSk4Q-TD8mghXJ5wmEIN62PfIsyMyOj1Vp5JpssJUTd_YHM5uDxGzOR0SwIl5F-T_PPtzi816tU3umRP4nXUypgtmkXN78m9kBAd_seVjQGY-hP3W53yF5QcVebD3FbobtZYX3zDss-c8zUClR3U0OIA3pKWO1gqtSU3A6MMGI9960BhPvcvcpeOUu37mgzYp3-PHvmmiOUaWRlHA8VqKVAw2nLsXRZvqgcnBbDZoIkteVfsQ0fFhBqfadcKmWCbmDEm3JEifBGlsYGOQtlE_HY-_57aLOy-qz8LNLb5wzUy1Qa8QbMrDPBWRtv4Q7E6vKYbgQ8WS_UIG9zPrNekT-TwSGvGl7YA6m0EXbV_7Gr9BwDvnCWmt571f0pVok8q3BbqTURNLKrrPQoTa9oPHxq36dGSAurgDadhwS86XocMyq_mXZqJ4tVmr_p70MiUEn4KDWkYrECl9B_ndH9hYdMqlchgIBeYvPUpYgUb_3WKGgJL6l7mgaAYY4A3QTXlLYCc_ziaEGFRFIu9LHYnUzh0CWe_DEQUgdOnlTMuy0R1Q2Sd6W6Q15IJBG04hBreccbsp9gLiDTs6enoIGbrSz-ZQKzTOUoXCjtw6o0_r_3kWzNzJFYtGNK_VcZKAn2nmMDfKXBVf46TMrDPy8VCm6_gjzZSG8dzr6RFdKUcKiLnCzRiyO10xxO624JLlDKNneEWC5uoLkeibHGMnjBLC9wA13ICwvtnDkd9DBGWhQ7sw6qV-NUY4Hl1JV_G9eCJsWi1pumjpqrRwE2JzEbo-h-1oTpLL6QpJRUWD0FEG4WIekrkRx_JqUXgw7tKyaPutiqTrzKZisTPIxuZEfTwgDn2yEinJ88qV08OnHfJeEq1lTYyRAUR4o_qtfvfLetf60J7gnjVwOZtr6IXPzl8YM_ivOcx0HDl0vFegyvbmSP9toterBcRx7MxBRE7-9_Pv6VDmZiSCNsw_BrKFh5dajm6hm5s98bLTvWPvqdUMdNujf4_qTNQOGvqz6PopGAR_4JVKKk_WQgakI1eIRJGm8P4ERvE44EqxF5DdY9nrCcJ6hobP0hRoFngTQdWS4_YOi1rAxAeYzoQABsNr4GANwxgEGtNC3zZ2X6aCv4pZjQCouTkqRxrDWqYy33Yt6tAQCF0d1sZbJKq5ow-geSnZ3MErX",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e160000908a78be",
                    "equipment": null,
                    "fare_basis": "XOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e160000908a78be_0",
                    "local_arrival": "2024-09-24T19:20:00.000Z",
                    "local_departure": "2024-09-24T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-09-24T17:20:00.000Z",
                    "utc_departure": "2024-09-24T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e16000035d6ccaa",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e16000035d6ccaa_0",
                    "local_arrival": "2024-09-24T22:10:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:10:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140bce4e160000908a78be_0%7C0bce0a224e16000035d6ccaa_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gt0begN7Tzmz951ilJysF8K1pUmAS_3ciudLY_UZgXm8us45Gs1P5vEHsUF9tZnxOwyL_z0yDxiY3DnngdCOimqgckRkmY1F0bLhry6fSk4Q-TD8mghXJ5wmEIN62PfIsyMyOj1Vp5JpssJUTd_YHM5uDxGzOR0SwIl5F-T_PPtzi816tU3umRP4nXUypgtmkXN78m9kBAd_seVjQGY-hP3W53yF5QcVebD3FbobtZYX3zDss-c8zUClR3U0OIA3pKWO1gqtSU3A6MMGI9960BhPvcvcpeOUu37mgzYp3-PHvmmiOUaWRlHA8VqKVAw2nLsXRZvqgcnBbDZoIkteVfsQ0fFhBqfadcKmWCbmDEm3JEifBGlsYGOQtlE_HY-_57aLOy-qz8LNLb5wzUy1Qa8QbMrDPBWRtv4Q7E6vKYbgQ8WS_UIG9zPrNekT-TwSGvGl7YA6m0EXbV_7Gr9BwDvnCWmt571f0pVok8q3BbqTURNLKrrPQoTa9oPHxq36dGSAurgDadhwS86XocMyq_mXZqJ4tVmr_p70MiUEn4KDWkYrECl9B_ndH9hYdMqlchgIBeYvPUpYgUb_3WKGgJL6l7mgaAYY4A3QTXlLYCc_ziaEGFRFIu9LHYnUzh0CWe_DEQUgdOnlTMuy0R1Q2Sd6W6Q15IJBG04hBreccbsp9gLiDTs6enoIGbrSz-ZQKzTOUoXCjtw6o0_r_3kWzNzJFYtGNK_VcZKAn2nmMDfKXBVf46TMrDPy8VCm6_gjzZSG8dzr6RFdKUcKiLnCzRiyO10xxO624JLlDKNneEWC5uoLkeibHGMnjBLC9wA13ICwvtnDkd9DBGWhQ7sw6qV-NUY4Hl1JV_G9eCJsWi1pumjpqrRwE2JzEbo-h-1oTpLL6QpJRUWD0FEG4WIekrkRx_JqUXgw7tKyaPutiqTrzKZisTPIxuZEfTwgDn2yEinJ88qV08OnHfJeEq1lTYyRAUR4o_qtfvfLetf60J7gnjVwOZtr6IXPzl8YM_ivOcx0HDl0vFegyvbmSP9toterBcRx7MxBRE7-9_Pv6VDmZiSCNsw_BrKFh5dajm6hm5s98bLTvWPvqdUMdNujf4_qTNQOGvqz6PopGAR_4JVKKk_WQgakI1eIRJGm8P4ERvE44EqxF5DdY9nrCcJ6hobP0hRoFngTQdWS4_YOi1rAxAeYzoQABsNr4GANwxgEGtNC3zZ2X6aCv4pZjQCouTkqRxrDWqYy33Yt6tAQCF0d1sZbJKq5ow-geSnZ3MErX",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1237.78,
            "totalPrice": 993
          },
          {
            "allPassengerTotalPrice": 996,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtowPOKKhrLx-YfyB1QTfGxLJ_sVcjjRxHT64XBcihV0R9ofy3fmIcEadzIE1ZmWcksjbZ3TjT90u17nL_-fI9U1lHqUhA4IMc3wFWMcEcHomhreOmVgnLW-7jhaROixy_mV8YneNKWrOXZ9fo2RTFqH6boxtPbO7TL5-YpJ8GccdP0RP_YPBbZAKrwWPRCn_Ixa-SBjy2E99i0q5_enXXpqnyXAbtjJ1D0fxLpKo0ckOnt48nk6nXnKOpKC1lsnS7j6DY_lEzoLHcJ4uhLdTxUXxpTjLZxIZ9F65cZqNRdeA1b2JJpOfztHx4dsPG7URS2gv3RXJTSZckX3jBP46u9NE851SZ08_cw7124AUVDEt6UtxBFnJ8480Iy6uHaM15dRxKv5hBDnyZ3m525KLQ-3a2gPRueJ90QHZfO-I36XG1gb4o11xwsZLCxxiZqyaovdXJQdLK8Cwc3wAUK-rRDVnIL-8TO4u3JnjqjA9o_UZKwQ8wpWz9DViaPXJvw7NbzY3i1Si5lnpVLvKuR3n7ooU5eKpDf7G53ELuTabYe63rFnD46eHe580T8KlpwvtnL4E7REpIPBEYlJjD4dk1n9JAgICUG3aS7geO8iHhA3ghatsidr-1b-nrWAL6Ug-gF-WalMGM8_l3ahCLuhvhYmNj5jQ_5yiCzL83eUGWUOPjRNXmX5Jk1HTUVcIqQmRRgmohfqGcuSSzOqOvoiat2nYykqEtDvzZdw-BjZxNaKkUD_kx10ohIFtfc4FnDh7SsUNjzxH1d5YN4tQrbtbxd1wlC9uAWKZRZ9pQoFvkINDYUer0WxUOk4c1JAK_NibIldbqAvdmmPUg6boVcqsqp5LlxNNHUdKfdfI4MJ0rjSyFl8_8mNsOREjdIcQPNCY_Bsp0dzBEDPb3sCf-_DEpvldq6c8NQq6xou5HL7y7GmHM9RMrkZtKriA-4ukhd0afijjmoX0QWybRhJrRp602a3-R7faDHVbtlVNK0ro3aBhrUFDviyhQm0cKsxE2xApa7ls0a_skrCRQvxfUBZ-qJrIuvSDiErcv0_HHNVOoSn39GesHdwMh2wsg2xEUjO5EzUU-3XchdwwvSdLCJTYTrf14PBLfyq66HGXxfzggmsN1AM5hbo0kQ2OqaVKabxxSIHn6n_A6MgjFJ_bcUrS5iYtLxGK051YPLMCuPKomVxs1SNDBUb6F_6auhhVM3_GwdJgD3bCpCM07iYrjtt1jUQqjZF2EnakNjvcGxKNpVE=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtowPOKKhrLx-YfyB1QTfGxLJ_sVcjjRxHT64XBcihV0R9ofy3fmIcEadzIE1ZmWcksjbZ3TjT90u17nL_-fI9U1lHqUhA4IMc3wFWMcEcHomhreOmVgnLW-7jhaROixy_mV8YneNKWrOXZ9fo2RTFqH6boxtPbO7TL5-YpJ8GccdP0RP_YPBbZAKrwWPRCn_Ixa-SBjy2E99i0q5_enXXpqnyXAbtjJ1D0fxLpKo0ckOnt48nk6nXnKOpKC1lsnS7j6DY_lEzoLHcJ4uhLdTxUXxpTjLZxIZ9F65cZqNRdeA1b2JJpOfztHx4dsPG7URS2gv3RXJTSZckX3jBP46u9NE851SZ08_cw7124AUVDEt6UtxBFnJ8480Iy6uHaM15dRxKv5hBDnyZ3m525KLQ-3a2gPRueJ90QHZfO-I36XG1gb4o11xwsZLCxxiZqyaovdXJQdLK8Cwc3wAUK-rRDVnIL-8TO4u3JnjqjA9o_UZKwQ8wpWz9DViaPXJvw7NbzY3i1Si5lnpVLvKuR3n7ooU5eKpDf7G53ELuTabYe63rFnD46eHe580T8KlpwvtnL4E7REpIPBEYlJjD4dk1n9JAgICUG3aS7geO8iHhA3ghatsidr-1b-nrWAL6Ug-gF-WalMGM8_l3ahCLuhvhYmNj5jQ_5yiCzL83eUGWUOPjRNXmX5Jk1HTUVcIqQmRRgmohfqGcuSSzOqOvoiat2nYykqEtDvzZdw-BjZxNaKkUD_kx10ohIFtfc4FnDh7SsUNjzxH1d5YN4tQrbtbxd1wlC9uAWKZRZ9pQoFvkINDYUer0WxUOk4c1JAK_NibIldbqAvdmmPUg6boVcqsqp5LlxNNHUdKfdfI4MJ0rjSyFl8_8mNsOREjdIcQPNCY_Bsp0dzBEDPb3sCf-_DEpvldq6c8NQq6xou5HL7y7GmHM9RMrkZtKriA-4ukhd0afijjmoX0QWybRhJrRp602a3-R7faDHVbtlVNK0ro3aBhrUFDviyhQm0cKsxE2xApa7ls0a_skrCRQvxfUBZ-qJrIuvSDiErcv0_HHNVOoSn39GesHdwMh2wsg2xEUjO5EzUU-3XchdwwvSdLCJTYTrf14PBLfyq66HGXxfzggmsN1AM5hbo0kQ2OqaVKabxxSIHn6n_A6MgjFJ_bcUrS5iYtLxGK051YPLMCuPKomVxs1SNDBUb6F_6auhhVM3_GwdJgD3bCpCM07iYrjtt1jUQqjZF2EnakNjvcGxKNpVE=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtowPOKKhrLx-YfyB1QTfGxLJ_sVcjjRxHT64XBcihV0R9ofy3fmIcEadzIE1ZmWcksjbZ3TjT90u17nL_-fI9U1lHqUhA4IMc3wFWMcEcHomhreOmVgnLW-7jhaROixy_mV8YneNKWrOXZ9fo2RTFqH6boxtPbO7TL5-YpJ8GccdP0RP_YPBbZAKrwWPRCn_Ixa-SBjy2E99i0q5_enXXpqnyXAbtjJ1D0fxLpKo0ckOnt48nk6nXnKOpKC1lsnS7j6DY_lEzoLHcJ4uhLdTxUXxpTjLZxIZ9F65cZqNRdeA1b2JJpOfztHx4dsPG7URS2gv3RXJTSZckX3jBP46u9NE851SZ08_cw7124AUVDEt6UtxBFnJ8480Iy6uHaM15dRxKv5hBDnyZ3m525KLQ-3a2gPRueJ90QHZfO-I36XG1gb4o11xwsZLCxxiZqyaovdXJQdLK8Cwc3wAUK-rRDVnIL-8TO4u3JnjqjA9o_UZKwQ8wpWz9DViaPXJvw7NbzY3i1Si5lnpVLvKuR3n7ooU5eKpDf7G53ELuTabYe63rFnD46eHe580T8KlpwvtnL4E7REpIPBEYlJjD4dk1n9JAgICUG3aS7geO8iHhA3ghatsidr-1b-nrWAL6Ug-gF-WalMGM8_l3ahCLuhvhYmNj5jQ_5yiCzL83eUGWUOPjRNXmX5Jk1HTUVcIqQmRRgmohfqGcuSSzOqOvoiat2nYykqEtDvzZdw-BjZxNaKkUD_kx10ohIFtfc4FnDh7SsUNjzxH1d5YN4tQrbtbxd1wlC9uAWKZRZ9pQoFvkINDYUer0WxUOk4c1JAK_NibIldbqAvdmmPUg6boVcqsqp5LlxNNHUdKfdfI4MJ0rjSyFl8_8mNsOREjdIcQPNCY_Bsp0dzBEDPb3sCf-_DEpvldq6c8NQq6xou5HL7y7GmHM9RMrkZtKriA-4ukhd0afijjmoX0QWybRhJrRp602a3-R7faDHVbtlVNK0ro3aBhrUFDviyhQm0cKsxE2xApa7ls0a_skrCRQvxfUBZ-qJrIuvSDiErcv0_HHNVOoSn39GesHdwMh2wsg2xEUjO5EzUU-3XchdwwvSdLCJTYTrf14PBLfyq66HGXxfzggmsN1AM5hbo0kQ2OqaVKabxxSIHn6n_A6MgjFJ_bcUrS5iYtLxGK051YPLMCuPKomVxs1SNDBUb6F_6auhhVM3_GwdJgD3bCpCM07iYrjtt1jUQqjZF2EnakNjvcGxKNpVE=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1233.02,
            "totalPrice": 996
          },
          {
            "allPassengerTotalPrice": 1002,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBor98FEHtBGoCyvV2Sg4Z3H9BhYxoVJHZEaxFOFWYiqp2ebGOU9G_i65ia0jsUZjRXssarE4er85bFl-m5nrFulzb0uNxhfi_MkZXgmK-OINfy4Flr5tz370Vq9qVe06LJ3vFSCUAr2qjKGUNN_-Ph4FVyy3nRuzDzpGxdrDR6qeNodTcptX3YluoP48yMe4aGrxR7vWY030s8EHwbA1bkFlM0Isxpf43TsMca3Hax35xf9cMRBRCrlgQg78pxubl-I-YaG2xoJwMz-LYsFUq9Ga2EjkWxrozKJ6VGEtjdjKcKsFXrlY5Yvtp0N-6tWm33kXzoMWjcRP2FE7nb3kWMdt2OA-gWeFoGWFM7m75fmjIAqt_CcKTw2vis-yENzVFzJcpeT6R_yf7vx86zayFjSEpS81__1TMW7hLwb4hgPrWn0Q91lf7RC1UiRvj_8b-wz3ALu5OCUC39ROi6Vm_MZ1IbheghjHed0aC7zIk_w3LerVgyQcsulIcHeQQmD4MKzwWXhSqMRSR2-NBBLk9vVuuRsh-Wnx8jlzj6pNr1rWZX71dV66Q8UR3tSlznfOCnMYmPOzNVacudHoqJRZAPnU774aaVYvI3TR8GyaLrEHApbDZOLZTivTxXLiPShOmZ6bpJuhDH3ybiAQM4V1i85riZRVZVNWx4yV7qYIYxgENC2WTH_OITN-rpu3KE7tNPfmGqskn6CpktcQT6e2lgVhV0v4lxlwDbvtyAAPdDifBaovk6d081eoVcwJHut6tCYdi_0HZcUUGM02wSDH9Zdj8kdL--5q90VDI8XSerHd5qXxUitCsX0QdiKawWVPWm1ujmSdkAomjU52HP5PvnbwcnVfhjmocfWg-PpQRU46B2ypUrTP0Sq3OkD521_VkkT0jU0SHX7GnKhkGzvEkcFcywXS2HpBu9nQAHy90h92p4mvaAoWIW2uAc-AVTzpNm_3nlXkUVyCbuNF2fgOtQZLOtCyGN6pZ_7BGnsFv9ZchJ3wyT-jliFuKh_KsC1M7S3mMV-nmVTDkWqlVex9G4DWvZuZR4ZFH6d9vye3ytKK5a2HMKxesdEL1Tc64zmMk1KwOztzEqpTZJbXlYEn6BAOc65LyHAJ03gdsiIDRTBQsISs0hw2NE9HGQ3kA_YjQhuLddXnbUl2wNYMFKuCbZkjhNy9TJlnUJOEkfg9bw8rHv7kt4rl2molXaCNY4YjF_Ei71mEjjr7tY1CZKnq1D_2VYpZVwYUKF6-UQ79uAwmhdfpjgB9sIPriPo2425PNrhK5GMXhN5OOJw4csnYXnj0L-D4i2e-tZHo8Ukn-G_jhx8DpDcRkYmjCxzOrVVsS1OiGUwhEHw_bO0DQBllVm21zXViUkMb-c7DiU3nLdNMkuqJL1Yb8Cr2OAcMbrK6TLAPjYZmbR8hHCmyxv3OYQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBor98FEHtBGoCyvV2Sg4Z3H9BhYxoVJHZEaxFOFWYiqp2ebGOU9G_i65ia0jsUZjRXssarE4er85bFl-m5nrFulzb0uNxhfi_MkZXgmK-OINfy4Flr5tz370Vq9qVe06LJ3vFSCUAr2qjKGUNN_-Ph4FVyy3nRuzDzpGxdrDR6qeNodTcptX3YluoP48yMe4aGrxR7vWY030s8EHwbA1bkFlM0Isxpf43TsMca3Hax35xf9cMRBRCrlgQg78pxubl-I-YaG2xoJwMz-LYsFUq9Ga2EjkWxrozKJ6VGEtjdjKcKsFXrlY5Yvtp0N-6tWm33kXzoMWjcRP2FE7nb3kWMdt2OA-gWeFoGWFM7m75fmjIAqt_CcKTw2vis-yENzVFzJcpeT6R_yf7vx86zayFjSEpS81__1TMW7hLwb4hgPrWn0Q91lf7RC1UiRvj_8b-wz3ALu5OCUC39ROi6Vm_MZ1IbheghjHed0aC7zIk_w3LerVgyQcsulIcHeQQmD4MKzwWXhSqMRSR2-NBBLk9vVuuRsh-Wnx8jlzj6pNr1rWZX71dV66Q8UR3tSlznfOCnMYmPOzNVacudHoqJRZAPnU774aaVYvI3TR8GyaLrEHApbDZOLZTivTxXLiPShOmZ6bpJuhDH3ybiAQM4V1i85riZRVZVNWx4yV7qYIYxgENC2WTH_OITN-rpu3KE7tNPfmGqskn6CpktcQT6e2lgVhV0v4lxlwDbvtyAAPdDifBaovk6d081eoVcwJHut6tCYdi_0HZcUUGM02wSDH9Zdj8kdL--5q90VDI8XSerHd5qXxUitCsX0QdiKawWVPWm1ujmSdkAomjU52HP5PvnbwcnVfhjmocfWg-PpQRU46B2ypUrTP0Sq3OkD521_VkkT0jU0SHX7GnKhkGzvEkcFcywXS2HpBu9nQAHy90h92p4mvaAoWIW2uAc-AVTzpNm_3nlXkUVyCbuNF2fgOtQZLOtCyGN6pZ_7BGnsFv9ZchJ3wyT-jliFuKh_KsC1M7S3mMV-nmVTDkWqlVex9G4DWvZuZR4ZFH6d9vye3ytKK5a2HMKxesdEL1Tc64zmMk1KwOztzEqpTZJbXlYEn6BAOc65LyHAJ03gdsiIDRTBQsISs0hw2NE9HGQ3kA_YjQhuLddXnbUl2wNYMFKuCbZkjhNy9TJlnUJOEkfg9bw8rHv7kt4rl2molXaCNY4YjF_Ei71mEjjr7tY1CZKnq1D_2VYpZVwYUKF6-UQ79uAwmhdfpjgB9sIPriPo2425PNrhK5GMXhN5OOJw4csnYXnj0L-D4i2e-tZHo8Ukn-G_jhx8DpDcRkYmjCxzOrVVsS1OiGUwhEHw_bO0DQBllVm21zXViUkMb-c7DiU3nLdNMkuqJL1Yb8Cr2OAcMbrK6TLAPjYZmbR8hHCmyxv3OYQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBor98FEHtBGoCyvV2Sg4Z3H9BhYxoVJHZEaxFOFWYiqp2ebGOU9G_i65ia0jsUZjRXssarE4er85bFl-m5nrFulzb0uNxhfi_MkZXgmK-OINfy4Flr5tz370Vq9qVe06LJ3vFSCUAr2qjKGUNN_-Ph4FVyy3nRuzDzpGxdrDR6qeNodTcptX3YluoP48yMe4aGrxR7vWY030s8EHwbA1bkFlM0Isxpf43TsMca3Hax35xf9cMRBRCrlgQg78pxubl-I-YaG2xoJwMz-LYsFUq9Ga2EjkWxrozKJ6VGEtjdjKcKsFXrlY5Yvtp0N-6tWm33kXzoMWjcRP2FE7nb3kWMdt2OA-gWeFoGWFM7m75fmjIAqt_CcKTw2vis-yENzVFzJcpeT6R_yf7vx86zayFjSEpS81__1TMW7hLwb4hgPrWn0Q91lf7RC1UiRvj_8b-wz3ALu5OCUC39ROi6Vm_MZ1IbheghjHed0aC7zIk_w3LerVgyQcsulIcHeQQmD4MKzwWXhSqMRSR2-NBBLk9vVuuRsh-Wnx8jlzj6pNr1rWZX71dV66Q8UR3tSlznfOCnMYmPOzNVacudHoqJRZAPnU774aaVYvI3TR8GyaLrEHApbDZOLZTivTxXLiPShOmZ6bpJuhDH3ybiAQM4V1i85riZRVZVNWx4yV7qYIYxgENC2WTH_OITN-rpu3KE7tNPfmGqskn6CpktcQT6e2lgVhV0v4lxlwDbvtyAAPdDifBaovk6d081eoVcwJHut6tCYdi_0HZcUUGM02wSDH9Zdj8kdL--5q90VDI8XSerHd5qXxUitCsX0QdiKawWVPWm1ujmSdkAomjU52HP5PvnbwcnVfhjmocfWg-PpQRU46B2ypUrTP0Sq3OkD521_VkkT0jU0SHX7GnKhkGzvEkcFcywXS2HpBu9nQAHy90h92p4mvaAoWIW2uAc-AVTzpNm_3nlXkUVyCbuNF2fgOtQZLOtCyGN6pZ_7BGnsFv9ZchJ3wyT-jliFuKh_KsC1M7S3mMV-nmVTDkWqlVex9G4DWvZuZR4ZFH6d9vye3ytKK5a2HMKxesdEL1Tc64zmMk1KwOztzEqpTZJbXlYEn6BAOc65LyHAJ03gdsiIDRTBQsISs0hw2NE9HGQ3kA_YjQhuLddXnbUl2wNYMFKuCbZkjhNy9TJlnUJOEkfg9bw8rHv7kt4rl2molXaCNY4YjF_Ei71mEjjr7tY1CZKnq1D_2VYpZVwYUKF6-UQ79uAwmhdfpjgB9sIPriPo2425PNrhK5GMXhN5OOJw4csnYXnj0L-D4i2e-tZHo8Ukn-G_jhx8DpDcRkYmjCxzOrVVsS1OiGUwhEHw_bO0DQBllVm21zXViUkMb-c7DiU3nLdNMkuqJL1Yb8Cr2OAcMbrK6TLAPjYZmbR8hHCmyxv3OYQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1249.72,
            "totalPrice": 1002
          },
          {
            "allPassengerTotalPrice": 1005,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "19:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPdcnDFzi1s-HMN53EDkqQFJmMeHvFgqk1jsUvLNKjkgXpd5D9QJ8SravKpfR2QZyJZnyBBCsqrueBx9BmWXhJ2aIkD1DeVpa5k-D3SfNO02wwYzYc3OsMuxrGEJunoI-cBf2s2sav4eWjqD6GW0TJi-pPAQy1VJvdIMxJCUr6rXVbSu6FA3t4oqNtDRVG3aau2fzf6C8skmm3EgV1V23z1n-MjHPzx79ogvgpN4GNz9ZZyoPtQHh5gs53hMdYgcVPn7j5-wn5GXU52lWlGpZQhW0QH46_8q5cFxoYZ34oXu8e5C18UtZeHMP2-WJ9lITZW8AwHcop9REnKFvJzXox3AZWu4HFi_9TQG97D9ro8snYa1WlBWkQXN1MVQC4hTRXN8PJC-dQ8Q56UJgioOj2ZKvtsh5aZVYFW8704BcSPQGmLG9x70N9T4mp2ia1OuzGWYukoUtkyVu_5bIBOSvXpVgJR0EvgPuamuFniYYSG-h9oJTZsdPWk1VnhlZzyBwa6V7uyWP3S5-Ka2IDNxcROLKZub5ovUXB2vF-PnQ1CVH5rX8Q0uWgmBOZwRYcueot7pxjeQTpzDPamCfoygLukHjyf4l2BkRZY-8L36oswetEdXhxwwc5-iFbx7N78EoY3WP-Nd7v0H0QWSoLKDgXQXk-clmui-EX776fJN2qqcoeRLK2j4hSpJOYNgH6Fee5f02Lr2a0c1OWaA4fXijFKTI6NGJ20uOjsNXEJXg7MpvcNcHGovDZqEHRp0vJmF-7Ttn5d7-h_zQRfh3b51eeN35dF90PKYuzQ_T5TeyWZ5Zu0aIAvKmis6ePMXjuJfceMHpNkMf504cjMnhOE6gouz7EBjra49DQFMwrXRhBMYJnwCxhyq_S2hKkVJPKbjegsYJ0j1UGzQ1S7vxgRe6unhIk3BF8cplFkQig0J49BTWkNUSS5dLR0q029T60EjpJfPncgbhY-NbqPkKB6cSHwVo6rZBhLaJ8EzSKMA-pcFj4KyC91CJSKZZr11J91haclG7XOihXDTXYfErUzvkxsJE6F_9uFbrHC8ibzx1y7Q_60jfpKEdSNGpT63seY9AOMFYBRuF5L0IUN1To-LxGQd_y-ZC_FSsYTuFOmUJiI24NoWktx5AR3wVjViuiMv4u204_7wW4JZq71_vhYLF-qSZrcD0A2nihn3-Xz6bMOek4uUV_SO79ZNiuyQk_-IuXCSC4gVdnbf2xBIB3ZNvdTwfiN8K8LE_QEKjncCbTpTKyZnjhevIActt-TAXnlRDzL5floVLMwxCDv6Rw38KuUuAWecUtyTcq37zFUc03cg=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h9m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d0000932b321c",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7827,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d0000932b321c_0",
                    "local_arrival": "2024-09-15T19:25:00.000Z",
                    "local_departure": "2024-09-15T16:10:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7827",
                    "return": 0,
                    "utc_arrival": "2024-09-15T17:25:00.000Z",
                    "utc_departure": "2024-09-15T15:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPdcnDFzi1s-HMN53EDkqQFJmMeHvFgqk1jsUvLNKjkgXpd5D9QJ8SravKpfR2QZyJZnyBBCsqrueBx9BmWXhJ2aIkD1DeVpa5k-D3SfNO02wwYzYc3OsMuxrGEJunoI-cBf2s2sav4eWjqD6GW0TJi-pPAQy1VJvdIMxJCUr6rXVbSu6FA3t4oqNtDRVG3aau2fzf6C8skmm3EgV1V23z1n-MjHPzx79ogvgpN4GNz9ZZyoPtQHh5gs53hMdYgcVPn7j5-wn5GXU52lWlGpZQhW0QH46_8q5cFxoYZ34oXu8e5C18UtZeHMP2-WJ9lITZW8AwHcop9REnKFvJzXox3AZWu4HFi_9TQG97D9ro8snYa1WlBWkQXN1MVQC4hTRXN8PJC-dQ8Q56UJgioOj2ZKvtsh5aZVYFW8704BcSPQGmLG9x70N9T4mp2ia1OuzGWYukoUtkyVu_5bIBOSvXpVgJR0EvgPuamuFniYYSG-h9oJTZsdPWk1VnhlZzyBwa6V7uyWP3S5-Ka2IDNxcROLKZub5ovUXB2vF-PnQ1CVH5rX8Q0uWgmBOZwRYcueot7pxjeQTpzDPamCfoygLukHjyf4l2BkRZY-8L36oswetEdXhxwwc5-iFbx7N78EoY3WP-Nd7v0H0QWSoLKDgXQXk-clmui-EX776fJN2qqcoeRLK2j4hSpJOYNgH6Fee5f02Lr2a0c1OWaA4fXijFKTI6NGJ20uOjsNXEJXg7MpvcNcHGovDZqEHRp0vJmF-7Ttn5d7-h_zQRfh3b51eeN35dF90PKYuzQ_T5TeyWZ5Zu0aIAvKmis6ePMXjuJfceMHpNkMf504cjMnhOE6gouz7EBjra49DQFMwrXRhBMYJnwCxhyq_S2hKkVJPKbjegsYJ0j1UGzQ1S7vxgRe6unhIk3BF8cplFkQig0J49BTWkNUSS5dLR0q029T60EjpJfPncgbhY-NbqPkKB6cSHwVo6rZBhLaJ8EzSKMA-pcFj4KyC91CJSKZZr11J91haclG7XOihXDTXYfErUzvkxsJE6F_9uFbrHC8ibzx1y7Q_60jfpKEdSNGpT63seY9AOMFYBRuF5L0IUN1To-LxGQd_y-ZC_FSsYTuFOmUJiI24NoWktx5AR3wVjViuiMv4u204_7wW4JZq71_vhYLF-qSZrcD0A2nihn3-Xz6bMOek4uUV_SO79ZNiuyQk_-IuXCSC4gVdnbf2xBIB3ZNvdTwfiN8K8LE_QEKjncCbTpTKyZnjhevIActt-TAXnlRDzL5floVLMwxCDv6Rw38KuUuAWecUtyTcq37zFUc03cg=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d0000932b321c_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPdcnDFzi1s-HMN53EDkqQFJmMeHvFgqk1jsUvLNKjkgXpd5D9QJ8SravKpfR2QZyJZnyBBCsqrueBx9BmWXhJ2aIkD1DeVpa5k-D3SfNO02wwYzYc3OsMuxrGEJunoI-cBf2s2sav4eWjqD6GW0TJi-pPAQy1VJvdIMxJCUr6rXVbSu6FA3t4oqNtDRVG3aau2fzf6C8skmm3EgV1V23z1n-MjHPzx79ogvgpN4GNz9ZZyoPtQHh5gs53hMdYgcVPn7j5-wn5GXU52lWlGpZQhW0QH46_8q5cFxoYZ34oXu8e5C18UtZeHMP2-WJ9lITZW8AwHcop9REnKFvJzXox3AZWu4HFi_9TQG97D9ro8snYa1WlBWkQXN1MVQC4hTRXN8PJC-dQ8Q56UJgioOj2ZKvtsh5aZVYFW8704BcSPQGmLG9x70N9T4mp2ia1OuzGWYukoUtkyVu_5bIBOSvXpVgJR0EvgPuamuFniYYSG-h9oJTZsdPWk1VnhlZzyBwa6V7uyWP3S5-Ka2IDNxcROLKZub5ovUXB2vF-PnQ1CVH5rX8Q0uWgmBOZwRYcueot7pxjeQTpzDPamCfoygLukHjyf4l2BkRZY-8L36oswetEdXhxwwc5-iFbx7N78EoY3WP-Nd7v0H0QWSoLKDgXQXk-clmui-EX776fJN2qqcoeRLK2j4hSpJOYNgH6Fee5f02Lr2a0c1OWaA4fXijFKTI6NGJ20uOjsNXEJXg7MpvcNcHGovDZqEHRp0vJmF-7Ttn5d7-h_zQRfh3b51eeN35dF90PKYuzQ_T5TeyWZ5Zu0aIAvKmis6ePMXjuJfceMHpNkMf504cjMnhOE6gouz7EBjra49DQFMwrXRhBMYJnwCxhyq_S2hKkVJPKbjegsYJ0j1UGzQ1S7vxgRe6unhIk3BF8cplFkQig0J49BTWkNUSS5dLR0q029T60EjpJfPncgbhY-NbqPkKB6cSHwVo6rZBhLaJ8EzSKMA-pcFj4KyC91CJSKZZr11J91haclG7XOihXDTXYfErUzvkxsJE6F_9uFbrHC8ibzx1y7Q_60jfpKEdSNGpT63seY9AOMFYBRuF5L0IUN1To-LxGQd_y-ZC_FSsYTuFOmUJiI24NoWktx5AR3wVjViuiMv4u204_7wW4JZq71_vhYLF-qSZrcD0A2nihn3-Xz6bMOek4uUV_SO79ZNiuyQk_-IuXCSC4gVdnbf2xBIB3ZNvdTwfiN8K8LE_QEKjncCbTpTKyZnjhevIActt-TAXnlRDzL5floVLMwxCDv6Rw38KuUuAWecUtyTcq37zFUc03cg=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1233.32,
            "totalPrice": 1005
          },
          {
            "allPassengerTotalPrice": 1005,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "20:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRJbzB4ZQQNLi9Q8OwwYi0ys--rVQRZ2AoJ52mgRkvLGEQes7etdRawuTaq6yy_VFYo70nnMO7iaiGm5JsNgmE7yOd0wKLW2PtzW46u8XwZydxPIGsv1Jp0nMv2lgWMZIIIOIFLo_SpGWnUR0Z8Z387xu2IudmL7wFOfS1R_WUPcOdbPw7z7rT6xnIhDMIxecRJ6vsIWk7n_RbXWQ3XUWg14z46LP5jzqvKVnlw55EcPiqqKNIxj2-s8tLEYqyK2TK1AY1hMUMVFB3QADsPCg7MIr8CckbFnU8J9tPCq2URRAZD4Iu-40B-YIFgEB396XmMwV7KLmEg2OraPlodFcxaVdQLiNNJeR7t3P1X0bpZUgJ3sCaAF4KMNI4nodGRz5He8rNjiB9ubTFWMjQRGTJBTxL7wNH3CZIIKGKYK8mHaLbgsr3XXdiw6_-gRO-w8nJRDxz8evlzg5LXQmPKWnwZ4OMbv6z_Ifcf25ZjEqHCevfnKvMl2JMcB8lqWc9CeGgBnbmsEWNJiRgaeWjz8mM6RorjQn6p_rixT1n6MOiOsHPssN8ueMgg8quvLi1qu9Znzue-45i1PRfEiKJGg2M39d_F9auu216Kwpa_rIDywR6RWMUOywVW-KvjK-MwocpImaeTCypGc_x7c2D0FDIIw8tmLSeQ9bh5YdzrQSK1YrbwMeQD-9aXifFT8qlGLNpoJGO-SLcyWpaBIGGIesBhFwssgsYnHTehPOx8MTVghyPKmZeu6vMCgnimtHYH-sjb6CdSlmY4hWjz51xQkQfEQvxp-aJzlmW7HFqFUpgmcCnvnBQRY8W9jOf9OlDL9mNOhU7TJl6U7gBIuqrlodO4rMRwXOoxYCQC5sRGWBEKG4uDq6Id9niy0p1oguZ_3sRcAtEZgk0B4-bHr_MnvaJJ_0XECzDK9AkEvSkaqRh-5OLmOERKJ0arNPZZ5nFzcTUjug6C6R68B6NdNuaf9AnVNyHBmSTqGr2qFAGsRo8Oy4dxrgzBistHT6D3RqlfHdyz4iBtOBzNv_P9cs0KV2vsCFhhObAgROvWUdoWCpCEq67X9X4vTyl6De5OjR-oH97PjZc77A6DvRvrXqTGp4_B4qqXE2hc_q_OTv6kuREcQcI79TpMcGOPi7NOPiPa4I0qqJCXoX6YqIokowT0XgqR-b0CQ_9SQ_73M_SRH7_pQJyOrjjmnREzIiDs-PU7HEGTUTrepfd4xO5nAirg7W9I83-mSOrLaPJWs6GSBgHkuOiGBZtsuZ36E0NPAYAtz9ME3hBc9YUJNjIWzSmB622ysRGl4QnQNNsZSIhX6r66w=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "13:16",
                "destination": "BCN",
                "duration": "21h49m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "NYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "New York",
                    "combination_id": "0f9c20a14e0c0000d6b090ec",
                    "equipment": null,
                    "fare_basis": "SH4OASBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 20,
                    "flyFrom": "SFO",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "0f9c20a14e0c0000d6b090ec_0",
                    "local_arrival": "2024-09-14T22:00:00.000Z",
                    "local_departure": "2024-09-14T13:16:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "20",
                    "return": 0,
                    "utc_arrival": "2024-09-15T02:00:00.000Z",
                    "utc_departure": "2024-09-14T20:16:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "LON",
                    "cityFrom": "New York",
                    "cityTo": "London",
                    "combination_id": "20a122f54e0d0000107e1a69",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 704,
                    "flyFrom": "JFK",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "20a122f54e0d0000107e1a69_0",
                    "local_arrival": "2024-09-15T13:30:00.000Z",
                    "local_departure": "2024-09-15T01:30:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T12:30:00.000Z",
                    "utc_departure": "2024-09-15T05:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d000052833457",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 6026,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d000052833457_0",
                    "local_arrival": "2024-09-15T20:05:00.000Z",
                    "local_departure": "2024-09-15T16:50:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "6026",
                    "return": 0,
                    "utc_arrival": "2024-09-15T18:05:00.000Z",
                    "utc_departure": "2024-09-15T15:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRJbzB4ZQQNLi9Q8OwwYi0ys--rVQRZ2AoJ52mgRkvLGEQes7etdRawuTaq6yy_VFYo70nnMO7iaiGm5JsNgmE7yOd0wKLW2PtzW46u8XwZydxPIGsv1Jp0nMv2lgWMZIIIOIFLo_SpGWnUR0Z8Z387xu2IudmL7wFOfS1R_WUPcOdbPw7z7rT6xnIhDMIxecRJ6vsIWk7n_RbXWQ3XUWg14z46LP5jzqvKVnlw55EcPiqqKNIxj2-s8tLEYqyK2TK1AY1hMUMVFB3QADsPCg7MIr8CckbFnU8J9tPCq2URRAZD4Iu-40B-YIFgEB396XmMwV7KLmEg2OraPlodFcxaVdQLiNNJeR7t3P1X0bpZUgJ3sCaAF4KMNI4nodGRz5He8rNjiB9ubTFWMjQRGTJBTxL7wNH3CZIIKGKYK8mHaLbgsr3XXdiw6_-gRO-w8nJRDxz8evlzg5LXQmPKWnwZ4OMbv6z_Ifcf25ZjEqHCevfnKvMl2JMcB8lqWc9CeGgBnbmsEWNJiRgaeWjz8mM6RorjQn6p_rixT1n6MOiOsHPssN8ueMgg8quvLi1qu9Znzue-45i1PRfEiKJGg2M39d_F9auu216Kwpa_rIDywR6RWMUOywVW-KvjK-MwocpImaeTCypGc_x7c2D0FDIIw8tmLSeQ9bh5YdzrQSK1YrbwMeQD-9aXifFT8qlGLNpoJGO-SLcyWpaBIGGIesBhFwssgsYnHTehPOx8MTVghyPKmZeu6vMCgnimtHYH-sjb6CdSlmY4hWjz51xQkQfEQvxp-aJzlmW7HFqFUpgmcCnvnBQRY8W9jOf9OlDL9mNOhU7TJl6U7gBIuqrlodO4rMRwXOoxYCQC5sRGWBEKG4uDq6Id9niy0p1oguZ_3sRcAtEZgk0B4-bHr_MnvaJJ_0XECzDK9AkEvSkaqRh-5OLmOERKJ0arNPZZ5nFzcTUjug6C6R68B6NdNuaf9AnVNyHBmSTqGr2qFAGsRo8Oy4dxrgzBistHT6D3RqlfHdyz4iBtOBzNv_P9cs0KV2vsCFhhObAgROvWUdoWCpCEq67X9X4vTyl6De5OjR-oH97PjZc77A6DvRvrXqTGp4_B4qqXE2hc_q_OTv6kuREcQcI79TpMcGOPi7NOPiPa4I0qqJCXoX6YqIokowT0XgqR-b0CQ_9SQ_73M_SRH7_pQJyOrjjmnREzIiDs-PU7HEGTUTrepfd4xO5nAirg7W9I83-mSOrLaPJWs6GSBgHkuOiGBZtsuZ36E0NPAYAtz9ME3hBc9YUJNjIWzSmB622ysRGl4QnQNNsZSIhX6r66w=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c20a14e0c0000d6b090ec_0%7C20a122f54e0d0000107e1a69_0%7C22f501af4e0d000052833457_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRJbzB4ZQQNLi9Q8OwwYi0ys--rVQRZ2AoJ52mgRkvLGEQes7etdRawuTaq6yy_VFYo70nnMO7iaiGm5JsNgmE7yOd0wKLW2PtzW46u8XwZydxPIGsv1Jp0nMv2lgWMZIIIOIFLo_SpGWnUR0Z8Z387xu2IudmL7wFOfS1R_WUPcOdbPw7z7rT6xnIhDMIxecRJ6vsIWk7n_RbXWQ3XUWg14z46LP5jzqvKVnlw55EcPiqqKNIxj2-s8tLEYqyK2TK1AY1hMUMVFB3QADsPCg7MIr8CckbFnU8J9tPCq2URRAZD4Iu-40B-YIFgEB396XmMwV7KLmEg2OraPlodFcxaVdQLiNNJeR7t3P1X0bpZUgJ3sCaAF4KMNI4nodGRz5He8rNjiB9ubTFWMjQRGTJBTxL7wNH3CZIIKGKYK8mHaLbgsr3XXdiw6_-gRO-w8nJRDxz8evlzg5LXQmPKWnwZ4OMbv6z_Ifcf25ZjEqHCevfnKvMl2JMcB8lqWc9CeGgBnbmsEWNJiRgaeWjz8mM6RorjQn6p_rixT1n6MOiOsHPssN8ueMgg8quvLi1qu9Znzue-45i1PRfEiKJGg2M39d_F9auu216Kwpa_rIDywR6RWMUOywVW-KvjK-MwocpImaeTCypGc_x7c2D0FDIIw8tmLSeQ9bh5YdzrQSK1YrbwMeQD-9aXifFT8qlGLNpoJGO-SLcyWpaBIGGIesBhFwssgsYnHTehPOx8MTVghyPKmZeu6vMCgnimtHYH-sjb6CdSlmY4hWjz51xQkQfEQvxp-aJzlmW7HFqFUpgmcCnvnBQRY8W9jOf9OlDL9mNOhU7TJl6U7gBIuqrlodO4rMRwXOoxYCQC5sRGWBEKG4uDq6Id9niy0p1oguZ_3sRcAtEZgk0B4-bHr_MnvaJJ_0XECzDK9AkEvSkaqRh-5OLmOERKJ0arNPZZ5nFzcTUjug6C6R68B6NdNuaf9AnVNyHBmSTqGr2qFAGsRo8Oy4dxrgzBistHT6D3RqlfHdyz4iBtOBzNv_P9cs0KV2vsCFhhObAgROvWUdoWCpCEq67X9X4vTyl6De5OjR-oH97PjZc77A6DvRvrXqTGp4_B4qqXE2hc_q_OTv6kuREcQcI79TpMcGOPi7NOPiPa4I0qqJCXoX6YqIokowT0XgqR-b0CQ_9SQ_73M_SRH7_pQJyOrjjmnREzIiDs-PU7HEGTUTrepfd4xO5nAirg7W9I83-mSOrLaPJWs6GSBgHkuOiGBZtsuZ36E0NPAYAtz9ME3hBc9YUJNjIWzSmB622ysRGl4QnQNNsZSIhX6r66w=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d0000e80948dd",
                    "equipment": null,
                    "fare_basis": "PIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e1d0000e80948dd_0",
                    "local_arrival": "2024-10-01T18:10:00.000Z",
                    "local_departure": "2024-10-01T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:10:00.000Z",
                    "utc_departure": "2024-10-01T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1238.66,
            "totalPrice": 1005
          },
          {
            "allPassengerTotalPrice": 1005,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G97RR7q5Wu2f2dbjKqcYFJIRfrKHMQfn1-ckEdrJkgAmxf-4c5kSpcnFIwXOs6JIIj1sLrWlelxz8SRdEnQVa_DFJH_AwwLGE09cGz_yjlNdPDaEI8OF3drokvMUWIlX414s1HQ2ihzqRRfYJuFHrR8vpTJ1xEwaELcqf2r5s36gcDhdodx707qNkvY19EtGwnC2nXqf_hyeWdc8uYOVGn9aSlfB-31RWI6FdDtRjB8fdjrHCNwljGoEMdnzIFrJopbrTlEcNzr0DK0n1osC92H780-sFnwgeog0QbYocJ9Y2BWBRDRE9U-Wta15sWNruoTWIDmwXPoZMCZnzZHeE7A24qfuKCg2G9c65NIeK39LoJukTxNk0O2kEZMdm3rUblafsG5GaWgoxBweCaJ2qGWCJyPZ_ZyJWqDHJPGeSFJmMb0K6dF5PCKI7YUHPfz2kIuNjsfySIwIaOuii0q0R6AZ3MLOBlDFuOgGnf5K4ZH2SfoPh7wJJUl0IWsmtP9YLZiWvMMMi-NkTtKa52Ft4hyY_pGx3Kx3kALYM--kKR5lMRBDJHvOC42GjYA4ybuY1nzzlC6QnrQQmcvulmQ3wnEJ2N99Lx0USTc2YM8WMSmdb6p8dm4anUMEVU7v4VKUskLWHdrnr15wJ-f-XHgb_KdQGAxhtctwosK520jIj-im2OP2iZcNd65s0UCLN71MMhxQIIFuEIfIMshIRATT28jWNwif-IXRYKimhvSVQHNAU4mQ0zitS8PawdZYHQeArQdRQ3xHy2LLXDJGBNok5ntHma-MqC-HmGtrzl_1pTV9mThQIwR-z5Ka8I2REQJ1k0eXTBbRMvtblEX6ADp_hNrgGJlwr2iHmru8i4gGyhIrVOcNts_mkhhGotlO4ESKSFYiejySjgG4YLCT61Ycojc4-U-NBPT2dLy_ykolhh7NGmqgXJkLz24y-_lshTBkzm1Z-6k7BxY8yXXFvOm95oqLxfCzbEhHIIbLVkovwhRQ9Vm7BJXmS62ljiTZSY7EAvVVWQrx966oTszcgKt6xJGfQRDm2tZOYCmVqssuIIkbEAsSfIU5_OMKW3dUnY5kn3G3GD6OvUxphIwsLII1iyjoFiQGb7fwl6wHN0JEofPeHUi7cMdgtWYoAt8KiAZIMJ6wWOmrTEajDXzULrmg4bMh_cvBMMS26LKd7JicweisUWwzHL1Jig6GvdMvpCSg_fCUrR9ZCyFzy_5vEk67ee2KwxSmJPnAZR9_UWldUrN5egkzju2I4Ir0QMx9Ln_apTseuJNEhQO5AE_0NVqWiLIEbsDPwOWl3FxpkbNG_qJwmjEERvw000lr7OVL70ihWM34zSf__l2TVZoW5zyycWZJTlpS2Ty0BvZJm031rt0Onw62pWr9t9hIZbdcfasDso_8WZuJeEUmSi3clvKqIjfHJj9YJ5yd_nWm8Df-raNc=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G97RR7q5Wu2f2dbjKqcYFJIRfrKHMQfn1-ckEdrJkgAmxf-4c5kSpcnFIwXOs6JIIj1sLrWlelxz8SRdEnQVa_DFJH_AwwLGE09cGz_yjlNdPDaEI8OF3drokvMUWIlX414s1HQ2ihzqRRfYJuFHrR8vpTJ1xEwaELcqf2r5s36gcDhdodx707qNkvY19EtGwnC2nXqf_hyeWdc8uYOVGn9aSlfB-31RWI6FdDtRjB8fdjrHCNwljGoEMdnzIFrJopbrTlEcNzr0DK0n1osC92H780-sFnwgeog0QbYocJ9Y2BWBRDRE9U-Wta15sWNruoTWIDmwXPoZMCZnzZHeE7A24qfuKCg2G9c65NIeK39LoJukTxNk0O2kEZMdm3rUblafsG5GaWgoxBweCaJ2qGWCJyPZ_ZyJWqDHJPGeSFJmMb0K6dF5PCKI7YUHPfz2kIuNjsfySIwIaOuii0q0R6AZ3MLOBlDFuOgGnf5K4ZH2SfoPh7wJJUl0IWsmtP9YLZiWvMMMi-NkTtKa52Ft4hyY_pGx3Kx3kALYM--kKR5lMRBDJHvOC42GjYA4ybuY1nzzlC6QnrQQmcvulmQ3wnEJ2N99Lx0USTc2YM8WMSmdb6p8dm4anUMEVU7v4VKUskLWHdrnr15wJ-f-XHgb_KdQGAxhtctwosK520jIj-im2OP2iZcNd65s0UCLN71MMhxQIIFuEIfIMshIRATT28jWNwif-IXRYKimhvSVQHNAU4mQ0zitS8PawdZYHQeArQdRQ3xHy2LLXDJGBNok5ntHma-MqC-HmGtrzl_1pTV9mThQIwR-z5Ka8I2REQJ1k0eXTBbRMvtblEX6ADp_hNrgGJlwr2iHmru8i4gGyhIrVOcNts_mkhhGotlO4ESKSFYiejySjgG4YLCT61Ycojc4-U-NBPT2dLy_ykolhh7NGmqgXJkLz24y-_lshTBkzm1Z-6k7BxY8yXXFvOm95oqLxfCzbEhHIIbLVkovwhRQ9Vm7BJXmS62ljiTZSY7EAvVVWQrx966oTszcgKt6xJGfQRDm2tZOYCmVqssuIIkbEAsSfIU5_OMKW3dUnY5kn3G3GD6OvUxphIwsLII1iyjoFiQGb7fwl6wHN0JEofPeHUi7cMdgtWYoAt8KiAZIMJ6wWOmrTEajDXzULrmg4bMh_cvBMMS26LKd7JicweisUWwzHL1Jig6GvdMvpCSg_fCUrR9ZCyFzy_5vEk67ee2KwxSmJPnAZR9_UWldUrN5egkzju2I4Ir0QMx9Ln_apTseuJNEhQO5AE_0NVqWiLIEbsDPwOWl3FxpkbNG_qJwmjEERvw000lr7OVL70ihWM34zSf__l2TVZoW5zyycWZJTlpS2Ty0BvZJm031rt0Onw62pWr9t9hIZbdcfasDso_8WZuJeEUmSi3clvKqIjfHJj9YJ5yd_nWm8Df-raNc=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000086ad835b_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G97RR7q5Wu2f2dbjKqcYFJIRfrKHMQfn1-ckEdrJkgAmxf-4c5kSpcnFIwXOs6JIIj1sLrWlelxz8SRdEnQVa_DFJH_AwwLGE09cGz_yjlNdPDaEI8OF3drokvMUWIlX414s1HQ2ihzqRRfYJuFHrR8vpTJ1xEwaELcqf2r5s36gcDhdodx707qNkvY19EtGwnC2nXqf_hyeWdc8uYOVGn9aSlfB-31RWI6FdDtRjB8fdjrHCNwljGoEMdnzIFrJopbrTlEcNzr0DK0n1osC92H780-sFnwgeog0QbYocJ9Y2BWBRDRE9U-Wta15sWNruoTWIDmwXPoZMCZnzZHeE7A24qfuKCg2G9c65NIeK39LoJukTxNk0O2kEZMdm3rUblafsG5GaWgoxBweCaJ2qGWCJyPZ_ZyJWqDHJPGeSFJmMb0K6dF5PCKI7YUHPfz2kIuNjsfySIwIaOuii0q0R6AZ3MLOBlDFuOgGnf5K4ZH2SfoPh7wJJUl0IWsmtP9YLZiWvMMMi-NkTtKa52Ft4hyY_pGx3Kx3kALYM--kKR5lMRBDJHvOC42GjYA4ybuY1nzzlC6QnrQQmcvulmQ3wnEJ2N99Lx0USTc2YM8WMSmdb6p8dm4anUMEVU7v4VKUskLWHdrnr15wJ-f-XHgb_KdQGAxhtctwosK520jIj-im2OP2iZcNd65s0UCLN71MMhxQIIFuEIfIMshIRATT28jWNwif-IXRYKimhvSVQHNAU4mQ0zitS8PawdZYHQeArQdRQ3xHy2LLXDJGBNok5ntHma-MqC-HmGtrzl_1pTV9mThQIwR-z5Ka8I2REQJ1k0eXTBbRMvtblEX6ADp_hNrgGJlwr2iHmru8i4gGyhIrVOcNts_mkhhGotlO4ESKSFYiejySjgG4YLCT61Ycojc4-U-NBPT2dLy_ykolhh7NGmqgXJkLz24y-_lshTBkzm1Z-6k7BxY8yXXFvOm95oqLxfCzbEhHIIbLVkovwhRQ9Vm7BJXmS62ljiTZSY7EAvVVWQrx966oTszcgKt6xJGfQRDm2tZOYCmVqssuIIkbEAsSfIU5_OMKW3dUnY5kn3G3GD6OvUxphIwsLII1iyjoFiQGb7fwl6wHN0JEofPeHUi7cMdgtWYoAt8KiAZIMJ6wWOmrTEajDXzULrmg4bMh_cvBMMS26LKd7JicweisUWwzHL1Jig6GvdMvpCSg_fCUrR9ZCyFzy_5vEk67ee2KwxSmJPnAZR9_UWldUrN5egkzju2I4Ir0QMx9Ln_apTseuJNEhQO5AE_0NVqWiLIEbsDPwOWl3FxpkbNG_qJwmjEERvw000lr7OVL70ihWM34zSf__l2TVZoW5zyycWZJTlpS2Ty0BvZJm031rt0Onw62pWr9t9hIZbdcfasDso_8WZuJeEUmSi3clvKqIjfHJj9YJ5yd_nWm8Df-raNc=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1251.5,
            "totalPrice": 1005
          },
          {
            "allPassengerTotalPrice": 1005,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gpvn3Dvi3km6w5xwmp_aex7F27KwRKyNp6VNAfKdjTvHbbF_r9IiIgZ4UbpEqGhx_Q1DcGM6dGFjINdG5lMw0-0nj0fBYozRuCYlT5Yta9DYf1BQcCpP7zZpeAY_bkCoB35ltIyg7kD6HYEUAN3mTEz3O94gwnq8FFWogHh0DPF6A-_f_tkFvPsLZ4-65M7hU6VmNOfWSj4Q7rJFHyAdNsYq76IbnKDkiRO-WY_EldF-MLkOXj__BiiKPazJpncVTKTJUz5SXBdfr3HUtqZbnkfM0o7YPo4YEQpOB-Sra2L3tHZwn0gVpd2sWruZf0a5Ja5H5s-oClo4srvO_5o-dFoQ7RqxGv_3ROzLu2Yt_cE02PD2yToqD5RlULf5yw_z78N-V-WWoEORflllwPhwdsjq3P9E5x3-PXJypnzv4Ou1QHEe3ktl53VpBK8d-YSXQRjzs-cq07VspJq7osaX5Fh0uDaIBkVpCp7Ljg0wFYr-RagCRnGYa72-_RK3b8fWcWtM_byFGjQyxPJp6CGFup1sDYYhCFHqQhJP42QF7Ec3dhPivfRkUPpkaJk6MIBRpioacu3fXvfr9q5LgNuO47odXu_ShihHS2ZvXWLSnAwmQQiVnf8HBq3EYoHh9NW13r94Fbitsw1N0nTyviqZRU8R3Bj9HO2GV-Zmv9GT9g6ztvfd8K3SBLRvmq6powlzNZM1_VgbfU8MgPjEjDCvUGOfrcaQxIrK6C-v0eoY-xSKqv72PHyjjo4YdWu6QUuhwQFDaIH09TocDnymemegXY5f_pPo4J6RJi8-NTeO6rSM0LX5H7ekBPaWmS9S2AN5paFyzI-Gr92fLYdHht9wlneDqSHUqX0tPngxikf1Le8Bhv_4gWjsgc5SH_YRkRMZMRV7kJfIyl25I4pLNM33eaTuvR7C7DdNLOeNli5otH5moDyT8OlK2VdpOZNmVLRWpaChE8bRkRock0638vYw-a5q058Yo4iTPlsu1kFAcrrygD-6KYzwf_QVKd4Bp5sNupeaa1JHeh304IskQTQpJ6vrht3ahFxkyoVsApLzTkOm9JtoZzueRrKeXyy6qwEbIbMC3inn4vGAr4ru_Ctj3FFY7Adq7pMtN2TpksVIGdcIsopE7GFanlr6RFDPCu5T-5IUf9foVER3PZgDtTnWrQy8IXmKbZf5_sdgUJa80MvCtyGY7GirteIrsFu2hSP8FC52W02ItV3NpOfRJ2QzR-EgbWOfQd7k-aH9SknVus-InAakgAU0V7T2sx4JB0xVJeuUTJ2197KKVnkYkdgQfdOmf_lraPKf1FC5OWEDgeAlO9ZZrP7qyCeUqrzatJ5gOqXHMlLagreUdYBrN4vcw6WhoUP5nais-dnPWlnwGnuTex0ZSmQEvWuHW66Rp_S3A31NQcyVJ6lBR-CEqR7cf2htOK1QuxTZjbHv5lfvsygU=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gpvn3Dvi3km6w5xwmp_aex7F27KwRKyNp6VNAfKdjTvHbbF_r9IiIgZ4UbpEqGhx_Q1DcGM6dGFjINdG5lMw0-0nj0fBYozRuCYlT5Yta9DYf1BQcCpP7zZpeAY_bkCoB35ltIyg7kD6HYEUAN3mTEz3O94gwnq8FFWogHh0DPF6A-_f_tkFvPsLZ4-65M7hU6VmNOfWSj4Q7rJFHyAdNsYq76IbnKDkiRO-WY_EldF-MLkOXj__BiiKPazJpncVTKTJUz5SXBdfr3HUtqZbnkfM0o7YPo4YEQpOB-Sra2L3tHZwn0gVpd2sWruZf0a5Ja5H5s-oClo4srvO_5o-dFoQ7RqxGv_3ROzLu2Yt_cE02PD2yToqD5RlULf5yw_z78N-V-WWoEORflllwPhwdsjq3P9E5x3-PXJypnzv4Ou1QHEe3ktl53VpBK8d-YSXQRjzs-cq07VspJq7osaX5Fh0uDaIBkVpCp7Ljg0wFYr-RagCRnGYa72-_RK3b8fWcWtM_byFGjQyxPJp6CGFup1sDYYhCFHqQhJP42QF7Ec3dhPivfRkUPpkaJk6MIBRpioacu3fXvfr9q5LgNuO47odXu_ShihHS2ZvXWLSnAwmQQiVnf8HBq3EYoHh9NW13r94Fbitsw1N0nTyviqZRU8R3Bj9HO2GV-Zmv9GT9g6ztvfd8K3SBLRvmq6powlzNZM1_VgbfU8MgPjEjDCvUGOfrcaQxIrK6C-v0eoY-xSKqv72PHyjjo4YdWu6QUuhwQFDaIH09TocDnymemegXY5f_pPo4J6RJi8-NTeO6rSM0LX5H7ekBPaWmS9S2AN5paFyzI-Gr92fLYdHht9wlneDqSHUqX0tPngxikf1Le8Bhv_4gWjsgc5SH_YRkRMZMRV7kJfIyl25I4pLNM33eaTuvR7C7DdNLOeNli5otH5moDyT8OlK2VdpOZNmVLRWpaChE8bRkRock0638vYw-a5q058Yo4iTPlsu1kFAcrrygD-6KYzwf_QVKd4Bp5sNupeaa1JHeh304IskQTQpJ6vrht3ahFxkyoVsApLzTkOm9JtoZzueRrKeXyy6qwEbIbMC3inn4vGAr4ru_Ctj3FFY7Adq7pMtN2TpksVIGdcIsopE7GFanlr6RFDPCu5T-5IUf9foVER3PZgDtTnWrQy8IXmKbZf5_sdgUJa80MvCtyGY7GirteIrsFu2hSP8FC52W02ItV3NpOfRJ2QzR-EgbWOfQd7k-aH9SknVus-InAakgAU0V7T2sx4JB0xVJeuUTJ2197KKVnkYkdgQfdOmf_lraPKf1FC5OWEDgeAlO9ZZrP7qyCeUqrzatJ5gOqXHMlLagreUdYBrN4vcw6WhoUP5nais-dnPWlnwGnuTex0ZSmQEvWuHW66Rp_S3A31NQcyVJ6lBR-CEqR7cf2htOK1QuxTZjbHv5lfvsygU=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e16000071b8d336_0%7C04b80a224e1c00004bc9b621_0%7C0a2222f54e1d0000b93101f3_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gpvn3Dvi3km6w5xwmp_aex7F27KwRKyNp6VNAfKdjTvHbbF_r9IiIgZ4UbpEqGhx_Q1DcGM6dGFjINdG5lMw0-0nj0fBYozRuCYlT5Yta9DYf1BQcCpP7zZpeAY_bkCoB35ltIyg7kD6HYEUAN3mTEz3O94gwnq8FFWogHh0DPF6A-_f_tkFvPsLZ4-65M7hU6VmNOfWSj4Q7rJFHyAdNsYq76IbnKDkiRO-WY_EldF-MLkOXj__BiiKPazJpncVTKTJUz5SXBdfr3HUtqZbnkfM0o7YPo4YEQpOB-Sra2L3tHZwn0gVpd2sWruZf0a5Ja5H5s-oClo4srvO_5o-dFoQ7RqxGv_3ROzLu2Yt_cE02PD2yToqD5RlULf5yw_z78N-V-WWoEORflllwPhwdsjq3P9E5x3-PXJypnzv4Ou1QHEe3ktl53VpBK8d-YSXQRjzs-cq07VspJq7osaX5Fh0uDaIBkVpCp7Ljg0wFYr-RagCRnGYa72-_RK3b8fWcWtM_byFGjQyxPJp6CGFup1sDYYhCFHqQhJP42QF7Ec3dhPivfRkUPpkaJk6MIBRpioacu3fXvfr9q5LgNuO47odXu_ShihHS2ZvXWLSnAwmQQiVnf8HBq3EYoHh9NW13r94Fbitsw1N0nTyviqZRU8R3Bj9HO2GV-Zmv9GT9g6ztvfd8K3SBLRvmq6powlzNZM1_VgbfU8MgPjEjDCvUGOfrcaQxIrK6C-v0eoY-xSKqv72PHyjjo4YdWu6QUuhwQFDaIH09TocDnymemegXY5f_pPo4J6RJi8-NTeO6rSM0LX5H7ekBPaWmS9S2AN5paFyzI-Gr92fLYdHht9wlneDqSHUqX0tPngxikf1Le8Bhv_4gWjsgc5SH_YRkRMZMRV7kJfIyl25I4pLNM33eaTuvR7C7DdNLOeNli5otH5moDyT8OlK2VdpOZNmVLRWpaChE8bRkRock0638vYw-a5q058Yo4iTPlsu1kFAcrrygD-6KYzwf_QVKd4Bp5sNupeaa1JHeh304IskQTQpJ6vrht3ahFxkyoVsApLzTkOm9JtoZzueRrKeXyy6qwEbIbMC3inn4vGAr4ru_Ctj3FFY7Adq7pMtN2TpksVIGdcIsopE7GFanlr6RFDPCu5T-5IUf9foVER3PZgDtTnWrQy8IXmKbZf5_sdgUJa80MvCtyGY7GirteIrsFu2hSP8FC52W02ItV3NpOfRJ2QzR-EgbWOfQd7k-aH9SknVus-InAakgAU0V7T2sx4JB0xVJeuUTJ2197KKVnkYkdgQfdOmf_lraPKf1FC5OWEDgeAlO9ZZrP7qyCeUqrzatJ5gOqXHMlLagreUdYBrN4vcw6WhoUP5nais-dnPWlnwGnuTex0ZSmQEvWuHW66Rp_S3A31NQcyVJ6lBR-CEqR7cf2htOK1QuxTZjbHv5lfvsygU=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e1c00004bc9b621",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e1c00004bc9b621_0",
                    "local_arrival": "2024-09-30T22:55:00.000Z",
                    "local_departure": "2024-09-30T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-09-30T20:55:00.000Z",
                    "utc_departure": "2024-09-30T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e1d0000b93101f3",
                    "equipment": null,
                    "fare_basis": "NYYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e1d0000b93101f3_0",
                    "local_arrival": "2024-10-01T09:00:00.000Z",
                    "local_departure": "2024-10-01T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:00:00.000Z",
                    "utc_departure": "2024-10-01T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1252.17,
            "totalPrice": 1005
          },
          {
            "allPassengerTotalPrice": 1008,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGcEBinUtR5Sqs0a4XjG5ScTsCKi8kry8Cw2etGCESt19psoKjbmKaNhJl1nr4oJJUPAwlLHhO9ptCjh3iNziGxYpAjx63Dx0Uq74UVqR8nV1uW_PkAtaO8ND18VDCBMYodZqboXa1hi1tHKlz3IaNzbU7L9t6_AtN6TSFD5MK1BkWIxZ0wj5FZVeRvUHXGX55AiE03hF0r2cc-pt-W5Ugepgty77isG4W9umgPHFC-x579RJV-AEtGnB_IhkcZ-iUVFjaQ0po04Z5arpsncaDhr8y2l0AnSB35m6XoGghKyOb05s8KrRnHn7G33LKgJfLZzfQUmkuMA-dUGNeglJyWOHPQFuXbl57Djex7dyh0lOi8pDZmfdq7PfC8Km8xvqvBAroCQqZVLQBiFNC8PzouEkz_9Y1vd7gZt0SQI9QIFHlYIMTma-uyiojkqFsqMGlJNQpoMl_11Jn3aE1j18B2EeYkkTHzfH5VxHVaH7hvEEQ9t200h8weME0v-SKcuZIgZArtgkOFsLunYdqZTNfF8jgvMT5VSpoHPBVC8W-MUfBYMkiVQGnXUjeqyIKElOSgRe8my5I3M1nRYGwgiyDjWXK69_xOrD-ffUfwAEwqbMrHHxLyJunKHSCOQe2PhTw-pd0E96aTwVtLq_4p69JMKkvmcfPWfhLbjiruLYMI6VzNhWtkXRbt4sCSsevnYOJvEkOR8bVMOeuPeZxtACQjX-p8yOZP3kZU36EOTU5tGLkG8OHCPpfUl3Jh5wkWLI82IFz4ujDnumuYnFl_PZejuIoKgZaM4yxGbDxnll8RSugxNPwOzc9SxXWQrd285QWjeZj47mE2HZJS2lgtvjye4tMBpWRaF8XWL1cBjiiaJxsgHwdgnDf9HJ0qIbQ4z_PH12w_d57QGYhS9xEDJ0HV8CxpB6ACoEIKcQ170rmcLqX0XyIDg7wqeg-7-vc-Ra9UptJy-URp139u57ATnOk-M1KLjreFEjKsKUMvc2F2kbop7pQ2K3h1mUYtSAfmqysGfeKVbEK5oVHh_MvOXD181JAl9H8K6-Uv2fSFtzO1sQt2Oe9gWENSeeDjtVFTfN8vLDtZ-k8r0bsztm_TBRzpDrE4v8cooAgwoVZQHwHO3A-L1ajTBsHTP4lDAymYtWeN0DaLOYsESwUp_6N49xu3T4nhmn_T4_W4EGFFHJ8EXCKJdIufbclA_vxBwKgeSRHK9Pn5VwSrrm53lyPHILBQ3c58JLRBy2xmPVYtwmKb8-awc9EbbacgQPaybvR-evFSlarYhXxs7nrf7HXNtRrZkAEyXkH9LumCIvgCw5c6Zosmzm0vydkfwcp6zvLB-WLpowbGMdvcsIReUBLSxJdw==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGcEBinUtR5Sqs0a4XjG5ScTsCKi8kry8Cw2etGCESt19psoKjbmKaNhJl1nr4oJJUPAwlLHhO9ptCjh3iNziGxYpAjx63Dx0Uq74UVqR8nV1uW_PkAtaO8ND18VDCBMYodZqboXa1hi1tHKlz3IaNzbU7L9t6_AtN6TSFD5MK1BkWIxZ0wj5FZVeRvUHXGX55AiE03hF0r2cc-pt-W5Ugepgty77isG4W9umgPHFC-x579RJV-AEtGnB_IhkcZ-iUVFjaQ0po04Z5arpsncaDhr8y2l0AnSB35m6XoGghKyOb05s8KrRnHn7G33LKgJfLZzfQUmkuMA-dUGNeglJyWOHPQFuXbl57Djex7dyh0lOi8pDZmfdq7PfC8Km8xvqvBAroCQqZVLQBiFNC8PzouEkz_9Y1vd7gZt0SQI9QIFHlYIMTma-uyiojkqFsqMGlJNQpoMl_11Jn3aE1j18B2EeYkkTHzfH5VxHVaH7hvEEQ9t200h8weME0v-SKcuZIgZArtgkOFsLunYdqZTNfF8jgvMT5VSpoHPBVC8W-MUfBYMkiVQGnXUjeqyIKElOSgRe8my5I3M1nRYGwgiyDjWXK69_xOrD-ffUfwAEwqbMrHHxLyJunKHSCOQe2PhTw-pd0E96aTwVtLq_4p69JMKkvmcfPWfhLbjiruLYMI6VzNhWtkXRbt4sCSsevnYOJvEkOR8bVMOeuPeZxtACQjX-p8yOZP3kZU36EOTU5tGLkG8OHCPpfUl3Jh5wkWLI82IFz4ujDnumuYnFl_PZejuIoKgZaM4yxGbDxnll8RSugxNPwOzc9SxXWQrd285QWjeZj47mE2HZJS2lgtvjye4tMBpWRaF8XWL1cBjiiaJxsgHwdgnDf9HJ0qIbQ4z_PH12w_d57QGYhS9xEDJ0HV8CxpB6ACoEIKcQ170rmcLqX0XyIDg7wqeg-7-vc-Ra9UptJy-URp139u57ATnOk-M1KLjreFEjKsKUMvc2F2kbop7pQ2K3h1mUYtSAfmqysGfeKVbEK5oVHh_MvOXD181JAl9H8K6-Uv2fSFtzO1sQt2Oe9gWENSeeDjtVFTfN8vLDtZ-k8r0bsztm_TBRzpDrE4v8cooAgwoVZQHwHO3A-L1ajTBsHTP4lDAymYtWeN0DaLOYsESwUp_6N49xu3T4nhmn_T4_W4EGFFHJ8EXCKJdIufbclA_vxBwKgeSRHK9Pn5VwSrrm53lyPHILBQ3c58JLRBy2xmPVYtwmKb8-awc9EbbacgQPaybvR-evFSlarYhXxs7nrf7HXNtRrZkAEyXkH9LumCIvgCw5c6Zosmzm0vydkfwcp6zvLB-WLpowbGMdvcsIReUBLSxJdw==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b813a24e1c000068b2193b_0%7C13a222f54e1d000084885915_0%7C22f524464e1d000073c88d7e_0%7C244610a14e1d0000283969f2_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGcEBinUtR5Sqs0a4XjG5ScTsCKi8kry8Cw2etGCESt19psoKjbmKaNhJl1nr4oJJUPAwlLHhO9ptCjh3iNziGxYpAjx63Dx0Uq74UVqR8nV1uW_PkAtaO8ND18VDCBMYodZqboXa1hi1tHKlz3IaNzbU7L9t6_AtN6TSFD5MK1BkWIxZ0wj5FZVeRvUHXGX55AiE03hF0r2cc-pt-W5Ugepgty77isG4W9umgPHFC-x579RJV-AEtGnB_IhkcZ-iUVFjaQ0po04Z5arpsncaDhr8y2l0AnSB35m6XoGghKyOb05s8KrRnHn7G33LKgJfLZzfQUmkuMA-dUGNeglJyWOHPQFuXbl57Djex7dyh0lOi8pDZmfdq7PfC8Km8xvqvBAroCQqZVLQBiFNC8PzouEkz_9Y1vd7gZt0SQI9QIFHlYIMTma-uyiojkqFsqMGlJNQpoMl_11Jn3aE1j18B2EeYkkTHzfH5VxHVaH7hvEEQ9t200h8weME0v-SKcuZIgZArtgkOFsLunYdqZTNfF8jgvMT5VSpoHPBVC8W-MUfBYMkiVQGnXUjeqyIKElOSgRe8my5I3M1nRYGwgiyDjWXK69_xOrD-ffUfwAEwqbMrHHxLyJunKHSCOQe2PhTw-pd0E96aTwVtLq_4p69JMKkvmcfPWfhLbjiruLYMI6VzNhWtkXRbt4sCSsevnYOJvEkOR8bVMOeuPeZxtACQjX-p8yOZP3kZU36EOTU5tGLkG8OHCPpfUl3Jh5wkWLI82IFz4ujDnumuYnFl_PZejuIoKgZaM4yxGbDxnll8RSugxNPwOzc9SxXWQrd285QWjeZj47mE2HZJS2lgtvjye4tMBpWRaF8XWL1cBjiiaJxsgHwdgnDf9HJ0qIbQ4z_PH12w_d57QGYhS9xEDJ0HV8CxpB6ACoEIKcQ170rmcLqX0XyIDg7wqeg-7-vc-Ra9UptJy-URp139u57ATnOk-M1KLjreFEjKsKUMvc2F2kbop7pQ2K3h1mUYtSAfmqysGfeKVbEK5oVHh_MvOXD181JAl9H8K6-Uv2fSFtzO1sQt2Oe9gWENSeeDjtVFTfN8vLDtZ-k8r0bsztm_TBRzpDrE4v8cooAgwoVZQHwHO3A-L1ajTBsHTP4lDAymYtWeN0DaLOYsESwUp_6N49xu3T4nhmn_T4_W4EGFFHJ8EXCKJdIufbclA_vxBwKgeSRHK9Pn5VwSrrm53lyPHILBQ3c58JLRBy2xmPVYtwmKb8-awc9EbbacgQPaybvR-evFSlarYhXxs7nrf7HXNtRrZkAEyXkH9LumCIvgCw5c6Zosmzm0vydkfwcp6zvLB-WLpowbGMdvcsIReUBLSxJdw==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:05",
                "destination": "OAK",
                "duration": "29h30m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Seville",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "04b813a24e1c000068b2193b",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3943,
                    "flyFrom": "SVQ",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "04b813a24e1c000068b2193b_0",
                    "local_arrival": "2024-09-30T23:40:00.000Z",
                    "local_departure": "2024-09-30T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3943",
                    "return": 0,
                    "utc_arrival": "2024-09-30T21:40:00.000Z",
                    "utc_departure": "2024-09-30T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "LON",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "London",
                    "combination_id": "13a222f54e1d000084885915",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8094,
                    "flyFrom": "PMI",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "13a222f54e1d000084885915_0",
                    "local_arrival": "2024-10-01T08:35:00.000Z",
                    "local_departure": "2024-10-01T07:00:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:35:00.000Z",
                    "utc_departure": "2024-10-01T05:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e1d000073c88d7e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e1d000073c88d7e_0",
                    "local_arrival": "2024-10-01T14:40:00.000Z",
                    "local_departure": "2024-10-01T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:40:00.000Z",
                    "utc_departure": "2024-10-01T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e1d0000283969f2",
                    "equipment": null,
                    "fare_basis": "WA7NR",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e1d0000283969f2_0",
                    "local_arrival": "2024-10-01T18:35:00.000Z",
                    "local_departure": "2024-10-01T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-02T01:35:00.000Z",
                    "utc_departure": "2024-10-01T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1233.72,
            "totalPrice": 1008
          },
          {
            "allPassengerTotalPrice": 1012,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5HmE8iErCEc6bThFV92i9etLkrL0ft19Y0h8oLUkP5k60WHpuGNno7M0BTQsUblD03YEV7Fdyj2rBX539SR6Q2eORbUvb5-oo8Qs_6Kwfvh_6Z_gH7ROOYmPI1GMsMmX3k-3rwLyqZ2jDYpwRm1yDlNfHKktUONtHzzEPh8MkkW5glt8MokvQ3ze_HrjtMcBZihD9zOr_URVfcL6eEd2odrFalR_T1dyGHb6HYe09DbMRQf3lHU69E03t1mOqSmwRKwbjKzeIIsubHOz_GopD6NXPQxnfInT5nW-EwfwDcJoJmCapZlZ3w1gL4aU9gF8Emn5unLoWzJfOCMHQXduUjhQ8fxOHVtx9tWTIzEnyZpiErK3kX8KNAy2iCsCpz9tXCQh6CGYBmJDwTqC-RPd7rdWGcRovvCmSVdnDlCBLYrXO8GjvfvIq1Ig9X9wu0RNHEz7kBaOfVkP2KzqgL4CPD-SWP8-ACUpwOMasmMhqGU4w9_ZHK1qyXZHUQcEtkAx9nPWgrAB6DSuf_ZuZt7EY7VGl2II7msbFgEFwhEBtxyt6NS787TnkvlXxXC59SK83NW756ewncT-nGG0nmAitAT-ttU0hnxq9Kt0g3Kxia4cXAr1tZ09eGBLn-hSaU9qSOMiNKWv8YGTICTKxe-QpFr0ZEeXQpqbw5-3CfT6Ri5H8iUKEZcW5KlmzDAtJUN1tIe7PAbfo956NSs4GXygNofQD4gXUSgfvwsxM7OSk4wzJZrMG1_TQ_DxH5_MJ0Oyg5PV9Gw--qF21nPpI0NT3jl9uSJY30eFtkZ7s5IlYkQvMTUF5O17k1yosVnQFTHMJFBHk_1z3804821i9s2W3P_lNpHJkgG9yjtVw-rbzl7ZAxqfnDCtw401wpuojHI3tkK33h4Unjkn-Uyk5IojoOs77IP9jwG0wxldiC15lGnyjZKxrA78fcJ-XOsgqujTwPuyY8BqN_FQU2_b2tn7gp3tXxHB-8hoVtranRK-5kORzbBByNBDP5lVfXC0Zv29CbqRyMMQw0rSO0K_DAWZepA2rGincO7yHlRjabUyDQY7PR4Xe8hN9h3d_iVmhw4zhZcPE2ex1sfl_oG8UlMJ0nWE4nm2xyrBntw3Fr3mpgQmLi-L2egTZu-cEgd5-abyLJ-Y9Lo94xD5EmjPS56Yi0c-RVLj0jScrNV33ASSHA-dxQ3B2QUZSlCUmzOXPHbT",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5HmE8iErCEc6bThFV92i9etLkrL0ft19Y0h8oLUkP5k60WHpuGNno7M0BTQsUblD03YEV7Fdyj2rBX539SR6Q2eORbUvb5-oo8Qs_6Kwfvh_6Z_gH7ROOYmPI1GMsMmX3k-3rwLyqZ2jDYpwRm1yDlNfHKktUONtHzzEPh8MkkW5glt8MokvQ3ze_HrjtMcBZihD9zOr_URVfcL6eEd2odrFalR_T1dyGHb6HYe09DbMRQf3lHU69E03t1mOqSmwRKwbjKzeIIsubHOz_GopD6NXPQxnfInT5nW-EwfwDcJoJmCapZlZ3w1gL4aU9gF8Emn5unLoWzJfOCMHQXduUjhQ8fxOHVtx9tWTIzEnyZpiErK3kX8KNAy2iCsCpz9tXCQh6CGYBmJDwTqC-RPd7rdWGcRovvCmSVdnDlCBLYrXO8GjvfvIq1Ig9X9wu0RNHEz7kBaOfVkP2KzqgL4CPD-SWP8-ACUpwOMasmMhqGU4w9_ZHK1qyXZHUQcEtkAx9nPWgrAB6DSuf_ZuZt7EY7VGl2II7msbFgEFwhEBtxyt6NS787TnkvlXxXC59SK83NW756ewncT-nGG0nmAitAT-ttU0hnxq9Kt0g3Kxia4cXAr1tZ09eGBLn-hSaU9qSOMiNKWv8YGTICTKxe-QpFr0ZEeXQpqbw5-3CfT6Ri5H8iUKEZcW5KlmzDAtJUN1tIe7PAbfo956NSs4GXygNofQD4gXUSgfvwsxM7OSk4wzJZrMG1_TQ_DxH5_MJ0Oyg5PV9Gw--qF21nPpI0NT3jl9uSJY30eFtkZ7s5IlYkQvMTUF5O17k1yosVnQFTHMJFBHk_1z3804821i9s2W3P_lNpHJkgG9yjtVw-rbzl7ZAxqfnDCtw401wpuojHI3tkK33h4Unjkn-Uyk5IojoOs77IP9jwG0wxldiC15lGnyjZKxrA78fcJ-XOsgqujTwPuyY8BqN_FQU2_b2tn7gp3tXxHB-8hoVtranRK-5kORzbBByNBDP5lVfXC0Zv29CbqRyMMQw0rSO0K_DAWZepA2rGincO7yHlRjabUyDQY7PR4Xe8hN9h3d_iVmhw4zhZcPE2ex1sfl_oG8UlMJ0nWE4nm2xyrBntw3Fr3mpgQmLi-L2egTZu-cEgd5-abyLJ-Y9Lo94xD5EmjPS56Yi0c-RVLj0jScrNV33ASSHA-dxQ3B2QUZSlCUmzOXPHbT",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5HmE8iErCEc6bThFV92i9etLkrL0ft19Y0h8oLUkP5k60WHpuGNno7M0BTQsUblD03YEV7Fdyj2rBX539SR6Q2eORbUvb5-oo8Qs_6Kwfvh_6Z_gH7ROOYmPI1GMsMmX3k-3rwLyqZ2jDYpwRm1yDlNfHKktUONtHzzEPh8MkkW5glt8MokvQ3ze_HrjtMcBZihD9zOr_URVfcL6eEd2odrFalR_T1dyGHb6HYe09DbMRQf3lHU69E03t1mOqSmwRKwbjKzeIIsubHOz_GopD6NXPQxnfInT5nW-EwfwDcJoJmCapZlZ3w1gL4aU9gF8Emn5unLoWzJfOCMHQXduUjhQ8fxOHVtx9tWTIzEnyZpiErK3kX8KNAy2iCsCpz9tXCQh6CGYBmJDwTqC-RPd7rdWGcRovvCmSVdnDlCBLYrXO8GjvfvIq1Ig9X9wu0RNHEz7kBaOfVkP2KzqgL4CPD-SWP8-ACUpwOMasmMhqGU4w9_ZHK1qyXZHUQcEtkAx9nPWgrAB6DSuf_ZuZt7EY7VGl2II7msbFgEFwhEBtxyt6NS787TnkvlXxXC59SK83NW756ewncT-nGG0nmAitAT-ttU0hnxq9Kt0g3Kxia4cXAr1tZ09eGBLn-hSaU9qSOMiNKWv8YGTICTKxe-QpFr0ZEeXQpqbw5-3CfT6Ri5H8iUKEZcW5KlmzDAtJUN1tIe7PAbfo956NSs4GXygNofQD4gXUSgfvwsxM7OSk4wzJZrMG1_TQ_DxH5_MJ0Oyg5PV9Gw--qF21nPpI0NT3jl9uSJY30eFtkZ7s5IlYkQvMTUF5O17k1yosVnQFTHMJFBHk_1z3804821i9s2W3P_lNpHJkgG9yjtVw-rbzl7ZAxqfnDCtw401wpuojHI3tkK33h4Unjkn-Uyk5IojoOs77IP9jwG0wxldiC15lGnyjZKxrA78fcJ-XOsgqujTwPuyY8BqN_FQU2_b2tn7gp3tXxHB-8hoVtranRK-5kORzbBByNBDP5lVfXC0Zv29CbqRyMMQw0rSO0K_DAWZepA2rGincO7yHlRjabUyDQY7PR4Xe8hN9h3d_iVmhw4zhZcPE2ex1sfl_oG8UlMJ0nWE4nm2xyrBntw3Fr3mpgQmLi-L2egTZu-cEgd5-abyLJ-Y9Lo94xD5EmjPS56Yi0c-RVLj0jScrNV33ASSHA-dxQ3B2QUZSlCUmzOXPHbT",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1195.27,
            "totalPrice": 1012
          },
          {
            "allPassengerTotalPrice": 1016,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBV4TSA3fy3asw5ly6vjZ9kOzX9IYh3dsr5NY5qbxNhuOJl0-wp9wtJiH98Xs6by2H3Fuk6cKkwD9tG2KzzuJY9ctdeRWenZzv3zTOXMw9w6HiCBFB_ZU7fmERltUM193_9pvBqeq2fw_uRtt2H1RvpJZq5Fj3QyyKdHse3NpgCNxCdLFSjbFuhrmaDqxvw0t9MS7ocIOancR1l1UKc1fWipsxxWd28yrz2sZbA3ytRZpMjd6vIv2JmfLXRCKG26jhZLGSV0BvUNlNGFcJZjQsGlRPGkFwUZTprBBMHopGD8qjMhJco3TRrwu3yZROcWBzCVH4woG98LaB_ju1aaEimTLXxRm-JQwihJ73YIf_A6UsFsBuB-ZdRUCp72lx_RVU2I3Mtclesw8-Fs2GFIufgs52gerlh9YHss2C5vBvjcrOkBP4OgvUGdNboIqFnoTmaV5RfLZywyU7CiTCdmKTOJxhtoDZ4f4q8GxW8jeHhNjZWjbnnQNzlPlVhx582r1YMykFWI_EmXZV11triWCY_usOLynE1eXnhQ1hiY4kYDCvBKqfQRGJXNoQgjo8at0vLp_HetB8De1CCqSmUbnoHVltrivQ7eGVRdh8d3c0lFR5t2uTi_OPLG1lx-0789eMjtpa5D-MLtp0Arv9l4_EEMJyBhFphIFv5h1X4RqfJxisDFWwAy502xB_sU0ek6aI0khPAHxQBBYy0fRr74_b9mm2HIQ_mZQhH-w2gRSMTYd2xxkCb_U9C-g7nIOvvaswksC6e9cp1eZ0oAAcrHkKr4r4NKFex7Xz2BOXoskmaBN43MDwCxrC-sZ3YCr33gBOleLB6Dxvh6md4rgcad2GzgA9-IRE7KtUXvwH6FJC10Hdbn-00v4KUq4az3JhvyTKhXwaVZjjg7fqAMHK0YVi3K5g8uLapYyHkNJzKqWelyHpAN3BHmA4rD5W6ln5Qe35lvJ8PHh4QeApo7EkjJbE2itXuYFWWfoPkk5jqUeIY8F57N2RpnBbxq3bj25WVmMFNE9w2zpk-C9kxCXFgnD76_MoRDx1r3I-aDOZez5-Y10iNHbLf_cY22yX9ygTbbTtXETmDbFkrMHFM11Ph6KVcTnshhOHz1avqrMJ-dLLAU0nLkIv3MsQa6CuvNeAKIJc6eg9lVKwlv9NjqWOxME2wbKLf1yOikcd3zWaKLwWej0grCxcvD8AJQ2qnBOoEeDY8JSeMJ-EfBeOwGFucxkfw==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBV4TSA3fy3asw5ly6vjZ9kOzX9IYh3dsr5NY5qbxNhuOJl0-wp9wtJiH98Xs6by2H3Fuk6cKkwD9tG2KzzuJY9ctdeRWenZzv3zTOXMw9w6HiCBFB_ZU7fmERltUM193_9pvBqeq2fw_uRtt2H1RvpJZq5Fj3QyyKdHse3NpgCNxCdLFSjbFuhrmaDqxvw0t9MS7ocIOancR1l1UKc1fWipsxxWd28yrz2sZbA3ytRZpMjd6vIv2JmfLXRCKG26jhZLGSV0BvUNlNGFcJZjQsGlRPGkFwUZTprBBMHopGD8qjMhJco3TRrwu3yZROcWBzCVH4woG98LaB_ju1aaEimTLXxRm-JQwihJ73YIf_A6UsFsBuB-ZdRUCp72lx_RVU2I3Mtclesw8-Fs2GFIufgs52gerlh9YHss2C5vBvjcrOkBP4OgvUGdNboIqFnoTmaV5RfLZywyU7CiTCdmKTOJxhtoDZ4f4q8GxW8jeHhNjZWjbnnQNzlPlVhx582r1YMykFWI_EmXZV11triWCY_usOLynE1eXnhQ1hiY4kYDCvBKqfQRGJXNoQgjo8at0vLp_HetB8De1CCqSmUbnoHVltrivQ7eGVRdh8d3c0lFR5t2uTi_OPLG1lx-0789eMjtpa5D-MLtp0Arv9l4_EEMJyBhFphIFv5h1X4RqfJxisDFWwAy502xB_sU0ek6aI0khPAHxQBBYy0fRr74_b9mm2HIQ_mZQhH-w2gRSMTYd2xxkCb_U9C-g7nIOvvaswksC6e9cp1eZ0oAAcrHkKr4r4NKFex7Xz2BOXoskmaBN43MDwCxrC-sZ3YCr33gBOleLB6Dxvh6md4rgcad2GzgA9-IRE7KtUXvwH6FJC10Hdbn-00v4KUq4az3JhvyTKhXwaVZjjg7fqAMHK0YVi3K5g8uLapYyHkNJzKqWelyHpAN3BHmA4rD5W6ln5Qe35lvJ8PHh4QeApo7EkjJbE2itXuYFWWfoPkk5jqUeIY8F57N2RpnBbxq3bj25WVmMFNE9w2zpk-C9kxCXFgnD76_MoRDx1r3I-aDOZez5-Y10iNHbLf_cY22yX9ygTbbTtXETmDbFkrMHFM11Ph6KVcTnshhOHz1avqrMJ-dLLAU0nLkIv3MsQa6CuvNeAKIJc6eg9lVKwlv9NjqWOxME2wbKLf1yOikcd3zWaKLwWej0grCxcvD8AJQ2qnBOoEeDY8JSeMJ-EfBeOwGFucxkfw==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000086ad835b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBV4TSA3fy3asw5ly6vjZ9kOzX9IYh3dsr5NY5qbxNhuOJl0-wp9wtJiH98Xs6by2H3Fuk6cKkwD9tG2KzzuJY9ctdeRWenZzv3zTOXMw9w6HiCBFB_ZU7fmERltUM193_9pvBqeq2fw_uRtt2H1RvpJZq5Fj3QyyKdHse3NpgCNxCdLFSjbFuhrmaDqxvw0t9MS7ocIOancR1l1UKc1fWipsxxWd28yrz2sZbA3ytRZpMjd6vIv2JmfLXRCKG26jhZLGSV0BvUNlNGFcJZjQsGlRPGkFwUZTprBBMHopGD8qjMhJco3TRrwu3yZROcWBzCVH4woG98LaB_ju1aaEimTLXxRm-JQwihJ73YIf_A6UsFsBuB-ZdRUCp72lx_RVU2I3Mtclesw8-Fs2GFIufgs52gerlh9YHss2C5vBvjcrOkBP4OgvUGdNboIqFnoTmaV5RfLZywyU7CiTCdmKTOJxhtoDZ4f4q8GxW8jeHhNjZWjbnnQNzlPlVhx582r1YMykFWI_EmXZV11triWCY_usOLynE1eXnhQ1hiY4kYDCvBKqfQRGJXNoQgjo8at0vLp_HetB8De1CCqSmUbnoHVltrivQ7eGVRdh8d3c0lFR5t2uTi_OPLG1lx-0789eMjtpa5D-MLtp0Arv9l4_EEMJyBhFphIFv5h1X4RqfJxisDFWwAy502xB_sU0ek6aI0khPAHxQBBYy0fRr74_b9mm2HIQ_mZQhH-w2gRSMTYd2xxkCb_U9C-g7nIOvvaswksC6e9cp1eZ0oAAcrHkKr4r4NKFex7Xz2BOXoskmaBN43MDwCxrC-sZ3YCr33gBOleLB6Dxvh6md4rgcad2GzgA9-IRE7KtUXvwH6FJC10Hdbn-00v4KUq4az3JhvyTKhXwaVZjjg7fqAMHK0YVi3K5g8uLapYyHkNJzKqWelyHpAN3BHmA4rD5W6ln5Qe35lvJ8PHh4QeApo7EkjJbE2itXuYFWWfoPkk5jqUeIY8F57N2RpnBbxq3bj25WVmMFNE9w2zpk-C9kxCXFgnD76_MoRDx1r3I-aDOZez5-Y10iNHbLf_cY22yX9ygTbbTtXETmDbFkrMHFM11Ph6KVcTnshhOHz1avqrMJ-dLLAU0nLkIv3MsQa6CuvNeAKIJc6eg9lVKwlv9NjqWOxME2wbKLf1yOikcd3zWaKLwWej0grCxcvD8AJQ2qnBOoEeDY8JSeMJ-EfBeOwGFucxkfw==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1197.04,
            "totalPrice": 1016
          },
          {
            "allPassengerTotalPrice": 1016,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GF5yLacpnJhpCoi12p-FpdGRGDPUYZssAzl8KQKsc5ak2suBwtJW8iGKyP84vqyMJF6RGo1rApN6Y23XGUzwZDyCCcP32Mupg8ohwyWap6UBqTYYRqdtTRCTIKacdxOuBqeUnSPStzc1fsYW7Wc0_QHrTTJzjERmLD109c88CCqfgmzIrCJI71mSv3DvB0F5g39v23LZA2xDShL2XNK8IMNXoqQZj1zhX6JGE9E4b6MeBY9F9q461crDNQf9OzI63je5QbF__eNG9G3EgCO484H8ffT89j9J9d30xRkj8MFesAEDD8dlKUoLSaCBHg3WYfFXcAhs0MebN6gjtJzXSybcy1Cffs1wxfKY8RFoDpGakghfSgUGz1D-oyj77zanPXc6X3TORVAIStnqSY0LEGAoSg4Ye7B-_ff2RVCs4CWdHrXFh39F4E2_iv4rWG4ql9x2q7VCOrZMeVq29LChK1SpPQ-m_2YRNPrM4qVgOMv0m6jTrsFGJo5unR5bjIa9rd38gMCrWiH4290M7R7X3WaXW6oT0DVuD0VqVxdOJ3QYpgmYlrcBaz_zqjPUETx7gGCqQ8VrwA5otLOcNg8cavQSm9-mYzb-42jq3FpocB3lJOY9ZhYi7hfsodh2CnnFGgHI9Zu6t_4ttXCDshYptteLs6Xz3OKP_pII1iAEbVXa7LT6CgYPT7LpAtyY-lv_Z7uBw2zSh8yhj7Fsump7NWE_bQfSW_-nhajn8iLOzKALg52RYl5gvi4VvU_hMtL0jZkMX9DKJL6MCm53a-Vq7H-At9h__IoJvRAEsxn5RqoxT4J9XilRZ_bxHuvgX0YQtmLMJ2f6D_5LX8FyCDog3epRbEnQU_CFfn11ZxE1PDKvGOxhK-_wu26eqWO5UPHaNfd-v3WqI1VIzM0RgUSAUQkHejpD4xW7sPw3rWTdFXvjoPu6hmLve-p_jVQGjDCcML0jRaEMCEte3WAq5vl1yGojHemOuIEiBDR1BLUuJ7CZD7FWCs_jOI6BmK0DZBK21yLI_eXnOzGHW9vef0KADx6RAu6XLNGHaBqg4lF2Rt2JzPlqVPy1r2z1Oe4JX57V8RJ7Iyzv3TSEGO9yQZbxk8ZfCpQ-tUNG94_NpfwxL4fBbX-v5muknv5XxG5rrwzh9MNHTJoL9tdxhvxcMAb2dnKa9aXrRgqRTHGOIwdC0DijNlFQLsiR814wbqJq27jv6c2Eg9IKTebh2qGEk2-IAqA==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GF5yLacpnJhpCoi12p-FpdGRGDPUYZssAzl8KQKsc5ak2suBwtJW8iGKyP84vqyMJF6RGo1rApN6Y23XGUzwZDyCCcP32Mupg8ohwyWap6UBqTYYRqdtTRCTIKacdxOuBqeUnSPStzc1fsYW7Wc0_QHrTTJzjERmLD109c88CCqfgmzIrCJI71mSv3DvB0F5g39v23LZA2xDShL2XNK8IMNXoqQZj1zhX6JGE9E4b6MeBY9F9q461crDNQf9OzI63je5QbF__eNG9G3EgCO484H8ffT89j9J9d30xRkj8MFesAEDD8dlKUoLSaCBHg3WYfFXcAhs0MebN6gjtJzXSybcy1Cffs1wxfKY8RFoDpGakghfSgUGz1D-oyj77zanPXc6X3TORVAIStnqSY0LEGAoSg4Ye7B-_ff2RVCs4CWdHrXFh39F4E2_iv4rWG4ql9x2q7VCOrZMeVq29LChK1SpPQ-m_2YRNPrM4qVgOMv0m6jTrsFGJo5unR5bjIa9rd38gMCrWiH4290M7R7X3WaXW6oT0DVuD0VqVxdOJ3QYpgmYlrcBaz_zqjPUETx7gGCqQ8VrwA5otLOcNg8cavQSm9-mYzb-42jq3FpocB3lJOY9ZhYi7hfsodh2CnnFGgHI9Zu6t_4ttXCDshYptteLs6Xz3OKP_pII1iAEbVXa7LT6CgYPT7LpAtyY-lv_Z7uBw2zSh8yhj7Fsump7NWE_bQfSW_-nhajn8iLOzKALg52RYl5gvi4VvU_hMtL0jZkMX9DKJL6MCm53a-Vq7H-At9h__IoJvRAEsxn5RqoxT4J9XilRZ_bxHuvgX0YQtmLMJ2f6D_5LX8FyCDog3epRbEnQU_CFfn11ZxE1PDKvGOxhK-_wu26eqWO5UPHaNfd-v3WqI1VIzM0RgUSAUQkHejpD4xW7sPw3rWTdFXvjoPu6hmLve-p_jVQGjDCcML0jRaEMCEte3WAq5vl1yGojHemOuIEiBDR1BLUuJ7CZD7FWCs_jOI6BmK0DZBK21yLI_eXnOzGHW9vef0KADx6RAu6XLNGHaBqg4lF2Rt2JzPlqVPy1r2z1Oe4JX57V8RJ7Iyzv3TSEGO9yQZbxk8ZfCpQ-tUNG94_NpfwxL4fBbX-v5muknv5XxG5rrwzh9MNHTJoL9tdxhvxcMAb2dnKa9aXrRgqRTHGOIwdC0DijNlFQLsiR814wbqJq27jv6c2Eg9IKTebh2qGEk2-IAqA==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000071b8d336_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GF5yLacpnJhpCoi12p-FpdGRGDPUYZssAzl8KQKsc5ak2suBwtJW8iGKyP84vqyMJF6RGo1rApN6Y23XGUzwZDyCCcP32Mupg8ohwyWap6UBqTYYRqdtTRCTIKacdxOuBqeUnSPStzc1fsYW7Wc0_QHrTTJzjERmLD109c88CCqfgmzIrCJI71mSv3DvB0F5g39v23LZA2xDShL2XNK8IMNXoqQZj1zhX6JGE9E4b6MeBY9F9q461crDNQf9OzI63je5QbF__eNG9G3EgCO484H8ffT89j9J9d30xRkj8MFesAEDD8dlKUoLSaCBHg3WYfFXcAhs0MebN6gjtJzXSybcy1Cffs1wxfKY8RFoDpGakghfSgUGz1D-oyj77zanPXc6X3TORVAIStnqSY0LEGAoSg4Ye7B-_ff2RVCs4CWdHrXFh39F4E2_iv4rWG4ql9x2q7VCOrZMeVq29LChK1SpPQ-m_2YRNPrM4qVgOMv0m6jTrsFGJo5unR5bjIa9rd38gMCrWiH4290M7R7X3WaXW6oT0DVuD0VqVxdOJ3QYpgmYlrcBaz_zqjPUETx7gGCqQ8VrwA5otLOcNg8cavQSm9-mYzb-42jq3FpocB3lJOY9ZhYi7hfsodh2CnnFGgHI9Zu6t_4ttXCDshYptteLs6Xz3OKP_pII1iAEbVXa7LT6CgYPT7LpAtyY-lv_Z7uBw2zSh8yhj7Fsump7NWE_bQfSW_-nhajn8iLOzKALg52RYl5gvi4VvU_hMtL0jZkMX9DKJL6MCm53a-Vq7H-At9h__IoJvRAEsxn5RqoxT4J9XilRZ_bxHuvgX0YQtmLMJ2f6D_5LX8FyCDog3epRbEnQU_CFfn11ZxE1PDKvGOxhK-_wu26eqWO5UPHaNfd-v3WqI1VIzM0RgUSAUQkHejpD4xW7sPw3rWTdFXvjoPu6hmLve-p_jVQGjDCcML0jRaEMCEte3WAq5vl1yGojHemOuIEiBDR1BLUuJ7CZD7FWCs_jOI6BmK0DZBK21yLI_eXnOzGHW9vef0KADx6RAu6XLNGHaBqg4lF2Rt2JzPlqVPy1r2z1Oe4JX57V8RJ7Iyzv3TSEGO9yQZbxk8ZfCpQ-tUNG94_NpfwxL4fBbX-v5muknv5XxG5rrwzh9MNHTJoL9tdxhvxcMAb2dnKa9aXrRgqRTHGOIwdC0DijNlFQLsiR814wbqJq27jv6c2Eg9IKTebh2qGEk2-IAqA==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1197.71,
            "totalPrice": 1016
          },
          {
            "allPassengerTotalPrice": 1023,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gtoj-5VA-gkiJh9T8C9waq3o1ESbcyuAGq54U_6Erc3mneGSO4NQ0dQwChjzRhaSA9wfw7gPEuw20vrM6Tt4l9hQRMEHhdIeJAvPX8Tkriz8iE1aC7sBmBLTQ9Yvip04hD1OnhOvWFJsInIS44PpTG9Y6M3jrKemAFx-ZbmkURgqp-RSRzdfWauasxIeyyA3mW_Q4T_ku8oCx3AbizK56Ut38uPKrPmTI8SvayBPURg5mVwScN9MIGHK9r-FZcYjplEffHY3H5fAhFtL38wsphqo9_7_msFVh__87sy4OSLZTvt5-pxvgiLeDpylsEct7xgg6w9epjwP50ZByjC957-d1YqEdDaycZZqkuUbHrm1rWYH2z6jpeQah9YT3W-ONUT88cdtEsMZhjvly95d-RE94JBdIx8QcxQm43IjHJTiJ1a8SJVZmGS5oxFYKnEvejlqcCMmMaGED4beYosw5xbrKXdoiMM7fRi4c1kgAT5x8eBH_IGtAWsJejQrDx3U0nLyi0YZSzr0wqQO-c2C0KyqfO1e804sgyleAbL7anItZDheQn1WhU-EFGqqSdTRzDw79uDTDdNCtC52ErWMzPYvIaaoQ8V_URgKFcbT0g-IQPz6Gg_xiSZlzPcwmNJh-z-QSSUWCuRH9kMzqsqmnKbj_pKFca0xtTbnrbZtk_gkZiYOm1KsWeIEjFmGZHsaddDIskisU97QP3f5B7wynTHpKgXZJvCFZtlFmh8B9NOeaYUtL_svJJhbHlqkVz7aN8glplnXhEBqTvtvqNz_uCWbq92EuOZvL70xBsvrodF9OsmWpcqG4HKgnK0TQd7lBgRBeshSXOcqwc7axOo24d8zQeXG6LlHxchcQz_xvd3CvhP0jX_N-HaqE1CzWetuga5hsHMoXxfhT1xbdtN_7gOGG__JyYfOnWcazes1yb19FXJewE34AHYXLuVxN2KR-YbvZZ34yk1nq3FINnCeaY1aia24DxOW99475eDdqdjRJPkgRvCqBhxxwkZAEb0TdCpluN5bJ6PXjXi6h8RTSJ88nvBZd__F28Jbncb5VcxI9jYBRnlHxVm0LpfuQWKoB2EzUWlVW1sYXMjiMvBBrq4IXgPzvk870UoyaWx_3uaUGrsxaYWcXl3NPVERJCMjVseeJTSS3_4t6I2AcCLvyeDWgbDCntBrFRjjQ4I_iXyfu9jwn3SWTzcz7154quS9JmWk0D5rqHgJ-6b-p5gvjsg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gtoj-5VA-gkiJh9T8C9waq3o1ESbcyuAGq54U_6Erc3mneGSO4NQ0dQwChjzRhaSA9wfw7gPEuw20vrM6Tt4l9hQRMEHhdIeJAvPX8Tkriz8iE1aC7sBmBLTQ9Yvip04hD1OnhOvWFJsInIS44PpTG9Y6M3jrKemAFx-ZbmkURgqp-RSRzdfWauasxIeyyA3mW_Q4T_ku8oCx3AbizK56Ut38uPKrPmTI8SvayBPURg5mVwScN9MIGHK9r-FZcYjplEffHY3H5fAhFtL38wsphqo9_7_msFVh__87sy4OSLZTvt5-pxvgiLeDpylsEct7xgg6w9epjwP50ZByjC957-d1YqEdDaycZZqkuUbHrm1rWYH2z6jpeQah9YT3W-ONUT88cdtEsMZhjvly95d-RE94JBdIx8QcxQm43IjHJTiJ1a8SJVZmGS5oxFYKnEvejlqcCMmMaGED4beYosw5xbrKXdoiMM7fRi4c1kgAT5x8eBH_IGtAWsJejQrDx3U0nLyi0YZSzr0wqQO-c2C0KyqfO1e804sgyleAbL7anItZDheQn1WhU-EFGqqSdTRzDw79uDTDdNCtC52ErWMzPYvIaaoQ8V_URgKFcbT0g-IQPz6Gg_xiSZlzPcwmNJh-z-QSSUWCuRH9kMzqsqmnKbj_pKFca0xtTbnrbZtk_gkZiYOm1KsWeIEjFmGZHsaddDIskisU97QP3f5B7wynTHpKgXZJvCFZtlFmh8B9NOeaYUtL_svJJhbHlqkVz7aN8glplnXhEBqTvtvqNz_uCWbq92EuOZvL70xBsvrodF9OsmWpcqG4HKgnK0TQd7lBgRBeshSXOcqwc7axOo24d8zQeXG6LlHxchcQz_xvd3CvhP0jX_N-HaqE1CzWetuga5hsHMoXxfhT1xbdtN_7gOGG__JyYfOnWcazes1yb19FXJewE34AHYXLuVxN2KR-YbvZZ34yk1nq3FINnCeaY1aia24DxOW99475eDdqdjRJPkgRvCqBhxxwkZAEb0TdCpluN5bJ6PXjXi6h8RTSJ88nvBZd__F28Jbncb5VcxI9jYBRnlHxVm0LpfuQWKoB2EzUWlVW1sYXMjiMvBBrq4IXgPzvk870UoyaWx_3uaUGrsxaYWcXl3NPVERJCMjVseeJTSS3_4t6I2AcCLvyeDWgbDCntBrFRjjQ4I_iXyfu9jwn3SWTzcz7154quS9JmWk0D5rqHgJ-6b-p5gvjsg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000890a2b7b_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gtoj-5VA-gkiJh9T8C9waq3o1ESbcyuAGq54U_6Erc3mneGSO4NQ0dQwChjzRhaSA9wfw7gPEuw20vrM6Tt4l9hQRMEHhdIeJAvPX8Tkriz8iE1aC7sBmBLTQ9Yvip04hD1OnhOvWFJsInIS44PpTG9Y6M3jrKemAFx-ZbmkURgqp-RSRzdfWauasxIeyyA3mW_Q4T_ku8oCx3AbizK56Ut38uPKrPmTI8SvayBPURg5mVwScN9MIGHK9r-FZcYjplEffHY3H5fAhFtL38wsphqo9_7_msFVh__87sy4OSLZTvt5-pxvgiLeDpylsEct7xgg6w9epjwP50ZByjC957-d1YqEdDaycZZqkuUbHrm1rWYH2z6jpeQah9YT3W-ONUT88cdtEsMZhjvly95d-RE94JBdIx8QcxQm43IjHJTiJ1a8SJVZmGS5oxFYKnEvejlqcCMmMaGED4beYosw5xbrKXdoiMM7fRi4c1kgAT5x8eBH_IGtAWsJejQrDx3U0nLyi0YZSzr0wqQO-c2C0KyqfO1e804sgyleAbL7anItZDheQn1WhU-EFGqqSdTRzDw79uDTDdNCtC52ErWMzPYvIaaoQ8V_URgKFcbT0g-IQPz6Gg_xiSZlzPcwmNJh-z-QSSUWCuRH9kMzqsqmnKbj_pKFca0xtTbnrbZtk_gkZiYOm1KsWeIEjFmGZHsaddDIskisU97QP3f5B7wynTHpKgXZJvCFZtlFmh8B9NOeaYUtL_svJJhbHlqkVz7aN8glplnXhEBqTvtvqNz_uCWbq92EuOZvL70xBsvrodF9OsmWpcqG4HKgnK0TQd7lBgRBeshSXOcqwc7axOo24d8zQeXG6LlHxchcQz_xvd3CvhP0jX_N-HaqE1CzWetuga5hsHMoXxfhT1xbdtN_7gOGG__JyYfOnWcazes1yb19FXJewE34AHYXLuVxN2KR-YbvZZ34yk1nq3FINnCeaY1aia24DxOW99475eDdqdjRJPkgRvCqBhxxwkZAEb0TdCpluN5bJ6PXjXi6h8RTSJ88nvBZd__F28Jbncb5VcxI9jYBRnlHxVm0LpfuQWKoB2EzUWlVW1sYXMjiMvBBrq4IXgPzvk870UoyaWx_3uaUGrsxaYWcXl3NPVERJCMjVseeJTSS3_4t6I2AcCLvyeDWgbDCntBrFRjjQ4I_iXyfu9jwn3SWTzcz7154quS9JmWk0D5rqHgJ-6b-p5gvjsg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1203.4,
            "totalPrice": 1023
          },
          {
            "allPassengerTotalPrice": 1025,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzNr-ALPommEc-0lX6AdWuXAnv0pNe_bm_94vAia_py06RpfkYjHx6PvW2By_jyheMBpedOyHxW41E_SmVo9-LpLSgO5h4ZYPgDaYkwcCyRKt4ZlgNtlc_4Q6JGiQr0iaz8Q2Y67eJN10YXBiGyRjEHIKoJCRQyg7cSantHIyuI1gyryyrR0-eM7Ipu7MEjBZVB9t_vTp_jufqE2doFFJZYQy5ePqVMwz9-QupIr4lAGy6U8asAhK0L093GrGVE46id9UUWad0EX-oL4TI4YyZ47d575h-y0On9VV_Cn4Fu9oOUTH5SRidbI3uLUc2HDjwwEqGFOQvZ1ISgxtkNkHp5C7fB_qwC2Vgl6d8NlEgsWqrqrzDePszVlSNx4JfnFF0eGE2V-B3hcbqeCpUHoI_GxvrWZTletF_k3ZZKN23PDdTzOY7xR_AAn0belHgS9Tx62lLgH8qDoGkRsIHHxQuVN2SglGBpVuNfnZM62j2P1mLlQZUxxipf4SE7z3j3EeQmnGdTvUtEZQvYJqFIENoX-SJwo76uprzh2i6CSdp-Aw11iU_KihSdtAB6HYZB1ziMWYWpYj5tut9rIUPM-vHuyjWeDS9af_1gkcPUBpukjPi38qAYh7Y0LveNMFCcNm6xVNtw9KRmLqBzT4yuBDhVPBPez6abESoqWIRVvh76aLqVJXmfcl5V_2BQ8yuvDLspKc7zFkY2M-63ytQT3RajMYOIgCPrDXLSvtbAuqjW-lBZeUT97n5i9L2xw_Z29_dLbNRkhftwA0nAglQiO5ZXo82SSNC4GdjgdvJ4W2kLvitGd1j1Q-G7Wcb2x_VzQydP6fdFszDKwr9Xl1uTIl09xI_MqZdf7I1OCRwcLmDH25FsJUBf5Yf14dqc7pkvCWxPZdat5PML6aOJlGIdnRaN4u3_o3G7b28apdkaZOR8-nZvEXBSkM341Ra1mthKkgSOC5NEouv1hB7MB4-JFCnuCmoxnI-raaZaFgiaUVIbZmRXFtaAQbQbmDq4OWyo_TTjH5_GVhYUuG_ATib6Oqlqj1e-WFU1MMd0rbZIFwOESGezTmEvqiG5IvnY_nyccRWfthUKGHap_FBmeLQGz1-f0kjTF6lY7XDq5nXGK-72IDTvNjI44y-MZx7JvTITR8lL8BLkuxPDqc2I7YVnY3aPfohPBkdjp7_YU4U5hNOjiaskZr8DEZD3L4osSuAeZ2",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzNr-ALPommEc-0lX6AdWuXAnv0pNe_bm_94vAia_py06RpfkYjHx6PvW2By_jyheMBpedOyHxW41E_SmVo9-LpLSgO5h4ZYPgDaYkwcCyRKt4ZlgNtlc_4Q6JGiQr0iaz8Q2Y67eJN10YXBiGyRjEHIKoJCRQyg7cSantHIyuI1gyryyrR0-eM7Ipu7MEjBZVB9t_vTp_jufqE2doFFJZYQy5ePqVMwz9-QupIr4lAGy6U8asAhK0L093GrGVE46id9UUWad0EX-oL4TI4YyZ47d575h-y0On9VV_Cn4Fu9oOUTH5SRidbI3uLUc2HDjwwEqGFOQvZ1ISgxtkNkHp5C7fB_qwC2Vgl6d8NlEgsWqrqrzDePszVlSNx4JfnFF0eGE2V-B3hcbqeCpUHoI_GxvrWZTletF_k3ZZKN23PDdTzOY7xR_AAn0belHgS9Tx62lLgH8qDoGkRsIHHxQuVN2SglGBpVuNfnZM62j2P1mLlQZUxxipf4SE7z3j3EeQmnGdTvUtEZQvYJqFIENoX-SJwo76uprzh2i6CSdp-Aw11iU_KihSdtAB6HYZB1ziMWYWpYj5tut9rIUPM-vHuyjWeDS9af_1gkcPUBpukjPi38qAYh7Y0LveNMFCcNm6xVNtw9KRmLqBzT4yuBDhVPBPez6abESoqWIRVvh76aLqVJXmfcl5V_2BQ8yuvDLspKc7zFkY2M-63ytQT3RajMYOIgCPrDXLSvtbAuqjW-lBZeUT97n5i9L2xw_Z29_dLbNRkhftwA0nAglQiO5ZXo82SSNC4GdjgdvJ4W2kLvitGd1j1Q-G7Wcb2x_VzQydP6fdFszDKwr9Xl1uTIl09xI_MqZdf7I1OCRwcLmDH25FsJUBf5Yf14dqc7pkvCWxPZdat5PML6aOJlGIdnRaN4u3_o3G7b28apdkaZOR8-nZvEXBSkM341Ra1mthKkgSOC5NEouv1hB7MB4-JFCnuCmoxnI-raaZaFgiaUVIbZmRXFtaAQbQbmDq4OWyo_TTjH5_GVhYUuG_ATib6Oqlqj1e-WFU1MMd0rbZIFwOESGezTmEvqiG5IvnY_nyccRWfthUKGHap_FBmeLQGz1-f0kjTF6lY7XDq5nXGK-72IDTvNjI44y-MZx7JvTITR8lL8BLkuxPDqc2I7YVnY3aPfohPBkdjp7_YU4U5hNOjiaskZr8DEZD3L4osSuAeZ2",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600002939fc82_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzNr-ALPommEc-0lX6AdWuXAnv0pNe_bm_94vAia_py06RpfkYjHx6PvW2By_jyheMBpedOyHxW41E_SmVo9-LpLSgO5h4ZYPgDaYkwcCyRKt4ZlgNtlc_4Q6JGiQr0iaz8Q2Y67eJN10YXBiGyRjEHIKoJCRQyg7cSantHIyuI1gyryyrR0-eM7Ipu7MEjBZVB9t_vTp_jufqE2doFFJZYQy5ePqVMwz9-QupIr4lAGy6U8asAhK0L093GrGVE46id9UUWad0EX-oL4TI4YyZ47d575h-y0On9VV_Cn4Fu9oOUTH5SRidbI3uLUc2HDjwwEqGFOQvZ1ISgxtkNkHp5C7fB_qwC2Vgl6d8NlEgsWqrqrzDePszVlSNx4JfnFF0eGE2V-B3hcbqeCpUHoI_GxvrWZTletF_k3ZZKN23PDdTzOY7xR_AAn0belHgS9Tx62lLgH8qDoGkRsIHHxQuVN2SglGBpVuNfnZM62j2P1mLlQZUxxipf4SE7z3j3EeQmnGdTvUtEZQvYJqFIENoX-SJwo76uprzh2i6CSdp-Aw11iU_KihSdtAB6HYZB1ziMWYWpYj5tut9rIUPM-vHuyjWeDS9af_1gkcPUBpukjPi38qAYh7Y0LveNMFCcNm6xVNtw9KRmLqBzT4yuBDhVPBPez6abESoqWIRVvh76aLqVJXmfcl5V_2BQ8yuvDLspKc7zFkY2M-63ytQT3RajMYOIgCPrDXLSvtbAuqjW-lBZeUT97n5i9L2xw_Z29_dLbNRkhftwA0nAglQiO5ZXo82SSNC4GdjgdvJ4W2kLvitGd1j1Q-G7Wcb2x_VzQydP6fdFszDKwr9Xl1uTIl09xI_MqZdf7I1OCRwcLmDH25FsJUBf5Yf14dqc7pkvCWxPZdat5PML6aOJlGIdnRaN4u3_o3G7b28apdkaZOR8-nZvEXBSkM341Ra1mthKkgSOC5NEouv1hB7MB4-JFCnuCmoxnI-raaZaFgiaUVIbZmRXFtaAQbQbmDq4OWyo_TTjH5_GVhYUuG_ATib6Oqlqj1e-WFU1MMd0rbZIFwOESGezTmEvqiG5IvnY_nyccRWfthUKGHap_FBmeLQGz1-f0kjTF6lY7XDq5nXGK-72IDTvNjI44y-MZx7JvTITR8lL8BLkuxPDqc2I7YVnY3aPfohPBkdjp7_YU4U5hNOjiaskZr8DEZD3L4osSuAeZ2",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1204.71,
            "totalPrice": 1025
          },
          {
            "allPassengerTotalPrice": 1041,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBTDmzmjgMkvSwFQXMcVXYTIX0UmsHjq6Yv8d6geK1Pfam3d8WI8qKOXCOTeGL0aBOPXIoC51QnkNjjZsErN_QBQhRgpkTCMZOwKVXjRwwTjlUmgZpjAZsNMOnedAkpGwNxYroyvGfn2kOCixiu3Z6KDNfa6mTgWINoctE8csRWTuoN_tWTXUxBH3uQzCcrZeL9Q27LUZ-KY72csht1RcF5pfIw86N7hO9GHgwQqp0DyV_Ni5NXy89l-HiPh4SX-ixpCup-6WCwJSwyiYu6bl65vJyjNtQm1U_n2wUuq3nwbEJazaMnKxE4wCZviBITM-POyod4emJjh44uxNovwAkyqnYkSD7FlqN9AlE-GYk4vBXAz08_-7dqFF_WLRnk1GlqNcFEV3wedCZRDZ70LNafE_gGjs1x7xa1_hUNDF4MNSOZL2egNqVQ1Py3us_nIHyQ9V-T8wUjEf_1CxXCbuIbH5TWEiGuIN4w3FORRXuw7a0P55Xqk5MkvWb7VzkkoW41xOWoVvkPTFo_PFH5mb6Lxmc-O3y1E9AgfpOt8q2YQrKyci2dHxf_L3xztXDqYLPyPGK2qmuJUQgJmuP8b5gMpR_KJZ9BNiYTffsyRKpwAc2RoOQlyGOhX_mcOHeKFyrurdkMDgJ-VycMYGE5TFnbKSTODUUZ3eKr5y3eZwM78BgY48_gi2-0xRI4R1Qa1pyv9PeJ6j6see6lpowebzWgxE6N8sdsAJ7Vht08n9xdmdYPPwDTJsSX1ez2PNh-8JEivXLv1CGAMAMYTpOD8U3WsDNNLz8pfemL3M-oKFx_U1JHtsh3jESZ_XabBMOJVKUQ5vY5TuWrh9tBfCROPKE0e50821VfOK87A_jmX7C_1Uib6duarQyvbnKu9XlRgchG4VtEBnYqsOhxzwJNhg-DUANmP-_sbcj4wvIHeOV6QhTMusZLshUeoWQy8U70F7lHQRup5ZvP-4LkNBz2-Pt4nsq4kKSl_uy3pSzpQkZRUiP_kirSezD9Rp2uRp-hqBL4nwIXvtBSDFPPCJEzM-Xr7C3XcNquthDySwS1U0oqshEm_IMuE39VNO8tJ_2HRmzIEwcDkSC4G-qfs30-iwPwwhxtcQYeLdNNS_l2mWirnhqlWaftXFiSoaa8xXnHD_5Ck6AAn42EXzL7yeHZ721ds8dHXv87fVpWv8arp98jS9EHbxxwOP2m8d8mBhp49KrGoPtTQfJzzFEAxYoMocaQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBTDmzmjgMkvSwFQXMcVXYTIX0UmsHjq6Yv8d6geK1Pfam3d8WI8qKOXCOTeGL0aBOPXIoC51QnkNjjZsErN_QBQhRgpkTCMZOwKVXjRwwTjlUmgZpjAZsNMOnedAkpGwNxYroyvGfn2kOCixiu3Z6KDNfa6mTgWINoctE8csRWTuoN_tWTXUxBH3uQzCcrZeL9Q27LUZ-KY72csht1RcF5pfIw86N7hO9GHgwQqp0DyV_Ni5NXy89l-HiPh4SX-ixpCup-6WCwJSwyiYu6bl65vJyjNtQm1U_n2wUuq3nwbEJazaMnKxE4wCZviBITM-POyod4emJjh44uxNovwAkyqnYkSD7FlqN9AlE-GYk4vBXAz08_-7dqFF_WLRnk1GlqNcFEV3wedCZRDZ70LNafE_gGjs1x7xa1_hUNDF4MNSOZL2egNqVQ1Py3us_nIHyQ9V-T8wUjEf_1CxXCbuIbH5TWEiGuIN4w3FORRXuw7a0P55Xqk5MkvWb7VzkkoW41xOWoVvkPTFo_PFH5mb6Lxmc-O3y1E9AgfpOt8q2YQrKyci2dHxf_L3xztXDqYLPyPGK2qmuJUQgJmuP8b5gMpR_KJZ9BNiYTffsyRKpwAc2RoOQlyGOhX_mcOHeKFyrurdkMDgJ-VycMYGE5TFnbKSTODUUZ3eKr5y3eZwM78BgY48_gi2-0xRI4R1Qa1pyv9PeJ6j6see6lpowebzWgxE6N8sdsAJ7Vht08n9xdmdYPPwDTJsSX1ez2PNh-8JEivXLv1CGAMAMYTpOD8U3WsDNNLz8pfemL3M-oKFx_U1JHtsh3jESZ_XabBMOJVKUQ5vY5TuWrh9tBfCROPKE0e50821VfOK87A_jmX7C_1Uib6duarQyvbnKu9XlRgchG4VtEBnYqsOhxzwJNhg-DUANmP-_sbcj4wvIHeOV6QhTMusZLshUeoWQy8U70F7lHQRup5ZvP-4LkNBz2-Pt4nsq4kKSl_uy3pSzpQkZRUiP_kirSezD9Rp2uRp-hqBL4nwIXvtBSDFPPCJEzM-Xr7C3XcNquthDySwS1U0oqshEm_IMuE39VNO8tJ_2HRmzIEwcDkSC4G-qfs30-iwPwwhxtcQYeLdNNS_l2mWirnhqlWaftXFiSoaa8xXnHD_5Ck6AAn42EXzL7yeHZ721ds8dHXv87fVpWv8arp98jS9EHbxxwOP2m8d8mBhp49KrGoPtTQfJzzFEAxYoMocaQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e160000ce81d185_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBTDmzmjgMkvSwFQXMcVXYTIX0UmsHjq6Yv8d6geK1Pfam3d8WI8qKOXCOTeGL0aBOPXIoC51QnkNjjZsErN_QBQhRgpkTCMZOwKVXjRwwTjlUmgZpjAZsNMOnedAkpGwNxYroyvGfn2kOCixiu3Z6KDNfa6mTgWINoctE8csRWTuoN_tWTXUxBH3uQzCcrZeL9Q27LUZ-KY72csht1RcF5pfIw86N7hO9GHgwQqp0DyV_Ni5NXy89l-HiPh4SX-ixpCup-6WCwJSwyiYu6bl65vJyjNtQm1U_n2wUuq3nwbEJazaMnKxE4wCZviBITM-POyod4emJjh44uxNovwAkyqnYkSD7FlqN9AlE-GYk4vBXAz08_-7dqFF_WLRnk1GlqNcFEV3wedCZRDZ70LNafE_gGjs1x7xa1_hUNDF4MNSOZL2egNqVQ1Py3us_nIHyQ9V-T8wUjEf_1CxXCbuIbH5TWEiGuIN4w3FORRXuw7a0P55Xqk5MkvWb7VzkkoW41xOWoVvkPTFo_PFH5mb6Lxmc-O3y1E9AgfpOt8q2YQrKyci2dHxf_L3xztXDqYLPyPGK2qmuJUQgJmuP8b5gMpR_KJZ9BNiYTffsyRKpwAc2RoOQlyGOhX_mcOHeKFyrurdkMDgJ-VycMYGE5TFnbKSTODUUZ3eKr5y3eZwM78BgY48_gi2-0xRI4R1Qa1pyv9PeJ6j6see6lpowebzWgxE6N8sdsAJ7Vht08n9xdmdYPPwDTJsSX1ez2PNh-8JEivXLv1CGAMAMYTpOD8U3WsDNNLz8pfemL3M-oKFx_U1JHtsh3jESZ_XabBMOJVKUQ5vY5TuWrh9tBfCROPKE0e50821VfOK87A_jmX7C_1Uib6duarQyvbnKu9XlRgchG4VtEBnYqsOhxzwJNhg-DUANmP-_sbcj4wvIHeOV6QhTMusZLshUeoWQy8U70F7lHQRup5ZvP-4LkNBz2-Pt4nsq4kKSl_uy3pSzpQkZRUiP_kirSezD9Rp2uRp-hqBL4nwIXvtBSDFPPCJEzM-Xr7C3XcNquthDySwS1U0oqshEm_IMuE39VNO8tJ_2HRmzIEwcDkSC4G-qfs30-iwPwwhxtcQYeLdNNS_l2mWirnhqlWaftXFiSoaa8xXnHD_5Ck6AAn42EXzL7yeHZ721ds8dHXv87fVpWv8arp98jS9EHbxxwOP2m8d8mBhp49KrGoPtTQfJzzFEAxYoMocaQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1217.04,
            "totalPrice": 1041
          },
          {
            "allPassengerTotalPrice": 1051,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G36kl0Nc-u9y7ihtN7q2DFs1A3k7EZVk7mhBldz_eBsyJVilQkaWuoMJh1G_HuALDC21ieQDUQmHHBnJ-565I1D01S2ykiT43werCwPKJwzmnsVDJRnCIlZYk3lWlZuT98yrMMhEO-jVH6_vWtaKUeLTwXCWo6n50hof3il0OQObN6DRc_HxG72WGmnw_HNdHwZ0nfotBgAUaetJIScETUmgxjpYGyuXcHMAOGFNJu2MzcMZy6OdkSxHdoqcPgIFjLmvz4lIckf0ihDa05OJBDlgTYYfQJJNU0-9dR_Bys7vGuyqnLMxYsCXp0imT_4K89tSl8FHEkZu6ou6Q45scpb-0d-qsrhemXX0F2XhLf9rgqSGWi4ERZdQxTuC2FmWX4GqlDfrZIIYb3lgsNMfMqUg2MGmZQ1YIgcMUnI0fTGvoMAHntWZ8103ccT5oHEv7xa39xVPJsj1VnYlUB3CNurDu1JD9nLYHJmMIw2GWAnrzpbWsM7u-bCT2RqcRY9iTXnD8ld5oEwG57lOpjV_597hhlsepU0idFz6Sbiuxl0zLxRA93ZVoCTWu3o-sFVoFYQw2kVL50789kdNTq6W2wJDBFvA01Xo8cJYjIHBn5ObHIjn0m6zjQkmT8QDYGhhMCcfqdVs6XksXaKXS697FKGbmiZtTE6Jh_pL6f4iCGcmmVsRXcCnaDlzgNozfhBBxcn-h2sBJYBaXJZz7CCpqCyeSppSU9rKVFAaBO9_sHIkKR8Pp-ZfpsNTwQU3acZD9F_wcs2DtltVDppDxi2jxZQuu_NDR-APd6qu6M3ChtvGUjs8cxJRsCefMbjNFLRArzagSyIRINCgKp6m92HbtVbsrt7UatgVp_V0McaIopQ7tT4-bzCpOEA883Js347xm37pGxGKpo329SjDBrl6hm8naZxnQTQekij6Gkvn_yOOurA1JjaKp019MmUH4WqSf1gC2eAfmBnqEnNfzZHOzCtwh6DXnLi_yYFMWDS9OhUcagDiRbvhE-fjf8cpJcRqP2wiQriUKdcbY7Mc3wAj4CGquZed2Q7JBMRqEJJlPQqyv1FnhIqZY9j70shubaHGdnuetGmambl48x7vgUhCQpsRCCaFkkEp---TZmlbB-8_jxFsSYZogtbOA_A1QmNEEQW1xoyYgFnbng_zXRW_oHERCImdImGJQs2c1KwRTepp15zOmQl10Sur6y_Sbs3Pv",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "07:40",
                "destination": "BCN",
                "duration": "19h35m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BOS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Boston",
                    "combination_id": "0f9c04884e0c0000a94a55fe",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 356,
                    "flyFrom": "SFO",
                    "flyTo": "BOS",
                    "guarantee": false,
                    "id": "0f9c04884e0c0000a94a55fe_0",
                    "local_arrival": "2024-09-14T16:26:00.000Z",
                    "local_departure": "2024-09-14T07:40:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "356",
                    "return": 0,
                    "utc_arrival": "2024-09-14T20:26:00.000Z",
                    "utc_departure": "2024-09-14T14:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BOS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Boston",
                    "cityTo": "Barcelona",
                    "combination_id": "048801af4e0c0000e48a1db7",
                    "equipment": null,
                    "fare_basis": "TIL2N7WO",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 9796,
                    "flyFrom": "BOS",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "048801af4e0c0000e48a1db7_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T22:55:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:15:00.000Z",
                    "utc_departure": "2024-09-15T02:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G36kl0Nc-u9y7ihtN7q2DFs1A3k7EZVk7mhBldz_eBsyJVilQkaWuoMJh1G_HuALDC21ieQDUQmHHBnJ-565I1D01S2ykiT43werCwPKJwzmnsVDJRnCIlZYk3lWlZuT98yrMMhEO-jVH6_vWtaKUeLTwXCWo6n50hof3il0OQObN6DRc_HxG72WGmnw_HNdHwZ0nfotBgAUaetJIScETUmgxjpYGyuXcHMAOGFNJu2MzcMZy6OdkSxHdoqcPgIFjLmvz4lIckf0ihDa05OJBDlgTYYfQJJNU0-9dR_Bys7vGuyqnLMxYsCXp0imT_4K89tSl8FHEkZu6ou6Q45scpb-0d-qsrhemXX0F2XhLf9rgqSGWi4ERZdQxTuC2FmWX4GqlDfrZIIYb3lgsNMfMqUg2MGmZQ1YIgcMUnI0fTGvoMAHntWZ8103ccT5oHEv7xa39xVPJsj1VnYlUB3CNurDu1JD9nLYHJmMIw2GWAnrzpbWsM7u-bCT2RqcRY9iTXnD8ld5oEwG57lOpjV_597hhlsepU0idFz6Sbiuxl0zLxRA93ZVoCTWu3o-sFVoFYQw2kVL50789kdNTq6W2wJDBFvA01Xo8cJYjIHBn5ObHIjn0m6zjQkmT8QDYGhhMCcfqdVs6XksXaKXS697FKGbmiZtTE6Jh_pL6f4iCGcmmVsRXcCnaDlzgNozfhBBxcn-h2sBJYBaXJZz7CCpqCyeSppSU9rKVFAaBO9_sHIkKR8Pp-ZfpsNTwQU3acZD9F_wcs2DtltVDppDxi2jxZQuu_NDR-APd6qu6M3ChtvGUjs8cxJRsCefMbjNFLRArzagSyIRINCgKp6m92HbtVbsrt7UatgVp_V0McaIopQ7tT4-bzCpOEA883Js347xm37pGxGKpo329SjDBrl6hm8naZxnQTQekij6Gkvn_yOOurA1JjaKp019MmUH4WqSf1gC2eAfmBnqEnNfzZHOzCtwh6DXnLi_yYFMWDS9OhUcagDiRbvhE-fjf8cpJcRqP2wiQriUKdcbY7Mc3wAj4CGquZed2Q7JBMRqEJJlPQqyv1FnhIqZY9j70shubaHGdnuetGmambl48x7vgUhCQpsRCCaFkkEp---TZmlbB-8_jxFsSYZogtbOA_A1QmNEEQW1xoyYgFnbng_zXRW_oHERCImdImGJQs2c1KwRTepp15zOmQl10Sur6y_Sbs3Pv",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e16000067044a57_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G36kl0Nc-u9y7ihtN7q2DFs1A3k7EZVk7mhBldz_eBsyJVilQkaWuoMJh1G_HuALDC21ieQDUQmHHBnJ-565I1D01S2ykiT43werCwPKJwzmnsVDJRnCIlZYk3lWlZuT98yrMMhEO-jVH6_vWtaKUeLTwXCWo6n50hof3il0OQObN6DRc_HxG72WGmnw_HNdHwZ0nfotBgAUaetJIScETUmgxjpYGyuXcHMAOGFNJu2MzcMZy6OdkSxHdoqcPgIFjLmvz4lIckf0ihDa05OJBDlgTYYfQJJNU0-9dR_Bys7vGuyqnLMxYsCXp0imT_4K89tSl8FHEkZu6ou6Q45scpb-0d-qsrhemXX0F2XhLf9rgqSGWi4ERZdQxTuC2FmWX4GqlDfrZIIYb3lgsNMfMqUg2MGmZQ1YIgcMUnI0fTGvoMAHntWZ8103ccT5oHEv7xa39xVPJsj1VnYlUB3CNurDu1JD9nLYHJmMIw2GWAnrzpbWsM7u-bCT2RqcRY9iTXnD8ld5oEwG57lOpjV_597hhlsepU0idFz6Sbiuxl0zLxRA93ZVoCTWu3o-sFVoFYQw2kVL50789kdNTq6W2wJDBFvA01Xo8cJYjIHBn5ObHIjn0m6zjQkmT8QDYGhhMCcfqdVs6XksXaKXS697FKGbmiZtTE6Jh_pL6f4iCGcmmVsRXcCnaDlzgNozfhBBxcn-h2sBJYBaXJZz7CCpqCyeSppSU9rKVFAaBO9_sHIkKR8Pp-ZfpsNTwQU3acZD9F_wcs2DtltVDppDxi2jxZQuu_NDR-APd6qu6M3ChtvGUjs8cxJRsCefMbjNFLRArzagSyIRINCgKp6m92HbtVbsrt7UatgVp_V0McaIopQ7tT4-bzCpOEA883Js347xm37pGxGKpo329SjDBrl6hm8naZxnQTQekij6Gkvn_yOOurA1JjaKp019MmUH4WqSf1gC2eAfmBnqEnNfzZHOzCtwh6DXnLi_yYFMWDS9OhUcagDiRbvhE-fjf8cpJcRqP2wiQriUKdcbY7Mc3wAj4CGquZed2Q7JBMRqEJJlPQqyv1FnhIqZY9j70shubaHGdnuetGmambl48x7vgUhCQpsRCCaFkkEp---TZmlbB-8_jxFsSYZogtbOA_A1QmNEEQW1xoyYgFnbng_zXRW_oHERCImdImGJQs2c1KwRTepp15zOmQl10Sur6y_Sbs3Pv",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1225.41,
            "totalPrice": 1051
          },
          {
            "allPassengerTotalPrice": 1062,
            "legs": [
              {
                "airlines": [
                  "AS",
                  "",
                  "VY"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gev25ZK5wNmZBnGVXFeC_Ngg_WFQgUcY_vWrwAraNNjfu78ENr1Lak8goFUgsWl0Mt74_zAHzlfnEEYkSoSjZT8gZ5a5MgRYIXGeEkpK6iCT5ygp8nJkouVl6oqGbG5PQJlZt7OC6gttDcgB2lDCsU_3Vy8IiJaAfqCPCjbKhl03Gvw6483CH_f73zwNNOhPSAc74T2zghe3TCdhd_pddeQHfI5hglj8m9S_bsqBgde1hlB6_VCw0jobcsq56d6cGV2NEPR9fWT4H20YAACjcqDcaMkiF43TXrQyUEV955Tpu_I-1iQAtkR1lChQpHCgLUjkqAWoT8Bm0K1_rL4N6-fv6ereDFSO3TQypG9f1KKoGGSFBgo7RxyvJCaFjgE8HPP5wusBiocxJZHRxbRnBkcpB-c8eZtX3DRoURQsV9okO6yAoaRhRS0zG0-AfnF9JEaUVwvDmxSpjD0kA3QL62PBV2Qfbl6XzuidIrGlZTTTp5KPMN63UKr7ZrRdPQQ2rfqPeJLB_qhiNppMFgYzpaxY4hsHl6NRfxLoCBsbwa6xAulMN-BRWdQoEf1VE0PRiThcL2_fyeMU0MOdiLHFEWXo-cwhSyz8JhUfKv0I0qV5A7BrCBK-Oq_VnG-kgWK6fGQgbeTfvz9PYoTBZoUx3uyD4cUjX6La2ot8XGv_XL4uAPQidZaPeeHVZC8CKnumoHEp9O5Ru5eU055MpI7nX5xaNMqj0Rsy71Nfg_go_AfbTCx6lJNT53TWIMKXGXN-gFpkgAD957Qxc8glk14DqmtDgVKIMfOW-vQxYcz6UeC4nESaAttrT5n8rQP4-SWBh5Ej04Uxk5uDFh_qvMQaQPiFUdoJf16XXfY9slmFHs_8BePTSYUAd00adN-vnaww3QGWhtOtFRZknCVWDOFAmpQk2-Lshb-7ggFJI9gxKBlgDxiLTaiUtyzXPEepaYjiEebw9mb9Lc9xx_jjS2lH3CLTdgKdE1rkOueyJ7DCswIy2AwQCqcslPegsYlFovHCcBb0Z6Q_tgTF2EXeRUZQrp5oF_NG8OYbAQWLnYMr_jRo5rqHuv0bzcmDD-e30r8SV2s0F-bMqEYu6EkeeMKzuLXiA6zMPBV1R9d8U2xsH7EPZiMMtqO7OuzMpFZCjivqwnMCfsvmwohoJQClh8FxVCNk8EMaxopMcVldOODMm59wY5RgGDjBlLW1dmFR7C5IDzuuQIY5tRL2ZsnoJGizrG9a8DHaLqZhfle7JLjNwNEuIPycRHexOeaQ-OgL-mA59",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "09:20",
                "destination": "BCN",
                "duration": "21h50m",
                "routes": [
                  {
                    "airline": "AS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "ORL",
                    "cityFrom": "San Francisco",
                    "cityTo": "Orlando",
                    "combination_id": "0f9c08d74e0c000085ebf28d",
                    "equipment": null,
                    "fare_basis": "QH2OAVBN",
                    "fare_category": "M",
                    "fare_classes": "X",
                    "flight_no": 368,
                    "flyFrom": "SFO",
                    "flyTo": "MCO",
                    "guarantee": false,
                    "id": "0f9c08d74e0c000085ebf28d_0",
                    "local_arrival": "2024-09-14T17:50:00.000Z",
                    "local_departure": "2024-09-14T09:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "368",
                    "return": 0,
                    "utc_arrival": "2024-09-14T21:50:00.000Z",
                    "utc_departure": "2024-09-14T16:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "ORL",
                    "cityCodeTo": "LON",
                    "cityFrom": "Orlando",
                    "cityTo": "London",
                    "combination_id": "08d722f54e0c0000a6ee4c36",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 784,
                    "flyFrom": "MCO",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "08d722f54e0c0000a6ee4c36_0",
                    "local_arrival": "2024-09-15T10:00:00.000Z",
                    "local_departure": "2024-09-14T20:25:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T09:00:00.000Z",
                    "utc_departure": "2024-09-15T00:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "BCN",
                    "cityFrom": "London",
                    "cityTo": "Barcelona",
                    "combination_id": "22f501af4e0d00008d5dc744",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 7833,
                    "flyFrom": "LGW",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "22f501af4e0d00008d5dc744_0",
                    "local_arrival": "2024-09-15T16:10:00.000Z",
                    "local_departure": "2024-09-15T13:00:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7833",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:10:00.000Z",
                    "utc_departure": "2024-09-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 2
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gev25ZK5wNmZBnGVXFeC_Ngg_WFQgUcY_vWrwAraNNjfu78ENr1Lak8goFUgsWl0Mt74_zAHzlfnEEYkSoSjZT8gZ5a5MgRYIXGeEkpK6iCT5ygp8nJkouVl6oqGbG5PQJlZt7OC6gttDcgB2lDCsU_3Vy8IiJaAfqCPCjbKhl03Gvw6483CH_f73zwNNOhPSAc74T2zghe3TCdhd_pddeQHfI5hglj8m9S_bsqBgde1hlB6_VCw0jobcsq56d6cGV2NEPR9fWT4H20YAACjcqDcaMkiF43TXrQyUEV955Tpu_I-1iQAtkR1lChQpHCgLUjkqAWoT8Bm0K1_rL4N6-fv6ereDFSO3TQypG9f1KKoGGSFBgo7RxyvJCaFjgE8HPP5wusBiocxJZHRxbRnBkcpB-c8eZtX3DRoURQsV9okO6yAoaRhRS0zG0-AfnF9JEaUVwvDmxSpjD0kA3QL62PBV2Qfbl6XzuidIrGlZTTTp5KPMN63UKr7ZrRdPQQ2rfqPeJLB_qhiNppMFgYzpaxY4hsHl6NRfxLoCBsbwa6xAulMN-BRWdQoEf1VE0PRiThcL2_fyeMU0MOdiLHFEWXo-cwhSyz8JhUfKv0I0qV5A7BrCBK-Oq_VnG-kgWK6fGQgbeTfvz9PYoTBZoUx3uyD4cUjX6La2ot8XGv_XL4uAPQidZaPeeHVZC8CKnumoHEp9O5Ru5eU055MpI7nX5xaNMqj0Rsy71Nfg_go_AfbTCx6lJNT53TWIMKXGXN-gFpkgAD957Qxc8glk14DqmtDgVKIMfOW-vQxYcz6UeC4nESaAttrT5n8rQP4-SWBh5Ej04Uxk5uDFh_qvMQaQPiFUdoJf16XXfY9slmFHs_8BePTSYUAd00adN-vnaww3QGWhtOtFRZknCVWDOFAmpQk2-Lshb-7ggFJI9gxKBlgDxiLTaiUtyzXPEepaYjiEebw9mb9Lc9xx_jjS2lH3CLTdgKdE1rkOueyJ7DCswIy2AwQCqcslPegsYlFovHCcBb0Z6Q_tgTF2EXeRUZQrp5oF_NG8OYbAQWLnYMr_jRo5rqHuv0bzcmDD-e30r8SV2s0F-bMqEYu6EkeeMKzuLXiA6zMPBV1R9d8U2xsH7EPZiMMtqO7OuzMpFZCjivqwnMCfsvmwohoJQClh8FxVCNk8EMaxopMcVldOODMm59wY5RgGDjBlLW1dmFR7C5IDzuuQIY5tRL2ZsnoJGizrG9a8DHaLqZhfle7JLjNwNEuIPycRHexOeaQ-OgL-mA59",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c08d74e0c000085ebf28d_0%7C08d722f54e0c0000a6ee4c36_0%7C22f501af4e0d00008d5dc744_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d00001eea8848_0%7C01af0f9c4e1d00001eea8848_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gev25ZK5wNmZBnGVXFeC_Ngg_WFQgUcY_vWrwAraNNjfu78ENr1Lak8goFUgsWl0Mt74_zAHzlfnEEYkSoSjZT8gZ5a5MgRYIXGeEkpK6iCT5ygp8nJkouVl6oqGbG5PQJlZt7OC6gttDcgB2lDCsU_3Vy8IiJaAfqCPCjbKhl03Gvw6483CH_f73zwNNOhPSAc74T2zghe3TCdhd_pddeQHfI5hglj8m9S_bsqBgde1hlB6_VCw0jobcsq56d6cGV2NEPR9fWT4H20YAACjcqDcaMkiF43TXrQyUEV955Tpu_I-1iQAtkR1lChQpHCgLUjkqAWoT8Bm0K1_rL4N6-fv6ereDFSO3TQypG9f1KKoGGSFBgo7RxyvJCaFjgE8HPP5wusBiocxJZHRxbRnBkcpB-c8eZtX3DRoURQsV9okO6yAoaRhRS0zG0-AfnF9JEaUVwvDmxSpjD0kA3QL62PBV2Qfbl6XzuidIrGlZTTTp5KPMN63UKr7ZrRdPQQ2rfqPeJLB_qhiNppMFgYzpaxY4hsHl6NRfxLoCBsbwa6xAulMN-BRWdQoEf1VE0PRiThcL2_fyeMU0MOdiLHFEWXo-cwhSyz8JhUfKv0I0qV5A7BrCBK-Oq_VnG-kgWK6fGQgbeTfvz9PYoTBZoUx3uyD4cUjX6La2ot8XGv_XL4uAPQidZaPeeHVZC8CKnumoHEp9O5Ru5eU055MpI7nX5xaNMqj0Rsy71Nfg_go_AfbTCx6lJNT53TWIMKXGXN-gFpkgAD957Qxc8glk14DqmtDgVKIMfOW-vQxYcz6UeC4nESaAttrT5n8rQP4-SWBh5Ej04Uxk5uDFh_qvMQaQPiFUdoJf16XXfY9slmFHs_8BePTSYUAd00adN-vnaww3QGWhtOtFRZknCVWDOFAmpQk2-Lshb-7ggFJI9gxKBlgDxiLTaiUtyzXPEepaYjiEebw9mb9Lc9xx_jjS2lH3CLTdgKdE1rkOueyJ7DCswIy2AwQCqcslPegsYlFovHCcBb0Z6Q_tgTF2EXeRUZQrp5oF_NG8OYbAQWLnYMr_jRo5rqHuv0bzcmDD-e30r8SV2s0F-bMqEYu6EkeeMKzuLXiA6zMPBV1R9d8U2xsH7EPZiMMtqO7OuzMpFZCjivqwnMCfsvmwohoJQClh8FxVCNk8EMaxopMcVldOODMm59wY5RgGDjBlLW1dmFR7C5IDzuuQIY5tRL2ZsnoJGizrG9a8DHaLqZhfle7JLjNwNEuIPycRHexOeaQ-OgL-mA59",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e1c000015602bb0",
                    "equipment": null,
                    "fare_basis": "WOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "W",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e1c000015602bb0_0",
                    "local_arrival": "2024-10-01T00:35:00.000Z",
                    "local_departure": "2024-09-30T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:35:00.000Z",
                    "utc_departure": "2024-09-30T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e1d00001eea8848_0",
                    "local_arrival": "2024-10-01T06:55:00.000Z",
                    "local_departure": "2024-10-01T06:00:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-01T05:55:00.000Z",
                    "utc_departure": "2024-10-01T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e1d00001eea8848",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e1d00001eea8848_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1253.18,
            "totalPrice": 1062
          },
          {
            "allPassengerTotalPrice": 1196,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJoSs-VBaddNVE79WDETRoMYQULABQQEQGPt_bgKmLuDGdtAIIFbOlRAb_sH2uoe1LiH7u44oNGOBEGFk5HZIIv_IbiNzSqbKE7cb_R-DYcUnfbEeLloAQnjwUn2P4Mn11dPmYnAqW90l83t5iHezDZsscBOrZOubtcrhZaSOILhxg7TU54bJ8KX0y5D8sp0kzcOy5UcnAYXNfR28yGQ001--ico7nHECm1I1fsOjQxsep3nNsZIbXuKqjmTbuEbTvhb5uwYDjA7cRiS_ZyTMWx-MF79fwEBN0VGOjyxBZhNM4-w4XllCy6pDLIpSQaTr7KVpe0vmXNsjXH8NIdsTy6iXVx4vqgBNAf1JqA_7prugGJmjIxcqkb4whF7C_Gi_Kr19aWb9f6UZ0H99-PSNwvOhOeqXGurqrW3MUcw2cCNB3HjJPe7AX1XefDzo45D31ibcNJ3fBXSS_jhEehKHLM0ULC8_xR8Z0Q7j7XvBVrDoHXzHCA8bjfJWJP6UdHxJ1FMKdh7_x3pUbzRJxrN2dkXPPhh2NKF59fpu8mfFrKsx-s2I5GItGoUozpH3AKxIh3oe81SxIiBNmZZe_vWVhkfUtS69WlHIrTBQegvB8wct6Np9ysWOEuK42txHRcq826IeAh6WIuBY6n-CNN-jBlG3XYGJLrpp3lxImWhcCQAZgqCYJ-A-OMATD_wxhapD_2R_gXlU9QynY-QuS5QdczDAW3WlMKBjZqGtawhLYYcWvP9-W7dlP0_DuSAFIRWBwGLlcSlgkkapFEfJ3txeIpZHj-G4XHIJACD7MydjF_Zb6jBbttEcZhPTqMOQhEHe0Bo3TX-PdtMenxdJePAMGuiJwcnEiHxyrKt7CL0VWwYaDcZGFTDB1RfHZbvQ3zmqMccHvHjxngZSX2HZYcvEjDPc-_NDwS8kMTlLOhvhLZZEtLcpN_ve09_EGYtW82hMhfV-AAzxA2XDIInP3FM5vcltDY7kNVkOyL3fnLdMRaov24ZgxmhPV6gTDdCtjTfg",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJoSs-VBaddNVE79WDETRoMYQULABQQEQGPt_bgKmLuDGdtAIIFbOlRAb_sH2uoe1LiH7u44oNGOBEGFk5HZIIv_IbiNzSqbKE7cb_R-DYcUnfbEeLloAQnjwUn2P4Mn11dPmYnAqW90l83t5iHezDZsscBOrZOubtcrhZaSOILhxg7TU54bJ8KX0y5D8sp0kzcOy5UcnAYXNfR28yGQ001--ico7nHECm1I1fsOjQxsep3nNsZIbXuKqjmTbuEbTvhb5uwYDjA7cRiS_ZyTMWx-MF79fwEBN0VGOjyxBZhNM4-w4XllCy6pDLIpSQaTr7KVpe0vmXNsjXH8NIdsTy6iXVx4vqgBNAf1JqA_7prugGJmjIxcqkb4whF7C_Gi_Kr19aWb9f6UZ0H99-PSNwvOhOeqXGurqrW3MUcw2cCNB3HjJPe7AX1XefDzo45D31ibcNJ3fBXSS_jhEehKHLM0ULC8_xR8Z0Q7j7XvBVrDoHXzHCA8bjfJWJP6UdHxJ1FMKdh7_x3pUbzRJxrN2dkXPPhh2NKF59fpu8mfFrKsx-s2I5GItGoUozpH3AKxIh3oe81SxIiBNmZZe_vWVhkfUtS69WlHIrTBQegvB8wct6Np9ysWOEuK42txHRcq826IeAh6WIuBY6n-CNN-jBlG3XYGJLrpp3lxImWhcCQAZgqCYJ-A-OMATD_wxhapD_2R_gXlU9QynY-QuS5QdczDAW3WlMKBjZqGtawhLYYcWvP9-W7dlP0_DuSAFIRWBwGLlcSlgkkapFEfJ3txeIpZHj-G4XHIJACD7MydjF_Zb6jBbttEcZhPTqMOQhEHe0Bo3TX-PdtMenxdJePAMGuiJwcnEiHxyrKt7CL0VWwYaDcZGFTDB1RfHZbvQ3zmqMccHvHjxngZSX2HZYcvEjDPc-_NDwS8kMTlLOhvhLZZEtLcpN_ve09_EGYtW82hMhfV-AAzxA2XDIInP3FM5vcltDY7kNVkOyL3fnLdMRaov24ZgxmhPV6gTDdCtjTfg",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJoSs-VBaddNVE79WDETRoMYQULABQQEQGPt_bgKmLuDGdtAIIFbOlRAb_sH2uoe1LiH7u44oNGOBEGFk5HZIIv_IbiNzSqbKE7cb_R-DYcUnfbEeLloAQnjwUn2P4Mn11dPmYnAqW90l83t5iHezDZsscBOrZOubtcrhZaSOILhxg7TU54bJ8KX0y5D8sp0kzcOy5UcnAYXNfR28yGQ001--ico7nHECm1I1fsOjQxsep3nNsZIbXuKqjmTbuEbTvhb5uwYDjA7cRiS_ZyTMWx-MF79fwEBN0VGOjyxBZhNM4-w4XllCy6pDLIpSQaTr7KVpe0vmXNsjXH8NIdsTy6iXVx4vqgBNAf1JqA_7prugGJmjIxcqkb4whF7C_Gi_Kr19aWb9f6UZ0H99-PSNwvOhOeqXGurqrW3MUcw2cCNB3HjJPe7AX1XefDzo45D31ibcNJ3fBXSS_jhEehKHLM0ULC8_xR8Z0Q7j7XvBVrDoHXzHCA8bjfJWJP6UdHxJ1FMKdh7_x3pUbzRJxrN2dkXPPhh2NKF59fpu8mfFrKsx-s2I5GItGoUozpH3AKxIh3oe81SxIiBNmZZe_vWVhkfUtS69WlHIrTBQegvB8wct6Np9ysWOEuK42txHRcq826IeAh6WIuBY6n-CNN-jBlG3XYGJLrpp3lxImWhcCQAZgqCYJ-A-OMATD_wxhapD_2R_gXlU9QynY-QuS5QdczDAW3WlMKBjZqGtawhLYYcWvP9-W7dlP0_DuSAFIRWBwGLlcSlgkkapFEfJ3txeIpZHj-G4XHIJACD7MydjF_Zb6jBbttEcZhPTqMOQhEHe0Bo3TX-PdtMenxdJePAMGuiJwcnEiHxyrKt7CL0VWwYaDcZGFTDB1RfHZbvQ3zmqMccHvHjxngZSX2HZYcvEjDPc-_NDwS8kMTlLOhvhLZZEtLcpN_ve09_EGYtW82hMhfV-AAzxA2XDIInP3FM5vcltDY7kNVkOyL3fnLdMRaov24ZgxmhPV6gTDdCtjTfg",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1399.32,
            "totalPrice": 1196
          },
          {
            "allPassengerTotalPrice": 1200,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1uGTRTWJX-h-xtguwxr_cmKHUFk8krjSIk7LZau9WZMVHFuvi36_rXr6sITpL_xSu3igmxHcvb_CGTxMYZrKWo5q8nJGhU118_8yCjv5eSUwuQVzgS6uGPtByP1OesUJbVGpdDOBGuieUeZ8k3yoooErDGUQ1nS2zZnBMPk5HcntJ-BKv7-Pg6ePEtcMFY1roSEcK_KQJzwDEzVUVRA2HEM-e6mbOxNF599uNDdgg7JpKacMBlgu0s69It8px4P1-jMllC0iHN_lw7vob9EP8O0k3NE7QLB8Lk34T_MWPKba7EQPrbOoT2iSakIgu7ToffQz_YduV5Y6nU2dPJoq6VXiEjnAiLHeuSqx882uc4UDOpq_lR6tZL9cnd2fkQGjiEQ03eWVz1Mpgr9xSWhQaCx_4IX8BSWOtjsfWavG4NLVw-ACw_Tl_WeC4Xyx_fuAHvmPJ0CEIlQSRsXkebiitGdPpFeeX-uC2EIUbH52l3IAViyJWlS0eaD9Or0GhJS9np8ahoLKPd04TTSEiYAsv7aOLo3GIHj8wVkPIwjV9eeWQzlL2GkUtbvIDg7BQVD5jtqUrqFRTor1J8T2qLkFW3epf4BUtQwlLbrFIeGzQkWdUNTkweYmhvj3RNIfQYFuWf0FUz96YcIfWtR6OONZ6Se5WA8sXBza_UwmYldhO7faF0FddF8cACyHBJBI2Nge8AJym_DO25j_jfCdBiadCA8TmNO1OcG_EJZTvwHtI9O5r3Y74KHWqhGGMg4-PDNXuUVp1DOCJIo0mbMya4-gNc64wQzkwmQWywK3tcDZpX17SWlngoZFXDnYxOTW13C20P8gKO5-TEEgd_oAlcro_EyTtQ-FdVqgdWLOB-SAEoJ0Khy89yer0Sv-1a5UIGOOC9eF4W4bFyP8TMNtSe1e8ZuHZlmh3OTMM8iQsspfAsh6jCeye7iiYh3h1aiiFrY49efIeIifqgpz0gi-FkKfVyPCQ_8BjO641ZCXuM0MH7IjG5tu80FSGuuHT2pDa_WdIYNaN-56qgH6u9Gs6gv4PvfejrTSXs3ip0IQD3UiEVk=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1uGTRTWJX-h-xtguwxr_cmKHUFk8krjSIk7LZau9WZMVHFuvi36_rXr6sITpL_xSu3igmxHcvb_CGTxMYZrKWo5q8nJGhU118_8yCjv5eSUwuQVzgS6uGPtByP1OesUJbVGpdDOBGuieUeZ8k3yoooErDGUQ1nS2zZnBMPk5HcntJ-BKv7-Pg6ePEtcMFY1roSEcK_KQJzwDEzVUVRA2HEM-e6mbOxNF599uNDdgg7JpKacMBlgu0s69It8px4P1-jMllC0iHN_lw7vob9EP8O0k3NE7QLB8Lk34T_MWPKba7EQPrbOoT2iSakIgu7ToffQz_YduV5Y6nU2dPJoq6VXiEjnAiLHeuSqx882uc4UDOpq_lR6tZL9cnd2fkQGjiEQ03eWVz1Mpgr9xSWhQaCx_4IX8BSWOtjsfWavG4NLVw-ACw_Tl_WeC4Xyx_fuAHvmPJ0CEIlQSRsXkebiitGdPpFeeX-uC2EIUbH52l3IAViyJWlS0eaD9Or0GhJS9np8ahoLKPd04TTSEiYAsv7aOLo3GIHj8wVkPIwjV9eeWQzlL2GkUtbvIDg7BQVD5jtqUrqFRTor1J8T2qLkFW3epf4BUtQwlLbrFIeGzQkWdUNTkweYmhvj3RNIfQYFuWf0FUz96YcIfWtR6OONZ6Se5WA8sXBza_UwmYldhO7faF0FddF8cACyHBJBI2Nge8AJym_DO25j_jfCdBiadCA8TmNO1OcG_EJZTvwHtI9O5r3Y74KHWqhGGMg4-PDNXuUVp1DOCJIo0mbMya4-gNc64wQzkwmQWywK3tcDZpX17SWlngoZFXDnYxOTW13C20P8gKO5-TEEgd_oAlcro_EyTtQ-FdVqgdWLOB-SAEoJ0Khy89yer0Sv-1a5UIGOOC9eF4W4bFyP8TMNtSe1e8ZuHZlmh3OTMM8iQsspfAsh6jCeye7iiYh3h1aiiFrY49efIeIifqgpz0gi-FkKfVyPCQ_8BjO641ZCXuM0MH7IjG5tu80FSGuuHT2pDa_WdIYNaN-56qgH6u9Gs6gv4PvfejrTSXs3ip0IQD3UiEVk=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1uGTRTWJX-h-xtguwxr_cmKHUFk8krjSIk7LZau9WZMVHFuvi36_rXr6sITpL_xSu3igmxHcvb_CGTxMYZrKWo5q8nJGhU118_8yCjv5eSUwuQVzgS6uGPtByP1OesUJbVGpdDOBGuieUeZ8k3yoooErDGUQ1nS2zZnBMPk5HcntJ-BKv7-Pg6ePEtcMFY1roSEcK_KQJzwDEzVUVRA2HEM-e6mbOxNF599uNDdgg7JpKacMBlgu0s69It8px4P1-jMllC0iHN_lw7vob9EP8O0k3NE7QLB8Lk34T_MWPKba7EQPrbOoT2iSakIgu7ToffQz_YduV5Y6nU2dPJoq6VXiEjnAiLHeuSqx882uc4UDOpq_lR6tZL9cnd2fkQGjiEQ03eWVz1Mpgr9xSWhQaCx_4IX8BSWOtjsfWavG4NLVw-ACw_Tl_WeC4Xyx_fuAHvmPJ0CEIlQSRsXkebiitGdPpFeeX-uC2EIUbH52l3IAViyJWlS0eaD9Or0GhJS9np8ahoLKPd04TTSEiYAsv7aOLo3GIHj8wVkPIwjV9eeWQzlL2GkUtbvIDg7BQVD5jtqUrqFRTor1J8T2qLkFW3epf4BUtQwlLbrFIeGzQkWdUNTkweYmhvj3RNIfQYFuWf0FUz96YcIfWtR6OONZ6Se5WA8sXBza_UwmYldhO7faF0FddF8cACyHBJBI2Nge8AJym_DO25j_jfCdBiadCA8TmNO1OcG_EJZTvwHtI9O5r3Y74KHWqhGGMg4-PDNXuUVp1DOCJIo0mbMya4-gNc64wQzkwmQWywK3tcDZpX17SWlngoZFXDnYxOTW13C20P8gKO5-TEEgd_oAlcro_EyTtQ-FdVqgdWLOB-SAEoJ0Khy89yer0Sv-1a5UIGOOC9eF4W4bFyP8TMNtSe1e8ZuHZlmh3OTMM8iQsspfAsh6jCeye7iiYh3h1aiiFrY49efIeIifqgpz0gi-FkKfVyPCQ_8BjO641ZCXuM0MH7IjG5tu80FSGuuHT2pDa_WdIYNaN-56qgH6u9Gs6gv4PvfejrTSXs3ip0IQD3UiEVk=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1401.1,
            "totalPrice": 1200
          },
          {
            "allPassengerTotalPrice": 1200,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaPf6V4-sXNpQ6a9BezdghUs9aT1hHEwWeh1kGGLXliOktXE7VdR4z_QwCw7KBjmdMxEZd-MmY067nGaJ67MGH5fEAVyP7mAUHYJpn1bUj3uBniG6Qwq9WcwEfWBh0oExM5Ms5DcXB51pE5pngP8289xRBadvLWEZdpnI1WmwsqUCszXd1AVO2cUajfFW8ADl-ItO1X8plgxgvUJ7OiUjewABEr1QgkrzV8g6ZXPisWWnAJh27YyYdVj0X7CsCvBeQpIrB1DPbpB_QYPZbgJnc-pGyRHnwbZFJzf56Rg7RKs8m4_fc-Quh4eqbeg6pXvDe5GeLM5TP2g2oXH1MsRq2poZBYu7FXnSQvGsCVWRz2hWrphgUdOxyWHzIqBWr1TxbrvVdzIYdOwkuKT2ORzOnG__j_ScEOElE7Lk1gZOhUYP0zkKqaBMlPaTiwtWP4lvfrTkwb4q9CwC1ZXQpkxpa70kRFUdmS3xdIFx3qszLxYUaZ0c5hZdJDdVwNHD6LFzny5EYMuYq-ziWBOa4OkmXzW1NPM538RkOSDSiih2yzk0C4EHy0LM0SATVZ61HFBGVPrXkv3dGL-93fyMlPALDycNyEx9_v5lBGRO7P1HiUseimx74J2KxAQNBigOBirrJ4b-sDry3hA7dmasbsQ0i74XG9pfbAdud5jcQy4jnwZdj2YbnAW68jgOSUjIIuyHM_pYO8G6lOetWPOp5tZCtuG5DluN585Jk7UAQrUu2zyYbmYQujGaZ2IRvA496IADIaCbhqSnsMb_LnRMjUZzpCuI4If2p2fLRhntw5rqnDS-y74wMHaqnYPOkXmLSfOyuA443996uYqOuMLpPxP0n3iBDsO-wRz3-SrjR-4gMjLzk7ZWh_5FNrew-xE7MDo51fTyd3dzEwFl-ks4UcqOMEsZWiz5FMV-jKpe173asm4LHCaD2zTRoXiBx0qGrMkscn2389gK-evESHJCrJKfARIDavtsTx70CZZoQ7Muk2sEblCCknbGf5v3ynUmkySrRnEDWfiLJBb9nB-vkXMs-A==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaPf6V4-sXNpQ6a9BezdghUs9aT1hHEwWeh1kGGLXliOktXE7VdR4z_QwCw7KBjmdMxEZd-MmY067nGaJ67MGH5fEAVyP7mAUHYJpn1bUj3uBniG6Qwq9WcwEfWBh0oExM5Ms5DcXB51pE5pngP8289xRBadvLWEZdpnI1WmwsqUCszXd1AVO2cUajfFW8ADl-ItO1X8plgxgvUJ7OiUjewABEr1QgkrzV8g6ZXPisWWnAJh27YyYdVj0X7CsCvBeQpIrB1DPbpB_QYPZbgJnc-pGyRHnwbZFJzf56Rg7RKs8m4_fc-Quh4eqbeg6pXvDe5GeLM5TP2g2oXH1MsRq2poZBYu7FXnSQvGsCVWRz2hWrphgUdOxyWHzIqBWr1TxbrvVdzIYdOwkuKT2ORzOnG__j_ScEOElE7Lk1gZOhUYP0zkKqaBMlPaTiwtWP4lvfrTkwb4q9CwC1ZXQpkxpa70kRFUdmS3xdIFx3qszLxYUaZ0c5hZdJDdVwNHD6LFzny5EYMuYq-ziWBOa4OkmXzW1NPM538RkOSDSiih2yzk0C4EHy0LM0SATVZ61HFBGVPrXkv3dGL-93fyMlPALDycNyEx9_v5lBGRO7P1HiUseimx74J2KxAQNBigOBirrJ4b-sDry3hA7dmasbsQ0i74XG9pfbAdud5jcQy4jnwZdj2YbnAW68jgOSUjIIuyHM_pYO8G6lOetWPOp5tZCtuG5DluN585Jk7UAQrUu2zyYbmYQujGaZ2IRvA496IADIaCbhqSnsMb_LnRMjUZzpCuI4If2p2fLRhntw5rqnDS-y74wMHaqnYPOkXmLSfOyuA443996uYqOuMLpPxP0n3iBDsO-wRz3-SrjR-4gMjLzk7ZWh_5FNrew-xE7MDo51fTyd3dzEwFl-ks4UcqOMEsZWiz5FMV-jKpe173asm4LHCaD2zTRoXiBx0qGrMkscn2389gK-evESHJCrJKfARIDavtsTx70CZZoQ7Muk2sEblCCknbGf5v3ynUmkySrRnEDWfiLJBb9nB-vkXMs-A==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaPf6V4-sXNpQ6a9BezdghUs9aT1hHEwWeh1kGGLXliOktXE7VdR4z_QwCw7KBjmdMxEZd-MmY067nGaJ67MGH5fEAVyP7mAUHYJpn1bUj3uBniG6Qwq9WcwEfWBh0oExM5Ms5DcXB51pE5pngP8289xRBadvLWEZdpnI1WmwsqUCszXd1AVO2cUajfFW8ADl-ItO1X8plgxgvUJ7OiUjewABEr1QgkrzV8g6ZXPisWWnAJh27YyYdVj0X7CsCvBeQpIrB1DPbpB_QYPZbgJnc-pGyRHnwbZFJzf56Rg7RKs8m4_fc-Quh4eqbeg6pXvDe5GeLM5TP2g2oXH1MsRq2poZBYu7FXnSQvGsCVWRz2hWrphgUdOxyWHzIqBWr1TxbrvVdzIYdOwkuKT2ORzOnG__j_ScEOElE7Lk1gZOhUYP0zkKqaBMlPaTiwtWP4lvfrTkwb4q9CwC1ZXQpkxpa70kRFUdmS3xdIFx3qszLxYUaZ0c5hZdJDdVwNHD6LFzny5EYMuYq-ziWBOa4OkmXzW1NPM538RkOSDSiih2yzk0C4EHy0LM0SATVZ61HFBGVPrXkv3dGL-93fyMlPALDycNyEx9_v5lBGRO7P1HiUseimx74J2KxAQNBigOBirrJ4b-sDry3hA7dmasbsQ0i74XG9pfbAdud5jcQy4jnwZdj2YbnAW68jgOSUjIIuyHM_pYO8G6lOetWPOp5tZCtuG5DluN585Jk7UAQrUu2zyYbmYQujGaZ2IRvA496IADIaCbhqSnsMb_LnRMjUZzpCuI4If2p2fLRhntw5rqnDS-y74wMHaqnYPOkXmLSfOyuA443996uYqOuMLpPxP0n3iBDsO-wRz3-SrjR-4gMjLzk7ZWh_5FNrew-xE7MDo51fTyd3dzEwFl-ks4UcqOMEsZWiz5FMV-jKpe173asm4LHCaD2zTRoXiBx0qGrMkscn2389gK-evESHJCrJKfARIDavtsTx70CZZoQ7Muk2sEblCCknbGf5v3ynUmkySrRnEDWfiLJBb9nB-vkXMs-A==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1401.76,
            "totalPrice": 1200
          },
          {
            "allPassengerTotalPrice": 1207,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkJS1UVKW5xCWdYjiG9TVS1f0LonHHuiMVCg57mEyiXXgWOSfiz7d3ThrOyX4WM8AM1jFEF0DaJYqRgn08OafUKwpxHesaqROidI_gzYxLzXKpz5t6kB7fKY4AmaVxvb-rU9RWSmZ-RW838ZOlEA1eiiyoKRByfM-Rt2ckZ_JgGtNwU-e3BAvsr_RT9BLyhc61TN-35B4Ejg4xzCPNyA1ilBxtoEKGtMvEC8yf4LEpwkGDGhcRVw77kHWT5Dx5ELa172lfRgHHwh-tGeL_JwcoeP4VXcoMYQ0dl-XwRVL0Eq4iomcwhWPKz82wXZbIfa02oC9cwlg_Pb5ip1WKk4Eo4oHAu29GXsGP771bB07FNhQz-4MyAc9i8a1LAHoevaqdwCIZO9ZAAFw-Cu4N003CL38Mkxn4OBogfCIkrA3wJNCekbtjRwWl3Pqwip-djwyyObaYEX3Ueym1flw50LPKkNIdPLKCg362l67CaQKtD3yy3AG5szM1lGhE7XNmlxfAI1pvMUAnac5Op5ogn6PgB-HkdGT5Awx_5EjYraI3TCuI3c1OtbC57BmbP-RqIHhO0VFLTCvL46QE_vKC31sOzXObX9UksNfv31iL_OV5OGiiESonoCixizXDiWpV8lu02O5nQ_qCfDA4RILySS9HRT_ikK-EF-Fn-2XpKyPhexjD7TDK5kE9Ny7yQKrkuqBlZ0nf-HwCl58Xr6ynBbKU2u2cXwzoUbdqc0EFrVvYS-SROYSJ7Zg5bnS012AZfLbInMX2SaiGFHJtzzE9xGGrImR5MdvHbZ8OSEnUvB6Ha15WfnQ44s8CffUjE4Udcy38bl4O4GoOzujQoOyIhnjdB-Pu39gzQQfvSodDFqecGuhRP_txWhUqMio5DGQ852puRKbljN1cpkcfCkZzdAYvRcSitH0xjVp-Xk8Y78etiTw1C55ES33jhgPyrkoqLsjNtZAAyZPHqxZbm-3xz5CZ0iuWzkn3HrZFQRLwkBSZZbdURQItdZOzAUp4hGanu5P",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkJS1UVKW5xCWdYjiG9TVS1f0LonHHuiMVCg57mEyiXXgWOSfiz7d3ThrOyX4WM8AM1jFEF0DaJYqRgn08OafUKwpxHesaqROidI_gzYxLzXKpz5t6kB7fKY4AmaVxvb-rU9RWSmZ-RW838ZOlEA1eiiyoKRByfM-Rt2ckZ_JgGtNwU-e3BAvsr_RT9BLyhc61TN-35B4Ejg4xzCPNyA1ilBxtoEKGtMvEC8yf4LEpwkGDGhcRVw77kHWT5Dx5ELa172lfRgHHwh-tGeL_JwcoeP4VXcoMYQ0dl-XwRVL0Eq4iomcwhWPKz82wXZbIfa02oC9cwlg_Pb5ip1WKk4Eo4oHAu29GXsGP771bB07FNhQz-4MyAc9i8a1LAHoevaqdwCIZO9ZAAFw-Cu4N003CL38Mkxn4OBogfCIkrA3wJNCekbtjRwWl3Pqwip-djwyyObaYEX3Ueym1flw50LPKkNIdPLKCg362l67CaQKtD3yy3AG5szM1lGhE7XNmlxfAI1pvMUAnac5Op5ogn6PgB-HkdGT5Awx_5EjYraI3TCuI3c1OtbC57BmbP-RqIHhO0VFLTCvL46QE_vKC31sOzXObX9UksNfv31iL_OV5OGiiESonoCixizXDiWpV8lu02O5nQ_qCfDA4RILySS9HRT_ikK-EF-Fn-2XpKyPhexjD7TDK5kE9Ny7yQKrkuqBlZ0nf-HwCl58Xr6ynBbKU2u2cXwzoUbdqc0EFrVvYS-SROYSJ7Zg5bnS012AZfLbInMX2SaiGFHJtzzE9xGGrImR5MdvHbZ8OSEnUvB6Ha15WfnQ44s8CffUjE4Udcy38bl4O4GoOzujQoOyIhnjdB-Pu39gzQQfvSodDFqecGuhRP_txWhUqMio5DGQ852puRKbljN1cpkcfCkZzdAYvRcSitH0xjVp-Xk8Y78etiTw1C55ES33jhgPyrkoqLsjNtZAAyZPHqxZbm-3xz5CZ0iuWzkn3HrZFQRLwkBSZZbdURQItdZOzAUp4hGanu5P",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkJS1UVKW5xCWdYjiG9TVS1f0LonHHuiMVCg57mEyiXXgWOSfiz7d3ThrOyX4WM8AM1jFEF0DaJYqRgn08OafUKwpxHesaqROidI_gzYxLzXKpz5t6kB7fKY4AmaVxvb-rU9RWSmZ-RW838ZOlEA1eiiyoKRByfM-Rt2ckZ_JgGtNwU-e3BAvsr_RT9BLyhc61TN-35B4Ejg4xzCPNyA1ilBxtoEKGtMvEC8yf4LEpwkGDGhcRVw77kHWT5Dx5ELa172lfRgHHwh-tGeL_JwcoeP4VXcoMYQ0dl-XwRVL0Eq4iomcwhWPKz82wXZbIfa02oC9cwlg_Pb5ip1WKk4Eo4oHAu29GXsGP771bB07FNhQz-4MyAc9i8a1LAHoevaqdwCIZO9ZAAFw-Cu4N003CL38Mkxn4OBogfCIkrA3wJNCekbtjRwWl3Pqwip-djwyyObaYEX3Ueym1flw50LPKkNIdPLKCg362l67CaQKtD3yy3AG5szM1lGhE7XNmlxfAI1pvMUAnac5Op5ogn6PgB-HkdGT5Awx_5EjYraI3TCuI3c1OtbC57BmbP-RqIHhO0VFLTCvL46QE_vKC31sOzXObX9UksNfv31iL_OV5OGiiESonoCixizXDiWpV8lu02O5nQ_qCfDA4RILySS9HRT_ikK-EF-Fn-2XpKyPhexjD7TDK5kE9Ny7yQKrkuqBlZ0nf-HwCl58Xr6ynBbKU2u2cXwzoUbdqc0EFrVvYS-SROYSJ7Zg5bnS012AZfLbInMX2SaiGFHJtzzE9xGGrImR5MdvHbZ8OSEnUvB6Ha15WfnQ44s8CffUjE4Udcy38bl4O4GoOzujQoOyIhnjdB-Pu39gzQQfvSodDFqecGuhRP_txWhUqMio5DGQ852puRKbljN1cpkcfCkZzdAYvRcSitH0xjVp-Xk8Y78etiTw1C55ES33jhgPyrkoqLsjNtZAAyZPHqxZbm-3xz5CZ0iuWzkn3HrZFQRLwkBSZZbdURQItdZOzAUp4hGanu5P",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1407.45,
            "totalPrice": 1207
          },
          {
            "allPassengerTotalPrice": 1208,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjP4rGn9t6SfVvFJG9tbGUXBnfyC1v59zeI1AatFJ3zsN_rAS2UI0NFlSQHQyQ2D0ZtqnLV7qsvUSCka_wZrIVOXoJ_IuAZhEyrth4biTcCnbXiaXlhqSSP0RNSsaiLdySu53kWD0LCEuFed0KaDVbLxZZJwN88u6NanovLkmgzqXIE_y6QO3uo2RhvnICPcuI2FxxUC6rEJKkDaIEAcjiHqbT6Jy2rKVuKCCypyOoZHSXAMVGp7BrcyWN79ThQjb4Zba-MAOAU_uxYXwF9E4h9uasK3m73STWFb7-6m8NIdqFlTplW25z5hKxf5w5PKS7b6y6l_g_dZmn8EP0WUMW-VaGjjpZxvA89IWJzh_T-pMVAtNrRe4hj7JbWaImaLQ_k8zXJCaxFwHpgPY4NnOSYK2clFDPgmOvk-XWafKXcN6fO7rE13_jlK2Zbse4cE_S6eqA8Pjhbhyvzy2KA-WHme-F6E3E2YBnJZlOZK1HdLa575eSTX1nCSTXrS0TTgP_L_k4nbQpwzj3VGwfPiJzj9zvYsJyqQ39mUd1Flaa6CUu7Amk_V9tG-KDHSNuRK21UrHSqgTM9OiNEQ-EbLedykRUXm1bOkfz8h5ejelmhsQp33NsbxkfUTN9HCZIywxPxfCQ9-6X30SRR2kAYguOLg3roRX1TkyI0wiEcMVqrlwRGq6sVu9f5li4h8iEskLgDv6T64M93oSoazyCLqsEz_hQhGr18tG5Kl-32o-f0HXlmSdzuHDsneuR4kl74YjILY9s-7Y-tceQPqw7zYIzazPMAtTOzL1TFND8fph9X3S344hhGMiSZWhlpfISNsHLJ2t3P70P8A_PKdG9HPp_szzQJDMd_FJmsTURzHETjMOpaXZEOGJ10tR4vHDntCqxyDcx2-ZyNIcYtiwI64zhoXthiKaY4vLT5fWA1_XSXlQQkyixHqRE92xncYqdUfpxxISl5R9XqGSdtXYRXV6ziwJtCltIDIbyJyv1QHjwV7PQcQQa_jK5nu0Z4z_PUVK4PhkFdMSWDtynlZNEaZQcxaXBQK6UFlL--lgg2aCXG0=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjP4rGn9t6SfVvFJG9tbGUXBnfyC1v59zeI1AatFJ3zsN_rAS2UI0NFlSQHQyQ2D0ZtqnLV7qsvUSCka_wZrIVOXoJ_IuAZhEyrth4biTcCnbXiaXlhqSSP0RNSsaiLdySu53kWD0LCEuFed0KaDVbLxZZJwN88u6NanovLkmgzqXIE_y6QO3uo2RhvnICPcuI2FxxUC6rEJKkDaIEAcjiHqbT6Jy2rKVuKCCypyOoZHSXAMVGp7BrcyWN79ThQjb4Zba-MAOAU_uxYXwF9E4h9uasK3m73STWFb7-6m8NIdqFlTplW25z5hKxf5w5PKS7b6y6l_g_dZmn8EP0WUMW-VaGjjpZxvA89IWJzh_T-pMVAtNrRe4hj7JbWaImaLQ_k8zXJCaxFwHpgPY4NnOSYK2clFDPgmOvk-XWafKXcN6fO7rE13_jlK2Zbse4cE_S6eqA8Pjhbhyvzy2KA-WHme-F6E3E2YBnJZlOZK1HdLa575eSTX1nCSTXrS0TTgP_L_k4nbQpwzj3VGwfPiJzj9zvYsJyqQ39mUd1Flaa6CUu7Amk_V9tG-KDHSNuRK21UrHSqgTM9OiNEQ-EbLedykRUXm1bOkfz8h5ejelmhsQp33NsbxkfUTN9HCZIywxPxfCQ9-6X30SRR2kAYguOLg3roRX1TkyI0wiEcMVqrlwRGq6sVu9f5li4h8iEskLgDv6T64M93oSoazyCLqsEz_hQhGr18tG5Kl-32o-f0HXlmSdzuHDsneuR4kl74YjILY9s-7Y-tceQPqw7zYIzazPMAtTOzL1TFND8fph9X3S344hhGMiSZWhlpfISNsHLJ2t3P70P8A_PKdG9HPp_szzQJDMd_FJmsTURzHETjMOpaXZEOGJ10tR4vHDntCqxyDcx2-ZyNIcYtiwI64zhoXthiKaY4vLT5fWA1_XSXlQQkyixHqRE92xncYqdUfpxxISl5R9XqGSdtXYRXV6ziwJtCltIDIbyJyv1QHjwV7PQcQQa_jK5nu0Z4z_PUVK4PhkFdMSWDtynlZNEaZQcxaXBQK6UFlL--lgg2aCXG0=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjP4rGn9t6SfVvFJG9tbGUXBnfyC1v59zeI1AatFJ3zsN_rAS2UI0NFlSQHQyQ2D0ZtqnLV7qsvUSCka_wZrIVOXoJ_IuAZhEyrth4biTcCnbXiaXlhqSSP0RNSsaiLdySu53kWD0LCEuFed0KaDVbLxZZJwN88u6NanovLkmgzqXIE_y6QO3uo2RhvnICPcuI2FxxUC6rEJKkDaIEAcjiHqbT6Jy2rKVuKCCypyOoZHSXAMVGp7BrcyWN79ThQjb4Zba-MAOAU_uxYXwF9E4h9uasK3m73STWFb7-6m8NIdqFlTplW25z5hKxf5w5PKS7b6y6l_g_dZmn8EP0WUMW-VaGjjpZxvA89IWJzh_T-pMVAtNrRe4hj7JbWaImaLQ_k8zXJCaxFwHpgPY4NnOSYK2clFDPgmOvk-XWafKXcN6fO7rE13_jlK2Zbse4cE_S6eqA8Pjhbhyvzy2KA-WHme-F6E3E2YBnJZlOZK1HdLa575eSTX1nCSTXrS0TTgP_L_k4nbQpwzj3VGwfPiJzj9zvYsJyqQ39mUd1Flaa6CUu7Amk_V9tG-KDHSNuRK21UrHSqgTM9OiNEQ-EbLedykRUXm1bOkfz8h5ejelmhsQp33NsbxkfUTN9HCZIywxPxfCQ9-6X30SRR2kAYguOLg3roRX1TkyI0wiEcMVqrlwRGq6sVu9f5li4h8iEskLgDv6T64M93oSoazyCLqsEz_hQhGr18tG5Kl-32o-f0HXlmSdzuHDsneuR4kl74YjILY9s-7Y-tceQPqw7zYIzazPMAtTOzL1TFND8fph9X3S344hhGMiSZWhlpfISNsHLJ2t3P70P8A_PKdG9HPp_szzQJDMd_FJmsTURzHETjMOpaXZEOGJ10tR4vHDntCqxyDcx2-ZyNIcYtiwI64zhoXthiKaY4vLT5fWA1_XSXlQQkyixHqRE92xncYqdUfpxxISl5R9XqGSdtXYRXV6ziwJtCltIDIbyJyv1QHjwV7PQcQQa_jK5nu0Z4z_PUVK4PhkFdMSWDtynlZNEaZQcxaXBQK6UFlL--lgg2aCXG0=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1408.76,
            "totalPrice": 1208
          },
          {
            "allPassengerTotalPrice": 1223,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GOWh0yu8kwTeICaeQT8T6hrh6zb8EN3miAxzoGBzeEMmsQHhPCX_W313moXyGabpBUdY9R24C7quMgPSjpFT2OOkjDRtepcJrsltVblF1v7oM-bzGYGHrijRlyTS1g9XHByZZKIUD-gjvuFcPyCBglSuo7tDSBfu8BgwDuRuVpOgtYvA_qGKmQkR7pCZDBNvMyFXpUJEpucUtG79Tgdj9KoUS4q9FB1B258u_PJhgnooA-a1cf8FbIV04mT9p9p7hgRbcVW_VQpPrTk88RZ20sSgUWZg4yx7VObvfRjR9qkpVOMnK_jx-ItqBY_0gvYbE6oxBDaIzLKxyH2W4ExDMkeNBFnTFDE3YoIW3vd-GVW5tzlL_jDk3oRYtVnz17CxnsMvwBbzL6IiPJktvhha1PRGz3AgeTFr2aXqVAcGiCxA35X0PMjsWBcJRJ8C1YGVV0BP7128clFYP3YJGGnVPgirPZRJf2Gwh7V5xeq7h-VKkRdVwRncfCvZUhLxUsIDFvrywG3jf77JiaGpvK16II2C-xssI5y6TMM5J5EdeduPFIrGsHFBRv5AsLYWZBO9Jm87S_3b1ogUch4n4ZUDFIyXi5jKA5RcdjwP7dJqtLj6hB0QeKAt5ezozZsbx9kR656LOYUENKfFKKu3zi8kCfh__qy5R-JspdIjhsjeirWsMW39qazmjKqg1DJIIEpt7ad5t1XwCPlj00gbGmZ1g8FTpcn0nBd-yvM5bE5xc7UNjNHnUZclTIBRoK6gVUWqIM_BMMhuXpJpUgsMaEX9Cbvmg4Km0NEunSHhZlH7YMUze7eCjwT1KtWTylb0euEJjWgtU5VMpMOxDVLLavvJeSJ9sJ_M23rWb43gMKDCYacbO2gy7vHMNB_73NJi6PM_cvZyUZThmrmCfiSvUjXpPShCSdZoEOAODfFEgb0OPC2wYnrcPTZiZruRDDUP7CIBDqhSDGoku412Vmwaf7xEKYxdaVlLvnfez4UNJgPmWQvb7Ia49odf-B4lAI42IkWJKCt7206DLy1c3MC1vhqeBkg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GOWh0yu8kwTeICaeQT8T6hrh6zb8EN3miAxzoGBzeEMmsQHhPCX_W313moXyGabpBUdY9R24C7quMgPSjpFT2OOkjDRtepcJrsltVblF1v7oM-bzGYGHrijRlyTS1g9XHByZZKIUD-gjvuFcPyCBglSuo7tDSBfu8BgwDuRuVpOgtYvA_qGKmQkR7pCZDBNvMyFXpUJEpucUtG79Tgdj9KoUS4q9FB1B258u_PJhgnooA-a1cf8FbIV04mT9p9p7hgRbcVW_VQpPrTk88RZ20sSgUWZg4yx7VObvfRjR9qkpVOMnK_jx-ItqBY_0gvYbE6oxBDaIzLKxyH2W4ExDMkeNBFnTFDE3YoIW3vd-GVW5tzlL_jDk3oRYtVnz17CxnsMvwBbzL6IiPJktvhha1PRGz3AgeTFr2aXqVAcGiCxA35X0PMjsWBcJRJ8C1YGVV0BP7128clFYP3YJGGnVPgirPZRJf2Gwh7V5xeq7h-VKkRdVwRncfCvZUhLxUsIDFvrywG3jf77JiaGpvK16II2C-xssI5y6TMM5J5EdeduPFIrGsHFBRv5AsLYWZBO9Jm87S_3b1ogUch4n4ZUDFIyXi5jKA5RcdjwP7dJqtLj6hB0QeKAt5ezozZsbx9kR656LOYUENKfFKKu3zi8kCfh__qy5R-JspdIjhsjeirWsMW39qazmjKqg1DJIIEpt7ad5t1XwCPlj00gbGmZ1g8FTpcn0nBd-yvM5bE5xc7UNjNHnUZclTIBRoK6gVUWqIM_BMMhuXpJpUgsMaEX9Cbvmg4Km0NEunSHhZlH7YMUze7eCjwT1KtWTylb0euEJjWgtU5VMpMOxDVLLavvJeSJ9sJ_M23rWb43gMKDCYacbO2gy7vHMNB_73NJi6PM_cvZyUZThmrmCfiSvUjXpPShCSdZoEOAODfFEgb0OPC2wYnrcPTZiZruRDDUP7CIBDqhSDGoku412Vmwaf7xEKYxdaVlLvnfez4UNJgPmWQvb7Ia49odf-B4lAI42IkWJKCt7206DLy1c3MC1vhqeBkg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GOWh0yu8kwTeICaeQT8T6hrh6zb8EN3miAxzoGBzeEMmsQHhPCX_W313moXyGabpBUdY9R24C7quMgPSjpFT2OOkjDRtepcJrsltVblF1v7oM-bzGYGHrijRlyTS1g9XHByZZKIUD-gjvuFcPyCBglSuo7tDSBfu8BgwDuRuVpOgtYvA_qGKmQkR7pCZDBNvMyFXpUJEpucUtG79Tgdj9KoUS4q9FB1B258u_PJhgnooA-a1cf8FbIV04mT9p9p7hgRbcVW_VQpPrTk88RZ20sSgUWZg4yx7VObvfRjR9qkpVOMnK_jx-ItqBY_0gvYbE6oxBDaIzLKxyH2W4ExDMkeNBFnTFDE3YoIW3vd-GVW5tzlL_jDk3oRYtVnz17CxnsMvwBbzL6IiPJktvhha1PRGz3AgeTFr2aXqVAcGiCxA35X0PMjsWBcJRJ8C1YGVV0BP7128clFYP3YJGGnVPgirPZRJf2Gwh7V5xeq7h-VKkRdVwRncfCvZUhLxUsIDFvrywG3jf77JiaGpvK16II2C-xssI5y6TMM5J5EdeduPFIrGsHFBRv5AsLYWZBO9Jm87S_3b1ogUch4n4ZUDFIyXi5jKA5RcdjwP7dJqtLj6hB0QeKAt5ezozZsbx9kR656LOYUENKfFKKu3zi8kCfh__qy5R-JspdIjhsjeirWsMW39qazmjKqg1DJIIEpt7ad5t1XwCPlj00gbGmZ1g8FTpcn0nBd-yvM5bE5xc7UNjNHnUZclTIBRoK6gVUWqIM_BMMhuXpJpUgsMaEX9Cbvmg4Km0NEunSHhZlH7YMUze7eCjwT1KtWTylb0euEJjWgtU5VMpMOxDVLLavvJeSJ9sJ_M23rWb43gMKDCYacbO2gy7vHMNB_73NJi6PM_cvZyUZThmrmCfiSvUjXpPShCSdZoEOAODfFEgb0OPC2wYnrcPTZiZruRDDUP7CIBDqhSDGoku412Vmwaf7xEKYxdaVlLvnfez4UNJgPmWQvb7Ia49odf-B4lAI42IkWJKCt7206DLy1c3MC1vhqeBkg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1421.1,
            "totalPrice": 1223
          },
          {
            "allPassengerTotalPrice": 1223,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_x2CcM1EFiCuF2_d6g99t2YLJAvvgeL8SYbnqyRPcse6cEaT-UdIBF7fGiahgfMUJufSvCXMFmtZcm44N3G2tmgkD5GHae5n-WIcoauTawzcj_WelfvZs6EoSM50RCEQ7--TmOvzuJX1pLRaYtnIimbtQo7j5ph5q6BtQ8cIyjygZxgg6Al_gmwKBzvD6TB8WuHy6cGVE0_8xBYPVUXMpS6mcL-mSjT_TOSvJNgCwXcDsDUwQ7CIQfqCCC8G289Rho30jVhdF5-fEDcT1NXrZl7sPe9ykVB5rYSm7fCDE1rdomLfsdRe87KsTO5rtROh6sMwXGmE49f44MIbCxmS-bEugQAzRTPhIQDxfABJaTMXh9nfqT1KXDOLyODHCP67MT3NhJN1xDQF8lJxM6YATmRWPqOVgZwAkV0NrALlV33Ku__ejw9WsdUjAS7FnL0PwPr67o1yfSrJ5WAGC392QlgRat_xYS3DOyrfZcCxCo2Q1ZvPn1cTVe8TTxOx82CKb5PDK0qsMu1vdyrF-r8lA3UUKXfFOigIYCj6fhwCAcjZbg-3D2AHNNfIpOioRPJAOxdGrd9W8iKJQf9sZu-nCN_UeJknKP0JDVAvK-5RBYXCmZm19Fq3PqaIS3uZm4L4o3bRMlJX2LV1IMs5xiCJlL4Lfb2uLhiA9swza0yWA2JGEr6NFx-NWMnOntVWI_FLeXMtqz2gyN4ZvTTVxxc5xD4j8LIU_bWzQr-YulQ22_hj4Az7l9g7RdgCs1p7uyvxOX5k1tiA-wIfa9DcagSXvWm4_2_1ggvK4P0IhJ9GrUYpB3E_UcPki1oZ5acrv-Mwi1cCskqohO1pX61XzdlT46ULsEgFa3zzV_7-cSlBifu3J6X-DzWho-S6eDo4U-IoPuYD5g1SQ-xscXmKAYhxFDainUziAA5zMESvv-8lVUpzp3OCCCxNhFhmIn-XqzzPdxeQRYuceYnzutp8gdWLUKu9y0w8QEjMQp6BRIOR52s6imCiTkM4IhCgR0C4N2_7fKNJVAQ3sxxMt2_Hq29hf9he644bIcmxde2dF3rniKpfmky1PodJqxWKIJojxAZw",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_x2CcM1EFiCuF2_d6g99t2YLJAvvgeL8SYbnqyRPcse6cEaT-UdIBF7fGiahgfMUJufSvCXMFmtZcm44N3G2tmgkD5GHae5n-WIcoauTawzcj_WelfvZs6EoSM50RCEQ7--TmOvzuJX1pLRaYtnIimbtQo7j5ph5q6BtQ8cIyjygZxgg6Al_gmwKBzvD6TB8WuHy6cGVE0_8xBYPVUXMpS6mcL-mSjT_TOSvJNgCwXcDsDUwQ7CIQfqCCC8G289Rho30jVhdF5-fEDcT1NXrZl7sPe9ykVB5rYSm7fCDE1rdomLfsdRe87KsTO5rtROh6sMwXGmE49f44MIbCxmS-bEugQAzRTPhIQDxfABJaTMXh9nfqT1KXDOLyODHCP67MT3NhJN1xDQF8lJxM6YATmRWPqOVgZwAkV0NrALlV33Ku__ejw9WsdUjAS7FnL0PwPr67o1yfSrJ5WAGC392QlgRat_xYS3DOyrfZcCxCo2Q1ZvPn1cTVe8TTxOx82CKb5PDK0qsMu1vdyrF-r8lA3UUKXfFOigIYCj6fhwCAcjZbg-3D2AHNNfIpOioRPJAOxdGrd9W8iKJQf9sZu-nCN_UeJknKP0JDVAvK-5RBYXCmZm19Fq3PqaIS3uZm4L4o3bRMlJX2LV1IMs5xiCJlL4Lfb2uLhiA9swza0yWA2JGEr6NFx-NWMnOntVWI_FLeXMtqz2gyN4ZvTTVxxc5xD4j8LIU_bWzQr-YulQ22_hj4Az7l9g7RdgCs1p7uyvxOX5k1tiA-wIfa9DcagSXvWm4_2_1ggvK4P0IhJ9GrUYpB3E_UcPki1oZ5acrv-Mwi1cCskqohO1pX61XzdlT46ULsEgFa3zzV_7-cSlBifu3J6X-DzWho-S6eDo4U-IoPuYD5g1SQ-xscXmKAYhxFDainUziAA5zMESvv-8lVUpzp3OCCCxNhFhmIn-XqzzPdxeQRYuceYnzutp8gdWLUKu9y0w8QEjMQp6BRIOR52s6imCiTkM4IhCgR0C4N2_7fKNJVAQ3sxxMt2_Hq29hf9he644bIcmxde2dF3rniKpfmky1PodJqxWKIJojxAZw",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_x2CcM1EFiCuF2_d6g99t2YLJAvvgeL8SYbnqyRPcse6cEaT-UdIBF7fGiahgfMUJufSvCXMFmtZcm44N3G2tmgkD5GHae5n-WIcoauTawzcj_WelfvZs6EoSM50RCEQ7--TmOvzuJX1pLRaYtnIimbtQo7j5ph5q6BtQ8cIyjygZxgg6Al_gmwKBzvD6TB8WuHy6cGVE0_8xBYPVUXMpS6mcL-mSjT_TOSvJNgCwXcDsDUwQ7CIQfqCCC8G289Rho30jVhdF5-fEDcT1NXrZl7sPe9ykVB5rYSm7fCDE1rdomLfsdRe87KsTO5rtROh6sMwXGmE49f44MIbCxmS-bEugQAzRTPhIQDxfABJaTMXh9nfqT1KXDOLyODHCP67MT3NhJN1xDQF8lJxM6YATmRWPqOVgZwAkV0NrALlV33Ku__ejw9WsdUjAS7FnL0PwPr67o1yfSrJ5WAGC392QlgRat_xYS3DOyrfZcCxCo2Q1ZvPn1cTVe8TTxOx82CKb5PDK0qsMu1vdyrF-r8lA3UUKXfFOigIYCj6fhwCAcjZbg-3D2AHNNfIpOioRPJAOxdGrd9W8iKJQf9sZu-nCN_UeJknKP0JDVAvK-5RBYXCmZm19Fq3PqaIS3uZm4L4o3bRMlJX2LV1IMs5xiCJlL4Lfb2uLhiA9swza0yWA2JGEr6NFx-NWMnOntVWI_FLeXMtqz2gyN4ZvTTVxxc5xD4j8LIU_bWzQr-YulQ22_hj4Az7l9g7RdgCs1p7uyvxOX5k1tiA-wIfa9DcagSXvWm4_2_1ggvK4P0IhJ9GrUYpB3E_UcPki1oZ5acrv-Mwi1cCskqohO1pX61XzdlT46ULsEgFa3zzV_7-cSlBifu3J6X-DzWho-S6eDo4U-IoPuYD5g1SQ-xscXmKAYhxFDainUziAA5zMESvv-8lVUpzp3OCCCxNhFhmIn-XqzzPdxeQRYuceYnzutp8gdWLUKu9y0w8QEjMQp6BRIOR52s6imCiTkM4IhCgR0C4N2_7fKNJVAQ3sxxMt2_Hq29hf9he644bIcmxde2dF3rniKpfmky1PodJqxWKIJojxAZw",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1444.46,
            "totalPrice": 1223
          },
          {
            "allPassengerTotalPrice": 1227,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtxC5AE5xawRMjKCKDWTc3fGSUs7CnOiZWKwR1xjDmPPQrYdAMu-n6eJhzas5JwO-hpOTul3bVyA-N_I7r8Tb7BfSNiz2ABUF4AjpTQpeheuZx1PyrFoMcTmaNoIcXTZwXkdburmz3nT5vK32dpbyzSXy2sPcM7nqkp78fYgSN_px8eBzVcfHBKUEEhFa1d86IoJ9-D-h14vJSSsGHa03-_onCXujDZYz4v4u8e_V5wxZRwTVFJ0p2oG5DRXWEEkMVg3UFGQVOU8xwnYulalwF-Nez-9YXACPOaaOzpGRNwAAQYROVpybbkHvRuHduXruOpOaEkEuxSPvnb7qddIvJj3bxg55-EGkfmS6ibODILuPleAz1ZB4cT1TZq542M5wmCaHIZbAc2EuXP6TjqBlnf9zw47yvihqwtV1CfMGG72WKvuHxdnQFR1cGJKv617dijMO1hkf7oxBB6kD_casIrA0eiKiGCQ_N29cu-StvBd5yzf6dJGyQdwVJDXWiGU4i9ZJb9lQ99Vw1Oy2LpT7OvOuE5T2rBwK3ggVfyrOx3L3tDvCn4MOqm0YGXJKasBJiFlyFET3NF2CWr2t6m0UGrc2uuatEUF8TeZZGZkGcHaTReST-AopZmyrISwgDpOfdrsSae6DKMlUov6nghnWf8TZ1y1MUBeXjz3Soi1WuxRZBs1m3PsKTGCYqIY1LH6HAZGLzAa_wGoEpKHubE7mIcN9WiYgtVrNdgl8UzpMg4LCaV6Uq0zc3bQ6X96nMgLB5nSuLN1U84pVfHPnZo1pvAsF3VYScYoT3i8MMVwKOQHdTHxU1JiLEpF0jTWka_-suAa0ZnD--gGgkTpDVM2qI8vHx7nvOzqu8MkZDIZYV1OIe86lxyYBnvC1OxSjKJzdllqEh24bSHSrACUGgtPT09Ghd9589P1uIHtu96L2qfhlnOIFzn_uGirKVLcbtx7-TWfLbH_G9bU198IlL3dY0NoUUcisTMkgEFqlzrSLxsyojio93_Ge_jvZ-1RNwPbX5CKC6c0o310bCse1EX3HCx81BoqwpFjmqCrDXW6FfSVJv1hHcXOuv7rRUGM1hKuntm1sPl4tblLZh1Xnkts5fg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtxC5AE5xawRMjKCKDWTc3fGSUs7CnOiZWKwR1xjDmPPQrYdAMu-n6eJhzas5JwO-hpOTul3bVyA-N_I7r8Tb7BfSNiz2ABUF4AjpTQpeheuZx1PyrFoMcTmaNoIcXTZwXkdburmz3nT5vK32dpbyzSXy2sPcM7nqkp78fYgSN_px8eBzVcfHBKUEEhFa1d86IoJ9-D-h14vJSSsGHa03-_onCXujDZYz4v4u8e_V5wxZRwTVFJ0p2oG5DRXWEEkMVg3UFGQVOU8xwnYulalwF-Nez-9YXACPOaaOzpGRNwAAQYROVpybbkHvRuHduXruOpOaEkEuxSPvnb7qddIvJj3bxg55-EGkfmS6ibODILuPleAz1ZB4cT1TZq542M5wmCaHIZbAc2EuXP6TjqBlnf9zw47yvihqwtV1CfMGG72WKvuHxdnQFR1cGJKv617dijMO1hkf7oxBB6kD_casIrA0eiKiGCQ_N29cu-StvBd5yzf6dJGyQdwVJDXWiGU4i9ZJb9lQ99Vw1Oy2LpT7OvOuE5T2rBwK3ggVfyrOx3L3tDvCn4MOqm0YGXJKasBJiFlyFET3NF2CWr2t6m0UGrc2uuatEUF8TeZZGZkGcHaTReST-AopZmyrISwgDpOfdrsSae6DKMlUov6nghnWf8TZ1y1MUBeXjz3Soi1WuxRZBs1m3PsKTGCYqIY1LH6HAZGLzAa_wGoEpKHubE7mIcN9WiYgtVrNdgl8UzpMg4LCaV6Uq0zc3bQ6X96nMgLB5nSuLN1U84pVfHPnZo1pvAsF3VYScYoT3i8MMVwKOQHdTHxU1JiLEpF0jTWka_-suAa0ZnD--gGgkTpDVM2qI8vHx7nvOzqu8MkZDIZYV1OIe86lxyYBnvC1OxSjKJzdllqEh24bSHSrACUGgtPT09Ghd9589P1uIHtu96L2qfhlnOIFzn_uGirKVLcbtx7-TWfLbH_G9bU198IlL3dY0NoUUcisTMkgEFqlzrSLxsyojio93_Ge_jvZ-1RNwPbX5CKC6c0o310bCse1EX3HCx81BoqwpFjmqCrDXW6FfSVJv1hHcXOuv7rRUGM1hKuntm1sPl4tblLZh1Xnkts5fg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtxC5AE5xawRMjKCKDWTc3fGSUs7CnOiZWKwR1xjDmPPQrYdAMu-n6eJhzas5JwO-hpOTul3bVyA-N_I7r8Tb7BfSNiz2ABUF4AjpTQpeheuZx1PyrFoMcTmaNoIcXTZwXkdburmz3nT5vK32dpbyzSXy2sPcM7nqkp78fYgSN_px8eBzVcfHBKUEEhFa1d86IoJ9-D-h14vJSSsGHa03-_onCXujDZYz4v4u8e_V5wxZRwTVFJ0p2oG5DRXWEEkMVg3UFGQVOU8xwnYulalwF-Nez-9YXACPOaaOzpGRNwAAQYROVpybbkHvRuHduXruOpOaEkEuxSPvnb7qddIvJj3bxg55-EGkfmS6ibODILuPleAz1ZB4cT1TZq542M5wmCaHIZbAc2EuXP6TjqBlnf9zw47yvihqwtV1CfMGG72WKvuHxdnQFR1cGJKv617dijMO1hkf7oxBB6kD_casIrA0eiKiGCQ_N29cu-StvBd5yzf6dJGyQdwVJDXWiGU4i9ZJb9lQ99Vw1Oy2LpT7OvOuE5T2rBwK3ggVfyrOx3L3tDvCn4MOqm0YGXJKasBJiFlyFET3NF2CWr2t6m0UGrc2uuatEUF8TeZZGZkGcHaTReST-AopZmyrISwgDpOfdrsSae6DKMlUov6nghnWf8TZ1y1MUBeXjz3Soi1WuxRZBs1m3PsKTGCYqIY1LH6HAZGLzAa_wGoEpKHubE7mIcN9WiYgtVrNdgl8UzpMg4LCaV6Uq0zc3bQ6X96nMgLB5nSuLN1U84pVfHPnZo1pvAsF3VYScYoT3i8MMVwKOQHdTHxU1JiLEpF0jTWka_-suAa0ZnD--gGgkTpDVM2qI8vHx7nvOzqu8MkZDIZYV1OIe86lxyYBnvC1OxSjKJzdllqEh24bSHSrACUGgtPT09Ghd9589P1uIHtu96L2qfhlnOIFzn_uGirKVLcbtx7-TWfLbH_G9bU198IlL3dY0NoUUcisTMkgEFqlzrSLxsyojio93_Ge_jvZ-1RNwPbX5CKC6c0o310bCse1EX3HCx81BoqwpFjmqCrDXW6FfSVJv1hHcXOuv7rRUGM1hKuntm1sPl4tblLZh1Xnkts5fg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1446.24,
            "totalPrice": 1227
          },
          {
            "allPassengerTotalPrice": 1227,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-DibsIcsv2gzNx2NUgwoHZ2P2E27ZAG647Zirag8-SBM8kOrLQDIv0rVUIGERSTWhICHn8T4kvirq8uJqd3axBIV9KTVJxZFD1O63rdNZQvmEzF06l1xKt6RExNzwAIEJVYyR80kJlSnFUeikdta4EkH5sMTAwH4FP0KRqLIx8i09VA2-WP7F01HcCXDHRwR8FPp06WLDFU1a5R9lEetu0YQLtxGYxWBueJOMhaWHl9SSq1h5nSGtCrkBrqcu4V-ScEC65MSuiFLxOA92VXpDQswVkfV3_NZ5GEv2V5cNTuRKm5JPS57o5InO0Yohnlj4Ryn2ZxEtXVuhNcaXbZEggIiDDuSUFfj6XqJLepENIJI7rrl8gm3UAzbabkD7P6KqL1H02bcGALYioo1miRp7efPWI-jUzEFfxVZiKboHnOGj4VLgpCPX3RzsVk-FU8bQt7eurzJ9LhAdeaG5T5v_vl0OR_2RXiAbDUzohvWU1Gsq29OOJzk8inrtMEMritxJmI7CwyKSyINHLz1FwwYgDZd3nwYvmmbOD-4_PjazBInIaKxpE5xBzvI87BSjxl5ZUx40ylXdxmkpqCCnNIYQhRQ13L33OWJd2eBTTVBF37AjkeZ5yis4XHO0eO17S72bC97zg_wxwY9CWE1syHCimfxnuC8800Vv2vCH3jcwrD9et_yxje80sPi4WgsyjZrx80UvReqp5RagJ7jMZmHpChD5K4tFMgpqB_dL4hSQ6aOHQfyJqVTCBJGxIDtys9p2AvyEWolUHfBMQlAkCQKOSdZ3aEmz4vJByPQBDckVRmgMVuSGsYQP8BVEZzSlwfCH9Zx3VOrPqGczdwUJTsx5JS2fXSY7ImtZvhBVjPeeYahe8A56Jknt6GUcC5iYokg2pMG9uLmRv-gieOF31WoicPNpghFfH3Ny-nMikZQrXaPNJetgoX2ZdPp1WzYk5SxII-eo_4gpakbRJTb4GK4PMaOmLTrr_5_qNkbhTVgdrK03ILSvcIz23JKJiuTcoYvwaMoGdhn_P3hRAw5bg3owvcgX_RHS5SPwiQ_ZEMyt6wjydZprAfFT8SWi6iBWFQh",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-DibsIcsv2gzNx2NUgwoHZ2P2E27ZAG647Zirag8-SBM8kOrLQDIv0rVUIGERSTWhICHn8T4kvirq8uJqd3axBIV9KTVJxZFD1O63rdNZQvmEzF06l1xKt6RExNzwAIEJVYyR80kJlSnFUeikdta4EkH5sMTAwH4FP0KRqLIx8i09VA2-WP7F01HcCXDHRwR8FPp06WLDFU1a5R9lEetu0YQLtxGYxWBueJOMhaWHl9SSq1h5nSGtCrkBrqcu4V-ScEC65MSuiFLxOA92VXpDQswVkfV3_NZ5GEv2V5cNTuRKm5JPS57o5InO0Yohnlj4Ryn2ZxEtXVuhNcaXbZEggIiDDuSUFfj6XqJLepENIJI7rrl8gm3UAzbabkD7P6KqL1H02bcGALYioo1miRp7efPWI-jUzEFfxVZiKboHnOGj4VLgpCPX3RzsVk-FU8bQt7eurzJ9LhAdeaG5T5v_vl0OR_2RXiAbDUzohvWU1Gsq29OOJzk8inrtMEMritxJmI7CwyKSyINHLz1FwwYgDZd3nwYvmmbOD-4_PjazBInIaKxpE5xBzvI87BSjxl5ZUx40ylXdxmkpqCCnNIYQhRQ13L33OWJd2eBTTVBF37AjkeZ5yis4XHO0eO17S72bC97zg_wxwY9CWE1syHCimfxnuC8800Vv2vCH3jcwrD9et_yxje80sPi4WgsyjZrx80UvReqp5RagJ7jMZmHpChD5K4tFMgpqB_dL4hSQ6aOHQfyJqVTCBJGxIDtys9p2AvyEWolUHfBMQlAkCQKOSdZ3aEmz4vJByPQBDckVRmgMVuSGsYQP8BVEZzSlwfCH9Zx3VOrPqGczdwUJTsx5JS2fXSY7ImtZvhBVjPeeYahe8A56Jknt6GUcC5iYokg2pMG9uLmRv-gieOF31WoicPNpghFfH3Ny-nMikZQrXaPNJetgoX2ZdPp1WzYk5SxII-eo_4gpakbRJTb4GK4PMaOmLTrr_5_qNkbhTVgdrK03ILSvcIz23JKJiuTcoYvwaMoGdhn_P3hRAw5bg3owvcgX_RHS5SPwiQ_ZEMyt6wjydZprAfFT8SWi6iBWFQh",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-DibsIcsv2gzNx2NUgwoHZ2P2E27ZAG647Zirag8-SBM8kOrLQDIv0rVUIGERSTWhICHn8T4kvirq8uJqd3axBIV9KTVJxZFD1O63rdNZQvmEzF06l1xKt6RExNzwAIEJVYyR80kJlSnFUeikdta4EkH5sMTAwH4FP0KRqLIx8i09VA2-WP7F01HcCXDHRwR8FPp06WLDFU1a5R9lEetu0YQLtxGYxWBueJOMhaWHl9SSq1h5nSGtCrkBrqcu4V-ScEC65MSuiFLxOA92VXpDQswVkfV3_NZ5GEv2V5cNTuRKm5JPS57o5InO0Yohnlj4Ryn2ZxEtXVuhNcaXbZEggIiDDuSUFfj6XqJLepENIJI7rrl8gm3UAzbabkD7P6KqL1H02bcGALYioo1miRp7efPWI-jUzEFfxVZiKboHnOGj4VLgpCPX3RzsVk-FU8bQt7eurzJ9LhAdeaG5T5v_vl0OR_2RXiAbDUzohvWU1Gsq29OOJzk8inrtMEMritxJmI7CwyKSyINHLz1FwwYgDZd3nwYvmmbOD-4_PjazBInIaKxpE5xBzvI87BSjxl5ZUx40ylXdxmkpqCCnNIYQhRQ13L33OWJd2eBTTVBF37AjkeZ5yis4XHO0eO17S72bC97zg_wxwY9CWE1syHCimfxnuC8800Vv2vCH3jcwrD9et_yxje80sPi4WgsyjZrx80UvReqp5RagJ7jMZmHpChD5K4tFMgpqB_dL4hSQ6aOHQfyJqVTCBJGxIDtys9p2AvyEWolUHfBMQlAkCQKOSdZ3aEmz4vJByPQBDckVRmgMVuSGsYQP8BVEZzSlwfCH9Zx3VOrPqGczdwUJTsx5JS2fXSY7ImtZvhBVjPeeYahe8A56Jknt6GUcC5iYokg2pMG9uLmRv-gieOF31WoicPNpghFfH3Ny-nMikZQrXaPNJetgoX2ZdPp1WzYk5SxII-eo_4gpakbRJTb4GK4PMaOmLTrr_5_qNkbhTVgdrK03ILSvcIz23JKJiuTcoYvwaMoGdhn_P3hRAw5bg3owvcgX_RHS5SPwiQ_ZEMyt6wjydZprAfFT8SWi6iBWFQh",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1446.9,
            "totalPrice": 1227
          },
          {
            "allPassengerTotalPrice": 1232,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GP-RzG9ICPf6MqRwIUUKWF55OGv8LSTmV2Tt6fNQLzld3tLeVxl7yrQU4GA23LPBbfn4sECyQEy8vwJz9SyIyr9xOUZLQhPfqg5wpLu-xF0Y1YjqeyyOCqnVPyKBqTr-0TTUY0kKFN4rIsdExUsS2FeRgq6uDLvGJ4tXd8qaGTqfZxJIQ5apZzJGFxGZNPdF64gXizvzyDiI0ioA-BFtazk8o3q-YJmBUGNlVf0E8yWdpkZeUZp6VNphmQONlzkf1EXqFsTH52VPtlDAymW6DOvPOv5ZWpuq-43LZ5OyRyJ-LFsvmCBffyzlbJdnCV3bvxbIAkpywwbBenMQOoCJU5DzUL7j2Zpk0bCbZNNFrwVIGev6shT_YHTWRm4_Kw1TTDsUY-tS_cdGuv169AdejbBaYwj34MDNCNDmMjNgPBIXAULUNGFoZx-HMfETPOh5qzhpSDWPf7kwVmeYZatPMqh_7fYFXVDBbE0NZjVh4WPumLYJYBxX9C_O0xsK4YVxan-LasNcFl4Y_btPZbIVre7JjZcU0q3kmdCD40-ZIfPWohhzfaBfkS8YQzdV4GLBnKoWEBonWWHm9nthQtlz2xdi3rhnaz2pqHLKNtnL3XhUk8rBF8NfPpWV--3RLMZ6JAtAgPIx_6isTSz3mn0PxiI6OmsHqmnec3jSRTjLWvtR3o2KsbavluQztVYmuDPNJO4dELARgOzaeAu51mlbOp44c7zQoKROk50kP-8aqxrE4NYq64uk3Yuifpfdcmzl2mQcAIo-3yLI6XPfLy0LaGJbYtEyBcZMpy-PZV4LNMFXLRidBIrN6GGhlouGmo9UjsWcG-egzljg4Jkq6DvNuv-29B8QVSzMPsOLK8e7ccK145QC9ISNH5ijmzAZcQJmlqMAfPyHazOY2R3cWLZW7flXrAB0xW8FhvwfBMcbK8_z1-jg_r98h4fkNkPGbbi5IdJK2bpsGJsaf990ZruxevTo8zuTji5r6WkVJKAA9i70N5scR9ihvO2w11ME3rUAB",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GP-RzG9ICPf6MqRwIUUKWF55OGv8LSTmV2Tt6fNQLzld3tLeVxl7yrQU4GA23LPBbfn4sECyQEy8vwJz9SyIyr9xOUZLQhPfqg5wpLu-xF0Y1YjqeyyOCqnVPyKBqTr-0TTUY0kKFN4rIsdExUsS2FeRgq6uDLvGJ4tXd8qaGTqfZxJIQ5apZzJGFxGZNPdF64gXizvzyDiI0ioA-BFtazk8o3q-YJmBUGNlVf0E8yWdpkZeUZp6VNphmQONlzkf1EXqFsTH52VPtlDAymW6DOvPOv5ZWpuq-43LZ5OyRyJ-LFsvmCBffyzlbJdnCV3bvxbIAkpywwbBenMQOoCJU5DzUL7j2Zpk0bCbZNNFrwVIGev6shT_YHTWRm4_Kw1TTDsUY-tS_cdGuv169AdejbBaYwj34MDNCNDmMjNgPBIXAULUNGFoZx-HMfETPOh5qzhpSDWPf7kwVmeYZatPMqh_7fYFXVDBbE0NZjVh4WPumLYJYBxX9C_O0xsK4YVxan-LasNcFl4Y_btPZbIVre7JjZcU0q3kmdCD40-ZIfPWohhzfaBfkS8YQzdV4GLBnKoWEBonWWHm9nthQtlz2xdi3rhnaz2pqHLKNtnL3XhUk8rBF8NfPpWV--3RLMZ6JAtAgPIx_6isTSz3mn0PxiI6OmsHqmnec3jSRTjLWvtR3o2KsbavluQztVYmuDPNJO4dELARgOzaeAu51mlbOp44c7zQoKROk50kP-8aqxrE4NYq64uk3Yuifpfdcmzl2mQcAIo-3yLI6XPfLy0LaGJbYtEyBcZMpy-PZV4LNMFXLRidBIrN6GGhlouGmo9UjsWcG-egzljg4Jkq6DvNuv-29B8QVSzMPsOLK8e7ccK145QC9ISNH5ijmzAZcQJmlqMAfPyHazOY2R3cWLZW7flXrAB0xW8FhvwfBMcbK8_z1-jg_r98h4fkNkPGbbi5IdJK2bpsGJsaf990ZruxevTo8zuTji5r6WkVJKAA9i70N5scR9ihvO2w11ME3rUAB",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GP-RzG9ICPf6MqRwIUUKWF55OGv8LSTmV2Tt6fNQLzld3tLeVxl7yrQU4GA23LPBbfn4sECyQEy8vwJz9SyIyr9xOUZLQhPfqg5wpLu-xF0Y1YjqeyyOCqnVPyKBqTr-0TTUY0kKFN4rIsdExUsS2FeRgq6uDLvGJ4tXd8qaGTqfZxJIQ5apZzJGFxGZNPdF64gXizvzyDiI0ioA-BFtazk8o3q-YJmBUGNlVf0E8yWdpkZeUZp6VNphmQONlzkf1EXqFsTH52VPtlDAymW6DOvPOv5ZWpuq-43LZ5OyRyJ-LFsvmCBffyzlbJdnCV3bvxbIAkpywwbBenMQOoCJU5DzUL7j2Zpk0bCbZNNFrwVIGev6shT_YHTWRm4_Kw1TTDsUY-tS_cdGuv169AdejbBaYwj34MDNCNDmMjNgPBIXAULUNGFoZx-HMfETPOh5qzhpSDWPf7kwVmeYZatPMqh_7fYFXVDBbE0NZjVh4WPumLYJYBxX9C_O0xsK4YVxan-LasNcFl4Y_btPZbIVre7JjZcU0q3kmdCD40-ZIfPWohhzfaBfkS8YQzdV4GLBnKoWEBonWWHm9nthQtlz2xdi3rhnaz2pqHLKNtnL3XhUk8rBF8NfPpWV--3RLMZ6JAtAgPIx_6isTSz3mn0PxiI6OmsHqmnec3jSRTjLWvtR3o2KsbavluQztVYmuDPNJO4dELARgOzaeAu51mlbOp44c7zQoKROk50kP-8aqxrE4NYq64uk3Yuifpfdcmzl2mQcAIo-3yLI6XPfLy0LaGJbYtEyBcZMpy-PZV4LNMFXLRidBIrN6GGhlouGmo9UjsWcG-egzljg4Jkq6DvNuv-29B8QVSzMPsOLK8e7ccK145QC9ISNH5ijmzAZcQJmlqMAfPyHazOY2R3cWLZW7flXrAB0xW8FhvwfBMcbK8_z1-jg_r98h4fkNkPGbbi5IdJK2bpsGJsaf990ZruxevTo8zuTji5r6WkVJKAA9i70N5scR9ihvO2w11ME3rUAB",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1429.46,
            "totalPrice": 1232
          },
          {
            "allPassengerTotalPrice": 1234,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9FysIMN1fRzjchVvO1p8Z5oruhXWCoaHblBwAKxPJa2MWR_TyyB0cSFWFkatCL9FdOwy07LNyqRXU5eiwNO4GJUMr53ApZC9-Soh1bKPpwcL4aTrKalPG3dFQ1Q5oK007v7wPSky9B4uvbWdEPZu3rLNHUNPUs5FeH5_5oRrHW8LyUXL9YCP7H4eK9rRKdVzdtn0TP2u6SG_vGRKgoUlR-wmDptFdFgAu1teeu5UlkoOqWfTwF4KImzHSU2_OEy1S17iiQ9DmwSfazeS1YYrsTKGmZ_1I1nloDc8IqWTSwo-opJ1H27CVLM_vCssG9YRoi9yWwIiMePNxfXrbornn-7hcTiUF27vF3fCZWiNj39DI-CjI0nHwa93cPIof4BG6i4srfoSYmvuvLdnvX0GcdUB29-frFGBD6jE2Y-yi7ElGqy1lKhKRELbGa77O6VJCzHTwwKEjwfECN2uOr7xp_l19XL07dMmKVKlLELs1he82LvUeTqbzOfbncqhVmT276-ov-Jsp8_bTb3uNhbz5S9bQMJvRITYWGyaFbu4iC94aY29aVkZFOGPoU385qhok3MTuUT-C--8RxE7rncp1sdWRlIknSERynkkpOvOXFNLt3Cd7tw6rStxzkhMbWMLaetOgGz7o9I1ayaDnzCET1REPDG1kbouS2IvDTfD9Ae3Ogr-xkwHf9xVmQTS0R4WEEfhNq5D5NrdXnpQ5-Wt8kntBIJDllpu6ruvrRfHR5q_MgEAY6rIfmktl9Qg_KZL0WBbd4JDJU4nzygNDTd3KMBBpSgLdFrj_mp7gayKTUp8VGhYPsc6t7-K-g7ZS11d5ZlMIL8CKHsqrGZPXV8HOUnrRHH9FF8plFaK0vzLH1NiDQQ85QWsgGWz4X3E-GTeBI9IZeh_-qo4bijihRwW1TfB46CIFB79eJlF-KhECQHIDC8tpeSdfLJPbQehk33nUYH93Sxmt4fE0x2oN5y9iFmaYlQyZrLZ-d87bkQu8ZCn_SSGXYpEVvOPpkmwKuSVejRlONxop0YJZ2OOU8YZh9JbEpBM0SIzauUfDAbolBcOqMBAiT3AmCzLJetGbqVC",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9FysIMN1fRzjchVvO1p8Z5oruhXWCoaHblBwAKxPJa2MWR_TyyB0cSFWFkatCL9FdOwy07LNyqRXU5eiwNO4GJUMr53ApZC9-Soh1bKPpwcL4aTrKalPG3dFQ1Q5oK007v7wPSky9B4uvbWdEPZu3rLNHUNPUs5FeH5_5oRrHW8LyUXL9YCP7H4eK9rRKdVzdtn0TP2u6SG_vGRKgoUlR-wmDptFdFgAu1teeu5UlkoOqWfTwF4KImzHSU2_OEy1S17iiQ9DmwSfazeS1YYrsTKGmZ_1I1nloDc8IqWTSwo-opJ1H27CVLM_vCssG9YRoi9yWwIiMePNxfXrbornn-7hcTiUF27vF3fCZWiNj39DI-CjI0nHwa93cPIof4BG6i4srfoSYmvuvLdnvX0GcdUB29-frFGBD6jE2Y-yi7ElGqy1lKhKRELbGa77O6VJCzHTwwKEjwfECN2uOr7xp_l19XL07dMmKVKlLELs1he82LvUeTqbzOfbncqhVmT276-ov-Jsp8_bTb3uNhbz5S9bQMJvRITYWGyaFbu4iC94aY29aVkZFOGPoU385qhok3MTuUT-C--8RxE7rncp1sdWRlIknSERynkkpOvOXFNLt3Cd7tw6rStxzkhMbWMLaetOgGz7o9I1ayaDnzCET1REPDG1kbouS2IvDTfD9Ae3Ogr-xkwHf9xVmQTS0R4WEEfhNq5D5NrdXnpQ5-Wt8kntBIJDllpu6ruvrRfHR5q_MgEAY6rIfmktl9Qg_KZL0WBbd4JDJU4nzygNDTd3KMBBpSgLdFrj_mp7gayKTUp8VGhYPsc6t7-K-g7ZS11d5ZlMIL8CKHsqrGZPXV8HOUnrRHH9FF8plFaK0vzLH1NiDQQ85QWsgGWz4X3E-GTeBI9IZeh_-qo4bijihRwW1TfB46CIFB79eJlF-KhECQHIDC8tpeSdfLJPbQehk33nUYH93Sxmt4fE0x2oN5y9iFmaYlQyZrLZ-d87bkQu8ZCn_SSGXYpEVvOPpkmwKuSVejRlONxop0YJZ2OOU8YZh9JbEpBM0SIzauUfDAbolBcOqMBAiT3AmCzLJetGbqVC",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9FysIMN1fRzjchVvO1p8Z5oruhXWCoaHblBwAKxPJa2MWR_TyyB0cSFWFkatCL9FdOwy07LNyqRXU5eiwNO4GJUMr53ApZC9-Soh1bKPpwcL4aTrKalPG3dFQ1Q5oK007v7wPSky9B4uvbWdEPZu3rLNHUNPUs5FeH5_5oRrHW8LyUXL9YCP7H4eK9rRKdVzdtn0TP2u6SG_vGRKgoUlR-wmDptFdFgAu1teeu5UlkoOqWfTwF4KImzHSU2_OEy1S17iiQ9DmwSfazeS1YYrsTKGmZ_1I1nloDc8IqWTSwo-opJ1H27CVLM_vCssG9YRoi9yWwIiMePNxfXrbornn-7hcTiUF27vF3fCZWiNj39DI-CjI0nHwa93cPIof4BG6i4srfoSYmvuvLdnvX0GcdUB29-frFGBD6jE2Y-yi7ElGqy1lKhKRELbGa77O6VJCzHTwwKEjwfECN2uOr7xp_l19XL07dMmKVKlLELs1he82LvUeTqbzOfbncqhVmT276-ov-Jsp8_bTb3uNhbz5S9bQMJvRITYWGyaFbu4iC94aY29aVkZFOGPoU385qhok3MTuUT-C--8RxE7rncp1sdWRlIknSERynkkpOvOXFNLt3Cd7tw6rStxzkhMbWMLaetOgGz7o9I1ayaDnzCET1REPDG1kbouS2IvDTfD9Ae3Ogr-xkwHf9xVmQTS0R4WEEfhNq5D5NrdXnpQ5-Wt8kntBIJDllpu6ruvrRfHR5q_MgEAY6rIfmktl9Qg_KZL0WBbd4JDJU4nzygNDTd3KMBBpSgLdFrj_mp7gayKTUp8VGhYPsc6t7-K-g7ZS11d5ZlMIL8CKHsqrGZPXV8HOUnrRHH9FF8plFaK0vzLH1NiDQQ85QWsgGWz4X3E-GTeBI9IZeh_-qo4bijihRwW1TfB46CIFB79eJlF-KhECQHIDC8tpeSdfLJPbQehk33nUYH93Sxmt4fE0x2oN5y9iFmaYlQyZrLZ-d87bkQu8ZCn_SSGXYpEVvOPpkmwKuSVejRlONxop0YJZ2OOU8YZh9JbEpBM0SIzauUfDAbolBcOqMBAiT3AmCzLJetGbqVC",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1452.59,
            "totalPrice": 1234
          },
          {
            "allPassengerTotalPrice": 1235,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUX8S6IodCBryFROqhs8m0mJF-qcI7dLTNo0onLE0YFEH4znve2ImPpETZYmGP1bE_YIUbrpJht2TQ1pCz2LTiIIpIPb4pcgiwhPYhY5M_M1iH7u-eO483URX6nfkGNmm0d22gjS-REKsEuYUGyrCEvBJKYqsSNsceMDgfIp2XRs8KiSoiT5O5GM1zDWlOnC5O9gDkuvnre7a6wUH16Vui20PfY8FDcYrlczgc_0-FYDdxYH466Rww_u2zpDGnZSdYs1dCNlcTNFA12vrI2rf-cCRpw8jzGNaX6uG-ECF8_XwvurYWZVyOigas7mWTl3gp7a1R0jjrbzqj8LSoQKBI5HwhE6xORP-FWeT6GMSS7ncd0lLDtmRa6YoqbFCfOYXHxBOQuVVBFjNfU87P7xiM4xc0Mfzm5ITB7jJJ4j72tlVrj26DnTXNqLqXt1I5UuUoOvqIypsnUeQeuLOnYp1nAbPFoIOIdH5Zl3O7gO9AX3nSJcpNKjnObbd5j65No2tRqAu4bliug2HLYoA5ow5bPgZ_tT52kN1sIKzP3SQRnXQgMUoj86_Px76iTnKdMNmJLmf6iBtkDhjD_rBojqAyTmrtypq6oW86GXnnXY3TrgZj-d0J0RPyHD2fXoNW7uxjP62o1FBc4ekETzDLfj-WSntaWu1YiSgi38V1bK5Xx-2gLyWUDHPqhX4Z_ZYpiNg35Umd33Bi8VSN5jN0gXZ6D6_d5t10PHYMfOjS5I9M6vrb3YHStcVwoKji1FY9PVei_wq9eh2f_ZUjwVUyB6gjDxqO-ov70hZnQg12jKX4RybSkeCd6Rn_5LfHHPmKj2clWlbMHOMMJ-YmaaRj1DEF7fCu4fs73l5_JPpH2o3Qy5VMqiBQiW6CVgvl6lqcGNC6J-NXfo1I5RkZB6hcuMU32l-dbnccLnLkNC5iPNvh4GOYnnaXUL58k0MxMBV0eQwpIgN7vmlLNtTtQOV_NWfPzWngoLt0QUWgvKSCXhp0SMvfr-ZTZX1_ulGa_V1E65wBC2Fq9WT7uWBcvbDwjR-XpY-HkBGPJJTzEoUqmJ0Aj8ZG-Vw97wNx6rpId7jc5k1ITaS4Xadg5smJGgQ_mVSGA==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUX8S6IodCBryFROqhs8m0mJF-qcI7dLTNo0onLE0YFEH4znve2ImPpETZYmGP1bE_YIUbrpJht2TQ1pCz2LTiIIpIPb4pcgiwhPYhY5M_M1iH7u-eO483URX6nfkGNmm0d22gjS-REKsEuYUGyrCEvBJKYqsSNsceMDgfIp2XRs8KiSoiT5O5GM1zDWlOnC5O9gDkuvnre7a6wUH16Vui20PfY8FDcYrlczgc_0-FYDdxYH466Rww_u2zpDGnZSdYs1dCNlcTNFA12vrI2rf-cCRpw8jzGNaX6uG-ECF8_XwvurYWZVyOigas7mWTl3gp7a1R0jjrbzqj8LSoQKBI5HwhE6xORP-FWeT6GMSS7ncd0lLDtmRa6YoqbFCfOYXHxBOQuVVBFjNfU87P7xiM4xc0Mfzm5ITB7jJJ4j72tlVrj26DnTXNqLqXt1I5UuUoOvqIypsnUeQeuLOnYp1nAbPFoIOIdH5Zl3O7gO9AX3nSJcpNKjnObbd5j65No2tRqAu4bliug2HLYoA5ow5bPgZ_tT52kN1sIKzP3SQRnXQgMUoj86_Px76iTnKdMNmJLmf6iBtkDhjD_rBojqAyTmrtypq6oW86GXnnXY3TrgZj-d0J0RPyHD2fXoNW7uxjP62o1FBc4ekETzDLfj-WSntaWu1YiSgi38V1bK5Xx-2gLyWUDHPqhX4Z_ZYpiNg35Umd33Bi8VSN5jN0gXZ6D6_d5t10PHYMfOjS5I9M6vrb3YHStcVwoKji1FY9PVei_wq9eh2f_ZUjwVUyB6gjDxqO-ov70hZnQg12jKX4RybSkeCd6Rn_5LfHHPmKj2clWlbMHOMMJ-YmaaRj1DEF7fCu4fs73l5_JPpH2o3Qy5VMqiBQiW6CVgvl6lqcGNC6J-NXfo1I5RkZB6hcuMU32l-dbnccLnLkNC5iPNvh4GOYnnaXUL58k0MxMBV0eQwpIgN7vmlLNtTtQOV_NWfPzWngoLt0QUWgvKSCXhp0SMvfr-ZTZX1_ulGa_V1E65wBC2Fq9WT7uWBcvbDwjR-XpY-HkBGPJJTzEoUqmJ0Aj8ZG-Vw97wNx6rpId7jc5k1ITaS4Xadg5smJGgQ_mVSGA==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUX8S6IodCBryFROqhs8m0mJF-qcI7dLTNo0onLE0YFEH4znve2ImPpETZYmGP1bE_YIUbrpJht2TQ1pCz2LTiIIpIPb4pcgiwhPYhY5M_M1iH7u-eO483URX6nfkGNmm0d22gjS-REKsEuYUGyrCEvBJKYqsSNsceMDgfIp2XRs8KiSoiT5O5GM1zDWlOnC5O9gDkuvnre7a6wUH16Vui20PfY8FDcYrlczgc_0-FYDdxYH466Rww_u2zpDGnZSdYs1dCNlcTNFA12vrI2rf-cCRpw8jzGNaX6uG-ECF8_XwvurYWZVyOigas7mWTl3gp7a1R0jjrbzqj8LSoQKBI5HwhE6xORP-FWeT6GMSS7ncd0lLDtmRa6YoqbFCfOYXHxBOQuVVBFjNfU87P7xiM4xc0Mfzm5ITB7jJJ4j72tlVrj26DnTXNqLqXt1I5UuUoOvqIypsnUeQeuLOnYp1nAbPFoIOIdH5Zl3O7gO9AX3nSJcpNKjnObbd5j65No2tRqAu4bliug2HLYoA5ow5bPgZ_tT52kN1sIKzP3SQRnXQgMUoj86_Px76iTnKdMNmJLmf6iBtkDhjD_rBojqAyTmrtypq6oW86GXnnXY3TrgZj-d0J0RPyHD2fXoNW7uxjP62o1FBc4ekETzDLfj-WSntaWu1YiSgi38V1bK5Xx-2gLyWUDHPqhX4Z_ZYpiNg35Umd33Bi8VSN5jN0gXZ6D6_d5t10PHYMfOjS5I9M6vrb3YHStcVwoKji1FY9PVei_wq9eh2f_ZUjwVUyB6gjDxqO-ov70hZnQg12jKX4RybSkeCd6Rn_5LfHHPmKj2clWlbMHOMMJ-YmaaRj1DEF7fCu4fs73l5_JPpH2o3Qy5VMqiBQiW6CVgvl6lqcGNC6J-NXfo1I5RkZB6hcuMU32l-dbnccLnLkNC5iPNvh4GOYnnaXUL58k0MxMBV0eQwpIgN7vmlLNtTtQOV_NWfPzWngoLt0QUWgvKSCXhp0SMvfr-ZTZX1_ulGa_V1E65wBC2Fq9WT7uWBcvbDwjR-XpY-HkBGPJJTzEoUqmJ0Aj8ZG-Vw97wNx6rpId7jc5k1ITaS4Xadg5smJGgQ_mVSGA==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1453.9,
            "totalPrice": 1235
          },
          {
            "allPassengerTotalPrice": 1251,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ge-9IFpTREwxlfmbddk1ZjOfR5UZLayx5ECxR3JGcq3EXQkCcmLS1gjcjLkMB6FD-lrWmsNjRbNbClYQD59Ew8NhQB3QeURRNcOQ1un84JIuqANpRwVaaT0vnufBcKaonVJyMYVPCKDKuvXeCDHR_bDkUwrVau8XWAd_qh3_IkAfddNl6P7WHt0sSR7fKpcZsg9iN59rgtaAFGpbYhgxja0Ax7EpF4JpsisdVnspKK2Daeg_ymRmUpViFsVZj0kw0dojoEVxkfAC9jlVfkZS1CO_Ekwy7tIr_Hh0ZUfOV8t7T52xMuWi0IezPyhqBJGTZmVYXw4NJiIXdXfRzci1poaY1mmAl8Id8RdBFM6fglalN151MtuFMpF-c-mCX0ukSiQw4C4JugcN1ybRaLtkLefsaJGTKnJNxiPXAyUWMbrkagxxIgV4PU8iqgLCCdgOSG23VEkRcrpLipix1qHoUwbtHlOkBHrlKN0Lr8_OVVv7dtqK-6ACKbn0QKYqoFM390OPmq07uDAHjyRPJCgUunDUThXPoplb_rgBNhwLv94IqC3lSeI43nzdZpQ1nfxlzHkMUF9AxtyON1ZL47GyZhUevDi9Q5kZgueJj8piH6PKugBmeSQKW7-fxn5XyUq7Ski1WlHfmcluC2fd1luj7kou5iEpa4K7a7QYsFT5LnQOLnjAnV8IBD9QxzvhgtCaIzyPHjrsAsXixWt2kdUr7M7Ls0TzgaEjq0uRpqDbDZI8AirUEimBw6HzkRQiVKmAFMhDH3U190pyuRF1U5IuAbsceB5AwckXakb5sqbtUxcci_dAdXAX6fygPDtmiV5isb2N1VXRVUUGwKzpAX-ypZP2zMN4Qsc21CQLPzgs2I2e-dZ7zbgHwrp3k57JfbVFD0sCF_xa5Kcu45HwfM0LFhcJiCBP7sUhO4szBz_-vE-ZUiOjawKpn7dal4EbtPVXe4dyO_U2QjLLwRV_h86RrjU4jz2c3TL2XwHlzXFSyv_HMfdDI7HJHWfMsI7yGM9KxmDxXQUXBH_wRPDKJx4OYb0jNRYruEeuPC9COgk2abnEiASQ-1erX8SPYbqWh8cgBQttgatPObzErfkPmGsxATQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ge-9IFpTREwxlfmbddk1ZjOfR5UZLayx5ECxR3JGcq3EXQkCcmLS1gjcjLkMB6FD-lrWmsNjRbNbClYQD59Ew8NhQB3QeURRNcOQ1un84JIuqANpRwVaaT0vnufBcKaonVJyMYVPCKDKuvXeCDHR_bDkUwrVau8XWAd_qh3_IkAfddNl6P7WHt0sSR7fKpcZsg9iN59rgtaAFGpbYhgxja0Ax7EpF4JpsisdVnspKK2Daeg_ymRmUpViFsVZj0kw0dojoEVxkfAC9jlVfkZS1CO_Ekwy7tIr_Hh0ZUfOV8t7T52xMuWi0IezPyhqBJGTZmVYXw4NJiIXdXfRzci1poaY1mmAl8Id8RdBFM6fglalN151MtuFMpF-c-mCX0ukSiQw4C4JugcN1ybRaLtkLefsaJGTKnJNxiPXAyUWMbrkagxxIgV4PU8iqgLCCdgOSG23VEkRcrpLipix1qHoUwbtHlOkBHrlKN0Lr8_OVVv7dtqK-6ACKbn0QKYqoFM390OPmq07uDAHjyRPJCgUunDUThXPoplb_rgBNhwLv94IqC3lSeI43nzdZpQ1nfxlzHkMUF9AxtyON1ZL47GyZhUevDi9Q5kZgueJj8piH6PKugBmeSQKW7-fxn5XyUq7Ski1WlHfmcluC2fd1luj7kou5iEpa4K7a7QYsFT5LnQOLnjAnV8IBD9QxzvhgtCaIzyPHjrsAsXixWt2kdUr7M7Ls0TzgaEjq0uRpqDbDZI8AirUEimBw6HzkRQiVKmAFMhDH3U190pyuRF1U5IuAbsceB5AwckXakb5sqbtUxcci_dAdXAX6fygPDtmiV5isb2N1VXRVUUGwKzpAX-ypZP2zMN4Qsc21CQLPzgs2I2e-dZ7zbgHwrp3k57JfbVFD0sCF_xa5Kcu45HwfM0LFhcJiCBP7sUhO4szBz_-vE-ZUiOjawKpn7dal4EbtPVXe4dyO_U2QjLLwRV_h86RrjU4jz2c3TL2XwHlzXFSyv_HMfdDI7HJHWfMsI7yGM9KxmDxXQUXBH_wRPDKJx4OYb0jNRYruEeuPC9COgk2abnEiASQ-1erX8SPYbqWh8cgBQttgatPObzErfkPmGsxATQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ge-9IFpTREwxlfmbddk1ZjOfR5UZLayx5ECxR3JGcq3EXQkCcmLS1gjcjLkMB6FD-lrWmsNjRbNbClYQD59Ew8NhQB3QeURRNcOQ1un84JIuqANpRwVaaT0vnufBcKaonVJyMYVPCKDKuvXeCDHR_bDkUwrVau8XWAd_qh3_IkAfddNl6P7WHt0sSR7fKpcZsg9iN59rgtaAFGpbYhgxja0Ax7EpF4JpsisdVnspKK2Daeg_ymRmUpViFsVZj0kw0dojoEVxkfAC9jlVfkZS1CO_Ekwy7tIr_Hh0ZUfOV8t7T52xMuWi0IezPyhqBJGTZmVYXw4NJiIXdXfRzci1poaY1mmAl8Id8RdBFM6fglalN151MtuFMpF-c-mCX0ukSiQw4C4JugcN1ybRaLtkLefsaJGTKnJNxiPXAyUWMbrkagxxIgV4PU8iqgLCCdgOSG23VEkRcrpLipix1qHoUwbtHlOkBHrlKN0Lr8_OVVv7dtqK-6ACKbn0QKYqoFM390OPmq07uDAHjyRPJCgUunDUThXPoplb_rgBNhwLv94IqC3lSeI43nzdZpQ1nfxlzHkMUF9AxtyON1ZL47GyZhUevDi9Q5kZgueJj8piH6PKugBmeSQKW7-fxn5XyUq7Ski1WlHfmcluC2fd1luj7kou5iEpa4K7a7QYsFT5LnQOLnjAnV8IBD9QxzvhgtCaIzyPHjrsAsXixWt2kdUr7M7Ls0TzgaEjq0uRpqDbDZI8AirUEimBw6HzkRQiVKmAFMhDH3U190pyuRF1U5IuAbsceB5AwckXakb5sqbtUxcci_dAdXAX6fygPDtmiV5isb2N1VXRVUUGwKzpAX-ypZP2zMN4Qsc21CQLPzgs2I2e-dZ7zbgHwrp3k57JfbVFD0sCF_xa5Kcu45HwfM0LFhcJiCBP7sUhO4szBz_-vE-ZUiOjawKpn7dal4EbtPVXe4dyO_U2QjLLwRV_h86RrjU4jz2c3TL2XwHlzXFSyv_HMfdDI7HJHWfMsI7yGM9KxmDxXQUXBH_wRPDKJx4OYb0jNRYruEeuPC9COgk2abnEiASQ-1erX8SPYbqWh8cgBQttgatPObzErfkPmGsxATQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1466.24,
            "totalPrice": 1251
          },
          {
            "allPassengerTotalPrice": 1260,
            "legs": [
              {
                "airlines": [
                  "WS",
                  "WS"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "12:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GA8xcsZvtj24SFVAbXeFW7POL7DyVKGjczjg5aNjDjRLdSgQnsIqRLfMCpeDIZbvbSoH6RqWJWoPHP7Eo7SZC03sHFU5TXnxoT-eCxHoZRGuCtec2FZUwoGRbORVzrlvaUkJVCRL_pFsJ1YAxVGP1hW-KJooiRVw8WtBPWpDk8vdwaV5VK4I-skSI0qZBanf_fwJJhBaLwWX0fWK6AbHqXjSYDZatIKAuxrLP06P0Umd2Sdrnfu-ar-ZjQOTdNvOYfOovouAnfsQyseWhanQdvCozFAqTCMnfbNyrH0_mFplBHyuoa6BtlmZrcXHzQ5EJd-trKeg1PaObi6fLT8JQXmspNU_QnpSqDEZqmxsc1uTTQ7AkpJ5vEozazypPxYg7xsJqaiNNkHql5RAB4fqYUkry5smCJ-EG_r5rZ0wIkRCHppauIwpcdCjRjDsMFiAOLlbvs5VNSS1lJ_bGSwujxWGD-eenliRXC-aF092l7j0SgveZ_15Pj1g8_am_aqOMU8bh4WBfeoifnBY3kumMy6yytVwI6yqXV_9RrrJRAtSBMVvh3R4Q1WsGEv6g8KT9gq_u-OCU2L81MVpFxgeG71F1jgjnSQBAw3A5qeFAqpfusy6DB15lzbI20-AIN-9hVUqgpEeyxVxBaT0bxBxFVB-YzYM3x-yZ-GefsV5br6SP80h8oH1lcs3D5ENruQqv4t0DMFENPxNhM8eAc-GxhEQyCpidueE1frOLfk_V33mqeiw1964PARUOegRaLOsXkmQWLZ16nvVne_BM5y3uQazWMdFIdP0t8Uyv-JXwMLE317yJ7GCzCY5iD_K0IGfP5Uaxdn4qPD6RQQ_fuhmXssCqIBuAP8kkAmEW5_jSVDX7kDW_Z3cxy4FQaQTg-OFc0PlvUNXqfETjr92yxgVrybaQ6paapKCN0qRKReJrH5SZKuQmWJaVzzjG_8H1ZiRnSKWvdKwXtY4ytg0_RUl_cZVkZZrP223wNqJK7HOzxXjllj350xXpHsoP5QoJtHoOHcFhiNAGt_o4oSAbaELnBU5NAQiTQf4xD4V8i4AJreI=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "12:50",
                "destination": "BCN",
                "duration": "14h35m",
                "routes": [
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "YYC",
                    "cityFrom": "San Francisco",
                    "cityTo": "Calgary",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "KTQD0ZEW",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1509,
                    "flyFrom": "SFO",
                    "flyTo": "YYC",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_0",
                    "local_arrival": "2024-09-14T16:31:00.000Z",
                    "local_departure": "2024-09-14T12:50:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "1509",
                    "return": 0,
                    "utc_arrival": "2024-09-14T22:31:00.000Z",
                    "utc_departure": "2024-09-14T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "WS",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "YYC",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Calgary",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00008bec35e7",
                    "equipment": null,
                    "fare_basis": "LP7J2HEK",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 8,
                    "flyFrom": "YYC",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00008bec35e7_1",
                    "local_arrival": "2024-09-15T12:25:00.000Z",
                    "local_departure": "2024-09-14T19:00:00.000Z",
                    "operating_carrier": "WS",
                    "operating_flight_no": "8",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:25:00.000Z",
                    "utc_departure": "2024-09-15T01:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GA8xcsZvtj24SFVAbXeFW7POL7DyVKGjczjg5aNjDjRLdSgQnsIqRLfMCpeDIZbvbSoH6RqWJWoPHP7Eo7SZC03sHFU5TXnxoT-eCxHoZRGuCtec2FZUwoGRbORVzrlvaUkJVCRL_pFsJ1YAxVGP1hW-KJooiRVw8WtBPWpDk8vdwaV5VK4I-skSI0qZBanf_fwJJhBaLwWX0fWK6AbHqXjSYDZatIKAuxrLP06P0Umd2Sdrnfu-ar-ZjQOTdNvOYfOovouAnfsQyseWhanQdvCozFAqTCMnfbNyrH0_mFplBHyuoa6BtlmZrcXHzQ5EJd-trKeg1PaObi6fLT8JQXmspNU_QnpSqDEZqmxsc1uTTQ7AkpJ5vEozazypPxYg7xsJqaiNNkHql5RAB4fqYUkry5smCJ-EG_r5rZ0wIkRCHppauIwpcdCjRjDsMFiAOLlbvs5VNSS1lJ_bGSwujxWGD-eenliRXC-aF092l7j0SgveZ_15Pj1g8_am_aqOMU8bh4WBfeoifnBY3kumMy6yytVwI6yqXV_9RrrJRAtSBMVvh3R4Q1WsGEv6g8KT9gq_u-OCU2L81MVpFxgeG71F1jgjnSQBAw3A5qeFAqpfusy6DB15lzbI20-AIN-9hVUqgpEeyxVxBaT0bxBxFVB-YzYM3x-yZ-GefsV5br6SP80h8oH1lcs3D5ENruQqv4t0DMFENPxNhM8eAc-GxhEQyCpidueE1frOLfk_V33mqeiw1964PARUOegRaLOsXkmQWLZ16nvVne_BM5y3uQazWMdFIdP0t8Uyv-JXwMLE317yJ7GCzCY5iD_K0IGfP5Uaxdn4qPD6RQQ_fuhmXssCqIBuAP8kkAmEW5_jSVDX7kDW_Z3cxy4FQaQTg-OFc0PlvUNXqfETjr92yxgVrybaQ6paapKCN0qRKReJrH5SZKuQmWJaVzzjG_8H1ZiRnSKWvdKwXtY4ytg0_RUl_cZVkZZrP223wNqJK7HOzxXjllj350xXpHsoP5QoJtHoOHcFhiNAGt_o4oSAbaELnBU5NAQiTQf4xD4V8i4AJreI=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00008bec35e7_0%7C0f9c01af4e0c00008bec35e7_1%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GA8xcsZvtj24SFVAbXeFW7POL7DyVKGjczjg5aNjDjRLdSgQnsIqRLfMCpeDIZbvbSoH6RqWJWoPHP7Eo7SZC03sHFU5TXnxoT-eCxHoZRGuCtec2FZUwoGRbORVzrlvaUkJVCRL_pFsJ1YAxVGP1hW-KJooiRVw8WtBPWpDk8vdwaV5VK4I-skSI0qZBanf_fwJJhBaLwWX0fWK6AbHqXjSYDZatIKAuxrLP06P0Umd2Sdrnfu-ar-ZjQOTdNvOYfOovouAnfsQyseWhanQdvCozFAqTCMnfbNyrH0_mFplBHyuoa6BtlmZrcXHzQ5EJd-trKeg1PaObi6fLT8JQXmspNU_QnpSqDEZqmxsc1uTTQ7AkpJ5vEozazypPxYg7xsJqaiNNkHql5RAB4fqYUkry5smCJ-EG_r5rZ0wIkRCHppauIwpcdCjRjDsMFiAOLlbvs5VNSS1lJ_bGSwujxWGD-eenliRXC-aF092l7j0SgveZ_15Pj1g8_am_aqOMU8bh4WBfeoifnBY3kumMy6yytVwI6yqXV_9RrrJRAtSBMVvh3R4Q1WsGEv6g8KT9gq_u-OCU2L81MVpFxgeG71F1jgjnSQBAw3A5qeFAqpfusy6DB15lzbI20-AIN-9hVUqgpEeyxVxBaT0bxBxFVB-YzYM3x-yZ-GefsV5br6SP80h8oH1lcs3D5ENruQqv4t0DMFENPxNhM8eAc-GxhEQyCpidueE1frOLfk_V33mqeiw1964PARUOegRaLOsXkmQWLZ16nvVne_BM5y3uQazWMdFIdP0t8Uyv-JXwMLE317yJ7GCzCY5iD_K0IGfP5Uaxdn4qPD6RQQ_fuhmXssCqIBuAP8kkAmEW5_jSVDX7kDW_Z3cxy4FQaQTg-OFc0PlvUNXqfETjr92yxgVrybaQ6paapKCN0qRKReJrH5SZKuQmWJaVzzjG_8H1ZiRnSKWvdKwXtY4ytg0_RUl_cZVkZZrP223wNqJK7HOzxXjllj350xXpHsoP5QoJtHoOHcFhiNAGt_o4oSAbaELnBU5NAQiTQf4xD4V8i4AJreI=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1474.6,
            "totalPrice": 1260
          },
          {
            "allPassengerTotalPrice": 1292,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtRni36HvvrLUv3fp00IxRYcddTWz2_WOpxGh82iyigAq5KmCBxOR0GMInCnJJHwGMcxppeuSxkxlvVU0J4gq5o9I_B0zMMe2TjrImT1vDC3IK_H3nkf3xY8v-PYoq4SgD0mq3hH9UVrgs7bHeAFKdhty-8OwA9_8_g4epbAKKeBmfX663nYPygX-6WcigcR1s0z21KoLsiQjoBrQ-8qwFSB_qRH9o3A5wgCQ7bULyDjmsREM7ZP4ZKfa9aWM9Sdcbft_trKTicNycoIS9wjC91EM1R8zj4qCLVQw3xDXQEjbgAFSr8IuG6VUiTJtt9qJ8odPTF7kL8gNyDDqtA4LlNOjdPc2QZfIDH2Bvcs6939HUQXWhk6e8LcC54IK2ZWj3sQjbiHdZgQo3F_GXGAGZea7M9NLkfgom6FJJw3H7C1qDLv-uEhrt7VZuTMk9NQDylYWeABo8Nd260jTBWzCcSItZDIi3KiXnSdh-AzFC-8de1ikY2oVvC-A3d8j8IuX96A3QhEhsr1FmtpXXgBmXRTGEIWn_wiO6sE2Uw1yfIixQOJCcLqS8k8rV9PcmpYIWJgOkmwQjlJCU1_sNeY5Q9MadZTKlk_GUoXUFBlQp-fl87Kfon2CoiPoULythsHrtfUBqxd38fbLD5DxnSBs5ImnjDOSEiVhaOVilFWcBQOnJH3_LfPwgXVATxw9SPgh31z-3rx6pm1y72oRFIXuUU3Wa8MlOz-OuWDoLzWUbya8RW7v-KjWiI8jiolfgyLNhfcVAiHUgBAk1qMfxw6l1sYU9xGEGgYiB-jB7ngtjEbWfLk2Hhy4vk5x8WABBgIFwm1crqtQOrpdfgzCGQ4sAFGYlHKNJzs2OBsDjhWDL1F1GTM7zHUNk67Zp5TXQ8TOkHY8xLcS3Bj5Pi7nlXmoAVBt_GANdaa4c8IrRdqDxO6N4vNtSz2a65gbNR6O94uD69dXn8I5sx2CSRmpbcylBGeyFOBztmSAqrMWtqEMWQQD3sWxCTjG_gu6EFqNq8mM0KfVbkfX1iNxuWrVzx3OH3YYCrvNqwOT1kVLSP_X_Fs=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtRni36HvvrLUv3fp00IxRYcddTWz2_WOpxGh82iyigAq5KmCBxOR0GMInCnJJHwGMcxppeuSxkxlvVU0J4gq5o9I_B0zMMe2TjrImT1vDC3IK_H3nkf3xY8v-PYoq4SgD0mq3hH9UVrgs7bHeAFKdhty-8OwA9_8_g4epbAKKeBmfX663nYPygX-6WcigcR1s0z21KoLsiQjoBrQ-8qwFSB_qRH9o3A5wgCQ7bULyDjmsREM7ZP4ZKfa9aWM9Sdcbft_trKTicNycoIS9wjC91EM1R8zj4qCLVQw3xDXQEjbgAFSr8IuG6VUiTJtt9qJ8odPTF7kL8gNyDDqtA4LlNOjdPc2QZfIDH2Bvcs6939HUQXWhk6e8LcC54IK2ZWj3sQjbiHdZgQo3F_GXGAGZea7M9NLkfgom6FJJw3H7C1qDLv-uEhrt7VZuTMk9NQDylYWeABo8Nd260jTBWzCcSItZDIi3KiXnSdh-AzFC-8de1ikY2oVvC-A3d8j8IuX96A3QhEhsr1FmtpXXgBmXRTGEIWn_wiO6sE2Uw1yfIixQOJCcLqS8k8rV9PcmpYIWJgOkmwQjlJCU1_sNeY5Q9MadZTKlk_GUoXUFBlQp-fl87Kfon2CoiPoULythsHrtfUBqxd38fbLD5DxnSBs5ImnjDOSEiVhaOVilFWcBQOnJH3_LfPwgXVATxw9SPgh31z-3rx6pm1y72oRFIXuUU3Wa8MlOz-OuWDoLzWUbya8RW7v-KjWiI8jiolfgyLNhfcVAiHUgBAk1qMfxw6l1sYU9xGEGgYiB-jB7ngtjEbWfLk2Hhy4vk5x8WABBgIFwm1crqtQOrpdfgzCGQ4sAFGYlHKNJzs2OBsDjhWDL1F1GTM7zHUNk67Zp5TXQ8TOkHY8xLcS3Bj5Pi7nlXmoAVBt_GANdaa4c8IrRdqDxO6N4vNtSz2a65gbNR6O94uD69dXn8I5sx2CSRmpbcylBGeyFOBztmSAqrMWtqEMWQQD3sWxCTjG_gu6EFqNq8mM0KfVbkfX1iNxuWrVzx3OH3YYCrvNqwOT1kVLSP_X_Fs=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtRni36HvvrLUv3fp00IxRYcddTWz2_WOpxGh82iyigAq5KmCBxOR0GMInCnJJHwGMcxppeuSxkxlvVU0J4gq5o9I_B0zMMe2TjrImT1vDC3IK_H3nkf3xY8v-PYoq4SgD0mq3hH9UVrgs7bHeAFKdhty-8OwA9_8_g4epbAKKeBmfX663nYPygX-6WcigcR1s0z21KoLsiQjoBrQ-8qwFSB_qRH9o3A5wgCQ7bULyDjmsREM7ZP4ZKfa9aWM9Sdcbft_trKTicNycoIS9wjC91EM1R8zj4qCLVQw3xDXQEjbgAFSr8IuG6VUiTJtt9qJ8odPTF7kL8gNyDDqtA4LlNOjdPc2QZfIDH2Bvcs6939HUQXWhk6e8LcC54IK2ZWj3sQjbiHdZgQo3F_GXGAGZea7M9NLkfgom6FJJw3H7C1qDLv-uEhrt7VZuTMk9NQDylYWeABo8Nd260jTBWzCcSItZDIi3KiXnSdh-AzFC-8de1ikY2oVvC-A3d8j8IuX96A3QhEhsr1FmtpXXgBmXRTGEIWn_wiO6sE2Uw1yfIixQOJCcLqS8k8rV9PcmpYIWJgOkmwQjlJCU1_sNeY5Q9MadZTKlk_GUoXUFBlQp-fl87Kfon2CoiPoULythsHrtfUBqxd38fbLD5DxnSBs5ImnjDOSEiVhaOVilFWcBQOnJH3_LfPwgXVATxw9SPgh31z-3rx6pm1y72oRFIXuUU3Wa8MlOz-OuWDoLzWUbya8RW7v-KjWiI8jiolfgyLNhfcVAiHUgBAk1qMfxw6l1sYU9xGEGgYiB-jB7ngtjEbWfLk2Hhy4vk5x8WABBgIFwm1crqtQOrpdfgzCGQ4sAFGYlHKNJzs2OBsDjhWDL1F1GTM7zHUNk67Zp5TXQ8TOkHY8xLcS3Bj5Pi7nlXmoAVBt_GANdaa4c8IrRdqDxO6N4vNtSz2a65gbNR6O94uD69dXn8I5sx2CSRmpbcylBGeyFOBztmSAqrMWtqEMWQQD3sWxCTjG_gu6EFqNq8mM0KfVbkfX1iNxuWrVzx3OH3YYCrvNqwOT1kVLSP_X_Fs=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1496.31,
            "totalPrice": 1292
          },
          {
            "allPassengerTotalPrice": 1295,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVjhZQ0SFGiperQviOmo0EK1C0IztrF0S-EdQ5zQAGs4okgc5Mw_7D_MHfCEPjlpNiybxSvgVnsGaQUuD8oldih8wEwSPbj_H49YVQEOqbnmbfTxrB877pUOhfCIWVI8hQxaCbTFCVZkoUD01vgpYBmTtZ9dTi-KiGESsMjwMAx4ogFhUn9XIl0A3JIK7_Q-8VY8uIAuoB5BrE5aGU70Q7uI7ZCAW7lw2umZPyQg-gSoeziFXlvGr22PG4INedsbZPjoqQCM16JMHpvf3401NBxlsqQHYxDcqlDwjLAuMVufsO6dxTg0VG_-o98bmaO9GsF94hw9sOe6UbZNPG-ehwrRsI4veBzXUWPQcWNzwsR_pTLKtASQcD0pKgtdTqmEKkIL5-jM0KqlKDzPco3cf7wclVMInwG79Tbh8hwLeLHsF38t_kU49qtJwvNw76SFZlCJFEl-J62A_lI5lByUZFzYIUoyhotWlFx_eVS3QYtvNtZbGrPTZQT0YWk7tFLqjMTrXg1aSKl_087gaustgLb03_Gl4RW91Js9JwdUwybn5puj0Kd3SATX6TtvDdl71GGPypJ95tADwbqnSvSQrBSXS_BJJobU0fcMsNPCxBdzyOKDZvb9Ln1R17WZkn2E1ogzHCTqgZTqao4fISytia7ueKVI8blNNraMJXXS_YlgLpY5pFOI9tbJ4Cg2PzB_eOH12q0uldWH7nsYs1KGGWErzwP28VD0I6LxXHmnn6ImIRU0X-AHd6sMQfcDJcEKsa5MuOfBZmEfJv-NaQa_lqHo38lwOsWlV4TO523w2RUmE2j3e1NcwNJ_FSDRZKRQlrt3JfVhp4pNanNm_AKTaBeKwlM0Un6J2hCY5s2JxCgKGXROdG2PWzhslIAhLFepGJYtf1XpxN978ptd9CaxrJg5sZb-m3A00OevyE_vbzcB5y_ICwJuN-lp7Vz-_o63UxkH_jnCrVXpxkcLKd55mvQsBYg2LIYoKYrKPUTpcSLr5dRsCAYbvWpN_rb_5Etyw6lLirPn-eRADaEu61hqSUo5i5vFhgKKkFDK0nXEQSza_F7XrgbzakUCN5WeOO7Xv",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVjhZQ0SFGiperQviOmo0EK1C0IztrF0S-EdQ5zQAGs4okgc5Mw_7D_MHfCEPjlpNiybxSvgVnsGaQUuD8oldih8wEwSPbj_H49YVQEOqbnmbfTxrB877pUOhfCIWVI8hQxaCbTFCVZkoUD01vgpYBmTtZ9dTi-KiGESsMjwMAx4ogFhUn9XIl0A3JIK7_Q-8VY8uIAuoB5BrE5aGU70Q7uI7ZCAW7lw2umZPyQg-gSoeziFXlvGr22PG4INedsbZPjoqQCM16JMHpvf3401NBxlsqQHYxDcqlDwjLAuMVufsO6dxTg0VG_-o98bmaO9GsF94hw9sOe6UbZNPG-ehwrRsI4veBzXUWPQcWNzwsR_pTLKtASQcD0pKgtdTqmEKkIL5-jM0KqlKDzPco3cf7wclVMInwG79Tbh8hwLeLHsF38t_kU49qtJwvNw76SFZlCJFEl-J62A_lI5lByUZFzYIUoyhotWlFx_eVS3QYtvNtZbGrPTZQT0YWk7tFLqjMTrXg1aSKl_087gaustgLb03_Gl4RW91Js9JwdUwybn5puj0Kd3SATX6TtvDdl71GGPypJ95tADwbqnSvSQrBSXS_BJJobU0fcMsNPCxBdzyOKDZvb9Ln1R17WZkn2E1ogzHCTqgZTqao4fISytia7ueKVI8blNNraMJXXS_YlgLpY5pFOI9tbJ4Cg2PzB_eOH12q0uldWH7nsYs1KGGWErzwP28VD0I6LxXHmnn6ImIRU0X-AHd6sMQfcDJcEKsa5MuOfBZmEfJv-NaQa_lqHo38lwOsWlV4TO523w2RUmE2j3e1NcwNJ_FSDRZKRQlrt3JfVhp4pNanNm_AKTaBeKwlM0Un6J2hCY5s2JxCgKGXROdG2PWzhslIAhLFepGJYtf1XpxN978ptd9CaxrJg5sZb-m3A00OevyE_vbzcB5y_ICwJuN-lp7Vz-_o63UxkH_jnCrVXpxkcLKd55mvQsBYg2LIYoKYrKPUTpcSLr5dRsCAYbvWpN_rb_5Etyw6lLirPn-eRADaEu61hqSUo5i5vFhgKKkFDK0nXEQSza_F7XrgbzakUCN5WeOO7Xv",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVjhZQ0SFGiperQviOmo0EK1C0IztrF0S-EdQ5zQAGs4okgc5Mw_7D_MHfCEPjlpNiybxSvgVnsGaQUuD8oldih8wEwSPbj_H49YVQEOqbnmbfTxrB877pUOhfCIWVI8hQxaCbTFCVZkoUD01vgpYBmTtZ9dTi-KiGESsMjwMAx4ogFhUn9XIl0A3JIK7_Q-8VY8uIAuoB5BrE5aGU70Q7uI7ZCAW7lw2umZPyQg-gSoeziFXlvGr22PG4INedsbZPjoqQCM16JMHpvf3401NBxlsqQHYxDcqlDwjLAuMVufsO6dxTg0VG_-o98bmaO9GsF94hw9sOe6UbZNPG-ehwrRsI4veBzXUWPQcWNzwsR_pTLKtASQcD0pKgtdTqmEKkIL5-jM0KqlKDzPco3cf7wclVMInwG79Tbh8hwLeLHsF38t_kU49qtJwvNw76SFZlCJFEl-J62A_lI5lByUZFzYIUoyhotWlFx_eVS3QYtvNtZbGrPTZQT0YWk7tFLqjMTrXg1aSKl_087gaustgLb03_Gl4RW91Js9JwdUwybn5puj0Kd3SATX6TtvDdl71GGPypJ95tADwbqnSvSQrBSXS_BJJobU0fcMsNPCxBdzyOKDZvb9Ln1R17WZkn2E1ogzHCTqgZTqao4fISytia7ueKVI8blNNraMJXXS_YlgLpY5pFOI9tbJ4Cg2PzB_eOH12q0uldWH7nsYs1KGGWErzwP28VD0I6LxXHmnn6ImIRU0X-AHd6sMQfcDJcEKsa5MuOfBZmEfJv-NaQa_lqHo38lwOsWlV4TO523w2RUmE2j3e1NcwNJ_FSDRZKRQlrt3JfVhp4pNanNm_AKTaBeKwlM0Un6J2hCY5s2JxCgKGXROdG2PWzhslIAhLFepGJYtf1XpxN978ptd9CaxrJg5sZb-m3A00OevyE_vbzcB5y_ICwJuN-lp7Vz-_o63UxkH_jnCrVXpxkcLKd55mvQsBYg2LIYoKYrKPUTpcSLr5dRsCAYbvWpN_rb_5Etyw6lLirPn-eRADaEu61hqSUo5i5vFhgKKkFDK0nXEQSza_F7XrgbzakUCN5WeOO7Xv",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1498.09,
            "totalPrice": 1295
          },
          {
            "allPassengerTotalPrice": 1295,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G00fiDtXvGycSoRgtNH5sp8F0Ycw68nReOAhDCAGzF64YKyB09c00Ki0W5kX64y30JKH6EzfSv38o2mtP6eeeXBm6QcxlGDGQIx4terJ-rQDpvR3Ac3R9x7mTyfEseQCX6L4yMmfNpD5UyWq1i8bnzHZXEsfTlv4WGKmSwA1rtDe3TPhAQ8ZI-49Gy8uoA33pASR0n7ZphdmttEwRru16q_m2QO8S6aVVKyQVi3SquDDe7-y8Ut9xnUOgZPXVUqPkM2YI5n13xA1EFF9paidzsMXogYm0STRUXS7G8buby-QnlKsv9s4otQ7-OEkWBoFUMboRmodV5uQa5Xncm25xcwcCEpWCCrhXCkLZc2Xw7GuNPSUSbFLe444TC3r_nyd2ty7gSU5jsxTuDn0vheoeSH6fqNxqPLk-_wej4YS5avMCr3KHoMbxLrbdXjpHuZ4pXOGCy7pQCMYBka-QxIpRZ-mh5TRMjo1PKlzbVIL7v8I9uzxL9ftZeVmnPmN7V_xusn8IFirN9ANnZrqOWTsOOl-fycov4nZMY6kAZS46afAFZ87qxpiNRJd2LbQ51dr0Xr59Sv-u4QjvhtksrhoBixNIEtFEWHm_KtVWGogycNKmRNNsJhRL5fSme9Uc4AKNgQ_nyL3XwWLA3gaYNqhw2Bfv3ZHLts7MMvemp5umAstf2v2UiP1OJOFHby3FtGrFVK8JZAR3ENQVI7Eop2JobFp2queHG8zS_HhKONJAt7HqNerQbjeL1lw3AhXvQT8CJRTXIZr4_UVulbWJl0SF72hBSm5hugyCZ42KGAvis2PpF-3nL7Bto7ns4MonD-A7XBvokinFF9q1c1E4iVrIdn0vkMPHWD9zE3NGQ2RL1y2QERdMssvaF0RRC5wzJKiTr9sWBNAPE47pMyUwqKvS61hiLCeZBVrmihPrJ5BtjMFjDfX9BqZfcBNwNu8abKfDqMKtwsjzNPPjZx2EcPTXYYc6jE-Zhq7tS52bze-NkHHRMwvOAwP8N2V2HQ2U85r3TcNfSRSIQZNxi78iLyq4toTUVz53Td-zWvblY0MW9SQ=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G00fiDtXvGycSoRgtNH5sp8F0Ycw68nReOAhDCAGzF64YKyB09c00Ki0W5kX64y30JKH6EzfSv38o2mtP6eeeXBm6QcxlGDGQIx4terJ-rQDpvR3Ac3R9x7mTyfEseQCX6L4yMmfNpD5UyWq1i8bnzHZXEsfTlv4WGKmSwA1rtDe3TPhAQ8ZI-49Gy8uoA33pASR0n7ZphdmttEwRru16q_m2QO8S6aVVKyQVi3SquDDe7-y8Ut9xnUOgZPXVUqPkM2YI5n13xA1EFF9paidzsMXogYm0STRUXS7G8buby-QnlKsv9s4otQ7-OEkWBoFUMboRmodV5uQa5Xncm25xcwcCEpWCCrhXCkLZc2Xw7GuNPSUSbFLe444TC3r_nyd2ty7gSU5jsxTuDn0vheoeSH6fqNxqPLk-_wej4YS5avMCr3KHoMbxLrbdXjpHuZ4pXOGCy7pQCMYBka-QxIpRZ-mh5TRMjo1PKlzbVIL7v8I9uzxL9ftZeVmnPmN7V_xusn8IFirN9ANnZrqOWTsOOl-fycov4nZMY6kAZS46afAFZ87qxpiNRJd2LbQ51dr0Xr59Sv-u4QjvhtksrhoBixNIEtFEWHm_KtVWGogycNKmRNNsJhRL5fSme9Uc4AKNgQ_nyL3XwWLA3gaYNqhw2Bfv3ZHLts7MMvemp5umAstf2v2UiP1OJOFHby3FtGrFVK8JZAR3ENQVI7Eop2JobFp2queHG8zS_HhKONJAt7HqNerQbjeL1lw3AhXvQT8CJRTXIZr4_UVulbWJl0SF72hBSm5hugyCZ42KGAvis2PpF-3nL7Bto7ns4MonD-A7XBvokinFF9q1c1E4iVrIdn0vkMPHWD9zE3NGQ2RL1y2QERdMssvaF0RRC5wzJKiTr9sWBNAPE47pMyUwqKvS61hiLCeZBVrmihPrJ5BtjMFjDfX9BqZfcBNwNu8abKfDqMKtwsjzNPPjZx2EcPTXYYc6jE-Zhq7tS52bze-NkHHRMwvOAwP8N2V2HQ2U85r3TcNfSRSIQZNxi78iLyq4toTUVz53Td-zWvblY0MW9SQ=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G00fiDtXvGycSoRgtNH5sp8F0Ycw68nReOAhDCAGzF64YKyB09c00Ki0W5kX64y30JKH6EzfSv38o2mtP6eeeXBm6QcxlGDGQIx4terJ-rQDpvR3Ac3R9x7mTyfEseQCX6L4yMmfNpD5UyWq1i8bnzHZXEsfTlv4WGKmSwA1rtDe3TPhAQ8ZI-49Gy8uoA33pASR0n7ZphdmttEwRru16q_m2QO8S6aVVKyQVi3SquDDe7-y8Ut9xnUOgZPXVUqPkM2YI5n13xA1EFF9paidzsMXogYm0STRUXS7G8buby-QnlKsv9s4otQ7-OEkWBoFUMboRmodV5uQa5Xncm25xcwcCEpWCCrhXCkLZc2Xw7GuNPSUSbFLe444TC3r_nyd2ty7gSU5jsxTuDn0vheoeSH6fqNxqPLk-_wej4YS5avMCr3KHoMbxLrbdXjpHuZ4pXOGCy7pQCMYBka-QxIpRZ-mh5TRMjo1PKlzbVIL7v8I9uzxL9ftZeVmnPmN7V_xusn8IFirN9ANnZrqOWTsOOl-fycov4nZMY6kAZS46afAFZ87qxpiNRJd2LbQ51dr0Xr59Sv-u4QjvhtksrhoBixNIEtFEWHm_KtVWGogycNKmRNNsJhRL5fSme9Uc4AKNgQ_nyL3XwWLA3gaYNqhw2Bfv3ZHLts7MMvemp5umAstf2v2UiP1OJOFHby3FtGrFVK8JZAR3ENQVI7Eop2JobFp2queHG8zS_HhKONJAt7HqNerQbjeL1lw3AhXvQT8CJRTXIZr4_UVulbWJl0SF72hBSm5hugyCZ42KGAvis2PpF-3nL7Bto7ns4MonD-A7XBvokinFF9q1c1E4iVrIdn0vkMPHWD9zE3NGQ2RL1y2QERdMssvaF0RRC5wzJKiTr9sWBNAPE47pMyUwqKvS61hiLCeZBVrmihPrJ5BtjMFjDfX9BqZfcBNwNu8abKfDqMKtwsjzNPPjZx2EcPTXYYc6jE-Zhq7tS52bze-NkHHRMwvOAwP8N2V2HQ2U85r3TcNfSRSIQZNxi78iLyq4toTUVz53Td-zWvblY0MW9SQ=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1498.75,
            "totalPrice": 1295
          },
          {
            "allPassengerTotalPrice": 1302,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLFdTc2FvqVUGlfcBKap8gowLY37bEbyJvYqKkl6SLL_2HaJqD1SKa4TVqDdRbcm8fwAlJwzDuiOhvYCn0PPfEcOwTxXyQh2vfJc2af0f6pGWc5GLCarwMz4FYsbaho8tTH9UF4I9FURQnnODePxUiGxm5pPfkbX5C9LEqkTr68sKclSGtSRgi_2VBO2CavCkp6USTppgrUUi59Nu4KBVrYf4vuu47YGFvT0kJ80iPu5iwDVfw39jFT3mmXnAMLDWAaQgURnRmi-URGN03fSruoA1qhVORmSrCZ6_ceQPjowp6MDwybS6rBVHTmN3PfoR2nULOsTXGXzYYrI1BeIAkluVOfClLQWCDDeXSpfMIUWVWm9kiB7N4vsU7h0NG_ymz_03JrqQGR9tPB7H6QFnlK1b0waCVm7AxpJ2VjwH8am1i1g2_aRUgdk51QwBmD0rWGX22LRKhI5xmBhRHvBoGj7DoE7BdpI_nWAE8Q9hJEqSmeQHgE7mB8l050xy0u31dXwn_JFO6ouabZQKb9QIGtKtbOc5jFTeZaPfhRLsBzdn-GPFSMEGL5hLPxgwtzeDluUHZ4stP2Sd7BqEn1VC0Pc_FFzJl0hlpkya_SyW_zd_-JM8MEmEWNg8oZebz3ZudeC-RBU6pAkjOxmJ744pmGNyUBJjMn3yx_KywFv6hGiQDxtqqOPH1_kuyulTEHg-ioWnwl99ahanpS8-myUH7VCf3_FMpDzHpQyA6GQCDP5AnHW1IBq1CBu-8v2HuLMQs__Dt9gqJUDNPMb5dSBPi5BRTKyhy_UmU0-1QrbJP2TQfnVDMtOY4TL92usBhrouoYKgEqJVRIBFWoqVn3pBVFCLExLbb-xNQ_QlGLq4K9BAd6uiyKFwRDAUrl861AWkDk6pcbz96I2aEa0A-9CmhsL5GSMvEpJpLiv4YxgUtKCKt5zqPIQ_jAAY0SQxRFLIfh5V9aXdbmL3Qe3UaMmb0LE_RKqlDbtZr25BFAsixj7uTHNTNtb31TPgGY3UBBd7dlbwDuNVGkNdNcgyukxGAqy6OvvFb9K8rzAj5PoRDW4=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLFdTc2FvqVUGlfcBKap8gowLY37bEbyJvYqKkl6SLL_2HaJqD1SKa4TVqDdRbcm8fwAlJwzDuiOhvYCn0PPfEcOwTxXyQh2vfJc2af0f6pGWc5GLCarwMz4FYsbaho8tTH9UF4I9FURQnnODePxUiGxm5pPfkbX5C9LEqkTr68sKclSGtSRgi_2VBO2CavCkp6USTppgrUUi59Nu4KBVrYf4vuu47YGFvT0kJ80iPu5iwDVfw39jFT3mmXnAMLDWAaQgURnRmi-URGN03fSruoA1qhVORmSrCZ6_ceQPjowp6MDwybS6rBVHTmN3PfoR2nULOsTXGXzYYrI1BeIAkluVOfClLQWCDDeXSpfMIUWVWm9kiB7N4vsU7h0NG_ymz_03JrqQGR9tPB7H6QFnlK1b0waCVm7AxpJ2VjwH8am1i1g2_aRUgdk51QwBmD0rWGX22LRKhI5xmBhRHvBoGj7DoE7BdpI_nWAE8Q9hJEqSmeQHgE7mB8l050xy0u31dXwn_JFO6ouabZQKb9QIGtKtbOc5jFTeZaPfhRLsBzdn-GPFSMEGL5hLPxgwtzeDluUHZ4stP2Sd7BqEn1VC0Pc_FFzJl0hlpkya_SyW_zd_-JM8MEmEWNg8oZebz3ZudeC-RBU6pAkjOxmJ744pmGNyUBJjMn3yx_KywFv6hGiQDxtqqOPH1_kuyulTEHg-ioWnwl99ahanpS8-myUH7VCf3_FMpDzHpQyA6GQCDP5AnHW1IBq1CBu-8v2HuLMQs__Dt9gqJUDNPMb5dSBPi5BRTKyhy_UmU0-1QrbJP2TQfnVDMtOY4TL92usBhrouoYKgEqJVRIBFWoqVn3pBVFCLExLbb-xNQ_QlGLq4K9BAd6uiyKFwRDAUrl861AWkDk6pcbz96I2aEa0A-9CmhsL5GSMvEpJpLiv4YxgUtKCKt5zqPIQ_jAAY0SQxRFLIfh5V9aXdbmL3Qe3UaMmb0LE_RKqlDbtZr25BFAsixj7uTHNTNtb31TPgGY3UBBd7dlbwDuNVGkNdNcgyukxGAqy6OvvFb9K8rzAj5PoRDW4=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLFdTc2FvqVUGlfcBKap8gowLY37bEbyJvYqKkl6SLL_2HaJqD1SKa4TVqDdRbcm8fwAlJwzDuiOhvYCn0PPfEcOwTxXyQh2vfJc2af0f6pGWc5GLCarwMz4FYsbaho8tTH9UF4I9FURQnnODePxUiGxm5pPfkbX5C9LEqkTr68sKclSGtSRgi_2VBO2CavCkp6USTppgrUUi59Nu4KBVrYf4vuu47YGFvT0kJ80iPu5iwDVfw39jFT3mmXnAMLDWAaQgURnRmi-URGN03fSruoA1qhVORmSrCZ6_ceQPjowp6MDwybS6rBVHTmN3PfoR2nULOsTXGXzYYrI1BeIAkluVOfClLQWCDDeXSpfMIUWVWm9kiB7N4vsU7h0NG_ymz_03JrqQGR9tPB7H6QFnlK1b0waCVm7AxpJ2VjwH8am1i1g2_aRUgdk51QwBmD0rWGX22LRKhI5xmBhRHvBoGj7DoE7BdpI_nWAE8Q9hJEqSmeQHgE7mB8l050xy0u31dXwn_JFO6ouabZQKb9QIGtKtbOc5jFTeZaPfhRLsBzdn-GPFSMEGL5hLPxgwtzeDluUHZ4stP2Sd7BqEn1VC0Pc_FFzJl0hlpkya_SyW_zd_-JM8MEmEWNg8oZebz3ZudeC-RBU6pAkjOxmJ744pmGNyUBJjMn3yx_KywFv6hGiQDxtqqOPH1_kuyulTEHg-ioWnwl99ahanpS8-myUH7VCf3_FMpDzHpQyA6GQCDP5AnHW1IBq1CBu-8v2HuLMQs__Dt9gqJUDNPMb5dSBPi5BRTKyhy_UmU0-1QrbJP2TQfnVDMtOY4TL92usBhrouoYKgEqJVRIBFWoqVn3pBVFCLExLbb-xNQ_QlGLq4K9BAd6uiyKFwRDAUrl861AWkDk6pcbz96I2aEa0A-9CmhsL5GSMvEpJpLiv4YxgUtKCKt5zqPIQ_jAAY0SQxRFLIfh5V9aXdbmL3Qe3UaMmb0LE_RKqlDbtZr25BFAsixj7uTHNTNtb31TPgGY3UBBd7dlbwDuNVGkNdNcgyukxGAqy6OvvFb9K8rzAj5PoRDW4=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1504.44,
            "totalPrice": 1302
          },
          {
            "allPassengerTotalPrice": 1304,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqBCdDrYESA14rmndbNFkezA6WtUU1Adxd-KzlgG-f6XVQkW1oYx8VRmlf1PwGM1Kv1CcOG_HWar_huaG3z8mLYkBsapWlSicrBwTZV7QQslepTW0d2dc_KE83mE5jq4IVVf4kltDLLBeJGdTLjbR1zAmzl4MPB7uylCdVEKQ1eQw4qp1F_1MJzMmK6xdpwKafGSWUTuS80x409JC6776eS0yTOWxDiJnxSK0WO7Jmd66Txa2NFjL6Ugys_ufFHdgK11zfzMbsmt_yB34FmitEljarrBw-ozaqy_2rac-QFBE23Q5eXTQLsS9t8-f7lZcG49wWrzUKCs-MgtrRmyC1pyWPVs540NiliDdRG3EO7FysijfFbJhcnIO13G8K2zyyteIyPA8ouGJtT-7eL3F5roMzWUp-pwBrjjO1En0fF1zSITy1ygVRD8OjIdg4IwBTrjcmtcAgdaBpdQTk2TGAMWUJKOSq-RwlVwDD0EEtCu34ocuMFpiSW7VyXhzPK-HFY8zFvG0l8pYFxeD6d8-kVd2mmxySEEBMhI3dJIXUyBOYpo8T-fKBwP1B2e-6B_XCm2ZbO_sFhww_780okugzJBTlDWQt4SBaFzQteNa7P9w8ttmscYIumLqg-QAl4wtjun3LJ5IMZBL6Qtx-cIkMmCpMQy6O_IGlHOnVX6qV14T6K3FDRMWf4X3tsOqEtNDAnCD3Vjaz-SRJCbSiPn5FVCkDfsYFL1Yt1L15VdNZkMx18Qg7THGC8ps83_ffiapqhQpBhNQKlDGUcURgByo9HCQD33wBHjhZ0f0wFK3hXzs4ISbqz6l1Bi5DTxCMi1HKa-u-nnUjZE-Vh3ixJRUXPOf9x2DieO3WzbnPc92S78_SRxgbigb2Sy_CBJxUl1qXBZenMqdXSogSkATOlY6xF06wXk1xq4h593kigGwJHcmd06XS-Hx3QyDZRe0z2upETjjv5D8YOzoemYvS5b25nNzdMmuxNv8TO-5b5twL2PpoPxkGQqkmKyICWyozCEWsp3zuRjiWUOjMle5oVAXPCClEzU2Nuj8qUoTpSwPMaRb68-sYy-BViEvAolFdoxHOBuPuzdgCco1Ffc9Z8O-tw==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqBCdDrYESA14rmndbNFkezA6WtUU1Adxd-KzlgG-f6XVQkW1oYx8VRmlf1PwGM1Kv1CcOG_HWar_huaG3z8mLYkBsapWlSicrBwTZV7QQslepTW0d2dc_KE83mE5jq4IVVf4kltDLLBeJGdTLjbR1zAmzl4MPB7uylCdVEKQ1eQw4qp1F_1MJzMmK6xdpwKafGSWUTuS80x409JC6776eS0yTOWxDiJnxSK0WO7Jmd66Txa2NFjL6Ugys_ufFHdgK11zfzMbsmt_yB34FmitEljarrBw-ozaqy_2rac-QFBE23Q5eXTQLsS9t8-f7lZcG49wWrzUKCs-MgtrRmyC1pyWPVs540NiliDdRG3EO7FysijfFbJhcnIO13G8K2zyyteIyPA8ouGJtT-7eL3F5roMzWUp-pwBrjjO1En0fF1zSITy1ygVRD8OjIdg4IwBTrjcmtcAgdaBpdQTk2TGAMWUJKOSq-RwlVwDD0EEtCu34ocuMFpiSW7VyXhzPK-HFY8zFvG0l8pYFxeD6d8-kVd2mmxySEEBMhI3dJIXUyBOYpo8T-fKBwP1B2e-6B_XCm2ZbO_sFhww_780okugzJBTlDWQt4SBaFzQteNa7P9w8ttmscYIumLqg-QAl4wtjun3LJ5IMZBL6Qtx-cIkMmCpMQy6O_IGlHOnVX6qV14T6K3FDRMWf4X3tsOqEtNDAnCD3Vjaz-SRJCbSiPn5FVCkDfsYFL1Yt1L15VdNZkMx18Qg7THGC8ps83_ffiapqhQpBhNQKlDGUcURgByo9HCQD33wBHjhZ0f0wFK3hXzs4ISbqz6l1Bi5DTxCMi1HKa-u-nnUjZE-Vh3ixJRUXPOf9x2DieO3WzbnPc92S78_SRxgbigb2Sy_CBJxUl1qXBZenMqdXSogSkATOlY6xF06wXk1xq4h593kigGwJHcmd06XS-Hx3QyDZRe0z2upETjjv5D8YOzoemYvS5b25nNzdMmuxNv8TO-5b5twL2PpoPxkGQqkmKyICWyozCEWsp3zuRjiWUOjMle5oVAXPCClEzU2Nuj8qUoTpSwPMaRb68-sYy-BViEvAolFdoxHOBuPuzdgCco1Ffc9Z8O-tw==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqBCdDrYESA14rmndbNFkezA6WtUU1Adxd-KzlgG-f6XVQkW1oYx8VRmlf1PwGM1Kv1CcOG_HWar_huaG3z8mLYkBsapWlSicrBwTZV7QQslepTW0d2dc_KE83mE5jq4IVVf4kltDLLBeJGdTLjbR1zAmzl4MPB7uylCdVEKQ1eQw4qp1F_1MJzMmK6xdpwKafGSWUTuS80x409JC6776eS0yTOWxDiJnxSK0WO7Jmd66Txa2NFjL6Ugys_ufFHdgK11zfzMbsmt_yB34FmitEljarrBw-ozaqy_2rac-QFBE23Q5eXTQLsS9t8-f7lZcG49wWrzUKCs-MgtrRmyC1pyWPVs540NiliDdRG3EO7FysijfFbJhcnIO13G8K2zyyteIyPA8ouGJtT-7eL3F5roMzWUp-pwBrjjO1En0fF1zSITy1ygVRD8OjIdg4IwBTrjcmtcAgdaBpdQTk2TGAMWUJKOSq-RwlVwDD0EEtCu34ocuMFpiSW7VyXhzPK-HFY8zFvG0l8pYFxeD6d8-kVd2mmxySEEBMhI3dJIXUyBOYpo8T-fKBwP1B2e-6B_XCm2ZbO_sFhww_780okugzJBTlDWQt4SBaFzQteNa7P9w8ttmscYIumLqg-QAl4wtjun3LJ5IMZBL6Qtx-cIkMmCpMQy6O_IGlHOnVX6qV14T6K3FDRMWf4X3tsOqEtNDAnCD3Vjaz-SRJCbSiPn5FVCkDfsYFL1Yt1L15VdNZkMx18Qg7THGC8ps83_ffiapqhQpBhNQKlDGUcURgByo9HCQD33wBHjhZ0f0wFK3hXzs4ISbqz6l1Bi5DTxCMi1HKa-u-nnUjZE-Vh3ixJRUXPOf9x2DieO3WzbnPc92S78_SRxgbigb2Sy_CBJxUl1qXBZenMqdXSogSkATOlY6xF06wXk1xq4h593kigGwJHcmd06XS-Hx3QyDZRe0z2upETjjv5D8YOzoemYvS5b25nNzdMmuxNv8TO-5b5twL2PpoPxkGQqkmKyICWyozCEWsp3zuRjiWUOjMle5oVAXPCClEzU2Nuj8qUoTpSwPMaRb68-sYy-BViEvAolFdoxHOBuPuzdgCco1Ffc9Z8O-tw==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1505.75,
            "totalPrice": 1304
          },
          {
            "allPassengerTotalPrice": 1319,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gamr_SLSjJ5xow50_QrRbPH3yBB3y7GHBr9PD46UUiOu5YYNSX4TlrgiZZmus3C6IoJ4l2Bkt7nWBTZ3WhAdVJ9gaditAjDy3AvYDB4flSLLpvZnFut_leBV6tcUoZnJHcQIzchJvgD8NlLEa0ydQ0HeUTVO7-y0KpYLI-8PzyZHQM-tg_QgGNwAyXILI2dwBwPjQpXiXJtCVjmWqqljlJYjS1groPqjl3-U9GeNGl2gPKMn_rpeNGfzNe8343U-pqoTG6OTHdAYr-DEYZXg5uxU1_wzDezGtHH5PT1UBJi0EkDnv28w_-tW0-FQ-2JxgDAKoahs851J5qQKPSaNbgeM6NW72M0kvyM9bkRMtXw_0u7gf0DlpP1JXfaXLFIMRXvy9JHq0rdqpHNsuEd8ywJQ42TNW-0_1V3aecxTD200ydoYRwrrDSTPEmM3wNvIZgStvg5uJt2oZmgieHoUUWhFVTErIDa8u1L_nHsIJshDkFaxqpcSkTbG8DOdA6apYSO4EAVgZq6N0jexct0GRGAqhlphBw7OhkmN-23zTOL8xCDBK2cSRvXvXsVylSOrqm9lxpVs8FQuKZZxYUTCKbDLx_K3pHRrzdfpZ0KyomHuXYDRNsb90TnOh2iglGXH8hceX7tudNOV3EiRq23joEzU5x88ywt0llYg_xw3Ks8ZgXSgtNYKuNlzY9YvSv5BBzLGQXmsebHFws8xsFJb2RXLU1HqLAFbO9RB6zKDixiz3WH3RJCLSMCsT8Fq2uS5DDhXJTQ5CgaSPKBXGma9F7i-3G5dbjM8OndXVe8VtiRlI-rokxSDga94s47vyE6WjndqR9VMxKYGh5JM4yLuelojUDvOuBAPrK0Zc5Anx7-mFi0c1kfcbhpcUIrztUpuIpW22GZcpMO5ZeRmVUZafvzCGVyExG4ku-6wISZzVFWIf6NYQsjewUKHsyaXV6r_Njoms1ry3n6Uj9yydM15_BD5DqGYvXzN98TSqbhnkRn6BJxEU49yptcfgJqWRDCOM2EKFNJVKuku34a9w27x7P-nh7NxN64Eci8Dnu2p3_Sw=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gamr_SLSjJ5xow50_QrRbPH3yBB3y7GHBr9PD46UUiOu5YYNSX4TlrgiZZmus3C6IoJ4l2Bkt7nWBTZ3WhAdVJ9gaditAjDy3AvYDB4flSLLpvZnFut_leBV6tcUoZnJHcQIzchJvgD8NlLEa0ydQ0HeUTVO7-y0KpYLI-8PzyZHQM-tg_QgGNwAyXILI2dwBwPjQpXiXJtCVjmWqqljlJYjS1groPqjl3-U9GeNGl2gPKMn_rpeNGfzNe8343U-pqoTG6OTHdAYr-DEYZXg5uxU1_wzDezGtHH5PT1UBJi0EkDnv28w_-tW0-FQ-2JxgDAKoahs851J5qQKPSaNbgeM6NW72M0kvyM9bkRMtXw_0u7gf0DlpP1JXfaXLFIMRXvy9JHq0rdqpHNsuEd8ywJQ42TNW-0_1V3aecxTD200ydoYRwrrDSTPEmM3wNvIZgStvg5uJt2oZmgieHoUUWhFVTErIDa8u1L_nHsIJshDkFaxqpcSkTbG8DOdA6apYSO4EAVgZq6N0jexct0GRGAqhlphBw7OhkmN-23zTOL8xCDBK2cSRvXvXsVylSOrqm9lxpVs8FQuKZZxYUTCKbDLx_K3pHRrzdfpZ0KyomHuXYDRNsb90TnOh2iglGXH8hceX7tudNOV3EiRq23joEzU5x88ywt0llYg_xw3Ks8ZgXSgtNYKuNlzY9YvSv5BBzLGQXmsebHFws8xsFJb2RXLU1HqLAFbO9RB6zKDixiz3WH3RJCLSMCsT8Fq2uS5DDhXJTQ5CgaSPKBXGma9F7i-3G5dbjM8OndXVe8VtiRlI-rokxSDga94s47vyE6WjndqR9VMxKYGh5JM4yLuelojUDvOuBAPrK0Zc5Anx7-mFi0c1kfcbhpcUIrztUpuIpW22GZcpMO5ZeRmVUZafvzCGVyExG4ku-6wISZzVFWIf6NYQsjewUKHsyaXV6r_Njoms1ry3n6Uj9yydM15_BD5DqGYvXzN98TSqbhnkRn6BJxEU49yptcfgJqWRDCOM2EKFNJVKuku34a9w27x7P-nh7NxN64Eci8Dnu2p3_Sw=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gamr_SLSjJ5xow50_QrRbPH3yBB3y7GHBr9PD46UUiOu5YYNSX4TlrgiZZmus3C6IoJ4l2Bkt7nWBTZ3WhAdVJ9gaditAjDy3AvYDB4flSLLpvZnFut_leBV6tcUoZnJHcQIzchJvgD8NlLEa0ydQ0HeUTVO7-y0KpYLI-8PzyZHQM-tg_QgGNwAyXILI2dwBwPjQpXiXJtCVjmWqqljlJYjS1groPqjl3-U9GeNGl2gPKMn_rpeNGfzNe8343U-pqoTG6OTHdAYr-DEYZXg5uxU1_wzDezGtHH5PT1UBJi0EkDnv28w_-tW0-FQ-2JxgDAKoahs851J5qQKPSaNbgeM6NW72M0kvyM9bkRMtXw_0u7gf0DlpP1JXfaXLFIMRXvy9JHq0rdqpHNsuEd8ywJQ42TNW-0_1V3aecxTD200ydoYRwrrDSTPEmM3wNvIZgStvg5uJt2oZmgieHoUUWhFVTErIDa8u1L_nHsIJshDkFaxqpcSkTbG8DOdA6apYSO4EAVgZq6N0jexct0GRGAqhlphBw7OhkmN-23zTOL8xCDBK2cSRvXvXsVylSOrqm9lxpVs8FQuKZZxYUTCKbDLx_K3pHRrzdfpZ0KyomHuXYDRNsb90TnOh2iglGXH8hceX7tudNOV3EiRq23joEzU5x88ywt0llYg_xw3Ks8ZgXSgtNYKuNlzY9YvSv5BBzLGQXmsebHFws8xsFJb2RXLU1HqLAFbO9RB6zKDixiz3WH3RJCLSMCsT8Fq2uS5DDhXJTQ5CgaSPKBXGma9F7i-3G5dbjM8OndXVe8VtiRlI-rokxSDga94s47vyE6WjndqR9VMxKYGh5JM4yLuelojUDvOuBAPrK0Zc5Anx7-mFi0c1kfcbhpcUIrztUpuIpW22GZcpMO5ZeRmVUZafvzCGVyExG4ku-6wISZzVFWIf6NYQsjewUKHsyaXV6r_Njoms1ry3n6Uj9yydM15_BD5DqGYvXzN98TSqbhnkRn6BJxEU49yptcfgJqWRDCOM2EKFNJVKuku34a9w27x7P-nh7NxN64Eci8Dnu2p3_Sw=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1518.09,
            "totalPrice": 1319
          },
          {
            "allPassengerTotalPrice": 1328,
            "legs": [
              {
                "airlines": [
                  "DE",
                  "LH"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "15:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIAYf6oiywrC23QgP9KS5Ou1UmItETGAKpTqSfrXj0ZWumP--yqIvk9FIH584E3dvv_sWgkTU63GcbW8mmjkfcKSg9KJaTINWUUY3pwXdB69rgkqMKxfr9ClYFlKsPEVrDBb0P2yH1blwQhJrm0kex_wzIWLaellqDUhiELUe7aiBxQS9C6_foK_ZhZfsChn6apjBl6AMCq4greJtx8wAbaNiBkJP4l-iXbH7quw6xs4jRXnphcXGczlUchnaCvxwCPRpFe75GkvO_SWOMDn9geqzKuZRse_630J2ujF3odboxksqfRPFijwCkVzjwgjO5ADum1-hZ_SRBKRpaOMduenhI9le2e-nZ4dNwK8Z1ZBsTwXHe_jELE_b-Bf7PulZ5-wCrS056F6wOs6tSmZutTydTsKywvAJ0dZXURltOIjCox3-3iyA-D8CX51AeWSfXjG40HwOputR28Yy0ufFCoWElgfAwTqi7LPphDyJkgdfW5ZuPJpOiimxp5y7_oz1dZCAUVl5MB_69M6B5N-Me1uP8sD1sPbmEIFopDnk6SR_9S7jDnzG7esDQHVxGB5cHhSJfYncNoCPgNFXjI8WsL9-4Ujg1Z-fVKPNUNoluioat9giLrmrLE7Fpsbf1qUEXrmwZXStyYowutMAMmxGBShK4xbkNgmUKUvPSUsil4D29KV2WrICjIBdGIoUqkZUwbz0167J5KAH6xIQ1E7HXIpjRSmtH2kGW7u2oY8fjs3Uw9IMXR8-wGXS-oFDGKKNbaWpoh4o3HO0yf4kUc7fbl_wFpXBv_sluP6ZoSygxNHiowj7vEdPKPA8wYWqhP9rAc9uESKo-fZSJff5wgoVvGgBbwm6qcGRW2Ycaluz4jmGxen82oPgJc6roIk20OaNUDmT1_07CBK8RXlY_2Qs1ChcTkNaAKDdCDVe9WmSRSnsp8z2SUas49_3v5mQJ7kqObKmo-4ON4qQmJzMOMXVqJfZ5nYDVdt-YSIuiF2d5bK_bB7yGhYA822SaKyfwdY9aEfOR0CwhD9t4uF07_SfHksH4SpiTR9jU6Ne-15q42g=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "16:35",
                "destination": "BCN",
                "duration": "13h50m",
                "routes": [
                  {
                    "airline": "DE",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "FRA",
                    "cityFrom": "San Francisco",
                    "cityTo": "Frankfurt",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 2097,
                    "flyFrom": "SFO",
                    "flyTo": "FRA",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_0",
                    "local_arrival": "2024-09-15T12:20:00.000Z",
                    "local_departure": "2024-09-14T16:35:00.000Z",
                    "operating_carrier": "DE",
                    "operating_flight_no": "2097",
                    "return": 0,
                    "utc_arrival": "2024-09-15T10:20:00.000Z",
                    "utc_departure": "2024-09-14T23:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "LH",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "FRA",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Frankfurt",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c000058ce8c8b",
                    "equipment": null,
                    "fare_basis": "SPO",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 1128,
                    "flyFrom": "FRA",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c000058ce8c8b_1",
                    "local_arrival": "2024-09-15T15:25:00.000Z",
                    "local_departure": "2024-09-15T13:20:00.000Z",
                    "operating_carrier": "LH",
                    "operating_flight_no": "1128",
                    "return": 0,
                    "utc_arrival": "2024-09-15T13:25:00.000Z",
                    "utc_departure": "2024-09-15T11:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIAYf6oiywrC23QgP9KS5Ou1UmItETGAKpTqSfrXj0ZWumP--yqIvk9FIH584E3dvv_sWgkTU63GcbW8mmjkfcKSg9KJaTINWUUY3pwXdB69rgkqMKxfr9ClYFlKsPEVrDBb0P2yH1blwQhJrm0kex_wzIWLaellqDUhiELUe7aiBxQS9C6_foK_ZhZfsChn6apjBl6AMCq4greJtx8wAbaNiBkJP4l-iXbH7quw6xs4jRXnphcXGczlUchnaCvxwCPRpFe75GkvO_SWOMDn9geqzKuZRse_630J2ujF3odboxksqfRPFijwCkVzjwgjO5ADum1-hZ_SRBKRpaOMduenhI9le2e-nZ4dNwK8Z1ZBsTwXHe_jELE_b-Bf7PulZ5-wCrS056F6wOs6tSmZutTydTsKywvAJ0dZXURltOIjCox3-3iyA-D8CX51AeWSfXjG40HwOputR28Yy0ufFCoWElgfAwTqi7LPphDyJkgdfW5ZuPJpOiimxp5y7_oz1dZCAUVl5MB_69M6B5N-Me1uP8sD1sPbmEIFopDnk6SR_9S7jDnzG7esDQHVxGB5cHhSJfYncNoCPgNFXjI8WsL9-4Ujg1Z-fVKPNUNoluioat9giLrmrLE7Fpsbf1qUEXrmwZXStyYowutMAMmxGBShK4xbkNgmUKUvPSUsil4D29KV2WrICjIBdGIoUqkZUwbz0167J5KAH6xIQ1E7HXIpjRSmtH2kGW7u2oY8fjs3Uw9IMXR8-wGXS-oFDGKKNbaWpoh4o3HO0yf4kUc7fbl_wFpXBv_sluP6ZoSygxNHiowj7vEdPKPA8wYWqhP9rAc9uESKo-fZSJff5wgoVvGgBbwm6qcGRW2Ycaluz4jmGxen82oPgJc6roIk20OaNUDmT1_07CBK8RXlY_2Qs1ChcTkNaAKDdCDVe9WmSRSnsp8z2SUas49_3v5mQJ7kqObKmo-4ON4qQmJzMOMXVqJfZ5nYDVdt-YSIuiF2d5bK_bB7yGhYA822SaKyfwdY9aEfOR0CwhD9t4uF07_SfHksH4SpiTR9jU6Ne-15q42g=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c000058ce8c8b_0%7C0f9c01af4e0c000058ce8c8b_1%7C14140a224e16000067044a57_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIAYf6oiywrC23QgP9KS5Ou1UmItETGAKpTqSfrXj0ZWumP--yqIvk9FIH584E3dvv_sWgkTU63GcbW8mmjkfcKSg9KJaTINWUUY3pwXdB69rgkqMKxfr9ClYFlKsPEVrDBb0P2yH1blwQhJrm0kex_wzIWLaellqDUhiELUe7aiBxQS9C6_foK_ZhZfsChn6apjBl6AMCq4greJtx8wAbaNiBkJP4l-iXbH7quw6xs4jRXnphcXGczlUchnaCvxwCPRpFe75GkvO_SWOMDn9geqzKuZRse_630J2ujF3odboxksqfRPFijwCkVzjwgjO5ADum1-hZ_SRBKRpaOMduenhI9le2e-nZ4dNwK8Z1ZBsTwXHe_jELE_b-Bf7PulZ5-wCrS056F6wOs6tSmZutTydTsKywvAJ0dZXURltOIjCox3-3iyA-D8CX51AeWSfXjG40HwOputR28Yy0ufFCoWElgfAwTqi7LPphDyJkgdfW5ZuPJpOiimxp5y7_oz1dZCAUVl5MB_69M6B5N-Me1uP8sD1sPbmEIFopDnk6SR_9S7jDnzG7esDQHVxGB5cHhSJfYncNoCPgNFXjI8WsL9-4Ujg1Z-fVKPNUNoluioat9giLrmrLE7Fpsbf1qUEXrmwZXStyYowutMAMmxGBShK4xbkNgmUKUvPSUsil4D29KV2WrICjIBdGIoUqkZUwbz0167J5KAH6xIQ1E7HXIpjRSmtH2kGW7u2oY8fjs3Uw9IMXR8-wGXS-oFDGKKNbaWpoh4o3HO0yf4kUc7fbl_wFpXBv_sluP6ZoSygxNHiowj7vEdPKPA8wYWqhP9rAc9uESKo-fZSJff5wgoVvGgBbwm6qcGRW2Ycaluz4jmGxen82oPgJc6roIk20OaNUDmT1_07CBK8RXlY_2Qs1ChcTkNaAKDdCDVe9WmSRSnsp8z2SUas49_3v5mQJ7kqObKmo-4ON4qQmJzMOMXVqJfZ5nYDVdt-YSIuiF2d5bK_bB7yGhYA822SaKyfwdY9aEfOR0CwhD9t4uF07_SfHksH4SpiTR9jU6Ne-15q42g=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1526.45,
            "totalPrice": 1328
          },
          {
            "allPassengerTotalPrice": 1522,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2jwMhjd14jIO-ZitsgM-bzSGs_ApY4LdXfR6yVoNLJ8kDSZGQf8OsI3Y7G9O0fPz2ZWy5GDaMNB8mIIGI7V9TwI9w16VBJjDQNCI_JeZEAKBnHFGSd3jIID7GdIN-FRa6b_h1LH7hfV4ErEwBd05vybhPkrcPxucQIqZtrTd1r17muPprY_rZw1iOnGUYsfWffVbJGNIP9OAe7dM40yeHwKdq2zD2b66S5z_8AyWK-ofFN2vuXT4lvmqU7y9Rqo7hPRmuVfU5XXkYBVmz4nD3I3zonMzoIUnxpM8k4zKyX_fjTTcO3r3PPhUEekiRd8g9LoVL2FoQQ6OcvNj3kuvqUVYepeJyCQ8V9__b-iqKNtx0DtEeZ2muNTrlBGG53TWdWsbFmgrg7rOdF2G8q7jt2AMHstvkXYF2Ym2AZX0PVvwSmpIiH49foNejdz6uXx5OmZWO6Z8fSdHQvXrPLvlHWihERryu7MvDd9l9Ymnnxjq4nq-RoU8vXTIbM3WLyhTJMqmoWgoY6jRi9pitop01D2SEjlruej8rNUmIivqCDMSBm_8A7qB1C16kSw6kBDbDDhVzYCr33NONjqlp9RSeqdcAfM_uv13jxRAPKh5gQBBISEzvfm85QqbShHXKqqPazv0jnJGT_Ty22PKi6RJQfifWVw5gZY0EETzS8NiBrp0vXUVrX9ct2DgXHQLAQ-E2tDYFqghXfaMWj1pOQEL6izubqcdFarQ8NJPIZVU4SG6f4nykkYypAgVxiZTpqLS_dgCylGff3aoT_2HLVKvWQJQCYkp5QudFuIwXMRyD1Pi9DspEo8lp5SEfQsT4mMB73oUG0dGwLSeSvdMl2ZbSGnRw-8WwjLMkOqcca2KA8MpDu27AFQEkyg8XP_S4qrkkW9Hr1Nu4W15iPIvQbvAZ6DV4LjSvJjxfnqE9MVcUa76MXNRqkC4tIMoXQuGHMgw7qSB8V2d2DUGHQ89P2ar2oS8tWUtTJ7QtMqujUc-xGdkKV3iBcoc7muOFEMJsg37CwaEv57N21mAUuguwHEpT1MxZgBt3ToROflXW6GnP9n9Xk89HI5gJqbEm7jJk9bK",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e0c00001e3d8708",
                    "equipment": null,
                    "fare_basis": "VH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00001e3d8708_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-15T11:15:00.000Z",
                    "utc_departure": "2024-09-15T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00001e3d8708",
                    "equipment": null,
                    "fare_basis": "VH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00001e3d8708_1",
                    "local_arrival": "2024-09-15T16:55:00.000Z",
                    "local_departure": "2024-09-15T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:55:00.000Z",
                    "utc_departure": "2024-09-15T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2jwMhjd14jIO-ZitsgM-bzSGs_ApY4LdXfR6yVoNLJ8kDSZGQf8OsI3Y7G9O0fPz2ZWy5GDaMNB8mIIGI7V9TwI9w16VBJjDQNCI_JeZEAKBnHFGSd3jIID7GdIN-FRa6b_h1LH7hfV4ErEwBd05vybhPkrcPxucQIqZtrTd1r17muPprY_rZw1iOnGUYsfWffVbJGNIP9OAe7dM40yeHwKdq2zD2b66S5z_8AyWK-ofFN2vuXT4lvmqU7y9Rqo7hPRmuVfU5XXkYBVmz4nD3I3zonMzoIUnxpM8k4zKyX_fjTTcO3r3PPhUEekiRd8g9LoVL2FoQQ6OcvNj3kuvqUVYepeJyCQ8V9__b-iqKNtx0DtEeZ2muNTrlBGG53TWdWsbFmgrg7rOdF2G8q7jt2AMHstvkXYF2Ym2AZX0PVvwSmpIiH49foNejdz6uXx5OmZWO6Z8fSdHQvXrPLvlHWihERryu7MvDd9l9Ymnnxjq4nq-RoU8vXTIbM3WLyhTJMqmoWgoY6jRi9pitop01D2SEjlruej8rNUmIivqCDMSBm_8A7qB1C16kSw6kBDbDDhVzYCr33NONjqlp9RSeqdcAfM_uv13jxRAPKh5gQBBISEzvfm85QqbShHXKqqPazv0jnJGT_Ty22PKi6RJQfifWVw5gZY0EETzS8NiBrp0vXUVrX9ct2DgXHQLAQ-E2tDYFqghXfaMWj1pOQEL6izubqcdFarQ8NJPIZVU4SG6f4nykkYypAgVxiZTpqLS_dgCylGff3aoT_2HLVKvWQJQCYkp5QudFuIwXMRyD1Pi9DspEo8lp5SEfQsT4mMB73oUG0dGwLSeSvdMl2ZbSGnRw-8WwjLMkOqcca2KA8MpDu27AFQEkyg8XP_S4qrkkW9Hr1Nu4W15iPIvQbvAZ6DV4LjSvJjxfnqE9MVcUa76MXNRqkC4tIMoXQuGHMgw7qSB8V2d2DUGHQ89P2ar2oS8tWUtTJ7QtMqujUc-xGdkKV3iBcoc7muOFEMJsg37CwaEv57N21mAUuguwHEpT1MxZgBt3ToROflXW6GnP9n9Xk89HI5gJqbEm7jJk9bK",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2jwMhjd14jIO-ZitsgM-bzSGs_ApY4LdXfR6yVoNLJ8kDSZGQf8OsI3Y7G9O0fPz2ZWy5GDaMNB8mIIGI7V9TwI9w16VBJjDQNCI_JeZEAKBnHFGSd3jIID7GdIN-FRa6b_h1LH7hfV4ErEwBd05vybhPkrcPxucQIqZtrTd1r17muPprY_rZw1iOnGUYsfWffVbJGNIP9OAe7dM40yeHwKdq2zD2b66S5z_8AyWK-ofFN2vuXT4lvmqU7y9Rqo7hPRmuVfU5XXkYBVmz4nD3I3zonMzoIUnxpM8k4zKyX_fjTTcO3r3PPhUEekiRd8g9LoVL2FoQQ6OcvNj3kuvqUVYepeJyCQ8V9__b-iqKNtx0DtEeZ2muNTrlBGG53TWdWsbFmgrg7rOdF2G8q7jt2AMHstvkXYF2Ym2AZX0PVvwSmpIiH49foNejdz6uXx5OmZWO6Z8fSdHQvXrPLvlHWihERryu7MvDd9l9Ymnnxjq4nq-RoU8vXTIbM3WLyhTJMqmoWgoY6jRi9pitop01D2SEjlruej8rNUmIivqCDMSBm_8A7qB1C16kSw6kBDbDDhVzYCr33NONjqlp9RSeqdcAfM_uv13jxRAPKh5gQBBISEzvfm85QqbShHXKqqPazv0jnJGT_Ty22PKi6RJQfifWVw5gZY0EETzS8NiBrp0vXUVrX9ct2DgXHQLAQ-E2tDYFqghXfaMWj1pOQEL6izubqcdFarQ8NJPIZVU4SG6f4nykkYypAgVxiZTpqLS_dgCylGff3aoT_2HLVKvWQJQCYkp5QudFuIwXMRyD1Pi9DspEo8lp5SEfQsT4mMB73oUG0dGwLSeSvdMl2ZbSGnRw-8WwjLMkOqcca2KA8MpDu27AFQEkyg8XP_S4qrkkW9Hr1Nu4W15iPIvQbvAZ6DV4LjSvJjxfnqE9MVcUa76MXNRqkC4tIMoXQuGHMgw7qSB8V2d2DUGHQ89P2ar2oS8tWUtTJ7QtMqujUc-xGdkKV3iBcoc7muOFEMJsg37CwaEv57N21mAUuguwHEpT1MxZgBt3ToROflXW6GnP9n9Xk89HI5gJqbEm7jJk9bK",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1698.56,
            "totalPrice": 1522
          },
          {
            "allPassengerTotalPrice": 1525,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQpuN3uLsFp2xwLV-fQuFFanEjqIqk2hrdV7EF7upjWOxWpI0QdtQ1BItGyYBACraQNqQ2K47llRcM7NuuNbs3BcWc-CFSazpOpAlq5zPghvN0PUZu-sFneSbWasOnD7I0T1UcmEGM9lWR35WTFprVdNVbvXbJFox0KHavzLO9xVklZruf4YF3UB76a3hL79xMgo3f4oI28MHGYxWKQe-DWUXTS-83ly2Qz0w96mi-QyJw2VYLS4IbBHKAN2vhWjN794nsqoGytQwCb_77r0HCGwsepTWttUODIdl1up7-aYgheVPtYX1k2EwMrECyU5acecB5bgwGE_HmVLYW0IMqSbAiT3SWZh3lEXtrLeP80WHYL3KYXRnjOdbXYWlYJ4jS9OO8027RYDl-LxpedCNDZiyZHp5jUOOKYuR4w-5uIC0JIGcpYwRcJ24tIUa0nPpuSagBajNKmVqjzmrqkzzTl8ZYkwdFs5D8npYeVEfSr2IoIsz9AU0OilCfwnxgBW0BtDkHQU0K78-R4JSxWQberaFqX3n4IYONZWNhbGQ8vsCn8l_xiZb-aR2TeNJUOHLjczub4Afkm4YGxTH2WY7_VynzcLHSpGptQL4zJme2_53BsNq94PaB9C1p-5jMWxESJhX-XTmNwdmCNY5V5UggLivQTk5HEwXq5bR4SdxJxRaJq3BzoTu0vXkPQoRrhrEuxb7kaoTzEHgoxkNZkbyS_Ex-VdQod9Q_cC0XKB34fVYP0tG2QG91qQMeRn9u3XhsszgfTYDfliTSGB4JVXqk62I8OZJBRNApbVbXcDgLc8JvenYpRS_iRZmPtKDs5xNrcUYOet2qPyFUkAg3Zh2uRiRxyaoFhs4e6HK7ZfTcnozmSSjmrvOUD5WGAkTFCjv-0rwyXo1G0gD9QeAjgzbZPNlwl-EZFsZvFa5uQJB082xUI7yeOxdYUC9R7w0k8uB7pkbLQDBecFRTS17Nqr488jHIcBqQyJ5Zy8TKzyYaBpiXwG3EQ-1KLfXZVDR8MW4CscsEgCTCZ1l9IyY5g085BHy6PSbFBZ2BSYpotM-XrB0t68Xyiqy8plLgLs7_NCA",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e0c00001e3d8708",
                    "equipment": null,
                    "fare_basis": "VH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00001e3d8708_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-15T11:15:00.000Z",
                    "utc_departure": "2024-09-15T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00001e3d8708",
                    "equipment": null,
                    "fare_basis": "VH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00001e3d8708_1",
                    "local_arrival": "2024-09-15T16:55:00.000Z",
                    "local_departure": "2024-09-15T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:55:00.000Z",
                    "utc_departure": "2024-09-15T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQpuN3uLsFp2xwLV-fQuFFanEjqIqk2hrdV7EF7upjWOxWpI0QdtQ1BItGyYBACraQNqQ2K47llRcM7NuuNbs3BcWc-CFSazpOpAlq5zPghvN0PUZu-sFneSbWasOnD7I0T1UcmEGM9lWR35WTFprVdNVbvXbJFox0KHavzLO9xVklZruf4YF3UB76a3hL79xMgo3f4oI28MHGYxWKQe-DWUXTS-83ly2Qz0w96mi-QyJw2VYLS4IbBHKAN2vhWjN794nsqoGytQwCb_77r0HCGwsepTWttUODIdl1up7-aYgheVPtYX1k2EwMrECyU5acecB5bgwGE_HmVLYW0IMqSbAiT3SWZh3lEXtrLeP80WHYL3KYXRnjOdbXYWlYJ4jS9OO8027RYDl-LxpedCNDZiyZHp5jUOOKYuR4w-5uIC0JIGcpYwRcJ24tIUa0nPpuSagBajNKmVqjzmrqkzzTl8ZYkwdFs5D8npYeVEfSr2IoIsz9AU0OilCfwnxgBW0BtDkHQU0K78-R4JSxWQberaFqX3n4IYONZWNhbGQ8vsCn8l_xiZb-aR2TeNJUOHLjczub4Afkm4YGxTH2WY7_VynzcLHSpGptQL4zJme2_53BsNq94PaB9C1p-5jMWxESJhX-XTmNwdmCNY5V5UggLivQTk5HEwXq5bR4SdxJxRaJq3BzoTu0vXkPQoRrhrEuxb7kaoTzEHgoxkNZkbyS_Ex-VdQod9Q_cC0XKB34fVYP0tG2QG91qQMeRn9u3XhsszgfTYDfliTSGB4JVXqk62I8OZJBRNApbVbXcDgLc8JvenYpRS_iRZmPtKDs5xNrcUYOet2qPyFUkAg3Zh2uRiRxyaoFhs4e6HK7ZfTcnozmSSjmrvOUD5WGAkTFCjv-0rwyXo1G0gD9QeAjgzbZPNlwl-EZFsZvFa5uQJB082xUI7yeOxdYUC9R7w0k8uB7pkbLQDBecFRTS17Nqr488jHIcBqQyJ5Zy8TKzyYaBpiXwG3EQ-1KLfXZVDR8MW4CscsEgCTCZ1l9IyY5g085BHy6PSbFBZ2BSYpotM-XrB0t68Xyiqy8plLgLs7_NCA",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQpuN3uLsFp2xwLV-fQuFFanEjqIqk2hrdV7EF7upjWOxWpI0QdtQ1BItGyYBACraQNqQ2K47llRcM7NuuNbs3BcWc-CFSazpOpAlq5zPghvN0PUZu-sFneSbWasOnD7I0T1UcmEGM9lWR35WTFprVdNVbvXbJFox0KHavzLO9xVklZruf4YF3UB76a3hL79xMgo3f4oI28MHGYxWKQe-DWUXTS-83ly2Qz0w96mi-QyJw2VYLS4IbBHKAN2vhWjN794nsqoGytQwCb_77r0HCGwsepTWttUODIdl1up7-aYgheVPtYX1k2EwMrECyU5acecB5bgwGE_HmVLYW0IMqSbAiT3SWZh3lEXtrLeP80WHYL3KYXRnjOdbXYWlYJ4jS9OO8027RYDl-LxpedCNDZiyZHp5jUOOKYuR4w-5uIC0JIGcpYwRcJ24tIUa0nPpuSagBajNKmVqjzmrqkzzTl8ZYkwdFs5D8npYeVEfSr2IoIsz9AU0OilCfwnxgBW0BtDkHQU0K78-R4JSxWQberaFqX3n4IYONZWNhbGQ8vsCn8l_xiZb-aR2TeNJUOHLjczub4Afkm4YGxTH2WY7_VynzcLHSpGptQL4zJme2_53BsNq94PaB9C1p-5jMWxESJhX-XTmNwdmCNY5V5UggLivQTk5HEwXq5bR4SdxJxRaJq3BzoTu0vXkPQoRrhrEuxb7kaoTzEHgoxkNZkbyS_Ex-VdQod9Q_cC0XKB34fVYP0tG2QG91qQMeRn9u3XhsszgfTYDfliTSGB4JVXqk62I8OZJBRNApbVbXcDgLc8JvenYpRS_iRZmPtKDs5xNrcUYOet2qPyFUkAg3Zh2uRiRxyaoFhs4e6HK7ZfTcnozmSSjmrvOUD5WGAkTFCjv-0rwyXo1G0gD9QeAjgzbZPNlwl-EZFsZvFa5uQJB082xUI7yeOxdYUC9R7w0k8uB7pkbLQDBecFRTS17Nqr488jHIcBqQyJ5Zy8TKzyYaBpiXwG3EQ-1KLfXZVDR8MW4CscsEgCTCZ1l9IyY5g085BHy6PSbFBZ2BSYpotM-XrB0t68Xyiqy8plLgLs7_NCA",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1700.34,
            "totalPrice": 1525
          },
          {
            "allPassengerTotalPrice": 1525,
            "legs": [
              {
                "airlines": [
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "16:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzpSCsGcFDM19rIdNXLEzdAd5qjQ62332IjDffM-RTqQDgE1RK70zSzzepNI0qvf9Es1rJHcBTOXOlE235xLiMpN8RC99OkV_8Tt5gjjU0NtugTZieGQVNi1xcvNhBqhq_77dsoKj9V0VkJsLxJbeQgxQAWO3UWmfLGoWX8lr2sjR6EjJgP95x22SehmU5-DovOP5N0xvlW4AN1iuZfvMDZ1U0LAo9whpJBH6n7okVDxiafE1uJsQoRkDKSxS8KDJjFnUiFte5pbPEzJYCsnUUQX4G_lLElIvPehoTW78LQrCsXg4czyVnBgDP_3qnzA2KrtsNrYXPXMukwZ5mrJMO2dfIpoi33XtmgbpNP96-FoFk6Gq6OH6Dio8FoIwonb8EiR_4oqysydnavm7K6BdWdKyKn5vcq_49NGzAe3z361MKUxIxW3a22pgoMjexdu7OND3Xcn6sbOGsp_vuHtsf_vlSQmm5dSy0Q8uF9vVwog--kSb4zuiWVCI2eSeHR4epJhkVvKPZEt9qbB7r10UNJCyO-NUTAi6WI3R7aWUfM_hfd5MYYX8SEgovruZAOa7Dhd9FUYF9yZ1eQmlJwwtuYOpuj68iGZ6klzSBb5RnY6kJCwS-OvB3H3c5lQUeofz7FrKrjzrJ7RyzHIVLDtUblIKadFNR8De6Pdp_qmHdTTQcX3BeIF0iiGcbEI-dkMeSu_AmcNdN6RroLNVBnLU_DRGYtrhxls8kwx_D8gQ9HXGMkhQhDz07sTBCkMz6egY-YOlZ7PDqDKlCDjxTHYi6Dlk-vqcpGMSTrSIPXAqqlUWXyX1fYCPciXhPA7CjCoIlNOSA5Uvl8ATbNuG-Fh0i70gHOdF46dTPxWvMiqtTbTlcRP4WdOwXGpfvArFMNjTCJFUSwYWFIxC2ICRGElMkZvHpebE6e27xoqlwHbk3GfpsyR97labhGNpP4mWEmdReuztlhrhT774F3Hbi5H4bBD8Li9MIhToWpn3wYbqwEBEoX9zziiiOpIN2sL9XB1bgirjefoBXFW5aRj4l6Q_95huSlFKxh2yWQJIkEoSwNM=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "17:10",
                "destination": "BCN",
                "duration": "14h45m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LIS",
                    "cityFrom": "San Francisco",
                    "cityTo": "Lisbon",
                    "combination_id": "0f9c01af4e0c00001e3d8708",
                    "equipment": null,
                    "fare_basis": "VH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 236,
                    "flyFrom": "SFO",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00001e3d8708_0",
                    "local_arrival": "2024-09-15T12:15:00.000Z",
                    "local_departure": "2024-09-14T17:10:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "236",
                    "return": 0,
                    "utc_arrival": "2024-09-15T11:15:00.000Z",
                    "utc_departure": "2024-09-15T00:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Lisbon",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c00001e3d8708",
                    "equipment": null,
                    "fare_basis": "VH0DSI05",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 1034,
                    "flyFrom": "LIS",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c00001e3d8708_1",
                    "local_arrival": "2024-09-15T16:55:00.000Z",
                    "local_departure": "2024-09-15T14:05:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1034",
                    "return": 0,
                    "utc_arrival": "2024-09-15T14:55:00.000Z",
                    "utc_departure": "2024-09-15T13:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzpSCsGcFDM19rIdNXLEzdAd5qjQ62332IjDffM-RTqQDgE1RK70zSzzepNI0qvf9Es1rJHcBTOXOlE235xLiMpN8RC99OkV_8Tt5gjjU0NtugTZieGQVNi1xcvNhBqhq_77dsoKj9V0VkJsLxJbeQgxQAWO3UWmfLGoWX8lr2sjR6EjJgP95x22SehmU5-DovOP5N0xvlW4AN1iuZfvMDZ1U0LAo9whpJBH6n7okVDxiafE1uJsQoRkDKSxS8KDJjFnUiFte5pbPEzJYCsnUUQX4G_lLElIvPehoTW78LQrCsXg4czyVnBgDP_3qnzA2KrtsNrYXPXMukwZ5mrJMO2dfIpoi33XtmgbpNP96-FoFk6Gq6OH6Dio8FoIwonb8EiR_4oqysydnavm7K6BdWdKyKn5vcq_49NGzAe3z361MKUxIxW3a22pgoMjexdu7OND3Xcn6sbOGsp_vuHtsf_vlSQmm5dSy0Q8uF9vVwog--kSb4zuiWVCI2eSeHR4epJhkVvKPZEt9qbB7r10UNJCyO-NUTAi6WI3R7aWUfM_hfd5MYYX8SEgovruZAOa7Dhd9FUYF9yZ1eQmlJwwtuYOpuj68iGZ6klzSBb5RnY6kJCwS-OvB3H3c5lQUeofz7FrKrjzrJ7RyzHIVLDtUblIKadFNR8De6Pdp_qmHdTTQcX3BeIF0iiGcbEI-dkMeSu_AmcNdN6RroLNVBnLU_DRGYtrhxls8kwx_D8gQ9HXGMkhQhDz07sTBCkMz6egY-YOlZ7PDqDKlCDjxTHYi6Dlk-vqcpGMSTrSIPXAqqlUWXyX1fYCPciXhPA7CjCoIlNOSA5Uvl8ATbNuG-Fh0i70gHOdF46dTPxWvMiqtTbTlcRP4WdOwXGpfvArFMNjTCJFUSwYWFIxC2ICRGElMkZvHpebE6e27xoqlwHbk3GfpsyR97labhGNpP4mWEmdReuztlhrhT774F3Hbi5H4bBD8Li9MIhToWpn3wYbqwEBEoX9zziiiOpIN2sL9XB1bgirjefoBXFW5aRj4l6Q_95huSlFKxh2yWQJIkEoSwNM=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "NI",
                  "TP"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c00001e3d8708_0%7C0f9c01af4e0c00001e3d8708_1%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c00007a5c90d8_0%7C04b80f9c4e1c00007a5c90d8_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzpSCsGcFDM19rIdNXLEzdAd5qjQ62332IjDffM-RTqQDgE1RK70zSzzepNI0qvf9Es1rJHcBTOXOlE235xLiMpN8RC99OkV_8Tt5gjjU0NtugTZieGQVNi1xcvNhBqhq_77dsoKj9V0VkJsLxJbeQgxQAWO3UWmfLGoWX8lr2sjR6EjJgP95x22SehmU5-DovOP5N0xvlW4AN1iuZfvMDZ1U0LAo9whpJBH6n7okVDxiafE1uJsQoRkDKSxS8KDJjFnUiFte5pbPEzJYCsnUUQX4G_lLElIvPehoTW78LQrCsXg4czyVnBgDP_3qnzA2KrtsNrYXPXMukwZ5mrJMO2dfIpoi33XtmgbpNP96-FoFk6Gq6OH6Dio8FoIwonb8EiR_4oqysydnavm7K6BdWdKyKn5vcq_49NGzAe3z361MKUxIxW3a22pgoMjexdu7OND3Xcn6sbOGsp_vuHtsf_vlSQmm5dSy0Q8uF9vVwog--kSb4zuiWVCI2eSeHR4epJhkVvKPZEt9qbB7r10UNJCyO-NUTAi6WI3R7aWUfM_hfd5MYYX8SEgovruZAOa7Dhd9FUYF9yZ1eQmlJwwtuYOpuj68iGZ6klzSBb5RnY6kJCwS-OvB3H3c5lQUeofz7FrKrjzrJ7RyzHIVLDtUblIKadFNR8De6Pdp_qmHdTTQcX3BeIF0iiGcbEI-dkMeSu_AmcNdN6RroLNVBnLU_DRGYtrhxls8kwx_D8gQ9HXGMkhQhDz07sTBCkMz6egY-YOlZ7PDqDKlCDjxTHYi6Dlk-vqcpGMSTrSIPXAqqlUWXyX1fYCPciXhPA7CjCoIlNOSA5Uvl8ATbNuG-Fh0i70gHOdF46dTPxWvMiqtTbTlcRP4WdOwXGpfvArFMNjTCJFUSwYWFIxC2ICRGElMkZvHpebE6e27xoqlwHbk3GfpsyR97labhGNpP4mWEmdReuztlhrhT774F3Hbi5H4bBD8Li9MIhToWpn3wYbqwEBEoX9zziiiOpIN2sL9XB1bgirjefoBXFW5aRj4l6Q_95huSlFKxh2yWQJIkEoSwNM=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "16:30",
                "destination": "SFO",
                "duration": "30h35m",
                "routes": [
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Seville",
                    "cityTo": "Lisbon",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 1105,
                    "flyFrom": "SVQ",
                    "flyTo": "LIS",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_0",
                    "local_arrival": "2024-09-30T16:30:00.000Z",
                    "local_departure": "2024-09-30T16:30:00.000Z",
                    "operating_carrier": "NI",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-30T15:30:00.000Z",
                    "utc_departure": "2024-09-30T14:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c00007a5c90d8",
                    "equipment": null,
                    "fare_basis": "AL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c00007a5c90d8_1",
                    "local_arrival": "2024-10-01T14:05:00.000Z",
                    "local_departure": "2024-10-01T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:05:00.000Z",
                    "utc_departure": "2024-10-01T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1701.01,
            "totalPrice": 1525
          },
          {
            "allPassengerTotalPrice": 1573,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GSkLZCQk_6oig_NxKNUppB35ew-0dT1iOGj7_PKm4fBUn5ZWV42kIdX25W9kBsTcK6eXc-JOFjpgUtaqblw39mCgVuLA_WSyPLsyrLgi6zS8CJ99PcDeEVjzORIJZ5jDGGrL8JXO8pW4KvphwH02_QfUAr1q9kBkrUUAH2M-5gUjcCcw0eT4Ifel9h7Hsekoo2HCn7tKpfg39Vh0GiI-kxK3AhiZkMMgvaB8nQCAQ0TpLL0-s0cZHuCmheopDaQl75okD1FZwVu4FxvQAdi0Zuj2DR_C-OdTotQm0syTqArlTmgiupNaMTQynUEuAHuQEoKnoLaL5gXlL5Q7StsXxl4G3tCYX5xki0sVIBhmEoxZFX1sWoe_ujHgS8TiqWaHf85CwcYYVwdd4rf3LXF7TXuM0LrSRdaFn-lW4kExU-sE-I8_N4zdjkiLO6FNsbU_XVmw7ArYAgNnMVOAdkMlCMDe_ji96DKaMp9xbMzDInatHvMxlPZxHobhmRcQEMrEjHiK3wCf7I7RyfFhhFcqHxAB_noCCP7RKwr5a1UT629JebFxbfWOvzXBsA8Z9LkKmTtwvDL35qitOuQsiJ_BBT70kMcx2i3KD5u3b_-yuSux_FMYbDT3CIZ5VdEKHK9hzW9c2Ycb3ZIXYA4h1WBeQlrDLlr09pzmeVN-dCwGRH11MmbTP9G1FOHWj22KaSNsahEQYe05Mb4QL12RMjO4YDNpfS5dvmiuJfA-8oeDFLVS9J_ClCxFTG4LtZwR7tgFQjLRb-Iq0sLNGi9toMjoQIWhsav88XVeF4kglXppK7KrXiLKz5aa9k5E7rCmtPtmZlDzDLEj-dORrVrJtwjbN2TBhaaNl93PMoj7l-P2zJiuzZSMBd9RgOd3Gks5_igrbjMOl-ftWeRPmasTaQz5gjRpU0ELNPoIthxKJKt2cW75QBgmRpHr6QjBu8OFAEk49lEH2yNhGTtPSiJ-cRXdN9hlINq7TfWrD5mtlyvm4h_Y68bMpCLWxrYspbE_PksmAcE6SK3ZxZt2NEAfB43qidQ==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GSkLZCQk_6oig_NxKNUppB35ew-0dT1iOGj7_PKm4fBUn5ZWV42kIdX25W9kBsTcK6eXc-JOFjpgUtaqblw39mCgVuLA_WSyPLsyrLgi6zS8CJ99PcDeEVjzORIJZ5jDGGrL8JXO8pW4KvphwH02_QfUAr1q9kBkrUUAH2M-5gUjcCcw0eT4Ifel9h7Hsekoo2HCn7tKpfg39Vh0GiI-kxK3AhiZkMMgvaB8nQCAQ0TpLL0-s0cZHuCmheopDaQl75okD1FZwVu4FxvQAdi0Zuj2DR_C-OdTotQm0syTqArlTmgiupNaMTQynUEuAHuQEoKnoLaL5gXlL5Q7StsXxl4G3tCYX5xki0sVIBhmEoxZFX1sWoe_ujHgS8TiqWaHf85CwcYYVwdd4rf3LXF7TXuM0LrSRdaFn-lW4kExU-sE-I8_N4zdjkiLO6FNsbU_XVmw7ArYAgNnMVOAdkMlCMDe_ji96DKaMp9xbMzDInatHvMxlPZxHobhmRcQEMrEjHiK3wCf7I7RyfFhhFcqHxAB_noCCP7RKwr5a1UT629JebFxbfWOvzXBsA8Z9LkKmTtwvDL35qitOuQsiJ_BBT70kMcx2i3KD5u3b_-yuSux_FMYbDT3CIZ5VdEKHK9hzW9c2Ycb3ZIXYA4h1WBeQlrDLlr09pzmeVN-dCwGRH11MmbTP9G1FOHWj22KaSNsahEQYe05Mb4QL12RMjO4YDNpfS5dvmiuJfA-8oeDFLVS9J_ClCxFTG4LtZwR7tgFQjLRb-Iq0sLNGi9toMjoQIWhsav88XVeF4kglXppK7KrXiLKz5aa9k5E7rCmtPtmZlDzDLEj-dORrVrJtwjbN2TBhaaNl93PMoj7l-P2zJiuzZSMBd9RgOd3Gks5_igrbjMOl-ftWeRPmasTaQz5gjRpU0ELNPoIthxKJKt2cW75QBgmRpHr6QjBu8OFAEk49lEH2yNhGTtPSiJ-cRXdN9hlINq7TfWrD5mtlyvm4h_Y68bMpCLWxrYspbE_PksmAcE6SK3ZxZt2NEAfB43qidQ==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600007972bf0f",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600007972bf0f_0",
                    "local_arrival": "2024-09-24T14:35:00.000Z",
                    "local_departure": "2024-09-24T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-09-24T12:35:00.000Z",
                    "utc_departure": "2024-09-24T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600007972bf0f_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GSkLZCQk_6oig_NxKNUppB35ew-0dT1iOGj7_PKm4fBUn5ZWV42kIdX25W9kBsTcK6eXc-JOFjpgUtaqblw39mCgVuLA_WSyPLsyrLgi6zS8CJ99PcDeEVjzORIJZ5jDGGrL8JXO8pW4KvphwH02_QfUAr1q9kBkrUUAH2M-5gUjcCcw0eT4Ifel9h7Hsekoo2HCn7tKpfg39Vh0GiI-kxK3AhiZkMMgvaB8nQCAQ0TpLL0-s0cZHuCmheopDaQl75okD1FZwVu4FxvQAdi0Zuj2DR_C-OdTotQm0syTqArlTmgiupNaMTQynUEuAHuQEoKnoLaL5gXlL5Q7StsXxl4G3tCYX5xki0sVIBhmEoxZFX1sWoe_ujHgS8TiqWaHf85CwcYYVwdd4rf3LXF7TXuM0LrSRdaFn-lW4kExU-sE-I8_N4zdjkiLO6FNsbU_XVmw7ArYAgNnMVOAdkMlCMDe_ji96DKaMp9xbMzDInatHvMxlPZxHobhmRcQEMrEjHiK3wCf7I7RyfFhhFcqHxAB_noCCP7RKwr5a1UT629JebFxbfWOvzXBsA8Z9LkKmTtwvDL35qitOuQsiJ_BBT70kMcx2i3KD5u3b_-yuSux_FMYbDT3CIZ5VdEKHK9hzW9c2Ycb3ZIXYA4h1WBeQlrDLlr09pzmeVN-dCwGRH11MmbTP9G1FOHWj22KaSNsahEQYe05Mb4QL12RMjO4YDNpfS5dvmiuJfA-8oeDFLVS9J_ClCxFTG4LtZwR7tgFQjLRb-Iq0sLNGi9toMjoQIWhsav88XVeF4kglXppK7KrXiLKz5aa9k5E7rCmtPtmZlDzDLEj-dORrVrJtwjbN2TBhaaNl93PMoj7l-P2zJiuzZSMBd9RgOd3Gks5_igrbjMOl-ftWeRPmasTaQz5gjRpU0ELNPoIthxKJKt2cW75QBgmRpHr6QjBu8OFAEk49lEH2yNhGTtPSiJ-cRXdN9hlINq7TfWrD5mtlyvm4h_Y68bMpCLWxrYspbE_PksmAcE6SK3ZxZt2NEAfB43qidQ==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1668.19,
            "totalPrice": 1573
          },
          {
            "allPassengerTotalPrice": 1577,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAMGlhPHPcBRNR2mUm3Ga8doQ__wXma5MCfr5xTDhu7w2hwJr9Bx8WVsgc1aFkBBODXOKITJNs6RXKFZCaj3zOMZFnczwti7SI2ibtAVE-OcrrWXLPJKOGUY5JIN8E6JC-k9xW1p0cPg-bRV6jIVUgCiX1XjCeamd_khISjIqdcaOWvItLvlHYrI0Tko8FcdSY1S2P1B3oQgZifeN7uB9shaGbH_rPiVw5TIIhFgTye3zA0pV9b338UvXjLy6ZRFC0743XXWPFsBJf6xoBfKmZSdrOtuBQgbB1sRqlmdL6LPPzQhAkpu_hMSQf6DvWpbgdyQr7EXNN0JBCP4aFPqiJMcrdnTidDNemFkPo4uGMnt_jMc44yEsTmVwZHhVnHCAlGa74ves-qsp79rHRcdbJD2FWUiZjmUVuHZEODJ2U1HHjaS8SSjon1BKTWUS9XYAa2Qu34jdLKW_UENAmvn0Snl1BN88PwiS5sWZp27UQA5Ey1nvMrf_ERXzVfYA1bVU2wxOCtcR9Yrdos5aPixENR3eAnPn9AwZLibMLWTsYv47-d27Gx5lB6zpbLg4waGuzpM9RGPT-VrpjtnCeONsqjDJ0eaq29G0WHwqGxqcUkRBeWuNjqeUHdzuvKfGtYeV2OWpPC3s_wJFkQZ6B2rqcfapVDM-h5tn8ydZlA9dzpIT8fmRugrMpzQ0Iu-_Ez049SL0SYbS6Dmk7TZ-rS0Qm1rGdtGEwag7-LDQM32NyjC7sfTbK1AOv8tf7y3DBZ-LZJLmkOqXiKHZVDhsZaj4GpYZABKrxbUPVESnQXXtbjNKBALo6lW1u4urvztCVaV5ZzfmlMfN_3pMKi1y9crRpOdqI_fTosyWrEIQ8uZsLw6Sy6R6vbG4sUmFHmSs5rDragOUeBRMHRLDOJxREH20wzje4GjX1MXGOGRWUj16hCo2P1ZL9E57cpIuR5KCvl5PChwoDAKXq5nNcPCXl6sRZWPntSy33-Kxs2H4gDu4cPb433M0zYALpyQSzNfFWglA1THlY6WJBQessJyrU-Eh6dg5yxNL7NM709QA67s4VZg=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAMGlhPHPcBRNR2mUm3Ga8doQ__wXma5MCfr5xTDhu7w2hwJr9Bx8WVsgc1aFkBBODXOKITJNs6RXKFZCaj3zOMZFnczwti7SI2ibtAVE-OcrrWXLPJKOGUY5JIN8E6JC-k9xW1p0cPg-bRV6jIVUgCiX1XjCeamd_khISjIqdcaOWvItLvlHYrI0Tko8FcdSY1S2P1B3oQgZifeN7uB9shaGbH_rPiVw5TIIhFgTye3zA0pV9b338UvXjLy6ZRFC0743XXWPFsBJf6xoBfKmZSdrOtuBQgbB1sRqlmdL6LPPzQhAkpu_hMSQf6DvWpbgdyQr7EXNN0JBCP4aFPqiJMcrdnTidDNemFkPo4uGMnt_jMc44yEsTmVwZHhVnHCAlGa74ves-qsp79rHRcdbJD2FWUiZjmUVuHZEODJ2U1HHjaS8SSjon1BKTWUS9XYAa2Qu34jdLKW_UENAmvn0Snl1BN88PwiS5sWZp27UQA5Ey1nvMrf_ERXzVfYA1bVU2wxOCtcR9Yrdos5aPixENR3eAnPn9AwZLibMLWTsYv47-d27Gx5lB6zpbLg4waGuzpM9RGPT-VrpjtnCeONsqjDJ0eaq29G0WHwqGxqcUkRBeWuNjqeUHdzuvKfGtYeV2OWpPC3s_wJFkQZ6B2rqcfapVDM-h5tn8ydZlA9dzpIT8fmRugrMpzQ0Iu-_Ez049SL0SYbS6Dmk7TZ-rS0Qm1rGdtGEwag7-LDQM32NyjC7sfTbK1AOv8tf7y3DBZ-LZJLmkOqXiKHZVDhsZaj4GpYZABKrxbUPVESnQXXtbjNKBALo6lW1u4urvztCVaV5ZzfmlMfN_3pMKi1y9crRpOdqI_fTosyWrEIQ8uZsLw6Sy6R6vbG4sUmFHmSs5rDragOUeBRMHRLDOJxREH20wzje4GjX1MXGOGRWUj16hCo2P1ZL9E57cpIuR5KCvl5PChwoDAKXq5nNcPCXl6sRZWPntSy33-Kxs2H4gDu4cPb433M0zYALpyQSzNfFWglA1THlY6WJBQessJyrU-Eh6dg5yxNL7NM709QA67s4VZg=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000086ad835b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000086ad835b_0",
                    "local_arrival": "2024-09-24T09:45:00.000Z",
                    "local_departure": "2024-09-24T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-09-24T07:45:00.000Z",
                    "utc_departure": "2024-09-24T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000086ad835b_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAMGlhPHPcBRNR2mUm3Ga8doQ__wXma5MCfr5xTDhu7w2hwJr9Bx8WVsgc1aFkBBODXOKITJNs6RXKFZCaj3zOMZFnczwti7SI2ibtAVE-OcrrWXLPJKOGUY5JIN8E6JC-k9xW1p0cPg-bRV6jIVUgCiX1XjCeamd_khISjIqdcaOWvItLvlHYrI0Tko8FcdSY1S2P1B3oQgZifeN7uB9shaGbH_rPiVw5TIIhFgTye3zA0pV9b338UvXjLy6ZRFC0743XXWPFsBJf6xoBfKmZSdrOtuBQgbB1sRqlmdL6LPPzQhAkpu_hMSQf6DvWpbgdyQr7EXNN0JBCP4aFPqiJMcrdnTidDNemFkPo4uGMnt_jMc44yEsTmVwZHhVnHCAlGa74ves-qsp79rHRcdbJD2FWUiZjmUVuHZEODJ2U1HHjaS8SSjon1BKTWUS9XYAa2Qu34jdLKW_UENAmvn0Snl1BN88PwiS5sWZp27UQA5Ey1nvMrf_ERXzVfYA1bVU2wxOCtcR9Yrdos5aPixENR3eAnPn9AwZLibMLWTsYv47-d27Gx5lB6zpbLg4waGuzpM9RGPT-VrpjtnCeONsqjDJ0eaq29G0WHwqGxqcUkRBeWuNjqeUHdzuvKfGtYeV2OWpPC3s_wJFkQZ6B2rqcfapVDM-h5tn8ydZlA9dzpIT8fmRugrMpzQ0Iu-_Ez049SL0SYbS6Dmk7TZ-rS0Qm1rGdtGEwag7-LDQM32NyjC7sfTbK1AOv8tf7y3DBZ-LZJLmkOqXiKHZVDhsZaj4GpYZABKrxbUPVESnQXXtbjNKBALo6lW1u4urvztCVaV5ZzfmlMfN_3pMKi1y9crRpOdqI_fTosyWrEIQ8uZsLw6Sy6R6vbG4sUmFHmSs5rDragOUeBRMHRLDOJxREH20wzje4GjX1MXGOGRWUj16hCo2P1ZL9E57cpIuR5KCvl5PChwoDAKXq5nNcPCXl6sRZWPntSy33-Kxs2H4gDu4cPb433M0zYALpyQSzNfFWglA1THlY6WJBQessJyrU-Eh6dg5yxNL7NM709QA67s4VZg=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1669.97,
            "totalPrice": 1577
          },
          {
            "allPassengerTotalPrice": 1577,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GolZB_qbcOCHrRf0zSWrYQIFt69LF0CYrHb5pE6Z9hIZOt_EtnB6TqrYKFV2FWjRza6n08_AQym_Xh3JyOffUw0xlG3kSJc3QFOptVw6HlBnOEy0qR1DR7uIl6ffAPgtwfBZMEmRrSRslPfhTP-7UJyZ4Ol9Q0eHiEt3fzOadS4vDY3w7i-XGsupJhlxohG2WqKOH4ciSaEkN69eHEEiVej-942ZaaPuUJArUfAlNWv7qh9AHBS-_a-FWcqpE8apqarrDe617KzHp2FfKagXj-zzrwP1i-GIOn9NKEj7sEI_uFYU7b91IWoX2qf45zSiAEo0SAbaWkr1GP5irCOH9gChEaGdxUM5uCMLhHD1WiwT03QdMeYw_J2dLrj8C84fQjaAfgCP1SZj-Kp4jx-9fMPDNQ422g20rW3s2DnZtUW3MCkiuEuX2cNqe-P5IY61pcbQVk9e0FGmCPbzyVK2Pciv8nq81zgoANmDw7LOwYSk6VHilonhhVU-SCftKoMeXM5rl2qC85G74kyX4wo_MkNNFCREJrvH5IRxJuZY6oZVUoGww3gc8qBKWAloO-Hw1oO8V0qJ48eJcQxrfoZ5oNsM-5_NByxReLp3IhFiaKvmFUowikYDutovnww278vmevQ9oobhYhYzg_-DQ0b_kS15yS4pgXrypQAh9e5Wm26nnl2RBljgoyFxZlYxTEtcmL03cZt3Mb9KyE6xRiA3ogOKJY7bVFreF8rJoh4jaQ5wclvDN1F4WoJC2p1LVlM5prV1WA4xgcbZSMXACaM5JQiMIChq1seX6FbFzqjb8mdGk1OMP1Raqib-6RCASP-8SfUrI8hAMBL1KL7FMUy2Jy5hk50pAJcor974evjWWp9Spef8H_SD4gsx2Gj7JOCIOqdemP7PwdEmyABRETuy-21jMWBibvXTYE6-GMYPrkyWbSxufrBtcs2IsMMUdKeKptandeG4_h_l1TcvWYFnUPSjsd9RZcODiXDjLGug2tDeZDwfc9DXn7jArW7G3tD9kklk9z4JaoTCHSYwE9NmClA==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GolZB_qbcOCHrRf0zSWrYQIFt69LF0CYrHb5pE6Z9hIZOt_EtnB6TqrYKFV2FWjRza6n08_AQym_Xh3JyOffUw0xlG3kSJc3QFOptVw6HlBnOEy0qR1DR7uIl6ffAPgtwfBZMEmRrSRslPfhTP-7UJyZ4Ol9Q0eHiEt3fzOadS4vDY3w7i-XGsupJhlxohG2WqKOH4ciSaEkN69eHEEiVej-942ZaaPuUJArUfAlNWv7qh9AHBS-_a-FWcqpE8apqarrDe617KzHp2FfKagXj-zzrwP1i-GIOn9NKEj7sEI_uFYU7b91IWoX2qf45zSiAEo0SAbaWkr1GP5irCOH9gChEaGdxUM5uCMLhHD1WiwT03QdMeYw_J2dLrj8C84fQjaAfgCP1SZj-Kp4jx-9fMPDNQ422g20rW3s2DnZtUW3MCkiuEuX2cNqe-P5IY61pcbQVk9e0FGmCPbzyVK2Pciv8nq81zgoANmDw7LOwYSk6VHilonhhVU-SCftKoMeXM5rl2qC85G74kyX4wo_MkNNFCREJrvH5IRxJuZY6oZVUoGww3gc8qBKWAloO-Hw1oO8V0qJ48eJcQxrfoZ5oNsM-5_NByxReLp3IhFiaKvmFUowikYDutovnww278vmevQ9oobhYhYzg_-DQ0b_kS15yS4pgXrypQAh9e5Wm26nnl2RBljgoyFxZlYxTEtcmL03cZt3Mb9KyE6xRiA3ogOKJY7bVFreF8rJoh4jaQ5wclvDN1F4WoJC2p1LVlM5prV1WA4xgcbZSMXACaM5JQiMIChq1seX6FbFzqjb8mdGk1OMP1Raqib-6RCASP-8SfUrI8hAMBL1KL7FMUy2Jy5hk50pAJcor974evjWWp9Spef8H_SD4gsx2Gj7JOCIOqdemP7PwdEmyABRETuy-21jMWBibvXTYE6-GMYPrkyWbSxufrBtcs2IsMMUdKeKptandeG4_h_l1TcvWYFnUPSjsd9RZcODiXDjLGug2tDeZDwfc9DXn7jArW7G3tD9kklk9z4JaoTCHSYwE9NmClA==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000071b8d336",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000071b8d336_0",
                    "local_arrival": "2024-09-24T18:15:00.000Z",
                    "local_departure": "2024-09-24T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:15:00.000Z",
                    "utc_departure": "2024-09-24T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000071b8d336_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GolZB_qbcOCHrRf0zSWrYQIFt69LF0CYrHb5pE6Z9hIZOt_EtnB6TqrYKFV2FWjRza6n08_AQym_Xh3JyOffUw0xlG3kSJc3QFOptVw6HlBnOEy0qR1DR7uIl6ffAPgtwfBZMEmRrSRslPfhTP-7UJyZ4Ol9Q0eHiEt3fzOadS4vDY3w7i-XGsupJhlxohG2WqKOH4ciSaEkN69eHEEiVej-942ZaaPuUJArUfAlNWv7qh9AHBS-_a-FWcqpE8apqarrDe617KzHp2FfKagXj-zzrwP1i-GIOn9NKEj7sEI_uFYU7b91IWoX2qf45zSiAEo0SAbaWkr1GP5irCOH9gChEaGdxUM5uCMLhHD1WiwT03QdMeYw_J2dLrj8C84fQjaAfgCP1SZj-Kp4jx-9fMPDNQ422g20rW3s2DnZtUW3MCkiuEuX2cNqe-P5IY61pcbQVk9e0FGmCPbzyVK2Pciv8nq81zgoANmDw7LOwYSk6VHilonhhVU-SCftKoMeXM5rl2qC85G74kyX4wo_MkNNFCREJrvH5IRxJuZY6oZVUoGww3gc8qBKWAloO-Hw1oO8V0qJ48eJcQxrfoZ5oNsM-5_NByxReLp3IhFiaKvmFUowikYDutovnww278vmevQ9oobhYhYzg_-DQ0b_kS15yS4pgXrypQAh9e5Wm26nnl2RBljgoyFxZlYxTEtcmL03cZt3Mb9KyE6xRiA3ogOKJY7bVFreF8rJoh4jaQ5wclvDN1F4WoJC2p1LVlM5prV1WA4xgcbZSMXACaM5JQiMIChq1seX6FbFzqjb8mdGk1OMP1Raqib-6RCASP-8SfUrI8hAMBL1KL7FMUy2Jy5hk50pAJcor974evjWWp9Spef8H_SD4gsx2Gj7JOCIOqdemP7PwdEmyABRETuy-21jMWBibvXTYE6-GMYPrkyWbSxufrBtcs2IsMMUdKeKptandeG4_h_l1TcvWYFnUPSjsd9RZcODiXDjLGug2tDeZDwfc9DXn7jArW7G3tD9kklk9z4JaoTCHSYwE9NmClA==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1670.63,
            "totalPrice": 1577
          },
          {
            "allPassengerTotalPrice": 1584,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLwj6V-alBnLpSjzUcQdzDUrgOs2UZv0u262QJQrlvnubrOy2_MIki9_8pINMdcqcxyos_a5aNmL7ZnHJsiW-dX7FJ1Q2QD8QPziZKW5b3UW3rjN7T0dPUfECp7xwULR8GGMIotvsTgozFjiMCFFlG6tHrXrdF-0AneD_YK64DPUsC8XNxdbq8NC2-iZkszbcCcBevZnT5wg2iB0f9b5NqWaJ9-NIrT4s0XI2yKNzyEVcj7etEKVLDFEB5KVy2wj9vD5xHWmJJLGUFYZg5on4pNWMfms725v04o5J3TIUOYaSZIMH7Zg41njuogubCMQXL7cHftRBAuL05pZbS-NvbzkzBJVJ3GK_C_laUEVC2J8XSJWI93VemhmZQdPJCWu0hHGzpTPQ2I0oKdEzKlkdVy7-UVxuuplQr8ar-lSjQn6vMmAtBK29DYS6seZTo2vBjmbKXMwGi-GbjXGTQRBEBBNF1jkUb3xILrRuk5ZEEoW-6K7BC6mUEd-Pa4EgvBBBcvcffJAKPXZ7gYH3r1sx_h1kfDZw8n55aLoGX1EZ9yl4MF3iGpOPkp8aNzGNUcg_vTVANUiUQeOStjMt5zapRIaTFXQ3OpK5BAobF0t5hCBE4NCEBN-9fPFHhXDUUR3VAs3ikc47cqjaVzke0reDaO54H-4-cEAC-eNhxyDuuq53SA203IxAk6Z9cybCskg9ESvKIx1AI3YMqM_hGOC73pmQM_xDY1s7igKdFBVCAv47tlgmOLXrnZoCssRKTa79smjUG0YdrRJ_AZZEQmNtQOO3hEoQ4ZYs_HOxpcrfqCDgILqJ9DEJ3aSMhyuX7kOjLaI4HgRnuI-xWJdiiE8Z0lhghTV318M13fVmxIwSXmJnHjIPT6-xxm99ynaCT6B9vI6uNvohj_H5zUzCGczVW2kXqRej5vpso34To90AeXyzMxlQjzaAIeAifYlJL5NQaRUrnJmC2Jt5xun4yWko5EDusPpa6fHPnDCxo4W7pA9TjWyuK9y99atMryOvjffT",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLwj6V-alBnLpSjzUcQdzDUrgOs2UZv0u262QJQrlvnubrOy2_MIki9_8pINMdcqcxyos_a5aNmL7ZnHJsiW-dX7FJ1Q2QD8QPziZKW5b3UW3rjN7T0dPUfECp7xwULR8GGMIotvsTgozFjiMCFFlG6tHrXrdF-0AneD_YK64DPUsC8XNxdbq8NC2-iZkszbcCcBevZnT5wg2iB0f9b5NqWaJ9-NIrT4s0XI2yKNzyEVcj7etEKVLDFEB5KVy2wj9vD5xHWmJJLGUFYZg5on4pNWMfms725v04o5J3TIUOYaSZIMH7Zg41njuogubCMQXL7cHftRBAuL05pZbS-NvbzkzBJVJ3GK_C_laUEVC2J8XSJWI93VemhmZQdPJCWu0hHGzpTPQ2I0oKdEzKlkdVy7-UVxuuplQr8ar-lSjQn6vMmAtBK29DYS6seZTo2vBjmbKXMwGi-GbjXGTQRBEBBNF1jkUb3xILrRuk5ZEEoW-6K7BC6mUEd-Pa4EgvBBBcvcffJAKPXZ7gYH3r1sx_h1kfDZw8n55aLoGX1EZ9yl4MF3iGpOPkp8aNzGNUcg_vTVANUiUQeOStjMt5zapRIaTFXQ3OpK5BAobF0t5hCBE4NCEBN-9fPFHhXDUUR3VAs3ikc47cqjaVzke0reDaO54H-4-cEAC-eNhxyDuuq53SA203IxAk6Z9cybCskg9ESvKIx1AI3YMqM_hGOC73pmQM_xDY1s7igKdFBVCAv47tlgmOLXrnZoCssRKTa79smjUG0YdrRJ_AZZEQmNtQOO3hEoQ4ZYs_HOxpcrfqCDgILqJ9DEJ3aSMhyuX7kOjLaI4HgRnuI-xWJdiiE8Z0lhghTV318M13fVmxIwSXmJnHjIPT6-xxm99ynaCT6B9vI6uNvohj_H5zUzCGczVW2kXqRej5vpso34To90AeXyzMxlQjzaAIeAifYlJL5NQaRUrnJmC2Jt5xun4yWko5EDusPpa6fHPnDCxo4W7pA9TjWyuK9y99atMryOvjffT",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000890a2b7b",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000890a2b7b_0",
                    "local_arrival": "2024-09-24T18:55:00.000Z",
                    "local_departure": "2024-09-24T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-09-24T16:55:00.000Z",
                    "utc_departure": "2024-09-24T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000890a2b7b_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLwj6V-alBnLpSjzUcQdzDUrgOs2UZv0u262QJQrlvnubrOy2_MIki9_8pINMdcqcxyos_a5aNmL7ZnHJsiW-dX7FJ1Q2QD8QPziZKW5b3UW3rjN7T0dPUfECp7xwULR8GGMIotvsTgozFjiMCFFlG6tHrXrdF-0AneD_YK64DPUsC8XNxdbq8NC2-iZkszbcCcBevZnT5wg2iB0f9b5NqWaJ9-NIrT4s0XI2yKNzyEVcj7etEKVLDFEB5KVy2wj9vD5xHWmJJLGUFYZg5on4pNWMfms725v04o5J3TIUOYaSZIMH7Zg41njuogubCMQXL7cHftRBAuL05pZbS-NvbzkzBJVJ3GK_C_laUEVC2J8XSJWI93VemhmZQdPJCWu0hHGzpTPQ2I0oKdEzKlkdVy7-UVxuuplQr8ar-lSjQn6vMmAtBK29DYS6seZTo2vBjmbKXMwGi-GbjXGTQRBEBBNF1jkUb3xILrRuk5ZEEoW-6K7BC6mUEd-Pa4EgvBBBcvcffJAKPXZ7gYH3r1sx_h1kfDZw8n55aLoGX1EZ9yl4MF3iGpOPkp8aNzGNUcg_vTVANUiUQeOStjMt5zapRIaTFXQ3OpK5BAobF0t5hCBE4NCEBN-9fPFHhXDUUR3VAs3ikc47cqjaVzke0reDaO54H-4-cEAC-eNhxyDuuq53SA203IxAk6Z9cybCskg9ESvKIx1AI3YMqM_hGOC73pmQM_xDY1s7igKdFBVCAv47tlgmOLXrnZoCssRKTa79smjUG0YdrRJ_AZZEQmNtQOO3hEoQ4ZYs_HOxpcrfqCDgILqJ9DEJ3aSMhyuX7kOjLaI4HgRnuI-xWJdiiE8Z0lhghTV318M13fVmxIwSXmJnHjIPT6-xxm99ynaCT6B9vI6uNvohj_H5zUzCGczVW2kXqRej5vpso34To90AeXyzMxlQjzaAIeAifYlJL5NQaRUrnJmC2Jt5xun4yWko5EDusPpa6fHPnDCxo4W7pA9TjWyuK9y99atMryOvjffT",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1676.32,
            "totalPrice": 1584
          },
          {
            "allPassengerTotalPrice": 1585,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkNm6CQRG5ANfANzsfPNAxAx7CYqFYzwe1n5lficRC0yOFH2H8QZQOlzEOYNRZwkngGWEan3yVSB7MpoJwWD-PVwrfovMhy1ZM-jDlUCe6Nm008cqYfwVYIOdx2lwk4jyJllp6-HmmMGd9mVXuTx8QsUowrcBDmn6S2BX8_7t9hGFLCB_UjjE70J6pLBuzz5JRcZgDYnmMjCa33vI2TDk2aicitj3VPEg6P0COdfR1mIw6ZOZT3GjA2aTCzun8nmVzEHD7G9mBEL4XAxWFJwTBCCZm2DCjgFFTTRa72tNSerNIzRJyP8dMO7cCCVx2j5w5ySpdjz8FhgoauzFiuOY26pLu21rsylqjtSHUHS_w3I1blEUATJ-2-rv76As9ZQt24BfEktnUoHut4cLQveZRm6nNCAlavXtwZaoK7sJ1O_6ZrjGzC7P5T6L3_qww5rKYfVjzwTCjDIGKRfQv-h-9EzCRGd2_q0Dtvcv4kOTM8AMFvsfwL8jmqApUHnQ8rwZbUF5GaFekOGuZUzHJRQn5OV98i0-hFX1_csYpf3KjrEH8PBTl2755cg63a8uxZzb071PiCP2wRCX0K-M1hHTvnFPObXAsLU4iQHeaf8e0L9s6lmCkVqh6PYY9pR36izK6_bPeMrgaGswjTRmfIozr9x8fTUR7a2ZosnPPZe2PqMpIy2h43zIkSdC0vfbLNjf1gDVE1V24uWgKPaDAE4K8OqCjVdA3QlmtFXCgw-XrtFDbrS2RiftWcDioBi2xQZ6OTMXmyYFslWahESx9AqgNjY8RmIFz2kDkhTKWVFWf8yh2Y6Fi189Ykoozdwv9H7DvAkF0ahHh2xuwhWidGteEFmqb17JMpfppgC_KdG8qVjpSBPMkbliSDUchYyUK024yG4k1U55kreYlVO31aqmrQjdTyR3MBBMGMKJhGdKT_U4o2gmLmM2-gJ01xF41NpQ5IGnpn-_O_p4kGEwfNX0qLhCBbDMt1ki43eBLG6KdTwDyaJYDnGXSd85VHqxOJWBCNiTbEUtvgqS3oTot8UfEIs4DH64t4CUL5AOKl3aptDxIKK-spLIGAirhc2nHn37",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkNm6CQRG5ANfANzsfPNAxAx7CYqFYzwe1n5lficRC0yOFH2H8QZQOlzEOYNRZwkngGWEan3yVSB7MpoJwWD-PVwrfovMhy1ZM-jDlUCe6Nm008cqYfwVYIOdx2lwk4jyJllp6-HmmMGd9mVXuTx8QsUowrcBDmn6S2BX8_7t9hGFLCB_UjjE70J6pLBuzz5JRcZgDYnmMjCa33vI2TDk2aicitj3VPEg6P0COdfR1mIw6ZOZT3GjA2aTCzun8nmVzEHD7G9mBEL4XAxWFJwTBCCZm2DCjgFFTTRa72tNSerNIzRJyP8dMO7cCCVx2j5w5ySpdjz8FhgoauzFiuOY26pLu21rsylqjtSHUHS_w3I1blEUATJ-2-rv76As9ZQt24BfEktnUoHut4cLQveZRm6nNCAlavXtwZaoK7sJ1O_6ZrjGzC7P5T6L3_qww5rKYfVjzwTCjDIGKRfQv-h-9EzCRGd2_q0Dtvcv4kOTM8AMFvsfwL8jmqApUHnQ8rwZbUF5GaFekOGuZUzHJRQn5OV98i0-hFX1_csYpf3KjrEH8PBTl2755cg63a8uxZzb071PiCP2wRCX0K-M1hHTvnFPObXAsLU4iQHeaf8e0L9s6lmCkVqh6PYY9pR36izK6_bPeMrgaGswjTRmfIozr9x8fTUR7a2ZosnPPZe2PqMpIy2h43zIkSdC0vfbLNjf1gDVE1V24uWgKPaDAE4K8OqCjVdA3QlmtFXCgw-XrtFDbrS2RiftWcDioBi2xQZ6OTMXmyYFslWahESx9AqgNjY8RmIFz2kDkhTKWVFWf8yh2Y6Fi189Ykoozdwv9H7DvAkF0ahHh2xuwhWidGteEFmqb17JMpfppgC_KdG8qVjpSBPMkbliSDUchYyUK024yG4k1U55kreYlVO31aqmrQjdTyR3MBBMGMKJhGdKT_U4o2gmLmM2-gJ01xF41NpQ5IGnpn-_O_p4kGEwfNX0qLhCBbDMt1ki43eBLG6KdTwDyaJYDnGXSd85VHqxOJWBCNiTbEUtvgqS3oTot8UfEIs4DH64t4CUL5AOKl3aptDxIKK-spLIGAirhc2nHn37",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1600002939fc82",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "Z",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1600002939fc82_0",
                    "local_arrival": "2024-09-24T22:20:00.000Z",
                    "local_departure": "2024-09-24T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-09-24T20:20:00.000Z",
                    "utc_departure": "2024-09-24T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e1600002939fc82_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkNm6CQRG5ANfANzsfPNAxAx7CYqFYzwe1n5lficRC0yOFH2H8QZQOlzEOYNRZwkngGWEan3yVSB7MpoJwWD-PVwrfovMhy1ZM-jDlUCe6Nm008cqYfwVYIOdx2lwk4jyJllp6-HmmMGd9mVXuTx8QsUowrcBDmn6S2BX8_7t9hGFLCB_UjjE70J6pLBuzz5JRcZgDYnmMjCa33vI2TDk2aicitj3VPEg6P0COdfR1mIw6ZOZT3GjA2aTCzun8nmVzEHD7G9mBEL4XAxWFJwTBCCZm2DCjgFFTTRa72tNSerNIzRJyP8dMO7cCCVx2j5w5ySpdjz8FhgoauzFiuOY26pLu21rsylqjtSHUHS_w3I1blEUATJ-2-rv76As9ZQt24BfEktnUoHut4cLQveZRm6nNCAlavXtwZaoK7sJ1O_6ZrjGzC7P5T6L3_qww5rKYfVjzwTCjDIGKRfQv-h-9EzCRGd2_q0Dtvcv4kOTM8AMFvsfwL8jmqApUHnQ8rwZbUF5GaFekOGuZUzHJRQn5OV98i0-hFX1_csYpf3KjrEH8PBTl2755cg63a8uxZzb071PiCP2wRCX0K-M1hHTvnFPObXAsLU4iQHeaf8e0L9s6lmCkVqh6PYY9pR36izK6_bPeMrgaGswjTRmfIozr9x8fTUR7a2ZosnPPZe2PqMpIy2h43zIkSdC0vfbLNjf1gDVE1V24uWgKPaDAE4K8OqCjVdA3QlmtFXCgw-XrtFDbrS2RiftWcDioBi2xQZ6OTMXmyYFslWahESx9AqgNjY8RmIFz2kDkhTKWVFWf8yh2Y6Fi189Ykoozdwv9H7DvAkF0ahHh2xuwhWidGteEFmqb17JMpfppgC_KdG8qVjpSBPMkbliSDUchYyUK024yG4k1U55kreYlVO31aqmrQjdTyR3MBBMGMKJhGdKT_U4o2gmLmM2-gJ01xF41NpQ5IGnpn-_O_p4kGEwfNX0qLhCBbDMt1ki43eBLG6KdTwDyaJYDnGXSd85VHqxOJWBCNiTbEUtvgqS3oTot8UfEIs4DH64t4CUL5AOKl3aptDxIKK-spLIGAirhc2nHn37",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1677.63,
            "totalPrice": 1585
          },
          {
            "allPassengerTotalPrice": 1601,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnV4rizqRoxh5JSGsylzrItPwTlYYNYPLyAhrc7xcar1iszEVYa9gxmXW93zw32fWC6YaNHVZIWf2wh1dqKgnGdP3uxxXf7FqYnqvA5JhuRdHrMy9F70nUTltnYZnL_Hw2n3vVRVGg2l8Td04qaat-Ne4rSoLNmPRRtMK06yy_yQp6vms2-0TneA0_WTJjGAppk4oGmpB0lYUnO8iJyFoysMvJ3lOo-4CyYYHeKq45eknFKOHumV5XmRZ81pu7qECjdGf9BgrHRxQviQNe9TAMsToylPB8S_L_jmuosJcS4dJpdeKeI3N8Ii6B9S0KKDfCMT3Kvn191jTvHpdHVjeTABnLcvFhWmxHkW42-8Y_468-7watNgI8Ngm5QRhrBRY_qJYcREugewaYbnp7cwJBonmCcDX7vPakImCzuAg9rVThNHeT3f6Hz_Gyxs1xasNG3e_ER9bID9DsA-QLwSDVThZHo_CBdkk4tlnxVNG3qOBVkqLnSdAVf-g-y32L0u7SxXIOq4yaFpoXfAf8CcduXKQ5jYl-UwH35mktEP0y7_7fA-iRgKF45dY0N09yhv-WaXl4BCih_Fc-rA9meTf8ovuab-4eyvpE1tV9R6PBaU4NLVxiF8UaZ-UdO9PVZ9Vxvi_tZ7OnsGOwOGjvaaBzUtiP_of7SN9fsFwp6NBMT_SUZWq_EJZnsIXHeqcyAEbBeHntPTyoP9OmCZFzHZSrf2B_QSqiMi50pnqmly-5z4iqA72Ld8xI4fXPW9hjuIN7Ym1RRBNeiF1BLnxV0A09iRaVLPlTmFOAjNcizxlqztpiH6XTpJTDILIBQsZr0QtuiUgrLU2JTZhYDuxjVqiNF75sgosHKcxq_cLGpEYESudq1B_8UAocVGdicF_3ttMC2843RX4U8_kYtS3lt1rnpypVo-aYWcJ2gvZKUv2hpGA_KkqEgqzIq23iwDstPgtqPWp-UIXZnLyDo7T8QWP6Ic9z4pCFQNzaudHQyaa2Hpxh4GQ0CD8XmV6uTw6XpiUD0UmhbBBJ0lBPexi4Eo0NU3Fim2mr3LpM_mbJQyHn6o=",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnV4rizqRoxh5JSGsylzrItPwTlYYNYPLyAhrc7xcar1iszEVYa9gxmXW93zw32fWC6YaNHVZIWf2wh1dqKgnGdP3uxxXf7FqYnqvA5JhuRdHrMy9F70nUTltnYZnL_Hw2n3vVRVGg2l8Td04qaat-Ne4rSoLNmPRRtMK06yy_yQp6vms2-0TneA0_WTJjGAppk4oGmpB0lYUnO8iJyFoysMvJ3lOo-4CyYYHeKq45eknFKOHumV5XmRZ81pu7qECjdGf9BgrHRxQviQNe9TAMsToylPB8S_L_jmuosJcS4dJpdeKeI3N8Ii6B9S0KKDfCMT3Kvn191jTvHpdHVjeTABnLcvFhWmxHkW42-8Y_468-7watNgI8Ngm5QRhrBRY_qJYcREugewaYbnp7cwJBonmCcDX7vPakImCzuAg9rVThNHeT3f6Hz_Gyxs1xasNG3e_ER9bID9DsA-QLwSDVThZHo_CBdkk4tlnxVNG3qOBVkqLnSdAVf-g-y32L0u7SxXIOq4yaFpoXfAf8CcduXKQ5jYl-UwH35mktEP0y7_7fA-iRgKF45dY0N09yhv-WaXl4BCih_Fc-rA9meTf8ovuab-4eyvpE1tV9R6PBaU4NLVxiF8UaZ-UdO9PVZ9Vxvi_tZ7OnsGOwOGjvaaBzUtiP_of7SN9fsFwp6NBMT_SUZWq_EJZnsIXHeqcyAEbBeHntPTyoP9OmCZFzHZSrf2B_QSqiMi50pnqmly-5z4iqA72Ld8xI4fXPW9hjuIN7Ym1RRBNeiF1BLnxV0A09iRaVLPlTmFOAjNcizxlqztpiH6XTpJTDILIBQsZr0QtuiUgrLU2JTZhYDuxjVqiNF75sgosHKcxq_cLGpEYESudq1B_8UAocVGdicF_3ttMC2843RX4U8_kYtS3lt1rnpypVo-aYWcJ2gvZKUv2hpGA_KkqEgqzIq23iwDstPgtqPWp-UIXZnLyDo7T8QWP6Ic9z4pCFQNzaudHQyaa2Hpxh4GQ0CD8XmV6uTw6XpiUD0UmhbBBJ0lBPexi4Eo0NU3Fim2mr3LpM_mbJQyHn6o=",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e160000ce81d185",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "N",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e160000ce81d185_0",
                    "local_arrival": "2024-09-24T13:45:00.000Z",
                    "local_departure": "2024-09-24T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-09-24T11:45:00.000Z",
                    "utc_departure": "2024-09-24T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e160000ce81d185_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnV4rizqRoxh5JSGsylzrItPwTlYYNYPLyAhrc7xcar1iszEVYa9gxmXW93zw32fWC6YaNHVZIWf2wh1dqKgnGdP3uxxXf7FqYnqvA5JhuRdHrMy9F70nUTltnYZnL_Hw2n3vVRVGg2l8Td04qaat-Ne4rSoLNmPRRtMK06yy_yQp6vms2-0TneA0_WTJjGAppk4oGmpB0lYUnO8iJyFoysMvJ3lOo-4CyYYHeKq45eknFKOHumV5XmRZ81pu7qECjdGf9BgrHRxQviQNe9TAMsToylPB8S_L_jmuosJcS4dJpdeKeI3N8Ii6B9S0KKDfCMT3Kvn191jTvHpdHVjeTABnLcvFhWmxHkW42-8Y_468-7watNgI8Ngm5QRhrBRY_qJYcREugewaYbnp7cwJBonmCcDX7vPakImCzuAg9rVThNHeT3f6Hz_Gyxs1xasNG3e_ER9bID9DsA-QLwSDVThZHo_CBdkk4tlnxVNG3qOBVkqLnSdAVf-g-y32L0u7SxXIOq4yaFpoXfAf8CcduXKQ5jYl-UwH35mktEP0y7_7fA-iRgKF45dY0N09yhv-WaXl4BCih_Fc-rA9meTf8ovuab-4eyvpE1tV9R6PBaU4NLVxiF8UaZ-UdO9PVZ9Vxvi_tZ7OnsGOwOGjvaaBzUtiP_of7SN9fsFwp6NBMT_SUZWq_EJZnsIXHeqcyAEbBeHntPTyoP9OmCZFzHZSrf2B_QSqiMi50pnqmly-5z4iqA72Ld8xI4fXPW9hjuIN7Ym1RRBNeiF1BLnxV0A09iRaVLPlTmFOAjNcizxlqztpiH6XTpJTDILIBQsZr0QtuiUgrLU2JTZhYDuxjVqiNF75sgosHKcxq_cLGpEYESudq1B_8UAocVGdicF_3ttMC2843RX4U8_kYtS3lt1rnpypVo-aYWcJ2gvZKUv2hpGA_KkqEgqzIq23iwDstPgtqPWp-UIXZnLyDo7T8QWP6Ic9z4pCFQNzaudHQyaa2Hpxh4GQ0CD8XmV6uTw6XpiUD0UmhbBBJ0lBPexi4Eo0NU3Fim2mr3LpM_mbJQyHn6o=",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1689.97,
            "totalPrice": 1601
          },
          {
            "allPassengerTotalPrice": 1610,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-15",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000067044a57_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlOOiq8OadcnDLIGETe6OYGPCc6YwjZ3FJNq9K5FSM0ciCmn-Os2tYmL13FN27YkKlU0Cm99P6EGhnbVGpDibK0ULgqXr6CAa3PgmRVIfgamtGGDCH78p2q9ItU_Zgh9AwFSJW7RkA8MBl-TK54wbTgPoNU9lLVau6YBSy9em3BqL1vNbm6hv7G8f0RGez2fLC_SnQ3_GEGLLDpD95HsNVpEVv5buQyQ5WTUneLxZcWF57diyL7DXxWJ60yIqSQbVZ9GHBayIRqTmwUi_XRpfk8CG6B9PcNpehbKt20hNM4uDb3Iog5siC9oKuJ19QoSwmYhjCacOJSwn7eQe-eb8zaJmtmp2QxBW_dPh6bkUUxlbJT_9nEht-r0-PQ6MiSZoJGyBMw9BmicaoiaDUNo8JwWoXOjrP3W_oe03XesUM9ijH40yHaw1yM8WALpFSRON3tQNOwftHuO6jPETlTZAyAgQF5fbq0sRr457USTII9olcJHWI4wta4FUExv9bUHQrqMGTSWUVD7VqFBPZ5MAllgMxQcZq7QWPhI9MqT44rpTSKj3aOpj4tuf2jy_p8mKNK2ymJPf9IZCWyIojMiy-Fxl8AjSDdY3iDulNv6qWT5N84Dn_lLlMWYGPXZDhEWAhFswo7hNDumwJ8qTvqqF6-JHG7rVabBK-_FouBSPUv8oNr51lO2pUNzIlvWKDvzrhv24JTfarEQUPDAWyhNzgjgpVOHLUVLJAmWA3ahHLfx8ath-sMOiURsZycxGhDHqYLAaghuXf8iQ4EAPdceAcqj3DNPIPi4XFzjym3nQ8VZIdW8lodmHE9MKgIqJhurmlrtF-_FDEXPvnxEPtgKCUVL93w6exMH6f89zSaK0GcqWkwDsCjXZkMCKbviTs8PGDlpHBmFJ2y1SX_Bv6MfJge44pKS4upuT98uqV3oMiV0zA4d7suI8D597ERuDXQ3IGQ6zXsqVkoUO0rm5G_38LxX3hD4siW99Q3f2XTr_x9-t3u_laZNiUFpxmGaxh76xSwT1Td3UbsDWwqRUs2O0xg==",
                "dateChange": 1,
                "departureDate": "2024-09-14",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e0c0000d47978c5",
                    "equipment": null,
                    "fare_basis": "SIL2D2WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e0c0000d47978c5_0",
                    "local_arrival": "2024-09-15T17:00:00.000Z",
                    "local_departure": "2024-09-14T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-15T15:00:00.000Z",
                    "utc_departure": "2024-09-15T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-09-24",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000067044a57_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlOOiq8OadcnDLIGETe6OYGPCc6YwjZ3FJNq9K5FSM0ciCmn-Os2tYmL13FN27YkKlU0Cm99P6EGhnbVGpDibK0ULgqXr6CAa3PgmRVIfgamtGGDCH78p2q9ItU_Zgh9AwFSJW7RkA8MBl-TK54wbTgPoNU9lLVau6YBSy9em3BqL1vNbm6hv7G8f0RGez2fLC_SnQ3_GEGLLDpD95HsNVpEVv5buQyQ5WTUneLxZcWF57diyL7DXxWJ60yIqSQbVZ9GHBayIRqTmwUi_XRpfk8CG6B9PcNpehbKt20hNM4uDb3Iog5siC9oKuJ19QoSwmYhjCacOJSwn7eQe-eb8zaJmtmp2QxBW_dPh6bkUUxlbJT_9nEht-r0-PQ6MiSZoJGyBMw9BmicaoiaDUNo8JwWoXOjrP3W_oe03XesUM9ijH40yHaw1yM8WALpFSRON3tQNOwftHuO6jPETlTZAyAgQF5fbq0sRr457USTII9olcJHWI4wta4FUExv9bUHQrqMGTSWUVD7VqFBPZ5MAllgMxQcZq7QWPhI9MqT44rpTSKj3aOpj4tuf2jy_p8mKNK2ymJPf9IZCWyIojMiy-Fxl8AjSDdY3iDulNv6qWT5N84Dn_lLlMWYGPXZDhEWAhFswo7hNDumwJ8qTvqqF6-JHG7rVabBK-_FouBSPUv8oNr51lO2pUNzIlvWKDvzrhv24JTfarEQUPDAWyhNzgjgpVOHLUVLJAmWA3ahHLfx8ath-sMOiURsZycxGhDHqYLAaghuXf8iQ4EAPdceAcqj3DNPIPi4XFzjym3nQ8VZIdW8lodmHE9MKgIqJhurmlrtF-_FDEXPvnxEPtgKCUVL93w6exMH6f89zSaK0GcqWkwDsCjXZkMCKbviTs8PGDlpHBmFJ2y1SX_Bv6MfJge44pKS4upuT98uqV3oMiV0zA4d7suI8D597ERuDXQ3IGQ6zXsqVkoUO0rm5G_38LxX3hD4siW99Q3f2XTr_x9-t3u_laZNiUFpxmGaxh76xSwT1Td3UbsDWwqRUs2O0xg==",
                "dateChange": 0,
                "departureDate": "2024-09-24",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e16000067044a57",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e16000067044a57_0",
                    "local_arrival": "2024-09-24T10:25:00.000Z",
                    "local_departure": "2024-09-24T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-09-24T08:25:00.000Z",
                    "utc_departure": "2024-09-24T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "IB",
                  "AA"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "00:14",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e0c0000d47978c5_0%7C14140a224e16000067044a57_0%7C04b80f9c4e1c0000ee3bd980_0%7C04b80f9c4e1c0000ee3bd980_1%7C04b80f9c4e1c0000ee3bd980_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlOOiq8OadcnDLIGETe6OYGPCc6YwjZ3FJNq9K5FSM0ciCmn-Os2tYmL13FN27YkKlU0Cm99P6EGhnbVGpDibK0ULgqXr6CAa3PgmRVIfgamtGGDCH78p2q9ItU_Zgh9AwFSJW7RkA8MBl-TK54wbTgPoNU9lLVau6YBSy9em3BqL1vNbm6hv7G8f0RGez2fLC_SnQ3_GEGLLDpD95HsNVpEVv5buQyQ5WTUneLxZcWF57diyL7DXxWJ60yIqSQbVZ9GHBayIRqTmwUi_XRpfk8CG6B9PcNpehbKt20hNM4uDb3Iog5siC9oKuJ19QoSwmYhjCacOJSwn7eQe-eb8zaJmtmp2QxBW_dPh6bkUUxlbJT_9nEht-r0-PQ6MiSZoJGyBMw9BmicaoiaDUNo8JwWoXOjrP3W_oe03XesUM9ijH40yHaw1yM8WALpFSRON3tQNOwftHuO6jPETlTZAyAgQF5fbq0sRr457USTII9olcJHWI4wta4FUExv9bUHQrqMGTSWUVD7VqFBPZ5MAllgMxQcZq7QWPhI9MqT44rpTSKj3aOpj4tuf2jy_p8mKNK2ymJPf9IZCWyIojMiy-Fxl8AjSDdY3iDulNv6qWT5N84Dn_lLlMWYGPXZDhEWAhFswo7hNDumwJ8qTvqqF6-JHG7rVabBK-_FouBSPUv8oNr51lO2pUNzIlvWKDvzrhv24JTfarEQUPDAWyhNzgjgpVOHLUVLJAmWA3ahHLfx8ath-sMOiURsZycxGhDHqYLAaghuXf8iQ4EAPdceAcqj3DNPIPi4XFzjym3nQ8VZIdW8lodmHE9MKgIqJhurmlrtF-_FDEXPvnxEPtgKCUVL93w6exMH6f89zSaK0GcqWkwDsCjXZkMCKbviTs8PGDlpHBmFJ2y1SX_Bv6MfJge44pKS4upuT98uqV3oMiV0zA4d7suI8D597ERuDXQ3IGQ6zXsqVkoUO0rm5G_38LxX3hD4siW99Q3f2XTr_x9-t3u_laZNiUFpxmGaxh76xSwT1Td3UbsDWwqRUs2O0xg==",
                "dateChange": 1,
                "departureDate": "2024-09-30",
                "departureTime": "09:00",
                "destination": "SFO",
                "duration": "24h14m",
                "routes": [
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5586,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_0",
                    "local_arrival": "2024-09-30T10:10:00.000Z",
                    "local_departure": "2024-09-30T09:00:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3947",
                    "return": 0,
                    "utc_arrival": "2024-09-30T08:10:00.000Z",
                    "utc_departure": "2024-09-30T07:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "NYC",
                    "cityFrom": "Madrid",
                    "cityTo": "New York",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 5655,
                    "flyFrom": "MAD",
                    "flyTo": "JFK",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_1",
                    "local_arrival": "2024-09-30T18:55:00.000Z",
                    "local_departure": "2024-09-30T16:20:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6253",
                    "return": 0,
                    "utc_arrival": "2024-09-30T22:55:00.000Z",
                    "utc_departure": "2024-09-30T14:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "NYC",
                    "cityCodeTo": "SFO",
                    "cityFrom": "New York",
                    "cityTo": "San Francisco",
                    "combination_id": "04b80f9c4e1c0000ee3bd980",
                    "equipment": null,
                    "fare_basis": "VKX8C5M4",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 4172,
                    "flyFrom": "JFK",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "04b80f9c4e1c0000ee3bd980_2",
                    "local_arrival": "2024-10-01T00:14:00.000Z",
                    "local_departure": "2024-09-30T20:30:00.000Z",
                    "operating_carrier": "AA",
                    "operating_flight_no": "177",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:14:00.000Z",
                    "utc_departure": "2024-10-01T00:30:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1698.33,
            "totalPrice": 1610
          }
        ]
      },
      {
        "dateOptionLabel": "2024-09-21 to 2024-10-07",
        "itineraries": [
          {
            "allPassengerTotalPrice": 766,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDuWHhEjQgM-3ZTBmBv1AMbwDN0BuuuCGhLFH0g0kz9E5pFSGafJ7RL5OZOatWWlaiKtKM5200AROk16mjozIlTmZBj_supfrrYge6A-4C_MsroPLwV-qUaD363Bf9fXOcGLVDVlkgdIkin2uOWfjKanPY5bErD81lGnTQR2lxRy3tbtjTLStUFDz2FHKfSFqt5y3S79opjzzNfjrhgF5183WKWJo41ueHUpU0ktoLBj_VOw_0-7qLgrurm3cnOH1UOVmaT0SwZ0yoVfVXX3MsDZ8tXJr-4toFE7ipK8DUluicEWixekTJ9ovXJC_PA3eOEkRX2K4sfrXntcm11t4BV568U_2zh_BAP7jKbXC0kHG0I2GGbS4tCGFDCQ_68HdBTe8Hw-zCHvgIHTtDpcz6z9kg6hU_RiTAm9h6XuEAYDqUvDRZrSIO8giz0PALKbpjXU8kTanqkaoJkJlXLeXQmWsf-DxW07-GDCu71lcb_RdF99pL_ifaYsf6XWIDqyRRG33tgZvv9v48GjXsdS9lGO6c7z2jLbD1mC9IgDql79T_cosa5ZzpxUkLO-BIq_7a_rCwJ8ra3wIXilHW0US4VnZBUZ0iLFzNMCvSpW_afHoeZ9a1-lXtBYghW49A-usUBdzQ9wViwDI2XDj5vrBvO7J7iny2_JGS6vyIcY1F9CVGP2wBUhexi1xMbKFAub4q0Ifym1gTXXUOx0EYwJI8mqh8O4Q1jIYm3poVDYHVaQcDniYyRZ4Qn5isbfNWL2e1Hce8dZzy7RbUy6mwDI2cVWwDhX66uHtH5wqMSl-8KZbGgWu9UftzbZxKr-0OCQm6VHR2Gwi-pN1RObBtvxshkL4p-3Bgf0s3YPfRRV_7bPLbOHfNGOJcA-xvNcrJhw3f-DT4W5ujx7yRb_uF4juE6gXtBh_igIjAK8MHZgbEe4N6Tf60N4IL-w3CZyGDJtkQnkKXj1P7S85JUjecscooWbTQQ0yIsT3ZClH7bRve7DJWjPK8juWzbazHF7teMiZWGqrLYFoYhQkK7tjlVnuc2-UJzAZVBAd0hMHKMwdS18Uz4My31MdVZ4f-avgxEVm-YtYHzDfG8BP5MaS-CTBAzLbUIjXfyHjnf-33dgoYGDl-929gBRXhgFaTh0pA_GVTkQ7CvF6dYPRptsUcCrOPXylx4r9hY9MGDcWHHa1ZeHyrz-1VfgjnkAxCgt1h1b53PegNwDxcp_bK4Oy5xGWaA==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDuWHhEjQgM-3ZTBmBv1AMbwDN0BuuuCGhLFH0g0kz9E5pFSGafJ7RL5OZOatWWlaiKtKM5200AROk16mjozIlTmZBj_supfrrYge6A-4C_MsroPLwV-qUaD363Bf9fXOcGLVDVlkgdIkin2uOWfjKanPY5bErD81lGnTQR2lxRy3tbtjTLStUFDz2FHKfSFqt5y3S79opjzzNfjrhgF5183WKWJo41ueHUpU0ktoLBj_VOw_0-7qLgrurm3cnOH1UOVmaT0SwZ0yoVfVXX3MsDZ8tXJr-4toFE7ipK8DUluicEWixekTJ9ovXJC_PA3eOEkRX2K4sfrXntcm11t4BV568U_2zh_BAP7jKbXC0kHG0I2GGbS4tCGFDCQ_68HdBTe8Hw-zCHvgIHTtDpcz6z9kg6hU_RiTAm9h6XuEAYDqUvDRZrSIO8giz0PALKbpjXU8kTanqkaoJkJlXLeXQmWsf-DxW07-GDCu71lcb_RdF99pL_ifaYsf6XWIDqyRRG33tgZvv9v48GjXsdS9lGO6c7z2jLbD1mC9IgDql79T_cosa5ZzpxUkLO-BIq_7a_rCwJ8ra3wIXilHW0US4VnZBUZ0iLFzNMCvSpW_afHoeZ9a1-lXtBYghW49A-usUBdzQ9wViwDI2XDj5vrBvO7J7iny2_JGS6vyIcY1F9CVGP2wBUhexi1xMbKFAub4q0Ifym1gTXXUOx0EYwJI8mqh8O4Q1jIYm3poVDYHVaQcDniYyRZ4Qn5isbfNWL2e1Hce8dZzy7RbUy6mwDI2cVWwDhX66uHtH5wqMSl-8KZbGgWu9UftzbZxKr-0OCQm6VHR2Gwi-pN1RObBtvxshkL4p-3Bgf0s3YPfRRV_7bPLbOHfNGOJcA-xvNcrJhw3f-DT4W5ujx7yRb_uF4juE6gXtBh_igIjAK8MHZgbEe4N6Tf60N4IL-w3CZyGDJtkQnkKXj1P7S85JUjecscooWbTQQ0yIsT3ZClH7bRve7DJWjPK8juWzbazHF7teMiZWGqrLYFoYhQkK7tjlVnuc2-UJzAZVBAd0hMHKMwdS18Uz4My31MdVZ4f-avgxEVm-YtYHzDfG8BP5MaS-CTBAzLbUIjXfyHjnf-33dgoYGDl-929gBRXhgFaTh0pA_GVTkQ7CvF6dYPRptsUcCrOPXylx4r9hY9MGDcWHHa1ZeHyrz-1VfgjnkAxCgt1h1b53PegNwDxcp_bK4Oy5xGWaA==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDuWHhEjQgM-3ZTBmBv1AMbwDN0BuuuCGhLFH0g0kz9E5pFSGafJ7RL5OZOatWWlaiKtKM5200AROk16mjozIlTmZBj_supfrrYge6A-4C_MsroPLwV-qUaD363Bf9fXOcGLVDVlkgdIkin2uOWfjKanPY5bErD81lGnTQR2lxRy3tbtjTLStUFDz2FHKfSFqt5y3S79opjzzNfjrhgF5183WKWJo41ueHUpU0ktoLBj_VOw_0-7qLgrurm3cnOH1UOVmaT0SwZ0yoVfVXX3MsDZ8tXJr-4toFE7ipK8DUluicEWixekTJ9ovXJC_PA3eOEkRX2K4sfrXntcm11t4BV568U_2zh_BAP7jKbXC0kHG0I2GGbS4tCGFDCQ_68HdBTe8Hw-zCHvgIHTtDpcz6z9kg6hU_RiTAm9h6XuEAYDqUvDRZrSIO8giz0PALKbpjXU8kTanqkaoJkJlXLeXQmWsf-DxW07-GDCu71lcb_RdF99pL_ifaYsf6XWIDqyRRG33tgZvv9v48GjXsdS9lGO6c7z2jLbD1mC9IgDql79T_cosa5ZzpxUkLO-BIq_7a_rCwJ8ra3wIXilHW0US4VnZBUZ0iLFzNMCvSpW_afHoeZ9a1-lXtBYghW49A-usUBdzQ9wViwDI2XDj5vrBvO7J7iny2_JGS6vyIcY1F9CVGP2wBUhexi1xMbKFAub4q0Ifym1gTXXUOx0EYwJI8mqh8O4Q1jIYm3poVDYHVaQcDniYyRZ4Qn5isbfNWL2e1Hce8dZzy7RbUy6mwDI2cVWwDhX66uHtH5wqMSl-8KZbGgWu9UftzbZxKr-0OCQm6VHR2Gwi-pN1RObBtvxshkL4p-3Bgf0s3YPfRRV_7bPLbOHfNGOJcA-xvNcrJhw3f-DT4W5ujx7yRb_uF4juE6gXtBh_igIjAK8MHZgbEe4N6Tf60N4IL-w3CZyGDJtkQnkKXj1P7S85JUjecscooWbTQQ0yIsT3ZClH7bRve7DJWjPK8juWzbazHF7teMiZWGqrLYFoYhQkK7tjlVnuc2-UJzAZVBAd0hMHKMwdS18Uz4My31MdVZ4f-avgxEVm-YtYHzDfG8BP5MaS-CTBAzLbUIjXfyHjnf-33dgoYGDl-929gBRXhgFaTh0pA_GVTkQ7CvF6dYPRptsUcCrOPXylx4r9hY9MGDcWHHa1ZeHyrz-1VfgjnkAxCgt1h1b53PegNwDxcp_bK4Oy5xGWaA==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1006.54,
            "totalPrice": 766
          },
          {
            "allPassengerTotalPrice": 766,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_IRzleXphgHqb9jraJJSbpjsVcYagMMRU5JXpN8gnwNRyJ6Pl1QQQRBt_kbjyr_BlqgdqZr1TSYtKToU5ChSIqfh-mhjXjy0rYO-y12ru-SeoFtdz-yh1QI3c9QL4XLVtWjMJOj-UyT9h2yxEAvnpoX0ro_f4N4VugMuFr620KLElW71myulLTPvJSaTBSXR87AnLjSzGcsdclVtj_hp08OqwLZIBPWLs8RFsAjkWIIX1yBQIkMSMZCgOlVictE8xWLh1I3XVBh5bOcswx_m3-x_sYtn0m88H6X66QfMWufErf_wiJOxmq--iCaT-kifnr5yGXRHdd3vmx9RUBI1l6pfme9uuAr_bJluvUiyNdt_G4mAUCNqaEeVizY7oeid9lQYES0oAT639_JHXn5OwTtKFM0JUjEWMFeU6SJypuw8tYSkKUD1Cr2wt-93kB4ygRdmphcADd1ugpH5TV9BlAawb0NJAeUGHJEb3BG2wtnD1v6QoY8lCXejwaAq51mfS6JXpPtfQw05C8IzWFxmWravCkMI4qDohZ0XEDhk3Pf2Xsw4en6FSwzi-QmrZgez3Q3X3681Wr9hSBqyTxkqEDge7h6cmKflWYioaBiP1dv3GuqvqaUKpa50hn4TFP2YlScvf1bpK0NGG_6oJun_Qug3-EaXXnLvYYfeYKxfks5NbxQ1KdozhLPI_oO3-nx1kn-KkFIcr1OZGgHLcvDL2cs7rDyaqyCRyOrFhTHA850CVDXCPXvGxXBIFjX0WxQM0gtu8PFuKUk7hzlTAMFidgNprcbEbhizzLoNDIQFMrUwu4xz-FG3UhioF_41I15TFZouB-xERAZdmD5M-RBRVOF2uMpxsGvVewXKofJkQUvfn8cewC69SE9TGz0mtEX3VmKJL8agjsyNuEvUSOJXQz6VO7sYEXWo4bfsCqMcxeyy8DsZayq-X-Ju2v2W4HRevXnwIQEvUTSr_cUEeWA8fMqMy92_5vKr4jKyV9DCzPKJcmKppUPRRJ1q0m_OQ-mFZCKMa9JPkZSpuK204XXWr2sITR0k0XL9z6q0sBmst9NYe_dr1iOMGTibsOFfUb6x3_z6iCEq6SLM37MfeJFsrk5UzyRZBsBOFno6TRwcKnuxNa2nuH45BkQoM7CJavnQIvMyn4e78z6PSa_efC7MsEyfubbJrwqqYxssBb5zpfNQgqrZWclpa0DVP24gFkCPJX5lhvgLRNx0RJFoAOiWtQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_IRzleXphgHqb9jraJJSbpjsVcYagMMRU5JXpN8gnwNRyJ6Pl1QQQRBt_kbjyr_BlqgdqZr1TSYtKToU5ChSIqfh-mhjXjy0rYO-y12ru-SeoFtdz-yh1QI3c9QL4XLVtWjMJOj-UyT9h2yxEAvnpoX0ro_f4N4VugMuFr620KLElW71myulLTPvJSaTBSXR87AnLjSzGcsdclVtj_hp08OqwLZIBPWLs8RFsAjkWIIX1yBQIkMSMZCgOlVictE8xWLh1I3XVBh5bOcswx_m3-x_sYtn0m88H6X66QfMWufErf_wiJOxmq--iCaT-kifnr5yGXRHdd3vmx9RUBI1l6pfme9uuAr_bJluvUiyNdt_G4mAUCNqaEeVizY7oeid9lQYES0oAT639_JHXn5OwTtKFM0JUjEWMFeU6SJypuw8tYSkKUD1Cr2wt-93kB4ygRdmphcADd1ugpH5TV9BlAawb0NJAeUGHJEb3BG2wtnD1v6QoY8lCXejwaAq51mfS6JXpPtfQw05C8IzWFxmWravCkMI4qDohZ0XEDhk3Pf2Xsw4en6FSwzi-QmrZgez3Q3X3681Wr9hSBqyTxkqEDge7h6cmKflWYioaBiP1dv3GuqvqaUKpa50hn4TFP2YlScvf1bpK0NGG_6oJun_Qug3-EaXXnLvYYfeYKxfks5NbxQ1KdozhLPI_oO3-nx1kn-KkFIcr1OZGgHLcvDL2cs7rDyaqyCRyOrFhTHA850CVDXCPXvGxXBIFjX0WxQM0gtu8PFuKUk7hzlTAMFidgNprcbEbhizzLoNDIQFMrUwu4xz-FG3UhioF_41I15TFZouB-xERAZdmD5M-RBRVOF2uMpxsGvVewXKofJkQUvfn8cewC69SE9TGz0mtEX3VmKJL8agjsyNuEvUSOJXQz6VO7sYEXWo4bfsCqMcxeyy8DsZayq-X-Ju2v2W4HRevXnwIQEvUTSr_cUEeWA8fMqMy92_5vKr4jKyV9DCzPKJcmKppUPRRJ1q0m_OQ-mFZCKMa9JPkZSpuK204XXWr2sITR0k0XL9z6q0sBmst9NYe_dr1iOMGTibsOFfUb6x3_z6iCEq6SLM37MfeJFsrk5UzyRZBsBOFno6TRwcKnuxNa2nuH45BkQoM7CJavnQIvMyn4e78z6PSa_efC7MsEyfubbJrwqqYxssBb5zpfNQgqrZWclpa0DVP24gFkCPJX5lhvgLRNx0RJFoAOiWtQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_IRzleXphgHqb9jraJJSbpjsVcYagMMRU5JXpN8gnwNRyJ6Pl1QQQRBt_kbjyr_BlqgdqZr1TSYtKToU5ChSIqfh-mhjXjy0rYO-y12ru-SeoFtdz-yh1QI3c9QL4XLVtWjMJOj-UyT9h2yxEAvnpoX0ro_f4N4VugMuFr620KLElW71myulLTPvJSaTBSXR87AnLjSzGcsdclVtj_hp08OqwLZIBPWLs8RFsAjkWIIX1yBQIkMSMZCgOlVictE8xWLh1I3XVBh5bOcswx_m3-x_sYtn0m88H6X66QfMWufErf_wiJOxmq--iCaT-kifnr5yGXRHdd3vmx9RUBI1l6pfme9uuAr_bJluvUiyNdt_G4mAUCNqaEeVizY7oeid9lQYES0oAT639_JHXn5OwTtKFM0JUjEWMFeU6SJypuw8tYSkKUD1Cr2wt-93kB4ygRdmphcADd1ugpH5TV9BlAawb0NJAeUGHJEb3BG2wtnD1v6QoY8lCXejwaAq51mfS6JXpPtfQw05C8IzWFxmWravCkMI4qDohZ0XEDhk3Pf2Xsw4en6FSwzi-QmrZgez3Q3X3681Wr9hSBqyTxkqEDge7h6cmKflWYioaBiP1dv3GuqvqaUKpa50hn4TFP2YlScvf1bpK0NGG_6oJun_Qug3-EaXXnLvYYfeYKxfks5NbxQ1KdozhLPI_oO3-nx1kn-KkFIcr1OZGgHLcvDL2cs7rDyaqyCRyOrFhTHA850CVDXCPXvGxXBIFjX0WxQM0gtu8PFuKUk7hzlTAMFidgNprcbEbhizzLoNDIQFMrUwu4xz-FG3UhioF_41I15TFZouB-xERAZdmD5M-RBRVOF2uMpxsGvVewXKofJkQUvfn8cewC69SE9TGz0mtEX3VmKJL8agjsyNuEvUSOJXQz6VO7sYEXWo4bfsCqMcxeyy8DsZayq-X-Ju2v2W4HRevXnwIQEvUTSr_cUEeWA8fMqMy92_5vKr4jKyV9DCzPKJcmKppUPRRJ1q0m_OQ-mFZCKMa9JPkZSpuK204XXWr2sITR0k0XL9z6q0sBmst9NYe_dr1iOMGTibsOFfUb6x3_z6iCEq6SLM37MfeJFsrk5UzyRZBsBOFno6TRwcKnuxNa2nuH45BkQoM7CJavnQIvMyn4e78z6PSa_efC7MsEyfubbJrwqqYxssBb5zpfNQgqrZWclpa0DVP24gFkCPJX5lhvgLRNx0RJFoAOiWtQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1006.54,
            "totalPrice": 766
          },
          {
            "allPassengerTotalPrice": 766,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbpDebhv-W-h0-ihuZO9H5XbnlcufziGh3AGIpW8h6krvSajEUPh5i_dud42lxSJpwrMds8kYXha6OFmijT8YKG6sse-cw-xk4PebFsSHpfc-8ILHtPbSfeUrA5ch5Bzy12ty8O13d55Rj8ZuZHhpGov7OboZpr09_uXI5YSY51AF-TowyoUkfpH964Vf3jdFJvhphJO0a_zNoORaGfGeD5qDdyrGkX4PwhcbNk8L_zw-vduck4KB6V_oD-SkKxizXqX5U-bFlEo0elcd78rvchJgvCp8lWRKzC7TRWbSQokuTIkpdz9CNEdLBmYfD31nki0BqVm9g3XW81jOauZBQW074EkgGXDxisf7z73xGdTFIi2iQssCjX4XpkmE3YC2I0ZiYwlmT28QcNRqrhj0FksxSqlZKIyyd8NcpI6MRJ_0uuG2D8I37yzr37Km2yAnEfglACMb5ky2fbHE_QZOWD2ijeCs4fsCdfhszC5m6j7meEQNvK_Z2wYrGoyThuwk5dzu0ETZQsUGbr18WFhWvdk-8-EnUKm5eNq58BZOExOBWiwozXl-bBftE4DpLxJNylUaF0NNwBMVnW7dIKAzRB4KYuCZp7781D7K8neU8jwJurWtV-_NBxIeWTTw2f_wxFDKFCRmWO1kLKKiIT500S2NOzXo3yBmzwlNty9lbkeVlIoMPYptS5A9453vQIJpTm_seTrnjX0uNhwIYjaviF7vKr_dC30bPpDSwGvk0KSobS5lgLuIGOxw4wmoLRGeng1raq1uou_yVE_NMFw4hJE-jXXY5gY3kuZ1Dnkj1Uz8p__1lPK1Gt0gGaZ-EgDFOcGVvAbuTW779kcg4_H6Mwuky1FJ6QbUNun-YntTKPQVO_3kS9-WVyntLZ0-I3tgxYdo5qYQf2Lct1bEVmUhpf7vyAoIPqpnSw1au5td9w5mvg3xpgJ5yo0F4CnDy7BbTSHjAT1aShiHIcENwkTtkgUsHkNHqKAc9jFy7Cssf3lzrqfHFezYnuoVkda8KCddTUi3JDNvOAL5qIbHC2Cd_PEn2V6CzjodPn0osJVjFK8NZrUBWgza7bnxcGVwFK6K4CuiLb9K2fiWQgVm-nOdg0vCIagOtMGx-uacgaHEttcugzao0lrV-pAGn2EkDzb_1oPI-qEA2oyZ3wVf8ikK3K_EuBCVtvC5ONiKpLyMQvNzMoVvzxSc4L7L4B3S0lT43YwGdGKGe0fykGNZySIC_NJFdJc36g9E_kdHDfhXpAU=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbpDebhv-W-h0-ihuZO9H5XbnlcufziGh3AGIpW8h6krvSajEUPh5i_dud42lxSJpwrMds8kYXha6OFmijT8YKG6sse-cw-xk4PebFsSHpfc-8ILHtPbSfeUrA5ch5Bzy12ty8O13d55Rj8ZuZHhpGov7OboZpr09_uXI5YSY51AF-TowyoUkfpH964Vf3jdFJvhphJO0a_zNoORaGfGeD5qDdyrGkX4PwhcbNk8L_zw-vduck4KB6V_oD-SkKxizXqX5U-bFlEo0elcd78rvchJgvCp8lWRKzC7TRWbSQokuTIkpdz9CNEdLBmYfD31nki0BqVm9g3XW81jOauZBQW074EkgGXDxisf7z73xGdTFIi2iQssCjX4XpkmE3YC2I0ZiYwlmT28QcNRqrhj0FksxSqlZKIyyd8NcpI6MRJ_0uuG2D8I37yzr37Km2yAnEfglACMb5ky2fbHE_QZOWD2ijeCs4fsCdfhszC5m6j7meEQNvK_Z2wYrGoyThuwk5dzu0ETZQsUGbr18WFhWvdk-8-EnUKm5eNq58BZOExOBWiwozXl-bBftE4DpLxJNylUaF0NNwBMVnW7dIKAzRB4KYuCZp7781D7K8neU8jwJurWtV-_NBxIeWTTw2f_wxFDKFCRmWO1kLKKiIT500S2NOzXo3yBmzwlNty9lbkeVlIoMPYptS5A9453vQIJpTm_seTrnjX0uNhwIYjaviF7vKr_dC30bPpDSwGvk0KSobS5lgLuIGOxw4wmoLRGeng1raq1uou_yVE_NMFw4hJE-jXXY5gY3kuZ1Dnkj1Uz8p__1lPK1Gt0gGaZ-EgDFOcGVvAbuTW779kcg4_H6Mwuky1FJ6QbUNun-YntTKPQVO_3kS9-WVyntLZ0-I3tgxYdo5qYQf2Lct1bEVmUhpf7vyAoIPqpnSw1au5td9w5mvg3xpgJ5yo0F4CnDy7BbTSHjAT1aShiHIcENwkTtkgUsHkNHqKAc9jFy7Cssf3lzrqfHFezYnuoVkda8KCddTUi3JDNvOAL5qIbHC2Cd_PEn2V6CzjodPn0osJVjFK8NZrUBWgza7bnxcGVwFK6K4CuiLb9K2fiWQgVm-nOdg0vCIagOtMGx-uacgaHEttcugzao0lrV-pAGn2EkDzb_1oPI-qEA2oyZ3wVf8ikK3K_EuBCVtvC5ONiKpLyMQvNzMoVvzxSc4L7L4B3S0lT43YwGdGKGe0fykGNZySIC_NJFdJc36g9E_kdHDfhXpAU=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbpDebhv-W-h0-ihuZO9H5XbnlcufziGh3AGIpW8h6krvSajEUPh5i_dud42lxSJpwrMds8kYXha6OFmijT8YKG6sse-cw-xk4PebFsSHpfc-8ILHtPbSfeUrA5ch5Bzy12ty8O13d55Rj8ZuZHhpGov7OboZpr09_uXI5YSY51AF-TowyoUkfpH964Vf3jdFJvhphJO0a_zNoORaGfGeD5qDdyrGkX4PwhcbNk8L_zw-vduck4KB6V_oD-SkKxizXqX5U-bFlEo0elcd78rvchJgvCp8lWRKzC7TRWbSQokuTIkpdz9CNEdLBmYfD31nki0BqVm9g3XW81jOauZBQW074EkgGXDxisf7z73xGdTFIi2iQssCjX4XpkmE3YC2I0ZiYwlmT28QcNRqrhj0FksxSqlZKIyyd8NcpI6MRJ_0uuG2D8I37yzr37Km2yAnEfglACMb5ky2fbHE_QZOWD2ijeCs4fsCdfhszC5m6j7meEQNvK_Z2wYrGoyThuwk5dzu0ETZQsUGbr18WFhWvdk-8-EnUKm5eNq58BZOExOBWiwozXl-bBftE4DpLxJNylUaF0NNwBMVnW7dIKAzRB4KYuCZp7781D7K8neU8jwJurWtV-_NBxIeWTTw2f_wxFDKFCRmWO1kLKKiIT500S2NOzXo3yBmzwlNty9lbkeVlIoMPYptS5A9453vQIJpTm_seTrnjX0uNhwIYjaviF7vKr_dC30bPpDSwGvk0KSobS5lgLuIGOxw4wmoLRGeng1raq1uou_yVE_NMFw4hJE-jXXY5gY3kuZ1Dnkj1Uz8p__1lPK1Gt0gGaZ-EgDFOcGVvAbuTW779kcg4_H6Mwuky1FJ6QbUNun-YntTKPQVO_3kS9-WVyntLZ0-I3tgxYdo5qYQf2Lct1bEVmUhpf7vyAoIPqpnSw1au5td9w5mvg3xpgJ5yo0F4CnDy7BbTSHjAT1aShiHIcENwkTtkgUsHkNHqKAc9jFy7Cssf3lzrqfHFezYnuoVkda8KCddTUi3JDNvOAL5qIbHC2Cd_PEn2V6CzjodPn0osJVjFK8NZrUBWgza7bnxcGVwFK6K4CuiLb9K2fiWQgVm-nOdg0vCIagOtMGx-uacgaHEttcugzao0lrV-pAGn2EkDzb_1oPI-qEA2oyZ3wVf8ikK3K_EuBCVtvC5ONiKpLyMQvNzMoVvzxSc4L7L4B3S0lT43YwGdGKGe0fykGNZySIC_NJFdJc36g9E_kdHDfhXpAU=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1007.21,
            "totalPrice": 766
          },
          {
            "allPassengerTotalPrice": 766,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUig0ebk8tZkNbPsovtYjWWrbP5SNw8g5tOWJ7iSiZVGnhBUg344lTbgpRp2SEOSftg5jZGvo6gal8JxSG-X2_ox3eg-7Z3tIUMP7PLVIapnolxEbDXrZ_JADQ0GkxgaCMFTGFZ_34DyNpCu-z4WfNhO6sdserG4ffloZolNUmOyTfk97KeXu-jIlgOINuud8_NZRNtlEKp8W8hUI_HW9Sf-7rqfwJW15HR2FOWyTfO9HuIHxb_CIuIOrZOfeFlAgAEwlPezNmYGPzjkNxuNVC9TC1zj3ndK-l8bHeAAU9iBYwWC9Qx7aEA6hgE0d5dCAbzswYSmfyNtCkL3MdGuI4ynrD0_o-ybKQyGD58lM5vRxCVPI-N8E0aO2Y-cecMtH-H2RuocxDJ2dcC8XR0cMFPoq1MFXANTF-MJYYouSB-B-OXB2WSBXdulQ3vnqRFUJKtVqwUrsQvGzs0Xw1Za57t9x9lM4NMqQ6uUqc1mFCc-oMVGJ4mJpcFu2pQB34RVLjcFnUIGCYabFj7cZ3VHfpuCR9DK1d7vZ-DnIU9hq_umJ5EKxqEh5vWCwCosXTJhh8waNDoOGfizKMCIED67fX6DK-3d3THF5RJFs3e_5R7-Vkud0XlLop3KjJO294DcpsKbqpRG3YavISLde61I-a6EhQ4mSOliGkFYmaXkEqJl_Z6oz98v9j6fbh3-n5Iis-EDc1zHO_gpVKLoxZfi_o3_0VF9RkUiANkEiA8Zp79uWw17kAyns8_69I2pH9EJBLBa9CabAngy8-HnmwJ6u9cl0qgwJ5XjDeCw4yyjuEDJns5sJ7lRW8EI_-A_syoZg-9x7OYoafqBsLWaLLcV7noZaHuCh3i24Ym5hXGoic9r3gubX6bJr5w_nJUCnDRqcOSmN9WQm09cYwn05Od2tbDjv6XY73gn4lfymzunyTEa6u9fgbS74GIQFQmwwLKn1wso9Gv-DDv396IHfw2sWb98s-dYuw54ViYSfM_qWeDp_7ygItg7cauJYX9VVxnyRwNdbXHLpMFLL6ysnGg8Hxq0UVvaolG8HqEsc74NWRbM-ACWXxeAYZDYSynp3velsSBLQxa7MQhWShBOy90ZWVYnDBHVgC47KJ-tcHlXu5R0VPI8VOkeUWPpPh0-dgy1Pbc9BgjCwI5BigEqSrMSOyN0Of9xPQcy9nUEEbeSSH6M4JEFP9IK-6M2XgFxlLjpI",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUig0ebk8tZkNbPsovtYjWWrbP5SNw8g5tOWJ7iSiZVGnhBUg344lTbgpRp2SEOSftg5jZGvo6gal8JxSG-X2_ox3eg-7Z3tIUMP7PLVIapnolxEbDXrZ_JADQ0GkxgaCMFTGFZ_34DyNpCu-z4WfNhO6sdserG4ffloZolNUmOyTfk97KeXu-jIlgOINuud8_NZRNtlEKp8W8hUI_HW9Sf-7rqfwJW15HR2FOWyTfO9HuIHxb_CIuIOrZOfeFlAgAEwlPezNmYGPzjkNxuNVC9TC1zj3ndK-l8bHeAAU9iBYwWC9Qx7aEA6hgE0d5dCAbzswYSmfyNtCkL3MdGuI4ynrD0_o-ybKQyGD58lM5vRxCVPI-N8E0aO2Y-cecMtH-H2RuocxDJ2dcC8XR0cMFPoq1MFXANTF-MJYYouSB-B-OXB2WSBXdulQ3vnqRFUJKtVqwUrsQvGzs0Xw1Za57t9x9lM4NMqQ6uUqc1mFCc-oMVGJ4mJpcFu2pQB34RVLjcFnUIGCYabFj7cZ3VHfpuCR9DK1d7vZ-DnIU9hq_umJ5EKxqEh5vWCwCosXTJhh8waNDoOGfizKMCIED67fX6DK-3d3THF5RJFs3e_5R7-Vkud0XlLop3KjJO294DcpsKbqpRG3YavISLde61I-a6EhQ4mSOliGkFYmaXkEqJl_Z6oz98v9j6fbh3-n5Iis-EDc1zHO_gpVKLoxZfi_o3_0VF9RkUiANkEiA8Zp79uWw17kAyns8_69I2pH9EJBLBa9CabAngy8-HnmwJ6u9cl0qgwJ5XjDeCw4yyjuEDJns5sJ7lRW8EI_-A_syoZg-9x7OYoafqBsLWaLLcV7noZaHuCh3i24Ym5hXGoic9r3gubX6bJr5w_nJUCnDRqcOSmN9WQm09cYwn05Od2tbDjv6XY73gn4lfymzunyTEa6u9fgbS74GIQFQmwwLKn1wso9Gv-DDv396IHfw2sWb98s-dYuw54ViYSfM_qWeDp_7ygItg7cauJYX9VVxnyRwNdbXHLpMFLL6ysnGg8Hxq0UVvaolG8HqEsc74NWRbM-ACWXxeAYZDYSynp3velsSBLQxa7MQhWShBOy90ZWVYnDBHVgC47KJ-tcHlXu5R0VPI8VOkeUWPpPh0-dgy1Pbc9BgjCwI5BigEqSrMSOyN0Of9xPQcy9nUEEbeSSH6M4JEFP9IK-6M2XgFxlLjpI",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUig0ebk8tZkNbPsovtYjWWrbP5SNw8g5tOWJ7iSiZVGnhBUg344lTbgpRp2SEOSftg5jZGvo6gal8JxSG-X2_ox3eg-7Z3tIUMP7PLVIapnolxEbDXrZ_JADQ0GkxgaCMFTGFZ_34DyNpCu-z4WfNhO6sdserG4ffloZolNUmOyTfk97KeXu-jIlgOINuud8_NZRNtlEKp8W8hUI_HW9Sf-7rqfwJW15HR2FOWyTfO9HuIHxb_CIuIOrZOfeFlAgAEwlPezNmYGPzjkNxuNVC9TC1zj3ndK-l8bHeAAU9iBYwWC9Qx7aEA6hgE0d5dCAbzswYSmfyNtCkL3MdGuI4ynrD0_o-ybKQyGD58lM5vRxCVPI-N8E0aO2Y-cecMtH-H2RuocxDJ2dcC8XR0cMFPoq1MFXANTF-MJYYouSB-B-OXB2WSBXdulQ3vnqRFUJKtVqwUrsQvGzs0Xw1Za57t9x9lM4NMqQ6uUqc1mFCc-oMVGJ4mJpcFu2pQB34RVLjcFnUIGCYabFj7cZ3VHfpuCR9DK1d7vZ-DnIU9hq_umJ5EKxqEh5vWCwCosXTJhh8waNDoOGfizKMCIED67fX6DK-3d3THF5RJFs3e_5R7-Vkud0XlLop3KjJO294DcpsKbqpRG3YavISLde61I-a6EhQ4mSOliGkFYmaXkEqJl_Z6oz98v9j6fbh3-n5Iis-EDc1zHO_gpVKLoxZfi_o3_0VF9RkUiANkEiA8Zp79uWw17kAyns8_69I2pH9EJBLBa9CabAngy8-HnmwJ6u9cl0qgwJ5XjDeCw4yyjuEDJns5sJ7lRW8EI_-A_syoZg-9x7OYoafqBsLWaLLcV7noZaHuCh3i24Ym5hXGoic9r3gubX6bJr5w_nJUCnDRqcOSmN9WQm09cYwn05Od2tbDjv6XY73gn4lfymzunyTEa6u9fgbS74GIQFQmwwLKn1wso9Gv-DDv396IHfw2sWb98s-dYuw54ViYSfM_qWeDp_7ygItg7cauJYX9VVxnyRwNdbXHLpMFLL6ysnGg8Hxq0UVvaolG8HqEsc74NWRbM-ACWXxeAYZDYSynp3velsSBLQxa7MQhWShBOy90ZWVYnDBHVgC47KJ-tcHlXu5R0VPI8VOkeUWPpPh0-dgy1Pbc9BgjCwI5BigEqSrMSOyN0Of9xPQcy9nUEEbeSSH6M4JEFP9IK-6M2XgFxlLjpI",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1008.05,
            "totalPrice": 766
          },
          {
            "allPassengerTotalPrice": 773,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGLEzy5_XZ8PZYje7KkaR_ppcwIHdm2D7309WxebR7LcDR1d4FfSW6fC5IHO2pkRTyEYEvdUsyZ9SgyNdy01Wo42Xnb16bX9vQPLdBp2WjM2_SooU9EJXacw-H56xZ4CTqi7h5IlO7Bh0L-b4JIoZ8lkPxrqf0nkl09mmEeLpareC12LUEKl3_JHbLrmaRVpEJ5cmfS5j8G6yD2X3X_qY8W62DPkBxne6gRB8ErouEnOY1kX1SpL_CYAxM9xmTWDK8ackUwLfl47fGIIZ_00JfNB23v4VaPnqBw4I5DBhv3yRJ7f_lLBc6q8mGgQPoMMAgB4pbWOGwr-O50ID1KdUDk0yY_0GsJ8AQN1fVsLV2f-vFNg9vqeVyz7FGRn9mi8XvOow3J8OxwELqiq8rrTvaCj98oCfFx-ocYfPVpRIrPgINZHOHxQrWgHrr6rOX7N5DhTDUyXybqt8kpE1W9yk6ooj1CXgqBrMQXpmR29hV2lubtcYJ4dDUMQPz6Ouljr0H6GnbMOwE7TLlR3HfzysUD_FkH7iZxvifRaGJryVnqQM1shxloq72qqJdwje07B3cKREaVu0jOnTHbhxYeDEmJCw-DxrT3UFdlo_bYguHO9YHKfRPlFZSK976Xjkbp9Wvr3Wj-SYPBJSrSVwl1CwTo9T1U-oC3MWrYJQEYnuhIpAc_WL8jTfXZefeZfgfHkVWBkVnzHa4GaolGsZIF-dP574t8rqIVJxeEBu7MqEPS8lpEoxVQgFSzyk8K6cMp1HLCon2DzWSNtaf-aXE8nxUKalohyANTAD20nVjNLWetGBX0E0l2o_TkJCwy2zJSzhqIBPGSmGYFe8uy7zF7R7J8sBxlwx6dmLP8QAezfsZOSkOeADo9p38Mb1XEYd0IlRyoOc84GfI-SMs1mNzrYWWmo93NYVg86-Hk04iZAe9V5iP390peaYtb7Ki_bm2LvorpcWYpWZp-J-L6Dk81Tvaej6MUe-NigpKc_ORV39ed1Gd05MCWLA6DkMXFR-CC3q_ST3frYpQBTAFgEW1KApgsRtry8YlhbqCr-eJbouxokCG-R5RJe3bZKtGPk1KgCMailUBpgeVk7QRLIFjDrjcBLK_PM3Z-tDkK9JKcaiR9ZUVeJyfBideF3NF7FuPhJCrZIeZogAg9lVnRoUPSkYqLyol7VsbPnEGL8fBHnxl2xNj-6QfzyW__aucrvVd4XRMnhFErYpHzDaQB2gYGW-dOiGqQyFoeWK6lfi2X7vUj0=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGLEzy5_XZ8PZYje7KkaR_ppcwIHdm2D7309WxebR7LcDR1d4FfSW6fC5IHO2pkRTyEYEvdUsyZ9SgyNdy01Wo42Xnb16bX9vQPLdBp2WjM2_SooU9EJXacw-H56xZ4CTqi7h5IlO7Bh0L-b4JIoZ8lkPxrqf0nkl09mmEeLpareC12LUEKl3_JHbLrmaRVpEJ5cmfS5j8G6yD2X3X_qY8W62DPkBxne6gRB8ErouEnOY1kX1SpL_CYAxM9xmTWDK8ackUwLfl47fGIIZ_00JfNB23v4VaPnqBw4I5DBhv3yRJ7f_lLBc6q8mGgQPoMMAgB4pbWOGwr-O50ID1KdUDk0yY_0GsJ8AQN1fVsLV2f-vFNg9vqeVyz7FGRn9mi8XvOow3J8OxwELqiq8rrTvaCj98oCfFx-ocYfPVpRIrPgINZHOHxQrWgHrr6rOX7N5DhTDUyXybqt8kpE1W9yk6ooj1CXgqBrMQXpmR29hV2lubtcYJ4dDUMQPz6Ouljr0H6GnbMOwE7TLlR3HfzysUD_FkH7iZxvifRaGJryVnqQM1shxloq72qqJdwje07B3cKREaVu0jOnTHbhxYeDEmJCw-DxrT3UFdlo_bYguHO9YHKfRPlFZSK976Xjkbp9Wvr3Wj-SYPBJSrSVwl1CwTo9T1U-oC3MWrYJQEYnuhIpAc_WL8jTfXZefeZfgfHkVWBkVnzHa4GaolGsZIF-dP574t8rqIVJxeEBu7MqEPS8lpEoxVQgFSzyk8K6cMp1HLCon2DzWSNtaf-aXE8nxUKalohyANTAD20nVjNLWetGBX0E0l2o_TkJCwy2zJSzhqIBPGSmGYFe8uy7zF7R7J8sBxlwx6dmLP8QAezfsZOSkOeADo9p38Mb1XEYd0IlRyoOc84GfI-SMs1mNzrYWWmo93NYVg86-Hk04iZAe9V5iP390peaYtb7Ki_bm2LvorpcWYpWZp-J-L6Dk81Tvaej6MUe-NigpKc_ORV39ed1Gd05MCWLA6DkMXFR-CC3q_ST3frYpQBTAFgEW1KApgsRtry8YlhbqCr-eJbouxokCG-R5RJe3bZKtGPk1KgCMailUBpgeVk7QRLIFjDrjcBLK_PM3Z-tDkK9JKcaiR9ZUVeJyfBideF3NF7FuPhJCrZIeZogAg9lVnRoUPSkYqLyol7VsbPnEGL8fBHnxl2xNj-6QfzyW__aucrvVd4XRMnhFErYpHzDaQB2gYGW-dOiGqQyFoeWK6lfi2X7vUj0=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGLEzy5_XZ8PZYje7KkaR_ppcwIHdm2D7309WxebR7LcDR1d4FfSW6fC5IHO2pkRTyEYEvdUsyZ9SgyNdy01Wo42Xnb16bX9vQPLdBp2WjM2_SooU9EJXacw-H56xZ4CTqi7h5IlO7Bh0L-b4JIoZ8lkPxrqf0nkl09mmEeLpareC12LUEKl3_JHbLrmaRVpEJ5cmfS5j8G6yD2X3X_qY8W62DPkBxne6gRB8ErouEnOY1kX1SpL_CYAxM9xmTWDK8ackUwLfl47fGIIZ_00JfNB23v4VaPnqBw4I5DBhv3yRJ7f_lLBc6q8mGgQPoMMAgB4pbWOGwr-O50ID1KdUDk0yY_0GsJ8AQN1fVsLV2f-vFNg9vqeVyz7FGRn9mi8XvOow3J8OxwELqiq8rrTvaCj98oCfFx-ocYfPVpRIrPgINZHOHxQrWgHrr6rOX7N5DhTDUyXybqt8kpE1W9yk6ooj1CXgqBrMQXpmR29hV2lubtcYJ4dDUMQPz6Ouljr0H6GnbMOwE7TLlR3HfzysUD_FkH7iZxvifRaGJryVnqQM1shxloq72qqJdwje07B3cKREaVu0jOnTHbhxYeDEmJCw-DxrT3UFdlo_bYguHO9YHKfRPlFZSK976Xjkbp9Wvr3Wj-SYPBJSrSVwl1CwTo9T1U-oC3MWrYJQEYnuhIpAc_WL8jTfXZefeZfgfHkVWBkVnzHa4GaolGsZIF-dP574t8rqIVJxeEBu7MqEPS8lpEoxVQgFSzyk8K6cMp1HLCon2DzWSNtaf-aXE8nxUKalohyANTAD20nVjNLWetGBX0E0l2o_TkJCwy2zJSzhqIBPGSmGYFe8uy7zF7R7J8sBxlwx6dmLP8QAezfsZOSkOeADo9p38Mb1XEYd0IlRyoOc84GfI-SMs1mNzrYWWmo93NYVg86-Hk04iZAe9V5iP390peaYtb7Ki_bm2LvorpcWYpWZp-J-L6Dk81Tvaej6MUe-NigpKc_ORV39ed1Gd05MCWLA6DkMXFR-CC3q_ST3frYpQBTAFgEW1KApgsRtry8YlhbqCr-eJbouxokCG-R5RJe3bZKtGPk1KgCMailUBpgeVk7QRLIFjDrjcBLK_PM3Z-tDkK9JKcaiR9ZUVeJyfBideF3NF7FuPhJCrZIeZogAg9lVnRoUPSkYqLyol7VsbPnEGL8fBHnxl2xNj-6QfzyW__aucrvVd4XRMnhFErYpHzDaQB2gYGW-dOiGqQyFoeWK6lfi2X7vUj0=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1012.99,
            "totalPrice": 773
          },
          {
            "allPassengerTotalPrice": 774,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GR-p81dYfDljQdhDhs7DZpc_gIz3keoxEJmWqLGCoMLiYosMEyPiP-CJg4BFld2D3VsCPKOw1u0dvr24C6c68Mj33mhRP4V8Zpa9MsRR1g__3XeYQp_QnSPdFVPOo0kdPFKKSzzAsiuGZ30pl9TBl8tDMfY1gg70aP82B6jDeUG8Dch2zAl6Jb1Y_4wjlwaNqa6d07inZ9ttDyRpPVJoZzpgVMzHXAIX-BmF76g3rsIwjvDdiJRGtLppxy9byKqlBrgYeD8TJu7EQXx4nRBz3sCd_S--lnoP-E1s-vjWXNqDXIchDFarLpqqoAPMhQDjo-5d1_gGRU73R0ypjCGP9QjW1m9O00DLWbLO7Hs7pc__9_wISc8srCOFJM1V64VHlga1rERzKie30xmIMl6F3sddklAeRXi1HWnEdMdRCBFLaekJFIWJzECi0Df5rbU1jaRGpewnKwmGFz7XmRP-S_0o_jnFyFhWlq-T9QNmZDreNlU2mTvJVxRUlTdgNDOkeXxBxW44CDqBDmWn_d6VXcxEZNR0smwJ7WAjyeFAWx_pxvuCPNkweLu_9lziC8JGhVk5dO9vHTv5IxTXRvIxtz1w9ZUdII3dxK8xGQ2NCa5QYQkISMWnbSMQH_uqIL2h7mmcx0XT4rO1Ft0sVPthEZeZibNJSp2LxbEL9GrjGh64cyUdvmVVNil8W-vcTZxqMRvJSguES7eNsOJRhfG6uc_ztrmFHEzyCZQ0V97Kut5-WRbWf1BUb4fxiVzgjmqVaOhVOPj5FYOculuTzUDAOXyjskmwpdoRmvK3ucXutwE1IRSzI6QQbdEJXOyxn6ZY5E0KbIiD4pesLNHTEIq0tzjiJ_3NyYbV0Dub9mBYCQeWpgJHi-soX_zwp-E7ZSlE5VbzHFwZdl954fP0kEThjvvE36uUwFtTAL1a2IYfhmAkMf_rsLcG5ts88F-9BEnU2kDTEyKcMD4BBlcfiliqwyCVwQPG1s9dh5j1OMucofdPBr2w7JyxD99RntZ7PBFrTQOPTHL9qAMKHGloZ15SHsb6arFuLM7UQp6LofgQMBeO5YQTeip01CWYAxfFjLFOtipInCILG2yC9XjWiHx2E7ZDWkWCJEWZP-khm-i_9G0Lbw5kDpzsHy7pfVR7pCkQZafiv0TJfkZV3iW-dzZMTgNXy_5bENGejbnvAvl3ShudV-dRgsl92FQfR-2_sx3UMA6dkV25t9kDSWRNQolXunKNsjFF9pTDBgvg6ab98qcJn0rp4YxFNfxNTtgqtMV3lJdU7y-LO10STPaTqeeLH5AkMCVTwBQ21S88h8V541Dg=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GR-p81dYfDljQdhDhs7DZpc_gIz3keoxEJmWqLGCoMLiYosMEyPiP-CJg4BFld2D3VsCPKOw1u0dvr24C6c68Mj33mhRP4V8Zpa9MsRR1g__3XeYQp_QnSPdFVPOo0kdPFKKSzzAsiuGZ30pl9TBl8tDMfY1gg70aP82B6jDeUG8Dch2zAl6Jb1Y_4wjlwaNqa6d07inZ9ttDyRpPVJoZzpgVMzHXAIX-BmF76g3rsIwjvDdiJRGtLppxy9byKqlBrgYeD8TJu7EQXx4nRBz3sCd_S--lnoP-E1s-vjWXNqDXIchDFarLpqqoAPMhQDjo-5d1_gGRU73R0ypjCGP9QjW1m9O00DLWbLO7Hs7pc__9_wISc8srCOFJM1V64VHlga1rERzKie30xmIMl6F3sddklAeRXi1HWnEdMdRCBFLaekJFIWJzECi0Df5rbU1jaRGpewnKwmGFz7XmRP-S_0o_jnFyFhWlq-T9QNmZDreNlU2mTvJVxRUlTdgNDOkeXxBxW44CDqBDmWn_d6VXcxEZNR0smwJ7WAjyeFAWx_pxvuCPNkweLu_9lziC8JGhVk5dO9vHTv5IxTXRvIxtz1w9ZUdII3dxK8xGQ2NCa5QYQkISMWnbSMQH_uqIL2h7mmcx0XT4rO1Ft0sVPthEZeZibNJSp2LxbEL9GrjGh64cyUdvmVVNil8W-vcTZxqMRvJSguES7eNsOJRhfG6uc_ztrmFHEzyCZQ0V97Kut5-WRbWf1BUb4fxiVzgjmqVaOhVOPj5FYOculuTzUDAOXyjskmwpdoRmvK3ucXutwE1IRSzI6QQbdEJXOyxn6ZY5E0KbIiD4pesLNHTEIq0tzjiJ_3NyYbV0Dub9mBYCQeWpgJHi-soX_zwp-E7ZSlE5VbzHFwZdl954fP0kEThjvvE36uUwFtTAL1a2IYfhmAkMf_rsLcG5ts88F-9BEnU2kDTEyKcMD4BBlcfiliqwyCVwQPG1s9dh5j1OMucofdPBr2w7JyxD99RntZ7PBFrTQOPTHL9qAMKHGloZ15SHsb6arFuLM7UQp6LofgQMBeO5YQTeip01CWYAxfFjLFOtipInCILG2yC9XjWiHx2E7ZDWkWCJEWZP-khm-i_9G0Lbw5kDpzsHy7pfVR7pCkQZafiv0TJfkZV3iW-dzZMTgNXy_5bENGejbnvAvl3ShudV-dRgsl92FQfR-2_sx3UMA6dkV25t9kDSWRNQolXunKNsjFF9pTDBgvg6ab98qcJn0rp4YxFNfxNTtgqtMV3lJdU7y-LO10STPaTqeeLH5AkMCVTwBQ21S88h8V541Dg=",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GR-p81dYfDljQdhDhs7DZpc_gIz3keoxEJmWqLGCoMLiYosMEyPiP-CJg4BFld2D3VsCPKOw1u0dvr24C6c68Mj33mhRP4V8Zpa9MsRR1g__3XeYQp_QnSPdFVPOo0kdPFKKSzzAsiuGZ30pl9TBl8tDMfY1gg70aP82B6jDeUG8Dch2zAl6Jb1Y_4wjlwaNqa6d07inZ9ttDyRpPVJoZzpgVMzHXAIX-BmF76g3rsIwjvDdiJRGtLppxy9byKqlBrgYeD8TJu7EQXx4nRBz3sCd_S--lnoP-E1s-vjWXNqDXIchDFarLpqqoAPMhQDjo-5d1_gGRU73R0ypjCGP9QjW1m9O00DLWbLO7Hs7pc__9_wISc8srCOFJM1V64VHlga1rERzKie30xmIMl6F3sddklAeRXi1HWnEdMdRCBFLaekJFIWJzECi0Df5rbU1jaRGpewnKwmGFz7XmRP-S_0o_jnFyFhWlq-T9QNmZDreNlU2mTvJVxRUlTdgNDOkeXxBxW44CDqBDmWn_d6VXcxEZNR0smwJ7WAjyeFAWx_pxvuCPNkweLu_9lziC8JGhVk5dO9vHTv5IxTXRvIxtz1w9ZUdII3dxK8xGQ2NCa5QYQkISMWnbSMQH_uqIL2h7mmcx0XT4rO1Ft0sVPthEZeZibNJSp2LxbEL9GrjGh64cyUdvmVVNil8W-vcTZxqMRvJSguES7eNsOJRhfG6uc_ztrmFHEzyCZQ0V97Kut5-WRbWf1BUb4fxiVzgjmqVaOhVOPj5FYOculuTzUDAOXyjskmwpdoRmvK3ucXutwE1IRSzI6QQbdEJXOyxn6ZY5E0KbIiD4pesLNHTEIq0tzjiJ_3NyYbV0Dub9mBYCQeWpgJHi-soX_zwp-E7ZSlE5VbzHFwZdl954fP0kEThjvvE36uUwFtTAL1a2IYfhmAkMf_rsLcG5ts88F-9BEnU2kDTEyKcMD4BBlcfiliqwyCVwQPG1s9dh5j1OMucofdPBr2w7JyxD99RntZ7PBFrTQOPTHL9qAMKHGloZ15SHsb6arFuLM7UQp6LofgQMBeO5YQTeip01CWYAxfFjLFOtipInCILG2yC9XjWiHx2E7ZDWkWCJEWZP-khm-i_9G0Lbw5kDpzsHy7pfVR7pCkQZafiv0TJfkZV3iW-dzZMTgNXy_5bENGejbnvAvl3ShudV-dRgsl92FQfR-2_sx3UMA6dkV25t9kDSWRNQolXunKNsjFF9pTDBgvg6ab98qcJn0rp4YxFNfxNTtgqtMV3lJdU7y-LO10STPaTqeeLH5AkMCVTwBQ21S88h8V541Dg=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1080.2,
            "totalPrice": 774
          },
          {
            "allPassengerTotalPrice": 775,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIPH0WOl0DkdKbjFJu70Rq1cTRxX1Mk_a0MP0gk1pTX-_oEPjK9kj6nu4jKYmbXxY8PhpHXEAo7VJol5FERv63JLgC_KiMXrXziB3x84ZJvMCV3Jats_RgdCBgA9UiHN5G0K_f1-9FZ97Nczz4MQZtmhoBaDx7DS68ddv1Mcd8OGFWvlZxRwAghPjxDViFqqbrUavhmE-ylRzgCdDagbQeI06HBTeGbYvfDSTLNOllzOE9L-QkgEFq9f0-YUx80_4UTjYXcAckMmhrcqzgheIbCPYgb8MjXyfn7q9r8SSG2t3xdWCt0t4vHznwhCJ-Q0nncCyjvshieeJwtKMDXLBxTTabiHDryALqz5ZCw1xgS2Rk3lE5_81yLEtkvL11UY8NP8lB4V5J8hrR_gMnWoyGZOn9w9cN7w7YVwAfQktgTWLbnf-NpIZgUGjpdofBl1jxYQdThlVAz2hgqvNw4cGJrh2G9Ip8mU5hm3xZtdm44lyzYVtm0lhII0zOSZ1r13aZCS0g-azcoSWNHOAQCE7_8kPOfOJ3bwLE9UoSOJvsYxzib3Olx-bL7gH87lpjt-YJxuE6VLPW24VG-BwvVNnB4kKalsMlxfzdeIyJx7f9bgfXbwEMH7LpFXCuDWq2F131OB5hTpJk1-ZBmmCSThqHn18nevY11Jin-SNHHeYXId3030vP_CDKExZwH7PVFQmhX5oWCK-Ysc1vwapsZvEBO0uJwHFOb-KrVPZ55D--rjrTxKzm63tL3LJLYQDRuXzKSRZrOCWJXMwv0dPQ_XaDi4HX0Kx5QLxS2KkWK6i-QHvfEPlymeWQX8Kjn69mXYKlc68UuTdTP_DJxOv6-FcUJO9BnHRustxs0Laj5XKrh-B_esPIHl7bP69PXbTbZdnTWRMG_WkY1z6a4Nnw0F0fZkaT6nZE9T_05h_rPylSL0t7dy_U58kAeE4potXKx85M1-8IGY5qxtAIHJBhEJMF9eMGjEELDLLhZJ_0FqdVQdf2MN0ET3A2eZ95B8eumW90b2QPKufFuAMRgwn1-zAqxgC8omZwqsz6BMh1991ysZUmmARWDQsS_f8yBD5gkrSfAdB8x1_5lIsxs5O7OR32ZjJHf2mBlp3-81B-ClIoQAeWdqYh11v6x9_JdWvoqg5Qycn07psB7PdCmEOxwE2Qcz47uPSkG30Pc6mEwH2gOoj0uNegvHax_31rXZlys8Ti9E2Uy40UG9iTgkmTKBYhQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIPH0WOl0DkdKbjFJu70Rq1cTRxX1Mk_a0MP0gk1pTX-_oEPjK9kj6nu4jKYmbXxY8PhpHXEAo7VJol5FERv63JLgC_KiMXrXziB3x84ZJvMCV3Jats_RgdCBgA9UiHN5G0K_f1-9FZ97Nczz4MQZtmhoBaDx7DS68ddv1Mcd8OGFWvlZxRwAghPjxDViFqqbrUavhmE-ylRzgCdDagbQeI06HBTeGbYvfDSTLNOllzOE9L-QkgEFq9f0-YUx80_4UTjYXcAckMmhrcqzgheIbCPYgb8MjXyfn7q9r8SSG2t3xdWCt0t4vHznwhCJ-Q0nncCyjvshieeJwtKMDXLBxTTabiHDryALqz5ZCw1xgS2Rk3lE5_81yLEtkvL11UY8NP8lB4V5J8hrR_gMnWoyGZOn9w9cN7w7YVwAfQktgTWLbnf-NpIZgUGjpdofBl1jxYQdThlVAz2hgqvNw4cGJrh2G9Ip8mU5hm3xZtdm44lyzYVtm0lhII0zOSZ1r13aZCS0g-azcoSWNHOAQCE7_8kPOfOJ3bwLE9UoSOJvsYxzib3Olx-bL7gH87lpjt-YJxuE6VLPW24VG-BwvVNnB4kKalsMlxfzdeIyJx7f9bgfXbwEMH7LpFXCuDWq2F131OB5hTpJk1-ZBmmCSThqHn18nevY11Jin-SNHHeYXId3030vP_CDKExZwH7PVFQmhX5oWCK-Ysc1vwapsZvEBO0uJwHFOb-KrVPZ55D--rjrTxKzm63tL3LJLYQDRuXzKSRZrOCWJXMwv0dPQ_XaDi4HX0Kx5QLxS2KkWK6i-QHvfEPlymeWQX8Kjn69mXYKlc68UuTdTP_DJxOv6-FcUJO9BnHRustxs0Laj5XKrh-B_esPIHl7bP69PXbTbZdnTWRMG_WkY1z6a4Nnw0F0fZkaT6nZE9T_05h_rPylSL0t7dy_U58kAeE4potXKx85M1-8IGY5qxtAIHJBhEJMF9eMGjEELDLLhZJ_0FqdVQdf2MN0ET3A2eZ95B8eumW90b2QPKufFuAMRgwn1-zAqxgC8omZwqsz6BMh1991ysZUmmARWDQsS_f8yBD5gkrSfAdB8x1_5lIsxs5O7OR32ZjJHf2mBlp3-81B-ClIoQAeWdqYh11v6x9_JdWvoqg5Qycn07psB7PdCmEOxwE2Qcz47uPSkG30Pc6mEwH2gOoj0uNegvHax_31rXZlys8Ti9E2Uy40UG9iTgkmTKBYhQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIPH0WOl0DkdKbjFJu70Rq1cTRxX1Mk_a0MP0gk1pTX-_oEPjK9kj6nu4jKYmbXxY8PhpHXEAo7VJol5FERv63JLgC_KiMXrXziB3x84ZJvMCV3Jats_RgdCBgA9UiHN5G0K_f1-9FZ97Nczz4MQZtmhoBaDx7DS68ddv1Mcd8OGFWvlZxRwAghPjxDViFqqbrUavhmE-ylRzgCdDagbQeI06HBTeGbYvfDSTLNOllzOE9L-QkgEFq9f0-YUx80_4UTjYXcAckMmhrcqzgheIbCPYgb8MjXyfn7q9r8SSG2t3xdWCt0t4vHznwhCJ-Q0nncCyjvshieeJwtKMDXLBxTTabiHDryALqz5ZCw1xgS2Rk3lE5_81yLEtkvL11UY8NP8lB4V5J8hrR_gMnWoyGZOn9w9cN7w7YVwAfQktgTWLbnf-NpIZgUGjpdofBl1jxYQdThlVAz2hgqvNw4cGJrh2G9Ip8mU5hm3xZtdm44lyzYVtm0lhII0zOSZ1r13aZCS0g-azcoSWNHOAQCE7_8kPOfOJ3bwLE9UoSOJvsYxzib3Olx-bL7gH87lpjt-YJxuE6VLPW24VG-BwvVNnB4kKalsMlxfzdeIyJx7f9bgfXbwEMH7LpFXCuDWq2F131OB5hTpJk1-ZBmmCSThqHn18nevY11Jin-SNHHeYXId3030vP_CDKExZwH7PVFQmhX5oWCK-Ysc1vwapsZvEBO0uJwHFOb-KrVPZ55D--rjrTxKzm63tL3LJLYQDRuXzKSRZrOCWJXMwv0dPQ_XaDi4HX0Kx5QLxS2KkWK6i-QHvfEPlymeWQX8Kjn69mXYKlc68UuTdTP_DJxOv6-FcUJO9BnHRustxs0Laj5XKrh-B_esPIHl7bP69PXbTbZdnTWRMG_WkY1z6a4Nnw0F0fZkaT6nZE9T_05h_rPylSL0t7dy_U58kAeE4potXKx85M1-8IGY5qxtAIHJBhEJMF9eMGjEELDLLhZJ_0FqdVQdf2MN0ET3A2eZ95B8eumW90b2QPKufFuAMRgwn1-zAqxgC8omZwqsz6BMh1991ysZUmmARWDQsS_f8yBD5gkrSfAdB8x1_5lIsxs5O7OR32ZjJHf2mBlp3-81B-ClIoQAeWdqYh11v6x9_JdWvoqg5Qycn07psB7PdCmEOxwE2Qcz47uPSkG30Pc6mEwH2gOoj0uNegvHax_31rXZlys8Ti9E2Uy40UG9iTgkmTKBYhQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1014.4,
            "totalPrice": 775
          },
          {
            "allPassengerTotalPrice": 776,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfnPjh39EeUN4TYNMqqouHDDR8bP27aUB4MBTggfxiW5wI31MFT5t1jjHWiXyULjjCddjNR2QR4GndrpTIYMBD94ETjsUxaxtuEDWJybfV6o3MVoGzujFQbtZGQMk82_DchY8RGv3Smco5Doq0rAjPHYkfZtSjFbPvtYzsg88yyEe4kRliF5W8-MT7pcPV6q95rY_jL96yC3MyEWcBKWgOdLBUpFHLcgHyvSuf75_mMzQQpIf2WV1fDqHL_VbXPsqPH7ifafiic1vArcO4Dv8Sm9sN8t7RD_R_s-vEEdxx29PRndEhCmY88QXYnfOJqKRN2L2KO-KfIW7PFVpbLiC2lJzJ9VXAzawIW_32mowqX1aPxERicfx-cWxZDAd0t-VCFZz1qUgwAjcu_7mS_4T_nglsho9cc3NYcdIwTvoFF-3pV5tEVKk40AA_oZ-uBjiGLGZexutRm7Diy4xcng0dBHeHWOGmTWWVc02iVXwhmQVQljtUYymLtmw4hQsphsfd76wuhDDg-EpDy7aAqjz-d4_sz3X_W-Bfd2pyCpd9URKkIVgKS_f29sxAuiPaYD9OEDZdsYxf4vZ9dXo_uyfwLpOkirtEDq1cq6qqmevdi4piA-SWV-ICTbcs_G3zhYRYCMRqUlAKk5htGfHFvAlDKdHimczRchtddWGsrsn5qkMjuJKckwEi0xBmvmTfRaDTK3urR9-dy7QRyu9wHUNF6ryGQnux7NTojNhEJDxNeECJAflPxgWhk8pmFJij94BVpvYqWNjE1FwCA3MddcOj2E5tD6cYxlERoEElh0Wt3hGEgyRsOufFZ04J42bOMHog6xydnihv2P6r_BK0tkoy2ZgHL76RNgu8ExOg7dmoRhuPDdalvZhfqZnbu4QoLMp91vmDDx28lKS6NuPu4Dv5jfk00Mq_ChXe1mVa-_vVt6nvBdI02BgzYwFwCNkFWnd06igbQhvtNopjpLlTffHfcVZhTYLyzkjbGyLz9_CuxhqzHazr6y8WBZqo9JxkIhmfXjmrcOd2THkDMcYTM9EVAVtC5hh0iOpj_NLD8vEZk2BDD75ybtceS1vcSzSXhiZhlNPbcmsWH9f2T6E-WcKk83GpL8tI5oYAdkZwHA8a-8qFMo9eTjJMBPyFNhJno_AVP2Ww7pRggBDkgT-8BS-PMFoMtZx3frKWi8mz0uttaStZqBt84Ab799dwYR7OnBa",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfnPjh39EeUN4TYNMqqouHDDR8bP27aUB4MBTggfxiW5wI31MFT5t1jjHWiXyULjjCddjNR2QR4GndrpTIYMBD94ETjsUxaxtuEDWJybfV6o3MVoGzujFQbtZGQMk82_DchY8RGv3Smco5Doq0rAjPHYkfZtSjFbPvtYzsg88yyEe4kRliF5W8-MT7pcPV6q95rY_jL96yC3MyEWcBKWgOdLBUpFHLcgHyvSuf75_mMzQQpIf2WV1fDqHL_VbXPsqPH7ifafiic1vArcO4Dv8Sm9sN8t7RD_R_s-vEEdxx29PRndEhCmY88QXYnfOJqKRN2L2KO-KfIW7PFVpbLiC2lJzJ9VXAzawIW_32mowqX1aPxERicfx-cWxZDAd0t-VCFZz1qUgwAjcu_7mS_4T_nglsho9cc3NYcdIwTvoFF-3pV5tEVKk40AA_oZ-uBjiGLGZexutRm7Diy4xcng0dBHeHWOGmTWWVc02iVXwhmQVQljtUYymLtmw4hQsphsfd76wuhDDg-EpDy7aAqjz-d4_sz3X_W-Bfd2pyCpd9URKkIVgKS_f29sxAuiPaYD9OEDZdsYxf4vZ9dXo_uyfwLpOkirtEDq1cq6qqmevdi4piA-SWV-ICTbcs_G3zhYRYCMRqUlAKk5htGfHFvAlDKdHimczRchtddWGsrsn5qkMjuJKckwEi0xBmvmTfRaDTK3urR9-dy7QRyu9wHUNF6ryGQnux7NTojNhEJDxNeECJAflPxgWhk8pmFJij94BVpvYqWNjE1FwCA3MddcOj2E5tD6cYxlERoEElh0Wt3hGEgyRsOufFZ04J42bOMHog6xydnihv2P6r_BK0tkoy2ZgHL76RNgu8ExOg7dmoRhuPDdalvZhfqZnbu4QoLMp91vmDDx28lKS6NuPu4Dv5jfk00Mq_ChXe1mVa-_vVt6nvBdI02BgzYwFwCNkFWnd06igbQhvtNopjpLlTffHfcVZhTYLyzkjbGyLz9_CuxhqzHazr6y8WBZqo9JxkIhmfXjmrcOd2THkDMcYTM9EVAVtC5hh0iOpj_NLD8vEZk2BDD75ybtceS1vcSzSXhiZhlNPbcmsWH9f2T6E-WcKk83GpL8tI5oYAdkZwHA8a-8qFMo9eTjJMBPyFNhJno_AVP2Ww7pRggBDkgT-8BS-PMFoMtZx3frKWi8mz0uttaStZqBt84Ab799dwYR7OnBa",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfnPjh39EeUN4TYNMqqouHDDR8bP27aUB4MBTggfxiW5wI31MFT5t1jjHWiXyULjjCddjNR2QR4GndrpTIYMBD94ETjsUxaxtuEDWJybfV6o3MVoGzujFQbtZGQMk82_DchY8RGv3Smco5Doq0rAjPHYkfZtSjFbPvtYzsg88yyEe4kRliF5W8-MT7pcPV6q95rY_jL96yC3MyEWcBKWgOdLBUpFHLcgHyvSuf75_mMzQQpIf2WV1fDqHL_VbXPsqPH7ifafiic1vArcO4Dv8Sm9sN8t7RD_R_s-vEEdxx29PRndEhCmY88QXYnfOJqKRN2L2KO-KfIW7PFVpbLiC2lJzJ9VXAzawIW_32mowqX1aPxERicfx-cWxZDAd0t-VCFZz1qUgwAjcu_7mS_4T_nglsho9cc3NYcdIwTvoFF-3pV5tEVKk40AA_oZ-uBjiGLGZexutRm7Diy4xcng0dBHeHWOGmTWWVc02iVXwhmQVQljtUYymLtmw4hQsphsfd76wuhDDg-EpDy7aAqjz-d4_sz3X_W-Bfd2pyCpd9URKkIVgKS_f29sxAuiPaYD9OEDZdsYxf4vZ9dXo_uyfwLpOkirtEDq1cq6qqmevdi4piA-SWV-ICTbcs_G3zhYRYCMRqUlAKk5htGfHFvAlDKdHimczRchtddWGsrsn5qkMjuJKckwEi0xBmvmTfRaDTK3urR9-dy7QRyu9wHUNF6ryGQnux7NTojNhEJDxNeECJAflPxgWhk8pmFJij94BVpvYqWNjE1FwCA3MddcOj2E5tD6cYxlERoEElh0Wt3hGEgyRsOufFZ04J42bOMHog6xydnihv2P6r_BK0tkoy2ZgHL76RNgu8ExOg7dmoRhuPDdalvZhfqZnbu4QoLMp91vmDDx28lKS6NuPu4Dv5jfk00Mq_ChXe1mVa-_vVt6nvBdI02BgzYwFwCNkFWnd06igbQhvtNopjpLlTffHfcVZhTYLyzkjbGyLz9_CuxhqzHazr6y8WBZqo9JxkIhmfXjmrcOd2THkDMcYTM9EVAVtC5hh0iOpj_NLD8vEZk2BDD75ybtceS1vcSzSXhiZhlNPbcmsWH9f2T6E-WcKk83GpL8tI5oYAdkZwHA8a-8qFMo9eTjJMBPyFNhJno_AVP2Ww7pRggBDkgT-8BS-PMFoMtZx3frKWi8mz0uttaStZqBt84Ab799dwYR7OnBa",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 990.56,
            "totalPrice": 776
          },
          {
            "allPassengerTotalPrice": 776,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G6wJ5Mm54HbDcYMoRB3ijg8_EXNFh8sfd1j6lgUGjyPwwEe1IRW6SUNfuDfGuVOkOevYj6JMGJ4NjB3wEmOAv_C9QxX9Bg7n__XGkSkyR75NcDnO0RyzYLP18u4WhAAdk1Smyd9Cy53od-jvv9GfgERihTej2s78_mfc9w_AP2IDObWxa2le8koyGiKedRfHYxzLNkQ45m7IZ-07-qbam0wYhIRNS4Yhj5IEsPcgU_GvKJRuKK4QH5UnixHOKcOvuQcAwNtWuhoy3-sph_zNolirPVOYWHPNSfGBSOdoqGSfv3tD3aKLEc_edKlOyhRxPeXRSSKKyxitLIO7G04d2-tkCgbh19DnPuFwNwMNb_ZQNyFxcf70_IOtp_lbC1Wg1UGr8sTorTHloSDO779nGcxc_aB6f_0LNCggxgtiSTVNyrEUyAte8gzqUWVcyrpDKnhC3zUJvHm_JRJ62n7F0GTWPOaReIqhav28ROY82EqQd3PhBjFTguRV8SwN68VA-1zSo37KgECr-V2HGDf_Yf1VRztEXZkOZNkF-B2Oldw1dmLdjZICSiW14MeuLFyLdKFMn0ya0scAEyzsX-j8tbvW0d302RPMw5C7Cz3GyPnTUb41uUlSKefE8_rpQlOQEEBNl-bKDwbcQEbctjpJGCYMc8gmoEAu_oytNAtjA9r1_riGcwQT2Aa_x_RumvnWKsuj1M_f8sakSdB_5hC96vVstwgHf6MLHmFstS73FqzLC5xmVt3mbE0-tR8oMotrXhs9zFd1yC83p-dko5sVYVNrbhKSDACZ7qJbsL7gvp-bdolHWmkU-n3l_Xqz637xrKe0bIZmX8RruNN9ivWRr8fd8u57ST9YzsMiTMMmtHO5lrxqGfQL-uBMWJXu1H1zNSqY8jKfGMWb0ILSDGTLPjRRyRS3OwEhCuKKhLTURI0JVrDsmRWux1Gj7ue-_mjM0Do6mEMb_XvYexp_REiuj3CzlyEAO1iPPT1oi3EterjU7LtkYKQj3haAsOtEFuF5FK-7IIWVwTeEfJD4dvc3qmH2ghM96nYP1muaN198ONdzID-0uH5IlSjJJpcv9596pTNsvOreIqdUo2EeUle4q0J0aOykzTnUWB5HMk7dzqeidshxcDGcioyPFydPr_zd-4QRTPEYWAxjOr4HUTlutNiH-6Rxyq8kdq0kHhMz36Wf5vE3sI54FBf96JlqFat3i",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G6wJ5Mm54HbDcYMoRB3ijg8_EXNFh8sfd1j6lgUGjyPwwEe1IRW6SUNfuDfGuVOkOevYj6JMGJ4NjB3wEmOAv_C9QxX9Bg7n__XGkSkyR75NcDnO0RyzYLP18u4WhAAdk1Smyd9Cy53od-jvv9GfgERihTej2s78_mfc9w_AP2IDObWxa2le8koyGiKedRfHYxzLNkQ45m7IZ-07-qbam0wYhIRNS4Yhj5IEsPcgU_GvKJRuKK4QH5UnixHOKcOvuQcAwNtWuhoy3-sph_zNolirPVOYWHPNSfGBSOdoqGSfv3tD3aKLEc_edKlOyhRxPeXRSSKKyxitLIO7G04d2-tkCgbh19DnPuFwNwMNb_ZQNyFxcf70_IOtp_lbC1Wg1UGr8sTorTHloSDO779nGcxc_aB6f_0LNCggxgtiSTVNyrEUyAte8gzqUWVcyrpDKnhC3zUJvHm_JRJ62n7F0GTWPOaReIqhav28ROY82EqQd3PhBjFTguRV8SwN68VA-1zSo37KgECr-V2HGDf_Yf1VRztEXZkOZNkF-B2Oldw1dmLdjZICSiW14MeuLFyLdKFMn0ya0scAEyzsX-j8tbvW0d302RPMw5C7Cz3GyPnTUb41uUlSKefE8_rpQlOQEEBNl-bKDwbcQEbctjpJGCYMc8gmoEAu_oytNAtjA9r1_riGcwQT2Aa_x_RumvnWKsuj1M_f8sakSdB_5hC96vVstwgHf6MLHmFstS73FqzLC5xmVt3mbE0-tR8oMotrXhs9zFd1yC83p-dko5sVYVNrbhKSDACZ7qJbsL7gvp-bdolHWmkU-n3l_Xqz637xrKe0bIZmX8RruNN9ivWRr8fd8u57ST9YzsMiTMMmtHO5lrxqGfQL-uBMWJXu1H1zNSqY8jKfGMWb0ILSDGTLPjRRyRS3OwEhCuKKhLTURI0JVrDsmRWux1Gj7ue-_mjM0Do6mEMb_XvYexp_REiuj3CzlyEAO1iPPT1oi3EterjU7LtkYKQj3haAsOtEFuF5FK-7IIWVwTeEfJD4dvc3qmH2ghM96nYP1muaN198ONdzID-0uH5IlSjJJpcv9596pTNsvOreIqdUo2EeUle4q0J0aOykzTnUWB5HMk7dzqeidshxcDGcioyPFydPr_zd-4QRTPEYWAxjOr4HUTlutNiH-6Rxyq8kdq0kHhMz36Wf5vE3sI54FBf96JlqFat3i",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G6wJ5Mm54HbDcYMoRB3ijg8_EXNFh8sfd1j6lgUGjyPwwEe1IRW6SUNfuDfGuVOkOevYj6JMGJ4NjB3wEmOAv_C9QxX9Bg7n__XGkSkyR75NcDnO0RyzYLP18u4WhAAdk1Smyd9Cy53od-jvv9GfgERihTej2s78_mfc9w_AP2IDObWxa2le8koyGiKedRfHYxzLNkQ45m7IZ-07-qbam0wYhIRNS4Yhj5IEsPcgU_GvKJRuKK4QH5UnixHOKcOvuQcAwNtWuhoy3-sph_zNolirPVOYWHPNSfGBSOdoqGSfv3tD3aKLEc_edKlOyhRxPeXRSSKKyxitLIO7G04d2-tkCgbh19DnPuFwNwMNb_ZQNyFxcf70_IOtp_lbC1Wg1UGr8sTorTHloSDO779nGcxc_aB6f_0LNCggxgtiSTVNyrEUyAte8gzqUWVcyrpDKnhC3zUJvHm_JRJ62n7F0GTWPOaReIqhav28ROY82EqQd3PhBjFTguRV8SwN68VA-1zSo37KgECr-V2HGDf_Yf1VRztEXZkOZNkF-B2Oldw1dmLdjZICSiW14MeuLFyLdKFMn0ya0scAEyzsX-j8tbvW0d302RPMw5C7Cz3GyPnTUb41uUlSKefE8_rpQlOQEEBNl-bKDwbcQEbctjpJGCYMc8gmoEAu_oytNAtjA9r1_riGcwQT2Aa_x_RumvnWKsuj1M_f8sakSdB_5hC96vVstwgHf6MLHmFstS73FqzLC5xmVt3mbE0-tR8oMotrXhs9zFd1yC83p-dko5sVYVNrbhKSDACZ7qJbsL7gvp-bdolHWmkU-n3l_Xqz637xrKe0bIZmX8RruNN9ivWRr8fd8u57ST9YzsMiTMMmtHO5lrxqGfQL-uBMWJXu1H1zNSqY8jKfGMWb0ILSDGTLPjRRyRS3OwEhCuKKhLTURI0JVrDsmRWux1Gj7ue-_mjM0Do6mEMb_XvYexp_REiuj3CzlyEAO1iPPT1oi3EterjU7LtkYKQj3haAsOtEFuF5FK-7IIWVwTeEfJD4dvc3qmH2ghM96nYP1muaN198ONdzID-0uH5IlSjJJpcv9596pTNsvOreIqdUo2EeUle4q0J0aOykzTnUWB5HMk7dzqeidshxcDGcioyPFydPr_zd-4QRTPEYWAxjOr4HUTlutNiH-6Rxyq8kdq0kHhMz36Wf5vE3sI54FBf96JlqFat3i",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 990.56,
            "totalPrice": 776
          },
          {
            "allPassengerTotalPrice": 776,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvVopB7ymNhkN8rrwthnsfdCilM0s46kJIPvRNWSzgf_bY4UwPxVKTUwSpMUH5GNYnuSc6tST8NkxX2DzebpiBvo6v0JbGBniNQkwvnTaRcAG4hX3KIYpi78xPmHMRSPX1-oNmD8Q-xAUUOhwUIOW9ajsgqG5lMqZEK_k3r6ebuTorK54yupSkN2e5xlddYUGfPeQV3VaKemfFnxXsFEXt44XJHV3Ww53DjJd9EOLmVWNU6vDCO1K9PJ6UN_apKpgk0O8iCqGs6b2ZYwny3YAO3Sk5tsI1tsP7US2kdWG4gIwsrFtb5hPWMwq3Jf8URF_6K8TUFPUkj176_FEaY4cjA17ht1HbWqbNSQsaAwHvFzWHL5grMwzofV3VPv-d9431ouvRfXO9wCph8G-BBQBobuF1gV5L3Z_5wcB5Q_7Bexso2WG_-uHXWUYY_Q3F3r-tiFVrLtNBZU8xIOOo1TAoWVxogA7JZEP_MlsLwqX4tP_01zP2gkfM-0HI989sXdStjBHeOuDXgfcG8B5vWMs0Xuo9QFaQMZ50Nkk-iIl8-WwEdCnWfMpu8a8IMpww2P-rirwwY71rJTd3bra4AQpLLk0N8dUDmq8FrJhAel0CK-oQVqN3mQ_ttPurRD9A0amF29-Ell_uFOElYF3mCbuq4nI1cbilqcV-rpvIWnKeLZFl2TOcz_ax7oOEEi3PSbfszW5dQsWpqPqts5zxLz5AZpnA_GYP_2M9yIWXM1HGpzgws14u7h1vLz0WDvuNVD1fCIfwSLa32MDwH1d56Q-LP5IkLYJQYZVvIRDTwtdW5Y5iS6sYmJCauRZfyg1LDbX77B4mfrCRkkAe3qOFBXC8WePpiiVa3N-0CrV-xRO6tHtvBEdqwc0kP3xkla8b28UeLt2mxMg-FOq951lpcEVUYEfZAY8svXyGCdvy8fnyS_C_fC3medk6I6U1m71c6tpV-p91FK2MPmg6kX1-pwXgYf7RqM9RIrb-Y4cVzlRCEHIlt_slHp13KckOyGwUVKazhYbWcKuqP4tpAYKmeGj111yPR85oU1qZkKnfpfzALXFgjadQbdsqE1NUW_m9U_yK2QPxnOhbjOIr2g6Op3sIP6GDkUBP4V7Pe0G4VxLJYMj0T2rAFPtCX3vNSl6JiSshQy92D0LV614fP85ypsJ5vRCfm7-j0_hB3Q9n_AARjiiVnGjT397OooGbQI-UrRLYu_RbjaIZGDvHiJGT4H3lA==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvVopB7ymNhkN8rrwthnsfdCilM0s46kJIPvRNWSzgf_bY4UwPxVKTUwSpMUH5GNYnuSc6tST8NkxX2DzebpiBvo6v0JbGBniNQkwvnTaRcAG4hX3KIYpi78xPmHMRSPX1-oNmD8Q-xAUUOhwUIOW9ajsgqG5lMqZEK_k3r6ebuTorK54yupSkN2e5xlddYUGfPeQV3VaKemfFnxXsFEXt44XJHV3Ww53DjJd9EOLmVWNU6vDCO1K9PJ6UN_apKpgk0O8iCqGs6b2ZYwny3YAO3Sk5tsI1tsP7US2kdWG4gIwsrFtb5hPWMwq3Jf8URF_6K8TUFPUkj176_FEaY4cjA17ht1HbWqbNSQsaAwHvFzWHL5grMwzofV3VPv-d9431ouvRfXO9wCph8G-BBQBobuF1gV5L3Z_5wcB5Q_7Bexso2WG_-uHXWUYY_Q3F3r-tiFVrLtNBZU8xIOOo1TAoWVxogA7JZEP_MlsLwqX4tP_01zP2gkfM-0HI989sXdStjBHeOuDXgfcG8B5vWMs0Xuo9QFaQMZ50Nkk-iIl8-WwEdCnWfMpu8a8IMpww2P-rirwwY71rJTd3bra4AQpLLk0N8dUDmq8FrJhAel0CK-oQVqN3mQ_ttPurRD9A0amF29-Ell_uFOElYF3mCbuq4nI1cbilqcV-rpvIWnKeLZFl2TOcz_ax7oOEEi3PSbfszW5dQsWpqPqts5zxLz5AZpnA_GYP_2M9yIWXM1HGpzgws14u7h1vLz0WDvuNVD1fCIfwSLa32MDwH1d56Q-LP5IkLYJQYZVvIRDTwtdW5Y5iS6sYmJCauRZfyg1LDbX77B4mfrCRkkAe3qOFBXC8WePpiiVa3N-0CrV-xRO6tHtvBEdqwc0kP3xkla8b28UeLt2mxMg-FOq951lpcEVUYEfZAY8svXyGCdvy8fnyS_C_fC3medk6I6U1m71c6tpV-p91FK2MPmg6kX1-pwXgYf7RqM9RIrb-Y4cVzlRCEHIlt_slHp13KckOyGwUVKazhYbWcKuqP4tpAYKmeGj111yPR85oU1qZkKnfpfzALXFgjadQbdsqE1NUW_m9U_yK2QPxnOhbjOIr2g6Op3sIP6GDkUBP4V7Pe0G4VxLJYMj0T2rAFPtCX3vNSl6JiSshQy92D0LV614fP85ypsJ5vRCfm7-j0_hB3Q9n_AARjiiVnGjT397OooGbQI-UrRLYu_RbjaIZGDvHiJGT4H3lA==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvVopB7ymNhkN8rrwthnsfdCilM0s46kJIPvRNWSzgf_bY4UwPxVKTUwSpMUH5GNYnuSc6tST8NkxX2DzebpiBvo6v0JbGBniNQkwvnTaRcAG4hX3KIYpi78xPmHMRSPX1-oNmD8Q-xAUUOhwUIOW9ajsgqG5lMqZEK_k3r6ebuTorK54yupSkN2e5xlddYUGfPeQV3VaKemfFnxXsFEXt44XJHV3Ww53DjJd9EOLmVWNU6vDCO1K9PJ6UN_apKpgk0O8iCqGs6b2ZYwny3YAO3Sk5tsI1tsP7US2kdWG4gIwsrFtb5hPWMwq3Jf8URF_6K8TUFPUkj176_FEaY4cjA17ht1HbWqbNSQsaAwHvFzWHL5grMwzofV3VPv-d9431ouvRfXO9wCph8G-BBQBobuF1gV5L3Z_5wcB5Q_7Bexso2WG_-uHXWUYY_Q3F3r-tiFVrLtNBZU8xIOOo1TAoWVxogA7JZEP_MlsLwqX4tP_01zP2gkfM-0HI989sXdStjBHeOuDXgfcG8B5vWMs0Xuo9QFaQMZ50Nkk-iIl8-WwEdCnWfMpu8a8IMpww2P-rirwwY71rJTd3bra4AQpLLk0N8dUDmq8FrJhAel0CK-oQVqN3mQ_ttPurRD9A0amF29-Ell_uFOElYF3mCbuq4nI1cbilqcV-rpvIWnKeLZFl2TOcz_ax7oOEEi3PSbfszW5dQsWpqPqts5zxLz5AZpnA_GYP_2M9yIWXM1HGpzgws14u7h1vLz0WDvuNVD1fCIfwSLa32MDwH1d56Q-LP5IkLYJQYZVvIRDTwtdW5Y5iS6sYmJCauRZfyg1LDbX77B4mfrCRkkAe3qOFBXC8WePpiiVa3N-0CrV-xRO6tHtvBEdqwc0kP3xkla8b28UeLt2mxMg-FOq951lpcEVUYEfZAY8svXyGCdvy8fnyS_C_fC3medk6I6U1m71c6tpV-p91FK2MPmg6kX1-pwXgYf7RqM9RIrb-Y4cVzlRCEHIlt_slHp13KckOyGwUVKazhYbWcKuqP4tpAYKmeGj111yPR85oU1qZkKnfpfzALXFgjadQbdsqE1NUW_m9U_yK2QPxnOhbjOIr2g6Op3sIP6GDkUBP4V7Pe0G4VxLJYMj0T2rAFPtCX3vNSl6JiSshQy92D0LV614fP85ypsJ5vRCfm7-j0_hB3Q9n_AARjiiVnGjT397OooGbQI-UrRLYu_RbjaIZGDvHiJGT4H3lA==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 991.23,
            "totalPrice": 776
          },
          {
            "allPassengerTotalPrice": 777,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkHj_Xe-Jk0tGnqFmsa_fdEjnbsPGgLsj32deUQzz9nwR2rRVbBMZgM9oaXxuNoFZYp999PDeBtP-CH3lv6rk60r-LeGGSaXp7ZSFuxn8RIUUet8I20gG2c9pcKyh_sYBOgSu6fe3lElAoB3BCXNEOzFMoU-52UNJzQr5JT1sG5OrTSWyslUvakRhVcdexhbVQCDe7kbbNA5Mv4mRmmrK8X7LK2wnw5pWrFNq7-pPLG-UM3pBNye4VwK7ZHhf-qYQHc7cb3UvyYyuQKNIw-4ljeJt765ydzPxIoGmtxwS9sLSsL685Z4nz-xAridYCu2sLldYGgqmwn0Z8boiSfNP5kcgVDzs7tO7eKj1GDZEFSVwdUsC2yCxidPuMcuP_8Q6RLo78mhuODEBeis43YbHUdBQjtKCXdJ86-i_gSNv8mU8WH0XazFerg1VdpwJ-_tqTcoAtsBZ3yFf1UAyifodRHrD_GQBM6DBAD6Up0AbxzpftQgJp41MRXDPjWNEJ-c3Jb8Zb5roZe8_Ab8YOoaOoEDb-f-wpz5Q2uTmR-rjokt4R9aK41V2arLcRIHRRPdMOF0x6CBphxDnkBumOFEDpOJRF1-DQrZjhmF32xh0LnoMwko6vm23xrJDd4I5Coz41NCLPJKgqHOka7-W4mTPk63_0APnaYt7DfX9MDO0MtiCIzGtQaaXjh_IRgMIq9HuLNXoSaXM-XZ2lHRQ1UytazJOPUxZJn0ybwz3PxGnJNZg0NlcziTkyUW-8QRefkjum27GACKJeiidcrE2WqJOAzEjxwaoltBumgSTbK4czigYHIYfITWHg7BoQIMClocau2Xsn-MajUXUoqpyD_4FZAmaZ-BupVTooGVoRQUa4DSflBub1d9jqtcsSC67taNISMMU4NPkYgzvGxxfA06n2rH7YuQUP9ZXLxaLdN4nKOOs056aJaLUMR889UZQdmcsewGv4kF32erb-mN_Aw-gjIorUf-Kv4WEAp0VN65JnU9ebaEvHWMEM1v5WPE-MmB3RocE5s7be2PlC0brfx3C8usHjNu7AHfrH4gYGIE1Hwu1xYi_TJ9HIsyaws6vWDyGyXrNclt7zBi7PvK0Edpr_qqBH7zGAqgRrAkjaM7MRz4S0MBgqRjJvW0XZqqBAP2Y82E1QetH92mjDzIIZ0zxAxBCh3Rpi4tk1otJOtfjZlZwbLD0tbTeI2sHQAIk1UQa",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkHj_Xe-Jk0tGnqFmsa_fdEjnbsPGgLsj32deUQzz9nwR2rRVbBMZgM9oaXxuNoFZYp999PDeBtP-CH3lv6rk60r-LeGGSaXp7ZSFuxn8RIUUet8I20gG2c9pcKyh_sYBOgSu6fe3lElAoB3BCXNEOzFMoU-52UNJzQr5JT1sG5OrTSWyslUvakRhVcdexhbVQCDe7kbbNA5Mv4mRmmrK8X7LK2wnw5pWrFNq7-pPLG-UM3pBNye4VwK7ZHhf-qYQHc7cb3UvyYyuQKNIw-4ljeJt765ydzPxIoGmtxwS9sLSsL685Z4nz-xAridYCu2sLldYGgqmwn0Z8boiSfNP5kcgVDzs7tO7eKj1GDZEFSVwdUsC2yCxidPuMcuP_8Q6RLo78mhuODEBeis43YbHUdBQjtKCXdJ86-i_gSNv8mU8WH0XazFerg1VdpwJ-_tqTcoAtsBZ3yFf1UAyifodRHrD_GQBM6DBAD6Up0AbxzpftQgJp41MRXDPjWNEJ-c3Jb8Zb5roZe8_Ab8YOoaOoEDb-f-wpz5Q2uTmR-rjokt4R9aK41V2arLcRIHRRPdMOF0x6CBphxDnkBumOFEDpOJRF1-DQrZjhmF32xh0LnoMwko6vm23xrJDd4I5Coz41NCLPJKgqHOka7-W4mTPk63_0APnaYt7DfX9MDO0MtiCIzGtQaaXjh_IRgMIq9HuLNXoSaXM-XZ2lHRQ1UytazJOPUxZJn0ybwz3PxGnJNZg0NlcziTkyUW-8QRefkjum27GACKJeiidcrE2WqJOAzEjxwaoltBumgSTbK4czigYHIYfITWHg7BoQIMClocau2Xsn-MajUXUoqpyD_4FZAmaZ-BupVTooGVoRQUa4DSflBub1d9jqtcsSC67taNISMMU4NPkYgzvGxxfA06n2rH7YuQUP9ZXLxaLdN4nKOOs056aJaLUMR889UZQdmcsewGv4kF32erb-mN_Aw-gjIorUf-Kv4WEAp0VN65JnU9ebaEvHWMEM1v5WPE-MmB3RocE5s7be2PlC0brfx3C8usHjNu7AHfrH4gYGIE1Hwu1xYi_TJ9HIsyaws6vWDyGyXrNclt7zBi7PvK0Edpr_qqBH7zGAqgRrAkjaM7MRz4S0MBgqRjJvW0XZqqBAP2Y82E1QetH92mjDzIIZ0zxAxBCh3Rpi4tk1otJOtfjZlZwbLD0tbTeI2sHQAIk1UQa",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkHj_Xe-Jk0tGnqFmsa_fdEjnbsPGgLsj32deUQzz9nwR2rRVbBMZgM9oaXxuNoFZYp999PDeBtP-CH3lv6rk60r-LeGGSaXp7ZSFuxn8RIUUet8I20gG2c9pcKyh_sYBOgSu6fe3lElAoB3BCXNEOzFMoU-52UNJzQr5JT1sG5OrTSWyslUvakRhVcdexhbVQCDe7kbbNA5Mv4mRmmrK8X7LK2wnw5pWrFNq7-pPLG-UM3pBNye4VwK7ZHhf-qYQHc7cb3UvyYyuQKNIw-4ljeJt765ydzPxIoGmtxwS9sLSsL685Z4nz-xAridYCu2sLldYGgqmwn0Z8boiSfNP5kcgVDzs7tO7eKj1GDZEFSVwdUsC2yCxidPuMcuP_8Q6RLo78mhuODEBeis43YbHUdBQjtKCXdJ86-i_gSNv8mU8WH0XazFerg1VdpwJ-_tqTcoAtsBZ3yFf1UAyifodRHrD_GQBM6DBAD6Up0AbxzpftQgJp41MRXDPjWNEJ-c3Jb8Zb5roZe8_Ab8YOoaOoEDb-f-wpz5Q2uTmR-rjokt4R9aK41V2arLcRIHRRPdMOF0x6CBphxDnkBumOFEDpOJRF1-DQrZjhmF32xh0LnoMwko6vm23xrJDd4I5Coz41NCLPJKgqHOka7-W4mTPk63_0APnaYt7DfX9MDO0MtiCIzGtQaaXjh_IRgMIq9HuLNXoSaXM-XZ2lHRQ1UytazJOPUxZJn0ybwz3PxGnJNZg0NlcziTkyUW-8QRefkjum27GACKJeiidcrE2WqJOAzEjxwaoltBumgSTbK4czigYHIYfITWHg7BoQIMClocau2Xsn-MajUXUoqpyD_4FZAmaZ-BupVTooGVoRQUa4DSflBub1d9jqtcsSC67taNISMMU4NPkYgzvGxxfA06n2rH7YuQUP9ZXLxaLdN4nKOOs056aJaLUMR889UZQdmcsewGv4kF32erb-mN_Aw-gjIorUf-Kv4WEAp0VN65JnU9ebaEvHWMEM1v5WPE-MmB3RocE5s7be2PlC0brfx3C8usHjNu7AHfrH4gYGIE1Hwu1xYi_TJ9HIsyaws6vWDyGyXrNclt7zBi7PvK0Edpr_qqBH7zGAqgRrAkjaM7MRz4S0MBgqRjJvW0XZqqBAP2Y82E1QetH92mjDzIIZ0zxAxBCh3Rpi4tk1otJOtfjZlZwbLD0tbTeI2sHQAIk1UQa",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 992.07,
            "totalPrice": 777
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnQqTqwEuSYoPYSnKIE49y-OlcoshL8Zai3SWX6VDNhCOhJTCLmfNjiT5EN5j50lGrNebLn0osmi3Pf3Ua4V1Cn8sfiiQ6zlg9dxxSjb56iMJjVQZXrLgNGzL8S-afgl393kvjMCPvbJ4-tyxQVdc51v0GCN8kG4m_uyQ1Jvbq5MkCxD-6dK0DhFNupWJiKPQoHxRckxpgYE_RkmTAK9gl-QraG-vwasfSaB2iTBGNRSmqtK_y-ebIUOYV-OOzupPe-Gmkd35ZIg7dHj6Rm8euuyNC0XLC6vC2sEn55w6epKoA1MkwKlguiyJlaV3ms0aZF4Y7jcv8ZdMkrdhCFz8cfFpW5umzdsDgTOZoZ0djtDKlguTK11R6NXMA3WBNAqjUPTglqE2rKNHsa8budcVSL20_188ufihIRo4TxdL7Gm49lEz6u4ZlH4bFxlNud9sNuSpfSvIRa_nKuRV0LvQ6Np8ovBpUG4OOKMqs2h8AOZnnImbNoLDBTdYQolb-VyyTeNRRIc3vrhHTwDRb3xqn_e2aAai-ftAqxL2vbAsa68q5c6WTTX6htppCeaHBQytnsDzaDsMEa3Hl2QumbkPqIxxrHMT5J5XwAyxmf0R_efTPuH5uu9QtjvVyTAfDj7IvW1CS3gheG82goOpqUHnYTSWTucQpezCFLEWZyPuXfMU4FOdN9GOLgxAnzNPMBKtVmQXGaPh0uWw2hrL8Z3w79s-OTQwqVMrHXMmag7_bQMm-Tfaxm2t5igxNnLP50ZtmLFj9MbVn2_fWKFX90vN8QJSGuh_PRStTmnaut2HdEhFTZsdhRRlO8RC23Ar5UjogCRXrJQFIhzfYrhe0KcQx5XBHT-k2lhGr00Ta0Pyk4cSFh1hatswQah8KmMnKfHML8cLl1y-o18dE29UxRm_SNpc8Fq1TIFK9IjnW3VwCHsOPyOrqpkHYaEgI-TPdqrFkeoCsxubouWvs6SumtBbxrOW_kF-wG8Ho1eTyqjFmBT6G4CsX_EXVhu--8_9e11ejkLtfKmD-J6icYt3Zzn50QOuobIWsdm1O3zErZ-vQOLLN7ehHB89EPTMosqSoUjCPegub6Y7Yfgs3qu5LcC5irfDbi5-X3Gc9q5RSXaDW9l_U90rcngWn4UDycrGivEjGrpTXm0v_J1tC_WlqYC3Sngc4OLOeaXtjd4M12wU8aW6h5xk6Y69_NdwHaaqBMlrLLpAPSIqzF6Msm1nxbsFm3Y4L_-AVX-26b-bkVhizSI=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnQqTqwEuSYoPYSnKIE49y-OlcoshL8Zai3SWX6VDNhCOhJTCLmfNjiT5EN5j50lGrNebLn0osmi3Pf3Ua4V1Cn8sfiiQ6zlg9dxxSjb56iMJjVQZXrLgNGzL8S-afgl393kvjMCPvbJ4-tyxQVdc51v0GCN8kG4m_uyQ1Jvbq5MkCxD-6dK0DhFNupWJiKPQoHxRckxpgYE_RkmTAK9gl-QraG-vwasfSaB2iTBGNRSmqtK_y-ebIUOYV-OOzupPe-Gmkd35ZIg7dHj6Rm8euuyNC0XLC6vC2sEn55w6epKoA1MkwKlguiyJlaV3ms0aZF4Y7jcv8ZdMkrdhCFz8cfFpW5umzdsDgTOZoZ0djtDKlguTK11R6NXMA3WBNAqjUPTglqE2rKNHsa8budcVSL20_188ufihIRo4TxdL7Gm49lEz6u4ZlH4bFxlNud9sNuSpfSvIRa_nKuRV0LvQ6Np8ovBpUG4OOKMqs2h8AOZnnImbNoLDBTdYQolb-VyyTeNRRIc3vrhHTwDRb3xqn_e2aAai-ftAqxL2vbAsa68q5c6WTTX6htppCeaHBQytnsDzaDsMEa3Hl2QumbkPqIxxrHMT5J5XwAyxmf0R_efTPuH5uu9QtjvVyTAfDj7IvW1CS3gheG82goOpqUHnYTSWTucQpezCFLEWZyPuXfMU4FOdN9GOLgxAnzNPMBKtVmQXGaPh0uWw2hrL8Z3w79s-OTQwqVMrHXMmag7_bQMm-Tfaxm2t5igxNnLP50ZtmLFj9MbVn2_fWKFX90vN8QJSGuh_PRStTmnaut2HdEhFTZsdhRRlO8RC23Ar5UjogCRXrJQFIhzfYrhe0KcQx5XBHT-k2lhGr00Ta0Pyk4cSFh1hatswQah8KmMnKfHML8cLl1y-o18dE29UxRm_SNpc8Fq1TIFK9IjnW3VwCHsOPyOrqpkHYaEgI-TPdqrFkeoCsxubouWvs6SumtBbxrOW_kF-wG8Ho1eTyqjFmBT6G4CsX_EXVhu--8_9e11ejkLtfKmD-J6icYt3Zzn50QOuobIWsdm1O3zErZ-vQOLLN7ehHB89EPTMosqSoUjCPegub6Y7Yfgs3qu5LcC5irfDbi5-X3Gc9q5RSXaDW9l_U90rcngWn4UDycrGivEjGrpTXm0v_J1tC_WlqYC3Sngc4OLOeaXtjd4M12wU8aW6h5xk6Y69_NdwHaaqBMlrLLpAPSIqzF6Msm1nxbsFm3Y4L_-AVX-26b-bkVhizSI=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnQqTqwEuSYoPYSnKIE49y-OlcoshL8Zai3SWX6VDNhCOhJTCLmfNjiT5EN5j50lGrNebLn0osmi3Pf3Ua4V1Cn8sfiiQ6zlg9dxxSjb56iMJjVQZXrLgNGzL8S-afgl393kvjMCPvbJ4-tyxQVdc51v0GCN8kG4m_uyQ1Jvbq5MkCxD-6dK0DhFNupWJiKPQoHxRckxpgYE_RkmTAK9gl-QraG-vwasfSaB2iTBGNRSmqtK_y-ebIUOYV-OOzupPe-Gmkd35ZIg7dHj6Rm8euuyNC0XLC6vC2sEn55w6epKoA1MkwKlguiyJlaV3ms0aZF4Y7jcv8ZdMkrdhCFz8cfFpW5umzdsDgTOZoZ0djtDKlguTK11R6NXMA3WBNAqjUPTglqE2rKNHsa8budcVSL20_188ufihIRo4TxdL7Gm49lEz6u4ZlH4bFxlNud9sNuSpfSvIRa_nKuRV0LvQ6Np8ovBpUG4OOKMqs2h8AOZnnImbNoLDBTdYQolb-VyyTeNRRIc3vrhHTwDRb3xqn_e2aAai-ftAqxL2vbAsa68q5c6WTTX6htppCeaHBQytnsDzaDsMEa3Hl2QumbkPqIxxrHMT5J5XwAyxmf0R_efTPuH5uu9QtjvVyTAfDj7IvW1CS3gheG82goOpqUHnYTSWTucQpezCFLEWZyPuXfMU4FOdN9GOLgxAnzNPMBKtVmQXGaPh0uWw2hrL8Z3w79s-OTQwqVMrHXMmag7_bQMm-Tfaxm2t5igxNnLP50ZtmLFj9MbVn2_fWKFX90vN8QJSGuh_PRStTmnaut2HdEhFTZsdhRRlO8RC23Ar5UjogCRXrJQFIhzfYrhe0KcQx5XBHT-k2lhGr00Ta0Pyk4cSFh1hatswQah8KmMnKfHML8cLl1y-o18dE29UxRm_SNpc8Fq1TIFK9IjnW3VwCHsOPyOrqpkHYaEgI-TPdqrFkeoCsxubouWvs6SumtBbxrOW_kF-wG8Ho1eTyqjFmBT6G4CsX_EXVhu--8_9e11ejkLtfKmD-J6icYt3Zzn50QOuobIWsdm1O3zErZ-vQOLLN7ehHB89EPTMosqSoUjCPegub6Y7Yfgs3qu5LcC5irfDbi5-X3Gc9q5RSXaDW9l_U90rcngWn4UDycrGivEjGrpTXm0v_J1tC_WlqYC3Sngc4OLOeaXtjd4M12wU8aW6h5xk6Y69_NdwHaaqBMlrLLpAPSIqzF6Msm1nxbsFm3Y4L_-AVX-26b-bkVhizSI=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1029.65,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gf9tPG-uBu9hK1iN-ThIeRp3gtcbJnge_SIEKrlyBpfk3UeyS0CW1QMheY462NxSYeNndC67INzUZogFjBpUGtLHaOMl-pGvqAFRW1PB0tszWMxCTkgcdFcCwNN31vachBXKFqxAHgscnFStOEEBqTMrR-XyAtla2DUcBRP1meQB4YVB5n5c0NTTdpCO36k5Xe88F5Ip_XkdPmgIl6QCwxfag7DXwZquMflzOoKzbx8jB-wnPcdHsnUkmiEivJuIMfOwcOXTAkGefDUU5wXmRwieR2i1oFRjkc9jTPu33GT-hJ_xswKI2bViJj_zNg8pbDZzpWj0v-nIjdJ5RsisjhCEU6FG9Zqc6lEaYI_ERW_RgB1NIt6wrTiD0a_voo4e4PGnl2DAq7xoeyw5qCSIoq4QCbP903cawq1GgPVU9AMq3qNxhBKYaAjjI9JaDfxIfeRyybKFVQrRPLIhYZB5dPfh4WMDO9F7gNzuXvSzGIWdK-jgPvvUHNk0dZu2lFqjRO87OoZKkYR9j3GUpeVsjzk_g-9StySCyM_VyiLHiOrxgdzSU8QVqmtdHnPAJhsG-mj25OJOmqFsy6QYnEgzwBE38f7kWfhh8d3RYnmBELYbHJvzPu8NCZvnsf4bbx0yOQa5Qsm6ITkVrfVuN-Pui8L29-whRg5MYb0tM79_a7cwFdUN_-6EZR45lJWRdP9voiWTvvF0Wr4k5-2mvRVhWzvrUUv0KIsd-eeMmUFIuSnmm1NmYw2jvFOa8V2wYduDgVo1Ma3MKc5o4rQVvR_t5LpdIDtZ5lIbVLdkc-uDmcm33X2K17MVcC5K7PEJ7MFG0w80aUlXn21sDJPquRUIt3WwbMNY8Vc4Wpn2WJHQ9xJld8eTU6X82xBaJ63wYMgs8p_G-y_6Hx029QmgkJN_0veXvpnpYqpphwtgGWLv1xAOWCyJa3bL_t8rLL5JgdYFWk7y6ecVhY06gdu-icAOYZi6PPkQTuY0Nrz2yPKxH-i8Px0kIcO96tidpD46vCoe2wmzKdR-UWESMYvO6V5mWX2M3ejNt_t_eQsaPBGMECQ1Ha7A4487HFC7xfGYrb-CyYVPtRManjHeKbwf65qr5nhEXi9lsMYEzwlCGJYBhgTE1aXrWGy83fBPwIodDfzFMjgLh7qa3aeq8zs59RlD2YDIdOc9PHUC8S55IaGRyNB1BOo_enHbOSi6f7meQZc0GwJZNosyLdpSBUDQ_rv6dBHKVQDdiOvLw4b0anVTKpS4=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gf9tPG-uBu9hK1iN-ThIeRp3gtcbJnge_SIEKrlyBpfk3UeyS0CW1QMheY462NxSYeNndC67INzUZogFjBpUGtLHaOMl-pGvqAFRW1PB0tszWMxCTkgcdFcCwNN31vachBXKFqxAHgscnFStOEEBqTMrR-XyAtla2DUcBRP1meQB4YVB5n5c0NTTdpCO36k5Xe88F5Ip_XkdPmgIl6QCwxfag7DXwZquMflzOoKzbx8jB-wnPcdHsnUkmiEivJuIMfOwcOXTAkGefDUU5wXmRwieR2i1oFRjkc9jTPu33GT-hJ_xswKI2bViJj_zNg8pbDZzpWj0v-nIjdJ5RsisjhCEU6FG9Zqc6lEaYI_ERW_RgB1NIt6wrTiD0a_voo4e4PGnl2DAq7xoeyw5qCSIoq4QCbP903cawq1GgPVU9AMq3qNxhBKYaAjjI9JaDfxIfeRyybKFVQrRPLIhYZB5dPfh4WMDO9F7gNzuXvSzGIWdK-jgPvvUHNk0dZu2lFqjRO87OoZKkYR9j3GUpeVsjzk_g-9StySCyM_VyiLHiOrxgdzSU8QVqmtdHnPAJhsG-mj25OJOmqFsy6QYnEgzwBE38f7kWfhh8d3RYnmBELYbHJvzPu8NCZvnsf4bbx0yOQa5Qsm6ITkVrfVuN-Pui8L29-whRg5MYb0tM79_a7cwFdUN_-6EZR45lJWRdP9voiWTvvF0Wr4k5-2mvRVhWzvrUUv0KIsd-eeMmUFIuSnmm1NmYw2jvFOa8V2wYduDgVo1Ma3MKc5o4rQVvR_t5LpdIDtZ5lIbVLdkc-uDmcm33X2K17MVcC5K7PEJ7MFG0w80aUlXn21sDJPquRUIt3WwbMNY8Vc4Wpn2WJHQ9xJld8eTU6X82xBaJ63wYMgs8p_G-y_6Hx029QmgkJN_0veXvpnpYqpphwtgGWLv1xAOWCyJa3bL_t8rLL5JgdYFWk7y6ecVhY06gdu-icAOYZi6PPkQTuY0Nrz2yPKxH-i8Px0kIcO96tidpD46vCoe2wmzKdR-UWESMYvO6V5mWX2M3ejNt_t_eQsaPBGMECQ1Ha7A4487HFC7xfGYrb-CyYVPtRManjHeKbwf65qr5nhEXi9lsMYEzwlCGJYBhgTE1aXrWGy83fBPwIodDfzFMjgLh7qa3aeq8zs59RlD2YDIdOc9PHUC8S55IaGRyNB1BOo_enHbOSi6f7meQZc0GwJZNosyLdpSBUDQ_rv6dBHKVQDdiOvLw4b0anVTKpS4=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gf9tPG-uBu9hK1iN-ThIeRp3gtcbJnge_SIEKrlyBpfk3UeyS0CW1QMheY462NxSYeNndC67INzUZogFjBpUGtLHaOMl-pGvqAFRW1PB0tszWMxCTkgcdFcCwNN31vachBXKFqxAHgscnFStOEEBqTMrR-XyAtla2DUcBRP1meQB4YVB5n5c0NTTdpCO36k5Xe88F5Ip_XkdPmgIl6QCwxfag7DXwZquMflzOoKzbx8jB-wnPcdHsnUkmiEivJuIMfOwcOXTAkGefDUU5wXmRwieR2i1oFRjkc9jTPu33GT-hJ_xswKI2bViJj_zNg8pbDZzpWj0v-nIjdJ5RsisjhCEU6FG9Zqc6lEaYI_ERW_RgB1NIt6wrTiD0a_voo4e4PGnl2DAq7xoeyw5qCSIoq4QCbP903cawq1GgPVU9AMq3qNxhBKYaAjjI9JaDfxIfeRyybKFVQrRPLIhYZB5dPfh4WMDO9F7gNzuXvSzGIWdK-jgPvvUHNk0dZu2lFqjRO87OoZKkYR9j3GUpeVsjzk_g-9StySCyM_VyiLHiOrxgdzSU8QVqmtdHnPAJhsG-mj25OJOmqFsy6QYnEgzwBE38f7kWfhh8d3RYnmBELYbHJvzPu8NCZvnsf4bbx0yOQa5Qsm6ITkVrfVuN-Pui8L29-whRg5MYb0tM79_a7cwFdUN_-6EZR45lJWRdP9voiWTvvF0Wr4k5-2mvRVhWzvrUUv0KIsd-eeMmUFIuSnmm1NmYw2jvFOa8V2wYduDgVo1Ma3MKc5o4rQVvR_t5LpdIDtZ5lIbVLdkc-uDmcm33X2K17MVcC5K7PEJ7MFG0w80aUlXn21sDJPquRUIt3WwbMNY8Vc4Wpn2WJHQ9xJld8eTU6X82xBaJ63wYMgs8p_G-y_6Hx029QmgkJN_0veXvpnpYqpphwtgGWLv1xAOWCyJa3bL_t8rLL5JgdYFWk7y6ecVhY06gdu-icAOYZi6PPkQTuY0Nrz2yPKxH-i8Px0kIcO96tidpD46vCoe2wmzKdR-UWESMYvO6V5mWX2M3ejNt_t_eQsaPBGMECQ1Ha7A4487HFC7xfGYrb-CyYVPtRManjHeKbwf65qr5nhEXi9lsMYEzwlCGJYBhgTE1aXrWGy83fBPwIodDfzFMjgLh7qa3aeq8zs59RlD2YDIdOc9PHUC8S55IaGRyNB1BOo_enHbOSi6f7meQZc0GwJZNosyLdpSBUDQ_rv6dBHKVQDdiOvLw4b0anVTKpS4=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1029.65,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLGa8HxmhdKjjSQgONrHZWqmzOsvdnni1qRvlq9yv_yZeMgdgh4I38ePZKF-IDlYvki140ciog0rzSTJ1wdyPePVyfT0XJM0qjxos3DziNBAxWSwG7PWJ3aubijw0SAMBhgxSHXjGCLMb7IHhTuz4dw36VVkghvAwFeZpwJlKpv7rWfSWIURzOncJuu08lNk2W0EOsbRJm2RdSAMHNVbxc6C0dffe-8n5HeQS7UZE7AC5QceNVa4LR2z_IXeTj411pird_1uWQYisqyXTyDC_KnjgpG8iIvc4Wg-T-Px8s2CYiciEstF9XGRRVqgflSSG3LG08Mv5knUEHoHnUSzUTjFivVnaVdUqGOlOV7wTgqxu82Xjl3LwFd3ABVMrAWE-u180Q6o3QOD-b86TRwiVwDyEPYCSF2QCS4u9eqTfrQMCuGR5qWtdNtm5vIggqGVJXxKDBB3B-lzF6XSQP-52AF71tDrsjIVFyj3ZjRDimWfxYioiTgLsspOWSFoHV5lXwIqRUvim1ZVvImA21m4214cCaQLvN5TKS_yD-3mHk26o_0kSzKl_DGw5H75d6QqT-UA4IiWYws51OJ-Bj3Z8rwzYiKwHy8pUm7amrSkIf0tc1KgQ4YF26zdtKdAHdfW31xpiXLCe5n8vwwBo1yM0H0_p9Oq27UPNzofMYejj6HI9DbzzaITYGhD4PF4skaH_wfMRmp2_09xfvwfCO8_fnq2NcAE4sf0K2yxa_UIYo767GHM0DGMG7WS4roQokRe6kQMUN4daBhZb3BrIw1FKsL4dAexXhG6jHhFsZAafu42qbJicN55JosbvIBdSERs_7YwoqYqp0aTydSalmfdMQ2KI_-hTOQlZ4uQzTLh9xa1aUgl-8RQCrInDoa8nSSJzx7HVBmOtNfWKicPejOD0ZUr5ppDUKCzrQIRzmL43wfdgTLgOnWUdP9Z4BbJlhLL8v69zL2qWABpyhWuIaRsWfv-nzhHeb0RQnafDQycz2nfaxV-bmy8CCZwqCxCq471Tzrt8BlWNCfDIcaIqXw3D6XICbcgYCmNrCrI1YwMDvftugrbDEXxK1ThDI655sojW1utDEbsIo8d5av3kT4yjGtnv5J7xDj39Pm2wkg-A_JL-W_ADRVb9w8QSMcPkPBYZMKNdP_d2oElglSzn0Bf-2Ps2IFGOevIO2T17s6ob8davHPd-6rm8wcV3oSKZPsYT-vKXlxvM7Zbdat4eRzUEiw0M2sBOofEfxp0uMVWx6TZN_jzqaj85v7EdLt7TAKB7",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLGa8HxmhdKjjSQgONrHZWqmzOsvdnni1qRvlq9yv_yZeMgdgh4I38ePZKF-IDlYvki140ciog0rzSTJ1wdyPePVyfT0XJM0qjxos3DziNBAxWSwG7PWJ3aubijw0SAMBhgxSHXjGCLMb7IHhTuz4dw36VVkghvAwFeZpwJlKpv7rWfSWIURzOncJuu08lNk2W0EOsbRJm2RdSAMHNVbxc6C0dffe-8n5HeQS7UZE7AC5QceNVa4LR2z_IXeTj411pird_1uWQYisqyXTyDC_KnjgpG8iIvc4Wg-T-Px8s2CYiciEstF9XGRRVqgflSSG3LG08Mv5knUEHoHnUSzUTjFivVnaVdUqGOlOV7wTgqxu82Xjl3LwFd3ABVMrAWE-u180Q6o3QOD-b86TRwiVwDyEPYCSF2QCS4u9eqTfrQMCuGR5qWtdNtm5vIggqGVJXxKDBB3B-lzF6XSQP-52AF71tDrsjIVFyj3ZjRDimWfxYioiTgLsspOWSFoHV5lXwIqRUvim1ZVvImA21m4214cCaQLvN5TKS_yD-3mHk26o_0kSzKl_DGw5H75d6QqT-UA4IiWYws51OJ-Bj3Z8rwzYiKwHy8pUm7amrSkIf0tc1KgQ4YF26zdtKdAHdfW31xpiXLCe5n8vwwBo1yM0H0_p9Oq27UPNzofMYejj6HI9DbzzaITYGhD4PF4skaH_wfMRmp2_09xfvwfCO8_fnq2NcAE4sf0K2yxa_UIYo767GHM0DGMG7WS4roQokRe6kQMUN4daBhZb3BrIw1FKsL4dAexXhG6jHhFsZAafu42qbJicN55JosbvIBdSERs_7YwoqYqp0aTydSalmfdMQ2KI_-hTOQlZ4uQzTLh9xa1aUgl-8RQCrInDoa8nSSJzx7HVBmOtNfWKicPejOD0ZUr5ppDUKCzrQIRzmL43wfdgTLgOnWUdP9Z4BbJlhLL8v69zL2qWABpyhWuIaRsWfv-nzhHeb0RQnafDQycz2nfaxV-bmy8CCZwqCxCq471Tzrt8BlWNCfDIcaIqXw3D6XICbcgYCmNrCrI1YwMDvftugrbDEXxK1ThDI655sojW1utDEbsIo8d5av3kT4yjGtnv5J7xDj39Pm2wkg-A_JL-W_ADRVb9w8QSMcPkPBYZMKNdP_d2oElglSzn0Bf-2Ps2IFGOevIO2T17s6ob8davHPd-6rm8wcV3oSKZPsYT-vKXlxvM7Zbdat4eRzUEiw0M2sBOofEfxp0uMVWx6TZN_jzqaj85v7EdLt7TAKB7",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLGa8HxmhdKjjSQgONrHZWqmzOsvdnni1qRvlq9yv_yZeMgdgh4I38ePZKF-IDlYvki140ciog0rzSTJ1wdyPePVyfT0XJM0qjxos3DziNBAxWSwG7PWJ3aubijw0SAMBhgxSHXjGCLMb7IHhTuz4dw36VVkghvAwFeZpwJlKpv7rWfSWIURzOncJuu08lNk2W0EOsbRJm2RdSAMHNVbxc6C0dffe-8n5HeQS7UZE7AC5QceNVa4LR2z_IXeTj411pird_1uWQYisqyXTyDC_KnjgpG8iIvc4Wg-T-Px8s2CYiciEstF9XGRRVqgflSSG3LG08Mv5knUEHoHnUSzUTjFivVnaVdUqGOlOV7wTgqxu82Xjl3LwFd3ABVMrAWE-u180Q6o3QOD-b86TRwiVwDyEPYCSF2QCS4u9eqTfrQMCuGR5qWtdNtm5vIggqGVJXxKDBB3B-lzF6XSQP-52AF71tDrsjIVFyj3ZjRDimWfxYioiTgLsspOWSFoHV5lXwIqRUvim1ZVvImA21m4214cCaQLvN5TKS_yD-3mHk26o_0kSzKl_DGw5H75d6QqT-UA4IiWYws51OJ-Bj3Z8rwzYiKwHy8pUm7amrSkIf0tc1KgQ4YF26zdtKdAHdfW31xpiXLCe5n8vwwBo1yM0H0_p9Oq27UPNzofMYejj6HI9DbzzaITYGhD4PF4skaH_wfMRmp2_09xfvwfCO8_fnq2NcAE4sf0K2yxa_UIYo767GHM0DGMG7WS4roQokRe6kQMUN4daBhZb3BrIw1FKsL4dAexXhG6jHhFsZAafu42qbJicN55JosbvIBdSERs_7YwoqYqp0aTydSalmfdMQ2KI_-hTOQlZ4uQzTLh9xa1aUgl-8RQCrInDoa8nSSJzx7HVBmOtNfWKicPejOD0ZUr5ppDUKCzrQIRzmL43wfdgTLgOnWUdP9Z4BbJlhLL8v69zL2qWABpyhWuIaRsWfv-nzhHeb0RQnafDQycz2nfaxV-bmy8CCZwqCxCq471Tzrt8BlWNCfDIcaIqXw3D6XICbcgYCmNrCrI1YwMDvftugrbDEXxK1ThDI655sojW1utDEbsIo8d5av3kT4yjGtnv5J7xDj39Pm2wkg-A_JL-W_ADRVb9w8QSMcPkPBYZMKNdP_d2oElglSzn0Bf-2Ps2IFGOevIO2T17s6ob8davHPd-6rm8wcV3oSKZPsYT-vKXlxvM7Zbdat4eRzUEiw0M2sBOofEfxp0uMVWx6TZN_jzqaj85v7EdLt7TAKB7",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1030.32,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnIRUtaRdAzvgziT3ZkDlA7p5VXWMYDodWpHuWSvmHX_qKUtVife9DPz6fqt1skobTSZwcrMgHdB8FNW-CK4NUJio3hvkUefLr6aH0C82iGjK54I8LLY872nS7NwAGiheY0WTj-lttnqLYycdF8QG8ba3IsN8UJjiYEoKS9M9Bds_4qqeIHBKKSnTNKGQHgkCOCyVZTaf9SgcH0O1UJ2H-W7sB4pbIi52PFQWtPqJJYmBkUOAA3F8E55tW2xVV8t60kV2NoMdi2YJhPDGgHeqRjIJdXEChe94PbKxVGNBEUe9CI0DBn1bqJRVozLPEsd_CWXGNvFKQT7anncbnkFo6UA3jTSMNBbYbjEPUD3WLbGtUEylM94jWaBfTTQfnzmumzDN7i1MUljGKyBL_iFgf8uhh2l5-OepVENNiboX62gfyhFmJTtwquwGCg8wZB4-CrpYqcKInOjEhSlUVOfWRcwqRD01Cs0VtGXkUOw7B8ixe0GV4IcmbgTpeyanXT5cK-X9a7PWo5mpOtAHLiTX-IyB6wxBG9OfRiXYLIvgIwSseYwVdua9-7-zxxmMNkhqsHvvXyrmSNwLI2uC7VnzgU62J0_WpCYIiBFAfXekSm478k7XCy7brCJxdS13pmttvQyjSYSBVnCuMSPXxe9dp_RL_bsXBf4RcoYFQs3p5Boh4Pnuv5pB2HdOxNrkNcUs2gSrs45MSfKndBtVezy5VRt1zziCx-vMoWn13fnQYqOmFzc5I214awX43ILHAFPhMHhdFOoFl_3jPMil1_3PahT7PcwWCPAG5JKlSeNhHplIhiQVMhMvMLXm4qmFlvEfscK69GQ9RiZ3NdZvXijEB6rIXtRG2tD1WpHHIvgyZTUNlmhfMC2fmdEtipQ0whTxRmceEGIRcX9FuhQsPEIOG3auUsxk8MPseyQ5rhJvSSN4DrM9ORwyw6OfI0pFwguxBFXOUHfsTvA999GQwfFizv3r3ZF9vV0_h2MqOMfqV_OktRNQEgMQftPev098_7mpjqY5uNsZ26AV1hKdKsEf_aiG3Pue2RxVJE_qv3Z89Z75dh7t2AycQr9l635k5DINyNuRHtGzJA34gx7CtXEl9pY-cJmmqcCIy8RsGpne0ImgQ2RoXkDfV0Ulo9r5CNw1wYwSlxbGp0_5E-sNlh5bDV7Sz1OyNHRl8ebM3DShQrE0nXuj4uLQjj6H0on7R_h127s3ZFijFQggyS6Bsx1XOw==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnIRUtaRdAzvgziT3ZkDlA7p5VXWMYDodWpHuWSvmHX_qKUtVife9DPz6fqt1skobTSZwcrMgHdB8FNW-CK4NUJio3hvkUefLr6aH0C82iGjK54I8LLY872nS7NwAGiheY0WTj-lttnqLYycdF8QG8ba3IsN8UJjiYEoKS9M9Bds_4qqeIHBKKSnTNKGQHgkCOCyVZTaf9SgcH0O1UJ2H-W7sB4pbIi52PFQWtPqJJYmBkUOAA3F8E55tW2xVV8t60kV2NoMdi2YJhPDGgHeqRjIJdXEChe94PbKxVGNBEUe9CI0DBn1bqJRVozLPEsd_CWXGNvFKQT7anncbnkFo6UA3jTSMNBbYbjEPUD3WLbGtUEylM94jWaBfTTQfnzmumzDN7i1MUljGKyBL_iFgf8uhh2l5-OepVENNiboX62gfyhFmJTtwquwGCg8wZB4-CrpYqcKInOjEhSlUVOfWRcwqRD01Cs0VtGXkUOw7B8ixe0GV4IcmbgTpeyanXT5cK-X9a7PWo5mpOtAHLiTX-IyB6wxBG9OfRiXYLIvgIwSseYwVdua9-7-zxxmMNkhqsHvvXyrmSNwLI2uC7VnzgU62J0_WpCYIiBFAfXekSm478k7XCy7brCJxdS13pmttvQyjSYSBVnCuMSPXxe9dp_RL_bsXBf4RcoYFQs3p5Boh4Pnuv5pB2HdOxNrkNcUs2gSrs45MSfKndBtVezy5VRt1zziCx-vMoWn13fnQYqOmFzc5I214awX43ILHAFPhMHhdFOoFl_3jPMil1_3PahT7PcwWCPAG5JKlSeNhHplIhiQVMhMvMLXm4qmFlvEfscK69GQ9RiZ3NdZvXijEB6rIXtRG2tD1WpHHIvgyZTUNlmhfMC2fmdEtipQ0whTxRmceEGIRcX9FuhQsPEIOG3auUsxk8MPseyQ5rhJvSSN4DrM9ORwyw6OfI0pFwguxBFXOUHfsTvA999GQwfFizv3r3ZF9vV0_h2MqOMfqV_OktRNQEgMQftPev098_7mpjqY5uNsZ26AV1hKdKsEf_aiG3Pue2RxVJE_qv3Z89Z75dh7t2AycQr9l635k5DINyNuRHtGzJA34gx7CtXEl9pY-cJmmqcCIy8RsGpne0ImgQ2RoXkDfV0Ulo9r5CNw1wYwSlxbGp0_5E-sNlh5bDV7Sz1OyNHRl8ebM3DShQrE0nXuj4uLQjj6H0on7R_h127s3ZFijFQggyS6Bsx1XOw==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnIRUtaRdAzvgziT3ZkDlA7p5VXWMYDodWpHuWSvmHX_qKUtVife9DPz6fqt1skobTSZwcrMgHdB8FNW-CK4NUJio3hvkUefLr6aH0C82iGjK54I8LLY872nS7NwAGiheY0WTj-lttnqLYycdF8QG8ba3IsN8UJjiYEoKS9M9Bds_4qqeIHBKKSnTNKGQHgkCOCyVZTaf9SgcH0O1UJ2H-W7sB4pbIi52PFQWtPqJJYmBkUOAA3F8E55tW2xVV8t60kV2NoMdi2YJhPDGgHeqRjIJdXEChe94PbKxVGNBEUe9CI0DBn1bqJRVozLPEsd_CWXGNvFKQT7anncbnkFo6UA3jTSMNBbYbjEPUD3WLbGtUEylM94jWaBfTTQfnzmumzDN7i1MUljGKyBL_iFgf8uhh2l5-OepVENNiboX62gfyhFmJTtwquwGCg8wZB4-CrpYqcKInOjEhSlUVOfWRcwqRD01Cs0VtGXkUOw7B8ixe0GV4IcmbgTpeyanXT5cK-X9a7PWo5mpOtAHLiTX-IyB6wxBG9OfRiXYLIvgIwSseYwVdua9-7-zxxmMNkhqsHvvXyrmSNwLI2uC7VnzgU62J0_WpCYIiBFAfXekSm478k7XCy7brCJxdS13pmttvQyjSYSBVnCuMSPXxe9dp_RL_bsXBf4RcoYFQs3p5Boh4Pnuv5pB2HdOxNrkNcUs2gSrs45MSfKndBtVezy5VRt1zziCx-vMoWn13fnQYqOmFzc5I214awX43ILHAFPhMHhdFOoFl_3jPMil1_3PahT7PcwWCPAG5JKlSeNhHplIhiQVMhMvMLXm4qmFlvEfscK69GQ9RiZ3NdZvXijEB6rIXtRG2tD1WpHHIvgyZTUNlmhfMC2fmdEtipQ0whTxRmceEGIRcX9FuhQsPEIOG3auUsxk8MPseyQ5rhJvSSN4DrM9ORwyw6OfI0pFwguxBFXOUHfsTvA999GQwfFizv3r3ZF9vV0_h2MqOMfqV_OktRNQEgMQftPev098_7mpjqY5uNsZ26AV1hKdKsEf_aiG3Pue2RxVJE_qv3Z89Z75dh7t2AycQr9l635k5DINyNuRHtGzJA34gx7CtXEl9pY-cJmmqcCIy8RsGpne0ImgQ2RoXkDfV0Ulo9r5CNw1wYwSlxbGp0_5E-sNlh5bDV7Sz1OyNHRl8ebM3DShQrE0nXuj4uLQjj6H0on7R_h127s3ZFijFQggyS6Bsx1XOw==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1031.17,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDDJVIdek2xZqPX6IBEZ8egfHXyTYisj-WrZYc_l__ZZ2Xs2t8kOuLYknzmRtqBTH57bedfqmnjU-dVAMdkvboDqEOUoa3DGq4GT8ijUToVMUl4pmuEyldoohsilzfTG024v9xDh5Mr0YwZF89Xnjy8FhrFD0FaMktbyySFvrfEyHUekYbEKpHopZRhcN3T-kqN-hjHrggsm9jqmANbvhI89eyL4SIXoQbQfyzYMUh7cg5zEkvhu6kBiYiEwoV0sOOxi8_EqKlhhqzA4hPMSiK2L8qniJr3p5vAng05YkHrN8a6Jw6-Gwv1wumkqIQPOO0QnZsulQObS26niE0Vj3Z-XFbd1OM1DHLAhpwdVpYCpFBi4SQmS1HsOgy5FLt6MzM8-rht7wLUGFXzYeAlw7l68QDC99BxueFgIj9uMY1uATOaX15WJUup_wNqi0T3TCbXDkxZDf7uz499sivOFAyAqm3gaK6ioFKHyRj2mGWLpnWsIEOIxDOi-4CUBn3XUNkHFMrHtE9ZXxcojuXCh4fBOmkMEQ2ARnBW7RqAlHt6aiyABsPe5UZXBUI_qRPmmoPVEfJjkuHO2bjyhgy00FtZDOiUtAN3wCc3HiMrTIRd2OpvRkWtEDJ0-AzepAbRmjyThW-5q5xwHVSmYzOWXsGMiOFnrnp-FVz59g9F66_iSbL9el8Mo7DLpA6LP6Q6gVgEPmAzJx7mlvBSmPKr8hcVTDcSRN5Y-XMMPGhBNNuT3HX6-mrOiuNyiWoahHJ6LVeDw2d0rkT5JzkzytfBBuGwOuVuY54eMO8No0Rfqt4L0bDUt6R2e37S6WKtC6hEtdYWUik0tFrnpSJ76QFRZEnU5p7RL_dU7Y8DL4qHW8gGwGTsyLGaGuf_rOiYtFzN7zhGixpbq3gOOdOwA4xAa8K5xdY9BxOapIf0XFjXBWsqSMK8nWgCyNYEU-KHwri6Aqd5DLOSYFdICw-yViamU3CAaL5nkyAgqt_-8qm_yT7YogVYwDrWTOVLYAY_rv74v9CiESihgpzMMV--tTA7-nSX-tEesO2yMUE9mOMrkOak31sma8lHvRFJ48jOvoLQ6OHeC5QZ3P6y14vYKU4z6FgmvOECr0tHeE-idC7egFa2kMCnaixnAIewixPlI-PBJS7jzhhcVlWEuftDLt60WmyvPfYJWqCqxieqtgfjGQ5_Sw_OSP7ePSykw2gRTYd_uO_yx6eEhpDC6dGfYGyPTIPar-duTV93pJqjUZZx6C2oA=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDDJVIdek2xZqPX6IBEZ8egfHXyTYisj-WrZYc_l__ZZ2Xs2t8kOuLYknzmRtqBTH57bedfqmnjU-dVAMdkvboDqEOUoa3DGq4GT8ijUToVMUl4pmuEyldoohsilzfTG024v9xDh5Mr0YwZF89Xnjy8FhrFD0FaMktbyySFvrfEyHUekYbEKpHopZRhcN3T-kqN-hjHrggsm9jqmANbvhI89eyL4SIXoQbQfyzYMUh7cg5zEkvhu6kBiYiEwoV0sOOxi8_EqKlhhqzA4hPMSiK2L8qniJr3p5vAng05YkHrN8a6Jw6-Gwv1wumkqIQPOO0QnZsulQObS26niE0Vj3Z-XFbd1OM1DHLAhpwdVpYCpFBi4SQmS1HsOgy5FLt6MzM8-rht7wLUGFXzYeAlw7l68QDC99BxueFgIj9uMY1uATOaX15WJUup_wNqi0T3TCbXDkxZDf7uz499sivOFAyAqm3gaK6ioFKHyRj2mGWLpnWsIEOIxDOi-4CUBn3XUNkHFMrHtE9ZXxcojuXCh4fBOmkMEQ2ARnBW7RqAlHt6aiyABsPe5UZXBUI_qRPmmoPVEfJjkuHO2bjyhgy00FtZDOiUtAN3wCc3HiMrTIRd2OpvRkWtEDJ0-AzepAbRmjyThW-5q5xwHVSmYzOWXsGMiOFnrnp-FVz59g9F66_iSbL9el8Mo7DLpA6LP6Q6gVgEPmAzJx7mlvBSmPKr8hcVTDcSRN5Y-XMMPGhBNNuT3HX6-mrOiuNyiWoahHJ6LVeDw2d0rkT5JzkzytfBBuGwOuVuY54eMO8No0Rfqt4L0bDUt6R2e37S6WKtC6hEtdYWUik0tFrnpSJ76QFRZEnU5p7RL_dU7Y8DL4qHW8gGwGTsyLGaGuf_rOiYtFzN7zhGixpbq3gOOdOwA4xAa8K5xdY9BxOapIf0XFjXBWsqSMK8nWgCyNYEU-KHwri6Aqd5DLOSYFdICw-yViamU3CAaL5nkyAgqt_-8qm_yT7YogVYwDrWTOVLYAY_rv74v9CiESihgpzMMV--tTA7-nSX-tEesO2yMUE9mOMrkOak31sma8lHvRFJ48jOvoLQ6OHeC5QZ3P6y14vYKU4z6FgmvOECr0tHeE-idC7egFa2kMCnaixnAIewixPlI-PBJS7jzhhcVlWEuftDLt60WmyvPfYJWqCqxieqtgfjGQ5_Sw_OSP7ePSykw2gRTYd_uO_yx6eEhpDC6dGfYGyPTIPar-duTV93pJqjUZZx6C2oA=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "08:41",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDDJVIdek2xZqPX6IBEZ8egfHXyTYisj-WrZYc_l__ZZ2Xs2t8kOuLYknzmRtqBTH57bedfqmnjU-dVAMdkvboDqEOUoa3DGq4GT8ijUToVMUl4pmuEyldoohsilzfTG024v9xDh5Mr0YwZF89Xnjy8FhrFD0FaMktbyySFvrfEyHUekYbEKpHopZRhcN3T-kqN-hjHrggsm9jqmANbvhI89eyL4SIXoQbQfyzYMUh7cg5zEkvhu6kBiYiEwoV0sOOxi8_EqKlhhqzA4hPMSiK2L8qniJr3p5vAng05YkHrN8a6Jw6-Gwv1wumkqIQPOO0QnZsulQObS26niE0Vj3Z-XFbd1OM1DHLAhpwdVpYCpFBi4SQmS1HsOgy5FLt6MzM8-rht7wLUGFXzYeAlw7l68QDC99BxueFgIj9uMY1uATOaX15WJUup_wNqi0T3TCbXDkxZDf7uz499sivOFAyAqm3gaK6ioFKHyRj2mGWLpnWsIEOIxDOi-4CUBn3XUNkHFMrHtE9ZXxcojuXCh4fBOmkMEQ2ARnBW7RqAlHt6aiyABsPe5UZXBUI_qRPmmoPVEfJjkuHO2bjyhgy00FtZDOiUtAN3wCc3HiMrTIRd2OpvRkWtEDJ0-AzepAbRmjyThW-5q5xwHVSmYzOWXsGMiOFnrnp-FVz59g9F66_iSbL9el8Mo7DLpA6LP6Q6gVgEPmAzJx7mlvBSmPKr8hcVTDcSRN5Y-XMMPGhBNNuT3HX6-mrOiuNyiWoahHJ6LVeDw2d0rkT5JzkzytfBBuGwOuVuY54eMO8No0Rfqt4L0bDUt6R2e37S6WKtC6hEtdYWUik0tFrnpSJ76QFRZEnU5p7RL_dU7Y8DL4qHW8gGwGTsyLGaGuf_rOiYtFzN7zhGixpbq3gOOdOwA4xAa8K5xdY9BxOapIf0XFjXBWsqSMK8nWgCyNYEU-KHwri6Aqd5DLOSYFdICw-yViamU3CAaL5nkyAgqt_-8qm_yT7YogVYwDrWTOVLYAY_rv74v9CiESihgpzMMV--tTA7-nSX-tEesO2yMUE9mOMrkOak31sma8lHvRFJ48jOvoLQ6OHeC5QZ3P6y14vYKU4z6FgmvOECr0tHeE-idC7egFa2kMCnaixnAIewixPlI-PBJS7jzhhcVlWEuftDLt60WmyvPfYJWqCqxieqtgfjGQ5_Sw_OSP7ePSykw2gRTYd_uO_yx6eEhpDC6dGfYGyPTIPar-duTV93pJqjUZZx6C2oA=",
                "dateChange": 2,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "43h51m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e25000002ceb7c8",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2518,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e25000002ceb7c8_0",
                    "local_arrival": "2024-10-09T08:41:00.000Z",
                    "local_departure": "2024-10-09T07:13:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2518",
                    "return": 0,
                    "utc_arrival": "2024-10-09T15:41:00.000Z",
                    "utc_departure": "2024-10-09T14:13:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1105.12,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7ZsDlUD_nSl3Lrm6EP1AM57EA5qe8EH4-cN0iSRwuYnJME0q5TaY9WsLTcJb4UFSgoVwnesBcOhrXBefWo5i4d9i01yuvHwtJfNfgOjYi1c9Oh_S1f0YWxhyKwRpT7AAHR2F-QjHJPRxZkVUA5jqu4FJi5-HmQVP4ZhKIVIqfHJada0QeLhDW0wEWe-6XlL7rS667BuQLQhANm0_Z-afCPTrayjTQTHSlDSWv9gKiWDO5VH3Z8ypeDaqctX_UMI-der023iUdWV3trKJY5TIFPrkvxy7Qgu0lvWqA9czSnrKgtbl88YwuYwfxRZICdbnioUxvSoVc1N-Q-WE46cUM7pRsd4Gb4X_HxihQY_hOyL2RG6o9m_n6xONx_nchQ3XuHjLRnmli3GNdfoCX1idUF2PpknA5aACEAl33Im6DtuD5zQ8040_Z-nGOKd-QbAzmhbwovOxOZRp6IYagCmgY1JBYpsmJYDWERJPReLNdVYz_04T-uns9KYPeIYy7QrXVXehKgEtEQmhMmRx3-lNzZnyWjivCiLvX9EtoMgQpXAm0lDCJmQzm-3cqZFSzPi7q2nHLkOquG6bf_ymuQnI_zkQ8D3j7mddTKAs_x_6MHSgSOUW0puiU0zip9dUkORz9Tt8uC7uS2j-EZu7QsmqQHkvUue-HGu2OZygJI3R4TXdOVqB4QstATcNTZfaK1YrX2ylUqCjDQYsviEPxNlyI3rhJphwu2WF55lfqWGW10liKnoWEgF3xBXgQWyk3MYT-kcWFfXQLTfrU73dj73s1U4RkEbm8LYwkZxbDej7lbD9h4PBaQ7StKSLJjJPoYmbReQKwCYr-5H7ccIQn06uEgavNJN6b1s_aOIs90w7QKKKprVZvVhF474RKpBE9pH4tNAJqW92dLh5-3z7WV6JhFRbNdb2De7sxbfD9hvDrXoS09LjZXR3YPlWUmN7avbtbi26MEUQ459HKOG-txgaqFmD8EVv8Am--DQl0RT3uJ9M6nm2r6vgktIZXeB409Np76tEmvGx6ebzb4NaqnVqC8UIxrn1mH6CdYwEA8N--sXBQj7LCk5X06UakHD69FqP7Jjp_TJlxyTrDsBH-WfB0DSGw1AFzDQh9xJFk6zgE2h5bVsPu0LtXhhC1Nm4DUQIuLf7weg1eFQcnoeGYei3oFoeo__UumxLKHvPsGRRB7evYcRSxWhc4XB1JHFabz8EXNwfe1ToEJFg46RMtsczos-BVYDE2FSSllyH5js7K9Hm1pGZOh9KCmjZOVgn1az1",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7ZsDlUD_nSl3Lrm6EP1AM57EA5qe8EH4-cN0iSRwuYnJME0q5TaY9WsLTcJb4UFSgoVwnesBcOhrXBefWo5i4d9i01yuvHwtJfNfgOjYi1c9Oh_S1f0YWxhyKwRpT7AAHR2F-QjHJPRxZkVUA5jqu4FJi5-HmQVP4ZhKIVIqfHJada0QeLhDW0wEWe-6XlL7rS667BuQLQhANm0_Z-afCPTrayjTQTHSlDSWv9gKiWDO5VH3Z8ypeDaqctX_UMI-der023iUdWV3trKJY5TIFPrkvxy7Qgu0lvWqA9czSnrKgtbl88YwuYwfxRZICdbnioUxvSoVc1N-Q-WE46cUM7pRsd4Gb4X_HxihQY_hOyL2RG6o9m_n6xONx_nchQ3XuHjLRnmli3GNdfoCX1idUF2PpknA5aACEAl33Im6DtuD5zQ8040_Z-nGOKd-QbAzmhbwovOxOZRp6IYagCmgY1JBYpsmJYDWERJPReLNdVYz_04T-uns9KYPeIYy7QrXVXehKgEtEQmhMmRx3-lNzZnyWjivCiLvX9EtoMgQpXAm0lDCJmQzm-3cqZFSzPi7q2nHLkOquG6bf_ymuQnI_zkQ8D3j7mddTKAs_x_6MHSgSOUW0puiU0zip9dUkORz9Tt8uC7uS2j-EZu7QsmqQHkvUue-HGu2OZygJI3R4TXdOVqB4QstATcNTZfaK1YrX2ylUqCjDQYsviEPxNlyI3rhJphwu2WF55lfqWGW10liKnoWEgF3xBXgQWyk3MYT-kcWFfXQLTfrU73dj73s1U4RkEbm8LYwkZxbDej7lbD9h4PBaQ7StKSLJjJPoYmbReQKwCYr-5H7ccIQn06uEgavNJN6b1s_aOIs90w7QKKKprVZvVhF474RKpBE9pH4tNAJqW92dLh5-3z7WV6JhFRbNdb2De7sxbfD9hvDrXoS09LjZXR3YPlWUmN7avbtbi26MEUQ459HKOG-txgaqFmD8EVv8Am--DQl0RT3uJ9M6nm2r6vgktIZXeB409Np76tEmvGx6ebzb4NaqnVqC8UIxrn1mH6CdYwEA8N--sXBQj7LCk5X06UakHD69FqP7Jjp_TJlxyTrDsBH-WfB0DSGw1AFzDQh9xJFk6zgE2h5bVsPu0LtXhhC1Nm4DUQIuLf7weg1eFQcnoeGYei3oFoeo__UumxLKHvPsGRRB7evYcRSxWhc4XB1JHFabz8EXNwfe1ToEJFg46RMtsczos-BVYDE2FSSllyH5js7K9Hm1pGZOh9KCmjZOVgn1az1",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "08:41",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7ZsDlUD_nSl3Lrm6EP1AM57EA5qe8EH4-cN0iSRwuYnJME0q5TaY9WsLTcJb4UFSgoVwnesBcOhrXBefWo5i4d9i01yuvHwtJfNfgOjYi1c9Oh_S1f0YWxhyKwRpT7AAHR2F-QjHJPRxZkVUA5jqu4FJi5-HmQVP4ZhKIVIqfHJada0QeLhDW0wEWe-6XlL7rS667BuQLQhANm0_Z-afCPTrayjTQTHSlDSWv9gKiWDO5VH3Z8ypeDaqctX_UMI-der023iUdWV3trKJY5TIFPrkvxy7Qgu0lvWqA9czSnrKgtbl88YwuYwfxRZICdbnioUxvSoVc1N-Q-WE46cUM7pRsd4Gb4X_HxihQY_hOyL2RG6o9m_n6xONx_nchQ3XuHjLRnmli3GNdfoCX1idUF2PpknA5aACEAl33Im6DtuD5zQ8040_Z-nGOKd-QbAzmhbwovOxOZRp6IYagCmgY1JBYpsmJYDWERJPReLNdVYz_04T-uns9KYPeIYy7QrXVXehKgEtEQmhMmRx3-lNzZnyWjivCiLvX9EtoMgQpXAm0lDCJmQzm-3cqZFSzPi7q2nHLkOquG6bf_ymuQnI_zkQ8D3j7mddTKAs_x_6MHSgSOUW0puiU0zip9dUkORz9Tt8uC7uS2j-EZu7QsmqQHkvUue-HGu2OZygJI3R4TXdOVqB4QstATcNTZfaK1YrX2ylUqCjDQYsviEPxNlyI3rhJphwu2WF55lfqWGW10liKnoWEgF3xBXgQWyk3MYT-kcWFfXQLTfrU73dj73s1U4RkEbm8LYwkZxbDej7lbD9h4PBaQ7StKSLJjJPoYmbReQKwCYr-5H7ccIQn06uEgavNJN6b1s_aOIs90w7QKKKprVZvVhF474RKpBE9pH4tNAJqW92dLh5-3z7WV6JhFRbNdb2De7sxbfD9hvDrXoS09LjZXR3YPlWUmN7avbtbi26MEUQ459HKOG-txgaqFmD8EVv8Am--DQl0RT3uJ9M6nm2r6vgktIZXeB409Np76tEmvGx6ebzb4NaqnVqC8UIxrn1mH6CdYwEA8N--sXBQj7LCk5X06UakHD69FqP7Jjp_TJlxyTrDsBH-WfB0DSGw1AFzDQh9xJFk6zgE2h5bVsPu0LtXhhC1Nm4DUQIuLf7weg1eFQcnoeGYei3oFoeo__UumxLKHvPsGRRB7evYcRSxWhc4XB1JHFabz8EXNwfe1ToEJFg46RMtsczos-BVYDE2FSSllyH5js7K9Hm1pGZOh9KCmjZOVgn1az1",
                "dateChange": 2,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "43h51m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e25000002ceb7c8",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2518,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e25000002ceb7c8_0",
                    "local_arrival": "2024-10-09T08:41:00.000Z",
                    "local_departure": "2024-10-09T07:13:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2518",
                    "return": 0,
                    "utc_arrival": "2024-10-09T15:41:00.000Z",
                    "utc_departure": "2024-10-09T14:13:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1105.12,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHFDCj2OgjQ14t2vN_oj5hLvULcxU8-Xo3gewpI7aUyByLnG3la8lsQRxP9eWwhG7PP2Qu53m2uVx2Ym4twZM44E8MsDqFeBOLrsuMsAZmyqC1e3HcKeyVOgWHYfoR0hwII3eXK56ufA15QTWGhKzAXjq1gHMHKt4D3kb2TmOQAyjkBF8d-67kqoGe2_UNfyanlxlartwZXhuOolUR2wJy9KRtabViCZkiiq-d4yiR2n21j4Q4Bc1X9ENjs3PqNNlBFV_SC1LucyVbsMl7trMQV_BDpIc8T-WidOvzaCPP81m1GF1ynLdOH4DKfdmGtiA7Gi8miwosbwHHsKCFOLpLRn-EaMTlAq8wHNLF6TnZF54Bqk2dHnGAAspr3hDoh0EHiZp1tGTjbxWd5JbMJKDUm6lPSKiUZ0z-8JQtBnzzaiSta9P6C1njo_utjxVTvtdVW3_0KrG-5mEoVZL2UJKKW-LNuuFixsaJHHVBmxoQvSqiiaQ9n4FOUAr2c2OsuEh_Nmg9C46bGZ6PBXx7x_G3a76DCmwjwtd47u6LG5YDzPrXowy9YBnyFTArAU7byfB_nMKlD50VFY6IdJnhcaNgtwEO5b-61TNjntYRL7u8eOJ9Fy-alUCeEJJEWm8f1K9ktqJxmHrnh5RL97UGFzTL5fmMNeOD5cerApEL-LG5JL_rRTgOcW8fJ0Zk0mGTFNeCqpo7FwFae3lJWa16cYQW0YvOLDj8QpLwYqUC9blMVQPnrW1ySu1YPwpcfxrQOgvO55pbiKHNvgPXQfUzLufwLqOcX3VohouF82jmqNnQFfVzpZQLCZiLw-GyIkcpPpcHY4mVoUVT2s3XQqSSwrzGZJlJPxF-7m_fPSSC4yUnA_fDPDwuz6PT4LY3cgCryiE6f-UqO7uJm7xHs4bPl55mRQM1HDDcDJWRW7lWcoNoU3myXCwwvi_aYSTuT_kmrCzxUBpBpMj41wjkyU0nRRnPRV3EZ_n_oCAwugeGznZowzbc0xk9nGv6RiXG0jy0ab5I4O0bJrnAy6fIzfa5DVxTWNvDhhXxEAF3R9x9UyK0HmZlr-7kS2BuLqeOLvXVOmuS06qG1lLDLN-0GAaBK-jz3j6bSwhDud1isO114exL46YHRce_EKNqPadvuUalbr1bvJNj9iBk0cJAW8EDTEhpzTMmZyQvMYsjJh-aDseWmCgUtxhGt274LaYRl3PrC3Lrz3IldxoQJ9yYxy4AJKkEinUo1N3rrlslxDels2p69819rdjG08AB0qqS4ROscqv",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHFDCj2OgjQ14t2vN_oj5hLvULcxU8-Xo3gewpI7aUyByLnG3la8lsQRxP9eWwhG7PP2Qu53m2uVx2Ym4twZM44E8MsDqFeBOLrsuMsAZmyqC1e3HcKeyVOgWHYfoR0hwII3eXK56ufA15QTWGhKzAXjq1gHMHKt4D3kb2TmOQAyjkBF8d-67kqoGe2_UNfyanlxlartwZXhuOolUR2wJy9KRtabViCZkiiq-d4yiR2n21j4Q4Bc1X9ENjs3PqNNlBFV_SC1LucyVbsMl7trMQV_BDpIc8T-WidOvzaCPP81m1GF1ynLdOH4DKfdmGtiA7Gi8miwosbwHHsKCFOLpLRn-EaMTlAq8wHNLF6TnZF54Bqk2dHnGAAspr3hDoh0EHiZp1tGTjbxWd5JbMJKDUm6lPSKiUZ0z-8JQtBnzzaiSta9P6C1njo_utjxVTvtdVW3_0KrG-5mEoVZL2UJKKW-LNuuFixsaJHHVBmxoQvSqiiaQ9n4FOUAr2c2OsuEh_Nmg9C46bGZ6PBXx7x_G3a76DCmwjwtd47u6LG5YDzPrXowy9YBnyFTArAU7byfB_nMKlD50VFY6IdJnhcaNgtwEO5b-61TNjntYRL7u8eOJ9Fy-alUCeEJJEWm8f1K9ktqJxmHrnh5RL97UGFzTL5fmMNeOD5cerApEL-LG5JL_rRTgOcW8fJ0Zk0mGTFNeCqpo7FwFae3lJWa16cYQW0YvOLDj8QpLwYqUC9blMVQPnrW1ySu1YPwpcfxrQOgvO55pbiKHNvgPXQfUzLufwLqOcX3VohouF82jmqNnQFfVzpZQLCZiLw-GyIkcpPpcHY4mVoUVT2s3XQqSSwrzGZJlJPxF-7m_fPSSC4yUnA_fDPDwuz6PT4LY3cgCryiE6f-UqO7uJm7xHs4bPl55mRQM1HDDcDJWRW7lWcoNoU3myXCwwvi_aYSTuT_kmrCzxUBpBpMj41wjkyU0nRRnPRV3EZ_n_oCAwugeGznZowzbc0xk9nGv6RiXG0jy0ab5I4O0bJrnAy6fIzfa5DVxTWNvDhhXxEAF3R9x9UyK0HmZlr-7kS2BuLqeOLvXVOmuS06qG1lLDLN-0GAaBK-jz3j6bSwhDud1isO114exL46YHRce_EKNqPadvuUalbr1bvJNj9iBk0cJAW8EDTEhpzTMmZyQvMYsjJh-aDseWmCgUtxhGt274LaYRl3PrC3Lrz3IldxoQJ9yYxy4AJKkEinUo1N3rrlslxDels2p69819rdjG08AB0qqS4ROscqv",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "08:41",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHFDCj2OgjQ14t2vN_oj5hLvULcxU8-Xo3gewpI7aUyByLnG3la8lsQRxP9eWwhG7PP2Qu53m2uVx2Ym4twZM44E8MsDqFeBOLrsuMsAZmyqC1e3HcKeyVOgWHYfoR0hwII3eXK56ufA15QTWGhKzAXjq1gHMHKt4D3kb2TmOQAyjkBF8d-67kqoGe2_UNfyanlxlartwZXhuOolUR2wJy9KRtabViCZkiiq-d4yiR2n21j4Q4Bc1X9ENjs3PqNNlBFV_SC1LucyVbsMl7trMQV_BDpIc8T-WidOvzaCPP81m1GF1ynLdOH4DKfdmGtiA7Gi8miwosbwHHsKCFOLpLRn-EaMTlAq8wHNLF6TnZF54Bqk2dHnGAAspr3hDoh0EHiZp1tGTjbxWd5JbMJKDUm6lPSKiUZ0z-8JQtBnzzaiSta9P6C1njo_utjxVTvtdVW3_0KrG-5mEoVZL2UJKKW-LNuuFixsaJHHVBmxoQvSqiiaQ9n4FOUAr2c2OsuEh_Nmg9C46bGZ6PBXx7x_G3a76DCmwjwtd47u6LG5YDzPrXowy9YBnyFTArAU7byfB_nMKlD50VFY6IdJnhcaNgtwEO5b-61TNjntYRL7u8eOJ9Fy-alUCeEJJEWm8f1K9ktqJxmHrnh5RL97UGFzTL5fmMNeOD5cerApEL-LG5JL_rRTgOcW8fJ0Zk0mGTFNeCqpo7FwFae3lJWa16cYQW0YvOLDj8QpLwYqUC9blMVQPnrW1ySu1YPwpcfxrQOgvO55pbiKHNvgPXQfUzLufwLqOcX3VohouF82jmqNnQFfVzpZQLCZiLw-GyIkcpPpcHY4mVoUVT2s3XQqSSwrzGZJlJPxF-7m_fPSSC4yUnA_fDPDwuz6PT4LY3cgCryiE6f-UqO7uJm7xHs4bPl55mRQM1HDDcDJWRW7lWcoNoU3myXCwwvi_aYSTuT_kmrCzxUBpBpMj41wjkyU0nRRnPRV3EZ_n_oCAwugeGznZowzbc0xk9nGv6RiXG0jy0ab5I4O0bJrnAy6fIzfa5DVxTWNvDhhXxEAF3R9x9UyK0HmZlr-7kS2BuLqeOLvXVOmuS06qG1lLDLN-0GAaBK-jz3j6bSwhDud1isO114exL46YHRce_EKNqPadvuUalbr1bvJNj9iBk0cJAW8EDTEhpzTMmZyQvMYsjJh-aDseWmCgUtxhGt274LaYRl3PrC3Lrz3IldxoQJ9yYxy4AJKkEinUo1N3rrlslxDels2p69819rdjG08AB0qqS4ROscqv",
                "dateChange": 2,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "43h51m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e25000002ceb7c8",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2518,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e25000002ceb7c8_0",
                    "local_arrival": "2024-10-09T08:41:00.000Z",
                    "local_departure": "2024-10-09T07:13:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2518",
                    "return": 0,
                    "utc_arrival": "2024-10-09T15:41:00.000Z",
                    "utc_departure": "2024-10-09T14:13:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1105.79,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 779,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gb_ig6fPfj8UY4c_NjdE-WZh16McFK_UJFlvERztecCkLRZE_Z5wHXlfvjtU90804wYHHBHNZ4iWsSeu3p-jtG2dhWPUtnnjUG7SneAMseuv9Gu1Lmf3tU1tG3SdtXY5RSH4DWiSFGjjRidxCAjqwAdUAhWRJvJXHBAhwXEOEv-Gk5fshhOtXg_ktHuPP3-j8NuDS16gKH9dE1wE6R8xCpzQFSYnRC3qsU6DZi2z2v_cKAQ8fxrNsxrRDc5L6IRwEOhmSXV6NDkJdYEO8giNuhsNrZIAExp1BrZ9r7QWw8MGM4uxsWEN0-Yc2X3K_B26heakTtbm02obePkZ_y1rv22EkOBcKDM6XqmJLPDWQgxm3nMWis3VYuZNLZOOmDFEIn_Y-uPZwswV6PYEsC72CVQHIDzHyqOf8YGcacmQl9apsTEDFO39C6nylWE2n5yR_WDIQkNNxvCrcpxgrh0LEJ72R1MyurFqI5rWEXqHCWdmO0btv_M-eUaest4SX2_fnsiVi2_HXt4Xj3gW9aEgx6xFLlkgn3pGxMwAIs3dUu1Z9xVOQbEQPYMISpeCpKmJGJJRPk1puo6Eywe-HhWu_OSRNVWk5vdn5E-PvYZ3nUoB5O6V5Meka_Yf9VTGwoAaVdHxn7Cj5XAX1dMtCYdOGOQQwjEPU7BKML7mxYe8NmktgcBk7zu5AGfutw1tbM5ORXABZa7453gwiraKcwdJSbPKqY0LL_c3MoJyXyt5oXcMp8IKgshku7itZiJCv1lcFzJWKAKhyK0HxMYrhQ4EqVhQaBVshsGSJXBOwPbvpBYEroeISCZ9DjkrQOtIr_5nSXZXO-1Xp94rMHfLheRuRhNevm5_KU0Sw1V0X5SP09qxz7WIMf7oW9b02YDewio5KuO3yEQGVJwvBE7G-YE3fvMCIAqugvJ4R5cc6PvUTIN7k8yxc6wO9z_eAGrjzx_Ou22e4eCO4oWCiZc4_wyn4iCwS03UYRFg_BgY4NveDcMB4NiJcnJo63yeB44fWNiI-9y3hhOb5Uxa6M_9z-B1KVtlVGjAQeAR6QgPjwrbC5KxPRJrtmg7pJL1WaCounKztQMuprkpCCngBQ-fiK2_Kx3dsrbqpCTmy15x8wyEdxRMQWQQO0p4qVuJGPNy9ikZaQGNf2J5AlrHQRqt3Fvlqgaxgx3yVXYfFps3bZtvpwsPcJKr628BlRlddLp7xov8VDOqeaCMb9uqtWFQGlmVIbz5eforY4Y5k9pURHlWsnXM=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gb_ig6fPfj8UY4c_NjdE-WZh16McFK_UJFlvERztecCkLRZE_Z5wHXlfvjtU90804wYHHBHNZ4iWsSeu3p-jtG2dhWPUtnnjUG7SneAMseuv9Gu1Lmf3tU1tG3SdtXY5RSH4DWiSFGjjRidxCAjqwAdUAhWRJvJXHBAhwXEOEv-Gk5fshhOtXg_ktHuPP3-j8NuDS16gKH9dE1wE6R8xCpzQFSYnRC3qsU6DZi2z2v_cKAQ8fxrNsxrRDc5L6IRwEOhmSXV6NDkJdYEO8giNuhsNrZIAExp1BrZ9r7QWw8MGM4uxsWEN0-Yc2X3K_B26heakTtbm02obePkZ_y1rv22EkOBcKDM6XqmJLPDWQgxm3nMWis3VYuZNLZOOmDFEIn_Y-uPZwswV6PYEsC72CVQHIDzHyqOf8YGcacmQl9apsTEDFO39C6nylWE2n5yR_WDIQkNNxvCrcpxgrh0LEJ72R1MyurFqI5rWEXqHCWdmO0btv_M-eUaest4SX2_fnsiVi2_HXt4Xj3gW9aEgx6xFLlkgn3pGxMwAIs3dUu1Z9xVOQbEQPYMISpeCpKmJGJJRPk1puo6Eywe-HhWu_OSRNVWk5vdn5E-PvYZ3nUoB5O6V5Meka_Yf9VTGwoAaVdHxn7Cj5XAX1dMtCYdOGOQQwjEPU7BKML7mxYe8NmktgcBk7zu5AGfutw1tbM5ORXABZa7453gwiraKcwdJSbPKqY0LL_c3MoJyXyt5oXcMp8IKgshku7itZiJCv1lcFzJWKAKhyK0HxMYrhQ4EqVhQaBVshsGSJXBOwPbvpBYEroeISCZ9DjkrQOtIr_5nSXZXO-1Xp94rMHfLheRuRhNevm5_KU0Sw1V0X5SP09qxz7WIMf7oW9b02YDewio5KuO3yEQGVJwvBE7G-YE3fvMCIAqugvJ4R5cc6PvUTIN7k8yxc6wO9z_eAGrjzx_Ou22e4eCO4oWCiZc4_wyn4iCwS03UYRFg_BgY4NveDcMB4NiJcnJo63yeB44fWNiI-9y3hhOb5Uxa6M_9z-B1KVtlVGjAQeAR6QgPjwrbC5KxPRJrtmg7pJL1WaCounKztQMuprkpCCngBQ-fiK2_Kx3dsrbqpCTmy15x8wyEdxRMQWQQO0p4qVuJGPNy9ikZaQGNf2J5AlrHQRqt3Fvlqgaxgx3yVXYfFps3bZtvpwsPcJKr628BlRlddLp7xov8VDOqeaCMb9uqtWFQGlmVIbz5eforY4Y5k9pURHlWsnXM=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "08:41",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e25000002ceb7c8_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gb_ig6fPfj8UY4c_NjdE-WZh16McFK_UJFlvERztecCkLRZE_Z5wHXlfvjtU90804wYHHBHNZ4iWsSeu3p-jtG2dhWPUtnnjUG7SneAMseuv9Gu1Lmf3tU1tG3SdtXY5RSH4DWiSFGjjRidxCAjqwAdUAhWRJvJXHBAhwXEOEv-Gk5fshhOtXg_ktHuPP3-j8NuDS16gKH9dE1wE6R8xCpzQFSYnRC3qsU6DZi2z2v_cKAQ8fxrNsxrRDc5L6IRwEOhmSXV6NDkJdYEO8giNuhsNrZIAExp1BrZ9r7QWw8MGM4uxsWEN0-Yc2X3K_B26heakTtbm02obePkZ_y1rv22EkOBcKDM6XqmJLPDWQgxm3nMWis3VYuZNLZOOmDFEIn_Y-uPZwswV6PYEsC72CVQHIDzHyqOf8YGcacmQl9apsTEDFO39C6nylWE2n5yR_WDIQkNNxvCrcpxgrh0LEJ72R1MyurFqI5rWEXqHCWdmO0btv_M-eUaest4SX2_fnsiVi2_HXt4Xj3gW9aEgx6xFLlkgn3pGxMwAIs3dUu1Z9xVOQbEQPYMISpeCpKmJGJJRPk1puo6Eywe-HhWu_OSRNVWk5vdn5E-PvYZ3nUoB5O6V5Meka_Yf9VTGwoAaVdHxn7Cj5XAX1dMtCYdOGOQQwjEPU7BKML7mxYe8NmktgcBk7zu5AGfutw1tbM5ORXABZa7453gwiraKcwdJSbPKqY0LL_c3MoJyXyt5oXcMp8IKgshku7itZiJCv1lcFzJWKAKhyK0HxMYrhQ4EqVhQaBVshsGSJXBOwPbvpBYEroeISCZ9DjkrQOtIr_5nSXZXO-1Xp94rMHfLheRuRhNevm5_KU0Sw1V0X5SP09qxz7WIMf7oW9b02YDewio5KuO3yEQGVJwvBE7G-YE3fvMCIAqugvJ4R5cc6PvUTIN7k8yxc6wO9z_eAGrjzx_Ou22e4eCO4oWCiZc4_wyn4iCwS03UYRFg_BgY4NveDcMB4NiJcnJo63yeB44fWNiI-9y3hhOb5Uxa6M_9z-B1KVtlVGjAQeAR6QgPjwrbC5KxPRJrtmg7pJL1WaCounKztQMuprkpCCngBQ-fiK2_Kx3dsrbqpCTmy15x8wyEdxRMQWQQO0p4qVuJGPNy9ikZaQGNf2J5AlrHQRqt3Fvlqgaxgx3yVXYfFps3bZtvpwsPcJKr628BlRlddLp7xov8VDOqeaCMb9uqtWFQGlmVIbz5eforY4Y5k9pURHlWsnXM=",
                "dateChange": 2,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "43h51m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e25000002ceb7c8",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2518,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e25000002ceb7c8_0",
                    "local_arrival": "2024-10-09T08:41:00.000Z",
                    "local_departure": "2024-10-09T07:13:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2518",
                    "return": 0,
                    "utc_arrival": "2024-10-09T15:41:00.000Z",
                    "utc_departure": "2024-10-09T14:13:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1106.63,
            "totalPrice": 779
          },
          {
            "allPassengerTotalPrice": 784,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GR5nyk_qXmlZlww3OhNewuHfHpaBGG8SiLS8G07pZpW-UEpvEL7-NgG3ejo6PQIuraa1JaWyQxZ8K2ihM07LI0JL0bAOQaXCwL1BbqD44_rFuDTHWUqRzBtFKxKjIlLViDRN7Ser1cuwsi0KvO7utrhgznUCmEBwMDaVee3cNgevNXFEM3L_gA2h1nvPoY3o6cLJGk5rujT3149NNatU7g0gvj-bzbG1SJSxbOmZEal_zg2EpBJlb48UNKlxjolQ-rCNDQkPgqJCfq8FwNCZKwoOVRF73d48Q3_tS4BtQYxWIUIYRLyXMqAcZkMBiNr-E21NNivK2hLWmOLs-LfTK3Xu-BUaFNqpu4T2QpacBdniNHcS8LQKzqLV8VfkzyT-7TVY2RGmwJJ0t3gMG9XOYA3fTB-2SrSXsAPeGUx9SsC_a-c_2fQ7bsg1XgAOAhbeXZRlrcjUAQq-deGXSd7UcM8noxezt6eGUiEwpMmdzdVTMoCKBGdLJbIV7cPNXD0Q3Bh47oAhEDp9SJmqscRDbzp9bEwymDT6P1jFbKTtKu-LJr9VmJF2SxXv-kyiY43czkBVS9_Z7fw_c-qAM3vCVzlHupBQMksJqfU4c7qiBDo1P5_mnGJ2BN1IT70-uUBsNqvQvXYMoD7bpN51n47RMa-k1AjXOVRX8VRsAnWK_hCPnGm1nrnRnaw5oeYkN4_Z0ZSHXBFQ_Ji0kSH_nMLj9WuxIxo0b4bsLiPMiA4oLyyzBN6Rx-g0CLsh_mnxKArK8uBOE8-OtlIRPzjPoEIYoizxGTwaQNJUmfwrOjk2JVidsQUPqlsKgk6H7rseW5NG0rwAk59LDvfvruYyh_IxV3AtBws8i2KFrE9kTTZ0NZVGzIlSQouPU2Ca3iMFztl9Q3kbqP6B0FWMIieKynvk2nmlCdHU1glFiQYtpIep1B_9Pp811P9EHftnFn9TIxCOQGSQXwXxB3mtpwjTKqlCLHRpHxh7cO3fQla5gx3P9in35qCr89PBCc3JeykhgU5v-_4esDZM7jmQ6PGE-tBuyD1yQdCsVbWFdp7yFfdoHAXp8H9e2ut-G1ow0yja07fV3ChHifW-9RDw7g_QVGrDuLC2QJuetLbKI1CmhTjUEtJIRSuY0VmKro_pLnVaq4iQQmZYKFKiHb84K8qqxU6uNzQIiH_5J7HDNbmuE87Aa3noN21FUKIyp1KaEX098vTtCnoTyWZiD1cFXBfOBhX5YWg==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GR5nyk_qXmlZlww3OhNewuHfHpaBGG8SiLS8G07pZpW-UEpvEL7-NgG3ejo6PQIuraa1JaWyQxZ8K2ihM07LI0JL0bAOQaXCwL1BbqD44_rFuDTHWUqRzBtFKxKjIlLViDRN7Ser1cuwsi0KvO7utrhgznUCmEBwMDaVee3cNgevNXFEM3L_gA2h1nvPoY3o6cLJGk5rujT3149NNatU7g0gvj-bzbG1SJSxbOmZEal_zg2EpBJlb48UNKlxjolQ-rCNDQkPgqJCfq8FwNCZKwoOVRF73d48Q3_tS4BtQYxWIUIYRLyXMqAcZkMBiNr-E21NNivK2hLWmOLs-LfTK3Xu-BUaFNqpu4T2QpacBdniNHcS8LQKzqLV8VfkzyT-7TVY2RGmwJJ0t3gMG9XOYA3fTB-2SrSXsAPeGUx9SsC_a-c_2fQ7bsg1XgAOAhbeXZRlrcjUAQq-deGXSd7UcM8noxezt6eGUiEwpMmdzdVTMoCKBGdLJbIV7cPNXD0Q3Bh47oAhEDp9SJmqscRDbzp9bEwymDT6P1jFbKTtKu-LJr9VmJF2SxXv-kyiY43czkBVS9_Z7fw_c-qAM3vCVzlHupBQMksJqfU4c7qiBDo1P5_mnGJ2BN1IT70-uUBsNqvQvXYMoD7bpN51n47RMa-k1AjXOVRX8VRsAnWK_hCPnGm1nrnRnaw5oeYkN4_Z0ZSHXBFQ_Ji0kSH_nMLj9WuxIxo0b4bsLiPMiA4oLyyzBN6Rx-g0CLsh_mnxKArK8uBOE8-OtlIRPzjPoEIYoizxGTwaQNJUmfwrOjk2JVidsQUPqlsKgk6H7rseW5NG0rwAk59LDvfvruYyh_IxV3AtBws8i2KFrE9kTTZ0NZVGzIlSQouPU2Ca3iMFztl9Q3kbqP6B0FWMIieKynvk2nmlCdHU1glFiQYtpIep1B_9Pp811P9EHftnFn9TIxCOQGSQXwXxB3mtpwjTKqlCLHRpHxh7cO3fQla5gx3P9in35qCr89PBCc3JeykhgU5v-_4esDZM7jmQ6PGE-tBuyD1yQdCsVbWFdp7yFfdoHAXp8H9e2ut-G1ow0yja07fV3ChHifW-9RDw7g_QVGrDuLC2QJuetLbKI1CmhTjUEtJIRSuY0VmKro_pLnVaq4iQQmZYKFKiHb84K8qqxU6uNzQIiH_5J7HDNbmuE87Aa3noN21FUKIyp1KaEX098vTtCnoTyWZiD1cFXBfOBhX5YWg==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GR5nyk_qXmlZlww3OhNewuHfHpaBGG8SiLS8G07pZpW-UEpvEL7-NgG3ejo6PQIuraa1JaWyQxZ8K2ihM07LI0JL0bAOQaXCwL1BbqD44_rFuDTHWUqRzBtFKxKjIlLViDRN7Ser1cuwsi0KvO7utrhgznUCmEBwMDaVee3cNgevNXFEM3L_gA2h1nvPoY3o6cLJGk5rujT3149NNatU7g0gvj-bzbG1SJSxbOmZEal_zg2EpBJlb48UNKlxjolQ-rCNDQkPgqJCfq8FwNCZKwoOVRF73d48Q3_tS4BtQYxWIUIYRLyXMqAcZkMBiNr-E21NNivK2hLWmOLs-LfTK3Xu-BUaFNqpu4T2QpacBdniNHcS8LQKzqLV8VfkzyT-7TVY2RGmwJJ0t3gMG9XOYA3fTB-2SrSXsAPeGUx9SsC_a-c_2fQ7bsg1XgAOAhbeXZRlrcjUAQq-deGXSd7UcM8noxezt6eGUiEwpMmdzdVTMoCKBGdLJbIV7cPNXD0Q3Bh47oAhEDp9SJmqscRDbzp9bEwymDT6P1jFbKTtKu-LJr9VmJF2SxXv-kyiY43czkBVS9_Z7fw_c-qAM3vCVzlHupBQMksJqfU4c7qiBDo1P5_mnGJ2BN1IT70-uUBsNqvQvXYMoD7bpN51n47RMa-k1AjXOVRX8VRsAnWK_hCPnGm1nrnRnaw5oeYkN4_Z0ZSHXBFQ_Ji0kSH_nMLj9WuxIxo0b4bsLiPMiA4oLyyzBN6Rx-g0CLsh_mnxKArK8uBOE8-OtlIRPzjPoEIYoizxGTwaQNJUmfwrOjk2JVidsQUPqlsKgk6H7rseW5NG0rwAk59LDvfvruYyh_IxV3AtBws8i2KFrE9kTTZ0NZVGzIlSQouPU2Ca3iMFztl9Q3kbqP6B0FWMIieKynvk2nmlCdHU1glFiQYtpIep1B_9Pp811P9EHftnFn9TIxCOQGSQXwXxB3mtpwjTKqlCLHRpHxh7cO3fQla5gx3P9in35qCr89PBCc3JeykhgU5v-_4esDZM7jmQ6PGE-tBuyD1yQdCsVbWFdp7yFfdoHAXp8H9e2ut-G1ow0yja07fV3ChHifW-9RDw7g_QVGrDuLC2QJuetLbKI1CmhTjUEtJIRSuY0VmKro_pLnVaq4iQQmZYKFKiHb84K8qqxU6uNzQIiH_5J7HDNbmuE87Aa3noN21FUKIyp1KaEX098vTtCnoTyWZiD1cFXBfOBhX5YWg==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 997.01,
            "totalPrice": 784
          },
          {
            "allPassengerTotalPrice": 784,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Go7BhCE5TortPmqmXVzqqPCaHbkMgvK1RsGUtvhyGgP7QffWNA-VltxcchOv1IVUyYuenz5kUd-ERXUhL039Ymtr8cIoSjmZfOuYB_3iFOexxEoMlCeCZSS9292_btxEHDcwDxnh1CdQukQ6BUbCO_lI58h4fe0T1ZakruHJ33uaiabGpY9Mm_L_uMx6eT6P9qHr-LHRu_mx0xYeLLGHYUNBeRiSO_6E-0th4hjaXyDcZOwgePyPPV2soIJlq8UnIuo4IZUpfn-E6v3iolmKsZU7byWf-r9_sgrwKSMillFsmfQfQdyyKJhDr13ZKMlTaVcIGy7F0JcsdtHZ0Hsh3abGSAClPmRmqTuyi3UWobx12851JLsxXR5cAtcfCkidcxrTuC2m-9wOIIuS85GddoJfnd9Vp6TC1cN90PCAzpzs9LiPtd-viM_UA8L9Q1say97lGEiwQAyZU6j88lUN5DDSfEG-gYFeKrZklrzBH2QHX4LAE5-Vj-nQMu5O987BCbfAZsZ9bc2XxtnxVOQTK04IzrTLHpkEQGHAvpiLGib9rF5fjMiXnk9PjmOTR4zYLBrQ0bUXRWKvzEWS2nKoOz79hZnoH0b6QJy3JDr5HbCMH461FpJyT2Rx4dv_j8pQH5GEr9vm15Umi6F2CX273J0O65JrVlxyawQwYDlXlEJysY6AQotVFIbcBX6e5izEFdnCjG1xF1IeOl8Hj6QXlA1sXL4HMyVXrHvgH41ufQBgdmUmm3gsGsvV1YZEx4GmoVlESehuTeL1OX55WR5TFs45xq9WNHuxQSI4HDp6iiKiH2FkI2D4gUHv5cjFKU9R4uW4bBVN1N56sEBCalrFax_Jdawfeg_rYlsujPilK9A5df3n8smSulQb0Vg2wGTXdspyLomCJTNvQQ-DWwvtM4FYHaNkjsBUhr7AXZFIHLY7q__fECUuM0bbaZ1hL5Jlq_k4odhNesFjW-hhcTegYIGW_wxn0P8i8a3_HtItwMlWGLAntDxmLTg12vjERCAEwkT7RwpCv1-iaYBSJadyu1StJSFq5UhOO2awlscC_1waXFjjxHq6_wt4PviVIrVYf8VUeGOharNUYHPZuGOT9d0eRPKLLf8VYzqlqH6kFpVTYxZhTFutpXMNBiGcs0whAwBJUZbL1RygdynC7hCpph_7jzIVi_35AsHjugw6PQgtYUxE5Izp__6QNMxehmAbUmJg8ECmu-5GeXyTkxdMYcoC8jD9YN93cvy3-dxxNWr1ItWc3NklE1f-s1NOA8YloHZlqeQp3Q-5PJSGdRSwLbQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Go7BhCE5TortPmqmXVzqqPCaHbkMgvK1RsGUtvhyGgP7QffWNA-VltxcchOv1IVUyYuenz5kUd-ERXUhL039Ymtr8cIoSjmZfOuYB_3iFOexxEoMlCeCZSS9292_btxEHDcwDxnh1CdQukQ6BUbCO_lI58h4fe0T1ZakruHJ33uaiabGpY9Mm_L_uMx6eT6P9qHr-LHRu_mx0xYeLLGHYUNBeRiSO_6E-0th4hjaXyDcZOwgePyPPV2soIJlq8UnIuo4IZUpfn-E6v3iolmKsZU7byWf-r9_sgrwKSMillFsmfQfQdyyKJhDr13ZKMlTaVcIGy7F0JcsdtHZ0Hsh3abGSAClPmRmqTuyi3UWobx12851JLsxXR5cAtcfCkidcxrTuC2m-9wOIIuS85GddoJfnd9Vp6TC1cN90PCAzpzs9LiPtd-viM_UA8L9Q1say97lGEiwQAyZU6j88lUN5DDSfEG-gYFeKrZklrzBH2QHX4LAE5-Vj-nQMu5O987BCbfAZsZ9bc2XxtnxVOQTK04IzrTLHpkEQGHAvpiLGib9rF5fjMiXnk9PjmOTR4zYLBrQ0bUXRWKvzEWS2nKoOz79hZnoH0b6QJy3JDr5HbCMH461FpJyT2Rx4dv_j8pQH5GEr9vm15Umi6F2CX273J0O65JrVlxyawQwYDlXlEJysY6AQotVFIbcBX6e5izEFdnCjG1xF1IeOl8Hj6QXlA1sXL4HMyVXrHvgH41ufQBgdmUmm3gsGsvV1YZEx4GmoVlESehuTeL1OX55WR5TFs45xq9WNHuxQSI4HDp6iiKiH2FkI2D4gUHv5cjFKU9R4uW4bBVN1N56sEBCalrFax_Jdawfeg_rYlsujPilK9A5df3n8smSulQb0Vg2wGTXdspyLomCJTNvQQ-DWwvtM4FYHaNkjsBUhr7AXZFIHLY7q__fECUuM0bbaZ1hL5Jlq_k4odhNesFjW-hhcTegYIGW_wxn0P8i8a3_HtItwMlWGLAntDxmLTg12vjERCAEwkT7RwpCv1-iaYBSJadyu1StJSFq5UhOO2awlscC_1waXFjjxHq6_wt4PviVIrVYf8VUeGOharNUYHPZuGOT9d0eRPKLLf8VYzqlqH6kFpVTYxZhTFutpXMNBiGcs0whAwBJUZbL1RygdynC7hCpph_7jzIVi_35AsHjugw6PQgtYUxE5Izp__6QNMxehmAbUmJg8ECmu-5GeXyTkxdMYcoC8jD9YN93cvy3-dxxNWr1ItWc3NklE1f-s1NOA8YloHZlqeQp3Q-5PJSGdRSwLbQ==",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Go7BhCE5TortPmqmXVzqqPCaHbkMgvK1RsGUtvhyGgP7QffWNA-VltxcchOv1IVUyYuenz5kUd-ERXUhL039Ymtr8cIoSjmZfOuYB_3iFOexxEoMlCeCZSS9292_btxEHDcwDxnh1CdQukQ6BUbCO_lI58h4fe0T1ZakruHJ33uaiabGpY9Mm_L_uMx6eT6P9qHr-LHRu_mx0xYeLLGHYUNBeRiSO_6E-0th4hjaXyDcZOwgePyPPV2soIJlq8UnIuo4IZUpfn-E6v3iolmKsZU7byWf-r9_sgrwKSMillFsmfQfQdyyKJhDr13ZKMlTaVcIGy7F0JcsdtHZ0Hsh3abGSAClPmRmqTuyi3UWobx12851JLsxXR5cAtcfCkidcxrTuC2m-9wOIIuS85GddoJfnd9Vp6TC1cN90PCAzpzs9LiPtd-viM_UA8L9Q1say97lGEiwQAyZU6j88lUN5DDSfEG-gYFeKrZklrzBH2QHX4LAE5-Vj-nQMu5O987BCbfAZsZ9bc2XxtnxVOQTK04IzrTLHpkEQGHAvpiLGib9rF5fjMiXnk9PjmOTR4zYLBrQ0bUXRWKvzEWS2nKoOz79hZnoH0b6QJy3JDr5HbCMH461FpJyT2Rx4dv_j8pQH5GEr9vm15Umi6F2CX273J0O65JrVlxyawQwYDlXlEJysY6AQotVFIbcBX6e5izEFdnCjG1xF1IeOl8Hj6QXlA1sXL4HMyVXrHvgH41ufQBgdmUmm3gsGsvV1YZEx4GmoVlESehuTeL1OX55WR5TFs45xq9WNHuxQSI4HDp6iiKiH2FkI2D4gUHv5cjFKU9R4uW4bBVN1N56sEBCalrFax_Jdawfeg_rYlsujPilK9A5df3n8smSulQb0Vg2wGTXdspyLomCJTNvQQ-DWwvtM4FYHaNkjsBUhr7AXZFIHLY7q__fECUuM0bbaZ1hL5Jlq_k4odhNesFjW-hhcTegYIGW_wxn0P8i8a3_HtItwMlWGLAntDxmLTg12vjERCAEwkT7RwpCv1-iaYBSJadyu1StJSFq5UhOO2awlscC_1waXFjjxHq6_wt4PviVIrVYf8VUeGOharNUYHPZuGOT9d0eRPKLLf8VYzqlqH6kFpVTYxZhTFutpXMNBiGcs0whAwBJUZbL1RygdynC7hCpph_7jzIVi_35AsHjugw6PQgtYUxE5Izp__6QNMxehmAbUmJg8ECmu-5GeXyTkxdMYcoC8jD9YN93cvy3-dxxNWr1ItWc3NklE1f-s1NOA8YloHZlqeQp3Q-5PJSGdRSwLbQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1064.22,
            "totalPrice": 784
          },
          {
            "allPassengerTotalPrice": 785,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCnQyQYFhilNMeUPhtp791a6fIhBCNpPHcyEdyHASD1dWKo-BqMfIDPDePWy5Ezwp2h6l3LZI9iF_9VH_znvRoNZAlkw-Ymcg7lPoPxZfSTD__Cl9aJX4Qdr1d9mknoRp95rWwoNmfUHe3p6fFyYP5CNR3KMtpk3A60-HNZ7LZsdSucyaIPQR5Q1HDxXhvOxlanzk-4hQaEf38XgJcAb6v-0L1KEu_staqqh2lGFTRFWAB_qhxAkK3Q5KbgnHUIEqqFx2HDF9RnCraRKqqM5x5-M_Dz8OFn2TevK4raSkd1F-4KCUZwfuMNS05vsZCvonMzpCkoYkP1JzEn2-zePj1z4d-bAH13HUxzckPFUlbM8j0_ma2UUTo0O3CrcoWeTtYWEF2NVNyfHjLkRp1PzPBf1twvf9S7kZ_7f8Fhu64j5aPFCwCkcy2boJpLsz1e1Yt75UERGrYBwPl9FAdFdg66fzo98sFLC20JGBo2huyxyeEFe-EwuLZETWdTvdykuYoYkQkzw89x5jwarn1BlP2yOjM9bvn6fDUQCz_fftFaJycbrH3qcApiNHAV96aLCKJr2vwqSDclpHW_iFgWcxNOqeF601bngRnoFFD1aX9ah8x3zGgW6i9IHbYisDgE7-FA8IgTgwWVNbHgQOosLKM3saMAQhX5NbZqyRYIFNhoIZZnzWRyRw_dUs_vnjyntObGAssxJ66IhpH5p8-9Qa9ZLUUPeR0E87vv6rPbsmRlaHulgOBwWBWlj5uCwLIY4XxJZQWelTwYqORzjdXKH1i7K-A-HJmD1oP_pxp4SD_IO-b6VR67dJZtq7I410mpNh7bMZ4_iOrFqxhOSsAHIeRt9-PRQacD7WqkhJtC1HELLugFc58gtDWijxX5HnZ1AgbfPSHdqaqTFe_v5ozhx3IZAEqwReVwt6r8DYelLXPaCnk90NFTSN7f_8OFAPj6Eujc6CRGG_3YmjTE0DCdydJvXxqOQgh1Z5_dbSlALQTZd8tNVKceKTPyC8ICIANR1qqmFUX9-umwMtrZzCZWBkILeBCcKrjGzm9NhRsT_bzGhDP6coA3X87VDh55yW0mHlgV5a2-oqujvc3jBx3UQi6zHPLMLVacnCiBsbVy2gKAs8iyhWYGQ16TCNh7CZUqMt0pHVYsoIPyzqyQ5oVSSBoBq1Feu5p7NjP4CtPD44MBYgTHFP8mfu7Sklzr3DJT6t",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCnQyQYFhilNMeUPhtp791a6fIhBCNpPHcyEdyHASD1dWKo-BqMfIDPDePWy5Ezwp2h6l3LZI9iF_9VH_znvRoNZAlkw-Ymcg7lPoPxZfSTD__Cl9aJX4Qdr1d9mknoRp95rWwoNmfUHe3p6fFyYP5CNR3KMtpk3A60-HNZ7LZsdSucyaIPQR5Q1HDxXhvOxlanzk-4hQaEf38XgJcAb6v-0L1KEu_staqqh2lGFTRFWAB_qhxAkK3Q5KbgnHUIEqqFx2HDF9RnCraRKqqM5x5-M_Dz8OFn2TevK4raSkd1F-4KCUZwfuMNS05vsZCvonMzpCkoYkP1JzEn2-zePj1z4d-bAH13HUxzckPFUlbM8j0_ma2UUTo0O3CrcoWeTtYWEF2NVNyfHjLkRp1PzPBf1twvf9S7kZ_7f8Fhu64j5aPFCwCkcy2boJpLsz1e1Yt75UERGrYBwPl9FAdFdg66fzo98sFLC20JGBo2huyxyeEFe-EwuLZETWdTvdykuYoYkQkzw89x5jwarn1BlP2yOjM9bvn6fDUQCz_fftFaJycbrH3qcApiNHAV96aLCKJr2vwqSDclpHW_iFgWcxNOqeF601bngRnoFFD1aX9ah8x3zGgW6i9IHbYisDgE7-FA8IgTgwWVNbHgQOosLKM3saMAQhX5NbZqyRYIFNhoIZZnzWRyRw_dUs_vnjyntObGAssxJ66IhpH5p8-9Qa9ZLUUPeR0E87vv6rPbsmRlaHulgOBwWBWlj5uCwLIY4XxJZQWelTwYqORzjdXKH1i7K-A-HJmD1oP_pxp4SD_IO-b6VR67dJZtq7I410mpNh7bMZ4_iOrFqxhOSsAHIeRt9-PRQacD7WqkhJtC1HELLugFc58gtDWijxX5HnZ1AgbfPSHdqaqTFe_v5ozhx3IZAEqwReVwt6r8DYelLXPaCnk90NFTSN7f_8OFAPj6Eujc6CRGG_3YmjTE0DCdydJvXxqOQgh1Z5_dbSlALQTZd8tNVKceKTPyC8ICIANR1qqmFUX9-umwMtrZzCZWBkILeBCcKrjGzm9NhRsT_bzGhDP6coA3X87VDh55yW0mHlgV5a2-oqujvc3jBx3UQi6zHPLMLVacnCiBsbVy2gKAs8iyhWYGQ16TCNh7CZUqMt0pHVYsoIPyzqyQ5oVSSBoBq1Feu5p7NjP4CtPD44MBYgTHFP8mfu7Sklzr3DJT6t",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCnQyQYFhilNMeUPhtp791a6fIhBCNpPHcyEdyHASD1dWKo-BqMfIDPDePWy5Ezwp2h6l3LZI9iF_9VH_znvRoNZAlkw-Ymcg7lPoPxZfSTD__Cl9aJX4Qdr1d9mknoRp95rWwoNmfUHe3p6fFyYP5CNR3KMtpk3A60-HNZ7LZsdSucyaIPQR5Q1HDxXhvOxlanzk-4hQaEf38XgJcAb6v-0L1KEu_staqqh2lGFTRFWAB_qhxAkK3Q5KbgnHUIEqqFx2HDF9RnCraRKqqM5x5-M_Dz8OFn2TevK4raSkd1F-4KCUZwfuMNS05vsZCvonMzpCkoYkP1JzEn2-zePj1z4d-bAH13HUxzckPFUlbM8j0_ma2UUTo0O3CrcoWeTtYWEF2NVNyfHjLkRp1PzPBf1twvf9S7kZ_7f8Fhu64j5aPFCwCkcy2boJpLsz1e1Yt75UERGrYBwPl9FAdFdg66fzo98sFLC20JGBo2huyxyeEFe-EwuLZETWdTvdykuYoYkQkzw89x5jwarn1BlP2yOjM9bvn6fDUQCz_fftFaJycbrH3qcApiNHAV96aLCKJr2vwqSDclpHW_iFgWcxNOqeF601bngRnoFFD1aX9ah8x3zGgW6i9IHbYisDgE7-FA8IgTgwWVNbHgQOosLKM3saMAQhX5NbZqyRYIFNhoIZZnzWRyRw_dUs_vnjyntObGAssxJ66IhpH5p8-9Qa9ZLUUPeR0E87vv6rPbsmRlaHulgOBwWBWlj5uCwLIY4XxJZQWelTwYqORzjdXKH1i7K-A-HJmD1oP_pxp4SD_IO-b6VR67dJZtq7I410mpNh7bMZ4_iOrFqxhOSsAHIeRt9-PRQacD7WqkhJtC1HELLugFc58gtDWijxX5HnZ1AgbfPSHdqaqTFe_v5ozhx3IZAEqwReVwt6r8DYelLXPaCnk90NFTSN7f_8OFAPj6Eujc6CRGG_3YmjTE0DCdydJvXxqOQgh1Z5_dbSlALQTZd8tNVKceKTPyC8ICIANR1qqmFUX9-umwMtrZzCZWBkILeBCcKrjGzm9NhRsT_bzGhDP6coA3X87VDh55yW0mHlgV5a2-oqujvc3jBx3UQi6zHPLMLVacnCiBsbVy2gKAs8iyhWYGQ16TCNh7CZUqMt0pHVYsoIPyzqyQ5oVSSBoBq1Feu5p7NjP4CtPD44MBYgTHFP8mfu7Sklzr3DJT6t",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 998.42,
            "totalPrice": 785
          },
          {
            "allPassengerTotalPrice": 786,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTjptdP41QC5fkjdfrszG6GBy-AfTLur9VT1HI6mJFpZwbJpVUuOeYE_rM7kP5S41N9Y_onmzBFHGf2Qp-THpyk_yognlDQe0FGa160T8IYUDGYZVB4AzBbseaa8IY1k9cE39qv0PMILFpqRkWWysYCQJknL8S0YwVw8fCxv6gCA7A5vkvf0ydm90pjo2eAqXlnY4c2HyFllS7txaxl8vO3omdnpwEIzwvszS-xC6LX154ijj2BaFFPiqq9gf6cyHFE1bf311xefVwzmEf4bvG4JBpqX-Vou4iJWzJmmV0w96vGXkpPqiDo2XZtPTuqxNW9y5ZlPZl14JrODVuClwigdOExEmOI-3e1srZAKmRWCadqQOJmEbYBlO6je-FEcGEArj1WY9qakkB7EKVwxPZ-e2I85ykURfzoXjUpVmOd-4dfpUQCGz2N60pn0OQ3xTNbt4be-nQQD5kT23BXwccInP0RFimpJa2OeDm4rbJxo-rRx4u9BJV5UeoHRLdp4DAjMYZW9RvmpaqaksypC8ELFRIVwTQU3q9ipfFGBZvAySAhcyyfw5YJQprq4_GtUMsVrjSNCYRMNFPxit3JtgBqYPw4c5PYQUJSs2-CKLlHIsb03G0R33XUwI_29wxf1R8bhqCAyjj0RPGEYFbtbntZTRr_LJT8mroz1gks1okmdDZLmey24eIzcWSpNJw-iyIeGB8LypQvxsQEeuPj0lh-I6hguCIQvap78NWl9tg00DjTRiSypiYsZdPeF9RuNdrVa9fi1Wnq1hCp3Lb1gkxi9I9skp-8Mpmqv9Z1B8AlE83Tr054pLXISltv2StBqUHVFibzmOjygdM2mDvV1sYGn8NMnVfv2iJmyE6j9hOskW3ftYZqAM4mhrHnUCML93l9G7BvXLl7mUfONcYUzrXnV-Or10rDZgrDfmOTeGoS2QIfLIen0wqzviU35jjPhSpnwp9-__bz-E3v60-q4UWlTgN5QQ7SuOYnPQMMZbe804yhsMSJ2W2z0Hqf6IxgC1n0jWQBphBIe7ejEPzP2DoD4Ap4zoDkLDPpKuzPsGlw7O-NbkoKXzcMU-Aa5BBJp18wlCnJWRnyNWQPrnxmBNORJGs3uwoQgGVQcPvFM3AwK46wzhUAhcyxsSNeHD3sle5C10pE8mAgn0xgA72w1imOYE8L8ZXwwWyeu96dMouXol3pvBsYAxTcFyAEQIRV8jMLA3R-0izkNO64dduYQrgkstH1X2UioU3cnmzuSx5cc=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTjptdP41QC5fkjdfrszG6GBy-AfTLur9VT1HI6mJFpZwbJpVUuOeYE_rM7kP5S41N9Y_onmzBFHGf2Qp-THpyk_yognlDQe0FGa160T8IYUDGYZVB4AzBbseaa8IY1k9cE39qv0PMILFpqRkWWysYCQJknL8S0YwVw8fCxv6gCA7A5vkvf0ydm90pjo2eAqXlnY4c2HyFllS7txaxl8vO3omdnpwEIzwvszS-xC6LX154ijj2BaFFPiqq9gf6cyHFE1bf311xefVwzmEf4bvG4JBpqX-Vou4iJWzJmmV0w96vGXkpPqiDo2XZtPTuqxNW9y5ZlPZl14JrODVuClwigdOExEmOI-3e1srZAKmRWCadqQOJmEbYBlO6je-FEcGEArj1WY9qakkB7EKVwxPZ-e2I85ykURfzoXjUpVmOd-4dfpUQCGz2N60pn0OQ3xTNbt4be-nQQD5kT23BXwccInP0RFimpJa2OeDm4rbJxo-rRx4u9BJV5UeoHRLdp4DAjMYZW9RvmpaqaksypC8ELFRIVwTQU3q9ipfFGBZvAySAhcyyfw5YJQprq4_GtUMsVrjSNCYRMNFPxit3JtgBqYPw4c5PYQUJSs2-CKLlHIsb03G0R33XUwI_29wxf1R8bhqCAyjj0RPGEYFbtbntZTRr_LJT8mroz1gks1okmdDZLmey24eIzcWSpNJw-iyIeGB8LypQvxsQEeuPj0lh-I6hguCIQvap78NWl9tg00DjTRiSypiYsZdPeF9RuNdrVa9fi1Wnq1hCp3Lb1gkxi9I9skp-8Mpmqv9Z1B8AlE83Tr054pLXISltv2StBqUHVFibzmOjygdM2mDvV1sYGn8NMnVfv2iJmyE6j9hOskW3ftYZqAM4mhrHnUCML93l9G7BvXLl7mUfONcYUzrXnV-Or10rDZgrDfmOTeGoS2QIfLIen0wqzviU35jjPhSpnwp9-__bz-E3v60-q4UWlTgN5QQ7SuOYnPQMMZbe804yhsMSJ2W2z0Hqf6IxgC1n0jWQBphBIe7ejEPzP2DoD4Ap4zoDkLDPpKuzPsGlw7O-NbkoKXzcMU-Aa5BBJp18wlCnJWRnyNWQPrnxmBNORJGs3uwoQgGVQcPvFM3AwK46wzhUAhcyxsSNeHD3sle5C10pE8mAgn0xgA72w1imOYE8L8ZXwwWyeu96dMouXol3pvBsYAxTcFyAEQIRV8jMLA3R-0izkNO64dduYQrgkstH1X2UioU3cnmzuSx5cc=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTjptdP41QC5fkjdfrszG6GBy-AfTLur9VT1HI6mJFpZwbJpVUuOeYE_rM7kP5S41N9Y_onmzBFHGf2Qp-THpyk_yognlDQe0FGa160T8IYUDGYZVB4AzBbseaa8IY1k9cE39qv0PMILFpqRkWWysYCQJknL8S0YwVw8fCxv6gCA7A5vkvf0ydm90pjo2eAqXlnY4c2HyFllS7txaxl8vO3omdnpwEIzwvszS-xC6LX154ijj2BaFFPiqq9gf6cyHFE1bf311xefVwzmEf4bvG4JBpqX-Vou4iJWzJmmV0w96vGXkpPqiDo2XZtPTuqxNW9y5ZlPZl14JrODVuClwigdOExEmOI-3e1srZAKmRWCadqQOJmEbYBlO6je-FEcGEArj1WY9qakkB7EKVwxPZ-e2I85ykURfzoXjUpVmOd-4dfpUQCGz2N60pn0OQ3xTNbt4be-nQQD5kT23BXwccInP0RFimpJa2OeDm4rbJxo-rRx4u9BJV5UeoHRLdp4DAjMYZW9RvmpaqaksypC8ELFRIVwTQU3q9ipfFGBZvAySAhcyyfw5YJQprq4_GtUMsVrjSNCYRMNFPxit3JtgBqYPw4c5PYQUJSs2-CKLlHIsb03G0R33XUwI_29wxf1R8bhqCAyjj0RPGEYFbtbntZTRr_LJT8mroz1gks1okmdDZLmey24eIzcWSpNJw-iyIeGB8LypQvxsQEeuPj0lh-I6hguCIQvap78NWl9tg00DjTRiSypiYsZdPeF9RuNdrVa9fi1Wnq1hCp3Lb1gkxi9I9skp-8Mpmqv9Z1B8AlE83Tr054pLXISltv2StBqUHVFibzmOjygdM2mDvV1sYGn8NMnVfv2iJmyE6j9hOskW3ftYZqAM4mhrHnUCML93l9G7BvXLl7mUfONcYUzrXnV-Or10rDZgrDfmOTeGoS2QIfLIen0wqzviU35jjPhSpnwp9-__bz-E3v60-q4UWlTgN5QQ7SuOYnPQMMZbe804yhsMSJ2W2z0Hqf6IxgC1n0jWQBphBIe7ejEPzP2DoD4Ap4zoDkLDPpKuzPsGlw7O-NbkoKXzcMU-Aa5BBJp18wlCnJWRnyNWQPrnxmBNORJGs3uwoQgGVQcPvFM3AwK46wzhUAhcyxsSNeHD3sle5C10pE8mAgn0xgA72w1imOYE8L8ZXwwWyeu96dMouXol3pvBsYAxTcFyAEQIRV8jMLA3R-0izkNO64dduYQrgkstH1X2UioU3cnmzuSx5cc=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1014.14,
            "totalPrice": 786
          },
          {
            "allPassengerTotalPrice": 786,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-td2vn1YeeE5lgmFgCjQ2iIeO7O4iOLXErrTQMsX4YwMsaSQIYJFFhJS8SmfvaRuIBFysyIDqdHZupYsNueWJG5pYyMJZHnAgGuVEHyBlboH8nl3Axwent72UIvTJIKydiYGut0MHDGS9iwluAXVucuVrmYyxbdPXVVFVAfcZHmCsn5dQtQJ45Z3e7PZs8xUnRsHuDi8MNgoIs8WoXVa5SiMLjqCto4QqVGva1wJ_aSzNpkhq2FBjoXS54gsmNQbVAmKhBAQKdeggGNhimGkufyTTT1_EYvnBlxkQog6MFJ7JmBwmCzgMs8BM5m78LDeMGiugFqtItSSmF97mR66lR5BnpQO5RYjRgytC8nwdmlUeJvmFn5rwuQYQhYzuXVnt0bGn4aeKwh37sYFSjLxPwpLZcADQiLrpbvtgR-W9xsd84WFjnacMe_UM7X7ez846fp-3cxG0mD5JSluoB_HGeIdSXjR6P6HfX4AVcXtGUCmByeNnLqHRcjIdX5lTkoFcYpzqzU6-zVX_LYkrHA1WkDZli3RN7qBcdUgRmo3XlO8wOfwPCP5yJlvUM8ipbj_UO552QOuu_q4QlfZF2OBG97T1eCyh0Wai-pMbe76lQy3hx6So-gTXopdL93d1o0IkDheU6PCWR9p4MWsy7zMDktkpUvnGWMZLQQrl8NVfFKLCNRPNuiI0ZZlEAqIILvSfUBYWkv8YTsHSWE1X2xGWHewwKn6lg_eOFbN3zSxu69m1fOzsuFTtpxkv170sOIc82WRjs9Ud-bw5tWdDOF7lmsWFaqrJ2MnmVKuEgoWvx2AqLdfP3BMehJNfyenRQWBUddRXJjXi38LCgK3cuZ5bnNhZf_7svYtQ4npKqF5RsRuqKocNJn5876GlACMDlbH37AaHkBHCO_7_AHrOYyOiXuWkaVe2R0M85EUfiOSgSP2potxfMTqQULhpLZYIt2KqLTBxfyTq3dTOw3fPig2Kk4pykIZt9i2ITzdfHHvSsQLVgEzysFBwHYcbqSbk94A0AywoguF_uJvQ3ruqB_QZ2DwMgBy7wyLrYgd6l0xlIszZEKtf86KloUxoZmP5mEZPBx0jo2s0aAkOGzwLvu09mBDU97be3iwXV9UWCQe3SCrRSwV9Itgr0aIFaaJV03XXxwK_kgRUXH-wqbRWAMX-pUEZUT69a-vlXqmP0-8IO89UbzpoZsnWcfebkDmt9G8eGaibNdp29oKjMmkfRM4Xq00SRZdZSBzc73Lfn1-Hsw=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-td2vn1YeeE5lgmFgCjQ2iIeO7O4iOLXErrTQMsX4YwMsaSQIYJFFhJS8SmfvaRuIBFysyIDqdHZupYsNueWJG5pYyMJZHnAgGuVEHyBlboH8nl3Axwent72UIvTJIKydiYGut0MHDGS9iwluAXVucuVrmYyxbdPXVVFVAfcZHmCsn5dQtQJ45Z3e7PZs8xUnRsHuDi8MNgoIs8WoXVa5SiMLjqCto4QqVGva1wJ_aSzNpkhq2FBjoXS54gsmNQbVAmKhBAQKdeggGNhimGkufyTTT1_EYvnBlxkQog6MFJ7JmBwmCzgMs8BM5m78LDeMGiugFqtItSSmF97mR66lR5BnpQO5RYjRgytC8nwdmlUeJvmFn5rwuQYQhYzuXVnt0bGn4aeKwh37sYFSjLxPwpLZcADQiLrpbvtgR-W9xsd84WFjnacMe_UM7X7ez846fp-3cxG0mD5JSluoB_HGeIdSXjR6P6HfX4AVcXtGUCmByeNnLqHRcjIdX5lTkoFcYpzqzU6-zVX_LYkrHA1WkDZli3RN7qBcdUgRmo3XlO8wOfwPCP5yJlvUM8ipbj_UO552QOuu_q4QlfZF2OBG97T1eCyh0Wai-pMbe76lQy3hx6So-gTXopdL93d1o0IkDheU6PCWR9p4MWsy7zMDktkpUvnGWMZLQQrl8NVfFKLCNRPNuiI0ZZlEAqIILvSfUBYWkv8YTsHSWE1X2xGWHewwKn6lg_eOFbN3zSxu69m1fOzsuFTtpxkv170sOIc82WRjs9Ud-bw5tWdDOF7lmsWFaqrJ2MnmVKuEgoWvx2AqLdfP3BMehJNfyenRQWBUddRXJjXi38LCgK3cuZ5bnNhZf_7svYtQ4npKqF5RsRuqKocNJn5876GlACMDlbH37AaHkBHCO_7_AHrOYyOiXuWkaVe2R0M85EUfiOSgSP2potxfMTqQULhpLZYIt2KqLTBxfyTq3dTOw3fPig2Kk4pykIZt9i2ITzdfHHvSsQLVgEzysFBwHYcbqSbk94A0AywoguF_uJvQ3ruqB_QZ2DwMgBy7wyLrYgd6l0xlIszZEKtf86KloUxoZmP5mEZPBx0jo2s0aAkOGzwLvu09mBDU97be3iwXV9UWCQe3SCrRSwV9Itgr0aIFaaJV03XXxwK_kgRUXH-wqbRWAMX-pUEZUT69a-vlXqmP0-8IO89UbzpoZsnWcfebkDmt9G8eGaibNdp29oKjMmkfRM4Xq00SRZdZSBzc73Lfn1-Hsw=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-td2vn1YeeE5lgmFgCjQ2iIeO7O4iOLXErrTQMsX4YwMsaSQIYJFFhJS8SmfvaRuIBFysyIDqdHZupYsNueWJG5pYyMJZHnAgGuVEHyBlboH8nl3Axwent72UIvTJIKydiYGut0MHDGS9iwluAXVucuVrmYyxbdPXVVFVAfcZHmCsn5dQtQJ45Z3e7PZs8xUnRsHuDi8MNgoIs8WoXVa5SiMLjqCto4QqVGva1wJ_aSzNpkhq2FBjoXS54gsmNQbVAmKhBAQKdeggGNhimGkufyTTT1_EYvnBlxkQog6MFJ7JmBwmCzgMs8BM5m78LDeMGiugFqtItSSmF97mR66lR5BnpQO5RYjRgytC8nwdmlUeJvmFn5rwuQYQhYzuXVnt0bGn4aeKwh37sYFSjLxPwpLZcADQiLrpbvtgR-W9xsd84WFjnacMe_UM7X7ez846fp-3cxG0mD5JSluoB_HGeIdSXjR6P6HfX4AVcXtGUCmByeNnLqHRcjIdX5lTkoFcYpzqzU6-zVX_LYkrHA1WkDZli3RN7qBcdUgRmo3XlO8wOfwPCP5yJlvUM8ipbj_UO552QOuu_q4QlfZF2OBG97T1eCyh0Wai-pMbe76lQy3hx6So-gTXopdL93d1o0IkDheU6PCWR9p4MWsy7zMDktkpUvnGWMZLQQrl8NVfFKLCNRPNuiI0ZZlEAqIILvSfUBYWkv8YTsHSWE1X2xGWHewwKn6lg_eOFbN3zSxu69m1fOzsuFTtpxkv170sOIc82WRjs9Ud-bw5tWdDOF7lmsWFaqrJ2MnmVKuEgoWvx2AqLdfP3BMehJNfyenRQWBUddRXJjXi38LCgK3cuZ5bnNhZf_7svYtQ4npKqF5RsRuqKocNJn5876GlACMDlbH37AaHkBHCO_7_AHrOYyOiXuWkaVe2R0M85EUfiOSgSP2potxfMTqQULhpLZYIt2KqLTBxfyTq3dTOw3fPig2Kk4pykIZt9i2ITzdfHHvSsQLVgEzysFBwHYcbqSbk94A0AywoguF_uJvQ3ruqB_QZ2DwMgBy7wyLrYgd6l0xlIszZEKtf86KloUxoZmP5mEZPBx0jo2s0aAkOGzwLvu09mBDU97be3iwXV9UWCQe3SCrRSwV9Itgr0aIFaaJV03XXxwK_kgRUXH-wqbRWAMX-pUEZUT69a-vlXqmP0-8IO89UbzpoZsnWcfebkDmt9G8eGaibNdp29oKjMmkfRM4Xq00SRZdZSBzc73Lfn1-Hsw=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1014.14,
            "totalPrice": 786
          },
          {
            "allPassengerTotalPrice": 786,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjD8Z9pwdnMpXeZKPwsBTBQBdBeuY1OwHLUnlUJZQw4jd_dT3c_5GCGCVxr1QxODKq2GXNmbbo4xjbSSpa65EW-E6Aubu__D0yobsPPtZNV76fPrwTPjiOHvEKw4DC8GUu5ICdgy0aLvT1WjAWuutDK5vgvtQA3s1AuGeGeiKbtvj_fZY02I9_g3xLobFUnljpzHgHQucX9nGQRqlxVSp7a9KLHkCNER_h2mB6yecYc4Ehf0JfGIJ0uM-XSTkN9FafUnK34TmMq9__FkCu-fojquwRRQspb5v81EUA_IlEd7VA5BnU1mV_qFo-mpdaVkWTZgSiuUJAIxLOagjXgJ-Li8zModiLKbOVS0kKz7xQe_vTh3C_muTQljW_jjkq0PJ5ZL2Pji0aiCnbxg6wC9ZYXojS9M0I4OLNeBbdmB83qplnENixezYff1CKMHP6sSrEvTRpdkIgq-2EsEtirqRS4r-ubbgt97DhVm7XPfkikcBRhZMr9qSZn8tAMDVpKFpW9IlT1NZ_vFtpRaP-tm9FsAanhbr3HSCzcoiSzvamp_0QP0PoiKOua-7EBJ-VOqXgmbkBHIKYDhsempoLtttbQSdDocgIlgIroyJsJ68CIUD7v0UbcwJtm8te1WfFPdJu5pTpfuoRuQmsgAwzpWf6uG8MoRXYgaqtA8nhLakLxU_H-GUVxVJLcHwAHNEIhuN3QbL1x-d6E4l0kZaNRylIvfFZ15LD1n-2N0mjd9zR-0yJXU3H-8X3xJa-15uBGu8QIrnc0RQZL0dYmXWkTWit1vGo6DH5DQ77WWb-tfQiJ2g9EaODYbqaKx1nGM4Ys2QFwGDjG68DZMbeZpzE1WQV0wh3XFR7qYP-lVZh1RhBTQPKlB8NF3-JXCmpWH7YGiBP0Wgbn8VFbasWZ7UDsr45lTPn4Dl2ZAMvz90dyGQBTIi_CedLH94HczkXrx64n5O6MZSRFKQPeP2GkF2WIiKm032W3_ZAYJLQjZV64kXPGBlFOhgyf2XWc2kSbUU0vP9WosN5lpeP9h28Oz22-Q9sCt-qYjIY4G8jRlJN4r4GCXRnuq_EaRPf7zdmWncwhe6l-WWKwbFYbwAjkbbFbavCI1GhOgR76DkkFHoJRQo3lNh_JtE9nX284HG2G82VWOsLpe7fxp3oERDx26WN-pXZZTSj0A2LZsyt_0NtrTzK3u_1JmYyWoJgPD42TYjwaA20zBxvgTfih8E9bHMNiRsFrO3TABemyO3CDJB7sP2erZTe3d3Sa5R8UtDzRyaXjB2",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjD8Z9pwdnMpXeZKPwsBTBQBdBeuY1OwHLUnlUJZQw4jd_dT3c_5GCGCVxr1QxODKq2GXNmbbo4xjbSSpa65EW-E6Aubu__D0yobsPPtZNV76fPrwTPjiOHvEKw4DC8GUu5ICdgy0aLvT1WjAWuutDK5vgvtQA3s1AuGeGeiKbtvj_fZY02I9_g3xLobFUnljpzHgHQucX9nGQRqlxVSp7a9KLHkCNER_h2mB6yecYc4Ehf0JfGIJ0uM-XSTkN9FafUnK34TmMq9__FkCu-fojquwRRQspb5v81EUA_IlEd7VA5BnU1mV_qFo-mpdaVkWTZgSiuUJAIxLOagjXgJ-Li8zModiLKbOVS0kKz7xQe_vTh3C_muTQljW_jjkq0PJ5ZL2Pji0aiCnbxg6wC9ZYXojS9M0I4OLNeBbdmB83qplnENixezYff1CKMHP6sSrEvTRpdkIgq-2EsEtirqRS4r-ubbgt97DhVm7XPfkikcBRhZMr9qSZn8tAMDVpKFpW9IlT1NZ_vFtpRaP-tm9FsAanhbr3HSCzcoiSzvamp_0QP0PoiKOua-7EBJ-VOqXgmbkBHIKYDhsempoLtttbQSdDocgIlgIroyJsJ68CIUD7v0UbcwJtm8te1WfFPdJu5pTpfuoRuQmsgAwzpWf6uG8MoRXYgaqtA8nhLakLxU_H-GUVxVJLcHwAHNEIhuN3QbL1x-d6E4l0kZaNRylIvfFZ15LD1n-2N0mjd9zR-0yJXU3H-8X3xJa-15uBGu8QIrnc0RQZL0dYmXWkTWit1vGo6DH5DQ77WWb-tfQiJ2g9EaODYbqaKx1nGM4Ys2QFwGDjG68DZMbeZpzE1WQV0wh3XFR7qYP-lVZh1RhBTQPKlB8NF3-JXCmpWH7YGiBP0Wgbn8VFbasWZ7UDsr45lTPn4Dl2ZAMvz90dyGQBTIi_CedLH94HczkXrx64n5O6MZSRFKQPeP2GkF2WIiKm032W3_ZAYJLQjZV64kXPGBlFOhgyf2XWc2kSbUU0vP9WosN5lpeP9h28Oz22-Q9sCt-qYjIY4G8jRlJN4r4GCXRnuq_EaRPf7zdmWncwhe6l-WWKwbFYbwAjkbbFbavCI1GhOgR76DkkFHoJRQo3lNh_JtE9nX284HG2G82VWOsLpe7fxp3oERDx26WN-pXZZTSj0A2LZsyt_0NtrTzK3u_1JmYyWoJgPD42TYjwaA20zBxvgTfih8E9bHMNiRsFrO3TABemyO3CDJB7sP2erZTe3d3Sa5R8UtDzRyaXjB2",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjD8Z9pwdnMpXeZKPwsBTBQBdBeuY1OwHLUnlUJZQw4jd_dT3c_5GCGCVxr1QxODKq2GXNmbbo4xjbSSpa65EW-E6Aubu__D0yobsPPtZNV76fPrwTPjiOHvEKw4DC8GUu5ICdgy0aLvT1WjAWuutDK5vgvtQA3s1AuGeGeiKbtvj_fZY02I9_g3xLobFUnljpzHgHQucX9nGQRqlxVSp7a9KLHkCNER_h2mB6yecYc4Ehf0JfGIJ0uM-XSTkN9FafUnK34TmMq9__FkCu-fojquwRRQspb5v81EUA_IlEd7VA5BnU1mV_qFo-mpdaVkWTZgSiuUJAIxLOagjXgJ-Li8zModiLKbOVS0kKz7xQe_vTh3C_muTQljW_jjkq0PJ5ZL2Pji0aiCnbxg6wC9ZYXojS9M0I4OLNeBbdmB83qplnENixezYff1CKMHP6sSrEvTRpdkIgq-2EsEtirqRS4r-ubbgt97DhVm7XPfkikcBRhZMr9qSZn8tAMDVpKFpW9IlT1NZ_vFtpRaP-tm9FsAanhbr3HSCzcoiSzvamp_0QP0PoiKOua-7EBJ-VOqXgmbkBHIKYDhsempoLtttbQSdDocgIlgIroyJsJ68CIUD7v0UbcwJtm8te1WfFPdJu5pTpfuoRuQmsgAwzpWf6uG8MoRXYgaqtA8nhLakLxU_H-GUVxVJLcHwAHNEIhuN3QbL1x-d6E4l0kZaNRylIvfFZ15LD1n-2N0mjd9zR-0yJXU3H-8X3xJa-15uBGu8QIrnc0RQZL0dYmXWkTWit1vGo6DH5DQ77WWb-tfQiJ2g9EaODYbqaKx1nGM4Ys2QFwGDjG68DZMbeZpzE1WQV0wh3XFR7qYP-lVZh1RhBTQPKlB8NF3-JXCmpWH7YGiBP0Wgbn8VFbasWZ7UDsr45lTPn4Dl2ZAMvz90dyGQBTIi_CedLH94HczkXrx64n5O6MZSRFKQPeP2GkF2WIiKm032W3_ZAYJLQjZV64kXPGBlFOhgyf2XWc2kSbUU0vP9WosN5lpeP9h28Oz22-Q9sCt-qYjIY4G8jRlJN4r4GCXRnuq_EaRPf7zdmWncwhe6l-WWKwbFYbwAjkbbFbavCI1GhOgR76DkkFHoJRQo3lNh_JtE9nX284HG2G82VWOsLpe7fxp3oERDx26WN-pXZZTSj0A2LZsyt_0NtrTzK3u_1JmYyWoJgPD42TYjwaA20zBxvgTfih8E9bHMNiRsFrO3TABemyO3CDJB7sP2erZTe3d3Sa5R8UtDzRyaXjB2",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1014.81,
            "totalPrice": 786
          },
          {
            "allPassengerTotalPrice": 786,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxvZ81AMTrTplFeN27lU4Njj9HNQRz0yhKqVsTs9KqkRuy8tfIfcv5bPTC3kOcuonK2dJruU52_1NHyTS6DSGLQuEFR_COHoR_wgZevOHwqYk1K-oAKcx-HYo1xb7uVk4Njk5QFY6bpLi3nbhrfekH-THJqv30p4HkSoAXDHmcfIpWFKtS24A1hUavDvYtdvb1x8b6RjbIAokwPvWx9f48bNosj1N-YafHCA8Vu6LwV5A8tQAk4TN24dZsQbQ1nOhRtGW0FPnwqPhSKwN1KoQwErGYfxMcRx7J7gmQNEbeNNY-JHDY0xHsWPXyy58fc59jhgNvpOFN7w8nAzp__rm4pHSUtB7ZKM9--bfLAL7EdkDOonWu_1Tz2FMZ-yspLNraPjN5Ey4HlXVCZm_0pi_xWTCBSnGzoP_r9lZLq0MlF2Xa_xrHygsykr2wIQg8PSvZBp5t7675Tsqze3OFbhHTAWJVxR9RFLrVZCCCVGfihSvsVgLOyVQ7lhXu14QX5fNxyj8M-3Fz_xDbS26MbwPhdwURaovcWI9iBzqu_nMGBmlS8-5eeWFQRjZj8c2BGTCObso5UMJElFPGlhRcFizOrdxR1vAh7S1hB0dKo-aIPsP0q20b9m1p-KaKiBRy2gXvYQEzno905u3hNYulSsRb4lR9ncQqBJNzGXR21v1zoIBZnOmuNHu6336a97yq09hWOwWe2WFokkIPJBbNo7SfgeKFc5S4503gRMfdeP8iVxbJCjNtQpiP4a8gqUERw2mwb8fwnWw4uGJGYl0ORrFFMHXdJ21_IMYG__6eUuYFr63CDIucH4HJg2urfuapMWTE69t9t2Ir9tOqLMF8RXbx-4ASjsZNWffXSeENJDhWoGaHRQfrItcXROHsPXEjTnsvSmijOUgAgTl2Qg7X-bmyELzy6123ww4fPbhSU5xVxqSATcG5Tm_de_S5CKZ7yqoRhd9Oar2Gw_Pg-4-0SCJA8WEH6ZvR76JitLv7fBUemhEOjZd9xXNzaXTN34Sc5NXwWVJ2d4ZsrfSj0Vp047Fce0kgZBBtcG9JbZx3WFFEJQlNa4eSKOhOewNLBU0wrvvaDNFecTmVbZ1NW5HoIv2WGFSparcAGkRfXFUT2Xdn5E4kHok0uyudzj_Doc3Kf3I6xo43DLuGS6HJbqyK-YyxJdowaPu1mggCKepEYJL36HTfKH83u5GhFEopHpyg49IVxH7dac6M6u7YJ5yiFv9oQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxvZ81AMTrTplFeN27lU4Njj9HNQRz0yhKqVsTs9KqkRuy8tfIfcv5bPTC3kOcuonK2dJruU52_1NHyTS6DSGLQuEFR_COHoR_wgZevOHwqYk1K-oAKcx-HYo1xb7uVk4Njk5QFY6bpLi3nbhrfekH-THJqv30p4HkSoAXDHmcfIpWFKtS24A1hUavDvYtdvb1x8b6RjbIAokwPvWx9f48bNosj1N-YafHCA8Vu6LwV5A8tQAk4TN24dZsQbQ1nOhRtGW0FPnwqPhSKwN1KoQwErGYfxMcRx7J7gmQNEbeNNY-JHDY0xHsWPXyy58fc59jhgNvpOFN7w8nAzp__rm4pHSUtB7ZKM9--bfLAL7EdkDOonWu_1Tz2FMZ-yspLNraPjN5Ey4HlXVCZm_0pi_xWTCBSnGzoP_r9lZLq0MlF2Xa_xrHygsykr2wIQg8PSvZBp5t7675Tsqze3OFbhHTAWJVxR9RFLrVZCCCVGfihSvsVgLOyVQ7lhXu14QX5fNxyj8M-3Fz_xDbS26MbwPhdwURaovcWI9iBzqu_nMGBmlS8-5eeWFQRjZj8c2BGTCObso5UMJElFPGlhRcFizOrdxR1vAh7S1hB0dKo-aIPsP0q20b9m1p-KaKiBRy2gXvYQEzno905u3hNYulSsRb4lR9ncQqBJNzGXR21v1zoIBZnOmuNHu6336a97yq09hWOwWe2WFokkIPJBbNo7SfgeKFc5S4503gRMfdeP8iVxbJCjNtQpiP4a8gqUERw2mwb8fwnWw4uGJGYl0ORrFFMHXdJ21_IMYG__6eUuYFr63CDIucH4HJg2urfuapMWTE69t9t2Ir9tOqLMF8RXbx-4ASjsZNWffXSeENJDhWoGaHRQfrItcXROHsPXEjTnsvSmijOUgAgTl2Qg7X-bmyELzy6123ww4fPbhSU5xVxqSATcG5Tm_de_S5CKZ7yqoRhd9Oar2Gw_Pg-4-0SCJA8WEH6ZvR76JitLv7fBUemhEOjZd9xXNzaXTN34Sc5NXwWVJ2d4ZsrfSj0Vp047Fce0kgZBBtcG9JbZx3WFFEJQlNa4eSKOhOewNLBU0wrvvaDNFecTmVbZ1NW5HoIv2WGFSparcAGkRfXFUT2Xdn5E4kHok0uyudzj_Doc3Kf3I6xo43DLuGS6HJbqyK-YyxJdowaPu1mggCKepEYJL36HTfKH83u5GhFEopHpyg49IVxH7dac6M6u7YJ5yiFv9oQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxvZ81AMTrTplFeN27lU4Njj9HNQRz0yhKqVsTs9KqkRuy8tfIfcv5bPTC3kOcuonK2dJruU52_1NHyTS6DSGLQuEFR_COHoR_wgZevOHwqYk1K-oAKcx-HYo1xb7uVk4Njk5QFY6bpLi3nbhrfekH-THJqv30p4HkSoAXDHmcfIpWFKtS24A1hUavDvYtdvb1x8b6RjbIAokwPvWx9f48bNosj1N-YafHCA8Vu6LwV5A8tQAk4TN24dZsQbQ1nOhRtGW0FPnwqPhSKwN1KoQwErGYfxMcRx7J7gmQNEbeNNY-JHDY0xHsWPXyy58fc59jhgNvpOFN7w8nAzp__rm4pHSUtB7ZKM9--bfLAL7EdkDOonWu_1Tz2FMZ-yspLNraPjN5Ey4HlXVCZm_0pi_xWTCBSnGzoP_r9lZLq0MlF2Xa_xrHygsykr2wIQg8PSvZBp5t7675Tsqze3OFbhHTAWJVxR9RFLrVZCCCVGfihSvsVgLOyVQ7lhXu14QX5fNxyj8M-3Fz_xDbS26MbwPhdwURaovcWI9iBzqu_nMGBmlS8-5eeWFQRjZj8c2BGTCObso5UMJElFPGlhRcFizOrdxR1vAh7S1hB0dKo-aIPsP0q20b9m1p-KaKiBRy2gXvYQEzno905u3hNYulSsRb4lR9ncQqBJNzGXR21v1zoIBZnOmuNHu6336a97yq09hWOwWe2WFokkIPJBbNo7SfgeKFc5S4503gRMfdeP8iVxbJCjNtQpiP4a8gqUERw2mwb8fwnWw4uGJGYl0ORrFFMHXdJ21_IMYG__6eUuYFr63CDIucH4HJg2urfuapMWTE69t9t2Ir9tOqLMF8RXbx-4ASjsZNWffXSeENJDhWoGaHRQfrItcXROHsPXEjTnsvSmijOUgAgTl2Qg7X-bmyELzy6123ww4fPbhSU5xVxqSATcG5Tm_de_S5CKZ7yqoRhd9Oar2Gw_Pg-4-0SCJA8WEH6ZvR76JitLv7fBUemhEOjZd9xXNzaXTN34Sc5NXwWVJ2d4ZsrfSj0Vp047Fce0kgZBBtcG9JbZx3WFFEJQlNa4eSKOhOewNLBU0wrvvaDNFecTmVbZ1NW5HoIv2WGFSparcAGkRfXFUT2Xdn5E4kHok0uyudzj_Doc3Kf3I6xo43DLuGS6HJbqyK-YyxJdowaPu1mggCKepEYJL36HTfKH83u5GhFEopHpyg49IVxH7dac6M6u7YJ5yiFv9oQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1015.66,
            "totalPrice": 786
          },
          {
            "allPassengerTotalPrice": 786,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhjPNbgphx9BCCnyQlm6SI7XNHwUa559Z1WFjjfYZXQ867pqc5bttLeKY0m_nYOOtOPhcxyQT4nFjxy77rLyhmzPNI2Cey4okUArxjeJzdniHUN6wmP8uClrhFTKQw90FIJN451oKcB8mSszeZxWIbzw1LHoW2hR652WBBVoEnD9xOXtykN1QzuJtloI0AF35jH51QRoURPMWig7xN_bVfCqFW9NkCVMlNVf1MPoxztyeV6wMlqhC1bAQt4vH9BiaV1d4s0OLJMW0zmaabV-5nX4H_xX8l7Z9LapI000OFnVmw5cMqWxu2fRuDOe1z7k_rdx6CSWDZzCa5yKMC1fhmsGm3bI-Ksm3g3oTPAiGfJhmNVVA_jqyBDU1ViFWx5990R4PZ4kRQKkeoD-3MYSOG5nTVd1FYMbBXsvXBQowJkt2C4yyAtBE2r8A-phsT36GKuziZ5ULHru-z1ysI2QjsIhIiacchrwnBtn_WKEF2M9yNDqRo-xP1HcdzXKYtna7Tit9ecyyfjRMUu-yW49f-PDwRjQlVVyGYntociaHOxocgpt6w9_x5Ikx1yleyi8_NJq1GvCVJFAUKLcaWOmnHl2cedQc2nzEgN1laVc-2ZXUl3X6VewJVfMDz6aSz8oLQpG8Ruq7tlKR9TBhITx9kMxa1RB000OkTI3d7CBU7_5MVuTg-kBS2Iw3E5VDQH9bSyfj0JD8zjCMY4yTnIihp6Qr-gDeWQfJqBLXegCpOytT0-R_rLqzKuerntzMBR6BWzrYinc9YRNbpQorLM3-jibZRd43ia7s-gBeDfbnth6lk2fVXPoiLUaHZXNUt-cWdad6213bP-sQYPCigTsMCfTYd_Lq1rmjq2HTmmkoz8F68W13zhtwnthoAQ5mDG12ftGSblb0klYMI2ibqcnKcLPblp7x11zp4uNChZDYOq23K4Xan88WLa_HmIslOjLu6BLfy97ijfm358JdBFo9Dx2wL14ReO6DpYLpDXGHWhny6P4gZJHEFoDXP-nu1QyLD35vDmkdjAEoKJo6uUv2yBbs6_wrvQ6k6CPJRMAaDQdta-H9NqPdOpgcnkt1F77MPmXd1jIgpf8kNjleIn-ghYt4dYFsleup5Hej848gvcpiLWZc-Qeuuj29MfzckxkPNvPci2RtUvAW7l-_HBUTKF6FoERhTTAtr4eqhEKiXKFI_YLiQ4_St9d4yBd4WBGo6URBp8QpAoTmxbrITab9_FwPx16EVd7RzjbV3m3TIUE=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhjPNbgphx9BCCnyQlm6SI7XNHwUa559Z1WFjjfYZXQ867pqc5bttLeKY0m_nYOOtOPhcxyQT4nFjxy77rLyhmzPNI2Cey4okUArxjeJzdniHUN6wmP8uClrhFTKQw90FIJN451oKcB8mSszeZxWIbzw1LHoW2hR652WBBVoEnD9xOXtykN1QzuJtloI0AF35jH51QRoURPMWig7xN_bVfCqFW9NkCVMlNVf1MPoxztyeV6wMlqhC1bAQt4vH9BiaV1d4s0OLJMW0zmaabV-5nX4H_xX8l7Z9LapI000OFnVmw5cMqWxu2fRuDOe1z7k_rdx6CSWDZzCa5yKMC1fhmsGm3bI-Ksm3g3oTPAiGfJhmNVVA_jqyBDU1ViFWx5990R4PZ4kRQKkeoD-3MYSOG5nTVd1FYMbBXsvXBQowJkt2C4yyAtBE2r8A-phsT36GKuziZ5ULHru-z1ysI2QjsIhIiacchrwnBtn_WKEF2M9yNDqRo-xP1HcdzXKYtna7Tit9ecyyfjRMUu-yW49f-PDwRjQlVVyGYntociaHOxocgpt6w9_x5Ikx1yleyi8_NJq1GvCVJFAUKLcaWOmnHl2cedQc2nzEgN1laVc-2ZXUl3X6VewJVfMDz6aSz8oLQpG8Ruq7tlKR9TBhITx9kMxa1RB000OkTI3d7CBU7_5MVuTg-kBS2Iw3E5VDQH9bSyfj0JD8zjCMY4yTnIihp6Qr-gDeWQfJqBLXegCpOytT0-R_rLqzKuerntzMBR6BWzrYinc9YRNbpQorLM3-jibZRd43ia7s-gBeDfbnth6lk2fVXPoiLUaHZXNUt-cWdad6213bP-sQYPCigTsMCfTYd_Lq1rmjq2HTmmkoz8F68W13zhtwnthoAQ5mDG12ftGSblb0klYMI2ibqcnKcLPblp7x11zp4uNChZDYOq23K4Xan88WLa_HmIslOjLu6BLfy97ijfm358JdBFo9Dx2wL14ReO6DpYLpDXGHWhny6P4gZJHEFoDXP-nu1QyLD35vDmkdjAEoKJo6uUv2yBbs6_wrvQ6k6CPJRMAaDQdta-H9NqPdOpgcnkt1F77MPmXd1jIgpf8kNjleIn-ghYt4dYFsleup5Hej848gvcpiLWZc-Qeuuj29MfzckxkPNvPci2RtUvAW7l-_HBUTKF6FoERhTTAtr4eqhEKiXKFI_YLiQ4_St9d4yBd4WBGo6URBp8QpAoTmxbrITab9_FwPx16EVd7RzjbV3m3TIUE=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhjPNbgphx9BCCnyQlm6SI7XNHwUa559Z1WFjjfYZXQ867pqc5bttLeKY0m_nYOOtOPhcxyQT4nFjxy77rLyhmzPNI2Cey4okUArxjeJzdniHUN6wmP8uClrhFTKQw90FIJN451oKcB8mSszeZxWIbzw1LHoW2hR652WBBVoEnD9xOXtykN1QzuJtloI0AF35jH51QRoURPMWig7xN_bVfCqFW9NkCVMlNVf1MPoxztyeV6wMlqhC1bAQt4vH9BiaV1d4s0OLJMW0zmaabV-5nX4H_xX8l7Z9LapI000OFnVmw5cMqWxu2fRuDOe1z7k_rdx6CSWDZzCa5yKMC1fhmsGm3bI-Ksm3g3oTPAiGfJhmNVVA_jqyBDU1ViFWx5990R4PZ4kRQKkeoD-3MYSOG5nTVd1FYMbBXsvXBQowJkt2C4yyAtBE2r8A-phsT36GKuziZ5ULHru-z1ysI2QjsIhIiacchrwnBtn_WKEF2M9yNDqRo-xP1HcdzXKYtna7Tit9ecyyfjRMUu-yW49f-PDwRjQlVVyGYntociaHOxocgpt6w9_x5Ikx1yleyi8_NJq1GvCVJFAUKLcaWOmnHl2cedQc2nzEgN1laVc-2ZXUl3X6VewJVfMDz6aSz8oLQpG8Ruq7tlKR9TBhITx9kMxa1RB000OkTI3d7CBU7_5MVuTg-kBS2Iw3E5VDQH9bSyfj0JD8zjCMY4yTnIihp6Qr-gDeWQfJqBLXegCpOytT0-R_rLqzKuerntzMBR6BWzrYinc9YRNbpQorLM3-jibZRd43ia7s-gBeDfbnth6lk2fVXPoiLUaHZXNUt-cWdad6213bP-sQYPCigTsMCfTYd_Lq1rmjq2HTmmkoz8F68W13zhtwnthoAQ5mDG12ftGSblb0klYMI2ibqcnKcLPblp7x11zp4uNChZDYOq23K4Xan88WLa_HmIslOjLu6BLfy97ijfm358JdBFo9Dx2wL14ReO6DpYLpDXGHWhny6P4gZJHEFoDXP-nu1QyLD35vDmkdjAEoKJo6uUv2yBbs6_wrvQ6k6CPJRMAaDQdta-H9NqPdOpgcnkt1F77MPmXd1jIgpf8kNjleIn-ghYt4dYFsleup5Hej848gvcpiLWZc-Qeuuj29MfzckxkPNvPci2RtUvAW7l-_HBUTKF6FoERhTTAtr4eqhEKiXKFI_YLiQ4_St9d4yBd4WBGo6URBp8QpAoTmxbrITab9_FwPx16EVd7RzjbV3m3TIUE=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1036.1,
            "totalPrice": 786
          },
          {
            "allPassengerTotalPrice": 787,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQoji2uSA0fhQWx5jxGVA4OnAKEXgY6BnGNTP4MwS1gDhSDiwEUViUtJX0icmKusAqpzVg0-FXpIJpXMGAYe9v-RZaPaQeA5kSdl-dKof7hzjdoYPP9UwmAvBjGH_iLWWcUvyqUfDHvOcftKFdv9uI-kRlCSvSWYte1WJvG3MY4ClIs7TxhvEn8WffPQrJM9bPQmOvQKAi1kAeUwhnPmKx_Q8b3P-W2_OhE-u-85jgzD4sZyxk3aD8ebrqffcsIzpPEAPJKew0S8-cH1aZOokaLERYvVnah8jYwcO2CiV3KqvuBL4g0kyLC6qjgpYsn1GgPJTqYnAqGse0QlaQSudW1a4sxKMqKINym3ykhPzGl7BwbZxOE0RvJ5mtP2erFziK3oXLvqeNBEhYVIgjZ_wuT3EbvS8undV1IX0BfnzSCxxx94XG8_eGifL5ZipQoDUVG75ZVeYDOEUPHDQLBAyOgXPKFIqDArlKbZPU2eI5BbuCC7AtbTvwwTkKLVvSKIJdPH4g16BSpkOrd5-DQ4WVvTcALO4mFQrVvGzRDS7OnFmPM0JOclJpEyIn5AXT5JF7gsPudJNC6QyszSiPJnZ5yz4T--TYwzthsIJabhO0sao8-wMPBMOFGQRVk3uZGwx8Pj2lUOIbEllBAD_1afB36Cbo2TJ7BFmSasN8zWQaiWJmx9HcjL-aeXXPTO-Ud4mII-xcyZgUPqb_yXqVhghaw_ram0AvzURk4MVsnHdKH9rHeeWU7Rie1aqLgBd5y9ot6zp_WEfHFQETV-MV7aK0ihB-x1AQQmqMmQSq07_kb_9T6uvktN_kaajLfbG_yGAPkKcQljuvxT25N6whibnJrgV_RRxUDDY7DsjwYVFAdlV3s8lCdErjlQV1m3N46Bjhn6cULBZsO7fJHMoGMCUCaH6YqXSPvB27jdxOjaMTcMaTbs7EhDZyiNwAho8IYvlJtLWrOU5HoFsP6HB9F45fjlItcVE7ImMbAcDLacznUS2Hvh7nx0JvFlVtSU5KD3pXaFhERavu7F01RCHMPiXCnMixAjCeBM9FGmhw22Xo8-fUxaEJHvE4ZteghFMsIMCc8ltWDFYE4snGF7LeINWiK5VKegb5lIMV-xTytx8uqN4miDDASQSMbfG0IKIdw3MbvlwiSMNALpBqvDi02WxZ56iHLzMOFA2a-HiNXBet-4A-uQH6UwcXtLjtpV9TvvNE5p0o0nZbT1ZJ6Z4SyriOIRbOS5_IQuVcarJ12UW7aevnzM2Uj7GLDLPilAkrpCECbqLJyd6FEfVJd-MKgxU1vOP6gE7Mz2U6rxqJjN9_fY=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQoji2uSA0fhQWx5jxGVA4OnAKEXgY6BnGNTP4MwS1gDhSDiwEUViUtJX0icmKusAqpzVg0-FXpIJpXMGAYe9v-RZaPaQeA5kSdl-dKof7hzjdoYPP9UwmAvBjGH_iLWWcUvyqUfDHvOcftKFdv9uI-kRlCSvSWYte1WJvG3MY4ClIs7TxhvEn8WffPQrJM9bPQmOvQKAi1kAeUwhnPmKx_Q8b3P-W2_OhE-u-85jgzD4sZyxk3aD8ebrqffcsIzpPEAPJKew0S8-cH1aZOokaLERYvVnah8jYwcO2CiV3KqvuBL4g0kyLC6qjgpYsn1GgPJTqYnAqGse0QlaQSudW1a4sxKMqKINym3ykhPzGl7BwbZxOE0RvJ5mtP2erFziK3oXLvqeNBEhYVIgjZ_wuT3EbvS8undV1IX0BfnzSCxxx94XG8_eGifL5ZipQoDUVG75ZVeYDOEUPHDQLBAyOgXPKFIqDArlKbZPU2eI5BbuCC7AtbTvwwTkKLVvSKIJdPH4g16BSpkOrd5-DQ4WVvTcALO4mFQrVvGzRDS7OnFmPM0JOclJpEyIn5AXT5JF7gsPudJNC6QyszSiPJnZ5yz4T--TYwzthsIJabhO0sao8-wMPBMOFGQRVk3uZGwx8Pj2lUOIbEllBAD_1afB36Cbo2TJ7BFmSasN8zWQaiWJmx9HcjL-aeXXPTO-Ud4mII-xcyZgUPqb_yXqVhghaw_ram0AvzURk4MVsnHdKH9rHeeWU7Rie1aqLgBd5y9ot6zp_WEfHFQETV-MV7aK0ihB-x1AQQmqMmQSq07_kb_9T6uvktN_kaajLfbG_yGAPkKcQljuvxT25N6whibnJrgV_RRxUDDY7DsjwYVFAdlV3s8lCdErjlQV1m3N46Bjhn6cULBZsO7fJHMoGMCUCaH6YqXSPvB27jdxOjaMTcMaTbs7EhDZyiNwAho8IYvlJtLWrOU5HoFsP6HB9F45fjlItcVE7ImMbAcDLacznUS2Hvh7nx0JvFlVtSU5KD3pXaFhERavu7F01RCHMPiXCnMixAjCeBM9FGmhw22Xo8-fUxaEJHvE4ZteghFMsIMCc8ltWDFYE4snGF7LeINWiK5VKegb5lIMV-xTytx8uqN4miDDASQSMbfG0IKIdw3MbvlwiSMNALpBqvDi02WxZ56iHLzMOFA2a-HiNXBet-4A-uQH6UwcXtLjtpV9TvvNE5p0o0nZbT1ZJ6Z4SyriOIRbOS5_IQuVcarJ12UW7aevnzM2Uj7GLDLPilAkrpCECbqLJyd6FEfVJd-MKgxU1vOP6gE7Mz2U6rxqJjN9_fY=",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQoji2uSA0fhQWx5jxGVA4OnAKEXgY6BnGNTP4MwS1gDhSDiwEUViUtJX0icmKusAqpzVg0-FXpIJpXMGAYe9v-RZaPaQeA5kSdl-dKof7hzjdoYPP9UwmAvBjGH_iLWWcUvyqUfDHvOcftKFdv9uI-kRlCSvSWYte1WJvG3MY4ClIs7TxhvEn8WffPQrJM9bPQmOvQKAi1kAeUwhnPmKx_Q8b3P-W2_OhE-u-85jgzD4sZyxk3aD8ebrqffcsIzpPEAPJKew0S8-cH1aZOokaLERYvVnah8jYwcO2CiV3KqvuBL4g0kyLC6qjgpYsn1GgPJTqYnAqGse0QlaQSudW1a4sxKMqKINym3ykhPzGl7BwbZxOE0RvJ5mtP2erFziK3oXLvqeNBEhYVIgjZ_wuT3EbvS8undV1IX0BfnzSCxxx94XG8_eGifL5ZipQoDUVG75ZVeYDOEUPHDQLBAyOgXPKFIqDArlKbZPU2eI5BbuCC7AtbTvwwTkKLVvSKIJdPH4g16BSpkOrd5-DQ4WVvTcALO4mFQrVvGzRDS7OnFmPM0JOclJpEyIn5AXT5JF7gsPudJNC6QyszSiPJnZ5yz4T--TYwzthsIJabhO0sao8-wMPBMOFGQRVk3uZGwx8Pj2lUOIbEllBAD_1afB36Cbo2TJ7BFmSasN8zWQaiWJmx9HcjL-aeXXPTO-Ud4mII-xcyZgUPqb_yXqVhghaw_ram0AvzURk4MVsnHdKH9rHeeWU7Rie1aqLgBd5y9ot6zp_WEfHFQETV-MV7aK0ihB-x1AQQmqMmQSq07_kb_9T6uvktN_kaajLfbG_yGAPkKcQljuvxT25N6whibnJrgV_RRxUDDY7DsjwYVFAdlV3s8lCdErjlQV1m3N46Bjhn6cULBZsO7fJHMoGMCUCaH6YqXSPvB27jdxOjaMTcMaTbs7EhDZyiNwAho8IYvlJtLWrOU5HoFsP6HB9F45fjlItcVE7ImMbAcDLacznUS2Hvh7nx0JvFlVtSU5KD3pXaFhERavu7F01RCHMPiXCnMixAjCeBM9FGmhw22Xo8-fUxaEJHvE4ZteghFMsIMCc8ltWDFYE4snGF7LeINWiK5VKegb5lIMV-xTytx8uqN4miDDASQSMbfG0IKIdw3MbvlwiSMNALpBqvDi02WxZ56iHLzMOFA2a-HiNXBet-4A-uQH6UwcXtLjtpV9TvvNE5p0o0nZbT1ZJ6Z4SyriOIRbOS5_IQuVcarJ12UW7aevnzM2Uj7GLDLPilAkrpCECbqLJyd6FEfVJd-MKgxU1vOP6gE7Mz2U6rxqJjN9_fY=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1103.32,
            "totalPrice": 787
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYXSnwSBNh5xKKsJ8dugQYClywIlEZAnMX14Hj-KnrnJbNezDCYTSHbSTOjHCT9rgpD2Pq1KsUEj5ULXvztOi_BJ9C-Z5Z4AsoaRQtS75kaQD3TrtBUsbPdev4HPoz6jDlF6nMg_6Vfdl0i4-01A3M8gt5z_e8FVQBl1Yh1D7CAQvVAUIMA2SznB4yJLdINBDs4ssmOHrtkOr8V-qeFPb1aE3gdoLB2xP5YmJaeUhmfQKQtEHf0OZS8FOJ9tEvkMQ0XWx7i0HiM6_Nk-oUGda7BgGf7-3EKMAcKxfE5MEOJ3Cu26ZXWifLts-8a3y-porTjRvtZzAt3GiCVLsn93S2CeuWtzPmWRohbIRvp5vAFRb_CxNYiGEAdYFOz29-0534u5s8ALFTT-WeQt_J_hnQzkPcEGgvpaaAJ5qLegF8XbdgL_d3hmCswPyXJZUIqqJPwUfSwaOD3Nr1jalSJHQ43aJR07gxQnZm-3vLMjZRXDwh1YsMOmjn-9TSSpHinsaG4mk3F5tkLdrxdT0cNeCzoEyy-YX2jacn4H48-2Kp6wjyiaWDZcvN3WvX_5XgDxifAks3hqD8FDF3iDoaRPKEYAYUva22C9ReDp9rZUOatn9tK3ONlFuXlDHM0nGttt6b2rGALbtuimAHU7iRRF-IiQrXw_aw6fMAwPO4JDzTht1zGPbPsbboO0QmeA4lfUXmOry1UUDYMFxqLHDqPox4WXl0OgaKNLoY8ylHDPKsYNbwMUIt0tiC33suTYghKPAag-ibAI5tm64TTyEBLct45qaLoWzX8r4ml-Jh2RFH4i-dAA84AIpY8dX_g3vqqXrbYU6N1oMc6hop_LRJu__WuT749o0Q0jlDdMo3qEscMCBtVBnXsB_q35yY28rFODW-yrxYGbIfzU5QtamFdSHcu_v3jgdMAJyd5LBfDJuchKCmxKSCIjzAXOVEy2ltnDjXlJYsEqpQjHb1QFuwsI_MVdaTtx2pBUHowAm1jma9zWfiLk26LDtX0VBAldJrKu_hqMsxho7GBnDi5siSfij-9HsLCKG2HcnR6TgHIbhaDcb81GJED2OWo6XA5NDIOxl68oZUGxcPHa6PFUl_YtBnEYeWhmvxvv93jwTCKMs5NeZ86uoDBzIwpL8sDVu9EqGPqY30nEzBtuXsASwuhYaKvh5OeXn8OTi1cjm9JEsftuyhah3HTYsE7dMGRtQfHGKbvZ44mL0-7Q9MorvadH2Mw==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYXSnwSBNh5xKKsJ8dugQYClywIlEZAnMX14Hj-KnrnJbNezDCYTSHbSTOjHCT9rgpD2Pq1KsUEj5ULXvztOi_BJ9C-Z5Z4AsoaRQtS75kaQD3TrtBUsbPdev4HPoz6jDlF6nMg_6Vfdl0i4-01A3M8gt5z_e8FVQBl1Yh1D7CAQvVAUIMA2SznB4yJLdINBDs4ssmOHrtkOr8V-qeFPb1aE3gdoLB2xP5YmJaeUhmfQKQtEHf0OZS8FOJ9tEvkMQ0XWx7i0HiM6_Nk-oUGda7BgGf7-3EKMAcKxfE5MEOJ3Cu26ZXWifLts-8a3y-porTjRvtZzAt3GiCVLsn93S2CeuWtzPmWRohbIRvp5vAFRb_CxNYiGEAdYFOz29-0534u5s8ALFTT-WeQt_J_hnQzkPcEGgvpaaAJ5qLegF8XbdgL_d3hmCswPyXJZUIqqJPwUfSwaOD3Nr1jalSJHQ43aJR07gxQnZm-3vLMjZRXDwh1YsMOmjn-9TSSpHinsaG4mk3F5tkLdrxdT0cNeCzoEyy-YX2jacn4H48-2Kp6wjyiaWDZcvN3WvX_5XgDxifAks3hqD8FDF3iDoaRPKEYAYUva22C9ReDp9rZUOatn9tK3ONlFuXlDHM0nGttt6b2rGALbtuimAHU7iRRF-IiQrXw_aw6fMAwPO4JDzTht1zGPbPsbboO0QmeA4lfUXmOry1UUDYMFxqLHDqPox4WXl0OgaKNLoY8ylHDPKsYNbwMUIt0tiC33suTYghKPAag-ibAI5tm64TTyEBLct45qaLoWzX8r4ml-Jh2RFH4i-dAA84AIpY8dX_g3vqqXrbYU6N1oMc6hop_LRJu__WuT749o0Q0jlDdMo3qEscMCBtVBnXsB_q35yY28rFODW-yrxYGbIfzU5QtamFdSHcu_v3jgdMAJyd5LBfDJuchKCmxKSCIjzAXOVEy2ltnDjXlJYsEqpQjHb1QFuwsI_MVdaTtx2pBUHowAm1jma9zWfiLk26LDtX0VBAldJrKu_hqMsxho7GBnDi5siSfij-9HsLCKG2HcnR6TgHIbhaDcb81GJED2OWo6XA5NDIOxl68oZUGxcPHa6PFUl_YtBnEYeWhmvxvv93jwTCKMs5NeZ86uoDBzIwpL8sDVu9EqGPqY30nEzBtuXsASwuhYaKvh5OeXn8OTi1cjm9JEsftuyhah3HTYsE7dMGRtQfHGKbvZ44mL0-7Q9MorvadH2Mw==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYXSnwSBNh5xKKsJ8dugQYClywIlEZAnMX14Hj-KnrnJbNezDCYTSHbSTOjHCT9rgpD2Pq1KsUEj5ULXvztOi_BJ9C-Z5Z4AsoaRQtS75kaQD3TrtBUsbPdev4HPoz6jDlF6nMg_6Vfdl0i4-01A3M8gt5z_e8FVQBl1Yh1D7CAQvVAUIMA2SznB4yJLdINBDs4ssmOHrtkOr8V-qeFPb1aE3gdoLB2xP5YmJaeUhmfQKQtEHf0OZS8FOJ9tEvkMQ0XWx7i0HiM6_Nk-oUGda7BgGf7-3EKMAcKxfE5MEOJ3Cu26ZXWifLts-8a3y-porTjRvtZzAt3GiCVLsn93S2CeuWtzPmWRohbIRvp5vAFRb_CxNYiGEAdYFOz29-0534u5s8ALFTT-WeQt_J_hnQzkPcEGgvpaaAJ5qLegF8XbdgL_d3hmCswPyXJZUIqqJPwUfSwaOD3Nr1jalSJHQ43aJR07gxQnZm-3vLMjZRXDwh1YsMOmjn-9TSSpHinsaG4mk3F5tkLdrxdT0cNeCzoEyy-YX2jacn4H48-2Kp6wjyiaWDZcvN3WvX_5XgDxifAks3hqD8FDF3iDoaRPKEYAYUva22C9ReDp9rZUOatn9tK3ONlFuXlDHM0nGttt6b2rGALbtuimAHU7iRRF-IiQrXw_aw6fMAwPO4JDzTht1zGPbPsbboO0QmeA4lfUXmOry1UUDYMFxqLHDqPox4WXl0OgaKNLoY8ylHDPKsYNbwMUIt0tiC33suTYghKPAag-ibAI5tm64TTyEBLct45qaLoWzX8r4ml-Jh2RFH4i-dAA84AIpY8dX_g3vqqXrbYU6N1oMc6hop_LRJu__WuT749o0Q0jlDdMo3qEscMCBtVBnXsB_q35yY28rFODW-yrxYGbIfzU5QtamFdSHcu_v3jgdMAJyd5LBfDJuchKCmxKSCIjzAXOVEy2ltnDjXlJYsEqpQjHb1QFuwsI_MVdaTtx2pBUHowAm1jma9zWfiLk26LDtX0VBAldJrKu_hqMsxho7GBnDi5siSfij-9HsLCKG2HcnR6TgHIbhaDcb81GJED2OWo6XA5NDIOxl68oZUGxcPHa6PFUl_YtBnEYeWhmvxvv93jwTCKMs5NeZ86uoDBzIwpL8sDVu9EqGPqY30nEzBtuXsASwuhYaKvh5OeXn8OTi1cjm9JEsftuyhah3HTYsE7dMGRtQfHGKbvZ44mL0-7Q9MorvadH2Mw==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1025.19,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqIuJh65H49qAn8Ko4HByfEIJ7AzID7_YlV_1kG1PB-w2pOGUrvgIgGDrneirv9d7AcoTbWLwMOnTPEF2Bcj1e-MB2wBccbzrouUm97kWxLnKSiW6RE3pw2daPfA1mRBGFqgX253o-JzPQnY30dxCTliccCtNeN_-UGSBnsBuUipiWhEdncCypPzZi6u1bpOYouACb_2gnDL6s82UOQwU05GrYhuUIU2k5O66tCs-pfpMDb3lM3UNbg2-OlAEunIUCp_VUS94aFnnOtQL119Uc0jwGD_gsOXQhxlt64_qMSqt4FREF0bZTsJEXykpdLeqj0PiuOvFKrCRXLKoUGfDFjVpP7hSpWvolaSqHH_Us3RrnZzVElNHCY2a8ll1fgn4lvZhqEFGuN2pkU-F2-V5g9czt2gRsf4FFCLnJINSk0BAgXetq18tH7sPJLwisL3IecQBtEfsRxxywk5M83xpfRG-P8NfdeMB1OOfg1Mi4JjSJac4KxaMOWLhpN8_inGMCTThPj945NciMXrm0DQ5EWOf3sWG5x3EEutPL3zEGnFYOo8G78iRr10VgD7yxr-5wP51bgd9bEx59Nl-T1bewY8AUO8qqN7Cj5g8vX4s2oF2RT-jsT5EmCAcohXYzOru1i_MtNcIN9AW5ZzJT4jgOHwUTBbSe8f1iS_uiY2TfZSc6zfbnVQNlUpaE8_77wIvr-Vf3eJcfiETTSo0G0zx53HfrQMHHjqz-CC7_RxJLSFr2nNX5tA8AEuVL9P-GoqMrU6UvHJlUbuh3ru9taNe5RBXT7Hh8RDmBx6vVO3sSsVDEtUWK-iyWoozZQEU9XNW3zksWzmQ5GqdCYiYgxEy0zvk5d33AaNHkMUhmTseZu7NJH0fTmb2RgX7K8NHIIrtTdZkCR_DL7LWbgPSdLP5EJ3GBm30LCW8-6rxk0So8aATKfwb76nqA1na9UGNj-DJwavh4y16HaEu7oxnVA3VVFHcZM-tVlGDc1vOPHudtc2PY1-_i-qH1UJ2bu70WQm8KXdJU5EwL0oZeFN_-WlBdJbj5-X8h6d4H43SHG0gyghKK0WQd9yQhVCCPG7gwjQr409tn2MtgiTNOoOJ6yyKsCJXiQONN2WmrIjinilZgt7-SKlMqt20XTTn6CENOVpmJ5PWJnLXul1J9f8DbbY4_R3hQMyo2vYzhGU2pH0NdDOLGAOLrZ9Ptd3TJw9GWacGmyOFH-SCG45hI7jz4N5x_Q==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqIuJh65H49qAn8Ko4HByfEIJ7AzID7_YlV_1kG1PB-w2pOGUrvgIgGDrneirv9d7AcoTbWLwMOnTPEF2Bcj1e-MB2wBccbzrouUm97kWxLnKSiW6RE3pw2daPfA1mRBGFqgX253o-JzPQnY30dxCTliccCtNeN_-UGSBnsBuUipiWhEdncCypPzZi6u1bpOYouACb_2gnDL6s82UOQwU05GrYhuUIU2k5O66tCs-pfpMDb3lM3UNbg2-OlAEunIUCp_VUS94aFnnOtQL119Uc0jwGD_gsOXQhxlt64_qMSqt4FREF0bZTsJEXykpdLeqj0PiuOvFKrCRXLKoUGfDFjVpP7hSpWvolaSqHH_Us3RrnZzVElNHCY2a8ll1fgn4lvZhqEFGuN2pkU-F2-V5g9czt2gRsf4FFCLnJINSk0BAgXetq18tH7sPJLwisL3IecQBtEfsRxxywk5M83xpfRG-P8NfdeMB1OOfg1Mi4JjSJac4KxaMOWLhpN8_inGMCTThPj945NciMXrm0DQ5EWOf3sWG5x3EEutPL3zEGnFYOo8G78iRr10VgD7yxr-5wP51bgd9bEx59Nl-T1bewY8AUO8qqN7Cj5g8vX4s2oF2RT-jsT5EmCAcohXYzOru1i_MtNcIN9AW5ZzJT4jgOHwUTBbSe8f1iS_uiY2TfZSc6zfbnVQNlUpaE8_77wIvr-Vf3eJcfiETTSo0G0zx53HfrQMHHjqz-CC7_RxJLSFr2nNX5tA8AEuVL9P-GoqMrU6UvHJlUbuh3ru9taNe5RBXT7Hh8RDmBx6vVO3sSsVDEtUWK-iyWoozZQEU9XNW3zksWzmQ5GqdCYiYgxEy0zvk5d33AaNHkMUhmTseZu7NJH0fTmb2RgX7K8NHIIrtTdZkCR_DL7LWbgPSdLP5EJ3GBm30LCW8-6rxk0So8aATKfwb76nqA1na9UGNj-DJwavh4y16HaEu7oxnVA3VVFHcZM-tVlGDc1vOPHudtc2PY1-_i-qH1UJ2bu70WQm8KXdJU5EwL0oZeFN_-WlBdJbj5-X8h6d4H43SHG0gyghKK0WQd9yQhVCCPG7gwjQr409tn2MtgiTNOoOJ6yyKsCJXiQONN2WmrIjinilZgt7-SKlMqt20XTTn6CENOVpmJ5PWJnLXul1J9f8DbbY4_R3hQMyo2vYzhGU2pH0NdDOLGAOLrZ9Ptd3TJw9GWacGmyOFH-SCG45hI7jz4N5x_Q==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqIuJh65H49qAn8Ko4HByfEIJ7AzID7_YlV_1kG1PB-w2pOGUrvgIgGDrneirv9d7AcoTbWLwMOnTPEF2Bcj1e-MB2wBccbzrouUm97kWxLnKSiW6RE3pw2daPfA1mRBGFqgX253o-JzPQnY30dxCTliccCtNeN_-UGSBnsBuUipiWhEdncCypPzZi6u1bpOYouACb_2gnDL6s82UOQwU05GrYhuUIU2k5O66tCs-pfpMDb3lM3UNbg2-OlAEunIUCp_VUS94aFnnOtQL119Uc0jwGD_gsOXQhxlt64_qMSqt4FREF0bZTsJEXykpdLeqj0PiuOvFKrCRXLKoUGfDFjVpP7hSpWvolaSqHH_Us3RrnZzVElNHCY2a8ll1fgn4lvZhqEFGuN2pkU-F2-V5g9czt2gRsf4FFCLnJINSk0BAgXetq18tH7sPJLwisL3IecQBtEfsRxxywk5M83xpfRG-P8NfdeMB1OOfg1Mi4JjSJac4KxaMOWLhpN8_inGMCTThPj945NciMXrm0DQ5EWOf3sWG5x3EEutPL3zEGnFYOo8G78iRr10VgD7yxr-5wP51bgd9bEx59Nl-T1bewY8AUO8qqN7Cj5g8vX4s2oF2RT-jsT5EmCAcohXYzOru1i_MtNcIN9AW5ZzJT4jgOHwUTBbSe8f1iS_uiY2TfZSc6zfbnVQNlUpaE8_77wIvr-Vf3eJcfiETTSo0G0zx53HfrQMHHjqz-CC7_RxJLSFr2nNX5tA8AEuVL9P-GoqMrU6UvHJlUbuh3ru9taNe5RBXT7Hh8RDmBx6vVO3sSsVDEtUWK-iyWoozZQEU9XNW3zksWzmQ5GqdCYiYgxEy0zvk5d33AaNHkMUhmTseZu7NJH0fTmb2RgX7K8NHIIrtTdZkCR_DL7LWbgPSdLP5EJ3GBm30LCW8-6rxk0So8aATKfwb76nqA1na9UGNj-DJwavh4y16HaEu7oxnVA3VVFHcZM-tVlGDc1vOPHudtc2PY1-_i-qH1UJ2bu70WQm8KXdJU5EwL0oZeFN_-WlBdJbj5-X8h6d4H43SHG0gyghKK0WQd9yQhVCCPG7gwjQr409tn2MtgiTNOoOJ6yyKsCJXiQONN2WmrIjinilZgt7-SKlMqt20XTTn6CENOVpmJ5PWJnLXul1J9f8DbbY4_R3hQMyo2vYzhGU2pH0NdDOLGAOLrZ9Ptd3TJw9GWacGmyOFH-SCG45hI7jz4N5x_Q==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1037.51,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 794,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2ZBlGAUX08ZAAxSLptfJqAOyvZqITY2kmrnHENmLXZWJZ7uShznnJielsjdXJgADUv36kd4OpKIqtzT_zust277zOU4e8mxNxnGLy2BpIGTIUccnCH1ZAJsakOlMZgEICSLWplbNA3N-6gr-Ker3IlNnyts-0i2eGpzRbn0Nqs0HV_8UiqdpcfhVKH4SgmPoVXmvfXor4jy7SDErOmm8TGRssnG8rBYEKL_Cg-FLX_9Di8CEJj4fQZibwaVdEknE-fkmnOkAqH_Ir4rQQ47RoNkV1-plOLeas2nckJ2T7DVs9sHmGud-jxGinegM1GdQ6fgOXcA36zndjSq0zGaMOCIy77PrimzX-JFmU_5n5_HDKPhNB0_8cwPzZ-UKvI1oi6rqzsJ9shYatIHVgrxVVpZBpBLwPrqMwIv854NzuARDTKYDs3QrI3l4uc9eOGSWnZnMyC4LIdcAvSak4RRWa5NXlocMbJ5z81ZkN3ecmGarit0gZoMCQGnQs6yUCSsxe7M6KGH_KpYq_kA1wd_q6eIinWaUtvu7XZailznjSDX6YVM8Wm8uzqopmyMN2dBfSVz8CQj0eSsOc5P17kMThA8J0lKzIVhjLxQnHMoCajN1Kgsy2GJDZAeHxEfwWImTf5tRgdb5nt07bYZTMRwLw_zKsr50qj-auGUSHxFoNh4q1KpsfdnreI2eFUR6lFh7sDKgGzZc8pfsOeihv3-ovRLkZUY3MdVw8l_QQMQIBYjVeEQNJqkiomZiUvMFMVGVm6h30ynxqqJ1sHl1gQOMr0En8UMs4grzZFPP9V-CpVmOOXInzirn2cngAJQoE1S57BJuLd8lN39762SNnAaQMJWOOQDesGrzYUq9_ZyIPZVeBLdN4B3JDqWiO1xOQ22YfXL8JR-QwN0r9DfeGUwHuvlQHejf6zN4UY4KaMhuRKHdEYdDJ7Azzu1Ds919WtPew3vskgTJJ8rm5BQZQHGKKQ77ehkhPCOeKsxTadmNfrr7i-J7TYKecBWo1QPFwuJCf3rFYvRlATqy8VHwLP5vZiiiQDNMzJyK1Qw8VCoCHfBe5SQsWwYtUa4TKcw1xNwIYioFW-ttC3ZSYcZ34KHSSas5DndhzywCvY6SVnmyaZ_ith2DSxWCEdSQjiW_9lOsp7W0TZ6AH5crWBPl6-uEHmYwUkVEUsLKetbg6WABp04FOhXCTKlKxLMOpOM91FLrgxNh2ufui50yNcxU-a0PZSziu0PXsJ_oG4bwSs7Au4M=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2ZBlGAUX08ZAAxSLptfJqAOyvZqITY2kmrnHENmLXZWJZ7uShznnJielsjdXJgADUv36kd4OpKIqtzT_zust277zOU4e8mxNxnGLy2BpIGTIUccnCH1ZAJsakOlMZgEICSLWplbNA3N-6gr-Ker3IlNnyts-0i2eGpzRbn0Nqs0HV_8UiqdpcfhVKH4SgmPoVXmvfXor4jy7SDErOmm8TGRssnG8rBYEKL_Cg-FLX_9Di8CEJj4fQZibwaVdEknE-fkmnOkAqH_Ir4rQQ47RoNkV1-plOLeas2nckJ2T7DVs9sHmGud-jxGinegM1GdQ6fgOXcA36zndjSq0zGaMOCIy77PrimzX-JFmU_5n5_HDKPhNB0_8cwPzZ-UKvI1oi6rqzsJ9shYatIHVgrxVVpZBpBLwPrqMwIv854NzuARDTKYDs3QrI3l4uc9eOGSWnZnMyC4LIdcAvSak4RRWa5NXlocMbJ5z81ZkN3ecmGarit0gZoMCQGnQs6yUCSsxe7M6KGH_KpYq_kA1wd_q6eIinWaUtvu7XZailznjSDX6YVM8Wm8uzqopmyMN2dBfSVz8CQj0eSsOc5P17kMThA8J0lKzIVhjLxQnHMoCajN1Kgsy2GJDZAeHxEfwWImTf5tRgdb5nt07bYZTMRwLw_zKsr50qj-auGUSHxFoNh4q1KpsfdnreI2eFUR6lFh7sDKgGzZc8pfsOeihv3-ovRLkZUY3MdVw8l_QQMQIBYjVeEQNJqkiomZiUvMFMVGVm6h30ynxqqJ1sHl1gQOMr0En8UMs4grzZFPP9V-CpVmOOXInzirn2cngAJQoE1S57BJuLd8lN39762SNnAaQMJWOOQDesGrzYUq9_ZyIPZVeBLdN4B3JDqWiO1xOQ22YfXL8JR-QwN0r9DfeGUwHuvlQHejf6zN4UY4KaMhuRKHdEYdDJ7Azzu1Ds919WtPew3vskgTJJ8rm5BQZQHGKKQ77ehkhPCOeKsxTadmNfrr7i-J7TYKecBWo1QPFwuJCf3rFYvRlATqy8VHwLP5vZiiiQDNMzJyK1Qw8VCoCHfBe5SQsWwYtUa4TKcw1xNwIYioFW-ttC3ZSYcZ34KHSSas5DndhzywCvY6SVnmyaZ_ith2DSxWCEdSQjiW_9lOsp7W0TZ6AH5crWBPl6-uEHmYwUkVEUsLKetbg6WABp04FOhXCTKlKxLMOpOM91FLrgxNh2ufui50yNcxU-a0PZSziu0PXsJ_oG4bwSs7Au4M=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2ZBlGAUX08ZAAxSLptfJqAOyvZqITY2kmrnHENmLXZWJZ7uShznnJielsjdXJgADUv36kd4OpKIqtzT_zust277zOU4e8mxNxnGLy2BpIGTIUccnCH1ZAJsakOlMZgEICSLWplbNA3N-6gr-Ker3IlNnyts-0i2eGpzRbn0Nqs0HV_8UiqdpcfhVKH4SgmPoVXmvfXor4jy7SDErOmm8TGRssnG8rBYEKL_Cg-FLX_9Di8CEJj4fQZibwaVdEknE-fkmnOkAqH_Ir4rQQ47RoNkV1-plOLeas2nckJ2T7DVs9sHmGud-jxGinegM1GdQ6fgOXcA36zndjSq0zGaMOCIy77PrimzX-JFmU_5n5_HDKPhNB0_8cwPzZ-UKvI1oi6rqzsJ9shYatIHVgrxVVpZBpBLwPrqMwIv854NzuARDTKYDs3QrI3l4uc9eOGSWnZnMyC4LIdcAvSak4RRWa5NXlocMbJ5z81ZkN3ecmGarit0gZoMCQGnQs6yUCSsxe7M6KGH_KpYq_kA1wd_q6eIinWaUtvu7XZailznjSDX6YVM8Wm8uzqopmyMN2dBfSVz8CQj0eSsOc5P17kMThA8J0lKzIVhjLxQnHMoCajN1Kgsy2GJDZAeHxEfwWImTf5tRgdb5nt07bYZTMRwLw_zKsr50qj-auGUSHxFoNh4q1KpsfdnreI2eFUR6lFh7sDKgGzZc8pfsOeihv3-ovRLkZUY3MdVw8l_QQMQIBYjVeEQNJqkiomZiUvMFMVGVm6h30ynxqqJ1sHl1gQOMr0En8UMs4grzZFPP9V-CpVmOOXInzirn2cngAJQoE1S57BJuLd8lN39762SNnAaQMJWOOQDesGrzYUq9_ZyIPZVeBLdN4B3JDqWiO1xOQ22YfXL8JR-QwN0r9DfeGUwHuvlQHejf6zN4UY4KaMhuRKHdEYdDJ7Azzu1Ds919WtPew3vskgTJJ8rm5BQZQHGKKQ77ehkhPCOeKsxTadmNfrr7i-J7TYKecBWo1QPFwuJCf3rFYvRlATqy8VHwLP5vZiiiQDNMzJyK1Qw8VCoCHfBe5SQsWwYtUa4TKcw1xNwIYioFW-ttC3ZSYcZ34KHSSas5DndhzywCvY6SVnmyaZ_ith2DSxWCEdSQjiW_9lOsp7W0TZ6AH5crWBPl6-uEHmYwUkVEUsLKetbg6WABp04FOhXCTKlKxLMOpOM91FLrgxNh2ufui50yNcxU-a0PZSziu0PXsJ_oG4bwSs7Au4M=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1020.59,
            "totalPrice": 794
          },
          {
            "allPassengerTotalPrice": 794,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqG8CGun_dzyhQIiuXx2m4DAWdxT5prII7KwGf-IK_yhrCjnTfhUCs4bW5s_vwOCke6MFD6bSd7YZL3izu3bCmkpnlB0oJwxsAoPFmDEHgr-RA45NoYlQyzgKolBoVKxbmouEoJKRupWn1Wzk1hvACPqUsEINWNnmlWbBHGVsTynoKTwDSVppUxJXG6SBE0lgi6hbhnovNuQODxKnIsd3ED6HKKhF1YDE3JIY_M0tyQijaI-7xxM1uj3Dot1BFC6oV04-1uuAvAgeqOEUWhvLVSLDGJXxT3dvhf_86XwbNg0rroHxJ5UtwiRXMY4l7VNlldMSj-LtyJ684anTP8etE5XyhdBkYfCuAlEBRs5xBj4qqU1iDHb_Vm3UAS1HpCJlZDy_4meSwnBc5OTY_J-8KF9bQEgwuAWF0XUhmra_8iFHQIJo-Aqjp1NC67ZKGL1NxApn-M556ZjC94-hXT_XcXVr_qZBX2th5Zqj27P8slDO5N4JnBjoYVIFtZRuuqfwFGCg-JoKmWedJgYTIlir9FQlPqNnh3SuliLlaTX5kCw3Q61w-fcztR3LSx2vRSIMNmr6D0deO8EQ_IOGLgNKs8vBFoTsAHa4HpScU7NvAW4hM3JpUfKNkkqJnsuhtGV9Nipu-aitOvqZzXwxK8aeeoZ3X0-mvlZa0Ye-3XRN-dEnRTPadaf1W9a1YXLkywCl-JROjg4pt-Usq_ZZU4BvtXVqyv69nSdLtusJUFXSXyoqE-gpRyR9XzdvtlYNva3qKaKZFOBS8kGoACVxXdW7lj7RAF6TpOSX5kzxzIQH1AjGOI2l46-gWcEKGdiT0Cqm8pz50P-6_b85rq8zlQp1S1RGjGPbtYhYHKdkIdf04Evt1YraPslQEWV_-Jmt06gKgp0qqFn87034novttvdKLLHPro994eqUuBkJ1Ta8N5iG5DnQhH-ju5ZHPIxG7HPY0fh2dLV0tO77pQFKPn7NaFrLg_Dri8nxx4RY0iJNAMMTPzlq5GjlO-1FYkY4XPWSiT-dM61r2fhWzg5ct5LV_isb7o1GR9G3tNkbjAMYst9kZzjLIq9GjBwKtBKNgVPy8MytDU0CHlX9f9y7883hUml0ZZB1aRCU7_uEHFFpjl7YJKOHed-6wyVhm0aGOKXfbwp8fybHcwBQ50V5H-Dfw9ocUst3kU4jJtIuB9yEQs7h4aTW-tyWOBYdyx9RaN66oMIb8whfJbVpBozGpdS5shoCqIPIy_x0QwlSn9JzghfZE3I9l7O4Oe26f8YpETFMhcPHdsEO8qV196e3xD20I5bIvuZ86lANr-XZgZbIA9w=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqG8CGun_dzyhQIiuXx2m4DAWdxT5prII7KwGf-IK_yhrCjnTfhUCs4bW5s_vwOCke6MFD6bSd7YZL3izu3bCmkpnlB0oJwxsAoPFmDEHgr-RA45NoYlQyzgKolBoVKxbmouEoJKRupWn1Wzk1hvACPqUsEINWNnmlWbBHGVsTynoKTwDSVppUxJXG6SBE0lgi6hbhnovNuQODxKnIsd3ED6HKKhF1YDE3JIY_M0tyQijaI-7xxM1uj3Dot1BFC6oV04-1uuAvAgeqOEUWhvLVSLDGJXxT3dvhf_86XwbNg0rroHxJ5UtwiRXMY4l7VNlldMSj-LtyJ684anTP8etE5XyhdBkYfCuAlEBRs5xBj4qqU1iDHb_Vm3UAS1HpCJlZDy_4meSwnBc5OTY_J-8KF9bQEgwuAWF0XUhmra_8iFHQIJo-Aqjp1NC67ZKGL1NxApn-M556ZjC94-hXT_XcXVr_qZBX2th5Zqj27P8slDO5N4JnBjoYVIFtZRuuqfwFGCg-JoKmWedJgYTIlir9FQlPqNnh3SuliLlaTX5kCw3Q61w-fcztR3LSx2vRSIMNmr6D0deO8EQ_IOGLgNKs8vBFoTsAHa4HpScU7NvAW4hM3JpUfKNkkqJnsuhtGV9Nipu-aitOvqZzXwxK8aeeoZ3X0-mvlZa0Ye-3XRN-dEnRTPadaf1W9a1YXLkywCl-JROjg4pt-Usq_ZZU4BvtXVqyv69nSdLtusJUFXSXyoqE-gpRyR9XzdvtlYNva3qKaKZFOBS8kGoACVxXdW7lj7RAF6TpOSX5kzxzIQH1AjGOI2l46-gWcEKGdiT0Cqm8pz50P-6_b85rq8zlQp1S1RGjGPbtYhYHKdkIdf04Evt1YraPslQEWV_-Jmt06gKgp0qqFn87034novttvdKLLHPro994eqUuBkJ1Ta8N5iG5DnQhH-ju5ZHPIxG7HPY0fh2dLV0tO77pQFKPn7NaFrLg_Dri8nxx4RY0iJNAMMTPzlq5GjlO-1FYkY4XPWSiT-dM61r2fhWzg5ct5LV_isb7o1GR9G3tNkbjAMYst9kZzjLIq9GjBwKtBKNgVPy8MytDU0CHlX9f9y7883hUml0ZZB1aRCU7_uEHFFpjl7YJKOHed-6wyVhm0aGOKXfbwp8fybHcwBQ50V5H-Dfw9ocUst3kU4jJtIuB9yEQs7h4aTW-tyWOBYdyx9RaN66oMIb8whfJbVpBozGpdS5shoCqIPIy_x0QwlSn9JzghfZE3I9l7O4Oe26f8YpETFMhcPHdsEO8qV196e3xD20I5bIvuZ86lANr-XZgZbIA9w=",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqG8CGun_dzyhQIiuXx2m4DAWdxT5prII7KwGf-IK_yhrCjnTfhUCs4bW5s_vwOCke6MFD6bSd7YZL3izu3bCmkpnlB0oJwxsAoPFmDEHgr-RA45NoYlQyzgKolBoVKxbmouEoJKRupWn1Wzk1hvACPqUsEINWNnmlWbBHGVsTynoKTwDSVppUxJXG6SBE0lgi6hbhnovNuQODxKnIsd3ED6HKKhF1YDE3JIY_M0tyQijaI-7xxM1uj3Dot1BFC6oV04-1uuAvAgeqOEUWhvLVSLDGJXxT3dvhf_86XwbNg0rroHxJ5UtwiRXMY4l7VNlldMSj-LtyJ684anTP8etE5XyhdBkYfCuAlEBRs5xBj4qqU1iDHb_Vm3UAS1HpCJlZDy_4meSwnBc5OTY_J-8KF9bQEgwuAWF0XUhmra_8iFHQIJo-Aqjp1NC67ZKGL1NxApn-M556ZjC94-hXT_XcXVr_qZBX2th5Zqj27P8slDO5N4JnBjoYVIFtZRuuqfwFGCg-JoKmWedJgYTIlir9FQlPqNnh3SuliLlaTX5kCw3Q61w-fcztR3LSx2vRSIMNmr6D0deO8EQ_IOGLgNKs8vBFoTsAHa4HpScU7NvAW4hM3JpUfKNkkqJnsuhtGV9Nipu-aitOvqZzXwxK8aeeoZ3X0-mvlZa0Ye-3XRN-dEnRTPadaf1W9a1YXLkywCl-JROjg4pt-Usq_ZZU4BvtXVqyv69nSdLtusJUFXSXyoqE-gpRyR9XzdvtlYNva3qKaKZFOBS8kGoACVxXdW7lj7RAF6TpOSX5kzxzIQH1AjGOI2l46-gWcEKGdiT0Cqm8pz50P-6_b85rq8zlQp1S1RGjGPbtYhYHKdkIdf04Evt1YraPslQEWV_-Jmt06gKgp0qqFn87034novttvdKLLHPro994eqUuBkJ1Ta8N5iG5DnQhH-ju5ZHPIxG7HPY0fh2dLV0tO77pQFKPn7NaFrLg_Dri8nxx4RY0iJNAMMTPzlq5GjlO-1FYkY4XPWSiT-dM61r2fhWzg5ct5LV_isb7o1GR9G3tNkbjAMYst9kZzjLIq9GjBwKtBKNgVPy8MytDU0CHlX9f9y7883hUml0ZZB1aRCU7_uEHFFpjl7YJKOHed-6wyVhm0aGOKXfbwp8fybHcwBQ50V5H-Dfw9ocUst3kU4jJtIuB9yEQs7h4aTW-tyWOBYdyx9RaN66oMIb8whfJbVpBozGpdS5shoCqIPIy_x0QwlSn9JzghfZE3I9l7O4Oe26f8YpETFMhcPHdsEO8qV196e3xD20I5bIvuZ86lANr-XZgZbIA9w=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1087.81,
            "totalPrice": 794
          },
          {
            "allPassengerTotalPrice": 795,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjLZBKS7tvUUdaGKMr_aC1C1fcNWRGTM5GK21UcITawazVJ4nOG9iIRUgXbGnkW8Daa6BaMKTwq82zg7-J58OduWzQzXC6dQgjRZsamuAP30LaOO-ei2hmyM4rbBslzIsrh5djb07dTWlr8X6SAf8C9qaKnEerIIj2_7TEhTDc9l1bDNUAvUisRCJlYsP2LupDvAtSxUieElhBax56pA3BPX24gmYdCEuNNYcNtffg5Q2ARDMyA-0mKzALi0IAshqhi0aDr19_2BUCXPsJKqaviCmb0DxJPlF0ozQjheKcr1xqGHn2krwX23CXnS5vh5T2FYhxX6OrBKMuf7K6_2l4MoLDp1ZushpiWERjYy18SCpWfZZ3D77MlZMCRvKkL01EevenM9YH5qI9ct6f_ISyDAN17eZlYq7M4gcKWtxnLXjdGzsS4t1kFoduvDaKW61uCgc2q_dJcAyN5zYlv8CAjAR3Uqhii-Ocyd_nVexH1Zom4qFkFRZeg5M3NLIpUIUNKlxExOm5Z3MJrasr27mwknFDhzH5bhFTHUiR4U_PbiJA-kh5-0xFOFOkIT3qEVzBjZYTMW8uNg_hBhfLhEcAtn4e49DTSt6Dy4e_lPkw7XbEjUZ-Ldn5qK1z_wM9cxG-CbJuwr470bFXv_k7jwgYtUxrfkoaIDHDF18pAcER3e7NddRHpfbP4ZykbcfZDfmnrSOLyka1NGVC7CzWLfTkgD0WJ70SGLcs7lxT5cJrLwTuXIt3htaBOHrP31UaH24TyioyhtCqjaRYMcDJtB8tZIsR7Q0zl8J17PG4Mme-ceN39-Z-R1iMYzvU9g_iRmB8yhqxlHvNXpMXozW-SARXGS1C6-W5f0MjTITfb1np734k0zOLPOFdzVI_JKg-pHzOIWlYKOD5FOzBtCvZhnmlfhkMJO8ioATWTkh087Spvp2ZllM1x7P0bNqQ32iSY2dJjGaR8DzUebSn_OoGKd-qsciR9mzQwXlnZJxNMESlJZoUFjZbC3yZfRcv9-Wt8uwGXJGH5mlzlMUYy2dD0cWgIwxW-XYQ4cDHysEP67FYh2KTIQe2p-vn9RzOQfi66N30v6TYh4qt3-I74UZG51bwptlikrLJIOkCN7M_Ua7yFr96O7tiSJ2pE3x7ZOIjcfoB1D_TWQC_X7O9SC4G2ftfARYpzkA_vDzSQEkv0QtbTFuVK0Zd5XZ99Vaj0yPP9icVsJ4jeJ1V6icwQ5neczfLy7-Pw45MbXJPfndQBTitgs=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjLZBKS7tvUUdaGKMr_aC1C1fcNWRGTM5GK21UcITawazVJ4nOG9iIRUgXbGnkW8Daa6BaMKTwq82zg7-J58OduWzQzXC6dQgjRZsamuAP30LaOO-ei2hmyM4rbBslzIsrh5djb07dTWlr8X6SAf8C9qaKnEerIIj2_7TEhTDc9l1bDNUAvUisRCJlYsP2LupDvAtSxUieElhBax56pA3BPX24gmYdCEuNNYcNtffg5Q2ARDMyA-0mKzALi0IAshqhi0aDr19_2BUCXPsJKqaviCmb0DxJPlF0ozQjheKcr1xqGHn2krwX23CXnS5vh5T2FYhxX6OrBKMuf7K6_2l4MoLDp1ZushpiWERjYy18SCpWfZZ3D77MlZMCRvKkL01EevenM9YH5qI9ct6f_ISyDAN17eZlYq7M4gcKWtxnLXjdGzsS4t1kFoduvDaKW61uCgc2q_dJcAyN5zYlv8CAjAR3Uqhii-Ocyd_nVexH1Zom4qFkFRZeg5M3NLIpUIUNKlxExOm5Z3MJrasr27mwknFDhzH5bhFTHUiR4U_PbiJA-kh5-0xFOFOkIT3qEVzBjZYTMW8uNg_hBhfLhEcAtn4e49DTSt6Dy4e_lPkw7XbEjUZ-Ldn5qK1z_wM9cxG-CbJuwr470bFXv_k7jwgYtUxrfkoaIDHDF18pAcER3e7NddRHpfbP4ZykbcfZDfmnrSOLyka1NGVC7CzWLfTkgD0WJ70SGLcs7lxT5cJrLwTuXIt3htaBOHrP31UaH24TyioyhtCqjaRYMcDJtB8tZIsR7Q0zl8J17PG4Mme-ceN39-Z-R1iMYzvU9g_iRmB8yhqxlHvNXpMXozW-SARXGS1C6-W5f0MjTITfb1np734k0zOLPOFdzVI_JKg-pHzOIWlYKOD5FOzBtCvZhnmlfhkMJO8ioATWTkh087Spvp2ZllM1x7P0bNqQ32iSY2dJjGaR8DzUebSn_OoGKd-qsciR9mzQwXlnZJxNMESlJZoUFjZbC3yZfRcv9-Wt8uwGXJGH5mlzlMUYy2dD0cWgIwxW-XYQ4cDHysEP67FYh2KTIQe2p-vn9RzOQfi66N30v6TYh4qt3-I74UZG51bwptlikrLJIOkCN7M_Ua7yFr96O7tiSJ2pE3x7ZOIjcfoB1D_TWQC_X7O9SC4G2ftfARYpzkA_vDzSQEkv0QtbTFuVK0Zd5XZ99Vaj0yPP9icVsJ4jeJ1V6icwQ5neczfLy7-Pw45MbXJPfndQBTitgs=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjLZBKS7tvUUdaGKMr_aC1C1fcNWRGTM5GK21UcITawazVJ4nOG9iIRUgXbGnkW8Daa6BaMKTwq82zg7-J58OduWzQzXC6dQgjRZsamuAP30LaOO-ei2hmyM4rbBslzIsrh5djb07dTWlr8X6SAf8C9qaKnEerIIj2_7TEhTDc9l1bDNUAvUisRCJlYsP2LupDvAtSxUieElhBax56pA3BPX24gmYdCEuNNYcNtffg5Q2ARDMyA-0mKzALi0IAshqhi0aDr19_2BUCXPsJKqaviCmb0DxJPlF0ozQjheKcr1xqGHn2krwX23CXnS5vh5T2FYhxX6OrBKMuf7K6_2l4MoLDp1ZushpiWERjYy18SCpWfZZ3D77MlZMCRvKkL01EevenM9YH5qI9ct6f_ISyDAN17eZlYq7M4gcKWtxnLXjdGzsS4t1kFoduvDaKW61uCgc2q_dJcAyN5zYlv8CAjAR3Uqhii-Ocyd_nVexH1Zom4qFkFRZeg5M3NLIpUIUNKlxExOm5Z3MJrasr27mwknFDhzH5bhFTHUiR4U_PbiJA-kh5-0xFOFOkIT3qEVzBjZYTMW8uNg_hBhfLhEcAtn4e49DTSt6Dy4e_lPkw7XbEjUZ-Ldn5qK1z_wM9cxG-CbJuwr470bFXv_k7jwgYtUxrfkoaIDHDF18pAcER3e7NddRHpfbP4ZykbcfZDfmnrSOLyka1NGVC7CzWLfTkgD0WJ70SGLcs7lxT5cJrLwTuXIt3htaBOHrP31UaH24TyioyhtCqjaRYMcDJtB8tZIsR7Q0zl8J17PG4Mme-ceN39-Z-R1iMYzvU9g_iRmB8yhqxlHvNXpMXozW-SARXGS1C6-W5f0MjTITfb1np734k0zOLPOFdzVI_JKg-pHzOIWlYKOD5FOzBtCvZhnmlfhkMJO8ioATWTkh087Spvp2ZllM1x7P0bNqQ32iSY2dJjGaR8DzUebSn_OoGKd-qsciR9mzQwXlnZJxNMESlJZoUFjZbC3yZfRcv9-Wt8uwGXJGH5mlzlMUYy2dD0cWgIwxW-XYQ4cDHysEP67FYh2KTIQe2p-vn9RzOQfi66N30v6TYh4qt3-I74UZG51bwptlikrLJIOkCN7M_Ua7yFr96O7tiSJ2pE3x7ZOIjcfoB1D_TWQC_X7O9SC4G2ftfARYpzkA_vDzSQEkv0QtbTFuVK0Zd5XZ99Vaj0yPP9icVsJ4jeJ1V6icwQ5neczfLy7-Pw45MbXJPfndQBTitgs=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1022,
            "totalPrice": 795
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTSYA6YMWlEa6g29_d4EgN4PpGgJPoP0kt5IT7rJWbAlSa5Kt5NYaHg9lLcFMSQd9WbTfCZ-gT5-PRTAiB5HpoOgRYRV1O3_5DpK8vFJohM2TOxWqV3vsBZ-AWjgwdlryIBEghgF0-3UQRB3SBqkAeSzIoBW1lzf04O85wfklTTWpaMJYv72EhJiRUUlq_dG_x2blYLRy4gB4zfWXIburu-w2XIAzduEcpu86kLsQtU8KO6XpHJiYiaYSJSyr_U9z2BSyfdP-XyqvariBAQGVQtNRWCh-cYDIE8CO3W521AfB4y8NlTXj6nYR7DCwOoV-lhl9psvYpHDtZtpI0fyac0MHe3A-TN_IS111SnOqnptBWKVWV4iwm2HADMJHul7iQRkUjQxLrPAdnBy0iWaIejk4yLUifH8ziFWkB_ZgTdWIfELoe3qPdm6vXGyc8SSaJk0rbA3YkKc8ikrJQu58V0tm7PiuCjNO4pLSSiUyFmCBilVHPgIwmDrYWPe-Wj4WAAdj8EN7K_DLYQG2_SiLwPq9uxCl3ugWjEzok0c4Fpmmroz-dM0YZdQuZsVXeWXalcgdQn6WWohCaoTHZbM71kT8z2UcumVNXV2JR8B4vTCSI1z9NTdALzrRlZh3cbm42nv2jgdlsq8H6Aks7dn12WhvfvWxw4y9-4ztJcJ3ONmRrG-gkDW1FJXaOoqO1K5fzxSisUKlW19v9Gm_nen-hWuC8GoEuqJap2kUcu5INeBRYc0E3mGc3xQ1zvzRX_BjUjrVgalIfQDuBY6RlpjAP2EB1slGZKN6nn-Rq5Tjech-ZSSJma0yXJ5we2De6xPx5POmsNRvqV4Ef6aHpxQGUgRlChDPh17oCdKdApgGqY2INtzzBVNZGLErfq_NGvh5zZVMYzC1fDYE_cefz3mHEmDJF-zpZssiH5Upp_aA7ePPsyAdgXSy5BCrLokYbXjMqPW7av5MkR35LDNM_VG-AfhdcALDVkma3AqBrsk-PA_U0JJeAWq30-qI9Y5WdxkHxk2xBf56CPPX7-uxAR8ome5CJGfipTz8So1Hogk6qM1XWpzWStWf-h9WRf4gDVFpEb4AiEsHd_LlTtwcq6xA_myhhkiEzMdGTLZ0qYaHdllyH52KILYAeoKkWCEl1K8cbgFArPCLl_cn2cWQCchp6lIXAbtkMsqGdOcc_m4G60AYvL0xGdbb0F0Nu3Ir4XAVShecuqcvoNbXB0V9spIqvg==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTSYA6YMWlEa6g29_d4EgN4PpGgJPoP0kt5IT7rJWbAlSa5Kt5NYaHg9lLcFMSQd9WbTfCZ-gT5-PRTAiB5HpoOgRYRV1O3_5DpK8vFJohM2TOxWqV3vsBZ-AWjgwdlryIBEghgF0-3UQRB3SBqkAeSzIoBW1lzf04O85wfklTTWpaMJYv72EhJiRUUlq_dG_x2blYLRy4gB4zfWXIburu-w2XIAzduEcpu86kLsQtU8KO6XpHJiYiaYSJSyr_U9z2BSyfdP-XyqvariBAQGVQtNRWCh-cYDIE8CO3W521AfB4y8NlTXj6nYR7DCwOoV-lhl9psvYpHDtZtpI0fyac0MHe3A-TN_IS111SnOqnptBWKVWV4iwm2HADMJHul7iQRkUjQxLrPAdnBy0iWaIejk4yLUifH8ziFWkB_ZgTdWIfELoe3qPdm6vXGyc8SSaJk0rbA3YkKc8ikrJQu58V0tm7PiuCjNO4pLSSiUyFmCBilVHPgIwmDrYWPe-Wj4WAAdj8EN7K_DLYQG2_SiLwPq9uxCl3ugWjEzok0c4Fpmmroz-dM0YZdQuZsVXeWXalcgdQn6WWohCaoTHZbM71kT8z2UcumVNXV2JR8B4vTCSI1z9NTdALzrRlZh3cbm42nv2jgdlsq8H6Aks7dn12WhvfvWxw4y9-4ztJcJ3ONmRrG-gkDW1FJXaOoqO1K5fzxSisUKlW19v9Gm_nen-hWuC8GoEuqJap2kUcu5INeBRYc0E3mGc3xQ1zvzRX_BjUjrVgalIfQDuBY6RlpjAP2EB1slGZKN6nn-Rq5Tjech-ZSSJma0yXJ5we2De6xPx5POmsNRvqV4Ef6aHpxQGUgRlChDPh17oCdKdApgGqY2INtzzBVNZGLErfq_NGvh5zZVMYzC1fDYE_cefz3mHEmDJF-zpZssiH5Upp_aA7ePPsyAdgXSy5BCrLokYbXjMqPW7av5MkR35LDNM_VG-AfhdcALDVkma3AqBrsk-PA_U0JJeAWq30-qI9Y5WdxkHxk2xBf56CPPX7-uxAR8ome5CJGfipTz8So1Hogk6qM1XWpzWStWf-h9WRf4gDVFpEb4AiEsHd_LlTtwcq6xA_myhhkiEzMdGTLZ0qYaHdllyH52KILYAeoKkWCEl1K8cbgFArPCLl_cn2cWQCchp6lIXAbtkMsqGdOcc_m4G60AYvL0xGdbb0F0Nu3Ir4XAVShecuqcvoNbXB0V9spIqvg==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTSYA6YMWlEa6g29_d4EgN4PpGgJPoP0kt5IT7rJWbAlSa5Kt5NYaHg9lLcFMSQd9WbTfCZ-gT5-PRTAiB5HpoOgRYRV1O3_5DpK8vFJohM2TOxWqV3vsBZ-AWjgwdlryIBEghgF0-3UQRB3SBqkAeSzIoBW1lzf04O85wfklTTWpaMJYv72EhJiRUUlq_dG_x2blYLRy4gB4zfWXIburu-w2XIAzduEcpu86kLsQtU8KO6XpHJiYiaYSJSyr_U9z2BSyfdP-XyqvariBAQGVQtNRWCh-cYDIE8CO3W521AfB4y8NlTXj6nYR7DCwOoV-lhl9psvYpHDtZtpI0fyac0MHe3A-TN_IS111SnOqnptBWKVWV4iwm2HADMJHul7iQRkUjQxLrPAdnBy0iWaIejk4yLUifH8ziFWkB_ZgTdWIfELoe3qPdm6vXGyc8SSaJk0rbA3YkKc8ikrJQu58V0tm7PiuCjNO4pLSSiUyFmCBilVHPgIwmDrYWPe-Wj4WAAdj8EN7K_DLYQG2_SiLwPq9uxCl3ugWjEzok0c4Fpmmroz-dM0YZdQuZsVXeWXalcgdQn6WWohCaoTHZbM71kT8z2UcumVNXV2JR8B4vTCSI1z9NTdALzrRlZh3cbm42nv2jgdlsq8H6Aks7dn12WhvfvWxw4y9-4ztJcJ3ONmRrG-gkDW1FJXaOoqO1K5fzxSisUKlW19v9Gm_nen-hWuC8GoEuqJap2kUcu5INeBRYc0E3mGc3xQ1zvzRX_BjUjrVgalIfQDuBY6RlpjAP2EB1slGZKN6nn-Rq5Tjech-ZSSJma0yXJ5we2De6xPx5POmsNRvqV4Ef6aHpxQGUgRlChDPh17oCdKdApgGqY2INtzzBVNZGLErfq_NGvh5zZVMYzC1fDYE_cefz3mHEmDJF-zpZssiH5Upp_aA7ePPsyAdgXSy5BCrLokYbXjMqPW7av5MkR35LDNM_VG-AfhdcALDVkma3AqBrsk-PA_U0JJeAWq30-qI9Y5WdxkHxk2xBf56CPPX7-uxAR8ome5CJGfipTz8So1Hogk6qM1XWpzWStWf-h9WRf4gDVFpEb4AiEsHd_LlTtwcq6xA_myhhkiEzMdGTLZ0qYaHdllyH52KILYAeoKkWCEl1K8cbgFArPCLl_cn2cWQCchp6lIXAbtkMsqGdOcc_m4G60AYvL0xGdbb0F0Nu3Ir4XAVShecuqcvoNbXB0V9spIqvg==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 998.16,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlTuCIRX4oyB79PnRL-XzscwtmfUbbs66vZHKlmVlebyi6Gjsyd15u-_4ZxyJIS3XpSh_SyfyUFDkAbtPYnz8Nq3qEMz2L5yJ-OSxJJ25Uj6yrRyIRse-jaox7h_qkRwRB0dI-9hOavNxcB7dhJzfEhVPtYwqn4coo1wrvv9n-Y_PLe9fA589gNuXct6h-nDXZiJBAXAlO6F366YmwXGXJzjq4UkvcNY-mC_JpzqR8JoK2xAfBZzQtmc1sCLpOChVKUiJ24Yy0c7VpMIaTcINAENf3RCxboySlZVMqBrmVmuCctvuoiglhl3kb1P0qDnEaD8b5Db3xgHdGh1iLxi15dhLXiTaf_q6zZ5nZuZsau84XDUHWSHdjLJE3wMYm562HY2s6OBeioFCr7G0vqQUJsku2usb-z9owt_84WE6XAd6HGzhqB_AT1LBiTQsrgCAOLJ984S4GuJKRyu1VKlXTfG0J5h-zUxj_tqbtgh1BNydAjZijpF80Ogit_npSfP8VWTMLdqTJxWLprV5X4HElG5df5Mrm9XkK55-wnX6U9luZfIIbpKQSXbdTmYLFhtotFyISBGQ2dvlqaLhXQU2PpNyQL44GbltbJ5PRhIEHOaUyxpqL4hUkm6_PuaSJP-Q-zUBxslO91rBeDoBdH76TAEaE1qd3eqiVu8DZIFvL5RVzilHEWhOkhGwy9sh8mg1Q-D9qi__bs5Dme3qHic66FLienJ2KCELsOK8Vxhtkd3Y0a1yGuHMcZCQ5vwXAExoEO7XvhQ4CvOMlVh-iJ-aX5Ts6CoMZJHAZTZkC4O6ZRAnfOQ5ZDYyxmOVpapTSINMj1ro0pABj1G3usUrWYuDYk8fDV8XincgcxoNb4wePrVTEa4HLQBnQ7gHWzAGohiZXArMvzg10WVtzgMM3mkQsjbrl5RZKwnycc3VqE7YT2s4a4xBKnmcGxZhM6_lWAO0WMPvD4e5Op7cBgQXZc-QxDfzxois9OwEITo39cosDHzeg58wCqskk20z6uoeOmR7ueiBx3Cj3dvBlezynkiW59HO5s8zA0kVjk9KzLeio0AP7qmNFk0A76zU3NBakmvA1a4NPObKVC8mdy4p-lTlnTB1or9MOe8cD3H5sAkYX3u18GdVEIcjbI_Ateen-3mIgQLTnhuYcXlrGMZK0uir1R_U4PGdC_EUtlXCAe6McW3pgh2Kv20sWUfNBkEqOyabhDocWP0anmpFUZQkfNr8yw==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlTuCIRX4oyB79PnRL-XzscwtmfUbbs66vZHKlmVlebyi6Gjsyd15u-_4ZxyJIS3XpSh_SyfyUFDkAbtPYnz8Nq3qEMz2L5yJ-OSxJJ25Uj6yrRyIRse-jaox7h_qkRwRB0dI-9hOavNxcB7dhJzfEhVPtYwqn4coo1wrvv9n-Y_PLe9fA589gNuXct6h-nDXZiJBAXAlO6F366YmwXGXJzjq4UkvcNY-mC_JpzqR8JoK2xAfBZzQtmc1sCLpOChVKUiJ24Yy0c7VpMIaTcINAENf3RCxboySlZVMqBrmVmuCctvuoiglhl3kb1P0qDnEaD8b5Db3xgHdGh1iLxi15dhLXiTaf_q6zZ5nZuZsau84XDUHWSHdjLJE3wMYm562HY2s6OBeioFCr7G0vqQUJsku2usb-z9owt_84WE6XAd6HGzhqB_AT1LBiTQsrgCAOLJ984S4GuJKRyu1VKlXTfG0J5h-zUxj_tqbtgh1BNydAjZijpF80Ogit_npSfP8VWTMLdqTJxWLprV5X4HElG5df5Mrm9XkK55-wnX6U9luZfIIbpKQSXbdTmYLFhtotFyISBGQ2dvlqaLhXQU2PpNyQL44GbltbJ5PRhIEHOaUyxpqL4hUkm6_PuaSJP-Q-zUBxslO91rBeDoBdH76TAEaE1qd3eqiVu8DZIFvL5RVzilHEWhOkhGwy9sh8mg1Q-D9qi__bs5Dme3qHic66FLienJ2KCELsOK8Vxhtkd3Y0a1yGuHMcZCQ5vwXAExoEO7XvhQ4CvOMlVh-iJ-aX5Ts6CoMZJHAZTZkC4O6ZRAnfOQ5ZDYyxmOVpapTSINMj1ro0pABj1G3usUrWYuDYk8fDV8XincgcxoNb4wePrVTEa4HLQBnQ7gHWzAGohiZXArMvzg10WVtzgMM3mkQsjbrl5RZKwnycc3VqE7YT2s4a4xBKnmcGxZhM6_lWAO0WMPvD4e5Op7cBgQXZc-QxDfzxois9OwEITo39cosDHzeg58wCqskk20z6uoeOmR7ueiBx3Cj3dvBlezynkiW59HO5s8zA0kVjk9KzLeio0AP7qmNFk0A76zU3NBakmvA1a4NPObKVC8mdy4p-lTlnTB1or9MOe8cD3H5sAkYX3u18GdVEIcjbI_Ateen-3mIgQLTnhuYcXlrGMZK0uir1R_U4PGdC_EUtlXCAe6McW3pgh2Kv20sWUfNBkEqOyabhDocWP0anmpFUZQkfNr8yw==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlTuCIRX4oyB79PnRL-XzscwtmfUbbs66vZHKlmVlebyi6Gjsyd15u-_4ZxyJIS3XpSh_SyfyUFDkAbtPYnz8Nq3qEMz2L5yJ-OSxJJ25Uj6yrRyIRse-jaox7h_qkRwRB0dI-9hOavNxcB7dhJzfEhVPtYwqn4coo1wrvv9n-Y_PLe9fA589gNuXct6h-nDXZiJBAXAlO6F366YmwXGXJzjq4UkvcNY-mC_JpzqR8JoK2xAfBZzQtmc1sCLpOChVKUiJ24Yy0c7VpMIaTcINAENf3RCxboySlZVMqBrmVmuCctvuoiglhl3kb1P0qDnEaD8b5Db3xgHdGh1iLxi15dhLXiTaf_q6zZ5nZuZsau84XDUHWSHdjLJE3wMYm562HY2s6OBeioFCr7G0vqQUJsku2usb-z9owt_84WE6XAd6HGzhqB_AT1LBiTQsrgCAOLJ984S4GuJKRyu1VKlXTfG0J5h-zUxj_tqbtgh1BNydAjZijpF80Ogit_npSfP8VWTMLdqTJxWLprV5X4HElG5df5Mrm9XkK55-wnX6U9luZfIIbpKQSXbdTmYLFhtotFyISBGQ2dvlqaLhXQU2PpNyQL44GbltbJ5PRhIEHOaUyxpqL4hUkm6_PuaSJP-Q-zUBxslO91rBeDoBdH76TAEaE1qd3eqiVu8DZIFvL5RVzilHEWhOkhGwy9sh8mg1Q-D9qi__bs5Dme3qHic66FLienJ2KCELsOK8Vxhtkd3Y0a1yGuHMcZCQ5vwXAExoEO7XvhQ4CvOMlVh-iJ-aX5Ts6CoMZJHAZTZkC4O6ZRAnfOQ5ZDYyxmOVpapTSINMj1ro0pABj1G3usUrWYuDYk8fDV8XincgcxoNb4wePrVTEa4HLQBnQ7gHWzAGohiZXArMvzg10WVtzgMM3mkQsjbrl5RZKwnycc3VqE7YT2s4a4xBKnmcGxZhM6_lWAO0WMPvD4e5Op7cBgQXZc-QxDfzxois9OwEITo39cosDHzeg58wCqskk20z6uoeOmR7ueiBx3Cj3dvBlezynkiW59HO5s8zA0kVjk9KzLeio0AP7qmNFk0A76zU3NBakmvA1a4NPObKVC8mdy4p-lTlnTB1or9MOe8cD3H5sAkYX3u18GdVEIcjbI_Ateen-3mIgQLTnhuYcXlrGMZK0uir1R_U4PGdC_EUtlXCAe6McW3pgh2Kv20sWUfNBkEqOyabhDocWP0anmpFUZQkfNr8yw==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 998.16,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzNUP1ahUzrqOCa6WStaKteqaxVSSWP9g6bjCQ9AVD0Ad53Z87SpL1Q8v2b-qGbEBmnfwTFq5DTaunryEPieeOXJIwbojzwE9-q_G2qnhu7AR-wu8STAHVBlya8JHHerKTnTCTQvEzsI06lOXIXZyot5DV7--eyo2ggnJk356w8hgp6siRCJSL0WtO7IF3syi2S9r3Mm2jujmPsONWjxr3o_ErUCQl2y5Pru6shlsAJ_cW0H63K7z4WE2iFm8h-JvMBohibUSa7evgLg2PefNQyEV52mR8-z3ZbjDYQHa8WVFCFGsc1WGM2qbcSTadTBE0DkxHITg0wVZ3xYkiH4WYM4XLOhFJPPqhs9eGv94wYssgmKJGA-MrAIRYZvXz8QjuYXTgplxMBbZ2WG4lPYG-LhIO8LDemt-IP-xMzPcgjpgaBamgz7TuAR8WdgwZq5dq2vfmDX81HMehmxnEhL8E18D3mWjd6VjoLxzIlM07Cfqm-vuWRm32FrEsjOKMxBlNuHgogOFIx6OPYlrzkJGmrqYN__UaPPIHKO5-dv492YvhfED-SkHrW9yA9hlkl0bn8s7dn1GjPdBdx4vwx5HJ7lIEM4q8TDehZdDtlfbU5eurM38_csA-Ew06egId2Cbwhw1X0iWanDmsnhp1OwOpr_GoJo38xyu0fXM49BjICJ2jwxbqTr0lPKI73gb0eOeTlZIey4oy6nX6S4TK3tV3mODLRwrnW0dDUR1bmjhjwzoofoMHQAzEcllom7Sw28rp6e7UIE1c6fHMscc9WWNL6NUzQ3dlLS8ohT4x3EBkDOkpO7SixhyX64qs9ljzIFJKxpeFSyAeWneN9xsA0sIAupAEQ2BkXw3UIl19Wx2ugvd87WqdXviGywMoCbc2D7-am2Ds06oCTdM4KWJnYQFA2Tqzh65Pn_EQdWcqNLWUFSECOxkuC8T1KKtN0Tpa3py8ZmWYj0raZw9gfSZtTkkQhz5v28BbOEUT2UETvrYJlAPTUIu8WUiWw-K5fGdNq9zFnysan5Mi9coCs3ROg2kGs65nu1UzkmW1xlOc6K4n9xGLVYB31UujtohLDykJwpuYsFu2rZbrPaRLks6pyMO06XgQyCf0TVvzwaPkwgQUDHA7XSiuyGIqsiecIWLcPcI9tlhvqBaWK_R4lvqMzilAeHGu-HP9JZRBz3tbtnBKKZWCBDxLSNMLAuIst768wDCUpdtKwk4EkTGM7a7EGRAZdR5KK_rvQpKpKA9d-S4fzk=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzNUP1ahUzrqOCa6WStaKteqaxVSSWP9g6bjCQ9AVD0Ad53Z87SpL1Q8v2b-qGbEBmnfwTFq5DTaunryEPieeOXJIwbojzwE9-q_G2qnhu7AR-wu8STAHVBlya8JHHerKTnTCTQvEzsI06lOXIXZyot5DV7--eyo2ggnJk356w8hgp6siRCJSL0WtO7IF3syi2S9r3Mm2jujmPsONWjxr3o_ErUCQl2y5Pru6shlsAJ_cW0H63K7z4WE2iFm8h-JvMBohibUSa7evgLg2PefNQyEV52mR8-z3ZbjDYQHa8WVFCFGsc1WGM2qbcSTadTBE0DkxHITg0wVZ3xYkiH4WYM4XLOhFJPPqhs9eGv94wYssgmKJGA-MrAIRYZvXz8QjuYXTgplxMBbZ2WG4lPYG-LhIO8LDemt-IP-xMzPcgjpgaBamgz7TuAR8WdgwZq5dq2vfmDX81HMehmxnEhL8E18D3mWjd6VjoLxzIlM07Cfqm-vuWRm32FrEsjOKMxBlNuHgogOFIx6OPYlrzkJGmrqYN__UaPPIHKO5-dv492YvhfED-SkHrW9yA9hlkl0bn8s7dn1GjPdBdx4vwx5HJ7lIEM4q8TDehZdDtlfbU5eurM38_csA-Ew06egId2Cbwhw1X0iWanDmsnhp1OwOpr_GoJo38xyu0fXM49BjICJ2jwxbqTr0lPKI73gb0eOeTlZIey4oy6nX6S4TK3tV3mODLRwrnW0dDUR1bmjhjwzoofoMHQAzEcllom7Sw28rp6e7UIE1c6fHMscc9WWNL6NUzQ3dlLS8ohT4x3EBkDOkpO7SixhyX64qs9ljzIFJKxpeFSyAeWneN9xsA0sIAupAEQ2BkXw3UIl19Wx2ugvd87WqdXviGywMoCbc2D7-am2Ds06oCTdM4KWJnYQFA2Tqzh65Pn_EQdWcqNLWUFSECOxkuC8T1KKtN0Tpa3py8ZmWYj0raZw9gfSZtTkkQhz5v28BbOEUT2UETvrYJlAPTUIu8WUiWw-K5fGdNq9zFnysan5Mi9coCs3ROg2kGs65nu1UzkmW1xlOc6K4n9xGLVYB31UujtohLDykJwpuYsFu2rZbrPaRLks6pyMO06XgQyCf0TVvzwaPkwgQUDHA7XSiuyGIqsiecIWLcPcI9tlhvqBaWK_R4lvqMzilAeHGu-HP9JZRBz3tbtnBKKZWCBDxLSNMLAuIst768wDCUpdtKwk4EkTGM7a7EGRAZdR5KK_rvQpKpKA9d-S4fzk=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GzNUP1ahUzrqOCa6WStaKteqaxVSSWP9g6bjCQ9AVD0Ad53Z87SpL1Q8v2b-qGbEBmnfwTFq5DTaunryEPieeOXJIwbojzwE9-q_G2qnhu7AR-wu8STAHVBlya8JHHerKTnTCTQvEzsI06lOXIXZyot5DV7--eyo2ggnJk356w8hgp6siRCJSL0WtO7IF3syi2S9r3Mm2jujmPsONWjxr3o_ErUCQl2y5Pru6shlsAJ_cW0H63K7z4WE2iFm8h-JvMBohibUSa7evgLg2PefNQyEV52mR8-z3ZbjDYQHa8WVFCFGsc1WGM2qbcSTadTBE0DkxHITg0wVZ3xYkiH4WYM4XLOhFJPPqhs9eGv94wYssgmKJGA-MrAIRYZvXz8QjuYXTgplxMBbZ2WG4lPYG-LhIO8LDemt-IP-xMzPcgjpgaBamgz7TuAR8WdgwZq5dq2vfmDX81HMehmxnEhL8E18D3mWjd6VjoLxzIlM07Cfqm-vuWRm32FrEsjOKMxBlNuHgogOFIx6OPYlrzkJGmrqYN__UaPPIHKO5-dv492YvhfED-SkHrW9yA9hlkl0bn8s7dn1GjPdBdx4vwx5HJ7lIEM4q8TDehZdDtlfbU5eurM38_csA-Ew06egId2Cbwhw1X0iWanDmsnhp1OwOpr_GoJo38xyu0fXM49BjICJ2jwxbqTr0lPKI73gb0eOeTlZIey4oy6nX6S4TK3tV3mODLRwrnW0dDUR1bmjhjwzoofoMHQAzEcllom7Sw28rp6e7UIE1c6fHMscc9WWNL6NUzQ3dlLS8ohT4x3EBkDOkpO7SixhyX64qs9ljzIFJKxpeFSyAeWneN9xsA0sIAupAEQ2BkXw3UIl19Wx2ugvd87WqdXviGywMoCbc2D7-am2Ds06oCTdM4KWJnYQFA2Tqzh65Pn_EQdWcqNLWUFSECOxkuC8T1KKtN0Tpa3py8ZmWYj0raZw9gfSZtTkkQhz5v28BbOEUT2UETvrYJlAPTUIu8WUiWw-K5fGdNq9zFnysan5Mi9coCs3ROg2kGs65nu1UzkmW1xlOc6K4n9xGLVYB31UujtohLDykJwpuYsFu2rZbrPaRLks6pyMO06XgQyCf0TVvzwaPkwgQUDHA7XSiuyGIqsiecIWLcPcI9tlhvqBaWK_R4lvqMzilAeHGu-HP9JZRBz3tbtnBKKZWCBDxLSNMLAuIst768wDCUpdtKwk4EkTGM7a7EGRAZdR5KK_rvQpKpKA9d-S4fzk=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 998.83,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-wp9s1S38SHuSQZPC8audGQnZFPww9dspXTriUj7-GQKkN7GvEdPChZMaLZfRPZnaSW1q1EaaAIIC18g1hoooJH2ryGanKX6Ao2XtCPqNWwXz-PyKUuF5JB_IAWfUzzGizS555tiNH9RZhGEOLJOVFyDzZ87kAzq-sxRf6uP6N63TjTlNNQMke3td5nxfCz4eXTfn67jblnM_2UDGB_IZgicRCP_2esRI5-q8Zz_8vxjdfXQ3iZbJ9WvzFHzbcva-FMSymwTygLRFQctHwhC_NqIC3Gg8jYFD5UL4lDHa2g8O7OyOWXpW8oymtzOJvz0RcdJl88k87sxT_6103X6ga473DUtN3Vo1543fOsBy_gbuYZLZtowEKOz93Rfj_HC0_RSbDy8M19xkAemdy7zYqNFUk3oyP4VnHp7oI8K702gn0Vx-JyfSfcsesOxQNrbAMqHhfBRGWujjLJCHrfvp2Cp2UXJ_nmRYyhQ6G5VmPW9HbjWH3EjXucjFzQ0bpNLG_tpyo2RCp-jr02od7qHIO-BkSI3VtdIJJ7flmU1iZOq9DKTHC9yZUGx6nM16DsjpW2KLW1VnzC5MOsvJefxfbj_XoccScLRyiIayQlJTfLaVuXWijR00k6GkLKDjNv9Wd1Vv1inyQ6YpuuMxYA7oi2otU8qhF17A6OLU7DtnLLa6T4HIEKSoW2pochQJoC4-1bftMaHKyItg92XxO-uGGmniqcY97V5sOKQSbJCGa-Ke6a4GsR5jtFShsg0swCwnNLDJkFbQPi6NffYX4Bhb32h5cWZw9g7jhrodh5_dWvYOdN6nieSJnyU3tzHwDIxGVDrZaQ7aU_5fopLeg9nA0n3MIUXGe0dludCSOqIl0kHMpH8Z21Ob_POOlOVdPHxOVzjLfmg9oY_0Bn3vkyu0GoNMEqxsxGsukYvw_6Kp5VRcCPOWOsRRQ0R9mFdj8sVY0ooUp8GvwXOdyU7FenscKaWeOEVHB_1aTaBESx50jiwMtTqhHLrI0_Y6Ultga27K6agiA6Oyt0WDO42CoPr5EMWS5g0zUSgGkdRX7NRp9BT2GuiJWxa3ODSarwBg6Hpxtl0ElRCFOcDTcWk4Tk7nD3Q9sSjIH2LxyzolKXJyC5HtE66F-ClvjMdQ4AbVddS-UcUHFC7aD4aiGnzY5arwzpv9Ec1omWWq_3JHx3B_Vz-uWWfxfLlQcwRMifBgVq7",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-wp9s1S38SHuSQZPC8audGQnZFPww9dspXTriUj7-GQKkN7GvEdPChZMaLZfRPZnaSW1q1EaaAIIC18g1hoooJH2ryGanKX6Ao2XtCPqNWwXz-PyKUuF5JB_IAWfUzzGizS555tiNH9RZhGEOLJOVFyDzZ87kAzq-sxRf6uP6N63TjTlNNQMke3td5nxfCz4eXTfn67jblnM_2UDGB_IZgicRCP_2esRI5-q8Zz_8vxjdfXQ3iZbJ9WvzFHzbcva-FMSymwTygLRFQctHwhC_NqIC3Gg8jYFD5UL4lDHa2g8O7OyOWXpW8oymtzOJvz0RcdJl88k87sxT_6103X6ga473DUtN3Vo1543fOsBy_gbuYZLZtowEKOz93Rfj_HC0_RSbDy8M19xkAemdy7zYqNFUk3oyP4VnHp7oI8K702gn0Vx-JyfSfcsesOxQNrbAMqHhfBRGWujjLJCHrfvp2Cp2UXJ_nmRYyhQ6G5VmPW9HbjWH3EjXucjFzQ0bpNLG_tpyo2RCp-jr02od7qHIO-BkSI3VtdIJJ7flmU1iZOq9DKTHC9yZUGx6nM16DsjpW2KLW1VnzC5MOsvJefxfbj_XoccScLRyiIayQlJTfLaVuXWijR00k6GkLKDjNv9Wd1Vv1inyQ6YpuuMxYA7oi2otU8qhF17A6OLU7DtnLLa6T4HIEKSoW2pochQJoC4-1bftMaHKyItg92XxO-uGGmniqcY97V5sOKQSbJCGa-Ke6a4GsR5jtFShsg0swCwnNLDJkFbQPi6NffYX4Bhb32h5cWZw9g7jhrodh5_dWvYOdN6nieSJnyU3tzHwDIxGVDrZaQ7aU_5fopLeg9nA0n3MIUXGe0dludCSOqIl0kHMpH8Z21Ob_POOlOVdPHxOVzjLfmg9oY_0Bn3vkyu0GoNMEqxsxGsukYvw_6Kp5VRcCPOWOsRRQ0R9mFdj8sVY0ooUp8GvwXOdyU7FenscKaWeOEVHB_1aTaBESx50jiwMtTqhHLrI0_Y6Ultga27K6agiA6Oyt0WDO42CoPr5EMWS5g0zUSgGkdRX7NRp9BT2GuiJWxa3ODSarwBg6Hpxtl0ElRCFOcDTcWk4Tk7nD3Q9sSjIH2LxyzolKXJyC5HtE66F-ClvjMdQ4AbVddS-UcUHFC7aD4aiGnzY5arwzpv9Ec1omWWq_3JHx3B_Vz-uWWfxfLlQcwRMifBgVq7",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-wp9s1S38SHuSQZPC8audGQnZFPww9dspXTriUj7-GQKkN7GvEdPChZMaLZfRPZnaSW1q1EaaAIIC18g1hoooJH2ryGanKX6Ao2XtCPqNWwXz-PyKUuF5JB_IAWfUzzGizS555tiNH9RZhGEOLJOVFyDzZ87kAzq-sxRf6uP6N63TjTlNNQMke3td5nxfCz4eXTfn67jblnM_2UDGB_IZgicRCP_2esRI5-q8Zz_8vxjdfXQ3iZbJ9WvzFHzbcva-FMSymwTygLRFQctHwhC_NqIC3Gg8jYFD5UL4lDHa2g8O7OyOWXpW8oymtzOJvz0RcdJl88k87sxT_6103X6ga473DUtN3Vo1543fOsBy_gbuYZLZtowEKOz93Rfj_HC0_RSbDy8M19xkAemdy7zYqNFUk3oyP4VnHp7oI8K702gn0Vx-JyfSfcsesOxQNrbAMqHhfBRGWujjLJCHrfvp2Cp2UXJ_nmRYyhQ6G5VmPW9HbjWH3EjXucjFzQ0bpNLG_tpyo2RCp-jr02od7qHIO-BkSI3VtdIJJ7flmU1iZOq9DKTHC9yZUGx6nM16DsjpW2KLW1VnzC5MOsvJefxfbj_XoccScLRyiIayQlJTfLaVuXWijR00k6GkLKDjNv9Wd1Vv1inyQ6YpuuMxYA7oi2otU8qhF17A6OLU7DtnLLa6T4HIEKSoW2pochQJoC4-1bftMaHKyItg92XxO-uGGmniqcY97V5sOKQSbJCGa-Ke6a4GsR5jtFShsg0swCwnNLDJkFbQPi6NffYX4Bhb32h5cWZw9g7jhrodh5_dWvYOdN6nieSJnyU3tzHwDIxGVDrZaQ7aU_5fopLeg9nA0n3MIUXGe0dludCSOqIl0kHMpH8Z21Ob_POOlOVdPHxOVzjLfmg9oY_0Bn3vkyu0GoNMEqxsxGsukYvw_6Kp5VRcCPOWOsRRQ0R9mFdj8sVY0ooUp8GvwXOdyU7FenscKaWeOEVHB_1aTaBESx50jiwMtTqhHLrI0_Y6Ultga27K6agiA6Oyt0WDO42CoPr5EMWS5g0zUSgGkdRX7NRp9BT2GuiJWxa3ODSarwBg6Hpxtl0ElRCFOcDTcWk4Tk7nD3Q9sSjIH2LxyzolKXJyC5HtE66F-ClvjMdQ4AbVddS-UcUHFC7aD4aiGnzY5arwzpv9Ec1omWWq_3JHx3B_Vz-uWWfxfLlQcwRMifBgVq7",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 999.68,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 799,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_Zusphyu6yQ1bHEcbT2b31_Yv0Wk31cqHmjZbYsBUHUGPOGFBMY0686CcK1UlLnF1klA-t6Ej8AK-rLAY4oVeM-NoPihcLZdj1gdK20zyhPJCouiup8liR6nVeA6NbGal86QQ7RDacRmnjDOe6dq6hqUZMbj4v-Kh2VCZ7FvoqTh-kgLV4goDNjq1je4V8gtqzS9tInWSPqToGepLJLQMcliW8ZwMSEmMoAbPETpuPXl6kw2jR-9lcN-4PMgczEDdgB4tiMybc0dMIwGuIA3H3SpxBFXJRfBgtP-jrdfbGe12XTpDq_IIHIuU-3Hy8QgtPE-RVhpfH9eOZIStaPgYLUvBNoD5L9ACdEsWhfgt93rVGc6hl3hvWFGZ40GvvmLODdM7JAvj2yMPmYnYZtsG_0KKcAWWTBZTP1nwC3C5Kt81-hRwUUvAmXepK8AZoa1UDaqkcNtuBE0Nk1H5UWBuEv0JWl56CjjO8-VjX3_E9J5LKWD2DmfvbKcEyvCOjEHlTaOF6eQgD_xH9eTKRjZ29U7nybu9_PKsdh15IDJY07l-nLFTNSnNblmKR568aVBTjBha1Wz-S10rSax_Gnjlpw3CfhwMVW4Fe7G4QSAHI4M-4qKyHCnVWRV_NsNnHzKjUOS2Gon4oCzmno_syr48UJzpCBxs-ywtpflUopwx9pydjIi4Jgsk10hWLgLR1hvwjbYPMfjIxBZLzOxEDJABzF6stAgGIR8ZdtBtoNEWiPetUw9sW0u3vbf3GrOHrvF5gfyd4Q1r_WoeVMbNtkxvh6u7gWNPg5tC9cYdHoYVDMnEKSqkBiak5uF8bgPR-R4Ew8AcUXlN2BFXb8kNONGQMpraB8slRBHYJ8XZAkxPKSPUHSFf-ZBjzBeruIfSv7nJSyHgvpovMVFpd08vF6MD-vg2yS3yyOqoTLrLWHXXqShnB9RQHpmtfvOWzxji3g657jSU9lFp6Ksk9Xmj1V5gzFo_nHA-hkgRSrnBzya0FAjWNLPPSBMak_EzdwwmdzBiEcu8Kna3jBf-8iB2orsXDvjGQ9RslXMnAME1oYzP7zb6-bnNf4ghscjcq65MYHvLzGaTHfMqXpuXsuzyHdBRZYBA-Kbbuns0qlbiLyZFWt8KUbsxIHOpTfPvvcw8GlpYtILI8CcrBqorkOMPRK91ebLbB79Hz5GU1KzZquG1FU8aeHvwdyHASm0KE3bOjGTou61Ttr0B6vFTiZwfpdAFQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_Zusphyu6yQ1bHEcbT2b31_Yv0Wk31cqHmjZbYsBUHUGPOGFBMY0686CcK1UlLnF1klA-t6Ej8AK-rLAY4oVeM-NoPihcLZdj1gdK20zyhPJCouiup8liR6nVeA6NbGal86QQ7RDacRmnjDOe6dq6hqUZMbj4v-Kh2VCZ7FvoqTh-kgLV4goDNjq1je4V8gtqzS9tInWSPqToGepLJLQMcliW8ZwMSEmMoAbPETpuPXl6kw2jR-9lcN-4PMgczEDdgB4tiMybc0dMIwGuIA3H3SpxBFXJRfBgtP-jrdfbGe12XTpDq_IIHIuU-3Hy8QgtPE-RVhpfH9eOZIStaPgYLUvBNoD5L9ACdEsWhfgt93rVGc6hl3hvWFGZ40GvvmLODdM7JAvj2yMPmYnYZtsG_0KKcAWWTBZTP1nwC3C5Kt81-hRwUUvAmXepK8AZoa1UDaqkcNtuBE0Nk1H5UWBuEv0JWl56CjjO8-VjX3_E9J5LKWD2DmfvbKcEyvCOjEHlTaOF6eQgD_xH9eTKRjZ29U7nybu9_PKsdh15IDJY07l-nLFTNSnNblmKR568aVBTjBha1Wz-S10rSax_Gnjlpw3CfhwMVW4Fe7G4QSAHI4M-4qKyHCnVWRV_NsNnHzKjUOS2Gon4oCzmno_syr48UJzpCBxs-ywtpflUopwx9pydjIi4Jgsk10hWLgLR1hvwjbYPMfjIxBZLzOxEDJABzF6stAgGIR8ZdtBtoNEWiPetUw9sW0u3vbf3GrOHrvF5gfyd4Q1r_WoeVMbNtkxvh6u7gWNPg5tC9cYdHoYVDMnEKSqkBiak5uF8bgPR-R4Ew8AcUXlN2BFXb8kNONGQMpraB8slRBHYJ8XZAkxPKSPUHSFf-ZBjzBeruIfSv7nJSyHgvpovMVFpd08vF6MD-vg2yS3yyOqoTLrLWHXXqShnB9RQHpmtfvOWzxji3g657jSU9lFp6Ksk9Xmj1V5gzFo_nHA-hkgRSrnBzya0FAjWNLPPSBMak_EzdwwmdzBiEcu8Kna3jBf-8iB2orsXDvjGQ9RslXMnAME1oYzP7zb6-bnNf4ghscjcq65MYHvLzGaTHfMqXpuXsuzyHdBRZYBA-Kbbuns0qlbiLyZFWt8KUbsxIHOpTfPvvcw8GlpYtILI8CcrBqorkOMPRK91ebLbB79Hz5GU1KzZquG1FU8aeHvwdyHASm0KE3bOjGTou61Ttr0B6vFTiZwfpdAFQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_Zusphyu6yQ1bHEcbT2b31_Yv0Wk31cqHmjZbYsBUHUGPOGFBMY0686CcK1UlLnF1klA-t6Ej8AK-rLAY4oVeM-NoPihcLZdj1gdK20zyhPJCouiup8liR6nVeA6NbGal86QQ7RDacRmnjDOe6dq6hqUZMbj4v-Kh2VCZ7FvoqTh-kgLV4goDNjq1je4V8gtqzS9tInWSPqToGepLJLQMcliW8ZwMSEmMoAbPETpuPXl6kw2jR-9lcN-4PMgczEDdgB4tiMybc0dMIwGuIA3H3SpxBFXJRfBgtP-jrdfbGe12XTpDq_IIHIuU-3Hy8QgtPE-RVhpfH9eOZIStaPgYLUvBNoD5L9ACdEsWhfgt93rVGc6hl3hvWFGZ40GvvmLODdM7JAvj2yMPmYnYZtsG_0KKcAWWTBZTP1nwC3C5Kt81-hRwUUvAmXepK8AZoa1UDaqkcNtuBE0Nk1H5UWBuEv0JWl56CjjO8-VjX3_E9J5LKWD2DmfvbKcEyvCOjEHlTaOF6eQgD_xH9eTKRjZ29U7nybu9_PKsdh15IDJY07l-nLFTNSnNblmKR568aVBTjBha1Wz-S10rSax_Gnjlpw3CfhwMVW4Fe7G4QSAHI4M-4qKyHCnVWRV_NsNnHzKjUOS2Gon4oCzmno_syr48UJzpCBxs-ywtpflUopwx9pydjIi4Jgsk10hWLgLR1hvwjbYPMfjIxBZLzOxEDJABzF6stAgGIR8ZdtBtoNEWiPetUw9sW0u3vbf3GrOHrvF5gfyd4Q1r_WoeVMbNtkxvh6u7gWNPg5tC9cYdHoYVDMnEKSqkBiak5uF8bgPR-R4Ew8AcUXlN2BFXb8kNONGQMpraB8slRBHYJ8XZAkxPKSPUHSFf-ZBjzBeruIfSv7nJSyHgvpovMVFpd08vF6MD-vg2yS3yyOqoTLrLWHXXqShnB9RQHpmtfvOWzxji3g657jSU9lFp6Ksk9Xmj1V5gzFo_nHA-hkgRSrnBzya0FAjWNLPPSBMak_EzdwwmdzBiEcu8Kna3jBf-8iB2orsXDvjGQ9RslXMnAME1oYzP7zb6-bnNf4ghscjcq65MYHvLzGaTHfMqXpuXsuzyHdBRZYBA-Kbbuns0qlbiLyZFWt8KUbsxIHOpTfPvvcw8GlpYtILI8CcrBqorkOMPRK91ebLbB79Hz5GU1KzZquG1FU8aeHvwdyHASm0KE3bOjGTou61Ttr0B6vFTiZwfpdAFQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "OAK",
                "duration": "29h45m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1009.21,
            "totalPrice": 799
          },
          {
            "allPassengerTotalPrice": 799,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_EnFxQITMJdZzSHdMInvRyWm9EjVX2r1tGr1dO47Ybdxz9wAqiHcM7IwNyoz3WnXhYiBcu2J8Mgd_ZO7Kj1rCuZSHk9WEn3uj0440BExgDUB39Tw85eOgLq8hFV_6WC2BwK6rRmFypFYEwoKjtoYNNw0u3aLRMDFUPq2cqACh_6NKlGgf8H05Brb67ZTue_EMHT9P_IRg8aOqDYsEB8z76srhWt6XXncRKyPXxRlpPt_ejLoxg0z60FEopD5qBGjMUfeZpTYw9mEUOzqvaRus4Ix0P2cvOG28zRrfEB-fucQvrcXntQw3k9p7kNYZqtXAU2h_D-jZdjzQPU51aavV2RzSghVGPhQDyE_P1avqhBIs86sFePJ3WbM-8PdlIkUOCdJ8NAXWoB4Mt1rzyzixwT27YK-blW8sZkTdUeBc48dR-ySF02dvBZHwZ36sjbs73ozoHI09yKSGLGBeNe1WBPF8PC2RCafSM9eix29-bIN_N-4SVaDpm1048q_s8yiwk0PWDPUmvcH6R51TFy9mA3Iou7UssbrdhmSRG5eMBtfZx40ZPekrJzGUNLF1fnF0u4lHG44cVUAiUyeADV_1Sud1bljrdhMdp99OeNmUukLFIWpqxTj6-jom4ld1mP6dqsA4QgImHDzcRts5E06MwnT51oBZO-aNz7dpxJJS_zR-vmK--YLWGytADd826FjnANAJRsTlebp1QCC3G15zagcDeMknKH3BHpIRSi0DYElQB0SU9dh0r-zyTT2WKpf0GAl6DpeIJu-5EXq2-kQ5E5TDTj9p45su0i3LhNa23SZpM2knYF79-uLOmho4ThY8-Nj78drZKBFvZRBthqHQ6A7Na6FSc4kAH3ma9KQg5g5Nv7knSrlu7MI3QhmUkaFzj4YkxRzFbbf-vwjf2HghaDVVqH4xN-Gwiyyl3JwibUo7JIfvHit3KuSs3wo7l38cLjtxc_gnWknAspmtDey2C9bno7INBaoQD6WcArba3TvrmU8DTARj1B6E-shBU8NegRZUL_f-6W_oFoH4tJzh9mOXTGcNkcOLSkuX4RDtMiA3yPqOkYKVWMcOxrOOSCU7CXXEKvV_KP8gQ9o-v-ktS57_cOn0CaoV4YwSZeXSGqLLnSJKWfq1qt4EKOSHEeo-8U8qEC5XWYkvlWdiBK06BXIH0Q_mt1sC3idcZ8hYWDFW1u5674N9iBXNePzK2aqrjPTeeTJ-VRnfrf9JeaptPCzoeLs6tPfy7ckBpU-glQ=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_EnFxQITMJdZzSHdMInvRyWm9EjVX2r1tGr1dO47Ybdxz9wAqiHcM7IwNyoz3WnXhYiBcu2J8Mgd_ZO7Kj1rCuZSHk9WEn3uj0440BExgDUB39Tw85eOgLq8hFV_6WC2BwK6rRmFypFYEwoKjtoYNNw0u3aLRMDFUPq2cqACh_6NKlGgf8H05Brb67ZTue_EMHT9P_IRg8aOqDYsEB8z76srhWt6XXncRKyPXxRlpPt_ejLoxg0z60FEopD5qBGjMUfeZpTYw9mEUOzqvaRus4Ix0P2cvOG28zRrfEB-fucQvrcXntQw3k9p7kNYZqtXAU2h_D-jZdjzQPU51aavV2RzSghVGPhQDyE_P1avqhBIs86sFePJ3WbM-8PdlIkUOCdJ8NAXWoB4Mt1rzyzixwT27YK-blW8sZkTdUeBc48dR-ySF02dvBZHwZ36sjbs73ozoHI09yKSGLGBeNe1WBPF8PC2RCafSM9eix29-bIN_N-4SVaDpm1048q_s8yiwk0PWDPUmvcH6R51TFy9mA3Iou7UssbrdhmSRG5eMBtfZx40ZPekrJzGUNLF1fnF0u4lHG44cVUAiUyeADV_1Sud1bljrdhMdp99OeNmUukLFIWpqxTj6-jom4ld1mP6dqsA4QgImHDzcRts5E06MwnT51oBZO-aNz7dpxJJS_zR-vmK--YLWGytADd826FjnANAJRsTlebp1QCC3G15zagcDeMknKH3BHpIRSi0DYElQB0SU9dh0r-zyTT2WKpf0GAl6DpeIJu-5EXq2-kQ5E5TDTj9p45su0i3LhNa23SZpM2knYF79-uLOmho4ThY8-Nj78drZKBFvZRBthqHQ6A7Na6FSc4kAH3ma9KQg5g5Nv7knSrlu7MI3QhmUkaFzj4YkxRzFbbf-vwjf2HghaDVVqH4xN-Gwiyyl3JwibUo7JIfvHit3KuSs3wo7l38cLjtxc_gnWknAspmtDey2C9bno7INBaoQD6WcArba3TvrmU8DTARj1B6E-shBU8NegRZUL_f-6W_oFoH4tJzh9mOXTGcNkcOLSkuX4RDtMiA3yPqOkYKVWMcOxrOOSCU7CXXEKvV_KP8gQ9o-v-ktS57_cOn0CaoV4YwSZeXSGqLLnSJKWfq1qt4EKOSHEeo-8U8qEC5XWYkvlWdiBK06BXIH0Q_mt1sC3idcZ8hYWDFW1u5674N9iBXNePzK2aqrjPTeeTJ-VRnfrf9JeaptPCzoeLs6tPfy7ckBpU-glQ=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_EnFxQITMJdZzSHdMInvRyWm9EjVX2r1tGr1dO47Ybdxz9wAqiHcM7IwNyoz3WnXhYiBcu2J8Mgd_ZO7Kj1rCuZSHk9WEn3uj0440BExgDUB39Tw85eOgLq8hFV_6WC2BwK6rRmFypFYEwoKjtoYNNw0u3aLRMDFUPq2cqACh_6NKlGgf8H05Brb67ZTue_EMHT9P_IRg8aOqDYsEB8z76srhWt6XXncRKyPXxRlpPt_ejLoxg0z60FEopD5qBGjMUfeZpTYw9mEUOzqvaRus4Ix0P2cvOG28zRrfEB-fucQvrcXntQw3k9p7kNYZqtXAU2h_D-jZdjzQPU51aavV2RzSghVGPhQDyE_P1avqhBIs86sFePJ3WbM-8PdlIkUOCdJ8NAXWoB4Mt1rzyzixwT27YK-blW8sZkTdUeBc48dR-ySF02dvBZHwZ36sjbs73ozoHI09yKSGLGBeNe1WBPF8PC2RCafSM9eix29-bIN_N-4SVaDpm1048q_s8yiwk0PWDPUmvcH6R51TFy9mA3Iou7UssbrdhmSRG5eMBtfZx40ZPekrJzGUNLF1fnF0u4lHG44cVUAiUyeADV_1Sud1bljrdhMdp99OeNmUukLFIWpqxTj6-jom4ld1mP6dqsA4QgImHDzcRts5E06MwnT51oBZO-aNz7dpxJJS_zR-vmK--YLWGytADd826FjnANAJRsTlebp1QCC3G15zagcDeMknKH3BHpIRSi0DYElQB0SU9dh0r-zyTT2WKpf0GAl6DpeIJu-5EXq2-kQ5E5TDTj9p45su0i3LhNa23SZpM2knYF79-uLOmho4ThY8-Nj78drZKBFvZRBthqHQ6A7Na6FSc4kAH3ma9KQg5g5Nv7knSrlu7MI3QhmUkaFzj4YkxRzFbbf-vwjf2HghaDVVqH4xN-Gwiyyl3JwibUo7JIfvHit3KuSs3wo7l38cLjtxc_gnWknAspmtDey2C9bno7INBaoQD6WcArba3TvrmU8DTARj1B6E-shBU8NegRZUL_f-6W_oFoH4tJzh9mOXTGcNkcOLSkuX4RDtMiA3yPqOkYKVWMcOxrOOSCU7CXXEKvV_KP8gQ9o-v-ktS57_cOn0CaoV4YwSZeXSGqLLnSJKWfq1qt4EKOSHEeo-8U8qEC5XWYkvlWdiBK06BXIH0Q_mt1sC3idcZ8hYWDFW1u5674N9iBXNePzK2aqrjPTeeTJ-VRnfrf9JeaptPCzoeLs6tPfy7ckBpU-glQ=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1037.26,
            "totalPrice": 799
          },
          {
            "allPassengerTotalPrice": 799,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5vKuXIw0_MUBfiHzJ9SgBqozpBbRMgEOzief5enLxsFp4E-lJ9GwnRkhYq6fe5GwRF7KsHyeGIzUSe-l8DX12JeUuv60ZJ3ZIfwAYjkJa2qPB0AI1z0VdvqNwUjysmcLA2duZZU71upx8z3bn_Ntvo2U85Ib6mVfJCX8Y6S_PEpCz4ri8GGAwM4KhuE1-kvk8zOzsldncv6yEKcRAKklImVL61aMDvPbOG576S4H8clGQb2lpKgFG9BvlOzLuHCwMRsFWhR3hLAUOp-YDR6DxvOR69Mm2m06qFJd3w94fpwcA9jjcbKyXC1zfrU-0el2cCE3r0Z5_VFF1Q3NI4Bv5sGr_VkCkklg1ScB5lfutKs0RXIcP85xArfD35rfTTmUHm8Fqm48KdLS2QSEUg5hM0XD20OnOwp1HzDq4wtMKFwUZwP7ECLU3NrEQjXNiytHI1McwempVB-x0WtyMqqvpgD3VrrbUpXEUJGDTW1OKDt79AK4-fz7UGPBSF19cfKJkvJv7gyhTtNKbuF_eDTzTP2MRnvlC2JTdr4b6rcLoqCe-ED6rpiA3Rv3vw5AnrZnGp18A4P6BBSyfBAM_gzFbds4o2w5kXCUmt9d865qgeB4eQ-6ibQHCqbU2X8dBJLTRMDRIViEQPtaI6CZq_CCvt2uK9YscAm4V67k10QK0aoeBkbU7wjAkLc6jFoFn9xw438v4db5E6Edc4mkuq8s5figSo2SVnGmjql8yZR2BE29SMp-ALQnx4ycM9ZdOART0-zdeIOFA4gthkqnX-R4EewTdf8Wg3Kzhai_9cDJRgLw4SMXoK61LPnstFxFVpmnBQZiLo9bGzFmKYCBZPoAvApSFYsR_BabjgdImEpyRQ764cu25IX17MnY4tOZCIltHHaYRHIZaaTfzfqUY3kY4cCXMkT_EXEauLa3tOpxO4hber_jJR3Lyh3X6J0j_cLS6N3k1Y15h_amHo-JqaA5vqHvfXpi5SmM53KXZ5FZDTykthpxH3Bdun2S1Uy05ukOU4tmLypvw8FU3CKGNIeQzjaSskNj7CZcdsK0JTB-UVReRisukGOHNNNtTNdUXFGyMzUVHAMKCrTmzlgV00EKgq6SVAbH58UsTEE4P-9ga_NfylCXQjLoscGLPEz0xeUG4Zkc0Bu2afFmsxIUOK9mWxie8FZkKWFZidDtc-rwzCT6r3Kdnxj2P_codk6sKa91vSZeZG-ILF9JuVdY8q11fBp5MsC4qfTfIZpXw_Uo9UM=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5vKuXIw0_MUBfiHzJ9SgBqozpBbRMgEOzief5enLxsFp4E-lJ9GwnRkhYq6fe5GwRF7KsHyeGIzUSe-l8DX12JeUuv60ZJ3ZIfwAYjkJa2qPB0AI1z0VdvqNwUjysmcLA2duZZU71upx8z3bn_Ntvo2U85Ib6mVfJCX8Y6S_PEpCz4ri8GGAwM4KhuE1-kvk8zOzsldncv6yEKcRAKklImVL61aMDvPbOG576S4H8clGQb2lpKgFG9BvlOzLuHCwMRsFWhR3hLAUOp-YDR6DxvOR69Mm2m06qFJd3w94fpwcA9jjcbKyXC1zfrU-0el2cCE3r0Z5_VFF1Q3NI4Bv5sGr_VkCkklg1ScB5lfutKs0RXIcP85xArfD35rfTTmUHm8Fqm48KdLS2QSEUg5hM0XD20OnOwp1HzDq4wtMKFwUZwP7ECLU3NrEQjXNiytHI1McwempVB-x0WtyMqqvpgD3VrrbUpXEUJGDTW1OKDt79AK4-fz7UGPBSF19cfKJkvJv7gyhTtNKbuF_eDTzTP2MRnvlC2JTdr4b6rcLoqCe-ED6rpiA3Rv3vw5AnrZnGp18A4P6BBSyfBAM_gzFbds4o2w5kXCUmt9d865qgeB4eQ-6ibQHCqbU2X8dBJLTRMDRIViEQPtaI6CZq_CCvt2uK9YscAm4V67k10QK0aoeBkbU7wjAkLc6jFoFn9xw438v4db5E6Edc4mkuq8s5figSo2SVnGmjql8yZR2BE29SMp-ALQnx4ycM9ZdOART0-zdeIOFA4gthkqnX-R4EewTdf8Wg3Kzhai_9cDJRgLw4SMXoK61LPnstFxFVpmnBQZiLo9bGzFmKYCBZPoAvApSFYsR_BabjgdImEpyRQ764cu25IX17MnY4tOZCIltHHaYRHIZaaTfzfqUY3kY4cCXMkT_EXEauLa3tOpxO4hber_jJR3Lyh3X6J0j_cLS6N3k1Y15h_amHo-JqaA5vqHvfXpi5SmM53KXZ5FZDTykthpxH3Bdun2S1Uy05ukOU4tmLypvw8FU3CKGNIeQzjaSskNj7CZcdsK0JTB-UVReRisukGOHNNNtTNdUXFGyMzUVHAMKCrTmzlgV00EKgq6SVAbH58UsTEE4P-9ga_NfylCXQjLoscGLPEz0xeUG4Zkc0Bu2afFmsxIUOK9mWxie8FZkKWFZidDtc-rwzCT6r3Kdnxj2P_codk6sKa91vSZeZG-ILF9JuVdY8q11fBp5MsC4qfTfIZpXw_Uo9UM=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5vKuXIw0_MUBfiHzJ9SgBqozpBbRMgEOzief5enLxsFp4E-lJ9GwnRkhYq6fe5GwRF7KsHyeGIzUSe-l8DX12JeUuv60ZJ3ZIfwAYjkJa2qPB0AI1z0VdvqNwUjysmcLA2duZZU71upx8z3bn_Ntvo2U85Ib6mVfJCX8Y6S_PEpCz4ri8GGAwM4KhuE1-kvk8zOzsldncv6yEKcRAKklImVL61aMDvPbOG576S4H8clGQb2lpKgFG9BvlOzLuHCwMRsFWhR3hLAUOp-YDR6DxvOR69Mm2m06qFJd3w94fpwcA9jjcbKyXC1zfrU-0el2cCE3r0Z5_VFF1Q3NI4Bv5sGr_VkCkklg1ScB5lfutKs0RXIcP85xArfD35rfTTmUHm8Fqm48KdLS2QSEUg5hM0XD20OnOwp1HzDq4wtMKFwUZwP7ECLU3NrEQjXNiytHI1McwempVB-x0WtyMqqvpgD3VrrbUpXEUJGDTW1OKDt79AK4-fz7UGPBSF19cfKJkvJv7gyhTtNKbuF_eDTzTP2MRnvlC2JTdr4b6rcLoqCe-ED6rpiA3Rv3vw5AnrZnGp18A4P6BBSyfBAM_gzFbds4o2w5kXCUmt9d865qgeB4eQ-6ibQHCqbU2X8dBJLTRMDRIViEQPtaI6CZq_CCvt2uK9YscAm4V67k10QK0aoeBkbU7wjAkLc6jFoFn9xw438v4db5E6Edc4mkuq8s5figSo2SVnGmjql8yZR2BE29SMp-ALQnx4ycM9ZdOART0-zdeIOFA4gthkqnX-R4EewTdf8Wg3Kzhai_9cDJRgLw4SMXoK61LPnstFxFVpmnBQZiLo9bGzFmKYCBZPoAvApSFYsR_BabjgdImEpyRQ764cu25IX17MnY4tOZCIltHHaYRHIZaaTfzfqUY3kY4cCXMkT_EXEauLa3tOpxO4hber_jJR3Lyh3X6J0j_cLS6N3k1Y15h_amHo-JqaA5vqHvfXpi5SmM53KXZ5FZDTykthpxH3Bdun2S1Uy05ukOU4tmLypvw8FU3CKGNIeQzjaSskNj7CZcdsK0JTB-UVReRisukGOHNNNtTNdUXFGyMzUVHAMKCrTmzlgV00EKgq6SVAbH58UsTEE4P-9ga_NfylCXQjLoscGLPEz0xeUG4Zkc0Bu2afFmsxIUOK9mWxie8FZkKWFZidDtc-rwzCT6r3Kdnxj2P_codk6sKa91vSZeZG-ILF9JuVdY8q11fBp5MsC4qfTfIZpXw_Uo9UM=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1037.26,
            "totalPrice": 799
          },
          {
            "allPassengerTotalPrice": 799,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs_lbyu7FP3-oFSQl-1_XpNt-w9RntpY_cOkx0X4ib6Qc36TwiO26hfU1-bcSCI2kwmHTNkzsaajJu_mMRn43ybOL1gCrPIORtt7OtBxBZ1-x2fhtqnUJG0qEvkzc93B_wS5As01pZZn-0c9ilvfd6r4j4tV6qYmgLicLcnlftfli2wpUkeRS4kJ316Y7x84ac3-JshefefZxvFY8CcrYsOAQ0Q_G4IrYzop8iatFWf_y_vQqsraS_p47E7C1maK8iIWsP39S1qCfVs75rVhzK1p_PVycLgJSWJWAPGDjxsZlGHaCd8jhozRHrdj2fo8BWYnazMkGqZKSDaabQVZCDZWsvIfwPjGfJmQxI-fqOHlYgIIFmlHs_LCDFxXsLhZj52jWB2RYmCej9ZQgQEWwEK89Bq_nWOCAU8uXlvoTh3ILwtsmIdsRhxENBtgPXW0JGvDaI-I-4dHo7l-mBIIbEnHgICTS68RlR2IuqmYPR_Jsx2tfyEGrd83gaL6oYPMcB6pyceKMsFQ4atfFk_LIvfgcK9gBu6bsFupx-1sl3PxhzUcxdIFiTgvBMSD1dtT8AJHhWjFHAGw9fLr5wmBji0JYO_JBU5WhANPjchNUPX9i25-coH5sTMpa0w7PR3VOuthDhZgtC2elUgja8lXUCIlo1LIL3P1C-_uGRnuQdkEhLtWXd_pd_Fy02VXutMwPFj-HeHKmc2_a5SRq6Ba2C03rFUBFerGFmSsErmuCnybL5vvHUgbhxTyKhRNuHewTF2zBiuTMzaArHC-QJ3ll1N8G5OTTy1jzi6tqctJHJPlE8i6sQoxeyvR_Fo9RkQ-oV4CzLkpznMcNB6gibr3uXW9MtZpktHmwWbcTU7LL1CrTbDLuL5WrBBbm0ygldQEziGH0gFhpEj3KUPeexkisXBIEasDUvs8DxUgNZ5I3xWvrqGUlOt2NtqFsO9UYFgWRVZQMhD4EXa15cBbuiPlFn_jbXz-Z8DSAJMyebqqcvu1AjGpu1rJxGH5JeCQIxovs5VYe4cm1bFn8ywDkGYeAAVR5KkmwZK_UvDU7cdiFbptAgtpw0lCCG5DLu4RK7QVJkS47lA9etcJI6_GP4qHLlPDJCGe2tPGMehwiQnNUNR2aCmLQ3INZuUpKC9-j-iHns8LpMuExoZzcdcfzg5FGO-LtqnHroHGfLM03jz-K7UYKUTVtXDza6v7oRzJqqJGwQitjk1rcP9gPv-3zrD9X1S4v9nbOzTyONuAkTu6OVtg9aMh2WNYGiIamM87cG2An",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs_lbyu7FP3-oFSQl-1_XpNt-w9RntpY_cOkx0X4ib6Qc36TwiO26hfU1-bcSCI2kwmHTNkzsaajJu_mMRn43ybOL1gCrPIORtt7OtBxBZ1-x2fhtqnUJG0qEvkzc93B_wS5As01pZZn-0c9ilvfd6r4j4tV6qYmgLicLcnlftfli2wpUkeRS4kJ316Y7x84ac3-JshefefZxvFY8CcrYsOAQ0Q_G4IrYzop8iatFWf_y_vQqsraS_p47E7C1maK8iIWsP39S1qCfVs75rVhzK1p_PVycLgJSWJWAPGDjxsZlGHaCd8jhozRHrdj2fo8BWYnazMkGqZKSDaabQVZCDZWsvIfwPjGfJmQxI-fqOHlYgIIFmlHs_LCDFxXsLhZj52jWB2RYmCej9ZQgQEWwEK89Bq_nWOCAU8uXlvoTh3ILwtsmIdsRhxENBtgPXW0JGvDaI-I-4dHo7l-mBIIbEnHgICTS68RlR2IuqmYPR_Jsx2tfyEGrd83gaL6oYPMcB6pyceKMsFQ4atfFk_LIvfgcK9gBu6bsFupx-1sl3PxhzUcxdIFiTgvBMSD1dtT8AJHhWjFHAGw9fLr5wmBji0JYO_JBU5WhANPjchNUPX9i25-coH5sTMpa0w7PR3VOuthDhZgtC2elUgja8lXUCIlo1LIL3P1C-_uGRnuQdkEhLtWXd_pd_Fy02VXutMwPFj-HeHKmc2_a5SRq6Ba2C03rFUBFerGFmSsErmuCnybL5vvHUgbhxTyKhRNuHewTF2zBiuTMzaArHC-QJ3ll1N8G5OTTy1jzi6tqctJHJPlE8i6sQoxeyvR_Fo9RkQ-oV4CzLkpznMcNB6gibr3uXW9MtZpktHmwWbcTU7LL1CrTbDLuL5WrBBbm0ygldQEziGH0gFhpEj3KUPeexkisXBIEasDUvs8DxUgNZ5I3xWvrqGUlOt2NtqFsO9UYFgWRVZQMhD4EXa15cBbuiPlFn_jbXz-Z8DSAJMyebqqcvu1AjGpu1rJxGH5JeCQIxovs5VYe4cm1bFn8ywDkGYeAAVR5KkmwZK_UvDU7cdiFbptAgtpw0lCCG5DLu4RK7QVJkS47lA9etcJI6_GP4qHLlPDJCGe2tPGMehwiQnNUNR2aCmLQ3INZuUpKC9-j-iHns8LpMuExoZzcdcfzg5FGO-LtqnHroHGfLM03jz-K7UYKUTVtXDza6v7oRzJqqJGwQitjk1rcP9gPv-3zrD9X1S4v9nbOzTyONuAkTu6OVtg9aMh2WNYGiIamM87cG2An",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs_lbyu7FP3-oFSQl-1_XpNt-w9RntpY_cOkx0X4ib6Qc36TwiO26hfU1-bcSCI2kwmHTNkzsaajJu_mMRn43ybOL1gCrPIORtt7OtBxBZ1-x2fhtqnUJG0qEvkzc93B_wS5As01pZZn-0c9ilvfd6r4j4tV6qYmgLicLcnlftfli2wpUkeRS4kJ316Y7x84ac3-JshefefZxvFY8CcrYsOAQ0Q_G4IrYzop8iatFWf_y_vQqsraS_p47E7C1maK8iIWsP39S1qCfVs75rVhzK1p_PVycLgJSWJWAPGDjxsZlGHaCd8jhozRHrdj2fo8BWYnazMkGqZKSDaabQVZCDZWsvIfwPjGfJmQxI-fqOHlYgIIFmlHs_LCDFxXsLhZj52jWB2RYmCej9ZQgQEWwEK89Bq_nWOCAU8uXlvoTh3ILwtsmIdsRhxENBtgPXW0JGvDaI-I-4dHo7l-mBIIbEnHgICTS68RlR2IuqmYPR_Jsx2tfyEGrd83gaL6oYPMcB6pyceKMsFQ4atfFk_LIvfgcK9gBu6bsFupx-1sl3PxhzUcxdIFiTgvBMSD1dtT8AJHhWjFHAGw9fLr5wmBji0JYO_JBU5WhANPjchNUPX9i25-coH5sTMpa0w7PR3VOuthDhZgtC2elUgja8lXUCIlo1LIL3P1C-_uGRnuQdkEhLtWXd_pd_Fy02VXutMwPFj-HeHKmc2_a5SRq6Ba2C03rFUBFerGFmSsErmuCnybL5vvHUgbhxTyKhRNuHewTF2zBiuTMzaArHC-QJ3ll1N8G5OTTy1jzi6tqctJHJPlE8i6sQoxeyvR_Fo9RkQ-oV4CzLkpznMcNB6gibr3uXW9MtZpktHmwWbcTU7LL1CrTbDLuL5WrBBbm0ygldQEziGH0gFhpEj3KUPeexkisXBIEasDUvs8DxUgNZ5I3xWvrqGUlOt2NtqFsO9UYFgWRVZQMhD4EXa15cBbuiPlFn_jbXz-Z8DSAJMyebqqcvu1AjGpu1rJxGH5JeCQIxovs5VYe4cm1bFn8ywDkGYeAAVR5KkmwZK_UvDU7cdiFbptAgtpw0lCCG5DLu4RK7QVJkS47lA9etcJI6_GP4qHLlPDJCGe2tPGMehwiQnNUNR2aCmLQ3INZuUpKC9-j-iHns8LpMuExoZzcdcfzg5FGO-LtqnHroHGfLM03jz-K7UYKUTVtXDza6v7oRzJqqJGwQitjk1rcP9gPv-3zrD9X1S4v9nbOzTyONuAkTu6OVtg9aMh2WNYGiIamM87cG2An",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1037.92,
            "totalPrice": 799
          },
          {
            "allPassengerTotalPrice": 799,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gegna5OQKXMAKLCpsWnzPtt1Tv3SNud04Sxhx6d5_qbVc-q9VB3n8wdYswxststS50Mgr8k8k8VG2N3gGeW9DkPF3tPA5bSX0ROBfkVtrLjtd22qG4znlBTkOi-EqxBbwo7sRPrgtFNSoe3Zqmc0UBO2cENNCxADaRqoNd-8dw5BDLDId6RD6XthtqlPyXmlGOgF3u7gBdZVgUtpwiTESE9gt9gnGr85vB6zQcAEV8Ql-MiQnbn9ijgeWTNDMUCDFvgXh9_3qScLtcwPq-rNAKh86-14TsrdA2t5GJvXtZLcb6RURIqcqLpTB6SfR2_GM3gkSJ3Lzw_1XVg_x8BuThXdZSNoOi4fWOxyhWn1VIIdwoGx41x1haptluq9kG9EuX7JW1WSMZ9NCEBIEGn4mYU34VW0c2mMMW-D63GEtvBZtT3kbI-sHmjotcNiwvbWHBU9Ez6Ygm2DLvrSWgyYqVfForP39sxU_okt-TgAKYKMt1k99UdGAp_hh5fOGbDVkSP-al6nzDMFN4PCiAzZj5r-_dqamLVFIsu1c9F7BKUpOi6osErXZquDK8ru3incp7_lDvshh3Dj4lDMehRrmbsWlgzyoNAyveWbJtjZDk_jkkWdCNC3IlWJIwBZsmAOVUbgvRnPJvHDWRj6j0RivjouDPQIPCDo-Wh-KiAh8s2GwTBlmDP1_RP5fY7vA0eGhvwbReAZiYmmvw2cdyCjBbZzmgtoG5NrPI0fXo_xdsfEE5VKSyD9pFeuDMc6syxfvfc51KYpJuSmqi-1H0rGrjCeZNb77gfnXshpibdxjmFF0YcDaaavmHlfVFXhbpP17ckn_Smmx6gPm8UTQMurL4zY90xVt8PP_YeRC9eXX-BNxRS8_V4iD2CcV0xXgGsLGUZcmz-7KZPx26JaNi_WaBIIzp4XODENWTFuYw-vk22WUKY0BK80oi_pks8fzvP-uw65LNcygaS5rYSbRnGaqLl07gN1HRXBDpMnlWydNgflOGOdDPBGAWg2LOkCVexQ9dNDZCvmT1azvrj_pDcwdHOoIKHMT1vlEnoly6-VL7ky-glxfY0E04rEzc7x-zMfbqc54z_RgU3a2_G7TZmiLsJroPzfEfsda96ORJjm_k2bHMK_3HY-ffk6F8UQXYKjexfEXRED4YKleBlw2xX4fAONTydfUNNo3nvqLwLaL37Xj6ntimhPkLQJLXqA3csa6XqdNFwd2ZSKm50kmgqVl2Q==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gegna5OQKXMAKLCpsWnzPtt1Tv3SNud04Sxhx6d5_qbVc-q9VB3n8wdYswxststS50Mgr8k8k8VG2N3gGeW9DkPF3tPA5bSX0ROBfkVtrLjtd22qG4znlBTkOi-EqxBbwo7sRPrgtFNSoe3Zqmc0UBO2cENNCxADaRqoNd-8dw5BDLDId6RD6XthtqlPyXmlGOgF3u7gBdZVgUtpwiTESE9gt9gnGr85vB6zQcAEV8Ql-MiQnbn9ijgeWTNDMUCDFvgXh9_3qScLtcwPq-rNAKh86-14TsrdA2t5GJvXtZLcb6RURIqcqLpTB6SfR2_GM3gkSJ3Lzw_1XVg_x8BuThXdZSNoOi4fWOxyhWn1VIIdwoGx41x1haptluq9kG9EuX7JW1WSMZ9NCEBIEGn4mYU34VW0c2mMMW-D63GEtvBZtT3kbI-sHmjotcNiwvbWHBU9Ez6Ygm2DLvrSWgyYqVfForP39sxU_okt-TgAKYKMt1k99UdGAp_hh5fOGbDVkSP-al6nzDMFN4PCiAzZj5r-_dqamLVFIsu1c9F7BKUpOi6osErXZquDK8ru3incp7_lDvshh3Dj4lDMehRrmbsWlgzyoNAyveWbJtjZDk_jkkWdCNC3IlWJIwBZsmAOVUbgvRnPJvHDWRj6j0RivjouDPQIPCDo-Wh-KiAh8s2GwTBlmDP1_RP5fY7vA0eGhvwbReAZiYmmvw2cdyCjBbZzmgtoG5NrPI0fXo_xdsfEE5VKSyD9pFeuDMc6syxfvfc51KYpJuSmqi-1H0rGrjCeZNb77gfnXshpibdxjmFF0YcDaaavmHlfVFXhbpP17ckn_Smmx6gPm8UTQMurL4zY90xVt8PP_YeRC9eXX-BNxRS8_V4iD2CcV0xXgGsLGUZcmz-7KZPx26JaNi_WaBIIzp4XODENWTFuYw-vk22WUKY0BK80oi_pks8fzvP-uw65LNcygaS5rYSbRnGaqLl07gN1HRXBDpMnlWydNgflOGOdDPBGAWg2LOkCVexQ9dNDZCvmT1azvrj_pDcwdHOoIKHMT1vlEnoly6-VL7ky-glxfY0E04rEzc7x-zMfbqc54z_RgU3a2_G7TZmiLsJroPzfEfsda96ORJjm_k2bHMK_3HY-ffk6F8UQXYKjexfEXRED4YKleBlw2xX4fAONTydfUNNo3nvqLwLaL37Xj6ntimhPkLQJLXqA3csa6XqdNFwd2ZSKm50kmgqVl2Q==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gegna5OQKXMAKLCpsWnzPtt1Tv3SNud04Sxhx6d5_qbVc-q9VB3n8wdYswxststS50Mgr8k8k8VG2N3gGeW9DkPF3tPA5bSX0ROBfkVtrLjtd22qG4znlBTkOi-EqxBbwo7sRPrgtFNSoe3Zqmc0UBO2cENNCxADaRqoNd-8dw5BDLDId6RD6XthtqlPyXmlGOgF3u7gBdZVgUtpwiTESE9gt9gnGr85vB6zQcAEV8Ql-MiQnbn9ijgeWTNDMUCDFvgXh9_3qScLtcwPq-rNAKh86-14TsrdA2t5GJvXtZLcb6RURIqcqLpTB6SfR2_GM3gkSJ3Lzw_1XVg_x8BuThXdZSNoOi4fWOxyhWn1VIIdwoGx41x1haptluq9kG9EuX7JW1WSMZ9NCEBIEGn4mYU34VW0c2mMMW-D63GEtvBZtT3kbI-sHmjotcNiwvbWHBU9Ez6Ygm2DLvrSWgyYqVfForP39sxU_okt-TgAKYKMt1k99UdGAp_hh5fOGbDVkSP-al6nzDMFN4PCiAzZj5r-_dqamLVFIsu1c9F7BKUpOi6osErXZquDK8ru3incp7_lDvshh3Dj4lDMehRrmbsWlgzyoNAyveWbJtjZDk_jkkWdCNC3IlWJIwBZsmAOVUbgvRnPJvHDWRj6j0RivjouDPQIPCDo-Wh-KiAh8s2GwTBlmDP1_RP5fY7vA0eGhvwbReAZiYmmvw2cdyCjBbZzmgtoG5NrPI0fXo_xdsfEE5VKSyD9pFeuDMc6syxfvfc51KYpJuSmqi-1H0rGrjCeZNb77gfnXshpibdxjmFF0YcDaaavmHlfVFXhbpP17ckn_Smmx6gPm8UTQMurL4zY90xVt8PP_YeRC9eXX-BNxRS8_V4iD2CcV0xXgGsLGUZcmz-7KZPx26JaNi_WaBIIzp4XODENWTFuYw-vk22WUKY0BK80oi_pks8fzvP-uw65LNcygaS5rYSbRnGaqLl07gN1HRXBDpMnlWydNgflOGOdDPBGAWg2LOkCVexQ9dNDZCvmT1azvrj_pDcwdHOoIKHMT1vlEnoly6-VL7ky-glxfY0E04rEzc7x-zMfbqc54z_RgU3a2_G7TZmiLsJroPzfEfsda96ORJjm_k2bHMK_3HY-ffk6F8UQXYKjexfEXRED4YKleBlw2xX4fAONTydfUNNo3nvqLwLaL37Xj6ntimhPkLQJLXqA3csa6XqdNFwd2ZSKm50kmgqVl2Q==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1038.77,
            "totalPrice": 799
          },
          {
            "allPassengerTotalPrice": 801,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNzHq35llVTaUiOeWnjbOwXkgkzkzdYQuBcTw6LggoodZ7F-cWHCLs5d2vJyPyile1ZNu-p5Uvfd6BW4GQSV8qEKDqm-OJPPaL5TyDIP2TKl04HQeX18KwEv9BBmMWRQ5HWnlSkT6-7Ml81ZLfdn6JBOdu8MH2aXmWPxUYsCMOB0vvKaZvLiO00y4DTGeDADgo4pH0k26in1EvvvJjL3eQOJDUGj2aDqlDH_n3tJfXqywNY7BYQks8wfZMLVpv2M8X_xMwhFUVfnt6890Td2QKuC7CQhc1gD6fnLfFX3LBCSTmF_8J-CWamz1St31MPlKPBGVQH7ewIr_wQllPLUw2o0JAiYr1i49t9nemighp46s51WVBY_pJLg8umIWBY-_bccMv-3pVfp-7uUS7Oq02Oemmq10eqn768wOSPhRTTJpTnjDXUR6B_i1EYJzZHmgzk3zZNur0O21Hh_KtIDjwIlP0EmW2Ch_xFVExbV53AtLg-kPBBcI19FZBDWTr1rYwagHtz_ei1cU6H4c_LzVyeY85TtMTh5ikNb9-M3_-mBKFhtsd9MkyG6pZKPDEATii9s9Ao7LqWg4LnX3MgzSceOSabaSTh9s2xXshrTc0bk4FWH8Q-6zXCsmVjSHftvElbd9zv5L9DF2m0IhfHXBZycRfWBvR5dEopO3_tMsROf-rYCvVzKujwIIJ2Wlqe-NOwVSelDuAD5TUzNnKYQUxPJ9NCI6WP5kHxDP2ZKCXXVprvOoI_-qrZpZ0eTKzFWIUn7YqFgv0CTZc3b7zCxhAppJH2fawnX6rqGBKGRGslUOLmYgrlAeULnt2jgWDIZbnbx9w_2UOauga2KtlAcMjPG84mwFhoZkUVvtX11VVLNXu5SEr3aozTYMYStBaZWkW5_G75HYEipIRsoIpVuA8Hg0MpTJWiEhsShcYPHZ50scwc6l6pGib_Bz9qqTfOLTedwbie163J1iLGz5rBHqPmhCOCzK1F8LhsHZ2PNg4DwYQgcZsUxSF8mTkiwdVIc7yhEQJhnZL2cjikMkLhAnt3mOlXp-XpvTQdY0HLSkbdVx71vExHgCemCQy9P88VQsJcMXlhV9z2FkZ2iqstXZIhbDDM3hD3tV8DoDvGMiNZzo8AYxfd4zQ4M4W6T_gF16eyhOsFImsFE8MdyTIBiB_u0z0coIEkxnaw72PtjmZvh0QH4qhwnPa8nmywH4739MqLCo1Q0aIirQgPwT0mwXwStoWBJoNZwfSbteid5jQnc=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNzHq35llVTaUiOeWnjbOwXkgkzkzdYQuBcTw6LggoodZ7F-cWHCLs5d2vJyPyile1ZNu-p5Uvfd6BW4GQSV8qEKDqm-OJPPaL5TyDIP2TKl04HQeX18KwEv9BBmMWRQ5HWnlSkT6-7Ml81ZLfdn6JBOdu8MH2aXmWPxUYsCMOB0vvKaZvLiO00y4DTGeDADgo4pH0k26in1EvvvJjL3eQOJDUGj2aDqlDH_n3tJfXqywNY7BYQks8wfZMLVpv2M8X_xMwhFUVfnt6890Td2QKuC7CQhc1gD6fnLfFX3LBCSTmF_8J-CWamz1St31MPlKPBGVQH7ewIr_wQllPLUw2o0JAiYr1i49t9nemighp46s51WVBY_pJLg8umIWBY-_bccMv-3pVfp-7uUS7Oq02Oemmq10eqn768wOSPhRTTJpTnjDXUR6B_i1EYJzZHmgzk3zZNur0O21Hh_KtIDjwIlP0EmW2Ch_xFVExbV53AtLg-kPBBcI19FZBDWTr1rYwagHtz_ei1cU6H4c_LzVyeY85TtMTh5ikNb9-M3_-mBKFhtsd9MkyG6pZKPDEATii9s9Ao7LqWg4LnX3MgzSceOSabaSTh9s2xXshrTc0bk4FWH8Q-6zXCsmVjSHftvElbd9zv5L9DF2m0IhfHXBZycRfWBvR5dEopO3_tMsROf-rYCvVzKujwIIJ2Wlqe-NOwVSelDuAD5TUzNnKYQUxPJ9NCI6WP5kHxDP2ZKCXXVprvOoI_-qrZpZ0eTKzFWIUn7YqFgv0CTZc3b7zCxhAppJH2fawnX6rqGBKGRGslUOLmYgrlAeULnt2jgWDIZbnbx9w_2UOauga2KtlAcMjPG84mwFhoZkUVvtX11VVLNXu5SEr3aozTYMYStBaZWkW5_G75HYEipIRsoIpVuA8Hg0MpTJWiEhsShcYPHZ50scwc6l6pGib_Bz9qqTfOLTedwbie163J1iLGz5rBHqPmhCOCzK1F8LhsHZ2PNg4DwYQgcZsUxSF8mTkiwdVIc7yhEQJhnZL2cjikMkLhAnt3mOlXp-XpvTQdY0HLSkbdVx71vExHgCemCQy9P88VQsJcMXlhV9z2FkZ2iqstXZIhbDDM3hD3tV8DoDvGMiNZzo8AYxfd4zQ4M4W6T_gF16eyhOsFImsFE8MdyTIBiB_u0z0coIEkxnaw72PtjmZvh0QH4qhwnPa8nmywH4739MqLCo1Q0aIirQgPwT0mwXwStoWBJoNZwfSbteid5jQnc=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNzHq35llVTaUiOeWnjbOwXkgkzkzdYQuBcTw6LggoodZ7F-cWHCLs5d2vJyPyile1ZNu-p5Uvfd6BW4GQSV8qEKDqm-OJPPaL5TyDIP2TKl04HQeX18KwEv9BBmMWRQ5HWnlSkT6-7Ml81ZLfdn6JBOdu8MH2aXmWPxUYsCMOB0vvKaZvLiO00y4DTGeDADgo4pH0k26in1EvvvJjL3eQOJDUGj2aDqlDH_n3tJfXqywNY7BYQks8wfZMLVpv2M8X_xMwhFUVfnt6890Td2QKuC7CQhc1gD6fnLfFX3LBCSTmF_8J-CWamz1St31MPlKPBGVQH7ewIr_wQllPLUw2o0JAiYr1i49t9nemighp46s51WVBY_pJLg8umIWBY-_bccMv-3pVfp-7uUS7Oq02Oemmq10eqn768wOSPhRTTJpTnjDXUR6B_i1EYJzZHmgzk3zZNur0O21Hh_KtIDjwIlP0EmW2Ch_xFVExbV53AtLg-kPBBcI19FZBDWTr1rYwagHtz_ei1cU6H4c_LzVyeY85TtMTh5ikNb9-M3_-mBKFhtsd9MkyG6pZKPDEATii9s9Ao7LqWg4LnX3MgzSceOSabaSTh9s2xXshrTc0bk4FWH8Q-6zXCsmVjSHftvElbd9zv5L9DF2m0IhfHXBZycRfWBvR5dEopO3_tMsROf-rYCvVzKujwIIJ2Wlqe-NOwVSelDuAD5TUzNnKYQUxPJ9NCI6WP5kHxDP2ZKCXXVprvOoI_-qrZpZ0eTKzFWIUn7YqFgv0CTZc3b7zCxhAppJH2fawnX6rqGBKGRGslUOLmYgrlAeULnt2jgWDIZbnbx9w_2UOauga2KtlAcMjPG84mwFhoZkUVvtX11VVLNXu5SEr3aozTYMYStBaZWkW5_G75HYEipIRsoIpVuA8Hg0MpTJWiEhsShcYPHZ50scwc6l6pGib_Bz9qqTfOLTedwbie163J1iLGz5rBHqPmhCOCzK1F8LhsHZ2PNg4DwYQgcZsUxSF8mTkiwdVIc7yhEQJhnZL2cjikMkLhAnt3mOlXp-XpvTQdY0HLSkbdVx71vExHgCemCQy9P88VQsJcMXlhV9z2FkZ2iqstXZIhbDDM3hD3tV8DoDvGMiNZzo8AYxfd4zQ4M4W6T_gF16eyhOsFImsFE8MdyTIBiB_u0z0coIEkxnaw72PtjmZvh0QH4qhwnPa8nmywH4739MqLCo1Q0aIirQgPwT0mwXwStoWBJoNZwfSbteid5jQnc=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SJC",
                "duration": "34h25m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1048.3,
            "totalPrice": 801
          },
          {
            "allPassengerTotalPrice": 804,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ghs8gRDrc5wVcA7gpmxvBMj08aiuBg3KXd2-635eLPlqdrOQzE4CixN5lrN6zl1_tYtJguwRUZeWqUIAaBX__SIJ92WAacp4PltPwV7BkkQ21RKtcOjRc2FJJrXcOLm5VshWSRlImTB0QEKn_7aNxXvRFxCzVP-FSGRyI7-Dp3qQQ8OaePLxGW9uNQZooUblc3OMWf9Mq02diKJb7IgfkD10FG10DiQdXc04_fxkqBLWCmHa3ETS144UZ0-E9dD9UnbleEsAW0aBsYBylrkPKo3G3MTMTBezQFi-lKiEYZaYTKZXFcf-8zYKMLPA0UP_wITr9NewCUAQmyS-az4chEpiKbF4hi5nR6-g-iV4HW2-OqhCGMFIdFgt-Gfb7w_0SORVfRwdlBb11utSvXCTsTiXh9w2gh7cgWqBuxMlyNLWOpNGRnrusxMXjJRuS7DlH74g9Ti6mm6lFIG6VRvnw4nmCLz1I-7v1EKwZvuYFI8Fb-YLN31khJbwGnoMonVwMkodMSwJOFhkZkvv1YikIELpLnkNE3wzIjJS8qabA-uaKxH0nmeIJMXFFGFFsuTmFh3Zc59hFB1IdIQJ1-J3mdNoBcG3GqqdoKiOfLzaNiG7wLdX_D9tAKxht1iBKtwrc0jo-GYefEjWqL9rpTry9f6IbJB-990ljLFyLnoQ2Pv5EdDcjvfXPRBXF-T3JNUytk6oww_FIFzRx8xk5rDVhzm-1WC6ZJEKDTptHGRFCLAUsDahU_HRfE161Gn-EfvReMWyeMMHYOxewDwDt6mJs3uMhhwe6r_DPFywBj8dVZLhmtqZD-q-HKHKq0A1PmZ72PUz63YG46ws0qLy1cFMk4dSoVXM1q9bZprGXxqG9t_TobSA7334mG0CS_cV4NMzHtJ3yZgVyBpaZDUuHMbPJ2ztVyJsxYTXcC4w8h2CzCQFo0KSWF-VezWLlgZcl9rHcEzCRYd_oUi4VFPqA3xEEDdIVag-6dETo8E5N6lCF4IN-4w2EUPCAKM1jPGAsrigG2frxAvBGl70ec9PFQiKPE9oybQ5H3F8RV0I5ngghVDlu4kemEzYYNzXrZCqNGREH5sd-8Xt4-axfbWGmA8oRxz1my0qRXRl8D0aiX0NlyIFeYm_qbjAut8eF5WQUQ6uhTvDlI9FeiqQNyUMBlhBfqK34uwfcKgS-F-YTCuTY1I-7IsCJQ0Wlaz8ZxjH3FBVAjBxgHnzyyioIRApc2XbFUA==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ghs8gRDrc5wVcA7gpmxvBMj08aiuBg3KXd2-635eLPlqdrOQzE4CixN5lrN6zl1_tYtJguwRUZeWqUIAaBX__SIJ92WAacp4PltPwV7BkkQ21RKtcOjRc2FJJrXcOLm5VshWSRlImTB0QEKn_7aNxXvRFxCzVP-FSGRyI7-Dp3qQQ8OaePLxGW9uNQZooUblc3OMWf9Mq02diKJb7IgfkD10FG10DiQdXc04_fxkqBLWCmHa3ETS144UZ0-E9dD9UnbleEsAW0aBsYBylrkPKo3G3MTMTBezQFi-lKiEYZaYTKZXFcf-8zYKMLPA0UP_wITr9NewCUAQmyS-az4chEpiKbF4hi5nR6-g-iV4HW2-OqhCGMFIdFgt-Gfb7w_0SORVfRwdlBb11utSvXCTsTiXh9w2gh7cgWqBuxMlyNLWOpNGRnrusxMXjJRuS7DlH74g9Ti6mm6lFIG6VRvnw4nmCLz1I-7v1EKwZvuYFI8Fb-YLN31khJbwGnoMonVwMkodMSwJOFhkZkvv1YikIELpLnkNE3wzIjJS8qabA-uaKxH0nmeIJMXFFGFFsuTmFh3Zc59hFB1IdIQJ1-J3mdNoBcG3GqqdoKiOfLzaNiG7wLdX_D9tAKxht1iBKtwrc0jo-GYefEjWqL9rpTry9f6IbJB-990ljLFyLnoQ2Pv5EdDcjvfXPRBXF-T3JNUytk6oww_FIFzRx8xk5rDVhzm-1WC6ZJEKDTptHGRFCLAUsDahU_HRfE161Gn-EfvReMWyeMMHYOxewDwDt6mJs3uMhhwe6r_DPFywBj8dVZLhmtqZD-q-HKHKq0A1PmZ72PUz63YG46ws0qLy1cFMk4dSoVXM1q9bZprGXxqG9t_TobSA7334mG0CS_cV4NMzHtJ3yZgVyBpaZDUuHMbPJ2ztVyJsxYTXcC4w8h2CzCQFo0KSWF-VezWLlgZcl9rHcEzCRYd_oUi4VFPqA3xEEDdIVag-6dETo8E5N6lCF4IN-4w2EUPCAKM1jPGAsrigG2frxAvBGl70ec9PFQiKPE9oybQ5H3F8RV0I5ngghVDlu4kemEzYYNzXrZCqNGREH5sd-8Xt4-axfbWGmA8oRxz1my0qRXRl8D0aiX0NlyIFeYm_qbjAut8eF5WQUQ6uhTvDlI9FeiqQNyUMBlhBfqK34uwfcKgS-F-YTCuTY1I-7IsCJQ0Wlaz8ZxjH3FBVAjBxgHnzyyioIRApc2XbFUA==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ghs8gRDrc5wVcA7gpmxvBMj08aiuBg3KXd2-635eLPlqdrOQzE4CixN5lrN6zl1_tYtJguwRUZeWqUIAaBX__SIJ92WAacp4PltPwV7BkkQ21RKtcOjRc2FJJrXcOLm5VshWSRlImTB0QEKn_7aNxXvRFxCzVP-FSGRyI7-Dp3qQQ8OaePLxGW9uNQZooUblc3OMWf9Mq02diKJb7IgfkD10FG10DiQdXc04_fxkqBLWCmHa3ETS144UZ0-E9dD9UnbleEsAW0aBsYBylrkPKo3G3MTMTBezQFi-lKiEYZaYTKZXFcf-8zYKMLPA0UP_wITr9NewCUAQmyS-az4chEpiKbF4hi5nR6-g-iV4HW2-OqhCGMFIdFgt-Gfb7w_0SORVfRwdlBb11utSvXCTsTiXh9w2gh7cgWqBuxMlyNLWOpNGRnrusxMXjJRuS7DlH74g9Ti6mm6lFIG6VRvnw4nmCLz1I-7v1EKwZvuYFI8Fb-YLN31khJbwGnoMonVwMkodMSwJOFhkZkvv1YikIELpLnkNE3wzIjJS8qabA-uaKxH0nmeIJMXFFGFFsuTmFh3Zc59hFB1IdIQJ1-J3mdNoBcG3GqqdoKiOfLzaNiG7wLdX_D9tAKxht1iBKtwrc0jo-GYefEjWqL9rpTry9f6IbJB-990ljLFyLnoQ2Pv5EdDcjvfXPRBXF-T3JNUytk6oww_FIFzRx8xk5rDVhzm-1WC6ZJEKDTptHGRFCLAUsDahU_HRfE161Gn-EfvReMWyeMMHYOxewDwDt6mJs3uMhhwe6r_DPFywBj8dVZLhmtqZD-q-HKHKq0A1PmZ72PUz63YG46ws0qLy1cFMk4dSoVXM1q9bZprGXxqG9t_TobSA7334mG0CS_cV4NMzHtJ3yZgVyBpaZDUuHMbPJ2ztVyJsxYTXcC4w8h2CzCQFo0KSWF-VezWLlgZcl9rHcEzCRYd_oUi4VFPqA3xEEDdIVag-6dETo8E5N6lCF4IN-4w2EUPCAKM1jPGAsrigG2frxAvBGl70ec9PFQiKPE9oybQ5H3F8RV0I5ngghVDlu4kemEzYYNzXrZCqNGREH5sd-8Xt4-axfbWGmA8oRxz1my0qRXRl8D0aiX0NlyIFeYm_qbjAut8eF5WQUQ6uhTvDlI9FeiqQNyUMBlhBfqK34uwfcKgS-F-YTCuTY1I-7IsCJQ0Wlaz8ZxjH3FBVAjBxgHnzyyioIRApc2XbFUA==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1004.61,
            "totalPrice": 804
          },
          {
            "allPassengerTotalPrice": 805,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_mRr1qx4O7cEzwfxFoa5KWIDBShirCfdRODBruHyo20Lu1bR4Hr3RCXYSRCosP7QD2Z6Z69YRh7EVEc4_SXTfEIG5xyq6tU4EcMr_jUbu-hFUu-O1zh-fdzoyWUHkRgsrReHc6rg1H7qBOeJNzOeiDFk_9PualfH-t-taPSaPY9n-Xqz8BELjBZNJTS6p3FgHIQYpBW59XymNwZVL3q6Yuj0844O5m0-gfGyx4c25HVfFYDspojCEgvir6D8UHbQVnULleA6AsTMy_BQ5LY3x2o1x3WOXJ2-8-IFUn2TVhbF5qxCAmCfQPSLt4N7CUDQwlLT82o5f7sI-54VstKBHT_qWbOqL1Ey6iJnpG0C3kpTaG6QQGEHeKAFtZIQe_Pyils_Z9H5C6d9xScv40HIx8WB2iaQLs6cvP5Lmviae4TRK91YtpFNN983ocG0L5dhO45xLPudD6M3triMsubmipndxiRJqh8yGn7fmlhMhPqJUSwqRulojhAnDtptuDxt2qepFlzOwGvL068nVD-S7obwsdReTK8CVmZmSTUdegbQj05ffrTZ_5Sp0-CvcF_-VZFwjTw0e28-XOQMnB4rTHjigo2DsLBUcHLveot2tcY9soc2DGEnYRtxhGLq4xI0amLJLU8rJy_tCtTjRgyyXx-cHn99N5_pj4aU4cthM1ZCv8QJ8LdY06b75wY5WiJNuy3zX_2MIgbP7z6qdMKAM0Yo3NazWPGiEkyZCT_SE_lF7nYSRPXTFNl4oeyaWhSm9HZUE7GifFZGF_ZoPWI50JyiaJfMmlbNRXn41jBpSlugfWPPRmiY3YFI2gx761X9-xjRPSP29FNAFaZXYpNm3NMn8P03bxasRmWFM9NSBTin1AzdS2yNNJ7nNgtvbzsqYFX2fFa1z5qrOBEX4g15oARC195F6RPUh6cVlXYQBwACLe4gDBArTfuYKk9HIj20ix8ZaHDzAdxl0yfY8CWEr0nRtFTCR_RCQB0bOACO7G6_Lwu2zbHhbUSK503kzAesDFgXLAmReK6LPyo4WBXZ4HcwHj4FiOnxtnRiEwLGm6OxAuv_LMsGkKDqysV-Y9P2LURNtkuxZi-kutn6ILZ43bzFPATK0PBaAqZnEH87YLP2pVhVa5Y78s4cEuMNonjDjqEe3QX_O3maqlG1WKWcblTcfC1tz2j6AVe97-YkWvIPusuhZnaUuuNM7rtWFpJETmRMP9rnRyx2RuK0GTpAdYfbUkDvfizyOQijOdK9eGo12o0qhzTnz9uL6dqA0KmOwQGaL5koMVbCN_7AJSJ0ttzyjIRFV7qNJbC7szgzwrM=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_mRr1qx4O7cEzwfxFoa5KWIDBShirCfdRODBruHyo20Lu1bR4Hr3RCXYSRCosP7QD2Z6Z69YRh7EVEc4_SXTfEIG5xyq6tU4EcMr_jUbu-hFUu-O1zh-fdzoyWUHkRgsrReHc6rg1H7qBOeJNzOeiDFk_9PualfH-t-taPSaPY9n-Xqz8BELjBZNJTS6p3FgHIQYpBW59XymNwZVL3q6Yuj0844O5m0-gfGyx4c25HVfFYDspojCEgvir6D8UHbQVnULleA6AsTMy_BQ5LY3x2o1x3WOXJ2-8-IFUn2TVhbF5qxCAmCfQPSLt4N7CUDQwlLT82o5f7sI-54VstKBHT_qWbOqL1Ey6iJnpG0C3kpTaG6QQGEHeKAFtZIQe_Pyils_Z9H5C6d9xScv40HIx8WB2iaQLs6cvP5Lmviae4TRK91YtpFNN983ocG0L5dhO45xLPudD6M3triMsubmipndxiRJqh8yGn7fmlhMhPqJUSwqRulojhAnDtptuDxt2qepFlzOwGvL068nVD-S7obwsdReTK8CVmZmSTUdegbQj05ffrTZ_5Sp0-CvcF_-VZFwjTw0e28-XOQMnB4rTHjigo2DsLBUcHLveot2tcY9soc2DGEnYRtxhGLq4xI0amLJLU8rJy_tCtTjRgyyXx-cHn99N5_pj4aU4cthM1ZCv8QJ8LdY06b75wY5WiJNuy3zX_2MIgbP7z6qdMKAM0Yo3NazWPGiEkyZCT_SE_lF7nYSRPXTFNl4oeyaWhSm9HZUE7GifFZGF_ZoPWI50JyiaJfMmlbNRXn41jBpSlugfWPPRmiY3YFI2gx761X9-xjRPSP29FNAFaZXYpNm3NMn8P03bxasRmWFM9NSBTin1AzdS2yNNJ7nNgtvbzsqYFX2fFa1z5qrOBEX4g15oARC195F6RPUh6cVlXYQBwACLe4gDBArTfuYKk9HIj20ix8ZaHDzAdxl0yfY8CWEr0nRtFTCR_RCQB0bOACO7G6_Lwu2zbHhbUSK503kzAesDFgXLAmReK6LPyo4WBXZ4HcwHj4FiOnxtnRiEwLGm6OxAuv_LMsGkKDqysV-Y9P2LURNtkuxZi-kutn6ILZ43bzFPATK0PBaAqZnEH87YLP2pVhVa5Y78s4cEuMNonjDjqEe3QX_O3maqlG1WKWcblTcfC1tz2j6AVe97-YkWvIPusuhZnaUuuNM7rtWFpJETmRMP9rnRyx2RuK0GTpAdYfbUkDvfizyOQijOdK9eGo12o0qhzTnz9uL6dqA0KmOwQGaL5koMVbCN_7AJSJ0ttzyjIRFV7qNJbC7szgzwrM=",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_mRr1qx4O7cEzwfxFoa5KWIDBShirCfdRODBruHyo20Lu1bR4Hr3RCXYSRCosP7QD2Z6Z69YRh7EVEc4_SXTfEIG5xyq6tU4EcMr_jUbu-hFUu-O1zh-fdzoyWUHkRgsrReHc6rg1H7qBOeJNzOeiDFk_9PualfH-t-taPSaPY9n-Xqz8BELjBZNJTS6p3FgHIQYpBW59XymNwZVL3q6Yuj0844O5m0-gfGyx4c25HVfFYDspojCEgvir6D8UHbQVnULleA6AsTMy_BQ5LY3x2o1x3WOXJ2-8-IFUn2TVhbF5qxCAmCfQPSLt4N7CUDQwlLT82o5f7sI-54VstKBHT_qWbOqL1Ey6iJnpG0C3kpTaG6QQGEHeKAFtZIQe_Pyils_Z9H5C6d9xScv40HIx8WB2iaQLs6cvP5Lmviae4TRK91YtpFNN983ocG0L5dhO45xLPudD6M3triMsubmipndxiRJqh8yGn7fmlhMhPqJUSwqRulojhAnDtptuDxt2qepFlzOwGvL068nVD-S7obwsdReTK8CVmZmSTUdegbQj05ffrTZ_5Sp0-CvcF_-VZFwjTw0e28-XOQMnB4rTHjigo2DsLBUcHLveot2tcY9soc2DGEnYRtxhGLq4xI0amLJLU8rJy_tCtTjRgyyXx-cHn99N5_pj4aU4cthM1ZCv8QJ8LdY06b75wY5WiJNuy3zX_2MIgbP7z6qdMKAM0Yo3NazWPGiEkyZCT_SE_lF7nYSRPXTFNl4oeyaWhSm9HZUE7GifFZGF_ZoPWI50JyiaJfMmlbNRXn41jBpSlugfWPPRmiY3YFI2gx761X9-xjRPSP29FNAFaZXYpNm3NMn8P03bxasRmWFM9NSBTin1AzdS2yNNJ7nNgtvbzsqYFX2fFa1z5qrOBEX4g15oARC195F6RPUh6cVlXYQBwACLe4gDBArTfuYKk9HIj20ix8ZaHDzAdxl0yfY8CWEr0nRtFTCR_RCQB0bOACO7G6_Lwu2zbHhbUSK503kzAesDFgXLAmReK6LPyo4WBXZ4HcwHj4FiOnxtnRiEwLGm6OxAuv_LMsGkKDqysV-Y9P2LURNtkuxZi-kutn6ILZ43bzFPATK0PBaAqZnEH87YLP2pVhVa5Y78s4cEuMNonjDjqEe3QX_O3maqlG1WKWcblTcfC1tz2j6AVe97-YkWvIPusuhZnaUuuNM7rtWFpJETmRMP9rnRyx2RuK0GTpAdYfbUkDvfizyOQijOdK9eGo12o0qhzTnz9uL6dqA0KmOwQGaL5koMVbCN_7AJSJ0ttzyjIRFV7qNJbC7szgzwrM=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1071.83,
            "totalPrice": 805
          },
          {
            "allPassengerTotalPrice": 806,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gw2aZAPD_CD1SRaRKpX-JcKI5Ku_RSh2_tfL20Nk57grMxmpFqzgPdioGz9RDepejWBchj_HUrYC7_R8kxac0u4Vp2-FfCZy3gq4Op6n7omhKnuuvL6RS-QVj5iCYJfrIGjuLvKJpTwdUjM1B6zk25UKsrU-ROv_X3QtmRM9N8-bZ3S_aohlI6asqDEgxrLwPkrB5P0qIxk6EjaAbBz7WGRs9VYtdJxqAdBSDhzMxm0b2LgonPkFpJG67Mh_I5QUz2V-VDv_Ac5UMLviecHVTrVVaXmvTVI30NYTYjGHl2x0cNK8ehBY_gvrI62cmD4Wj3MJSmFlp2stZpFwiqLvmqixRyGwVQGu43cR72cmyXM6iSFfOnnKk2l8MYPJkOI6uR_9SIPbFRYyTx2b8TKCCQudYMWV0Q0_1H8ckeR5Bj87GV120aB9ojZfPdNceRrr4lRe1F1xuLxnC3SanD6N7xvhLZydDVE5IC-BCGjtsLZNRlI5s5aoRdXyu80ysIqpkDCBtlfi_fK9xUfsrnVWK7jl01Bi59aU_umWgfKMeqT9sKB6bg3eN1ou4p-SqgYyla3gczpy_MJK41EFG5DfxIJBewB5_EnspW2HQO3Aagq3_jhOm9_LtCxAu5QBaXtbUSj3tDeWfWc2tmVUily6b80ZeeXEAARIZ2Cvj784M1peq_TYP5aiI-3QI6qJsuMDdFoqcUanjut8FKjt_OYYhvyjH4kLuAFSApPWgTd6AR3mDAolSzsdeCRLYe9_VU6x7c22CEuCZ67ziqs3P7FckiZSwk0djroNXCClvLrtmn538IIkwX4uj7OwCEu6bOnX3tn8mHhjk3FqJOWq_lMxzEKbrKW6Z724tAzEnzSAxX2_qPwJh5QupaHxqCf2u3NEdtokeoj-d0Wuf7KSIPLQvozBljL-UDF9FZiuQmJ09a-IO_-JIq-Sxh_wqeQDySudX3Ww1-LjqR3CW0tqiJoUcSoSu2lhCmB4KfLPL9wmWaJKy8DJbG_rKdN55qV4WhwTdH9sTcBsOxo9Jv-DGcVlBqiJ_tYkIlIhst0g6xxW44hPXXZDsdhS_AmIWwwSlbLT-5zMrC8cve3rTGmu2ipieUwIyl3H1VSp1HTCblgn880B1sS1v_vKRJ0ZewDVFUaGbXCUtQ_Lma0-a3bQ_JNt8f6ZOBQvx6CEhvEZE6YdOWQ3irGl3vRk_3jILGXTjopCWUI7QUf0Aw97asQFqmpsVSJO5dDmqN2fiRispWqgqtnw=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gw2aZAPD_CD1SRaRKpX-JcKI5Ku_RSh2_tfL20Nk57grMxmpFqzgPdioGz9RDepejWBchj_HUrYC7_R8kxac0u4Vp2-FfCZy3gq4Op6n7omhKnuuvL6RS-QVj5iCYJfrIGjuLvKJpTwdUjM1B6zk25UKsrU-ROv_X3QtmRM9N8-bZ3S_aohlI6asqDEgxrLwPkrB5P0qIxk6EjaAbBz7WGRs9VYtdJxqAdBSDhzMxm0b2LgonPkFpJG67Mh_I5QUz2V-VDv_Ac5UMLviecHVTrVVaXmvTVI30NYTYjGHl2x0cNK8ehBY_gvrI62cmD4Wj3MJSmFlp2stZpFwiqLvmqixRyGwVQGu43cR72cmyXM6iSFfOnnKk2l8MYPJkOI6uR_9SIPbFRYyTx2b8TKCCQudYMWV0Q0_1H8ckeR5Bj87GV120aB9ojZfPdNceRrr4lRe1F1xuLxnC3SanD6N7xvhLZydDVE5IC-BCGjtsLZNRlI5s5aoRdXyu80ysIqpkDCBtlfi_fK9xUfsrnVWK7jl01Bi59aU_umWgfKMeqT9sKB6bg3eN1ou4p-SqgYyla3gczpy_MJK41EFG5DfxIJBewB5_EnspW2HQO3Aagq3_jhOm9_LtCxAu5QBaXtbUSj3tDeWfWc2tmVUily6b80ZeeXEAARIZ2Cvj784M1peq_TYP5aiI-3QI6qJsuMDdFoqcUanjut8FKjt_OYYhvyjH4kLuAFSApPWgTd6AR3mDAolSzsdeCRLYe9_VU6x7c22CEuCZ67ziqs3P7FckiZSwk0djroNXCClvLrtmn538IIkwX4uj7OwCEu6bOnX3tn8mHhjk3FqJOWq_lMxzEKbrKW6Z724tAzEnzSAxX2_qPwJh5QupaHxqCf2u3NEdtokeoj-d0Wuf7KSIPLQvozBljL-UDF9FZiuQmJ09a-IO_-JIq-Sxh_wqeQDySudX3Ww1-LjqR3CW0tqiJoUcSoSu2lhCmB4KfLPL9wmWaJKy8DJbG_rKdN55qV4WhwTdH9sTcBsOxo9Jv-DGcVlBqiJ_tYkIlIhst0g6xxW44hPXXZDsdhS_AmIWwwSlbLT-5zMrC8cve3rTGmu2ipieUwIyl3H1VSp1HTCblgn880B1sS1v_vKRJ0ZewDVFUaGbXCUtQ_Lma0-a3bQ_JNt8f6ZOBQvx6CEhvEZE6YdOWQ3irGl3vRk_3jILGXTjopCWUI7QUf0Aw97asQFqmpsVSJO5dDmqN2fiRispWqgqtnw=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gw2aZAPD_CD1SRaRKpX-JcKI5Ku_RSh2_tfL20Nk57grMxmpFqzgPdioGz9RDepejWBchj_HUrYC7_R8kxac0u4Vp2-FfCZy3gq4Op6n7omhKnuuvL6RS-QVj5iCYJfrIGjuLvKJpTwdUjM1B6zk25UKsrU-ROv_X3QtmRM9N8-bZ3S_aohlI6asqDEgxrLwPkrB5P0qIxk6EjaAbBz7WGRs9VYtdJxqAdBSDhzMxm0b2LgonPkFpJG67Mh_I5QUz2V-VDv_Ac5UMLviecHVTrVVaXmvTVI30NYTYjGHl2x0cNK8ehBY_gvrI62cmD4Wj3MJSmFlp2stZpFwiqLvmqixRyGwVQGu43cR72cmyXM6iSFfOnnKk2l8MYPJkOI6uR_9SIPbFRYyTx2b8TKCCQudYMWV0Q0_1H8ckeR5Bj87GV120aB9ojZfPdNceRrr4lRe1F1xuLxnC3SanD6N7xvhLZydDVE5IC-BCGjtsLZNRlI5s5aoRdXyu80ysIqpkDCBtlfi_fK9xUfsrnVWK7jl01Bi59aU_umWgfKMeqT9sKB6bg3eN1ou4p-SqgYyla3gczpy_MJK41EFG5DfxIJBewB5_EnspW2HQO3Aagq3_jhOm9_LtCxAu5QBaXtbUSj3tDeWfWc2tmVUily6b80ZeeXEAARIZ2Cvj784M1peq_TYP5aiI-3QI6qJsuMDdFoqcUanjut8FKjt_OYYhvyjH4kLuAFSApPWgTd6AR3mDAolSzsdeCRLYe9_VU6x7c22CEuCZ67ziqs3P7FckiZSwk0djroNXCClvLrtmn538IIkwX4uj7OwCEu6bOnX3tn8mHhjk3FqJOWq_lMxzEKbrKW6Z724tAzEnzSAxX2_qPwJh5QupaHxqCf2u3NEdtokeoj-d0Wuf7KSIPLQvozBljL-UDF9FZiuQmJ09a-IO_-JIq-Sxh_wqeQDySudX3Ww1-LjqR3CW0tqiJoUcSoSu2lhCmB4KfLPL9wmWaJKy8DJbG_rKdN55qV4WhwTdH9sTcBsOxo9Jv-DGcVlBqiJ_tYkIlIhst0g6xxW44hPXXZDsdhS_AmIWwwSlbLT-5zMrC8cve3rTGmu2ipieUwIyl3H1VSp1HTCblgn880B1sS1v_vKRJ0ZewDVFUaGbXCUtQ_Lma0-a3bQ_JNt8f6ZOBQvx6CEhvEZE6YdOWQ3irGl3vRk_3jILGXTjopCWUI7QUf0Aw97asQFqmpsVSJO5dDmqN2fiRispWqgqtnw=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1006.02,
            "totalPrice": 806
          },
          {
            "allPassengerTotalPrice": 806,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3zJXRUk3dGBCokivekKq5G8WTBwRerQ0e-aeVI_5VHKm2hf_Wf0KqSNWxD5RBQQNTtbsyd-kpHVvvR-RNuNQGMfgBqWrVsBX_Y-EudSJfeTZwk7FMmeyVOSOHDbFYVrMIUZjobqyB1oqVEbYVKnKGirVJb9yzKNTo4pEc5s1zbGm3TmjLhkqUvi--nkOtTKw0oz6t0VlJIR8YuLv0Myh1mrr47GqcU-39f9iTv-KZ-HGtWaHIA-iyMpmkdljk7ggY8h-NolLrSBjCX-3swfouYeQjwWrXxnCiZSqp2v6kD4SyTXzNTtFXP1FzuSHV3i3T4QPJA_lIUwtC8fHJdZGHIgUXnzL_MCtx5ZEAcBFOTldzLK-zXkSld9pnOu7rcbT3QFGc72eCVYV5-c_JeKfSmZI5MU9Gus3p3C-ejxnZCQZVOd7xVG-G3AJVSoBv2NzHaCTBt2f4S-rTkHxv8sW7oxisfYOjWtKRQ3_JXQLCDy8ubmfOWXUDVXqXcoPztQUcP0zuLJ8fing9qfOvvlPvAtG--PbaeRdj2SSYs75HuxsyGMtYXxVYT8qWTz9Mfq4lE34iLtXOg930XN0z0hviTWyHEuORbpXfHSYiTvuqB82b-R4dZK1Hc7JaYtm8FHvKeKiv-tydgk59mbkeMQn1sOy39oyO1bMUmxiyM3mcWZOeW3Vl_cX8K-v7dXBC3CTPTJ_lwJKCtsBW-dL2iV6ixjU6T7r3ZN48pYoZyV6tIrAeprhUmN8PexFH7uGZ1wZ1S89qa2YnaMW4vFdZ17cwWYtdjCqFLvKckO0BimKRnc5nj1AgXdy5b046GPSZ1YuCdfU08wSy_ZxvV0FiSkRNlRKnYjOeqHsMxh2gQFO0UU7Wy-D9qmvgoWSMUFz3JthC4QZuCbDf6dsOKk_wyPDzKV7iZsvwElWG1Z1MDx_jFDFM1ysDTutEL9rpYJ8OXGfawYj3HaUB5uise4Wq1R-i6awEEsuZ4L3CDkfAPGd4nn7WyXBhb5lYlr1mh5iwc9QSI6XqLcGPMgah-yFOy3mfHiGVt-2gd0mueRJAebw48ldDroqN_JXuWECFDpECjXdoCflpxhZkeuxXbvHgLzsTA44gOB2cCv09GdGaUd0vIjqfCS34m8DBRvfzeiW3H87Wd0IZ1u5sUlo8PhKGJln6KUFgH0niX9LUUr985b_Ss6w6WUS6_7I2GuT8N9mvFQ0Ib_IzhiFWcC82scFNQaV6diOdH0MxBjpUCvGjWP95wA=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3zJXRUk3dGBCokivekKq5G8WTBwRerQ0e-aeVI_5VHKm2hf_Wf0KqSNWxD5RBQQNTtbsyd-kpHVvvR-RNuNQGMfgBqWrVsBX_Y-EudSJfeTZwk7FMmeyVOSOHDbFYVrMIUZjobqyB1oqVEbYVKnKGirVJb9yzKNTo4pEc5s1zbGm3TmjLhkqUvi--nkOtTKw0oz6t0VlJIR8YuLv0Myh1mrr47GqcU-39f9iTv-KZ-HGtWaHIA-iyMpmkdljk7ggY8h-NolLrSBjCX-3swfouYeQjwWrXxnCiZSqp2v6kD4SyTXzNTtFXP1FzuSHV3i3T4QPJA_lIUwtC8fHJdZGHIgUXnzL_MCtx5ZEAcBFOTldzLK-zXkSld9pnOu7rcbT3QFGc72eCVYV5-c_JeKfSmZI5MU9Gus3p3C-ejxnZCQZVOd7xVG-G3AJVSoBv2NzHaCTBt2f4S-rTkHxv8sW7oxisfYOjWtKRQ3_JXQLCDy8ubmfOWXUDVXqXcoPztQUcP0zuLJ8fing9qfOvvlPvAtG--PbaeRdj2SSYs75HuxsyGMtYXxVYT8qWTz9Mfq4lE34iLtXOg930XN0z0hviTWyHEuORbpXfHSYiTvuqB82b-R4dZK1Hc7JaYtm8FHvKeKiv-tydgk59mbkeMQn1sOy39oyO1bMUmxiyM3mcWZOeW3Vl_cX8K-v7dXBC3CTPTJ_lwJKCtsBW-dL2iV6ixjU6T7r3ZN48pYoZyV6tIrAeprhUmN8PexFH7uGZ1wZ1S89qa2YnaMW4vFdZ17cwWYtdjCqFLvKckO0BimKRnc5nj1AgXdy5b046GPSZ1YuCdfU08wSy_ZxvV0FiSkRNlRKnYjOeqHsMxh2gQFO0UU7Wy-D9qmvgoWSMUFz3JthC4QZuCbDf6dsOKk_wyPDzKV7iZsvwElWG1Z1MDx_jFDFM1ysDTutEL9rpYJ8OXGfawYj3HaUB5uise4Wq1R-i6awEEsuZ4L3CDkfAPGd4nn7WyXBhb5lYlr1mh5iwc9QSI6XqLcGPMgah-yFOy3mfHiGVt-2gd0mueRJAebw48ldDroqN_JXuWECFDpECjXdoCflpxhZkeuxXbvHgLzsTA44gOB2cCv09GdGaUd0vIjqfCS34m8DBRvfzeiW3H87Wd0IZ1u5sUlo8PhKGJln6KUFgH0niX9LUUr985b_Ss6w6WUS6_7I2GuT8N9mvFQ0Ib_IzhiFWcC82scFNQaV6diOdH0MxBjpUCvGjWP95wA=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3zJXRUk3dGBCokivekKq5G8WTBwRerQ0e-aeVI_5VHKm2hf_Wf0KqSNWxD5RBQQNTtbsyd-kpHVvvR-RNuNQGMfgBqWrVsBX_Y-EudSJfeTZwk7FMmeyVOSOHDbFYVrMIUZjobqyB1oqVEbYVKnKGirVJb9yzKNTo4pEc5s1zbGm3TmjLhkqUvi--nkOtTKw0oz6t0VlJIR8YuLv0Myh1mrr47GqcU-39f9iTv-KZ-HGtWaHIA-iyMpmkdljk7ggY8h-NolLrSBjCX-3swfouYeQjwWrXxnCiZSqp2v6kD4SyTXzNTtFXP1FzuSHV3i3T4QPJA_lIUwtC8fHJdZGHIgUXnzL_MCtx5ZEAcBFOTldzLK-zXkSld9pnOu7rcbT3QFGc72eCVYV5-c_JeKfSmZI5MU9Gus3p3C-ejxnZCQZVOd7xVG-G3AJVSoBv2NzHaCTBt2f4S-rTkHxv8sW7oxisfYOjWtKRQ3_JXQLCDy8ubmfOWXUDVXqXcoPztQUcP0zuLJ8fing9qfOvvlPvAtG--PbaeRdj2SSYs75HuxsyGMtYXxVYT8qWTz9Mfq4lE34iLtXOg930XN0z0hviTWyHEuORbpXfHSYiTvuqB82b-R4dZK1Hc7JaYtm8FHvKeKiv-tydgk59mbkeMQn1sOy39oyO1bMUmxiyM3mcWZOeW3Vl_cX8K-v7dXBC3CTPTJ_lwJKCtsBW-dL2iV6ixjU6T7r3ZN48pYoZyV6tIrAeprhUmN8PexFH7uGZ1wZ1S89qa2YnaMW4vFdZ17cwWYtdjCqFLvKckO0BimKRnc5nj1AgXdy5b046GPSZ1YuCdfU08wSy_ZxvV0FiSkRNlRKnYjOeqHsMxh2gQFO0UU7Wy-D9qmvgoWSMUFz3JthC4QZuCbDf6dsOKk_wyPDzKV7iZsvwElWG1Z1MDx_jFDFM1ysDTutEL9rpYJ8OXGfawYj3HaUB5uise4Wq1R-i6awEEsuZ4L3CDkfAPGd4nn7WyXBhb5lYlr1mh5iwc9QSI6XqLcGPMgah-yFOy3mfHiGVt-2gd0mueRJAebw48ldDroqN_JXuWECFDpECjXdoCflpxhZkeuxXbvHgLzsTA44gOB2cCv09GdGaUd0vIjqfCS34m8DBRvfzeiW3H87Wd0IZ1u5sUlo8PhKGJln6KUFgH0niX9LUUr985b_Ss6w6WUS6_7I2GuT8N9mvFQ0Ib_IzhiFWcC82scFNQaV6diOdH0MxBjpUCvGjWP95wA=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1043.7,
            "totalPrice": 806
          },
          {
            "allPassengerTotalPrice": 807,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHBWVJPGetzXWqT5LOvVBA7cb23KJw1EuBR0RHP8LFF5kL2tkBQFABn4uC8yPjzElz8BanVwGLJ71QxLDSabkC8JezedYjpw-mIhXvxSQAnQa3lPpU-e1IbfM00FnXgXYuVyeCfM1wOV308cI42yXaU4VUlMsjX4Tbc70urKSX3Mcogg6a91BUdXvvrHtClJWNSnQg4KZ-jOCYIP-hXNUe4skoyncMa2rVAfGCC0MHD4eqIivAzztf6XM77x17zVSdGjn171M9VIBjPRuzLFbWtQByusF9av2vJ30zwu-SDRq2n8xVwSVRavYvmVaKh6w4NpH76DGD84cW1ilhnVzF5mDn9Qzr1jLAVnB1hsxi6ZWxVmqp33oh5DX7ltgFFadwjZjw3qSjURWAjEhSPnYhZB3hBqDNzPf0VwFwMY4gdn2nnl3UDh58JUuwINoviygpz-mIWaPU2Kk9DSTH55k0dH9ibOXi0UrohqI6aCrfe1_6cS8OdOUNLSsxcOulMdq2K2IZB7-8g0blQQ55igNIL_6rubBD5ep0-_-3Bsbn8HwI3IX1gMihrw3pU_7qdC37JQSk_ebKozTStB79ePkbQQwcaIaRpePgNJuPDuXWBEVVsSNMkhmKVYrX3qIj1qH9K0SkUODCqsF3rp9I0lGectKhAq4Q7kKMWaEO5_B3EYKuvnrDPtCsrgMPQYKP-pfVn5-GAxg_C5zcsiLLU4BPQSD-vwQpOy0F0486GO28l_lxzZhTaAvSdUgM0BQ6f0XD0TsO_PbolfwXATZD6oQYZULmu-y7bVmKiTkr9NkXlclDVFvmXRs9pfYaatf-uI6uCEl2xGWNGDcxskQ74kHXmo8QTAuyTUrOrk0lRCJxaWhKrI4jxfb0Dxx0-zozFQWZKNF-3UcXlLU2WOTw_ewPONI-VEPiJeGrtgXOxuSlxCmmUMwlyHADskxcHmeH1IkZ_bD4Ph8BFCoF-S_Gi8gCbzpOHlPMRkIrVjd7UCXPQ_uquOk0gg0n8jDOC1A2PuYH0sGTSV6jl2Sqehbs8XNIM_jDx013eQtBWesM3Lmn-DVIssQ4lH6IDeVc9PxUejlH8YPNTTUHCUWSjOQN3bZEWQ8mCWyRAMMRq1hOaVODFishg9prLKDfy_kCvaRYQJLCSIj4xVxe29q_Drexz25BkmAQj7gGxO3-1G0JTRkwhsxeHyJ37S_xKuNnIo2iAHWhK3VTpzZRyALuKz7B4CtWVgTJ3ejO1HNNR9vHWNq_BzXGFjRs7un_VOtkQxKRBKA4DXX-ZI3b9cqHiEv_u5YABb3Ds9detqJcW4lgzGm2Uw=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHBWVJPGetzXWqT5LOvVBA7cb23KJw1EuBR0RHP8LFF5kL2tkBQFABn4uC8yPjzElz8BanVwGLJ71QxLDSabkC8JezedYjpw-mIhXvxSQAnQa3lPpU-e1IbfM00FnXgXYuVyeCfM1wOV308cI42yXaU4VUlMsjX4Tbc70urKSX3Mcogg6a91BUdXvvrHtClJWNSnQg4KZ-jOCYIP-hXNUe4skoyncMa2rVAfGCC0MHD4eqIivAzztf6XM77x17zVSdGjn171M9VIBjPRuzLFbWtQByusF9av2vJ30zwu-SDRq2n8xVwSVRavYvmVaKh6w4NpH76DGD84cW1ilhnVzF5mDn9Qzr1jLAVnB1hsxi6ZWxVmqp33oh5DX7ltgFFadwjZjw3qSjURWAjEhSPnYhZB3hBqDNzPf0VwFwMY4gdn2nnl3UDh58JUuwINoviygpz-mIWaPU2Kk9DSTH55k0dH9ibOXi0UrohqI6aCrfe1_6cS8OdOUNLSsxcOulMdq2K2IZB7-8g0blQQ55igNIL_6rubBD5ep0-_-3Bsbn8HwI3IX1gMihrw3pU_7qdC37JQSk_ebKozTStB79ePkbQQwcaIaRpePgNJuPDuXWBEVVsSNMkhmKVYrX3qIj1qH9K0SkUODCqsF3rp9I0lGectKhAq4Q7kKMWaEO5_B3EYKuvnrDPtCsrgMPQYKP-pfVn5-GAxg_C5zcsiLLU4BPQSD-vwQpOy0F0486GO28l_lxzZhTaAvSdUgM0BQ6f0XD0TsO_PbolfwXATZD6oQYZULmu-y7bVmKiTkr9NkXlclDVFvmXRs9pfYaatf-uI6uCEl2xGWNGDcxskQ74kHXmo8QTAuyTUrOrk0lRCJxaWhKrI4jxfb0Dxx0-zozFQWZKNF-3UcXlLU2WOTw_ewPONI-VEPiJeGrtgXOxuSlxCmmUMwlyHADskxcHmeH1IkZ_bD4Ph8BFCoF-S_Gi8gCbzpOHlPMRkIrVjd7UCXPQ_uquOk0gg0n8jDOC1A2PuYH0sGTSV6jl2Sqehbs8XNIM_jDx013eQtBWesM3Lmn-DVIssQ4lH6IDeVc9PxUejlH8YPNTTUHCUWSjOQN3bZEWQ8mCWyRAMMRq1hOaVODFishg9prLKDfy_kCvaRYQJLCSIj4xVxe29q_Drexz25BkmAQj7gGxO3-1G0JTRkwhsxeHyJ37S_xKuNnIo2iAHWhK3VTpzZRyALuKz7B4CtWVgTJ3ejO1HNNR9vHWNq_BzXGFjRs7un_VOtkQxKRBKA4DXX-ZI3b9cqHiEv_u5YABb3Ds9detqJcW4lgzGm2Uw=",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GHBWVJPGetzXWqT5LOvVBA7cb23KJw1EuBR0RHP8LFF5kL2tkBQFABn4uC8yPjzElz8BanVwGLJ71QxLDSabkC8JezedYjpw-mIhXvxSQAnQa3lPpU-e1IbfM00FnXgXYuVyeCfM1wOV308cI42yXaU4VUlMsjX4Tbc70urKSX3Mcogg6a91BUdXvvrHtClJWNSnQg4KZ-jOCYIP-hXNUe4skoyncMa2rVAfGCC0MHD4eqIivAzztf6XM77x17zVSdGjn171M9VIBjPRuzLFbWtQByusF9av2vJ30zwu-SDRq2n8xVwSVRavYvmVaKh6w4NpH76DGD84cW1ilhnVzF5mDn9Qzr1jLAVnB1hsxi6ZWxVmqp33oh5DX7ltgFFadwjZjw3qSjURWAjEhSPnYhZB3hBqDNzPf0VwFwMY4gdn2nnl3UDh58JUuwINoviygpz-mIWaPU2Kk9DSTH55k0dH9ibOXi0UrohqI6aCrfe1_6cS8OdOUNLSsxcOulMdq2K2IZB7-8g0blQQ55igNIL_6rubBD5ep0-_-3Bsbn8HwI3IX1gMihrw3pU_7qdC37JQSk_ebKozTStB79ePkbQQwcaIaRpePgNJuPDuXWBEVVsSNMkhmKVYrX3qIj1qH9K0SkUODCqsF3rp9I0lGectKhAq4Q7kKMWaEO5_B3EYKuvnrDPtCsrgMPQYKP-pfVn5-GAxg_C5zcsiLLU4BPQSD-vwQpOy0F0486GO28l_lxzZhTaAvSdUgM0BQ6f0XD0TsO_PbolfwXATZD6oQYZULmu-y7bVmKiTkr9NkXlclDVFvmXRs9pfYaatf-uI6uCEl2xGWNGDcxskQ74kHXmo8QTAuyTUrOrk0lRCJxaWhKrI4jxfb0Dxx0-zozFQWZKNF-3UcXlLU2WOTw_ewPONI-VEPiJeGrtgXOxuSlxCmmUMwlyHADskxcHmeH1IkZ_bD4Ph8BFCoF-S_Gi8gCbzpOHlPMRkIrVjd7UCXPQ_uquOk0gg0n8jDOC1A2PuYH0sGTSV6jl2Sqehbs8XNIM_jDx013eQtBWesM3Lmn-DVIssQ4lH6IDeVc9PxUejlH8YPNTTUHCUWSjOQN3bZEWQ8mCWyRAMMRq1hOaVODFishg9prLKDfy_kCvaRYQJLCSIj4xVxe29q_Drexz25BkmAQj7gGxO3-1G0JTRkwhsxeHyJ37S_xKuNnIo2iAHWhK3VTpzZRyALuKz7B4CtWVgTJ3ejO1HNNR9vHWNq_BzXGFjRs7un_VOtkQxKRBKA4DXX-ZI3b9cqHiEv_u5YABb3Ds9detqJcW4lgzGm2Uw=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1110.92,
            "totalPrice": 807
          },
          {
            "allPassengerTotalPrice": 808,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbSEKqiG2bpJpDOL9VEHH51O3ElysGbsfeyXCtLBKWp251i5toR89AS19RWkJUvTgBy9sHlkdu9MFHgWjFVZlaMEhGb_nM9pwRlaJqCYBIfYGBtdv1xSSdpI9IvO9st8m8IU54v4FQuU_We9zB8YAInNN8yFNuA-E07AYPrEwiwY_i8g1q-a2B8GJtSejYAWM7jqj8l_oeXV6xpm8MSVkHnupTpt2xXX4wAOrxtSf8odEwE13qAvyOZkrewGS3Sh-0BXxMk2nrqzhROrN7_WJL8wQNFUN-BR3-lHjJijYtdnYRNaK84kvoPHibmgx9zLCy80Uu3RnUrh7dqKQYBkVBkFUJx83bNPvIKdppA6rZ5V_CR4DDkax9GcjxGSmplqS59vu8B2In8qjGl93CLOfsj8nkuXQKHhx-VEm-G5JL7TLzIfAJrZn2JrRj-LmxrBJxv5QWc3modZbGdtehX3kOrZEPkk0FbFiOxMMMrYt47WLGjb6cQHoB7kjQmRU5Oy3JivjzeQ6UlmUxX01aYs5hXwyGLq8TX75-rdOzPgyiK9wNR1EoAUaQEGgEvVq52Dt2pJPpoNfI-1i9zS3x1UzDnLu7W9TIpfbXl38TB7OH0cx0V6Ayff4H1uZtic35Fv5VOEP8ccDtrO0zuFJw79Lniv-csGb3dXSZ_76F24Bj-MZyoNX0h3FnTyspt3eDZJrkc2Xy-Nc2nJJ70EXcC7ySyAm9FbX5UpyUACUmMTSNJbNquZdCJT-ZY17ksOrUlce1j4EbIKrR1hspcK2Ed17XYfmojdmxhtjGESyvqxjk2xMg91UQhrNbGBUx8VvyRk7AO-5BE_-p-Jx88dHZsJelMwoH5oOzbnpLfb-BazeJL2gZkLrgs88sTw7dAKRmpU_4_CAjj7dXPgZ5wKAX6HymWIfdbVC03eohtJX3maP1pnZ_BFfuJO7SjQwEOU_gpFUHY2cEDOAcZ1uTJgZM-idDGMtLPb_dcKLYH9ITr6dnoIe09i5_bAXjAThkWd8hypSVTlOTAzNlvpTqUr85Z_RJEmdhjUQKpmp-3lL-2u7gAHICspabaaG9SWpmozDAzaI2H1JHyowTjSeJJZj4Vqa6935HYfuZVfmT85VmngSuGkFsHnp8Qry6Q2mZ6oCiaQyw5riZ6R5ZtkMvCdz6Fh3iELRrWoLKAsfG719qiM8o94ZoFuDCGMqDf4V2SyFDtzKMlUDkmBLiXzJi41AQt18zJX9LPy5bVAPaqiS46Lxj20=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbSEKqiG2bpJpDOL9VEHH51O3ElysGbsfeyXCtLBKWp251i5toR89AS19RWkJUvTgBy9sHlkdu9MFHgWjFVZlaMEhGb_nM9pwRlaJqCYBIfYGBtdv1xSSdpI9IvO9st8m8IU54v4FQuU_We9zB8YAInNN8yFNuA-E07AYPrEwiwY_i8g1q-a2B8GJtSejYAWM7jqj8l_oeXV6xpm8MSVkHnupTpt2xXX4wAOrxtSf8odEwE13qAvyOZkrewGS3Sh-0BXxMk2nrqzhROrN7_WJL8wQNFUN-BR3-lHjJijYtdnYRNaK84kvoPHibmgx9zLCy80Uu3RnUrh7dqKQYBkVBkFUJx83bNPvIKdppA6rZ5V_CR4DDkax9GcjxGSmplqS59vu8B2In8qjGl93CLOfsj8nkuXQKHhx-VEm-G5JL7TLzIfAJrZn2JrRj-LmxrBJxv5QWc3modZbGdtehX3kOrZEPkk0FbFiOxMMMrYt47WLGjb6cQHoB7kjQmRU5Oy3JivjzeQ6UlmUxX01aYs5hXwyGLq8TX75-rdOzPgyiK9wNR1EoAUaQEGgEvVq52Dt2pJPpoNfI-1i9zS3x1UzDnLu7W9TIpfbXl38TB7OH0cx0V6Ayff4H1uZtic35Fv5VOEP8ccDtrO0zuFJw79Lniv-csGb3dXSZ_76F24Bj-MZyoNX0h3FnTyspt3eDZJrkc2Xy-Nc2nJJ70EXcC7ySyAm9FbX5UpyUACUmMTSNJbNquZdCJT-ZY17ksOrUlce1j4EbIKrR1hspcK2Ed17XYfmojdmxhtjGESyvqxjk2xMg91UQhrNbGBUx8VvyRk7AO-5BE_-p-Jx88dHZsJelMwoH5oOzbnpLfb-BazeJL2gZkLrgs88sTw7dAKRmpU_4_CAjj7dXPgZ5wKAX6HymWIfdbVC03eohtJX3maP1pnZ_BFfuJO7SjQwEOU_gpFUHY2cEDOAcZ1uTJgZM-idDGMtLPb_dcKLYH9ITr6dnoIe09i5_bAXjAThkWd8hypSVTlOTAzNlvpTqUr85Z_RJEmdhjUQKpmp-3lL-2u7gAHICspabaaG9SWpmozDAzaI2H1JHyowTjSeJJZj4Vqa6935HYfuZVfmT85VmngSuGkFsHnp8Qry6Q2mZ6oCiaQyw5riZ6R5ZtkMvCdz6Fh3iELRrWoLKAsfG719qiM8o94ZoFuDCGMqDf4V2SyFDtzKMlUDkmBLiXzJi41AQt18zJX9LPy5bVAPaqiS46Lxj20=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbSEKqiG2bpJpDOL9VEHH51O3ElysGbsfeyXCtLBKWp251i5toR89AS19RWkJUvTgBy9sHlkdu9MFHgWjFVZlaMEhGb_nM9pwRlaJqCYBIfYGBtdv1xSSdpI9IvO9st8m8IU54v4FQuU_We9zB8YAInNN8yFNuA-E07AYPrEwiwY_i8g1q-a2B8GJtSejYAWM7jqj8l_oeXV6xpm8MSVkHnupTpt2xXX4wAOrxtSf8odEwE13qAvyOZkrewGS3Sh-0BXxMk2nrqzhROrN7_WJL8wQNFUN-BR3-lHjJijYtdnYRNaK84kvoPHibmgx9zLCy80Uu3RnUrh7dqKQYBkVBkFUJx83bNPvIKdppA6rZ5V_CR4DDkax9GcjxGSmplqS59vu8B2In8qjGl93CLOfsj8nkuXQKHhx-VEm-G5JL7TLzIfAJrZn2JrRj-LmxrBJxv5QWc3modZbGdtehX3kOrZEPkk0FbFiOxMMMrYt47WLGjb6cQHoB7kjQmRU5Oy3JivjzeQ6UlmUxX01aYs5hXwyGLq8TX75-rdOzPgyiK9wNR1EoAUaQEGgEvVq52Dt2pJPpoNfI-1i9zS3x1UzDnLu7W9TIpfbXl38TB7OH0cx0V6Ayff4H1uZtic35Fv5VOEP8ccDtrO0zuFJw79Lniv-csGb3dXSZ_76F24Bj-MZyoNX0h3FnTyspt3eDZJrkc2Xy-Nc2nJJ70EXcC7ySyAm9FbX5UpyUACUmMTSNJbNquZdCJT-ZY17ksOrUlce1j4EbIKrR1hspcK2Ed17XYfmojdmxhtjGESyvqxjk2xMg91UQhrNbGBUx8VvyRk7AO-5BE_-p-Jx88dHZsJelMwoH5oOzbnpLfb-BazeJL2gZkLrgs88sTw7dAKRmpU_4_CAjj7dXPgZ5wKAX6HymWIfdbVC03eohtJX3maP1pnZ_BFfuJO7SjQwEOU_gpFUHY2cEDOAcZ1uTJgZM-idDGMtLPb_dcKLYH9ITr6dnoIe09i5_bAXjAThkWd8hypSVTlOTAzNlvpTqUr85Z_RJEmdhjUQKpmp-3lL-2u7gAHICspabaaG9SWpmozDAzaI2H1JHyowTjSeJJZj4Vqa6935HYfuZVfmT85VmngSuGkFsHnp8Qry6Q2mZ6oCiaQyw5riZ6R5ZtkMvCdz6Fh3iELRrWoLKAsfG719qiM8o94ZoFuDCGMqDf4V2SyFDtzKMlUDkmBLiXzJi41AQt18zJX9LPy5bVAPaqiS46Lxj20=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1045.11,
            "totalPrice": 808
          },
          {
            "allPassengerTotalPrice": 809,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GwU_LHM61W2-zDoGCgkL0ZHfk3WwQNIP1Nzmq2zbz4OTJ6m3EiVvRv23CyCyVBnsbiMxj4iZHwuTeuzS6o6AkECJUfrPbFLF-4ZZDhzzDfOxYXOfQCCaAaQ3vdbatJ2A9FVTITB4kVw7RjBabLsHQRxmvSHq2SoOGOe8QgvZhbEEd0Ftt6vic_ORivKcbdmwblpIMBjwsyhy5MJpNuvkMkmNCEVOvThXfHJxdLkDeNorlQeHJVnoQpvJW95klqYsLOIdasumADd7Qt8bFSi0h26JGmKs8i6VfcllU_lhQJpGV4E_MQMetmyBC-B1hhb6YnZcGoYc0zdP48c4IwexTCMrIumwykATo9JMk_55UuYX9Sbyjb0MRvkTR1vVjLLWBYZU6ai7IK3Oj51scxcDq24Y3yqzJJptNGTU_7dxnoodBDh2l7jVybnWGVBLPBFW4yLqNSzI8TgiwWCMwgtYABOYTsfB2AoEzAUDKPPvdB-p7uYWkN8zxUXOtSMeprlSNwHudp6-297RuZi3LPQSYC8xIhWFqWgDOTUBqpj3JYKrbzv1VwFyewM1T8NEXNztTTdntKosSNitOlm6J-Z_6yt-PO_NXOuDSotpf-R9hsiA_yoT57TggBFkWXYt2NMGfcyqJVbspBIdD-_BQGZKv-3BQZItbg4ou3O-vVU8zODgYq4sDC1FVT145m2NU-vwBUPorLSs_mn_sQRobuZtMrp-fL1ABvdiMvX0JfT2xiWkEI2RD6wi53QeATfLOiWnXr7potKp1fSj2XGWUOjNBw-Rck-Fsnfa3AqBodW3201gPj5HpEdI9k9UwCW3uEyJFusFnMyaeUZMKVEDHvFDtpChfF8YzpBjmmNpxW-VH_kaLViBp9ZIvFE_OzI6I2MjLRNSvCPfH_dFla9DuRXY1urIqLV1vR-MtfilX1zsCkxCC4hPqUHBp2GXs3e_IuxAn3duN_IUMH6KbDiZy9ASD3VWRMWP9SD1-YfSe-FWRvbjGgRyTub1-xflAfuK_MBcobyf3O_LT0nDoJyAm0_2DcpsFstmlQMi184YvB8X7cjHsmeDwu6anWmcbLstCnlGdxmJVpaNiNnFgzeLynmA_qMaONgNJXZ-nzSfgNPPdOcT-4IrJXlCr2YPQZZfoE4Zd3yHtohNIIHgdkvQAUsabJjQt8jJ_p9atY77mjSpoaLnhNEAprVr7K1QUG12FsWSkEOK6WHe3DhcZo_yrhh_MWS1YaOWsIvKaItP7I0FhQf8=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GwU_LHM61W2-zDoGCgkL0ZHfk3WwQNIP1Nzmq2zbz4OTJ6m3EiVvRv23CyCyVBnsbiMxj4iZHwuTeuzS6o6AkECJUfrPbFLF-4ZZDhzzDfOxYXOfQCCaAaQ3vdbatJ2A9FVTITB4kVw7RjBabLsHQRxmvSHq2SoOGOe8QgvZhbEEd0Ftt6vic_ORivKcbdmwblpIMBjwsyhy5MJpNuvkMkmNCEVOvThXfHJxdLkDeNorlQeHJVnoQpvJW95klqYsLOIdasumADd7Qt8bFSi0h26JGmKs8i6VfcllU_lhQJpGV4E_MQMetmyBC-B1hhb6YnZcGoYc0zdP48c4IwexTCMrIumwykATo9JMk_55UuYX9Sbyjb0MRvkTR1vVjLLWBYZU6ai7IK3Oj51scxcDq24Y3yqzJJptNGTU_7dxnoodBDh2l7jVybnWGVBLPBFW4yLqNSzI8TgiwWCMwgtYABOYTsfB2AoEzAUDKPPvdB-p7uYWkN8zxUXOtSMeprlSNwHudp6-297RuZi3LPQSYC8xIhWFqWgDOTUBqpj3JYKrbzv1VwFyewM1T8NEXNztTTdntKosSNitOlm6J-Z_6yt-PO_NXOuDSotpf-R9hsiA_yoT57TggBFkWXYt2NMGfcyqJVbspBIdD-_BQGZKv-3BQZItbg4ou3O-vVU8zODgYq4sDC1FVT145m2NU-vwBUPorLSs_mn_sQRobuZtMrp-fL1ABvdiMvX0JfT2xiWkEI2RD6wi53QeATfLOiWnXr7potKp1fSj2XGWUOjNBw-Rck-Fsnfa3AqBodW3201gPj5HpEdI9k9UwCW3uEyJFusFnMyaeUZMKVEDHvFDtpChfF8YzpBjmmNpxW-VH_kaLViBp9ZIvFE_OzI6I2MjLRNSvCPfH_dFla9DuRXY1urIqLV1vR-MtfilX1zsCkxCC4hPqUHBp2GXs3e_IuxAn3duN_IUMH6KbDiZy9ASD3VWRMWP9SD1-YfSe-FWRvbjGgRyTub1-xflAfuK_MBcobyf3O_LT0nDoJyAm0_2DcpsFstmlQMi184YvB8X7cjHsmeDwu6anWmcbLstCnlGdxmJVpaNiNnFgzeLynmA_qMaONgNJXZ-nzSfgNPPdOcT-4IrJXlCr2YPQZZfoE4Zd3yHtohNIIHgdkvQAUsabJjQt8jJ_p9atY77mjSpoaLnhNEAprVr7K1QUG12FsWSkEOK6WHe3DhcZo_yrhh_MWS1YaOWsIvKaItP7I0FhQf8=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GwU_LHM61W2-zDoGCgkL0ZHfk3WwQNIP1Nzmq2zbz4OTJ6m3EiVvRv23CyCyVBnsbiMxj4iZHwuTeuzS6o6AkECJUfrPbFLF-4ZZDhzzDfOxYXOfQCCaAaQ3vdbatJ2A9FVTITB4kVw7RjBabLsHQRxmvSHq2SoOGOe8QgvZhbEEd0Ftt6vic_ORivKcbdmwblpIMBjwsyhy5MJpNuvkMkmNCEVOvThXfHJxdLkDeNorlQeHJVnoQpvJW95klqYsLOIdasumADd7Qt8bFSi0h26JGmKs8i6VfcllU_lhQJpGV4E_MQMetmyBC-B1hhb6YnZcGoYc0zdP48c4IwexTCMrIumwykATo9JMk_55UuYX9Sbyjb0MRvkTR1vVjLLWBYZU6ai7IK3Oj51scxcDq24Y3yqzJJptNGTU_7dxnoodBDh2l7jVybnWGVBLPBFW4yLqNSzI8TgiwWCMwgtYABOYTsfB2AoEzAUDKPPvdB-p7uYWkN8zxUXOtSMeprlSNwHudp6-297RuZi3LPQSYC8xIhWFqWgDOTUBqpj3JYKrbzv1VwFyewM1T8NEXNztTTdntKosSNitOlm6J-Z_6yt-PO_NXOuDSotpf-R9hsiA_yoT57TggBFkWXYt2NMGfcyqJVbspBIdD-_BQGZKv-3BQZItbg4ou3O-vVU8zODgYq4sDC1FVT145m2NU-vwBUPorLSs_mn_sQRobuZtMrp-fL1ABvdiMvX0JfT2xiWkEI2RD6wi53QeATfLOiWnXr7potKp1fSj2XGWUOjNBw-Rck-Fsnfa3AqBodW3201gPj5HpEdI9k9UwCW3uEyJFusFnMyaeUZMKVEDHvFDtpChfF8YzpBjmmNpxW-VH_kaLViBp9ZIvFE_OzI6I2MjLRNSvCPfH_dFla9DuRXY1urIqLV1vR-MtfilX1zsCkxCC4hPqUHBp2GXs3e_IuxAn3duN_IUMH6KbDiZy9ASD3VWRMWP9SD1-YfSe-FWRvbjGgRyTub1-xflAfuK_MBcobyf3O_LT0nDoJyAm0_2DcpsFstmlQMi184YvB8X7cjHsmeDwu6anWmcbLstCnlGdxmJVpaNiNnFgzeLynmA_qMaONgNJXZ-nzSfgNPPdOcT-4IrJXlCr2YPQZZfoE4Zd3yHtohNIIHgdkvQAUsabJjQt8jJ_p9atY77mjSpoaLnhNEAprVr7K1QUG12FsWSkEOK6WHe3DhcZo_yrhh_MWS1YaOWsIvKaItP7I0FhQf8=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1032.79,
            "totalPrice": 809
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1008.35,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gd3dwT-R0h42bd6WPxgcD5cT6OrEFnqV3M_O6sYcPk_ofbajk-8sx6Do4fJT-hkGlnyQSz610rNj2of45u-G0TAF2lAFr6z7lLoM09vM-XQpO5Jm3g-Cfn7LgchzeIoqksFn2TzwPLX_PwxLvVNMyLX6-tzoEr1j8UzGpHp-orhh9H-1a_PCKTgY0LVJUg-1TCsTNgDALYuGWMkvK9fQhNytCg4LbgSKAfkr-7Iku_zk-_-qBPz7NcqS711D6AMoQed7tqMtVB-RYWyGTXix1wXXuraEJq0pUf-EEjQ7XiMj8bOmBLdO-8TEDUbnHfP5sE4nIPYiVm60VVaJQcr0E5SXikMOhi4goU3wZWfpv77FFumOq8vPOWSC5eiXnl-wVVVQ7pFrDsCXAQOV8ZXR8_VYyCJx54y153oooHfto1ciw2MfpgaoKjwKZXSu_wnA9awPNmefJ6LvQM7GPTQAcFOiTwvI8CEkVRTaWOrSKAzf3pxfB5vkJR0ifHLckRMB1vWTwlLUS82HN63btJI3rwSP7woNpRuQOVVzeez--sMxqME7KPNrvFmzrhiHqBf7sWuQBfGyaTPk5OT2r7lqzL08gN5HBBhnzH2schq7mYSSwq3sntTdixwV8JVmxhY5gqmXmI8vVhLIDisa3yMQrsGpbyioA3MzJIY4_NfHdIiOMzB8PgJrtDEA2Gz1weU5KVJQh5E0t_rDwtDxwFNuggBrNM7VcrPlN8U4dgEDJZAAC8yVDgq8SolFJAVP9CMkCmQs24Ovd0d54VTZG7n8LDPuWNcO2ILHKNphHy2erT3rMlx6NHKSPM1-lgDV_djI3FU06i2bowTmskwEAl3ogdjQf0yI35DASV8zunbAputgAkIbbG-DJdw4nWEQfNSE8bLwTAFAb2DlHE3EeUThc4xdaF68-InHtRfrPGmcCA0zJXf-tXHjnGCcoIfDWOK__SRnTOLdOdFri18jVmWUw2ysFSi2HiO0YVwR8iWzuUEthj9qIAv7mVBN7ZsVIHUrE6kyls_-nvjWhtZ73og7csMLNbBwZfhvOz1Lz_uNV3xP73cVAlNugeZG9yrbHoLP0ajX9irBir_uwG_v45ZfbXA==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gd3dwT-R0h42bd6WPxgcD5cT6OrEFnqV3M_O6sYcPk_ofbajk-8sx6Do4fJT-hkGlnyQSz610rNj2of45u-G0TAF2lAFr6z7lLoM09vM-XQpO5Jm3g-Cfn7LgchzeIoqksFn2TzwPLX_PwxLvVNMyLX6-tzoEr1j8UzGpHp-orhh9H-1a_PCKTgY0LVJUg-1TCsTNgDALYuGWMkvK9fQhNytCg4LbgSKAfkr-7Iku_zk-_-qBPz7NcqS711D6AMoQed7tqMtVB-RYWyGTXix1wXXuraEJq0pUf-EEjQ7XiMj8bOmBLdO-8TEDUbnHfP5sE4nIPYiVm60VVaJQcr0E5SXikMOhi4goU3wZWfpv77FFumOq8vPOWSC5eiXnl-wVVVQ7pFrDsCXAQOV8ZXR8_VYyCJx54y153oooHfto1ciw2MfpgaoKjwKZXSu_wnA9awPNmefJ6LvQM7GPTQAcFOiTwvI8CEkVRTaWOrSKAzf3pxfB5vkJR0ifHLckRMB1vWTwlLUS82HN63btJI3rwSP7woNpRuQOVVzeez--sMxqME7KPNrvFmzrhiHqBf7sWuQBfGyaTPk5OT2r7lqzL08gN5HBBhnzH2schq7mYSSwq3sntTdixwV8JVmxhY5gqmXmI8vVhLIDisa3yMQrsGpbyioA3MzJIY4_NfHdIiOMzB8PgJrtDEA2Gz1weU5KVJQh5E0t_rDwtDxwFNuggBrNM7VcrPlN8U4dgEDJZAAC8yVDgq8SolFJAVP9CMkCmQs24Ovd0d54VTZG7n8LDPuWNcO2ILHKNphHy2erT3rMlx6NHKSPM1-lgDV_djI3FU06i2bowTmskwEAl3ogdjQf0yI35DASV8zunbAputgAkIbbG-DJdw4nWEQfNSE8bLwTAFAb2DlHE3EeUThc4xdaF68-InHtRfrPGmcCA0zJXf-tXHjnGCcoIfDWOK__SRnTOLdOdFri18jVmWUw2ysFSi2HiO0YVwR8iWzuUEthj9qIAv7mVBN7ZsVIHUrE6kyls_-nvjWhtZ73og7csMLNbBwZfhvOz1Lz_uNV3xP73cVAlNugeZG9yrbHoLP0ajX9irBir_uwG_v45ZfbXA==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gd3dwT-R0h42bd6WPxgcD5cT6OrEFnqV3M_O6sYcPk_ofbajk-8sx6Do4fJT-hkGlnyQSz610rNj2of45u-G0TAF2lAFr6z7lLoM09vM-XQpO5Jm3g-Cfn7LgchzeIoqksFn2TzwPLX_PwxLvVNMyLX6-tzoEr1j8UzGpHp-orhh9H-1a_PCKTgY0LVJUg-1TCsTNgDALYuGWMkvK9fQhNytCg4LbgSKAfkr-7Iku_zk-_-qBPz7NcqS711D6AMoQed7tqMtVB-RYWyGTXix1wXXuraEJq0pUf-EEjQ7XiMj8bOmBLdO-8TEDUbnHfP5sE4nIPYiVm60VVaJQcr0E5SXikMOhi4goU3wZWfpv77FFumOq8vPOWSC5eiXnl-wVVVQ7pFrDsCXAQOV8ZXR8_VYyCJx54y153oooHfto1ciw2MfpgaoKjwKZXSu_wnA9awPNmefJ6LvQM7GPTQAcFOiTwvI8CEkVRTaWOrSKAzf3pxfB5vkJR0ifHLckRMB1vWTwlLUS82HN63btJI3rwSP7woNpRuQOVVzeez--sMxqME7KPNrvFmzrhiHqBf7sWuQBfGyaTPk5OT2r7lqzL08gN5HBBhnzH2schq7mYSSwq3sntTdixwV8JVmxhY5gqmXmI8vVhLIDisa3yMQrsGpbyioA3MzJIY4_NfHdIiOMzB8PgJrtDEA2Gz1weU5KVJQh5E0t_rDwtDxwFNuggBrNM7VcrPlN8U4dgEDJZAAC8yVDgq8SolFJAVP9CMkCmQs24Ovd0d54VTZG7n8LDPuWNcO2ILHKNphHy2erT3rMlx6NHKSPM1-lgDV_djI3FU06i2bowTmskwEAl3ogdjQf0yI35DASV8zunbAputgAkIbbG-DJdw4nWEQfNSE8bLwTAFAb2DlHE3EeUThc4xdaF68-InHtRfrPGmcCA0zJXf-tXHjnGCcoIfDWOK__SRnTOLdOdFri18jVmWUw2ysFSi2HiO0YVwR8iWzuUEthj9qIAv7mVBN7ZsVIHUrE6kyls_-nvjWhtZ73og7csMLNbBwZfhvOz1Lz_uNV3xP73cVAlNugeZG9yrbHoLP0ajX9irBir_uwG_v45ZfbXA==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1008.35,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBWUnlH-WzSkkmE_e-_Jtz15nZTVmO9LjbxWmhGDTNeccqvv2ewA3oKsLPK78wTxxg2kgw89ckHK4_ayy2MNCAj9T8dfZjX2scjk2YnRt9iiignXFn5RELvzt5TRKC_VNVKUx8P-dj-jpDfUU3LJfoexIJLu_wnFByBa-HhrXuEVQp_AX-2n_-KOzk44dDwnNSPZY9H2PiGm5PsatmaHxBddu0eqQNMOtqkggobdH43K6ONGOOr4pG_9Ah_fssxkvum7jTCKSnRU_Mmqt4Pw8BXYYX4lTaKAQEi5bF-qYc1dAHodu3SiwQ66NXm2hcJa1Ye7jMzdP1qXolx-M3WytKVn-cI3u_F2UYMxwQzUwaxHMXDtZRUJc6O_zrPATavEvgpsOVb2a-rte1B8Wmgtnym9PclPPyh3F8mRR62R2mP0NetMH6utKN224g9Ph2bZIDbr9RXuWxVDkJT3xsHM4-g4-_2CbJSn6PbOf0HZl2shAsuYY230KRaPsMDUZcvx9ILM2LNjvEVlMzvI75FIdx2F36HoXodN5dQ5XYN-U3syGPotE46tav14BzE7Yycd_Xz7fa4ykVUUFz5WTqD0yzq5R0DdsBOlMAp6H1rFWoIwPuuha-sbGt7QKQbRWuTyzStDsijSjnG85cwePkX4stc47SsN5cFlQRAo8R7kkBQPBtDZ-79L3rqhiuOJHCjnr4vcVnwYREs91mWwiEZxNgUOzR1ZQxYo_FTigJL3cYZNZfNAYnJe7Mrmn6Td3yvxypOk0ibxyIKdi8pAif5UCWCIx6Qf-V3viNHPbmkZK19PjTN1oYiWMfZXG8KYdVFpKRoXvFUpsXyKH69Z_feF9xjfZ-WNa8a6_hY5CHQMJhZfkcYGQa-M8fCwWdQV1y-jXXFs3_eMhnv0BUJoD2votuuc5m0Lq1d24EgsipSVfPglzjXjKxQsTHtcU0oL92ZHPfP8fzJUwAvUQooKOMHMD6kmeiUkzQN2-RGqstoZCXzcgq0u3WtmNkG8zqz2fFgL0pCRpZ1kXXNfN_htoAorVGkihuhZNVjVrinLiS3oB4FxtYrNZO4tpEzFfDk7G1wKJG_rUCdmIugYbNCqmsj5p3XPAcyaCY0mlWJjkzlvnnX4=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBWUnlH-WzSkkmE_e-_Jtz15nZTVmO9LjbxWmhGDTNeccqvv2ewA3oKsLPK78wTxxg2kgw89ckHK4_ayy2MNCAj9T8dfZjX2scjk2YnRt9iiignXFn5RELvzt5TRKC_VNVKUx8P-dj-jpDfUU3LJfoexIJLu_wnFByBa-HhrXuEVQp_AX-2n_-KOzk44dDwnNSPZY9H2PiGm5PsatmaHxBddu0eqQNMOtqkggobdH43K6ONGOOr4pG_9Ah_fssxkvum7jTCKSnRU_Mmqt4Pw8BXYYX4lTaKAQEi5bF-qYc1dAHodu3SiwQ66NXm2hcJa1Ye7jMzdP1qXolx-M3WytKVn-cI3u_F2UYMxwQzUwaxHMXDtZRUJc6O_zrPATavEvgpsOVb2a-rte1B8Wmgtnym9PclPPyh3F8mRR62R2mP0NetMH6utKN224g9Ph2bZIDbr9RXuWxVDkJT3xsHM4-g4-_2CbJSn6PbOf0HZl2shAsuYY230KRaPsMDUZcvx9ILM2LNjvEVlMzvI75FIdx2F36HoXodN5dQ5XYN-U3syGPotE46tav14BzE7Yycd_Xz7fa4ykVUUFz5WTqD0yzq5R0DdsBOlMAp6H1rFWoIwPuuha-sbGt7QKQbRWuTyzStDsijSjnG85cwePkX4stc47SsN5cFlQRAo8R7kkBQPBtDZ-79L3rqhiuOJHCjnr4vcVnwYREs91mWwiEZxNgUOzR1ZQxYo_FTigJL3cYZNZfNAYnJe7Mrmn6Td3yvxypOk0ibxyIKdi8pAif5UCWCIx6Qf-V3viNHPbmkZK19PjTN1oYiWMfZXG8KYdVFpKRoXvFUpsXyKH69Z_feF9xjfZ-WNa8a6_hY5CHQMJhZfkcYGQa-M8fCwWdQV1y-jXXFs3_eMhnv0BUJoD2votuuc5m0Lq1d24EgsipSVfPglzjXjKxQsTHtcU0oL92ZHPfP8fzJUwAvUQooKOMHMD6kmeiUkzQN2-RGqstoZCXzcgq0u3WtmNkG8zqz2fFgL0pCRpZ1kXXNfN_htoAorVGkihuhZNVjVrinLiS3oB4FxtYrNZO4tpEzFfDk7G1wKJG_rUCdmIugYbNCqmsj5p3XPAcyaCY0mlWJjkzlvnnX4=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GBWUnlH-WzSkkmE_e-_Jtz15nZTVmO9LjbxWmhGDTNeccqvv2ewA3oKsLPK78wTxxg2kgw89ckHK4_ayy2MNCAj9T8dfZjX2scjk2YnRt9iiignXFn5RELvzt5TRKC_VNVKUx8P-dj-jpDfUU3LJfoexIJLu_wnFByBa-HhrXuEVQp_AX-2n_-KOzk44dDwnNSPZY9H2PiGm5PsatmaHxBddu0eqQNMOtqkggobdH43K6ONGOOr4pG_9Ah_fssxkvum7jTCKSnRU_Mmqt4Pw8BXYYX4lTaKAQEi5bF-qYc1dAHodu3SiwQ66NXm2hcJa1Ye7jMzdP1qXolx-M3WytKVn-cI3u_F2UYMxwQzUwaxHMXDtZRUJc6O_zrPATavEvgpsOVb2a-rte1B8Wmgtnym9PclPPyh3F8mRR62R2mP0NetMH6utKN224g9Ph2bZIDbr9RXuWxVDkJT3xsHM4-g4-_2CbJSn6PbOf0HZl2shAsuYY230KRaPsMDUZcvx9ILM2LNjvEVlMzvI75FIdx2F36HoXodN5dQ5XYN-U3syGPotE46tav14BzE7Yycd_Xz7fa4ykVUUFz5WTqD0yzq5R0DdsBOlMAp6H1rFWoIwPuuha-sbGt7QKQbRWuTyzStDsijSjnG85cwePkX4stc47SsN5cFlQRAo8R7kkBQPBtDZ-79L3rqhiuOJHCjnr4vcVnwYREs91mWwiEZxNgUOzR1ZQxYo_FTigJL3cYZNZfNAYnJe7Mrmn6Td3yvxypOk0ibxyIKdi8pAif5UCWCIx6Qf-V3viNHPbmkZK19PjTN1oYiWMfZXG8KYdVFpKRoXvFUpsXyKH69Z_feF9xjfZ-WNa8a6_hY5CHQMJhZfkcYGQa-M8fCwWdQV1y-jXXFs3_eMhnv0BUJoD2votuuc5m0Lq1d24EgsipSVfPglzjXjKxQsTHtcU0oL92ZHPfP8fzJUwAvUQooKOMHMD6kmeiUkzQN2-RGqstoZCXzcgq0u3WtmNkG8zqz2fFgL0pCRpZ1kXXNfN_htoAorVGkihuhZNVjVrinLiS3oB4FxtYrNZO4tpEzFfDk7G1wKJG_rUCdmIugYbNCqmsj5p3XPAcyaCY0mlWJjkzlvnnX4=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1009.02,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywQkr7KpDCtNCVM_uyTz75o5ibPhsiODpf5DSDXcBdFsi_WQEKP6uuidKoXNgNPBXa58Om_N9RpNVhR7SDGyMihw8f2TibMLqZLi8YqqCpmLSmPdFlze3refMmIH0L2jdOleNvKQ95h_gqfWZWRhGnyrf16cqDA36BTape8wldcnGIPJ1rtc-RT2bffDzPdpHVR4t5Eybl6l7OkHEQ2ifqKLAfVoXAwVhA_oJeq9lOvFisWs0eppLCP70AUh8E0VI_Ckv14gFNy254AzukY85xjHwhtcr8tMVdXdVzR_Xj6-spGKXXwD0Ai3EahAT1cRziVMu4mPjpUvisiyGfh41rbVtQKINIFdLusycPQtZwngt2pidYl9DLXwr5STfDz-77W58No-rTiRhGstne_3NSlM9jRSiZ_0h-SO6rPJt6fiy0-vzDkqZU0GgqvSGYUvcXd5SiYgjGcVncWHCev_E3mP_1LhsgJvXlQpzr1i_4tiRIAjQytnyTk3CjApH5s-QSbu4MlS0rYGQgaWmaTIUr3NgpNvMX3wBwx0AajKsdvDOEY1BmIVROXD0tIEMMupnsk9dchjFlhRbwaB6J32kGgZRupz-Bz8JqcrhRdVPRNU2EK68Peq69yKPJisP7YfROjVBVSK1NrEBsCudSPglFHNUKgUsVPBbfYUz3F6aeN_06mpkjE7M_PCfhBMT259CaXjg7Dmr18SBSgYR2MvYjcUVpaASHn11nzxdOx4JKk3Nr1sjgu40-Q9DECU5Qqpnh9bi3XtyfXp3etoE6RmF7NZcgzNqnDUh1ZzTlmlEw9wveaNUpwMxuRKel8bTPHVKwMTHFbGsjxlIwoNufgd9jFZeJ7gzd3q-U66xRvlxNzlrIgJPvPwGhXTUIPMfU1iYDRtLm_eFs1HmFTsiEzaTgb3_KrURV90pn8p3dKqWd1X6ftQGtYzdEleCZ-dYEdtIG0iIB1tv20kAEREWLzAR_B4jk-18gUcgGQjKUT3sDMtpALUDgsN9Qa0-iFFiSyLD22FScaVqslua9lWkorDRJhSrbERjagBZ_64OhtShzfBclITmy2pXx0DekammWTY",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywQkr7KpDCtNCVM_uyTz75o5ibPhsiODpf5DSDXcBdFsi_WQEKP6uuidKoXNgNPBXa58Om_N9RpNVhR7SDGyMihw8f2TibMLqZLi8YqqCpmLSmPdFlze3refMmIH0L2jdOleNvKQ95h_gqfWZWRhGnyrf16cqDA36BTape8wldcnGIPJ1rtc-RT2bffDzPdpHVR4t5Eybl6l7OkHEQ2ifqKLAfVoXAwVhA_oJeq9lOvFisWs0eppLCP70AUh8E0VI_Ckv14gFNy254AzukY85xjHwhtcr8tMVdXdVzR_Xj6-spGKXXwD0Ai3EahAT1cRziVMu4mPjpUvisiyGfh41rbVtQKINIFdLusycPQtZwngt2pidYl9DLXwr5STfDz-77W58No-rTiRhGstne_3NSlM9jRSiZ_0h-SO6rPJt6fiy0-vzDkqZU0GgqvSGYUvcXd5SiYgjGcVncWHCev_E3mP_1LhsgJvXlQpzr1i_4tiRIAjQytnyTk3CjApH5s-QSbu4MlS0rYGQgaWmaTIUr3NgpNvMX3wBwx0AajKsdvDOEY1BmIVROXD0tIEMMupnsk9dchjFlhRbwaB6J32kGgZRupz-Bz8JqcrhRdVPRNU2EK68Peq69yKPJisP7YfROjVBVSK1NrEBsCudSPglFHNUKgUsVPBbfYUz3F6aeN_06mpkjE7M_PCfhBMT259CaXjg7Dmr18SBSgYR2MvYjcUVpaASHn11nzxdOx4JKk3Nr1sjgu40-Q9DECU5Qqpnh9bi3XtyfXp3etoE6RmF7NZcgzNqnDUh1ZzTlmlEw9wveaNUpwMxuRKel8bTPHVKwMTHFbGsjxlIwoNufgd9jFZeJ7gzd3q-U66xRvlxNzlrIgJPvPwGhXTUIPMfU1iYDRtLm_eFs1HmFTsiEzaTgb3_KrURV90pn8p3dKqWd1X6ftQGtYzdEleCZ-dYEdtIG0iIB1tv20kAEREWLzAR_B4jk-18gUcgGQjKUT3sDMtpALUDgsN9Qa0-iFFiSyLD22FScaVqslua9lWkorDRJhSrbERjagBZ_64OhtShzfBclITmy2pXx0DekammWTY",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywQkr7KpDCtNCVM_uyTz75o5ibPhsiODpf5DSDXcBdFsi_WQEKP6uuidKoXNgNPBXa58Om_N9RpNVhR7SDGyMihw8f2TibMLqZLi8YqqCpmLSmPdFlze3refMmIH0L2jdOleNvKQ95h_gqfWZWRhGnyrf16cqDA36BTape8wldcnGIPJ1rtc-RT2bffDzPdpHVR4t5Eybl6l7OkHEQ2ifqKLAfVoXAwVhA_oJeq9lOvFisWs0eppLCP70AUh8E0VI_Ckv14gFNy254AzukY85xjHwhtcr8tMVdXdVzR_Xj6-spGKXXwD0Ai3EahAT1cRziVMu4mPjpUvisiyGfh41rbVtQKINIFdLusycPQtZwngt2pidYl9DLXwr5STfDz-77W58No-rTiRhGstne_3NSlM9jRSiZ_0h-SO6rPJt6fiy0-vzDkqZU0GgqvSGYUvcXd5SiYgjGcVncWHCev_E3mP_1LhsgJvXlQpzr1i_4tiRIAjQytnyTk3CjApH5s-QSbu4MlS0rYGQgaWmaTIUr3NgpNvMX3wBwx0AajKsdvDOEY1BmIVROXD0tIEMMupnsk9dchjFlhRbwaB6J32kGgZRupz-Bz8JqcrhRdVPRNU2EK68Peq69yKPJisP7YfROjVBVSK1NrEBsCudSPglFHNUKgUsVPBbfYUz3F6aeN_06mpkjE7M_PCfhBMT259CaXjg7Dmr18SBSgYR2MvYjcUVpaASHn11nzxdOx4JKk3Nr1sjgu40-Q9DECU5Qqpnh9bi3XtyfXp3etoE6RmF7NZcgzNqnDUh1ZzTlmlEw9wveaNUpwMxuRKel8bTPHVKwMTHFbGsjxlIwoNufgd9jFZeJ7gzd3q-U66xRvlxNzlrIgJPvPwGhXTUIPMfU1iYDRtLm_eFs1HmFTsiEzaTgb3_KrURV90pn8p3dKqWd1X6ftQGtYzdEleCZ-dYEdtIG0iIB1tv20kAEREWLzAR_B4jk-18gUcgGQjKUT3sDMtpALUDgsN9Qa0-iFFiSyLD22FScaVqslua9lWkorDRJhSrbERjagBZ_64OhtShzfBclITmy2pXx0DekammWTY",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1009.86,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 820,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GMvSaeIfIUDp-zifJIoWyo7ayNUNJzjbTyUXnTNWlBpDU5rAuD6DPz92ob5ws2z1RWGrqST5fbeVRfiWiICePqzY71cej_1pWr_0b5RSbdp3b0YKnmmAp8mcROyxJSgB206AwUCm_In2Xr6EpPu7jdSCE-9ECJ2Me6wHPZJP_QuZsQxps7YEeCuZUfJxZtchTiu1YeextzP6-81TUG4zusStxnSjkgV_Cb0t1WXo_Ft-h9h4C9l7nmoX7AGzddCGn-hC7NcZ4QtB_BSrdIyKgeNePLkGWyzPJ-zp5sNp7EJlapP6ZDcYDkuehlaPg6JkqVcqJD5PR0bfhkfZvmWynuI8yVVPT-MmuzPHP3sCl5eccdCZn5bweFNXz988glGaA1wjP7IfPU58F-3mEBxlzRtxigM7gnMN3I0nxS9iQvGawc0FER9-1kLJfXp-1Wn30UPyz46rw1QlKHRX1ykfXm0my6K1vqDHF_Eoce-rC_ZPEzQCFXkgiep-eGEaWZrbohBZIngW21aoX3uhnoZSDxtR-3c4pN5iUpc9KE_Qg95astPgiAuAakQjS-H7czngiUi7l-QdiAJbj-ab-992mPHtfq5oMe49rB08DbFytJXeN8bTjruSOJdK0D378w70PbruNTGGiqtDY-OwmepVNjF0b8BiL8apjUGOaLdutMDxHXUthSNbQia6c0_weWL5XP1cCz070sjoDb25FmvyPfzw52hfgcIIeV0KQttoxu6sw23qt9lFLOygy9EVHla1x0NWQ1ISpqxstvWszw4aDLD_ybnU4QB0YdSzzRrMYs-ABOfSXuzLFfbfGtmVg2D_CpTw86lChTfT7l-wnaAE2g-tZpCX6p-qwUgIOrNIaB6ZjDUU6zgVDBDs4ZtUkRGKH1tZaY2Ubs2s1rtYoJEkZgslfOVizwvrt-4dmAyEfDrkSTawmuH2DV5WHAxi3r3jSAla45buKEJJ3uE6oy6wm-GQbCc1sgYA2N-pY_x1ek-z4p8fcD7wAUbE1fwVz1ZJ-lsNgN0sOs_2sxAaVInmOP4hqY6UmJgu9PG1FFDwhJe2Bwke_wDFmiTVcXe57M9vnMnAcSSvyod5Tvqdt_HNl8lQ-S2rLcQ-j3tFn25r6QZjNP3nGxWFzq4hzmlDBocuE9ffg9-GYRMWX1HxgTfzLbKjYbmd49ZNIUK4lG5IzZaga2DtWm3fzqhUTZzpWrmbfElLkWp_Ey3iOGkofVZd9sg==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GMvSaeIfIUDp-zifJIoWyo7ayNUNJzjbTyUXnTNWlBpDU5rAuD6DPz92ob5ws2z1RWGrqST5fbeVRfiWiICePqzY71cej_1pWr_0b5RSbdp3b0YKnmmAp8mcROyxJSgB206AwUCm_In2Xr6EpPu7jdSCE-9ECJ2Me6wHPZJP_QuZsQxps7YEeCuZUfJxZtchTiu1YeextzP6-81TUG4zusStxnSjkgV_Cb0t1WXo_Ft-h9h4C9l7nmoX7AGzddCGn-hC7NcZ4QtB_BSrdIyKgeNePLkGWyzPJ-zp5sNp7EJlapP6ZDcYDkuehlaPg6JkqVcqJD5PR0bfhkfZvmWynuI8yVVPT-MmuzPHP3sCl5eccdCZn5bweFNXz988glGaA1wjP7IfPU58F-3mEBxlzRtxigM7gnMN3I0nxS9iQvGawc0FER9-1kLJfXp-1Wn30UPyz46rw1QlKHRX1ykfXm0my6K1vqDHF_Eoce-rC_ZPEzQCFXkgiep-eGEaWZrbohBZIngW21aoX3uhnoZSDxtR-3c4pN5iUpc9KE_Qg95astPgiAuAakQjS-H7czngiUi7l-QdiAJbj-ab-992mPHtfq5oMe49rB08DbFytJXeN8bTjruSOJdK0D378w70PbruNTGGiqtDY-OwmepVNjF0b8BiL8apjUGOaLdutMDxHXUthSNbQia6c0_weWL5XP1cCz070sjoDb25FmvyPfzw52hfgcIIeV0KQttoxu6sw23qt9lFLOygy9EVHla1x0NWQ1ISpqxstvWszw4aDLD_ybnU4QB0YdSzzRrMYs-ABOfSXuzLFfbfGtmVg2D_CpTw86lChTfT7l-wnaAE2g-tZpCX6p-qwUgIOrNIaB6ZjDUU6zgVDBDs4ZtUkRGKH1tZaY2Ubs2s1rtYoJEkZgslfOVizwvrt-4dmAyEfDrkSTawmuH2DV5WHAxi3r3jSAla45buKEJJ3uE6oy6wm-GQbCc1sgYA2N-pY_x1ek-z4p8fcD7wAUbE1fwVz1ZJ-lsNgN0sOs_2sxAaVInmOP4hqY6UmJgu9PG1FFDwhJe2Bwke_wDFmiTVcXe57M9vnMnAcSSvyod5Tvqdt_HNl8lQ-S2rLcQ-j3tFn25r6QZjNP3nGxWFzq4hzmlDBocuE9ffg9-GYRMWX1HxgTfzLbKjYbmd49ZNIUK4lG5IzZaga2DtWm3fzqhUTZzpWrmbfElLkWp_Ey3iOGkofVZd9sg==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C244610a14e240000b569d27c_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GMvSaeIfIUDp-zifJIoWyo7ayNUNJzjbTyUXnTNWlBpDU5rAuD6DPz92ob5ws2z1RWGrqST5fbeVRfiWiICePqzY71cej_1pWr_0b5RSbdp3b0YKnmmAp8mcROyxJSgB206AwUCm_In2Xr6EpPu7jdSCE-9ECJ2Me6wHPZJP_QuZsQxps7YEeCuZUfJxZtchTiu1YeextzP6-81TUG4zusStxnSjkgV_Cb0t1WXo_Ft-h9h4C9l7nmoX7AGzddCGn-hC7NcZ4QtB_BSrdIyKgeNePLkGWyzPJ-zp5sNp7EJlapP6ZDcYDkuehlaPg6JkqVcqJD5PR0bfhkfZvmWynuI8yVVPT-MmuzPHP3sCl5eccdCZn5bweFNXz988glGaA1wjP7IfPU58F-3mEBxlzRtxigM7gnMN3I0nxS9iQvGawc0FER9-1kLJfXp-1Wn30UPyz46rw1QlKHRX1ykfXm0my6K1vqDHF_Eoce-rC_ZPEzQCFXkgiep-eGEaWZrbohBZIngW21aoX3uhnoZSDxtR-3c4pN5iUpc9KE_Qg95astPgiAuAakQjS-H7czngiUi7l-QdiAJbj-ab-992mPHtfq5oMe49rB08DbFytJXeN8bTjruSOJdK0D378w70PbruNTGGiqtDY-OwmepVNjF0b8BiL8apjUGOaLdutMDxHXUthSNbQia6c0_weWL5XP1cCz070sjoDb25FmvyPfzw52hfgcIIeV0KQttoxu6sw23qt9lFLOygy9EVHla1x0NWQ1ISpqxstvWszw4aDLD_ybnU4QB0YdSzzRrMYs-ABOfSXuzLFfbfGtmVg2D_CpTw86lChTfT7l-wnaAE2g-tZpCX6p-qwUgIOrNIaB6ZjDUU6zgVDBDs4ZtUkRGKH1tZaY2Ubs2s1rtYoJEkZgslfOVizwvrt-4dmAyEfDrkSTawmuH2DV5WHAxi3r3jSAla45buKEJJ3uE6oy6wm-GQbCc1sgYA2N-pY_x1ek-z4p8fcD7wAUbE1fwVz1ZJ-lsNgN0sOs_2sxAaVInmOP4hqY6UmJgu9PG1FFDwhJe2Bwke_wDFmiTVcXe57M9vnMnAcSSvyod5Tvqdt_HNl8lQ-S2rLcQ-j3tFn25r6QZjNP3nGxWFzq4hzmlDBocuE9ffg9-GYRMWX1HxgTfzLbKjYbmd49ZNIUK4lG5IzZaga2DtWm3fzqhUTZzpWrmbfElLkWp_Ey3iOGkofVZd9sg==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e240000b569d27c",
                    "equipment": null,
                    "fare_basis": "GA3NR",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e240000b569d27c_0",
                    "local_arrival": "2024-10-08T18:35:00.000Z",
                    "local_departure": "2024-10-08T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:35:00.000Z",
                    "utc_departure": "2024-10-08T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1016.81,
            "totalPrice": 820
          },
          {
            "allPassengerTotalPrice": 821,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRKnbAijdGt4fCdckUliq0nzzk2SHn_PHaHGFegNOxpuPJaPjV1DjlZQxbkfIkoUPt4DXp0rLght2WYZaXKOpSGb6GHj7moDSeGFiyLe94xN3ktRvcqZBSIOXRjLrdbedoxY4NdGA1KkK3IpncobWm1WbhwlxVbDwN6Uafkk8YIdOb_0IWL1W0ynuEFrh-hqQPzVrj-2JMV1p_yABr6Q3UXQqaUaaCDoq4D_TM0t3oi3x-U4RaSMLgOXWfhLiZgtS0jL_6VFcyzdGuBmy3r0AP5Tqv3TbexEfs65H9L37fsZm4AO8VUqTuJAOxxZNObxqsNFg5B9ZfcI-5YNnk9CjgViPeTtDDjrG6IThAONeAjCoxYxty8Zb6InotFUfwu1eIY8MwPT-2sVOTvvbUbhwWtS_ecQ2vFgWS6JHjp9KBfDgp0fTpwpNl1FPuG5gvo3GsnqwKFO7fwpINuR67h1z8FNO4W1hrno0hH2x9njnZzw3wg-xtFjmirAxKRt1mhX72qPJf1AoSNmg--ca70rXZXY0na3U3sIrL9_lo_qV6y3DcY5gmjPJre1o6_SbFr0_SDG-7bl6FyiX9t9IY4hXPTDhC6ByK42xDNS0dd9BwTlACkDxmT-GdHbFpydSKKRJwiz-J8nKKGBQNpXnuVfFo_msh1POhJB21Vb1IoRXj5mQYfy2sdKdsUYR4sW-MzIxNV0IcGavkgBb2naUO-KummmPBFUpt03nhF0l8HyRqaNBPJmBVTw5A4HocWSPgG2f7lAKZo2ZSR-IJlNIQJodUOxBvMdw8L5NpegNSTmx4WGqWXIetB-WOnAFD7bZzHAT9j92m9SkTa5GNzGYjntsSnz2lfWeOmnG7ECp4qmGNRoQYNWbDgEzpykr1ByzdsSEl0dLuuao_Nk_YpzIwPV83cLnBNMOhewDJM5UghPuNgd0alozPF2B0je9vlPoeP0XUrTqaoKWB7YXhsOK9n9J1F77_wHTxZD7cnVSY7Qjgz2-_jpGalUPQfiTvg51HD8QCkUnFZWdRJDjAffcaAsEQ0bu_d_Ua2dQzEc_WX3dkIXt-PS4SWMLeaJkDZVs1xI0os6uh-wuq7q-whcdN9zk8A==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRKnbAijdGt4fCdckUliq0nzzk2SHn_PHaHGFegNOxpuPJaPjV1DjlZQxbkfIkoUPt4DXp0rLght2WYZaXKOpSGb6GHj7moDSeGFiyLe94xN3ktRvcqZBSIOXRjLrdbedoxY4NdGA1KkK3IpncobWm1WbhwlxVbDwN6Uafkk8YIdOb_0IWL1W0ynuEFrh-hqQPzVrj-2JMV1p_yABr6Q3UXQqaUaaCDoq4D_TM0t3oi3x-U4RaSMLgOXWfhLiZgtS0jL_6VFcyzdGuBmy3r0AP5Tqv3TbexEfs65H9L37fsZm4AO8VUqTuJAOxxZNObxqsNFg5B9ZfcI-5YNnk9CjgViPeTtDDjrG6IThAONeAjCoxYxty8Zb6InotFUfwu1eIY8MwPT-2sVOTvvbUbhwWtS_ecQ2vFgWS6JHjp9KBfDgp0fTpwpNl1FPuG5gvo3GsnqwKFO7fwpINuR67h1z8FNO4W1hrno0hH2x9njnZzw3wg-xtFjmirAxKRt1mhX72qPJf1AoSNmg--ca70rXZXY0na3U3sIrL9_lo_qV6y3DcY5gmjPJre1o6_SbFr0_SDG-7bl6FyiX9t9IY4hXPTDhC6ByK42xDNS0dd9BwTlACkDxmT-GdHbFpydSKKRJwiz-J8nKKGBQNpXnuVfFo_msh1POhJB21Vb1IoRXj5mQYfy2sdKdsUYR4sW-MzIxNV0IcGavkgBb2naUO-KummmPBFUpt03nhF0l8HyRqaNBPJmBVTw5A4HocWSPgG2f7lAKZo2ZSR-IJlNIQJodUOxBvMdw8L5NpegNSTmx4WGqWXIetB-WOnAFD7bZzHAT9j92m9SkTa5GNzGYjntsSnz2lfWeOmnG7ECp4qmGNRoQYNWbDgEzpykr1ByzdsSEl0dLuuao_Nk_YpzIwPV83cLnBNMOhewDJM5UghPuNgd0alozPF2B0je9vlPoeP0XUrTqaoKWB7YXhsOK9n9J1F77_wHTxZD7cnVSY7Qjgz2-_jpGalUPQfiTvg51HD8QCkUnFZWdRJDjAffcaAsEQ0bu_d_Ua2dQzEc_WX3dkIXt-PS4SWMLeaJkDZVs1xI0os6uh-wuq7q-whcdN9zk8A==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRKnbAijdGt4fCdckUliq0nzzk2SHn_PHaHGFegNOxpuPJaPjV1DjlZQxbkfIkoUPt4DXp0rLght2WYZaXKOpSGb6GHj7moDSeGFiyLe94xN3ktRvcqZBSIOXRjLrdbedoxY4NdGA1KkK3IpncobWm1WbhwlxVbDwN6Uafkk8YIdOb_0IWL1W0ynuEFrh-hqQPzVrj-2JMV1p_yABr6Q3UXQqaUaaCDoq4D_TM0t3oi3x-U4RaSMLgOXWfhLiZgtS0jL_6VFcyzdGuBmy3r0AP5Tqv3TbexEfs65H9L37fsZm4AO8VUqTuJAOxxZNObxqsNFg5B9ZfcI-5YNnk9CjgViPeTtDDjrG6IThAONeAjCoxYxty8Zb6InotFUfwu1eIY8MwPT-2sVOTvvbUbhwWtS_ecQ2vFgWS6JHjp9KBfDgp0fTpwpNl1FPuG5gvo3GsnqwKFO7fwpINuR67h1z8FNO4W1hrno0hH2x9njnZzw3wg-xtFjmirAxKRt1mhX72qPJf1AoSNmg--ca70rXZXY0na3U3sIrL9_lo_qV6y3DcY5gmjPJre1o6_SbFr0_SDG-7bl6FyiX9t9IY4hXPTDhC6ByK42xDNS0dd9BwTlACkDxmT-GdHbFpydSKKRJwiz-J8nKKGBQNpXnuVfFo_msh1POhJB21Vb1IoRXj5mQYfy2sdKdsUYR4sW-MzIxNV0IcGavkgBb2naUO-KummmPBFUpt03nhF0l8HyRqaNBPJmBVTw5A4HocWSPgG2f7lAKZo2ZSR-IJlNIQJodUOxBvMdw8L5NpegNSTmx4WGqWXIetB-WOnAFD7bZzHAT9j92m9SkTa5GNzGYjntsSnz2lfWeOmnG7ECp4qmGNRoQYNWbDgEzpykr1ByzdsSEl0dLuuao_Nk_YpzIwPV83cLnBNMOhewDJM5UghPuNgd0alozPF2B0je9vlPoeP0XUrTqaoKWB7YXhsOK9n9J1F77_wHTxZD7cnVSY7Qjgz2-_jpGalUPQfiTvg51HD8QCkUnFZWdRJDjAffcaAsEQ0bu_d_Ua2dQzEc_WX3dkIXt-PS4SWMLeaJkDZVs1xI0os6uh-wuq7q-whcdN9zk8A==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1014.8,
            "totalPrice": 821
          },
          {
            "allPassengerTotalPrice": 822,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5EEbOtDCTd3BDHvQPJ8GwsJjP4W4r3ykGCDwF1nwIDlCfd7w42WMznsiB6VFXtgX-5aOjWxbMWBi5KtWCBa4cqELrE082DU_hCaIF1hK5UwDnWif-fVOxgAwnp4CqDrts1DkfW-ZpP9zcG-iYsZ8tWRpSpJE6sY0CqCR8XSORmFw5zkZ2C3rGVjtagCjI9y2wXJADd50beyj_MvENTlyn_edJ4o8B17fhoaZEYkJKj8U_2ZfrYqLxF3DmVLS3vkfwRDqMvt0aIiWh1N8QXBfA-CVWyGQOBm2Umks5mlpxFhOOtPa9lz075C32tcVVboz6t7zJF0r0VRJkSCZ9mDSZdU1qb3UcVivGVYuqBVZMMHlQixGOIsc-l8hvy9XawvHKD0SgVWV1LsJBVmm-rl-YrjEV91NgsCHsVqF_gR_Vk__W4Bd16fSbCBpfOieUyKitrHz63x9XFGqzzGO4hYRhU3kV2nO9YNWW_amvXyAGuoPCNrvq9QUt-h-DULSCl0LiFJIIKGf9qwcDgsVuY-ZqZxQ2HqegtOPR38yuwdjC5dwpTT6GUZvNB0bbOCekM6Gu7joDqLKc7ckC5_55yUPFiZ4DD25WKqELHQYkSNTrP21dpKKTCb2MmucAdVTsDYi6uehOdAIH7JjDsSKpRbkUgnrlbhjgn2pY0Si1WZRX5x3-EynvkxUibxXo5i2i0LXDKTSB-2fTPB98_nizejPm1xneGxzHYsm8DxQAuLcmn3m7HmlS3rA2DJGlSgQEpgUuj-AgllRMiB8PAqkZk18rZcXodxi3su6gR-C7k5iNOAIlC5-aiw51gOWMx__bs_H7gCeA7nj1VQC0oYiiqTxrSvI5-QXGPqs-ljCgb0yR7xy03frUNaMg5v0ITULP_XAe19LLfaMtuFLuc9rF7tS01lYfc-3mgPAZf3NstWJQ-5BHSr98Hh7Mt5c4uqOBJBs4PB8zkpNdJqnwrjkrG-pVp6WEZq3zjdiPyaj0Rhpo2yk9gMuUn14tSAtbV44RVcmNxIlfluo3EkdcpfAJKpH12LtOs_N6kdMRRbTWvM9SOCCGPeV1uDywzIkxyLOtMtpMqrn3ajL2arBoaMKrxYjkHuNMrK5fqwkNhtf1vkAsaFe8yoLsX2YoXHQw-0zxEazWjUPE5_FL9RzZC58G1T0606eK2j5ktzqDPu8T9arB408V360rN5jmZilQXdWsunIEs7MZJll7vNGAVxUbAAeMg==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5EEbOtDCTd3BDHvQPJ8GwsJjP4W4r3ykGCDwF1nwIDlCfd7w42WMznsiB6VFXtgX-5aOjWxbMWBi5KtWCBa4cqELrE082DU_hCaIF1hK5UwDnWif-fVOxgAwnp4CqDrts1DkfW-ZpP9zcG-iYsZ8tWRpSpJE6sY0CqCR8XSORmFw5zkZ2C3rGVjtagCjI9y2wXJADd50beyj_MvENTlyn_edJ4o8B17fhoaZEYkJKj8U_2ZfrYqLxF3DmVLS3vkfwRDqMvt0aIiWh1N8QXBfA-CVWyGQOBm2Umks5mlpxFhOOtPa9lz075C32tcVVboz6t7zJF0r0VRJkSCZ9mDSZdU1qb3UcVivGVYuqBVZMMHlQixGOIsc-l8hvy9XawvHKD0SgVWV1LsJBVmm-rl-YrjEV91NgsCHsVqF_gR_Vk__W4Bd16fSbCBpfOieUyKitrHz63x9XFGqzzGO4hYRhU3kV2nO9YNWW_amvXyAGuoPCNrvq9QUt-h-DULSCl0LiFJIIKGf9qwcDgsVuY-ZqZxQ2HqegtOPR38yuwdjC5dwpTT6GUZvNB0bbOCekM6Gu7joDqLKc7ckC5_55yUPFiZ4DD25WKqELHQYkSNTrP21dpKKTCb2MmucAdVTsDYi6uehOdAIH7JjDsSKpRbkUgnrlbhjgn2pY0Si1WZRX5x3-EynvkxUibxXo5i2i0LXDKTSB-2fTPB98_nizejPm1xneGxzHYsm8DxQAuLcmn3m7HmlS3rA2DJGlSgQEpgUuj-AgllRMiB8PAqkZk18rZcXodxi3su6gR-C7k5iNOAIlC5-aiw51gOWMx__bs_H7gCeA7nj1VQC0oYiiqTxrSvI5-QXGPqs-ljCgb0yR7xy03frUNaMg5v0ITULP_XAe19LLfaMtuFLuc9rF7tS01lYfc-3mgPAZf3NstWJQ-5BHSr98Hh7Mt5c4uqOBJBs4PB8zkpNdJqnwrjkrG-pVp6WEZq3zjdiPyaj0Rhpo2yk9gMuUn14tSAtbV44RVcmNxIlfluo3EkdcpfAJKpH12LtOs_N6kdMRRbTWvM9SOCCGPeV1uDywzIkxyLOtMtpMqrn3ajL2arBoaMKrxYjkHuNMrK5fqwkNhtf1vkAsaFe8yoLsX2YoXHQw-0zxEazWjUPE5_FL9RzZC58G1T0606eK2j5ktzqDPu8T9arB408V360rN5jmZilQXdWsunIEs7MZJll7vNGAVxUbAAeMg==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "23:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af22f54e24000091d2500f_0%7C22f524464e240000f104acfb_0%7C2446076e4e240000a0df67a1_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5EEbOtDCTd3BDHvQPJ8GwsJjP4W4r3ykGCDwF1nwIDlCfd7w42WMznsiB6VFXtgX-5aOjWxbMWBi5KtWCBa4cqELrE082DU_hCaIF1hK5UwDnWif-fVOxgAwnp4CqDrts1DkfW-ZpP9zcG-iYsZ8tWRpSpJE6sY0CqCR8XSORmFw5zkZ2C3rGVjtagCjI9y2wXJADd50beyj_MvENTlyn_edJ4o8B17fhoaZEYkJKj8U_2ZfrYqLxF3DmVLS3vkfwRDqMvt0aIiWh1N8QXBfA-CVWyGQOBm2Umks5mlpxFhOOtPa9lz075C32tcVVboz6t7zJF0r0VRJkSCZ9mDSZdU1qb3UcVivGVYuqBVZMMHlQixGOIsc-l8hvy9XawvHKD0SgVWV1LsJBVmm-rl-YrjEV91NgsCHsVqF_gR_Vk__W4Bd16fSbCBpfOieUyKitrHz63x9XFGqzzGO4hYRhU3kV2nO9YNWW_amvXyAGuoPCNrvq9QUt-h-DULSCl0LiFJIIKGf9qwcDgsVuY-ZqZxQ2HqegtOPR38yuwdjC5dwpTT6GUZvNB0bbOCekM6Gu7joDqLKc7ckC5_55yUPFiZ4DD25WKqELHQYkSNTrP21dpKKTCb2MmucAdVTsDYi6uehOdAIH7JjDsSKpRbkUgnrlbhjgn2pY0Si1WZRX5x3-EynvkxUibxXo5i2i0LXDKTSB-2fTPB98_nizejPm1xneGxzHYsm8DxQAuLcmn3m7HmlS3rA2DJGlSgQEpgUuj-AgllRMiB8PAqkZk18rZcXodxi3su6gR-C7k5iNOAIlC5-aiw51gOWMx__bs_H7gCeA7nj1VQC0oYiiqTxrSvI5-QXGPqs-ljCgb0yR7xy03frUNaMg5v0ITULP_XAe19LLfaMtuFLuc9rF7tS01lYfc-3mgPAZf3NstWJQ-5BHSr98Hh7Mt5c4uqOBJBs4PB8zkpNdJqnwrjkrG-pVp6WEZq3zjdiPyaj0Rhpo2yk9gMuUn14tSAtbV44RVcmNxIlfluo3EkdcpfAJKpH12LtOs_N6kdMRRbTWvM9SOCCGPeV1uDywzIkxyLOtMtpMqrn3ajL2arBoaMKrxYjkHuNMrK5fqwkNhtf1vkAsaFe8yoLsX2YoXHQw-0zxEazWjUPE5_FL9RzZC58G1T0606eK2j5ktzqDPu8T9arB408V360rN5jmZilQXdWsunIEs7MZJll7vNGAVxUbAAeMg==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SJC",
                "duration": "33h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e24000091d2500f",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 7822,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e24000091d2500f_0",
                    "local_arrival": "2024-10-08T08:55:00.000Z",
                    "local_departure": "2024-10-08T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "7822",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:55:00.000Z",
                    "utc_departure": "2024-10-08T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SJC",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Jose",
                    "combination_id": "2446076e4e240000a0df67a1",
                    "equipment": null,
                    "fare_basis": "G7SYBY4",
                    "fare_category": "M",
                    "fare_classes": "G",
                    "flight_no": 2522,
                    "flyFrom": "LAS",
                    "flyTo": "SJC",
                    "guarantee": true,
                    "id": "2446076e4e240000a0df67a1_0",
                    "local_arrival": "2024-10-08T23:15:00.000Z",
                    "local_departure": "2024-10-08T21:45:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2522",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:15:00.000Z",
                    "utc_departure": "2024-10-09T04:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1055.9,
            "totalPrice": 822
          },
          {
            "allPassengerTotalPrice": 822,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GgiSnihfhEZfEDSL-TdSBHDn3265VJsYTXr3yW3IOODup5i1ZBU6nsYxrSBNApQQcAVIim6eFviIJczB8H-uvGRS3Z-938KPuAsMEJx_35qFd7sqOJSmQ7PUd1qu9-a2zg3EyJgZkFlFRiGP1BpfjuX1OiwLKXfkhZdMWo_Uvsmt44eMb1Qsmr-B8I8_Pe15T-wCVpVvBHiPH_KEa5JVHdX1mJxFXUqUzIzasb6R8SP3gq3sNpmr8tFopVxuurp1Hka_sy295ctGFjaL5mdT-YM048nmQZHiLeq0pFgC7dtYtc94iZjD4M95uo7CUtfjN1x93vxt6qYgmYXUEaqBx_SbnFa-cQVjsGtVjJn4aOAKIKoFoVv6QuoFiIjvJdPqRRq0FVWcj7nPGsZkslNR_VplOx4fqJBZSgZaMF9jiQY1vK1gsTa6wcXn7cYjjZItMbmZOAfDSk0567X-gR1z6wFC74d5Bl1q2h18KYiwLlaoYuDhj00upK37xrMnmLa2Wh-GfeeEUjVgRgx62Zk9UBTIqMbpApAwAkjSaeXIX5U0v4Mzn41p0fkYDqUhDSA0zfcrVcE3vtQgqxZkI2TuxshSA42njGjcI06ocA9Ig052jBI04aZM26F9L3IWNFLimxHnvm7gMdT6p-kCKSGIbLRxYpmsyrTrrsQ41F47-SnjnQCl1QZtdCiFcJFMM5fqfYVh5u_yD3KzNQETDdjUOBYbrd26uL0LHH2aSPxiGiPI-03M0i2SOeIboqNJZQmI15hVk0_DCoojXjWsVOOEbD5mRSXEggaaDsMgC8wWXI-DXPl9yxy2wB-mXJ6ddr5TKzp6Djzqc_8-W4OEA_nZJptJu1LOsEtcMqX_phpDMDkLn_63zq3XaaIewP4nuwgfE5D-HMf9FW7oAYS0VMu-j5Iesn5beMepR1oYyqiuJRY2Wz06DKnXtzkG-CE-j15ObZA-ZKPTbTO-ydmdW6c8qVMR4RlqOXyFWJB6NWbEvdVqtn-j7bAb7LpUYuS9MWTJY075-II4tD14KKkOSG97gIlXnNXt1zsLuu5uzdBK9hcc9PjOkKEfNjC1lkWmQKyA5dXyxYYqIAIO0iLLjhG81XwQ-Y4-nACIaVwirg_NHO1Q4I2sNp_YKPpNxP6kdSU2g0qlqDk3dLs9SG-vqIFR7DA==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-02",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GgiSnihfhEZfEDSL-TdSBHDn3265VJsYTXr3yW3IOODup5i1ZBU6nsYxrSBNApQQcAVIim6eFviIJczB8H-uvGRS3Z-938KPuAsMEJx_35qFd7sqOJSmQ7PUd1qu9-a2zg3EyJgZkFlFRiGP1BpfjuX1OiwLKXfkhZdMWo_Uvsmt44eMb1Qsmr-B8I8_Pe15T-wCVpVvBHiPH_KEa5JVHdX1mJxFXUqUzIzasb6R8SP3gq3sNpmr8tFopVxuurp1Hka_sy295ctGFjaL5mdT-YM048nmQZHiLeq0pFgC7dtYtc94iZjD4M95uo7CUtfjN1x93vxt6qYgmYXUEaqBx_SbnFa-cQVjsGtVjJn4aOAKIKoFoVv6QuoFiIjvJdPqRRq0FVWcj7nPGsZkslNR_VplOx4fqJBZSgZaMF9jiQY1vK1gsTa6wcXn7cYjjZItMbmZOAfDSk0567X-gR1z6wFC74d5Bl1q2h18KYiwLlaoYuDhj00upK37xrMnmLa2Wh-GfeeEUjVgRgx62Zk9UBTIqMbpApAwAkjSaeXIX5U0v4Mzn41p0fkYDqUhDSA0zfcrVcE3vtQgqxZkI2TuxshSA42njGjcI06ocA9Ig052jBI04aZM26F9L3IWNFLimxHnvm7gMdT6p-kCKSGIbLRxYpmsyrTrrsQ41F47-SnjnQCl1QZtdCiFcJFMM5fqfYVh5u_yD3KzNQETDdjUOBYbrd26uL0LHH2aSPxiGiPI-03M0i2SOeIboqNJZQmI15hVk0_DCoojXjWsVOOEbD5mRSXEggaaDsMgC8wWXI-DXPl9yxy2wB-mXJ6ddr5TKzp6Djzqc_8-W4OEA_nZJptJu1LOsEtcMqX_phpDMDkLn_63zq3XaaIewP4nuwgfE5D-HMf9FW7oAYS0VMu-j5Iesn5beMepR1oYyqiuJRY2Wz06DKnXtzkG-CE-j15ObZA-ZKPTbTO-ydmdW6c8qVMR4RlqOXyFWJB6NWbEvdVqtn-j7bAb7LpUYuS9MWTJY075-II4tD14KKkOSG97gIlXnNXt1zsLuu5uzdBK9hcc9PjOkKEfNjC1lkWmQKyA5dXyxYYqIAIO0iLLjhG81XwQ-Y4-nACIaVwirg_NHO1Q4I2sNp_YKPpNxP6kdSU2g0qlqDk3dLs9SG-vqIFR7DA==",
                "dateChange": 1,
                "departureDate": "2024-10-01",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e1d0000781a283d",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e1d0000781a283d_0",
                    "local_arrival": "2024-10-01T23:35:00.000Z",
                    "local_departure": "2024-10-01T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-01T21:35:00.000Z",
                    "utc_departure": "2024-10-01T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e1e0000846aec65",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e1e0000846aec65_0",
                    "local_arrival": "2024-10-02T07:40:00.000Z",
                    "local_departure": "2024-10-02T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-02T05:40:00.000Z",
                    "utc_departure": "2024-10-02T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C141413a24e1d0000781a283d_0%7C13a20a224e1e0000846aec65_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GgiSnihfhEZfEDSL-TdSBHDn3265VJsYTXr3yW3IOODup5i1ZBU6nsYxrSBNApQQcAVIim6eFviIJczB8H-uvGRS3Z-938KPuAsMEJx_35qFd7sqOJSmQ7PUd1qu9-a2zg3EyJgZkFlFRiGP1BpfjuX1OiwLKXfkhZdMWo_Uvsmt44eMb1Qsmr-B8I8_Pe15T-wCVpVvBHiPH_KEa5JVHdX1mJxFXUqUzIzasb6R8SP3gq3sNpmr8tFopVxuurp1Hka_sy295ctGFjaL5mdT-YM048nmQZHiLeq0pFgC7dtYtc94iZjD4M95uo7CUtfjN1x93vxt6qYgmYXUEaqBx_SbnFa-cQVjsGtVjJn4aOAKIKoFoVv6QuoFiIjvJdPqRRq0FVWcj7nPGsZkslNR_VplOx4fqJBZSgZaMF9jiQY1vK1gsTa6wcXn7cYjjZItMbmZOAfDSk0567X-gR1z6wFC74d5Bl1q2h18KYiwLlaoYuDhj00upK37xrMnmLa2Wh-GfeeEUjVgRgx62Zk9UBTIqMbpApAwAkjSaeXIX5U0v4Mzn41p0fkYDqUhDSA0zfcrVcE3vtQgqxZkI2TuxshSA42njGjcI06ocA9Ig052jBI04aZM26F9L3IWNFLimxHnvm7gMdT6p-kCKSGIbLRxYpmsyrTrrsQ41F47-SnjnQCl1QZtdCiFcJFMM5fqfYVh5u_yD3KzNQETDdjUOBYbrd26uL0LHH2aSPxiGiPI-03M0i2SOeIboqNJZQmI15hVk0_DCoojXjWsVOOEbD5mRSXEggaaDsMgC8wWXI-DXPl9yxy2wB-mXJ6ddr5TKzp6Djzqc_8-W4OEA_nZJptJu1LOsEtcMqX_phpDMDkLn_63zq3XaaIewP4nuwgfE5D-HMf9FW7oAYS0VMu-j5Iesn5beMepR1oYyqiuJRY2Wz06DKnXtzkG-CE-j15ObZA-ZKPTbTO-ydmdW6c8qVMR4RlqOXyFWJB6NWbEvdVqtn-j7bAb7LpUYuS9MWTJY075-II4tD14KKkOSG97gIlXnNXt1zsLuu5uzdBK9hcc9PjOkKEfNjC1lkWmQKyA5dXyxYYqIAIO0iLLjhG81XwQ-Y4-nACIaVwirg_NHO1Q4I2sNp_YKPpNxP6kdSU2g0qlqDk3dLs9SG-vqIFR7DA==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1082.01,
            "totalPrice": 822
          },
          {
            "allPassengerTotalPrice": 823,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUvF-nSN1B-d06DpMxtnwos8Ph72E0Alp9RgTE2VL3i2HXJirISZMghGlXx9tnU8Jg0MR2Ue3YFtq9DCUDe3BkiRy8_x7NC01QNxJ5ZbHNsRgdn2GgbCk-oyM1MyQphrmA6B_WHfh4ZhRP3p98PLywcTwN10Wtc0Tmal3C6h1_6dk-ULc3SqtVdmRgqRX-W-7-slT1IWhApxY9a_0c7CMiiX9m9Ph9MqtE7slbXkjUPhjKaoZupp0bKUfyOOkpcBVeay2sjNsdDR9nVvhI_1SEoxQ6xxsM3dZtyKhhVyamBeJNd7YHj6xbpBjO1RhLZaNHERu4GXfB6thWNDgvHN9c3Ffe_NBksI7hDI-ZsVsPDST1gQ8k_v-GyWl9qo9Hcia4k2I1IOnYePd5r18d4aeZ1NF8w0xR8RIAIiAzTe8DQrP3JBqAB7V0sHW7xYoZ-edDd_8IfIMB0hy7USt-qkxmdDh88wOsQRvavWo5xLNHvOwBaMoE1LF5u0pIVSV-nn8QR5k-G1n8WXxvCZg7klyuh3_sV-Uqvx0G3n6HScGHRxVen9LdPwjF5zeTcXCe83oobQbU0IhVpPpAZYw8qNONQF55HUEFdvVAXFzTuRYJiXKe4oulikx4ertR5lxLlUq0lZMnuRsGXzWrcLU9PADktkMUkKcynxlm8w9qW-s-ISLA5S5_qtBFOn98jQzxe6IWddCQa3wwZRV579Mb_7937m-BMP0yFgK22Zzpz9t9-zt5s1vaFDSd5IsP4cONewsomjkyRXvM1vbE2oMkaUGwD7r09VAGQWfEsppBnHlB5_cIFkMz3WPVAQWoohC6c8iucT6BUS5xNgsTJ1EmGCrGCWgb6INZS-a-1ORd-5RVZPFaWdBZjyHBJr_oYHnGsmO4ACUljktIwAG9sID1YZYGm70M25jEKWQyT4LNApUWhE8KRQTs4Ny6uopI5zDjNT73vyyQDA2C_z8QLqkOnKKn8QdixK5JWgjmfgoXmkvXMqF6qufXHck6IryNySj_4GOSH27S2VB846fYhodWB00WsqVLcCtEfFyXJ8a0oaQO6emSqJia5KKWHeXCzVly4mrIruEI-7cYZzqVJECDLa6sg==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUvF-nSN1B-d06DpMxtnwos8Ph72E0Alp9RgTE2VL3i2HXJirISZMghGlXx9tnU8Jg0MR2Ue3YFtq9DCUDe3BkiRy8_x7NC01QNxJ5ZbHNsRgdn2GgbCk-oyM1MyQphrmA6B_WHfh4ZhRP3p98PLywcTwN10Wtc0Tmal3C6h1_6dk-ULc3SqtVdmRgqRX-W-7-slT1IWhApxY9a_0c7CMiiX9m9Ph9MqtE7slbXkjUPhjKaoZupp0bKUfyOOkpcBVeay2sjNsdDR9nVvhI_1SEoxQ6xxsM3dZtyKhhVyamBeJNd7YHj6xbpBjO1RhLZaNHERu4GXfB6thWNDgvHN9c3Ffe_NBksI7hDI-ZsVsPDST1gQ8k_v-GyWl9qo9Hcia4k2I1IOnYePd5r18d4aeZ1NF8w0xR8RIAIiAzTe8DQrP3JBqAB7V0sHW7xYoZ-edDd_8IfIMB0hy7USt-qkxmdDh88wOsQRvavWo5xLNHvOwBaMoE1LF5u0pIVSV-nn8QR5k-G1n8WXxvCZg7klyuh3_sV-Uqvx0G3n6HScGHRxVen9LdPwjF5zeTcXCe83oobQbU0IhVpPpAZYw8qNONQF55HUEFdvVAXFzTuRYJiXKe4oulikx4ertR5lxLlUq0lZMnuRsGXzWrcLU9PADktkMUkKcynxlm8w9qW-s-ISLA5S5_qtBFOn98jQzxe6IWddCQa3wwZRV579Mb_7937m-BMP0yFgK22Zzpz9t9-zt5s1vaFDSd5IsP4cONewsomjkyRXvM1vbE2oMkaUGwD7r09VAGQWfEsppBnHlB5_cIFkMz3WPVAQWoohC6c8iucT6BUS5xNgsTJ1EmGCrGCWgb6INZS-a-1ORd-5RVZPFaWdBZjyHBJr_oYHnGsmO4ACUljktIwAG9sID1YZYGm70M25jEKWQyT4LNApUWhE8KRQTs4Ny6uopI5zDjNT73vyyQDA2C_z8QLqkOnKKn8QdixK5JWgjmfgoXmkvXMqF6qufXHck6IryNySj_4GOSH27S2VB846fYhodWB00WsqVLcCtEfFyXJ8a0oaQO6emSqJia5KKWHeXCzVly4mrIruEI-7cYZzqVJECDLa6sg==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUvF-nSN1B-d06DpMxtnwos8Ph72E0Alp9RgTE2VL3i2HXJirISZMghGlXx9tnU8Jg0MR2Ue3YFtq9DCUDe3BkiRy8_x7NC01QNxJ5ZbHNsRgdn2GgbCk-oyM1MyQphrmA6B_WHfh4ZhRP3p98PLywcTwN10Wtc0Tmal3C6h1_6dk-ULc3SqtVdmRgqRX-W-7-slT1IWhApxY9a_0c7CMiiX9m9Ph9MqtE7slbXkjUPhjKaoZupp0bKUfyOOkpcBVeay2sjNsdDR9nVvhI_1SEoxQ6xxsM3dZtyKhhVyamBeJNd7YHj6xbpBjO1RhLZaNHERu4GXfB6thWNDgvHN9c3Ffe_NBksI7hDI-ZsVsPDST1gQ8k_v-GyWl9qo9Hcia4k2I1IOnYePd5r18d4aeZ1NF8w0xR8RIAIiAzTe8DQrP3JBqAB7V0sHW7xYoZ-edDd_8IfIMB0hy7USt-qkxmdDh88wOsQRvavWo5xLNHvOwBaMoE1LF5u0pIVSV-nn8QR5k-G1n8WXxvCZg7klyuh3_sV-Uqvx0G3n6HScGHRxVen9LdPwjF5zeTcXCe83oobQbU0IhVpPpAZYw8qNONQF55HUEFdvVAXFzTuRYJiXKe4oulikx4ertR5lxLlUq0lZMnuRsGXzWrcLU9PADktkMUkKcynxlm8w9qW-s-ISLA5S5_qtBFOn98jQzxe6IWddCQa3wwZRV579Mb_7937m-BMP0yFgK22Zzpz9t9-zt5s1vaFDSd5IsP4cONewsomjkyRXvM1vbE2oMkaUGwD7r09VAGQWfEsppBnHlB5_cIFkMz3WPVAQWoohC6c8iucT6BUS5xNgsTJ1EmGCrGCWgb6INZS-a-1ORd-5RVZPFaWdBZjyHBJr_oYHnGsmO4ACUljktIwAG9sID1YZYGm70M25jEKWQyT4LNApUWhE8KRQTs4Ny6uopI5zDjNT73vyyQDA2C_z8QLqkOnKKn8QdixK5JWgjmfgoXmkvXMqF6qufXHck6IryNySj_4GOSH27S2VB846fYhodWB00WsqVLcCtEfFyXJ8a0oaQO6emSqJia5KKWHeXCzVly4mrIruEI-7cYZzqVJECDLa6sg==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1016.21,
            "totalPrice": 823
          },
          {
            "allPassengerTotalPrice": 823,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140bce4e1d00003a1ccbbb_0%7C0bce0a224e1d00009f407faf_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlKeISxUlKAynkR0c52xnXVT015Wi3LIDCC61PnLdgafiuklLPlJLILmFOjElVE0ueyxN0qIP3g7eGAWCPH97M9uhOyEdsgwOTCDP5ZMIviP6jyPaZQlVWSTpFc1xz_4XeC0waW0ZHErdR3K23F3wVyov4IY6GxxHhL63ho4FMTkuYR-MaCvehFyjtSXiaFEXaurAvjE5jl4iZzWhj28nhc_wBttdzJpldIX6w5xpNA60hdsRQUJP5MvquZmm8_WpFylvpXsfZvMQgvjN4B94_7IfJQY3IAtTHWERYrPe6NjiFSphsmfCbfAFPOAzx4zuBblOXP6f7VfPeIgbNbqUr6GA-pXzuCEQZdeR5GDarckjfPqCIyZ5aStF_ARyeGd31plYO0rkuuxMLH6VByiY4k9TtJ76fyqk1BLM81b1Bcy6LFiQbvebjFmliQzmQdjzvFV91_4hHuKif7su-Yu5bl5G3WA5nVkd_bWOk18ZGEpifkSlasR6EfdnRGp7YZZBM41YV6Y78CARZl98krv1xa0PNo99N3AjGM3bvmab4ZUerk12JI7QFegial2r_hmEPUIUCTk92hzVDWnAqj6XUolJToYsBLOdYD_TH8wqaC-PjNBiBvM6wNEpQjbPOvXjIxWN9NWvyqJiDaYIiLsP8hN6DQDCa7RstDxucR_0M42fscWARCj25zWcRi6lPDxP8vmCTLNrGFfW8oragIOtEr9Pkr3NuVafELZI61Z-fWBslWFFzGN1Z4I_Q2OgixlLOZRp48F87CfRi5BMbm84Nax5Bsk75ht1KGocA1LcqVhqhuenLDUUQAVlrlnt-JcSP3qnRWEN0q2HquOAjzmMOU8UQuGl9csLN5g8SCq89vHGw8Kl9fNzCQxTVf5uwhRTYhmACoDgLpj_PYE0HYhSjlPzxSR2ZzsONi9b1gt2V8Z7616twr7hLVlUyzPxKMYE2q2K2MzIVl3LxgzjZ_IPDf48d3CWJCFUPH9t-mxkqGueAGfUsIyl_HjZ4q92hxeaHhXaj06SG941tOdp82_tugAF-BIKbYgs4TIRaTm1_Y_p261dLEGS9hx492hzxbxTpZdLE4D13AM_fG6qknkt-LSWgYdf-fyP1yGg9QBzDS_QpCLigo0krNglJVOU1AFH2qsvH9btoIwdxbWfOTM01nRmyaNFZwtZouUIcg2EuXlRnhlWXfXGRXHfz1CBFMSI1ipW2vw2c-ZrEnFgSdAUNL-H_dzVQ7rvY2S931Uw-hkbcXAKHA3kWMX8CL3RQV3h6SrS6Gn9CN3pbqTb2jax27aI1M9MY1i2NHTBRJxT9lA=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140bce4e1d00003a1ccbbb_0%7C0bce0a224e1d00009f407faf_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlKeISxUlKAynkR0c52xnXVT015Wi3LIDCC61PnLdgafiuklLPlJLILmFOjElVE0ueyxN0qIP3g7eGAWCPH97M9uhOyEdsgwOTCDP5ZMIviP6jyPaZQlVWSTpFc1xz_4XeC0waW0ZHErdR3K23F3wVyov4IY6GxxHhL63ho4FMTkuYR-MaCvehFyjtSXiaFEXaurAvjE5jl4iZzWhj28nhc_wBttdzJpldIX6w5xpNA60hdsRQUJP5MvquZmm8_WpFylvpXsfZvMQgvjN4B94_7IfJQY3IAtTHWERYrPe6NjiFSphsmfCbfAFPOAzx4zuBblOXP6f7VfPeIgbNbqUr6GA-pXzuCEQZdeR5GDarckjfPqCIyZ5aStF_ARyeGd31plYO0rkuuxMLH6VByiY4k9TtJ76fyqk1BLM81b1Bcy6LFiQbvebjFmliQzmQdjzvFV91_4hHuKif7su-Yu5bl5G3WA5nVkd_bWOk18ZGEpifkSlasR6EfdnRGp7YZZBM41YV6Y78CARZl98krv1xa0PNo99N3AjGM3bvmab4ZUerk12JI7QFegial2r_hmEPUIUCTk92hzVDWnAqj6XUolJToYsBLOdYD_TH8wqaC-PjNBiBvM6wNEpQjbPOvXjIxWN9NWvyqJiDaYIiLsP8hN6DQDCa7RstDxucR_0M42fscWARCj25zWcRi6lPDxP8vmCTLNrGFfW8oragIOtEr9Pkr3NuVafELZI61Z-fWBslWFFzGN1Z4I_Q2OgixlLOZRp48F87CfRi5BMbm84Nax5Bsk75ht1KGocA1LcqVhqhuenLDUUQAVlrlnt-JcSP3qnRWEN0q2HquOAjzmMOU8UQuGl9csLN5g8SCq89vHGw8Kl9fNzCQxTVf5uwhRTYhmACoDgLpj_PYE0HYhSjlPzxSR2ZzsONi9b1gt2V8Z7616twr7hLVlUyzPxKMYE2q2K2MzIVl3LxgzjZ_IPDf48d3CWJCFUPH9t-mxkqGueAGfUsIyl_HjZ4q92hxeaHhXaj06SG941tOdp82_tugAF-BIKbYgs4TIRaTm1_Y_p261dLEGS9hx492hzxbxTpZdLE4D13AM_fG6qknkt-LSWgYdf-fyP1yGg9QBzDS_QpCLigo0krNglJVOU1AFH2qsvH9btoIwdxbWfOTM01nRmyaNFZwtZouUIcg2EuXlRnhlWXfXGRXHfz1CBFMSI1ipW2vw2c-ZrEnFgSdAUNL-H_dzVQ7rvY2S931Uw-hkbcXAKHA3kWMX8CL3RQV3h6SrS6Gn9CN3pbqTb2jax27aI1M9MY1i2NHTBRJxT9lA=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e1d00003a1ccbbb",
                    "equipment": null,
                    "fare_basis": "TOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e1d00003a1ccbbb_0",
                    "local_arrival": "2024-10-01T19:20:00.000Z",
                    "local_departure": "2024-10-01T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-01T17:20:00.000Z",
                    "utc_departure": "2024-10-01T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e1d00009f407faf",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e1d00009f407faf_0",
                    "local_arrival": "2024-10-01T22:10:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:10:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "I2",
                  "UX",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140bce4e1d00003a1ccbbb_0%7C0bce0a224e1d00009f407faf_0%7C04b80a224e230000bea4b402_0%7C0a2222f54e2400002461ba7d_0%7C22f524464e240000f104acfb_0%7C24460f9c4e240000451acdbe_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlKeISxUlKAynkR0c52xnXVT015Wi3LIDCC61PnLdgafiuklLPlJLILmFOjElVE0ueyxN0qIP3g7eGAWCPH97M9uhOyEdsgwOTCDP5ZMIviP6jyPaZQlVWSTpFc1xz_4XeC0waW0ZHErdR3K23F3wVyov4IY6GxxHhL63ho4FMTkuYR-MaCvehFyjtSXiaFEXaurAvjE5jl4iZzWhj28nhc_wBttdzJpldIX6w5xpNA60hdsRQUJP5MvquZmm8_WpFylvpXsfZvMQgvjN4B94_7IfJQY3IAtTHWERYrPe6NjiFSphsmfCbfAFPOAzx4zuBblOXP6f7VfPeIgbNbqUr6GA-pXzuCEQZdeR5GDarckjfPqCIyZ5aStF_ARyeGd31plYO0rkuuxMLH6VByiY4k9TtJ76fyqk1BLM81b1Bcy6LFiQbvebjFmliQzmQdjzvFV91_4hHuKif7su-Yu5bl5G3WA5nVkd_bWOk18ZGEpifkSlasR6EfdnRGp7YZZBM41YV6Y78CARZl98krv1xa0PNo99N3AjGM3bvmab4ZUerk12JI7QFegial2r_hmEPUIUCTk92hzVDWnAqj6XUolJToYsBLOdYD_TH8wqaC-PjNBiBvM6wNEpQjbPOvXjIxWN9NWvyqJiDaYIiLsP8hN6DQDCa7RstDxucR_0M42fscWARCj25zWcRi6lPDxP8vmCTLNrGFfW8oragIOtEr9Pkr3NuVafELZI61Z-fWBslWFFzGN1Z4I_Q2OgixlLOZRp48F87CfRi5BMbm84Nax5Bsk75ht1KGocA1LcqVhqhuenLDUUQAVlrlnt-JcSP3qnRWEN0q2HquOAjzmMOU8UQuGl9csLN5g8SCq89vHGw8Kl9fNzCQxTVf5uwhRTYhmACoDgLpj_PYE0HYhSjlPzxSR2ZzsONi9b1gt2V8Z7616twr7hLVlUyzPxKMYE2q2K2MzIVl3LxgzjZ_IPDf48d3CWJCFUPH9t-mxkqGueAGfUsIyl_HjZ4q92hxeaHhXaj06SG941tOdp82_tugAF-BIKbYgs4TIRaTm1_Y_p261dLEGS9hx492hzxbxTpZdLE4D13AM_fG6qknkt-LSWgYdf-fyP1yGg9QBzDS_QpCLigo0krNglJVOU1AFH2qsvH9btoIwdxbWfOTM01nRmyaNFZwtZouUIcg2EuXlRnhlWXfXGRXHfz1CBFMSI1ipW2vw2c-ZrEnFgSdAUNL-H_dzVQ7rvY2S931Uw-hkbcXAKHA3kWMX8CL3RQV3h6SrS6Gn9CN3pbqTb2jax27aI1M9MY1i2NHTBRJxT9lA=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "21:50",
                "destination": "SFO",
                "duration": "32h48m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Seville",
                    "cityTo": "Madrid",
                    "combination_id": "04b80a224e230000bea4b402",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3953,
                    "flyFrom": "SVQ",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "04b80a224e230000bea4b402_0",
                    "local_arrival": "2024-10-07T22:55:00.000Z",
                    "local_departure": "2024-10-07T21:50:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3953",
                    "return": 0,
                    "utc_arrival": "2024-10-07T20:55:00.000Z",
                    "utc_departure": "2024-10-07T19:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "LON",
                    "cityFrom": "Madrid",
                    "cityTo": "London",
                    "combination_id": "0a2222f54e2400002461ba7d",
                    "equipment": null,
                    "fare_basis": "ZY7O5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1013,
                    "flyFrom": "MAD",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0a2222f54e2400002461ba7d_0",
                    "local_arrival": "2024-10-08T09:00:00.000Z",
                    "local_departure": "2024-10-08T07:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "1013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:00:00.000Z",
                    "utc_departure": "2024-10-08T05:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e240000f104acfb",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e240000f104acfb_0",
                    "local_arrival": "2024-10-08T14:40:00.000Z",
                    "local_departure": "2024-10-08T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:40:00.000Z",
                    "utc_departure": "2024-10-08T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e240000451acdbe",
                    "equipment": null,
                    "fare_basis": "R21EXS2",
                    "fare_category": "M",
                    "fare_classes": "R",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e240000451acdbe_0",
                    "local_arrival": "2024-10-08T21:38:00.000Z",
                    "local_departure": "2024-10-08T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-09T04:38:00.000Z",
                    "utc_departure": "2024-10-09T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1077.84,
            "totalPrice": 823
          },
          {
            "allPassengerTotalPrice": 837,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTxIcaK_Avc_HxckTgJHUWdBPUlEz_xy-sPEzgAgnq_X3FaB5Hv3gWsJzUtPSozUrjBQhxLoNa18FDdq4Z9wA-JX4uBWmL-04JnJu-KtOKwYmP8dZEpKWWvW4av4HSiNCuaSSxnot1jZqLb-yYiEH9d48MhCHQ5rfRuSA9sWWf2W1AsgLjDhB-EljTaHywwEhn0i7NG4rUPENl7CaWU01y0XSFrZYB8uIvB6leaqrlMJRtNwpvYLRwXr-Pyh5oY9o1SFvv6iw-KD7WalPpDofTaJVN-zi_yjuGU_HrPR_w0upaUu717ZZ82CQOK7zpeV-OHD3b5gYcBgYOnnQhtQePGuGeUd9-WAtM8LhhRCt8hmUsr5twhpx-lHyhZFZMjun60fWDFW0sFFr9m9FXLeCmR550lSp_gh8ri9VHsHNHfDqud4Z6ucJHWtYLRvxYKXS5Adt23B60p0JD7vIUUrBBYdPW9UTtl9OoQ-3vWoe4Z_ZRxHGvPCrWleSGjoxqSKXo_JLi9CziMoiDmMpP_gPwC7Ku7S67ZPmIj8Dz_4Qr_hHSaEWImmqknSGmmtfUQ6lUKnokkt4z6LzAVQn3C1W7BqegoLpEQ0mEU3PhOI0h3BZpEox--gh2YsXIlf0Oy3TXGT_CpwM5G6u87zdZh671NOqHhyJAnRI3VI6dLngkCT5PKYhI9bI8WUOaWmoSxorOSNz56uE95qRQyRV9yucTFrH03PzYwtCCerEVov_h9D1UicCgBZhCQghEZlmTG4JbUqMw-JqN3B8dGCMYt1D61u_RpiE0AMkWDBroZSNLmfQI9hnbIcRtu0zaFpF_4mS6volCpZYd-qnO9SGLYLLMuyLW6GOFK2pK6TsHYVXCFDtWhxdBCN-54yOAnAEMTWPUYdjtkBiQ6hJRKROkRmxX33Aru44gPm9NImJNiQIrL46VPcMnfJIV3GISXzbX6WOKJhWl4NKpYWw-kQjGHwYCshoDn_LXp0JmrXTS6ojKagi0kYX0ZF6FgQOl8O4q2WXvJhnUFeHxCR0lCforXT4ea3jG-heAk4sXhDtaM_ZLzS-gDX5Ezm5AHaQ-6IgDKUvvQ_eZJj6417xsrcMAaUMYQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTxIcaK_Avc_HxckTgJHUWdBPUlEz_xy-sPEzgAgnq_X3FaB5Hv3gWsJzUtPSozUrjBQhxLoNa18FDdq4Z9wA-JX4uBWmL-04JnJu-KtOKwYmP8dZEpKWWvW4av4HSiNCuaSSxnot1jZqLb-yYiEH9d48MhCHQ5rfRuSA9sWWf2W1AsgLjDhB-EljTaHywwEhn0i7NG4rUPENl7CaWU01y0XSFrZYB8uIvB6leaqrlMJRtNwpvYLRwXr-Pyh5oY9o1SFvv6iw-KD7WalPpDofTaJVN-zi_yjuGU_HrPR_w0upaUu717ZZ82CQOK7zpeV-OHD3b5gYcBgYOnnQhtQePGuGeUd9-WAtM8LhhRCt8hmUsr5twhpx-lHyhZFZMjun60fWDFW0sFFr9m9FXLeCmR550lSp_gh8ri9VHsHNHfDqud4Z6ucJHWtYLRvxYKXS5Adt23B60p0JD7vIUUrBBYdPW9UTtl9OoQ-3vWoe4Z_ZRxHGvPCrWleSGjoxqSKXo_JLi9CziMoiDmMpP_gPwC7Ku7S67ZPmIj8Dz_4Qr_hHSaEWImmqknSGmmtfUQ6lUKnokkt4z6LzAVQn3C1W7BqegoLpEQ0mEU3PhOI0h3BZpEox--gh2YsXIlf0Oy3TXGT_CpwM5G6u87zdZh671NOqHhyJAnRI3VI6dLngkCT5PKYhI9bI8WUOaWmoSxorOSNz56uE95qRQyRV9yucTFrH03PzYwtCCerEVov_h9D1UicCgBZhCQghEZlmTG4JbUqMw-JqN3B8dGCMYt1D61u_RpiE0AMkWDBroZSNLmfQI9hnbIcRtu0zaFpF_4mS6volCpZYd-qnO9SGLYLLMuyLW6GOFK2pK6TsHYVXCFDtWhxdBCN-54yOAnAEMTWPUYdjtkBiQ6hJRKROkRmxX33Aru44gPm9NImJNiQIrL46VPcMnfJIV3GISXzbX6WOKJhWl4NKpYWw-kQjGHwYCshoDn_LXp0JmrXTS6ojKagi0kYX0ZF6FgQOl8O4q2WXvJhnUFeHxCR0lCforXT4ea3jG-heAk4sXhDtaM_ZLzS-gDX5Ezm5AHaQ-6IgDKUvvQ_eZJj6417xsrcMAaUMYQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTxIcaK_Avc_HxckTgJHUWdBPUlEz_xy-sPEzgAgnq_X3FaB5Hv3gWsJzUtPSozUrjBQhxLoNa18FDdq4Z9wA-JX4uBWmL-04JnJu-KtOKwYmP8dZEpKWWvW4av4HSiNCuaSSxnot1jZqLb-yYiEH9d48MhCHQ5rfRuSA9sWWf2W1AsgLjDhB-EljTaHywwEhn0i7NG4rUPENl7CaWU01y0XSFrZYB8uIvB6leaqrlMJRtNwpvYLRwXr-Pyh5oY9o1SFvv6iw-KD7WalPpDofTaJVN-zi_yjuGU_HrPR_w0upaUu717ZZ82CQOK7zpeV-OHD3b5gYcBgYOnnQhtQePGuGeUd9-WAtM8LhhRCt8hmUsr5twhpx-lHyhZFZMjun60fWDFW0sFFr9m9FXLeCmR550lSp_gh8ri9VHsHNHfDqud4Z6ucJHWtYLRvxYKXS5Adt23B60p0JD7vIUUrBBYdPW9UTtl9OoQ-3vWoe4Z_ZRxHGvPCrWleSGjoxqSKXo_JLi9CziMoiDmMpP_gPwC7Ku7S67ZPmIj8Dz_4Qr_hHSaEWImmqknSGmmtfUQ6lUKnokkt4z6LzAVQn3C1W7BqegoLpEQ0mEU3PhOI0h3BZpEox--gh2YsXIlf0Oy3TXGT_CpwM5G6u87zdZh671NOqHhyJAnRI3VI6dLngkCT5PKYhI9bI8WUOaWmoSxorOSNz56uE95qRQyRV9yucTFrH03PzYwtCCerEVov_h9D1UicCgBZhCQghEZlmTG4JbUqMw-JqN3B8dGCMYt1D61u_RpiE0AMkWDBroZSNLmfQI9hnbIcRtu0zaFpF_4mS6volCpZYd-qnO9SGLYLLMuyLW6GOFK2pK6TsHYVXCFDtWhxdBCN-54yOAnAEMTWPUYdjtkBiQ6hJRKROkRmxX33Aru44gPm9NImJNiQIrL46VPcMnfJIV3GISXzbX6WOKJhWl4NKpYWw-kQjGHwYCshoDn_LXp0JmrXTS6ojKagi0kYX0ZF6FgQOl8O4q2WXvJhnUFeHxCR0lCforXT4ea3jG-heAk4sXhDtaM_ZLzS-gDX5Ezm5AHaQ-6IgDKUvvQ_eZJj6417xsrcMAaUMYQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2400007559f353",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2400007559f353_0",
                    "local_arrival": "2024-10-08T18:10:00.000Z",
                    "local_departure": "2024-10-08T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-09T01:10:00.000Z",
                    "utc_departure": "2024-10-08T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1027,
            "totalPrice": 837
          },
          {
            "allPassengerTotalPrice": 953,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYSuy7xE4dQvhoWFe84VC-DUGPB5OjfzoL6gA7knMXhouMgvAXJapYX-yapPHptPn3NYO7JPGOR9cYQqWjEY7FRFDFhMb8zwysdg9JbO8BTIvUUPwCCW90VSWXxbpG8A9LW1Wme4AF84kB7ngbW7pKobBUfTJ-Y6z16dpLB-oq5agA151cSlbEMf2wLKPhmNgAzRySfGzKXQDk1P0HOLGhnesJ2Z6p3qVbLLeThHeDQ31sDTrvI72_H3SgDRLdFFmVGiROT2ADx3kBYGOotU-LfinGDtrh4Bw8FmDLU1ybH-nYHiowkxmqVjdk5gcr2Tt50fjq4QerNjZoZTJLXnAOG7FCFYhEE_7r-6FFFC4Z93n53OXpcjyxezCl7UxNQJVbGXlDGtfDtlhGbQSQsg7Q78eUDreEn71O4skpBegdpwL9gHj9qR_rMkY-1iOO64E8cC373DlCRdm9sgMM_tEV_YfVrkyDEyzoSDDI6Y1cg-luPq-Hu3VStQGOb6yKCaAnM95SVICyUsS22L_CRWRS63C4tUwhDIQx0fjGE-UCDtnei8LrHM-uVWi6f43pq7FnrEizg38wSOmHfGNKHI960SorEl002xc16gl5fjCHzLPFJlmtcYzG-dzzQFivzeYWLo6Di3_-hJteVEwpHwT6-mzNHn5axHSwezUcE9Dzg4wiZkiAX2it8DVxytpVOsScWQyEOVddBmcojIN4wDcWSM6GlMmBSJiUuO5ti2DoH4roFp6TbCiyQrqsVVfrs8IACffOxYNEY_0bNPd2GAjpiGZoJMLTcWbGOxpXYPFue_3BStkJzOSY8OU_m3Ol0wVL-cBI3_oVLbaJijbgkmnAGILg4yowNay0fA_gGYxXMmnR3jgHoa9dLLtamzMmXDyFJliQDgDdE8UD3MX1wG70W1XCcjlStb7SuEpL4Hrg5nzLbvHqRFQ4s5BG7x8Y_JW656Xb0ns32wfKdl3lOTlL5OwcALknRnE31cc_GqlZcCoNi6_QluK8I2YodETAF05QndyBezt7ybVpZLLjOco8jKgyj3kTJSX8os_7D4d2ml6Zz24DO_KsB9Y0WUEv4SbREF-CwA-O29Ka01lXN7e2lKZRM6BOiqsgLyBs7HjvppN9mUI1rzIh95_ooOAV-EHXt9pEp3M2mtlrss4YmpwAQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYSuy7xE4dQvhoWFe84VC-DUGPB5OjfzoL6gA7knMXhouMgvAXJapYX-yapPHptPn3NYO7JPGOR9cYQqWjEY7FRFDFhMb8zwysdg9JbO8BTIvUUPwCCW90VSWXxbpG8A9LW1Wme4AF84kB7ngbW7pKobBUfTJ-Y6z16dpLB-oq5agA151cSlbEMf2wLKPhmNgAzRySfGzKXQDk1P0HOLGhnesJ2Z6p3qVbLLeThHeDQ31sDTrvI72_H3SgDRLdFFmVGiROT2ADx3kBYGOotU-LfinGDtrh4Bw8FmDLU1ybH-nYHiowkxmqVjdk5gcr2Tt50fjq4QerNjZoZTJLXnAOG7FCFYhEE_7r-6FFFC4Z93n53OXpcjyxezCl7UxNQJVbGXlDGtfDtlhGbQSQsg7Q78eUDreEn71O4skpBegdpwL9gHj9qR_rMkY-1iOO64E8cC373DlCRdm9sgMM_tEV_YfVrkyDEyzoSDDI6Y1cg-luPq-Hu3VStQGOb6yKCaAnM95SVICyUsS22L_CRWRS63C4tUwhDIQx0fjGE-UCDtnei8LrHM-uVWi6f43pq7FnrEizg38wSOmHfGNKHI960SorEl002xc16gl5fjCHzLPFJlmtcYzG-dzzQFivzeYWLo6Di3_-hJteVEwpHwT6-mzNHn5axHSwezUcE9Dzg4wiZkiAX2it8DVxytpVOsScWQyEOVddBmcojIN4wDcWSM6GlMmBSJiUuO5ti2DoH4roFp6TbCiyQrqsVVfrs8IACffOxYNEY_0bNPd2GAjpiGZoJMLTcWbGOxpXYPFue_3BStkJzOSY8OU_m3Ol0wVL-cBI3_oVLbaJijbgkmnAGILg4yowNay0fA_gGYxXMmnR3jgHoa9dLLtamzMmXDyFJliQDgDdE8UD3MX1wG70W1XCcjlStb7SuEpL4Hrg5nzLbvHqRFQ4s5BG7x8Y_JW656Xb0ns32wfKdl3lOTlL5OwcALknRnE31cc_GqlZcCoNi6_QluK8I2YodETAF05QndyBezt7ybVpZLLjOco8jKgyj3kTJSX8os_7D4d2ml6Zz24DO_KsB9Y0WUEv4SbREF-CwA-O29Ka01lXN7e2lKZRM6BOiqsgLyBs7HjvppN9mUI1rzIh95_ooOAV-EHXt9pEp3M2mtlrss4YmpwAQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYSuy7xE4dQvhoWFe84VC-DUGPB5OjfzoL6gA7knMXhouMgvAXJapYX-yapPHptPn3NYO7JPGOR9cYQqWjEY7FRFDFhMb8zwysdg9JbO8BTIvUUPwCCW90VSWXxbpG8A9LW1Wme4AF84kB7ngbW7pKobBUfTJ-Y6z16dpLB-oq5agA151cSlbEMf2wLKPhmNgAzRySfGzKXQDk1P0HOLGhnesJ2Z6p3qVbLLeThHeDQ31sDTrvI72_H3SgDRLdFFmVGiROT2ADx3kBYGOotU-LfinGDtrh4Bw8FmDLU1ybH-nYHiowkxmqVjdk5gcr2Tt50fjq4QerNjZoZTJLXnAOG7FCFYhEE_7r-6FFFC4Z93n53OXpcjyxezCl7UxNQJVbGXlDGtfDtlhGbQSQsg7Q78eUDreEn71O4skpBegdpwL9gHj9qR_rMkY-1iOO64E8cC373DlCRdm9sgMM_tEV_YfVrkyDEyzoSDDI6Y1cg-luPq-Hu3VStQGOb6yKCaAnM95SVICyUsS22L_CRWRS63C4tUwhDIQx0fjGE-UCDtnei8LrHM-uVWi6f43pq7FnrEizg38wSOmHfGNKHI960SorEl002xc16gl5fjCHzLPFJlmtcYzG-dzzQFivzeYWLo6Di3_-hJteVEwpHwT6-mzNHn5axHSwezUcE9Dzg4wiZkiAX2it8DVxytpVOsScWQyEOVddBmcojIN4wDcWSM6GlMmBSJiUuO5ti2DoH4roFp6TbCiyQrqsVVfrs8IACffOxYNEY_0bNPd2GAjpiGZoJMLTcWbGOxpXYPFue_3BStkJzOSY8OU_m3Ol0wVL-cBI3_oVLbaJijbgkmnAGILg4yowNay0fA_gGYxXMmnR3jgHoa9dLLtamzMmXDyFJliQDgDdE8UD3MX1wG70W1XCcjlStb7SuEpL4Hrg5nzLbvHqRFQ4s5BG7x8Y_JW656Xb0ns32wfKdl3lOTlL5OwcALknRnE31cc_GqlZcCoNi6_QluK8I2YodETAF05QndyBezt7ybVpZLLjOco8jKgyj3kTJSX8os_7D4d2ml6Zz24DO_KsB9Y0WUEv4SbREF-CwA-O29Ka01lXN7e2lKZRM6BOiqsgLyBs7HjvppN9mUI1rzIh95_ooOAV-EHXt9pEp3M2mtlrss4YmpwAQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1105.09,
            "totalPrice": 953
          },
          {
            "allPassengerTotalPrice": 953,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Giyfr22zillTdEFIbpvFp6W1AOVJ-jPVs3rUkFMMC-trKVsF7cngkrfGcoZqNAaKemt4nwh9-v0G6E8r-7v9RzdiZ9ILNjFVQDGqXTbJKUcUrDN2Mk7Ab6OzqMSs5LM16GdbEy7bjWUIxWyQ3kK-H_nMbKM1c8pf_VF_ettzLNU5oa0kWqQAYR9IVkXl2RbzckFMr3EWIlHmsZpKOMnp8LFHHAF5yqESgTw8JCWXwXHe8S5rCIaLY3Fjs3H7730bgmvCkJOxvsHx8NAoEa-Zm0mfD9_F6AUmRRoOlTUSeOGRnOZqzVE3qLL5s77Au9n3_RUg8ICppeShleNpQnTPiuPEK5U8ktdxkDUbR7mLUcCaO0B2G8a2lqebf3NwFhLI7ZOh-kSL7Df8Z1vEia5ZdeX43CoJOdLU3YWp1pGUpwuqWpYuavcIvayzxwl_6ygZIkvx2dIf0O9t7Uat040tyXYspd56mCOXSj_iOmKVynj_t8k8Dh3iE8-_R2HSAZ6MO4Gpt2AC03gJmgb3pVKLHP2OKFIS7PaBBeTnwtIzhBClHzzX8N6WRT03x3SYqxcTZ3gK_C8NYK88DWyKYZw_oO-7Lb9vdDEVVGowuQUXXng2oie1UzSh3PlDaNiCgaBUinGQizWQqGuilWpte7aT_9fDZp2SeusZhXphirr-6aEdQeKcmWQwv5CF5kfzGVABrMdKd7LZ97cpeQKRYwWKF20RlsZo_QFlNxDWxZ-xgYDjtxPsliUMgN1p1AG-6GV6QObm5303BR97Vxk2zUHHgVJDVHlcdTRlDQM5-MI9A4lEU5A_legjglJHX07PXO-hBrT2kypksvpMqNtxhk2DsiIQf5aa1fujAIMCFuvJZypFH0CYYEZCdksj_z0Xt6ByRwhwfOOYd0K-68qeXgmuLw1rUzQp3jMRFJNcuwcJ3HK8dlBMtrhIhMefIrrLbHskpTUFFOkl0haAvxlx4qOr3CumRZaFsNGIMIRyPTUeBOrN42ObeZoG-wuXAoPgdrfXOr1-Lx5tR1a47_yxl6RSSE9hrGw5DdQhqV2oyvL0rAwZ1OkYXWjAxlUUnBUYktSjzeMpY3ddJyzvvtMVtFbV4F5pv4YsThrPONfzs9UsVSCHEe_2HYjz9nbtgBYWRqt02IlpLh3eQgvHrY-E6IWrnKQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Giyfr22zillTdEFIbpvFp6W1AOVJ-jPVs3rUkFMMC-trKVsF7cngkrfGcoZqNAaKemt4nwh9-v0G6E8r-7v9RzdiZ9ILNjFVQDGqXTbJKUcUrDN2Mk7Ab6OzqMSs5LM16GdbEy7bjWUIxWyQ3kK-H_nMbKM1c8pf_VF_ettzLNU5oa0kWqQAYR9IVkXl2RbzckFMr3EWIlHmsZpKOMnp8LFHHAF5yqESgTw8JCWXwXHe8S5rCIaLY3Fjs3H7730bgmvCkJOxvsHx8NAoEa-Zm0mfD9_F6AUmRRoOlTUSeOGRnOZqzVE3qLL5s77Au9n3_RUg8ICppeShleNpQnTPiuPEK5U8ktdxkDUbR7mLUcCaO0B2G8a2lqebf3NwFhLI7ZOh-kSL7Df8Z1vEia5ZdeX43CoJOdLU3YWp1pGUpwuqWpYuavcIvayzxwl_6ygZIkvx2dIf0O9t7Uat040tyXYspd56mCOXSj_iOmKVynj_t8k8Dh3iE8-_R2HSAZ6MO4Gpt2AC03gJmgb3pVKLHP2OKFIS7PaBBeTnwtIzhBClHzzX8N6WRT03x3SYqxcTZ3gK_C8NYK88DWyKYZw_oO-7Lb9vdDEVVGowuQUXXng2oie1UzSh3PlDaNiCgaBUinGQizWQqGuilWpte7aT_9fDZp2SeusZhXphirr-6aEdQeKcmWQwv5CF5kfzGVABrMdKd7LZ97cpeQKRYwWKF20RlsZo_QFlNxDWxZ-xgYDjtxPsliUMgN1p1AG-6GV6QObm5303BR97Vxk2zUHHgVJDVHlcdTRlDQM5-MI9A4lEU5A_legjglJHX07PXO-hBrT2kypksvpMqNtxhk2DsiIQf5aa1fujAIMCFuvJZypFH0CYYEZCdksj_z0Xt6ByRwhwfOOYd0K-68qeXgmuLw1rUzQp3jMRFJNcuwcJ3HK8dlBMtrhIhMefIrrLbHskpTUFFOkl0haAvxlx4qOr3CumRZaFsNGIMIRyPTUeBOrN42ObeZoG-wuXAoPgdrfXOr1-Lx5tR1a47_yxl6RSSE9hrGw5DdQhqV2oyvL0rAwZ1OkYXWjAxlUUnBUYktSjzeMpY3ddJyzvvtMVtFbV4F5pv4YsThrPONfzs9UsVSCHEe_2HYjz9nbtgBYWRqt02IlpLh3eQgvHrY-E6IWrnKQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Giyfr22zillTdEFIbpvFp6W1AOVJ-jPVs3rUkFMMC-trKVsF7cngkrfGcoZqNAaKemt4nwh9-v0G6E8r-7v9RzdiZ9ILNjFVQDGqXTbJKUcUrDN2Mk7Ab6OzqMSs5LM16GdbEy7bjWUIxWyQ3kK-H_nMbKM1c8pf_VF_ettzLNU5oa0kWqQAYR9IVkXl2RbzckFMr3EWIlHmsZpKOMnp8LFHHAF5yqESgTw8JCWXwXHe8S5rCIaLY3Fjs3H7730bgmvCkJOxvsHx8NAoEa-Zm0mfD9_F6AUmRRoOlTUSeOGRnOZqzVE3qLL5s77Au9n3_RUg8ICppeShleNpQnTPiuPEK5U8ktdxkDUbR7mLUcCaO0B2G8a2lqebf3NwFhLI7ZOh-kSL7Df8Z1vEia5ZdeX43CoJOdLU3YWp1pGUpwuqWpYuavcIvayzxwl_6ygZIkvx2dIf0O9t7Uat040tyXYspd56mCOXSj_iOmKVynj_t8k8Dh3iE8-_R2HSAZ6MO4Gpt2AC03gJmgb3pVKLHP2OKFIS7PaBBeTnwtIzhBClHzzX8N6WRT03x3SYqxcTZ3gK_C8NYK88DWyKYZw_oO-7Lb9vdDEVVGowuQUXXng2oie1UzSh3PlDaNiCgaBUinGQizWQqGuilWpte7aT_9fDZp2SeusZhXphirr-6aEdQeKcmWQwv5CF5kfzGVABrMdKd7LZ97cpeQKRYwWKF20RlsZo_QFlNxDWxZ-xgYDjtxPsliUMgN1p1AG-6GV6QObm5303BR97Vxk2zUHHgVJDVHlcdTRlDQM5-MI9A4lEU5A_legjglJHX07PXO-hBrT2kypksvpMqNtxhk2DsiIQf5aa1fujAIMCFuvJZypFH0CYYEZCdksj_z0Xt6ByRwhwfOOYd0K-68qeXgmuLw1rUzQp3jMRFJNcuwcJ3HK8dlBMtrhIhMefIrrLbHskpTUFFOkl0haAvxlx4qOr3CumRZaFsNGIMIRyPTUeBOrN42ObeZoG-wuXAoPgdrfXOr1-Lx5tR1a47_yxl6RSSE9hrGw5DdQhqV2oyvL0rAwZ1OkYXWjAxlUUnBUYktSjzeMpY3ddJyzvvtMVtFbV4F5pv4YsThrPONfzs9UsVSCHEe_2HYjz9nbtgBYWRqt02IlpLh3eQgvHrY-E6IWrnKQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1105.09,
            "totalPrice": 953
          },
          {
            "allPassengerTotalPrice": 953,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYpa2FBlFYryRPQjHy4YDcFQDawfn47ExAQmT0RUSUGzTopL4SbBHN0ZQR9u0QI6yb8c_-5pdSeC-MwgxFIS1ajQcNP8fYlPzJPZ2nDGTaYZA7NMge1Ec5SVMPLL4x7_hSixYMGf7H4vJ3NpeTJJt24BiPg6GFifGtIIDcJhhypF5b4G84hH8hYz75qb0wWQOHXcL4erblLtn8nPy2j4FUuiAfA8PWT6lzhgK9Ly9onqtbl1KsOpriRCO0T1tL1GobgkDg08siYfMaawTxIvYq2JHN8aHE-eSNq_51ueM4psiJzeOFnZTVNa6gSzafXhP6NNbHFzECa-zoxqMIpkFTXD3X8DO1MVupwWNrrJUIHHvZhJ_AcVMj7KxWL4wYJaO-R_TiE6755ZQVgXC2YCs21_spmcFvufBorzhCQjIR1iHf1GWdFuLPw2-QeACH2-wWBq3y2xyfBjl6bg0sjdNNfZwTx_93Y-o5zjkExrqQCm9XTiFGAo9O9u3wjBMKJhT8o-P1Mii4zKpj-EwIStqP00TH7S9cz8HQkFLrp1hGdXu0fWEm8mTd3nHb1ca82jOw0h7Tldul-Pzwk7K5yolmems_UdoVvL6keBRARufhrk-cekMLp87Pk1j1qWdR2zMoo05_FMeJi8Vc8M4VjKadpkv3fb5WuDpOGRYAajGMheJKW9X4clfJtyIGwwGDgpZ5jD63M4mnvT5aXhPOxBNDlbSpJztKmX3fiLsRG4bQyGP7b5FiU57Oj45dOh9e-5D1E6bDIvaOdJLs57orNReiQ5zlblBdAMo4mStA-06WgfKZk8Jbvf6Dz2kz6UDn7f17DyoAJ-y5_XwFJn42UPLi8Zsxn4HjJ32qJ7p3G2S7f-sjmHW0q63Xv4L9Kej_W2BTj7U-c7UWqI5LWo7g-6ypSlrO3D6qYSIv6Br-HoX5TZQB7JRx0oRgqQinsDPnxKWkj8wBohpRU-x5EuGEjhJG5xvkXKgQ2O9xu-JoOAcyyJZe_yNt-xvd7ybsm60B2lfn4kug7pe-VbXj0K-IL0LRsXmQQvniHVbpqpLEzn_Td6IIzFJHJI7bsgly5xb7dxaJIBevef6OG4MlMroiN6ImzZ5u3a_fu_w4xtr3YBOcMbtZDajch9qP7HlMmNZHCHZ4az5tuZ4p8U1yTwen7_gTAOi7aHhvbdHriSmQRqvd24=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYpa2FBlFYryRPQjHy4YDcFQDawfn47ExAQmT0RUSUGzTopL4SbBHN0ZQR9u0QI6yb8c_-5pdSeC-MwgxFIS1ajQcNP8fYlPzJPZ2nDGTaYZA7NMge1Ec5SVMPLL4x7_hSixYMGf7H4vJ3NpeTJJt24BiPg6GFifGtIIDcJhhypF5b4G84hH8hYz75qb0wWQOHXcL4erblLtn8nPy2j4FUuiAfA8PWT6lzhgK9Ly9onqtbl1KsOpriRCO0T1tL1GobgkDg08siYfMaawTxIvYq2JHN8aHE-eSNq_51ueM4psiJzeOFnZTVNa6gSzafXhP6NNbHFzECa-zoxqMIpkFTXD3X8DO1MVupwWNrrJUIHHvZhJ_AcVMj7KxWL4wYJaO-R_TiE6755ZQVgXC2YCs21_spmcFvufBorzhCQjIR1iHf1GWdFuLPw2-QeACH2-wWBq3y2xyfBjl6bg0sjdNNfZwTx_93Y-o5zjkExrqQCm9XTiFGAo9O9u3wjBMKJhT8o-P1Mii4zKpj-EwIStqP00TH7S9cz8HQkFLrp1hGdXu0fWEm8mTd3nHb1ca82jOw0h7Tldul-Pzwk7K5yolmems_UdoVvL6keBRARufhrk-cekMLp87Pk1j1qWdR2zMoo05_FMeJi8Vc8M4VjKadpkv3fb5WuDpOGRYAajGMheJKW9X4clfJtyIGwwGDgpZ5jD63M4mnvT5aXhPOxBNDlbSpJztKmX3fiLsRG4bQyGP7b5FiU57Oj45dOh9e-5D1E6bDIvaOdJLs57orNReiQ5zlblBdAMo4mStA-06WgfKZk8Jbvf6Dz2kz6UDn7f17DyoAJ-y5_XwFJn42UPLi8Zsxn4HjJ32qJ7p3G2S7f-sjmHW0q63Xv4L9Kej_W2BTj7U-c7UWqI5LWo7g-6ypSlrO3D6qYSIv6Br-HoX5TZQB7JRx0oRgqQinsDPnxKWkj8wBohpRU-x5EuGEjhJG5xvkXKgQ2O9xu-JoOAcyyJZe_yNt-xvd7ybsm60B2lfn4kug7pe-VbXj0K-IL0LRsXmQQvniHVbpqpLEzn_Td6IIzFJHJI7bsgly5xb7dxaJIBevef6OG4MlMroiN6ImzZ5u3a_fu_w4xtr3YBOcMbtZDajch9qP7HlMmNZHCHZ4az5tuZ4p8U1yTwen7_gTAOi7aHhvbdHriSmQRqvd24=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYpa2FBlFYryRPQjHy4YDcFQDawfn47ExAQmT0RUSUGzTopL4SbBHN0ZQR9u0QI6yb8c_-5pdSeC-MwgxFIS1ajQcNP8fYlPzJPZ2nDGTaYZA7NMge1Ec5SVMPLL4x7_hSixYMGf7H4vJ3NpeTJJt24BiPg6GFifGtIIDcJhhypF5b4G84hH8hYz75qb0wWQOHXcL4erblLtn8nPy2j4FUuiAfA8PWT6lzhgK9Ly9onqtbl1KsOpriRCO0T1tL1GobgkDg08siYfMaawTxIvYq2JHN8aHE-eSNq_51ueM4psiJzeOFnZTVNa6gSzafXhP6NNbHFzECa-zoxqMIpkFTXD3X8DO1MVupwWNrrJUIHHvZhJ_AcVMj7KxWL4wYJaO-R_TiE6755ZQVgXC2YCs21_spmcFvufBorzhCQjIR1iHf1GWdFuLPw2-QeACH2-wWBq3y2xyfBjl6bg0sjdNNfZwTx_93Y-o5zjkExrqQCm9XTiFGAo9O9u3wjBMKJhT8o-P1Mii4zKpj-EwIStqP00TH7S9cz8HQkFLrp1hGdXu0fWEm8mTd3nHb1ca82jOw0h7Tldul-Pzwk7K5yolmems_UdoVvL6keBRARufhrk-cekMLp87Pk1j1qWdR2zMoo05_FMeJi8Vc8M4VjKadpkv3fb5WuDpOGRYAajGMheJKW9X4clfJtyIGwwGDgpZ5jD63M4mnvT5aXhPOxBNDlbSpJztKmX3fiLsRG4bQyGP7b5FiU57Oj45dOh9e-5D1E6bDIvaOdJLs57orNReiQ5zlblBdAMo4mStA-06WgfKZk8Jbvf6Dz2kz6UDn7f17DyoAJ-y5_XwFJn42UPLi8Zsxn4HjJ32qJ7p3G2S7f-sjmHW0q63Xv4L9Kej_W2BTj7U-c7UWqI5LWo7g-6ypSlrO3D6qYSIv6Br-HoX5TZQB7JRx0oRgqQinsDPnxKWkj8wBohpRU-x5EuGEjhJG5xvkXKgQ2O9xu-JoOAcyyJZe_yNt-xvd7ybsm60B2lfn4kug7pe-VbXj0K-IL0LRsXmQQvniHVbpqpLEzn_Td6IIzFJHJI7bsgly5xb7dxaJIBevef6OG4MlMroiN6ImzZ5u3a_fu_w4xtr3YBOcMbtZDajch9qP7HlMmNZHCHZ4az5tuZ4p8U1yTwen7_gTAOi7aHhvbdHriSmQRqvd24=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1105.75,
            "totalPrice": 953
          },
          {
            "allPassengerTotalPrice": 954,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_70Jg5IKCUfwy3NUOvp4ox191RFgtqkxm5vsaptnHwNgsDoG9CALUExiY1V3z4rHi2rXh22zGw9TeyXxNUTrwqxyjqYC7adYJ8c0cSOFVFsaf-TKrNrD_oDgUfxM5DWh14t9BwkpfsPa3nq4N-MC_fjzPThEb2gFwDeUY_Fx31kTpAFBBbTC4awxVVE5FzZdHtZDWfVc3rkyXbKI6RFFds8zV1qgWsScPpeRWimo0i4D-TwlP65bgcY-vX9hwNpVZImHs-AtQwBkfoSgfKnOXIZ2G6Vp2RK3xQIQOFpDDmoMGTOjbngiAtc1icRGpx6s_9REB_wXOF0mD55k0igdSNA8ZcS-paOaA0Z-Bh1y3pIJnp5vvUPL87yF8dX2lWVstlXlFMcHMh_ucj__06Q4UtHt1bxYhEpAAQAwBxI2RG4BVfTpsgXaMAOha463jAhIF_8ak9nlxMc1zzEQ5NlBLKWWNQwptchQFk4Y1Y1pTukbGD-m2o_UwfAyFjVhFC3OCTKqWjHQ421RJj8IHGWhoiVUG7KO_YcgZwm6aOLYXPWJ3Vjgm4we2NoQqAQVqgjywYqxPpgImzdc3Y4UQ5at3aHobeUllxbc4FJg2DVI7E7DPBEPzxvfnVzJucXpKSw7YFjTMnPs4XLso4v0hcF-ZhhEYC_dyAsKjHIUNLsH_cvFCjz0QLAeg0KaRpUvgwh0vk4_FNvidQvD6x1dZB7swHK7QVS_B87tN1jpXyw-kKgMKNJq0ODvrGZabec_hGSLkhzX-DEubjY9x8DIIXm5faUVnIvF7ngf20AUGZv8F3l_MjalgBHaGazqBrxo8h7mL0bej35tR7t0DEqMbTA-80DTeBvaHhAUOIVY0wTTZUtV6aRhFxK0a2a8WTbDIOFNU6KlG1oBvfnol2OTj8flYiBYy12smevvCEa-E9sGo4vqYZCofpDI8wDtAkUN7HqfXQRWClo1T8E3uwNnZa74e0UVQ-4LG2bR_jGzoLcRIhDXn3LCVw0QmPyBPbzrtaRR6BN9YMyVz03yEwxCkH2HCy46yTdpqpgzdkNigQl-jkLYn7C9P_e0x2bjgvXjhiFtyLaIYLcu52hh9Omu0dsK7bGnK5T01rC1jezFNFxzZ3apa63ewHE_avXDhOJA6zdE",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_70Jg5IKCUfwy3NUOvp4ox191RFgtqkxm5vsaptnHwNgsDoG9CALUExiY1V3z4rHi2rXh22zGw9TeyXxNUTrwqxyjqYC7adYJ8c0cSOFVFsaf-TKrNrD_oDgUfxM5DWh14t9BwkpfsPa3nq4N-MC_fjzPThEb2gFwDeUY_Fx31kTpAFBBbTC4awxVVE5FzZdHtZDWfVc3rkyXbKI6RFFds8zV1qgWsScPpeRWimo0i4D-TwlP65bgcY-vX9hwNpVZImHs-AtQwBkfoSgfKnOXIZ2G6Vp2RK3xQIQOFpDDmoMGTOjbngiAtc1icRGpx6s_9REB_wXOF0mD55k0igdSNA8ZcS-paOaA0Z-Bh1y3pIJnp5vvUPL87yF8dX2lWVstlXlFMcHMh_ucj__06Q4UtHt1bxYhEpAAQAwBxI2RG4BVfTpsgXaMAOha463jAhIF_8ak9nlxMc1zzEQ5NlBLKWWNQwptchQFk4Y1Y1pTukbGD-m2o_UwfAyFjVhFC3OCTKqWjHQ421RJj8IHGWhoiVUG7KO_YcgZwm6aOLYXPWJ3Vjgm4we2NoQqAQVqgjywYqxPpgImzdc3Y4UQ5at3aHobeUllxbc4FJg2DVI7E7DPBEPzxvfnVzJucXpKSw7YFjTMnPs4XLso4v0hcF-ZhhEYC_dyAsKjHIUNLsH_cvFCjz0QLAeg0KaRpUvgwh0vk4_FNvidQvD6x1dZB7swHK7QVS_B87tN1jpXyw-kKgMKNJq0ODvrGZabec_hGSLkhzX-DEubjY9x8DIIXm5faUVnIvF7ngf20AUGZv8F3l_MjalgBHaGazqBrxo8h7mL0bej35tR7t0DEqMbTA-80DTeBvaHhAUOIVY0wTTZUtV6aRhFxK0a2a8WTbDIOFNU6KlG1oBvfnol2OTj8flYiBYy12smevvCEa-E9sGo4vqYZCofpDI8wDtAkUN7HqfXQRWClo1T8E3uwNnZa74e0UVQ-4LG2bR_jGzoLcRIhDXn3LCVw0QmPyBPbzrtaRR6BN9YMyVz03yEwxCkH2HCy46yTdpqpgzdkNigQl-jkLYn7C9P_e0x2bjgvXjhiFtyLaIYLcu52hh9Omu0dsK7bGnK5T01rC1jezFNFxzZ3apa63ewHE_avXDhOJA6zdE",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_70Jg5IKCUfwy3NUOvp4ox191RFgtqkxm5vsaptnHwNgsDoG9CALUExiY1V3z4rHi2rXh22zGw9TeyXxNUTrwqxyjqYC7adYJ8c0cSOFVFsaf-TKrNrD_oDgUfxM5DWh14t9BwkpfsPa3nq4N-MC_fjzPThEb2gFwDeUY_Fx31kTpAFBBbTC4awxVVE5FzZdHtZDWfVc3rkyXbKI6RFFds8zV1qgWsScPpeRWimo0i4D-TwlP65bgcY-vX9hwNpVZImHs-AtQwBkfoSgfKnOXIZ2G6Vp2RK3xQIQOFpDDmoMGTOjbngiAtc1icRGpx6s_9REB_wXOF0mD55k0igdSNA8ZcS-paOaA0Z-Bh1y3pIJnp5vvUPL87yF8dX2lWVstlXlFMcHMh_ucj__06Q4UtHt1bxYhEpAAQAwBxI2RG4BVfTpsgXaMAOha463jAhIF_8ak9nlxMc1zzEQ5NlBLKWWNQwptchQFk4Y1Y1pTukbGD-m2o_UwfAyFjVhFC3OCTKqWjHQ421RJj8IHGWhoiVUG7KO_YcgZwm6aOLYXPWJ3Vjgm4we2NoQqAQVqgjywYqxPpgImzdc3Y4UQ5at3aHobeUllxbc4FJg2DVI7E7DPBEPzxvfnVzJucXpKSw7YFjTMnPs4XLso4v0hcF-ZhhEYC_dyAsKjHIUNLsH_cvFCjz0QLAeg0KaRpUvgwh0vk4_FNvidQvD6x1dZB7swHK7QVS_B87tN1jpXyw-kKgMKNJq0ODvrGZabec_hGSLkhzX-DEubjY9x8DIIXm5faUVnIvF7ngf20AUGZv8F3l_MjalgBHaGazqBrxo8h7mL0bej35tR7t0DEqMbTA-80DTeBvaHhAUOIVY0wTTZUtV6aRhFxK0a2a8WTbDIOFNU6KlG1oBvfnol2OTj8flYiBYy12smevvCEa-E9sGo4vqYZCofpDI8wDtAkUN7HqfXQRWClo1T8E3uwNnZa74e0UVQ-4LG2bR_jGzoLcRIhDXn3LCVw0QmPyBPbzrtaRR6BN9YMyVz03yEwxCkH2HCy46yTdpqpgzdkNigQl-jkLYn7C9P_e0x2bjgvXjhiFtyLaIYLcu52hh9Omu0dsK7bGnK5T01rC1jezFNFxzZ3apa63ewHE_avXDhOJA6zdE",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1106.6,
            "totalPrice": 954
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GgcQ3UvIw0pOHrgT8fomB46DerNfte6E8llyXETLpHZuW9KMnn3J7jS-kGbDPArqzAcRNi0ebOD8ybfLByNrjiYaU2J_3_UG9z5oL_kRAGkOIFitFMvF6GPD4JAO6tVXUpJji67KA-BNl0V1r9st_0jEnl2503rwpD-v_MMAcrUgA2XxnyoTfa3h0bxZYnQMlHkLhUBnOfN9YgvsXG4W3cWl-lW6SmxtH2hWYSDs6K7G2JLwW-hMehnINHH9vJi1eVUKvhjUQ8709mSNuKzyJ6HWLdHhgJzy9rHG9fykpZ-f9fZZW_wQKvA0B_z_u3AB_CviHlM_HqLNxcOgIGLoxKIu-1LRNbCxkJB8Bx9uDp3BBz8G4mAscTH4OchJ_XQsL68-LghX0TThtjz8epqlySfSz1n-rXGyn4CYv47YPHPO6Q3wIXRoKY0mQX98J4M1qZlAfpeglYm2VIaPn5v8X80lESiXHbJYl5GbKehiFLZtdY32exbLz-vyvAeApWmB-zLK1ge_s2MPH4kC8Ef0oIPDNJGvZJtXXpXGwezyL5F9BMLSQoIlnkpalOELDLoySxnTzg9c-HinOEQeVoyaCQW1nyKm0bdR-ljrwpWWWM3MGgz7H9RorngLtl8O_Y9pgLoPUfxNFOgAyoKgcBN9sQbCvv8BuvF_lnVWSA1_88t7Bp2qcizhKMXQzaM7fPMXmBpPQmlwAl2XNy1VA2SFdLk9Ed_B_jmM0nwVi7fOlHhkuqGZZj7TW_hht8aunYL2BYXAGWy4E4s4t5VuWm4C65W5ZBxPY6CTJrFI6PftexRcGYFwSMuIIVS64LNmUqDiXqJjkmrdDt75g1grN2yp1h91DN2wX7_jfATarR9vf8ORbWSievfd1o1wVVnBDDV_R7gyRbcmjkoqigOQvowWN6tIDh7DSBj4LqFe2TQsL_X37smRJ49X8bimoLGY_Ek1tVqDRtLnN6yuv2rQWQnSklA-CLCalv6oA0mZntOA3SkpXMXBHkWpIJDrcI0DNvYciU7S4r_HdJLHtKaQ5uJJNwjbdt4yI_sDrRv52zuOOU_o27hjiCKM9KaHrWTkoAXt2EYVSl70mEkkvEK1dcYgAWkAwjFFpS-9B3Xuk2KnQVuM=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GgcQ3UvIw0pOHrgT8fomB46DerNfte6E8llyXETLpHZuW9KMnn3J7jS-kGbDPArqzAcRNi0ebOD8ybfLByNrjiYaU2J_3_UG9z5oL_kRAGkOIFitFMvF6GPD4JAO6tVXUpJji67KA-BNl0V1r9st_0jEnl2503rwpD-v_MMAcrUgA2XxnyoTfa3h0bxZYnQMlHkLhUBnOfN9YgvsXG4W3cWl-lW6SmxtH2hWYSDs6K7G2JLwW-hMehnINHH9vJi1eVUKvhjUQ8709mSNuKzyJ6HWLdHhgJzy9rHG9fykpZ-f9fZZW_wQKvA0B_z_u3AB_CviHlM_HqLNxcOgIGLoxKIu-1LRNbCxkJB8Bx9uDp3BBz8G4mAscTH4OchJ_XQsL68-LghX0TThtjz8epqlySfSz1n-rXGyn4CYv47YPHPO6Q3wIXRoKY0mQX98J4M1qZlAfpeglYm2VIaPn5v8X80lESiXHbJYl5GbKehiFLZtdY32exbLz-vyvAeApWmB-zLK1ge_s2MPH4kC8Ef0oIPDNJGvZJtXXpXGwezyL5F9BMLSQoIlnkpalOELDLoySxnTzg9c-HinOEQeVoyaCQW1nyKm0bdR-ljrwpWWWM3MGgz7H9RorngLtl8O_Y9pgLoPUfxNFOgAyoKgcBN9sQbCvv8BuvF_lnVWSA1_88t7Bp2qcizhKMXQzaM7fPMXmBpPQmlwAl2XNy1VA2SFdLk9Ed_B_jmM0nwVi7fOlHhkuqGZZj7TW_hht8aunYL2BYXAGWy4E4s4t5VuWm4C65W5ZBxPY6CTJrFI6PftexRcGYFwSMuIIVS64LNmUqDiXqJjkmrdDt75g1grN2yp1h91DN2wX7_jfATarR9vf8ORbWSievfd1o1wVVnBDDV_R7gyRbcmjkoqigOQvowWN6tIDh7DSBj4LqFe2TQsL_X37smRJ49X8bimoLGY_Ek1tVqDRtLnN6yuv2rQWQnSklA-CLCalv6oA0mZntOA3SkpXMXBHkWpIJDrcI0DNvYciU7S4r_HdJLHtKaQ5uJJNwjbdt4yI_sDrRv52zuOOU_o27hjiCKM9KaHrWTkoAXt2EYVSl70mEkkvEK1dcYgAWkAwjFFpS-9B3Xuk2KnQVuM=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GgcQ3UvIw0pOHrgT8fomB46DerNfte6E8llyXETLpHZuW9KMnn3J7jS-kGbDPArqzAcRNi0ebOD8ybfLByNrjiYaU2J_3_UG9z5oL_kRAGkOIFitFMvF6GPD4JAO6tVXUpJji67KA-BNl0V1r9st_0jEnl2503rwpD-v_MMAcrUgA2XxnyoTfa3h0bxZYnQMlHkLhUBnOfN9YgvsXG4W3cWl-lW6SmxtH2hWYSDs6K7G2JLwW-hMehnINHH9vJi1eVUKvhjUQ8709mSNuKzyJ6HWLdHhgJzy9rHG9fykpZ-f9fZZW_wQKvA0B_z_u3AB_CviHlM_HqLNxcOgIGLoxKIu-1LRNbCxkJB8Bx9uDp3BBz8G4mAscTH4OchJ_XQsL68-LghX0TThtjz8epqlySfSz1n-rXGyn4CYv47YPHPO6Q3wIXRoKY0mQX98J4M1qZlAfpeglYm2VIaPn5v8X80lESiXHbJYl5GbKehiFLZtdY32exbLz-vyvAeApWmB-zLK1ge_s2MPH4kC8Ef0oIPDNJGvZJtXXpXGwezyL5F9BMLSQoIlnkpalOELDLoySxnTzg9c-HinOEQeVoyaCQW1nyKm0bdR-ljrwpWWWM3MGgz7H9RorngLtl8O_Y9pgLoPUfxNFOgAyoKgcBN9sQbCvv8BuvF_lnVWSA1_88t7Bp2qcizhKMXQzaM7fPMXmBpPQmlwAl2XNy1VA2SFdLk9Ed_B_jmM0nwVi7fOlHhkuqGZZj7TW_hht8aunYL2BYXAGWy4E4s4t5VuWm4C65W5ZBxPY6CTJrFI6PftexRcGYFwSMuIIVS64LNmUqDiXqJjkmrdDt75g1grN2yp1h91DN2wX7_jfATarR9vf8ORbWSievfd1o1wVVnBDDV_R7gyRbcmjkoqigOQvowWN6tIDh7DSBj4LqFe2TQsL_X37smRJ49X8bimoLGY_Ek1tVqDRtLnN6yuv2rQWQnSklA-CLCalv6oA0mZntOA3SkpXMXBHkWpIJDrcI0DNvYciU7S4r_HdJLHtKaQ5uJJNwjbdt4yI_sDrRv52zuOOU_o27hjiCKM9KaHrWTkoAXt2EYVSl70mEkkvEK1dcYgAWkAwjFFpS-9B3Xuk2KnQVuM=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1091,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gaukz7bdCNmaDnn91ibLwOEciXYqMsAZgZ0ybmgHAsmz5VEaGjFGnJSM5vrkbw5MT2V_Mf49NV0iSut9lL8rXh0NiRBZjmJOQnad9ClgGkyjI7lu-SZOeU0nG2JSDIqYNQN6mjq4Ytjnad7u2mJ1bSAW7wa9i6VgebDG4Q9jtCLxx5SA8f_OpQtjWaQMbjtVhf-vTqWCvPB6sf4cZ2X0QFY0kXEqzs0JmDWBB69UcsMzMaPeHqekaoshUWjfCMVzym4QC9ZVr7VflibQRgT40KWBR6VBNEKzydFoswywpwgErM1Ll54RHX-IVI3YE_UeDwD8ocpFYOoFKvh6CHbdE0tdihZhLRToxJk6Dxtbldp6dsJXrWcAKvayorSE9gvJILxifofQUSOImKz1_LKur5rduOGQLjJXFbKBZfrEc8iZjKGk0Lfbk26XZuM2VLLXASijA_9LpgPFp3ObIPWuhNuFvXONDI3x1YSQp3ZmNmGCUJ3VJ9IDvYkRTZHB9vXGmRHKB_Zfy6kGhyePTxu_jb3huuTT84N5DUml7B1gEDXR7tatqzpKMZIAgajXglLK08-SeSVs9rsSRkBeX4Jfc4rW3GykcI7M-hotJsYHv6TjqVNYR1h5hEzsdDM8r8TUbdzwH1L9XbRfXgk76curjsMSdjrW97oeqLeeKg3Eij9GD2nEqfH5Frhj8NERxLyzXKSQs9EkgnaWnWWy_FLCMNiYifptAeEtFYdYpPPZN4w8vc2rewJnSnqI5hIuVlbXJfncYGogu1OlTa19W7U8fVf-5vFdKdjMXp8ZZbbNtMoYOaa9GWYaJBdX037ochXVjFSkwdJ3nd5VxFojPAsQ-AGjiXjGDve754Td3DKdqmApT9ElchE-ypJJELiaqA9-5QyU8Ktv9tyNmRFsracVTwODVfd18Sm0r0KLo6OVPlKEQDslQ-OOa8U5zJDr74TITKum0eb8qjBjWHpbHimnWVQ_7a97ujcW4SByGCbsAdYuDiyPwCDNytTQPIi0IXyfWCoQjYAA43JVArh-kDeSt4Tpbi7r0My3uX6iZGsTNIJOS4ZO0fJdFE8IETiuVna_Y9t_KucF06rGQhu19ENKJqP5oCkCLjX2UIEMT_DRkSMs=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gaukz7bdCNmaDnn91ibLwOEciXYqMsAZgZ0ybmgHAsmz5VEaGjFGnJSM5vrkbw5MT2V_Mf49NV0iSut9lL8rXh0NiRBZjmJOQnad9ClgGkyjI7lu-SZOeU0nG2JSDIqYNQN6mjq4Ytjnad7u2mJ1bSAW7wa9i6VgebDG4Q9jtCLxx5SA8f_OpQtjWaQMbjtVhf-vTqWCvPB6sf4cZ2X0QFY0kXEqzs0JmDWBB69UcsMzMaPeHqekaoshUWjfCMVzym4QC9ZVr7VflibQRgT40KWBR6VBNEKzydFoswywpwgErM1Ll54RHX-IVI3YE_UeDwD8ocpFYOoFKvh6CHbdE0tdihZhLRToxJk6Dxtbldp6dsJXrWcAKvayorSE9gvJILxifofQUSOImKz1_LKur5rduOGQLjJXFbKBZfrEc8iZjKGk0Lfbk26XZuM2VLLXASijA_9LpgPFp3ObIPWuhNuFvXONDI3x1YSQp3ZmNmGCUJ3VJ9IDvYkRTZHB9vXGmRHKB_Zfy6kGhyePTxu_jb3huuTT84N5DUml7B1gEDXR7tatqzpKMZIAgajXglLK08-SeSVs9rsSRkBeX4Jfc4rW3GykcI7M-hotJsYHv6TjqVNYR1h5hEzsdDM8r8TUbdzwH1L9XbRfXgk76curjsMSdjrW97oeqLeeKg3Eij9GD2nEqfH5Frhj8NERxLyzXKSQs9EkgnaWnWWy_FLCMNiYifptAeEtFYdYpPPZN4w8vc2rewJnSnqI5hIuVlbXJfncYGogu1OlTa19W7U8fVf-5vFdKdjMXp8ZZbbNtMoYOaa9GWYaJBdX037ochXVjFSkwdJ3nd5VxFojPAsQ-AGjiXjGDve754Td3DKdqmApT9ElchE-ypJJELiaqA9-5QyU8Ktv9tyNmRFsracVTwODVfd18Sm0r0KLo6OVPlKEQDslQ-OOa8U5zJDr74TITKum0eb8qjBjWHpbHimnWVQ_7a97ujcW4SByGCbsAdYuDiyPwCDNytTQPIi0IXyfWCoQjYAA43JVArh-kDeSt4Tpbi7r0My3uX6iZGsTNIJOS4ZO0fJdFE8IETiuVna_Y9t_KucF06rGQhu19ENKJqP5oCkCLjX2UIEMT_DRkSMs=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gaukz7bdCNmaDnn91ibLwOEciXYqMsAZgZ0ybmgHAsmz5VEaGjFGnJSM5vrkbw5MT2V_Mf49NV0iSut9lL8rXh0NiRBZjmJOQnad9ClgGkyjI7lu-SZOeU0nG2JSDIqYNQN6mjq4Ytjnad7u2mJ1bSAW7wa9i6VgebDG4Q9jtCLxx5SA8f_OpQtjWaQMbjtVhf-vTqWCvPB6sf4cZ2X0QFY0kXEqzs0JmDWBB69UcsMzMaPeHqekaoshUWjfCMVzym4QC9ZVr7VflibQRgT40KWBR6VBNEKzydFoswywpwgErM1Ll54RHX-IVI3YE_UeDwD8ocpFYOoFKvh6CHbdE0tdihZhLRToxJk6Dxtbldp6dsJXrWcAKvayorSE9gvJILxifofQUSOImKz1_LKur5rduOGQLjJXFbKBZfrEc8iZjKGk0Lfbk26XZuM2VLLXASijA_9LpgPFp3ObIPWuhNuFvXONDI3x1YSQp3ZmNmGCUJ3VJ9IDvYkRTZHB9vXGmRHKB_Zfy6kGhyePTxu_jb3huuTT84N5DUml7B1gEDXR7tatqzpKMZIAgajXglLK08-SeSVs9rsSRkBeX4Jfc4rW3GykcI7M-hotJsYHv6TjqVNYR1h5hEzsdDM8r8TUbdzwH1L9XbRfXgk76curjsMSdjrW97oeqLeeKg3Eij9GD2nEqfH5Frhj8NERxLyzXKSQs9EkgnaWnWWy_FLCMNiYifptAeEtFYdYpPPZN4w8vc2rewJnSnqI5hIuVlbXJfncYGogu1OlTa19W7U8fVf-5vFdKdjMXp8ZZbbNtMoYOaa9GWYaJBdX037ochXVjFSkwdJ3nd5VxFojPAsQ-AGjiXjGDve754Td3DKdqmApT9ElchE-ypJJELiaqA9-5QyU8Ktv9tyNmRFsracVTwODVfd18Sm0r0KLo6OVPlKEQDslQ-OOa8U5zJDr74TITKum0eb8qjBjWHpbHimnWVQ_7a97ujcW4SByGCbsAdYuDiyPwCDNytTQPIi0IXyfWCoQjYAA43JVArh-kDeSt4Tpbi7r0My3uX6iZGsTNIJOS4ZO0fJdFE8IETiuVna_Y9t_KucF06rGQhu19ENKJqP5oCkCLjX2UIEMT_DRkSMs=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1091,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIrxkd-7P4IRD1tSdPWQgGY3crsKSDLDLa6XzGmNMHjvyAIdzv1wQADQ5FObB5jUtW4TM5lMl6_BOFPNa5dzI18duQHwyTHNKNok9iDCOc3gcDyvKRlhMbfROgzekjD-s0-SkkFF4NHcBjxd6PMtlL9tpeKCLmLS2emsNun-JybIsjDO0NGlgsHMfHxQMdiVgy4SJLPqWwiFusOywH-F8tj12uVRsu850Li9Z2Nfqw-utjAt7kwweVHzWmax0ffHMvf7ZpKrA2_ODAXOLDC2r1DBaDX_-PXkAnM6x0U3kbTkMQRWfSRt91Dk8iPe29V6rHosndY8NRjNW01uem2ZA1oDj2yJEsedozI70O4TIJqpyIw0GAzlZSGP19R5ruxpbKO-RwyIwK-350rxaXx_nR07Q76GqByWjHCbDxSJGaFkSRsYVDzG6kVDgXGJ3NGhyG19Tf-V7_VgLjXj6saoYpz7Hzs7PchAzA3nU12WtvEXM5N5kwBs_54gatd22AheIyRIubOo950mq1qgPWLMip2k3pJ1G5IosPsFluLpZQLXDeAkOANzU0F5YjPOiJL-YqfDz1b6Of3g8GbaM2ZxkkHwLBUZ0YGjx2W9JiYf1X7iCcKW8URjDaa8w8o0XM9zk627lkJpQd3WmNOENUWJTFYaJuETin1f9i7rW2WLtBENbxmXexetzy3ad0n_HC_k4H7zj-ssorUIyERNiKV4Xrk4GC0b-WAZnL2NCe-syllDxzALBWgrePKOD_0uAq-2S8RwbDUZfxGs8CJYX_ighPf9vZJsNiOLlaMJPrNgEz1S7txKsp_RFatHAEbo6WwtVivEqhqcdOag0gVGWGkM8HQmjgcscTSNGGDOdLD2g7gc8rleq1CkMe-AYtH119M3KL2S0uOKFDxs9l1wmpiM3IHZzjrll0paAnQjnTBkSQZF4dN2aINZg7tcUZerpQY6-w3F3QVV4mhsB2nO2cEtM7yXSmD5mcHSpLrntrJjx_ycLwdqsMsZcU3lUAOeWQigy1NdIw36bn75pCNhKpHeuiAlH0qO8eudmsJagRMFrqYHkaTwVXHKAFJayxg-pAyy83cy4L3c58tLXC-DBpxeU7iKFVvHDsmMMwC0rc-76wOdkuW28xwSA2mmV10R55fGq",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIrxkd-7P4IRD1tSdPWQgGY3crsKSDLDLa6XzGmNMHjvyAIdzv1wQADQ5FObB5jUtW4TM5lMl6_BOFPNa5dzI18duQHwyTHNKNok9iDCOc3gcDyvKRlhMbfROgzekjD-s0-SkkFF4NHcBjxd6PMtlL9tpeKCLmLS2emsNun-JybIsjDO0NGlgsHMfHxQMdiVgy4SJLPqWwiFusOywH-F8tj12uVRsu850Li9Z2Nfqw-utjAt7kwweVHzWmax0ffHMvf7ZpKrA2_ODAXOLDC2r1DBaDX_-PXkAnM6x0U3kbTkMQRWfSRt91Dk8iPe29V6rHosndY8NRjNW01uem2ZA1oDj2yJEsedozI70O4TIJqpyIw0GAzlZSGP19R5ruxpbKO-RwyIwK-350rxaXx_nR07Q76GqByWjHCbDxSJGaFkSRsYVDzG6kVDgXGJ3NGhyG19Tf-V7_VgLjXj6saoYpz7Hzs7PchAzA3nU12WtvEXM5N5kwBs_54gatd22AheIyRIubOo950mq1qgPWLMip2k3pJ1G5IosPsFluLpZQLXDeAkOANzU0F5YjPOiJL-YqfDz1b6Of3g8GbaM2ZxkkHwLBUZ0YGjx2W9JiYf1X7iCcKW8URjDaa8w8o0XM9zk627lkJpQd3WmNOENUWJTFYaJuETin1f9i7rW2WLtBENbxmXexetzy3ad0n_HC_k4H7zj-ssorUIyERNiKV4Xrk4GC0b-WAZnL2NCe-syllDxzALBWgrePKOD_0uAq-2S8RwbDUZfxGs8CJYX_ighPf9vZJsNiOLlaMJPrNgEz1S7txKsp_RFatHAEbo6WwtVivEqhqcdOag0gVGWGkM8HQmjgcscTSNGGDOdLD2g7gc8rleq1CkMe-AYtH119M3KL2S0uOKFDxs9l1wmpiM3IHZzjrll0paAnQjnTBkSQZF4dN2aINZg7tcUZerpQY6-w3F3QVV4mhsB2nO2cEtM7yXSmD5mcHSpLrntrJjx_ycLwdqsMsZcU3lUAOeWQigy1NdIw36bn75pCNhKpHeuiAlH0qO8eudmsJagRMFrqYHkaTwVXHKAFJayxg-pAyy83cy4L3c58tLXC-DBpxeU7iKFVvHDsmMMwC0rc-76wOdkuW28xwSA2mmV10R55fGq",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIrxkd-7P4IRD1tSdPWQgGY3crsKSDLDLa6XzGmNMHjvyAIdzv1wQADQ5FObB5jUtW4TM5lMl6_BOFPNa5dzI18duQHwyTHNKNok9iDCOc3gcDyvKRlhMbfROgzekjD-s0-SkkFF4NHcBjxd6PMtlL9tpeKCLmLS2emsNun-JybIsjDO0NGlgsHMfHxQMdiVgy4SJLPqWwiFusOywH-F8tj12uVRsu850Li9Z2Nfqw-utjAt7kwweVHzWmax0ffHMvf7ZpKrA2_ODAXOLDC2r1DBaDX_-PXkAnM6x0U3kbTkMQRWfSRt91Dk8iPe29V6rHosndY8NRjNW01uem2ZA1oDj2yJEsedozI70O4TIJqpyIw0GAzlZSGP19R5ruxpbKO-RwyIwK-350rxaXx_nR07Q76GqByWjHCbDxSJGaFkSRsYVDzG6kVDgXGJ3NGhyG19Tf-V7_VgLjXj6saoYpz7Hzs7PchAzA3nU12WtvEXM5N5kwBs_54gatd22AheIyRIubOo950mq1qgPWLMip2k3pJ1G5IosPsFluLpZQLXDeAkOANzU0F5YjPOiJL-YqfDz1b6Of3g8GbaM2ZxkkHwLBUZ0YGjx2W9JiYf1X7iCcKW8URjDaa8w8o0XM9zk627lkJpQd3WmNOENUWJTFYaJuETin1f9i7rW2WLtBENbxmXexetzy3ad0n_HC_k4H7zj-ssorUIyERNiKV4Xrk4GC0b-WAZnL2NCe-syllDxzALBWgrePKOD_0uAq-2S8RwbDUZfxGs8CJYX_ighPf9vZJsNiOLlaMJPrNgEz1S7txKsp_RFatHAEbo6WwtVivEqhqcdOag0gVGWGkM8HQmjgcscTSNGGDOdLD2g7gc8rleq1CkMe-AYtH119M3KL2S0uOKFDxs9l1wmpiM3IHZzjrll0paAnQjnTBkSQZF4dN2aINZg7tcUZerpQY6-w3F3QVV4mhsB2nO2cEtM7yXSmD5mcHSpLrntrJjx_ycLwdqsMsZcU3lUAOeWQigy1NdIw36bn75pCNhKpHeuiAlH0qO8eudmsJagRMFrqYHkaTwVXHKAFJayxg-pAyy83cy4L3c58tLXC-DBpxeU7iKFVvHDsmMMwC0rc-76wOdkuW28xwSA2mmV10R55fGq",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1091.67,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3x_Qp58MeRggmi6L6j4jlAMHfMNLt55r08V4gdYKq9vv4wk_rHf-WVL2rg6G02D5Eqgdt3oMI54WPTF1pL29LJniTU-OisFGu-cOFV9XF1ZR8Lj1mwcUwPVEkxx27MZnYzXoEAl0KZGSDn3LPVDXgQhOAsiGlBdOxotkOsngmj6NybvbLcryeEEs8gmwcShDns88wRpycsMzhJub42Zod7CSaeaTbisnTSSqHK_7TnO8Ox61fSoy6B1pnkp2ohXyd98H6vH0FndSKk18dz_j_jWz_3zmCGWCEFGJkNxB4VU4BDLkPzbuxUKw5EgbqzqqIDO7lCJF-9pSS4c1yanWjfCoLx3g-BVNUmSD5ePRBjJa75BGCzpr1T_qpLTAnDYDz64hjXG2UhPosvQwCVC35rPsLvNaAp8f57kYHcAqA4AyCVUe4_4Kq7NiB7Y69s8V0QqP7Pn2YkPWzdvcYsmIKUnm8q5mT3YAm7kWpuCeEjEeUsk-HRzgnZjCS-B2X0DZYSsLG8bxHJgAgRiW55OKSO_uMkTivgjG9pMr0RV1d3TaJD1WiRqVlzTsJUBviJKql6j3basfqSrmcDWPsIxYI6_sNd7qNxdSvbuOvs5T0ORVR4N9Anm8_mdLHt2Mg9EyRv1JA1wXSZUgga0KMnsoe9D9TCmYDmnuR39TnzHEndi0V3ZCGWOHNvt-mnvm-8ZEz4Z2UyV7VMQ3u-PG5I7k3MqNeokoWRdIUFQ3BFlnfZ6Ambdzn7wIJ371kmRFn2900-pO4HkOEIfrh5wL1FUCBHugfsBPDpobBUeL-OA3r9tbnGs5kqkTD4mbwdLbEtJkL0VHas0VbJ6raRywPhPmrpGM1dHkm-U_f68yo7cQYa443dlSfRKcrZ8OKjuZti0-t5jxkVmUrUqcLZ3UT2rDc5YMQVRe_Zez4poMh-4DGQbikx5dZTOT61isYhXHIld1QT41mgYz_JKANqeAsAht41gWlB2OFJz26YkzCq2LsnTDL8JlndNwfhjFVDMRluiQ38B0qP9nvx0i2G0vRwsRlkuFvz-ThtfOK4HR7cjYSl3uoii8uom3SGFVisp-BW5hj2ZPtRk9O7MhLgKg9Evflw==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3x_Qp58MeRggmi6L6j4jlAMHfMNLt55r08V4gdYKq9vv4wk_rHf-WVL2rg6G02D5Eqgdt3oMI54WPTF1pL29LJniTU-OisFGu-cOFV9XF1ZR8Lj1mwcUwPVEkxx27MZnYzXoEAl0KZGSDn3LPVDXgQhOAsiGlBdOxotkOsngmj6NybvbLcryeEEs8gmwcShDns88wRpycsMzhJub42Zod7CSaeaTbisnTSSqHK_7TnO8Ox61fSoy6B1pnkp2ohXyd98H6vH0FndSKk18dz_j_jWz_3zmCGWCEFGJkNxB4VU4BDLkPzbuxUKw5EgbqzqqIDO7lCJF-9pSS4c1yanWjfCoLx3g-BVNUmSD5ePRBjJa75BGCzpr1T_qpLTAnDYDz64hjXG2UhPosvQwCVC35rPsLvNaAp8f57kYHcAqA4AyCVUe4_4Kq7NiB7Y69s8V0QqP7Pn2YkPWzdvcYsmIKUnm8q5mT3YAm7kWpuCeEjEeUsk-HRzgnZjCS-B2X0DZYSsLG8bxHJgAgRiW55OKSO_uMkTivgjG9pMr0RV1d3TaJD1WiRqVlzTsJUBviJKql6j3basfqSrmcDWPsIxYI6_sNd7qNxdSvbuOvs5T0ORVR4N9Anm8_mdLHt2Mg9EyRv1JA1wXSZUgga0KMnsoe9D9TCmYDmnuR39TnzHEndi0V3ZCGWOHNvt-mnvm-8ZEz4Z2UyV7VMQ3u-PG5I7k3MqNeokoWRdIUFQ3BFlnfZ6Ambdzn7wIJ371kmRFn2900-pO4HkOEIfrh5wL1FUCBHugfsBPDpobBUeL-OA3r9tbnGs5kqkTD4mbwdLbEtJkL0VHas0VbJ6raRywPhPmrpGM1dHkm-U_f68yo7cQYa443dlSfRKcrZ8OKjuZti0-t5jxkVmUrUqcLZ3UT2rDc5YMQVRe_Zez4poMh-4DGQbikx5dZTOT61isYhXHIld1QT41mgYz_JKANqeAsAht41gWlB2OFJz26YkzCq2LsnTDL8JlndNwfhjFVDMRluiQ38B0qP9nvx0i2G0vRwsRlkuFvz-ThtfOK4HR7cjYSl3uoii8uom3SGFVisp-BW5hj2ZPtRk9O7MhLgKg9Evflw==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3x_Qp58MeRggmi6L6j4jlAMHfMNLt55r08V4gdYKq9vv4wk_rHf-WVL2rg6G02D5Eqgdt3oMI54WPTF1pL29LJniTU-OisFGu-cOFV9XF1ZR8Lj1mwcUwPVEkxx27MZnYzXoEAl0KZGSDn3LPVDXgQhOAsiGlBdOxotkOsngmj6NybvbLcryeEEs8gmwcShDns88wRpycsMzhJub42Zod7CSaeaTbisnTSSqHK_7TnO8Ox61fSoy6B1pnkp2ohXyd98H6vH0FndSKk18dz_j_jWz_3zmCGWCEFGJkNxB4VU4BDLkPzbuxUKw5EgbqzqqIDO7lCJF-9pSS4c1yanWjfCoLx3g-BVNUmSD5ePRBjJa75BGCzpr1T_qpLTAnDYDz64hjXG2UhPosvQwCVC35rPsLvNaAp8f57kYHcAqA4AyCVUe4_4Kq7NiB7Y69s8V0QqP7Pn2YkPWzdvcYsmIKUnm8q5mT3YAm7kWpuCeEjEeUsk-HRzgnZjCS-B2X0DZYSsLG8bxHJgAgRiW55OKSO_uMkTivgjG9pMr0RV1d3TaJD1WiRqVlzTsJUBviJKql6j3basfqSrmcDWPsIxYI6_sNd7qNxdSvbuOvs5T0ORVR4N9Anm8_mdLHt2Mg9EyRv1JA1wXSZUgga0KMnsoe9D9TCmYDmnuR39TnzHEndi0V3ZCGWOHNvt-mnvm-8ZEz4Z2UyV7VMQ3u-PG5I7k3MqNeokoWRdIUFQ3BFlnfZ6Ambdzn7wIJ371kmRFn2900-pO4HkOEIfrh5wL1FUCBHugfsBPDpobBUeL-OA3r9tbnGs5kqkTD4mbwdLbEtJkL0VHas0VbJ6raRywPhPmrpGM1dHkm-U_f68yo7cQYa443dlSfRKcrZ8OKjuZti0-t5jxkVmUrUqcLZ3UT2rDc5YMQVRe_Zez4poMh-4DGQbikx5dZTOT61isYhXHIld1QT41mgYz_JKANqeAsAht41gWlB2OFJz26YkzCq2LsnTDL8JlndNwfhjFVDMRluiQ38B0qP9nvx0i2G0vRwsRlkuFvz-ThtfOK4HR7cjYSl3uoii8uom3SGFVisp-BW5hj2ZPtRk9O7MhLgKg9Evflw==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1092.52,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 961,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYFQJ3uzK0tUhqPNsc9XoPBJeq7i8qjhBu-QdMwiCGdWxMRhfxA87KVgQOOGvXY2_nJSZm8lvJ4oH4IRLSvuWNSJkPzL3hMQ_Qcs4PfCfks0DmfoUzx-lb3fkjzLfkGlv7sMsQWaLnNoZ-wrDBR8lgsVY0BeNdgvM-itIlvkzlTVf2vZNyEQwH5_tasnE1uuaAhAaUZYfvZGmDkDP7-00Oqr2Tyd5KYRuXh7ruoRnV_vYwkR_X6VihUKAhVlaSPhn4aegZivo4eW1XTPwFgmxu5NooMKrfKTKfRJzFkfN8qhg6QRUHLgy_pbIyFveSlrGmM8gGAWh74eCcIsX71iaoKZrSu8cFiQPvtGG7sP7BMCGYQ9-2Po5vL_SL_nWLF7ld1TUc2Tn78TDSWp56Vc0PMAS9YUh7k8m7WIQcslSkRPME2x9pFB37jjI65Rn9m5BmBUMzXMrFNxPeRVjlq79R1Lj-0VnBMKGvoXjwO-af7GclkYGcDS4sQqm4echsa99aLxVmi9sQfCL24mcjk-jI5RGgpFCvA0a0DJuG4yOYhKir-Om133mw2mFIe74rtsCOhpebo61aMc8IaCW29woAneDN63bKtTf9kz5EeYgGjHGRF3QxiR1QfLeB80bbtX3xKNQxGsFd5WG0m568O0PFRfpKjM8LX_wtEvf1E4Cfw76ClSgNpUMMJwbOwxw0eJxz2-zMC3jgeawlpI_s5WAnNhYSkwHLtTnkr_mHtufWJlBYOJ2Kpo0xECZzh7G3vK4i1GQ-2Xv3Pkul87ol4uRNuxO_xCr5_Dy3iJcqPj9Z82WoyYM0i28U0r8kJWN8g0QkxC7TaXcjzhe6bgRhny8pTx6Fcwa3oAd-VXS_0KYYgdtFxrZ8o__3USpiyeXllOYp7kjbFN9KVOcCIF_AM-mmPj8HLy3spcJbxV6ASfJLSaY0vlqw8ojFIgIHO4ozl296gVT4WhFNqeFZYjCGFaM5mGgV5C-D7jPm3TRR7MOkaRtfJsaGqcsQlnI-iG4SkdgufyLpCTEDyPFwvarT09nugrKN-mvjFO1emYiwj5andkQFvhjkm4ZZ2BHUReU5oHpnLJxRVw92Jr4iK71Ue6ZRMer0xwDqHKT6d7gqxA7_GpS--fOTqc2FxtnwlPRdBGUpPpXKSxVlojFP2RZWVIHAQ==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYFQJ3uzK0tUhqPNsc9XoPBJeq7i8qjhBu-QdMwiCGdWxMRhfxA87KVgQOOGvXY2_nJSZm8lvJ4oH4IRLSvuWNSJkPzL3hMQ_Qcs4PfCfks0DmfoUzx-lb3fkjzLfkGlv7sMsQWaLnNoZ-wrDBR8lgsVY0BeNdgvM-itIlvkzlTVf2vZNyEQwH5_tasnE1uuaAhAaUZYfvZGmDkDP7-00Oqr2Tyd5KYRuXh7ruoRnV_vYwkR_X6VihUKAhVlaSPhn4aegZivo4eW1XTPwFgmxu5NooMKrfKTKfRJzFkfN8qhg6QRUHLgy_pbIyFveSlrGmM8gGAWh74eCcIsX71iaoKZrSu8cFiQPvtGG7sP7BMCGYQ9-2Po5vL_SL_nWLF7ld1TUc2Tn78TDSWp56Vc0PMAS9YUh7k8m7WIQcslSkRPME2x9pFB37jjI65Rn9m5BmBUMzXMrFNxPeRVjlq79R1Lj-0VnBMKGvoXjwO-af7GclkYGcDS4sQqm4echsa99aLxVmi9sQfCL24mcjk-jI5RGgpFCvA0a0DJuG4yOYhKir-Om133mw2mFIe74rtsCOhpebo61aMc8IaCW29woAneDN63bKtTf9kz5EeYgGjHGRF3QxiR1QfLeB80bbtX3xKNQxGsFd5WG0m568O0PFRfpKjM8LX_wtEvf1E4Cfw76ClSgNpUMMJwbOwxw0eJxz2-zMC3jgeawlpI_s5WAnNhYSkwHLtTnkr_mHtufWJlBYOJ2Kpo0xECZzh7G3vK4i1GQ-2Xv3Pkul87ol4uRNuxO_xCr5_Dy3iJcqPj9Z82WoyYM0i28U0r8kJWN8g0QkxC7TaXcjzhe6bgRhny8pTx6Fcwa3oAd-VXS_0KYYgdtFxrZ8o__3USpiyeXllOYp7kjbFN9KVOcCIF_AM-mmPj8HLy3spcJbxV6ASfJLSaY0vlqw8ojFIgIHO4ozl296gVT4WhFNqeFZYjCGFaM5mGgV5C-D7jPm3TRR7MOkaRtfJsaGqcsQlnI-iG4SkdgufyLpCTEDyPFwvarT09nugrKN-mvjFO1emYiwj5andkQFvhjkm4ZZ2BHUReU5oHpnLJxRVw92Jr4iK71Ue6ZRMer0xwDqHKT6d7gqxA7_GpS--fOTqc2FxtnwlPRdBGUpPpXKSxVlojFP2RZWVIHAQ==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GYFQJ3uzK0tUhqPNsc9XoPBJeq7i8qjhBu-QdMwiCGdWxMRhfxA87KVgQOOGvXY2_nJSZm8lvJ4oH4IRLSvuWNSJkPzL3hMQ_Qcs4PfCfks0DmfoUzx-lb3fkjzLfkGlv7sMsQWaLnNoZ-wrDBR8lgsVY0BeNdgvM-itIlvkzlTVf2vZNyEQwH5_tasnE1uuaAhAaUZYfvZGmDkDP7-00Oqr2Tyd5KYRuXh7ruoRnV_vYwkR_X6VihUKAhVlaSPhn4aegZivo4eW1XTPwFgmxu5NooMKrfKTKfRJzFkfN8qhg6QRUHLgy_pbIyFveSlrGmM8gGAWh74eCcIsX71iaoKZrSu8cFiQPvtGG7sP7BMCGYQ9-2Po5vL_SL_nWLF7ld1TUc2Tn78TDSWp56Vc0PMAS9YUh7k8m7WIQcslSkRPME2x9pFB37jjI65Rn9m5BmBUMzXMrFNxPeRVjlq79R1Lj-0VnBMKGvoXjwO-af7GclkYGcDS4sQqm4echsa99aLxVmi9sQfCL24mcjk-jI5RGgpFCvA0a0DJuG4yOYhKir-Om133mw2mFIe74rtsCOhpebo61aMc8IaCW29woAneDN63bKtTf9kz5EeYgGjHGRF3QxiR1QfLeB80bbtX3xKNQxGsFd5WG0m568O0PFRfpKjM8LX_wtEvf1E4Cfw76ClSgNpUMMJwbOwxw0eJxz2-zMC3jgeawlpI_s5WAnNhYSkwHLtTnkr_mHtufWJlBYOJ2Kpo0xECZzh7G3vK4i1GQ-2Xv3Pkul87ol4uRNuxO_xCr5_Dy3iJcqPj9Z82WoyYM0i28U0r8kJWN8g0QkxC7TaXcjzhe6bgRhny8pTx6Fcwa3oAd-VXS_0KYYgdtFxrZ8o__3USpiyeXllOYp7kjbFN9KVOcCIF_AM-mmPj8HLy3spcJbxV6ASfJLSaY0vlqw8ojFIgIHO4ozl296gVT4WhFNqeFZYjCGFaM5mGgV5C-D7jPm3TRR7MOkaRtfJsaGqcsQlnI-iG4SkdgufyLpCTEDyPFwvarT09nugrKN-mvjFO1emYiwj5andkQFvhjkm4ZZ2BHUReU5oHpnLJxRVw92Jr4iK71Ue6ZRMer0xwDqHKT6d7gqxA7_GpS--fOTqc2FxtnwlPRdBGUpPpXKSxVlojFP2RZWVIHAQ==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1111.53,
            "totalPrice": 961
          },
          {
            "allPassengerTotalPrice": 963,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0Ng0E4--PHjLCcE1zsNFieJjBHXzYlIoLorcHE9jdsbVXJedXt5ZFrmWP4H5h6gg3svZ2rncviBWlzyy3dZBtBMnSxEeyDkLasBOPumIc776U9ZXBqBRqWvuvgApCvRgnqXCfw4Taic7UsPAewszhSNJ5BfkiHdRgFKED_U7NzRtcjlYWbuxwx6dgkzJOkUNniRwS6gucFZrLBjOThKQsXX0dhMP-R1aDapJhhpryJu1nYPDr7xnhXCbxsNphxDkB0HxmjsacVrGEbGmf7h4dTmv8aJj2CgZZoJg0AwxCVoP-fGrnPXVMkztC0OER6bI0tDf3NXIMzkVxrPYGtRoDwCn5frVJeLJ8ffdaTGVoCHXN9gyg7erfxSY8HOzPstOZerOj9e2xGzaz82jevLBD3MuaqljOxIwcg3ssSVN05a5yWYJ6mRv2ci5mZyirHHCU7kOnuQA58UOfcXr_UUFgaYenv27qt_hQka3kmSSTomLbv8cgfs1RvOFwpue-wuQ9dEiJwmFj0Nav6-79-TEy-JVhNpGDJrwYTYQgY6mhWHXI0CkNX82Q-1pqNMkAP8jN8bPbkdQvD-t9-UK7QLdTMoOje9NLsQUD0nQxxk6ePEVhPoJZCezikTMwblk2NhDaihDFI-W8QL0FtupTitu62QPMu3PszNStQmZY2KeX0uJMftf_Wk0TqOzNWb6A6Pt2gyhAWoxrR3L85rZPNC6tBra2oK6C6-ZUfvcSIP3soFd9cOAjw24ibnj0y0cUJNTjXNNSWJyZCz-II5OSeO594htdpzW7Qi5sMTikqqCJJTE-_6bq1Qdc5pQJ3nR-wWBRe4ml3OqEmYFzWliW0c67m37L0W7eR98Bqplp9cyb0aPuNy11Oxo-KdVq672YcPCY1N7jIP0L6wJlotjxrODJ4J3K8TdI2YiB_4AggNfVbFjMyeF3Ayf0uf0ImYIlU3pekawevaEOVK0MGCrjmTbLNzwy4qFWYIfRZ--HP8aiH0usPtjb2HZqUHiQT3L09ASss5B_tgBLYyGftZb72skKAiSYxTimXIESEmwYgMhuAKQr-G0L-b-DC0DK9vhPTttbMk_AUt6y44b0NsvOqEF0y_O_u9oUItCLO0Y5NsHOsadq9MdRjZA-pGWY2NPPTwKBKPProNlv5UZCPFoGUlMgw==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0Ng0E4--PHjLCcE1zsNFieJjBHXzYlIoLorcHE9jdsbVXJedXt5ZFrmWP4H5h6gg3svZ2rncviBWlzyy3dZBtBMnSxEeyDkLasBOPumIc776U9ZXBqBRqWvuvgApCvRgnqXCfw4Taic7UsPAewszhSNJ5BfkiHdRgFKED_U7NzRtcjlYWbuxwx6dgkzJOkUNniRwS6gucFZrLBjOThKQsXX0dhMP-R1aDapJhhpryJu1nYPDr7xnhXCbxsNphxDkB0HxmjsacVrGEbGmf7h4dTmv8aJj2CgZZoJg0AwxCVoP-fGrnPXVMkztC0OER6bI0tDf3NXIMzkVxrPYGtRoDwCn5frVJeLJ8ffdaTGVoCHXN9gyg7erfxSY8HOzPstOZerOj9e2xGzaz82jevLBD3MuaqljOxIwcg3ssSVN05a5yWYJ6mRv2ci5mZyirHHCU7kOnuQA58UOfcXr_UUFgaYenv27qt_hQka3kmSSTomLbv8cgfs1RvOFwpue-wuQ9dEiJwmFj0Nav6-79-TEy-JVhNpGDJrwYTYQgY6mhWHXI0CkNX82Q-1pqNMkAP8jN8bPbkdQvD-t9-UK7QLdTMoOje9NLsQUD0nQxxk6ePEVhPoJZCezikTMwblk2NhDaihDFI-W8QL0FtupTitu62QPMu3PszNStQmZY2KeX0uJMftf_Wk0TqOzNWb6A6Pt2gyhAWoxrR3L85rZPNC6tBra2oK6C6-ZUfvcSIP3soFd9cOAjw24ibnj0y0cUJNTjXNNSWJyZCz-II5OSeO594htdpzW7Qi5sMTikqqCJJTE-_6bq1Qdc5pQJ3nR-wWBRe4ml3OqEmYFzWliW0c67m37L0W7eR98Bqplp9cyb0aPuNy11Oxo-KdVq672YcPCY1N7jIP0L6wJlotjxrODJ4J3K8TdI2YiB_4AggNfVbFjMyeF3Ayf0uf0ImYIlU3pekawevaEOVK0MGCrjmTbLNzwy4qFWYIfRZ--HP8aiH0usPtjb2HZqUHiQT3L09ASss5B_tgBLYyGftZb72skKAiSYxTimXIESEmwYgMhuAKQr-G0L-b-DC0DK9vhPTttbMk_AUt6y44b0NsvOqEF0y_O_u9oUItCLO0Y5NsHOsadq9MdRjZA-pGWY2NPPTwKBKPProNlv5UZCPFoGUlMgw==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0Ng0E4--PHjLCcE1zsNFieJjBHXzYlIoLorcHE9jdsbVXJedXt5ZFrmWP4H5h6gg3svZ2rncviBWlzyy3dZBtBMnSxEeyDkLasBOPumIc776U9ZXBqBRqWvuvgApCvRgnqXCfw4Taic7UsPAewszhSNJ5BfkiHdRgFKED_U7NzRtcjlYWbuxwx6dgkzJOkUNniRwS6gucFZrLBjOThKQsXX0dhMP-R1aDapJhhpryJu1nYPDr7xnhXCbxsNphxDkB0HxmjsacVrGEbGmf7h4dTmv8aJj2CgZZoJg0AwxCVoP-fGrnPXVMkztC0OER6bI0tDf3NXIMzkVxrPYGtRoDwCn5frVJeLJ8ffdaTGVoCHXN9gyg7erfxSY8HOzPstOZerOj9e2xGzaz82jevLBD3MuaqljOxIwcg3ssSVN05a5yWYJ6mRv2ci5mZyirHHCU7kOnuQA58UOfcXr_UUFgaYenv27qt_hQka3kmSSTomLbv8cgfs1RvOFwpue-wuQ9dEiJwmFj0Nav6-79-TEy-JVhNpGDJrwYTYQgY6mhWHXI0CkNX82Q-1pqNMkAP8jN8bPbkdQvD-t9-UK7QLdTMoOje9NLsQUD0nQxxk6ePEVhPoJZCezikTMwblk2NhDaihDFI-W8QL0FtupTitu62QPMu3PszNStQmZY2KeX0uJMftf_Wk0TqOzNWb6A6Pt2gyhAWoxrR3L85rZPNC6tBra2oK6C6-ZUfvcSIP3soFd9cOAjw24ibnj0y0cUJNTjXNNSWJyZCz-II5OSeO594htdpzW7Qi5sMTikqqCJJTE-_6bq1Qdc5pQJ3nR-wWBRe4ml3OqEmYFzWliW0c67m37L0W7eR98Bqplp9cyb0aPuNy11Oxo-KdVq672YcPCY1N7jIP0L6wJlotjxrODJ4J3K8TdI2YiB_4AggNfVbFjMyeF3Ayf0uf0ImYIlU3pekawevaEOVK0MGCrjmTbLNzwy4qFWYIfRZ--HP8aiH0usPtjb2HZqUHiQT3L09ASss5B_tgBLYyGftZb72skKAiSYxTimXIESEmwYgMhuAKQr-G0L-b-DC0DK9vhPTttbMk_AUt6y44b0NsvOqEF0y_O_u9oUItCLO0Y5NsHOsadq9MdRjZA-pGWY2NPPTwKBKPProNlv5UZCPFoGUlMgw==",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1112.94,
            "totalPrice": 963
          },
          {
            "allPassengerTotalPrice": 967,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjYFL90pvSV0Z2EunwkQD8B7ZEEtP5YHu0ktNiKQljN8BLRhm5s0bAFqyNJzavduczyfZj0CKtTkQqgoOTxPWvG2a-Wgw60vKFmonohGj1g6Sj_5h9IufAasH1-nDq4ZCCNw8rVggwuI7fFSHXWggigkUhvCyBUIY2vZIdoSVOY8eYRjBgAZsseyVFHG7rQRkPBB5Uskr_XxgVqSdGifC_uaaEw-8wkcFlomUnfON0mAsl-lW-drOY8GV7NnJTl0fwEQ8Dcr5l6MZtIhcMaWOmyhhogtbLc6KeKr_7-w8k8fdKsuoFmq6pL7MU_-glKyaC7zAsqh-cdG1NLSpPXQ7gTvfwYKr07Zb1CRGYIWO7tMV9bEvt7dSjcUUahAMhB_8BynAOEuV00tsV9qXIXuj2xa1bH4dCNPv5znIUAXAHKAxclCNNii_cV2BDJEXR6ecaR-PLl4E2NziqiiWKFDPiK-T-dYZHy2k5Yy7_pJYmgvtjCoh807EglThqvyZtbqNVHEqwuzgYuaOO8GMxXMpY0sPhtB1BTA8BOfVR6z4GSEsdH54g24A5mqwiWsyynT0Bof8MWbCMmvBSKf0Y2LUWgQB9gG6_qWcmDNcs6SRWTk3mXdeR9qAoHhAxYHGGXbw03sDugD76Tc9OnQvQO0BnxG5ehaYzZ_U3zUUkS5QgbSHvCUKQ31J2aBjzJ0HjCAi9SBnm5cBbUBH2acc2GAVQqZy-1Q5LcKKjJ1ZsZ2JfvwFvwM2FjBqtFKH_F7F4T1pUid_5dLUBnTXXHiTS8kjXa--OORXZ0odpmQP_MOBaWm8ofKWai-2zDaQ1A9rHD-Ka8yx3BeQ4gKDDDPw0kDCQ_-on9Uo3-4xq4UXTqVafxYFAF1OVEO4VmiatnC8S6DYnaqOt30m52Q5cXotKgPkEUMq_wW-DLXBHxAlbcZE7CV-fjZEZagMwppwjoB3TWJ0WjhTUyFsNnTG7xd1XfgQ3XH6soKypiVZmVRsRgXGTXMoTVjzxhgVNtDdxX-w1QGY6Jpv4FD8PvgyMQqIqShaclncUDFyyA1Z64DNy0_GP6oYfoOcLq6qZScX_cIE3Wy4knlAN5dHzWkzW_05-U61nYTOjCGQDj0Kth07JWBF_eQJzeHC7VUm6BNSQ0rhelCQ",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjYFL90pvSV0Z2EunwkQD8B7ZEEtP5YHu0ktNiKQljN8BLRhm5s0bAFqyNJzavduczyfZj0CKtTkQqgoOTxPWvG2a-Wgw60vKFmonohGj1g6Sj_5h9IufAasH1-nDq4ZCCNw8rVggwuI7fFSHXWggigkUhvCyBUIY2vZIdoSVOY8eYRjBgAZsseyVFHG7rQRkPBB5Uskr_XxgVqSdGifC_uaaEw-8wkcFlomUnfON0mAsl-lW-drOY8GV7NnJTl0fwEQ8Dcr5l6MZtIhcMaWOmyhhogtbLc6KeKr_7-w8k8fdKsuoFmq6pL7MU_-glKyaC7zAsqh-cdG1NLSpPXQ7gTvfwYKr07Zb1CRGYIWO7tMV9bEvt7dSjcUUahAMhB_8BynAOEuV00tsV9qXIXuj2xa1bH4dCNPv5znIUAXAHKAxclCNNii_cV2BDJEXR6ecaR-PLl4E2NziqiiWKFDPiK-T-dYZHy2k5Yy7_pJYmgvtjCoh807EglThqvyZtbqNVHEqwuzgYuaOO8GMxXMpY0sPhtB1BTA8BOfVR6z4GSEsdH54g24A5mqwiWsyynT0Bof8MWbCMmvBSKf0Y2LUWgQB9gG6_qWcmDNcs6SRWTk3mXdeR9qAoHhAxYHGGXbw03sDugD76Tc9OnQvQO0BnxG5ehaYzZ_U3zUUkS5QgbSHvCUKQ31J2aBjzJ0HjCAi9SBnm5cBbUBH2acc2GAVQqZy-1Q5LcKKjJ1ZsZ2JfvwFvwM2FjBqtFKH_F7F4T1pUid_5dLUBnTXXHiTS8kjXa--OORXZ0odpmQP_MOBaWm8ofKWai-2zDaQ1A9rHD-Ka8yx3BeQ4gKDDDPw0kDCQ_-on9Uo3-4xq4UXTqVafxYFAF1OVEO4VmiatnC8S6DYnaqOt30m52Q5cXotKgPkEUMq_wW-DLXBHxAlbcZE7CV-fjZEZagMwppwjoB3TWJ0WjhTUyFsNnTG7xd1XfgQ3XH6soKypiVZmVRsRgXGTXMoTVjzxhgVNtDdxX-w1QGY6Jpv4FD8PvgyMQqIqShaclncUDFyyA1Z64DNy0_GP6oYfoOcLq6qZScX_cIE3Wy4knlAN5dHzWkzW_05-U61nYTOjCGQDj0Kth07JWBF_eQJzeHC7VUm6BNSQ0rhelCQ",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjYFL90pvSV0Z2EunwkQD8B7ZEEtP5YHu0ktNiKQljN8BLRhm5s0bAFqyNJzavduczyfZj0CKtTkQqgoOTxPWvG2a-Wgw60vKFmonohGj1g6Sj_5h9IufAasH1-nDq4ZCCNw8rVggwuI7fFSHXWggigkUhvCyBUIY2vZIdoSVOY8eYRjBgAZsseyVFHG7rQRkPBB5Uskr_XxgVqSdGifC_uaaEw-8wkcFlomUnfON0mAsl-lW-drOY8GV7NnJTl0fwEQ8Dcr5l6MZtIhcMaWOmyhhogtbLc6KeKr_7-w8k8fdKsuoFmq6pL7MU_-glKyaC7zAsqh-cdG1NLSpPXQ7gTvfwYKr07Zb1CRGYIWO7tMV9bEvt7dSjcUUahAMhB_8BynAOEuV00tsV9qXIXuj2xa1bH4dCNPv5znIUAXAHKAxclCNNii_cV2BDJEXR6ecaR-PLl4E2NziqiiWKFDPiK-T-dYZHy2k5Yy7_pJYmgvtjCoh807EglThqvyZtbqNVHEqwuzgYuaOO8GMxXMpY0sPhtB1BTA8BOfVR6z4GSEsdH54g24A5mqwiWsyynT0Bof8MWbCMmvBSKf0Y2LUWgQB9gG6_qWcmDNcs6SRWTk3mXdeR9qAoHhAxYHGGXbw03sDugD76Tc9OnQvQO0BnxG5ehaYzZ_U3zUUkS5QgbSHvCUKQ31J2aBjzJ0HjCAi9SBnm5cBbUBH2acc2GAVQqZy-1Q5LcKKjJ1ZsZ2JfvwFvwM2FjBqtFKH_F7F4T1pUid_5dLUBnTXXHiTS8kjXa--OORXZ0odpmQP_MOBaWm8ofKWai-2zDaQ1A9rHD-Ka8yx3BeQ4gKDDDPw0kDCQ_-on9Uo3-4xq4UXTqVafxYFAF1OVEO4VmiatnC8S6DYnaqOt30m52Q5cXotKgPkEUMq_wW-DLXBHxAlbcZE7CV-fjZEZagMwppwjoB3TWJ0WjhTUyFsNnTG7xd1XfgQ3XH6soKypiVZmVRsRgXGTXMoTVjzxhgVNtDdxX-w1QGY6Jpv4FD8PvgyMQqIqShaclncUDFyyA1Z64DNy0_GP6oYfoOcLq6qZScX_cIE3Wy4knlAN5dHzWkzW_05-U61nYTOjCGQDj0Kth07JWBF_eQJzeHC7VUm6BNSQ0rhelCQ",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1097.45,
            "totalPrice": 967
          },
          {
            "allPassengerTotalPrice": 967,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoRxAwBG3W_wOP_IGltCVqeknV2aHvrEZB_twE8Q60-hmOS8CxeeI7UVo_y063FjHv3BweVFQAXRCwx4CDxrqwe0vm6il2isBQbO_tKte2bkfBgY_WZ37DACoOlByF1gpukntq_c_6elyg7bL0juBKOKvygGalHZ1BfVK98whHRrFH8ks_F9zJTIz_CfzYkJAibVYFYf_kMq-wW_8Zvo6YtkCHnZ34-S-MHbQugiTUZEC2ZOcLz2SfxbKpMuTg2JxlPv5pcNMUTuZCeYbjjf6zczo2E7K-jvDhthv7U_Vl__XiRBrOV2cT1jTYuxvfLuJ3a5D_3IbRTuFw9hnyJw-IDCSH9oG_gjxw0UK7IbYrJZhFZlnjR4Ji_ejgaaQ1Kef0o4eaqUQccCkzVLkyH8uJivnkkERRH7E5bbdm347kaJG9q8u86fFuNcQQEWHUrGPi4tJkrmmRe4Kw9EpIhoklubfn9wrhkBzaZ3ZPIeBQZPn8KQP_Oh5zmtPL6sRTrJHLdDkJvKtvcAWVdJdKUYEVbxCOo3MOaa9K15Gv_K3zRK6tetrqzhXPM9icZRLT7iGNP19w_xuVUoU0_vnGa9nqj-0hrkfD3rj6Ig1md5YptLK5Yxt_YqVdlyd7t5cUFVv9bXCrrQWNvG65Qaclbnk4ntAE5Rj401dvAhdnHE79eJ524uNo3XO1m9mzaXFTU1Rp3x9AhKez5vKDV0Hj0QZEQp6kqqOJTSaobE7LPTUPxq3MfyfBtpKU1nz-S0r35Tij8a5Og1C_6ViOeijRv2K2-Bwky99ghNQU6xvXzpd84i7kJ_MEJU5El29tburp__c_u3fRF4m5Pczo8BLtu98dZGYP7GYX7NYYspM4qbE5qN0jyfzxCd8h_qTN1q0zW5a7-A2dMD71s-t9x42ZQkPKGDg-XIb0X-HBIYUQK_j9zqsqqWFlRlBh2FM8veASt3ee43Qd8gMSIOBmZTQWKBjSpuWzsmbMscxXSOeLG3FfIWCTXfIRQPpASRRhopKdErvC1CCDKndewTxZMzOd_YoG0EDt-Z_tO_kCkRzzBetvPAsWwmXPi3h7EvD5--so84A_mzP3P99LZ1_MH-9JjOnwnNJ0LN0II5pVJfecGPkIKYHD7axI6uqENMREqDKzJKjW4Yp7179yuRmzIvhTt8n_g==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoRxAwBG3W_wOP_IGltCVqeknV2aHvrEZB_twE8Q60-hmOS8CxeeI7UVo_y063FjHv3BweVFQAXRCwx4CDxrqwe0vm6il2isBQbO_tKte2bkfBgY_WZ37DACoOlByF1gpukntq_c_6elyg7bL0juBKOKvygGalHZ1BfVK98whHRrFH8ks_F9zJTIz_CfzYkJAibVYFYf_kMq-wW_8Zvo6YtkCHnZ34-S-MHbQugiTUZEC2ZOcLz2SfxbKpMuTg2JxlPv5pcNMUTuZCeYbjjf6zczo2E7K-jvDhthv7U_Vl__XiRBrOV2cT1jTYuxvfLuJ3a5D_3IbRTuFw9hnyJw-IDCSH9oG_gjxw0UK7IbYrJZhFZlnjR4Ji_ejgaaQ1Kef0o4eaqUQccCkzVLkyH8uJivnkkERRH7E5bbdm347kaJG9q8u86fFuNcQQEWHUrGPi4tJkrmmRe4Kw9EpIhoklubfn9wrhkBzaZ3ZPIeBQZPn8KQP_Oh5zmtPL6sRTrJHLdDkJvKtvcAWVdJdKUYEVbxCOo3MOaa9K15Gv_K3zRK6tetrqzhXPM9icZRLT7iGNP19w_xuVUoU0_vnGa9nqj-0hrkfD3rj6Ig1md5YptLK5Yxt_YqVdlyd7t5cUFVv9bXCrrQWNvG65Qaclbnk4ntAE5Rj401dvAhdnHE79eJ524uNo3XO1m9mzaXFTU1Rp3x9AhKez5vKDV0Hj0QZEQp6kqqOJTSaobE7LPTUPxq3MfyfBtpKU1nz-S0r35Tij8a5Og1C_6ViOeijRv2K2-Bwky99ghNQU6xvXzpd84i7kJ_MEJU5El29tburp__c_u3fRF4m5Pczo8BLtu98dZGYP7GYX7NYYspM4qbE5qN0jyfzxCd8h_qTN1q0zW5a7-A2dMD71s-t9x42ZQkPKGDg-XIb0X-HBIYUQK_j9zqsqqWFlRlBh2FM8veASt3ee43Qd8gMSIOBmZTQWKBjSpuWzsmbMscxXSOeLG3FfIWCTXfIRQPpASRRhopKdErvC1CCDKndewTxZMzOd_YoG0EDt-Z_tO_kCkRzzBetvPAsWwmXPi3h7EvD5--so84A_mzP3P99LZ1_MH-9JjOnwnNJ0LN0II5pVJfecGPkIKYHD7axI6uqENMREqDKzJKjW4Yp7179yuRmzIvhTt8n_g==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoRxAwBG3W_wOP_IGltCVqeknV2aHvrEZB_twE8Q60-hmOS8CxeeI7UVo_y063FjHv3BweVFQAXRCwx4CDxrqwe0vm6il2isBQbO_tKte2bkfBgY_WZ37DACoOlByF1gpukntq_c_6elyg7bL0juBKOKvygGalHZ1BfVK98whHRrFH8ks_F9zJTIz_CfzYkJAibVYFYf_kMq-wW_8Zvo6YtkCHnZ34-S-MHbQugiTUZEC2ZOcLz2SfxbKpMuTg2JxlPv5pcNMUTuZCeYbjjf6zczo2E7K-jvDhthv7U_Vl__XiRBrOV2cT1jTYuxvfLuJ3a5D_3IbRTuFw9hnyJw-IDCSH9oG_gjxw0UK7IbYrJZhFZlnjR4Ji_ejgaaQ1Kef0o4eaqUQccCkzVLkyH8uJivnkkERRH7E5bbdm347kaJG9q8u86fFuNcQQEWHUrGPi4tJkrmmRe4Kw9EpIhoklubfn9wrhkBzaZ3ZPIeBQZPn8KQP_Oh5zmtPL6sRTrJHLdDkJvKtvcAWVdJdKUYEVbxCOo3MOaa9K15Gv_K3zRK6tetrqzhXPM9icZRLT7iGNP19w_xuVUoU0_vnGa9nqj-0hrkfD3rj6Ig1md5YptLK5Yxt_YqVdlyd7t5cUFVv9bXCrrQWNvG65Qaclbnk4ntAE5Rj401dvAhdnHE79eJ524uNo3XO1m9mzaXFTU1Rp3x9AhKez5vKDV0Hj0QZEQp6kqqOJTSaobE7LPTUPxq3MfyfBtpKU1nz-S0r35Tij8a5Og1C_6ViOeijRv2K2-Bwky99ghNQU6xvXzpd84i7kJ_MEJU5El29tburp__c_u3fRF4m5Pczo8BLtu98dZGYP7GYX7NYYspM4qbE5qN0jyfzxCd8h_qTN1q0zW5a7-A2dMD71s-t9x42ZQkPKGDg-XIb0X-HBIYUQK_j9zqsqqWFlRlBh2FM8veASt3ee43Qd8gMSIOBmZTQWKBjSpuWzsmbMscxXSOeLG3FfIWCTXfIRQPpASRRhopKdErvC1CCDKndewTxZMzOd_YoG0EDt-Z_tO_kCkRzzBetvPAsWwmXPi3h7EvD5--so84A_mzP3P99LZ1_MH-9JjOnwnNJ0LN0II5pVJfecGPkIKYHD7axI6uqENMREqDKzJKjW4Yp7179yuRmzIvhTt8n_g==",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1103.54,
            "totalPrice": 967
          },
          {
            "allPassengerTotalPrice": 967,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gx0EX26D7HFJYa7jAPKp1ub8WSrdoZ_ynX6l1AEv7XKQpMYXrdfRleI-qEkoFzdVXz8PTXByfpPWXSVfr_kCPBbbTXijAHbMn8IUHl_w5RqDXT91_dclNByIIrwaZJL88bycERrzyTTyEh1NTLQfa10f5JvsI1H-gvk9aLABKVlxe2KuJX3AupByfjPricu_zuz5jCIHofulWDv7PkkDUdyg6pTGPCJkcG0NgS9ZidM_ugH-kzkhwZesB9HIe0Al35B3ymQR0U89hLWD5abH5yhElstnfItdq_e47MRUAZs1BAFev4mlqeRd3TgsBbC-ocR71m1zwDExCHycYws9gx85Ch5ahiWbEZxlM2pFddDcBIr1LuINZDwbFcwsVmXsULtT-0zRJkT5bi3MmXg91uKcU3P7MsSih1SE7sjisdSU95K8K8mQzs6egtDVSTSXT7Y7b6PIFNVEjPUeJSAsc2Iqz7NzoZFilzP-f3cBig7a_YmTzSFaTn-XO6Vw6yFWbkJGseefDLEnxhxiIb7WIA8_3uFNJ2P5Pb9Ch7f2P3ctple1drHd47ykjvytzFD6DjbA0uM0sbpJkVgW1bEMqR6dojAv8AUaOZ0EeOYOZWAvoZ8fqwpiDD7NpUKOY1juz9PueXHAD1M0r1_xnwFRNOZIQG_zOaRkCiebZm7v0yVDz8WT2gyUHDHUjgD1Rqb-lBg3LHouCTGPj0sHlzb0MTEyvy2v2vfbWmyORPjZzZLAjXQ0d8aThfgbcyv4IFnn7HRQ1UtMczE61uMTTfnD4G6lYipXTln0jhdu8fnvzQ3srR2MpiRvh2EY2ZDytcVc3352bybJ08J3P1oCBKRjFm-h0dFuS4Km4R-7O43RdR9DJcN87XCoSsAfUyFxKnVv_pMqidKK6-Dr2PTAEChaSosuiNKkbC9eq348Ab_faSXfWEt1-EE0zdGoP8s27oXasjdGZS2yWZNozo_SQ4Icdfyq2hdDlGTmMakvVseaZHdaU3wt4Nqc1k1SWHqYMR5AeR7WiGVtQzxG3tCsSrfhUPyeOSGVZiAQnV7Inuai0DiJ3WYCrSi1RfujJhVJOuBEPrQcACo8IINHwr5XdVHZq36Tl708CuTKQXygbfdy_PJGRp1c9mc5iyhC00QPfrOdCvbvMN8Ao05UNgjM57BmCPw==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gx0EX26D7HFJYa7jAPKp1ub8WSrdoZ_ynX6l1AEv7XKQpMYXrdfRleI-qEkoFzdVXz8PTXByfpPWXSVfr_kCPBbbTXijAHbMn8IUHl_w5RqDXT91_dclNByIIrwaZJL88bycERrzyTTyEh1NTLQfa10f5JvsI1H-gvk9aLABKVlxe2KuJX3AupByfjPricu_zuz5jCIHofulWDv7PkkDUdyg6pTGPCJkcG0NgS9ZidM_ugH-kzkhwZesB9HIe0Al35B3ymQR0U89hLWD5abH5yhElstnfItdq_e47MRUAZs1BAFev4mlqeRd3TgsBbC-ocR71m1zwDExCHycYws9gx85Ch5ahiWbEZxlM2pFddDcBIr1LuINZDwbFcwsVmXsULtT-0zRJkT5bi3MmXg91uKcU3P7MsSih1SE7sjisdSU95K8K8mQzs6egtDVSTSXT7Y7b6PIFNVEjPUeJSAsc2Iqz7NzoZFilzP-f3cBig7a_YmTzSFaTn-XO6Vw6yFWbkJGseefDLEnxhxiIb7WIA8_3uFNJ2P5Pb9Ch7f2P3ctple1drHd47ykjvytzFD6DjbA0uM0sbpJkVgW1bEMqR6dojAv8AUaOZ0EeOYOZWAvoZ8fqwpiDD7NpUKOY1juz9PueXHAD1M0r1_xnwFRNOZIQG_zOaRkCiebZm7v0yVDz8WT2gyUHDHUjgD1Rqb-lBg3LHouCTGPj0sHlzb0MTEyvy2v2vfbWmyORPjZzZLAjXQ0d8aThfgbcyv4IFnn7HRQ1UtMczE61uMTTfnD4G6lYipXTln0jhdu8fnvzQ3srR2MpiRvh2EY2ZDytcVc3352bybJ08J3P1oCBKRjFm-h0dFuS4Km4R-7O43RdR9DJcN87XCoSsAfUyFxKnVv_pMqidKK6-Dr2PTAEChaSosuiNKkbC9eq348Ab_faSXfWEt1-EE0zdGoP8s27oXasjdGZS2yWZNozo_SQ4Icdfyq2hdDlGTmMakvVseaZHdaU3wt4Nqc1k1SWHqYMR5AeR7WiGVtQzxG3tCsSrfhUPyeOSGVZiAQnV7Inuai0DiJ3WYCrSi1RfujJhVJOuBEPrQcACo8IINHwr5XdVHZq36Tl708CuTKQXygbfdy_PJGRp1c9mc5iyhC00QPfrOdCvbvMN8Ao05UNgjM57BmCPw==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gx0EX26D7HFJYa7jAPKp1ub8WSrdoZ_ynX6l1AEv7XKQpMYXrdfRleI-qEkoFzdVXz8PTXByfpPWXSVfr_kCPBbbTXijAHbMn8IUHl_w5RqDXT91_dclNByIIrwaZJL88bycERrzyTTyEh1NTLQfa10f5JvsI1H-gvk9aLABKVlxe2KuJX3AupByfjPricu_zuz5jCIHofulWDv7PkkDUdyg6pTGPCJkcG0NgS9ZidM_ugH-kzkhwZesB9HIe0Al35B3ymQR0U89hLWD5abH5yhElstnfItdq_e47MRUAZs1BAFev4mlqeRd3TgsBbC-ocR71m1zwDExCHycYws9gx85Ch5ahiWbEZxlM2pFddDcBIr1LuINZDwbFcwsVmXsULtT-0zRJkT5bi3MmXg91uKcU3P7MsSih1SE7sjisdSU95K8K8mQzs6egtDVSTSXT7Y7b6PIFNVEjPUeJSAsc2Iqz7NzoZFilzP-f3cBig7a_YmTzSFaTn-XO6Vw6yFWbkJGseefDLEnxhxiIb7WIA8_3uFNJ2P5Pb9Ch7f2P3ctple1drHd47ykjvytzFD6DjbA0uM0sbpJkVgW1bEMqR6dojAv8AUaOZ0EeOYOZWAvoZ8fqwpiDD7NpUKOY1juz9PueXHAD1M0r1_xnwFRNOZIQG_zOaRkCiebZm7v0yVDz8WT2gyUHDHUjgD1Rqb-lBg3LHouCTGPj0sHlzb0MTEyvy2v2vfbWmyORPjZzZLAjXQ0d8aThfgbcyv4IFnn7HRQ1UtMczE61uMTTfnD4G6lYipXTln0jhdu8fnvzQ3srR2MpiRvh2EY2ZDytcVc3352bybJ08J3P1oCBKRjFm-h0dFuS4Km4R-7O43RdR9DJcN87XCoSsAfUyFxKnVv_pMqidKK6-Dr2PTAEChaSosuiNKkbC9eq348Ab_faSXfWEt1-EE0zdGoP8s27oXasjdGZS2yWZNozo_SQ4Icdfyq2hdDlGTmMakvVseaZHdaU3wt4Nqc1k1SWHqYMR5AeR7WiGVtQzxG3tCsSrfhUPyeOSGVZiAQnV7Inuai0DiJ3WYCrSi1RfujJhVJOuBEPrQcACo8IINHwr5XdVHZq36Tl708CuTKQXygbfdy_PJGRp1c9mc5iyhC00QPfrOdCvbvMN8Ao05UNgjM57BmCPw==",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1103.54,
            "totalPrice": 967
          },
          {
            "allPassengerTotalPrice": 967,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGFL4ghwuJu-xqB-JmJCnh-I9aqcoXrdKfD6EsWZBDiiNb9vHCkqZOqSRmRcGLH6l7xtY_V66mFA8Et2i9kLz02wrM-J5a6sdSp09a2bAsXGFvY8FMKcL5KyUtZlQ590zD4ITdPbTT7XDLnqmwiHvcgk916zkNDQlMITahyfZM9rDCdDqjUoiGil4NtALzBXANmP5_DD_AIIMp-Ev2ITY-L5vwHssnI8Mkwnjtfif0eLskn6OnOWjuuv3mtSAu43Z329PLoPXbi7fCv1VPPDThTIGVhamI5wkkeuR_A2P7sBpPle0DptMpzdrDEN786A6NdOkm5zqTdTVh7HKXMKtXkZLcdmD53UKq-T9y6S0aBNNG2qunATPfdzZshLI7FaI0qcfEvd0NFIz8IVhARIvWJkYY5i6RgFg3i0CEuT_j3Drsc748d6bjy6zQlfpbjCiHvh8C0bG_Il073ZKyqWa9pTNQRpcfY4zCeBwnSGmECa9Qed4Q-xpLGgNGLixF3EczI80OWQ3xn8AMecLHkcaL0nXEYj7Cjv3VLglWI8xQan3e8-kU4vSrk7NiJzHs1BFu6dVfyd_p7FgjOrf-TDm1tusDGa98iSYp98YWvilMjxBRaGH9ckhhqTUxHwMXaOgI-A9VUMqptwAeBko_kut_V-r34G4MDoP9IZzfTMBPO-UYa8-eobWw7AMiaCA4i1zDno2JJw6h5ZL_uLu2MXWfNbgGc60DauuUfL765TgKmfL3h8RzIm2T4UslMhQZlHnGK6j56HQJo8i8QTZpxGdETUVNOk7lab5gk44qbn8NRzrwwyMF280eeO_lI_rKEQKz_iywLwsjggR_jgFsMHvrNCufokNaYx-fZBhnSmhR5ZM860wbCIcqBbLeJELJguNliAhJI_8H_5Crc1GvP1HP9P8f5ZrxHrPMuN-7dbygOyfIMIfyrJC5ZFzAsxIUCdydlGdsfS8oyvbT9079BBLypq3ya-2eADS7QCUaoe-F7_Hj8wasiNAfoiPpwNw-7Ye1DQLQCZIjS3iHTGmE-a40hOxPxI8aHsEaBCGz5y-NmdRoa3M9U0hVVjQa0yDaZp-6a5KrA7n63d9NcTiFvCMDe0t2XM3aNFZ6Qa2_w46TvZrjsYNcodUW01IRWmEfn1Yui5Mn5StrY8ELi_FZM7YEQQNhURcMfWMkrXhrTV8Oy4=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGFL4ghwuJu-xqB-JmJCnh-I9aqcoXrdKfD6EsWZBDiiNb9vHCkqZOqSRmRcGLH6l7xtY_V66mFA8Et2i9kLz02wrM-J5a6sdSp09a2bAsXGFvY8FMKcL5KyUtZlQ590zD4ITdPbTT7XDLnqmwiHvcgk916zkNDQlMITahyfZM9rDCdDqjUoiGil4NtALzBXANmP5_DD_AIIMp-Ev2ITY-L5vwHssnI8Mkwnjtfif0eLskn6OnOWjuuv3mtSAu43Z329PLoPXbi7fCv1VPPDThTIGVhamI5wkkeuR_A2P7sBpPle0DptMpzdrDEN786A6NdOkm5zqTdTVh7HKXMKtXkZLcdmD53UKq-T9y6S0aBNNG2qunATPfdzZshLI7FaI0qcfEvd0NFIz8IVhARIvWJkYY5i6RgFg3i0CEuT_j3Drsc748d6bjy6zQlfpbjCiHvh8C0bG_Il073ZKyqWa9pTNQRpcfY4zCeBwnSGmECa9Qed4Q-xpLGgNGLixF3EczI80OWQ3xn8AMecLHkcaL0nXEYj7Cjv3VLglWI8xQan3e8-kU4vSrk7NiJzHs1BFu6dVfyd_p7FgjOrf-TDm1tusDGa98iSYp98YWvilMjxBRaGH9ckhhqTUxHwMXaOgI-A9VUMqptwAeBko_kut_V-r34G4MDoP9IZzfTMBPO-UYa8-eobWw7AMiaCA4i1zDno2JJw6h5ZL_uLu2MXWfNbgGc60DauuUfL765TgKmfL3h8RzIm2T4UslMhQZlHnGK6j56HQJo8i8QTZpxGdETUVNOk7lab5gk44qbn8NRzrwwyMF280eeO_lI_rKEQKz_iywLwsjggR_jgFsMHvrNCufokNaYx-fZBhnSmhR5ZM860wbCIcqBbLeJELJguNliAhJI_8H_5Crc1GvP1HP9P8f5ZrxHrPMuN-7dbygOyfIMIfyrJC5ZFzAsxIUCdydlGdsfS8oyvbT9079BBLypq3ya-2eADS7QCUaoe-F7_Hj8wasiNAfoiPpwNw-7Ye1DQLQCZIjS3iHTGmE-a40hOxPxI8aHsEaBCGz5y-NmdRoa3M9U0hVVjQa0yDaZp-6a5KrA7n63d9NcTiFvCMDe0t2XM3aNFZ6Qa2_w46TvZrjsYNcodUW01IRWmEfn1Yui5Mn5StrY8ELi_FZM7YEQQNhURcMfWMkrXhrTV8Oy4=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGFL4ghwuJu-xqB-JmJCnh-I9aqcoXrdKfD6EsWZBDiiNb9vHCkqZOqSRmRcGLH6l7xtY_V66mFA8Et2i9kLz02wrM-J5a6sdSp09a2bAsXGFvY8FMKcL5KyUtZlQ590zD4ITdPbTT7XDLnqmwiHvcgk916zkNDQlMITahyfZM9rDCdDqjUoiGil4NtALzBXANmP5_DD_AIIMp-Ev2ITY-L5vwHssnI8Mkwnjtfif0eLskn6OnOWjuuv3mtSAu43Z329PLoPXbi7fCv1VPPDThTIGVhamI5wkkeuR_A2P7sBpPle0DptMpzdrDEN786A6NdOkm5zqTdTVh7HKXMKtXkZLcdmD53UKq-T9y6S0aBNNG2qunATPfdzZshLI7FaI0qcfEvd0NFIz8IVhARIvWJkYY5i6RgFg3i0CEuT_j3Drsc748d6bjy6zQlfpbjCiHvh8C0bG_Il073ZKyqWa9pTNQRpcfY4zCeBwnSGmECa9Qed4Q-xpLGgNGLixF3EczI80OWQ3xn8AMecLHkcaL0nXEYj7Cjv3VLglWI8xQan3e8-kU4vSrk7NiJzHs1BFu6dVfyd_p7FgjOrf-TDm1tusDGa98iSYp98YWvilMjxBRaGH9ckhhqTUxHwMXaOgI-A9VUMqptwAeBko_kut_V-r34G4MDoP9IZzfTMBPO-UYa8-eobWw7AMiaCA4i1zDno2JJw6h5ZL_uLu2MXWfNbgGc60DauuUfL765TgKmfL3h8RzIm2T4UslMhQZlHnGK6j56HQJo8i8QTZpxGdETUVNOk7lab5gk44qbn8NRzrwwyMF280eeO_lI_rKEQKz_iywLwsjggR_jgFsMHvrNCufokNaYx-fZBhnSmhR5ZM860wbCIcqBbLeJELJguNliAhJI_8H_5Crc1GvP1HP9P8f5ZrxHrPMuN-7dbygOyfIMIfyrJC5ZFzAsxIUCdydlGdsfS8oyvbT9079BBLypq3ya-2eADS7QCUaoe-F7_Hj8wasiNAfoiPpwNw-7Ye1DQLQCZIjS3iHTGmE-a40hOxPxI8aHsEaBCGz5y-NmdRoa3M9U0hVVjQa0yDaZp-6a5KrA7n63d9NcTiFvCMDe0t2XM3aNFZ6Qa2_w46TvZrjsYNcodUW01IRWmEfn1Yui5Mn5StrY8ELi_FZM7YEQQNhURcMfWMkrXhrTV8Oy4=",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1104.21,
            "totalPrice": 967
          },
          {
            "allPassengerTotalPrice": 967,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GrR2g7BVeOx5rfk-qZvb-nlrVDQuMlzVn7H56K5Jf6zawK9Pnsx8F7zsygexb7zaAgyercfymVZP0NBg-0iWVKF6cw_NPNwDV0oRGGyMovanDgofu14BrNOqK3YKCkfcuXKo6IL4vA9cKUDlSNeWxwrYUbM7M_2601CLHLXnnam5cLgHdgj7jzF4Ddl3OzIGv_cMQ06S3Z7YQ5AuODHGnYJZIOwCStFubWhDZkU5SnPI_Q-FL0CBhKBN8CUYuzlhwhCvoKGnZ1CUajOfXw8plBRCYJ_7PfD0KYawxf637I-exskt55633T_8jzESmkxKxP0aA52oPr2EYfJu4Sr1SzLO_mPM0ues-DNXw3pTpgIhAhh5ROR3vOYh0RKsJ106IpQFA5U9Ze1rYlEdQCywpOf2MUgWzbdp-37l7AmO6T6nIdl156GZtlZuvwavXVRDDxP9eu5wh_Ngflr-kxRiGUkYMxSzBpKlM-OuEDIq7XnugZ9MIYmdL04zqtQ6nkvADfQT0AE2bOuwTPPwmHswclmts6-I69YvftytOsDJVkmi5CfmVCEIylXqVPHj-va43aMdUtyVnuw-T2EWtIWPHLIRbYzIt-Fdgjh_ljU09Jt9tDTKbG4r8MiBW7YjFQbhnro8me1mMNbThKCyXaj-AHt3HUMtryAkDDvAVMbMb2Gjcqh95dTKw_yT1e0hKAYSQAUsLWVDul5YuLFcGgQs4pZr2d00asNeh--ofZiAv0ZOuBt1NnxNhZTnP11-7Hht20aYVoW-YO3nEEEWavp7N0_mqVYPdSlEnycHbRQwGh2Pf4ENugrSqcDa1NQArhpyJowUuS4KNB0tL_xmikbqY-d9YwUsgGMxyFunOiYX6T-1IKrsShe4PRqfmp1SvAsnS0HaG299tFL2ntGsOZIsANjjZP3fRlQ85fgoCv2Jo9It_AmijUWqHpVcKEgtP1PQYV0cIwu7qaghu6FwNyLPtrGHaQImGK1EivVAjIDvqb84K1w_Zrp0xybFgDgj_-aqpvIvJeeWHaUIjEtRK2e1dgR25x2hCrvHl2enmR10-PCGiqjOrQEeXSrfdJ9oVUkXtNOuRQrrbAbJc_LpyMV7VHGhH76rzfykN6jN-r6pQuzdFfmdmvPLhqb3hqtcBHGV2XFF_FS_zwHQIkO14welGGg==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GrR2g7BVeOx5rfk-qZvb-nlrVDQuMlzVn7H56K5Jf6zawK9Pnsx8F7zsygexb7zaAgyercfymVZP0NBg-0iWVKF6cw_NPNwDV0oRGGyMovanDgofu14BrNOqK3YKCkfcuXKo6IL4vA9cKUDlSNeWxwrYUbM7M_2601CLHLXnnam5cLgHdgj7jzF4Ddl3OzIGv_cMQ06S3Z7YQ5AuODHGnYJZIOwCStFubWhDZkU5SnPI_Q-FL0CBhKBN8CUYuzlhwhCvoKGnZ1CUajOfXw8plBRCYJ_7PfD0KYawxf637I-exskt55633T_8jzESmkxKxP0aA52oPr2EYfJu4Sr1SzLO_mPM0ues-DNXw3pTpgIhAhh5ROR3vOYh0RKsJ106IpQFA5U9Ze1rYlEdQCywpOf2MUgWzbdp-37l7AmO6T6nIdl156GZtlZuvwavXVRDDxP9eu5wh_Ngflr-kxRiGUkYMxSzBpKlM-OuEDIq7XnugZ9MIYmdL04zqtQ6nkvADfQT0AE2bOuwTPPwmHswclmts6-I69YvftytOsDJVkmi5CfmVCEIylXqVPHj-va43aMdUtyVnuw-T2EWtIWPHLIRbYzIt-Fdgjh_ljU09Jt9tDTKbG4r8MiBW7YjFQbhnro8me1mMNbThKCyXaj-AHt3HUMtryAkDDvAVMbMb2Gjcqh95dTKw_yT1e0hKAYSQAUsLWVDul5YuLFcGgQs4pZr2d00asNeh--ofZiAv0ZOuBt1NnxNhZTnP11-7Hht20aYVoW-YO3nEEEWavp7N0_mqVYPdSlEnycHbRQwGh2Pf4ENugrSqcDa1NQArhpyJowUuS4KNB0tL_xmikbqY-d9YwUsgGMxyFunOiYX6T-1IKrsShe4PRqfmp1SvAsnS0HaG299tFL2ntGsOZIsANjjZP3fRlQ85fgoCv2Jo9It_AmijUWqHpVcKEgtP1PQYV0cIwu7qaghu6FwNyLPtrGHaQImGK1EivVAjIDvqb84K1w_Zrp0xybFgDgj_-aqpvIvJeeWHaUIjEtRK2e1dgR25x2hCrvHl2enmR10-PCGiqjOrQEeXSrfdJ9oVUkXtNOuRQrrbAbJc_LpyMV7VHGhH76rzfykN6jN-r6pQuzdFfmdmvPLhqb3hqtcBHGV2XFF_FS_zwHQIkO14welGGg==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GrR2g7BVeOx5rfk-qZvb-nlrVDQuMlzVn7H56K5Jf6zawK9Pnsx8F7zsygexb7zaAgyercfymVZP0NBg-0iWVKF6cw_NPNwDV0oRGGyMovanDgofu14BrNOqK3YKCkfcuXKo6IL4vA9cKUDlSNeWxwrYUbM7M_2601CLHLXnnam5cLgHdgj7jzF4Ddl3OzIGv_cMQ06S3Z7YQ5AuODHGnYJZIOwCStFubWhDZkU5SnPI_Q-FL0CBhKBN8CUYuzlhwhCvoKGnZ1CUajOfXw8plBRCYJ_7PfD0KYawxf637I-exskt55633T_8jzESmkxKxP0aA52oPr2EYfJu4Sr1SzLO_mPM0ues-DNXw3pTpgIhAhh5ROR3vOYh0RKsJ106IpQFA5U9Ze1rYlEdQCywpOf2MUgWzbdp-37l7AmO6T6nIdl156GZtlZuvwavXVRDDxP9eu5wh_Ngflr-kxRiGUkYMxSzBpKlM-OuEDIq7XnugZ9MIYmdL04zqtQ6nkvADfQT0AE2bOuwTPPwmHswclmts6-I69YvftytOsDJVkmi5CfmVCEIylXqVPHj-va43aMdUtyVnuw-T2EWtIWPHLIRbYzIt-Fdgjh_ljU09Jt9tDTKbG4r8MiBW7YjFQbhnro8me1mMNbThKCyXaj-AHt3HUMtryAkDDvAVMbMb2Gjcqh95dTKw_yT1e0hKAYSQAUsLWVDul5YuLFcGgQs4pZr2d00asNeh--ofZiAv0ZOuBt1NnxNhZTnP11-7Hht20aYVoW-YO3nEEEWavp7N0_mqVYPdSlEnycHbRQwGh2Pf4ENugrSqcDa1NQArhpyJowUuS4KNB0tL_xmikbqY-d9YwUsgGMxyFunOiYX6T-1IKrsShe4PRqfmp1SvAsnS0HaG299tFL2ntGsOZIsANjjZP3fRlQ85fgoCv2Jo9It_AmijUWqHpVcKEgtP1PQYV0cIwu7qaghu6FwNyLPtrGHaQImGK1EivVAjIDvqb84K1w_Zrp0xybFgDgj_-aqpvIvJeeWHaUIjEtRK2e1dgR25x2hCrvHl2enmR10-PCGiqjOrQEeXSrfdJ9oVUkXtNOuRQrrbAbJc_LpyMV7VHGhH76rzfykN6jN-r6pQuzdFfmdmvPLhqb3hqtcBHGV2XFF_FS_zwHQIkO14welGGg==",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1105.05,
            "totalPrice": 967
          },
          {
            "allPassengerTotalPrice": 969,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-f4m0j0gHz6EzmL67AB3FT8vGNakiRBSqOMAeZM5s9RzZPsyUeMWxSw1gZ7g1xuMjuXFvE7bKYTMwnepzclRzj3nfmR3TcfVWINSD0zA6mbTNJ-_wwQgQIdFFx23ODNWGcAXBTRvEJ4XUx3MSEqZmW4ALdM7XEunPsUACLMHewSJ_RZq7snxLAOX5q3wTRmaul5FmibQY5Kug6qOu9EwxDpdpiXDKiUpb2JIJUfmUytqx5BMgR6Q22TPExoHpFNdXmsw04dNeZNctLOBMnqVFC_F0-QuMIuYNUiPjZQa8q4ruD3bPDkhqhkg9c946Hmxhyg54pza3imii8OUjlG3-9EvrX6BNYdRzY9b_xk4anXdBFD8TrsH9wt9OTkZieGyE7_bzoHdRrnkn1KElytEtm71WFizvQ0PU9i2WHrb_ET8ZDsWKvkDkFJDbJ7U0d9tPpbyp4dbzi5v4L7JNYjqTkIskJt0jO7qfLaEtVbSkWI63UTSPrGImKf-zH0yM58psaawM12dQQF5_y6uEGGG8cxIf-BOfOX1CB_UzrpTTP-C2pHZ41Fuv3-Oip-cS2oBB6U4PpmmM7GS5xQCvC1YiSPLFukKQRRdbTRxsTdhnzjZ2TmuHJ6ZyaVVfOrBv41li-rY-f3aKOaGNKU7T0mNJPcRBFMQIFFNQM2YKgVXZ4PdqZ42xKlJ_78SDbNvk0EeHfJLQCfd5ngLDhQ45E9Xx0hj_a7AiU-zTmTtFx2hUp4Ao5RiIn3mlj4vM-D3TJGjz7dmiKUaBliYOKZOfQOYOtC8DdS-ZxgbtMT04G7XuKNEp4mSralF3WvU3kOrrp9Ip_nJXkZmmvSqeE14GDOJEvJ73B-urJrzAQ4DkUmi0rcMjuRlKyeN3Wno6pgiMACFC6bAnQtod--_rhNxTrfcS_OfvNSYff-hThDQ8CmU4LPx1XyJ-DBuuUNSvklO7gyXol8RpMhLSOdB-NGMbipV9bMugXFYIEbOuIDvvYnfI4Ns2guwMG6cWecfKSfQdPlhHu1n4Mcu_k_ygnItE4ynent6soYVnTymriCCGYtWpiCawVjWLKduP1yU_htjxmh2RtwrPurlDCETnuBH16R9C7X1Q9mEanMGF8XQ8zCCYIc=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-f4m0j0gHz6EzmL67AB3FT8vGNakiRBSqOMAeZM5s9RzZPsyUeMWxSw1gZ7g1xuMjuXFvE7bKYTMwnepzclRzj3nfmR3TcfVWINSD0zA6mbTNJ-_wwQgQIdFFx23ODNWGcAXBTRvEJ4XUx3MSEqZmW4ALdM7XEunPsUACLMHewSJ_RZq7snxLAOX5q3wTRmaul5FmibQY5Kug6qOu9EwxDpdpiXDKiUpb2JIJUfmUytqx5BMgR6Q22TPExoHpFNdXmsw04dNeZNctLOBMnqVFC_F0-QuMIuYNUiPjZQa8q4ruD3bPDkhqhkg9c946Hmxhyg54pza3imii8OUjlG3-9EvrX6BNYdRzY9b_xk4anXdBFD8TrsH9wt9OTkZieGyE7_bzoHdRrnkn1KElytEtm71WFizvQ0PU9i2WHrb_ET8ZDsWKvkDkFJDbJ7U0d9tPpbyp4dbzi5v4L7JNYjqTkIskJt0jO7qfLaEtVbSkWI63UTSPrGImKf-zH0yM58psaawM12dQQF5_y6uEGGG8cxIf-BOfOX1CB_UzrpTTP-C2pHZ41Fuv3-Oip-cS2oBB6U4PpmmM7GS5xQCvC1YiSPLFukKQRRdbTRxsTdhnzjZ2TmuHJ6ZyaVVfOrBv41li-rY-f3aKOaGNKU7T0mNJPcRBFMQIFFNQM2YKgVXZ4PdqZ42xKlJ_78SDbNvk0EeHfJLQCfd5ngLDhQ45E9Xx0hj_a7AiU-zTmTtFx2hUp4Ao5RiIn3mlj4vM-D3TJGjz7dmiKUaBliYOKZOfQOYOtC8DdS-ZxgbtMT04G7XuKNEp4mSralF3WvU3kOrrp9Ip_nJXkZmmvSqeE14GDOJEvJ73B-urJrzAQ4DkUmi0rcMjuRlKyeN3Wno6pgiMACFC6bAnQtod--_rhNxTrfcS_OfvNSYff-hThDQ8CmU4LPx1XyJ-DBuuUNSvklO7gyXol8RpMhLSOdB-NGMbipV9bMugXFYIEbOuIDvvYnfI4Ns2guwMG6cWecfKSfQdPlhHu1n4Mcu_k_ygnItE4ynent6soYVnTymriCCGYtWpiCawVjWLKduP1yU_htjxmh2RtwrPurlDCETnuBH16R9C7X1Q9mEanMGF8XQ8zCCYIc=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-f4m0j0gHz6EzmL67AB3FT8vGNakiRBSqOMAeZM5s9RzZPsyUeMWxSw1gZ7g1xuMjuXFvE7bKYTMwnepzclRzj3nfmR3TcfVWINSD0zA6mbTNJ-_wwQgQIdFFx23ODNWGcAXBTRvEJ4XUx3MSEqZmW4ALdM7XEunPsUACLMHewSJ_RZq7snxLAOX5q3wTRmaul5FmibQY5Kug6qOu9EwxDpdpiXDKiUpb2JIJUfmUytqx5BMgR6Q22TPExoHpFNdXmsw04dNeZNctLOBMnqVFC_F0-QuMIuYNUiPjZQa8q4ruD3bPDkhqhkg9c946Hmxhyg54pza3imii8OUjlG3-9EvrX6BNYdRzY9b_xk4anXdBFD8TrsH9wt9OTkZieGyE7_bzoHdRrnkn1KElytEtm71WFizvQ0PU9i2WHrb_ET8ZDsWKvkDkFJDbJ7U0d9tPpbyp4dbzi5v4L7JNYjqTkIskJt0jO7qfLaEtVbSkWI63UTSPrGImKf-zH0yM58psaawM12dQQF5_y6uEGGG8cxIf-BOfOX1CB_UzrpTTP-C2pHZ41Fuv3-Oip-cS2oBB6U4PpmmM7GS5xQCvC1YiSPLFukKQRRdbTRxsTdhnzjZ2TmuHJ6ZyaVVfOrBv41li-rY-f3aKOaGNKU7T0mNJPcRBFMQIFFNQM2YKgVXZ4PdqZ42xKlJ_78SDbNvk0EeHfJLQCfd5ngLDhQ45E9Xx0hj_a7AiU-zTmTtFx2hUp4Ao5RiIn3mlj4vM-D3TJGjz7dmiKUaBliYOKZOfQOYOtC8DdS-ZxgbtMT04G7XuKNEp4mSralF3WvU3kOrrp9Ip_nJXkZmmvSqeE14GDOJEvJ73B-urJrzAQ4DkUmi0rcMjuRlKyeN3Wno6pgiMACFC6bAnQtod--_rhNxTrfcS_OfvNSYff-hThDQ8CmU4LPx1XyJ-DBuuUNSvklO7gyXol8RpMhLSOdB-NGMbipV9bMugXFYIEbOuIDvvYnfI4Ns2guwMG6cWecfKSfQdPlhHu1n4Mcu_k_ygnItE4ynent6soYVnTymriCCGYtWpiCawVjWLKduP1yU_htjxmh2RtwrPurlDCETnuBH16R9C7X1Q9mEanMGF8XQ8zCCYIc=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1098.86,
            "totalPrice": 969
          },
          {
            "allPassengerTotalPrice": 974,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtjluvA-jU1elf_qAAnhN8NXBhQ68ur6lcr7VY076VprkD9WxCaK4SgcnS-ymJZfAULMaAhyTfcFzHNfeGN0ML5myIObgo3VO-8b1y7q_Xbv6kNUT3h1NiwugaWVnJFb8tawPuHjxaRsxKJbB_G2sJnjm12QlL5AsboNk1z2xqMag4ostCD4uCrd_13NOipNTcczVinFtFetQGyyXHdZvT_163iBVeUsWzzn5lbT03rlWiFPaHMtaDNfkTDLysWEdG6Wq_Te23jbLgDo5Gqjb6ZLFWHrbcMxvC-RJSAzQn2cvbQqsfzyfFzFaHpm6ReFIhqCtpf38fngcJeeF74fMqgOcRv4AbycvuY5598RgMbWdKovrA0caKmiboFlQSIwm2jKIBbjJ1NZMOB37BI94FTWLLHQ7kyxcw0emuE6pf_V88-9nKccpGbyyvqsPjYwC_rbPyrGjqNCovFdbWYf5qCEeXTrUD3CYfQkXZzrpNbLggBPCu8FM42A_nlbBopRdLQAEP6s8J4BKEhLAdLXkmPZ6IA9p6pwBYsIX13ezjoHiOMnYvUw-EaGUyqBbONZGCOCLRI0-mIcc_pTQqawVow1iIjD5gSOtxNyYi_SNbWOrVppkWP7-wVp9_jbMhFLppl7YYTQzOFP0v4gq6jweqU3BKF83gE-CrcQsasJndyOLgD7it8SWw8VbdTSCJ5CinJLykdzqgluAnTy0XLou-u2wguVX0hfyBS6Tn_2dyiv_XRZBOud9OOaobldPWXnR9bXOVSnINaq-WTFh7UaizLysAVz_of0TgXb_1i4HszVIukOOhSzHieYlv5a4vXmTGwBqBHUM4S3XWMUxeup7cfJMl1m9oBeqLMVQk2-nFbbNJMhk5Q5fF90lF_jOo_-RhM_HgnVLYCkaxt91rjmIhrdPoOLgwMrFpMoiR4BrK9BC32-vDu7fuj-P0Gze--8pOGIMphjY1GKXSKdPQ_IQ0WNjztqykoYxb3xGo24nknfJwU0CoI6Q_9lxDGrW8fMqM7mMLEFB8lpaOwMu1qEMx1Re8HRc5IKN6mZ6QCoC40-x52QpD7DXG9lG8Zbp_gfU7k6DpIkxWK7EYPHSmfMSG3c9Wo65ofM2Tzt4PcdzwdptcYM4eoF78O4HsqHmUHWujP5sVwe1fWvX8l2ceIG123Zv1DIaNjDR0M8gBHGuoT4=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtjluvA-jU1elf_qAAnhN8NXBhQ68ur6lcr7VY076VprkD9WxCaK4SgcnS-ymJZfAULMaAhyTfcFzHNfeGN0ML5myIObgo3VO-8b1y7q_Xbv6kNUT3h1NiwugaWVnJFb8tawPuHjxaRsxKJbB_G2sJnjm12QlL5AsboNk1z2xqMag4ostCD4uCrd_13NOipNTcczVinFtFetQGyyXHdZvT_163iBVeUsWzzn5lbT03rlWiFPaHMtaDNfkTDLysWEdG6Wq_Te23jbLgDo5Gqjb6ZLFWHrbcMxvC-RJSAzQn2cvbQqsfzyfFzFaHpm6ReFIhqCtpf38fngcJeeF74fMqgOcRv4AbycvuY5598RgMbWdKovrA0caKmiboFlQSIwm2jKIBbjJ1NZMOB37BI94FTWLLHQ7kyxcw0emuE6pf_V88-9nKccpGbyyvqsPjYwC_rbPyrGjqNCovFdbWYf5qCEeXTrUD3CYfQkXZzrpNbLggBPCu8FM42A_nlbBopRdLQAEP6s8J4BKEhLAdLXkmPZ6IA9p6pwBYsIX13ezjoHiOMnYvUw-EaGUyqBbONZGCOCLRI0-mIcc_pTQqawVow1iIjD5gSOtxNyYi_SNbWOrVppkWP7-wVp9_jbMhFLppl7YYTQzOFP0v4gq6jweqU3BKF83gE-CrcQsasJndyOLgD7it8SWw8VbdTSCJ5CinJLykdzqgluAnTy0XLou-u2wguVX0hfyBS6Tn_2dyiv_XRZBOud9OOaobldPWXnR9bXOVSnINaq-WTFh7UaizLysAVz_of0TgXb_1i4HszVIukOOhSzHieYlv5a4vXmTGwBqBHUM4S3XWMUxeup7cfJMl1m9oBeqLMVQk2-nFbbNJMhk5Q5fF90lF_jOo_-RhM_HgnVLYCkaxt91rjmIhrdPoOLgwMrFpMoiR4BrK9BC32-vDu7fuj-P0Gze--8pOGIMphjY1GKXSKdPQ_IQ0WNjztqykoYxb3xGo24nknfJwU0CoI6Q_9lxDGrW8fMqM7mMLEFB8lpaOwMu1qEMx1Re8HRc5IKN6mZ6QCoC40-x52QpD7DXG9lG8Zbp_gfU7k6DpIkxWK7EYPHSmfMSG3c9Wo65ofM2Tzt4PcdzwdptcYM4eoF78O4HsqHmUHWujP5sVwe1fWvX8l2ceIG123Zv1DIaNjDR0M8gBHGuoT4=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtjluvA-jU1elf_qAAnhN8NXBhQ68ur6lcr7VY076VprkD9WxCaK4SgcnS-ymJZfAULMaAhyTfcFzHNfeGN0ML5myIObgo3VO-8b1y7q_Xbv6kNUT3h1NiwugaWVnJFb8tawPuHjxaRsxKJbB_G2sJnjm12QlL5AsboNk1z2xqMag4ostCD4uCrd_13NOipNTcczVinFtFetQGyyXHdZvT_163iBVeUsWzzn5lbT03rlWiFPaHMtaDNfkTDLysWEdG6Wq_Te23jbLgDo5Gqjb6ZLFWHrbcMxvC-RJSAzQn2cvbQqsfzyfFzFaHpm6ReFIhqCtpf38fngcJeeF74fMqgOcRv4AbycvuY5598RgMbWdKovrA0caKmiboFlQSIwm2jKIBbjJ1NZMOB37BI94FTWLLHQ7kyxcw0emuE6pf_V88-9nKccpGbyyvqsPjYwC_rbPyrGjqNCovFdbWYf5qCEeXTrUD3CYfQkXZzrpNbLggBPCu8FM42A_nlbBopRdLQAEP6s8J4BKEhLAdLXkmPZ6IA9p6pwBYsIX13ezjoHiOMnYvUw-EaGUyqBbONZGCOCLRI0-mIcc_pTQqawVow1iIjD5gSOtxNyYi_SNbWOrVppkWP7-wVp9_jbMhFLppl7YYTQzOFP0v4gq6jweqU3BKF83gE-CrcQsasJndyOLgD7it8SWw8VbdTSCJ5CinJLykdzqgluAnTy0XLou-u2wguVX0hfyBS6Tn_2dyiv_XRZBOud9OOaobldPWXnR9bXOVSnINaq-WTFh7UaizLysAVz_of0TgXb_1i4HszVIukOOhSzHieYlv5a4vXmTGwBqBHUM4S3XWMUxeup7cfJMl1m9oBeqLMVQk2-nFbbNJMhk5Q5fF90lF_jOo_-RhM_HgnVLYCkaxt91rjmIhrdPoOLgwMrFpMoiR4BrK9BC32-vDu7fuj-P0Gze--8pOGIMphjY1GKXSKdPQ_IQ0WNjztqykoYxb3xGo24nknfJwU0CoI6Q_9lxDGrW8fMqM7mMLEFB8lpaOwMu1qEMx1Re8HRc5IKN6mZ6QCoC40-x52QpD7DXG9lG8Zbp_gfU7k6DpIkxWK7EYPHSmfMSG3c9Wo65ofM2Tzt4PcdzwdptcYM4eoF78O4HsqHmUHWujP5sVwe1fWvX8l2ceIG123Zv1DIaNjDR0M8gBHGuoT4=",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1109.99,
            "totalPrice": 974
          },
          {
            "allPassengerTotalPrice": 976,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs6Cphpltw1IzTsm6BCmWnJG2bYnNEWH2Te3tOVMjfGG97JjB98P5wZSpqk6ySvhJkfWJONnK6HuSn68o9_jUkIgxI67Bsj3Hg5o8fLMJl6_bEupKgj07XmcoqtB0BXCKyGYt1GMbY5C7xVB7y7Mm6VR2Qp74oYQQWBYrfzmHwKsxi2qENa33w7L2c265HhLy23SzXYN-tHthuLT_o4JAQtk4L9nbde4zIcBUs7CIl3BnJnPt8K5CQwzY7ZkXGF733GPYWPmmipsrjXjpeSwDyAXhR9VPKyuFg2roUFcGM-zLRGiUfHuPjA_FdoWnq1i2AeiHPly2pyJsT4NlO_qiW4Wp_-xuu-d1kyUwnVFddfj2wDv3Q0jt2N1uoUIy79cpXQZZUn4HHGUTtXP4esefEQw7qTC2kudr3g9Tbi-EOzh4Ume3L9v4YuQKnrjvA-SvT4XNeg0QSfvW1YXQ-kwgMxql_Ig39v08dEjO8FnanwvHc7Smr_senRwcXjtwQ-sVSNYBbbfDaZKoeqmnSUOMYwDDXC9TAXCsiMpmK4xImY6SfDyg0RNaLyharTk_wJKSFR_Q0rB37Qyw9XcwDvimmUxLuDVOxcCD_B0aIi_HktCEdOZBvw-O6lx8wjsV2JUM9wG_T9c4ooqyFqDoA0KI_iCyfNJrNtGQqYJDl64nSGaCuCH9KN9BVCRvFpsFTIj0X6mv1kT3BrVhkJOv7riAsTh2rSgw0R8IQNWLM-K8dyU3THNle7VA6gGk9mPwVt5wJ_8JTcxUWrM619JBZhpnYPJtX9UiZePdIoyMCyASWoCCEeE2s8ni5DVInBdFgSVkw3tMLKb4iRLk49lXM2GwAGpI794loxnwGBfdhS0_FKBO_ainV11wvLwe2S8xi5x-Mab9oSMqu-krzldpKXrMIvQ43Aod2Os3jzo2-rl_kiVJn5HY9Or0mfmWXbOYI3DJtgdMV2DeDWqKzfXgbZrQu5FW2-ImAWMLiST9eVHDvgmwGwMlBrKA2Unn53cAAB1cWMmPFE5yduQ8vx525hbvx55K2kSV1l7YyvkXaOoz0lNh07Kul8eMY8H2yTGtYoG0aUG8B8ZWoD4d17mXUqLo0gTYPj1ct_1Agukl07CXuJJ6JO8UFfQRxxhllQ26QgqpKNkKZPGN8SpsEu_cpafh_Q==",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs6Cphpltw1IzTsm6BCmWnJG2bYnNEWH2Te3tOVMjfGG97JjB98P5wZSpqk6ySvhJkfWJONnK6HuSn68o9_jUkIgxI67Bsj3Hg5o8fLMJl6_bEupKgj07XmcoqtB0BXCKyGYt1GMbY5C7xVB7y7Mm6VR2Qp74oYQQWBYrfzmHwKsxi2qENa33w7L2c265HhLy23SzXYN-tHthuLT_o4JAQtk4L9nbde4zIcBUs7CIl3BnJnPt8K5CQwzY7ZkXGF733GPYWPmmipsrjXjpeSwDyAXhR9VPKyuFg2roUFcGM-zLRGiUfHuPjA_FdoWnq1i2AeiHPly2pyJsT4NlO_qiW4Wp_-xuu-d1kyUwnVFddfj2wDv3Q0jt2N1uoUIy79cpXQZZUn4HHGUTtXP4esefEQw7qTC2kudr3g9Tbi-EOzh4Ume3L9v4YuQKnrjvA-SvT4XNeg0QSfvW1YXQ-kwgMxql_Ig39v08dEjO8FnanwvHc7Smr_senRwcXjtwQ-sVSNYBbbfDaZKoeqmnSUOMYwDDXC9TAXCsiMpmK4xImY6SfDyg0RNaLyharTk_wJKSFR_Q0rB37Qyw9XcwDvimmUxLuDVOxcCD_B0aIi_HktCEdOZBvw-O6lx8wjsV2JUM9wG_T9c4ooqyFqDoA0KI_iCyfNJrNtGQqYJDl64nSGaCuCH9KN9BVCRvFpsFTIj0X6mv1kT3BrVhkJOv7riAsTh2rSgw0R8IQNWLM-K8dyU3THNle7VA6gGk9mPwVt5wJ_8JTcxUWrM619JBZhpnYPJtX9UiZePdIoyMCyASWoCCEeE2s8ni5DVInBdFgSVkw3tMLKb4iRLk49lXM2GwAGpI794loxnwGBfdhS0_FKBO_ainV11wvLwe2S8xi5x-Mab9oSMqu-krzldpKXrMIvQ43Aod2Os3jzo2-rl_kiVJn5HY9Or0mfmWXbOYI3DJtgdMV2DeDWqKzfXgbZrQu5FW2-ImAWMLiST9eVHDvgmwGwMlBrKA2Unn53cAAB1cWMmPFE5yduQ8vx525hbvx55K2kSV1l7YyvkXaOoz0lNh07Kul8eMY8H2yTGtYoG0aUG8B8ZWoD4d17mXUqLo0gTYPj1ct_1Agukl07CXuJJ6JO8UFfQRxxhllQ26QgqpKNkKZPGN8SpsEu_cpafh_Q==",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs6Cphpltw1IzTsm6BCmWnJG2bYnNEWH2Te3tOVMjfGG97JjB98P5wZSpqk6ySvhJkfWJONnK6HuSn68o9_jUkIgxI67Bsj3Hg5o8fLMJl6_bEupKgj07XmcoqtB0BXCKyGYt1GMbY5C7xVB7y7Mm6VR2Qp74oYQQWBYrfzmHwKsxi2qENa33w7L2c265HhLy23SzXYN-tHthuLT_o4JAQtk4L9nbde4zIcBUs7CIl3BnJnPt8K5CQwzY7ZkXGF733GPYWPmmipsrjXjpeSwDyAXhR9VPKyuFg2roUFcGM-zLRGiUfHuPjA_FdoWnq1i2AeiHPly2pyJsT4NlO_qiW4Wp_-xuu-d1kyUwnVFddfj2wDv3Q0jt2N1uoUIy79cpXQZZUn4HHGUTtXP4esefEQw7qTC2kudr3g9Tbi-EOzh4Ume3L9v4YuQKnrjvA-SvT4XNeg0QSfvW1YXQ-kwgMxql_Ig39v08dEjO8FnanwvHc7Smr_senRwcXjtwQ-sVSNYBbbfDaZKoeqmnSUOMYwDDXC9TAXCsiMpmK4xImY6SfDyg0RNaLyharTk_wJKSFR_Q0rB37Qyw9XcwDvimmUxLuDVOxcCD_B0aIi_HktCEdOZBvw-O6lx8wjsV2JUM9wG_T9c4ooqyFqDoA0KI_iCyfNJrNtGQqYJDl64nSGaCuCH9KN9BVCRvFpsFTIj0X6mv1kT3BrVhkJOv7riAsTh2rSgw0R8IQNWLM-K8dyU3THNle7VA6gGk9mPwVt5wJ_8JTcxUWrM619JBZhpnYPJtX9UiZePdIoyMCyASWoCCEeE2s8ni5DVInBdFgSVkw3tMLKb4iRLk49lXM2GwAGpI794loxnwGBfdhS0_FKBO_ainV11wvLwe2S8xi5x-Mab9oSMqu-krzldpKXrMIvQ43Aod2Os3jzo2-rl_kiVJn5HY9Or0mfmWXbOYI3DJtgdMV2DeDWqKzfXgbZrQu5FW2-ImAWMLiST9eVHDvgmwGwMlBrKA2Unn53cAAB1cWMmPFE5yduQ8vx525hbvx55K2kSV1l7YyvkXaOoz0lNh07Kul8eMY8H2yTGtYoG0aUG8B8ZWoD4d17mXUqLo0gTYPj1ct_1Agukl07CXuJJ6JO8UFfQRxxhllQ26QgqpKNkKZPGN8SpsEu_cpafh_Q==",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1111.4,
            "totalPrice": 976
          },
          {
            "allPassengerTotalPrice": 976,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNZIIwf0u8D98uLl_7RRvTexFZcwPaMsIrl-1qyQxNYA7gcphwyQCdkArNVEiUGwPq0ZdUmKfygNMWj0OL_Sjc0VTpgjTukn7QM_eoivVU3u8tXrx2hEXuRN0AaQRw6ePikgQFGLu6-1y3igduMI3iLzaOYNbR9ZpEvPvGAI3eN_GgjeF8ulvaWmuaGoJWZvvFH4iWbfmoQHj9WfVF7gPkg1UvoI5L-KYYY6aW3qOj7LSbTbdriGHLgowF12D6xdTaLk9lIz6QXee3j4Br1g_HBIwTx7gEFV3wJ3a6mRoZXRBztBm_xI880J9WG6ZR0J47A2EKIchbbTxfSEgUJWIwbVJ-D0CeM5Qnvrdq2JJu8GmCNZ5z5FtrkqlSVMuBcGwZYr7KU9wir3AiJMQ9Lpa7sx6AyAEaf7HYJqATNfXz8Rq1ZBA47YR_dqBFbbUCvbaV2LMr_0nNWkesbQth-YlV1aVwxbyKkRfk9jIa4Thuo8z14HnwKEI2eEWxaJBOj9sKzAgfzESsCsEhWpxfIboKj5uj71y-06Vm9WetN0ZQrzPo210raTSxID92mr-V4_AH0daJ2u9RjJ-EEDfqA6NatMdTxvSj14s7TwnRDc5B4w4ccueoJ7DIMnKArgOT-v6bsFrr5jrmnttW4xbU8KBM5BY3tDIbZ1bRxFHksys9B4lvih-2_t703NFbt9wrAdt3FCe4EHErw0vbT8Nn5EnmAiKnxXnhneyE5qZN618hk289K6h0Z5e_JrOXVWFr1V84CMESvPfdvADMS_tBvFSkXRq2oxK1WCykQWA9J1xfEjnb9OGBGi1uYkO7B3Zwx9znMxRkMmY25AG8OuX8RKy2bbeD1IBlFmKeOdOnxP7G-ygg5RXTrDShIqyx6u8O5wGMd944Srj9WZLp0zzSceIUzRS64OkL24cQ1CexKM3nY43nBr5mekn2dmHIcxzqvfufM-ovu3Cl8Y8rBTUmSdMIbRvgwuolRshYFiVp1orXQKPJqm6UckY5RIcydijJXWgrafU6hpzBlMNfCqbaOLJP-o-a29YBl1hfowsQuIiZVZA-LamqPwxE5mp6lN7uD1mIpYcmQvoklWJxrEMRdWxsSKhLMk73ywiaZfp1BXGWMdfg-u9qB7UqmU8zSANvKUI",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNZIIwf0u8D98uLl_7RRvTexFZcwPaMsIrl-1qyQxNYA7gcphwyQCdkArNVEiUGwPq0ZdUmKfygNMWj0OL_Sjc0VTpgjTukn7QM_eoivVU3u8tXrx2hEXuRN0AaQRw6ePikgQFGLu6-1y3igduMI3iLzaOYNbR9ZpEvPvGAI3eN_GgjeF8ulvaWmuaGoJWZvvFH4iWbfmoQHj9WfVF7gPkg1UvoI5L-KYYY6aW3qOj7LSbTbdriGHLgowF12D6xdTaLk9lIz6QXee3j4Br1g_HBIwTx7gEFV3wJ3a6mRoZXRBztBm_xI880J9WG6ZR0J47A2EKIchbbTxfSEgUJWIwbVJ-D0CeM5Qnvrdq2JJu8GmCNZ5z5FtrkqlSVMuBcGwZYr7KU9wir3AiJMQ9Lpa7sx6AyAEaf7HYJqATNfXz8Rq1ZBA47YR_dqBFbbUCvbaV2LMr_0nNWkesbQth-YlV1aVwxbyKkRfk9jIa4Thuo8z14HnwKEI2eEWxaJBOj9sKzAgfzESsCsEhWpxfIboKj5uj71y-06Vm9WetN0ZQrzPo210raTSxID92mr-V4_AH0daJ2u9RjJ-EEDfqA6NatMdTxvSj14s7TwnRDc5B4w4ccueoJ7DIMnKArgOT-v6bsFrr5jrmnttW4xbU8KBM5BY3tDIbZ1bRxFHksys9B4lvih-2_t703NFbt9wrAdt3FCe4EHErw0vbT8Nn5EnmAiKnxXnhneyE5qZN618hk289K6h0Z5e_JrOXVWFr1V84CMESvPfdvADMS_tBvFSkXRq2oxK1WCykQWA9J1xfEjnb9OGBGi1uYkO7B3Zwx9znMxRkMmY25AG8OuX8RKy2bbeD1IBlFmKeOdOnxP7G-ygg5RXTrDShIqyx6u8O5wGMd944Srj9WZLp0zzSceIUzRS64OkL24cQ1CexKM3nY43nBr5mekn2dmHIcxzqvfufM-ovu3Cl8Y8rBTUmSdMIbRvgwuolRshYFiVp1orXQKPJqm6UckY5RIcydijJXWgrafU6hpzBlMNfCqbaOLJP-o-a29YBl1hfowsQuIiZVZA-LamqPwxE5mp6lN7uD1mIpYcmQvoklWJxrEMRdWxsSKhLMk73ywiaZfp1BXGWMdfg-u9qB7UqmU8zSANvKUI",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "IB",
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "16:30",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e2faae550dc_0%7C01af0f9c4e244e2faae550dc_1%7C01af0f9c4e244e2faae550dc_2&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNZIIwf0u8D98uLl_7RRvTexFZcwPaMsIrl-1qyQxNYA7gcphwyQCdkArNVEiUGwPq0ZdUmKfygNMWj0OL_Sjc0VTpgjTukn7QM_eoivVU3u8tXrx2hEXuRN0AaQRw6ePikgQFGLu6-1y3igduMI3iLzaOYNbR9ZpEvPvGAI3eN_GgjeF8ulvaWmuaGoJWZvvFH4iWbfmoQHj9WfVF7gPkg1UvoI5L-KYYY6aW3qOj7LSbTbdriGHLgowF12D6xdTaLk9lIz6QXee3j4Br1g_HBIwTx7gEFV3wJ3a6mRoZXRBztBm_xI880J9WG6ZR0J47A2EKIchbbTxfSEgUJWIwbVJ-D0CeM5Qnvrdq2JJu8GmCNZ5z5FtrkqlSVMuBcGwZYr7KU9wir3AiJMQ9Lpa7sx6AyAEaf7HYJqATNfXz8Rq1ZBA47YR_dqBFbbUCvbaV2LMr_0nNWkesbQth-YlV1aVwxbyKkRfk9jIa4Thuo8z14HnwKEI2eEWxaJBOj9sKzAgfzESsCsEhWpxfIboKj5uj71y-06Vm9WetN0ZQrzPo210raTSxID92mr-V4_AH0daJ2u9RjJ-EEDfqA6NatMdTxvSj14s7TwnRDc5B4w4ccueoJ7DIMnKArgOT-v6bsFrr5jrmnttW4xbU8KBM5BY3tDIbZ1bRxFHksys9B4lvih-2_t703NFbt9wrAdt3FCe4EHErw0vbT8Nn5EnmAiKnxXnhneyE5qZN618hk289K6h0Z5e_JrOXVWFr1V84CMESvPfdvADMS_tBvFSkXRq2oxK1WCykQWA9J1xfEjnb9OGBGi1uYkO7B3Zwx9znMxRkMmY25AG8OuX8RKy2bbeD1IBlFmKeOdOnxP7G-ygg5RXTrDShIqyx6u8O5wGMd944Srj9WZLp0zzSceIUzRS64OkL24cQ1CexKM3nY43nBr5mekn2dmHIcxzqvfufM-ovu3Cl8Y8rBTUmSdMIbRvgwuolRshYFiVp1orXQKPJqm6UckY5RIcydijJXWgrafU6hpzBlMNfCqbaOLJP-o-a29YBl1hfowsQuIiZVZA-LamqPwxE5mp6lN7uD1mIpYcmQvoklWJxrEMRdWxsSKhLMk73ywiaZfp1BXGWMdfg-u9qB7UqmU8zSANvKUI",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "26h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 3013,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0f9c4e244e2faae550dc_0",
                    "local_arrival": "2024-10-08T11:10:00.000Z",
                    "local_departure": "2024-10-08T09:45:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3013",
                    "return": 0,
                    "utc_arrival": "2024-10-08T09:10:00.000Z",
                    "utc_departure": "2024-10-08T07:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "IB",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "MAD",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Madrid",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e2faae550dc",
                    "equipment": null,
                    "fare_basis": "OKX7T1BW",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 6193,
                    "flyFrom": "MAD",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e2faae550dc_1",
                    "local_arrival": "2024-10-08T16:30:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "6193",
                    "return": 0,
                    "utc_arrival": "2024-10-08T23:30:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1123.73,
            "totalPrice": 976
          },
          {
            "allPassengerTotalPrice": 983,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKLL0LeiehhL_xuVsFb6uxsJ8hD2l1WZLpXC0xurqQ6pDuhC2X8lKKW9KXheR7A74cHBBnJBdbXU_SjoqMPr087--g4r-_7AVIXLx_3ZJnw909IDbjjUL7Pjo19IlsUc6Z5ZlcES_gcUcU3YnTAL69RyZpSevg65ofmLJvUH8WH2A9u1YoaUlUVsncl8KyubpdnPwwvqjqBj7lPtaOqwToNpRH6AAl0i-VWRpQ93taKNbit7T1IwEoR0_zsOGdv7vT3MktXsce3sxwWwbqPBe_jTEeViKVQy-o_3-uQqgQEvd7Im71GLq5GMBfZ8vrJpezPrtW1aAet4M1tGvV8OfcWdIU1KK0bqolaF_dp0oqA5k_mSAA4f0YsDzXvxNQVtQ1rkLFIsomlFaBDEFMJSZBF6Gb9h-_BAP9nP-aN1Try8W29y2ZoICnbUu8nOcNZ_wKKOd3Op3Mvg_e3ZXUNQ-tnsnJt2xvjtlgpvqnaQN8kSaI7xlo-xt_B-7yqlFwzFCqGPS07ci37emgiGV2458OZKof37thjAP32_29yaeuSCMSaVPkmphugfW2TLAQPgv_rd2mCUIWMtCvxVfti6t77IMjMRjWE5TQwBRYmo-agTgrH2mqYJwKchpMaY-OyCEkjZwwnndxCDcJ2Skku-zfIusurOjM3AXgsegCS4J52nmFyA02EFaG4feBq3Ci7tt09N-tphqBKK5AeLDKQj7f6en4RlJKnxQShXGeoSFLD2mD05WK-vKFAmWf0f7WKcFF8SYeHTamC8lm_8rgrjOK8AHhkicOJvuBMkvT80z2zfBZFW1hVDRb-P6hdcxpYIgQD7wft3bfLUvgZdJBVRII04Z9eS5k8Dyh8pNwNJVJ2voXoHqX2Z51CVvF-JY6s1LNRWkq2NhsR1PhDQrfHtIE92lpRh2dW3ApF-25VpoBQKg1DI0gC4ik5nOHJfUal23iwohtG-LAou51zjSUKgv1HRWBml-St2IhZ4jfbhpIroh-xmIxfjQrhGZnzkcvvNzPHp7SQy4wHmkANkWzVHHRqKCq70uyNu_USVV7ClyMaa6xIeGNGEr09FqWN2POq8vBZmL7iGGsHi2GLpfJLEe4lKIpCMHDzJzKmK0pfQq0_4=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKLL0LeiehhL_xuVsFb6uxsJ8hD2l1WZLpXC0xurqQ6pDuhC2X8lKKW9KXheR7A74cHBBnJBdbXU_SjoqMPr087--g4r-_7AVIXLx_3ZJnw909IDbjjUL7Pjo19IlsUc6Z5ZlcES_gcUcU3YnTAL69RyZpSevg65ofmLJvUH8WH2A9u1YoaUlUVsncl8KyubpdnPwwvqjqBj7lPtaOqwToNpRH6AAl0i-VWRpQ93taKNbit7T1IwEoR0_zsOGdv7vT3MktXsce3sxwWwbqPBe_jTEeViKVQy-o_3-uQqgQEvd7Im71GLq5GMBfZ8vrJpezPrtW1aAet4M1tGvV8OfcWdIU1KK0bqolaF_dp0oqA5k_mSAA4f0YsDzXvxNQVtQ1rkLFIsomlFaBDEFMJSZBF6Gb9h-_BAP9nP-aN1Try8W29y2ZoICnbUu8nOcNZ_wKKOd3Op3Mvg_e3ZXUNQ-tnsnJt2xvjtlgpvqnaQN8kSaI7xlo-xt_B-7yqlFwzFCqGPS07ci37emgiGV2458OZKof37thjAP32_29yaeuSCMSaVPkmphugfW2TLAQPgv_rd2mCUIWMtCvxVfti6t77IMjMRjWE5TQwBRYmo-agTgrH2mqYJwKchpMaY-OyCEkjZwwnndxCDcJ2Skku-zfIusurOjM3AXgsegCS4J52nmFyA02EFaG4feBq3Ci7tt09N-tphqBKK5AeLDKQj7f6en4RlJKnxQShXGeoSFLD2mD05WK-vKFAmWf0f7WKcFF8SYeHTamC8lm_8rgrjOK8AHhkicOJvuBMkvT80z2zfBZFW1hVDRb-P6hdcxpYIgQD7wft3bfLUvgZdJBVRII04Z9eS5k8Dyh8pNwNJVJ2voXoHqX2Z51CVvF-JY6s1LNRWkq2NhsR1PhDQrfHtIE92lpRh2dW3ApF-25VpoBQKg1DI0gC4ik5nOHJfUal23iwohtG-LAou51zjSUKgv1HRWBml-St2IhZ4jfbhpIroh-xmIxfjQrhGZnzkcvvNzPHp7SQy4wHmkANkWzVHHRqKCq70uyNu_USVV7ClyMaa6xIeGNGEr09FqWN2POq8vBZmL7iGGsHi2GLpfJLEe4lKIpCMHDzJzKmK0pfQq0_4=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e240000aafef4f2_0%7C01af0f9c4e240000aafef4f2_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKLL0LeiehhL_xuVsFb6uxsJ8hD2l1WZLpXC0xurqQ6pDuhC2X8lKKW9KXheR7A74cHBBnJBdbXU_SjoqMPr087--g4r-_7AVIXLx_3ZJnw909IDbjjUL7Pjo19IlsUc6Z5ZlcES_gcUcU3YnTAL69RyZpSevg65ofmLJvUH8WH2A9u1YoaUlUVsncl8KyubpdnPwwvqjqBj7lPtaOqwToNpRH6AAl0i-VWRpQ93taKNbit7T1IwEoR0_zsOGdv7vT3MktXsce3sxwWwbqPBe_jTEeViKVQy-o_3-uQqgQEvd7Im71GLq5GMBfZ8vrJpezPrtW1aAet4M1tGvV8OfcWdIU1KK0bqolaF_dp0oqA5k_mSAA4f0YsDzXvxNQVtQ1rkLFIsomlFaBDEFMJSZBF6Gb9h-_BAP9nP-aN1Try8W29y2ZoICnbUu8nOcNZ_wKKOd3Op3Mvg_e3ZXUNQ-tnsnJt2xvjtlgpvqnaQN8kSaI7xlo-xt_B-7yqlFwzFCqGPS07ci37emgiGV2458OZKof37thjAP32_29yaeuSCMSaVPkmphugfW2TLAQPgv_rd2mCUIWMtCvxVfti6t77IMjMRjWE5TQwBRYmo-agTgrH2mqYJwKchpMaY-OyCEkjZwwnndxCDcJ2Skku-zfIusurOjM3AXgsegCS4J52nmFyA02EFaG4feBq3Ci7tt09N-tphqBKK5AeLDKQj7f6en4RlJKnxQShXGeoSFLD2mD05WK-vKFAmWf0f7WKcFF8SYeHTamC8lm_8rgrjOK8AHhkicOJvuBMkvT80z2zfBZFW1hVDRb-P6hdcxpYIgQD7wft3bfLUvgZdJBVRII04Z9eS5k8Dyh8pNwNJVJ2voXoHqX2Z51CVvF-JY6s1LNRWkq2NhsR1PhDQrfHtIE92lpRh2dW3ApF-25VpoBQKg1DI0gC4ik5nOHJfUal23iwohtG-LAou51zjSUKgv1HRWBml-St2IhZ4jfbhpIroh-xmIxfjQrhGZnzkcvvNzPHp7SQy4wHmkANkWzVHHRqKCq70uyNu_USVV7ClyMaa6xIeGNGEr09FqWN2POq8vBZmL7iGGsHi2GLpfJLEe4lKIpCMHDzJzKmK0pfQq0_4=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e240000aafef4f2_0",
                    "local_arrival": "2024-10-08T06:55:00.000Z",
                    "local_departure": "2024-10-08T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-08T05:55:00.000Z",
                    "utc_departure": "2024-10-08T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e240000aafef4f2",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e240000aafef4f2_1",
                    "local_arrival": "2024-10-08T14:05:00.000Z",
                    "local_departure": "2024-10-08T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:05:00.000Z",
                    "utc_departure": "2024-10-08T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1109.65,
            "totalPrice": 983
          },
          {
            "allPassengerTotalPrice": 990,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNYADVdAUYj_NWyCUh8T17qL0cD0MhAM5Oy9SlimZIoOiRBH5bb8Nree3I7X-Pe1XjBWuk4F4O49WgmrCKomSgBCqVFzvEin-32C2pHdeChRDNdtkKCzuT08xibA5I2HZT6_6dPgRLR78m01EPGonTUGR73nMLJ4EP5MIQHKcOmyffEB7pVYEg6-HEKMz8lcJ58tkHH58dYp2sgAFSNNwFKBAhHnJJrWbQlZ9TUhXumF1-90PuEYaf9eab9Y5EB9HkozUlweXpwCguHidBx0o2ExRYILAE7Fbz2sNAgfwLurh_xuQk1zP5eDUy24J0b7hqtdjQLM0NB30jg_TWcJh422-aXDUL6lPFodAsprhgtRFU_CH57GvrQAxZxrykAxAZgcznQtxf_Fn2lQMEx84i9aW5_FkUckbPR7FUQly6tPmgfyxpDMceBUrmwQuBHD6kMyZAWtUBhlBUUrt7TVLkpWJH9CPhzvfd4h6gwEUxlPlKr_JyOuBkOtLAI2qcgi_kCZdGd2V-rldCPp_T68m5LKx03JkRA4tqd7EJlyG5TmplQLyAkAmc3_c8oemN3cVPoRwYpF_6mfSuwGmYRqH4r4J53w_bxdoofjR_fAq0mSlQR5UqynV1pxD5LhCbyN7rX4unTuckHsWgMV9JKCA-UC4zLXh4ETvs15YPabq5nQo9eD6KUqNBD19P-Y71llj9iktksoe3hdqCYF3PdrB5vmWbsNQ4TmsHHqxyMFEdgOoLQL2HIAzJ-P_Juz5fOZFcHueNUJswmOYvLo_Oeue1zHcFc5YaeK6batwk5Wb1xZA98TQbjrkN1eYk0L1E97Af3lJXKQmlOAjdEZcHMylHG2t-twyTVZajYRW1UreOX46NO1IIH3RmLJ4JvLSpTwEGQlW-tyHLUzGg5baZXGkPv7UYKsS5O_XnFPCVZSSfk01hMrLcBr5TIf-XOApXVOEQfhq9uSQy4fXt3_YntwCUrb55KK3Ny3KW_IqYwy1HsD0UzxL1y4ZFvX-D1rJWBvNSM7FqzPaBlZK2jX4toDNH4TJ1df6XpufQY7rMdvZKFMis1zTt1kUBYolT5h8r6-6ojRRw00HnMRiyafxVTl_o_eihSvvcps7yVrUf6uTyDClmg1YGit99tEnboK6kTWTaEjDR6sOT7ICCACOuRmWG1Xk5CfNQQrXpBR3jJs659A=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNYADVdAUYj_NWyCUh8T17qL0cD0MhAM5Oy9SlimZIoOiRBH5bb8Nree3I7X-Pe1XjBWuk4F4O49WgmrCKomSgBCqVFzvEin-32C2pHdeChRDNdtkKCzuT08xibA5I2HZT6_6dPgRLR78m01EPGonTUGR73nMLJ4EP5MIQHKcOmyffEB7pVYEg6-HEKMz8lcJ58tkHH58dYp2sgAFSNNwFKBAhHnJJrWbQlZ9TUhXumF1-90PuEYaf9eab9Y5EB9HkozUlweXpwCguHidBx0o2ExRYILAE7Fbz2sNAgfwLurh_xuQk1zP5eDUy24J0b7hqtdjQLM0NB30jg_TWcJh422-aXDUL6lPFodAsprhgtRFU_CH57GvrQAxZxrykAxAZgcznQtxf_Fn2lQMEx84i9aW5_FkUckbPR7FUQly6tPmgfyxpDMceBUrmwQuBHD6kMyZAWtUBhlBUUrt7TVLkpWJH9CPhzvfd4h6gwEUxlPlKr_JyOuBkOtLAI2qcgi_kCZdGd2V-rldCPp_T68m5LKx03JkRA4tqd7EJlyG5TmplQLyAkAmc3_c8oemN3cVPoRwYpF_6mfSuwGmYRqH4r4J53w_bxdoofjR_fAq0mSlQR5UqynV1pxD5LhCbyN7rX4unTuckHsWgMV9JKCA-UC4zLXh4ETvs15YPabq5nQo9eD6KUqNBD19P-Y71llj9iktksoe3hdqCYF3PdrB5vmWbsNQ4TmsHHqxyMFEdgOoLQL2HIAzJ-P_Juz5fOZFcHueNUJswmOYvLo_Oeue1zHcFc5YaeK6batwk5Wb1xZA98TQbjrkN1eYk0L1E97Af3lJXKQmlOAjdEZcHMylHG2t-twyTVZajYRW1UreOX46NO1IIH3RmLJ4JvLSpTwEGQlW-tyHLUzGg5baZXGkPv7UYKsS5O_XnFPCVZSSfk01hMrLcBr5TIf-XOApXVOEQfhq9uSQy4fXt3_YntwCUrb55KK3Ny3KW_IqYwy1HsD0UzxL1y4ZFvX-D1rJWBvNSM7FqzPaBlZK2jX4toDNH4TJ1df6XpufQY7rMdvZKFMis1zTt1kUBYolT5h8r6-6ojRRw00HnMRiyafxVTl_o_eihSvvcps7yVrUf6uTyDClmg1YGit99tEnboK6kTWTaEjDR6sOT7ICCACOuRmWG1Xk5CfNQQrXpBR3jJs659A=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-07",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e2300009067dd1c_0%7C01af243e4e230000931fe28b_0%7C243e0f9c4e2300007005552f_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GNYADVdAUYj_NWyCUh8T17qL0cD0MhAM5Oy9SlimZIoOiRBH5bb8Nree3I7X-Pe1XjBWuk4F4O49WgmrCKomSgBCqVFzvEin-32C2pHdeChRDNdtkKCzuT08xibA5I2HZT6_6dPgRLR78m01EPGonTUGR73nMLJ4EP5MIQHKcOmyffEB7pVYEg6-HEKMz8lcJ58tkHH58dYp2sgAFSNNwFKBAhHnJJrWbQlZ9TUhXumF1-90PuEYaf9eab9Y5EB9HkozUlweXpwCguHidBx0o2ExRYILAE7Fbz2sNAgfwLurh_xuQk1zP5eDUy24J0b7hqtdjQLM0NB30jg_TWcJh422-aXDUL6lPFodAsprhgtRFU_CH57GvrQAxZxrykAxAZgcznQtxf_Fn2lQMEx84i9aW5_FkUckbPR7FUQly6tPmgfyxpDMceBUrmwQuBHD6kMyZAWtUBhlBUUrt7TVLkpWJH9CPhzvfd4h6gwEUxlPlKr_JyOuBkOtLAI2qcgi_kCZdGd2V-rldCPp_T68m5LKx03JkRA4tqd7EJlyG5TmplQLyAkAmc3_c8oemN3cVPoRwYpF_6mfSuwGmYRqH4r4J53w_bxdoofjR_fAq0mSlQR5UqynV1pxD5LhCbyN7rX4unTuckHsWgMV9JKCA-UC4zLXh4ETvs15YPabq5nQo9eD6KUqNBD19P-Y71llj9iktksoe3hdqCYF3PdrB5vmWbsNQ4TmsHHqxyMFEdgOoLQL2HIAzJ-P_Juz5fOZFcHueNUJswmOYvLo_Oeue1zHcFc5YaeK6batwk5Wb1xZA98TQbjrkN1eYk0L1E97Af3lJXKQmlOAjdEZcHMylHG2t-twyTVZajYRW1UreOX46NO1IIH3RmLJ4JvLSpTwEGQlW-tyHLUzGg5baZXGkPv7UYKsS5O_XnFPCVZSSfk01hMrLcBr5TIf-XOApXVOEQfhq9uSQy4fXt3_YntwCUrb55KK3Ny3KW_IqYwy1HsD0UzxL1y4ZFvX-D1rJWBvNSM7FqzPaBlZK2jX4toDNH4TJ1df6XpufQY7rMdvZKFMis1zTt1kUBYolT5h8r6-6ojRRw00HnMRiyafxVTl_o_eihSvvcps7yVrUf6uTyDClmg1YGit99tEnboK6kTWTaEjDR6sOT7ICCACOuRmWG1Xk5CfNQQrXpBR3jJs659A=",
                "dateChange": 0,
                "departureDate": "2024-10-07",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2300009067dd1c",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2300009067dd1c_0",
                    "local_arrival": "2024-10-07T09:20:00.000Z",
                    "local_departure": "2024-10-07T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-07T07:20:00.000Z",
                    "utc_departure": "2024-10-07T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e230000931fe28b",
                    "equipment": null,
                    "fare_basis": "QIL2A9WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e230000931fe28b_0",
                    "local_arrival": "2024-10-07T18:10:00.000Z",
                    "local_departure": "2024-10-07T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-08T01:10:00.000Z",
                    "utc_departure": "2024-10-07T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2300007005552f",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2300007005552f_0",
                    "local_arrival": "2024-10-07T23:43:00.000Z",
                    "local_departure": "2024-10-07T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:43:00.000Z",
                    "utc_departure": "2024-10-08T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1122.19,
            "totalPrice": 990
          },
          {
            "allPassengerTotalPrice": 1062,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIHSIGrSndpv4pHVxV3rI5TKag2gCVU9GYiOEaq0nfCrpWQJuNpUnZ2QyFBxVc3r8doBbXx8wwBdNpb4T22Z6AAecBClKYtSNaQtGiCXzhgFwFZaTOCwS230SVYpk5AsYzN3GUZbTawbrW34e9eEjIu1jKRzEDzfZn15-_8BgsTu_WejarjaKLrS4vpJDjWsH4V5PYlVS_lXqbCOfacyChBHzgc-qEiv2FKcTxJzCRQ6tXGVazTuHJaqKuEcdubLX3qLvgPRRAIvob2hu0Ze5IUo5egNzt0gp4N-f4Glwadu_I4-y9EzFWq4CLl1I5TQTs4tfRcAs7SYLko6nXdGE7PHjLuI4vUkkRaAWjuHsv4BIGkyOaQoqUBS54f7a2n6pA3qH1okllnPPNeICBXl7s9lKLp-UZhnMGu6hTqsAkt-bp1LtuGS5kWjPaOgUEHc0J4-LFxBt4QJUJB0Xs25cCTa3fX4PZZvy0-OJe6vmldoaj2BJhi0rdQHTaJvSfdEuO3uuhSraXS16nxRZXAe64DRu5e9qSZ_jdeOXu8asnbpLjRpuBfgmSMN-AKc8EEMXpmVZTl4LoNwVuCp1Dtx9dPq8cVRNqz3gOPQhm8FxfaR1dAxcZ1EuRknva-SvARqHTEo2nu1aDF5OQMTZy2aqKTkcAP_3g2qJupOxbnzmUo0zLuUag_HmfV4C8_LL39DTCzVb8RqUIbAqKBbPQaUl0P6r2967gpOwEuF_H9-GKFbVxDp5rauT5n75tvcOGZ5bBz6t3VDAnjz2LjynUGTgK2m8qOqhWnie6kkx19sNF4KFPtRRcf7cJbB1F0JWi5X9J9FzYwjeA9V9Cu4LRxt9bM5Z68ck9hIyevzYl4uAZeoJJIK6HiBCKvi0se01Rt0u0p6SfPpjWOhUFfG-pULBqVxViKD8dmrSKT0ancvWcG4mGtoBFdCQxKZDJWgdFU8su0vr2GDBwNI2GIUC_qVjhcpvajLji0bBs5pSyHoca6kN0iQ7G2_GLFR_PcMsInVgPxR219RbKUM39OhMjoFpmQfIyN0GH_qOQ9JMBIRefxnWOXHKc_VElS3fluVfK7pzTk1VSQrpv0JJJ0lX5I9q0e9sUtXHQ4odi8Ww9bjcZUu6EE9xAa5q2HWXoZi47HQj7cb9qIY6k1OYoNz_sw9YnTFTvTp5L2-Sj5ewOmtbccofl--6G3_TpG0LEt5muYi6",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIHSIGrSndpv4pHVxV3rI5TKag2gCVU9GYiOEaq0nfCrpWQJuNpUnZ2QyFBxVc3r8doBbXx8wwBdNpb4T22Z6AAecBClKYtSNaQtGiCXzhgFwFZaTOCwS230SVYpk5AsYzN3GUZbTawbrW34e9eEjIu1jKRzEDzfZn15-_8BgsTu_WejarjaKLrS4vpJDjWsH4V5PYlVS_lXqbCOfacyChBHzgc-qEiv2FKcTxJzCRQ6tXGVazTuHJaqKuEcdubLX3qLvgPRRAIvob2hu0Ze5IUo5egNzt0gp4N-f4Glwadu_I4-y9EzFWq4CLl1I5TQTs4tfRcAs7SYLko6nXdGE7PHjLuI4vUkkRaAWjuHsv4BIGkyOaQoqUBS54f7a2n6pA3qH1okllnPPNeICBXl7s9lKLp-UZhnMGu6hTqsAkt-bp1LtuGS5kWjPaOgUEHc0J4-LFxBt4QJUJB0Xs25cCTa3fX4PZZvy0-OJe6vmldoaj2BJhi0rdQHTaJvSfdEuO3uuhSraXS16nxRZXAe64DRu5e9qSZ_jdeOXu8asnbpLjRpuBfgmSMN-AKc8EEMXpmVZTl4LoNwVuCp1Dtx9dPq8cVRNqz3gOPQhm8FxfaR1dAxcZ1EuRknva-SvARqHTEo2nu1aDF5OQMTZy2aqKTkcAP_3g2qJupOxbnzmUo0zLuUag_HmfV4C8_LL39DTCzVb8RqUIbAqKBbPQaUl0P6r2967gpOwEuF_H9-GKFbVxDp5rauT5n75tvcOGZ5bBz6t3VDAnjz2LjynUGTgK2m8qOqhWnie6kkx19sNF4KFPtRRcf7cJbB1F0JWi5X9J9FzYwjeA9V9Cu4LRxt9bM5Z68ck9hIyevzYl4uAZeoJJIK6HiBCKvi0se01Rt0u0p6SfPpjWOhUFfG-pULBqVxViKD8dmrSKT0ancvWcG4mGtoBFdCQxKZDJWgdFU8su0vr2GDBwNI2GIUC_qVjhcpvajLji0bBs5pSyHoca6kN0iQ7G2_GLFR_PcMsInVgPxR219RbKUM39OhMjoFpmQfIyN0GH_qOQ9JMBIRefxnWOXHKc_VElS3fluVfK7pzTk1VSQrpv0JJJ0lX5I9q0e9sUtXHQ4odi8Ww9bjcZUu6EE9xAa5q2HWXoZi47HQj7cb9qIY6k1OYoNz_sw9YnTFTvTp5L2-Sj5ewOmtbccofl--6G3_TpG0LEt5muYi6",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d00002c3b305e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d00002c3b305e_0",
                    "local_arrival": "2024-10-01T09:45:00.000Z",
                    "local_departure": "2024-10-01T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-01T07:45:00.000Z",
                    "utc_departure": "2024-10-01T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d00002c3b305e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIHSIGrSndpv4pHVxV3rI5TKag2gCVU9GYiOEaq0nfCrpWQJuNpUnZ2QyFBxVc3r8doBbXx8wwBdNpb4T22Z6AAecBClKYtSNaQtGiCXzhgFwFZaTOCwS230SVYpk5AsYzN3GUZbTawbrW34e9eEjIu1jKRzEDzfZn15-_8BgsTu_WejarjaKLrS4vpJDjWsH4V5PYlVS_lXqbCOfacyChBHzgc-qEiv2FKcTxJzCRQ6tXGVazTuHJaqKuEcdubLX3qLvgPRRAIvob2hu0Ze5IUo5egNzt0gp4N-f4Glwadu_I4-y9EzFWq4CLl1I5TQTs4tfRcAs7SYLko6nXdGE7PHjLuI4vUkkRaAWjuHsv4BIGkyOaQoqUBS54f7a2n6pA3qH1okllnPPNeICBXl7s9lKLp-UZhnMGu6hTqsAkt-bp1LtuGS5kWjPaOgUEHc0J4-LFxBt4QJUJB0Xs25cCTa3fX4PZZvy0-OJe6vmldoaj2BJhi0rdQHTaJvSfdEuO3uuhSraXS16nxRZXAe64DRu5e9qSZ_jdeOXu8asnbpLjRpuBfgmSMN-AKc8EEMXpmVZTl4LoNwVuCp1Dtx9dPq8cVRNqz3gOPQhm8FxfaR1dAxcZ1EuRknva-SvARqHTEo2nu1aDF5OQMTZy2aqKTkcAP_3g2qJupOxbnzmUo0zLuUag_HmfV4C8_LL39DTCzVb8RqUIbAqKBbPQaUl0P6r2967gpOwEuF_H9-GKFbVxDp5rauT5n75tvcOGZ5bBz6t3VDAnjz2LjynUGTgK2m8qOqhWnie6kkx19sNF4KFPtRRcf7cJbB1F0JWi5X9J9FzYwjeA9V9Cu4LRxt9bM5Z68ck9hIyevzYl4uAZeoJJIK6HiBCKvi0se01Rt0u0p6SfPpjWOhUFfG-pULBqVxViKD8dmrSKT0ancvWcG4mGtoBFdCQxKZDJWgdFU8su0vr2GDBwNI2GIUC_qVjhcpvajLji0bBs5pSyHoca6kN0iQ7G2_GLFR_PcMsInVgPxR219RbKUM39OhMjoFpmQfIyN0GH_qOQ9JMBIRefxnWOXHKc_VElS3fluVfK7pzTk1VSQrpv0JJJ0lX5I9q0e9sUtXHQ4odi8Ww9bjcZUu6EE9xAa5q2HWXoZi47HQj7cb9qIY6k1OYoNz_sw9YnTFTvTp5L2-Sj5ewOmtbccofl--6G3_TpG0LEt5muYi6",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1160.79,
            "totalPrice": 1062
          },
          {
            "allPassengerTotalPrice": 1062,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2HjV9kq0IiPbrnCM91CDPhskpZYb-HsUHpFVs88d1Wu5CJah8tobSqUPuwj4_rfhYP9aL2SkP1Ooqn_-sxuytG_HYQ-k5jyXv6DNs2aIyLcpNSfr5968zvZ26CZaG5OOkDClIZ_4_QhAfzAzIQ7eOQYejJD43yHere41CHSCvLINK-FcWEudftBq8Ps8AZJoE3I9LbfEUTlMN569Q-wvx8cGsWNsN3RtXOp_UtaEdZC5pVcr-pc1dedrKjdb12bdnlXD2rTSM8UFcGSRHTgahD_-iEDiKol-22uDgKqrYdjXQHrZfcwsuitbAwvFuGXwT1V3bnmoMw5vJKAKMu51w_mPlC9Vk8ucK2Pyw3EidkGKQJEvchmCgepgrctBoSmPfB5xW3Dt6iWqYuku2XAdQhdYrL8EHZazta2d-0us7Tfo4iqNFg9lZDXn_jhSvJbkKHif3cweS9m2HDk9M5UqOyoiIaCMPCp6V1EpOUt_1ywJsu3Ye3mYgLA_NPEhUx-_JN7lTjXhVbDAHn8vPff9XsFc323py3uuHEWe5KeNNtPKmKl9Klla6aTdE3ILNIhQQXn2-xhVwXKWBu3n2Hg43GepVOsEftdvxfiKL3bsxsYa2igKonejeUTvz40__oeRO-yIqfwE9t5NkTVXcoTcgCvz86DBFOKDUJKiwr9Lwp6yK3F3EBWD5DCaifRi3Zi1lLtmQRz49Z-ErPkb45k4UJnKgD5FSPHIxb0RO8G7ywTzp2gW34MYg_1qT4jqhbmyi6mFnNbDXHo8bJCyfWg7oYqKxHTWhOLeImzD0fAKBlr7Abe7S6UIqGyYoC6b6UaXZkMVL_0sSjjQtZu7be9lcxOkeg6pplK9amKk2jbmDx1EWHH-GTCL_aNeJT8t4f7x3JJVZ9ZGaBmMfeb9vxP9inFSSCB6D7HQmdchm7izhIcrixb3Sw1VZaLqiY4PIS3-r2o8oxENnsvqvxbnpf3APypZYYQpxByrfON31qu6BoBLN7xjk6HiS4b2IP1GG9OuLR4lPBLNXS2aueKuJTHmlobibCXHw4NAUn3wgCTSflo6Mqde2GAa5irqa70MgctxrNK4Qn_JH5RCuvR0ir0ocm5Dqp4V-At72grl22RSdC6GtYQIYLsl2NXhch0AfXCws_8eBffdXzhe3kj2z5zS4XP4JtneokhR524JxQ1o2tIoJV6WySQ3G2IN7dGIhHPC",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2HjV9kq0IiPbrnCM91CDPhskpZYb-HsUHpFVs88d1Wu5CJah8tobSqUPuwj4_rfhYP9aL2SkP1Ooqn_-sxuytG_HYQ-k5jyXv6DNs2aIyLcpNSfr5968zvZ26CZaG5OOkDClIZ_4_QhAfzAzIQ7eOQYejJD43yHere41CHSCvLINK-FcWEudftBq8Ps8AZJoE3I9LbfEUTlMN569Q-wvx8cGsWNsN3RtXOp_UtaEdZC5pVcr-pc1dedrKjdb12bdnlXD2rTSM8UFcGSRHTgahD_-iEDiKol-22uDgKqrYdjXQHrZfcwsuitbAwvFuGXwT1V3bnmoMw5vJKAKMu51w_mPlC9Vk8ucK2Pyw3EidkGKQJEvchmCgepgrctBoSmPfB5xW3Dt6iWqYuku2XAdQhdYrL8EHZazta2d-0us7Tfo4iqNFg9lZDXn_jhSvJbkKHif3cweS9m2HDk9M5UqOyoiIaCMPCp6V1EpOUt_1ywJsu3Ye3mYgLA_NPEhUx-_JN7lTjXhVbDAHn8vPff9XsFc323py3uuHEWe5KeNNtPKmKl9Klla6aTdE3ILNIhQQXn2-xhVwXKWBu3n2Hg43GepVOsEftdvxfiKL3bsxsYa2igKonejeUTvz40__oeRO-yIqfwE9t5NkTVXcoTcgCvz86DBFOKDUJKiwr9Lwp6yK3F3EBWD5DCaifRi3Zi1lLtmQRz49Z-ErPkb45k4UJnKgD5FSPHIxb0RO8G7ywTzp2gW34MYg_1qT4jqhbmyi6mFnNbDXHo8bJCyfWg7oYqKxHTWhOLeImzD0fAKBlr7Abe7S6UIqGyYoC6b6UaXZkMVL_0sSjjQtZu7be9lcxOkeg6pplK9amKk2jbmDx1EWHH-GTCL_aNeJT8t4f7x3JJVZ9ZGaBmMfeb9vxP9inFSSCB6D7HQmdchm7izhIcrixb3Sw1VZaLqiY4PIS3-r2o8oxENnsvqvxbnpf3APypZYYQpxByrfON31qu6BoBLN7xjk6HiS4b2IP1GG9OuLR4lPBLNXS2aueKuJTHmlobibCXHw4NAUn3wgCTSflo6Mqde2GAa5irqa70MgctxrNK4Qn_JH5RCuvR0ir0ocm5Dqp4V-At72grl22RSdC6GtYQIYLsl2NXhch0AfXCws_8eBffdXzhe3kj2z5zS4XP4JtneokhR524JxQ1o2tIoJV6WySQ3G2IN7dGIhHPC",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000064176280",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000064176280_0",
                    "local_arrival": "2024-10-01T13:45:00.000Z",
                    "local_departure": "2024-10-01T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-01T11:45:00.000Z",
                    "utc_departure": "2024-10-01T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2HjV9kq0IiPbrnCM91CDPhskpZYb-HsUHpFVs88d1Wu5CJah8tobSqUPuwj4_rfhYP9aL2SkP1Ooqn_-sxuytG_HYQ-k5jyXv6DNs2aIyLcpNSfr5968zvZ26CZaG5OOkDClIZ_4_QhAfzAzIQ7eOQYejJD43yHere41CHSCvLINK-FcWEudftBq8Ps8AZJoE3I9LbfEUTlMN569Q-wvx8cGsWNsN3RtXOp_UtaEdZC5pVcr-pc1dedrKjdb12bdnlXD2rTSM8UFcGSRHTgahD_-iEDiKol-22uDgKqrYdjXQHrZfcwsuitbAwvFuGXwT1V3bnmoMw5vJKAKMu51w_mPlC9Vk8ucK2Pyw3EidkGKQJEvchmCgepgrctBoSmPfB5xW3Dt6iWqYuku2XAdQhdYrL8EHZazta2d-0us7Tfo4iqNFg9lZDXn_jhSvJbkKHif3cweS9m2HDk9M5UqOyoiIaCMPCp6V1EpOUt_1ywJsu3Ye3mYgLA_NPEhUx-_JN7lTjXhVbDAHn8vPff9XsFc323py3uuHEWe5KeNNtPKmKl9Klla6aTdE3ILNIhQQXn2-xhVwXKWBu3n2Hg43GepVOsEftdvxfiKL3bsxsYa2igKonejeUTvz40__oeRO-yIqfwE9t5NkTVXcoTcgCvz86DBFOKDUJKiwr9Lwp6yK3F3EBWD5DCaifRi3Zi1lLtmQRz49Z-ErPkb45k4UJnKgD5FSPHIxb0RO8G7ywTzp2gW34MYg_1qT4jqhbmyi6mFnNbDXHo8bJCyfWg7oYqKxHTWhOLeImzD0fAKBlr7Abe7S6UIqGyYoC6b6UaXZkMVL_0sSjjQtZu7be9lcxOkeg6pplK9amKk2jbmDx1EWHH-GTCL_aNeJT8t4f7x3JJVZ9ZGaBmMfeb9vxP9inFSSCB6D7HQmdchm7izhIcrixb3Sw1VZaLqiY4PIS3-r2o8oxENnsvqvxbnpf3APypZYYQpxByrfON31qu6BoBLN7xjk6HiS4b2IP1GG9OuLR4lPBLNXS2aueKuJTHmlobibCXHw4NAUn3wgCTSflo6Mqde2GAa5irqa70MgctxrNK4Qn_JH5RCuvR0ir0ocm5Dqp4V-At72grl22RSdC6GtYQIYLsl2NXhch0AfXCws_8eBffdXzhe3kj2z5zS4XP4JtneokhR524JxQ1o2tIoJV6WySQ3G2IN7dGIhHPC",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1160.79,
            "totalPrice": 1062
          },
          {
            "allPassengerTotalPrice": 1062,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gqgzph2cTOfCwKMmRTEv1pGEMprdCk6xGKdid-EHL3X17g3RYm5UVGMJBExLBE_Mb91XLXwQ0oC05hYrCScOT8ou5tuLFI7ta158sOys0SkFUV_rdmFne11j5GPB7rn3utUB8uRPOcR701LAqPmWBZg5PIJVFInT_AKfl1QaFCdnfOXOmznkJv8sW5tKgB4NVNZefW5VXz1WgujHBoj5v9Odkgh38Iq26OszT8CYD_ibjvTOfEuHz1Wb8zBLV_om9zRiF38odT0_y24hOCUbbPVztK2M_TdfrZaugsMz0heOhryNoSELMNfbNhsSIpStSvbKcm7xcjyMr1idG4_O8ZBsl3I8rU6aKTCc20ekyh3VKTVagDsiGxUOFXCZRrwHavw0HRzHG1h7kdGY1-2F9hqtciLlhzxfmDQHh8fsEBtj2tXN7zendMcKOboeyvc5dE30WtTxc_eBk4nReebm0oSXiHcMIagzTZvRwRoPeXtDbvQoMgCF7smPENkcnNex2peF_NmrRTr1DRGBL55yyWucKiyqdT20tx9NBZ5Ok4zgHnVXiZCqdwjopxeNWIaS7xK8tF7YR801Di0QFc1ovMRgouY6LsNOphIqeLGb3kb8F_NP-WAg_vZ4leleOqmMkJc8US-gCUMv3gotTTTvi6XVjdsqupAOQaOiHRb1xBThUyxtn5dyH8R6Ag4BargZvJCFFOWwhM24LayFrYVKCbZjgmrggEYNCa26UNntRCLBdSXjFraK6F4bN7qkwgKdXgP9yCtvBfmqxVLG633KWEnFa_R0H87R0qwp24CVNIL6d5QqK4ptBFV3A9VZ8BtcyiixzbswVeqJ9tsxvGxJcCGK-OkvNr_zY0dAHme3VwlbhiMaG_79I1A2Gbx02ApUOBkXuIm_Gi8W0f9_ZpDooMkvOHdJZi_wSMubHvjlnGCoHZ5ShNh5LBNjiJENlWQ9lbTQiugvH6DSRB2cS79tGRIdTvn0E9LTDPHGrcpSz8hQlC8MPWspQq3ovxcLT71RYNwC0t6F1XZg-CsLyfv71Tp3zP92tn_uS1THRq5G3KUa8Rff5a2Jih2SUTPohZejbPctFfGsxDeWGcAYMj_hlBRyEoDA_lrOGE-lAFw3LnWkEDAa4Ut-DYwyaounugblevv9V3QJQ2m_uS3pDZ3HpaONqslR9_gEQoCiw83zm-2Y=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gqgzph2cTOfCwKMmRTEv1pGEMprdCk6xGKdid-EHL3X17g3RYm5UVGMJBExLBE_Mb91XLXwQ0oC05hYrCScOT8ou5tuLFI7ta158sOys0SkFUV_rdmFne11j5GPB7rn3utUB8uRPOcR701LAqPmWBZg5PIJVFInT_AKfl1QaFCdnfOXOmznkJv8sW5tKgB4NVNZefW5VXz1WgujHBoj5v9Odkgh38Iq26OszT8CYD_ibjvTOfEuHz1Wb8zBLV_om9zRiF38odT0_y24hOCUbbPVztK2M_TdfrZaugsMz0heOhryNoSELMNfbNhsSIpStSvbKcm7xcjyMr1idG4_O8ZBsl3I8rU6aKTCc20ekyh3VKTVagDsiGxUOFXCZRrwHavw0HRzHG1h7kdGY1-2F9hqtciLlhzxfmDQHh8fsEBtj2tXN7zendMcKOboeyvc5dE30WtTxc_eBk4nReebm0oSXiHcMIagzTZvRwRoPeXtDbvQoMgCF7smPENkcnNex2peF_NmrRTr1DRGBL55yyWucKiyqdT20tx9NBZ5Ok4zgHnVXiZCqdwjopxeNWIaS7xK8tF7YR801Di0QFc1ovMRgouY6LsNOphIqeLGb3kb8F_NP-WAg_vZ4leleOqmMkJc8US-gCUMv3gotTTTvi6XVjdsqupAOQaOiHRb1xBThUyxtn5dyH8R6Ag4BargZvJCFFOWwhM24LayFrYVKCbZjgmrggEYNCa26UNntRCLBdSXjFraK6F4bN7qkwgKdXgP9yCtvBfmqxVLG633KWEnFa_R0H87R0qwp24CVNIL6d5QqK4ptBFV3A9VZ8BtcyiixzbswVeqJ9tsxvGxJcCGK-OkvNr_zY0dAHme3VwlbhiMaG_79I1A2Gbx02ApUOBkXuIm_Gi8W0f9_ZpDooMkvOHdJZi_wSMubHvjlnGCoHZ5ShNh5LBNjiJENlWQ9lbTQiugvH6DSRB2cS79tGRIdTvn0E9LTDPHGrcpSz8hQlC8MPWspQq3ovxcLT71RYNwC0t6F1XZg-CsLyfv71Tp3zP92tn_uS1THRq5G3KUa8Rff5a2Jih2SUTPohZejbPctFfGsxDeWGcAYMj_hlBRyEoDA_lrOGE-lAFw3LnWkEDAa4Ut-DYwyaounugblevv9V3QJQ2m_uS3pDZ3HpaONqslR9_gEQoCiw83zm-2Y=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000db2e6033",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000db2e6033_0",
                    "local_arrival": "2024-10-01T18:15:00.000Z",
                    "local_departure": "2024-10-01T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:15:00.000Z",
                    "utc_departure": "2024-10-01T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000db2e6033_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gqgzph2cTOfCwKMmRTEv1pGEMprdCk6xGKdid-EHL3X17g3RYm5UVGMJBExLBE_Mb91XLXwQ0oC05hYrCScOT8ou5tuLFI7ta158sOys0SkFUV_rdmFne11j5GPB7rn3utUB8uRPOcR701LAqPmWBZg5PIJVFInT_AKfl1QaFCdnfOXOmznkJv8sW5tKgB4NVNZefW5VXz1WgujHBoj5v9Odkgh38Iq26OszT8CYD_ibjvTOfEuHz1Wb8zBLV_om9zRiF38odT0_y24hOCUbbPVztK2M_TdfrZaugsMz0heOhryNoSELMNfbNhsSIpStSvbKcm7xcjyMr1idG4_O8ZBsl3I8rU6aKTCc20ekyh3VKTVagDsiGxUOFXCZRrwHavw0HRzHG1h7kdGY1-2F9hqtciLlhzxfmDQHh8fsEBtj2tXN7zendMcKOboeyvc5dE30WtTxc_eBk4nReebm0oSXiHcMIagzTZvRwRoPeXtDbvQoMgCF7smPENkcnNex2peF_NmrRTr1DRGBL55yyWucKiyqdT20tx9NBZ5Ok4zgHnVXiZCqdwjopxeNWIaS7xK8tF7YR801Di0QFc1ovMRgouY6LsNOphIqeLGb3kb8F_NP-WAg_vZ4leleOqmMkJc8US-gCUMv3gotTTTvi6XVjdsqupAOQaOiHRb1xBThUyxtn5dyH8R6Ag4BargZvJCFFOWwhM24LayFrYVKCbZjgmrggEYNCa26UNntRCLBdSXjFraK6F4bN7qkwgKdXgP9yCtvBfmqxVLG633KWEnFa_R0H87R0qwp24CVNIL6d5QqK4ptBFV3A9VZ8BtcyiixzbswVeqJ9tsxvGxJcCGK-OkvNr_zY0dAHme3VwlbhiMaG_79I1A2Gbx02ApUOBkXuIm_Gi8W0f9_ZpDooMkvOHdJZi_wSMubHvjlnGCoHZ5ShNh5LBNjiJENlWQ9lbTQiugvH6DSRB2cS79tGRIdTvn0E9LTDPHGrcpSz8hQlC8MPWspQq3ovxcLT71RYNwC0t6F1XZg-CsLyfv71Tp3zP92tn_uS1THRq5G3KUa8Rff5a2Jih2SUTPohZejbPctFfGsxDeWGcAYMj_hlBRyEoDA_lrOGE-lAFw3LnWkEDAa4Ut-DYwyaounugblevv9V3QJQ2m_uS3pDZ3HpaONqslR9_gEQoCiw83zm-2Y=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1161.45,
            "totalPrice": 1062
          },
          {
            "allPassengerTotalPrice": 1062,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAlP05sFDL8eNYJ9uN4xl4ljaj--ZuQssiJ_2lA8IrYi4EM3mDkhjYgv0Jg_4urFGCZd41HWSb8DfnhFGUpWO4X1CRlTDnUP5fC-j5N38932vCODtpBGxcbehDlgDxWQORtdXEyY0ZkAj2oMiBZnLh1WJUlHeKBE4NFD1oCiFEHPEIUzhNmXckKa_Wfuwr7faBf8MbuUqqmz3uuPYJ-LT_Kn5yredThMnpc549spPAfUuff4uwmTnzLGJjA14XTOCQLRQCEpRr3ejH-Ve9f150jCXyWq85_qm3g8yiJ_M44Mhm0rvF4l5D7BGdNE10f2LwxcJbM69ejym8pW-6Mh7FgJVaYqQ7nqeT5kOs0gFEGcs4WtbgwUrE-zjtNST9UWAAHVZQQ1igzWftAnh8uD8JtsGORzyR3ZhabUGqzy_IqxLYheOSihx6Qs3mtl-Gwe94CQzhWIW5GTVckvl85FKLEhvezNUcjnv6KXdQR5udAIs_hnQdIxjwWvKLhz_bukBtTq_ne_uY5Y5vXO8p4Lqif45WjO4ejTibuyACJerZ8_rErJGXrW-L5VsajPIV72r2NHRl_LSe4EaLjWd_8Z2XAqpbAyfd6Mq6WcGEwUTvNI4sePJr8jJ129EgGaMjJWijwnWDsxP-pzvtArMqObXvWyM7810V-SLmk4U3f5UFgquhuHA940cB2W8yuy0EI_QatFiBUuTZ8bY46grXyyJwBhT0l4O7pQpe7V___fpBCPX9lPIQ8TARRGNOLxMT0xyk7rIZqyYp7pa4-KJqij-jWwlzC5d6mEs8wFz-sqpII52SHIDKqHrkrTKmSZGGXpe018V4JLOVqmrtP0I9isMOQQCsH5lGDdGJ7-12_Ucaxz5bpavjm4H5Q8s_juAwKetE8kc3QtLDJGczCUXcYE0rAk-_k5TxoQq14QcLBoMsAmPDRTLkeFyBftfzW_0bC6g1rG7PIK3UZ4i0uFQtpjE3kJErgGTf4Ej6MN_ZlYBwW4JIZsfDDIJ6T08YnqG0g2llzVhHctjtteho4l1PrX6jEFZjuM7FNU46-NBmlEBvmSC_-97xzaOqZKgZbfzAw1BHSkpcPgMYYb0aT-rD0GwBEHXs2h03m2-rOxuxbKQzfph1NOzfqx4E_rQ4VfcjL9ZMuR-TfrU5VdezYQ6_CjcJ5xUTbPKyaeOIrwKmaDI-M_ry5_feDyMNGT7wz7zAznE",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAlP05sFDL8eNYJ9uN4xl4ljaj--ZuQssiJ_2lA8IrYi4EM3mDkhjYgv0Jg_4urFGCZd41HWSb8DfnhFGUpWO4X1CRlTDnUP5fC-j5N38932vCODtpBGxcbehDlgDxWQORtdXEyY0ZkAj2oMiBZnLh1WJUlHeKBE4NFD1oCiFEHPEIUzhNmXckKa_Wfuwr7faBf8MbuUqqmz3uuPYJ-LT_Kn5yredThMnpc549spPAfUuff4uwmTnzLGJjA14XTOCQLRQCEpRr3ejH-Ve9f150jCXyWq85_qm3g8yiJ_M44Mhm0rvF4l5D7BGdNE10f2LwxcJbM69ejym8pW-6Mh7FgJVaYqQ7nqeT5kOs0gFEGcs4WtbgwUrE-zjtNST9UWAAHVZQQ1igzWftAnh8uD8JtsGORzyR3ZhabUGqzy_IqxLYheOSihx6Qs3mtl-Gwe94CQzhWIW5GTVckvl85FKLEhvezNUcjnv6KXdQR5udAIs_hnQdIxjwWvKLhz_bukBtTq_ne_uY5Y5vXO8p4Lqif45WjO4ejTibuyACJerZ8_rErJGXrW-L5VsajPIV72r2NHRl_LSe4EaLjWd_8Z2XAqpbAyfd6Mq6WcGEwUTvNI4sePJr8jJ129EgGaMjJWijwnWDsxP-pzvtArMqObXvWyM7810V-SLmk4U3f5UFgquhuHA940cB2W8yuy0EI_QatFiBUuTZ8bY46grXyyJwBhT0l4O7pQpe7V___fpBCPX9lPIQ8TARRGNOLxMT0xyk7rIZqyYp7pa4-KJqij-jWwlzC5d6mEs8wFz-sqpII52SHIDKqHrkrTKmSZGGXpe018V4JLOVqmrtP0I9isMOQQCsH5lGDdGJ7-12_Ucaxz5bpavjm4H5Q8s_juAwKetE8kc3QtLDJGczCUXcYE0rAk-_k5TxoQq14QcLBoMsAmPDRTLkeFyBftfzW_0bC6g1rG7PIK3UZ4i0uFQtpjE3kJErgGTf4Ej6MN_ZlYBwW4JIZsfDDIJ6T08YnqG0g2llzVhHctjtteho4l1PrX6jEFZjuM7FNU46-NBmlEBvmSC_-97xzaOqZKgZbfzAw1BHSkpcPgMYYb0aT-rD0GwBEHXs2h03m2-rOxuxbKQzfph1NOzfqx4E_rQ4VfcjL9ZMuR-TfrU5VdezYQ6_CjcJ5xUTbPKyaeOIrwKmaDI-M_ry5_feDyMNGT7wz7zAznE",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000d3e40c0a",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000d3e40c0a_0",
                    "local_arrival": "2024-10-01T14:35:00.000Z",
                    "local_departure": "2024-10-01T13:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-01T12:35:00.000Z",
                    "utc_departure": "2024-10-01T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000d3e40c0a_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAlP05sFDL8eNYJ9uN4xl4ljaj--ZuQssiJ_2lA8IrYi4EM3mDkhjYgv0Jg_4urFGCZd41HWSb8DfnhFGUpWO4X1CRlTDnUP5fC-j5N38932vCODtpBGxcbehDlgDxWQORtdXEyY0ZkAj2oMiBZnLh1WJUlHeKBE4NFD1oCiFEHPEIUzhNmXckKa_Wfuwr7faBf8MbuUqqmz3uuPYJ-LT_Kn5yredThMnpc549spPAfUuff4uwmTnzLGJjA14XTOCQLRQCEpRr3ejH-Ve9f150jCXyWq85_qm3g8yiJ_M44Mhm0rvF4l5D7BGdNE10f2LwxcJbM69ejym8pW-6Mh7FgJVaYqQ7nqeT5kOs0gFEGcs4WtbgwUrE-zjtNST9UWAAHVZQQ1igzWftAnh8uD8JtsGORzyR3ZhabUGqzy_IqxLYheOSihx6Qs3mtl-Gwe94CQzhWIW5GTVckvl85FKLEhvezNUcjnv6KXdQR5udAIs_hnQdIxjwWvKLhz_bukBtTq_ne_uY5Y5vXO8p4Lqif45WjO4ejTibuyACJerZ8_rErJGXrW-L5VsajPIV72r2NHRl_LSe4EaLjWd_8Z2XAqpbAyfd6Mq6WcGEwUTvNI4sePJr8jJ129EgGaMjJWijwnWDsxP-pzvtArMqObXvWyM7810V-SLmk4U3f5UFgquhuHA940cB2W8yuy0EI_QatFiBUuTZ8bY46grXyyJwBhT0l4O7pQpe7V___fpBCPX9lPIQ8TARRGNOLxMT0xyk7rIZqyYp7pa4-KJqij-jWwlzC5d6mEs8wFz-sqpII52SHIDKqHrkrTKmSZGGXpe018V4JLOVqmrtP0I9isMOQQCsH5lGDdGJ7-12_Ucaxz5bpavjm4H5Q8s_juAwKetE8kc3QtLDJGczCUXcYE0rAk-_k5TxoQq14QcLBoMsAmPDRTLkeFyBftfzW_0bC6g1rG7PIK3UZ4i0uFQtpjE3kJErgGTf4Ej6MN_ZlYBwW4JIZsfDDIJ6T08YnqG0g2llzVhHctjtteho4l1PrX6jEFZjuM7FNU46-NBmlEBvmSC_-97xzaOqZKgZbfzAw1BHSkpcPgMYYb0aT-rD0GwBEHXs2h03m2-rOxuxbKQzfph1NOzfqx4E_rQ4VfcjL9ZMuR-TfrU5VdezYQ6_CjcJ5xUTbPKyaeOIrwKmaDI-M_ry5_feDyMNGT7wz7zAznE",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1162.3,
            "totalPrice": 1062
          },
          {
            "allPassengerTotalPrice": 1069,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gbl9xLqRCcQZTTLLdE5rmjm46AooJGiAQYEIMHpyvN58J3I_uKUzA0e1se8GiByeHyhu2p4HZgJAZE8UoNrHNRxFuOFKIMDtloX1acVRP_Hnufpm5pVPFFGSFtVEpn_Gkit4LoXyuMwFTb3jEyIJHSNCISYFMbtnieDSKYraATPCjlNoaQuJAJ_uY0dDGz0zB80r0MkRh3gECSsGiFHFK6exgKjHmEUhblTF4RxpzgcP_aJJzRbLzhfNRxrur4oUSx5u23bEunoOiGgraWOzn7HvzECTfZ7WLVUWnJcsccaTSMGLSyUrsnsJ6BMAhuXg1WHLumFs6-eV5Bfw96vDYwQqr4mQSvdcBFNyD7x8uhNzW2UzXzpaMEShgs-1lvmW_huK312BBtnPqdB3D_JDeXYhwKmtf2sPeS0PEgHI8BhOFPxiVgR4mmDhhgqClwNJrXPgD-ayU1P0fodLS1bnFVn7iukMzICo-pi5wJvXUrGQ2th7yQp_NpZiQzeNfmQIp_sFyluIA1P_PnoMwMPjnKgKe7jsuWcLPwaGYMpZ3NF58iCdPV2LxSlwLvnaJ_iDCZKyF50jS5ot_tC-OQ-g96kPU8NkKiD1aPkwvzNj9azf_UIdyBLLazkwwtSa6emAEm5lcKJF69RwB_oXABhqdfVnNa6-BU2Q2FwKadryBfP899lzJJFf4CxuZ2WV3piQk131IMRTZYSPgsIZwjbGT72lvmjw29QzywT_scbv_NEPqO_o2b4AlRp_jK9y65XDCdGULgepuWAIUc0RM__gA7QX3RLXCSzJqByAsoSDRzjT1yrJBrNc9nKzZKGNPqVVKvU_1-J1HWSIULQPHEbLJCrqoTVOijNUZB2b6vR25x3r-SNG-cLolAFIB_3m_YOEYKVcBQjqebDfXR4RKYS_rfw--Kkgv4qQ8XAB600D1vbYG1rbuex6uDZZk24BdYbymPHrUvLZRlHRGwT_-OS_9bRvenAPoatCY7pif4w2dDHp8pSL5dxsjGQl5MztYNN1Pe-zLAUeS3wl-mEj4OWVantdyUy8jC3kbjJEbeqnz-cEMl2O7fJS9qYLyG9xkpCXlu4HDPY-ex_XeRTQ_WHTr0QGJ81OGi-RY2pM7SKgu_lWcD5mB0Xb4us-pslPFGcSL8d6kIqZ2q2rzZXvXPNxuqPK4L3c5-iCDuXMEcZrPbRA=",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gbl9xLqRCcQZTTLLdE5rmjm46AooJGiAQYEIMHpyvN58J3I_uKUzA0e1se8GiByeHyhu2p4HZgJAZE8UoNrHNRxFuOFKIMDtloX1acVRP_Hnufpm5pVPFFGSFtVEpn_Gkit4LoXyuMwFTb3jEyIJHSNCISYFMbtnieDSKYraATPCjlNoaQuJAJ_uY0dDGz0zB80r0MkRh3gECSsGiFHFK6exgKjHmEUhblTF4RxpzgcP_aJJzRbLzhfNRxrur4oUSx5u23bEunoOiGgraWOzn7HvzECTfZ7WLVUWnJcsccaTSMGLSyUrsnsJ6BMAhuXg1WHLumFs6-eV5Bfw96vDYwQqr4mQSvdcBFNyD7x8uhNzW2UzXzpaMEShgs-1lvmW_huK312BBtnPqdB3D_JDeXYhwKmtf2sPeS0PEgHI8BhOFPxiVgR4mmDhhgqClwNJrXPgD-ayU1P0fodLS1bnFVn7iukMzICo-pi5wJvXUrGQ2th7yQp_NpZiQzeNfmQIp_sFyluIA1P_PnoMwMPjnKgKe7jsuWcLPwaGYMpZ3NF58iCdPV2LxSlwLvnaJ_iDCZKyF50jS5ot_tC-OQ-g96kPU8NkKiD1aPkwvzNj9azf_UIdyBLLazkwwtSa6emAEm5lcKJF69RwB_oXABhqdfVnNa6-BU2Q2FwKadryBfP899lzJJFf4CxuZ2WV3piQk131IMRTZYSPgsIZwjbGT72lvmjw29QzywT_scbv_NEPqO_o2b4AlRp_jK9y65XDCdGULgepuWAIUc0RM__gA7QX3RLXCSzJqByAsoSDRzjT1yrJBrNc9nKzZKGNPqVVKvU_1-J1HWSIULQPHEbLJCrqoTVOijNUZB2b6vR25x3r-SNG-cLolAFIB_3m_YOEYKVcBQjqebDfXR4RKYS_rfw--Kkgv4qQ8XAB600D1vbYG1rbuex6uDZZk24BdYbymPHrUvLZRlHRGwT_-OS_9bRvenAPoatCY7pif4w2dDHp8pSL5dxsjGQl5MztYNN1Pe-zLAUeS3wl-mEj4OWVantdyUy8jC3kbjJEbeqnz-cEMl2O7fJS9qYLyG9xkpCXlu4HDPY-ex_XeRTQ_WHTr0QGJ81OGi-RY2pM7SKgu_lWcD5mB0Xb4us-pslPFGcSL8d6kIqZ2q2rzZXvXPNxuqPK4L3c5-iCDuXMEcZrPbRA=",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000239c987e",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000239c987e_0",
                    "local_arrival": "2024-10-01T18:55:00.000Z",
                    "local_departure": "2024-10-01T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-01T16:55:00.000Z",
                    "utc_departure": "2024-10-01T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000239c987e_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gbl9xLqRCcQZTTLLdE5rmjm46AooJGiAQYEIMHpyvN58J3I_uKUzA0e1se8GiByeHyhu2p4HZgJAZE8UoNrHNRxFuOFKIMDtloX1acVRP_Hnufpm5pVPFFGSFtVEpn_Gkit4LoXyuMwFTb3jEyIJHSNCISYFMbtnieDSKYraATPCjlNoaQuJAJ_uY0dDGz0zB80r0MkRh3gECSsGiFHFK6exgKjHmEUhblTF4RxpzgcP_aJJzRbLzhfNRxrur4oUSx5u23bEunoOiGgraWOzn7HvzECTfZ7WLVUWnJcsccaTSMGLSyUrsnsJ6BMAhuXg1WHLumFs6-eV5Bfw96vDYwQqr4mQSvdcBFNyD7x8uhNzW2UzXzpaMEShgs-1lvmW_huK312BBtnPqdB3D_JDeXYhwKmtf2sPeS0PEgHI8BhOFPxiVgR4mmDhhgqClwNJrXPgD-ayU1P0fodLS1bnFVn7iukMzICo-pi5wJvXUrGQ2th7yQp_NpZiQzeNfmQIp_sFyluIA1P_PnoMwMPjnKgKe7jsuWcLPwaGYMpZ3NF58iCdPV2LxSlwLvnaJ_iDCZKyF50jS5ot_tC-OQ-g96kPU8NkKiD1aPkwvzNj9azf_UIdyBLLazkwwtSa6emAEm5lcKJF69RwB_oXABhqdfVnNa6-BU2Q2FwKadryBfP899lzJJFf4CxuZ2WV3piQk131IMRTZYSPgsIZwjbGT72lvmjw29QzywT_scbv_NEPqO_o2b4AlRp_jK9y65XDCdGULgepuWAIUc0RM__gA7QX3RLXCSzJqByAsoSDRzjT1yrJBrNc9nKzZKGNPqVVKvU_1-J1HWSIULQPHEbLJCrqoTVOijNUZB2b6vR25x3r-SNG-cLolAFIB_3m_YOEYKVcBQjqebDfXR4RKYS_rfw--Kkgv4qQ8XAB600D1vbYG1rbuex6uDZZk24BdYbymPHrUvLZRlHRGwT_-OS_9bRvenAPoatCY7pif4w2dDHp8pSL5dxsjGQl5MztYNN1Pe-zLAUeS3wl-mEj4OWVantdyUy8jC3kbjJEbeqnz-cEMl2O7fJS9qYLyG9xkpCXlu4HDPY-ex_XeRTQ_WHTr0QGJ81OGi-RY2pM7SKgu_lWcD5mB0Xb4us-pslPFGcSL8d6kIqZ2q2rzZXvXPNxuqPK4L3c5-iCDuXMEcZrPbRA=",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1167.23,
            "totalPrice": 1069
          },
          {
            "allPassengerTotalPrice": 1071,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvJBZ82Ry3fz9RRaAeNr-ZtOsQWRzk6_oen3LaH0WHj7CTAifjc-c_hNKV9WwvcKsw0-IoUA3fKMH_dd90GiUtHlhJ1mhdDdTQ6KgZd1elTjQnYRudFNHSNMs4x0gguuukoZ7E-GriTwZoGYfIQVJmsPv4NuQRpgmT-hQpQ6oVSCiBpvK1pPi_ECXrEDM1aeEBwVJfuJoqRYVVeKhtp1cLOLQHIGr9bbvfxoMqhlEhEaD94AvNO8q82uax7pM0OHWDaB7KZStesH1u2rcsNxwoVoWa-jcnlxe226ijC1PT6j8vz9Lz1fpjnVldPzQCWiPR-xywGM7o61EmTZFE5OxGLSWKcD5ORwwny7_25Ej4QAAM0_mZhu7btJfB6CRSDneEAZIORdeWUMQstZRGxKCJb8cvqkCuGzpWb53RJqTs4FKwOi3N64tfG3IuT89_xfV0IOK5GXHM7o1qzf9apKavd_qhrQGBGCHvI9SZtYf2lvrWX5su3JQEkonUHgVZGcwQumffvj6JgGErvcu_q7CzJHuTjv2Q4FytQapIStFpP-GsmXkffBm4loVNXvoJb_Hla77zRMZ4MScKlWKblOHoeFzthXJPujsF9A92jwWoyMr06EaoHV1QKGq4oFn2ineMTmfYaDjdNn7S0CcT9PWPsAJSd22Wc5dnVoqr4LC3HWNuVzlB2v__d1yC1MYCNgquFQpfif9tljjeiby_qRkVyLjPRhKXaqYOipGP8jpJwZf7HOsBcZntnfDQMHe2voRS0rULIWX_ltWPv9YvolErifiNjRzCD7-iZBXpZ-Hd3I8shK155O0WxdegJcK9rkmTXQDPK6AQPqd8rDrf8kV5fHaAt4TttzBz8RO5blQ4gSlcuDDNyAQyEhBhzN13NUob68shbi9ucnfV4Geqz74wLpD4nQ1GPVXZNXPnraNFyV05wNV5yOr8fRF2qUxGtW_Z9HQpllI-ccg1Fa6Tbs5_k3I25v8bo466eoVyql0U4102PoH5qVC4_cyP6eWuoWGHlZVPPfWQ95IfHr0LiDarsV69En9Uwg-JvHeTBhQPieNtBYguAZoiNoK1V_Pc4n5aks8yONj1J8Ubt2MsVPWq23gfbBG5NLV4BP6wIj9LE8d4esHCZZznKHZElfgFwtjdnApUJleQiVO-WeM50YCwCxxY8Pf3J71yJOs1Wuj-yW9uU3RrVbpBqYCQbJLThUO",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvJBZ82Ry3fz9RRaAeNr-ZtOsQWRzk6_oen3LaH0WHj7CTAifjc-c_hNKV9WwvcKsw0-IoUA3fKMH_dd90GiUtHlhJ1mhdDdTQ6KgZd1elTjQnYRudFNHSNMs4x0gguuukoZ7E-GriTwZoGYfIQVJmsPv4NuQRpgmT-hQpQ6oVSCiBpvK1pPi_ECXrEDM1aeEBwVJfuJoqRYVVeKhtp1cLOLQHIGr9bbvfxoMqhlEhEaD94AvNO8q82uax7pM0OHWDaB7KZStesH1u2rcsNxwoVoWa-jcnlxe226ijC1PT6j8vz9Lz1fpjnVldPzQCWiPR-xywGM7o61EmTZFE5OxGLSWKcD5ORwwny7_25Ej4QAAM0_mZhu7btJfB6CRSDneEAZIORdeWUMQstZRGxKCJb8cvqkCuGzpWb53RJqTs4FKwOi3N64tfG3IuT89_xfV0IOK5GXHM7o1qzf9apKavd_qhrQGBGCHvI9SZtYf2lvrWX5su3JQEkonUHgVZGcwQumffvj6JgGErvcu_q7CzJHuTjv2Q4FytQapIStFpP-GsmXkffBm4loVNXvoJb_Hla77zRMZ4MScKlWKblOHoeFzthXJPujsF9A92jwWoyMr06EaoHV1QKGq4oFn2ineMTmfYaDjdNn7S0CcT9PWPsAJSd22Wc5dnVoqr4LC3HWNuVzlB2v__d1yC1MYCNgquFQpfif9tljjeiby_qRkVyLjPRhKXaqYOipGP8jpJwZf7HOsBcZntnfDQMHe2voRS0rULIWX_ltWPv9YvolErifiNjRzCD7-iZBXpZ-Hd3I8shK155O0WxdegJcK9rkmTXQDPK6AQPqd8rDrf8kV5fHaAt4TttzBz8RO5blQ4gSlcuDDNyAQyEhBhzN13NUob68shbi9ucnfV4Geqz74wLpD4nQ1GPVXZNXPnraNFyV05wNV5yOr8fRF2qUxGtW_Z9HQpllI-ccg1Fa6Tbs5_k3I25v8bo466eoVyql0U4102PoH5qVC4_cyP6eWuoWGHlZVPPfWQ95IfHr0LiDarsV69En9Uwg-JvHeTBhQPieNtBYguAZoiNoK1V_Pc4n5aks8yONj1J8Ubt2MsVPWq23gfbBG5NLV4BP6wIj9LE8d4esHCZZznKHZElfgFwtjdnApUJleQiVO-WeM50YCwCxxY8Pf3J71yJOs1Wuj-yW9uU3RrVbpBqYCQbJLThUO",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d000083af4f87",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d000083af4f87_0",
                    "local_arrival": "2024-10-01T22:20:00.000Z",
                    "local_departure": "2024-10-01T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-01T20:20:00.000Z",
                    "utc_departure": "2024-10-01T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000083af4f87_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GvJBZ82Ry3fz9RRaAeNr-ZtOsQWRzk6_oen3LaH0WHj7CTAifjc-c_hNKV9WwvcKsw0-IoUA3fKMH_dd90GiUtHlhJ1mhdDdTQ6KgZd1elTjQnYRudFNHSNMs4x0gguuukoZ7E-GriTwZoGYfIQVJmsPv4NuQRpgmT-hQpQ6oVSCiBpvK1pPi_ECXrEDM1aeEBwVJfuJoqRYVVeKhtp1cLOLQHIGr9bbvfxoMqhlEhEaD94AvNO8q82uax7pM0OHWDaB7KZStesH1u2rcsNxwoVoWa-jcnlxe226ijC1PT6j8vz9Lz1fpjnVldPzQCWiPR-xywGM7o61EmTZFE5OxGLSWKcD5ORwwny7_25Ej4QAAM0_mZhu7btJfB6CRSDneEAZIORdeWUMQstZRGxKCJb8cvqkCuGzpWb53RJqTs4FKwOi3N64tfG3IuT89_xfV0IOK5GXHM7o1qzf9apKavd_qhrQGBGCHvI9SZtYf2lvrWX5su3JQEkonUHgVZGcwQumffvj6JgGErvcu_q7CzJHuTjv2Q4FytQapIStFpP-GsmXkffBm4loVNXvoJb_Hla77zRMZ4MScKlWKblOHoeFzthXJPujsF9A92jwWoyMr06EaoHV1QKGq4oFn2ineMTmfYaDjdNn7S0CcT9PWPsAJSd22Wc5dnVoqr4LC3HWNuVzlB2v__d1yC1MYCNgquFQpfif9tljjeiby_qRkVyLjPRhKXaqYOipGP8jpJwZf7HOsBcZntnfDQMHe2voRS0rULIWX_ltWPv9YvolErifiNjRzCD7-iZBXpZ-Hd3I8shK155O0WxdegJcK9rkmTXQDPK6AQPqd8rDrf8kV5fHaAt4TttzBz8RO5blQ4gSlcuDDNyAQyEhBhzN13NUob68shbi9ucnfV4Geqz74wLpD4nQ1GPVXZNXPnraNFyV05wNV5yOr8fRF2qUxGtW_Z9HQpllI-ccg1Fa6Tbs5_k3I25v8bo466eoVyql0U4102PoH5qVC4_cyP6eWuoWGHlZVPPfWQ95IfHr0LiDarsV69En9Uwg-JvHeTBhQPieNtBYguAZoiNoK1V_Pc4n5aks8yONj1J8Ubt2MsVPWq23gfbBG5NLV4BP6wIj9LE8d4esHCZZznKHZElfgFwtjdnApUJleQiVO-WeM50YCwCxxY8Pf3J71yJOs1Wuj-yW9uU3RrVbpBqYCQbJLThUO",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1168.64,
            "totalPrice": 1071
          },
          {
            "allPassengerTotalPrice": 1085,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-22",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-8wqO_sLTX7rTnDLdPcoXN95uw219JyfgA7OPm1w57drWbbeFnLgYO8vPZ5Hpk_aJmyJXu9uAcpyNkhxtPwhNDDHP8ZN_A6fxcFhKnalu-nDpO6lediyu33jWH-zCEv7_KnscYzREDeU3pmXKPQF7qOEaUJ5y0ZazAmn2h4zPfj6mSkWgObXFOV8SwHfmJNTM55vPxZeAexey2oeKAKksgNIvW1vIlXvggnh3cUzWaQ6dbhqNqMbDYIEqNNk_aqInNAFFX35XYagow4uKj9EOa3GckNPnbv9RV1XQfJWanjFMLh_D2-86Aa7fpTlqJ4ylOWoaaj_un98FGAGbxKoMmZtinh5wx284DhLad1VZBUxf4ZiHy2oRmJ2oRSqxf-MAXU8Lb_12kGOHCKE95yWy_nupK74kEysPZh4kph0xponzMDg1wI-XcIfO6r-J4lNViSa6MgJIeGWTBCTnTzimgKH9OHD-cR9Pkilr0lyiftLO6Mc72tOUjv2IZCAK9e4dnum-5fex_5pPv4EC6NRjYzVFeoBfLBVYm6_CbyVVBEgA13k3yf3R2kCS__WdYSz4N30mYTK4GTWWvsOXYgRxJgvEUIxTwjLskLBUm1RJ9MyfHLAHKH_aZo7KQ7DsyIceafamIEyNXlCXFPMFOZmWkYmqom0K2quEdC7kjsGTCOPZQxYw8A0LLqXu7AwbJW5tCy5CMyJQGf6i-qwh7OV2fjKHgB8g_MTPCQdQXrqUZskSpVm6us3euRkNiNtnvDwb3rz7pirZrfVDQdr9vFXOD-zqPx8ZkEsLjRK6lkWe-4lmuITx8KoluR9DOgL-0lz29V9qio-7UzROHxnyFQ8RfI2x4js9aVsJ8VocSz5cdiQtSGW0468SfocpbmmoVsN5BhzmeLCFR2N9tz5UHUqAPslRdWVf3pM_EDTH5tr_mdDt9ZRM4JN4XL_qVGW0120YWp_TAaUWKEsN5WRoD_n590BpMv9tsqGOnRWw5yBKp7LndEFxPGrrj-2-Iyv7TQbaHJJbd513i2zIOfrv4dh5X97E0MBuNt7BXXNSuWKsRMEwmAyIxVCyLOJ_iUEtnAXaPUQMtZmZYYHWhihMW-qcv8aPXWDiFDZITgmKjpqCrehiIOFjT2R_yDOQdEuU7nCXXUJV6fCAFnNw1bgBhJZ8lZGPal5NAmMYrh2_1yYBebVOcoFmtdWjSdvjBz66fnq",
                "dateChange": 1,
                "departureDate": "2024-09-21",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e130000b8df6af8",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e130000b8df6af8_0",
                    "local_arrival": "2024-09-22T17:00:00.000Z",
                    "local_departure": "2024-09-21T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-22T15:00:00.000Z",
                    "utc_departure": "2024-09-22T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-01",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-8wqO_sLTX7rTnDLdPcoXN95uw219JyfgA7OPm1w57drWbbeFnLgYO8vPZ5Hpk_aJmyJXu9uAcpyNkhxtPwhNDDHP8ZN_A6fxcFhKnalu-nDpO6lediyu33jWH-zCEv7_KnscYzREDeU3pmXKPQF7qOEaUJ5y0ZazAmn2h4zPfj6mSkWgObXFOV8SwHfmJNTM55vPxZeAexey2oeKAKksgNIvW1vIlXvggnh3cUzWaQ6dbhqNqMbDYIEqNNk_aqInNAFFX35XYagow4uKj9EOa3GckNPnbv9RV1XQfJWanjFMLh_D2-86Aa7fpTlqJ4ylOWoaaj_un98FGAGbxKoMmZtinh5wx284DhLad1VZBUxf4ZiHy2oRmJ2oRSqxf-MAXU8Lb_12kGOHCKE95yWy_nupK74kEysPZh4kph0xponzMDg1wI-XcIfO6r-J4lNViSa6MgJIeGWTBCTnTzimgKH9OHD-cR9Pkilr0lyiftLO6Mc72tOUjv2IZCAK9e4dnum-5fex_5pPv4EC6NRjYzVFeoBfLBVYm6_CbyVVBEgA13k3yf3R2kCS__WdYSz4N30mYTK4GTWWvsOXYgRxJgvEUIxTwjLskLBUm1RJ9MyfHLAHKH_aZo7KQ7DsyIceafamIEyNXlCXFPMFOZmWkYmqom0K2quEdC7kjsGTCOPZQxYw8A0LLqXu7AwbJW5tCy5CMyJQGf6i-qwh7OV2fjKHgB8g_MTPCQdQXrqUZskSpVm6us3euRkNiNtnvDwb3rz7pirZrfVDQdr9vFXOD-zqPx8ZkEsLjRK6lkWe-4lmuITx8KoluR9DOgL-0lz29V9qio-7UzROHxnyFQ8RfI2x4js9aVsJ8VocSz5cdiQtSGW0468SfocpbmmoVsN5BhzmeLCFR2N9tz5UHUqAPslRdWVf3pM_EDTH5tr_mdDt9ZRM4JN4XL_qVGW0120YWp_TAaUWKEsN5WRoD_n590BpMv9tsqGOnRWw5yBKp7LndEFxPGrrj-2-Iyv7TQbaHJJbd513i2zIOfrv4dh5X97E0MBuNt7BXXNSuWKsRMEwmAyIxVCyLOJ_iUEtnAXaPUQMtZmZYYHWhihMW-qcv8aPXWDiFDZITgmKjpqCrehiIOFjT2R_yDOQdEuU7nCXXUJV6fCAFnNw1bgBhJZ8lZGPal5NAmMYrh2_1yYBebVOcoFmtdWjSdvjBz66fnq",
                "dateChange": 0,
                "departureDate": "2024-10-01",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e1d0000cd92f952",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e1d0000cd92f952_0",
                    "local_arrival": "2024-10-01T10:25:00.000Z",
                    "local_departure": "2024-10-01T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-01T08:25:00.000Z",
                    "utc_departure": "2024-10-01T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "AF",
                  "AF"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "12:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d0000cd92f952_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e244e29517b76c5_0%7C01af0f9c4e244e29517b76c5_1%7C01af0f9c4e244e29517b76c5_2%7C01af0f9c4e244e29517b76c5_3&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G-8wqO_sLTX7rTnDLdPcoXN95uw219JyfgA7OPm1w57drWbbeFnLgYO8vPZ5Hpk_aJmyJXu9uAcpyNkhxtPwhNDDHP8ZN_A6fxcFhKnalu-nDpO6lediyu33jWH-zCEv7_KnscYzREDeU3pmXKPQF7qOEaUJ5y0ZazAmn2h4zPfj6mSkWgObXFOV8SwHfmJNTM55vPxZeAexey2oeKAKksgNIvW1vIlXvggnh3cUzWaQ6dbhqNqMbDYIEqNNk_aqInNAFFX35XYagow4uKj9EOa3GckNPnbv9RV1XQfJWanjFMLh_D2-86Aa7fpTlqJ4ylOWoaaj_un98FGAGbxKoMmZtinh5wx284DhLad1VZBUxf4ZiHy2oRmJ2oRSqxf-MAXU8Lb_12kGOHCKE95yWy_nupK74kEysPZh4kph0xponzMDg1wI-XcIfO6r-J4lNViSa6MgJIeGWTBCTnTzimgKH9OHD-cR9Pkilr0lyiftLO6Mc72tOUjv2IZCAK9e4dnum-5fex_5pPv4EC6NRjYzVFeoBfLBVYm6_CbyVVBEgA13k3yf3R2kCS__WdYSz4N30mYTK4GTWWvsOXYgRxJgvEUIxTwjLskLBUm1RJ9MyfHLAHKH_aZo7KQ7DsyIceafamIEyNXlCXFPMFOZmWkYmqom0K2quEdC7kjsGTCOPZQxYw8A0LLqXu7AwbJW5tCy5CMyJQGf6i-qwh7OV2fjKHgB8g_MTPCQdQXrqUZskSpVm6us3euRkNiNtnvDwb3rz7pirZrfVDQdr9vFXOD-zqPx8ZkEsLjRK6lkWe-4lmuITx8KoluR9DOgL-0lz29V9qio-7UzROHxnyFQ8RfI2x4js9aVsJ8VocSz5cdiQtSGW0468SfocpbmmoVsN5BhzmeLCFR2N9tz5UHUqAPslRdWVf3pM_EDTH5tr_mdDt9ZRM4JN4XL_qVGW0120YWp_TAaUWKEsN5WRoD_n590BpMv9tsqGOnRWw5yBKp7LndEFxPGrrj-2-Iyv7TQbaHJJbd513i2zIOfrv4dh5X97E0MBuNt7BXXNSuWKsRMEwmAyIxVCyLOJ_iUEtnAXaPUQMtZmZYYHWhihMW-qcv8aPXWDiFDZITgmKjpqCrehiIOFjT2R_yDOQdEuU7nCXXUJV6fCAFnNw1bgBhJZ8lZGPal5NAmMYrh2_1yYBebVOcoFmtdWjSdvjBz66fnq",
                "dateChange": 1,
                "departureDate": "2024-10-07",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "22h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e230000e00d2993",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e230000e00d2993_0",
                    "local_arrival": "2024-10-08T00:35:00.000Z",
                    "local_departure": "2024-10-07T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-07T22:35:00.000Z",
                    "utc_departure": "2024-10-07T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "PAR",
                    "cityFrom": "Barcelona",
                    "cityTo": "Paris",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "L",
                    "flight_no": 1449,
                    "flyFrom": "BCN",
                    "flyTo": "CDG",
                    "guarantee": true,
                    "id": "01af0f9c4e244e29517b76c5_0",
                    "local_arrival": "2024-10-08T08:05:00.000Z",
                    "local_departure": "2024-10-08T06:10:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "1449",
                    "return": 0,
                    "utc_arrival": "2024-10-08T06:05:00.000Z",
                    "utc_departure": "2024-10-08T04:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AF",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "PAR",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Paris",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e244e29517b76c5",
                    "equipment": null,
                    "fare_basis": "TYK6TALA",
                    "fare_category": "M",
                    "fare_classes": "T",
                    "flight_no": 84,
                    "flyFrom": "CDG",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e244e29517b76c5_1",
                    "local_arrival": "2024-10-08T12:45:00.000Z",
                    "local_departure": "2024-10-08T10:15:00.000Z",
                    "operating_carrier": "AF",
                    "operating_flight_no": "84",
                    "return": 0,
                    "utc_arrival": "2024-10-08T19:45:00.000Z",
                    "utc_departure": "2024-10-08T08:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1179.43,
            "totalPrice": 1085
          }
        ]
      },
      {
        "dateOptionLabel": "2024-09-28 to 2024-10-14",
        "itineraries": [
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GG75-7F9fYAt7AfxJ4fkwEn8fexSL2sahPOr7899cmLhk7vayh1SG12coJydz8bt_6qDENfhPeBrsD67qF0rZzt5mAm79XIA_nzEDWqVfiN1ObNf_SThE8YIbY0Cv32EDHGZznm2XzvnWcTCNHGMSaL_5EF7Ue6SgPWM0bgwdd-m-5J8_SPOtBzYTmRnrzhuvB0017V2f46ULySQnBAoaobvDtHufMluQwLMB3srt_ixex-fkfxOmtFs7zT3IuszjIbjhlGJfzsNnytSqQX7Z61OK4npcbsPMij5zaQv1YYGsB5I5NWmlz8AC-bC68aI7bMoNKvTYsMzSOPl1hsaqsmHn7ZmbslgLFMsK5tPt7XLmNzRLavItEBFAxEzAw1NWstQ5345KN4IMBvl96rJU70u9_DvjG30eRrQQT-FMq0scOXP0M45E674ycdya0aw7MeKslzxA2sGEfHSwng59G7zjfxWoWdAU_gCijHKd7Aic86kH5JH9W9G5tytDKahh9mwT4pQhSp22snDL8Rv-rUyEEy4MKg55fDJonH69G98avQoKiD8yuO-8PPCaSXe8CbymJaCfJO6OjTvsgZfdU-VvspKk-SrJa4XuMCmJxb5vq1ZIb0Mbp_UVx17MSIAlacR0LTfj4pRRcOIuNw6E9e-E9bCPoDA8qp8_IvYKIuuRm-43l3X-u9VuHIwkIDj9SrmSgsJPVW2RrGe-qXeBdwz8b3E5lTOyKmtgD3ZvssRcGakQ22EsNVfBQd8oBA8ia1_87Wcl9jqgORzLhX4J3Ah9OzIP2UtHL0ZVtI4nuiITLzfT07dyYvsXbz35Ni5I01aSFGjMa_ePR_pQSYjP4vQvIrYwdE1MmOw1XuRnKRAhDH6KdNRbeepPr9zocoeRLzWW-e8m7EXySS4oqtI2rXGozkpwY8pCgUPQ92Asi30dlG_NE0XWTujWOX4f5J8WAsUWZLHMexxttEE7m-1BdrTFjQJHobFhSsBV2Rahez8GMx8YWo2-4FDMl2CKOrD2CqKYFYn2KsNJeJN9cESbJ0KjBbvo9jIqECmbOE9QsjDZwItwuKyNhIj6QzWgYSTa6Jjfba1Iwj1zXCu6OQ1SOX1tPX7HFP6F2df4lGuDVUSZR1z2kNSDNYDMVd2SJG48HllLT6lHn9tikOyqe63lexRzymGLzociOZrY6-6w9t-0ZSRH08xEGjlqI8cDWDh9le9V-sDtV21YiMmCYEe1-hqH16pDlhYJptmvVzArLkdHNDGeCZOBVOzRB3rBOgVugIc-0eCGvxX2Hr_dvP_BMK18M7qfywihwOyMWf4YGH7bPcUQ_zpq6Qp5jaMZQt9nr0o0EEnN6TlKkBRuSWYXfQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GG75-7F9fYAt7AfxJ4fkwEn8fexSL2sahPOr7899cmLhk7vayh1SG12coJydz8bt_6qDENfhPeBrsD67qF0rZzt5mAm79XIA_nzEDWqVfiN1ObNf_SThE8YIbY0Cv32EDHGZznm2XzvnWcTCNHGMSaL_5EF7Ue6SgPWM0bgwdd-m-5J8_SPOtBzYTmRnrzhuvB0017V2f46ULySQnBAoaobvDtHufMluQwLMB3srt_ixex-fkfxOmtFs7zT3IuszjIbjhlGJfzsNnytSqQX7Z61OK4npcbsPMij5zaQv1YYGsB5I5NWmlz8AC-bC68aI7bMoNKvTYsMzSOPl1hsaqsmHn7ZmbslgLFMsK5tPt7XLmNzRLavItEBFAxEzAw1NWstQ5345KN4IMBvl96rJU70u9_DvjG30eRrQQT-FMq0scOXP0M45E674ycdya0aw7MeKslzxA2sGEfHSwng59G7zjfxWoWdAU_gCijHKd7Aic86kH5JH9W9G5tytDKahh9mwT4pQhSp22snDL8Rv-rUyEEy4MKg55fDJonH69G98avQoKiD8yuO-8PPCaSXe8CbymJaCfJO6OjTvsgZfdU-VvspKk-SrJa4XuMCmJxb5vq1ZIb0Mbp_UVx17MSIAlacR0LTfj4pRRcOIuNw6E9e-E9bCPoDA8qp8_IvYKIuuRm-43l3X-u9VuHIwkIDj9SrmSgsJPVW2RrGe-qXeBdwz8b3E5lTOyKmtgD3ZvssRcGakQ22EsNVfBQd8oBA8ia1_87Wcl9jqgORzLhX4J3Ah9OzIP2UtHL0ZVtI4nuiITLzfT07dyYvsXbz35Ni5I01aSFGjMa_ePR_pQSYjP4vQvIrYwdE1MmOw1XuRnKRAhDH6KdNRbeepPr9zocoeRLzWW-e8m7EXySS4oqtI2rXGozkpwY8pCgUPQ92Asi30dlG_NE0XWTujWOX4f5J8WAsUWZLHMexxttEE7m-1BdrTFjQJHobFhSsBV2Rahez8GMx8YWo2-4FDMl2CKOrD2CqKYFYn2KsNJeJN9cESbJ0KjBbvo9jIqECmbOE9QsjDZwItwuKyNhIj6QzWgYSTa6Jjfba1Iwj1zXCu6OQ1SOX1tPX7HFP6F2df4lGuDVUSZR1z2kNSDNYDMVd2SJG48HllLT6lHn9tikOyqe63lexRzymGLzociOZrY6-6w9t-0ZSRH08xEGjlqI8cDWDh9le9V-sDtV21YiMmCYEe1-hqH16pDlhYJptmvVzArLkdHNDGeCZOBVOzRB3rBOgVugIc-0eCGvxX2Hr_dvP_BMK18M7qfywihwOyMWf4YGH7bPcUQ_zpq6Qp5jaMZQt9nr0o0EEnN6TlKkBRuSWYXfQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GG75-7F9fYAt7AfxJ4fkwEn8fexSL2sahPOr7899cmLhk7vayh1SG12coJydz8bt_6qDENfhPeBrsD67qF0rZzt5mAm79XIA_nzEDWqVfiN1ObNf_SThE8YIbY0Cv32EDHGZznm2XzvnWcTCNHGMSaL_5EF7Ue6SgPWM0bgwdd-m-5J8_SPOtBzYTmRnrzhuvB0017V2f46ULySQnBAoaobvDtHufMluQwLMB3srt_ixex-fkfxOmtFs7zT3IuszjIbjhlGJfzsNnytSqQX7Z61OK4npcbsPMij5zaQv1YYGsB5I5NWmlz8AC-bC68aI7bMoNKvTYsMzSOPl1hsaqsmHn7ZmbslgLFMsK5tPt7XLmNzRLavItEBFAxEzAw1NWstQ5345KN4IMBvl96rJU70u9_DvjG30eRrQQT-FMq0scOXP0M45E674ycdya0aw7MeKslzxA2sGEfHSwng59G7zjfxWoWdAU_gCijHKd7Aic86kH5JH9W9G5tytDKahh9mwT4pQhSp22snDL8Rv-rUyEEy4MKg55fDJonH69G98avQoKiD8yuO-8PPCaSXe8CbymJaCfJO6OjTvsgZfdU-VvspKk-SrJa4XuMCmJxb5vq1ZIb0Mbp_UVx17MSIAlacR0LTfj4pRRcOIuNw6E9e-E9bCPoDA8qp8_IvYKIuuRm-43l3X-u9VuHIwkIDj9SrmSgsJPVW2RrGe-qXeBdwz8b3E5lTOyKmtgD3ZvssRcGakQ22EsNVfBQd8oBA8ia1_87Wcl9jqgORzLhX4J3Ah9OzIP2UtHL0ZVtI4nuiITLzfT07dyYvsXbz35Ni5I01aSFGjMa_ePR_pQSYjP4vQvIrYwdE1MmOw1XuRnKRAhDH6KdNRbeepPr9zocoeRLzWW-e8m7EXySS4oqtI2rXGozkpwY8pCgUPQ92Asi30dlG_NE0XWTujWOX4f5J8WAsUWZLHMexxttEE7m-1BdrTFjQJHobFhSsBV2Rahez8GMx8YWo2-4FDMl2CKOrD2CqKYFYn2KsNJeJN9cESbJ0KjBbvo9jIqECmbOE9QsjDZwItwuKyNhIj6QzWgYSTa6Jjfba1Iwj1zXCu6OQ1SOX1tPX7HFP6F2df4lGuDVUSZR1z2kNSDNYDMVd2SJG48HllLT6lHn9tikOyqe63lexRzymGLzociOZrY6-6w9t-0ZSRH08xEGjlqI8cDWDh9le9V-sDtV21YiMmCYEe1-hqH16pDlhYJptmvVzArLkdHNDGeCZOBVOzRB3rBOgVugIc-0eCGvxX2Hr_dvP_BMK18M7qfywihwOyMWf4YGH7bPcUQ_zpq6Qp5jaMZQt9nr0o0EEnN6TlKkBRuSWYXfQ==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1063.8,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyRRQ9SfVd6PsA71J4-UvAMLDjF2GHJw_yfNraESXPIYn8M6tj3lRUET6a0p83lUN7ElIKnry7IpI5bvHb98unT5Tca3pZTrDkK5qVvu4qJfWCHELSstiHToD93j5xrAlLVHCqRFJreHHgImmivsZMUoClSrnAwRSRZb5fkyhzqMMbqJfwwyAFgQm1UAv7H41iOg1z4b7_O4HvsZuHvvAJFO189aAcy4YTAThQuA_fpFYzG-5s4lRY0cTfujn2XhUzeuDgAP2nXmwtIMVqFQQeMz04FdO7o5zlV3WbUmD1Mu3ulD3PdrwfSafZOWbDg1fWSYUOFqOkM8otf66tUcyzgpogTVK2pUWuA69h3u0ahKl9WDBi84YcR_WALkDjL-pOUlbTFR7g4fR6lcYLjW6s-mDk-x2nc4QZf_d1d7r3wqlajm634BOynrRJ9FdGRqGoTcB9ipKOZlNO3_yUGe157e84aK5jMdscDLBKP-1JsW_PtplRJMqUn_Hhbo3aqlU2HSIVk7Ga-552MreA9GvBtkom1s1ib16DfHLeKitds-B3g_Xk1azVCApi5A1HvLI9oOSON9AIr-bUsYHPC-6tgIJOY_ZRJs60Mxt7Or0ylNl-KQl6D_jAHx4MK1H0TRCOjuRZbxbdQxbH0OiHfAjWav1Wd9WfujHaTpSsJxRUHBxIDAZ1xhsZyk97XLivQwdc0fJzPdl2BqWG6cEb6BlRmJhPb4BUzdzWAub1PQYSsv9tXAIfvQ058oy7JVwmaGikgzaBngKeXEaP7aikpytTgUV5E_xswdx4Xu7-bn3ReytbKWWE7FI-16ueIhrn3KLSz_rU_cw0ovPuOzSozQnZaMSDPeUKETs8gAiBdvyeUkyafjjNpg6_mVZ9pfwvdygOmmaaGm8OHtNphuAyBoHd-7UAROZLj33fgL5kmx5TQDD4E-GQrTorCRnk_lWhTY7rspqM0YN-xATGEHlIUKQxKAPw3H3NZfDv1VvvA8t30NUyYQLdoVQPoXHAuOPyhS5HDMGfrs9R21ED-UcJRVHistPq_oPBdpkhBBapAEgsz2l4gGFuSOYBZTsspQlGNMBE9lSv8FK8b-J_n-2VRa31DpsrGBpVBZVuF7FD6wWgaAqCvStsyqeW4qO7bjd0-Z7-PHv0UjpLTQgIGY1aHaJKkRXDQZzh3rJFXiXHwVEtLnNE1oUrS_VH3Opp2RSpIU9sQ86TncUQyHcIUHQhWxC2XDtIX-Z5iPa2hIYkDyPABAEF1wnECfV4qzh4YoZDRbVkGi9K9hbObiFM4P07d265GpFuGvvfEAV3eCTwLYeOdsYZ569FAQKvzcJ4ixulu8xUa5aNnyIqRyts5OvAOsySA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyRRQ9SfVd6PsA71J4-UvAMLDjF2GHJw_yfNraESXPIYn8M6tj3lRUET6a0p83lUN7ElIKnry7IpI5bvHb98unT5Tca3pZTrDkK5qVvu4qJfWCHELSstiHToD93j5xrAlLVHCqRFJreHHgImmivsZMUoClSrnAwRSRZb5fkyhzqMMbqJfwwyAFgQm1UAv7H41iOg1z4b7_O4HvsZuHvvAJFO189aAcy4YTAThQuA_fpFYzG-5s4lRY0cTfujn2XhUzeuDgAP2nXmwtIMVqFQQeMz04FdO7o5zlV3WbUmD1Mu3ulD3PdrwfSafZOWbDg1fWSYUOFqOkM8otf66tUcyzgpogTVK2pUWuA69h3u0ahKl9WDBi84YcR_WALkDjL-pOUlbTFR7g4fR6lcYLjW6s-mDk-x2nc4QZf_d1d7r3wqlajm634BOynrRJ9FdGRqGoTcB9ipKOZlNO3_yUGe157e84aK5jMdscDLBKP-1JsW_PtplRJMqUn_Hhbo3aqlU2HSIVk7Ga-552MreA9GvBtkom1s1ib16DfHLeKitds-B3g_Xk1azVCApi5A1HvLI9oOSON9AIr-bUsYHPC-6tgIJOY_ZRJs60Mxt7Or0ylNl-KQl6D_jAHx4MK1H0TRCOjuRZbxbdQxbH0OiHfAjWav1Wd9WfujHaTpSsJxRUHBxIDAZ1xhsZyk97XLivQwdc0fJzPdl2BqWG6cEb6BlRmJhPb4BUzdzWAub1PQYSsv9tXAIfvQ058oy7JVwmaGikgzaBngKeXEaP7aikpytTgUV5E_xswdx4Xu7-bn3ReytbKWWE7FI-16ueIhrn3KLSz_rU_cw0ovPuOzSozQnZaMSDPeUKETs8gAiBdvyeUkyafjjNpg6_mVZ9pfwvdygOmmaaGm8OHtNphuAyBoHd-7UAROZLj33fgL5kmx5TQDD4E-GQrTorCRnk_lWhTY7rspqM0YN-xATGEHlIUKQxKAPw3H3NZfDv1VvvA8t30NUyYQLdoVQPoXHAuOPyhS5HDMGfrs9R21ED-UcJRVHistPq_oPBdpkhBBapAEgsz2l4gGFuSOYBZTsspQlGNMBE9lSv8FK8b-J_n-2VRa31DpsrGBpVBZVuF7FD6wWgaAqCvStsyqeW4qO7bjd0-Z7-PHv0UjpLTQgIGY1aHaJKkRXDQZzh3rJFXiXHwVEtLnNE1oUrS_VH3Opp2RSpIU9sQ86TncUQyHcIUHQhWxC2XDtIX-Z5iPa2hIYkDyPABAEF1wnECfV4qzh4YoZDRbVkGi9K9hbObiFM4P07d265GpFuGvvfEAV3eCTwLYeOdsYZ569FAQKvzcJ4ixulu8xUa5aNnyIqRyts5OvAOsySA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyRRQ9SfVd6PsA71J4-UvAMLDjF2GHJw_yfNraESXPIYn8M6tj3lRUET6a0p83lUN7ElIKnry7IpI5bvHb98unT5Tca3pZTrDkK5qVvu4qJfWCHELSstiHToD93j5xrAlLVHCqRFJreHHgImmivsZMUoClSrnAwRSRZb5fkyhzqMMbqJfwwyAFgQm1UAv7H41iOg1z4b7_O4HvsZuHvvAJFO189aAcy4YTAThQuA_fpFYzG-5s4lRY0cTfujn2XhUzeuDgAP2nXmwtIMVqFQQeMz04FdO7o5zlV3WbUmD1Mu3ulD3PdrwfSafZOWbDg1fWSYUOFqOkM8otf66tUcyzgpogTVK2pUWuA69h3u0ahKl9WDBi84YcR_WALkDjL-pOUlbTFR7g4fR6lcYLjW6s-mDk-x2nc4QZf_d1d7r3wqlajm634BOynrRJ9FdGRqGoTcB9ipKOZlNO3_yUGe157e84aK5jMdscDLBKP-1JsW_PtplRJMqUn_Hhbo3aqlU2HSIVk7Ga-552MreA9GvBtkom1s1ib16DfHLeKitds-B3g_Xk1azVCApi5A1HvLI9oOSON9AIr-bUsYHPC-6tgIJOY_ZRJs60Mxt7Or0ylNl-KQl6D_jAHx4MK1H0TRCOjuRZbxbdQxbH0OiHfAjWav1Wd9WfujHaTpSsJxRUHBxIDAZ1xhsZyk97XLivQwdc0fJzPdl2BqWG6cEb6BlRmJhPb4BUzdzWAub1PQYSsv9tXAIfvQ058oy7JVwmaGikgzaBngKeXEaP7aikpytTgUV5E_xswdx4Xu7-bn3ReytbKWWE7FI-16ueIhrn3KLSz_rU_cw0ovPuOzSozQnZaMSDPeUKETs8gAiBdvyeUkyafjjNpg6_mVZ9pfwvdygOmmaaGm8OHtNphuAyBoHd-7UAROZLj33fgL5kmx5TQDD4E-GQrTorCRnk_lWhTY7rspqM0YN-xATGEHlIUKQxKAPw3H3NZfDv1VvvA8t30NUyYQLdoVQPoXHAuOPyhS5HDMGfrs9R21ED-UcJRVHistPq_oPBdpkhBBapAEgsz2l4gGFuSOYBZTsspQlGNMBE9lSv8FK8b-J_n-2VRa31DpsrGBpVBZVuF7FD6wWgaAqCvStsyqeW4qO7bjd0-Z7-PHv0UjpLTQgIGY1aHaJKkRXDQZzh3rJFXiXHwVEtLnNE1oUrS_VH3Opp2RSpIU9sQ86TncUQyHcIUHQhWxC2XDtIX-Z5iPa2hIYkDyPABAEF1wnECfV4qzh4YoZDRbVkGi9K9hbObiFM4P07d265GpFuGvvfEAV3eCTwLYeOdsYZ569FAQKvzcJ4ixulu8xUa5aNnyIqRyts5OvAOsySA==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1063.8,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpWPm6nGrJzJOCC2TdMlroozN0VxbuAuY0myL-CnzZxR5ZK7qyQsf6D87NXIQ--jpH39gMLnbS8NniYRMzwj7BPBREuUP_uVqxvb5O1gTAg_ZnuDn1LKShNTewbJJ9IXgQ2NuLhdjmPsGELfOcEtxSW3h5l7-3GaRtbJLuQUCainRLI3iW3p3-ex2atnCICC1d97ET8s88-kJa4rc0tReUrWu040LeAevJ1kEeuEW3Wzlh55mjHDemo6XF6GHcrxXBJX1wkzzc9-tpmSCR5BjXktkh0BKxrZWNXgQKNBjCT5cF67lkqo8bKD8-CZrXRsd1TL_kqpCKiYAKnGO-VH59R5_Fu9j8hTsbnmj3b8SZUor8-OABzK4Y3YqAFboFuV8xKgvVQA5ghLiEDh70q0mpWk0JTELh2YBJd_fjhv2yYlenpVrtRP7kARns6p_ckRCHPORl7Zs6uMj_TFOERh9mzhv_g3dHeRM8MYxb28RiVplrPjK3Tc24afKcjuEa99BG8ku9Vr9fGInBLQ2aBTaud-aBJqn9Lx-1Ssf8w9vfMP6oOtuF40BpT3aaQ3kWaq1pTvEM3VHXxaqsVqJBQHlIWGCw2kpkiZhVqhHj_gnurpfeSxniri5m2wZoelR3WI_PpgXoE-vB0nhJWKRSNvp4hAz2ulIhQjSD10NLVDtX14G0peQQiNLEq-GJBo3s2F_PUUPrLWk8FcdcSZK-x1jYh2x97R22WOCarxIsqGdviyqGRA-5FTZdbPMvGZ1aXRhCPNrPS1cefXDIouA8okd2kQPLqwTwr0USZh-zp3HCLknnlsNjUw1wrLIF3mizwxbvpYdagEiQ3Zs2Q-FtbFMkIDfTdI_mC1Ppn_CPUUQfVWUBb_LkYycOwHpKAR1XXzxaBcuh5PXbQYpNiTYXanecaDlBsaXFFhtwFEgkk5V6kRDMEPr94uyzTNQZhNpVZErSsLU5VHE3YLwS4ag2wrZEDqSZLPkbHN1gtRRBcE2TpUykHgtgmILttNvFMejJtmqTagz0dHIyLKGJyjkmG_NUe_hsGGHiYY0xeZcvoeR9AWFTkUkb1V9w9oAF5kNGXrjM_Svgvk-Kg9RuQJzA3L6GhN5LiWRVL15iWvQOUlfyKfjqRc_rhioh3Jqwpzl34iZKlfsz0roeSGOGP9JVQ5HsNrRKYFeJaDFI4Kkdbr3NVFifPsrdpUq3uLJrr8rj46Y5toDtd-LY6g1aHRU1e4znBSrZRYMjy_F7mPdWxlUQma6IS4xR3UGeoPOZpWXjos9CTwYLumPYQDi5UeIhLgFbYsAfXER9LeWgJwqICCTFtWJ3oY1MMINg9tA7ipyv3Qg",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpWPm6nGrJzJOCC2TdMlroozN0VxbuAuY0myL-CnzZxR5ZK7qyQsf6D87NXIQ--jpH39gMLnbS8NniYRMzwj7BPBREuUP_uVqxvb5O1gTAg_ZnuDn1LKShNTewbJJ9IXgQ2NuLhdjmPsGELfOcEtxSW3h5l7-3GaRtbJLuQUCainRLI3iW3p3-ex2atnCICC1d97ET8s88-kJa4rc0tReUrWu040LeAevJ1kEeuEW3Wzlh55mjHDemo6XF6GHcrxXBJX1wkzzc9-tpmSCR5BjXktkh0BKxrZWNXgQKNBjCT5cF67lkqo8bKD8-CZrXRsd1TL_kqpCKiYAKnGO-VH59R5_Fu9j8hTsbnmj3b8SZUor8-OABzK4Y3YqAFboFuV8xKgvVQA5ghLiEDh70q0mpWk0JTELh2YBJd_fjhv2yYlenpVrtRP7kARns6p_ckRCHPORl7Zs6uMj_TFOERh9mzhv_g3dHeRM8MYxb28RiVplrPjK3Tc24afKcjuEa99BG8ku9Vr9fGInBLQ2aBTaud-aBJqn9Lx-1Ssf8w9vfMP6oOtuF40BpT3aaQ3kWaq1pTvEM3VHXxaqsVqJBQHlIWGCw2kpkiZhVqhHj_gnurpfeSxniri5m2wZoelR3WI_PpgXoE-vB0nhJWKRSNvp4hAz2ulIhQjSD10NLVDtX14G0peQQiNLEq-GJBo3s2F_PUUPrLWk8FcdcSZK-x1jYh2x97R22WOCarxIsqGdviyqGRA-5FTZdbPMvGZ1aXRhCPNrPS1cefXDIouA8okd2kQPLqwTwr0USZh-zp3HCLknnlsNjUw1wrLIF3mizwxbvpYdagEiQ3Zs2Q-FtbFMkIDfTdI_mC1Ppn_CPUUQfVWUBb_LkYycOwHpKAR1XXzxaBcuh5PXbQYpNiTYXanecaDlBsaXFFhtwFEgkk5V6kRDMEPr94uyzTNQZhNpVZErSsLU5VHE3YLwS4ag2wrZEDqSZLPkbHN1gtRRBcE2TpUykHgtgmILttNvFMejJtmqTagz0dHIyLKGJyjkmG_NUe_hsGGHiYY0xeZcvoeR9AWFTkUkb1V9w9oAF5kNGXrjM_Svgvk-Kg9RuQJzA3L6GhN5LiWRVL15iWvQOUlfyKfjqRc_rhioh3Jqwpzl34iZKlfsz0roeSGOGP9JVQ5HsNrRKYFeJaDFI4Kkdbr3NVFifPsrdpUq3uLJrr8rj46Y5toDtd-LY6g1aHRU1e4znBSrZRYMjy_F7mPdWxlUQma6IS4xR3UGeoPOZpWXjos9CTwYLumPYQDi5UeIhLgFbYsAfXER9LeWgJwqICCTFtWJ3oY1MMINg9tA7ipyv3Qg",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpWPm6nGrJzJOCC2TdMlroozN0VxbuAuY0myL-CnzZxR5ZK7qyQsf6D87NXIQ--jpH39gMLnbS8NniYRMzwj7BPBREuUP_uVqxvb5O1gTAg_ZnuDn1LKShNTewbJJ9IXgQ2NuLhdjmPsGELfOcEtxSW3h5l7-3GaRtbJLuQUCainRLI3iW3p3-ex2atnCICC1d97ET8s88-kJa4rc0tReUrWu040LeAevJ1kEeuEW3Wzlh55mjHDemo6XF6GHcrxXBJX1wkzzc9-tpmSCR5BjXktkh0BKxrZWNXgQKNBjCT5cF67lkqo8bKD8-CZrXRsd1TL_kqpCKiYAKnGO-VH59R5_Fu9j8hTsbnmj3b8SZUor8-OABzK4Y3YqAFboFuV8xKgvVQA5ghLiEDh70q0mpWk0JTELh2YBJd_fjhv2yYlenpVrtRP7kARns6p_ckRCHPORl7Zs6uMj_TFOERh9mzhv_g3dHeRM8MYxb28RiVplrPjK3Tc24afKcjuEa99BG8ku9Vr9fGInBLQ2aBTaud-aBJqn9Lx-1Ssf8w9vfMP6oOtuF40BpT3aaQ3kWaq1pTvEM3VHXxaqsVqJBQHlIWGCw2kpkiZhVqhHj_gnurpfeSxniri5m2wZoelR3WI_PpgXoE-vB0nhJWKRSNvp4hAz2ulIhQjSD10NLVDtX14G0peQQiNLEq-GJBo3s2F_PUUPrLWk8FcdcSZK-x1jYh2x97R22WOCarxIsqGdviyqGRA-5FTZdbPMvGZ1aXRhCPNrPS1cefXDIouA8okd2kQPLqwTwr0USZh-zp3HCLknnlsNjUw1wrLIF3mizwxbvpYdagEiQ3Zs2Q-FtbFMkIDfTdI_mC1Ppn_CPUUQfVWUBb_LkYycOwHpKAR1XXzxaBcuh5PXbQYpNiTYXanecaDlBsaXFFhtwFEgkk5V6kRDMEPr94uyzTNQZhNpVZErSsLU5VHE3YLwS4ag2wrZEDqSZLPkbHN1gtRRBcE2TpUykHgtgmILttNvFMejJtmqTagz0dHIyLKGJyjkmG_NUe_hsGGHiYY0xeZcvoeR9AWFTkUkb1V9w9oAF5kNGXrjM_Svgvk-Kg9RuQJzA3L6GhN5LiWRVL15iWvQOUlfyKfjqRc_rhioh3Jqwpzl34iZKlfsz0roeSGOGP9JVQ5HsNrRKYFeJaDFI4Kkdbr3NVFifPsrdpUq3uLJrr8rj46Y5toDtd-LY6g1aHRU1e4znBSrZRYMjy_F7mPdWxlUQma6IS4xR3UGeoPOZpWXjos9CTwYLumPYQDi5UeIhLgFbYsAfXER9LeWgJwqICCTFtWJ3oY1MMINg9tA7ipyv3Qg",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1064.46,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywUVs6sxyuTkB_pi-k9_GejHZfZok35QQaQcz5Spt_zUZCfjSbFQgUb6kblYISxQqqc67hiGEpIUT5Xq2hxEy2nQNKp3Sp0wPDmd2YuAtKgd7yu8YQrzEtJ8n60FoSpcaqs6mLWlxLrNgW7aaVI54sP_1JHFXGNA5fCkFq9595gEmLl667axl7y9euFcZF8kWtdtXEdL-tfuuKk12UPZNqNK-zhUeYHB8MaJfug_ffvRSrQmWxWw4aDle3uXkHaIwYAEyJ78RJYVEtpmwxHGMBGBI7T0O2CS0NV4N9KvQGPqRBGL8thVYadZtF8Dy1FqHmk0GMQY5haKC6mriAIKm4pHYPs1Q9pAa_SYIgL945dK1YlZURYzlc3UM9yKyDgNQrouHTZ8EN2P4UuW1Zn82x3QMsZd29jRr3eCB43dg0WOLP62aKFgJu0haDqIDHAESwIn4L0w_NtLbPRvd2DWuyyAAof-JNnlqsNuMZjiseHDegb7A0nsR8P-H2A6sIAMc1LVZ9fhWePZLFphVUhNBzgWq7uuGkc7QUzesh_SPRA0k1jieQfy22MU2CVlNjSpyGbOD95iteiTQzwKdL3z0GLXmEeVihCUOXZAiTa606Ys7xdrRI2lPxEWu-ThDhH-pRHf3OrERPYzjqy-oGpuhRSgX4V9i_0uDHNAeSF_U8EaipfH58lhU138nisPyRLS4h0p0O3NOhSmYkP-colOlJqxvkItl90GfMcFYCQtMtWi9iKqD1FctPI5km19MpOX_UL5SKmnSbzkSOK0ragixX3jO0YCHUIYI-LKwCb_sBiVoYZ7K6O17e3jqqjdIr9eEND5a-lfntyzpYwWEoCUyl79dHIj6-BNUaTl1sc75g1jdbXtjWSzMWu1o8Q0XLKrYpIdFSSULjbG2eattpoi00EzQCuK1l90CrfNAxwPoCBYfht44_nezgTnb6VvBIyn0dlO4tdFW5WqNGqKchzUAAEbgLU-PAjmMcN1oVzZdinHtfCDO0eQCS93wVwn8MnSslt3Hx5lbtS_6AKK7hmGCCyWIL23xNyekDWjJQ8NJMTKBkV7te3pE4BvVcDf9spVRdhVs2JqHkfzyFuUy8W0g4oE2yMu8DElZ4FLpfFWxO4IivR61Bs_7XLVGlgAL9viupFRMZUoxBp-CgCzSeXGkJmSqrU6WKYeWCqp5MJT94-qVruX1nY1PONeHMVPr3VUC3IuidT9o11aOVKwDXhNJzL070PA5rgFuNsOh3hQHIeiciwyG2kG7oBNgYl6kKMzGZUB_EViMTynzAQKa2-JUk6WJpL-8Dph9_khRHYR54s=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywUVs6sxyuTkB_pi-k9_GejHZfZok35QQaQcz5Spt_zUZCfjSbFQgUb6kblYISxQqqc67hiGEpIUT5Xq2hxEy2nQNKp3Sp0wPDmd2YuAtKgd7yu8YQrzEtJ8n60FoSpcaqs6mLWlxLrNgW7aaVI54sP_1JHFXGNA5fCkFq9595gEmLl667axl7y9euFcZF8kWtdtXEdL-tfuuKk12UPZNqNK-zhUeYHB8MaJfug_ffvRSrQmWxWw4aDle3uXkHaIwYAEyJ78RJYVEtpmwxHGMBGBI7T0O2CS0NV4N9KvQGPqRBGL8thVYadZtF8Dy1FqHmk0GMQY5haKC6mriAIKm4pHYPs1Q9pAa_SYIgL945dK1YlZURYzlc3UM9yKyDgNQrouHTZ8EN2P4UuW1Zn82x3QMsZd29jRr3eCB43dg0WOLP62aKFgJu0haDqIDHAESwIn4L0w_NtLbPRvd2DWuyyAAof-JNnlqsNuMZjiseHDegb7A0nsR8P-H2A6sIAMc1LVZ9fhWePZLFphVUhNBzgWq7uuGkc7QUzesh_SPRA0k1jieQfy22MU2CVlNjSpyGbOD95iteiTQzwKdL3z0GLXmEeVihCUOXZAiTa606Ys7xdrRI2lPxEWu-ThDhH-pRHf3OrERPYzjqy-oGpuhRSgX4V9i_0uDHNAeSF_U8EaipfH58lhU138nisPyRLS4h0p0O3NOhSmYkP-colOlJqxvkItl90GfMcFYCQtMtWi9iKqD1FctPI5km19MpOX_UL5SKmnSbzkSOK0ragixX3jO0YCHUIYI-LKwCb_sBiVoYZ7K6O17e3jqqjdIr9eEND5a-lfntyzpYwWEoCUyl79dHIj6-BNUaTl1sc75g1jdbXtjWSzMWu1o8Q0XLKrYpIdFSSULjbG2eattpoi00EzQCuK1l90CrfNAxwPoCBYfht44_nezgTnb6VvBIyn0dlO4tdFW5WqNGqKchzUAAEbgLU-PAjmMcN1oVzZdinHtfCDO0eQCS93wVwn8MnSslt3Hx5lbtS_6AKK7hmGCCyWIL23xNyekDWjJQ8NJMTKBkV7te3pE4BvVcDf9spVRdhVs2JqHkfzyFuUy8W0g4oE2yMu8DElZ4FLpfFWxO4IivR61Bs_7XLVGlgAL9viupFRMZUoxBp-CgCzSeXGkJmSqrU6WKYeWCqp5MJT94-qVruX1nY1PONeHMVPr3VUC3IuidT9o11aOVKwDXhNJzL070PA5rgFuNsOh3hQHIeiciwyG2kG7oBNgYl6kKMzGZUB_EViMTynzAQKa2-JUk6WJpL-8Dph9_khRHYR54s=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GywUVs6sxyuTkB_pi-k9_GejHZfZok35QQaQcz5Spt_zUZCfjSbFQgUb6kblYISxQqqc67hiGEpIUT5Xq2hxEy2nQNKp3Sp0wPDmd2YuAtKgd7yu8YQrzEtJ8n60FoSpcaqs6mLWlxLrNgW7aaVI54sP_1JHFXGNA5fCkFq9595gEmLl667axl7y9euFcZF8kWtdtXEdL-tfuuKk12UPZNqNK-zhUeYHB8MaJfug_ffvRSrQmWxWw4aDle3uXkHaIwYAEyJ78RJYVEtpmwxHGMBGBI7T0O2CS0NV4N9KvQGPqRBGL8thVYadZtF8Dy1FqHmk0GMQY5haKC6mriAIKm4pHYPs1Q9pAa_SYIgL945dK1YlZURYzlc3UM9yKyDgNQrouHTZ8EN2P4UuW1Zn82x3QMsZd29jRr3eCB43dg0WOLP62aKFgJu0haDqIDHAESwIn4L0w_NtLbPRvd2DWuyyAAof-JNnlqsNuMZjiseHDegb7A0nsR8P-H2A6sIAMc1LVZ9fhWePZLFphVUhNBzgWq7uuGkc7QUzesh_SPRA0k1jieQfy22MU2CVlNjSpyGbOD95iteiTQzwKdL3z0GLXmEeVihCUOXZAiTa606Ys7xdrRI2lPxEWu-ThDhH-pRHf3OrERPYzjqy-oGpuhRSgX4V9i_0uDHNAeSF_U8EaipfH58lhU138nisPyRLS4h0p0O3NOhSmYkP-colOlJqxvkItl90GfMcFYCQtMtWi9iKqD1FctPI5km19MpOX_UL5SKmnSbzkSOK0ragixX3jO0YCHUIYI-LKwCb_sBiVoYZ7K6O17e3jqqjdIr9eEND5a-lfntyzpYwWEoCUyl79dHIj6-BNUaTl1sc75g1jdbXtjWSzMWu1o8Q0XLKrYpIdFSSULjbG2eattpoi00EzQCuK1l90CrfNAxwPoCBYfht44_nezgTnb6VvBIyn0dlO4tdFW5WqNGqKchzUAAEbgLU-PAjmMcN1oVzZdinHtfCDO0eQCS93wVwn8MnSslt3Hx5lbtS_6AKK7hmGCCyWIL23xNyekDWjJQ8NJMTKBkV7te3pE4BvVcDf9spVRdhVs2JqHkfzyFuUy8W0g4oE2yMu8DElZ4FLpfFWxO4IivR61Bs_7XLVGlgAL9viupFRMZUoxBp-CgCzSeXGkJmSqrU6WKYeWCqp5MJT94-qVruX1nY1PONeHMVPr3VUC3IuidT9o11aOVKwDXhNJzL070PA5rgFuNsOh3hQHIeiciwyG2kG7oBNgYl6kKMzGZUB_EViMTynzAQKa2-JUk6WJpL-8Dph9_khRHYR54s=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1065.31,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPRjKNCq4EZBla4yZ8h1oxuNI8IEpAQEEWyEvkOxGW0gPIfUA8io9xxiNBIU5xxKgTMgqL6kAgW8Ag-3iPooaCqP9XCTW3xx34Ckmab1KCQ35RZpa-8-JoGmXHlLiXdL4WB7oCY_FiP2tbzX5nNRJj74IgJdMXmreWDlF0LW1iTxntGwKgYuO-J41MTBTL96N2B_qch_BDfE0YBzSOYBrtRjHBhkwkBy3weCQJNraW5zrnDKIp4SNuDDLdfM3Ut7hZ3aoJWzgP9w4mVRC1JR5AdAEYJgi6GRFm5Dcd-5p95BFV56QE73bCxSQQRfxdtMZI_diavGNRItU4QkgTj2j0uxMG9FZSzcZEMhw9AgkTxWAAhKRZfHft--aXfaRWwJHm3KM_8wW-2k8fKlKL-UphpCLE0UPUSkyNuwb4ZazQ-mCpfdW5EDWr1_2JyhLeyTsb-edZgtAxPIaN_cAfr3KMYh22VSVLiJQGQQ1fbJ8OQqsK46r0BgxI808Etl84WasOfpmfhatbxjFu2TnGC1YtDa3hcM4UONnM1UTiuOlPeMRbl109BLzBM2b8_QRPIURdVLITgVAT7jJuyub7u_OiuN7hlwlBO3OgGv2REt3ZQMKVjqTbKS1RqMtyvbWNqFh3fswbWrGbqF1nKv5af2yd1kIoPg8W-J2w7BhUgCOxZIvCZVImJnIMM0RFO1EHs1i308SQnzmNi8MQ_9AQQlKb3tL_dUTpsbAt6uYYWo38OYmCNcGSXMK9Dcf8cgEenhHi4-TSBc7m4Q45ry6G1D19mskQIqqYOAq6h7eB_FVcjsiby8gGBy4FmbZfVvZuAQXoV2NqO3A1xzfcdSmA60X9Q6kOIeetgStTUESMI0_ElsmmhAIAKan1IrPDH_YOgL8QpzBj4tF4RH3AQNYYo4Kkg4VCMvOGdD2AyeOVPoaResGqR6xwuWr4R7Kucd-IJJVeTMeClAdyO9XCBiQIScXMIFJn_ZAxfkRBY9dGjXrneBPsUOYsyhJ6zDBqM2jCs4r5MXJ7sAuLsuswZBqScZGyaQG5h2oB5ry5gLTUW_7ox2NnP_FK8_uDCjzHqSduwRsvpCblXrnqmpvV6kfKB0IYvJs1Z-IdT7aefr1g9BNy8YZ1CykZRvufMEksx_hBLXSX3Gwx5j1-fOyO_sd0KNjchXxL2sDsX5I076Di8ziAzCywp8gPCUswWdEifdKct_crnHwleH6lekshd0QFqGBq_oQTHC0G3UltzspnSugVnib6DFwRF83IHrOXTCUynBdRzrqmKFyr9PFAzlb_J_uuESsv945eajAKrDdLx888BeUFg5Kf5B6u69wcDnCr29f",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPRjKNCq4EZBla4yZ8h1oxuNI8IEpAQEEWyEvkOxGW0gPIfUA8io9xxiNBIU5xxKgTMgqL6kAgW8Ag-3iPooaCqP9XCTW3xx34Ckmab1KCQ35RZpa-8-JoGmXHlLiXdL4WB7oCY_FiP2tbzX5nNRJj74IgJdMXmreWDlF0LW1iTxntGwKgYuO-J41MTBTL96N2B_qch_BDfE0YBzSOYBrtRjHBhkwkBy3weCQJNraW5zrnDKIp4SNuDDLdfM3Ut7hZ3aoJWzgP9w4mVRC1JR5AdAEYJgi6GRFm5Dcd-5p95BFV56QE73bCxSQQRfxdtMZI_diavGNRItU4QkgTj2j0uxMG9FZSzcZEMhw9AgkTxWAAhKRZfHft--aXfaRWwJHm3KM_8wW-2k8fKlKL-UphpCLE0UPUSkyNuwb4ZazQ-mCpfdW5EDWr1_2JyhLeyTsb-edZgtAxPIaN_cAfr3KMYh22VSVLiJQGQQ1fbJ8OQqsK46r0BgxI808Etl84WasOfpmfhatbxjFu2TnGC1YtDa3hcM4UONnM1UTiuOlPeMRbl109BLzBM2b8_QRPIURdVLITgVAT7jJuyub7u_OiuN7hlwlBO3OgGv2REt3ZQMKVjqTbKS1RqMtyvbWNqFh3fswbWrGbqF1nKv5af2yd1kIoPg8W-J2w7BhUgCOxZIvCZVImJnIMM0RFO1EHs1i308SQnzmNi8MQ_9AQQlKb3tL_dUTpsbAt6uYYWo38OYmCNcGSXMK9Dcf8cgEenhHi4-TSBc7m4Q45ry6G1D19mskQIqqYOAq6h7eB_FVcjsiby8gGBy4FmbZfVvZuAQXoV2NqO3A1xzfcdSmA60X9Q6kOIeetgStTUESMI0_ElsmmhAIAKan1IrPDH_YOgL8QpzBj4tF4RH3AQNYYo4Kkg4VCMvOGdD2AyeOVPoaResGqR6xwuWr4R7Kucd-IJJVeTMeClAdyO9XCBiQIScXMIFJn_ZAxfkRBY9dGjXrneBPsUOYsyhJ6zDBqM2jCs4r5MXJ7sAuLsuswZBqScZGyaQG5h2oB5ry5gLTUW_7ox2NnP_FK8_uDCjzHqSduwRsvpCblXrnqmpvV6kfKB0IYvJs1Z-IdT7aefr1g9BNy8YZ1CykZRvufMEksx_hBLXSX3Gwx5j1-fOyO_sd0KNjchXxL2sDsX5I076Di8ziAzCywp8gPCUswWdEifdKct_crnHwleH6lekshd0QFqGBq_oQTHC0G3UltzspnSugVnib6DFwRF83IHrOXTCUynBdRzrqmKFyr9PFAzlb_J_uuESsv945eajAKrDdLx888BeUFg5Kf5B6u69wcDnCr29f",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPRjKNCq4EZBla4yZ8h1oxuNI8IEpAQEEWyEvkOxGW0gPIfUA8io9xxiNBIU5xxKgTMgqL6kAgW8Ag-3iPooaCqP9XCTW3xx34Ckmab1KCQ35RZpa-8-JoGmXHlLiXdL4WB7oCY_FiP2tbzX5nNRJj74IgJdMXmreWDlF0LW1iTxntGwKgYuO-J41MTBTL96N2B_qch_BDfE0YBzSOYBrtRjHBhkwkBy3weCQJNraW5zrnDKIp4SNuDDLdfM3Ut7hZ3aoJWzgP9w4mVRC1JR5AdAEYJgi6GRFm5Dcd-5p95BFV56QE73bCxSQQRfxdtMZI_diavGNRItU4QkgTj2j0uxMG9FZSzcZEMhw9AgkTxWAAhKRZfHft--aXfaRWwJHm3KM_8wW-2k8fKlKL-UphpCLE0UPUSkyNuwb4ZazQ-mCpfdW5EDWr1_2JyhLeyTsb-edZgtAxPIaN_cAfr3KMYh22VSVLiJQGQQ1fbJ8OQqsK46r0BgxI808Etl84WasOfpmfhatbxjFu2TnGC1YtDa3hcM4UONnM1UTiuOlPeMRbl109BLzBM2b8_QRPIURdVLITgVAT7jJuyub7u_OiuN7hlwlBO3OgGv2REt3ZQMKVjqTbKS1RqMtyvbWNqFh3fswbWrGbqF1nKv5af2yd1kIoPg8W-J2w7BhUgCOxZIvCZVImJnIMM0RFO1EHs1i308SQnzmNi8MQ_9AQQlKb3tL_dUTpsbAt6uYYWo38OYmCNcGSXMK9Dcf8cgEenhHi4-TSBc7m4Q45ry6G1D19mskQIqqYOAq6h7eB_FVcjsiby8gGBy4FmbZfVvZuAQXoV2NqO3A1xzfcdSmA60X9Q6kOIeetgStTUESMI0_ElsmmhAIAKan1IrPDH_YOgL8QpzBj4tF4RH3AQNYYo4Kkg4VCMvOGdD2AyeOVPoaResGqR6xwuWr4R7Kucd-IJJVeTMeClAdyO9XCBiQIScXMIFJn_ZAxfkRBY9dGjXrneBPsUOYsyhJ6zDBqM2jCs4r5MXJ7sAuLsuswZBqScZGyaQG5h2oB5ry5gLTUW_7ox2NnP_FK8_uDCjzHqSduwRsvpCblXrnqmpvV6kfKB0IYvJs1Z-IdT7aefr1g9BNy8YZ1CykZRvufMEksx_hBLXSX3Gwx5j1-fOyO_sd0KNjchXxL2sDsX5I076Di8ziAzCywp8gPCUswWdEifdKct_crnHwleH6lekshd0QFqGBq_oQTHC0G3UltzspnSugVnib6DFwRF83IHrOXTCUynBdRzrqmKFyr9PFAzlb_J_uuESsv945eajAKrDdLx888BeUFg5Kf5B6u69wcDnCr29f",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1090.46,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gy8QdiOCHFZeQGIhWwlkMS3HQ_5u0QiFJDVqefzsHTQBeDQRxRl2LNTbwr8edUe1WxasCfINom7PrihkKyYXTKqB70bCbYgT_-i6_kjW_svnitIWMxhEWbtO2xy9uDXbDb2WdYKMx_RtLyKgNHH3gLO7C03xIGkOU_cbrnz6yv5lgApwondnlrHPHz2gUsU9VMgJ8DQ0-9xl34qN4CJenxjzQ38Ze0Au83cmY8Fwwgmh2BFDZg2Zlg0PS5HDPWuXY_HW2rtfzGiIZWW-ViCF7f7S2oWC6WCc2JQ8kH_XS-FQjN39InZqRwIbiZ7s8md6rSpA_p1kQm75koTQFreTsIph4PbDpbsvBEvEGSKc5sOKpp8S2mNN34Eca7VQdffO-leO5MIy4q464TDRJS-R6p1HBDoO2qSV8tyu3VHdM6BnWKrnUE3bQZm1Zq0ddprG2PzBt-rbWHd4Z1_Adh6utQmPWaesA9RvB6PmxthcaArttgLbA-_2YHElBVJ03yOGlF2e0nhYPzYIbDKliEWUsli3j6lWk6im-vLH6t2VlV9rVTz8bRruY7iBx_n3cTkGrCtE4Kfg072pFPhCC2tWneIxGGGmbR7OP8QSwMGU2LZpfL9vWVAf3notFFLkvY8B3mbYaWZ8iIgvbZXCEzbOdVBAeCLk1QSarQN3puIKy-8GoMO170aEssFiHIPYF3Rr346HV-C7MrutTp_Aiikj3rIB1gD6-_H_tm2oeu-R_lShQk9dO5aJaNmrDdW5qXQSWMdt0cT_LsX9lME3G8Q9V8mmvZaoxdUE1rBbPEH4JyA1rj48Ngcl467rVigLq5SaWyD2ZCeGQ8-p3Vm1yec0_P5TrYdjOOqMsg9IUFUlgAH4zvokkrcuuUUHoL_a12Tg3QV54dH1CfUzaY13T1kyZkGlwgZuzKyUsIa7tzfZ6Q0JFz6W81LIpUPXJBhvXCxTeUZlEcId-d3--sxg52PmpWep4AzFdjmTr_Ro_FatgC7u--h0-yMKUS8QPEO9-6XFhG87sZOBscQ8cwJR7tb87OeA5IBaA17624kqBl-jERhZoxt3WQYoKccHpdwD-oF9EM2jGVGz1IUTorrkeDuuq-3gC7j-2533t1wk_UuKnavxQPd_dASk-lZaKk3-H7viE5znAea5ZQu-zlZPiewVEPbEDL9tbINja4_pTWh-u4a-lUit0Rkq2ezjlaPT4VJfzCFe42eoFNGs2ZPbygMo_j56kK_THstcEI2WiX0Pd2iLRVhiqR4_6M6ZBKoWcrTIMVDaLPbVDac-4Qq6O195fDKkLr1whHGSoLL1eFyt9l35lSBJ3y2wpcH6-vjim3XaSCldCKr_lP0OWJCEwr9yNkQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gy8QdiOCHFZeQGIhWwlkMS3HQ_5u0QiFJDVqefzsHTQBeDQRxRl2LNTbwr8edUe1WxasCfINom7PrihkKyYXTKqB70bCbYgT_-i6_kjW_svnitIWMxhEWbtO2xy9uDXbDb2WdYKMx_RtLyKgNHH3gLO7C03xIGkOU_cbrnz6yv5lgApwondnlrHPHz2gUsU9VMgJ8DQ0-9xl34qN4CJenxjzQ38Ze0Au83cmY8Fwwgmh2BFDZg2Zlg0PS5HDPWuXY_HW2rtfzGiIZWW-ViCF7f7S2oWC6WCc2JQ8kH_XS-FQjN39InZqRwIbiZ7s8md6rSpA_p1kQm75koTQFreTsIph4PbDpbsvBEvEGSKc5sOKpp8S2mNN34Eca7VQdffO-leO5MIy4q464TDRJS-R6p1HBDoO2qSV8tyu3VHdM6BnWKrnUE3bQZm1Zq0ddprG2PzBt-rbWHd4Z1_Adh6utQmPWaesA9RvB6PmxthcaArttgLbA-_2YHElBVJ03yOGlF2e0nhYPzYIbDKliEWUsli3j6lWk6im-vLH6t2VlV9rVTz8bRruY7iBx_n3cTkGrCtE4Kfg072pFPhCC2tWneIxGGGmbR7OP8QSwMGU2LZpfL9vWVAf3notFFLkvY8B3mbYaWZ8iIgvbZXCEzbOdVBAeCLk1QSarQN3puIKy-8GoMO170aEssFiHIPYF3Rr346HV-C7MrutTp_Aiikj3rIB1gD6-_H_tm2oeu-R_lShQk9dO5aJaNmrDdW5qXQSWMdt0cT_LsX9lME3G8Q9V8mmvZaoxdUE1rBbPEH4JyA1rj48Ngcl467rVigLq5SaWyD2ZCeGQ8-p3Vm1yec0_P5TrYdjOOqMsg9IUFUlgAH4zvokkrcuuUUHoL_a12Tg3QV54dH1CfUzaY13T1kyZkGlwgZuzKyUsIa7tzfZ6Q0JFz6W81LIpUPXJBhvXCxTeUZlEcId-d3--sxg52PmpWep4AzFdjmTr_Ro_FatgC7u--h0-yMKUS8QPEO9-6XFhG87sZOBscQ8cwJR7tb87OeA5IBaA17624kqBl-jERhZoxt3WQYoKccHpdwD-oF9EM2jGVGz1IUTorrkeDuuq-3gC7j-2533t1wk_UuKnavxQPd_dASk-lZaKk3-H7viE5znAea5ZQu-zlZPiewVEPbEDL9tbINja4_pTWh-u4a-lUit0Rkq2ezjlaPT4VJfzCFe42eoFNGs2ZPbygMo_j56kK_THstcEI2WiX0Pd2iLRVhiqR4_6M6ZBKoWcrTIMVDaLPbVDac-4Qq6O195fDKkLr1whHGSoLL1eFyt9l35lSBJ3y2wpcH6-vjim3XaSCldCKr_lP0OWJCEwr9yNkQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gy8QdiOCHFZeQGIhWwlkMS3HQ_5u0QiFJDVqefzsHTQBeDQRxRl2LNTbwr8edUe1WxasCfINom7PrihkKyYXTKqB70bCbYgT_-i6_kjW_svnitIWMxhEWbtO2xy9uDXbDb2WdYKMx_RtLyKgNHH3gLO7C03xIGkOU_cbrnz6yv5lgApwondnlrHPHz2gUsU9VMgJ8DQ0-9xl34qN4CJenxjzQ38Ze0Au83cmY8Fwwgmh2BFDZg2Zlg0PS5HDPWuXY_HW2rtfzGiIZWW-ViCF7f7S2oWC6WCc2JQ8kH_XS-FQjN39InZqRwIbiZ7s8md6rSpA_p1kQm75koTQFreTsIph4PbDpbsvBEvEGSKc5sOKpp8S2mNN34Eca7VQdffO-leO5MIy4q464TDRJS-R6p1HBDoO2qSV8tyu3VHdM6BnWKrnUE3bQZm1Zq0ddprG2PzBt-rbWHd4Z1_Adh6utQmPWaesA9RvB6PmxthcaArttgLbA-_2YHElBVJ03yOGlF2e0nhYPzYIbDKliEWUsli3j6lWk6im-vLH6t2VlV9rVTz8bRruY7iBx_n3cTkGrCtE4Kfg072pFPhCC2tWneIxGGGmbR7OP8QSwMGU2LZpfL9vWVAf3notFFLkvY8B3mbYaWZ8iIgvbZXCEzbOdVBAeCLk1QSarQN3puIKy-8GoMO170aEssFiHIPYF3Rr346HV-C7MrutTp_Aiikj3rIB1gD6-_H_tm2oeu-R_lShQk9dO5aJaNmrDdW5qXQSWMdt0cT_LsX9lME3G8Q9V8mmvZaoxdUE1rBbPEH4JyA1rj48Ngcl467rVigLq5SaWyD2ZCeGQ8-p3Vm1yec0_P5TrYdjOOqMsg9IUFUlgAH4zvokkrcuuUUHoL_a12Tg3QV54dH1CfUzaY13T1kyZkGlwgZuzKyUsIa7tzfZ6Q0JFz6W81LIpUPXJBhvXCxTeUZlEcId-d3--sxg52PmpWep4AzFdjmTr_Ro_FatgC7u--h0-yMKUS8QPEO9-6XFhG87sZOBscQ8cwJR7tb87OeA5IBaA17624kqBl-jERhZoxt3WQYoKccHpdwD-oF9EM2jGVGz1IUTorrkeDuuq-3gC7j-2533t1wk_UuKnavxQPd_dASk-lZaKk3-H7viE5znAea5ZQu-zlZPiewVEPbEDL9tbINja4_pTWh-u4a-lUit0Rkq2ezjlaPT4VJfzCFe42eoFNGs2ZPbygMo_j56kK_THstcEI2WiX0Pd2iLRVhiqR4_6M6ZBKoWcrTIMVDaLPbVDac-4Qq6O195fDKkLr1whHGSoLL1eFyt9l35lSBJ3y2wpcH6-vjim3XaSCldCKr_lP0OWJCEwr9yNkQ==",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1090.46,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLuHdDxtldaaouyc4Yp_FVoPihA4tUGA3tVr24GqQVkXMa4k4UhUIz7E4QjApsoeDVyyx4_A3meNsTfSQN0Z51fyokiApnfo1uQGu6WPDC_1_SMb2n7ZXjl58x0kFw2ZsNCVLs6QLWCqJOweRwd7uWiDA4eu8yAYXd6_fPxt9DnlXtyFMREEaRjSHbXPrQYzeup4e-TxQJ6ErCL1s8fYn7XL1pGRpbmDVh-f3bTxo_lYjrBVWcNZWnVpzTeXwK6YJwwlb-9ypEcZwzypFLZXTwsY8dTKQEEEzfIwAJ9A2Yy2jf913DJPKX8E8XH-sorQ2NpTUo0vygiHbP2GZ48AJCotbf1g69W7Qs2Q7CWe6IMGLnEMgplga3fHFOX-vLDwGmGWy7oRmQ5XSOTt2ezu5pFhyNnAVI_SSjpgBfQbtYKsDG6TUsnTFzcwuEGcpD3m7anTzRgyqsJVsGdkNUlTd_6jXDa_4Gx4o4FHPXvnP2C-bdlg7x0YOjzLoDQLwwcYuQuJ-1cMfvHXpFDNg9raYGuREWNnzz0Gy9vyc_h3qNhByZA8zL5voTqTHu9Oheu-GVuWsM2OXMuWoRr9xPUq3lwbcRyVS7IQPp4mD4CbqCZ6Con5Xn8PJeg9YW2-p2ZLAAPe3jWxCXDdLKdmJY2crE_91n3qJjldVRiARcJf0xQ2FEZ8FYmFHX0fLFyY2Bk0XAxoeIJ5CztIa2GMRBcqVCNBpBSEST0tDmUgRb6T9_9_I4LRy_4QFAJmvqlZBB_SxwaFRepr6wHmS39dew139_3KvyRb5VZjx7S9ZnxxhG_LQqZjiFzvYpZx2l_0bWOahSNxSNSJZ2Jt4fB9XMd4A-_sW-honumQ4NMHW8EwoO3rXKn_A_kghgFnr4DEhlm65NvGKxJOaLuxrzaEfuvXM6mmT3ofGnVdyjZvLOwcBemOSApqf4JqIzySiR-8h-atL63Qb7LluhlPUYlSxsYCCvOa5uzi2ZZuetJRL_OHZyDylBtB8Nj3AqqYsFBux3e05BILrFGC2-Bp1xHCrNHvv877_7IOBzKRcCSS588AuaPHSMcu3bJnS-59duoGHshBM7RzJPlI5kUHBtw8f1-lyyfhKBvzEPRebF5TtleirWwWOn7l2LaVgjGWGVpZ1bVT0CThTNh8EKoUMxL4h4sbi7OgMtFDVZmdOOkEJZM8mKRzc-_jhGBWI5-e6W4THmjFIlP1fhqjR6za1wVyvQ99nMs_4rENmaes9oYPM4s_4O0PBmYLX-2FfXTFknylG_R2rAZ6XKwr_M24yCKY_Tzil2SH53Edn5Of8vQm-_QTkqYM2wWN5jVRsdhrcWieqScwY",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLuHdDxtldaaouyc4Yp_FVoPihA4tUGA3tVr24GqQVkXMa4k4UhUIz7E4QjApsoeDVyyx4_A3meNsTfSQN0Z51fyokiApnfo1uQGu6WPDC_1_SMb2n7ZXjl58x0kFw2ZsNCVLs6QLWCqJOweRwd7uWiDA4eu8yAYXd6_fPxt9DnlXtyFMREEaRjSHbXPrQYzeup4e-TxQJ6ErCL1s8fYn7XL1pGRpbmDVh-f3bTxo_lYjrBVWcNZWnVpzTeXwK6YJwwlb-9ypEcZwzypFLZXTwsY8dTKQEEEzfIwAJ9A2Yy2jf913DJPKX8E8XH-sorQ2NpTUo0vygiHbP2GZ48AJCotbf1g69W7Qs2Q7CWe6IMGLnEMgplga3fHFOX-vLDwGmGWy7oRmQ5XSOTt2ezu5pFhyNnAVI_SSjpgBfQbtYKsDG6TUsnTFzcwuEGcpD3m7anTzRgyqsJVsGdkNUlTd_6jXDa_4Gx4o4FHPXvnP2C-bdlg7x0YOjzLoDQLwwcYuQuJ-1cMfvHXpFDNg9raYGuREWNnzz0Gy9vyc_h3qNhByZA8zL5voTqTHu9Oheu-GVuWsM2OXMuWoRr9xPUq3lwbcRyVS7IQPp4mD4CbqCZ6Con5Xn8PJeg9YW2-p2ZLAAPe3jWxCXDdLKdmJY2crE_91n3qJjldVRiARcJf0xQ2FEZ8FYmFHX0fLFyY2Bk0XAxoeIJ5CztIa2GMRBcqVCNBpBSEST0tDmUgRb6T9_9_I4LRy_4QFAJmvqlZBB_SxwaFRepr6wHmS39dew139_3KvyRb5VZjx7S9ZnxxhG_LQqZjiFzvYpZx2l_0bWOahSNxSNSJZ2Jt4fB9XMd4A-_sW-honumQ4NMHW8EwoO3rXKn_A_kghgFnr4DEhlm65NvGKxJOaLuxrzaEfuvXM6mmT3ofGnVdyjZvLOwcBemOSApqf4JqIzySiR-8h-atL63Qb7LluhlPUYlSxsYCCvOa5uzi2ZZuetJRL_OHZyDylBtB8Nj3AqqYsFBux3e05BILrFGC2-Bp1xHCrNHvv877_7IOBzKRcCSS588AuaPHSMcu3bJnS-59duoGHshBM7RzJPlI5kUHBtw8f1-lyyfhKBvzEPRebF5TtleirWwWOn7l2LaVgjGWGVpZ1bVT0CThTNh8EKoUMxL4h4sbi7OgMtFDVZmdOOkEJZM8mKRzc-_jhGBWI5-e6W4THmjFIlP1fhqjR6za1wVyvQ99nMs_4rENmaes9oYPM4s_4O0PBmYLX-2FfXTFknylG_R2rAZ6XKwr_M24yCKY_Tzil2SH53Edn5Of8vQm-_QTkqYM2wWN5jVRsdhrcWieqScwY",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLuHdDxtldaaouyc4Yp_FVoPihA4tUGA3tVr24GqQVkXMa4k4UhUIz7E4QjApsoeDVyyx4_A3meNsTfSQN0Z51fyokiApnfo1uQGu6WPDC_1_SMb2n7ZXjl58x0kFw2ZsNCVLs6QLWCqJOweRwd7uWiDA4eu8yAYXd6_fPxt9DnlXtyFMREEaRjSHbXPrQYzeup4e-TxQJ6ErCL1s8fYn7XL1pGRpbmDVh-f3bTxo_lYjrBVWcNZWnVpzTeXwK6YJwwlb-9ypEcZwzypFLZXTwsY8dTKQEEEzfIwAJ9A2Yy2jf913DJPKX8E8XH-sorQ2NpTUo0vygiHbP2GZ48AJCotbf1g69W7Qs2Q7CWe6IMGLnEMgplga3fHFOX-vLDwGmGWy7oRmQ5XSOTt2ezu5pFhyNnAVI_SSjpgBfQbtYKsDG6TUsnTFzcwuEGcpD3m7anTzRgyqsJVsGdkNUlTd_6jXDa_4Gx4o4FHPXvnP2C-bdlg7x0YOjzLoDQLwwcYuQuJ-1cMfvHXpFDNg9raYGuREWNnzz0Gy9vyc_h3qNhByZA8zL5voTqTHu9Oheu-GVuWsM2OXMuWoRr9xPUq3lwbcRyVS7IQPp4mD4CbqCZ6Con5Xn8PJeg9YW2-p2ZLAAPe3jWxCXDdLKdmJY2crE_91n3qJjldVRiARcJf0xQ2FEZ8FYmFHX0fLFyY2Bk0XAxoeIJ5CztIa2GMRBcqVCNBpBSEST0tDmUgRb6T9_9_I4LRy_4QFAJmvqlZBB_SxwaFRepr6wHmS39dew139_3KvyRb5VZjx7S9ZnxxhG_LQqZjiFzvYpZx2l_0bWOahSNxSNSJZ2Jt4fB9XMd4A-_sW-honumQ4NMHW8EwoO3rXKn_A_kghgFnr4DEhlm65NvGKxJOaLuxrzaEfuvXM6mmT3ofGnVdyjZvLOwcBemOSApqf4JqIzySiR-8h-atL63Qb7LluhlPUYlSxsYCCvOa5uzi2ZZuetJRL_OHZyDylBtB8Nj3AqqYsFBux3e05BILrFGC2-Bp1xHCrNHvv877_7IOBzKRcCSS588AuaPHSMcu3bJnS-59duoGHshBM7RzJPlI5kUHBtw8f1-lyyfhKBvzEPRebF5TtleirWwWOn7l2LaVgjGWGVpZ1bVT0CThTNh8EKoUMxL4h4sbi7OgMtFDVZmdOOkEJZM8mKRzc-_jhGBWI5-e6W4THmjFIlP1fhqjR6za1wVyvQ99nMs_4rENmaes9oYPM4s_4O0PBmYLX-2FfXTFknylG_R2rAZ6XKwr_M24yCKY_Tzil2SH53Edn5Of8vQm-_QTkqYM2wWN5jVRsdhrcWieqScwY",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1091.13,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 788,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GuML_v-MYynx1asoRXLsxBEBHSbvI--W_PF1p9gVClcjfH1KPbhY7lY3huMAWe19wGniOemBee1l5YTmvOzzPFvfyFbagXSh16F-IRG-cinKYCOCEVpYoqkoA-m1S4ZJKT4oKAdzFp_-V-0RG6zXCFsZkLmEqplPRDDf1dzfpSse56LQMvRXx7n6EMBc0VY11lHficvvui2ySbmz6gHkp5hoW7WOLBdB5GET69ORgg6hbPrAmpAgagH-C58dimdud4aiDebKJonXukIKj8DiQI8Ps1bM5p7FkJEcokMa7gvUUsIVEv7TpF86EkgfvUn8hZUZkhQHq590A5aNKVDF8jJQSrmY2honTmv-gg12TGsCnxVHapaFvXN4JpfKST2UBngOJhIGUfs35QK-bkFRAOsz59pHg3TUP-gPyi5lh-ck_KUNR-abWhqb2J0LSnbONHzE431HdbN1tcBS-nt06dXo175qYjUDAX8RiLSnKFFpej2FkrvP2fvwR3ETqiYpH3NEhfwF3TScjTGeXdPdFEIsI6D3OBEkblNFtgmqBnXze8pcMRE6r6wrs7aqfxjBwNmdeSy_1H2H04ntwhcx-5evMQ6_3XK9HIBSEfHjSCucZBRm4m_u814YI7_9EEU8-dz_5v5kXfWJx9IfIWXjCIzIRYNqrV147LN0qoeA5LRQhzZWtPf_RjbyEE2eiDWOf0hgJ1MqbSPqnWEGmKbokF-i1k9yYV6UqgnAK8DpBnS7QFNQdCrZfGbSMvzAP2sQNXn4B8SS1KEx-NC2ksuW07Te-5P8G85FS88cN33payz7f_O0X6M6Yz183kqDeFnrP_wnLJxPuOOv5DXiTNueVhC734OV8hCp4HjRiWy-T5z1C5OaKxaYrWg1pM4YFqR4Kd8BKgIXgJN9nSdMJyV28GK7TbjhGfcBRRQJ8Y2Pm3oxsurOX3VTz2WFsObr13A6Ku7LraKPdx1hKaXAE7jqdNNF3q4xrTgAKYpMLEdmujaSSVpqkJPfzoFX-GkzIHV9uxoGb6ZOBaLXkoSrbDkbjrNCg9EOb_jpnqhkxiHcp31BjafDpHmGYLU54ZeI4xnA5OuzJxnAdxxwQBgl5-kpOj5UYEEmdWf5iIx43GK6fb6NK4AIkHiiXGMxs2418BPUepka1OotaFPjd-6UENzB9v-wf8QmXUZPHznvHIctm5K2FSqDTcRW1mtBJOLbUHPGBiUAQf0k8XkV3GOJAddxyVxifcU-cZBiu2jXQW6DVcEFBl2JJ4W_6HpMBChQ5MljPTt6Z_Dl3_XVD76WE7V78JBa-k2A9DT7ClF4J9O4qEOc=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GuML_v-MYynx1asoRXLsxBEBHSbvI--W_PF1p9gVClcjfH1KPbhY7lY3huMAWe19wGniOemBee1l5YTmvOzzPFvfyFbagXSh16F-IRG-cinKYCOCEVpYoqkoA-m1S4ZJKT4oKAdzFp_-V-0RG6zXCFsZkLmEqplPRDDf1dzfpSse56LQMvRXx7n6EMBc0VY11lHficvvui2ySbmz6gHkp5hoW7WOLBdB5GET69ORgg6hbPrAmpAgagH-C58dimdud4aiDebKJonXukIKj8DiQI8Ps1bM5p7FkJEcokMa7gvUUsIVEv7TpF86EkgfvUn8hZUZkhQHq590A5aNKVDF8jJQSrmY2honTmv-gg12TGsCnxVHapaFvXN4JpfKST2UBngOJhIGUfs35QK-bkFRAOsz59pHg3TUP-gPyi5lh-ck_KUNR-abWhqb2J0LSnbONHzE431HdbN1tcBS-nt06dXo175qYjUDAX8RiLSnKFFpej2FkrvP2fvwR3ETqiYpH3NEhfwF3TScjTGeXdPdFEIsI6D3OBEkblNFtgmqBnXze8pcMRE6r6wrs7aqfxjBwNmdeSy_1H2H04ntwhcx-5evMQ6_3XK9HIBSEfHjSCucZBRm4m_u814YI7_9EEU8-dz_5v5kXfWJx9IfIWXjCIzIRYNqrV147LN0qoeA5LRQhzZWtPf_RjbyEE2eiDWOf0hgJ1MqbSPqnWEGmKbokF-i1k9yYV6UqgnAK8DpBnS7QFNQdCrZfGbSMvzAP2sQNXn4B8SS1KEx-NC2ksuW07Te-5P8G85FS88cN33payz7f_O0X6M6Yz183kqDeFnrP_wnLJxPuOOv5DXiTNueVhC734OV8hCp4HjRiWy-T5z1C5OaKxaYrWg1pM4YFqR4Kd8BKgIXgJN9nSdMJyV28GK7TbjhGfcBRRQJ8Y2Pm3oxsurOX3VTz2WFsObr13A6Ku7LraKPdx1hKaXAE7jqdNNF3q4xrTgAKYpMLEdmujaSSVpqkJPfzoFX-GkzIHV9uxoGb6ZOBaLXkoSrbDkbjrNCg9EOb_jpnqhkxiHcp31BjafDpHmGYLU54ZeI4xnA5OuzJxnAdxxwQBgl5-kpOj5UYEEmdWf5iIx43GK6fb6NK4AIkHiiXGMxs2418BPUepka1OotaFPjd-6UENzB9v-wf8QmXUZPHznvHIctm5K2FSqDTcRW1mtBJOLbUHPGBiUAQf0k8XkV3GOJAddxyVxifcU-cZBiu2jXQW6DVcEFBl2JJ4W_6HpMBChQ5MljPTt6Z_Dl3_XVD76WE7V78JBa-k2A9DT7ClF4J9O4qEOc=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GuML_v-MYynx1asoRXLsxBEBHSbvI--W_PF1p9gVClcjfH1KPbhY7lY3huMAWe19wGniOemBee1l5YTmvOzzPFvfyFbagXSh16F-IRG-cinKYCOCEVpYoqkoA-m1S4ZJKT4oKAdzFp_-V-0RG6zXCFsZkLmEqplPRDDf1dzfpSse56LQMvRXx7n6EMBc0VY11lHficvvui2ySbmz6gHkp5hoW7WOLBdB5GET69ORgg6hbPrAmpAgagH-C58dimdud4aiDebKJonXukIKj8DiQI8Ps1bM5p7FkJEcokMa7gvUUsIVEv7TpF86EkgfvUn8hZUZkhQHq590A5aNKVDF8jJQSrmY2honTmv-gg12TGsCnxVHapaFvXN4JpfKST2UBngOJhIGUfs35QK-bkFRAOsz59pHg3TUP-gPyi5lh-ck_KUNR-abWhqb2J0LSnbONHzE431HdbN1tcBS-nt06dXo175qYjUDAX8RiLSnKFFpej2FkrvP2fvwR3ETqiYpH3NEhfwF3TScjTGeXdPdFEIsI6D3OBEkblNFtgmqBnXze8pcMRE6r6wrs7aqfxjBwNmdeSy_1H2H04ntwhcx-5evMQ6_3XK9HIBSEfHjSCucZBRm4m_u814YI7_9EEU8-dz_5v5kXfWJx9IfIWXjCIzIRYNqrV147LN0qoeA5LRQhzZWtPf_RjbyEE2eiDWOf0hgJ1MqbSPqnWEGmKbokF-i1k9yYV6UqgnAK8DpBnS7QFNQdCrZfGbSMvzAP2sQNXn4B8SS1KEx-NC2ksuW07Te-5P8G85FS88cN33payz7f_O0X6M6Yz183kqDeFnrP_wnLJxPuOOv5DXiTNueVhC734OV8hCp4HjRiWy-T5z1C5OaKxaYrWg1pM4YFqR4Kd8BKgIXgJN9nSdMJyV28GK7TbjhGfcBRRQJ8Y2Pm3oxsurOX3VTz2WFsObr13A6Ku7LraKPdx1hKaXAE7jqdNNF3q4xrTgAKYpMLEdmujaSSVpqkJPfzoFX-GkzIHV9uxoGb6ZOBaLXkoSrbDkbjrNCg9EOb_jpnqhkxiHcp31BjafDpHmGYLU54ZeI4xnA5OuzJxnAdxxwQBgl5-kpOj5UYEEmdWf5iIx43GK6fb6NK4AIkHiiXGMxs2418BPUepka1OotaFPjd-6UENzB9v-wf8QmXUZPHznvHIctm5K2FSqDTcRW1mtBJOLbUHPGBiUAQf0k8XkV3GOJAddxyVxifcU-cZBiu2jXQW6DVcEFBl2JJ4W_6HpMBChQ5MljPTt6Z_Dl3_XVD76WE7V78JBa-k2A9DT7ClF4J9O4qEOc=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1091.98,
            "totalPrice": 788
          },
          {
            "allPassengerTotalPrice": 795,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ggh5h2wp2UX4I5q01zKTCZEd2_3zZFAyYEc6b4QgBMIz3XDo1ZvF0CpUpM4-yzWnwwKJvHYO7-xJmPaAwy7s8Sw_RbKvlrqijJpnQMfQ5SNKrmbjC4XaswUx-XDqJZuULvMkhJdF5C31yEac03Iu_-6cOgkTf-MWD2w6H-0TMTOJV53Ndp7TrhuNTXnbppIOYrCrTYI0k-wvQdunEIjqcSYWdgwMdqA9dYLewww2NkzZ3DvqLQC_ukScSM1QNgMVVzTCVgSF4rrEu7Ko7crVmOPaGCjpAwWUBC7jK8WWDylgXfcKNcMyYOMgoQ5L-3ItfTseW2Xz8sjhrtDMGjGYszVQD6c_QvjC63A26pDy2EIL0cmE5qVGcWYzgWgcz_lBQQhFbS1gm9raV01VBVeTJoDKjVZzpq4ZLEYtKXsVjoIvIlBj0Z6TCHx6TDCbfYY1OFbXPKldgPQSnvuI1ivhVdPCSt5v1yLk074Pb5tA_2lwwX46MYyFfajPCssr2sOxchJ3HLxhc_zieO6NgovvTY1nJCEVuv4KPYlrYIYbLV_XJ8bNPiFIRheRUevDDi4w_KX_7AAmN-bNwOoUIlsWKzhSR_JcUSA3q5_YYZMPppgPR7Bb7vUBWCuz3kVMK2LIZIUykFMg3cfWV8zx1TfYLGgpV0nGjz8ICkWT1a9TQU_72ILcecwWJNfDQgGzYdcXPE7lstdGM57J67wjx5Fj6viaajygmIq2GtAEhYvxCR0ccaswe8EFNRh8GCiBNplZjbH4FiFbnGz_1iEFWbS4moSZWyRsk6wko-ih_mcXnDXjnlav2t0bb1xVJu8ulwVOkvEAcmR31_VfkPnz3VCIbt2rC1EwEWi-kDaQtkh_oaPNnuoMtAmhGv7gISLK-5pySbEU_GYe-Eq5pOrrJtOAmRg3lvFuJ-2-g1PwufPbypq7y4QiifRUVNAmMb9nizLKuGW1thr0H23Boz4TKbIreT5yi3JZGKBgWiOzxztlJBiFGVRdcG5FeJfygWEK8ioYilLvGlM0M414dJf76ihPKhfsbbWY0su_r43xLGxUwHHbt03THaK5qVq47cmCEP4KQtLUzs7WFLJ6gY-oVndqrC4_im73Jyaie2rjjZY_Sgrpr6se8TMLmbAN33hpCdWEkBiO7Twns8dX2UcAMTkDbOFLLLNMiIaSxNZGu-wn_vrvIXc2k95vAUQJWt10aFAGxQWQ-hTA9YjOiH2XzolwIEfEyRGhkR7eVGlelvsCJeONy-NVV2yhaTubiE6zv-HGzM1wwEtV3KfWDU8q654slnHtzgl1JN5fmGL2cl2llmdIKxBJ49ANGS3MsIjutaJAH",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ggh5h2wp2UX4I5q01zKTCZEd2_3zZFAyYEc6b4QgBMIz3XDo1ZvF0CpUpM4-yzWnwwKJvHYO7-xJmPaAwy7s8Sw_RbKvlrqijJpnQMfQ5SNKrmbjC4XaswUx-XDqJZuULvMkhJdF5C31yEac03Iu_-6cOgkTf-MWD2w6H-0TMTOJV53Ndp7TrhuNTXnbppIOYrCrTYI0k-wvQdunEIjqcSYWdgwMdqA9dYLewww2NkzZ3DvqLQC_ukScSM1QNgMVVzTCVgSF4rrEu7Ko7crVmOPaGCjpAwWUBC7jK8WWDylgXfcKNcMyYOMgoQ5L-3ItfTseW2Xz8sjhrtDMGjGYszVQD6c_QvjC63A26pDy2EIL0cmE5qVGcWYzgWgcz_lBQQhFbS1gm9raV01VBVeTJoDKjVZzpq4ZLEYtKXsVjoIvIlBj0Z6TCHx6TDCbfYY1OFbXPKldgPQSnvuI1ivhVdPCSt5v1yLk074Pb5tA_2lwwX46MYyFfajPCssr2sOxchJ3HLxhc_zieO6NgovvTY1nJCEVuv4KPYlrYIYbLV_XJ8bNPiFIRheRUevDDi4w_KX_7AAmN-bNwOoUIlsWKzhSR_JcUSA3q5_YYZMPppgPR7Bb7vUBWCuz3kVMK2LIZIUykFMg3cfWV8zx1TfYLGgpV0nGjz8ICkWT1a9TQU_72ILcecwWJNfDQgGzYdcXPE7lstdGM57J67wjx5Fj6viaajygmIq2GtAEhYvxCR0ccaswe8EFNRh8GCiBNplZjbH4FiFbnGz_1iEFWbS4moSZWyRsk6wko-ih_mcXnDXjnlav2t0bb1xVJu8ulwVOkvEAcmR31_VfkPnz3VCIbt2rC1EwEWi-kDaQtkh_oaPNnuoMtAmhGv7gISLK-5pySbEU_GYe-Eq5pOrrJtOAmRg3lvFuJ-2-g1PwufPbypq7y4QiifRUVNAmMb9nizLKuGW1thr0H23Boz4TKbIreT5yi3JZGKBgWiOzxztlJBiFGVRdcG5FeJfygWEK8ioYilLvGlM0M414dJf76ihPKhfsbbWY0su_r43xLGxUwHHbt03THaK5qVq47cmCEP4KQtLUzs7WFLJ6gY-oVndqrC4_im73Jyaie2rjjZY_Sgrpr6se8TMLmbAN33hpCdWEkBiO7Twns8dX2UcAMTkDbOFLLLNMiIaSxNZGu-wn_vrvIXc2k95vAUQJWt10aFAGxQWQ-hTA9YjOiH2XzolwIEfEyRGhkR7eVGlelvsCJeONy-NVV2yhaTubiE6zv-HGzM1wwEtV3KfWDU8q654slnHtzgl1JN5fmGL2cl2llmdIKxBJ49ANGS3MsIjutaJAH",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Ggh5h2wp2UX4I5q01zKTCZEd2_3zZFAyYEc6b4QgBMIz3XDo1ZvF0CpUpM4-yzWnwwKJvHYO7-xJmPaAwy7s8Sw_RbKvlrqijJpnQMfQ5SNKrmbjC4XaswUx-XDqJZuULvMkhJdF5C31yEac03Iu_-6cOgkTf-MWD2w6H-0TMTOJV53Ndp7TrhuNTXnbppIOYrCrTYI0k-wvQdunEIjqcSYWdgwMdqA9dYLewww2NkzZ3DvqLQC_ukScSM1QNgMVVzTCVgSF4rrEu7Ko7crVmOPaGCjpAwWUBC7jK8WWDylgXfcKNcMyYOMgoQ5L-3ItfTseW2Xz8sjhrtDMGjGYszVQD6c_QvjC63A26pDy2EIL0cmE5qVGcWYzgWgcz_lBQQhFbS1gm9raV01VBVeTJoDKjVZzpq4ZLEYtKXsVjoIvIlBj0Z6TCHx6TDCbfYY1OFbXPKldgPQSnvuI1ivhVdPCSt5v1yLk074Pb5tA_2lwwX46MYyFfajPCssr2sOxchJ3HLxhc_zieO6NgovvTY1nJCEVuv4KPYlrYIYbLV_XJ8bNPiFIRheRUevDDi4w_KX_7AAmN-bNwOoUIlsWKzhSR_JcUSA3q5_YYZMPppgPR7Bb7vUBWCuz3kVMK2LIZIUykFMg3cfWV8zx1TfYLGgpV0nGjz8ICkWT1a9TQU_72ILcecwWJNfDQgGzYdcXPE7lstdGM57J67wjx5Fj6viaajygmIq2GtAEhYvxCR0ccaswe8EFNRh8GCiBNplZjbH4FiFbnGz_1iEFWbS4moSZWyRsk6wko-ih_mcXnDXjnlav2t0bb1xVJu8ulwVOkvEAcmR31_VfkPnz3VCIbt2rC1EwEWi-kDaQtkh_oaPNnuoMtAmhGv7gISLK-5pySbEU_GYe-Eq5pOrrJtOAmRg3lvFuJ-2-g1PwufPbypq7y4QiifRUVNAmMb9nizLKuGW1thr0H23Boz4TKbIreT5yi3JZGKBgWiOzxztlJBiFGVRdcG5FeJfygWEK8ioYilLvGlM0M414dJf76ihPKhfsbbWY0su_r43xLGxUwHHbt03THaK5qVq47cmCEP4KQtLUzs7WFLJ6gY-oVndqrC4_im73Jyaie2rjjZY_Sgrpr6se8TMLmbAN33hpCdWEkBiO7Twns8dX2UcAMTkDbOFLLLNMiIaSxNZGu-wn_vrvIXc2k95vAUQJWt10aFAGxQWQ-hTA9YjOiH2XzolwIEfEyRGhkR7eVGlelvsCJeONy-NVV2yhaTubiE6zv-HGzM1wwEtV3KfWDU8q654slnHtzgl1JN5fmGL2cl2llmdIKxBJ49ANGS3MsIjutaJAH",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1070.24,
            "totalPrice": 795
          },
          {
            "allPassengerTotalPrice": 795,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3xRu_TExumeiB1oYsj1ttGs4PJWM47j-N1ycOudxjE39IMYQa4SJx6OyE2nhRo6o8qYGzjEORlMkoUQ8bs58qZagXnJakCOwnNWEzExAB6JAoixCeUIU5mZPasEieC-JMFHhHgMKFw5QpuMjCIh2fQFP0BsiCDWXDIaGBonDvHmo4WIkS0Yeco4XGO3pa-iVxD2FksWO35UFa4y3V0_9G4WJ8zKDTnotlKIeQACKkB9cI8tgSLobov04nLiSzaTawWMYgHDC4o5_-dOkjxOoNuPsH9PZOYVO9QPBkmUqrTyW_QXGflnrCmAZWJNtuAhkgDgpuhNU3E4gfCTC_MfL-ahSvY3_EP77SltHD813Aulo63Fk6cEnVVPHWX4gRX5f_vOcA7-78TQbEtfDxCr3kkpZepupsqpQfAQ5rJ5A3_dwF_99o8hUSkS7h0m4yxWGAohSU6gW7WbRMlbPxWQD7zhZtSrk7hHXMHJLDMLIrGp9FkQQfhe0c1VvFAa8vme8hjUCDrH9r0jJ6anablQlRs7DQQ9D9EXVKmVkh1WNwFMtZ5UX8N1WfytFLe2t_9MG6TgStEvx_NtywDmpWo-8AZGop6c3TBk-uBjmxI4A_YUDFW64cjEUVueH64r9l_txFPOfRQSdg4Di8E-cbT9GvMLIYQ2oAfspOaXnZM_NV2lf2-KNJWU9Y1G7KCO4fh64NM9apdZZbpXS0VZ5hLJU8Wa0Va6aitAaBzGlurDFqGZNtIFyykQ-3oitod4bbH0WG5BxigtzaOgZ9rtiPKSWUmo7jnvveVPla_OjYTmbdPieY9KuRoqDWh7blpO62ek0qCa4w_F5Yg5ofclSy4RxTzKh89aNRUIfk0UOZany_sjfkWR3VUN9I6MFB1MjfTPRzhJTiRUALo7lTcMW2LRr4yxf-td0m_Dbr3sQ_ic74_JXhH3sJqz7A3KOgRvOcG7VgEIR1EvtF6hYAKPXEvR-lXQmcrChOi_OXg98rJwCS8JEu7UjM9ULiPdsYi01umerXROAQOa_rMzWi-N_zM5lx99xYkM5rfTlN6vVRNSwEBY6HF4B7yNFhgfqNZdNiUba41BS-1Z-eK0wcBKY2p7feGDpsWgSuv15mNPkDyzwD_0WQwjzjk0Ccv6g-AUCckEzERWFu6RQC30a6C0H6Q0C0w5j4o_pH5B1OGxNuzDgETSnpHotezlgI_y9MBSjyg-Kwuet3Hmcplq_DpTk--Ayf_mnDGdiLZDfSU59KuBYBqv-ASD5plGLCy-lPPbK1PICrj4vz-a9XWMbYigNktHvspqfXmMsghxw-WFiVVLwQuM=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3xRu_TExumeiB1oYsj1ttGs4PJWM47j-N1ycOudxjE39IMYQa4SJx6OyE2nhRo6o8qYGzjEORlMkoUQ8bs58qZagXnJakCOwnNWEzExAB6JAoixCeUIU5mZPasEieC-JMFHhHgMKFw5QpuMjCIh2fQFP0BsiCDWXDIaGBonDvHmo4WIkS0Yeco4XGO3pa-iVxD2FksWO35UFa4y3V0_9G4WJ8zKDTnotlKIeQACKkB9cI8tgSLobov04nLiSzaTawWMYgHDC4o5_-dOkjxOoNuPsH9PZOYVO9QPBkmUqrTyW_QXGflnrCmAZWJNtuAhkgDgpuhNU3E4gfCTC_MfL-ahSvY3_EP77SltHD813Aulo63Fk6cEnVVPHWX4gRX5f_vOcA7-78TQbEtfDxCr3kkpZepupsqpQfAQ5rJ5A3_dwF_99o8hUSkS7h0m4yxWGAohSU6gW7WbRMlbPxWQD7zhZtSrk7hHXMHJLDMLIrGp9FkQQfhe0c1VvFAa8vme8hjUCDrH9r0jJ6anablQlRs7DQQ9D9EXVKmVkh1WNwFMtZ5UX8N1WfytFLe2t_9MG6TgStEvx_NtywDmpWo-8AZGop6c3TBk-uBjmxI4A_YUDFW64cjEUVueH64r9l_txFPOfRQSdg4Di8E-cbT9GvMLIYQ2oAfspOaXnZM_NV2lf2-KNJWU9Y1G7KCO4fh64NM9apdZZbpXS0VZ5hLJU8Wa0Va6aitAaBzGlurDFqGZNtIFyykQ-3oitod4bbH0WG5BxigtzaOgZ9rtiPKSWUmo7jnvveVPla_OjYTmbdPieY9KuRoqDWh7blpO62ek0qCa4w_F5Yg5ofclSy4RxTzKh89aNRUIfk0UOZany_sjfkWR3VUN9I6MFB1MjfTPRzhJTiRUALo7lTcMW2LRr4yxf-td0m_Dbr3sQ_ic74_JXhH3sJqz7A3KOgRvOcG7VgEIR1EvtF6hYAKPXEvR-lXQmcrChOi_OXg98rJwCS8JEu7UjM9ULiPdsYi01umerXROAQOa_rMzWi-N_zM5lx99xYkM5rfTlN6vVRNSwEBY6HF4B7yNFhgfqNZdNiUba41BS-1Z-eK0wcBKY2p7feGDpsWgSuv15mNPkDyzwD_0WQwjzjk0Ccv6g-AUCckEzERWFu6RQC30a6C0H6Q0C0w5j4o_pH5B1OGxNuzDgETSnpHotezlgI_y9MBSjyg-Kwuet3Hmcplq_DpTk--Ayf_mnDGdiLZDfSU59KuBYBqv-ASD5plGLCy-lPPbK1PICrj4vz-a9XWMbYigNktHvspqfXmMsghxw-WFiVVLwQuM=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3xRu_TExumeiB1oYsj1ttGs4PJWM47j-N1ycOudxjE39IMYQa4SJx6OyE2nhRo6o8qYGzjEORlMkoUQ8bs58qZagXnJakCOwnNWEzExAB6JAoixCeUIU5mZPasEieC-JMFHhHgMKFw5QpuMjCIh2fQFP0BsiCDWXDIaGBonDvHmo4WIkS0Yeco4XGO3pa-iVxD2FksWO35UFa4y3V0_9G4WJ8zKDTnotlKIeQACKkB9cI8tgSLobov04nLiSzaTawWMYgHDC4o5_-dOkjxOoNuPsH9PZOYVO9QPBkmUqrTyW_QXGflnrCmAZWJNtuAhkgDgpuhNU3E4gfCTC_MfL-ahSvY3_EP77SltHD813Aulo63Fk6cEnVVPHWX4gRX5f_vOcA7-78TQbEtfDxCr3kkpZepupsqpQfAQ5rJ5A3_dwF_99o8hUSkS7h0m4yxWGAohSU6gW7WbRMlbPxWQD7zhZtSrk7hHXMHJLDMLIrGp9FkQQfhe0c1VvFAa8vme8hjUCDrH9r0jJ6anablQlRs7DQQ9D9EXVKmVkh1WNwFMtZ5UX8N1WfytFLe2t_9MG6TgStEvx_NtywDmpWo-8AZGop6c3TBk-uBjmxI4A_YUDFW64cjEUVueH64r9l_txFPOfRQSdg4Di8E-cbT9GvMLIYQ2oAfspOaXnZM_NV2lf2-KNJWU9Y1G7KCO4fh64NM9apdZZbpXS0VZ5hLJU8Wa0Va6aitAaBzGlurDFqGZNtIFyykQ-3oitod4bbH0WG5BxigtzaOgZ9rtiPKSWUmo7jnvveVPla_OjYTmbdPieY9KuRoqDWh7blpO62ek0qCa4w_F5Yg5ofclSy4RxTzKh89aNRUIfk0UOZany_sjfkWR3VUN9I6MFB1MjfTPRzhJTiRUALo7lTcMW2LRr4yxf-td0m_Dbr3sQ_ic74_JXhH3sJqz7A3KOgRvOcG7VgEIR1EvtF6hYAKPXEvR-lXQmcrChOi_OXg98rJwCS8JEu7UjM9ULiPdsYi01umerXROAQOa_rMzWi-N_zM5lx99xYkM5rfTlN6vVRNSwEBY6HF4B7yNFhgfqNZdNiUba41BS-1Z-eK0wcBKY2p7feGDpsWgSuv15mNPkDyzwD_0WQwjzjk0Ccv6g-AUCckEzERWFu6RQC30a6C0H6Q0C0w5j4o_pH5B1OGxNuzDgETSnpHotezlgI_y9MBSjyg-Kwuet3Hmcplq_DpTk--Ayf_mnDGdiLZDfSU59KuBYBqv-ASD5plGLCy-lPPbK1PICrj4vz-a9XWMbYigNktHvspqfXmMsghxw-WFiVVLwQuM=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1096.91,
            "totalPrice": 795
          },
          {
            "allPassengerTotalPrice": 796,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKjMt-YDQBZaL9b54668zm4eDteBRavqURviLsAfRn226EV3tSsr2_yG-50vaQFqk8t5Rx08-7uAWEIJNRkJ7DrdIXLN5HERJitL4JD19qwI250haKvjbE8ocWmSXSz_NVrECdmTtnyGgK7ZeRgchVMgx9A3YKn_oHeNL2h0PfHstYxmZ1iyoXOxPa-hEwN5VUk-2MbG8GxxeKImtDfEsIifTgDBaX_KYteNeVuSGOxnRvtZbNnvawl_KpZrs4utTxL-zWM9e_7xn3XhOirjyUnRo9MQruYijpaLd-H70ZYPepbwJykbTzgyk_TndQQt9dtjr4yvKcDWcFNts39e_7j89isip6ZmI4n3hamkLScD9kKRM6SUR1K6hDUngagfTzgptDKkrAF7u5zEDCb1FQQDm7Xik5LbrDb7FXSPdOEOjkwqCGpFKt1qrqaFkab4AbwmjkQgMeePPa6jNXZXYZWpol_T6G79OHrv3BiHdv73W4HMG4_tuohLeton4LzOxXSty90TjkCqrhp4SiOCmJYovmRcWwItDOy7hZgYVyUr3cXGYfMw9LWVB6dO6k-favYWn_3LW8FzdR5lD5_NU6PrmMOwV7FQKbUT0EJaayxH4B-8HCL63huW-YALQBkcdKPo_FQPTFQZXvLL7Lqv8crd51Q2_Tta5OeN_ENBahL_WdALTREjvVxvKoS3yu8VVjuR98tVo4O-ddVrE7LRfSzjvpse1DmTbge73eBh7HGG1_GBTualMb-2ppRgc7Vdj_Oeg9BL62ufq43oBlUNq0zKSTIUMvuhBlJtqfhtJIQ9uzLHEXYpz3kEVBBER_B5xBrXgSzS3C1ovS2ypVjupE-hpKCCKbrpTmxjQEj0xQ9yZtFQfOzFddJMq6m0Zz4IOb392w_ABttGKex8gGPiGZfzPPy6YRYHkAxr--ORNtePHHPowxEkT84krG-U9mv4pGyRZpsCVeh7sXYXHbUeHx-ZpaZ8Glz7AIY0dj_AAxMgtCcTnB7SruM17LnfWeByLy31VwyEhiJgUvcmij-uvQBQgMxQpgcRgmAAbopDYp-es4Lif3yeIeclVlOhOON3NknWeZxNwY2Pcgv8f4aASd-YtfEYrHBOCRkDvuN1tlb4dhMdnlM0Wfhu-nRVOB7BwZjnBu61QcsUs0ISy3B0sdQQ8oJE0qX2Ubg2JzyAZelRNGKHl__UB2Zb3U-PLFA2yfMK6Oa-Jl5LtjfBbiBZA36g5sekQrYL4HiCgoeIORrLyWzCW2irIkoNY-CFUSDFsh8d0_7fphk_ygWbY407aHg==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKjMt-YDQBZaL9b54668zm4eDteBRavqURviLsAfRn226EV3tSsr2_yG-50vaQFqk8t5Rx08-7uAWEIJNRkJ7DrdIXLN5HERJitL4JD19qwI250haKvjbE8ocWmSXSz_NVrECdmTtnyGgK7ZeRgchVMgx9A3YKn_oHeNL2h0PfHstYxmZ1iyoXOxPa-hEwN5VUk-2MbG8GxxeKImtDfEsIifTgDBaX_KYteNeVuSGOxnRvtZbNnvawl_KpZrs4utTxL-zWM9e_7xn3XhOirjyUnRo9MQruYijpaLd-H70ZYPepbwJykbTzgyk_TndQQt9dtjr4yvKcDWcFNts39e_7j89isip6ZmI4n3hamkLScD9kKRM6SUR1K6hDUngagfTzgptDKkrAF7u5zEDCb1FQQDm7Xik5LbrDb7FXSPdOEOjkwqCGpFKt1qrqaFkab4AbwmjkQgMeePPa6jNXZXYZWpol_T6G79OHrv3BiHdv73W4HMG4_tuohLeton4LzOxXSty90TjkCqrhp4SiOCmJYovmRcWwItDOy7hZgYVyUr3cXGYfMw9LWVB6dO6k-favYWn_3LW8FzdR5lD5_NU6PrmMOwV7FQKbUT0EJaayxH4B-8HCL63huW-YALQBkcdKPo_FQPTFQZXvLL7Lqv8crd51Q2_Tta5OeN_ENBahL_WdALTREjvVxvKoS3yu8VVjuR98tVo4O-ddVrE7LRfSzjvpse1DmTbge73eBh7HGG1_GBTualMb-2ppRgc7Vdj_Oeg9BL62ufq43oBlUNq0zKSTIUMvuhBlJtqfhtJIQ9uzLHEXYpz3kEVBBER_B5xBrXgSzS3C1ovS2ypVjupE-hpKCCKbrpTmxjQEj0xQ9yZtFQfOzFddJMq6m0Zz4IOb392w_ABttGKex8gGPiGZfzPPy6YRYHkAxr--ORNtePHHPowxEkT84krG-U9mv4pGyRZpsCVeh7sXYXHbUeHx-ZpaZ8Glz7AIY0dj_AAxMgtCcTnB7SruM17LnfWeByLy31VwyEhiJgUvcmij-uvQBQgMxQpgcRgmAAbopDYp-es4Lif3yeIeclVlOhOON3NknWeZxNwY2Pcgv8f4aASd-YtfEYrHBOCRkDvuN1tlb4dhMdnlM0Wfhu-nRVOB7BwZjnBu61QcsUs0ISy3B0sdQQ8oJE0qX2Ubg2JzyAZelRNGKHl__UB2Zb3U-PLFA2yfMK6Oa-Jl5LtjfBbiBZA36g5sekQrYL4HiCgoeIORrLyWzCW2irIkoNY-CFUSDFsh8d0_7fphk_ygWbY407aHg==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "U2",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GKjMt-YDQBZaL9b54668zm4eDteBRavqURviLsAfRn226EV3tSsr2_yG-50vaQFqk8t5Rx08-7uAWEIJNRkJ7DrdIXLN5HERJitL4JD19qwI250haKvjbE8ocWmSXSz_NVrECdmTtnyGgK7ZeRgchVMgx9A3YKn_oHeNL2h0PfHstYxmZ1iyoXOxPa-hEwN5VUk-2MbG8GxxeKImtDfEsIifTgDBaX_KYteNeVuSGOxnRvtZbNnvawl_KpZrs4utTxL-zWM9e_7xn3XhOirjyUnRo9MQruYijpaLd-H70ZYPepbwJykbTzgyk_TndQQt9dtjr4yvKcDWcFNts39e_7j89isip6ZmI4n3hamkLScD9kKRM6SUR1K6hDUngagfTzgptDKkrAF7u5zEDCb1FQQDm7Xik5LbrDb7FXSPdOEOjkwqCGpFKt1qrqaFkab4AbwmjkQgMeePPa6jNXZXYZWpol_T6G79OHrv3BiHdv73W4HMG4_tuohLeton4LzOxXSty90TjkCqrhp4SiOCmJYovmRcWwItDOy7hZgYVyUr3cXGYfMw9LWVB6dO6k-favYWn_3LW8FzdR5lD5_NU6PrmMOwV7FQKbUT0EJaayxH4B-8HCL63huW-YALQBkcdKPo_FQPTFQZXvLL7Lqv8crd51Q2_Tta5OeN_ENBahL_WdALTREjvVxvKoS3yu8VVjuR98tVo4O-ddVrE7LRfSzjvpse1DmTbge73eBh7HGG1_GBTualMb-2ppRgc7Vdj_Oeg9BL62ufq43oBlUNq0zKSTIUMvuhBlJtqfhtJIQ9uzLHEXYpz3kEVBBER_B5xBrXgSzS3C1ovS2ypVjupE-hpKCCKbrpTmxjQEj0xQ9yZtFQfOzFddJMq6m0Zz4IOb392w_ABttGKex8gGPiGZfzPPy6YRYHkAxr--ORNtePHHPowxEkT84krG-U9mv4pGyRZpsCVeh7sXYXHbUeHx-ZpaZ8Glz7AIY0dj_AAxMgtCcTnB7SruM17LnfWeByLy31VwyEhiJgUvcmij-uvQBQgMxQpgcRgmAAbopDYp-es4Lif3yeIeclVlOhOON3NknWeZxNwY2Pcgv8f4aASd-YtfEYrHBOCRkDvuN1tlb4dhMdnlM0Wfhu-nRVOB7BwZjnBu61QcsUs0ISy3B0sdQQ8oJE0qX2Ubg2JzyAZelRNGKHl__UB2Zb3U-PLFA2yfMK6Oa-Jl5LtjfBbiBZA36g5sekQrYL4HiCgoeIORrLyWzCW2irIkoNY-CFUSDFsh8d0_7fphk_ygWbY407aHg==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "13:05",
                "destination": "SFO",
                "duration": "41h33m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "IBZ",
                    "cityFrom": "Seville",
                    "cityTo": "Ibiza",
                    "combination_id": "04b80f354e2a0000048c9b92",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 2424,
                    "flyFrom": "SVQ",
                    "flyTo": "IBZ",
                    "guarantee": false,
                    "id": "04b80f354e2a0000048c9b92_0",
                    "local_arrival": "2024-10-14T14:25:00.000Z",
                    "local_departure": "2024-10-14T13:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2424",
                    "return": 0,
                    "utc_arrival": "2024-10-14T12:25:00.000Z",
                    "utc_departure": "2024-10-14T11:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "IBZ",
                    "cityCodeTo": "LON",
                    "cityFrom": "Ibiza",
                    "cityTo": "London",
                    "combination_id": "0f3522f54e2b00003abc8da6",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8106,
                    "flyFrom": "IBZ",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0f3522f54e2b00003abc8da6_0",
                    "local_arrival": "2024-10-15T02:05:00.000Z",
                    "local_departure": "2024-10-15T00:40:00.000Z",
                    "operating_carrier": "U2",
                    "operating_flight_no": "8106",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:05:00.000Z",
                    "utc_departure": "2024-10-14T22:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1100.9,
            "totalPrice": 796
          },
          {
            "allPassengerTotalPrice": 796,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFkjVlyeadKuqX7-D_IetM_DUFC8hjxAtYKRWb8Co1DEBHa1pNYHyv9y4lAuk9r4Wcv-IlUL2zGDrE48up2nrzzWl1wF4goA5yKdZxBE7NJ2D5tK-YyOVj3mdSUFfNLU7qBMDkQJucbqPXp6xhpygzD8HPP6V5EJwAy6PK03_RdQf8DlX4AKAHxlqW9ba82n6skbdqX61J_Cdmwr3THgNRFlxI-RSLls3mAqU6ghvILoMfdN0OqLJky8m4HXcDaiPoBOTwiX4xL9Uq7RBM5SVVbL9VGyBQnRMSWFbzGhCSmVwHRsaSzHWHbb-kv0vMOyfLmmpHoB-W9NDz9VUsSna5EM4ywuHZEn3S1VE72yTUn5cXb9H550ZwAewlJe0W6PUSOEoxezEF0GDCDLn5EvDiFQGVF6NJwx5gO8lSJjFIYhlcGZ884fB0NEO78twUuqHSOHWCn5EUdUUkMMJTKUgaZhfEi8i8vVZPbehP30NkNuzgTtGzCRbkbJYn2YMCArQtu_QA25rrcWTT2AexdnBp8w4A_OcHlzel3l-Qp4nJGxECwz3NKippYhBeH_MJZEUeSMo4BHEDHlUifsIqR32NyuqEBwvXHehbUIbm5seT8SEfMNztMMD9ezf-QfBQNvp_lsAuMHZbJ_e56sRqDrUlgXQwAIGzTI0Uk6aD_WY6_dWgGLftAJKD273TUgBMMrvhoUhvY7OFJ4I_X12JAiLibMB10FUFBd-y0Yz2vHeGDv1_OL8Sd_nSsR9g1SvF8quUKrRrvT6evDJWO__bdTZwn_VFpeNfhSl65ck1Zbrc-CAB9g-b5nVpVaf4ZHU7vUFePakALnh_fpE9fvSS-caNl8keEIXUqN-uvT6ZZOe8IRSLy7i_jD5oI4sMuVhq2ILf_B2DeNxEUcQdBEz6UZu_nLdHfvnf65AlO8QqjdC-G1i9gg4HesqxCgqdo-8vHZ9bQ403pcGU6VT5Y9TV8MdUTKv2NqTfIEicDFaXDwVLUEDrYTlCeakkOow8SAU2QH92x5GyhWJIbkTmQrfsNZbGCAKWR1462Xtk0B4dCc5VfXKw3qLcah4ZP9wVwio9NYdUE-U8NRlyijLRCizQY7Rtb-BKdOzYE6DyJJpg1UlOn0lBE9YoQcac0Po-orUu8oCX4-L4aeA_i5YgAgmzyZvkrbEBAhaeTkH4VDapjdiAarDkat0zjP0S2-nod02W-6qZkajZBSYM-T7vTTu-N2_lyMWVm3watxsEjKa17Pndfmchys9ZUkZiJfusNjJtm0Pvqv_P1OqHFKGLi_bXwT7Xg==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFkjVlyeadKuqX7-D_IetM_DUFC8hjxAtYKRWb8Co1DEBHa1pNYHyv9y4lAuk9r4Wcv-IlUL2zGDrE48up2nrzzWl1wF4goA5yKdZxBE7NJ2D5tK-YyOVj3mdSUFfNLU7qBMDkQJucbqPXp6xhpygzD8HPP6V5EJwAy6PK03_RdQf8DlX4AKAHxlqW9ba82n6skbdqX61J_Cdmwr3THgNRFlxI-RSLls3mAqU6ghvILoMfdN0OqLJky8m4HXcDaiPoBOTwiX4xL9Uq7RBM5SVVbL9VGyBQnRMSWFbzGhCSmVwHRsaSzHWHbb-kv0vMOyfLmmpHoB-W9NDz9VUsSna5EM4ywuHZEn3S1VE72yTUn5cXb9H550ZwAewlJe0W6PUSOEoxezEF0GDCDLn5EvDiFQGVF6NJwx5gO8lSJjFIYhlcGZ884fB0NEO78twUuqHSOHWCn5EUdUUkMMJTKUgaZhfEi8i8vVZPbehP30NkNuzgTtGzCRbkbJYn2YMCArQtu_QA25rrcWTT2AexdnBp8w4A_OcHlzel3l-Qp4nJGxECwz3NKippYhBeH_MJZEUeSMo4BHEDHlUifsIqR32NyuqEBwvXHehbUIbm5seT8SEfMNztMMD9ezf-QfBQNvp_lsAuMHZbJ_e56sRqDrUlgXQwAIGzTI0Uk6aD_WY6_dWgGLftAJKD273TUgBMMrvhoUhvY7OFJ4I_X12JAiLibMB10FUFBd-y0Yz2vHeGDv1_OL8Sd_nSsR9g1SvF8quUKrRrvT6evDJWO__bdTZwn_VFpeNfhSl65ck1Zbrc-CAB9g-b5nVpVaf4ZHU7vUFePakALnh_fpE9fvSS-caNl8keEIXUqN-uvT6ZZOe8IRSLy7i_jD5oI4sMuVhq2ILf_B2DeNxEUcQdBEz6UZu_nLdHfvnf65AlO8QqjdC-G1i9gg4HesqxCgqdo-8vHZ9bQ403pcGU6VT5Y9TV8MdUTKv2NqTfIEicDFaXDwVLUEDrYTlCeakkOow8SAU2QH92x5GyhWJIbkTmQrfsNZbGCAKWR1462Xtk0B4dCc5VfXKw3qLcah4ZP9wVwio9NYdUE-U8NRlyijLRCizQY7Rtb-BKdOzYE6DyJJpg1UlOn0lBE9YoQcac0Po-orUu8oCX4-L4aeA_i5YgAgmzyZvkrbEBAhaeTkH4VDapjdiAarDkat0zjP0S2-nod02W-6qZkajZBSYM-T7vTTu-N2_lyMWVm3watxsEjKa17Pndfmchys9ZUkZiJfusNjJtm0Pvqv_P1OqHFKGLi_bXwT7Xg==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "U2",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFkjVlyeadKuqX7-D_IetM_DUFC8hjxAtYKRWb8Co1DEBHa1pNYHyv9y4lAuk9r4Wcv-IlUL2zGDrE48up2nrzzWl1wF4goA5yKdZxBE7NJ2D5tK-YyOVj3mdSUFfNLU7qBMDkQJucbqPXp6xhpygzD8HPP6V5EJwAy6PK03_RdQf8DlX4AKAHxlqW9ba82n6skbdqX61J_Cdmwr3THgNRFlxI-RSLls3mAqU6ghvILoMfdN0OqLJky8m4HXcDaiPoBOTwiX4xL9Uq7RBM5SVVbL9VGyBQnRMSWFbzGhCSmVwHRsaSzHWHbb-kv0vMOyfLmmpHoB-W9NDz9VUsSna5EM4ywuHZEn3S1VE72yTUn5cXb9H550ZwAewlJe0W6PUSOEoxezEF0GDCDLn5EvDiFQGVF6NJwx5gO8lSJjFIYhlcGZ884fB0NEO78twUuqHSOHWCn5EUdUUkMMJTKUgaZhfEi8i8vVZPbehP30NkNuzgTtGzCRbkbJYn2YMCArQtu_QA25rrcWTT2AexdnBp8w4A_OcHlzel3l-Qp4nJGxECwz3NKippYhBeH_MJZEUeSMo4BHEDHlUifsIqR32NyuqEBwvXHehbUIbm5seT8SEfMNztMMD9ezf-QfBQNvp_lsAuMHZbJ_e56sRqDrUlgXQwAIGzTI0Uk6aD_WY6_dWgGLftAJKD273TUgBMMrvhoUhvY7OFJ4I_X12JAiLibMB10FUFBd-y0Yz2vHeGDv1_OL8Sd_nSsR9g1SvF8quUKrRrvT6evDJWO__bdTZwn_VFpeNfhSl65ck1Zbrc-CAB9g-b5nVpVaf4ZHU7vUFePakALnh_fpE9fvSS-caNl8keEIXUqN-uvT6ZZOe8IRSLy7i_jD5oI4sMuVhq2ILf_B2DeNxEUcQdBEz6UZu_nLdHfvnf65AlO8QqjdC-G1i9gg4HesqxCgqdo-8vHZ9bQ403pcGU6VT5Y9TV8MdUTKv2NqTfIEicDFaXDwVLUEDrYTlCeakkOow8SAU2QH92x5GyhWJIbkTmQrfsNZbGCAKWR1462Xtk0B4dCc5VfXKw3qLcah4ZP9wVwio9NYdUE-U8NRlyijLRCizQY7Rtb-BKdOzYE6DyJJpg1UlOn0lBE9YoQcac0Po-orUu8oCX4-L4aeA_i5YgAgmzyZvkrbEBAhaeTkH4VDapjdiAarDkat0zjP0S2-nod02W-6qZkajZBSYM-T7vTTu-N2_lyMWVm3watxsEjKa17Pndfmchys9ZUkZiJfusNjJtm0Pvqv_P1OqHFKGLi_bXwT7Xg==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "13:05",
                "destination": "SFO",
                "duration": "41h33m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "IBZ",
                    "cityFrom": "Seville",
                    "cityTo": "Ibiza",
                    "combination_id": "04b80f354e2a0000048c9b92",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 2424,
                    "flyFrom": "SVQ",
                    "flyTo": "IBZ",
                    "guarantee": false,
                    "id": "04b80f354e2a0000048c9b92_0",
                    "local_arrival": "2024-10-14T14:25:00.000Z",
                    "local_departure": "2024-10-14T13:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2424",
                    "return": 0,
                    "utc_arrival": "2024-10-14T12:25:00.000Z",
                    "utc_departure": "2024-10-14T11:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "IBZ",
                    "cityCodeTo": "LON",
                    "cityFrom": "Ibiza",
                    "cityTo": "London",
                    "combination_id": "0f3522f54e2b00003abc8da6",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8106,
                    "flyFrom": "IBZ",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0f3522f54e2b00003abc8da6_0",
                    "local_arrival": "2024-10-15T02:05:00.000Z",
                    "local_departure": "2024-10-15T00:40:00.000Z",
                    "operating_carrier": "U2",
                    "operating_flight_no": "8106",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:05:00.000Z",
                    "utc_departure": "2024-10-14T22:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1100.9,
            "totalPrice": 796
          },
          {
            "allPassengerTotalPrice": 796,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhDYkaiuMWBmDfmWcyXL4gbFG9sP_Ki_vWM65nWD8f6YiQBDe8-26a_yfqiLJC_-WnoDnUI8xEJZCRFSpNYd0gzvX_9pfKY7zSTv_86j4L8bZyZ8KDvbRaHXFtSeftsBiYYFvhk5PxKTjBkL0QQoRCXypKnE3VgmeuMgXaAIhAMQKU3Pi5uzljgYL6oFuvnsJ4r7qdX2tqErjx4mJeWIueTnJLtHEEmC-i0cZS9DFPX4hqm1BNT84un37Xo34GoA3vgj3Fr3WXU1qHNaJPB1sMiuamb2oohTgLPCgz6AZmZ2b60sHY6eDJMnlsQ1dRb4K0R-z6JDReornDn-YOCxLYmFuEN4_rc7pYF9Iy76kLIQ242bkjHAUzes5UOjTI1L1VAtRakyMPjlsJw2BWp0hw7ULnKDGNam-OyB9xNkYITtgA3qJjYAgO8DrhV0uooWlCNnVBbkfqWMoOQxAwEPqbqdamwrgkArIlfJnNAPhpd9U_kbjDMbel4HflGXFo4_509ROUcHLJAGVksmpbgVjFQI4kbw_MKaCvgzCLPz60-LJ23gs9HKwsdO0CYmZ7cSjZ2aawj0a0RISo0FmvnwZIH9zMXUXOkDihJRR3ts7IEXZt5mqX38PFS3Wy_ntMVclGEERFdsCO6DL-Ns6PWty-UPSygsiNhi1sZ0XMxPwWfEmvLXnxpAyYXTs_5haSqwDJYhxaqFrw3DFTR1MEvd3f-CBpHf0E_sH4HW_w-VxIP909oyyyhC4c0v7OZbPbiwxcy1CWoOnwmz6Up_aU6Nl4ApJIQ2ld-K4eI7X7tmGdHGfTHO84undAZ5RQ-9sSwvBiMwFOib-F7thd5Z7eIDi-ZCX9_5gfoWUaAE8Te9cziThnsnyW6WSaWFZLoGlzBz_4r1TGsm0Z-KSJyySTwfHx4btrm04dmltCUdxE7Ch_DIg17zdaEOzcqlSpeGJnwPfdL3Juj2OLABkHbhazkFY6p_p-P_mhwEUdqJcC__N_GkBIrdgMtI39AmfElTAONJ47X1WwwuvzYthKJ05lZ830RmFBg5mMLli-UuhtvfmTBhIKcrInPQCOLSmC-aDFwJEYaS8yhcqM3_-X08kJceM1LI0So0BbJTyIZ74FI9pWKeY2ay3hFFvNqKdduCYmytGRGmsY4BLaM615KAzNFIzYug1cPAM00eIVxxjWw6tTX2URd7qUPYNxmbQV2T2gJgElyhEKfCylO5H30gSioz2yAHoDOdYi9Ey0KalUVOGZw1_uwDVNPEfnm4kgoQqtaQE",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhDYkaiuMWBmDfmWcyXL4gbFG9sP_Ki_vWM65nWD8f6YiQBDe8-26a_yfqiLJC_-WnoDnUI8xEJZCRFSpNYd0gzvX_9pfKY7zSTv_86j4L8bZyZ8KDvbRaHXFtSeftsBiYYFvhk5PxKTjBkL0QQoRCXypKnE3VgmeuMgXaAIhAMQKU3Pi5uzljgYL6oFuvnsJ4r7qdX2tqErjx4mJeWIueTnJLtHEEmC-i0cZS9DFPX4hqm1BNT84un37Xo34GoA3vgj3Fr3WXU1qHNaJPB1sMiuamb2oohTgLPCgz6AZmZ2b60sHY6eDJMnlsQ1dRb4K0R-z6JDReornDn-YOCxLYmFuEN4_rc7pYF9Iy76kLIQ242bkjHAUzes5UOjTI1L1VAtRakyMPjlsJw2BWp0hw7ULnKDGNam-OyB9xNkYITtgA3qJjYAgO8DrhV0uooWlCNnVBbkfqWMoOQxAwEPqbqdamwrgkArIlfJnNAPhpd9U_kbjDMbel4HflGXFo4_509ROUcHLJAGVksmpbgVjFQI4kbw_MKaCvgzCLPz60-LJ23gs9HKwsdO0CYmZ7cSjZ2aawj0a0RISo0FmvnwZIH9zMXUXOkDihJRR3ts7IEXZt5mqX38PFS3Wy_ntMVclGEERFdsCO6DL-Ns6PWty-UPSygsiNhi1sZ0XMxPwWfEmvLXnxpAyYXTs_5haSqwDJYhxaqFrw3DFTR1MEvd3f-CBpHf0E_sH4HW_w-VxIP909oyyyhC4c0v7OZbPbiwxcy1CWoOnwmz6Up_aU6Nl4ApJIQ2ld-K4eI7X7tmGdHGfTHO84undAZ5RQ-9sSwvBiMwFOib-F7thd5Z7eIDi-ZCX9_5gfoWUaAE8Te9cziThnsnyW6WSaWFZLoGlzBz_4r1TGsm0Z-KSJyySTwfHx4btrm04dmltCUdxE7Ch_DIg17zdaEOzcqlSpeGJnwPfdL3Juj2OLABkHbhazkFY6p_p-P_mhwEUdqJcC__N_GkBIrdgMtI39AmfElTAONJ47X1WwwuvzYthKJ05lZ830RmFBg5mMLli-UuhtvfmTBhIKcrInPQCOLSmC-aDFwJEYaS8yhcqM3_-X08kJceM1LI0So0BbJTyIZ74FI9pWKeY2ay3hFFvNqKdduCYmytGRGmsY4BLaM615KAzNFIzYug1cPAM00eIVxxjWw6tTX2URd7qUPYNxmbQV2T2gJgElyhEKfCylO5H30gSioz2yAHoDOdYi9Ey0KalUVOGZw1_uwDVNPEfnm4kgoQqtaQE",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "U2",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhDYkaiuMWBmDfmWcyXL4gbFG9sP_Ki_vWM65nWD8f6YiQBDe8-26a_yfqiLJC_-WnoDnUI8xEJZCRFSpNYd0gzvX_9pfKY7zSTv_86j4L8bZyZ8KDvbRaHXFtSeftsBiYYFvhk5PxKTjBkL0QQoRCXypKnE3VgmeuMgXaAIhAMQKU3Pi5uzljgYL6oFuvnsJ4r7qdX2tqErjx4mJeWIueTnJLtHEEmC-i0cZS9DFPX4hqm1BNT84un37Xo34GoA3vgj3Fr3WXU1qHNaJPB1sMiuamb2oohTgLPCgz6AZmZ2b60sHY6eDJMnlsQ1dRb4K0R-z6JDReornDn-YOCxLYmFuEN4_rc7pYF9Iy76kLIQ242bkjHAUzes5UOjTI1L1VAtRakyMPjlsJw2BWp0hw7ULnKDGNam-OyB9xNkYITtgA3qJjYAgO8DrhV0uooWlCNnVBbkfqWMoOQxAwEPqbqdamwrgkArIlfJnNAPhpd9U_kbjDMbel4HflGXFo4_509ROUcHLJAGVksmpbgVjFQI4kbw_MKaCvgzCLPz60-LJ23gs9HKwsdO0CYmZ7cSjZ2aawj0a0RISo0FmvnwZIH9zMXUXOkDihJRR3ts7IEXZt5mqX38PFS3Wy_ntMVclGEERFdsCO6DL-Ns6PWty-UPSygsiNhi1sZ0XMxPwWfEmvLXnxpAyYXTs_5haSqwDJYhxaqFrw3DFTR1MEvd3f-CBpHf0E_sH4HW_w-VxIP909oyyyhC4c0v7OZbPbiwxcy1CWoOnwmz6Up_aU6Nl4ApJIQ2ld-K4eI7X7tmGdHGfTHO84undAZ5RQ-9sSwvBiMwFOib-F7thd5Z7eIDi-ZCX9_5gfoWUaAE8Te9cziThnsnyW6WSaWFZLoGlzBz_4r1TGsm0Z-KSJyySTwfHx4btrm04dmltCUdxE7Ch_DIg17zdaEOzcqlSpeGJnwPfdL3Juj2OLABkHbhazkFY6p_p-P_mhwEUdqJcC__N_GkBIrdgMtI39AmfElTAONJ47X1WwwuvzYthKJ05lZ830RmFBg5mMLli-UuhtvfmTBhIKcrInPQCOLSmC-aDFwJEYaS8yhcqM3_-X08kJceM1LI0So0BbJTyIZ74FI9pWKeY2ay3hFFvNqKdduCYmytGRGmsY4BLaM615KAzNFIzYug1cPAM00eIVxxjWw6tTX2URd7qUPYNxmbQV2T2gJgElyhEKfCylO5H30gSioz2yAHoDOdYi9Ey0KalUVOGZw1_uwDVNPEfnm4kgoQqtaQE",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "13:05",
                "destination": "SFO",
                "duration": "41h33m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "IBZ",
                    "cityFrom": "Seville",
                    "cityTo": "Ibiza",
                    "combination_id": "04b80f354e2a0000048c9b92",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 2424,
                    "flyFrom": "SVQ",
                    "flyTo": "IBZ",
                    "guarantee": false,
                    "id": "04b80f354e2a0000048c9b92_0",
                    "local_arrival": "2024-10-14T14:25:00.000Z",
                    "local_departure": "2024-10-14T13:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2424",
                    "return": 0,
                    "utc_arrival": "2024-10-14T12:25:00.000Z",
                    "utc_departure": "2024-10-14T11:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "IBZ",
                    "cityCodeTo": "LON",
                    "cityFrom": "Ibiza",
                    "cityTo": "London",
                    "combination_id": "0f3522f54e2b00003abc8da6",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8106,
                    "flyFrom": "IBZ",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0f3522f54e2b00003abc8da6_0",
                    "local_arrival": "2024-10-15T02:05:00.000Z",
                    "local_departure": "2024-10-15T00:40:00.000Z",
                    "operating_carrier": "U2",
                    "operating_flight_no": "8106",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:05:00.000Z",
                    "utc_departure": "2024-10-14T22:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1101.57,
            "totalPrice": 796
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFDJ_qZzf6wTndVg8Pa8RZivfvO7SaDQrZ10Hw22xZhgD8Ox0Y6HuYS-1TqUc178OLZbp8kYxKPpSPQi_vbzTyn_HqYo-_p2tZg0Ne828_HCz7SJVllAXA5ff3jKci1Yk-ignE9-9zCaBR3Y2vqhXje4g5PH9kK1Adj4Ceu7JIDb1H6hjzfKn_n1oOzPlaZ3_BDsACyoFKgOVcaXd_XtcOwl1dc85NXc0zgPXgx-qj5zs_8NPEquc_kC27kS8h2rZju9BTCaEd1FItskoElB77jBti-3l3dD35rtaifaMCQH2G8ddAuWrUiNBWcpuPeXgaWJu89D_fMk-EzRioezmAThYFzjpHty-0fj6zkMgoZfGfSafEmT3O4ITijlg8uFnR47ElSJhonnYNojAh7tpUOV4mI8L_2BIz8oT7nTTWOeVhYOWzn2b4Y71iWDYhl8FMvCXKrVsSRxuv-HjL5KyWvCLFe-v2ndJMURM_3ouJgir7wQARVrFHOUK0GXh-eotqskRqDxG2z7Xsx5ZWnNEBA-XVAOoVy4HM05-xSGJOrNZ08YoToxeOmb6_7b0JvRYaYGsdz9VriWwf5nm4oX-kreLX3Y2IVTS0SJqf_Z6MRH8sekSFXZSw8QwVGtbyrQutDkPKCX1uPJ8Ok-YZdsiFQLXYtYkq20Bwclu8JRVESAAOFWPCYYE88rO8RBDurCo-KF5_6__uYbSCmybuUDkRExYMtxcnxsKFJGT-AjGk9yQcHy19K-r422p_QpRwm4JARRJ47731y3rmjt7on1AvRu9tbGukzGrdGUSUFwK8qEA_yVfeWA61g0-6G6owh65AXamOjNNSXlvbRv2tLWJNMVpgwHEdwGcZCuIOBVeKwP24-X-guf8o2uKRsiQsVlfa3QfMpvlRLz6V6gHLBvJdX_we7R6gc_bky5AFAJ_JS1yCfmM9-ZOiy-5xD8mRRnFpiBNxpI1oCqPTrfxcrE6mexy_aRC3PNYczaxMiieunwcRzDHoi7GFVd9oBqMsTiavlXH3p7eA-Hq6gSRiKHPqp19iUqpKJoYTSpOMEM_Jwxf_GJvQKBPqvbSYQFX8ZTbeaZSxCh8MTyxlp7NwZUVt7wTcawxCOzxghijYpTAUSBfRfXIJuKXguRi9aF2iRMdnFoFHtS0FS56rMd1mbUx8urcxeq3d-ArXdx68tA5_sMhXZdRMHvD4hQJbUY9XPL1doWk71p4UM4k68WFe2qrN0w9oVpiei9GVESJ2cq0TmUitk717gv13vzDMEXzwoq__rvqKm9nPJxFhvH9loCcbH9XZbhTpRsA1irQLCNJV5QnJ_ZoVviBPzD9QMCcWSoIP2sc4SighsXMK2SGH5UWcg==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFDJ_qZzf6wTndVg8Pa8RZivfvO7SaDQrZ10Hw22xZhgD8Ox0Y6HuYS-1TqUc178OLZbp8kYxKPpSPQi_vbzTyn_HqYo-_p2tZg0Ne828_HCz7SJVllAXA5ff3jKci1Yk-ignE9-9zCaBR3Y2vqhXje4g5PH9kK1Adj4Ceu7JIDb1H6hjzfKn_n1oOzPlaZ3_BDsACyoFKgOVcaXd_XtcOwl1dc85NXc0zgPXgx-qj5zs_8NPEquc_kC27kS8h2rZju9BTCaEd1FItskoElB77jBti-3l3dD35rtaifaMCQH2G8ddAuWrUiNBWcpuPeXgaWJu89D_fMk-EzRioezmAThYFzjpHty-0fj6zkMgoZfGfSafEmT3O4ITijlg8uFnR47ElSJhonnYNojAh7tpUOV4mI8L_2BIz8oT7nTTWOeVhYOWzn2b4Y71iWDYhl8FMvCXKrVsSRxuv-HjL5KyWvCLFe-v2ndJMURM_3ouJgir7wQARVrFHOUK0GXh-eotqskRqDxG2z7Xsx5ZWnNEBA-XVAOoVy4HM05-xSGJOrNZ08YoToxeOmb6_7b0JvRYaYGsdz9VriWwf5nm4oX-kreLX3Y2IVTS0SJqf_Z6MRH8sekSFXZSw8QwVGtbyrQutDkPKCX1uPJ8Ok-YZdsiFQLXYtYkq20Bwclu8JRVESAAOFWPCYYE88rO8RBDurCo-KF5_6__uYbSCmybuUDkRExYMtxcnxsKFJGT-AjGk9yQcHy19K-r422p_QpRwm4JARRJ47731y3rmjt7on1AvRu9tbGukzGrdGUSUFwK8qEA_yVfeWA61g0-6G6owh65AXamOjNNSXlvbRv2tLWJNMVpgwHEdwGcZCuIOBVeKwP24-X-guf8o2uKRsiQsVlfa3QfMpvlRLz6V6gHLBvJdX_we7R6gc_bky5AFAJ_JS1yCfmM9-ZOiy-5xD8mRRnFpiBNxpI1oCqPTrfxcrE6mexy_aRC3PNYczaxMiieunwcRzDHoi7GFVd9oBqMsTiavlXH3p7eA-Hq6gSRiKHPqp19iUqpKJoYTSpOMEM_Jwxf_GJvQKBPqvbSYQFX8ZTbeaZSxCh8MTyxlp7NwZUVt7wTcawxCOzxghijYpTAUSBfRfXIJuKXguRi9aF2iRMdnFoFHtS0FS56rMd1mbUx8urcxeq3d-ArXdx68tA5_sMhXZdRMHvD4hQJbUY9XPL1doWk71p4UM4k68WFe2qrN0w9oVpiei9GVESJ2cq0TmUitk717gv13vzDMEXzwoq__rvqKm9nPJxFhvH9loCcbH9XZbhTpRsA1irQLCNJV5QnJ_ZoVviBPzD9QMCcWSoIP2sc4SighsXMK2SGH5UWcg==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GFDJ_qZzf6wTndVg8Pa8RZivfvO7SaDQrZ10Hw22xZhgD8Ox0Y6HuYS-1TqUc178OLZbp8kYxKPpSPQi_vbzTyn_HqYo-_p2tZg0Ne828_HCz7SJVllAXA5ff3jKci1Yk-ignE9-9zCaBR3Y2vqhXje4g5PH9kK1Adj4Ceu7JIDb1H6hjzfKn_n1oOzPlaZ3_BDsACyoFKgOVcaXd_XtcOwl1dc85NXc0zgPXgx-qj5zs_8NPEquc_kC27kS8h2rZju9BTCaEd1FItskoElB77jBti-3l3dD35rtaifaMCQH2G8ddAuWrUiNBWcpuPeXgaWJu89D_fMk-EzRioezmAThYFzjpHty-0fj6zkMgoZfGfSafEmT3O4ITijlg8uFnR47ElSJhonnYNojAh7tpUOV4mI8L_2BIz8oT7nTTWOeVhYOWzn2b4Y71iWDYhl8FMvCXKrVsSRxuv-HjL5KyWvCLFe-v2ndJMURM_3ouJgir7wQARVrFHOUK0GXh-eotqskRqDxG2z7Xsx5ZWnNEBA-XVAOoVy4HM05-xSGJOrNZ08YoToxeOmb6_7b0JvRYaYGsdz9VriWwf5nm4oX-kreLX3Y2IVTS0SJqf_Z6MRH8sekSFXZSw8QwVGtbyrQutDkPKCX1uPJ8Ok-YZdsiFQLXYtYkq20Bwclu8JRVESAAOFWPCYYE88rO8RBDurCo-KF5_6__uYbSCmybuUDkRExYMtxcnxsKFJGT-AjGk9yQcHy19K-r422p_QpRwm4JARRJ47731y3rmjt7on1AvRu9tbGukzGrdGUSUFwK8qEA_yVfeWA61g0-6G6owh65AXamOjNNSXlvbRv2tLWJNMVpgwHEdwGcZCuIOBVeKwP24-X-guf8o2uKRsiQsVlfa3QfMpvlRLz6V6gHLBvJdX_we7R6gc_bky5AFAJ_JS1yCfmM9-ZOiy-5xD8mRRnFpiBNxpI1oCqPTrfxcrE6mexy_aRC3PNYczaxMiieunwcRzDHoi7GFVd9oBqMsTiavlXH3p7eA-Hq6gSRiKHPqp19iUqpKJoYTSpOMEM_Jwxf_GJvQKBPqvbSYQFX8ZTbeaZSxCh8MTyxlp7NwZUVt7wTcawxCOzxghijYpTAUSBfRfXIJuKXguRi9aF2iRMdnFoFHtS0FS56rMd1mbUx8urcxeq3d-ArXdx68tA5_sMhXZdRMHvD4hQJbUY9XPL1doWk71p4UM4k68WFe2qrN0w9oVpiei9GVESJ2cq0TmUitk717gv13vzDMEXzwoq__rvqKm9nPJxFhvH9loCcbH9XZbhTpRsA1irQLCNJV5QnJ_ZoVviBPzD9QMCcWSoIP2sc4SighsXMK2SGH5UWcg==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1071.65,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH4_7URNvN-KXdKjkL991r52zA8-a54jvi7KAkVMWWBuBrPHaANhJvsumR9SBgSfL3FoJ36_Jmk7zRFb7QsioAhJFmqO7C8ySRa_iamD5PmMe55XDxTa4JuO9DLxJ71qatT6pC0qqzWHj0oVqKX7ypOl5AI-4l3ka4ydKxJzdoI5_4VNQ3k4QrPDz8kzJxUNTlLu3Jn4zhHuPEkJLMzmcmk3gfsJLXscvFKR6TPz70zjTNcwFhpT6YHXWHYJc573qMyVg9s82TLq2IcBFl2cYVtoY4dtG5FSfvlQ5mJcJ82mJnteseCl8p6ReZlcwld2CakMHrbbjkTFA_eutlnKkH56ih6rla9m-czastsmjEWrzghuN0VPv38ReFpyjnUR9D8aNJSndwDSM_RkzPv8E-M04wq9_e2LiTGgBV1mRLvfoiu1dSRl9-qd7KAJ3-R9MFYjdkyX_3GB0ibUth-vHhY-oFOAGrdf96VXTUrkn9r4HzW7H5XIAlDo4kevN1LtIMZyZke9g0k5--RClIaVOXc9TKJ7GjhWQwS7RhopICbpbMT75daS71T5vxjYNtH3mJXjG9aqiC9XM5osnexlWbM9rfq5dEc1-chnX_QBbq9-btYUEFeBUZsWjpXyylFosIY5WypwN7cdd5zmSRGbMategoe0okuxjYF128PcZVm_J0CECZ7Y6IjPWi1Z2LLA0l6ulYjVKNhMepnzd235TLLMg6_8N9Yab4HWIyRft-ZMesh7WqgqcAyuNJKt82xKjG9xkIZoCQ8RZXqqCb8L4pXN1ugLSwXJnn1NZ6uuIIbdyvQjgLp4QgWClv2jG27GW3BOX1sFtDCvOeyGSfWaiLUHykMySeqdRJ8SYDPkqCk6gXEGSvXyOIaJTd6rtW0UjyIHJvG-cfT8OrNYoOr5EV1_r0fVmawn5rm06aYaSTeFkwrmNa0b00B1wFqmDzQOzs81-tVMtde8M4Xrc5x85VrzKchY1GhlU76FhN2IJzJuNhZkmgzce-W5tCVbm40GpVYTYiMAw6yzBQaDMlQLJV8vn5nXoQBu1Mvmwz3RmPYDm55GxhF4C1h3Yb5oPePkNWO_eGH77p_KoAaPYTctDitea5Q-XryCpWCFx82EyPtH15BJJjoCeBa9c_g0ywBEdzcovYLr7TqqRdFfwBu9Oaz8BM1_qhwfw4YSAvcgoIcDoXu8caDINK7gZKQwOgt_tGiep1_RXSMtI2DsrPCdMIoeIAkQMeEOnvekiw2zyQQF5p8j6dqy5L4FXQpIQ2TmUTIdWjz6u4aT47s9fdyDmLcHcjU3y_3gYc6zWW3I2VxvBXiUYV6tPxWtyNeTh3tBM",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH4_7URNvN-KXdKjkL991r52zA8-a54jvi7KAkVMWWBuBrPHaANhJvsumR9SBgSfL3FoJ36_Jmk7zRFb7QsioAhJFmqO7C8ySRa_iamD5PmMe55XDxTa4JuO9DLxJ71qatT6pC0qqzWHj0oVqKX7ypOl5AI-4l3ka4ydKxJzdoI5_4VNQ3k4QrPDz8kzJxUNTlLu3Jn4zhHuPEkJLMzmcmk3gfsJLXscvFKR6TPz70zjTNcwFhpT6YHXWHYJc573qMyVg9s82TLq2IcBFl2cYVtoY4dtG5FSfvlQ5mJcJ82mJnteseCl8p6ReZlcwld2CakMHrbbjkTFA_eutlnKkH56ih6rla9m-czastsmjEWrzghuN0VPv38ReFpyjnUR9D8aNJSndwDSM_RkzPv8E-M04wq9_e2LiTGgBV1mRLvfoiu1dSRl9-qd7KAJ3-R9MFYjdkyX_3GB0ibUth-vHhY-oFOAGrdf96VXTUrkn9r4HzW7H5XIAlDo4kevN1LtIMZyZke9g0k5--RClIaVOXc9TKJ7GjhWQwS7RhopICbpbMT75daS71T5vxjYNtH3mJXjG9aqiC9XM5osnexlWbM9rfq5dEc1-chnX_QBbq9-btYUEFeBUZsWjpXyylFosIY5WypwN7cdd5zmSRGbMategoe0okuxjYF128PcZVm_J0CECZ7Y6IjPWi1Z2LLA0l6ulYjVKNhMepnzd235TLLMg6_8N9Yab4HWIyRft-ZMesh7WqgqcAyuNJKt82xKjG9xkIZoCQ8RZXqqCb8L4pXN1ugLSwXJnn1NZ6uuIIbdyvQjgLp4QgWClv2jG27GW3BOX1sFtDCvOeyGSfWaiLUHykMySeqdRJ8SYDPkqCk6gXEGSvXyOIaJTd6rtW0UjyIHJvG-cfT8OrNYoOr5EV1_r0fVmawn5rm06aYaSTeFkwrmNa0b00B1wFqmDzQOzs81-tVMtde8M4Xrc5x85VrzKchY1GhlU76FhN2IJzJuNhZkmgzce-W5tCVbm40GpVYTYiMAw6yzBQaDMlQLJV8vn5nXoQBu1Mvmwz3RmPYDm55GxhF4C1h3Yb5oPePkNWO_eGH77p_KoAaPYTctDitea5Q-XryCpWCFx82EyPtH15BJJjoCeBa9c_g0ywBEdzcovYLr7TqqRdFfwBu9Oaz8BM1_qhwfw4YSAvcgoIcDoXu8caDINK7gZKQwOgt_tGiep1_RXSMtI2DsrPCdMIoeIAkQMeEOnvekiw2zyQQF5p8j6dqy5L4FXQpIQ2TmUTIdWjz6u4aT47s9fdyDmLcHcjU3y_3gYc6zWW3I2VxvBXiUYV6tPxWtyNeTh3tBM",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GH4_7URNvN-KXdKjkL991r52zA8-a54jvi7KAkVMWWBuBrPHaANhJvsumR9SBgSfL3FoJ36_Jmk7zRFb7QsioAhJFmqO7C8ySRa_iamD5PmMe55XDxTa4JuO9DLxJ71qatT6pC0qqzWHj0oVqKX7ypOl5AI-4l3ka4ydKxJzdoI5_4VNQ3k4QrPDz8kzJxUNTlLu3Jn4zhHuPEkJLMzmcmk3gfsJLXscvFKR6TPz70zjTNcwFhpT6YHXWHYJc573qMyVg9s82TLq2IcBFl2cYVtoY4dtG5FSfvlQ5mJcJ82mJnteseCl8p6ReZlcwld2CakMHrbbjkTFA_eutlnKkH56ih6rla9m-czastsmjEWrzghuN0VPv38ReFpyjnUR9D8aNJSndwDSM_RkzPv8E-M04wq9_e2LiTGgBV1mRLvfoiu1dSRl9-qd7KAJ3-R9MFYjdkyX_3GB0ibUth-vHhY-oFOAGrdf96VXTUrkn9r4HzW7H5XIAlDo4kevN1LtIMZyZke9g0k5--RClIaVOXc9TKJ7GjhWQwS7RhopICbpbMT75daS71T5vxjYNtH3mJXjG9aqiC9XM5osnexlWbM9rfq5dEc1-chnX_QBbq9-btYUEFeBUZsWjpXyylFosIY5WypwN7cdd5zmSRGbMategoe0okuxjYF128PcZVm_J0CECZ7Y6IjPWi1Z2LLA0l6ulYjVKNhMepnzd235TLLMg6_8N9Yab4HWIyRft-ZMesh7WqgqcAyuNJKt82xKjG9xkIZoCQ8RZXqqCb8L4pXN1ugLSwXJnn1NZ6uuIIbdyvQjgLp4QgWClv2jG27GW3BOX1sFtDCvOeyGSfWaiLUHykMySeqdRJ8SYDPkqCk6gXEGSvXyOIaJTd6rtW0UjyIHJvG-cfT8OrNYoOr5EV1_r0fVmawn5rm06aYaSTeFkwrmNa0b00B1wFqmDzQOzs81-tVMtde8M4Xrc5x85VrzKchY1GhlU76FhN2IJzJuNhZkmgzce-W5tCVbm40GpVYTYiMAw6yzBQaDMlQLJV8vn5nXoQBu1Mvmwz3RmPYDm55GxhF4C1h3Yb5oPePkNWO_eGH77p_KoAaPYTctDitea5Q-XryCpWCFx82EyPtH15BJJjoCeBa9c_g0ywBEdzcovYLr7TqqRdFfwBu9Oaz8BM1_qhwfw4YSAvcgoIcDoXu8caDINK7gZKQwOgt_tGiep1_RXSMtI2DsrPCdMIoeIAkQMeEOnvekiw2zyQQF5p8j6dqy5L4FXQpIQ2TmUTIdWjz6u4aT47s9fdyDmLcHcjU3y_3gYc6zWW3I2VxvBXiUYV6tPxWtyNeTh3tBM",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1098.32,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 797,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyNDSEqIJQZqgHTcxSV5g5l1o4YzpH4DGT0JzCu0zUwKzc07dyeSGoFa2dsrhn3LJPbe9ieLNfxj_a3xX5A3rL4rsXEQTSF8romGy_wlcbAp44ibDwCCK_2LgnqE-QzCJsVZb0laKKpU4482D_P2tZ9IzD_cF7cS7cNdJsoTHa-toqvA76iEaknjopu43P3gjmY4ny_o70_v6vzcDe2WvoZY8C1sDnJooNbggcnHnaITSluWo443qbDAG_C2V7yjT8j6skwEuTWhKtLLA7KQCD2JlpQ4YlYpYyAEkcrvgmZ2E-XVOKr9_6qH1qWmjkeeI1Itq4Y4kdjguBNvJPK6DazUY5pCSyeSD9wcKf8e7SRVEaBNe8jfaRJgo99incOSV0-F2nlXWTsHH2C7a3HME0kUrGQEyGUK5LcxCfIBaAdU-NLGnyf8mBGennG3O1UuIwIZVExe2qLchIxNTYR1ZJFGAXOWaTxjp9yVQGLyJ-GiSCx-MoyYwuwa2aOZUiiRji0ndxhVgEQRMoLd2SaBx8gqDtn1KtlRkS5lczTS2UTsMCkNhPLEivpdqgVZ-l8PUpt8bUsDNJvxvHRS-zawGUFjLUzCOtRzlAcC7JP6AlOYWaJiBNGz31UrpCvsFuCUgt3FuGfKqA8QLIM2aGXSfkAeTT8OQEsCGkNeXqtjGOYMd61dl92tjAtCCdiVUlzauGu93xT5s4nDE_aE0XBr_Ri5bePv1euM7WTGswgtMhLMmOx1FVvLpFYCWCyTPqnJkzZ-059tHxFQlECOrp-L5GkGCNLF1BPl5xn4T_DOq_a2XHNU-wni916T08L8SW1x3QaXRC5Eg4J2US58dTFqztTJQstBXVJo1pSRInKFVU3Tem9bkpFpoz3yiOwYv0SKawK8gLG9L0-caYIAT79bHLAcdmJ4ebtyOEbIsT-kne2LkEa96BpUnVEpYVoRRyL_PYLHp_5neUeRgIw0GX_TgFd1sIAPQ2AZez7fcejFHRZr1L4u2f3ln-ys0cQFTUoQe3pKat4vHXuP8yR_lr8QcAPfUOkm4QzxOvqAtgDBWnn5z_QVPKtFGpCi7CP1QlAByU5wCIc8rAglQ-fS78Zx9LxVIVW7JtS-nn-UvyBS1OhWmJULcSF9mb-xuR-EhFGMhBuGtNZp_IAIWKOkVJbxDUI55wF_xPxUMzBxyMQ9tmNu53iOYH4YHgLBxH-YqHkInX2n8VUa9TFxy20ZyyQlAeOSAO8isYsOpJr2h2GIzSxg=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyNDSEqIJQZqgHTcxSV5g5l1o4YzpH4DGT0JzCu0zUwKzc07dyeSGoFa2dsrhn3LJPbe9ieLNfxj_a3xX5A3rL4rsXEQTSF8romGy_wlcbAp44ibDwCCK_2LgnqE-QzCJsVZb0laKKpU4482D_P2tZ9IzD_cF7cS7cNdJsoTHa-toqvA76iEaknjopu43P3gjmY4ny_o70_v6vzcDe2WvoZY8C1sDnJooNbggcnHnaITSluWo443qbDAG_C2V7yjT8j6skwEuTWhKtLLA7KQCD2JlpQ4YlYpYyAEkcrvgmZ2E-XVOKr9_6qH1qWmjkeeI1Itq4Y4kdjguBNvJPK6DazUY5pCSyeSD9wcKf8e7SRVEaBNe8jfaRJgo99incOSV0-F2nlXWTsHH2C7a3HME0kUrGQEyGUK5LcxCfIBaAdU-NLGnyf8mBGennG3O1UuIwIZVExe2qLchIxNTYR1ZJFGAXOWaTxjp9yVQGLyJ-GiSCx-MoyYwuwa2aOZUiiRji0ndxhVgEQRMoLd2SaBx8gqDtn1KtlRkS5lczTS2UTsMCkNhPLEivpdqgVZ-l8PUpt8bUsDNJvxvHRS-zawGUFjLUzCOtRzlAcC7JP6AlOYWaJiBNGz31UrpCvsFuCUgt3FuGfKqA8QLIM2aGXSfkAeTT8OQEsCGkNeXqtjGOYMd61dl92tjAtCCdiVUlzauGu93xT5s4nDE_aE0XBr_Ri5bePv1euM7WTGswgtMhLMmOx1FVvLpFYCWCyTPqnJkzZ-059tHxFQlECOrp-L5GkGCNLF1BPl5xn4T_DOq_a2XHNU-wni916T08L8SW1x3QaXRC5Eg4J2US58dTFqztTJQstBXVJo1pSRInKFVU3Tem9bkpFpoz3yiOwYv0SKawK8gLG9L0-caYIAT79bHLAcdmJ4ebtyOEbIsT-kne2LkEa96BpUnVEpYVoRRyL_PYLHp_5neUeRgIw0GX_TgFd1sIAPQ2AZez7fcejFHRZr1L4u2f3ln-ys0cQFTUoQe3pKat4vHXuP8yR_lr8QcAPfUOkm4QzxOvqAtgDBWnn5z_QVPKtFGpCi7CP1QlAByU5wCIc8rAglQ-fS78Zx9LxVIVW7JtS-nn-UvyBS1OhWmJULcSF9mb-xuR-EhFGMhBuGtNZp_IAIWKOkVJbxDUI55wF_xPxUMzBxyMQ9tmNu53iOYH4YHgLBxH-YqHkInX2n8VUa9TFxy20ZyyQlAeOSAO8isYsOpJr2h2GIzSxg=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "U2",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyNDSEqIJQZqgHTcxSV5g5l1o4YzpH4DGT0JzCu0zUwKzc07dyeSGoFa2dsrhn3LJPbe9ieLNfxj_a3xX5A3rL4rsXEQTSF8romGy_wlcbAp44ibDwCCK_2LgnqE-QzCJsVZb0laKKpU4482D_P2tZ9IzD_cF7cS7cNdJsoTHa-toqvA76iEaknjopu43P3gjmY4ny_o70_v6vzcDe2WvoZY8C1sDnJooNbggcnHnaITSluWo443qbDAG_C2V7yjT8j6skwEuTWhKtLLA7KQCD2JlpQ4YlYpYyAEkcrvgmZ2E-XVOKr9_6qH1qWmjkeeI1Itq4Y4kdjguBNvJPK6DazUY5pCSyeSD9wcKf8e7SRVEaBNe8jfaRJgo99incOSV0-F2nlXWTsHH2C7a3HME0kUrGQEyGUK5LcxCfIBaAdU-NLGnyf8mBGennG3O1UuIwIZVExe2qLchIxNTYR1ZJFGAXOWaTxjp9yVQGLyJ-GiSCx-MoyYwuwa2aOZUiiRji0ndxhVgEQRMoLd2SaBx8gqDtn1KtlRkS5lczTS2UTsMCkNhPLEivpdqgVZ-l8PUpt8bUsDNJvxvHRS-zawGUFjLUzCOtRzlAcC7JP6AlOYWaJiBNGz31UrpCvsFuCUgt3FuGfKqA8QLIM2aGXSfkAeTT8OQEsCGkNeXqtjGOYMd61dl92tjAtCCdiVUlzauGu93xT5s4nDE_aE0XBr_Ri5bePv1euM7WTGswgtMhLMmOx1FVvLpFYCWCyTPqnJkzZ-059tHxFQlECOrp-L5GkGCNLF1BPl5xn4T_DOq_a2XHNU-wni916T08L8SW1x3QaXRC5Eg4J2US58dTFqztTJQstBXVJo1pSRInKFVU3Tem9bkpFpoz3yiOwYv0SKawK8gLG9L0-caYIAT79bHLAcdmJ4ebtyOEbIsT-kne2LkEa96BpUnVEpYVoRRyL_PYLHp_5neUeRgIw0GX_TgFd1sIAPQ2AZez7fcejFHRZr1L4u2f3ln-ys0cQFTUoQe3pKat4vHXuP8yR_lr8QcAPfUOkm4QzxOvqAtgDBWnn5z_QVPKtFGpCi7CP1QlAByU5wCIc8rAglQ-fS78Zx9LxVIVW7JtS-nn-UvyBS1OhWmJULcSF9mb-xuR-EhFGMhBuGtNZp_IAIWKOkVJbxDUI55wF_xPxUMzBxyMQ9tmNu53iOYH4YHgLBxH-YqHkInX2n8VUa9TFxy20ZyyQlAeOSAO8isYsOpJr2h2GIzSxg=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "13:05",
                "destination": "SFO",
                "duration": "41h33m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "IBZ",
                    "cityFrom": "Seville",
                    "cityTo": "Ibiza",
                    "combination_id": "04b80f354e2a0000048c9b92",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 2424,
                    "flyFrom": "SVQ",
                    "flyTo": "IBZ",
                    "guarantee": false,
                    "id": "04b80f354e2a0000048c9b92_0",
                    "local_arrival": "2024-10-14T14:25:00.000Z",
                    "local_departure": "2024-10-14T13:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2424",
                    "return": 0,
                    "utc_arrival": "2024-10-14T12:25:00.000Z",
                    "utc_departure": "2024-10-14T11:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "IBZ",
                    "cityCodeTo": "LON",
                    "cityFrom": "Ibiza",
                    "cityTo": "London",
                    "combination_id": "0f3522f54e2b00003abc8da6",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8106,
                    "flyFrom": "IBZ",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0f3522f54e2b00003abc8da6_0",
                    "local_arrival": "2024-10-15T02:05:00.000Z",
                    "local_departure": "2024-10-15T00:40:00.000Z",
                    "operating_carrier": "U2",
                    "operating_flight_no": "8106",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:05:00.000Z",
                    "utc_departure": "2024-10-14T22:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1102.41,
            "totalPrice": 797
          },
          {
            "allPassengerTotalPrice": 798,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDh0hFGZbP1bzuGlckelkiVIH-7alHQtZumGmZfSQLxQZULXgdqge38KemV36jsdM1dYUuQp3g0laUbBIQdnFeckRpIqfpyh8SZknzdSdVV5VivWOs2tuRsIlz-fcUtXsIB36b2qSzoj-UkRd4jUhj0N6BFzdsH501Q5zERdimVUkdAppURrOj1JPAxK5t3kUeCCnGSBCdx4XQtuLW81_sBbyIuOYFUnGDeEKrvVbQ8Agc1PZ4XQUH4164fWocjwQF6BrzETi7LmmpWLid97fCl1-HgB4U23Vg0V9wd7tJ6IF_MeMi10WOecpsXYlxLho6wdYVL0qb0wLgl8iOJl_kA5fTiofAYz1O0gUGB_iqiRB1IyZJ0-yBz4bONv0VoZ3cR11C8dLYoBZBzOcKy6PJHBiaTcBsyvQAyLEp93XLfZ1n7g9nlAxokmUzoVYx8x_Y4OLiSJ9thT1P2mAb1X933yQ3Vc5b-64ug4aur1u7FSLasubFjjJKbbR60ATf-Sp50McWDba9XjPlgwq4Zn2mCL7sK9wWLXoACzg5xqv9s8fbiHskduB3CuWWjvuaBLLfP6PI9qy4JcTKL-HJTL1wkXA-DbrnUtHpXWTWUjRFhDvDUEUeFby6Rv9TEnkxpdhnJR9SsXwtRZOVf91nW2sisVb4kKTE5WRcD7432pmf0PznMJ0uC1PQP9rCWu_bqdewhN3HHNe7tzgc-w8fOcsJubR7JvRXmgpNtza7fZK51rtUVpW95Z4AsiLD4wbhdJUCCgQEw636ExiqV9-Fke35R1ujN9SOXxJzhrz5Uh1Yqhzv8wXkzl1I0xjWnCck1JblRPdoIF8UQ4oWLc4Z3GpDqwPkyLxc6TLrG3DNR-70pv68lI509ABq6SJS4oox4_UW36GzohFf_09de1TAmB_s97slM0j42VNIE22J-172-MDwUHD0CX5d-LcLEIZSSW6Wprn3hoND907hcP-TDKhFelTnujWDaPsSXRB5x2m2mrYm2_D7TUPNzY-3PaRm75IhLuONr36M7J5wcZErko1nzHwyi6063Owun4kpzZbn8Sz7fNXbdEpjF5nmO9UezyyOaahyEZJAwABSJYntwPi0Da4lLxlGB6y8lDzXOWUoXSIG4wGA6A-fgWbXdWiLSZL_0l2AGtx1mLQu7kCKdB_jgpVMdGMr6UwtMUF1X86mf4=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDh0hFGZbP1bzuGlckelkiVIH-7alHQtZumGmZfSQLxQZULXgdqge38KemV36jsdM1dYUuQp3g0laUbBIQdnFeckRpIqfpyh8SZknzdSdVV5VivWOs2tuRsIlz-fcUtXsIB36b2qSzoj-UkRd4jUhj0N6BFzdsH501Q5zERdimVUkdAppURrOj1JPAxK5t3kUeCCnGSBCdx4XQtuLW81_sBbyIuOYFUnGDeEKrvVbQ8Agc1PZ4XQUH4164fWocjwQF6BrzETi7LmmpWLid97fCl1-HgB4U23Vg0V9wd7tJ6IF_MeMi10WOecpsXYlxLho6wdYVL0qb0wLgl8iOJl_kA5fTiofAYz1O0gUGB_iqiRB1IyZJ0-yBz4bONv0VoZ3cR11C8dLYoBZBzOcKy6PJHBiaTcBsyvQAyLEp93XLfZ1n7g9nlAxokmUzoVYx8x_Y4OLiSJ9thT1P2mAb1X933yQ3Vc5b-64ug4aur1u7FSLasubFjjJKbbR60ATf-Sp50McWDba9XjPlgwq4Zn2mCL7sK9wWLXoACzg5xqv9s8fbiHskduB3CuWWjvuaBLLfP6PI9qy4JcTKL-HJTL1wkXA-DbrnUtHpXWTWUjRFhDvDUEUeFby6Rv9TEnkxpdhnJR9SsXwtRZOVf91nW2sisVb4kKTE5WRcD7432pmf0PznMJ0uC1PQP9rCWu_bqdewhN3HHNe7tzgc-w8fOcsJubR7JvRXmgpNtza7fZK51rtUVpW95Z4AsiLD4wbhdJUCCgQEw636ExiqV9-Fke35R1ujN9SOXxJzhrz5Uh1Yqhzv8wXkzl1I0xjWnCck1JblRPdoIF8UQ4oWLc4Z3GpDqwPkyLxc6TLrG3DNR-70pv68lI509ABq6SJS4oox4_UW36GzohFf_09de1TAmB_s97slM0j42VNIE22J-172-MDwUHD0CX5d-LcLEIZSSW6Wprn3hoND907hcP-TDKhFelTnujWDaPsSXRB5x2m2mrYm2_D7TUPNzY-3PaRm75IhLuONr36M7J5wcZErko1nzHwyi6063Owun4kpzZbn8Sz7fNXbdEpjF5nmO9UezyyOaahyEZJAwABSJYntwPi0Da4lLxlGB6y8lDzXOWUoXSIG4wGA6A-fgWbXdWiLSZL_0l2AGtx1mLQu7kCKdB_jgpVMdGMr6UwtMUF1X86mf4=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GDh0hFGZbP1bzuGlckelkiVIH-7alHQtZumGmZfSQLxQZULXgdqge38KemV36jsdM1dYUuQp3g0laUbBIQdnFeckRpIqfpyh8SZknzdSdVV5VivWOs2tuRsIlz-fcUtXsIB36b2qSzoj-UkRd4jUhj0N6BFzdsH501Q5zERdimVUkdAppURrOj1JPAxK5t3kUeCCnGSBCdx4XQtuLW81_sBbyIuOYFUnGDeEKrvVbQ8Agc1PZ4XQUH4164fWocjwQF6BrzETi7LmmpWLid97fCl1-HgB4U23Vg0V9wd7tJ6IF_MeMi10WOecpsXYlxLho6wdYVL0qb0wLgl8iOJl_kA5fTiofAYz1O0gUGB_iqiRB1IyZJ0-yBz4bONv0VoZ3cR11C8dLYoBZBzOcKy6PJHBiaTcBsyvQAyLEp93XLfZ1n7g9nlAxokmUzoVYx8x_Y4OLiSJ9thT1P2mAb1X933yQ3Vc5b-64ug4aur1u7FSLasubFjjJKbbR60ATf-Sp50McWDba9XjPlgwq4Zn2mCL7sK9wWLXoACzg5xqv9s8fbiHskduB3CuWWjvuaBLLfP6PI9qy4JcTKL-HJTL1wkXA-DbrnUtHpXWTWUjRFhDvDUEUeFby6Rv9TEnkxpdhnJR9SsXwtRZOVf91nW2sisVb4kKTE5WRcD7432pmf0PznMJ0uC1PQP9rCWu_bqdewhN3HHNe7tzgc-w8fOcsJubR7JvRXmgpNtza7fZK51rtUVpW95Z4AsiLD4wbhdJUCCgQEw636ExiqV9-Fke35R1ujN9SOXxJzhrz5Uh1Yqhzv8wXkzl1I0xjWnCck1JblRPdoIF8UQ4oWLc4Z3GpDqwPkyLxc6TLrG3DNR-70pv68lI509ABq6SJS4oox4_UW36GzohFf_09de1TAmB_s97slM0j42VNIE22J-172-MDwUHD0CX5d-LcLEIZSSW6Wprn3hoND907hcP-TDKhFelTnujWDaPsSXRB5x2m2mrYm2_D7TUPNzY-3PaRm75IhLuONr36M7J5wcZErko1nzHwyi6063Owun4kpzZbn8Sz7fNXbdEpjF5nmO9UezyyOaahyEZJAwABSJYntwPi0Da4lLxlGB6y8lDzXOWUoXSIG4wGA6A-fgWbXdWiLSZL_0l2AGtx1mLQu7kCKdB_jgpVMdGMr6UwtMUF1X86mf4=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1044.41,
            "totalPrice": 798
          },
          {
            "allPassengerTotalPrice": 798,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G34iGRhigb2kq8eMaI0fEsWXKDGjXlRc9LSO_cR2cqFN5lGB7R4BaG6mxCP0tMkTs30WMmfechQC95jKWvQCFG5Y81CkG6DmHhpnQ-kRP6PklXZrVYRIHrmo50CZb5DB6_4OhJhN6zervu1e0-z0MyR93vIer93kjkgtIWqkJiAVQeptTQxPmNT4CXOYJXbCIzXUmnirLMVwsafispgCUMLoduHyZP7VGAcfxCN57TEE0A12LEWcDmHAIMIvNkoc4Kbodb_L7TCuMglPlHdXvuCf7FqCJVtXF5FWe52nYucXMVsvKDxOsvsGZlyo4wNbYyAb3y-ySZN4QfC5wOZ6kWsEt0sTYevpgNlZo7FSd1OqdQ5Zzm0Z6g8uUZjWmUfnykuPbUjn6E_SUeoQioP_Gsnxp-pZZSADIVz8N1VoUVm3mGTGfWWBMSCi2FqdGFjSQ9-fWUCFFr2_kpExELvl063Y4JwY-YxaJlwUkG_uOPR3kuYJTufjRfSiwFBod_VKi_FPW2QYH8hg1cS-doXb73SyQOV7j1DWBiBSr5E_Lwz6_ioN4Y98zf0IC529SOg4pXQB4HXHo4ZTOoSnovTQ4urn--J9c0q1McDrL57YShDdHFq4q-LJUxfOpaaE3mQA-nPsrfzc0rx7phoCD9izMjWLH2JxMDwaiA4zNmz6YsQCFowZ1ziFNsD2d_uKJAs0NPZyz4fli1Bu0ohagvOM8SE_ArYlk50WQpXOXmPt4t40hkx2LJywL1_AUJWaRaumpSs9icOpzrGQmkfwX2goKwBNKRtfzDOrt4i-GwfzoT8jXXtJN3hd9b_1Hup9Gur0j8_aIkMMpMatq39_F2X-oNzL-cykqTqagmT8y--SgIO6KfI-o2wcakBgbihkxOf94GuN9D8NusNTzVpjhzSI9EJmkIPtGIqbeC9eXIykibyRbHs78PvjLO9WF2DwWtHs0bnt1HoXAye4dEoi30r3xM4t27EKOgWhTC0axX4RLxEX72MyImUMd3jCPm9jh-4R80w3lULjOaDUVcNs8-vflgk_sQ9fPT0Qj3ftdt9wHMaRFTraIlyLJpk5pbU9jbUmXvrapI0BNmSyaQTBeBRUkaJMX3YIvYgH6jPAuYz74SNvWkxC5WufrtfWIuQET3zgWnjtJUFHAjmjcahD_71ebm0m-tDmpQNNiJMEU6sqbH6u_Hqg27EAS6oOg79fbPxa-",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G34iGRhigb2kq8eMaI0fEsWXKDGjXlRc9LSO_cR2cqFN5lGB7R4BaG6mxCP0tMkTs30WMmfechQC95jKWvQCFG5Y81CkG6DmHhpnQ-kRP6PklXZrVYRIHrmo50CZb5DB6_4OhJhN6zervu1e0-z0MyR93vIer93kjkgtIWqkJiAVQeptTQxPmNT4CXOYJXbCIzXUmnirLMVwsafispgCUMLoduHyZP7VGAcfxCN57TEE0A12LEWcDmHAIMIvNkoc4Kbodb_L7TCuMglPlHdXvuCf7FqCJVtXF5FWe52nYucXMVsvKDxOsvsGZlyo4wNbYyAb3y-ySZN4QfC5wOZ6kWsEt0sTYevpgNlZo7FSd1OqdQ5Zzm0Z6g8uUZjWmUfnykuPbUjn6E_SUeoQioP_Gsnxp-pZZSADIVz8N1VoUVm3mGTGfWWBMSCi2FqdGFjSQ9-fWUCFFr2_kpExELvl063Y4JwY-YxaJlwUkG_uOPR3kuYJTufjRfSiwFBod_VKi_FPW2QYH8hg1cS-doXb73SyQOV7j1DWBiBSr5E_Lwz6_ioN4Y98zf0IC529SOg4pXQB4HXHo4ZTOoSnovTQ4urn--J9c0q1McDrL57YShDdHFq4q-LJUxfOpaaE3mQA-nPsrfzc0rx7phoCD9izMjWLH2JxMDwaiA4zNmz6YsQCFowZ1ziFNsD2d_uKJAs0NPZyz4fli1Bu0ohagvOM8SE_ArYlk50WQpXOXmPt4t40hkx2LJywL1_AUJWaRaumpSs9icOpzrGQmkfwX2goKwBNKRtfzDOrt4i-GwfzoT8jXXtJN3hd9b_1Hup9Gur0j8_aIkMMpMatq39_F2X-oNzL-cykqTqagmT8y--SgIO6KfI-o2wcakBgbihkxOf94GuN9D8NusNTzVpjhzSI9EJmkIPtGIqbeC9eXIykibyRbHs78PvjLO9WF2DwWtHs0bnt1HoXAye4dEoi30r3xM4t27EKOgWhTC0axX4RLxEX72MyImUMd3jCPm9jh-4R80w3lULjOaDUVcNs8-vflgk_sQ9fPT0Qj3ftdt9wHMaRFTraIlyLJpk5pbU9jbUmXvrapI0BNmSyaQTBeBRUkaJMX3YIvYgH6jPAuYz74SNvWkxC5WufrtfWIuQET3zgWnjtJUFHAjmjcahD_71ebm0m-tDmpQNNiJMEU6sqbH6u_Hqg27EAS6oOg79fbPxa-",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G34iGRhigb2kq8eMaI0fEsWXKDGjXlRc9LSO_cR2cqFN5lGB7R4BaG6mxCP0tMkTs30WMmfechQC95jKWvQCFG5Y81CkG6DmHhpnQ-kRP6PklXZrVYRIHrmo50CZb5DB6_4OhJhN6zervu1e0-z0MyR93vIer93kjkgtIWqkJiAVQeptTQxPmNT4CXOYJXbCIzXUmnirLMVwsafispgCUMLoduHyZP7VGAcfxCN57TEE0A12LEWcDmHAIMIvNkoc4Kbodb_L7TCuMglPlHdXvuCf7FqCJVtXF5FWe52nYucXMVsvKDxOsvsGZlyo4wNbYyAb3y-ySZN4QfC5wOZ6kWsEt0sTYevpgNlZo7FSd1OqdQ5Zzm0Z6g8uUZjWmUfnykuPbUjn6E_SUeoQioP_Gsnxp-pZZSADIVz8N1VoUVm3mGTGfWWBMSCi2FqdGFjSQ9-fWUCFFr2_kpExELvl063Y4JwY-YxaJlwUkG_uOPR3kuYJTufjRfSiwFBod_VKi_FPW2QYH8hg1cS-doXb73SyQOV7j1DWBiBSr5E_Lwz6_ioN4Y98zf0IC529SOg4pXQB4HXHo4ZTOoSnovTQ4urn--J9c0q1McDrL57YShDdHFq4q-LJUxfOpaaE3mQA-nPsrfzc0rx7phoCD9izMjWLH2JxMDwaiA4zNmz6YsQCFowZ1ziFNsD2d_uKJAs0NPZyz4fli1Bu0ohagvOM8SE_ArYlk50WQpXOXmPt4t40hkx2LJywL1_AUJWaRaumpSs9icOpzrGQmkfwX2goKwBNKRtfzDOrt4i-GwfzoT8jXXtJN3hd9b_1Hup9Gur0j8_aIkMMpMatq39_F2X-oNzL-cykqTqagmT8y--SgIO6KfI-o2wcakBgbihkxOf94GuN9D8NusNTzVpjhzSI9EJmkIPtGIqbeC9eXIykibyRbHs78PvjLO9WF2DwWtHs0bnt1HoXAye4dEoi30r3xM4t27EKOgWhTC0axX4RLxEX72MyImUMd3jCPm9jh-4R80w3lULjOaDUVcNs8-vflgk_sQ9fPT0Qj3ftdt9wHMaRFTraIlyLJpk5pbU9jbUmXvrapI0BNmSyaQTBeBRUkaJMX3YIvYgH6jPAuYz74SNvWkxC5WufrtfWIuQET3zgWnjtJUFHAjmjcahD_71ebm0m-tDmpQNNiJMEU6sqbH6u_Hqg27EAS6oOg79fbPxa-",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1044.41,
            "totalPrice": 798
          },
          {
            "allPassengerTotalPrice": 798,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7GqDrsh_DnWeLzyLBr6Umm3lU8PCWdVH1FH-z7-oXFTZQfVrFi_zehtPayEqQJs8kJgirpR6sUWZ4vzDGN5Ks6-ChWRYaLMdjFKkG1Jmg4xBU8m8oIUiLk0gVNoIiQ5dGk7SMhyp9Q4dxVlF_a_5Doe0TMX6nEKtM44slhExCmnrTxD263bx8vYKzqXuOKlANOykbGmHczDbRfFdlB7vfjPLRfQYBr1ptf-SuLkyaCZ3PMzWtZzPZPsE5zAUNwalvCVp9B1RImY3ZNVmuy9CbT0ivPnMIAGVg1_i980mq1MF179N-snFqKPM86JxUY7AArIxlLkpkTjVcYPWhoGI6GKEFlBQjcQKUsWwN_h5mnEW8mYlUme6pdpbO7d5HBs8Z3yn0nPRdQwHGnb4V12fJJC5ryIunHul0eUlDfV4mkaxUz41lqRJTW6Gxghx_--ifI7Bo6Zds1OuBKD9cQ9N-GYtTBRFCz2prJMpP8qsoXLvSwZAH0hLDwz0dOQCUIfaa5IlpnOI0QjfYEm64Gav9pH-FO7ygtWQQN7tPb2bq8vW7iZtEW3DqvVKT0Q7EudKnfzPsn_3_aw3kpVj-mRy6HMsMiyvOSnRHe8fBFSjDdXsPQQZN05w0ClrKPl5bmq3bF0pLQmMvZnYZDIOp7A17L2q4n4O3z1PyzRp-Y8XOfp_Y2cJmyq9zMkvCPWvtwxy_tMAUBh6Hi2BP6sAXBkRiGVMAlyujHnqkcq8dybWwIfCRyH0CY2ijhXmMOrpvWORwpq4VYlnB-1v_f_MB4PT7Qe3L7v1ZiMeqI48weUyjiDzeaWfl5es_WsQSbwV7KSUXmmtohh3MYFGpv3LP1zV8Nk3Mdl2a3D7TMGgWFExuZ8fdDJDbslVDnF-9JRDhsTwuFScYXnZfhdZ557WFoIg3m5aH-toADFLxW7IWAZjwKuuven3XyeSaNrRsXsSucXCtjPrsNCzaHBNzp_o-rYZdyMMTlM7fcoPLm16A0NPKF2WFiI13IWCM0FbEzGt3gzTRKMtBQFihjl21jMHpTY-oRQEbW7B2_xwqXjIPeYdT7yR2JEE9nK-Pxkz6kyGDfMWexmvvFh0xnOrqYKlvrsKTFXAZ8SL66I987UBeb2lgbc62J_G7BBj-okmhH1oo1kvk5u6O3yecB8HP3BENcnKUoaFxrskeIDt9ZgyNv2EoKc=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7GqDrsh_DnWeLzyLBr6Umm3lU8PCWdVH1FH-z7-oXFTZQfVrFi_zehtPayEqQJs8kJgirpR6sUWZ4vzDGN5Ks6-ChWRYaLMdjFKkG1Jmg4xBU8m8oIUiLk0gVNoIiQ5dGk7SMhyp9Q4dxVlF_a_5Doe0TMX6nEKtM44slhExCmnrTxD263bx8vYKzqXuOKlANOykbGmHczDbRfFdlB7vfjPLRfQYBr1ptf-SuLkyaCZ3PMzWtZzPZPsE5zAUNwalvCVp9B1RImY3ZNVmuy9CbT0ivPnMIAGVg1_i980mq1MF179N-snFqKPM86JxUY7AArIxlLkpkTjVcYPWhoGI6GKEFlBQjcQKUsWwN_h5mnEW8mYlUme6pdpbO7d5HBs8Z3yn0nPRdQwHGnb4V12fJJC5ryIunHul0eUlDfV4mkaxUz41lqRJTW6Gxghx_--ifI7Bo6Zds1OuBKD9cQ9N-GYtTBRFCz2prJMpP8qsoXLvSwZAH0hLDwz0dOQCUIfaa5IlpnOI0QjfYEm64Gav9pH-FO7ygtWQQN7tPb2bq8vW7iZtEW3DqvVKT0Q7EudKnfzPsn_3_aw3kpVj-mRy6HMsMiyvOSnRHe8fBFSjDdXsPQQZN05w0ClrKPl5bmq3bF0pLQmMvZnYZDIOp7A17L2q4n4O3z1PyzRp-Y8XOfp_Y2cJmyq9zMkvCPWvtwxy_tMAUBh6Hi2BP6sAXBkRiGVMAlyujHnqkcq8dybWwIfCRyH0CY2ijhXmMOrpvWORwpq4VYlnB-1v_f_MB4PT7Qe3L7v1ZiMeqI48weUyjiDzeaWfl5es_WsQSbwV7KSUXmmtohh3MYFGpv3LP1zV8Nk3Mdl2a3D7TMGgWFExuZ8fdDJDbslVDnF-9JRDhsTwuFScYXnZfhdZ557WFoIg3m5aH-toADFLxW7IWAZjwKuuven3XyeSaNrRsXsSucXCtjPrsNCzaHBNzp_o-rYZdyMMTlM7fcoPLm16A0NPKF2WFiI13IWCM0FbEzGt3gzTRKMtBQFihjl21jMHpTY-oRQEbW7B2_xwqXjIPeYdT7yR2JEE9nK-Pxkz6kyGDfMWexmvvFh0xnOrqYKlvrsKTFXAZ8SL66I987UBeb2lgbc62J_G7BBj-okmhH1oo1kvk5u6O3yecB8HP3BENcnKUoaFxrskeIDt9ZgyNv2EoKc=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7GqDrsh_DnWeLzyLBr6Umm3lU8PCWdVH1FH-z7-oXFTZQfVrFi_zehtPayEqQJs8kJgirpR6sUWZ4vzDGN5Ks6-ChWRYaLMdjFKkG1Jmg4xBU8m8oIUiLk0gVNoIiQ5dGk7SMhyp9Q4dxVlF_a_5Doe0TMX6nEKtM44slhExCmnrTxD263bx8vYKzqXuOKlANOykbGmHczDbRfFdlB7vfjPLRfQYBr1ptf-SuLkyaCZ3PMzWtZzPZPsE5zAUNwalvCVp9B1RImY3ZNVmuy9CbT0ivPnMIAGVg1_i980mq1MF179N-snFqKPM86JxUY7AArIxlLkpkTjVcYPWhoGI6GKEFlBQjcQKUsWwN_h5mnEW8mYlUme6pdpbO7d5HBs8Z3yn0nPRdQwHGnb4V12fJJC5ryIunHul0eUlDfV4mkaxUz41lqRJTW6Gxghx_--ifI7Bo6Zds1OuBKD9cQ9N-GYtTBRFCz2prJMpP8qsoXLvSwZAH0hLDwz0dOQCUIfaa5IlpnOI0QjfYEm64Gav9pH-FO7ygtWQQN7tPb2bq8vW7iZtEW3DqvVKT0Q7EudKnfzPsn_3_aw3kpVj-mRy6HMsMiyvOSnRHe8fBFSjDdXsPQQZN05w0ClrKPl5bmq3bF0pLQmMvZnYZDIOp7A17L2q4n4O3z1PyzRp-Y8XOfp_Y2cJmyq9zMkvCPWvtwxy_tMAUBh6Hi2BP6sAXBkRiGVMAlyujHnqkcq8dybWwIfCRyH0CY2ijhXmMOrpvWORwpq4VYlnB-1v_f_MB4PT7Qe3L7v1ZiMeqI48weUyjiDzeaWfl5es_WsQSbwV7KSUXmmtohh3MYFGpv3LP1zV8Nk3Mdl2a3D7TMGgWFExuZ8fdDJDbslVDnF-9JRDhsTwuFScYXnZfhdZ557WFoIg3m5aH-toADFLxW7IWAZjwKuuven3XyeSaNrRsXsSucXCtjPrsNCzaHBNzp_o-rYZdyMMTlM7fcoPLm16A0NPKF2WFiI13IWCM0FbEzGt3gzTRKMtBQFihjl21jMHpTY-oRQEbW7B2_xwqXjIPeYdT7yR2JEE9nK-Pxkz6kyGDfMWexmvvFh0xnOrqYKlvrsKTFXAZ8SL66I987UBeb2lgbc62J_G7BBj-okmhH1oo1kvk5u6O3yecB8HP3BENcnKUoaFxrskeIDt9ZgyNv2EoKc=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1045.08,
            "totalPrice": 798
          },
          {
            "allPassengerTotalPrice": 798,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQM-GzpFMQQZyjfiZztjyLtuoJbpmtJefLBbYAqkx3KCYb7qonBhqc_pHVzezvRQ0J9zbAWm_xay4HnNL2QwbCuYrIW4SEFqu2iDSzXKPdxCTco4OtH8Rj1t2r0fab4_KtoaQd4n_6hUgMAuWQbZJb9SHSLt8q9T9zeSHrAYhTXc1Y66EEXpOGri_7KFq0UciAVW-mj7POgOmhKYa2-YS-2RDHn_j1uWcjvRKBj4YXVf84UdRIxowT8OW3jiwPcar-zODUMmVR3PhTHfLN_EfNHCXocuC1C28UVJMwdFe2OfnMQNFpEEYeE67QfavN0SR4pzc5NcQqZVf6is6wWRC7PuSpkWF0QeO7FG4qDo9lDv23W0-D7btqJ8VTQvYCZis1jNUrz5nYq7VpamIGpX6D4CzVGUwiXRI4y6LXF8h82fSkIGEha7cE3VSqeMBy3fey3tO_vrc9dJTN_0IS1sMF7gZq4Ktvrv6fXjlWRBiUmh4UIPrXWSY8sHpXu67NXJID-nM7bsRES8M-B47a6DKWORMVlZ0vy_SkTk1Ke8AJb3kKjFkRXmISEfYifbXGIjRWNzygBeTr-2QRQigdNtmjOfTyrO47qiJ7YnVBviCvXEagofGKnF1DaK6n4j_Ro3pd_vMzeVmc4l0l8RlPA2wWotTDLpfLvoXJW2OJCQ5FELxUlPRTLlkdUL6sFi5lg7YM_znwM6ZPDnuze0ZHPyXfxBTnQlYdyY3I3yyxO0kYP9nFAFzGCGAbedgUvPH_Dw7S7tGDM8HTx2-xu4oZwp44_-ShUiAHTrUjjBFPlDeaEacaZmDUTyh0IkuQbtdrqA1VSM6WhSO8JSJIp73Xs8VUG1RfgS3qOt1DTCkqHpAe9P8Q6MHMLI2FJSeE35mjiTSA7NlOoRXSD2HVcBjj-4u7kLhUf1_TB0w4kmI2v0bylzcHBiRGtNURjc3qhta6zbiQBeutujdPCTD4p4-DOsRyp-A4rJ7kBqoCvd2gego5T_d6yZ_9koitfjVgnmpIzbya2K4iRgp2vSx_9qJqpGuJICbrDCc6GY3xOQTc1Bn0eYD1dj-aHmRiMlsK_xw4KQzsTfm9jSaOk8lj0lmkOl8Q1LSxenH5rlxyuxqeZciuFhkEI9FhqhQ1kuI7KF_chnB9OM70bpfITMr09tE034xdg==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQM-GzpFMQQZyjfiZztjyLtuoJbpmtJefLBbYAqkx3KCYb7qonBhqc_pHVzezvRQ0J9zbAWm_xay4HnNL2QwbCuYrIW4SEFqu2iDSzXKPdxCTco4OtH8Rj1t2r0fab4_KtoaQd4n_6hUgMAuWQbZJb9SHSLt8q9T9zeSHrAYhTXc1Y66EEXpOGri_7KFq0UciAVW-mj7POgOmhKYa2-YS-2RDHn_j1uWcjvRKBj4YXVf84UdRIxowT8OW3jiwPcar-zODUMmVR3PhTHfLN_EfNHCXocuC1C28UVJMwdFe2OfnMQNFpEEYeE67QfavN0SR4pzc5NcQqZVf6is6wWRC7PuSpkWF0QeO7FG4qDo9lDv23W0-D7btqJ8VTQvYCZis1jNUrz5nYq7VpamIGpX6D4CzVGUwiXRI4y6LXF8h82fSkIGEha7cE3VSqeMBy3fey3tO_vrc9dJTN_0IS1sMF7gZq4Ktvrv6fXjlWRBiUmh4UIPrXWSY8sHpXu67NXJID-nM7bsRES8M-B47a6DKWORMVlZ0vy_SkTk1Ke8AJb3kKjFkRXmISEfYifbXGIjRWNzygBeTr-2QRQigdNtmjOfTyrO47qiJ7YnVBviCvXEagofGKnF1DaK6n4j_Ro3pd_vMzeVmc4l0l8RlPA2wWotTDLpfLvoXJW2OJCQ5FELxUlPRTLlkdUL6sFi5lg7YM_znwM6ZPDnuze0ZHPyXfxBTnQlYdyY3I3yyxO0kYP9nFAFzGCGAbedgUvPH_Dw7S7tGDM8HTx2-xu4oZwp44_-ShUiAHTrUjjBFPlDeaEacaZmDUTyh0IkuQbtdrqA1VSM6WhSO8JSJIp73Xs8VUG1RfgS3qOt1DTCkqHpAe9P8Q6MHMLI2FJSeE35mjiTSA7NlOoRXSD2HVcBjj-4u7kLhUf1_TB0w4kmI2v0bylzcHBiRGtNURjc3qhta6zbiQBeutujdPCTD4p4-DOsRyp-A4rJ7kBqoCvd2gego5T_d6yZ_9koitfjVgnmpIzbya2K4iRgp2vSx_9qJqpGuJICbrDCc6GY3xOQTc1Bn0eYD1dj-aHmRiMlsK_xw4KQzsTfm9jSaOk8lj0lmkOl8Q1LSxenH5rlxyuxqeZciuFhkEI9FhqhQ1kuI7KF_chnB9OM70bpfITMr09tE034xdg==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQM-GzpFMQQZyjfiZztjyLtuoJbpmtJefLBbYAqkx3KCYb7qonBhqc_pHVzezvRQ0J9zbAWm_xay4HnNL2QwbCuYrIW4SEFqu2iDSzXKPdxCTco4OtH8Rj1t2r0fab4_KtoaQd4n_6hUgMAuWQbZJb9SHSLt8q9T9zeSHrAYhTXc1Y66EEXpOGri_7KFq0UciAVW-mj7POgOmhKYa2-YS-2RDHn_j1uWcjvRKBj4YXVf84UdRIxowT8OW3jiwPcar-zODUMmVR3PhTHfLN_EfNHCXocuC1C28UVJMwdFe2OfnMQNFpEEYeE67QfavN0SR4pzc5NcQqZVf6is6wWRC7PuSpkWF0QeO7FG4qDo9lDv23W0-D7btqJ8VTQvYCZis1jNUrz5nYq7VpamIGpX6D4CzVGUwiXRI4y6LXF8h82fSkIGEha7cE3VSqeMBy3fey3tO_vrc9dJTN_0IS1sMF7gZq4Ktvrv6fXjlWRBiUmh4UIPrXWSY8sHpXu67NXJID-nM7bsRES8M-B47a6DKWORMVlZ0vy_SkTk1Ke8AJb3kKjFkRXmISEfYifbXGIjRWNzygBeTr-2QRQigdNtmjOfTyrO47qiJ7YnVBviCvXEagofGKnF1DaK6n4j_Ro3pd_vMzeVmc4l0l8RlPA2wWotTDLpfLvoXJW2OJCQ5FELxUlPRTLlkdUL6sFi5lg7YM_znwM6ZPDnuze0ZHPyXfxBTnQlYdyY3I3yyxO0kYP9nFAFzGCGAbedgUvPH_Dw7S7tGDM8HTx2-xu4oZwp44_-ShUiAHTrUjjBFPlDeaEacaZmDUTyh0IkuQbtdrqA1VSM6WhSO8JSJIp73Xs8VUG1RfgS3qOt1DTCkqHpAe9P8Q6MHMLI2FJSeE35mjiTSA7NlOoRXSD2HVcBjj-4u7kLhUf1_TB0w4kmI2v0bylzcHBiRGtNURjc3qhta6zbiQBeutujdPCTD4p4-DOsRyp-A4rJ7kBqoCvd2gego5T_d6yZ_9koitfjVgnmpIzbya2K4iRgp2vSx_9qJqpGuJICbrDCc6GY3xOQTc1Bn0eYD1dj-aHmRiMlsK_xw4KQzsTfm9jSaOk8lj0lmkOl8Q1LSxenH5rlxyuxqeZciuFhkEI9FhqhQ1kuI7KF_chnB9OM70bpfITMr09tE034xdg==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1045.93,
            "totalPrice": 798
          },
          {
            "allPassengerTotalPrice": 803,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gtt9pADrlJHUTC8R-UIZPywfVbjh5Dq3hl3FsZspZfwfnuRIqib5ADrd2ZTWQNW-58hi2lrDBYWepnBuyiP_wQp276QEnDpq-ufCIgQlwajmobVCRQhBzRPrIspQOyG3KdQqRjwesFIAxgECNWUElSOjVDaaF1eLTjGew4YC8difASJGGsp-sGmd3VtUwHIwX0WBiB8-r-WNfMuLvmftwVb9R7U9C-kT9u8ZJdmZqEsb9e3tC9uvA_bOBtEojpNCuvr20y9kFgx3Rt1p3rJMFgQERqN27KurI213_syCGvVV76V2ZRlXFGjVq49k-W3nqc4I2ghYnDdeuATAduxqsi4mJ6LasJbSfQspgogPV52HmknlTrPZRtHcoktZUEg1NmtjjPbykChYLL3u5B0msvZTk19s1AWjo1DLSBTRHlh6F1hiB0cXSxF28n2s41Zbg2LrwAyMz-iIN8y22ouOcuZlVaYKqQjvWr5x-CX2b-TA_9D52WUjJlHP4nEqTQOOgRfXmuREJYnpSZHl-5qPTUMCoLFu-S1OcBmGeaOFRZsBRUkjxEKi6QNjAs4r9eksxpPMuyZ2s8pHCy8xmdl-joGd3BJBsjMZHyid6jtUpG479El6y3AVfqlBKXQa1axUorNLW9P6oSWi5vp1cpbeLf0nz0m0vbgauRCXOeI_vJbCW1zcJ0Gvh-UutPlZWlE6p_OCgg_O4lLrP6obeoTPpKetnKTG0m3kfF651UKn2hyWlLuFGYsVn9VTPhe2qCDHAhSCKdUtLcJOi05k63elMPu9qtoAywjss-PB7R5O52eL7HY4f5frqUwJHzRCf5j1GZx2DQYvKMDRMCDONpMy37wONtoVCOKvDyLTT_BexHE_XtWGlRJQc30RWjauzc_faogXjWkW0Kiy_achg59tTawcVoPqClwB17dtbbXBTmeoVOI-7t1_okZ9gPj-MpBC1vxbgpy4ICX2fWPFOppVQ-BHqv9mVHYA6pGOYomHB6-Hr8gaRlPLetuPfbNQ26xu-8qhOaEvJhSOg17uQJozSPIFREG8N3K4G09TQsnmpqfcsfIaf-C9RFJdCQTD-pKoxEIrrIkl2SP3Gua4tBTgqp6lIOBCxk4A7R67o4u13XeFnMeUB9DNWske0gGK5_s6gYPP5XUdvx7zeCPUhwkQFuBx1y1VrL2HTaxdSXgLq7kUfocXF25kNCAvzHBbUM2bNtJp1YWAYrYbAp44W_gIG_2bFwPQVLFfDtA0QDrT9pk1OyB3vlzM4g5jE6df15yuZ",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gtt9pADrlJHUTC8R-UIZPywfVbjh5Dq3hl3FsZspZfwfnuRIqib5ADrd2ZTWQNW-58hi2lrDBYWepnBuyiP_wQp276QEnDpq-ufCIgQlwajmobVCRQhBzRPrIspQOyG3KdQqRjwesFIAxgECNWUElSOjVDaaF1eLTjGew4YC8difASJGGsp-sGmd3VtUwHIwX0WBiB8-r-WNfMuLvmftwVb9R7U9C-kT9u8ZJdmZqEsb9e3tC9uvA_bOBtEojpNCuvr20y9kFgx3Rt1p3rJMFgQERqN27KurI213_syCGvVV76V2ZRlXFGjVq49k-W3nqc4I2ghYnDdeuATAduxqsi4mJ6LasJbSfQspgogPV52HmknlTrPZRtHcoktZUEg1NmtjjPbykChYLL3u5B0msvZTk19s1AWjo1DLSBTRHlh6F1hiB0cXSxF28n2s41Zbg2LrwAyMz-iIN8y22ouOcuZlVaYKqQjvWr5x-CX2b-TA_9D52WUjJlHP4nEqTQOOgRfXmuREJYnpSZHl-5qPTUMCoLFu-S1OcBmGeaOFRZsBRUkjxEKi6QNjAs4r9eksxpPMuyZ2s8pHCy8xmdl-joGd3BJBsjMZHyid6jtUpG479El6y3AVfqlBKXQa1axUorNLW9P6oSWi5vp1cpbeLf0nz0m0vbgauRCXOeI_vJbCW1zcJ0Gvh-UutPlZWlE6p_OCgg_O4lLrP6obeoTPpKetnKTG0m3kfF651UKn2hyWlLuFGYsVn9VTPhe2qCDHAhSCKdUtLcJOi05k63elMPu9qtoAywjss-PB7R5O52eL7HY4f5frqUwJHzRCf5j1GZx2DQYvKMDRMCDONpMy37wONtoVCOKvDyLTT_BexHE_XtWGlRJQc30RWjauzc_faogXjWkW0Kiy_achg59tTawcVoPqClwB17dtbbXBTmeoVOI-7t1_okZ9gPj-MpBC1vxbgpy4ICX2fWPFOppVQ-BHqv9mVHYA6pGOYomHB6-Hr8gaRlPLetuPfbNQ26xu-8qhOaEvJhSOg17uQJozSPIFREG8N3K4G09TQsnmpqfcsfIaf-C9RFJdCQTD-pKoxEIrrIkl2SP3Gua4tBTgqp6lIOBCxk4A7R67o4u13XeFnMeUB9DNWske0gGK5_s6gYPP5XUdvx7zeCPUhwkQFuBx1y1VrL2HTaxdSXgLq7kUfocXF25kNCAvzHBbUM2bNtJp1YWAYrYbAp44W_gIG_2bFwPQVLFfDtA0QDrT9pk1OyB3vlzM4g5jE6df15yuZ",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gtt9pADrlJHUTC8R-UIZPywfVbjh5Dq3hl3FsZspZfwfnuRIqib5ADrd2ZTWQNW-58hi2lrDBYWepnBuyiP_wQp276QEnDpq-ufCIgQlwajmobVCRQhBzRPrIspQOyG3KdQqRjwesFIAxgECNWUElSOjVDaaF1eLTjGew4YC8difASJGGsp-sGmd3VtUwHIwX0WBiB8-r-WNfMuLvmftwVb9R7U9C-kT9u8ZJdmZqEsb9e3tC9uvA_bOBtEojpNCuvr20y9kFgx3Rt1p3rJMFgQERqN27KurI213_syCGvVV76V2ZRlXFGjVq49k-W3nqc4I2ghYnDdeuATAduxqsi4mJ6LasJbSfQspgogPV52HmknlTrPZRtHcoktZUEg1NmtjjPbykChYLL3u5B0msvZTk19s1AWjo1DLSBTRHlh6F1hiB0cXSxF28n2s41Zbg2LrwAyMz-iIN8y22ouOcuZlVaYKqQjvWr5x-CX2b-TA_9D52WUjJlHP4nEqTQOOgRfXmuREJYnpSZHl-5qPTUMCoLFu-S1OcBmGeaOFRZsBRUkjxEKi6QNjAs4r9eksxpPMuyZ2s8pHCy8xmdl-joGd3BJBsjMZHyid6jtUpG479El6y3AVfqlBKXQa1axUorNLW9P6oSWi5vp1cpbeLf0nz0m0vbgauRCXOeI_vJbCW1zcJ0Gvh-UutPlZWlE6p_OCgg_O4lLrP6obeoTPpKetnKTG0m3kfF651UKn2hyWlLuFGYsVn9VTPhe2qCDHAhSCKdUtLcJOi05k63elMPu9qtoAywjss-PB7R5O52eL7HY4f5frqUwJHzRCf5j1GZx2DQYvKMDRMCDONpMy37wONtoVCOKvDyLTT_BexHE_XtWGlRJQc30RWjauzc_faogXjWkW0Kiy_achg59tTawcVoPqClwB17dtbbXBTmeoVOI-7t1_okZ9gPj-MpBC1vxbgpy4ICX2fWPFOppVQ-BHqv9mVHYA6pGOYomHB6-Hr8gaRlPLetuPfbNQ26xu-8qhOaEvJhSOg17uQJozSPIFREG8N3K4G09TQsnmpqfcsfIaf-C9RFJdCQTD-pKoxEIrrIkl2SP3Gua4tBTgqp6lIOBCxk4A7R67o4u13XeFnMeUB9DNWske0gGK5_s6gYPP5XUdvx7zeCPUhwkQFuBx1y1VrL2HTaxdSXgLq7kUfocXF25kNCAvzHBbUM2bNtJp1YWAYrYbAp44W_gIG_2bFwPQVLFfDtA0QDrT9pk1OyB3vlzM4g5jE6df15yuZ",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1027.73,
            "totalPrice": 803
          },
          {
            "allPassengerTotalPrice": 803,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAotahh7qh5dUXYjOGRey1ZOwWYTtFP6W_z9Hkw411N3pjzDermtt3C44Yx4T9V9qR5U1JsZxI5aYNU0Fv64Palz2hfZNjG4dstykdMl3jh5Crsk9itPELUqumtNQxbXMTu93U7fxb9bllGT5PhQMlj_he27ynMoIYWXL94SPaUrUB1_sZBz4gwM-Vfws4WHZrOx1gUCQ8U_V22Hy26A-7UuZ-6zRdFyKFbYcMtk0v6dhgu6RoyCIotw9ggnQpplle5i2DVuNXGywy7430a1apRS-ICigJro0rZTfFjDNLyiF4U3Ciq_EhBq5nHALLj-HWg7b4h4G1hsklsplO0pnQk6eQMlcGgi1MCBXEmNNkxaAtHav6mNTxx4EYCLQftQjgMFeSZzM70DR97mXq88BvWre_y_k2NXbN_Gq9buXN5Gm8L_T66U8W0jD0YxOqbRz0Q5rb_lZ9cjxg7Kyty28jMNPp6cSQjYGQoUFwL_WBPmh_ssi614ousm87UuK7Rr0C14stc1DsBpk5c0_8lZyrraoIzvWtg1QIRy-Xa89jWrJJbdu0sH2-6Erkqsr5DAZNewn5iD8D8Yb3v-0J_mzWjxGePVV3_KTMgA8kWVPU3jfv2MlbS03TG2RggaWDkbrvhcx0rrGPeV3ryqeJtdZzImxZzLrLT8h-FA48YFDX78sgtsDuAp_m2lIdIGWe3WsUdnEtSONjNQfCSeN-p0A83xPs3AieyTHhelVkTVwNKtCkDtVbWfREsdR8T-LS1FHA3WxiZHUkkhWxMcdSIiPphCy29wef_IUGdmOZRR8KwgvzVSjgWPwhQJHWfxcFSsSzP7Yp13c_QJwQBv5nbmD0-yiDIzkAfZLlKZo3TBULV1p6neaK620LDiEN_fZgEoYaBfio8Sj-ynorHMJgv45HWirf3nlfDUwBHMUOBjtZb3uUfuDalpJpQJTARkjT0qcl2L7ZYi8XuZkZonAzgQHQBJEMvsfHmMHi9GdiVc3XugAee13vQYh2LOV74bfB_pYUt0tAq92q4CI4lZI5sJtRkusO03DagAxT6Obpl8TKsGpOJz69yU_jLxXwmBKnIGJlCzthiz60wM3bSVsQYetrVzCFKhw1nwls0tfi1vAEWDAzZQWxCbPjbfk0xsjh7FhE1hfV-Maz5Uxjc8l2wrQ2G5tyG7mcbg0cBwWxyrwK7ipfNakLwjkb8WBwWR-3betgkSN1Dm6ztC9jlvB7su9suRWxTApjnp7ptQHJ1qzM7o=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAotahh7qh5dUXYjOGRey1ZOwWYTtFP6W_z9Hkw411N3pjzDermtt3C44Yx4T9V9qR5U1JsZxI5aYNU0Fv64Palz2hfZNjG4dstykdMl3jh5Crsk9itPELUqumtNQxbXMTu93U7fxb9bllGT5PhQMlj_he27ynMoIYWXL94SPaUrUB1_sZBz4gwM-Vfws4WHZrOx1gUCQ8U_V22Hy26A-7UuZ-6zRdFyKFbYcMtk0v6dhgu6RoyCIotw9ggnQpplle5i2DVuNXGywy7430a1apRS-ICigJro0rZTfFjDNLyiF4U3Ciq_EhBq5nHALLj-HWg7b4h4G1hsklsplO0pnQk6eQMlcGgi1MCBXEmNNkxaAtHav6mNTxx4EYCLQftQjgMFeSZzM70DR97mXq88BvWre_y_k2NXbN_Gq9buXN5Gm8L_T66U8W0jD0YxOqbRz0Q5rb_lZ9cjxg7Kyty28jMNPp6cSQjYGQoUFwL_WBPmh_ssi614ousm87UuK7Rr0C14stc1DsBpk5c0_8lZyrraoIzvWtg1QIRy-Xa89jWrJJbdu0sH2-6Erkqsr5DAZNewn5iD8D8Yb3v-0J_mzWjxGePVV3_KTMgA8kWVPU3jfv2MlbS03TG2RggaWDkbrvhcx0rrGPeV3ryqeJtdZzImxZzLrLT8h-FA48YFDX78sgtsDuAp_m2lIdIGWe3WsUdnEtSONjNQfCSeN-p0A83xPs3AieyTHhelVkTVwNKtCkDtVbWfREsdR8T-LS1FHA3WxiZHUkkhWxMcdSIiPphCy29wef_IUGdmOZRR8KwgvzVSjgWPwhQJHWfxcFSsSzP7Yp13c_QJwQBv5nbmD0-yiDIzkAfZLlKZo3TBULV1p6neaK620LDiEN_fZgEoYaBfio8Sj-ynorHMJgv45HWirf3nlfDUwBHMUOBjtZb3uUfuDalpJpQJTARkjT0qcl2L7ZYi8XuZkZonAzgQHQBJEMvsfHmMHi9GdiVc3XugAee13vQYh2LOV74bfB_pYUt0tAq92q4CI4lZI5sJtRkusO03DagAxT6Obpl8TKsGpOJz69yU_jLxXwmBKnIGJlCzthiz60wM3bSVsQYetrVzCFKhw1nwls0tfi1vAEWDAzZQWxCbPjbfk0xsjh7FhE1hfV-Maz5Uxjc8l2wrQ2G5tyG7mcbg0cBwWxyrwK7ipfNakLwjkb8WBwWR-3betgkSN1Dm6ztC9jlvB7su9suRWxTApjnp7ptQHJ1qzM7o=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GAotahh7qh5dUXYjOGRey1ZOwWYTtFP6W_z9Hkw411N3pjzDermtt3C44Yx4T9V9qR5U1JsZxI5aYNU0Fv64Palz2hfZNjG4dstykdMl3jh5Crsk9itPELUqumtNQxbXMTu93U7fxb9bllGT5PhQMlj_he27ynMoIYWXL94SPaUrUB1_sZBz4gwM-Vfws4WHZrOx1gUCQ8U_V22Hy26A-7UuZ-6zRdFyKFbYcMtk0v6dhgu6RoyCIotw9ggnQpplle5i2DVuNXGywy7430a1apRS-ICigJro0rZTfFjDNLyiF4U3Ciq_EhBq5nHALLj-HWg7b4h4G1hsklsplO0pnQk6eQMlcGgi1MCBXEmNNkxaAtHav6mNTxx4EYCLQftQjgMFeSZzM70DR97mXq88BvWre_y_k2NXbN_Gq9buXN5Gm8L_T66U8W0jD0YxOqbRz0Q5rb_lZ9cjxg7Kyty28jMNPp6cSQjYGQoUFwL_WBPmh_ssi614ousm87UuK7Rr0C14stc1DsBpk5c0_8lZyrraoIzvWtg1QIRy-Xa89jWrJJbdu0sH2-6Erkqsr5DAZNewn5iD8D8Yb3v-0J_mzWjxGePVV3_KTMgA8kWVPU3jfv2MlbS03TG2RggaWDkbrvhcx0rrGPeV3ryqeJtdZzImxZzLrLT8h-FA48YFDX78sgtsDuAp_m2lIdIGWe3WsUdnEtSONjNQfCSeN-p0A83xPs3AieyTHhelVkTVwNKtCkDtVbWfREsdR8T-LS1FHA3WxiZHUkkhWxMcdSIiPphCy29wef_IUGdmOZRR8KwgvzVSjgWPwhQJHWfxcFSsSzP7Yp13c_QJwQBv5nbmD0-yiDIzkAfZLlKZo3TBULV1p6neaK620LDiEN_fZgEoYaBfio8Sj-ynorHMJgv45HWirf3nlfDUwBHMUOBjtZb3uUfuDalpJpQJTARkjT0qcl2L7ZYi8XuZkZonAzgQHQBJEMvsfHmMHi9GdiVc3XugAee13vQYh2LOV74bfB_pYUt0tAq92q4CI4lZI5sJtRkusO03DagAxT6Obpl8TKsGpOJz69yU_jLxXwmBKnIGJlCzthiz60wM3bSVsQYetrVzCFKhw1nwls0tfi1vAEWDAzZQWxCbPjbfk0xsjh7FhE1hfV-Maz5Uxjc8l2wrQ2G5tyG7mcbg0cBwWxyrwK7ipfNakLwjkb8WBwWR-3betgkSN1Dm6ztC9jlvB7su9suRWxTApjnp7ptQHJ1qzM7o=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1027.73,
            "totalPrice": 803
          },
          {
            "allPassengerTotalPrice": 803,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GWL2J8ZfIakvhkvcj_G9CM0OqUqbrBy6VGNVatJ8dwDqIuZbJuyXsQQCjq0STbrKXqp3m_jBd405xeLAwaQcMbyMTekZfrLg6qDNNOd1ffdOxWpimFNkt0wdkLv3ktvjvbMe63vg3avdtb99Bw-ERUcA_83v5vqBJcfcV7zQxFwxjIDw0W4jXGoCj5FYhx7c3pT-z6PF1i4cSGh8Mnh3Rq79Z_QbeSVNe8S04PcE-X1YB6V-S1HVvAEoNu5eCO6OhFO2zuHp9KqLjCJOt3HN4RV3DQ4ywatDHtMyu68OzlQLEAeQNJzZ42dLRkA2ENBMkPzZjFQZE-kbAYoM6r7tnGVP26BiuyMfcpKiMrMIu_LxnKXUqg44Lah57VlvTbloAUwrxeRl3ZEIfD3ismNZJ2b1kena8y9oqaHtbb9ns1kytc3cqxUpI0hgVqunyoK2JmsSgsM6Qj8VdQ8-kweR4FtdBVJ0VNLHHOu--Otvxa1ZhM4aqC9L_50BVWQemhveEXLI0C6C6n-Lor2yk2ScYdPfMc21E0Q4V7sk5qoN6Is_rL8DA5WVR2WAQS1tH_qojCTDiLpSSg6_-H_Na_SNnP2W28ls7ylxbst7rWc88wRFZHgi626OG8iRI5DDjTljvorMbwNZ3lt-u5alzDuZbDMkjFTGxpR5DOmXBTZUdNw22upOJ63xl6DCBxv5zCK5zeQ2UQ4y4GMLFlp4TBWdy71-s3fNza0Gt0BJp_sVFK8hCdipKle7oQqYLUvOiITbmYnSQIQdjGGn5Ts2eIRCFB7UBaOkCFDLCn6isNyufnmPYqguNC4tEvVpqh07FFyfbldU7PVOkZMVT7Bqt4OlxS6Yiy-AuYE7GvMxdBJBMxY8KWJU3q327uFhkgR6ppgBfy0wEJEsntMJtmyW2e3zp0Rn6Mc38puZgDdl_i-kIUmnqBl6EbrJ3ezYnX_HdfnnC1kGZiNgNK2b0onffF6D1dPVyvuI0eCbb5xFY_d8Gf5LY9dRuXce6YbZ7JDPE9-7CkJC3MhbFf5-O6_LOf40hWo_2M3LG3uor72oDlZhOi7TGDiyqul_rqBog7peB-eznWePRRgbCrcvW7F1hetCPbDiw4lIH7f2fmPfima2PB78dJwnGR2s9ivN1Vng1vc0-1tR7TKIviTzqZkfC1F-F909LDwMnWIf9Jl-J-ETu-RrgQQa_LNwuNb8oVbnGqFTwvdTmPRlQehPXh3JDs1Bb0kvznVZftsjqbhLWOygTX0M=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GWL2J8ZfIakvhkvcj_G9CM0OqUqbrBy6VGNVatJ8dwDqIuZbJuyXsQQCjq0STbrKXqp3m_jBd405xeLAwaQcMbyMTekZfrLg6qDNNOd1ffdOxWpimFNkt0wdkLv3ktvjvbMe63vg3avdtb99Bw-ERUcA_83v5vqBJcfcV7zQxFwxjIDw0W4jXGoCj5FYhx7c3pT-z6PF1i4cSGh8Mnh3Rq79Z_QbeSVNe8S04PcE-X1YB6V-S1HVvAEoNu5eCO6OhFO2zuHp9KqLjCJOt3HN4RV3DQ4ywatDHtMyu68OzlQLEAeQNJzZ42dLRkA2ENBMkPzZjFQZE-kbAYoM6r7tnGVP26BiuyMfcpKiMrMIu_LxnKXUqg44Lah57VlvTbloAUwrxeRl3ZEIfD3ismNZJ2b1kena8y9oqaHtbb9ns1kytc3cqxUpI0hgVqunyoK2JmsSgsM6Qj8VdQ8-kweR4FtdBVJ0VNLHHOu--Otvxa1ZhM4aqC9L_50BVWQemhveEXLI0C6C6n-Lor2yk2ScYdPfMc21E0Q4V7sk5qoN6Is_rL8DA5WVR2WAQS1tH_qojCTDiLpSSg6_-H_Na_SNnP2W28ls7ylxbst7rWc88wRFZHgi626OG8iRI5DDjTljvorMbwNZ3lt-u5alzDuZbDMkjFTGxpR5DOmXBTZUdNw22upOJ63xl6DCBxv5zCK5zeQ2UQ4y4GMLFlp4TBWdy71-s3fNza0Gt0BJp_sVFK8hCdipKle7oQqYLUvOiITbmYnSQIQdjGGn5Ts2eIRCFB7UBaOkCFDLCn6isNyufnmPYqguNC4tEvVpqh07FFyfbldU7PVOkZMVT7Bqt4OlxS6Yiy-AuYE7GvMxdBJBMxY8KWJU3q327uFhkgR6ppgBfy0wEJEsntMJtmyW2e3zp0Rn6Mc38puZgDdl_i-kIUmnqBl6EbrJ3ezYnX_HdfnnC1kGZiNgNK2b0onffF6D1dPVyvuI0eCbb5xFY_d8Gf5LY9dRuXce6YbZ7JDPE9-7CkJC3MhbFf5-O6_LOf40hWo_2M3LG3uor72oDlZhOi7TGDiyqul_rqBog7peB-eznWePRRgbCrcvW7F1hetCPbDiw4lIH7f2fmPfima2PB78dJwnGR2s9ivN1Vng1vc0-1tR7TKIviTzqZkfC1F-F909LDwMnWIf9Jl-J-ETu-RrgQQa_LNwuNb8oVbnGqFTwvdTmPRlQehPXh3JDs1Bb0kvznVZftsjqbhLWOygTX0M=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GWL2J8ZfIakvhkvcj_G9CM0OqUqbrBy6VGNVatJ8dwDqIuZbJuyXsQQCjq0STbrKXqp3m_jBd405xeLAwaQcMbyMTekZfrLg6qDNNOd1ffdOxWpimFNkt0wdkLv3ktvjvbMe63vg3avdtb99Bw-ERUcA_83v5vqBJcfcV7zQxFwxjIDw0W4jXGoCj5FYhx7c3pT-z6PF1i4cSGh8Mnh3Rq79Z_QbeSVNe8S04PcE-X1YB6V-S1HVvAEoNu5eCO6OhFO2zuHp9KqLjCJOt3HN4RV3DQ4ywatDHtMyu68OzlQLEAeQNJzZ42dLRkA2ENBMkPzZjFQZE-kbAYoM6r7tnGVP26BiuyMfcpKiMrMIu_LxnKXUqg44Lah57VlvTbloAUwrxeRl3ZEIfD3ismNZJ2b1kena8y9oqaHtbb9ns1kytc3cqxUpI0hgVqunyoK2JmsSgsM6Qj8VdQ8-kweR4FtdBVJ0VNLHHOu--Otvxa1ZhM4aqC9L_50BVWQemhveEXLI0C6C6n-Lor2yk2ScYdPfMc21E0Q4V7sk5qoN6Is_rL8DA5WVR2WAQS1tH_qojCTDiLpSSg6_-H_Na_SNnP2W28ls7ylxbst7rWc88wRFZHgi626OG8iRI5DDjTljvorMbwNZ3lt-u5alzDuZbDMkjFTGxpR5DOmXBTZUdNw22upOJ63xl6DCBxv5zCK5zeQ2UQ4y4GMLFlp4TBWdy71-s3fNza0Gt0BJp_sVFK8hCdipKle7oQqYLUvOiITbmYnSQIQdjGGn5Ts2eIRCFB7UBaOkCFDLCn6isNyufnmPYqguNC4tEvVpqh07FFyfbldU7PVOkZMVT7Bqt4OlxS6Yiy-AuYE7GvMxdBJBMxY8KWJU3q327uFhkgR6ppgBfy0wEJEsntMJtmyW2e3zp0Rn6Mc38puZgDdl_i-kIUmnqBl6EbrJ3ezYnX_HdfnnC1kGZiNgNK2b0onffF6D1dPVyvuI0eCbb5xFY_d8Gf5LY9dRuXce6YbZ7JDPE9-7CkJC3MhbFf5-O6_LOf40hWo_2M3LG3uor72oDlZhOi7TGDiyqul_rqBog7peB-eznWePRRgbCrcvW7F1hetCPbDiw4lIH7f2fmPfima2PB78dJwnGR2s9ivN1Vng1vc0-1tR7TKIviTzqZkfC1F-F909LDwMnWIf9Jl-J-ETu-RrgQQa_LNwuNb8oVbnGqFTwvdTmPRlQehPXh3JDs1Bb0kvznVZftsjqbhLWOygTX0M=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1028.4,
            "totalPrice": 803
          },
          {
            "allPassengerTotalPrice": 803,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GS1rl4q4bIa_eZmRgSMA2HG2qG1qvLjnNa0j0BpsUPi_uhtXimqIJke6Bel1nR0pH6m4wHU1VyXhAeiSlPbKMPV3Rm-xtgr2UElXwQ1dutNbqZRjki2F3kYiflm_FArAROLT2GxlKqyozciN3H0XtAX3Q9zXGxAawtJTzQtOCoReMOX7bMXLTu73gxRWiQylXG4Ev-NA_6AhZ6kiufVtPjzenqmgG_fkMJr95qjjhLnofLNVDFjku2aa05guF2onInYJzXAT4RQ-9-JQ76_4B1V9HlCN05Mz1KRsVL4pRMSbf2D01_LQ8pct-t5fqDrO-C_jUl12QJB-l9LQTPz1eUcnaLfBh_hwzYyaGvnWANdnD0DZ53EebKps4p_EJZ3icn5x6TB-tHvgmrQhH2-NYUzgnpVlpYZQhKZnN3oO8PvFlcRynMS7nyksa99nYeTWVzgsnkO6ZtJTaFyqYv4JTiXxWjKuX1zgtfq6SqjgZYaz66nu_6cB6cOlBs-vsYXKRwQxuCxkQyc01ZJh9S4pKE84SBv7Gp25IG3C7suuPnFMaO80BmOz2hm1yV6bGWDFGlw6N3HrMOb6XopshGDSZHLR7dIjEj9F02ttfVkDYxL4CWTHEAKc6B6KaEkR-T6yFkSbuNmew7gQ8q5OTplRQNxMdSp-546muE1QNU3ZSKhv8yJ0AOTZV-hZ10-uzorcat8MVk4xxNs2e4KOJwcUZG-dU60hnJZrLEGlj1ZFZpqkq0kLanc-ldTOVoSv2i8y-mLwPAXzMmvFks_NKuXLlVPAxfsXxzyCLP7Zz6DCAj9YCb2KyrEmwLk9Y228mGd2Hmx3nK2sLQapQP9PLyTVKwPEoBJUztufNUax7JHEl6WDVZE-y1u5XAZ_tLqnMiD8edNfeZymNL-oS1WFOgYj5GvOKWFJ5npAhFZd_47AnyaykOYOyKBhNGDJrhUlJVYHxqVxjv2mDqtGIuhFDLePOJENXtgszY7gV0d4rBdjalKvOCMy10z_sQeM80PsNNDngFAqbj2oRsugpufvz48JXsCNiTAHB__q_QtKA8CwFZJTsCEuHusTPkEgK15v-nCgx_zB3GG6XWEjhqIAESnLI_hWsERSVgbatXneLC0MmsLqqzKEV_I1tBuB1LFtvskc_J-bJGmQw3omH1RmyT0-l09qYjYmcCBro2psp3gH_MLZLX6qNgr4S199JAiPPdJ70Eokdqfd5dggYn8xEJGabC37MHQhmJ8eAjdgE0eyqdU4=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GS1rl4q4bIa_eZmRgSMA2HG2qG1qvLjnNa0j0BpsUPi_uhtXimqIJke6Bel1nR0pH6m4wHU1VyXhAeiSlPbKMPV3Rm-xtgr2UElXwQ1dutNbqZRjki2F3kYiflm_FArAROLT2GxlKqyozciN3H0XtAX3Q9zXGxAawtJTzQtOCoReMOX7bMXLTu73gxRWiQylXG4Ev-NA_6AhZ6kiufVtPjzenqmgG_fkMJr95qjjhLnofLNVDFjku2aa05guF2onInYJzXAT4RQ-9-JQ76_4B1V9HlCN05Mz1KRsVL4pRMSbf2D01_LQ8pct-t5fqDrO-C_jUl12QJB-l9LQTPz1eUcnaLfBh_hwzYyaGvnWANdnD0DZ53EebKps4p_EJZ3icn5x6TB-tHvgmrQhH2-NYUzgnpVlpYZQhKZnN3oO8PvFlcRynMS7nyksa99nYeTWVzgsnkO6ZtJTaFyqYv4JTiXxWjKuX1zgtfq6SqjgZYaz66nu_6cB6cOlBs-vsYXKRwQxuCxkQyc01ZJh9S4pKE84SBv7Gp25IG3C7suuPnFMaO80BmOz2hm1yV6bGWDFGlw6N3HrMOb6XopshGDSZHLR7dIjEj9F02ttfVkDYxL4CWTHEAKc6B6KaEkR-T6yFkSbuNmew7gQ8q5OTplRQNxMdSp-546muE1QNU3ZSKhv8yJ0AOTZV-hZ10-uzorcat8MVk4xxNs2e4KOJwcUZG-dU60hnJZrLEGlj1ZFZpqkq0kLanc-ldTOVoSv2i8y-mLwPAXzMmvFks_NKuXLlVPAxfsXxzyCLP7Zz6DCAj9YCb2KyrEmwLk9Y228mGd2Hmx3nK2sLQapQP9PLyTVKwPEoBJUztufNUax7JHEl6WDVZE-y1u5XAZ_tLqnMiD8edNfeZymNL-oS1WFOgYj5GvOKWFJ5npAhFZd_47AnyaykOYOyKBhNGDJrhUlJVYHxqVxjv2mDqtGIuhFDLePOJENXtgszY7gV0d4rBdjalKvOCMy10z_sQeM80PsNNDngFAqbj2oRsugpufvz48JXsCNiTAHB__q_QtKA8CwFZJTsCEuHusTPkEgK15v-nCgx_zB3GG6XWEjhqIAESnLI_hWsERSVgbatXneLC0MmsLqqzKEV_I1tBuB1LFtvskc_J-bJGmQw3omH1RmyT0-l09qYjYmcCBro2psp3gH_MLZLX6qNgr4S199JAiPPdJ70Eokdqfd5dggYn8xEJGabC37MHQhmJ8eAjdgE0eyqdU4=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GS1rl4q4bIa_eZmRgSMA2HG2qG1qvLjnNa0j0BpsUPi_uhtXimqIJke6Bel1nR0pH6m4wHU1VyXhAeiSlPbKMPV3Rm-xtgr2UElXwQ1dutNbqZRjki2F3kYiflm_FArAROLT2GxlKqyozciN3H0XtAX3Q9zXGxAawtJTzQtOCoReMOX7bMXLTu73gxRWiQylXG4Ev-NA_6AhZ6kiufVtPjzenqmgG_fkMJr95qjjhLnofLNVDFjku2aa05guF2onInYJzXAT4RQ-9-JQ76_4B1V9HlCN05Mz1KRsVL4pRMSbf2D01_LQ8pct-t5fqDrO-C_jUl12QJB-l9LQTPz1eUcnaLfBh_hwzYyaGvnWANdnD0DZ53EebKps4p_EJZ3icn5x6TB-tHvgmrQhH2-NYUzgnpVlpYZQhKZnN3oO8PvFlcRynMS7nyksa99nYeTWVzgsnkO6ZtJTaFyqYv4JTiXxWjKuX1zgtfq6SqjgZYaz66nu_6cB6cOlBs-vsYXKRwQxuCxkQyc01ZJh9S4pKE84SBv7Gp25IG3C7suuPnFMaO80BmOz2hm1yV6bGWDFGlw6N3HrMOb6XopshGDSZHLR7dIjEj9F02ttfVkDYxL4CWTHEAKc6B6KaEkR-T6yFkSbuNmew7gQ8q5OTplRQNxMdSp-546muE1QNU3ZSKhv8yJ0AOTZV-hZ10-uzorcat8MVk4xxNs2e4KOJwcUZG-dU60hnJZrLEGlj1ZFZpqkq0kLanc-ldTOVoSv2i8y-mLwPAXzMmvFks_NKuXLlVPAxfsXxzyCLP7Zz6DCAj9YCb2KyrEmwLk9Y228mGd2Hmx3nK2sLQapQP9PLyTVKwPEoBJUztufNUax7JHEl6WDVZE-y1u5XAZ_tLqnMiD8edNfeZymNL-oS1WFOgYj5GvOKWFJ5npAhFZd_47AnyaykOYOyKBhNGDJrhUlJVYHxqVxjv2mDqtGIuhFDLePOJENXtgszY7gV0d4rBdjalKvOCMy10z_sQeM80PsNNDngFAqbj2oRsugpufvz48JXsCNiTAHB__q_QtKA8CwFZJTsCEuHusTPkEgK15v-nCgx_zB3GG6XWEjhqIAESnLI_hWsERSVgbatXneLC0MmsLqqzKEV_I1tBuB1LFtvskc_J-bJGmQw3omH1RmyT0-l09qYjYmcCBro2psp3gH_MLZLX6qNgr4S199JAiPPdJ70Eokdqfd5dggYn8xEJGabC37MHQhmJ8eAjdgE0eyqdU4=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1054.4,
            "totalPrice": 803
          },
          {
            "allPassengerTotalPrice": 803,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfgqywUByEeoaqe4TWgdLRr0Ix71JHiNUD4_v8MP9oMPVwcrFxMYhx9WUS6cw_58Nl2bw6ip5w4zlLBeAknIJKLbTfrn9989C45HT6XP6CrZmKu_tqwL_Cfn0yokUqnOIfpcMN42IPJEDg6t6ow1U2K94b1_MjjacAu8AstknQu9gysWGDiJYJ57rHKy5hj8YrQuWBuBJZWC7vBx2DqoNRexb8XHbPUsjgtrPgoDTk3z4VZRRj6Z4EOAQnHU4LnxxtF8qudZLSnDmcMkpD5HjZjxtMCgV8jeNe-WOryGkPTRPVq1-OYQ_ziiEz7f32gtJP56RgxsAS8lWPYU5jFNfB8djYA7hqDhgWKv8Euh_tNIcz8-Q2k8NhmGBhflFyu1XBzFLE3mJwJf_fj3sdDK-JhwsEk_ke-lJwthewl67IKYrTrevPtNOIIiGr78tnFGOigZJbhishaBj0vRjSuIyYUCsaGHrCwlujt8nAxNepRXG-90JWDN2sOI6GPheH_DzvD8zn9SvbcpYLOFvSVaN3iIOfvCadQUDPmI9gd9VVgj5hi2BjKTMa0kQ9og-nU5N-RnVe1F2lrDP7JqOTGrs-n0LEgPQDz3ZzbKyAMjrBPUAe56-GPXPmHCFrEM218LBFfPTtq4ZkGoy3yDk-yiIIwSzkj9kMb9gOyMs-03_bWV9r224MIHkhbM0fWC6DxgoYAHOUNeZKqNb8xWG7EoIuhToN-FG85rJet5udBWC1B24NsAvYfAmMbADFeKG4kig0NoT4vQA_VQzQ1PWNGseFQvnpTtS01LkjF7GcZZl-HqZlCTwmcdY7gaWlVyKV_k3o3tQDjQAOHK00wUhKo-IIruUzH9-u6aEQMJoP8YZLkXmzkxKSnYtPQWSu4pn18-_ow9ERc76WQNtbkjWJTd876-qkrChD3gkVoo6RmtezZCHCvpPYq7WppemKNio2S-CrzYBed_zC_IgdmYQX-oA5EgKCUgnu8BI50Upvtz8-uoyVP-NnvtdHBBDI-AaOHNEb4_5ahS2neYBNNQyPYwjPfT5mf6SHLH0VbQD5IY9VI06gbjC20LN6DCPBAtcBMV15Nexs6Pwpg6JklAyDtkrSXR0U5dXbibvvGc9wpYS2ktkq_0FRRTyDxxHUdHh5ipWTw8kPSmbwHu15-KA3bGLCEEi21nEz6M1VKpPOeBBbk4OVxGDpGRhsjb1LpSwiX8Gd3WlF47Qq7Beh74jVqJ0ItzR-C_1Cxg9QQTYSO4IXOPYcDA3lp7lgSqK_qB75l12",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfgqywUByEeoaqe4TWgdLRr0Ix71JHiNUD4_v8MP9oMPVwcrFxMYhx9WUS6cw_58Nl2bw6ip5w4zlLBeAknIJKLbTfrn9989C45HT6XP6CrZmKu_tqwL_Cfn0yokUqnOIfpcMN42IPJEDg6t6ow1U2K94b1_MjjacAu8AstknQu9gysWGDiJYJ57rHKy5hj8YrQuWBuBJZWC7vBx2DqoNRexb8XHbPUsjgtrPgoDTk3z4VZRRj6Z4EOAQnHU4LnxxtF8qudZLSnDmcMkpD5HjZjxtMCgV8jeNe-WOryGkPTRPVq1-OYQ_ziiEz7f32gtJP56RgxsAS8lWPYU5jFNfB8djYA7hqDhgWKv8Euh_tNIcz8-Q2k8NhmGBhflFyu1XBzFLE3mJwJf_fj3sdDK-JhwsEk_ke-lJwthewl67IKYrTrevPtNOIIiGr78tnFGOigZJbhishaBj0vRjSuIyYUCsaGHrCwlujt8nAxNepRXG-90JWDN2sOI6GPheH_DzvD8zn9SvbcpYLOFvSVaN3iIOfvCadQUDPmI9gd9VVgj5hi2BjKTMa0kQ9og-nU5N-RnVe1F2lrDP7JqOTGrs-n0LEgPQDz3ZzbKyAMjrBPUAe56-GPXPmHCFrEM218LBFfPTtq4ZkGoy3yDk-yiIIwSzkj9kMb9gOyMs-03_bWV9r224MIHkhbM0fWC6DxgoYAHOUNeZKqNb8xWG7EoIuhToN-FG85rJet5udBWC1B24NsAvYfAmMbADFeKG4kig0NoT4vQA_VQzQ1PWNGseFQvnpTtS01LkjF7GcZZl-HqZlCTwmcdY7gaWlVyKV_k3o3tQDjQAOHK00wUhKo-IIruUzH9-u6aEQMJoP8YZLkXmzkxKSnYtPQWSu4pn18-_ow9ERc76WQNtbkjWJTd876-qkrChD3gkVoo6RmtezZCHCvpPYq7WppemKNio2S-CrzYBed_zC_IgdmYQX-oA5EgKCUgnu8BI50Upvtz8-uoyVP-NnvtdHBBDI-AaOHNEb4_5ahS2neYBNNQyPYwjPfT5mf6SHLH0VbQD5IY9VI06gbjC20LN6DCPBAtcBMV15Nexs6Pwpg6JklAyDtkrSXR0U5dXbibvvGc9wpYS2ktkq_0FRRTyDxxHUdHh5ipWTw8kPSmbwHu15-KA3bGLCEEi21nEz6M1VKpPOeBBbk4OVxGDpGRhsjb1LpSwiX8Gd3WlF47Qq7Beh74jVqJ0ItzR-C_1Cxg9QQTYSO4IXOPYcDA3lp7lgSqK_qB75l12",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GfgqywUByEeoaqe4TWgdLRr0Ix71JHiNUD4_v8MP9oMPVwcrFxMYhx9WUS6cw_58Nl2bw6ip5w4zlLBeAknIJKLbTfrn9989C45HT6XP6CrZmKu_tqwL_Cfn0yokUqnOIfpcMN42IPJEDg6t6ow1U2K94b1_MjjacAu8AstknQu9gysWGDiJYJ57rHKy5hj8YrQuWBuBJZWC7vBx2DqoNRexb8XHbPUsjgtrPgoDTk3z4VZRRj6Z4EOAQnHU4LnxxtF8qudZLSnDmcMkpD5HjZjxtMCgV8jeNe-WOryGkPTRPVq1-OYQ_ziiEz7f32gtJP56RgxsAS8lWPYU5jFNfB8djYA7hqDhgWKv8Euh_tNIcz8-Q2k8NhmGBhflFyu1XBzFLE3mJwJf_fj3sdDK-JhwsEk_ke-lJwthewl67IKYrTrevPtNOIIiGr78tnFGOigZJbhishaBj0vRjSuIyYUCsaGHrCwlujt8nAxNepRXG-90JWDN2sOI6GPheH_DzvD8zn9SvbcpYLOFvSVaN3iIOfvCadQUDPmI9gd9VVgj5hi2BjKTMa0kQ9og-nU5N-RnVe1F2lrDP7JqOTGrs-n0LEgPQDz3ZzbKyAMjrBPUAe56-GPXPmHCFrEM218LBFfPTtq4ZkGoy3yDk-yiIIwSzkj9kMb9gOyMs-03_bWV9r224MIHkhbM0fWC6DxgoYAHOUNeZKqNb8xWG7EoIuhToN-FG85rJet5udBWC1B24NsAvYfAmMbADFeKG4kig0NoT4vQA_VQzQ1PWNGseFQvnpTtS01LkjF7GcZZl-HqZlCTwmcdY7gaWlVyKV_k3o3tQDjQAOHK00wUhKo-IIruUzH9-u6aEQMJoP8YZLkXmzkxKSnYtPQWSu4pn18-_ow9ERc76WQNtbkjWJTd876-qkrChD3gkVoo6RmtezZCHCvpPYq7WppemKNio2S-CrzYBed_zC_IgdmYQX-oA5EgKCUgnu8BI50Upvtz8-uoyVP-NnvtdHBBDI-AaOHNEb4_5ahS2neYBNNQyPYwjPfT5mf6SHLH0VbQD5IY9VI06gbjC20LN6DCPBAtcBMV15Nexs6Pwpg6JklAyDtkrSXR0U5dXbibvvGc9wpYS2ktkq_0FRRTyDxxHUdHh5ipWTw8kPSmbwHu15-KA3bGLCEEi21nEz6M1VKpPOeBBbk4OVxGDpGRhsjb1LpSwiX8Gd3WlF47Qq7Beh74jVqJ0ItzR-C_1Cxg9QQTYSO4IXOPYcDA3lp7lgSqK_qB75l12",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1054.4,
            "totalPrice": 803
          },
          {
            "allPassengerTotalPrice": 803,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2WExXlB-0FeVq955iqreYb6468tUhDdpiHp1A1waEYltRBxUweh38-iv1u03aD3D7qAL99fC44S95KUzCex0BB8o0NAEgdSOXcb7hwjZhWqzqNh6tuP8Yh5Tar1VNsMtVJWiAuTNW3yw0gFsV9veGxYzLPaglv3AjYnYPyCcL5hjarzi-XaMkD3_pwydJ7vRkR1i5zAjBs5w3Sst5ONS37IFi6XdkrGasvQ8jGSK4qGTsvtgdQz3IJf-oszVkzoMEfk5KOl1ayY3l2SdwTaYA4W52ZZKT4EgdBOeSZJHM7eewyyPydNwJB7Mpa0XogdmcjXlBR0iOkZYITggeGs_5mWnyLr4iAEcNR5-G2V7fRQdFgML9ahvEv1sEy4CocN60C3owZa3VJWonmbO4srJftYC-jkhtT8kDgsC-xvI-AKyN-BK9WwspSiKrhPWgJQrw1qVglI3kxDzrNzVKIaqYcoGEZLzx1BZsU-P4uQbqUijXq-DVB5pbYad2tA9naN7f5L8kQt_Hprxaw96B7sCVM2pzmzObztSLuKms9I5CWxnDYJxf9ClpLFLpJQZa5CMQ_CEAnlyDMTjy5xcQsdpcuPjOBdVkBPHpIVTfPfBX7V2ytK7jD09Or58W9q8fsjXEq8y-T6Y46tsBNeMyYFSoGQfvq3hPCn2QgRo_M29udxcris88Rah21YvgDwS3-Xr6yyMESTo2_AcapL-39z66upjoMzQyBEL03orblxSpcSeQ-3JIBLVBLofpWTd1IUEHQiUEgNjuTzMTROq7ZEuBzUMZ2jk9zX2krE3wsweGbaBQXEtT-UR-UqNmav-dMWACCQl_Wj2-EbS-D0sftcYoRllQ4kzlSh70BKsqrBqYMcSaITSLMebxjlfg6HcN1FQdQA6jMC7K_yg5tr_XiMcGMPwT4eKGEmPJMf53TTCY-F6v7A_KUMumcU8HlUpEIJ-Teh6gZlZEFtdX2Rki67WFBvkIgK-am-nKW0uG_SP2BPAKpjM0CzJO_nY4sCCgJTmJ6VtDSGJxmXl1aCNK9z3RL8MaDSgxfTIvQmJ3bdtspEiTugQi7fjzG7CVmpgxrLXgck_TDE4ZrQdh1K8ujweLX80n7Z-0RKWEmg0kkbXrUPoH8k5RRFhrysGCSZkYtmY0KZOBZIVGgptsMa42LmodCIq0RpRFqUPnqgvrvqH79TpB8L7PokQQQq_xWU8IDknIFn64azGj7q0uw2t8oPYs3ypR0EnH6A8T0GbB3BNtdA=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2WExXlB-0FeVq955iqreYb6468tUhDdpiHp1A1waEYltRBxUweh38-iv1u03aD3D7qAL99fC44S95KUzCex0BB8o0NAEgdSOXcb7hwjZhWqzqNh6tuP8Yh5Tar1VNsMtVJWiAuTNW3yw0gFsV9veGxYzLPaglv3AjYnYPyCcL5hjarzi-XaMkD3_pwydJ7vRkR1i5zAjBs5w3Sst5ONS37IFi6XdkrGasvQ8jGSK4qGTsvtgdQz3IJf-oszVkzoMEfk5KOl1ayY3l2SdwTaYA4W52ZZKT4EgdBOeSZJHM7eewyyPydNwJB7Mpa0XogdmcjXlBR0iOkZYITggeGs_5mWnyLr4iAEcNR5-G2V7fRQdFgML9ahvEv1sEy4CocN60C3owZa3VJWonmbO4srJftYC-jkhtT8kDgsC-xvI-AKyN-BK9WwspSiKrhPWgJQrw1qVglI3kxDzrNzVKIaqYcoGEZLzx1BZsU-P4uQbqUijXq-DVB5pbYad2tA9naN7f5L8kQt_Hprxaw96B7sCVM2pzmzObztSLuKms9I5CWxnDYJxf9ClpLFLpJQZa5CMQ_CEAnlyDMTjy5xcQsdpcuPjOBdVkBPHpIVTfPfBX7V2ytK7jD09Or58W9q8fsjXEq8y-T6Y46tsBNeMyYFSoGQfvq3hPCn2QgRo_M29udxcris88Rah21YvgDwS3-Xr6yyMESTo2_AcapL-39z66upjoMzQyBEL03orblxSpcSeQ-3JIBLVBLofpWTd1IUEHQiUEgNjuTzMTROq7ZEuBzUMZ2jk9zX2krE3wsweGbaBQXEtT-UR-UqNmav-dMWACCQl_Wj2-EbS-D0sftcYoRllQ4kzlSh70BKsqrBqYMcSaITSLMebxjlfg6HcN1FQdQA6jMC7K_yg5tr_XiMcGMPwT4eKGEmPJMf53TTCY-F6v7A_KUMumcU8HlUpEIJ-Teh6gZlZEFtdX2Rki67WFBvkIgK-am-nKW0uG_SP2BPAKpjM0CzJO_nY4sCCgJTmJ6VtDSGJxmXl1aCNK9z3RL8MaDSgxfTIvQmJ3bdtspEiTugQi7fjzG7CVmpgxrLXgck_TDE4ZrQdh1K8ujweLX80n7Z-0RKWEmg0kkbXrUPoH8k5RRFhrysGCSZkYtmY0KZOBZIVGgptsMa42LmodCIq0RpRFqUPnqgvrvqH79TpB8L7PokQQQq_xWU8IDknIFn64azGj7q0uw2t8oPYs3ypR0EnH6A8T0GbB3BNtdA=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G2WExXlB-0FeVq955iqreYb6468tUhDdpiHp1A1waEYltRBxUweh38-iv1u03aD3D7qAL99fC44S95KUzCex0BB8o0NAEgdSOXcb7hwjZhWqzqNh6tuP8Yh5Tar1VNsMtVJWiAuTNW3yw0gFsV9veGxYzLPaglv3AjYnYPyCcL5hjarzi-XaMkD3_pwydJ7vRkR1i5zAjBs5w3Sst5ONS37IFi6XdkrGasvQ8jGSK4qGTsvtgdQz3IJf-oszVkzoMEfk5KOl1ayY3l2SdwTaYA4W52ZZKT4EgdBOeSZJHM7eewyyPydNwJB7Mpa0XogdmcjXlBR0iOkZYITggeGs_5mWnyLr4iAEcNR5-G2V7fRQdFgML9ahvEv1sEy4CocN60C3owZa3VJWonmbO4srJftYC-jkhtT8kDgsC-xvI-AKyN-BK9WwspSiKrhPWgJQrw1qVglI3kxDzrNzVKIaqYcoGEZLzx1BZsU-P4uQbqUijXq-DVB5pbYad2tA9naN7f5L8kQt_Hprxaw96B7sCVM2pzmzObztSLuKms9I5CWxnDYJxf9ClpLFLpJQZa5CMQ_CEAnlyDMTjy5xcQsdpcuPjOBdVkBPHpIVTfPfBX7V2ytK7jD09Or58W9q8fsjXEq8y-T6Y46tsBNeMyYFSoGQfvq3hPCn2QgRo_M29udxcris88Rah21YvgDwS3-Xr6yyMESTo2_AcapL-39z66upjoMzQyBEL03orblxSpcSeQ-3JIBLVBLofpWTd1IUEHQiUEgNjuTzMTROq7ZEuBzUMZ2jk9zX2krE3wsweGbaBQXEtT-UR-UqNmav-dMWACCQl_Wj2-EbS-D0sftcYoRllQ4kzlSh70BKsqrBqYMcSaITSLMebxjlfg6HcN1FQdQA6jMC7K_yg5tr_XiMcGMPwT4eKGEmPJMf53TTCY-F6v7A_KUMumcU8HlUpEIJ-Teh6gZlZEFtdX2Rki67WFBvkIgK-am-nKW0uG_SP2BPAKpjM0CzJO_nY4sCCgJTmJ6VtDSGJxmXl1aCNK9z3RL8MaDSgxfTIvQmJ3bdtspEiTugQi7fjzG7CVmpgxrLXgck_TDE4ZrQdh1K8ujweLX80n7Z-0RKWEmg0kkbXrUPoH8k5RRFhrysGCSZkYtmY0KZOBZIVGgptsMa42LmodCIq0RpRFqUPnqgvrvqH79TpB8L7PokQQQq_xWU8IDknIFn64azGj7q0uw2t8oPYs3ypR0EnH6A8T0GbB3BNtdA=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1055.07,
            "totalPrice": 803
          },
          {
            "allPassengerTotalPrice": 804,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPIrZHl0fdWa1aWYVZZRvyk0raZoJwExUtPdiwGjCQrDSVlKOnqI_T9a0KxY4zmWgcBv93-llo5MMKg12pMXWPDyp_vmPaL806FSE_C9-8PiTPyQU3zO-k_Fis_xSywIFSZIqDQL9__Ebm6csXokaQ-2CRU9-XVcrk0iX_kQZ7amvZXmrgcbF8KT2bUkPL_KR31-llOj2ekwlDW_7xqsWpvarDngGSA2S28bc3g4CGTRP_Kcq2wNGJp1ZMEsQV93Oev6AWYkWqPfXapreTa5CngMFjflfrRTtO8QYqJTNWnm0v13WYu-mozO5asYnqRsAGOQnAASeX7IDxpW-tBJuYUk_NzRZzH6eh0oC81pL79u0zYlSAg3B_LDAkJIRxL6r6ncKM1-5A_STlsmFowfFWiV_G0QLlNifDOw6cHGpr83KiwW7_OhTLjBw0W9i9OopWlE9s0RSJxsm47g3rb7f2uiyp7ed0zL4wfTgwhpeT_XNzO86Ssd262-G-IOv6VYbdVXJ19mv3TmLbc5y3XkejV7BMEY0v5OTAHZExOsS3d1FO8ndYiAvbdzO3yIfVd8-LCbta9iCOnh4U-PVpNMB9g6VSYpFgSXRDX82ugJor9sOkwDTsxoLoDeeSU61kTSDyqt-eH6iBwAjS4czM10_f_ni7njnATX9YnxhFTR9uFORajmO1MRK0Iw7hUDqDXk9eYkvQ7Eqeozdbebb3wMnzKRfs4nH468TLbfj_xE1-pLHCQfuQd5dEFoAE786J8Ba8CrwqOZtRzqhZzMcyHEeOtgbjWIYhaY_nwrO3V0TvsWvbR8h11-BXYQCjvNxSKLKkfA-6_POj-vTVO8Q8r5mtP9fEB-SRJmcALYNEP0CSe47Oz6nuzTh68MMwQ3L7d_O6FBHTsMddsIVoR-EZtCNPOlVZWCVg7-gBhNBr6WB-CAIYu_XIuycXGZTivRwLX8sRQ_Vpxn19yu5e47tSgOK9n7wvFDpLQnk3uNVvgXZFkFGDlNsSdrd5vjwo61K_FwfiUnmPvfiE2pt1Nk_uNOf1FM92T3BeQV0WO9_ceSjDicrMZvVIQnlmMQ4-oiTd4OlUb0184xBZt7dqmCoCmB6hAcJLNXLf3S_KY8eLb8hv_Cry5OUd6Wdv6BbpYrtpi6Vn50iKj6tfKFEb2oJYD-dRczwaSn_lxccQoxv5ldW2lu8N7JZBEzkumZXuj2Ed4cgzj9xLeB7bw5930HP6b83VYPi9lIdlJXaqWtOvsfm2R8=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPIrZHl0fdWa1aWYVZZRvyk0raZoJwExUtPdiwGjCQrDSVlKOnqI_T9a0KxY4zmWgcBv93-llo5MMKg12pMXWPDyp_vmPaL806FSE_C9-8PiTPyQU3zO-k_Fis_xSywIFSZIqDQL9__Ebm6csXokaQ-2CRU9-XVcrk0iX_kQZ7amvZXmrgcbF8KT2bUkPL_KR31-llOj2ekwlDW_7xqsWpvarDngGSA2S28bc3g4CGTRP_Kcq2wNGJp1ZMEsQV93Oev6AWYkWqPfXapreTa5CngMFjflfrRTtO8QYqJTNWnm0v13WYu-mozO5asYnqRsAGOQnAASeX7IDxpW-tBJuYUk_NzRZzH6eh0oC81pL79u0zYlSAg3B_LDAkJIRxL6r6ncKM1-5A_STlsmFowfFWiV_G0QLlNifDOw6cHGpr83KiwW7_OhTLjBw0W9i9OopWlE9s0RSJxsm47g3rb7f2uiyp7ed0zL4wfTgwhpeT_XNzO86Ssd262-G-IOv6VYbdVXJ19mv3TmLbc5y3XkejV7BMEY0v5OTAHZExOsS3d1FO8ndYiAvbdzO3yIfVd8-LCbta9iCOnh4U-PVpNMB9g6VSYpFgSXRDX82ugJor9sOkwDTsxoLoDeeSU61kTSDyqt-eH6iBwAjS4czM10_f_ni7njnATX9YnxhFTR9uFORajmO1MRK0Iw7hUDqDXk9eYkvQ7Eqeozdbebb3wMnzKRfs4nH468TLbfj_xE1-pLHCQfuQd5dEFoAE786J8Ba8CrwqOZtRzqhZzMcyHEeOtgbjWIYhaY_nwrO3V0TvsWvbR8h11-BXYQCjvNxSKLKkfA-6_POj-vTVO8Q8r5mtP9fEB-SRJmcALYNEP0CSe47Oz6nuzTh68MMwQ3L7d_O6FBHTsMddsIVoR-EZtCNPOlVZWCVg7-gBhNBr6WB-CAIYu_XIuycXGZTivRwLX8sRQ_Vpxn19yu5e47tSgOK9n7wvFDpLQnk3uNVvgXZFkFGDlNsSdrd5vjwo61K_FwfiUnmPvfiE2pt1Nk_uNOf1FM92T3BeQV0WO9_ceSjDicrMZvVIQnlmMQ4-oiTd4OlUb0184xBZt7dqmCoCmB6hAcJLNXLf3S_KY8eLb8hv_Cry5OUd6Wdv6BbpYrtpi6Vn50iKj6tfKFEb2oJYD-dRczwaSn_lxccQoxv5ldW2lu8N7JZBEzkumZXuj2Ed4cgzj9xLeB7bw5930HP6b83VYPi9lIdlJXaqWtOvsfm2R8=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GPIrZHl0fdWa1aWYVZZRvyk0raZoJwExUtPdiwGjCQrDSVlKOnqI_T9a0KxY4zmWgcBv93-llo5MMKg12pMXWPDyp_vmPaL806FSE_C9-8PiTPyQU3zO-k_Fis_xSywIFSZIqDQL9__Ebm6csXokaQ-2CRU9-XVcrk0iX_kQZ7amvZXmrgcbF8KT2bUkPL_KR31-llOj2ekwlDW_7xqsWpvarDngGSA2S28bc3g4CGTRP_Kcq2wNGJp1ZMEsQV93Oev6AWYkWqPfXapreTa5CngMFjflfrRTtO8QYqJTNWnm0v13WYu-mozO5asYnqRsAGOQnAASeX7IDxpW-tBJuYUk_NzRZzH6eh0oC81pL79u0zYlSAg3B_LDAkJIRxL6r6ncKM1-5A_STlsmFowfFWiV_G0QLlNifDOw6cHGpr83KiwW7_OhTLjBw0W9i9OopWlE9s0RSJxsm47g3rb7f2uiyp7ed0zL4wfTgwhpeT_XNzO86Ssd262-G-IOv6VYbdVXJ19mv3TmLbc5y3XkejV7BMEY0v5OTAHZExOsS3d1FO8ndYiAvbdzO3yIfVd8-LCbta9iCOnh4U-PVpNMB9g6VSYpFgSXRDX82ugJor9sOkwDTsxoLoDeeSU61kTSDyqt-eH6iBwAjS4czM10_f_ni7njnATX9YnxhFTR9uFORajmO1MRK0Iw7hUDqDXk9eYkvQ7Eqeozdbebb3wMnzKRfs4nH468TLbfj_xE1-pLHCQfuQd5dEFoAE786J8Ba8CrwqOZtRzqhZzMcyHEeOtgbjWIYhaY_nwrO3V0TvsWvbR8h11-BXYQCjvNxSKLKkfA-6_POj-vTVO8Q8r5mtP9fEB-SRJmcALYNEP0CSe47Oz6nuzTh68MMwQ3L7d_O6FBHTsMddsIVoR-EZtCNPOlVZWCVg7-gBhNBr6WB-CAIYu_XIuycXGZTivRwLX8sRQ_Vpxn19yu5e47tSgOK9n7wvFDpLQnk3uNVvgXZFkFGDlNsSdrd5vjwo61K_FwfiUnmPvfiE2pt1Nk_uNOf1FM92T3BeQV0WO9_ceSjDicrMZvVIQnlmMQ4-oiTd4OlUb0184xBZt7dqmCoCmB6hAcJLNXLf3S_KY8eLb8hv_Cry5OUd6Wdv6BbpYrtpi6Vn50iKj6tfKFEb2oJYD-dRczwaSn_lxccQoxv5ldW2lu8N7JZBEzkumZXuj2Ed4cgzj9xLeB7bw5930HP6b83VYPi9lIdlJXaqWtOvsfm2R8=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1029.25,
            "totalPrice": 804
          },
          {
            "allPassengerTotalPrice": 804,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsjMTBtOOL1UNzlLpZ0AV4mN0QDneWjO4MeyWhonY66NlikU-6gXRicWnQPH_aFDgItNd0qGi9wC8slx2Uotv0Skl1iyQjGw2ZCFecqAESoKSSivr537TRqQuykeehh4v11S4j7V_BbgdLNHV2y5pmtSUkEUmhCOUfua962x2XL7S0LC4lTiU_G-vT6aR65KTt2pLe5UpNaRlptyYz8q5jQoZArTtCATphgEmxLniUq6cA21IIp6gJjQOVZfZ7_GlyVqzaQWmRzixhnqpJw3SsCYQ1lqEYyY_NXd4BNtsVRUmfYQE8lxLCIntdGUeuSri94XGciVJvTHVMoIPw7Y8a6Du7J1mwzn4jQ_LrVUP6DZoTqrW_cUxWL0Jp4OkjdGApnRjIHRw_QluPIJeBAtBuMCHj6kmR42LkwrDvRmxvyc_5Ik5h6xAHa6UU7ZBcnR0bm-c_mG-Bc34cCnL6E2PsWXIl2BBa5a7VwIVq0Q_c97bOUkcRSohABGLbDH_CFD-x-p4AEB3352qqynUbvLh1YKm5psIjONbr62QjJsZmlFJwYtRzgRK_diNUCtZPznA8qVp-0RsVNgHv2HOYmrV4_9hUkQ-wkHYgIalsr9v4VE_ZgZ8hmZviArC9j7yPO35LFKYAQbTDlc4cxKIOJboqCc_Kbs4OHphHwQHZH5cwNC8fwTpTsWw8Mzoy2qdVzgtu96YaOz9tb4buyKpKGfOK89cObBQc1Mu2wLyNKadMVo3xQntNxAnlyRezUWgYeoWgy52UATajVJWEvD8aoRUFlXxd27SbC8jzR9FgRS4H-ktPCoy9UFw4tTT_DhwHeuYuUkqR_VukTm_9CKFFvR-MO8mbww9q-QSGVEMTXJOyxQgmBHZGVGZh9C64gkqBgDhLrDzLC29OeSrHuFj_EvffX1eH9R0T8fjrWgo0fIj5YlPp0W0TkwxipSUluCqhKytfx22Gs7vijru2W73oIfA7UBDf8ARZn5aKfgok4Nnd-SiC96hXUSaEuFHnVqK1PIxdFokIXNWISw9sOKL7fMxjflRhjUg9rezJlij6ef-PQ-EEAKFsJhBFJo9AA91A0jyHAw2J8RVsw3h_Lcj0C3X4rBBAgWEuwzp7rzWzWDQ7Rmres7ZoNC7_UqTB7LJQlrv1HtrSG1xjejRBdxbGa1NemQGGr7_d96T9QUnBZZoIBf_-HzIV_VTtTEoXC8x47fVRIgK9JJ0hfKf3N1EVEvuuQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsjMTBtOOL1UNzlLpZ0AV4mN0QDneWjO4MeyWhonY66NlikU-6gXRicWnQPH_aFDgItNd0qGi9wC8slx2Uotv0Skl1iyQjGw2ZCFecqAESoKSSivr537TRqQuykeehh4v11S4j7V_BbgdLNHV2y5pmtSUkEUmhCOUfua962x2XL7S0LC4lTiU_G-vT6aR65KTt2pLe5UpNaRlptyYz8q5jQoZArTtCATphgEmxLniUq6cA21IIp6gJjQOVZfZ7_GlyVqzaQWmRzixhnqpJw3SsCYQ1lqEYyY_NXd4BNtsVRUmfYQE8lxLCIntdGUeuSri94XGciVJvTHVMoIPw7Y8a6Du7J1mwzn4jQ_LrVUP6DZoTqrW_cUxWL0Jp4OkjdGApnRjIHRw_QluPIJeBAtBuMCHj6kmR42LkwrDvRmxvyc_5Ik5h6xAHa6UU7ZBcnR0bm-c_mG-Bc34cCnL6E2PsWXIl2BBa5a7VwIVq0Q_c97bOUkcRSohABGLbDH_CFD-x-p4AEB3352qqynUbvLh1YKm5psIjONbr62QjJsZmlFJwYtRzgRK_diNUCtZPznA8qVp-0RsVNgHv2HOYmrV4_9hUkQ-wkHYgIalsr9v4VE_ZgZ8hmZviArC9j7yPO35LFKYAQbTDlc4cxKIOJboqCc_Kbs4OHphHwQHZH5cwNC8fwTpTsWw8Mzoy2qdVzgtu96YaOz9tb4buyKpKGfOK89cObBQc1Mu2wLyNKadMVo3xQntNxAnlyRezUWgYeoWgy52UATajVJWEvD8aoRUFlXxd27SbC8jzR9FgRS4H-ktPCoy9UFw4tTT_DhwHeuYuUkqR_VukTm_9CKFFvR-MO8mbww9q-QSGVEMTXJOyxQgmBHZGVGZh9C64gkqBgDhLrDzLC29OeSrHuFj_EvffX1eH9R0T8fjrWgo0fIj5YlPp0W0TkwxipSUluCqhKytfx22Gs7vijru2W73oIfA7UBDf8ARZn5aKfgok4Nnd-SiC96hXUSaEuFHnVqK1PIxdFokIXNWISw9sOKL7fMxjflRhjUg9rezJlij6ef-PQ-EEAKFsJhBFJo9AA91A0jyHAw2J8RVsw3h_Lcj0C3X4rBBAgWEuwzp7rzWzWDQ7Rmres7ZoNC7_UqTB7LJQlrv1HtrSG1xjejRBdxbGa1NemQGGr7_d96T9QUnBZZoIBf_-HzIV_VTtTEoXC8x47fVRIgK9JJ0hfKf3N1EVEvuuQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsjMTBtOOL1UNzlLpZ0AV4mN0QDneWjO4MeyWhonY66NlikU-6gXRicWnQPH_aFDgItNd0qGi9wC8slx2Uotv0Skl1iyQjGw2ZCFecqAESoKSSivr537TRqQuykeehh4v11S4j7V_BbgdLNHV2y5pmtSUkEUmhCOUfua962x2XL7S0LC4lTiU_G-vT6aR65KTt2pLe5UpNaRlptyYz8q5jQoZArTtCATphgEmxLniUq6cA21IIp6gJjQOVZfZ7_GlyVqzaQWmRzixhnqpJw3SsCYQ1lqEYyY_NXd4BNtsVRUmfYQE8lxLCIntdGUeuSri94XGciVJvTHVMoIPw7Y8a6Du7J1mwzn4jQ_LrVUP6DZoTqrW_cUxWL0Jp4OkjdGApnRjIHRw_QluPIJeBAtBuMCHj6kmR42LkwrDvRmxvyc_5Ik5h6xAHa6UU7ZBcnR0bm-c_mG-Bc34cCnL6E2PsWXIl2BBa5a7VwIVq0Q_c97bOUkcRSohABGLbDH_CFD-x-p4AEB3352qqynUbvLh1YKm5psIjONbr62QjJsZmlFJwYtRzgRK_diNUCtZPznA8qVp-0RsVNgHv2HOYmrV4_9hUkQ-wkHYgIalsr9v4VE_ZgZ8hmZviArC9j7yPO35LFKYAQbTDlc4cxKIOJboqCc_Kbs4OHphHwQHZH5cwNC8fwTpTsWw8Mzoy2qdVzgtu96YaOz9tb4buyKpKGfOK89cObBQc1Mu2wLyNKadMVo3xQntNxAnlyRezUWgYeoWgy52UATajVJWEvD8aoRUFlXxd27SbC8jzR9FgRS4H-ktPCoy9UFw4tTT_DhwHeuYuUkqR_VukTm_9CKFFvR-MO8mbww9q-QSGVEMTXJOyxQgmBHZGVGZh9C64gkqBgDhLrDzLC29OeSrHuFj_EvffX1eH9R0T8fjrWgo0fIj5YlPp0W0TkwxipSUluCqhKytfx22Gs7vijru2W73oIfA7UBDf8ARZn5aKfgok4Nnd-SiC96hXUSaEuFHnVqK1PIxdFokIXNWISw9sOKL7fMxjflRhjUg9rezJlij6ef-PQ-EEAKFsJhBFJo9AA91A0jyHAw2J8RVsw3h_Lcj0C3X4rBBAgWEuwzp7rzWzWDQ7Rmres7ZoNC7_UqTB7LJQlrv1HtrSG1xjejRBdxbGa1NemQGGr7_d96T9QUnBZZoIBf_-HzIV_VTtTEoXC8x47fVRIgK9JJ0hfKf3N1EVEvuuQ==",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1055.91,
            "totalPrice": 804
          },
          {
            "allPassengerTotalPrice": 804,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoD3vfbRxDldCykDCsO6RnEJ6qOX9W0ef3t-Vrhp9XRJ8JSvx1zMXYkwFP9VhENNUvn27RCPKzMjuHoFdicnV4vg0VwcvMG_LC6oP_oswfAc86nz142w-iJcFGKO7NmVxepCP5MjXqOqF3Qd7M9bzZl-ISpeYl0ne4AboCVTSLx7GOFzApowAi3Bjr7ic0uisr5es0yrgtabqKHXQMwO6XYNZqeSMNcv31Ylrmh5MvDuMzi_vHGdoHhDr1FCGtY_NURqxtHD7JRN9qwBAleGZy63GUQzrIIcEg3ilTeZoTGHEooTUaIdoqS2OFFHa1QXqdC-WLhsYuKWdResf-Aj178YHbITd_Pjt135VssxeValY8z3s_nSP6bV7cDy-gcarj9GXf4J8XLSLHcxGoVqj7UTHJwWg3pMtnT7L5nsgyoo7Uu2DU1x9s80gnBVQlbz68GvvGnqVyLSJV971zuZ4_w4KcRMOBQP_vB1A41-eUMA08EVGRs7b8ve1yN-j3yjL5ePVviyUSpzeuZ0g2vKB6NdCaKWzZQIxARHh4_SFrxYSAK_bTjj20ct-ExvIx6oncgd9fwE4wZFD71z6bauCqOOuWJYEEocABzf_JRuNE_AU2IFpb7zfNfoqS4M3VGN46HhRc_CNB7e2q5tXnj07gvRWwpdC5QHwh15ufrTDj_eiiZO3bOiiHsnCvFCNc0Y25OOOtJh92Ubm-AkB_cNohVnj6DtBW9XVucVGwtsxavb5m27kcuMp7fSlW4glww_xTsogmyMUnR8VwSsEqkk3NWhuOe23xyRW1LZenRbFE508BlYQAkfT9ng-yvHIHATOO-yMNKna6mHHjAm5B2SZd6MoC3Sc4B9W7Ltk1BmEoNVaGTcjY8f40PKVommLiq5k7wOWZF5BXsolrgO2ZSVVQ_vD9_FMuuUTvVftcqy_O4MXn2sZEMScaB5klFFGVqbgf6MOjXvRaq8NCxf9GCzR39aLdQXZMjPVO7nZkUXrd99lktOUwtCV6hHVA5r8uwnwaK3LN1-SAlwevxq8_zbR3MwUAjRB4zJuU_Z7irZqfsho9MKaRdqHF1HBkFeOrLRWvo1NU20o9lLzFoERcfivKhDSvuj3T-hIRA9vvr0tb1JunYBYzgwLP62QqrMGgbubjlhNbTEMJ32xNg-cgqG7fmRPAhhO9rtp8Fi86QZWMuK4Ft_QUjH3PWRwvGks4MrmlCYADQfiJfXmzrSvvJBelnD8oIrm265sOcyZXm_oodfhJAuHZeRcjln4pPIZsXLg",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoD3vfbRxDldCykDCsO6RnEJ6qOX9W0ef3t-Vrhp9XRJ8JSvx1zMXYkwFP9VhENNUvn27RCPKzMjuHoFdicnV4vg0VwcvMG_LC6oP_oswfAc86nz142w-iJcFGKO7NmVxepCP5MjXqOqF3Qd7M9bzZl-ISpeYl0ne4AboCVTSLx7GOFzApowAi3Bjr7ic0uisr5es0yrgtabqKHXQMwO6XYNZqeSMNcv31Ylrmh5MvDuMzi_vHGdoHhDr1FCGtY_NURqxtHD7JRN9qwBAleGZy63GUQzrIIcEg3ilTeZoTGHEooTUaIdoqS2OFFHa1QXqdC-WLhsYuKWdResf-Aj178YHbITd_Pjt135VssxeValY8z3s_nSP6bV7cDy-gcarj9GXf4J8XLSLHcxGoVqj7UTHJwWg3pMtnT7L5nsgyoo7Uu2DU1x9s80gnBVQlbz68GvvGnqVyLSJV971zuZ4_w4KcRMOBQP_vB1A41-eUMA08EVGRs7b8ve1yN-j3yjL5ePVviyUSpzeuZ0g2vKB6NdCaKWzZQIxARHh4_SFrxYSAK_bTjj20ct-ExvIx6oncgd9fwE4wZFD71z6bauCqOOuWJYEEocABzf_JRuNE_AU2IFpb7zfNfoqS4M3VGN46HhRc_CNB7e2q5tXnj07gvRWwpdC5QHwh15ufrTDj_eiiZO3bOiiHsnCvFCNc0Y25OOOtJh92Ubm-AkB_cNohVnj6DtBW9XVucVGwtsxavb5m27kcuMp7fSlW4glww_xTsogmyMUnR8VwSsEqkk3NWhuOe23xyRW1LZenRbFE508BlYQAkfT9ng-yvHIHATOO-yMNKna6mHHjAm5B2SZd6MoC3Sc4B9W7Ltk1BmEoNVaGTcjY8f40PKVommLiq5k7wOWZF5BXsolrgO2ZSVVQ_vD9_FMuuUTvVftcqy_O4MXn2sZEMScaB5klFFGVqbgf6MOjXvRaq8NCxf9GCzR39aLdQXZMjPVO7nZkUXrd99lktOUwtCV6hHVA5r8uwnwaK3LN1-SAlwevxq8_zbR3MwUAjRB4zJuU_Z7irZqfsho9MKaRdqHF1HBkFeOrLRWvo1NU20o9lLzFoERcfivKhDSvuj3T-hIRA9vvr0tb1JunYBYzgwLP62QqrMGgbubjlhNbTEMJ32xNg-cgqG7fmRPAhhO9rtp8Fi86QZWMuK4Ft_QUjH3PWRwvGks4MrmlCYADQfiJfXmzrSvvJBelnD8oIrm265sOcyZXm_oodfhJAuHZeRcjln4pPIZsXLg",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "U2",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoD3vfbRxDldCykDCsO6RnEJ6qOX9W0ef3t-Vrhp9XRJ8JSvx1zMXYkwFP9VhENNUvn27RCPKzMjuHoFdicnV4vg0VwcvMG_LC6oP_oswfAc86nz142w-iJcFGKO7NmVxepCP5MjXqOqF3Qd7M9bzZl-ISpeYl0ne4AboCVTSLx7GOFzApowAi3Bjr7ic0uisr5es0yrgtabqKHXQMwO6XYNZqeSMNcv31Ylrmh5MvDuMzi_vHGdoHhDr1FCGtY_NURqxtHD7JRN9qwBAleGZy63GUQzrIIcEg3ilTeZoTGHEooTUaIdoqS2OFFHa1QXqdC-WLhsYuKWdResf-Aj178YHbITd_Pjt135VssxeValY8z3s_nSP6bV7cDy-gcarj9GXf4J8XLSLHcxGoVqj7UTHJwWg3pMtnT7L5nsgyoo7Uu2DU1x9s80gnBVQlbz68GvvGnqVyLSJV971zuZ4_w4KcRMOBQP_vB1A41-eUMA08EVGRs7b8ve1yN-j3yjL5ePVviyUSpzeuZ0g2vKB6NdCaKWzZQIxARHh4_SFrxYSAK_bTjj20ct-ExvIx6oncgd9fwE4wZFD71z6bauCqOOuWJYEEocABzf_JRuNE_AU2IFpb7zfNfoqS4M3VGN46HhRc_CNB7e2q5tXnj07gvRWwpdC5QHwh15ufrTDj_eiiZO3bOiiHsnCvFCNc0Y25OOOtJh92Ubm-AkB_cNohVnj6DtBW9XVucVGwtsxavb5m27kcuMp7fSlW4glww_xTsogmyMUnR8VwSsEqkk3NWhuOe23xyRW1LZenRbFE508BlYQAkfT9ng-yvHIHATOO-yMNKna6mHHjAm5B2SZd6MoC3Sc4B9W7Ltk1BmEoNVaGTcjY8f40PKVommLiq5k7wOWZF5BXsolrgO2ZSVVQ_vD9_FMuuUTvVftcqy_O4MXn2sZEMScaB5klFFGVqbgf6MOjXvRaq8NCxf9GCzR39aLdQXZMjPVO7nZkUXrd99lktOUwtCV6hHVA5r8uwnwaK3LN1-SAlwevxq8_zbR3MwUAjRB4zJuU_Z7irZqfsho9MKaRdqHF1HBkFeOrLRWvo1NU20o9lLzFoERcfivKhDSvuj3T-hIRA9vvr0tb1JunYBYzgwLP62QqrMGgbubjlhNbTEMJ32xNg-cgqG7fmRPAhhO9rtp8Fi86QZWMuK4Ft_QUjH3PWRwvGks4MrmlCYADQfiJfXmzrSvvJBelnD8oIrm265sOcyZXm_oodfhJAuHZeRcjln4pPIZsXLg",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "13:05",
                "destination": "SFO",
                "duration": "41h33m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "IBZ",
                    "cityFrom": "Seville",
                    "cityTo": "Ibiza",
                    "combination_id": "04b80f354e2a0000048c9b92",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 2424,
                    "flyFrom": "SVQ",
                    "flyTo": "IBZ",
                    "guarantee": false,
                    "id": "04b80f354e2a0000048c9b92_0",
                    "local_arrival": "2024-10-14T14:25:00.000Z",
                    "local_departure": "2024-10-14T13:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2424",
                    "return": 0,
                    "utc_arrival": "2024-10-14T12:25:00.000Z",
                    "utc_departure": "2024-10-14T11:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "IBZ",
                    "cityCodeTo": "LON",
                    "cityFrom": "Ibiza",
                    "cityTo": "London",
                    "combination_id": "0f3522f54e2b00003abc8da6",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8106,
                    "flyFrom": "IBZ",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0f3522f54e2b00003abc8da6_0",
                    "local_arrival": "2024-10-15T02:05:00.000Z",
                    "local_departure": "2024-10-15T00:40:00.000Z",
                    "operating_carrier": "U2",
                    "operating_flight_no": "8106",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:05:00.000Z",
                    "utc_departure": "2024-10-14T22:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1107.35,
            "totalPrice": 804
          },
          {
            "allPassengerTotalPrice": 806,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsVpSA0_ZcTgV9jNwfLujCx4BU2YChnzGWkDUbPcyBbvF9nYFoRvFw1xy3wRcsXU98kDuT4QVKfoHDiqhdaydthKp7WBnJxoLZtAqaohHLHm8dQofia39d3pjv5Y9t29TPC2LApLxsCraAuZveNGwnHLTRGWXuU0_dHjqaBqPSMJnJoVlMugvmaYz0xVyh04Wc3FPjl68Uef_ouQjdRWMAVDgcTvl-QjxeiPDNY5xblq6MU6E3R9Pew6_jh9VHfGTFwMSYCrGpMMG7ApOvhyrybo62e-ZsQjywa1eS-yrI80pA5af3tdZILpNftm9b9NWCLDAWVS17Iq5EhViacjOS3GyNpRepgurhyTHsYA9f5ip7kcLXdFDP2tXcN6Yv4rHYCjuUt_yEbx2Ay0jaz_VKMQjkAoUFnDGbOacQMbT3J112m3G6UHTPBzrTVYZMl4DYojEYpB-iXBDw6Lz5DG6uzFVUbmQAWw0EVuZ_64fo-cmnnrDZBfz2fCjtDWZXhCGRlRDU1WxV0fi3gVs8Nkp4w0bjuQAeFQYsHG3LM0hJKh00Ml_10u9xJjSQ_sw4ujRasFoa6mzOlgHT0nlz8ZyCTwkw9emaM_7Uw3_iI_gGfTzxx-LXiZYTGVBURgUnl_Kov2rKLkiJgcr2WvbiiXDwGBFDH8x47UU_VQsI7m3_wAqQV6svsNh_sMq6hFCNr84nn2FLJvOA_omTwcV1rcFCQ814Nb1CoEuxzDkGOG-vuPOyxR6hwMzus9Lwqsbgih6jU83Wv_WMSg_3SsWAkTqtiIOVwMfKe0_eUTQNs-599A1USpmju9LxTBMBuRF5yhPWBwmDdwDm4Td5F-Qyq6Bd4HUb5jWkAKbwriANClAsuPu_4sEYwgVSApL5ufNdgiGT-s86Q-HsZkb-AAwdaVWoldg8lwLrH-JAq-x9vRB8cxT7x7T3xBBXvxnnl8rI9VyB0URnWNU3ALH3CwQ4VJ-q1B_k4XJNQBX_n4gTMyXCIAtib-xy1F2dTXo5T76fv4vybIverllxVfJ_fz7GLgVFA3NYPArucOOKLwZwz4J2flNlibvAuAHlku2m_dAD0mAdAo0VmdoyHfCulR_PSi91y1eOPmzTD1v00Kt9vNJXAx_JMnNt1u2dLXnpeHgQmtWltRen_UUOf482bL7TFFZvvCR7dAVtzAT19djDCFuycw=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsVpSA0_ZcTgV9jNwfLujCx4BU2YChnzGWkDUbPcyBbvF9nYFoRvFw1xy3wRcsXU98kDuT4QVKfoHDiqhdaydthKp7WBnJxoLZtAqaohHLHm8dQofia39d3pjv5Y9t29TPC2LApLxsCraAuZveNGwnHLTRGWXuU0_dHjqaBqPSMJnJoVlMugvmaYz0xVyh04Wc3FPjl68Uef_ouQjdRWMAVDgcTvl-QjxeiPDNY5xblq6MU6E3R9Pew6_jh9VHfGTFwMSYCrGpMMG7ApOvhyrybo62e-ZsQjywa1eS-yrI80pA5af3tdZILpNftm9b9NWCLDAWVS17Iq5EhViacjOS3GyNpRepgurhyTHsYA9f5ip7kcLXdFDP2tXcN6Yv4rHYCjuUt_yEbx2Ay0jaz_VKMQjkAoUFnDGbOacQMbT3J112m3G6UHTPBzrTVYZMl4DYojEYpB-iXBDw6Lz5DG6uzFVUbmQAWw0EVuZ_64fo-cmnnrDZBfz2fCjtDWZXhCGRlRDU1WxV0fi3gVs8Nkp4w0bjuQAeFQYsHG3LM0hJKh00Ml_10u9xJjSQ_sw4ujRasFoa6mzOlgHT0nlz8ZyCTwkw9emaM_7Uw3_iI_gGfTzxx-LXiZYTGVBURgUnl_Kov2rKLkiJgcr2WvbiiXDwGBFDH8x47UU_VQsI7m3_wAqQV6svsNh_sMq6hFCNr84nn2FLJvOA_omTwcV1rcFCQ814Nb1CoEuxzDkGOG-vuPOyxR6hwMzus9Lwqsbgih6jU83Wv_WMSg_3SsWAkTqtiIOVwMfKe0_eUTQNs-599A1USpmju9LxTBMBuRF5yhPWBwmDdwDm4Td5F-Qyq6Bd4HUb5jWkAKbwriANClAsuPu_4sEYwgVSApL5ufNdgiGT-s86Q-HsZkb-AAwdaVWoldg8lwLrH-JAq-x9vRB8cxT7x7T3xBBXvxnnl8rI9VyB0URnWNU3ALH3CwQ4VJ-q1B_k4XJNQBX_n4gTMyXCIAtib-xy1F2dTXo5T76fv4vybIverllxVfJ_fz7GLgVFA3NYPArucOOKLwZwz4J2flNlibvAuAHlku2m_dAD0mAdAo0VmdoyHfCulR_PSi91y1eOPmzTD1v00Kt9vNJXAx_JMnNt1u2dLXnpeHgQmtWltRen_UUOf482bL7TFFZvvCR7dAVtzAT19djDCFuycw=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GsVpSA0_ZcTgV9jNwfLujCx4BU2YChnzGWkDUbPcyBbvF9nYFoRvFw1xy3wRcsXU98kDuT4QVKfoHDiqhdaydthKp7WBnJxoLZtAqaohHLHm8dQofia39d3pjv5Y9t29TPC2LApLxsCraAuZveNGwnHLTRGWXuU0_dHjqaBqPSMJnJoVlMugvmaYz0xVyh04Wc3FPjl68Uef_ouQjdRWMAVDgcTvl-QjxeiPDNY5xblq6MU6E3R9Pew6_jh9VHfGTFwMSYCrGpMMG7ApOvhyrybo62e-ZsQjywa1eS-yrI80pA5af3tdZILpNftm9b9NWCLDAWVS17Iq5EhViacjOS3GyNpRepgurhyTHsYA9f5ip7kcLXdFDP2tXcN6Yv4rHYCjuUt_yEbx2Ay0jaz_VKMQjkAoUFnDGbOacQMbT3J112m3G6UHTPBzrTVYZMl4DYojEYpB-iXBDw6Lz5DG6uzFVUbmQAWw0EVuZ_64fo-cmnnrDZBfz2fCjtDWZXhCGRlRDU1WxV0fi3gVs8Nkp4w0bjuQAeFQYsHG3LM0hJKh00Ml_10u9xJjSQ_sw4ujRasFoa6mzOlgHT0nlz8ZyCTwkw9emaM_7Uw3_iI_gGfTzxx-LXiZYTGVBURgUnl_Kov2rKLkiJgcr2WvbiiXDwGBFDH8x47UU_VQsI7m3_wAqQV6svsNh_sMq6hFCNr84nn2FLJvOA_omTwcV1rcFCQ814Nb1CoEuxzDkGOG-vuPOyxR6hwMzus9Lwqsbgih6jU83Wv_WMSg_3SsWAkTqtiIOVwMfKe0_eUTQNs-599A1USpmju9LxTBMBuRF5yhPWBwmDdwDm4Td5F-Qyq6Bd4HUb5jWkAKbwriANClAsuPu_4sEYwgVSApL5ufNdgiGT-s86Q-HsZkb-AAwdaVWoldg8lwLrH-JAq-x9vRB8cxT7x7T3xBBXvxnnl8rI9VyB0URnWNU3ALH3CwQ4VJ-q1B_k4XJNQBX_n4gTMyXCIAtib-xy1F2dTXo5T76fv4vybIverllxVfJ_fz7GLgVFA3NYPArucOOKLwZwz4J2flNlibvAuAHlku2m_dAD0mAdAo0VmdoyHfCulR_PSi91y1eOPmzTD1v00Kt9vNJXAx_JMnNt1u2dLXnpeHgQmtWltRen_UUOf482bL7TFFZvvCR7dAVtzAT19djDCFuycw=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1050.86,
            "totalPrice": 806
          },
          {
            "allPassengerTotalPrice": 806,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Glbt39n3sFHSaTzaGZ9SUaz_LmMUQSxJrK2GUUpg0PVq_PJkFT3OeCMer8kXIq2z_XJGDz1s0pF2L_QH5F4lmE1bcJ_kt71hyN27QNrRaQKR946CegVLHBKC7vZoGihcxjh7hRzJqwmm_LWKrCQ6xmtwyjenWAnj_fuSZZCVTXphOX-fxtuDy2dv3ij5XtTwFAscRfPzqKwNJW4PKwlkmr3Yj45q6QrYtnwNopsR1JsTcojeS8S06ZMWtqNToZWXH3Jt8oXH7g36iAKN5n9ND0VN0IcgetwipGKSNJxUfiPcHeXQUCUXsqjObY9T2vkLT5M4RM9R7_35HvYV6iGxvj_3Bti6GEl6NPT5D85_9dYxO_iQYOC-3oWnIy4kPy982gcPd2zQCFXcIFnqjFjzt1TbAXjUNxqGqdj2FAZblizzqP-H1tBhmneWgyd9xJrvmDtqeBY2u8Zm1_jNSvIclnD84p0oeFo4wsNsuxhmwSekv-Bat0Czz7LQyK9So58JrbiuD6y075s6zTwOmkBTDVm5ZEaa4OUvYQpCHyotopvvXvLsmEGiyM48ZT3dfiJP6AlmgmwVMNE_IOau9vK1TvRHDywUX47bMI92GyopuFzD9lFFLlz9BemaI1aZtD8IUZqW7B2w7GT0E1vJuKmXw7Od2Kic2S2zk4gVJncmAaDyOX9vMgNi0PBgQVVOtpMYpht1noRnGkgrBy0HGbLM-uYV8orZcC8NXi1vAo4UxzWs-u_1ow07y8WLLAEdhaXcRFX6dIOH85k3WjyA5b4yCJIbbvF_dwHV9ZBE4Rsi_xnUC-2biueqkMqcPgmkZRYePschj5ViTvhzjVYNMGVLc6cjxYHkOzxVT7lVTxaEw-ok1FXJja4xaJJSwutf_7kh9KbQfdBrwT69_MNcFJ9-mTWlVpM5hKo0O6wRok9X8o9L2LLd6i4Zcms69FMpcj2maFXCZJcT0nN4_lGwniBQc9SG7T2FUkFwZ7MAtlr0aISATjDjb2dSXrudAvjxOt_kbMX77pJ4Q_6y8gOl6WvHNMZ6BUVmPzzsRVHWuIjd13u6asjSctkFAP5a-u0gGIk8oeOsmE556o3AioPVz-n5d7xy6Xs7zAvO1KoqYSgiTsjHL51nr9rrygMiAfplttnjMRBkfuZHDhvNdvI5c7CUH2uwv5T5iK3n8Op6Bh5zCqIiq5r11PxavGwMBbZ6omeQfK9L175H7BfAgujhADahFo_zthuDOXcBOxX60KvysIGYBpRwlqJgWCEz5fJIDsLbJEgNCW6T7JnLzpxiWAnxlMANzSGm4QN6fPraqFflJT-Eabv6PwTiZSkSFN_fgdvaZ",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Glbt39n3sFHSaTzaGZ9SUaz_LmMUQSxJrK2GUUpg0PVq_PJkFT3OeCMer8kXIq2z_XJGDz1s0pF2L_QH5F4lmE1bcJ_kt71hyN27QNrRaQKR946CegVLHBKC7vZoGihcxjh7hRzJqwmm_LWKrCQ6xmtwyjenWAnj_fuSZZCVTXphOX-fxtuDy2dv3ij5XtTwFAscRfPzqKwNJW4PKwlkmr3Yj45q6QrYtnwNopsR1JsTcojeS8S06ZMWtqNToZWXH3Jt8oXH7g36iAKN5n9ND0VN0IcgetwipGKSNJxUfiPcHeXQUCUXsqjObY9T2vkLT5M4RM9R7_35HvYV6iGxvj_3Bti6GEl6NPT5D85_9dYxO_iQYOC-3oWnIy4kPy982gcPd2zQCFXcIFnqjFjzt1TbAXjUNxqGqdj2FAZblizzqP-H1tBhmneWgyd9xJrvmDtqeBY2u8Zm1_jNSvIclnD84p0oeFo4wsNsuxhmwSekv-Bat0Czz7LQyK9So58JrbiuD6y075s6zTwOmkBTDVm5ZEaa4OUvYQpCHyotopvvXvLsmEGiyM48ZT3dfiJP6AlmgmwVMNE_IOau9vK1TvRHDywUX47bMI92GyopuFzD9lFFLlz9BemaI1aZtD8IUZqW7B2w7GT0E1vJuKmXw7Od2Kic2S2zk4gVJncmAaDyOX9vMgNi0PBgQVVOtpMYpht1noRnGkgrBy0HGbLM-uYV8orZcC8NXi1vAo4UxzWs-u_1ow07y8WLLAEdhaXcRFX6dIOH85k3WjyA5b4yCJIbbvF_dwHV9ZBE4Rsi_xnUC-2biueqkMqcPgmkZRYePschj5ViTvhzjVYNMGVLc6cjxYHkOzxVT7lVTxaEw-ok1FXJja4xaJJSwutf_7kh9KbQfdBrwT69_MNcFJ9-mTWlVpM5hKo0O6wRok9X8o9L2LLd6i4Zcms69FMpcj2maFXCZJcT0nN4_lGwniBQc9SG7T2FUkFwZ7MAtlr0aISATjDjb2dSXrudAvjxOt_kbMX77pJ4Q_6y8gOl6WvHNMZ6BUVmPzzsRVHWuIjd13u6asjSctkFAP5a-u0gGIk8oeOsmE556o3AioPVz-n5d7xy6Xs7zAvO1KoqYSgiTsjHL51nr9rrygMiAfplttnjMRBkfuZHDhvNdvI5c7CUH2uwv5T5iK3n8Op6Bh5zCqIiq5r11PxavGwMBbZ6omeQfK9L175H7BfAgujhADahFo_zthuDOXcBOxX60KvysIGYBpRwlqJgWCEz5fJIDsLbJEgNCW6T7JnLzpxiWAnxlMANzSGm4QN6fPraqFflJT-Eabv6PwTiZSkSFN_fgdvaZ",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Glbt39n3sFHSaTzaGZ9SUaz_LmMUQSxJrK2GUUpg0PVq_PJkFT3OeCMer8kXIq2z_XJGDz1s0pF2L_QH5F4lmE1bcJ_kt71hyN27QNrRaQKR946CegVLHBKC7vZoGihcxjh7hRzJqwmm_LWKrCQ6xmtwyjenWAnj_fuSZZCVTXphOX-fxtuDy2dv3ij5XtTwFAscRfPzqKwNJW4PKwlkmr3Yj45q6QrYtnwNopsR1JsTcojeS8S06ZMWtqNToZWXH3Jt8oXH7g36iAKN5n9ND0VN0IcgetwipGKSNJxUfiPcHeXQUCUXsqjObY9T2vkLT5M4RM9R7_35HvYV6iGxvj_3Bti6GEl6NPT5D85_9dYxO_iQYOC-3oWnIy4kPy982gcPd2zQCFXcIFnqjFjzt1TbAXjUNxqGqdj2FAZblizzqP-H1tBhmneWgyd9xJrvmDtqeBY2u8Zm1_jNSvIclnD84p0oeFo4wsNsuxhmwSekv-Bat0Czz7LQyK9So58JrbiuD6y075s6zTwOmkBTDVm5ZEaa4OUvYQpCHyotopvvXvLsmEGiyM48ZT3dfiJP6AlmgmwVMNE_IOau9vK1TvRHDywUX47bMI92GyopuFzD9lFFLlz9BemaI1aZtD8IUZqW7B2w7GT0E1vJuKmXw7Od2Kic2S2zk4gVJncmAaDyOX9vMgNi0PBgQVVOtpMYpht1noRnGkgrBy0HGbLM-uYV8orZcC8NXi1vAo4UxzWs-u_1ow07y8WLLAEdhaXcRFX6dIOH85k3WjyA5b4yCJIbbvF_dwHV9ZBE4Rsi_xnUC-2biueqkMqcPgmkZRYePschj5ViTvhzjVYNMGVLc6cjxYHkOzxVT7lVTxaEw-ok1FXJja4xaJJSwutf_7kh9KbQfdBrwT69_MNcFJ9-mTWlVpM5hKo0O6wRok9X8o9L2LLd6i4Zcms69FMpcj2maFXCZJcT0nN4_lGwniBQc9SG7T2FUkFwZ7MAtlr0aISATjDjb2dSXrudAvjxOt_kbMX77pJ4Q_6y8gOl6WvHNMZ6BUVmPzzsRVHWuIjd13u6asjSctkFAP5a-u0gGIk8oeOsmE556o3AioPVz-n5d7xy6Xs7zAvO1KoqYSgiTsjHL51nr9rrygMiAfplttnjMRBkfuZHDhvNdvI5c7CUH2uwv5T5iK3n8Op6Bh5zCqIiq5r11PxavGwMBbZ6omeQfK9L175H7BfAgujhADahFo_zthuDOXcBOxX60KvysIGYBpRwlqJgWCEz5fJIDsLbJEgNCW6T7JnLzpxiWAnxlMANzSGm4QN6fPraqFflJT-Eabv6PwTiZSkSFN_fgdvaZ",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1079.04,
            "totalPrice": 806
          },
          {
            "allPassengerTotalPrice": 806,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjCC0D8O6pAKl_7rPJbyqYpKAVtMcKCWyYcGgetbzZomEaZHyJ208iK7sOo3q7BitmBqAcWYmO3XYG17IdtP6pZtWsq4u9iKKJqFO0fCHPeQQz0D1jlbLr_tw_-EPAGEPm-lOGkL4BcuXF-Ir4ociExSmp43qgoB9qyqiI7ePuEd8Xx1DcquUjoTuWnQbiJqfF9IfzG_Uz34MGa-TMqYTcChH4KIiQnuuKKBDQ99xHTHmKLNrcWlHGbfJ2DEGQ6pY1k7fAIHthBoBFAyqroqj99vGKxmBq4TjiGaguN2zeta0OZHz_wZScnLfUjnBQtIO0i9ObC7PPTa00uTzzgV8JGTF5HrcV51BxFcYrgFSDqvRupQq9G3Z3iqiexE7cXkmpWNDel2SAb5Bqp7DoqpUvc71iT9CGsi7ZDTpK30JpoAy0iNs-smR9XQzH3LG_j0-Bz9Ae8fmi5ZesTULxvtccFuRQL32vi65Q8hB_miZgJi_zMPuIM67YU9f8_6GE0e3TVoDRR8tpsUoCyX6x-nH8jfxFJsGw08GHeYRobT9begz9bfbylXF6YK7ISn56NPrx7eYfu_wAPnNp6tcBuJo3djiRGVaNPCuEvkH6HzhMf6Anbvk1t_I8kf9WbVMcvcbbSH4JjLVA7MiWDZSxnysBqNK-ELrh-Yx64WRiKMKAFoYaLTwnVeeKVKq6VN8NcW0ErqonAzywn8Fzl3w9jp-yEl7PYnlZAHb3PjEN1aZypyWmO6SabZ3bVSjSJdFgjjyEF1CtiGnbfsnTJHOHxN28yL6DMco2Nb6uZaIUPbU7kHwhKYecmS2Z0fNAO1KN9M_38CJHCbiQi46lASra_bGZv6HiGBvxzyCZHF0H7wkv7kt8eM1tmd2hE8KXFb4ORHrDmElAh2Cg2hv54CVlpesoCNWMCKY2h8PDXrtdL9z3A97ClWcR-6ELDoa3xMOZPQ4DOlU49YHsC50huuq501A-Q-Kgb94li643D1LOl2EevbxR_vrFGazlaqteZovf5gPcX8IngIjDQYeaRRg9ZUtFrV4QNpZ0rnAnF_rs7TSZ7iNg-V4ATUfO7i0JeHGXpOFxB1PxFkRTI0XnUAuS6S9FAG41kRjjagmWbgl8CuXdTVyo0dfjfgUhhMVRL_U-v_Rk0IqfLqd-SxpiNRdJVWw1o_TqrgZLIrQ1QSOlXYdngDQWF_eofNkkAuCzdZplKPtVeG7-KmXUYt4ZBaYQyiLxBF7Z1bdYkmMdCYjT4BVYD70hfNmvdKRViiMc7X45WQ_gU72wD9CrG7NZf93pQXpUIVzGO31r_YBqIv3moJD02M=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjCC0D8O6pAKl_7rPJbyqYpKAVtMcKCWyYcGgetbzZomEaZHyJ208iK7sOo3q7BitmBqAcWYmO3XYG17IdtP6pZtWsq4u9iKKJqFO0fCHPeQQz0D1jlbLr_tw_-EPAGEPm-lOGkL4BcuXF-Ir4ociExSmp43qgoB9qyqiI7ePuEd8Xx1DcquUjoTuWnQbiJqfF9IfzG_Uz34MGa-TMqYTcChH4KIiQnuuKKBDQ99xHTHmKLNrcWlHGbfJ2DEGQ6pY1k7fAIHthBoBFAyqroqj99vGKxmBq4TjiGaguN2zeta0OZHz_wZScnLfUjnBQtIO0i9ObC7PPTa00uTzzgV8JGTF5HrcV51BxFcYrgFSDqvRupQq9G3Z3iqiexE7cXkmpWNDel2SAb5Bqp7DoqpUvc71iT9CGsi7ZDTpK30JpoAy0iNs-smR9XQzH3LG_j0-Bz9Ae8fmi5ZesTULxvtccFuRQL32vi65Q8hB_miZgJi_zMPuIM67YU9f8_6GE0e3TVoDRR8tpsUoCyX6x-nH8jfxFJsGw08GHeYRobT9begz9bfbylXF6YK7ISn56NPrx7eYfu_wAPnNp6tcBuJo3djiRGVaNPCuEvkH6HzhMf6Anbvk1t_I8kf9WbVMcvcbbSH4JjLVA7MiWDZSxnysBqNK-ELrh-Yx64WRiKMKAFoYaLTwnVeeKVKq6VN8NcW0ErqonAzywn8Fzl3w9jp-yEl7PYnlZAHb3PjEN1aZypyWmO6SabZ3bVSjSJdFgjjyEF1CtiGnbfsnTJHOHxN28yL6DMco2Nb6uZaIUPbU7kHwhKYecmS2Z0fNAO1KN9M_38CJHCbiQi46lASra_bGZv6HiGBvxzyCZHF0H7wkv7kt8eM1tmd2hE8KXFb4ORHrDmElAh2Cg2hv54CVlpesoCNWMCKY2h8PDXrtdL9z3A97ClWcR-6ELDoa3xMOZPQ4DOlU49YHsC50huuq501A-Q-Kgb94li643D1LOl2EevbxR_vrFGazlaqteZovf5gPcX8IngIjDQYeaRRg9ZUtFrV4QNpZ0rnAnF_rs7TSZ7iNg-V4ATUfO7i0JeHGXpOFxB1PxFkRTI0XnUAuS6S9FAG41kRjjagmWbgl8CuXdTVyo0dfjfgUhhMVRL_U-v_Rk0IqfLqd-SxpiNRdJVWw1o_TqrgZLIrQ1QSOlXYdngDQWF_eofNkkAuCzdZplKPtVeG7-KmXUYt4ZBaYQyiLxBF7Z1bdYkmMdCYjT4BVYD70hfNmvdKRViiMc7X45WQ_gU72wD9CrG7NZf93pQXpUIVzGO31r_YBqIv3moJD02M=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GjCC0D8O6pAKl_7rPJbyqYpKAVtMcKCWyYcGgetbzZomEaZHyJ208iK7sOo3q7BitmBqAcWYmO3XYG17IdtP6pZtWsq4u9iKKJqFO0fCHPeQQz0D1jlbLr_tw_-EPAGEPm-lOGkL4BcuXF-Ir4ociExSmp43qgoB9qyqiI7ePuEd8Xx1DcquUjoTuWnQbiJqfF9IfzG_Uz34MGa-TMqYTcChH4KIiQnuuKKBDQ99xHTHmKLNrcWlHGbfJ2DEGQ6pY1k7fAIHthBoBFAyqroqj99vGKxmBq4TjiGaguN2zeta0OZHz_wZScnLfUjnBQtIO0i9ObC7PPTa00uTzzgV8JGTF5HrcV51BxFcYrgFSDqvRupQq9G3Z3iqiexE7cXkmpWNDel2SAb5Bqp7DoqpUvc71iT9CGsi7ZDTpK30JpoAy0iNs-smR9XQzH3LG_j0-Bz9Ae8fmi5ZesTULxvtccFuRQL32vi65Q8hB_miZgJi_zMPuIM67YU9f8_6GE0e3TVoDRR8tpsUoCyX6x-nH8jfxFJsGw08GHeYRobT9begz9bfbylXF6YK7ISn56NPrx7eYfu_wAPnNp6tcBuJo3djiRGVaNPCuEvkH6HzhMf6Anbvk1t_I8kf9WbVMcvcbbSH4JjLVA7MiWDZSxnysBqNK-ELrh-Yx64WRiKMKAFoYaLTwnVeeKVKq6VN8NcW0ErqonAzywn8Fzl3w9jp-yEl7PYnlZAHb3PjEN1aZypyWmO6SabZ3bVSjSJdFgjjyEF1CtiGnbfsnTJHOHxN28yL6DMco2Nb6uZaIUPbU7kHwhKYecmS2Z0fNAO1KN9M_38CJHCbiQi46lASra_bGZv6HiGBvxzyCZHF0H7wkv7kt8eM1tmd2hE8KXFb4ORHrDmElAh2Cg2hv54CVlpesoCNWMCKY2h8PDXrtdL9z3A97ClWcR-6ELDoa3xMOZPQ4DOlU49YHsC50huuq501A-Q-Kgb94li643D1LOl2EevbxR_vrFGazlaqteZovf5gPcX8IngIjDQYeaRRg9ZUtFrV4QNpZ0rnAnF_rs7TSZ7iNg-V4ATUfO7i0JeHGXpOFxB1PxFkRTI0XnUAuS6S9FAG41kRjjagmWbgl8CuXdTVyo0dfjfgUhhMVRL_U-v_Rk0IqfLqd-SxpiNRdJVWw1o_TqrgZLIrQ1QSOlXYdngDQWF_eofNkkAuCzdZplKPtVeG7-KmXUYt4ZBaYQyiLxBF7Z1bdYkmMdCYjT4BVYD70hfNmvdKRViiMc7X45WQ_gU72wD9CrG7NZf93pQXpUIVzGO31r_YBqIv3moJD02M=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1105.71,
            "totalPrice": 806
          },
          {
            "allPassengerTotalPrice": 806,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhOmTvsT4Y3D3zHXLVsA-IRzm20nG7YlDfOZkOp-97C1QWfwkOyHQ3vdollCccuMmThGt-zzvpHuNnlu58iF3365lC-W47gK-KJRLPNY31wwA8V_isFXxQoBHuSXyyR3_1E3Teatv5Pb4PRi_XksQbkQ61blle0KDaMNxJncQRG7EcIM8YHrhLlM37lthlRbKkNbb-ruHlh4eEu3TjubbogBqhz6lM-4i3MN8_kwWqo0q6XjvJZrn1PsL3jQ3FrRomQ3V8aQLijuPvh4JB-cyKYuGRm2KHlEeHbRxfoOhI6KJXH6869Aqxf_U8h92WpnXEAWjESQWLP8eg-mI_ZTq6Ln2E-JCCp3padnbToTyGQ1gJxlG3YhxN_k3_sC27TIH2jMAUe3Cdzwloo11u3-JLGVpA3CnzzpEzS_CkykwI3GMwL1CJ4-HZ3PQe5h1WN8p5zMEhFCqbcD3A-bXYCZD1F4iCa8qucJz2Dc0ZwP0TPWF-bcHxQi-Ny6NIZIFFTQdCN0VWuLAXZPdQ0v-2mjNDFiHmOqJlAwEf_zOTkuS-Xp4tBYAtqNSyWZPLnd1kFcj5tRSrCFV-r9Ed37yffVDkwvInfBeacjEXwS2U4N7zbLwtpFO2GMqUL-5ND4XB9mtPa1bhhdvZ7_PGMcI_N1JFrXsQLt5Y3ePmSwPUvL87sR48H2_y3i9l8GQkaOReLSeqUDosVs0ExwBm9GTUF79f7FZ6ZMdjbUgdQpq2elSMMFU07l55eb-eRbGmx_GfWBUwhEm4v_Wx4-pxsiJnul1PQbutAZY4nkicX0XtqFtfoOzr_ZZrevIJaGcx_W41xac9S53viRroF5zt9QC3CCNWcrOcnCnEnXIlrWn4_otYAaAbfJujvl7-i_H9dEiu-d-CwpjRLTSl5FTp597v26ym3DQrTD9to6wMt9q-eizXkant4tc2nEjlaGgNwHynKHlZyLBkYL-eO59oRSIj7ae9rsHRF_wPFP10fuMVRPxFQ7mY1X5qrSxa6xS7BKwC_KLWqbBtMBPqTOnXJ4Y_4pXourMXy1zxNJQ0ecdWL4qpC-p1WyAQ6BgU8RkX20aHI387Uchd2U2msLzlSgpSxNhB1M09ajOMJx2RMVs-twyJDKRaJsR6cW8_ui2ZI3lsNk5QEd-pp5kQ-w3s6rv2jkLwTrPXaKFPkFcWhe2RXu8anOos9B_VYJuwrB1rwWTzeWJERL3UHNuscrdW9y3I7ed6kSXbGTIbYgiv8qQa_0WAmEm2mHQucsNWJqHOyeUP31Z",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhOmTvsT4Y3D3zHXLVsA-IRzm20nG7YlDfOZkOp-97C1QWfwkOyHQ3vdollCccuMmThGt-zzvpHuNnlu58iF3365lC-W47gK-KJRLPNY31wwA8V_isFXxQoBHuSXyyR3_1E3Teatv5Pb4PRi_XksQbkQ61blle0KDaMNxJncQRG7EcIM8YHrhLlM37lthlRbKkNbb-ruHlh4eEu3TjubbogBqhz6lM-4i3MN8_kwWqo0q6XjvJZrn1PsL3jQ3FrRomQ3V8aQLijuPvh4JB-cyKYuGRm2KHlEeHbRxfoOhI6KJXH6869Aqxf_U8h92WpnXEAWjESQWLP8eg-mI_ZTq6Ln2E-JCCp3padnbToTyGQ1gJxlG3YhxN_k3_sC27TIH2jMAUe3Cdzwloo11u3-JLGVpA3CnzzpEzS_CkykwI3GMwL1CJ4-HZ3PQe5h1WN8p5zMEhFCqbcD3A-bXYCZD1F4iCa8qucJz2Dc0ZwP0TPWF-bcHxQi-Ny6NIZIFFTQdCN0VWuLAXZPdQ0v-2mjNDFiHmOqJlAwEf_zOTkuS-Xp4tBYAtqNSyWZPLnd1kFcj5tRSrCFV-r9Ed37yffVDkwvInfBeacjEXwS2U4N7zbLwtpFO2GMqUL-5ND4XB9mtPa1bhhdvZ7_PGMcI_N1JFrXsQLt5Y3ePmSwPUvL87sR48H2_y3i9l8GQkaOReLSeqUDosVs0ExwBm9GTUF79f7FZ6ZMdjbUgdQpq2elSMMFU07l55eb-eRbGmx_GfWBUwhEm4v_Wx4-pxsiJnul1PQbutAZY4nkicX0XtqFtfoOzr_ZZrevIJaGcx_W41xac9S53viRroF5zt9QC3CCNWcrOcnCnEnXIlrWn4_otYAaAbfJujvl7-i_H9dEiu-d-CwpjRLTSl5FTp597v26ym3DQrTD9to6wMt9q-eizXkant4tc2nEjlaGgNwHynKHlZyLBkYL-eO59oRSIj7ae9rsHRF_wPFP10fuMVRPxFQ7mY1X5qrSxa6xS7BKwC_KLWqbBtMBPqTOnXJ4Y_4pXourMXy1zxNJQ0ecdWL4qpC-p1WyAQ6BgU8RkX20aHI387Uchd2U2msLzlSgpSxNhB1M09ajOMJx2RMVs-twyJDKRaJsR6cW8_ui2ZI3lsNk5QEd-pp5kQ-w3s6rv2jkLwTrPXaKFPkFcWhe2RXu8anOos9B_VYJuwrB1rwWTzeWJERL3UHNuscrdW9y3I7ed6kSXbGTIbYgiv8qQa_0WAmEm2mHQucsNWJqHOyeUP31Z",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "U2",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b80f354e2a0000048c9b92_0%7C0f3522f54e2b00003abc8da6_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhOmTvsT4Y3D3zHXLVsA-IRzm20nG7YlDfOZkOp-97C1QWfwkOyHQ3vdollCccuMmThGt-zzvpHuNnlu58iF3365lC-W47gK-KJRLPNY31wwA8V_isFXxQoBHuSXyyR3_1E3Teatv5Pb4PRi_XksQbkQ61blle0KDaMNxJncQRG7EcIM8YHrhLlM37lthlRbKkNbb-ruHlh4eEu3TjubbogBqhz6lM-4i3MN8_kwWqo0q6XjvJZrn1PsL3jQ3FrRomQ3V8aQLijuPvh4JB-cyKYuGRm2KHlEeHbRxfoOhI6KJXH6869Aqxf_U8h92WpnXEAWjESQWLP8eg-mI_ZTq6Ln2E-JCCp3padnbToTyGQ1gJxlG3YhxN_k3_sC27TIH2jMAUe3Cdzwloo11u3-JLGVpA3CnzzpEzS_CkykwI3GMwL1CJ4-HZ3PQe5h1WN8p5zMEhFCqbcD3A-bXYCZD1F4iCa8qucJz2Dc0ZwP0TPWF-bcHxQi-Ny6NIZIFFTQdCN0VWuLAXZPdQ0v-2mjNDFiHmOqJlAwEf_zOTkuS-Xp4tBYAtqNSyWZPLnd1kFcj5tRSrCFV-r9Ed37yffVDkwvInfBeacjEXwS2U4N7zbLwtpFO2GMqUL-5ND4XB9mtPa1bhhdvZ7_PGMcI_N1JFrXsQLt5Y3ePmSwPUvL87sR48H2_y3i9l8GQkaOReLSeqUDosVs0ExwBm9GTUF79f7FZ6ZMdjbUgdQpq2elSMMFU07l55eb-eRbGmx_GfWBUwhEm4v_Wx4-pxsiJnul1PQbutAZY4nkicX0XtqFtfoOzr_ZZrevIJaGcx_W41xac9S53viRroF5zt9QC3CCNWcrOcnCnEnXIlrWn4_otYAaAbfJujvl7-i_H9dEiu-d-CwpjRLTSl5FTp597v26ym3DQrTD9to6wMt9q-eizXkant4tc2nEjlaGgNwHynKHlZyLBkYL-eO59oRSIj7ae9rsHRF_wPFP10fuMVRPxFQ7mY1X5qrSxa6xS7BKwC_KLWqbBtMBPqTOnXJ4Y_4pXourMXy1zxNJQ0ecdWL4qpC-p1WyAQ6BgU8RkX20aHI387Uchd2U2msLzlSgpSxNhB1M09ajOMJx2RMVs-twyJDKRaJsR6cW8_ui2ZI3lsNk5QEd-pp5kQ-w3s6rv2jkLwTrPXaKFPkFcWhe2RXu8anOos9B_VYJuwrB1rwWTzeWJERL3UHNuscrdW9y3I7ed6kSXbGTIbYgiv8qQa_0WAmEm2mHQucsNWJqHOyeUP31Z",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "13:05",
                "destination": "SFO",
                "duration": "41h33m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "IBZ",
                    "cityFrom": "Seville",
                    "cityTo": "Ibiza",
                    "combination_id": "04b80f354e2a0000048c9b92",
                    "equipment": null,
                    "fare_basis": "DOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 2424,
                    "flyFrom": "SVQ",
                    "flyTo": "IBZ",
                    "guarantee": false,
                    "id": "04b80f354e2a0000048c9b92_0",
                    "local_arrival": "2024-10-14T14:25:00.000Z",
                    "local_departure": "2024-10-14T13:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2424",
                    "return": 0,
                    "utc_arrival": "2024-10-14T12:25:00.000Z",
                    "utc_departure": "2024-10-14T11:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "IBZ",
                    "cityCodeTo": "LON",
                    "cityFrom": "Ibiza",
                    "cityTo": "London",
                    "combination_id": "0f3522f54e2b00003abc8da6",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 8106,
                    "flyFrom": "IBZ",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "0f3522f54e2b00003abc8da6_0",
                    "local_arrival": "2024-10-15T02:05:00.000Z",
                    "local_departure": "2024-10-15T00:40:00.000Z",
                    "operating_carrier": "U2",
                    "operating_flight_no": "8106",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:05:00.000Z",
                    "utc_departure": "2024-10-14T22:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1108.76,
            "totalPrice": 806
          },
          {
            "allPassengerTotalPrice": 807,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVSrM6wJgO3MLaD-C3zWqkUwsGRwzBqyCeZefOh-YT49y75m9QhXqGQojjqekt8V52X8G-gRWUH7ugS5tAM2n6UMcLUi3FQNHymAePzBXK25uWDZyWd4hw-ypLD-8PAnDcbW6L0IniYpVz6k92ZHJSKeqK_3C1jwGdiFaY6sGbgnECmfW0T2ESIJ6a0Y_L9KYxqVkfdE6Fiy-6R9gRb0uKG9qYz6bVEMW0HphzSwilhpvju6qyoauvGd0Xa3arQCk_2DeF4-1u1NH-u5v2QAzjKD4uR3dYiI6twmpjBj4yNSEZmp2vCrbvvA-BNbQtZ9CGNlMAdHdbzAgNV6lTPMTXITI4PotSABm3_E5y0360mmqPEx3qjKRo8Vtt9_eEfpL4cG3pDrDCSC5NJYGY1QeZ8q2QeFHoewktX8UWgcliHGmh0xWLqdn7gOjQrBdFijZscR-hrk2lP04vlL5UxYwB8Cd3ddT06MnMZzrTU2Y_mz6fwbdEMdlS0c92k0P03l1xJGREoZrFbmpkbr1lWm7I_jVbnbj2awybvdlUTgCAQrgyk-tqrYYDIWYfYRQJWt97rIgT__6KnZ4avu4iKTyFuPD6mHOOIPd6Dztzlr5MdUzFNeYnwm02tp5SgiW4RGn8RCrxNfc1OP-QYVMLRmNpuPAJ6ecz83BjTH_rH2H7GQ8xgDNTtYut48AkUJNSCOf3XW-jbznj_qloYwVJnhCqP47UA8MyU-tC_8ddplLbWYyPzwNG4_AqhjEWUezZ1OiEoOmCIujz1HihG6ghViFHawvw8mn8Ssv030fP1yp1tzTNpG74XmFyWmxue5FQBt1H4kvI4pzBsKyaHJaZqcrZpXr7tuZl7xZZxx7UhbTlSxDABQJcu9p04VN_NgnCgZQNmtFa_qADUl5axKVzn5ijVUxkrgSYupBFf9-G3_-qxBDMZz7weD9jVpcnXRsk8oYZ46ad5ivIlwZPQwz4gidb7dOPXN20c8-RZVQhrGcCdSX-7FEV8jw0jywkov59zZ2vcQ14qKvwrt8Au8cHsN7QuYxsr5R0UB5ScaWLFrNoTp5Y_ulweajeO2f07pGM2mFffDwsj2-lHvChiNrDZIDw1k73qAhgRZJ9rC1Fi3sHUewpkyz6gqmQJyOEnhzmOxNILy2aWLwGLuVWiGIW5Jp2GXLXsHveliC21HxS4wjxLQ=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVSrM6wJgO3MLaD-C3zWqkUwsGRwzBqyCeZefOh-YT49y75m9QhXqGQojjqekt8V52X8G-gRWUH7ugS5tAM2n6UMcLUi3FQNHymAePzBXK25uWDZyWd4hw-ypLD-8PAnDcbW6L0IniYpVz6k92ZHJSKeqK_3C1jwGdiFaY6sGbgnECmfW0T2ESIJ6a0Y_L9KYxqVkfdE6Fiy-6R9gRb0uKG9qYz6bVEMW0HphzSwilhpvju6qyoauvGd0Xa3arQCk_2DeF4-1u1NH-u5v2QAzjKD4uR3dYiI6twmpjBj4yNSEZmp2vCrbvvA-BNbQtZ9CGNlMAdHdbzAgNV6lTPMTXITI4PotSABm3_E5y0360mmqPEx3qjKRo8Vtt9_eEfpL4cG3pDrDCSC5NJYGY1QeZ8q2QeFHoewktX8UWgcliHGmh0xWLqdn7gOjQrBdFijZscR-hrk2lP04vlL5UxYwB8Cd3ddT06MnMZzrTU2Y_mz6fwbdEMdlS0c92k0P03l1xJGREoZrFbmpkbr1lWm7I_jVbnbj2awybvdlUTgCAQrgyk-tqrYYDIWYfYRQJWt97rIgT__6KnZ4avu4iKTyFuPD6mHOOIPd6Dztzlr5MdUzFNeYnwm02tp5SgiW4RGn8RCrxNfc1OP-QYVMLRmNpuPAJ6ecz83BjTH_rH2H7GQ8xgDNTtYut48AkUJNSCOf3XW-jbznj_qloYwVJnhCqP47UA8MyU-tC_8ddplLbWYyPzwNG4_AqhjEWUezZ1OiEoOmCIujz1HihG6ghViFHawvw8mn8Ssv030fP1yp1tzTNpG74XmFyWmxue5FQBt1H4kvI4pzBsKyaHJaZqcrZpXr7tuZl7xZZxx7UhbTlSxDABQJcu9p04VN_NgnCgZQNmtFa_qADUl5axKVzn5ijVUxkrgSYupBFf9-G3_-qxBDMZz7weD9jVpcnXRsk8oYZ46ad5ivIlwZPQwz4gidb7dOPXN20c8-RZVQhrGcCdSX-7FEV8jw0jywkov59zZ2vcQ14qKvwrt8Au8cHsN7QuYxsr5R0UB5ScaWLFrNoTp5Y_ulweajeO2f07pGM2mFffDwsj2-lHvChiNrDZIDw1k73qAhgRZJ9rC1Fi3sHUewpkyz6gqmQJyOEnhzmOxNILy2aWLwGLuVWiGIW5Jp2GXLXsHveliC21HxS4wjxLQ=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVSrM6wJgO3MLaD-C3zWqkUwsGRwzBqyCeZefOh-YT49y75m9QhXqGQojjqekt8V52X8G-gRWUH7ugS5tAM2n6UMcLUi3FQNHymAePzBXK25uWDZyWd4hw-ypLD-8PAnDcbW6L0IniYpVz6k92ZHJSKeqK_3C1jwGdiFaY6sGbgnECmfW0T2ESIJ6a0Y_L9KYxqVkfdE6Fiy-6R9gRb0uKG9qYz6bVEMW0HphzSwilhpvju6qyoauvGd0Xa3arQCk_2DeF4-1u1NH-u5v2QAzjKD4uR3dYiI6twmpjBj4yNSEZmp2vCrbvvA-BNbQtZ9CGNlMAdHdbzAgNV6lTPMTXITI4PotSABm3_E5y0360mmqPEx3qjKRo8Vtt9_eEfpL4cG3pDrDCSC5NJYGY1QeZ8q2QeFHoewktX8UWgcliHGmh0xWLqdn7gOjQrBdFijZscR-hrk2lP04vlL5UxYwB8Cd3ddT06MnMZzrTU2Y_mz6fwbdEMdlS0c92k0P03l1xJGREoZrFbmpkbr1lWm7I_jVbnbj2awybvdlUTgCAQrgyk-tqrYYDIWYfYRQJWt97rIgT__6KnZ4avu4iKTyFuPD6mHOOIPd6Dztzlr5MdUzFNeYnwm02tp5SgiW4RGn8RCrxNfc1OP-QYVMLRmNpuPAJ6ecz83BjTH_rH2H7GQ8xgDNTtYut48AkUJNSCOf3XW-jbznj_qloYwVJnhCqP47UA8MyU-tC_8ddplLbWYyPzwNG4_AqhjEWUezZ1OiEoOmCIujz1HihG6ghViFHawvw8mn8Ssv030fP1yp1tzTNpG74XmFyWmxue5FQBt1H4kvI4pzBsKyaHJaZqcrZpXr7tuZl7xZZxx7UhbTlSxDABQJcu9p04VN_NgnCgZQNmtFa_qADUl5axKVzn5ijVUxkrgSYupBFf9-G3_-qxBDMZz7weD9jVpcnXRsk8oYZ46ad5ivIlwZPQwz4gidb7dOPXN20c8-RZVQhrGcCdSX-7FEV8jw0jywkov59zZ2vcQ14qKvwrt8Au8cHsN7QuYxsr5R0UB5ScaWLFrNoTp5Y_ulweajeO2f07pGM2mFffDwsj2-lHvChiNrDZIDw1k73qAhgRZJ9rC1Fi3sHUewpkyz6gqmQJyOEnhzmOxNILy2aWLwGLuVWiGIW5Jp2GXLXsHveliC21HxS4wjxLQ=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1052.27,
            "totalPrice": 807
          },
          {
            "allPassengerTotalPrice": 811,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8vEKJMUKIPAN2-LlsHgA3z5ptLmax83Wo0zt5pAg8vS8hiB-Afh0WvlQ66zizkodOYXhWER184caa4W6HlVXqa9b1gLLuKttMUVsaacTZ1imc1z58epdv5-kPvHJvOWt5D4joCiKS1VhNXNocnQcZ3JDB0wxBJ9inZTTW5Gwg64g30QdmWSstOpcNWgjitRAwgj16WWHBR7RuDSdUGsXe4BjlIsRIsRwvRRqnGklFq5UTeG7A_p7NazTGxDoZ44C7q54LQw7lHxHb1ZMCipaPsSLAXO7evLwqp9mNVyWzEnu2z8gcz5cejqgQofFwoNZ0m88gr-ohBoFjYF8Q-_OxkVmh5rzkcqk5CTEhzYDX1lPE_UYdXVN6034jgpBHReJGDuht_fkcdgmRmMVNqT0_PYU_DpPbqiqCUFLBqHPRIaMNi4_AB2kA0grXkrz992JaqrxLjOL5s_4aq562F5Dc5Jxg227Xnwd0rH9JLfx044nYvzZ2VMUnh2zpsTXLOXm2uuTAbMiaMsTx_1hnCpKIU1g3bfU4SVUYTqUe_VRQlBS5OyaR_J5le5Olkpkc8ciM9Lwze6lMYn9IiqzHAqNat0TdjcFRAz3X3FOOjOcgBgTJdvgEzBjBbQJNJll4u851PTetfyqwxxOccmrB2mPOeBKeT6QTdOm44PkyeuBfmGGvShv7XPOTPiX72ZuxMJV71UL9sr858a3nn28kkx7lgrt0e8AxYXgLn_rczNMec4Lrv-18LaEDNaLrYo91i1VANBzSrIdqMqDS7BR_8VsRH60hFkelHHkFpc69gRtEJMfQoKtByUvSpVR1n8kHytxE_Fm04pjQf3zPWwEePMsj4k7Fm3lypmBrLvUX_zfM1a0yv_25vEgaZbTJ3pCb7imgHTYjJoGYR2DeBqrpKqK0NLw-8Viy1x04ymu-Um44N-w1t7Av6tq4kmgF7ahGiUpyNWNGHBV5BIaQUcap4AmQPS5KfWR6tPtQTcbfW0JFjM9kWrJ7hIu84KQYV7-4-TaqmZEZQOWJzKBdfsU1drISuC9tlRiQR4hKEgziqfnx1cP7XHdCS4uGLNwEUUHRA6eR2y5kThZUWFC_UwC1nJADBqcje2b_K6citdEsx6ITMOj0ce8xOHgF8SQfC83sTcsgXLOFvavfsUGZc8H-ykTnJfb5PSALyPfni1He0Io-LgQWBUYrvkWqY5XE0I7F0DVdIhEKaJjFBC0AZFWyttjlRbNUZ1vPkvHGhikOddNLaI=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8vEKJMUKIPAN2-LlsHgA3z5ptLmax83Wo0zt5pAg8vS8hiB-Afh0WvlQ66zizkodOYXhWER184caa4W6HlVXqa9b1gLLuKttMUVsaacTZ1imc1z58epdv5-kPvHJvOWt5D4joCiKS1VhNXNocnQcZ3JDB0wxBJ9inZTTW5Gwg64g30QdmWSstOpcNWgjitRAwgj16WWHBR7RuDSdUGsXe4BjlIsRIsRwvRRqnGklFq5UTeG7A_p7NazTGxDoZ44C7q54LQw7lHxHb1ZMCipaPsSLAXO7evLwqp9mNVyWzEnu2z8gcz5cejqgQofFwoNZ0m88gr-ohBoFjYF8Q-_OxkVmh5rzkcqk5CTEhzYDX1lPE_UYdXVN6034jgpBHReJGDuht_fkcdgmRmMVNqT0_PYU_DpPbqiqCUFLBqHPRIaMNi4_AB2kA0grXkrz992JaqrxLjOL5s_4aq562F5Dc5Jxg227Xnwd0rH9JLfx044nYvzZ2VMUnh2zpsTXLOXm2uuTAbMiaMsTx_1hnCpKIU1g3bfU4SVUYTqUe_VRQlBS5OyaR_J5le5Olkpkc8ciM9Lwze6lMYn9IiqzHAqNat0TdjcFRAz3X3FOOjOcgBgTJdvgEzBjBbQJNJll4u851PTetfyqwxxOccmrB2mPOeBKeT6QTdOm44PkyeuBfmGGvShv7XPOTPiX72ZuxMJV71UL9sr858a3nn28kkx7lgrt0e8AxYXgLn_rczNMec4Lrv-18LaEDNaLrYo91i1VANBzSrIdqMqDS7BR_8VsRH60hFkelHHkFpc69gRtEJMfQoKtByUvSpVR1n8kHytxE_Fm04pjQf3zPWwEePMsj4k7Fm3lypmBrLvUX_zfM1a0yv_25vEgaZbTJ3pCb7imgHTYjJoGYR2DeBqrpKqK0NLw-8Viy1x04ymu-Um44N-w1t7Av6tq4kmgF7ahGiUpyNWNGHBV5BIaQUcap4AmQPS5KfWR6tPtQTcbfW0JFjM9kWrJ7hIu84KQYV7-4-TaqmZEZQOWJzKBdfsU1drISuC9tlRiQR4hKEgziqfnx1cP7XHdCS4uGLNwEUUHRA6eR2y5kThZUWFC_UwC1nJADBqcje2b_K6citdEsx6ITMOj0ce8xOHgF8SQfC83sTcsgXLOFvavfsUGZc8H-ykTnJfb5PSALyPfni1He0Io-LgQWBUYrvkWqY5XE0I7F0DVdIhEKaJjFBC0AZFWyttjlRbNUZ1vPkvHGhikOddNLaI=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8vEKJMUKIPAN2-LlsHgA3z5ptLmax83Wo0zt5pAg8vS8hiB-Afh0WvlQ66zizkodOYXhWER184caa4W6HlVXqa9b1gLLuKttMUVsaacTZ1imc1z58epdv5-kPvHJvOWt5D4joCiKS1VhNXNocnQcZ3JDB0wxBJ9inZTTW5Gwg64g30QdmWSstOpcNWgjitRAwgj16WWHBR7RuDSdUGsXe4BjlIsRIsRwvRRqnGklFq5UTeG7A_p7NazTGxDoZ44C7q54LQw7lHxHb1ZMCipaPsSLAXO7evLwqp9mNVyWzEnu2z8gcz5cejqgQofFwoNZ0m88gr-ohBoFjYF8Q-_OxkVmh5rzkcqk5CTEhzYDX1lPE_UYdXVN6034jgpBHReJGDuht_fkcdgmRmMVNqT0_PYU_DpPbqiqCUFLBqHPRIaMNi4_AB2kA0grXkrz992JaqrxLjOL5s_4aq562F5Dc5Jxg227Xnwd0rH9JLfx044nYvzZ2VMUnh2zpsTXLOXm2uuTAbMiaMsTx_1hnCpKIU1g3bfU4SVUYTqUe_VRQlBS5OyaR_J5le5Olkpkc8ciM9Lwze6lMYn9IiqzHAqNat0TdjcFRAz3X3FOOjOcgBgTJdvgEzBjBbQJNJll4u851PTetfyqwxxOccmrB2mPOeBKeT6QTdOm44PkyeuBfmGGvShv7XPOTPiX72ZuxMJV71UL9sr858a3nn28kkx7lgrt0e8AxYXgLn_rczNMec4Lrv-18LaEDNaLrYo91i1VANBzSrIdqMqDS7BR_8VsRH60hFkelHHkFpc69gRtEJMfQoKtByUvSpVR1n8kHytxE_Fm04pjQf3zPWwEePMsj4k7Fm3lypmBrLvUX_zfM1a0yv_25vEgaZbTJ3pCb7imgHTYjJoGYR2DeBqrpKqK0NLw-8Viy1x04ymu-Um44N-w1t7Av6tq4kmgF7ahGiUpyNWNGHBV5BIaQUcap4AmQPS5KfWR6tPtQTcbfW0JFjM9kWrJ7hIu84KQYV7-4-TaqmZEZQOWJzKBdfsU1drISuC9tlRiQR4hKEgziqfnx1cP7XHdCS4uGLNwEUUHRA6eR2y5kThZUWFC_UwC1nJADBqcje2b_K6citdEsx6ITMOj0ce8xOHgF8SQfC83sTcsgXLOFvavfsUGZc8H-ykTnJfb5PSALyPfni1He0Io-LgQWBUYrvkWqY5XE0I7F0DVdIhEKaJjFBC0AZFWyttjlRbNUZ1vPkvHGhikOddNLaI=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1034.18,
            "totalPrice": 811
          },
          {
            "allPassengerTotalPrice": 811,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GI8Lu_I980IM0-XeR6MHY5ppcrGHuDYaqEbLTjFLdAEM_rGIoOrAoqMk2NxXyzY3d21rT4_mIBWeAtzyIdvsFDuHfg53I0kF_dXCFmdNt55HmEsLvvfsMmJ_m-l9nas3bv4lDnC_O4sYVCLly3-wflhRCzQntUstYSV2_InSAizHWFfW_KUuRZvLnX_vOQb3dwBfsIb-P6KbGgQadk2yILzTHf9c8tkNRh-oXYLUHxhZL0TWwN6DOuboJQIkjQAN_cx0kLOE_qsUDVcGNPoqbPnjFPcIuGoaTVmkgujLQWB803FvfB6Tt1951ayIdPUSmOk3q6KNlPjtJW862fEgmnHJltzAlsFdEfx-TaxbFKKA3eqL0g04hUHY1_nDtsYvUSJ24l5CbFTpFtuZJaqaWZhNxCJ5tk8YKdTK-JgsHRZxHYPeWCm9xSJoZTVRHgriqhI1vMJC4btInLZsfLKzuDr4x7Haow0k-DPoR-UHKy9q3WQollybTG2RKZ7VxXCwLQfvdkCdL7r_lxyK3qMHWJZIHQSfGPbZvhNAZbPSt6Fgx3OFb9EsTfNz67NOPJAl0_rkxf4IPklW5qAgYNAXqE7JkQE57kYFaMneL3ygcxoFI9r6Zyjq9zV_5kOR0IAXhlqmKPFBTPGspL9SjReLGZqgZYOiX_xV1j-jQtaAnjmghzMRr4smwW8Oy1CORRznCgA5sgeBVJuJvkiKnXYQy9x0MfIHDmzDxrDeyIczYopOw3Nvon3E5eXNYKU9RlU9puH4aDLazEfr3MiCPP9KTeTrOsgmjNLrTwcpKx5fs0z29DjLBoJluRvvA8_p8TuRs2x8W4yd726XMUNmwl2xreGyyWHJqtORJEeUCxk6cVQFyYqsUBfFbygA8AK2iyhGVfJsOCWM-3GBF0P0K0PZ2N_V0RVt7LcZYa9bCaQD9W5Os4UzLl0qf9vfxpb-GoUMjPNgNVWjjTbnC7kK1HM5Niy16dKlZCMZsGKzr2C8K-GYjWk852R2rzl9MhqPlUxKLdfgg6ikVMZY9WXYNN6N0euh7dpWicWFczH04XEhgQLILpEGjQosw2r0LfY7tMQEGYCFl62Wo3EBGIUAH24c56mxw-S5Yt_qoV1LNlge5cOoYgAH3cVNE-poftvcVnmqACTcl5MzJwviL_UIRzhMPwCiroALaHgiWu8hHhdY4IeTGkBnQf8_2yhpJmC7ASF6yK2LhtUy-aSLE2biY99_IkoVI1c38NATrrppxfhfvwrE=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GI8Lu_I980IM0-XeR6MHY5ppcrGHuDYaqEbLTjFLdAEM_rGIoOrAoqMk2NxXyzY3d21rT4_mIBWeAtzyIdvsFDuHfg53I0kF_dXCFmdNt55HmEsLvvfsMmJ_m-l9nas3bv4lDnC_O4sYVCLly3-wflhRCzQntUstYSV2_InSAizHWFfW_KUuRZvLnX_vOQb3dwBfsIb-P6KbGgQadk2yILzTHf9c8tkNRh-oXYLUHxhZL0TWwN6DOuboJQIkjQAN_cx0kLOE_qsUDVcGNPoqbPnjFPcIuGoaTVmkgujLQWB803FvfB6Tt1951ayIdPUSmOk3q6KNlPjtJW862fEgmnHJltzAlsFdEfx-TaxbFKKA3eqL0g04hUHY1_nDtsYvUSJ24l5CbFTpFtuZJaqaWZhNxCJ5tk8YKdTK-JgsHRZxHYPeWCm9xSJoZTVRHgriqhI1vMJC4btInLZsfLKzuDr4x7Haow0k-DPoR-UHKy9q3WQollybTG2RKZ7VxXCwLQfvdkCdL7r_lxyK3qMHWJZIHQSfGPbZvhNAZbPSt6Fgx3OFb9EsTfNz67NOPJAl0_rkxf4IPklW5qAgYNAXqE7JkQE57kYFaMneL3ygcxoFI9r6Zyjq9zV_5kOR0IAXhlqmKPFBTPGspL9SjReLGZqgZYOiX_xV1j-jQtaAnjmghzMRr4smwW8Oy1CORRznCgA5sgeBVJuJvkiKnXYQy9x0MfIHDmzDxrDeyIczYopOw3Nvon3E5eXNYKU9RlU9puH4aDLazEfr3MiCPP9KTeTrOsgmjNLrTwcpKx5fs0z29DjLBoJluRvvA8_p8TuRs2x8W4yd726XMUNmwl2xreGyyWHJqtORJEeUCxk6cVQFyYqsUBfFbygA8AK2iyhGVfJsOCWM-3GBF0P0K0PZ2N_V0RVt7LcZYa9bCaQD9W5Os4UzLl0qf9vfxpb-GoUMjPNgNVWjjTbnC7kK1HM5Niy16dKlZCMZsGKzr2C8K-GYjWk852R2rzl9MhqPlUxKLdfgg6ikVMZY9WXYNN6N0euh7dpWicWFczH04XEhgQLILpEGjQosw2r0LfY7tMQEGYCFl62Wo3EBGIUAH24c56mxw-S5Yt_qoV1LNlge5cOoYgAH3cVNE-poftvcVnmqACTcl5MzJwviL_UIRzhMPwCiroALaHgiWu8hHhdY4IeTGkBnQf8_2yhpJmC7ASF6yK2LhtUy-aSLE2biY99_IkoVI1c38NATrrppxfhfvwrE=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GI8Lu_I980IM0-XeR6MHY5ppcrGHuDYaqEbLTjFLdAEM_rGIoOrAoqMk2NxXyzY3d21rT4_mIBWeAtzyIdvsFDuHfg53I0kF_dXCFmdNt55HmEsLvvfsMmJ_m-l9nas3bv4lDnC_O4sYVCLly3-wflhRCzQntUstYSV2_InSAizHWFfW_KUuRZvLnX_vOQb3dwBfsIb-P6KbGgQadk2yILzTHf9c8tkNRh-oXYLUHxhZL0TWwN6DOuboJQIkjQAN_cx0kLOE_qsUDVcGNPoqbPnjFPcIuGoaTVmkgujLQWB803FvfB6Tt1951ayIdPUSmOk3q6KNlPjtJW862fEgmnHJltzAlsFdEfx-TaxbFKKA3eqL0g04hUHY1_nDtsYvUSJ24l5CbFTpFtuZJaqaWZhNxCJ5tk8YKdTK-JgsHRZxHYPeWCm9xSJoZTVRHgriqhI1vMJC4btInLZsfLKzuDr4x7Haow0k-DPoR-UHKy9q3WQollybTG2RKZ7VxXCwLQfvdkCdL7r_lxyK3qMHWJZIHQSfGPbZvhNAZbPSt6Fgx3OFb9EsTfNz67NOPJAl0_rkxf4IPklW5qAgYNAXqE7JkQE57kYFaMneL3ygcxoFI9r6Zyjq9zV_5kOR0IAXhlqmKPFBTPGspL9SjReLGZqgZYOiX_xV1j-jQtaAnjmghzMRr4smwW8Oy1CORRznCgA5sgeBVJuJvkiKnXYQy9x0MfIHDmzDxrDeyIczYopOw3Nvon3E5eXNYKU9RlU9puH4aDLazEfr3MiCPP9KTeTrOsgmjNLrTwcpKx5fs0z29DjLBoJluRvvA8_p8TuRs2x8W4yd726XMUNmwl2xreGyyWHJqtORJEeUCxk6cVQFyYqsUBfFbygA8AK2iyhGVfJsOCWM-3GBF0P0K0PZ2N_V0RVt7LcZYa9bCaQD9W5Os4UzLl0qf9vfxpb-GoUMjPNgNVWjjTbnC7kK1HM5Niy16dKlZCMZsGKzr2C8K-GYjWk852R2rzl9MhqPlUxKLdfgg6ikVMZY9WXYNN6N0euh7dpWicWFczH04XEhgQLILpEGjQosw2r0LfY7tMQEGYCFl62Wo3EBGIUAH24c56mxw-S5Yt_qoV1LNlge5cOoYgAH3cVNE-poftvcVnmqACTcl5MzJwviL_UIRzhMPwCiroALaHgiWu8hHhdY4IeTGkBnQf8_2yhpJmC7ASF6yK2LhtUy-aSLE2biY99_IkoVI1c38NATrrppxfhfvwrE=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1060.85,
            "totalPrice": 811
          },
          {
            "allPassengerTotalPrice": 811,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141413a24e240000e54a93b3_0%7C13a20a224e2500001b94c8df_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkU1WMDg5_FTTEjvByD3NRST-Eqe2sCywxvFct-pkPDj5lwzw5_WCuzvSZYE2leDWq_X-LtTfnIajJ0cIW3XGzDMhz8zAH6mpmf9Av6UeY5EGtDZDVnB_QiwZhyLU07l8s806g7DIG1-DPcenQchJqzf8S_uj0xRGpJTXwWrxgV4CeWAjiYL7QMn1ZvYquGgvxak9KPjjsY87PFRloG34n4SonnaN4Ns6DvL5_vsseVFavEZFyXpOt3S28VWBvuhHy8AyCqf_xGOcz0un7IGtccmB2NnUOEgbThGsM9iTKmrAJ4cQSDvRyTBPylwXJNQYp6L8I6NzUv5Dw2MP_6_zwpd3HZ-pV5WE1cXXzxBxBT5iO7nXs1u32mSenP8-C9h7pDq0sweeFVooEHYfpkjL5Wr0jJbeIAt27c3UPyVwQJa16vBHNb5rxzzxS8QVEb0patfarPzB1NV3jtmkTN40WzCRu3oZjJeXmZdhZ55q4_90BXow_ak_wc0Ztd-K6oKlHiwOF_-DVQ_QZ2onSweAdDW4tr0pZ9q8WBN92VJ2AYGHVzd9ybwgatgtn_Jgvq9-zIw69jHJoRRoCjLXB96lDEs-Hs9iSBn9G8ya-P484o-mQusFHzOyZbLjbPx0z3_UNWPlvASU3AA6fBp8rvbfPsaCd77ycr88MSCgtORsnDKrUSLJkctW9dk38dr9OJtaIQm5f-WO2QbVW3AAzYSz_H9bBIIfuGl0f5r10qtcizwZTpYRIoQX0panzhBAVtEKacJd0pueLpiiSR2U5QHHfc46nZdXXlHMO90yyyvMiJPwVFs0Wvp2XiwaprN4rl6NqIU0GJf-UoqxB6gt-uPHW5YyG5f3kyocUfnMK49lgoqoApUHqRiSRyU-dZ72bpYQmi1XLXwoJNiZlYH8Q54yKEf3rBJHmIHOl1VDAvl_tq0OFDVTlKskrjwy1EVXrzVBdnSVG_Eay7yrLueSdXYwCUeUyDQTFNHzxZE-rZBediPuzXY-0cupHpo7Mo5aFD9lhmycWHRO2FmFa-RgGdB_TKwGWOHHhK7Bxkruo5KrWVQ00UslwYdbv208y0eWWqLoLlEs2Y5NxOycXmYvhB__L4oo3XHBBTQGgqZcFAQytjKQaLNK2JTD54-dZt-vkdQl_8yxFouYx1ESPMqP0O30r-Y7Jx5xompd_nAKdl4mBuPaKsycVqAySJN_75pYv82ulW4VH6k909JMwTd39sgUFWCPf-5_dqNN0N-D_zYVlyPn1iVRX4p3Wz8gnSPL9wkcq2A4xIQDFcgvKIT2-lAoTbu0lmeIpDRAIwjj828EFhgExzhM0E7hGt4Zte5leEAW",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141413a24e240000e54a93b3_0%7C13a20a224e2500001b94c8df_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkU1WMDg5_FTTEjvByD3NRST-Eqe2sCywxvFct-pkPDj5lwzw5_WCuzvSZYE2leDWq_X-LtTfnIajJ0cIW3XGzDMhz8zAH6mpmf9Av6UeY5EGtDZDVnB_QiwZhyLU07l8s806g7DIG1-DPcenQchJqzf8S_uj0xRGpJTXwWrxgV4CeWAjiYL7QMn1ZvYquGgvxak9KPjjsY87PFRloG34n4SonnaN4Ns6DvL5_vsseVFavEZFyXpOt3S28VWBvuhHy8AyCqf_xGOcz0un7IGtccmB2NnUOEgbThGsM9iTKmrAJ4cQSDvRyTBPylwXJNQYp6L8I6NzUv5Dw2MP_6_zwpd3HZ-pV5WE1cXXzxBxBT5iO7nXs1u32mSenP8-C9h7pDq0sweeFVooEHYfpkjL5Wr0jJbeIAt27c3UPyVwQJa16vBHNb5rxzzxS8QVEb0patfarPzB1NV3jtmkTN40WzCRu3oZjJeXmZdhZ55q4_90BXow_ak_wc0Ztd-K6oKlHiwOF_-DVQ_QZ2onSweAdDW4tr0pZ9q8WBN92VJ2AYGHVzd9ybwgatgtn_Jgvq9-zIw69jHJoRRoCjLXB96lDEs-Hs9iSBn9G8ya-P484o-mQusFHzOyZbLjbPx0z3_UNWPlvASU3AA6fBp8rvbfPsaCd77ycr88MSCgtORsnDKrUSLJkctW9dk38dr9OJtaIQm5f-WO2QbVW3AAzYSz_H9bBIIfuGl0f5r10qtcizwZTpYRIoQX0panzhBAVtEKacJd0pueLpiiSR2U5QHHfc46nZdXXlHMO90yyyvMiJPwVFs0Wvp2XiwaprN4rl6NqIU0GJf-UoqxB6gt-uPHW5YyG5f3kyocUfnMK49lgoqoApUHqRiSRyU-dZ72bpYQmi1XLXwoJNiZlYH8Q54yKEf3rBJHmIHOl1VDAvl_tq0OFDVTlKskrjwy1EVXrzVBdnSVG_Eay7yrLueSdXYwCUeUyDQTFNHzxZE-rZBediPuzXY-0cupHpo7Mo5aFD9lhmycWHRO2FmFa-RgGdB_TKwGWOHHhK7Bxkruo5KrWVQ00UslwYdbv208y0eWWqLoLlEs2Y5NxOycXmYvhB__L4oo3XHBBTQGgqZcFAQytjKQaLNK2JTD54-dZt-vkdQl_8yxFouYx1ESPMqP0O30r-Y7Jx5xompd_nAKdl4mBuPaKsycVqAySJN_75pYv82ulW4VH6k909JMwTd39sgUFWCPf-5_dqNN0N-D_zYVlyPn1iVRX4p3Wz8gnSPL9wkcq2A4xIQDFcgvKIT2-lAoTbu0lmeIpDRAIwjj828EFhgExzhM0E7hGt4Zte5leEAW",
                "dateChange": 1,
                "departureDate": "2024-10-08",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e240000e54a93b3",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e240000e54a93b3_0",
                    "local_arrival": "2024-10-08T23:35:00.000Z",
                    "local_departure": "2024-10-08T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:35:00.000Z",
                    "utc_departure": "2024-10-08T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e2500001b94c8df",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e2500001b94c8df_0",
                    "local_arrival": "2024-10-09T07:40:00.000Z",
                    "local_departure": "2024-10-09T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-09T05:40:00.000Z",
                    "utc_departure": "2024-10-09T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141413a24e240000e54a93b3_0%7C13a20a224e2500001b94c8df_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkU1WMDg5_FTTEjvByD3NRST-Eqe2sCywxvFct-pkPDj5lwzw5_WCuzvSZYE2leDWq_X-LtTfnIajJ0cIW3XGzDMhz8zAH6mpmf9Av6UeY5EGtDZDVnB_QiwZhyLU07l8s806g7DIG1-DPcenQchJqzf8S_uj0xRGpJTXwWrxgV4CeWAjiYL7QMn1ZvYquGgvxak9KPjjsY87PFRloG34n4SonnaN4Ns6DvL5_vsseVFavEZFyXpOt3S28VWBvuhHy8AyCqf_xGOcz0un7IGtccmB2NnUOEgbThGsM9iTKmrAJ4cQSDvRyTBPylwXJNQYp6L8I6NzUv5Dw2MP_6_zwpd3HZ-pV5WE1cXXzxBxBT5iO7nXs1u32mSenP8-C9h7pDq0sweeFVooEHYfpkjL5Wr0jJbeIAt27c3UPyVwQJa16vBHNb5rxzzxS8QVEb0patfarPzB1NV3jtmkTN40WzCRu3oZjJeXmZdhZ55q4_90BXow_ak_wc0Ztd-K6oKlHiwOF_-DVQ_QZ2onSweAdDW4tr0pZ9q8WBN92VJ2AYGHVzd9ybwgatgtn_Jgvq9-zIw69jHJoRRoCjLXB96lDEs-Hs9iSBn9G8ya-P484o-mQusFHzOyZbLjbPx0z3_UNWPlvASU3AA6fBp8rvbfPsaCd77ycr88MSCgtORsnDKrUSLJkctW9dk38dr9OJtaIQm5f-WO2QbVW3AAzYSz_H9bBIIfuGl0f5r10qtcizwZTpYRIoQX0panzhBAVtEKacJd0pueLpiiSR2U5QHHfc46nZdXXlHMO90yyyvMiJPwVFs0Wvp2XiwaprN4rl6NqIU0GJf-UoqxB6gt-uPHW5YyG5f3kyocUfnMK49lgoqoApUHqRiSRyU-dZ72bpYQmi1XLXwoJNiZlYH8Q54yKEf3rBJHmIHOl1VDAvl_tq0OFDVTlKskrjwy1EVXrzVBdnSVG_Eay7yrLueSdXYwCUeUyDQTFNHzxZE-rZBediPuzXY-0cupHpo7Mo5aFD9lhmycWHRO2FmFa-RgGdB_TKwGWOHHhK7Bxkruo5KrWVQ00UslwYdbv208y0eWWqLoLlEs2Y5NxOycXmYvhB__L4oo3XHBBTQGgqZcFAQytjKQaLNK2JTD54-dZt-vkdQl_8yxFouYx1ESPMqP0O30r-Y7Jx5xompd_nAKdl4mBuPaKsycVqAySJN_75pYv82ulW4VH6k909JMwTd39sgUFWCPf-5_dqNN0N-D_zYVlyPn1iVRX4p3Wz8gnSPL9wkcq2A4xIQDFcgvKIT2-lAoTbu0lmeIpDRAIwjj828EFhgExzhM0E7hGt4Zte5leEAW",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1101.4,
            "totalPrice": 811
          },
          {
            "allPassengerTotalPrice": 813,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gl5LeW6lxpbfDzHEjFW1VZC0WkKmeeyICkFgb7CFNduSM1knOMdBLFo1xYNGHG1rbROPS3siCTGfhOLtmjgDwAMKT6OcDaR_H3Af6alxxKBPD7cOORf_3u1pyFdGH30H6PSe4H7FlIT8jGyEsgGt1AstciNmhIvYYRxvpTMu9FTPE2ql10lsl_d8sqPNnRG56pDqJx4gNoDtL5nr5rvpBaJcBN4Nol9y-VQarHkSGqBbdbsASzRzb9KuMwVveehgOWGD6rvNzwlrb61pWCXgrwIDy23T-Vtf_N_V4vvFO1fHbjUsJLZfsWdi6NOVPaqWRNI4lUJHn9rERmmw4gXBDka1bMxH57aC_LBeFJODlc3OBH9N2K9d36ikjUwna-ifgSJkjbTsWmUsMpdLBE3qbyA0O1l5jTgKARsgcg3pMGC9UDuZ_zXXXHQBGWh0IzGZ82DFTOfVeYzVae_DT9SzVicXvdyTkXT1nMn4Cv4-OtcRtWEfgtOp-X003KcWS-a-3Ouc8FK9GyANEwA9IFK3CYgTJbyJ8Kug1sVCtl1O2b6SCfryK2qYYDz_kGyyojzWSR1soN5zJ8KvPi7CTRX2QVQMNavcELuyAqJ4r7D3HR366NH-31ururw6fNv0a1LXst_PV55HEE5UKpHHb57pGW1Nlk40miny7aWjSHXrKWw-u7vwv-GH_95PcMK6h2oR-aP4QnBbVGuOs8VkSs9iRz_VN6OSLTa8HJP83H5s32jMROYWc_KIiZjyFY7b9NixmM4aJudNEkAckow8huOi-H5k1WsYpZRZHlAWHLXUq13Ga0bLjgYLIW_fAWR0sg4ObzBiIqO0OXHVzN4kkHh5r10vFLdxm6Pq-qdGE9QTqmYLApGc50tZ2YCc0l9UI1QJNtpWUCRP4U6LSH8Hw5lc4SkmSipCaP31UHu0oVMVX2WRHYfajCYo3-wxrUAEAq9Qqxi_hj_CixRLaWg2yfUYBgLxuds2SeY3BHKEAyOkMOnHe1wa2WrRgN796b2N9PdMtZdU2Oq-Q9mfH9rvKPgmsJ3XBfkQYXCIxICRRRA3I932Ypyi1KqUbTFzT27viA122dmgAYuQ9JS0a6UNlYeBLHD-kLoj_gJAeDCNLYzevb0FTjy01WkDIaXc-q-MC53zZ6aVOPnncPQSzpImHbf_MLr2Z-C8ra1Kj_V-OqE0wRHt2Vnx21cYTDwMK6mmoFmJSGFS7D9XNHcY5Ej9Ufd6U2WuACQNtB2YQ_S6L06cUOwY=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gl5LeW6lxpbfDzHEjFW1VZC0WkKmeeyICkFgb7CFNduSM1knOMdBLFo1xYNGHG1rbROPS3siCTGfhOLtmjgDwAMKT6OcDaR_H3Af6alxxKBPD7cOORf_3u1pyFdGH30H6PSe4H7FlIT8jGyEsgGt1AstciNmhIvYYRxvpTMu9FTPE2ql10lsl_d8sqPNnRG56pDqJx4gNoDtL5nr5rvpBaJcBN4Nol9y-VQarHkSGqBbdbsASzRzb9KuMwVveehgOWGD6rvNzwlrb61pWCXgrwIDy23T-Vtf_N_V4vvFO1fHbjUsJLZfsWdi6NOVPaqWRNI4lUJHn9rERmmw4gXBDka1bMxH57aC_LBeFJODlc3OBH9N2K9d36ikjUwna-ifgSJkjbTsWmUsMpdLBE3qbyA0O1l5jTgKARsgcg3pMGC9UDuZ_zXXXHQBGWh0IzGZ82DFTOfVeYzVae_DT9SzVicXvdyTkXT1nMn4Cv4-OtcRtWEfgtOp-X003KcWS-a-3Ouc8FK9GyANEwA9IFK3CYgTJbyJ8Kug1sVCtl1O2b6SCfryK2qYYDz_kGyyojzWSR1soN5zJ8KvPi7CTRX2QVQMNavcELuyAqJ4r7D3HR366NH-31ururw6fNv0a1LXst_PV55HEE5UKpHHb57pGW1Nlk40miny7aWjSHXrKWw-u7vwv-GH_95PcMK6h2oR-aP4QnBbVGuOs8VkSs9iRz_VN6OSLTa8HJP83H5s32jMROYWc_KIiZjyFY7b9NixmM4aJudNEkAckow8huOi-H5k1WsYpZRZHlAWHLXUq13Ga0bLjgYLIW_fAWR0sg4ObzBiIqO0OXHVzN4kkHh5r10vFLdxm6Pq-qdGE9QTqmYLApGc50tZ2YCc0l9UI1QJNtpWUCRP4U6LSH8Hw5lc4SkmSipCaP31UHu0oVMVX2WRHYfajCYo3-wxrUAEAq9Qqxi_hj_CixRLaWg2yfUYBgLxuds2SeY3BHKEAyOkMOnHe1wa2WrRgN796b2N9PdMtZdU2Oq-Q9mfH9rvKPgmsJ3XBfkQYXCIxICRRRA3I932Ypyi1KqUbTFzT27viA122dmgAYuQ9JS0a6UNlYeBLHD-kLoj_gJAeDCNLYzevb0FTjy01WkDIaXc-q-MC53zZ6aVOPnncPQSzpImHbf_MLr2Z-C8ra1Kj_V-OqE0wRHt2Vnx21cYTDwMK6mmoFmJSGFS7D9XNHcY5Ej9Ufd6U2WuACQNtB2YQ_S6L06cUOwY=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gl5LeW6lxpbfDzHEjFW1VZC0WkKmeeyICkFgb7CFNduSM1knOMdBLFo1xYNGHG1rbROPS3siCTGfhOLtmjgDwAMKT6OcDaR_H3Af6alxxKBPD7cOORf_3u1pyFdGH30H6PSe4H7FlIT8jGyEsgGt1AstciNmhIvYYRxvpTMu9FTPE2ql10lsl_d8sqPNnRG56pDqJx4gNoDtL5nr5rvpBaJcBN4Nol9y-VQarHkSGqBbdbsASzRzb9KuMwVveehgOWGD6rvNzwlrb61pWCXgrwIDy23T-Vtf_N_V4vvFO1fHbjUsJLZfsWdi6NOVPaqWRNI4lUJHn9rERmmw4gXBDka1bMxH57aC_LBeFJODlc3OBH9N2K9d36ikjUwna-ifgSJkjbTsWmUsMpdLBE3qbyA0O1l5jTgKARsgcg3pMGC9UDuZ_zXXXHQBGWh0IzGZ82DFTOfVeYzVae_DT9SzVicXvdyTkXT1nMn4Cv4-OtcRtWEfgtOp-X003KcWS-a-3Ouc8FK9GyANEwA9IFK3CYgTJbyJ8Kug1sVCtl1O2b6SCfryK2qYYDz_kGyyojzWSR1soN5zJ8KvPi7CTRX2QVQMNavcELuyAqJ4r7D3HR366NH-31ururw6fNv0a1LXst_PV55HEE5UKpHHb57pGW1Nlk40miny7aWjSHXrKWw-u7vwv-GH_95PcMK6h2oR-aP4QnBbVGuOs8VkSs9iRz_VN6OSLTa8HJP83H5s32jMROYWc_KIiZjyFY7b9NixmM4aJudNEkAckow8huOi-H5k1WsYpZRZHlAWHLXUq13Ga0bLjgYLIW_fAWR0sg4ObzBiIqO0OXHVzN4kkHh5r10vFLdxm6Pq-qdGE9QTqmYLApGc50tZ2YCc0l9UI1QJNtpWUCRP4U6LSH8Hw5lc4SkmSipCaP31UHu0oVMVX2WRHYfajCYo3-wxrUAEAq9Qqxi_hj_CixRLaWg2yfUYBgLxuds2SeY3BHKEAyOkMOnHe1wa2WrRgN796b2N9PdMtZdU2Oq-Q9mfH9rvKPgmsJ3XBfkQYXCIxICRRRA3I932Ypyi1KqUbTFzT27viA122dmgAYuQ9JS0a6UNlYeBLHD-kLoj_gJAeDCNLYzevb0FTjy01WkDIaXc-q-MC53zZ6aVOPnncPQSzpImHbf_MLr2Z-C8ra1Kj_V-OqE0wRHt2Vnx21cYTDwMK6mmoFmJSGFS7D9XNHcY5Ej9Ufd6U2WuACQNtB2YQ_S6L06cUOwY=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1035.59,
            "totalPrice": 813
          },
          {
            "allPassengerTotalPrice": 813,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqH1vsTO42TEbs_SojA2rQT1qHa-c3PxyxLRVfgEFNkFL42neGC4w6F39r7AiCHrLfdpblPuKZ0PXH2HjV0MCuH4qgc6muJeWUvBgwN06TThW4u2k9v7uiDq9QACWPdv8z7Nga99gsid2oB6z8H0TQetPUXPo5QjqFxfdVkWcHyIg1xRDxTdUrbpQWiznPUR4FU9ghZLcPvkkLIRQh5VYtiEZg1uuJaQ4ZCRdmER3YNxWvmZAH_au7uHj0ovJxum2XuVSWSiOtg9IMB_xgzbIB47a4xNdcmHXGLnT242glSUEL6gqIjm8lcEW6VPxYpVuyJ8k5MBYtOXIBrQzL_DAWSiXEeZ8DLGLP-sw57-VFBjaNjt_IP6yeeb_WKrltQarkrqiXcQTI-ONqxfKptg3gXLsJaZgtB1iqsQEE1gpmvmJJsoEwWRgsMqRR5Pe5CoNz-R8qHUrU5oSpYnWeP0WpQFuxeMLbJrunPcatrhQ9veEVhXwq4XgfM6aeSa4vsYQaqZLzNPVj_esZpfdcTwSIP_iPbk2SrtCFwP8VR2oR8GJ51nDqVPGAI8VhOPJtxaa6gZ4CemxT_9GRIsXlbXRtsB94fsc6VA0eYHTjpwJQZooYhhXuuAcq8x2_kvK2VrQ1Hv-n1GFzAGRDNpyo0EeHX4hMWvywoJbks5zassFejcc1CFIbeH9BrKGmeLhsmAXHb3jNxwo5prBiSftLIjagSyti4GIybPRMxUnmZelPdOPquay3dSfcikwhqCaaXaq6KR62hFOptMusA9y7SZxZjHHF2E71BAYrfMd8Cgg4XiYdEyGYK1NbtqBGozFq-av3GLmhDe6mSYJBk2gnPBQBDXYCN6RWhdJ7ZWsW6I8anAC55JOHxmJbsGVPJQMUp8qpFmWS9yO3JvUupWqafr_JRI3qM4IJUvj_Ux6Vo8vB9mucH0x_JbLhTCIXMdW-ZfHeKixuXX8PdOxKMN6uob_6DntOtTip3Z7Q1FvhR1omQQsJ4N3lgxiRjGVJX3iWSxuASE35NH_V1lpPLkvGmIAYVoAw1LhXnFS_GBkgRv50itha6pUCzX3a2vIoDsxCBvZyelmy783-U-TSmeAKFUkVWJhqbYuRJXypCLDKjGAHgdXv7RC4vlcP_Mj27yq0P4mVE5-mFpJrZRbLno-66x2pgCILVVOjpoI49u4F5orG0kCCnfADXa8gidBlpjFJo7Gfw0pGweidmXZG-qAKd-lpExjRCZjhFvuFzpVwFLKZBw=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqH1vsTO42TEbs_SojA2rQT1qHa-c3PxyxLRVfgEFNkFL42neGC4w6F39r7AiCHrLfdpblPuKZ0PXH2HjV0MCuH4qgc6muJeWUvBgwN06TThW4u2k9v7uiDq9QACWPdv8z7Nga99gsid2oB6z8H0TQetPUXPo5QjqFxfdVkWcHyIg1xRDxTdUrbpQWiznPUR4FU9ghZLcPvkkLIRQh5VYtiEZg1uuJaQ4ZCRdmER3YNxWvmZAH_au7uHj0ovJxum2XuVSWSiOtg9IMB_xgzbIB47a4xNdcmHXGLnT242glSUEL6gqIjm8lcEW6VPxYpVuyJ8k5MBYtOXIBrQzL_DAWSiXEeZ8DLGLP-sw57-VFBjaNjt_IP6yeeb_WKrltQarkrqiXcQTI-ONqxfKptg3gXLsJaZgtB1iqsQEE1gpmvmJJsoEwWRgsMqRR5Pe5CoNz-R8qHUrU5oSpYnWeP0WpQFuxeMLbJrunPcatrhQ9veEVhXwq4XgfM6aeSa4vsYQaqZLzNPVj_esZpfdcTwSIP_iPbk2SrtCFwP8VR2oR8GJ51nDqVPGAI8VhOPJtxaa6gZ4CemxT_9GRIsXlbXRtsB94fsc6VA0eYHTjpwJQZooYhhXuuAcq8x2_kvK2VrQ1Hv-n1GFzAGRDNpyo0EeHX4hMWvywoJbks5zassFejcc1CFIbeH9BrKGmeLhsmAXHb3jNxwo5prBiSftLIjagSyti4GIybPRMxUnmZelPdOPquay3dSfcikwhqCaaXaq6KR62hFOptMusA9y7SZxZjHHF2E71BAYrfMd8Cgg4XiYdEyGYK1NbtqBGozFq-av3GLmhDe6mSYJBk2gnPBQBDXYCN6RWhdJ7ZWsW6I8anAC55JOHxmJbsGVPJQMUp8qpFmWS9yO3JvUupWqafr_JRI3qM4IJUvj_Ux6Vo8vB9mucH0x_JbLhTCIXMdW-ZfHeKixuXX8PdOxKMN6uob_6DntOtTip3Z7Q1FvhR1omQQsJ4N3lgxiRjGVJX3iWSxuASE35NH_V1lpPLkvGmIAYVoAw1LhXnFS_GBkgRv50itha6pUCzX3a2vIoDsxCBvZyelmy783-U-TSmeAKFUkVWJhqbYuRJXypCLDKjGAHgdXv7RC4vlcP_Mj27yq0P4mVE5-mFpJrZRbLno-66x2pgCILVVOjpoI49u4F5orG0kCCnfADXa8gidBlpjFJo7Gfw0pGweidmXZG-qAKd-lpExjRCZjhFvuFzpVwFLKZBw=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GqH1vsTO42TEbs_SojA2rQT1qHa-c3PxyxLRVfgEFNkFL42neGC4w6F39r7AiCHrLfdpblPuKZ0PXH2HjV0MCuH4qgc6muJeWUvBgwN06TThW4u2k9v7uiDq9QACWPdv8z7Nga99gsid2oB6z8H0TQetPUXPo5QjqFxfdVkWcHyIg1xRDxTdUrbpQWiznPUR4FU9ghZLcPvkkLIRQh5VYtiEZg1uuJaQ4ZCRdmER3YNxWvmZAH_au7uHj0ovJxum2XuVSWSiOtg9IMB_xgzbIB47a4xNdcmHXGLnT242glSUEL6gqIjm8lcEW6VPxYpVuyJ8k5MBYtOXIBrQzL_DAWSiXEeZ8DLGLP-sw57-VFBjaNjt_IP6yeeb_WKrltQarkrqiXcQTI-ONqxfKptg3gXLsJaZgtB1iqsQEE1gpmvmJJsoEwWRgsMqRR5Pe5CoNz-R8qHUrU5oSpYnWeP0WpQFuxeMLbJrunPcatrhQ9veEVhXwq4XgfM6aeSa4vsYQaqZLzNPVj_esZpfdcTwSIP_iPbk2SrtCFwP8VR2oR8GJ51nDqVPGAI8VhOPJtxaa6gZ4CemxT_9GRIsXlbXRtsB94fsc6VA0eYHTjpwJQZooYhhXuuAcq8x2_kvK2VrQ1Hv-n1GFzAGRDNpyo0EeHX4hMWvywoJbks5zassFejcc1CFIbeH9BrKGmeLhsmAXHb3jNxwo5prBiSftLIjagSyti4GIybPRMxUnmZelPdOPquay3dSfcikwhqCaaXaq6KR62hFOptMusA9y7SZxZjHHF2E71BAYrfMd8Cgg4XiYdEyGYK1NbtqBGozFq-av3GLmhDe6mSYJBk2gnPBQBDXYCN6RWhdJ7ZWsW6I8anAC55JOHxmJbsGVPJQMUp8qpFmWS9yO3JvUupWqafr_JRI3qM4IJUvj_Ux6Vo8vB9mucH0x_JbLhTCIXMdW-ZfHeKixuXX8PdOxKMN6uob_6DntOtTip3Z7Q1FvhR1omQQsJ4N3lgxiRjGVJX3iWSxuASE35NH_V1lpPLkvGmIAYVoAw1LhXnFS_GBkgRv50itha6pUCzX3a2vIoDsxCBvZyelmy783-U-TSmeAKFUkVWJhqbYuRJXypCLDKjGAHgdXv7RC4vlcP_Mj27yq0P4mVE5-mFpJrZRbLno-66x2pgCILVVOjpoI49u4F5orG0kCCnfADXa8gidBlpjFJo7Gfw0pGweidmXZG-qAKd-lpExjRCZjhFvuFzpVwFLKZBw=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1062.26,
            "totalPrice": 813
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4UQVkonVvxqE6c9JtLUPYkyX8j4bm46wvEML9296SsaTa0pBRLrKNiG4GRGXm7kohfIhRu8iscSImSciLGkkfyQGEnIybx6PMuviVLBXqWd1cdFxhE7uwFKNVUQllygBFJGyf37TKfFyK7GsK7sDD6-sMxEv94lxtH3YpbfN86AAkhCaIUnG446mby2Qm6Q-xDI0i9xQibs-kR9hlD4KQT_vxowAJYycDsjeUzx3XNsDBupjOMQvRGOpACdQqbtohbQGpFolDMSfo-0Sshfww1zn5cMPkZvzX1fJGd3blnMpRxsS1m-5qesWpQC7FBNw61JAI8uaCgL1ydkkBFy8wmDco_1bh4guzPj9sIgequ6PeB6gxkVG3tQJbx0q8cUdl84c_Z44XgqZ_mcFOOlWmVIz8QLaJyJ9enm2Pmc3g8-NcqTxNs1Gh6cLBpC6dbLMUSlERRVe_rgPB5xdGtcfdTEd3NgWsyaloz-omsodwROHjvZlTgMOHlnms2NX623AX1D5OZsZpgu-5u8Oj3mchEvXgm0UAsx0odLjORl-EEaqWNPSS3RIM-eAH_N2C4CSYU--NKQmQYpOlvDa0zg5Qmtf_KI-Drb7OxH83T3CFYGcjzIH7HbTn-6Q5YA6zCgXbq6TRag4uhRCigwlTA39EtD56yXeNO8w64hi0u8ITgNiF3rrfGfBjwGaMPDQmp54kpN7mXXxiY35WWUfp0DRTtjFPV7xlTMldPm_Yv6lCT3cr9Tik-hTIfhxukCMgoF4hN63fPxpxZ4MSxuCfweIG0Y0SbD9afIoeNZA47Q_Nt_WM-2NmeUWxSDcqaCRFcbEzeMfoAhe18fkoGeoVgG-K2xLy1EvBHztmfpGNTUuUUC52ChHgg94UxF_EtJMtDs3SUdpaJQrmyeN4eQXVkg391jkMkMLasap66y164kkMw4RM1jyk_XvglEp3GGjJzCVcC4ABDDX6NLNd7-QtmfbTWHCLSk8V4nBS8_-M2PaSYJYsm597G-RgYA2IFqxQPOxsHPhrknzsa4o526T6matbnGiuqQU1LGBLAAflMKEcTgam9DV3FGwoVAL9uv329ZQdfXgvWwPBFLImsTWFkS1BQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4UQVkonVvxqE6c9JtLUPYkyX8j4bm46wvEML9296SsaTa0pBRLrKNiG4GRGXm7kohfIhRu8iscSImSciLGkkfyQGEnIybx6PMuviVLBXqWd1cdFxhE7uwFKNVUQllygBFJGyf37TKfFyK7GsK7sDD6-sMxEv94lxtH3YpbfN86AAkhCaIUnG446mby2Qm6Q-xDI0i9xQibs-kR9hlD4KQT_vxowAJYycDsjeUzx3XNsDBupjOMQvRGOpACdQqbtohbQGpFolDMSfo-0Sshfww1zn5cMPkZvzX1fJGd3blnMpRxsS1m-5qesWpQC7FBNw61JAI8uaCgL1ydkkBFy8wmDco_1bh4guzPj9sIgequ6PeB6gxkVG3tQJbx0q8cUdl84c_Z44XgqZ_mcFOOlWmVIz8QLaJyJ9enm2Pmc3g8-NcqTxNs1Gh6cLBpC6dbLMUSlERRVe_rgPB5xdGtcfdTEd3NgWsyaloz-omsodwROHjvZlTgMOHlnms2NX623AX1D5OZsZpgu-5u8Oj3mchEvXgm0UAsx0odLjORl-EEaqWNPSS3RIM-eAH_N2C4CSYU--NKQmQYpOlvDa0zg5Qmtf_KI-Drb7OxH83T3CFYGcjzIH7HbTn-6Q5YA6zCgXbq6TRag4uhRCigwlTA39EtD56yXeNO8w64hi0u8ITgNiF3rrfGfBjwGaMPDQmp54kpN7mXXxiY35WWUfp0DRTtjFPV7xlTMldPm_Yv6lCT3cr9Tik-hTIfhxukCMgoF4hN63fPxpxZ4MSxuCfweIG0Y0SbD9afIoeNZA47Q_Nt_WM-2NmeUWxSDcqaCRFcbEzeMfoAhe18fkoGeoVgG-K2xLy1EvBHztmfpGNTUuUUC52ChHgg94UxF_EtJMtDs3SUdpaJQrmyeN4eQXVkg391jkMkMLasap66y164kkMw4RM1jyk_XvglEp3GGjJzCVcC4ABDDX6NLNd7-QtmfbTWHCLSk8V4nBS8_-M2PaSYJYsm597G-RgYA2IFqxQPOxsHPhrknzsa4o526T6matbnGiuqQU1LGBLAAflMKEcTgam9DV3FGwoVAL9uv329ZQdfXgvWwPBFLImsTWFkS1BQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4UQVkonVvxqE6c9JtLUPYkyX8j4bm46wvEML9296SsaTa0pBRLrKNiG4GRGXm7kohfIhRu8iscSImSciLGkkfyQGEnIybx6PMuviVLBXqWd1cdFxhE7uwFKNVUQllygBFJGyf37TKfFyK7GsK7sDD6-sMxEv94lxtH3YpbfN86AAkhCaIUnG446mby2Qm6Q-xDI0i9xQibs-kR9hlD4KQT_vxowAJYycDsjeUzx3XNsDBupjOMQvRGOpACdQqbtohbQGpFolDMSfo-0Sshfww1zn5cMPkZvzX1fJGd3blnMpRxsS1m-5qesWpQC7FBNw61JAI8uaCgL1ydkkBFy8wmDco_1bh4guzPj9sIgequ6PeB6gxkVG3tQJbx0q8cUdl84c_Z44XgqZ_mcFOOlWmVIz8QLaJyJ9enm2Pmc3g8-NcqTxNs1Gh6cLBpC6dbLMUSlERRVe_rgPB5xdGtcfdTEd3NgWsyaloz-omsodwROHjvZlTgMOHlnms2NX623AX1D5OZsZpgu-5u8Oj3mchEvXgm0UAsx0odLjORl-EEaqWNPSS3RIM-eAH_N2C4CSYU--NKQmQYpOlvDa0zg5Qmtf_KI-Drb7OxH83T3CFYGcjzIH7HbTn-6Q5YA6zCgXbq6TRag4uhRCigwlTA39EtD56yXeNO8w64hi0u8ITgNiF3rrfGfBjwGaMPDQmp54kpN7mXXxiY35WWUfp0DRTtjFPV7xlTMldPm_Yv6lCT3cr9Tik-hTIfhxukCMgoF4hN63fPxpxZ4MSxuCfweIG0Y0SbD9afIoeNZA47Q_Nt_WM-2NmeUWxSDcqaCRFcbEzeMfoAhe18fkoGeoVgG-K2xLy1EvBHztmfpGNTUuUUC52ChHgg94UxF_EtJMtDs3SUdpaJQrmyeN4eQXVkg391jkMkMLasap66y164kkMw4RM1jyk_XvglEp3GGjJzCVcC4ABDDX6NLNd7-QtmfbTWHCLSk8V4nBS8_-M2PaSYJYsm597G-RgYA2IFqxQPOxsHPhrknzsa4o526T6matbnGiuqQU1LGBLAAflMKEcTgam9DV3FGwoVAL9uv329ZQdfXgvWwPBFLImsTWFkS1BQ==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1008.35,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyX6sc9z9-wS9f0RUOx0nNHeDbTWP9tIWnJK7J3NDdqrIGG0RrikTPwlXMYvtuaPIFDrK-yl2dX13tfRLR9051RsA6vbXpTm8merpGrf1-QhlK3CUl_IX1BCQhjG6Is7sPqOYPfS0wrp99_D4cYfOPIcm58qSIVOwUs0JYI7YAc7gVk1Pvz3B-Ti_U18Df-PK_PoqyAEGgCRJy23jaY56HIFKisLc1ldFGjFgyfdIqF3seirQEywX3kcopq8J3hiqANbAEKIh_Xuy8HOROBVP3mIk-Fv6XmowVoU1CMWMl1xHx0W1-N4ySi0G3GDnUl-X6VsTj6NniKSrZ2cKw15Fx8qE0rPmDGmX8ZajgO_0IMMovezGi83MlG47CbvqWiJFqTzdmVecHRtZZC6nVAmliQGlJvbcnIwcF0o1Xai3eNankBW_88sF7GMFTC5KBSeRsQIafgQ9FHoX_n7HLIxPp6UoRA6IMgptSaVosNE5wf8mpfk6f-HWIEWTYiJ5AkJPjCsA3hOnz78kTe2zcEbZ7Q3JOT_S8ltQYVmDYPZAZnYq5x7051JHfJ--_1TzrGhnWK2uKmnOhQ-dD_11_GX59MxTN8k-R2IIyv2GWfq7Fr3Bpko6t79rbGLh5roW8QF0G12rom2gj5MBVo-tyZjHpTRssUWsPXQPlJ-C9GqfmaUVmNviDe3oii0TLG4guJkqdpW3sXYPlOciQCSfoTYiBu2x37r0hy_b_5f2pBwnnc83wSuK8yyxLI-3l2Gwu0t0LdqwiRrHE2Oa2uSke9razREsj_KBiiu0ZQjlgs5epzf6vud7KZGY712w1d6J8jSE4ToIW3HxhmG7E_CdICrkWRt5DdmfBI5tsSI8GSBCSKx06u5zHGXThfY1T8xWFnBENWpTwnDvT3FVcb1LUSMbxY_bAejKWylXUdFTohvKeMYw52HOe4Mu9Lph0-lSJ6g07wOVAc_KVBLXjKQTMXRPvGkSfa1sLZJMrKy06ExA7QdwfEWuirvtbWvQpWIrRy4YKeolx-ZfDBlrHGUGgQWt5pUFvsmDJmHcDtFwKNUzU3QxOkNW4srCTAboxgxC2rVqACCha20R-C0uZ8p2no5nfw==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyX6sc9z9-wS9f0RUOx0nNHeDbTWP9tIWnJK7J3NDdqrIGG0RrikTPwlXMYvtuaPIFDrK-yl2dX13tfRLR9051RsA6vbXpTm8merpGrf1-QhlK3CUl_IX1BCQhjG6Is7sPqOYPfS0wrp99_D4cYfOPIcm58qSIVOwUs0JYI7YAc7gVk1Pvz3B-Ti_U18Df-PK_PoqyAEGgCRJy23jaY56HIFKisLc1ldFGjFgyfdIqF3seirQEywX3kcopq8J3hiqANbAEKIh_Xuy8HOROBVP3mIk-Fv6XmowVoU1CMWMl1xHx0W1-N4ySi0G3GDnUl-X6VsTj6NniKSrZ2cKw15Fx8qE0rPmDGmX8ZajgO_0IMMovezGi83MlG47CbvqWiJFqTzdmVecHRtZZC6nVAmliQGlJvbcnIwcF0o1Xai3eNankBW_88sF7GMFTC5KBSeRsQIafgQ9FHoX_n7HLIxPp6UoRA6IMgptSaVosNE5wf8mpfk6f-HWIEWTYiJ5AkJPjCsA3hOnz78kTe2zcEbZ7Q3JOT_S8ltQYVmDYPZAZnYq5x7051JHfJ--_1TzrGhnWK2uKmnOhQ-dD_11_GX59MxTN8k-R2IIyv2GWfq7Fr3Bpko6t79rbGLh5roW8QF0G12rom2gj5MBVo-tyZjHpTRssUWsPXQPlJ-C9GqfmaUVmNviDe3oii0TLG4guJkqdpW3sXYPlOciQCSfoTYiBu2x37r0hy_b_5f2pBwnnc83wSuK8yyxLI-3l2Gwu0t0LdqwiRrHE2Oa2uSke9razREsj_KBiiu0ZQjlgs5epzf6vud7KZGY712w1d6J8jSE4ToIW3HxhmG7E_CdICrkWRt5DdmfBI5tsSI8GSBCSKx06u5zHGXThfY1T8xWFnBENWpTwnDvT3FVcb1LUSMbxY_bAejKWylXUdFTohvKeMYw52HOe4Mu9Lph0-lSJ6g07wOVAc_KVBLXjKQTMXRPvGkSfa1sLZJMrKy06ExA7QdwfEWuirvtbWvQpWIrRy4YKeolx-ZfDBlrHGUGgQWt5pUFvsmDJmHcDtFwKNUzU3QxOkNW4srCTAboxgxC2rVqACCha20R-C0uZ8p2no5nfw==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyX6sc9z9-wS9f0RUOx0nNHeDbTWP9tIWnJK7J3NDdqrIGG0RrikTPwlXMYvtuaPIFDrK-yl2dX13tfRLR9051RsA6vbXpTm8merpGrf1-QhlK3CUl_IX1BCQhjG6Is7sPqOYPfS0wrp99_D4cYfOPIcm58qSIVOwUs0JYI7YAc7gVk1Pvz3B-Ti_U18Df-PK_PoqyAEGgCRJy23jaY56HIFKisLc1ldFGjFgyfdIqF3seirQEywX3kcopq8J3hiqANbAEKIh_Xuy8HOROBVP3mIk-Fv6XmowVoU1CMWMl1xHx0W1-N4ySi0G3GDnUl-X6VsTj6NniKSrZ2cKw15Fx8qE0rPmDGmX8ZajgO_0IMMovezGi83MlG47CbvqWiJFqTzdmVecHRtZZC6nVAmliQGlJvbcnIwcF0o1Xai3eNankBW_88sF7GMFTC5KBSeRsQIafgQ9FHoX_n7HLIxPp6UoRA6IMgptSaVosNE5wf8mpfk6f-HWIEWTYiJ5AkJPjCsA3hOnz78kTe2zcEbZ7Q3JOT_S8ltQYVmDYPZAZnYq5x7051JHfJ--_1TzrGhnWK2uKmnOhQ-dD_11_GX59MxTN8k-R2IIyv2GWfq7Fr3Bpko6t79rbGLh5roW8QF0G12rom2gj5MBVo-tyZjHpTRssUWsPXQPlJ-C9GqfmaUVmNviDe3oii0TLG4guJkqdpW3sXYPlOciQCSfoTYiBu2x37r0hy_b_5f2pBwnnc83wSuK8yyxLI-3l2Gwu0t0LdqwiRrHE2Oa2uSke9razREsj_KBiiu0ZQjlgs5epzf6vud7KZGY712w1d6J8jSE4ToIW3HxhmG7E_CdICrkWRt5DdmfBI5tsSI8GSBCSKx06u5zHGXThfY1T8xWFnBENWpTwnDvT3FVcb1LUSMbxY_bAejKWylXUdFTohvKeMYw52HOe4Mu9Lph0-lSJ6g07wOVAc_KVBLXjKQTMXRPvGkSfa1sLZJMrKy06ExA7QdwfEWuirvtbWvQpWIrRy4YKeolx-ZfDBlrHGUGgQWt5pUFvsmDJmHcDtFwKNUzU3QxOkNW4srCTAboxgxC2rVqACCha20R-C0uZ8p2no5nfw==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1008.35,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVnPzxYHQypsAwpXP96793AGgqLLLNsTW-ai77YNoYn-7WGcyBfghe_pzfOgBIELSBnpc-sC9b1AmK8Q3xqVufmDySotR2fhQ20GkIoIHsk7P1d3YQ60FaQm6e319j565QXErmcSW49UAd7ZZhvvmc-XtAADP0XniOKyZt4HmwvRc8CbXH-jwBOTTHSFtcsCV_L8FlwLRaf12joLgYfWK-tgKpgQz6B45cjvm4z1oquNivkCYhXiiY5xRGrbRpKyC5830tT2owwF-fuerPNTsPoEQs1VlU0_HRrlVu6HSZPdo-vx_UqM1e8FqaZJeg7YPv6YYMbdZFX91f6pYmC65FeyK37aZ-6VQ0Q-JznuC0t0ZErkNrKC8u25C-yQhyzySxsfncG_RHDT5MysPLB42LEvnGKxSro8D7R6rjDUB2w067EbQ3VGrXDJuhvbzV0iSdqM7iQ1RwpOZPgi5XK_YpJb-W7FbBNfAIqRhHSK5bHxHSqN6iiQjWDPhFWNqRcIINDZt0W_ksXfZ1UXLaLpRdTluYiNOhoRquAm-KP9zECUjs3-Zj06nL3xR_7pe40qHBNkvTebF5MqpGib3S3T-czHEPUzGNfSXDpzfV46oVt991B_B7k-gX9Fc14-9CLZR_Uhu_iLTdb3uyRoEH-6oJpqQCTsUTOyEA1hvLTmDr1M_rEuN6Jb4ycVaRvPd2hXEuWEQ49gBEm5k7cPavPBjaczRgrrgL8EbMiSPZKrhKItbiR3e_Dyf0rbqRVcMwg1hX6zxjMvHqKQEWu1-SdLcZB3TAvnWPFe1yCPU_T8s7PlDJdurG6eViCx-r2LHZFKY7xjAwJXxCNCbSoj53pdWiSUt-_Og5kxSLBDKngZZCm8yHYsJtPxSPAD1BM66ghvqRGvTnY8528jw24FkS6bew1zQK7teeNcjtviUAPrjU0Z6kc-fJzNDcZO6kzBWWmmNrCeeD4tbghJLX_OLGFY-t0GIWy9IAJy3-E0qlgSutPfrQFcf4FKXPMClwtvjAZ14VnkJvf3DqzlexS2G4tEPmq7x8AMJvQw7GgPPRrmlqQUkuDkUYAP3hhGxEXrgaDkJ6Te7I034mG6hqkmC4rEDxQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVnPzxYHQypsAwpXP96793AGgqLLLNsTW-ai77YNoYn-7WGcyBfghe_pzfOgBIELSBnpc-sC9b1AmK8Q3xqVufmDySotR2fhQ20GkIoIHsk7P1d3YQ60FaQm6e319j565QXErmcSW49UAd7ZZhvvmc-XtAADP0XniOKyZt4HmwvRc8CbXH-jwBOTTHSFtcsCV_L8FlwLRaf12joLgYfWK-tgKpgQz6B45cjvm4z1oquNivkCYhXiiY5xRGrbRpKyC5830tT2owwF-fuerPNTsPoEQs1VlU0_HRrlVu6HSZPdo-vx_UqM1e8FqaZJeg7YPv6YYMbdZFX91f6pYmC65FeyK37aZ-6VQ0Q-JznuC0t0ZErkNrKC8u25C-yQhyzySxsfncG_RHDT5MysPLB42LEvnGKxSro8D7R6rjDUB2w067EbQ3VGrXDJuhvbzV0iSdqM7iQ1RwpOZPgi5XK_YpJb-W7FbBNfAIqRhHSK5bHxHSqN6iiQjWDPhFWNqRcIINDZt0W_ksXfZ1UXLaLpRdTluYiNOhoRquAm-KP9zECUjs3-Zj06nL3xR_7pe40qHBNkvTebF5MqpGib3S3T-czHEPUzGNfSXDpzfV46oVt991B_B7k-gX9Fc14-9CLZR_Uhu_iLTdb3uyRoEH-6oJpqQCTsUTOyEA1hvLTmDr1M_rEuN6Jb4ycVaRvPd2hXEuWEQ49gBEm5k7cPavPBjaczRgrrgL8EbMiSPZKrhKItbiR3e_Dyf0rbqRVcMwg1hX6zxjMvHqKQEWu1-SdLcZB3TAvnWPFe1yCPU_T8s7PlDJdurG6eViCx-r2LHZFKY7xjAwJXxCNCbSoj53pdWiSUt-_Og5kxSLBDKngZZCm8yHYsJtPxSPAD1BM66ghvqRGvTnY8528jw24FkS6bew1zQK7teeNcjtviUAPrjU0Z6kc-fJzNDcZO6kzBWWmmNrCeeD4tbghJLX_OLGFY-t0GIWy9IAJy3-E0qlgSutPfrQFcf4FKXPMClwtvjAZ14VnkJvf3DqzlexS2G4tEPmq7x8AMJvQw7GgPPRrmlqQUkuDkUYAP3hhGxEXrgaDkJ6Te7I034mG6hqkmC4rEDxQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GVnPzxYHQypsAwpXP96793AGgqLLLNsTW-ai77YNoYn-7WGcyBfghe_pzfOgBIELSBnpc-sC9b1AmK8Q3xqVufmDySotR2fhQ20GkIoIHsk7P1d3YQ60FaQm6e319j565QXErmcSW49UAd7ZZhvvmc-XtAADP0XniOKyZt4HmwvRc8CbXH-jwBOTTHSFtcsCV_L8FlwLRaf12joLgYfWK-tgKpgQz6B45cjvm4z1oquNivkCYhXiiY5xRGrbRpKyC5830tT2owwF-fuerPNTsPoEQs1VlU0_HRrlVu6HSZPdo-vx_UqM1e8FqaZJeg7YPv6YYMbdZFX91f6pYmC65FeyK37aZ-6VQ0Q-JznuC0t0ZErkNrKC8u25C-yQhyzySxsfncG_RHDT5MysPLB42LEvnGKxSro8D7R6rjDUB2w067EbQ3VGrXDJuhvbzV0iSdqM7iQ1RwpOZPgi5XK_YpJb-W7FbBNfAIqRhHSK5bHxHSqN6iiQjWDPhFWNqRcIINDZt0W_ksXfZ1UXLaLpRdTluYiNOhoRquAm-KP9zECUjs3-Zj06nL3xR_7pe40qHBNkvTebF5MqpGib3S3T-czHEPUzGNfSXDpzfV46oVt991B_B7k-gX9Fc14-9CLZR_Uhu_iLTdb3uyRoEH-6oJpqQCTsUTOyEA1hvLTmDr1M_rEuN6Jb4ycVaRvPd2hXEuWEQ49gBEm5k7cPavPBjaczRgrrgL8EbMiSPZKrhKItbiR3e_Dyf0rbqRVcMwg1hX6zxjMvHqKQEWu1-SdLcZB3TAvnWPFe1yCPU_T8s7PlDJdurG6eViCx-r2LHZFKY7xjAwJXxCNCbSoj53pdWiSUt-_Og5kxSLBDKngZZCm8yHYsJtPxSPAD1BM66ghvqRGvTnY8528jw24FkS6bew1zQK7teeNcjtviUAPrjU0Z6kc-fJzNDcZO6kzBWWmmNrCeeD4tbghJLX_OLGFY-t0GIWy9IAJy3-E0qlgSutPfrQFcf4FKXPMClwtvjAZ14VnkJvf3DqzlexS2G4tEPmq7x8AMJvQw7GgPPRrmlqQUkuDkUYAP3hhGxEXrgaDkJ6Te7I034mG6hqkmC4rEDxQ==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1009.02,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 814,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GORQ5cbeOcEpzYZkttns72g1jm_Zu65zs5MyJQFZfyzJDcvx0DWh0bTB1LFmDeT2S4KVvudzeGERtFAB_M5rEve_DFTGJpWMM-KfGb92HsLhVW-dm-vaK6gIe_Kka4--LjN-W0i-I_NZGKX8pOmH-2b885v5KkmwqByIFvmcC4v4V8Qow-V66HcQ-0o_GGrJ-UvHxnoO-qBMoSdCvD6gnR2-1A8n_xvkGBtCUgLhQudaQEGj1MQ-xS-hjgHehGtsk5dCSB-R1ZDDQAqX-b4AcG6pCRWsPSwlakm5-j3kFH6dyt8PHf-WVMjbHGHWOlWKKid5b13S7SiD52_FAaHg22WNc3zG23KLau84oeI74I0pAKPR-xtcOOL3AppOiSb2tkbjF-Yb5eZR5DBEu4Q2R1s1vIO-U8Kfwju2hOKkTQYncGZ8v1WAJGwPE6y25bZzPr2GMYqfD_cIuGVMWIUc5oVvo_YqPztY4Cg_5CLSDMlXEKKDRj3GdB_Q2EkHNZMFlS05hJcQia11GeZWmt0EZrorlq2j-ble5j_JSOf_YMnWMkPDF1OUeOZeLBI3A4LPAvPn4YyGqNGbFLs7lXHvMWdAA2n5BPAMLCEuMOfatmg3IZfyOp84x0Cp3VrKWAotPoAW9E9jS3ZwFvaRWgZsUIWPiArIYIs9U85Xwwyx-4l-OCvpu7H2-HYBg2JKg9POfhyVEzUD2rD03ZrVsuTdZ9Qs8pPw59DQbOMsqKAguMBllGQzQzgnO5Ei_fkQy4zm6O4ZatwypGq9I37_terviN157wvMKurbKvHCn8Cse3twsNzE_oF5l7xnZBVKm__cFUvmsWpCtsBtgEA__eODbixXetgBSKooa6YUIRCviMTGj5hvviWdXpHh1Yayly7U2xKVV4kYNt-_-9JwHMX7he_7hJKbxzPr53-9sdbeO_kcrT2uH088mgqqHSKGpQr-a_L_BjfEiIvVCdhmZBJOhf3PDvw9Iqqvzy0mDBZvmz3GuAvwQm4_sJ2xAnioO60TNJd33ii5IRuS8oXnVVxBwgch-ExmLeJT4C8XpZCvyjMLYH4ksv2seTC4qVeR66rnv",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GORQ5cbeOcEpzYZkttns72g1jm_Zu65zs5MyJQFZfyzJDcvx0DWh0bTB1LFmDeT2S4KVvudzeGERtFAB_M5rEve_DFTGJpWMM-KfGb92HsLhVW-dm-vaK6gIe_Kka4--LjN-W0i-I_NZGKX8pOmH-2b885v5KkmwqByIFvmcC4v4V8Qow-V66HcQ-0o_GGrJ-UvHxnoO-qBMoSdCvD6gnR2-1A8n_xvkGBtCUgLhQudaQEGj1MQ-xS-hjgHehGtsk5dCSB-R1ZDDQAqX-b4AcG6pCRWsPSwlakm5-j3kFH6dyt8PHf-WVMjbHGHWOlWKKid5b13S7SiD52_FAaHg22WNc3zG23KLau84oeI74I0pAKPR-xtcOOL3AppOiSb2tkbjF-Yb5eZR5DBEu4Q2R1s1vIO-U8Kfwju2hOKkTQYncGZ8v1WAJGwPE6y25bZzPr2GMYqfD_cIuGVMWIUc5oVvo_YqPztY4Cg_5CLSDMlXEKKDRj3GdB_Q2EkHNZMFlS05hJcQia11GeZWmt0EZrorlq2j-ble5j_JSOf_YMnWMkPDF1OUeOZeLBI3A4LPAvPn4YyGqNGbFLs7lXHvMWdAA2n5BPAMLCEuMOfatmg3IZfyOp84x0Cp3VrKWAotPoAW9E9jS3ZwFvaRWgZsUIWPiArIYIs9U85Xwwyx-4l-OCvpu7H2-HYBg2JKg9POfhyVEzUD2rD03ZrVsuTdZ9Qs8pPw59DQbOMsqKAguMBllGQzQzgnO5Ei_fkQy4zm6O4ZatwypGq9I37_terviN157wvMKurbKvHCn8Cse3twsNzE_oF5l7xnZBVKm__cFUvmsWpCtsBtgEA__eODbixXetgBSKooa6YUIRCviMTGj5hvviWdXpHh1Yayly7U2xKVV4kYNt-_-9JwHMX7he_7hJKbxzPr53-9sdbeO_kcrT2uH088mgqqHSKGpQr-a_L_BjfEiIvVCdhmZBJOhf3PDvw9Iqqvzy0mDBZvmz3GuAvwQm4_sJ2xAnioO60TNJd33ii5IRuS8oXnVVxBwgch-ExmLeJT4C8XpZCvyjMLYH4ksv2seTC4qVeR66rnv",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GORQ5cbeOcEpzYZkttns72g1jm_Zu65zs5MyJQFZfyzJDcvx0DWh0bTB1LFmDeT2S4KVvudzeGERtFAB_M5rEve_DFTGJpWMM-KfGb92HsLhVW-dm-vaK6gIe_Kka4--LjN-W0i-I_NZGKX8pOmH-2b885v5KkmwqByIFvmcC4v4V8Qow-V66HcQ-0o_GGrJ-UvHxnoO-qBMoSdCvD6gnR2-1A8n_xvkGBtCUgLhQudaQEGj1MQ-xS-hjgHehGtsk5dCSB-R1ZDDQAqX-b4AcG6pCRWsPSwlakm5-j3kFH6dyt8PHf-WVMjbHGHWOlWKKid5b13S7SiD52_FAaHg22WNc3zG23KLau84oeI74I0pAKPR-xtcOOL3AppOiSb2tkbjF-Yb5eZR5DBEu4Q2R1s1vIO-U8Kfwju2hOKkTQYncGZ8v1WAJGwPE6y25bZzPr2GMYqfD_cIuGVMWIUc5oVvo_YqPztY4Cg_5CLSDMlXEKKDRj3GdB_Q2EkHNZMFlS05hJcQia11GeZWmt0EZrorlq2j-ble5j_JSOf_YMnWMkPDF1OUeOZeLBI3A4LPAvPn4YyGqNGbFLs7lXHvMWdAA2n5BPAMLCEuMOfatmg3IZfyOp84x0Cp3VrKWAotPoAW9E9jS3ZwFvaRWgZsUIWPiArIYIs9U85Xwwyx-4l-OCvpu7H2-HYBg2JKg9POfhyVEzUD2rD03ZrVsuTdZ9Qs8pPw59DQbOMsqKAguMBllGQzQzgnO5Ei_fkQy4zm6O4ZatwypGq9I37_terviN157wvMKurbKvHCn8Cse3twsNzE_oF5l7xnZBVKm__cFUvmsWpCtsBtgEA__eODbixXetgBSKooa6YUIRCviMTGj5hvviWdXpHh1Yayly7U2xKVV4kYNt-_-9JwHMX7he_7hJKbxzPr53-9sdbeO_kcrT2uH088mgqqHSKGpQr-a_L_BjfEiIvVCdhmZBJOhf3PDvw9Iqqvzy0mDBZvmz3GuAvwQm4_sJ2xAnioO60TNJd33ii5IRuS8oXnVVxBwgch-ExmLeJT4C8XpZCvyjMLYH4ksv2seTC4qVeR66rnv",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1009.86,
            "totalPrice": 814
          },
          {
            "allPassengerTotalPrice": 817,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZ0rxFxuEjTJxImpt_gW70E3UTpwi0JqjTi8SwKbpz4UXRg0ELX4OTsheSzZIDLiNu7SX1K_COXFnxTnAlN_gCKMxl1069B7H6tGFQwP3ig9rnpqCffyigG8kFrOW1yY1GG5giONlQ55nCglgOamGj2N5Kr8D9-sXaS2SJda29k8-esBYaj1nmQQU8Lx4WUdyHdvQ8xgLCLjhOu9yIS1Avc71Q0sGrBxYaGubicBaNajujQv8stVBnW-RJfa9B5-PJ7ZqEtM94knVHG80_7500Q4vuDLfIc3aPIKv5zjxGXY9erL_C6I4GxRw2m0wtBwJccZJ-fzRZXpblJ9lRzbJ-zq81WTgGj1c_mjnaYd1etiO-5noGm3IZ1PH1e7h5WOdqZbbKphKCeD96IXyZ-zOfQY1948wyKAnEXT0TR0W8H2z2uxEhViGQQo9tycBusnGLuFood6mguuv7ZxD5UoEjIAnM3Z6gP_PZkaOKwmpgPTxk6sN-oPJD0TwMCKzrZTC5grPAhNOwNFr_ZTR4Ex7-spMzvnAGfNubosyViYuT6Z8-jjbghr7fkKvywW5O_j-ch9lPWwy3XM5kAOpjHe4_lUhtWSGFXmEHcNrPrhDJj5ArjA3TsBwvwliANMdaxCi2mvME9rWtbM0-lUN9nteHcf_W05iW5d799SCbEZUJ9bW2a2stPbEP6Lzk3JHf-N3gEw6MeuDGr08s8eyFUv9mkTz2c8oa_VZV5GtPZt0DyiJqLHDJjIDkRf_MpyjJ2Tk6ESAI5yYpn_cP-zdj1DswFrXEzb0WixyCIjLk4g1a5lTZUNTN9PgJcwjbknVIG75XcrhMMW6_oPDbPi909xzeP04nGrOSsdPYCWhstcGaC4ngaNYJhe_z-bc5mPFkQf6Txurvuc7OHy5a5Be5N2E-essjW8hkWz57ezSFFDhXb8DoFV1hP2RRsN6mcXBmsIZFdIPnZXvZbprrz3vDQhUx5gVHkPuJ0MUF8KZXLccpQzbjwpKfQG4A44Dc1kmsYBut81BOmrtTMx8BcrUcnt49EMUPRfXiZ2MCrAxQ7spmhgvOKOSalVncSqNvWO-QNNhIDZ31nUj1JtGOPFlDKg7gvpyOobS00PaB201a9KX7XiXcmGIO8rEM0hEt51DLH5XSX6cadXwX6-QdD8A3RvsCA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZ0rxFxuEjTJxImpt_gW70E3UTpwi0JqjTi8SwKbpz4UXRg0ELX4OTsheSzZIDLiNu7SX1K_COXFnxTnAlN_gCKMxl1069B7H6tGFQwP3ig9rnpqCffyigG8kFrOW1yY1GG5giONlQ55nCglgOamGj2N5Kr8D9-sXaS2SJda29k8-esBYaj1nmQQU8Lx4WUdyHdvQ8xgLCLjhOu9yIS1Avc71Q0sGrBxYaGubicBaNajujQv8stVBnW-RJfa9B5-PJ7ZqEtM94knVHG80_7500Q4vuDLfIc3aPIKv5zjxGXY9erL_C6I4GxRw2m0wtBwJccZJ-fzRZXpblJ9lRzbJ-zq81WTgGj1c_mjnaYd1etiO-5noGm3IZ1PH1e7h5WOdqZbbKphKCeD96IXyZ-zOfQY1948wyKAnEXT0TR0W8H2z2uxEhViGQQo9tycBusnGLuFood6mguuv7ZxD5UoEjIAnM3Z6gP_PZkaOKwmpgPTxk6sN-oPJD0TwMCKzrZTC5grPAhNOwNFr_ZTR4Ex7-spMzvnAGfNubosyViYuT6Z8-jjbghr7fkKvywW5O_j-ch9lPWwy3XM5kAOpjHe4_lUhtWSGFXmEHcNrPrhDJj5ArjA3TsBwvwliANMdaxCi2mvME9rWtbM0-lUN9nteHcf_W05iW5d799SCbEZUJ9bW2a2stPbEP6Lzk3JHf-N3gEw6MeuDGr08s8eyFUv9mkTz2c8oa_VZV5GtPZt0DyiJqLHDJjIDkRf_MpyjJ2Tk6ESAI5yYpn_cP-zdj1DswFrXEzb0WixyCIjLk4g1a5lTZUNTN9PgJcwjbknVIG75XcrhMMW6_oPDbPi909xzeP04nGrOSsdPYCWhstcGaC4ngaNYJhe_z-bc5mPFkQf6Txurvuc7OHy5a5Be5N2E-essjW8hkWz57ezSFFDhXb8DoFV1hP2RRsN6mcXBmsIZFdIPnZXvZbprrz3vDQhUx5gVHkPuJ0MUF8KZXLccpQzbjwpKfQG4A44Dc1kmsYBut81BOmrtTMx8BcrUcnt49EMUPRfXiZ2MCrAxQ7spmhgvOKOSalVncSqNvWO-QNNhIDZ31nUj1JtGOPFlDKg7gvpyOobS00PaB201a9KX7XiXcmGIO8rEM0hEt51DLH5XSX6cadXwX6-QdD8A3RvsCA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZ0rxFxuEjTJxImpt_gW70E3UTpwi0JqjTi8SwKbpz4UXRg0ELX4OTsheSzZIDLiNu7SX1K_COXFnxTnAlN_gCKMxl1069B7H6tGFQwP3ig9rnpqCffyigG8kFrOW1yY1GG5giONlQ55nCglgOamGj2N5Kr8D9-sXaS2SJda29k8-esBYaj1nmQQU8Lx4WUdyHdvQ8xgLCLjhOu9yIS1Avc71Q0sGrBxYaGubicBaNajujQv8stVBnW-RJfa9B5-PJ7ZqEtM94knVHG80_7500Q4vuDLfIc3aPIKv5zjxGXY9erL_C6I4GxRw2m0wtBwJccZJ-fzRZXpblJ9lRzbJ-zq81WTgGj1c_mjnaYd1etiO-5noGm3IZ1PH1e7h5WOdqZbbKphKCeD96IXyZ-zOfQY1948wyKAnEXT0TR0W8H2z2uxEhViGQQo9tycBusnGLuFood6mguuv7ZxD5UoEjIAnM3Z6gP_PZkaOKwmpgPTxk6sN-oPJD0TwMCKzrZTC5grPAhNOwNFr_ZTR4Ex7-spMzvnAGfNubosyViYuT6Z8-jjbghr7fkKvywW5O_j-ch9lPWwy3XM5kAOpjHe4_lUhtWSGFXmEHcNrPrhDJj5ArjA3TsBwvwliANMdaxCi2mvME9rWtbM0-lUN9nteHcf_W05iW5d799SCbEZUJ9bW2a2stPbEP6Lzk3JHf-N3gEw6MeuDGr08s8eyFUv9mkTz2c8oa_VZV5GtPZt0DyiJqLHDJjIDkRf_MpyjJ2Tk6ESAI5yYpn_cP-zdj1DswFrXEzb0WixyCIjLk4g1a5lTZUNTN9PgJcwjbknVIG75XcrhMMW6_oPDbPi909xzeP04nGrOSsdPYCWhstcGaC4ngaNYJhe_z-bc5mPFkQf6Txurvuc7OHy5a5Be5N2E-essjW8hkWz57ezSFFDhXb8DoFV1hP2RRsN6mcXBmsIZFdIPnZXvZbprrz3vDQhUx5gVHkPuJ0MUF8KZXLccpQzbjwpKfQG4A44Dc1kmsYBut81BOmrtTMx8BcrUcnt49EMUPRfXiZ2MCrAxQ7spmhgvOKOSalVncSqNvWO-QNNhIDZ31nUj1JtGOPFlDKg7gvpyOobS00PaB201a9KX7XiXcmGIO8rEM0hEt51DLH5XSX6cadXwX6-QdD8A3RvsCA==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1059.66,
            "totalPrice": 817
          },
          {
            "allPassengerTotalPrice": 821,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUloA-btxLwOfy2aZ-5JcRa1FMc98XLhW4WH5Yev3P8TN8q8PfD9KH7XUyWzOwwdR4duFlpNGaNJxRmfs6hBEnGtgFdkfFUMKIyJq4M-PM5fmKa6niUElBHU7QClDkZtfKj1gJdB4JiySAetLvqPFXTejpy7v337XfVmDPvjZwoosbdlsHgC3Y0QyqZ47tsPFN1kHRya2jbXOtpn9zjkDOcws5k-dqd2UlGqR6DUqxrtOFLwUZl_GcakCm7RRI429s9yur-mdCotOt2bT01mOcdi3Lq6adEI-xJQ0iT70RyokBdC6Lq0Alvvqq5Jx-idbZNOXDAt-drpbfg_QAaOqIqEcGKlt3vCcsZeZX9-utzlLduqVGlnkqHh9dQuuyOaVnZhOCGu_MtlxbqG0PMfP3-HqGBZFKtliwbu4lJ6uP6N4_SLMpEEH14rAUnPVITraI4vooUF4hhCfib-6TDpEoMJVvmY8KRMAsO6iqw2-IThqxrzMcm8WR1Ds9eeUh1CsmWenBbBakDtuqWtjloE0OLf8E2KeetiswJbs18-Hf9tpJcIrVzlNf3HILVPq01jU3uG5Z5YeKCQtj66043lzZ7OEqljPV1jPCzrXTIHkUaMgs1YIP7JQwj7A2flVBvxP9rLjgQdHn-QdXr3Fd3GgZpYLzhb0h4EXls9bnBYYu0GassYO4NvhT5zpGBcVHEE_M0nQGQCvOEsEOSWwLtuTK5xMEdo7wBDOkdOYGNBfD_Q1h3ayFs2KdH7iVbz9mFwEfIbBVQNx2oN2YmdXwNl8QSYA43TjIFFVDslnSvzCBkLCqAKlxBstMGJ_vzr27K_8uzflSUeyfOfFxZliT85mNhdiVBms6Xplxrz63et5BiRgMjvOQ1XYtXWe86XIZ3IOcoeq6xsJEfMW1CUofICyCYhO4kT-05nQG9RLMQPnxW2-Jn4Tb-cFGtb7ReTYv1hmrO-JsPsQDYgzsioLcj2D0C8y0qBbny2kGMCV7vqigMJ3ZPafAWG6z_Jmkkl6Kl3ZWVEW1TJrmRjolmefvv58JqUyKo3dCPNvtSwcEV2Y_KPDv4iS68RufellbBV_niW5",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUloA-btxLwOfy2aZ-5JcRa1FMc98XLhW4WH5Yev3P8TN8q8PfD9KH7XUyWzOwwdR4duFlpNGaNJxRmfs6hBEnGtgFdkfFUMKIyJq4M-PM5fmKa6niUElBHU7QClDkZtfKj1gJdB4JiySAetLvqPFXTejpy7v337XfVmDPvjZwoosbdlsHgC3Y0QyqZ47tsPFN1kHRya2jbXOtpn9zjkDOcws5k-dqd2UlGqR6DUqxrtOFLwUZl_GcakCm7RRI429s9yur-mdCotOt2bT01mOcdi3Lq6adEI-xJQ0iT70RyokBdC6Lq0Alvvqq5Jx-idbZNOXDAt-drpbfg_QAaOqIqEcGKlt3vCcsZeZX9-utzlLduqVGlnkqHh9dQuuyOaVnZhOCGu_MtlxbqG0PMfP3-HqGBZFKtliwbu4lJ6uP6N4_SLMpEEH14rAUnPVITraI4vooUF4hhCfib-6TDpEoMJVvmY8KRMAsO6iqw2-IThqxrzMcm8WR1Ds9eeUh1CsmWenBbBakDtuqWtjloE0OLf8E2KeetiswJbs18-Hf9tpJcIrVzlNf3HILVPq01jU3uG5Z5YeKCQtj66043lzZ7OEqljPV1jPCzrXTIHkUaMgs1YIP7JQwj7A2flVBvxP9rLjgQdHn-QdXr3Fd3GgZpYLzhb0h4EXls9bnBYYu0GassYO4NvhT5zpGBcVHEE_M0nQGQCvOEsEOSWwLtuTK5xMEdo7wBDOkdOYGNBfD_Q1h3ayFs2KdH7iVbz9mFwEfIbBVQNx2oN2YmdXwNl8QSYA43TjIFFVDslnSvzCBkLCqAKlxBstMGJ_vzr27K_8uzflSUeyfOfFxZliT85mNhdiVBms6Xplxrz63et5BiRgMjvOQ1XYtXWe86XIZ3IOcoeq6xsJEfMW1CUofICyCYhO4kT-05nQG9RLMQPnxW2-Jn4Tb-cFGtb7ReTYv1hmrO-JsPsQDYgzsioLcj2D0C8y0qBbny2kGMCV7vqigMJ3ZPafAWG6z_Jmkkl6Kl3ZWVEW1TJrmRjolmefvv58JqUyKo3dCPNvtSwcEV2Y_KPDv4iS68RufellbBV_niW5",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GUloA-btxLwOfy2aZ-5JcRa1FMc98XLhW4WH5Yev3P8TN8q8PfD9KH7XUyWzOwwdR4duFlpNGaNJxRmfs6hBEnGtgFdkfFUMKIyJq4M-PM5fmKa6niUElBHU7QClDkZtfKj1gJdB4JiySAetLvqPFXTejpy7v337XfVmDPvjZwoosbdlsHgC3Y0QyqZ47tsPFN1kHRya2jbXOtpn9zjkDOcws5k-dqd2UlGqR6DUqxrtOFLwUZl_GcakCm7RRI429s9yur-mdCotOt2bT01mOcdi3Lq6adEI-xJQ0iT70RyokBdC6Lq0Alvvqq5Jx-idbZNOXDAt-drpbfg_QAaOqIqEcGKlt3vCcsZeZX9-utzlLduqVGlnkqHh9dQuuyOaVnZhOCGu_MtlxbqG0PMfP3-HqGBZFKtliwbu4lJ6uP6N4_SLMpEEH14rAUnPVITraI4vooUF4hhCfib-6TDpEoMJVvmY8KRMAsO6iqw2-IThqxrzMcm8WR1Ds9eeUh1CsmWenBbBakDtuqWtjloE0OLf8E2KeetiswJbs18-Hf9tpJcIrVzlNf3HILVPq01jU3uG5Z5YeKCQtj66043lzZ7OEqljPV1jPCzrXTIHkUaMgs1YIP7JQwj7A2flVBvxP9rLjgQdHn-QdXr3Fd3GgZpYLzhb0h4EXls9bnBYYu0GassYO4NvhT5zpGBcVHEE_M0nQGQCvOEsEOSWwLtuTK5xMEdo7wBDOkdOYGNBfD_Q1h3ayFs2KdH7iVbz9mFwEfIbBVQNx2oN2YmdXwNl8QSYA43TjIFFVDslnSvzCBkLCqAKlxBstMGJ_vzr27K_8uzflSUeyfOfFxZliT85mNhdiVBms6Xplxrz63et5BiRgMjvOQ1XYtXWe86XIZ3IOcoeq6xsJEfMW1CUofICyCYhO4kT-05nQG9RLMQPnxW2-Jn4Tb-cFGtb7ReTYv1hmrO-JsPsQDYgzsioLcj2D0C8y0qBbny2kGMCV7vqigMJ3ZPafAWG6z_Jmkkl6Kl3ZWVEW1TJrmRjolmefvv58JqUyKo3dCPNvtSwcEV2Y_KPDv4iS68RufellbBV_niW5",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1014.8,
            "totalPrice": 821
          },
          {
            "allPassengerTotalPrice": 822,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GApP-J-xKGQr_-I118G1VE-OCh9sdUpRZfaxYYXyeC3PZ06afuQVMGZGZSDdeXLc-tH3lbloL6bKQtRVEdkcC-AcVUE2BgR5brV-L5SLqfotAa9UTpJqzy7ly0HpC6DjtXKBtrvJT5YJ6-c0OnHWgea-fqVIkmlsGQRW6AaxxD-Ha1nahQpVkUhgY5aCXt7iu-YurBr6sDcuP2JKHXPDQGH_81ATUyMlW4Zzlois53PCJF2roZPggBaI17XI8BdoU7v8jpn-o5JWtmyW2yjOUMVMt0u4TR_jIO2NZyL8ilues39mty-LcbPlfGKJbawVMhHlBi7TVSR-IJRlNdVQFmUoYZ1RcnOuHwfm-LTVkJtiLqFhsK8qh0h2KdeoTtBxtb8oWMGjj1PPiomuHQhyMYJRvgg8aPvHhzXU4ks7wqSmPC2hKC5WU6jSzwg9RRjbACvSSDaPdzT-SZneg9LesP1-NOP7M3X8MLr2pBfIJLoNXj5JAr7GVHQKcVTqu4Ka4JFjKDt6dHmY4VIeVeAVnBdtgc7K540x4wF76e2u61gjsCOZkuEb_evLJX6oGi_Huj1ss0hR4GNycm80JxEAYvgbha_e1I_oaJ7Ixi5nmqhF6vLR5OUCIaVjWxBZdxY8PyadX0O81Afj-GXnrvpDxJnQYIAYdM1F0QagmITn2ymGEX_AP0RYfZoVTmwJkWjJg7b_EpxmanOvx4MvZouqILYDPHy7tM5gbPn4FGH6FhDtJJFISBTRH_mj65fZ0kZSF6FheKhAPJi8DrWtCXhsFAVIbONkUOv5ju_KWrMbzAIc0zm8wMjCNDfTYpTutXh7neSXwz1RyljWRsGnugrvHEGTCPNylqnMC6wQpTwW6D5F6PKAGRLLQ0WpUvQQ5nkXkwuJ9W50g92eBgb0bMNIRP6n-r0IVuBOT7hdR-Mou9FXevAAqdFTgutbTxGLAovN_aIwSNHwakCoS9iWFPf0XNAevtIld5LwsBKTIfeSd9OTglb8-6tltmTm9HNrgXCyjzLhtkl-ULojKODjOlKVddUR0QMzhyesR6fZetQyTvvZ0rOeJV1xCr9imdVMYGEF4UkF1j1vBNZ_gl_r_T_scPQabRW6kWe-U-SjpVLKDzv6WR4TnCBVxZOcWjvcKWwK9B-diiyRLOB8n0p4njo5zU8NCMtpIqn1HB_kk5LWvoto5fY1UT6rbwpDAFWnE4nT3XZDFujE4EFsIeLgYmfABhg==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GApP-J-xKGQr_-I118G1VE-OCh9sdUpRZfaxYYXyeC3PZ06afuQVMGZGZSDdeXLc-tH3lbloL6bKQtRVEdkcC-AcVUE2BgR5brV-L5SLqfotAa9UTpJqzy7ly0HpC6DjtXKBtrvJT5YJ6-c0OnHWgea-fqVIkmlsGQRW6AaxxD-Ha1nahQpVkUhgY5aCXt7iu-YurBr6sDcuP2JKHXPDQGH_81ATUyMlW4Zzlois53PCJF2roZPggBaI17XI8BdoU7v8jpn-o5JWtmyW2yjOUMVMt0u4TR_jIO2NZyL8ilues39mty-LcbPlfGKJbawVMhHlBi7TVSR-IJRlNdVQFmUoYZ1RcnOuHwfm-LTVkJtiLqFhsK8qh0h2KdeoTtBxtb8oWMGjj1PPiomuHQhyMYJRvgg8aPvHhzXU4ks7wqSmPC2hKC5WU6jSzwg9RRjbACvSSDaPdzT-SZneg9LesP1-NOP7M3X8MLr2pBfIJLoNXj5JAr7GVHQKcVTqu4Ka4JFjKDt6dHmY4VIeVeAVnBdtgc7K540x4wF76e2u61gjsCOZkuEb_evLJX6oGi_Huj1ss0hR4GNycm80JxEAYvgbha_e1I_oaJ7Ixi5nmqhF6vLR5OUCIaVjWxBZdxY8PyadX0O81Afj-GXnrvpDxJnQYIAYdM1F0QagmITn2ymGEX_AP0RYfZoVTmwJkWjJg7b_EpxmanOvx4MvZouqILYDPHy7tM5gbPn4FGH6FhDtJJFISBTRH_mj65fZ0kZSF6FheKhAPJi8DrWtCXhsFAVIbONkUOv5ju_KWrMbzAIc0zm8wMjCNDfTYpTutXh7neSXwz1RyljWRsGnugrvHEGTCPNylqnMC6wQpTwW6D5F6PKAGRLLQ0WpUvQQ5nkXkwuJ9W50g92eBgb0bMNIRP6n-r0IVuBOT7hdR-Mou9FXevAAqdFTgutbTxGLAovN_aIwSNHwakCoS9iWFPf0XNAevtIld5LwsBKTIfeSd9OTglb8-6tltmTm9HNrgXCyjzLhtkl-ULojKODjOlKVddUR0QMzhyesR6fZetQyTvvZ0rOeJV1xCr9imdVMYGEF4UkF1j1vBNZ_gl_r_T_scPQabRW6kWe-U-SjpVLKDzv6WR4TnCBVxZOcWjvcKWwK9B-diiyRLOB8n0p4njo5zU8NCMtpIqn1HB_kk5LWvoto5fY1UT6rbwpDAFWnE4nT3XZDFujE4EFsIeLgYmfABhg==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GApP-J-xKGQr_-I118G1VE-OCh9sdUpRZfaxYYXyeC3PZ06afuQVMGZGZSDdeXLc-tH3lbloL6bKQtRVEdkcC-AcVUE2BgR5brV-L5SLqfotAa9UTpJqzy7ly0HpC6DjtXKBtrvJT5YJ6-c0OnHWgea-fqVIkmlsGQRW6AaxxD-Ha1nahQpVkUhgY5aCXt7iu-YurBr6sDcuP2JKHXPDQGH_81ATUyMlW4Zzlois53PCJF2roZPggBaI17XI8BdoU7v8jpn-o5JWtmyW2yjOUMVMt0u4TR_jIO2NZyL8ilues39mty-LcbPlfGKJbawVMhHlBi7TVSR-IJRlNdVQFmUoYZ1RcnOuHwfm-LTVkJtiLqFhsK8qh0h2KdeoTtBxtb8oWMGjj1PPiomuHQhyMYJRvgg8aPvHhzXU4ks7wqSmPC2hKC5WU6jSzwg9RRjbACvSSDaPdzT-SZneg9LesP1-NOP7M3X8MLr2pBfIJLoNXj5JAr7GVHQKcVTqu4Ka4JFjKDt6dHmY4VIeVeAVnBdtgc7K540x4wF76e2u61gjsCOZkuEb_evLJX6oGi_Huj1ss0hR4GNycm80JxEAYvgbha_e1I_oaJ7Ixi5nmqhF6vLR5OUCIaVjWxBZdxY8PyadX0O81Afj-GXnrvpDxJnQYIAYdM1F0QagmITn2ymGEX_AP0RYfZoVTmwJkWjJg7b_EpxmanOvx4MvZouqILYDPHy7tM5gbPn4FGH6FhDtJJFISBTRH_mj65fZ0kZSF6FheKhAPJi8DrWtCXhsFAVIbONkUOv5ju_KWrMbzAIc0zm8wMjCNDfTYpTutXh7neSXwz1RyljWRsGnugrvHEGTCPNylqnMC6wQpTwW6D5F6PKAGRLLQ0WpUvQQ5nkXkwuJ9W50g92eBgb0bMNIRP6n-r0IVuBOT7hdR-Mou9FXevAAqdFTgutbTxGLAovN_aIwSNHwakCoS9iWFPf0XNAevtIld5LwsBKTIfeSd9OTglb8-6tltmTm9HNrgXCyjzLhtkl-ULojKODjOlKVddUR0QMzhyesR6fZetQyTvvZ0rOeJV1xCr9imdVMYGEF4UkF1j1vBNZ_gl_r_T_scPQabRW6kWe-U-SjpVLKDzv6WR4TnCBVxZOcWjvcKWwK9B-diiyRLOB8n0p4njo5zU8NCMtpIqn1HB_kk5LWvoto5fY1UT6rbwpDAFWnE4nT3XZDFujE4EFsIeLgYmfABhg==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1042.98,
            "totalPrice": 822
          },
          {
            "allPassengerTotalPrice": 822,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GleWXXDbYOnS08yGvGQjiBDNn5AM9urJKyfUCrt1lseZS1CCNVfEZ1AHmqWmiEdsXO-A1qzro2zvTRqckXwJ_YqKXxCS_6HiJ9zPpcloEXy8FP-SeEOI73SpBxhX9ETFMStOgjuQu9oZwXs5jn-vdX1L2QOoHT47J_0534KBVqTKGsPkEQune2j16-fn4lhCnlKGN80_58cdIST5rOxjIkL8oHdmPHFoqAq3tWJChl0xFAMrdLH_eAjhnJSLvn-UPo1rK1OsPYFkU2uqBwult5ZMCLEfMStjVLXGEo9XwVpFKm4jm4T-_Vs6WGVVoyprvyl0Ib5Q3Qfq6xCAuFsVOYDqhdo0RR-B0ZIJx5fNzTIfovLUGGtV3P99MmPL5UDxi9LRAldgiAF8nfrXtElaUz3QHeMmTWMm0WFWi47bk98DhdqfzM0gvZ4tvzVorGuGM1-PRLQ49vcwulRqq3VPAjKE9VM7s6WyOga_9uxoWmVRQeuqgS987UExWgMJoI_37VmSckx8Ou-pABOvM0rswmRifAc6dZQlKg2_zx0aYNCnZ9iXWV9ud7YiLw_Fu5n5xPzk_PLgF1Uji-MJp6qhQ-15q9YWip7Hue_qWOC_4rQbBjVznWQnUNPT8EMVa-TyPEExo4ycjbuaJqhw2PY58VygmXqHw1_4_a_t3QMSnsbO7t2-2g7i5IRAx3e4Rfp6iXB-7KR5nkZgBnGJFT-3Aj2kq9ICrWnl5sE-hnv196ghKrDPdNMjfrrlioFAXFx6YoUpazwnur0YIR-ckDEug3TPD8Jie1bhYw4sBhrXVX5LTkjsVKbaRTyziln7wTrprxJJkrhIvf-fOUHzr-_JHUh9vG_p_p4L73HfNZqVxnZK-1oKUi25EmAunbl-Qc-6ZFZWDWVpTmD5yLVsi3wVNNAUkm_BwpX7LIk4JRjQ2JFPdCHvy1bbkNdkiBjajtAeqpe3U0eIHNRLbSmjg0tfYP8D1NEj1BpplFI6k_DGUDW8wCEK4HWWy454EyLZQZIkBYonVfCinesQEdia3c_KQZr-NJBV8tq6gKeMUKDVMmA4o5SVsQwfDubhuKagp8pzi1Xr70cT0uH9BYg06sBO_pGT_2_a5oyf9aJOd-Ya9ysUSWag4W6G10n3ykaGRuP9cZ_OUtQC4Vbn7Mn9u6XqPs_W92jlgpt7Rb9OYcmKBcEhdTeUfqYxya9GkGW8Gdy3q",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GleWXXDbYOnS08yGvGQjiBDNn5AM9urJKyfUCrt1lseZS1CCNVfEZ1AHmqWmiEdsXO-A1qzro2zvTRqckXwJ_YqKXxCS_6HiJ9zPpcloEXy8FP-SeEOI73SpBxhX9ETFMStOgjuQu9oZwXs5jn-vdX1L2QOoHT47J_0534KBVqTKGsPkEQune2j16-fn4lhCnlKGN80_58cdIST5rOxjIkL8oHdmPHFoqAq3tWJChl0xFAMrdLH_eAjhnJSLvn-UPo1rK1OsPYFkU2uqBwult5ZMCLEfMStjVLXGEo9XwVpFKm4jm4T-_Vs6WGVVoyprvyl0Ib5Q3Qfq6xCAuFsVOYDqhdo0RR-B0ZIJx5fNzTIfovLUGGtV3P99MmPL5UDxi9LRAldgiAF8nfrXtElaUz3QHeMmTWMm0WFWi47bk98DhdqfzM0gvZ4tvzVorGuGM1-PRLQ49vcwulRqq3VPAjKE9VM7s6WyOga_9uxoWmVRQeuqgS987UExWgMJoI_37VmSckx8Ou-pABOvM0rswmRifAc6dZQlKg2_zx0aYNCnZ9iXWV9ud7YiLw_Fu5n5xPzk_PLgF1Uji-MJp6qhQ-15q9YWip7Hue_qWOC_4rQbBjVznWQnUNPT8EMVa-TyPEExo4ycjbuaJqhw2PY58VygmXqHw1_4_a_t3QMSnsbO7t2-2g7i5IRAx3e4Rfp6iXB-7KR5nkZgBnGJFT-3Aj2kq9ICrWnl5sE-hnv196ghKrDPdNMjfrrlioFAXFx6YoUpazwnur0YIR-ckDEug3TPD8Jie1bhYw4sBhrXVX5LTkjsVKbaRTyziln7wTrprxJJkrhIvf-fOUHzr-_JHUh9vG_p_p4L73HfNZqVxnZK-1oKUi25EmAunbl-Qc-6ZFZWDWVpTmD5yLVsi3wVNNAUkm_BwpX7LIk4JRjQ2JFPdCHvy1bbkNdkiBjajtAeqpe3U0eIHNRLbSmjg0tfYP8D1NEj1BpplFI6k_DGUDW8wCEK4HWWy454EyLZQZIkBYonVfCinesQEdia3c_KQZr-NJBV8tq6gKeMUKDVMmA4o5SVsQwfDubhuKagp8pzi1Xr70cT0uH9BYg06sBO_pGT_2_a5oyf9aJOd-Ya9ysUSWag4W6G10n3ykaGRuP9cZ_OUtQC4Vbn7Mn9u6XqPs_W92jlgpt7Rb9OYcmKBcEhdTeUfqYxya9GkGW8Gdy3q",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GleWXXDbYOnS08yGvGQjiBDNn5AM9urJKyfUCrt1lseZS1CCNVfEZ1AHmqWmiEdsXO-A1qzro2zvTRqckXwJ_YqKXxCS_6HiJ9zPpcloEXy8FP-SeEOI73SpBxhX9ETFMStOgjuQu9oZwXs5jn-vdX1L2QOoHT47J_0534KBVqTKGsPkEQune2j16-fn4lhCnlKGN80_58cdIST5rOxjIkL8oHdmPHFoqAq3tWJChl0xFAMrdLH_eAjhnJSLvn-UPo1rK1OsPYFkU2uqBwult5ZMCLEfMStjVLXGEo9XwVpFKm4jm4T-_Vs6WGVVoyprvyl0Ib5Q3Qfq6xCAuFsVOYDqhdo0RR-B0ZIJx5fNzTIfovLUGGtV3P99MmPL5UDxi9LRAldgiAF8nfrXtElaUz3QHeMmTWMm0WFWi47bk98DhdqfzM0gvZ4tvzVorGuGM1-PRLQ49vcwulRqq3VPAjKE9VM7s6WyOga_9uxoWmVRQeuqgS987UExWgMJoI_37VmSckx8Ou-pABOvM0rswmRifAc6dZQlKg2_zx0aYNCnZ9iXWV9ud7YiLw_Fu5n5xPzk_PLgF1Uji-MJp6qhQ-15q9YWip7Hue_qWOC_4rQbBjVznWQnUNPT8EMVa-TyPEExo4ycjbuaJqhw2PY58VygmXqHw1_4_a_t3QMSnsbO7t2-2g7i5IRAx3e4Rfp6iXB-7KR5nkZgBnGJFT-3Aj2kq9ICrWnl5sE-hnv196ghKrDPdNMjfrrlioFAXFx6YoUpazwnur0YIR-ckDEug3TPD8Jie1bhYw4sBhrXVX5LTkjsVKbaRTyziln7wTrprxJJkrhIvf-fOUHzr-_JHUh9vG_p_p4L73HfNZqVxnZK-1oKUi25EmAunbl-Qc-6ZFZWDWVpTmD5yLVsi3wVNNAUkm_BwpX7LIk4JRjQ2JFPdCHvy1bbkNdkiBjajtAeqpe3U0eIHNRLbSmjg0tfYP8D1NEj1BpplFI6k_DGUDW8wCEK4HWWy454EyLZQZIkBYonVfCinesQEdia3c_KQZr-NJBV8tq6gKeMUKDVMmA4o5SVsQwfDubhuKagp8pzi1Xr70cT0uH9BYg06sBO_pGT_2_a5oyf9aJOd-Ya9ysUSWag4W6G10n3ykaGRuP9cZ_OUtQC4Vbn7Mn9u6XqPs_W92jlgpt7Rb9OYcmKBcEhdTeUfqYxya9GkGW8Gdy3q",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1069.65,
            "totalPrice": 822
          },
          {
            "allPassengerTotalPrice": 822,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141413a24e240000e54a93b3_0%7C13a20a224e2500001b94c8df_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQcJsrSxwaDWGqgEm2qs6Wgvex3kDaQ0W0zf5W8x8VFT7B1kgmaOzNdysQzU7WMVuEkaGfKoCkKh8ZcfB3GrSkOzpjnGH6bnAECKw27fxWHcfLP3aVXNy7Hv5sRzygUJCcE2mKud_01eLQd1yXM4WrIrhpBE4wpXPB4zyDGvU2RlevqnEnmJr64IFNvTipFDhG0L-moLr8capWUzqhmAV61nmc5N0gIr3hoeB1F_CH4QsLmrFtLlelE4fnV24Ql8UZGbTFJnjIzfvW23zvfWEt_hlIEN0I1m-WZn44J812WwJygOFKXeMEFf3dcV6BZZ2rhctMx_zty5CDd8TEl_s50ryq6xXvbf3ojxudMlL1A8zWxGXKavqSTY-u7PGA0VNgNrvvWRLmqTFs2pBQ8RAmyGZIIqB-o462BmMpqai_oCnyrqbIZEwnHRiXkaXyK_zKf7EsK7nXouszV78kHNlGuLSHcecSXrsZjNVsL3bQeyY2N_TnN-Qs23ZG7uzYV71uvkugtjnTQRd_YB2KhwCh_m3me-qCbEFPHALzWePVFc5SH37F3KHcdkXq-GRJRN2Tmrkov_3O1WTFIvqf1vWoO1AkyXx7_mGHozIMKeWxPgFu3Zu0CMY2BhseDPi7ybqqlHBnhapQbVn901-cyx71u0om_A4lBbYAFVCEM6BohniU9L2EPhnsU3mogI02QuZECRb-oqOtItEbjr8nJ69uHU3xBNJJJimekr9YmGbRFAdbN--1BVQv_AGR_TT_3_U6bASBuvcZitoaJhUixZJx9_E4OEb8AP1snMEPCmMqnDH4vtr1mpdMY4qT0jeu-nq5ivYIi1CL_6kFN4AMuBXQbRd4AFhudQikoFHqiCF9O3F8dGPfjWWW11xFNQGoDLFQzU_oaYjD7GWl_N6Y3TGZmI27syxGb_UEXP2MV6__A-iDenK274i_QD29JrASXYgS31G63hJ-xx0PbQ__ZS2zfitoaK2sQ2QIaHgG6B-l6l4K5V2-_WK3zVWOF8eIEhT_t5_jU4P3YEbtje4FT1QViewJW4Nib1Y32PKcNEBJEWkiH2uD4uNFuaXUcHvYvsZdxIR4kL_Nw5IFxp8BWZfHRUpFqHkXK1KATe0niU4OX3o1BdC-BthyQC8z593fBFw",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "I2"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "07:40",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141413a24e240000e54a93b3_0%7C13a20a224e2500001b94c8df_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQcJsrSxwaDWGqgEm2qs6Wgvex3kDaQ0W0zf5W8x8VFT7B1kgmaOzNdysQzU7WMVuEkaGfKoCkKh8ZcfB3GrSkOzpjnGH6bnAECKw27fxWHcfLP3aVXNy7Hv5sRzygUJCcE2mKud_01eLQd1yXM4WrIrhpBE4wpXPB4zyDGvU2RlevqnEnmJr64IFNvTipFDhG0L-moLr8capWUzqhmAV61nmc5N0gIr3hoeB1F_CH4QsLmrFtLlelE4fnV24Ql8UZGbTFJnjIzfvW23zvfWEt_hlIEN0I1m-WZn44J812WwJygOFKXeMEFf3dcV6BZZ2rhctMx_zty5CDd8TEl_s50ryq6xXvbf3ojxudMlL1A8zWxGXKavqSTY-u7PGA0VNgNrvvWRLmqTFs2pBQ8RAmyGZIIqB-o462BmMpqai_oCnyrqbIZEwnHRiXkaXyK_zKf7EsK7nXouszV78kHNlGuLSHcecSXrsZjNVsL3bQeyY2N_TnN-Qs23ZG7uzYV71uvkugtjnTQRd_YB2KhwCh_m3me-qCbEFPHALzWePVFc5SH37F3KHcdkXq-GRJRN2Tmrkov_3O1WTFIvqf1vWoO1AkyXx7_mGHozIMKeWxPgFu3Zu0CMY2BhseDPi7ybqqlHBnhapQbVn901-cyx71u0om_A4lBbYAFVCEM6BohniU9L2EPhnsU3mogI02QuZECRb-oqOtItEbjr8nJ69uHU3xBNJJJimekr9YmGbRFAdbN--1BVQv_AGR_TT_3_U6bASBuvcZitoaJhUixZJx9_E4OEb8AP1snMEPCmMqnDH4vtr1mpdMY4qT0jeu-nq5ivYIi1CL_6kFN4AMuBXQbRd4AFhudQikoFHqiCF9O3F8dGPfjWWW11xFNQGoDLFQzU_oaYjD7GWl_N6Y3TGZmI27syxGb_UEXP2MV6__A-iDenK274i_QD29JrASXYgS31G63hJ-xx0PbQ__ZS2zfitoaK2sQ2QIaHgG6B-l6l4K5V2-_WK3zVWOF8eIEhT_t5_jU4P3YEbtje4FT1QViewJW4Nib1Y32PKcNEBJEWkiH2uD4uNFuaXUcHvYvsZdxIR4kL_Nw5IFxp8BWZfHRUpFqHkXK1KATe0niU4OX3o1BdC-BthyQC8z593fBFw",
                "dateChange": 1,
                "departureDate": "2024-10-08",
                "departureTime": "22:05",
                "destination": "MAD",
                "duration": "9h35m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "PMI",
                    "cityFrom": "Málaga",
                    "cityTo": "Palma, Majorca",
                    "combination_id": "141413a24e240000e54a93b3",
                    "equipment": null,
                    "fare_basis": "DCOWLPB",
                    "fare_category": "M",
                    "fare_classes": "D",
                    "flight_no": 3150,
                    "flyFrom": "AGP",
                    "flyTo": "PMI",
                    "guarantee": false,
                    "id": "141413a24e240000e54a93b3_0",
                    "local_arrival": "2024-10-08T23:35:00.000Z",
                    "local_departure": "2024-10-08T22:05:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "3150",
                    "return": 0,
                    "utc_arrival": "2024-10-08T21:35:00.000Z",
                    "utc_departure": "2024-10-08T20:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "I2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "PMI",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Palma, Majorca",
                    "cityTo": "Madrid",
                    "combination_id": "13a20a224e2500001b94c8df",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3917,
                    "flyFrom": "PMI",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "13a20a224e2500001b94c8df_0",
                    "local_arrival": "2024-10-09T07:40:00.000Z",
                    "local_departure": "2024-10-09T06:15:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3917",
                    "return": 0,
                    "utc_arrival": "2024-10-09T05:40:00.000Z",
                    "utc_departure": "2024-10-09T04:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141413a24e240000e54a93b3_0%7C13a20a224e2500001b94c8df_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQcJsrSxwaDWGqgEm2qs6Wgvex3kDaQ0W0zf5W8x8VFT7B1kgmaOzNdysQzU7WMVuEkaGfKoCkKh8ZcfB3GrSkOzpjnGH6bnAECKw27fxWHcfLP3aVXNy7Hv5sRzygUJCcE2mKud_01eLQd1yXM4WrIrhpBE4wpXPB4zyDGvU2RlevqnEnmJr64IFNvTipFDhG0L-moLr8capWUzqhmAV61nmc5N0gIr3hoeB1F_CH4QsLmrFtLlelE4fnV24Ql8UZGbTFJnjIzfvW23zvfWEt_hlIEN0I1m-WZn44J812WwJygOFKXeMEFf3dcV6BZZ2rhctMx_zty5CDd8TEl_s50ryq6xXvbf3ojxudMlL1A8zWxGXKavqSTY-u7PGA0VNgNrvvWRLmqTFs2pBQ8RAmyGZIIqB-o462BmMpqai_oCnyrqbIZEwnHRiXkaXyK_zKf7EsK7nXouszV78kHNlGuLSHcecSXrsZjNVsL3bQeyY2N_TnN-Qs23ZG7uzYV71uvkugtjnTQRd_YB2KhwCh_m3me-qCbEFPHALzWePVFc5SH37F3KHcdkXq-GRJRN2Tmrkov_3O1WTFIvqf1vWoO1AkyXx7_mGHozIMKeWxPgFu3Zu0CMY2BhseDPi7ybqqlHBnhapQbVn901-cyx71u0om_A4lBbYAFVCEM6BohniU9L2EPhnsU3mogI02QuZECRb-oqOtItEbjr8nJ69uHU3xBNJJJimekr9YmGbRFAdbN--1BVQv_AGR_TT_3_U6bASBuvcZitoaJhUixZJx9_E4OEb8AP1snMEPCmMqnDH4vtr1mpdMY4qT0jeu-nq5ivYIi1CL_6kFN4AMuBXQbRd4AFhudQikoFHqiCF9O3F8dGPfjWWW11xFNQGoDLFQzU_oaYjD7GWl_N6Y3TGZmI27syxGb_UEXP2MV6__A-iDenK274i_QD29JrASXYgS31G63hJ-xx0PbQ__ZS2zfitoaK2sQ2QIaHgG6B-l6l4K5V2-_WK3zVWOF8eIEhT_t5_jU4P3YEbtje4FT1QViewJW4Nib1Y32PKcNEBJEWkiH2uD4uNFuaXUcHvYvsZdxIR4kL_Nw5IFxp8BWZfHRUpFqHkXK1KATe0niU4OX3o1BdC-BthyQC8z593fBFw",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1082.01,
            "totalPrice": 822
          },
          {
            "allPassengerTotalPrice": 823,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCtxYZt9nCWaPK_o04k3oO3Orky-2V17jnEn6pu-_NkwS4fUHHO2-l0LUx8Jqkhogo-HEaltS5dyDUfkau8qUsXc3OQYnbCHAiGraK_I6xOIolRTx8h0JyRMKEhbvhiqzNPHDmwhXytXXdXGD-SfUHGZ36snw1UdHZpxaNlOSgjo08uhQYNBREPxT9Vs_d7gUCv-JB7jm5Pim1kOk0VZBfNOAIJrfWEe5sd2hHYLEOQTPEmrs5YIFuL4lege8ADu2Q3UA_21jmuiQRjkCYuiUnyPcRTQdMUtZ66ysbJgTADrB5tCc_juFwFiC-xNsZa_sac1T37R5Hv-2fij6yNazKt1nETDcwCpOB8aEK3fqQjHhAhMXrNnjiDPzULZWl7hgI66SQosPxHY9M9GGZv6P_FrWZKgRRx6bA4X64gQqRzUeMAzEVQyvDQkXZUdphE9BltEteQcLN7Mbix-tSy9N8anbpGHWd6BXptIEPB6bMneHIxxM77HSU3n8c9XI4eRLsenZTpqsBn48E1N9QbWf0Up-Nhr7lcpTAlIdEuRL7wPLH3wnxb3oIOY_AyCDK1HXqqDT8Pd2ZaWo1-X3v7QlsR1YkgeP5s2-Oz5ar3oChLXszRrLFC7ejgFXHfmH1dLzYjmiHR4zD4Fr1-T0GJjtl4Yxh3nAiIr4nJu7k-1ibghzVNnfryHXnGrj8uD-oAPOxjKJbgWVxmANR7QfAUIvSx9c1P2yQBVBCcClLQkoVzqqeHGdxU_2kYPBiovRz5Ovn_vVrPVak-cmV81okV0-hAGq3KX_VLue78XztbMSg7KVIxtNQ_yssYWtJtojwdgyEfrsFOAmtD2tjNMvJ0g7h5h0LbPwcTfLjwdMp9DD8dXmzdH4J4sEzbqGo_uyEd1OFjKihf61LOKDXpA9wxUHI7pR9Dq3W95BzmLOYYCKZfFTwz_HAm1_G7uQNFz6GZZp8gJiMiNtOa7TTqCm1p0sXcmFVHPTAQCQ4AH37ASXgTP2gYCgvNo5HpcaANFSZxLo2WpEaprsZ2kD12U4sTTGCYCEr7z11-YZdEKbtuUHtSDnZxAEtCEmrkonc9mjUVv9cIqsn4EoyxvnPi9yR4KzqA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCtxYZt9nCWaPK_o04k3oO3Orky-2V17jnEn6pu-_NkwS4fUHHO2-l0LUx8Jqkhogo-HEaltS5dyDUfkau8qUsXc3OQYnbCHAiGraK_I6xOIolRTx8h0JyRMKEhbvhiqzNPHDmwhXytXXdXGD-SfUHGZ36snw1UdHZpxaNlOSgjo08uhQYNBREPxT9Vs_d7gUCv-JB7jm5Pim1kOk0VZBfNOAIJrfWEe5sd2hHYLEOQTPEmrs5YIFuL4lege8ADu2Q3UA_21jmuiQRjkCYuiUnyPcRTQdMUtZ66ysbJgTADrB5tCc_juFwFiC-xNsZa_sac1T37R5Hv-2fij6yNazKt1nETDcwCpOB8aEK3fqQjHhAhMXrNnjiDPzULZWl7hgI66SQosPxHY9M9GGZv6P_FrWZKgRRx6bA4X64gQqRzUeMAzEVQyvDQkXZUdphE9BltEteQcLN7Mbix-tSy9N8anbpGHWd6BXptIEPB6bMneHIxxM77HSU3n8c9XI4eRLsenZTpqsBn48E1N9QbWf0Up-Nhr7lcpTAlIdEuRL7wPLH3wnxb3oIOY_AyCDK1HXqqDT8Pd2ZaWo1-X3v7QlsR1YkgeP5s2-Oz5ar3oChLXszRrLFC7ejgFXHfmH1dLzYjmiHR4zD4Fr1-T0GJjtl4Yxh3nAiIr4nJu7k-1ibghzVNnfryHXnGrj8uD-oAPOxjKJbgWVxmANR7QfAUIvSx9c1P2yQBVBCcClLQkoVzqqeHGdxU_2kYPBiovRz5Ovn_vVrPVak-cmV81okV0-hAGq3KX_VLue78XztbMSg7KVIxtNQ_yssYWtJtojwdgyEfrsFOAmtD2tjNMvJ0g7h5h0LbPwcTfLjwdMp9DD8dXmzdH4J4sEzbqGo_uyEd1OFjKihf61LOKDXpA9wxUHI7pR9Dq3W95BzmLOYYCKZfFTwz_HAm1_G7uQNFz6GZZp8gJiMiNtOa7TTqCm1p0sXcmFVHPTAQCQ4AH37ASXgTP2gYCgvNo5HpcaANFSZxLo2WpEaprsZ2kD12U4sTTGCYCEr7z11-YZdEKbtuUHtSDnZxAEtCEmrkonc9mjUVv9cIqsn4EoyxvnPi9yR4KzqA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GCtxYZt9nCWaPK_o04k3oO3Orky-2V17jnEn6pu-_NkwS4fUHHO2-l0LUx8Jqkhogo-HEaltS5dyDUfkau8qUsXc3OQYnbCHAiGraK_I6xOIolRTx8h0JyRMKEhbvhiqzNPHDmwhXytXXdXGD-SfUHGZ36snw1UdHZpxaNlOSgjo08uhQYNBREPxT9Vs_d7gUCv-JB7jm5Pim1kOk0VZBfNOAIJrfWEe5sd2hHYLEOQTPEmrs5YIFuL4lege8ADu2Q3UA_21jmuiQRjkCYuiUnyPcRTQdMUtZ66ysbJgTADrB5tCc_juFwFiC-xNsZa_sac1T37R5Hv-2fij6yNazKt1nETDcwCpOB8aEK3fqQjHhAhMXrNnjiDPzULZWl7hgI66SQosPxHY9M9GGZv6P_FrWZKgRRx6bA4X64gQqRzUeMAzEVQyvDQkXZUdphE9BltEteQcLN7Mbix-tSy9N8anbpGHWd6BXptIEPB6bMneHIxxM77HSU3n8c9XI4eRLsenZTpqsBn48E1N9QbWf0Up-Nhr7lcpTAlIdEuRL7wPLH3wnxb3oIOY_AyCDK1HXqqDT8Pd2ZaWo1-X3v7QlsR1YkgeP5s2-Oz5ar3oChLXszRrLFC7ejgFXHfmH1dLzYjmiHR4zD4Fr1-T0GJjtl4Yxh3nAiIr4nJu7k-1ibghzVNnfryHXnGrj8uD-oAPOxjKJbgWVxmANR7QfAUIvSx9c1P2yQBVBCcClLQkoVzqqeHGdxU_2kYPBiovRz5Ovn_vVrPVak-cmV81okV0-hAGq3KX_VLue78XztbMSg7KVIxtNQ_yssYWtJtojwdgyEfrsFOAmtD2tjNMvJ0g7h5h0LbPwcTfLjwdMp9DD8dXmzdH4J4sEzbqGo_uyEd1OFjKihf61LOKDXpA9wxUHI7pR9Dq3W95BzmLOYYCKZfFTwz_HAm1_G7uQNFz6GZZp8gJiMiNtOa7TTqCm1p0sXcmFVHPTAQCQ4AH37ASXgTP2gYCgvNo5HpcaANFSZxLo2WpEaprsZ2kD12U4sTTGCYCEr7z11-YZdEKbtuUHtSDnZxAEtCEmrkonc9mjUVv9cIqsn4EoyxvnPi9yR4KzqA==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1016.21,
            "totalPrice": 823
          },
          {
            "allPassengerTotalPrice": 833,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIU4FIFXIXUfwNPSYUx7WjbGAGON726lEgYQOyOH9fKKDxmCTAk7oiPJ1toSatjNekKfO7K5LIKtX7i8bAMndgD2tmL6ku9tFVvr3RPrzcGR3xVzSR0RqQ-FPAZuFpBbOW5emB3HEBpdKe8Rhe_ZNcTAzhfUeGGHpsobAgNKox_OBRQcqy9o6OMkvdZ0lBF7pCS7hUweusfCTDspqxl-vIh0d4m1qT5JBgJozPZvYI6T5JYAe-GfXvKIm6ic26UQlGucgTcMb-YET4lE9Fzu8jlvfNQNd_Wufiyhc7OS33p40vXg-qGv-XhP1-SeX0uGIHapt44g1v4qsvz91W1xIfoF6OJU75RZa2J7878tNzzAiAVkA28UhLf6gvoVINUEgu1FolEvEhxrFlKZfIXQz4EzyQTuQd7h9WW6BdeDGclqW6WpIrn2eMwi9XYjThhP_o1KwDB6MksEYWNyxPXwd4Cu2QfCWGCotKUwsg50SxjD8FUcHX1MTiIs8oWTurhuhGUMyAlDLjrpKrlqCADa0qDgqfy0Npn2kEUqkXBgsXpmJ6aDIwfkyA4qqWSbjmZtCm72alfFTCaLyVVxYJKa8uSA5vHV9DCPz845tNzwz-gFqPjfB0NUs0LlMQvMBNNvu3ZCqEdKKbDOs8tEef0RtOSIcHGDgeMyHf-77lbZYEgX0V1uQNopKMMGhaVpWHG8a7rswNjqNN_pSQT_brmbiXNu-8cke-OBezPJHJHlNKEmLgCRyAXz9uPMJismiBjVXwjHYs5t0od5xOhNw3uaRyGgtTBn_GiLj8Bsh5l2W3kL7JyDl6PP7cd08G6lbtimyxWDL0P9zk9KTQ7P8r7FNq0ernwPyFAqWofLUQM3VOgEu5W6xqP8xU9NlQvdQNO9PYp_seZhaUBnRZ3lQ39CxCfjRhronUMQE2jhSeV6thUqL6SSVyGdt6wKPHObsqMNWmGF1asf3PyKqqCgp2h7_cRJcJFd2a6w4CdAwU89dsAu0NSAZ-V4jwVB4Pf0zE_tTRi9xeVgTB6KnbqOydneHKCUt9iWfP5NLma1Hh78lqXmBmnEiz6cF3WJfaNNxTvr0",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIU4FIFXIXUfwNPSYUx7WjbGAGON726lEgYQOyOH9fKKDxmCTAk7oiPJ1toSatjNekKfO7K5LIKtX7i8bAMndgD2tmL6ku9tFVvr3RPrzcGR3xVzSR0RqQ-FPAZuFpBbOW5emB3HEBpdKe8Rhe_ZNcTAzhfUeGGHpsobAgNKox_OBRQcqy9o6OMkvdZ0lBF7pCS7hUweusfCTDspqxl-vIh0d4m1qT5JBgJozPZvYI6T5JYAe-GfXvKIm6ic26UQlGucgTcMb-YET4lE9Fzu8jlvfNQNd_Wufiyhc7OS33p40vXg-qGv-XhP1-SeX0uGIHapt44g1v4qsvz91W1xIfoF6OJU75RZa2J7878tNzzAiAVkA28UhLf6gvoVINUEgu1FolEvEhxrFlKZfIXQz4EzyQTuQd7h9WW6BdeDGclqW6WpIrn2eMwi9XYjThhP_o1KwDB6MksEYWNyxPXwd4Cu2QfCWGCotKUwsg50SxjD8FUcHX1MTiIs8oWTurhuhGUMyAlDLjrpKrlqCADa0qDgqfy0Npn2kEUqkXBgsXpmJ6aDIwfkyA4qqWSbjmZtCm72alfFTCaLyVVxYJKa8uSA5vHV9DCPz845tNzwz-gFqPjfB0NUs0LlMQvMBNNvu3ZCqEdKKbDOs8tEef0RtOSIcHGDgeMyHf-77lbZYEgX0V1uQNopKMMGhaVpWHG8a7rswNjqNN_pSQT_brmbiXNu-8cke-OBezPJHJHlNKEmLgCRyAXz9uPMJismiBjVXwjHYs5t0od5xOhNw3uaRyGgtTBn_GiLj8Bsh5l2W3kL7JyDl6PP7cd08G6lbtimyxWDL0P9zk9KTQ7P8r7FNq0ernwPyFAqWofLUQM3VOgEu5W6xqP8xU9NlQvdQNO9PYp_seZhaUBnRZ3lQ39CxCfjRhronUMQE2jhSeV6thUqL6SSVyGdt6wKPHObsqMNWmGF1asf3PyKqqCgp2h7_cRJcJFd2a6w4CdAwU89dsAu0NSAZ-V4jwVB4Pf0zE_tTRi9xeVgTB6KnbqOydneHKCUt9iWfP5NLma1Hh78lqXmBmnEiz6cF3WJfaNNxTvr0",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GIU4FIFXIXUfwNPSYUx7WjbGAGON726lEgYQOyOH9fKKDxmCTAk7oiPJ1toSatjNekKfO7K5LIKtX7i8bAMndgD2tmL6ku9tFVvr3RPrzcGR3xVzSR0RqQ-FPAZuFpBbOW5emB3HEBpdKe8Rhe_ZNcTAzhfUeGGHpsobAgNKox_OBRQcqy9o6OMkvdZ0lBF7pCS7hUweusfCTDspqxl-vIh0d4m1qT5JBgJozPZvYI6T5JYAe-GfXvKIm6ic26UQlGucgTcMb-YET4lE9Fzu8jlvfNQNd_Wufiyhc7OS33p40vXg-qGv-XhP1-SeX0uGIHapt44g1v4qsvz91W1xIfoF6OJU75RZa2J7878tNzzAiAVkA28UhLf6gvoVINUEgu1FolEvEhxrFlKZfIXQz4EzyQTuQd7h9WW6BdeDGclqW6WpIrn2eMwi9XYjThhP_o1KwDB6MksEYWNyxPXwd4Cu2QfCWGCotKUwsg50SxjD8FUcHX1MTiIs8oWTurhuhGUMyAlDLjrpKrlqCADa0qDgqfy0Npn2kEUqkXBgsXpmJ6aDIwfkyA4qqWSbjmZtCm72alfFTCaLyVVxYJKa8uSA5vHV9DCPz845tNzwz-gFqPjfB0NUs0LlMQvMBNNvu3ZCqEdKKbDOs8tEef0RtOSIcHGDgeMyHf-77lbZYEgX0V1uQNopKMMGhaVpWHG8a7rswNjqNN_pSQT_brmbiXNu-8cke-OBezPJHJHlNKEmLgCRyAXz9uPMJismiBjVXwjHYs5t0od5xOhNw3uaRyGgtTBn_GiLj8Bsh5l2W3kL7JyDl6PP7cd08G6lbtimyxWDL0P9zk9KTQ7P8r7FNq0ernwPyFAqWofLUQM3VOgEu5W6xqP8xU9NlQvdQNO9PYp_seZhaUBnRZ3lQ39CxCfjRhronUMQE2jhSeV6thUqL6SSVyGdt6wKPHObsqMNWmGF1asf3PyKqqCgp2h7_cRJcJFd2a6w4CdAwU89dsAu0NSAZ-V4jwVB4Pf0zE_tTRi9xeVgTB6KnbqOydneHKCUt9iWfP5NLma1Hh78lqXmBmnEiz6cF3WJfaNNxTvr0",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1023.6,
            "totalPrice": 833
          },
          {
            "allPassengerTotalPrice": 840,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GeF6DcQf99XVS0H66VZEn5qlPiOuaPkwKSpLssz24_SQGM9T6oZxc2e6OqVNq7zQfMFl_W84zJjocf24mbgsaSsenfUoTqFcyTFfqJjhyzZJP1OmnkYvePRasXCMsssWjD5zDD7ImkqkrLTQhbk_aPvp-2GD8p-qNbhira2A4SZW_5x0QqYksuD64hNQbrY_cYvWLxmhP63AZ_L0Hl6-XfTmlC80tup6au1d2oB4NBOUWf1PZwoALlTV5xhcpeCndH3Aj77gx3ffK-31pSy-6DJY8bM_b_JYQh1IEm-hFednRocz5TXtagno_MIO2rHA5M0U0dI3jnSmf8z5jjcisVzTID9uQrDe61fFiLlgEit0bM77gwLRzONuvpVvzb-SxE-rEGFGH5nUHksZ7jEVc4-jYhm6dvGaJbhAW5ydPpXHYkDajJ7--AEy0Xr7T6xyH8RUzqT99NItkkQNdfhnqNcNhUEXbeGaHf5jgnQbn8VUN4LsvOUBf8s3FZ4mB-4Q5rG2o5twi697PUcTprocgJgfStKjqat4DrzukbPxlhKvFuyyu8B1VKap6x_ZLks02SX-GI0yTHaU0zzH0A8t0RSUyUn315kWfn13fMNQVPFzgcOBO-lgfYLwuvXAfG6-MD5gsvSnerJb7ILC6bm7lQSAo0UlGJx9AgWIVIC8ImRu4jYNImpYlFfacKV4uso5BwvXEVC7OBp5LT-smN-p_jQsevbO1BeA6MOMl0ybSQIkQdZKk8grwkL4WF0sZJWvKM4he9kVUWXGMPPVQBnhHdMXYLyoHFj9w3qpHMgfqE9UhE9mbSWNfujHuIwh97Jn8TqLLK3-PdakCQ57x0jarL9bQGq-iV7v_uBM-eQNbFEvTUog_nvJSJgv5y-JnMa5wDQk8tlLAEcP2zM5i0bxriJPVunoS66byXYfsM6nfVtlizbH5C03cS12Q_YPV-AGURPjPIPoRbMuApLEHtxDE-iIwteBl1XKJBH80QD2_GJmbdolSjfk-CGFjGy9IgL7XB-PCRRaBLZ8zrxyVNNEZF3Aj1YTWuhXXV9-sYPN5TR1loaTsZzYyeAljOkDx9VFdU2Si9NGeHBR3U8baex2L3ud4Ql9AW-UTcTfUkiwHsVkGUbjyiluDAz5WiLnGgvM9YcmL1gFYw5GJlgqiKUwu532sO6uWA2KsWKIP9r9uzllJs-aD7OK5AJM2F_vT5b29Dqhlpp9Q7URqbPvpsS7tNZXQSLBTlUsp4PKFQEKNsfk=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GeF6DcQf99XVS0H66VZEn5qlPiOuaPkwKSpLssz24_SQGM9T6oZxc2e6OqVNq7zQfMFl_W84zJjocf24mbgsaSsenfUoTqFcyTFfqJjhyzZJP1OmnkYvePRasXCMsssWjD5zDD7ImkqkrLTQhbk_aPvp-2GD8p-qNbhira2A4SZW_5x0QqYksuD64hNQbrY_cYvWLxmhP63AZ_L0Hl6-XfTmlC80tup6au1d2oB4NBOUWf1PZwoALlTV5xhcpeCndH3Aj77gx3ffK-31pSy-6DJY8bM_b_JYQh1IEm-hFednRocz5TXtagno_MIO2rHA5M0U0dI3jnSmf8z5jjcisVzTID9uQrDe61fFiLlgEit0bM77gwLRzONuvpVvzb-SxE-rEGFGH5nUHksZ7jEVc4-jYhm6dvGaJbhAW5ydPpXHYkDajJ7--AEy0Xr7T6xyH8RUzqT99NItkkQNdfhnqNcNhUEXbeGaHf5jgnQbn8VUN4LsvOUBf8s3FZ4mB-4Q5rG2o5twi697PUcTprocgJgfStKjqat4DrzukbPxlhKvFuyyu8B1VKap6x_ZLks02SX-GI0yTHaU0zzH0A8t0RSUyUn315kWfn13fMNQVPFzgcOBO-lgfYLwuvXAfG6-MD5gsvSnerJb7ILC6bm7lQSAo0UlGJx9AgWIVIC8ImRu4jYNImpYlFfacKV4uso5BwvXEVC7OBp5LT-smN-p_jQsevbO1BeA6MOMl0ybSQIkQdZKk8grwkL4WF0sZJWvKM4he9kVUWXGMPPVQBnhHdMXYLyoHFj9w3qpHMgfqE9UhE9mbSWNfujHuIwh97Jn8TqLLK3-PdakCQ57x0jarL9bQGq-iV7v_uBM-eQNbFEvTUog_nvJSJgv5y-JnMa5wDQk8tlLAEcP2zM5i0bxriJPVunoS66byXYfsM6nfVtlizbH5C03cS12Q_YPV-AGURPjPIPoRbMuApLEHtxDE-iIwteBl1XKJBH80QD2_GJmbdolSjfk-CGFjGy9IgL7XB-PCRRaBLZ8zrxyVNNEZF3Aj1YTWuhXXV9-sYPN5TR1loaTsZzYyeAljOkDx9VFdU2Si9NGeHBR3U8baex2L3ud4Ql9AW-UTcTfUkiwHsVkGUbjyiluDAz5WiLnGgvM9YcmL1gFYw5GJlgqiKUwu532sO6uWA2KsWKIP9r9uzllJs-aD7OK5AJM2F_vT5b29Dqhlpp9Q7URqbPvpsS7tNZXQSLBTlUsp4PKFQEKNsfk=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e240000a74c7035",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e240000a74c7035_0",
                    "local_arrival": "2024-10-08T19:20:00.000Z",
                    "local_departure": "2024-10-08T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-08T17:20:00.000Z",
                    "utc_departure": "2024-10-08T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e2400000210c421",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e2400000210c421_0",
                    "local_arrival": "2024-10-08T22:10:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:10:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GeF6DcQf99XVS0H66VZEn5qlPiOuaPkwKSpLssz24_SQGM9T6oZxc2e6OqVNq7zQfMFl_W84zJjocf24mbgsaSsenfUoTqFcyTFfqJjhyzZJP1OmnkYvePRasXCMsssWjD5zDD7ImkqkrLTQhbk_aPvp-2GD8p-qNbhira2A4SZW_5x0QqYksuD64hNQbrY_cYvWLxmhP63AZ_L0Hl6-XfTmlC80tup6au1d2oB4NBOUWf1PZwoALlTV5xhcpeCndH3Aj77gx3ffK-31pSy-6DJY8bM_b_JYQh1IEm-hFednRocz5TXtagno_MIO2rHA5M0U0dI3jnSmf8z5jjcisVzTID9uQrDe61fFiLlgEit0bM77gwLRzONuvpVvzb-SxE-rEGFGH5nUHksZ7jEVc4-jYhm6dvGaJbhAW5ydPpXHYkDajJ7--AEy0Xr7T6xyH8RUzqT99NItkkQNdfhnqNcNhUEXbeGaHf5jgnQbn8VUN4LsvOUBf8s3FZ4mB-4Q5rG2o5twi697PUcTprocgJgfStKjqat4DrzukbPxlhKvFuyyu8B1VKap6x_ZLks02SX-GI0yTHaU0zzH0A8t0RSUyUn315kWfn13fMNQVPFzgcOBO-lgfYLwuvXAfG6-MD5gsvSnerJb7ILC6bm7lQSAo0UlGJx9AgWIVIC8ImRu4jYNImpYlFfacKV4uso5BwvXEVC7OBp5LT-smN-p_jQsevbO1BeA6MOMl0ybSQIkQdZKk8grwkL4WF0sZJWvKM4he9kVUWXGMPPVQBnhHdMXYLyoHFj9w3qpHMgfqE9UhE9mbSWNfujHuIwh97Jn8TqLLK3-PdakCQ57x0jarL9bQGq-iV7v_uBM-eQNbFEvTUog_nvJSJgv5y-JnMa5wDQk8tlLAEcP2zM5i0bxriJPVunoS66byXYfsM6nfVtlizbH5C03cS12Q_YPV-AGURPjPIPoRbMuApLEHtxDE-iIwteBl1XKJBH80QD2_GJmbdolSjfk-CGFjGy9IgL7XB-PCRRaBLZ8zrxyVNNEZF3Aj1YTWuhXXV9-sYPN5TR1loaTsZzYyeAljOkDx9VFdU2Si9NGeHBR3U8baex2L3ud4Ql9AW-UTcTfUkiwHsVkGUbjyiluDAz5WiLnGgvM9YcmL1gFYw5GJlgqiKUwu532sO6uWA2KsWKIP9r9uzllJs-aD7OK5AJM2F_vT5b29Dqhlpp9Q7URqbPvpsS7tNZXQSLBTlUsp4PKFQEKNsfk=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1103.7,
            "totalPrice": 840
          },
          {
            "allPassengerTotalPrice": 846,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGCT5JY3W5ZshQg_cJ6kZRNTaHa8OMoyoYREJxJB7X6da9V2TvrXUb58FvtzJ7E9M74ZABlQan9aHe4CP8ZB-sp-AFNzHgWLcKZyKuvfDpFIHaZdW3_RY8oOCn3UkayD4iQ5x_ZLG7u_Fd6GY5vVhcJqwwfMRPSO-3ReCp6pNMLB8sjJtDhESHE42lW21u6MRXJApiJCvAYJFzsTXQoKPOiYfZ2cjoJbZa4Niejpa8n9RZqiVrNwSPNGTTrhGP8vOpnPUmrSQsOICbHUKHecmpeCLRyau8KnoBHvqDNRM9qh3lXELZD4bncsZ7gF4ufyOUWhlsZassx9DqT5MRSh1MInOg0Huu9qVnvySvxljA7eLZYXplk7FrBPGnSeeF9cqW63XU4o5ZprkX8dSSa4jmTGLbSQDwgvOh2QZ2_3YGOV2TYKns-SXhhAGceM-UCk99GpDsux-cU0MS_gPhHR0CgbPvS9OYYDe2c_6fg2jEVj1WqqeRO0ECVT1Z9gT5Mu1o32kL-wT6EFiG8GBzaZiCmBhTiERYdgpmUtJtp3E-x51IJ0wK_3lSdShDe4FRBF_Ka9QIbID0fxksBNjXTwIgZgblx8qIUA46s5vvSG2j0Hk-VRZjG3XT6lKx0VqD8xguVi3Ea5ASsKOUvsmaAnsyxsr9L9OFCrGRDg9jIWhyJdvVveruQzbiIghVun5zeZ4idkkYiZPSuZhs0RkwlSX0GB0pU0-0oQ9n_rttxKy8NEqhiK0EFyVbaOU2jxzUuoEC3ZmXHSpfk7PZA77KhubDOj0_tw8CrkIfoDTn-RlUcPF3mLsGswdxtP-96hO3t3lgFGzM5zzAS0TdogStga-g2AAtmLZLeXc_nm_gAwwhhMlONCzLaSDTrog4XzAhr30giz9Z3UD5bsdrPlaSnK6lYmTyP9gymwGc2JZqBU7e97HIqtJWX24Z0NKZI-0HLtrk9woKLZu8ygLSjOKKgdzva3KAtHSTFKdoG-fySw50EIYIxckiFwvXqxwnpNzrvFLGxq_u--uQ1-zhQXSfdBiX-BwKEF0mf52knFt4Kyw25EOd4_JSVHAXpL6PUczGU4i83JpkCHHcFHw70cP_nCW3Kfiz8xF-ayPYECSNAfhE6XrPgVbZDqgBLTZ_ov8xDs_4R9eNf2fAXcbJvRALR47gQpDs35o-WF6-fhvLzJ4nCm71GdWGF6-3dy2tCIJqL-mA5ut5vozcM9hNBYSo311YpLO1XikC5y8xVSKVBRjZ-IVp2Wfut5RJjKEDTV8dwuUCXWZHe0h7Lt_kgflCEqXIOZt636wO-CcRqETfxkOhFoqbqRDLqDTpv55rwjnHT_H",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGCT5JY3W5ZshQg_cJ6kZRNTaHa8OMoyoYREJxJB7X6da9V2TvrXUb58FvtzJ7E9M74ZABlQan9aHe4CP8ZB-sp-AFNzHgWLcKZyKuvfDpFIHaZdW3_RY8oOCn3UkayD4iQ5x_ZLG7u_Fd6GY5vVhcJqwwfMRPSO-3ReCp6pNMLB8sjJtDhESHE42lW21u6MRXJApiJCvAYJFzsTXQoKPOiYfZ2cjoJbZa4Niejpa8n9RZqiVrNwSPNGTTrhGP8vOpnPUmrSQsOICbHUKHecmpeCLRyau8KnoBHvqDNRM9qh3lXELZD4bncsZ7gF4ufyOUWhlsZassx9DqT5MRSh1MInOg0Huu9qVnvySvxljA7eLZYXplk7FrBPGnSeeF9cqW63XU4o5ZprkX8dSSa4jmTGLbSQDwgvOh2QZ2_3YGOV2TYKns-SXhhAGceM-UCk99GpDsux-cU0MS_gPhHR0CgbPvS9OYYDe2c_6fg2jEVj1WqqeRO0ECVT1Z9gT5Mu1o32kL-wT6EFiG8GBzaZiCmBhTiERYdgpmUtJtp3E-x51IJ0wK_3lSdShDe4FRBF_Ka9QIbID0fxksBNjXTwIgZgblx8qIUA46s5vvSG2j0Hk-VRZjG3XT6lKx0VqD8xguVi3Ea5ASsKOUvsmaAnsyxsr9L9OFCrGRDg9jIWhyJdvVveruQzbiIghVun5zeZ4idkkYiZPSuZhs0RkwlSX0GB0pU0-0oQ9n_rttxKy8NEqhiK0EFyVbaOU2jxzUuoEC3ZmXHSpfk7PZA77KhubDOj0_tw8CrkIfoDTn-RlUcPF3mLsGswdxtP-96hO3t3lgFGzM5zzAS0TdogStga-g2AAtmLZLeXc_nm_gAwwhhMlONCzLaSDTrog4XzAhr30giz9Z3UD5bsdrPlaSnK6lYmTyP9gymwGc2JZqBU7e97HIqtJWX24Z0NKZI-0HLtrk9woKLZu8ygLSjOKKgdzva3KAtHSTFKdoG-fySw50EIYIxckiFwvXqxwnpNzrvFLGxq_u--uQ1-zhQXSfdBiX-BwKEF0mf52knFt4Kyw25EOd4_JSVHAXpL6PUczGU4i83JpkCHHcFHw70cP_nCW3Kfiz8xF-ayPYECSNAfhE6XrPgVbZDqgBLTZ_ov8xDs_4R9eNf2fAXcbJvRALR47gQpDs35o-WF6-fhvLzJ4nCm71GdWGF6-3dy2tCIJqL-mA5ut5vozcM9hNBYSo311YpLO1XikC5y8xVSKVBRjZ-IVp2Wfut5RJjKEDTV8dwuUCXWZHe0h7Lt_kgflCEqXIOZt636wO-CcRqETfxkOhFoqbqRDLqDTpv55rwjnHT_H",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e240000a74c7035",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e240000a74c7035_0",
                    "local_arrival": "2024-10-08T19:20:00.000Z",
                    "local_departure": "2024-10-08T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-08T17:20:00.000Z",
                    "utc_departure": "2024-10-08T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e2400000210c421",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e2400000210c421_0",
                    "local_arrival": "2024-10-08T22:10:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:10:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GGCT5JY3W5ZshQg_cJ6kZRNTaHa8OMoyoYREJxJB7X6da9V2TvrXUb58FvtzJ7E9M74ZABlQan9aHe4CP8ZB-sp-AFNzHgWLcKZyKuvfDpFIHaZdW3_RY8oOCn3UkayD4iQ5x_ZLG7u_Fd6GY5vVhcJqwwfMRPSO-3ReCp6pNMLB8sjJtDhESHE42lW21u6MRXJApiJCvAYJFzsTXQoKPOiYfZ2cjoJbZa4Niejpa8n9RZqiVrNwSPNGTTrhGP8vOpnPUmrSQsOICbHUKHecmpeCLRyau8KnoBHvqDNRM9qh3lXELZD4bncsZ7gF4ufyOUWhlsZassx9DqT5MRSh1MInOg0Huu9qVnvySvxljA7eLZYXplk7FrBPGnSeeF9cqW63XU4o5ZprkX8dSSa4jmTGLbSQDwgvOh2QZ2_3YGOV2TYKns-SXhhAGceM-UCk99GpDsux-cU0MS_gPhHR0CgbPvS9OYYDe2c_6fg2jEVj1WqqeRO0ECVT1Z9gT5Mu1o32kL-wT6EFiG8GBzaZiCmBhTiERYdgpmUtJtp3E-x51IJ0wK_3lSdShDe4FRBF_Ka9QIbID0fxksBNjXTwIgZgblx8qIUA46s5vvSG2j0Hk-VRZjG3XT6lKx0VqD8xguVi3Ea5ASsKOUvsmaAnsyxsr9L9OFCrGRDg9jIWhyJdvVveruQzbiIghVun5zeZ4idkkYiZPSuZhs0RkwlSX0GB0pU0-0oQ9n_rttxKy8NEqhiK0EFyVbaOU2jxzUuoEC3ZmXHSpfk7PZA77KhubDOj0_tw8CrkIfoDTn-RlUcPF3mLsGswdxtP-96hO3t3lgFGzM5zzAS0TdogStga-g2AAtmLZLeXc_nm_gAwwhhMlONCzLaSDTrog4XzAhr30giz9Z3UD5bsdrPlaSnK6lYmTyP9gymwGc2JZqBU7e97HIqtJWX24Z0NKZI-0HLtrk9woKLZu8ygLSjOKKgdzva3KAtHSTFKdoG-fySw50EIYIxckiFwvXqxwnpNzrvFLGxq_u--uQ1-zhQXSfdBiX-BwKEF0mf52knFt4Kyw25EOd4_JSVHAXpL6PUczGU4i83JpkCHHcFHw70cP_nCW3Kfiz8xF-ayPYECSNAfhE6XrPgVbZDqgBLTZ_ov8xDs_4R9eNf2fAXcbJvRALR47gQpDs35o-WF6-fhvLzJ4nCm71GdWGF6-3dy2tCIJqL-mA5ut5vozcM9hNBYSo311YpLO1XikC5y8xVSKVBRjZ-IVp2Wfut5RJjKEDTV8dwuUCXWZHe0h7Lt_kgflCEqXIOZt636wO-CcRqETfxkOhFoqbqRDLqDTpv55rwjnHT_H",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1087.02,
            "totalPrice": 846
          },
          {
            "allPassengerTotalPrice": 846,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkRx05WemoIIkaoh-NoWfzSdAM4m1_dr_Y7_XLaX1iqXbpYlYRw98QwjbEIgJNi2nOqDGzaUdoveA1U0oCauo_l0vjU5TvRm-FQ9W1RYF_Q8YHmc4TKfk4dYv_v1KoY36bJb8_fVSsp5OsjevavNtF-_wCfvx3fv41SIwV1bPyRrbWqcthzhX5dBpbOI4ucf07UY0-LDJA3u-gKZ4T3xnxGCEnrlq2zua8YzDu_E6_17vZ4TtV1_r0oqDlJmQi98cqMJ25LzdZR0wrZymV6UdVbk25-t9Ihve9ilBHKpK1ojZDcnS-8hdGSEyt9Wpog-0v2v4_DAHOw2sHcsOErJ_GR1W_04d6aXIlDVgUNLyMpxDDeFCaY9oY0yxCYbXe67VDsyq9N1TxTOPuCYgwORTkIel04Vqw6-WwCtvIwTYe789_15p6b87E5jU7JZnPwkjRlX2xwFmmbGYNPjrfOgxiS7WyGN8fU1O_5HM0X_nvaxcySTpQK_UdNe_Hu3Yno7QAOiOCIU61pGoaDj9ZnHuYE1y3QBcvYXinjFjdyXiomYniX-RdC5YqzrvJCvHoYya0rE0XrNTGrM-z07P4xqZ_VO8mYNi3SS5r8YW8uIy0DcaWEwvatNjEeXHb9lzDtfRTdIwR2BKM4bXN5182-ZgzsxqwYyML1QGM63Xo40jb7yikQ0n4NoxllQ5STLj629sHc-G37oIhE4kt78YM_nbt49Ys-Jo2MBOGFuR2sHYmID_Q5evBgwdPKSShYTQ2L8w3HRfZ5pTnv0lbY-VZ9XPtrBXa_Q6AqSzuLF3HHWKDLW3rsy1waemtqC5Zl4XrNn3p-WGCqKWum6Wb_B7svkO91zBRG7IZcwIC0jFzsxlYCcnWbdszGqM3I_HjyuQGNHB7RCJFG6QkrIPSyGGtS0OMgd2Q9hPdFySLrM-eDIkYWE4r2h9ebYwGUFX6u5tLSeSJip_5n2NqzFhRCa78bKFywlUxHgfoPBiFL3g-9UsW6E3Ho093IKTvdimlkzEs58g1eRihbuFzG2mtmeOvD7B7sl6lz5NCD0LLvpfT7QQ-rQuIzjlGTq5IMc26c7srR6_PO9z4160gSMuGDvmNZRp-v4Iy1AOdEvWkt_rA6C8lvnd6XkmiwUhXU3h8Ps8A4afS3Go563UpM8ZRzrfhYQNGbwW63rFkX0EhGQKoBjyjOIY6ES579n_wbGDXJgYRg_J17nyYmpJq7bO6avpo6pCT8vO_VB5OCXAEeS3fSh9keLLfW95QOxK3qxNzbsh1Xld24Z0MFN8FiT1OM0sZrzmGNRYXP6XkMEeqRxRm__0cKTyB8CLBN-MGXiTVq2FZ57l",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkRx05WemoIIkaoh-NoWfzSdAM4m1_dr_Y7_XLaX1iqXbpYlYRw98QwjbEIgJNi2nOqDGzaUdoveA1U0oCauo_l0vjU5TvRm-FQ9W1RYF_Q8YHmc4TKfk4dYv_v1KoY36bJb8_fVSsp5OsjevavNtF-_wCfvx3fv41SIwV1bPyRrbWqcthzhX5dBpbOI4ucf07UY0-LDJA3u-gKZ4T3xnxGCEnrlq2zua8YzDu_E6_17vZ4TtV1_r0oqDlJmQi98cqMJ25LzdZR0wrZymV6UdVbk25-t9Ihve9ilBHKpK1ojZDcnS-8hdGSEyt9Wpog-0v2v4_DAHOw2sHcsOErJ_GR1W_04d6aXIlDVgUNLyMpxDDeFCaY9oY0yxCYbXe67VDsyq9N1TxTOPuCYgwORTkIel04Vqw6-WwCtvIwTYe789_15p6b87E5jU7JZnPwkjRlX2xwFmmbGYNPjrfOgxiS7WyGN8fU1O_5HM0X_nvaxcySTpQK_UdNe_Hu3Yno7QAOiOCIU61pGoaDj9ZnHuYE1y3QBcvYXinjFjdyXiomYniX-RdC5YqzrvJCvHoYya0rE0XrNTGrM-z07P4xqZ_VO8mYNi3SS5r8YW8uIy0DcaWEwvatNjEeXHb9lzDtfRTdIwR2BKM4bXN5182-ZgzsxqwYyML1QGM63Xo40jb7yikQ0n4NoxllQ5STLj629sHc-G37oIhE4kt78YM_nbt49Ys-Jo2MBOGFuR2sHYmID_Q5evBgwdPKSShYTQ2L8w3HRfZ5pTnv0lbY-VZ9XPtrBXa_Q6AqSzuLF3HHWKDLW3rsy1waemtqC5Zl4XrNn3p-WGCqKWum6Wb_B7svkO91zBRG7IZcwIC0jFzsxlYCcnWbdszGqM3I_HjyuQGNHB7RCJFG6QkrIPSyGGtS0OMgd2Q9hPdFySLrM-eDIkYWE4r2h9ebYwGUFX6u5tLSeSJip_5n2NqzFhRCa78bKFywlUxHgfoPBiFL3g-9UsW6E3Ho093IKTvdimlkzEs58g1eRihbuFzG2mtmeOvD7B7sl6lz5NCD0LLvpfT7QQ-rQuIzjlGTq5IMc26c7srR6_PO9z4160gSMuGDvmNZRp-v4Iy1AOdEvWkt_rA6C8lvnd6XkmiwUhXU3h8Ps8A4afS3Go563UpM8ZRzrfhYQNGbwW63rFkX0EhGQKoBjyjOIY6ES579n_wbGDXJgYRg_J17nyYmpJq7bO6avpo6pCT8vO_VB5OCXAEeS3fSh9keLLfW95QOxK3qxNzbsh1Xld24Z0MFN8FiT1OM0sZrzmGNRYXP6XkMEeqRxRm__0cKTyB8CLBN-MGXiTVq2FZ57l",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e240000a74c7035",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e240000a74c7035_0",
                    "local_arrival": "2024-10-08T19:20:00.000Z",
                    "local_departure": "2024-10-08T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-08T17:20:00.000Z",
                    "utc_departure": "2024-10-08T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e2400000210c421",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e2400000210c421_0",
                    "local_arrival": "2024-10-08T22:10:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:10:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkRx05WemoIIkaoh-NoWfzSdAM4m1_dr_Y7_XLaX1iqXbpYlYRw98QwjbEIgJNi2nOqDGzaUdoveA1U0oCauo_l0vjU5TvRm-FQ9W1RYF_Q8YHmc4TKfk4dYv_v1KoY36bJb8_fVSsp5OsjevavNtF-_wCfvx3fv41SIwV1bPyRrbWqcthzhX5dBpbOI4ucf07UY0-LDJA3u-gKZ4T3xnxGCEnrlq2zua8YzDu_E6_17vZ4TtV1_r0oqDlJmQi98cqMJ25LzdZR0wrZymV6UdVbk25-t9Ihve9ilBHKpK1ojZDcnS-8hdGSEyt9Wpog-0v2v4_DAHOw2sHcsOErJ_GR1W_04d6aXIlDVgUNLyMpxDDeFCaY9oY0yxCYbXe67VDsyq9N1TxTOPuCYgwORTkIel04Vqw6-WwCtvIwTYe789_15p6b87E5jU7JZnPwkjRlX2xwFmmbGYNPjrfOgxiS7WyGN8fU1O_5HM0X_nvaxcySTpQK_UdNe_Hu3Yno7QAOiOCIU61pGoaDj9ZnHuYE1y3QBcvYXinjFjdyXiomYniX-RdC5YqzrvJCvHoYya0rE0XrNTGrM-z07P4xqZ_VO8mYNi3SS5r8YW8uIy0DcaWEwvatNjEeXHb9lzDtfRTdIwR2BKM4bXN5182-ZgzsxqwYyML1QGM63Xo40jb7yikQ0n4NoxllQ5STLj629sHc-G37oIhE4kt78YM_nbt49Ys-Jo2MBOGFuR2sHYmID_Q5evBgwdPKSShYTQ2L8w3HRfZ5pTnv0lbY-VZ9XPtrBXa_Q6AqSzuLF3HHWKDLW3rsy1waemtqC5Zl4XrNn3p-WGCqKWum6Wb_B7svkO91zBRG7IZcwIC0jFzsxlYCcnWbdszGqM3I_HjyuQGNHB7RCJFG6QkrIPSyGGtS0OMgd2Q9hPdFySLrM-eDIkYWE4r2h9ebYwGUFX6u5tLSeSJip_5n2NqzFhRCa78bKFywlUxHgfoPBiFL3g-9UsW6E3Ho093IKTvdimlkzEs58g1eRihbuFzG2mtmeOvD7B7sl6lz5NCD0LLvpfT7QQ-rQuIzjlGTq5IMc26c7srR6_PO9z4160gSMuGDvmNZRp-v4Iy1AOdEvWkt_rA6C8lvnd6XkmiwUhXU3h8Ps8A4afS3Go563UpM8ZRzrfhYQNGbwW63rFkX0EhGQKoBjyjOIY6ES579n_wbGDXJgYRg_J17nyYmpJq7bO6avpo6pCT8vO_VB5OCXAEeS3fSh9keLLfW95QOxK3qxNzbsh1Xld24Z0MFN8FiT1OM0sZrzmGNRYXP6XkMEeqRxRm__0cKTyB8CLBN-MGXiTVq2FZ57l",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1113.69,
            "totalPrice": 846
          },
          {
            "allPassengerTotalPrice": 848,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3fUDtuEDgX7BTjukA0jU9MAEKUJRB2VzK6GHtzOnsLnU5DgqTzvmY20YgDe8csV6spjxbjcyDKmULJq44-qtUTdQcTuXZFvLnVFyEIZFg274j0cuXpSTjfeNb4mEHx3VsHC84iQpdYMJ0UpXQCsc6BtHKfsvounfUxc5Kas1AJicDaPvrIejHj-ANlrDIoLmYw1mdqs8nbYQ9GUimQedjRiCM7YVqqiIyMF8zxgHwEopkIDkQFLIZb3LaxyXV48pCHRLmb2p2DCIQGZCm-4Ch_4AQPkQ9BryDAa9yYqL7QiiAfY523sSC0i3yi1k65qzITuyyj_ZwJE5kYnbdEDkwMz_RDsU-NNdLf6JsllKVRx4DgtF12QxceL_Hu2q6706FwIv9P6eCq_4X-1Q4HhO5upaNAPPBjppwANuhFadVV-WURWTfXNROfh5jnVRQU0fUU4L23s2g_tcqPsZ-A-opjF7gL7DRdJ_yWLChBatyjxR0QVI2XOVeQq66X0qyWrX6ZzvuSR6CL8VKPYEYjK20LFloZQJ5f0JOZSCnLMMBvBi6e0JwmN2y41YgMukIoqJA2LItG7kxNai1Om1o3sRdzKz3xfPckEBEYpvxB7UuYUxuKUH44l8JyVeySidZFxooQlaHluZO4i_dD-XRceR6QcBulPUbsxENOzPWAdjFSaqwBGdwM2JdhYRI6RYAyNqSoNI2e-h_mgl3KKVrKVMntQCKU_prEmGwMzrMpJwQPjeL1Xjut8O5kuJAEYtU6hl6jz00onAq06wJttzUfJZxVVda3WPA79VfYHscLVkgbg-bHDV0Ge3VZ65-9VR01PtgLUW5V9OboXnYQefeuvP68V08RXRFkykSUBYW0Pukx-DGUP1v0kZhla11PK_aCFIITnuhS416mujzJde5MN0FUYwFX_3b11l22MPmOCYm58uW2v4YQNDlfAVD1fLjkSDFxQC-Pkpxt_PGNm1RkccXSRG-6nncgLdjH8iCC-RLBVa1Li0npRzQ9IfIyYV7SfYvhFG1oh3rhN-DmjoJy6OscXk4OX8lwhy1KsQozcUnq33P0GPJ6ke9nAoBYLFRncNL9E-AaW16VlBApfKB2zh5aIQm8WOwVUQOjiJJQsBe_zqV3TFN5Qb8PaJ_k95BqBd2LONpMOt4xMNQLNHqwOMbOLmWycHNpSUcI--KeWDbbimOexgKZz3-ZLsZBlcS3rv7X5bvQlSWU77c06AIGiC6VBly9NFHKoiPEYnD4HAxrg1Nv2oJVS_y51w4aqd1BN173KtnN0-JuDS-AUd0Cnu99XS_BtZDPAXsnhsvnQhuohPAToNqjJYFeq68Gwhy3ls",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3fUDtuEDgX7BTjukA0jU9MAEKUJRB2VzK6GHtzOnsLnU5DgqTzvmY20YgDe8csV6spjxbjcyDKmULJq44-qtUTdQcTuXZFvLnVFyEIZFg274j0cuXpSTjfeNb4mEHx3VsHC84iQpdYMJ0UpXQCsc6BtHKfsvounfUxc5Kas1AJicDaPvrIejHj-ANlrDIoLmYw1mdqs8nbYQ9GUimQedjRiCM7YVqqiIyMF8zxgHwEopkIDkQFLIZb3LaxyXV48pCHRLmb2p2DCIQGZCm-4Ch_4AQPkQ9BryDAa9yYqL7QiiAfY523sSC0i3yi1k65qzITuyyj_ZwJE5kYnbdEDkwMz_RDsU-NNdLf6JsllKVRx4DgtF12QxceL_Hu2q6706FwIv9P6eCq_4X-1Q4HhO5upaNAPPBjppwANuhFadVV-WURWTfXNROfh5jnVRQU0fUU4L23s2g_tcqPsZ-A-opjF7gL7DRdJ_yWLChBatyjxR0QVI2XOVeQq66X0qyWrX6ZzvuSR6CL8VKPYEYjK20LFloZQJ5f0JOZSCnLMMBvBi6e0JwmN2y41YgMukIoqJA2LItG7kxNai1Om1o3sRdzKz3xfPckEBEYpvxB7UuYUxuKUH44l8JyVeySidZFxooQlaHluZO4i_dD-XRceR6QcBulPUbsxENOzPWAdjFSaqwBGdwM2JdhYRI6RYAyNqSoNI2e-h_mgl3KKVrKVMntQCKU_prEmGwMzrMpJwQPjeL1Xjut8O5kuJAEYtU6hl6jz00onAq06wJttzUfJZxVVda3WPA79VfYHscLVkgbg-bHDV0Ge3VZ65-9VR01PtgLUW5V9OboXnYQefeuvP68V08RXRFkykSUBYW0Pukx-DGUP1v0kZhla11PK_aCFIITnuhS416mujzJde5MN0FUYwFX_3b11l22MPmOCYm58uW2v4YQNDlfAVD1fLjkSDFxQC-Pkpxt_PGNm1RkccXSRG-6nncgLdjH8iCC-RLBVa1Li0npRzQ9IfIyYV7SfYvhFG1oh3rhN-DmjoJy6OscXk4OX8lwhy1KsQozcUnq33P0GPJ6ke9nAoBYLFRncNL9E-AaW16VlBApfKB2zh5aIQm8WOwVUQOjiJJQsBe_zqV3TFN5Qb8PaJ_k95BqBd2LONpMOt4xMNQLNHqwOMbOLmWycHNpSUcI--KeWDbbimOexgKZz3-ZLsZBlcS3rv7X5bvQlSWU77c06AIGiC6VBly9NFHKoiPEYnD4HAxrg1Nv2oJVS_y51w4aqd1BN173KtnN0-JuDS-AUd0Cnu99XS_BtZDPAXsnhsvnQhuohPAToNqjJYFeq68Gwhy3ls",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G3fUDtuEDgX7BTjukA0jU9MAEKUJRB2VzK6GHtzOnsLnU5DgqTzvmY20YgDe8csV6spjxbjcyDKmULJq44-qtUTdQcTuXZFvLnVFyEIZFg274j0cuXpSTjfeNb4mEHx3VsHC84iQpdYMJ0UpXQCsc6BtHKfsvounfUxc5Kas1AJicDaPvrIejHj-ANlrDIoLmYw1mdqs8nbYQ9GUimQedjRiCM7YVqqiIyMF8zxgHwEopkIDkQFLIZb3LaxyXV48pCHRLmb2p2DCIQGZCm-4Ch_4AQPkQ9BryDAa9yYqL7QiiAfY523sSC0i3yi1k65qzITuyyj_ZwJE5kYnbdEDkwMz_RDsU-NNdLf6JsllKVRx4DgtF12QxceL_Hu2q6706FwIv9P6eCq_4X-1Q4HhO5upaNAPPBjppwANuhFadVV-WURWTfXNROfh5jnVRQU0fUU4L23s2g_tcqPsZ-A-opjF7gL7DRdJ_yWLChBatyjxR0QVI2XOVeQq66X0qyWrX6ZzvuSR6CL8VKPYEYjK20LFloZQJ5f0JOZSCnLMMBvBi6e0JwmN2y41YgMukIoqJA2LItG7kxNai1Om1o3sRdzKz3xfPckEBEYpvxB7UuYUxuKUH44l8JyVeySidZFxooQlaHluZO4i_dD-XRceR6QcBulPUbsxENOzPWAdjFSaqwBGdwM2JdhYRI6RYAyNqSoNI2e-h_mgl3KKVrKVMntQCKU_prEmGwMzrMpJwQPjeL1Xjut8O5kuJAEYtU6hl6jz00onAq06wJttzUfJZxVVda3WPA79VfYHscLVkgbg-bHDV0Ge3VZ65-9VR01PtgLUW5V9OboXnYQefeuvP68V08RXRFkykSUBYW0Pukx-DGUP1v0kZhla11PK_aCFIITnuhS416mujzJde5MN0FUYwFX_3b11l22MPmOCYm58uW2v4YQNDlfAVD1fLjkSDFxQC-Pkpxt_PGNm1RkccXSRG-6nncgLdjH8iCC-RLBVa1Li0npRzQ9IfIyYV7SfYvhFG1oh3rhN-DmjoJy6OscXk4OX8lwhy1KsQozcUnq33P0GPJ6ke9nAoBYLFRncNL9E-AaW16VlBApfKB2zh5aIQm8WOwVUQOjiJJQsBe_zqV3TFN5Qb8PaJ_k95BqBd2LONpMOt4xMNQLNHqwOMbOLmWycHNpSUcI--KeWDbbimOexgKZz3-ZLsZBlcS3rv7X5bvQlSWU77c06AIGiC6VBly9NFHKoiPEYnD4HAxrg1Nv2oJVS_y51w4aqd1BN173KtnN0-JuDS-AUd0Cnu99XS_BtZDPAXsnhsvnQhuohPAToNqjJYFeq68Gwhy3ls",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1113.02,
            "totalPrice": 848
          },
          {
            "allPassengerTotalPrice": 853,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141401af4e24000041b67294_0%7C01af0a224e250000c0172282_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJJxbDaDm0dfv-emgPKeaBnvBihMILdW6S0V6zVWunHUkXyRzgnn9m_sY3Nl2fomzNp5mQ2galkSM6kXQlAaK3cJpd5dHYUcyF7hmbNeVPJk7dfc_0YE0zf689YJgc1peR-JGgl2p4EunsDWU4TlVX_SvE67LIKPi3GCmkgcEh5caI7GiLh5GcFhoAp4mOMlXT7NrxkMI1XtSKZYIF3Mz2m3MwOW0VqTL951DiiBkNOd-Y5EEWbRZsyyqmiLWPUO8slGBYpAamENi6U0Vl7PLXMWBLM-R8U_78CXkg1wBi5Jycr3gnc8sA9IMAxgkgndeCInOnvkKNiyy6FIIZ5-vMJtO1ixwmPYJ52-tI422LGCSwzMBOrjIi7LfvnLF0yHAur-xvb_X9qqnmlO9_DyPfT6v4uxYAD31Ina_UF8M2RNNSnXelW07Udv2v3pYO-jHu3Ge7zjbAwEbAwFUpwQAZqQEatzwYlnDVfVPahWJHBsqQB8-i6DHkl9Wy-Q5vwYy9kxZekQwodhY51O9eT9q2vif3zoc68kEcvUo0dM5D2p7TcWXEnNkUb4KTMH73toi40QycUYeTUtVheQMXQpuND6sjNERue81X4kdjN0WIukfaXsi09kDCJBPEd3nJ5HPBvsc2MGeiJq5encLIvAJjudZMNuGQK_alHKW-iOXjarrRKwSimheq_IKbLdiyfNBK9be4U9Ocix86zumIWxHg4ldXVjh4uEK67imky9vBmRzgWALCHVV_k3hu59_7JNJyj_5U0kzlO8r8CO_ogCS9pnfHERwmDOsY_PLuCMf_axUhBp4as7P9lRnm-NgrX_oIGwX91rFn30W23F5teBZpHZjIr2s0aoapDot9PttHhQ55h6bcek6ZCyhsd_NGC_15-NSMl3ZtVytH1TA27eR40Zz6f8m-Yn8myLpnKLfiNPnMBuPqc5Z5jiLwJKcm2mKlOv8SL86mBPCTMwF2h5uqv2oGolMcPQabQS4wb2-L4kRJyCeE3GTl4E2vCjFg5QeNqeyQRkhS6D3WfIU15Oy51T497T0Vi_jS3J9d-TZK5Nxjr6-WGnzmD7oJ4jR5CcgHfnuu0bJuaI0Iligv7rOslLG6auL9Oh0RxbYyUiMsEvZIqbnHSLEeQ4Fvk3tdVGs",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "VY"
                ],
                "arrivalDate": "2024-10-09",
                "arrivalTime": "08:50",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141401af4e24000041b67294_0%7C01af0a224e250000c0172282_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJJxbDaDm0dfv-emgPKeaBnvBihMILdW6S0V6zVWunHUkXyRzgnn9m_sY3Nl2fomzNp5mQ2galkSM6kXQlAaK3cJpd5dHYUcyF7hmbNeVPJk7dfc_0YE0zf689YJgc1peR-JGgl2p4EunsDWU4TlVX_SvE67LIKPi3GCmkgcEh5caI7GiLh5GcFhoAp4mOMlXT7NrxkMI1XtSKZYIF3Mz2m3MwOW0VqTL951DiiBkNOd-Y5EEWbRZsyyqmiLWPUO8slGBYpAamENi6U0Vl7PLXMWBLM-R8U_78CXkg1wBi5Jycr3gnc8sA9IMAxgkgndeCInOnvkKNiyy6FIIZ5-vMJtO1ixwmPYJ52-tI422LGCSwzMBOrjIi7LfvnLF0yHAur-xvb_X9qqnmlO9_DyPfT6v4uxYAD31Ina_UF8M2RNNSnXelW07Udv2v3pYO-jHu3Ge7zjbAwEbAwFUpwQAZqQEatzwYlnDVfVPahWJHBsqQB8-i6DHkl9Wy-Q5vwYy9kxZekQwodhY51O9eT9q2vif3zoc68kEcvUo0dM5D2p7TcWXEnNkUb4KTMH73toi40QycUYeTUtVheQMXQpuND6sjNERue81X4kdjN0WIukfaXsi09kDCJBPEd3nJ5HPBvsc2MGeiJq5encLIvAJjudZMNuGQK_alHKW-iOXjarrRKwSimheq_IKbLdiyfNBK9be4U9Ocix86zumIWxHg4ldXVjh4uEK67imky9vBmRzgWALCHVV_k3hu59_7JNJyj_5U0kzlO8r8CO_ogCS9pnfHERwmDOsY_PLuCMf_axUhBp4as7P9lRnm-NgrX_oIGwX91rFn30W23F5teBZpHZjIr2s0aoapDot9PttHhQ55h6bcek6ZCyhsd_NGC_15-NSMl3ZtVytH1TA27eR40Zz6f8m-Yn8myLpnKLfiNPnMBuPqc5Z5jiLwJKcm2mKlOv8SL86mBPCTMwF2h5uqv2oGolMcPQabQS4wb2-L4kRJyCeE3GTl4E2vCjFg5QeNqeyQRkhS6D3WfIU15Oy51T497T0Vi_jS3J9d-TZK5Nxjr6-WGnzmD7oJ4jR5CcgHfnuu0bJuaI0Iligv7rOslLG6auL9Oh0RxbYyUiMsEvZIqbnHSLEeQ4Fvk3tdVGs",
                "dateChange": 1,
                "departureDate": "2024-10-08",
                "departureTime": "22:45",
                "destination": "MAD",
                "duration": "10h5m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Málaga",
                    "cityTo": "Barcelona",
                    "combination_id": "141401af4e24000041b67294",
                    "equipment": null,
                    "fare_basis": "POWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 2122,
                    "flyFrom": "AGP",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "141401af4e24000041b67294_0",
                    "local_arrival": "2024-10-09T00:20:00.000Z",
                    "local_departure": "2024-10-08T22:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2122",
                    "return": 0,
                    "utc_arrival": "2024-10-08T22:20:00.000Z",
                    "utc_departure": "2024-10-08T20:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Barcelona",
                    "cityTo": "Madrid",
                    "combination_id": "01af0a224e250000c0172282",
                    "equipment": null,
                    "fare_basis": "QOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 1002,
                    "flyFrom": "BCN",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "01af0a224e250000c0172282_0",
                    "local_arrival": "2024-10-09T08:50:00.000Z",
                    "local_departure": "2024-10-09T07:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "1002",
                    "return": 0,
                    "utc_arrival": "2024-10-09T06:50:00.000Z",
                    "utc_departure": "2024-10-09T05:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C141401af4e24000041b67294_0%7C01af0a224e250000c0172282_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GJJxbDaDm0dfv-emgPKeaBnvBihMILdW6S0V6zVWunHUkXyRzgnn9m_sY3Nl2fomzNp5mQ2galkSM6kXQlAaK3cJpd5dHYUcyF7hmbNeVPJk7dfc_0YE0zf689YJgc1peR-JGgl2p4EunsDWU4TlVX_SvE67LIKPi3GCmkgcEh5caI7GiLh5GcFhoAp4mOMlXT7NrxkMI1XtSKZYIF3Mz2m3MwOW0VqTL951DiiBkNOd-Y5EEWbRZsyyqmiLWPUO8slGBYpAamENi6U0Vl7PLXMWBLM-R8U_78CXkg1wBi5Jycr3gnc8sA9IMAxgkgndeCInOnvkKNiyy6FIIZ5-vMJtO1ixwmPYJ52-tI422LGCSwzMBOrjIi7LfvnLF0yHAur-xvb_X9qqnmlO9_DyPfT6v4uxYAD31Ina_UF8M2RNNSnXelW07Udv2v3pYO-jHu3Ge7zjbAwEbAwFUpwQAZqQEatzwYlnDVfVPahWJHBsqQB8-i6DHkl9Wy-Q5vwYy9kxZekQwodhY51O9eT9q2vif3zoc68kEcvUo0dM5D2p7TcWXEnNkUb4KTMH73toi40QycUYeTUtVheQMXQpuND6sjNERue81X4kdjN0WIukfaXsi09kDCJBPEd3nJ5HPBvsc2MGeiJq5encLIvAJjudZMNuGQK_alHKW-iOXjarrRKwSimheq_IKbLdiyfNBK9be4U9Ocix86zumIWxHg4ldXVjh4uEK67imky9vBmRzgWALCHVV_k3hu59_7JNJyj_5U0kzlO8r8CO_ogCS9pnfHERwmDOsY_PLuCMf_axUhBp4as7P9lRnm-NgrX_oIGwX91rFn30W23F5teBZpHZjIr2s0aoapDot9PttHhQ55h6bcek6ZCyhsd_NGC_15-NSMl3ZtVytH1TA27eR40Zz6f8m-Yn8myLpnKLfiNPnMBuPqc5Z5jiLwJKcm2mKlOv8SL86mBPCTMwF2h5uqv2oGolMcPQabQS4wb2-L4kRJyCeE3GTl4E2vCjFg5QeNqeyQRkhS6D3WfIU15Oy51T497T0Vi_jS3J9d-TZK5Nxjr6-WGnzmD7oJ4jR5CcgHfnuu0bJuaI0Iligv7rOslLG6auL9Oh0RxbYyUiMsEvZIqbnHSLEeQ4Fvk3tdVGs",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1110.3,
            "totalPrice": 853
          },
          {
            "allPassengerTotalPrice": 856,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlLr25MtnveVMKqKrb9iTxRcIdSLfPlIdpwST-QXvUhGc5wKal8cdkC-lyrKYhDvc9QfZ-RKFck42AamK794buoh-XluIjtad2H2tnDTORwue-Us9wENZNTZashHKXdwehxdyEQcADMMY9dqt7tfRLgus20eEagGExTQ7t6GoJaC-3n2lGMu2vip0zenR1KskC3GvuuMdV3DBie9kAif46t8w3vXped__w4qTK5Q2efRLP_jLHWfBuYo2A-r3su95VdchZbVDJoPz9qiAhqcB97dQevO3__mN1x3cYK_iJlRx67OlUebdz6bfU7E4IAKGz6MZlbR-Ht-68SabG1VQjx_kZleiTxruwCTkDiGwq7koymwQF4uJnrFh9jJ_yOOvqHFFc9L0KsUCEwmv1_M7PEUaHtSj78g0li4kqzXhdAeLlIbP_LzK_X7SMsMQouaLwoKctKqN85Y0fJ5mUSRPY_IfUBu3duJRUMjqzNdiE0m6B4h0iFVmlDyXA_7zNGZR7FnHngPgEVCZqJexnxEfDwwaoA5hLK5PsJ4PdutFwnSY0p4JXQ49NHpASmmqzjDN_ubUW8c2Bv_gA2GVaoLrgx7kCMuS-NVMD6zFL3rA2Lk6vJBLr2NlrGcwssEqrpwpqX_Y_pLHW0c2W6RcHwMRazs4TCuvCbgiXhxWxk32A_5OeDPXYE8f47URpUDYmolHvVhMwHhiKze0Y6Ly-K9FDuQg-xZySGjnhVaSPZ6Vo64GFWRQPx25r0dLkRVnhK-UdxOQBIuyCaUKl5WH3-z4yciuji1i5wEjq3vzZREf8KMS5sRasohD-tN0NQWXbzQ9bj6wIcvam29VRWabCoFg5c8UA5CqoQV2HYskVYnsRE9vp4ammL8qDQCe9QoBFJLbhMyo3pCXzcMh08khYE_OowFvGcwDiQthoFvUsoUpqL_NRoP9Xel_Rg_N9rgV-ohwyTPL_Y_xthctoyffageL1LwO-z6ZKn9a8jTHCbfbJT9TBo5Sm-IZriwBRQL9HdEd3FaPG1EuIQMT5n1WMESDLrSwGLAJkGhh1Yt7fFmx_IXyKcZHySFJfd10uLe_FdLowEcPkHR_-sSnVWQ7Q0jqzjn2UvB0zhgolRU-JovIzWdCjhTLbvgPIvF-oOc-I1fL",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlLr25MtnveVMKqKrb9iTxRcIdSLfPlIdpwST-QXvUhGc5wKal8cdkC-lyrKYhDvc9QfZ-RKFck42AamK794buoh-XluIjtad2H2tnDTORwue-Us9wENZNTZashHKXdwehxdyEQcADMMY9dqt7tfRLgus20eEagGExTQ7t6GoJaC-3n2lGMu2vip0zenR1KskC3GvuuMdV3DBie9kAif46t8w3vXped__w4qTK5Q2efRLP_jLHWfBuYo2A-r3su95VdchZbVDJoPz9qiAhqcB97dQevO3__mN1x3cYK_iJlRx67OlUebdz6bfU7E4IAKGz6MZlbR-Ht-68SabG1VQjx_kZleiTxruwCTkDiGwq7koymwQF4uJnrFh9jJ_yOOvqHFFc9L0KsUCEwmv1_M7PEUaHtSj78g0li4kqzXhdAeLlIbP_LzK_X7SMsMQouaLwoKctKqN85Y0fJ5mUSRPY_IfUBu3duJRUMjqzNdiE0m6B4h0iFVmlDyXA_7zNGZR7FnHngPgEVCZqJexnxEfDwwaoA5hLK5PsJ4PdutFwnSY0p4JXQ49NHpASmmqzjDN_ubUW8c2Bv_gA2GVaoLrgx7kCMuS-NVMD6zFL3rA2Lk6vJBLr2NlrGcwssEqrpwpqX_Y_pLHW0c2W6RcHwMRazs4TCuvCbgiXhxWxk32A_5OeDPXYE8f47URpUDYmolHvVhMwHhiKze0Y6Ly-K9FDuQg-xZySGjnhVaSPZ6Vo64GFWRQPx25r0dLkRVnhK-UdxOQBIuyCaUKl5WH3-z4yciuji1i5wEjq3vzZREf8KMS5sRasohD-tN0NQWXbzQ9bj6wIcvam29VRWabCoFg5c8UA5CqoQV2HYskVYnsRE9vp4ammL8qDQCe9QoBFJLbhMyo3pCXzcMh08khYE_OowFvGcwDiQthoFvUsoUpqL_NRoP9Xel_Rg_N9rgV-ohwyTPL_Y_xthctoyffageL1LwO-z6ZKn9a8jTHCbfbJT9TBo5Sm-IZriwBRQL9HdEd3FaPG1EuIQMT5n1WMESDLrSwGLAJkGhh1Yt7fFmx_IXyKcZHySFJfd10uLe_FdLowEcPkHR_-sSnVWQ7Q0jqzjn2UvB0zhgolRU-JovIzWdCjhTLbvgPIvF-oOc-I1fL",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e240000a74c7035",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e240000a74c7035_0",
                    "local_arrival": "2024-10-08T19:20:00.000Z",
                    "local_departure": "2024-10-08T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-08T17:20:00.000Z",
                    "utc_departure": "2024-10-08T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e2400000210c421",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e2400000210c421_0",
                    "local_arrival": "2024-10-08T22:10:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:10:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlLr25MtnveVMKqKrb9iTxRcIdSLfPlIdpwST-QXvUhGc5wKal8cdkC-lyrKYhDvc9QfZ-RKFck42AamK794buoh-XluIjtad2H2tnDTORwue-Us9wENZNTZashHKXdwehxdyEQcADMMY9dqt7tfRLgus20eEagGExTQ7t6GoJaC-3n2lGMu2vip0zenR1KskC3GvuuMdV3DBie9kAif46t8w3vXped__w4qTK5Q2efRLP_jLHWfBuYo2A-r3su95VdchZbVDJoPz9qiAhqcB97dQevO3__mN1x3cYK_iJlRx67OlUebdz6bfU7E4IAKGz6MZlbR-Ht-68SabG1VQjx_kZleiTxruwCTkDiGwq7koymwQF4uJnrFh9jJ_yOOvqHFFc9L0KsUCEwmv1_M7PEUaHtSj78g0li4kqzXhdAeLlIbP_LzK_X7SMsMQouaLwoKctKqN85Y0fJ5mUSRPY_IfUBu3duJRUMjqzNdiE0m6B4h0iFVmlDyXA_7zNGZR7FnHngPgEVCZqJexnxEfDwwaoA5hLK5PsJ4PdutFwnSY0p4JXQ49NHpASmmqzjDN_ubUW8c2Bv_gA2GVaoLrgx7kCMuS-NVMD6zFL3rA2Lk6vJBLr2NlrGcwssEqrpwpqX_Y_pLHW0c2W6RcHwMRazs4TCuvCbgiXhxWxk32A_5OeDPXYE8f47URpUDYmolHvVhMwHhiKze0Y6Ly-K9FDuQg-xZySGjnhVaSPZ6Vo64GFWRQPx25r0dLkRVnhK-UdxOQBIuyCaUKl5WH3-z4yciuji1i5wEjq3vzZREf8KMS5sRasohD-tN0NQWXbzQ9bj6wIcvam29VRWabCoFg5c8UA5CqoQV2HYskVYnsRE9vp4ammL8qDQCe9QoBFJLbhMyo3pCXzcMh08khYE_OowFvGcwDiQthoFvUsoUpqL_NRoP9Xel_Rg_N9rgV-ohwyTPL_Y_xthctoyffageL1LwO-z6ZKn9a8jTHCbfbJT9TBo5Sm-IZriwBRQL9HdEd3FaPG1EuIQMT5n1WMESDLrSwGLAJkGhh1Yt7fFmx_IXyKcZHySFJfd10uLe_FdLowEcPkHR_-sSnVWQ7Q0jqzjn2UvB0zhgolRU-JovIzWdCjhTLbvgPIvF-oOc-I1fL",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1067.64,
            "totalPrice": 856
          },
          {
            "allPassengerTotalPrice": 859,
            "legs": [
              {
                "airlines": [
                  "F9",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GdDhhW_OGYPhrdsPeAckPyahr3twB1ti39q70yfhNMkykIaEbs1pjvybKWV4QmbfqxrrySR-tMgewzakpP9BGDJoZl-2qMGnDviocM8gpuHMWW4FYInUEwmuUowDhT9_OhK3PlooV7gmSuAbiA2hksTdKd82YoxFSPZ5LK0Kb4edaiM3_shF9kbyPFtk22qxk9N-ukHdqVux78GlWuHwCcgWznGbk4LmOYxAskkhSHA81PFk3lqj3ef4hfDouDclCgqjrdI3loPzQt3WpfmLgt9zfnFGwuOwYzg9cXekT0vb91bCv5_kdATlGBYWHDWSum5nf7LD1Til2B4f2L2Ou5A1eYLxHBFK7Z5OFqMvQsmUqjX4LgNEN5kwnjoUSiujjz54Oa8Q_z5AQ-lZv1HGBIG7zpV51Io2U8hqfJXSRtDPSvAWI_sD4Hv8t8n__lWZvvTw9ThlK6u75WTLYt9JcaHYYU0rDrE4J05yTW4Lu4abm5IHu5xULHXCJS0soAJc5rvNtVwlFa-N4g6MT5gHoVdpSEazqESYyMD4ZdoiZZu1jK62gVpWuMHeZwOhZRH_06_lJj_XgY7yCdVazrxF_Okf0xf8K5iEFyM-3UgHNqV5Wa0dsm7Q1A3P4ciHFrgwfgLK6CGLUMEiLl6JGp1udLglB7pWzSWZADjaldthJlo5DhQyN__beJHSUC5ohCHJcZw6g2O75XifQIgLStS2UJuTXIKdvPCU-5LRAJCOtaRzg9kNEYzyildc2R5Drt1MZpZYS--yQ_emyqLWHY1ubYRkbn9vOo3NX3EyhDwwd3k1h3J8Geswgw8MMBqBgJQ5NM6GcYhgGl3zbqX-bAmJlMlrLT_kLCCWgYa2F64Gz4Xty1jAEhWa3ntufelB2xyPY4ALnQqlDQqbcN1CoknU6RzB4GXLBToAJyotqn1qQgdLWxoU5ytNgqVIqcO_ZjXD05S2rP3j59Uvv8clIJyG6QH52X9nO6X6RtEuAKCRj9tUFKBmYXlj3IHCwJdLMEOdzDV5Vnr0fzrhi7-SLVoojq2k-HkhvMwZ9fLU5BQAVuTHNLObHxzeU9WOHJ8_Bw-R1oezX7hqUcksW8QV7jG2SPLw1iSrKfpWxBAFrqcoxf-lE1XcW7eVrIq-zGtu6ZuMpz2wcwmndB5lkHpXLRdkcLDQdh2oTT-Qh3c3vps0O4To=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "14:41",
                "destination": "BCN",
                "duration": "17h54m",
                "routes": [
                  {
                    "airline": "F9",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "LAX",
                    "cityFrom": "San Francisco",
                    "cityTo": "Los Angeles",
                    "combination_id": "0f9c243e4e1a0000d41819a8",
                    "equipment": null,
                    "fare_basis": "K21SXS2",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 3308,
                    "flyFrom": "SFO",
                    "flyTo": "LAX",
                    "guarantee": false,
                    "id": "0f9c243e4e1a0000d41819a8_0",
                    "local_arrival": "2024-09-28T16:10:00.000Z",
                    "local_departure": "2024-09-28T14:41:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "3308",
                    "return": 0,
                    "utc_arrival": "2024-09-28T23:10:00.000Z",
                    "utc_departure": "2024-09-28T21:41:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Los Angeles",
                    "cityTo": "Barcelona",
                    "combination_id": "243e01af4e1a000096252996",
                    "equipment": null,
                    "fare_basis": "SIL2C7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9794,
                    "flyFrom": "LAX",
                    "flyTo": "BCN",
                    "guarantee": true,
                    "id": "243e01af4e1a000096252996_0",
                    "local_arrival": "2024-09-29T17:35:00.000Z",
                    "local_departure": "2024-09-28T20:50:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:35:00.000Z",
                    "utc_departure": "2024-09-29T03:50:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SFO",
                "stops": 1
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GdDhhW_OGYPhrdsPeAckPyahr3twB1ti39q70yfhNMkykIaEbs1pjvybKWV4QmbfqxrrySR-tMgewzakpP9BGDJoZl-2qMGnDviocM8gpuHMWW4FYInUEwmuUowDhT9_OhK3PlooV7gmSuAbiA2hksTdKd82YoxFSPZ5LK0Kb4edaiM3_shF9kbyPFtk22qxk9N-ukHdqVux78GlWuHwCcgWznGbk4LmOYxAskkhSHA81PFk3lqj3ef4hfDouDclCgqjrdI3loPzQt3WpfmLgt9zfnFGwuOwYzg9cXekT0vb91bCv5_kdATlGBYWHDWSum5nf7LD1Til2B4f2L2Ou5A1eYLxHBFK7Z5OFqMvQsmUqjX4LgNEN5kwnjoUSiujjz54Oa8Q_z5AQ-lZv1HGBIG7zpV51Io2U8hqfJXSRtDPSvAWI_sD4Hv8t8n__lWZvvTw9ThlK6u75WTLYt9JcaHYYU0rDrE4J05yTW4Lu4abm5IHu5xULHXCJS0soAJc5rvNtVwlFa-N4g6MT5gHoVdpSEazqESYyMD4ZdoiZZu1jK62gVpWuMHeZwOhZRH_06_lJj_XgY7yCdVazrxF_Okf0xf8K5iEFyM-3UgHNqV5Wa0dsm7Q1A3P4ciHFrgwfgLK6CGLUMEiLl6JGp1udLglB7pWzSWZADjaldthJlo5DhQyN__beJHSUC5ohCHJcZw6g2O75XifQIgLStS2UJuTXIKdvPCU-5LRAJCOtaRzg9kNEYzyildc2R5Drt1MZpZYS--yQ_emyqLWHY1ubYRkbn9vOo3NX3EyhDwwd3k1h3J8Geswgw8MMBqBgJQ5NM6GcYhgGl3zbqX-bAmJlMlrLT_kLCCWgYa2F64Gz4Xty1jAEhWa3ntufelB2xyPY4ALnQqlDQqbcN1CoknU6RzB4GXLBToAJyotqn1qQgdLWxoU5ytNgqVIqcO_ZjXD05S2rP3j59Uvv8clIJyG6QH52X9nO6X6RtEuAKCRj9tUFKBmYXlj3IHCwJdLMEOdzDV5Vnr0fzrhi7-SLVoojq2k-HkhvMwZ9fLU5BQAVuTHNLObHxzeU9WOHJ8_Bw-R1oezX7hqUcksW8QV7jG2SPLw1iSrKfpWxBAFrqcoxf-lE1XcW7eVrIq-zGtu6ZuMpz2wcwmndB5lkHpXLRdkcLDQdh2oTT-Qh3c3vps0O4To=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c243e4e1a0000d41819a8_0%7C243e01af4e1a000096252996_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GdDhhW_OGYPhrdsPeAckPyahr3twB1ti39q70yfhNMkykIaEbs1pjvybKWV4QmbfqxrrySR-tMgewzakpP9BGDJoZl-2qMGnDviocM8gpuHMWW4FYInUEwmuUowDhT9_OhK3PlooV7gmSuAbiA2hksTdKd82YoxFSPZ5LK0Kb4edaiM3_shF9kbyPFtk22qxk9N-ukHdqVux78GlWuHwCcgWznGbk4LmOYxAskkhSHA81PFk3lqj3ef4hfDouDclCgqjrdI3loPzQt3WpfmLgt9zfnFGwuOwYzg9cXekT0vb91bCv5_kdATlGBYWHDWSum5nf7LD1Til2B4f2L2Ou5A1eYLxHBFK7Z5OFqMvQsmUqjX4LgNEN5kwnjoUSiujjz54Oa8Q_z5AQ-lZv1HGBIG7zpV51Io2U8hqfJXSRtDPSvAWI_sD4Hv8t8n__lWZvvTw9ThlK6u75WTLYt9JcaHYYU0rDrE4J05yTW4Lu4abm5IHu5xULHXCJS0soAJc5rvNtVwlFa-N4g6MT5gHoVdpSEazqESYyMD4ZdoiZZu1jK62gVpWuMHeZwOhZRH_06_lJj_XgY7yCdVazrxF_Okf0xf8K5iEFyM-3UgHNqV5Wa0dsm7Q1A3P4ciHFrgwfgLK6CGLUMEiLl6JGp1udLglB7pWzSWZADjaldthJlo5DhQyN__beJHSUC5ohCHJcZw6g2O75XifQIgLStS2UJuTXIKdvPCU-5LRAJCOtaRzg9kNEYzyildc2R5Drt1MZpZYS--yQ_emyqLWHY1ubYRkbn9vOo3NX3EyhDwwd3k1h3J8Geswgw8MMBqBgJQ5NM6GcYhgGl3zbqX-bAmJlMlrLT_kLCCWgYa2F64Gz4Xty1jAEhWa3ntufelB2xyPY4ALnQqlDQqbcN1CoknU6RzB4GXLBToAJyotqn1qQgdLWxoU5ytNgqVIqcO_ZjXD05S2rP3j59Uvv8clIJyG6QH52X9nO6X6RtEuAKCRj9tUFKBmYXlj3IHCwJdLMEOdzDV5Vnr0fzrhi7-SLVoojq2k-HkhvMwZ9fLU5BQAVuTHNLObHxzeU9WOHJ8_Bw-R1oezX7hqUcksW8QV7jG2SPLw1iSrKfpWxBAFrqcoxf-lE1XcW7eVrIq-zGtu6ZuMpz2wcwmndB5lkHpXLRdkcLDQdh2oTT-Qh3c3vps0O4To=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1093.64,
            "totalPrice": 859
          },
          {
            "allPassengerTotalPrice": 864,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQejcgGIcdXLfvsEWf6ihZl3zOzoDpdQUqvC5fiYkCAN-Ip39TlO-07sa5DMm2XqFYDEHUr5ARwCvoMmlRu_My3jeeTI41HE2HQHS0raV7uNP1gkKb1YWFpNNlaad9hRe84Ejip8gyk9Xa1uJ3x5ELv-uPrrSuNJGlinM3bv7Rtnow9T3RYxGacnhAej9qMgdzerCSJHkqWI3NyCAy6s-jEgeGftvKlp4J4qPDqpuvjXPgYu0WwOf0KhwhLXbxkKT8GOfKAzm84iPZrtenTJ4ymg2IiMgB653nVij8EalmqF4ywA1fxucMHECnHTwFSBNrbmpXzcV2YHXUedhkKEEddzNReF8QRBERfTnYlSLurXd2_51fXA97T9G8GKtCQ85Pewg1AjS9ZuCGcw0sUGpN-vDitZklOx2E_OSlvtzUXwzNVIRS6SiShpDC0LJmwku3tnVdNLC0mbuk-0Oelt3uYFdOAg1pbYTvXPEjU7XI8Q-op9XMZ2-Jvr8TemDIyKzxGPfBB-AHgGQILlPqdwNCYq9okDKo7EN966Sxdita1Rop7kR01qHmcfxOF6ddpk0IUikplF_32QEi5VvNeYz5bd97bXclfDSHfdEddpoV-mnpiAzi28Hu1HeVMkcp9LH3x9aJY7x4QVqCbJ4_NQx6IOzueO8ZnUhl_eSNSz8wLTOOWLOwtVqD68PGm7KC4VVw3uyGGgubbDU8avBz93SQbTHxOTEiRi1uU7258YpXT_Sv8voGswOUbuOWz5UpE0QC8dJT9UgPq2cxifzGSEJ_RpRkYB6vEwWpfO5PX7ZCgBjKJ8h0oblOgwHrnDZGL2z-5cO2kK82R-s4d6kDAS4mS6J_8xGrvhrfoubvXjgmCLlz-BrMOs1KJQDnjGouDAsIoorwJrv2enB6wxrFO6ljyZxkoIcKdU-DLm-Ir00EwnW-YJcxcvuo32D4YWJjvzLbKqu_-Mowb1kaMSXmcvRDVRbEb9BCDB476Q9WfJrwdWHiSwF3sbkTI5fi695JikU0alXU3hNXNW9Pf5ATBZInVVb0cuwABCog17K_xrwP0y62Jx12QyD8Z1BEBhoyXpiQmxjSN6YwXtl1XTtcLEkJAT3CKC0x1Qtq0K11gfDxobRH8vQbexVdT6m4bmLbKVxeLde-OHBnSPCNsk7oYrMHH8myycJIDoTQO_xXbNCYZhmQ5XBlFsFJYtncrUgOvCy7xeowTqNWl06SYG1dvU5MIktPsszdJavHmf0uZ1M4wU=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQejcgGIcdXLfvsEWf6ihZl3zOzoDpdQUqvC5fiYkCAN-Ip39TlO-07sa5DMm2XqFYDEHUr5ARwCvoMmlRu_My3jeeTI41HE2HQHS0raV7uNP1gkKb1YWFpNNlaad9hRe84Ejip8gyk9Xa1uJ3x5ELv-uPrrSuNJGlinM3bv7Rtnow9T3RYxGacnhAej9qMgdzerCSJHkqWI3NyCAy6s-jEgeGftvKlp4J4qPDqpuvjXPgYu0WwOf0KhwhLXbxkKT8GOfKAzm84iPZrtenTJ4ymg2IiMgB653nVij8EalmqF4ywA1fxucMHECnHTwFSBNrbmpXzcV2YHXUedhkKEEddzNReF8QRBERfTnYlSLurXd2_51fXA97T9G8GKtCQ85Pewg1AjS9ZuCGcw0sUGpN-vDitZklOx2E_OSlvtzUXwzNVIRS6SiShpDC0LJmwku3tnVdNLC0mbuk-0Oelt3uYFdOAg1pbYTvXPEjU7XI8Q-op9XMZ2-Jvr8TemDIyKzxGPfBB-AHgGQILlPqdwNCYq9okDKo7EN966Sxdita1Rop7kR01qHmcfxOF6ddpk0IUikplF_32QEi5VvNeYz5bd97bXclfDSHfdEddpoV-mnpiAzi28Hu1HeVMkcp9LH3x9aJY7x4QVqCbJ4_NQx6IOzueO8ZnUhl_eSNSz8wLTOOWLOwtVqD68PGm7KC4VVw3uyGGgubbDU8avBz93SQbTHxOTEiRi1uU7258YpXT_Sv8voGswOUbuOWz5UpE0QC8dJT9UgPq2cxifzGSEJ_RpRkYB6vEwWpfO5PX7ZCgBjKJ8h0oblOgwHrnDZGL2z-5cO2kK82R-s4d6kDAS4mS6J_8xGrvhrfoubvXjgmCLlz-BrMOs1KJQDnjGouDAsIoorwJrv2enB6wxrFO6ljyZxkoIcKdU-DLm-Ir00EwnW-YJcxcvuo32D4YWJjvzLbKqu_-Mowb1kaMSXmcvRDVRbEb9BCDB476Q9WfJrwdWHiSwF3sbkTI5fi695JikU0alXU3hNXNW9Pf5ATBZInVVb0cuwABCog17K_xrwP0y62Jx12QyD8Z1BEBhoyXpiQmxjSN6YwXtl1XTtcLEkJAT3CKC0x1Qtq0K11gfDxobRH8vQbexVdT6m4bmLbKVxeLde-OHBnSPCNsk7oYrMHH8myycJIDoTQO_xXbNCYZhmQ5XBlFsFJYtncrUgOvCy7xeowTqNWl06SYG1dvU5MIktPsszdJavHmf0uZ1M4wU=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "21:38",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b000040c5bc36_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GQejcgGIcdXLfvsEWf6ihZl3zOzoDpdQUqvC5fiYkCAN-Ip39TlO-07sa5DMm2XqFYDEHUr5ARwCvoMmlRu_My3jeeTI41HE2HQHS0raV7uNP1gkKb1YWFpNNlaad9hRe84Ejip8gyk9Xa1uJ3x5ELv-uPrrSuNJGlinM3bv7Rtnow9T3RYxGacnhAej9qMgdzerCSJHkqWI3NyCAy6s-jEgeGftvKlp4J4qPDqpuvjXPgYu0WwOf0KhwhLXbxkKT8GOfKAzm84iPZrtenTJ4ymg2IiMgB653nVij8EalmqF4ywA1fxucMHECnHTwFSBNrbmpXzcV2YHXUedhkKEEddzNReF8QRBERfTnYlSLurXd2_51fXA97T9G8GKtCQ85Pewg1AjS9ZuCGcw0sUGpN-vDitZklOx2E_OSlvtzUXwzNVIRS6SiShpDC0LJmwku3tnVdNLC0mbuk-0Oelt3uYFdOAg1pbYTvXPEjU7XI8Q-op9XMZ2-Jvr8TemDIyKzxGPfBB-AHgGQILlPqdwNCYq9okDKo7EN966Sxdita1Rop7kR01qHmcfxOF6ddpk0IUikplF_32QEi5VvNeYz5bd97bXclfDSHfdEddpoV-mnpiAzi28Hu1HeVMkcp9LH3x9aJY7x4QVqCbJ4_NQx6IOzueO8ZnUhl_eSNSz8wLTOOWLOwtVqD68PGm7KC4VVw3uyGGgubbDU8avBz93SQbTHxOTEiRi1uU7258YpXT_Sv8voGswOUbuOWz5UpE0QC8dJT9UgPq2cxifzGSEJ_RpRkYB6vEwWpfO5PX7ZCgBjKJ8h0oblOgwHrnDZGL2z-5cO2kK82R-s4d6kDAS4mS6J_8xGrvhrfoubvXjgmCLlz-BrMOs1KJQDnjGouDAsIoorwJrv2enB6wxrFO6ljyZxkoIcKdU-DLm-Ir00EwnW-YJcxcvuo32D4YWJjvzLbKqu_-Mowb1kaMSXmcvRDVRbEb9BCDB476Q9WfJrwdWHiSwF3sbkTI5fi695JikU0alXU3hNXNW9Pf5ATBZInVVb0cuwABCog17K_xrwP0y62Jx12QyD8Z1BEBhoyXpiQmxjSN6YwXtl1XTtcLEkJAT3CKC0x1Qtq0K11gfDxobRH8vQbexVdT6m4bmLbKVxeLde-OHBnSPCNsk7oYrMHH8myycJIDoTQO_xXbNCYZhmQ5XBlFsFJYtncrUgOvCy7xeowTqNWl06SYG1dvU5MIktPsszdJavHmf0uZ1M4wU=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "31h43m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b000040c5bc36",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4401,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b000040c5bc36_0",
                    "local_arrival": "2024-10-15T21:38:00.000Z",
                    "local_departure": "2024-10-15T20:00:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4401",
                    "return": 0,
                    "utc_arrival": "2024-10-16T04:38:00.000Z",
                    "utc_departure": "2024-10-16T03:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1076.96,
            "totalPrice": 864
          },
          {
            "allPassengerTotalPrice": 864,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxGKm3sD931Vfn-xxRnC81_tZDyje73CpihBmXnjUsrKa49regrudcEsYVcRmXbX0gH-UU3XI73tlJy6OvdiYS6F8xAy2roucBR81zC7bW1VtiCvktlx_W5sXVfLAEq5qwd8cxAIZJXJI1ttBhFLRhqO9kP48EJhqL1BhzCic2eByADrddVjcv8atKi8U9qz0nCetnJefu6iPGbLuk11QAungw7-ZgbJOiQxfAJlgX4W54n228Pu2HfxGpSGq6OiUp9K2QqJHoKJ2-F7mrbRSk39DipQA5lonvv-nPcpQWdH5yGGF4P8qAuyFm_4J3BioU4Bw-_INrtZn-oUlZ9OPFZQdQsWODcUAxeM8SVZvsLQGzmjU9c4A9-Zvuv-rcY2_2hEekQKESVpPJhugi_4SVa31SfvhsyzYaxEUKnacYGTI896wGlKVhtqK_NnHbI5XOhEo6mzentEmoY1wh8FyC-mEpRR3lTUkWXrc8ayUPrysNlnunZ9aoOOvYKvvJYl-uC7nOJn7FF2sLd0hiMy5nZliJ8pZcpY64RANsFr9qNAqIzmv7_5Q_DCqp3rNBh7PdOZ_6H0SR70iiVUF9OPaPatAIqBj2mdrZqt8iubWUDLxJfut6-DqDfp6mZMWZ1AcI8ncnhFKA9JuInKcx_JVTPk0H72S4Y1nSCWkUsBYfJ5tvsbOGDzMmPYgPx8dwz0fFwPYUjGF7yxAcSp20k4pC6lTSj3SGCC0hJgIQVJsSaJWyrxp0tVLzDRA23qm6DD-_cxroYfUiBpQksUP3hdPQigd8Vybg3FcBp_QN7RkBQrpS1WrS_4Cle6X0y267BoNaeNFtJrIdDCq3e20q_pojOW8UqdE8He92TPWPvoXtdzt4IOK-y8uATuCR_GdmFLgQgQZJGInExS1ej3WgZiYv_U6OIfXdkv6o7oifXgFBghTE1xid7SAqjEcuryOfBjUSu42KQBLHJuvTmfUKVoohr4_RrnB-fkyoK08FBZUFN43F8B95EuxtzrxNqEa7ZMZMdFbO9evNxpz9ayF6hTezG7Rvxq-eOPl3cFZK-uHpJqOGgWzqv_SRD-PtPfh5pmmxTGBnfb2w-bKbR9uOQFigHyK5-Qu-JSvxruYn8lTI2tDN_CRd5OY0vPMQBfSRt_5Poz-JVYAcULzJHeo52JvforqBTXdcisDqIjyTsyov5pgh_MiCcSsxDIo0jaaR-0x8JP4-mRvExDdVoycBcbEeQuswRXurRRiDnUceeK9DhA=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxGKm3sD931Vfn-xxRnC81_tZDyje73CpihBmXnjUsrKa49regrudcEsYVcRmXbX0gH-UU3XI73tlJy6OvdiYS6F8xAy2roucBR81zC7bW1VtiCvktlx_W5sXVfLAEq5qwd8cxAIZJXJI1ttBhFLRhqO9kP48EJhqL1BhzCic2eByADrddVjcv8atKi8U9qz0nCetnJefu6iPGbLuk11QAungw7-ZgbJOiQxfAJlgX4W54n228Pu2HfxGpSGq6OiUp9K2QqJHoKJ2-F7mrbRSk39DipQA5lonvv-nPcpQWdH5yGGF4P8qAuyFm_4J3BioU4Bw-_INrtZn-oUlZ9OPFZQdQsWODcUAxeM8SVZvsLQGzmjU9c4A9-Zvuv-rcY2_2hEekQKESVpPJhugi_4SVa31SfvhsyzYaxEUKnacYGTI896wGlKVhtqK_NnHbI5XOhEo6mzentEmoY1wh8FyC-mEpRR3lTUkWXrc8ayUPrysNlnunZ9aoOOvYKvvJYl-uC7nOJn7FF2sLd0hiMy5nZliJ8pZcpY64RANsFr9qNAqIzmv7_5Q_DCqp3rNBh7PdOZ_6H0SR70iiVUF9OPaPatAIqBj2mdrZqt8iubWUDLxJfut6-DqDfp6mZMWZ1AcI8ncnhFKA9JuInKcx_JVTPk0H72S4Y1nSCWkUsBYfJ5tvsbOGDzMmPYgPx8dwz0fFwPYUjGF7yxAcSp20k4pC6lTSj3SGCC0hJgIQVJsSaJWyrxp0tVLzDRA23qm6DD-_cxroYfUiBpQksUP3hdPQigd8Vybg3FcBp_QN7RkBQrpS1WrS_4Cle6X0y267BoNaeNFtJrIdDCq3e20q_pojOW8UqdE8He92TPWPvoXtdzt4IOK-y8uATuCR_GdmFLgQgQZJGInExS1ej3WgZiYv_U6OIfXdkv6o7oifXgFBghTE1xid7SAqjEcuryOfBjUSu42KQBLHJuvTmfUKVoohr4_RrnB-fkyoK08FBZUFN43F8B95EuxtzrxNqEa7ZMZMdFbO9evNxpz9ayF6hTezG7Rvxq-eOPl3cFZK-uHpJqOGgWzqv_SRD-PtPfh5pmmxTGBnfb2w-bKbR9uOQFigHyK5-Qu-JSvxruYn8lTI2tDN_CRd5OY0vPMQBfSRt_5Poz-JVYAcULzJHeo52JvforqBTXdcisDqIjyTsyov5pgh_MiCcSsxDIo0jaaR-0x8JP4-mRvExDdVoycBcbEeQuswRXurRRiDnUceeK9DhA=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "F9"
                ],
                "arrivalDate": "2024-10-16",
                "arrivalTime": "00:58",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C24460f9c4e2b0000d21a2fad_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GxGKm3sD931Vfn-xxRnC81_tZDyje73CpihBmXnjUsrKa49regrudcEsYVcRmXbX0gH-UU3XI73tlJy6OvdiYS6F8xAy2roucBR81zC7bW1VtiCvktlx_W5sXVfLAEq5qwd8cxAIZJXJI1ttBhFLRhqO9kP48EJhqL1BhzCic2eByADrddVjcv8atKi8U9qz0nCetnJefu6iPGbLuk11QAungw7-ZgbJOiQxfAJlgX4W54n228Pu2HfxGpSGq6OiUp9K2QqJHoKJ2-F7mrbRSk39DipQA5lonvv-nPcpQWdH5yGGF4P8qAuyFm_4J3BioU4Bw-_INrtZn-oUlZ9OPFZQdQsWODcUAxeM8SVZvsLQGzmjU9c4A9-Zvuv-rcY2_2hEekQKESVpPJhugi_4SVa31SfvhsyzYaxEUKnacYGTI896wGlKVhtqK_NnHbI5XOhEo6mzentEmoY1wh8FyC-mEpRR3lTUkWXrc8ayUPrysNlnunZ9aoOOvYKvvJYl-uC7nOJn7FF2sLd0hiMy5nZliJ8pZcpY64RANsFr9qNAqIzmv7_5Q_DCqp3rNBh7PdOZ_6H0SR70iiVUF9OPaPatAIqBj2mdrZqt8iubWUDLxJfut6-DqDfp6mZMWZ1AcI8ncnhFKA9JuInKcx_JVTPk0H72S4Y1nSCWkUsBYfJ5tvsbOGDzMmPYgPx8dwz0fFwPYUjGF7yxAcSp20k4pC6lTSj3SGCC0hJgIQVJsSaJWyrxp0tVLzDRA23qm6DD-_cxroYfUiBpQksUP3hdPQigd8Vybg3FcBp_QN7RkBQrpS1WrS_4Cle6X0y267BoNaeNFtJrIdDCq3e20q_pojOW8UqdE8He92TPWPvoXtdzt4IOK-y8uATuCR_GdmFLgQgQZJGInExS1ej3WgZiYv_U6OIfXdkv6o7oifXgFBghTE1xid7SAqjEcuryOfBjUSu42KQBLHJuvTmfUKVoohr4_RrnB-fkyoK08FBZUFN43F8B95EuxtzrxNqEa7ZMZMdFbO9evNxpz9ayF6hTezG7Rvxq-eOPl3cFZK-uHpJqOGgWzqv_SRD-PtPfh5pmmxTGBnfb2w-bKbR9uOQFigHyK5-Qu-JSvxruYn8lTI2tDN_CRd5OY0vPMQBfSRt_5Poz-JVYAcULzJHeo52JvforqBTXdcisDqIjyTsyov5pgh_MiCcSsxDIo0jaaR-0x8JP4-mRvExDdVoycBcbEeQuswRXurRRiDnUceeK9DhA=",
                "dateChange": 2,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "35h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "F9",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Las Vegas",
                    "cityTo": "San Francisco",
                    "combination_id": "24460f9c4e2b0000d21a2fad",
                    "equipment": null,
                    "fare_basis": "K07MXS9",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 4157,
                    "flyFrom": "LAS",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "24460f9c4e2b0000d21a2fad_0",
                    "local_arrival": "2024-10-16T00:58:00.000Z",
                    "local_departure": "2024-10-15T23:19:00.000Z",
                    "operating_carrier": "F9",
                    "operating_flight_no": "4157",
                    "return": 0,
                    "utc_arrival": "2024-10-16T07:58:00.000Z",
                    "utc_departure": "2024-10-16T06:19:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1103.62,
            "totalPrice": 864
          },
          {
            "allPassengerTotalPrice": 868,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2b0000ba6df08b_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8Sarza6fBjqjDmlLqWa2NI6z92vy2dJL_Am5PnpN8Qqu37-lhh0oMQTvhk3qgGS4Ck4zhZg_93nUS8qbtBpuSz2VEnVajaJxpbf4nuRNSHbdVpul0EHdSXtDKZL9xkos6QdmyravipwZPxVh2We4Qvd37XqXJ3bS-A7ibZ1PUxvQMMsgriR5J7Qm7flifMRTHVfIu1bzOsB5fEj74Hiw7rr7cIW8A0-ncrBh-2e_38gNrkzt3xmjLH6UXEEFBO5on3jKqJgqVM0DoaU-DIndzqGu4k9f-ftRTt6cL1t0m8tW27wXbgtp-ZscB7CXTuPCz_xj0GGbEjyMMLbFtxmF2g0iacs2zSm9sqBzzIJ_IKFsD2rjXIqsZ4trvTYLeXxn332oPQnflXaGQazgNpQ-sn342fhnedDM2FWXufjou3jl5aCWUVeoRzxxyfXTT5RRJQ9PH0iJFufGDaZaq1sYdPo11X-3PfQMIXsbbVCEvS5cQ0cKdj0KUTl_hCO8-RnNw7k6pE_6WFa7SIWYfRnm1tcy0G1LtM6nKxh7Mufk7Uc5gNWVun0v5KPloZ-rashBFQ09RmCgc9c_QeUu61fvq-qJ5qLdm8iPdwpL0gc5YtrBabm0Dhl8_bmeI9Rp8qSqJnGtpLStL8gDEi9p78akr8wTN_eUxHEKzyPUAPHPk6we4BFWSpqWwXcE5bedmqPPxMPCoGMNx4u8zPlOdjG4QBRZaiU6S4hWTvgrDR09zqMxk2-d0lCsSGxDY5PUouhuGLa8R77v_DcOUYM5Ob0uETX7eQfigM7U5HeWVx5RRyRzXLZ4T8FmMh0S5ND7S7iXfvPgLYd-Os8Xykd2o2lF0GsE56_ePVBoqsxZaDg_SjYJvhi28r9eXJTUfHJODjXau5e1tA2-pNJ91pHcG_04AlHKq9jkmJrsA0qhyGCtbdZvzljnyE5SF47AthSgbxWLjqtKvc07Kw_dfFQuljkwER82_IJjhOIbW9XaxSlCxEZ2uszSLiYifG0RaOPiM2l4vHHiAYBN06nwrPY7dqdBgxoE0gs1d90DeN7RxACMenN-iHwtPKbe0nWvNJ2UwlBnQvFcYHfDmjXs0NbAVYTMcugqiUvkyxV5Ws3HNU2IbLXEQqVsRXuqR4gG3zoT0fouhVjSj2XzQAFqCMDyweEwDdLqoeWys-JHeuvUOJK8zvkUA1JLu5nkFcm9XX4Pe0Q-",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2b0000ba6df08b_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8Sarza6fBjqjDmlLqWa2NI6z92vy2dJL_Am5PnpN8Qqu37-lhh0oMQTvhk3qgGS4Ck4zhZg_93nUS8qbtBpuSz2VEnVajaJxpbf4nuRNSHbdVpul0EHdSXtDKZL9xkos6QdmyravipwZPxVh2We4Qvd37XqXJ3bS-A7ibZ1PUxvQMMsgriR5J7Qm7flifMRTHVfIu1bzOsB5fEj74Hiw7rr7cIW8A0-ncrBh-2e_38gNrkzt3xmjLH6UXEEFBO5on3jKqJgqVM0DoaU-DIndzqGu4k9f-ftRTt6cL1t0m8tW27wXbgtp-ZscB7CXTuPCz_xj0GGbEjyMMLbFtxmF2g0iacs2zSm9sqBzzIJ_IKFsD2rjXIqsZ4trvTYLeXxn332oPQnflXaGQazgNpQ-sn342fhnedDM2FWXufjou3jl5aCWUVeoRzxxyfXTT5RRJQ9PH0iJFufGDaZaq1sYdPo11X-3PfQMIXsbbVCEvS5cQ0cKdj0KUTl_hCO8-RnNw7k6pE_6WFa7SIWYfRnm1tcy0G1LtM6nKxh7Mufk7Uc5gNWVun0v5KPloZ-rashBFQ09RmCgc9c_QeUu61fvq-qJ5qLdm8iPdwpL0gc5YtrBabm0Dhl8_bmeI9Rp8qSqJnGtpLStL8gDEi9p78akr8wTN_eUxHEKzyPUAPHPk6we4BFWSpqWwXcE5bedmqPPxMPCoGMNx4u8zPlOdjG4QBRZaiU6S4hWTvgrDR09zqMxk2-d0lCsSGxDY5PUouhuGLa8R77v_DcOUYM5Ob0uETX7eQfigM7U5HeWVx5RRyRzXLZ4T8FmMh0S5ND7S7iXfvPgLYd-Os8Xykd2o2lF0GsE56_ePVBoqsxZaDg_SjYJvhi28r9eXJTUfHJODjXau5e1tA2-pNJ91pHcG_04AlHKq9jkmJrsA0qhyGCtbdZvzljnyE5SF47AthSgbxWLjqtKvc07Kw_dfFQuljkwER82_IJjhOIbW9XaxSlCxEZ2uszSLiYifG0RaOPiM2l4vHHiAYBN06nwrPY7dqdBgxoE0gs1d90DeN7RxACMenN-iHwtPKbe0nWvNJ2UwlBnQvFcYHfDmjXs0NbAVYTMcugqiUvkyxV5Ws3HNU2IbLXEQqVsRXuqR4gG3zoT0fouhVjSj2XzQAFqCMDyweEwDdLqoeWys-JHeuvUOJK8zvkUA1JLu5nkFcm9XX4Pe0Q-",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "09:27",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2b0000ba6df08b_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G8Sarza6fBjqjDmlLqWa2NI6z92vy2dJL_Am5PnpN8Qqu37-lhh0oMQTvhk3qgGS4Ck4zhZg_93nUS8qbtBpuSz2VEnVajaJxpbf4nuRNSHbdVpul0EHdSXtDKZL9xkos6QdmyravipwZPxVh2We4Qvd37XqXJ3bS-A7ibZ1PUxvQMMsgriR5J7Qm7flifMRTHVfIu1bzOsB5fEj74Hiw7rr7cIW8A0-ncrBh-2e_38gNrkzt3xmjLH6UXEEFBO5on3jKqJgqVM0DoaU-DIndzqGu4k9f-ftRTt6cL1t0m8tW27wXbgtp-ZscB7CXTuPCz_xj0GGbEjyMMLbFtxmF2g0iacs2zSm9sqBzzIJ_IKFsD2rjXIqsZ4trvTYLeXxn332oPQnflXaGQazgNpQ-sn342fhnedDM2FWXufjou3jl5aCWUVeoRzxxyfXTT5RRJQ9PH0iJFufGDaZaq1sYdPo11X-3PfQMIXsbbVCEvS5cQ0cKdj0KUTl_hCO8-RnNw7k6pE_6WFa7SIWYfRnm1tcy0G1LtM6nKxh7Mufk7Uc5gNWVun0v5KPloZ-rashBFQ09RmCgc9c_QeUu61fvq-qJ5qLdm8iPdwpL0gc5YtrBabm0Dhl8_bmeI9Rp8qSqJnGtpLStL8gDEi9p78akr8wTN_eUxHEKzyPUAPHPk6we4BFWSpqWwXcE5bedmqPPxMPCoGMNx4u8zPlOdjG4QBRZaiU6S4hWTvgrDR09zqMxk2-d0lCsSGxDY5PUouhuGLa8R77v_DcOUYM5Ob0uETX7eQfigM7U5HeWVx5RRyRzXLZ4T8FmMh0S5ND7S7iXfvPgLYd-Os8Xykd2o2lF0GsE56_ePVBoqsxZaDg_SjYJvhi28r9eXJTUfHJODjXau5e1tA2-pNJ91pHcG_04AlHKq9jkmJrsA0qhyGCtbdZvzljnyE5SF47AthSgbxWLjqtKvc07Kw_dfFQuljkwER82_IJjhOIbW9XaxSlCxEZ2uszSLiYifG0RaOPiM2l4vHHiAYBN06nwrPY7dqdBgxoE0gs1d90DeN7RxACMenN-iHwtPKbe0nWvNJ2UwlBnQvFcYHfDmjXs0NbAVYTMcugqiUvkyxV5Ws3HNU2IbLXEQqVsRXuqR4gG3zoT0fouhVjSj2XzQAFqCMDyweEwDdLqoeWys-JHeuvUOJK8zvkUA1JLu5nkFcm9XX4Pe0Q-",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "34h47m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2b0000ba6df08b",
                    "equipment": null,
                    "fare_basis": "GH3OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 1051,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2b0000ba6df08b_0",
                    "local_arrival": "2024-10-15T09:27:00.000Z",
                    "local_departure": "2024-10-15T08:00:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "1051",
                    "return": 0,
                    "utc_arrival": "2024-10-15T16:27:00.000Z",
                    "utc_departure": "2024-10-15T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1102.94,
            "totalPrice": 868
          },
          {
            "allPassengerTotalPrice": 875,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_AfZ5X8gO2qjMmJTonxjcBf32S7IGqQleKXOY6SeGVbOGa-r416LrMIGUbrGS7JcNA5H2MlxQCvqFrmQAriozmkswImfSDKzkQMZLb5-WESJqT1Udxrb5AxFeg6haF0VL7Bqy8ZhXwW0mPTJ3P55kc3pz92GtpaDbREIE1pa9KJ6bQCCeyHSPGuHMGE-rhp2_iqz_L5jScEWjMKhhjAHrLylBFGsIUypaufb2dzS6lhWIE7ST9s0LyY-YZLvqIVTLqJoQUP_wtYr_T8d0VHJMpva4GAfoPOPaLvI_JzoBYzP-GTNWDlyV2LNpuVivqHPwlVxQrVv862JYBv1Mvj_i92oDBbjlbRsFDRrDNgJE5mnsS16DiKY-XghObzKEn3v31jIfd_a3g0e26Kx2ai5sVnxbMcFCogakr6qq4N8Y4IBQsKC_p1_t23Xw2jsg_kWN_m8FMSd0_8sT0cX-V1SY6KkniJnN8TSN5mbbZw3FoXV-qOxPjsikajw02MPI7pcGMA1Dh3ZV0sFGsUg4-fbWHWFjh3aHuIaZXruiDlUNia6TLAepAV5d4qFZsJigqD-BtNTfpEbwlutUED3YfrS5YQjUmsCPssSeuWlDTLgiXQ0g2XcXphUgQDSnzWy1onZEX77g_lPFcTIDSJ23q7gi8K2Jy0DfY2t6PUcwkGRabrpco3QDyRlaKsGwjLh6ZC6BoWssmP-HuwGP3tcGRnZddA6XhIH7KH83klk1QuVV1qrFNaqlMnnxC5ma63FDWm5G2z9ASH8u6WVDnns9_LzBr6zHwZ4lBf8NArdPR1x-BElpblP828n6ebpk21RfKNihUiw1CItluf4jpGE1OIX8RhLZaIAzMfC7JBRVLpbuYkOkh9H8qQ5YO4HO3DYIvNPpWoDYsip6QFlNRh72GaflWzY6WyoMgXEe0ODaWiwf2-8m6DOtmUuYY6eS4wV6hz2M_LdZAJ0yUmlB6pSPTGMeJYawLQn71_Bjzj0AfZQlIuQwAJo2Dlc2ubktGsd8g9_Kg_vDqdyIp4wGMrcuC4-mtf00LiiTSq-R5QP_OlIWI_Ri9zTKzgsKHFOQ9L1QbYu",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_AfZ5X8gO2qjMmJTonxjcBf32S7IGqQleKXOY6SeGVbOGa-r416LrMIGUbrGS7JcNA5H2MlxQCvqFrmQAriozmkswImfSDKzkQMZLb5-WESJqT1Udxrb5AxFeg6haF0VL7Bqy8ZhXwW0mPTJ3P55kc3pz92GtpaDbREIE1pa9KJ6bQCCeyHSPGuHMGE-rhp2_iqz_L5jScEWjMKhhjAHrLylBFGsIUypaufb2dzS6lhWIE7ST9s0LyY-YZLvqIVTLqJoQUP_wtYr_T8d0VHJMpva4GAfoPOPaLvI_JzoBYzP-GTNWDlyV2LNpuVivqHPwlVxQrVv862JYBv1Mvj_i92oDBbjlbRsFDRrDNgJE5mnsS16DiKY-XghObzKEn3v31jIfd_a3g0e26Kx2ai5sVnxbMcFCogakr6qq4N8Y4IBQsKC_p1_t23Xw2jsg_kWN_m8FMSd0_8sT0cX-V1SY6KkniJnN8TSN5mbbZw3FoXV-qOxPjsikajw02MPI7pcGMA1Dh3ZV0sFGsUg4-fbWHWFjh3aHuIaZXruiDlUNia6TLAepAV5d4qFZsJigqD-BtNTfpEbwlutUED3YfrS5YQjUmsCPssSeuWlDTLgiXQ0g2XcXphUgQDSnzWy1onZEX77g_lPFcTIDSJ23q7gi8K2Jy0DfY2t6PUcwkGRabrpco3QDyRlaKsGwjLh6ZC6BoWssmP-HuwGP3tcGRnZddA6XhIH7KH83klk1QuVV1qrFNaqlMnnxC5ma63FDWm5G2z9ASH8u6WVDnns9_LzBr6zHwZ4lBf8NArdPR1x-BElpblP828n6ebpk21RfKNihUiw1CItluf4jpGE1OIX8RhLZaIAzMfC7JBRVLpbuYkOkh9H8qQ5YO4HO3DYIvNPpWoDYsip6QFlNRh72GaflWzY6WyoMgXEe0ODaWiwf2-8m6DOtmUuYY6eS4wV6hz2M_LdZAJ0yUmlB6pSPTGMeJYawLQn71_Bjzj0AfZQlIuQwAJo2Dlc2ubktGsd8g9_Kg_vDqdyIp4wGMrcuC4-mtf00LiiTSq-R5QP_OlIWI_Ri9zTKzgsKHFOQ9L1QbYu",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G_AfZ5X8gO2qjMmJTonxjcBf32S7IGqQleKXOY6SeGVbOGa-r416LrMIGUbrGS7JcNA5H2MlxQCvqFrmQAriozmkswImfSDKzkQMZLb5-WESJqT1Udxrb5AxFeg6haF0VL7Bqy8ZhXwW0mPTJ3P55kc3pz92GtpaDbREIE1pa9KJ6bQCCeyHSPGuHMGE-rhp2_iqz_L5jScEWjMKhhjAHrLylBFGsIUypaufb2dzS6lhWIE7ST9s0LyY-YZLvqIVTLqJoQUP_wtYr_T8d0VHJMpva4GAfoPOPaLvI_JzoBYzP-GTNWDlyV2LNpuVivqHPwlVxQrVv862JYBv1Mvj_i92oDBbjlbRsFDRrDNgJE5mnsS16DiKY-XghObzKEn3v31jIfd_a3g0e26Kx2ai5sVnxbMcFCogakr6qq4N8Y4IBQsKC_p1_t23Xw2jsg_kWN_m8FMSd0_8sT0cX-V1SY6KkniJnN8TSN5mbbZw3FoXV-qOxPjsikajw02MPI7pcGMA1Dh3ZV0sFGsUg4-fbWHWFjh3aHuIaZXruiDlUNia6TLAepAV5d4qFZsJigqD-BtNTfpEbwlutUED3YfrS5YQjUmsCPssSeuWlDTLgiXQ0g2XcXphUgQDSnzWy1onZEX77g_lPFcTIDSJ23q7gi8K2Jy0DfY2t6PUcwkGRabrpco3QDyRlaKsGwjLh6ZC6BoWssmP-HuwGP3tcGRnZddA6XhIH7KH83klk1QuVV1qrFNaqlMnnxC5ma63FDWm5G2z9ASH8u6WVDnns9_LzBr6zHwZ4lBf8NArdPR1x-BElpblP828n6ebpk21RfKNihUiw1CItluf4jpGE1OIX8RhLZaIAzMfC7JBRVLpbuYkOkh9H8qQ5YO4HO3DYIvNPpWoDYsip6QFlNRh72GaflWzY6WyoMgXEe0ODaWiwf2-8m6DOtmUuYY6eS4wV6hz2M_LdZAJ0yUmlB6pSPTGMeJYawLQn71_Bjzj0AfZQlIuQwAJo2Dlc2ubktGsd8g9_Kg_vDqdyIp4wGMrcuC4-mtf00LiiTSq-R5QP_OlIWI_Ri9zTKzgsKHFOQ9L1QbYu",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "28h15m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Barcelona",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b0000708682db",
                    "equipment": null,
                    "fare_basis": "QIL2A3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9791,
                    "flyFrom": "BCN",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "01af0f9c4e2b0000708682db_0",
                    "local_arrival": "2024-10-15T18:10:00.000Z",
                    "local_departure": "2024-10-15T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:10:00.000Z",
                    "utc_departure": "2024-10-15T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 1
              }
            ],
            "quality": 1057.57,
            "totalPrice": 875
          },
          {
            "allPassengerTotalPrice": 924,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLPEp6bfziVm6dM9dtwGzvy1Q5kSNd3Tn4CA17bkKOb9jIySE7jQSuUe4eoDnsPW2GIfhlKoYmsLy2r06JyeRO2VOiL0LaOGfNl4A_IPbCwYCuPQ9qVNBiikQPu7NdicZ2ylXG0prgBlhr4fTi0oo_mRC6RHwIN_nJbG7BbD_GcZ904UZMTbX6Vbu0Se2ODX0Yk0UrWIkULNrmE-rJZIrjnnQZLNjwdbGSJfK8ELck8_WWz6oAJ4QuJ7z9WcwvEXW_FgX9laGiQxi9HNBqZY_rWGBav6x4wq6iFBu0RnwCyWd2L5vnm4R3xFwejB8yGr2SYtBLdtxz8ObsSlG3T9b95MmQZPQ_hqdNRE1e0W_Ge2X-ei6d07Ujvf_lOozofZFgDVzsuxF_smF0eERFLFXhyISSR1jlQScF2dpCQ9h7lfJ53HwjMS_eJmpTMzkJIDVQrDDRVDRRq_LdRMntTKJYaA8ViXe0-f8v4PIEXslEfbG3XWfhGV-gHvXhNyVh8gD5buATXjJon31_59JSDOdx_VjEe3kzDMQWSQrgrdR9Q-EDpxKe-112nSS1KAJobooew7CZQzi1VDC-0DZGYaapBwG6n9PQuBmMkv2WHGfkOvAnTYHAq30VknBpXmUZFuqlVDMSV2siznxGfT4AwWDo4oJVLe0fIsXjcNzbOnV2V6vCnCVVBwIBCjjf9Q89bmrJJJ-iSCxbYNi8LrjxgMuTBQXZdW1fR51F_LkbmFANsWVAvoss4QklU1ZhD6FS5Dey3MClbz0KpH4PWqY9CfhilCpA3WHlhwAiKSnCrDkkjHlHN7YRw82k3PHtbkwXzsF6NmNSPGNaOQmAWCyJhLlIMB9EiB2EzkRu4O7D_MTo4O1oSx2Ri0w8ymTrG4xdREbF2cmhco5154aaIhUVgXOJGoALxGcuGy_F6vaaolsJJ7YMJFODYFLgYVTSmn6Pgb_JHiMd12A2FGy1W-AMUaW9onfOG7KR2fNUic16bu8cSLP_ZqsUPEFt7Qp1h1ht6J6TkD8IpizrLwlH-lLGCVQkVzjtbw484uWjpFfDanldcTu-VBC1q7U7xJt_jYW94NdvYQ0YdTuPXGVNKCGsgn4ys3SENs14uH0joZqvkS409t1aOvvP63jfinkJEOcRkC22OpdTeGmHEuwTbC3jDtRoxKTDMhDN5m6Ddvtyt5-E7M=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLPEp6bfziVm6dM9dtwGzvy1Q5kSNd3Tn4CA17bkKOb9jIySE7jQSuUe4eoDnsPW2GIfhlKoYmsLy2r06JyeRO2VOiL0LaOGfNl4A_IPbCwYCuPQ9qVNBiikQPu7NdicZ2ylXG0prgBlhr4fTi0oo_mRC6RHwIN_nJbG7BbD_GcZ904UZMTbX6Vbu0Se2ODX0Yk0UrWIkULNrmE-rJZIrjnnQZLNjwdbGSJfK8ELck8_WWz6oAJ4QuJ7z9WcwvEXW_FgX9laGiQxi9HNBqZY_rWGBav6x4wq6iFBu0RnwCyWd2L5vnm4R3xFwejB8yGr2SYtBLdtxz8ObsSlG3T9b95MmQZPQ_hqdNRE1e0W_Ge2X-ei6d07Ujvf_lOozofZFgDVzsuxF_smF0eERFLFXhyISSR1jlQScF2dpCQ9h7lfJ53HwjMS_eJmpTMzkJIDVQrDDRVDRRq_LdRMntTKJYaA8ViXe0-f8v4PIEXslEfbG3XWfhGV-gHvXhNyVh8gD5buATXjJon31_59JSDOdx_VjEe3kzDMQWSQrgrdR9Q-EDpxKe-112nSS1KAJobooew7CZQzi1VDC-0DZGYaapBwG6n9PQuBmMkv2WHGfkOvAnTYHAq30VknBpXmUZFuqlVDMSV2siznxGfT4AwWDo4oJVLe0fIsXjcNzbOnV2V6vCnCVVBwIBCjjf9Q89bmrJJJ-iSCxbYNi8LrjxgMuTBQXZdW1fR51F_LkbmFANsWVAvoss4QklU1ZhD6FS5Dey3MClbz0KpH4PWqY9CfhilCpA3WHlhwAiKSnCrDkkjHlHN7YRw82k3PHtbkwXzsF6NmNSPGNaOQmAWCyJhLlIMB9EiB2EzkRu4O7D_MTo4O1oSx2Ri0w8ymTrG4xdREbF2cmhco5154aaIhUVgXOJGoALxGcuGy_F6vaaolsJJ7YMJFODYFLgYVTSmn6Pgb_JHiMd12A2FGy1W-AMUaW9onfOG7KR2fNUic16bu8cSLP_ZqsUPEFt7Qp1h1ht6J6TkD8IpizrLwlH-lLGCVQkVzjtbw484uWjpFfDanldcTu-VBC1q7U7xJt_jYW94NdvYQ0YdTuPXGVNKCGsgn4ys3SENs14uH0joZqvkS409t1aOvvP63jfinkJEOcRkC22OpdTeGmHEuwTbC3jDtRoxKTDMhDN5m6Ddvtyt5-E7M=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLPEp6bfziVm6dM9dtwGzvy1Q5kSNd3Tn4CA17bkKOb9jIySE7jQSuUe4eoDnsPW2GIfhlKoYmsLy2r06JyeRO2VOiL0LaOGfNl4A_IPbCwYCuPQ9qVNBiikQPu7NdicZ2ylXG0prgBlhr4fTi0oo_mRC6RHwIN_nJbG7BbD_GcZ904UZMTbX6Vbu0Se2ODX0Yk0UrWIkULNrmE-rJZIrjnnQZLNjwdbGSJfK8ELck8_WWz6oAJ4QuJ7z9WcwvEXW_FgX9laGiQxi9HNBqZY_rWGBav6x4wq6iFBu0RnwCyWd2L5vnm4R3xFwejB8yGr2SYtBLdtxz8ObsSlG3T9b95MmQZPQ_hqdNRE1e0W_Ge2X-ei6d07Ujvf_lOozofZFgDVzsuxF_smF0eERFLFXhyISSR1jlQScF2dpCQ9h7lfJ53HwjMS_eJmpTMzkJIDVQrDDRVDRRq_LdRMntTKJYaA8ViXe0-f8v4PIEXslEfbG3XWfhGV-gHvXhNyVh8gD5buATXjJon31_59JSDOdx_VjEe3kzDMQWSQrgrdR9Q-EDpxKe-112nSS1KAJobooew7CZQzi1VDC-0DZGYaapBwG6n9PQuBmMkv2WHGfkOvAnTYHAq30VknBpXmUZFuqlVDMSV2siznxGfT4AwWDo4oJVLe0fIsXjcNzbOnV2V6vCnCVVBwIBCjjf9Q89bmrJJJ-iSCxbYNi8LrjxgMuTBQXZdW1fR51F_LkbmFANsWVAvoss4QklU1ZhD6FS5Dey3MClbz0KpH4PWqY9CfhilCpA3WHlhwAiKSnCrDkkjHlHN7YRw82k3PHtbkwXzsF6NmNSPGNaOQmAWCyJhLlIMB9EiB2EzkRu4O7D_MTo4O1oSx2Ri0w8ymTrG4xdREbF2cmhco5154aaIhUVgXOJGoALxGcuGy_F6vaaolsJJ7YMJFODYFLgYVTSmn6Pgb_JHiMd12A2FGy1W-AMUaW9onfOG7KR2fNUic16bu8cSLP_ZqsUPEFt7Qp1h1ht6J6TkD8IpizrLwlH-lLGCVQkVzjtbw484uWjpFfDanldcTu-VBC1q7U7xJt_jYW94NdvYQ0YdTuPXGVNKCGsgn4ys3SENs14uH0joZqvkS409t1aOvvP63jfinkJEOcRkC22OpdTeGmHEuwTbC3jDtRoxKTDMhDN5m6Ddvtyt5-E7M=",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1069.54,
            "totalPrice": 924
          },
          {
            "allPassengerTotalPrice": 924,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9bZ4X3muxoYUH2zHeyFCa8g0I1PdO9OnnAGWw9OECIhpdDstuxnB5e5wK0SFWi2oT5nnwZadLLPZH9tlj1m-WAbz4Ke30tc1AQd_WEOt-Jd2OF7wT_M_8SXi5Cw91WW5yCgRyDjWjesfL-eLUqMQXDqWQsVtPIfEmBny1Cxyi_V3jyEmnW35FT1UoUScQ0Rak99gpoqLObPSWnBg76EwB3KAHzA3ZhW_uKi3v-eFp-tI4CYocTSqaQRQT-YtrqnpbyFcA3D1o1k_Pfr2lWnjYFf0KaQ-lGkDiRJaQSKlcZvYpPAIrIU23VW3tRo96EqZigl7nM7vvyYV6BG4uA8NdDFDkUI6wYNQPXz14gHvnLVvQScydHASekjb37tXFAYX0IQIXg_WSedTglqh-KGjZ4dGwNsX8W7-jiQJ0k8mwpo9GcQ06jz3FOBlywZHekxULfX63ZpCcY-Dqht9K4UGdQfyF2pZanb16SWYlC-Rye7P9-XQppyWc84AkSzxAwYYYywsAaDzfyflTsaEweUNNxZzApl31R0iQVwyUrLc2MEzqs1AAe0ATtLnivnJSXutZB5vIlDvJu_gEGvHmV8YztUWoEQQRmpd9hw0yIgevBfYB7WWcMMuge0Y4RZYEUx312TykGocfylFz2e5HtR5TMFlRy-0x1eqPJbr8QkxM1EFjFkqyRLGg1gHxR_J9v1pdbQiYk5Z1dqDegFsQYPWIcm-RK3OGFYraeFkc7yFX5t7hjH6srfS-4M1lBuCG6GD8up6uDVLjYKBBa8m3PVwlICvErh51R8bVG8rwF6QgnihPH03Ib3zdoW2PDCQ54IL88T50G40UZz9yJfGw0-opUQX3F1d9bHHYZyvK7fmwt2pcydKqOuc7OUJTUajZgLXAeAVptjKrvG99g55T32veQ_LJ_9B9donoaR7R5xEq9I9UnYJFO24iC1GxdOWn0nbEhfO8nQwPBVFy0H402aMgyRZCa6UnhcW9IyUuwOSJVeoZ9qyZxnq0Evq-hluogLSalrlcIYQz_XTwv32AQ5ecgnicV61fHBbYkjkTu-SqjdtA_wvXhMbkdqGZucdSuL6ZeN93fQTwrsGT5T51FJBpj8m75uyouTrbADpRTtPCfC37JNEFrBDY6exUYsZf5QXmRlXSsgr3j6L6tLZTSqCmQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9bZ4X3muxoYUH2zHeyFCa8g0I1PdO9OnnAGWw9OECIhpdDstuxnB5e5wK0SFWi2oT5nnwZadLLPZH9tlj1m-WAbz4Ke30tc1AQd_WEOt-Jd2OF7wT_M_8SXi5Cw91WW5yCgRyDjWjesfL-eLUqMQXDqWQsVtPIfEmBny1Cxyi_V3jyEmnW35FT1UoUScQ0Rak99gpoqLObPSWnBg76EwB3KAHzA3ZhW_uKi3v-eFp-tI4CYocTSqaQRQT-YtrqnpbyFcA3D1o1k_Pfr2lWnjYFf0KaQ-lGkDiRJaQSKlcZvYpPAIrIU23VW3tRo96EqZigl7nM7vvyYV6BG4uA8NdDFDkUI6wYNQPXz14gHvnLVvQScydHASekjb37tXFAYX0IQIXg_WSedTglqh-KGjZ4dGwNsX8W7-jiQJ0k8mwpo9GcQ06jz3FOBlywZHekxULfX63ZpCcY-Dqht9K4UGdQfyF2pZanb16SWYlC-Rye7P9-XQppyWc84AkSzxAwYYYywsAaDzfyflTsaEweUNNxZzApl31R0iQVwyUrLc2MEzqs1AAe0ATtLnivnJSXutZB5vIlDvJu_gEGvHmV8YztUWoEQQRmpd9hw0yIgevBfYB7WWcMMuge0Y4RZYEUx312TykGocfylFz2e5HtR5TMFlRy-0x1eqPJbr8QkxM1EFjFkqyRLGg1gHxR_J9v1pdbQiYk5Z1dqDegFsQYPWIcm-RK3OGFYraeFkc7yFX5t7hjH6srfS-4M1lBuCG6GD8up6uDVLjYKBBa8m3PVwlICvErh51R8bVG8rwF6QgnihPH03Ib3zdoW2PDCQ54IL88T50G40UZz9yJfGw0-opUQX3F1d9bHHYZyvK7fmwt2pcydKqOuc7OUJTUajZgLXAeAVptjKrvG99g55T32veQ_LJ_9B9donoaR7R5xEq9I9UnYJFO24iC1GxdOWn0nbEhfO8nQwPBVFy0H402aMgyRZCa6UnhcW9IyUuwOSJVeoZ9qyZxnq0Evq-hluogLSalrlcIYQz_XTwv32AQ5ecgnicV61fHBbYkjkTu-SqjdtA_wvXhMbkdqGZucdSuL6ZeN93fQTwrsGT5T51FJBpj8m75uyouTrbADpRTtPCfC37JNEFrBDY6exUYsZf5QXmRlXSsgr3j6L6tLZTSqCmQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G9bZ4X3muxoYUH2zHeyFCa8g0I1PdO9OnnAGWw9OECIhpdDstuxnB5e5wK0SFWi2oT5nnwZadLLPZH9tlj1m-WAbz4Ke30tc1AQd_WEOt-Jd2OF7wT_M_8SXi5Cw91WW5yCgRyDjWjesfL-eLUqMQXDqWQsVtPIfEmBny1Cxyi_V3jyEmnW35FT1UoUScQ0Rak99gpoqLObPSWnBg76EwB3KAHzA3ZhW_uKi3v-eFp-tI4CYocTSqaQRQT-YtrqnpbyFcA3D1o1k_Pfr2lWnjYFf0KaQ-lGkDiRJaQSKlcZvYpPAIrIU23VW3tRo96EqZigl7nM7vvyYV6BG4uA8NdDFDkUI6wYNQPXz14gHvnLVvQScydHASekjb37tXFAYX0IQIXg_WSedTglqh-KGjZ4dGwNsX8W7-jiQJ0k8mwpo9GcQ06jz3FOBlywZHekxULfX63ZpCcY-Dqht9K4UGdQfyF2pZanb16SWYlC-Rye7P9-XQppyWc84AkSzxAwYYYywsAaDzfyflTsaEweUNNxZzApl31R0iQVwyUrLc2MEzqs1AAe0ATtLnivnJSXutZB5vIlDvJu_gEGvHmV8YztUWoEQQRmpd9hw0yIgevBfYB7WWcMMuge0Y4RZYEUx312TykGocfylFz2e5HtR5TMFlRy-0x1eqPJbr8QkxM1EFjFkqyRLGg1gHxR_J9v1pdbQiYk5Z1dqDegFsQYPWIcm-RK3OGFYraeFkc7yFX5t7hjH6srfS-4M1lBuCG6GD8up6uDVLjYKBBa8m3PVwlICvErh51R8bVG8rwF6QgnihPH03Ib3zdoW2PDCQ54IL88T50G40UZz9yJfGw0-opUQX3F1d9bHHYZyvK7fmwt2pcydKqOuc7OUJTUajZgLXAeAVptjKrvG99g55T32veQ_LJ_9B9donoaR7R5xEq9I9UnYJFO24iC1GxdOWn0nbEhfO8nQwPBVFy0H402aMgyRZCa6UnhcW9IyUuwOSJVeoZ9qyZxnq0Evq-hluogLSalrlcIYQz_XTwv32AQ5ecgnicV61fHBbYkjkTu-SqjdtA_wvXhMbkdqGZucdSuL6ZeN93fQTwrsGT5T51FJBpj8m75uyouTrbADpRTtPCfC37JNEFrBDY6exUYsZf5QXmRlXSsgr3j6L6tLZTSqCmQ==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1069.54,
            "totalPrice": 924
          },
          {
            "allPassengerTotalPrice": 924,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZpZhbZ8QvUmdKgqfRWb07Un0BrL81ywbOMfs1RaSATCaNUgw2UawqxQ5_kaRNki_-EiF433IV8DywhQgSBnQ2zlmk2UldNHXRna78W7T_eMbIBY6moc9Ac3_UXDTq27zZSrBg1RP7ZXIuJePOm8K9yDsxpgE9_3UgKid79KHNboiUCudz7ApmG5fiem0eazLmZPFVQrYWo8NiiO3C2EZqCiwSuIDK8bRMiVlCrpiWqc2_V6D47MY7ySwMRV0c7z2PB_A_wW6mOuwuv7kB7YMn5z4GKpsB2LQPyyG1thRZeq_GFBdiY9NgAw8VRpa7tCQSOsp2lZZF3Wm6d-4HWKigsGNRfFDWUxASzI3KleSFkeg9e0x71GNFt2wrtDSAaLXcnqchUz8SwzuQKwsqI9FgO9mhbVSv_B7-vnfbnprRSFlfia6Lo1zMKTTmpommdcad-HMJOF67x6WCLg9-zp3W4EejVo6tT0QxIQB81KxxrGctagVvNfJwhV1k5OyQMmU-tZjuRF_xFHMf4x-lCTnU725vl9a-lgzrm3BrFidBhAQFIJKl0HcMogNt6QAPc3VtXinswPlyPD-NQbJI6qWyAVJF_dzcdetSmva2SgzRScj6sqL1eSI6PM9Bl0jLo9fgRyGBfiGCV90ESZ0ftYhJzN-4iEjouYFJNwSxSudVBZKKvY4JndDHPUF9pwQ4V5ZhuwjPlSEK1V_nCy-8jQi5z94S84AueK5UKxT8ktwnUDvG_bR-YYnQCMxFFSHKwPqWqdp_WIs0QMVxgu_xKQ6ysflBmNivllbdc5_i7s8Umb9aftpWqc_z78QNy08fGTQbPhyvNJa88s0iHlxpSS0tfCBZEc6rrP0C5yOcOWBlaRGdEiarSIpocB8hod6mG8pPSfFPQFo__QRI3xKHuRuYNMlmX0q-5P692lJMT54sFJXLWMICqgXjHB9DgwMreLyU34pO9zAf3-Y5fWcAR45EJmQPsMx7KnWomD_FGZBRuRupcvhxlcgi93Rl0iSxTQKFQ9Sl2OznvgtMBV1M7Gg5lF3QdJ4ywYQoDYQgBEwOQe-l2dH5FV4SRNjI4ShnB3BddZR5aY8b09tOHNOt2Wd6zSRgzIhWn8ZdZrNPrL4e7MPQypb4mqMxJvPtZHn3h8kz-TmAzBgJAankCRjRLwwuA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZpZhbZ8QvUmdKgqfRWb07Un0BrL81ywbOMfs1RaSATCaNUgw2UawqxQ5_kaRNki_-EiF433IV8DywhQgSBnQ2zlmk2UldNHXRna78W7T_eMbIBY6moc9Ac3_UXDTq27zZSrBg1RP7ZXIuJePOm8K9yDsxpgE9_3UgKid79KHNboiUCudz7ApmG5fiem0eazLmZPFVQrYWo8NiiO3C2EZqCiwSuIDK8bRMiVlCrpiWqc2_V6D47MY7ySwMRV0c7z2PB_A_wW6mOuwuv7kB7YMn5z4GKpsB2LQPyyG1thRZeq_GFBdiY9NgAw8VRpa7tCQSOsp2lZZF3Wm6d-4HWKigsGNRfFDWUxASzI3KleSFkeg9e0x71GNFt2wrtDSAaLXcnqchUz8SwzuQKwsqI9FgO9mhbVSv_B7-vnfbnprRSFlfia6Lo1zMKTTmpommdcad-HMJOF67x6WCLg9-zp3W4EejVo6tT0QxIQB81KxxrGctagVvNfJwhV1k5OyQMmU-tZjuRF_xFHMf4x-lCTnU725vl9a-lgzrm3BrFidBhAQFIJKl0HcMogNt6QAPc3VtXinswPlyPD-NQbJI6qWyAVJF_dzcdetSmva2SgzRScj6sqL1eSI6PM9Bl0jLo9fgRyGBfiGCV90ESZ0ftYhJzN-4iEjouYFJNwSxSudVBZKKvY4JndDHPUF9pwQ4V5ZhuwjPlSEK1V_nCy-8jQi5z94S84AueK5UKxT8ktwnUDvG_bR-YYnQCMxFFSHKwPqWqdp_WIs0QMVxgu_xKQ6ysflBmNivllbdc5_i7s8Umb9aftpWqc_z78QNy08fGTQbPhyvNJa88s0iHlxpSS0tfCBZEc6rrP0C5yOcOWBlaRGdEiarSIpocB8hod6mG8pPSfFPQFo__QRI3xKHuRuYNMlmX0q-5P692lJMT54sFJXLWMICqgXjHB9DgwMreLyU34pO9zAf3-Y5fWcAR45EJmQPsMx7KnWomD_FGZBRuRupcvhxlcgi93Rl0iSxTQKFQ9Sl2OznvgtMBV1M7Gg5lF3QdJ4ywYQoDYQgBEwOQe-l2dH5FV4SRNjI4ShnB3BddZR5aY8b09tOHNOt2Wd6zSRgzIhWn8ZdZrNPrL4e7MPQypb4mqMxJvPtZHn3h8kz-TmAzBgJAankCRjRLwwuA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GZpZhbZ8QvUmdKgqfRWb07Un0BrL81ywbOMfs1RaSATCaNUgw2UawqxQ5_kaRNki_-EiF433IV8DywhQgSBnQ2zlmk2UldNHXRna78W7T_eMbIBY6moc9Ac3_UXDTq27zZSrBg1RP7ZXIuJePOm8K9yDsxpgE9_3UgKid79KHNboiUCudz7ApmG5fiem0eazLmZPFVQrYWo8NiiO3C2EZqCiwSuIDK8bRMiVlCrpiWqc2_V6D47MY7ySwMRV0c7z2PB_A_wW6mOuwuv7kB7YMn5z4GKpsB2LQPyyG1thRZeq_GFBdiY9NgAw8VRpa7tCQSOsp2lZZF3Wm6d-4HWKigsGNRfFDWUxASzI3KleSFkeg9e0x71GNFt2wrtDSAaLXcnqchUz8SwzuQKwsqI9FgO9mhbVSv_B7-vnfbnprRSFlfia6Lo1zMKTTmpommdcad-HMJOF67x6WCLg9-zp3W4EejVo6tT0QxIQB81KxxrGctagVvNfJwhV1k5OyQMmU-tZjuRF_xFHMf4x-lCTnU725vl9a-lgzrm3BrFidBhAQFIJKl0HcMogNt6QAPc3VtXinswPlyPD-NQbJI6qWyAVJF_dzcdetSmva2SgzRScj6sqL1eSI6PM9Bl0jLo9fgRyGBfiGCV90ESZ0ftYhJzN-4iEjouYFJNwSxSudVBZKKvY4JndDHPUF9pwQ4V5ZhuwjPlSEK1V_nCy-8jQi5z94S84AueK5UKxT8ktwnUDvG_bR-YYnQCMxFFSHKwPqWqdp_WIs0QMVxgu_xKQ6ysflBmNivllbdc5_i7s8Umb9aftpWqc_z78QNy08fGTQbPhyvNJa88s0iHlxpSS0tfCBZEc6rrP0C5yOcOWBlaRGdEiarSIpocB8hod6mG8pPSfFPQFo__QRI3xKHuRuYNMlmX0q-5P692lJMT54sFJXLWMICqgXjHB9DgwMreLyU34pO9zAf3-Y5fWcAR45EJmQPsMx7KnWomD_FGZBRuRupcvhxlcgi93Rl0iSxTQKFQ9Sl2OznvgtMBV1M7Gg5lF3QdJ4ywYQoDYQgBEwOQe-l2dH5FV4SRNjI4ShnB3BddZR5aY8b09tOHNOt2Wd6zSRgzIhWn8ZdZrNPrL4e7MPQypb4mqMxJvPtZHn3h8kz-TmAzBgJAankCRjRLwwuA==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1070.21,
            "totalPrice": 924
          },
          {
            "allPassengerTotalPrice": 924,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs79SqWXCVkACvNSt44vsnswCkAIy0FsJCXz4PzWvsXkE9cNjL6qnWlUqin1-o5umCiMepePn0wGaqoilIkMVkolGlH7ypMW-Yc3ttIuaoMXDlwQ44SmWdtlO64dF1dpJyZxkRLlsD8q4YuxUKUZpZfuTEEj_Rth2d5l94uJo07jLErZJygGcf6MRj5cQJeqNjSXcgu_pc1Aaa3myEoTdxQ3aRuVHKTed2I1b6Jb6O2e3uBQpWMvqGr2vCsNAKIvNSP-vew_HoPTJDxu-xJTZCFNe28gfs1O70stGFY348pLSoMSVoOjQMLFAo-XFcdpvCgeSGh7Z6JWhge9dMeKnxxK4NYkflEzSpsVwEAfUOKG3aunS8zzk_nspWa_DcOepUhGSYaqduAHVpwzfmBAQYLAZ1teSI7DIBhP8W3NJ3BByQLuULpnB7ny9qtKmOzmwKuMJ-RQ8GfqSctEWBoW_Lgymq_4auZnrEQZn4RxpvSFKj8He8R9stru7_G8xAlXCra-qM7w-srYerSiTaF_LrL4r2uwzmnRuRZkgBbZokkHu2FekRNVZLbIsvrErWMM0dFTTa5B5KGduh3fbYANXi9phisFNGJkS7FkwMtlGmYqKRtXvBobVs_2KdQx_bllFv7kxRdy56px42y4ttos8zDj9Lsd9g0xRDCucYS179FbOgG23Bc0ARCCI7IgrOWSWP178gAltSyLczIiOFHFhClRuIBM7aUZ-ShjnJdstqVuIOdQnl36orUkZK7FyIPoAbpHBV764DRtIpi-FxXj89jfHssklzEc-DkWMETxzSKbC3oUFdIlyDAasA4x24yozQp_Aw3KiCqdMIiSFV74Tg2RfmwbqPXQbqTvD-thSoliNpL1TW9gbH2SsQfJelfrnxIFxITmx0T0Wt0_oVwiH21kbLSr7rzJqDfpnapFF7bV87iuk9Og3xZhPw-dqqXz2iYIPscXbP9zfNbd68X0XuZsxRBqHGN0r_hgwMSkQPshawLA_cheE68GzOdRX9xPIIlGdqQoBl8P0frZ3va_w9_nFdWnW73KCMxRSX8bR7dgAOAB9PpX6BFpRAS9ksPupar7DmNuBo89dk_pY6mUMbfRO_y_-QahU4PiovpuhUiPOUaqXCIkCY4nkIUodhn8WvOb8jYPsERN_TieCsocvvA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs79SqWXCVkACvNSt44vsnswCkAIy0FsJCXz4PzWvsXkE9cNjL6qnWlUqin1-o5umCiMepePn0wGaqoilIkMVkolGlH7ypMW-Yc3ttIuaoMXDlwQ44SmWdtlO64dF1dpJyZxkRLlsD8q4YuxUKUZpZfuTEEj_Rth2d5l94uJo07jLErZJygGcf6MRj5cQJeqNjSXcgu_pc1Aaa3myEoTdxQ3aRuVHKTed2I1b6Jb6O2e3uBQpWMvqGr2vCsNAKIvNSP-vew_HoPTJDxu-xJTZCFNe28gfs1O70stGFY348pLSoMSVoOjQMLFAo-XFcdpvCgeSGh7Z6JWhge9dMeKnxxK4NYkflEzSpsVwEAfUOKG3aunS8zzk_nspWa_DcOepUhGSYaqduAHVpwzfmBAQYLAZ1teSI7DIBhP8W3NJ3BByQLuULpnB7ny9qtKmOzmwKuMJ-RQ8GfqSctEWBoW_Lgymq_4auZnrEQZn4RxpvSFKj8He8R9stru7_G8xAlXCra-qM7w-srYerSiTaF_LrL4r2uwzmnRuRZkgBbZokkHu2FekRNVZLbIsvrErWMM0dFTTa5B5KGduh3fbYANXi9phisFNGJkS7FkwMtlGmYqKRtXvBobVs_2KdQx_bllFv7kxRdy56px42y4ttos8zDj9Lsd9g0xRDCucYS179FbOgG23Bc0ARCCI7IgrOWSWP178gAltSyLczIiOFHFhClRuIBM7aUZ-ShjnJdstqVuIOdQnl36orUkZK7FyIPoAbpHBV764DRtIpi-FxXj89jfHssklzEc-DkWMETxzSKbC3oUFdIlyDAasA4x24yozQp_Aw3KiCqdMIiSFV74Tg2RfmwbqPXQbqTvD-thSoliNpL1TW9gbH2SsQfJelfrnxIFxITmx0T0Wt0_oVwiH21kbLSr7rzJqDfpnapFF7bV87iuk9Og3xZhPw-dqqXz2iYIPscXbP9zfNbd68X0XuZsxRBqHGN0r_hgwMSkQPshawLA_cheE68GzOdRX9xPIIlGdqQoBl8P0frZ3va_w9_nFdWnW73KCMxRSX8bR7dgAOAB9PpX6BFpRAS9ksPupar7DmNuBo89dk_pY6mUMbfRO_y_-QahU4PiovpuhUiPOUaqXCIkCY4nkIUodhn8WvOb8jYPsERN_TieCsocvvA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gs79SqWXCVkACvNSt44vsnswCkAIy0FsJCXz4PzWvsXkE9cNjL6qnWlUqin1-o5umCiMepePn0wGaqoilIkMVkolGlH7ypMW-Yc3ttIuaoMXDlwQ44SmWdtlO64dF1dpJyZxkRLlsD8q4YuxUKUZpZfuTEEj_Rth2d5l94uJo07jLErZJygGcf6MRj5cQJeqNjSXcgu_pc1Aaa3myEoTdxQ3aRuVHKTed2I1b6Jb6O2e3uBQpWMvqGr2vCsNAKIvNSP-vew_HoPTJDxu-xJTZCFNe28gfs1O70stGFY348pLSoMSVoOjQMLFAo-XFcdpvCgeSGh7Z6JWhge9dMeKnxxK4NYkflEzSpsVwEAfUOKG3aunS8zzk_nspWa_DcOepUhGSYaqduAHVpwzfmBAQYLAZ1teSI7DIBhP8W3NJ3BByQLuULpnB7ny9qtKmOzmwKuMJ-RQ8GfqSctEWBoW_Lgymq_4auZnrEQZn4RxpvSFKj8He8R9stru7_G8xAlXCra-qM7w-srYerSiTaF_LrL4r2uwzmnRuRZkgBbZokkHu2FekRNVZLbIsvrErWMM0dFTTa5B5KGduh3fbYANXi9phisFNGJkS7FkwMtlGmYqKRtXvBobVs_2KdQx_bllFv7kxRdy56px42y4ttos8zDj9Lsd9g0xRDCucYS179FbOgG23Bc0ARCCI7IgrOWSWP178gAltSyLczIiOFHFhClRuIBM7aUZ-ShjnJdstqVuIOdQnl36orUkZK7FyIPoAbpHBV764DRtIpi-FxXj89jfHssklzEc-DkWMETxzSKbC3oUFdIlyDAasA4x24yozQp_Aw3KiCqdMIiSFV74Tg2RfmwbqPXQbqTvD-thSoliNpL1TW9gbH2SsQfJelfrnxIFxITmx0T0Wt0_oVwiH21kbLSr7rzJqDfpnapFF7bV87iuk9Og3xZhPw-dqqXz2iYIPscXbP9zfNbd68X0XuZsxRBqHGN0r_hgwMSkQPshawLA_cheE68GzOdRX9xPIIlGdqQoBl8P0frZ3va_w9_nFdWnW73KCMxRSX8bR7dgAOAB9PpX6BFpRAS9ksPupar7DmNuBo89dk_pY6mUMbfRO_y_-QahU4PiovpuhUiPOUaqXCIkCY4nkIUodhn8WvOb8jYPsERN_TieCsocvvA==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1071.05,
            "totalPrice": 924
          },
          {
            "allPassengerTotalPrice": 931,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnCJj11_adFPyG-Vr0Ds1xRbekv77G0v6NZ9FtRlGEK1f59Wf5Nau73xeVa70dlCa5zuz9SIMY_UfeohSyDEePx8HAnsh55C7JFSJY6Q36e87kkJNkul5ISU15-iEzEbsWPNRBtHlErzgaL6yXf5-AxwzdAFxug4qUjd6mX7P4aaPb5lxRlo4OVmNJlSy7R8jel17fsApf3j3Gw7ui6qRjpBrGcLx15dtWdcuJOqGT6ZMNp0NVCee10wBcfQms23VO0KgUJVOumNG3UcC4UjcvZ9FvAiHhXpButTkzuKZ6Uq6to4j2dfivibroHFno3FPzRyPchbgAeo-cHo7vIRkraqpDZOFcWTUxQbZ5njj7ccMdpCGKlrWz2wag9XWFTsngLHVvhYmTVmQ1wt_OytfJXixw6Vzvq_0YMLrxaxdZs40xgnkbvTjk-GACjVGT7UHAZbuXQkWEZu3f_x8LoDgjFn9IcrJNQDk9ddqao5KdhFSM-WkCACZ9TpkYq_oq2btvy-8JtQhtxCL2VFIDd7mh41912Xdg_KgFfE23IsJJqBJZIEYRLzymXXS3MFsUxfypa_uEZYy2zmdcgYSc3ASRLxMbmFFkQCxPO4YzXw4WDtjSGBl0UdpOnYUfty4VA07boHBQP7JMSv-z9PqLZ96Lb1V1seCeTkXPCFNsYIPtCYP6XCU3yAPyIZbOl7qySPBOOrz9pZ3gXUnSauNmO-Fh0cMyOwG_X9obQlRvfN6nLhxCiye0EJsrkRCuRp71T_nl7pPqpX3BSUfmbMq6g4v-dAnIwsrE5RtZPWJWoISX06NGJVO2nuwg0kHUoEm_hJuXWHkbi406CEP-5v7IBKEqFR5juPRLlbWfkFUCbxTca2cfI6Hwzyd1C0Lem2HSTZ5BRB1sluqwsZk1u7_H-hARgzlUnnaNytCNyZL2TA2BInN1eHP5SOnUTUjLSbCmKXeWB8zXxsgRPABuZbpd39zlCTsB0PfgB2VU2wPOF_ZIPKvnudfM2OBEB9jL6G3UfJZLTpapBX-EZEoJDgf-ihg5JKQBVFk_vLzH98aBMbL7E5gUthIQNmC609M1oQYwu6MU9J5ttGao_W63QtFGu9l19jUPzi2JNzcaWTA0YFuXbbAPsSLaz_xtUG-1ZBYG27rV07qjyd8IpBaeXrkmZ1EoQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnCJj11_adFPyG-Vr0Ds1xRbekv77G0v6NZ9FtRlGEK1f59Wf5Nau73xeVa70dlCa5zuz9SIMY_UfeohSyDEePx8HAnsh55C7JFSJY6Q36e87kkJNkul5ISU15-iEzEbsWPNRBtHlErzgaL6yXf5-AxwzdAFxug4qUjd6mX7P4aaPb5lxRlo4OVmNJlSy7R8jel17fsApf3j3Gw7ui6qRjpBrGcLx15dtWdcuJOqGT6ZMNp0NVCee10wBcfQms23VO0KgUJVOumNG3UcC4UjcvZ9FvAiHhXpButTkzuKZ6Uq6to4j2dfivibroHFno3FPzRyPchbgAeo-cHo7vIRkraqpDZOFcWTUxQbZ5njj7ccMdpCGKlrWz2wag9XWFTsngLHVvhYmTVmQ1wt_OytfJXixw6Vzvq_0YMLrxaxdZs40xgnkbvTjk-GACjVGT7UHAZbuXQkWEZu3f_x8LoDgjFn9IcrJNQDk9ddqao5KdhFSM-WkCACZ9TpkYq_oq2btvy-8JtQhtxCL2VFIDd7mh41912Xdg_KgFfE23IsJJqBJZIEYRLzymXXS3MFsUxfypa_uEZYy2zmdcgYSc3ASRLxMbmFFkQCxPO4YzXw4WDtjSGBl0UdpOnYUfty4VA07boHBQP7JMSv-z9PqLZ96Lb1V1seCeTkXPCFNsYIPtCYP6XCU3yAPyIZbOl7qySPBOOrz9pZ3gXUnSauNmO-Fh0cMyOwG_X9obQlRvfN6nLhxCiye0EJsrkRCuRp71T_nl7pPqpX3BSUfmbMq6g4v-dAnIwsrE5RtZPWJWoISX06NGJVO2nuwg0kHUoEm_hJuXWHkbi406CEP-5v7IBKEqFR5juPRLlbWfkFUCbxTca2cfI6Hwzyd1C0Lem2HSTZ5BRB1sluqwsZk1u7_H-hARgzlUnnaNytCNyZL2TA2BInN1eHP5SOnUTUjLSbCmKXeWB8zXxsgRPABuZbpd39zlCTsB0PfgB2VU2wPOF_ZIPKvnudfM2OBEB9jL6G3UfJZLTpapBX-EZEoJDgf-ihg5JKQBVFk_vLzH98aBMbL7E5gUthIQNmC609M1oQYwu6MU9J5ttGao_W63QtFGu9l19jUPzi2JNzcaWTA0YFuXbbAPsSLaz_xtUG-1ZBYG27rV07qjyd8IpBaeXrkmZ1EoQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GnCJj11_adFPyG-Vr0Ds1xRbekv77G0v6NZ9FtRlGEK1f59Wf5Nau73xeVa70dlCa5zuz9SIMY_UfeohSyDEePx8HAnsh55C7JFSJY6Q36e87kkJNkul5ISU15-iEzEbsWPNRBtHlErzgaL6yXf5-AxwzdAFxug4qUjd6mX7P4aaPb5lxRlo4OVmNJlSy7R8jel17fsApf3j3Gw7ui6qRjpBrGcLx15dtWdcuJOqGT6ZMNp0NVCee10wBcfQms23VO0KgUJVOumNG3UcC4UjcvZ9FvAiHhXpButTkzuKZ6Uq6to4j2dfivibroHFno3FPzRyPchbgAeo-cHo7vIRkraqpDZOFcWTUxQbZ5njj7ccMdpCGKlrWz2wag9XWFTsngLHVvhYmTVmQ1wt_OytfJXixw6Vzvq_0YMLrxaxdZs40xgnkbvTjk-GACjVGT7UHAZbuXQkWEZu3f_x8LoDgjFn9IcrJNQDk9ddqao5KdhFSM-WkCACZ9TpkYq_oq2btvy-8JtQhtxCL2VFIDd7mh41912Xdg_KgFfE23IsJJqBJZIEYRLzymXXS3MFsUxfypa_uEZYy2zmdcgYSc3ASRLxMbmFFkQCxPO4YzXw4WDtjSGBl0UdpOnYUfty4VA07boHBQP7JMSv-z9PqLZ96Lb1V1seCeTkXPCFNsYIPtCYP6XCU3yAPyIZbOl7qySPBOOrz9pZ3gXUnSauNmO-Fh0cMyOwG_X9obQlRvfN6nLhxCiye0EJsrkRCuRp71T_nl7pPqpX3BSUfmbMq6g4v-dAnIwsrE5RtZPWJWoISX06NGJVO2nuwg0kHUoEm_hJuXWHkbi406CEP-5v7IBKEqFR5juPRLlbWfkFUCbxTca2cfI6Hwzyd1C0Lem2HSTZ5BRB1sluqwsZk1u7_H-hARgzlUnnaNytCNyZL2TA2BInN1eHP5SOnUTUjLSbCmKXeWB8zXxsgRPABuZbpd39zlCTsB0PfgB2VU2wPOF_ZIPKvnudfM2OBEB9jL6G3UfJZLTpapBX-EZEoJDgf-ihg5JKQBVFk_vLzH98aBMbL7E5gUthIQNmC609M1oQYwu6MU9J5ttGao_W63QtFGu9l19jUPzi2JNzcaWTA0YFuXbbAPsSLaz_xtUG-1ZBYG27rV07qjyd8IpBaeXrkmZ1EoQ==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1075.99,
            "totalPrice": 931
          },
          {
            "allPassengerTotalPrice": 933,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyXMVazsWC4RgGqFqK8oErepA0IGas7Ya8Zdk-QRK2ZBw7THc_r8J4eRUNbxGNXmLXZnIowNd6kNSHnsx0pINH8a-ePLkMHkJBrIvjurMHuE1mNyepSDLcuC08oBbIVYjt-mrCYD8OZPvFvZTM-5om6mkG9pK7gcVADN1LdVdh8WgmhEMwsfLpaiGLF0g8ujzuVl7u40_UDKqGDF89VNzPuu6R143IEyv-DoMLkFR2AAq0WfFzq42MeNeO0qlJfst8jczTQTeWmH9CX9W69OK72r0idjcduFCjlaNA7TYuCufxnhx9DLSHIyqkktIf9gBqTGnTgVorpRX1y6vwIyy-PX24_Bt5NODBHlFzcgUZ8n1RDedRMUgkLPW9ENr0SSAgnodP7CgcVtI0nMS2dVNyFGF0fTYuchsfOWlsLB4CVQRmqjlRDo8emZq7WCP8U_ZKs7D2t8mwNCIZcp8FjoJRe7CRQxST2inAWnVRJzlwVA86D-EQGrOrVe9vXZfyMUUcoEEy7RelgNRF6wHUKQePU7J_kO7j2CPpe4-vVfjr6FfVQU2kvkmSPCze2BVipkk62JaYuzuU3HzNc93i2MP9A1RYNtejyufLqEm3IovM7TCaVZaUM--AF7R8_4TtHI372X5dm5b8v6M6tPI8l1wZSz-_OGkhl3VIaLrAYy6eRBVL8P8F1MzbfpSH7a7BiDvgbOFtzFVOXoR66GVOCJAh8jeBqnO451GVg8co3Udtd43F36IBBIKATx7Xsr9UTYN7BJevT5ipDZMZKPvbOWpomsVqRb_INIrTZCFnOncKKwcnbtEbIcpR_IdELnBSTe6b1YmXaAotYum10MlwZVysi9aTKvHho9k6D1TSGtd3uWOciKdUg02FjMBm3wqx_tJ92mcS0ieCickaUD6fPzwgchI-S6j3RvqkB8t8ggrXxJGOlx87k8slLVdD3I4h16G-19Oj3W6XQWqIhhhzJeptbsKvQrTx6GSmCqM50Iz4qB0y3eU7YYos7YOe3FtaAe-ama7poMgWuzqeXnROobLqAwfB-ssPi2LZNNGWk6yQn51v3kgBY9zciRRtdyQHi1AmvgZeAXoDqrFnK4NwD0kSTLlnKV7RVNf94u-DTYEA0OBxf517XcIwAbGonVgyJ0m9mAl3QmMyfa57upMEBUj9w==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyXMVazsWC4RgGqFqK8oErepA0IGas7Ya8Zdk-QRK2ZBw7THc_r8J4eRUNbxGNXmLXZnIowNd6kNSHnsx0pINH8a-ePLkMHkJBrIvjurMHuE1mNyepSDLcuC08oBbIVYjt-mrCYD8OZPvFvZTM-5om6mkG9pK7gcVADN1LdVdh8WgmhEMwsfLpaiGLF0g8ujzuVl7u40_UDKqGDF89VNzPuu6R143IEyv-DoMLkFR2AAq0WfFzq42MeNeO0qlJfst8jczTQTeWmH9CX9W69OK72r0idjcduFCjlaNA7TYuCufxnhx9DLSHIyqkktIf9gBqTGnTgVorpRX1y6vwIyy-PX24_Bt5NODBHlFzcgUZ8n1RDedRMUgkLPW9ENr0SSAgnodP7CgcVtI0nMS2dVNyFGF0fTYuchsfOWlsLB4CVQRmqjlRDo8emZq7WCP8U_ZKs7D2t8mwNCIZcp8FjoJRe7CRQxST2inAWnVRJzlwVA86D-EQGrOrVe9vXZfyMUUcoEEy7RelgNRF6wHUKQePU7J_kO7j2CPpe4-vVfjr6FfVQU2kvkmSPCze2BVipkk62JaYuzuU3HzNc93i2MP9A1RYNtejyufLqEm3IovM7TCaVZaUM--AF7R8_4TtHI372X5dm5b8v6M6tPI8l1wZSz-_OGkhl3VIaLrAYy6eRBVL8P8F1MzbfpSH7a7BiDvgbOFtzFVOXoR66GVOCJAh8jeBqnO451GVg8co3Udtd43F36IBBIKATx7Xsr9UTYN7BJevT5ipDZMZKPvbOWpomsVqRb_INIrTZCFnOncKKwcnbtEbIcpR_IdELnBSTe6b1YmXaAotYum10MlwZVysi9aTKvHho9k6D1TSGtd3uWOciKdUg02FjMBm3wqx_tJ92mcS0ieCickaUD6fPzwgchI-S6j3RvqkB8t8ggrXxJGOlx87k8slLVdD3I4h16G-19Oj3W6XQWqIhhhzJeptbsKvQrTx6GSmCqM50Iz4qB0y3eU7YYos7YOe3FtaAe-ama7poMgWuzqeXnROobLqAwfB-ssPi2LZNNGWk6yQn51v3kgBY9zciRRtdyQHi1AmvgZeAXoDqrFnK4NwD0kSTLlnKV7RVNf94u-DTYEA0OBxf517XcIwAbGonVgyJ0m9mAl3QmMyfa57upMEBUj9w==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GyXMVazsWC4RgGqFqK8oErepA0IGas7Ya8Zdk-QRK2ZBw7THc_r8J4eRUNbxGNXmLXZnIowNd6kNSHnsx0pINH8a-ePLkMHkJBrIvjurMHuE1mNyepSDLcuC08oBbIVYjt-mrCYD8OZPvFvZTM-5om6mkG9pK7gcVADN1LdVdh8WgmhEMwsfLpaiGLF0g8ujzuVl7u40_UDKqGDF89VNzPuu6R143IEyv-DoMLkFR2AAq0WfFzq42MeNeO0qlJfst8jczTQTeWmH9CX9W69OK72r0idjcduFCjlaNA7TYuCufxnhx9DLSHIyqkktIf9gBqTGnTgVorpRX1y6vwIyy-PX24_Bt5NODBHlFzcgUZ8n1RDedRMUgkLPW9ENr0SSAgnodP7CgcVtI0nMS2dVNyFGF0fTYuchsfOWlsLB4CVQRmqjlRDo8emZq7WCP8U_ZKs7D2t8mwNCIZcp8FjoJRe7CRQxST2inAWnVRJzlwVA86D-EQGrOrVe9vXZfyMUUcoEEy7RelgNRF6wHUKQePU7J_kO7j2CPpe4-vVfjr6FfVQU2kvkmSPCze2BVipkk62JaYuzuU3HzNc93i2MP9A1RYNtejyufLqEm3IovM7TCaVZaUM--AF7R8_4TtHI372X5dm5b8v6M6tPI8l1wZSz-_OGkhl3VIaLrAYy6eRBVL8P8F1MzbfpSH7a7BiDvgbOFtzFVOXoR66GVOCJAh8jeBqnO451GVg8co3Udtd43F36IBBIKATx7Xsr9UTYN7BJevT5ipDZMZKPvbOWpomsVqRb_INIrTZCFnOncKKwcnbtEbIcpR_IdELnBSTe6b1YmXaAotYum10MlwZVysi9aTKvHho9k6D1TSGtd3uWOciKdUg02FjMBm3wqx_tJ92mcS0ieCickaUD6fPzwgchI-S6j3RvqkB8t8ggrXxJGOlx87k8slLVdD3I4h16G-19Oj3W6XQWqIhhhzJeptbsKvQrTx6GSmCqM50Iz4qB0y3eU7YYos7YOe3FtaAe-ama7poMgWuzqeXnROobLqAwfB-ssPi2LZNNGWk6yQn51v3kgBY9zciRRtdyQHi1AmvgZeAXoDqrFnK4NwD0kSTLlnKV7RVNf94u-DTYEA0OBxf517XcIwAbGonVgyJ0m9mAl3QmMyfa57upMEBUj9w==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1077.4,
            "totalPrice": 933
          },
          {
            "allPassengerTotalPrice": 939,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoF2KSEDuvOcLN_o2Ja4lKE44XKkZA646PE8GOgo-VMeYN437VJrT2WcSWs2ZeKmMmbKNZHyqjA1pmoYpDVmBXaZO4eb86BhJS-2RKDweBRQ-hspdKUzHwp2CNYBujuDWfo95t9cngP54AVPUDH2xa5Qs0BkC29QsnQtsq3URDvCa4R8M09sjz8y1YkrAVY8c-cVTWywVa7pFut616YYObo6IpQw_EckY2k_wBDqtREVmgNw36lbJD2sZcoXeWuEjARtBqGcDWzvevM1T8QPICbYH_c--B-Nv9ewBK7sHrE5cXCHzztH0omwSjwtVOJZDHWkTUnsTQkpRo0JcOOaxutdwwy_J2XDLjvlEsGnVhTkT0JGp-nnz_U_LMo1MvZnhGmUGU5X1_OnpbloduBzbhyy0Ha3vUvOwx9PXHghuAP4Moz7mKx41EPOymkCP0uLPw9bPNXL21iy51alDzI58_yb5vvTAglnUBO-7zHsPq2vbkJahyITlT_y1R5bqBAaTmjsP86BlBlJYeKMh6Wb6ZO59gO8jphjMWAwF8bAYM45v8RknISgyAK5bHGwhavAgD6rqZJagBNk6_h9iM3z-utVog5zGT2QAQpwqW3OkurnrZCBkDK8clY2d7k46nIhoWp4iSrb8D1PxDbvbj8fxmt_Ly3jfp_d71JEEKgHKic66-zksm7QRzRi1GjgOoOeUJq02WIrfSGBLsnfTd0aeQPhpIEDsFBm5EaP1nTJEhtfU9wdOgQ9PbpDAqcmnSMtBPqXJuKfIzlmbhIKfdXY2uVMqsc-BQWE_SYfAFqg0THqwZiUp0i1wN-8laeHuqwM9CRqj9M9JmjmlilVebQSl_Edp8pTx9L0cW6b2ACbQgbcE6e1bdbmijtugpF2T-kp_r526x3r_hMKwwZHiHsfQkdTA7ygwkab5ytMsAuD_3Mrrx0fGYkRV-qy29MTu5y__6583PXDTw7Nnp_UMP8C13U2EZxMsrL80YUIRViBe6MK2dTF-ICnY56fyjba91fAtM5LzGGvlKLQ0vyKF7i60i9KeESw6xrKiJXrTmGpy-C2hNTAWa0Rd2E9OZw_TPQzVKarW4ahCQxQuY1_FLpAWykR3shZaha5Km2zIVwc9hT_IdhoSXBeqgW5i7bx-GWPJiH3VfZHcyaKfibz0MNUxkoAvYTbWXKtql3apGHN4xwYyL9XuIpoSlhvMzlFwwuqp",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoF2KSEDuvOcLN_o2Ja4lKE44XKkZA646PE8GOgo-VMeYN437VJrT2WcSWs2ZeKmMmbKNZHyqjA1pmoYpDVmBXaZO4eb86BhJS-2RKDweBRQ-hspdKUzHwp2CNYBujuDWfo95t9cngP54AVPUDH2xa5Qs0BkC29QsnQtsq3URDvCa4R8M09sjz8y1YkrAVY8c-cVTWywVa7pFut616YYObo6IpQw_EckY2k_wBDqtREVmgNw36lbJD2sZcoXeWuEjARtBqGcDWzvevM1T8QPICbYH_c--B-Nv9ewBK7sHrE5cXCHzztH0omwSjwtVOJZDHWkTUnsTQkpRo0JcOOaxutdwwy_J2XDLjvlEsGnVhTkT0JGp-nnz_U_LMo1MvZnhGmUGU5X1_OnpbloduBzbhyy0Ha3vUvOwx9PXHghuAP4Moz7mKx41EPOymkCP0uLPw9bPNXL21iy51alDzI58_yb5vvTAglnUBO-7zHsPq2vbkJahyITlT_y1R5bqBAaTmjsP86BlBlJYeKMh6Wb6ZO59gO8jphjMWAwF8bAYM45v8RknISgyAK5bHGwhavAgD6rqZJagBNk6_h9iM3z-utVog5zGT2QAQpwqW3OkurnrZCBkDK8clY2d7k46nIhoWp4iSrb8D1PxDbvbj8fxmt_Ly3jfp_d71JEEKgHKic66-zksm7QRzRi1GjgOoOeUJq02WIrfSGBLsnfTd0aeQPhpIEDsFBm5EaP1nTJEhtfU9wdOgQ9PbpDAqcmnSMtBPqXJuKfIzlmbhIKfdXY2uVMqsc-BQWE_SYfAFqg0THqwZiUp0i1wN-8laeHuqwM9CRqj9M9JmjmlilVebQSl_Edp8pTx9L0cW6b2ACbQgbcE6e1bdbmijtugpF2T-kp_r526x3r_hMKwwZHiHsfQkdTA7ygwkab5ytMsAuD_3Mrrx0fGYkRV-qy29MTu5y__6583PXDTw7Nnp_UMP8C13U2EZxMsrL80YUIRViBe6MK2dTF-ICnY56fyjba91fAtM5LzGGvlKLQ0vyKF7i60i9KeESw6xrKiJXrTmGpy-C2hNTAWa0Rd2E9OZw_TPQzVKarW4ahCQxQuY1_FLpAWykR3shZaha5Km2zIVwc9hT_IdhoSXBeqgW5i7bx-GWPJiH3VfZHcyaKfibz0MNUxkoAvYTbWXKtql3apGHN4xwYyL9XuIpoSlhvMzlFwwuqp",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GoF2KSEDuvOcLN_o2Ja4lKE44XKkZA646PE8GOgo-VMeYN437VJrT2WcSWs2ZeKmMmbKNZHyqjA1pmoYpDVmBXaZO4eb86BhJS-2RKDweBRQ-hspdKUzHwp2CNYBujuDWfo95t9cngP54AVPUDH2xa5Qs0BkC29QsnQtsq3URDvCa4R8M09sjz8y1YkrAVY8c-cVTWywVa7pFut616YYObo6IpQw_EckY2k_wBDqtREVmgNw36lbJD2sZcoXeWuEjARtBqGcDWzvevM1T8QPICbYH_c--B-Nv9ewBK7sHrE5cXCHzztH0omwSjwtVOJZDHWkTUnsTQkpRo0JcOOaxutdwwy_J2XDLjvlEsGnVhTkT0JGp-nnz_U_LMo1MvZnhGmUGU5X1_OnpbloduBzbhyy0Ha3vUvOwx9PXHghuAP4Moz7mKx41EPOymkCP0uLPw9bPNXL21iy51alDzI58_yb5vvTAglnUBO-7zHsPq2vbkJahyITlT_y1R5bqBAaTmjsP86BlBlJYeKMh6Wb6ZO59gO8jphjMWAwF8bAYM45v8RknISgyAK5bHGwhavAgD6rqZJagBNk6_h9iM3z-utVog5zGT2QAQpwqW3OkurnrZCBkDK8clY2d7k46nIhoWp4iSrb8D1PxDbvbj8fxmt_Ly3jfp_d71JEEKgHKic66-zksm7QRzRi1GjgOoOeUJq02WIrfSGBLsnfTd0aeQPhpIEDsFBm5EaP1nTJEhtfU9wdOgQ9PbpDAqcmnSMtBPqXJuKfIzlmbhIKfdXY2uVMqsc-BQWE_SYfAFqg0THqwZiUp0i1wN-8laeHuqwM9CRqj9M9JmjmlilVebQSl_Edp8pTx9L0cW6b2ACbQgbcE6e1bdbmijtugpF2T-kp_r526x3r_hMKwwZHiHsfQkdTA7ygwkab5ytMsAuD_3Mrrx0fGYkRV-qy29MTu5y__6583PXDTw7Nnp_UMP8C13U2EZxMsrL80YUIRViBe6MK2dTF-ICnY56fyjba91fAtM5LzGGvlKLQ0vyKF7i60i9KeESw6xrKiJXrTmGpy-C2hNTAWa0Rd2E9OZw_TPQzVKarW4ahCQxQuY1_FLpAWykR3shZaha5Km2zIVwc9hT_IdhoSXBeqgW5i7bx-GWPJiH3VfZHcyaKfibz0MNUxkoAvYTbWXKtql3apGHN4xwYyL9XuIpoSlhvMzlFwwuqp",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1067.54,
            "totalPrice": 939
          },
          {
            "allPassengerTotalPrice": 939,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkEoIkwHjzUdzmXTBS08zMYZRg3_bDfzumA8WFGXoNBnsNheHkC-ze3ylM3fEddrzBV5pSaOFi8Ejrk_5NfRW6FIZsdezelBQB2cjfpAK3pS8g7yBTbSd8NQHy2dsIKbhoO3sU8mLo6bPkx--wD11XUMD3oBLlCPccjwBqZltRJ6zFGCrZss9SvutwZ2KKmPfQXa_Bdrxa-jK8XLz0_3ICPmrpwXkNYOfW39YH2q7sBZdQbyDQwB1ABJIRfJkYJ5SaWqe3Cs55J7tkl19Ez6cD8tvzxmaR8M9xCmPBWunp7FBIlSr9w5rliQmxJequwmlv6grUtasGLnmWX2wvliJ_OQ8nQQWPvyiySsKSmlxg0xZxzccB2g4mBGoTvp-GBVu64uVbBDny6SAa2Z3Lwk3hKdazRtCrViZiAPyXQIkxJ0WZFOQmk8ZbgZcgObH-qU4FdmcDj1izAwEXAs6_Djuls3mJQoZvDkt9SrO4KfpDn7U4V3exCIx8b_1JLyA9pY9JU19uuLyehq30quwsxqH_eLwiaOhZ6qqL01yyHo9trggFxV5HQdWneCmXN5TaGogKccEp-EFSHtgr5OLFw6lUm4ESdqMrFlP-ldYPVErTjXFWMMhow4QsncfS0t83PDKF6Zf56He_4AlQZbeU2rg2zPXPlT_wGRCbWd3IUQTvysSfriKJkJc08ErY7RaX9EkUWyJfUUNy4brPzv1_EWX7OVcNZo689p0wZ7DIQDGT-SDjem8wJ72b0LuZAKe63w1vIXkaf-E50YdkBaOTZp1U922qzG2hXtEKASnIdcicQq7s4pSx0So7jYZ9XYfxYGJc1DxYoIqmswNPEPqYRu_5DGhxHu4aHNPYsG5WHjFPa-3Ayn32hQexieu7nji3vxnC1uCtYO94RaIvHT2rtujESy8G4pjKXHwnkzq9w6-aPhj8BYV52ttn-g0GbeCxRww2evaOg9cMATReZGFQV_G1WYxrarfV2cOokFo0kfVZdXz5uqSVKB7qz1wIFSJezjyCKMtdEiC4yqcc_244hTXIShY2294EeUc_GL3YoD645Vzc_BivH2f6Bhv-dg21qfE4iGSTX4ZEh-VVC7SnkOtnfwd8gNqMbsRRSOL8bVt1Jj13_JK5VbXANcc8Tb57Y3TBejzPymPBJSKvvfWlHoPirKaF7i4tED_ukUA8aEHw6k=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkEoIkwHjzUdzmXTBS08zMYZRg3_bDfzumA8WFGXoNBnsNheHkC-ze3ylM3fEddrzBV5pSaOFi8Ejrk_5NfRW6FIZsdezelBQB2cjfpAK3pS8g7yBTbSd8NQHy2dsIKbhoO3sU8mLo6bPkx--wD11XUMD3oBLlCPccjwBqZltRJ6zFGCrZss9SvutwZ2KKmPfQXa_Bdrxa-jK8XLz0_3ICPmrpwXkNYOfW39YH2q7sBZdQbyDQwB1ABJIRfJkYJ5SaWqe3Cs55J7tkl19Ez6cD8tvzxmaR8M9xCmPBWunp7FBIlSr9w5rliQmxJequwmlv6grUtasGLnmWX2wvliJ_OQ8nQQWPvyiySsKSmlxg0xZxzccB2g4mBGoTvp-GBVu64uVbBDny6SAa2Z3Lwk3hKdazRtCrViZiAPyXQIkxJ0WZFOQmk8ZbgZcgObH-qU4FdmcDj1izAwEXAs6_Djuls3mJQoZvDkt9SrO4KfpDn7U4V3exCIx8b_1JLyA9pY9JU19uuLyehq30quwsxqH_eLwiaOhZ6qqL01yyHo9trggFxV5HQdWneCmXN5TaGogKccEp-EFSHtgr5OLFw6lUm4ESdqMrFlP-ldYPVErTjXFWMMhow4QsncfS0t83PDKF6Zf56He_4AlQZbeU2rg2zPXPlT_wGRCbWd3IUQTvysSfriKJkJc08ErY7RaX9EkUWyJfUUNy4brPzv1_EWX7OVcNZo689p0wZ7DIQDGT-SDjem8wJ72b0LuZAKe63w1vIXkaf-E50YdkBaOTZp1U922qzG2hXtEKASnIdcicQq7s4pSx0So7jYZ9XYfxYGJc1DxYoIqmswNPEPqYRu_5DGhxHu4aHNPYsG5WHjFPa-3Ayn32hQexieu7nji3vxnC1uCtYO94RaIvHT2rtujESy8G4pjKXHwnkzq9w6-aPhj8BYV52ttn-g0GbeCxRww2evaOg9cMATReZGFQV_G1WYxrarfV2cOokFo0kfVZdXz5uqSVKB7qz1wIFSJezjyCKMtdEiC4yqcc_244hTXIShY2294EeUc_GL3YoD645Vzc_BivH2f6Bhv-dg21qfE4iGSTX4ZEh-VVC7SnkOtnfwd8gNqMbsRRSOL8bVt1Jj13_JK5VbXANcc8Tb57Y3TBejzPymPBJSKvvfWlHoPirKaF7i4tED_ukUA8aEHw6k=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GkEoIkwHjzUdzmXTBS08zMYZRg3_bDfzumA8WFGXoNBnsNheHkC-ze3ylM3fEddrzBV5pSaOFi8Ejrk_5NfRW6FIZsdezelBQB2cjfpAK3pS8g7yBTbSd8NQHy2dsIKbhoO3sU8mLo6bPkx--wD11XUMD3oBLlCPccjwBqZltRJ6zFGCrZss9SvutwZ2KKmPfQXa_Bdrxa-jK8XLz0_3ICPmrpwXkNYOfW39YH2q7sBZdQbyDQwB1ABJIRfJkYJ5SaWqe3Cs55J7tkl19Ez6cD8tvzxmaR8M9xCmPBWunp7FBIlSr9w5rliQmxJequwmlv6grUtasGLnmWX2wvliJ_OQ8nQQWPvyiySsKSmlxg0xZxzccB2g4mBGoTvp-GBVu64uVbBDny6SAa2Z3Lwk3hKdazRtCrViZiAPyXQIkxJ0WZFOQmk8ZbgZcgObH-qU4FdmcDj1izAwEXAs6_Djuls3mJQoZvDkt9SrO4KfpDn7U4V3exCIx8b_1JLyA9pY9JU19uuLyehq30quwsxqH_eLwiaOhZ6qqL01yyHo9trggFxV5HQdWneCmXN5TaGogKccEp-EFSHtgr5OLFw6lUm4ESdqMrFlP-ldYPVErTjXFWMMhow4QsncfS0t83PDKF6Zf56He_4AlQZbeU2rg2zPXPlT_wGRCbWd3IUQTvysSfriKJkJc08ErY7RaX9EkUWyJfUUNy4brPzv1_EWX7OVcNZo689p0wZ7DIQDGT-SDjem8wJ72b0LuZAKe63w1vIXkaf-E50YdkBaOTZp1U922qzG2hXtEKASnIdcicQq7s4pSx0So7jYZ9XYfxYGJc1DxYoIqmswNPEPqYRu_5DGhxHu4aHNPYsG5WHjFPa-3Ayn32hQexieu7nji3vxnC1uCtYO94RaIvHT2rtujESy8G4pjKXHwnkzq9w6-aPhj8BYV52ttn-g0GbeCxRww2evaOg9cMATReZGFQV_G1WYxrarfV2cOokFo0kfVZdXz5uqSVKB7qz1wIFSJezjyCKMtdEiC4yqcc_244hTXIShY2294EeUc_GL3YoD645Vzc_BivH2f6Bhv-dg21qfE4iGSTX4ZEh-VVC7SnkOtnfwd8gNqMbsRRSOL8bVt1Jj13_JK5VbXANcc8Tb57Y3TBejzPymPBJSKvvfWlHoPirKaF7i4tED_ukUA8aEHw6k=",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1067.54,
            "totalPrice": 939
          },
          {
            "allPassengerTotalPrice": 939,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GajOsWbTWOCMIfEnsp4BvSNZDW_iJq7Lm-vRKKXRJ0Xy-fC7sSdWDeV-RoOgaYZ_vqSJUmyU7RG7PIKFgZYBd_Hj8SA4OU5f63fW36SpMK29ta3pECGdBg7rFunMfgPKAzc6k3Pys40DzVFjBRBlzAbML4KQvpVKT8ptzqzBod1nlE7OxigoqCx1ho-8ALU7msCcNWbbZ2KMDoK-ska_wO8z7lCF7SGQqH-3y2CFW31FWrCAgRvvKlKWzJF6QV0KjhmAxPrHgGVdz4mSNzAGenaFOA4cy9j1M_WADkXJhfGpzSVXfFadK7vWgBm0RD0gRTFk14uO0PhTRTvrGSEJLK10RlmZbiEQwZoPFqhM-8XhkADGTNdwuyi4r2xrn-tsyg3Az-YvaKQrARq7Mg6ThKB0Ey2ZRKc69eEkr1d6Ds0HimDAFlVkNPdiCWMHW99gP-Gi1jQ9ZOff0StoRAW--ZpRZBm8xH-AaxhcAM_uvgbfrj6I57TiB_S3XzzajpBpBTmSd5ga1mCsJ8uhZd3X4z8DCEFSI4NK34V-lXTTZbCULemKBxU3eCNzJr5QrfY3E7sMTR7-gsJoQTwBa6cfW1vtLMFgC8rBnal0l66ahmQNtCa3BkFkD9BCHrxikQm7JK_3r_9H_sJgAfjytohGIUE6H4luaPTqtMGDySAj8mG175kPCxwbVBdlCR4PMKocyw_didbZB6x_eLVoknJPJD6l6tO_MsXoTbQIeNWuukn3eru3gT4FklAHUO0lMp04iaQOZ7nMWhcx8EA2tjUd5YlgVdCvZxRdvefYTVJ7chHnY9QqYbXQMHxURTmJiTvQlP0ySgKfEj2FC-8bCZXfrukTjcoj4y9L3cBsWtN4nMsE1Ak2kfLKunSyhoabrjggtngsEqJrBI4Utq2iNZzkbqbO1Py29T__IUoprDOyKtg200pWQ3IJ2EBYHWFKGno3ujAZVhlKDvNS_Oj676pt00C7C5krO9HR_aLREV_NVz3fN8Uw2Sqf1r_VsYJxu2oEql0CmF25X1m976I6BWkbD0Kjtb-AEQnxzbK6Gc9a71YZjBX7Y4ndDe_sx3JKGOxS8-xx6ce4KCHlTpT68XM0mqKxVj2184t97CVi1_fJVuM1FvuB_BG08Chp4DgJvtUp9YDGZ3f0wwPpacQVMANKlaw==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GajOsWbTWOCMIfEnsp4BvSNZDW_iJq7Lm-vRKKXRJ0Xy-fC7sSdWDeV-RoOgaYZ_vqSJUmyU7RG7PIKFgZYBd_Hj8SA4OU5f63fW36SpMK29ta3pECGdBg7rFunMfgPKAzc6k3Pys40DzVFjBRBlzAbML4KQvpVKT8ptzqzBod1nlE7OxigoqCx1ho-8ALU7msCcNWbbZ2KMDoK-ska_wO8z7lCF7SGQqH-3y2CFW31FWrCAgRvvKlKWzJF6QV0KjhmAxPrHgGVdz4mSNzAGenaFOA4cy9j1M_WADkXJhfGpzSVXfFadK7vWgBm0RD0gRTFk14uO0PhTRTvrGSEJLK10RlmZbiEQwZoPFqhM-8XhkADGTNdwuyi4r2xrn-tsyg3Az-YvaKQrARq7Mg6ThKB0Ey2ZRKc69eEkr1d6Ds0HimDAFlVkNPdiCWMHW99gP-Gi1jQ9ZOff0StoRAW--ZpRZBm8xH-AaxhcAM_uvgbfrj6I57TiB_S3XzzajpBpBTmSd5ga1mCsJ8uhZd3X4z8DCEFSI4NK34V-lXTTZbCULemKBxU3eCNzJr5QrfY3E7sMTR7-gsJoQTwBa6cfW1vtLMFgC8rBnal0l66ahmQNtCa3BkFkD9BCHrxikQm7JK_3r_9H_sJgAfjytohGIUE6H4luaPTqtMGDySAj8mG175kPCxwbVBdlCR4PMKocyw_didbZB6x_eLVoknJPJD6l6tO_MsXoTbQIeNWuukn3eru3gT4FklAHUO0lMp04iaQOZ7nMWhcx8EA2tjUd5YlgVdCvZxRdvefYTVJ7chHnY9QqYbXQMHxURTmJiTvQlP0ySgKfEj2FC-8bCZXfrukTjcoj4y9L3cBsWtN4nMsE1Ak2kfLKunSyhoabrjggtngsEqJrBI4Utq2iNZzkbqbO1Py29T__IUoprDOyKtg200pWQ3IJ2EBYHWFKGno3ujAZVhlKDvNS_Oj676pt00C7C5krO9HR_aLREV_NVz3fN8Uw2Sqf1r_VsYJxu2oEql0CmF25X1m976I6BWkbD0Kjtb-AEQnxzbK6Gc9a71YZjBX7Y4ndDe_sx3JKGOxS8-xx6ce4KCHlTpT68XM0mqKxVj2184t97CVi1_fJVuM1FvuB_BG08Chp4DgJvtUp9YDGZ3f0wwPpacQVMANKlaw==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GajOsWbTWOCMIfEnsp4BvSNZDW_iJq7Lm-vRKKXRJ0Xy-fC7sSdWDeV-RoOgaYZ_vqSJUmyU7RG7PIKFgZYBd_Hj8SA4OU5f63fW36SpMK29ta3pECGdBg7rFunMfgPKAzc6k3Pys40DzVFjBRBlzAbML4KQvpVKT8ptzqzBod1nlE7OxigoqCx1ho-8ALU7msCcNWbbZ2KMDoK-ska_wO8z7lCF7SGQqH-3y2CFW31FWrCAgRvvKlKWzJF6QV0KjhmAxPrHgGVdz4mSNzAGenaFOA4cy9j1M_WADkXJhfGpzSVXfFadK7vWgBm0RD0gRTFk14uO0PhTRTvrGSEJLK10RlmZbiEQwZoPFqhM-8XhkADGTNdwuyi4r2xrn-tsyg3Az-YvaKQrARq7Mg6ThKB0Ey2ZRKc69eEkr1d6Ds0HimDAFlVkNPdiCWMHW99gP-Gi1jQ9ZOff0StoRAW--ZpRZBm8xH-AaxhcAM_uvgbfrj6I57TiB_S3XzzajpBpBTmSd5ga1mCsJ8uhZd3X4z8DCEFSI4NK34V-lXTTZbCULemKBxU3eCNzJr5QrfY3E7sMTR7-gsJoQTwBa6cfW1vtLMFgC8rBnal0l66ahmQNtCa3BkFkD9BCHrxikQm7JK_3r_9H_sJgAfjytohGIUE6H4luaPTqtMGDySAj8mG175kPCxwbVBdlCR4PMKocyw_didbZB6x_eLVoknJPJD6l6tO_MsXoTbQIeNWuukn3eru3gT4FklAHUO0lMp04iaQOZ7nMWhcx8EA2tjUd5YlgVdCvZxRdvefYTVJ7chHnY9QqYbXQMHxURTmJiTvQlP0ySgKfEj2FC-8bCZXfrukTjcoj4y9L3cBsWtN4nMsE1Ak2kfLKunSyhoabrjggtngsEqJrBI4Utq2iNZzkbqbO1Py29T__IUoprDOyKtg200pWQ3IJ2EBYHWFKGno3ujAZVhlKDvNS_Oj676pt00C7C5krO9HR_aLREV_NVz3fN8Uw2Sqf1r_VsYJxu2oEql0CmF25X1m976I6BWkbD0Kjtb-AEQnxzbK6Gc9a71YZjBX7Y4ndDe_sx3JKGOxS8-xx6ce4KCHlTpT68XM0mqKxVj2184t97CVi1_fJVuM1FvuB_BG08Chp4DgJvtUp9YDGZ3f0wwPpacQVMANKlaw==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1068.21,
            "totalPrice": 939
          },
          {
            "allPassengerTotalPrice": 939,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0wglg4RCaWetE6KjkoS-1kbf4GP5TIs691_m3G5mWgN-HuAkBw_PthzTc7ztQMrULi-mPvEhStqgFJ5t5KYkX7j2XCVCsb10L45R5f6Tvjqh9aXeI7WJDRKI_08DFsSIS16VUBpldJGvFUeun_exGPR4S5eKfqoCUp-zmfeK6cBifs5u9737NvQQ3NDZit1G1DKila93VcyzqlyZ8XzhPjLGwxk_pnox8yPWMGddsIpzLk1t6B4Su3FzlE8lSsN-mpC1K7a1tj_pm9CAC1h0JN3PQmizjDdzFhBZGxI5W7smNtLdeCVWzgExm0_pfhsx1Fv2-V4kQ9YTA4enuNxGtv_x6RBJj1Jr7vF27B00zb4D5d1Fm0McywDwWWBibzwQHYzsp2bOHJhZrhfhizRV7snutzh-uJjD0gClNeLbNZNygUH9Svemaj_VnwjBI0f4hXFgbBzJnylac7u0v5vA39SnCYS6DBdlEnwrB6mKgX-U2ZVMrqqKs9Xfps5hTTDlRy_yqKcTrFehcHjT6wxJoOiuvlw_-T2vkjjha92ZGRdNeq5tAfoyTrF4h-c6z0JeSfPbPCz5IXFwCccb8wwrC1Xc1KFoxRtZj3VembKYVLWj7W9KKJXSERU78MGXzbGU1-xd9EokGujuGsbN8Kz-ync2GJqlCz7eDAhf2j1SfMegYrAb8Q4Zu1SnXXDK2o6M_EXVr0EoQIbTXMmHm4pzaUMNkRTEMSQdtHs5IXdSlgkUxXkYvZ4NhE92nktgdMaeH6oHWLbLWi2Bpzac3F3CoDU6kB8k-G03RRngpPeeUS73NWI4z5TycwnniN3RXGfeHv50uGt1RqcVtX0UHcngClc-Vgc3uHpzXFjFkSU9AZTJcfnegALjoTKdS1dPvKl4F4ZlM09HQblAbn4Uia6f0bwKC-dTjZ7fp1XG1exJVl6obUBRUQsmXkpOO1vtiYEtvugKQFjn9xlWdeuE_kZfRmzNB6wHWgY2X10JGn3-Qgdfm3XvphXggcdlCDdg7I84Je271RvzmOUR3hj0Fcq9J4sTsjdQ3ezBx7H3BOUuSOEpMoKAiCtj3m7rOKGish7wtlW7UFsdRo3t0lPFf4AXGxUrKmwxwnzMuvA7k95BnYv_mJNlNbYt1am_SuypxB_YxcZq_FJ8ogkuUaMZzGt86g==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0wglg4RCaWetE6KjkoS-1kbf4GP5TIs691_m3G5mWgN-HuAkBw_PthzTc7ztQMrULi-mPvEhStqgFJ5t5KYkX7j2XCVCsb10L45R5f6Tvjqh9aXeI7WJDRKI_08DFsSIS16VUBpldJGvFUeun_exGPR4S5eKfqoCUp-zmfeK6cBifs5u9737NvQQ3NDZit1G1DKila93VcyzqlyZ8XzhPjLGwxk_pnox8yPWMGddsIpzLk1t6B4Su3FzlE8lSsN-mpC1K7a1tj_pm9CAC1h0JN3PQmizjDdzFhBZGxI5W7smNtLdeCVWzgExm0_pfhsx1Fv2-V4kQ9YTA4enuNxGtv_x6RBJj1Jr7vF27B00zb4D5d1Fm0McywDwWWBibzwQHYzsp2bOHJhZrhfhizRV7snutzh-uJjD0gClNeLbNZNygUH9Svemaj_VnwjBI0f4hXFgbBzJnylac7u0v5vA39SnCYS6DBdlEnwrB6mKgX-U2ZVMrqqKs9Xfps5hTTDlRy_yqKcTrFehcHjT6wxJoOiuvlw_-T2vkjjha92ZGRdNeq5tAfoyTrF4h-c6z0JeSfPbPCz5IXFwCccb8wwrC1Xc1KFoxRtZj3VembKYVLWj7W9KKJXSERU78MGXzbGU1-xd9EokGujuGsbN8Kz-ync2GJqlCz7eDAhf2j1SfMegYrAb8Q4Zu1SnXXDK2o6M_EXVr0EoQIbTXMmHm4pzaUMNkRTEMSQdtHs5IXdSlgkUxXkYvZ4NhE92nktgdMaeH6oHWLbLWi2Bpzac3F3CoDU6kB8k-G03RRngpPeeUS73NWI4z5TycwnniN3RXGfeHv50uGt1RqcVtX0UHcngClc-Vgc3uHpzXFjFkSU9AZTJcfnegALjoTKdS1dPvKl4F4ZlM09HQblAbn4Uia6f0bwKC-dTjZ7fp1XG1exJVl6obUBRUQsmXkpOO1vtiYEtvugKQFjn9xlWdeuE_kZfRmzNB6wHWgY2X10JGn3-Qgdfm3XvphXggcdlCDdg7I84Je271RvzmOUR3hj0Fcq9J4sTsjdQ3ezBx7H3BOUuSOEpMoKAiCtj3m7rOKGish7wtlW7UFsdRo3t0lPFf4AXGxUrKmwxwnzMuvA7k95BnYv_mJNlNbYt1am_SuypxB_YxcZq_FJ8ogkuUaMZzGt86g==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G0wglg4RCaWetE6KjkoS-1kbf4GP5TIs691_m3G5mWgN-HuAkBw_PthzTc7ztQMrULi-mPvEhStqgFJ5t5KYkX7j2XCVCsb10L45R5f6Tvjqh9aXeI7WJDRKI_08DFsSIS16VUBpldJGvFUeun_exGPR4S5eKfqoCUp-zmfeK6cBifs5u9737NvQQ3NDZit1G1DKila93VcyzqlyZ8XzhPjLGwxk_pnox8yPWMGddsIpzLk1t6B4Su3FzlE8lSsN-mpC1K7a1tj_pm9CAC1h0JN3PQmizjDdzFhBZGxI5W7smNtLdeCVWzgExm0_pfhsx1Fv2-V4kQ9YTA4enuNxGtv_x6RBJj1Jr7vF27B00zb4D5d1Fm0McywDwWWBibzwQHYzsp2bOHJhZrhfhizRV7snutzh-uJjD0gClNeLbNZNygUH9Svemaj_VnwjBI0f4hXFgbBzJnylac7u0v5vA39SnCYS6DBdlEnwrB6mKgX-U2ZVMrqqKs9Xfps5hTTDlRy_yqKcTrFehcHjT6wxJoOiuvlw_-T2vkjjha92ZGRdNeq5tAfoyTrF4h-c6z0JeSfPbPCz5IXFwCccb8wwrC1Xc1KFoxRtZj3VembKYVLWj7W9KKJXSERU78MGXzbGU1-xd9EokGujuGsbN8Kz-ync2GJqlCz7eDAhf2j1SfMegYrAb8Q4Zu1SnXXDK2o6M_EXVr0EoQIbTXMmHm4pzaUMNkRTEMSQdtHs5IXdSlgkUxXkYvZ4NhE92nktgdMaeH6oHWLbLWi2Bpzac3F3CoDU6kB8k-G03RRngpPeeUS73NWI4z5TycwnniN3RXGfeHv50uGt1RqcVtX0UHcngClc-Vgc3uHpzXFjFkSU9AZTJcfnegALjoTKdS1dPvKl4F4ZlM09HQblAbn4Uia6f0bwKC-dTjZ7fp1XG1exJVl6obUBRUQsmXkpOO1vtiYEtvugKQFjn9xlWdeuE_kZfRmzNB6wHWgY2X10JGn3-Qgdfm3XvphXggcdlCDdg7I84Je271RvzmOUR3hj0Fcq9J4sTsjdQ3ezBx7H3BOUuSOEpMoKAiCtj3m7rOKGish7wtlW7UFsdRo3t0lPFf4AXGxUrKmwxwnzMuvA7k95BnYv_mJNlNbYt1am_SuypxB_YxcZq_FJ8ogkuUaMZzGt86g==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1069.05,
            "totalPrice": 939
          },
          {
            "allPassengerTotalPrice": 942,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gngd6btoyy2TCiAKoZWv_CIs3HTmpPvJur50RxPIbuYiR7-Jf5FBWkTeZz4XevPnk5R7Q2kY9g4jTEUfcamK-8n8_zfe78Yb5hI3vOaxaUl-a8lFIGpOnQMwVfKkPst3NfrbL-AVMHQmORe2fTpMTbaQfTQB2YokJuGa7XGnTgO42ksHDlbO1-W4SjXCJcFTsrPZtMb2E4kAjVGUD6WCojnpNtsGZQV86og489WyzDF1JgJgD6RDkiC5yoZxyqPhQ5oQylfMeseTUXC3xQFMplxbfk_DAdRc3rzInrPr6UBva7LAK8Bc4Y01T7gsGbid69OVSQPyR53SIXrSS7y6E18LqVVFlAyFzm23SiVVPV4p5ec5mrGn_AHnZDy3QYuF3PmqPxhrjMpIi4Nq5Pg8GiEzSo_tkj4Gs9vQnW6poAMQBWf1bT4m2SEpQmMghBmbR0DYQOFdFtPg06hETvum4_omAWLiiUll6M61rDre54YFbdtvDaS48v1FU9rUvkts34bSRHbl84kmWjPCO_cugumDbUm0OVplLyVl2yega-jU1WWEm0jiXfvbI41MoBj4iESTjNxg6bgHHu0fNaHEETqMilvKmaX5UI-q8it42MjbsK2TXvulUIyLSmoK9clD5fcwLisSZ0_pvR_nJ7hfj7-q5mexu_cnt9mhyYqlt9YK3aDuczvg-MnXa0qBG_U8wE1yndYWbs4OPliSIihvAI5Gf9QXRQRGAIVOd3TNovaPhSVAV6QMGIfmIoj8SFekDtj2xiOxbJrQG7V5y5gAIp6-3DAteFiMjGrsIIg-RsVPv9--i7FAUnTLWoXJ0KoMBAA_FUSS3tlFci3PKADT9RXbzZgF_7cOzMMBuaKQZ4tf5bYSDmR080LfzHMAFZrfhGnFZ8rGASIFhwos5GJ5rfCxylL1BNjgR8gEPp39fptlIgMGamHvw7_9Bk57r7KivtL0mHFPSm5-v9x9gYHLxmZeKpdUaO1k2P4Q6r-MMJ6Nr44t4kQs6gLrNkFphLmBSk4I3pIOcKtgt4BW6gyWTeO5zriTOGyV1EAaDx1jgRxhiuk2hAQGhdKM1lXHBZ3kM7LNtl7829wJc5xw7qX8fhS4WPgWOVU8ePhPWA02xrGL3jTpFCXbuZn7pviBAL5NAgW2dWOaJJs3BqQmK8hW5CA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gngd6btoyy2TCiAKoZWv_CIs3HTmpPvJur50RxPIbuYiR7-Jf5FBWkTeZz4XevPnk5R7Q2kY9g4jTEUfcamK-8n8_zfe78Yb5hI3vOaxaUl-a8lFIGpOnQMwVfKkPst3NfrbL-AVMHQmORe2fTpMTbaQfTQB2YokJuGa7XGnTgO42ksHDlbO1-W4SjXCJcFTsrPZtMb2E4kAjVGUD6WCojnpNtsGZQV86og489WyzDF1JgJgD6RDkiC5yoZxyqPhQ5oQylfMeseTUXC3xQFMplxbfk_DAdRc3rzInrPr6UBva7LAK8Bc4Y01T7gsGbid69OVSQPyR53SIXrSS7y6E18LqVVFlAyFzm23SiVVPV4p5ec5mrGn_AHnZDy3QYuF3PmqPxhrjMpIi4Nq5Pg8GiEzSo_tkj4Gs9vQnW6poAMQBWf1bT4m2SEpQmMghBmbR0DYQOFdFtPg06hETvum4_omAWLiiUll6M61rDre54YFbdtvDaS48v1FU9rUvkts34bSRHbl84kmWjPCO_cugumDbUm0OVplLyVl2yega-jU1WWEm0jiXfvbI41MoBj4iESTjNxg6bgHHu0fNaHEETqMilvKmaX5UI-q8it42MjbsK2TXvulUIyLSmoK9clD5fcwLisSZ0_pvR_nJ7hfj7-q5mexu_cnt9mhyYqlt9YK3aDuczvg-MnXa0qBG_U8wE1yndYWbs4OPliSIihvAI5Gf9QXRQRGAIVOd3TNovaPhSVAV6QMGIfmIoj8SFekDtj2xiOxbJrQG7V5y5gAIp6-3DAteFiMjGrsIIg-RsVPv9--i7FAUnTLWoXJ0KoMBAA_FUSS3tlFci3PKADT9RXbzZgF_7cOzMMBuaKQZ4tf5bYSDmR080LfzHMAFZrfhGnFZ8rGASIFhwos5GJ5rfCxylL1BNjgR8gEPp39fptlIgMGamHvw7_9Bk57r7KivtL0mHFPSm5-v9x9gYHLxmZeKpdUaO1k2P4Q6r-MMJ6Nr44t4kQs6gLrNkFphLmBSk4I3pIOcKtgt4BW6gyWTeO5zriTOGyV1EAaDx1jgRxhiuk2hAQGhdKM1lXHBZ3kM7LNtl7829wJc5xw7qX8fhS4WPgWOVU8ePhPWA02xrGL3jTpFCXbuZn7pviBAL5NAgW2dWOaJJs3BqQmK8hW5CA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gngd6btoyy2TCiAKoZWv_CIs3HTmpPvJur50RxPIbuYiR7-Jf5FBWkTeZz4XevPnk5R7Q2kY9g4jTEUfcamK-8n8_zfe78Yb5hI3vOaxaUl-a8lFIGpOnQMwVfKkPst3NfrbL-AVMHQmORe2fTpMTbaQfTQB2YokJuGa7XGnTgO42ksHDlbO1-W4SjXCJcFTsrPZtMb2E4kAjVGUD6WCojnpNtsGZQV86og489WyzDF1JgJgD6RDkiC5yoZxyqPhQ5oQylfMeseTUXC3xQFMplxbfk_DAdRc3rzInrPr6UBva7LAK8Bc4Y01T7gsGbid69OVSQPyR53SIXrSS7y6E18LqVVFlAyFzm23SiVVPV4p5ec5mrGn_AHnZDy3QYuF3PmqPxhrjMpIi4Nq5Pg8GiEzSo_tkj4Gs9vQnW6poAMQBWf1bT4m2SEpQmMghBmbR0DYQOFdFtPg06hETvum4_omAWLiiUll6M61rDre54YFbdtvDaS48v1FU9rUvkts34bSRHbl84kmWjPCO_cugumDbUm0OVplLyVl2yega-jU1WWEm0jiXfvbI41MoBj4iESTjNxg6bgHHu0fNaHEETqMilvKmaX5UI-q8it42MjbsK2TXvulUIyLSmoK9clD5fcwLisSZ0_pvR_nJ7hfj7-q5mexu_cnt9mhyYqlt9YK3aDuczvg-MnXa0qBG_U8wE1yndYWbs4OPliSIihvAI5Gf9QXRQRGAIVOd3TNovaPhSVAV6QMGIfmIoj8SFekDtj2xiOxbJrQG7V5y5gAIp6-3DAteFiMjGrsIIg-RsVPv9--i7FAUnTLWoXJ0KoMBAA_FUSS3tlFci3PKADT9RXbzZgF_7cOzMMBuaKQZ4tf5bYSDmR080LfzHMAFZrfhGnFZ8rGASIFhwos5GJ5rfCxylL1BNjgR8gEPp39fptlIgMGamHvw7_9Bk57r7KivtL0mHFPSm5-v9x9gYHLxmZeKpdUaO1k2P4Q6r-MMJ6Nr44t4kQs6gLrNkFphLmBSk4I3pIOcKtgt4BW6gyWTeO5zriTOGyV1EAaDx1jgRxhiuk2hAQGhdKM1lXHBZ3kM7LNtl7829wJc5xw7qX8fhS4WPgWOVU8ePhPWA02xrGL3jTpFCXbuZn7pviBAL5NAgW2dWOaJJs3BqQmK8hW5CA==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1084.79,
            "totalPrice": 942
          },
          {
            "allPassengerTotalPrice": 946,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gmt_uBbi819O0fkl-ri_UjPbtXySXaxoXK82vmyP1_IW2Yymdy0XrJjzlyCcVJ_j4feNYrM0hn9j3h1S761DXrTbn_BVCEx0mtpY1JVJ9_6udnqhJPh3F15BBMW1aLMeDBhUu9Gw3JNf3dFNmrtz72xj61xl9jwrD7d6m6W-xEU5wS7Yno14Sf05nQmr24hObFb0LhakLcQ6yXN6fQZdVHAM0eS94_SzbP7sFmFyzTBZJaZa_mZ2l2_kKP47MkC3qVeYIQrdq1-lgjZuJWkyMvJ8MnB7LCNrrWRnNSYj-JSh1XF1kCfRW6LOqDYubAV8iX2XqP3cUPt2gt30_nnUhDO5WgsF7EhPrVwIjOnG2N-YuNEuQdVTRM4RDVp4mH7ze_mzkk-QOl7Kp2XKRoL94p-wuSFBgCWhSRnUGP3eWCqfDLBHquUIuQ7pdSJDvMiswMzGZQC5UF_wzC6v1ck9-QyAonQBdktjY89LXijHA22DhO-r_x_Wfy7vXh2RT48lN-jWr3UvAOc2CUaf-RH-llYdVDCzmx4UeFz547XFdEjgoSf_--Kdwv0atMcQqDuO9P6-nt5kUYgTEZXuX23IqQwjvqsR9J_QpN5L1W2GtPaOHMb3qZxjwjLKFFpuE6y4M9YAXISeL46kkpBUJX_-5mJ55dpITbCQs9XBddFoRvRgdJyyZ2e-TIB_1auEigSDuGS1SNWqoR_U8t1gKe9K3WsRNgjXEXdC5Irn1edZvocQgQK6i4Zh6TTcndqh6IRj5tKBj2hSkQIZS5KtgNjqItbNeSizG_N-V-xDjhceVtfxEFr5IU_-jl5qx5SFFZL7bK31dQqpP4F5U8SBEOjyDwyhHmOTxPISADcxz7GZNfK-R6TpJc_yECyIXAFijqioh4kl5WaS6C9W9-DTqyHW0hySrZC8DEtUIXzBSJfy_YnDykBzzHpwyQACVk0XvRYJt6l1KqNb43G5TSx2Y31jITElL2ODDbqXWT9cT4kdZzkbDQYp7iy9xsDJtVIHh1tlxVWihwMlyNF2RyMgykChz1RCMyF1R-qFdzzp5DXz49U69UrxmYUmTSdsV3mHLwm3St4dA8Do8D0J_Lz46arv2FLfp12Pq7QPhTVvFXq3cU0vwJ4Y4i5MmX9Flsy09WsHgkGBgG8rHXWvv9T4tpkfHkH2eziGK_a1p3v9AB5ydyAE=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gmt_uBbi819O0fkl-ri_UjPbtXySXaxoXK82vmyP1_IW2Yymdy0XrJjzlyCcVJ_j4feNYrM0hn9j3h1S761DXrTbn_BVCEx0mtpY1JVJ9_6udnqhJPh3F15BBMW1aLMeDBhUu9Gw3JNf3dFNmrtz72xj61xl9jwrD7d6m6W-xEU5wS7Yno14Sf05nQmr24hObFb0LhakLcQ6yXN6fQZdVHAM0eS94_SzbP7sFmFyzTBZJaZa_mZ2l2_kKP47MkC3qVeYIQrdq1-lgjZuJWkyMvJ8MnB7LCNrrWRnNSYj-JSh1XF1kCfRW6LOqDYubAV8iX2XqP3cUPt2gt30_nnUhDO5WgsF7EhPrVwIjOnG2N-YuNEuQdVTRM4RDVp4mH7ze_mzkk-QOl7Kp2XKRoL94p-wuSFBgCWhSRnUGP3eWCqfDLBHquUIuQ7pdSJDvMiswMzGZQC5UF_wzC6v1ck9-QyAonQBdktjY89LXijHA22DhO-r_x_Wfy7vXh2RT48lN-jWr3UvAOc2CUaf-RH-llYdVDCzmx4UeFz547XFdEjgoSf_--Kdwv0atMcQqDuO9P6-nt5kUYgTEZXuX23IqQwjvqsR9J_QpN5L1W2GtPaOHMb3qZxjwjLKFFpuE6y4M9YAXISeL46kkpBUJX_-5mJ55dpITbCQs9XBddFoRvRgdJyyZ2e-TIB_1auEigSDuGS1SNWqoR_U8t1gKe9K3WsRNgjXEXdC5Irn1edZvocQgQK6i4Zh6TTcndqh6IRj5tKBj2hSkQIZS5KtgNjqItbNeSizG_N-V-xDjhceVtfxEFr5IU_-jl5qx5SFFZL7bK31dQqpP4F5U8SBEOjyDwyhHmOTxPISADcxz7GZNfK-R6TpJc_yECyIXAFijqioh4kl5WaS6C9W9-DTqyHW0hySrZC8DEtUIXzBSJfy_YnDykBzzHpwyQACVk0XvRYJt6l1KqNb43G5TSx2Y31jITElL2ODDbqXWT9cT4kdZzkbDQYp7iy9xsDJtVIHh1tlxVWihwMlyNF2RyMgykChz1RCMyF1R-qFdzzp5DXz49U69UrxmYUmTSdsV3mHLwm3St4dA8Do8D0J_Lz46arv2FLfp12Pq7QPhTVvFXq3cU0vwJ4Y4i5MmX9Flsy09WsHgkGBgG8rHXWvv9T4tpkfHkH2eziGK_a1p3v9AB5ydyAE=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gmt_uBbi819O0fkl-ri_UjPbtXySXaxoXK82vmyP1_IW2Yymdy0XrJjzlyCcVJ_j4feNYrM0hn9j3h1S761DXrTbn_BVCEx0mtpY1JVJ9_6udnqhJPh3F15BBMW1aLMeDBhUu9Gw3JNf3dFNmrtz72xj61xl9jwrD7d6m6W-xEU5wS7Yno14Sf05nQmr24hObFb0LhakLcQ6yXN6fQZdVHAM0eS94_SzbP7sFmFyzTBZJaZa_mZ2l2_kKP47MkC3qVeYIQrdq1-lgjZuJWkyMvJ8MnB7LCNrrWRnNSYj-JSh1XF1kCfRW6LOqDYubAV8iX2XqP3cUPt2gt30_nnUhDO5WgsF7EhPrVwIjOnG2N-YuNEuQdVTRM4RDVp4mH7ze_mzkk-QOl7Kp2XKRoL94p-wuSFBgCWhSRnUGP3eWCqfDLBHquUIuQ7pdSJDvMiswMzGZQC5UF_wzC6v1ck9-QyAonQBdktjY89LXijHA22DhO-r_x_Wfy7vXh2RT48lN-jWr3UvAOc2CUaf-RH-llYdVDCzmx4UeFz547XFdEjgoSf_--Kdwv0atMcQqDuO9P6-nt5kUYgTEZXuX23IqQwjvqsR9J_QpN5L1W2GtPaOHMb3qZxjwjLKFFpuE6y4M9YAXISeL46kkpBUJX_-5mJ55dpITbCQs9XBddFoRvRgdJyyZ2e-TIB_1auEigSDuGS1SNWqoR_U8t1gKe9K3WsRNgjXEXdC5Irn1edZvocQgQK6i4Zh6TTcndqh6IRj5tKBj2hSkQIZS5KtgNjqItbNeSizG_N-V-xDjhceVtfxEFr5IU_-jl5qx5SFFZL7bK31dQqpP4F5U8SBEOjyDwyhHmOTxPISADcxz7GZNfK-R6TpJc_yECyIXAFijqioh4kl5WaS6C9W9-DTqyHW0hySrZC8DEtUIXzBSJfy_YnDykBzzHpwyQACVk0XvRYJt6l1KqNb43G5TSx2Y31jITElL2ODDbqXWT9cT4kdZzkbDQYp7iy9xsDJtVIHh1tlxVWihwMlyNF2RyMgykChz1RCMyF1R-qFdzzp5DXz49U69UrxmYUmTSdsV3mHLwm3St4dA8Do8D0J_Lz46arv2FLfp12Pq7QPhTVvFXq3cU0vwJ4Y4i5MmX9Flsy09WsHgkGBgG8rHXWvv9T4tpkfHkH2eziGK_a1p3v9AB5ydyAE=",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1073.99,
            "totalPrice": 946
          },
          {
            "allPassengerTotalPrice": 948,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpDqL18w7eRN3rYN7uJsw6XSQR8iac4QPEXZrWKFp_GizJxTrl4_ah7NbQaBjRLnPvsLHEQIz7V4P1DQOoTIo2nO6Bhf7LKtGuBzC9zqq748B7aaIof-iDnrZSTkmNit8XFJPNeAoPepePkqrGCxULdv9q8wFg0YaQtrc1MIbkp9L3eV1At50GED4kb5wNxjDlMcHSeOJ0_XJZQIkxNi2CzSNc8WfSFS0aKeS8L1hqdU2lP7Bx6WAecvVrExG0fvAs-6RaAuA7DAFcTL2A4S8B0W2fCj4mJD5y2HMSlVkmAXnXrthbIrAEH9PbCvIHcK63I3sJMcWTgDdGxHXCPgw_wV-nw9SsGw_QQP6tWgZeNwLGlH-B0HiFC8VpaV_BjSo_gp4fSvspf35x1F7_yF83nZ_OjPHRjSrk_zsdD3a4Aug0HRC7Q9FJ0_wVc7zsoH1uVOlIJWIuzBM3BNizS1QEHwxVO4Ak7G4FJfUksvlFp_SGyVkDwUafYhBEJJLgvg7HJ8VDSRhaeIJwHXu3lExAt-ofUKH_xrSpenfSennN_O91juayOnqhAWGLYLlNQHVjzb6A5q0VadDhJ4DmNq2D07FKLO5hBdw-3aidYcJrmmNesKWEdnPcIndj0RTr4y6kRaMxIVg377GrOftAIFQpB2uskIsqgqAqBMO8eIrnjoC2KHbeG9BjfEZDRQgh0-BptSk91U5AhZHQsrDUBbVNmpDtG5xoqODrgltxTkbGSZrwkdG_WF4Yjd2cQWbtcPK2xm5ae1n2JtGThRq5soOhbQNpy5hhQ39_jPgbCHV_d8Q6zcXYosVcT35vy5NPb8Z8IovwhiKoAeLklG5hn3HtpSz-LMCV80NdWTIBr9gZqN2UEjdmrMgphvbnjKDaAkt6BEhHnAT3nrfBmElzmimBPrrDsxHoRWRqbatM1MAiYWY1aVFOPjYmd7DaKmN3w0qcsEEANh4MyNzINURZDdLIh-s0hayzTaU9UOwZv-6aKyjfvH30lZIAdEaHWXkBpyTeZ-wERSNferUD9BiWE3JcToFTQazTehLbmIN9jBupF5PWe7Zk6dJgBydBxDgU4W0tsbzBavYe0-BuZlK9_aukmUPNMZfFsf8mOeczZEygT1JaVPMA_7Wj3OeOpHiKBbZfDAXJJhd40xHWIWNbZjciQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpDqL18w7eRN3rYN7uJsw6XSQR8iac4QPEXZrWKFp_GizJxTrl4_ah7NbQaBjRLnPvsLHEQIz7V4P1DQOoTIo2nO6Bhf7LKtGuBzC9zqq748B7aaIof-iDnrZSTkmNit8XFJPNeAoPepePkqrGCxULdv9q8wFg0YaQtrc1MIbkp9L3eV1At50GED4kb5wNxjDlMcHSeOJ0_XJZQIkxNi2CzSNc8WfSFS0aKeS8L1hqdU2lP7Bx6WAecvVrExG0fvAs-6RaAuA7DAFcTL2A4S8B0W2fCj4mJD5y2HMSlVkmAXnXrthbIrAEH9PbCvIHcK63I3sJMcWTgDdGxHXCPgw_wV-nw9SsGw_QQP6tWgZeNwLGlH-B0HiFC8VpaV_BjSo_gp4fSvspf35x1F7_yF83nZ_OjPHRjSrk_zsdD3a4Aug0HRC7Q9FJ0_wVc7zsoH1uVOlIJWIuzBM3BNizS1QEHwxVO4Ak7G4FJfUksvlFp_SGyVkDwUafYhBEJJLgvg7HJ8VDSRhaeIJwHXu3lExAt-ofUKH_xrSpenfSennN_O91juayOnqhAWGLYLlNQHVjzb6A5q0VadDhJ4DmNq2D07FKLO5hBdw-3aidYcJrmmNesKWEdnPcIndj0RTr4y6kRaMxIVg377GrOftAIFQpB2uskIsqgqAqBMO8eIrnjoC2KHbeG9BjfEZDRQgh0-BptSk91U5AhZHQsrDUBbVNmpDtG5xoqODrgltxTkbGSZrwkdG_WF4Yjd2cQWbtcPK2xm5ae1n2JtGThRq5soOhbQNpy5hhQ39_jPgbCHV_d8Q6zcXYosVcT35vy5NPb8Z8IovwhiKoAeLklG5hn3HtpSz-LMCV80NdWTIBr9gZqN2UEjdmrMgphvbnjKDaAkt6BEhHnAT3nrfBmElzmimBPrrDsxHoRWRqbatM1MAiYWY1aVFOPjYmd7DaKmN3w0qcsEEANh4MyNzINURZDdLIh-s0hayzTaU9UOwZv-6aKyjfvH30lZIAdEaHWXkBpyTeZ-wERSNferUD9BiWE3JcToFTQazTehLbmIN9jBupF5PWe7Zk6dJgBydBxDgU4W0tsbzBavYe0-BuZlK9_aukmUPNMZfFsf8mOeczZEygT1JaVPMA_7Wj3OeOpHiKBbZfDAXJJhd40xHWIWNbZjciQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpDqL18w7eRN3rYN7uJsw6XSQR8iac4QPEXZrWKFp_GizJxTrl4_ah7NbQaBjRLnPvsLHEQIz7V4P1DQOoTIo2nO6Bhf7LKtGuBzC9zqq748B7aaIof-iDnrZSTkmNit8XFJPNeAoPepePkqrGCxULdv9q8wFg0YaQtrc1MIbkp9L3eV1At50GED4kb5wNxjDlMcHSeOJ0_XJZQIkxNi2CzSNc8WfSFS0aKeS8L1hqdU2lP7Bx6WAecvVrExG0fvAs-6RaAuA7DAFcTL2A4S8B0W2fCj4mJD5y2HMSlVkmAXnXrthbIrAEH9PbCvIHcK63I3sJMcWTgDdGxHXCPgw_wV-nw9SsGw_QQP6tWgZeNwLGlH-B0HiFC8VpaV_BjSo_gp4fSvspf35x1F7_yF83nZ_OjPHRjSrk_zsdD3a4Aug0HRC7Q9FJ0_wVc7zsoH1uVOlIJWIuzBM3BNizS1QEHwxVO4Ak7G4FJfUksvlFp_SGyVkDwUafYhBEJJLgvg7HJ8VDSRhaeIJwHXu3lExAt-ofUKH_xrSpenfSennN_O91juayOnqhAWGLYLlNQHVjzb6A5q0VadDhJ4DmNq2D07FKLO5hBdw-3aidYcJrmmNesKWEdnPcIndj0RTr4y6kRaMxIVg377GrOftAIFQpB2uskIsqgqAqBMO8eIrnjoC2KHbeG9BjfEZDRQgh0-BptSk91U5AhZHQsrDUBbVNmpDtG5xoqODrgltxTkbGSZrwkdG_WF4Yjd2cQWbtcPK2xm5ae1n2JtGThRq5soOhbQNpy5hhQ39_jPgbCHV_d8Q6zcXYosVcT35vy5NPb8Z8IovwhiKoAeLklG5hn3HtpSz-LMCV80NdWTIBr9gZqN2UEjdmrMgphvbnjKDaAkt6BEhHnAT3nrfBmElzmimBPrrDsxHoRWRqbatM1MAiYWY1aVFOPjYmd7DaKmN3w0qcsEEANh4MyNzINURZDdLIh-s0hayzTaU9UOwZv-6aKyjfvH30lZIAdEaHWXkBpyTeZ-wERSNferUD9BiWE3JcToFTQazTehLbmIN9jBupF5PWe7Zk6dJgBydBxDgU4W0tsbzBavYe0-BuZlK9_aukmUPNMZfFsf8mOeczZEygT1JaVPMA_7Wj3OeOpHiKBbZfDAXJJhd40xHWIWNbZjciQ==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1075.4,
            "totalPrice": 948
          },
          {
            "allPassengerTotalPrice": 958,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaGPIUO3c57i261Ubs20wPbTe1EhWi6vjcWVQ5glNuLRPVouHw0Znvz4q2jYFyisNgbyf6OCvD_VBAnbNP_Bnn_zP4w5GCr7O1Rb97poDGpPIIyfWXwRyVUCXT0RNHbqOSfwws0mLsaQMS16guLt5qVQf5h8WQTWsDTJA_ulFE-hmiGUtdZXxm1AgBJ7xmGGKVjHpLgCCZ0aKVCgHh3jIRTNAM0DM13iTaPzI1rVLD12_7YZjvTf3rCXLLaiBn2wvTOMgKs148s60-jRsv6Ui83xA0Dee3kLrZGcUv-tR5Feu7tC1m7lScXKBO3H_UJXnHPRXu61BQsFgcp8cclHE2pfR1n3_AmvOefRCmu8SBV99TdIojc7q98hjLQuURu4mNdXnycw4e7jKL5IxgAPL2qwSf7xDyjNpwhXhBS-mvjjsFBM-VEaVpJkvdvCHRXoA5e7dMjkYMl6ShtDK9lslwiD3HOv5f151ZK-IoJfmrJ0EcZY2-ppeQJesK6dFC1d6az7n6rK3lNE0dQbg1fgTqHQBm-4fmnF8m6m_pxh-BNo_zHe16Mbf6SEbrJy5X6TAypuY4bkLEuoGl4tEweMZEHOq9B13yY9HaA0Guh36ZUXg0p2LPihz-NK6ivFX_FpVdwQPzIRO4pYAotxzhula_OebBkO9eSidISchgJK-nGdZRCeLe5DrfNf2YkIMl6WVMeQ0Re_gbUEV1xvSx7p6VB2KnuBUf6lBwoM3URGHWmpD9V4KL4fy9kaSMlRVw7C36oE6gZGy8o4wbkiIS3VB3T17xgG4aNGkbrV_t8cGMQjF6M3ou14cl-AUNTaNIb7mGnnEHS0mNKOMRdVRpCdMc8RAsRjYIQ1hPH5ZjKrCFvbB79SSm33v9lxapowsvS4yc4reUPk_WXy3Pkt8_OIKQOiDN5sQluFoF_tvbQk-rDfcQNz9d2zXKleoUDuj4IelM2Xo82AoyKtYwygStMnBC2DlAscf-HVYF5k4oBXlY_UVOAKCjUzmNCbIxnBEpUI6jFM2Yk4K_0YlAXdRuiT1WbjlZx56_-pzGSX-iUo-hADDmgmkhcSGmnd7nreczwZYIsEIIPCJgu-cdilYdekoNPoMxawsWyTO3QrGDs0iFJUTgmB6CZzQWkWlX0kLrXJJnL-T6rHSs0yJUlQIqTW82g==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaGPIUO3c57i261Ubs20wPbTe1EhWi6vjcWVQ5glNuLRPVouHw0Znvz4q2jYFyisNgbyf6OCvD_VBAnbNP_Bnn_zP4w5GCr7O1Rb97poDGpPIIyfWXwRyVUCXT0RNHbqOSfwws0mLsaQMS16guLt5qVQf5h8WQTWsDTJA_ulFE-hmiGUtdZXxm1AgBJ7xmGGKVjHpLgCCZ0aKVCgHh3jIRTNAM0DM13iTaPzI1rVLD12_7YZjvTf3rCXLLaiBn2wvTOMgKs148s60-jRsv6Ui83xA0Dee3kLrZGcUv-tR5Feu7tC1m7lScXKBO3H_UJXnHPRXu61BQsFgcp8cclHE2pfR1n3_AmvOefRCmu8SBV99TdIojc7q98hjLQuURu4mNdXnycw4e7jKL5IxgAPL2qwSf7xDyjNpwhXhBS-mvjjsFBM-VEaVpJkvdvCHRXoA5e7dMjkYMl6ShtDK9lslwiD3HOv5f151ZK-IoJfmrJ0EcZY2-ppeQJesK6dFC1d6az7n6rK3lNE0dQbg1fgTqHQBm-4fmnF8m6m_pxh-BNo_zHe16Mbf6SEbrJy5X6TAypuY4bkLEuoGl4tEweMZEHOq9B13yY9HaA0Guh36ZUXg0p2LPihz-NK6ivFX_FpVdwQPzIRO4pYAotxzhula_OebBkO9eSidISchgJK-nGdZRCeLe5DrfNf2YkIMl6WVMeQ0Re_gbUEV1xvSx7p6VB2KnuBUf6lBwoM3URGHWmpD9V4KL4fy9kaSMlRVw7C36oE6gZGy8o4wbkiIS3VB3T17xgG4aNGkbrV_t8cGMQjF6M3ou14cl-AUNTaNIb7mGnnEHS0mNKOMRdVRpCdMc8RAsRjYIQ1hPH5ZjKrCFvbB79SSm33v9lxapowsvS4yc4reUPk_WXy3Pkt8_OIKQOiDN5sQluFoF_tvbQk-rDfcQNz9d2zXKleoUDuj4IelM2Xo82AoyKtYwygStMnBC2DlAscf-HVYF5k4oBXlY_UVOAKCjUzmNCbIxnBEpUI6jFM2Yk4K_0YlAXdRuiT1WbjlZx56_-pzGSX-iUo-hADDmgmkhcSGmnd7nreczwZYIsEIIPCJgu-cdilYdekoNPoMxawsWyTO3QrGDs0iFJUTgmB6CZzQWkWlX0kLrXJJnL-T6rHSs0yJUlQIqTW82g==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaGPIUO3c57i261Ubs20wPbTe1EhWi6vjcWVQ5glNuLRPVouHw0Znvz4q2jYFyisNgbyf6OCvD_VBAnbNP_Bnn_zP4w5GCr7O1Rb97poDGpPIIyfWXwRyVUCXT0RNHbqOSfwws0mLsaQMS16guLt5qVQf5h8WQTWsDTJA_ulFE-hmiGUtdZXxm1AgBJ7xmGGKVjHpLgCCZ0aKVCgHh3jIRTNAM0DM13iTaPzI1rVLD12_7YZjvTf3rCXLLaiBn2wvTOMgKs148s60-jRsv6Ui83xA0Dee3kLrZGcUv-tR5Feu7tC1m7lScXKBO3H_UJXnHPRXu61BQsFgcp8cclHE2pfR1n3_AmvOefRCmu8SBV99TdIojc7q98hjLQuURu4mNdXnycw4e7jKL5IxgAPL2qwSf7xDyjNpwhXhBS-mvjjsFBM-VEaVpJkvdvCHRXoA5e7dMjkYMl6ShtDK9lslwiD3HOv5f151ZK-IoJfmrJ0EcZY2-ppeQJesK6dFC1d6az7n6rK3lNE0dQbg1fgTqHQBm-4fmnF8m6m_pxh-BNo_zHe16Mbf6SEbrJy5X6TAypuY4bkLEuoGl4tEweMZEHOq9B13yY9HaA0Guh36ZUXg0p2LPihz-NK6ivFX_FpVdwQPzIRO4pYAotxzhula_OebBkO9eSidISchgJK-nGdZRCeLe5DrfNf2YkIMl6WVMeQ0Re_gbUEV1xvSx7p6VB2KnuBUf6lBwoM3URGHWmpD9V4KL4fy9kaSMlRVw7C36oE6gZGy8o4wbkiIS3VB3T17xgG4aNGkbrV_t8cGMQjF6M3ou14cl-AUNTaNIb7mGnnEHS0mNKOMRdVRpCdMc8RAsRjYIQ1hPH5ZjKrCFvbB79SSm33v9lxapowsvS4yc4reUPk_WXy3Pkt8_OIKQOiDN5sQluFoF_tvbQk-rDfcQNz9d2zXKleoUDuj4IelM2Xo82AoyKtYwygStMnBC2DlAscf-HVYF5k4oBXlY_UVOAKCjUzmNCbIxnBEpUI6jFM2Yk4K_0YlAXdRuiT1WbjlZx56_-pzGSX-iUo-hADDmgmkhcSGmnd7nreczwZYIsEIIPCJgu-cdilYdekoNPoMxawsWyTO3QrGDs0iFJUTgmB6CZzQWkWlX0kLrXJJnL-T6rHSs0yJUlQIqTW82g==",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1082.79,
            "totalPrice": 958
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTOIb3KJiRsbcHXWNGcTI9dZhZU-1GTvQM00Fm4WpoSeNqEGjGRDa48BMp6GfZajXOjQoVrXmpJ1zVTgTm47krR-KPOvC8dN_-wSIAgoCYb7JmkcRktI8DLjQqEBNDVD16hjlLJj1PylhUM-fS8_SwriwfK7ERm1NINmk8HBMkiblFlD6M2V2-evx1Ptc2CC87elUH6gpMyoggvYSsqKMjU6LoHZOhfbloDyca-vUIUkSQ6vNu1afWQataWmbFFAXhmZUWiFW0SgkhuATUDAAb57mF2aIbszV-wIA4vJxrfMalc4TS1eqEwPisjhljCl89UJBkEaX53qc8nN-0PA7TOTAFQk5BEuQSzM93qbk4iv6U_AU-ozn7rLlLWyFH4RCi6BAEh1lZqGgsWglXvyxlPRvpC2-PbnEMlvPJMy0ph-6o7SBinuC5-fbWMDfO_wrqc8To2KqLMZPLq-RqJ4Gu5Qf7B182Fs3lkXUa2equaIt2Dr-WJ2-P788TpoWiO-3JMtDGEHQHTwpJAlRcRi55f4AoAbIT5d3C6WNLP6tFu4rMpq3PdqmqDrAGVte2aeIgdRWsIqRDy7pdqfHAoxyAX6rSMLp4UwmwfWrp10r2V5N1YanmxK6OGh_OrjTRRgowMqmDCHIJGBH0CVrSwyIqDXZzmC4E-WxgPHP0RLK1JYU0EgdpvLsWMeBvRUL8R0SpfVGKU8zFu2EM3Ij9wqMmK7xpZJPP8IIQb2mzp_FABPlT15b-KkCe9gizfVbTXlz_o6vVt-gn_zMkrSuubjJvEO-XVW2EXn-_kIW8asScOSAF_tkZ1ONAV99DAF6L0pEfO81KaAv9rTdfjbvVDMHbtQDZy9S2RhWWAOSxtEmUDG_js5Gye8X6tBzk94S1TPaqQZyRChjWZhorhjY57-2tZIoHY4Y_aPt4HbYVktUpfC68LcuGeLegidpk9yHrv_WNJ2gqzGi7ensXKz9ufP_vNLRKh09LcCIzxT5MKhkpbWKhR0nAxgCGkgODFQpCLkadj6JZdDPiRRxuHJgJf1QGzYU4h9Wp324_RJoQE3cSm6BLydrUxKqe1ApAD85muhrRb7qq1DhZDQcG1AlX7ynUXorqukApmjUZheenZ6UVwI=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTOIb3KJiRsbcHXWNGcTI9dZhZU-1GTvQM00Fm4WpoSeNqEGjGRDa48BMp6GfZajXOjQoVrXmpJ1zVTgTm47krR-KPOvC8dN_-wSIAgoCYb7JmkcRktI8DLjQqEBNDVD16hjlLJj1PylhUM-fS8_SwriwfK7ERm1NINmk8HBMkiblFlD6M2V2-evx1Ptc2CC87elUH6gpMyoggvYSsqKMjU6LoHZOhfbloDyca-vUIUkSQ6vNu1afWQataWmbFFAXhmZUWiFW0SgkhuATUDAAb57mF2aIbszV-wIA4vJxrfMalc4TS1eqEwPisjhljCl89UJBkEaX53qc8nN-0PA7TOTAFQk5BEuQSzM93qbk4iv6U_AU-ozn7rLlLWyFH4RCi6BAEh1lZqGgsWglXvyxlPRvpC2-PbnEMlvPJMy0ph-6o7SBinuC5-fbWMDfO_wrqc8To2KqLMZPLq-RqJ4Gu5Qf7B182Fs3lkXUa2equaIt2Dr-WJ2-P788TpoWiO-3JMtDGEHQHTwpJAlRcRi55f4AoAbIT5d3C6WNLP6tFu4rMpq3PdqmqDrAGVte2aeIgdRWsIqRDy7pdqfHAoxyAX6rSMLp4UwmwfWrp10r2V5N1YanmxK6OGh_OrjTRRgowMqmDCHIJGBH0CVrSwyIqDXZzmC4E-WxgPHP0RLK1JYU0EgdpvLsWMeBvRUL8R0SpfVGKU8zFu2EM3Ij9wqMmK7xpZJPP8IIQb2mzp_FABPlT15b-KkCe9gizfVbTXlz_o6vVt-gn_zMkrSuubjJvEO-XVW2EXn-_kIW8asScOSAF_tkZ1ONAV99DAF6L0pEfO81KaAv9rTdfjbvVDMHbtQDZy9S2RhWWAOSxtEmUDG_js5Gye8X6tBzk94S1TPaqQZyRChjWZhorhjY57-2tZIoHY4Y_aPt4HbYVktUpfC68LcuGeLegidpk9yHrv_WNJ2gqzGi7ensXKz9ufP_vNLRKh09LcCIzxT5MKhkpbWKhR0nAxgCGkgODFQpCLkadj6JZdDPiRRxuHJgJf1QGzYU4h9Wp324_RJoQE3cSm6BLydrUxKqe1ApAD85muhrRb7qq1DhZDQcG1AlX7ynUXorqukApmjUZheenZ6UVwI=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GTOIb3KJiRsbcHXWNGcTI9dZhZU-1GTvQM00Fm4WpoSeNqEGjGRDa48BMp6GfZajXOjQoVrXmpJ1zVTgTm47krR-KPOvC8dN_-wSIAgoCYb7JmkcRktI8DLjQqEBNDVD16hjlLJj1PylhUM-fS8_SwriwfK7ERm1NINmk8HBMkiblFlD6M2V2-evx1Ptc2CC87elUH6gpMyoggvYSsqKMjU6LoHZOhfbloDyca-vUIUkSQ6vNu1afWQataWmbFFAXhmZUWiFW0SgkhuATUDAAb57mF2aIbszV-wIA4vJxrfMalc4TS1eqEwPisjhljCl89UJBkEaX53qc8nN-0PA7TOTAFQk5BEuQSzM93qbk4iv6U_AU-ozn7rLlLWyFH4RCi6BAEh1lZqGgsWglXvyxlPRvpC2-PbnEMlvPJMy0ph-6o7SBinuC5-fbWMDfO_wrqc8To2KqLMZPLq-RqJ4Gu5Qf7B182Fs3lkXUa2equaIt2Dr-WJ2-P788TpoWiO-3JMtDGEHQHTwpJAlRcRi55f4AoAbIT5d3C6WNLP6tFu4rMpq3PdqmqDrAGVte2aeIgdRWsIqRDy7pdqfHAoxyAX6rSMLp4UwmwfWrp10r2V5N1YanmxK6OGh_OrjTRRgowMqmDCHIJGBH0CVrSwyIqDXZzmC4E-WxgPHP0RLK1JYU0EgdpvLsWMeBvRUL8R0SpfVGKU8zFu2EM3Ij9wqMmK7xpZJPP8IIQb2mzp_FABPlT15b-KkCe9gizfVbTXlz_o6vVt-gn_zMkrSuubjJvEO-XVW2EXn-_kIW8asScOSAF_tkZ1ONAV99DAF6L0pEfO81KaAv9rTdfjbvVDMHbtQDZy9S2RhWWAOSxtEmUDG_js5Gye8X6tBzk94S1TPaqQZyRChjWZhorhjY57-2tZIoHY4Y_aPt4HbYVktUpfC68LcuGeLegidpk9yHrv_WNJ2gqzGi7ensXKz9ufP_vNLRKh09LcCIzxT5MKhkpbWKhR0nAxgCGkgODFQpCLkadj6JZdDPiRRxuHJgJf1QGzYU4h9Wp324_RJoQE3cSm6BLydrUxKqe1ApAD85muhrRb7qq1DhZDQcG1AlX7ynUXorqukApmjUZheenZ6UVwI=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1091,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GU5hDFGMx-cjYHL39h-xh_cv2n_vE1xx-_a9sl0C0aN2OZa5INmyVF6mA7gpfHbu-acVjA4OI-SvUO_CakSLZO43_xSZVAg0U62U0isSSHce9-GsDNIIPZ5upDeq94W4NQh_U3tV0H75G1rw8XmZ3gh0W4WA65xkNGvBjAyGZKaI1Rh-Kd80_KsFDKffZQ_ujTzyHlPfESNZ4om1_25Jck4i4pSd1F8VWDCKtTl4rizWSEjNT7sirIkJYcbEG0sQcnwmizLdQp8b0PsW_QBQZqYzX2jVXv-HHj_qjqv82y2Xyb8NBarjmpAt5cHALRVEgOQt_ahJxP6qFw6hk47yilGPC8t9vNfV_K6F1fZIzkULDZjNXc-gcOkUSdAvtawhDSPGZK2Xnfv5eoyvxvs3zqycGjKU1rCE626P9A0CVgYjTHHHMdP5TUf3EE71hqBjGPFEpPs_QUIHTemY6TRaw0i81WTMB4n1fo4oTjAn-ZER7jBJL_BjGZvBXpmoyvwgfOf2qK11KfbunA05J0Jh9q0XtFosNMGUCBC4n3zXj1ZifwvFwp5KP74f4fh2VVUXl3aWUVomC3qkY04IVOLpx1KjDazPRZf5BX844x-0Op_aMe2HH6No17w5bXvLNLA_XYefEGHUfEWOsA0XX6xC5qrl7IPmkxl7fNM3KYshatj8P0iUCWFiKOlyAbMTlv--p9oQQBxMxcfhWTH0-4O8ZPPMUivLber95vr3Jj_MYtCuFAqNUv1TLJ8wVgztPWXTosKyhDb8Gdm11sKRy5ptTctwC2b780HoLE8eDjD1LNtLgA6njd6jfjicg1UjGV--vfQrMXTiQgwsJRFvxnmQC6R3WeR_LxQcuEqbegTvhHSIACjoXJw3BHP1QOmSf9Jzcl1apgdD3xZ-7f5iYcQGTHMvbXRS7F12-bNbzfCgd-vPVfr6C7g4mQS0pmhgFUvtQ8BWF_CEeJc5xc_opZHlmw894w4NJnz1i7bt2s68sY_t7kDBl8UBzd1F0UkDjXv09WrizVztA24Ksxi0pEuvvoUJ4-Tuqo5H0hyVvaTNAC0WpVbI8FOFCJQ5EjqQdE0_GkpTAUr5nf1RAAa540Dm0b0Vc2w683NteEwnTMnXcKA4=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GU5hDFGMx-cjYHL39h-xh_cv2n_vE1xx-_a9sl0C0aN2OZa5INmyVF6mA7gpfHbu-acVjA4OI-SvUO_CakSLZO43_xSZVAg0U62U0isSSHce9-GsDNIIPZ5upDeq94W4NQh_U3tV0H75G1rw8XmZ3gh0W4WA65xkNGvBjAyGZKaI1Rh-Kd80_KsFDKffZQ_ujTzyHlPfESNZ4om1_25Jck4i4pSd1F8VWDCKtTl4rizWSEjNT7sirIkJYcbEG0sQcnwmizLdQp8b0PsW_QBQZqYzX2jVXv-HHj_qjqv82y2Xyb8NBarjmpAt5cHALRVEgOQt_ahJxP6qFw6hk47yilGPC8t9vNfV_K6F1fZIzkULDZjNXc-gcOkUSdAvtawhDSPGZK2Xnfv5eoyvxvs3zqycGjKU1rCE626P9A0CVgYjTHHHMdP5TUf3EE71hqBjGPFEpPs_QUIHTemY6TRaw0i81WTMB4n1fo4oTjAn-ZER7jBJL_BjGZvBXpmoyvwgfOf2qK11KfbunA05J0Jh9q0XtFosNMGUCBC4n3zXj1ZifwvFwp5KP74f4fh2VVUXl3aWUVomC3qkY04IVOLpx1KjDazPRZf5BX844x-0Op_aMe2HH6No17w5bXvLNLA_XYefEGHUfEWOsA0XX6xC5qrl7IPmkxl7fNM3KYshatj8P0iUCWFiKOlyAbMTlv--p9oQQBxMxcfhWTH0-4O8ZPPMUivLber95vr3Jj_MYtCuFAqNUv1TLJ8wVgztPWXTosKyhDb8Gdm11sKRy5ptTctwC2b780HoLE8eDjD1LNtLgA6njd6jfjicg1UjGV--vfQrMXTiQgwsJRFvxnmQC6R3WeR_LxQcuEqbegTvhHSIACjoXJw3BHP1QOmSf9Jzcl1apgdD3xZ-7f5iYcQGTHMvbXRS7F12-bNbzfCgd-vPVfr6C7g4mQS0pmhgFUvtQ8BWF_CEeJc5xc_opZHlmw894w4NJnz1i7bt2s68sY_t7kDBl8UBzd1F0UkDjXv09WrizVztA24Ksxi0pEuvvoUJ4-Tuqo5H0hyVvaTNAC0WpVbI8FOFCJQ5EjqQdE0_GkpTAUr5nf1RAAa540Dm0b0Vc2w683NteEwnTMnXcKA4=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GU5hDFGMx-cjYHL39h-xh_cv2n_vE1xx-_a9sl0C0aN2OZa5INmyVF6mA7gpfHbu-acVjA4OI-SvUO_CakSLZO43_xSZVAg0U62U0isSSHce9-GsDNIIPZ5upDeq94W4NQh_U3tV0H75G1rw8XmZ3gh0W4WA65xkNGvBjAyGZKaI1Rh-Kd80_KsFDKffZQ_ujTzyHlPfESNZ4om1_25Jck4i4pSd1F8VWDCKtTl4rizWSEjNT7sirIkJYcbEG0sQcnwmizLdQp8b0PsW_QBQZqYzX2jVXv-HHj_qjqv82y2Xyb8NBarjmpAt5cHALRVEgOQt_ahJxP6qFw6hk47yilGPC8t9vNfV_K6F1fZIzkULDZjNXc-gcOkUSdAvtawhDSPGZK2Xnfv5eoyvxvs3zqycGjKU1rCE626P9A0CVgYjTHHHMdP5TUf3EE71hqBjGPFEpPs_QUIHTemY6TRaw0i81WTMB4n1fo4oTjAn-ZER7jBJL_BjGZvBXpmoyvwgfOf2qK11KfbunA05J0Jh9q0XtFosNMGUCBC4n3zXj1ZifwvFwp5KP74f4fh2VVUXl3aWUVomC3qkY04IVOLpx1KjDazPRZf5BX844x-0Op_aMe2HH6No17w5bXvLNLA_XYefEGHUfEWOsA0XX6xC5qrl7IPmkxl7fNM3KYshatj8P0iUCWFiKOlyAbMTlv--p9oQQBxMxcfhWTH0-4O8ZPPMUivLber95vr3Jj_MYtCuFAqNUv1TLJ8wVgztPWXTosKyhDb8Gdm11sKRy5ptTctwC2b780HoLE8eDjD1LNtLgA6njd6jfjicg1UjGV--vfQrMXTiQgwsJRFvxnmQC6R3WeR_LxQcuEqbegTvhHSIACjoXJw3BHP1QOmSf9Jzcl1apgdD3xZ-7f5iYcQGTHMvbXRS7F12-bNbzfCgd-vPVfr6C7g4mQS0pmhgFUvtQ8BWF_CEeJc5xc_opZHlmw894w4NJnz1i7bt2s68sY_t7kDBl8UBzd1F0UkDjXv09WrizVztA24Ksxi0pEuvvoUJ4-Tuqo5H0hyVvaTNAC0WpVbI8FOFCJQ5EjqQdE0_GkpTAUr5nf1RAAa540Dm0b0Vc2w683NteEwnTMnXcKA4=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1091,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhQ3_msWeIfV4Y0Fz8Tg-dQshPNK9K4ffmDmhRBqqhDq5JXJzf3T_FqSUrufL1E6cQ_F1gFVT25zqpLS85KNz6VDGt9vfwPoC4DRhSVEsLnQZ1J5vEjLur_yxMzbK0flCUk7QJrUPTSDT9TaxdLzQdaFuEntyVOZtXwlzSTfkqzQh6Mnf_bhO8W4wIWPhpHvVZECoEenY3-R-VFNpXf50ATdtI0osO1A6PXh-PrTfCqNYN1aw2tBODZzK31Do2G0O7Jf-azASJBS2zdffnlNyLOd6w8hHnW7Z6srUDfM_t7qBD43Nw-v0loUM_cdW7pIbAkf4X8qg4-7h9sGyYCeYyCSQpSw4FHla2BDoFDXVHjdTsFbP3LiUwjl5si4tZSD8K80hC-3G2WLaDIFF4caNDVKNR1KatavDmOYBpKv_CT5tb7Nczkog3O2Pnc3VIZ_HCihMZ1IE786s_SF9rDtwDaoJ4pqYlx1EJBritk_LjN8sqJeQbdSueADEPRZI5GwCcMSS-B3u9iFgx8C3mMlm3YP9-79hog1H-vj_C7vS4qsmnSO5nM7nXpfs7Fa1ASB0YAUgecH5pxJuVrsuPtZpuF_ST-CjXFP5Cpc8mIvCsCYBAAzqUFULoo9td9U2P0DrO5Ef5dFEC1J7aS4Cu2SlLgjBPzvjYrIYeohbaiSxiqvazqdoLlCDpf0zWv_1GX9UWrxSnQUEplGAvlp9vHOMzKGYp1qPyTn6p9403UVbznvCjxzdxenzgxPOrvZlL6RiSk_7W7lEz2nu0DIYATxBhk3rqJghWamU59QhqMafK9UQ5SSLDjJ7UmgUey45LGq6sGtW6e2XY_3BPy-qFUbJWJe5aXmQRfQCVD9GMaok1KmtIrb2Y0Mzvnz69xZP8Q9pSpvrc1EyjBKVJWvv1AvXIm9KvtAnEH8qlEGSSl-nx_WAuoSWmZSIthEAGtSqy1-vpEUxH7kDl26PEhO-Kux41ho82ewaYRz-dS1kDCBeCoNxgAItRBq7cCXB6XCblUI_vNSuAO7hq2oBDGxQVAhJXglnae0Wr_VowjG3PBie6yxgHVOz9nNrJA669_OTppf8T6_4s8ZELyrMl4I6vGPs2g==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhQ3_msWeIfV4Y0Fz8Tg-dQshPNK9K4ffmDmhRBqqhDq5JXJzf3T_FqSUrufL1E6cQ_F1gFVT25zqpLS85KNz6VDGt9vfwPoC4DRhSVEsLnQZ1J5vEjLur_yxMzbK0flCUk7QJrUPTSDT9TaxdLzQdaFuEntyVOZtXwlzSTfkqzQh6Mnf_bhO8W4wIWPhpHvVZECoEenY3-R-VFNpXf50ATdtI0osO1A6PXh-PrTfCqNYN1aw2tBODZzK31Do2G0O7Jf-azASJBS2zdffnlNyLOd6w8hHnW7Z6srUDfM_t7qBD43Nw-v0loUM_cdW7pIbAkf4X8qg4-7h9sGyYCeYyCSQpSw4FHla2BDoFDXVHjdTsFbP3LiUwjl5si4tZSD8K80hC-3G2WLaDIFF4caNDVKNR1KatavDmOYBpKv_CT5tb7Nczkog3O2Pnc3VIZ_HCihMZ1IE786s_SF9rDtwDaoJ4pqYlx1EJBritk_LjN8sqJeQbdSueADEPRZI5GwCcMSS-B3u9iFgx8C3mMlm3YP9-79hog1H-vj_C7vS4qsmnSO5nM7nXpfs7Fa1ASB0YAUgecH5pxJuVrsuPtZpuF_ST-CjXFP5Cpc8mIvCsCYBAAzqUFULoo9td9U2P0DrO5Ef5dFEC1J7aS4Cu2SlLgjBPzvjYrIYeohbaiSxiqvazqdoLlCDpf0zWv_1GX9UWrxSnQUEplGAvlp9vHOMzKGYp1qPyTn6p9403UVbznvCjxzdxenzgxPOrvZlL6RiSk_7W7lEz2nu0DIYATxBhk3rqJghWamU59QhqMafK9UQ5SSLDjJ7UmgUey45LGq6sGtW6e2XY_3BPy-qFUbJWJe5aXmQRfQCVD9GMaok1KmtIrb2Y0Mzvnz69xZP8Q9pSpvrc1EyjBKVJWvv1AvXIm9KvtAnEH8qlEGSSl-nx_WAuoSWmZSIthEAGtSqy1-vpEUxH7kDl26PEhO-Kux41ho82ewaYRz-dS1kDCBeCoNxgAItRBq7cCXB6XCblUI_vNSuAO7hq2oBDGxQVAhJXglnae0Wr_VowjG3PBie6yxgHVOz9nNrJA669_OTppf8T6_4s8ZELyrMl4I6vGPs2g==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhQ3_msWeIfV4Y0Fz8Tg-dQshPNK9K4ffmDmhRBqqhDq5JXJzf3T_FqSUrufL1E6cQ_F1gFVT25zqpLS85KNz6VDGt9vfwPoC4DRhSVEsLnQZ1J5vEjLur_yxMzbK0flCUk7QJrUPTSDT9TaxdLzQdaFuEntyVOZtXwlzSTfkqzQh6Mnf_bhO8W4wIWPhpHvVZECoEenY3-R-VFNpXf50ATdtI0osO1A6PXh-PrTfCqNYN1aw2tBODZzK31Do2G0O7Jf-azASJBS2zdffnlNyLOd6w8hHnW7Z6srUDfM_t7qBD43Nw-v0loUM_cdW7pIbAkf4X8qg4-7h9sGyYCeYyCSQpSw4FHla2BDoFDXVHjdTsFbP3LiUwjl5si4tZSD8K80hC-3G2WLaDIFF4caNDVKNR1KatavDmOYBpKv_CT5tb7Nczkog3O2Pnc3VIZ_HCihMZ1IE786s_SF9rDtwDaoJ4pqYlx1EJBritk_LjN8sqJeQbdSueADEPRZI5GwCcMSS-B3u9iFgx8C3mMlm3YP9-79hog1H-vj_C7vS4qsmnSO5nM7nXpfs7Fa1ASB0YAUgecH5pxJuVrsuPtZpuF_ST-CjXFP5Cpc8mIvCsCYBAAzqUFULoo9td9U2P0DrO5Ef5dFEC1J7aS4Cu2SlLgjBPzvjYrIYeohbaiSxiqvazqdoLlCDpf0zWv_1GX9UWrxSnQUEplGAvlp9vHOMzKGYp1qPyTn6p9403UVbznvCjxzdxenzgxPOrvZlL6RiSk_7W7lEz2nu0DIYATxBhk3rqJghWamU59QhqMafK9UQ5SSLDjJ7UmgUey45LGq6sGtW6e2XY_3BPy-qFUbJWJe5aXmQRfQCVD9GMaok1KmtIrb2Y0Mzvnz69xZP8Q9pSpvrc1EyjBKVJWvv1AvXIm9KvtAnEH8qlEGSSl-nx_WAuoSWmZSIthEAGtSqy1-vpEUxH7kDl26PEhO-Kux41ho82ewaYRz-dS1kDCBeCoNxgAItRBq7cCXB6XCblUI_vNSuAO7hq2oBDGxQVAhJXglnae0Wr_VowjG3PBie6yxgHVOz9nNrJA669_OTppf8T6_4s8ZELyrMl4I6vGPs2g==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1091.67,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 960,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GW7hphOmLUq1UsIrh1daeIMQFjiM-OPU1MkcQfzEdRgHrB1s_XlvKW39-iHEwd1Sfi1vF2xjr1p1bTdRk5BBOp05-j8FvPlzb9T1EDWnXv-fs7HJbuGdDqMD3CjXX68LfIz8FzcKHLkkxPkb8QDJN2wf4vDUPyJU67dvaUAJr_819GLk9OBm1Ms2f1a8v4vl2oWfmfD0m9YozqF-_ZojXuEySxGKkLSWMfWLc3mix5ZAeWwoXXfTCfcOqnmEzYyf6B7-FIa-QkKZQgrRx0dG4sv87qXhyIPrT05oRUdfGJYBzVD_6Od_cUcpT40tPi7TzGnhZhI1UUtwvh5rYO4fzuAGvAoPVgnaW0w3Q79xeZ9q6wyojvxt070a3f8wUywSZd9p_z2cCv0_j8BIrFpaMof2eg7f-TeOGvajCgChq0ghGzB_qg8OBsB4JCx4917tysxGslxiN4kkYe6WBiH3f_NpI2XVBXFfap__rEun-VQCcyK2XdytCmvtzf_YvO39Mk_dhvNVtW1lgnPmNBZwwDVjQ4i9qf-MI7oWCBQiLQs2WAkR6AczOLqQVmwJdtAJh0ficVAMxHuT3yxBKhkpJucYXzbi8iB06hhKD9riVQE8hijooUl37vTfyrjJfOksuUc71JYok0gHGReMvqn2GYrZpBFN1sGPAnIEGun4gDpAwqFtYDCgzIQCK_LF_AJGRu80CnZt9k8rD-hIL_LV0FYXYhuKS8tUEkSXdAA3Sgb8dKLnu2o9sBkb031DyX_HzvhxB0MCcitSBuUMaCQudjzuOeU4JDjOfm6ILTHXsBKsoHI9w23s6f6eWAbdn0tJeTHzuGCkorTgV4imNzYrBsyajkPNLuFEwb0tCGuCCg9YmKcT3D-LXxpBxH2y6_2W6t0NL1hWavSbb8K4_HqU0L8qC4gOuVq4vTmqhI1dB_s0nTG8sQIdMU14O75j2hr403FVLCLzcJ51exFbr0axFW8dW9G2nPnnvaN-7kXstQIWjYkVDYjDPgDem0Jhc3dESC8Emyib2JxxcosNnQvvPMx5nSr2Ff4JpOabvd9z1Ov2Asal_qASXRIS-5ZcKID_W5Fsx-CK_0n9lm9LBTXOOOA==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GW7hphOmLUq1UsIrh1daeIMQFjiM-OPU1MkcQfzEdRgHrB1s_XlvKW39-iHEwd1Sfi1vF2xjr1p1bTdRk5BBOp05-j8FvPlzb9T1EDWnXv-fs7HJbuGdDqMD3CjXX68LfIz8FzcKHLkkxPkb8QDJN2wf4vDUPyJU67dvaUAJr_819GLk9OBm1Ms2f1a8v4vl2oWfmfD0m9YozqF-_ZojXuEySxGKkLSWMfWLc3mix5ZAeWwoXXfTCfcOqnmEzYyf6B7-FIa-QkKZQgrRx0dG4sv87qXhyIPrT05oRUdfGJYBzVD_6Od_cUcpT40tPi7TzGnhZhI1UUtwvh5rYO4fzuAGvAoPVgnaW0w3Q79xeZ9q6wyojvxt070a3f8wUywSZd9p_z2cCv0_j8BIrFpaMof2eg7f-TeOGvajCgChq0ghGzB_qg8OBsB4JCx4917tysxGslxiN4kkYe6WBiH3f_NpI2XVBXFfap__rEun-VQCcyK2XdytCmvtzf_YvO39Mk_dhvNVtW1lgnPmNBZwwDVjQ4i9qf-MI7oWCBQiLQs2WAkR6AczOLqQVmwJdtAJh0ficVAMxHuT3yxBKhkpJucYXzbi8iB06hhKD9riVQE8hijooUl37vTfyrjJfOksuUc71JYok0gHGReMvqn2GYrZpBFN1sGPAnIEGun4gDpAwqFtYDCgzIQCK_LF_AJGRu80CnZt9k8rD-hIL_LV0FYXYhuKS8tUEkSXdAA3Sgb8dKLnu2o9sBkb031DyX_HzvhxB0MCcitSBuUMaCQudjzuOeU4JDjOfm6ILTHXsBKsoHI9w23s6f6eWAbdn0tJeTHzuGCkorTgV4imNzYrBsyajkPNLuFEwb0tCGuCCg9YmKcT3D-LXxpBxH2y6_2W6t0NL1hWavSbb8K4_HqU0L8qC4gOuVq4vTmqhI1dB_s0nTG8sQIdMU14O75j2hr403FVLCLzcJ51exFbr0axFW8dW9G2nPnnvaN-7kXstQIWjYkVDYjDPgDem0Jhc3dESC8Emyib2JxxcosNnQvvPMx5nSr2Ff4JpOabvd9z1Ov2Asal_qASXRIS-5ZcKID_W5Fsx-CK_0n9lm9LBTXOOOA==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GW7hphOmLUq1UsIrh1daeIMQFjiM-OPU1MkcQfzEdRgHrB1s_XlvKW39-iHEwd1Sfi1vF2xjr1p1bTdRk5BBOp05-j8FvPlzb9T1EDWnXv-fs7HJbuGdDqMD3CjXX68LfIz8FzcKHLkkxPkb8QDJN2wf4vDUPyJU67dvaUAJr_819GLk9OBm1Ms2f1a8v4vl2oWfmfD0m9YozqF-_ZojXuEySxGKkLSWMfWLc3mix5ZAeWwoXXfTCfcOqnmEzYyf6B7-FIa-QkKZQgrRx0dG4sv87qXhyIPrT05oRUdfGJYBzVD_6Od_cUcpT40tPi7TzGnhZhI1UUtwvh5rYO4fzuAGvAoPVgnaW0w3Q79xeZ9q6wyojvxt070a3f8wUywSZd9p_z2cCv0_j8BIrFpaMof2eg7f-TeOGvajCgChq0ghGzB_qg8OBsB4JCx4917tysxGslxiN4kkYe6WBiH3f_NpI2XVBXFfap__rEun-VQCcyK2XdytCmvtzf_YvO39Mk_dhvNVtW1lgnPmNBZwwDVjQ4i9qf-MI7oWCBQiLQs2WAkR6AczOLqQVmwJdtAJh0ficVAMxHuT3yxBKhkpJucYXzbi8iB06hhKD9riVQE8hijooUl37vTfyrjJfOksuUc71JYok0gHGReMvqn2GYrZpBFN1sGPAnIEGun4gDpAwqFtYDCgzIQCK_LF_AJGRu80CnZt9k8rD-hIL_LV0FYXYhuKS8tUEkSXdAA3Sgb8dKLnu2o9sBkb031DyX_HzvhxB0MCcitSBuUMaCQudjzuOeU4JDjOfm6ILTHXsBKsoHI9w23s6f6eWAbdn0tJeTHzuGCkorTgV4imNzYrBsyajkPNLuFEwb0tCGuCCg9YmKcT3D-LXxpBxH2y6_2W6t0NL1hWavSbb8K4_HqU0L8qC4gOuVq4vTmqhI1dB_s0nTG8sQIdMU14O75j2hr403FVLCLzcJ51exFbr0axFW8dW9G2nPnnvaN-7kXstQIWjYkVDYjDPgDem0Jhc3dESC8Emyib2JxxcosNnQvvPMx5nSr2Ff4JpOabvd9z1Ov2Asal_qASXRIS-5ZcKID_W5Fsx-CK_0n9lm9LBTXOOOA==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1092.52,
            "totalPrice": 960
          },
          {
            "allPassengerTotalPrice": 962,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7kRe1ZdoF7IyJ0lUfmqZsArk8eTGePF1jJoPouZtOGRnycxhGAmIZ9Pv4AG5rm3Dg0s8-HzdVYK5T1oo-T9h_xe7b4yUkD7_9Jq6abGF8lzun-tcQ9jin3F3dEUT6GTxwqISkaRNmtBd1p1Usduog9u-5OIKY2dsjlGDS0a5uj7vqZNBrnVPfEF1IqzZrzDrxx3h5CslaCi15XNdpCzRVLtunpgeQUbeXfjUV8bZHy26kwxHu2rq5mYD-IruVfBV11Zp3FBmhN63xQcKQTBAectXzx9wtw-OKG3Na1THOjRy9Iqz_b3Na1z0LyCdVhlGVwyg7Lu2hobWX3d8cPFLkl9AOdrgxvjPGro5L7gvJLVVEUwRM3ct9aHti7-gWUUOHJXt5yeEo3zuKHpNkYVdlrfANt_F6uOXdiwLHujeW4JkqaQW-xt78f7_faoMK_C_rHwpyQhJamlViyETjSw2v-TSd-w7rdBcNCJB1dPJGJTyV_T4bu5muAF__TI55sNcxPF0tbpIvLxdRqKSkxIAoxse1h4GDKYqG4JMTMp2pNOWAm1DwSERXDi3fZrUZWvFeO_CLo83l-Y3FFjFG7ifngGv-lVsPE0pg8UjJMIDTYdZJr3FIMp_HHqZZo71UKL0wBOPR6r7d8zZjD0-NC1QxUPyCS79jGwwqj7hJBK1BGc7hna6Br89XwSGch-aX6IdxmBhiaokLaerJWxCts2l69zf63QjZCYaCC8RDOVBpR-RiaJdYhd76iXGuwKpZeEYXW5HkvE2uWXJ6MjxeAy6jPE52LcynAJThMp5tB2Dfs8aqgpsQoRUyYMVjEfSxOKXGCy5UUcpa_wQo2XfusFQrHNGwSqpmgoQndmlOfoBzfDTbUZpFeWGT4MwtJBad3ORu1c4Y0rbggAw0jB_jylHSplOdFP5urcuLZFHb0DaPWPoAApe2a8VhVJbXb_k64l8Q0whQlw2FmhDShpXjdO5xYIoNL4viwE9lXE842-xVXSTTU7qYauEAqDlJldinyUlAigAHbDbrirFMPz8PCFwuHvgnQ7V8UqxTctpg3gE0eN9sfno2LwALtIjjm0kMUS1Zw281KhiVfVj9YQRDkMD6EA_ELrufsN067yUY_WZKV3TaQCjwtfQOvsW2q4-YIkXJt_GJcU5SQxikTH-Zj4aVyx-Iik-ttqy1rXyOU3TkEkbCgeuGiuQnK5YLlqJAhK0wNJB9-uxHFqC9QP2-YUBo0h0kPx_clG4xlQfC51_B0KAuE3Pm1K0BEY-rFVcXYEV",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "09:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7kRe1ZdoF7IyJ0lUfmqZsArk8eTGePF1jJoPouZtOGRnycxhGAmIZ9Pv4AG5rm3Dg0s8-HzdVYK5T1oo-T9h_xe7b4yUkD7_9Jq6abGF8lzun-tcQ9jin3F3dEUT6GTxwqISkaRNmtBd1p1Usduog9u-5OIKY2dsjlGDS0a5uj7vqZNBrnVPfEF1IqzZrzDrxx3h5CslaCi15XNdpCzRVLtunpgeQUbeXfjUV8bZHy26kwxHu2rq5mYD-IruVfBV11Zp3FBmhN63xQcKQTBAectXzx9wtw-OKG3Na1THOjRy9Iqz_b3Na1z0LyCdVhlGVwyg7Lu2hobWX3d8cPFLkl9AOdrgxvjPGro5L7gvJLVVEUwRM3ct9aHti7-gWUUOHJXt5yeEo3zuKHpNkYVdlrfANt_F6uOXdiwLHujeW4JkqaQW-xt78f7_faoMK_C_rHwpyQhJamlViyETjSw2v-TSd-w7rdBcNCJB1dPJGJTyV_T4bu5muAF__TI55sNcxPF0tbpIvLxdRqKSkxIAoxse1h4GDKYqG4JMTMp2pNOWAm1DwSERXDi3fZrUZWvFeO_CLo83l-Y3FFjFG7ifngGv-lVsPE0pg8UjJMIDTYdZJr3FIMp_HHqZZo71UKL0wBOPR6r7d8zZjD0-NC1QxUPyCS79jGwwqj7hJBK1BGc7hna6Br89XwSGch-aX6IdxmBhiaokLaerJWxCts2l69zf63QjZCYaCC8RDOVBpR-RiaJdYhd76iXGuwKpZeEYXW5HkvE2uWXJ6MjxeAy6jPE52LcynAJThMp5tB2Dfs8aqgpsQoRUyYMVjEfSxOKXGCy5UUcpa_wQo2XfusFQrHNGwSqpmgoQndmlOfoBzfDTbUZpFeWGT4MwtJBad3ORu1c4Y0rbggAw0jB_jylHSplOdFP5urcuLZFHb0DaPWPoAApe2a8VhVJbXb_k64l8Q0whQlw2FmhDShpXjdO5xYIoNL4viwE9lXE842-xVXSTTU7qYauEAqDlJldinyUlAigAHbDbrirFMPz8PCFwuHvgnQ7V8UqxTctpg3gE0eN9sfno2LwALtIjjm0kMUS1Zw281KhiVfVj9YQRDkMD6EA_ELrufsN067yUY_WZKV3TaQCjwtfQOvsW2q4-YIkXJt_GJcU5SQxikTH-Zj4aVyx-Iik-ttqy1rXyOU3TkEkbCgeuGiuQnK5YLlqJAhK0wNJB9-uxHFqC9QP2-YUBo0h0kPx_clG4xlQfC51_B0KAuE3Pm1K0BEY-rFVcXYEV",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "08:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000b16b8bd0",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5042,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000b16b8bd0_0",
                    "local_arrival": "2024-10-08T09:45:00.000Z",
                    "local_departure": "2024-10-08T08:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5042",
                    "return": 0,
                    "utc_arrival": "2024-10-08T07:45:00.000Z",
                    "utc_departure": "2024-10-08T06:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000b16b8bd0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G7kRe1ZdoF7IyJ0lUfmqZsArk8eTGePF1jJoPouZtOGRnycxhGAmIZ9Pv4AG5rm3Dg0s8-HzdVYK5T1oo-T9h_xe7b4yUkD7_9Jq6abGF8lzun-tcQ9jin3F3dEUT6GTxwqISkaRNmtBd1p1Usduog9u-5OIKY2dsjlGDS0a5uj7vqZNBrnVPfEF1IqzZrzDrxx3h5CslaCi15XNdpCzRVLtunpgeQUbeXfjUV8bZHy26kwxHu2rq5mYD-IruVfBV11Zp3FBmhN63xQcKQTBAectXzx9wtw-OKG3Na1THOjRy9Iqz_b3Na1z0LyCdVhlGVwyg7Lu2hobWX3d8cPFLkl9AOdrgxvjPGro5L7gvJLVVEUwRM3ct9aHti7-gWUUOHJXt5yeEo3zuKHpNkYVdlrfANt_F6uOXdiwLHujeW4JkqaQW-xt78f7_faoMK_C_rHwpyQhJamlViyETjSw2v-TSd-w7rdBcNCJB1dPJGJTyV_T4bu5muAF__TI55sNcxPF0tbpIvLxdRqKSkxIAoxse1h4GDKYqG4JMTMp2pNOWAm1DwSERXDi3fZrUZWvFeO_CLo83l-Y3FFjFG7ifngGv-lVsPE0pg8UjJMIDTYdZJr3FIMp_HHqZZo71UKL0wBOPR6r7d8zZjD0-NC1QxUPyCS79jGwwqj7hJBK1BGc7hna6Br89XwSGch-aX6IdxmBhiaokLaerJWxCts2l69zf63QjZCYaCC8RDOVBpR-RiaJdYhd76iXGuwKpZeEYXW5HkvE2uWXJ6MjxeAy6jPE52LcynAJThMp5tB2Dfs8aqgpsQoRUyYMVjEfSxOKXGCy5UUcpa_wQo2XfusFQrHNGwSqpmgoQndmlOfoBzfDTbUZpFeWGT4MwtJBad3ORu1c4Y0rbggAw0jB_jylHSplOdFP5urcuLZFHb0DaPWPoAApe2a8VhVJbXb_k64l8Q0whQlw2FmhDShpXjdO5xYIoNL4viwE9lXE842-xVXSTTU7qYauEAqDlJldinyUlAigAHbDbrirFMPz8PCFwuHvgnQ7V8UqxTctpg3gE0eN9sfno2LwALtIjjm0kMUS1Zw281KhiVfVj9YQRDkMD6EA_ELrufsN067yUY_WZKV3TaQCjwtfQOvsW2q4-YIkXJt_GJcU5SQxikTH-Zj4aVyx-Iik-ttqy1rXyOU3TkEkbCgeuGiuQnK5YLlqJAhK0wNJB9-uxHFqC9QP2-YUBo0h0kPx_clG4xlQfC51_B0KAuE3Pm1K0BEY-rFVcXYEV",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e2b0000b0b6a3f4",
                    "equipment": null,
                    "fare_basis": "VNR",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e2b0000b0b6a3f4_0",
                    "local_arrival": "2024-10-15T18:35:00.000Z",
                    "local_departure": "2024-10-15T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:35:00.000Z",
                    "utc_departure": "2024-10-15T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1128.15,
            "totalPrice": 962
          },
          {
            "allPassengerTotalPrice": 962,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaTnCbIr-Hb979hGoH7vxRT09JGqCMHbseuuTFxHU4KUbkB91YFlfm61PicoDyv7qrW2bHIo18eQPFKQztAJQXE0hNypGguUu6vq8Y9nasouQgJYoGaqRIKGiiTw-ZxqMgGPOwkUqaM1zrmU69Pf0yZ0wU6r7nQFw3C_JNLAo98wDSyKJz3Ng6CdtdsMrcPBzqhKgXdry0LaZy1UHd3CxHD9RyYrQ0y5qpipkJ4gWU4zqzrx1lta4FCqp62RQwZSq8bsHO2XubPRklFXZ_-gjmBbEYkAObaBn5dEl_Sxed7BLfaw1unnmOfzEY2rvkCLXbYn651CJ1IIFqDfEJaITzEN4OwToizreWJSofJRE0HTjFmI5W-3AZ_cTM3jZQ--OGL1-c1RVye8sw8n3ZCzvCAKaMT4SxJa5obNoQuR48HH8ccJVcOy1If5E9fbipWuco2WkgMrfycz0yZP5NW8bkj3tYtabNfcwY2skXeopDb5J0dAUe5AOQOpF0w22N_hsofVG4FuKgV7HqyIo36PioQwRWc-ueus6GDAFUiuZrpqRM7Q5_ODJ20FOFQHJEK82Wg2E-lM0cLOUQJFXgwyyeh-cghCEXLtxgumFeCvpVEEBNr5Bb1VL56SNxci4Cepk73ZA7FcVCUOyApDmWxXTv3EPYK66wzRRVkroASdBhXKf9vhMObTkq8fjjDzxQ2EKbeapEQEuJna07AKWx5vkRNtXOwCVdTdEzATlvUIRSUd-nIqqvMdGO_aJBUH_wLX5KOhzTVebXtOX_4htEHZQbjHOu0zGkECzpYaK5ktZbkqvFK9_HFVp3MEuaAcOTwdvcAKF9vCWZrZLHuSGJZ7Y5QMHUTZjCegKSSFYdSQen0we4nDkZSbC6XrAd03cDDynOFMMrtXl-1tz3ibPkOzXFSOBXAY67J21bSlHlChLxu827lzI4wlcMObB1svNM5Sra3Dg1aMvpMq_Pes_OsGIP0u4UN-ik_-Iqy15EYdOQBKQicw3xdSMcqV9pJLAMedm5tC2RXpIhdIVM-MvIKrzhTsTYTaK7ij8GoxM5u3KNZqHVGV5XRdnMQnKaVsdIraN_VRi1v2LXTQ5DXuTSPBtBbdB_17vVnJ44f9EXDH1wk4zkw0FkbgN9T04bonqTnBEg7LqyNDW2TzmCTOdwQ6xZMjFmLoJ-5ilpBa1tPfHk67V4yoy9JtwkOBU-23CO92qJAiw7C2VLcAlruf4gL_9jJJXH0c9ajQhP_CQkKRdWIgavqabGLjgBtlRXs98sO-Y",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "13:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaTnCbIr-Hb979hGoH7vxRT09JGqCMHbseuuTFxHU4KUbkB91YFlfm61PicoDyv7qrW2bHIo18eQPFKQztAJQXE0hNypGguUu6vq8Y9nasouQgJYoGaqRIKGiiTw-ZxqMgGPOwkUqaM1zrmU69Pf0yZ0wU6r7nQFw3C_JNLAo98wDSyKJz3Ng6CdtdsMrcPBzqhKgXdry0LaZy1UHd3CxHD9RyYrQ0y5qpipkJ4gWU4zqzrx1lta4FCqp62RQwZSq8bsHO2XubPRklFXZ_-gjmBbEYkAObaBn5dEl_Sxed7BLfaw1unnmOfzEY2rvkCLXbYn651CJ1IIFqDfEJaITzEN4OwToizreWJSofJRE0HTjFmI5W-3AZ_cTM3jZQ--OGL1-c1RVye8sw8n3ZCzvCAKaMT4SxJa5obNoQuR48HH8ccJVcOy1If5E9fbipWuco2WkgMrfycz0yZP5NW8bkj3tYtabNfcwY2skXeopDb5J0dAUe5AOQOpF0w22N_hsofVG4FuKgV7HqyIo36PioQwRWc-ueus6GDAFUiuZrpqRM7Q5_ODJ20FOFQHJEK82Wg2E-lM0cLOUQJFXgwyyeh-cghCEXLtxgumFeCvpVEEBNr5Bb1VL56SNxci4Cepk73ZA7FcVCUOyApDmWxXTv3EPYK66wzRRVkroASdBhXKf9vhMObTkq8fjjDzxQ2EKbeapEQEuJna07AKWx5vkRNtXOwCVdTdEzATlvUIRSUd-nIqqvMdGO_aJBUH_wLX5KOhzTVebXtOX_4htEHZQbjHOu0zGkECzpYaK5ktZbkqvFK9_HFVp3MEuaAcOTwdvcAKF9vCWZrZLHuSGJZ7Y5QMHUTZjCegKSSFYdSQen0we4nDkZSbC6XrAd03cDDynOFMMrtXl-1tz3ibPkOzXFSOBXAY67J21bSlHlChLxu827lzI4wlcMObB1svNM5Sra3Dg1aMvpMq_Pes_OsGIP0u4UN-ik_-Iqy15EYdOQBKQicw3xdSMcqV9pJLAMedm5tC2RXpIhdIVM-MvIKrzhTsTYTaK7ij8GoxM5u3KNZqHVGV5XRdnMQnKaVsdIraN_VRi1v2LXTQ5DXuTSPBtBbdB_17vVnJ44f9EXDH1wk4zkw0FkbgN9T04bonqTnBEg7LqyNDW2TzmCTOdwQ6xZMjFmLoJ-5ilpBa1tPfHk67V4yoy9JtwkOBU-23CO92qJAiw7C2VLcAlruf4gL_9jJJXH0c9ajQhP_CQkKRdWIgavqabGLjgBtlRXs98sO-Y",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "12:35",
                "destination": "MAD",
                "duration": "1h10m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000f947d90e",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5036,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000f947d90e_0",
                    "local_arrival": "2024-10-08T13:45:00.000Z",
                    "local_departure": "2024-10-08T12:35:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5036",
                    "return": 0,
                    "utc_arrival": "2024-10-08T11:45:00.000Z",
                    "utc_departure": "2024-10-08T10:35:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GaTnCbIr-Hb979hGoH7vxRT09JGqCMHbseuuTFxHU4KUbkB91YFlfm61PicoDyv7qrW2bHIo18eQPFKQztAJQXE0hNypGguUu6vq8Y9nasouQgJYoGaqRIKGiiTw-ZxqMgGPOwkUqaM1zrmU69Pf0yZ0wU6r7nQFw3C_JNLAo98wDSyKJz3Ng6CdtdsMrcPBzqhKgXdry0LaZy1UHd3CxHD9RyYrQ0y5qpipkJ4gWU4zqzrx1lta4FCqp62RQwZSq8bsHO2XubPRklFXZ_-gjmBbEYkAObaBn5dEl_Sxed7BLfaw1unnmOfzEY2rvkCLXbYn651CJ1IIFqDfEJaITzEN4OwToizreWJSofJRE0HTjFmI5W-3AZ_cTM3jZQ--OGL1-c1RVye8sw8n3ZCzvCAKaMT4SxJa5obNoQuR48HH8ccJVcOy1If5E9fbipWuco2WkgMrfycz0yZP5NW8bkj3tYtabNfcwY2skXeopDb5J0dAUe5AOQOpF0w22N_hsofVG4FuKgV7HqyIo36PioQwRWc-ueus6GDAFUiuZrpqRM7Q5_ODJ20FOFQHJEK82Wg2E-lM0cLOUQJFXgwyyeh-cghCEXLtxgumFeCvpVEEBNr5Bb1VL56SNxci4Cepk73ZA7FcVCUOyApDmWxXTv3EPYK66wzRRVkroASdBhXKf9vhMObTkq8fjjDzxQ2EKbeapEQEuJna07AKWx5vkRNtXOwCVdTdEzATlvUIRSUd-nIqqvMdGO_aJBUH_wLX5KOhzTVebXtOX_4htEHZQbjHOu0zGkECzpYaK5ktZbkqvFK9_HFVp3MEuaAcOTwdvcAKF9vCWZrZLHuSGJZ7Y5QMHUTZjCegKSSFYdSQen0we4nDkZSbC6XrAd03cDDynOFMMrtXl-1tz3ibPkOzXFSOBXAY67J21bSlHlChLxu827lzI4wlcMObB1svNM5Sra3Dg1aMvpMq_Pes_OsGIP0u4UN-ik_-Iqy15EYdOQBKQicw3xdSMcqV9pJLAMedm5tC2RXpIhdIVM-MvIKrzhTsTYTaK7ij8GoxM5u3KNZqHVGV5XRdnMQnKaVsdIraN_VRi1v2LXTQ5DXuTSPBtBbdB_17vVnJ44f9EXDH1wk4zkw0FkbgN9T04bonqTnBEg7LqyNDW2TzmCTOdwQ6xZMjFmLoJ-5ilpBa1tPfHk67V4yoy9JtwkOBU-23CO92qJAiw7C2VLcAlruf4gL_9jJJXH0c9ajQhP_CQkKRdWIgavqabGLjgBtlRXs98sO-Y",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e2b0000b0b6a3f4",
                    "equipment": null,
                    "fare_basis": "VNR",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e2b0000b0b6a3f4_0",
                    "local_arrival": "2024-10-15T18:35:00.000Z",
                    "local_departure": "2024-10-15T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:35:00.000Z",
                    "utc_departure": "2024-10-15T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1128.15,
            "totalPrice": 962
          },
          {
            "allPassengerTotalPrice": 962,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GMg0rBNxHWPJZTJRTDhmJEoYkIpzyEq4_Z-lhnofem6A59Nkm6hqyaqMJEn6t3dQ1Lx22rt16wE4TfmjBGLKCtt7w60owr78EOlMERhoZ7hD8gZk6-KiLZk6ooCO0oqZOmG3zow0pyrEkA_qLFjLnGIYAd0IeOsJl44MjPcKQn_l-v8-pzv_7n9dqOcjrYx-sOteeNntv7OmhCkJuqTMo2HQsvL_zhT2vHn6nhYjuMTnh3WFDD4EOc-C7sh_NH9CYPde6Y8mC7gjzYYUw-8GD3CQhcJIDxeSv_eqFqc0fzxPwNjZ-TDkY1d__GgA8PWJAfS3xHzQJjVwm-AMH6fnwKxSJgs1LfF0TBDj-qzeFRzK8o0h-suiUiTKlXZp9liRp-nUxHsF-d1iVYMJ_x374XbLQhpN-cn6QF3S_1lYFLayjSqoZd68fq9xoekaS0v0Aa9Bvtv330VKKXnIuuCkU-jMmM19Rq0v-wVeXFs7F6kPOZ7w_bMmh0N6wNjlcZGXwekYG8c5B4e0L4YcavJ4u9UkKBvr2cLlYl-DAOt5RTJOTAqQ-QKAZuD5R1dNGXCGO4k2buJAHRZnnkNU2mS3KRqc2U89zbXk-jSHkD0mQpnZnGV4DrQqFZPY_dqP6sMrXGcNFZj2ltdJ548Wy1V-TZvEpPUw4bsKLQ3JcfNda8anLDnfrWoG_SyTYnhT-WG2-Er4eRmmpOOkICDIVDVkJpHxfyqpgy8mDZzISuk6tWzDNO3erlH6uR4PzmDubAq058_mKeMomV8rOKr-9KKzNThYsKtibl1KA8PGIK6cyZ0Oay1jkn52MCJ1wv7e9DRuV_a7eFF4lKtZZcguNrkbQtqdgHW4jck1vlO6crUClBouNHxmBVrcqnR0s-7OjhubPPHGxHndNT1DYj4fpi4mcO5rJwTItLlgqYz1Cq-VyV4Mm5jyjMw_B0Y6fnnQon5Oap4iWDfrOwlP8gQvC2-swo73LROF6bPgeCLcs_ClA561xZwFNH-Sgv5srwCUzWPPCbQI-mbPcZ0qXOa1_osB1KKHPC6YV9VEf9iIZK-opEn4Uc9U0weTIkH1Uue-1u2I98qt9kWsjATfoedL-n7cIHSe7IPo08A0kGMw4cZ0zeCO4jVLde88XqQsxMir5ykF2ftyjYzrPErZz2Nyl025yjsate-z2o_3i2jGOG9L4DfxdwOxeV6nyDe69j3_l4dzu2fCP2sIlHoAvj0puGvTmugYuGLkckAZsU4sqY4wUgZM=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:15",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GMg0rBNxHWPJZTJRTDhmJEoYkIpzyEq4_Z-lhnofem6A59Nkm6hqyaqMJEn6t3dQ1Lx22rt16wE4TfmjBGLKCtt7w60owr78EOlMERhoZ7hD8gZk6-KiLZk6ooCO0oqZOmG3zow0pyrEkA_qLFjLnGIYAd0IeOsJl44MjPcKQn_l-v8-pzv_7n9dqOcjrYx-sOteeNntv7OmhCkJuqTMo2HQsvL_zhT2vHn6nhYjuMTnh3WFDD4EOc-C7sh_NH9CYPde6Y8mC7gjzYYUw-8GD3CQhcJIDxeSv_eqFqc0fzxPwNjZ-TDkY1d__GgA8PWJAfS3xHzQJjVwm-AMH6fnwKxSJgs1LfF0TBDj-qzeFRzK8o0h-suiUiTKlXZp9liRp-nUxHsF-d1iVYMJ_x374XbLQhpN-cn6QF3S_1lYFLayjSqoZd68fq9xoekaS0v0Aa9Bvtv330VKKXnIuuCkU-jMmM19Rq0v-wVeXFs7F6kPOZ7w_bMmh0N6wNjlcZGXwekYG8c5B4e0L4YcavJ4u9UkKBvr2cLlYl-DAOt5RTJOTAqQ-QKAZuD5R1dNGXCGO4k2buJAHRZnnkNU2mS3KRqc2U89zbXk-jSHkD0mQpnZnGV4DrQqFZPY_dqP6sMrXGcNFZj2ltdJ548Wy1V-TZvEpPUw4bsKLQ3JcfNda8anLDnfrWoG_SyTYnhT-WG2-Er4eRmmpOOkICDIVDVkJpHxfyqpgy8mDZzISuk6tWzDNO3erlH6uR4PzmDubAq058_mKeMomV8rOKr-9KKzNThYsKtibl1KA8PGIK6cyZ0Oay1jkn52MCJ1wv7e9DRuV_a7eFF4lKtZZcguNrkbQtqdgHW4jck1vlO6crUClBouNHxmBVrcqnR0s-7OjhubPPHGxHndNT1DYj4fpi4mcO5rJwTItLlgqYz1Cq-VyV4Mm5jyjMw_B0Y6fnnQon5Oap4iWDfrOwlP8gQvC2-swo73LROF6bPgeCLcs_ClA561xZwFNH-Sgv5srwCUzWPPCbQI-mbPcZ0qXOa1_osB1KKHPC6YV9VEf9iIZK-opEn4Uc9U0weTIkH1Uue-1u2I98qt9kWsjATfoedL-n7cIHSe7IPo08A0kGMw4cZ0zeCO4jVLde88XqQsxMir5ykF2ftyjYzrPErZz2Nyl025yjsate-z2o_3i2jGOG9L4DfxdwOxeV6nyDe69j3_l4dzu2fCP2sIlHoAvj0puGvTmugYuGLkckAZsU4sqY4wUgZM=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:00",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000467edbbd",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5050,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000467edbbd_0",
                    "local_arrival": "2024-10-08T18:15:00.000Z",
                    "local_departure": "2024-10-08T17:00:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5050",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:15:00.000Z",
                    "utc_departure": "2024-10-08T15:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000467edbbd_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GMg0rBNxHWPJZTJRTDhmJEoYkIpzyEq4_Z-lhnofem6A59Nkm6hqyaqMJEn6t3dQ1Lx22rt16wE4TfmjBGLKCtt7w60owr78EOlMERhoZ7hD8gZk6-KiLZk6ooCO0oqZOmG3zow0pyrEkA_qLFjLnGIYAd0IeOsJl44MjPcKQn_l-v8-pzv_7n9dqOcjrYx-sOteeNntv7OmhCkJuqTMo2HQsvL_zhT2vHn6nhYjuMTnh3WFDD4EOc-C7sh_NH9CYPde6Y8mC7gjzYYUw-8GD3CQhcJIDxeSv_eqFqc0fzxPwNjZ-TDkY1d__GgA8PWJAfS3xHzQJjVwm-AMH6fnwKxSJgs1LfF0TBDj-qzeFRzK8o0h-suiUiTKlXZp9liRp-nUxHsF-d1iVYMJ_x374XbLQhpN-cn6QF3S_1lYFLayjSqoZd68fq9xoekaS0v0Aa9Bvtv330VKKXnIuuCkU-jMmM19Rq0v-wVeXFs7F6kPOZ7w_bMmh0N6wNjlcZGXwekYG8c5B4e0L4YcavJ4u9UkKBvr2cLlYl-DAOt5RTJOTAqQ-QKAZuD5R1dNGXCGO4k2buJAHRZnnkNU2mS3KRqc2U89zbXk-jSHkD0mQpnZnGV4DrQqFZPY_dqP6sMrXGcNFZj2ltdJ548Wy1V-TZvEpPUw4bsKLQ3JcfNda8anLDnfrWoG_SyTYnhT-WG2-Er4eRmmpOOkICDIVDVkJpHxfyqpgy8mDZzISuk6tWzDNO3erlH6uR4PzmDubAq058_mKeMomV8rOKr-9KKzNThYsKtibl1KA8PGIK6cyZ0Oay1jkn52MCJ1wv7e9DRuV_a7eFF4lKtZZcguNrkbQtqdgHW4jck1vlO6crUClBouNHxmBVrcqnR0s-7OjhubPPHGxHndNT1DYj4fpi4mcO5rJwTItLlgqYz1Cq-VyV4Mm5jyjMw_B0Y6fnnQon5Oap4iWDfrOwlP8gQvC2-swo73LROF6bPgeCLcs_ClA561xZwFNH-Sgv5srwCUzWPPCbQI-mbPcZ0qXOa1_osB1KKHPC6YV9VEf9iIZK-opEn4Uc9U0weTIkH1Uue-1u2I98qt9kWsjATfoedL-n7cIHSe7IPo08A0kGMw4cZ0zeCO4jVLde88XqQsxMir5ykF2ftyjYzrPErZz2Nyl025yjsate-z2o_3i2jGOG9L4DfxdwOxeV6nyDe69j3_l4dzu2fCP2sIlHoAvj0puGvTmugYuGLkckAZsU4sqY4wUgZM=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e2b0000b0b6a3f4",
                    "equipment": null,
                    "fare_basis": "VNR",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e2b0000b0b6a3f4_0",
                    "local_arrival": "2024-10-15T18:35:00.000Z",
                    "local_departure": "2024-10-15T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:35:00.000Z",
                    "utc_departure": "2024-10-15T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1128.82,
            "totalPrice": 962
          },
          {
            "allPassengerTotalPrice": 962,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1lKcuaxdVohmrHSt58pjkDKHTzBEWuGKq4Mx52HpO1Y_UegExdL5J1qbJo8gCzP-aAHL5QXo343u1u4ol2kigRhxlnF3iljXIxE3lDBvjEe5KaaloolU5pPhXv-86YyeeSFjrzfKqrmF1xjFWJxFzv1cJZFx0upL5axH0e4M7dP5rrJEsrpB07DAQCDLOstbudQvqE-8_7XGSYHT1Hi17nNYjA38GRrUX4uEIU5z0hv8wUKlwk05fN-oulqP8d1zLoewvuNCTwruvWmcVbuRQ70Mz5CHiQ5vWF-nyvup7GjkJXk8lBrm-1dfXA-K1rJi4PXvMzTW48_1J-oYC1N9ufjGz_66O2BcpbkG4GN-fvDqMvZW9vedfSUH-QWxImEgqQ2v_El5hNjqWQfBuBybaCWW7FdJ5QVpOTYwZv7IIqiNnN6iUs_nKbvcN1bzvdIa4x-4BmEs1Z3Vqqqs4pnAV0Rl8ertQTIA6-X-8SzdjYS_L3Fv2lhhks4qxnUYmrcjCFxQoMxtV0CG_Owhzu4SuG68bvFq-Ity1oIr8jQH2QidWSVWcZ2Peerp_ZSrF6qb0w8qWadXSrGNUZN6ygMi5g3dGRhZM77q79723D-H_Q6_BjfRBTqhykMBlf-FvwUjTBVXdf8VSGhqhxX3Cl9iYFRy3nRVdHXwpUfCnMYFg4id0_8SbE_iMzDzzd6YS6G1KIe22EXNDq0ox0oxRLun5FgT_xrDhBkNBg-cV-qno0AMFMJg4tRGxLtDfgW0GK4VLUf_FwoEQLQwguyS5IYTdiPaACvRqkRlW-cL44HezH4bixK5zauZLDZoBGeVmMfBs7j5AUilwfT5opWGfTDV5E-ce3EtiOaVtRmwkiJtTFzbXv7G06mj3bDGpERv0_WEbLwmshjUQ_kQ5-kHHX_CUMLmwlkn2MpoIE8K7WRyy52RdY_aoK--VpEABoeeFkzsVL5v2LZDZVE-e1KJZ8-aW03Ctj3Oogb_YVBfc-sM20fJDq5uw3i_ZPn4KU--2q5_SgEJ4b50buYmDPUMaYAiTbGbn3FTn37bLfprsU5foYmekDfOdbhcYNDD_tuzBgnAlCX5dm-C3ip5xT62HU6dwFuBRnPbN9UIFtcGgM-ZNyFoI2rODndy2u-D99tMFJVNyL2YpXzRCGNHouriTeidA3357I6NQ04GaFHTT2Kl1dkPXWQMWJ5IN7Iq1gnWH-obCAPt7v66DCCvCJcACYRc9A==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:45",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1lKcuaxdVohmrHSt58pjkDKHTzBEWuGKq4Mx52HpO1Y_UegExdL5J1qbJo8gCzP-aAHL5QXo343u1u4ol2kigRhxlnF3iljXIxE3lDBvjEe5KaaloolU5pPhXv-86YyeeSFjrzfKqrmF1xjFWJxFzv1cJZFx0upL5axH0e4M7dP5rrJEsrpB07DAQCDLOstbudQvqE-8_7XGSYHT1Hi17nNYjA38GRrUX4uEIU5z0hv8wUKlwk05fN-oulqP8d1zLoewvuNCTwruvWmcVbuRQ70Mz5CHiQ5vWF-nyvup7GjkJXk8lBrm-1dfXA-K1rJi4PXvMzTW48_1J-oYC1N9ufjGz_66O2BcpbkG4GN-fvDqMvZW9vedfSUH-QWxImEgqQ2v_El5hNjqWQfBuBybaCWW7FdJ5QVpOTYwZv7IIqiNnN6iUs_nKbvcN1bzvdIa4x-4BmEs1Z3Vqqqs4pnAV0Rl8ertQTIA6-X-8SzdjYS_L3Fv2lhhks4qxnUYmrcjCFxQoMxtV0CG_Owhzu4SuG68bvFq-Ity1oIr8jQH2QidWSVWcZ2Peerp_ZSrF6qb0w8qWadXSrGNUZN6ygMi5g3dGRhZM77q79723D-H_Q6_BjfRBTqhykMBlf-FvwUjTBVXdf8VSGhqhxX3Cl9iYFRy3nRVdHXwpUfCnMYFg4id0_8SbE_iMzDzzd6YS6G1KIe22EXNDq0ox0oxRLun5FgT_xrDhBkNBg-cV-qno0AMFMJg4tRGxLtDfgW0GK4VLUf_FwoEQLQwguyS5IYTdiPaACvRqkRlW-cL44HezH4bixK5zauZLDZoBGeVmMfBs7j5AUilwfT5opWGfTDV5E-ce3EtiOaVtRmwkiJtTFzbXv7G06mj3bDGpERv0_WEbLwmshjUQ_kQ5-kHHX_CUMLmwlkn2MpoIE8K7WRyy52RdY_aoK--VpEABoeeFkzsVL5v2LZDZVE-e1KJZ8-aW03Ctj3Oogb_YVBfc-sM20fJDq5uw3i_ZPn4KU--2q5_SgEJ4b50buYmDPUMaYAiTbGbn3FTn37bLfprsU5foYmekDfOdbhcYNDD_tuzBgnAlCX5dm-C3ip5xT62HU6dwFuBRnPbN9UIFtcGgM-ZNyFoI2rODndy2u-D99tMFJVNyL2YpXzRCGNHouriTeidA3357I6NQ04GaFHTT2Kl1dkPXWQMWJ5IN7Iq1gnWH-obCAPt7v66DCCvCJcACYRc9A==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:25",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400004eb4b784",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3871,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400004eb4b784_0",
                    "local_arrival": "2024-10-08T14:45:00.000Z",
                    "local_departure": "2024-10-08T13:25:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:45:00.000Z",
                    "utc_departure": "2024-10-08T11:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400004eb4b784_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G1lKcuaxdVohmrHSt58pjkDKHTzBEWuGKq4Mx52HpO1Y_UegExdL5J1qbJo8gCzP-aAHL5QXo343u1u4ol2kigRhxlnF3iljXIxE3lDBvjEe5KaaloolU5pPhXv-86YyeeSFjrzfKqrmF1xjFWJxFzv1cJZFx0upL5axH0e4M7dP5rrJEsrpB07DAQCDLOstbudQvqE-8_7XGSYHT1Hi17nNYjA38GRrUX4uEIU5z0hv8wUKlwk05fN-oulqP8d1zLoewvuNCTwruvWmcVbuRQ70Mz5CHiQ5vWF-nyvup7GjkJXk8lBrm-1dfXA-K1rJi4PXvMzTW48_1J-oYC1N9ufjGz_66O2BcpbkG4GN-fvDqMvZW9vedfSUH-QWxImEgqQ2v_El5hNjqWQfBuBybaCWW7FdJ5QVpOTYwZv7IIqiNnN6iUs_nKbvcN1bzvdIa4x-4BmEs1Z3Vqqqs4pnAV0Rl8ertQTIA6-X-8SzdjYS_L3Fv2lhhks4qxnUYmrcjCFxQoMxtV0CG_Owhzu4SuG68bvFq-Ity1oIr8jQH2QidWSVWcZ2Peerp_ZSrF6qb0w8qWadXSrGNUZN6ygMi5g3dGRhZM77q79723D-H_Q6_BjfRBTqhykMBlf-FvwUjTBVXdf8VSGhqhxX3Cl9iYFRy3nRVdHXwpUfCnMYFg4id0_8SbE_iMzDzzd6YS6G1KIe22EXNDq0ox0oxRLun5FgT_xrDhBkNBg-cV-qno0AMFMJg4tRGxLtDfgW0GK4VLUf_FwoEQLQwguyS5IYTdiPaACvRqkRlW-cL44HezH4bixK5zauZLDZoBGeVmMfBs7j5AUilwfT5opWGfTDV5E-ce3EtiOaVtRmwkiJtTFzbXv7G06mj3bDGpERv0_WEbLwmshjUQ_kQ5-kHHX_CUMLmwlkn2MpoIE8K7WRyy52RdY_aoK--VpEABoeeFkzsVL5v2LZDZVE-e1KJZ8-aW03Ctj3Oogb_YVBfc-sM20fJDq5uw3i_ZPn4KU--2q5_SgEJ4b50buYmDPUMaYAiTbGbn3FTn37bLfprsU5foYmekDfOdbhcYNDD_tuzBgnAlCX5dm-C3ip5xT62HU6dwFuBRnPbN9UIFtcGgM-ZNyFoI2rODndy2u-D99tMFJVNyL2YpXzRCGNHouriTeidA3357I6NQ04GaFHTT2Kl1dkPXWQMWJ5IN7Iq1gnWH-obCAPt7v66DCCvCJcACYRc9A==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e2b0000b0b6a3f4",
                    "equipment": null,
                    "fare_basis": "VNR",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e2b0000b0b6a3f4_0",
                    "local_arrival": "2024-10-15T18:35:00.000Z",
                    "local_departure": "2024-10-15T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:35:00.000Z",
                    "utc_departure": "2024-10-15T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1129.67,
            "totalPrice": 962
          },
          {
            "allPassengerTotalPrice": 966,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtTGpT2KEJ_RRdM2L6-SpxLZbp9oJQ96TfO1jA9VcHH36ZFWi_LaorFsJv87YWEtgO4yZNxqEkvTIMPCBZz003Tx_cagK420mZo5TBaAhRT8uB5ylbvaFUyTAR8nSsfu1tpbE-tClXPzAqMM0ot_mjuQOmLksq3Y6VdVGyTZPSkziBTm9pD3wwnorEHfN-2kpSEyH7YYPCfZT7BRT8hH5tCYu2DuwZtCziL7lBlcbfZAYLH4xRmYWj20TGjibxeytzZuya71u-TJF2Z-15pWJY-T2YQi_hc_jG7VQw2NDARRalt3rZ3feBtfDr34XhBlVOscDid2AoODTO8r3m1BQj4mQHHLNbnait9-CWZWARUk-wcQQEO7rU2Ts82K9GA7yzpA5xoi0XovguG8EWCRbxU11OChvPC6Yf-Gcvf2HHlAgz6zkQb1n7l7kaEX9oK0A33AN1Pnj_j2as0cQdzMFFbzu4I8Ku6VsDyNkOf0mD5mbqazZYpP-l0WgnwE2KmEY3uFxwt1Q3TVdEIGTk9Rp8QMIr95lr99sk7ZyhZSK73-VqfzR0TzjRb35ZggKtikXa3HLd2OHKCd3ve8_HvpzbyLepfhbSnCShxcGDUqbqF1DRudmNVmJbZbdzyWmR2jmGRhqLR4JKbOm2OXAAVP7ZDRlywkIhRyQ5kKlJxl8mZygZaJNw8YXLqLtlLU8P1Aanxaz5eRhaI21Z7Ij_-aQkE-VnMzSrqnI1pz9tJie6CKG7iTJQnmlpLWTo0Z2meVFyNWct0fYnpXLggE8UW_PHNs1VZ0lMOU3R4x83iqH310wGg4rNouLlif6j753lGiESesOJy1MUkwM82jgJBiST8H9vYb0inIdKA8Ls1yMsHR0ceBFBGA4ATT04DRxvNtnSWi1x1byCghxEEfbfKIMKla8JJwsLrlIW63Sy4Panz_oRl1MJBTch9SB31pfiEqR1vxPB15bUNgODnnUXEESEA9TrALtJa9xW_KJkMdzCxm5PHl00QcFFUM0gA_1HERSHRpwivWxGOqJAv7-WPbz0vHtyM89lq-xSpTRqxvbAidgOpajqui5RFHdGsnZ-V42LTVTsc_ldkZvTBIgQ3J1r3Bj6yDNtMVR4ssYv2mVLN67qOsrOF_2rzA6a39UVwJ9niwQBHPgNYnNtRZldAzfoxzsJpjBo-jRckoJMPbIe8Q1ZObL0SpORQxdJxv8zLp5U0HCBRRXwo1j6nPe466aTgr1oe15ap8ecN6-7Py3zlc=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtTGpT2KEJ_RRdM2L6-SpxLZbp9oJQ96TfO1jA9VcHH36ZFWi_LaorFsJv87YWEtgO4yZNxqEkvTIMPCBZz003Tx_cagK420mZo5TBaAhRT8uB5ylbvaFUyTAR8nSsfu1tpbE-tClXPzAqMM0ot_mjuQOmLksq3Y6VdVGyTZPSkziBTm9pD3wwnorEHfN-2kpSEyH7YYPCfZT7BRT8hH5tCYu2DuwZtCziL7lBlcbfZAYLH4xRmYWj20TGjibxeytzZuya71u-TJF2Z-15pWJY-T2YQi_hc_jG7VQw2NDARRalt3rZ3feBtfDr34XhBlVOscDid2AoODTO8r3m1BQj4mQHHLNbnait9-CWZWARUk-wcQQEO7rU2Ts82K9GA7yzpA5xoi0XovguG8EWCRbxU11OChvPC6Yf-Gcvf2HHlAgz6zkQb1n7l7kaEX9oK0A33AN1Pnj_j2as0cQdzMFFbzu4I8Ku6VsDyNkOf0mD5mbqazZYpP-l0WgnwE2KmEY3uFxwt1Q3TVdEIGTk9Rp8QMIr95lr99sk7ZyhZSK73-VqfzR0TzjRb35ZggKtikXa3HLd2OHKCd3ve8_HvpzbyLepfhbSnCShxcGDUqbqF1DRudmNVmJbZbdzyWmR2jmGRhqLR4JKbOm2OXAAVP7ZDRlywkIhRyQ5kKlJxl8mZygZaJNw8YXLqLtlLU8P1Aanxaz5eRhaI21Z7Ij_-aQkE-VnMzSrqnI1pz9tJie6CKG7iTJQnmlpLWTo0Z2meVFyNWct0fYnpXLggE8UW_PHNs1VZ0lMOU3R4x83iqH310wGg4rNouLlif6j753lGiESesOJy1MUkwM82jgJBiST8H9vYb0inIdKA8Ls1yMsHR0ceBFBGA4ATT04DRxvNtnSWi1x1byCghxEEfbfKIMKla8JJwsLrlIW63Sy4Panz_oRl1MJBTch9SB31pfiEqR1vxPB15bUNgODnnUXEESEA9TrALtJa9xW_KJkMdzCxm5PHl00QcFFUM0gA_1HERSHRpwivWxGOqJAv7-WPbz0vHtyM89lq-xSpTRqxvbAidgOpajqui5RFHdGsnZ-V42LTVTsc_ldkZvTBIgQ3J1r3Bj6yDNtMVR4ssYv2mVLN67qOsrOF_2rzA6a39UVwJ9niwQBHPgNYnNtRZldAzfoxzsJpjBo-jRckoJMPbIe8Q1ZObL0SpORQxdJxv8zLp5U0HCBRRXwo1j6nPe466aTgr1oe15ap8ecN6-7Py3zlc=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e240000a74c7035",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e240000a74c7035_0",
                    "local_arrival": "2024-10-08T19:20:00.000Z",
                    "local_departure": "2024-10-08T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-08T17:20:00.000Z",
                    "utc_departure": "2024-10-08T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e2400000210c421",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e2400000210c421_0",
                    "local_arrival": "2024-10-08T22:10:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:10:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GtTGpT2KEJ_RRdM2L6-SpxLZbp9oJQ96TfO1jA9VcHH36ZFWi_LaorFsJv87YWEtgO4yZNxqEkvTIMPCBZz003Tx_cagK420mZo5TBaAhRT8uB5ylbvaFUyTAR8nSsfu1tpbE-tClXPzAqMM0ot_mjuQOmLksq3Y6VdVGyTZPSkziBTm9pD3wwnorEHfN-2kpSEyH7YYPCfZT7BRT8hH5tCYu2DuwZtCziL7lBlcbfZAYLH4xRmYWj20TGjibxeytzZuya71u-TJF2Z-15pWJY-T2YQi_hc_jG7VQw2NDARRalt3rZ3feBtfDr34XhBlVOscDid2AoODTO8r3m1BQj4mQHHLNbnait9-CWZWARUk-wcQQEO7rU2Ts82K9GA7yzpA5xoi0XovguG8EWCRbxU11OChvPC6Yf-Gcvf2HHlAgz6zkQb1n7l7kaEX9oK0A33AN1Pnj_j2as0cQdzMFFbzu4I8Ku6VsDyNkOf0mD5mbqazZYpP-l0WgnwE2KmEY3uFxwt1Q3TVdEIGTk9Rp8QMIr95lr99sk7ZyhZSK73-VqfzR0TzjRb35ZggKtikXa3HLd2OHKCd3ve8_HvpzbyLepfhbSnCShxcGDUqbqF1DRudmNVmJbZbdzyWmR2jmGRhqLR4JKbOm2OXAAVP7ZDRlywkIhRyQ5kKlJxl8mZygZaJNw8YXLqLtlLU8P1Aanxaz5eRhaI21Z7Ij_-aQkE-VnMzSrqnI1pz9tJie6CKG7iTJQnmlpLWTo0Z2meVFyNWct0fYnpXLggE8UW_PHNs1VZ0lMOU3R4x83iqH310wGg4rNouLlif6j753lGiESesOJy1MUkwM82jgJBiST8H9vYb0inIdKA8Ls1yMsHR0ceBFBGA4ATT04DRxvNtnSWi1x1byCghxEEfbfKIMKla8JJwsLrlIW63Sy4Panz_oRl1MJBTch9SB31pfiEqR1vxPB15bUNgODnnUXEESEA9TrALtJa9xW_KJkMdzCxm5PHl00QcFFUM0gA_1HERSHRpwivWxGOqJAv7-WPbz0vHtyM89lq-xSpTRqxvbAidgOpajqui5RFHdGsnZ-V42LTVTsc_ldkZvTBIgQ3J1r3Bj6yDNtMVR4ssYv2mVLN67qOsrOF_2rzA6a39UVwJ9niwQBHPgNYnNtRZldAzfoxzsJpjBo-jRckoJMPbIe8Q1ZObL0SpORQxdJxv8zLp5U0HCBRRXwo1j6nPe466aTgr1oe15ap8ecN6-7Py3zlc=",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1128.83,
            "totalPrice": 966
          },
          {
            "allPassengerTotalPrice": 967,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlUWzHH3HFmi4jDJPq1fK1KSK42K3gUnc96wo2T_WTyvTC8RkfptlnbsMMjM_B1jGEOHHlQ8zULqk0P1GeNtLhDFgz0NUCHkkiaM6EVB3hQnWSw2Bn5T3a9nzkNOaAIRm30eZDnoASPbgjlHCO9sZHKwrAJCj-imvF8EhLOSsrwi1PDiPAW_GoSqbZjj2DmEwt_HUhIR1eQuq7sYpBNfZfo_stSpJdgUHWH3aM-AnCmjgUKo9afvfaS6BBRSuBD0x1a50hCFuSxVFj3Z2C4aoqgJD16FIqvzc3r3QREHZigpkH3Fj8GaszmIIyfTeaSDMa9gVH4kBWS-H3fUs6bPV4wSA_EBa8eTqxoSq4_FfS3uQtITY6S_BZW5FmwjvzsBUMV1D1-4V1smsvoTpwaMil4WQf_klBHOVsgiqMDz-exKRccbrirosy-YBkvr-0o6JRFBp1LHk_9N3B6O8jWTikbdXrUAhqeXPAo9ORpilivGylIQLRdoEFET01gZNyD7UsQVCJqQrSvrGBfnhHpHPXs5Xx2R0okGg1SzG85bkYrXCSCGj9Jy6qtJzzJM8ICuMet0dJ_PIn-W8s3NhiuDchJ6o2-wjL0RF0UjsnXJH0EW9SA-OXX4J6UlWqrlJ2ftXfVw0yRgHrhnedOYKkw44WInamWmpMlIzAccUKS7AYbTM4-qooWwhWIJ1uGTOuTFeXJqJ5CrXP78lnJOPuFk9XnfIiOc3F54b1k88Gcd1qG0szK91RxKTwHE1OMFsej9AjrbxkV3tGRUL0-j3lM_tBV7iti-ZYiz8FO04FVazfRwIRCqEUxe8S7usDnh4mj7DKtvhAmo23Y7Xx57NhPPDuVcviWwpLjyx87yZvOVA3AGPLDGIVMgZEP8EcMY_TOQRd7-nPnn5qbVuTP0Qc0h9bfGzGuvl7gqreKfShG_OOEEXHgQuKrkc4fRH05MtGG8VXplpD0CGqvufFGjWzLCnS9_QyRU_btSt83iZqhKcimBLoiyH5c7pJyIysScGqPFzUczbfPi9Qv057qgmH2Nk_dYrl79WLLdq-ozt1zuhF7A52aJuTC8TpWZLKUyuHCQQXdSOGo9CBDqzyrevJVRCOFECYJx2t1CWbir9QjVDdpk=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlUWzHH3HFmi4jDJPq1fK1KSK42K3gUnc96wo2T_WTyvTC8RkfptlnbsMMjM_B1jGEOHHlQ8zULqk0P1GeNtLhDFgz0NUCHkkiaM6EVB3hQnWSw2Bn5T3a9nzkNOaAIRm30eZDnoASPbgjlHCO9sZHKwrAJCj-imvF8EhLOSsrwi1PDiPAW_GoSqbZjj2DmEwt_HUhIR1eQuq7sYpBNfZfo_stSpJdgUHWH3aM-AnCmjgUKo9afvfaS6BBRSuBD0x1a50hCFuSxVFj3Z2C4aoqgJD16FIqvzc3r3QREHZigpkH3Fj8GaszmIIyfTeaSDMa9gVH4kBWS-H3fUs6bPV4wSA_EBa8eTqxoSq4_FfS3uQtITY6S_BZW5FmwjvzsBUMV1D1-4V1smsvoTpwaMil4WQf_klBHOVsgiqMDz-exKRccbrirosy-YBkvr-0o6JRFBp1LHk_9N3B6O8jWTikbdXrUAhqeXPAo9ORpilivGylIQLRdoEFET01gZNyD7UsQVCJqQrSvrGBfnhHpHPXs5Xx2R0okGg1SzG85bkYrXCSCGj9Jy6qtJzzJM8ICuMet0dJ_PIn-W8s3NhiuDchJ6o2-wjL0RF0UjsnXJH0EW9SA-OXX4J6UlWqrlJ2ftXfVw0yRgHrhnedOYKkw44WInamWmpMlIzAccUKS7AYbTM4-qooWwhWIJ1uGTOuTFeXJqJ5CrXP78lnJOPuFk9XnfIiOc3F54b1k88Gcd1qG0szK91RxKTwHE1OMFsej9AjrbxkV3tGRUL0-j3lM_tBV7iti-ZYiz8FO04FVazfRwIRCqEUxe8S7usDnh4mj7DKtvhAmo23Y7Xx57NhPPDuVcviWwpLjyx87yZvOVA3AGPLDGIVMgZEP8EcMY_TOQRd7-nPnn5qbVuTP0Qc0h9bfGzGuvl7gqreKfShG_OOEEXHgQuKrkc4fRH05MtGG8VXplpD0CGqvufFGjWzLCnS9_QyRU_btSt83iZqhKcimBLoiyH5c7pJyIysScGqPFzUczbfPi9Qv057qgmH2Nk_dYrl79WLLdq-ozt1zuhF7A52aJuTC8TpWZLKUyuHCQQXdSOGo9CBDqzyrevJVRCOFECYJx2t1CWbir9QjVDdpk=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GlUWzHH3HFmi4jDJPq1fK1KSK42K3gUnc96wo2T_WTyvTC8RkfptlnbsMMjM_B1jGEOHHlQ8zULqk0P1GeNtLhDFgz0NUCHkkiaM6EVB3hQnWSw2Bn5T3a9nzkNOaAIRm30eZDnoASPbgjlHCO9sZHKwrAJCj-imvF8EhLOSsrwi1PDiPAW_GoSqbZjj2DmEwt_HUhIR1eQuq7sYpBNfZfo_stSpJdgUHWH3aM-AnCmjgUKo9afvfaS6BBRSuBD0x1a50hCFuSxVFj3Z2C4aoqgJD16FIqvzc3r3QREHZigpkH3Fj8GaszmIIyfTeaSDMa9gVH4kBWS-H3fUs6bPV4wSA_EBa8eTqxoSq4_FfS3uQtITY6S_BZW5FmwjvzsBUMV1D1-4V1smsvoTpwaMil4WQf_klBHOVsgiqMDz-exKRccbrirosy-YBkvr-0o6JRFBp1LHk_9N3B6O8jWTikbdXrUAhqeXPAo9ORpilivGylIQLRdoEFET01gZNyD7UsQVCJqQrSvrGBfnhHpHPXs5Xx2R0okGg1SzG85bkYrXCSCGj9Jy6qtJzzJM8ICuMet0dJ_PIn-W8s3NhiuDchJ6o2-wjL0RF0UjsnXJH0EW9SA-OXX4J6UlWqrlJ2ftXfVw0yRgHrhnedOYKkw44WInamWmpMlIzAccUKS7AYbTM4-qooWwhWIJ1uGTOuTFeXJqJ5CrXP78lnJOPuFk9XnfIiOc3F54b1k88Gcd1qG0szK91RxKTwHE1OMFsej9AjrbxkV3tGRUL0-j3lM_tBV7iti-ZYiz8FO04FVazfRwIRCqEUxe8S7usDnh4mj7DKtvhAmo23Y7Xx57NhPPDuVcviWwpLjyx87yZvOVA3AGPLDGIVMgZEP8EcMY_TOQRd7-nPnn5qbVuTP0Qc0h9bfGzGuvl7gqreKfShG_OOEEXHgQuKrkc4fRH05MtGG8VXplpD0CGqvufFGjWzLCnS9_QyRU_btSt83iZqhKcimBLoiyH5c7pJyIysScGqPFzUczbfPi9Qv057qgmH2Nk_dYrl79WLLdq-ozt1zuhF7A52aJuTC8TpWZLKUyuHCQQXdSOGo9CBDqzyrevJVRCOFECYJx2t1CWbir9QjVDdpk=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1097.45,
            "totalPrice": 967
          },
          {
            "allPassengerTotalPrice": 969,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRaWzf8fF11cKc8rgGHdMVFylP1-_8tLRZWIcDlPIuKCiCMb6w7oDTbJsBlwZ2rzjOQz_fScDW5u8dsH-ZE2R_tHhBZ9wrQBYMTn0EkEb6637feF-bW0-4ugqdA2n8oUBRzfDbIkRQEdYhs7S4DXmV6O7EY9IlVaKyxfSqmzscgmMl9wu-OwVWYpfUKyFB09oTc7nmWdTvSMljgzJB2NIvzY0mxeGnqEMt0EMw9Y5AZjeUIAphMthd9sSjz9Tc-Hv09ujBIiIVnMwZzHuz8BvM-XFtXL9j2nQJP4u2YmQAgz1JDvSL7dtxkSuQNGVJ3j9WMwKg-KTzFS6PCrSGUuJ7T8lSCUk0gCqSYu3Ku85uQYVoBAOEvJNzv7APS4YrCIqvcSpdxNukvPqcgG5MJxhMIRO4qwu_lxgu86NuLrZoc0fY0mX2jLcMERP_8hSnOD14x92Qd1lf9nKkS826vdf_lcLgpaqVpoArxkZBMgBloQ1Q_tx19cnjjgcssTegm4VmZLsBfMja9lQJBGjz_TTrZWkaVJOFK-joukp_eyl2RWnyIqy0TGw6xbeVZUoKfQSni43MOmUbr9b1uOnFYo8GhZvAtruo714BHiOu-GNbOBfHrJWZoi_5D6lwOv5HBUxnRISkv06VcAB_RVCRXnZIRiJwsG-ThUUTIcRTq4qA8MydZo29qDny_krgZg2i3lyykz7KBEfEoPOmqL4hmzUgGuMZbNAfQsW_yDU1fVYE5A4oz92e--4ACPvRDlsdX8ZOIyqf5Y4DzqbQnRVCQPIu-0ovsIsnMJuadU10-fUVkuMoH8JXlubahpboHXFpZbr_3UrAFu2zLc_itNjzP-cjuZwFXiWdjfJs6oMJMrQyKU8nVlL_-q_vhW_nUuPoeh6t6ZwTsWav8Ev8r2jk4KPpwTb6nwBtthO-RFET3_ZZkoJDFSQuqjUtOhkrb0ken_bHYL2dPeodc4NKtxbvXvwgbWtBIKGFT7kJ-5jLOZ8wifPzvfSGKbPL6KeazdwirTQ36nJuf-BPit0LK5woKgb150TUY-3sOLKIqPGSgUBAR1hayS6e_MyHNFweYypAQqdkay6Mr_WRXIzZCJDJe21ZeRd9_bU0Sl8wgTopPV4cPE=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRaWzf8fF11cKc8rgGHdMVFylP1-_8tLRZWIcDlPIuKCiCMb6w7oDTbJsBlwZ2rzjOQz_fScDW5u8dsH-ZE2R_tHhBZ9wrQBYMTn0EkEb6637feF-bW0-4ugqdA2n8oUBRzfDbIkRQEdYhs7S4DXmV6O7EY9IlVaKyxfSqmzscgmMl9wu-OwVWYpfUKyFB09oTc7nmWdTvSMljgzJB2NIvzY0mxeGnqEMt0EMw9Y5AZjeUIAphMthd9sSjz9Tc-Hv09ujBIiIVnMwZzHuz8BvM-XFtXL9j2nQJP4u2YmQAgz1JDvSL7dtxkSuQNGVJ3j9WMwKg-KTzFS6PCrSGUuJ7T8lSCUk0gCqSYu3Ku85uQYVoBAOEvJNzv7APS4YrCIqvcSpdxNukvPqcgG5MJxhMIRO4qwu_lxgu86NuLrZoc0fY0mX2jLcMERP_8hSnOD14x92Qd1lf9nKkS826vdf_lcLgpaqVpoArxkZBMgBloQ1Q_tx19cnjjgcssTegm4VmZLsBfMja9lQJBGjz_TTrZWkaVJOFK-joukp_eyl2RWnyIqy0TGw6xbeVZUoKfQSni43MOmUbr9b1uOnFYo8GhZvAtruo714BHiOu-GNbOBfHrJWZoi_5D6lwOv5HBUxnRISkv06VcAB_RVCRXnZIRiJwsG-ThUUTIcRTq4qA8MydZo29qDny_krgZg2i3lyykz7KBEfEoPOmqL4hmzUgGuMZbNAfQsW_yDU1fVYE5A4oz92e--4ACPvRDlsdX8ZOIyqf5Y4DzqbQnRVCQPIu-0ovsIsnMJuadU10-fUVkuMoH8JXlubahpboHXFpZbr_3UrAFu2zLc_itNjzP-cjuZwFXiWdjfJs6oMJMrQyKU8nVlL_-q_vhW_nUuPoeh6t6ZwTsWav8Ev8r2jk4KPpwTb6nwBtthO-RFET3_ZZkoJDFSQuqjUtOhkrb0ken_bHYL2dPeodc4NKtxbvXvwgbWtBIKGFT7kJ-5jLOZ8wifPzvfSGKbPL6KeazdwirTQ36nJuf-BPit0LK5woKgb150TUY-3sOLKIqPGSgUBAR1hayS6e_MyHNFweYypAQqdkay6Mr_WRXIzZCJDJe21ZeRd9_bU0Sl8wgTopPV4cPE=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GRaWzf8fF11cKc8rgGHdMVFylP1-_8tLRZWIcDlPIuKCiCMb6w7oDTbJsBlwZ2rzjOQz_fScDW5u8dsH-ZE2R_tHhBZ9wrQBYMTn0EkEb6637feF-bW0-4ugqdA2n8oUBRzfDbIkRQEdYhs7S4DXmV6O7EY9IlVaKyxfSqmzscgmMl9wu-OwVWYpfUKyFB09oTc7nmWdTvSMljgzJB2NIvzY0mxeGnqEMt0EMw9Y5AZjeUIAphMthd9sSjz9Tc-Hv09ujBIiIVnMwZzHuz8BvM-XFtXL9j2nQJP4u2YmQAgz1JDvSL7dtxkSuQNGVJ3j9WMwKg-KTzFS6PCrSGUuJ7T8lSCUk0gCqSYu3Ku85uQYVoBAOEvJNzv7APS4YrCIqvcSpdxNukvPqcgG5MJxhMIRO4qwu_lxgu86NuLrZoc0fY0mX2jLcMERP_8hSnOD14x92Qd1lf9nKkS826vdf_lcLgpaqVpoArxkZBMgBloQ1Q_tx19cnjjgcssTegm4VmZLsBfMja9lQJBGjz_TTrZWkaVJOFK-joukp_eyl2RWnyIqy0TGw6xbeVZUoKfQSni43MOmUbr9b1uOnFYo8GhZvAtruo714BHiOu-GNbOBfHrJWZoi_5D6lwOv5HBUxnRISkv06VcAB_RVCRXnZIRiJwsG-ThUUTIcRTq4qA8MydZo29qDny_krgZg2i3lyykz7KBEfEoPOmqL4hmzUgGuMZbNAfQsW_yDU1fVYE5A4oz92e--4ACPvRDlsdX8ZOIyqf5Y4DzqbQnRVCQPIu-0ovsIsnMJuadU10-fUVkuMoH8JXlubahpboHXFpZbr_3UrAFu2zLc_itNjzP-cjuZwFXiWdjfJs6oMJMrQyKU8nVlL_-q_vhW_nUuPoeh6t6ZwTsWav8Ev8r2jk4KPpwTb6nwBtthO-RFET3_ZZkoJDFSQuqjUtOhkrb0ken_bHYL2dPeodc4NKtxbvXvwgbWtBIKGFT7kJ-5jLOZ8wifPzvfSGKbPL6KeazdwirTQ36nJuf-BPit0LK5woKgb150TUY-3sOLKIqPGSgUBAR1hayS6e_MyHNFweYypAQqdkay6Mr_WRXIzZCJDJe21ZeRd9_bU0Sl8wgTopPV4cPE=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1098.86,
            "totalPrice": 969
          },
          {
            "allPassengerTotalPrice": 969,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpsxTSHCZo63L0WdFqJwehSwobYyWq5BtncEdXONdBGVCe0o3cSL7XZocBlxd4Q8JJ6Cu6ZSZjcla_a-ax3tExGuNjtkY9-PzTngeqTAy_n1uoqjoyWjoyOj6yc5pCocEcTUGXBKqkzZ58MXCaN2vXQrb6vWLEPJxui_REDI_mc-h_J8YLOMNKGwia_tqS9DMze2CQi20HD7o29jyKqSJlXXLDlpm6eaWVnvcoyO2w3O4wDj36I8TSvg0kpINHl8VvZfW8bD377ZIeCUw1TK0PXiQ4PGSQb1Cin9S_kvcd82m3_eWBhsSvVO-o_VCLvPYvQstGESIUjV11z0wGYMyaIBoFmKPGuz_M-qxQD0EipnHSYMJda3zHY9WQDxvKTuPKfmSFRfzkiNqcKeJsGXUcyW1OTiAVTWGj7gpFtuB9xIgpdtzv-DzQZl_fCJne0U91uqRdTGCFw97a9hrsFg3hO04vuwyuhmmk0nHadKTs10oG-n65QGctqEAJ4cBj4fkl6H9-cVFpR2EbfniN2G-RCZr60XPE3hv8qqXEXIQN0ABlVCa3409VzNQGVC48jh5pofxgDsXS343OXHWVrQmi8PcKFi-JBnaVkMHhYBVhd_JEhDukiE0vL0lAfEXc4C_HbDlfBnEkknQvlRUIO2TIJKoUSOEbaJwKLuYD-JdgbGm3KOkg6XhbMGE-gMaoDKWmyxnBpKL59thDuD1lDtQ4buRyucyjYitOYFY5iIVecrcl6YnHOxzyHWRE3cP0OpzSXhjQ8kRuq7YpgztBHVIsFRBtL4-CGtrxdqPtuXcaOpHX9_8W_JRKFs9SZ1b4BUw3SgNjr1gTko4Bb7QodIrEAEp6mWyrQV7ax6wWiQr-a5iABgyFgKzzuCcGrfcK8-b7B7ydz9k4Ku3eiZFxZBA9k253iBQxE5g4mBlnWAU_7DA57xz_iQCjL8mS7SWtxGslt1Enlo4pM2abXYRxfYsnV8EV2Az1y1c1xkbX-eh2ip1WFegRlvhcZjSUlCkBwNctJohDmNAr8TjZg2Q_SLNGoIt5f6W3r4CRWoZd_f6KkNQhaevK84RRc1jC1h2b4WngJpcc4ioCWUQYxXJc7LR_T4ocJv_TsiAALh-PT4aBENBYM00KhJHyLt6x65vSPfA_mr6qDfI6mWdV7aSlWmHqlhvUch7wxAK0gly19xbLqXZQ0M23u574k84MV6W11EPT9uTeY6OiGEzU8nbmcFKHQ==",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "18:55",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpsxTSHCZo63L0WdFqJwehSwobYyWq5BtncEdXONdBGVCe0o3cSL7XZocBlxd4Q8JJ6Cu6ZSZjcla_a-ax3tExGuNjtkY9-PzTngeqTAy_n1uoqjoyWjoyOj6yc5pCocEcTUGXBKqkzZ58MXCaN2vXQrb6vWLEPJxui_REDI_mc-h_J8YLOMNKGwia_tqS9DMze2CQi20HD7o29jyKqSJlXXLDlpm6eaWVnvcoyO2w3O4wDj36I8TSvg0kpINHl8VvZfW8bD377ZIeCUw1TK0PXiQ4PGSQb1Cin9S_kvcd82m3_eWBhsSvVO-o_VCLvPYvQstGESIUjV11z0wGYMyaIBoFmKPGuz_M-qxQD0EipnHSYMJda3zHY9WQDxvKTuPKfmSFRfzkiNqcKeJsGXUcyW1OTiAVTWGj7gpFtuB9xIgpdtzv-DzQZl_fCJne0U91uqRdTGCFw97a9hrsFg3hO04vuwyuhmmk0nHadKTs10oG-n65QGctqEAJ4cBj4fkl6H9-cVFpR2EbfniN2G-RCZr60XPE3hv8qqXEXIQN0ABlVCa3409VzNQGVC48jh5pofxgDsXS343OXHWVrQmi8PcKFi-JBnaVkMHhYBVhd_JEhDukiE0vL0lAfEXc4C_HbDlfBnEkknQvlRUIO2TIJKoUSOEbaJwKLuYD-JdgbGm3KOkg6XhbMGE-gMaoDKWmyxnBpKL59thDuD1lDtQ4buRyucyjYitOYFY5iIVecrcl6YnHOxzyHWRE3cP0OpzSXhjQ8kRuq7YpgztBHVIsFRBtL4-CGtrxdqPtuXcaOpHX9_8W_JRKFs9SZ1b4BUw3SgNjr1gTko4Bb7QodIrEAEp6mWyrQV7ax6wWiQr-a5iABgyFgKzzuCcGrfcK8-b7B7ydz9k4Ku3eiZFxZBA9k253iBQxE5g4mBlnWAU_7DA57xz_iQCjL8mS7SWtxGslt1Enlo4pM2abXYRxfYsnV8EV2Az1y1c1xkbX-eh2ip1WFegRlvhcZjSUlCkBwNctJohDmNAr8TjZg2Q_SLNGoIt5f6W3r4CRWoZd_f6KkNQhaevK84RRc1jC1h2b4WngJpcc4ioCWUQYxXJc7LR_T4ocJv_TsiAALh-PT4aBENBYM00KhJHyLt6x65vSPfA_mr6qDfI6mWdV7aSlWmHqlhvUch7wxAK0gly19xbLqXZQ0M23u574k84MV6W11EPT9uTeY6OiGEzU8nbmcFKHQ==",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:40",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000becc23f0",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3867,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000becc23f0_0",
                    "local_arrival": "2024-10-08T18:55:00.000Z",
                    "local_departure": "2024-10-08T17:40:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3867",
                    "return": 0,
                    "utc_arrival": "2024-10-08T16:55:00.000Z",
                    "utc_departure": "2024-10-08T15:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000becc23f0_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GpsxTSHCZo63L0WdFqJwehSwobYyWq5BtncEdXONdBGVCe0o3cSL7XZocBlxd4Q8JJ6Cu6ZSZjcla_a-ax3tExGuNjtkY9-PzTngeqTAy_n1uoqjoyWjoyOj6yc5pCocEcTUGXBKqkzZ58MXCaN2vXQrb6vWLEPJxui_REDI_mc-h_J8YLOMNKGwia_tqS9DMze2CQi20HD7o29jyKqSJlXXLDlpm6eaWVnvcoyO2w3O4wDj36I8TSvg0kpINHl8VvZfW8bD377ZIeCUw1TK0PXiQ4PGSQb1Cin9S_kvcd82m3_eWBhsSvVO-o_VCLvPYvQstGESIUjV11z0wGYMyaIBoFmKPGuz_M-qxQD0EipnHSYMJda3zHY9WQDxvKTuPKfmSFRfzkiNqcKeJsGXUcyW1OTiAVTWGj7gpFtuB9xIgpdtzv-DzQZl_fCJne0U91uqRdTGCFw97a9hrsFg3hO04vuwyuhmmk0nHadKTs10oG-n65QGctqEAJ4cBj4fkl6H9-cVFpR2EbfniN2G-RCZr60XPE3hv8qqXEXIQN0ABlVCa3409VzNQGVC48jh5pofxgDsXS343OXHWVrQmi8PcKFi-JBnaVkMHhYBVhd_JEhDukiE0vL0lAfEXc4C_HbDlfBnEkknQvlRUIO2TIJKoUSOEbaJwKLuYD-JdgbGm3KOkg6XhbMGE-gMaoDKWmyxnBpKL59thDuD1lDtQ4buRyucyjYitOYFY5iIVecrcl6YnHOxzyHWRE3cP0OpzSXhjQ8kRuq7YpgztBHVIsFRBtL4-CGtrxdqPtuXcaOpHX9_8W_JRKFs9SZ1b4BUw3SgNjr1gTko4Bb7QodIrEAEp6mWyrQV7ax6wWiQr-a5iABgyFgKzzuCcGrfcK8-b7B7ydz9k4Ku3eiZFxZBA9k253iBQxE5g4mBlnWAU_7DA57xz_iQCjL8mS7SWtxGslt1Enlo4pM2abXYRxfYsnV8EV2Az1y1c1xkbX-eh2ip1WFegRlvhcZjSUlCkBwNctJohDmNAr8TjZg2Q_SLNGoIt5f6W3r4CRWoZd_f6KkNQhaevK84RRc1jC1h2b4WngJpcc4ioCWUQYxXJc7LR_T4ocJv_TsiAALh-PT4aBENBYM00KhJHyLt6x65vSPfA_mr6qDfI6mWdV7aSlWmHqlhvUch7wxAK0gly19xbLqXZQ0M23u574k84MV6W11EPT9uTeY6OiGEzU8nbmcFKHQ==",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e2b0000b0b6a3f4",
                    "equipment": null,
                    "fare_basis": "VNR",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e2b0000b0b6a3f4_0",
                    "local_arrival": "2024-10-15T18:35:00.000Z",
                    "local_departure": "2024-10-15T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:35:00.000Z",
                    "utc_departure": "2024-10-15T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1134.6,
            "totalPrice": 969
          },
          {
            "allPassengerTotalPrice": 971,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GC7a0ShpMChX57yq_T1orCqWqJKLSD1RTPsrGFKHQ4kiwO6CAeJX0wMK4eP74r6ZJ-HuLRj_j9bO4_4tV5OBGyv7f4-YHq96H9LbXlamIe2vgfa3gl7IA1QrAC0-QAC7UayjVTbLSDynj64BVtA5aNjphLdv8CI_vt1krhJ9Y_eLPoZ7tCRFGK8CCVDDDytP6nNR4CgAywBpdADcXScuErtkaUMBxLXpANOB29BrhN3hAbOlkhEP2r3MnerFmLQIAhipKiJzzZyfG5ohFgb4qvVkY7k1oOCO9bB6-ry9IkXZW6SzehHfznmp4ASuO75IIThPW9EWlJXHpnTXmxz4iZ5Vx1UHL71ohAHJozXGv_QiIIyzh255ZfuBajS7Dhl-Qc4eJpT3RzfMQMbvRpFEp9ZqJ4n2hbS3-3-6PkvcoZBuuy8x8Pk0ZoTgX4FRxvPPeAf6gNmBiDywSk30S8BnjVp8-LsyPqn4SrtGfd2A4OjbmO-NsTvi196Fh1TTOrVksR8wL8aWVp5dXzQA58cMkmGNNStmKO7piWc6Dq45Yh60gPEVhLBwTrKlDZNQDS8a97wKLG2vg5JowZ7lTGEwD92YBh7fp0eDZcWpmFQKZou4RiY6h_xFwEK-GVzOrDUJ5nj3mCoKL92a9xPaKTJueRY2pCOaAI2oK0CAY5HVGmlrUcJvGc0HAVUst1RzPJJtgpkKtZGfqBmxQ7Hav0v0VuA4JM8ESbRTrTL2kSAadh-x5svhCGS4uYMbbX7jFZckf7bXT1p9vTkRN_pXPkTUvpqcNGI3TIgD67-oHHLcOebDsMRuRYoQhbu9urZfVyuEpftDCFl5xw2ktMM5Ff4CJDJzyabRkHtuPwOgPVnXGb_C2SPchJlFhBnyyBTi1m179iuNzdboTC9zuMFQC4a25uLnT7d3Ux-KR8_iA8nza1dC-CeH02RTpFQg8GMgVgTCTjiCbip-6IzSAjcMD1Ammb5Uj_xd4A3UbmyuMYPklSUAof2zbnwo3NKaKUCbeh3kdo0KRX7ooLEUf5EboAEl4b1nz-fpNsZnemuIt_wAGfUn6lHnYWZsyyXLX3cSVTTJZ0VCbT91lMDKpo2jRk55h_fcTt_2VUI5ZRO1lEKN38PLbwRgwQ6cBH8ywiA6FdCRc6WUPyQbbOMlPWH_6sUcFu_6MDnjlARdMSB-sCPf-yT-AF2QjSIsm_UPOM24FDu2aX5WRQyuI0VFjqS7FqWn8M3Xl12Kg_oIpKe7iTuU1dVQ=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:20",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GC7a0ShpMChX57yq_T1orCqWqJKLSD1RTPsrGFKHQ4kiwO6CAeJX0wMK4eP74r6ZJ-HuLRj_j9bO4_4tV5OBGyv7f4-YHq96H9LbXlamIe2vgfa3gl7IA1QrAC0-QAC7UayjVTbLSDynj64BVtA5aNjphLdv8CI_vt1krhJ9Y_eLPoZ7tCRFGK8CCVDDDytP6nNR4CgAywBpdADcXScuErtkaUMBxLXpANOB29BrhN3hAbOlkhEP2r3MnerFmLQIAhipKiJzzZyfG5ohFgb4qvVkY7k1oOCO9bB6-ry9IkXZW6SzehHfznmp4ASuO75IIThPW9EWlJXHpnTXmxz4iZ5Vx1UHL71ohAHJozXGv_QiIIyzh255ZfuBajS7Dhl-Qc4eJpT3RzfMQMbvRpFEp9ZqJ4n2hbS3-3-6PkvcoZBuuy8x8Pk0ZoTgX4FRxvPPeAf6gNmBiDywSk30S8BnjVp8-LsyPqn4SrtGfd2A4OjbmO-NsTvi196Fh1TTOrVksR8wL8aWVp5dXzQA58cMkmGNNStmKO7piWc6Dq45Yh60gPEVhLBwTrKlDZNQDS8a97wKLG2vg5JowZ7lTGEwD92YBh7fp0eDZcWpmFQKZou4RiY6h_xFwEK-GVzOrDUJ5nj3mCoKL92a9xPaKTJueRY2pCOaAI2oK0CAY5HVGmlrUcJvGc0HAVUst1RzPJJtgpkKtZGfqBmxQ7Hav0v0VuA4JM8ESbRTrTL2kSAadh-x5svhCGS4uYMbbX7jFZckf7bXT1p9vTkRN_pXPkTUvpqcNGI3TIgD67-oHHLcOebDsMRuRYoQhbu9urZfVyuEpftDCFl5xw2ktMM5Ff4CJDJzyabRkHtuPwOgPVnXGb_C2SPchJlFhBnyyBTi1m179iuNzdboTC9zuMFQC4a25uLnT7d3Ux-KR8_iA8nza1dC-CeH02RTpFQg8GMgVgTCTjiCbip-6IzSAjcMD1Ammb5Uj_xd4A3UbmyuMYPklSUAof2zbnwo3NKaKUCbeh3kdo0KRX7ooLEUf5EboAEl4b1nz-fpNsZnemuIt_wAGfUn6lHnYWZsyyXLX3cSVTTJZ0VCbT91lMDKpo2jRk55h_fcTt_2VUI5ZRO1lEKN38PLbwRgwQ6cBH8ywiA6FdCRc6WUPyQbbOMlPWH_6sUcFu_6MDnjlARdMSB-sCPf-yT-AF2QjSIsm_UPOM24FDu2aX5WRQyuI0VFjqS7FqWn8M3Xl12Kg_oIpKe7iTuU1dVQ=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "21:05",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "UX",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e2400001efff409",
                    "equipment": null,
                    "fare_basis": "ZDYO5L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 5038,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e2400001efff409_0",
                    "local_arrival": "2024-10-08T22:20:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "5038",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:20:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "EC",
                  "",
                  "NK"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "18:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e2400001efff409_0%7C04b801af4e2a00005aff2a3e_0%7C01af22f54e2b00004d69d883_0%7C22f524464e2b00002e22373c_0%7C244610a14e2b0000b0b6a3f4_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GC7a0ShpMChX57yq_T1orCqWqJKLSD1RTPsrGFKHQ4kiwO6CAeJX0wMK4eP74r6ZJ-HuLRj_j9bO4_4tV5OBGyv7f4-YHq96H9LbXlamIe2vgfa3gl7IA1QrAC0-QAC7UayjVTbLSDynj64BVtA5aNjphLdv8CI_vt1krhJ9Y_eLPoZ7tCRFGK8CCVDDDytP6nNR4CgAywBpdADcXScuErtkaUMBxLXpANOB29BrhN3hAbOlkhEP2r3MnerFmLQIAhipKiJzzZyfG5ohFgb4qvVkY7k1oOCO9bB6-ry9IkXZW6SzehHfznmp4ASuO75IIThPW9EWlJXHpnTXmxz4iZ5Vx1UHL71ohAHJozXGv_QiIIyzh255ZfuBajS7Dhl-Qc4eJpT3RzfMQMbvRpFEp9ZqJ4n2hbS3-3-6PkvcoZBuuy8x8Pk0ZoTgX4FRxvPPeAf6gNmBiDywSk30S8BnjVp8-LsyPqn4SrtGfd2A4OjbmO-NsTvi196Fh1TTOrVksR8wL8aWVp5dXzQA58cMkmGNNStmKO7piWc6Dq45Yh60gPEVhLBwTrKlDZNQDS8a97wKLG2vg5JowZ7lTGEwD92YBh7fp0eDZcWpmFQKZou4RiY6h_xFwEK-GVzOrDUJ5nj3mCoKL92a9xPaKTJueRY2pCOaAI2oK0CAY5HVGmlrUcJvGc0HAVUst1RzPJJtgpkKtZGfqBmxQ7Hav0v0VuA4JM8ESbRTrTL2kSAadh-x5svhCGS4uYMbbX7jFZckf7bXT1p9vTkRN_pXPkTUvpqcNGI3TIgD67-oHHLcOebDsMRuRYoQhbu9urZfVyuEpftDCFl5xw2ktMM5Ff4CJDJzyabRkHtuPwOgPVnXGb_C2SPchJlFhBnyyBTi1m179iuNzdboTC9zuMFQC4a25uLnT7d3Ux-KR8_iA8nza1dC-CeH02RTpFQg8GMgVgTCTjiCbip-6IzSAjcMD1Ammb5Uj_xd4A3UbmyuMYPklSUAof2zbnwo3NKaKUCbeh3kdo0KRX7ooLEUf5EboAEl4b1nz-fpNsZnemuIt_wAGfUn6lHnYWZsyyXLX3cSVTTJZ0VCbT91lMDKpo2jRk55h_fcTt_2VUI5ZRO1lEKN38PLbwRgwQ6cBH8ywiA6FdCRc6WUPyQbbOMlPWH_6sUcFu_6MDnjlARdMSB-sCPf-yT-AF2QjSIsm_UPOM24FDu2aX5WRQyuI0VFjqS7FqWn8M3Xl12Kg_oIpKe7iTuU1dVQ=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "OAK",
                "duration": "28h40m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "U2",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LON",
                    "cityFrom": "Barcelona",
                    "cityTo": "London",
                    "combination_id": "01af22f54e2b00004d69d883",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 7196,
                    "flyFrom": "BCN",
                    "flyTo": "LGW",
                    "guarantee": true,
                    "id": "01af22f54e2b00004d69d883_0",
                    "local_arrival": "2024-10-15T08:35:00.000Z",
                    "local_departure": "2024-10-15T07:10:00.000Z",
                    "operating_carrier": "EC",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T07:35:00.000Z",
                    "utc_departure": "2024-10-15T05:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "Z0",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LON",
                    "cityCodeTo": "LAS",
                    "cityFrom": "London",
                    "cityTo": "Las Vegas",
                    "combination_id": "22f524464e2b00002e22373c",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 777,
                    "flyFrom": "LGW",
                    "flyTo": "LAS",
                    "guarantee": true,
                    "id": "22f524464e2b00002e22373c_0",
                    "local_arrival": "2024-10-15T14:40:00.000Z",
                    "local_departure": "2024-10-15T12:00:00.000Z",
                    "operating_carrier": "",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:40:00.000Z",
                    "utc_departure": "2024-10-15T11:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "NK",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAS",
                    "cityCodeTo": "OAK",
                    "cityFrom": "Las Vegas",
                    "cityTo": "Oakland",
                    "combination_id": "244610a14e2b0000b0b6a3f4",
                    "equipment": null,
                    "fare_basis": "VNR",
                    "fare_category": "M",
                    "fare_classes": "V",
                    "flight_no": 2256,
                    "flyFrom": "LAS",
                    "flyTo": "OAK",
                    "guarantee": true,
                    "id": "244610a14e2b0000b0b6a3f4_0",
                    "local_arrival": "2024-10-15T18:35:00.000Z",
                    "local_departure": "2024-10-15T16:59:00.000Z",
                    "operating_carrier": "NK",
                    "operating_flight_no": "2256",
                    "return": 0,
                    "utc_arrival": "2024-10-16T01:35:00.000Z",
                    "utc_departure": "2024-10-15T23:59:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 3
              }
            ],
            "quality": 1136.01,
            "totalPrice": 971
          },
          {
            "allPassengerTotalPrice": 979,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gy1kwTvimevAsfCsDmdddgG5K5TlKHcVRm4NS92ZE00DeFL6VpKnaWshwAnMZnKHZNWhTyE0XPuVZkFTuc_8Et2sdrwQFtYVJfDVFnqEYNGj7IWqveHifYRW5nwxFD_EGSpOYAJFLnBklrzM7JRP8EHOnsiAKBs_jV6wFnFIB8crpCokg3905gEK5esb5_6LXVnVC5urxZ0s8KQQ9NK_5TaELgf9IRKjVN4alQNvgOeTknVAXJw0tAqN1FOVZWY2q8le1GmEOO0Qwm_WHm_h6GjTfXW1QhUtCahsLSdY7jwYWx7FRqlJRtcXKU_ff50uY6RiHalg5gHtQELfkxP3w8Mvvxm13dxp8Y_gsi_3ysvvU_snZL6y3qz9NFmR4Y-qXWBNOamzuPs_o8zuxvNyONgKkoKPUZbaHJrN84YEbcmrRcWY5IVnBaVYcdBfWoIapgn5av9r74FIg_EBB2W26KPgpldplFlc2aBzdM4gj39PYmKXe8RAq0YKxAqnZ0eJYVrACxJfvLeDIH-BpRFKjkQhArkjP9xHLaU6enQpIuCpPKTEUqWUkmx21jEJ0A7nGJlHoVnHDFuLBoGtyThmsp0zYb950ZlcH-hOko25xpUaUqZWgahjS1an-nrEkgTa_phRJpArkNmsY2PyzMoVyCSHvCh_u-DL8RogE1_TAMDfxkur-0czXP-9WFvW0_kKzFNQrqlCWCozjPVOlvkjXG9PtWVPiZegXy9L9FaOy8kIHrHWpuQW49REl0om7CxjHHr6E_WLNYELiu97tU_eyygpbeOtW83DDgdEC3X5TTdqvlYaSRF2uAr8nbQD2rVZtJb8XOEsDd79iJc-Jhoa92CFbh6cR7YJL_8fIgmZkT0g4NrCAcPBNoJ0undPQmkjxovkVRulLmR-9MK1NjGc4nmHttZ-yS8HwOBrrGN-8u34U3igJoPOuZcMmLSJ1NesqkWu5GEELpGGO8gtPgm_HzZBJVZdng8Srw616qg4mbw8NEMXCsgNilc-TPI8DGLe45pNNWEIyGLYe_FoXTG6-rUnoTl_SqoWbpzr-K0YLmhP1lTtGuvf5LBCvLgaGBTBn3fcvnYFyQkvl8jqKJ8hcB5S89p0HTzOTOMVUN3nT_Mw=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "I2"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "10:25",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gy1kwTvimevAsfCsDmdddgG5K5TlKHcVRm4NS92ZE00DeFL6VpKnaWshwAnMZnKHZNWhTyE0XPuVZkFTuc_8Et2sdrwQFtYVJfDVFnqEYNGj7IWqveHifYRW5nwxFD_EGSpOYAJFLnBklrzM7JRP8EHOnsiAKBs_jV6wFnFIB8crpCokg3905gEK5esb5_6LXVnVC5urxZ0s8KQQ9NK_5TaELgf9IRKjVN4alQNvgOeTknVAXJw0tAqN1FOVZWY2q8le1GmEOO0Qwm_WHm_h6GjTfXW1QhUtCahsLSdY7jwYWx7FRqlJRtcXKU_ff50uY6RiHalg5gHtQELfkxP3w8Mvvxm13dxp8Y_gsi_3ysvvU_snZL6y3qz9NFmR4Y-qXWBNOamzuPs_o8zuxvNyONgKkoKPUZbaHJrN84YEbcmrRcWY5IVnBaVYcdBfWoIapgn5av9r74FIg_EBB2W26KPgpldplFlc2aBzdM4gj39PYmKXe8RAq0YKxAqnZ0eJYVrACxJfvLeDIH-BpRFKjkQhArkjP9xHLaU6enQpIuCpPKTEUqWUkmx21jEJ0A7nGJlHoVnHDFuLBoGtyThmsp0zYb950ZlcH-hOko25xpUaUqZWgahjS1an-nrEkgTa_phRJpArkNmsY2PyzMoVyCSHvCh_u-DL8RogE1_TAMDfxkur-0czXP-9WFvW0_kKzFNQrqlCWCozjPVOlvkjXG9PtWVPiZegXy9L9FaOy8kIHrHWpuQW49REl0om7CxjHHr6E_WLNYELiu97tU_eyygpbeOtW83DDgdEC3X5TTdqvlYaSRF2uAr8nbQD2rVZtJb8XOEsDd79iJc-Jhoa92CFbh6cR7YJL_8fIgmZkT0g4NrCAcPBNoJ0undPQmkjxovkVRulLmR-9MK1NjGc4nmHttZ-yS8HwOBrrGN-8u34U3igJoPOuZcMmLSJ1NesqkWu5GEELpGGO8gtPgm_HzZBJVZdng8Srw616qg4mbw8NEMXCsgNilc-TPI8DGLe45pNNWEIyGLYe_FoXTG6-rUnoTl_SqoWbpzr-K0YLmhP1lTtGuvf5LBCvLgaGBTBn3fcvnYFyQkvl8jqKJ8hcB5S89p0HTzOTOMVUN3nT_Mw=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "09:10",
                "destination": "MAD",
                "duration": "1h15m",
                "routes": [
                  {
                    "airline": "I2",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e24000050c242dc",
                    "equipment": null,
                    "fare_basis": "",
                    "fare_category": "M",
                    "fare_classes": "",
                    "flight_no": 3865,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e24000050c242dc_0",
                    "local_arrival": "2024-10-08T10:25:00.000Z",
                    "local_departure": "2024-10-08T09:10:00.000Z",
                    "operating_carrier": "I2",
                    "operating_flight_no": "3865",
                    "return": 0,
                    "utc_arrival": "2024-10-08T08:25:00.000Z",
                    "utc_departure": "2024-10-08T07:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e24000050c242dc_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=Gy1kwTvimevAsfCsDmdddgG5K5TlKHcVRm4NS92ZE00DeFL6VpKnaWshwAnMZnKHZNWhTyE0XPuVZkFTuc_8Et2sdrwQFtYVJfDVFnqEYNGj7IWqveHifYRW5nwxFD_EGSpOYAJFLnBklrzM7JRP8EHOnsiAKBs_jV6wFnFIB8crpCokg3905gEK5esb5_6LXVnVC5urxZ0s8KQQ9NK_5TaELgf9IRKjVN4alQNvgOeTknVAXJw0tAqN1FOVZWY2q8le1GmEOO0Qwm_WHm_h6GjTfXW1QhUtCahsLSdY7jwYWx7FRqlJRtcXKU_ff50uY6RiHalg5gHtQELfkxP3w8Mvvxm13dxp8Y_gsi_3ysvvU_snZL6y3qz9NFmR4Y-qXWBNOamzuPs_o8zuxvNyONgKkoKPUZbaHJrN84YEbcmrRcWY5IVnBaVYcdBfWoIapgn5av9r74FIg_EBB2W26KPgpldplFlc2aBzdM4gj39PYmKXe8RAq0YKxAqnZ0eJYVrACxJfvLeDIH-BpRFKjkQhArkjP9xHLaU6enQpIuCpPKTEUqWUkmx21jEJ0A7nGJlHoVnHDFuLBoGtyThmsp0zYb950ZlcH-hOko25xpUaUqZWgahjS1an-nrEkgTa_phRJpArkNmsY2PyzMoVyCSHvCh_u-DL8RogE1_TAMDfxkur-0czXP-9WFvW0_kKzFNQrqlCWCozjPVOlvkjXG9PtWVPiZegXy9L9FaOy8kIHrHWpuQW49REl0om7CxjHHr6E_WLNYELiu97tU_eyygpbeOtW83DDgdEC3X5TTdqvlYaSRF2uAr8nbQD2rVZtJb8XOEsDd79iJc-Jhoa92CFbh6cR7YJL_8fIgmZkT0g4NrCAcPBNoJ0undPQmkjxovkVRulLmR-9MK1NjGc4nmHttZ-yS8HwOBrrGN-8u34U3igJoPOuZcMmLSJ1NesqkWu5GEELpGGO8gtPgm_HzZBJVZdng8Srw616qg4mbw8NEMXCsgNilc-TPI8DGLe45pNNWEIyGLYe_FoXTG6-rUnoTl_SqoWbpzr-K0YLmhP1lTtGuvf5LBCvLgaGBTBn3fcvnYFyQkvl8jqKJ8hcB5S89p0HTzOTOMVUN3nT_Mw=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1106.25,
            "totalPrice": 979
          },
          {
            "allPassengerTotalPrice": 981,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GOFue-BfY2DeljboMOGCtTb06ckozG_U7VAMrlD6054aKTnONE7V1aQsF1x0Dak_nHkS0g0r4LmSqkstzkX0B2aSp1OEPWd7U-0YhB-WcNiKVi2Wmrv-cbUc62Iwgkv6Gt-5c6L5HfKbPNv3IbqfqfPSLf5H-8pjyd5UYSz3T8VpfXrR47OvP8acdpSZIuNcZ035MKWLUFD8TKvtLtPCy2r-qlA3DINcgiWcRGiaVxchKMwUmxwR72mb1MwnLkS4VfS4Ca6QaYpH-tNANBN4JuBsOKwZumvxqZfdnRY4X_lBVWaNGFi3BbqQLjmNTSywMMU1Lb8s-gQKrjERqfA7CGLQxfUKh-f-nSw-i0kKuKCRUTsKMAllLoeYoKzu2bEEifxoBeULXk8Zy6ldUL-nshcgiU6DHxquC3fcSrNjY9n2WeMYW5v1UBXUR6Ww677-ktuPa_uhwdduQ5F3ByGXpCV4f723mzqHxiMKor_KmdeaBQE0Fo0FWx_iA0KqS2AmLuPd4u2vvrvS9QAqMtu0inns7MBKczDbPmx2vdh8awN1EvNRB4JCz9miZeXv73G-o-lEeOrv8UkqglUE1bTkG-mwhHzaY_LvhCdCbSuBU8LlTmWtRYbfhKutQ0TnHZJ5ZnMg9MFPEstoWMngrSvFuTKYfWLmXybY_4403P5wIFV-YEPU3wTYdFKIQ4kC1cd6ErFa0WlIU-ReA62Bfvm_sQWFsEMq0Rp5aohebct7YnGsuB1JksZBCqdLdYf7qy4wfdKqOH5RRzzDMcDaF_gmSrrEOpMuMIS78zqgEZApj2TzIjnO6G--lYjNMt62zWY1BhkBmQO5gdtZcM-50e9S5HnxTVpEH6D4JzqKij09EJjGLlFuS6S_dLKyqD3nLS362y4lgl47gws3ljj1lPS3taEz54_-w7MKsGnUUA2fpRQT4khYvS0WAF7nOpu39v7WuMhhSueUtHMeT_C1MZEk-e7-Ai-YRAVdJgxuUFhmpqx_gafA4Ij1Feh4rjaaACElSbR9c5dsmC-PpDNwqZUIu86y9rpRbT60P0TIfexf_b-h1Z9Ckapi1gYDyeV3FMIKuRXZZ92MjaT78HmQ87W_x4O-gWDLle26-A9Pcj2DRue2h34f6Ov8XTbP1FBoCKY2yA6MzOSBtM6PpAzKq2mBlr0co43JM8946XtNhtiqtev8tlwf95eSBnobHkuPWcpgyYt_I-4FYjzHhmf-EmQFjJnY5QiWNp2RGS5k2wQ_kJ2TEOTDjUtCx3_0qpeRFbIv1",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "UX"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "22:10",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GOFue-BfY2DeljboMOGCtTb06ckozG_U7VAMrlD6054aKTnONE7V1aQsF1x0Dak_nHkS0g0r4LmSqkstzkX0B2aSp1OEPWd7U-0YhB-WcNiKVi2Wmrv-cbUc62Iwgkv6Gt-5c6L5HfKbPNv3IbqfqfPSLf5H-8pjyd5UYSz3T8VpfXrR47OvP8acdpSZIuNcZ035MKWLUFD8TKvtLtPCy2r-qlA3DINcgiWcRGiaVxchKMwUmxwR72mb1MwnLkS4VfS4Ca6QaYpH-tNANBN4JuBsOKwZumvxqZfdnRY4X_lBVWaNGFi3BbqQLjmNTSywMMU1Lb8s-gQKrjERqfA7CGLQxfUKh-f-nSw-i0kKuKCRUTsKMAllLoeYoKzu2bEEifxoBeULXk8Zy6ldUL-nshcgiU6DHxquC3fcSrNjY9n2WeMYW5v1UBXUR6Ww677-ktuPa_uhwdduQ5F3ByGXpCV4f723mzqHxiMKor_KmdeaBQE0Fo0FWx_iA0KqS2AmLuPd4u2vvrvS9QAqMtu0inns7MBKczDbPmx2vdh8awN1EvNRB4JCz9miZeXv73G-o-lEeOrv8UkqglUE1bTkG-mwhHzaY_LvhCdCbSuBU8LlTmWtRYbfhKutQ0TnHZJ5ZnMg9MFPEstoWMngrSvFuTKYfWLmXybY_4403P5wIFV-YEPU3wTYdFKIQ4kC1cd6ErFa0WlIU-ReA62Bfvm_sQWFsEMq0Rp5aohebct7YnGsuB1JksZBCqdLdYf7qy4wfdKqOH5RRzzDMcDaF_gmSrrEOpMuMIS78zqgEZApj2TzIjnO6G--lYjNMt62zWY1BhkBmQO5gdtZcM-50e9S5HnxTVpEH6D4JzqKij09EJjGLlFuS6S_dLKyqD3nLS362y4lgl47gws3ljj1lPS3taEz54_-w7MKsGnUUA2fpRQT4khYvS0WAF7nOpu39v7WuMhhSueUtHMeT_C1MZEk-e7-Ai-YRAVdJgxuUFhmpqx_gafA4Ij1Feh4rjaaACElSbR9c5dsmC-PpDNwqZUIu86y9rpRbT60P0TIfexf_b-h1Z9Ckapi1gYDyeV3FMIKuRXZZ92MjaT78HmQ87W_x4O-gWDLle26-A9Pcj2DRue2h34f6Ov8XTbP1FBoCKY2yA6MzOSBtM6PpAzKq2mBlr0co43JM8946XtNhtiqtev8tlwf95eSBnobHkuPWcpgyYt_I-4FYjzHhmf-EmQFjJnY5QiWNp2RGS5k2wQ_kJ2TEOTDjUtCx3_0qpeRFbIv1",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "17:45",
                "destination": "MAD",
                "duration": "4h25m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "BIO",
                    "cityFrom": "Málaga",
                    "cityTo": "Bilbao",
                    "combination_id": "14140bce4e240000a74c7035",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2610,
                    "flyFrom": "AGP",
                    "flyTo": "BIO",
                    "guarantee": false,
                    "id": "14140bce4e240000a74c7035_0",
                    "local_arrival": "2024-10-08T19:20:00.000Z",
                    "local_departure": "2024-10-08T17:45:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2610",
                    "return": 0,
                    "utc_arrival": "2024-10-08T17:20:00.000Z",
                    "utc_departure": "2024-10-08T15:45:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "UX",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BIO",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Bilbao",
                    "cityTo": "Madrid",
                    "combination_id": "0bce0a224e2400000210c421",
                    "equipment": null,
                    "fare_basis": "ZDYO4L",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 7160,
                    "flyFrom": "BIO",
                    "flyTo": "MAD",
                    "guarantee": true,
                    "id": "0bce0a224e2400000210c421_0",
                    "local_arrival": "2024-10-08T22:10:00.000Z",
                    "local_departure": "2024-10-08T21:05:00.000Z",
                    "operating_carrier": "UX",
                    "operating_flight_no": "7160",
                    "return": 0,
                    "utc_arrival": "2024-10-08T20:10:00.000Z",
                    "utc_departure": "2024-10-08T19:05:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "AGP",
                "stops": 1
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140bce4e240000a74c7035_0%7C0bce0a224e2400000210c421_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GOFue-BfY2DeljboMOGCtTb06ckozG_U7VAMrlD6054aKTnONE7V1aQsF1x0Dak_nHkS0g0r4LmSqkstzkX0B2aSp1OEPWd7U-0YhB-WcNiKVi2Wmrv-cbUc62Iwgkv6Gt-5c6L5HfKbPNv3IbqfqfPSLf5H-8pjyd5UYSz3T8VpfXrR47OvP8acdpSZIuNcZ035MKWLUFD8TKvtLtPCy2r-qlA3DINcgiWcRGiaVxchKMwUmxwR72mb1MwnLkS4VfS4Ca6QaYpH-tNANBN4JuBsOKwZumvxqZfdnRY4X_lBVWaNGFi3BbqQLjmNTSywMMU1Lb8s-gQKrjERqfA7CGLQxfUKh-f-nSw-i0kKuKCRUTsKMAllLoeYoKzu2bEEifxoBeULXk8Zy6ldUL-nshcgiU6DHxquC3fcSrNjY9n2WeMYW5v1UBXUR6Ww677-ktuPa_uhwdduQ5F3ByGXpCV4f723mzqHxiMKor_KmdeaBQE0Fo0FWx_iA0KqS2AmLuPd4u2vvrvS9QAqMtu0inns7MBKczDbPmx2vdh8awN1EvNRB4JCz9miZeXv73G-o-lEeOrv8UkqglUE1bTkG-mwhHzaY_LvhCdCbSuBU8LlTmWtRYbfhKutQ0TnHZJ5ZnMg9MFPEstoWMngrSvFuTKYfWLmXybY_4403P5wIFV-YEPU3wTYdFKIQ4kC1cd6ErFa0WlIU-ReA62Bfvm_sQWFsEMq0Rp5aohebct7YnGsuB1JksZBCqdLdYf7qy4wfdKqOH5RRzzDMcDaF_gmSrrEOpMuMIS78zqgEZApj2TzIjnO6G--lYjNMt62zWY1BhkBmQO5gdtZcM-50e9S5HnxTVpEH6D4JzqKij09EJjGLlFuS6S_dLKyqD3nLS362y4lgl47gws3ljj1lPS3taEz54_-w7MKsGnUUA2fpRQT4khYvS0WAF7nOpu39v7WuMhhSueUtHMeT_C1MZEk-e7-Ai-YRAVdJgxuUFhmpqx_gafA4Ij1Feh4rjaaACElSbR9c5dsmC-PpDNwqZUIu86y9rpRbT60P0TIfexf_b-h1Z9Ckapi1gYDyeV3FMIKuRXZZ92MjaT78HmQ87W_x4O-gWDLle26-A9Pcj2DRue2h34f6Ov8XTbP1FBoCKY2yA6MzOSBtM6PpAzKq2mBlr0co43JM8946XtNhtiqtev8tlwf95eSBnobHkuPWcpgyYt_I-4FYjzHhmf-EmQFjJnY5QiWNp2RGS5k2wQ_kJ2TEOTDjUtCx3_0qpeRFbIv1",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1126.83,
            "totalPrice": 981
          },
          {
            "allPassengerTotalPrice": 985,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLR_DZHvOq8CeTM1VmPKHzU6DA5HpmgXWg8EP3WRJ41_Gw__cM0vcieLJqgBb8TVslLQDNrDCWGNTWug218M-EMnQA-rJK9szL7n3WaVQtYr6k8mj7Q7xP6YIeLRWTWnnlJXyuBSzfJxLifACoA-UBmNIZKFBg9dHvEqINmtB7gUwbUliS5D9ZzD-BsDu53lZ8QYykFhsxZKw-htwtJCdr-UuveYQg92r6X37ljiY7k7I-edls8ul9-inlvTLjXJ0iFG-xGra1uDIYcSkgSZ8Kc7g6M2l_jZiVJaP3gdDvQhPQXJZckYww32br_-TjV_M7WDkUmn7FhcxEwjVYveRlo8ep4mNNxJaLMg9AcSPUGllIQhXbcieeiZUAlrVDvMNje2eGgbzn0YQnqnwpEAKRdg4gLNPKilupr-6HpQfOU1uT3qJf-Taoja0uS9uYs-2Ma9ZrtKR1E2Ab0ZBkHF0-RQtWaA3gPoxMLWopWUanY7KQCyhQub55-mBeM6Rtje1LMEr5r4lAf4eaSKH9pFzAOzn_kTI8qzSxbZedORQ7s9cwV09z7OgZYrR_k8oCvIBvz0mHkieiopIjBwofe5xjSD9hA7KYyQDtGjdkRRGCLur6B5RHoOdZSFMxB1sGf0uD0vblXx_x_epcom3BSKxr6p2NpkSHDn0rK6118pSFBi91bb7IjQA9Gt1YZxacTtiu9Q9KcWKfxp8lfUzyMlY9WK2kfam1mQ206eIRbnZjvLgNqaXJrhV6f8ZoXrW8ihoQdgKk7Uz3p7r1H4ycL6T_tc6Cs3yyBWPAKC0Jmdk9CvtwcM7-my8nNH2G80pLY0cnRSFjjRC7jjE1V2QKH1q3QzgH60t4momfkRnhacgyWTsgB4QK2gHvSPap1Gg8ZDisbzyoCro51zG5xO_DJm3kfxXw-e9WlCdwN4waMGK4PnarKbxHTD2d27Wgmal_AfNHbm1-qBcN6pZZGeUJPRFVVipb9U8BTMEs-y6nf7zuJ2Z5D-96-2iGputrG_yo3yL16MiXZP0TU_SP_IOrebI4Fw1GBZwyLeRCcomofrf7tqC98VfOdv0zGNmbPxwZHhm3PjpfwzPeeVo-joJa3mIoLGsttNfnyLSW8-DsIa-L2Mnr0X_SWQMwu4mRN2zDoAt-rIClqlPqmoI90JNdHEodNyADaP94sbUUVv7RbQZPfU=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLR_DZHvOq8CeTM1VmPKHzU6DA5HpmgXWg8EP3WRJ41_Gw__cM0vcieLJqgBb8TVslLQDNrDCWGNTWug218M-EMnQA-rJK9szL7n3WaVQtYr6k8mj7Q7xP6YIeLRWTWnnlJXyuBSzfJxLifACoA-UBmNIZKFBg9dHvEqINmtB7gUwbUliS5D9ZzD-BsDu53lZ8QYykFhsxZKw-htwtJCdr-UuveYQg92r6X37ljiY7k7I-edls8ul9-inlvTLjXJ0iFG-xGra1uDIYcSkgSZ8Kc7g6M2l_jZiVJaP3gdDvQhPQXJZckYww32br_-TjV_M7WDkUmn7FhcxEwjVYveRlo8ep4mNNxJaLMg9AcSPUGllIQhXbcieeiZUAlrVDvMNje2eGgbzn0YQnqnwpEAKRdg4gLNPKilupr-6HpQfOU1uT3qJf-Taoja0uS9uYs-2Ma9ZrtKR1E2Ab0ZBkHF0-RQtWaA3gPoxMLWopWUanY7KQCyhQub55-mBeM6Rtje1LMEr5r4lAf4eaSKH9pFzAOzn_kTI8qzSxbZedORQ7s9cwV09z7OgZYrR_k8oCvIBvz0mHkieiopIjBwofe5xjSD9hA7KYyQDtGjdkRRGCLur6B5RHoOdZSFMxB1sGf0uD0vblXx_x_epcom3BSKxr6p2NpkSHDn0rK6118pSFBi91bb7IjQA9Gt1YZxacTtiu9Q9KcWKfxp8lfUzyMlY9WK2kfam1mQ206eIRbnZjvLgNqaXJrhV6f8ZoXrW8ihoQdgKk7Uz3p7r1H4ycL6T_tc6Cs3yyBWPAKC0Jmdk9CvtwcM7-my8nNH2G80pLY0cnRSFjjRC7jjE1V2QKH1q3QzgH60t4momfkRnhacgyWTsgB4QK2gHvSPap1Gg8ZDisbzyoCro51zG5xO_DJm3kfxXw-e9WlCdwN4waMGK4PnarKbxHTD2d27Wgmal_AfNHbm1-qBcN6pZZGeUJPRFVVipb9U8BTMEs-y6nf7zuJ2Z5D-96-2iGputrG_yo3yL16MiXZP0TU_SP_IOrebI4Fw1GBZwyLeRCcomofrf7tqC98VfOdv0zGNmbPxwZHhm3PjpfwzPeeVo-joJa3mIoLGsttNfnyLSW8-DsIa-L2Mnr0X_SWQMwu4mRN2zDoAt-rIClqlPqmoI90JNdHEodNyADaP94sbUUVv7RbQZPfU=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00002a95deb1_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GLR_DZHvOq8CeTM1VmPKHzU6DA5HpmgXWg8EP3WRJ41_Gw__cM0vcieLJqgBb8TVslLQDNrDCWGNTWug218M-EMnQA-rJK9szL7n3WaVQtYr6k8mj7Q7xP6YIeLRWTWnnlJXyuBSzfJxLifACoA-UBmNIZKFBg9dHvEqINmtB7gUwbUliS5D9ZzD-BsDu53lZ8QYykFhsxZKw-htwtJCdr-UuveYQg92r6X37ljiY7k7I-edls8ul9-inlvTLjXJ0iFG-xGra1uDIYcSkgSZ8Kc7g6M2l_jZiVJaP3gdDvQhPQXJZckYww32br_-TjV_M7WDkUmn7FhcxEwjVYveRlo8ep4mNNxJaLMg9AcSPUGllIQhXbcieeiZUAlrVDvMNje2eGgbzn0YQnqnwpEAKRdg4gLNPKilupr-6HpQfOU1uT3qJf-Taoja0uS9uYs-2Ma9ZrtKR1E2Ab0ZBkHF0-RQtWaA3gPoxMLWopWUanY7KQCyhQub55-mBeM6Rtje1LMEr5r4lAf4eaSKH9pFzAOzn_kTI8qzSxbZedORQ7s9cwV09z7OgZYrR_k8oCvIBvz0mHkieiopIjBwofe5xjSD9hA7KYyQDtGjdkRRGCLur6B5RHoOdZSFMxB1sGf0uD0vblXx_x_epcom3BSKxr6p2NpkSHDn0rK6118pSFBi91bb7IjQA9Gt1YZxacTtiu9Q9KcWKfxp8lfUzyMlY9WK2kfam1mQ206eIRbnZjvLgNqaXJrhV6f8ZoXrW8ihoQdgKk7Uz3p7r1H4ycL6T_tc6Cs3yyBWPAKC0Jmdk9CvtwcM7-my8nNH2G80pLY0cnRSFjjRC7jjE1V2QKH1q3QzgH60t4momfkRnhacgyWTsgB4QK2gHvSPap1Gg8ZDisbzyoCro51zG5xO_DJm3kfxXw-e9WlCdwN4waMGK4PnarKbxHTD2d27Wgmal_AfNHbm1-qBcN6pZZGeUJPRFVVipb9U8BTMEs-y6nf7zuJ2Z5D-96-2iGputrG_yo3yL16MiXZP0TU_SP_IOrebI4Fw1GBZwyLeRCcomofrf7tqC98VfOdv0zGNmbPxwZHhm3PjpfwzPeeVo-joJa3mIoLGsttNfnyLSW8-DsIa-L2Mnr0X_SWQMwu4mRN2zDoAt-rIClqlPqmoI90JNdHEodNyADaP94sbUUVv7RbQZPfU=",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "07:40",
                "destination": "SFO",
                "duration": "25h3m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00002a95deb1",
                    "equipment": null,
                    "fare_basis": "JOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "J",
                    "flight_no": 2224,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00002a95deb1_0",
                    "local_arrival": "2024-10-14T09:20:00.000Z",
                    "local_departure": "2024-10-14T07:40:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2224",
                    "return": 0,
                    "utc_arrival": "2024-10-14T07:20:00.000Z",
                    "utc_departure": "2024-10-14T05:40:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1118.76,
            "totalPrice": 985
          },
          {
            "allPassengerTotalPrice": 1000,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhXzyc3Tly5e93D-cHGE8yFIqTGeFfygeWY1ERiQiigPrWJxMmUpi9FyEdpo7sDiUhHQeZH5XRuVqjWCSmFy02lJ-AksNEE3e1KAf2GOXXqY22GNAiUfGuFGQb7gfUZVwlxuluPrc6LGnJv6jAPrA8lTGSiUkHKLLe-7MdmrLwrBjKlFVfTtQ3905-5fRbjbswzgHsod98wtcQXnOjphgGwLeNNotXWSa_9kgRhE4kprm5HpOsT4pyGvTIAVG18Mmo4_hqdPebYLHZCMefludnTSYaE-UbObBIGUOJ7xT9pMld3MLvOBadn5wNUtuQ-p8iiQ7xJa5RrjTBnAPbRnYGjSvMFbZqUUiciSxvVcSYt3y7TfA8KifVUl6Oen0dVXaT0pevJG0h6yShBob2ylij-fMlvtevPqXwAr-y2Ke6iLGwcwo-FKIhEDU_vkPgKK7fyQ1mZ78iFqxJ3C_N5TncFaYhfS5lIPOevD3dMlQY4Mnid7qZWIG-c9q4K23CsIn_EbazA2uHFiurH5-hBHgv_JqxcC4niIt1z91ReNkiFiiJoJ9A3m97yjT-VCXt7E1dBPFUiWRi9uO9TsYluVHrgCNvIXlw9Q0aDO1mL3IUFh6-VL9aF_Oz5zs0pdkcNsfp2T2AhRL8bHazRWK4rkV4_qiofLP-sOev_hIfHcAoec4wFQK538FpHlB-SCvFxuNRIzVnHoVXlL43Cl3MYglmbBqf-uGQwG9iw_5jWJ8Ve9DpJs1qJoHPDv2C7h1BcStxSyi8aN0UJPKjsa53IeUbrndBkV-fAjjvWGlQzqyjBgATQ8jxdWmG41O5lummTYjY0FWCb9VW3awCEOLM_iGxjMKSyHFUO3ZljVWY6gflGnR_fGqOP5EXz0vC_fMz0gfgNQVeU1qE_iMXmg45HwaRjQjI9CgFVMf-haUuCH-mGjG4xWRqwpHZc59HULqqW0-Pxx9hcG35cScT2cpbJdg7BTDCF_3JSkuXFBmMee3P0-7IR9Isx2Mv5dUQBQ14jgdJmK6ENQpkD-BvjsdCP38LyCAaXHAbsuFxeumGEfFiiQn_oVBS1D88mBZSOR6ypeR8m8KUW7e19V_v7V0JERUsRKnFXu9yUEteLx3QIuAb4h0bIuUqdMveEPl83BbUun-8WM79QyKTLBE_cg-jSr9296FqL708F2kvJg8frmzRHQ=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhXzyc3Tly5e93D-cHGE8yFIqTGeFfygeWY1ERiQiigPrWJxMmUpi9FyEdpo7sDiUhHQeZH5XRuVqjWCSmFy02lJ-AksNEE3e1KAf2GOXXqY22GNAiUfGuFGQb7gfUZVwlxuluPrc6LGnJv6jAPrA8lTGSiUkHKLLe-7MdmrLwrBjKlFVfTtQ3905-5fRbjbswzgHsod98wtcQXnOjphgGwLeNNotXWSa_9kgRhE4kprm5HpOsT4pyGvTIAVG18Mmo4_hqdPebYLHZCMefludnTSYaE-UbObBIGUOJ7xT9pMld3MLvOBadn5wNUtuQ-p8iiQ7xJa5RrjTBnAPbRnYGjSvMFbZqUUiciSxvVcSYt3y7TfA8KifVUl6Oen0dVXaT0pevJG0h6yShBob2ylij-fMlvtevPqXwAr-y2Ke6iLGwcwo-FKIhEDU_vkPgKK7fyQ1mZ78iFqxJ3C_N5TncFaYhfS5lIPOevD3dMlQY4Mnid7qZWIG-c9q4K23CsIn_EbazA2uHFiurH5-hBHgv_JqxcC4niIt1z91ReNkiFiiJoJ9A3m97yjT-VCXt7E1dBPFUiWRi9uO9TsYluVHrgCNvIXlw9Q0aDO1mL3IUFh6-VL9aF_Oz5zs0pdkcNsfp2T2AhRL8bHazRWK4rkV4_qiofLP-sOev_hIfHcAoec4wFQK538FpHlB-SCvFxuNRIzVnHoVXlL43Cl3MYglmbBqf-uGQwG9iw_5jWJ8Ve9DpJs1qJoHPDv2C7h1BcStxSyi8aN0UJPKjsa53IeUbrndBkV-fAjjvWGlQzqyjBgATQ8jxdWmG41O5lummTYjY0FWCb9VW3awCEOLM_iGxjMKSyHFUO3ZljVWY6gflGnR_fGqOP5EXz0vC_fMz0gfgNQVeU1qE_iMXmg45HwaRjQjI9CgFVMf-haUuCH-mGjG4xWRqwpHZc59HULqqW0-Pxx9hcG35cScT2cpbJdg7BTDCF_3JSkuXFBmMee3P0-7IR9Isx2Mv5dUQBQ14jgdJmK6ENQpkD-BvjsdCP38LyCAaXHAbsuFxeumGEfFiiQn_oVBS1D88mBZSOR6ypeR8m8KUW7e19V_v7V0JERUsRKnFXu9yUEteLx3QIuAb4h0bIuUqdMveEPl83BbUun-8WM79QyKTLBE_cg-jSr9296FqL708F2kvJg8frmzRHQ=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "LEVELSPAIN",
                  "AS"
                ],
                "arrivalDate": "2024-10-14",
                "arrivalTime": "23:43",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a000071d279fd_0%7C01af243e4e2a000029ede126_0%7C243e0f9c4e2a0000caf75682_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GhXzyc3Tly5e93D-cHGE8yFIqTGeFfygeWY1ERiQiigPrWJxMmUpi9FyEdpo7sDiUhHQeZH5XRuVqjWCSmFy02lJ-AksNEE3e1KAf2GOXXqY22GNAiUfGuFGQb7gfUZVwlxuluPrc6LGnJv6jAPrA8lTGSiUkHKLLe-7MdmrLwrBjKlFVfTtQ3905-5fRbjbswzgHsod98wtcQXnOjphgGwLeNNotXWSa_9kgRhE4kprm5HpOsT4pyGvTIAVG18Mmo4_hqdPebYLHZCMefludnTSYaE-UbObBIGUOJ7xT9pMld3MLvOBadn5wNUtuQ-p8iiQ7xJa5RrjTBnAPbRnYGjSvMFbZqUUiciSxvVcSYt3y7TfA8KifVUl6Oen0dVXaT0pevJG0h6yShBob2ylij-fMlvtevPqXwAr-y2Ke6iLGwcwo-FKIhEDU_vkPgKK7fyQ1mZ78iFqxJ3C_N5TncFaYhfS5lIPOevD3dMlQY4Mnid7qZWIG-c9q4K23CsIn_EbazA2uHFiurH5-hBHgv_JqxcC4niIt1z91ReNkiFiiJoJ9A3m97yjT-VCXt7E1dBPFUiWRi9uO9TsYluVHrgCNvIXlw9Q0aDO1mL3IUFh6-VL9aF_Oz5zs0pdkcNsfp2T2AhRL8bHazRWK4rkV4_qiofLP-sOev_hIfHcAoec4wFQK538FpHlB-SCvFxuNRIzVnHoVXlL43Cl3MYglmbBqf-uGQwG9iw_5jWJ8Ve9DpJs1qJoHPDv2C7h1BcStxSyi8aN0UJPKjsa53IeUbrndBkV-fAjjvWGlQzqyjBgATQ8jxdWmG41O5lummTYjY0FWCb9VW3awCEOLM_iGxjMKSyHFUO3ZljVWY6gflGnR_fGqOP5EXz0vC_fMz0gfgNQVeU1qE_iMXmg45HwaRjQjI9CgFVMf-haUuCH-mGjG4xWRqwpHZc59HULqqW0-Pxx9hcG35cScT2cpbJdg7BTDCF_3JSkuXFBmMee3P0-7IR9Isx2Mv5dUQBQ14jgdJmK6ENQpkD-BvjsdCP38LyCAaXHAbsuFxeumGEfFiiQn_oVBS1D88mBZSOR6ypeR8m8KUW7e19V_v7V0JERUsRKnFXu9yUEteLx3QIuAb4h0bIuUqdMveEPl83BbUun-8WM79QyKTLBE_cg-jSr9296FqL708F2kvJg8frmzRHQ=",
                "dateChange": 0,
                "departureDate": "2024-10-14",
                "departureTime": "09:25",
                "destination": "SFO",
                "duration": "23h18m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a000071d279fd",
                    "equipment": null,
                    "fare_basis": "AOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "A",
                    "flight_no": 2211,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a000071d279fd_0",
                    "local_arrival": "2024-10-14T11:05:00.000Z",
                    "local_departure": "2024-10-14T09:25:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2211",
                    "return": 0,
                    "utc_arrival": "2024-10-14T09:05:00.000Z",
                    "utc_departure": "2024-10-14T07:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "VY",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LAX",
                    "cityFrom": "Barcelona",
                    "cityTo": "Los Angeles",
                    "combination_id": "01af243e4e2a000029ede126",
                    "equipment": null,
                    "fare_basis": "SIL2N7WN",
                    "fare_category": "M",
                    "fare_classes": "S",
                    "flight_no": 9793,
                    "flyFrom": "BCN",
                    "flyTo": "LAX",
                    "guarantee": true,
                    "id": "01af243e4e2a000029ede126_0",
                    "local_arrival": "2024-10-14T18:10:00.000Z",
                    "local_departure": "2024-10-14T14:00:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-10-15T01:10:00.000Z",
                    "utc_departure": "2024-10-14T12:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "AS",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "LAX",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Los Angeles",
                    "cityTo": "San Francisco",
                    "combination_id": "243e0f9c4e2a0000caf75682",
                    "equipment": null,
                    "fare_basis": "NH0OAVBN",
                    "fare_category": "M",
                    "fare_classes": "M",
                    "flight_no": 3386,
                    "flyFrom": "LAX",
                    "flyTo": "SFO",
                    "guarantee": true,
                    "id": "243e0f9c4e2a0000caf75682_0",
                    "local_arrival": "2024-10-14T23:43:00.000Z",
                    "local_departure": "2024-10-14T22:20:00.000Z",
                    "operating_carrier": "AS",
                    "operating_flight_no": "3386",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:43:00.000Z",
                    "utc_departure": "2024-10-15T05:20:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1116.76,
            "totalPrice": 1000
          },
          {
            "allPassengerTotalPrice": 1021,
            "legs": [
              {
                "airlines": [
                  "LEVELSPAIN"
                ],
                "arrivalDate": "2024-09-29",
                "arrivalTime": "17:00",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GXAkCMNW707263eAaiXRdeC40RuLvoSN0vStssqsVm9y7hWx2zYK5429iuI1iz3T1K7gh043RTKaJdZx99IRboATwTdT_5gaZKOr4JpBnZN6qQLmFVy4I0AHuFOld-iJQ74WEh3vuanv8C6X1nVarP5BSfTnKkwcnAVAVqL1air3dUDZ2edjLVE4QW2PPlMUTEWE3pr_z-AqCn14Z_I6MOc3ZLZuYf3AVqwW84cTBS4p4hTSnjEldik8PhFX_w3bSgte6v3E146uOMUL3u7-GJzxX29BOnqIbFJgnCZYIopZzz0LlbKxBtGMUuUkp-AxJ42UBxiHLtLaR2JMNL1UJFj1Q6XkhEP0WTk1uQWGIggQw6tOIRaShhk6uDBfm2owTIJe5ZTC-Lgx9glQH4h87Ttfi5iYyIOaRvF3EXu_noT8Fe1GgQXbCZI05uM4V-yh0BjhpPRG30bYLuQovL9scKLXmXSUqM-Ift7H9gHIhaXFqd48xh1FNdGFhpjS2qNxK2NkCGOwSqnD-OLFqMC-qZSb-zTHraEUPhuD9jA2SIVRY89utWL-EEWOKTxg-7LfwF-uSaeXdQo5q6b6QUfA0a915YOKhiwnJxsVYxHD2Mvdx7Gzz4E6SrdXjmlDjUMR1NI1Sib7eBT0DeVYyTXRDAm09GODCqIUPMYWVrq4mVQVa1roYXCEi4lhdH81kgfR5sdU_pnavcajdVaW2W3cXY6lyKK7wWrc3mA2WB7RTCqOIkOpzeb0YJ6XP3xjolVYx90lIlnewHljARIwx5fe4V3OibAa04P-Y-sGK4br2YWIdhHltniyVsbZgq_yn-yyrUhbJ_9xOCcqSnodjWeb3Vz8G4vE5J1hIz9LQYa0LrC3c2NbJxgwok7oLT4onFy6rqWzno6TEts5g7S7Ovx7YnEMPiC6QoAPKjaYpM3uNpVcKV-Y-0msXS2OybsfWqCK5BqLaAchz1bWJx-1X4WoB9_ICg59QJKk07a8ImPQikE0R4UhOxnQe18OtDi_3IjFymZEapVnh_zGTiQv-ktLF3BKI0QGG4ToN-5QBrhC234jo_hAwhxPv_5zcQQMdKOE_AoveYQTfXbQvrKSGfI9MF4OQBbbp7RoalS7UIEwBPSg=",
                "dateChange": 1,
                "departureDate": "2024-09-28",
                "departureTime": "20:10",
                "destination": "BCN",
                "duration": "11h50m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SFO",
                    "cityCodeTo": "BCN",
                    "cityFrom": "San Francisco",
                    "cityTo": "Barcelona",
                    "combination_id": "0f9c01af4e1a0000a9809026",
                    "equipment": null,
                    "fare_basis": "QIL2C3WN",
                    "fare_category": "M",
                    "fare_classes": "Q",
                    "flight_no": 9792,
                    "flyFrom": "SFO",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "0f9c01af4e1a0000a9809026_0",
                    "local_arrival": "2024-09-29T17:00:00.000Z",
                    "local_departure": "2024-09-28T20:10:00.000Z",
                    "operating_carrier": "LEVELSPAIN",
                    "operating_flight_no": "",
                    "return": 0,
                    "utc_arrival": "2024-09-29T15:00:00.000Z",
                    "utc_departure": "2024-09-29T03:10:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SFO",
                "stops": 0
              },
              {
                "airlines": [
                  "IB"
                ],
                "arrivalDate": "2024-10-08",
                "arrivalTime": "14:35",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GXAkCMNW707263eAaiXRdeC40RuLvoSN0vStssqsVm9y7hWx2zYK5429iuI1iz3T1K7gh043RTKaJdZx99IRboATwTdT_5gaZKOr4JpBnZN6qQLmFVy4I0AHuFOld-iJQ74WEh3vuanv8C6X1nVarP5BSfTnKkwcnAVAVqL1air3dUDZ2edjLVE4QW2PPlMUTEWE3pr_z-AqCn14Z_I6MOc3ZLZuYf3AVqwW84cTBS4p4hTSnjEldik8PhFX_w3bSgte6v3E146uOMUL3u7-GJzxX29BOnqIbFJgnCZYIopZzz0LlbKxBtGMUuUkp-AxJ42UBxiHLtLaR2JMNL1UJFj1Q6XkhEP0WTk1uQWGIggQw6tOIRaShhk6uDBfm2owTIJe5ZTC-Lgx9glQH4h87Ttfi5iYyIOaRvF3EXu_noT8Fe1GgQXbCZI05uM4V-yh0BjhpPRG30bYLuQovL9scKLXmXSUqM-Ift7H9gHIhaXFqd48xh1FNdGFhpjS2qNxK2NkCGOwSqnD-OLFqMC-qZSb-zTHraEUPhuD9jA2SIVRY89utWL-EEWOKTxg-7LfwF-uSaeXdQo5q6b6QUfA0a915YOKhiwnJxsVYxHD2Mvdx7Gzz4E6SrdXjmlDjUMR1NI1Sib7eBT0DeVYyTXRDAm09GODCqIUPMYWVrq4mVQVa1roYXCEi4lhdH81kgfR5sdU_pnavcajdVaW2W3cXY6lyKK7wWrc3mA2WB7RTCqOIkOpzeb0YJ6XP3xjolVYx90lIlnewHljARIwx5fe4V3OibAa04P-Y-sGK4br2YWIdhHltniyVsbZgq_yn-yyrUhbJ_9xOCcqSnodjWeb3Vz8G4vE5J1hIz9LQYa0LrC3c2NbJxgwok7oLT4onFy6rqWzno6TEts5g7S7Ovx7YnEMPiC6QoAPKjaYpM3uNpVcKV-Y-0msXS2OybsfWqCK5BqLaAchz1bWJx-1X4WoB9_ICg59QJKk07a8ImPQikE0R4UhOxnQe18OtDi_3IjFymZEapVnh_zGTiQv-ktLF3BKI0QGG4ToN-5QBrhC234jo_hAwhxPv_5zcQQMdKOE_AoveYQTfXbQvrKSGfI9MF4OQBbbp7RoalS7UIEwBPSg=",
                "dateChange": 0,
                "departureDate": "2024-10-08",
                "departureTime": "13:15",
                "destination": "MAD",
                "duration": "1h20m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "AGP",
                    "cityCodeTo": "MAD",
                    "cityFrom": "Málaga",
                    "cityTo": "Madrid",
                    "combination_id": "14140a224e240000209e1473",
                    "equipment": null,
                    "fare_basis": "PABCHJA",
                    "fare_category": "M",
                    "fare_classes": "P",
                    "flight_no": 5020,
                    "flyFrom": "AGP",
                    "flyTo": "MAD",
                    "guarantee": false,
                    "id": "14140a224e240000209e1473_0",
                    "local_arrival": "2024-10-08T14:35:00.000Z",
                    "local_departure": "2024-10-08T13:15:00.000Z",
                    "operating_carrier": "IB",
                    "operating_flight_no": "3871",
                    "return": 0,
                    "utc_arrival": "2024-10-08T12:35:00.000Z",
                    "utc_departure": "2024-10-08T11:15:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "AGP",
                "stops": 0
              },
              {
                "airlines": [
                  "VY",
                  "TP",
                  "TP"
                ],
                "arrivalDate": "2024-10-15",
                "arrivalTime": "14:05",
                "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000209e1473_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b000063aa3cc5_0%7C01af0f9c4e2b000063aa3cc5_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GXAkCMNW707263eAaiXRdeC40RuLvoSN0vStssqsVm9y7hWx2zYK5429iuI1iz3T1K7gh043RTKaJdZx99IRboATwTdT_5gaZKOr4JpBnZN6qQLmFVy4I0AHuFOld-iJQ74WEh3vuanv8C6X1nVarP5BSfTnKkwcnAVAVqL1air3dUDZ2edjLVE4QW2PPlMUTEWE3pr_z-AqCn14Z_I6MOc3ZLZuYf3AVqwW84cTBS4p4hTSnjEldik8PhFX_w3bSgte6v3E146uOMUL3u7-GJzxX29BOnqIbFJgnCZYIopZzz0LlbKxBtGMUuUkp-AxJ42UBxiHLtLaR2JMNL1UJFj1Q6XkhEP0WTk1uQWGIggQw6tOIRaShhk6uDBfm2owTIJe5ZTC-Lgx9glQH4h87Ttfi5iYyIOaRvF3EXu_noT8Fe1GgQXbCZI05uM4V-yh0BjhpPRG30bYLuQovL9scKLXmXSUqM-Ift7H9gHIhaXFqd48xh1FNdGFhpjS2qNxK2NkCGOwSqnD-OLFqMC-qZSb-zTHraEUPhuD9jA2SIVRY89utWL-EEWOKTxg-7LfwF-uSaeXdQo5q6b6QUfA0a915YOKhiwnJxsVYxHD2Mvdx7Gzz4E6SrdXjmlDjUMR1NI1Sib7eBT0DeVYyTXRDAm09GODCqIUPMYWVrq4mVQVa1roYXCEi4lhdH81kgfR5sdU_pnavcajdVaW2W3cXY6lyKK7wWrc3mA2WB7RTCqOIkOpzeb0YJ6XP3xjolVYx90lIlnewHljARIwx5fe4V3OibAa04P-Y-sGK4br2YWIdhHltniyVsbZgq_yn-yyrUhbJ_9xOCcqSnodjWeb3Vz8G4vE5J1hIz9LQYa0LrC3c2NbJxgwok7oLT4onFy6rqWzno6TEts5g7S7Ovx7YnEMPiC6QoAPKjaYpM3uNpVcKV-Y-0msXS2OybsfWqCK5BqLaAchz1bWJx-1X4WoB9_ICg59QJKk07a8ImPQikE0R4UhOxnQe18OtDi_3IjFymZEapVnh_zGTiQv-ktLF3BKI0QGG4ToN-5QBrhC234jo_hAwhxPv_5zcQQMdKOE_AoveYQTfXbQvrKSGfI9MF4OQBbbp7RoalS7UIEwBPSg=",
                "dateChange": 1,
                "departureDate": "2024-10-14",
                "departureTime": "22:55",
                "destination": "SFO",
                "duration": "24h10m",
                "routes": [
                  {
                    "airline": "VY",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "SVQ",
                    "cityCodeTo": "BCN",
                    "cityFrom": "Seville",
                    "cityTo": "Barcelona",
                    "combination_id": "04b801af4e2a00005aff2a3e",
                    "equipment": null,
                    "fare_basis": "OOWVYCLB",
                    "fare_category": "M",
                    "fare_classes": "O",
                    "flight_no": 2221,
                    "flyFrom": "SVQ",
                    "flyTo": "BCN",
                    "guarantee": false,
                    "id": "04b801af4e2a00005aff2a3e_0",
                    "local_arrival": "2024-10-15T00:35:00.000Z",
                    "local_departure": "2024-10-14T22:55:00.000Z",
                    "operating_carrier": "VY",
                    "operating_flight_no": "2221",
                    "return": 0,
                    "utc_arrival": "2024-10-14T22:35:00.000Z",
                    "utc_departure": "2024-10-14T20:55:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": true,
                    "cityCodeFrom": "BCN",
                    "cityCodeTo": "LIS",
                    "cityFrom": "Barcelona",
                    "cityTo": "Lisbon",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 1041,
                    "flyFrom": "BCN",
                    "flyTo": "LIS",
                    "guarantee": true,
                    "id": "01af0f9c4e2b000063aa3cc5_0",
                    "local_arrival": "2024-10-15T07:00:00.000Z",
                    "local_departure": "2024-10-15T06:00:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "1041",
                    "return": 0,
                    "utc_arrival": "2024-10-15T06:00:00.000Z",
                    "utc_departure": "2024-10-15T04:00:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": true
                  },
                  {
                    "airline": "TP",
                    "bags_recheck_required": false,
                    "cityCodeFrom": "LIS",
                    "cityCodeTo": "SFO",
                    "cityFrom": "Lisbon",
                    "cityTo": "San Francisco",
                    "combination_id": "01af0f9c4e2b000063aa3cc5",
                    "equipment": null,
                    "fare_basis": "KL0DSI05",
                    "fare_category": "M",
                    "fare_classes": "K",
                    "flight_no": 237,
                    "flyFrom": "LIS",
                    "flyTo": "SFO",
                    "guarantee": false,
                    "id": "01af0f9c4e2b000063aa3cc5_1",
                    "local_arrival": "2024-10-15T14:05:00.000Z",
                    "local_departure": "2024-10-15T09:25:00.000Z",
                    "operating_carrier": "TP",
                    "operating_flight_no": "237",
                    "return": 0,
                    "utc_arrival": "2024-10-15T21:05:00.000Z",
                    "utc_departure": "2024-10-15T08:25:00.000Z",
                    "vehicle_type": "aircraft",
                    "vi_connection": false
                  }
                ],
                "source": "SVQ",
                "stops": 2
              }
            ],
            "quality": 1140.23,
            "totalPrice": 1021
          }
        ]
      }
    ],
    "hotel_results": {
      "HOTEL_LISTS": [
        [
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 35.86
            },
            "dupeId": 700094622,
            "geoCode": {
              "latitude": 36.73964,
              "longitude": -5.16443
            },
            "hotelId": "HSRRAACE",
            "iataCode": "RRA",
            "lastUpdate": "2023-08-01T09:57:19",
            "name": "HOTEL RONDA",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 36.09
            },
            "dupeId": 700261872,
            "geoCode": {
              "latitude": 36.74165,
              "longitude": -5.16553
            },
            "hotelId": "HSRRAACI",
            "iataCode": "RRA",
            "lastUpdate": "2023-08-01T10:50:07",
            "name": "HOSTAL VIRGEN DEL ROCIO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 46.78
            },
            "dupeId": 700183255,
            "geoCode": {
              "latitude": 36.71866,
              "longitude": -4.4276
            },
            "hotelId": "RTAGPTRO",
            "iataCode": "AGP",
            "lastUpdate": "2024-03-28T01:52:24",
            "name": "IBIS BUDGET MALAGA CENTRO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "WV",
            "distance": {
              "unit": "KM",
              "value": 47.69
            },
            "dupeId": 700584788,
            "geoCode": {
              "latitude": 36.72129,
              "longitude": -4.41768
            },
            "hotelId": "WVAGP392",
            "iataCode": "AGP",
            "lastUpdate": "2023-08-01T10:52:59",
            "name": "SOHO BOUTIQUE MUSEO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 57.14
            },
            "dupeId": 700285231,
            "geoCode": {
              "latitude": 36.16051,
              "longitude": -5.34891
            },
            "hotelId": "HSAEIACF",
            "iataCode": "AEI",
            "lastUpdate": "2023-08-01T10:49:47",
            "name": "HOSTAL LA CAMPANA",
            "rating": 1
          }
        ],
        [
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "WV",
            "distance": {
              "unit": "KM",
              "value": 0.34
            },
            "dupeId": 700584788,
            "geoCode": {
              "latitude": 36.72129,
              "longitude": -4.41768
            },
            "hotelId": "WVAGP392",
            "iataCode": "AGP",
            "lastUpdate": "2023-08-01T10:52:59",
            "name": "SOHO BOUTIQUE MUSEO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 0.61
            },
            "dupeId": 700183255,
            "geoCode": {
              "latitude": 36.71866,
              "longitude": -4.4276
            },
            "hotelId": "RTAGPTRO",
            "iataCode": "AGP",
            "lastUpdate": "2024-03-28T01:52:24",
            "name": "IBIS BUDGET MALAGA CENTRO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 49.05
            },
            "dupeId": 700139319,
            "geoCode": {
              "latitude": 37.15509,
              "longitude": -4.32404
            },
            "hotelId": "HSGENAAH",
            "iataCode": "GEN",
            "lastUpdate": "2023-08-01T10:05:42",
            "name": "HOTEL RURAL LA PALOMA",
            "rating": 1
          }
        ],
        [
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 0.13
            },
            "dupeId": 700132410,
            "geoCode": {
              "latitude": 40.41603,
              "longitude": -3.70507
            },
            "hotelId": "HSMADBNE",
            "iataCode": "MAD",
            "lastUpdate": "2023-08-01T10:05:42",
            "name": "HOSTAL COMERCIAL",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 0.83
            },
            "dupeId": 700237543,
            "geoCode": {
              "latitude": 40.42329,
              "longitude": -3.69882
            },
            "hotelId": "HSMADAZW",
            "iataCode": "MAD",
            "lastUpdate": "2023-08-01T10:58:10",
            "name": "ROOM007 CHUECA HOSTAL",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 0.95
            },
            "dupeId": 700650158,
            "geoCode": {
              "latitude": 40.40851,
              "longitude": -3.70098
            },
            "hotelId": "RTMADLAV",
            "iataCode": "MAD",
            "lastUpdate": "2024-04-20T06:38:57",
            "name": "IBIS BUDGET MADRID LAVAPIES",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 1.06
            },
            "dupeId": 700221941,
            "geoCode": {
              "latitude": 40.41033,
              "longitude": -3.69462
            },
            "hotelId": "HSMADAMA",
            "iataCode": "MAD",
            "lastUpdate": "2023-08-01T10:42:17",
            "name": "URBAN SEA ATOCHA 113",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 1.09
            },
            "dupeId": 700166042,
            "geoCode": {
              "latitude": 40.41008,
              "longitude": -3.69438
            },
            "hotelId": "HSMADBBV",
            "iataCode": "MAD",
            "lastUpdate": "2023-08-01T10:16:01",
            "name": "HOSTAL SAN BLAS",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 3.64
            },
            "dupeId": 700318447,
            "geoCode": {
              "latitude": 40.39609,
              "longitude": -3.67054
            },
            "hotelId": "RTMADBUD",
            "iataCode": "MAD",
            "lastUpdate": "2024-05-14T09:04:08",
            "name": "IBIS BUDGET MADRID CALLE 30",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "UI",
            "distance": {
              "unit": "KM",
              "value": 3.98
            },
            "dupeId": 700027281,
            "geoCode": {
              "latitude": 40.44373,
              "longitude": -3.67282
            },
            "hotelId": "UIMAD047",
            "iataCode": "MAD",
            "lastUpdate": "2024-04-24T14:32:23",
            "name": "SERCOTEL TOGUMAR",
            "rating": 2
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 4.08
            },
            "dupeId": 700323123,
            "geoCode": {
              "latitude": 40.45353,
              "longitude": -3.70273
            },
            "hotelId": "HSMADBEL",
            "iataCode": "MAD",
            "lastUpdate": "2023-08-01T10:50:33",
            "name": "HOSTAL FALFES",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 4.39
            },
            "dupeId": 700267018,
            "geoCode": {
              "latitude": 40.43255,
              "longitude": -3.65624
            },
            "hotelId": "RTMADTAS",
            "iataCode": "MAD",
            "lastUpdate": "2024-04-11T06:33:24",
            "name": "IBIS BUDGET MADRID CENTRO LAS VENTAS",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "TG",
            "distance": {
              "unit": "KM",
              "value": 5.14
            },
            "dupeId": 700034115,
            "geoCode": {
              "latitude": 40.44433,
              "longitude": -3.65502
            },
            "hotelId": "TGMAD003",
            "iataCode": "MAD",
            "lastUpdate": "2024-01-13T10:00:05",
            "name": "TRAVELODGE MADRID TORRELAGUNA",
            "rating": 2
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "TG",
            "distance": {
              "unit": "KM",
              "value": 6.54
            },
            "dupeId": 700189965,
            "geoCode": {
              "latitude": 40.4369,
              "longitude": -3.63118
            },
            "hotelId": "TGMAD015",
            "iataCode": "MAD",
            "lastUpdate": "2024-05-14T09:04:05",
            "name": "TRAVELODGE MADRID ALCALA",
            "rating": 2
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 7.03
            },
            "dupeId": 700094554,
            "geoCode": {
              "latitude": 40.43934,
              "longitude": -3.62618
            },
            "hotelId": "RTMADVAL",
            "iataCode": "MAD",
            "lastUpdate": "2024-05-14T09:04:05",
            "name": "IBIS MADRID VALENTIN BEATO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 7.28
            },
            "dupeId": 700183254,
            "geoCode": {
              "latitude": 40.37231,
              "longitude": -3.64069
            },
            "hotelId": "RTMADCAS",
            "iataCode": "MAD",
            "lastUpdate": "2024-04-17T01:14:57",
            "name": "IBIS BUDGET MADRID VALLECAS",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "TG",
            "distance": {
              "unit": "KM",
              "value": 12.18
            },
            "dupeId": 700032961,
            "geoCode": {
              "latitude": 40.42805,
              "longitude": -3.56075
            },
            "hotelId": "TGMAD016",
            "iataCode": "MAD",
            "lastUpdate": "2024-01-13T10:46:48",
            "name": "TRAVELODGE MADRID COSLADA AEROPUERTO",
            "rating": 2
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 13.4
            },
            "dupeId": 529295301,
            "geoCode": {
              "latitude": 40.44717,
              "longitude": -3.55066
            },
            "hotelId": "RTMADIBM",
            "iataCode": "MAD",
            "lastUpdate": "2024-04-26T23:09:26",
            "name": "IBIS BUDGET MADRID AEROPUERTO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 15.64
            },
            "dupeId": 700219108,
            "geoCode": {
              "latitude": 40.2764,
              "longitude": -3.69523
            },
            "hotelId": "RTMADAFE",
            "iataCode": "MAD",
            "lastUpdate": "2024-04-17T01:15:05",
            "name": "IBIS BUDGET MADRID GETAFE",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 44.24
            },
            "dupeId": 700325907,
            "geoCode": {
              "latitude": 40.64645,
              "longitude": -4.1308
            },
            "hotelId": "HSXOUAAP",
            "iataCode": "XOU",
            "lastUpdate": "2023-08-01T10:50:21",
            "name": "HOSPEDERIA SANTA CRUZ",
            "rating": 1
          }
        ],
        [
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "KY",
            "distance": {
              "unit": "KM",
              "value": 0.47
            },
            "dupeId": 700057864,
            "geoCode": {
              "latitude": 37.39229,
              "longitude": -5.98808
            },
            "hotelId": "KYSVQMAE",
            "iataCode": "SVQ",
            "lastUpdate": "2024-04-22T11:48:20",
            "name": "DOMUS SELECTA LA CASA DEL MAESTRO",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 0.96
            },
            "dupeId": 700136854,
            "geoCode": {
              "latitude": 37.38641,
              "longitude": -5.99493
            },
            "hotelId": "HSSVQAAC",
            "iataCode": "SVQ",
            "lastUpdate": "2023-08-01T10:08:07",
            "name": "SIMON",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "AC",
            "distance": {
              "unit": "KM",
              "value": 0.99
            },
            "dupeId": 700131490,
            "geoCode": {
              "latitude": 37.38753,
              "longitude": -5.99553
            },
            "hotelId": "ACSVQN01",
            "iataCode": "SVQ",
            "lastUpdate": "2024-03-28T15:42:56",
            "name": "HOTEL EUROPA SEVILLA",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 1.42
            },
            "dupeId": 700163771,
            "geoCode": {
              "latitude": 37.39137,
              "longitude": -6.00038
            },
            "hotelId": "HSSVQALC",
            "iataCode": "SVQ",
            "lastUpdate": "2023-08-01T10:37:04",
            "name": "HOTEL MADRID SEVILLA",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "HS",
            "distance": {
              "unit": "KM",
              "value": 1.5
            },
            "dupeId": 700132492,
            "geoCode": {
              "latitude": 37.3919,
              "longitude": -6.00118
            },
            "hotelId": "HSSVQAFH",
            "iataCode": "SVQ",
            "lastUpdate": "2023-08-01T10:05:42",
            "name": "ROMA HOSTAL",
            "rating": 1
          },
          {
            "address": {
              "countryCode": "ES"
            },
            "chainCode": "RT",
            "distance": {
              "unit": "KM",
              "value": 3.65
            },
            "dupeId": 700094649,
            "geoCode": {
              "latitude": 37.4082,
              "longitude": -5.9509
            },
            "hotelId": "RTSVQIBI",
            "iataCode": "SVQ",
            "lastUpdate": "2024-05-14T09:03:56",
            "name": "IBIS SEVILLA",
            "rating": 1
          }
        ]
      ],
      "HOTEL_OFFERSETS": [
        [
          [
            {
              "available": true,
              "hotel": {
                "chainCode": "RT",
                "cityCode": "AGP",
                "dupeId": "700183255",
                "hotelId": "RTAGPTRO",
                "latitude": 36.71866,
                "longitude": -4.4276,
                "name": "ibis budget Málaga Centro",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-09-25",
                  "checkOutDate": "2024-09-28",
                  "guests": {
                    "adults": 1
                  },
                  "id": "E1THP68V5A",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-09-24T23:59:00+02:00"
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "AX"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "VI"
                          }
                        ],
                        "creditCards": [
                          "AX",
                          "CA",
                          "VI"
                        ],
                        "methods": [
                          "TRAVEL_AGENT_ID",
                          "CREDIT_CARD",
                          "VCC_EXTERNAL_PROVIDER"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "total": "322.50",
                    "variations": {
                      "average": {
                        "base": "107.50"
                      },
                      "changes": [
                        {
                          "base": "98.50",
                          "endDate": "2024-09-27",
                          "startDate": "2024-09-25",
                          "total": "98.50"
                        },
                        {
                          "base": "125.50",
                          "endDate": "2024-09-28",
                          "startDate": "2024-09-27",
                          "total": "125.50"
                        }
                      ]
                    }
                  },
                  "rateCode": "RG1",
                  "rateFamilyEstimated": {
                    "code": "RAC",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "RAC GDS-RO/Room only\nRoom with double bed and single bed"
                    },
                    "type": "C1D",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "roomQuantity": 2,
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/E1THP68V5A"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTAGPTRO&adults=1&checkInDate=2024-09-25&checkOutDate=2024-09-28&roomQuantity=2",
              "type": "hotel-offers"
            },
            {
              "available": true,
              "hotel": {
                "chainCode": "WV",
                "cityCode": "AGP",
                "dupeId": "700584788",
                "hotelId": "WVAGP392",
                "latitude": 36.72129,
                "longitude": -4.41768,
                "name": "Soho Boutique Museo",
                "type": "hotel"
              },
              "offers": [
                {
                  "checkInDate": "2024-09-25",
                  "checkOutDate": "2024-09-28",
                  "commission": {
                    "percentage": "10.0"
                  },
                  "guests": {
                    "adults": 1
                  },
                  "id": "MQ9CWCRYV5",
                  "policies": {
                    "cancellations": [
                      {
                        "description": {
                          "lang": "EN",
                          "text": "Non refundable rate"
                        },
                        "type": "FULL_STAY"
                      }
                    ],
                    "paymentType": "prepay",
                    "prepay": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "VI"
                          },
                          {
                            "vendorCode": "MC"
                          },
                          {
                            "vendorCode": "AX"
                          }
                        ],
                        "creditCards": [
                          "VI",
                          "MC",
                          "AX"
                        ],
                        "methods": [
                          "CREDIT_CARD"
                        ]
                      },
                      "amount": "460.80"
                    }
                  },
                  "price": {
                    "currency": "EUR",
                    "taxes": [
                      {
                        "code": "VAT_GST_TAX",
                        "included": true,
                        "percentage": "10.00"
                      }
                    ],
                    "total": "460.80",
                    "variations": {
                      "average": {
                        "base": "153.60"
                      },
                      "changes": [
                        {
                          "base": "148.50",
                          "endDate": "2024-09-27",
                          "startDate": "2024-09-25"
                        },
                        {
                          "base": "163.80",
                          "endDate": "2024-09-28",
                          "startDate": "2024-09-27"
                        }
                      ]
                    }
                  },
                  "rateCode": "RAC",
                  "rateFamilyEstimated": {
                    "code": "PRO",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "NON REFUNDABLE RO\nLOFT 35 SQ M 1 DBL  SOFA BED 3 PAX FLAT TV SAFE\nNR RO WIFI FREE"
                    },
                    "type": "J9S",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "roomQuantity": 2,
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/MQ9CWCRYV5"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=WVAGP392&adults=1&checkInDate=2024-09-25&checkOutDate=2024-09-28&roomQuantity=2",
              "type": "hotel-offers"
            }
          ],
          [
            {
              "available": true,
              "hotel": {
                "chainCode": "RT",
                "cityCode": "AGP",
                "dupeId": "700183255",
                "hotelId": "RTAGPTRO",
                "latitude": 36.71866,
                "longitude": -4.4276,
                "name": "ibis budget Málaga Centro",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-09-28",
                  "checkOutDate": "2024-10-01",
                  "guests": {
                    "adults": 1
                  },
                  "id": "HMGSQ91P7P",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-09-27T23:59:00+02:00"
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "AX"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "VI"
                          }
                        ],
                        "creditCards": [
                          "AX",
                          "CA",
                          "VI"
                        ],
                        "methods": [
                          "TRAVEL_AGENT_ID",
                          "CREDIT_CARD",
                          "VCC_EXTERNAL_PROVIDER"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "total": "297.50",
                    "variations": {
                      "average": {
                        "base": "99.17"
                      },
                      "changes": [
                        {
                          "base": "125.50",
                          "endDate": "2024-09-29",
                          "startDate": "2024-09-28",
                          "total": "125.50"
                        },
                        {
                          "base": "85.50",
                          "endDate": "2024-09-30",
                          "startDate": "2024-09-29",
                          "total": "85.50"
                        },
                        {
                          "base": "86.50",
                          "endDate": "2024-10-01",
                          "startDate": "2024-09-30",
                          "total": "86.50"
                        }
                      ]
                    }
                  },
                  "rateCode": "RG1",
                  "rateFamilyEstimated": {
                    "code": "RAC",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "RAC GDS-RO/Room only\nRoom with double bed and single bed"
                    },
                    "type": "C1D",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "roomQuantity": 2,
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/HMGSQ91P7P"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTAGPTRO&adults=1&checkInDate=2024-09-28&checkOutDate=2024-10-01&roomQuantity=2",
              "type": "hotel-offers"
            },
            {
              "available": true,
              "hotel": {
                "chainCode": "WV",
                "cityCode": "AGP",
                "dupeId": "700584788",
                "hotelId": "WVAGP392",
                "latitude": 36.72129,
                "longitude": -4.41768,
                "name": "Soho Boutique Museo",
                "type": "hotel"
              },
              "offers": [
                {
                  "checkInDate": "2024-09-28",
                  "checkOutDate": "2024-10-01",
                  "commission": {
                    "percentage": "10.0"
                  },
                  "guests": {
                    "adults": 1
                  },
                  "id": "9IIKTOP1BD",
                  "policies": {
                    "cancellations": [
                      {
                        "description": {
                          "lang": "EN",
                          "text": "Non refundable rate"
                        },
                        "type": "FULL_STAY"
                      }
                    ],
                    "paymentType": "prepay",
                    "prepay": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "VI"
                          },
                          {
                            "vendorCode": "MC"
                          },
                          {
                            "vendorCode": "AX"
                          }
                        ],
                        "creditCards": [
                          "VI",
                          "MC",
                          "AX"
                        ],
                        "methods": [
                          "CREDIT_CARD"
                        ]
                      },
                      "amount": "476.10"
                    }
                  },
                  "price": {
                    "currency": "EUR",
                    "taxes": [
                      {
                        "code": "VAT_GST_TAX",
                        "included": true,
                        "percentage": "10.00"
                      }
                    ],
                    "total": "476.10",
                    "variations": {
                      "average": {
                        "base": "158.70"
                      },
                      "changes": [
                        {
                          "base": "179.10",
                          "endDate": "2024-09-29",
                          "startDate": "2024-09-28"
                        },
                        {
                          "base": "148.50",
                          "endDate": "2024-10-01",
                          "startDate": "2024-09-29"
                        }
                      ]
                    }
                  },
                  "rateCode": "RAC",
                  "rateFamilyEstimated": {
                    "code": "PRO",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "NON REFUNDABLE RO\nLOFT 35 SQ M 1 DBL  SOFA BED 3 PAX FLAT TV SAFE\nNR RO WIFI FREE"
                    },
                    "type": "J9S",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "roomQuantity": 2,
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/9IIKTOP1BD"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=WVAGP392&adults=1&checkInDate=2024-09-28&checkOutDate=2024-10-01&roomQuantity=2",
              "type": "hotel-offers"
            }
          ],
          [
            {
              "available": true,
              "hotel": {
                "chainCode": "HS",
                "cityCode": "MAD",
                "dupeId": "700221941",
                "hotelId": "HSMADAMA",
                "latitude": 40.41033,
                "longitude": -3.69462,
                "name": "Urban Sea Atocha 113",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-10-01",
                  "checkOutDate": "2024-10-04",
                  "commission": {
                    "percentage": "4.00"
                  },
                  "guests": {
                    "adults": 1
                  },
                  "id": "RVAH8HDDBK",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-09-24T22:59:00+02:00",
                        "numberOfNights": 3
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "DC"
                          },
                          {
                            "vendorCode": "DN"
                          },
                          {
                            "vendorCode": "VI"
                          },
                          {
                            "vendorCode": "MC"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "AX"
                          }
                        ],
                        "creditCards": [
                          "DC",
                          "DN",
                          "VI",
                          "MC",
                          "CA",
                          "AX"
                        ],
                        "methods": [
                          "CREDIT_CARD"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "taxes": [
                      {
                        "code": "VALUE_ADDED_TAX",
                        "included": true,
                        "percentage": "10.00"
                      }
                    ],
                    "total": "417.00",
                    "variations": {
                      "average": {
                        "total": "139.00"
                      },
                      "changes": [
                        {
                          "endDate": "2024-10-04",
                          "startDate": "2024-10-01",
                          "total": "139.00"
                        }
                      ]
                    }
                  },
                  "rateCode": "RAC",
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "Event-Rate\nStandard room A standard room consists of a room with shower-toilet or bathtub-toilet."
                    },
                    "type": "ROH",
                    "typeEstimated": {
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/RVAH8HDDBK"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=HSMADAMA&adults=1&checkInDate=2024-10-01&checkOutDate=2024-10-04&roomQuantity=1",
              "type": "hotel-offers"
            },
            {
              "available": true,
              "hotel": {
                "chainCode": "RT",
                "cityCode": "MAD",
                "dupeId": "700219108",
                "hotelId": "RTMADAFE",
                "latitude": 40.2764,
                "longitude": -3.69523,
                "name": "ibis budget Madrid Getafe",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-10-01",
                  "checkOutDate": "2024-10-04",
                  "guests": {
                    "adults": 1
                  },
                  "id": "HD5HM5XP0X",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-09-30T23:59:00+02:00"
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "AX"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "EC"
                          },
                          {
                            "vendorCode": "VI"
                          }
                        ],
                        "creditCards": [
                          "AX",
                          "CA",
                          "EC",
                          "VI"
                        ],
                        "methods": [
                          "CREDIT_CARD",
                          "VCC_EXTERNAL_PROVIDER"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "total": "229.50",
                    "variations": {
                      "average": {
                        "base": "76.50"
                      },
                      "changes": [
                        {
                          "base": "76.50",
                          "endDate": "2024-10-04",
                          "startDate": "2024-10-01",
                          "total": "76.50"
                        }
                      ]
                    }
                  },
                  "rateCode": "RG1",
                  "rateFamilyEstimated": {
                    "code": "RAC",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "RAC GDS-RO/Room only\nRoom with double bed and single bed"
                    },
                    "type": "C1D",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/HD5HM5XP0X"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTMADAFE&adults=1&checkInDate=2024-10-01&checkOutDate=2024-10-04&roomQuantity=1",
              "type": "hotel-offers"
            },
            {
              "available": true,
              "hotel": {
                "chainCode": "RT",
                "cityCode": "MAD",
                "dupeId": "700318447",
                "hotelId": "RTMADBUD",
                "latitude": 40.39609,
                "longitude": -3.67054,
                "name": "ibis budget Madrid Calle 30",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-10-01",
                  "checkOutDate": "2024-10-04",
                  "guests": {
                    "adults": 1
                  },
                  "id": "IX7IB94ZNZ",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-09-30T23:59:00+02:00"
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "AX"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "EC"
                          },
                          {
                            "vendorCode": "VI"
                          }
                        ],
                        "creditCards": [
                          "AX",
                          "CA",
                          "EC",
                          "VI"
                        ],
                        "methods": [
                          "CREDIT_CARD",
                          "VCC_EXTERNAL_PROVIDER"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "total": "301.50",
                    "variations": {
                      "average": {
                        "base": "100.50"
                      },
                      "changes": [
                        {
                          "base": "103.50",
                          "endDate": "2024-10-02",
                          "startDate": "2024-10-01",
                          "total": "103.50"
                        },
                        {
                          "base": "99.50",
                          "endDate": "2024-10-03",
                          "startDate": "2024-10-02",
                          "total": "99.50"
                        },
                        {
                          "base": "98.50",
                          "endDate": "2024-10-04",
                          "startDate": "2024-10-03",
                          "total": "98.50"
                        }
                      ]
                    }
                  },
                  "rateCode": "RG1",
                  "rateFamilyEstimated": {
                    "code": "RAC",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "RAC GDS-RO/Room only\nRoom with double bed and bunk bed"
                    },
                    "type": "C1D",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/IX7IB94ZNZ"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTMADBUD&adults=1&checkInDate=2024-10-01&checkOutDate=2024-10-04&roomQuantity=1",
              "type": "hotel-offers"
            },
            {
              "available": true,
              "hotel": {
                "chainCode": "RT",
                "cityCode": "MAD",
                "dupeId": "700183254",
                "hotelId": "RTMADCAS",
                "latitude": 40.37231,
                "longitude": -3.64069,
                "name": "ibis budget Madrid Vallecas",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-10-01",
                  "checkOutDate": "2024-10-04",
                  "guests": {
                    "adults": 1
                  },
                  "id": "MOWLA6RNFY",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-09-30T23:59:00+02:00"
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "AX"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "VI"
                          }
                        ],
                        "creditCards": [
                          "AX",
                          "CA",
                          "VI"
                        ],
                        "methods": [
                          "CREDIT_CARD",
                          "VCC_EXTERNAL_PROVIDER"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "total": "250.50",
                    "variations": {
                      "average": {
                        "base": "83.50"
                      },
                      "changes": [
                        {
                          "base": "83.50",
                          "endDate": "2024-10-04",
                          "startDate": "2024-10-01",
                          "total": "83.50"
                        }
                      ]
                    }
                  },
                  "rateCode": "RG1",
                  "rateFamilyEstimated": {
                    "code": "RAC",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "RAC GDS-RO/Room only\nRoom with double bed and single bed"
                    },
                    "type": "C1D",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "STANDARD_ROOM"
                    }
                  },
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/MOWLA6RNFY"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTMADCAS&adults=1&checkInDate=2024-10-01&checkOutDate=2024-10-04&roomQuantity=1",
              "type": "hotel-offers"
            }
          ],
          [
            {
              "available": true,
              "hotel": {
                "chainCode": "RT",
                "cityCode": "SVQ",
                "dupeId": "700094649",
                "hotelId": "RTSVQIBI",
                "latitude": 37.4082,
                "longitude": -5.9509,
                "name": "ibis Sevilla",
                "type": "hotel"
              },
              "offers": [
                {
                  "boardType": "ROOM_ONLY",
                  "checkInDate": "2024-10-04",
                  "checkOutDate": "2024-10-07",
                  "commission": {
                    "amount": "2.74"
                  },
                  "guests": {
                    "adults": 1
                  },
                  "id": "2TEP4FTXH7",
                  "policies": {
                    "cancellations": [
                      {
                        "deadline": "2024-10-03T23:59:00+02:00"
                      }
                    ],
                    "guarantee": {
                      "acceptedPayments": {
                        "creditCardPolicies": [
                          {
                            "vendorCode": "AX"
                          },
                          {
                            "vendorCode": "CA"
                          },
                          {
                            "vendorCode": "EC"
                          },
                          {
                            "vendorCode": "IK"
                          },
                          {
                            "vendorCode": "VI"
                          }
                        ],
                        "creditCards": [
                          "AX",
                          "CA",
                          "EC",
                          "IK",
                          "VI"
                        ],
                        "methods": [
                          "CREDIT_CARD",
                          "VCC_EXTERNAL_PROVIDER"
                        ]
                      }
                    },
                    "paymentType": "guarantee"
                  },
                  "price": {
                    "currency": "EUR",
                    "total": "324.00",
                    "variations": {
                      "average": {
                        "base": "108.00"
                      },
                      "changes": [
                        {
                          "base": "118.00",
                          "endDate": "2024-10-06",
                          "startDate": "2024-10-04",
                          "total": "118.00"
                        },
                        {
                          "base": "88.00",
                          "endDate": "2024-10-07",
                          "startDate": "2024-10-06",
                          "total": "88.00"
                        }
                      ]
                    }
                  },
                  "rateCode": "E5Z",
                  "rateFamilyEstimated": {
                    "code": "BAR",
                    "type": "P"
                  },
                  "room": {
                    "description": {
                      "lang": "EN",
                      "text": "FLEXIBLE RATE-RO/Room only\nAdapted room with double bed for 1-2 people equ\nipped with the new Ibis bed"
                    },
                    "type": "D1D",
                    "typeEstimated": {
                      "bedType": "DOUBLE",
                      "beds": 1,
                      "category": "RESIDENTIAL_APPARTMENT"
                    }
                  },
                  "self": "https://api.amadeus.com/v3/shopping/hotel-offers/2TEP4FTXH7"
                }
              ],
              "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTSVQIBI&adults=1&checkInDate=2024-10-04&checkOutDate=2024-10-07&roomQuantity=1",
              "type": "hotel-offers"
            }
          ]
        ]
      ]
    },
    "day_plans": [
      {
        "DATE": "2024-09-22",
        "SCHEDULE": [
          {
            "TIME_START": "17:00",
            "TIME_END": "18:00",
            "NAME": "Arrival at Barcelona Airport",
            "COORDINATES": "41.2974, 2.0833",
            "TYPE": "transfer",
            "DESCRIPTION": "Take a taxi or airport shuttle from Barcelona-El Prat Airport to your accommodation in the city center."
          },
          {
            "TIME_START": "19:00",
            "TIME_END": "21:00",
            "NAME": "Dinner at La Paradeta",
            "COORDINATES": "41.3805, 2.1762",
            "TYPE": "activity",
            "PLACE_NAME": "La Paradeta, Barcelona",
            "DESCRIPTION": "Indulge in delectable seafood dishes at La Paradeta, a renowned restaurant located in the heart of the Gothic Quarter."
          },
          {
            "TIME_START": "21:30",
            "TIME_END": "23:30",
            "NAME": "Evening Stroll at La Barceloneta Beach",
            "COORDINATES": "41.3763, 2.1932",
            "TYPE": "activity",
            "PLACE_NAME": "La Barceloneta Beach, Barcelona",
            "DESCRIPTION": "Take a leisurely evening stroll along the iconic La Barceloneta Beach, soaking in the vibrant atmosphere and enjoying the sea breeze."
          }
        ],
        "location": "Barcelona"
      },
      {
        "DATE": "2024-09-23",
        "SCHEDULE": [
          {
            "TIME_START": "09:00",
            "TIME_END": "11:00",
            "NAME": "Tour of Camp Nou",
            "COORDINATES": "41.3809, 2.1228",
            "TYPE": "activity",
            "PLACE_NAME": "Camp Nou, Barcelona",
            "DESCRIPTION": "Explore the iconic Camp Nou Stadium, the home of FC Barcelona, and immerse yourself in its rich history and grandeur."
          },
          {
            "TIME_START": "11:30",
            "TIME_END": "13:30",
            "NAME": "Lunch at Bogatell Beach",
            "COORDINATES": "41.4015, 2.2058",
            "TYPE": "activity",
            "PLACE_NAME": "Bogatell Beach, Barcelona",
            "DESCRIPTION": "Enjoy a relaxing lunch at one of the beachside restaurants along Bogatell Beach, savoring the local cuisine and soaking up the sun."
          },
          {
            "TIME_START": "14:00",
            "TIME_END": "16:00",
            "NAME": "Visit La Sagrada Familia",
            "COORDINATES": "41.4036, 2.1744",
            "TYPE": "activity",
            "PLACE_NAME": "La Sagrada Familia, Barcelona",
            "DESCRIPTION": "Marvel at the architectural brilliance of La Sagrada Familia, a UNESCO World Heritage Site and Gaudi's masterpiece."
          },
          {
            "TIME_START": "16:30",
            "TIME_END": "18:30",
            "NAME": "Explore Park Güell",
            "COORDINATES": "41.4145, 2.1527",
            "TYPE": "activity",
            "PLACE_NAME": "Park Güell, Barcelona",
            "DESCRIPTION": "Wander through the whimsical Park Güell, another of Gaudi's iconic creations, offering panoramic views of the city."
          }
        ],
        "location": "Barcelona"
      },
      {
        "DATE": "2024-09-24",
        "SCHEDULE": [
          {
            "TIME_START": "09:30",
            "TIME_END": "11:30",
            "NAME": "Visit La Boqueria Market",
            "COORDINATES": "41.3818, 2.1714",
            "TYPE": "activity",
            "PLACE_NAME": "La Boqueria Market, Barcelona",
            "DESCRIPTION": "Explore the vibrant La Boqueria Market, a foodie's paradise, and indulge in a variety of fresh local foods and drinks."
          },
          {
            "TIME_START": "12:00",
            "TIME_END": "14:00",
            "NAME": "Lunch at Els Quatre Gats",
            "COORDINATES": "41.3846, 2.1726",
            "TYPE": "activity",
            "PLACE_NAME": "Els Quatre Gats, Barcelona",
            "DESCRIPTION": "Savor a traditional Catalan lunch at Els Quatre Gats, a historic restaurant frequented by famous artists like Picasso and Gaudí."
          },
          {
            "TIME_START": "14:30",
            "TIME_END": "17:30",
            "NAME": "Explore Gothic Quarter",
            "COORDINATES": "41.3834, 2.1760",
            "TYPE": "activity",
            "PLACE_NAME": "Gothic Quarter, Barcelona",
            "DESCRIPTION": "Wander through the Gothic Quarter's narrow medieval streets filled with shops, bars, and historic buildings, immersing yourself in the city's rich cultural heritage."
          },
          {
            "TIME_START": "18:00",
            "TIME_END": "20:00",
            "NAME": "Dinner at Tickets Bar",
            "COORDINATES": "41.3924, 2.1949",
            "TYPE": "activity",
            "PLACE_NAME": "Tickets Bar, Barcelona",
            "DESCRIPTION": "Indulge in an innovative and avant-garde dining experience at Tickets Bar, a culinary hotspot by the renowned Adrià brothers."
          }
        ],
        "location": "Barcelona"
      },
      {
        "DATE": "2024-09-25",
        "SCHEDULE": [
          {
            "TIME_START": "09:00",
            "TIME_END": "15:00",
            "NAME": "Depart for Marbella, Spain",
            "TYPE": "inter_city_transfer",
            "DESCRIPTION": "Depart for your journey to Marbella, Spain. The travel time by CAR is 6 hours",
            "TRANSPORT": "CAR"
          },
          {
            "TIME_START": "15:00",
            "TIME_END": "17:00",
            "NAME": "Arrival in Marbella",
            "COORDINATES": "36.5106, -4.8845",
            "TYPE": "activity",
            "PLACE_NAME": "Marbella",
            "DESCRIPTION": "Relax and unwind after your journey to the beautiful coastal city of Marbella."
          },
          {
            "TIME_START": "17:30",
            "TIME_END": "19:30",
            "NAME": "Explore Old Town",
            "COORDINATES": "36.5135, -4.8903",
            "TYPE": "activity",
            "PLACE_NAME": "Old Town, Marbella",
            "DESCRIPTION": "Stroll through the charming Old Town, admiring the whitewashed buildings, narrow streets, and vibrant plazas."
          },
          {
            "TIME_START": "20:00",
            "TIME_END": "22:00",
            "NAME": "Dinner at Casanis",
            "COORDINATES": "36.5135, -4.8903",
            "TYPE": "activity",
            "PLACE_NAME": "Casanis, Marbella",
            "DESCRIPTION": "Indulge in delectable Mediterranean cuisine at Casanis, a renowned restaurant in the heart of the Old Town."
          }
        ],
        "location": "Barcelona, Marbella"
      },
      {
        "DATE": "2024-09-26",
        "SCHEDULE": [
          {
            "TIME_START": "10:00",
            "TIME_END": "13:00",
            "NAME": "Beach Day at Playa de Cabopino",
            "COORDINATES": "36.4767, -4.7367",
            "TYPE": "activity",
            "PLACE_NAME": "Playa de Cabopino, Marbella",
            "DESCRIPTION": "Spend a relaxing day at the beautiful Playa de Cabopino, soaking up the sun and enjoying the crystal-clear waters."
          },
          {
            "TIME_START": "13:30",
            "TIME_END": "15:30",
            "NAME": "Lunch at Chiringuito Playa Cabopino",
            "COORDINATES": "36.4767, -4.7367",
            "TYPE": "activity",
            "PLACE_NAME": "Chiringuito Playa Cabopino, Marbella",
            "DESCRIPTION": "Savor fresh seafood and local specialties at Chiringuito Playa Cabopino, a beachside restaurant with stunning views."
          },
          {
            "TIME_START": "16:00",
            "TIME_END": "18:00",
            "NAME": "Visit Museo Ralli",
            "COORDINATES": "36.5135, -4.8903",
            "TYPE": "activity",
            "PLACE_NAME": "Museo Ralli, Marbella",
            "DESCRIPTION": "Explore the Museo Ralli, a renowned museum showcasing an impressive collection of contemporary Latin American art."
          }
        ],
        "location": "Marbella"
      },
      {
        "DATE": "2024-09-27",
        "SCHEDULE": [
          {
            "TIME_START": "10:00",
            "TIME_END": "13:00",
            "NAME": "Day Trip to Ronda",
            "COORDINATES": "36.7425, -5.1677",
            "TYPE": "activity",
            "PLACE_NAME": "Ronda, Spain",
            "DESCRIPTION": "Embark on a day trip to the picturesque town of Ronda, known for its stunning bridge spanning the El Tajo gorge."
          },
          {
            "TIME_START": "13:30",
            "TIME_END": "15:30",
            "NAME": "Lunch at Tragatapas",
            "COORDINATES": "36.7425, -5.1677",
            "TYPE": "activity",
            "PLACE_NAME": "Tragatapas, Ronda",
            "DESCRIPTION": "Enjoy a delightful tapas lunch at Tragatapas, a local favorite in the heart of Ronda."
          },
          {
            "TIME_START": "16:00",
            "TIME_END": "18:00",
            "NAME": "Relax at Playa de Guadalmina",
            "COORDINATES": "36.4833, -4.9333",
            "TYPE": "activity",
            "PLACE_NAME": "Playa de Guadalmina, Marbella",
            "DESCRIPTION": "Unwind at the beautiful Playa de Guadalmina, a tranquil beach perfect for soaking up the last rays of the day."
          }
        ],
        "location": "Marbella"
      },
      {
        "DATE": "2024-09-28",
        "SCHEDULE": [
          {
            "TIME_START": "10:00",
            "TIME_END": "16:00",
            "NAME": "Depart for Malaga, Spain",
            "TYPE": "inter_city_transfer",
            "DESCRIPTION": "Depart for your journey to Malaga, Spain. The travel time by CAR is 6 hours",
            "TRANSPORT": "CAR"
          },
          {
            "TIME_START": "16:00",
            "TIME_END": "18:00",
            "NAME": "Arrival in Malaga",
            "COORDINATES": "36.7196, -4.4203",
            "TYPE": "activity",
            "PLACE_NAME": "Malaga City Center",
            "DESCRIPTION": "After arriving in Malaga, take some time to relax and freshen up at your accommodation."
          },
          {
            "TIME_START": "19:00",
            "TIME_END": "21:00",
            "NAME": "Dinner at El Pimpi",
            "COORDINATES": "36.7210, -4.4179",
            "TYPE": "activity",
            "PLACE_NAME": "El Pimpi, Malaga",
            "DESCRIPTION": "Experience the vibrant atmosphere and delicious local cuisine at El Pimpi, a renowned restaurant in the heart of Malaga."
          }
        ],
        "location": "Marbella, Malaga"
      },
      {
        "DATE": "2024-09-29",
        "SCHEDULE": [
          {
            "TIME_START": "10:00",
            "TIME_END": "13:00",
            "NAME": "Explore Alcazaba and Roman Theatre",
            "COORDINATES": "36.7218, -4.4175",
            "TYPE": "activity",
            "PLACE_NAME": "Alcazaba and Roman Theatre, Malaga",
            "DESCRIPTION": "Immerse yourself in the rich cultural heritage of Malaga by visiting the Alcazaba, a Moorish fortress, and the adjacent Roman Theatre."
          },
          {
            "TIME_START": "13:30",
            "TIME_END": "15:00",
            "NAME": "Lunch at El Cortijo de Pepe",
            "COORDINATES": "36.7193, -4.4211",
            "TYPE": "activity",
            "PLACE_NAME": "El Cortijo de Pepe, Malaga",
            "DESCRIPTION": "Savor the authentic flavors of Andalusian cuisine at El Cortijo de Pepe, a local favorite for traditional Spanish dishes."
          },
          {
            "TIME_START": "15:30",
            "TIME_END": "18:30",
            "NAME": "Relax at Malagueta Beach",
            "COORDINATES": "36.7095, -4.4193",
            "TYPE": "activity",
            "PLACE_NAME": "Malagueta Beach, Malaga",
            "DESCRIPTION": "Spend the afternoon unwinding on the golden sands of Malagueta Beach, one of Malaga's most popular urban beaches."
          }
        ],
        "location": "Malaga"
      },
      {
        "DATE": "2024-09-30",
        "SCHEDULE": [
          {
            "TIME_START": "09:30",
            "TIME_END": "11:30",
            "NAME": "Visit Picasso Museum",
            "COORDINATES": "36.7227, -4.4181",
            "TYPE": "activity",
            "PLACE_NAME": "Picasso Museum, Malaga",
            "DESCRIPTION": "Explore the birthplace of Pablo Picasso and discover his early works at the Picasso Museum, housed in a beautiful Renaissance-style palace."
          },
          {
            "TIME_START": "12:00",
            "TIME_END": "14:00",
            "NAME": "Lunch at El Tapeo de Cervantes",
            "COORDINATES": "36.7227, -4.4200",
            "TYPE": "activity",
            "PLACE_NAME": "El Tapeo de Cervantes, Malaga",
            "DESCRIPTION": "Indulge in a delightful tapas experience at El Tapeo de Cervantes, a cozy restaurant known for its creative and flavorful small plates."
          },
          {
            "TIME_START": "14:30",
            "TIME_END": "17:30",
            "NAME": "Explore Gibralfaro Hill and Castillo de Gibralfaro",
            "COORDINATES": "36.7163, -4.4025",
            "TYPE": "activity",
            "PLACE_NAME": "Gibralfaro Hill and Castillo de Gibralfaro, Malaga",
            "DESCRIPTION": "Hike up Gibralfaro Hill for panoramic views of Malaga and visit the Castillo de Gibralfaro, a 14th-century Moorish fortress."
          }
        ],
        "location": "Malaga"
      },
      {
        "DATE": "2024-10-01",
        "SCHEDULE": [
          {
            "TIME_START": "09:00",
            "TIME_END": "11:00",
            "NAME": "Explore Malaga Cathedral",
            "COORDINATES": "36.7227, -4.4203",
            "TYPE": "activity",
            "PLACE_NAME": "Malaga Cathedral, Malaga",
            "DESCRIPTION": "Marvel at the impressive architecture of Malaga Cathedral, also known as the 'One-Armed Lady,' a stunning Renaissance-style cathedral."
          },
          {
            "TIME_START": "11:30",
            "TIME_END": "12:35",
            "NAME": "Transfer to Malaga Airport",
            "COORDINATES": "36.6749, -4.4989",
            "TYPE": "transfer",
            "DESCRIPTION": "Depart for Malaga Airport (AGP) to catch your flight."
          },
          {
            "TIME_START": "12:35",
            "TIME_END": "13:45",
            "NAME": "Depart for Madrid, Spain",
            "TYPE": "inter_city_transfer",
            "DESCRIPTION": "Depart for your journey to Madrid, Spain. The travel time by FLIGHT is 1 hours and 10 minutes",
            "TRANSPORT": "FLIGHT"
          },
          {
            "TIME_START": "13:45",
            "TIME_END": "14:30",
            "NAME": "Arrival at Madrid Airport",
            "COORDINATES": "40.4722, -3.5611",
            "TYPE": "transfer",
            "DESCRIPTION": "Take a taxi or airport shuttle from Madrid-Barajas Airport to your accommodation in the city center."
          },
          {
            "TIME_START": "15:30",
            "TIME_END": "17:30",
            "NAME": "Visit Puerta del Sol",
            "COORDINATES": "40.4167, -3.7038",
            "TYPE": "activity",
            "PLACE_NAME": "Puerta del Sol, Madrid",
            "DESCRIPTION": "Explore the iconic Puerta del Sol, the bustling heart of Madrid, and soak in the vibrant atmosphere."
          },
          {
            "TIME_START": "18:00",
            "TIME_END": "20:00",
            "NAME": "Dinner at Sobrino de Botín",
            "COORDINATES": "40.4135, -3.7122",
            "TYPE": "activity",
            "PLACE_NAME": "Sobrino de Botín, Madrid",
            "DESCRIPTION": "Indulge in a delectable dinner at Sobrino de Botín, the world's oldest restaurant, renowned for its traditional Spanish cuisine."
          }
        ],
        "location": "Malaga, Madrid"
      },
      {
        "DATE": "2024-10-02",
        "SCHEDULE": [
          {
            "TIME_START": "09:00",
            "TIME_END": "11:00",
            "NAME": "Tour of Santiago Bernabéu Stadium",
            "COORDINATES": "40.4532, -3.6885",
            "TYPE": "activity",
            "PLACE_NAME": "Santiago Bernabéu Stadium, Madrid",
            "DESCRIPTION": "Explore the iconic Santiago Bernabéu Stadium, home of Real Madrid, and learn about its rich football history."
          },
          {
            "TIME_START": "11:30",
            "TIME_END": "13:30",
            "NAME": "Visit Palacio Real",
            "COORDINATES": "40.4167, -3.7138",
            "TYPE": "activity",
            "PLACE_NAME": "Palacio Real, Madrid",
            "DESCRIPTION": "Marvel at the grandeur of the Palacio Real, the official residence of the Spanish Royal Family, and admire its stunning architecture."
          },
          {
            "TIME_START": "14:00",
            "TIME_END": "16:00",
            "NAME": "Lunch at Mercado de San Miguel",
            "COORDINATES": "40.4153, -3.7083",
            "TYPE": "activity",
            "PLACE_NAME": "Mercado de San Miguel, Madrid",
            "DESCRIPTION": "Explore the vibrant Mercado de San Miguel and savor a variety of delicious tapas and local delicacies."
          },
          {
            "TIME_START": "16:30",
            "TIME_END": "18:30",
            "NAME": "Visit Museo del Prado",
            "COORDINATES": "40.4137, -3.6922",
            "TYPE": "activity",
            "PLACE_NAME": "Museo del Prado, Madrid",
            "DESCRIPTION": "Immerse yourself in the world-renowned art collection at the Museo del Prado, one of the finest art museums in the world."
          }
        ],
        "location": "Madrid"
      },
      {
        "DATE": "2024-10-03",
        "SCHEDULE": [
          {
            "TIME_START": "09:30",
            "TIME_END": "11:30",
            "NAME": "Explore Plaza Mayor",
            "COORDINATES": "40.4151, -3.7071",
            "TYPE": "activity",
            "PLACE_NAME": "Plaza Mayor, Madrid",
            "DESCRIPTION": "Discover the iconic Plaza Mayor, a stunning square surrounded by historic buildings and bustling with street performers."
          },
          {
            "TIME_START": "12:00",
            "TIME_END": "14:00",
            "NAME": "Lunch at La Huerta de Berlín",
            "COORDINATES": "40.4236, -3.6924",
            "TYPE": "activity",
            "PLACE_NAME": "La Huerta de Berlín, Madrid",
            "DESCRIPTION": "Enjoy a delightful lunch at La Huerta de Berlín, a charming restaurant known for its traditional Spanish cuisine and cozy ambiance."
          },
          {
            "TIME_START": "14:30",
            "TIME_END": "16:30",
            "NAME": "Visit Parque del Retiro",
            "COORDINATES": "40.4151, -3.6831",
            "TYPE": "activity",
            "PLACE_NAME": "Parque del Retiro, Madrid",
            "DESCRIPTION": "Relax and unwind in the beautiful Parque del Retiro, Madrid's iconic park, and enjoy its lush gardens and monuments."
          },
          {
            "TIME_START": "17:00",
            "TIME_END": "19:00",
            "NAME": "Explore Gran Vía",
            "COORDINATES": "40.4202, -3.7051",
            "TYPE": "activity",
            "PLACE_NAME": "Gran Vía, Madrid",
            "DESCRIPTION": "Stroll along the vibrant Gran Vía, Madrid's famous shopping and entertainment district, and experience its lively atmosphere."
          }
        ],
        "location": "Madrid"
      },
      {
        "DATE": "2024-10-04",
        "SCHEDULE": [
          {
            "TIME_START": "08:00",
            "TIME_END": "14:00",
            "NAME": "Depart for Seville, Spain",
            "TYPE": "inter_city_transfer",
            "DESCRIPTION": "Depart for your journey to Seville, Spain. The travel time by CAR is 6 hours",
            "TRANSPORT": "CAR"
          },
          {
            "TIME_START": "14:00",
            "TIME_END": "16:00",
            "NAME": "Arrival in Seville",
            "COORDINATES": "37.3891, -5.9845",
            "TYPE": "activity",
            "PLACE_NAME": "Seville, Spain",
            "DESCRIPTION": "Explore the vibrant city of Seville, known for its rich cultural heritage and stunning architecture."
          },
          {
            "TIME_START": "16:30",
            "TIME_END": "18:30",
            "NAME": "Visit Real Alcázar",
            "COORDINATES": "37.3838, -5.9953",
            "TYPE": "activity",
            "PLACE_NAME": "Real Alcázar, Seville",
            "DESCRIPTION": "Marvel at the stunning Mudéjar architecture and lush gardens of the Real Alcázar, a UNESCO World Heritage Site."
          },
          {
            "TIME_START": "19:00",
            "TIME_END": "21:00",
            "NAME": "Dinner at Ovejas Negras",
            "COORDINATES": "37.3894, -5.9929",
            "TYPE": "activity",
            "PLACE_NAME": "Ovejas Negras, Seville",
            "DESCRIPTION": "Indulge in delectable Andalusian cuisine at Ovejas Negras, a highly acclaimed restaurant in the heart of Seville."
          }
        ],
        "location": "Madrid, Seville"
      },
      {
        "DATE": "2024-10-05",
        "SCHEDULE": [
          {
            "TIME_START": "09:30",
            "TIME_END": "11:30",
            "NAME": "Explore Seville Cathedral and Giralda Tower",
            "COORDINATES": "37.3887, -6.0009",
            "TYPE": "activity",
            "PLACE_NAME": "Seville Cathedral and Giralda Tower, Seville",
            "DESCRIPTION": "Visit the magnificent Seville Cathedral, the largest Gothic cathedral in the world, and climb the iconic Giralda Tower for panoramic views."
          },
          {
            "TIME_START": "12:00",
            "TIME_END": "14:00",
            "NAME": "Lunch at Bodega Santa Cruz",
            "COORDINATES": "37.3871, -5.9942",
            "TYPE": "activity",
            "PLACE_NAME": "Bodega Santa Cruz, Seville",
            "DESCRIPTION": "Savor traditional Sevillian tapas and wines at Bodega Santa Cruz, a charming bodega in the heart of the Santa Cruz neighborhood."
          },
          {
            "TIME_START": "14:30",
            "TIME_END": "16:30",
            "NAME": "Explore Plaza de España",
            "COORDINATES": "37.3763, -5.9933",
            "TYPE": "activity",
            "PLACE_NAME": "Plaza de España, Seville",
            "DESCRIPTION": "Admire the stunning Renaissance Revival architecture and intricate tilework of the Plaza de España, a iconic landmark in Seville."
          },
          {
            "TIME_START": "17:00",
            "TIME_END": "19:00",
            "NAME": "Flamenco Show at Casa del Flamenco",
            "COORDINATES": "37.3891, -5.9929",
            "TYPE": "activity",
            "PLACE_NAME": "Casa del Flamenco, Seville",
            "DESCRIPTION": "Experience the passion and energy of a captivating Flamenco show at Casa del Flamenco, a renowned venue in Seville."
          }
        ],
        "location": "Seville"
      },
      {
        "DATE": "2024-10-06",
        "SCHEDULE": [
          {
            "TIME_START": "09:00",
            "TIME_END": "11:00",
            "NAME": "Visit Barrio Santa Cruz",
            "COORDINATES": "37.3871, -5.9942",
            "TYPE": "activity",
            "PLACE_NAME": "Barrio Santa Cruz, Seville",
            "DESCRIPTION": "Wander through the charming streets of Barrio Santa Cruz, the former Jewish quarter, and soak in the vibrant atmosphere."
          },
          {
            "TIME_START": "11:30",
            "TIME_END": "13:30",
            "NAME": "Lunch at Ovejas Negras",
            "COORDINATES": "37.3894, -5.9929",
            "TYPE": "activity",
            "PLACE_NAME": "Ovejas Negras, Seville",
            "DESCRIPTION": "Revisit Ovejas Negras for another delightful culinary experience, savoring the flavors of Andalusia."
          },
          {
            "TIME_START": "14:00",
            "TIME_END": "16:00",
            "NAME": "Visit Museo de Bellas Artes",
            "COORDINATES": "37.3879, -5.9932",
            "TYPE": "activity",
            "PLACE_NAME": "Museo de Bellas Artes, Seville",
            "DESCRIPTION": "Explore the impressive collection of Spanish art, including works by Velázquez and Murillo, at the Museo de Bellas Artes."
          },
          {
            "TIME_START": "16:30",
            "TIME_END": "18:30",
            "NAME": "Relax at Parque de María Luisa",
            "COORDINATES": "37.3763, -5.9933",
            "TYPE": "activity",
            "PLACE_NAME": "Parque de María Luisa, Seville",
            "DESCRIPTION": "Unwind in the beautiful Parque de María Luisa, a lush green oasis featuring stunning plazas, fountains, and monuments."
          }
        ],
        "location": "Seville"
      },
      {
        "DATE": "2024-10-07",
        "SCHEDULE": [
          {
            "TIME_START": "09:00",
            "TIME_END": "11:00",
            "NAME": "Visit Metropol Parasol",
            "COORDINATES": "37.3902, -6.0001",
            "TYPE": "activity",
            "PLACE_NAME": "Metropol Parasol, Seville",
            "DESCRIPTION": "Explore the unique Metropol Parasol, a modern architectural marvel featuring a walkway with panoramic views of Seville."
          },
          {
            "TIME_START": "11:30",
            "TIME_END": "13:30",
            "NAME": "Lunch at Bodeguita Romero",
            "COORDINATES": "37.3891, -5.9929",
            "TYPE": "activity",
            "PLACE_NAME": "Bodeguita Romero, Seville",
            "DESCRIPTION": "Indulge in a delicious farewell lunch at Bodeguita Romero, a cozy bodega serving traditional Sevillian tapas and wines."
          },
          {
            "TIME_START": "14:00",
            "TIME_END": "16:00",
            "NAME": "Shopping at Calle Sierpes",
            "COORDINATES": "37.3901, -5.9952",
            "TYPE": "activity",
            "PLACE_NAME": "Calle Sierpes, Seville",
            "DESCRIPTION": "Stroll along Calle Sierpes, the main shopping street in Seville, and pick up souvenirs or local crafts."
          },
          {
            "TIME_START": "18:00",
            "TIME_END": "20:00",
            "NAME": "Transfer to Seville Airport",
            "COORDINATES": "37.4185, -5.8932",
            "TYPE": "transfer",
            "DESCRIPTION": "Depart for Seville Airport (SVQ) to catch your flight, allowing ample time for check-in and security procedures."
          },
          {
            "TIME_START": "22:55",
            "TIME_END": "",
            "NAME": "Transfer from Seville, Spain",
            "TYPE": "inter_city_transfer",
            "DESCRIPTION": "Depart for your journey to your next destination",
            "TRANSPORT": "FLIGHT"
          }
        ],
        "location": "Seville"
      }
    ],
    "oldDayPlansForServer": [
      {
        "WRAP": {
          "DAYS": [
            {
              "DATE": "2024-09-22",
              "SCHEDULE": [
                {
                  "TIME_START": "17:00",
                  "TIME_END": "18:00",
                  "NAME": "Arrival at Barcelona Airport",
                  "COORDINATES": "41.2974, 2.0833",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Take a taxi or airport shuttle from Barcelona-El Prat Airport to your accommodation in the city center."
                },
                {
                  "TIME_START": "19:00",
                  "TIME_END": "21:00",
                  "NAME": "Dinner at La Paradeta",
                  "COORDINATES": "41.3805, 2.1762",
                  "TYPE": "activity",
                  "PLACE_NAME": "La Paradeta, Barcelona",
                  "DESCRIPTION": "Indulge in delectable seafood dishes at La Paradeta, a renowned restaurant located in the heart of the Gothic Quarter."
                },
                {
                  "TIME_START": "21:30",
                  "TIME_END": "23:30",
                  "NAME": "Evening Stroll at La Barceloneta Beach",
                  "COORDINATES": "41.3763, 2.1932",
                  "TYPE": "activity",
                  "PLACE_NAME": "La Barceloneta Beach, Barcelona",
                  "DESCRIPTION": "Take a leisurely evening stroll along the iconic La Barceloneta Beach, soaking in the vibrant atmosphere and enjoying the sea breeze."
                }
              ]
            },
            {
              "DATE": "2024-09-23",
              "SCHEDULE": [
                {
                  "TIME_START": "09:00",
                  "TIME_END": "11:00",
                  "NAME": "Tour of Camp Nou",
                  "COORDINATES": "41.3809, 2.1228",
                  "TYPE": "activity",
                  "PLACE_NAME": "Camp Nou, Barcelona",
                  "DESCRIPTION": "Explore the iconic Camp Nou Stadium, the home of FC Barcelona, and immerse yourself in its rich history and grandeur."
                },
                {
                  "TIME_START": "11:30",
                  "TIME_END": "13:30",
                  "NAME": "Lunch at Bogatell Beach",
                  "COORDINATES": "41.4015, 2.2058",
                  "TYPE": "activity",
                  "PLACE_NAME": "Bogatell Beach, Barcelona",
                  "DESCRIPTION": "Enjoy a relaxing lunch at one of the beachside restaurants along Bogatell Beach, savoring the local cuisine and soaking up the sun."
                },
                {
                  "TIME_START": "14:00",
                  "TIME_END": "16:00",
                  "NAME": "Visit La Sagrada Familia",
                  "COORDINATES": "41.4036, 2.1744",
                  "TYPE": "activity",
                  "PLACE_NAME": "La Sagrada Familia, Barcelona",
                  "DESCRIPTION": "Marvel at the architectural brilliance of La Sagrada Familia, a UNESCO World Heritage Site and Gaudi's masterpiece."
                },
                {
                  "TIME_START": "16:30",
                  "TIME_END": "18:30",
                  "NAME": "Explore Park Güell",
                  "COORDINATES": "41.4145, 2.1527",
                  "TYPE": "activity",
                  "PLACE_NAME": "Park Güell, Barcelona",
                  "DESCRIPTION": "Wander through the whimsical Park Güell, another of Gaudi's iconic creations, offering panoramic views of the city."
                }
              ]
            },
            {
              "DATE": "2024-09-24",
              "SCHEDULE": [
                {
                  "TIME_START": "09:30",
                  "TIME_END": "11:30",
                  "NAME": "Visit La Boqueria Market",
                  "COORDINATES": "41.3818, 2.1714",
                  "TYPE": "activity",
                  "PLACE_NAME": "La Boqueria Market, Barcelona",
                  "DESCRIPTION": "Explore the vibrant La Boqueria Market, a foodie's paradise, and indulge in a variety of fresh local foods and drinks."
                },
                {
                  "TIME_START": "12:00",
                  "TIME_END": "14:00",
                  "NAME": "Lunch at Els Quatre Gats",
                  "COORDINATES": "41.3846, 2.1726",
                  "TYPE": "activity",
                  "PLACE_NAME": "Els Quatre Gats, Barcelona",
                  "DESCRIPTION": "Savor a traditional Catalan lunch at Els Quatre Gats, a historic restaurant frequented by famous artists like Picasso and Gaudí."
                },
                {
                  "TIME_START": "14:30",
                  "TIME_END": "17:30",
                  "NAME": "Explore Gothic Quarter",
                  "COORDINATES": "41.3834, 2.1760",
                  "TYPE": "activity",
                  "PLACE_NAME": "Gothic Quarter, Barcelona",
                  "DESCRIPTION": "Wander through the Gothic Quarter's narrow medieval streets filled with shops, bars, and historic buildings, immersing yourself in the city's rich cultural heritage."
                },
                {
                  "TIME_START": "18:00",
                  "TIME_END": "20:00",
                  "NAME": "Dinner at Tickets Bar",
                  "COORDINATES": "41.3924, 2.1949",
                  "TYPE": "activity",
                  "PLACE_NAME": "Tickets Bar, Barcelona",
                  "DESCRIPTION": "Indulge in an innovative and avant-garde dining experience at Tickets Bar, a culinary hotspot by the renowned Adrià brothers."
                }
              ]
            },
            {
              "DATE": "2024-09-25",
              "SCHEDULE": [
                {
                  "TIME_START": "06:00",
                  "TIME_END": "07:00",
                  "NAME": "Departure Transfer",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Arrange for a taxi or private transfer to take you from your accommodation to Barcelona Airport for your departure."
                }
              ]
            }
          ]
        },
        "LOCATION": "Barcelona, Spain"
      },
      {
        "WRAP": {
          "DAYS": [
            {
              "DATE": "2024-09-25",
              "SCHEDULE": [
                {
                  "TIME_START": "09:00",
                  "TIME_END": "15:00",
                  "NAME": "Depart for Marbella, Spain",
                  "TYPE": "inter_city_transfer",
                  "DESCRIPTION": "Depart for your journey to Marbella, Spain. The travel time by CAR is 6 hours",
                  "TRANSPORT": "CAR"
                }
              ]
            }
          ]
        },
        "LOCATION": "inter_city_transfer"
      },
      {
        "WRAP": {
          "DAYS": [
            {
              "DATE": "2024-09-25",
              "SCHEDULE": [
                {
                  "TIME_START": "15:00",
                  "TIME_END": "17:00",
                  "NAME": "Arrival in Marbella",
                  "COORDINATES": "36.5106, -4.8845",
                  "TYPE": "activity",
                  "PLACE_NAME": "Marbella",
                  "DESCRIPTION": "Relax and unwind after your journey to the beautiful coastal city of Marbella."
                },
                {
                  "TIME_START": "17:30",
                  "TIME_END": "19:30",
                  "NAME": "Explore Old Town",
                  "COORDINATES": "36.5135, -4.8903",
                  "TYPE": "activity",
                  "PLACE_NAME": "Old Town, Marbella",
                  "DESCRIPTION": "Stroll through the charming Old Town, admiring the whitewashed buildings, narrow streets, and vibrant plazas."
                },
                {
                  "TIME_START": "20:00",
                  "TIME_END": "22:00",
                  "NAME": "Dinner at Casanis",
                  "COORDINATES": "36.5135, -4.8903",
                  "TYPE": "activity",
                  "PLACE_NAME": "Casanis, Marbella",
                  "DESCRIPTION": "Indulge in delectable Mediterranean cuisine at Casanis, a renowned restaurant in the heart of the Old Town."
                }
              ]
            },
            {
              "DATE": "2024-09-26",
              "SCHEDULE": [
                {
                  "TIME_START": "10:00",
                  "TIME_END": "13:00",
                  "NAME": "Beach Day at Playa de Cabopino",
                  "COORDINATES": "36.4767, -4.7367",
                  "TYPE": "activity",
                  "PLACE_NAME": "Playa de Cabopino, Marbella",
                  "DESCRIPTION": "Spend a relaxing day at the beautiful Playa de Cabopino, soaking up the sun and enjoying the crystal-clear waters."
                },
                {
                  "TIME_START": "13:30",
                  "TIME_END": "15:30",
                  "NAME": "Lunch at Chiringuito Playa Cabopino",
                  "COORDINATES": "36.4767, -4.7367",
                  "TYPE": "activity",
                  "PLACE_NAME": "Chiringuito Playa Cabopino, Marbella",
                  "DESCRIPTION": "Savor fresh seafood and local specialties at Chiringuito Playa Cabopino, a beachside restaurant with stunning views."
                },
                {
                  "TIME_START": "16:00",
                  "TIME_END": "18:00",
                  "NAME": "Visit Museo Ralli",
                  "COORDINATES": "36.5135, -4.8903",
                  "TYPE": "activity",
                  "PLACE_NAME": "Museo Ralli, Marbella",
                  "DESCRIPTION": "Explore the Museo Ralli, a renowned museum showcasing an impressive collection of contemporary Latin American art."
                }
              ]
            },
            {
              "DATE": "2024-09-27",
              "SCHEDULE": [
                {
                  "TIME_START": "10:00",
                  "TIME_END": "13:00",
                  "NAME": "Day Trip to Ronda",
                  "COORDINATES": "36.7425, -5.1677",
                  "TYPE": "activity",
                  "PLACE_NAME": "Ronda, Spain",
                  "DESCRIPTION": "Embark on a day trip to the picturesque town of Ronda, known for its stunning bridge spanning the El Tajo gorge."
                },
                {
                  "TIME_START": "13:30",
                  "TIME_END": "15:30",
                  "NAME": "Lunch at Tragatapas",
                  "COORDINATES": "36.7425, -5.1677",
                  "TYPE": "activity",
                  "PLACE_NAME": "Tragatapas, Ronda",
                  "DESCRIPTION": "Enjoy a delightful tapas lunch at Tragatapas, a local favorite in the heart of Ronda."
                },
                {
                  "TIME_START": "16:00",
                  "TIME_END": "18:00",
                  "NAME": "Relax at Playa de Guadalmina",
                  "COORDINATES": "36.4833, -4.9333",
                  "TYPE": "activity",
                  "PLACE_NAME": "Playa de Guadalmina, Marbella",
                  "DESCRIPTION": "Unwind at the beautiful Playa de Guadalmina, a tranquil beach perfect for soaking up the last rays of the day."
                }
              ]
            },
            {
              "DATE": "2024-09-28",
              "SCHEDULE": [
                {
                  "TIME_START": "07:00",
                  "TIME_END": "09:00",
                  "NAME": "Departure from Marbella",
                  "COORDINATES": "36.5106, -4.8845",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Depart from Marbella, carrying with you cherished memories of this beautiful coastal city."
                },
                {
                  "TIME_START": "10:00",
                  "TIME_END": "16:00",
                  "NAME": "Depart for Malaga, Spain",
                  "TYPE": "inter_city_transfer",
                  "DESCRIPTION": "Depart for your journey to Malaga, Spain. The travel time by CAR is 6 hours",
                  "TRANSPORT": "CAR"
                }
              ]
            }
          ]
        },
        "LOCATION": "Marbella, Spain"
      },
      {
        "WRAP": {
          "DAYS": [
            {
              "DATE": "2024-09-28",
              "SCHEDULE": [
                {
                  "TIME_START": "16:00",
                  "TIME_END": "18:00",
                  "NAME": "Arrival in Malaga",
                  "COORDINATES": "36.7196, -4.4203",
                  "TYPE": "activity",
                  "PLACE_NAME": "Malaga City Center",
                  "DESCRIPTION": "After arriving in Malaga, take some time to relax and freshen up at your accommodation."
                },
                {
                  "TIME_START": "19:00",
                  "TIME_END": "21:00",
                  "NAME": "Dinner at El Pimpi",
                  "COORDINATES": "36.7210, -4.4179",
                  "TYPE": "activity",
                  "PLACE_NAME": "El Pimpi, Malaga",
                  "DESCRIPTION": "Experience the vibrant atmosphere and delicious local cuisine at El Pimpi, a renowned restaurant in the heart of Malaga."
                }
              ]
            },
            {
              "DATE": "2024-09-29",
              "SCHEDULE": [
                {
                  "TIME_START": "10:00",
                  "TIME_END": "13:00",
                  "NAME": "Explore Alcazaba and Roman Theatre",
                  "COORDINATES": "36.7218, -4.4175",
                  "TYPE": "activity",
                  "PLACE_NAME": "Alcazaba and Roman Theatre, Malaga",
                  "DESCRIPTION": "Immerse yourself in the rich cultural heritage of Malaga by visiting the Alcazaba, a Moorish fortress, and the adjacent Roman Theatre."
                },
                {
                  "TIME_START": "13:30",
                  "TIME_END": "15:00",
                  "NAME": "Lunch at El Cortijo de Pepe",
                  "COORDINATES": "36.7193, -4.4211",
                  "TYPE": "activity",
                  "PLACE_NAME": "El Cortijo de Pepe, Malaga",
                  "DESCRIPTION": "Savor the authentic flavors of Andalusian cuisine at El Cortijo de Pepe, a local favorite for traditional Spanish dishes."
                },
                {
                  "TIME_START": "15:30",
                  "TIME_END": "18:30",
                  "NAME": "Relax at Malagueta Beach",
                  "COORDINATES": "36.7095, -4.4193",
                  "TYPE": "activity",
                  "PLACE_NAME": "Malagueta Beach, Malaga",
                  "DESCRIPTION": "Spend the afternoon unwinding on the golden sands of Malagueta Beach, one of Malaga's most popular urban beaches."
                }
              ]
            },
            {
              "DATE": "2024-09-30",
              "SCHEDULE": [
                {
                  "TIME_START": "09:30",
                  "TIME_END": "11:30",
                  "NAME": "Visit Picasso Museum",
                  "COORDINATES": "36.7227, -4.4181",
                  "TYPE": "activity",
                  "PLACE_NAME": "Picasso Museum, Malaga",
                  "DESCRIPTION": "Explore the birthplace of Pablo Picasso and discover his early works at the Picasso Museum, housed in a beautiful Renaissance-style palace."
                },
                {
                  "TIME_START": "12:00",
                  "TIME_END": "14:00",
                  "NAME": "Lunch at El Tapeo de Cervantes",
                  "COORDINATES": "36.7227, -4.4200",
                  "TYPE": "activity",
                  "PLACE_NAME": "El Tapeo de Cervantes, Malaga",
                  "DESCRIPTION": "Indulge in a delightful tapas experience at El Tapeo de Cervantes, a cozy restaurant known for its creative and flavorful small plates."
                },
                {
                  "TIME_START": "14:30",
                  "TIME_END": "17:30",
                  "NAME": "Explore Gibralfaro Hill and Castillo de Gibralfaro",
                  "COORDINATES": "36.7163, -4.4025",
                  "TYPE": "activity",
                  "PLACE_NAME": "Gibralfaro Hill and Castillo de Gibralfaro, Malaga",
                  "DESCRIPTION": "Hike up Gibralfaro Hill for panoramic views of Malaga and visit the Castillo de Gibralfaro, a 14th-century Moorish fortress."
                }
              ]
            },
            {
              "DATE": "2024-10-01",
              "SCHEDULE": [
                {
                  "TIME_START": "09:00",
                  "TIME_END": "11:00",
                  "NAME": "Explore Malaga Cathedral",
                  "COORDINATES": "36.7227, -4.4203",
                  "TYPE": "activity",
                  "PLACE_NAME": "Malaga Cathedral, Malaga",
                  "DESCRIPTION": "Marvel at the impressive architecture of Malaga Cathedral, also known as the 'One-Armed Lady,' a stunning Renaissance-style cathedral."
                },
                {
                  "TIME_START": "11:30",
                  "TIME_END": "12:35",
                  "NAME": "Transfer to Malaga Airport",
                  "COORDINATES": "36.6749, -4.4989",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Depart for Malaga Airport (AGP) to catch your flight."
                },
                {
                  "TIME_START": "12:35",
                  "TIME_END": "13:45",
                  "NAME": "Depart for Madrid, Spain",
                  "TYPE": "inter_city_transfer",
                  "DESCRIPTION": "Depart for your journey to Madrid, Spain. The travel time by FLIGHT is 1 hours and 10 minutes",
                  "TRANSPORT": "FLIGHT"
                }
              ]
            }
          ]
        },
        "LOCATION": "Malaga, Spain"
      },
      {
        "WRAP": {
          "DAYS": [
            {
              "DATE": "2024-10-01",
              "SCHEDULE": [
                {
                  "TIME_START": "13:45",
                  "TIME_END": "14:30",
                  "NAME": "Arrival at Madrid Airport",
                  "COORDINATES": "40.4722, -3.5611",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Take a taxi or airport shuttle from Madrid-Barajas Airport to your accommodation in the city center."
                },
                {
                  "TIME_START": "15:30",
                  "TIME_END": "17:30",
                  "NAME": "Visit Puerta del Sol",
                  "COORDINATES": "40.4167, -3.7038",
                  "TYPE": "activity",
                  "PLACE_NAME": "Puerta del Sol, Madrid",
                  "DESCRIPTION": "Explore the iconic Puerta del Sol, the bustling heart of Madrid, and soak in the vibrant atmosphere."
                },
                {
                  "TIME_START": "18:00",
                  "TIME_END": "20:00",
                  "NAME": "Dinner at Sobrino de Botín",
                  "COORDINATES": "40.4135, -3.7122",
                  "TYPE": "activity",
                  "PLACE_NAME": "Sobrino de Botín, Madrid",
                  "DESCRIPTION": "Indulge in a delectable dinner at Sobrino de Botín, the world's oldest restaurant, renowned for its traditional Spanish cuisine."
                }
              ]
            },
            {
              "DATE": "2024-10-02",
              "SCHEDULE": [
                {
                  "TIME_START": "09:00",
                  "TIME_END": "11:00",
                  "NAME": "Tour of Santiago Bernabéu Stadium",
                  "COORDINATES": "40.4532, -3.6885",
                  "TYPE": "activity",
                  "PLACE_NAME": "Santiago Bernabéu Stadium, Madrid",
                  "DESCRIPTION": "Explore the iconic Santiago Bernabéu Stadium, home of Real Madrid, and learn about its rich football history."
                },
                {
                  "TIME_START": "11:30",
                  "TIME_END": "13:30",
                  "NAME": "Visit Palacio Real",
                  "COORDINATES": "40.4167, -3.7138",
                  "TYPE": "activity",
                  "PLACE_NAME": "Palacio Real, Madrid",
                  "DESCRIPTION": "Marvel at the grandeur of the Palacio Real, the official residence of the Spanish Royal Family, and admire its stunning architecture."
                },
                {
                  "TIME_START": "14:00",
                  "TIME_END": "16:00",
                  "NAME": "Lunch at Mercado de San Miguel",
                  "COORDINATES": "40.4153, -3.7083",
                  "TYPE": "activity",
                  "PLACE_NAME": "Mercado de San Miguel, Madrid",
                  "DESCRIPTION": "Explore the vibrant Mercado de San Miguel and savor a variety of delicious tapas and local delicacies."
                },
                {
                  "TIME_START": "16:30",
                  "TIME_END": "18:30",
                  "NAME": "Visit Museo del Prado",
                  "COORDINATES": "40.4137, -3.6922",
                  "TYPE": "activity",
                  "PLACE_NAME": "Museo del Prado, Madrid",
                  "DESCRIPTION": "Immerse yourself in the world-renowned art collection at the Museo del Prado, one of the finest art museums in the world."
                }
              ]
            },
            {
              "DATE": "2024-10-03",
              "SCHEDULE": [
                {
                  "TIME_START": "09:30",
                  "TIME_END": "11:30",
                  "NAME": "Explore Plaza Mayor",
                  "COORDINATES": "40.4151, -3.7071",
                  "TYPE": "activity",
                  "PLACE_NAME": "Plaza Mayor, Madrid",
                  "DESCRIPTION": "Discover the iconic Plaza Mayor, a stunning square surrounded by historic buildings and bustling with street performers."
                },
                {
                  "TIME_START": "12:00",
                  "TIME_END": "14:00",
                  "NAME": "Lunch at La Huerta de Berlín",
                  "COORDINATES": "40.4236, -3.6924",
                  "TYPE": "activity",
                  "PLACE_NAME": "La Huerta de Berlín, Madrid",
                  "DESCRIPTION": "Enjoy a delightful lunch at La Huerta de Berlín, a charming restaurant known for its traditional Spanish cuisine and cozy ambiance."
                },
                {
                  "TIME_START": "14:30",
                  "TIME_END": "16:30",
                  "NAME": "Visit Parque del Retiro",
                  "COORDINATES": "40.4151, -3.6831",
                  "TYPE": "activity",
                  "PLACE_NAME": "Parque del Retiro, Madrid",
                  "DESCRIPTION": "Relax and unwind in the beautiful Parque del Retiro, Madrid's iconic park, and enjoy its lush gardens and monuments."
                },
                {
                  "TIME_START": "17:00",
                  "TIME_END": "19:00",
                  "NAME": "Explore Gran Vía",
                  "COORDINATES": "40.4202, -3.7051",
                  "TYPE": "activity",
                  "PLACE_NAME": "Gran Vía, Madrid",
                  "DESCRIPTION": "Stroll along the vibrant Gran Vía, Madrid's famous shopping and entertainment district, and experience its lively atmosphere."
                }
              ]
            },
            {
              "DATE": "2024-10-04",
              "SCHEDULE": [
                {
                  "TIME_START": "08:00",
                  "TIME_END": "08:30",
                  "NAME": "Departure from Madrid",
                  "COORDINATES": "40.4722, -3.5611",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Depart from your accommodation and head to the airport or your next destination by car."
                },
                {
                  "TIME_START": "08:00",
                  "TIME_END": "14:00",
                  "NAME": "Depart for Seville, Spain",
                  "TYPE": "inter_city_transfer",
                  "DESCRIPTION": "Depart for your journey to Seville, Spain. The travel time by CAR is 6 hours",
                  "TRANSPORT": "CAR"
                }
              ]
            }
          ]
        },
        "LOCATION": "Madrid, Spain"
      },
      {
        "WRAP": {
          "DAYS": [
            {
              "DATE": "2024-10-04",
              "SCHEDULE": [
                {
                  "TIME_START": "14:00",
                  "TIME_END": "16:00",
                  "NAME": "Arrival in Seville",
                  "COORDINATES": "37.3891, -5.9845",
                  "TYPE": "activity",
                  "PLACE_NAME": "Seville, Spain",
                  "DESCRIPTION": "Explore the vibrant city of Seville, known for its rich cultural heritage and stunning architecture."
                },
                {
                  "TIME_START": "16:30",
                  "TIME_END": "18:30",
                  "NAME": "Visit Real Alcázar",
                  "COORDINATES": "37.3838, -5.9953",
                  "TYPE": "activity",
                  "PLACE_NAME": "Real Alcázar, Seville",
                  "DESCRIPTION": "Marvel at the stunning Mudéjar architecture and lush gardens of the Real Alcázar, a UNESCO World Heritage Site."
                },
                {
                  "TIME_START": "19:00",
                  "TIME_END": "21:00",
                  "NAME": "Dinner at Ovejas Negras",
                  "COORDINATES": "37.3894, -5.9929",
                  "TYPE": "activity",
                  "PLACE_NAME": "Ovejas Negras, Seville",
                  "DESCRIPTION": "Indulge in delectable Andalusian cuisine at Ovejas Negras, a highly acclaimed restaurant in the heart of Seville."
                }
              ]
            },
            {
              "DATE": "2024-10-05",
              "SCHEDULE": [
                {
                  "TIME_START": "09:30",
                  "TIME_END": "11:30",
                  "NAME": "Explore Seville Cathedral and Giralda Tower",
                  "COORDINATES": "37.3887, -6.0009",
                  "TYPE": "activity",
                  "PLACE_NAME": "Seville Cathedral and Giralda Tower, Seville",
                  "DESCRIPTION": "Visit the magnificent Seville Cathedral, the largest Gothic cathedral in the world, and climb the iconic Giralda Tower for panoramic views."
                },
                {
                  "TIME_START": "12:00",
                  "TIME_END": "14:00",
                  "NAME": "Lunch at Bodega Santa Cruz",
                  "COORDINATES": "37.3871, -5.9942",
                  "TYPE": "activity",
                  "PLACE_NAME": "Bodega Santa Cruz, Seville",
                  "DESCRIPTION": "Savor traditional Sevillian tapas and wines at Bodega Santa Cruz, a charming bodega in the heart of the Santa Cruz neighborhood."
                },
                {
                  "TIME_START": "14:30",
                  "TIME_END": "16:30",
                  "NAME": "Explore Plaza de España",
                  "COORDINATES": "37.3763, -5.9933",
                  "TYPE": "activity",
                  "PLACE_NAME": "Plaza de España, Seville",
                  "DESCRIPTION": "Admire the stunning Renaissance Revival architecture and intricate tilework of the Plaza de España, a iconic landmark in Seville."
                },
                {
                  "TIME_START": "17:00",
                  "TIME_END": "19:00",
                  "NAME": "Flamenco Show at Casa del Flamenco",
                  "COORDINATES": "37.3891, -5.9929",
                  "TYPE": "activity",
                  "PLACE_NAME": "Casa del Flamenco, Seville",
                  "DESCRIPTION": "Experience the passion and energy of a captivating Flamenco show at Casa del Flamenco, a renowned venue in Seville."
                }
              ]
            },
            {
              "DATE": "2024-10-06",
              "SCHEDULE": [
                {
                  "TIME_START": "09:00",
                  "TIME_END": "11:00",
                  "NAME": "Visit Barrio Santa Cruz",
                  "COORDINATES": "37.3871, -5.9942",
                  "TYPE": "activity",
                  "PLACE_NAME": "Barrio Santa Cruz, Seville",
                  "DESCRIPTION": "Wander through the charming streets of Barrio Santa Cruz, the former Jewish quarter, and soak in the vibrant atmosphere."
                },
                {
                  "TIME_START": "11:30",
                  "TIME_END": "13:30",
                  "NAME": "Lunch at Ovejas Negras",
                  "COORDINATES": "37.3894, -5.9929",
                  "TYPE": "activity",
                  "PLACE_NAME": "Ovejas Negras, Seville",
                  "DESCRIPTION": "Revisit Ovejas Negras for another delightful culinary experience, savoring the flavors of Andalusia."
                },
                {
                  "TIME_START": "14:00",
                  "TIME_END": "16:00",
                  "NAME": "Visit Museo de Bellas Artes",
                  "COORDINATES": "37.3879, -5.9932",
                  "TYPE": "activity",
                  "PLACE_NAME": "Museo de Bellas Artes, Seville",
                  "DESCRIPTION": "Explore the impressive collection of Spanish art, including works by Velázquez and Murillo, at the Museo de Bellas Artes."
                },
                {
                  "TIME_START": "16:30",
                  "TIME_END": "18:30",
                  "NAME": "Relax at Parque de María Luisa",
                  "COORDINATES": "37.3763, -5.9933",
                  "TYPE": "activity",
                  "PLACE_NAME": "Parque de María Luisa, Seville",
                  "DESCRIPTION": "Unwind in the beautiful Parque de María Luisa, a lush green oasis featuring stunning plazas, fountains, and monuments."
                }
              ]
            },
            {
              "DATE": "2024-10-07",
              "SCHEDULE": [
                {
                  "TIME_START": "09:00",
                  "TIME_END": "11:00",
                  "NAME": "Visit Metropol Parasol",
                  "COORDINATES": "37.3902, -6.0001",
                  "TYPE": "activity",
                  "PLACE_NAME": "Metropol Parasol, Seville",
                  "DESCRIPTION": "Explore the unique Metropol Parasol, a modern architectural marvel featuring a walkway with panoramic views of Seville."
                },
                {
                  "TIME_START": "11:30",
                  "TIME_END": "13:30",
                  "NAME": "Lunch at Bodeguita Romero",
                  "COORDINATES": "37.3891, -5.9929",
                  "TYPE": "activity",
                  "PLACE_NAME": "Bodeguita Romero, Seville",
                  "DESCRIPTION": "Indulge in a delicious farewell lunch at Bodeguita Romero, a cozy bodega serving traditional Sevillian tapas and wines."
                },
                {
                  "TIME_START": "14:00",
                  "TIME_END": "16:00",
                  "NAME": "Shopping at Calle Sierpes",
                  "COORDINATES": "37.3901, -5.9952",
                  "TYPE": "activity",
                  "PLACE_NAME": "Calle Sierpes, Seville",
                  "DESCRIPTION": "Stroll along Calle Sierpes, the main shopping street in Seville, and pick up souvenirs or local crafts."
                },
                {
                  "TIME_START": "18:00",
                  "TIME_END": "20:00",
                  "NAME": "Transfer to Seville Airport",
                  "COORDINATES": "37.4185, -5.8932",
                  "TYPE": "transfer",
                  "DESCRIPTION": "Depart for Seville Airport (SVQ) to catch your flight, allowing ample time for check-in and security procedures."
                },
                {
                  "TIME_START": "22:55",
                  "TIME_END": "",
                  "NAME": "Transfer from Seville, Spain",
                  "TYPE": "inter_city_transfer",
                  "DESCRIPTION": "Depart for your journey to your next destination",
                  "TRANSPORT": "FLIGHT"
                }
              ]
            }
          ]
        },
        "LOCATION": "Seville, Spain"
      }
    ],
    "active_tab_index": 2,
    "bestTabIndex": 2,
    "sort_option": "Best",
    "flightFormCollapisbleOpen": false,
    "chosen_option": -1,
    "maxPossibleFlightPrice": 0,
    "minPossibleFlightPrice": null,
    "selectedFlight": {
      "allPassengerTotalPrice": 814,
      "legs": [
        {
          "airlines": [
            "LEVELSPAIN"
          ],
          "arrivalDate": "2024-09-22",
          "arrivalTime": "17:00",
          "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
          "dateChange": 1,
          "departureDate": "2024-09-21",
          "departureTime": "20:10",
          "destination": "BCN",
          "duration": "11h50m",
          "routes": [
            {
              "airline": "VY",
              "bags_recheck_required": false,
              "cityCodeFrom": "SFO",
              "cityCodeTo": "BCN",
              "cityFrom": "San Francisco",
              "cityTo": "Barcelona",
              "combination_id": "0f9c01af4e130000b8df6af8",
              "equipment": null,
              "fare_basis": "QIL2C3WN",
              "fare_category": "M",
              "fare_classes": "Q",
              "flight_no": 9792,
              "flyFrom": "SFO",
              "flyTo": "BCN",
              "guarantee": false,
              "id": "0f9c01af4e130000b8df6af8_0",
              "local_arrival": "2024-09-22T17:00:00.000Z",
              "local_departure": "2024-09-21T20:10:00.000Z",
              "operating_carrier": "LEVELSPAIN",
              "operating_flight_no": "",
              "return": 0,
              "utc_arrival": "2024-09-22T15:00:00.000Z",
              "utc_departure": "2024-09-22T03:10:00.000Z",
              "vehicle_type": "aircraft",
              "vi_connection": false
            }
          ],
          "source": "SFO",
          "stops": 0
        },
        {
          "airlines": [
            "UX"
          ],
          "arrivalDate": "2024-10-01",
          "arrivalTime": "13:45",
          "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
          "dateChange": 0,
          "departureDate": "2024-10-01",
          "departureTime": "12:35",
          "destination": "MAD",
          "duration": "1h10m",
          "routes": [
            {
              "airline": "UX",
              "bags_recheck_required": false,
              "cityCodeFrom": "AGP",
              "cityCodeTo": "MAD",
              "cityFrom": "Málaga",
              "cityTo": "Madrid",
              "combination_id": "14140a224e1d000064176280",
              "equipment": null,
              "fare_basis": "ZDYO4L",
              "fare_category": "M",
              "fare_classes": "M",
              "flight_no": 5036,
              "flyFrom": "AGP",
              "flyTo": "MAD",
              "guarantee": false,
              "id": "14140a224e1d000064176280_0",
              "local_arrival": "2024-10-01T13:45:00.000Z",
              "local_departure": "2024-10-01T12:35:00.000Z",
              "operating_carrier": "UX",
              "operating_flight_no": "5036",
              "return": 0,
              "utc_arrival": "2024-10-01T11:45:00.000Z",
              "utc_departure": "2024-10-01T10:35:00.000Z",
              "vehicle_type": "aircraft",
              "vi_connection": false
            }
          ],
          "source": "AGP",
          "stops": 0
        },
        {
          "airlines": [
            "VY",
            "LEVELSPAIN"
          ],
          "arrivalDate": "2024-10-08",
          "arrivalTime": "18:10",
          "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
          "dateChange": 1,
          "departureDate": "2024-10-07",
          "departureTime": "22:55",
          "destination": "SFO",
          "duration": "28h15m",
          "routes": [
            {
              "airline": "VY",
              "bags_recheck_required": false,
              "cityCodeFrom": "SVQ",
              "cityCodeTo": "BCN",
              "cityFrom": "Seville",
              "cityTo": "Barcelona",
              "combination_id": "04b801af4e230000e00d2993",
              "equipment": null,
              "fare_basis": "OOWVYCLB",
              "fare_category": "M",
              "fare_classes": "O",
              "flight_no": 2221,
              "flyFrom": "SVQ",
              "flyTo": "BCN",
              "guarantee": false,
              "id": "04b801af4e230000e00d2993_0",
              "local_arrival": "2024-10-08T00:35:00.000Z",
              "local_departure": "2024-10-07T22:55:00.000Z",
              "operating_carrier": "VY",
              "operating_flight_no": "2221",
              "return": 0,
              "utc_arrival": "2024-10-07T22:35:00.000Z",
              "utc_departure": "2024-10-07T20:55:00.000Z",
              "vehicle_type": "aircraft",
              "vi_connection": false
            },
            {
              "airline": "VY",
              "bags_recheck_required": true,
              "cityCodeFrom": "BCN",
              "cityCodeTo": "SFO",
              "cityFrom": "Barcelona",
              "cityTo": "San Francisco",
              "combination_id": "01af0f9c4e2400007559f353",
              "equipment": null,
              "fare_basis": "QIL2A3WN",
              "fare_category": "M",
              "fare_classes": "Q",
              "flight_no": 9791,
              "flyFrom": "BCN",
              "flyTo": "SFO",
              "guarantee": true,
              "id": "01af0f9c4e2400007559f353_0",
              "local_arrival": "2024-10-08T18:10:00.000Z",
              "local_departure": "2024-10-08T14:00:00.000Z",
              "operating_carrier": "LEVELSPAIN",
              "operating_flight_no": "",
              "return": 0,
              "utc_arrival": "2024-10-09T01:10:00.000Z",
              "utc_departure": "2024-10-08T12:00:00.000Z",
              "vehicle_type": "aircraft",
              "vi_connection": true
            }
          ],
          "source": "SVQ",
          "stops": 1
        }
      ],
      "quality": 1008.35,
      "totalPrice": 814
    },
    "selectedHotels": [
      null,
      {
        "available": true,
        "hotel": {
          "chainCode": "RT",
          "cityCode": "AGP",
          "dupeId": "700183255",
          "hotelId": "RTAGPTRO",
          "latitude": 36.71866,
          "longitude": -4.4276,
          "name": "ibis budget Málaga Centro",
          "type": "hotel"
        },
        "offers": [
          {
            "boardType": "ROOM_ONLY",
            "checkInDate": "2024-09-25",
            "checkOutDate": "2024-09-28",
            "guests": {
              "adults": 1
            },
            "id": "E1THP68V5A",
            "policies": {
              "cancellations": [
                {
                  "deadline": "2024-09-24T23:59:00+02:00"
                }
              ],
              "guarantee": {
                "acceptedPayments": {
                  "creditCardPolicies": [
                    {
                      "vendorCode": "AX"
                    },
                    {
                      "vendorCode": "CA"
                    },
                    {
                      "vendorCode": "VI"
                    }
                  ],
                  "creditCards": [
                    "AX",
                    "CA",
                    "VI"
                  ],
                  "methods": [
                    "TRAVEL_AGENT_ID",
                    "CREDIT_CARD",
                    "VCC_EXTERNAL_PROVIDER"
                  ]
                }
              },
              "paymentType": "guarantee"
            },
            "price": {
              "currency": "EUR",
              "total": "322.50",
              "variations": {
                "average": {
                  "base": "107.50"
                },
                "changes": [
                  {
                    "base": "98.50",
                    "endDate": "2024-09-27",
                    "startDate": "2024-09-25",
                    "total": "98.50"
                  },
                  {
                    "base": "125.50",
                    "endDate": "2024-09-28",
                    "startDate": "2024-09-27",
                    "total": "125.50"
                  }
                ]
              }
            },
            "rateCode": "RG1",
            "rateFamilyEstimated": {
              "code": "RAC",
              "type": "P"
            },
            "room": {
              "description": {
                "lang": "EN",
                "text": "RAC GDS-RO/Room only\nRoom with double bed and single bed"
              },
              "type": "C1D",
              "typeEstimated": {
                "bedType": "DOUBLE",
                "beds": 1,
                "category": "STANDARD_ROOM"
              }
            },
            "roomQuantity": 2,
            "self": "https://api.amadeus.com/v3/shopping/hotel-offers/E1THP68V5A"
          }
        ],
        "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTAGPTRO&adults=1&checkInDate=2024-09-25&checkOutDate=2024-09-28&roomQuantity=2",
        "type": "hotel-offers"
      },
      {
        "available": true,
        "hotel": {
          "chainCode": "RT",
          "cityCode": "AGP",
          "dupeId": "700183255",
          "hotelId": "RTAGPTRO",
          "latitude": 36.71866,
          "longitude": -4.4276,
          "name": "ibis budget Málaga Centro",
          "type": "hotel"
        },
        "offers": [
          {
            "boardType": "ROOM_ONLY",
            "checkInDate": "2024-09-28",
            "checkOutDate": "2024-10-01",
            "guests": {
              "adults": 1
            },
            "id": "HMGSQ91P7P",
            "policies": {
              "cancellations": [
                {
                  "deadline": "2024-09-27T23:59:00+02:00"
                }
              ],
              "guarantee": {
                "acceptedPayments": {
                  "creditCardPolicies": [
                    {
                      "vendorCode": "AX"
                    },
                    {
                      "vendorCode": "CA"
                    },
                    {
                      "vendorCode": "VI"
                    }
                  ],
                  "creditCards": [
                    "AX",
                    "CA",
                    "VI"
                  ],
                  "methods": [
                    "TRAVEL_AGENT_ID",
                    "CREDIT_CARD",
                    "VCC_EXTERNAL_PROVIDER"
                  ]
                }
              },
              "paymentType": "guarantee"
            },
            "price": {
              "currency": "EUR",
              "total": "297.50",
              "variations": {
                "average": {
                  "base": "99.17"
                },
                "changes": [
                  {
                    "base": "125.50",
                    "endDate": "2024-09-29",
                    "startDate": "2024-09-28",
                    "total": "125.50"
                  },
                  {
                    "base": "85.50",
                    "endDate": "2024-09-30",
                    "startDate": "2024-09-29",
                    "total": "85.50"
                  },
                  {
                    "base": "86.50",
                    "endDate": "2024-10-01",
                    "startDate": "2024-09-30",
                    "total": "86.50"
                  }
                ]
              }
            },
            "rateCode": "RG1",
            "rateFamilyEstimated": {
              "code": "RAC",
              "type": "P"
            },
            "room": {
              "description": {
                "lang": "EN",
                "text": "RAC GDS-RO/Room only\nRoom with double bed and single bed"
              },
              "type": "C1D",
              "typeEstimated": {
                "bedType": "DOUBLE",
                "beds": 1,
                "category": "STANDARD_ROOM"
              }
            },
            "roomQuantity": 2,
            "self": "https://api.amadeus.com/v3/shopping/hotel-offers/HMGSQ91P7P"
          }
        ],
        "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTAGPTRO&adults=1&checkInDate=2024-09-28&checkOutDate=2024-10-01&roomQuantity=2",
        "type": "hotel-offers"
      },
      {
        "available": true,
        "hotel": {
          "chainCode": "HS",
          "cityCode": "MAD",
          "dupeId": "700221941",
          "hotelId": "HSMADAMA",
          "latitude": 40.41033,
          "longitude": -3.69462,
          "name": "Urban Sea Atocha 113",
          "type": "hotel"
        },
        "offers": [
          {
            "boardType": "ROOM_ONLY",
            "checkInDate": "2024-10-01",
            "checkOutDate": "2024-10-04",
            "commission": {
              "percentage": "4.00"
            },
            "guests": {
              "adults": 1
            },
            "id": "RVAH8HDDBK",
            "policies": {
              "cancellations": [
                {
                  "deadline": "2024-09-24T22:59:00+02:00",
                  "numberOfNights": 3
                }
              ],
              "guarantee": {
                "acceptedPayments": {
                  "creditCardPolicies": [
                    {
                      "vendorCode": "DC"
                    },
                    {
                      "vendorCode": "DN"
                    },
                    {
                      "vendorCode": "VI"
                    },
                    {
                      "vendorCode": "MC"
                    },
                    {
                      "vendorCode": "CA"
                    },
                    {
                      "vendorCode": "AX"
                    }
                  ],
                  "creditCards": [
                    "DC",
                    "DN",
                    "VI",
                    "MC",
                    "CA",
                    "AX"
                  ],
                  "methods": [
                    "CREDIT_CARD"
                  ]
                }
              },
              "paymentType": "guarantee"
            },
            "price": {
              "currency": "EUR",
              "taxes": [
                {
                  "code": "VALUE_ADDED_TAX",
                  "included": true,
                  "percentage": "10.00"
                }
              ],
              "total": "417.00",
              "variations": {
                "average": {
                  "total": "139.00"
                },
                "changes": [
                  {
                    "endDate": "2024-10-04",
                    "startDate": "2024-10-01",
                    "total": "139.00"
                  }
                ]
              }
            },
            "rateCode": "RAC",
            "room": {
              "description": {
                "lang": "EN",
                "text": "Event-Rate\nStandard room A standard room consists of a room with shower-toilet or bathtub-toilet."
              },
              "type": "ROH",
              "typeEstimated": {
                "category": "STANDARD_ROOM"
              }
            },
            "self": "https://api.amadeus.com/v3/shopping/hotel-offers/RVAH8HDDBK"
          }
        ],
        "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=HSMADAMA&adults=1&checkInDate=2024-10-01&checkOutDate=2024-10-04&roomQuantity=1",
        "type": "hotel-offers"
      },
      {
        "available": true,
        "hotel": {
          "chainCode": "RT",
          "cityCode": "SVQ",
          "dupeId": "700094649",
          "hotelId": "RTSVQIBI",
          "latitude": 37.4082,
          "longitude": -5.9509,
          "name": "ibis Sevilla",
          "type": "hotel"
        },
        "offers": [
          {
            "boardType": "ROOM_ONLY",
            "checkInDate": "2024-10-04",
            "checkOutDate": "2024-10-07",
            "commission": {
              "amount": "2.74"
            },
            "guests": {
              "adults": 1
            },
            "id": "2TEP4FTXH7",
            "policies": {
              "cancellations": [
                {
                  "deadline": "2024-10-03T23:59:00+02:00"
                }
              ],
              "guarantee": {
                "acceptedPayments": {
                  "creditCardPolicies": [
                    {
                      "vendorCode": "AX"
                    },
                    {
                      "vendorCode": "CA"
                    },
                    {
                      "vendorCode": "EC"
                    },
                    {
                      "vendorCode": "IK"
                    },
                    {
                      "vendorCode": "VI"
                    }
                  ],
                  "creditCards": [
                    "AX",
                    "CA",
                    "EC",
                    "IK",
                    "VI"
                  ],
                  "methods": [
                    "CREDIT_CARD",
                    "VCC_EXTERNAL_PROVIDER"
                  ]
                }
              },
              "paymentType": "guarantee"
            },
            "price": {
              "currency": "EUR",
              "total": "324.00",
              "variations": {
                "average": {
                  "base": "108.00"
                },
                "changes": [
                  {
                    "base": "118.00",
                    "endDate": "2024-10-06",
                    "startDate": "2024-10-04",
                    "total": "118.00"
                  },
                  {
                    "base": "88.00",
                    "endDate": "2024-10-07",
                    "startDate": "2024-10-06",
                    "total": "88.00"
                  }
                ]
              }
            },
            "rateCode": "E5Z",
            "rateFamilyEstimated": {
              "code": "BAR",
              "type": "P"
            },
            "room": {
              "description": {
                "lang": "EN",
                "text": "FLEXIBLE RATE-RO/Room only\nAdapted room with double bed for 1-2 people equ\nipped with the new Ibis bed"
              },
              "type": "D1D",
              "typeEstimated": {
                "bedType": "DOUBLE",
                "beds": 1,
                "category": "RESIDENTIAL_APPARTMENT"
              }
            },
            "self": "https://api.amadeus.com/v3/shopping/hotel-offers/2TEP4FTXH7"
          }
        ],
        "self": "https://api.amadeus.com/v3/shopping/hotel-offers?hotelIds=RTSVQIBI&adults=1&checkInDate=2024-10-04&checkOutDate=2024-10-07&roomQuantity=1",
        "type": "hotel-offers"
      }
    ],
    "selectedFlightByDateOption": [
      {
        "allPassengerTotalPrice": 1270,
        "legs": [
          {
            "airlines": [
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-09-08",
            "arrivalTime": "17:00",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbLzMTEUG0ns7ARZ3KNdnAjJU8kJ9Rebq5FN88wp2UFVKZ3lhP_K5V6ezPy6gL7DF1ulj_306AmspRF-YQNV_AJ4XTJdjnlgktsWuL1dLNqV1WRBObPLt3nSH_9Y67S8XyMttAcr_pi0C9X7bRXMgABWtwIWvoGNPlHmHUCp5xQ05etAzOWBBHlVwEp4y2Bo7fB1jA2KIyOr2gAstqy2pj96l6la7gpAiPl3pIU2J5icljxGHGeHLIZ6sAUnDsjN0toueOVyFvv1cRslQwc4WFLeeTr4Z43SOE63_VjB3n2IuaFp9HJe78NX51ySq9TpvKJ51-kAad4PdXE_X2MwbPzFnDWDvRbT0HT3VEleikAQA8xpsWaltLUavw7zr93kuM3wG-gw6K_3i2ed9GRjLcrhRhVvDVS48UYdMK0mhuNazPA5LgPF1dXfASXWnzxrtAG5AigeXzm7tRA71YYQMHL7BBBLoHYkiAMdX69kNIWSG_OGJOCS0kSy3PR3XLZshD7EJSlVynYxAyaqI-f_-R8MKfJ4GKu5iEjr5vx99xF2fLRyhsevYsUVnR99i5M-zBGzho2recH4_e82rN2VW3Hhn-LVMbe1fw_Ozt5kd-XFlvh4ol0N_dq-mZl0raJ2G06kbw7ZzhwrgevmOhIoOopu8NI8kXNzPR7SoGP9a5XQO4Q_XHrrbXBHgO2IpEyd-LczfDnorEVpCVwE3Hb2pUdbSWuqs0hcH3yxTwRNDwU0uMA-mK96Rp9eg39PRFI6CwOapVNVUuArv-uzExa1rZCkbbh8z7XtifhY-1knniRAPeilpePaZGLXfB3P3HT834YkdggUOjq9IxdEzDPv87SOVyPvz2oGyUV_KYpJFk2CPxryKjD-ijkUmeo_pfO9hyCz3BAFo0Dni-DfRBwODu4PnY7vyWDTNR-7lQPcO3DFHm2pMyLLWB23u6DtIRc7MT2szRoc1MtyjH8Lwzy2lNLg9Hsg8TuTTNtGZ5LUS5lDcWlDcLoJWqWlljjcgGWY-LUv52GoGbkze7AcBLj8ogw==",
            "dateChange": 1,
            "departureDate": "2024-09-07",
            "departureTime": "20:10",
            "destination": "BCN",
            "duration": "11h50m",
            "routes": [
              {
                "airline": "VY",
                "bags_recheck_required": false,
                "cityCodeFrom": "SFO",
                "cityCodeTo": "BCN",
                "cityFrom": "San Francisco",
                "cityTo": "Barcelona",
                "combination_id": "0f9c01af4e050000f44ef0bb",
                "equipment": null,
                "fare_basis": "PIL2D4WN",
                "fare_category": "M",
                "fare_classes": "P",
                "flight_no": 9792,
                "flyFrom": "SFO",
                "flyTo": "BCN",
                "guarantee": false,
                "id": "0f9c01af4e050000f44ef0bb_0",
                "local_arrival": "2024-09-08T17:00:00.000Z",
                "local_departure": "2024-09-07T20:10:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-09-08T15:00:00.000Z",
                "utc_departure": "2024-09-08T03:10:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "SFO",
            "stops": 0
          },
          {
            "airlines": [
              "UX"
            ],
            "arrivalDate": "2024-09-17",
            "arrivalTime": "09:45",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbLzMTEUG0ns7ARZ3KNdnAjJU8kJ9Rebq5FN88wp2UFVKZ3lhP_K5V6ezPy6gL7DF1ulj_306AmspRF-YQNV_AJ4XTJdjnlgktsWuL1dLNqV1WRBObPLt3nSH_9Y67S8XyMttAcr_pi0C9X7bRXMgABWtwIWvoGNPlHmHUCp5xQ05etAzOWBBHlVwEp4y2Bo7fB1jA2KIyOr2gAstqy2pj96l6la7gpAiPl3pIU2J5icljxGHGeHLIZ6sAUnDsjN0toueOVyFvv1cRslQwc4WFLeeTr4Z43SOE63_VjB3n2IuaFp9HJe78NX51ySq9TpvKJ51-kAad4PdXE_X2MwbPzFnDWDvRbT0HT3VEleikAQA8xpsWaltLUavw7zr93kuM3wG-gw6K_3i2ed9GRjLcrhRhVvDVS48UYdMK0mhuNazPA5LgPF1dXfASXWnzxrtAG5AigeXzm7tRA71YYQMHL7BBBLoHYkiAMdX69kNIWSG_OGJOCS0kSy3PR3XLZshD7EJSlVynYxAyaqI-f_-R8MKfJ4GKu5iEjr5vx99xF2fLRyhsevYsUVnR99i5M-zBGzho2recH4_e82rN2VW3Hhn-LVMbe1fw_Ozt5kd-XFlvh4ol0N_dq-mZl0raJ2G06kbw7ZzhwrgevmOhIoOopu8NI8kXNzPR7SoGP9a5XQO4Q_XHrrbXBHgO2IpEyd-LczfDnorEVpCVwE3Hb2pUdbSWuqs0hcH3yxTwRNDwU0uMA-mK96Rp9eg39PRFI6CwOapVNVUuArv-uzExa1rZCkbbh8z7XtifhY-1knniRAPeilpePaZGLXfB3P3HT834YkdggUOjq9IxdEzDPv87SOVyPvz2oGyUV_KYpJFk2CPxryKjD-ijkUmeo_pfO9hyCz3BAFo0Dni-DfRBwODu4PnY7vyWDTNR-7lQPcO3DFHm2pMyLLWB23u6DtIRc7MT2szRoc1MtyjH8Lwzy2lNLg9Hsg8TuTTNtGZ5LUS5lDcWlDcLoJWqWlljjcgGWY-LUv52GoGbkze7AcBLj8ogw==",
            "dateChange": 0,
            "departureDate": "2024-09-17",
            "departureTime": "08:35",
            "destination": "MAD",
            "duration": "1h10m",
            "routes": [
              {
                "airline": "UX",
                "bags_recheck_required": false,
                "cityCodeFrom": "AGP",
                "cityCodeTo": "MAD",
                "cityFrom": "Málaga",
                "cityTo": "Madrid",
                "combination_id": "14140a224e0f00008b95d602",
                "equipment": null,
                "fare_basis": "",
                "fare_category": "M",
                "fare_classes": "Z",
                "flight_no": 5042,
                "flyFrom": "AGP",
                "flyTo": "MAD",
                "guarantee": false,
                "id": "14140a224e0f00008b95d602_0",
                "local_arrival": "2024-09-17T09:45:00.000Z",
                "local_departure": "2024-09-17T08:35:00.000Z",
                "operating_carrier": "UX",
                "operating_flight_no": "5042",
                "return": 0,
                "utc_arrival": "2024-09-17T07:45:00.000Z",
                "utc_departure": "2024-09-17T06:35:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "AGP",
            "stops": 0
          },
          {
            "airlines": [
              "NI",
              "TP"
            ],
            "arrivalDate": "2024-09-24",
            "arrivalTime": "14:05",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e050000f44ef0bb_0%7C14140a224e0f00008b95d602_0%7C04b80f9c4e150000d4f94b56_0%7C04b80f9c4e150000d4f94b56_1&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GbLzMTEUG0ns7ARZ3KNdnAjJU8kJ9Rebq5FN88wp2UFVKZ3lhP_K5V6ezPy6gL7DF1ulj_306AmspRF-YQNV_AJ4XTJdjnlgktsWuL1dLNqV1WRBObPLt3nSH_9Y67S8XyMttAcr_pi0C9X7bRXMgABWtwIWvoGNPlHmHUCp5xQ05etAzOWBBHlVwEp4y2Bo7fB1jA2KIyOr2gAstqy2pj96l6la7gpAiPl3pIU2J5icljxGHGeHLIZ6sAUnDsjN0toueOVyFvv1cRslQwc4WFLeeTr4Z43SOE63_VjB3n2IuaFp9HJe78NX51ySq9TpvKJ51-kAad4PdXE_X2MwbPzFnDWDvRbT0HT3VEleikAQA8xpsWaltLUavw7zr93kuM3wG-gw6K_3i2ed9GRjLcrhRhVvDVS48UYdMK0mhuNazPA5LgPF1dXfASXWnzxrtAG5AigeXzm7tRA71YYQMHL7BBBLoHYkiAMdX69kNIWSG_OGJOCS0kSy3PR3XLZshD7EJSlVynYxAyaqI-f_-R8MKfJ4GKu5iEjr5vx99xF2fLRyhsevYsUVnR99i5M-zBGzho2recH4_e82rN2VW3Hhn-LVMbe1fw_Ozt5kd-XFlvh4ol0N_dq-mZl0raJ2G06kbw7ZzhwrgevmOhIoOopu8NI8kXNzPR7SoGP9a5XQO4Q_XHrrbXBHgO2IpEyd-LczfDnorEVpCVwE3Hb2pUdbSWuqs0hcH3yxTwRNDwU0uMA-mK96Rp9eg39PRFI6CwOapVNVUuArv-uzExa1rZCkbbh8z7XtifhY-1knniRAPeilpePaZGLXfB3P3HT834YkdggUOjq9IxdEzDPv87SOVyPvz2oGyUV_KYpJFk2CPxryKjD-ijkUmeo_pfO9hyCz3BAFo0Dni-DfRBwODu4PnY7vyWDTNR-7lQPcO3DFHm2pMyLLWB23u6DtIRc7MT2szRoc1MtyjH8Lwzy2lNLg9Hsg8TuTTNtGZ5LUS5lDcWlDcLoJWqWlljjcgGWY-LUv52GoGbkze7AcBLj8ogw==",
            "dateChange": 1,
            "departureDate": "2024-09-23",
            "departureTime": "16:30",
            "destination": "SFO",
            "duration": "30h35m",
            "routes": [
              {
                "airline": "TP",
                "bags_recheck_required": false,
                "cityCodeFrom": "SVQ",
                "cityCodeTo": "LIS",
                "cityFrom": "Seville",
                "cityTo": "Lisbon",
                "combination_id": "04b80f9c4e150000d4f94b56",
                "equipment": null,
                "fare_basis": "AL0DSI05",
                "fare_category": "M",
                "fare_classes": "A",
                "flight_no": 1105,
                "flyFrom": "SVQ",
                "flyTo": "LIS",
                "guarantee": false,
                "id": "04b80f9c4e150000d4f94b56_0",
                "local_arrival": "2024-09-23T16:30:00.000Z",
                "local_departure": "2024-09-23T16:30:00.000Z",
                "operating_carrier": "NI",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-09-23T15:30:00.000Z",
                "utc_departure": "2024-09-23T14:30:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              },
              {
                "airline": "TP",
                "bags_recheck_required": false,
                "cityCodeFrom": "LIS",
                "cityCodeTo": "SFO",
                "cityFrom": "Lisbon",
                "cityTo": "San Francisco",
                "combination_id": "04b80f9c4e150000d4f94b56",
                "equipment": null,
                "fare_basis": "AL0DSI05",
                "fare_category": "M",
                "fare_classes": "A",
                "flight_no": 237,
                "flyFrom": "LIS",
                "flyTo": "SFO",
                "guarantee": false,
                "id": "04b80f9c4e150000d4f94b56_1",
                "local_arrival": "2024-09-24T14:05:00.000Z",
                "local_departure": "2024-09-24T09:25:00.000Z",
                "operating_carrier": "TP",
                "operating_flight_no": "237",
                "return": 0,
                "utc_arrival": "2024-09-24T21:05:00.000Z",
                "utc_departure": "2024-09-24T08:25:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "SVQ",
            "stops": 1
          }
        ],
        "quality": 1461.01,
        "totalPrice": 1270
      },
      {
        "allPassengerTotalPrice": 907,
        "legs": [
          {
            "airlines": [
              "AS",
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-09-15",
            "arrivalTime": "12:15",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5sgQyX2a0XFv44VTW6q2eLkdo14lwqofpeAONCPssc43xLQ41zuOnXLi9ySPdIBBW8DxX8rVfCnwV-9CYb_Sy4kNHs3clSFZ9JUKiNN8z09IMXDpWfkD-FWBnNQB9C8GNScziqLWPgB6DEvCvY6SBow2esrWyTFqRFeJprAFSooScmigKVSJPSzJSTlyzkHACpVGtXQmb6V3f9R0evaMlBgh6n1YFxct1YRdfhg_Hn5PapHDRW8HXenPOH_lGXO-szz_-AGV1d4PwMktR5XibCZIgRw3KmecpfUUEn7uC34Fl7GgYBLX8S7cotljUZ5lA4FWvfetq4sXk_pvLkGfV7UTWegzquN-0KXdjqHhS0GhOX1GoL3s1M6K4ApvqEKNSNFsvcxfNahY_tsIaEhaRIaz_TJBUp1k__rt8lQJ_VuqCC3Qaj5FFnsKkvrriu5xJEQ7z_puINTX5K2NPyv29fywBaTqUnSOoiju_sVNgygCOmSnFWt1VfD_HilsY-XQWgavhEDPnNyA50AhQp3uyyNQkLukYp0WAdS3_AWYk8N9FQJwzbJ4KIxqh8NmOcaDysD_lUe1guZZ4makkCzzsuwfhXzGVFQ6kszJAP5KRBW4CPHhxXIzV152lhPglmJxSLFt0O2ADpNjiPAKBnO7LoCK6Rj0I3em_IwND0-9mNfLua7iEe_c-F619qCMGaTZKpJ2FQNVCAy7ajZwpfCwqTncNkykVLEgdkMDzRMpa6ytFI9i4YDuuCbScEmYxPxsZSFoEr6w1KItyisY-2UF454NzwlsEBaagqLJnnBI4T1IRSFyLt1GGJ4f3kti7-H9P7ztjzT-u0Xsra5n6DIAWWTG_kpAKLPdww8kkKH9SASaPefWrRTWNCHOFZvwE_Zz2GiJRqHNtLgk5NvY3F22_Vy_Q_Zd18gxW8UMVNEQ30TiS3XcjkUCs6K1rSurlcCYXf8G-RlB89Fk37aRLQXoZTUpDFSHgGYbSzsEtZrZTgSoI7Vdz9bDqAyzJBR9cjX50pGjhr72xP2W-wvbIeucyJmTdj7XEGVEaZ5p4rAj6s-58w8lVE-TNyONztS9K7drv6F4oqPYgTZw13_PArYBbCYSL9Ode_Py4Fw3y-ezZ5YJaSCF7ZlUcP_jw6qW8qmslZlMqxSB3ZerMYye8bNQbg==",
            "dateChange": 1,
            "departureDate": "2024-09-14",
            "departureTime": "07:40",
            "destination": "BCN",
            "duration": "19h35m",
            "routes": [
              {
                "airline": "AS",
                "bags_recheck_required": false,
                "cityCodeFrom": "SFO",
                "cityCodeTo": "BOS",
                "cityFrom": "San Francisco",
                "cityTo": "Boston",
                "combination_id": "0f9c04884e0c0000a94a55fe",
                "equipment": null,
                "fare_basis": "QH2OAVBN",
                "fare_category": "M",
                "fare_classes": "X",
                "flight_no": 356,
                "flyFrom": "SFO",
                "flyTo": "BOS",
                "guarantee": false,
                "id": "0f9c04884e0c0000a94a55fe_0",
                "local_arrival": "2024-09-14T16:26:00.000Z",
                "local_departure": "2024-09-14T07:40:00.000Z",
                "operating_carrier": "AS",
                "operating_flight_no": "356",
                "return": 0,
                "utc_arrival": "2024-09-14T20:26:00.000Z",
                "utc_departure": "2024-09-14T14:40:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              },
              {
                "airline": "VY",
                "bags_recheck_required": true,
                "cityCodeFrom": "BOS",
                "cityCodeTo": "BCN",
                "cityFrom": "Boston",
                "cityTo": "Barcelona",
                "combination_id": "048801af4e0c0000e48a1db7",
                "equipment": null,
                "fare_basis": "TIL2N7WO",
                "fare_category": "M",
                "fare_classes": "T",
                "flight_no": 9796,
                "flyFrom": "BOS",
                "flyTo": "BCN",
                "guarantee": true,
                "id": "048801af4e0c0000e48a1db7_0",
                "local_arrival": "2024-09-15T12:15:00.000Z",
                "local_departure": "2024-09-14T22:55:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-09-15T10:15:00.000Z",
                "utc_departure": "2024-09-15T02:55:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": true
              }
            ],
            "source": "SFO",
            "stops": 1
          },
          {
            "airlines": [
              "I2"
            ],
            "arrivalDate": "2024-09-24",
            "arrivalTime": "14:35",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5sgQyX2a0XFv44VTW6q2eLkdo14lwqofpeAONCPssc43xLQ41zuOnXLi9ySPdIBBW8DxX8rVfCnwV-9CYb_Sy4kNHs3clSFZ9JUKiNN8z09IMXDpWfkD-FWBnNQB9C8GNScziqLWPgB6DEvCvY6SBow2esrWyTFqRFeJprAFSooScmigKVSJPSzJSTlyzkHACpVGtXQmb6V3f9R0evaMlBgh6n1YFxct1YRdfhg_Hn5PapHDRW8HXenPOH_lGXO-szz_-AGV1d4PwMktR5XibCZIgRw3KmecpfUUEn7uC34Fl7GgYBLX8S7cotljUZ5lA4FWvfetq4sXk_pvLkGfV7UTWegzquN-0KXdjqHhS0GhOX1GoL3s1M6K4ApvqEKNSNFsvcxfNahY_tsIaEhaRIaz_TJBUp1k__rt8lQJ_VuqCC3Qaj5FFnsKkvrriu5xJEQ7z_puINTX5K2NPyv29fywBaTqUnSOoiju_sVNgygCOmSnFWt1VfD_HilsY-XQWgavhEDPnNyA50AhQp3uyyNQkLukYp0WAdS3_AWYk8N9FQJwzbJ4KIxqh8NmOcaDysD_lUe1guZZ4makkCzzsuwfhXzGVFQ6kszJAP5KRBW4CPHhxXIzV152lhPglmJxSLFt0O2ADpNjiPAKBnO7LoCK6Rj0I3em_IwND0-9mNfLua7iEe_c-F619qCMGaTZKpJ2FQNVCAy7ajZwpfCwqTncNkykVLEgdkMDzRMpa6ytFI9i4YDuuCbScEmYxPxsZSFoEr6w1KItyisY-2UF454NzwlsEBaagqLJnnBI4T1IRSFyLt1GGJ4f3kti7-H9P7ztjzT-u0Xsra5n6DIAWWTG_kpAKLPdww8kkKH9SASaPefWrRTWNCHOFZvwE_Zz2GiJRqHNtLgk5NvY3F22_Vy_Q_Zd18gxW8UMVNEQ30TiS3XcjkUCs6K1rSurlcCYXf8G-RlB89Fk37aRLQXoZTUpDFSHgGYbSzsEtZrZTgSoI7Vdz9bDqAyzJBR9cjX50pGjhr72xP2W-wvbIeucyJmTdj7XEGVEaZ5p4rAj6s-58w8lVE-TNyONztS9K7drv6F4oqPYgTZw13_PArYBbCYSL9Ode_Py4Fw3y-ezZ5YJaSCF7ZlUcP_jw6qW8qmslZlMqxSB3ZerMYye8bNQbg==",
            "dateChange": 0,
            "departureDate": "2024-09-24",
            "departureTime": "13:15",
            "destination": "MAD",
            "duration": "1h20m",
            "routes": [
              {
                "airline": "I2",
                "bags_recheck_required": false,
                "cityCodeFrom": "AGP",
                "cityCodeTo": "MAD",
                "cityFrom": "Málaga",
                "cityTo": "Madrid",
                "combination_id": "14140a224e1600007972bf0f",
                "equipment": null,
                "fare_basis": "",
                "fare_category": "M",
                "fare_classes": "",
                "flight_no": 3871,
                "flyFrom": "AGP",
                "flyTo": "MAD",
                "guarantee": false,
                "id": "14140a224e1600007972bf0f_0",
                "local_arrival": "2024-09-24T14:35:00.000Z",
                "local_departure": "2024-09-24T13:15:00.000Z",
                "operating_carrier": "I2",
                "operating_flight_no": "3871",
                "return": 0,
                "utc_arrival": "2024-09-24T12:35:00.000Z",
                "utc_departure": "2024-09-24T11:15:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "AGP",
            "stops": 0
          },
          {
            "airlines": [
              "VY",
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-10-01",
            "arrivalTime": "18:10",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c04884e0c0000a94a55fe_0%7C048801af4e0c0000e48a1db7_0%7C14140a224e1600007972bf0f_0%7C04b801af4e1c000015602bb0_0%7C01af0f9c4e1d0000e80948dd_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G5sgQyX2a0XFv44VTW6q2eLkdo14lwqofpeAONCPssc43xLQ41zuOnXLi9ySPdIBBW8DxX8rVfCnwV-9CYb_Sy4kNHs3clSFZ9JUKiNN8z09IMXDpWfkD-FWBnNQB9C8GNScziqLWPgB6DEvCvY6SBow2esrWyTFqRFeJprAFSooScmigKVSJPSzJSTlyzkHACpVGtXQmb6V3f9R0evaMlBgh6n1YFxct1YRdfhg_Hn5PapHDRW8HXenPOH_lGXO-szz_-AGV1d4PwMktR5XibCZIgRw3KmecpfUUEn7uC34Fl7GgYBLX8S7cotljUZ5lA4FWvfetq4sXk_pvLkGfV7UTWegzquN-0KXdjqHhS0GhOX1GoL3s1M6K4ApvqEKNSNFsvcxfNahY_tsIaEhaRIaz_TJBUp1k__rt8lQJ_VuqCC3Qaj5FFnsKkvrriu5xJEQ7z_puINTX5K2NPyv29fywBaTqUnSOoiju_sVNgygCOmSnFWt1VfD_HilsY-XQWgavhEDPnNyA50AhQp3uyyNQkLukYp0WAdS3_AWYk8N9FQJwzbJ4KIxqh8NmOcaDysD_lUe1guZZ4makkCzzsuwfhXzGVFQ6kszJAP5KRBW4CPHhxXIzV152lhPglmJxSLFt0O2ADpNjiPAKBnO7LoCK6Rj0I3em_IwND0-9mNfLua7iEe_c-F619qCMGaTZKpJ2FQNVCAy7ajZwpfCwqTncNkykVLEgdkMDzRMpa6ytFI9i4YDuuCbScEmYxPxsZSFoEr6w1KItyisY-2UF454NzwlsEBaagqLJnnBI4T1IRSFyLt1GGJ4f3kti7-H9P7ztjzT-u0Xsra5n6DIAWWTG_kpAKLPdww8kkKH9SASaPefWrRTWNCHOFZvwE_Zz2GiJRqHNtLgk5NvY3F22_Vy_Q_Zd18gxW8UMVNEQ30TiS3XcjkUCs6K1rSurlcCYXf8G-RlB89Fk37aRLQXoZTUpDFSHgGYbSzsEtZrZTgSoI7Vdz9bDqAyzJBR9cjX50pGjhr72xP2W-wvbIeucyJmTdj7XEGVEaZ5p4rAj6s-58w8lVE-TNyONztS9K7drv6F4oqPYgTZw13_PArYBbCYSL9Ode_Py4Fw3y-ezZ5YJaSCF7ZlUcP_jw6qW8qmslZlMqxSB3ZerMYye8bNQbg==",
            "dateChange": 1,
            "departureDate": "2024-09-30",
            "departureTime": "22:55",
            "destination": "SFO",
            "duration": "28h15m",
            "routes": [
              {
                "airline": "VY",
                "bags_recheck_required": false,
                "cityCodeFrom": "SVQ",
                "cityCodeTo": "BCN",
                "cityFrom": "Seville",
                "cityTo": "Barcelona",
                "combination_id": "04b801af4e1c000015602bb0",
                "equipment": null,
                "fare_basis": "WOWVYCLB",
                "fare_category": "M",
                "fare_classes": "W",
                "flight_no": 2221,
                "flyFrom": "SVQ",
                "flyTo": "BCN",
                "guarantee": false,
                "id": "04b801af4e1c000015602bb0_0",
                "local_arrival": "2024-10-01T00:35:00.000Z",
                "local_departure": "2024-09-30T22:55:00.000Z",
                "operating_carrier": "VY",
                "operating_flight_no": "2221",
                "return": 0,
                "utc_arrival": "2024-09-30T22:35:00.000Z",
                "utc_departure": "2024-09-30T20:55:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              },
              {
                "airline": "VY",
                "bags_recheck_required": true,
                "cityCodeFrom": "BCN",
                "cityCodeTo": "SFO",
                "cityFrom": "Barcelona",
                "cityTo": "San Francisco",
                "combination_id": "01af0f9c4e1d0000e80948dd",
                "equipment": null,
                "fare_basis": "PIL2A3WN",
                "fare_category": "M",
                "fare_classes": "P",
                "flight_no": 9791,
                "flyFrom": "BCN",
                "flyTo": "SFO",
                "guarantee": true,
                "id": "01af0f9c4e1d0000e80948dd_0",
                "local_arrival": "2024-10-01T18:10:00.000Z",
                "local_departure": "2024-10-01T14:00:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-10-02T01:10:00.000Z",
                "utc_departure": "2024-10-01T12:00:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": true
              }
            ],
            "source": "SVQ",
            "stops": 1
          }
        ],
        "quality": 1144.96,
        "totalPrice": 907
      },
      {
        "allPassengerTotalPrice": 814,
        "legs": [
          {
            "airlines": [
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-09-22",
            "arrivalTime": "17:00",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
            "dateChange": 1,
            "departureDate": "2024-09-21",
            "departureTime": "20:10",
            "destination": "BCN",
            "duration": "11h50m",
            "routes": [
              {
                "airline": "VY",
                "bags_recheck_required": false,
                "cityCodeFrom": "SFO",
                "cityCodeTo": "BCN",
                "cityFrom": "San Francisco",
                "cityTo": "Barcelona",
                "combination_id": "0f9c01af4e130000b8df6af8",
                "equipment": null,
                "fare_basis": "QIL2C3WN",
                "fare_category": "M",
                "fare_classes": "Q",
                "flight_no": 9792,
                "flyFrom": "SFO",
                "flyTo": "BCN",
                "guarantee": false,
                "id": "0f9c01af4e130000b8df6af8_0",
                "local_arrival": "2024-09-22T17:00:00.000Z",
                "local_departure": "2024-09-21T20:10:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-09-22T15:00:00.000Z",
                "utc_departure": "2024-09-22T03:10:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "SFO",
            "stops": 0
          },
          {
            "airlines": [
              "UX"
            ],
            "arrivalDate": "2024-10-01",
            "arrivalTime": "13:45",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
            "dateChange": 0,
            "departureDate": "2024-10-01",
            "departureTime": "12:35",
            "destination": "MAD",
            "duration": "1h10m",
            "routes": [
              {
                "airline": "UX",
                "bags_recheck_required": false,
                "cityCodeFrom": "AGP",
                "cityCodeTo": "MAD",
                "cityFrom": "Málaga",
                "cityTo": "Madrid",
                "combination_id": "14140a224e1d000064176280",
                "equipment": null,
                "fare_basis": "ZDYO4L",
                "fare_category": "M",
                "fare_classes": "M",
                "flight_no": 5036,
                "flyFrom": "AGP",
                "flyTo": "MAD",
                "guarantee": false,
                "id": "14140a224e1d000064176280_0",
                "local_arrival": "2024-10-01T13:45:00.000Z",
                "local_departure": "2024-10-01T12:35:00.000Z",
                "operating_carrier": "UX",
                "operating_flight_no": "5036",
                "return": 0,
                "utc_arrival": "2024-10-01T11:45:00.000Z",
                "utc_departure": "2024-10-01T10:35:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "AGP",
            "stops": 0
          },
          {
            "airlines": [
              "VY",
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-10-08",
            "arrivalTime": "18:10",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e130000b8df6af8_0%7C14140a224e1d000064176280_0%7C04b801af4e230000e00d2993_0%7C01af0f9c4e2400007559f353_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=GN_QLRBqEeBaHsv4gCt7f4-c19m1eZFfWYpqo9ay918Y9ze4SAiXEejYnBmyhOPqYrgdbtgnaXOGFHbbmNIlaTO7OlgU3LOMJ4TclVhm87AsbRLJUM_Z6v1vcJo4iG143qVeXGP1mqTBYsFHPczruzwD42llfyebLNMLAOPfrX-LBpBgfltZLZlHxKXyaV-vNd9IxtJwqH7mSiOO9QWtZoNCH6M5JtL1rUkzWq_B81vIreRKsn7NUI4W0fVGcW-qMTiVpEaYA8LV6HF01T8Zg1qsMDji0ZM0OUhOP7lmKzqb-JJQ09MLpT9t7yDnyTnFmCQTq3MgsmXnDt5MoEYzIe1YaXCpvutriBPHe7BIcarXLanm0Jmo1Pu9PPYniHvWyKBF1LPRVDRoEI8bThJa6U518kYIg8QTb7JnRyOWuzU4-3kZo2QXoTsr8ZklzZQyv3FxDErHOKpoyB-7lwDL248KuAX0zl_SxfYP0cZFoLXu8UblFq5yp5YdzHvNs8WkeDDF1z8ykS-FjOn3ypBYlZhA6c8yIzHyDcjTitFOITd7Vv9H8sOChsZ0zgbMH3qhwmFrWT5JY97hWAJ7sYrXnTz7jBXta7jeZHzvB4g4iUhJVfH1_VURVeqttxGR1vvtxy7zM90whs6ahBqeCgQIB_zaSZaH_qOuY2JdB2BwYwLu0bYQPuXdF6Wtr1v11jSKr47YTJtZQo9AnPAdd2pE8mnXddZX4FeAnwR8qqJPL2OP2w6213MIW99JoQVL3WzrLeWqjSX6TbmxlpicOrhd3BCfDOPFS8SxSY76hG7hrGn9t_IB1IcKsbEdmq9V2HeFSMJlCJTh7ayKxvmzbGd84NttP5qGSeKbSq46D5pBzcrgIqK_8JJNshcdFUB-slyQmzayiGeiVsVXHWQTsf30x5ccRA1iEmyooNZ9xm-MPBr6NSG9zPZjBLJAaCyQqWJVyC_JYb54W5ZbsYt4s9K-9k8_jjzOC6U1ParwtDDfAg8JtRpjyRpugIra7-H_QNEz4t1nLpC7N3cydxem4s2Xsh8EuzEcbUZ4kFXYzyuGlkJ3qLk9MhjC1OoSLh1Cdl-WWLppQPNivGBawr6H7_vUfzA==",
            "dateChange": 1,
            "departureDate": "2024-10-07",
            "departureTime": "22:55",
            "destination": "SFO",
            "duration": "28h15m",
            "routes": [
              {
                "airline": "VY",
                "bags_recheck_required": false,
                "cityCodeFrom": "SVQ",
                "cityCodeTo": "BCN",
                "cityFrom": "Seville",
                "cityTo": "Barcelona",
                "combination_id": "04b801af4e230000e00d2993",
                "equipment": null,
                "fare_basis": "OOWVYCLB",
                "fare_category": "M",
                "fare_classes": "O",
                "flight_no": 2221,
                "flyFrom": "SVQ",
                "flyTo": "BCN",
                "guarantee": false,
                "id": "04b801af4e230000e00d2993_0",
                "local_arrival": "2024-10-08T00:35:00.000Z",
                "local_departure": "2024-10-07T22:55:00.000Z",
                "operating_carrier": "VY",
                "operating_flight_no": "2221",
                "return": 0,
                "utc_arrival": "2024-10-07T22:35:00.000Z",
                "utc_departure": "2024-10-07T20:55:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              },
              {
                "airline": "VY",
                "bags_recheck_required": true,
                "cityCodeFrom": "BCN",
                "cityCodeTo": "SFO",
                "cityFrom": "Barcelona",
                "cityTo": "San Francisco",
                "combination_id": "01af0f9c4e2400007559f353",
                "equipment": null,
                "fare_basis": "QIL2A3WN",
                "fare_category": "M",
                "fare_classes": "Q",
                "flight_no": 9791,
                "flyFrom": "BCN",
                "flyTo": "SFO",
                "guarantee": true,
                "id": "01af0f9c4e2400007559f353_0",
                "local_arrival": "2024-10-08T18:10:00.000Z",
                "local_departure": "2024-10-08T14:00:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-10-09T01:10:00.000Z",
                "utc_departure": "2024-10-08T12:00:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": true
              }
            ],
            "source": "SVQ",
            "stops": 1
          }
        ],
        "quality": 1008.35,
        "totalPrice": 814
      },
      {
        "allPassengerTotalPrice": 814,
        "legs": [
          {
            "airlines": [
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-09-29",
            "arrivalTime": "17:00",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4UQVkonVvxqE6c9JtLUPYkyX8j4bm46wvEML9296SsaTa0pBRLrKNiG4GRGXm7kohfIhRu8iscSImSciLGkkfyQGEnIybx6PMuviVLBXqWd1cdFxhE7uwFKNVUQllygBFJGyf37TKfFyK7GsK7sDD6-sMxEv94lxtH3YpbfN86AAkhCaIUnG446mby2Qm6Q-xDI0i9xQibs-kR9hlD4KQT_vxowAJYycDsjeUzx3XNsDBupjOMQvRGOpACdQqbtohbQGpFolDMSfo-0Sshfww1zn5cMPkZvzX1fJGd3blnMpRxsS1m-5qesWpQC7FBNw61JAI8uaCgL1ydkkBFy8wmDco_1bh4guzPj9sIgequ6PeB6gxkVG3tQJbx0q8cUdl84c_Z44XgqZ_mcFOOlWmVIz8QLaJyJ9enm2Pmc3g8-NcqTxNs1Gh6cLBpC6dbLMUSlERRVe_rgPB5xdGtcfdTEd3NgWsyaloz-omsodwROHjvZlTgMOHlnms2NX623AX1D5OZsZpgu-5u8Oj3mchEvXgm0UAsx0odLjORl-EEaqWNPSS3RIM-eAH_N2C4CSYU--NKQmQYpOlvDa0zg5Qmtf_KI-Drb7OxH83T3CFYGcjzIH7HbTn-6Q5YA6zCgXbq6TRag4uhRCigwlTA39EtD56yXeNO8w64hi0u8ITgNiF3rrfGfBjwGaMPDQmp54kpN7mXXxiY35WWUfp0DRTtjFPV7xlTMldPm_Yv6lCT3cr9Tik-hTIfhxukCMgoF4hN63fPxpxZ4MSxuCfweIG0Y0SbD9afIoeNZA47Q_Nt_WM-2NmeUWxSDcqaCRFcbEzeMfoAhe18fkoGeoVgG-K2xLy1EvBHztmfpGNTUuUUC52ChHgg94UxF_EtJMtDs3SUdpaJQrmyeN4eQXVkg391jkMkMLasap66y164kkMw4RM1jyk_XvglEp3GGjJzCVcC4ABDDX6NLNd7-QtmfbTWHCLSk8V4nBS8_-M2PaSYJYsm597G-RgYA2IFqxQPOxsHPhrknzsa4o526T6matbnGiuqQU1LGBLAAflMKEcTgam9DV3FGwoVAL9uv329ZQdfXgvWwPBFLImsTWFkS1BQ==",
            "dateChange": 1,
            "departureDate": "2024-09-28",
            "departureTime": "20:10",
            "destination": "BCN",
            "duration": "11h50m",
            "routes": [
              {
                "airline": "VY",
                "bags_recheck_required": false,
                "cityCodeFrom": "SFO",
                "cityCodeTo": "BCN",
                "cityFrom": "San Francisco",
                "cityTo": "Barcelona",
                "combination_id": "0f9c01af4e1a0000a9809026",
                "equipment": null,
                "fare_basis": "QIL2C3WN",
                "fare_category": "M",
                "fare_classes": "Q",
                "flight_no": 9792,
                "flyFrom": "SFO",
                "flyTo": "BCN",
                "guarantee": false,
                "id": "0f9c01af4e1a0000a9809026_0",
                "local_arrival": "2024-09-29T17:00:00.000Z",
                "local_departure": "2024-09-28T20:10:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-09-29T15:00:00.000Z",
                "utc_departure": "2024-09-29T03:10:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "SFO",
            "stops": 0
          },
          {
            "airlines": [
              "UX"
            ],
            "arrivalDate": "2024-10-08",
            "arrivalTime": "13:45",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4UQVkonVvxqE6c9JtLUPYkyX8j4bm46wvEML9296SsaTa0pBRLrKNiG4GRGXm7kohfIhRu8iscSImSciLGkkfyQGEnIybx6PMuviVLBXqWd1cdFxhE7uwFKNVUQllygBFJGyf37TKfFyK7GsK7sDD6-sMxEv94lxtH3YpbfN86AAkhCaIUnG446mby2Qm6Q-xDI0i9xQibs-kR9hlD4KQT_vxowAJYycDsjeUzx3XNsDBupjOMQvRGOpACdQqbtohbQGpFolDMSfo-0Sshfww1zn5cMPkZvzX1fJGd3blnMpRxsS1m-5qesWpQC7FBNw61JAI8uaCgL1ydkkBFy8wmDco_1bh4guzPj9sIgequ6PeB6gxkVG3tQJbx0q8cUdl84c_Z44XgqZ_mcFOOlWmVIz8QLaJyJ9enm2Pmc3g8-NcqTxNs1Gh6cLBpC6dbLMUSlERRVe_rgPB5xdGtcfdTEd3NgWsyaloz-omsodwROHjvZlTgMOHlnms2NX623AX1D5OZsZpgu-5u8Oj3mchEvXgm0UAsx0odLjORl-EEaqWNPSS3RIM-eAH_N2C4CSYU--NKQmQYpOlvDa0zg5Qmtf_KI-Drb7OxH83T3CFYGcjzIH7HbTn-6Q5YA6zCgXbq6TRag4uhRCigwlTA39EtD56yXeNO8w64hi0u8ITgNiF3rrfGfBjwGaMPDQmp54kpN7mXXxiY35WWUfp0DRTtjFPV7xlTMldPm_Yv6lCT3cr9Tik-hTIfhxukCMgoF4hN63fPxpxZ4MSxuCfweIG0Y0SbD9afIoeNZA47Q_Nt_WM-2NmeUWxSDcqaCRFcbEzeMfoAhe18fkoGeoVgG-K2xLy1EvBHztmfpGNTUuUUC52ChHgg94UxF_EtJMtDs3SUdpaJQrmyeN4eQXVkg391jkMkMLasap66y164kkMw4RM1jyk_XvglEp3GGjJzCVcC4ABDDX6NLNd7-QtmfbTWHCLSk8V4nBS8_-M2PaSYJYsm597G-RgYA2IFqxQPOxsHPhrknzsa4o526T6matbnGiuqQU1LGBLAAflMKEcTgam9DV3FGwoVAL9uv329ZQdfXgvWwPBFLImsTWFkS1BQ==",
            "dateChange": 0,
            "departureDate": "2024-10-08",
            "departureTime": "12:35",
            "destination": "MAD",
            "duration": "1h10m",
            "routes": [
              {
                "airline": "UX",
                "bags_recheck_required": false,
                "cityCodeFrom": "AGP",
                "cityCodeTo": "MAD",
                "cityFrom": "Málaga",
                "cityTo": "Madrid",
                "combination_id": "14140a224e240000f947d90e",
                "equipment": null,
                "fare_basis": "ZDYO4L",
                "fare_category": "M",
                "fare_classes": "M",
                "flight_no": 5036,
                "flyFrom": "AGP",
                "flyTo": "MAD",
                "guarantee": false,
                "id": "14140a224e240000f947d90e_0",
                "local_arrival": "2024-10-08T13:45:00.000Z",
                "local_departure": "2024-10-08T12:35:00.000Z",
                "operating_carrier": "UX",
                "operating_flight_no": "5036",
                "return": 0,
                "utc_arrival": "2024-10-08T11:45:00.000Z",
                "utc_departure": "2024-10-08T10:35:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              }
            ],
            "source": "AGP",
            "stops": 0
          },
          {
            "airlines": [
              "VY",
              "LEVELSPAIN"
            ],
            "arrivalDate": "2024-10-15",
            "arrivalTime": "18:10",
            "bookingLink": "https://www.kiwi.com/deep?affilid=coinventaimulti&currency=USD&flightsId=0f9c01af4e1a0000a9809026_0%7C14140a224e240000f947d90e_0%7C04b801af4e2a00005aff2a3e_0%7C01af0f9c4e2b0000708682db_0&from=SFO&lang=en&passengers=1&to=BCN&type2=multicity&booking_token=G4UQVkonVvxqE6c9JtLUPYkyX8j4bm46wvEML9296SsaTa0pBRLrKNiG4GRGXm7kohfIhRu8iscSImSciLGkkfyQGEnIybx6PMuviVLBXqWd1cdFxhE7uwFKNVUQllygBFJGyf37TKfFyK7GsK7sDD6-sMxEv94lxtH3YpbfN86AAkhCaIUnG446mby2Qm6Q-xDI0i9xQibs-kR9hlD4KQT_vxowAJYycDsjeUzx3XNsDBupjOMQvRGOpACdQqbtohbQGpFolDMSfo-0Sshfww1zn5cMPkZvzX1fJGd3blnMpRxsS1m-5qesWpQC7FBNw61JAI8uaCgL1ydkkBFy8wmDco_1bh4guzPj9sIgequ6PeB6gxkVG3tQJbx0q8cUdl84c_Z44XgqZ_mcFOOlWmVIz8QLaJyJ9enm2Pmc3g8-NcqTxNs1Gh6cLBpC6dbLMUSlERRVe_rgPB5xdGtcfdTEd3NgWsyaloz-omsodwROHjvZlTgMOHlnms2NX623AX1D5OZsZpgu-5u8Oj3mchEvXgm0UAsx0odLjORl-EEaqWNPSS3RIM-eAH_N2C4CSYU--NKQmQYpOlvDa0zg5Qmtf_KI-Drb7OxH83T3CFYGcjzIH7HbTn-6Q5YA6zCgXbq6TRag4uhRCigwlTA39EtD56yXeNO8w64hi0u8ITgNiF3rrfGfBjwGaMPDQmp54kpN7mXXxiY35WWUfp0DRTtjFPV7xlTMldPm_Yv6lCT3cr9Tik-hTIfhxukCMgoF4hN63fPxpxZ4MSxuCfweIG0Y0SbD9afIoeNZA47Q_Nt_WM-2NmeUWxSDcqaCRFcbEzeMfoAhe18fkoGeoVgG-K2xLy1EvBHztmfpGNTUuUUC52ChHgg94UxF_EtJMtDs3SUdpaJQrmyeN4eQXVkg391jkMkMLasap66y164kkMw4RM1jyk_XvglEp3GGjJzCVcC4ABDDX6NLNd7-QtmfbTWHCLSk8V4nBS8_-M2PaSYJYsm597G-RgYA2IFqxQPOxsHPhrknzsa4o526T6matbnGiuqQU1LGBLAAflMKEcTgam9DV3FGwoVAL9uv329ZQdfXgvWwPBFLImsTWFkS1BQ==",
            "dateChange": 1,
            "departureDate": "2024-10-14",
            "departureTime": "22:55",
            "destination": "SFO",
            "duration": "28h15m",
            "routes": [
              {
                "airline": "VY",
                "bags_recheck_required": false,
                "cityCodeFrom": "SVQ",
                "cityCodeTo": "BCN",
                "cityFrom": "Seville",
                "cityTo": "Barcelona",
                "combination_id": "04b801af4e2a00005aff2a3e",
                "equipment": null,
                "fare_basis": "OOWVYCLB",
                "fare_category": "M",
                "fare_classes": "O",
                "flight_no": 2221,
                "flyFrom": "SVQ",
                "flyTo": "BCN",
                "guarantee": false,
                "id": "04b801af4e2a00005aff2a3e_0",
                "local_arrival": "2024-10-15T00:35:00.000Z",
                "local_departure": "2024-10-14T22:55:00.000Z",
                "operating_carrier": "VY",
                "operating_flight_no": "2221",
                "return": 0,
                "utc_arrival": "2024-10-14T22:35:00.000Z",
                "utc_departure": "2024-10-14T20:55:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": false
              },
              {
                "airline": "VY",
                "bags_recheck_required": true,
                "cityCodeFrom": "BCN",
                "cityCodeTo": "SFO",
                "cityFrom": "Barcelona",
                "cityTo": "San Francisco",
                "combination_id": "01af0f9c4e2b0000708682db",
                "equipment": null,
                "fare_basis": "QIL2A3WN",
                "fare_category": "M",
                "fare_classes": "Q",
                "flight_no": 9791,
                "flyFrom": "BCN",
                "flyTo": "SFO",
                "guarantee": true,
                "id": "01af0f9c4e2b0000708682db_0",
                "local_arrival": "2024-10-15T18:10:00.000Z",
                "local_departure": "2024-10-15T14:00:00.000Z",
                "operating_carrier": "LEVELSPAIN",
                "operating_flight_no": "",
                "return": 0,
                "utc_arrival": "2024-10-16T01:10:00.000Z",
                "utc_departure": "2024-10-15T12:00:00.000Z",
                "vehicle_type": "aircraft",
                "vi_connection": true
              }
            ],
            "source": "SVQ",
            "stops": 1
          }
        ],
        "quality": 1008.35,
        "totalPrice": 814
      }
    ],
    "gMapsData": {
      "ibis budget Málaga Centro": [
        [
          {
            "about": {
              "Amenities": {
                "Accessible": true,
                "Air-conditioned": true,
                "Fitness center": false,
                "Free Wi-Fi": true,
                "Free breakfast": false,
                "Kid-friendly": true,
                "Paid parking": true,
                "Pet-friendly": true,
                "Pool": false,
                "Restaurant": true,
                "Smoke-free": true
              },
              "Other": {
                "Air-conditioned": true,
                "Check-in time": "2:00 PM",
                "Check-out time": "12:00 PM",
                "Free Wi-Fi": true,
                "Parking": true,
                "Star rating": "1 stars",
                "Wi-Fi": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "hotels",
            "cid": "13215366552600739444",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Straightforward budget hotel offering simple rooms, as well as complimentary Wi-Fi.",
            "full_address": "Hostal 1 - H/Ma/02083, C. Calvo, 4, Distrito Centro, 29007 Málaga, Spain",
            "google_id": "0xd72f7979d8959c9:0xb76669dc90587e74",
            "latitude": 36.718570799999995,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/ibis+budget+M%C3%A1laga+Centro/@36.718570799999995,-4.4276607,14z/data=!4m8!1m2!2m1!1sibis+budget+M%C3%A1laga+Centro!3m4!1s0xd72f7979d8959c9:0xb76669dc90587e74!8m2!3d36.718570799999995!4d-4.4276607",
            "location_reviews_link": "https://www.google.com/maps/place/ibis+budget+M%C3%A1laga+Centro/@36.718570799999995,-4.4276607,13.99z/data=!4m16!1m7!3m6!1s0xd72f7979d8959c9:0xb76669dc90587e74!2sibis+budget+M%C3%A1laga+Centro!8m2!3d36.718570799999995!4d-4.4276607!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7979d8959c9:0xb76669dc90587e74!8m2!3d36.718570799999995!4d-4.4276607!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh3.googleusercontent.com/-vVt3fnMSkBM/AAAAAAAAAAI/AAAAAAAAAAA/Cxptqn354DM/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.4276607,
            "menu_link": null,
            "name": "ibis budget Málaga Centro",
            "name_for_emails": "Ibis Budget Málaga Centro",
            "order_links": null,
            "other_hours": null,
            "owner_id": "101465147377598297682",
            "owner_link": "https://www.google.com/maps/contrib/101465147377598297682",
            "owner_title": "ibis budget Málaga Centro",
            "phone": "+34 951 21 38 54",
            "photo": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhExeWzkfj0pk2ANmPQXVkxssWtsfZ-9u_sUieFRwVrwDElFgKpAX-YtF-oi6KknJzSrpsQAVv4nklrOUof9T43yCai-u1wzwaDogWUnU6O28CBG1E-arglM2Da-OD8SEnwj3KATmXQhNmDsgJhImHmpfFYQIeAFDGhekr5QK1jIZ9AT3fIWM6UqAg=w800-h500-k-no",
            "photos_count": 901,
            "photos_data": [
              {
                "latitude": 36.7187446,
                "longitude": -4.427648899999999,
                "original_photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhExeWzkfj0pk2ANmPQXVkxssWtsfZ-9u_sUieFRwVrwDElFgKpAX-YtF-oi6KknJzSrpsQAVv4nklrOUof9T43yCai-u1wzwaDogWUnU6O28CBG1E-arglM2Da-OD8SEnwj3KATmXQhNmDsgJhImHmpfFYQIeAFDGhekr5QK1jIZ9AT3fIWM6UqAg=w203-h152-k-no",
                "photo_date": "1/20/2022 14:00:00",
                "photo_id": "https://photos.hotelbeds.com/giata/original/22/228461/228461a_hb_a_019.jpg",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "PROVIDER_HOTELBEDS-hotelpois",
                "photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhExeWzkfj0pk2ANmPQXVkxssWtsfZ-9u_sUieFRwVrwDElFgKpAX-YtF-oi6KknJzSrpsQAVv4nklrOUof9T43yCai-u1wzwaDogWUnU6O28CBG1E-arglM2Da-OD8SEnwj3KATmXQhNmDsgJhImHmpfFYQIeAFDGhekr5QK1jIZ9AT3fIWM6UqAg",
                "photo_url_big": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhExeWzkfj0pk2ANmPQXVkxssWtsfZ-9u_sUieFRwVrwDElFgKpAX-YtF-oi6KknJzSrpsQAVv4nklrOUof9T43yCai-u1wzwaDogWUnU6O28CBG1E-arglM2Da-OD8SEnwj3KATmXQhNmDsgJhImHmpfFYQIeAFDGhekr5QK1jIZ9AT3fIWM6UqAg=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7187446,
                "longitude": -4.427648899999999,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNvPbTt4fEMgZLozVzukX_fuVnV4g27HjV8sOau=w203-h135-k-no",
                "photo_date": "12/11/2017 16:00:00",
                "photo_id": "AF1QipNvPbTt4fEMgZLozVzukX_fuVnV4g27HjV8sOau",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNvPbTt4fEMgZLozVzukX_fuVnV4g27HjV8sOau",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNvPbTt4fEMgZLozVzukX_fuVnV4g27HjV8sOau=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7187446,
                "longitude": -4.427648899999999,
                "original_photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhG1VEkoPUNiYlDTwZx_bA5AP14gP1rbVH--ohSC6YgZ9haQ0aEQX1b9EWAoLOLt9sADFAiaAhMsIz9itI_vTdBGp_NOdjXQOFkuGDB-zPMWpqNgdMMMdm6oTl7E_7472vw4OwNVt2GHkrsJKJRKCpSNwuQAi1o47TAUabt8D-z8L-FVVPMrdTKg0w=w203-h152-k-no",
                "photo_date": "12/15/2020 4:00:00",
                "photo_id": "https://i.giatamedia.com/m.php?m=AQABAAAAla4KANYm3wIFAITPQj0Ng1Ypm5ZmApg3ZBk",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "giata-de",
                "photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhG1VEkoPUNiYlDTwZx_bA5AP14gP1rbVH--ohSC6YgZ9haQ0aEQX1b9EWAoLOLt9sADFAiaAhMsIz9itI_vTdBGp_NOdjXQOFkuGDB-zPMWpqNgdMMMdm6oTl7E_7472vw4OwNVt2GHkrsJKJRKCpSNwuQAi1o47TAUabt8D-z8L-FVVPMrdTKg0w",
                "photo_url_big": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhG1VEkoPUNiYlDTwZx_bA5AP14gP1rbVH--ohSC6YgZ9haQ0aEQX1b9EWAoLOLt9sADFAiaAhMsIz9itI_vTdBGp_NOdjXQOFkuGDB-zPMWpqNgdMMMdm6oTl7E_7472vw4OwNVt2GHkrsJKJRKCpSNwuQAi1o47TAUabt8D-z8L-FVVPMrdTKg0w=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJyVmJnZf3cg0RdH5YkNxpZrc",
            "plus_code": "8C8QPH9C+CW",
            "popular_times": null,
            "postal_code": "29007",
            "posts": null,
            "query": "ibis budget Málaga Centro",
            "range": "1 stars",
            "rating": 3.8,
            "reservation_links": null,
            "reviews": 2588,
            "reviews_id": "-5231377521108812172",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJyVmJnZf3cg0RdH5YkNxpZrc&q=*&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 171,
              "2": 178,
              "3": 482,
              "4": 832,
              "5": 925
            },
            "reviews_tags": [
              "closet",
              "sink",
              "suitcases",
              "minimalist",
              "bunk",
              "hangers",
              "cabin",
              "hostel",
              "facilities",
              "theory of categories"
            ],
            "site": "https://all.accor.com/lien_externe.svlt%3Fgoto%3Dfiche_hotel%26code_hotel%3D6350%26merchantid%3Dseo-maps-ES-6350%26sourceid%3Daw-cen%26utm_medium%3Dseo%2520maps%26utm_source%3Dgoogle%2520Maps%26utm_campaign%3Dseo%2520maps",
            "state": "Málaga",
            "street": "Hostal 1 - H/Ma/02083, C. Calvo, 4",
            "street_view": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=5QKDkOSY_v1bEaUqPx3mqA&cb_client=search.gws-prod.gps&w=1600&h=1000&yaw=184.90953&pitch=0&thumbfov=100",
            "subtypes": "Hotel",
            "time_zone": "Europe/Madrid",
            "type": "Hotel",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Urban Sea Atocha 113": [
        [
          {
            "about": {
              "Amenities": {
                "Accessible": true,
                "Air-conditioned": true,
                "Airport shuttle": false,
                "Breakfast": false,
                "Business center": false,
                "Fitness center": false,
                "Free Wi-Fi": true,
                "Parking": false,
                "Pet-friendly": false,
                "Pool": false,
                "Restaurant": false,
                "Smoke-free": true
              },
              "Crowd": {
                "LGBTQ+ friendly": true
              },
              "Other": {
                "Air-conditioned": true,
                "Check-in time": "3:00 PM",
                "Check-out time": "12:00 PM",
                "Free Wi-Fi": true,
                "LGBTQ+ friendly": true,
                "Star rating": "1 stars",
                "Wi-Fi": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "hotels",
            "cid": "6570672011684886378",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": "Minimalist hotel offering white-on-white rooms, plus free Wi-Fi & a rooftop terrace.",
            "full_address": "C. de Atocha, 113, Centro, 28012 Madrid, Spain",
            "google_id": "0xd422628f74ca6c7:0x5b2fb761b814376a",
            "latitude": 40.4103724,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/URBANSEA+Atocha+113/@40.4103724,-3.6946021,14z/data=!4m8!1m2!2m1!1sURBANSEA+Atocha+113!3m4!1s0xd422628f74ca6c7:0x5b2fb761b814376a!8m2!3d40.4103724!4d-3.6946021",
            "location_reviews_link": "https://www.google.com/maps/place/URBANSEA+Atocha+113/@40.4103724,-3.6946021,13.99z/data=!4m16!1m7!3m6!1s0xd422628f74ca6c7:0x5b2fb761b814376a!2sURBANSEA+Atocha+113!8m2!3d40.4103724!4d-3.6946021!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd422628f74ca6c7:0x5b2fb761b814376a!8m2!3d40.4103724!4d-3.6946021!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-VuUrF7mfpJ0/AAAAAAAAAAI/AAAAAAAAAAA/5Rf7TCWHNS4/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -3.6946021,
            "menu_link": null,
            "name": "URBANSEA Atocha 113",
            "name_for_emails": "Urbansea Atocha 113",
            "order_links": null,
            "other_hours": null,
            "owner_id": "112615079361371353403",
            "owner_link": "https://www.google.com/maps/contrib/112615079361371353403",
            "owner_title": "URBANSEA Atocha 113",
            "phone": "+34 913 69 28 95",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipP2l50pfOhECXiWyzNTdySXFNeBHIzQJJ72Xfva=w800-h500-k-no",
            "photos_count": 505,
            "photos_data": [
              {
                "latitude": 40.410224299999996,
                "longitude": -3.6947569999999996,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipP2l50pfOhECXiWyzNTdySXFNeBHIzQJJ72Xfva=w203-h135-k-no",
                "photo_date": "11/17/2021 13:00:00",
                "photo_id": "AF1QipP2l50pfOhECXiWyzNTdySXFNeBHIzQJJ72Xfva",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipP2l50pfOhECXiWyzNTdySXFNeBHIzQJJ72Xfva",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipP2l50pfOhECXiWyzNTdySXFNeBHIzQJJ72Xfva=w2048-h2048-k-no"
              },
              {
                "latitude": 40.410224299999996,
                "longitude": -3.6947569999999996,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMTvUJo_qBif85rg-6LzscK5WoVC9SJqiwFeIIH=w203-h119-k-no",
                "photo_date": "1/16/2020 13:00:00",
                "photo_id": "AF1QipMTvUJo_qBif85rg-6LzscK5WoVC9SJqiwFeIIH",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMTvUJo_qBif85rg-6LzscK5WoVC9SJqiwFeIIH",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMTvUJo_qBif85rg-6LzscK5WoVC9SJqiwFeIIH=w2048-h2048-k-no"
              },
              {
                "latitude": 40.410224299999996,
                "longitude": -3.6947569999999996,
                "original_photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhFjdJh8PQA3-byuhVmAxt03wBAyvLI1DY-9wh5jlvHuXbU9ECM6c1y1bfWta1Ac6pA8IRxygKXM29wz0hd9Iu0SiLOhN65BGoJwmhFp5KPIh0VU5hFJ8QLG7AUGSLBscKTVEecTKTuMthtHk24jOXvFiWZwMeJG1ppxUtdL1FGc1l-6kU1RsnK0jg=w203-h152-k-no",
                "photo_date": "4/12/2024 11:00:00",
                "photo_id": "https://cdn.worldota.net/t/1024x768/content/4d/89/4d8960e20c5aedd7b8a680fd8034c2790538ceb5.jpeg",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "travelads-zenhotels_new",
                "photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhFjdJh8PQA3-byuhVmAxt03wBAyvLI1DY-9wh5jlvHuXbU9ECM6c1y1bfWta1Ac6pA8IRxygKXM29wz0hd9Iu0SiLOhN65BGoJwmhFp5KPIh0VU5hFJ8QLG7AUGSLBscKTVEecTKTuMthtHk24jOXvFiWZwMeJG1ppxUtdL1FGc1l-6kU1RsnK0jg",
                "photo_url_big": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhFjdJh8PQA3-byuhVmAxt03wBAyvLI1DY-9wh5jlvHuXbU9ECM6c1y1bfWta1Ac6pA8IRxygKXM29wz0hd9Iu0SiLOhN65BGoJwmhFp5KPIh0VU5hFJ8QLG7AUGSLBscKTVEecTKTuMthtHk24jOXvFiWZwMeJG1ppxUtdL1FGc1l-6kU1RsnK0jg=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJx6ZM9ygmQg0RajcUuGG3L1s",
            "plus_code": "8CGRC864+45",
            "popular_times": null,
            "postal_code": "28012",
            "posts": null,
            "query": "Urban Sea Atocha 113",
            "range": "1 stars",
            "rating": 3.9,
            "reservation_links": null,
            "reviews": 734,
            "reviews_id": "6570672011684886378",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJx6ZM9ygmQg0RajcUuGG3L1s&q=Urban+Sea+Atocha+113&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 48,
              "2": 24,
              "3": 137,
              "4": 253,
              "5": 272
            },
            "reviews_tags": [
              "atocha station",
              "lobby",
              "cookies",
              "curtains",
              "minimalist",
              "retiro park",
              "infused",
              "estancia",
              "metro",
              "patio"
            ],
            "site": "https://www.blueseahotels.com/hoteles/destinos/madrid/madrid-centro/urbansea-atocha-113?utm_source=mybusiness",
            "state": "Madrid",
            "street": "C. de Atocha, 113",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMpy938n8hToDdvCUgNkxfsGzTP8ZfIY6_gbQuT=w1600-h1000-k-no",
            "subtypes": "Hotel, Holiday apartment",
            "time_zone": "Europe/Madrid",
            "type": "Hotel",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "ibis Sevilla": [
        [
          {
            "about": {
              "Amenities": {
                "Accessible": true,
                "Air-conditioned": true,
                "Airport shuttle": false,
                "Bar": true,
                "Business center": false,
                "Fitness center": false,
                "Free Wi-Fi": true,
                "Kid-friendly": true,
                "Paid breakfast": true,
                "Paid parking": true,
                "Pet-friendly": true,
                "Pool": false,
                "Restaurant": true,
                "Room service": true,
                "Smoke-free": false
              },
              "Other": {
                "Air-conditioned": true,
                "Breakfast": true,
                "Check-in time": "12:00 PM",
                "Check-out time": "12:00 PM",
                "Free Wi-Fi": true,
                "Star rating": "1 stars",
                "Wi-Fi": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Distrito Norte",
            "business_status": "OPERATIONAL",
            "category": "hotels",
            "cid": "6123693249891659047",
            "city": "Seville",
            "country": "Spain",
            "country_code": "ES",
            "description": "Simple budget property with streamlined rooms, plus a relaxed restaurant & a casual bar.",
            "full_address": "Hotel Ciudad 1*, C. Aviación, 12, Norte, 41007 Sevilla, Spain",
            "google_id": "0xd12692df256e935:0x54fbba90e93fed27",
            "h3": "89391c709a3ffff",
            "latitude": 37.4084169,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/ibis+Sevilla/@37.4084169,-5.950676199999999,14z/data=!4m8!1m2!2m1!1sibis+Sevilla!3m4!1s0xd12692df256e935:0x54fbba90e93fed27!8m2!3d37.4084169!4d-5.950676199999999",
            "location_reviews_link": "https://www.google.com/maps/place/ibis+Sevilla/@37.4084169,-5.950676199999999,13.99z/data=!4m16!1m7!3m6!1s0xd12692df256e935:0x54fbba90e93fed27!2sibis+Sevilla!8m2!3d37.4084169!4d-5.950676199999999!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd12692df256e935:0x54fbba90e93fed27!8m2!3d37.4084169!4d-5.950676199999999!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh3.googleusercontent.com/-RRNg0dqxCPE/AAAAAAAAAAI/AAAAAAAAAAA/A3HsSjU3mMo/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -5.950676199999999,
            "menu_link": null,
            "name": "ibis Sevilla",
            "name_for_emails": "Ibis Sevilla",
            "order_links": null,
            "other_hours": null,
            "owner_id": "103060614709976235108",
            "owner_link": "https://www.google.com/maps/contrib/103060614709976235108",
            "owner_title": "ibis Sevilla",
            "phone": "+34 954 36 18 39",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipM8WSnIgjs-9H5icg_npAN4aJ3Zwc1xDfLJHRhs=w800-h500-k-no",
            "photos_count": 1371,
            "photos_data": [
              {
                "latitude": 37.4082127,
                "longitude": -5.9510228,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipM8WSnIgjs-9H5icg_npAN4aJ3Zwc1xDfLJHRhs=w203-h135-k-no",
                "photo_date": "3/10/2017 11:00:00",
                "photo_id": "AF1QipM8WSnIgjs-9H5icg_npAN4aJ3Zwc1xDfLJHRhs",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipM8WSnIgjs-9H5icg_npAN4aJ3Zwc1xDfLJHRhs",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipM8WSnIgjs-9H5icg_npAN4aJ3Zwc1xDfLJHRhs=w2048-h2048-k-no"
              },
              {
                "latitude": 37.4082127,
                "longitude": -5.9510228,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMU6FXknEHHaBMG1-dwqD2vCVNj6dShGmC3t5ng=w203-h135-k-no",
                "photo_date": "2/22/2021 10:00:00",
                "photo_id": "AF1QipMU6FXknEHHaBMG1-dwqD2vCVNj6dShGmC3t5ng",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5,
                  1
                ],
                "photo_tags": [
                  "other",
                  "all"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMU6FXknEHHaBMG1-dwqD2vCVNj6dShGmC3t5ng",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMU6FXknEHHaBMG1-dwqD2vCVNj6dShGmC3t5ng=w2048-h2048-k-no"
              },
              {
                "latitude": 37.4082127,
                "longitude": -5.9510228,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNU-48o545F1WpsvLI4q1ViGFhuWtD5GYBLz0ik=w203-h135-k-no",
                "photo_date": "3/10/2017 11:00:00",
                "photo_id": "AF1QipNU-48o545F1WpsvLI4q1ViGFhuWtD5GYBLz0ik",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNU-48o545F1WpsvLI4q1ViGFhuWtD5GYBLz0ik",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNU-48o545F1WpsvLI4q1ViGFhuWtD5GYBLz0ik=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJNelW8i1pEg0RJ-0_6ZC6-1Q",
            "plus_code": "8C9PC25X+9P",
            "popular_times": null,
            "postal_code": "41007",
            "posts": null,
            "query": "ibis Sevilla",
            "range": "1 stars",
            "rating": 4.1,
            "reservation_links": null,
            "reviews": 1962,
            "reviews_id": "6123693249891659047",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJNelW8i1pEg0RJ-0_6ZC6-1Q&q=ibis+Sevilla&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 89,
              "2": 70,
              "3": 274,
              "4": 688,
              "5": 841
            },
            "reviews_tags": [
              "airport",
              "car park",
              "bus",
              "dresser",
              "economy",
              "estancia",
              "facilities",
              "city center",
              "tobacco",
              "trade"
            ],
            "site": "https://all.accor.com/lien_externe.svlt?goto=fiche_hotel",
            "state": "Seville",
            "street": "Hotel Ciudad 1*, C. Aviación, 12",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMTB8xDj1RZ9QsXS2aD1wfaNvzgk7TKP0yygzQU=w1600-h1000-k-no",
            "subtypes": "Hotel",
            "time_zone": "Europe/Madrid",
            "type": "Hotel",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "La Paradeta, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true
              },
              "Amenities": {
                "Restroom": true
              },
              "Atmosphere": {
                "Casual": true
              },
              "Children": {
                "Good for kids": true,
                "High chairs": true
              },
              "Crowd": {
                "Groups": true
              },
              "Dining options": {
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Small plates": true,
                "Wine": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": false
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Takeout": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Sants-Montjuïc",
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "14566191444307046178",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": null,
            "full_address": "C. de Riego, 27, Sants-Montjuïc, 08014 Barcelona, Spain",
            "google_id": "0x12a4993692cb1e6f:0xca2581f190416f22",
            "latitude": 41.3766215,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/La+Paradeta+Sants/@41.3766215,2.1383123,14z/data=!4m8!1m2!2m1!1sLa+Paradeta+Sants!3m4!1s0x12a4993692cb1e6f:0xca2581f190416f22!8m2!3d41.3766215!4d2.1383123",
            "location_reviews_link": "https://www.google.com/maps/place/La+Paradeta+Sants/@41.3766215,2.1383123,13.99z/data=!4m16!1m7!3m6!1s0x12a4993692cb1e6f:0xca2581f190416f22!2sLa+Paradeta+Sants!8m2!3d41.3766215!4d2.1383123!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4993692cb1e6f:0xca2581f190416f22!8m2!3d41.3766215!4d2.1383123!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-2ttdETzITBk/AAAAAAAAAAI/AAAAAAAAAAA/wNLbzFMsqSI/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1383123,
            "menu_link": null,
            "name": "La Paradeta Sants",
            "name_for_emails": "La Paradeta Sants",
            "order_links": null,
            "other_hours": null,
            "owner_id": "102685107715468303904",
            "owner_link": "https://www.google.com/maps/contrib/102685107715468303904",
            "owner_title": "La Paradeta Sants",
            "phone": "+34 934 31 90 59",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNR8sLskWujOVHyzm5Ymzqy8Lu3sCTHrijZwMnA=w800-h500-k-no",
            "photos_count": 2041,
            "photos_data": [
              {
                "latitude": 41.3766098,
                "longitude": 2.1383505,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNR8sLskWujOVHyzm5Ymzqy8Lu3sCTHrijZwMnA=w205-h100-k-no",
                "photo_date": "2/6/2022 20:00:00",
                "photo_id": "AF1QipNR8sLskWujOVHyzm5Ymzqy8Lu3sCTHrijZwMnA",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5
                ],
                "photo_tags": [
                  "other"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNR8sLskWujOVHyzm5Ymzqy8Lu3sCTHrijZwMnA",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNR8sLskWujOVHyzm5Ymzqy8Lu3sCTHrijZwMnA=w2048-h2048-k-no"
              },
              {
                "latitude": 41.3766098,
                "longitude": 2.1383505,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOwP18Wg0hP02rqW6OftwdMdkbqt-FX7bUb-wxk=w203-h203-k-no",
                "photo_date": "12/23/2021 12:00:00",
                "photo_id": "AF1QipOwP18Wg0hP02rqW6OftwdMdkbqt-FX7bUb-wxk",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipOwP18Wg0hP02rqW6OftwdMdkbqt-FX7bUb-wxk=m18",
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOwP18Wg0hP02rqW6OftwdMdkbqt-FX7bUb-wxk",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOwP18Wg0hP02rqW6OftwdMdkbqt-FX7bUb-wxk=w2048-h2048-k-no"
              },
              {
                "latitude": 41.3766098,
                "longitude": 2.1383505,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMO7TV7NoRpFB49DMHZr7EuOVGvB44rXB-yOBVD=w203-h114-k-no",
                "photo_date": "7/17/2023 9:00:00",
                "photo_id": "AF1QipMO7TV7NoRpFB49DMHZr7EuOVGvB44rXB-yOBVD",
                "photo_source_video": null,
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmb_on_local_universal_desktop",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMO7TV7NoRpFB49DMHZr7EuOVGvB44rXB-yOBVD",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMO7TV7NoRpFB49DMHZr7EuOVGvB44rXB-yOBVD=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJbx7LkjaZpBIRIm9BkPGBJco",
            "plus_code": null,
            "popular_times": null,
            "postal_code": "08014",
            "posts": null,
            "query": "La Paradeta, Barcelona",
            "range": "$$",
            "rating": 4.3,
            "reservation_links": null,
            "reviews": 1851,
            "reviews_id": "-3880552629402505438",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJbx7LkjaZpBIRIm9BkPGBJco&q=La+Paradeta,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 50,
              "2": 49,
              "3": 199,
              "4": 583,
              "5": 970
            },
            "reviews_tags": null,
            "site": "http://www.laparadeta.com/",
            "state": "Barcelona",
            "street": "C. de Riego, 27",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNR8sLskWujOVHyzm5Ymzqy8Lu3sCTHrijZwMnA=w1600-h1000-k-no",
            "subtypes": "Restaurant, Seafood restaurant",
            "time_zone": "Europe/Madrid",
            "type": "Restaurant",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "8-11PM",
              "Monday": "Closed",
              "Saturday": "1-4PM,8-11PM",
              "Sunday": "1-4PM",
              "Thursday": "8-11PM",
              "Tuesday": "8-11PM",
              "Wednesday": "8-11PM"
            },
            "working_hours_old_format": "Monday:Closed|Tuesday:8-11PM|Wednesday:8-11PM|Thursday:8-11PM|Friday:8-11PM|Saturday:1-4PM,8-11PM|Sunday:1-4PM"
          }
        ]
      ],
      "La Barceloneta Beach, Barcelona": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": null,
            "cid": "2201713585473283579",
            "city": "ES",
            "country": "Spain",
            "country_code": "ES",
            "description": "Popular swimming & lounging haunt with many services, including a lifeguard, rental chairs & Wi-Fi.",
            "full_address": "Spain",
            "google_id": "0x12a4a3a809389627:0x1e8e0ed73f4965fb",
            "latitude": 41.3783713,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Barceloneta+Beach/@41.3783713,2.1924685,14z/data=!4m8!1m2!2m1!1sBarceloneta+Beach!3m4!1s0x12a4a3a809389627:0x1e8e0ed73f4965fb!8m2!3d41.3783713!4d2.1924685",
            "location_reviews_link": "https://www.google.com/maps/place/Barceloneta+Beach/@41.3783713,2.1924685,13.99z/data=!4m16!1m7!3m6!1s0x12a4a3a809389627:0x1e8e0ed73f4965fb!2sBarceloneta+Beach!8m2!3d41.3783713!4d2.1924685!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a3a809389627:0x1e8e0ed73f4965fb!8m2!3d41.3783713!4d2.1924685!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": 2.1924685,
            "menu_link": null,
            "name": "Barceloneta Beach",
            "name_for_emails": "Barceloneta Beach",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipMN5wJmmxAaxKDZQxQIfy1Og9vpvihgEJ_urhbs=w800-h500-k-no",
            "photos_count": 17728,
            "photos_data": [
              {
                "latitude": 41.378371324159694,
                "longitude": 2.1924685101582813,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMN5wJmmxAaxKDZQxQIfy1Og9vpvihgEJ_urhbs=w203-h152-k-no",
                "photo_date": "1/4/2023 12:00:00",
                "photo_id": "AF1QipMN5wJmmxAaxKDZQxQIfy1Og9vpvihgEJ_urhbs",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMN5wJmmxAaxKDZQxQIfy1Og9vpvihgEJ_urhbs",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMN5wJmmxAaxKDZQxQIfy1Og9vpvihgEJ_urhbs=w2048-h2048-k-no"
              },
              {
                "latitude": 41.378371324159694,
                "longitude": 2.1924685101582813,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOWqItNkIF3usGtHtQr66_tixooPvxifpc6TwPE=w203-h360-k-no",
                "photo_date": "2/13/2023 11:00:00",
                "photo_id": "AF1QipOWqItNkIF3usGtHtQr66_tixooPvxifpc6TwPE",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipOWqItNkIF3usGtHtQr66_tixooPvxifpc6TwPE=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOWqItNkIF3usGtHtQr66_tixooPvxifpc6TwPE",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOWqItNkIF3usGtHtQr66_tixooPvxifpc6TwPE=w2048-h2048-k-no"
              },
              {
                "latitude": 41.378371324159694,
                "longitude": 2.1924685101582813,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNgF_YzI6zOcynif5ZFRGpRcD9XgU_qmwaM-vXJ=w203-h114-k-no",
                "photo_date": "5/16/2023 8:00:00",
                "photo_id": "AF1QipNgF_YzI6zOcynif5ZFRGpRcD9XgU_qmwaM-vXJ",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNgF_YzI6zOcynif5ZFRGpRcD9XgU_qmwaM-vXJ",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNgF_YzI6zOcynif5ZFRGpRcD9XgU_qmwaM-vXJ=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJJ5Y4CaijpBIR-2VJP9cOjh4",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "La Barceloneta Beach, Barcelona",
            "range": null,
            "rating": 4.4,
            "reservation_links": null,
            "reviews": 9899,
            "reviews_id": "2201713585473283579",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJJ5Y4CaijpBIR-2VJP9cOjh4&q=*&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 273,
              "2": 325,
              "3": 951,
              "4": 2143,
              "5": 6207
            },
            "reviews_tags": [
              "massages",
              "mojito",
              "pickpockets",
              "blankets",
              "metro",
              "las ramblas",
              "sales assistant",
              "gym",
              "braids",
              "el periódico mediterráneo"
            ],
            "site": null,
            "state": null,
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNgF_YzI6zOcynif5ZFRGpRcD9XgU_qmwaM-vXJ=w1600-h1000-k-no",
            "subtypes": "Beach",
            "time_zone": "Europe/Madrid",
            "type": "Beach",
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Park Güell, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true
              },
              "Activities": {
                "Hiking": true
              },
              "Amenities": {
                "Picnic tables": true,
                "Public restroom": true,
                "Swings": true
              },
              "Children": {
                "Good for kids": true,
                "Kid-friendly hikes": true,
                "Playground": true
              },
              "Pets": {
                "Dogs allowed": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Gràcia",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "9915250311879642712",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Mosaic-covered buildings, steps & sculptures in verdant park with Gaudi museum & panoramic views.",
            "full_address": "Gràcia, 08024 Barcelona, Spain",
            "google_id": "0x12a4a2ae52d441ab:0x899a0ba01aaace58",
            "latitude": 41.4144948,
            "located_google_id": "0x12a4a308fd241f7b:0x3be1967d61864132",
            "located_in": "Playground",
            "location_link": "https://www.google.com/maps/place/Park+G%C3%BCell/@41.4144948,2.1526945,14z/data=!4m8!1m2!2m1!1sPark+G%C3%BCell!3m4!1s0x12a4a2ae52d441ab:0x899a0ba01aaace58!8m2!3d41.4144948!4d2.1526945",
            "location_reviews_link": "https://www.google.com/maps/place/Park+G%C3%BCell/@41.4144948,2.1526945,13.99z/data=!4m16!1m7!3m6!1s0x12a4a2ae52d441ab:0x899a0ba01aaace58!2sPark+G%C3%BCell!8m2!3d41.4144948!4d2.1526945!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a2ae52d441ab:0x899a0ba01aaace58!8m2!3d41.4144948!4d2.1526945!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-6AWdCT-N0Uc/AAAAAAAAAAI/AAAAAAAAAAA/7gOYWSovvsU/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1526945,
            "menu_link": null,
            "name": "Park Güell",
            "order_links": null,
            "other_hours": [
              {
                "playground": {
                  "Friday": "Open 24 hours",
                  "Monday": "Open 24 hours",
                  "Saturday": "Open 24 hours",
                  "Sunday": "Open 24 hours",
                  "Thursday": "Open 24 hours",
                  "Tuesday": "Open 24 hours",
                  "Wednesday": "Open 24 hours"
                }
              }
            ],
            "owner_id": "114900873705773848685",
            "owner_link": "https://www.google.com/maps/contrib/114900873705773848685",
            "owner_title": "Park Güell",
            "phone": "+34 934 09 18 31",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNgwQHFyIjmdNz9RYHLND4_2hXzrBmqObHjBIfR=w800-h500-k-no",
            "photos_count": 1353188,
            "photos_data": [
              {
                "latitude": 41.41449481389226,
                "longitude": 2.152694510596638,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNgwQHFyIjmdNz9RYHLND4_2hXzrBmqObHjBIfR=w203-h151-k-no",
                "photo_date": "10/19/2022 15:00:00",
                "photo_id": "AF1QipNgwQHFyIjmdNz9RYHLND4_2hXzrBmqObHjBIfR",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNgwQHFyIjmdNz9RYHLND4_2hXzrBmqObHjBIfR",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNgwQHFyIjmdNz9RYHLND4_2hXzrBmqObHjBIfR=w2048-h2048-k-no"
              },
              {
                "latitude": 41.41449481389226,
                "longitude": 2.152694510596638,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN0YqIz0vJ3ltFYLLwkjf7yxcsuO1A0uf1GenAd=w203-h114-k-no",
                "photo_date": "1/7/2020 21:00:00",
                "photo_id": "AF1QipN0YqIz0vJ3ltFYLLwkjf7yxcsuO1A0uf1GenAd",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipN0YqIz0vJ3ltFYLLwkjf7yxcsuO1A0uf1GenAd=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN0YqIz0vJ3ltFYLLwkjf7yxcsuO1A0uf1GenAd",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN0YqIz0vJ3ltFYLLwkjf7yxcsuO1A0uf1GenAd=w2048-h2048-k-no"
              },
              {
                "latitude": 41.41449481389226,
                "longitude": 2.152694510596638,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMln8LxOT3DvEsPFhZGEmqSNNR_jHYkihksbcpu=w203-h114-k-no",
                "photo_date": "6/13/2023 19:00:00",
                "photo_id": "AF1QipMln8LxOT3DvEsPFhZGEmqSNNR_jHYkihksbcpu",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android_photo_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMln8LxOT3DvEsPFhZGEmqSNNR_jHYkihksbcpu",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMln8LxOT3DvEsPFhZGEmqSNNR_jHYkihksbcpu=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJq0HUUq6ipBIRWM6qGqALmok",
            "plus_code": "8FH4C573+Q3",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 35,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 65,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 89,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 98,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 95,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 74,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 61,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 45,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 29,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 16,
                    "time": "6p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 34,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 58,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 77,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 83,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 79,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 73,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 68,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 61,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 33,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 20,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 32,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 52,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 69,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 74,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 72,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 69,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 65,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 46,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 31,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 18,
                    "time": "6p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 33,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 56,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 75,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 81,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 76,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 66,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 46,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 32,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 19,
                    "time": "6p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 33,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 56,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 76,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 83,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 80,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 76,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 72,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 50,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 34,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 20,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 36,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 62,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 83,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 89,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 78,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 73,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 66,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 53,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 36,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 21,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 68,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 92,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 95,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 79,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 68,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 51,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 33,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 19,
                    "time": "6p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "08024",
            "posts": null,
            "query": "Park Güell, Barcelona",
            "range": null,
            "rating": 4.4,
            "reservation_links": null,
            "reviews": 192909,
            "reviews_id": "-8531493761829908904",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJq0HUUq6ipBIRWM6qGqALmok&q=Park+G%C3%BCell,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 6070,
              "2": 5681,
              "3": 18415,
              "4": 43504,
              "5": 119239
            },
            "reviews_tags": [
              "gaudi",
              "architecture",
              "mosaic",
              "sagrada familia",
              "ticket",
              "escalators",
              "audio guide",
              "paid area",
              "gingerbread house",
              "trencadís"
            ],
            "site": "https://parkguell.barcelona/",
            "state": "Barcelona",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipOqNNDDYQDOmtN-LiBCQbcXBJOZ_7iEn95zZCaN=w1600-h1000-k-no",
            "subtypes": "Park, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Park",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "9:30AM-7:30PM",
              "Monday": "9:30AM-7:30PM",
              "Saturday": "9:30AM-7:30PM",
              "Sunday": "9:30AM-7:30PM",
              "Thursday": "9:30AM-7:30PM",
              "Tuesday": "9:30AM-7:30PM",
              "Wednesday": "9:30AM-7:30PM"
            },
            "working_hours_old_format": "Monday:9:30AM-7:30PM|Tuesday:9:30AM-7:30PM|Wednesday:9:30AM-7:30PM|Thursday:9:30AM-7:30PM|Friday:9:30AM-7:30PM|Saturday:9:30AM-7:30PM|Sunday:9:30AM-7:30PM"
          }
        ]
      ],
      "Bogatell Beach, Barcelona": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Sant Martí",
            "business_status": "OPERATIONAL",
            "category": null,
            "cid": "7296628446138130342",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Large, bustling beach featuring a wide stretch of sand used for volleyball, sunbathing & more.",
            "full_address": "Sant Martí, Barcelona, Spain",
            "google_id": "0x12a4a36c5a05f533:0x6542d4e99e12dfa6",
            "latitude": 41.3943735,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Playa+de+Bogatell/@41.3943735,2.2070108999999998,14z/data=!4m8!1m2!2m1!1sPlaya+de+Bogatell!3m4!1s0x12a4a36c5a05f533:0x6542d4e99e12dfa6!8m2!3d41.3943735!4d2.2070108999999998",
            "location_reviews_link": "https://www.google.com/maps/place/Playa+de+Bogatell/@41.3943735,2.2070108999999998,13.99z/data=!4m16!1m7!3m6!1s0x12a4a36c5a05f533:0x6542d4e99e12dfa6!2sPlaya+de+Bogatell!8m2!3d41.3943735!4d2.2070108999999998!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a36c5a05f533:0x6542d4e99e12dfa6!8m2!3d41.3943735!4d2.2070108999999998!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": 2.2070108999999998,
            "menu_link": null,
            "name": "Playa de Bogatell",
            "name_for_emails": "Playa De Bogatell",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipOjcZHouUsrvVltxeQd8KfNQyt1-8LnlmdC9LTL=w800-h500-k-no",
            "photos_count": 2987,
            "photos_data": [
              {
                "latitude": 41.39437351208024,
                "longitude": 2.2070109248312044,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOjcZHouUsrvVltxeQd8KfNQyt1-8LnlmdC9LTL=w203-h152-k-no",
                "photo_date": "1/22/2023 11:00:00",
                "photo_id": "AF1QipOjcZHouUsrvVltxeQd8KfNQyt1-8LnlmdC9LTL",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOjcZHouUsrvVltxeQd8KfNQyt1-8LnlmdC9LTL",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOjcZHouUsrvVltxeQd8KfNQyt1-8LnlmdC9LTL=w2048-h2048-k-no"
              },
              {
                "latitude": 41.39437351208024,
                "longitude": 2.2070109248312044,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNCH7F-UwZToHk2IDsyloIF5hkpM9hqSvpW98_q=w203-h114-k-no",
                "photo_date": "1/18/2022 17:00:00",
                "photo_id": "AF1QipNCH7F-UwZToHk2IDsyloIF5hkpM9hqSvpW98_q",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipNCH7F-UwZToHk2IDsyloIF5hkpM9hqSvpW98_q=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNCH7F-UwZToHk2IDsyloIF5hkpM9hqSvpW98_q",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNCH7F-UwZToHk2IDsyloIF5hkpM9hqSvpW98_q=w2048-h2048-k-no"
              },
              {
                "latitude": 41.39437351208024,
                "longitude": 2.2070109248312044,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPyhX_4nj7JfUrr0sfXjDSjWpqD5LWFQL1DVpSM=w203-h135-k-no",
                "photo_date": "5/1/2020 13:00:00",
                "photo_id": "AF1QipPyhX_4nj7JfUrr0sfXjDSjWpqD5LWFQL1DVpSM",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPyhX_4nj7JfUrr0sfXjDSjWpqD5LWFQL1DVpSM",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPyhX_4nj7JfUrr0sfXjDSjWpqD5LWFQL1DVpSM=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJM_UFWmyjpBIRpt8SnunUQmU",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Bogatell Beach, Barcelona",
            "range": null,
            "rating": 4.4,
            "reservation_links": null,
            "reviews": 2395,
            "reviews_id": "7296628446138130342",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJM_UFWmyjpBIRpt8SnunUQmU&q=Bogatell+Beach,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 62,
              "2": 63,
              "3": 237,
              "4": 602,
              "5": 1431
            },
            "reviews_tags": [
              "volleyball",
              "chiringuito",
              "summer",
              "clean",
              "games",
              "massage",
              "winter",
              "pickpockets",
              "salesperson",
              "yoga"
            ],
            "site": null,
            "state": null,
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipPyhX_4nj7JfUrr0sfXjDSjWpqD5LWFQL1DVpSM=w1600-h1000-k-no",
            "subtypes": "Beach",
            "time_zone": "Europe/Madrid",
            "type": "Beach",
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "La Sagrada Familia, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true
              },
              "Planning": {
                "Getting tickets in advance recommended": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Eixample",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "11229913421514525008",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Antoni Gaudí's renowned unfinished church, started in the 1880s, with museum and city views.",
            "full_address": "C/ de Mallorca, 401, L'Eixample, 08013 Barcelona, Spain",
            "google_id": "0x12a4a2dcd83dfb93:0x9bd8aac21bc3c950",
            "latitude": 41.4036299,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/La+Sagrada+Familia/@41.4036299,2.1743558,14z/data=!4m8!1m2!2m1!1sLa+Sagrada+Familia!3m4!1s0x12a4a2dcd83dfb93:0x9bd8aac21bc3c950!8m2!3d41.4036299!4d2.1743558",
            "location_reviews_link": "https://www.google.com/maps/place/La+Sagrada+Familia/@41.4036299,2.1743558,13.99z/data=!4m16!1m7!3m6!1s0x12a4a2dcd83dfb93:0x9bd8aac21bc3c950!2sLa+Sagrada+Familia!8m2!3d41.4036299!4d2.1743558!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a2dcd83dfb93:0x9bd8aac21bc3c950!8m2!3d41.4036299!4d2.1743558!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh3.googleusercontent.com/-oWKlbw4NI9k/AAAAAAAAAAI/AAAAAAAAAAA/oyIn0t3ug1U/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1743558,
            "menu_link": null,
            "name": "La Sagrada Familia",
            "order_links": null,
            "other_hours": null,
            "owner_id": "112747286341876798697",
            "owner_link": "https://www.google.com/maps/contrib/112747286341876798697",
            "owner_title": "La Sagrada Familia",
            "phone": "+34 932 08 04 14",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPvcLglf3S0-gNAZEVATnRTaObdbR4fcclktCO0=w800-h500-k-no",
            "photos_count": 1419290,
            "photos_data": [
              {
                "latitude": 41.40362987249877,
                "longitude": 2.174355778943138,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPvcLglf3S0-gNAZEVATnRTaObdbR4fcclktCO0=w203-h152-k-no",
                "photo_date": "12/31/2022 18:00:00",
                "photo_id": "AF1QipPvcLglf3S0-gNAZEVATnRTaObdbR4fcclktCO0",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android_photo_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPvcLglf3S0-gNAZEVATnRTaObdbR4fcclktCO0",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPvcLglf3S0-gNAZEVATnRTaObdbR4fcclktCO0=w2048-h2048-k-no"
              },
              {
                "latitude": 41.40362987249877,
                "longitude": 2.174355778943138,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNmg_BIV0FLPHxRP-rdOBU0QyoO6-zFE8I2PTN1=w203-h360-k-no",
                "photo_date": "6/25/2019 5:00:00",
                "photo_id": "AF1QipNmg_BIV0FLPHxRP-rdOBU0QyoO6-zFE8I2PTN1",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipNmg_BIV0FLPHxRP-rdOBU0QyoO6-zFE8I2PTN1=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNmg_BIV0FLPHxRP-rdOBU0QyoO6-zFE8I2PTN1",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNmg_BIV0FLPHxRP-rdOBU0QyoO6-zFE8I2PTN1=w2048-h2048-k-no"
              },
              {
                "latitude": 41.40362987249877,
                "longitude": 2.174355778943138,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPgZKBRhlEpj1POjiymNHvOBTuNMauGs4Zxqfi4=w203-h197-k-no",
                "photo_date": "12/16/2019 21:00:00",
                "photo_id": "AF1QipPgZKBRhlEpj1POjiymNHvOBTuNMauGs4Zxqfi4",
                "photo_source_video": null,
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPgZKBRhlEpj1POjiymNHvOBTuNMauGs4Zxqfi4",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPgZKBRhlEpj1POjiymNHvOBTuNMauGs4Zxqfi4=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJk_s92NyipBIRUMnDG8Kq2Js",
            "plus_code": "8FH4C53F+FP",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 54,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 77,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 91,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 92,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 80,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 76,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 69,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 36,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 54,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 70,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 78,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 77,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 73,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 69,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 65,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 35,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 51,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 65,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 66,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 62,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 35,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 51,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 65,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 68,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 66,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 36,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 53,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 68,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 72,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 70,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 61,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 39,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 60,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 79,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 87,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 81,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 75,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 70,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 41,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 66,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 88,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 99,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 93,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 87,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 83,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 75,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "08013",
            "posts": null,
            "query": "La Sagrada Familia, Barcelona",
            "range": null,
            "rating": 4.8,
            "reservation_links": null,
            "reviews": 247229,
            "reviews_id": "-7216830652195026608",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJk_s92NyipBIRUMnDG8Kq2Js&q=La+Sagrada+Familia,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 2714,
              "2": 2088,
              "3": 7899,
              "4": 28888,
              "5": 205640
            },
            "reviews_tags": [
              "gaudi",
              "audio guide",
              "tower",
              "monument",
              "2026",
              "tickets",
              "guided tour",
              "tickets",
              "1882",
              "unfinished work"
            ],
            "site": "https://sagradafamilia.org/",
            "state": "Barcelona",
            "street": "C/ de Mallorca, 401",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNCsrkeY4PevcXfZYRr90OloMGXlXdfUKrvGRZx=w1600-h1000-k-no",
            "subtypes": "Basilica, Church, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Basilica",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "9AM-8PM",
              "Monday": "9AM-8PM",
              "Saturday": "9AM-6PM",
              "Sunday": "10:30AM-8PM",
              "Thursday": "9AM-8PM",
              "Tuesday": "9AM-8PM",
              "Wednesday": "9AM-8PM"
            },
            "working_hours_old_format": "Monday:9AM-8PM|Tuesday:9AM-8PM|Wednesday:9AM-8PM|Thursday:9AM-8PM|Friday:9AM-8PM|Saturday:9AM-6PM|Sunday:10:30AM-8PM"
          }
        ]
      ],
      "Camp Nou, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true
              },
              "Payments": {
                "Debit cards": true
              },
              "Planning": {
                "Getting tickets in advance recommended": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Les Corts",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "4969250708736640185",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Home ground for famous Barcelona football club, with regular matches plus stadium tours and museum.",
            "full_address": "C/ d'Arístides Maillol, 12, Les Corts, 08028 Barcelona, Spain",
            "google_id": "0x12a498f576297baf:0x44f65330fe1b04b9",
            "latitude": 41.380896,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Spotify+Camp+Nou/@41.380896,2.1228198,14z/data=!4m8!1m2!2m1!1sSpotify+Camp+Nou!3m4!1s0x12a498f576297baf:0x44f65330fe1b04b9!8m2!3d41.380896!4d2.1228198",
            "location_reviews_link": "https://www.google.com/maps/place/Spotify+Camp+Nou/@41.380896,2.1228198,13.99z/data=!4m16!1m7!3m6!1s0x12a498f576297baf:0x44f65330fe1b04b9!2sSpotify+Camp+Nou!8m2!3d41.380896!4d2.1228198!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a498f576297baf:0x44f65330fe1b04b9!8m2!3d41.380896!4d2.1228198!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh3.googleusercontent.com/-bV3m7CvyZAw/AAAAAAAAAAI/AAAAAAAAAAA/Jiil3dnHCBc/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1228198,
            "menu_link": null,
            "name": "Spotify Camp Nou",
            "order_links": null,
            "other_hours": null,
            "owner_id": "113155163495804921362",
            "owner_link": "https://www.google.com/maps/contrib/113155163495804921362",
            "owner_title": "Spotify Camp Nou",
            "phone": "+34 902 18 99 00",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipMLAdUJ03345s-aBYZzbTSoNJT10pRFYiQqbBa8=w800-h500-k-no",
            "photos_count": 613812,
            "photos_data": [
              {
                "latitude": 41.380896023259204,
                "longitude": 2.1228198094261823,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMLAdUJ03345s-aBYZzbTSoNJT10pRFYiQqbBa8=w203-h114-k-no",
                "photo_date": "4/19/2022 6:00:00",
                "photo_id": "AF1QipMLAdUJ03345s-aBYZzbTSoNJT10pRFYiQqbBa8",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMLAdUJ03345s-aBYZzbTSoNJT10pRFYiQqbBa8",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMLAdUJ03345s-aBYZzbTSoNJT10pRFYiQqbBa8=w2048-h2048-k-no"
              },
              {
                "latitude": 41.380896023259204,
                "longitude": 2.1228198094261823,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPjSEFcXtkv-Y2a7igbaCKyYD0Efx705QZtdDaE=w203-h114-k-no",
                "photo_date": "8/3/2019 19:00:00",
                "photo_id": "AF1QipPjSEFcXtkv-Y2a7igbaCKyYD0Efx705QZtdDaE",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPjSEFcXtkv-Y2a7igbaCKyYD0Efx705QZtdDaE=m18",
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPjSEFcXtkv-Y2a7igbaCKyYD0Efx705QZtdDaE",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPjSEFcXtkv-Y2a7igbaCKyYD0Efx705QZtdDaE=w2048-h2048-k-no"
              },
              {
                "latitude": 41.380896023259204,
                "longitude": 2.1228198094261823,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNLIQ3j2KyO8NUrBeRWfQ0aNvwWpTVTPT6YkBvO=w203-h135-k-no",
                "photo_date": "6/15/2022 12:00:00",
                "photo_id": "AF1QipNLIQ3j2KyO8NUrBeRWfQ0aNvwWpTVTPT6YkBvO",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNLIQ3j2KyO8NUrBeRWfQ0aNvwWpTVTPT6YkBvO",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNLIQ3j2KyO8NUrBeRWfQ0aNvwWpTVTPT6YkBvO=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJr3spdvWYpBIRuQQb_jBT9kQ",
            "plus_code": "8FH494JF+94",
            "popular_times": null,
            "postal_code": "08028",
            "posts": null,
            "query": "Camp Nou, Barcelona",
            "range": null,
            "rating": 4.6,
            "reservation_links": null,
            "reviews": 157149,
            "reviews_id": "4969250708736640185",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJr3spdvWYpBIRuQQb_jBT9kQ&q=Camp+Nou,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 5234,
              "2": 2415,
              "3": 7490,
              "4": 21893,
              "5": 120117
            },
            "reviews_tags": [
              "barcelona",
              "museum",
              "més que un club",
              "audio guide",
              "VR",
              "neymar",
              "ballon d'or",
              "1957",
              "suarez",
              "hala madrid"
            ],
            "site": "https://www.fcbarcelona.com/club/facilities/new-camp-nou",
            "state": "Barcelona",
            "street": "C/ d'Arístides Maillol, 12",
            "street_view": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=I2fHKtE_F_DtgRCypwZ6CA&cb_client=search.gws-prod.gps&w=1600&h=1000&yaw=110.530205&pitch=0&thumbfov=100",
            "subtypes": "Stadium, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Stadium",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Els Quatre Gats, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible seating": true
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true
              },
              "Atmosphere": {
                "Casual": true,
                "Cozy": true,
                "Romantic": true
              },
              "Children": {
                "High chairs": true
              },
              "Crowd": {
                "Groups": true
              },
              "Dining options": {
                "Breakfast": true,
                "Brunch": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Highlights": {
                "Great cocktails": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Late-night food": true,
                "Small plates": true,
                "Wine": true
              },
              "Parking": {
                "Paid street parking": true,
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Takeout": false
              }
            },
            "area_service": false,
            "booking_appointment_link": "https://4gats.com/reserves/",
            "borough": "Ciutat Vella",
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "14953139396855923916",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Art nouveau-style cafe opened in 1896, hub for the Modernisme movement, now serving Catalan cuisine.",
            "full_address": "Carrer de Montsió, 3, Ciutat Vella, 08002 Barcelona, Spain",
            "google_id": "0x12a4a2f0b32ce7e1:0xcf84390e6a7f04cc",
            "h3": "89394460e43ffff",
            "latitude": 41.3857621,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Els+4Gats/@41.3857621,2.1736964,14z/data=!4m8!1m2!2m1!1sEls+4Gats!3m4!1s0x12a4a2f0b32ce7e1:0xcf84390e6a7f04cc!8m2!3d41.3857621!4d2.1736964",
            "location_reviews_link": "https://www.google.com/maps/place/Els+4Gats/@41.3857621,2.1736964,13.99z/data=!4m16!1m7!3m6!1s0x12a4a2f0b32ce7e1:0xcf84390e6a7f04cc!2sEls+4Gats!8m2!3d41.3857621!4d2.1736964!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a2f0b32ce7e1:0xcf84390e6a7f04cc!8m2!3d41.3857621!4d2.1736964!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh4.googleusercontent.com/-XvkYxz6qnjM/AAAAAAAAAAI/AAAAAAAAAAA/k7zCXdONjlM/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1736964,
            "menu_link": "https://4gats.com/",
            "name": "Els 4Gats",
            "name_for_emails": "Els 4Gats",
            "order_links": [
              "https://4gats.com/reserves/"
            ],
            "other_hours": null,
            "owner_id": "108852709215732136935",
            "owner_link": "https://www.google.com/maps/contrib/108852709215732136935",
            "owner_title": "Els 4Gats",
            "phone": "+34 933 02 41 40",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPRoDtJ-gRpK6q-_cg_xVlAdMAb7v3Gl6DAa3jf=w800-h500-k-no",
            "photos_count": 12036,
            "photos_data": [
              {
                "latitude": 41.385727599999996,
                "longitude": 2.1737227999999997,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPRoDtJ-gRpK6q-_cg_xVlAdMAb7v3Gl6DAa3jf=w203-h100-k-no-pi-13.729903-ya16.430868-ro0-fo100",
                "photo_date": "2/15/2018 16:00:00",
                "photo_id": "AF1QipPRoDtJ-gRpK6q-_cg_xVlAdMAb7v3Gl6DAa3jf",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1,
                  9
                ],
                "photo_tags": [
                  "all",
                  "vibe"
                ],
                "photo_upload_source": "geo_transfer",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPRoDtJ-gRpK6q-_cg_xVlAdMAb7v3Gl6DAa3jf",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPRoDtJ-gRpK6q-_cg_xVlAdMAb7v3Gl6DAa3jf=w2048-h2048-k-no"
              },
              {
                "latitude": 41.3858096332757,
                "longitude": 2.173574318483492,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPIh7K_up6xUDu_nSkyZVJ4lPav_kIptVEsJK2y=w203-h360-k-no",
                "photo_date": "4/3/2023 7:00:00",
                "photo_id": "AF1QipPIh7K_up6xUDu_nSkyZVJ4lPav_kIptVEsJK2y",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPIh7K_up6xUDu_nSkyZVJ4lPav_kIptVEsJK2y=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPIh7K_up6xUDu_nSkyZVJ4lPav_kIptVEsJK2y",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPIh7K_up6xUDu_nSkyZVJ4lPav_kIptVEsJK2y=w2048-h2048-k-no"
              },
              {
                "latitude": 41.385727599999996,
                "longitude": 2.1737227999999997,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPSrIB8XQznQNWd164eYn9dkZpvK1WrWwGUDnIz=w203-h270-k-no",
                "photo_date": "5/4/2024 21:00:00",
                "photo_id": "AF1QipPSrIB8XQznQNWd164eYn9dkZpvK1WrWwGUDnIz",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPSrIB8XQznQNWd164eYn9dkZpvK1WrWwGUDnIz",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPSrIB8XQznQNWd164eYn9dkZpvK1WrWwGUDnIz=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ4ecss_CipBIRzAR_ag45hM8",
            "plus_code": "8FH495PF+8F",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 34,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 50,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 59,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 61,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": []
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 33,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 48,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 59,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 64,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 61,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 64,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 69,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 69,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 56,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 43,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 27,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 39,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 56,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 64,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 66,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 56,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 54,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 51,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 48,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 46,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 37,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 25,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 39,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 51,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 55,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 59,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 54,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 57,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 62,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 57,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 48,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 37,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 24,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 40,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 53,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 65,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 71,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 66,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 65,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 66,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 65,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 67,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 62,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 51,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 34,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 40,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 60,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 83,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 94,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 100,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 95,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 90,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 88,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 90,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 84,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 70,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 55,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 34,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": "live",
                "percentage": 53,
                "time": 18,
                "title": "A little busy"
              }
            ],
            "postal_code": "08002",
            "posts": [
              {
                "body": "El próximo domingo 25 y lunes 26 de febrero abrimos para cenas. Haz tu reserva!",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipPBrzlxOgHFzrl8C9-JSfO9tKuvIu_l9qIKRnoQ=h400-no",
                "link": "https://search.google.com/local/posts?q=Els+4Gats&ludocid=14953139396855923916&lpsid=CIHM0ogKEICAgIDdqJecrwE&source=sh/x/localposts&lsig=AB86z5WFWD-RunrojmnUMTZkdGp7",
                "timestamp": 1708449929
              }
            ],
            "query": "Els Quatre Gats, Barcelona",
            "range": "$$",
            "rating": 4.1,
            "reservation_links": [
              "https://4gats.com/reserves/"
            ],
            "reviews": 6847,
            "reviews_id": "-3493604676853627700",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJ4ecss_CipBIRzAR_ag45hM8&q=Els+Quatre+Gats,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 524,
              "2": 340,
              "3": 759,
              "4": 1816,
              "5": 3408
            },
            "reviews_tags": [
              "picasso",
              "piano",
              "modernisme",
              "historical place",
              "gaudi",
              "bohemian",
              "20th century",
              "1897",
              "writer",
              "woody allen"
            ],
            "site": "http://www.4gats.com/",
            "state": "Barcelona",
            "street": "Carrer de Montsió, 3",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipPRoDtJ-gRpK6q-_cg_xVlAdMAb7v3Gl6DAa3jf=w1600-h1000-k-no",
            "subtypes": "Restaurant, Bar, Cafe",
            "time_zone": "Europe/Madrid",
            "type": "Restaurant",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "11AM-12AM",
              "Monday": "Closed",
              "Saturday": "11AM-12AM",
              "Sunday": "11AM-4PM",
              "Thursday": "11AM-12AM",
              "Tuesday": "11AM-12AM",
              "Wednesday": "11AM-12AM"
            },
            "working_hours_old_format": "Monday:Closed|Tuesday:11AM-12AM|Wednesday:11AM-12AM|Thursday:11AM-12AM|Friday:11AM-12AM|Saturday:11AM-12AM|Sunday:11AM-4PM"
          }
        ]
      ],
      "La Boqueria Market, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true
              },
              "Crowd": {
                "LGBTQ+ friendly": true
              },
              "Other": {
                "LGBTQ+ friendly": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Service options": {
                "In-store shopping": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Ciutat Vella",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "9658750418627571157",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Famous indoor public market with meat, produce, cheese & an array of other food in a bustling space.",
            "full_address": "La Rambla, 91, Ciutat Vella, 08001 Barcelona, Spain",
            "google_id": "0x12a4a2f7b51e5a01:0x860ac654dc73add5",
            "latitude": 41.3817399,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Mercado+de+La+Boqueria/@41.3817399,2.1715853,14z/data=!4m8!1m2!2m1!1sMercado+de+La+Boqueria!3m4!1s0x12a4a2f7b51e5a01:0x860ac654dc73add5!8m2!3d41.3817399!4d2.1715853",
            "location_reviews_link": "https://www.google.com/maps/place/Mercado+de+La+Boqueria/@41.3817399,2.1715853,13.99z/data=!4m16!1m7!3m6!1s0x12a4a2f7b51e5a01:0x860ac654dc73add5!2sMercado+de+La+Boqueria!8m2!3d41.3817399!4d2.1715853!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a2f7b51e5a01:0x860ac654dc73add5!8m2!3d41.3817399!4d2.1715853!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-y885Q6U0AR8/AAAAAAAAAAI/AAAAAAAAAAA/IywsEQYlkGQ/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1715853,
            "menu_link": null,
            "name": "Mercado de La Boqueria",
            "name_for_emails": "Mercado De La Boqueria",
            "order_links": null,
            "other_hours": null,
            "owner_id": "109661944079716820710",
            "owner_link": "https://www.google.com/maps/contrib/109661944079716820710",
            "owner_title": "Mercado de La Boqueria",
            "phone": "+34 934 13 23 03",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPL5EKnOFujIdjElYmmvC0WZW09TGXOJO-NiXNF=w800-h500-k-no",
            "photos_count": 438850,
            "photos_data": [
              {
                "latitude": 41.38194558555505,
                "longitude": 2.1720292081806165,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPL5EKnOFujIdjElYmmvC0WZW09TGXOJO-NiXNF=w203-h114-k-no",
                "photo_date": "9/18/2021 16:00:00",
                "photo_id": "AF1QipPL5EKnOFujIdjElYmmvC0WZW09TGXOJO-NiXNF",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPL5EKnOFujIdjElYmmvC0WZW09TGXOJO-NiXNF",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPL5EKnOFujIdjElYmmvC0WZW09TGXOJO-NiXNF=w2048-h2048-k-no"
              },
              {
                "latitude": 41.382099000000004,
                "longitude": 2.1723670999999998,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPQtauMD6dIzjWy9NW8-ljnQe0MjH0Plyq2jvht=w203-h134-k-no",
                "photo_date": "1/28/2018 18:00:00",
                "photo_id": "AF1QipPQtauMD6dIzjWy9NW8-ljnQe0MjH0Plyq2jvht",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5,
                  7
                ],
                "photo_tags": [
                  "other",
                  "people"
                ],
                "photo_upload_source": "bizbuilder",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPQtauMD6dIzjWy9NW8-ljnQe0MjH0Plyq2jvht",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPQtauMD6dIzjWy9NW8-ljnQe0MjH0Plyq2jvht=w2048-h2048-k-no"
              },
              {
                "latitude": 41.382099000000004,
                "longitude": 2.1723670999999998,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipP352X_rvAlywQr12Z_nO446ueCNzEpJhw23UXI=w203-h152-k-no",
                "photo_date": "4/21/2024 4:00:00",
                "photo_id": "AF1QipP352X_rvAlywQr12Z_nO446ueCNzEpJhw23UXI",
                "photo_source_video": null,
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipP352X_rvAlywQr12Z_nO446ueCNzEpJhw23UXI",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipP352X_rvAlywQr12Z_nO446ueCNzEpJhw23UXI=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJAVoetfeipBIR1a1z3FTGCoY",
            "plus_code": "8FH495JC+MJ",
            "popular_times": [
              {
                "day": 7,
                "popular_times": []
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 12,
                    "time": "6a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 9,
                    "percentage": 24,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 39,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 54,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 64,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 67,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 64,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 54,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 43,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 34,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 23,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 12,
                    "time": "6a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 9,
                    "percentage": 23,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 36,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 49,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 58,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 60,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 57,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 53,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 45,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 41,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 34,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 24,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 12,
                    "time": "6a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 9,
                    "percentage": 22,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 35,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 48,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 55,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 57,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 55,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 52,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 48,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 45,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 42,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 25,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 11,
                    "time": "6a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 9,
                    "percentage": 21,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 34,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 46,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 55,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 58,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 55,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 52,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 46,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 39,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 28,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 13,
                    "time": "6a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 9,
                    "percentage": 25,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 41,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 57,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 67,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 68,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 63,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 55,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 46,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 33,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 15,
                    "time": "6a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 9,
                    "percentage": 31,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 10,
                    "percentage": 53,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 75,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 93,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 96,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 89,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 81,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 76,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 69,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 57,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 40,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "08001",
            "posts": null,
            "query": "La Boqueria Market, Barcelona",
            "range": null,
            "rating": 4.5,
            "reservation_links": null,
            "reviews": 174572,
            "reviews_id": "-8787993655081980459",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJAVoetfeipBIR1a1z3FTGCoY&q=La+Boqueria+Market,+Barcelona&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 2299,
              "2": 2974,
              "3": 12536,
              "4": 40071,
              "5": 116692
            },
            "reviews_tags": [
              "tourists",
              "smoothies",
              "jamon",
              "pickpockets",
              "race stage",
              "fruit salads",
              "salumi",
              "fresh juice",
              "grazing",
              "dry-cured ham"
            ],
            "site": "http://www.boqueria.barcelona/",
            "state": "Barcelona",
            "street": "La Rambla, 91",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipPYL6YilCspV6omB2UbaKj0fH4XSRykY4wvkaeB=w1600-h1000-k-no",
            "subtypes": "Market, Fresh food market, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Market",
            "typical_time_spent": "People typically spend 20 min to 1 hr here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "8AM-8:30PM",
              "Monday": "8AM-8:30PM",
              "Saturday": "8AM-8:30PM",
              "Sunday": "Closed",
              "Thursday": "8AM-8:30PM",
              "Tuesday": "8AM-8:30PM",
              "Wednesday": "8AM-8:30PM"
            },
            "working_hours_old_format": "Monday:8AM-8:30PM|Tuesday:8AM-8:30PM|Wednesday:8AM-8:30PM|Thursday:8AM-8:30PM|Friday:8AM-8:30PM|Saturday:8AM-8:30PM|Sunday:Closed"
          }
        ]
      ],
      "Gothic Quarter, Barcelona": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Ciutat Vella",
            "business_status": "CLOSED_PERMANENTLY",
            "category": null,
            "cid": "13251136864134211503",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "The charming Gothic Quarter, or Barri Gòtic, has narrow medieval streets filled with trendy bars, clubs and Catalan restaurants. The Museu d'Història de Barcelona shows remains of the Roman city. Artisans sell leather and jewelry near the Cathedral of Barcelona, while flower stalls and street-food vendors line busy avenue La Rambla. The Plaça del Pi, named after the adjacent Gothic church, hosts a weekend art market.",
            "full_address": "Ciutat Vella, Barcelona, Spain",
            "google_id": "0x12a4a2f8901e0681:0xb7e57ec538cda7af",
            "latitude": 41.3819247,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Gothic+Quarter/@41.3819247,2.1773051999999997,14z/data=!4m8!1m2!2m1!1sGothic+Quarter!3m4!1s0x12a4a2f8901e0681:0xb7e57ec538cda7af!8m2!3d41.3819247!4d2.1773051999999997",
            "location_reviews_link": "https://www.google.com/maps/place/Gothic+Quarter/@41.3819247,2.1773051999999997,13.99z/data=!4m16!1m7!3m6!1s0x12a4a2f8901e0681:0xb7e57ec538cda7af!2sGothic+Quarter!8m2!3d41.3819247!4d2.1773051999999997!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a2f8901e0681:0xb7e57ec538cda7af!8m2!3d41.3819247!4d2.1773051999999997!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": 2.1773051999999997,
            "menu_link": null,
            "name": "Gothic Quarter",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPDCKKl9gVTmFc9OmzJFE1SPTeJHFFDf_q3Rke7=w800-h500-k-no",
            "photos_count": 5031,
            "photos_data": [
              {
                "latitude": 41.381905497942505,
                "longitude": 2.1781849962814253,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPDCKKl9gVTmFc9OmzJFE1SPTeJHFFDf_q3Rke7=w203-h135-k-no",
                "photo_date": "7/28/2021 14:00:00",
                "photo_id": "AF1QipPDCKKl9gVTmFc9OmzJFE1SPTeJHFFDf_q3Rke7",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPDCKKl9gVTmFc9OmzJFE1SPTeJHFFDf_q3Rke7",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPDCKKl9gVTmFc9OmzJFE1SPTeJHFFDf_q3Rke7=w2048-h2048-k-no"
              },
              {
                "latitude": 41.381905497942505,
                "longitude": 2.1781849962814253,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipORPJLBIATwKlV4YXE913aZbGNUU8qaWigEgS0f=w203-h243-k-no",
                "photo_date": "2/16/2021 14:00:00",
                "photo_id": "AF1QipORPJLBIATwKlV4YXE913aZbGNUU8qaWigEgS0f",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_ios_share_extension_media_upload",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipORPJLBIATwKlV4YXE913aZbGNUU8qaWigEgS0f",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipORPJLBIATwKlV4YXE913aZbGNUU8qaWigEgS0f=w2048-h2048-k-no"
              },
              {
                "latitude": 41.381905497942505,
                "longitude": 2.1781849962814253,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOVd9wSpmV1ox6faemII6Sa_vfjfliNdfGw__eW=w203-h152-k-no",
                "photo_date": "11/24/2021 10:00:00",
                "photo_id": "AF1QipOVd9wSpmV1ox6faemII6Sa_vfjfliNdfGw__eW",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOVd9wSpmV1ox6faemII6Sa_vfjfliNdfGw__eW",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOVd9wSpmV1ox6faemII6Sa_vfjfliNdfGw__eW=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJgQYekPiipBIRr6fNOMV-5bc",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Gothic Quarter, Barcelona",
            "range": null,
            "rating": null,
            "reservation_links": null,
            "reviews": null,
            "reviews_id": null,
            "reviews_link": null,
            "reviews_per_score": null,
            "reviews_per_score_1": null,
            "reviews_per_score_2": null,
            "reviews_per_score_3": null,
            "reviews_per_score_4": null,
            "reviews_per_score_5": null,
            "reviews_tags": null,
            "site": null,
            "state": "Barcelona",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipORPJLBIATwKlV4YXE913aZbGNUU8qaWigEgS0f=w1600-h1000-k-no",
            "subtypes": null,
            "time_zone": "Europe/Madrid",
            "type": null,
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Tickets Bar, Barcelona": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true,
                "Wi-Fi": true
              },
              "Crowd": {
                "LGBTQ+ friendly": true,
                "Transgender safespace": true
              },
              "Dining options": {
                "Dessert": true,
                "Dinner": true,
                "Lunch": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Organic dishes": true,
                "Small plates": true,
                "Vegetarian options": true,
                "Wine": true
              },
              "Other": {
                "LGBTQ+ friendly": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true
              },
              "Planning": {
                "Accepts reservations": true,
                "Reservations required": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true,
                "Takeout": false
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Eixample",
            "business_status": "CLOSED_PERMANENTLY",
            "category": "restaurants",
            "cid": "7932944007596180854",
            "city": "Barcelona",
            "country": "Spain",
            "country_code": "ES",
            "description": "Sophisticated takes on classic tapas in a relaxed space, plus an inventive dessert room.",
            "full_address": "Av. del Paral·lel, 164, L'Eixample, 08015 Barcelona, Spain",
            "google_id": "0x12a4a2648c40d3eb:0x6e177a8b5bd58576",
            "latitude": 41.3752608,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Tickets/@41.3752608,2.1568707,14z/data=!4m8!1m2!2m1!1sTickets!3m4!1s0x12a4a2648c40d3eb:0x6e177a8b5bd58576!8m2!3d41.3752608!4d2.1568707",
            "location_reviews_link": "https://www.google.com/maps/place/Tickets/@41.3752608,2.1568707,13.99z/data=!4m16!1m7!3m6!1s0x12a4a2648c40d3eb:0x6e177a8b5bd58576!2sTickets!8m2!3d41.3752608!4d2.1568707!16s%2Fg%2F11ckvg1dmb!3m7!1s0x12a4a2648c40d3eb:0x6e177a8b5bd58576!8m2!3d41.3752608!4d2.1568707!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh4.googleusercontent.com/-v-FnxG-JtxA/AAAAAAAAAAI/AAAAAAAAAAA/MFXnj072Wxo/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": 2.1568707,
            "menu_link": null,
            "name": "Tickets",
            "name_for_emails": "Tickets",
            "order_links": null,
            "other_hours": null,
            "owner_id": "104277152838607418838",
            "owner_link": "https://www.google.com/maps/contrib/104277152838607418838",
            "owner_title": "Tickets",
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPOyYpkiBDwP2sWubfwd01EgWyLH-PC5FrWafVw=w800-h500-k-no",
            "photos_count": 7837,
            "photos_data": [
              {
                "latitude": 41.3750517,
                "longitude": 2.1568529,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPQnodYzRwjEOa48aIwcAkY6t59pH4YmWIDvddK=w203-h135-k-no",
                "photo_date": "8/7/2018 19:00:00",
                "photo_id": "AF1QipPQnodYzRwjEOa48aIwcAkY6t59pH4YmWIDvddK",
                "photo_source_video": null,
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "photo_transfer",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPQnodYzRwjEOa48aIwcAkY6t59pH4YmWIDvddK",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPQnodYzRwjEOa48aIwcAkY6t59pH4YmWIDvddK=w2048-h2048-k-no"
              },
              {
                "latitude": 41.37520429475534,
                "longitude": 2.156853926610314,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNomH8vKC7Gkg9MzK9qbMXemv6U8Cy9o1tw862w=w203-h114-k-no",
                "photo_date": "5/27/2019 0:00:00",
                "photo_id": "AF1QipNomH8vKC7Gkg9MzK9qbMXemv6U8Cy9o1tw862w",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipNomH8vKC7Gkg9MzK9qbMXemv6U8Cy9o1tw862w=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNomH8vKC7Gkg9MzK9qbMXemv6U8Cy9o1tw862w",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNomH8vKC7Gkg9MzK9qbMXemv6U8Cy9o1tw862w=w2048-h2048-k-no"
              },
              {
                "latitude": 41.3750517,
                "longitude": 2.1568529,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNCwb0vBJASm3jMCbVvY91EginhJA1dp-qKvd5E=w203-h135-k-no",
                "photo_date": "3/5/2019 12:00:00",
                "photo_id": "AF1QipNCwb0vBJASm3jMCbVvY91EginhJA1dp-qKvd5E",
                "photo_source_video": null,
                "photo_tag_ids": [
                  8,
                  3
                ],
                "photo_tags": [
                  "drink",
                  "food"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNCwb0vBJASm3jMCbVvY91EginhJA1dp-qKvd5E",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNCwb0vBJASm3jMCbVvY91EginhJA1dp-qKvd5E=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ69NAjGSipBIRdoXVW4t6F24",
            "plus_code": "8FH495G4+4P",
            "popular_times": null,
            "postal_code": "08015",
            "posts": null,
            "query": "Tickets Bar, Barcelona",
            "range": "$$$$",
            "rating": 4.5,
            "reservation_links": null,
            "reviews": 1834,
            "reviews_id": "7932944007596180854",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJ69NAjGSipBIRdoXVW4t6F24&q=*&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 55,
              "2": 48,
              "3": 125,
              "4": 289,
              "5": 1317
            },
            "reviews_tags": [
              "barcelona",
              "ferran adrià",
              "disfrutar",
              "olives",
              "recreation",
              "best restaurant",
              "siblings",
              "innovative",
              "ostrea",
              "circus"
            ],
            "site": null,
            "state": "Barcelona",
            "street": "Av. del Paral·lel, 164",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMAjbzUATnWWUOG8KKUiL6RjvWhJ-ttFLX--rrV=w1600-h1000-k-no",
            "subtypes": "Fine dining restaurant, Bar, Cocktail bar, European restaurant, Spanish restaurant, Tapas bar, Tapas restaurant",
            "time_zone": "Europe/Madrid",
            "type": "Fine dining restaurant",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Casanis, Marbella": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true,
                "Wheelchair accessible seating": true
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true
              },
              "Atmosphere": {
                "Cozy": true,
                "Romantic": true
              },
              "Children": {
                "Kids' menu": true
              },
              "Crowd": {
                "Family-friendly": true,
                "Groups": true,
                "Transgender safespace": true
              },
              "Dining options": {
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Highlights": {
                "Great cocktails": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Organic dishes": true,
                "Salad bar": true,
                "Small plates": true,
                "Vegetarian options": true,
                "Wine": true
              },
              "Parking": {
                "Free parking lot": true,
                "Free street parking": true,
                "Paid parking garage": true,
                "Paid street parking": true,
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": true,
                "Reservations required": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true
              }
            },
            "area_service": false,
            "booking_appointment_link": "https://casanisbistrot.com/book-a-table/",
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "7014639080149687416",
            "city": "Marbella",
            "country": "Spain",
            "country_code": "ES",
            "description": "Creative Mediterranean dishes & a long wine list at a rustic tavern with sidewalk tables.",
            "full_address": "C. Ancha, 8, 29601 Marbella, Málaga, Spain",
            "google_id": "0xd72d807a45bb4bf:0x6159011407e51c78",
            "latitude": 36.5114951,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Casanis+Bistrot+Marbella/@36.5114951,-4.885189899999999,14z/data=!4m8!1m2!2m1!1sCasanis+Bistrot+Marbella!3m4!1s0xd72d807a45bb4bf:0x6159011407e51c78!8m2!3d36.5114951!4d-4.885189899999999",
            "location_reviews_link": "https://www.google.com/maps/place/Casanis+Bistrot+Marbella/@36.5114951,-4.885189899999999,13.99z/data=!4m16!1m7!3m6!1s0xd72d807a45bb4bf:0x6159011407e51c78!2sCasanis+Bistrot+Marbella!8m2!3d36.5114951!4d-4.885189899999999!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72d807a45bb4bf:0x6159011407e51c78!8m2!3d36.5114951!4d-4.885189899999999!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-CSUX0nWz7JI/AAAAAAAAAAI/AAAAAAAAAAA/4PTtHsaL1w4/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.885189899999999,
            "menu_link": "https://casanisbistrot.com/menus",
            "name": "Casanis Bistrot Marbella",
            "name_for_emails": "Casanis Bistrot Marbella",
            "order_links": [
              "https://casanisbistrot.com/book-a-table/"
            ],
            "other_hours": null,
            "owner_id": "116364583892618336604",
            "owner_link": "https://www.google.com/maps/contrib/116364583892618336604",
            "owner_title": "Casanis Bistrot Marbella",
            "phone": "+34 952 90 04 50",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipO27oRqFGX7FMhz0xyp1ntACcVlMp6cQoxgd9A9=w800-h500-k-no",
            "photos_count": 760,
            "photos_data": [
              {
                "latitude": 36.511507099999996,
                "longitude": -4.8852853,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipO27oRqFGX7FMhz0xyp1ntACcVlMp6cQoxgd9A9=w203-h135-k-no",
                "photo_date": "2/12/2018 14:00:00",
                "photo_id": "AF1QipO27oRqFGX7FMhz0xyp1ntACcVlMp6cQoxgd9A9",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipO27oRqFGX7FMhz0xyp1ntACcVlMp6cQoxgd9A9",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipO27oRqFGX7FMhz0xyp1ntACcVlMp6cQoxgd9A9=w2048-h2048-k-no"
              },
              {
                "latitude": 36.511495095952384,
                "longitude": -4.885189877679231,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMdIWfABaNhfVNktILrQFwClWZGIMgaLmfCh1KC=w203-h360-k-no",
                "photo_date": "11/22/2017 13:00:00",
                "photo_id": "AF1QipMdIWfABaNhfVNktILrQFwClWZGIMgaLmfCh1KC",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipMdIWfABaNhfVNktILrQFwClWZGIMgaLmfCh1KC=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMdIWfABaNhfVNktILrQFwClWZGIMgaLmfCh1KC",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMdIWfABaNhfVNktILrQFwClWZGIMgaLmfCh1KC=w2048-h2048-k-no"
              },
              {
                "latitude": 36.511507099999996,
                "longitude": -4.8852853,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNirboEpGcGJVh9S9JC1NwpYVNS1-T9aYaMKpNF=w203-h135-k-no",
                "photo_date": "2/12/2018 14:00:00",
                "photo_id": "AF1QipNirboEpGcGJVh9S9JC1NwpYVNS1-T9aYaMKpNF",
                "photo_source_video": null,
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNirboEpGcGJVh9S9JC1NwpYVNS1-T9aYaMKpNF",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNirboEpGcGJVh9S9JC1NwpYVNS1-T9aYaMKpNF=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJv7RbpAfYcg0ReBzlBxQBWWE",
            "plus_code": "8C8QG467+HW",
            "popular_times": null,
            "postal_code": "29601",
            "posts": null,
            "query": "Casanis, Marbella",
            "range": "$$$",
            "rating": 4.1,
            "reservation_links": [
              "https://casanisbistrot.com/book-a-table/"
            ],
            "reviews": 584,
            "reviews_id": "7014639080149687416",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJv7RbpAfYcg0ReBzlBxQBWWE&q=Casanis,+Marbella&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 58,
              "2": 30,
              "3": 53,
              "4": 115,
              "5": 328
            },
            "reviews_tags": [
              "wine",
              "tip",
              "dinner",
              "butter",
              "letter",
              "beef wellington",
              "entrecôte",
              "tuna",
              "glass",
              "cuisine"
            ],
            "site": "https://casanisbistrot.com/",
            "state": "Málaga",
            "street": "C. Ancha, 8",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipM76qxHc_fetETzTfyc4NywDKou_ErKv3agCPkc=w1600-h1000-k-no",
            "subtypes": "Restaurant, French restaurant, Mediterranean restaurant",
            "time_zone": "Europe/Madrid",
            "type": "Restaurant",
            "typical_time_spent": "People typically spend 45 min to 2.5 hr here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "7-11PM",
              "Monday": "7-11PM",
              "Saturday": "7-11PM",
              "Sunday": "7-11PM",
              "Thursday": "7-11PM",
              "Tuesday": "7-11PM",
              "Wednesday": "7-11PM"
            },
            "working_hours_old_format": "Monday:7-11PM|Tuesday:7-11PM|Wednesday:7-11PM|Thursday:7-11PM|Friday:7-11PM|Saturday:7-11PM|Sunday:7-11PM"
          }
        ]
      ],
      "Marbella": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "CLOSED_PERMANENTLY",
            "category": null,
            "cid": "1039379032039615659",
            "city": "Marbella",
            "country": "Spain",
            "country_code": "ES",
            "description": "Marbella is a city and resort area on southern Spain’s Costa del Sol, part of the Andalusia region. The Sierra Blanca Mountains are the backdrop to 27 km of sandy Mediterranean beaches, villas, hotels, and golf courses. West of Marbella town, the Golden Mile of prestigious nightclubs and coastal estates leads to Puerto Banús marina, filled with luxury yachts, and surrounded by upmarket boutiques and bars.",
            "full_address": "Málaga, Spain",
            "google_id": "0xd72d809904dabdf:0xe6c9db907b5ecab",
            "latitude": 36.510283099999995,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Marbella/@36.510283099999995,-4.8852579,14z/data=!4m8!1m2!2m1!1sMarbella!3m4!1s0xd72d809904dabdf:0xe6c9db907b5ecab!8m2!3d36.510283099999995!4d-4.8852579",
            "location_reviews_link": "https://www.google.com/maps/place/Marbella/@36.510283099999995,-4.8852579,13.99z/data=!4m16!1m7!3m6!1s0xd72d809904dabdf:0xe6c9db907b5ecab!2sMarbella!8m2!3d36.510283099999995!4d-4.8852579!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72d809904dabdf:0xe6c9db907b5ecab!8m2!3d36.510283099999995!4d-4.8852579!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.8852579,
            "menu_link": null,
            "name": "Marbella",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNPtncygXySank8MRHF4EmH579e14ViGzUWhjc3=w800-h500-k-no",
            "photos_count": 9458,
            "photos_data": [
              {
                "latitude": 36.492375232420024,
                "longitude": -4.951609636005314,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNPtncygXySank8MRHF4EmH579e14ViGzUWhjc3=w203-h202-k-no",
                "photo_date": "4/29/2021 11:00:00",
                "photo_id": "AF1QipNPtncygXySank8MRHF4EmH579e14ViGzUWhjc3",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNPtncygXySank8MRHF4EmH579e14ViGzUWhjc3",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNPtncygXySank8MRHF4EmH579e14ViGzUWhjc3=w2048-h2048-k-no"
              },
              {
                "latitude": 36.492375232420024,
                "longitude": -4.951609636005314,
                "original_photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhHSIjhYlfQNpK9xumGP1J-lt1FDZisQJt83kENXnG2ocOFShMJwLrxtUfysRf6mQ1NRoTwJOWxaqkpbQnsArKcCv-wrLOqN0TlcCH2NHncfkHwAX-e-afuUO21-FwmqMe0n1i5_RrCKQh-utNTatTswttyr8pV9WkKmPUhBHUHfP4Z5gC4tZZQy=w203-h152-k-no",
                "photo_date": "1/12/2020 15:00:00",
                "photo_id": "http://t2.gstatic.com/images?q=tbn:ANd9GcT-8mH9wplYQcD-82AnPf22uRaQ3MAM9mLnwHR1A2tfVcMv-zWp",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "TRAVEL_GETTY",
                "photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhHSIjhYlfQNpK9xumGP1J-lt1FDZisQJt83kENXnG2ocOFShMJwLrxtUfysRf6mQ1NRoTwJOWxaqkpbQnsArKcCv-wrLOqN0TlcCH2NHncfkHwAX-e-afuUO21-FwmqMe0n1i5_RrCKQh-utNTatTswttyr8pV9WkKmPUhBHUHfP4Z5gC4tZZQy",
                "photo_url_big": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhHSIjhYlfQNpK9xumGP1J-lt1FDZisQJt83kENXnG2ocOFShMJwLrxtUfysRf6mQ1NRoTwJOWxaqkpbQnsArKcCv-wrLOqN0TlcCH2NHncfkHwAX-e-afuUO21-FwmqMe0n1i5_RrCKQh-utNTatTswttyr8pV9WkKmPUhBHUHfP4Z5gC4tZZQy=w2048-h2048-k-no"
              },
              {
                "latitude": 36.492375232420024,
                "longitude": -4.951609636005314,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNpTJJrcZvShgGWIm3WZQJWArcarF7MC4DCeN-2=w210-h100-k-no",
                "photo_date": "8/29/2021 14:00:00",
                "photo_id": "AF1QipNpTJJrcZvShgGWIm3WZQJWArcarF7MC4DCeN-2",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNpTJJrcZvShgGWIm3WZQJWArcarF7MC4DCeN-2",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNpTJJrcZvShgGWIm3WZQJWArcarF7MC4DCeN-2=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ36tNkAnYcg0Rq-y1B7mdbA4",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Marbella",
            "range": null,
            "rating": null,
            "reservation_links": null,
            "reviews": null,
            "reviews_id": null,
            "reviews_link": null,
            "reviews_per_score": null,
            "reviews_per_score_1": null,
            "reviews_per_score_2": null,
            "reviews_per_score_3": null,
            "reviews_per_score_4": null,
            "reviews_per_score_5": null,
            "reviews_tags": null,
            "site": null,
            "state": "Málaga",
            "street": null,
            "street_view": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhHSIjhYlfQNpK9xumGP1J-lt1FDZisQJt83kENXnG2ocOFShMJwLrxtUfysRf6mQ1NRoTwJOWxaqkpbQnsArKcCv-wrLOqN0TlcCH2NHncfkHwAX-e-afuUO21-FwmqMe0n1i5_RrCKQh-utNTatTswttyr8pV9WkKmPUhBHUHfP4Z5gC4tZZQy=w1600-h1000-k-no",
            "subtypes": null,
            "time_zone": "Europe/Madrid",
            "type": null,
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Old Town, Marbella": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "CLOSED_PERMANENTLY",
            "category": null,
            "cid": "1497637035087930916",
            "city": "Marbella",
            "country": "Spain",
            "country_code": "ES",
            "description": "The quaint Old Town is home to Renaissance-era whitewashed buildings, flower-draped balconies, and narrow, winding streets. Plaza de los Naranjos draws visitors with its orange trees, 15th-century Ermita de Santiago church, and restaurants with terraces, serving traditional dishes like gazpacho and meatballs. Avenida del Mar is a long, narrow plaza dotted with palm trees and bronze sculptures by Salvador Dalí.",
            "full_address": "29601 Marbella, Málaga, Spain",
            "google_id": "0xd72d807f6351871:0x14c8acf2d6e2a624",
            "latitude": 36.5113219,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Old+Town/@36.5113219,-4.8846457,14z/data=!4m8!1m2!2m1!1sOld+Town!3m4!1s0xd72d807f6351871:0x14c8acf2d6e2a624!8m2!3d36.5113219!4d-4.8846457",
            "location_reviews_link": "https://www.google.com/maps/place/Old+Town/@36.5113219,-4.8846457,13.99z/data=!4m16!1m7!3m6!1s0xd72d807f6351871:0x14c8acf2d6e2a624!2sOld+Town!8m2!3d36.5113219!4d-4.8846457!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72d807f6351871:0x14c8acf2d6e2a624!8m2!3d36.5113219!4d-4.8846457!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.8846457,
            "menu_link": null,
            "name": "Old Town",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhF2g1MY7tstB4L1XhVhOQqh8ZeRztqn0WobpAtRgot2E-YDzK38tqsqB6zqpF1AWh107p6yJ6TrcqiiTTcC7hmFBGJNQO2qxrehx_Va4a3W2Dqc03TCoTe9ps_ryIaSpGEoknSAXdNDRorgnEvQhchOZQSpbeSsNqsaLNuFezErJ6us0Leg2Gs8=w800-h500-k-no",
            "photos_count": 497,
            "photos_data": [
              {
                "latitude": 36.51132190036185,
                "longitude": -4.884645738114861,
                "original_photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhF2g1MY7tstB4L1XhVhOQqh8ZeRztqn0WobpAtRgot2E-YDzK38tqsqB6zqpF1AWh107p6yJ6TrcqiiTTcC7hmFBGJNQO2qxrehx_Va4a3W2Dqc03TCoTe9ps_ryIaSpGEoknSAXdNDRorgnEvQhchOZQSpbeSsNqsaLNuFezErJ6us0Leg2Gs8=w203-h152-k-no",
                "photo_date": "1/12/2020 15:00:00",
                "photo_id": "http://t2.gstatic.com/images?q=tbn:ANd9GcT-8mH9wplYQcD-82AnPf22uRaQ3MAM9mLnwHR1A2tfVcMv-zWp",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "TRAVEL_GETTY",
                "photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhF2g1MY7tstB4L1XhVhOQqh8ZeRztqn0WobpAtRgot2E-YDzK38tqsqB6zqpF1AWh107p6yJ6TrcqiiTTcC7hmFBGJNQO2qxrehx_Va4a3W2Dqc03TCoTe9ps_ryIaSpGEoknSAXdNDRorgnEvQhchOZQSpbeSsNqsaLNuFezErJ6us0Leg2Gs8",
                "photo_url_big": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhF2g1MY7tstB4L1XhVhOQqh8ZeRztqn0WobpAtRgot2E-YDzK38tqsqB6zqpF1AWh107p6yJ6TrcqiiTTcC7hmFBGJNQO2qxrehx_Va4a3W2Dqc03TCoTe9ps_ryIaSpGEoknSAXdNDRorgnEvQhchOZQSpbeSsNqsaLNuFezErJ6us0Leg2Gs8=w2048-h2048-k-no"
              },
              {
                "latitude": 36.51132190036185,
                "longitude": -4.884645738114861,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNCSLLhxufQEihzK8ZBPYDDakME0qz7kPYovO7u=w203-h360-k-no",
                "photo_date": "9/13/2021 15:00:00",
                "photo_id": "AF1QipNCSLLhxufQEihzK8ZBPYDDakME0qz7kPYovO7u",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNCSLLhxufQEihzK8ZBPYDDakME0qz7kPYovO7u",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNCSLLhxufQEihzK8ZBPYDDakME0qz7kPYovO7u=w2048-h2048-k-no"
              },
              {
                "latitude": 36.51132190036185,
                "longitude": -4.884645738114861,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPbCJWMdkIO_tzjTBb64jgLFbOyX-Nltpd2lT9K=w203-h360-k-no",
                "photo_date": "1/17/2023 23:00:00",
                "photo_id": "AF1QipPbCJWMdkIO_tzjTBb64jgLFbOyX-Nltpd2lT9K",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPbCJWMdkIO_tzjTBb64jgLFbOyX-Nltpd2lT9K=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPbCJWMdkIO_tzjTBb64jgLFbOyX-Nltpd2lT9K",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPbCJWMdkIO_tzjTBb64jgLFbOyX-Nltpd2lT9K=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJcRg19gfYcg0RJKbi1vKsyBQ",
            "plus_code": null,
            "popular_times": null,
            "postal_code": "29601",
            "posts": null,
            "query": "Old Town Marbella",
            "range": null,
            "rating": null,
            "reservation_links": null,
            "reviews": null,
            "reviews_id": null,
            "reviews_link": null,
            "reviews_per_score": null,
            "reviews_per_score_1": null,
            "reviews_per_score_2": null,
            "reviews_per_score_3": null,
            "reviews_per_score_4": null,
            "reviews_per_score_5": null,
            "reviews_tags": null,
            "site": null,
            "state": "Málaga",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNCSLLhxufQEihzK8ZBPYDDakME0qz7kPYovO7u=w1600-h1000-k-no",
            "subtypes": null,
            "time_zone": "Europe/Madrid",
            "type": null,
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Museo Ralli, Marbella": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible restroom": true
              },
              "Amenities": {
                "Restaurant": false,
                "Restroom": true,
                "Wi-Fi": true
              },
              "Children": {
                "Good for kids": true
              },
              "Crowd": {
                "Family-friendly": true
              },
              "Highlights": {
                "Live performances": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": "museums",
            "cid": "13234506611189239655",
            "city": "Marbella",
            "country": "Spain",
            "country_code": "ES",
            "description": "El Museo Ralli Marbella, inaugurado en el año 2000, alberga una de las colecciones más importantes de arte contemporáneo latinoamericano que hay en Europa, la cual se complementa con la colección permanente de arte europeo.\nFormado por diez salas de exposiciones, en él se muestran las distintas vanguardias que se dieron en ambos continentes, con una especial presencia del surrealismo tanto de Europa como de América Latina.",
            "full_address": "Urb.coral Beach, Rio Verde, N-340, km.176, 29602 Marbella, Málaga, Spain",
            "google_id": "0xd7327f89c7a54ff:0xb7aa69a4b97e4f67",
            "latitude": 36.498081,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Museo+Ralli+Marbella/@36.498081,-4.9409529999999995,14z/data=!4m8!1m2!2m1!1sMuseo+Ralli+Marbella!3m4!1s0xd7327f89c7a54ff:0xb7aa69a4b97e4f67!8m2!3d36.498081!4d-4.9409529999999995",
            "location_reviews_link": "https://www.google.com/maps/place/Museo+Ralli+Marbella/@36.498081,-4.9409529999999995,13.99z/data=!4m16!1m7!3m6!1s0xd7327f89c7a54ff:0xb7aa69a4b97e4f67!2sMuseo+Ralli+Marbella!8m2!3d36.498081!4d-4.9409529999999995!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd7327f89c7a54ff:0xb7aa69a4b97e4f67!8m2!3d36.498081!4d-4.9409529999999995!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-OxrtxBWMGoQ/AAAAAAAAAAI/AAAAAAAAAAA/Z-vrpaBZ2YA/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.9409529999999995,
            "menu_link": null,
            "name": "Museo Ralli Marbella",
            "name_for_emails": "Museo Ralli Marbella",
            "order_links": null,
            "other_hours": null,
            "owner_id": "102476515596245052029",
            "owner_link": "https://www.google.com/maps/contrib/102476515596245052029",
            "owner_title": "Museo Ralli Marbella",
            "phone": "+34 952 85 79 23",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNmZoG3IXLotrsGmA9Dm_hdZMU65cxDSPV9dLrK=w800-h500-k-no",
            "photos_count": 1150,
            "photos_data": [
              {
                "latitude": 36.4981272,
                "longitude": -4.940931,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNmZoG3IXLotrsGmA9Dm_hdZMU65cxDSPV9dLrK=w203-h194-k-no",
                "photo_date": "3/28/2024 11:00:00",
                "photo_id": "AF1QipNmZoG3IXLotrsGmA9Dm_hdZMU65cxDSPV9dLrK",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5,
                  2
                ],
                "photo_tags": [
                  "front",
                  "other"
                ],
                "photo_upload_source": "gmb_on_local_universal_desktop",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNmZoG3IXLotrsGmA9Dm_hdZMU65cxDSPV9dLrK",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNmZoG3IXLotrsGmA9Dm_hdZMU65cxDSPV9dLrK=w2048-h2048-k-no"
              },
              {
                "latitude": 36.4981272,
                "longitude": -4.940931,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN5u8C97_9sGfF07J00FMIaZH86DR3d4bPmrGlA=w203-h114-k-no",
                "photo_date": "3/28/2024 11:00:00",
                "photo_id": "AF1QipN5u8C97_9sGfF07J00FMIaZH86DR3d4bPmrGlA",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipN5u8C97_9sGfF07J00FMIaZH86DR3d4bPmrGlA=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmb_on_local_universal_desktop",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN5u8C97_9sGfF07J00FMIaZH86DR3d4bPmrGlA",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN5u8C97_9sGfF07J00FMIaZH86DR3d4bPmrGlA=w2048-h2048-k-no"
              },
              {
                "latitude": 36.4981272,
                "longitude": -4.940931,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPmojiMvxsB-gnle2uXnloqV8WUMO46TaVyTOYB=w203-h135-k-no",
                "photo_date": "12/20/2016 9:00:00",
                "photo_id": "AF1QipPmojiMvxsB-gnle2uXnloqV8WUMO46TaVyTOYB",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPmojiMvxsB-gnle2uXnloqV8WUMO46TaVyTOYB",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPmojiMvxsB-gnle2uXnloqV8WUMO46TaVyTOYB=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ_1R6nPgncw0RZ09-uaRpqrc",
            "plus_code": "8C8QF3X5+6J",
            "popular_times": [
              {
                "day": 7,
                "popular_times": []
              },
              {
                "day": 1,
                "popular_times": []
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 22,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 32,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 30,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 20,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 22,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 22,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 26,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 30,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 30,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 34,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 34,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 36,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 24,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 46,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 53,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 53,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 48,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 28,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 24,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 28,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 81,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 11,
                    "percentage": 100,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 42,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 22,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 20,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 16,
                    "time": "9a",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 26,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 40,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 69,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "29602",
            "posts": [
              {
                "body": "🔄 Como cada año, el museo cierra sus puertas desde el 22 de diciembre hasta el 22 de enero (ambos incluidos) para ofrecer el cuidado necesario a las obras y salas del museo, así como para realizar las tareas de mantenimiento pertinentes.\n¡Todavía puedes visitarnos! El museo permanecerá abierto en su horario habitual hasta el viernes 21 de diciembre a las 17:00h.\n😉 Nos vamos pero no desaparecemos. Puedes consultar todo el contenido para disfrutar desde casa en museoralli.es y nuestras redes sociales.\n¡Volvemos el 23 de enero con más Museo Ralli!",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipML6W4oErK4ma9J2NNQKTOED4ZH9FwlP_7scr20=h400-no",
                "link": "https://search.google.com/local/posts?q=Museo+Ralli+Marbella&ludocid=13234506611189239655&lpsid=CIHM0ogKEICAgICVm9rXSA&source=sh/x/localposts&lsig=AB86z5VSU6wU1u3qkJ637csDwgP9",
                "timestamp": 1702124381
              }
            ],
            "query": "Museo Ralli, Marbella",
            "range": null,
            "rating": 4.7,
            "reservation_links": null,
            "reviews": 243,
            "reviews_id": "-5212237462520311961",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJ_1R6nPgncw0RZ09-uaRpqrc&q=Museo+Ralli,+Marbella&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 2,
              "2": 5,
              "3": 5,
              "4": 35,
              "5": 196
            },
            "reviews_tags": [
              "salvador dali",
              "sculptures",
              "exhibits",
              "artists",
              "contemporary art",
              "building",
              "joan miró",
              "gem",
              "art collection",
              "chagall"
            ],
            "site": "https://www.museoralli.es/",
            "state": "Málaga",
            "street": "Urb.coral Beach, Rio Verde, N-340, km.176",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMehdwgevQG848XTauNidvVmrjXXHIl_m91yjO4=w1600-h1000-k-no",
            "subtypes": "Art museum, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Art museum",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "10AM-5PM",
              "Monday": "Closed",
              "Saturday": "10AM-4PM",
              "Sunday": "Closed",
              "Thursday": "10AM-5PM",
              "Tuesday": "10AM-5PM",
              "Wednesday": "10AM-5PM"
            },
            "working_hours_old_format": "Monday:Closed|Tuesday:10AM-5PM|Wednesday:10AM-5PM|Thursday:10AM-5PM|Friday:10AM-5PM|Saturday:10AM-4PM|Sunday:Closed"
          }
        ]
      ],
      "Playa de Cabopino, Marbella": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": null,
            "cid": "12017769330425017887",
            "city": "ES",
            "country": "Spain",
            "country_code": "ES",
            "description": "Spacious beach with dunes, nearby restaurants, water sports, swimming & a busy nudist area.",
            "full_address": "Spain",
            "google_id": "0xd7320387444acd7:0xa6c7b1887c91361f",
            "latitude": 36.484562499999996,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Cabopino+Beach/@36.484562499999996,-4.743680599999999,14z/data=!4m8!1m2!2m1!1sCabopino+Beach!3m4!1s0xd7320387444acd7:0xa6c7b1887c91361f!8m2!3d36.484562499999996!4d-4.743680599999999",
            "location_reviews_link": "https://www.google.com/maps/place/Cabopino+Beach/@36.484562499999996,-4.743680599999999,13.99z/data=!4m16!1m7!3m6!1s0xd7320387444acd7:0xa6c7b1887c91361f!2sCabopino+Beach!8m2!3d36.484562499999996!4d-4.743680599999999!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd7320387444acd7:0xa6c7b1887c91361f!8m2!3d36.484562499999996!4d-4.743680599999999!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.743680599999999,
            "menu_link": null,
            "name": "Cabopino Beach",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNOdkaWMsu4AUAHUWZv36BMrDjcvga0iZbSoX6G=w800-h500-k-no",
            "photos_count": 6976,
            "photos_data": [
              {
                "latitude": 36.48456252938888,
                "longitude": -4.743680567718984,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNOdkaWMsu4AUAHUWZv36BMrDjcvga0iZbSoX6G=w203-h135-k-no",
                "photo_date": "9/9/2023 22:00:00",
                "photo_id": "AF1QipNOdkaWMsu4AUAHUWZv36BMrDjcvga0iZbSoX6G",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNOdkaWMsu4AUAHUWZv36BMrDjcvga0iZbSoX6G",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNOdkaWMsu4AUAHUWZv36BMrDjcvga0iZbSoX6G=w2048-h2048-k-no"
              },
              {
                "latitude": 36.48456252938888,
                "longitude": -4.743680567718984,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMBDH0rz_LLWmnozE7CRszqKv-9OkZQRisxSoyU=w203-h114-k-no",
                "photo_date": "6/19/2023 6:00:00",
                "photo_id": "AF1QipMBDH0rz_LLWmnozE7CRszqKv-9OkZQRisxSoyU",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipMBDH0rz_LLWmnozE7CRszqKv-9OkZQRisxSoyU=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android_photo_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMBDH0rz_LLWmnozE7CRszqKv-9OkZQRisxSoyU",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMBDH0rz_LLWmnozE7CRszqKv-9OkZQRisxSoyU=w2048-h2048-k-no"
              },
              {
                "latitude": 36.48456252938888,
                "longitude": -4.743680567718984,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipP54PU9bcb9733LJIEcYsRv6XpM46zm5hJmx6Ga=w203-h152-k-no",
                "photo_date": "5/31/2022 17:00:00",
                "photo_id": "AF1QipP54PU9bcb9733LJIEcYsRv6XpM46zm5hJmx6Ga",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipP54PU9bcb9733LJIEcYsRv6XpM46zm5hJmx6Ga",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipP54PU9bcb9733LJIEcYsRv6XpM46zm5hJmx6Ga=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ16xEdDggcw0RHzaRfIixx6Y",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Playa de Cabopino, Marbella",
            "range": null,
            "rating": 4.4,
            "reservation_links": null,
            "reviews": 4289,
            "reviews_id": "-6428974743284533729",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJ16xEdDggcw0RHzaRfIixx6Y&q=Playa+de+Cabopino,+Marbella&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 100,
              "2": 84,
              "3": 332,
              "4": 1145,
              "5": 2628
            },
            "reviews_tags": [
              "dunes",
              "chiringuito",
              "nudist",
              "ideal",
              "wooden",
              "marina",
              "franelero",
              "natural park",
              "lonja",
              "tip"
            ],
            "site": "http://www.marbella.es/",
            "state": null,
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipP54PU9bcb9733LJIEcYsRv6XpM46zm5hJmx6Ga=w1600-h1000-k-no",
            "subtypes": "Beach",
            "time_zone": "Europe/Madrid",
            "type": "Beach",
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Chiringuito Playa Cabopino, Marbella": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible restroom": true,
                "Wheelchair accessible seating": true
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true
              },
              "Atmosphere": {
                "Casual": true,
                "Cozy": true
              },
              "Children": {
                "Good for kids": true,
                "High chairs": true
              },
              "Crowd": {
                "Family-friendly": true,
                "Groups": true
              },
              "Dining options": {
                "Breakfast": true,
                "Brunch": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Highlights": {
                "Live music": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Happy hour drinks": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Late-night food": true,
                "Salad bar": true,
                "Small plates": true,
                "Wine": true
              },
              "Parking": {
                "Paid parking lot": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true,
                "Takeout": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "8983947057659034198",
            "city": "Marbella",
            "country": "Spain",
            "country_code": "ES",
            "description": null,
            "full_address": "Urbanización Cabopino, 1L, 29604 Marbella, Málaga, Spain",
            "google_id": "0xd73203a415a8723:0x7cad643ffcaeba56",
            "latitude": 36.4847737,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Restaurant+La+Lonja+Beach+Club+Cabopino/@36.4847737,-4.741080999999999,14z/data=!4m8!1m2!2m1!1sRestaurant+La+Lonja+Beach+Club+Cabopino!3m4!1s0xd73203a415a8723:0x7cad643ffcaeba56!8m2!3d36.4847737!4d-4.741080999999999",
            "location_reviews_link": "https://www.google.com/maps/place/Restaurant+La+Lonja+Beach+Club+Cabopino/@36.4847737,-4.741080999999999,13.99z/data=!4m16!1m7!3m6!1s0xd73203a415a8723:0x7cad643ffcaeba56!2sRestaurant+La+Lonja+Beach+Club+Cabopino!8m2!3d36.4847737!4d-4.741080999999999!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd73203a415a8723:0x7cad643ffcaeba56!8m2!3d36.4847737!4d-4.741080999999999!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-HHOf5DwDgpk/AAAAAAAAAAI/AAAAAAAAAAA/3I6-WFkShC4/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.741080999999999,
            "menu_link": null,
            "name": "Restaurant La Lonja Beach Club Cabopino",
            "name_for_emails": "Restaurant La Lonja Beach Club Cabopino",
            "order_links": [
              "https://www.restaurantelalonjadecabopino.com/contacto.html"
            ],
            "other_hours": null,
            "owner_id": "100384827324542872076",
            "owner_link": "https://www.google.com/maps/contrib/100384827324542872076",
            "owner_title": "Restaurant La Lonja Beach Club Cabopino",
            "phone": "+34 952 83 53 53",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipOliN3SblkqliJbDEfDN8VVG7FFDTiikgWEkFBy=w800-h500-k-no",
            "photos_count": 767,
            "photos_data": [
              {
                "latitude": 36.4849495,
                "longitude": -4.7409118999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipM1gLwHIwJdI9N46hhacvyaQORKLG-XSX1f19Vq=w203-h152-k-no",
                "photo_date": "8/22/2022 14:00:00",
                "photo_id": "AF1QipM1gLwHIwJdI9N46hhacvyaQORKLG-XSX1f19Vq",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipM1gLwHIwJdI9N46hhacvyaQORKLG-XSX1f19Vq",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipM1gLwHIwJdI9N46hhacvyaQORKLG-XSX1f19Vq=w2048-h2048-k-no"
              },
              {
                "latitude": 36.48477372854521,
                "longitude": -4.741080989972764,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN8R28iK428q4QeF2KL0TzGLAK_6qHOZNkiSOw_=w203-h114-k-no",
                "photo_date": "6/20/2021 18:00:00",
                "photo_id": "AF1QipN8R28iK428q4QeF2KL0TzGLAK_6qHOZNkiSOw_",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipN8R28iK428q4QeF2KL0TzGLAK_6qHOZNkiSOw_=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN8R28iK428q4QeF2KL0TzGLAK_6qHOZNkiSOw_",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN8R28iK428q4QeF2KL0TzGLAK_6qHOZNkiSOw_=w2048-h2048-k-no"
              },
              {
                "latitude": 36.4849495,
                "longitude": -4.7409118999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMTkuzjN1w46wGgEixh5p2RpgL4Vbi39u5koHyO=w203-h152-k-no",
                "photo_date": "8/22/2022 14:00:00",
                "photo_id": "AF1QipMTkuzjN1w46wGgEixh5p2RpgL4Vbi39u5koHyO",
                "photo_source_video": null,
                "photo_tag_ids": [
                  8,
                  3
                ],
                "photo_tags": [
                  "food",
                  "drink"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMTkuzjN1w46wGgEixh5p2RpgL4Vbi39u5koHyO",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMTkuzjN1w46wGgEixh5p2RpgL4Vbi39u5koHyO=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJI4daQTogcw0RVrqu_D9krXw",
            "plus_code": null,
            "popular_times": null,
            "postal_code": "29604",
            "posts": null,
            "query": "Chiringuito Playa Cabopino, Marbella",
            "range": "$$",
            "rating": 3.7,
            "reservation_links": [
              "https://www.restaurantelalonjadecabopino.com/contacto.html"
            ],
            "reviews": 689,
            "reviews_id": "8983947057659034198",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJI4daQTogcw0RVrqu_D9krXw&q=Chiringuito+Playa+Cabopino,+Marbella&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 101,
              "2": 52,
              "3": 73,
              "4": 163,
              "5": 300
            },
            "reviews_tags": null,
            "site": "https://lalonjabeachclubcabopino.com/",
            "state": "Málaga",
            "street": "Urbanización Cabopino, 1L",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipOliN3SblkqliJbDEfDN8VVG7FFDTiikgWEkFBy=w1600-h1000-k-no",
            "subtypes": "Mediterranean restaurant",
            "time_zone": "Europe/Madrid",
            "type": "Mediterranean restaurant",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "10AM-8PM",
              "Monday": "10AM-8PM",
              "Saturday": "10AM-8PM",
              "Sunday": "10AM-8PM",
              "Thursday": "10AM-8PM",
              "Tuesday": "10AM-8PM",
              "Wednesday": "10AM-8PM"
            },
            "working_hours_old_format": "Monday:10AM-8PM|Tuesday:10AM-8PM|Wednesday:10AM-8PM|Thursday:10AM-8PM|Friday:10AM-8PM|Saturday:10AM-8PM|Sunday:10AM-8PM"
          }
        ]
      ],
      "Playa de Guadalmina, Marbella": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": null,
            "cid": "5192590127843892903",
            "city": "ES",
            "country": "Spain",
            "country_code": "ES",
            "description": "Family-friendly urban beach featuring ancient Roman ruins, parking & accessible areas.",
            "full_address": "Spain",
            "google_id": "0xd732a4b24092255:0x480fc935b255dea7",
            "latitude": 36.4646245,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Playa+Guadalmina/@36.4646245,-4.9992333,14z/data=!4m8!1m2!2m1!1sPlaya+Guadalmina!3m4!1s0xd732a4b24092255:0x480fc935b255dea7!8m2!3d36.4646245!4d-4.9992333",
            "location_reviews_link": "https://www.google.com/maps/place/Playa+Guadalmina/@36.4646245,-4.9992333,13.99z/data=!4m16!1m7!3m6!1s0xd732a4b24092255:0x480fc935b255dea7!2sPlaya+Guadalmina!8m2!3d36.4646245!4d-4.9992333!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd732a4b24092255:0x480fc935b255dea7!8m2!3d36.4646245!4d-4.9992333!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.9992333,
            "menu_link": null,
            "name": "Playa Guadalmina",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNMA-9TGEf87lwGnjb9h9pAys8m-OsPkjXa8_Cx=w800-h500-k-no",
            "photos_count": 86,
            "photos_data": [
              {
                "latitude": 36.46453630068177,
                "longitude": -4.999112215021152,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNMA-9TGEf87lwGnjb9h9pAys8m-OsPkjXa8_Cx=w203-h114-k-no",
                "photo_date": "9/28/2018 17:00:00",
                "photo_id": "AF1QipNMA-9TGEf87lwGnjb9h9pAys8m-OsPkjXa8_Cx",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNMA-9TGEf87lwGnjb9h9pAys8m-OsPkjXa8_Cx",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNMA-9TGEf87lwGnjb9h9pAys8m-OsPkjXa8_Cx=w2048-h2048-k-no"
              },
              {
                "latitude": 36.46462451892697,
                "longitude": -4.999233277041733,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPHUUUy3GhqjTtxq2iYehUJKqAN47YC-CkASYDI=w203-h114-k-no",
                "photo_date": "3/29/2022 21:00:00",
                "photo_id": "AF1QipPHUUUy3GhqjTtxq2iYehUJKqAN47YC-CkASYDI",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPHUUUy3GhqjTtxq2iYehUJKqAN47YC-CkASYDI=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPHUUUy3GhqjTtxq2iYehUJKqAN47YC-CkASYDI",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPHUUUy3GhqjTtxq2iYehUJKqAN47YC-CkASYDI=w2048-h2048-k-no"
              },
              {
                "latitude": 36.46462451892697,
                "longitude": -4.999233277041733,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipP1SGqopQNCkd7n-hbhx7T-SavXqdkdKZwsApcn=w203-h114-k-no",
                "photo_date": "3/23/2020 23:00:00",
                "photo_id": "AF1QipP1SGqopQNCkd7n-hbhx7T-SavXqdkdKZwsApcn",
                "photo_source_video": null,
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipP1SGqopQNCkd7n-hbhx7T-SavXqdkdKZwsApcn",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipP1SGqopQNCkd7n-hbhx7T-SavXqdkdKZwsApcn=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJVSIJJEsqcw0Rp95VsjXJD0g",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Playa de Guadalmina, Marbella",
            "range": null,
            "rating": 3.8,
            "reservation_links": null,
            "reviews": 67,
            "reviews_id": "5192590127843892903",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJVSIJJEsqcw0Rp95VsjXJD0g&q=Playa+de+Guadalmina,+Marbella&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 3,
              "2": 3,
              "3": 23,
              "4": 15,
              "5": 23
            },
            "reviews_tags": [
              "stone"
            ],
            "site": null,
            "state": null,
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipP1SGqopQNCkd7n-hbhx7T-SavXqdkdKZwsApcn=w1600-h1000-k-no",
            "subtypes": "Beach",
            "time_zone": "Europe/Madrid",
            "type": "Beach",
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Ronda, Spain": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "CLOSED_PERMANENTLY",
            "category": null,
            "cid": "10679582971483806626",
            "city": "Ronda",
            "country": "Spain",
            "country_code": "ES",
            "description": "Ronda is a mountaintop city in Spain’s Malaga province that’s set dramatically above a deep gorge. This gorge (El Tajo) separates the city’s circa-15th-century new town from its old town, dating to Moorish rule. Puente Nuevo, a stone bridge spanning the gorge, has a lookout offering views. New town’s Plaza de Toros, a legendary 18th-century bullring, is one of the city’s most recognizable landmarks.",
            "full_address": "29400, Málaga, Spain",
            "google_id": "0xd0d3143e258a279:0x94358021349a5ba2",
            "latitude": 36.746209,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Ronda/@36.746209,-5.161225099999999,14z/data=!4m8!1m2!2m1!1sRonda!3m4!1s0xd0d3143e258a279:0x94358021349a5ba2!8m2!3d36.746209!4d-5.161225099999999",
            "location_reviews_link": "https://www.google.com/maps/place/Ronda/@36.746209,-5.161225099999999,13.99z/data=!4m16!1m7!3m6!1s0xd0d3143e258a279:0x94358021349a5ba2!2sRonda!8m2!3d36.746209!4d-5.161225099999999!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd0d3143e258a279:0x94358021349a5ba2!8m2!3d36.746209!4d-5.161225099999999!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -5.161225099999999,
            "menu_link": null,
            "name": "Ronda",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPKgtYuffV3ixyY3oWyFjW6rCA7IM1fd-EFZ2tR=w800-h500-k-no",
            "photos_count": 23024,
            "photos_data": [
              {
                "latitude": 36.74620900202574,
                "longitude": -5.161225106944311,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPKgtYuffV3ixyY3oWyFjW6rCA7IM1fd-EFZ2tR=w203-h360-k-no",
                "photo_date": "8/5/2022 19:00:00",
                "photo_id": "AF1QipPKgtYuffV3ixyY3oWyFjW6rCA7IM1fd-EFZ2tR",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPKgtYuffV3ixyY3oWyFjW6rCA7IM1fd-EFZ2tR",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPKgtYuffV3ixyY3oWyFjW6rCA7IM1fd-EFZ2tR=w2048-h2048-k-no"
              },
              {
                "latitude": 36.74620900202574,
                "longitude": -5.161225106944311,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNDbtjILo6qk732ufsmff9_MtlhHZ4ZD5ND-DeR=w203-h451-k-no",
                "photo_date": "4/24/2022 7:00:00",
                "photo_id": "AF1QipNDbtjILo6qk732ufsmff9_MtlhHZ4ZD5ND-DeR",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNDbtjILo6qk732ufsmff9_MtlhHZ4ZD5ND-DeR",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNDbtjILo6qk732ufsmff9_MtlhHZ4ZD5ND-DeR=w2048-h2048-k-no"
              },
              {
                "latitude": 36.74620900202574,
                "longitude": -5.161225106944311,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOGEmwK-JK-Ee6aXLYZ837zzx2vxdLs2YFtlEB1=w203-h270-k-no",
                "photo_date": "8/17/2022 17:00:00",
                "photo_id": "AF1QipOGEmwK-JK-Ee6aXLYZ837zzx2vxdLs2YFtlEB1",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOGEmwK-JK-Ee6aXLYZ837zzx2vxdLs2YFtlEB1",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOGEmwK-JK-Ee6aXLYZ837zzx2vxdLs2YFtlEB1=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJeaJY4kMxDQ0RoluaNCGANZQ",
            "plus_code": null,
            "popular_times": null,
            "postal_code": "29400",
            "posts": null,
            "query": "Ronda, Spain",
            "range": null,
            "rating": null,
            "reservation_links": null,
            "reviews": null,
            "reviews_id": null,
            "reviews_link": null,
            "reviews_per_score": null,
            "reviews_per_score_1": null,
            "reviews_per_score_2": null,
            "reviews_per_score_3": null,
            "reviews_per_score_4": null,
            "reviews_per_score_5": null,
            "reviews_tags": null,
            "site": null,
            "state": "Málaga",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNDbtjILo6qk732ufsmff9_MtlhHZ4ZD5ND-DeR=w1600-h1000-k-no",
            "subtypes": null,
            "time_zone": "Europe/Madrid",
            "type": null,
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Tragatapas, Ronda": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true,
                "Wheelchair accessible seating": true
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true,
                "Wi-Fi": true
              },
              "Atmosphere": {
                "Casual": true,
                "Cozy": true
              },
              "Children": {
                "High chairs": true
              },
              "Crowd": {
                "Groups": true
              },
              "Dining options": {
                "Counter service": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Salad bar": true,
                "Small plates": true,
                "Wine": true
              },
              "Parking": {
                "Paid parking lot": true,
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true
              }
            },
            "area_service": false,
            "booking_appointment_link": "https://www.covermanager.com/reserve/module_restaurant/restaurante-tragata/spanish",
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "5385149502692112951",
            "city": "Ronda",
            "country": "Spain",
            "country_code": "ES",
            "description": "Creative takes on tapas, from squid sandwiches to pork ears, in a sleek, industrial-chic space.",
            "full_address": "C. Nueva, 4, 29400 Ronda, Málaga, Spain",
            "google_id": "0xd0d3138dfe09061:0x4abbe4f0e255ba37",
            "latitude": 36.7415034,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda/@36.7415034,-5.1659301,14z/data=!4m8!1m2!2m1!1sTragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda!3m4!1s0xd0d3138dfe09061:0x4abbe4f0e255ba37!8m2!3d36.7415034!4d-5.1659301",
            "location_reviews_link": "https://www.google.com/maps/place/Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda/@36.7415034,-5.1659301,13.99z/data=!4m16!1m7!3m6!1s0xd0d3138dfe09061:0x4abbe4f0e255ba37!2sTragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda!8m2!3d36.7415034!4d-5.1659301!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd0d3138dfe09061:0x4abbe4f0e255ba37!8m2!3d36.7415034!4d-5.1659301!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-RDz2ilY9GjI/AAAAAAAAAAI/AAAAAAAAAAA/SJ8GiOqLe1k/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -5.1659301,
            "menu_link": "https://drive.google.com/file/u/1/d/1I259bI69tMv5pSqDcyrQZ4KNYI-PhrXB/view",
            "name": "Tragatá | Benito Gómez | Ronda",
            "name_for_emails": "Tragatá | Benito Gómez | Ronda",
            "order_links": [
              "https://www.covermanager.com/reserve/module_restaurant/restaurante-tragata/spanish"
            ],
            "other_hours": null,
            "owner_id": "116876289242801988704",
            "owner_link": "https://www.google.com/maps/contrib/116876289242801988704",
            "owner_title": "Tragatá | Benito Gómez | Ronda",
            "phone": "+34 952 87 72 09",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipMI2G_9lUODBXckhF2hl3AwPq82s59aUnwyLJM5=w800-h500-k-no",
            "photos_count": 3760,
            "photos_data": [
              {
                "latitude": 36.7414663,
                "longitude": -5.1661649999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMI2G_9lUODBXckhF2hl3AwPq82s59aUnwyLJM5=w203-h133-k-no",
                "photo_date": "4/24/2024 11:00:00",
                "photo_id": "AF1QipMI2G_9lUODBXckhF2hl3AwPq82s59aUnwyLJM5",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1,
                  9
                ],
                "photo_tags": [
                  "vibe",
                  "all"
                ],
                "photo_upload_source": "gmb_on_local_universal_desktop",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMI2G_9lUODBXckhF2hl3AwPq82s59aUnwyLJM5",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMI2G_9lUODBXckhF2hl3AwPq82s59aUnwyLJM5=w2048-h2048-k-no"
              },
              {
                "latitude": 36.74150338873793,
                "longitude": -5.165930071017314,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPo03BVZYCOvkR-4W-rUy0SYDMs-1Y2ZaQN3k7d=w203-h360-k-no",
                "photo_date": "10/27/2022 14:00:00",
                "photo_id": "AF1QipPo03BVZYCOvkR-4W-rUy0SYDMs-1Y2ZaQN3k7d",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPo03BVZYCOvkR-4W-rUy0SYDMs-1Y2ZaQN3k7d=m18",
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPo03BVZYCOvkR-4W-rUy0SYDMs-1Y2ZaQN3k7d",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPo03BVZYCOvkR-4W-rUy0SYDMs-1Y2ZaQN3k7d=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7414663,
                "longitude": -5.1661649999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipO1SYEsmQlgP-92pL_k2mUKWQdOWXRFLDeTmNni=w203-h134-k-no",
                "photo_date": "4/24/2024 11:00:00",
                "photo_id": "AF1QipO1SYEsmQlgP-92pL_k2mUKWQdOWXRFLDeTmNni",
                "photo_source_video": null,
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmb_on_local_universal_desktop",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipO1SYEsmQlgP-92pL_k2mUKWQdOWXRFLDeTmNni",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipO1SYEsmQlgP-92pL_k2mUKWQdOWXRFLDeTmNni=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJYZDg3zgxDQ0RN7pV4vDku0o",
            "plus_code": "8C8PPRRM+JJ",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 98,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 79,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 45,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 47,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 28,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": []
              },
              {
                "day": 2,
                "popular_times": []
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 48,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 63,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 50,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 58,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 47,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 50,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 62,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 55,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 50,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 59,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 47,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 39,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 50,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 51,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 60,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 74,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 75,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 58,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 81,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 77,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 81,
                    "time": "6p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 21,
                    "percentage": 100,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 22,
                    "percentage": 87,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": "live",
                "percentage": 25,
                "time": 20,
                "title": "Not too busy"
              }
            ],
            "postal_code": "29400",
            "posts": [
              {
                "body": "Bienvenido a casa.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipPUcy5P452zTdc6ha54Gds2Gn9w5T5Xgl5_TL5c=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgICjgfKVgAE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1713781494
              },
              {
                "body": "Siempre pendiente al detalle. Nuestra @cristinatellezr 🤍\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipPQqbgHeYmRgky7FXfPddfaUk-f0cBRKTNBOLtB=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgICj1Pew2QE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1713423007
              },
              {
                "body": "Los mejores del mundo.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipOxayRso6Htk_rskXwNefTD-K_699xfsWe-DJ_u=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgIDDiY7vRw&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1712818618
              },
              {
                "body": "Dúo perfecto: nuestra ensaladilla rusa y vino de la tierra.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipMiaNNY8jDJ4a7DLQ321WduDroiKDG8HAl-7yJC=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgIDDmtuziQE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1712560578
              },
              {
                "body": "Nada que una copa de vino no pueda arreglar.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipPgLwHCEq_ZZ1cGKDOZPZtO8ZGQ-YLqpuuwPofZ=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgIDDwNnTswE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1712214111
              },
              {
                "body": "Tartar de atún picante, el compañero ideal para nuestra jugosa tortilla de patatas.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipM0ZPltEv53EjFSY6gMEzsGDy3Z2pAnc3tEAKvW=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgICD89PJYA&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1711977442
              },
              {
                "body": "¡A sus puestos! Todo preparado para volver a casa.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipOkxQPyq4Io1QG5dS3f_FgN0q_I0l88yvkupxud=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgID964jT7wE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1711006919
              },
              {
                "body": "Toca descansar. Nos vemos el 20 de Marzo donde siempre, en @tragata_ronda.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipPDzQpiZrt7Y0MoX6AhYkyfk8S1ZHAPlGPfir7S=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgIC9j4np4AE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1710145690
              },
              {
                "body": "Perseverancia.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipPa63qXwuQvLNST3z_oTB0SM3NbhVyk1H_Nwasy=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgIC9hY79_QE&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1709813264
              },
              {
                "body": "Lo de siempre, donde siempre. Gracias Ronda, gracias equipo, gracias @guiarepsol.\n\n",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipOgsNSI8hQ4sbJjiJY7OSEh9jT9y69SVX8NP75H=h400-no",
                "link": "https://search.google.com/local/posts?q=Tragat%C3%A1+%7C+Benito+G%C3%B3mez+%7C+Ronda&ludocid=5385149502692112951&lpsid=CIHM0ogKEICAgIC9gYOWAg&source=sh/x/localposts&lsig=AB86z5X6Sg0VZLeyc60AEg64Qanb",
                "timestamp": 1709712903
              }
            ],
            "query": "Tragatapas, Ronda",
            "range": "$$",
            "rating": 4.5,
            "reservation_links": [
              "https://www.covermanager.com/reserve/module_restaurant/restaurante-tragata/spanish"
            ],
            "reviews": 2184,
            "reviews_id": "5385149502692112951",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJYZDg3zgxDQ0RN7pV4vDku0o&q=Tragatapas,+Ronda&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 80,
              "2": 46,
              "3": 134,
              "4": 401,
              "5": 1523
            },
            "reviews_tags": [
              "salmorejo",
              "flan",
              "bone marrow",
              "cannelloni",
              "squid sandwich",
              "bun",
              "sea bass",
              "michelin",
              "pig ears",
              "pastrami"
            ],
            "site": "http://tragata.com/",
            "state": "Málaga",
            "street": "C. Nueva, 4",
            "street_view": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=SUYBgMYITnlAv_2ORnOpmA&cb_client=search.gws-prod.gps&w=1600&h=1000&yaw=82.02142&pitch=0&thumbfov=100",
            "subtypes": "Tapas restaurant, Spanish restaurant, Tapas bar",
            "time_zone": "Europe/Madrid",
            "type": "Tapas restaurant",
            "typical_time_spent": "People typically spend 1.5-2.5 hours here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "1-3:45PM,8-10:45PM",
              "Monday": "Closed",
              "Saturday": "1-3:45PM,8-10:45PM",
              "Sunday": "1-3:45PM,8-10:45PM",
              "Thursday": "1-3:45PM,8-10:45PM",
              "Tuesday": "Closed",
              "Wednesday": "1-3:45PM,8-10:45PM"
            },
            "working_hours_old_format": "Monday:Closed|Tuesday:Closed|Wednesday:1-3:45PM,8-10:45PM|Thursday:1-3:45PM,8-10:45PM|Friday:1-3:45PM,8-10:45PM|Saturday:1-3:45PM,8-10:45PM|Sunday:1-3:45PM,8-10:45PM"
          }
        ]
      ],
      "Malaga City Center": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "CLOSED_PERMANENTLY",
            "category": null,
            "cid": "9604195620883884642",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": null,
            "full_address": "Málaga, Spain",
            "google_id": "0xd72f7959d2b253d:0x8548f50830256a62",
            "latitude": 36.7184187,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Centro/@36.7184187,-4.4197803,14z/data=!4m8!1m2!2m1!1sCentro!3m4!1s0xd72f7959d2b253d:0x8548f50830256a62!8m2!3d36.7184187!4d-4.4197803",
            "location_reviews_link": "https://www.google.com/maps/place/Centro/@36.7184187,-4.4197803,13.99z/data=!4m16!1m7!3m6!1s0xd72f7959d2b253d:0x8548f50830256a62!2sCentro!8m2!3d36.7184187!4d-4.4197803!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7959d2b253d:0x8548f50830256a62!8m2!3d36.7184187!4d-4.4197803!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.4197803,
            "menu_link": null,
            "name": "Centro",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNHFIxNmXwexB465QKBnsCBZiWSgNba9djjtMYx=w800-h500-k-no",
            "photos_count": 1073,
            "photos_data": [
              {
                "latitude": 36.71841873951493,
                "longitude": -4.419780280278284,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNHFIxNmXwexB465QKBnsCBZiWSgNba9djjtMYx=w203-h360-k-no",
                "photo_date": "3/29/2021 9:00:00",
                "photo_id": "AF1QipNHFIxNmXwexB465QKBnsCBZiWSgNba9djjtMYx",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNHFIxNmXwexB465QKBnsCBZiWSgNba9djjtMYx",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNHFIxNmXwexB465QKBnsCBZiWSgNba9djjtMYx=w2048-h2048-k-no"
              },
              {
                "latitude": 36.71841873951493,
                "longitude": -4.419780280278284,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOwGxOgfoZ-o-5vC287Q9UpTYKzwArkCU_VKGRR=w203-h270-k-no",
                "photo_date": "7/18/2022 16:00:00",
                "photo_id": "AF1QipOwGxOgfoZ-o-5vC287Q9UpTYKzwArkCU_VKGRR",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOwGxOgfoZ-o-5vC287Q9UpTYKzwArkCU_VKGRR",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOwGxOgfoZ-o-5vC287Q9UpTYKzwArkCU_VKGRR=w2048-h2048-k-no"
              },
              {
                "latitude": 36.71841873951493,
                "longitude": -4.419780280278284,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNnKhyd-QRp3l0gde_xhL4x8D-SEWtTN4J0NKiy=w203-h270-k-no",
                "photo_date": "3/12/2022 17:00:00",
                "photo_id": "AF1QipNnKhyd-QRp3l0gde_xhL4x8D-SEWtTN4J0NKiy",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_ios_share_extension_media_upload",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNnKhyd-QRp3l0gde_xhL4x8D-SEWtTN4J0NKiy",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNnKhyd-QRp3l0gde_xhL4x8D-SEWtTN4J0NKiy=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJPSUrnZX3cg0RYmolMAj1SIU",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Malaga City Center",
            "range": null,
            "rating": null,
            "reservation_links": null,
            "reviews": null,
            "reviews_id": null,
            "reviews_link": null,
            "reviews_per_score": null,
            "reviews_per_score_1": null,
            "reviews_per_score_2": null,
            "reviews_per_score_3": null,
            "reviews_per_score_4": null,
            "reviews_per_score_5": null,
            "reviews_tags": null,
            "site": null,
            "state": "Málaga",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNHFIxNmXwexB465QKBnsCBZiWSgNba9djjtMYx=w1600-h1000-k-no",
            "subtypes": null,
            "time_zone": "Europe/Madrid",
            "type": null,
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "El Pimpi, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Assistive hearing loop": false,
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true,
                "Wheelchair accessible seating": true
              },
              "Atmosphere": {
                "Casual": true,
                "Cozy": true,
                "Romantic": true
              },
              "Children": {
                "Good for kids": true,
                "High chairs": true,
                "Kids' menu": true
              },
              "Crowd": {
                "Family-friendly": true,
                "Groups": true,
                "LGBTQ+ friendly": true,
                "Transgender safespace": true
              },
              "Dining options": {
                "Brunch": true,
                "Counter service": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Highlights": {
                "Fast service": true,
                "Great cocktails": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Late-night food": true,
                "Organic dishes": true,
                "Salad bar": true,
                "Small plates": true,
                "Wine": true
              },
              "Other": {
                "Check-out time": "12:00 PM",
                "LGBTQ+ friendly": true,
                "Star rating": null
              },
              "Parking": {
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": true
              },
              "Service options": {
                "Dine-in": true,
                "Outdoor seating": true,
                "Takeout": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "10001583176465433103",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "El Pimpi bombea Sabor a Málaga a los cuatro puntos cardinales del planeta. Vinos de la tierra y exquisita gastronomía en pleno Centro Histórico.",
            "full_address": "C. Granada, 62, Distrito Centro, 29015 Málaga, Spain",
            "google_id": "0xd72f7c00093aded:0x8accc2e888bec20f",
            "latitude": 36.7219304,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/El+Pimpi/@36.7219304,-4.4181336,14z/data=!4m8!1m2!2m1!1sEl+Pimpi!3m4!1s0xd72f7c00093aded:0x8accc2e888bec20f!8m2!3d36.7219304!4d-4.4181336",
            "location_reviews_link": "https://www.google.com/maps/place/El+Pimpi/@36.7219304,-4.4181336,13.99z/data=!4m16!1m7!3m6!1s0xd72f7c00093aded:0x8accc2e888bec20f!2sEl+Pimpi!8m2!3d36.7219304!4d-4.4181336!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7c00093aded:0x8accc2e888bec20f!8m2!3d36.7219304!4d-4.4181336!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-AzZAUNhKez8/AAAAAAAAAAI/AAAAAAAAAAA/RTWPatBiyoU/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.4181336,
            "menu_link": "https://www.laappdelosrestaurantes.com/wal/B107/wa2/#/",
            "name": "El Pimpi",
            "order_links": null,
            "other_hours": null,
            "owner_id": "100038237028435026391",
            "owner_link": "https://www.google.com/maps/contrib/100038237028435026391",
            "owner_title": "El Pimpi",
            "phone": "+34 952 22 54 03",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPJd_fFUYUTRLppQQ87qykcRt2Km28xMFJoyasj=w800-h500-k-no",
            "photos_count": 20757,
            "photos_data": [
              {
                "latitude": 36.7228486,
                "longitude": -4.4174592,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPJd_fFUYUTRLppQQ87qykcRt2Km28xMFJoyasj=w203-h135-k-no",
                "photo_date": "6/28/2022 6:00:00",
                "photo_id": "AF1QipPJd_fFUYUTRLppQQ87qykcRt2Km28xMFJoyasj",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5
                ],
                "photo_tags": [
                  "other"
                ],
                "photo_upload_source": "merchant_panel_action_bar",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPJd_fFUYUTRLppQQ87qykcRt2Km28xMFJoyasj",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPJd_fFUYUTRLppQQ87qykcRt2Km28xMFJoyasj=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7228486,
                "longitude": -4.4174592,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN4gfDpgkyCthEHARtESN8Ry2xVZKDLkbiwiIIG=w203-h114-k-no",
                "photo_date": "9/9/2020 22:00:00",
                "photo_id": "AF1QipN4gfDpgkyCthEHARtESN8Ry2xVZKDLkbiwiIIG",
                "photo_source_video": "https://lh3.googleusercontent.com/gwCNZfWZ6N-wG88oCSgZsG6c9d8fXmoldyuBZN0ccJp8cdpkptDCM3AC898LIWDe7Bx2K4yVtg=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_api",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN4gfDpgkyCthEHARtESN8Ry2xVZKDLkbiwiIIG",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN4gfDpgkyCthEHARtESN8Ry2xVZKDLkbiwiIIG=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7228486,
                "longitude": -4.4174592,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOZB6LLq_22YzPrW3VxSHkbXJaOuJeR-mqjCjgv=w203-h114-k-no",
                "photo_date": "4/12/2024 18:00:00",
                "photo_id": "AF1QipOZB6LLq_22YzPrW3VxSHkbXJaOuJeR-mqjCjgv",
                "photo_source_video": null,
                "photo_tag_ids": [
                  9,
                  1
                ],
                "photo_tags": [
                  "all",
                  "vibe"
                ],
                "photo_upload_source": "gmm_android_photo_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOZB6LLq_22YzPrW3VxSHkbXJaOuJeR-mqjCjgv",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOZB6LLq_22YzPrW3VxSHkbXJaOuJeR-mqjCjgv=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ7a2TAMD3cg0RD8K-iOjCzIo",
            "plus_code": "8C8QPHCJ+QP",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 33,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 47,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 52,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 50,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 45,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 44,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 48,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 54,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 56,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 53,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 40,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 25,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 12,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 20,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 29,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 33,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 31,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 27,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 27,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 33,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 44,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 52,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 49,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 39,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 24,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 12,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 19,
                    "time": "12p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 28,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 31,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 29,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 26,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 27,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 47,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 56,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 55,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 45,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 29,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 13,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 20,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 29,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 32,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 30,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 28,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 30,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 38,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 51,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 59,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 57,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 45,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 28,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 13,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 22,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 31,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 35,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 32,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 28,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 30,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 39,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 53,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 64,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 63,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 51,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 34,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 17,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 28,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 39,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 42,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 40,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 40,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 61,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 78,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 88,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 90,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 78,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 57,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 30,
                    "time": "12a",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 36,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 54,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 60,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 57,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 54,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 56,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 68,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 85,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 100,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 98,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 80,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 55,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 29,
                    "time": "12a",
                    "title": "Usually busy"
                  }
                ]
              },
              {
                "day": "live",
                "percentage": 27,
                "time": 22,
                "title": "Not too busy"
              }
            ],
            "postal_code": "29015",
            "posts": null,
            "query": "El Pimpi, Malaga",
            "range": "$$",
            "rating": 4.2,
            "reservation_links": null,
            "reviews": 15682,
            "reviews_id": "-8445160897244118513",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJ7a2TAMD3cg0RD8K-iOjCzIo&q=El+Pimpi,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 1267,
              "2": 686,
              "3": 1269,
              "4": 3475,
              "5": 8985
            },
            "reviews_tags": [
              "alcazaba",
              "pringá",
              "vin doux naturel",
              "antonio banderas",
              "malaga salad",
              "transparent goby",
              "muscatel",
              "barrel",
              "andalusian patio",
              "roman amphitheatre"
            ],
            "site": "http://www.elpimpi.com/",
            "state": "Málaga",
            "street": "C. Granada, 62",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNyYfhsJ0zBYBhV6Qz-6h7rbwUwtg7_xmlPtUSc=w1600-h1000-k-no",
            "subtypes": "Restaurant, Andalusian restaurant, Cocktail bar, Spanish restaurant, Tourist attraction, Winery",
            "time_zone": "Europe/Madrid",
            "type": "Restaurant",
            "typical_time_spent": "People typically spend 1-2 hours here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "12PM-1AM",
              "Monday": "12PM-1AM",
              "Saturday": "12PM-1AM",
              "Sunday": "12PM-1AM",
              "Thursday": "12PM-1AM",
              "Tuesday": "12PM-1AM",
              "Wednesday": "12PM-1AM"
            },
            "working_hours_old_format": "Monday:12PM-1AM|Tuesday:12PM-1AM|Wednesday:12PM-1AM|Thursday:12PM-1AM|Friday:12PM-1AM|Saturday:12PM-1AM|Sunday:12PM-1AM"
          }
        ]
      ],
      "Malagueta Beach, Malaga": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": null,
            "business_status": "OPERATIONAL",
            "category": null,
            "cid": "14108151940372366247",
            "city": "ES",
            "country": "Spain",
            "country_code": "ES",
            "description": "Long, sandy city beach backed with seafood shacks, with a playground & rental loungers/umbrellas.",
            "full_address": "Málaga, Spain",
            "google_id": "0xd72f7eef56a4e4d:0xc3ca39710b2203a7",
            "latitude": 36.7192893,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Playa+de+la+Malagueta+%28M%C3%A1laga%29/@36.7192893,-4.4024567999999995,14z/data=!4m8!1m2!2m1!1sPlaya+de+la+Malagueta+%28M%C3%A1laga%29!3m4!1s0xd72f7eef56a4e4d:0xc3ca39710b2203a7!8m2!3d36.7192893!4d-4.4024567999999995",
            "location_reviews_link": "https://www.google.com/maps/place/Playa+de+la+Malagueta+%28M%C3%A1laga%29/@36.7192893,-4.4024567999999995,13.99z/data=!4m16!1m7!3m6!1s0xd72f7eef56a4e4d:0xc3ca39710b2203a7!2sPlaya+de+la+Malagueta+%28M%C3%A1laga%29!8m2!3d36.7192893!4d-4.4024567999999995!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7eef56a4e4d:0xc3ca39710b2203a7!8m2!3d36.7192893!4d-4.4024567999999995!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.4024567999999995,
            "menu_link": null,
            "name": "Playa de la Malagueta (Málaga)",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipOg8LIoViWMsfZD8SS6m-NQVRrj2XHMdvBVhBA=w800-h500-k-no",
            "photos_count": 4180,
            "photos_data": [
              {
                "latitude": 36.71928931173094,
                "longitude": -4.402456765764394,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOg8LIoViWMsfZD8SS6m-NQVRrj2XHMdvBVhBA=w203-h152-k-no",
                "photo_date": "12/30/2022 12:00:00",
                "photo_id": "AF1QipOg8LIoViWMsfZD8SS6m-NQVRrj2XHMdvBVhBA",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOg8LIoViWMsfZD8SS6m-NQVRrj2XHMdvBVhBA",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOg8LIoViWMsfZD8SS6m-NQVRrj2XHMdvBVhBA=w2048-h2048-k-no"
              },
              {
                "latitude": 36.71928931173094,
                "longitude": -4.402456765764394,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPJY2cITD93lGuT1_4ULL5MVX7jVHvS7lTZE_5m=w203-h114-k-no",
                "photo_date": "1/12/2022 21:00:00",
                "photo_id": "AF1QipPJY2cITD93lGuT1_4ULL5MVX7jVHvS7lTZE_5m",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPJY2cITD93lGuT1_4ULL5MVX7jVHvS7lTZE_5m=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPJY2cITD93lGuT1_4ULL5MVX7jVHvS7lTZE_5m",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPJY2cITD93lGuT1_4ULL5MVX7jVHvS7lTZE_5m=w2048-h2048-k-no"
              },
              {
                "latitude": 36.71928931173094,
                "longitude": -4.402456765764394,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOdtgvTk0oR8QM857zc2xgZbaYMK4y468L2_iEO=w203-h152-k-no",
                "photo_date": "4/11/2022 11:00:00",
                "photo_id": "AF1QipOdtgvTk0oR8QM857zc2xgZbaYMK4y468L2_iEO",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOdtgvTk0oR8QM857zc2xgZbaYMK4y468L2_iEO",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOdtgvTk0oR8QM857zc2xgZbaYMK4y468L2_iEO=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJTU5q9e73cg0RpwMiC3E5ysM",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Malagueta Beach, Malaga",
            "range": null,
            "rating": 4.3,
            "reservation_links": null,
            "reviews": 2534,
            "reviews_id": "-4338592133337185369",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJTU5q9e73cg0RpwMiC3E5ysM&q=Malagueta+Beach,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 89,
              "2": 91,
              "3": 259,
              "4": 638,
              "5": 1457
            },
            "reviews_tags": [
              "chiringuito",
              "palm trees",
              "espeto",
              "port",
              "cigarette butt",
              "shadow",
              "hammock",
              "city centre",
              "massage",
              "mojito"
            ],
            "site": null,
            "state": "Málaga",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipOdtgvTk0oR8QM857zc2xgZbaYMK4y468L2_iEO=w1600-h1000-k-no",
            "subtypes": "Beach",
            "time_zone": "Europe/Madrid",
            "type": "Beach",
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "El Cortijo de Pepe, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": false
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true
              },
              "Atmosphere": {
                "Casual": true,
                "Cozy": true
              },
              "Children": {
                "Good for kids": true,
                "High chairs": true
              },
              "Crowd": {
                "Family-friendly": true,
                "Groups": true
              },
              "Dining options": {
                "Brunch": true,
                "Counter service": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Highlights": {
                "Fireplace": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Coffee": true,
                "Food": true,
                "Food at bar": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Late-night food": true,
                "Salad bar": true,
                "Small plates": true,
                "Wine": true
              },
              "Parking": {
                "Paid parking lot": true,
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Pets": {
                "Dogs allowed": true
              },
              "Planning": {
                "Accepts reservations": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true,
                "Takeout": true
              }
            },
            "area_service": false,
            "booking_appointment_link": "https://cortijodepepe.es/#reservation",
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "15677605917748694148",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Nuestro local ofrece al público su peculiar distribución en dos niveles.\n\n- En la parte baja se encuentra la barra, de aspecto clásico es una fotografía viva del estilo de los bares andaluces de los setenta, hecha de madera y decorada con azulejos. Como atractivo particular la brasa, a la vista del cliente desde la barra, decorada con utensilios antiguos.\n\n- Al fondo está la “barra chica”, un trozo de mostrador más bajo con sillas bajas para el tapeo más reposado.\n\n- En la parte alta, subiendo las escaleras del fondo, está la zona de mesas dispuestas a lo largo de una balconada que domina la barra.\n\n- Y el salón interior, un espacio acogedor basado en un cortijo andaluz, con arcos de ladrillo, que van de pared a pared, antigua",
            "full_address": "Pl. de la Merced, 2, Distrito Centro, 29012 Málaga, Spain",
            "google_id": "0xd72f7c00b755e55:0xd9920b84c7f90c84",
            "latitude": 36.7228914,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Cortijo+de+Pepe/@36.7228914,-4.4178068,14z/data=!4m8!1m2!2m1!1sCortijo+de+Pepe!3m4!1s0xd72f7c00b755e55:0xd9920b84c7f90c84!8m2!3d36.7228914!4d-4.4178068",
            "location_reviews_link": "https://www.google.com/maps/place/Cortijo+de+Pepe/@36.7228914,-4.4178068,13.99z/data=!4m16!1m7!3m6!1s0xd72f7c00b755e55:0xd9920b84c7f90c84!2sCortijo+de+Pepe!8m2!3d36.7228914!4d-4.4178068!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7c00b755e55:0xd9920b84c7f90c84!8m2!3d36.7228914!4d-4.4178068!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh3.googleusercontent.com/-W7fwNS9dNVA/AAAAAAAAAAI/AAAAAAAAAAA/SIO_41IhZ6A/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.4178068,
            "menu_link": "https://cortijodepepe.es/menu/",
            "name": "Cortijo de Pepe",
            "name_for_emails": "Cortijo De Pepe",
            "order_links": [
              "https://cortijodepepe.es/#reservation"
            ],
            "other_hours": null,
            "owner_id": "116302905590405872391",
            "owner_link": "https://www.google.com/maps/contrib/116302905590405872391",
            "owner_title": "Cortijo de Pepe",
            "phone": "+34 952 22 40 71",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipOA01LSq49Sqmmt0Sp26DoMxgeVO9NQt5H4JHsq=w800-h500-k-no",
            "photos_count": 3886,
            "photos_data": [
              {
                "latitude": 36.72296740000001,
                "longitude": -4.4177608,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOA01LSq49Sqmmt0Sp26DoMxgeVO9NQt5H4JHsq=w203-h146-k-no",
                "photo_date": "9/6/2020 0:00:00",
                "photo_id": "AF1QipOA01LSq49Sqmmt0Sp26DoMxgeVO9NQt5H4JHsq",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmb_api",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOA01LSq49Sqmmt0Sp26DoMxgeVO9NQt5H4JHsq",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOA01LSq49Sqmmt0Sp26DoMxgeVO9NQt5H4JHsq=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72296740000001,
                "longitude": -4.4177608,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOKlSXdebQUfrKkO7ncX-XXs8G_ikLeD7XDjj6I=w203-h152-k-no",
                "photo_date": "4/19/2024 7:00:00",
                "photo_id": "AF1QipOKlSXdebQUfrKkO7ncX-XXs8G_ikLeD7XDjj6I",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1,
                  9
                ],
                "photo_tags": [
                  "vibe",
                  "all"
                ],
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOKlSXdebQUfrKkO7ncX-XXs8G_ikLeD7XDjj6I",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOKlSXdebQUfrKkO7ncX-XXs8G_ikLeD7XDjj6I=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72296740000001,
                "longitude": -4.4177608,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMKoo_qTjQaIhZT04j1qi3tKJ2yzocLQgKJr-xl=w203-h113-k-no",
                "photo_date": "9/9/2021 16:00:00",
                "photo_id": "AF1QipMKoo_qTjQaIhZT04j1qi3tKJ2yzocLQgKJr-xl",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5
                ],
                "photo_tags": [
                  "other"
                ],
                "photo_upload_source": "gmb_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMKoo_qTjQaIhZT04j1qi3tKJ2yzocLQgKJr-xl",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMKoo_qTjQaIhZT04j1qi3tKJ2yzocLQgKJr-xl=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJVV51C8D3cg0RhAz5x4QLktk",
            "plus_code": "8C8QPHFJ+5V",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 56,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 69,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 72,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 79,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 70,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 57,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 39,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 24,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 32,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 43,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 43,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 37,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 33,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 36,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 45,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 55,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 59,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 52,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 35,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 28,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 36,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 37,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 35,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 36,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 46,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 59,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 70,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 71,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 59,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 37,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 31,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 40,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 42,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 37,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 35,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 40,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 50,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 61,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 61,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 51,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 33,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 30,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 39,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 39,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 34,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 31,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 34,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 42,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 54,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 62,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 59,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 43,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 33,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 44,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 63,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 81,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 95,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 100,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 89,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 66,
                    "time": "9p",
                    "title": "Usually busy"
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 49,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 66,
                    "time": "12p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 69,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 61,
                    "time": "3p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 52,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 54,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 67,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 85,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 94,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 84,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 60,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              }
            ],
            "postal_code": "29012",
            "posts": null,
            "query": "El Cortijo de Pepe, Malaga",
            "range": "$",
            "rating": 4.3,
            "reservation_links": [
              "https://cortijodepepe.es/#reservation"
            ],
            "reviews": 5014,
            "reviews_id": "-2769138155960857468",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJVV51C8D3cg0RhAz5x4QLktk&q=El+Cortijo+de+Pepe,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 185,
              "2": 143,
              "3": 419,
              "4": 1311,
              "5": 2956
            },
            "reviews_tags": [
              "coals",
              "pulpit",
              "gambas pil pil",
              "habas con jamón",
              "grilled octopus",
              "giant pacific octopus",
              "Russian salad",
              "porra antequerana",
              "kofta",
              "avocado salad"
            ],
            "site": "http://www.cortijodepepe.es/",
            "state": "Málaga",
            "street": "Pl. de la Merced, 2",
            "street_view": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=LFPpgBNmo-SNvK7thkzL5A&cb_client=search.gws-prod.gps&w=1600&h=1000&yaw=209.67038&pitch=0&thumbfov=100",
            "subtypes": "Tapas bar, Grill, Bar, Barbecue restaurant, Restaurant, Traditional restaurant",
            "time_zone": "Europe/Madrid",
            "type": "Tapas bar",
            "typical_time_spent": "People typically spend 45 min to 1.5 hr here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "1PM-12AM",
              "Monday": "1PM-12AM",
              "Saturday": "1PM-12AM",
              "Sunday": "1PM-12AM",
              "Thursday": "1PM-12AM",
              "Tuesday": "1PM-12AM",
              "Wednesday": "1PM-12AM"
            },
            "working_hours_old_format": "Tuesday:1PM-12AM|Wednesday:1PM-12AM|Thursday:1PM-12AM|Friday:1PM-12AM|Saturday:1PM-12AM|Sunday:1PM-12AM|Monday:1PM-12AM"
          }
        ]
      ],
      "Alcazaba and Roman Theatre, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": false
              },
              "Children": {
                "Good for kids": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "9648473637463672617",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Contemporary museum at an ancient Roman theater with artifacts & educational information.",
            "full_address": "C/ Alcazabilla, s/n, Distrito Centro, 29015 Málaga, Spain",
            "google_id": "0xd72f7eaa049c333:0x85e643a749be4729",
            "latitude": 36.7211729,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Teatro+Romano+de+M%C3%A1laga/@36.7211729,-4.416823,14z/data=!4m8!1m2!2m1!1sTeatro+Romano+de+M%C3%A1laga!3m4!1s0xd72f7eaa049c333:0x85e643a749be4729!8m2!3d36.7211729!4d-4.416823",
            "location_reviews_link": "https://www.google.com/maps/place/Teatro+Romano+de+M%C3%A1laga/@36.7211729,-4.416823,13.99z/data=!4m16!1m7!3m6!1s0xd72f7eaa049c333:0x85e643a749be4729!2sTeatro+Romano+de+M%C3%A1laga!8m2!3d36.7211729!4d-4.416823!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7eaa049c333:0x85e643a749be4729!8m2!3d36.7211729!4d-4.416823!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.416823,
            "menu_link": null,
            "name": "Teatro Romano de Málaga",
            "name_for_emails": "Teatro Romano De Málaga",
            "order_links": null,
            "other_hours": null,
            "owner_id": null,
            "owner_link": null,
            "owner_title": "Teatro Romano de Málaga",
            "phone": null,
            "photo": "https://lh5.googleusercontent.com/p/AF1QipP8AC_l42_JjAAsaUb-23kR4w6PVQXPJWuh6cl2=w800-h500-k-no",
            "photos_count": 18006,
            "photos_data": [
              {
                "latitude": 36.72133123083905,
                "longitude": -4.417033704909271,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipP8AC_l42_JjAAsaUb-23kR4w6PVQXPJWuh6cl2=w203-h134-k-no",
                "photo_date": "11/26/2021 16:00:00",
                "photo_id": "AF1QipP8AC_l42_JjAAsaUb-23kR4w6PVQXPJWuh6cl2",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipP8AC_l42_JjAAsaUb-23kR4w6PVQXPJWuh6cl2",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipP8AC_l42_JjAAsaUb-23kR4w6PVQXPJWuh6cl2=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7221739,
                "longitude": -4.4163790999999994,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipM9iLXJs6WUrqd83ck7XqJKR4y3ogPHBYhDc7SD=w203-h114-k-no",
                "photo_date": "4/4/2022 7:00:00",
                "photo_id": "AF1QipM9iLXJs6WUrqd83ck7XqJKR4y3ogPHBYhDc7SD",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipM9iLXJs6WUrqd83ck7XqJKR4y3ogPHBYhDc7SD=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipM9iLXJs6WUrqd83ck7XqJKR4y3ogPHBYhDc7SD",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipM9iLXJs6WUrqd83ck7XqJKR4y3ogPHBYhDc7SD=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72127306726383,
                "longitude": -4.416795319176204,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPs8daNk6gRRi4JfmOEpl2ADydC6r6LbQoOwIZ2=w203-h135-k-no",
                "photo_date": "6/25/2022 18:00:00",
                "photo_id": "AF1QipPs8daNk6gRRi4JfmOEpl2ADydC6r6LbQoOwIZ2",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPs8daNk6gRRi4JfmOEpl2ADydC6r6LbQoOwIZ2",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPs8daNk6gRRi4JfmOEpl2ADydC6r6LbQoOwIZ2=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJM8NJoOr3cg0RKUe-SadD5oU",
            "plus_code": "8C8QPHCM+F7",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 39,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 70,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 96,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 82,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": []
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 33,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 53,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 64,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 64,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 54,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 48,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 45,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 40,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 60,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 67,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 58,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 56,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 56,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 59,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 60,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 68,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 59,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 55,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 51,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 44,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 35,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 54,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 67,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 65,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 60,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 39,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 61,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 79,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 76,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 71,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 68,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 58,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "29015",
            "posts": null,
            "query": "Alcazaba and Roman Theatre, Malaga",
            "range": null,
            "rating": 4.6,
            "reservation_links": null,
            "reviews": 6685,
            "reviews_id": "-8798270436245878999",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJM8NJoOr3cg0RKUe-SadD5oU&q=Alcazaba+and+Roman+Theatre,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 41,
              "2": 55,
              "3": 442,
              "4": 1640,
              "5": 4507
            },
            "reviews_tags": [
              "amphitheatre",
              "bleacher",
              "1951",
              "1st century",
              "augustus",
              "interpretive center",
              "house of culture",
              "cavea",
              "garum",
              "orchestra"
            ],
            "site": "https://www.juntadeandalucia.es/cultura/enclaves/enclave-arqueologico-teatro-romano-de-malaga",
            "state": "Málaga",
            "street": "C/ Alcazabilla, s/n",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipO4ZI5V4Ci2AMrwgrHV7MAbbozIvLZYsPHYKby6=w1600-h1000-k-no",
            "subtypes": "Historical landmark, Monument",
            "time_zone": "Europe/Madrid",
            "type": "Historical landmark",
            "typical_time_spent": "People typically spend up to 1 hour here",
            "us_state": null,
            "verified": false,
            "working_hours": {
              "Friday": "10AM-6PM",
              "Monday": "Closed",
              "Saturday": "10AM-6PM",
              "Sunday": "10AM-4PM",
              "Thursday": "10AM-6PM",
              "Tuesday": "10AM-6PM",
              "Wednesday": "10AM-6PM"
            },
            "working_hours_old_format": "Tuesday:10AM-6PM|Wednesday:10AM-6PM|Thursday:10AM-6PM|Friday:10AM-6PM|Saturday:10AM-6PM|Sunday:10AM-4PM|Monday:Closed"
          }
        ]
      ],
      "Picasso Museum, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true,
                "Wheelchair rental": true
              },
              "Amenities": {
                "Restaurant": true,
                "Restroom": true,
                "Wi-Fi": true
              },
              "Children": {
                "Good for kids": true
              },
              "Crowd": {
                "Family-friendly": true
              },
              "Highlights": {
                "Live performances": true
              },
              "Planning": {
                "Getting tickets in advance recommended": true
              }
            },
            "area_service": false,
            "booking_appointment_link": "https://tickets.museopicassomalaga.org/muslinkIII/venda/index.jsp?nom_cache=MPM&property=MPM&grupActiv=1&lang=1",
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "museums",
            "cid": "2054424185812036537",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Restored 16th-century palace with a collection of more than 200 works by artist Pablo Picasso.",
            "full_address": "Palacio de Buenavista, C. San Agustín, 8, Distrito Centro, 29015 Málaga, Spain",
            "google_id": "0xd72f795542a70b7:0x1c82c7e9854c2bb9",
            "latitude": 36.721736299999996,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Picasso+Museum+M%C3%A1laga/@36.721736299999996,-4.4184291,14z/data=!4m8!1m2!2m1!1sPicasso+Museum+M%C3%A1laga!3m4!1s0xd72f795542a70b7:0x1c82c7e9854c2bb9!8m2!3d36.721736299999996!4d-4.4184291",
            "location_reviews_link": "https://www.google.com/maps/place/Picasso+Museum+M%C3%A1laga/@36.721736299999996,-4.4184291,13.99z/data=!4m16!1m7!3m6!1s0xd72f795542a70b7:0x1c82c7e9854c2bb9!2sPicasso+Museum+M%C3%A1laga!8m2!3d36.721736299999996!4d-4.4184291!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f795542a70b7:0x1c82c7e9854c2bb9!8m2!3d36.721736299999996!4d-4.4184291!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh4.googleusercontent.com/-gmqQqiLYx0s/AAAAAAAAAAI/AAAAAAAAAAA/Xw__tpSMjyU/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.4184291,
            "menu_link": null,
            "name": "Picasso Museum Málaga",
            "order_links": [
              "https://tickets.museopicassomalaga.org/muslinkIII/venda/index.jsp?nom_cache=MPM&property=MPM&grupActiv=1&lang=1"
            ],
            "other_hours": null,
            "owner_id": "112218578716319022663",
            "owner_link": "https://www.google.com/maps/contrib/112218578716319022663",
            "owner_title": "Picasso Museum Málaga",
            "phone": "+34 952 12 76 00",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipMEuJaX5ZYiIjHaZG1SnNfe0XLxXVNqy248JfTw=w800-h500-k-no",
            "photos_count": 54216,
            "photos_data": [
              {
                "latitude": 36.721744799999996,
                "longitude": -4.4184852999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMEuJaX5ZYiIjHaZG1SnNfe0XLxXVNqy248JfTw=w203-h152-k-no",
                "photo_date": "11/29/2021 23:00:00",
                "photo_id": "AF1QipMEuJaX5ZYiIjHaZG1SnNfe0XLxXVNqy248JfTw",
                "photo_source_video": null,
                "photo_tag_ids": [
                  7
                ],
                "photo_tags": [
                  "people"
                ],
                "photo_upload_source": "gmm_ios",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMEuJaX5ZYiIjHaZG1SnNfe0XLxXVNqy248JfTw",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMEuJaX5ZYiIjHaZG1SnNfe0XLxXVNqy248JfTw=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7217363318396,
                "longitude": -4.418429110855722,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipObaO7sJNMyMf0sWqbDZGH5eZYo3-GSezOPblj1=w203-h114-k-no",
                "photo_date": "3/8/2021 16:00:00",
                "photo_id": "AF1QipObaO7sJNMyMf0sWqbDZGH5eZYo3-GSezOPblj1",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipObaO7sJNMyMf0sWqbDZGH5eZYo3-GSezOPblj1=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipObaO7sJNMyMf0sWqbDZGH5eZYo3-GSezOPblj1",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipObaO7sJNMyMf0sWqbDZGH5eZYo3-GSezOPblj1=w2048-h2048-k-no"
              },
              {
                "latitude": 36.721744799999996,
                "longitude": -4.4184852999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOpLwlizKJuLPYIUIYczj5wLnnBcrwvuczsRwhT=w203-h135-k-no",
                "photo_date": "10/25/2021 10:00:00",
                "photo_id": "AF1QipOpLwlizKJuLPYIUIYczj5wLnnBcrwvuczsRwhT",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOpLwlizKJuLPYIUIYczj5wLnnBcrwvuczsRwhT",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOpLwlizKJuLPYIUIYczj5wLnnBcrwvuczsRwhT=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJt3AqVJX3cg0RuStMhenHghw",
            "plus_code": null,
            "popular_times": null,
            "postal_code": "29015",
            "posts": null,
            "query": "Picasso Museum, Malaga",
            "range": null,
            "rating": 4.3,
            "reservation_links": [
              "https://tickets.museopicassomalaga.org/muslinkIII/venda/index.jsp?nom_cache=MPM&property=MPM&grupActiv=1&lang=1"
            ],
            "reviews": 26308,
            "reviews_id": "2054424185812036537",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJt3AqVJX3cg0RuStMhenHghw&q=Picasso+Museum,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 729,
              "2": 766,
              "3": 2726,
              "4": 7271,
              "5": 14816
            },
            "reviews_tags": null,
            "site": "https://www.museopicassomalaga.org/",
            "state": "Málaga",
            "street": "Palacio de Buenavista, C. San Agustín, 8",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMEuJaX5ZYiIjHaZG1SnNfe0XLxXVNqy248JfTw=w1600-h1000-k-no",
            "subtypes": "Museum, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Museum",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "10AM-7PM",
              "Monday": "10AM-7PM",
              "Saturday": "10AM-7PM",
              "Sunday": "10AM-7PM",
              "Thursday": "10AM-7PM",
              "Tuesday": "10AM-7PM",
              "Wednesday": "10AM-7PM"
            },
            "working_hours_old_format": "Monday:10AM-7PM|Tuesday:10AM-7PM|Wednesday:10AM-7PM|Thursday:10AM-7PM|Friday:10AM-7PM|Saturday:10AM-7PM|Sunday:10AM-7PM"
          }
        ]
      ],
      "Gibralfaro Hill and Castillo de Gibralfaro, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true
              },
              "Children": {
                "Good for kids": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "14138212742589260177",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Ruins of a 1300s Moorish castle & fortress atop Mount Gibralfaro with walkable walls & scenic views.",
            "full_address": "Cam. Gibralfaro, 11, Distrito Centro, 29016 Málaga, Spain",
            "google_id": "0xd72f7c2d298c359:0xc4350594f8ef2191",
            "latitude": 36.7235379,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Castillo+de+Gibralfaro/@36.7235379,-4.410563,14z/data=!4m8!1m2!2m1!1sCastillo+de+Gibralfaro!3m4!1s0xd72f7c2d298c359:0xc4350594f8ef2191!8m2!3d36.7235379!4d-4.410563",
            "location_reviews_link": "https://www.google.com/maps/place/Castillo+de+Gibralfaro/@36.7235379,-4.410563,13.99z/data=!4m16!1m7!3m6!1s0xd72f7c2d298c359:0xc4350594f8ef2191!2sCastillo+de+Gibralfaro!8m2!3d36.7235379!4d-4.410563!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7c2d298c359:0xc4350594f8ef2191!8m2!3d36.7235379!4d-4.410563!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh4.googleusercontent.com/-9aCzF8gADfk/AAAAAAAAAAI/AAAAAAAAAAA/cYBT9CduFUE/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.410563,
            "menu_link": null,
            "name": "Castillo de Gibralfaro",
            "name_for_emails": "Castillo De Gibralfaro",
            "order_links": null,
            "other_hours": null,
            "owner_id": "105490111468891117554",
            "owner_link": "https://www.google.com/maps/contrib/105490111468891117554",
            "owner_title": "Castillo de Gibralfaro",
            "phone": "+34 952 22 72 30",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPbTpdXahSwIDv37WtlnGuESeqyPFXrwiS_0-xs=w800-h500-k-no",
            "photos_count": 52206,
            "photos_data": [
              {
                "latitude": 36.723638699999995,
                "longitude": -4.4098673999999995,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipO3twZWRmVdUA12tZlxkVLSq3iT94p8xMaeoMYS=w203-h152-k-no",
                "photo_date": "6/30/2020 10:00:00",
                "photo_id": "AF1QipO3twZWRmVdUA12tZlxkVLSq3iT94p8xMaeoMYS",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2,
                  5
                ],
                "photo_tags": [
                  "other",
                  "front"
                ],
                "photo_upload_source": "gmb_web",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipO3twZWRmVdUA12tZlxkVLSq3iT94p8xMaeoMYS",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipO3twZWRmVdUA12tZlxkVLSq3iT94p8xMaeoMYS=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7235354332028,
                "longitude": -4.410558185137293,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipM9_lvTCQlqP7hWgz36IHECYmnhGjrBXhl7Uhuu=w203-h108-k-no",
                "photo_date": "12/29/2020 18:00:00",
                "photo_id": "AF1QipM9_lvTCQlqP7hWgz36IHECYmnhGjrBXhl7Uhuu",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipM9_lvTCQlqP7hWgz36IHECYmnhGjrBXhl7Uhuu",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipM9_lvTCQlqP7hWgz36IHECYmnhGjrBXhl7Uhuu=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72353582830156,
                "longitude": -4.410556379993911,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPbTpdXahSwIDv37WtlnGuESeqyPFXrwiS_0-xs=w203-h152-k-no",
                "photo_date": "6/23/2020 2:00:00",
                "photo_id": "AF1QipPbTpdXahSwIDv37WtlnGuESeqyPFXrwiS_0-xs",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPbTpdXahSwIDv37WtlnGuESeqyPFXrwiS_0-xs",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPbTpdXahSwIDv37WtlnGuESeqyPFXrwiS_0-xs=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJWcOY0sL3cg0RkSHv-JQFNcQ",
            "plus_code": null,
            "popular_times": null,
            "postal_code": "29016",
            "posts": null,
            "query": "Gibralfaro Hill and Castillo de Gibralfaro, Malaga",
            "range": null,
            "rating": 4.5,
            "reservation_links": null,
            "reviews": 14342,
            "reviews_id": "-4308531331120291439",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJWcOY0sL3cg0RkSHv-JQFNcQ&q=Gibralfaro+Hill+and+Castillo+de+Gibralfaro,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 212,
              "2": 226,
              "3": 1087,
              "4": 3825,
              "5": 8992
            },
            "reviews_tags": null,
            "site": "http://alcazabaygibralfaro.malaga.eu/es/",
            "state": "Málaga",
            "street": "Cam. Gibralfaro, 11",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipPbTpdXahSwIDv37WtlnGuESeqyPFXrwiS_0-xs=w1600-h1000-k-no",
            "subtypes": "Castle, Historical landmark, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Castle",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "9AM-8PM",
              "Monday": "9AM-8PM",
              "Saturday": "9AM-8PM",
              "Sunday": "9AM-8PM",
              "Thursday": "9AM-8PM",
              "Tuesday": "9AM-8PM",
              "Wednesday": "9AM-8PM"
            },
            "working_hours_old_format": "Tuesday:9AM-8PM|Wednesday:9AM-8PM|Thursday:9AM-8PM|Friday:9AM-8PM|Saturday:9AM-8PM|Sunday:9AM-8PM|Monday:9AM-8PM"
          }
        ]
      ],
      "El Tapeo de Cervantes, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": false,
                "Wheelchair accessible seating": false
              },
              "Amenities": {
                "Bar onsite": true,
                "Restroom": true,
                "Wi-Fi": true
              },
              "Atmosphere": {
                "Casual": true,
                "Cozy": true
              },
              "Children": {
                "High chairs": true
              },
              "Dining options": {
                "Brunch": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Late-night food": true,
                "Salad bar": true,
                "Small plates": true,
                "Wine": true
              },
              "Parking": {
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true
              },
              "Planning": {
                "Accepts reservations": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true
              }
            },
            "area_service": false,
            "booking_appointment_link": "https://book.octotable.com/otb/form/index.xhtml?pubkey=38005bec9ed04b7ca51d23fb670520c3&property=882769",
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "13004205626119107113",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Traditional setting for upscale, modern seafood tapas & classic, meat-focused Spanish mains.",
            "full_address": "C. Cárcer, 8, Distrito Centro, 29012 Málaga, Spain",
            "google_id": "0xd72f7bf98de372b:0xb478381e22f73229",
            "latitude": 36.7239612,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/El+Tapeo+de+Cervantes/@36.7239612,-4.4193114,14z/data=!4m8!1m2!2m1!1sEl+Tapeo+de+Cervantes!3m4!1s0xd72f7bf98de372b:0xb478381e22f73229!8m2!3d36.7239612!4d-4.4193114",
            "location_reviews_link": "https://www.google.com/maps/place/El+Tapeo+de+Cervantes/@36.7239612,-4.4193114,13.99z/data=!4m16!1m7!3m6!1s0xd72f7bf98de372b:0xb478381e22f73229!2sEl+Tapeo+de+Cervantes!8m2!3d36.7239612!4d-4.4193114!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f7bf98de372b:0xb478381e22f73229!8m2!3d36.7239612!4d-4.4193114!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-e43dFVuIgY8/AAAAAAAAAAI/AAAAAAAAAAA/vS8EjOlrZVU/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -4.4193114,
            "menu_link": "https://eltapeodecervantes.com/",
            "name": "El Tapeo de Cervantes",
            "order_links": [
              "https://book.octotable.com/otb/form/index.xhtml?pubkey=38005bec9ed04b7ca51d23fb670520c3&property=882769"
            ],
            "other_hours": null,
            "owner_id": "116756451945408366817",
            "owner_link": "https://www.google.com/maps/contrib/116756451945408366817",
            "owner_title": "El Tapeo de Cervantes",
            "phone": "+34 952 60 94 58",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipP6eBR3FglbwyBNSa2ol0MryfNNmJZSdsqNe9f-=w800-h500-k-no",
            "photos_count": 1971,
            "photos_data": [
              {
                "latitude": 36.7239961,
                "longitude": -4.419353,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipP6eBR3FglbwyBNSa2ol0MryfNNmJZSdsqNe9f-=w203-h152-k-no",
                "photo_date": "7/10/2017 9:00:00",
                "photo_id": "AF1QipP6eBR3FglbwyBNSa2ol0MryfNNmJZSdsqNe9f-",
                "photo_source_video": null,
                "photo_tag_ids": [
                  5,
                  2
                ],
                "photo_tags": [
                  "front",
                  "other"
                ],
                "photo_upload_source": "photo_transfer",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipP6eBR3FglbwyBNSa2ol0MryfNNmJZSdsqNe9f-",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipP6eBR3FglbwyBNSa2ol0MryfNNmJZSdsqNe9f-=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72396118469981,
                "longitude": -4.419311413638249,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPnDa55BufcUtUh5Fz0FHtAQa0DXqYA9vvf0WpH=w203-h360-k-no",
                "photo_date": "4/22/2022 11:00:00",
                "photo_id": "AF1QipPnDa55BufcUtUh5Fz0FHtAQa0DXqYA9vvf0WpH",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipPnDa55BufcUtUh5Fz0FHtAQa0DXqYA9vvf0WpH=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_ios",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPnDa55BufcUtUh5Fz0FHtAQa0DXqYA9vvf0WpH",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPnDa55BufcUtUh5Fz0FHtAQa0DXqYA9vvf0WpH=w2048-h2048-k-no"
              },
              {
                "latitude": 36.7239961,
                "longitude": -4.419353,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMAMvR-ji04N4O4xA-iLmFwaPUnEwmqmtar387Y=w203-h135-k-no",
                "photo_date": "10/12/2023 13:00:00",
                "photo_id": "AF1QipMAMvR-ji04N4O4xA-iLmFwaPUnEwmqmtar387Y",
                "photo_source_video": null,
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmb_menu_editor_nmx_lu",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMAMvR-ji04N4O4xA-iLmFwaPUnEwmqmtar387Y",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMAMvR-ji04N4O4xA-iLmFwaPUnEwmqmtar387Y=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJKzfemL_3cg0RKTL3Ih44eLQ",
            "plus_code": "8C8QPHFJ+H7",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 12,
                    "time": "12p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 21,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 30,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 55,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 57,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 55,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 43,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 30,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": []
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 21,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 22,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 20,
                    "time": "3p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 43,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 68,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 91,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 88,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 73,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 23,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 24,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 20,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 25,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 42,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 38,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 28,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 16,
                    "time": "12p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 21,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 20,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 54,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 71,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 67,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 44,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 22,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 29,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 29,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 72,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 95,
                    "time": "6p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 21,
                    "percentage": 98,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 22,
                    "percentage": 86,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 23,
                    "percentage": 62,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 29,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 40,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 34,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 54,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 81,
                    "time": "6p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 100,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 93,
                    "time": "9p",
                    "title": "Usually busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 60,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": "live",
                "percentage": 1,
                "time": 23,
                "title": "Not busy"
              }
            ],
            "postal_code": "29012",
            "posts": [
              {
                "body": "Queremos informarles que, con motivo de las vacaciones anuales, nuestra empresa estará cerrada desde el 6 hasta el 19 de Febrero, inclusive. Durante este período, nuestro equipo se tomará un merecido descanso para recargar energías y regresar con renovado entusiasmo.\n\nEstaremos de vuelta y listos para atenderles el 20 de Febrero. Esperamos contar con su presencia y continuar brindándoles el servicio de calidad al que están acostumbrados.\n\nPueden visitar otro de los restaurantes de la familia durante nuestras vacaciones, El Mesón de Cervantes se encuentra a 100 metros en Calle Álamos 11, Málaga. Realiza tu reserva desde aquí.",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipOotGmQqGiJUow10K2r_sqQctUw9xKpBbtjHG_u=h400-no",
                "link": "https://search.google.com/local/posts?q=El+Tapeo+de+Cervantes&ludocid=13004205626119107113&lpsid=CIHM0ogKEICAgIDtnfSXPQ&source=sh/x/localposts&lsig=AB86z5Xy2SpS7HUj65cHXBJvgrgp",
                "timestamp": 1707213167
              },
              {
                "body": "Recomendaciones del mes\n\nEstas opciones han sido diseñadas cuidadosamente para satisfacer su paladar con sabores excepcionales.\n\nPincho de Pollo Marinado en Azafrán\n\nUn bocado de pollo con el sutil aroma del azafrán que deleitará su paladar.\n\nPorra de Antequera\n\nPruebe una porra auténtica de Antequera, una deliciosa sopa fría que es todo un clásico de la cocina española.\n\nCarrillada Estofada\n\nNuestra carrillada estofada es un plato de carne tierna y sabrosa que se deshace en la boca.\n\nAtún a la Plancha con Emulsión de Coliflor\n\nSaboree el fresco sabor del atún a la plancha acompañado de una delicada emulsión de coliflor.\n\nMagret de Pato con Manzanas Caramelizadas y Mermelada de Frutos Rojos\n\nUna combinación excepcional de sabores, el magret de pato con manzanas caramelizadas y mermelada de frutos rojos es una verdadera delicia.",
                "image": "https://lh3.googleusercontent.com/geougc/AF1QipMys-6-ag2sOJxIlIy8pj_ojLHM48tKhlM8OYl9=h400-no",
                "link": "https://search.google.com/local/posts?q=El+Tapeo+de+Cervantes&ludocid=13004205626119107113&lpsid=CIHM0ogKEICAgID5wPCNZg&source=sh/x/localposts&lsig=AB86z5Xy2SpS7HUj65cHXBJvgrgp",
                "timestamp": 1697627192
              }
            ],
            "query": "El Tapeo de Cervantes, Malaga",
            "range": "$$",
            "rating": 4.2,
            "reservation_links": [
              "https://book.octotable.com/otb/form/index.xhtml?pubkey=38005bec9ed04b7ca51d23fb670520c3&property=882769"
            ],
            "reviews": 2344,
            "reviews_id": "-5442538447590444503",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJKzfemL_3cg0RKTL3Ih44eLQ&q=El+Tapeo+de+Cervantes,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 185,
              "2": 105,
              "3": 185,
              "4": 432,
              "5": 1437
            },
            "reviews_tags": [
              "book",
              "tourist trap",
              "half portions",
              "tip",
              "table reservation",
              "wineglass",
              "tuna tataki",
              "pressure",
              "innovative",
              "black rice"
            ],
            "site": "https://eltapeodecervantes.com/",
            "state": "Málaga",
            "street": "C. Cárcer, 8",
            "street_view": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=vk4YfhCrZRjgCTTK3gOBuA&cb_client=search.gws-prod.gps&w=1600&h=1000&yaw=173.55539&pitch=0&thumbfov=100",
            "subtypes": "Spanish restaurant, Restaurant, Tapas bar, Tapas restaurant, Wine bar",
            "time_zone": "Europe/Madrid",
            "type": "Spanish restaurant",
            "typical_time_spent": "People typically spend 1-2 hours here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "1-3:30PM,7:30-11:30PM",
              "Monday": "Closed",
              "Saturday": "1-3:30PM,7:30-11:30PM",
              "Sunday": "1-3:30PM,7:30-11:30PM",
              "Thursday": "1-3:30PM,7:30-11:30PM",
              "Tuesday": "1-3:30PM,7:30-11:30PM",
              "Wednesday": "1-3:30PM,7:30-11:30PM"
            },
            "working_hours_old_format": "Monday:Closed|Tuesday:1-3:30PM,7:30-11:30PM|Wednesday:1-3:30PM,7:30-11:30PM|Thursday:1-3:30PM,7:30-11:30PM|Friday:1-3:30PM,7:30-11:30PM|Saturday:1-3:30PM,7:30-11:30PM|Sunday:1-3:30PM,7:30-11:30PM"
          }
        ]
      ],
      "Sobrino de Botín, Madrid": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible seating": true
              },
              "Amenities": {
                "Restroom": true
              },
              "Atmosphere": {
                "Cozy": true,
                "Romantic": true,
                "Upscale": true
              },
              "Crowd": {
                "Groups": true
              },
              "Dining options": {
                "Breakfast": true,
                "Dessert": true,
                "Dinner": true,
                "Lunch": true,
                "Seating": true
              },
              "Offerings": {
                "Alcohol": true,
                "Beer": true,
                "Cocktails": true,
                "Coffee": true,
                "Hard liquor": true,
                "Healthy options": true,
                "Late-night food": true,
                "Small plates": true,
                "Wine": true
              },
              "Parking": {
                "Paid parking lot": true,
                "Parking": true
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Planning": {
                "Accepts reservations": true,
                "Reservations required": true
              },
              "Service options": {
                "Delivery": false,
                "Dine-in": true,
                "Outdoor seating": true,
                "Takeout": false
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "restaurants",
            "cid": "18333321966658437093",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": null,
            "full_address": "C. de Cuchilleros, 17, Centro, 28005 Madrid, Spain",
            "google_id": "0xd42292d0e183a03:0xfe6d0b31cf8abfe5",
            "latitude": 40.4141769,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Bot%C3%ADn/@40.4141769,-3.7079546999999997,14z/data=!4m8!1m2!2m1!1sBot%C3%ADn!3m4!1s0xd42292d0e183a03:0xfe6d0b31cf8abfe5!8m2!3d40.4141769!4d-3.7079546999999997",
            "location_reviews_link": "https://www.google.com/maps/place/Bot%C3%ADn/@40.4141769,-3.7079546999999997,13.99z/data=!4m16!1m7!3m6!1s0xd42292d0e183a03:0xfe6d0b31cf8abfe5!2sBot%C3%ADn!8m2!3d40.4141769!4d-3.7079546999999997!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd42292d0e183a03:0xfe6d0b31cf8abfe5!8m2!3d40.4141769!4d-3.7079546999999997!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-KPgJz5xV5GY/AAAAAAAAAAI/AAAAAAAAAAA/GEa3fsh2EcA/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -3.7079546999999997,
            "menu_link": null,
            "name": "Botín",
            "order_links": null,
            "other_hours": null,
            "owner_id": "109213404126022655239",
            "owner_link": "https://www.google.com/maps/contrib/109213404126022655239",
            "owner_title": "Botín",
            "phone": "+34 913 66 42 17",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNN2CFS4uooDOkUuufutamPV21NdJMLgt7HJXz1=w800-h500-k-no",
            "photos_count": 6523,
            "photos_data": [
              {
                "latitude": 40.41417689224286,
                "longitude": -3.7079547115697946,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNN2CFS4uooDOkUuufutamPV21NdJMLgt7HJXz1=w203-h152-k-no",
                "photo_date": "2/16/2023 19:00:00",
                "photo_id": "AF1QipNN2CFS4uooDOkUuufutamPV21NdJMLgt7HJXz1",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNN2CFS4uooDOkUuufutamPV21NdJMLgt7HJXz1",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNN2CFS4uooDOkUuufutamPV21NdJMLgt7HJXz1=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41417689224286,
                "longitude": -3.7079547115697946,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOOtQrsCmqGfLoquECYEH5Bav6AK1MztN3eBQlm=w203-h360-k-no",
                "photo_date": "6/2/2023 21:00:00",
                "photo_id": "AF1QipOOtQrsCmqGfLoquECYEH5Bav6AK1MztN3eBQlm",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipOOtQrsCmqGfLoquECYEH5Bav6AK1MztN3eBQlm=m18",
                "photo_tag_ids": [
                  8
                ],
                "photo_tags": [
                  "food"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOOtQrsCmqGfLoquECYEH5Bav6AK1MztN3eBQlm",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOOtQrsCmqGfLoquECYEH5Bav6AK1MztN3eBQlm=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41417689224286,
                "longitude": -3.7079547115697946,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMx0NkTQoQ735Z_8mDv3rvtGuqPRN_zfaDDvRL1=w203-h152-k-no",
                "photo_date": "4/14/2024 17:00:00",
                "photo_id": "AF1QipMx0NkTQoQ735Z_8mDv3rvtGuqPRN_zfaDDvRL1",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMx0NkTQoQ735Z_8mDv3rvtGuqPRN_zfaDDvRL1",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMx0NkTQoQ735Z_8mDv3rvtGuqPRN_zfaDDvRL1=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJAzoYDi0pQg0R5b-KzzELbf4",
            "plus_code": "8CGRC77R+MR",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 66,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 82,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 47,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 57,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 55,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 42,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 47,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 57,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 53,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 42,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 52,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 51,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 39,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 41,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 48,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 44,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 41,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 50,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 47,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 33,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 45,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 56,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 48,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 44,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 55,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 53,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 40,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 45,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 58,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 53,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 47,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 60,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 60,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 47,
                    "time": "9p",
                    "title": "Usually not too busy"
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 49,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 61,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 57,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 55,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 75,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 83,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 23,
                    "percentage": 70,
                    "time": "9p",
                    "title": "Usually a little busy"
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 69,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 93,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 92,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 68,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 92,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 22,
                    "percentage": 100,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 23,
                    "percentage": 83,
                    "time": "9p",
                    "title": "Usually as busy as it gets"
                  }
                ]
              }
            ],
            "postal_code": "28005",
            "posts": null,
            "query": "Sobrino de Botín, Madrid",
            "range": "$$$",
            "rating": 4.3,
            "reservation_links": null,
            "reviews": 2982,
            "reviews_id": "-113422107051114523",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJAzoYDi0pQg0R5b-KzzELbf4&q=*&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 194,
              "2": 118,
              "3": 233,
              "4": 463,
              "5": 1974
            },
            "reviews_tags": [
              "roast suckling pig",
              "history",
              "garlic soup",
              "basement",
              "ancient",
              "roast pig",
              "hemingway",
              "tour",
              "plaza mayor",
              "tuna"
            ],
            "site": "https://botin.es/",
            "state": "Madrid",
            "street": "C. de Cuchilleros, 17",
            "street_view": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=yb1rHu6Mg8DSmcHC_UpNyQ&cb_client=search.gws-prod.gps&w=1600&h=1000&yaw=97.93231&pitch=0&thumbfov=100",
            "subtypes": "Spanish restaurant",
            "time_zone": "Europe/Madrid",
            "type": "Spanish restaurant",
            "typical_time_spent": "People typically spend 2 hours here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "1-4PM,8-11:30PM",
              "Monday": "1-4PM,8-11:30PM",
              "Saturday": "1-4PM,8-11:30PM",
              "Sunday": "1-4PM,8-11:30PM",
              "Thursday": "1-4PM,8-11:30PM",
              "Tuesday": "1-4PM,8-11:30PM",
              "Wednesday": "1-4PM,8-11:30PM"
            },
            "working_hours_old_format": "Monday:1-4PM,8-11:30PM|Tuesday:1-4PM,8-11:30PM|Wednesday:1-4PM,8-11:30PM|Thursday:1-4PM,8-11:30PM|Friday:1-4PM,8-11:30PM|Saturday:1-4PM,8-11:30PM|Sunday:1-4PM,8-11:30PM"
          }
        ]
      ],
      "Puerta del Sol, Madrid": [
        [
          {
            "about": {},
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "CLOSED_PERMANENTLY",
            "category": null,
            "cid": "7587138583347971705",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": "Busy central square featuring well-known buildings, statues & a clock that rings in the New Year.",
            "full_address": "Centro, Madrid, Spain",
            "google_id": "0xd422880a07d7dc7:0x694aee57da1dc679",
            "latitude": 40.4169473,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Puerta+del+Sol/@40.4169473,-3.7035285,14z/data=!4m8!1m2!2m1!1sPuerta+del+Sol!3m4!1s0xd422880a07d7dc7:0x694aee57da1dc679!8m2!3d40.4169473!4d-3.7035285",
            "location_reviews_link": "https://www.google.com/maps/place/Puerta+del+Sol/@40.4169473,-3.7035285,13.99z/data=!4m16!1m7!3m6!1s0xd422880a07d7dc7:0x694aee57da1dc679!2sPuerta+del+Sol!8m2!3d40.4169473!4d-3.7035285!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd422880a07d7dc7:0x694aee57da1dc679!8m2!3d40.4169473!4d-3.7035285!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -3.7035285,
            "menu_link": null,
            "name": "Puerta del Sol",
            "order_links": null,
            "other_hours": null,
            "owner_id": "",
            "owner_link": null,
            "owner_title": null,
            "phone": null,
            "photo": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhEbUCzBNIKDMax7KZYgvMfY7XWjAmkjoLXQ2DMHfKdi3ODJvqg-cfuamlGbAsDoHECxfOvZcDeIELIeNjY-2Kuq9PPCe6wS8Qbg_QQNP7qLlML5pjsgRaVPdLEIvE2W5kW5ajNrSfPjnXtYaFN8Enc5bGMLyvUjC8_nO2NVzY3wAeNaEvTa9YNx=w800-h500-k-no",
            "photos_count": 3171,
            "photos_data": [
              {
                "latitude": 40.41694733677474,
                "longitude": -3.7035285118303936,
                "original_photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhEbUCzBNIKDMax7KZYgvMfY7XWjAmkjoLXQ2DMHfKdi3ODJvqg-cfuamlGbAsDoHECxfOvZcDeIELIeNjY-2Kuq9PPCe6wS8Qbg_QQNP7qLlML5pjsgRaVPdLEIvE2W5kW5ajNrSfPjnXtYaFN8Enc5bGMLyvUjC8_nO2NVzY3wAeNaEvTa9YNx=w203-h134-k-no",
                "photo_date": "5/17/2021 13:00:00",
                "photo_id": "http://t0.gstatic.com/images?q=tbn:ANd9GcQzKJKvEBhDAY-jKmXo-MA6OaGLJPVhcrmoA-GoiYLirGkblWMs",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "TRAVEL_SNAPWIRE",
                "photo_url": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhEbUCzBNIKDMax7KZYgvMfY7XWjAmkjoLXQ2DMHfKdi3ODJvqg-cfuamlGbAsDoHECxfOvZcDeIELIeNjY-2Kuq9PPCe6wS8Qbg_QQNP7qLlML5pjsgRaVPdLEIvE2W5kW5ajNrSfPjnXtYaFN8Enc5bGMLyvUjC8_nO2NVzY3wAeNaEvTa9YNx",
                "photo_url_big": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhEbUCzBNIKDMax7KZYgvMfY7XWjAmkjoLXQ2DMHfKdi3ODJvqg-cfuamlGbAsDoHECxfOvZcDeIELIeNjY-2Kuq9PPCe6wS8Qbg_QQNP7qLlML5pjsgRaVPdLEIvE2W5kW5ajNrSfPjnXtYaFN8Enc5bGMLyvUjC8_nO2NVzY3wAeNaEvTa9YNx=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41694733677474,
                "longitude": -3.7035285118303936,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN_yKB1estFNbJzFU3w1pKO7nUbv9NFZ1K5v3oR=w203-h114-k-no",
                "photo_date": "10/11/2020 20:00:00",
                "photo_id": "AF1QipN_yKB1estFNbJzFU3w1pKO7nUbv9NFZ1K5v3oR",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipN_yKB1estFNbJzFU3w1pKO7nUbv9NFZ1K5v3oR=m18",
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN_yKB1estFNbJzFU3w1pKO7nUbv9NFZ1K5v3oR",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN_yKB1estFNbJzFU3w1pKO7nUbv9NFZ1K5v3oR=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41694733677474,
                "longitude": -3.7035285118303936,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMMS8gBD78B0HsPtx4iqQmYtTrUdznhoGfygATY=w203-h134-k-no",
                "photo_date": "10/13/2021 7:00:00",
                "photo_id": "AF1QipMMS8gBD78B0HsPtx4iqQmYtTrUdznhoGfygATY",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMMS8gBD78B0HsPtx4iqQmYtTrUdznhoGfygATY",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMMS8gBD78B0HsPtx4iqQmYtTrUdznhoGfygATY=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJx319oIAoQg0RecYd2lfuSmk",
            "plus_code": null,
            "popular_times": null,
            "postal_code": null,
            "posts": null,
            "query": "Puerta del Sol, Madrid",
            "range": null,
            "rating": null,
            "reservation_links": null,
            "reviews": null,
            "reviews_id": null,
            "reviews_link": null,
            "reviews_per_score": null,
            "reviews_per_score_1": null,
            "reviews_per_score_2": null,
            "reviews_per_score_3": null,
            "reviews_per_score_4": null,
            "reviews_per_score_5": null,
            "reviews_tags": null,
            "site": null,
            "state": "Madrid",
            "street": null,
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipPLoTg6OeWE1V8uiouNyk67wq94l8E74mdyuN8s=w1600-h1000-k-no",
            "subtypes": "Town square",
            "time_zone": "Europe/Madrid",
            "type": "Town square",
            "typical_time_spent": null,
            "us_state": null,
            "verified": null,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Malaga Cathedral, Malaga": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": false
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "18213615145923320314",
            "city": "Málaga",
            "country": "Spain",
            "country_code": "ES",
            "description": "Iconic national landmark with a 16th-century baroque facade & ornate choir stalls, plus a museum.",
            "full_address": "C. Molina Lario, 9, Distrito Centro, 29015 Málaga, Spain",
            "google_id": "0xd72f79511cea98d:0xfcc3c279b06f19fa",
            "latitude": 36.7201678,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Catedral+de+la+Encarnaci%C3%B3n+de+M%C3%A1laga/@36.7201678,-4.4193106,14z/data=!4m8!1m2!2m1!1sCatedral+de+la+Encarnaci%C3%B3n+de+M%C3%A1laga!3m4!1s0xd72f79511cea98d:0xfcc3c279b06f19fa!8m2!3d36.7201678!4d-4.4193106",
            "location_reviews_link": "https://www.google.com/maps/place/Catedral+de+la+Encarnaci%C3%B3n+de+M%C3%A1laga/@36.7201678,-4.4193106,13.99z/data=!4m16!1m7!3m6!1s0xd72f79511cea98d:0xfcc3c279b06f19fa!2sCatedral+de+la+Encarnaci%C3%B3n+de+M%C3%A1laga!8m2!3d36.7201678!4d-4.4193106!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd72f79511cea98d:0xfcc3c279b06f19fa!8m2!3d36.7201678!4d-4.4193106!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": null,
            "longitude": -4.4193106,
            "menu_link": null,
            "name": "Catedral de la Encarnación de Málaga",
            "order_links": null,
            "other_hours": null,
            "owner_id": null,
            "owner_link": null,
            "owner_title": "Catedral de la Encarnación de Málaga",
            "phone": "+34 617 50 05 82",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNVAZZmwzyuSWoa00HN68JONOEwO2cqhG7gA_Mh=w800-h500-k-no",
            "photos_count": 167606,
            "photos_data": [
              {
                "latitude": 36.72016778484853,
                "longitude": -4.419310628664896,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNVAZZmwzyuSWoa00HN68JONOEwO2cqhG7gA_Mh=w203-h152-k-no",
                "photo_date": "12/1/2022 11:00:00",
                "photo_id": "AF1QipNVAZZmwzyuSWoa00HN68JONOEwO2cqhG7gA_Mh",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_ios",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNVAZZmwzyuSWoa00HN68JONOEwO2cqhG7gA_Mh",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNVAZZmwzyuSWoa00HN68JONOEwO2cqhG7gA_Mh=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72016778484853,
                "longitude": -4.419310628664896,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOPKJRcdWVhRUMejBupRWwrWldNuwobbHwPMD_H=w203-h152-k-no",
                "photo_date": "10/11/2022 11:00:00",
                "photo_id": "AF1QipOPKJRcdWVhRUMejBupRWwrWldNuwobbHwPMD_H",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "dragonfly_tactile",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOPKJRcdWVhRUMejBupRWwrWldNuwobbHwPMD_H",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOPKJRcdWVhRUMejBupRWwrWldNuwobbHwPMD_H=w2048-h2048-k-no"
              },
              {
                "latitude": 36.72016778484853,
                "longitude": -4.419310628664896,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOSkprUdP9Uvj4fZeqzFmaDyfIVQ-zunEMWUTa_=w203-h270-k-no",
                "photo_date": "4/24/2022 21:00:00",
                "photo_id": "AF1QipOSkprUdP9Uvj4fZeqzFmaDyfIVQ-zunEMWUTa_",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_ios_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOSkprUdP9Uvj4fZeqzFmaDyfIVQ-zunEMWUTa_",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOSkprUdP9Uvj4fZeqzFmaDyfIVQ-zunEMWUTa_=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJjanOEZX3cg0R-hlvsHnCw_w",
            "plus_code": "8C8QPHCJ+37",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 11,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 12,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 13,
                    "percentage": 0,
                    "time": "12p",
                    "title": ""
                  },
                  {
                    "hour": 14,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 93,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 82,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 52,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 73,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 84,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 82,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 74,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 55,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 52,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 51,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 70,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 80,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 79,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 57,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 50,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 44,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 50,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 73,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 78,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 71,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 66,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 65,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 46,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 66,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 79,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 80,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 68,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 60,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 58,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 46,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 68,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 82,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 84,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 77,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 72,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 71,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 76,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 83,
                    "time": "6p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 52,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 75,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 89,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 93,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 89,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 84,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 78,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 70,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "29015",
            "posts": null,
            "query": "Malaga Cathedral, Malaga",
            "range": null,
            "rating": 4.6,
            "reservation_links": null,
            "reviews": 27638,
            "reviews_id": "-233128927786231302",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJjanOEZX3cg0R-hlvsHnCw_w&q=Malaga+Cathedral,+Malaga&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 320,
              "2": 194,
              "3": 1211,
              "4": 5910,
              "5": 20003
            },
            "reviews_tags": [
              "roof",
              "audio guide",
              "mosque",
              "holy week",
              "choir stalls",
              "catholic monarchs of spain",
              "renaissance architecture",
              "entrance fee",
              "spanish renaissance",
              "1525"
            ],
            "site": "https://malagacatedral.com/",
            "state": "Málaga",
            "street": "C. Molina Lario, 9",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMbeqbv4egFvsGV_Gy_cqhzCslAyNDGjWy1CHyh=w1600-h1000-k-no",
            "subtypes": "Cathedral, Catholic cathedral, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Cathedral",
            "typical_time_spent": null,
            "us_state": null,
            "verified": false,
            "working_hours": {
              "Friday": "10AM-6:30PM",
              "Monday": "10AM-6:30PM",
              "Saturday": "10AM-6PM",
              "Sunday": "2-6PM",
              "Thursday": "10AM-6:30PM",
              "Tuesday": "10AM-6:30PM",
              "Wednesday": "10AM-6:30PM"
            },
            "working_hours_old_format": "Monday:10AM-6:30PM|Tuesday:10AM-6:30PM|Wednesday:10AM-6:30PM|Thursday:10AM-6:30PM|Friday:10AM-6:30PM|Saturday:10AM-6PM|Sunday:2-6PM"
          }
        ]
      ],
      "Santiago Bernabéu Stadium, Madrid": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true
              },
              "Planning": {
                "Getting tickets in advance recommended": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Chamartín",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "12177723301084993928",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": "81,000-capacity ground for Real Madrid, with regular matches plus tours of stadium and trophy room.",
            "full_address": "Av. de Concha Espina, 1, Chamartín, 28036 Madrid, Spain",
            "google_id": "0xd4228e23705d39f:0xa8fff6d26e2b1988",
            "latitude": 40.4530387,
            "located_google_id": "0xd4228e23007b26f:0x5392b88b271a84d2",
            "located_in": "Partner Support",
            "location_link": "https://www.google.com/maps/place/Santiago+Bernab%C3%A9u+Stadium/@40.4530387,-3.6883337,14z/data=!4m8!1m2!2m1!1sSantiago+Bernab%C3%A9u+Stadium!3m4!1s0xd4228e23705d39f:0xa8fff6d26e2b1988!8m2!3d40.4530387!4d-3.6883337",
            "location_reviews_link": "https://www.google.com/maps/place/Santiago+Bernab%C3%A9u+Stadium/@40.4530387,-3.6883337,13.99z/data=!4m16!1m7!3m6!1s0xd4228e23705d39f:0xa8fff6d26e2b1988!2sSantiago+Bernab%C3%A9u+Stadium!8m2!3d40.4530387!4d-3.6883337!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd4228e23705d39f:0xa8fff6d26e2b1988!8m2!3d40.4530387!4d-3.6883337!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-xYqsFjsfENw/AAAAAAAAAAI/AAAAAAAAAAA/dYapFIHdjsI/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -3.6883337,
            "menu_link": null,
            "name": "Santiago Bernabéu Stadium",
            "name_for_emails": "Santiago Bernabéu Stadium",
            "order_links": null,
            "other_hours": null,
            "owner_id": "104146100747427984145",
            "owner_link": "https://www.google.com/maps/contrib/104146100747427984145",
            "owner_title": "Santiago Bernabéu Stadium",
            "phone": "+34 913 98 43 00",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPXffvFNqEmk1Epeky-tZwnloD1tzdHO0cqHSpZ=w800-h500-k-no",
            "photos_count": 476063,
            "photos_data": [
              {
                "latitude": 40.4542206,
                "longitude": -3.6883160999999998,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPXffvFNqEmk1Epeky-tZwnloD1tzdHO0cqHSpZ=w203-h114-k-no",
                "photo_date": "3/5/2023 15:00:00",
                "photo_id": "AF1QipPXffvFNqEmk1Epeky-tZwnloD1tzdHO0cqHSpZ",
                "photo_source_video": null,
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPXffvFNqEmk1Epeky-tZwnloD1tzdHO0cqHSpZ",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPXffvFNqEmk1Epeky-tZwnloD1tzdHO0cqHSpZ=w2048-h2048-k-no"
              },
              {
                "latitude": 40.45305413217405,
                "longitude": -3.688344474907261,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipObTni2t5UA8W6Max1BoHKUbNgc0agHQmHvk6ND=w203-h114-k-no",
                "photo_date": "10/9/2019 15:00:00",
                "photo_id": "AF1QipObTni2t5UA8W6Max1BoHKUbNgc0agHQmHvk6ND",
                "photo_source_video": "https://lh3.googleusercontent.com/ggs/AF1QipObTni2t5UA8W6Max1BoHKUbNgc0agHQmHvk6ND=m18",
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipObTni2t5UA8W6Max1BoHKUbNgc0agHQmHvk6ND",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipObTni2t5UA8W6Max1BoHKUbNgc0agHQmHvk6ND=w2048-h2048-k-no"
              },
              {
                "latitude": 40.453038720913355,
                "longitude": -3.688333716048159,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN56H3adjy-m10miChOwBn9tx0dgndriqi_EEYl=w203-h134-k-no",
                "photo_date": "9/29/2021 8:00:00",
                "photo_id": "AF1QipN56H3adjy-m10miChOwBn9tx0dgndriqi_EEYl",
                "photo_source_video": null,
                "photo_tag_ids": [
                  1
                ],
                "photo_tags": [
                  "all"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN56H3adjy-m10miChOwBn9tx0dgndriqi_EEYl",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN56H3adjy-m10miChOwBn9tx0dgndriqi_EEYl=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJn9MFN-IoQg0RiBkrbtL2_6g",
            "plus_code": "8CGRF836+6M",
            "popular_times": null,
            "postal_code": "28036",
            "posts": null,
            "query": "Santiago Bernabéu Stadium, Madrid",
            "range": null,
            "rating": 4.6,
            "reservation_links": null,
            "reviews": 138959,
            "reviews_id": "-6269020772624557688",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJn9MFN-IoQg0RiBkrbtL2_6g&q=Santiago+Bernab%C3%A9u+Stadium,+Madrid&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 6624,
              "2": 2150,
              "3": 5825,
              "4": 15365,
              "5": 108995
            },
            "reviews_tags": null,
            "site": "https://www.realmadrid.com/estadio-santiago-bernabeu?utm_medium=googlebusiness",
            "state": "Madrid",
            "street": "Av. de Concha Espina, 1",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNfrAsTzIVJ_k1zRWvjE2c7gEsFtYLfOvSlZrha=w1600-h1000-k-no",
            "subtypes": "Arena, Soccer field, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Arena",
            "typical_time_spent": null,
            "us_state": null,
            "verified": true,
            "working_hours": null,
            "working_hours_old_format": null
          }
        ]
      ],
      "Mercado de San Miguel, Madrid": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": false,
                "Wheelchair accessible restroom": true,
                "Wheelchair accessible seating": false
              },
              "Payments": {
                "Credit cards": true,
                "Debit cards": true,
                "NFC mobile payments": true
              },
              "Service options": {
                "In-store shopping": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "1598750187957883112",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": "Circa-1916 covered market offering local food, delicacies & events in an elegant ambiance.",
            "full_address": "Pl. de San Miguel, s/n, Centro, 28005 Madrid, Spain",
            "google_id": "0xd42287921196e2d:0x162fe6d34dd190e8",
            "latitude": 40.4153794,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Mercado+de+San+Miguel/@40.4153794,-3.7089697,14z/data=!4m8!1m2!2m1!1sMercado+de+San+Miguel!3m4!1s0xd42287921196e2d:0x162fe6d34dd190e8!8m2!3d40.4153794!4d-3.7089697",
            "location_reviews_link": "https://www.google.com/maps/place/Mercado+de+San+Miguel/@40.4153794,-3.7089697,13.99z/data=!4m16!1m7!3m6!1s0xd42287921196e2d:0x162fe6d34dd190e8!2sMercado+de+San+Miguel!8m2!3d40.4153794!4d-3.7089697!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd42287921196e2d:0x162fe6d34dd190e8!8m2!3d40.4153794!4d-3.7089697!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-rYmoCnCRjJU/AAAAAAAAAAI/AAAAAAAAAAA/H925pArOYQ8/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -3.7089697,
            "menu_link": null,
            "name": "Mercado de San Miguel",
            "order_links": null,
            "other_hours": null,
            "owner_id": "114847793192173144272",
            "owner_link": "https://www.google.com/maps/contrib/114847793192173144272",
            "owner_title": "Mercado de San Miguel",
            "phone": "+34 915 42 49 36",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipNsVeF3Ko9Ae_IaaQD6994di1-rI9ZPS3wyYZ6P=w800-h500-k-no",
            "photos_count": 227540,
            "photos_data": [
              {
                "latitude": 40.41537942481943,
                "longitude": -3.708969698777917,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNsVeF3Ko9Ae_IaaQD6994di1-rI9ZPS3wyYZ6P=w203-h152-k-no",
                "photo_date": "1/27/2018 22:00:00",
                "photo_id": "AF1QipNsVeF3Ko9Ae_IaaQD6994di1-rI9ZPS3wyYZ6P",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNsVeF3Ko9Ae_IaaQD6994di1-rI9ZPS3wyYZ6P",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNsVeF3Ko9Ae_IaaQD6994di1-rI9ZPS3wyYZ6P=w2048-h2048-k-no"
              },
              {
                "latitude": 40.415534099999995,
                "longitude": -3.7089809000000002,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOlL7O4Gbl19wZ-48x9rgwwmaH2vKzgZhfUNkdg=w203-h152-k-no",
                "photo_date": "4/16/2024 13:00:00",
                "photo_id": "AF1QipOlL7O4Gbl19wZ-48x9rgwwmaH2vKzgZhfUNkdg",
                "photo_source_video": null,
                "photo_tag_ids": [
                  9,
                  1
                ],
                "photo_tags": [
                  "vibe",
                  "all"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOlL7O4Gbl19wZ-48x9rgwwmaH2vKzgZhfUNkdg",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOlL7O4Gbl19wZ-48x9rgwwmaH2vKzgZhfUNkdg=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41537942481943,
                "longitude": -3.708969698777917,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMJgPIX4-117NVYdUx-ysvW1NvlKIGJnEF4Y0Xl=w203-h152-k-no",
                "photo_date": "12/3/2021 7:00:00",
                "photo_id": "AF1QipMJgPIX4-117NVYdUx-ysvW1NvlKIGJnEF4Y0Xl",
                "photo_source_video": null,
                "photo_tag_ids": null,
                "photo_tags": null,
                "photo_upload_source": "gmm_android_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMJgPIX4-117NVYdUx-ysvW1NvlKIGJnEF4Y0Xl",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMJgPIX4-117NVYdUx-ysvW1NvlKIGJnEF4Y0Xl=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJLW4ZIXkoQg0R6JDRTdPmLxY",
            "plus_code": "8CGRC78R+5C",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 26,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 46,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 70,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 88,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 93,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 86,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 74,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 63,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 55,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 48,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 40,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 31,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 21,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 12,
                    "time": "9p",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 21,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 32,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 42,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 47,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 47,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 43,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 38,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 36,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 36,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 33,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 27,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 20,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 12,
                    "time": "9p",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 22,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 31,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 40,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 44,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 44,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 41,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 36,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 34,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 34,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 34,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 32,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 27,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 20,
                    "time": "9p",
                    "title": "Usually not busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 12,
                    "time": "9p",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 21,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 32,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 42,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 47,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 47,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 42,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 37,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 35,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 36,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 35,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 30,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 22,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 14,
                    "time": "9p",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 22,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 34,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 45,
                    "time": "12p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 51,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 52,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 41,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 39,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 40,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 41,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 39,
                    "time": "6p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 33,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 24,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 15,
                    "time": "9p",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 24,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 51,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 59,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 60,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 55,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 52,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 60,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 67,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 63,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 50,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 33,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 16,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 29,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 51,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 76,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 94,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 93,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 81,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 73,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 73,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 78,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 78,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 21,
                    "percentage": 69,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 22,
                    "percentage": 53,
                    "time": "9p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 23,
                    "percentage": 35,
                    "time": "9p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 0,
                    "percentage": 18,
                    "time": "12a",
                    "title": "Usually not busy"
                  }
                ]
              },
              {
                "day": "live",
                "percentage": 20,
                "time": 23,
                "title": "Not too busy"
              }
            ],
            "postal_code": "28005",
            "posts": null,
            "query": "Mercado de San Miguel, Madrid",
            "range": null,
            "rating": 4.4,
            "reservation_links": null,
            "reviews": 131520,
            "reviews_id": "1598750187957883112",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJLW4ZIXkoQg0R6JDRTdPmLxY&q=Mercado+de+San+Miguel,+Madrid&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 2594,
              "2": 3675,
              "3": 13197,
              "4": 33787,
              "5": 78267
            },
            "reviews_tags": [
              "tourists",
              "gastronomic",
              "spanish food",
              "ostrea",
              "safety pins",
              "jamon",
              "race stage",
              "finger food",
              "drinks",
              "fantástico"
            ],
            "site": "https://mercadodesanmiguel.es/",
            "state": "Madrid",
            "street": "Pl. de San Miguel, s/n",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipNIURSzDceCxAay20reLGfz59tgCGjRk-DyFjQ2=w1600-h1000-k-no",
            "subtypes": "Fresh food market, Food court, Historical landmark, Market, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Fresh food market",
            "typical_time_spent": "People typically spend 20 min to 1 hr here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "10AM-1AM",
              "Monday": "10AM-12AM",
              "Saturday": "10AM-1AM",
              "Sunday": "10AM-12AM",
              "Thursday": "10AM-12AM",
              "Tuesday": "10AM-12AM",
              "Wednesday": "10AM-12AM"
            },
            "working_hours_old_format": "Monday:10AM-12AM|Tuesday:10AM-12AM|Wednesday:10AM-12AM|Thursday:10AM-12AM|Friday:10AM-1AM|Saturday:10AM-1AM|Sunday:10AM-12AM"
          }
        ]
      ],
      "Palacio Real, Madrid": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true,
                "Wheelchair accessible restroom": true
              },
              "Children": {
                "Good for kids": true
              },
              "Planning": {
                "Getting tickets in advance recommended": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Centro",
            "business_status": "OPERATIONAL",
            "category": "attractions",
            "cid": "3350656655825979473",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": "18th-century, ridge-top palace for state occasions, plus tours with rooms full of art and antiques.",
            "full_address": "C. de Bailén, s/n, Centro, 28071 Madrid, Spain",
            "google_id": "0xd42287e7da4a9c1:0x2e7fec79d6ce4851",
            "latitude": 40.417955,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Royal+Palace+of+Madrid/@40.417955,-3.7143119999999996,14z/data=!4m8!1m2!2m1!1sRoyal+Palace+of+Madrid!3m4!1s0xd42287e7da4a9c1:0x2e7fec79d6ce4851!8m2!3d40.417955!4d-3.7143119999999996",
            "location_reviews_link": "https://www.google.com/maps/place/Royal+Palace+of+Madrid/@40.417955,-3.7143119999999996,13.99z/data=!4m16!1m7!3m6!1s0xd42287e7da4a9c1:0x2e7fec79d6ce4851!2sRoyal+Palace+of+Madrid!8m2!3d40.417955!4d-3.7143119999999996!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd42287e7da4a9c1:0x2e7fec79d6ce4851!8m2!3d40.417955!4d-3.7143119999999996!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh5.googleusercontent.com/-jioGCqWkDKw/AAAAAAAAAAI/AAAAAAAAAAA/5Uz3gBmzXr0/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -3.7143119999999996,
            "menu_link": null,
            "name": "Royal Palace of Madrid",
            "order_links": null,
            "other_hours": null,
            "owner_id": "101519037783260500678",
            "owner_link": "https://www.google.com/maps/contrib/101519037783260500678",
            "owner_title": "Royal Palace of Madrid",
            "phone": "+34 914 54 87 00",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipPp7gG6UeQm7kyfY5d_KCZbDZnKjsFGMTpUH8YQ=w800-h500-k-no",
            "photos_count": 494897,
            "photos_data": [
              {
                "latitude": 40.41795503065277,
                "longitude": -3.714312035317524,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipPp7gG6UeQm7kyfY5d_KCZbDZnKjsFGMTpUH8YQ=w203-h135-k-no",
                "photo_date": "1/16/2022 12:00:00",
                "photo_id": "AF1QipPp7gG6UeQm7kyfY5d_KCZbDZnKjsFGMTpUH8YQ",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipPp7gG6UeQm7kyfY5d_KCZbDZnKjsFGMTpUH8YQ",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipPp7gG6UeQm7kyfY5d_KCZbDZnKjsFGMTpUH8YQ=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41795503065277,
                "longitude": -3.714312035317524,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipMCDgoDGkfRFq3t5AuIAV3AGoilW3fwl0jcyC7l=w203-h152-k-no",
                "photo_date": "8/28/2021 23:00:00",
                "photo_id": "AF1QipMCDgoDGkfRFq3t5AuIAV3AGoilW3fwl0jcyC7l",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipMCDgoDGkfRFq3t5AuIAV3AGoilW3fwl0jcyC7l",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipMCDgoDGkfRFq3t5AuIAV3AGoilW3fwl0jcyC7l=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41795503065277,
                "longitude": -3.714312035317524,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOIzIxwcgQJSh8cPw7ydgbVnRzW1uJfvgViosPX=w203-h270-k-no",
                "photo_date": "2/25/2022 13:00:00",
                "photo_id": "AF1QipOIzIxwcgQJSh8cPw7ydgbVnRzW1uJfvgViosPX",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOIzIxwcgQJSh8cPw7ydgbVnRzW1uJfvgViosPX",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOIzIxwcgQJSh8cPw7ydgbVnRzW1uJfvgViosPX=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJwamkfX4oQg0RUUjO1nnsfy4",
            "plus_code": "8CGRC79P+57",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 40,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 63,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 81,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 77,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 17,
                    "percentage": 0,
                    "time": "3p",
                    "title": ""
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 40,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 54,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 63,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 63,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 59,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 56,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 52,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 43,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 36,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 48,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 54,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 54,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 52,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 50,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 42,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 59,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 82,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 87,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 60,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 52,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 47,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 41,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 53,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 62,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 62,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 57,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 54,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 50,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 44,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 38,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 53,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 62,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 61,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 56,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 51,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 49,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 48,
                    "time": "3p",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 49,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 78,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 97,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 92,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 84,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 80,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 74,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "28071",
            "posts": null,
            "query": "Palacio Real, Madrid",
            "range": null,
            "rating": 4.7,
            "reservation_links": null,
            "reviews": 104063,
            "reviews_id": "3350656655825979473",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJwamkfX4oQg0RUUjO1nnsfy4&q=Palacio+Real,+Madrid&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 1286,
              "2": 840,
              "3": 4354,
              "4": 20048,
              "5": 77535
            },
            "reviews_tags": [
              "audio guide",
              "almudena cathedral",
              "body armor",
              "armory",
              "monarchy of spain",
              "throne room",
              "changing of the guard",
              "house of bourbon",
              "campo del moro",
              "giovanni battista tiepolo"
            ],
            "site": "https://www.patrimonionacional.es/real-sitio/palacio-real-de-madrid",
            "state": "Madrid",
            "street": "C. de Bailén, s/n",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipPXIStJjbOQATB_HWpw24ULBJT8eX7xXn73z6uk=w1600-h1000-k-no",
            "subtypes": "Historical landmark, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Historical landmark",
            "typical_time_spent": "People typically spend up to 2 hours here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "10AM-6PM",
              "Monday": "10AM-6PM",
              "Saturday": "10AM-6PM",
              "Sunday": "10AM-4PM",
              "Thursday": "10AM-6PM",
              "Tuesday": "10AM-6PM",
              "Wednesday": "10AM-6PM"
            },
            "working_hours_old_format": "Monday:10AM-6PM|Tuesday:10AM-6PM|Wednesday:10AM-6PM|Thursday:10AM-6PM|Friday:10AM-6PM|Saturday:10AM-6PM|Sunday:10AM-4PM"
          }
        ]
      ],
      "Museo del Prado, Madrid": [
        [
          {
            "about": {
              "Accessibility": {
                "Wheelchair accessible entrance": true,
                "Wheelchair accessible parking lot": true,
                "Wheelchair accessible restroom": false
              },
              "Amenities": {
                "Restaurant": true,
                "Restroom": true
              },
              "Children": {
                "Good for kids": true
              },
              "Highlights": {
                "Live performances": true
              },
              "Planning": {
                "Getting tickets in advance recommended": true
              }
            },
            "area_service": false,
            "booking_appointment_link": null,
            "borough": "Retiro",
            "business_status": "OPERATIONAL",
            "category": "museums",
            "cid": "1194844223299225690",
            "city": "Madrid",
            "country": "Spain",
            "country_code": "ES",
            "description": "Art museum with Velazquez, Goya and El Greco masterpieces in vast 12th- to 19th-century collection.",
            "full_address": "C. de Ruiz de Alarcón, 23, Retiro, 28014 Madrid, Spain",
            "google_id": "0xd42289d66d8a2ed:0x1094f07d93ad885a",
            "latitude": 40.413781799999995,
            "located_google_id": null,
            "located_in": null,
            "location_link": "https://www.google.com/maps/place/Museo+Nacional+del+Prado/@40.413781799999995,-3.6921271,14z/data=!4m8!1m2!2m1!1sMuseo+Nacional+del+Prado!3m4!1s0xd42289d66d8a2ed:0x1094f07d93ad885a!8m2!3d40.413781799999995!4d-3.6921271",
            "location_reviews_link": "https://www.google.com/maps/place/Museo+Nacional+del+Prado/@40.413781799999995,-3.6921271,13.99z/data=!4m16!1m7!3m6!1s0xd42289d66d8a2ed:0x1094f07d93ad885a!2sMuseo+Nacional+del+Prado!8m2!3d40.413781799999995!4d-3.6921271!16s%2Fg%2F11ckvg1dmb!3m7!1s0xd42289d66d8a2ed:0x1094f07d93ad885a!8m2!3d40.413781799999995!4d-3.6921271!9m1!1b1!16s%2Fg%2F11ckvg1dmb?entry=ttu",
            "logo": "https://lh6.googleusercontent.com/-LyVAAC0J660/AAAAAAAAAAI/AAAAAAAAAAA/sCf_Nx33_KQ/s44-p-k-no-ns-nd/photo.jpg",
            "longitude": -3.6921271,
            "menu_link": null,
            "name": "Museo Nacional del Prado",
            "order_links": null,
            "other_hours": null,
            "owner_id": "118186884596101175658",
            "owner_link": "https://www.google.com/maps/contrib/118186884596101175658",
            "owner_title": "Museo Nacional del Prado",
            "phone": "+34 913 30 28 00",
            "photo": "https://lh5.googleusercontent.com/p/AF1QipN6GUICd-pgKnWB_jzrtHMuEs7QxK_BcRI2NsQo=w800-h500-k-no",
            "photos_count": 211884,
            "photos_data": [
              {
                "latitude": 40.41378178215342,
                "longitude": -3.692127091081621,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipN6GUICd-pgKnWB_jzrtHMuEs7QxK_BcRI2NsQo=w203-h152-k-no",
                "photo_date": "4/4/2023 14:00:00",
                "photo_id": "AF1QipN6GUICd-pgKnWB_jzrtHMuEs7QxK_BcRI2NsQo",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android_review_post",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipN6GUICd-pgKnWB_jzrtHMuEs7QxK_BcRI2NsQo",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipN6GUICd-pgKnWB_jzrtHMuEs7QxK_BcRI2NsQo=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41369999703283,
                "longitude": -3.6927832800927756,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipNd-CFEgKTR05TEJZZAXco2nmzav7HRklgEYvjX=w203-h270-k-no",
                "photo_date": "5/10/2022 18:00:00",
                "photo_id": "AF1QipNd-CFEgKTR05TEJZZAXco2nmzav7HRklgEYvjX",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipNd-CFEgKTR05TEJZZAXco2nmzav7HRklgEYvjX",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipNd-CFEgKTR05TEJZZAXco2nmzav7HRklgEYvjX=w2048-h2048-k-no"
              },
              {
                "latitude": 40.41378178215342,
                "longitude": -3.692127091081621,
                "original_photo_url": "https://lh5.googleusercontent.com/p/AF1QipOGOy9gwDTAWPizE5bYwYMs6U56kyeRMsOixCLg=w203-h135-k-no",
                "photo_date": "1/21/2023 10:00:00",
                "photo_id": "AF1QipOGOy9gwDTAWPizE5bYwYMs6U56kyeRMsOixCLg",
                "photo_source_video": null,
                "photo_tag_ids": [
                  2
                ],
                "photo_tags": [
                  "front"
                ],
                "photo_upload_source": "gmm_android",
                "photo_url": "https://lh5.googleusercontent.com/p/AF1QipOGOy9gwDTAWPizE5bYwYMs6U56kyeRMsOixCLg",
                "photo_url_big": "https://lh5.googleusercontent.com/p/AF1QipOGOy9gwDTAWPizE5bYwYMs6U56kyeRMsOixCLg=w2048-h2048-k-no"
              }
            ],
            "place_id": "ChIJ7aLYZp0oQg0RWoitk33wlBA",
            "plus_code": "8CGRC875+G4",
            "popular_times": [
              {
                "day": 7,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 44,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 69,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 87,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 80,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 78,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 82,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 80,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 18,
                    "percentage": 65,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 1,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 51,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 73,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 86,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 84,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 75,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 68,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 70,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 67,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 53,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 2,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 45,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 63,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 72,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 65,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 62,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 68,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 64,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 50,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 3,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 51,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 71,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 81,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 78,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 69,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 63,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 64,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 64,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 51,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 4,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 45,
                    "time": "9a",
                    "title": "Usually not too busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 65,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 78,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 13,
                    "percentage": 77,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 14,
                    "percentage": 71,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 66,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 67,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 69,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 18,
                    "percentage": 65,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 19,
                    "percentage": 51,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 5,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 54,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 76,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 12,
                    "percentage": 87,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 85,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 77,
                    "time": "12p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 15,
                    "percentage": 72,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 16,
                    "percentage": 74,
                    "time": "3p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 17,
                    "percentage": 81,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 18,
                    "percentage": 84,
                    "time": "6p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 19,
                    "percentage": 76,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              },
              {
                "day": 6,
                "popular_times": [
                  {
                    "hour": 6,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 7,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 8,
                    "percentage": 0,
                    "time": "6a",
                    "title": ""
                  },
                  {
                    "hour": 9,
                    "percentage": 0,
                    "time": "9a",
                    "title": ""
                  },
                  {
                    "hour": 10,
                    "percentage": 52,
                    "time": "9a",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 11,
                    "percentage": 80,
                    "time": "9a",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 12,
                    "percentage": 98,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 13,
                    "percentage": 100,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 14,
                    "percentage": 92,
                    "time": "12p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 15,
                    "percentage": 87,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 16,
                    "percentage": 90,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 17,
                    "percentage": 94,
                    "time": "3p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 18,
                    "percentage": 91,
                    "time": "6p",
                    "title": "Usually as busy as it gets"
                  },
                  {
                    "hour": 19,
                    "percentage": 73,
                    "time": "6p",
                    "title": "Usually a little busy"
                  },
                  {
                    "hour": 20,
                    "percentage": 0,
                    "time": "6p",
                    "title": ""
                  },
                  {
                    "hour": 21,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 22,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  },
                  {
                    "hour": 23,
                    "percentage": 0,
                    "time": "9p",
                    "title": ""
                  }
                ]
              }
            ],
            "postal_code": "28014",
            "posts": null,
            "query": "Museo del Prado, Madrid",
            "range": null,
            "rating": 4.7,
            "reservation_links": null,
            "reviews": 122295,
            "reviews_id": "1194844223299225690",
            "reviews_link": "https://search.google.com/local/reviews?placeid=ChIJ7aLYZp0oQg0RWoitk33wlBA&q=Museo+del+Prado,+Madrid&authuser=0&hl=en&gl=US",
            "reviews_per_score": {
              "1": 1499,
              "2": 905,
              "3": 3750,
              "4": 17156,
              "5": 98985
            },
            "reviews_tags": [
              "picture gallery",
              "francisco goya",
              "velazquez",
              "hieronymus bosch",
              "el greco",
              "las meninas",
              "titian",
              "the garden of earthly delights",
              "black paintings",
              "1819"
            ],
            "site": "https://www.museodelprado.es/",
            "state": "Madrid",
            "street": "C. de Ruiz de Alarcón, 23",
            "street_view": "https://lh5.googleusercontent.com/p/AF1QipMwTmMSSypzznj-yuM-OQLw8GKOvx75vvGiTpZ0=w1600-h1000-k-no",
            "subtypes": "Art museum, Heritage museum, Museum, Tourist attraction",
            "time_zone": "Europe/Madrid",
            "type": "Art museum",
            "typical_time_spent": "People typically spend up to 3 hours here",
            "us_state": null,
            "verified": true,
            "working_hours": {
              "Friday": "10AM-8PM",
              "Monday": "10AM-8PM",
              "Saturday": "10AM-8PM",
              "Sunday": "10AM-7PM",
              "Thursday": "10AM-8PM",
              "Tuesday": "10AM-8PM",
              "Wednesday": "10AM-8PM"
            },
            "working_hours_old_format": "Monday:10AM-8PM|Tuesday:10AM-8PM|Wednesday:10AM-8PM|Thursday:10AM-8PM|Friday:10AM-8PM|Saturday:10AM-8PM|Sunday:10AM-7PM"
          }
        ]
      ]
    },
    "questionWidget": {
      "submitted": true,
      "expanded": false
    },
    "locationConfirmationWidget": {
      "submitted": true,
      "expanded": false
    },
    "majorUpdateConfirmationWidgets": [],
    "isMidReportGenerated": true,
    "isChatViewCompressed": true,
    "addedCitiesImages": [
      {
        "url": "https://t3.ftcdn.net/jpg/01/17/59/80/240_F_117598013_o6oihHtCfzNi5fOvUjrFmS83pKHMISmx.jpg",
        "title": "Malaga, Spain Old Town Skyline",
        "placeName": "Malaga, Spain"
      },
      {
        "url": "https://t4.ftcdn.net/jpg/02/29/52/63/240_F_229526382_6ZoOxMUlkB4Vniw6xkURNHPzFGOH6SH3.jpg",
        "title": "Madrid city skyline gran via street twilight , Spain",
        "placeName": "Madrid, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/01/38/36/20/240_F_138362042_sqhEyI1IAMo97FqT1U0D6DPHxhjIopxX.jpg",
        "title": "Cityscape of Malaga city. Bull Ring, Plaza la Malagueta, Andalusia, Spain",
        "placeName": "Malaga, Spain"
      },
      {
        "url": "https://t4.ftcdn.net/jpg/01/03/18/15/240_F_103181516_8GJmbgeG3mkTrPfXQ15g7nc2mHYhIXaG.jpg",
        "title": "Madrid, Spain cityscape at Calle de Alcala and Gran Via.",
        "placeName": "Madrid, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/04/61/88/44/240_F_461884428_7esm5kuQ4WfQLuh1HG9w7RVHvbPThTYw.jpg",
        "title": "Aerial panoramic view of Puerto Banus marina with luxury yachts, Marbella, Spain",
        "placeName": "Marbella, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/02/57/06/78/240_F_257067850_JWnLNo00M2XPZkJDuM3dkA1PYO6TaLVj.jpg",
        "title": "View of Park Geull at sunset",
        "placeName": "Barcelona, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/01/12/60/42/240_F_112604266_DDKRYfj8EXC2SoFrB70Yam1N2S07EymI.jpg",
        "title": "Spain Square (Plaza de Espana). Seville, Spain.",
        "placeName": "Seville, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/01/15/13/44/240_F_115134473_7dArpywLvq8sFEsyLEhK197RQchgJy0H.jpg",
        "title": "Plaza de Espana (Spain square) in Seville, Andalusia",
        "placeName": "Seville, Spain"
      },
      {
        "url": "https://t4.ftcdn.net/jpg/03/11/13/41/240_F_311134116_5nQvEcDX8e10K40ipHqcFm9zXgoA7TI2.jpg",
        "title": "BARCELONA, SPAIN - SEPTEMBER 15,2015 :  Sagrada Familia  in  Barcelona. Sagrada  - the most known the buildings created by Antoni Gaudi.",
        "placeName": "Barcelona, Spain"
      },
      {
        "url": "https://t4.ftcdn.net/jpg/00/00/51/05/240_F_510533_l6pqVWBLVfgCM1OYDEtYYiEtP4wx70.jpg",
        "title": "marbella arch in san pedro in spain",
        "placeName": "Marbella, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/04/15/61/06/240_F_415610620_fFvrstpdhv7dLGI8V9Lch0XtWbPIz8dV.jpg",
        "title": "Madrid Spain, sunrise city skyline at Cibeles Fountain Town Square",
        "placeName": "Madrid, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/01/14/53/42/240_F_114534203_jH4MIeGCXpJxLIFNDKdIyhOmr0ysAawk.jpg",
        "title": "Malaga in Andalusia, Spain",
        "placeName": "Malaga, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/03/05/31/08/240_F_305310830_P0qC5nCoWlcKcmy1H8YhYFevJmGTbyvq.jpg",
        "title": "Scenes from Seville Spain",
        "placeName": "Seville, Spain"
      },
      {
        "url": "https://t4.ftcdn.net/jpg/00/47/26/25/240_F_47262519_JBji4zbizuOybaNXaBFAlYyawW3zx066.jpg",
        "title": "Park Guell in Barcelona, Spain",
        "placeName": "Barcelona, Spain"
      },
      {
        "url": "https://t3.ftcdn.net/jpg/02/24/25/66/240_F_224256612_1RKuEPIyjjUBtHU4OhzWbnf0IiWiZYhR.jpg",
        "title": "Puerto Banus Marina",
        "placeName": "Marbella, Spain"
      }
    ],
    "isItineraryLoaded": true,
    "inspirationDrawerInfo": {
      "show": false,
      "source": null
    },
    "isHeaderAnimationCompleted": true,
    "initialAnimationCompleted": true,
    "showTripControls": false,
    "attachmentCardData": {}
  },
  "tripTransitions": {
    "maxPrice": -1,
    "transitionReqs": [
      "flight",
      "car",
      "car",
      "flight",
      "car",
      "flight"
    ],
    "travelSets": [
      [
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "toAirport": [
              "BCN"
            ],
            "dateOfJourney": "2024-09-07",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-11",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-14",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "AGP"
            ],
            "toAirport": [
              "MAD"
            ],
            "dateOfJourney": "2024-09-17",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-20",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SVQ"
            ],
            "toAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "dateOfJourney": "2024-09-23",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        }
      ],
      [
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "toAirport": [
              "BCN"
            ],
            "dateOfJourney": "2024-09-14",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-18",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-21",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "AGP"
            ],
            "toAirport": [
              "MAD"
            ],
            "dateOfJourney": "2024-09-24",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-27",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SVQ"
            ],
            "toAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "dateOfJourney": "2024-09-30",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        }
      ],
      [
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "toAirport": [
              "BCN"
            ],
            "dateOfJourney": "2024-09-21",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-25",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-09-28",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "AGP"
            ],
            "toAirport": [
              "MAD"
            ],
            "dateOfJourney": "2024-10-01",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-10-04",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SVQ"
            ],
            "toAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "dateOfJourney": "2024-10-07",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        }
      ],
      [
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "toAirport": [
              "BCN"
            ],
            "dateOfJourney": "2024-09-28",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-10-02",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-10-05",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "AGP"
            ],
            "toAirport": [
              "MAD"
            ],
            "dateOfJourney": "2024-10-08",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        },
        {
          "type": "CAR",
          "flightDetails": null,
          "nonFlightDetails": {
            "dateOfJourney": "2024-10-11",
            "timeOfDeparture": null,
            "dateOfArrival": null,
            "timeOfArrival": null
          }
        },
        {
          "type": "FLIGHT",
          "flightDetails": {
            "fromAirport": [
              "SVQ"
            ],
            "toAirport": [
              "SFO",
              "OAK",
              "SJC"
            ],
            "dateOfJourney": "2024-10-14",
            "maxStops": -1,
            "maxDuration": -1
          },
          "nonFlightDetails": null
        }
      ]
    ],
    "tripType": "MULTI_CITY",
    "tripClass": "ECONOMY"
  },
  "tripLocationDetails": {
    "numTravellers": 4,
    "budget": "$$",
    "numPlaces": 5,
    "startLocation": "San Francisco",
    "places": [
      "Barcelona, Spain",
      "Marbella, Spain",
      "Malaga, Spain",
      "Madrid, Spain",
      "Seville, Spain"
    ],
    "endLocation": "San Francisco",
    "hotelFilters": [
      null,
      {
        "NUM_ROOMS": 2,
        "MIN_STAR_RATING": -1,
        "MAX_PRICE": -1,
        "AMENITIES": [],
        "LOCALITY": "N/A",
        "COORDINATES_LABEL": "Marbella, Spain",
        "LATITUDE": 36.5103,
        "LONGITUDE": -4.8824
      },
      {
        "NUM_ROOMS": 2,
        "MIN_STAR_RATING": -1,
        "MAX_PRICE": -1,
        "AMENITIES": [],
        "LOCALITY": "N/A",
        "COORDINATES_LABEL": "Malaga, Spain",
        "LATITUDE": 36.7213,
        "LONGITUDE": -4.4215
      },
      {
        "NUM_ROOMS": 1,
        "MIN_STAR_RATING": -1,
        "MAX_PRICE": -1,
        "AMENITIES": [],
        "LOCALITY": "N/A",
        "COORDINATES_LABEL": "Madrid, Spain",
        "LATITUDE": 40.4168,
        "LONGITUDE": -3.7038
      },
      {
        "NUM_ROOMS": 1,
        "MIN_STAR_RATING": -1,
        "MAX_PRICE": -1,
        "AMENITIES": [],
        "LOCALITY": "N/A",
        "COORDINATES_LABEL": "Seville, Spain",
        "LATITUDE": 37.3891,
        "LONGITUDE": -5.9845
      }
    ],
    "hotelReqs": [
      false,
      true,
      true,
      true,
      true
    ],
    "hotelSets": [
      [
        null,
        {
          "CHECK_IN_DATE": "2024-09-25",
          "CHECK_OUT_DATE": "2024-09-28"
        },
        {
          "CHECK_IN_DATE": "2024-09-28",
          "CHECK_OUT_DATE": "2024-10-01"
        },
        {
          "CHECK_IN_DATE": "2024-10-01",
          "CHECK_OUT_DATE": "2024-10-04"
        },
        {
          "CHECK_IN_DATE": "2024-10-04",
          "CHECK_OUT_DATE": "2024-10-07"
        }
      ]
    ]
  },
  "profile": {
    "_initialized": false
  }
}

export default directOverviewState;
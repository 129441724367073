import React, { useState } from 'react';
import RightIcon from '@commons/icons/RightIcon';
import LeftIcon from '@commons/icons/LeftIcon';
import { motion } from 'framer-motion';
import styles from './styles.module.css';
import Card from './Card';

const SLIDES_TO_SHOW = 4;

function Slider({
	cards = [],
	setItems = () => {},
	setAlternatives = () => {},
	places = [],
}) {
	const [currentIndex, setCurrentIndex] = useState(0);

	const nextSlide = () => {
		if (currentIndex < cards.length - SLIDES_TO_SHOW) {
			setCurrentIndex(currentIndex + 1);
		}
	};

	const prevSlide = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	};

	return (
		<div className={styles.slider}>
			{currentIndex ? (
				<button
					className={`${styles.arrow} ${styles.prev}`}
					onClick={prevSlide}
					disabled={currentIndex === 0}
					type="button"
					aria-label="Previous slide"
				>
					<LeftIcon />
				</button>
			) : null}

			<div className={styles.sliderWrapper}>
				{cards
					.slice(currentIndex, currentIndex + SLIDES_TO_SHOW)
					.map((details, index) => (
						<motion.div
							key={index}
							className={styles.slide}
							style={{
								width:
									!currentIndex && cards.length > SLIDES_TO_SHOW
										? '26%'
										: '25%',
							}}
							initial={{ opacity: 0, x: 100 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.5 }}
						>
							<div style={{ padding: '4px' }}>
								<Card
									details={details}
									isAddLocationCard
									setItems={setItems}
									setAlternatives={setAlternatives}
									places={places}
								/>
							</div>
						</motion.div>
					))}
			</div>

			{currentIndex < cards.length - SLIDES_TO_SHOW ? (
				<button
					className={`${styles.arrow} ${styles.next}`}
					onClick={nextSlide}
					disabled={currentIndex >= cards.length - SLIDES_TO_SHOW}
					type="button"
					aria-label="Next slide"
				>
					<RightIcon />
				</button>
			) : null}
		</div>
	);
}

export default Slider;

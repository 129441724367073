import styles from './styles.module.css';

function CardsLoadingComponent({ width, height, length = 1 }) {
	return (
		<div className="flex gap-3 overflow-auto">
			{[...Array(length)].map((number) => {
				return (
					<div
						key={number}
						className={styles.shimmer_container}
						style={{
							width: width || '340px',
							height: height || '102px',
						}}
					/>
				);
			})}
		</div>
	);
}

export default CardsLoadingComponent;

import TabComponent from '@commons/TabComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { InspirationDrawerSource } from '@helpers/Enums';
import axios from 'axios';
import isEmpty from '@utils/isEmpty';
import CardsLoadingComponent from '../../components/SearchResultsV1/OverallResults/CardsLoadingComponent';
import InspirationThumbnailHorizontalSlider from './InspirationThumbnailHorizontalSlider';
import { discoverCuratedData } from './discoverCurated';

const ContentUrlsLoadingState = {
	INIT: 'INIT',
	LOADING: 'LOADING',
	LOADED: 'LOADED',
	ERROR: 'ERROR',
};

const convertToObjectOfLists = (data) => {
	const result = {};

	for (const item in data) {
		const currentObject = data[item];
		result[item] = [];
		for (const key in currentObject) {
			result[item].push([key, currentObject[key]]);
		}
	}
	return result;
};

export default function DiscoverInspiration({
	onContinue,
	source,
	discoverInspirationState = {},
	onDiscoverInspirationStateChange = () => {},
}) {
	const dispatch = useDispatch();
	const discoverInspirationUrls =
		useSelector((state) => state.ui_states.discoverInspirationUrls) || {};

	const curatedDataWithAllTab = useMemo(() => {
		const allTabData = {};
		const data = convertToObjectOfLists(discoverCuratedData);
		for (let i = 0; i < 6; i += 1) {
			for (const tabName in data) {
				const key = data[tabName][i][0];
				const value = data[tabName][i][1];
				allTabData[key] = value;
			}
		}
		return {
			All: allTabData,
			...discoverCuratedData,
		};
	}, [discoverCuratedData]);

	const TAB_NAMES = Object.keys(curatedDataWithAllTab);
	const contentUrlsByTabIndex = [];
	const initialLoadingStates = [];
	const initialTabData = {};

	for (let i = 0; i < TAB_NAMES.length; i += 1) {
		if (source === InspirationDrawerSource.LANDING_PAGE) {
			const tabName = TAB_NAMES[i];
			contentUrlsByTabIndex.push(Object.keys(curatedDataWithAllTab[tabName]));
			initialLoadingStates.push(ContentUrlsLoadingState.LOADED);
			const contentFetched = Object.keys(curatedDataWithAllTab[tabName]).reduce(
				(acc, key) => {
					acc[key] = true;
					return acc;
				},
				{},
			);
			initialTabData[i] = {
				content: { ...curatedDataWithAllTab[tabName] },
				contentFetched,
			};
		} else {
			contentUrlsByTabIndex.push([]);
			initialLoadingStates.push(ContentUrlsLoadingState.INIT);
		}
	}

	const [activeTab, setActiveTab] = useState(
		discoverInspirationState.activeTab || 0,
	);

	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const { places = [] } = tripLocationDetails;

	const isCurrentViewLandingPage =
		source === InspirationDrawerSource.LANDING_PAGE;

	let tabNames = TAB_NAMES;
	if (!isCurrentViewLandingPage) {
		tabNames = places.map((place) => place.split(',')[0]);
	}

	const processedPlaces = new Set();

	useEffect(() => {
		const fetchContentUrls = () => {
			const fetchUrls = tabNames.map(async (placeName) => {
				if (
					isEmpty(discoverInspirationUrls[placeName]) &&
					!processedPlaces.has(placeName)
				) {
					processedPlaces.add(placeName);

					try {
						const response = await axios.get(
							`${process.env.REACT_APP_BACKEND_URL}/api/discover_inspiration`,
							{
								params: { place: placeName },
							},
						);

						dispatch({
							type: 'UPDATE_DISCOVER_INSPIRATION_URLS',
							payload: {
								placeName,
								data: response?.data || [],
							},
						});
					} catch (error) {
						dispatch({
							type: 'UPDATE_DISCOVER_INSPIRATION_URLS',
							payload: {
								placeName,
								data: [],
							},
						});
					}
				}
			});

			if (fetchUrls.length > 0) {
				Promise.all(fetchUrls).finally(() => {
					console.log('All fetch operations are complete.');
				});
			}
		};

		if (!isCurrentViewLandingPage) {
			fetchContentUrls();
		}
	}, []);

	useEffect(() => {
		onDiscoverInspirationStateChange({
			activeTab,
		});
	}, [activeTab]);

	const contentUrls = isCurrentViewLandingPage
		? contentUrlsByTabIndex[activeTab]
		: discoverInspirationUrls[tabNames[activeTab]];

	return (
		<div className="flex flex-col space-y-4">
			<TabComponent
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				tabNames={tabNames}
			/>
			{!isEmpty(contentUrls) && (
				<InspirationThumbnailHorizontalSlider
					tabIndex={activeTab}
					contentUrls={contentUrls}
					onContinue={onContinue}
					initialTabData={initialTabData}
					isCurrentViewLandingPage={isCurrentViewLandingPage}
					key={`inspiration-thumbnail-horizontal-slider-${activeTab}`}
				/>
			)}
			{!contentUrls && (
				<div className="flex flex-row space-x-4">
					<CardsLoadingComponent width="180px" height="320px" />
				</div>
			)}
			{contentUrls && isEmpty(contentUrls) && (
				<div className="flex flex-row space-x-4">
					There was an error loading the content. Please try again later.
				</div>
			)}
		</div>
	);
}

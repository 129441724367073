import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ImageSlider from '@commons/ImageSlider';
import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { formatDates } from '@helpers/DateUtils';
import CheckCircleselected from '@commons/icons/CheckCircleSelected';
import CheckCircleDefault from '@commons/icons/CheckCircleDefault';
import { useDispatch, useSelector } from 'react-redux';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WifiIcon from '@mui/icons-material/Wifi';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { CgSearchLoading, CgUnavailable } from 'react-icons/cg';
import { TripUpdateType } from '@helpers/Enums';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useState } from 'react';
import isEmpty from '@utils/isEmpty';
import Accordion from '../CustomAccordion/Accordion';
import useGetHotelBookingLink from '../../../../hooks/useGetHotelBookingLink';
import styles from './styles.module.css';

const AMENITY_KEYS = [
	'Air-conditioned',
	'Free Wi-Fi',
	'Free breakfast',
	'Parking',
	'Fitness center',
];

const AMENITY_MAPPING = {
	'Air-conditioned': { icon: AcUnitIcon, name: 'Air Conditioned' },
	'Free Wi-Fi': { icon: WifiIcon, name: 'Free Wifi' },
	'Free breakfast': { icon: FreeBreakfastIcon, name: 'Free breakfast' },
	Parking: { icon: LocalParkingIcon, name: 'Free Parking' },
	'Fitness center': { icon: FitnessCenterIcon, name: 'Fitness center' },
};

function ImagesComponent({
	isImagesEmpty = false,
	isImagesLoaded = false,
	hotelImages,
}) {
	if (!isImagesLoaded) {
		return (
			<div
				className="flex items-center justify-center rounded-xl"
				style={{
					backgroundColor: 'rgba(48, 19, 69, 0.04)',
					width: '30%',
					flexBasis: '30%',
					height: '184px',
				}}
			>
				<CgSearchLoading
					fontSize="large"
					className="self-center h-12 w-12"
					applyActualWidth
				/>
			</div>
		);
	}

	if (isImagesEmpty) {
		return (
			<div
				className="flex items-center justify-center flex-col gap-4 rounded-xl"
				style={{
					backgroundColor: 'rgba(48, 19, 69, 0.04)',
					width: '30%',
					flexBasis: '30%',
					height: '184px',
				}}
			>
				<CgUnavailable
					fontSize="large"
					className="self-center h-12 w-12"
					applyActualWidth
				/>

				<div>No Images found</div>
			</div>
		);
	}

	return (
		<ImageSlider
			data={hotelImages}
			width="30%"
			flexBasis="30%"
			height="184px"
		/>
	);
}

function HotelCard({
	hotelDateSetDetails = {},
	isHotelSelected = false,
	numTravellers = 1,
	googleHotelData = {},
	hotelImages = [],
	locationIndex,
	hotelFilterDetails = {},
	hotelObject = {},
	isImagesEmpty = false,
	isImagesLoaded = false,
	isFinalLoadingCompletedForReviews = false,
	placeName = '',
}) {
	const dispatch = useDispatch();
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const selectedHotels = useSelector((state) => state.ui_states.selectedHotels);
	const hotelReviewSummary =
		useSelector((state) => state.ui_states.hotelReviewSummary) || {};

	const [isReviewsAccordionOpen, setIsReviewsAccordionOpen] = useState(false);

	const { hotel, offers = [] } = hotelObject;
	const { name = '' } = hotel;
	const { COORDINATES_LABEL } = hotelFilterDetails;
	const { description = '', rating, about = {} } = googleHotelData;
	const { Amenities = {}, Other = {} } = about;
	const allAmenities = { ...Amenities, ...Other };

	const availableAmenities = Object.entries(allAmenities).reduce(
		(acc, [key, value]) => {
			if (acc.length > 2) {
				return acc;
			}

			if (AMENITY_KEYS.includes(key) && value) {
				return [...acc, key];
			}

			return acc;
		},
		[],
	);

	const { CHECK_IN_DATE = '', CHECK_OUT_DATE = '' } = hotelDateSetDetails;

	const price = Number(
		offers[0].price?.variations?.average?.base?.split('.')?.[0] ||
			offers[0].price?.variations?.average?.total?.split('.')?.[0] ||
			offers[0].price?.total?.split('.')?.[0],
	);

	const { handleBookHotel, loading } = useGetHotelBookingLink();

	const handleHotelSelection = () => {
		const updatedSelectedHotels = selectedHotels.map((hotelDetails, index) => {
			if (index === locationIndex) {
				return hotelObject;
			}

			return hotelDetails;
		});

		sendMessageEvent({
			event: GoogleEventTypes.HOTELS_VIEW_CHANGE_SELECTED_HOTEL_OF_LOCATION,
			chat_session_id: chatSessionId,
		});
		dispatch({
			type: 'UPDATE_SELECTED_HOTELS',
			payload: updatedSelectedHotels,
		});

		dispatch({
			type: 'CHECKPOINT',
			payload: TripUpdateType.HOTEL_SELECTION_CHANGED_USER,
		});
	};

	const IconToShow = isHotelSelected ? CheckCircleselected : CheckCircleDefault;

	const isReviewSummaryAvailable =
		isFinalLoadingCompletedForReviews && hotelReviewSummary[placeName];

	return (
		<div
			className="bg-white flex w-full justify-between rounded-2xl hover:cursor-pointer"
			style={{
				border: isHotelSelected ? '2px solid #AD95EF' : '2px solid #F0ECFC',
				padding: '16px',
				color: '#36174D',
			}}
		>
			<ImagesComponent
				isImagesEmpty={isImagesEmpty}
				hotelImages={hotelImages}
				isImagesLoaded={isImagesLoaded}
			/>

			<div
				className="flex justify-between"
				style={{
					flexBasis: '70%',
					width: '70%',
					height: '184px',
					overflow: 'auto',
				}}
			>
				<div
					className="flex flex-col gap-3"
					style={{
						padding: isReviewsAccordionOpen ? '0 0 0 16px' : '0 12px',
						width: isReviewsAccordionOpen ? '100%' : 'calc(100% - 188px)',
					}}
				>
					<div className="flex items-center justify-between">
						<div
							className="mr-4 font-semibold whitespace-nowrap text-ellipsis overflow-hidden"
							style={{
								fontSize: '20px',
								color: '#7750E5',
								maxWidth: '300px',
							}}
						>
							{name}
						</div>

						{rating ? (
							<div className="flex items-center font-semibold">
								<StarIcon sx={{ color: '#F4C01E' }} style={{ fontSize: 16 }} />
								<div
									style={{
										fontSize: '10px',
										marginBottom: '-1px',
										marginLeft: '6px',
									}}
								>
									{rating}
								</div>
							</div>
						) : null}
					</div>

					{!isReviewSummaryAvailable ? (
						<div
							style={{ color: '#DC1F90', marginTop: '-4px' }}
							className="text-sm flex items-center gap-1"
						>
							<LocationOnOutlinedIcon style={{ fontSize: '16' }} />
							{COORDINATES_LABEL.split(',')[0] ?? 'Las Vegas'}
						</div>
					) : null}

					{!isReviewsAccordionOpen && !isEmpty(availableAmenities) ? (
						<div
							className="flex gap-4"
							style={{ flexWrap: 'wrap', color: '#301345', fontSize: '13px' }}
						>
							{availableAmenities.map((value) => {
								const { icon: ActiveIcon, name: AmenityName } =
									AMENITY_MAPPING[value] || {};

								return (
									<div
										key={value}
										style={{
											width: 'fit-content',
											display: 'flex',
											gap: '6px',
											alignItems: 'center',
										}}
									>
										{ActiveIcon ? (
											<ActiveIcon
												style={{ fontSize: '15', color: '#231F20' }}
											/>
										) : null}
										{AmenityName}
									</div>
								);
							})}
						</div>
					) : null}

					{!isReviewSummaryAvailable ? (
						<div
							style={{
								maxWidth: '400px',
							}}
							className="text-sm font-normal"
						>
							{description || 'No description found'}
						</div>
					) : null}

					{!isReviewsAccordionOpen ? (
						<div className="flex items-center gap-1 text-sm">
							<CalendarMonthOutlinedIcon style={{ fontSize: '16' }} />
							{formatDates(CHECK_IN_DATE, CHECK_OUT_DATE)}
						</div>
					) : null}

					{isReviewSummaryAvailable ? (
						<Accordion
							hotelReviewSummary={hotelReviewSummary[placeName]}
							isReviewsAccordionOpen={isReviewsAccordionOpen}
							setIsReviewsAccordionOpen={setIsReviewsAccordionOpen}
						/>
					) : null}
				</div>

				{!isReviewsAccordionOpen ? (
					<div
						className="flex flex-col items-center gap-3 py-4"
						style={{
							borderLeft: '2px solid #F0ECFC',
							paddingLeft: '20px',
							width: '184px',
						}}
					>
						<div style={{ color: '#301345', fontSize: '12px' }}>
							<span
								style={{ fontSize: '24px', marginRight: '2px' }}
							>{`$${price}/`}</span>
							night
						</div>

						<button
							id="flight_option_select"
							className={`${styles.select_button} ${isHotelSelected && styles.selected}`}
							onClick={handleHotelSelection}
							type="button"
							style={{ display: 'flex' }}
						>
							<div
								className="flex items-center gap-1"
								style={{ margin: 'auto' }}
							>
								{isHotelSelected ? 'Selected' : 'Select'}
								<IconToShow />
							</div>
						</button>

						<button
							id="flight_option_select"
							className={styles.book_now_button}
							onClick={() => {
								sendMessageEvent({
									event: GoogleEventTypes.HOTELS_VIEW_BOOK_HOTEL_OF_LOCATION,
									chat_session_id: chatSessionId,
								});
								handleBookHotel({
									name,
									hotelDateSetDetails,
									hotelFilterDetails,
									numTravellers,
								});
							}}
							type="button"
						>
							{loading ? (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										margin: 'auto',
									}}
								>
									<CircularProgress size={20} color="inherit" />
									<span style={{ marginLeft: '8px' }}>Loading...</span>
								</Box>
							) : (
								<div className="m-auto">Book now</div>
							)}
						</button>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default HotelCard;

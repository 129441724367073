import { Component } from 'react';

function move(array, oldIndex, newIndex) {
	let adjustedIndex = newIndex;

	if (newIndex >= array.length) {
		adjustedIndex = array.length - 1;
	}

	const newArray = [...array]; // Make a copy of the array to ensure immutability
	newArray.splice(adjustedIndex, 0, newArray.splice(oldIndex, 1)[0]);

	return newArray;
}

function moveElement(array, index, offset) {
	const newIndex = index + offset;

	return move(array, index, newIndex);
}

export class GridProvider extends Component {
	render() {
		const { items, children } = this.props;
		const moveItem = (sourceId, destinationId) => {
			const sourceIndex = items.findIndex((item) => item.id === sourceId);
			const destinationIndex = items.findIndex(
				(item) => item.id === destinationId,
			);

			// If source/destination is unknown, do nothing.
			if (sourceIndex === -1 || destinationIndex === -1) {
				return;
			}

			const offset = destinationIndex - sourceIndex;

			// Call the move function and pass it back to the parent
			const updatedItems = moveElement(items, sourceIndex, offset);
			this.props.onMoveItem(updatedItems);
		};

		return children({ moveItem });
	}
}

function TrainIcon({ width, height }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '22'}
			height={height || '22'}
			viewBox="0 0 25 24"
			fill="none"
		>
			<path
				d="M18.8499 5.235V16.6125C18.8499 17.385 18.1899 18.0075 17.3799 18.0075H7.95242C7.14242 18.0075 6.48242 17.385 6.48242 16.6125V5.235C6.48242 3.9975 9.24994 3 12.6624 3C16.0749 3 18.8424 3.9975 18.8424 5.235H18.8499Z"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.8499 6.53906H6.48242V11.8866H18.8499V6.53906Z"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6699 11.768V6.66797"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.06055 21.0003L8.89053 18.0078"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.2802 21.0003L16.4502 18.0078"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.80957 19.7617H17.3646"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.35438 15.8474C9.83487 15.8474 10.2244 15.4579 10.2244 14.9774C10.2244 14.4969 9.83487 14.1074 9.35438 14.1074C8.87389 14.1074 8.48438 14.4969 8.48438 14.9774C8.48438 15.4579 8.87389 15.8474 9.35438 15.8474Z"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.9843 15.8474C16.4647 15.8474 16.8543 15.4579 16.8543 14.9774C16.8543 14.4969 16.4647 14.1074 15.9843 14.1074C15.5038 14.1074 15.1143 14.4969 15.1143 14.9774C15.1143 15.4579 15.5038 15.8474 15.9843 15.8474Z"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.6504 4.67969H13.6829"
				stroke="#301345"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
export default TrainIcon;

function AiRialSmallIcon({ width }) {
	return (
		<div style={{ margin: 'auto', width: width || '32px' }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width || '32'}
				height={width || '32'}
				viewBox="0 0 32 32"
				fill="none"
			>
				<g clipPath="url(#clip0_1471_38539)">
					<path
						d="M31 15.975L22.575 20.5625C21.7375 21.025 21.05 21.7125 20.5875 22.55L16.025 31L11.4375 22.575C10.975 21.7375 10.2875 21.05 9.45 20.5875L1 16.025L9.425 11.4375C10.2625 10.975 10.95 10.2875 11.4125 9.45L15.975 1L20.5625 9.425C21.025 10.2625 21.7125 10.95 22.55 11.4125L30.9875 15.975H31Z"
						fill="#7750E5"
						stroke="#7750E5"
						strokeWidth="1.875"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M30 4.50073L28.0316 5.57237C27.8348 5.68172 27.6708 5.84575 27.5724 6.03164L26.5117 7.99995L25.44 6.03164C25.3307 5.83481 25.1667 5.67078 24.9808 5.57237L23.0125 4.51167L24.9808 3.44003C25.1776 3.33068 25.3416 3.16666 25.44 2.98076L26.5007 1.01245L27.5724 2.98076C27.6817 3.17759 27.8348 3.34162 28.0316 3.44003L30 4.50073Z"
						fill="#7750E5"
						stroke="#7750E5"
						strokeWidth="1.875"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M27.5 24.8728L26.7254 25.2946C26.6479 25.3376 26.5833 25.4022 26.5446 25.4754L26.1272 26.25L25.7054 25.4754C25.6624 25.3979 25.5978 25.3333 25.5246 25.2946L24.75 24.8772L25.5246 24.4554C25.6021 24.4124 25.6667 24.3478 25.7054 24.2746L26.1228 23.5L26.5446 24.2746C26.5876 24.3521 26.6479 24.4167 26.7254 24.4554L27.5 24.8728Z"
						fill="#7750E5"
						stroke="#7750E5"
						strokeWidth="0.625"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_1471_38539">
						<rect
							width="31.875"
							height="31.875"
							fill="white"
							transform="translate(0.0625 0.0625)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
}

export default AiRialSmallIcon;

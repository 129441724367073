function TabComponent({ activeTab, setActiveTab, tabNames = [] }) {
	const showTab = (index) => {
		setActiveTab(index);
	};

	if (!tabNames.length) {
		return null;
	}

	return (
		<div className="flex space-x-2 bg-white rounded-lg overflow-auto">
			{tabNames.map((name, index) => {
				const isActive = activeTab === index;
				return (
					<div
						key={`tabComponent${index}`}
						className={`${isActive ? 'text-white bg-primary-600' : 'border-solid border border-primary-100'} rounded-3xl cursor-pointer text-sm font-medium py-2 px-3 flex-shrink-0`}
						onClick={() => showTab(index)}
					>
						<div className="flex">{name}</div>
					</div>
				);
			})}
		</div>
	);
}

export default TabComponent;

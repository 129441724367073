import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const UNAUTHENTICATED_PATHS = ['/login', '/signup', '/signup-form', '/'];

const useGetAuthorizationChecked = () => {
	const { _initialized, ...profile } =
		useSelector((state) => state.profile) || {};

	const dispatch = useDispatch();
	const location = useLocation();

	const navigate = useNavigate();

	const [sessionInitialized, setSessionInitialized] = useState(false);
	const [hasFetchedSession, setHasFetchedSession] = useState(false);

	const { pathname } = location;

	const isProfilePresent = !!profile?.email;

	const isUnauthenticatedPath = UNAUTHENTICATED_PATHS.includes(pathname);

	useEffect(() => {
		if (!_initialized && !hasFetchedSession) {
			const fetchUserSession = async () => {
				try {
					const res = await axios.get('/auth/get_user_session');
					dispatch({
						type: 'UPDATE_PROFILE_REDUCER',
						payload: {
							_initialized: true,
							...res?.data,
						},
					});
				} catch (err) {
					if (!isUnauthenticatedPath) {
						navigate('/login');
					}

					dispatch({
						type: 'UPDATE_PROFILE_REDUCER',
						payload: {
							_initialized: true,
						},
					});
				} finally {
					setHasFetchedSession(true);
				}
			};

			fetchUserSession();
		}
	}, [
		_initialized,
		hasFetchedSession,
		dispatch,
		isUnauthenticatedPath,
		navigate,
	]);

	useEffect(() => {
		if (!sessionInitialized && _initialized) {
			if (isProfilePresent && isUnauthenticatedPath) {
				navigate('/');
			}
			setSessionInitialized(true);
		}
	}, [
		_initialized,
		sessionInitialized,
		isProfilePresent,
		isUnauthenticatedPath,
		navigate,
	]);

	return { sessionInitialized, setSessionInitialized };
};

export default useGetAuthorizationChecked;

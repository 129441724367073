import React, { useEffect, useState } from 'react';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import Collapsible from 'react-collapsible';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { checkLink } from '@utils/LinkUtils';
import CheckCircleselected from '@commons/icons/CheckCircleSelected';
import CheckCircleDefault from '@commons/icons/CheckCircleDefault';
import { IconButton } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

function displayPlayCount(num) {
	if (num < 1000) {
		return num.toString(); // Return the number as is if less than 1000
	}
	if (num < 1000000) {
		return `${(num / 1000).toFixed(1)}K`; // Convert to thousands with one decimal place
	}
	return `${(num / 1000000).toFixed(2)}M`; // Convert to millions with two decimal places
}

function SelectPill({ isSelected, onClick }) {
	return (
		<div
			className="flex gap-1 bg-white rounded-2xl p-2 items-center justify-center border"
			style={{ width: '76px' }}
			onClick={onClick}
		>
			<div className="text-xs">Select</div>
			{isSelected ? (
				<CheckCircleselected width="19.5" height="19.5" />
			) : (
				<CheckCircleDefault width="15" height="15" />
			)}
		</div>
	);
}

function SocialMediaPlayableThumbnailComponent({
	url,
	scrape_results,
	isSelected,
	isExpanded,
	toggleSelected,
	onExpand,
	onCollapse,
}) {
	const [collapsed, setCollapsed] = useState(!isExpanded);
	const [fullyClosed, setFullyClosed] = useState(!isExpanded);

	useEffect(() => {
		setCollapsed(!isExpanded);
		setFullyClosed(!isExpanded);
	}, [isExpanded]);

	const toggleCollapsed = () => {
		if (collapsed) {
			setFullyClosed(false);
			onExpand();
		}
		setCollapsed(!collapsed);
	};
	const linkCheck = checkLink(url);

	const getCollapsedCard = () => {
		const displayUrl =
			scrape_results?.azure_display_url || scrape_results?.display_url;
		const playCount = scrape_results?.everything?.stats?.playCount;
		return (
			<div
				key={`thumb: ${url}`}
				className="flex-none rounded-xl overflow-hidden shadow-2 relative"
				style={{ width: '180px', height: '320px' }}
			>
				<img src={displayUrl} alt="thumbnail" />
				<div className="top-2 right-1 absolute">
					<SelectPill
						isSelected={isSelected}
						onClick={() => toggleSelected(url)}
					/>
				</div>
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<IconButton size="large" onClick={toggleCollapsed}>
						<PlayArrowRoundedIcon fontSize="large" style={{ color: '#fff' }} />
					</IconButton>
				</div>
				<div
					className="flex absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-xs p-1 justify-center align-middle"
					style={{ width: '100%' }}
				>
					<PlayArrowRoundedIcon fontSize="small" style={{ color: '#fff' }} />
					<div>{playCount ? displayPlayCount(playCount) : ''}</div>
				</div>
			</div>
		);
	};

	const getExpandedTrigger = () => {
		return (
			<div className="flex justify-end cursor-pointer items-center border">
				<SelectPill
					isSelected={isSelected}
					onClick={() => toggleSelected(url)}
				/>
				<IconButton onClick={toggleCollapsed}>
					<ExpandLessIcon fontSize="large" className="flex-none" />
				</IconButton>
			</div>
		);
	};

	return (
		<div>
			<Collapsible
				open={!collapsed}
				trigger={fullyClosed ? getCollapsedCard() : getExpandedTrigger()}
				onClose={() => {
					setFullyClosed(true);
					onCollapse();
				}}
			>
				{!collapsed && (
					<div
						className={`flex justify-center ${fullyClosed ? 'w-0 h-0' : ''}`}
					>
						{url && linkCheck.isInstagram && (
							<InstagramEmbed url={url} key={url} captioned width={328} />
						)}

						{url && linkCheck.isTikTok && (
							<TikTokEmbed url={url} key={url} captioned width={328} />
						)}
					</div>
				)}
			</Collapsible>
		</div>
	);
}

export default SocialMediaPlayableThumbnailComponent;

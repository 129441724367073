import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Button } from '@mui/material';
import { RxCross2 } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
import { handleFindFlights } from '@helpers/FlightsQueryHelper';
import AddIcon from '@mui/icons-material/Add';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import TripOptionsBar from './TripOptionsBar';
import DestinationAutoCompleteTextBox from '../../../../commons/forms/page-components/components/DestinationAutoCompleteTextBox/DestinationAutoCompleteTextBox';
import './styles.css';
import useFlightsQuery from '../../../../hooks/flightsQuery';

export default function FlightForm({
	flightSets,
	flightFormState,
	setFlightFormState,
	textColor,
}) {
	const dispatch = useDispatch();

	const page_step = useSelector((state) => state.ui_states.pageId);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const { maxPrice, tripType } = tripTransitionsDetails;
	const { travelSets, tripClass, numTravellers } = flightFormState;
	const { dayPlansQuery, tripData } = useFlightsQuery();
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);

	const flightIndexMap = [];
	if (travelSets?.length) {
		let ix = 0;
		for (let i = 0; i < travelSets[0].length; i += 1) {
			if (['FLIGHT', 'flight'].includes(travelSets[0][i].type)) {
				flightIndexMap[ix] = i;
				ix += 1;
			}
		}
	}

	const flightQuery = {
		NUM_PASSENGERS: numTravellers,
		MAX_PRICE: maxPrice,
		FLIGHTSETS: flightSets,
		TRIP_TYPE: tripType,
		TRIP_CLASS: tripClass,
	};

	const chat_session_id = '1a1cbf76-6f09-49b4-98c9-eb17db77a3ac';
	const location = useLocation();

	const handleModifyDate = (newDate, innerIndex) => {
		const newTravelSets = travelSets.map((travelSet, travelSetIndex) =>
			travelSetIndex === activeTabIndex
				? travelSet.map((leg, legIndex) =>
						legIndex === innerIndex
							? {
									...leg,
									flightDetails: {
										...leg.flightDetails,
										dateOfJourney: newDate.toISOString().split('T')[0],
									},
								}
							: leg,
					)
				: travelSet,
		);

		setFlightFormState((prevFlightFormState) => ({
			...prevFlightFormState,
			modified: true,
			travelSets: newTravelSets,
		}));
	};

	const handleShowTripControls = () => {
		sendMessageEvent({
			event: GoogleEventTypes.FLIGHTS_VIEW_OPEN_TRIP_CONTROLS_FOR_EDIT,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_SHOW_TRIP_CONTROLS',
			payload: true,
		});
	};

	const handleSearch = () => {
		sendMessageEvent({
			event: GoogleEventTypes.FLIGHTS_VIEW_SEARCH_FOR_FLIGHTS,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'MODIFY_TRAVEL_SETS',
			payload: flightFormState.travelSets,
		});
		dispatch({ type: 'MODIFY_TRIP_CLASS', payload: flightFormState.tripClass });
		dispatch({
			type: 'UPDATE_NUM_TRAVELLERS',
			payload: flightFormState.numTravellers,
		});

		setFlightFormState((prevFlightFormState) => ({
			...prevFlightFormState,
			modified: false,
		}));

		handleFindFlights({
			flightQuery,
			dispatch,
			chat_session_id,
			location,
			dayPlansQuery,
			shouldUpdateFlightsAndDayPlans: true,
			tripData,
			sortOption: flightSortOption,
			gMapsData,
			curActiveTabIndex: activeTabIndex,
		});
	};

	return (
		<div className="flex flex-col mb-4">
			<div className="self-start">
				<TripOptionsBar
					flightFormState={flightFormState}
					setFlightFormState={setFlightFormState}
					textColor={textColor}
				/>
			</div>

			<form
				className="flex flex-col space-y-4 p-4"
				style={{ backgroundColor: '#FAF9FF', borderRadius: '16px' }}
			>
				{flightSets[activeTabIndex].map((flight, innerIndex) => {
					return (
						<div className="flex space-x-2 items-center">
							<div className="flex flex-1 space-x-2 w-1/12">
								<div className="flex-1 w-1/12">
									<DestinationAutoCompleteTextBox
										flightFormState={flightFormState}
										setFlightFormState={setFlightFormState}
										initialTags={flight.FROM_AIRPORT}
										inner_ix={flightIndexMap[innerIndex]}
										textbox_type="fromAirport"
										text="From"
									/>
								</div>

								<div className="flex-1 w-1/12">
									<DestinationAutoCompleteTextBox
										flightFormState={flightFormState}
										setFlightFormState={setFlightFormState}
										initialTags={flight.TO_AIRPORT}
										inner_ix={flightIndexMap[innerIndex]}
										textbox_type="toAirport"
										text="To"
									/>
								</div>
							</div>
							<div
								className="bg-white rounded-lg flex gap-2 items-center"
								style={{
									boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.05)',
									padding: '4px 12px',
								}}
							>
								<div
									style={{
										color: '#92849D',
										fontWeight: 400,
										fontSize: '14px',
									}}
								>
									Departure
								</div>

								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DesktopDatePicker
										value={dayjs(flight.DATE_OF_JOURNEY)}
										onChange={(newValue) =>
											handleModifyDate(newValue, flightIndexMap[innerIndex])
										}
										sx={{
											width: '140px',
											'& .MuiInputBase-input': {
												padding: '8px 0px 8px 8px',
												height: '24px',
											},
											'& .MuiInputAdornment-root': { margin: '0px' },
										}}
									/>
								</LocalizationProvider>
							</div>

							{flightSets[activeTabIndex].length > 1 && (
								<RxCross2
									id="flight_form_remove_multi_city"
									className="text-neutrals-secondary_text h-6 w-6 cursor-pointer hover:text-primary-700 z-30 hover:animate-spin-quarter"
									onClick={handleShowTripControls}
									style={{ color: '#7750E5' }}
								/>
							)}
						</div>
					);
				})}

				<div style={{ display: 'flex', marginTop: '12px' }}>
					<Button
						id="flight_form_add_flight"
						onClick={handleShowTripControls}
						className={
							page_step === 0
								? 'border-blue-500 text-blue-500 hover:bg-blue-50 bg-neutrals-card_background normal-case cursor-pointer'
								: 'border-primary-800 text-primary-800 hover:bg-primary-50 bg-neutrals-card_background normal-case cursor-pointer'
						}
						variant="text"
						style={{ textTransform: 'none' }}
					>
						<div className="flex items-center gap-2">
							<AddIcon />
							Add Flight
						</div>
					</Button>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginTop: '16px',
					}}
				>
					<Button
						id="flight_form_search"
						onClick={handleSearch}
						className="generate-trip-button"
						variant="contained"
						style={{ textTransform: 'unset', padding: '8px 32px' }}
					>
						Search
					</Button>
				</div>
			</form>
		</div>
	);
}

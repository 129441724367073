/* eslint-disable no-inner-declarations */
import { findBestFlight, sortFlights } from '@commons/Flights/FlightsUtils';
import { SortOptions, LoadingState, TripUpdateType } from '@helpers/Enums';
import { GoogleEventTypes, sendMessageEvent } from './GoogleAnalyticsHelper';
import hotelDateSetHelper from './hotelDateSetHelper';
import { handleFindHotels } from './hotelsQueryHelper';
import { fetchDayPlansAndDispatch } from './dayPlansQueryHelper';

// TODO: This function and other places in the codebase should take into account the flight time filters.
// TODO: Other places in the codebase should also take into account the maxDuration.
export function getFlightQueryFromTripTransitions(
	tripTransitions,
	numTravellers,
) {
	const ret = {
		NUM_PASSENGERS: numTravellers,
		MAX_PRICE: tripTransitions.maxPrice,
		FLIGHTSETS: tripTransitions.travelSets.map((travelSet) =>
			travelSet.reduce((acc, travelData) => {
				if (!travelData || !travelData?.flightDetails) {
					return acc;
				}

				const { flightDetails } = travelData;

				return [
					...acc,
					{
						DATE_OF_JOURNEY: flightDetails?.dateOfJourney,
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}, []),
		),
		TRIP_TYPE: tripTransitions.tripType,
		TRIP_CLASS: tripTransitions.tripClass,
	};
	return ret;
}

const flightQueryForNormalCall = ({
	payload,
	sortOption,
	curActiveTabIndex,
	flightQuery,
	dispatch,
}) => {
	let tabsData = payload
		.filter((item) => !!item)
		.map((f, index) => ({
			title: f.dateOptionLabel,
			content: f.itineraries,
			flightset: flightQuery.FLIGHTSETS[index],
			disableDatesWithMessage: null,
		}));

	tabsData = sortFlights(tabsData, sortOption);
	const { tabIndex } = findBestFlight(tabsData);

	const selectedTabIndex =
		curActiveTabIndex !== -1 ? curActiveTabIndex : tabIndex;

	dispatch({
		type: 'UPDATE_ACTIVE_TAB_INDEX',
		payload: selectedTabIndex,
	});
	dispatch({
		type: 'UPDATE_BEST_TAB_INDEX',
		payload: tabIndex,
	});
	dispatch({
		type: 'UPDATE_SELECTED_FLIGHT_BY_DATE_OPTION',
		payload: Array.from(
			{ length: tabsData.length },
			(_, i) => tabsData[i]?.content?.[0] || [],
		),
	});
	dispatch({ type: 'UPDATE_FLIGHT_RESULTS', payload });
	dispatch({
		type: 'UPDATE_LOADING_STATE',
		payload: {
			flightsLoading: LoadingState.LOADED,
		},
	});

	dispatch({
		type: 'UPDATE_SELECTED_FLIGHT',
		payload: tabsData[selectedTabIndex]?.content?.[0] || {},
	});

	return { tabsData, selectedTabIndex };
};

export const handleFindFlights = ({
	flightQuery,
	dispatch,
	chatSessionId,
	location,
	dayPlansQuery,
	shouldUpdateFlightsAndDayPlans,
	tripData = {},
	sortOption = SortOptions.BEST,
	gMapsData = {},
	curActiveTabIndex = -1,
}) => {
	try {
		dispatch({ type: 'UPDATE_FLIGHT_RESULTS', payload: null });

		handleFindFlightsImp({
			flightQuery,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery,
			shouldUpdateFlightsAndDayPlans,
			tripData,
			sortOption,
			gMapsData,
			curActiveTabIndex,
			queryFunction: flightQueryForNormalCall,
		});
	} catch (error) {
		console.log('error', error);
	}
};

export const handleFindFlightsImp = async ({
	flightQuery,
	dispatch,
	chatSessionId,
	location,
	dayPlansQuery,
	shouldUpdateFlightsAndDayPlans,
	tripData = {},
	sortOption = SortOptions.BEST,
	gMapsData = {},
	curActiveTabIndex = -1,
	queryFunction = flightQueryForNormalCall,
	existingFlightResults,
}) => {
	dispatch({ type: 'PAGE_STEP', payload: 1 });
	const backendURL = process.env.REACT_APP_BACKEND_URL;
	dispatch({
		type: 'UPDATE_LOADING_STATE',
		payload: {
			flightsLoading: LoadingState.LOADING,
		},
	});

	try {
		const {
			hotelFilters,
			hotelSortCriterion,
			LOCATIONS = [],
			HOTEL_REQUIREMENT = [],
			NUM_TRAVELLERS = 0,
			travelSets,
		} = tripData || {};

		const queryParams = new URLSearchParams(location.search);
		queryParams.append('flight_search_query', JSON.stringify(flightQuery));
		queryParams.append('stream', false);
		queryParams.append('chat_session_id', chatSessionId);
		let hotelsListPromiseResolved = false;

		const flightsPromise = new Promise((resolve, reject) => {
			fetch(`${backendURL}/api/search_stream?${queryParams.toString()}`)
				.then((flightsResponse) => {
					const reader = flightsResponse.body.getReader();
					let accumulatedChunk = '';
					let payload = [];

					function readChunk() {
						reader
							.read()
							.then(({ done, value }) => {
								if (done) {
									return;
								}

								const chunkText = new TextDecoder().decode(value);
								const startOfResponse = '[{"dateOptionLabel"';
								let remainingChunk = chunkText;

								while (remainingChunk.length > 0) {
									const ix = remainingChunk.indexOf(startOfResponse, 1);

									// Single chunk containing multiple responses
									if (ix > 0) {
										accumulatedChunk += remainingChunk.substring(0, ix);
										remainingChunk = remainingChunk.substring(ix);
									} else {
										accumulatedChunk += remainingChunk;
										remainingChunk = '';
									}

									let parsedJSON = null;
									try {
										parsedJSON = JSON.parse(accumulatedChunk);
										parsedJSON = parsedJSON.FLIGHT_SEARCH_RESULTS;
									} catch (error) {
										// Not a valid json yet, we'll keep waiting for more chunks to arrive
									}

									if (parsedJSON != null) {
										payload = payload.concat(parsedJSON);

										accumulatedChunk = '';
										parsedJSON = null;

										const { tabsData, selectedTabIndex } = queryFunction({
											payload,
											sortOption,
											curActiveTabIndex,
											flightQuery,
											dispatch,
											tripData,
											existingFlightResults,
										});

										resolve({ tabsData, selectedTabIndex });

										dispatch({
											type: 'CHECKPOINT',
											payload: TripUpdateType.OVERVIEW_FLIGHT_SELECTED,
										});

										sendMessageEvent({
											event: GoogleEventTypes.FLIGHTS_LOADED,
											chat_session_id: chatSessionId,
										});

										if (!shouldUpdateFlightsAndDayPlans) {
											return;
										}

										if (!hotelsListPromiseResolved) {
											dispatch({
												type: 'UPDATE_LOADING_STATE',
												payload: {
													hotelsLoading: LoadingState.LOADING,
												},
											});
										}

										fetchDayPlansAndDispatch(
											dispatch,
											{
												...dayPlansQuery,
												travelSets: travelSets[selectedTabIndex],
												flightSelections: (
													tabsData[selectedTabIndex]?.content?.[0]?.legs || []
												).map(
													({
														arrivalDate,
														arrivalTime,
														destination,
														source,
														departureTime,
														departureDate,
													}) => ({
														fromAirport: source,
														toAirport: destination,
														departureDate,
														departureTime,
														arrivalDate,
														arrivalTime,
													}),
												),
											},
											chatSessionId,
											selectedTabIndex,
										);
									}
								}

								readChunk(); // Recursively read the next chunk
							})
							.catch((error) => {
								reject(error); // Reject the promise if there's an error
							});
					}

					readChunk();
				})
				.catch((error) => {
					reject(error);
					dispatch({ type: 'UPDATE_FLIGHT_RESULTS', payload: null });
					dispatch({
						type: 'UPDATE_LOADING_STATE',
						payload: {
							flightsLoading: LoadingState.ERROR,
						},
					});
				});
		});

		if (!shouldUpdateFlightsAndDayPlans) {
			await Promise.all([flightsPromise]);
		}

		const hotelspromise = new Promise((resolve, reject) => {
			const flightQueryParams = new URLSearchParams(location.search);
			flightQueryParams.append(
				'hotel_reqs',
				JSON.stringify(hotelFilters.filter((filter) => filter)),
			);
			flightQueryParams.append('sort_criterion', hotelSortCriterion);

			fetch(
				`${backendURL}/api/search_hotels_lists?${flightQueryParams.toString()}`,
			)
				.then((resp) => {
					return resp.json();
				})
				.then((resp) => {
					const hotelsListWithids = resp.map((hotelsofLocation) => {
						return hotelsofLocation.map(({ hotelId }) => ({ hotelId }));
					});
					hotelsListPromiseResolved = true;
					resolve({ hotelsList: resp, hotelsListWithids });
				})
				.catch((error) => {
					reject(error);
				});
		});

		const promisesArray = [hotelspromise, flightsPromise];

		const finalPayload = await Promise.all(promisesArray);

		if (shouldUpdateFlightsAndDayPlans) {
			const [
				{ hotelsList = [], hotelsListWithids = [] } = {},
				{ tabsData = [], selectedTabIndex = 0 } = {},
			] = finalPayload;

			const hotelDateSets = hotelDateSetHelper(
				LOCATIONS,
				HOTEL_REQUIREMENT,
				tabsData[selectedTabIndex]?.content?.[0]?.legs || [],
				travelSets[selectedTabIndex],
			);

			handleFindHotels({
				dispatch,
				location,
				hotel_set_data: {
					hotel_datesets: [hotelDateSets],
					hotelFilters,
					NUM_TRAVELLERS,
					HOTEL_REQUIREMENT,
					sortCriterion: hotelSortCriterion,
				},
				tabIndex: selectedTabIndex,
				gMapsData,
				chatSessionId,
				hotelsList: hotelsListWithids,
				actualHotelsList: hotelsList,
			});
		}
	} catch (error) {
		console.log(error);
	}
};

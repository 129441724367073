export default [
    {
      name: "All schengen countries in Europe",
      city: "Schengen Europe",
      country: "Region",
      value:
        "AT,BE,CZ,DK,EE,FI,FR,DE,GR,HU,IS,IT,LV,LI,LT,LU,MT,NL,NO,PL,PT,SK,SI,ES,SE,CH",
      display: "26 Countries"
    },
    {
      name: "All non-schengen countries in Europe",
      city: "Non-Schengen Europe",
      country: "Region",
      value: "AL,AD,AM,AZ,BY,BA,HR,CY,GE,IE,MK,MD,MC,ME,RO,RU,SM,RS,TR,UA,UK",
      display: "21 Countries"
    },
    {
      name: "All countries in North America",
      city: "North America",
      country: "Region",
      value: "CA,US,MX",
      display: "3 Countries"
    },
    {
      name: "All countries in the Pacific",
      city: "Pacific",
      country: "Region",
      value: "MH,WS,TV,FJ,FM,PW,SB,VU,KI,NR,PG,TO,PF",
      display: "13 Countries"
    },
    {
      name: "All countries in South America",
      city: "South America",
      country: "Region",
      value: "AR,BO,BR,CL,CO,EC,FK,GF,GY,GY,PY,PE,SR,UY,VE",
      display: "15 Countries"
    },
    {
      name: "All countries in Europe",
      city: "Europe",
      country: "Region",
      value:
        "AL,AD,AM,AT,BY,BE,BA,BG,CH,CY,CZ,DE,DK,EE,ES,FO,FI,FR,GB,GE,GI,GR,HU,HR,IE,IS,IT,LT,LU,LV,MC,MK,MT,NO,NL,PO,PT,RO,SE,SI,SK,SM,UA,VA",
      display: "44 Countries"
    },
    {
      name: "All countries in the Caribbean",
      city: "Caribbean",
      country: "Region",
      value:
        "CU,HT,DO,PR,JM,TT,GP,MQ,BS,BB,LC,CW,AW,VC,VI,GD,AG,DM,KY,KN,SX,TC,MF,VG,BQ,AI,BL,MS",
      display: "28 Countries"
    },
    {
      name: "All countries in Central America",
      city: "Central America",
      country: "Region",
      value: "BZ,CR,SV,GT,HN,NI,PA",
      display: "7 Countries"
    },
    {
      name: "All countries in East Asia",
      city: "East Asia",
      country: "Region",
      value: "CN,HK,MO,JP,KR,KP,MN,TW",
      display: "8 Countries"
    },
    {
      name: "All countries in South East Asia",
      city: "South East Asia",
      country: "Region",
      value: "BN,KH,ID,LA,MY,MM,PH,SG,TH,TP,VN",
      display: "11 Countries"
    },
    {
      name: "All countries in Central Asia",
      city: "Central Asia",
      country: "Region",
      value: "AF,BD,BT,IN,IR,KZ,KG,MV,NP,PK,IK,TJ,TM,UZ",
      display: "14 Countries"
    },
    {
      name: "All countries in Oceania",
      city: "Oceania",
      country: "Region",
      value: "AU,NZ",
      display: "2 Countries"
    },
    {
      name: "All countries in Africa",
      city: "Africa",
      country: "Region",
      value:
        "DZ,AO,SH,BJ,BW,BF,BI,CM,CV,CF,TD,KM,CG,CD,DJ,EG,GQ,ER,ET,GA,GM,GH,GN,GW,CI,KE,LS,LR,LY,MG,MW,ML,MR,MU,YT,MA,MZ,NA,NE,NG,ST,RE,RW,ST,SN,SC,SL,SO,ZA,SS,SH,SD,SZ,TZ,TG,TN,UG,CD,ZM,TZ,ZW",
      display: "61 Countries"
    },
    {
      name: "All countries in the Middle East",
      city: "Middle East",
      country: "Region",
      value: "AM,AZ,BH,GE,IR,IQ,IL,JO,KW,LB,NT,OM,QA,SA,SY,TR,TM,AE,YE",
      display: "19 Countries"
    },
    {
      name: "All cities in Afghanistan",
      city: "Afghanistan",
      country: "Country",
      value: "AF",
      display: "AF"
    },
    {
      name: "All cities in Aland Islands",
      city: "Aland Islands",
      country: "Country",
      value: "AX",
      display: "AX"
    },
    {
      name: "All cities in Albania",
      city: "Albania",
      country: "Country",
      value: "AL",
      display: "AL"
    },
    {
      name: "All cities in Algeria",
      city: "Algeria",
      country: "Country",
      value: "DZ",
      display: "DZ"
    },
    {
      name: "All cities in American Samoa",
      city: "American Samoa",
      country: "Country",
      value: "AS",
      display: "AS"
    },
    {
      name: "All cities in Andorra",
      city: "Andorra",
      country: "Country",
      value: "AD",
      display: "AD"
    },
    {
      name: "All cities in Angola",
      city: "Angola",
      country: "Country",
      value: "AO",
      display: "AO"
    },
    {
      name: "All cities in Anguilla",
      city: "Anguilla",
      country: "Country",
      value: "AI",
      display: "AI"
    },
    {
      name: "All cities in Antigua and Barbuda",
      city: "Antigua and Barbuda",
      country: "Country",
      value: "AG",
      display: "AG"
    },
    {
      name: "All cities in Argentina",
      city: "Argentina",
      country: "Country",
      value: "AR",
      display: "AR"
    },
    {
      name: "All cities in Armenia",
      city: "Armenia",
      country: "Country",
      value: "AM",
      display: "AM"
    },
    {
      name: "All cities in Aruba",
      city: "Aruba",
      country: "Country",
      value: "AW",
      display: "AW"
    },
    {
      name: "All cities in Australia",
      city: "Australia",
      country: "Country",
      value: "AU",
      display: "AU"
    },
    {
      name: "All cities in Austria",
      city: "Austria",
      country: "Country",
      value: "AT",
      display: "AT"
    },
    {
      name: "All cities in Azerbaijan",
      city: "Azerbaijan",
      country: "Country",
      value: "AZ",
      display: "AZ"
    },
    {
      name: "All cities in Bahamas",
      city: "Bahamas",
      country: "Country",
      value: "BS",
      display: "BS"
    },
    {
      name: "All cities in Bahrain",
      city: "Bahrain",
      country: "Country",
      value: "BH",
      display: "BH"
    },
    {
      name: "All cities in Bangladesh",
      city: "Bangladesh",
      country: "Country",
      value: "BD",
      display: "BD"
    },
    {
      name: "All cities in Barbados",
      city: "Barbados",
      country: "Country",
      value: "BB",
      display: "BB"
    },
    {
      name: "All cities in Belarus",
      city: "Belarus",
      country: "Country",
      value: "BY",
      display: "BY"
    },
    {
      name: "All cities in Belgium",
      city: "Belgium",
      country: "Country",
      value: "BE",
      display: "BE"
    },
    {
      name: "All cities in Belize",
      city: "Belize",
      country: "Country",
      value: "BZ",
      display: "BZ"
    },
    {
      name: "All cities in Benin",
      city: "Benin",
      country: "Country",
      value: "BJ",
      display: "BJ"
    },
    {
      name: "All cities in Bermuda",
      city: "Bermuda",
      country: "Country",
      value: "BM",
      display: "BM"
    },
    {
      name: "All cities in Bhutan",
      city: "Bhutan",
      country: "Country",
      value: "BT",
      display: "BT"
    },
    {
      name: "All cities in Bolivia",
      city: "Bolivia",
      country: "Country",
      value: "BO",
      display: "BO"
    },
    {
      name: "All cities in Bosnia and Herzegovina",
      city: "Bosnia and Herzegovina",
      country: "Country",
      value: "BA",
      display: "BA"
    },
    {
      name: "All cities in Botswana",
      city: "Botswana",
      country: "Country",
      value: "BW",
      display: "BW"
    },
    {
      name: "All cities in Bouvet Island",
      city: "Bouvet Island",
      country: "Country",
      value: "BV",
      display: "BV"
    },
    {
      name: "All cities in Brazil",
      city: "Brazil",
      country: "Country",
      value: "BR",
      display: "BR"
    },
    {
      name: "All cities in British Virgin Islands",
      city: "British Virgin Islands",
      country: "Country",
      value: "VG",
      display: "VG"
    },
    {
      name: "All cities in British Indian Ocean Territory",
      city: "British Indian Ocean Territory",
      country: "Country",
      value: "IO",
      display: "IO"
    },
    {
      name: "All cities in Brunei Darussalam",
      city: "Brunei Darussalam",
      country: "Country",
      value: "BN",
      display: "BN"
    },
    {
      name: "All cities in Bulgaria",
      city: "Bulgaria",
      country: "Country",
      value: "BG",
      display: "BG"
    },
    {
      name: "All cities in Burkina Faso",
      city: "Burkina Faso",
      country: "Country",
      value: "BF",
      display: "BF"
    },
    {
      name: "All cities in Burundi",
      city: "Burundi",
      country: "Country",
      value: "BI",
      display: "BI"
    },
    {
      name: "All cities in Cambodia",
      city: "Cambodia",
      country: "Country",
      value: "KH",
      display: "KH"
    },
    {
      name: "All cities in Cameroon",
      city: "Cameroon",
      country: "Country",
      value: "CM",
      display: "CM"
    },
    {
      name: "All cities in Canada",
      city: "Canada",
      country: "Country",
      value: "CA",
      display: "CA"
    },
    {
      name: "All cities in Cape Verde",
      city: "Cape Verde",
      country: "Country",
      value: "CV",
      display: "CV"
    },
    {
      name: "All cities in Cayman Islands",
      city: "Cayman Islands",
      country: "Country",
      value: "KY",
      display: "KY"
    },
    {
      name: "All cities in Central African Republic",
      city: "Central African Republic",
      country: "Country",
      value: "CF",
      display: "CF"
    },
    {
      name: "All cities in Chad",
      city: "Chad",
      country: "Country",
      value: "TD",
      display: "TD"
    },
    {
      name: "All cities in Chile",
      city: "Chile",
      country: "Country",
      value: "CL",
      display: "CL"
    },
    {
      name: "All cities in China",
      city: "China",
      country: "Country",
      value: "CN",
      display: "CN"
    },
    {
      name: "All cities in Hong Kong",
      city: "Hong Kong",
      country: "Country",
      value: "HK",
      display: "HK"
    },
    {
      name: "All cities in Macao",
      city: "Macao",
      country: "Country",
      value: "MO",
      display: "MO"
    },
    {
      name: "All cities in Christmas Island",
      city: "Christmas Island",
      country: "Country",
      value: "CX",
      display: "CX"
    },
    {
      name: "All cities in Cocos (Keeling) Islands",
      city: "Cocos (Keeling) Islands",
      country: "Country",
      value: "CC",
      display: "CC"
    },
    {
      name: "All cities in Colombia",
      city: "Colombia",
      country: "Country",
      value: "CO",
      display: "CO"
    },
    {
      name: "All cities in Comoros",
      city: "Comoros",
      country: "Country",
      value: "KM",
      display: "KM"
    },
    {
      name: "All cities in Republic of Congo (Brazzaville)",
      city: "Republic of Congo",
      country: "Country",
      value: "CG",
      display: "CG"
    },
    {
      name: "All cities in Democratic Republic of Congo (Kinshasa)",
      city: "Democratic Republic of Congo",
      country: "Country",
      value: "CD",
      display: "CD"
    },
    {
      name: "All cities in Cook Islands",
      city: "Cook Islands",
      country: "Country",
      value: "CK",
      display: "CK"
    },
    {
      name: "All cities in Costa Rica",
      city: "Costa Rica",
      country: "Country",
      value: "CR",
      display: "CR"
    },
    {
      name: "All cities in Ivory Coast",
      city: "Ivory Coast",
      country: "Country",
      value: "CI",
      display: "CI"
    },
    {
      name: "All cities in Croatia",
      city: "Croatia",
      country: "Country",
      value: "HR",
      display: "HR"
    },
    {
      name: "All cities in Cuba",
      city: "Cuba",
      country: "Country",
      value: "CU",
      display: "CU"
    },
    {
      name: "All cities in Cyprus",
      city: "Cyprus",
      country: "Country",
      value: "CY",
      display: "CY"
    },
    {
      name: "All cities in Czech Republic",
      city: "Czech Republic",
      country: "Country",
      value: "CZ",
      display: "CZ"
    },
    {
      name: "All cities in Denmark",
      city: "Denmark",
      country: "Country",
      value: "DK",
      display: "DK"
    },
    {
      name: "All cities in Djibouti",
      city: "Djibouti",
      country: "Country",
      value: "DJ",
      display: "DJ"
    },
    {
      name: "All cities in Dominica",
      city: "Dominica",
      country: "Country",
      value: "DM",
      display: "DM"
    },
    {
      name: "All cities in Dominican Republic",
      city: "Dominican Republic",
      country: "Country",
      value: "DO",
      display: "DO"
    },
    {
      name: "All cities in Ecuador",
      city: "Ecuador",
      country: "Country",
      value: "EC",
      display: "EC"
    },
    {
      name: "All cities in Egypt",
      city: "Egypt",
      country: "Country",
      value: "EG",
      display: "EG"
    },
    {
      name: "All cities in El Salvador",
      city: "El Salvador",
      country: "Country",
      value: "SV",
      display: "SV"
    },
    {
      name: "All cities in Equatorial Guinea",
      city: "Equatorial Guinea",
      country: "Country",
      value: "GQ",
      display: "GQ"
    },
    {
      name: "All cities in Eritrea",
      city: "Eritrea",
      country: "Country",
      value: "ER",
      display: "ER"
    },
    {
      name: "All cities in Estonia",
      city: "Estonia",
      country: "Country",
      value: "EE",
      display: "EE"
    },
    {
      name: "All cities in Ethiopia",
      city: "Ethiopia",
      country: "Country",
      value: "ET",
      display: "ET"
    },
    {
      name: "All cities in Falkland Islands",
      city: "Falkland Islands",
      country: "Country",
      value: "FK",
      display: "FK"
    },
    {
      name: "All cities in Faroe Islands",
      city: "Faroe Islands",
      country: "Country",
      value: "FO",
      display: "FO"
    },
    {
      name: "All cities in Fiji",
      city: "Fiji",
      country: "Country",
      value: "FJ",
      display: "FJ"
    },
    {
      name: "All cities in Finland",
      city: "Finland",
      country: "Country",
      value: "FI",
      display: "FI"
    },
    {
      name: "All cities in France",
      city: "France",
      country: "Country",
      value: "FR",
      display: "FR"
    },
    {
      name: "All cities in French Guiana",
      city: "French Guiana",
      country: "Country",
      value: "GF",
      display: "GF"
    },
    {
      name: "All cities in French Polynesia",
      city: "French Polynesia",
      country: "Country",
      value: "PF",
      display: "PF"
    },
    {
      name: "All cities in French Southern Territories",
      city: "French Southern Territories",
      country: "Country",
      value: "TF",
      display: "TF"
    },
    {
      name: "All cities in Gabon",
      city: "Gabon",
      country: "Country",
      value: "GA",
      display: "GA"
    },
    {
      name: "All cities in Gambia",
      city: "Gambia",
      country: "Country",
      value: "GM",
      display: "GM"
    },
    {
      name: "All cities in Georgia",
      city: "Georgia",
      country: "Country",
      value: "GE",
      display: "GE"
    },
    {
      name: "All cities in Germany",
      city: "Germany",
      country: "Country",
      value: "DE",
      display: "DE"
    },
    {
      name: "All cities in Ghana",
      city: "Ghana",
      country: "Country",
      value: "GH",
      display: "GH"
    },
    {
      name: "All cities in Gibraltar",
      city: "Gibraltar",
      country: "Country",
      value: "GI",
      display: "GI"
    },
    {
      name: "All cities in Greece",
      city: "Greece",
      country: "Country",
      value: "GR",
      display: "GR"
    },
    {
      name: "All cities in Greenland",
      city: "Greenland",
      country: "Country",
      value: "GL",
      display: "GL"
    },
    {
      name: "All cities in Grenada",
      city: "Grenada",
      country: "Country",
      value: "GD",
      display: "GD"
    },
    {
      name: "All cities in Guadeloupe",
      city: "Guadeloupe",
      country: "Country",
      value: "GP",
      display: "GP"
    },
    {
      name: "All cities in Guam",
      city: "Guam",
      country: "Country",
      value: "GU",
      display: "GU"
    },
    {
      name: "All cities in Guatemala",
      city: "Guatemala",
      country: "Country",
      value: "GT",
      display: "GT"
    },
    {
      name: "All cities in Guernsey",
      city: "Guernsey",
      country: "Country",
      value: "GG",
      display: "GG"
    },
    {
      name: "All cities in Guinea",
      city: "Guinea",
      country: "Country",
      value: "GN",
      display: "GN"
    },
    {
      name: "All cities in Guinea-Bissau",
      city: "Guinea-Bissau",
      country: "Country",
      value: "GW",
      display: "GW"
    },
    {
      name: "All cities in Guyana",
      city: "Guyana",
      country: "Country",
      value: "GY",
      display: "GY"
    },
    {
      name: "All cities in Haiti",
      city: "Haiti",
      country: "Country",
      value: "HT",
      display: "HT"
    },
    {
      name: "All cities in Heard and Mcdonald Islands",
      city: "Heard and Mcdonald Islands",
      country: "Country",
      value: "HM",
      display: "HM"
    },
    {
      name: "All cities in Honduras",
      city: "Honduras",
      country: "Country",
      value: "HN",
      display: "HN"
    },
    {
      name: "All cities in Hungary",
      city: "Hungary",
      country: "Country",
      value: "HU",
      display: "HU"
    },
    {
      name: "All cities in Iceland",
      city: "Iceland",
      country: "Country",
      value: "IS",
      display: "IS"
    },
    {
      name: "All cities in India",
      city: "India",
      country: "Country",
      value: "IN",
      display: "IN"
    },
    {
      name: "All cities in Indonesia",
      city: "Indonesia",
      country: "Country",
      value: "ID",
      display: "ID"
    },
    {
      name: "All cities in Iran",
      city: "Iran",
      country: "Country",
      value: "IR",
      display: "IR"
    },
    {
      name: "All cities in Iraq",
      city: "Iraq",
      country: "Country",
      value: "IQ",
      display: "IQ"
    },
    {
      name: "All cities in Ireland",
      city: "Ireland",
      country: "Country",
      value: "IE",
      display: "IE"
    },
    {
      name: "All cities in Isle of Man",
      city: "Isle of Man",
      country: "Country",
      value: "IM",
      display: "IM"
    },
    {
      name: "All cities in Israel",
      city: "Israel",
      country: "Country",
      value: "IL",
      display: "IL"
    },
    {
      name: "All cities in Italy",
      city: "Italy",
      country: "Country",
      value: "IT",
      display: "IT"
    },
    {
      name: "All cities in Jamaica",
      city: "Jamaica",
      country: "Country",
      value: "JM",
      display: "JM"
    },
    {
      name: "All cities in Japan",
      city: "Japan",
      country: "Country",
      value: "JP",
      display: "JP"
    },
    {
      name: "All cities in Jersey",
      city: "Jersey",
      country: "Country",
      value: "JE",
      display: "JE"
    },
    {
      name: "All cities in Jordan",
      city: "Jordan",
      country: "Country",
      value: "JO",
      display: "JO"
    },
    {
      name: "All cities in Kazakhstan",
      city: "Kazakhstan",
      country: "Country",
      value: "KZ",
      display: "KZ"
    },
    {
      name: "All cities in Kenya",
      city: "Kenya",
      country: "Country",
      value: "KE",
      display: "KE"
    },
    {
      name: "All cities in Kiribati",
      city: "Kiribati",
      country: "Country",
      value: "KI",
      display: "KI"
    },
    {
      name: "All cities in North Korea",
      city: "North Korea",
      country: "Country",
      value: "KP",
      display: "KP"
    },
    {
      name: "All cities in South Korea",
      city: "South Korea",
      country: "Country",
      value: "KR",
      display: "KR"
    },
    {
      name: "All cities in Kuwait",
      city: "Kuwait",
      country: "Country",
      value: "KW",
      display: "KW"
    },
    {
      name: "All cities in Kyrgyzstan",
      city: "Kyrgyzstan",
      country: "Country",
      value: "KG",
      display: "KG"
    },
    {
      name: "All cities in Laos",
      city: "Laos",
      country: "Country",
      value: "LA",
      display: "LA"
    },
    {
      name: "All cities in Latvia",
      city: "Latvia",
      country: "Country",
      value: "LV",
      display: "LV"
    },
    {
      name: "All cities in Lebanon",
      city: "Lebanon",
      country: "Country",
      value: "LB",
      display: "LB"
    },
    {
      name: "All cities in Lesotho",
      city: "Lesotho",
      country: "Country",
      value: "LS",
      display: "LS"
    },
    {
      name: "All cities in Liberia",
      city: "Liberia",
      country: "Country",
      value: "LR",
      display: "LR"
    },
    {
      name: "All cities in Libya",
      city: "Libya",
      country: "Country",
      value: "LY",
      display: "LY"
    },
    {
      name: "All cities in Liechtenstein",
      city: "Liechtenstein",
      country: "Country",
      value: "LI",
      display: "LI"
    },
    {
      name: "All cities in Lithuania",
      city: "Lithuania",
      country: "Country",
      value: "LT",
      display: "LT"
    },
    {
      name: "All cities in Luxembourg",
      city: "Luxembourg",
      country: "Country",
      value: "LU",
      display: "LU"
    },
    {
      name: "All cities in Macedonia",
      city: "Macedonia",
      country: "Country",
      value: "MK",
      display: "MK"
    },
    {
      name: "All cities in Madagascar",
      city: "Madagascar",
      country: "Country",
      value: "MG",
      display: "MG"
    },
    {
      name: "All cities in Malawi",
      city: "Malawi",
      country: "Country",
      value: "MW",
      display: "MW"
    },
    {
      name: "All cities in Malaysia",
      city: "Malaysia",
      country: "Country",
      value: "MY",
      display: "MY"
    },
    {
      name: "All cities in Maldives",
      city: "Maldives",
      country: "Country",
      value: "MV",
      display: "MV"
    },
    {
      name: "All cities in Mali",
      city: "Mali",
      country: "Country",
      value: "ML",
      display: "ML"
    },
    {
      name: "All cities in Malta",
      city: "Malta",
      country: "Country",
      value: "MT",
      display: "MT"
    },
    {
      name: "All cities in Marshall Islands",
      city: "Marshall Islands",
      country: "Country",
      value: "MH",
      display: "MH"
    },
    {
      name: "All cities in Martinique",
      city: "Martinique",
      country: "Country",
      value: "MQ",
      display: "MQ"
    },
    {
      name: "All cities in Mauritania",
      city: "Mauritania",
      country: "Country",
      value: "MR",
      display: "MR"
    },
    {
      name: "All cities in Mauritius",
      city: "Mauritius",
      country: "Country",
      value: "MU",
      display: "MU"
    },
    {
      name: "All cities in Mayotte",
      city: "Mayotte",
      country: "Country",
      value: "YT",
      display: "YT"
    },
    {
      name: "All cities in Mexico",
      city: "Mexico",
      country: "Country",
      value: "MX",
      display: "MX"
    },
    {
      name: "All cities in Micronesia",
      city: "Micronesia",
      country: "Country",
      value: "FM",
      display: "FM"
    },
    {
      name: "All cities in Moldova",
      city: "Moldova",
      country: "Country",
      value: "MD",
      display: "MD"
    },
    {
      name: "All cities in Monaco",
      city: "Monaco",
      country: "Country",
      value: "MC",
      display: "MC"
    },
    {
      name: "All cities in Mongolia",
      city: "Mongolia",
      country: "Country",
      value: "MN",
      display: "MN"
    },
    {
      name: "All cities in Montenegro",
      city: "Montenegro",
      country: "Country",
      value: "ME",
      display: "ME"
    },
    {
      name: "All cities in Montserrat",
      city: "Montserrat",
      country: "Country",
      value: "MS",
      display: "MS"
    },
    {
      name: "All cities in Morocco",
      city: "Morocco",
      country: "Country",
      value: "MA",
      display: "MA"
    },
    {
      name: "All cities in Mozambique",
      city: "Mozambique",
      country: "Country",
      value: "MZ",
      display: "MZ"
    },
    {
      name: "All cities in Myanmar",
      city: "Myanmar",
      country: "Country",
      value: "MM",
      display: "MM"
    },
    {
      name: "All cities in Namibia",
      city: "Namibia",
      country: "Country",
      value: "NA",
      display: "NA"
    },
    {
      name: "All cities in Nauru",
      city: "Nauru",
      country: "Country",
      value: "NR",
      display: "NR"
    },
    {
      name: "All cities in Nepal",
      city: "Nepal",
      country: "Country",
      value: "NP",
      display: "NP"
    },
    {
      name: "All cities in Netherlands",
      city: "Netherlands",
      country: "Country",
      value: "NL",
      display: "NL"
    },
    {
      name: "All cities in Netherlands Antilles",
      city: "Netherlands Antilles",
      country: "Country",
      value: "AN",
      display: "AN"
    },
    {
      name: "All cities in New Caledonia",
      city: "New Caledonia",
      country: "Country",
      value: "NC",
      display: "NC"
    },
    {
      name: "All cities in New Zealand",
      city: "New Zealand",
      country: "Country",
      value: "NZ",
      display: "NZ"
    },
    {
      name: "All cities in Nicaragua",
      city: "Nicaragua",
      country: "Country",
      value: "NI",
      display: "NI"
    },
    {
      name: "All cities in Niger",
      city: "Niger",
      country: "Country",
      value: "NE",
      display: "NE"
    },
    {
      name: "All cities in Nigeria",
      city: "Nigeria",
      country: "Country",
      value: "NG",
      display: "NG"
    },
    {
      name: "All cities in Niue",
      city: "Niue",
      country: "Country",
      value: "NU",
      display: "NU"
    },
    {
      name: "All cities in Norfolk Island",
      city: "Norfolk Island",
      country: "Country",
      value: "NF",
      display: "NF"
    },
    {
      name: "All cities in Northern Mariana Islands",
      city: "Northern Mariana Islands",
      country: "Country",
      value: "MP",
      display: "MP"
    },
    {
      name: "All cities in Norway",
      city: "Norway",
      country: "Country",
      value: "NO",
      display: "NO"
    },
    {
      name: "All cities in Oman",
      city: "Oman",
      country: "Country",
      value: "OM",
      display: "OM"
    },
    {
      name: "All cities in Pakistan",
      city: "Pakistan",
      country: "Country",
      value: "PK",
      display: "PK"
    },
    {
      name: "All cities in Palau",
      city: "Palau",
      country: "Country",
      value: "PW",
      display: "PW"
    },
    {
      name: "All cities in Palestinian Territory",
      city: "Palestinian Territory",
      country: "Country",
      value: "PS",
      display: "PS"
    },
    {
      name: "All cities in Panama",
      city: "Panama",
      country: "Country",
      value: "PA",
      display: "PA"
    },
    {
      name: "All cities in Papua New Guinea",
      city: "Papua New Guinea",
      country: "Country",
      value: "PG",
      display: "PG"
    },
    {
      name: "All cities in Paraguay",
      city: "Paraguay",
      country: "Country",
      value: "PY",
      display: "PY"
    },
    {
      name: "All cities in Peru",
      city: "Peru",
      country: "Country",
      value: "PE",
      display: "PE"
    },
    {
      name: "All cities in Philippines",
      city: "Philippines",
      country: "Country",
      value: "PH",
      display: "PH"
    },
    {
      name: "All cities in Pitcairn",
      city: "Pitcairn",
      country: "Country",
      value: "PN",
      display: "PN"
    },
    {
      name: "All cities in Poland",
      city: "Poland",
      country: "Country",
      value: "PL",
      display: "PL"
    },
    {
      name: "All cities in Portugal",
      city: "Portugal",
      country: "Country",
      value: "PT",
      display: "PT"
    },
    {
      name: "All cities in Puerto Rico",
      city: "Puerto Rico",
      country: "Country",
      value: "PR",
      display: "PR"
    },
    {
      name: "All cities in Qatar",
      city: "Qatar",
      country: "Country",
      value: "QA",
      display: "QA"
    },
    {
      name: "All cities in Reunion",
      city: "Reunion",
      country: "Country",
      value: "RE",
      display: "RE"
    },
    {
      name: "All cities in Romania",
      city: "Romania",
      country: "Country",
      value: "RO",
      display: "RO"
    },
    {
      name: "All cities in Russia",
      city: "Russia",
      country: "Country",
      value: "RU",
      display: "RU"
    },
    {
      name: "All cities in Rwanda",
      city: "Rwanda",
      country: "Country",
      value: "RW",
      display: "RW"
    },
    {
      name: "All cities in Saint-Barthelemy",
      city: "Saint-Barthelemy",
      country: "Country",
      value: "BL",
      display: "BL"
    },
    {
      name: "All cities in Saint Helena",
      city: "Saint Helena",
      country: "Country",
      value: "SH",
      display: "SH"
    },
    {
      name: "All cities in Saint Kitts and Nevis",
      city: "Saint Kitts and Nevis",
      country: "Country",
      value: "KN",
      display: "KN"
    },
    {
      name: "All cities in Saint Lucia",
      city: "Saint Lucia",
      country: "Country",
      value: "LC",
      display: "LC"
    },
    {
      name: "All cities in Saint-Martin (French part)",
      city: "Saint-Martin (French part)",
      country: "Country",
      value: "MF",
      display: "MF"
    },
    {
      name: "All cities in Saint Pierre and Miquelon",
      city: "Saint Pierre and Miquelon",
      country: "Country",
      value: "PM",
      display: "PM"
    },
    {
      name: "All cities in Saint Vincent and Grenadines",
      city: "Saint Vincent and Grenadines",
      country: "Country",
      value: "VC",
      display: "VC"
    },
    {
      name: "All cities in Samoa",
      city: "Samoa",
      country: "Country",
      value: "WS",
      display: "WS"
    },
    {
      name: "All cities in San Marino",
      city: "San Marino",
      country: "Country",
      value: "SM",
      display: "SM"
    },
    {
      name: "All cities in Sao Tome and Principe",
      city: "Sao Tome and Principe",
      country: "Country",
      value: "ST",
      display: "ST"
    },
    {
      name: "All cities in Saudi Arabia",
      city: "Saudi Arabia",
      country: "Country",
      value: "SA",
      display: "SA"
    },
    {
      name: "All cities in Senegal",
      city: "Senegal",
      country: "Country",
      value: "SN",
      display: "SN"
    },
    {
      name: "All cities in Serbia",
      city: "Serbia",
      country: "Country",
      value: "RS",
      display: "RS"
    },
    {
      name: "All cities in Seychelles",
      city: "Seychelles",
      country: "Country",
      value: "SC",
      display: "SC"
    },
    {
      name: "All cities in Sierra Leone",
      city: "Sierra Leone",
      country: "Country",
      value: "SL",
      display: "SL"
    },
    {
      name: "All cities in Singapore",
      city: "Singapore",
      country: "Country",
      value: "SG",
      display: "SG"
    },
    {
      name: "All cities in Slovakia",
      city: "Slovakia",
      country: "Country",
      value: "SK",
      display: "SK"
    },
    {
      name: "All cities in Slovenia",
      city: "Slovenia",
      country: "Country",
      value: "SI",
      display: "SI"
    },
    {
      name: "All cities in Solomon Islands",
      city: "Solomon Islands",
      country: "Country",
      value: "SB",
      display: "SB"
    },
    {
      name: "All cities in Somalia",
      city: "Somalia",
      country: "Country",
      value: "SO",
      display: "SO"
    },
    {
      name: "All cities in South Africa",
      city: "South Africa",
      country: "Country",
      value: "ZA",
      display: "ZA"
    },
    {
      name: "All cities in South Georgia and the South Sandwich Islands",
      city: "South Georgia and the South Sandwich Islands",
      country: "Country",
      value: "GS",
      display: "GS"
    },
    {
      name: "All cities in South Sudan",
      city: "South Sudan",
      country: "Country",
      value: "SS",
      display: "SS"
    },
    {
      name: "All cities in Spain",
      city: "Spain",
      country: "Country",
      value: "ES",
      display: "ES"
    },
    {
      name: "All cities in Sri Lanka",
      city: "Sri Lanka",
      country: "Country",
      value: "LK",
      display: "LK"
    },
    {
      name: "All cities in Sudan",
      city: "Sudan",
      country: "Country",
      value: "SD",
      display: "SD"
    },
    {
      name: "All cities in Suriname",
      city: "Suriname",
      country: "Country",
      value: "SR",
      display: "SR"
    },
    {
      name: "All cities in Svalbard and Jan Mayen Islands",
      city: "Svalbard and Jan Mayen Islands",
      country: "Country",
      value: "SJ",
      display: "SJ"
    },
    {
      name: "All cities in Swaziland",
      city: "Swaziland",
      country: "Country",
      value: "SZ",
      display: "SZ"
    },
    {
      name: "All cities in Sweden",
      city: "Sweden",
      country: "Country",
      value: "SE",
      display: "SE"
    },
    {
      name: "All cities in Switzerland",
      city: "Switzerland",
      country: "Country",
      value: "CH",
      display: "CH"
    },
    {
      name: "All cities in Syria",
      city: "Syria",
      country: "Country",
      value: "SY",
      display: "SY"
    },
    {
      name: "All cities in Taiwan",
      city: "Taiwan",
      country: "Country",
      value: "TW",
      display: "TW"
    },
    {
      name: "All cities in Tajikistan",
      city: "Tajikistan",
      country: "Country",
      value: "TJ",
      display: "TJ"
    },
    {
      name: "All cities in Tanzania",
      city: "Tanzania",
      country: "Country",
      value: "TZ",
      display: "TZ"
    },
    {
      name: "All cities in Thailand",
      city: "Thailand",
      country: "Country",
      value: "TH",
      display: "TH"
    },
    {
      name: "All cities in Timor-Leste",
      city: "Timor-Leste",
      country: "Country",
      value: "TL",
      display: "TL"
    },
    {
      name: "All cities in Togo",
      city: "Togo",
      country: "Country",
      value: "TG",
      display: "TG"
    },
    {
      name: "All cities in Tokelau",
      city: "Tokelau",
      country: "Country",
      value: "TK",
      display: "TK"
    },
    {
      name: "All cities in Tonga",
      city: "Tonga",
      country: "Country",
      value: "TO",
      display: "TO"
    },
    {
      name: "All cities in Trinidad and Tobago",
      city: "Trinidad and Tobago",
      country: "Country",
      value: "TT",
      display: "TT"
    },
    {
      name: "All cities in Tunisia",
      city: "Tunisia",
      country: "Country",
      value: "TN",
      display: "TN"
    },
    {
      name: "All cities in Turkey",
      city: "Turkey",
      country: "Country",
      value: "TR",
      display: "TR"
    },
    {
      name: "All cities in Turkmenistan",
      city: "Turkmenistan",
      country: "Country",
      value: "TM",
      display: "TM"
    },
    {
      name: "All cities in Turks and Caicos Islands",
      city: "Turks and Caicos Islands",
      country: "Country",
      value: "TC",
      display: "TC"
    },
    {
      name: "All cities in Tuvalu",
      city: "Tuvalu",
      country: "Country",
      value: "TV",
      display: "TV"
    },
    {
      name: "All cities in Uganda",
      city: "Uganda",
      country: "Country",
      value: "UG",
      display: "UG"
    },
    {
      name: "All cities in Ukraine",
      city: "Ukraine",
      country: "Country",
      value: "UA",
      display: "UA"
    },
    {
      name: "All cities in United Arab Emirates",
      city: "United Arab Emirates",
      country: "Country",
      value: "AE",
      display: "AE"
    },
    {
      name: "All cities in United Kingdom",
      city: "United Kingdom",
      country: "Country",
      value: "GB",
      display: "GB"
    },
    {
      name: "All cities in United States of America",
      city: "United States of America",
      country: "Country",
      value: "US",
      display: "US"
    },
    {
      name: "All cities in US Minor Outlying Islands",
      city: "US Minor Outlying Islands",
      country: "Country",
      value: "UM",
      display: "UM"
    },
    {
      name: "All cities in Uruguay",
      city: "Uruguay",
      country: "Country",
      value: "UY",
      display: "UY"
    },
    {
      name: "All cities in Uzbekistan",
      city: "Uzbekistan",
      country: "Country",
      value: "UZ",
      display: "UZ"
    },
    {
      name: "All cities in Vanuatu",
      city: "Vanuatu",
      country: "Country",
      value: "VU",
      display: "VU"
    },
    {
      name: "All cities in Venezuela",
      city: "Venezuela",
      country: "Country",
      value: "VE",
      display: "VE"
    },
    {
      name: "All cities in Viet Nam",
      city: "Viet Nam",
      country: "Country",
      value: "VN",
      display: "VN"
    },
    {
      name: "All cities in US Virgin Islands",
      city: "US Virgin Islands",
      country: "Country",
      value: "VI",
      display: "VI"
    },
    {
      name: "All cities in Wallis and Futuna Islands",
      city: "Wallis and Futuna Islands",
      country: "Country",
      value: "WF",
      display: "WF"
    },
    {
      name: "All cities in Western Sahara",
      city: "Western Sahara",
      country: "Country",
      value: "EH",
      display: "EH"
    },
    {
      name: "All cities in Yemen",
      city: "Yemen",
      country: "Country",
      value: "YE",
      display: "YE"
    },
    {
      name: "All cities in Zambia",
      city: "Zambia",
      country: "Country",
      value: "ZM",
      display: "ZM"
    },
    {
      name: "All cities in Zimbabwe",
      city: "Zimbabwe",
      country: "Country",
      value: "ZW",
      display: "ZW"
    },
    {
      name: "All Airports",
      city: "Abilene",
      country: "Metropolitan Area",
      value: "ABI",
      display: "ABI"
    },
    {
      name: "All Airports",
      city: "Abu Dhabi",
      country: "Metropolitan Area",
      value: "AUH",
      display: "AUH"
    },
    {
      name: "All Airports",
      city: "Aitape",
      country: "Metropolitan Area",
      value: "ATP",
      display: "ATP"
    },
    {
      name: "All Airports",
      city: "Akron Canton",
      country: "Metropolitan Area",
      value: "CAK",
      display: "CAK"
    },
    {
      name: "All Airports",
      city: "Alamogordo",
      country: "Metropolitan Area",
      value: "ALM",
      display: "ALM"
    },
    {
      name: "All Airports",
      city: "Alexandria",
      country: "Metropolitan Area",
      value: "AEX",
      display: "AEX"
    },
    {
      name: "All Airports",
      city: "Alexandria",
      country: "Metropolitan Area",
      value: "ALY",
      display: "ALY"
    },
    {
      name: "All Airports",
      city: "Altus",
      country: "Metropolitan Area",
      value: "LTS",
      display: "LTS"
    },
    {
      name: "All Airports",
      city: "Amarillo",
      country: "Metropolitan Area",
      value: "AMA",
      display: "AMA"
    },
    {
      name: "All Airports",
      city: "Amman",
      country: "Metropolitan Area",
      value: "AMM",
      display: "AMM"
    },
    {
      name: "All Airports",
      city: "Anchorage",
      country: "Metropolitan Area",
      value: "ANC",
      display: "ANC"
    },
    {
      name: "All Airports",
      city: "Ankara",
      country: "Metropolitan Area",
      value: "ANK",
      display: "ANK"
    },
    {
      name: "All Airports",
      city: "Apia",
      country: "Metropolitan Area",
      value: "APW",
      display: "APW"
    },
    {
      name: "All Airports",
      city: "Ardmore",
      country: "Metropolitan Area",
      value: "ADM",
      display: "ADM"
    },
    {
      name: "All Airports",
      city: "Atlanta",
      country: "Metropolitan Area",
      value: "ATL",
      display: "ATL"
    },
    {
      name: "All Airports",
      city: "Auckland",
      country: "Metropolitan Area",
      value: "AKL",
      display: "AKL"
    },
    {
      name: "All Airports",
      city: "Augusta",
      country: "Metropolitan Area",
      value: "AGS",
      display: "AGS"
    },
    {
      name: "All Airports",
      city: "Austin",
      country: "Metropolitan Area",
      value: "AUS",
      display: "AUS"
    },
    {
      name: "All Airports",
      city: "Baku",
      country: "Metropolitan Area",
      value: "BAK",
      display: "BAK"
    },
    {
      name: "All Airports",
      city: "Baltimore",
      country: "Metropolitan Area",
      value: "BWI",
      display: "BWI"
    },
    {
      name: "All Airports",
      city: "Bangkok",
      country: "Metropolitan Area",
      value: "BKK",
      display: "BKK"
    },
    {
      name: "All Airports",
      city: "Basel-Mulhouse-Freiburg EuroAirport",
      country: "Metropolitan Area",
      value: "EAP",
      display: "EAP"
    },
    {
      name: "All Airports",
      city: "Beaumont Port Arthur",
      country: "Metropolitan Area",
      value: "BPT",
      display: "BPT"
    },
    {
      name: "All Airports",
      city: "Beijing",
      country: "Metropolitan Area",
      value: "BJS",
      display: "BJS"
    },
    {
      name: "All Airports",
      city: "Belfast",
      country: "Metropolitan Area",
      value: "BFS",
      display: "BFS"
    },
    {
      name: "All Airports",
      city: "Belgrade",
      country: "Metropolitan Area",
      value: "BEG",
      display: "BEG"
    },
    {
      name: "All Airports",
      city: "Belize City",
      country: "Metropolitan Area",
      value: "BZE",
      display: "BZE"
    },
    {
      name: "All Airports",
      city: "Belo Horizonte",
      country: "Metropolitan Area",
      value: "BHZ",
      display: "BHZ"
    },
    {
      name: "All Airports",
      city: "Berlin",
      country: "Metropolitan Area",
      value: "BER",
      display: "BER"
    },
    {
      name: "All Airports",
      city: "Bethel",
      country: "Metropolitan Area",
      value: "BET",
      display: "BET"
    },
    {
      name: "All Airports",
      city: "Blytheville",
      country: "Metropolitan Area",
      value: "BYH",
      display: "BYH"
    },
    {
      name: "All Airports",
      city: "Boston",
      country: "Metropolitan Area",
      value: "BOS",
      display: "BOS"
    },
    {
      name: "All Airports",
      city: "Brunswick",
      country: "Metropolitan Area",
      value: "SSI",
      display: "SSI"
    },
    {
      name: "All Airports",
      city: "Brussels",
      country: "Metropolitan Area",
      value: "BRU",
      display: "BRU"
    },
    {
      name: "All Airports",
      city: "Bucharest",
      country: "Metropolitan Area",
      value: "BUH",
      display: "BUH"
    },
    {
      name: "All Airports",
      city: "Buenos Aires",
      country: "Metropolitan Area",
      value: "BUE",
      display: "BUE"
    },
    {
      name: "All Airports",
      city: "Cairo",
      country: "Metropolitan Area",
      value: "CAI",
      display: "CAI"
    },
    {
      name: "All Airports",
      city: "Campbell River",
      country: "Metropolitan Area",
      value: "YBL",
      display: "YBL"
    },
    {
      name: "All Airports",
      city: "Cannes",
      country: "Metropolitan Area",
      value: "CEQ",
      display: "CEQ"
    },
    {
      name: "All Airports",
      city: "Casablanca",
      country: "Metropolitan Area",
      value: "CAS",
      display: "CAS"
    },
    {
      name: "All Airports",
      city: "Charlotte",
      country: "Metropolitan Area",
      value: "CLT",
      display: "CLT"
    },
    {
      name: "All Airports",
      city: "Cheyenne",
      country: "Metropolitan Area",
      value: "CYS",
      display: "CYS"
    },
    {
      name: "All Airports",
      city: "Chicago",
      country: "Metropolitan Area",
      value: "CHI",
      display: "CHI"
    },
    {
      name: "All Airports",
      city: "Chignik",
      country: "Metropolitan Area",
      value: "KCL",
      display: "KCL"
    },
    {
      name: "All Airports",
      city: "Cincinnati",
      country: "Metropolitan Area",
      value: "CVG",
      display: "CVG"
    },
    {
      name: "All Airports",
      city: "Cleveland",
      country: "Metropolitan Area",
      value: "CLE",
      display: "CLE"
    },
    {
      name: "All Airports",
      city: "Clinton",
      country: "Metropolitan Area",
      value: "CSM",
      display: "CSM"
    },
    {
      name: "All Airports",
      city: "Clovis",
      country: "Metropolitan Area",
      value: "CVN",
      display: "CVN"
    },
    {
      name: "All Airports",
      city: "Colombo",
      country: "Metropolitan Area",
      value: "CMB",
      display: "CMB"
    },
    {
      name: "All Airports",
      city: "Colorado Springs",
      country: "Metropolitan Area",
      value: "COS",
      display: "COS"
    },
    {
      name: "All Airports",
      city: "Columbia",
      country: "Metropolitan Area",
      value: "CAE",
      display: "CAE"
    },
    {
      name: "All Airports",
      city: "Columbus",
      country: "Metropolitan Area",
      value: "CMH",
      display: "CMH"
    },
    {
      name: "All Airports",
      city: "Columbus",
      country: "Metropolitan Area",
      value: "CSG",
      display: "CSG"
    },
    {
      name: "All Airports",
      city: "Columbus",
      country: "Metropolitan Area",
      value: "UBS",
      display: "UBS"
    },
    {
      name: "All Airports",
      city: "Copenhagen",
      country: "Metropolitan Area",
      value: "CPH",
      display: "CPH"
    },
    {
      name: "All Airports",
      city: "Cordova",
      country: "Metropolitan Area",
      value: "CDV",
      display: "CDV"
    },
    {
      name: "All Airports",
      city: "Corpus Christi",
      country: "Metropolitan Area",
      value: "CRP",
      display: "CRP"
    },
    {
      name: "All Airports",
      city: "Dakar",
      country: "Metropolitan Area",
      value: "DKR",
      display: "DKR"
    },
    {
      name: "All Airports",
      city: "Dallas",
      country: "Metropolitan Area",
      value: "DFW",
      display: "DFW"
    },
    {
      name: "All Airports",
      city: "Dayton",
      country: "Metropolitan Area",
      value: "DAY",
      display: "DAY"
    },
    {
      name: "All Airports",
      city: "Del Rio",
      country: "Metropolitan Area",
      value: "DRT",
      display: "DRT"
    },
    {
      name: "All Airports",
      city: "Denver",
      country: "Metropolitan Area",
      value: "DEN",
      display: "DEN"
    },
    {
      name: "All Airports",
      city: "Destin Ft Walton Beach",
      country: "Metropolitan Area",
      value: "VPS",
      display: "VPS"
    },
    {
      name: "All Airports",
      city: "Detroit",
      country: "Metropolitan Area",
      value: "DTT",
      display: "DTT"
    },
    {
      name: "All Airports",
      city: "Dominica",
      country: "Metropolitan Area",
      value: "DOM",
      display: "DOM"
    },
    {
      name: "All Airports",
      city: "Douglas Bisbee",
      country: "Metropolitan Area",
      value: "DUG",
      display: "DUG"
    },
    {
      name: "All Airports",
      city: "Dubai",
      country: "Metropolitan Area",
      value: "DXB",
      display: "DXB"
    },
    {
      name: "All Airports",
      city: "Duesseldorf",
      country: "Metropolitan Area",
      value: "DUS",
      display: "DUS"
    },
    {
      name: "All Airports",
      city: "Durango",
      country: "Metropolitan Area",
      value: "DRO",
      display: "DRO"
    },
    {
      name: "All Airports",
      city: "Durban",
      country: "Metropolitan Area",
      value: "DUR",
      display: "DUR"
    },
    {
      name: "All Airports",
      city: "Edmonton",
      country: "Metropolitan Area",
      value: "YEA",
      display: "YEA"
    },
    {
      name: "All Airports",
      city: "El Paso",
      country: "Metropolitan Area",
      value: "ELP",
      display: "ELP"
    },
    {
      name: "All Airports",
      city: "Enid",
      country: "Metropolitan Area",
      value: "WDG",
      display: "WDG"
    },
    {
      name: "All Airports",
      city: "Fairbanks",
      country: "Metropolitan Area",
      value: "FAI",
      display: "FAI"
    },
    {
      name: "All Airports",
      city: "Fallon",
      country: "Metropolitan Area",
      value: "NFL",
      display: "NFL"
    },
    {
      name: "All Airports",
      city: "Fayetteville Springdale",
      country: "Metropolitan Area",
      value: "FYV",
      display: "FYV"
    },
    {
      name: "All Airports",
      city: "Fort Lauderdale",
      country: "Metropolitan Area",
      value: "FLL",
      display: "FLL"
    },
    {
      name: "All Airports",
      city: "Fort Myers",
      country: "Metropolitan Area",
      value: "FMY",
      display: "FMY"
    },
    {
      name: "All Airports",
      city: "Fort Rucker Ozark",
      country: "Metropolitan Area",
      value: "OZR",
      display: "OZR"
    },
    {
      name: "All Airports",
      city: "Fort Wayne",
      country: "Metropolitan Area",
      value: "FWA",
      display: "FWA"
    },
    {
      name: "All Airports",
      city: "Frankfurt",
      country: "Metropolitan Area",
      value: "FRA",
      display: "FRA"
    },
    {
      name: "All Airports",
      city: "Freetown",
      country: "Metropolitan Area",
      value: "FNA",
      display: "FNA"
    },
    {
      name: "All Airports",
      city: "Fresno",
      country: "Metropolitan Area",
      value: "FAT",
      display: "FAT"
    },
    {
      name: "All Airports",
      city: "Friday Harbor",
      country: "Metropolitan Area",
      value: "FRD",
      display: "FRD"
    },
    {
      name: "All Airports",
      city: "Glasgow",
      country: "Metropolitan Area",
      value: "GLA",
      display: "GLA"
    },
    {
      name: "All Airports",
      city: "Goteborg",
      country: "Metropolitan Area",
      value: "GOT",
      display: "GOT"
    },
    {
      name: "All Airports",
      city: "Grand Canyon",
      country: "Metropolitan Area",
      value: "GCN",
      display: "GCN"
    },
    {
      name: "All Airports",
      city: "Great Falls",
      country: "Metropolitan Area",
      value: "GTF",
      display: "GTF"
    },
    {
      name: "All Airports",
      city: "Greenville Spartanburg",
      country: "Metropolitan Area",
      value: "GSP",
      display: "GSP"
    },
    {
      name: "All Airports",
      city: "Guam",
      country: "Metropolitan Area",
      value: "GUM",
      display: "GUM"
    },
    {
      name: "All Airports",
      city: "Gustavus",
      country: "Metropolitan Area",
      value: "GST",
      display: "GST"
    },
    {
      name: "All Airports",
      city: "Halifax",
      country: "Metropolitan Area",
      value: "YHZ",
      display: "YHZ"
    },
    {
      name: "All Airports",
      city: "Hamburg",
      country: "Metropolitan Area",
      value: "HAM",
      display: "HAM"
    },
    {
      name: "All Airports",
      city: "Harrisburg",
      country: "Metropolitan Area",
      value: "HAR",
      display: "HAR"
    },
    {
      name: "All Airports",
      city: "Hartford",
      country: "Metropolitan Area",
      value: "HFD",
      display: "HFD"
    },
    {
      name: "All Airports",
      city: "Hattiesburg Laurel",
      country: "Metropolitan Area",
      value: "LUL",
      display: "LUL"
    },
    {
      name: "All Airports",
      city: "Helsinki",
      country: "Metropolitan Area",
      value: "HEL",
      display: "HEL"
    },
    {
      name: "All Airports",
      city: "Hobbs",
      country: "Metropolitan Area",
      value: "HOB",
      display: "HOB"
    },
    {
      name: "All Airports",
      city: "Hong Kong",
      country: "Metropolitan Area",
      value: "HKG",
      display: "HKG"
    },
    {
      name: "All Airports",
      city: "Houston",
      country: "Metropolitan Area",
      value: "HOU",
      display: "HOU"
    },
    {
      name: "All Airports",
      city: "Huntsville",
      country: "Metropolitan Area",
      value: "HSV",
      display: "HSV"
    },
    {
      name: "All Airports",
      city: "Hyderabad",
      country: "Metropolitan Area",
      value: "HYD",
      display: "HYD"
    },
    {
      name: "All Airports",
      city: "Imperial El Centro",
      country: "Metropolitan Area",
      value: "IPL",
      display: "IPL"
    },
    {
      name: "All Airports",
      city: "Isles of Scilly",
      country: "Metropolitan Area",
      value: "ISC",
      display: "ISC"
    },
    {
      name: "All Airports",
      city: "Istanbul",
      country: "Metropolitan Area",
      value: "IST",
      display: "IST"
    },
    {
      name: "All Airports",
      city: "Izmir",
      country: "Metropolitan Area",
      value: "IZM",
      display: "IZM"
    },
    {
      name: "All Airports",
      city: "Jackson",
      country: "Metropolitan Area",
      value: "JAN",
      display: "JAN"
    },
    {
      name: "All Airports",
      city: "Jacksonville",
      country: "Metropolitan Area",
      value: "JAX",
      display: "JAX"
    },
    {
      name: "All Airports",
      city: "Jakarta",
      country: "Metropolitan Area",
      value: "JKT",
      display: "JKT"
    },
    {
      name: "All Airports",
      city: "Johannesburg",
      country: "Metropolitan Area",
      value: "JNB",
      display: "JNB"
    },
    {
      name: "All Airports",
      city: "Kansas City",
      country: "Metropolitan Area",
      value: "MKC",
      display: "MKC"
    },
    {
      name: "All Airports",
      city: "Ketchikan",
      country: "Metropolitan Area",
      value: "KTN",
      display: "KTN"
    },
    {
      name: "All Airports",
      city: "Key West",
      country: "Metropolitan Area",
      value: "EYW",
      display: "EYW"
    },
    {
      name: "All Airports",
      city: "Kiev",
      country: "Metropolitan Area",
      value: "IEV",
      display: "IEV"
    },
    {
      name: "All Airports",
      city: "Killeen Fort Hood",
      country: "Metropolitan Area",
      value: "ILE",
      display: "ILE"
    },
    {
      name: "All Airports",
      city: "Kingston",
      country: "Metropolitan Area",
      value: "KIN",
      display: "KIN"
    },
    {
      name: "All Airports",
      city: "Kinshasa",
      country: "Metropolitan Area",
      value: "FIH",
      display: "FIH"
    },
    {
      name: "All Airports",
      city: "Kodiak",
      country: "Metropolitan Area",
      value: "ADQ",
      display: "ADQ"
    },
    {
      name: "All Airports",
      city: "Kuala Lumpur",
      country: "Metropolitan Area",
      value: "KUL",
      display: "KUL"
    },
    {
      name: "All Airports",
      city: "Las Vegas",
      country: "Metropolitan Area",
      value: "LAS",
      display: "LAS"
    },
    {
      name: "All Airports",
      city: "Lexington",
      country: "Metropolitan Area",
      value: "LEX",
      display: "LEX"
    },
    {
      name: "All Airports",
      city: "Lompoc",
      country: "Metropolitan Area",
      value: "LPC",
      display: "LPC"
    },
    {
      name: "All Airports",
      city: "London",
      country: "Metropolitan Area",
      value: "LON",
      display: "LON"
    },
    {
      name: "All Airports",
      city: "Los Angeles",
      country: "Metropolitan Area",
      value: "LAX",
      display: "LAX"
    },
    {
      name: "All Airports",
      city: "Louisville",
      country: "Metropolitan Area",
      value: "SDF",
      display: "SDF"
    },
    {
      name: "All Airports",
      city: "Lubbock",
      country: "Metropolitan Area",
      value: "LBB",
      display: "LBB"
    },
    {
      name: "All Airports",
      city: "Lyon",
      country: "Metropolitan Area",
      value: "LYS",
      display: "LYS"
    },
    {
      name: "All Airports",
      city: "Macon",
      country: "Metropolitan Area",
      value: "MCN",
      display: "MCN"
    },
    {
      name: "All Airports",
      city: "Madrid",
      country: "Metropolitan Area",
      value: "MAD",
      display: "MAD"
    },
    {
      name: "All Airports",
      city: "Malmo",
      country: "Metropolitan Area",
      value: "MMA",
      display: "MMA"
    },
    {
      name: "All Airports",
      city: "Manila",
      country: "Metropolitan Area",
      value: "MNL",
      display: "MNL"
    },
    {
      name: "All Airports",
      city: "Marysville",
      country: "Metropolitan Area",
      value: "MYV",
      display: "MYV"
    },
    {
      name: "All Airports",
      city: "Medellin",
      country: "Metropolitan Area",
      value: "MDE",
      display: "MDE"
    },
    {
      name: "All Airports",
      city: "Melbourne",
      country: "Metropolitan Area",
      value: "MEL",
      display: "MEL"
    },
    {
      name: "All Airports",
      city: "Merced",
      country: "Metropolitan Area",
      value: "MCE",
      display: "MCE"
    },
    {
      name: "All Airports",
      city: "Mexico City",
      country: "Metropolitan Area",
      value: "MEX",
      display: "MEX"
    },
    {
      name: "All Airports",
      city: "Miami",
      country: "Metropolitan Area",
      value: "MIA",
      display: "MIA"
    },
    {
      name: "All Airports",
      city: "Midland",
      country: "Metropolitan Area",
      value: "MAF",
      display: "MAF"
    },
    {
      name: "All Airports",
      city: "Milan",
      country: "Metropolitan Area",
      value: "MIL",
      display: "MIL"
    },
    {
      name: "All Airports",
      city: "Milwaukee",
      country: "Metropolitan Area",
      value: "MKE",
      display: "MKE"
    },
    {
      name: "All Airports",
      city: "Minneapolis St Paul",
      country: "Metropolitan Area",
      value: "MSP",
      display: "MSP"
    },
    {
      name: "All Airports",
      city: "Minot",
      country: "Metropolitan Area",
      value: "MOT",
      display: "MOT"
    },
    {
      name: "All Airports",
      city: "Minsk",
      country: "Metropolitan Area",
      value: "MSQ",
      display: "MSQ"
    },
    {
      name: "All Airports",
      city: "Mobile",
      country: "Metropolitan Area",
      value: "MOB",
      display: "MOB"
    },
    {
      name: "All Airports",
      city: "Monrovia",
      country: "Metropolitan Area",
      value: "MLW",
      display: "MLW"
    },
    {
      name: "All Airports",
      city: "Montague Yreka",
      country: "Metropolitan Area",
      value: "SIY",
      display: "SIY"
    },
    {
      name: "All Airports",
      city: "Monterey Carmel",
      country: "Metropolitan Area",
      value: "MRY",
      display: "MRY"
    },
    {
      name: "All Airports",
      city: "Monterrey",
      country: "Metropolitan Area",
      value: "MTY",
      display: "MTY"
    },
    {
      name: "All Airports",
      city: "Montgomery",
      country: "Metropolitan Area",
      value: "MGM",
      display: "MGM"
    },
    {
      name: "All Airports",
      city: "Montreal",
      country: "Metropolitan Area",
      value: "YMQ",
      display: "YMQ"
    },
    {
      name: "All Airports",
      city: "Moroni",
      country: "Metropolitan Area",
      value: "YVA",
      display: "YVA"
    },
    {
      name: "All Airports",
      city: "Moscow",
      country: "Metropolitan Area",
      value: "MOW",
      display: "MOW"
    },
    {
      name: "All Airports",
      city: "Moultrie",
      country: "Metropolitan Area",
      value: "MGR",
      display: "MGR"
    },
    {
      name: "All Airports",
      city: "Mount Cook",
      country: "Metropolitan Area",
      value: "MON",
      display: "MON"
    },
    {
      name: "All Airports",
      city: "Munich",
      country: "Metropolitan Area",
      value: "MUC",
      display: "MUC"
    },
    {
      name: "All Airports",
      city: "Myrtle Beach",
      country: "Metropolitan Area",
      value: "MYR",
      display: "MYR"
    },
    {
      name: "All Airports",
      city: "Nagoya",
      country: "Metropolitan Area",
      value: "NGO",
      display: "NGO"
    },
    {
      name: "All Airports",
      city: "Nairobi",
      country: "Metropolitan Area",
      value: "NBO",
      display: "NBO"
    },
    {
      name: "All Airports",
      city: "Nanaimo",
      country: "Metropolitan Area",
      value: "YCD",
      display: "YCD"
    },
    {
      name: "All Airports",
      city: "New Orleans",
      country: "Metropolitan Area",
      value: "MSY",
      display: "MSY"
    },
    {
      name: "All Airports",
      city: "New York",
      country: "Metropolitan Area",
      value: "NYC",
      display: "NYC"
    },
    {
      name: "All Airports",
      city: "Newcastle",
      country: "Metropolitan Area",
      value: "NTL",
      display: "NTL"
    },
    {
      name: "All Airports",
      city: "Nha Trang",
      country: "Metropolitan Area",
      value: "NHA",
      display: "NHA"
    },
    {
      name: "All Airports",
      city: "Norfolk",
      country: "Metropolitan Area",
      value: "ORF",
      display: "ORF"
    },
    {
      name: "All Airports",
      city: "Nottingham",
      country: "Metropolitan Area",
      value: "NQT",
      display: "NQT"
    },
    {
      name: "All Airports",
      city: "Noumea",
      country: "Metropolitan Area",
      value: "NOU",
      display: "NOU"
    },
    {
      name: "All Airports",
      city: "Oakland",
      country: "Metropolitan Area",
      value: "OAK",
      display: "OAK"
    },
    {
      name: "All Airports",
      city: "Ogden",
      country: "Metropolitan Area",
      value: "OGD",
      display: "OGD"
    },
    {
      name: "All Airports",
      city: "Okinawa",
      country: "Metropolitan Area",
      value: "OKA",
      display: "OKA"
    },
    {
      name: "All Airports",
      city: "Oklahoma City",
      country: "Metropolitan Area",
      value: "OKC",
      display: "OKC"
    },
    {
      name: "All Airports",
      city: "Omaha",
      country: "Metropolitan Area",
      value: "OMA",
      display: "OMA"
    },
    {
      name: "All Airports",
      city: "Oran",
      country: "Metropolitan Area",
      value: "ORN",
      display: "ORN"
    },
    {
      name: "All Airports",
      city: "Orlando",
      country: "Metropolitan Area",
      value: "ORL",
      display: "ORL"
    },
    {
      name: "All Airports",
      city: "Osaka",
      country: "Metropolitan Area",
      value: "OSA",
      display: "OSA"
    },
    {
      name: "All Airports",
      city: "Oslo",
      country: "Metropolitan Area",
      value: "OSL",
      display: "OSL"
    },
    {
      name: "All Airports",
      city: "Ottawa",
      country: "Metropolitan Area",
      value: "YOW",
      display: "YOW"
    },
    {
      name: "All Airports",
      city: "Palm Springs",
      country: "Metropolitan Area",
      value: "PSP",
      display: "PSP"
    },
    {
      name: "All Airports",
      city: "Panama City",
      country: "Metropolitan Area",
      value: "ECP",
      display: "ECP"
    },
    {
      name: "All Airports",
      city: "Panama City",
      country: "Metropolitan Area",
      value: "PTY",
      display: "PTY"
    },
    {
      name: "All Airports",
      city: "Paramaribo",
      country: "Metropolitan Area",
      value: "PBM",
      display: "PBM"
    },
    {
      name: "All Airports",
      city: "Paris",
      country: "Metropolitan Area",
      value: "PAR",
      display: "PAR"
    },
    {
      name: "All Airports",
      city: "Pensacola",
      country: "Metropolitan Area",
      value: "PNS",
      display: "PNS"
    },
    {
      name: "All Airports",
      city: "Philadelphia",
      country: "Metropolitan Area",
      value: "PHL",
      display: "PHL"
    },
    {
      name: "All Airports",
      city: "Phoenix",
      country: "Metropolitan Area",
      value: "PHX",
      display: "PHX"
    },
    {
      name: "All Airports",
      city: "Pilot Point",
      country: "Metropolitan Area",
      value: "PIP",
      display: "PIP"
    },
    {
      name: "All Airports",
      city: "Pittsburgh",
      country: "Metropolitan Area",
      value: "PIT",
      display: "PIT"
    },
    {
      name: "All Airports",
      city: "Prague",
      country: "Metropolitan Area",
      value: "PRG",
      display: "PRG"
    },
    {
      name: "All Airports",
      city: "Pretoria",
      country: "Metropolitan Area",
      value: "PRY",
      display: "PRY"
    },
    {
      name: "All Airports",
      city: "Prince Rupert",
      country: "Metropolitan Area",
      value: "YPR",
      display: "YPR"
    },
    {
      name: "All Airports",
      city: "Rapid City",
      country: "Metropolitan Area",
      value: "RAP",
      display: "RAP"
    },
    {
      name: "All Airports",
      city: "Reykjavik",
      country: "Metropolitan Area",
      value: "REK",
      display: "REK"
    },
    {
      name: "All Airports",
      city: "Rio de Janeiro",
      country: "Metropolitan Area",
      value: "RIO",
      display: "RIO"
    },
    {
      name: "All Airports",
      city: "Riverside",
      country: "Metropolitan Area",
      value: "RAL",
      display: "RAL"
    },
    {
      name: "All Airports",
      city: "Rome",
      country: "Metropolitan Area",
      value: "ROM",
      display: "ROM"
    },
    {
      name: "All Airports",
      city: "Saarbruecken",
      country: "Metropolitan Area",
      value: "SCN",
      display: "SCN"
    },
    {
      name: "All Airports",
      city: "Sacramento",
      country: "Metropolitan Area",
      value: "SAC",
      display: "SAC"
    },
    {
      name: "All Airports",
      city: "San Antonio",
      country: "Metropolitan Area",
      value: "SAT",
      display: "SAT"
    },
    {
      name: "All Airports",
      city: "San Diego",
      country: "Metropolitan Area",
      value: "SAN",
      display: "SAN"
    },
    {
      name: "All Airports",
      city: "San Jose",
      country: "Metropolitan Area",
      value: "SJC",
      display: "SJC"
    },
    {
      name: "All Airports",
      city: "San Juan",
      country: "Metropolitan Area",
      value: "SJU",
      display: "SJU"
    },
    {
      name: "All Airports",
      city: "Santa Cruz",
      country: "Metropolitan Area",
      value: "SRZ",
      display: "SRZ"
    },
    {
      name: "All Airports",
      city: "Santo Domingo",
      country: "Metropolitan Area",
      value: "SDQ",
      display: "SDQ"
    },
    {
      name: "All Airports",
      city: "Sao Paulo",
      country: "Metropolitan Area",
      value: "SAO",
      display: "SAO"
    },
    {
      name: "All Airports",
      city: "Sapporo",
      country: "Metropolitan Area",
      value: "SPK",
      display: "SPK"
    },
    {
      name: "All Airports",
      city: "Sargodha",
      country: "Metropolitan Area",
      value: "SGI",
      display: "SGI"
    },
    {
      name: "All Airports",
      city: "Sault Ste Marie",
      country: "Metropolitan Area",
      value: "SSM",
      display: "SSM"
    },
    {
      name: "All Airports",
      city: "Savannah",
      country: "Metropolitan Area",
      value: "SAV",
      display: "SAV"
    },
    {
      name: "All Airports",
      city: "Seattle",
      country: "Metropolitan Area",
      value: "SEA",
      display: "SEA"
    },
    {
      name: "All Airports",
      city: "Seoul",
      country: "Metropolitan Area",
      value: "SEL",
      display: "SEL"
    },
    {
      name: "All Airports",
      city: "Shanghai",
      country: "Metropolitan Area",
      value: "SHA",
      display: "SHA"
    },
    {
      name: "All Airports",
      city: "Shetland Islands",
      country: "Metropolitan Area",
      value: "SDZ",
      display: "SDZ"
    },
    {
      name: "All Airports",
      city: "Shreveport",
      country: "Metropolitan Area",
      value: "SHV",
      display: "SHV"
    },
    {
      name: "All Airports",
      city: "Singapore",
      country: "Metropolitan Area",
      value: "SIN",
      display: "SIN"
    },
    {
      name: "All Airports",
      city: "Spokane",
      country: "Metropolitan Area",
      value: "GEG",
      display: "GEG"
    },
    {
      name: "All Airports",
      city: "Springfield",
      country: "Metropolitan Area",
      value: "SFY",
      display: "SFY"
    },
    {
      name: "All Airports",
      city: "St Croix Island",
      country: "Metropolitan Area",
      value: "STX",
      display: "STX"
    },
    {
      name: "All Airports",
      city: "St Louis",
      country: "Metropolitan Area",
      value: "STL",
      display: "STL"
    },
    {
      name: "All Airports",
      city: "St Lucia",
      country: "Metropolitan Area",
      value: "SLU",
      display: "SLU"
    },
    {
      name: "All Airports",
      city: "St Petersburg",
      country: "Metropolitan Area",
      value: "LED",
      display: "LED"
    },
    {
      name: "All Airports",
      city: "St Thomas Island",
      country: "Metropolitan Area",
      value: "STT",
      display: "STT"
    },
    {
      name: "All Airports",
      city: "Stockholm",
      country: "Metropolitan Area",
      value: "STO",
      display: "STO"
    },
    {
      name: "All Airports",
      city: "Sumter",
      country: "Metropolitan Area",
      value: "SSC",
      display: "SSC"
    },
    {
      name: "All Airports",
      city: "Sydney",
      country: "Metropolitan Area",
      value: "SYD",
      display: "SYD"
    },
    {
      name: "All Airports",
      city: "Tacoma",
      country: "Metropolitan Area",
      value: "TIW",
      display: "TIW"
    },
    {
      name: "All Airports",
      city: "Taipei",
      country: "Metropolitan Area",
      value: "TPE",
      display: "TPE"
    },
    {
      name: "All Airports",
      city: "Tampa",
      country: "Metropolitan Area",
      value: "TPA",
      display: "TPA"
    },
    {
      name: "All Airports",
      city: "Tehran",
      country: "Metropolitan Area",
      value: "THR",
      display: "THR"
    },
    {
      name: "All Airports",
      city: "Tel Aviv-Yafo",
      country: "Metropolitan Area",
      value: "TLV",
      display: "TLV"
    },
    {
      name: "All Airports",
      city: "Tenerife",
      country: "Metropolitan Area",
      value: "TCI",
      display: "TCI"
    },
    {
      name: "All Airports",
      city: "Tofino",
      country: "Metropolitan Area",
      value: "YAZ",
      display: "YAZ"
    },
    {
      name: "All Airports",
      city: "Tokyo",
      country: "Metropolitan Area",
      value: "TYO",
      display: "TYO"
    },
    {
      name: "All Airports",
      city: "Toledo",
      country: "Metropolitan Area",
      value: "TOL",
      display: "TOL"
    },
    {
      name: "All Airports",
      city: "Tonopah",
      country: "Metropolitan Area",
      value: "TPH",
      display: "TPH"
    },
    {
      name: "All Airports",
      city: "Toowoomba",
      country: "Metropolitan Area",
      value: "TWB",
      display: "TWB"
    },
    {
      name: "All Airports",
      city: "Topeka",
      country: "Metropolitan Area",
      value: "TOP",
      display: "TOP"
    },
    {
      name: "All Airports",
      city: "Toronto",
      country: "Metropolitan Area",
      value: "YTO",
      display: "YTO"
    },
    {
      name: "All Airports",
      city: "Tortola",
      country: "Metropolitan Area",
      value: "TOV",
      display: "TOV"
    },
    {
      name: "All Airports",
      city: "Tucson",
      country: "Metropolitan Area",
      value: "TUS",
      display: "TUS"
    },
    {
      name: "All Airports",
      city: "Tulsa",
      country: "Metropolitan Area",
      value: "TUL",
      display: "TUL"
    },
    {
      name: "All Airports",
      city: "Valdosta",
      country: "Metropolitan Area",
      value: "VLD",
      display: "VLD"
    },
    {
      name: "All Airports",
      city: "Vancouver",
      country: "Metropolitan Area",
      value: "YVR",
      display: "YVR"
    },
    {
      name: "All Airports",
      city: "Venice",
      country: "Metropolitan Area",
      value: "VCE",
      display: "VCE"
    },
    {
      name: "All Airports",
      city: "Verona",
      country: "Metropolitan Area",
      value: "VRN",
      display: "VRN"
    },
    {
      name: "All Airports",
      city: "Victoria",
      country: "Metropolitan Area",
      value: "YYJ",
      display: "YYJ"
    },
    {
      name: "All Airports",
      city: "Waco",
      country: "Metropolitan Area",
      value: "ACT",
      display: "ACT"
    },
    {
      name: "All Airports",
      city: "Washington",
      country: "Metropolitan Area",
      value: "WAS",
      display: "WAS"
    },
    {
      name: "All Airports",
      city: "Wausau",
      country: "Metropolitan Area",
      value: "AUW",
      display: "AUW"
    },
    {
      name: "All Airports",
      city: "West Palm Beach",
      country: "Metropolitan Area",
      value: "PBI",
      display: "PBI"
    },
    {
      name: "All Airports",
      city: "Wichita",
      country: "Metropolitan Area",
      value: "ICT",
      display: "ICT"
    },
    {
      name: "All Airports",
      city: "Wichita Falls",
      country: "Metropolitan Area",
      value: "SPS",
      display: "SPS"
    },
    {
      name: "All Airports",
      city: "Windhoek",
      country: "Metropolitan Area",
      value: "WDH",
      display: "WDH"
    },
    {
      name: "All Airports",
      city: "Xi an",
      country: "Metropolitan Area",
      value: "SIA",
      display: "SIA"
    },
    {
      name: "All Airports",
      city: "Yakima",
      country: "Metropolitan Area",
      value: "YKM",
      display: "YKM"
    },
    {
      name: "All Airports",
      city: "Yaounde",
      country: "Metropolitan Area",
      value: "YAO",
      display: "YAO"
    },
    {
      name: "All Airports",
      city: "Yuma",
      country: "Metropolitan Area",
      value: "YUM",
      display: "YUM"
    },
    {
      name: "Goroka Airport",
      city: "Goroka",
      country: "Papua New Guinea",
      value: "GKA",
      display: "GKA"
    },
    {
      name: "Madang Airport",
      city: "Madang",
      country: "Papua New Guinea",
      value: "MAG",
      display: "MAG"
    },
    {
      name: "Mount Hagen Kagamuga Airport",
      city: "Mount Hagen",
      country: "Papua New Guinea",
      value: "HGU",
      display: "HGU"
    },
    {
      name: "Nadzab Airport",
      city: "Nadzab",
      country: "Papua New Guinea",
      value: "LAE",
      display: "LAE"
    },
    {
      name: "Port Moresby Jacksons International Airport",
      city: "Port Moresby",
      country: "Papua New Guinea",
      value: "POM",
      display: "POM"
    },
    {
      name: "Wewak International Airport",
      city: "Wewak",
      country: "Papua New Guinea",
      value: "WWK",
      display: "WWK"
    },
    {
      name: "Narsarsuaq Airport",
      city: "Narssarssuaq",
      country: "Greenland",
      value: "UAK",
      display: "UAK"
    },
    {
      name: "Godthaab / Nuuk Airport",
      city: "Godthaab",
      country: "Greenland",
      value: "GOH",
      display: "GOH"
    },
    {
      name: "Kangerlussuaq Airport",
      city: "Sondrestrom",
      country: "Greenland",
      value: "SFJ",
      display: "SFJ"
    },
    {
      name: "Thule Air Base",
      city: "Thule",
      country: "Greenland",
      value: "THU",
      display: "THU"
    },
    {
      name: "Akureyri Airport",
      city: "Akureyri",
      country: "Iceland",
      value: "AEY",
      display: "AEY"
    },
    {
      name: "Egilsstaoir Airport",
      city: "Egilsstadir",
      country: "Iceland",
      value: "EGS",
      display: "EGS"
    },
    {
      name: "Hornafjorour Airport",
      city: "Hofn",
      country: "Iceland",
      value: "HFN",
      display: "HFN"
    },
    {
      name: "Husavik Airport",
      city: "Husavik",
      country: "Iceland",
      value: "HZK",
      display: "HZK"
    },
    {
      name: "Isafjorour Airport",
      city: "Isafjordur",
      country: "Iceland",
      value: "IFJ",
      display: "IFJ"
    },
    {
      name: "Keflavik International Airport",
      city: "Keflavik",
      country: "Iceland",
      value: "KEF",
      display: "KEF"
    },
    {
      name: "Patreksfjorour Airport",
      city: "Patreksfjordur",
      country: "Iceland",
      value: "PFJ",
      display: "PFJ"
    },
    {
      name: "Reykjavik Airport",
      city: "Reykjavik",
      country: "Iceland",
      value: "RKV",
      display: "RKV"
    },
    {
      name: "Siglufjorour Airport",
      city: "Siglufjordur",
      country: "Iceland",
      value: "SIJ",
      display: "SIJ"
    },
    {
      name: "Vestmannaeyjar Airport",
      city: "Vestmannaeyjar",
      country: "Iceland",
      value: "VEY",
      display: "VEY"
    },
    {
      name: "Sault Ste Marie Airport",
      city: "Sault Sainte Marie",
      country: "Canada",
      value: "YAM",
      display: "YAM"
    },
    {
      name: "Winnipeg / St. Andrews Airport",
      city: "Winnipeg",
      country: "Canada",
      value: "YAV",
      display: "YAV"
    },
    {
      name: "Halifax / CFB Shearwater Heliport",
      city: "Halifax",
      country: "Canada",
      value: "YAW",
      display: "YAW"
    },
    {
      name: "St. Anthony Airport",
      city: "St. Anthony",
      country: "Canada",
      value: "YAY",
      display: "YAY"
    },
    {
      name: "Tofino / Long Beach Airport",
      city: "Tofino",
      country: "Canada",
      value: "YAZ",
      display: "YAZ"
    },
    {
      name: "Kugaaruk Airport",
      city: "Pelly Bay",
      country: "Canada",
      value: "YBB",
      display: "YBB"
    },
    {
      name: "Baie Comeau Airport",
      city: "Baie Comeau",
      country: "Canada",
      value: "YBC",
      display: "YBC"
    },
    {
      name: "CFB Bagotville",
      city: "Bagotville",
      country: "Canada",
      value: "YBG",
      display: "YBG"
    },
    {
      name: "Baker Lake Airport",
      city: "Baker Lake",
      country: "Canada",
      value: "YBK",
      display: "YBK"
    },
    {
      name: "Campbell River Airport",
      city: "Campbell River",
      country: "Canada",
      value: "YBL",
      display: "YBL"
    },
    {
      name: "Brandon Municipal Airport",
      city: "Brandon",
      country: "Canada",
      value: "YBR",
      display: "YBR"
    },
    {
      name: "Cambridge Bay Airport",
      city: "Cambridge Bay",
      country: "Canada",
      value: "YCB",
      display: "YCB"
    },
    {
      name: "Nanaimo Airport",
      city: "Nanaimo",
      country: "Canada",
      value: "YCD",
      display: "YCD"
    },
    {
      name: "Castlegar/West Kootenay Regional Airport",
      city: "Castlegar",
      country: "Canada",
      value: "YCG",
      display: "YCG"
    },
    {
      name: "Miramichi Airport",
      city: "Chatham",
      country: "Canada",
      value: "YCH",
      display: "YCH"
    },
    {
      name: "Charlo Airport",
      city: "Charlo",
      country: "Canada",
      value: "YCL",
      display: "YCL"
    },
    {
      name: "Kugluktuk Airport",
      city: "Coppermine",
      country: "Canada",
      value: "YCO",
      display: "YCO"
    },
    {
      name: "Coronation Airport",
      city: "Coronation",
      country: "Canada",
      value: "YCT",
      display: "YCT"
    },
    {
      name: "Chilliwack Airport",
      city: "Chilliwack",
      country: "Canada",
      value: "YCW",
      display: "YCW"
    },
    {
      name: "Clyde River Airport",
      city: "Clyde River",
      country: "Canada",
      value: "YCY",
      display: "YCY"
    },
    {
      name: "Coral Harbour Airport",
      city: "Coral Harbour",
      country: "Canada",
      value: "YZS",
      display: "YZS"
    },
    {
      name: "Dawson City Airport",
      city: "Dawson",
      country: "Canada",
      value: "YDA",
      display: "YDA"
    },
    {
      name: "Burwash Airport",
      city: "Burwash",
      country: "Canada",
      value: "YDB",
      display: "YDB"
    },
    {
      name: "Princeton Airport",
      city: "Princeton",
      country: "Canada",
      value: "YDC",
      display: "YDC"
    },
    {
      name: "Deer Lake Airport",
      city: "Deer Lake",
      country: "Canada",
      value: "YDF",
      display: "YDF"
    },
    {
      name: "Dease Lake Airport",
      city: "Dease Lake",
      country: "Canada",
      value: "YDL",
      display: "YDL"
    },
    {
      name: "Dauphin Barker Airport",
      city: "Dauphin",
      country: "Canada",
      value: "YDN",
      display: "YDN"
    },
    {
      name: "Dawson Creek Airport",
      city: "Dawson Creek",
      country: "Canada",
      value: "YDQ",
      display: "YDQ"
    },
    {
      name: "Edmonton International Airport",
      city: "Edmonton",
      country: "Canada",
      value: "YEG",
      display: "YEG"
    },
    {
      name: "Arviat Airport",
      city: "Eskimo Point",
      country: "Canada",
      value: "YEK",
      display: "YEK"
    },
    {
      name: "Estevan Airport",
      city: "Estevan",
      country: "Canada",
      value: "YEN",
      display: "YEN"
    },
    {
      name: "Edson Airport",
      city: "Edson",
      country: "Canada",
      value: "YET",
      display: "YET"
    },
    {
      name: "Eureka Airport",
      city: "Eureka",
      country: "Canada",
      value: "YEU",
      display: "YEU"
    },
    {
      name: "Inuvik Mike Zubko Airport",
      city: "Inuvik",
      country: "Canada",
      value: "YEV",
      display: "YEV"
    },
    {
      name: "Iqaluit Airport",
      city: "Iqaluit",
      country: "Canada",
      value: "YFB",
      display: "YFB"
    },
    {
      name: "Fredericton Airport",
      city: "Fredericton",
      country: "Canada",
      value: "YFC",
      display: "YFC"
    },
    {
      name: "Flin Flon Airport",
      city: "Flin Flon",
      country: "Canada",
      value: "YFO",
      display: "YFO"
    },
    {
      name: "Fort Resolution Airport",
      city: "Fort Resolution",
      country: "Canada",
      value: "YFR",
      display: "YFR"
    },
    {
      name: "Fort Simpson Airport",
      city: "Fort Simpson",
      country: "Canada",
      value: "YFS",
      display: "YFS"
    },
    {
      name: "Kingston Norman Rogers Airport",
      city: "Kingston",
      country: "Canada",
      value: "YGK",
      display: "YGK"
    },
    {
      name: "La Grande Riviere Airport",
      city: "La Grande Riviere",
      country: "Canada",
      value: "YGL",
      display: "YGL"
    },
    {
      name: "Gaspe (Michel-Pouliot) Airport",
      city: "Gaspe",
      country: "Canada",
      value: "YGP",
      display: "YGP"
    },
    {
      name: "Geraldton Greenstone Regional Airport",
      city: "Geraldton",
      country: "Canada",
      value: "YGQ",
      display: "YGQ"
    },
    {
      name: "Iles-de-la-Madeleine Airport",
      city: "Iles De La Madeleine",
      country: "Canada",
      value: "YGR",
      display: "YGR"
    },
    {
      name: "Hudson Bay Airport",
      city: "Hudson Bay",
      country: "Canada",
      value: "YHB",
      display: "YHB"
    },
    {
      name: "Dryden Regional Airport",
      city: "Dryden",
      country: "Canada",
      value: "YHD",
      display: "YHD"
    },
    {
      name: "Ulukhaktok Holman Airport",
      city: "Holman Island",
      country: "Canada",
      value: "YHI",
      display: "YHI"
    },
    {
      name: "Gjoa Haven Airport",
      city: "Gjoa Haven",
      country: "Canada",
      value: "YHK",
      display: "YHK"
    },
    {
      name: "John C. Munro Hamilton International Airport",
      city: "Hamilton",
      country: "Canada",
      value: "YHM",
      display: "YHM"
    },
    {
      name: "Montreal / Saint-Hubert Airport",
      city: "Montreal",
      country: "Canada",
      value: "YHU",
      display: "YHU"
    },
    {
      name: "Hay River / Merlyn Carter Airport",
      city: "Hay River",
      country: "Canada",
      value: "YHY",
      display: "YHY"
    },
    {
      name: "Halifax / Stanfield International Airport",
      city: "Halifax",
      country: "Canada",
      value: "YHZ",
      display: "YHZ"
    },
    {
      name: "Atikokan Municipal Airport",
      city: "Atikokan",
      country: "Canada",
      value: "YIB",
      display: "YIB"
    },
    {
      name: "Pond Inlet Airport",
      city: "Pond Inlet",
      country: "Canada",
      value: "YIO",
      display: "YIO"
    },
    {
      name: "St Jean Airport",
      city: "St. Jean",
      country: "Canada",
      value: "YJN",
      display: "YJN"
    },
    {
      name: "Stephenville Airport",
      city: "Stephenville",
      country: "Canada",
      value: "YJT",
      display: "YJT"
    },
    {
      name: "Kamloops Airport",
      city: "Kamloops",
      country: "Canada",
      value: "YKA",
      display: "YKA"
    },
    {
      name: "Waterloo Airport",
      city: "Waterloo",
      country: "Canada",
      value: "YKF",
      display: "YKF"
    },
    {
      name: "Schefferville Airport",
      city: "Schefferville",
      country: "Canada",
      value: "YKL",
      display: "YKL"
    },
    {
      name: "Kindersley Airport",
      city: "Kindersley",
      country: "Canada",
      value: "YKY",
      display: "YKY"
    },
    {
      name: "Buttonville Municipal Airport",
      city: "Toronto",
      country: "Canada",
      value: "YKZ",
      display: "YKZ"
    },
    {
      name: "Chapleau Airport",
      city: "Chapleau",
      country: "Canada",
      value: "YLD",
      display: "YLD"
    },
    {
      name: "Meadow Lake Airport",
      city: "Meadow Lake",
      country: "Canada",
      value: "YLJ",
      display: "YLJ"
    },
    {
      name: "Lloydminster Airport",
      city: "Lloydminster",
      country: "Canada",
      value: "YLL",
      display: "YLL"
    },
    {
      name: "Alert Airport",
      city: "Alert",
      country: "Canada",
      value: "YLT",
      display: "YLT"
    },
    {
      name: "Kelowna International Airport",
      city: "Kelowna",
      country: "Canada",
      value: "YLW",
      display: "YLW"
    },
    {
      name: "Mayo Airport",
      city: "Mayo",
      country: "Canada",
      value: "YMA",
      display: "YMA"
    },
    {
      name: "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
      city: "Moose Jaw",
      country: "Canada",
      value: "YMJ",
      display: "YMJ"
    },
    {
      name: "Fort McMurray Airport",
      city: "Fort Mcmurray",
      country: "Canada",
      value: "YMM",
      display: "YMM"
    },
    {
      name: "Moosonee Airport",
      city: "Moosonee",
      country: "Canada",
      value: "YMO",
      display: "YMO"
    },
    {
      name: "Maniwaki Airport",
      city: "Maniwaki",
      country: "Canada",
      value: "YMW",
      display: "YMW"
    },
    {
      name: "Montreal International (Mirabel) Airport",
      city: "Montreal",
      country: "Canada",
      value: "YMX",
      display: "YMX"
    },
    {
      name: "Natashquan Airport",
      city: "Natashquan",
      country: "Canada",
      value: "YNA",
      display: "YNA"
    },
    {
      name: "Ottawa / Gatineau Airport",
      city: "Gatineau",
      country: "Canada",
      value: "YND",
      display: "YND"
    },
    {
      name: "Matagami Airport",
      city: "Matagami",
      country: "Canada",
      value: "YNM",
      display: "YNM"
    },
    {
      name: "Old Crow Airport",
      city: "Old Crow",
      country: "Canada",
      value: "YOC",
      display: "YOC"
    },
    {
      name: "CFB Cold Lake",
      city: "Cold Lake",
      country: "Canada",
      value: "YOD",
      display: "YOD"
    },
    {
      name: "High Level Airport",
      city: "High Level",
      country: "Canada",
      value: "YOJ",
      display: "YOJ"
    },
    {
      name: "Ottawa Macdonald-Cartier International Airport",
      city: "Ottawa",
      country: "Canada",
      value: "YOW",
      display: "YOW"
    },
    {
      name: "Prince Albert Glass Field",
      city: "Prince Albert",
      country: "Canada",
      value: "YPA",
      display: "YPA"
    },
    {
      name: "Peace River Airport",
      city: "Peace River",
      country: "Canada",
      value: "YPE",
      display: "YPE"
    },
    {
      name: "Southport Airport",
      city: "Portage-la-prairie",
      country: "Canada",
      value: "YPG",
      display: "YPG"
    },
    {
      name: "Pickle Lake Airport",
      city: "Pickle Lake",
      country: "Canada",
      value: "YPL",
      display: "YPL"
    },
    {
      name: "Port Menier Airport",
      city: "Port Menier",
      country: "Canada",
      value: "YPN",
      display: "YPN"
    },
    {
      name: "Peterborough Airport",
      city: "Peterborough",
      country: "Canada",
      value: "YPQ",
      display: "YPQ"
    },
    {
      name: "Prince Rupert Airport",
      city: "Prince Rupert",
      country: "Canada",
      value: "YPR",
      display: "YPR"
    },
    {
      name: "Fort Chipewyan Airport",
      city: "Fort Chipewyan",
      country: "Canada",
      value: "YPY",
      display: "YPY"
    },
    {
      name: "Muskoka Airport",
      city: "Muskoka",
      country: "Canada",
      value: "YQA",
      display: "YQA"
    },
    {
      name: "Quebec Jean Lesage International Airport",
      city: "Quebec",
      country: "Canada",
      value: "YQB",
      display: "YQB"
    },
    {
      name: "Red Deer Regional Airport",
      city: "Red Deer Industrial",
      country: "Canada",
      value: "YQF",
      display: "YQF"
    },
    {
      name: "Windsor Airport",
      city: "Windsor",
      country: "Canada",
      value: "YQG",
      display: "YQG"
    },
    {
      name: "Watson Lake Airport",
      city: "Watson Lake",
      country: "Canada",
      value: "YQH",
      display: "YQH"
    },
    {
      name: "Kenora Airport",
      city: "Kenora",
      country: "Canada",
      value: "YQK",
      display: "YQK"
    },
    {
      name: "Lethbridge County Airport",
      city: "Lethbridge",
      country: "Canada",
      value: "YQL",
      display: "YQL"
    },
    {
      name: "Greater Moncton International Airport",
      city: "Moncton",
      country: "Canada",
      value: "YQM",
      display: "YQM"
    },
    {
      name: "Comox Airport",
      city: "Comox",
      country: "Canada",
      value: "YQQ",
      display: "YQQ"
    },
    {
      name: "Regina International Airport",
      city: "Regina",
      country: "Canada",
      value: "YQR",
      display: "YQR"
    },
    {
      name: "Thunder Bay Airport",
      city: "Thunder Bay",
      country: "Canada",
      value: "YQT",
      display: "YQT"
    },
    {
      name: "Grande Prairie Airport",
      city: "Grande Prairie",
      country: "Canada",
      value: "YQU",
      display: "YQU"
    },
    {
      name: "Yorkton Municipal Airport",
      city: "Yorkton",
      country: "Canada",
      value: "YQV",
      display: "YQV"
    },
    {
      name: "North Battleford Airport",
      city: "North Battleford",
      country: "Canada",
      value: "YQW",
      display: "YQW"
    },
    {
      name: "Gander International Airport",
      city: "Gander",
      country: "Canada",
      value: "YQX",
      display: "YQX"
    },
    {
      name: "Sydney / J.A. Douglas McCurdy Airport",
      city: "Sydney",
      country: "Canada",
      value: "YQY",
      display: "YQY"
    },
    {
      name: "Quesnel Airport",
      city: "Quesnel",
      country: "Canada",
      value: "YQZ",
      display: "YQZ"
    },
    {
      name: "Resolute Bay Airport",
      city: "Resolute",
      country: "Canada",
      value: "YRB",
      display: "YRB"
    },
    {
      name: "Riviere-du-Loup Airport",
      city: "Riviere Du Loup",
      country: "Canada",
      value: "YRI",
      display: "YRI"
    },
    {
      name: "Roberval Airport",
      city: "Roberval",
      country: "Canada",
      value: "YRJ",
      display: "YRJ"
    },
    {
      name: "Rocky Mountain House Airport",
      city: "Rocky Mountain House",
      country: "Canada",
      value: "YRM",
      display: "YRM"
    },
    {
      name: "Rankin Inlet Airport",
      city: "Rankin Inlet",
      country: "Canada",
      value: "YRT",
      display: "YRT"
    },
    {
      name: "Sudbury Airport",
      city: "Sudbury",
      country: "Canada",
      value: "YSB",
      display: "YSB"
    },
    {
      name: "Sherbrooke Airport",
      city: "Sherbrooke",
      country: "Canada",
      value: "YSC",
      display: "YSC"
    },
    {
      name: "Saint John Airport",
      city: "St. John",
      country: "Canada",
      value: "YSJ",
      display: "YSJ"
    },
    {
      name: "Fort Smith Airport",
      city: "Fort Smith",
      country: "Canada",
      value: "YSM",
      display: "YSM"
    },
    {
      name: "Nanisivik Airport",
      city: "Nanisivik",
      country: "Canada",
      value: "YSR",
      display: "YSR"
    },
    {
      name: "Summerside Airport",
      city: "Summerside",
      country: "Canada",
      value: "YSU",
      display: "YSU"
    },
    {
      name: "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
      city: "Sachs Harbour",
      country: "Canada",
      value: "YSY",
      display: "YSY"
    },
    {
      name: "Cape Dorset Airport",
      city: "Cape Dorset",
      country: "Canada",
      value: "YTE",
      display: "YTE"
    },
    {
      name: "Thompson Airport",
      city: "Thompson",
      country: "Canada",
      value: "YTH",
      display: "YTH"
    },
    {
      name: "CFB Trenton",
      city: "Trenton",
      country: "Canada",
      value: "YTR",
      display: "YTR"
    },
    {
      name: "Timmins/Victor M. Power",
      city: "Timmins",
      country: "Canada",
      value: "YTS",
      display: "YTS"
    },
    {
      name: "Billy Bishop Toronto City Centre Airport",
      city: "Toronto",
      country: "Canada",
      value: "YTZ",
      display: "YTZ"
    },
    {
      name: "Tuktoyaktuk Airport",
      city: "Tuktoyaktuk",
      country: "Canada",
      value: "YUB",
      display: "YUB"
    },
    {
      name: "Montreal / Pierre Elliott Trudeau International Airport",
      city: "Montreal",
      country: "Canada",
      value: "YUL",
      display: "YUL"
    },
    {
      name: "Repulse Bay Airport",
      city: "Repulse Bay",
      country: "Canada",
      value: "YUT",
      display: "YUT"
    },
    {
      name: "Hall Beach Airport",
      city: "Hall Beach",
      country: "Canada",
      value: "YUX",
      display: "YUX"
    },
    {
      name: "Rouyn Noranda Airport",
      city: "Rouyn",
      country: "Canada",
      value: "YUY",
      display: "YUY"
    },
    {
      name: "La Ronge Airport",
      city: "La Ronge",
      country: "Canada",
      value: "YVC",
      display: "YVC"
    },
    {
      name: "Vermilion Airport",
      city: "Vermillion",
      country: "Canada",
      value: "YVG",
      display: "YVG"
    },
    {
      name: "Qikiqtarjuaq Airport",
      city: "Broughton Island",
      country: "Canada",
      value: "YVM",
      display: "YVM"
    },
    {
      name: "Val-d'Or Airport",
      city: "Val D or",
      country: "Canada",
      value: "YVO",
      display: "YVO"
    },
    {
      name: "Kuujjuaq Airport",
      city: "Quujjuaq",
      country: "Canada",
      value: "YVP",
      display: "YVP"
    },
    {
      name: "Norman Wells Airport",
      city: "Norman Wells",
      country: "Canada",
      value: "YVQ",
      display: "YVQ"
    },
    {
      name: "Vancouver International Airport",
      city: "Vancouver",
      country: "Canada",
      value: "YVR",
      display: "YVR"
    },
    {
      name: "Buffalo Narrows Airport",
      city: "Buffalo Narrows",
      country: "Canada",
      value: "YVT",
      display: "YVT"
    },
    {
      name: "Wiarton Airport",
      city: "Wiarton",
      country: "Canada",
      value: "YVV",
      display: "YVV"
    },
    {
      name: "Petawawa Airport",
      city: "Petawawa",
      country: "Canada",
      value: "YWA",
      display: "YWA"
    },
    {
      name: "Winnipeg / James Armstrong Richardson International Airport",
      city: "Winnipeg",
      country: "Canada",
      value: "YWG",
      display: "YWG"
    },
    {
      name: "Wabush Airport",
      city: "Wabush",
      country: "Canada",
      value: "YWK",
      display: "YWK"
    },
    {
      name: "Williams Lake Airport",
      city: "Williams Lake",
      country: "Canada",
      value: "YWL",
      display: "YWL"
    },
    {
      name: "Wrigley Airport",
      city: "Wrigley",
      country: "Canada",
      value: "YWY",
      display: "YWY"
    },
    {
      name: "Cranbrook/Canadian Rockies International Airport",
      city: "Cranbrook",
      country: "Canada",
      value: "YXC",
      display: "YXC"
    },
    {
      name: "Edmonton City Centre (Blatchford Field) Airport",
      city: "Edmonton",
      country: "Canada",
      value: "YXD",
      display: "YXD"
    },
    {
      name: "Saskatoon John G. Diefenbaker International Airport",
      city: "Saskatoon",
      country: "Canada",
      value: "YXE",
      display: "YXE"
    },
    {
      name: "Medicine Hat Airport",
      city: "Medicine Hat",
      country: "Canada",
      value: "YXH",
      display: "YXH"
    },
    {
      name: "Fort St John Airport",
      city: "Fort Saint John",
      country: "Canada",
      value: "YXJ",
      display: "YXJ"
    },
    {
      name: "Sioux Lookout Airport",
      city: "Sioux Lookout",
      country: "Canada",
      value: "YXL",
      display: "YXL"
    },
    {
      name: "Pangnirtung Airport",
      city: "Pangnirtung",
      country: "Canada",
      value: "YXP",
      display: "YXP"
    },
    {
      name: "Earlton (Timiskaming Regional) Airport",
      city: "Earlton",
      country: "Canada",
      value: "YXR",
      display: "YXR"
    },
    {
      name: "Prince George Airport",
      city: "Prince George",
      country: "Canada",
      value: "YXS",
      display: "YXS"
    },
    {
      name: "Northwest Regional Airport Terrace-Kitimat",
      city: "Terrace",
      country: "Canada",
      value: "YXT",
      display: "YXT"
    },
    {
      name: "London Airport",
      city: "London",
      country: "Canada",
      value: "YXU",
      display: "YXU"
    },
    {
      name: "Abbotsford Airport",
      city: "Abbotsford",
      country: "Canada",
      value: "YXX",
      display: "YXX"
    },
    {
      name: "Whitehorse / Erik Nielsen International Airport",
      city: "Whitehorse",
      country: "Canada",
      value: "YXY",
      display: "YXY"
    },
    {
      name: "North Bay Airport",
      city: "North Bay",
      country: "Canada",
      value: "YYB",
      display: "YYB"
    },
    {
      name: "Calgary International Airport",
      city: "Calgary",
      country: "Canada",
      value: "YYC",
      display: "YYC"
    },
    {
      name: "Smithers Airport",
      city: "Smithers",
      country: "Canada",
      value: "YYD",
      display: "YYD"
    },
    {
      name: "Fort Nelson Airport",
      city: "Fort Nelson",
      country: "Canada",
      value: "YYE",
      display: "YYE"
    },
    {
      name: "Penticton Airport",
      city: "Penticton",
      country: "Canada",
      value: "YYF",
      display: "YYF"
    },
    {
      name: "Charlottetown Airport",
      city: "Charlottetown",
      country: "Canada",
      value: "YYG",
      display: "YYG"
    },
    {
      name: "Taloyoak Airport",
      city: "Spence Bay",
      country: "Canada",
      value: "YYH",
      display: "YYH"
    },
    {
      name: "Victoria International Airport",
      city: "Victoria",
      country: "Canada",
      value: "YYJ",
      display: "YYJ"
    },
    {
      name: "Lynn Lake Airport",
      city: "Lynn Lake",
      country: "Canada",
      value: "YYL",
      display: "YYL"
    },
    {
      name: "Swift Current Airport",
      city: "Swift Current",
      country: "Canada",
      value: "YYN",
      display: "YYN"
    },
    {
      name: "Churchill Airport",
      city: "Churchill",
      country: "Canada",
      value: "YYQ",
      display: "YYQ"
    },
    {
      name: "Goose Bay Airport",
      city: "Goose Bay",
      country: "Canada",
      value: "YYR",
      display: "YYR"
    },
    {
      name: "St. John's International Airport",
      city: "St. John s",
      country: "Canada",
      value: "YYT",
      display: "YYT"
    },
    {
      name: "Kapuskasing Airport",
      city: "Kapuskasing",
      country: "Canada",
      value: "YYU",
      display: "YYU"
    },
    {
      name: "Armstrong Airport",
      city: "Armstrong",
      country: "Canada",
      value: "YYW",
      display: "YYW"
    },
    {
      name: "Mont Joli Airport",
      city: "Mont Joli",
      country: "Canada",
      value: "YYY",
      display: "YYY"
    },
    {
      name: "Lester B. Pearson International Airport",
      city: "Toronto",
      country: "Canada",
      value: "YYZ",
      display: "YYZ"
    },
    {
      name: "Downsview Airport",
      city: "Toronto",
      country: "Canada",
      value: "YZD",
      display: "YZD"
    },
    {
      name: "Gore Bay Manitoulin Airport",
      city: "Gore Bay",
      country: "Canada",
      value: "YZE",
      display: "YZE"
    },
    {
      name: "Yellowknife Airport",
      city: "Yellowknife",
      country: "Canada",
      value: "YZF",
      display: "YZF"
    },
    {
      name: "Slave Lake Airport",
      city: "Slave Lake",
      country: "Canada",
      value: "YZH",
      display: "YZH"
    },
    {
      name: "Sandspit Airport",
      city: "Sandspit",
      country: "Canada",
      value: "YZP",
      display: "YZP"
    },
    {
      name: "Chris Hadfield Airport",
      city: "Sarnia",
      country: "Canada",
      value: "YZR",
      display: "YZR"
    },
    {
      name: "Port Hardy Airport",
      city: "Port Hardy",
      country: "Canada",
      value: "YZT",
      display: "YZT"
    },
    {
      name: "Whitecourt Airport",
      city: "Whitecourt",
      country: "Canada",
      value: "YZU",
      display: "YZU"
    },
    {
      name: "Sept-Iles Airport",
      city: "Sept-iles",
      country: "Canada",
      value: "YZV",
      display: "YZV"
    },
    {
      name: "Teslin Airport",
      city: "Teslin",
      country: "Canada",
      value: "YZW",
      display: "YZW"
    },
    {
      name: "CFB Greenwood",
      city: "Greenwood",
      country: "Canada",
      value: "YZX",
      display: "YZX"
    },
    {
      name: "Faro Airport",
      city: "Faro",
      country: "Canada",
      value: "ZFA",
      display: "ZFA"
    },
    {
      name: "Fort Mcpherson Airport",
      city: "Fort Mcpherson",
      country: "Canada",
      value: "ZFM",
      display: "ZFM"
    },
    {
      name: "Soummam Airport",
      city: "Bejaja",
      country: "Algeria",
      value: "BJA",
      display: "BJA"
    },
    {
      name: "Houari Boumediene Airport",
      city: "Algier",
      country: "Algeria",
      value: "ALG",
      display: "ALG"
    },
    {
      name: "Djanet Inedbirene Airport",
      city: "Djanet",
      country: "Algeria",
      value: "DJG",
      display: "DJG"
    },
    {
      name: "Boufarik Airport",
      city: "Boufarik",
      country: "Algeria",
      value: "QFD",
      display: "QFD"
    },
    {
      name: "Illizi Takhamalt Airport",
      city: "Illizi",
      country: "Algeria",
      value: "VVZ",
      display: "VVZ"
    },
    {
      name: "Aguenar - Hadj Bey Akhamok Airport",
      city: "Tamanrasset",
      country: "Algeria",
      value: "TMR",
      display: "TMR"
    },
    {
      name: "Jijel Ferhat Abbas Airport",
      city: "Jijel",
      country: "Algeria",
      value: "GJL",
      display: "GJL"
    },
    {
      name: "Annaba Airport",
      city: "Annaba",
      country: "Algeria",
      value: "AAE",
      display: "AAE"
    },
    {
      name: "Mohamed Boudiaf International Airport",
      city: "Constantine",
      country: "Algeria",
      value: "CZL",
      display: "CZL"
    },
    {
      name: "Cheikh Larbi Tebessi Airport",
      city: "Tebessa",
      country: "Algeria",
      value: "TEE",
      display: "TEE"
    },
    {
      name: "Hassi R'Mel Airport",
      city: "Tilrempt",
      country: "Algeria",
      value: "HRM",
      display: "HRM"
    },
    {
      name: "Bou Chekif Airport",
      city: "Tiaret",
      country: "Algeria",
      value: "TID",
      display: "TID"
    },
    {
      name: "Tindouf Airport",
      city: "Tindouf",
      country: "Algeria",
      value: "TIN",
      display: "TIN"
    },
    {
      name: "Ech Cheliff Airport",
      city: "Ech-cheliff",
      country: "Algeria",
      value: "QAS",
      display: "QAS"
    },
    {
      name: "Tafaraoui Airport",
      city: "Oran",
      country: "Algeria",
      value: "TAF",
      display: "TAF"
    },
    {
      name: "Zenata - Messali El Hadj Airport",
      city: "Tlemcen",
      country: "Algeria",
      value: "TLM",
      display: "TLM"
    },
    {
      name: "Es Senia Airport",
      city: "Oran",
      country: "Algeria",
      value: "ORN",
      display: "ORN"
    },
    {
      name: "Ghriss Airport",
      city: "Ghriss",
      country: "Algeria",
      value: "MUW",
      display: "MUW"
    },
    {
      name: "Touat Cheikh Sidi Mohamed Belkebir Airport",
      city: "Adrar",
      country: "Algeria",
      value: "AZR",
      display: "AZR"
    },
    {
      name: "Biskra Airport",
      city: "Biskra",
      country: "Algeria",
      value: "BSK",
      display: "BSK"
    },
    {
      name: "El Golea Airport",
      city: "El Golea",
      country: "Algeria",
      value: "ELG",
      display: "ELG"
    },
    {
      name: "Noumerat - Moufdi Zakaria Airport",
      city: "Ghardaia",
      country: "Algeria",
      value: "GHA",
      display: "GHA"
    },
    {
      name: "Oued Irara Airport",
      city: "Hassi Messaoud",
      country: "Algeria",
      value: "HME",
      display: "HME"
    },
    {
      name: "In Salah Airport",
      city: "In Salah",
      country: "Algeria",
      value: "INZ",
      display: "INZ"
    },
    {
      name: "Touggourt Sidi Madhi Airport",
      city: "Touggourt",
      country: "Algeria",
      value: "TGR",
      display: "TGR"
    },
    {
      name: "Laghouat Airport",
      city: "Laghouat",
      country: "Algeria",
      value: "LOO",
      display: "LOO"
    },
    {
      name: "Timimoun Airport",
      city: "Timimoun",
      country: "Algeria",
      value: "TMX",
      display: "TMX"
    },
    {
      name: "Ain el Beida Airport",
      city: "Ouargla",
      country: "Algeria",
      value: "OGX",
      display: "OGX"
    },
    {
      name: "In Amenas Airport",
      city: "Zarzaitine",
      country: "Algeria",
      value: "IAM",
      display: "IAM"
    },
    {
      name: "Cadjehoun Airport",
      city: "Cotonou",
      country: "Benin",
      value: "COO",
      display: "COO"
    },
    {
      name: "Ouagadougou Airport",
      city: "Ouagadougou",
      country: "Burkina Faso",
      value: "OUA",
      display: "OUA"
    },
    {
      name: "Bobo Dioulasso Airport",
      city: "Bobo-dioulasso",
      country: "Burkina Faso",
      value: "BOY",
      display: "BOY"
    },
    {
      name: "Kotoka International Airport",
      city: "Accra",
      country: "Ghana",
      value: "ACC",
      display: "ACC"
    },
    {
      name: "Tamale Airport",
      city: "Tamale",
      country: "Ghana",
      value: "TML",
      display: "TML"
    },
    {
      name: "Sunyani Airport",
      city: "Sunyani",
      country: "Ghana",
      value: "NYI",
      display: "NYI"
    },
    {
      name: "Takoradi Airport",
      city: "Takoradi",
      country: "Ghana",
      value: "TKD",
      display: "TKD"
    },
    {
      name: "Port Bouet Airport",
      city: "Abidjan",
      country: "Cote d Ivoire",
      value: "ABJ",
      display: "ABJ"
    },
    {
      name: "Bouake Airport",
      city: "Bouake",
      country: "Cote d Ivoire",
      value: "BYK",
      display: "BYK"
    },
    {
      name: "Daloa Airport",
      city: "Daloa",
      country: "Cote d Ivoire",
      value: "DJO",
      display: "DJO"
    },
    {
      name: "Korhogo Airport",
      city: "Korhogo",
      country: "Cote d Ivoire",
      value: "HGO",
      display: "HGO"
    },
    {
      name: "Man Airport",
      city: "Man",
      country: "Cote d Ivoire",
      value: "MJC",
      display: "MJC"
    },
    {
      name: "San Pedro Airport",
      city: "San Pedro",
      country: "Cote d Ivoire",
      value: "SPY",
      display: "SPY"
    },
    {
      name: "Yamoussoukro Airport",
      city: "Yamoussoukro",
      country: "Cote d Ivoire",
      value: "ASK",
      display: "ASK"
    },
    {
      name: "Nnamdi Azikiwe International Airport",
      city: "Abuja",
      country: "Nigeria",
      value: "ABV",
      display: "ABV"
    },
    {
      name: "Akure Airport",
      city: "Akure",
      country: "Nigeria",
      value: "AKR",
      display: "AKR"
    },
    {
      name: "Benin Airport",
      city: "Benin",
      country: "Nigeria",
      value: "BNI",
      display: "BNI"
    },
    {
      name: "Margaret Ekpo International Airport",
      city: "Calabar",
      country: "Nigeria",
      value: "CBQ",
      display: "CBQ"
    },
    {
      name: "Akanu Ibiam International Airport",
      city: "Enugu",
      country: "Nigeria",
      value: "ENU",
      display: "ENU"
    },
    {
      name: "Gusau Airport",
      city: "Gusau",
      country: "Nigeria",
      value: "QUS",
      display: "QUS"
    },
    {
      name: "Ibadan Airport",
      city: "Ibadan",
      country: "Nigeria",
      value: "IBA",
      display: "IBA"
    },
    {
      name: "Ilorin International Airport",
      city: "Ilorin",
      country: "Nigeria",
      value: "ILR",
      display: "ILR"
    },
    {
      name: "Yakubu Gowon Airport",
      city: "Jos",
      country: "Nigeria",
      value: "JOS",
      display: "JOS"
    },
    {
      name: "Kaduna Airport",
      city: "Kaduna",
      country: "Nigeria",
      value: "KAD",
      display: "KAD"
    },
    {
      name: "Mallam Aminu International Airport",
      city: "Kano",
      country: "Nigeria",
      value: "KAN",
      display: "KAN"
    },
    {
      name: "Maiduguri International Airport",
      city: "Maiduguri",
      country: "Nigeria",
      value: "MIU",
      display: "MIU"
    },
    {
      name: "Makurdi Airport",
      city: "Makurdi",
      country: "Nigeria",
      value: "MDI",
      display: "MDI"
    },
    {
      name: "Murtala Muhammed International Airport",
      city: "Lagos",
      country: "Nigeria",
      value: "LOS",
      display: "LOS"
    },
    {
      name: "Minna Airport",
      city: "Minna",
      country: "Nigeria",
      value: "MXJ",
      display: "MXJ"
    },
    {
      name: "Port Harcourt International Airport",
      city: "Port Hartcourt",
      country: "Nigeria",
      value: "PHC",
      display: "PHC"
    },
    {
      name: "Sadiq Abubakar III International Airport",
      city: "Sokoto",
      country: "Nigeria",
      value: "SKO",
      display: "SKO"
    },
    {
      name: "Yola Airport",
      city: "Yola",
      country: "Nigeria",
      value: "YOL",
      display: "YOL"
    },
    {
      name: "Zaria Airport",
      city: "Zaria",
      country: "Nigeria",
      value: "ZAR",
      display: "ZAR"
    },
    {
      name: "Maradi Airport",
      city: "Maradi",
      country: "Niger",
      value: "MFQ",
      display: "MFQ"
    },
    {
      name: "Diori Hamani International Airport",
      city: "Niamey",
      country: "Niger",
      value: "NIM",
      display: "NIM"
    },
    {
      name: "Tahoua Airport",
      city: "Tahoua",
      country: "Niger",
      value: "THZ",
      display: "THZ"
    },
    {
      name: "Mano Dayak International Airport",
      city: "Agadez",
      country: "Niger",
      value: "AJY",
      display: "AJY"
    },
    {
      name: "Zinder Airport",
      city: "Zinder",
      country: "Niger",
      value: "ZND",
      display: "ZND"
    },
    {
      name: "Monastir Habib Bourguiba International Airport",
      city: "Monastir",
      country: "Tunisia",
      value: "MIR",
      display: "MIR"
    },
    {
      name: "Tunis Carthage International Airport",
      city: "Tunis",
      country: "Tunisia",
      value: "TUN",
      display: "TUN"
    },
    {
      name: "Gafsa Ksar International Airport",
      city: "Gafsa",
      country: "Tunisia",
      value: "GAF",
      display: "GAF"
    },
    {
      name: "Gabes Matmata International Airport",
      city: "Gabes",
      country: "Tunisia",
      value: "GAE",
      display: "GAE"
    },
    {
      name: "Djerba Zarzis International Airport",
      city: "Djerba",
      country: "Tunisia",
      value: "DJE",
      display: "DJE"
    },
    {
      name: "El Borma Airport",
      city: "El Borma",
      country: "Tunisia",
      value: "EBM",
      display: "EBM"
    },
    {
      name: "Sfax Thyna International Airport",
      city: "Sfax",
      country: "Tunisia",
      value: "SFA",
      display: "SFA"
    },
    {
      name: "Tozeur Nefta International Airport",
      city: "Tozeur",
      country: "Tunisia",
      value: "TOE",
      display: "TOE"
    },
    {
      name: "Niamtougou International Airport",
      city: "Niatougou",
      country: "Togo",
      value: "LRL",
      display: "LRL"
    },
    {
      name: "Lome-Tokoin Airport",
      city: "Lome",
      country: "Togo",
      value: "LFW",
      display: "LFW"
    },
    {
      name: "Antwerp International Airport (Deurne)",
      city: "Antwerp",
      country: "Belgium",
      value: "ANR",
      display: "ANR"
    },
    {
      name: "Brussels Airport",
      city: "Brussels",
      country: "Belgium",
      value: "BRU",
      display: "BRU"
    },
    {
      name: "Brussels South Charleroi Airport",
      city: "Charleroi",
      country: "Belgium",
      value: "CRL",
      display: "CRL"
    },
    {
      name: "Wevelgem Airport",
      city: "Kortrijk-vevelgem",
      country: "Belgium",
      value: "QKT",
      display: "QKT"
    },
    {
      name: "Liege Airport",
      city: "Liege",
      country: "Belgium",
      value: "LGG",
      display: "LGG"
    },
    {
      name: "Ostend-Bruges International Airport",
      city: "Ostend",
      country: "Belgium",
      value: "OST",
      display: "OST"
    },
    {
      name: "Altenburg-Nobitz Airport",
      city: "Altenburg",
      country: "Germany",
      value: "AOC",
      display: "AOC"
    },
    {
      name: "Barth Airport",
      city: "Barth",
      country: "Germany",
      value: "BBH",
      display: "BBH"
    },
    {
      name: "Berlin-Schonefeld International Airport",
      city: "Berlin",
      country: "Germany",
      value: "SXF",
      display: "SXF"
    },
    {
      name: "Dresden Airport",
      city: "Dresden",
      country: "Germany",
      value: "DRS",
      display: "DRS"
    },
    {
      name: "Erfurt Airport",
      city: "Erfurt",
      country: "Germany",
      value: "ERF",
      display: "ERF"
    },
    {
      name: "Frankfurt am Main International Airport",
      city: "Frankfurt",
      country: "Germany",
      value: "FRA",
      display: "FRA"
    },
    {
      name: "Munster Osnabruck Airport",
      city: "Munster",
      country: "Germany",
      value: "FMO",
      display: "FMO"
    },
    {
      name: "Hamburg Airport",
      city: "Hamburg",
      country: "Germany",
      value: "HAM",
      display: "HAM"
    },
    {
      name: "Berlin Brandenburg Airport",
      city: "Berlin",
      country: "Germany",
      value: "BER",
      display: "BER"
    },
    {
      name: "Cologne Bonn Airport",
      city: "Cologne",
      country: "Germany",
      value: "CGN",
      display: "CGN"
    },
    {
      name: "Dusseldorf International Airport",
      city: "Duesseldorf",
      country: "Germany",
      value: "DUS",
      display: "DUS"
    },
    {
      name: "Munich International Airport",
      city: "Munich",
      country: "Germany",
      value: "MUC",
      display: "MUC"
    },
    {
      name: "Nuremberg Airport",
      city: "Nuernberg",
      country: "Germany",
      value: "NUE",
      display: "NUE"
    },
    {
      name: "Leipzig Halle Airport",
      city: "Leipzig",
      country: "Germany",
      value: "LEJ",
      display: "LEJ"
    },
    {
      name: "Saarbrucken Airport",
      city: "Saarbruecken",
      country: "Germany",
      value: "SCN",
      display: "SCN"
    },
    {
      name: "Stuttgart Airport",
      city: "Stuttgart",
      country: "Germany",
      value: "STR",
      display: "STR"
    },
    {
      name: "Berlin-Tegel International Airport",
      city: "Berlin",
      country: "Germany",
      value: "TXL",
      display: "TXL"
    },
    {
      name: "Hannover Airport",
      city: "Hannover",
      country: "Germany",
      value: "HAJ",
      display: "HAJ"
    },
    {
      name: "Bremen Airport",
      city: "Bremen",
      country: "Germany",
      value: "BRE",
      display: "BRE"
    },
    {
      name: "Frankfurt-Egelsbach Airport",
      city: "Egelsbach",
      country: "Germany",
      value: "QEF",
      display: "QEF"
    },
    {
      name: "Frankfurt-Hahn Airport",
      city: "Hahn",
      country: "Germany",
      value: "HHN",
      display: "HHN"
    },
    {
      name: "Mannheim-City Airport",
      city: "Mannheim",
      country: "Germany",
      value: "MHG",
      display: "MHG"
    },
    {
      name: "Hamburg-Finkenwerder Airport",
      city: "Hamburg",
      country: "Germany",
      value: "XFW",
      display: "XFW"
    },
    {
      name: "Kiel-Holtenau Airport",
      city: "Kiel",
      country: "Germany",
      value: "KEL",
      display: "KEL"
    },
    {
      name: "Lubeck Blankensee Airport",
      city: "Luebeck",
      country: "Germany",
      value: "LBC",
      display: "LBC"
    },
    {
      name: "Arnsberg-Menden Airport",
      city: "Arnsberg",
      country: "Germany",
      value: "ZCA",
      display: "ZCA"
    },
    {
      name: "Essen Mulheim Airport",
      city: "Essen",
      country: "Germany",
      value: "ESS",
      display: "ESS"
    },
    {
      name: "Monchengladbach Airport",
      city: "Moenchengladbach",
      country: "Germany",
      value: "MGL",
      display: "MGL"
    },
    {
      name: "Paderborn Lippstadt Airport",
      city: "Paderborn",
      country: "Germany",
      value: "PAD",
      display: "PAD"
    },
    {
      name: "Dortmund Airport",
      city: "Dortmund",
      country: "Germany",
      value: "DTM",
      display: "DTM"
    },
    {
      name: "Augsburg Airport",
      city: "Augsburg",
      country: "Germany",
      value: "AGB",
      display: "AGB"
    },
    {
      name: "Oberpfaffenhofen Airport",
      city: "Oberpfaffenhofen",
      country: "Germany",
      value: "OBF",
      display: "OBF"
    },
    {
      name: "Straubing Airport",
      city: "Straubing",
      country: "Germany",
      value: "RBM",
      display: "RBM"
    },
    {
      name: "Friedrichshafen Airport",
      city: "Friedrichshafen",
      country: "Germany",
      value: "FDH",
      display: "FDH"
    },
    {
      name: "Schwerin Parchim Airport",
      city: "Parchim",
      country: "Germany",
      value: "SZW",
      display: "SZW"
    },
    {
      name: "Stendal-Borstel Airport",
      city: "Stendal",
      country: "Germany",
      value: "ZSN",
      display: "ZSN"
    },
    {
      name: "Bayreuth Airport",
      city: "Bayreuth",
      country: "Germany",
      value: "BYU",
      display: "BYU"
    },
    {
      name: "Hof-Plauen Airport",
      city: "Hof",
      country: "Germany",
      value: "HOQ",
      display: "HOQ"
    },
    {
      name: "Koblenz-Winningen Airport",
      city: "Koblenz",
      country: "Germany",
      value: "ZNV",
      display: "ZNV"
    },
    {
      name: "Trier-Fohren Airport",
      city: "Trier",
      country: "Germany",
      value: "ZQF",
      display: "ZQF"
    },
    {
      name: "Speyer Airport",
      city: "Speyer",
      country: "Germany",
      value: "ZQC",
      display: "ZQC"
    },
    {
      name: "Zweibrucken Airport",
      city: "Zweibruecken",
      country: "Germany",
      value: "ZQW",
      display: "ZQW"
    },
    {
      name: "Donaueschingen-Villingen Airport",
      city: "Donaueschingen",
      country: "Germany",
      value: "ZQL",
      display: "ZQL"
    },
    {
      name: "Braunschweig Wolfsburg Airport",
      city: "Braunschweig",
      country: "Germany",
      value: "BWE",
      display: "BWE"
    },
    {
      name: "Kassel-Calden Airport",
      city: "Kassel",
      country: "Germany",
      value: "KSF",
      display: "KSF"
    },
    {
      name: "Bremerhaven Airport",
      city: "Bremerhaven",
      country: "Germany",
      value: "BRV",
      display: "BRV"
    },
    {
      name: "Emden Airport",
      city: "Emden",
      country: "Germany",
      value: "EME",
      display: "EME"
    },
    {
      name: "Wilhelmshaven-Mariensiel Airport",
      city: "Wilhelmshaven",
      country: "Germany",
      value: "WVN",
      display: "WVN"
    },
    {
      name: "Borkum Airport",
      city: "Borkum",
      country: "Germany",
      value: "BMK",
      display: "BMK"
    },
    {
      name: "Norderney Airport",
      city: "Norderney",
      country: "Germany",
      value: "NRD",
      display: "NRD"
    },
    {
      name: "Flensburg-Schaferhaus Airport",
      city: "Flensburg",
      country: "Germany",
      value: "FLF",
      display: "FLF"
    },
    {
      name: "Westerland Sylt Airport",
      city: "Westerland",
      country: "Germany",
      value: "GWT",
      display: "GWT"
    },
    {
      name: "Kardla Airport",
      city: "Kardla",
      country: "Estonia",
      value: "KDL",
      display: "KDL"
    },
    {
      name: "Kuressaare Airport",
      city: "Kuressaare",
      country: "Estonia",
      value: "URE",
      display: "URE"
    },
    {
      name: "Parnu Airport",
      city: "Parnu",
      country: "Estonia",
      value: "EPU",
      display: "EPU"
    },
    {
      name: "Lennart Meri Tallinn Airport",
      city: "Tallinn-ulemiste International",
      country: "Estonia",
      value: "TLL",
      display: "TLL"
    },
    {
      name: "Tartu Airport",
      city: "Tartu",
      country: "Estonia",
      value: "TAY",
      display: "TAY"
    },
    {
      name: "Enontekio Airport",
      city: "Enontekio",
      country: "Finland",
      value: "ENF",
      display: "ENF"
    },
    {
      name: "Halli Airport",
      city: "Halli",
      country: "Finland",
      value: "KEV",
      display: "KEV"
    },
    {
      name: "Helsinki Malmi Airport",
      city: "Helsinki",
      country: "Finland",
      value: "HEM",
      display: "HEM"
    },
    {
      name: "Helsinki Vantaa Airport",
      city: "Helsinki",
      country: "Finland",
      value: "HEL",
      display: "HEL"
    },
    {
      name: "Hyvinkaa Airfield",
      city: "Hyvinkaa",
      country: "Finland",
      value: "HYV",
      display: "HYV"
    },
    {
      name: "Ivalo Airport",
      city: "Ivalo",
      country: "Finland",
      value: "IVL",
      display: "IVL"
    },
    {
      name: "Joensuu Airport",
      city: "Joensuu",
      country: "Finland",
      value: "JOE",
      display: "JOE"
    },
    {
      name: "Jyvaskyla Airport",
      city: "Jyvaskyla",
      country: "Finland",
      value: "JYV",
      display: "JYV"
    },
    {
      name: "Kauhava Airport",
      city: "Kauhava",
      country: "Finland",
      value: "KAU",
      display: "KAU"
    },
    {
      name: "Kemi-Tornio Airport",
      city: "Kemi",
      country: "Finland",
      value: "KEM",
      display: "KEM"
    },
    {
      name: "Kajaani Airport",
      city: "Kajaani",
      country: "Finland",
      value: "KAJ",
      display: "KAJ"
    },
    {
      name: "Kokkola-Pietarsaari Airport",
      city: "Kruunupyy",
      country: "Finland",
      value: "KOK",
      display: "KOK"
    },
    {
      name: "Kuusamo Airport",
      city: "Kuusamo",
      country: "Finland",
      value: "KAO",
      display: "KAO"
    },
    {
      name: "Kittila Airport",
      city: "Kittila",
      country: "Finland",
      value: "KTT",
      display: "KTT"
    },
    {
      name: "Kuopio Airport",
      city: "Kuopio",
      country: "Finland",
      value: "KUO",
      display: "KUO"
    },
    {
      name: "Lappeenranta Airport",
      city: "Lappeenranta",
      country: "Finland",
      value: "LPP",
      display: "LPP"
    },
    {
      name: "Mariehamn Airport",
      city: "Mariehamn",
      country: "Finland",
      value: "MHQ",
      display: "MHQ"
    },
    {
      name: "Mikkeli Airport",
      city: "Mikkeli",
      country: "Finland",
      value: "MIK",
      display: "MIK"
    },
    {
      name: "Oulu Airport",
      city: "Oulu",
      country: "Finland",
      value: "OUL",
      display: "OUL"
    },
    {
      name: "Pori Airport",
      city: "Pori",
      country: "Finland",
      value: "POR",
      display: "POR"
    },
    {
      name: "Rovaniemi Airport",
      city: "Rovaniemi",
      country: "Finland",
      value: "RVN",
      display: "RVN"
    },
    {
      name: "Savonlinna Airport",
      city: "Savonlinna",
      country: "Finland",
      value: "SVL",
      display: "SVL"
    },
    {
      name: "Sodankyla Airport",
      city: "Sodankyla",
      country: "Finland",
      value: "SOT",
      display: "SOT"
    },
    {
      name: "Tampere-Pirkkala Airport",
      city: "Tampere",
      country: "Finland",
      value: "TMP",
      display: "TMP"
    },
    {
      name: "Turku Airport",
      city: "Turku",
      country: "Finland",
      value: "TKU",
      display: "TKU"
    },
    {
      name: "Utti Air Base",
      city: "Utti",
      country: "Finland",
      value: "QVY",
      display: "QVY"
    },
    {
      name: "Vaasa Airport",
      city: "Vaasa",
      country: "Finland",
      value: "VAA",
      display: "VAA"
    },
    {
      name: "Varkaus Airport",
      city: "Varkaus",
      country: "Finland",
      value: "VRK",
      display: "VRK"
    },
    {
      name: "Belfast International Airport",
      city: "Belfast",
      country: "United Kingdom",
      value: "BFS",
      display: "BFS"
    },
    {
      name: "St Angelo Airport",
      city: "Enniskillen",
      country: "United Kingdom",
      value: "ENK",
      display: "ENK"
    },
    {
      name: "George Best Belfast City Airport",
      city: "Belfast",
      country: "United Kingdom",
      value: "BHD",
      display: "BHD"
    },
    {
      name: "City of Derry Airport",
      city: "Londonderry",
      country: "United Kingdom",
      value: "LDY",
      display: "LDY"
    },
    {
      name: "Birmingham International Airport",
      city: "Birmingham",
      country: "United Kingdom",
      value: "BHX",
      display: "BHX"
    },
    {
      name: "Coventry Airport",
      city: "Coventry",
      country: "United Kingdom",
      value: "CVT",
      display: "CVT"
    },
    {
      name: "Gloucestershire Airport",
      city: "Golouchestershire",
      country: "United Kingdom",
      value: "GLO",
      display: "GLO"
    },
    {
      name: "Manchester Airport",
      city: "Manchester",
      country: "United Kingdom",
      value: "MAN",
      display: "MAN"
    },
    {
      name: "Newquay Cornwall Airport",
      city: "Newquai",
      country: "United Kingdom",
      value: "NQY",
      display: "NQY"
    },
    {
      name: "RAF Lyneham",
      city: "Lyneham",
      country: "United Kingdom",
      value: "LYE",
      display: "LYE"
    },
    {
      name: "RNAS Yeovilton",
      city: "Yeovilton",
      country: "United Kingdom",
      value: "YEO",
      display: "YEO"
    },
    {
      name: "Cardiff International Airport",
      city: "Cardiff",
      country: "United Kingdom",
      value: "CWL",
      display: "CWL"
    },
    {
      name: "Swansea Airport",
      city: "Swansea",
      country: "United Kingdom",
      value: "SWS",
      display: "SWS"
    },
    {
      name: "Bristol Airport",
      city: "Bristol",
      country: "United Kingdom",
      value: "BRS",
      display: "BRS"
    },
    {
      name: "Liverpool John Lennon Airport",
      city: "Liverpool",
      country: "United Kingdom",
      value: "LPL",
      display: "LPL"
    },
    {
      name: "London Luton Airport",
      city: "London",
      country: "United Kingdom",
      value: "LTN",
      display: "LTN"
    },
    {
      name: "Plymouth City Airport",
      city: "Plymouth",
      country: "United Kingdom",
      value: "PLH",
      display: "PLH"
    },
    {
      name: "Bournemouth Airport",
      city: "Bournemouth",
      country: "United Kingdom",
      value: "BOH",
      display: "BOH"
    },
    {
      name: "Southampton Airport",
      city: "Southampton",
      country: "United Kingdom",
      value: "SOU",
      display: "SOU"
    },
    {
      name: "Lasham Airport",
      city: "Lasham",
      country: "United Kingdom",
      value: "QLA",
      display: "QLA"
    },
    {
      name: "Alderney Airport",
      city: "Alderney",
      country: "Guernsey",
      value: "ACI",
      display: "ACI"
    },
    {
      name: "Guernsey Airport",
      city: "Guernsey",
      country: "Guernsey",
      value: "GCI",
      display: "GCI"
    },
    {
      name: "Jersey Airport",
      city: "Jersey",
      country: "Jersey",
      value: "JER",
      display: "JER"
    },
    {
      name: "Shoreham Airport",
      city: "Shoreham By Sea",
      country: "United Kingdom",
      value: "ESH",
      display: "ESH"
    },
    {
      name: "London Biggin Hill Airport",
      city: "Biggin Hill",
      country: "United Kingdom",
      value: "BQH",
      display: "BQH"
    },
    {
      name: "London Gatwick Airport",
      city: "London",
      country: "United Kingdom",
      value: "LGW",
      display: "LGW"
    },
    {
      name: "London City Airport",
      city: "London",
      country: "United Kingdom",
      value: "LCY",
      display: "LCY"
    },
    {
      name: "Farnborough Airport",
      city: "Farnborough",
      country: "United Kingdom",
      value: "FAB",
      display: "FAB"
    },
    {
      name: "Blackbushe Airport",
      city: "Blackbushe",
      country: "United Kingdom",
      value: "BBS",
      display: "BBS"
    },
    {
      name: "London Heathrow Airport",
      city: "London",
      country: "United Kingdom",
      value: "LHR",
      display: "LHR"
    },
    {
      name: "Southend Airport",
      city: "Southend",
      country: "United Kingdom",
      value: "SEN",
      display: "SEN"
    },
    {
      name: "Lydd Airport",
      city: "Lydd",
      country: "United Kingdom",
      value: "LYX",
      display: "LYX"
    },
    {
      name: "Kent International Airport",
      city: "Manston",
      country: "United Kingdom",
      value: "MSE",
      display: "MSE"
    },
    {
      name: "Carlisle Airport",
      city: "Carlisle",
      country: "United Kingdom",
      value: "CAX",
      display: "CAX"
    },
    {
      name: "Blackpool International Airport",
      city: "Blackpool",
      country: "United Kingdom",
      value: "BLK",
      display: "BLK"
    },
    {
      name: "Humberside Airport",
      city: "Humberside",
      country: "United Kingdom",
      value: "HUY",
      display: "HUY"
    },
    {
      name: "Barrow Walney Island Airport",
      city: "Barrow Island",
      country: "United Kingdom",
      value: "BWF",
      display: "BWF"
    },
    {
      name: "Leeds Bradford Airport",
      city: "Leeds",
      country: "United Kingdom",
      value: "LBA",
      display: "LBA"
    },
    {
      name: "Hawarden Airport",
      city: "Hawarden",
      country: "United Kingdom",
      value: "CEG",
      display: "CEG"
    },
    {
      name: "Isle of Man Airport",
      city: "Isle Of Man",
      country: "Isle of Man",
      value: "IOM",
      display: "IOM"
    },
    {
      name: "Newcastle Airport",
      city: "Newcastle",
      country: "United Kingdom",
      value: "NCL",
      display: "NCL"
    },
    {
      name: "Durham Tees Valley Airport",
      city: "Teesside",
      country: "United Kingdom",
      value: "MME",
      display: "MME"
    },
    {
      name: "East Midlands Airport",
      city: "East Midlands",
      country: "United Kingdom",
      value: "EMA",
      display: "EMA"
    },
    {
      name: "Kirkwall Airport",
      city: "Kirkwall",
      country: "United Kingdom",
      value: "KOI",
      display: "KOI"
    },
    {
      name: "Sumburgh Airport",
      city: "Sumburgh",
      country: "United Kingdom",
      value: "LSI",
      display: "LSI"
    },
    {
      name: "Wick Airport",
      city: "Wick",
      country: "United Kingdom",
      value: "WIC",
      display: "WIC"
    },
    {
      name: "Aberdeen Dyce Airport",
      city: "Aberdeen",
      country: "United Kingdom",
      value: "ABZ",
      display: "ABZ"
    },
    {
      name: "Inverness Airport",
      city: "Inverness",
      country: "United Kingdom",
      value: "INV",
      display: "INV"
    },
    {
      name: "Glasgow International Airport",
      city: "Glasgow",
      country: "United Kingdom",
      value: "GLA",
      display: "GLA"
    },
    {
      name: "Edinburgh Airport",
      city: "Edinburgh",
      country: "United Kingdom",
      value: "EDI",
      display: "EDI"
    },
    {
      name: "Islay Airport",
      city: "Islay",
      country: "United Kingdom",
      value: "ILY",
      display: "ILY"
    },
    {
      name: "Glasgow Prestwick Airport",
      city: "Prestwick",
      country: "United Kingdom",
      value: "PIK",
      display: "PIK"
    },
    {
      name: "Benbecula Airport",
      city: "Benbecula",
      country: "United Kingdom",
      value: "BEB",
      display: "BEB"
    },
    {
      name: "Scatsta Airport",
      city: "Scatsta",
      country: "United Kingdom",
      value: "SCS",
      display: "SCS"
    },
    {
      name: "Dundee Airport",
      city: "Dundee",
      country: "United Kingdom",
      value: "DND",
      display: "DND"
    },
    {
      name: "Stornoway Airport",
      city: "Stornoway",
      country: "United Kingdom",
      value: "SYY",
      display: "SYY"
    },
    {
      name: "Tiree Airport",
      city: "Tiree",
      country: "United Kingdom",
      value: "TRE",
      display: "TRE"
    },
    {
      name: "RAF Leuchars",
      city: "Leuchars",
      country: "United Kingdom",
      value: "ADX",
      display: "ADX"
    },
    {
      name: "RAF Lossiemouth",
      city: "Lossiemouth",
      country: "United Kingdom",
      value: "LMO",
      display: "LMO"
    },
    {
      name: "Cambridge Airport",
      city: "Cambridge",
      country: "United Kingdom",
      value: "CBG",
      display: "CBG"
    },
    {
      name: "Norwich International Airport",
      city: "Norwich",
      country: "United Kingdom",
      value: "NWI",
      display: "NWI"
    },
    {
      name: "London Stansted Airport",
      city: "London",
      country: "United Kingdom",
      value: "STN",
      display: "STN"
    },
    {
      name: "Exeter International Airport",
      city: "Exeter",
      country: "United Kingdom",
      value: "EXT",
      display: "EXT"
    },
    {
      name: "Bristol Filton Airport",
      city: "Bristol",
      country: "United Kingdom",
      value: "FZO",
      display: "FZO"
    },
    {
      name: "Oxford (Kidlington) Airport",
      city: "Oxford",
      country: "United Kingdom",
      value: "OXF",
      display: "OXF"
    },
    {
      name: "RAF Mildenhall",
      city: "Mildenhall",
      country: "United Kingdom",
      value: "MHZ",
      display: "MHZ"
    },
    {
      name: "RAF Fairford",
      city: "Fairford",
      country: "United Kingdom",
      value: "FFD",
      display: "FFD"
    },
    {
      name: "RAF Brize Norton",
      city: "Brize Norton",
      country: "United Kingdom",
      value: "BZZ",
      display: "BZZ"
    },
    {
      name: "RAF Odiham",
      city: "Odiham",
      country: "United Kingdom",
      value: "ODH",
      display: "ODH"
    },
    {
      name: "RAF Northolt",
      city: "Northolt",
      country: "United Kingdom",
      value: "NHT",
      display: "NHT"
    },
    {
      name: "RAF Coningsby",
      city: "Coningsby",
      country: "United Kingdom",
      value: "QCY",
      display: "QCY"
    },
    {
      name: "RAF Honington",
      city: "Honington",
      country: "United Kingdom",
      value: "BEQ",
      display: "BEQ"
    },
    {
      name: "RAF Linton-On-Ouse",
      city: "Linton-on-ouse",
      country: "United Kingdom",
      value: "HRT",
      display: "HRT"
    },
    {
      name: "RAF Waddington",
      city: "Waddington",
      country: "United Kingdom",
      value: "WTN",
      display: "WTN"
    },
    {
      name: "RAF Marham",
      city: "Marham",
      country: "United Kingdom",
      value: "KNF",
      display: "KNF"
    },
    {
      name: "Mount Pleasant Airport",
      city: "Mount Pleasant",
      country: "Falkland Islands",
      value: "MPN",
      display: "MPN"
    },
    {
      name: "Amsterdam Airport Schiphol",
      city: "Amsterdam",
      country: "Netherlands",
      value: "AMS",
      display: "AMS"
    },
    {
      name: "Maastricht Aachen Airport",
      city: "Maastricht",
      country: "Netherlands",
      value: "MST",
      display: "MST"
    },
    {
      name: "Eindhoven Airport",
      city: "Eindhoven",
      country: "Netherlands",
      value: "EIN",
      display: "EIN"
    },
    {
      name: "Eelde Airport",
      city: "Groningen",
      country: "Netherlands",
      value: "GRQ",
      display: "GRQ"
    },
    {
      name: "De Kooy Airport",
      city: "De Kooy",
      country: "Netherlands",
      value: "DHR",
      display: "DHR"
    },
    {
      name: "Lelystad Airport",
      city: "Lelystad",
      country: "Netherlands",
      value: "LEY",
      display: "LEY"
    },
    {
      name: "Leeuwarden Air Base",
      city: "Leeuwarden",
      country: "Netherlands",
      value: "LWR",
      display: "LWR"
    },
    {
      name: "Rotterdam The Hague Airport",
      city: "Rotterdam",
      country: "Netherlands",
      value: "RTM",
      display: "RTM"
    },
    {
      name: "Soesterberg Air Base",
      city: "Soesterberg",
      country: "Netherlands",
      value: "UTC",
      display: "UTC"
    },
    {
      name: "Twente Airfield",
      city: "Enschede",
      country: "Netherlands",
      value: "ENS",
      display: "ENS"
    },
    {
      name: "Valkenburg Naval Air Base",
      city: "Valkenburg",
      country: "Netherlands",
      value: "LID",
      display: "LID"
    },
    {
      name: "Woensdrecht Air Base",
      city: "Woensdrecht",
      country: "Netherlands",
      value: "WOE",
      display: "WOE"
    },
    {
      name: "Cork Airport",
      city: "Cork",
      country: "Ireland",
      value: "ORK",
      display: "ORK"
    },
    {
      name: "Galway Airport",
      city: "Galway",
      country: "Ireland",
      value: "GWY",
      display: "GWY"
    },
    {
      name: "Dublin Airport",
      city: "Dublin",
      country: "Ireland",
      value: "DUB",
      display: "DUB"
    },
    {
      name: "Ireland West Knock Airport",
      city: "Connaught",
      country: "Ireland",
      value: "NOC",
      display: "NOC"
    },
    {
      name: "Kerry Airport",
      city: "Kerry",
      country: "Ireland",
      value: "KIR",
      display: "KIR"
    },
    {
      name: "Shannon Airport",
      city: "Shannon",
      country: "Ireland",
      value: "SNN",
      display: "SNN"
    },
    {
      name: "Sligo Airport",
      city: "Sligo",
      country: "Ireland",
      value: "SXL",
      display: "SXL"
    },
    {
      name: "Waterford Airport",
      city: "Waterford",
      country: "Ireland",
      value: "WAT",
      display: "WAT"
    },
    {
      name: "Aarhus Airport",
      city: "Aarhus",
      country: "Denmark",
      value: "AAR",
      display: "AAR"
    },
    {
      name: "Billund Airport",
      city: "Billund",
      country: "Denmark",
      value: "BLL",
      display: "BLL"
    },
    {
      name: "Copenhagen Kastrup Airport",
      city: "Copenhagen",
      country: "Denmark",
      value: "CPH",
      display: "CPH"
    },
    {
      name: "Esbjerg Airport",
      city: "Esbjerg",
      country: "Denmark",
      value: "EBJ",
      display: "EBJ"
    },
    {
      name: "Karup Airport",
      city: "Karup",
      country: "Denmark",
      value: "KRP",
      display: "KRP"
    },
    {
      name: "Odense Airport",
      city: "Odense",
      country: "Denmark",
      value: "ODE",
      display: "ODE"
    },
    {
      name: "Copenhagen Roskilde Airport",
      city: "Copenhagen",
      country: "Denmark",
      value: "RKE",
      display: "RKE"
    },
    {
      name: "Bornholm Airport",
      city: "Ronne",
      country: "Denmark",
      value: "RNN",
      display: "RNN"
    },
    {
      name: "SÃ¸nderborg Airport",
      city: "Soenderborg",
      country: "Denmark",
      value: "SGD",
      display: "SGD"
    },
    {
      name: "Skrydstrup Air Base",
      city: "Skrydstrup",
      country: "Denmark",
      value: "SKS",
      display: "SKS"
    },
    {
      name: "Thisted Airport",
      city: "Thisted",
      country: "Denmark",
      value: "TED",
      display: "TED"
    },
    {
      name: "Vagar Airport",
      city: "Vagar",
      country: "Faroe Islands",
      value: "FAE",
      display: "FAE"
    },
    {
      name: "Stauning Airport",
      city: "Stauning",
      country: "Denmark",
      value: "STA",
      display: "STA"
    },
    {
      name: "Aalborg Airport",
      city: "Aalborg",
      country: "Denmark",
      value: "AAL",
      display: "AAL"
    },
    {
      name: "Luxembourg-Findel International Airport",
      city: "Luxemburg",
      country: "Luxembourg",
      value: "LUX",
      display: "LUX"
    },
    {
      name: "Alesund Airport",
      city: "Alesund",
      country: "Norway",
      value: "AES",
      display: "AES"
    },
    {
      name: "AndÃ¸ya Airport",
      city: "Andoya",
      country: "Norway",
      value: "ANX",
      display: "ANX"
    },
    {
      name: "Alta Airport",
      city: "Alta",
      country: "Norway",
      value: "ALF",
      display: "ALF"
    },
    {
      name: "BrÃ¸nnÃ¸ysund Airport",
      city: "Bronnoysund",
      country: "Norway",
      value: "BNN",
      display: "BNN"
    },
    {
      name: "BodÃ¸ Airport",
      city: "Bodo",
      country: "Norway",
      value: "BOO",
      display: "BOO"
    },
    {
      name: "Bergen Airport Flesland",
      city: "Bergen",
      country: "Norway",
      value: "BGO",
      display: "BGO"
    },
    {
      name: "Batsfjord Airport",
      city: "Batsfjord",
      country: "Norway",
      value: "BJF",
      display: "BJF"
    },
    {
      name: "Kristiansand Airport",
      city: "Kristiansand",
      country: "Norway",
      value: "KRS",
      display: "KRS"
    },
    {
      name: "Bardufoss Airport",
      city: "Bardufoss",
      country: "Norway",
      value: "BDU",
      display: "BDU"
    },
    {
      name: "Harstad/Narvik Airport, Evenes",
      city: "Harstad/Narvik",
      country: "Norway",
      value: "EVE",
      display: "EVE"
    },
    {
      name: "Leirin Airport",
      city: "Fagernes",
      country: "Norway",
      value: "VDB",
      display: "VDB"
    },
    {
      name: "FlorÃ¸ Airport",
      city: "Floro",
      country: "Norway",
      value: "FRO",
      display: "FRO"
    },
    {
      name: "Oslo Gardermoen Airport",
      city: "Oslo",
      country: "Norway",
      value: "OSL",
      display: "OSL"
    },
    {
      name: "Haugesund Airport",
      city: "Haugesund",
      country: "Norway",
      value: "HAU",
      display: "HAU"
    },
    {
      name: "Hasvik Airport",
      city: "Hasvik",
      country: "Norway",
      value: "HAA",
      display: "HAA"
    },
    {
      name: "Kristiansund Airport (Kvernberget)",
      city: "Kristiansund",
      country: "Norway",
      value: "KSU",
      display: "KSU"
    },
    {
      name: "Kirkenes Airport (HÃ¸ybuktmoen)",
      city: "Kirkenes",
      country: "Norway",
      value: "KKN",
      display: "KKN"
    },
    {
      name: "Lista Airport",
      city: "Farsund",
      country: "Norway",
      value: "FAN",
      display: "FAN"
    },
    {
      name: "Molde Airport",
      city: "Molde",
      country: "Norway",
      value: "MOL",
      display: "MOL"
    },
    {
      name: "MosjÃ¸en Airport, KjÃ¦rstad",
      city: "Mosjoen",
      country: "Norway",
      value: "MJF",
      display: "MJF"
    },
    {
      name: "Banak Airport",
      city: "Lakselv",
      country: "Norway",
      value: "LKL",
      display: "LKL"
    },
    {
      name: "Notodden Airport",
      city: "Notodden",
      country: "Norway",
      value: "NTB",
      display: "NTB"
    },
    {
      name: "Ã˜rland Airport",
      city: "Orland",
      country: "Norway",
      value: "OLA",
      display: "OLA"
    },
    {
      name: "RÃ¸ros Airport",
      city: "Roros",
      country: "Norway",
      value: "RRS",
      display: "RRS"
    },
    {
      name: "Moss-Rygge Airport",
      city: "Rygge",
      country: "Norway",
      value: "RYG",
      display: "RYG"
    },
    {
      name: "Svalbard Airport, Longyear",
      city: "Svalbard",
      country: "Norway",
      value: "LYR",
      display: "LYR"
    },
    {
      name: "Skien Airport",
      city: "Skien",
      country: "Norway",
      value: "SKE",
      display: "SKE"
    },
    {
      name: "Stord Airport",
      city: "Stord",
      country: "Norway",
      value: "SRP",
      display: "SRP"
    },
    {
      name: "SandnessjÃ¸en Airport (Stokka)",
      city: "Sandnessjoen",
      country: "Norway",
      value: "SSJ",
      display: "SSJ"
    },
    {
      name: "Tromso Airport",
      city: "Tromso",
      country: "Norway",
      value: "TOS",
      display: "TOS"
    },
    {
      name: "Torp Sandefjord Airport",
      city: "Sandefjord",
      country: "Norway",
      value: "TRF",
      display: "TRF"
    },
    {
      name: "Trondheim Airport VÃ¦rnes",
      city: "Trondheim",
      country: "Norway",
      value: "TRD",
      display: "TRD"
    },
    {
      name: "Stavanger Airport Sola",
      city: "Stavanger",
      country: "Norway",
      value: "SVG",
      display: "SVG"
    },
    {
      name: "GdaÅ„sk Lech WaÅ‚Ä™sa Airport",
      city: "Gdansk",
      country: "Poland",
      value: "GDN",
      display: "GDN"
    },
    {
      name: "Solidarity Szczecinâ€“GoleniÃ³w Airport",
      city: "Szczecin",
      country: "Poland",
      value: "SZZ",
      display: "SZZ"
    },
    {
      name: "Port Lotniczy Lublin Airport",
      city: "Lublin",
      country: "Poland",
      value: "LUZ",
      display: "LUZ"
    },
    {
      name: "John Paul II International Airport Krakow-Balice Airport",
      city: "Krakow",
      country: "Poland",
      value: "KRK",
      display: "KRK"
    },
    {
      name: "Katowice International Airport",
      city: "Katowice",
      country: "Poland",
      value: "KTW",
      display: "KTW"
    },
    {
      name: "PoznaÅ„â€“Åawica Airport",
      city: "Poznan",
      country: "Poland",
      value: "POZ",
      display: "POZ"
    },
    {
      name: "Rzeszow-Jasionka Airport",
      city: "Rzeszow",
      country: "Poland",
      value: "RZE",
      display: "RZE"
    },
    {
      name: "Redzikowo Air Base",
      city: "Slupsk",
      country: "Poland",
      value: "OSP",
      display: "OSP"
    },
    {
      name: "Warsaw Chopin Airport",
      city: "Warsaw",
      country: "Poland",
      value: "WAW",
      display: "WAW"
    },
    {
      name: "Copernicus WrocÅ‚aw Airport",
      city: "Wroclaw",
      country: "Poland",
      value: "WRO",
      display: "WRO"
    },
    {
      name: "Zielona Gora-Babimost Airport",
      city: "Zielona Gora",
      country: "Poland",
      value: "IEG",
      display: "IEG"
    },
    {
      name: "Ronneby Airport",
      city: "Ronneby",
      country: "Sweden",
      value: "RNB",
      display: "RNB"
    },
    {
      name: "Gothenburg-Landvetter Airport",
      city: "Gothenborg",
      country: "Sweden",
      value: "GOT",
      display: "GOT"
    },
    {
      name: "Jonkoping Airport",
      city: "Joenkoeping",
      country: "Sweden",
      value: "JKG",
      display: "JKG"
    },
    {
      name: "Lidkoping-Hovby Airport",
      city: "Lidkoping",
      country: "Sweden",
      value: "LDK",
      display: "LDK"
    },
    {
      name: "Gothenburg City Airport",
      city: "Gothenborg",
      country: "Sweden",
      value: "GSE",
      display: "GSE"
    },
    {
      name: "Skovde Airport",
      city: "Skovde",
      country: "Sweden",
      value: "KVB",
      display: "KVB"
    },
    {
      name: "Trollhattan-Vanersborg Airport",
      city: "Trollhattan",
      country: "Sweden",
      value: "THN",
      display: "THN"
    },
    {
      name: "Karlskoga Airport",
      city: "Karlskoga",
      country: "Sweden",
      value: "KSK",
      display: "KSK"
    },
    {
      name: "Mora Airport",
      city: "Mora",
      country: "Sweden",
      value: "MXX",
      display: "MXX"
    },
    {
      name: "Stockholm Skavsta Airport",
      city: "Stockholm",
      country: "Sweden",
      value: "NYO",
      display: "NYO"
    },
    {
      name: "Kristianstad Airport",
      city: "Kristianstad",
      country: "Sweden",
      value: "KID",
      display: "KID"
    },
    {
      name: "Landskrona Airport",
      city: "Landskrona",
      country: "Sweden",
      value: "JLD",
      display: "JLD"
    },
    {
      name: "Oskarshamn Airport",
      city: "Oskarshamn",
      country: "Sweden",
      value: "OSK",
      display: "OSK"
    },
    {
      name: "Kalmar Airport",
      city: "Kalkmar",
      country: "Sweden",
      value: "KLR",
      display: "KLR"
    },
    {
      name: "Malmo Sturup Airport",
      city: "Malmoe",
      country: "Sweden",
      value: "MMX",
      display: "MMX"
    },
    {
      name: "Halmstad Airport",
      city: "Halmstad",
      country: "Sweden",
      value: "HAD",
      display: "HAD"
    },
    {
      name: "Vaxjo Kronoberg Airport",
      city: "Vaxjo",
      country: "Sweden",
      value: "VXO",
      display: "VXO"
    },
    {
      name: "Sveg Airport",
      city: "Sveg",
      country: "Sweden",
      value: "EVG",
      display: "EVG"
    },
    {
      name: "Gallivare Airport",
      city: "Gallivare",
      country: "Sweden",
      value: "GEV",
      display: "GEV"
    },
    {
      name: "Hudiksvall Airport",
      city: "Hudiksvall",
      country: "Sweden",
      value: "HUV",
      display: "HUV"
    },
    {
      name: "Kramfors Solleftea Airport",
      city: "Kramfors",
      country: "Sweden",
      value: "KRF",
      display: "KRF"
    },
    {
      name: "Lycksele Airport",
      city: "Lycksele",
      country: "Sweden",
      value: "LYC",
      display: "LYC"
    },
    {
      name: "Sundsvall-Harnosand Airport",
      city: "Sundsvall",
      country: "Sweden",
      value: "SDL",
      display: "SDL"
    },
    {
      name: "Ornskoldsvik Airport",
      city: "Ornskoldsvik",
      country: "Sweden",
      value: "OER",
      display: "OER"
    },
    {
      name: "Kiruna Airport",
      city: "Kiruna",
      country: "Sweden",
      value: "KRN",
      display: "KRN"
    },
    {
      name: "Skelleftea Airport",
      city: "Skelleftea",
      country: "Sweden",
      value: "SFT",
      display: "SFT"
    },
    {
      name: "Umea Airport",
      city: "Umea",
      country: "Sweden",
      value: "UME",
      display: "UME"
    },
    {
      name: "Vilhelmina Airport",
      city: "Vilhelmina",
      country: "Sweden",
      value: "VHM",
      display: "VHM"
    },
    {
      name: "Arvidsjaur Airport",
      city: "Arvidsjaur",
      country: "Sweden",
      value: "AJR",
      display: "AJR"
    },
    {
      name: "Orebro Airport",
      city: "Orebro",
      country: "Sweden",
      value: "ORB",
      display: "ORB"
    },
    {
      name: "Stockholm Vasteras Airport",
      city: "Vasteras",
      country: "Sweden",
      value: "VST",
      display: "VST"
    },
    {
      name: "Lulea Airport",
      city: "Lulea",
      country: "Sweden",
      value: "LLA",
      display: "LLA"
    },
    {
      name: "Stockholm-Arlanda Airport",
      city: "Stockholm",
      country: "Sweden",
      value: "ARN",
      display: "ARN"
    },
    {
      name: "Stockholm-Bromma Airport",
      city: "Stockholm",
      country: "Sweden",
      value: "BMA",
      display: "BMA"
    },
    {
      name: "Borlange Airport",
      city: "Borlange",
      country: "Sweden",
      value: "BLE",
      display: "BLE"
    },
    {
      name: "Hultsfred Airport",
      city: "Hultsfred",
      country: "Sweden",
      value: "HLF",
      display: "HLF"
    },
    {
      name: "Gavle Sandviken Airport",
      city: "Gavle",
      country: "Sweden",
      value: "GVX",
      display: "GVX"
    },
    {
      name: "Linkoping City Airport",
      city: "Linkoeping",
      country: "Sweden",
      value: "LPI",
      display: "LPI"
    },
    {
      name: "Norrkoping Airport",
      city: "Norrkoeping",
      country: "Sweden",
      value: "NRK",
      display: "NRK"
    },
    {
      name: "Visby Airport",
      city: "Visby",
      country: "Sweden",
      value: "VBY",
      display: "VBY"
    },
    {
      name: "Spangdahlem Air Base",
      city: "Spangdahlem",
      country: "Germany",
      value: "SPM",
      display: "SPM"
    },
    {
      name: "Ramstein Air Base",
      city: "Ramstein",
      country: "Germany",
      value: "RMS",
      display: "RMS"
    },
    {
      name: "[Duplicate] Giebelstadt Army Air Field",
      city: "Giebelstadt",
      country: "Germany",
      value: "GHF",
      display: "GHF"
    },
    {
      name: "Celle Airport",
      city: "Celle",
      country: "Germany",
      value: "ZCN",
      display: "ZCN"
    },
    {
      name: "Hanau Army Air Field",
      city: "Hanau",
      country: "Germany",
      value: "ZNF",
      display: "ZNF"
    },
    {
      name: "Geilenkirchen Air Base",
      city: "Geilenkirchen",
      country: "Germany",
      value: "GKE",
      display: "GKE"
    },
    {
      name: "Rostock-Laage Airport",
      city: "Laage",
      country: "Germany",
      value: "RLG",
      display: "RLG"
    },
    {
      name: "Furstenfeldbruck Airport",
      city: "Fuerstenfeldbruck",
      country: "Germany",
      value: "FEL",
      display: "FEL"
    },
    {
      name: "Ingolstadt Manching Airport",
      city: "Ingolstadt",
      country: "Germany",
      value: "IGS",
      display: "IGS"
    },
    {
      name: "Gutersloh Air Base",
      city: "Guetersloh",
      country: "Germany",
      value: "GUT",
      display: "GUT"
    },
    {
      name: "Alexander Bay Airport",
      city: "Alexander Bay",
      country: "South Africa",
      value: "ALJ",
      display: "ALJ"
    },
    {
      name: "Aggeneys Airport",
      city: "Aggeneys",
      country: "South Africa",
      value: "AGZ",
      display: "AGZ"
    },
    {
      name: "Bisho Airport",
      city: "Bisho",
      country: "South Africa",
      value: "BIY",
      display: "BIY"
    },
    {
      name: "Bram Fischer International Airport",
      city: "Bloemfontein",
      country: "South Africa",
      value: "BFN",
      display: "BFN"
    },
    {
      name: "Cape Town International Airport",
      city: "Cape Town",
      country: "South Africa",
      value: "CPT",
      display: "CPT"
    },
    {
      name: "King Shaka International Airport",
      city: "Durban",
      country: "South Africa",
      value: "DUR",
      display: "DUR"
    },
    {
      name: "Ben Schoeman Airport",
      city: "East London",
      country: "South Africa",
      value: "ELS",
      display: "ELS"
    },
    {
      name: "Grand Central Airport",
      city: "Johannesburg",
      country: "South Africa",
      value: "GCJ",
      display: "GCJ"
    },
    {
      name: "George Airport",
      city: "George",
      country: "South Africa",
      value: "GRJ",
      display: "GRJ"
    },
    {
      name: "Hoedspruit Air Force Base Airport",
      city: "Hoedspruit",
      country: "South Africa",
      value: "HDS",
      display: "HDS"
    },
    {
      name: "OR Tambo International Airport",
      city: "Johannesburg",
      country: "South Africa",
      value: "JNB",
      display: "JNB"
    },
    {
      name: "Kimberley Airport",
      city: "Kimberley",
      country: "South Africa",
      value: "KIM",
      display: "KIM"
    },
    {
      name: "Johan Pienaar Airport",
      city: "Kuruman",
      country: "South Africa",
      value: "KMH",
      display: "KMH"
    },
    {
      name: "Kleinsee Airport",
      city: "Kleinsee",
      country: "South Africa",
      value: "KLZ",
      display: "KLZ"
    },
    {
      name: "Lanseria Airport",
      city: "Johannesburg",
      country: "South Africa",
      value: "HLA",
      display: "HLA"
    },
    {
      name: "Ladysmith Airport",
      city: "Ladysmith",
      country: "South Africa",
      value: "LAY",
      display: "LAY"
    },
    {
      name: "Margate Airport",
      city: "Margate",
      country: "South Africa",
      value: "MGH",
      display: "MGH"
    },
    {
      name: "Morningside Farm Airport",
      city: "Musina",
      country: "South Africa",
      value: "MEZ",
      display: "MEZ"
    },
    {
      name: "Newcastle Airport",
      city: "Newcastle",
      country: "South Africa",
      value: "NCS",
      display: "NCS"
    },
    {
      name: "Oudtshoorn Airport",
      city: "Oudtshoorn",
      country: "South Africa",
      value: "DUH",
      display: "DUH"
    },
    {
      name: "Port Elizabeth Airport",
      city: "Port Elizabeth",
      country: "South Africa",
      value: "PLZ",
      display: "PLZ"
    },
    {
      name: "Hendrik Van Eck Airport",
      city: "Phalaborwa",
      country: "South Africa",
      value: "PHW",
      display: "PHW"
    },
    {
      name: "Pietermaritzburg Airport",
      city: "Pietermaritzburg",
      country: "South Africa",
      value: "PZB",
      display: "PZB"
    },
    {
      name: "Pilanesberg International Airport",
      city: "Pilanesberg",
      country: "South Africa",
      value: "NTY",
      display: "NTY"
    },
    {
      name: "Polokwane International Airport",
      city: "Potgietersrus",
      country: "South Africa",
      value: "PTG",
      display: "PTG"
    },
    {
      name: "Queenstown Airport",
      city: "Queenstown",
      country: "South Africa",
      value: "UTW",
      display: "UTW"
    },
    {
      name: "Richards Bay Airport",
      city: "Richard s Bay",
      country: "South Africa",
      value: "RCB",
      display: "RCB"
    },
    {
      name: "Robertson Airport",
      city: "Robertson",
      country: "South Africa",
      value: "ROD",
      display: "ROD"
    },
    {
      name: "Springbok Airport",
      city: "Springbok",
      country: "South Africa",
      value: "SBU",
      display: "SBU"
    },
    {
      name: "Sishen Airport",
      city: "Sishen",
      country: "South Africa",
      value: "SIS",
      display: "SIS"
    },
    {
      name: "Skukuza Airport",
      city: "Skukuza",
      country: "South Africa",
      value: "SZK",
      display: "SZK"
    },
    {
      name: "Tzaneen Airport",
      city: "Tzaneen",
      country: "South Africa",
      value: "LTA",
      display: "LTA"
    },
    {
      name: "Prince Mangosuthu Buthelezi Airport",
      city: "Ulundi",
      country: "South Africa",
      value: "ULD",
      display: "ULD"
    },
    {
      name: "Pierre Van Ryneveld Airport",
      city: "Upington",
      country: "South Africa",
      value: "UTN",
      display: "UTN"
    },
    {
      name: "K. D. Matanzima Airport",
      city: "Umtata",
      country: "South Africa",
      value: "UTT",
      display: "UTT"
    },
    {
      name: "Vryburg Airport",
      city: "Vryburg",
      country: "South Africa",
      value: "VRU",
      display: "VRU"
    },
    {
      name: "Virginia Airport",
      city: "Durban",
      country: "South Africa",
      value: "VIR",
      display: "VIR"
    },
    {
      name: "Wonderboom Airport",
      city: "Pretoria",
      country: "South Africa",
      value: "PRY",
      display: "PRY"
    },
    {
      name: "Welkom Airport",
      city: "Welkom",
      country: "South Africa",
      value: "WEL",
      display: "WEL"
    },
    {
      name: "Francistown Airport",
      city: "Francistown",
      country: "Botswana",
      value: "FRW",
      display: "FRW"
    },
    {
      name: "Jwaneng Airport",
      city: "Jwaneng",
      country: "Botswana",
      value: "JWA",
      display: "JWA"
    },
    {
      name: "Kasane Airport",
      city: "Kasane",
      country: "Botswana",
      value: "BBK",
      display: "BBK"
    },
    {
      name: "Maun Airport",
      city: "Maun",
      country: "Botswana",
      value: "MUB",
      display: "MUB"
    },
    {
      name: "Sir Seretse Khama International Airport",
      city: "Gaberone",
      country: "Botswana",
      value: "GBE",
      display: "GBE"
    },
    {
      name: "Selebi Phikwe Airport",
      city: "Selebi-phikwe",
      country: "Botswana",
      value: "PKW",
      display: "PKW"
    },
    {
      name: "Maya-Maya Airport",
      city: "Brazzaville",
      country: "Congo (Brazzaville)",
      value: "BZV",
      display: "BZV"
    },
    {
      name: "Owando Airport",
      city: "Owando",
      country: "Congo (Kinshasa)",
      value: "FTX",
      display: "FTX"
    },
    {
      name: "Ouesso Airport",
      city: "Ouesso",
      country: "Congo (Kinshasa)",
      value: "OUE",
      display: "OUE"
    },
    {
      name: "Pointe Noire Airport",
      city: "Pointe-noire",
      country: "Congo (Brazzaville)",
      value: "PNR",
      display: "PNR"
    },
    {
      name: "Matsapha Airport",
      city: "Manzini",
      country: "Swaziland",
      value: "MTS",
      display: "MTS"
    },
    {
      name: "Bangui M'Poko International Airport",
      city: "Bangui",
      country: "Central African Republic",
      value: "BGF",
      display: "BGF"
    },
    {
      name: "Berberati Airport",
      city: "Berberati",
      country: "Central African Republic",
      value: "BBT",
      display: "BBT"
    },
    {
      name: "Bata Airport",
      city: "Bata",
      country: "Equatorial Guinea",
      value: "BSG",
      display: "BSG"
    },
    {
      name: "Malabo Airport",
      city: "Malabo",
      country: "Equatorial Guinea",
      value: "SSG",
      display: "SSG"
    },
    {
      name: "RAF Ascension Island",
      city: "Wide Awake",
      country: "Saint Helena",
      value: "ASI",
      display: "ASI"
    },
    {
      name: "Sir Seewoosagur Ramgoolam International Airport",
      city: "Plaisance",
      country: "Mauritius",
      value: "MRU",
      display: "MRU"
    },
    {
      name: "Sir Charles Gaetan Duval Airport",
      city: "Rodriguez Island",
      country: "Mauritius",
      value: "RRG",
      display: "RRG"
    },
    {
      name: "Tiko Airport",
      city: "Tiko",
      country: "Cameroon",
      value: "TKC",
      display: "TKC"
    },
    {
      name: "Douala International Airport",
      city: "Douala",
      country: "Cameroon",
      value: "DLA",
      display: "DLA"
    },
    {
      name: "Salak Airport",
      city: "Maroua",
      country: "Cameroon",
      value: "MVR",
      display: "MVR"
    },
    {
      name: "Foumban Nkounja Airport",
      city: "Foumban",
      country: "Cameroon",
      value: "FOM",
      display: "FOM"
    },
    {
      name: "N'Gaoundere Airport",
      city: "N gaoundere",
      country: "Cameroon",
      value: "NGE",
      display: "NGE"
    },
    {
      name: "Garoua International Airport",
      city: "Garoua",
      country: "Cameroon",
      value: "GOU",
      display: "GOU"
    },
    {
      name: "Bafoussam Airport",
      city: "Bafoussam",
      country: "Cameroon",
      value: "BFX",
      display: "BFX"
    },
    {
      name: "Bamenda Airport",
      city: "Bamenda",
      country: "Cameroon",
      value: "BPC",
      display: "BPC"
    },
    {
      name: "Yaounde Airport",
      city: "Yaounde",
      country: "Cameroon",
      value: "YAO",
      display: "YAO"
    },
    {
      name: "Livingstone Airport",
      city: "Livingstone",
      country: "Zambia",
      value: "LVI",
      display: "LVI"
    },
    {
      name: "Kenneth Kaunda International Airport Lusaka",
      city: "Lusaka",
      country: "Zambia",
      value: "LUN",
      display: "LUN"
    },
    {
      name: "Mfuwe Airport",
      city: "Mfuwe",
      country: "Zambia",
      value: "MFU",
      display: "MFU"
    },
    {
      name: "Simon Mwansa Kapwepwe International Airport",
      city: "Ndola",
      country: "Zambia",
      value: "NLA",
      display: "NLA"
    },
    {
      name: "Southdowns Airport",
      city: "Southdowns",
      country: "Zambia",
      value: "KIW",
      display: "KIW"
    },
    {
      name: "Prince Said Ibrahim International Airport",
      city: "Moroni",
      country: "Comoros",
      value: "HAH",
      display: "HAH"
    },
    {
      name: "Moheli Bandar Es Eslam Airport",
      city: "Moheli",
      country: "Comoros",
      value: "NWA",
      display: "NWA"
    },
    {
      name: "Ouani Airport",
      city: "Anjouan",
      country: "Comoros",
      value: "AJN",
      display: "AJN"
    },
    {
      name: "Dzaoudzi Pamandzi International Airport",
      city: "Dzaoudzi",
      country: "Mayotte",
      value: "DZA",
      display: "DZA"
    },
    {
      name: "Roland Garros Airport",
      city: "St Denis",
      country: "Reunion",
      value: "RUN",
      display: "RUN"
    },
    {
      name: "Pierrefonds Airport",
      city: "St Pierre",
      country: "Reunion",
      value: "ZSE",
      display: "ZSE"
    },
    {
      name: "Ivato Airport",
      city: "Antananarivo",
      country: "Madagascar",
      value: "TNR",
      display: "TNR"
    },
    {
      name: "Miandrivazo Airport",
      city: "Miandrivazo",
      country: "Madagascar",
      value: "ZVA",
      display: "ZVA"
    },
    {
      name: "Sainte Marie Airport",
      city: "Sainte Marie",
      country: "Madagascar",
      value: "SMS",
      display: "SMS"
    },
    {
      name: "Toamasina Airport",
      city: "Toamasina",
      country: "Madagascar",
      value: "TMM",
      display: "TMM"
    },
    {
      name: "Morondava Airport",
      city: "Morondava",
      country: "Madagascar",
      value: "MOQ",
      display: "MOQ"
    },
    {
      name: "Arrachart Airport",
      city: "Antsiranana",
      country: "Madagascar",
      value: "DIE",
      display: "DIE"
    },
    {
      name: "Mananara Nord Airport",
      city: "Mananara",
      country: "Madagascar",
      value: "WMR",
      display: "WMR"
    },
    {
      name: "Andapa Airport",
      city: "Andapa",
      country: "Madagascar",
      value: "ZWA",
      display: "ZWA"
    },
    {
      name: "Ambilobe Airport",
      city: "Ambilobe",
      country: "Madagascar",
      value: "AMB",
      display: "AMB"
    },
    {
      name: "Antsirabato Airport",
      city: "Antalaha",
      country: "Madagascar",
      value: "ANM",
      display: "ANM"
    },
    {
      name: "Analalava Airport",
      city: "Analalava",
      country: "Madagascar",
      value: "HVA",
      display: "HVA"
    },
    {
      name: "Amborovy Airport",
      city: "Mahajanga",
      country: "Madagascar",
      value: "MJN",
      display: "MJN"
    },
    {
      name: "Fascene Airport",
      city: "Nosy-be",
      country: "Madagascar",
      value: "NOS",
      display: "NOS"
    },
    {
      name: "Besalampy Airport",
      city: "Besalampy",
      country: "Madagascar",
      value: "BPY",
      display: "BPY"
    },
    {
      name: "Maroantsetra Airport",
      city: "Maroantsetra",
      country: "Madagascar",
      value: "WMN",
      display: "WMN"
    },
    {
      name: "Sambava Airport",
      city: "Sambava",
      country: "Madagascar",
      value: "SVB",
      display: "SVB"
    },
    {
      name: "Vohimarina Airport",
      city: "Vohemar",
      country: "Madagascar",
      value: "VOH",
      display: "VOH"
    },
    {
      name: "Ambalabe Airport",
      city: "Antsohihy",
      country: "Madagascar",
      value: "WAI",
      display: "WAI"
    },
    {
      name: "Ampampamena Airport",
      city: "Ampampamena",
      country: "Madagascar",
      value: "IVA",
      display: "IVA"
    },
    {
      name: "Tolanaro Airport",
      city: "Tolagnaro",
      country: "Madagascar",
      value: "FTU",
      display: "FTU"
    },
    {
      name: "Fianarantsoa Airport",
      city: "Fianarantsoa",
      country: "Madagascar",
      value: "WFI",
      display: "WFI"
    },
    {
      name: "Farafangana Airport",
      city: "Farafangana",
      country: "Madagascar",
      value: "RVA",
      display: "RVA"
    },
    {
      name: "Manakara Airport",
      city: "Manakara",
      country: "Madagascar",
      value: "WVK",
      display: "WVK"
    },
    {
      name: "Mananjary Airport",
      city: "Mananjary",
      country: "Madagascar",
      value: "MNJ",
      display: "MNJ"
    },
    {
      name: "Morombe Airport",
      city: "Morombe",
      country: "Madagascar",
      value: "MXM",
      display: "MXM"
    },
    {
      name: "Toliara Airport",
      city: "Toliara",
      country: "Madagascar",
      value: "TLE",
      display: "TLE"
    },
    {
      name: "Mbanza Congo Airport",
      city: "M banza-congo",
      country: "Angola",
      value: "SSY",
      display: "SSY"
    },
    {
      name: "Benguela Airport",
      city: "Benguela",
      country: "Angola",
      value: "BUG",
      display: "BUG"
    },
    {
      name: "Cabinda Airport",
      city: "Cabinda",
      country: "Angola",
      value: "CAB",
      display: "CAB"
    },
    {
      name: "Nova Lisboa Airport",
      city: "Huambo",
      country: "Angola",
      value: "NOV",
      display: "NOV"
    },
    {
      name: "Kuito Airport",
      city: "Kuito",
      country: "Angola",
      value: "SVP",
      display: "SVP"
    },
    {
      name: "Quatro de Fevereiro Airport",
      city: "Luanda",
      country: "Angola",
      value: "LAD",
      display: "LAD"
    },
    {
      name: "Malanje Airport",
      city: "Malanje",
      country: "Angola",
      value: "MEG",
      display: "MEG"
    },
    {
      name: "Menongue Airport",
      city: "Menongue",
      country: "Angola",
      value: "SPP",
      display: "SPP"
    },
    {
      name: "Negage Airport",
      city: "Negage",
      country: "Angola",
      value: "GXG",
      display: "GXG"
    },
    {
      name: "Porto Amboim Airport",
      city: "Porto Amboim",
      country: "Angola",
      value: "PBN",
      display: "PBN"
    },
    {
      name: "Saurimo Airport",
      city: "Saurimo",
      country: "Angola",
      value: "VHC",
      display: "VHC"
    },
    {
      name: "Soyo Airport",
      city: "Soyo",
      country: "Angola",
      value: "SZA",
      display: "SZA"
    },
    {
      name: "Lubango Airport",
      city: "Lubango",
      country: "Angola",
      value: "SDD",
      display: "SDD"
    },
    {
      name: "Luena Airport",
      city: "Luena",
      country: "Angola",
      value: "LUO",
      display: "LUO"
    },
    {
      name: "Uige Airport",
      city: "Uige",
      country: "Angola",
      value: "UGO",
      display: "UGO"
    },
    {
      name: "Xangongo Airport",
      city: "Xangongo",
      country: "Angola",
      value: "XGN",
      display: "XGN"
    },
    {
      name: "Oyem Airport",
      city: "Oyem",
      country: "Gabon",
      value: "OYE",
      display: "OYE"
    },
    {
      name: "Okondja Airport",
      city: "Okondja",
      country: "Gabon",
      value: "OKN",
      display: "OKN"
    },
    {
      name: "Lambarene Airport",
      city: "Lambarene",
      country: "Gabon",
      value: "LBQ",
      display: "LBQ"
    },
    {
      name: "Bitam Airport",
      city: "Bitam",
      country: "Gabon",
      value: "BMM",
      display: "BMM"
    },
    {
      name: "Port Gentil Airport",
      city: "Port Gentil",
      country: "Gabon",
      value: "POG",
      display: "POG"
    },
    {
      name: "Omboue Hopital Airport",
      city: "Omboue Hospial",
      country: "Gabon",
      value: "OMB",
      display: "OMB"
    },
    {
      name: "Makokou Airport",
      city: "Makokou",
      country: "Gabon",
      value: "MKU",
      display: "MKU"
    },
    {
      name: "Libreville Leon M'ba International Airport",
      city: "Libreville",
      country: "Gabon",
      value: "LBV",
      display: "LBV"
    },
    {
      name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
      city: "Franceville",
      country: "Gabon",
      value: "MVB",
      display: "MVB"
    },
    {
      name: "Principe Airport",
      city: "Principe",
      country: "Sao Tome and Principe",
      value: "PCP",
      display: "PCP"
    },
    {
      name: "Sao Tome International Airport",
      city: "Sao Tome",
      country: "Sao Tome and Principe",
      value: "TMS",
      display: "TMS"
    },
    {
      name: "Beira Airport",
      city: "Beira",
      country: "Mozambique",
      value: "BEW",
      display: "BEW"
    },
    {
      name: "Inhambane Airport",
      city: "Inhambane",
      country: "Mozambique",
      value: "INH",
      display: "INH"
    },
    {
      name: "Lichinga Airport",
      city: "Lichinga",
      country: "Mozambique",
      value: "VXC",
      display: "VXC"
    },
    {
      name: "Maputo Airport",
      city: "Maputo",
      country: "Mozambique",
      value: "MPM",
      display: "MPM"
    },
    {
      name: "Mocimboa da Praia Airport",
      city: "Mocimboa Da Praia",
      country: "Mozambique",
      value: "MZB",
      display: "MZB"
    },
    {
      name: "Nacala Airport",
      city: "Nacala",
      country: "Mozambique",
      value: "MNC",
      display: "MNC"
    },
    {
      name: "Nampula Airport",
      city: "Nampula",
      country: "Mozambique",
      value: "APL",
      display: "APL"
    },
    {
      name: "Pemba Airport",
      city: "Pemba",
      country: "Mozambique",
      value: "POL",
      display: "POL"
    },
    {
      name: "Quelimane Airport",
      city: "Quelimane",
      country: "Mozambique",
      value: "UEL",
      display: "UEL"
    },
    {
      name: "Chingozi Airport",
      city: "Tete",
      country: "Mozambique",
      value: "TET",
      display: "TET"
    },
    {
      name: "Vilankulo Airport",
      city: "Vilankulu",
      country: "Mozambique",
      value: "VNX",
      display: "VNX"
    },
    {
      name: "Desroches Airport",
      city: "Desroches",
      country: "Seychelles",
      value: "DES",
      display: "DES"
    },
    {
      name: "Seychelles International Airport",
      city: "Mahe",
      country: "Seychelles",
      value: "SEZ",
      display: "SEZ"
    },
    {
      name: "Praslin Airport",
      city: "Praslin",
      country: "Seychelles",
      value: "PRI",
      display: "PRI"
    },
    {
      name: "Abeche Airport",
      city: "Abeche",
      country: "Chad",
      value: "AEH",
      display: "AEH"
    },
    {
      name: "Moundou Airport",
      city: "Moundou",
      country: "Chad",
      value: "MQQ",
      display: "MQQ"
    },
    {
      name: "N'Djamena International Airport",
      city: "N djamena",
      country: "Chad",
      value: "NDJ",
      display: "NDJ"
    },
    {
      name: "Faya Largeau Airport",
      city: "Faya-largeau",
      country: "Chad",
      value: "FYT",
      display: "FYT"
    },
    {
      name: "Joshua Mqabuko Nkomo International Airport",
      city: "Bulawayo",
      country: "Zimbabwe",
      value: "BUQ",
      display: "BUQ"
    },
    {
      name: "Buffalo Range Airport",
      city: "Chiredzi",
      country: "Zimbabwe",
      value: "BFO",
      display: "BFO"
    },
    {
      name: "Victoria Falls International Airport",
      city: "Victoria Falls",
      country: "Zimbabwe",
      value: "VFA",
      display: "VFA"
    },
    {
      name: "Harare International Airport",
      city: "Harare",
      country: "Zimbabwe",
      value: "HRE",
      display: "HRE"
    },
    {
      name: "Kariba International Airport",
      city: "Kariba",
      country: "Zimbabwe",
      value: "KAB",
      display: "KAB"
    },
    {
      name: "Masvingo International Airport",
      city: "Masvingo",
      country: "Zimbabwe",
      value: "MVZ",
      display: "MVZ"
    },
    {
      name: "Thornhill Air Base",
      city: "Gwert",
      country: "Zimbabwe",
      value: "GWE",
      display: "GWE"
    },
    {
      name: "Hwange National Park Airport",
      city: "Hwange National Park",
      country: "Zimbabwe",
      value: "WKM",
      display: "WKM"
    },
    {
      name: "Chileka International Airport",
      city: "Blantyre",
      country: "Malawi",
      value: "BLZ",
      display: "BLZ"
    },
    {
      name: "Karonga Airport",
      city: "Karonga",
      country: "Malawi",
      value: "KGJ",
      display: "KGJ"
    },
    {
      name: "Lilongwe International Airport",
      city: "Lilongwe",
      country: "Malawi",
      value: "LLW",
      display: "LLW"
    },
    {
      name: "Mzuzu Airport",
      city: "Mzuzu",
      country: "Malawi",
      value: "ZZU",
      display: "ZZU"
    },
    {
      name: "Moshoeshoe I International Airport",
      city: "Maseru",
      country: "Lesotho",
      value: "MSU",
      display: "MSU"
    },
    {
      name: "Ndjili International Airport",
      city: "Kinshasa",
      country: "Congo (Kinshasa)",
      value: "FIH",
      display: "FIH"
    },
    {
      name: "Ndolo Airport",
      city: "Kinshasa",
      country: "Congo (Kinshasa)",
      value: "NLO",
      display: "NLO"
    },
    {
      name: "Muanda Airport",
      city: "Muanda",
      country: "Congo (Kinshasa)",
      value: "MNB",
      display: "MNB"
    },
    {
      name: "Bandundu Airport",
      city: "Bandoundu",
      country: "Congo (Kinshasa)",
      value: "FDU",
      display: "FDU"
    },
    {
      name: "Kikwit Airport",
      city: "Kikwit",
      country: "Congo (Kinshasa)",
      value: "KKW",
      display: "KKW"
    },
    {
      name: "Mbandaka Airport",
      city: "Mbandaka",
      country: "Congo (Kinshasa)",
      value: "MDK",
      display: "MDK"
    },
    {
      name: "Gbadolite Airport",
      city: "Gbadolite",
      country: "Congo (Kinshasa)",
      value: "BDT",
      display: "BDT"
    },
    {
      name: "Gemena Airport",
      city: "Gemena",
      country: "Congo (Kinshasa)",
      value: "GMA",
      display: "GMA"
    },
    {
      name: "Lisala Airport",
      city: "Lisala",
      country: "Congo (Kinshasa)",
      value: "LIQ",
      display: "LIQ"
    },
    {
      name: "Bangoka International Airport",
      city: "Kisangani",
      country: "Congo (Kinshasa)",
      value: "FKI",
      display: "FKI"
    },
    {
      name: "Matari Airport",
      city: "Isiro",
      country: "Congo (Kinshasa)",
      value: "IRP",
      display: "IRP"
    },
    {
      name: "Bunia Airport",
      city: "Bunia",
      country: "Congo (Kinshasa)",
      value: "BUX",
      display: "BUX"
    },
    {
      name: "Bukavu Kavumu Airport",
      city: "Bukavu/kavumu",
      country: "Congo (Kinshasa)",
      value: "BKY",
      display: "BKY"
    },
    {
      name: "Goma International Airport",
      city: "Goma",
      country: "Congo (Kinshasa)",
      value: "GOM",
      display: "GOM"
    },
    {
      name: "Kindu Airport",
      city: "Kindu",
      country: "Congo (Kinshasa)",
      value: "KND",
      display: "KND"
    },
    {
      name: "Lubumbashi International Airport",
      city: "Lubumashi",
      country: "Congo (Kinshasa)",
      value: "FBM",
      display: "FBM"
    },
    {
      name: "Kolwezi Airport",
      city: "Kolwezi",
      country: "Congo (Kinshasa)",
      value: "KWZ",
      display: "KWZ"
    },
    {
      name: "Kalemie Airport",
      city: "Kalemie",
      country: "Congo (Kinshasa)",
      value: "FMI",
      display: "FMI"
    },
    {
      name: "Kamina Base Airport",
      city: "Kamina Base",
      country: "Congo (Kinshasa)",
      value: "KMN",
      display: "KMN"
    },
    {
      name: "Kananga Airport",
      city: "Kananga",
      country: "Congo (Kinshasa)",
      value: "KGA",
      display: "KGA"
    },
    {
      name: "Mbuji Mayi Airport",
      city: "Mbuji-mayi",
      country: "Congo (Kinshasa)",
      value: "MJM",
      display: "MJM"
    },
    {
      name: "Senou Airport",
      city: "Bamako",
      country: "Mali",
      value: "BKO",
      display: "BKO"
    },
    {
      name: "Gao Airport",
      city: "Gao",
      country: "Mali",
      value: "GAQ",
      display: "GAQ"
    },
    {
      name: "Kayes Dag Dag Airport",
      city: "Kayes",
      country: "Mali",
      value: "KYS",
      display: "KYS"
    },
    {
      name: "Mopti Airport",
      city: "Mopti",
      country: "Mali",
      value: "MZI",
      display: "MZI"
    },
    {
      name: "Timbuktu Airport",
      city: "Tombouctou",
      country: "Mali",
      value: "TOM",
      display: "TOM"
    },
    {
      name: "Banjul International Airport",
      city: "Banjul",
      country: "Gambia",
      value: "BJL",
      display: "BJL"
    },
    {
      name: "Fuerteventura Airport",
      city: "Fuerteventura",
      country: "Spain",
      value: "FUE",
      display: "FUE"
    },
    {
      name: "Hierro Airport",
      city: "Hierro",
      country: "Spain",
      value: "VDE",
      display: "VDE"
    },
    {
      name: "La Palma Airport",
      city: "Santa Cruz De La Palma",
      country: "Spain",
      value: "SPC",
      display: "SPC"
    },
    {
      name: "Gran Canaria Airport",
      city: "Gran Canaria",
      country: "Spain",
      value: "LPA",
      display: "LPA"
    },
    {
      name: "Lanzarote Airport",
      city: "Arrecife",
      country: "Spain",
      value: "ACE",
      display: "ACE"
    },
    {
      name: "Tenerife South Airport",
      city: "Tenerife",
      country: "Spain",
      value: "TFS",
      display: "TFS"
    },
    {
      name: "Tenerife Norte Airport",
      city: "Tenerife",
      country: "Spain",
      value: "TFN",
      display: "TFN"
    },
    {
      name: "Melilla Airport",
      city: "Melilla",
      country: "Spain",
      value: "MLN",
      display: "MLN"
    },
    {
      name: "Lungi International Airport",
      city: "Freetown",
      country: "Sierra Leone",
      value: "FNA",
      display: "FNA"
    },
    {
      name: "Spriggs Payne Airport",
      city: "Monrovia",
      country: "Liberia",
      value: "MLW",
      display: "MLW"
    },
    {
      name: "Roberts International Airport",
      city: "Monrovia",
      country: "Liberia",
      value: "ROB",
      display: "ROB"
    },
    {
      name: "Al Massira Airport",
      city: "Agadir",
      country: "Morocco",
      value: "AGA",
      display: "AGA"
    },
    {
      name: "Tan Tan Airport",
      city: "Tan Tan",
      country: "Morocco",
      value: "TTA",
      display: "TTA"
    },
    {
      name: "Saiss Airport",
      city: "Fes",
      country: "Morocco",
      value: "FEZ",
      display: "FEZ"
    },
    {
      name: "Moulay Ali Cherif Airport",
      city: "Er-rachidia",
      country: "Morocco",
      value: "ERH",
      display: "ERH"
    },
    {
      name: "Bassatine Airport",
      city: "Meknes",
      country: "Morocco",
      value: "MEK",
      display: "MEK"
    },
    {
      name: "Angads Airport",
      city: "Oujda",
      country: "Morocco",
      value: "OUD",
      display: "OUD"
    },
    {
      name: "Rabat-Sale Airport",
      city: "Rabat",
      country: "Morocco",
      value: "RBA",
      display: "RBA"
    },
    {
      name: "Mohammed V International Airport",
      city: "Casablanca",
      country: "Morocco",
      value: "CMN",
      display: "CMN"
    },
    {
      name: "Menara Airport",
      city: "Marrakech",
      country: "Morocco",
      value: "RAK",
      display: "RAK"
    },
    {
      name: "Kenitra Airport",
      city: "Kentira",
      country: "Morocco",
      value: "NNA",
      display: "NNA"
    },
    {
      name: "Ouarzazate Airport",
      city: "Ouarzazate",
      country: "Morocco",
      value: "OZZ",
      display: "OZZ"
    },
    {
      name: "Cherif Al Idrissi Airport",
      city: "Al Hociema",
      country: "Morocco",
      value: "AHU",
      display: "AHU"
    },
    {
      name: "Saniat R'mel Airport",
      city: "Tetouan",
      country: "Morocco",
      value: "TTU",
      display: "TTU"
    },
    {
      name: "Ibn Batouta Airport",
      city: "Tangier",
      country: "Morocco",
      value: "TNG",
      display: "TNG"
    },
    {
      name: "Ziguinchor Airport",
      city: "Ziguinchor",
      country: "Senegal",
      value: "ZIG",
      display: "ZIG"
    },
    {
      name: "Cap Skirring Airport",
      city: "Cap Skiring",
      country: "Senegal",
      value: "CSK",
      display: "CSK"
    },
    {
      name: "Kaolack Airport",
      city: "Kaolack",
      country: "Senegal",
      value: "KLC",
      display: "KLC"
    },
    {
      name: "Leopold Sedar Senghor International Airport",
      city: "Dakar",
      country: "Senegal",
      value: "DKR",
      display: "DKR"
    },
    {
      name: "Saint Louis Airport",
      city: "St. Louis",
      country: "Senegal",
      value: "XLS",
      display: "XLS"
    },
    {
      name: "Bakel Airport",
      city: "Bakel",
      country: "Senegal",
      value: "BXE",
      display: "BXE"
    },
    {
      name: "Kedougou Airport",
      city: "Kedougou",
      country: "Senegal",
      value: "KGG",
      display: "KGG"
    },
    {
      name: "Tambacounda Airport",
      city: "Tambacounda",
      country: "Senegal",
      value: "TUD",
      display: "TUD"
    },
    {
      name: "Aioun el Atrouss Airport",
      city: "Aioun El Atrouss",
      country: "Mauritania",
      value: "IEO",
      display: "IEO"
    },
    {
      name: "Tidjikja Airport",
      city: "Tidjikja",
      country: "Mauritania",
      value: "TIY",
      display: "TIY"
    },
    {
      name: "Kiffa Airport",
      city: "Kiffa",
      country: "Mauritania",
      value: "KFA",
      display: "KFA"
    },
    {
      name: "Nema Airport",
      city: "Nema",
      country: "Mauritania",
      value: "EMN",
      display: "EMN"
    },
    {
      name: "Kaedi Airport",
      city: "Kaedi",
      country: "Mauritania",
      value: "KED",
      display: "KED"
    },
    {
      name: "Nouakchott International Airport",
      city: "Nouakschott",
      country: "Mauritania",
      value: "NKC",
      display: "NKC"
    },
    {
      name: "Selibaby Airport",
      city: "Selibabi",
      country: "Mauritania",
      value: "SEY",
      display: "SEY"
    },
    {
      name: "Atar International Airport",
      city: "Atar",
      country: "Mauritania",
      value: "ATR",
      display: "ATR"
    },
    {
      name: "Nouadhibou International Airport",
      city: "Nouadhibou",
      country: "Mauritania",
      value: "NDB",
      display: "NDB"
    },
    {
      name: "Fria Airport",
      city: "Fira",
      country: "Guinea",
      value: "FIG",
      display: "FIG"
    },
    {
      name: "Faranah Airport",
      city: "Faranah",
      country: "Guinea",
      value: "FAA",
      display: "FAA"
    },
    {
      name: "Tata Airport",
      city: "Labe",
      country: "Guinea",
      value: "LEK",
      display: "LEK"
    },
    {
      name: "Amilcar Cabral International Airport",
      city: "Amilcar Cabral",
      country: "Cape Verde",
      value: "SID",
      display: "SID"
    },
    {
      name: "Rabil Airport",
      city: "Boa Vista",
      country: "Cape Verde",
      value: "BVC",
      display: "BVC"
    },
    {
      name: "Maio Airport",
      city: "Maio",
      country: "Cape Verde",
      value: "MMO",
      display: "MMO"
    },
    {
      name: "Preguica Airport",
      city: "Sao Nocolau Island",
      country: "Cape Verde",
      value: "SNE",
      display: "SNE"
    },
    {
      name: "Sao Pedro Airport",
      city: "Sao Vicente Island",
      country: "Cape Verde",
      value: "VXE",
      display: "VXE"
    },
    {
      name: "Addis Ababa Bole International Airport",
      city: "Addis Ababa",
      country: "Ethiopia",
      value: "ADD",
      display: "ADD"
    },
    {
      name: "Arba Minch Airport",
      city: "Arba Minch",
      country: "Ethiopia",
      value: "AMH",
      display: "AMH"
    },
    {
      name: "Axum Airport",
      city: "Axum",
      country: "Ethiopia",
      value: "AXU",
      display: "AXU"
    },
    {
      name: "Bahir Dar Airport",
      city: "Bahar Dar",
      country: "Ethiopia",
      value: "BJR",
      display: "BJR"
    },
    {
      name: "Aba Tenna Dejazmach Yilma International Airport",
      city: "Dire Dawa",
      country: "Ethiopia",
      value: "DIR",
      display: "DIR"
    },
    {
      name: "Gambella Airport",
      city: "Gambella",
      country: "Ethiopia",
      value: "GMB",
      display: "GMB"
    },
    {
      name: "Gonder Airport",
      city: "Gondar",
      country: "Ethiopia",
      value: "GDQ",
      display: "GDQ"
    },
    {
      name: "Jimma Airport",
      city: "Jimma",
      country: "Ethiopia",
      value: "JIM",
      display: "JIM"
    },
    {
      name: "Lalibella Airport",
      city: "Lalibella",
      country: "Ethiopia",
      value: "LLI",
      display: "LLI"
    },
    {
      name: "Mekele Airport",
      city: "Makale",
      country: "Ethiopia",
      value: "MQX",
      display: "MQX"
    },
    {
      name: "Asosa Airport",
      city: "Asosa",
      country: "Ethiopia",
      value: "ASO",
      display: "ASO"
    },
    {
      name: "Bujumbura International Airport",
      city: "Bujumbura",
      country: "Burundi",
      value: "BJM",
      display: "BJM"
    },
    {
      name: "Egal International Airport",
      city: "Hargeisa",
      country: "Somalia",
      value: "HGA",
      display: "HGA"
    },
    {
      name: "Berbera Airport",
      city: "Berbera",
      country: "Somalia",
      value: "BBO",
      display: "BBO"
    },
    {
      name: "Kisimayu Airport",
      city: "Kismayu",
      country: "Somalia",
      value: "KMU",
      display: "KMU"
    },
    {
      name: "El Nouzha Airport",
      city: "Alexandria",
      country: "Egypt",
      value: "ALY",
      display: "ALY"
    },
    {
      name: "Abu Simbel Airport",
      city: "Abu Simbel",
      country: "Egypt",
      value: "ABS",
      display: "ABS"
    },
    {
      name: "Cairo International Airport",
      city: "Cairo",
      country: "Egypt",
      value: "CAI",
      display: "CAI"
    },
    {
      name: "Hurghada International Airport",
      city: "Hurghada",
      country: "Egypt",
      value: "HRG",
      display: "HRG"
    },
    {
      name: "El Gora Airport",
      city: "El Gorah",
      country: "Egypt",
      value: "EGR",
      display: "EGR"
    },
    {
      name: "Luxor International Airport",
      city: "Luxor",
      country: "Egypt",
      value: "LXR",
      display: "LXR"
    },
    {
      name: "Mersa Matruh Airport",
      city: "Mersa-matruh",
      country: "Egypt",
      value: "MUH",
      display: "MUH"
    },
    {
      name: "Port Said Airport",
      city: "Port Said",
      country: "Egypt",
      value: "PSD",
      display: "PSD"
    },
    {
      name: "St Catherine International Airport",
      city: "St. Catherine",
      country: "Egypt",
      value: "SKV",
      display: "SKV"
    },
    {
      name: "Aswan International Airport",
      city: "Aswan",
      country: "Egypt",
      value: "ASW",
      display: "ASW"
    },
    {
      name: "El Tor Airport",
      city: "El-tor",
      country: "Egypt",
      value: "ELT",
      display: "ELT"
    },
    {
      name: "Eldoret International Airport",
      city: "Eldoret",
      country: "Kenya",
      value: "EDL",
      display: "EDL"
    },
    {
      name: "Kisumu Airport",
      city: "Kisumu",
      country: "Kenya",
      value: "KIS",
      display: "KIS"
    },
    {
      name: "Kitale Airport",
      city: "Kitale",
      country: "Kenya",
      value: "KTL",
      display: "KTL"
    },
    {
      name: "Lodwar Airport",
      city: "Lodwar",
      country: "Kenya",
      value: "LOK",
      display: "LOK"
    },
    {
      name: "Manda Airstrip",
      city: "Lamu",
      country: "Kenya",
      value: "LAU",
      display: "LAU"
    },
    {
      name: "Mombasa Moi International Airport",
      city: "Mombasa",
      country: "Kenya",
      value: "MBA",
      display: "MBA"
    },
    {
      name: "Nairobi Wilson Airport",
      city: "Nairobi",
      country: "Kenya",
      value: "WIL",
      display: "WIL"
    },
    {
      name: "Wajir Airport",
      city: "Wajir",
      country: "Kenya",
      value: "WJR",
      display: "WJR"
    },
    {
      name: "Ghat Airport",
      city: "Ghat",
      country: "Libya",
      value: "GHT",
      display: "GHT"
    },
    {
      name: "Kufra Airport",
      city: "Kufra",
      country: "Libya",
      value: "AKF",
      display: "AKF"
    },
    {
      name: "Benina International Airport",
      city: "Benghazi",
      country: "Libya",
      value: "BEN",
      display: "BEN"
    },
    {
      name: "Sabha Airport",
      city: "Sebha",
      country: "Libya",
      value: "SEB",
      display: "SEB"
    },
    {
      name: "Tripoli International Airport",
      city: "Tripoli",
      country: "Libya",
      value: "TIP",
      display: "TIP"
    },
    {
      name: "Ghadames East Airport",
      city: "Ghadames",
      country: "Libya",
      value: "LTD",
      display: "LTD"
    },
    {
      name: "Gisenyi Airport",
      city: "Gisenyi",
      country: "Rwanda",
      value: "GYI",
      display: "GYI"
    },
    {
      name: "Kigali International Airport",
      city: "Kigali",
      country: "Rwanda",
      value: "KGL",
      display: "KGL"
    },
    {
      name: "Kamembe Airport",
      city: "Kamembe",
      country: "Rwanda",
      value: "KME",
      display: "KME"
    },
    {
      name: "Dongola Airport",
      city: "Dongola",
      country: "Sudan",
      value: "DOG",
      display: "DOG"
    },
    {
      name: "El Fasher Airport",
      city: "El Fasher",
      country: "Sudan",
      value: "ELF",
      display: "ELF"
    },
    {
      name: "Kassala Airport",
      city: "Kassala",
      country: "Sudan",
      value: "KSL",
      display: "KSL"
    },
    {
      name: "El Obeid Airport",
      city: "El Obeid",
      country: "Sudan",
      value: "EBD",
      display: "EBD"
    },
    {
      name: "Juba International Airport",
      city: "Juba",
      country: "South Sudan",
      value: "JUB",
      display: "JUB"
    },
    {
      name: "Malakal Airport",
      city: "Malakal",
      country: "Sudan",
      value: "MAK",
      display: "MAK"
    },
    {
      name: "Khartoum International Airport",
      city: "Khartoum",
      country: "Sudan",
      value: "KRT",
      display: "KRT"
    },
    {
      name: "Arusha Airport",
      city: "Arusha",
      country: "Tanzania",
      value: "ARK",
      display: "ARK"
    },
    {
      name: "Julius Nyerere International Airport",
      city: "Dar Es Salaam",
      country: "Tanzania",
      value: "DAR",
      display: "DAR"
    },
    {
      name: "Dodoma Airport",
      city: "Dodoma",
      country: "Tanzania",
      value: "DOD",
      display: "DOD"
    },
    {
      name: "Iringa Airport",
      city: "Iringa",
      country: "Tanzania",
      value: "IRI",
      display: "IRI"
    },
    {
      name: "Kilimanjaro International Airport",
      city: "Kilimanjaro",
      country: "Tanzania",
      value: "JRO",
      display: "JRO"
    },
    {
      name: "Lake Manyara Airport",
      city: "Lake Manyara",
      country: "Tanzania",
      value: "LKY",
      display: "LKY"
    },
    {
      name: "Mtwara Airport",
      city: "Mtwara",
      country: "Tanzania",
      value: "MYW",
      display: "MYW"
    },
    {
      name: "Mwanza Airport",
      city: "Mwanza",
      country: "Tanzania",
      value: "MWZ",
      display: "MWZ"
    },
    {
      name: "Pemba Airport",
      city: "Pemba",
      country: "Tanzania",
      value: "PMA",
      display: "PMA"
    },
    {
      name: "Tanga Airport",
      city: "Tanga",
      country: "Tanzania",
      value: "TGT",
      display: "TGT"
    },
    {
      name: "Abeid Amani Karume International Airport",
      city: "Zanzibar",
      country: "Tanzania",
      value: "ZNZ",
      display: "ZNZ"
    },
    {
      name: "Entebbe International Airport",
      city: "Entebbe",
      country: "Uganda",
      value: "EBB",
      display: "EBB"
    },
    {
      name: "Soroti Airport",
      city: "Soroti",
      country: "Uganda",
      value: "SRT",
      display: "SRT"
    },
    {
      name: "Tirana International Airport Mother Teresa",
      city: "Tirana",
      country: "Albania",
      value: "TIA",
      display: "TIA"
    },
    {
      name: "Burgas Airport",
      city: "Bourgas",
      country: "Bulgaria",
      value: "BOJ",
      display: "BOJ"
    },
    {
      name: "Gorna Oryahovitsa Airport",
      city: "Gorna Orechovica",
      country: "Bulgaria",
      value: "GOZ",
      display: "GOZ"
    },
    {
      name: "Plovdiv International Airport",
      city: "Plovdiv",
      country: "Bulgaria",
      value: "PDV",
      display: "PDV"
    },
    {
      name: "Sofia Airport",
      city: "Sofia",
      country: "Bulgaria",
      value: "SOF",
      display: "SOF"
    },
    {
      name: "Varna Airport",
      city: "Varna",
      country: "Bulgaria",
      value: "VAR",
      display: "VAR"
    },
    {
      name: "Larnaca International Airport",
      city: "Larnaca",
      country: "Cyprus",
      value: "LCA",
      display: "LCA"
    },
    {
      name: "Paphos International Airport",
      city: "Paphos",
      country: "Cyprus",
      value: "PFO",
      display: "PFO"
    },
    {
      name: "RAF Akrotiri",
      city: "Akrotiri",
      country: "Cyprus",
      value: "AKT",
      display: "AKT"
    },
    {
      name: "Dubrovnik Airport",
      city: "Dubrovnik",
      country: "Croatia",
      value: "DBV",
      display: "DBV"
    },
    {
      name: "Osijek Airport",
      city: "Osijek",
      country: "Croatia",
      value: "OSI",
      display: "OSI"
    },
    {
      name: "Pula Airport",
      city: "Pula",
      country: "Croatia",
      value: "PUY",
      display: "PUY"
    },
    {
      name: "Rijeka Airport",
      city: "Rijeka",
      country: "Croatia",
      value: "RJK",
      display: "RJK"
    },
    {
      name: "Split Airport",
      city: "Split",
      country: "Croatia",
      value: "SPU",
      display: "SPU"
    },
    {
      name: "Zagreb Airport",
      city: "Zagreb",
      country: "Croatia",
      value: "ZAG",
      display: "ZAG"
    },
    {
      name: "Zemunik Airport",
      city: "Zadar",
      country: "Croatia",
      value: "ZAD",
      display: "ZAD"
    },
    {
      name: "Alicante International Airport",
      city: "Alicante",
      country: "Spain",
      value: "ALC",
      display: "ALC"
    },
    {
      name: "Almeria International Airport",
      city: "Almeria",
      country: "Spain",
      value: "LEI",
      display: "LEI"
    },
    {
      name: "Asturias Airport",
      city: "Aviles",
      country: "Spain",
      value: "OVD",
      display: "OVD"
    },
    {
      name: "Cordoba Airport",
      city: "Cordoba",
      country: "Spain",
      value: "ODB",
      display: "ODB"
    },
    {
      name: "Bilbao Airport",
      city: "Bilbao",
      country: "Spain",
      value: "BIO",
      display: "BIO"
    },
    {
      name: "Barcelona International Airport",
      city: "Barcelona",
      country: "Spain",
      value: "BCN",
      display: "BCN"
    },
    {
      name: "Badajoz Airport",
      city: "Badajoz",
      country: "Spain",
      value: "BJZ",
      display: "BJZ"
    },
    {
      name: "A Coruna Airport",
      city: "La Coruna",
      country: "Spain",
      value: "LCG",
      display: "LCG"
    },
    {
      name: "Girona Airport",
      city: "Gerona",
      country: "Spain",
      value: "GRO",
      display: "GRO"
    },
    {
      name: "Federico Garcia Lorca Airport",
      city: "Granada",
      country: "Spain",
      value: "GRX",
      display: "GRX"
    },
    {
      name: "Ibiza Airport",
      city: "Ibiza",
      country: "Spain",
      value: "IBZ",
      display: "IBZ"
    },
    {
      name: "Jerez Airport",
      city: "Jerez",
      country: "Spain",
      value: "XRY",
      display: "XRY"
    },
    {
      name: "San Javier Airport",
      city: "Murcia",
      country: "Spain",
      value: "MJV",
      display: "MJV"
    },
    {
      name: "Adolfo Suarez Madrid - Barajas Airport",
      city: "Madrid",
      country: "Spain",
      value: "MAD",
      display: "MAD"
    },
    {
      name: "Malaga Airport",
      city: "Malaga",
      country: "Spain",
      value: "AGP",
      display: "AGP"
    },
    {
      name: "Menorca Airport",
      city: "Menorca",
      country: "Spain",
      value: "MAH",
      display: "MAH"
    },
    {
      name: "Moron Air Base",
      city: "Sevilla",
      country: "Spain",
      value: "OZP",
      display: "OZP"
    },
    {
      name: "Pamplona Airport",
      city: "Pamplona",
      country: "Spain",
      value: "PNA",
      display: "PNA"
    },
    {
      name: "Reus Air Base",
      city: "Reus",
      country: "Spain",
      value: "REU",
      display: "REU"
    },
    {
      name: "Salamanca Airport",
      city: "Salamanca",
      country: "Spain",
      value: "SLM",
      display: "SLM"
    },
    {
      name: "San Sebastian Airport",
      city: "San Sebastian",
      country: "Spain",
      value: "EAS",
      display: "EAS"
    },
    {
      name: "Santiago de Compostela Airport",
      city: "Santiago",
      country: "Spain",
      value: "SCQ",
      display: "SCQ"
    },
    {
      name: "Pirineus - la Seu d'Urgel Airport",
      city: "Seo De Urgel",
      country: "Spain",
      value: "LEU",
      display: "LEU"
    },
    {
      name: "Torrejon Airport",
      city: "Madrid",
      country: "Spain",
      value: "TOJ",
      display: "TOJ"
    },
    {
      name: "Valencia Airport",
      city: "Valencia",
      country: "Spain",
      value: "VLC",
      display: "VLC"
    },
    {
      name: "Valladolid Airport",
      city: "Valladolid",
      country: "Spain",
      value: "VLL",
      display: "VLL"
    },
    {
      name: "Vitoria/Foronda Airport",
      city: "Vitoria",
      country: "Spain",
      value: "VIT",
      display: "VIT"
    },
    {
      name: "Vigo Airport",
      city: "Vigo",
      country: "Spain",
      value: "VGO",
      display: "VGO"
    },
    {
      name: "Santander Airport",
      city: "Santander",
      country: "Spain",
      value: "SDR",
      display: "SDR"
    },
    {
      name: "Zaragoza Air Base",
      city: "Zaragoza",
      country: "Spain",
      value: "ZAZ",
      display: "ZAZ"
    },
    {
      name: "Sevilla Airport",
      city: "Sevilla",
      country: "Spain",
      value: "SVQ",
      display: "SVQ"
    },
    {
      name: "Calais-Dunkerque Airport",
      city: "Calais",
      country: "France",
      value: "CQF",
      display: "CQF"
    },
    {
      name: "Le Touquet-Cote d'Opale Airport",
      city: "Le Tourquet",
      country: "France",
      value: "LTQ",
      display: "LTQ"
    },
    {
      name: "Agen-La Garenne Airport",
      city: "Agen",
      country: "France",
      value: "AGF",
      display: "AGF"
    },
    {
      name: "Bordeaux-Merignac Airport",
      city: "Bordeaux",
      country: "France",
      value: "BOD",
      display: "BOD"
    },
    {
      name: "Bergerac-Roumaniere Airport",
      city: "Bergerac",
      country: "France",
      value: "EGC",
      display: "EGC"
    },
    {
      name: "Cognac-Chateaubernard (BA 709) Air Base",
      city: "Cognac",
      country: "France",
      value: "CNG",
      display: "CNG"
    },
    {
      name: "Poitiers-Biard Airport",
      city: "Poitiers",
      country: "France",
      value: "PIS",
      display: "PIS"
    },
    {
      name: "Montlucon-Gueret Airport",
      city: "Montlucon-gueret",
      country: "France",
      value: "MCU",
      display: "MCU"
    },
    {
      name: "Limoges Airport",
      city: "Limoges",
      country: "France",
      value: "LIG",
      display: "LIG"
    },
    {
      name: "Niort-Souche Airport",
      city: "Niort",
      country: "France",
      value: "NIT",
      display: "NIT"
    },
    {
      name: "Toulouse-Blagnac Airport",
      city: "Toulouse",
      country: "France",
      value: "TLS",
      display: "TLS"
    },
    {
      name: "Pau Pyrenees Airport",
      city: "Pau",
      country: "France",
      value: "PUF",
      display: "PUF"
    },
    {
      name: "Tarbes-Lourdes-Pyrenees Airport",
      city: "Tarbes",
      country: "France",
      value: "LDE",
      display: "LDE"
    },
    {
      name: "Angouleme-Brie-Champniers Airport",
      city: "Angouleme",
      country: "France",
      value: "ANG",
      display: "ANG"
    },
    {
      name: "Toul Rosieres Air Base",
      city: "Brive",
      country: "France",
      value: "BVE",
      display: "BVE"
    },
    {
      name: "Perigueux-Bassillac Airport",
      city: "Perigueux",
      country: "France",
      value: "PGX",
      display: "PGX"
    },
    {
      name: "Biarritz-Anglet-Bayonne Airport",
      city: "Biarritz-bayonne",
      country: "France",
      value: "BIQ",
      display: "BIQ"
    },
    {
      name: "Arcachon-La Teste-de-Buch Airport",
      city: "Arcachon",
      country: "France",
      value: "XAC",
      display: "XAC"
    },
    {
      name: "Albi-Le Sequestre Airport",
      city: "Albi",
      country: "France",
      value: "LBI",
      display: "LBI"
    },
    {
      name: "Castres-Mazamet Airport",
      city: "Castres",
      country: "France",
      value: "DCM",
      display: "DCM"
    },
    {
      name: "Rodez-Marcillac Airport",
      city: "Rodez",
      country: "France",
      value: "RDZ",
      display: "RDZ"
    },
    {
      name: "Royan-Medis Airport",
      city: "Royan",
      country: "France",
      value: "RYN",
      display: "RYN"
    },
    {
      name: "Rochefort-Saint-Agnant (BA 721) Airport",
      city: "Rochefort",
      country: "France",
      value: "RCO",
      display: "RCO"
    },
    {
      name: "Colmar-Houssen Airport",
      city: "Colmar",
      country: "France",
      value: "CMR",
      display: "CMR"
    },
    {
      name: "Dole-Tavaux Airport",
      city: "Dole",
      country: "France",
      value: "DLE",
      display: "DLE"
    },
    {
      name: "Aubenas-Ardeche Meridional Airport",
      city: "Aubenas-vals-lanas",
      country: "France",
      value: "OBS",
      display: "OBS"
    },
    {
      name: "Le Puy-Loudes Airport",
      city: "Le Puy",
      country: "France",
      value: "LPY",
      display: "LPY"
    },
    {
      name: "Bourg-Ceyzeriat Airport",
      city: "Bourg",
      country: "France",
      value: "XBK",
      display: "XBK"
    },
    {
      name: "Villefranche-Tarare Airport",
      city: "Vilefrance",
      country: "France",
      value: "XVF",
      display: "XVF"
    },
    {
      name: "Moulins-Montbeugny Airport",
      city: "Moulins",
      country: "France",
      value: "XMU",
      display: "XMU"
    },
    {
      name: "Metz-Nancy-Lorraine Airport",
      city: "Metz",
      country: "France",
      value: "ETZ",
      display: "ETZ"
    },
    {
      name: "Bastia-Poretta Airport",
      city: "Bastia",
      country: "France",
      value: "BIA",
      display: "BIA"
    },
    {
      name: "Calvi-Sainte-Catherine Airport",
      city: "Calvi",
      country: "France",
      value: "CLY",
      display: "CLY"
    },
    {
      name: "Figari Sud-Corse Airport",
      city: "Figari",
      country: "France",
      value: "FSC",
      display: "FSC"
    },
    {
      name: "Ajaccio-Napoleon Bonaparte Airport",
      city: "Ajaccio",
      country: "France",
      value: "AJA",
      display: "AJA"
    },
    {
      name: "Solenzara (BA 126) Air Base",
      city: "Solenzara",
      country: "France",
      value: "SOZ",
      display: "SOZ"
    },
    {
      name: "Auxerre-Branches Airport",
      city: "Auxerre",
      country: "France",
      value: "AUF",
      display: "AUF"
    },
    {
      name: "Chambery-Savoie Airport",
      city: "Chambery",
      country: "France",
      value: "CMF",
      display: "CMF"
    },
    {
      name: "Clermont-Ferrand Auvergne Airport",
      city: "Clermont-Ferrand",
      country: "France",
      value: "CFE",
      display: "CFE"
    },
    {
      name: "Bourges Airport",
      city: "Bourges",
      country: "France",
      value: "BOU",
      display: "BOU"
    },
    {
      name: "Chalon-Champforgeuil Airport",
      city: "Chalon",
      country: "France",
      value: "XCD",
      display: "XCD"
    },
    {
      name: "Annemasse Airport",
      city: "Annemasse",
      country: "France",
      value: "QNJ",
      display: "QNJ"
    },
    {
      name: "Lyon Saint-Exupery Airport",
      city: "Lyon",
      country: "France",
      value: "LYS",
      display: "LYS"
    },
    {
      name: "Macon-Charnay Airport",
      city: "Macon",
      country: "France",
      value: "QNX",
      display: "QNX"
    },
    {
      name: "Roanne-Renaison Airport",
      city: "Roanne",
      country: "France",
      value: "RNE",
      display: "RNE"
    },
    {
      name: "Annecy-Haute-Savoie-Mont Blanc Airport",
      city: "Annecy",
      country: "France",
      value: "NCY",
      display: "NCY"
    },
    {
      name: "Grenoble-Isere Airport",
      city: "Grenoble",
      country: "France",
      value: "GNB",
      display: "GNB"
    },
    {
      name: "Valence-Chabeuil Airport",
      city: "Valence",
      country: "France",
      value: "VAF",
      display: "VAF"
    },
    {
      name: "Vichy-Charmeil Airport",
      city: "Vichy",
      country: "France",
      value: "VHY",
      display: "VHY"
    },
    {
      name: "Aurillac Airport",
      city: "Aurillac",
      country: "France",
      value: "AUR",
      display: "AUR"
    },
    {
      name: "Lyon-Bron Airport",
      city: "Lyon",
      country: "France",
      value: "LYN",
      display: "LYN"
    },
    {
      name: "Aix-en-Provence (BA 114) Airport",
      city: "Aix-les-milles",
      country: "France",
      value: "QXB",
      display: "QXB"
    },
    {
      name: "Cannes-Mandelieu Airport",
      city: "Cannes",
      country: "France",
      value: "CEQ",
      display: "CEQ"
    },
    {
      name: "Saint-Etienne-Boutheon Airport",
      city: "St-Etienne",
      country: "France",
      value: "EBU",
      display: "EBU"
    },
    {
      name: "Carcassonne Airport",
      city: "Carcassonne",
      country: "France",
      value: "CCF",
      display: "CCF"
    },
    {
      name: "Marseille Provence Airport",
      city: "Marseille",
      country: "France",
      value: "MRS",
      display: "MRS"
    },
    {
      name: "Nice-Cote d'Azur Airport",
      city: "Nice",
      country: "France",
      value: "NCE",
      display: "NCE"
    },
    {
      name: "Perpignan-Rivesaltes (Llabanere) Airport",
      city: "Perpignan",
      country: "France",
      value: "PGF",
      display: "PGF"
    },
    {
      name: "Le Castellet Airport",
      city: "Le Castellet",
      country: "France",
      value: "CTT",
      display: "CTT"
    },
    {
      name: "Montpellier-Mediterranee Airport",
      city: "Montpellier",
      country: "France",
      value: "MPL",
      display: "MPL"
    },
    {
      name: "Beziers-Vias Airport",
      city: "Beziers",
      country: "France",
      value: "BZR",
      display: "BZR"
    },
    {
      name: "Avignon-Caumont Airport",
      city: "Avignon",
      country: "France",
      value: "AVN",
      display: "AVN"
    },
    {
      name: "Mende-Brenoux Airport",
      city: "Mende",
      country: "France",
      value: "MEN",
      display: "MEN"
    },
    {
      name: "Paris Beauvais Tille Airport",
      city: "Beauvais",
      country: "France",
      value: "BVA",
      display: "BVA"
    },
    {
      name: "Le Havre Octeville Airport",
      city: "Le Havre",
      country: "France",
      value: "LEH",
      display: "LEH"
    },
    {
      name: "Orleans-Bricy (BA 123) Air Base",
      city: "Orleans",
      country: "France",
      value: "ORE",
      display: "ORE"
    },
    {
      name: "Chalons-Vatry Air Base",
      city: "Chalons",
      country: "France",
      value: "XCR",
      display: "XCR"
    },
    {
      name: "Rouen Airport",
      city: "Rouen",
      country: "France",
      value: "URO",
      display: "URO"
    },
    {
      name: "Tours-Val-de-Loire Airport",
      city: "Tours",
      country: "France",
      value: "TUF",
      display: "TUF"
    },
    {
      name: "Cholet Le Pontreau Airport",
      city: "Cholet",
      country: "France",
      value: "CET",
      display: "CET"
    },
    {
      name: "Laval-Entrammes Airport",
      city: "Laval",
      country: "France",
      value: "LVA",
      display: "LVA"
    },
    {
      name: "Paris-Le Bourget Airport",
      city: "Paris",
      country: "France",
      value: "LBG",
      display: "LBG"
    },
    {
      name: "Creil Air Base",
      city: "Creil",
      country: "France",
      value: "CSF",
      display: "CSF"
    },
    {
      name: "Charles de Gaulle International Airport",
      city: "Paris",
      country: "France",
      value: "CDG",
      display: "CDG"
    },
    {
      name: "Toussus-le-Noble Airport",
      city: "Toussous-le-noble",
      country: "France",
      value: "TNF",
      display: "TNF"
    },
    {
      name: "Paris-Orly Airport",
      city: "Paris",
      country: "France",
      value: "ORY",
      display: "ORY"
    },
    {
      name: "Pontoise - Cormeilles-en-Vexin Airport",
      city: "Pontoise",
      country: "France",
      value: "POX",
      display: "POX"
    },
    {
      name: "Troyes-Barberey Airport",
      city: "Troyes",
      country: "France",
      value: "QYR",
      display: "QYR"
    },
    {
      name: "Nevers-Fourchambault Airport",
      city: "Nevers",
      country: "France",
      value: "NVS",
      display: "NVS"
    },
    {
      name: "Lille-Lesquin Airport",
      city: "Lille",
      country: "France",
      value: "LIL",
      display: "LIL"
    },
    {
      name: "Brest Bretagne Airport",
      city: "Brest",
      country: "France",
      value: "BES",
      display: "BES"
    },
    {
      name: "Cherbourg-Maupertus Airport",
      city: "Cherbourg",
      country: "France",
      value: "CER",
      display: "CER"
    },
    {
      name: "Dinard-Pleurtuit-Saint-Malo Airport",
      city: "Dinard",
      country: "France",
      value: "DNR",
      display: "DNR"
    },
    {
      name: "Granville Airport",
      city: "Granville",
      country: "France",
      value: "GFR",
      display: "GFR"
    },
    {
      name: "Deauville-Saint-Gatien Airport",
      city: "Deauville",
      country: "France",
      value: "DOL",
      display: "DOL"
    },
    {
      name: "Lorient South Brittany (Bretagne Sud) Airport",
      city: "Lorient",
      country: "France",
      value: "LRT",
      display: "LRT"
    },
    {
      name: "La Roche-sur-Yon Airport",
      city: "La Roche-sur-yon",
      country: "France",
      value: "EDM",
      display: "EDM"
    },
    {
      name: "Caen-Carpiquet Airport",
      city: "Caen",
      country: "France",
      value: "CFR",
      display: "CFR"
    },
    {
      name: "Le Mans-Arnage Airport",
      city: "Le Mans",
      country: "France",
      value: "LME",
      display: "LME"
    },
    {
      name: "Rennes-Saint-Jacques Airport",
      city: "Rennes",
      country: "France",
      value: "RNS",
      display: "RNS"
    },
    {
      name: "Lannion-Cote de Granit Airport",
      city: "Lannion",
      country: "France",
      value: "LAI",
      display: "LAI"
    },
    {
      name: "Quimper-Cornouaille Airport",
      city: "Quimper",
      country: "France",
      value: "UIP",
      display: "UIP"
    },
    {
      name: "Nantes Atlantique Airport",
      city: "Nantes",
      country: "France",
      value: "NTE",
      display: "NTE"
    },
    {
      name: "Saint-Brieuc-Armor Airport",
      city: "St.-brieuc Armor",
      country: "France",
      value: "SBK",
      display: "SBK"
    },
    {
      name: "Morlaix-Ploujean Airport",
      city: "Morlaix",
      country: "France",
      value: "MXN",
      display: "MXN"
    },
    {
      name: "Vannes-Meucon Airport",
      city: "Vannes",
      country: "France",
      value: "VNE",
      display: "VNE"
    },
    {
      name: "Saint-Nazaire-Montoir Airport",
      city: "St.- Nazaire",
      country: "France",
      value: "SNR",
      display: "SNR"
    },
    {
      name: "Basel-Mulhouse-Freiburg EuroAirport",
      city: "Basel",
      country: "Switzerland",
      value: "BSL",
      display: "BSL"
    },
    {
      name: "Basel-Mulhouse-Freiburg EuroAirport",
      city: "Mulhouse",
      country: "France",
      value: "MLH",
      display: "MLH"
    },
    {
      name: "Dijon-Bourgogne Airport",
      city: "Dijon",
      country: "France",
      value: "DIJ",
      display: "DIJ"
    },
    {
      name: "Metz-Frescaty (BA 128) Air Base",
      city: "Metz",
      country: "France",
      value: "MZM",
      display: "MZM"
    },
    {
      name: "Epinal-Mirecourt Airport",
      city: "Epinal",
      country: "France",
      value: "EPL",
      display: "EPL"
    },
    {
      name: "Nancy-Essey Airport",
      city: "Nancy",
      country: "France",
      value: "ENC",
      display: "ENC"
    },
    {
      name: "Reims-Champagne (BA 112) Air Base",
      city: "Reims",
      country: "France",
      value: "RHE",
      display: "RHE"
    },
    {
      name: "Strasbourg Airport",
      city: "Strasbourg",
      country: "France",
      value: "SXB",
      display: "SXB"
    },
    {
      name: "Toulon-Hyeres Airport",
      city: "Hyeres",
      country: "France",
      value: "TLN",
      display: "TLN"
    },
    {
      name: "Nimes-Arles-Camargue Airport",
      city: "Nimes",
      country: "France",
      value: "FNI",
      display: "FNI"
    },
    {
      name: "Miquelon Airport",
      city: "Miquelon",
      country: "Saint Pierre and Miquelon",
      value: "MQC",
      display: "MQC"
    },
    {
      name: "St Pierre Airport",
      city: "St Pierre",
      country: "Saint Pierre and Miquelon",
      value: "FSP",
      display: "FSP"
    },
    {
      name: "Andravida Air Base",
      city: "Andravida",
      country: "Greece",
      value: "PYR",
      display: "PYR"
    },
    {
      name: "Agrinion Air Base",
      city: "Agrinion",
      country: "Greece",
      value: "AGQ",
      display: "AGQ"
    },
    {
      name: "Dimokritos Airport",
      city: "Alexandroupolis",
      country: "Greece",
      value: "AXD",
      display: "AXD"
    },
    {
      name: "Nea Anchialos Airport",
      city: "Nea Anghialos",
      country: "Greece",
      value: "VOL",
      display: "VOL"
    },
    {
      name: "Chios Island National Airport",
      city: "Chios",
      country: "Greece",
      value: "JKH",
      display: "JKH"
    },
    {
      name: "Ioannina Airport",
      city: "Ioannina",
      country: "Greece",
      value: "IOA",
      display: "IOA"
    },
    {
      name: "Heraklion International Nikos Kazantzakis Airport",
      city: "Heraklion",
      country: "Greece",
      value: "HER",
      display: "HER"
    },
    {
      name: "Kastoria National Airport",
      city: "Kastoria",
      country: "Greece",
      value: "KSO",
      display: "KSO"
    },
    {
      name: "Kithira Airport",
      city: "Kithira",
      country: "Greece",
      value: "KIT",
      display: "KIT"
    },
    {
      name: "Kefallinia Airport",
      city: "Keffallinia",
      country: "Greece",
      value: "EFL",
      display: "EFL"
    },
    {
      name: "Kalamata Airport",
      city: "Kalamata",
      country: "Greece",
      value: "KLX",
      display: "KLX"
    },
    {
      name: "Kos Airport",
      city: "Kos",
      country: "Greece",
      value: "KGS",
      display: "KGS"
    },
    {
      name: "Karpathos Airport",
      city: "Karpathos",
      country: "Greece",
      value: "AOK",
      display: "AOK"
    },
    {
      name: "Ioannis Kapodistrias International Airport",
      city: "Kerkyra/corfu",
      country: "Greece",
      value: "CFU",
      display: "CFU"
    },
    {
      name: "Kasos Airport",
      city: "Kasos",
      country: "Greece",
      value: "KSJ",
      display: "KSJ"
    },
    {
      name: "Alexander the Great International Airport",
      city: "Kavala",
      country: "Greece",
      value: "KVA",
      display: "KVA"
    },
    {
      name: "Filippos Airport",
      city: "Kozani",
      country: "Greece",
      value: "KZI",
      display: "KZI"
    },
    {
      name: "Leros Airport",
      city: "Leros",
      country: "Greece",
      value: "LRS",
      display: "LRS"
    },
    {
      name: "Limnos Airport",
      city: "Limnos",
      country: "Greece",
      value: "LXS",
      display: "LXS"
    },
    {
      name: "Larisa Airport",
      city: "Larissa",
      country: "Greece",
      value: "LRA",
      display: "LRA"
    },
    {
      name: "Mikonos Airport",
      city: "Mykonos",
      country: "Greece",
      value: "JMK",
      display: "JMK"
    },
    {
      name: "Mytilene International Airport",
      city: "Mytilini",
      country: "Greece",
      value: "MJT",
      display: "MJT"
    },
    {
      name: "Aktion National Airport",
      city: "Preveza",
      country: "Greece",
      value: "PVK",
      display: "PVK"
    },
    {
      name: "Diagoras Airport",
      city: "Rhodos",
      country: "Greece",
      value: "RHO",
      display: "RHO"
    },
    {
      name: "Araxos Airport",
      city: "Patras",
      country: "Greece",
      value: "GPA",
      display: "GPA"
    },
    {
      name: "Chania International Airport",
      city: "Chania",
      country: "Greece",
      value: "CHQ",
      display: "CHQ"
    },
    {
      name: "Skiathos Island National Airport",
      city: "Skiathos",
      country: "Greece",
      value: "JSI",
      display: "JSI"
    },
    {
      name: "Samos Airport",
      city: "Samos",
      country: "Greece",
      value: "SMI",
      display: "SMI"
    },
    {
      name: "Santorini Airport",
      city: "Thira",
      country: "Greece",
      value: "JTR",
      display: "JTR"
    },
    {
      name: "Sitia Airport",
      city: "Sitia",
      country: "Greece",
      value: "JSH",
      display: "JSH"
    },
    {
      name: "Skiros Airport",
      city: "Skiros",
      country: "Greece",
      value: "SKU",
      display: "SKU"
    },
    {
      name: "Thessaloniki Macedonia International Airport",
      city: "Thessaloniki",
      country: "Greece",
      value: "SKG",
      display: "SKG"
    },
    {
      name: "Dionysios Solomos Airport",
      city: "Zakynthos",
      country: "Greece",
      value: "ZTH",
      display: "ZTH"
    },
    {
      name: "Budapest Ferenc Liszt International Airport",
      city: "Budapest",
      country: "Hungary",
      value: "BUD",
      display: "BUD"
    },
    {
      name: "Debrecen International Airport",
      city: "Debrecen",
      country: "Hungary",
      value: "DEB",
      display: "DEB"
    },
    {
      name: "Crotone Airport",
      city: "Crotone",
      country: "Italy",
      value: "CRV",
      display: "CRV"
    },
    {
      name: "Bari Karol Wojty?a Airport",
      city: "Bari",
      country: "Italy",
      value: "BRI",
      display: "BRI"
    },
    {
      name: "Lecce Galatina Air Base",
      city: "Lecce",
      country: "Italy",
      value: "LCC",
      display: "LCC"
    },
    {
      name: "Pescara International Airport",
      city: "Pescara",
      country: "Italy",
      value: "PSR",
      display: "PSR"
    },
    {
      name: "Brindisi - Salento Airport",
      city: "Brindisi",
      country: "Italy",
      value: "BDS",
      display: "BDS"
    },
    {
      name: "Lamezia Terme Airport",
      city: "Lamezia",
      country: "Italy",
      value: "SUF",
      display: "SUF"
    },
    {
      name: "Catania-Fontanarossa Airport",
      city: "Catania",
      country: "Italy",
      value: "CTA",
      display: "CTA"
    },
    {
      name: "Lampedusa Airport",
      city: "Lampedusa",
      country: "Italy",
      value: "LMP",
      display: "LMP"
    },
    {
      name: "Pantelleria Airport",
      city: "Pantelleria",
      country: "Italy",
      value: "PNL",
      display: "PNL"
    },
    {
      name: "Falcone-Borsellino Airport",
      city: "Palermo",
      country: "Italy",
      value: "PMO",
      display: "PMO"
    },
    {
      name: "Reggio Calabria Airport",
      city: "Reggio Calabria",
      country: "Italy",
      value: "REG",
      display: "REG"
    },
    {
      name: "Vincenzo Florio Airport Trapani-Birgi",
      city: "Trapani",
      country: "Italy",
      value: "TPS",
      display: "TPS"
    },
    {
      name: "Sigonella Navy Air Base",
      city: "Sigonella",
      country: "Italy",
      value: "NSY",
      display: "NSY"
    },
    {
      name: "Alghero-Fertilia Airport",
      city: "Alghero",
      country: "Italy",
      value: "AHO",
      display: "AHO"
    },
    {
      name: "Decimomannu Air Base",
      city: "Decimomannu",
      country: "Italy",
      value: "DCI",
      display: "DCI"
    },
    {
      name: "Cagliari Elmas Airport",
      city: "Cagliari",
      country: "Italy",
      value: "CAG",
      display: "CAG"
    },
    {
      name: "Olbia Costa Smeralda Airport",
      city: "Olbia",
      country: "Italy",
      value: "OLB",
      display: "OLB"
    },
    {
      name: "Tortoli Airport",
      city: "Tortoli",
      country: "Italy",
      value: "TTB",
      display: "TTB"
    },
    {
      name: "Malpensa International Airport",
      city: "Milano",
      country: "Italy",
      value: "MXP",
      display: "MXP"
    },
    {
      name: "Il Caravaggio International Airport",
      city: "Bergamo",
      country: "Italy",
      value: "BGY",
      display: "BGY"
    },
    {
      name: "Turin Airport",
      city: "Torino",
      country: "Italy",
      value: "TRN",
      display: "TRN"
    },
    {
      name: "Villanova D'Albenga International Airport",
      city: "Albenga",
      country: "Italy",
      value: "ALL",
      display: "ALL"
    },
    {
      name: "Genoa Cristoforo Colombo Airport",
      city: "Genoa",
      country: "Italy",
      value: "GOA",
      display: "GOA"
    },
    {
      name: "Milano Linate Airport",
      city: "Milan",
      country: "Italy",
      value: "LIN",
      display: "LIN"
    },
    {
      name: "Parma Airport",
      city: "Parma",
      country: "Italy",
      value: "PMF",
      display: "PMF"
    },
    {
      name: "Piacenza San Damiano Air Base",
      city: "Piacenza",
      country: "Italy",
      value: "QPZ",
      display: "QPZ"
    },
    {
      name: "Cuneo International Airport",
      city: "Cuneo",
      country: "Italy",
      value: "CUF",
      display: "CUF"
    },
    {
      name: "Aviano Air Base",
      city: "Aviano",
      country: "Italy",
      value: "AVB",
      display: "AVB"
    },
    {
      name: "Bolzano Airport",
      city: "Bolzano",
      country: "Italy",
      value: "BZO",
      display: "BZO"
    },
    {
      name: "Bologna Guglielmo Marconi Airport",
      city: "Bologna",
      country: "Italy",
      value: "BLQ",
      display: "BLQ"
    },
    {
      name: "Treviso-Sant'Angelo Airport",
      city: "Treviso",
      country: "Italy",
      value: "TSF",
      display: "TSF"
    },
    {
      name: "Forli Airport",
      city: "Forli",
      country: "Italy",
      value: "FRL",
      display: "FRL"
    },
    {
      name: "Brescia Airport",
      city: "Brescia",
      country: "Italy",
      value: "VBS",
      display: "VBS"
    },
    {
      name: "Trieste-Friuli Venezia Giulia Airport",
      city: "Trieste",
      country: "Italy",
      value: "TRS",
      display: "TRS"
    },
    {
      name: "Federico Fellini International Airport",
      city: "Rimini",
      country: "Italy",
      value: "RMI",
      display: "RMI"
    },
    {
      name: "Vicenza Airport",
      city: "Vicenza",
      country: "Italy",
      value: "VIC",
      display: "VIC"
    },
    {
      name: "Padova Airport",
      city: "Padova",
      country: "Italy",
      value: "QPA",
      display: "QPA"
    },
    {
      name: "Verona Villafranca Airport",
      city: "Villafranca",
      country: "Italy",
      value: "VRN",
      display: "VRN"
    },
    {
      name: "Venice Marco Polo Airport",
      city: "Venice",
      country: "Italy",
      value: "VCE",
      display: "VCE"
    },
    {
      name: "Siena-Ampugnano Airport",
      city: "Siena",
      country: "Italy",
      value: "SAY",
      display: "SAY"
    },
    {
      name: "Ciampino - G. B. Pastine International Airport",
      city: "Rome",
      country: "Italy",
      value: "CIA",
      display: "CIA"
    },
    {
      name: "Leonardo da Vinci-Fiumicino Airport",
      city: "Rome",
      country: "Italy",
      value: "FCO",
      display: "FCO"
    },
    {
      name: "Marina Di Campo Airport",
      city: "Marina Di Campo",
      country: "Italy",
      value: "EBA",
      display: "EBA"
    },
    {
      name: "Latina Air Base",
      city: "Latina",
      country: "Italy",
      value: "QLT",
      display: "QLT"
    },
    {
      name: "Naples International Airport",
      city: "Naples",
      country: "Italy",
      value: "NAP",
      display: "NAP"
    },
    {
      name: "Pisa International Airport",
      city: "Pisa",
      country: "Italy",
      value: "PSA",
      display: "PSA"
    },
    {
      name: "Peretola Airport",
      city: "Florence",
      country: "Italy",
      value: "FLR",
      display: "FLR"
    },
    {
      name: "Grosseto Air Base",
      city: "Grosseto",
      country: "Italy",
      value: "GRS",
      display: "GRS"
    },
    {
      name: "Perugia San Francesco d'Assisi - Umbria International Airport",
      city: "Perugia",
      country: "Italy",
      value: "PEG",
      display: "PEG"
    },
    {
      name: "Ljubljana Joze Pucnik Airport",
      city: "Ljubljana",
      country: "Slovenia",
      value: "LJU",
      display: "LJU"
    },
    {
      name: "Maribor Airport",
      city: "Maribor",
      country: "Slovenia",
      value: "MBX",
      display: "MBX"
    },
    {
      name: "Portoroz Airport",
      city: "Portoroz",
      country: "Slovenia",
      value: "POW",
      display: "POW"
    },
    {
      name: "Karlovy Vary International Airport",
      city: "Karlovy Vary",
      country: "Czech Republic",
      value: "KLV",
      display: "KLV"
    },
    {
      name: "Ostrava Leos Janacek Airport",
      city: "Ostrava",
      country: "Czech Republic",
      value: "OSR",
      display: "OSR"
    },
    {
      name: "Pardubice Airport",
      city: "Pardubice",
      country: "Czech Republic",
      value: "PED",
      display: "PED"
    },
    {
      name: "Prerov Air Base",
      city: "Prerov",
      country: "Czech Republic",
      value: "PRV",
      display: "PRV"
    },
    {
      name: "Vaclav Havel Airport Prague",
      city: "Prague",
      country: "Czech Republic",
      value: "PRG",
      display: "PRG"
    },
    {
      name: "Brno-Turany Airport",
      city: "Brno",
      country: "Czech Republic",
      value: "BRQ",
      display: "BRQ"
    },
    {
      name: "Ben Gurion International Airport",
      city: "Tel-Aviv",
      country: "Israel",
      value: "TLV",
      display: "TLV"
    },
    {
      name: "Beersheba (Teyman) Airport",
      city: "Beer-sheba",
      country: "Israel",
      value: "BEV",
      display: "BEV"
    },
    {
      name: "Eilat Airport",
      city: "Elat",
      country: "Israel",
      value: "ETH",
      display: "ETH"
    },
    {
      name: "Haifa International Airport",
      city: "Haifa",
      country: "Israel",
      value: "HFA",
      display: "HFA"
    },
    {
      name: "Ben Ya'akov Airport",
      city: "Rosh Pina",
      country: "Israel",
      value: "RPN",
      display: "RPN"
    },
    {
      name: "Ovda International Airport",
      city: "Ovda",
      country: "Israel",
      value: "VDA",
      display: "VDA"
    },
    {
      name: "Sde Dov Airport",
      city: "Tel-Aviv",
      country: "Israel",
      value: "SDV",
      display: "SDV"
    },
    {
      name: "Malta International Airport",
      city: "Malta",
      country: "Malta",
      value: "MLA",
      display: "MLA"
    },
    {
      name: "Graz Airport",
      city: "Graz",
      country: "Austria",
      value: "GRZ",
      display: "GRZ"
    },
    {
      name: "Innsbruck Airport",
      city: "Innsbruck",
      country: "Austria",
      value: "INN",
      display: "INN"
    },
    {
      name: "Linz Horsching Airport",
      city: "Linz",
      country: "Austria",
      value: "LNZ",
      display: "LNZ"
    },
    {
      name: "Salzburg Airport",
      city: "Salzburg",
      country: "Austria",
      value: "SZG",
      display: "SZG"
    },
    {
      name: "Vienna International Airport",
      city: "Vienna",
      country: "Austria",
      value: "VIE",
      display: "VIE"
    },
    {
      name: "Santa Maria Airport",
      city: "Santa Maria (island)",
      country: "Portugal",
      value: "SMA",
      display: "SMA"
    },
    {
      name: "Braganca Airport",
      city: "Braganca",
      country: "Portugal",
      value: "BGC",
      display: "BGC"
    },
    {
      name: "Flores Airport",
      city: "Flores",
      country: "Portugal",
      value: "FLW",
      display: "FLW"
    },
    {
      name: "Faro Airport",
      city: "Faro",
      country: "Portugal",
      value: "FAO",
      display: "FAO"
    },
    {
      name: "Graciosa Airport",
      city: "Graciosa Island",
      country: "Portugal",
      value: "GRW",
      display: "GRW"
    },
    {
      name: "Horta Airport",
      city: "Horta",
      country: "Portugal",
      value: "HOR",
      display: "HOR"
    },
    {
      name: "Lajes Field",
      city: "Lajes (Terceira Island)",
      country: "Portugal",
      value: "TER",
      display: "TER"
    },
    {
      name: "Joao Paulo II Airport",
      city: "Ponta Delgada",
      country: "Portugal",
      value: "PDL",
      display: "PDL"
    },
    {
      name: "Pico Airport",
      city: "Pico",
      country: "Portugal",
      value: "PIX",
      display: "PIX"
    },
    {
      name: "Francisco de Sa Carneiro Airport",
      city: "Porto",
      country: "Portugal",
      value: "OPO",
      display: "OPO"
    },
    {
      name: "Porto Santo Airport",
      city: "Porto Santo",
      country: "Portugal",
      value: "PXO",
      display: "PXO"
    },
    {
      name: "Lisbon Portela Airport",
      city: "Lisbon",
      country: "Portugal",
      value: "LIS",
      display: "LIS"
    },
    {
      name: "Sao Jorge Airport",
      city: "Sao Jorge Island",
      country: "Portugal",
      value: "SJZ",
      display: "SJZ"
    },
    {
      name: "Vila Real Airport",
      city: "Vila Real",
      country: "Portugal",
      value: "VRL",
      display: "VRL"
    },
    {
      name: "Mostar International Airport",
      city: "Mostar",
      country: "Bosnia and Herzegovina",
      value: "OMO",
      display: "OMO"
    },
    {
      name: "Sarajevo International Airport",
      city: "Sarajevo",
      country: "Bosnia and Herzegovina",
      value: "SJJ",
      display: "SJJ"
    },
    {
      name: "Arad International Airport",
      city: "Arad",
      country: "Romania",
      value: "ARW",
      display: "ARW"
    },
    {
      name: "Bacau Airport",
      city: "Bacau",
      country: "Romania",
      value: "BCM",
      display: "BCM"
    },
    {
      name: "Tautii Magheraus Airport",
      city: "Baia Mare",
      country: "Romania",
      value: "BAY",
      display: "BAY"
    },
    {
      name: "Baneasa International Airport",
      city: "Bucharest",
      country: "Romania",
      value: "BBU",
      display: "BBU"
    },
    {
      name: "Mihail Kogalniceanu International Airport",
      city: "Constanta",
      country: "Romania",
      value: "CND",
      display: "CND"
    },
    {
      name: "Cluj-Napoca International Airport",
      city: "Cluj-Napoca",
      country: "Romania",
      value: "CLJ",
      display: "CLJ"
    },
    {
      name: "Caransebes Airport",
      city: "Caransebes",
      country: "Romania",
      value: "CSB",
      display: "CSB"
    },
    {
      name: "Craiova Airport",
      city: "Craiova",
      country: "Romania",
      value: "CRA",
      display: "CRA"
    },
    {
      name: "Iasi Airport",
      city: "Iasi",
      country: "Romania",
      value: "IAS",
      display: "IAS"
    },
    {
      name: "Oradea International Airport",
      city: "Oradea",
      country: "Romania",
      value: "OMR",
      display: "OMR"
    },
    {
      name: "Henri Coanda International Airport",
      city: "Bucharest",
      country: "Romania",
      value: "OTP",
      display: "OTP"
    },
    {
      name: "Sibiu International Airport",
      city: "Sibiu",
      country: "Romania",
      value: "SBZ",
      display: "SBZ"
    },
    {
      name: "Satu Mare Airport",
      city: "Satu Mare",
      country: "Romania",
      value: "SUJ",
      display: "SUJ"
    },
    {
      name: "Suceava Stefan cel Mare Airport",
      city: "Suceava",
      country: "Romania",
      value: "SCV",
      display: "SCV"
    },
    {
      name: "Tulcea Airport",
      city: "Tulcea",
      country: "Romania",
      value: "TCE",
      display: "TCE"
    },
    {
      name: "Transilvania Targu Mures International Airport",
      city: "Tirgu Mures",
      country: "Romania",
      value: "TGM",
      display: "TGM"
    },
    {
      name: "Timisoara Traian Vuia Airport",
      city: "Timisoara",
      country: "Romania",
      value: "TSR",
      display: "TSR"
    },
    {
      name: "Geneva Cointrin International Airport",
      city: "Geneva",
      country: "Switzerland",
      value: "GVA",
      display: "GVA"
    },
    {
      name: "Sion Airport",
      city: "Sion",
      country: "Switzerland",
      value: "SIR",
      display: "SIR"
    },
    {
      name: "Emmen Air Base",
      city: "Emmen",
      country: "Switzerland",
      value: "EML",
      display: "EML"
    },
    {
      name: "Lugano Airport",
      city: "Lugano",
      country: "Switzerland",
      value: "LUG",
      display: "LUG"
    },
    {
      name: "Bern Belp Airport",
      city: "Bern",
      country: "Switzerland",
      value: "BRN",
      display: "BRN"
    },
    {
      name: "Zurich Airport",
      city: "Zurich",
      country: "Switzerland",
      value: "ZRH",
      display: "ZRH"
    },
    {
      name: "St Gallen Altenrhein Airport",
      city: "Altenrhein",
      country: "Switzerland",
      value: "ACH",
      display: "ACH"
    },
    {
      name: "Samedan Airport",
      city: "Samedan",
      country: "Switzerland",
      value: "SMV",
      display: "SMV"
    },
    {
      name: "Esenboga International Airport",
      city: "Ankara",
      country: "Turkey",
      value: "ESB",
      display: "ESB"
    },
    {
      name: "Etimesgut Air Base",
      city: "Ankara",
      country: "Turkey",
      value: "ANK",
      display: "ANK"
    },
    {
      name: "Adana Airport",
      city: "Adana",
      country: "Turkey",
      value: "ADA",
      display: "ADA"
    },
    {
      name: "Incirlik Air Base",
      city: "Adana",
      country: "Turkey",
      value: "UAB",
      display: "UAB"
    },
    {
      name: "Afyon Airport",
      city: "Afyon",
      country: "Turkey",
      value: "AFY",
      display: "AFY"
    },
    {
      name: "Antalya International Airport",
      city: "Antalya",
      country: "Turkey",
      value: "AYT",
      display: "AYT"
    },
    {
      name: "Gaziantep International Airport",
      city: "Gaziantep",
      country: "Turkey",
      value: "GZT",
      display: "GZT"
    },
    {
      name: "Konya Airport",
      city: "Konya",
      country: "Turkey",
      value: "KYA",
      display: "KYA"
    },
    {
      name: "Malatya Tulga Airport",
      city: "Malatya",
      country: "Turkey",
      value: "MLX",
      display: "MLX"
    },
    {
      name: "Amasya Merzifon Airport",
      city: "Merzifon",
      country: "Turkey",
      value: "MZH",
      display: "MZH"
    },
    {
      name: "Sivas Nuri Demirag Airport",
      city: "Sivas",
      country: "Turkey",
      value: "VAS",
      display: "VAS"
    },
    {
      name: "Kayseri Erkilet Airport",
      city: "Kayseri",
      country: "Turkey",
      value: "ASR",
      display: "ASR"
    },
    {
      name: "Cardak Airport",
      city: "Denizli",
      country: "Turkey",
      value: "DNZ",
      display: "DNZ"
    },
    {
      name: "Ataturk International Airport",
      city: "Istanbul",
      country: "Turkey",
      value: "IST",
      display: "IST"
    },
    {
      name: "Bal?kesir Merkez Airport",
      city: "Balikesir",
      country: "Turkey",
      value: "BZI",
      display: "BZI"
    },
    {
      name: "Band?rma Airport",
      city: "Bandirma",
      country: "Turkey",
      value: "BDM",
      display: "BDM"
    },
    {
      name: "Eskisehir Air Base",
      city: "Eskisehir",
      country: "Turkey",
      value: "ESK",
      display: "ESK"
    },
    {
      name: "Adnan Menderes International Airport",
      city: "Izmir",
      country: "Turkey",
      value: "ADB",
      display: "ADB"
    },
    {
      name: "Cigli Airport",
      city: "Izmir",
      country: "Turkey",
      value: "IGL",
      display: "IGL"
    },
    {
      name: "Dalaman International Airport",
      city: "Dalaman",
      country: "Turkey",
      value: "DLM",
      display: "DLM"
    },
    {
      name: "Ims?k Airport",
      city: "Bodrum",
      country: "Turkey",
      value: "BXN",
      display: "BXN"
    },
    {
      name: "Elaz?g Airport",
      city: "Elazig",
      country: "Turkey",
      value: "EZS",
      display: "EZS"
    },
    {
      name: "Diyarbakir Airport",
      city: "Diyabakir",
      country: "Turkey",
      value: "DIY",
      display: "DIY"
    },
    {
      name: "Erzincan Airport",
      city: "Erzincan",
      country: "Turkey",
      value: "ERC",
      display: "ERC"
    },
    {
      name: "Erzurum International Airport",
      city: "Erzurum",
      country: "Turkey",
      value: "ERZ",
      display: "ERZ"
    },
    {
      name: "Trabzon International Airport",
      city: "Trabzon",
      country: "Turkey",
      value: "TZX",
      display: "TZX"
    },
    {
      name: "Van Ferit Melen Airport",
      city: "Van",
      country: "Turkey",
      value: "VAN",
      display: "VAN"
    },
    {
      name: "Batman Airport",
      city: "Batman",
      country: "Turkey",
      value: "BAL",
      display: "BAL"
    },
    {
      name: "Balti International Airport",
      city: "Saltsy",
      country: "Moldova",
      value: "BZY",
      display: "BZY"
    },
    {
      name: "Chisinau International Airport",
      city: "Chisinau",
      country: "Moldova",
      value: "KIV",
      display: "KIV"
    },
    {
      name: "Ohrid St. Paul the Apostle Airport",
      city: "Ohrid",
      country: "Macedonia",
      value: "OHD",
      display: "OHD"
    },
    {
      name: "Skopje Alexander the Great Airport",
      city: "Skopje",
      country: "Macedonia",
      value: "SKP",
      display: "SKP"
    },
    {
      name: "Gibraltar Airport",
      city: "Gibraltar",
      country: "Gibraltar",
      value: "GIB",
      display: "GIB"
    },
    {
      name: "Belgrade Nikola Tesla Airport",
      city: "Belgrade",
      country: "Serbia",
      value: "BEG",
      display: "BEG"
    },
    {
      name: "Nis Airport",
      city: "Nis",
      country: "Serbia",
      value: "INI",
      display: "INI"
    },
    {
      name: "Podgorica Airport",
      city: "Podgorica",
      country: "Montenegro",
      value: "TGD",
      display: "TGD"
    },
    {
      name: "Pristina International Airport",
      city: "Pristina",
      country: "Kosovo",
      value: "PRN",
      display: "PRN"
    },
    {
      name: "Tivat Airport",
      city: "Tivat",
      country: "Montenegro",
      value: "TIV",
      display: "TIV"
    },
    {
      name: "M. R. Stefanik Airport",
      city: "Bratislava",
      country: "Slovakia",
      value: "BTS",
      display: "BTS"
    },
    {
      name: "Kosice Airport",
      city: "Kosice",
      country: "Slovakia",
      value: "KSC",
      display: "KSC"
    },
    {
      name: "Piestany Airport",
      city: "Piestany",
      country: "Slovakia",
      value: "PZY",
      display: "PZY"
    },
    {
      name: "Sliac Airport",
      city: "Sliac",
      country: "Slovakia",
      value: "SLD",
      display: "SLD"
    },
    {
      name: "Poprad-Tatry Airport",
      city: "Poprad",
      country: "Slovakia",
      value: "TAT",
      display: "TAT"
    },
    {
      name: "North Caicos Airport",
      city: "North Caicos",
      country: "Turks and Caicos Islands",
      value: "NCA",
      display: "NCA"
    },
    {
      name: "Providenciales Airport",
      city: "Providenciales",
      country: "Turks and Caicos Islands",
      value: "PLS",
      display: "PLS"
    },
    {
      name: "South Caicos Airport",
      city: "South Caicos",
      country: "Turks and Caicos Islands",
      value: "XSC",
      display: "XSC"
    },
    {
      name: "Arroyo Barril Airport",
      city: "Samana",
      country: "Dominican Republic",
      value: "EPS",
      display: "EPS"
    },
    {
      name: "Maria Montez International Airport",
      city: "Barahona",
      country: "Dominican Republic",
      value: "BRX",
      display: "BRX"
    },
    {
      name: "Casa De Campo International Airport",
      city: "La Romana",
      country: "Dominican Republic",
      value: "LRM",
      display: "LRM"
    },
    {
      name: "Punta Cana International Airport",
      city: "Punta Cana",
      country: "Dominican Republic",
      value: "PUJ",
      display: "PUJ"
    },
    {
      name: "Gregorio Luperon International Airport",
      city: "Puerto Plata",
      country: "Dominican Republic",
      value: "POP",
      display: "POP"
    },
    {
      name: "Las Americas International Airport",
      city: "Santo Domingo",
      country: "Dominican Republic",
      value: "SDQ",
      display: "SDQ"
    },
    {
      name: "Cibao International Airport",
      city: "Santiago",
      country: "Dominican Republic",
      value: "STI",
      display: "STI"
    },
    {
      name: "Coban Airport",
      city: "Coban",
      country: "Guatemala",
      value: "CBV",
      display: "CBV"
    },
    {
      name: "La Aurora Airport",
      city: "Guatemala City",
      country: "Guatemala",
      value: "GUA",
      display: "GUA"
    },
    {
      name: "Goloson International Airport",
      city: "La Ceiba",
      country: "Honduras",
      value: "LCE",
      display: "LCE"
    },
    {
      name: "Ramon Villeda Morales International Airport",
      city: "San Pedro Sula",
      country: "Honduras",
      value: "SAP",
      display: "SAP"
    },
    {
      name: "La Laguna Airport",
      city: "Guanaja",
      country: "Honduras",
      value: "GJA",
      display: "GJA"
    },
    {
      name: "Juan Manuel Galvez International Airport",
      city: "Roatan",
      country: "Honduras",
      value: "RTB",
      display: "RTB"
    },
    {
      name: "Tela Airport",
      city: "Tela",
      country: "Honduras",
      value: "TEA",
      display: "TEA"
    },
    {
      name: "Toncontin International Airport",
      city: "Tegucigalpa",
      country: "Honduras",
      value: "TGU",
      display: "TGU"
    },
    {
      name: "Boscobel Aerodrome",
      city: "Ocho Rios",
      country: "Jamaica",
      value: "OCJ",
      display: "OCJ"
    },
    {
      name: "Norman Manley International Airport",
      city: "Kingston",
      country: "Jamaica",
      value: "KIN",
      display: "KIN"
    },
    {
      name: "Sangster International Airport",
      city: "Montego Bay",
      country: "Jamaica",
      value: "MBJ",
      display: "MBJ"
    },
    {
      name: "Ken Jones Airport",
      city: "Port Antonio",
      country: "Jamaica",
      value: "POT",
      display: "POT"
    },
    {
      name: "Tinson Pen Airport",
      city: "Kingston",
      country: "Jamaica",
      value: "KTP",
      display: "KTP"
    },
    {
      name: "General Juan N Alvarez International Airport",
      city: "Acapulco",
      country: "Mexico",
      value: "ACA",
      display: "ACA"
    },
    {
      name: "Del Norte International Airport",
      city: "Monterrey",
      country: "Mexico",
      value: "NTR",
      display: "NTR"
    },
    {
      name: "Jesus Teran Paredo International Airport",
      city: "Aguascalientes",
      country: "Mexico",
      value: "AGU",
      display: "AGU"
    },
    {
      name: "Bahias de Huatulco International Airport",
      city: "Huatulco",
      country: "Mexico",
      value: "HUX",
      display: "HUX"
    },
    {
      name: "General Mariano Matamoros Airport",
      city: "Cuernavaca",
      country: "Mexico",
      value: "CVJ",
      display: "CVJ"
    },
    {
      name: "Ciudad del Carmen International Airport",
      city: "Ciudad Del Carmen",
      country: "Mexico",
      value: "CME",
      display: "CME"
    },
    {
      name: "Bachigualato Federal International Airport",
      city: "Culiacan",
      country: "Mexico",
      value: "CUL",
      display: "CUL"
    },
    {
      name: "Chetumal International Airport",
      city: "Chetumal",
      country: "Mexico",
      value: "CTM",
      display: "CTM"
    },
    {
      name: "Ciudad Obregon International Airport",
      city: "Ciudad Obregon",
      country: "Mexico",
      value: "CEN",
      display: "CEN"
    },
    {
      name: "Ingeniero Alberto Acuna Ongay International Airport",
      city: "Campeche",
      country: "Mexico",
      value: "CPE",
      display: "CPE"
    },
    {
      name: "Abraham Gonzalez International Airport",
      city: "Ciudad Juarez",
      country: "Mexico",
      value: "CJS",
      display: "CJS"
    },
    {
      name: "General Roberto Fierro Villalobos International Airport",
      city: "Chihuahua",
      country: "Mexico",
      value: "CUU",
      display: "CUU"
    },
    {
      name: "General Pedro Jose Mendez International Airport",
      city: "Ciudad Victoria",
      country: "Mexico",
      value: "CVM",
      display: "CVM"
    },
    {
      name: "Cozumel International Airport",
      city: "Cozumel",
      country: "Mexico",
      value: "CZM",
      display: "CZM"
    },
    {
      name: "General Guadalupe Victoria International Airport",
      city: "Durango",
      country: "Mexico",
      value: "DGO",
      display: "DGO"
    },
    {
      name: "Amado Nervo National Airport",
      city: "Tepic",
      country: "Mexico",
      value: "TPQ",
      display: "TPQ"
    },
    {
      name: "Ensenada Airport",
      city: "Ensenada",
      country: "Mexico",
      value: "ESE",
      display: "ESE"
    },
    {
      name: "Don Miguel Hidalgo Y Costilla International Airport",
      city: "Guadalajara",
      country: "Mexico",
      value: "GDL",
      display: "GDL"
    },
    {
      name: "General Jose Maria Yanez International Airport",
      city: "Guaymas",
      country: "Mexico",
      value: "GYM",
      display: "GYM"
    },
    {
      name: "Tehuacan Airport",
      city: "Tehuacan",
      country: "Mexico",
      value: "TCN",
      display: "TCN"
    },
    {
      name: "General Ignacio P. Garcia International Airport",
      city: "Hermosillo",
      country: "Mexico",
      value: "HMO",
      display: "HMO"
    },
    {
      name: "Licenciado Miguel de la Madrid Airport",
      city: "Colima",
      country: "Mexico",
      value: "CLQ",
      display: "CLQ"
    },
    {
      name: "Isla Mujeres Airport",
      city: "Isla Mujeres",
      country: "Mexico",
      value: "ISJ",
      display: "ISJ"
    },
    {
      name: "Plan De Guadalupe International Airport",
      city: "Saltillo",
      country: "Mexico",
      value: "SLW",
      display: "SLW"
    },
    {
      name: "Lazaro Cardenas Airport",
      city: "Lazard Cardenas",
      country: "Mexico",
      value: "LZC",
      display: "LZC"
    },
    {
      name: "Valle del Fuerte International Airport",
      city: "Los Mochis",
      country: "Mexico",
      value: "LMM",
      display: "LMM"
    },
    {
      name: "Del Bajio International Airport",
      city: "Del Bajio",
      country: "Mexico",
      value: "BJX",
      display: "BJX"
    },
    {
      name: "Manuel Marquez de Leon International Airport",
      city: "La Paz",
      country: "Mexico",
      value: "LAP",
      display: "LAP"
    },
    {
      name: "Loreto International Airport",
      city: "Loreto",
      country: "Mexico",
      value: "LTO",
      display: "LTO"
    },
    {
      name: "General Servando Canales International Airport",
      city: "Matamoros",
      country: "Mexico",
      value: "MAM",
      display: "MAM"
    },
    {
      name: "Licenciado Manuel Crescencio Rejon Int Airport",
      city: "Merida",
      country: "Mexico",
      value: "MID",
      display: "MID"
    },
    {
      name: "General Rodolfo Sanchez Taboada International Airport",
      city: "Mexicali",
      country: "Mexico",
      value: "MXL",
      display: "MXL"
    },
    {
      name: "General Francisco J. Mujica International Airport",
      city: "Morelia",
      country: "Mexico",
      value: "MLM",
      display: "MLM"
    },
    {
      name: "Minatitlan/Coatzacoalcos National Airport",
      city: "Minatitlan",
      country: "Mexico",
      value: "MTT",
      display: "MTT"
    },
    {
      name: "Monclova International Airport",
      city: "Monclova",
      country: "Mexico",
      value: "LOV",
      display: "LOV"
    },
    {
      name: "Licenciado Benito Juarez International Airport",
      city: "Mexico City",
      country: "Mexico",
      value: "MEX",
      display: "MEX"
    },
    {
      name: "General Mariano Escobedo International Airport",
      city: "Monterrey",
      country: "Mexico",
      value: "MTY",
      display: "MTY"
    },
    {
      name: "General Rafael Buelna International Airport",
      city: "Mazatlan",
      country: "Mexico",
      value: "MZT",
      display: "MZT"
    },
    {
      name: "Nogales International Airport",
      city: "Nogales",
      country: "Mexico",
      value: "NOG",
      display: "NOG"
    },
    {
      name: "Quetzalcoatl International Airport",
      city: "Nuevo Laredo",
      country: "Mexico",
      value: "NLD",
      display: "NLD"
    },
    {
      name: "Xoxocotlan International Airport",
      city: "Oaxaca",
      country: "Mexico",
      value: "OAX",
      display: "OAX"
    },
    {
      name: "El Tajin National Airport",
      city: "Poza Rico",
      country: "Mexico",
      value: "PAZ",
      display: "PAZ"
    },
    {
      name: "Hermanos Serdan International Airport",
      city: "Puebla",
      country: "Mexico",
      value: "PBC",
      display: "PBC"
    },
    {
      name: "Ingeniero Juan Guillermo Villasana Airport",
      city: "Pachuca",
      country: "Mexico",
      value: "PCA",
      display: "PCA"
    },
    {
      name: "Puerto Penasco International Airport",
      city: "Punta Penasco",
      country: "Mexico",
      value: "PPE",
      display: "PPE"
    },
    {
      name: "Piedras Negras International Airport",
      city: "Piedras Negras",
      country: "Mexico",
      value: "PDS",
      display: "PDS"
    },
    {
      name: "Licenciado y General Ignacio Lopez Rayon Airport",
      city: "Uruapan",
      country: "Mexico",
      value: "UPN",
      display: "UPN"
    },
    {
      name: "Licenciado Gustavo Diaz Ordaz International Airport",
      city: "Puerto Vallarta",
      country: "Mexico",
      value: "PVR",
      display: "PVR"
    },
    {
      name: "Puerto Escondido International Airport",
      city: "Puerto Escondido",
      country: "Mexico",
      value: "PXM",
      display: "PXM"
    },
    {
      name: "Queretaro Intercontinental Airport",
      city: "Queretaro",
      country: "Mexico",
      value: "QRO",
      display: "QRO"
    },
    {
      name: "General Lucio Blanco International Airport",
      city: "Reynosa",
      country: "Mexico",
      value: "REX",
      display: "REX"
    },
    {
      name: "Los Cabos International Airport",
      city: "San Jose Del Cabo",
      country: "Mexico",
      value: "SJD",
      display: "SJD"
    },
    {
      name: "San Felipe International Airport",
      city: "San Filipe",
      country: "Mexico",
      value: "SFH",
      display: "SFH"
    },
    {
      name: "Ponciano Arriaga International Airport",
      city: "San Luis Potosi",
      country: "Mexico",
      value: "SLP",
      display: "SLP"
    },
    {
      name: "Tlaxcala Airport",
      city: "Tlaxcala",
      country: "Mexico",
      value: "TXA",
      display: "TXA"
    },
    {
      name: "Francisco Sarabia International Airport",
      city: "Torreon",
      country: "Mexico",
      value: "TRC",
      display: "TRC"
    },
    {
      name: "Angel Albino Corzo International Airport",
      city: "Tuxtla Gutierrez",
      country: "Mexico",
      value: "TGZ",
      display: "TGZ"
    },
    {
      name: "General Abelardo L. Rodriguez International Airport",
      city: "Tijuana",
      country: "Mexico",
      value: "TIJ",
      display: "TIJ"
    },
    {
      name: "General Francisco Javier Mina International Airport",
      city: "Tampico",
      country: "Mexico",
      value: "TAM",
      display: "TAM"
    },
    {
      name: "Tamuin Airport",
      city: "Tamuin",
      country: "Mexico",
      value: "TSL",
      display: "TSL"
    },
    {
      name: "Licenciado Adolfo Lopez Mateos International Airport",
      city: "Toluca",
      country: "Mexico",
      value: "TLC",
      display: "TLC"
    },
    {
      name: "Tapachula International Airport",
      city: "Tapachula",
      country: "Mexico",
      value: "TAP",
      display: "TAP"
    },
    {
      name: "Cancun International Airport",
      city: "Cancun",
      country: "Mexico",
      value: "CUN",
      display: "CUN"
    },
    {
      name: "Carlos Rovirosa Perez International Airport",
      city: "Villahermosa",
      country: "Mexico",
      value: "VSA",
      display: "VSA"
    },
    {
      name: "General Heriberto Jara International Airport",
      city: "Vera Cruz",
      country: "Mexico",
      value: "VER",
      display: "VER"
    },
    {
      name: "General Leobardo C. Ruiz International Airport",
      city: "Zacatecas",
      country: "Mexico",
      value: "ZCL",
      display: "ZCL"
    },
    {
      name: "Ixtapa Zihuatanejo International Airport",
      city: "Zihuatanejo",
      country: "Mexico",
      value: "ZIH",
      display: "ZIH"
    },
    {
      name: "Zamora Airport",
      city: "Zamora",
      country: "Mexico",
      value: "ZMM",
      display: "ZMM"
    },
    {
      name: "Playa De Oro International Airport",
      city: "Manzanillo",
      country: "Mexico",
      value: "ZLO",
      display: "ZLO"
    },
    {
      name: "Bluefields Airport",
      city: "Bluefields",
      country: "Nicaragua",
      value: "BEF",
      display: "BEF"
    },
    {
      name: "Augusto C. Sandino (Managua) International Airport",
      city: "Managua",
      country: "Nicaragua",
      value: "MGA",
      display: "MGA"
    },
    {
      name: "Puerto Cabezas Airport",
      city: "Puerto Cabezas",
      country: "Nicaragua",
      value: "PUZ",
      display: "PUZ"
    },
    {
      name: "Bocas Del Toro International Airport",
      city: "Bocas Del Toro",
      country: "Panama",
      value: "BOC",
      display: "BOC"
    },
    {
      name: "Cap Manuel Nino International Airport",
      city: "Changuinola",
      country: "Panama",
      value: "CHX",
      display: "CHX"
    },
    {
      name: "Enrique Malek International Airport",
      city: "David",
      country: "Panama",
      value: "DAV",
      display: "DAV"
    },
    {
      name: "Panama Pacifico International Airport",
      city: "Panama City",
      country: "Panama",
      value: "BLB",
      display: "BLB"
    },
    {
      name: "Marcos A. Gelabert International Airport",
      city: "Panama",
      country: "Panama",
      value: "PAC",
      display: "PAC"
    },
    {
      name: "Tocumen International Airport",
      city: "Panama City",
      country: "Panama",
      value: "PTY",
      display: "PTY"
    },
    {
      name: "Coto 47 Airport",
      city: "Coto 47",
      country: "Costa Rica",
      value: "OTR",
      display: "OTR"
    },
    {
      name: "Golfito Airport",
      city: "Golfito",
      country: "Costa Rica",
      value: "GLF",
      display: "GLF"
    },
    {
      name: "Daniel Oduber Quiros International Airport",
      city: "Liberia",
      country: "Costa Rica",
      value: "LIR",
      display: "LIR"
    },
    {
      name: "Limon International Airport",
      city: "Limon",
      country: "Costa Rica",
      value: "LIO",
      display: "LIO"
    },
    {
      name: "Nosara Airport",
      city: "Nosara Beach",
      country: "Costa Rica",
      value: "NOB",
      display: "NOB"
    },
    {
      name: "Juan Santamaria International Airport",
      city: "San Jose",
      country: "Costa Rica",
      value: "SJO",
      display: "SJO"
    },
    {
      name: "Palmar Sur Airport",
      city: "Palmar Sur",
      country: "Costa Rica",
      value: "PMZ",
      display: "PMZ"
    },
    {
      name: "Quepos Managua Airport",
      city: "Quepos",
      country: "Costa Rica",
      value: "XQP",
      display: "XQP"
    },
    {
      name: "El Salvador International Airport",
      city: "San Salvador",
      country: "El Salvador",
      value: "SAL",
      display: "SAL"
    },
    {
      name: "Cap Haitien International Airport",
      city: "Cap Haitien",
      country: "Haiti",
      value: "CAP",
      display: "CAP"
    },
    {
      name: "Toussaint Louverture International Airport",
      city: "Port-au-prince",
      country: "Haiti",
      value: "PAP",
      display: "PAP"
    },
    {
      name: "Gustavo Rizo Airport",
      city: "Baracoa Playa",
      country: "Cuba",
      value: "BCA",
      display: "BCA"
    },
    {
      name: "Carlos Manuel de Cespedes Airport",
      city: "Bayamo",
      country: "Cuba",
      value: "BYM",
      display: "BYM"
    },
    {
      name: "Maximo Gomez Airport",
      city: "Ciego De Avila",
      country: "Cuba",
      value: "AVI",
      display: "AVI"
    },
    {
      name: "Jardines Del Rey Airport",
      city: "Cunagua",
      country: "Cuba",
      value: "CCC",
      display: "CCC"
    },
    {
      name: "Jaime Gonzalez Airport",
      city: "Cienfuegos",
      country: "Cuba",
      value: "CFG",
      display: "CFG"
    },
    {
      name: "Vilo Acuna International Airport",
      city: "Cayo Largo del Sur",
      country: "Cuba",
      value: "CYO",
      display: "CYO"
    },
    {
      name: "Ignacio Agramonte International Airport",
      city: "Camaguey",
      country: "Cuba",
      value: "CMW",
      display: "CMW"
    },
    {
      name: "Antonio Maceo International Airport",
      city: "Santiago De Cuba",
      country: "Cuba",
      value: "SCU",
      display: "SCU"
    },
    {
      name: "Mariana Grajales Airport",
      city: "Guantanamo",
      country: "Cuba",
      value: "GAO",
      display: "GAO"
    },
    {
      name: "Jose Marti International Airport",
      city: "Havana",
      country: "Cuba",
      value: "HAV",
      display: "HAV"
    },
    {
      name: "Frank Pais International Airport",
      city: "Holguin",
      country: "Cuba",
      value: "HOG",
      display: "HOG"
    },
    {
      name: "La Coloma Airport",
      city: "La Coloma",
      country: "Cuba",
      value: "LCL",
      display: "LCL"
    },
    {
      name: "Orestes Acosta Airport",
      city: "Moa",
      country: "Cuba",
      value: "MOA",
      display: "MOA"
    },
    {
      name: "Sierra Maestra Airport",
      city: "Manzanillo",
      country: "Cuba",
      value: "MZO",
      display: "MZO"
    },
    {
      name: "Rafael Cabrera Airport",
      city: "Nueva Gerona",
      country: "Cuba",
      value: "GER",
      display: "GER"
    },
    {
      name: "Playa Baracoa Airport",
      city: "Baracoa Playa",
      country: "Cuba",
      value: "UPB",
      display: "UPB"
    },
    {
      name: "Abel Santamaria Airport",
      city: "Santa Clara",
      country: "Cuba",
      value: "SNU",
      display: "SNU"
    },
    {
      name: "Juan Gualberto Gomez International Airport",
      city: "Varadero",
      country: "Cuba",
      value: "VRA",
      display: "VRA"
    },
    {
      name: "Hermanos Ameijeiras Airport",
      city: "Las Tunas",
      country: "Cuba",
      value: "VTU",
      display: "VTU"
    },
    {
      name: "Gerrard Smith International Airport",
      city: "Cayman Brac",
      country: "Cayman Islands",
      value: "CYB",
      display: "CYB"
    },
    {
      name: "Owen Roberts International Airport",
      city: "Georgetown",
      country: "Cayman Islands",
      value: "GCM",
      display: "GCM"
    },
    {
      name: "Andros Town Airport",
      city: "Andros Town",
      country: "Bahamas",
      value: "ASD",
      display: "ASD"
    },
    {
      name: "Marsh Harbour International Airport",
      city: "Marsh Harbor",
      country: "Bahamas",
      value: "MHH",
      display: "MHH"
    },
    {
      name: "San Andros Airport",
      city: "San Andros",
      country: "Bahamas",
      value: "SAQ",
      display: "SAQ"
    },
    {
      name: "Spring Point Airport",
      city: "Spring Point",
      country: "Bahamas",
      value: "AXP",
      display: "AXP"
    },
    {
      name: "Treasure Cay Airport",
      city: "Treasure Cay",
      country: "Bahamas",
      value: "TCB",
      display: "TCB"
    },
    {
      name: "Chub Cay Airport",
      city: "Chub Cay",
      country: "Bahamas",
      value: "CCZ",
      display: "CCZ"
    },
    {
      name: "South Bimini Airport",
      city: "Alice Town",
      country: "Bahamas",
      value: "BIM",
      display: "BIM"
    },
    {
      name: "Exuma International Airport",
      city: "Great Exuma",
      country: "Bahamas",
      value: "GGT",
      display: "GGT"
    },
    {
      name: "North Eleuthera Airport",
      city: "North Eleuthera",
      country: "Bahamas",
      value: "ELH",
      display: "ELH"
    },
    {
      name: "Governor's Harbour Airport",
      city: "Governor s Harbor",
      country: "Bahamas",
      value: "GHB",
      display: "GHB"
    },
    {
      name: "Rock Sound Airport",
      city: "Rock Sound",
      country: "Bahamas",
      value: "RSD",
      display: "RSD"
    },
    {
      name: "Grand Bahama International Airport",
      city: "Freeport",
      country: "Bahamas",
      value: "FPO",
      display: "FPO"
    },
    {
      name: "Inagua Airport",
      city: "Matthew Town",
      country: "Bahamas",
      value: "IGA",
      display: "IGA"
    },
    {
      name: "Deadman's Cay Airport",
      city: "Dead Man s Cay",
      country: "Bahamas",
      value: "LGI",
      display: "LGI"
    },
    {
      name: "Stella Maris Airport",
      city: "Stella Maris",
      country: "Bahamas",
      value: "SML",
      display: "SML"
    },
    {
      name: "Mayaguana Airport",
      city: "Mayaguana",
      country: "Bahamas",
      value: "MYG",
      display: "MYG"
    },
    {
      name: "Lynden Pindling International Airport",
      city: "Nassau",
      country: "Bahamas",
      value: "NAS",
      display: "NAS"
    },
    {
      name: "San Salvador Airport",
      city: "Cockburn Town",
      country: "Bahamas",
      value: "ZSA",
      display: "ZSA"
    },
    {
      name: "Philip S. W. Goldson International Airport",
      city: "Belize City",
      country: "Belize",
      value: "BZE",
      display: "BZE"
    },
    {
      name: "Aitutaki Airport",
      city: "Aitutaki",
      country: "Cook Islands",
      value: "AIT",
      display: "AIT"
    },
    {
      name: "Rarotonga International Airport",
      city: "Avarua",
      country: "Cook Islands",
      value: "RAR",
      display: "RAR"
    },
    {
      name: "Nadi International Airport",
      city: "Nandi",
      country: "Fiji",
      value: "NAN",
      display: "NAN"
    },
    {
      name: "Nausori International Airport",
      city: "Nausori",
      country: "Fiji",
      value: "SUV",
      display: "SUV"
    },
    {
      name: "Fua'amotu International Airport",
      city: "Tongatapu",
      country: "Tonga",
      value: "TBU",
      display: "TBU"
    },
    {
      name: "Vava'u International Airport",
      city: "Vava u",
      country: "Tonga",
      value: "VAV",
      display: "VAV"
    },
    {
      name: "Bonriki International Airport",
      city: "Tarawa",
      country: "Kiribati",
      value: "TRW",
      display: "TRW"
    },
    {
      name: "Tabiteuea North Airport",
      city: "Tabiteuea North",
      country: "Kiribati",
      value: "TBF",
      display: "TBF"
    },
    {
      name: "Hihifo Airport",
      city: "Wallis",
      country: "Wallis and Futuna",
      value: "WLS",
      display: "WLS"
    },
    {
      name: "Faleolo International Airport",
      city: "Faleolo",
      country: "Samoa",
      value: "APW",
      display: "APW"
    },
    {
      name: "Pago Pago International Airport",
      city: "Pago Pago",
      country: "American Samoa",
      value: "PPG",
      display: "PPG"
    },
    {
      name: "Rurutu Airport",
      city: "Rurutu",
      country: "French Polynesia",
      value: "RUR",
      display: "RUR"
    },
    {
      name: "Tubuai Airport",
      city: "Tubuai",
      country: "French Polynesia",
      value: "TUB",
      display: "TUB"
    },
    {
      name: "Anaa Airport",
      city: "Anaa",
      country: "French Polynesia",
      value: "AAA",
      display: "AAA"
    },
    {
      name: "Tikehau Airport",
      city: "Tikehau",
      country: "French Polynesia",
      value: "TIH",
      display: "TIH"
    },
    {
      name: "Reao Airport",
      city: "Reao",
      country: "French Polynesia",
      value: "REA",
      display: "REA"
    },
    {
      name: "Fakarava Airport",
      city: "Fakarava",
      country: "French Polynesia",
      value: "FAV",
      display: "FAV"
    },
    {
      name: "Manihi Airport",
      city: "Manihi",
      country: "French Polynesia",
      value: "XMH",
      display: "XMH"
    },
    {
      name: "Totegegie Airport",
      city: "Totegegie",
      country: "French Polynesia",
      value: "GMR",
      display: "GMR"
    },
    {
      name: "Kaukura Airport",
      city: "Kaukura Atoll",
      country: "French Polynesia",
      value: "KKR",
      display: "KKR"
    },
    {
      name: "Makemo Airport",
      city: "Makemo",
      country: "French Polynesia",
      value: "MKP",
      display: "MKP"
    },
    {
      name: "Puka Puka Airport",
      city: "Puka Puka",
      country: "French Polynesia",
      value: "PKP",
      display: "PKP"
    },
    {
      name: "Takapoto Airport",
      city: "Takapoto",
      country: "French Polynesia",
      value: "TKP",
      display: "TKP"
    },
    {
      name: "Arutua Airport",
      city: "Arutua",
      country: "French Polynesia",
      value: "AXR",
      display: "AXR"
    },
    {
      name: "Mataiva Airport",
      city: "Mataiva",
      country: "French Polynesia",
      value: "MVT",
      display: "MVT"
    },
    {
      name: "Takaroa Airport",
      city: "Takaroa",
      country: "French Polynesia",
      value: "TKX",
      display: "TKX"
    },
    {
      name: "Nuku Hiva Airport",
      city: "Nuku Hiva",
      country: "French Polynesia",
      value: "NHV",
      display: "NHV"
    },
    {
      name: "Bora Bora Airport",
      city: "Bora Bora",
      country: "French Polynesia",
      value: "BOB",
      display: "BOB"
    },
    {
      name: "Rangiroa Airport",
      city: "Rangiroa",
      country: "French Polynesia",
      value: "RGI",
      display: "RGI"
    },
    {
      name: "Huahine-Fare Airport",
      city: "Huahine Island",
      country: "French Polynesia",
      value: "HUH",
      display: "HUH"
    },
    {
      name: "Moorea Airport",
      city: "Moorea",
      country: "French Polynesia",
      value: "MOZ",
      display: "MOZ"
    },
    {
      name: "Hao Airport",
      city: "Hao Island",
      country: "French Polynesia",
      value: "HOI",
      display: "HOI"
    },
    {
      name: "Maupiti Airport",
      city: "Maupiti",
      country: "French Polynesia",
      value: "MAU",
      display: "MAU"
    },
    {
      name: "Raiatea Airport",
      city: "Raiatea Island",
      country: "French Polynesia",
      value: "RFP",
      display: "RFP"
    },
    {
      name: "Bauerfield International Airport",
      city: "Port-vila",
      country: "Vanuatu",
      value: "VLI",
      display: "VLI"
    },
    {
      name: "Kone Airport",
      city: "Kone",
      country: "New Caledonia",
      value: "KNQ",
      display: "KNQ"
    },
    {
      name: "Koumac Airport",
      city: "Koumac",
      country: "New Caledonia",
      value: "KOC",
      display: "KOC"
    },
    {
      name: "Lifou Airport",
      city: "Lifou",
      country: "New Caledonia",
      value: "LIF",
      display: "LIF"
    },
    {
      name: "Noumea Magenta Airport",
      city: "Noumea",
      country: "New Caledonia",
      value: "GEA",
      display: "GEA"
    },
    {
      name: "Mare Airport",
      city: "Mare",
      country: "New Caledonia",
      value: "MEE",
      display: "MEE"
    },
    {
      name: "Touho Airport",
      city: "Touho",
      country: "New Caledonia",
      value: "TOU",
      display: "TOU"
    },
    {
      name: "Ouvea Airport",
      city: "Ouvea",
      country: "New Caledonia",
      value: "UVE",
      display: "UVE"
    },
    {
      name: "La Tontouta International Airport",
      city: "Noumea",
      country: "New Caledonia",
      value: "NOU",
      display: "NOU"
    },
    {
      name: "Auckland International Airport",
      city: "Auckland",
      country: "New Zealand",
      value: "AKL",
      display: "AKL"
    },
    {
      name: "Taupo Airport",
      city: "Taupo",
      country: "New Zealand",
      value: "TUO",
      display: "TUO"
    },
    {
      name: "Ardmore Airport",
      city: "Ardmore",
      country: "New Zealand",
      value: "AMZ",
      display: "AMZ"
    },
    {
      name: "Christchurch International Airport",
      city: "Christchurch",
      country: "New Zealand",
      value: "CHC",
      display: "CHC"
    },
    {
      name: "Chatham Islands-Tuuta Airport",
      city: "Chatham Island",
      country: "New Zealand",
      value: "CHT",
      display: "CHT"
    },
    {
      name: "Dunedin Airport",
      city: "Dunedin",
      country: "New Zealand",
      value: "DUD",
      display: "DUD"
    },
    {
      name: "Gisborne Airport",
      city: "Gisborne",
      country: "New Zealand",
      value: "GIS",
      display: "GIS"
    },
    {
      name: "Hokitika Airfield",
      city: "Hokitika",
      country: "New Zealand",
      value: "HKK",
      display: "HKK"
    },
    {
      name: "Hamilton International Airport",
      city: "Hamilton",
      country: "New Zealand",
      value: "HLZ",
      display: "HLZ"
    },
    {
      name: "Kerikeri Airport",
      city: "Kerikeri",
      country: "New Zealand",
      value: "KKE",
      display: "KKE"
    },
    {
      name: "Kaitaia Airport",
      city: "Kaitaia",
      country: "New Zealand",
      value: "KAT",
      display: "KAT"
    },
    {
      name: "Alexandra Airport",
      city: "Alexandra",
      country: "New Zealand",
      value: "ALR",
      display: "ALR"
    },
    {
      name: "Mount Cook Airport",
      city: "Mount Cook",
      country: "New Zealand",
      value: "MON",
      display: "MON"
    },
    {
      name: "Manapouri Airport",
      city: "Manapouri",
      country: "New Zealand",
      value: "TEU",
      display: "TEU"
    },
    {
      name: "Hood Airport",
      city: "Masterton",
      country: "New Zealand",
      value: "MRO",
      display: "MRO"
    },
    {
      name: "New Plymouth Airport",
      city: "New Plymouth",
      country: "New Zealand",
      value: "NPL",
      display: "NPL"
    },
    {
      name: "Nelson Airport",
      city: "Nelson",
      country: "New Zealand",
      value: "NSN",
      display: "NSN"
    },
    {
      name: "Invercargill Airport",
      city: "Invercargill",
      country: "New Zealand",
      value: "IVC",
      display: "IVC"
    },
    {
      name: "Oamaru Airport",
      city: "Oamaru",
      country: "New Zealand",
      value: "OAM",
      display: "OAM"
    },
    {
      name: "Palmerston North Airport",
      city: "Palmerston North",
      country: "New Zealand",
      value: "PMR",
      display: "PMR"
    },
    {
      name: "Paraparaumu Airport",
      city: "Paraparaumu",
      country: "New Zealand",
      value: "PPQ",
      display: "PPQ"
    },
    {
      name: "Queenstown International Airport",
      city: "Queenstown International",
      country: "New Zealand",
      value: "ZQN",
      display: "ZQN"
    },
    {
      name: "Rotorua Regional Airport",
      city: "Rotorua",
      country: "New Zealand",
      value: "ROT",
      display: "ROT"
    },
    {
      name: "Tauranga Airport",
      city: "Tauranga",
      country: "New Zealand",
      value: "TRG",
      display: "TRG"
    },
    {
      name: "Timaru Airport",
      city: "Timaru",
      country: "New Zealand",
      value: "TIU",
      display: "TIU"
    },
    {
      name: "Woodbourne Airport",
      city: "Woodbourne",
      country: "New Zealand",
      value: "BHE",
      display: "BHE"
    },
    {
      name: "Wanaka Airport",
      city: "Wanaka",
      country: "New Zealand",
      value: "WKA",
      display: "WKA"
    },
    {
      name: "Whakatane Airport",
      city: "Whakatane",
      country: "New Zealand",
      value: "WHK",
      display: "WHK"
    },
    {
      name: "Wellington International Airport",
      city: "Wellington",
      country: "New Zealand",
      value: "WLG",
      display: "WLG"
    },
    {
      name: "Whangarei Airport",
      city: "Whangarei",
      country: "New Zealand",
      value: "WRE",
      display: "WRE"
    },
    {
      name: "Westport Airport",
      city: "Westport",
      country: "New Zealand",
      value: "WSZ",
      display: "WSZ"
    },
    {
      name: "Wanganui Airport",
      city: "Wanganui",
      country: "New Zealand",
      value: "WAG",
      display: "WAG"
    },
    {
      name: "Herat Airport",
      city: "Herat",
      country: "Afghanistan",
      value: "HEA",
      display: "HEA"
    },
    {
      name: "Jalalabad Airport",
      city: "Jalalabad",
      country: "Afghanistan",
      value: "JAA",
      display: "JAA"
    },
    {
      name: "Kabul International Airport",
      city: "Kabul",
      country: "Afghanistan",
      value: "KBL",
      display: "KBL"
    },
    {
      name: "Kandahar Airport",
      city: "Kandahar",
      country: "Afghanistan",
      value: "KDH",
      display: "KDH"
    },
    {
      name: "Maimana Airport",
      city: "Maimama",
      country: "Afghanistan",
      value: "MMZ",
      display: "MMZ"
    },
    {
      name: "Mazar I Sharif Airport",
      city: "Mazar-i-sharif",
      country: "Afghanistan",
      value: "MZR",
      display: "MZR"
    },
    {
      name: "Konduz Airport",
      city: "Kunduz",
      country: "Afghanistan",
      value: "UND",
      display: "UND"
    },
    {
      name: "Bahrain International Airport",
      city: "Bahrain",
      country: "Bahrain",
      value: "BAH",
      display: "BAH"
    },
    {
      name: "Abha Regional Airport",
      city: "Abha",
      country: "Saudi Arabia",
      value: "AHB",
      display: "AHB"
    },
    {
      name: "Al Ahsa Airport",
      city: "Al-ahsa",
      country: "Saudi Arabia",
      value: "HOF",
      display: "HOF"
    },
    {
      name: "Al Baha Airport",
      city: "El-baha",
      country: "Saudi Arabia",
      value: "ABT",
      display: "ABT"
    },
    {
      name: "Bisha Airport",
      city: "Bisha",
      country: "Saudi Arabia",
      value: "BHH",
      display: "BHH"
    },
    {
      name: "King Fahd International Airport",
      city: "Dammam",
      country: "Saudi Arabia",
      value: "DMM",
      display: "DMM"
    },
    {
      name: "King Abdulaziz Air Base",
      city: "Dhahran",
      country: "Saudi Arabia",
      value: "DHA",
      display: "DHA"
    },
    {
      name: "Jizan Regional Airport",
      city: "Gizan",
      country: "Saudi Arabia",
      value: "GIZ",
      display: "GIZ"
    },
    {
      name: "Gassim Airport",
      city: "Gassim",
      country: "Saudi Arabia",
      value: "ELQ",
      display: "ELQ"
    },
    {
      name: "Gurayat Domestic Airport",
      city: "Guriat",
      country: "Saudi Arabia",
      value: "URY",
      display: "URY"
    },
    {
      name: "Ha'il Airport",
      city: "Hail",
      country: "Saudi Arabia",
      value: "HAS",
      display: "HAS"
    },
    {
      name: "King Abdulaziz International Airport",
      city: "Jeddah",
      country: "Saudi Arabia",
      value: "JED",
      display: "JED"
    },
    {
      name: "King Khaled Military City Airport",
      city: "King Khalid Mil.city",
      country: "Saudi Arabia",
      value: "HBT",
      display: "HBT"
    },
    {
      name: "Prince Mohammad Bin Abdulaziz Airport",
      city: "Madinah",
      country: "Saudi Arabia",
      value: "MED",
      display: "MED"
    },
    {
      name: "Nejran Airport",
      city: "Nejran",
      country: "Saudi Arabia",
      value: "EAM",
      display: "EAM"
    },
    {
      name: "Al Qaisumah/Hafr Al Batin Airport",
      city: "Hafr Al-batin",
      country: "Saudi Arabia",
      value: "AQI",
      display: "AQI"
    },
    {
      name: "Rafha Domestic Airport",
      city: "Rafha",
      country: "Saudi Arabia",
      value: "RAH",
      display: "RAH"
    },
    {
      name: "King Khaled International Airport",
      city: "Riyadh",
      country: "Saudi Arabia",
      value: "RUH",
      display: "RUH"
    },
    {
      name: "Arar Domestic Airport",
      city: "Arar",
      country: "Saudi Arabia",
      value: "RAE",
      display: "RAE"
    },
    {
      name: "Sharurah Airport",
      city: "Sharurah",
      country: "Saudi Arabia",
      value: "SHW",
      display: "SHW"
    },
    {
      name: "Sulayel Airport",
      city: "Sulayel",
      country: "Saudi Arabia",
      value: "SLF",
      display: "SLF"
    },
    {
      name: "Tabuk Airport",
      city: "Tabuk",
      country: "Saudi Arabia",
      value: "TUU",
      display: "TUU"
    },
    {
      name: "Ta'if Regional Airport",
      city: "Taif",
      country: "Saudi Arabia",
      value: "TIF",
      display: "TIF"
    },
    {
      name: "Turaif Domestic Airport",
      city: "Turaif",
      country: "Saudi Arabia",
      value: "TUI",
      display: "TUI"
    },
    {
      name: "Al Wajh Domestic Airport",
      city: "Wejh",
      country: "Saudi Arabia",
      value: "EJH",
      display: "EJH"
    },
    {
      name: "Prince Abdulmohsin Bin Abdulaziz Airport",
      city: "Yenbo",
      country: "Saudi Arabia",
      value: "YNB",
      display: "YNB"
    },
    {
      name: "Abadan Airport",
      city: "Abadan",
      country: "Iran",
      value: "ABD",
      display: "ABD"
    },
    {
      name: "Shahid Asyaee Airport",
      city: "Masjed Soleiman",
      country: "Iran",
      value: "QMJ",
      display: "QMJ"
    },
    {
      name: "Mahshahr Airport",
      city: "Bandar Mahshahr",
      country: "Iran",
      value: "MRX",
      display: "MRX"
    },
    {
      name: "Ahwaz Airport",
      city: "Ahwaz",
      country: "Iran",
      value: "AWZ",
      display: "AWZ"
    },
    {
      name: "Bushehr Airport",
      city: "Bushehr",
      country: "Iran",
      value: "BUZ",
      display: "BUZ"
    },
    {
      name: "Kish International Airport",
      city: "Kish Island",
      country: "Iran",
      value: "KIH",
      display: "KIH"
    },
    {
      name: "Bandar Lengeh Airport",
      city: "Bandar Lengeh",
      country: "Iran",
      value: "BDH",
      display: "BDH"
    },
    {
      name: "Shahid Ashrafi Esfahani Airport",
      city: "Bakhtaran",
      country: "Iran",
      value: "KSH",
      display: "KSH"
    },
    {
      name: "Sanandaj Airport",
      city: "Sanandaj",
      country: "Iran",
      value: "SDG",
      display: "SDG"
    },
    {
      name: "Esfahan Shahid Beheshti International Airport",
      city: "Esfahan",
      country: "Iran",
      value: "IFN",
      display: "IFN"
    },
    {
      name: "Sardar-e-Jangal Airport",
      city: "Rasht",
      country: "Iran",
      value: "RAS",
      display: "RAS"
    },
    {
      name: "Mehrabad International Airport",
      city: "Teheran",
      country: "Iran",
      value: "THR",
      display: "THR"
    },
    {
      name: "Bandar Abbas International Airport",
      city: "Bandar Abbas",
      country: "Iran",
      value: "BND",
      display: "BND"
    },
    {
      name: "Kerman Airport",
      city: "Kerman",
      country: "Iran",
      value: "KER",
      display: "KER"
    },
    {
      name: "Birjand Airport",
      city: "Birjand",
      country: "Iran",
      value: "XBJ",
      display: "XBJ"
    },
    {
      name: "Ramsar Airport",
      city: "Ramsar",
      country: "Iran",
      value: "RZR",
      display: "RZR"
    },
    {
      name: "Shiraz Shahid Dastghaib International Airport",
      city: "Shiraz",
      country: "Iran",
      value: "SYZ",
      display: "SYZ"
    },
    {
      name: "Tabriz International Airport",
      city: "Tabriz",
      country: "Iran",
      value: "TBZ",
      display: "TBZ"
    },
    {
      name: "Shahid Sadooghi Airport",
      city: "Yazd",
      country: "Iran",
      value: "AZD",
      display: "AZD"
    },
    {
      name: "Zabol Airport",
      city: "Zabol",
      country: "Iran",
      value: "ACZ",
      display: "ACZ"
    },
    {
      name: "Konarak Airport",
      city: "Chah Bahar",
      country: "Iran",
      value: "ZBR",
      display: "ZBR"
    },
    {
      name: "Zahedan International Airport",
      city: "Zahedan",
      country: "Iran",
      value: "ZAH",
      display: "ZAH"
    },
    {
      name: "Queen Alia International Airport",
      city: "Amman",
      country: "Jordan",
      value: "AMM",
      display: "AMM"
    },
    {
      name: "Amman-Marka International Airport",
      city: "Amman",
      country: "Jordan",
      value: "ADJ",
      display: "ADJ"
    },
    {
      name: "Aqaba King Hussein International Airport",
      city: "Aqaba",
      country: "Jordan",
      value: "AQJ",
      display: "AQJ"
    },
    {
      name: "King Hussein Air College",
      city: "Mafraq",
      country: "Jordan",
      value: "OMF",
      display: "OMF"
    },
    {
      name: "Kuwait International Airport",
      city: "Kuwait",
      country: "Kuwait",
      value: "KWI",
      display: "KWI"
    },
    {
      name: "Beirut Rafic Hariri International Airport",
      city: "Beirut",
      country: "Lebanon",
      value: "BEY",
      display: "BEY"
    },
    {
      name: "Abu Dhabi International Airport",
      city: "Abu Dhabi",
      country: "United Arab Emirates",
      value: "AUH",
      display: "AUH"
    },
    {
      name: "Bateen Airport",
      city: "Abu Dhabi",
      country: "United Arab Emirates",
      value: "AZI",
      display: "AZI"
    },
    {
      name: "Dubai International Airport",
      city: "Dubai",
      country: "United Arab Emirates",
      value: "DXB",
      display: "DXB"
    },
    {
      name: "Fujairah International Airport",
      city: "Fujeirah",
      country: "United Arab Emirates",
      value: "FJR",
      display: "FJR"
    },
    {
      name: "Ras Al Khaimah International Airport",
      city: "Ras Al Khaimah",
      country: "United Arab Emirates",
      value: "RKT",
      display: "RKT"
    },
    {
      name: "Sharjah International Airport",
      city: "Sharjah",
      country: "United Arab Emirates",
      value: "SHJ",
      display: "SHJ"
    },
    {
      name: "Khasab Air Base",
      city: "Khasab",
      country: "Oman",
      value: "KHS",
      display: "KHS"
    },
    {
      name: "Masirah Air Base",
      city: "Masirah",
      country: "Oman",
      value: "MSH",
      display: "MSH"
    },
    {
      name: "Muscat International Airport",
      city: "Muscat",
      country: "Oman",
      value: "MCT",
      display: "MCT"
    },
    {
      name: "Salalah Airport",
      city: "Salalah",
      country: "Oman",
      value: "SLL",
      display: "SLL"
    },
    {
      name: "Thumrait Air Base",
      city: "Thumrait",
      country: "Oman",
      value: "TTH",
      display: "TTH"
    },
    {
      name: "Faisalabad International Airport",
      city: "Faisalabad",
      country: "Pakistan",
      value: "LYP",
      display: "LYP"
    },
    {
      name: "Gwadar International Airport",
      city: "Gwadar",
      country: "Pakistan",
      value: "GWD",
      display: "GWD"
    },
    {
      name: "Gilgit Airport",
      city: "Gilgit",
      country: "Pakistan",
      value: "GIL",
      display: "GIL"
    },
    {
      name: "Jinnah International Airport",
      city: "Karachi",
      country: "Pakistan",
      value: "KHI",
      display: "KHI"
    },
    {
      name: "Alama Iqbal International Airport",
      city: "Lahore",
      country: "Pakistan",
      value: "LHE",
      display: "LHE"
    },
    {
      name: "Muzaffarabad Airport",
      city: "Muzaffarabad",
      country: "Pakistan",
      value: "MFG",
      display: "MFG"
    },
    {
      name: "Moenjodaro Airport",
      city: "Moenjodaro",
      country: "Pakistan",
      value: "MJD",
      display: "MJD"
    },
    {
      name: "Multan International Airport",
      city: "Multan",
      country: "Pakistan",
      value: "MUX",
      display: "MUX"
    },
    {
      name: "Shaheed Benazirabad Airport",
      city: "Nawabshah",
      country: "Pakistan",
      value: "WNS",
      display: "WNS"
    },
    {
      name: "Panjgur Airport",
      city: "Panjgur",
      country: "Pakistan",
      value: "PJG",
      display: "PJG"
    },
    {
      name: "Pasni Airport",
      city: "Pasni",
      country: "Pakistan",
      value: "PSI",
      display: "PSI"
    },
    {
      name: "Peshawar International Airport",
      city: "Peshawar",
      country: "Pakistan",
      value: "PEW",
      display: "PEW"
    },
    {
      name: "Quetta International Airport",
      city: "Quetta",
      country: "Pakistan",
      value: "UET",
      display: "UET"
    },
    {
      name: "Shaikh Zaid Airport",
      city: "Rahim Yar Khan",
      country: "Pakistan",
      value: "RYK",
      display: "RYK"
    },
    {
      name: "Benazir Bhutto International Airport",
      city: "Islamabad",
      country: "Pakistan",
      value: "ISB",
      display: "ISB"
    },
    {
      name: "Rawalakot Airport",
      city: "Rawala Kot",
      country: "Pakistan",
      value: "RAZ",
      display: "RAZ"
    },
    {
      name: "Sukkur Airport",
      city: "Sukkur",
      country: "Pakistan",
      value: "SKZ",
      display: "SKZ"
    },
    {
      name: "Saidu Sharif Airport",
      city: "Saidu Sharif",
      country: "Pakistan",
      value: "SDT",
      display: "SDT"
    },
    {
      name: "Sui Airport",
      city: "Sui",
      country: "Pakistan",
      value: "SUL",
      display: "SUL"
    },
    {
      name: "Talhar Airport",
      city: "Talhar",
      country: "Pakistan",
      value: "BDN",
      display: "BDN"
    },
    {
      name: "Zhob Airport",
      city: "Zhob",
      country: "Pakistan",
      value: "PZH",
      display: "PZH"
    },
    {
      name: "Basrah International Airport",
      city: "Basrah",
      country: "Iraq",
      value: "BSR",
      display: "BSR"
    },
    {
      name: "Aleppo International Airport",
      city: "Aleppo",
      country: "Syria",
      value: "ALP",
      display: "ALP"
    },
    {
      name: "Damascus International Airport",
      city: "Damascus",
      country: "Syria",
      value: "DAM",
      display: "DAM"
    },
    {
      name: "Deir ez-Zor Airport",
      city: "Deire Zor",
      country: "Syria",
      value: "DEZ",
      display: "DEZ"
    },
    {
      name: "Bassel Al-Assad International Airport",
      city: "Latakia",
      country: "Syria",
      value: "LTK",
      display: "LTK"
    },
    {
      name: "Palmyra Airport",
      city: "Palmyra",
      country: "Syria",
      value: "PMS",
      display: "PMS"
    },
    {
      name: "Canton Island Airport",
      city: "Canton Island",
      country: "Kiribati",
      value: "CIS",
      display: "CIS"
    },
    {
      name: "Rota International Airport",
      city: "Rota",
      country: "Northern Mariana Islands",
      value: "ROP",
      display: "ROP"
    },
    {
      name: "Saipan International Airport",
      city: "Saipan",
      country: "Northern Mariana Islands",
      value: "SPN",
      display: "SPN"
    },
    {
      name: "Andersen Air Force Base",
      city: "Andersen",
      country: "Guam",
      value: "UAM",
      display: "UAM"
    },
    {
      name: "Antonio B. Won Pat International Airport",
      city: "Agana",
      country: "Guam",
      value: "GUM",
      display: "GUM"
    },
    {
      name: "Tinian International Airport",
      city: "West Tinian",
      country: "Northern Mariana Islands",
      value: "TIQ",
      display: "TIQ"
    },
    {
      name: "Marshall Islands International Airport",
      city: "Majuro",
      country: "Marshall Islands",
      value: "MAJ",
      display: "MAJ"
    },
    {
      name: "Bucholz Army Air Field",
      city: "Kwajalein",
      country: "Marshall Islands",
      value: "KWA",
      display: "KWA"
    },
    {
      name: "Cassidy International Airport",
      city: "Kiritimati",
      country: "Kiribati",
      value: "CXI",
      display: "CXI"
    },
    {
      name: "Henderson Field",
      city: "Midway",
      country: "Midway Islands",
      value: "MDY",
      display: "MDY"
    },
    {
      name: "Chuuk International Airport",
      city: "Chuuk",
      country: "Micronesia",
      value: "TKK",
      display: "TKK"
    },
    {
      name: "Pohnpei International Airport",
      city: "Pohnpei",
      country: "Micronesia",
      value: "PNI",
      display: "PNI"
    },
    {
      name: "Babelthuap Airport",
      city: "Babelthuap",
      country: "Palau",
      value: "ROR",
      display: "ROR"
    },
    {
      name: "Kosrae International Airport",
      city: "Kosrae",
      country: "Micronesia",
      value: "KSA",
      display: "KSA"
    },
    {
      name: "Yap International Airport",
      city: "Yap",
      country: "Micronesia",
      value: "YAP",
      display: "YAP"
    },
    {
      name: "Kinmen Airport",
      city: "Kinmen",
      country: "Taiwan",
      value: "KNH",
      display: "KNH"
    },
    {
      name: "Taitung Airport",
      city: "Fengnin",
      country: "Taiwan",
      value: "TTT",
      display: "TTT"
    },
    {
      name: "Lyudao Airport",
      city: "Green Island",
      country: "Taiwan",
      value: "GNI",
      display: "GNI"
    },
    {
      name: "Kaohsiung International Airport",
      city: "Kaohsiung",
      country: "Taiwan",
      value: "KHH",
      display: "KHH"
    },
    {
      name: "Chiayi Airport",
      city: "Chiayi",
      country: "Taiwan",
      value: "CYI",
      display: "CYI"
    },
    {
      name: "Lanyu Airport",
      city: "Lanyu",
      country: "Taiwan",
      value: "KYD",
      display: "KYD"
    },
    {
      name: "Taichung Ching Chuang Kang Airport",
      city: "Taichung",
      country: "Taiwan",
      value: "RMQ",
      display: "RMQ"
    },
    {
      name: "Tainan Airport",
      city: "Tainan",
      country: "Taiwan",
      value: "TNN",
      display: "TNN"
    },
    {
      name: "Makung Airport",
      city: "Makung",
      country: "Taiwan",
      value: "MZG",
      display: "MZG"
    },
    {
      name: "Pingtung North Airport",
      city: "Pingtung",
      country: "Taiwan",
      value: "PIF",
      display: "PIF"
    },
    {
      name: "Taipei Songshan Airport",
      city: "Taipei",
      country: "Taiwan",
      value: "TSA",
      display: "TSA"
    },
    {
      name: "Taiwan Taoyuan International Airport",
      city: "Taipei",
      country: "Taiwan",
      value: "TPE",
      display: "TPE"
    },
    {
      name: "Wang-an Airport",
      city: "Wang An",
      country: "Taiwan",
      value: "WOT",
      display: "WOT"
    },
    {
      name: "Hualien Airport",
      city: "Hualien",
      country: "Taiwan",
      value: "HUN",
      display: "HUN"
    },
    {
      name: "Narita International Airport",
      city: "Tokyo",
      country: "Japan",
      value: "NRT",
      display: "NRT"
    },
    {
      name: "Matsumoto Airport",
      city: "Matsumoto",
      country: "Japan",
      value: "MMJ",
      display: "MMJ"
    },
    {
      name: "Hyakuri Airport",
      city: "Ibaraki",
      country: "Japan",
      value: "IBR",
      display: "IBR"
    },
    {
      name: "Iwo Jima Airport",
      city: "Iwojima",
      country: "Japan",
      value: "IWO",
      display: "IWO"
    },
    {
      name: "Nanki Shirahama Airport",
      city: "Nanki-shirahama",
      country: "Japan",
      value: "SHM",
      display: "SHM"
    },
    {
      name: "Tokachi-Obihiro Airport",
      city: "Obihiro",
      country: "Japan",
      value: "OBO",
      display: "OBO"
    },
    {
      name: "New Chitose Airport",
      city: "Sapporo",
      country: "Japan",
      value: "CTS",
      display: "CTS"
    },
    {
      name: "Hakodate Airport",
      city: "Hakodate",
      country: "Japan",
      value: "HKD",
      display: "HKD"
    },
    {
      name: "Chitose Air Base",
      city: "Chitose",
      country: "Japan",
      value: "SPK",
      display: "SPK"
    },
    {
      name: "Memanbetsu Airport",
      city: "Memanbetsu",
      country: "Japan",
      value: "MMB",
      display: "MMB"
    },
    {
      name: "Nakashibetsu Airport",
      city: "Nakashibetsu",
      country: "Japan",
      value: "SHB",
      display: "SHB"
    },
    {
      name: "Wakkanai Airport",
      city: "Wakkanai",
      country: "Japan",
      value: "WKJ",
      display: "WKJ"
    },
    {
      name: "Iki Airport",
      city: "Iki",
      country: "Japan",
      value: "IKI",
      display: "IKI"
    },
    {
      name: "Yamaguchi Ube Airport",
      city: "Yamaguchi",
      country: "Japan",
      value: "UBJ",
      display: "UBJ"
    },
    {
      name: "Tsushima Airport",
      city: "Tsushima",
      country: "Japan",
      value: "TSJ",
      display: "TSJ"
    },
    {
      name: "Monbetsu Airport",
      city: "Monbetsu",
      country: "Japan",
      value: "MBE",
      display: "MBE"
    },
    {
      name: "Asahikawa Airport",
      city: "Asahikawa",
      country: "Japan",
      value: "AKJ",
      display: "AKJ"
    },
    {
      name: "Okushiri Airport",
      city: "Okushiri",
      country: "Japan",
      value: "OIR",
      display: "OIR"
    },
    {
      name: "Rishiri Airport",
      city: "Rishiri Island",
      country: "Japan",
      value: "RIS",
      display: "RIS"
    },
    {
      name: "Yakushima Airport",
      city: "Yakushima",
      country: "Japan",
      value: "KUM",
      display: "KUM"
    },
    {
      name: "Fukue Airport",
      city: "Fukue",
      country: "Japan",
      value: "FUJ",
      display: "FUJ"
    },
    {
      name: "Fukuoka Airport",
      city: "Fukuoka",
      country: "Japan",
      value: "FUK",
      display: "FUK"
    },
    {
      name: "New Tanegashima Airport",
      city: "Tanegashima",
      country: "Japan",
      value: "TNE",
      display: "TNE"
    },
    {
      name: "Kagoshima Airport",
      city: "Kagoshima",
      country: "Japan",
      value: "KOJ",
      display: "KOJ"
    },
    {
      name: "Miyazaki Airport",
      city: "Miyazaki",
      country: "Japan",
      value: "KMI",
      display: "KMI"
    },
    {
      name: "Oita Airport",
      city: "Oita",
      country: "Japan",
      value: "OIT",
      display: "OIT"
    },
    {
      name: "Kitakyushu Airport",
      city: "Kitakyushu",
      country: "Japan",
      value: "KKJ",
      display: "KKJ"
    },
    {
      name: "Kumamoto Airport",
      city: "Kumamoto",
      country: "Japan",
      value: "KMJ",
      display: "KMJ"
    },
    {
      name: "Nagasaki Airport",
      city: "Nagasaki",
      country: "Japan",
      value: "NGS",
      display: "NGS"
    },
    {
      name: "Amami Airport",
      city: "Amami",
      country: "Japan",
      value: "ASJ",
      display: "ASJ"
    },
    {
      name: "Tokunoshima Airport",
      city: "Tokunoshima",
      country: "Japan",
      value: "TKN",
      display: "TKN"
    },
    {
      name: "Komatsu Airport",
      city: "Kanazawa",
      country: "Japan",
      value: "KMQ",
      display: "KMQ"
    },
    {
      name: "Oki Airport",
      city: "Oki Island",
      country: "Japan",
      value: "OKI",
      display: "OKI"
    },
    {
      name: "Toyama Airport",
      city: "Toyama",
      country: "Japan",
      value: "TOY",
      display: "TOY"
    },
    {
      name: "Hiroshima Airport",
      city: "Hiroshima",
      country: "Japan",
      value: "HIJ",
      display: "HIJ"
    },
    {
      name: "Okayama Airport",
      city: "Okayama",
      country: "Japan",
      value: "OKJ",
      display: "OKJ"
    },
    {
      name: "Izumo Airport",
      city: "Izumo",
      country: "Japan",
      value: "IZO",
      display: "IZO"
    },
    {
      name: "Miho Yonago Airport",
      city: "Miho",
      country: "Japan",
      value: "YGJ",
      display: "YGJ"
    },
    {
      name: "Kochi Ryoma Airport",
      city: "Kochi",
      country: "Japan",
      value: "KCZ",
      display: "KCZ"
    },
    {
      name: "Matsuyama Airport",
      city: "Matsuyama",
      country: "Japan",
      value: "MYJ",
      display: "MYJ"
    },
    {
      name: "Osaka International Airport",
      city: "Osaka",
      country: "Japan",
      value: "ITM",
      display: "ITM"
    },
    {
      name: "Tottori Airport",
      city: "Tottori",
      country: "Japan",
      value: "TTJ",
      display: "TTJ"
    },
    {
      name: "Tokushima Airport",
      city: "Tokushima",
      country: "Japan",
      value: "TKS",
      display: "TKS"
    },
    {
      name: "Takamatsu Airport",
      city: "Takamatsu",
      country: "Japan",
      value: "TAK",
      display: "TAK"
    },
    {
      name: "Aomori Airport",
      city: "Aomori",
      country: "Japan",
      value: "AOJ",
      display: "AOJ"
    },
    {
      name: "Yamagata Airport",
      city: "Yamagata",
      country: "Japan",
      value: "GAJ",
      display: "GAJ"
    },
    {
      name: "Sado Airport",
      city: "Sado",
      country: "Japan",
      value: "SDS",
      display: "SDS"
    },
    {
      name: "Hanamaki Airport",
      city: "Hanamaki",
      country: "Japan",
      value: "HNA",
      display: "HNA"
    },
    {
      name: "Akita Airport",
      city: "Akita",
      country: "Japan",
      value: "AXT",
      display: "AXT"
    },
    {
      name: "Misawa Air Base",
      city: "Misawa",
      country: "Japan",
      value: "MSJ",
      display: "MSJ"
    },
    {
      name: "Sendai Airport",
      city: "Sendai",
      country: "Japan",
      value: "SDJ",
      display: "SDJ"
    },
    {
      name: "Hachijojima Airport",
      city: "Hachijojima",
      country: "Japan",
      value: "HAC",
      display: "HAC"
    },
    {
      name: "Oshima Airport",
      city: "Oshima",
      country: "Japan",
      value: "OIM",
      display: "OIM"
    },
    {
      name: "Tokyo Haneda International Airport",
      city: "Tokyo",
      country: "Japan",
      value: "HND",
      display: "HND"
    },
    {
      name: "Yokota Air Base",
      city: "Yokota",
      country: "Japan",
      value: "OKO",
      display: "OKO"
    },
    {
      name: "Gwangju Airport",
      city: "Kwangju",
      country: "South Korea",
      value: "KWJ",
      display: "KWJ"
    },
    {
      name: "Jeon Ju Airport",
      city: "Jhunju",
      country: "South Korea",
      value: "CHN",
      display: "CHN"
    },
    {
      name: "Yeosu Airport",
      city: "Yeosu",
      country: "South Korea",
      value: "RSU",
      display: "RSU"
    },
    {
      name: "Sokcho Airport",
      city: "Sokch o",
      country: "South Korea",
      value: "SHO",
      display: "SHO"
    },
    {
      name: "Gangneung Airport",
      city: "Kangnung",
      country: "South Korea",
      value: "KAG",
      display: "KAG"
    },
    {
      name: "Jeju International Airport",
      city: "Cheju",
      country: "South Korea",
      value: "CJU",
      display: "CJU"
    },
    {
      name: "Gimhae International Airport",
      city: "Busan",
      country: "South Korea",
      value: "PUS",
      display: "PUS"
    },
    {
      name: "Ulsan Airport",
      city: "Ulsan",
      country: "South Korea",
      value: "USN",
      display: "USN"
    },
    {
      name: "Seoul Air Base",
      city: "Seoul East",
      country: "South Korea",
      value: "SSN",
      display: "SSN"
    },
    {
      name: "Osan Air Base",
      city: "Osan",
      country: "South Korea",
      value: "OSN",
      display: "OSN"
    },
    {
      name: "Gimpo International Airport",
      city: "Seoul",
      country: "South Korea",
      value: "GMP",
      display: "GMP"
    },
    {
      name: "Suwon Airport",
      city: "Suwon",
      country: "South Korea",
      value: "SWU",
      display: "SWU"
    },
    {
      name: "Pohang Airport",
      city: "Pohang",
      country: "South Korea",
      value: "KPO",
      display: "KPO"
    },
    {
      name: "Daegu Airport",
      city: "Taegu",
      country: "South Korea",
      value: "TAE",
      display: "TAE"
    },
    {
      name: "Yecheon Airport",
      city: "Yechon",
      country: "South Korea",
      value: "YEC",
      display: "YEC"
    },
    {
      name: "Naha Airport",
      city: "Okinawa",
      country: "Japan",
      value: "OKA",
      display: "OKA"
    },
    {
      name: "Kadena Air Base",
      city: "Kadena",
      country: "Japan",
      value: "DNA",
      display: "DNA"
    },
    {
      name: "Ishigaki Airport",
      city: "Ishigaki",
      country: "Japan",
      value: "ISG",
      display: "ISG"
    },
    {
      name: "Kumejima Airport",
      city: "Kumejima",
      country: "Japan",
      value: "UEO",
      display: "UEO"
    },
    {
      name: "Minami-Daito Airport",
      city: "Minami Daito",
      country: "Japan",
      value: "MMD",
      display: "MMD"
    },
    {
      name: "Miyako Airport",
      city: "Miyako",
      country: "Japan",
      value: "MMY",
      display: "MMY"
    },
    {
      name: "Kitadaito Airport",
      city: "Kitadaito",
      country: "Japan",
      value: "KTD",
      display: "KTD"
    },
    {
      name: "Shimojishima Airport",
      city: "Shimojishima",
      country: "Japan",
      value: "SHI",
      display: "SHI"
    },
    {
      name: "Yoron Airport",
      city: "Yoron",
      country: "Japan",
      value: "RNJ",
      display: "RNJ"
    },
    {
      name: "Yonaguni Airport",
      city: "Yonaguni Jima",
      country: "Japan",
      value: "OGN",
      display: "OGN"
    },
    {
      name: "Ninoy Aquino International Airport",
      city: "Manila",
      country: "Philippines",
      value: "MNL",
      display: "MNL"
    },
    {
      name: "Awang Airport",
      city: "Cotabato",
      country: "Philippines",
      value: "CBO",
      display: "CBO"
    },
    {
      name: "Cagayan De Oro Airport",
      city: "Ladag",
      country: "Philippines",
      value: "CGY",
      display: "CGY"
    },
    {
      name: "Pagadian Airport",
      city: "Pagadian",
      country: "Philippines",
      value: "PAG",
      display: "PAG"
    },
    {
      name: "General Santos International Airport",
      city: "Romblon",
      country: "Philippines",
      value: "GES",
      display: "GES"
    },
    {
      name: "Zamboanga International Airport",
      city: "Zamboanga",
      country: "Philippines",
      value: "ZAM",
      display: "ZAM"
    },
    {
      name: "Loakan Airport",
      city: "Baguio",
      country: "Philippines",
      value: "BAG",
      display: "BAG"
    },
    {
      name: "San Jose Airport",
      city: "San Jose",
      country: "Philippines",
      value: "SJI",
      display: "SJI"
    },
    {
      name: "Daniel Z. Romualdez Airport",
      city: "Tacloban",
      country: "Philippines",
      value: "TAC",
      display: "TAC"
    },
    {
      name: "Bacolod-Silay City International Airport",
      city: "Bacolod",
      country: "Philippines",
      value: "BCD",
      display: "BCD"
    },
    {
      name: "Sibulan Airport",
      city: "Dumaguete",
      country: "Philippines",
      value: "DGT",
      display: "DGT"
    },
    {
      name: "Godofredo P. Ramos Airport",
      city: "Caticlan",
      country: "Philippines",
      value: "MPH",
      display: "MPH"
    },
    {
      name: "Iloilo International Airport",
      city: "Iloilo",
      country: "Philippines",
      value: "ILO",
      display: "ILO"
    },
    {
      name: "Kalibo International Airport",
      city: "Kalibo",
      country: "Philippines",
      value: "KLO",
      display: "KLO"
    },
    {
      name: "Puerto Princesa Airport",
      city: "Puerto Princesa",
      country: "Philippines",
      value: "PPS",
      display: "PPS"
    },
    {
      name: "Comodoro Pierrestegui Airport",
      city: "Concordia",
      country: "Argentina",
      value: "COC",
      display: "COC"
    },
    {
      name: "Gualeguaychu Airport",
      city: "Gualeguaychu",
      country: "Argentina",
      value: "GHU",
      display: "GHU"
    },
    {
      name: "General Urquiza Airport",
      city: "Parana",
      country: "Argentina",
      value: "PRA",
      display: "PRA"
    },
    {
      name: "Islas Malvinas Airport",
      city: "Rosario",
      country: "Argentina",
      value: "ROS",
      display: "ROS"
    },
    {
      name: "Sauce Viejo Airport",
      city: "Santa Fe",
      country: "Argentina",
      value: "SFN",
      display: "SFN"
    },
    {
      name: "Jorge Newbery Airpark",
      city: "Buenos Aires",
      country: "Argentina",
      value: "AEP",
      display: "AEP"
    },
    {
      name: "Ingeniero Ambrosio Taravella Airport",
      city: "Cordoba",
      country: "Argentina",
      value: "COR",
      display: "COR"
    },
    {
      name: "La Plata Airport",
      city: "La Plata",
      country: "Argentina",
      value: "LPG",
      display: "LPG"
    },
    {
      name: "El Plumerillo Airport",
      city: "Mendoza",
      country: "Argentina",
      value: "MDZ",
      display: "MDZ"
    },
    {
      name: "Comodoro D.R. Salomon Airport",
      city: "Malargue",
      country: "Argentina",
      value: "LGS",
      display: "LGS"
    },
    {
      name: "Suboficial Ay Santiago Germano Airport",
      city: "San Rafael",
      country: "Argentina",
      value: "AFA",
      display: "AFA"
    },
    {
      name: "Catamarca Airport",
      city: "Catamarca",
      country: "Argentina",
      value: "CTC",
      display: "CTC"
    },
    {
      name: "Vicecomodoro Angel D. La Paz Aragones Airport",
      city: "Santiago Del Estero",
      country: "Argentina",
      value: "SDE",
      display: "SDE"
    },
    {
      name: "Capitan V A Almonacid Airport",
      city: "La Rioja",
      country: "Argentina",
      value: "IRJ",
      display: "IRJ"
    },
    {
      name: "Teniente Benjamin Matienzo Airport",
      city: "Tucuman",
      country: "Argentina",
      value: "TUC",
      display: "TUC"
    },
    {
      name: "Domingo Faustino Sarmiento Airport",
      city: "San Julian",
      country: "Argentina",
      value: "UAQ",
      display: "UAQ"
    },
    {
      name: "Area De Material Airport",
      city: "Rio Cuarto",
      country: "Argentina",
      value: "RCU",
      display: "RCU"
    },
    {
      name: "Villa Dolores Airport",
      city: "Villa Dolores",
      country: "Argentina",
      value: "VDR",
      display: "VDR"
    },
    {
      name: "Brigadier Mayor D Cesar Raul Ojeda Airport",
      city: "San Luis",
      country: "Argentina",
      value: "LUQ",
      display: "LUQ"
    },
    {
      name: "Corrientes Airport",
      city: "Corrientes",
      country: "Argentina",
      value: "CNQ",
      display: "CNQ"
    },
    {
      name: "Resistencia International Airport",
      city: "Resistencia",
      country: "Argentina",
      value: "RES",
      display: "RES"
    },
    {
      name: "Formosa Airport",
      city: "Formosa",
      country: "Argentina",
      value: "FMA",
      display: "FMA"
    },
    {
      name: "Cataratas Del Iguazu International Airport",
      city: "Iguazu Falls",
      country: "Argentina",
      value: "IGR",
      display: "IGR"
    },
    {
      name: "Paso De Los Libres Airport",
      city: "Paso De Los Libres",
      country: "Argentina",
      value: "AOL",
      display: "AOL"
    },
    {
      name: "Libertador Gral D Jose De San Martin Airport",
      city: "Posadas",
      country: "Argentina",
      value: "PSS",
      display: "PSS"
    },
    {
      name: "Martin Miguel De Guemes International Airport",
      city: "Salta",
      country: "Argentina",
      value: "SLA",
      display: "SLA"
    },
    {
      name: "Gobernador Horacio Guzman International Airport",
      city: "Jujuy",
      country: "Argentina",
      value: "JUJ",
      display: "JUJ"
    },
    {
      name: "Oran Airport",
      city: "Oran",
      country: "Argentina",
      value: "ORA",
      display: "ORA"
    },
    {
      name: "El Bolson Airport",
      city: "El Bolson",
      country: "Argentina",
      value: "EHL",
      display: "EHL"
    },
    {
      name: "General E. Mosconi Airport",
      city: "Comodoro Rivadavia",
      country: "Argentina",
      value: "CRD",
      display: "CRD"
    },
    {
      name: "Brigadier Antonio Parodi Airport",
      city: "Esquel",
      country: "Argentina",
      value: "EQS",
      display: "EQS"
    },
    {
      name: "Almirante Marco Andres Zar Airport",
      city: "Trelew",
      country: "Argentina",
      value: "REL",
      display: "REL"
    },
    {
      name: "Gobernador Castello Airport",
      city: "Viedma",
      country: "Argentina",
      value: "VDM",
      display: "VDM"
    },
    {
      name: "El Tehuelche Airport",
      city: "Puerto Madryn",
      country: "Argentina",
      value: "PMY",
      display: "PMY"
    },
    {
      name: "Puerto Deseado Airport",
      city: "Puerto Deseado",
      country: "Argentina",
      value: "PUD",
      display: "PUD"
    },
    {
      name: "Hermes Quijada International Airport",
      city: "Rio Grande",
      country: "Argentina",
      value: "RGA",
      display: "RGA"
    },
    {
      name: "Piloto Civil N. Fernandez Airport",
      city: "Rio Gallegos",
      country: "Argentina",
      value: "RGL",
      display: "RGL"
    },
    {
      name: "Malvinas Argentinas Airport",
      city: "Ushuaia",
      country: "Argentina",
      value: "USH",
      display: "USH"
    },
    {
      name: "Capitan D Daniel Vazquez Airport",
      city: "San Julian",
      country: "Argentina",
      value: "ULA",
      display: "ULA"
    },
    {
      name: "Perito Moreno Airport",
      city: "Perito Moreno",
      country: "Argentina",
      value: "PMQ",
      display: "PMQ"
    },
    {
      name: "Santa Cruz Airport",
      city: "Santa Cruz",
      country: "Argentina",
      value: "RZA",
      display: "RZA"
    },
    {
      name: "Comandante Espora Airport",
      city: "Bahia Blanca",
      country: "Argentina",
      value: "BHI",
      display: "BHI"
    },
    {
      name: "Astor Piazzola International Airport",
      city: "Mar Del Plata",
      country: "Argentina",
      value: "MDQ",
      display: "MDQ"
    },
    {
      name: "Presidente Peron Airport",
      city: "Neuquen",
      country: "Argentina",
      value: "NQN",
      display: "NQN"
    },
    {
      name: "Santa Rosa Airport",
      city: "Santa Rosa",
      country: "Argentina",
      value: "RSA",
      display: "RSA"
    },
    {
      name: "San Carlos De Bariloche Airport",
      city: "San Carlos De Bariloche",
      country: "Argentina",
      value: "BRC",
      display: "BRC"
    },
    {
      name: "Heroes De Malvinas Airport",
      city: "Tandil",
      country: "Argentina",
      value: "TDL",
      display: "TDL"
    },
    {
      name: "Villa Gesell Airport",
      city: "Villa Gesell",
      country: "Argentina",
      value: "VLG",
      display: "VLG"
    },
    {
      name: "Aviador C. Campos Airport",
      city: "San Martin Des Andes",
      country: "Argentina",
      value: "CPC",
      display: "CPC"
    },
    {
      name: "Conceicao do Araguaia Airport",
      city: "Conceicao Do Araguaia",
      country: "Brazil",
      value: "CDJ",
      display: "CDJ"
    },
    {
      name: "Araraquara Airport",
      city: "Araracuara",
      country: "Brazil",
      value: "AQA",
      display: "AQA"
    },
    {
      name: "Santa Maria Airport",
      city: "Aracaju",
      country: "Brazil",
      value: "AJU",
      display: "AJU"
    },
    {
      name: "Piloto Osvaldo Marques Dias Airport",
      city: "Alta Floresta",
      country: "Brazil",
      value: "AFL",
      display: "AFL"
    },
    {
      name: "Aracatuba Airport",
      city: "Aracatuba",
      country: "Brazil",
      value: "ARU",
      display: "ARU"
    },
    {
      name: "Val de Cans/Julio Cezar Ribeiro International Airport",
      city: "Belem",
      country: "Brazil",
      value: "BEL",
      display: "BEL"
    },
    {
      name: "Comandante Gustavo Kraemer Airport",
      city: "Bage",
      country: "Brazil",
      value: "BGX",
      display: "BGX"
    },
    {
      name: "Pampulha - Carlos Drummond de Andrade Airport",
      city: "Belo Horizonte",
      country: "Brazil",
      value: "PLU",
      display: "PLU"
    },
    {
      name: "Bacacheri Airport",
      city: "Curitiba",
      country: "Brazil",
      value: "BFH",
      display: "BFH"
    },
    {
      name: "Presidente Juscelino Kubistschek International Airport",
      city: "Brasilia",
      country: "Brazil",
      value: "BSB",
      display: "BSB"
    },
    {
      name: "Bauru Airport",
      city: "Bauru",
      country: "Brazil",
      value: "BAU",
      display: "BAU"
    },
    {
      name: "Atlas Brasil Cantanhede Airport",
      city: "Boa Vista",
      country: "Brazil",
      value: "BVB",
      display: "BVB"
    },
    {
      name: "Cascavel Airport",
      city: "Cascavel",
      country: "Brazil",
      value: "CAC",
      display: "CAC"
    },
    {
      name: "Tancredo Neves International Airport",
      city: "Belo Horizonte",
      country: "Brazil",
      value: "CNF",
      display: "CNF"
    },
    {
      name: "Campo Grande Airport",
      city: "Campo Grande",
      country: "Brazil",
      value: "CGR",
      display: "CGR"
    },
    {
      name: "Serafin Enoss Bertaso Airport",
      city: "Chapeco",
      country: "Brazil",
      value: "XAP",
      display: "XAP"
    },
    {
      name: "Brig. Lysias Augusto Rodrigues Airport",
      city: "Carolina",
      country: "Brazil",
      value: "CLN",
      display: "CLN"
    },
    {
      name: "Diomicio Freitas Airport",
      city: "Criciuma",
      country: "Brazil",
      value: "CCM",
      display: "CCM"
    },
    {
      name: "Bartolomeu Lisandro Airport",
      city: "Campos",
      country: "Brazil",
      value: "CAW",
      display: "CAW"
    },
    {
      name: "Corumba International Airport",
      city: "Corumba",
      country: "Brazil",
      value: "CMG",
      display: "CMG"
    },
    {
      name: "Afonso Pena Airport",
      city: "Curitiba",
      country: "Brazil",
      value: "CWB",
      display: "CWB"
    },
    {
      name: "Caravelas Airport",
      city: "Caravelas",
      country: "Brazil",
      value: "CRQ",
      display: "CRQ"
    },
    {
      name: "Hugo Cantergiani Regional Airport",
      city: "Caxias Do Sul",
      country: "Brazil",
      value: "CXJ",
      display: "CXJ"
    },
    {
      name: "Marechal Rondon Airport",
      city: "Cuiaba",
      country: "Brazil",
      value: "CGB",
      display: "CGB"
    },
    {
      name: "Cruzeiro do Sul Airport",
      city: "Cruzeiro do Sul",
      country: "Brazil",
      value: "CZS",
      display: "CZS"
    },
    {
      name: "Presidente Prudente Airport",
      city: "President Prudente",
      country: "Brazil",
      value: "PPB",
      display: "PPB"
    },
    {
      name: "Eduardo Gomes International Airport",
      city: "Manaus",
      country: "Brazil",
      value: "MAO",
      display: "MAO"
    },
    {
      name: "Cataratas International Airport",
      city: "Foz Do Iguacu",
      country: "Brazil",
      value: "IGU",
      display: "IGU"
    },
    {
      name: "Hercilio Luz International Airport",
      city: "Florianopolis",
      country: "Brazil",
      value: "FLN",
      display: "FLN"
    },
    {
      name: "Fernando de Noronha Airport",
      city: "Fernando Do Noronha",
      country: "Brazil",
      value: "FEN",
      display: "FEN"
    },
    {
      name: "Pinto Martins International Airport",
      city: "Fortaleza",
      country: "Brazil",
      value: "FOR",
      display: "FOR"
    },
    {
      name: "Rio Galeao - Tom Jobim International Airport",
      city: "Rio De Janeiro",
      country: "Brazil",
      value: "GIG",
      display: "GIG"
    },
    {
      name: "Santa Genoveva Airport",
      city: "Goiania",
      country: "Brazil",
      value: "GYN",
      display: "GYN"
    },
    {
      name: "Guarulhos - Governador Andre Franco Montoro International Airport",
      city: "Sao Paulo",
      country: "Brazil",
      value: "GRU",
      display: "GRU"
    },
    {
      name: "Altamira Airport",
      city: "Altamira",
      country: "Brazil",
      value: "ATM",
      display: "ATM"
    },
    {
      name: "Itaituba Airport",
      city: "Itaituba",
      country: "Brazil",
      value: "ITB",
      display: "ITB"
    },
    {
      name: "Bahia - Jorge Amado Airport",
      city: "Ilheus",
      country: "Brazil",
      value: "IOS",
      display: "IOS"
    },
    {
      name: "Usiminas Airport",
      city: "Ipatinga",
      country: "Brazil",
      value: "IPN",
      display: "IPN"
    },
    {
      name: "Prefeito Renato Moreira Airport",
      city: "Imperatriz",
      country: "Brazil",
      value: "IMP",
      display: "IMP"
    },
    {
      name: "Francisco de Assis Airport",
      city: "Juiz De Fora",
      country: "Brazil",
      value: "JDF",
      display: "JDF"
    },
    {
      name: "Presidente Castro Pinto International Airport",
      city: "Joao Pessoa",
      country: "Brazil",
      value: "JPA",
      display: "JPA"
    },
    {
      name: "Lauro Carneiro de Loyola Airport",
      city: "Joinville",
      country: "Brazil",
      value: "JOI",
      display: "JOI"
    },
    {
      name: "Presidente Joao Suassuna Airport",
      city: "Campina Grande",
      country: "Brazil",
      value: "CPV",
      display: "CPV"
    },
    {
      name: "Viracopos International Airport",
      city: "Campinas",
      country: "Brazil",
      value: "VCP",
      display: "VCP"
    },
    {
      name: "Lins Airport",
      city: "Lins",
      country: "Brazil",
      value: "LIP",
      display: "LIP"
    },
    {
      name: "Governador Jose Richa Airport",
      city: "Londrina",
      country: "Brazil",
      value: "LDB",
      display: "LDB"
    },
    {
      name: "Bom Jesus da Lapa Airport",
      city: "Bom Jesus Da Lapa",
      country: "Brazil",
      value: "LAZ",
      display: "LAZ"
    },
    {
      name: "Joao Correa da Rocha Airport",
      city: "Maraba",
      country: "Brazil",
      value: "MAB",
      display: "MAB"
    },
    {
      name: "Regional de Maringa - Silvio Nane Junior Airport",
      city: "Maringa",
      country: "Brazil",
      value: "MGF",
      display: "MGF"
    },
    {
      name: "Mario Ribeiro Airport",
      city: "Montes Claros",
      country: "Brazil",
      value: "MOC",
      display: "MOC"
    },
    {
      name: "Ponta Pelada Airport",
      city: "Manaus",
      country: "Brazil",
      value: "PLL",
      display: "PLL"
    },
    {
      name: "Zumbi dos Palmares Airport",
      city: "Maceio",
      country: "Brazil",
      value: "MCZ",
      display: "MCZ"
    },
    {
      name: "Alberto Alcolumbre Airport",
      city: "Macapa",
      country: "Brazil",
      value: "MCP",
      display: "MCP"
    },
    {
      name: "Dix-Sept Rosado Airport",
      city: "Mocord",
      country: "Brazil",
      value: "MVF",
      display: "MVF"
    },
    {
      name: "Manicore Airport",
      city: "Manicore",
      country: "Brazil",
      value: "MNX",
      display: "MNX"
    },
    {
      name: "Ministro Victor Konder International Airport",
      city: "Navegantes",
      country: "Brazil",
      value: "NVT",
      display: "NVT"
    },
    {
      name: "Santo Angelo Airport",
      city: "Santo Angelo",
      country: "Brazil",
      value: "GEL",
      display: "GEL"
    },
    {
      name: "Governador Aluizio Alves International Airport",
      city: "Natal",
      country: "Brazil",
      value: "NAT",
      display: "NAT"
    },
    {
      name: "Salgado Filho Airport",
      city: "Porto Alegre",
      country: "Brazil",
      value: "POA",
      display: "POA"
    },
    {
      name: "Pocos de Caldas - Embaixador Walther Moreira Salles Airport",
      city: "Pocos De Caldas",
      country: "Brazil",
      value: "POO",
      display: "POO"
    },
    {
      name: "Lauro Kurtz Airport",
      city: "Passo Fundo",
      country: "Brazil",
      value: "PFB",
      display: "PFB"
    },
    {
      name: "Joao Simoes Lopes Neto International Airport",
      city: "Pelotas",
      country: "Brazil",
      value: "PET",
      display: "PET"
    },
    {
      name: "Senador Nilo Coelho Airport",
      city: "Petrolina",
      country: "Brazil",
      value: "PNZ",
      display: "PNZ"
    },
    {
      name: "Porto Nacional Airport",
      city: "Porto Nacional",
      country: "Brazil",
      value: "PNB",
      display: "PNB"
    },
    {
      name: "Ponta Pora Airport",
      city: "Ponta Pora",
      country: "Brazil",
      value: "PMG",
      display: "PMG"
    },
    {
      name: "Governador Jorge Teixeira de Oliveira Airport",
      city: "Porto Velho",
      country: "Brazil",
      value: "PVH",
      display: "PVH"
    },
    {
      name: "Placido de Castro Airport",
      city: "Rio Branco",
      country: "Brazil",
      value: "RBR",
      display: "RBR"
    },
    {
      name: "Guararapes - Gilberto Freyre International Airport",
      city: "Recife",
      country: "Brazil",
      value: "REC",
      display: "REC"
    },
    {
      name: "Santos Dumont Airport",
      city: "Rio De Janeiro",
      country: "Brazil",
      value: "SDU",
      display: "SDU"
    },
    {
      name: "Leite Lopes Airport",
      city: "Ribeirao Preto",
      country: "Brazil",
      value: "RAO",
      display: "RAO"
    },
    {
      name: "Santa Cruz Airport",
      city: "Rio De Janeiro",
      country: "Brazil",
      value: "STU",
      display: "STU"
    },
    {
      name: "Professor Urbano Ernesto Stumpf Airport",
      city: "Sao Jose Dos Campos",
      country: "Brazil",
      value: "SJK",
      display: "SJK"
    },
    {
      name: "Marechal Cunha Machado International Airport",
      city: "Sao Luis",
      country: "Brazil",
      value: "SLZ",
      display: "SLZ"
    },
    {
      name: "Congonhas Airport",
      city: "Sao Paulo",
      country: "Brazil",
      value: "CGH",
      display: "CGH"
    },
    {
      name: "Prof. Eribelto Manoel Reino State Airport",
      city: "Sao Jose Do Rio Preto",
      country: "Brazil",
      value: "SJP",
      display: "SJP"
    },
    {
      name: "Base Aerea de Santos Airport",
      city: "Santos",
      country: "Brazil",
      value: "SSZ",
      display: "SSZ"
    },
    {
      name: "Deputado Luiz Eduardo Magalhaes International Airport",
      city: "Salvador",
      country: "Brazil",
      value: "SSA",
      display: "SSA"
    },
    {
      name: "Trombetas Airport",
      city: "Oriximina",
      country: "Brazil",
      value: "TMT",
      display: "TMT"
    },
    {
      name: "Senador Petronio Portela Airport",
      city: "Teresina",
      country: "Brazil",
      value: "THE",
      display: "THE"
    },
    {
      name: "Tefe Airport",
      city: "Tefe",
      country: "Brazil",
      value: "TFF",
      display: "TFF"
    },
    {
      name: "Tabatinga Airport",
      city: "Tabatinga",
      country: "Brazil",
      value: "TBT",
      display: "TBT"
    },
    {
      name: "Tucurui Airport",
      city: "Tucurui",
      country: "Brazil",
      value: "TUR",
      display: "TUR"
    },
    {
      name: "Sao Gabriel da Cachoeira Airport",
      city: "Sao Gabriel",
      country: "Brazil",
      value: "SJL",
      display: "SJL"
    },
    {
      name: "Paulo Afonso Airport",
      city: "Paulo Alfonso",
      country: "Brazil",
      value: "PAV",
      display: "PAV"
    },
    {
      name: "Rubem Berta Airport",
      city: "Uruguaiana",
      country: "Brazil",
      value: "URG",
      display: "URG"
    },
    {
      name: "Ten. Cel. Aviador Cesar Bombonato Airport",
      city: "Uberlandia",
      country: "Brazil",
      value: "UDI",
      display: "UDI"
    },
    {
      name: "Mario de Almeida Franco Airport",
      city: "Uberaba",
      country: "Brazil",
      value: "UBA",
      display: "UBA"
    },
    {
      name: "Major Brigadeiro Trompowsky Airport",
      city: "Varginha",
      country: "Brazil",
      value: "VAG",
      display: "VAG"
    },
    {
      name: "Brigadeiro Camarao Airport",
      city: "Vilhena",
      country: "Brazil",
      value: "BVH",
      display: "BVH"
    },
    {
      name: "Eurico de Aguiar Salles Airport",
      city: "Vitoria",
      country: "Brazil",
      value: "VIX",
      display: "VIX"
    },
    {
      name: "Campo Fontenelle Airport",
      city: "Piracununga",
      country: "Brazil",
      value: "QPS",
      display: "QPS"
    },
    {
      name: "Chacalluta Airport",
      city: "Arica",
      country: "Chile",
      value: "ARI",
      display: "ARI"
    },
    {
      name: "Balmaceda Airport",
      city: "Balmaceda",
      country: "Chile",
      value: "BBA",
      display: "BBA"
    },
    {
      name: "Chile Chico Airport",
      city: "Chile Chico",
      country: "Chile",
      value: "CCH",
      display: "CCH"
    },
    {
      name: "El Loa Airport",
      city: "Calama",
      country: "Chile",
      value: "CJC",
      display: "CJC"
    },
    {
      name: "Pdte. carlos Ibanez del Campo Airport",
      city: "Punta Arenas",
      country: "Chile",
      value: "PUQ",
      display: "PUQ"
    },
    {
      name: "Teniente Vidal Airport",
      city: "Coyhaique",
      country: "Chile",
      value: "GXQ",
      display: "GXQ"
    },
    {
      name: "Diego Aracena Airport",
      city: "Iquique",
      country: "Chile",
      value: "IQQ",
      display: "IQQ"
    },
    {
      name: "Comodoro Arturo Merino Benitez International Airport",
      city: "Santiago",
      country: "Chile",
      value: "SCL",
      display: "SCL"
    },
    {
      name: "Cerro Moreno Airport",
      city: "Antofagasta",
      country: "Chile",
      value: "ANF",
      display: "ANF"
    },
    {
      name: "Capitan Fuentes Martinez Airport Airport",
      city: "Porvenir",
      country: "Chile",
      value: "WPR",
      display: "WPR"
    },
    {
      name: "Maria Dolores Airport",
      city: "Los Angeles",
      country: "Chile",
      value: "LSQ",
      display: "LSQ"
    },
    {
      name: "Guardiamarina Zanartu Airport",
      city: "Puerto Williams",
      country: "Chile",
      value: "WPU",
      display: "WPU"
    },
    {
      name: "Carriel Sur Airport",
      city: "Concepcion",
      country: "Chile",
      value: "CCP",
      display: "CCP"
    },
    {
      name: "Mataveri Airport",
      city: "Easter Island",
      country: "Chile",
      value: "IPC",
      display: "IPC"
    },
    {
      name: "Canal Bajo Carlos - Hott Siebert Airport",
      city: "Osorno",
      country: "Chile",
      value: "ZOS",
      display: "ZOS"
    },
    {
      name: "La Florida Airport",
      city: "La Serena",
      country: "Chile",
      value: "LSC",
      display: "LSC"
    },
    {
      name: "Maquehue Airport",
      city: "Temuco",
      country: "Chile",
      value: "ZCO",
      display: "ZCO"
    },
    {
      name: "El Tepual Airport",
      city: "Puerto Montt",
      country: "Chile",
      value: "PMC",
      display: "PMC"
    },
    {
      name: "Chaiten Airport",
      city: "Chaiten",
      country: "Chile",
      value: "WCH",
      display: "WCH"
    },
    {
      name: "Pichoy Airport",
      city: "Valdivia",
      country: "Chile",
      value: "ZAL",
      display: "ZAL"
    },
    {
      name: "Chachoan Airport",
      city: "Ambato",
      country: "Ecuador",
      value: "ATF",
      display: "ATF"
    },
    {
      name: "Francisco De Orellana Airport",
      city: "Coca",
      country: "Ecuador",
      value: "OCC",
      display: "OCC"
    },
    {
      name: "Mariscal Lamar Airport",
      city: "Cuenca",
      country: "Ecuador",
      value: "CUE",
      display: "CUE"
    },
    {
      name: "Seymour Airport",
      city: "Galapagos",
      country: "Ecuador",
      value: "GPS",
      display: "GPS"
    },
    {
      name: "Jose Joaquin de Olmedo International Airport",
      city: "Guayaquil",
      country: "Ecuador",
      value: "GYE",
      display: "GYE"
    },
    {
      name: "Cotopaxi International Airport",
      city: "Latacunga",
      country: "Ecuador",
      value: "LTX",
      display: "LTX"
    },
    {
      name: "Coronel E Carvajal Airport",
      city: "Macas",
      country: "Ecuador",
      value: "XMS",
      display: "XMS"
    },
    {
      name: "General Manuel Serrano Airport",
      city: "Machala",
      country: "Ecuador",
      value: "MCH",
      display: "MCH"
    },
    {
      name: "Eloy Alfaro International Airport",
      city: "Manta",
      country: "Ecuador",
      value: "MEC",
      display: "MEC"
    },
    {
      name: "Reales Tamarindos Airport",
      city: "Portoviejo",
      country: "Ecuador",
      value: "PVO",
      display: "PVO"
    },
    {
      name: "Mariscal Sucre International Airport",
      city: "Quito",
      country: "Ecuador",
      value: "UIO",
      display: "UIO"
    },
    {
      name: "Santa Rosa International Airport",
      city: "Santa Rosa",
      country: "Ecuador",
      value: "ETR",
      display: "ETR"
    },
    {
      name: "General Ulpiano Paez Airport",
      city: "Salinas",
      country: "Ecuador",
      value: "SNC",
      display: "SNC"
    },
    {
      name: "Tarapoa Airport",
      city: "Tarapoa",
      country: "Ecuador",
      value: "TPC",
      display: "TPC"
    },
    {
      name: "Teniente Coronel Luis a Mantilla Airport",
      city: "Tulcan",
      country: "Ecuador",
      value: "TUA",
      display: "TUA"
    },
    {
      name: "Silvio Pettirossi International Airport",
      city: "Asuncion",
      country: "Paraguay",
      value: "ASU",
      display: "ASU"
    },
    {
      name: "Teniente Col Carmelo Peralta Airport",
      city: "Conception",
      country: "Paraguay",
      value: "CIO",
      display: "CIO"
    },
    {
      name: "El Eden Airport",
      city: "Armenia",
      country: "Colombia",
      value: "AXM",
      display: "AXM"
    },
    {
      name: "Tres De Mayo Airport",
      city: "Puerto Asis",
      country: "Colombia",
      value: "PUU",
      display: "PUU"
    },
    {
      name: "Palonegro Airport",
      city: "Bucaramanga",
      country: "Colombia",
      value: "BGA",
      display: "BGA"
    },
    {
      name: "El Dorado International Airport",
      city: "Bogota",
      country: "Colombia",
      value: "BOG",
      display: "BOG"
    },
    {
      name: "Ernesto Cortissoz International Airport",
      city: "Barranquilla",
      country: "Colombia",
      value: "BAQ",
      display: "BAQ"
    },
    {
      name: "Jose Celestino Mutis Airport",
      city: "Bahia Solano",
      country: "Colombia",
      value: "BSC",
      display: "BSC"
    },
    {
      name: "Gerardo Tobar Lopez Airport",
      city: "Buenaventura",
      country: "Colombia",
      value: "BUN",
      display: "BUN"
    },
    {
      name: "Camilo Daza International Airport",
      city: "Cucuta",
      country: "Colombia",
      value: "CUC",
      display: "CUC"
    },
    {
      name: "Rafael Nunez International Airport",
      city: "Cartagena",
      country: "Colombia",
      value: "CTG",
      display: "CTG"
    },
    {
      name: "Alfonso Bonilla Aragon International Airport",
      city: "Cali",
      country: "Colombia",
      value: "CLO",
      display: "CLO"
    },
    {
      name: "La Florida Airport",
      city: "Tumaco",
      country: "Colombia",
      value: "TCO",
      display: "TCO"
    },
    {
      name: "Las Brujas Airport",
      city: "Corozal",
      country: "Colombia",
      value: "CZU",
      display: "CZU"
    },
    {
      name: "Yariguies Airport",
      city: "Barrancabermeja",
      country: "Colombia",
      value: "EJA",
      display: "EJA"
    },
    {
      name: "Gustavo Artunduaga Paredes Airport",
      city: "Florencia",
      country: "Colombia",
      value: "FLA",
      display: "FLA"
    },
    {
      name: "Juan Casiano Airport",
      city: "Guapi",
      country: "Colombia",
      value: "GPI",
      display: "GPI"
    },
    {
      name: "Perales Airport",
      city: "Ibague",
      country: "Colombia",
      value: "IBE",
      display: "IBE"
    },
    {
      name: "San Luis Airport",
      city: "Ipiales",
      country: "Colombia",
      value: "IPI",
      display: "IPI"
    },
    {
      name: "Antonio Roldan Betancourt Airport",
      city: "Carepa",
      country: "Colombia",
      value: "APO",
      display: "APO"
    },
    {
      name: "Alfredo Vasquez Cobo International Airport",
      city: "Leticia",
      country: "Colombia",
      value: "LET",
      display: "LET"
    },
    {
      name: "Enrique Olaya Herrera Airport",
      city: "Medellin",
      country: "Colombia",
      value: "EOH",
      display: "EOH"
    },
    {
      name: "Baracoa Airport",
      city: "Magangue",
      country: "Colombia",
      value: "MGN",
      display: "MGN"
    },
    {
      name: "Los Garzones Airport",
      city: "Monteria",
      country: "Colombia",
      value: "MTR",
      display: "MTR"
    },
    {
      name: "Fabio Alberto Leon Bentley Airport",
      city: "Mitu",
      country: "Colombia",
      value: "MVP",
      display: "MVP"
    },
    {
      name: "La Nubia Airport",
      city: "Manizales",
      country: "Colombia",
      value: "MZL",
      display: "MZL"
    },
    {
      name: "Benito Salas Airport",
      city: "Neiva",
      country: "Colombia",
      value: "NVA",
      display: "NVA"
    },
    {
      name: "Aguas Claras Airport",
      city: "Ocana",
      country: "Colombia",
      value: "OCV",
      display: "OCV"
    },
    {
      name: "Otu Airport",
      city: "Otu",
      country: "Colombia",
      value: "OTU",
      display: "OTU"
    },
    {
      name: "German Olano Airport",
      city: "Puerto Carreno",
      country: "Colombia",
      value: "PCR",
      display: "PCR"
    },
    {
      name: "Matecana International Airport",
      city: "Pereira",
      country: "Colombia",
      value: "PEI",
      display: "PEI"
    },
    {
      name: "Guillermo Leon Valencia Airport",
      city: "Popayan",
      country: "Colombia",
      value: "PPN",
      display: "PPN"
    },
    {
      name: "Antonio Narino Airport",
      city: "Pasto",
      country: "Colombia",
      value: "PSO",
      display: "PSO"
    },
    {
      name: "El Embrujo Airport",
      city: "Providencia",
      country: "Colombia",
      value: "PVA",
      display: "PVA"
    },
    {
      name: "Jose Maria Cordova International Airport",
      city: "Rio Negro",
      country: "Colombia",
      value: "MDE",
      display: "MDE"
    },
    {
      name: "Almirante Padilla Airport",
      city: "Rio Hacha",
      country: "Colombia",
      value: "RCH",
      display: "RCH"
    },
    {
      name: "Jorge E. Gonzalez Torres Airport",
      city: "San Jose Del Guaviare",
      country: "Colombia",
      value: "SJE",
      display: "SJE"
    },
    {
      name: "Simon Bolivar International Airport",
      city: "Santa Marta",
      country: "Colombia",
      value: "SMR",
      display: "SMR"
    },
    {
      name: "Gustavo Rojas Pinilla International Airport",
      city: "San Andres Island",
      country: "Colombia",
      value: "ADZ",
      display: "ADZ"
    },
    {
      name: "Eduardo Falla Solano Airport",
      city: "San Vincente De Caguan",
      country: "Colombia",
      value: "SVI",
      display: "SVI"
    },
    {
      name: "Gustavo Vargas Airport",
      city: "Tame",
      country: "Colombia",
      value: "TME",
      display: "TME"
    },
    {
      name: "Santiago Perez Airport",
      city: "Arauca",
      country: "Colombia",
      value: "AUC",
      display: "AUC"
    },
    {
      name: "El Carano Airport",
      city: "Quibdo",
      country: "Colombia",
      value: "UIB",
      display: "UIB"
    },
    {
      name: "Heriberto Gil Martinez Airport",
      city: "Tulua",
      country: "Colombia",
      value: "ULQ",
      display: "ULQ"
    },
    {
      name: "Alfonso Lopez Pumarejo Airport",
      city: "Valledupar",
      country: "Colombia",
      value: "VUP",
      display: "VUP"
    },
    {
      name: "Vanguardia Airport",
      city: "Villavicencio",
      country: "Colombia",
      value: "VVC",
      display: "VVC"
    },
    {
      name: "Bermejo Airport",
      city: "Bermejo",
      country: "Bolivia",
      value: "BJO",
      display: "BJO"
    },
    {
      name: "Jorge Wilsterman International Airport",
      city: "Cochabamba",
      country: "Bolivia",
      value: "CBB",
      display: "CBB"
    },
    {
      name: "Capitan Anibal Arab Airport",
      city: "Cobija",
      country: "Bolivia",
      value: "CIJ",
      display: "CIJ"
    },
    {
      name: "El Alto International Airport",
      city: "La Paz",
      country: "Bolivia",
      value: "LPB",
      display: "LPB"
    },
    {
      name: "Capitan Nicolas Rojas Airport",
      city: "Potosi",
      country: "Bolivia",
      value: "POI",
      display: "POI"
    },
    {
      name: "Capitan Av. Salvador Ogaya G. airport",
      city: "Puerto Suarez",
      country: "Bolivia",
      value: "PSZ",
      display: "PSZ"
    },
    {
      name: "Juana Azurduy De Padilla Airport",
      city: "Sucre",
      country: "Bolivia",
      value: "SRE",
      display: "SRE"
    },
    {
      name: "Capitan Oriel Lea Plaza Airport",
      city: "Tarija",
      country: "Bolivia",
      value: "TJA",
      display: "TJA"
    },
    {
      name: "Teniente Av. Jorge Henrich Arauz Airport",
      city: "Trinidad",
      country: "Bolivia",
      value: "TDD",
      display: "TDD"
    },
    {
      name: "Viru Viru International Airport",
      city: "Santa Cruz",
      country: "Bolivia",
      value: "VVI",
      display: "VVI"
    },
    {
      name: "Yacuiba Airport",
      city: "Yacuiba",
      country: "Bolivia",
      value: "BYC",
      display: "BYC"
    },
    {
      name: "Johan Adolf Pengel International Airport",
      city: "Zandery",
      country: "Suriname",
      value: "PBM",
      display: "PBM"
    },
    {
      name: "Cayenne-Rochambeau Airport",
      city: "Cayenne",
      country: "French Guiana",
      value: "CAY",
      display: "CAY"
    },
    {
      name: "Cap FAP David Abenzur Rengifo International Airport",
      city: "Pucallpa",
      country: "Peru",
      value: "PCL",
      display: "PCL"
    },
    {
      name: "Teniente FAP Jaime A De Montreuil Morales Airport",
      city: "Chimbote",
      country: "Peru",
      value: "CHM",
      display: "CHM"
    },
    {
      name: "Capitan FAP Jose A Quinones Gonzales International Airport",
      city: "Chiclayo",
      country: "Peru",
      value: "CIX",
      display: "CIX"
    },
    {
      name: "Coronel FAP Alfredo Mendivil Duarte Airport",
      city: "Ayacucho",
      country: "Peru",
      value: "AYP",
      display: "AYP"
    },
    {
      name: "Andahuaylas Airport",
      city: "Andahuaylas",
      country: "Peru",
      value: "ANS",
      display: "ANS"
    },
    {
      name: "Comandante FAP German Arias Graziani Airport",
      city: "Anta",
      country: "Peru",
      value: "ATA",
      display: "ATA"
    },
    {
      name: "Jorge Chavez International Airport",
      city: "Lima",
      country: "Peru",
      value: "LIM",
      display: "LIM"
    },
    {
      name: "Juanjui Airport",
      city: "Juanjui",
      country: "Peru",
      value: "JJI",
      display: "JJI"
    },
    {
      name: "Inca Manco Capac International Airport",
      city: "Juliaca",
      country: "Peru",
      value: "JUL",
      display: "JUL"
    },
    {
      name: "Capitan FAP Pedro Canga Rodriguez Airport",
      city: "Tumbes",
      country: "Peru",
      value: "TBP",
      display: "TBP"
    },
    {
      name: "Moises Benzaquen Rengifo Airport",
      city: "Yurimaguas",
      country: "Peru",
      value: "YMS",
      display: "YMS"
    },
    {
      name: "Chachapoyas Airport",
      city: "Chachapoyas",
      country: "Peru",
      value: "CHH",
      display: "CHH"
    },
    {
      name: "Coronel FAP Francisco Secada Vignetta International Airport",
      city: "Iquitos",
      country: "Peru",
      value: "IQT",
      display: "IQT"
    },
    {
      name: "Rodriguez Ballon International Airport",
      city: "Arequipa",
      country: "Peru",
      value: "AQP",
      display: "AQP"
    },
    {
      name: "Capitan FAP Carlos Martinez De Pinillos International Airport",
      city: "Trujillo",
      country: "Peru",
      value: "TRU",
      display: "TRU"
    },
    {
      name: "Capitan FAP Renan Elias Olivera International Airport",
      city: "Pisco",
      country: "Peru",
      value: "PIO",
      display: "PIO"
    },
    {
      name: "Cadete FAP Guillermo Del Castillo Paredes Airport",
      city: "Tarapoto",
      country: "Peru",
      value: "TPP",
      display: "TPP"
    },
    {
      name: "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
      city: "Tacna",
      country: "Peru",
      value: "TCQ",
      display: "TCQ"
    },
    {
      name: "Padre Aldamiz International Airport",
      city: "Puerto Maldonado",
      country: "Peru",
      value: "PEM",
      display: "PEM"
    },
    {
      name: "Capitan FAP Guillermo Concha Iberico International Airport",
      city: "Piura",
      country: "Peru",
      value: "PIU",
      display: "PIU"
    },
    {
      name: "Capitan Montes Airport",
      city: "Talara",
      country: "Peru",
      value: "TYL",
      display: "TYL"
    },
    {
      name: "Alejandro Velasco Astete International Airport",
      city: "Cuzco",
      country: "Peru",
      value: "CUZ",
      display: "CUZ"
    },
    {
      name: "Carrasco International /General C L Berisso Airport",
      city: "Montevideo",
      country: "Uruguay",
      value: "MVD",
      display: "MVD"
    },
    {
      name: "Nueva Hesperides International Airport",
      city: "Salto",
      country: "Uruguay",
      value: "STY",
      display: "STY"
    },
    {
      name: "Oswaldo Guevara Mujica Airport",
      city: "Acarigua",
      country: "Venezuela",
      value: "AGV",
      display: "AGV"
    },
    {
      name: "Anaco Airport",
      city: "Anaco",
      country: "Venezuela",
      value: "AAO",
      display: "AAO"
    },
    {
      name: "General Jose Antonio Anzoategui International Airport",
      city: "Barcelona",
      country: "Venezuela",
      value: "BLA",
      display: "BLA"
    },
    {
      name: "Barinas Airport",
      city: "Barinas",
      country: "Venezuela",
      value: "BNS",
      display: "BNS"
    },
    {
      name: "Barquisimeto International Airport",
      city: "Barquisimeto",
      country: "Venezuela",
      value: "BRM",
      display: "BRM"
    },
    {
      name: "Canaima Airport",
      city: "Canaima",
      country: "Venezuela",
      value: "CAJ",
      display: "CAJ"
    },
    {
      name: "General Francisco Bermudez Airport",
      city: "Carupano",
      country: "Venezuela",
      value: "CUP",
      display: "CUP"
    },
    {
      name: "Jose Leonardo Chirinos Airport",
      city: "Coro",
      country: "Venezuela",
      value: "CZE",
      display: "CZE"
    },
    {
      name: "Cumana (Antonio Jose de Sucre) Airport",
      city: "Cumana",
      country: "Venezuela",
      value: "CUM",
      display: "CUM"
    },
    {
      name: "Guiria Airport",
      city: "Guiria",
      country: "Venezuela",
      value: "GUI",
      display: "GUI"
    },
    {
      name: "Guanare Airport",
      city: "Guanare",
      country: "Venezuela",
      value: "GUQ",
      display: "GUQ"
    },
    {
      name: "Josefa Camejo International Airport",
      city: "Paraguana",
      country: "Venezuela",
      value: "LSP",
      display: "LSP"
    },
    {
      name: "La Fria Airport",
      city: "La Fria",
      country: "Venezuela",
      value: "LFR",
      display: "LFR"
    },
    {
      name: "La Chinita International Airport",
      city: "Maracaibo",
      country: "Venezuela",
      value: "MAR",
      display: "MAR"
    },
    {
      name: "Alberto Carnevalli Airport",
      city: "Merida",
      country: "Venezuela",
      value: "MRD",
      display: "MRD"
    },
    {
      name: "Del Caribe Santiago Marino International Airport",
      city: "Porlamar",
      country: "Venezuela",
      value: "PMV",
      display: "PMV"
    },
    {
      name: "Simon Bolivar International Airport",
      city: "Caracas",
      country: "Venezuela",
      value: "CCS",
      display: "CCS"
    },
    {
      name: "Maturin Airport",
      city: "Maturin",
      country: "Venezuela",
      value: "MUN",
      display: "MUN"
    },
    {
      name: "Cacique Aramare Airport",
      city: "Puerto Ayacucho",
      country: "Venezuela",
      value: "PYH",
      display: "PYH"
    },
    {
      name: "General Bartolome Salom International Airport",
      city: "Puerto Cabello",
      country: "Venezuela",
      value: "PBL",
      display: "PBL"
    },
    {
      name: "General Manuel Carlos Piar International Airport",
      city: "Guayana",
      country: "Venezuela",
      value: "PZO",
      display: "PZO"
    },
    {
      name: "San Antonio Del Tachira Airport",
      city: "San Antonio",
      country: "Venezuela",
      value: "SVZ",
      display: "SVZ"
    },
    {
      name: "Santa Elena de Uairen Airport",
      city: "Santa Ana De Uairen",
      country: "Venezuela",
      value: "SNV",
      display: "SNV"
    },
    {
      name: "Mayor Buenaventura Vivas International Airport",
      city: "Santo Domingo",
      country: "Venezuela",
      value: "STD",
      display: "STD"
    },
    {
      name: "San Fernando De Apure Airport",
      city: "San Fernando De Apure",
      country: "Venezuela",
      value: "SFD",
      display: "SFD"
    },
    {
      name: "San Tome Airport",
      city: "San Tome",
      country: "Venezuela",
      value: "SOM",
      display: "SOM"
    },
    {
      name: "Santa Barbara del Zulia Airport",
      city: "Santa Barbara",
      country: "Venezuela",
      value: "STB",
      display: "STB"
    },
    {
      name: "Tucupita Airport",
      city: "Tucupita",
      country: "Venezuela",
      value: "TUV",
      display: "TUV"
    },
    {
      name: "Arturo Michelena International Airport",
      city: "Valencia",
      country: "Venezuela",
      value: "VLN",
      display: "VLN"
    },
    {
      name: "Dr. Antonio Nicolas Briceno Airport",
      city: "Valera",
      country: "Venezuela",
      value: "VLV",
      display: "VLV"
    },
    {
      name: "Valle de La Pascua Airport",
      city: "Valle De La Pascua",
      country: "Venezuela",
      value: "VDP",
      display: "VDP"
    },
    {
      name: "Lethem Airport",
      city: "Lethem",
      country: "Guyana",
      value: "LTM",
      display: "LTM"
    },
    {
      name: "V.C. Bird International Airport",
      city: "Antigua",
      country: "Antigua and Barbuda",
      value: "ANU",
      display: "ANU"
    },
    {
      name: "Sir Grantley Adams International Airport",
      city: "Bridgetown",
      country: "Barbados",
      value: "BGI",
      display: "BGI"
    },
    {
      name: "Canefield Airport",
      city: "Canefield",
      country: "Dominica",
      value: "DCF",
      display: "DCF"
    },
    {
      name: "Melville Hall Airport",
      city: "Dominica",
      country: "Dominica",
      value: "DOM",
      display: "DOM"
    },
    {
      name: "Martinique Aime Cesaire International Airport",
      city: "Fort-de-france",
      country: "Martinique",
      value: "FDF",
      display: "FDF"
    },
    {
      name: "L'Esperance Airport",
      city: "St. Martin",
      country: "Guadeloupe",
      value: "SFG",
      display: "SFG"
    },
    {
      name: "Pointe-a-Pitre Le Raizet",
      city: "Pointe-a-Pitre",
      country: "Guadeloupe",
      value: "PTP",
      display: "PTP"
    },
    {
      name: "Point Salines International Airport",
      city: "Point Salines",
      country: "Grenada",
      value: "GND",
      display: "GND"
    },
    {
      name: "Cyril E. King Airport",
      city: "St. Thomas",
      country: "Virgin Islands",
      value: "STT",
      display: "STT"
    },
    {
      name: "Henry E Rohlsen Airport",
      city: "St. Croix Island",
      country: "Virgin Islands",
      value: "STX",
      display: "STX"
    },
    {
      name: "Rafael Hernandez Airport",
      city: "Aguadilla",
      country: "Puerto Rico",
      value: "BQN",
      display: "BQN"
    },
    {
      name: "Diego Jimenez Torres Airport",
      city: "Fajardo",
      country: "Puerto Rico",
      value: "FAJ",
      display: "FAJ"
    },
    {
      name: "Fernando Luis Ribas Dominicci Airport",
      city: "San Juan",
      country: "Puerto Rico",
      value: "SIG",
      display: "SIG"
    },
    {
      name: "Eugenio Maria De Hostos Airport",
      city: "Mayaguez",
      country: "Puerto Rico",
      value: "MAZ",
      display: "MAZ"
    },
    {
      name: "Mercedita Airport",
      city: "Ponce",
      country: "Puerto Rico",
      value: "PSE",
      display: "PSE"
    },
    {
      name: "Luis Munoz Marin International Airport",
      city: "San Juan",
      country: "Puerto Rico",
      value: "SJU",
      display: "SJU"
    },
    {
      name: "Robert L. Bradshaw International Airport",
      city: "Basse Terre",
      country: "Saint Kitts and Nevis",
      value: "SKB",
      display: "SKB"
    },
    {
      name: "George F. L. Charles Airport",
      city: "Castries",
      country: "Saint Lucia",
      value: "SLU",
      display: "SLU"
    },
    {
      name: "Hewanorra International Airport",
      city: "Hewandorra",
      country: "Saint Lucia",
      value: "UVF",
      display: "UVF"
    },
    {
      name: "Queen Beatrix International Airport",
      city: "Oranjestad",
      country: "Aruba",
      value: "AUA",
      display: "AUA"
    },
    {
      name: "Flamingo International Airport",
      city: "Kralendijk",
      country: "Netherlands Antilles",
      value: "BON",
      display: "BON"
    },
    {
      name: "Hato International Airport",
      city: "Willemstad",
      country: "Netherlands Antilles",
      value: "CUR",
      display: "CUR"
    },
    {
      name: "F. D. Roosevelt Airport",
      city: "Oranjestad",
      country: "Netherlands Antilles",
      value: "EUX",
      display: "EUX"
    },
    {
      name: "Princess Juliana International Airport",
      city: "Philipsburg",
      country: "Netherlands Antilles",
      value: "SXM",
      display: "SXM"
    },
    {
      name: "Wallblake Airport",
      city: "The Valley",
      country: "Anguilla",
      value: "AXA",
      display: "AXA"
    },
    {
      name: "Tobago-Crown Point Airport",
      city: "Scarborough",
      country: "Trinidad and Tobago",
      value: "TAB",
      display: "TAB"
    },
    {
      name: "Piarco International Airport",
      city: "Port of Spain",
      country: "Trinidad and Tobago",
      value: "POS",
      display: "POS"
    },
    {
      name: "Terrance B. Lettsome International Airport",
      city: "Tortola",
      country: "British Virgin Islands",
      value: "EIS",
      display: "EIS"
    },
    {
      name: "Canouan Airport",
      city: "Canouan Island",
      country: "Saint Vincent and the Grenadines",
      value: "CIW",
      display: "CIW"
    },
    {
      name: "Mustique Airport",
      city: "Mustique",
      country: "Saint Vincent and the Grenadines",
      value: "MQS",
      display: "MQS"
    },
    {
      name: "E. T. Joshua Airport",
      city: "Kingstown",
      country: "Saint Vincent and the Grenadines",
      value: "SVD",
      display: "SVD"
    },
    {
      name: "Almaty Airport",
      city: "Alma-ata",
      country: "Kazakhstan",
      value: "ALA",
      display: "ALA"
    },
    {
      name: "Balkhash Airport",
      city: "Balkhash",
      country: "Kazakhstan",
      value: "BXH",
      display: "BXH"
    },
    {
      name: "Astana International Airport",
      city: "Tselinograd",
      country: "Kazakhstan",
      value: "TSE",
      display: "TSE"
    },
    {
      name: "Taraz Airport",
      city: "Dzhambul",
      country: "Kazakhstan",
      value: "DMB",
      display: "DMB"
    },
    {
      name: "Manas International Airport",
      city: "Bishkek",
      country: "Kyrgyzstan",
      value: "FRU",
      display: "FRU"
    },
    {
      name: "Osh Airport",
      city: "Osh",
      country: "Kyrgyzstan",
      value: "OSS",
      display: "OSS"
    },
    {
      name: "Shymkent Airport",
      city: "Chimkent",
      country: "Kazakhstan",
      value: "CIT",
      display: "CIT"
    },
    {
      name: "Uralsk Airport",
      city: "Uralsk",
      country: "Kazakhstan",
      value: "URA",
      display: "URA"
    },
    {
      name: "Pavlodar Airport",
      city: "Pavlodar",
      country: "Kazakhstan",
      value: "PWQ",
      display: "PWQ"
    },
    {
      name: "Semipalatinsk Airport",
      city: "Semiplatinsk",
      country: "Kazakhstan",
      value: "PLX",
      display: "PLX"
    },
    {
      name: "Aktobe Airport",
      city: "Aktyubinsk",
      country: "Kazakhstan",
      value: "AKX",
      display: "AKX"
    },
    {
      name: "Heydar Aliyev International Airport",
      city: "Baku",
      country: "Azerbaijan",
      value: "GYD",
      display: "GYD"
    },
    {
      name: "Yakutsk Airport",
      city: "Yakutsk",
      country: "Russia",
      value: "YKS",
      display: "YKS"
    },
    {
      name: "Mirny Airport",
      city: "Mirnyj",
      country: "Russia",
      value: "MJZ",
      display: "MJZ"
    },
    {
      name: "Ignatyevo Airport",
      city: "Blagoveschensk",
      country: "Russia",
      value: "BQS",
      display: "BQS"
    },
    {
      name: "Khabarovsk-Novy Airport",
      city: "Khabarovsk",
      country: "Russia",
      value: "KHV",
      display: "KHV"
    },
    {
      name: "Provideniya Bay Airport",
      city: "Provideniya Bay",
      country: "Russia",
      value: "PVS",
      display: "PVS"
    },
    {
      name: "Sokol Airport",
      city: "Magadan",
      country: "Russia",
      value: "GDX",
      display: "GDX"
    },
    {
      name: "Pevek Airport",
      city: "Pevek",
      country: "Russia",
      value: "PWE",
      display: "PWE"
    },
    {
      name: "Yelizovo Airport",
      city: "Petropavlovsk",
      country: "Russia",
      value: "PKC",
      display: "PKC"
    },
    {
      name: "Yuzhno-Sakhalinsk Airport",
      city: "Yuzhno-sakhalinsk",
      country: "Russia",
      value: "UUS",
      display: "UUS"
    },
    {
      name: "Vladivostok International Airport",
      city: "Vladivostok",
      country: "Russia",
      value: "VVO",
      display: "VVO"
    },
    {
      name: "Chita-Kadala Airport",
      city: "Chita",
      country: "Russia",
      value: "HTA",
      display: "HTA"
    },
    {
      name: "Bratsk Airport",
      city: "Bratsk",
      country: "Russia",
      value: "BTK",
      display: "BTK"
    },
    {
      name: "Irkutsk Airport",
      city: "Irkutsk",
      country: "Russia",
      value: "IKT",
      display: "IKT"
    },
    {
      name: "Ulan-Ude Airport (Mukhino)",
      city: "Ulan-ude",
      country: "Russia",
      value: "UUD",
      display: "UUD"
    },
    {
      name: "Boryspil International Airport",
      city: "Kiev",
      country: "Ukraine",
      value: "KBP",
      display: "KBP"
    },
    {
      name: "Donetsk International Airport",
      city: "Donetsk",
      country: "Ukraine",
      value: "DOK",
      display: "DOK"
    },
    {
      name: "Dnipropetrovsk International Airport",
      city: "Dnepropetrovsk",
      country: "Ukraine",
      value: "DNK",
      display: "DNK"
    },
    {
      name: "Simferopol International Airport",
      city: "Simferopol",
      country: "Ukraine",
      value: "SIP",
      display: "SIP"
    },
    {
      name: "Kiev Zhuliany International Airport",
      city: "Kiev",
      country: "Ukraine",
      value: "IEV",
      display: "IEV"
    },
    {
      name: "Lviv International Airport",
      city: "Lvov",
      country: "Ukraine",
      value: "LWO",
      display: "LWO"
    },
    {
      name: "Odessa International Airport",
      city: "Odessa",
      country: "Ukraine",
      value: "ODS",
      display: "ODS"
    },
    {
      name: "Pulkovo Airport",
      city: "St. Petersburg",
      country: "Russia",
      value: "LED",
      display: "LED"
    },
    {
      name: "Murmansk Airport",
      city: "Murmansk",
      country: "Russia",
      value: "MMK",
      display: "MMK"
    },
    {
      name: "Gomel Airport",
      city: "Gomel",
      country: "Belarus",
      value: "GME",
      display: "GME"
    },
    {
      name: "Vitebsk Vostochny Airport",
      city: "Vitebsk",
      country: "Belarus",
      value: "VTB",
      display: "VTB"
    },
    {
      name: "Khrabrovo Airport",
      city: "Kaliningrad",
      country: "Russia",
      value: "KGD",
      display: "KGD"
    },
    {
      name: "Minsk-1 Airport",
      city: "Minsk",
      country: "Belarus",
      value: "MHP",
      display: "MHP"
    },
    {
      name: "Minsk National Airport",
      city: "Minsk",
      country: "Belarus",
      value: "MSQ",
      display: "MSQ"
    },
    {
      name: "Abakan Airport",
      city: "Abakan",
      country: "Russia",
      value: "ABA",
      display: "ABA"
    },
    {
      name: "Barnaul Airport",
      city: "Barnaul",
      country: "Russia",
      value: "BAX",
      display: "BAX"
    },
    {
      name: "Kemerovo Airport",
      city: "Kemorovo",
      country: "Russia",
      value: "KEJ",
      display: "KEJ"
    },
    {
      name: "Omsk Central Airport",
      city: "Omsk",
      country: "Russia",
      value: "OMS",
      display: "OMS"
    },
    {
      name: "Krasnodar Pashkovsky International Airport",
      city: "Krasnodar",
      country: "Russia",
      value: "KRR",
      display: "KRR"
    },
    {
      name: "Uytash Airport",
      city: "Makhachkala",
      country: "Russia",
      value: "MCX",
      display: "MCX"
    },
    {
      name: "Mineralnyye Vody Airport",
      city: "Mineralnye Vody",
      country: "Russia",
      value: "MRV",
      display: "MRV"
    },
    {
      name: "Stavropol Shpakovskoye Airport",
      city: "Stavropol",
      country: "Russia",
      value: "STW",
      display: "STW"
    },
    {
      name: "Rostov-on-Don Airport",
      city: "Rostov",
      country: "Russia",
      value: "ROV",
      display: "ROV"
    },
    {
      name: "Sochi International Airport",
      city: "Sochi",
      country: "Russia",
      value: "AER",
      display: "AER"
    },
    {
      name: "Astrakhan Airport",
      city: "Astrakhan",
      country: "Russia",
      value: "ASF",
      display: "ASF"
    },
    {
      name: "Volgograd International Airport",
      city: "Volgograd",
      country: "Russia",
      value: "VOG",
      display: "VOG"
    },
    {
      name: "Chelyabinsk Balandino Airport",
      city: "Chelyabinsk",
      country: "Russia",
      value: "CEK",
      display: "CEK"
    },
    {
      name: "Magnitogorsk International Airport",
      city: "Magnetiogorsk",
      country: "Russia",
      value: "MQF",
      display: "MQF"
    },
    {
      name: "Nizhnevartovsk Airport",
      city: "Nizhnevartovsk",
      country: "Russia",
      value: "NJC",
      display: "NJC"
    },
    {
      name: "Bolshoye Savino Airport",
      city: "Perm",
      country: "Russia",
      value: "PEE",
      display: "PEE"
    },
    {
      name: "Surgut Airport",
      city: "Surgut",
      country: "Russia",
      value: "SGC",
      display: "SGC"
    },
    {
      name: "Koltsovo Airport",
      city: "Yekaterinburg",
      country: "Russia",
      value: "SVX",
      display: "SVX"
    },
    {
      name: "Ashgabat Airport",
      city: "Ashkhabad",
      country: "Turkmenistan",
      value: "ASB",
      display: "ASB"
    },
    {
      name: "Turkmenbashi Airport",
      city: "Krasnovodsk",
      country: "Turkmenistan",
      value: "KRW",
      display: "KRW"
    },
    {
      name: "Turkmenabat Airport",
      city: "Chardzhou",
      country: "Turkmenistan",
      value: "CRZ",
      display: "CRZ"
    },
    {
      name: "Dushanbe Airport",
      city: "Dushanbe",
      country: "Tajikistan",
      value: "DYU",
      display: "DYU"
    },
    {
      name: "Bukhara Airport",
      city: "Bukhara",
      country: "Uzbekistan",
      value: "BHK",
      display: "BHK"
    },
    {
      name: "Samarkand Airport",
      city: "Samarkand",
      country: "Uzbekistan",
      value: "SKD",
      display: "SKD"
    },
    {
      name: "Tashkent International Airport",
      city: "Tashkent",
      country: "Uzbekistan",
      value: "TAS",
      display: "TAS"
    },
    {
      name: "Bryansk Airport",
      city: "Bryansk",
      country: "Russia",
      value: "BZK",
      display: "BZK"
    },
    {
      name: "Sheremetyevo International Airport",
      city: "Moscow",
      country: "Russia",
      value: "SVO",
      display: "SVO"
    },
    {
      name: "Migalovo Air Base",
      city: "Tver",
      country: "Russia",
      value: "KLD",
      display: "KLD"
    },
    {
      name: "Voronezh International Airport",
      city: "Voronezh",
      country: "Russia",
      value: "VOZ",
      display: "VOZ"
    },
    {
      name: "Vnukovo International Airport",
      city: "Moscow",
      country: "Russia",
      value: "VKO",
      display: "VKO"
    },
    {
      name: "Syktyvkar Airport",
      city: "Syktyvkar",
      country: "Russia",
      value: "SCW",
      display: "SCW"
    },
    {
      name: "Kazan International Airport",
      city: "Kazan",
      country: "Russia",
      value: "KZN",
      display: "KZN"
    },
    {
      name: "Orenburg Central Airport",
      city: "Orenburg",
      country: "Russia",
      value: "REN",
      display: "REN"
    },
    {
      name: "Ufa International Airport",
      city: "Ufa",
      country: "Russia",
      value: "UFA",
      display: "UFA"
    },
    {
      name: "Kurumoch International Airport",
      city: "Samara",
      country: "Russia",
      value: "KUF",
      display: "KUF"
    },
    {
      name: "Sardar Vallabhbhai Patel International Airport",
      city: "Ahmedabad",
      country: "India",
      value: "AMD",
      display: "AMD"
    },
    {
      name: "Akola Airport",
      city: "Akola",
      country: "India",
      value: "AKD",
      display: "AKD"
    },
    {
      name: "Aurangabad Airport",
      city: "Aurangabad",
      country: "India",
      value: "IXU",
      display: "IXU"
    },
    {
      name: "Chhatrapati Shivaji International Airport",
      city: "Mumbai",
      country: "India",
      value: "BOM",
      display: "BOM"
    },
    {
      name: "Bilaspur Airport",
      city: "Bilaspur",
      country: "India",
      value: "PAB",
      display: "PAB"
    },
    {
      name: "Bhuj Airport",
      city: "Bhuj",
      country: "India",
      value: "BHJ",
      display: "BHJ"
    },
    {
      name: "Belgaum Airport",
      city: "Belgaum",
      country: "India",
      value: "IXG",
      display: "IXG"
    },
    {
      name: "Vadodara Airport",
      city: "Baroda",
      country: "India",
      value: "BDQ",
      display: "BDQ"
    },
    {
      name: "Raja Bhoj International Airport",
      city: "Bhopal",
      country: "India",
      value: "BHO",
      display: "BHO"
    },
    {
      name: "Bhavnagar Airport",
      city: "Bhaunagar",
      country: "India",
      value: "BHU",
      display: "BHU"
    },
    {
      name: "Daman Airport",
      city: "Daman",
      country: "India",
      value: "NMB",
      display: "NMB"
    },
    {
      name: "Dabolim Airport",
      city: "Goa",
      country: "India",
      value: "GOI",
      display: "GOI"
    },
    {
      name: "Devi Ahilyabai Holkar Airport",
      city: "Indore",
      country: "India",
      value: "IDR",
      display: "IDR"
    },
    {
      name: "Jabalpur Airport",
      city: "Jabalpur",
      country: "India",
      value: "JLR",
      display: "JLR"
    },
    {
      name: "Jamnagar Airport",
      city: "Jamnagar",
      country: "India",
      value: "JGA",
      display: "JGA"
    },
    {
      name: "Kandla Airport",
      city: "Kandla",
      country: "India",
      value: "IXY",
      display: "IXY"
    },
    {
      name: "Khajuraho Airport",
      city: "Khajuraho",
      country: "India",
      value: "HJR",
      display: "HJR"
    },
    {
      name: "Kolhapur Airport",
      city: "Kolhapur",
      country: "India",
      value: "KLH",
      display: "KLH"
    },
    {
      name: "Keshod Airport",
      city: "Keshod",
      country: "India",
      value: "IXK",
      display: "IXK"
    },
    {
      name: "Dr. Babasaheb Ambedkar International Airport",
      city: "Nagpur",
      country: "India",
      value: "NAG",
      display: "NAG"
    },
    {
      name: "Gandhinagar Airport",
      city: "Nasik Road",
      country: "India",
      value: "ISK",
      display: "ISK"
    },
    {
      name: "Pune Airport",
      city: "Pune",
      country: "India",
      value: "PNQ",
      display: "PNQ"
    },
    {
      name: "Porbandar Airport",
      city: "Porbandar",
      country: "India",
      value: "PBD",
      display: "PBD"
    },
    {
      name: "Rajkot Airport",
      city: "Rajkot",
      country: "India",
      value: "RAJ",
      display: "RAJ"
    },
    {
      name: "Raipur Airport",
      city: "Raipur",
      country: "India",
      value: "RPR",
      display: "RPR"
    },
    {
      name: "Solapur Airport",
      city: "Sholapur",
      country: "India",
      value: "SSE",
      display: "SSE"
    },
    {
      name: "Surat Airport",
      city: "Surat",
      country: "India",
      value: "STV",
      display: "STV"
    },
    {
      name: "Maharana Pratap Airport",
      city: "Udaipur",
      country: "India",
      value: "UDR",
      display: "UDR"
    },
    {
      name: "Bandaranaike International Colombo Airport",
      city: "Colombo",
      country: "Sri Lanka",
      value: "CMB",
      display: "CMB"
    },
    {
      name: "Anuradhapura Air Force Base",
      city: "Anuradhapura",
      country: "Sri Lanka",
      value: "ACJ",
      display: "ACJ"
    },
    {
      name: "Colombo Ratmalana Airport",
      city: "Colombo",
      country: "Sri Lanka",
      value: "RML",
      display: "RML"
    },
    {
      name: "Ampara Airport",
      city: "Galoya",
      country: "Sri Lanka",
      value: "GOY",
      display: "GOY"
    },
    {
      name: "Kankesanturai Airport",
      city: "Jaffna",
      country: "Sri Lanka",
      value: "JAF",
      display: "JAF"
    },
    {
      name: "China Bay Airport",
      city: "Trinciomalee",
      country: "Sri Lanka",
      value: "TRR",
      display: "TRR"
    },
    {
      name: "Phnom Penh International Airport",
      city: "Phnom Penh",
      country: "Cambodia",
      value: "PNH",
      display: "PNH"
    },
    {
      name: "Siem Reap International Airport",
      city: "Siem-reap",
      country: "Cambodia",
      value: "REP",
      display: "REP"
    },
    {
      name: "Stung Treng Airport",
      city: "Stung Treng",
      country: "Cambodia",
      value: "TNX",
      display: "TNX"
    },
    {
      name: "Agartala Airport",
      city: "Agartala",
      country: "India",
      value: "IXA",
      display: "IXA"
    },
    {
      name: "Lengpui Airport",
      city: "Aizwal",
      country: "India",
      value: "AJL",
      display: "AJL"
    },
    {
      name: "Bagdogra Airport",
      city: "Baghdogra",
      country: "India",
      value: "IXB",
      display: "IXB"
    },
    {
      name: "Biju Patnaik Airport",
      city: "Bhubaneswar",
      country: "India",
      value: "BBI",
      display: "BBI"
    },
    {
      name: "Netaji Subhash Chandra Bose International Airport",
      city: "Kolkata",
      country: "India",
      value: "CCU",
      display: "CCU"
    },
    {
      name: "Cooch Behar Airport",
      city: "Cooch-behar",
      country: "India",
      value: "COH",
      display: "COH"
    },
    {
      name: "Dhanbad Airport",
      city: "Dhanbad",
      country: "India",
      value: "DBD",
      display: "DBD"
    },
    {
      name: "Gaya Airport",
      city: "Gaya",
      country: "India",
      value: "GAY",
      display: "GAY"
    },
    {
      name: "Imphal Airport",
      city: "Imphal",
      country: "India",
      value: "IMF",
      display: "IMF"
    },
    {
      name: "Jamshedpur Airport",
      city: "Jamshedpur",
      country: "India",
      value: "IXW",
      display: "IXW"
    },
    {
      name: "Jorhat Airport",
      city: "Jorhat",
      country: "India",
      value: "JRH",
      display: "JRH"
    },
    {
      name: "Kailashahar Airport",
      city: "Kailashahar",
      country: "India",
      value: "IXH",
      display: "IXH"
    },
    {
      name: "Silchar Airport",
      city: "Silchar",
      country: "India",
      value: "IXS",
      display: "IXS"
    },
    {
      name: "North Lakhimpur Airport",
      city: "Lilabari",
      country: "India",
      value: "IXI",
      display: "IXI"
    },
    {
      name: "Dibrugarh Airport",
      city: "Mohanbari",
      country: "India",
      value: "DIB",
      display: "DIB"
    },
    {
      name: "Lok Nayak Jayaprakash Airport",
      city: "Patina",
      country: "India",
      value: "PAT",
      display: "PAT"
    },
    {
      name: "Birsa Munda Airport",
      city: "Ranchi",
      country: "India",
      value: "IXR",
      display: "IXR"
    },
    {
      name: "Rourkela Airport",
      city: "Rourkela",
      country: "India",
      value: "RRK",
      display: "RRK"
    },
    {
      name: "Vishakhapatnam Airport",
      city: "Vishakhapatnam",
      country: "India",
      value: "VTZ",
      display: "VTZ"
    },
    {
      name: "Cox's Bazar Airport",
      city: "Cox s Bazar",
      country: "Bangladesh",
      value: "CXB",
      display: "CXB"
    },
    {
      name: "Shah Amanat International Airport",
      city: "Chittagong",
      country: "Bangladesh",
      value: "CGP",
      display: "CGP"
    },
    {
      name: "Ishurdi Airport",
      city: "Ishurdi",
      country: "Bangladesh",
      value: "IRD",
      display: "IRD"
    },
    {
      name: "Jessore Airport",
      city: "Jessore",
      country: "Bangladesh",
      value: "JSR",
      display: "JSR"
    },
    {
      name: "Shah Mokhdum Airport",
      city: "Rajshahi",
      country: "Bangladesh",
      value: "RJH",
      display: "RJH"
    },
    {
      name: "Saidpur Airport",
      city: "Saidpur",
      country: "Bangladesh",
      value: "SPD",
      display: "SPD"
    },
    {
      name: "Osmany International Airport",
      city: "Sylhet Osmani",
      country: "Bangladesh",
      value: "ZYL",
      display: "ZYL"
    },
    {
      name: "Dhaka / Hazrat Shahjalal International Airport",
      city: "Dhaka",
      country: "Bangladesh",
      value: "DAC",
      display: "DAC"
    },
    {
      name: "Chek Lap Kok International Airport",
      city: "Hong Kong",
      country: "Hong Kong",
      value: "HKG",
      display: "HKG"
    },
    {
      name: "Agra Airport",
      city: "Agra",
      country: "India",
      value: "AGR",
      display: "AGR"
    },
    {
      name: "Allahabad Airport",
      city: "Allahabad",
      country: "India",
      value: "IXD",
      display: "IXD"
    },
    {
      name: "Sri Guru Ram Dass Jee International Airport",
      city: "Amritsar",
      country: "India",
      value: "ATQ",
      display: "ATQ"
    },
    {
      name: "Lal Bahadur Shastri Airport",
      city: "Varanasi",
      country: "India",
      value: "VNS",
      display: "VNS"
    },
    {
      name: "Kullu Manali Airport",
      city: "Kulu",
      country: "India",
      value: "KUU",
      display: "KUU"
    },
    {
      name: "Chandigarh Airport",
      city: "Chandigarh",
      country: "India",
      value: "IXC",
      display: "IXC"
    },
    {
      name: "Dehradun Airport",
      city: "Dehra Dun",
      country: "India",
      value: "DED",
      display: "DED"
    },
    {
      name: "Indira Gandhi International Airport",
      city: "Delhi",
      country: "India",
      value: "DEL",
      display: "DEL"
    },
    {
      name: "Gwalior Airport",
      city: "Gwalior",
      country: "India",
      value: "GWL",
      display: "GWL"
    },
    {
      name: "Jodhpur Airport",
      city: "Jodhpur",
      country: "India",
      value: "JDH",
      display: "JDH"
    },
    {
      name: "Jaipur International Airport",
      city: "Jaipur",
      country: "India",
      value: "JAI",
      display: "JAI"
    },
    {
      name: "Jaisalmer Airport",
      city: "Jaisalmer",
      country: "India",
      value: "JSA",
      display: "JSA"
    },
    {
      name: "Jammu Airport",
      city: "Jammu",
      country: "India",
      value: "IXJ",
      display: "IXJ"
    },
    {
      name: "Kanpur Airport",
      city: "Kanpur",
      country: "India",
      value: "KNU",
      display: "KNU"
    },
    {
      name: "Kota Airport",
      city: "Kota",
      country: "India",
      value: "KTU",
      display: "KTU"
    },
    {
      name: "Ludhiana Airport",
      city: "Ludhiaha",
      country: "India",
      value: "LUH",
      display: "LUH"
    },
    {
      name: "Leh Kushok Bakula Rimpochee Airport",
      city: "Leh",
      country: "India",
      value: "IXL",
      display: "IXL"
    },
    {
      name: "Chaudhary Charan Singh International Airport",
      city: "Lucknow",
      country: "India",
      value: "LKO",
      display: "LKO"
    },
    {
      name: "Pantnagar Airport",
      city: "Nainital",
      country: "India",
      value: "PGH",
      display: "PGH"
    },
    {
      name: "Sheikh ul Alam Airport",
      city: "Srinagar",
      country: "India",
      value: "SXR",
      display: "SXR"
    },
    {
      name: "Satna Airport",
      city: "Satna",
      country: "India",
      value: "TNI",
      display: "TNI"
    },
    {
      name: "Luang Phabang International Airport",
      city: "Luang Prabang",
      country: "Laos",
      value: "LPQ",
      display: "LPQ"
    },
    {
      name: "Pakse International Airport",
      city: "Pakse",
      country: "Laos",
      value: "PKZ",
      display: "PKZ"
    },
    {
      name: "Savannakhet Airport",
      city: "Savannakhet",
      country: "Laos",
      value: "ZVK",
      display: "ZVK"
    },
    {
      name: "Wattay International Airport",
      city: "Vientiane",
      country: "Laos",
      value: "VTE",
      display: "VTE"
    },
    {
      name: "Macau International Airport",
      city: "Macau",
      country: "Macau",
      value: "MFM",
      display: "MFM"
    },
    {
      name: "Gautam Buddha Airport",
      city: "Bhairawa",
      country: "Nepal",
      value: "BWA",
      display: "BWA"
    },
    {
      name: "Tribhuvan International Airport",
      city: "Kathmandu",
      country: "Nepal",
      value: "KTM",
      display: "KTM"
    },
    {
      name: "Pokhara Airport",
      city: "Pokhara",
      country: "Nepal",
      value: "PKR",
      display: "PKR"
    },
    {
      name: "Simara Airport",
      city: "Simara",
      country: "Nepal",
      value: "SIF",
      display: "SIF"
    },
    {
      name: "Biratnagar Airport",
      city: "Biratnagar",
      country: "Nepal",
      value: "BIR",
      display: "BIR"
    },
    {
      name: "Agatti Airport",
      city: "Agatti Island",
      country: "India",
      value: "AGX",
      display: "AGX"
    },
    {
      name: "Kempegowda International Airport",
      city: "Bangalore",
      country: "India",
      value: "BLR",
      display: "BLR"
    },
    {
      name: "Bellary Airport",
      city: "Bellary",
      country: "India",
      value: "BEP",
      display: "BEP"
    },
    {
      name: "Vijayawada Airport",
      city: "Vijayawada",
      country: "India",
      value: "VGA",
      display: "VGA"
    },
    {
      name: "Coimbatore International Airport",
      city: "Coimbatore",
      country: "India",
      value: "CJB",
      display: "CJB"
    },
    {
      name: "Cochin International Airport",
      city: "Kochi",
      country: "India",
      value: "COK",
      display: "COK"
    },
    {
      name: "Calicut International Airport",
      city: "Calicut",
      country: "India",
      value: "CCJ",
      display: "CCJ"
    },
    {
      name: "Cuddapah Airport",
      city: "Cuddapah",
      country: "India",
      value: "CDP",
      display: "CDP"
    },
    {
      name: "Begumpet Airport",
      city: "Hyderabad",
      country: "India",
      value: "BPM",
      display: "BPM"
    },
    {
      name: "Madurai Airport",
      city: "Madurai",
      country: "India",
      value: "IXM",
      display: "IXM"
    },
    {
      name: "Mangalore International Airport",
      city: "Mangalore",
      country: "India",
      value: "IXE",
      display: "IXE"
    },
    {
      name: "Chennai International Airport",
      city: "Chennai",
      country: "India",
      value: "MAA",
      display: "MAA"
    },
    {
      name: "Vir Savarkar International Airport",
      city: "Port Blair",
      country: "India",
      value: "IXZ",
      display: "IXZ"
    },
    {
      name: "Pondicherry Airport",
      city: "Pendicherry",
      country: "India",
      value: "PNY",
      display: "PNY"
    },
    {
      name: "Rajahmundry Airport",
      city: "Rajahmundry",
      country: "India",
      value: "RJA",
      display: "RJA"
    },
    {
      name: "Tirupati Airport",
      city: "Tirupeti",
      country: "India",
      value: "TIR",
      display: "TIR"
    },
    {
      name: "Tiruchirapally Civil Airport Airport",
      city: "Tiruchirappalli",
      country: "India",
      value: "TRZ",
      display: "TRZ"
    },
    {
      name: "Trivandrum International Airport",
      city: "Trivandrum",
      country: "India",
      value: "TRV",
      display: "TRV"
    },
    {
      name: "Paro Airport",
      city: "Thimphu",
      country: "Bhutan",
      value: "PBH",
      display: "PBH"
    },
    {
      name: "Male International Airport",
      city: "Male",
      country: "Maldives",
      value: "MLE",
      display: "MLE"
    },
    {
      name: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
      value: "DMK",
      display: "DMK"
    },
    {
      name: "U-Tapao International Airport",
      city: "Pattaya",
      country: "Thailand",
      value: "UTP",
      display: "UTP"
    },
    {
      name: "Lampang Airport",
      city: "Lampang",
      country: "Thailand",
      value: "LPT",
      display: "LPT"
    },
    {
      name: "Phrae Airport",
      city: "Phrae",
      country: "Thailand",
      value: "PRH",
      display: "PRH"
    },
    {
      name: "Hua Hin Airport",
      city: "Prachuap Khiri Khan",
      country: "Thailand",
      value: "HHQ",
      display: "HHQ"
    },
    {
      name: "Phitsanulok Airport",
      city: "Phitsanulok",
      country: "Thailand",
      value: "PHS",
      display: "PHS"
    },
    {
      name: "Narathiwat Airport",
      city: "Narathiwat",
      country: "Thailand",
      value: "NAW",
      display: "NAW"
    },
    {
      name: "Krabi Airport",
      city: "Krabi",
      country: "Thailand",
      value: "KBV",
      display: "KBV"
    },
    {
      name: "Pattani Airport",
      city: "Pattani",
      country: "Thailand",
      value: "PAN",
      display: "PAN"
    },
    {
      name: "Samui Airport",
      city: "Ko Samui",
      country: "Thailand",
      value: "USM",
      display: "USM"
    },
    {
      name: "Phuket International Airport",
      city: "Phuket",
      country: "Thailand",
      value: "HKT",
      display: "HKT"
    },
    {
      name: "Ranong Airport",
      city: "Ranong",
      country: "Thailand",
      value: "UNN",
      display: "UNN"
    },
    {
      name: "Hat Yai International Airport",
      city: "Hat Yai",
      country: "Thailand",
      value: "HDY",
      display: "HDY"
    },
    {
      name: "Trang Airport",
      city: "Trang",
      country: "Thailand",
      value: "TST",
      display: "TST"
    },
    {
      name: "Udon Thani Airport",
      city: "Udon Thani",
      country: "Thailand",
      value: "UTH",
      display: "UTH"
    },
    {
      name: "Sakon Nakhon Airport",
      city: "Sakon Nakhon",
      country: "Thailand",
      value: "SNO",
      display: "SNO"
    },
    {
      name: "Loei Airport",
      city: "Loei",
      country: "Thailand",
      value: "LOE",
      display: "LOE"
    },
    {
      name: "Da Nang International Airport",
      city: "Da Nang",
      country: "Vietnam",
      value: "DAD",
      display: "DAD"
    },
    {
      name: "Noi Bai International Airport",
      city: "Hanoi",
      country: "Vietnam",
      value: "HAN",
      display: "HAN"
    },
    {
      name: "Nha Trang Air Base",
      city: "Nhatrang",
      country: "Vietnam",
      value: "NHA",
      display: "NHA"
    },
    {
      name: "Phu Bai Airport",
      city: "Hue",
      country: "Vietnam",
      value: "HUI",
      display: "HUI"
    },
    {
      name: "Phu Quoc International Airport",
      city: "Phuquoc",
      country: "Vietnam",
      value: "PQC",
      display: "PQC"
    },
    {
      name: "Tan Son Nhat International Airport",
      city: "Ho Chi Minh City",
      country: "Vietnam",
      value: "SGN",
      display: "SGN"
    },
    {
      name: "Bagan Airport",
      city: "Bagan",
      country: "Burma",
      value: "NYU",
      display: "NYU"
    },
    {
      name: "Heho Airport",
      city: "Heho",
      country: "Burma",
      value: "HEH",
      display: "HEH"
    },
    {
      name: "Kengtung Airport",
      city: "Kengtung",
      country: "Burma",
      value: "KET",
      display: "KET"
    },
    {
      name: "Kyaukpyu Airport",
      city: "Kyaukpyu",
      country: "Burma",
      value: "KYP",
      display: "KYP"
    },
    {
      name: "Lashio Airport",
      city: "Lashio",
      country: "Burma",
      value: "LSH",
      display: "LSH"
    },
    {
      name: "Mandalay International Airport",
      city: "Mandalay",
      country: "Burma",
      value: "MDL",
      display: "MDL"
    },
    {
      name: "Myeik Airport",
      city: "Myeik",
      country: "Burma",
      value: "MGZ",
      display: "MGZ"
    },
    {
      name: "Myitkyina Airport",
      city: "Myitkyina",
      country: "Burma",
      value: "MYT",
      display: "MYT"
    },
    {
      name: "Mong Hsat Airport",
      city: "Mong Hsat",
      country: "Burma",
      value: "MOG",
      display: "MOG"
    },
    {
      name: "Putao Airport",
      city: "Putao",
      country: "Burma",
      value: "PBU",
      display: "PBU"
    },
    {
      name: "Sittwe Airport",
      city: "Sittwe",
      country: "Burma",
      value: "AKY",
      display: "AKY"
    },
    {
      name: "Thandwe Airport",
      city: "Thandwe",
      country: "Burma",
      value: "SNW",
      display: "SNW"
    },
    {
      name: "Tachileik Airport",
      city: "Tachilek",
      country: "Burma",
      value: "THL",
      display: "THL"
    },
    {
      name: "Yangon International Airport",
      city: "Yangon",
      country: "Burma",
      value: "RGN",
      display: "RGN"
    },
    {
      name: "Hasanuddin International Airport",
      city: "Ujung Pandang",
      country: "Indonesia",
      value: "UPG",
      display: "UPG"
    },
    {
      name: "Frans Kaisiepo Airport",
      city: "Biak",
      country: "Indonesia",
      value: "BIK",
      display: "BIK"
    },
    {
      name: "Nabire Airport",
      city: "Nabire",
      country: "Indonesia",
      value: "NBX",
      display: "NBX"
    },
    {
      name: "Moses Kilangin Airport",
      city: "Timika",
      country: "Indonesia",
      value: "TIM",
      display: "TIM"
    },
    {
      name: "Sentani Airport",
      city: "Jayapura",
      country: "Indonesia",
      value: "DJJ",
      display: "DJJ"
    },
    {
      name: "Wamena Airport",
      city: "Wamena",
      country: "Indonesia",
      value: "WMX",
      display: "WMX"
    },
    {
      name: "Mopah Airport",
      city: "Merauke",
      country: "Indonesia",
      value: "MKQ",
      display: "MKQ"
    },
    {
      name: "Jalaluddin Airport",
      city: "Gorontalo",
      country: "Indonesia",
      value: "GTO",
      display: "GTO"
    },
    {
      name: "Mutiara Airport",
      city: "Palu",
      country: "Indonesia",
      value: "PLW",
      display: "PLW"
    },
    {
      name: "Sam Ratulangi Airport",
      city: "Manado",
      country: "Indonesia",
      value: "MDC",
      display: "MDC"
    },
    {
      name: "Kasiguncu Airport",
      city: "Poso",
      country: "Indonesia",
      value: "PSJ",
      display: "PSJ"
    },
    {
      name: "Pitu Airport",
      city: "Morotai Island",
      country: "Indonesia",
      value: "OTI",
      display: "OTI"
    },
    {
      name: "Sultan Khairun Babullah Airport",
      city: "Ternate",
      country: "Indonesia",
      value: "TTE",
      display: "TTE"
    },
    {
      name: "Bubung Airport",
      city: "Luwuk",
      country: "Indonesia",
      value: "LUW",
      display: "LUW"
    },
    {
      name: "Pattimura Airport, Ambon",
      city: "Ambon",
      country: "Indonesia",
      value: "AMQ",
      display: "AMQ"
    },
    {
      name: "Fakfak Airport",
      city: "Fak Fak",
      country: "Indonesia",
      value: "FKQ",
      display: "FKQ"
    },
    {
      name: "Kaimana Airport",
      city: "Kaimana",
      country: "Indonesia",
      value: "KNG",
      display: "KNG"
    },
    {
      name: "Babo Airport",
      city: "Babo",
      country: "Indonesia",
      value: "BXB",
      display: "BXB"
    },
    {
      name: "Rendani Airport",
      city: "Manokwari",
      country: "Indonesia",
      value: "MKW",
      display: "MKW"
    },
    {
      name: "Dominique Edward Osok Airport",
      city: "Sorong",
      country: "Indonesia",
      value: "SOQ",
      display: "SOQ"
    },
    {
      name: "Bintulu Airport",
      city: "Bintulu",
      country: "Malaysia",
      value: "BTU",
      display: "BTU"
    },
    {
      name: "Kuching International Airport",
      city: "Kuching",
      country: "Malaysia",
      value: "KCH",
      display: "KCH"
    },
    {
      name: "Limbang Airport",
      city: "Limbang",
      country: "Malaysia",
      value: "LMN",
      display: "LMN"
    },
    {
      name: "Marudi Airport",
      city: "Marudi",
      country: "Malaysia",
      value: "MUR",
      display: "MUR"
    },
    {
      name: "Miri Airport",
      city: "Miri",
      country: "Malaysia",
      value: "MYY",
      display: "MYY"
    },
    {
      name: "Sibu Airport",
      city: "Sibu",
      country: "Malaysia",
      value: "SBW",
      display: "SBW"
    },
    {
      name: "Lahad Datu Airport",
      city: "Lahad Datu",
      country: "Malaysia",
      value: "LDU",
      display: "LDU"
    },
    {
      name: "Kota Kinabalu International Airport",
      city: "Kota Kinabalu",
      country: "Malaysia",
      value: "BKI",
      display: "BKI"
    },
    {
      name: "Labuan Airport",
      city: "Labuan",
      country: "Malaysia",
      value: "LBU",
      display: "LBU"
    },
    {
      name: "Tawau Airport",
      city: "Tawau",
      country: "Malaysia",
      value: "TWU",
      display: "TWU"
    },
    {
      name: "Brunei International Airport",
      city: "Bandar Seri Begawan",
      country: "Brunei",
      value: "BWN",
      display: "BWN"
    },
    {
      name: "Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
      city: "Pekanbaru",
      country: "Indonesia",
      value: "PKU",
      display: "PKU"
    },
    {
      name: "Pinang Kampai Airport",
      city: "Dumai",
      country: "Indonesia",
      value: "DUM",
      display: "DUM"
    },
    {
      name: "Soekarno-Hatta International Airport",
      city: "Jakarta",
      country: "Indonesia",
      value: "CGK",
      display: "CGK"
    },
    {
      name: "Binaka Airport",
      city: "Gunung Sitoli",
      country: "Indonesia",
      value: "GNS",
      display: "GNS"
    },
    {
      name: "Soewondo Air Force Base",
      city: "Medan",
      country: "Indonesia",
      value: "MES",
      display: "MES"
    },
    {
      name: "Ketapang(Rahadi Usman) Airport",
      city: "Ketapang",
      country: "Indonesia",
      value: "KTG",
      display: "KTG"
    },
    {
      name: "Supadio Airport",
      city: "Pontianak",
      country: "Indonesia",
      value: "PNK",
      display: "PNK"
    },
    {
      name: "Sultan Thaha Airport",
      city: "Jambi",
      country: "Indonesia",
      value: "DJB",
      display: "DJB"
    },
    {
      name: "Fatmawati Soekarno Airport",
      city: "Bengkulu",
      country: "Indonesia",
      value: "BKS",
      display: "BKS"
    },
    {
      name: "Sultan Mahmud Badaruddin II Airport",
      city: "Palembang",
      country: "Indonesia",
      value: "PLM",
      display: "PLM"
    },
    {
      name: "Japura Airport",
      city: "Rengat",
      country: "Indonesia",
      value: "RGT",
      display: "RGT"
    },
    {
      name: "Sultan Iskandar Muda International Airport",
      city: "Banda Aceh",
      country: "Indonesia",
      value: "BTJ",
      display: "BTJ"
    },
    {
      name: "Sultan Abdul Halim Airport",
      city: "Alor Setar",
      country: "Malaysia",
      value: "AOR",
      display: "AOR"
    },
    {
      name: "Sultan Ismail Petra Airport",
      city: "Kota Bahru",
      country: "Malaysia",
      value: "KBR",
      display: "KBR"
    },
    {
      name: "Kuantan Airport",
      city: "Kuantan",
      country: "Malaysia",
      value: "KUA",
      display: "KUA"
    },
    {
      name: "Kerteh Airport",
      city: "Kerteh",
      country: "Malaysia",
      value: "KTE",
      display: "KTE"
    },
    {
      name: "Sultan Azlan Shah Airport",
      city: "Ipoh",
      country: "Malaysia",
      value: "IPH",
      display: "IPH"
    },
    {
      name: "Senai International Airport",
      city: "Johor Bahru",
      country: "Malaysia",
      value: "JHB",
      display: "JHB"
    },
    {
      name: "Kuala Lumpur International Airport",
      city: "Kuala Lumpur",
      country: "Malaysia",
      value: "KUL",
      display: "KUL"
    },
    {
      name: "Langkawi International Airport",
      city: "Langkawi",
      country: "Malaysia",
      value: "LGK",
      display: "LGK"
    },
    {
      name: "Malacca Airport",
      city: "Malacca",
      country: "Malaysia",
      value: "MKZ",
      display: "MKZ"
    },
    {
      name: "Sultan Mahmud Airport",
      city: "Kuala Terengganu",
      country: "Malaysia",
      value: "TGG",
      display: "TGG"
    },
    {
      name: "Penang International Airport",
      city: "Penang",
      country: "Malaysia",
      value: "PEN",
      display: "PEN"
    },
    {
      name: "Presidente Nicolau Lobato International Airport",
      city: "Dili",
      country: "East Timor",
      value: "DIL",
      display: "DIL"
    },
    {
      name: "Paya Lebar Air Base",
      city: "Paya Lebar",
      country: "Singapore",
      value: "QPG",
      display: "QPG"
    },
    {
      name: "Seletar Airport",
      city: "Singapore",
      country: "Singapore",
      value: "XSP",
      display: "XSP"
    },
    {
      name: "Singapore Changi Airport",
      city: "Singapore",
      country: "Singapore",
      value: "SIN",
      display: "SIN"
    },
    {
      name: "Northern Peninsula Airport",
      city: "Amberley",
      country: "Australia",
      value: "ABM",
      display: "ABM"
    },
    {
      name: "Alice Springs Airport",
      city: "Alice Springs",
      country: "Australia",
      value: "ASP",
      display: "ASP"
    },
    {
      name: "Brisbane International Airport",
      city: "Brisbane",
      country: "Australia",
      value: "BNE",
      display: "BNE"
    },
    {
      name: "Gold Coast Coolangatta Airport",
      city: "Gold Coast",
      country: "Australia",
      value: "OOL",
      display: "OOL"
    },
    {
      name: "Cairns International Airport",
      city: "Cairns",
      country: "Australia",
      value: "CNS",
      display: "CNS"
    },
    {
      name: "Charleville Airport",
      city: "Charlieville",
      country: "Australia",
      value: "CTL",
      display: "CTL"
    },
    {
      name: "Mount Isa Airport",
      city: "Mount Isa",
      country: "Australia",
      value: "ISA",
      display: "ISA"
    },
    {
      name: "Sunshine Coast Airport",
      city: "Maroochydore",
      country: "Australia",
      value: "MCY",
      display: "MCY"
    },
    {
      name: "Mackay Airport",
      city: "Mackay",
      country: "Australia",
      value: "MKY",
      display: "MKY"
    },
    {
      name: "Proserpine Whitsunday Coast Airport",
      city: "Prosserpine",
      country: "Australia",
      value: "PPP",
      display: "PPP"
    },
    {
      name: "Rockhampton Airport",
      city: "Rockhampton",
      country: "Australia",
      value: "ROK",
      display: "ROK"
    },
    {
      name: "Townsville Airport",
      city: "Townsville",
      country: "Australia",
      value: "TSV",
      display: "TSV"
    },
    {
      name: "Weipa Airport",
      city: "Weipa",
      country: "Australia",
      value: "WEI",
      display: "WEI"
    },
    {
      name: "Avalon Airport",
      city: "Avalon",
      country: "Australia",
      value: "AVV",
      display: "AVV"
    },
    {
      name: "Albury Airport",
      city: "Albury",
      country: "Australia",
      value: "ABX",
      display: "ABX"
    },
    {
      name: "Melbourne Essendon Airport",
      city: "Melbourne",
      country: "Australia",
      value: "MEB",
      display: "MEB"
    },
    {
      name: "Hobart International Airport",
      city: "Hobart",
      country: "Australia",
      value: "HBA",
      display: "HBA"
    },
    {
      name: "Launceston Airport",
      city: "Launceston",
      country: "Australia",
      value: "LST",
      display: "LST"
    },
    {
      name: "Melbourne Moorabbin Airport",
      city: "Melbourne",
      country: "Australia",
      value: "MBW",
      display: "MBW"
    },
    {
      name: "Melbourne International Airport",
      city: "Melbourne",
      country: "Australia",
      value: "MEL",
      display: "MEL"
    },
    {
      name: "Adelaide International Airport",
      city: "Adelaide",
      country: "Australia",
      value: "ADL",
      display: "ADL"
    },
    {
      name: "Perth Jandakot Airport",
      city: "Perth",
      country: "Australia",
      value: "JAD",
      display: "JAD"
    },
    {
      name: "Karratha Airport",
      city: "Karratha",
      country: "Australia",
      value: "KTA",
      display: "KTA"
    },
    {
      name: "Kalgoorlie Boulder Airport",
      city: "Kalgoorlie",
      country: "Australia",
      value: "KGI",
      display: "KGI"
    },
    {
      name: "Kununurra Airport",
      city: "Kununurra",
      country: "Australia",
      value: "KNX",
      display: "KNX"
    },
    {
      name: "Learmonth Airport",
      city: "Learmonth",
      country: "Australia",
      value: "LEA",
      display: "LEA"
    },
    {
      name: "Port Hedland International Airport",
      city: "Port Hedland",
      country: "Australia",
      value: "PHE",
      display: "PHE"
    },
    {
      name: "Perth International Airport",
      city: "Perth",
      country: "Australia",
      value: "PER",
      display: "PER"
    },
    {
      name: "Woomera Airfield",
      city: "Woomera",
      country: "Australia",
      value: "UMR",
      display: "UMR"
    },
    {
      name: "Christmas Island Airport",
      city: "Christmas Island",
      country: "Christmas Island",
      value: "XCH",
      display: "XCH"
    },
    {
      name: "Sydney Bankstown Airport",
      city: "Sydney",
      country: "Australia",
      value: "BWU",
      display: "BWU"
    },
    {
      name: "Canberra International Airport",
      city: "Canberra",
      country: "Australia",
      value: "CBR",
      display: "CBR"
    },
    {
      name: "Coffs Harbour Airport",
      city: "Coff s Harbour",
      country: "Australia",
      value: "CFS",
      display: "CFS"
    },
    {
      name: "Camden Airport",
      city: "Camden",
      country: "Australia",
      value: "CDU",
      display: "CDU"
    },
    {
      name: "Dubbo City Regional Airport",
      city: "Dubbo",
      country: "Australia",
      value: "DBO",
      display: "DBO"
    },
    {
      name: "Norfolk Island International Airport",
      city: "Norfolk Island",
      country: "Norfolk Island",
      value: "NLK",
      display: "NLK"
    },
    {
      name: "Sydney Kingsford Smith International Airport",
      city: "Sydney",
      country: "Australia",
      value: "SYD",
      display: "SYD"
    },
    {
      name: "Tamworth Airport",
      city: "Tamworth",
      country: "Australia",
      value: "TMW",
      display: "TMW"
    },
    {
      name: "Wagga Wagga City Airport",
      city: "Wagga Wagga",
      country: "Australia",
      value: "WGA",
      display: "WGA"
    },
    {
      name: "Beijing Capital International Airport",
      city: "Beijing",
      country: "China",
      value: "PEK",
      display: "PEK"
    },
    {
      name: "Dongshan Airport",
      city: "Hailar",
      country: "China",
      value: "HLD",
      display: "HLD"
    },
    {
      name: "Tianjin Binhai International Airport",
      city: "Tianjin",
      country: "China",
      value: "TSN",
      display: "TSN"
    },
    {
      name: "Taiyuan Wusu Airport",
      city: "Taiyuan",
      country: "China",
      value: "TYN",
      display: "TYN"
    },
    {
      name: "Guangzhou Baiyun International Airport",
      city: "Guangzhou",
      country: "China",
      value: "CAN",
      display: "CAN"
    },
    {
      name: "Changsha Huanghua International Airport",
      city: "Changcha",
      country: "China",
      value: "CSX",
      display: "CSX"
    },
    {
      name: "Guilin Liangjiang International Airport",
      city: "Guilin",
      country: "China",
      value: "KWL",
      display: "KWL"
    },
    {
      name: "Nanning Wuxu Airport",
      city: "Nanning",
      country: "China",
      value: "NNG",
      display: "NNG"
    },
    {
      name: "Shenzhen Bao'an International Airport",
      city: "Shenzhen",
      country: "China",
      value: "SZX",
      display: "SZX"
    },
    {
      name: "Zhengzhou Xinzheng International Airport",
      city: "Zhengzhou",
      country: "China",
      value: "CGO",
      display: "CGO"
    },
    {
      name: "Wuhan Tianhe International Airport",
      city: "Wuhan",
      country: "China",
      value: "WUH",
      display: "WUH"
    },
    {
      name: "Pyongyang Sunan International Airport",
      city: "Pyongyang",
      country: "North Korea",
      value: "FNJ",
      display: "FNJ"
    },
    {
      name: "Lanzhou Zhongchuan Airport",
      city: "Lanzhou",
      country: "China",
      value: "LHW",
      display: "LHW"
    },
    {
      name: "Xi'an Xianyang International Airport",
      city: "Xi an",
      country: "China",
      value: "XIY",
      display: "XIY"
    },
    {
      name: "Chinggis Khaan International Airport",
      city: "Ulan Bator",
      country: "Mongolia",
      value: "ULN",
      display: "ULN"
    },
    {
      name: "Xishuangbanna Gasa Airport",
      city: "Jinghonggasa",
      country: "China",
      value: "JHG",
      display: "JHG"
    },
    {
      name: "Kunming Changshui International Airport",
      city: "Kunming",
      country: "China",
      value: "KMG",
      display: "KMG"
    },
    {
      name: "Xiamen Gaoqi International Airport",
      city: "Xiamen",
      country: "China",
      value: "XMN",
      display: "XMN"
    },
    {
      name: "Nanchang Changbei International Airport",
      city: "Nanchang",
      country: "China",
      value: "KHN",
      display: "KHN"
    },
    {
      name: "Fuzhou Changle International Airport",
      city: "Fuzhou",
      country: "China",
      value: "FOC",
      display: "FOC"
    },
    {
      name: "Hangzhou Xiaoshan International Airport",
      city: "Hangzhou",
      country: "China",
      value: "HGH",
      display: "HGH"
    },
    {
      name: "Ningbo Lishe International Airport",
      city: "Ninbo",
      country: "China",
      value: "NGB",
      display: "NGB"
    },
    {
      name: "Nanjing Lukou Airport",
      city: "Nanjing",
      country: "China",
      value: "NKG",
      display: "NKG"
    },
    {
      name: "Hefei Luogang International Airport",
      city: "Hefei",
      country: "China",
      value: "HFE",
      display: "HFE"
    },
    {
      name: "Liuting Airport",
      city: "Qingdao",
      country: "China",
      value: "TAO",
      display: "TAO"
    },
    {
      name: "Shanghai Hongqiao International Airport",
      city: "Shanghai",
      country: "China",
      value: "SHA",
      display: "SHA"
    },
    {
      name: "Yantai Laishan Airport",
      city: "Yantai",
      country: "China",
      value: "YNT",
      display: "YNT"
    },
    {
      name: "Chongqing Jiangbei International Airport",
      city: "Chongqing",
      country: "China",
      value: "CKG",
      display: "CKG"
    },
    {
      name: "Longdongbao Airport",
      city: "Guiyang",
      country: "China",
      value: "KWE",
      display: "KWE"
    },
    {
      name: "Chengdu Shuangliu International Airport",
      city: "Chengdu",
      country: "China",
      value: "CTU",
      display: "CTU"
    },
    {
      name: "Xichang Qingshan Airport",
      city: "Xichang",
      country: "China",
      value: "XIC",
      display: "XIC"
    },
    {
      name: "Kashgar Airport",
      city: "Kashi",
      country: "China",
      value: "KHG",
      display: "KHG"
    },
    {
      name: "Hotan Airport",
      city: "Hotan",
      country: "China",
      value: "HTN",
      display: "HTN"
    },
    {
      name: "Urumqi Diwopu International Airport",
      city: "Urumqi",
      country: "China",
      value: "URC",
      display: "URC"
    },
    {
      name: "Taiping Airport",
      city: "Harbin",
      country: "China",
      value: "HRB",
      display: "HRB"
    },
    {
      name: "Mudanjiang Hailang International Airport",
      city: "Mudanjiang",
      country: "China",
      value: "MDG",
      display: "MDG"
    },
    {
      name: "Zhoushuizi Airport",
      city: "Dalian",
      country: "China",
      value: "DLC",
      display: "DLC"
    },
    {
      name: "Shanghai Pudong International Airport",
      city: "Shanghai",
      country: "China",
      value: "PVG",
      display: "PVG"
    },
    {
      name: "Pulau Tioman Airport",
      city: "Tioman",
      country: "Malaysia",
      value: "TOD",
      display: "TOD"
    },
    {
      name: "Sultan Abdul Aziz Shah International Airport",
      city: "Kuala Lumpur",
      country: "Malaysia",
      value: "SZB",
      display: "SZB"
    },
    {
      name: "Noto Airport",
      city: "Wajima",
      country: "Japan",
      value: "NTQ",
      display: "NTQ"
    },
    {
      name: "Borg El Arab International Airport",
      city: "Alexandria",
      country: "Egypt",
      value: "HBE",
      display: "HBE"
    },
    {
      name: "Barter Island LRRS Airport",
      city: "Barter Island",
      country: "United States",
      value: "BTI",
      display: "BTI"
    },
    {
      name: "Cape Lisburne LRRS Airport",
      city: "Cape Lisburne",
      country: "United States",
      value: "LUR",
      display: "LUR"
    },
    {
      name: "Point Lay LRRS Airport",
      city: "Point Lay",
      country: "United States",
      value: "PIZ",
      display: "PIZ"
    },
    {
      name: "Hilo International Airport",
      city: "Hilo",
      country: "United States",
      value: "ITO",
      display: "ITO"
    },
    {
      name: "Orlando Executive Airport",
      city: "Orlando",
      country: "United States",
      value: "ORL",
      display: "ORL"
    },
    {
      name: "Bettles Airport",
      city: "Bettles",
      country: "United States",
      value: "BTT",
      display: "BTT"
    },
    {
      name: "Clear Airport",
      city: "Clear Mews",
      country: "United States",
      value: "Z84",
      display: "Z84"
    },
    {
      name: "Indian Mountain LRRS Airport",
      city: "Indian Mountains",
      country: "United States",
      value: "UTO",
      display: "UTO"
    },
    {
      name: "Fort Yukon Airport",
      city: "Fort Yukon",
      country: "United States",
      value: "FYU",
      display: "FYU"
    },
    {
      name: "Sparrevohn LRRS Airport",
      city: "Sparrevohn",
      country: "United States",
      value: "SVW",
      display: "SVW"
    },
    {
      name: "Bryant Army Heliport",
      city: "Fort Richardson",
      country: "United States",
      value: "FRN",
      display: "FRN"
    },
    {
      name: "Tatalina LRRS Airport",
      city: "Tatalina",
      country: "United States",
      value: "TLJ",
      display: "TLJ"
    },
    {
      name: "Cape Romanzof LRRS Airport",
      city: "Cape Romanzof",
      country: "United States",
      value: "CZF",
      display: "CZF"
    },
    {
      name: "Laurence G Hanscom Field",
      city: "Bedford",
      country: "United States",
      value: "BED",
      display: "BED"
    },
    {
      name: "St Paul Island Airport",
      city: "St. Paul Island",
      country: "United States",
      value: "SNP",
      display: "SNP"
    },
    {
      name: "Cape Newenham LRRS Airport",
      city: "Cape Newenham",
      country: "United States",
      value: "EHM",
      display: "EHM"
    },
    {
      name: "St George Airport",
      city: "Point Barrow",
      country: "United States",
      value: "STG",
      display: "STG"
    },
    {
      name: "Iliamna Airport",
      city: "Iliamna",
      country: "United States",
      value: "ILI",
      display: "ILI"
    },
    {
      name: "Platinum Airport",
      city: "Port Moller",
      country: "United States",
      value: "PTU",
      display: "PTU"
    },
    {
      name: "Big Mountain Airport",
      city: "Big Mountain",
      country: "United States",
      value: "BMX",
      display: "BMX"
    },
    {
      name: "Oscoda Wurtsmith Airport",
      city: "Oscoda",
      country: "United States",
      value: "OSC",
      display: "OSC"
    },
    {
      name: "Marina Municipal Airport",
      city: "Fort Ord",
      country: "United States",
      value: "OAR",
      display: "OAR"
    },
    {
      name: "Sacramento Mather Airport",
      city: "Sacramento",
      country: "United States",
      value: "MHR",
      display: "MHR"
    },
    {
      name: "Bicycle Lake Army Air Field",
      city: "Fort Irwin",
      country: "United States",
      value: "BYS",
      display: "BYS"
    },
    {
      name: "Twentynine Palms (Self) Airport",
      city: "Twenty Nine Palms",
      country: "United States",
      value: "NXP",
      display: "NXP"
    },
    {
      name: "Fort Smith Regional Airport",
      city: "Fort Smith",
      country: "United States",
      value: "FSM",
      display: "FSM"
    },
    {
      name: "Merrill Field",
      city: "Anchorage",
      country: "United States",
      value: "MRI",
      display: "MRI"
    },
    {
      name: "Grants-Milan Municipal Airport",
      city: "Grants",
      country: "United States",
      value: "GNT",
      display: "GNT"
    },
    {
      name: "Ponca City Regional Airport",
      city: "Ponca City",
      country: "United States",
      value: "PNC",
      display: "PNC"
    },
    {
      name: "Hunter Army Air Field",
      city: "Hunter Aaf",
      country: "United States",
      value: "SVN",
      display: "SVN"
    },
    {
      name: "Grand Forks International Airport",
      city: "Grand Forks",
      country: "United States",
      value: "GFK",
      display: "GFK"
    },
    {
      name: "Grider Field",
      city: "Pine Bluff",
      country: "United States",
      value: "PBF",
      display: "PBF"
    },
    {
      name: "Whiting Field Naval Air Station - North",
      city: "Milton",
      country: "United States",
      value: "NSE",
      display: "NSE"
    },
    {
      name: "Hana Airport",
      city: "Hana",
      country: "United States",
      value: "HNM",
      display: "HNM"
    },
    {
      name: "Ernest A. Love Field",
      city: "Prescott",
      country: "United States",
      value: "PRC",
      display: "PRC"
    },
    {
      name: "Trenton Mercer Airport",
      city: "Trenton",
      country: "United States",
      value: "TTN",
      display: "TTN"
    },
    {
      name: "General Edward Lawrence Logan International Airport",
      city: "Boston",
      country: "United States",
      value: "BOS",
      display: "BOS"
    },
    {
      name: "Travis Air Force Base",
      city: "Fairfield",
      country: "United States",
      value: "SUU",
      display: "SUU"
    },
    {
      name: "Griffiss International Airport",
      city: "Rome",
      country: "United States",
      value: "RME",
      display: "RME"
    },
    {
      name: "Wendover Airport",
      city: "Wendover",
      country: "United States",
      value: "ENV",
      display: "ENV"
    },
    {
      name: "Mobile Downtown Airport",
      city: "Mobile",
      country: "United States",
      value: "BFM",
      display: "BFM"
    },
    {
      name: "Metropolitan Oakland International Airport",
      city: "Oakland",
      country: "United States",
      value: "OAK",
      display: "OAK"
    },
    {
      name: "Eppley Airfield",
      city: "Omaha",
      country: "United States",
      value: "OMA",
      display: "OMA"
    },
    {
      name: "Port Angeles Cgas Airport",
      city: "Port Angeles",
      country: "United States",
      value: "NOW",
      display: "NOW"
    },
    {
      name: "Kahului Airport",
      city: "Kahului",
      country: "United States",
      value: "OGG",
      display: "OGG"
    },
    {
      name: "Wichita Mid Continent Airport",
      city: "Wichita",
      country: "United States",
      value: "ICT",
      display: "ICT"
    },
    {
      name: "Kansas City International Airport",
      city: "Kansas City",
      country: "United States",
      value: "MCI",
      display: "MCI"
    },
    {
      name: "Dane County Regional Truax Field",
      city: "Madison",
      country: "United States",
      value: "MSN",
      display: "MSN"
    },
    {
      name: "Dillingham Airport",
      city: "Dillingham",
      country: "United States",
      value: "DLG",
      display: "DLG"
    },
    {
      name: "Boone County Airport",
      city: "Harrison",
      country: "United States",
      value: "HRO",
      display: "HRO"
    },
    {
      name: "Phoenix Sky Harbor International Airport",
      city: "Phoenix",
      country: "United States",
      value: "PHX",
      display: "PHX"
    },
    {
      name: "Bangor International Airport",
      city: "Bangor",
      country: "United States",
      value: "BGR",
      display: "BGR"
    },
    {
      name: "Fort Lauderdale Executive Airport",
      city: "Fort Lauderdale",
      country: "United States",
      value: "FXE",
      display: "FXE"
    },
    {
      name: "East Texas Regional Airport",
      city: "Longview",
      country: "United States",
      value: "GGG",
      display: "GGG"
    },
    {
      name: "Anderson Regional Airport",
      city: "Andersen",
      country: "United States",
      value: "AND",
      display: "AND"
    },
    {
      name: "Spokane International Airport",
      city: "Spokane",
      country: "United States",
      value: "GEG",
      display: "GEG"
    },
    {
      name: "North Perry Airport",
      city: "Hollywood",
      country: "United States",
      value: "HWO",
      display: "HWO"
    },
    {
      name: "San Francisco International Airport",
      city: "San Francisco",
      country: "United States",
      value: "SFO",
      display: "SFO"
    },
    {
      name: "Cut Bank International Airport",
      city: "Cutbank",
      country: "United States",
      value: "CTB",
      display: "CTB"
    },
    {
      name: "Acadiana Regional Airport",
      city: "Louisiana",
      country: "United States",
      value: "ARA",
      display: "ARA"
    },
    {
      name: "Gainesville Regional Airport",
      city: "Gainesville",
      country: "United States",
      value: "GNV",
      display: "GNV"
    },
    {
      name: "Memphis International Airport",
      city: "Memphis",
      country: "United States",
      value: "MEM",
      display: "MEM"
    },
    {
      name: "Bisbee Douglas International Airport",
      city: "Douglas",
      country: "United States",
      value: "DUG",
      display: "DUG"
    },
    {
      name: "Allen Army Airfield",
      city: "Delta Junction",
      country: "United States",
      value: "BIG",
      display: "BIG"
    },
    {
      name: "TSTC Waco Airport",
      city: "Waco",
      country: "United States",
      value: "CNW",
      display: "CNW"
    },
    {
      name: "Annette Island Airport",
      city: "Annette Island",
      country: "United States",
      value: "ANN",
      display: "ANN"
    },
    {
      name: "Caribou Municipal Airport",
      city: "Caribou",
      country: "United States",
      value: "CAR",
      display: "CAR"
    },
    {
      name: "Little Rock Air Force Base",
      city: "Jacksonville",
      country: "United States",
      value: "LRF",
      display: "LRF"
    },
    {
      name: "Redstone Army Air Field",
      city: "Redstone",
      country: "United States",
      value: "HUA",
      display: "HUA"
    },
    {
      name: "Pope Field",
      city: "Fort Bragg",
      country: "United States",
      value: "POB",
      display: "POB"
    },
    {
      name: "Dalhart Municipal Airport",
      city: "Dalhart",
      country: "United States",
      value: "DHT",
      display: "DHT"
    },
    {
      name: "Laughlin Air Force Base",
      city: "Del Rio",
      country: "United States",
      value: "DLF",
      display: "DLF"
    },
    {
      name: "Los Angeles International Airport",
      city: "Los Angeles",
      country: "United States",
      value: "LAX",
      display: "LAX"
    },
    {
      name: "Anniston Metropolitan Airport",
      city: "Anniston",
      country: "United States",
      value: "ANB",
      display: "ANB"
    },
    {
      name: "Cleveland Hopkins International Airport",
      city: "Cleveland",
      country: "United States",
      value: "CLE",
      display: "CLE"
    },
    {
      name: "Dover Air Force Base",
      city: "Dover",
      country: "United States",
      value: "DOV",
      display: "DOV"
    },
    {
      name: "Cincinnati Northern Kentucky International Airport",
      city: "Cincinnati",
      country: "United States",
      value: "CVG",
      display: "CVG"
    },
    {
      name: "Tipton Airport",
      city: "Fort Meade",
      country: "United States",
      value: "FME",
      display: "FME"
    },
    {
      name: "China Lake Naws (Armitage Field) Airport",
      city: "China Lake",
      country: "United States",
      value: "NID",
      display: "NID"
    },
    {
      name: "Huron Regional Airport",
      city: "Huron",
      country: "United States",
      value: "HON",
      display: "HON"
    },
    {
      name: "Juneau International Airport",
      city: "Juneau",
      country: "United States",
      value: "JNU",
      display: "JNU"
    },
    {
      name: "Lafayette Regional Airport",
      city: "Lafayette",
      country: "United States",
      value: "LFT",
      display: "LFT"
    },
    {
      name: "Newark Liberty International Airport",
      city: "Newark",
      country: "United States",
      value: "EWR",
      display: "EWR"
    },
    {
      name: "Boise Air Terminal/Gowen field",
      city: "Boise",
      country: "United States",
      value: "BOI",
      display: "BOI"
    },
    {
      name: "Creech Air Force Base",
      city: "Indian Springs",
      country: "United States",
      value: "INS",
      display: "INS"
    },
    {
      name: "Garden City Regional Airport",
      city: "Garden City",
      country: "United States",
      value: "GCK",
      display: "GCK"
    },
    {
      name: "Minot International Airport",
      city: "Minot",
      country: "United States",
      value: "MOT",
      display: "MOT"
    },
    {
      name: "Wheeler Army Airfield",
      city: "Wahiawa",
      country: "United States",
      value: "HHI",
      display: "HHI"
    },
    {
      name: "Maxwell Air Force Base",
      city: "Montgomery",
      country: "United States",
      value: "MXF",
      display: "MXF"
    },
    {
      name: "Dallas Love Field",
      city: "Dallas",
      country: "United States",
      value: "DAL",
      display: "DAL"
    },
    {
      name: "Butts AAF (Fort Carson) Air Field",
      city: "Fort Carson",
      country: "United States",
      value: "FCS",
      display: "FCS"
    },
    {
      name: "Helena Regional Airport",
      city: "Helena",
      country: "United States",
      value: "HLN",
      display: "HLN"
    },
    {
      name: "Miramar Marine Corps Air Station - Mitscher Field",
      city: "Miramar",
      country: "United States",
      value: "NKX",
      display: "NKX"
    },
    {
      name: "Luke Air Force Base",
      city: "Phoenix",
      country: "United States",
      value: "LUF",
      display: "LUF"
    },
    {
      name: "Jack Northrop Field Hawthorne Municipal Airport",
      city: "Hawthorne",
      country: "United States",
      value: "HHR",
      display: "HHR"
    },
    {
      name: "Houlton International Airport",
      city: "Houlton",
      country: "United States",
      value: "HUL",
      display: "HUL"
    },
    {
      name: "Vance Air Force Base",
      city: "Enid",
      country: "United States",
      value: "END",
      display: "END"
    },
    {
      name: "Point Mugu Naval Air Station (Naval Base Ventura Co)",
      city: "Point Mugu",
      country: "United States",
      value: "NTD",
      display: "NTD"
    },
    {
      name: "Edwards Air Force Base",
      city: "Edwards Afb",
      country: "United States",
      value: "EDW",
      display: "EDW"
    },
    {
      name: "Lake Charles Regional Airport",
      city: "Lake Charles",
      country: "United States",
      value: "LCH",
      display: "LCH"
    },
    {
      name: "Kona International At Keahole Airport",
      city: "Kona",
      country: "United States",
      value: "KOA",
      display: "KOA"
    },
    {
      name: "Myrtle Beach International Airport",
      city: "Myrtle Beach",
      country: "United States",
      value: "MYR",
      display: "MYR"
    },
    {
      name: "Lemoore Naval Air Station (Reeves Field) Airport",
      city: "Lemoore",
      country: "United States",
      value: "NLC",
      display: "NLC"
    },
    {
      name: "Nantucket Memorial Airport",
      city: "Nantucket",
      country: "United States",
      value: "ACK",
      display: "ACK"
    },
    {
      name: "Felker Army Air Field",
      city: "Fort Eustis",
      country: "United States",
      value: "FAF",
      display: "FAF"
    },
    {
      name: "Campbell AAF (Fort Campbell) Air Field",
      city: "Hopkinsville",
      country: "United States",
      value: "HOP",
      display: "HOP"
    },
    {
      name: "Ronald Reagan Washington National Airport",
      city: "Washington",
      country: "United States",
      value: "DCA",
      display: "DCA"
    },
    {
      name: "Patuxent River Naval Air Station/Trapnell Field Aiport",
      city: "Patuxent River",
      country: "United States",
      value: "NHK",
      display: "NHK"
    },
    {
      name: "Palacios Municipal Airport",
      city: "Palacios",
      country: "United States",
      value: "PSX",
      display: "PSX"
    },
    {
      name: "Arkansas International Airport",
      city: "Blytheville",
      country: "United States",
      value: "BYH",
      display: "BYH"
    },
    {
      name: "Atlantic City International Airport",
      city: "Atlantic City",
      country: "United States",
      value: "ACY",
      display: "ACY"
    },
    {
      name: "Tinker Air Force Base",
      city: "Oklahoma City",
      country: "United States",
      value: "TIK",
      display: "TIK"
    },
    {
      name: "Pueblo Memorial Airport",
      city: "Pueblo",
      country: "United States",
      value: "PUB",
      display: "PUB"
    },
    {
      name: "Northern Maine Regional Airport at Presque Isle",
      city: "Presque Isle",
      country: "United States",
      value: "PQI",
      display: "PQI"
    },
    {
      name: "Gray Army Air Field",
      city: "Fort Lewis",
      country: "United States",
      value: "GRF",
      display: "GRF"
    },
    {
      name: "Kodiak Airport",
      city: "Kodiak",
      country: "United States",
      value: "ADQ",
      display: "ADQ"
    },
    {
      name: "Upolu Airport",
      city: "Opolu",
      country: "United States",
      value: "UPP",
      display: "UPP"
    },
    {
      name: "Fort Lauderdale Hollywood International Airport",
      city: "Fort Lauderdale",
      country: "United States",
      value: "FLL",
      display: "FLL"
    },
    {
      name: "Davis Field",
      city: "Muskogee",
      country: "United States",
      value: "MKO",
      display: "MKO"
    },
    {
      name: "Falls International Airport",
      city: "International Falls",
      country: "United States",
      value: "INL",
      display: "INL"
    },
    {
      name: "Salt Lake City International Airport",
      city: "Salt Lake City",
      country: "United States",
      value: "SLC",
      display: "SLC"
    },
    {
      name: "Childress Municipal Airport",
      city: "Childress",
      country: "United States",
      value: "CDS",
      display: "CDS"
    },
    {
      name: "Keesler Air Force Base",
      city: "Biloxi",
      country: "United States",
      value: "BIX",
      display: "BIX"
    },
    {
      name: "Lawson Army Air Field (Fort Benning)",
      city: "Fort Benning",
      country: "United States",
      value: "LSF",
      display: "LSF"
    },
    {
      name: "Marshall Army Air Field",
      city: "Fort Riley",
      country: "United States",
      value: "FRI",
      display: "FRI"
    },
    {
      name: "Harrisburg International Airport",
      city: "Harrisburg",
      country: "United States",
      value: "MDT",
      display: "MDT"
    },
    {
      name: "Lincoln Airport",
      city: "Lincoln",
      country: "United States",
      value: "LNK",
      display: "LNK"
    },
    {
      name: "Capital City Airport",
      city: "Lansing",
      country: "United States",
      value: "LAN",
      display: "LAN"
    },
    {
      name: "Waimea Kohala Airport",
      city: "Kamuela",
      country: "United States",
      value: "MUE",
      display: "MUE"
    },
    {
      name: "Massena International Richards Field",
      city: "Massena",
      country: "United States",
      value: "MSS",
      display: "MSS"
    },
    {
      name: "Hickory Regional Airport",
      city: "Hickory",
      country: "United States",
      value: "HKY",
      display: "HKY"
    },
    {
      name: "Albert Whitted Airport",
      city: "St. Petersburg",
      country: "United States",
      value: "SPG",
      display: "SPG"
    },
    {
      name: "Page Field",
      city: "Fort Myers",
      country: "United States",
      value: "FMY",
      display: "FMY"
    },
    {
      name: "George Bush Intercontinental Houston Airport",
      city: "Houston",
      country: "United States",
      value: "IAH",
      display: "IAH"
    },
    {
      name: "Millinocket Municipal Airport",
      city: "Millinocket",
      country: "United States",
      value: "MLT",
      display: "MLT"
    },
    {
      name: "Andrews Air Force Base",
      city: "Camp Springs",
      country: "United States",
      value: "ADW",
      display: "ADW"
    },
    {
      name: "Smith Reynolds Airport",
      city: "Winston-salem",
      country: "United States",
      value: "INT",
      display: "INT"
    },
    {
      name: "Southern California Logistics Airport",
      city: "Victorville",
      country: "United States",
      value: "VCV",
      display: "VCV"
    },
    {
      name: "Bob Sikes Airport",
      city: "Crestview",
      country: "United States",
      value: "CEW",
      display: "CEW"
    },
    {
      name: "Wheeler Sack Army Air Field",
      city: "Fort Drum",
      country: "United States",
      value: "GTB",
      display: "GTB"
    },
    {
      name: "St Clair County International Airport",
      city: "Port Huron",
      country: "United States",
      value: "PHN",
      display: "PHN"
    },
    {
      name: "Meadows Field",
      city: "Bakersfield",
      country: "United States",
      value: "BFL",
      display: "BFL"
    },
    {
      name: "El Paso International Airport",
      city: "El Paso",
      country: "United States",
      value: "ELP",
      display: "ELP"
    },
    {
      name: "Valley International Airport",
      city: "Harlingen",
      country: "United States",
      value: "HRL",
      display: "HRL"
    },
    {
      name: "Columbia Metropolitan Airport",
      city: "Columbia",
      country: "United States",
      value: "CAE",
      display: "CAE"
    },
    {
      name: "Davis Monthan Air Force Base",
      city: "Tucson",
      country: "United States",
      value: "DMA",
      display: "DMA"
    },
    {
      name: "Pensacola Naval Air Station/Forrest Sherman Field",
      city: "Pensacola",
      country: "United States",
      value: "NPA",
      display: "NPA"
    },
    {
      name: "Pensacola Regional Airport",
      city: "Pensacola",
      country: "United States",
      value: "PNS",
      display: "PNS"
    },
    {
      name: "Grand Forks Air Force Base",
      city: "Red River",
      country: "United States",
      value: "RDR",
      display: "RDR"
    },
    {
      name: "William P Hobby Airport",
      city: "Houston",
      country: "United States",
      value: "HOU",
      display: "HOU"
    },
    {
      name: "Buckley Air Force Base",
      city: "Buckley",
      country: "United States",
      value: "BKF",
      display: "BKF"
    },
    {
      name: "Northway Airport",
      city: "Northway",
      country: "United States",
      value: "ORT",
      display: "ORT"
    },
    {
      name: "Palmer Municipal Airport",
      city: "Palmer",
      country: "United States",
      value: "PAQ",
      display: "PAQ"
    },
    {
      name: "Pittsburgh International Airport",
      city: "Pittsburgh",
      country: "United States",
      value: "PIT",
      display: "PIT"
    },
    {
      name: "Wiley Post Will Rogers Memorial Airport",
      city: "Barrow",
      country: "United States",
      value: "BRW",
      display: "BRW"
    },
    {
      name: "Ellington Airport",
      city: "Houston",
      country: "United States",
      value: "EFD",
      display: "EFD"
    },
    {
      name: "Whidbey Island Naval Air Station /Ault Field/ Airport",
      city: "Whidbey Island",
      country: "United States",
      value: "NUW",
      display: "NUW"
    },
    {
      name: "Alice International Airport",
      city: "Alice",
      country: "United States",
      value: "ALI",
      display: "ALI"
    },
    {
      name: "Moody Air Force Base",
      city: "Valdosta",
      country: "United States",
      value: "VAD",
      display: "VAD"
    },
    {
      name: "Miami International Airport",
      city: "Miami",
      country: "United States",
      value: "MIA",
      display: "MIA"
    },
    {
      name: "Seattle Tacoma International Airport",
      city: "Seattle",
      country: "United States",
      value: "SEA",
      display: "SEA"
    },
    {
      name: "Lovell Field",
      city: "Chattanooga",
      country: "United States",
      value: "CHA",
      display: "CHA"
    },
    {
      name: "Igor I Sikorsky Memorial Airport",
      city: "Stratford",
      country: "United States",
      value: "BDR",
      display: "BDR"
    },
    {
      name: "Jackson-Medgar Wiley Evers International Airport",
      city: "Jackson",
      country: "United States",
      value: "JAN",
      display: "JAN"
    },
    {
      name: "Scholes International At Galveston Airport",
      city: "Galveston",
      country: "United States",
      value: "GLS",
      display: "GLS"
    },
    {
      name: "Long Beach /Daugherty Field/ Airport",
      city: "Long Beach",
      country: "United States",
      value: "LGB",
      display: "LGB"
    },
    {
      name: "Dillingham Airfield",
      city: "Dillingham",
      country: "United States",
      value: "HDH",
      display: "HDH"
    },
    {
      name: "Williamsport Regional Airport",
      city: "Williamsport",
      country: "United States",
      value: "IPT",
      display: "IPT"
    },
    {
      name: "Indianapolis International Airport",
      city: "Indianapolis",
      country: "United States",
      value: "IND",
      display: "IND"
    },
    {
      name: "Whiteman Air Force Base",
      city: "Knobnoster",
      country: "United States",
      value: "SZL",
      display: "SZL"
    },
    {
      name: "Akron Fulton International Airport",
      city: "Akron",
      country: "United States",
      value: "AKC",
      display: "AKC"
    },
    {
      name: "Greenwood-Leflore Airport",
      city: "Greenwood",
      country: "United States",
      value: "GWO",
      display: "GWO"
    },
    {
      name: "Westchester County Airport",
      city: "White Plains",
      country: "United States",
      value: "HPN",
      display: "HPN"
    },
    {
      name: "Francis S Gabreski Airport",
      city: "West Hampton Beach",
      country: "United States",
      value: "FOK",
      display: "FOK"
    },
    {
      name: "Jonesboro Municipal Airport",
      city: "Jonesboro",
      country: "United States",
      value: "JBR",
      display: "JBR"
    },
    {
      name: "Palm Beach County Park Airport",
      city: "West Palm Beach",
      country: "United States",
      value: "LNA",
      display: "LNA"
    },
    {
      name: "North Island Naval Air Station-Halsey Field",
      city: "San Diego",
      country: "United States",
      value: "NZY",
      display: "NZY"
    },
    {
      name: "Biggs Army Air Field (Fort Bliss)",
      city: "El Paso",
      country: "United States",
      value: "BIF",
      display: "BIF"
    },
    {
      name: "Yuma MCAS/Yuma International Airport",
      city: "Yuma",
      country: "United States",
      value: "YUM",
      display: "YUM"
    },
    {
      name: "Cavern City Air Terminal",
      city: "Carlsbad",
      country: "United States",
      value: "CNM",
      display: "CNM"
    },
    {
      name: "Duluth International Airport",
      city: "Duluth",
      country: "United States",
      value: "DLH",
      display: "DLH"
    },
    {
      name: "Bethel Airport",
      city: "Bethel",
      country: "United States",
      value: "BET",
      display: "BET"
    },
    {
      name: "Bowman Field",
      city: "Louisville",
      country: "United States",
      value: "LOU",
      display: "LOU"
    },
    {
      name: "Sierra Vista Municipal Libby Army Air Field",
      city: "Fort Huachuca",
      country: "United States",
      value: "FHU",
      display: "FHU"
    },
    {
      name: "Lihue Airport",
      city: "Lihue",
      country: "United States",
      value: "LIH",
      display: "LIH"
    },
    {
      name: "Terre Haute International Hulman Field",
      city: "Terre Haute",
      country: "United States",
      value: "HUF",
      display: "HUF"
    },
    {
      name: "Havre City County Airport",
      city: "Havre",
      country: "United States",
      value: "HVR",
      display: "HVR"
    },
    {
      name: "Grant County International Airport",
      city: "Grant County Airport",
      country: "United States",
      value: "MWH",
      display: "MWH"
    },
    {
      name: "Edward F Knapp State Airport",
      city: "Montpelier",
      country: "United States",
      value: "MPV",
      display: "MPV"
    },
    {
      name: "Richmond International Airport",
      city: "Richmond",
      country: "United States",
      value: "RIC",
      display: "RIC"
    },
    {
      name: "Shreveport Regional Airport",
      city: "Shreveport",
      country: "United States",
      value: "SHV",
      display: "SHV"
    },
    {
      name: "Merle K (Mudhole) Smith Airport",
      city: "Cordova",
      country: "United States",
      value: "CDV",
      display: "CDV"
    },
    {
      name: "Norfolk International Airport",
      city: "Norfolk",
      country: "United States",
      value: "ORF",
      display: "ORF"
    },
    {
      name: "Southeast Texas Regional Airport",
      city: "Beaumont",
      country: "United States",
      value: "BPT",
      display: "BPT"
    },
    {
      name: "Savannah Hilton Head International Airport",
      city: "Savannah",
      country: "United States",
      value: "SAV",
      display: "SAV"
    },
    {
      name: "Hill Air Force Base",
      city: "Ogden",
      country: "United States",
      value: "HIF",
      display: "HIF"
    },
    {
      name: "Nome Airport",
      city: "Nome",
      country: "United States",
      value: "OME",
      display: "OME"
    },
    {
      name: "St Petersburg Clearwater International Airport",
      city: "St. Petersburg",
      country: "United States",
      value: "PIE",
      display: "PIE"
    },
    {
      name: "Menominee Marinette Twin County Airport",
      city: "Macon",
      country: "United States",
      value: "MNM",
      display: "MNM"
    },
    {
      name: "Lone Star Executive Airport",
      city: "Conroe",
      country: "United States",
      value: "CXO",
      display: "CXO"
    },
    {
      name: "Deadhorse Airport",
      city: "Deadhorse",
      country: "United States",
      value: "SCC",
      display: "SCC"
    },
    {
      name: "San Antonio International Airport",
      city: "San Antonio",
      country: "United States",
      value: "SAT",
      display: "SAT"
    },
    {
      name: "Greater Rochester International Airport",
      city: "Rochester",
      country: "United States",
      value: "ROC",
      display: "ROC"
    },
    {
      name: "Patrick Air Force Base",
      city: "Coco Beach",
      country: "United States",
      value: "COF",
      display: "COF"
    },
    {
      name: "Teterboro Airport",
      city: "Teterboro",
      country: "United States",
      value: "TEB",
      display: "TEB"
    },
    {
      name: "Ellsworth Air Force Base",
      city: "Rapid City",
      country: "United States",
      value: "RCA",
      display: "RCA"
    },
    {
      name: "Raleigh Durham International Airport",
      city: "Raleigh-Durham",
      country: "United States",
      value: "RDU",
      display: "RDU"
    },
    {
      name: "James M Cox Dayton International Airport",
      city: "Dayton",
      country: "United States",
      value: "DAY",
      display: "DAY"
    },
    {
      name: "Kenai Municipal Airport",
      city: "Kenai",
      country: "United States",
      value: "ENA",
      display: "ENA"
    },
    {
      name: "Mc Alester Regional Airport",
      city: "Mcalester",
      country: "United States",
      value: "MLC",
      display: "MLC"
    },
    {
      name: "Niagara Falls International Airport",
      city: "Niagara Falls",
      country: "United States",
      value: "IAG",
      display: "IAG"
    },
    {
      name: "Coulter Field",
      city: "Bryan",
      country: "United States",
      value: "CFD",
      display: "CFD"
    },
    {
      name: "Newport News Williamsburg International Airport",
      city: "Newport News",
      country: "United States",
      value: "PHF",
      display: "PHF"
    },
    {
      name: "Esler Regional Airport",
      city: "Alexandria",
      country: "United States",
      value: "ESF",
      display: "ESF"
    },
    {
      name: "Altus Air Force Base",
      city: "Altus",
      country: "United States",
      value: "LTS",
      display: "LTS"
    },
    {
      name: "Tucson International Airport",
      city: "Tucson",
      country: "United States",
      value: "TUS",
      display: "TUS"
    },
    {
      name: "Minot Air Force Base",
      city: "Minot",
      country: "United States",
      value: "MIB",
      display: "MIB"
    },
    {
      name: "Beale Air Force Base",
      city: "Marysville",
      country: "United States",
      value: "BAB",
      display: "BAB"
    },
    {
      name: "Greater Kankakee Airport",
      city: "Kankakee",
      country: "United States",
      value: "IKK",
      display: "IKK"
    },
    {
      name: "Seymour Johnson Air Force Base",
      city: "Goldsboro",
      country: "United States",
      value: "GSB",
      display: "GSB"
    },
    {
      name: "Theodore Francis Green State Airport",
      city: "Providence",
      country: "United States",
      value: "PVD",
      display: "PVD"
    },
    {
      name: "Salisbury Ocean City Wicomico Regional Airport",
      city: "Salisbury",
      country: "United States",
      value: "SBY",
      display: "SBY"
    },
    {
      name: "Rancho Murieta Airport",
      city: "Rancho Murieta",
      country: "United States",
      value: "RIU",
      display: "RIU"
    },
    {
      name: "Bob Hope Airport",
      city: "Burbank",
      country: "United States",
      value: "BUR",
      display: "BUR"
    },
    {
      name: "Detroit Metropolitan Wayne County Airport",
      city: "Detroit",
      country: "United States",
      value: "DTW",
      display: "DTW"
    },
    {
      name: "Tampa International Airport",
      city: "Tampa",
      country: "United States",
      value: "TPA",
      display: "TPA"
    },
    {
      name: "Pembina Municipal Airport",
      city: "Pembina",
      country: "United States",
      value: "PMB",
      display: "PMB"
    },
    {
      name: "Polk Army Air Field",
      city: "Fort Polk",
      country: "United States",
      value: "POE",
      display: "POE"
    },
    {
      name: "Eielson Air Force Base",
      city: "Fairbanks",
      country: "United States",
      value: "EIL",
      display: "EIL"
    },
    {
      name: "Range Regional Airport",
      city: "Hibbing",
      country: "United States",
      value: "HIB",
      display: "HIB"
    },
    {
      name: "Angelina County Airport",
      city: "Lufkin",
      country: "United States",
      value: "LFK",
      display: "LFK"
    },
    {
      name: "Midland International Airport",
      city: "Midland",
      country: "United States",
      value: "MAF",
      display: "MAF"
    },
    {
      name: "Austin Straubel International Airport",
      city: "Green Bay",
      country: "United States",
      value: "GRB",
      display: "GRB"
    },
    {
      name: "Ardmore Municipal Airport",
      city: "Ardmore",
      country: "United States",
      value: "ADM",
      display: "ADM"
    },
    {
      name: "Mc Guire Air Force Base",
      city: "Wrightstown",
      country: "United States",
      value: "WRI",
      display: "WRI"
    },
    {
      name: "Emanuel County Airport",
      city: "Santa Barbara",
      country: "United States",
      value: "SBO",
      display: "SBO"
    },
    {
      name: "Augusta Regional At Bush Field",
      city: "Bush Field",
      country: "United States",
      value: "AGS",
      display: "AGS"
    },
    {
      name: "Sloulin Field International Airport",
      city: "Williston",
      country: "United States",
      value: "ISN",
      display: "ISN"
    },
    {
      name: "Bill & Hillary Clinton National Airport/Adams Field",
      city: "Little Rock",
      country: "United States",
      value: "LIT",
      display: "LIT"
    },
    {
      name: "Stewart International Airport",
      city: "Newburgh",
      country: "United States",
      value: "SWF",
      display: "SWF"
    },
    {
      name: "Baudette International Airport",
      city: "Baudette",
      country: "United States",
      value: "BDE",
      display: "BDE"
    },
    {
      name: "Sacramento Executive Airport",
      city: "Sacramento",
      country: "United States",
      value: "SAC",
      display: "SAC"
    },
    {
      name: "Homer Airport",
      city: "Homer",
      country: "United States",
      value: "HOM",
      display: "HOM"
    },
    {
      name: "Waynesville-St. Robert Regional Forney field",
      city: "Fort Leonardwood",
      country: "United States",
      value: "TBN",
      display: "TBN"
    },
    {
      name: "Dobbins Air Reserve Base",
      city: "Marietta",
      country: "United States",
      value: "MGE",
      display: "MGE"
    },
    {
      name: "Fairchild Air Force Base",
      city: "Spokane",
      country: "United States",
      value: "SKA",
      display: "SKA"
    },
    {
      name: "Roscommon County - Blodgett Memorial Airport",
      city: "Houghton Lake",
      country: "United States",
      value: "HTL",
      display: "HTL"
    },
    {
      name: "Tyndall Air Force Base",
      city: "Panama City",
      country: "United States",
      value: "PAM",
      display: "PAM"
    },
    {
      name: "Dallas Fort Worth International Airport",
      city: "Dallas-Fort Worth",
      country: "United States",
      value: "DFW",
      display: "DFW"
    },
    {
      name: "Melbourne International Airport",
      city: "Melbourne",
      country: "United States",
      value: "MLB",
      display: "MLB"
    },
    {
      name: "McChord Air Force Base",
      city: "Tacoma",
      country: "United States",
      value: "TCM",
      display: "TCM"
    },
    {
      name: "Austin Bergstrom International Airport",
      city: "Austin",
      country: "United States",
      value: "AUS",
      display: "AUS"
    },
    {
      name: "Rickenbacker International Airport",
      city: "Columbus",
      country: "United States",
      value: "LCK",
      display: "LCK"
    },
    {
      name: "Sawyer International Airport",
      city: "Gwinn",
      country: "United States",
      value: "MQT",
      display: "MQT"
    },
    {
      name: "McGhee Tyson Airport",
      city: "Knoxville",
      country: "United States",
      value: "TYS",
      display: "TYS"
    },
    {
      name: "Hood Army Air Field",
      city: "Fort Hood",
      country: "United States",
      value: "HLR",
      display: "HLR"
    },
    {
      name: "Lambert St Louis International Airport",
      city: "St. Louis",
      country: "United States",
      value: "STL",
      display: "STL"
    },
    {
      name: "Millville Municipal Airport",
      city: "Millville",
      country: "United States",
      value: "MIV",
      display: "MIV"
    },
    {
      name: "Sheppard Air Force Base-Wichita Falls Municipal Airport",
      city: "Wichita Falls",
      country: "United States",
      value: "SPS",
      display: "SPS"
    },
    {
      name: "Cincinnati Municipal Airport Lunken Field",
      city: "Cincinnati",
      country: "United States",
      value: "LUK",
      display: "LUK"
    },
    {
      name: "Hartsfield Jackson Atlanta International Airport",
      city: "Atlanta",
      country: "United States",
      value: "ATL",
      display: "ATL"
    },
    {
      name: "Castle Airport",
      city: "Merced",
      country: "United States",
      value: "MER",
      display: "MER"
    },
    {
      name: "Mc Clellan Airfield",
      city: "Sacramento",
      country: "United States",
      value: "MCC",
      display: "MCC"
    },
    {
      name: "Gerald R. Ford International Airport",
      city: "Grand Rapids",
      country: "United States",
      value: "GRR",
      display: "GRR"
    },
    {
      name: "Winkler County Airport",
      city: "Wink",
      country: "United States",
      value: "INK",
      display: "INK"
    },
    {
      name: "Fresno Yosemite International Airport",
      city: "Fresno",
      country: "United States",
      value: "FAT",
      display: "FAT"
    },
    {
      name: "Vero Beach Municipal Airport",
      city: "Vero Beach",
      country: "United States",
      value: "VRB",
      display: "VRB"
    },
    {
      name: "Imperial County Airport",
      city: "Imperial",
      country: "United States",
      value: "IPL",
      display: "IPL"
    },
    {
      name: "Nashville International Airport",
      city: "Nashville",
      country: "United States",
      value: "BNA",
      display: "BNA"
    },
    {
      name: "Laredo International Airport",
      city: "Laredo",
      country: "United States",
      value: "LRD",
      display: "LRD"
    },
    {
      name: "Elmendorf Air Force Base",
      city: "Anchorage",
      country: "United States",
      value: "EDF",
      display: "EDF"
    },
    {
      name: "Ralph Wien Memorial Airport",
      city: "Kotzebue",
      country: "United States",
      value: "OTZ",
      display: "OTZ"
    },
    {
      name: "Altoona Blair County Airport",
      city: "Altoona",
      country: "United States",
      value: "AOO",
      display: "AOO"
    },
    {
      name: "Dyess Air Force Base",
      city: "Abilene",
      country: "United States",
      value: "DYS",
      display: "DYS"
    },
    {
      name: "South Arkansas Regional At Goodwin Field",
      city: "El Dorado",
      country: "United States",
      value: "ELD",
      display: "ELD"
    },
    {
      name: "La Guardia Airport",
      city: "New York",
      country: "United States",
      value: "LGA",
      display: "LGA"
    },
    {
      name: "Tallahassee Regional Airport",
      city: "Tallahassee",
      country: "United States",
      value: "TLH",
      display: "TLH"
    },
    {
      name: "Dupage Airport",
      city: "West Chicago",
      country: "United States",
      value: "DPA",
      display: "DPA"
    },
    {
      name: "Waco Regional Airport",
      city: "Waco",
      country: "United States",
      value: "ACT",
      display: "ACT"
    },
    {
      name: "Augusta State Airport",
      city: "Augusta",
      country: "United States",
      value: "AUG",
      display: "AUG"
    },
    {
      name: "Hillsboro Municipal Airport",
      city: "Hillsboro",
      country: "United States",
      value: "INJ",
      display: "INJ"
    },
    {
      name: "Mc Kellar Sipes Regional Airport",
      city: "Jackson",
      country: "United States",
      value: "MKL",
      display: "MKL"
    },
    {
      name: "Molokai Airport",
      city: "Molokai",
      country: "United States",
      value: "MKK",
      display: "MKK"
    },
    {
      name: "Godman Army Air Field",
      city: "Fort Knox",
      country: "United States",
      value: "FTK",
      display: "FTK"
    },
    {
      name: "San Angelo Regional Mathis Field",
      city: "San Angelo",
      country: "United States",
      value: "SJT",
      display: "SJT"
    },
    {
      name: "Calexico International Airport",
      city: "Calexico",
      country: "United States",
      value: "CXL",
      display: "CXL"
    },
    {
      name: "Chico Municipal Airport",
      city: "Chico",
      country: "United States",
      value: "CIC",
      display: "CIC"
    },
    {
      name: "Burlington International Airport",
      city: "Burlington",
      country: "United States",
      value: "BTV",
      display: "BTV"
    },
    {
      name: "Jacksonville International Airport",
      city: "Jacksonville",
      country: "United States",
      value: "JAX",
      display: "JAX"
    },
    {
      name: "Durango La Plata County Airport",
      city: "Durango",
      country: "United States",
      value: "DRO",
      display: "DRO"
    },
    {
      name: "Washington Dulles International Airport",
      city: "Washington",
      country: "United States",
      value: "IAD",
      display: "IAD"
    },
    {
      name: "Easterwood Field",
      city: "College Station",
      country: "United States",
      value: "CLL",
      display: "CLL"
    },
    {
      name: "Felts Field",
      city: "Spokane",
      country: "United States",
      value: "SFF",
      display: "SFF"
    },
    {
      name: "General Mitchell International Airport",
      city: "Milwaukee",
      country: "United States",
      value: "MKE",
      display: "MKE"
    },
    {
      name: "Abilene Regional Airport",
      city: "Abilene",
      country: "United States",
      value: "ABI",
      display: "ABI"
    },
    {
      name: "Columbia Regional Airport",
      city: "Columbia",
      country: "United States",
      value: "COU",
      display: "COU"
    },
    {
      name: "Portland International Airport",
      city: "Portland",
      country: "United States",
      value: "PDX",
      display: "PDX"
    },
    {
      name: "Dade Collier Training and Transition Airport",
      city: "Miami",
      country: "United States",
      value: "TNT",
      display: "TNT"
    },
    {
      name: "Palm Beach International Airport",
      city: "West Palm Beach",
      country: "United States",
      value: "PBI",
      display: "PBI"
    },
    {
      name: "Fort Worth Meacham International Airport",
      city: "Fort Worth",
      country: "United States",
      value: "FTW",
      display: "FTW"
    },
    {
      name: "Ogdensburg International Airport",
      city: "Ogdensburg",
      country: "United States",
      value: "OGS",
      display: "OGS"
    },
    {
      name: "Boeing Field King County International Airport",
      city: "Seattle",
      country: "United States",
      value: "BFI",
      display: "BFI"
    },
    {
      name: "Lackland Air Force Base",
      city: "San Antonio",
      country: "United States",
      value: "SKF",
      display: "SKF"
    },
    {
      name: "Honolulu International Airport",
      city: "Honolulu",
      country: "United States",
      value: "HNL",
      display: "HNL"
    },
    {
      name: "Des Moines International Airport",
      city: "Des Moines",
      country: "United States",
      value: "DSM",
      display: "DSM"
    },
    {
      name: "Coastal Carolina Regional Airport",
      city: "New Bern",
      country: "United States",
      value: "EWN",
      display: "EWN"
    },
    {
      name: "San Diego International Airport",
      city: "San Diego",
      country: "United States",
      value: "SAN",
      display: "SAN"
    },
    {
      name: "Monroe Regional Airport",
      city: "Monroe",
      country: "United States",
      value: "MLU",
      display: "MLU"
    },
    {
      name: "Shaw Air Force Base",
      city: "Sumter",
      country: "United States",
      value: "SSC",
      display: "SSC"
    },
    {
      name: "Ontario International Airport",
      city: "Ontario",
      country: "United States",
      value: "ONT",
      display: "ONT"
    },
    {
      name: "Majors Airport",
      city: "Greenvile",
      country: "United States",
      value: "GVT",
      display: "GVT"
    },
    {
      name: "Roswell International Air Center Airport",
      city: "Roswell",
      country: "United States",
      value: "ROW",
      display: "ROW"
    },
    {
      name: "Coleman A. Young Municipal Airport",
      city: "Detroit",
      country: "United States",
      value: "DET",
      display: "DET"
    },
    {
      name: "Brownsville South Padre Island International Airport",
      city: "Brownsville",
      country: "United States",
      value: "BRO",
      display: "BRO"
    },
    {
      name: "Dothan Regional Airport",
      city: "Dothan",
      country: "United States",
      value: "DHN",
      display: "DHN"
    },
    {
      name: "Cape May County Airport",
      city: "Wildwood",
      country: "United States",
      value: "WWD",
      display: "WWD"
    },
    {
      name: "Selfridge Angb Airport",
      city: "Mount Clemens",
      country: "United States",
      value: "MTC",
      display: "MTC"
    },
    {
      name: "Four Corners Regional Airport",
      city: "Farmington",
      country: "United States",
      value: "FMN",
      display: "FMN"
    },
    {
      name: "Corpus Christi International Airport",
      city: "Corpus Christi",
      country: "United States",
      value: "CRP",
      display: "CRP"
    },
    {
      name: "Syracuse Hancock International Airport",
      city: "Syracuse",
      country: "United States",
      value: "SYR",
      display: "SYR"
    },
    {
      name: "Naval Air Station Key West/Boca Chica Field",
      city: "Key West",
      country: "United States",
      value: "NQX",
      display: "NQX"
    },
    {
      name: "Chicago Midway International Airport",
      city: "Chicago",
      country: "United States",
      value: "MDW",
      display: "MDW"
    },
    {
      name: "Norman Y. Mineta San Jose International Airport",
      city: "San Jose",
      country: "United States",
      value: "SJC",
      display: "SJC"
    },
    {
      name: "Lea County Regional Airport",
      city: "Hobbs",
      country: "United States",
      value: "HOB",
      display: "HOB"
    },
    {
      name: "Northeast Philadelphia Airport",
      city: "Philadelphia",
      country: "United States",
      value: "PNE",
      display: "PNE"
    },
    {
      name: "Denver International Airport",
      city: "Denver",
      country: "United States",
      value: "DEN",
      display: "DEN"
    },
    {
      name: "Philadelphia International Airport",
      city: "Philadelphia",
      country: "United States",
      value: "PHL",
      display: "PHL"
    },
    {
      name: "Sioux Gateway Col. Bud Day Field",
      city: "Sioux City",
      country: "United States",
      value: "SUX",
      display: "SUX"
    },
    {
      name: "Middle Georgia Regional Airport",
      city: "Macon",
      country: "United States",
      value: "MCN",
      display: "MCN"
    },
    {
      name: "Truth Or Consequences Municipal Airport",
      city: "Truth Or Consequences",
      country: "United States",
      value: "TCS",
      display: "TCS"
    },
    {
      name: "Palmdale Regional/USAF Plant 42 Airport",
      city: "Palmdale",
      country: "United States",
      value: "PMD",
      display: "PMD"
    },
    {
      name: "Randolph Air Force Base",
      city: "San Antonio",
      country: "United States",
      value: "RND",
      display: "RND"
    },
    {
      name: "El Centro Naf Airport",
      city: "El Centro",
      country: "United States",
      value: "NJK",
      display: "NJK"
    },
    {
      name: "Port Columbus International Airport",
      city: "Columbus",
      country: "United States",
      value: "CMH",
      display: "CMH"
    },
    {
      name: "Drake Field",
      city: "Fayetteville",
      country: "United States",
      value: "FYV",
      display: "FYV"
    },
    {
      name: "Henry Post Army Air Field (Fort Sill)",
      city: "Fort Sill",
      country: "United States",
      value: "FSI",
      display: "FSI"
    },
    {
      name: "Princeton Municipal Airport",
      city: "Princeton",
      country: "United States",
      value: "PNM",
      display: "PNM"
    },
    {
      name: "Wright-Patterson Air Force Base",
      city: "Dayton",
      country: "United States",
      value: "FFO",
      display: "FFO"
    },
    {
      name: "Edward G. Pitka Sr Airport",
      city: "Galena",
      country: "United States",
      value: "GAL",
      display: "GAL"
    },
    {
      name: "Mineral Wells Airport",
      city: "Mineral Wells",
      country: "United States",
      value: "MWL",
      display: "MWL"
    },
    {
      name: "Mc Connell Air Force Base",
      city: "Wichita",
      country: "United States",
      value: "IAB",
      display: "IAB"
    },
    {
      name: "New Orleans NAS JRB/Alvin Callender Field",
      city: "New Orleans",
      country: "United States",
      value: "NBG",
      display: "NBG"
    },
    {
      name: "Beaufort County Airport",
      city: "Beaufort",
      country: "United States",
      value: "BFT",
      display: "BFT"
    },
    {
      name: "Texarkana Regional Webb Field",
      city: "Texarkana",
      country: "United States",
      value: "TXK",
      display: "TXK"
    },
    {
      name: "Plattsburgh International Airport",
      city: "Plattsburgh",
      country: "United States",
      value: "PBG",
      display: "PBG"
    },
    {
      name: "Phillips Army Air Field",
      city: "Aberdeen",
      country: "United States",
      value: "APG",
      display: "APG"
    },
    {
      name: "Tucumcari Municipal Airport",
      city: "Tucumcari",
      country: "United States",
      value: "TCC",
      display: "TCC"
    },
    {
      name: "Ted Stevens Anchorage International Airport",
      city: "Anchorage",
      country: "United States",
      value: "ANC",
      display: "ANC"
    },
    {
      name: "Robert Gray  Army Air Field Airport",
      city: "Killeen",
      country: "United States",
      value: "GRK",
      display: "GRK"
    },
    {
      name: "Black Rock Airport",
      city: "Zuni Pueblo",
      country: "United States",
      value: "ZUN",
      display: "ZUN"
    },
    {
      name: "Bellingham International Airport",
      city: "Bellingham",
      country: "United States",
      value: "BLI",
      display: "BLI"
    },
    {
      name: "Millington Regional Jetport Airport",
      city: "Millington",
      country: "United States",
      value: "NQA",
      display: "NQA"
    },
    {
      name: "Elkins-Randolph Co-Jennings Randolph Field",
      city: "Elkins",
      country: "United States",
      value: "EKN",
      display: "EKN"
    },
    {
      name: "Hartford Brainard Airport",
      city: "Hartford",
      country: "United States",
      value: "HFD",
      display: "HFD"
    },
    {
      name: "North Central State Airport",
      city: "Smithfield",
      country: "United States",
      value: "SFZ",
      display: "SFZ"
    },
    {
      name: "Mobile Regional Airport",
      city: "Mobile",
      country: "United States",
      value: "MOB",
      display: "MOB"
    },
    {
      name: "Moffett Federal Airfield",
      city: "Mountain View",
      country: "United States",
      value: "NUQ",
      display: "NUQ"
    },
    {
      name: "Santa Fe Municipal Airport",
      city: "Santa Fe",
      country: "United States",
      value: "SAF",
      display: "SAF"
    },
    {
      name: "Barking Sands Airport",
      city: "Barking Sands",
      country: "United States",
      value: "BKH",
      display: "BKH"
    },
    {
      name: "Beauregard Regional Airport",
      city: "Deridder",
      country: "United States",
      value: "DRI",
      display: "DRI"
    },
    {
      name: "Bradshaw Army Airfield",
      city: "Bradshaw Field",
      country: "United States",
      value: "BSF",
      display: "BSF"
    },
    {
      name: "Nogales International Airport",
      city: "Nogales",
      country: "United States",
      value: "OLS",
      display: "OLS"
    },
    {
      name: "Mac Dill Air Force Base",
      city: "Tampa",
      country: "United States",
      value: "MCF",
      display: "MCF"
    },
    {
      name: "Scott AFB/Midamerica Airport",
      city: "Belleville",
      country: "United States",
      value: "BLV",
      display: "BLV"
    },
    {
      name: "Opa-locka Executive Airport",
      city: "Miami",
      country: "United States",
      value: "OPF",
      display: "OPF"
    },
    {
      name: "Del Rio International Airport",
      city: "Del Rio",
      country: "United States",
      value: "DRT",
      display: "DRT"
    },
    {
      name: "Southwest Florida International Airport",
      city: "Fort Myers",
      country: "United States",
      value: "RSW",
      display: "RSW"
    },
    {
      name: "King Salmon Airport",
      city: "King Salmon",
      country: "United States",
      value: "AKN",
      display: "AKN"
    },
    {
      name: "Muir Army Air Field (Fort Indiantown Gap) Airport",
      city: "Muir",
      country: "United States",
      value: "MUI",
      display: "MUI"
    },
    {
      name: "Kapalua Airport",
      city: "Lahania-kapalua",
      country: "United States",
      value: "JHM",
      display: "JHM"
    },
    {
      name: "John F Kennedy International Airport",
      city: "New York",
      country: "United States",
      value: "JFK",
      display: "JFK"
    },
    {
      name: "Homestead ARB Airport",
      city: "Homestead",
      country: "United States",
      value: "HST",
      display: "HST"
    },
    {
      name: "Riverside Municipal Airport",
      city: "Riverside",
      country: "United States",
      value: "RAL",
      display: "RAL"
    },
    {
      name: "Sherman Army Air Field",
      city: "Fort Leavenworth",
      country: "United States",
      value: "FLV",
      display: "FLV"
    },
    {
      name: "Wallops Flight Facility Airport",
      city: "Wallops Island",
      country: "United States",
      value: "WAL",
      display: "WAL"
    },
    {
      name: "Holloman Air Force Base",
      city: "Alamogordo",
      country: "United States",
      value: "HMN",
      display: "HMN"
    },
    {
      name: "Willow Grove Naval Air Station/Joint Reserve Base",
      city: "Willow Grove",
      country: "United States",
      value: "NXX",
      display: "NXX"
    },
    {
      name: "Cheyenne Regional Jerry Olson Field",
      city: "Cheyenne",
      country: "United States",
      value: "CYS",
      display: "CYS"
    },
    {
      name: "Stockton Metropolitan Airport",
      city: "Stockton",
      country: "United States",
      value: "SCK",
      display: "SCK"
    },
    {
      name: "Charleston Air Force Base-International Airport",
      city: "Charleston",
      country: "United States",
      value: "CHS",
      display: "CHS"
    },
    {
      name: "Reno Tahoe International Airport",
      city: "Reno",
      country: "United States",
      value: "RNO",
      display: "RNO"
    },
    {
      name: "Ketchikan International Airport",
      city: "Ketchikan",
      country: "United States",
      value: "KTN",
      display: "KTN"
    },
    {
      name: "Willow Run Airport",
      city: "Detroit",
      country: "United States",
      value: "YIP",
      display: "YIP"
    },
    {
      name: "Vandenberg Air Force Base",
      city: "Lompoc",
      country: "United States",
      value: "VBG",
      display: "VBG"
    },
    {
      name: "Birmingham-Shuttlesworth International Airport",
      city: "Birmingham",
      country: "United States",
      value: "BHM",
      display: "BHM"
    },
    {
      name: "Lakehurst Maxfield Field Airport",
      city: "Lakehurst",
      country: "United States",
      value: "NEL",
      display: "NEL"
    },
    {
      name: "Nellis Air Force Base",
      city: "Las Vegas",
      country: "United States",
      value: "LSV",
      display: "LSV"
    },
    {
      name: "March ARB Airport",
      city: "Riverside",
      country: "United States",
      value: "RIV",
      display: "RIV"
    },
    {
      name: "Modesto City Co-Harry Sham Field",
      city: "Modesto",
      country: "United States",
      value: "MOD",
      display: "MOD"
    },
    {
      name: "Sacramento International Airport",
      city: "Sacramento",
      country: "United States",
      value: "SMF",
      display: "SMF"
    },
    {
      name: "Waukegan National Airport",
      city: "Chicago",
      country: "United States",
      value: "UGN",
      display: "UGN"
    },
    {
      name: "City of Colorado Springs Municipal Airport",
      city: "Colorado Springs",
      country: "United States",
      value: "COS",
      display: "COS"
    },
    {
      name: "Buffalo Niagara International Airport",
      city: "Buffalo",
      country: "United States",
      value: "BUF",
      display: "BUF"
    },
    {
      name: "Griffing Sandusky Airport",
      city: "Sandusky",
      country: "United States",
      value: "SKY",
      display: "SKY"
    },
    {
      name: "Snohomish County (Paine Field) Airport",
      city: "Everett",
      country: "United States",
      value: "PAE",
      display: "PAE"
    },
    {
      name: "Mountain Home Air Force Base",
      city: "Mountain Home",
      country: "United States",
      value: "MUO",
      display: "MUO"
    },
    {
      name: "Cedar City Regional Airport",
      city: "Cedar City",
      country: "United States",
      value: "CDC",
      display: "CDC"
    },
    {
      name: "Bradley International Airport",
      city: "Windsor Locks",
      country: "United States",
      value: "BDL",
      display: "BDL"
    },
    {
      name: "Mc Allen Miller International Airport",
      city: "Mcallen",
      country: "United States",
      value: "MFE",
      display: "MFE"
    },
    {
      name: "Norfolk Ns (Chambers Fld) Airport",
      city: "Norfolk",
      country: "United States",
      value: "NGU",
      display: "NGU"
    },
    {
      name: "Westover ARB/Metropolitan Airport",
      city: "Chicopee Falls",
      country: "United States",
      value: "CEF",
      display: "CEF"
    },
    {
      name: "Lubbock Preston Smith International Airport",
      city: "Lubbock",
      country: "United States",
      value: "LBB",
      display: "LBB"
    },
    {
      name: "Chicago O'Hare International Airport",
      city: "Chicago",
      country: "United States",
      value: "ORD",
      display: "ORD"
    },
    {
      name: "Boca Raton Airport",
      city: "Boca Raton",
      country: "United States",
      value: "BCT",
      display: "BCT"
    },
    {
      name: "Fairbanks International Airport",
      city: "Fairbanks",
      country: "United States",
      value: "FAI",
      display: "FAI"
    },
    {
      name: "Quantico MCAF /Turner field",
      city: "Quantico",
      country: "United States",
      value: "NYG",
      display: "NYG"
    },
    {
      name: "Cannon Air Force Base",
      city: "Clovis",
      country: "United States",
      value: "CVS",
      display: "CVS"
    },
    {
      name: "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
      city: "Kaneohe Bay",
      country: "United States",
      value: "NGF",
      display: "NGF"
    },
    {
      name: "Offutt Air Force Base",
      city: "Omaha",
      country: "United States",
      value: "OFF",
      display: "OFF"
    },
    {
      name: "Gulkana Airport",
      city: "Gulkana",
      country: "United States",
      value: "GKN",
      display: "GKN"
    },
    {
      name: "Watertown International Airport",
      city: "Watertown",
      country: "United States",
      value: "ART",
      display: "ART"
    },
    {
      name: "Palm Springs International Airport",
      city: "Palm Springs",
      country: "United States",
      value: "PSP",
      display: "PSP"
    },
    {
      name: "Rick Husband Amarillo International Airport",
      city: "Amarillo",
      country: "United States",
      value: "AMA",
      display: "AMA"
    },
    {
      name: "Fort Dodge Regional Airport",
      city: "Fort Dodge",
      country: "United States",
      value: "FOD",
      display: "FOD"
    },
    {
      name: "Barksdale Air Force Base",
      city: "Shreveport",
      country: "United States",
      value: "BAD",
      display: "BAD"
    },
    {
      name: "Topeka Regional Airport - Forbes Field",
      city: "Topeka",
      country: "United States",
      value: "FOE",
      display: "FOE"
    },
    {
      name: "Cotulla-La Salle County Airport",
      city: "Cotulla",
      country: "United States",
      value: "COT",
      display: "COT"
    },
    {
      name: "Wilmington International Airport",
      city: "Wilmington",
      country: "United States",
      value: "ILM",
      display: "ILM"
    },
    {
      name: "Baton Rouge Metropolitan, Ryan Field",
      city: "Baton Rouge",
      country: "United States",
      value: "BTR",
      display: "BTR"
    },
    {
      name: "Tyler Pounds Regional Airport",
      city: "Tyler",
      country: "United States",
      value: "TYR",
      display: "TYR"
    },
    {
      name: "Baltimore/Washington International Thurgood Marshall Airport",
      city: "Baltimore",
      country: "United States",
      value: "BWI",
      display: "BWI"
    },
    {
      name: "Hobart Regional Airport",
      city: "Hobart",
      country: "United States",
      value: "HBR",
      display: "HBR"
    },
    {
      name: "Lanai Airport",
      city: "Lanai",
      country: "United States",
      value: "LNY",
      display: "LNY"
    },
    {
      name: "Alexandria International Airport",
      city: "Alexandria",
      country: "United States",
      value: "AEX",
      display: "AEX"
    },
    {
      name: "Condron Army Air Field",
      city: "White Sands",
      country: "United States",
      value: "WSD",
      display: "WSD"
    },
    {
      name: "Cold Bay Airport",
      city: "Cold Bay",
      country: "United States",
      value: "CDB",
      display: "CDB"
    },
    {
      name: "Tulsa International Airport",
      city: "Tulsa",
      country: "United States",
      value: "TUL",
      display: "TUL"
    },
    {
      name: "Sitka Rocky Gutierrez Airport",
      city: "Sitka",
      country: "United States",
      value: "SIT",
      display: "SIT"
    },
    {
      name: "Long Island Mac Arthur Airport",
      city: "Islip",
      country: "United States",
      value: "ISP",
      display: "ISP"
    },
    {
      name: "Minneapolis-St Paul International/Wold-Chamberlain Airport",
      city: "Minneapolis",
      country: "United States",
      value: "MSP",
      display: "MSP"
    },
    {
      name: "New Castle Airport",
      city: "Wilmington",
      country: "United States",
      value: "ILG",
      display: "ILG"
    },
    {
      name: "Unalaska Airport",
      city: "Unalaska",
      country: "United States",
      value: "DUT",
      display: "DUT"
    },
    {
      name: "Louis Armstrong New Orleans International Airport",
      city: "New Orleans",
      country: "United States",
      value: "MSY",
      display: "MSY"
    },
    {
      name: "Portland International Jetport Airport",
      city: "Portland",
      country: "United States",
      value: "PWM",
      display: "PWM"
    },
    {
      name: "Will Rogers World Airport",
      city: "Oklahoma City",
      country: "United States",
      value: "OKC",
      display: "OKC"
    },
    {
      name: "Albany International Airport",
      city: "Albany",
      country: "United States",
      value: "ALB",
      display: "ALB"
    },
    {
      name: "Valdez Pioneer Field",
      city: "Valdez",
      country: "United States",
      value: "VDZ",
      display: "VDZ"
    },
    {
      name: "Langley Air Force Base",
      city: "Hampton",
      country: "United States",
      value: "LFI",
      display: "LFI"
    },
    {
      name: "John Wayne Airport-Orange County Airport",
      city: "Santa Ana",
      country: "United States",
      value: "SNA",
      display: "SNA"
    },
    {
      name: "Columbus Air Force Base",
      city: "Colombus",
      country: "United States",
      value: "CBM",
      display: "CBM"
    },
    {
      name: "Kendall-Tamiami Executive Airport",
      city: "Kendall-tamiami",
      country: "United States",
      value: "TMB",
      display: "TMB"
    },
    {
      name: "Oceana NAS",
      city: "Oceana",
      country: "United States",
      value: "NTU",
      display: "NTU"
    },
    {
      name: "Grissom Air Reserve Base",
      city: "Peru",
      country: "United States",
      value: "GUS",
      display: "GUS"
    },
    {
      name: "Casper-Natrona County International Airport",
      city: "Casper",
      country: "United States",
      value: "CPR",
      display: "CPR"
    },
    {
      name: "Destin-Ft Walton Beach Airport",
      city: "Valparaiso",
      country: "United States",
      value: "VPS",
      display: "VPS"
    },
    {
      name: "Craig Field",
      city: "Selma",
      country: "United States",
      value: "SEM",
      display: "SEM"
    },
    {
      name: "Key West International Airport",
      city: "Key West",
      country: "United States",
      value: "EYW",
      display: "EYW"
    },
    {
      name: "Charlotte Douglas International Airport",
      city: "Charlotte",
      country: "United States",
      value: "CLT",
      display: "CLT"
    },
    {
      name: "McCarran International Airport",
      city: "Las Vegas",
      country: "United States",
      value: "LAS",
      display: "LAS"
    },
    {
      name: "Orlando International Airport",
      city: "Orlando",
      country: "United States",
      value: "MCO",
      display: "MCO"
    },
    {
      name: "Florence Regional Airport",
      city: "Florence",
      country: "United States",
      value: "FLO",
      display: "FLO"
    },
    {
      name: "Great Falls International Airport",
      city: "Great Falls",
      country: "United States",
      value: "GTF",
      display: "GTF"
    },
    {
      name: "Youngstown Warren Regional Airport",
      city: "Youngstown",
      country: "United States",
      value: "YNG",
      display: "YNG"
    },
    {
      name: "Ladd AAF Airfield",
      city: "Fort Wainwright",
      country: "United States",
      value: "FBK",
      display: "FBK"
    },
    {
      name: "Mc Minnville Municipal Airport",
      city: "Mackminnville",
      country: "United States",
      value: "MMV",
      display: "MMV"
    },
    {
      name: "Robins Air Force Base",
      city: "Macon",
      country: "United States",
      value: "WRB",
      display: "WRB"
    },
    {
      name: "Suvarnabhumi Airport",
      city: "Bangkok",
      country: "Thailand",
      value: "BKK",
      display: "BKK"
    },
    {
      name: "Naha Airport",
      city: "Naha",
      country: "Indonesia",
      value: "NAH",
      display: "NAH"
    },
    {
      name: "Pongtiku Airport",
      city: "Makale",
      country: "Indonesia",
      value: "TTR",
      display: "TTR"
    },
    {
      name: "Wolter Monginsidi Airport",
      city: "Kendari",
      country: "Indonesia",
      value: "KDI",
      display: "KDI"
    },
    {
      name: "Maimun Saleh Airport",
      city: "Sabang",
      country: "Indonesia",
      value: "SBG",
      display: "SBG"
    },
    {
      name: "Abdul Rachman Saleh Airport",
      city: "Malang",
      country: "Indonesia",
      value: "MLG",
      display: "MLG"
    },
    {
      name: "Husein Sastranegara International Airport",
      city: "Bandung",
      country: "Indonesia",
      value: "BDO",
      display: "BDO"
    },
    {
      name: "Penggung Airport",
      city: "Cirebon",
      country: "Indonesia",
      value: "CBN",
      display: "CBN"
    },
    {
      name: "Adi Sutjipto International Airport",
      city: "Yogyakarta",
      country: "Indonesia",
      value: "JOG",
      display: "JOG"
    },
    {
      name: "Tunggul Wulung Airport",
      city: "Cilacap",
      country: "Indonesia",
      value: "CXP",
      display: "CXP"
    },
    {
      name: "Pondok Cabe Air Base",
      city: "Jakarta",
      country: "Indonesia",
      value: "PCB",
      display: "PCB"
    },
    {
      name: "Achmad Yani Airport",
      city: "Semarang",
      country: "Indonesia",
      value: "SRG",
      display: "SRG"
    },
    {
      name: "Hang Nadim International Airport",
      city: "Batam",
      country: "Indonesia",
      value: "BTH",
      display: "BTH"
    },
    {
      name: "Buluh Tumbang (H A S Hanandjoeddin) Airport",
      city: "Tanjung Pandan",
      country: "Indonesia",
      value: "TJQ",
      display: "TJQ"
    },
    {
      name: "Pangkal Pinang (Depati Amir) Airport",
      city: "Pangkal Pinang",
      country: "Indonesia",
      value: "PGK",
      display: "PGK"
    },
    {
      name: "Raja Haji Fisabilillah International Airport",
      city: "Tanjung Pinang",
      country: "Indonesia",
      value: "TNJ",
      display: "TNJ"
    },
    {
      name: "Dabo Airport",
      city: "Singkep",
      country: "Indonesia",
      value: "SIQ",
      display: "SIQ"
    },
    {
      name: "Syamsudin Noor Airport",
      city: "Banjarmasin",
      country: "Indonesia",
      value: "BDJ",
      display: "BDJ"
    },
    {
      name: "Iskandar Airport",
      city: "Pangkalan Bun",
      country: "Indonesia",
      value: "PKN",
      display: "PKN"
    },
    {
      name: "Tjilik Riwut Airport",
      city: "Palangkaraya",
      country: "Indonesia",
      value: "PKY",
      display: "PKY"
    },
    {
      name: "Maumere(Wai Oti) Airport",
      city: "Maumere",
      country: "Indonesia",
      value: "MOF",
      display: "MOF"
    },
    {
      name: "Ende (H Hasan Aroeboesman) Airport",
      city: "Ende",
      country: "Indonesia",
      value: "ENE",
      display: "ENE"
    },
    {
      name: "Frans Sales Lega Airport",
      city: "Ruteng",
      country: "Indonesia",
      value: "RTG",
      display: "RTG"
    },
    {
      name: "El Tari Airport",
      city: "Kupang",
      country: "Indonesia",
      value: "KOE",
      display: "KOE"
    },
    {
      name: "Komodo (Mutiara II) Airport",
      city: "Labuhan Bajo",
      country: "Indonesia",
      value: "LBJ",
      display: "LBJ"
    },
    {
      name: "Sultan Aji Muhamad Sulaiman Airport",
      city: "Balikpapan",
      country: "Indonesia",
      value: "BPN",
      display: "BPN"
    },
    {
      name: "Juwata Airport",
      city: "Taraken",
      country: "Indonesia",
      value: "TRK",
      display: "TRK"
    },
    {
      name: "Temindung Airport",
      city: "Samarinda",
      country: "Indonesia",
      value: "SRI",
      display: "SRI"
    },
    {
      name: "Selaparang Airport",
      city: "Mataram",
      country: "Indonesia",
      value: "AMI",
      display: "AMI"
    },
    {
      name: "Muhammad Salahuddin Airport",
      city: "Bima",
      country: "Indonesia",
      value: "BMU",
      display: "BMU"
    },
    {
      name: "Waingapu Airport",
      city: "Waingapu",
      country: "Indonesia",
      value: "WGP",
      display: "WGP"
    },
    {
      name: "Juanda International Airport",
      city: "Surabaya",
      country: "Indonesia",
      value: "SUB",
      display: "SUB"
    },
    {
      name: "Adi Sumarmo Wiryokusumo Airport",
      city: "Solo City",
      country: "Indonesia",
      value: "SOC",
      display: "SOC"
    },
    {
      name: "Incheon International Airport",
      city: "Seoul",
      country: "South Korea",
      value: "ICN",
      display: "ICN"
    },
    {
      name: "Chiang Mai International Airport",
      city: "Chiang Mai",
      country: "Thailand",
      value: "CNX",
      display: "CNX"
    },
    {
      name: "Chiang Rai International Airport",
      city: "Chiang Rai",
      country: "Thailand",
      value: "CEI",
      display: "CEI"
    },
    {
      name: "Nakhon Si Thammarat Airport",
      city: "Nakhon Si Thammarat",
      country: "Thailand",
      value: "NST",
      display: "NST"
    },
    {
      name: "Nakhon Ratchasima Airport",
      city: "Nakhon Ratchasima",
      country: "Thailand",
      value: "NAK",
      display: "NAK"
    },
    {
      name: "Nakhon Phanom Airport",
      city: "Nakhon Phanom",
      country: "Thailand",
      value: "KOP",
      display: "KOP"
    },
    {
      name: "Ubon Ratchathani Airport",
      city: "Ubon Ratchathani",
      country: "Thailand",
      value: "UBP",
      display: "UBP"
    },
    {
      name: "Khon Kaen Airport",
      city: "Khon Kaen",
      country: "Thailand",
      value: "KKC",
      display: "KKC"
    },
    {
      name: "Sukhothai Airport",
      city: "Sukhothai",
      country: "Thailand",
      value: "THS",
      display: "THS"
    },
    {
      name: "Ngurah Rai (Bali) International Airport",
      city: "Denpasar",
      country: "Indonesia",
      value: "DPS",
      display: "DPS"
    },
    {
      name: "Eleftherios Venizelos International Airport",
      city: "Athens",
      country: "Greece",
      value: "ATH",
      display: "ATH"
    },
    {
      name: "Chubu Centrair International Airport",
      city: "Nagoya",
      country: "Japan",
      value: "NGO",
      display: "NGO"
    },
    {
      name: "Kobe Airport",
      city: "Kobe",
      country: "Japan",
      value: "UKB",
      display: "UKB"
    },
    {
      name: "Pullman Moscow Regional Airport",
      city: "Pullman",
      country: "United States",
      value: "PUW",
      display: "PUW"
    },
    {
      name: "Lewiston Nez Perce County Airport",
      city: "Lewiston",
      country: "United States",
      value: "LWS",
      display: "LWS"
    },
    {
      name: "Elmira Corning Regional Airport",
      city: "Elmira",
      country: "United States",
      value: "ELM",
      display: "ELM"
    },
    {
      name: "Ithaca Tompkins Regional Airport",
      city: "Ithaca",
      country: "United States",
      value: "ITH",
      display: "ITH"
    },
    {
      name: "Monterey Peninsula Airport",
      city: "Monterey",
      country: "United States",
      value: "MRY",
      display: "MRY"
    },
    {
      name: "Santa Barbara Municipal Airport",
      city: "Santa Barbara",
      country: "United States",
      value: "SBA",
      display: "SBA"
    },
    {
      name: "Daytona Beach International Airport",
      city: "Daytona Beach",
      country: "United States",
      value: "DAB",
      display: "DAB"
    },
    {
      name: "Liepaja International Airport",
      city: "Liepaja",
      country: "Latvia",
      value: "LPX",
      display: "LPX"
    },
    {
      name: "Riga International Airport",
      city: "Riga",
      country: "Latvia",
      value: "RIX",
      display: "RIX"
    },
    {
      name: "Siauliai International Airport",
      city: "Siauliai",
      country: "Lithuania",
      value: "SQQ",
      display: "SQQ"
    },
    {
      name: "Barysiai Airport",
      city: "Barysiai",
      country: "Lithuania",
      value: "HLJ",
      display: "HLJ"
    },
    {
      name: "Kaunas International Airport",
      city: "Kaunas",
      country: "Lithuania",
      value: "KUN",
      display: "KUN"
    },
    {
      name: "Palanga International Airport",
      city: "Palanga",
      country: "Lithuania",
      value: "PLQ",
      display: "PLQ"
    },
    {
      name: "Vilnius International Airport",
      city: "Vilnius",
      country: "Lithuania",
      value: "VNO",
      display: "VNO"
    },
    {
      name: "Panevezys Air Base",
      city: "Panevezys",
      country: "Lithuania",
      value: "PNV",
      display: "PNV"
    },
    {
      name: "Zvartnots International Airport",
      city: "Yerevan",
      country: "Armenia",
      value: "EVN",
      display: "EVN"
    },
    {
      name: "Gyumri Shirak Airport",
      city: "Gyumri",
      country: "Armenia",
      value: "LWN",
      display: "LWN"
    },
    {
      name: "Assab International Airport",
      city: "Assab",
      country: "Eritrea",
      value: "ASA",
      display: "ASA"
    },
    {
      name: "Asmara International Airport",
      city: "Asmara",
      country: "Eritrea",
      value: "ASM",
      display: "ASM"
    },
    {
      name: "Massawa International Airport",
      city: "Massawa",
      country: "Eritrea",
      value: "MSW",
      display: "MSW"
    },
    {
      name: "Yasser Arafat International Airport",
      city: "Gaza",
      country: "Palestine",
      value: "GZA",
      display: "GZA"
    },
    {
      name: "Batumi International Airport",
      city: "Batumi",
      country: "Georgia",
      value: "BUS",
      display: "BUS"
    },
    {
      name: "Kopitnari Airport",
      city: "Kutaisi",
      country: "Georgia",
      value: "KUT",
      display: "KUT"
    },
    {
      name: "Tbilisi International Airport",
      city: "Tbilisi",
      country: "Georgia",
      value: "TBS",
      display: "TBS"
    },
    {
      name: "Mukalla International Airport",
      city: "Mukalla",
      country: "Yemen",
      value: "RIY",
      display: "RIY"
    },
    {
      name: "Ta'izz International Airport",
      city: "Taiz",
      country: "Yemen",
      value: "TAI",
      display: "TAI"
    },
    {
      name: "Hodeidah International Airport",
      city: "Hodeidah",
      country: "Yemen",
      value: "HOD",
      display: "HOD"
    },
    {
      name: "Aden International Airport",
      city: "Aden",
      country: "Yemen",
      value: "ADE",
      display: "ADE"
    },
    {
      name: "Ataq Airport",
      city: "Ataq",
      country: "Yemen",
      value: "AXK",
      display: "AXK"
    },
    {
      name: "Al Ghaidah International Airport",
      city: "Al Ghaidah Intl",
      country: "Yemen",
      value: "AAY",
      display: "AAY"
    },
    {
      name: "Sana'a International Airport",
      city: "Sanaa",
      country: "Yemen",
      value: "SAH",
      display: "SAH"
    },
    {
      name: "Beihan Airport",
      city: "Beihan",
      country: "Yemen",
      value: "BHN",
      display: "BHN"
    },
    {
      name: "Socotra International Airport",
      city: "Socotra",
      country: "Yemen",
      value: "SCT",
      display: "SCT"
    },
    {
      name: "Memmingen Allgau Airport",
      city: "Memmingen",
      country: "Germany",
      value: "FMM",
      display: "FMM"
    },
    {
      name: "Nevsehir Kapadokya Airport",
      city: "Nevsehir",
      country: "Turkey",
      value: "NAV",
      display: "NAV"
    },
    {
      name: "Ministro Pistarini International Airport",
      city: "Buenos Aires",
      country: "Argentina",
      value: "EZE",
      display: "EZE"
    },
    {
      name: "Erbil International Airport",
      city: "Erbil",
      country: "Iraq",
      value: "EBL",
      display: "EBL"
    },
    {
      name: "Emerald Airport",
      city: "Emerald",
      country: "Australia",
      value: "EMD",
      display: "EMD"
    },
    {
      name: "Athen Helenikon Airport",
      city: "Athens",
      country: "Greece",
      value: "HEW",
      display: "HEW"
    },
    {
      name: "Kansai International Airport",
      city: "Osaka",
      country: "Japan",
      value: "KIX",
      display: "KIX"
    },
    {
      name: "Tagbilaran Airport",
      city: "Tagbilaran",
      country: "Philippines",
      value: "TAG",
      display: "TAG"
    },
    {
      name: "Ilulissat Airport",
      city: "Ilulissat",
      country: "Greenland",
      value: "JAV",
      display: "JAV"
    },
    {
      name: "Qasigiannguit Heliport",
      city: "Qasigiannguit",
      country: "Greenland",
      value: "JCH",
      display: "JCH"
    },
    {
      name: "Aasiaat Airport",
      city: "Aasiaat",
      country: "Greenland",
      value: "JEG",
      display: "JEG"
    },
    {
      name: "Palma De Mallorca Airport",
      city: "Palma de Mallorca",
      country: "Spain",
      value: "PMI",
      display: "PMI"
    },
    {
      name: "Darwin International Airport",
      city: "Darwin",
      country: "Australia",
      value: "DRW",
      display: "DRW"
    },
    {
      name: "Surat Thani Airport",
      city: "Surat Thani",
      country: "Thailand",
      value: "URT",
      display: "URT"
    },
    {
      name: "Talkeetna Airport",
      city: "Talkeetna",
      country: "United States",
      value: "TKA",
      display: "TKA"
    },
    {
      name: "Xewkija Heliport",
      city: "Gozo",
      country: "Malta",
      value: "GZM",
      display: "GZM"
    },
    {
      name: "Tweed New Haven Airport",
      city: "New Haven",
      country: "United States",
      value: "HVN",
      display: "HVN"
    },
    {
      name: "Asheville Regional Airport",
      city: "Asheville",
      country: "United States",
      value: "AVL",
      display: "AVL"
    },
    {
      name: "Piedmont Triad International Airport",
      city: "Greensboro",
      country: "United States",
      value: "GSO",
      display: "GSO"
    },
    {
      name: "Joe Foss Field Airport",
      city: "Sioux Falls",
      country: "United States",
      value: "FSD",
      display: "FSD"
    },
    {
      name: "Ayers Rock Connellan Airport",
      city: "Uluru",
      country: "Australia",
      value: "AYQ",
      display: "AYQ"
    },
    {
      name: "Manchester Airport",
      city: "Manchester NH",
      country: "United States",
      value: "MHT",
      display: "MHT"
    },
    {
      name: "Naples Municipal Airport",
      city: "Naples",
      country: "United States",
      value: "APF",
      display: "APF"
    },
    {
      name: "LTS Pulau Redang Airport",
      city: "Redang",
      country: "Malaysia",
      value: "RDN",
      display: "RDN"
    },
    {
      name: "Louisville International Standiford Field",
      city: "Louisville",
      country: "United States",
      value: "SDF",
      display: "SDF"
    },
    {
      name: "Charlottesville Albemarle Airport",
      city: "Charlottesville VA",
      country: "United States",
      value: "CHO",
      display: "CHO"
    },
    {
      name: "Roanoke-Blacksburg Regional Airport",
      city: "Roanoke VA",
      country: "United States",
      value: "ROA",
      display: "ROA"
    },
    {
      name: "Blue Grass Airport",
      city: "Lexington KY",
      country: "United States",
      value: "LEX",
      display: "LEX"
    },
    {
      name: "Evansville Regional Airport",
      city: "Evansville",
      country: "United States",
      value: "EVV",
      display: "EVV"
    },
    {
      name: "Albuquerque International Sunport Airport",
      city: "Albuquerque",
      country: "United States",
      value: "ABQ",
      display: "ABQ"
    },
    {
      name: "Gallatin Field",
      city: "Bozeman",
      country: "United States",
      value: "BZN",
      display: "BZN"
    },
    {
      name: "Billings Logan International Airport",
      city: "Billings",
      country: "United States",
      value: "BIL",
      display: "BIL"
    },
    {
      name: "Bert Mooney Airport",
      city: "Butte",
      country: "United States",
      value: "BTM",
      display: "BTM"
    },
    {
      name: "Cherry Capital Airport",
      city: "Traverse City",
      country: "United States",
      value: "TVC",
      display: "TVC"
    },
    {
      name: "Mundo Maya International Airport",
      city: "Flores",
      country: "Guatemala",
      value: "FRS",
      display: "FRS"
    },
    {
      name: "Hancock County-Bar Harbor Airport",
      city: "Bar Harbor",
      country: "United States",
      value: "BHB",
      display: "BHB"
    },
    {
      name: "Knox County Regional Airport",
      city: "Rockland",
      country: "United States",
      value: "RKD",
      display: "RKD"
    },
    {
      name: "Jackson Hole Airport",
      city: "Jacksn Hole",
      country: "United States",
      value: "JAC",
      display: "JAC"
    },
    {
      name: "Chicago Rockford International Airport",
      city: "Rockford",
      country: "United States",
      value: "RFD",
      display: "RFD"
    },
    {
      name: "Domodedovo International Airport",
      city: "Moscow",
      country: "Russia",
      value: "DME",
      display: "DME"
    },
    {
      name: "Sanya Phoenix International Airport",
      city: "Sanya",
      country: "China",
      value: "SYX",
      display: "SYX"
    },
    {
      name: "Milford Sound Airport",
      city: "Milford Sound",
      country: "New Zealand",
      value: "MFN",
      display: "MFN"
    },
    {
      name: "Lijiang Airport",
      city: "Lijiang",
      country: "China",
      value: "LJG",
      display: "LJG"
    },
    {
      name: "Greenville Spartanburg International Airport",
      city: "Greenville",
      country: "United States",
      value: "GSP",
      display: "GSP"
    },
    {
      name: "Central Illinois Regional Airport at Bloomington-Normal",
      city: "Bloomington",
      country: "United States",
      value: "BMI",
      display: "BMI"
    },
    {
      name: "Gulfport Biloxi International Airport",
      city: "Gulfport",
      country: "United States",
      value: "GPT",
      display: "GPT"
    },
    {
      name: "Kalamazoo Battle Creek International Airport",
      city: "Kalamazoo",
      country: "United States",
      value: "AZO",
      display: "AZO"
    },
    {
      name: "Toledo Express Airport",
      city: "Toledo",
      country: "United States",
      value: "TOL",
      display: "TOL"
    },
    {
      name: "Fort Wayne International Airport",
      city: "Fort Wayne",
      country: "United States",
      value: "FWA",
      display: "FWA"
    },
    {
      name: "Decatur Airport",
      city: "Decatur",
      country: "United States",
      value: "DEC",
      display: "DEC"
    },
    {
      name: "The Eastern Iowa Airport",
      city: "Cedar Rapids",
      country: "United States",
      value: "CID",
      display: "CID"
    },
    {
      name: "La Crosse Municipal Airport",
      city: "La Crosse",
      country: "United States",
      value: "LSE",
      display: "LSE"
    },
    {
      name: "Central Wisconsin Airport",
      city: "Wassau",
      country: "United States",
      value: "CWA",
      display: "CWA"
    },
    {
      name: "General Wayne A. Downing Peoria International Airport",
      city: "Peoria",
      country: "United States",
      value: "PIA",
      display: "PIA"
    },
    {
      name: "Appleton International Airport",
      city: "Appleton",
      country: "United States",
      value: "ATW",
      display: "ATW"
    },
    {
      name: "Rochester International Airport",
      city: "Rochester",
      country: "United States",
      value: "RST",
      display: "RST"
    },
    {
      name: "University of Illinois Willard Airport",
      city: "Champaign",
      country: "United States",
      value: "CMI",
      display: "CMI"
    },
    {
      name: "Manhattan Regional Airport",
      city: "Manhattan",
      country: "United States",
      value: "MHK",
      display: "MHK"
    },
    {
      name: "Kingscote Airport",
      city: "Kingscote",
      country: "Australia",
      value: "KGC",
      display: "KGC"
    },
    {
      name: "Hervey Bay Airport",
      city: "Hervey Bay",
      country: "Australia",
      value: "HVB",
      display: "HVB"
    },
    {
      name: "Dali Airport",
      city: "Dali",
      country: "China",
      value: "DLU",
      display: "DLU"
    },
    {
      name: "Mulu Airport",
      city: "Mulu",
      country: "Malaysia",
      value: "MZV",
      display: "MZV"
    },
    {
      name: "Sharm El Sheikh International Airport",
      city: "Sharm El Sheikh",
      country: "Egypt",
      value: "SSH",
      display: "SSH"
    },
    {
      name: "Venango Regional Airport",
      city: "Franklin",
      country: "United States",
      value: "FKL",
      display: "FKL"
    },
    {
      name: "Jomo Kenyatta International Airport",
      city: "Nairobi",
      country: "Kenya",
      value: "NBO",
      display: "NBO"
    },
    {
      name: "Seronera Airport",
      city: "Seronera",
      country: "Tanzania",
      value: "SEU",
      display: "SEU"
    },
    {
      name: "El Calafate Airport",
      city: "El Calafate",
      country: "Argentina",
      value: "FTE",
      display: "FTE"
    },
    {
      name: "Armidale Airport",
      city: "Armidale",
      country: "Australia",
      value: "ARM",
      display: "ARM"
    },
    {
      name: "Grand Junction Regional Airport",
      city: "Grand Junction",
      country: "United States",
      value: "GJT",
      display: "GJT"
    },
    {
      name: "St George Municipal Airport",
      city: "Saint George",
      country: "United States",
      value: "SGU",
      display: "SGU"
    },
    {
      name: "David Wayne Hooks Memorial Airport",
      city: "Houston",
      country: "United States",
      value: "DWH",
      display: "DWH"
    },
    {
      name: "Port O'Connor Private Airport",
      city: "Port O Connor",
      country: "United States",
      value: "S46",
      display: "S46"
    },
    {
      name: "Sarasota Bradenton International Airport",
      city: "Sarasota",
      country: "United States",
      value: "SRQ",
      display: "SRQ"
    },
    {
      name: "L.F. Wade International International Airport",
      city: "Bermuda",
      country: "Bermuda",
      value: "BDA",
      display: "BDA"
    },
    {
      name: "Van Nuys Airport",
      city: "Van Nuys",
      country: "United States",
      value: "VNY",
      display: "VNY"
    },
    {
      name: "Quad City International Airport",
      city: "Moline",
      country: "United States",
      value: "MLI",
      display: "MLI"
    },
    {
      name: "Panama City-Bay Co International Airport",
      city: "Panama City",
      country: "United States",
      value: "PFN",
      display: "PFN"
    },
    {
      name: "Honiara International Airport",
      city: "Honiara",
      country: "Solomon Islands",
      value: "HIR",
      display: "HIR"
    },
    {
      name: "Faa'a International Airport",
      city: "Papeete",
      country: "French Polynesia",
      value: "PPT",
      display: "PPT"
    },
    {
      name: "Nauru International Airport",
      city: "Nauru",
      country: "Nauru",
      value: "INU",
      display: "INU"
    },
    {
      name: "Funafuti International Airport",
      city: "Funafuti",
      country: "Tuvalu",
      value: "FUN",
      display: "FUN"
    },
    {
      name: "Tolmachevo Airport",
      city: "Novosibirsk",
      country: "Russia",
      value: "OVB",
      display: "OVB"
    },
    {
      name: "Xieng Khouang Airport",
      city: "Phon Savan",
      country: "Laos",
      value: "XKH",
      display: "XKH"
    },
    {
      name: "Bismarck Municipal Airport",
      city: "Bismarck",
      country: "United States",
      value: "BIS",
      display: "BIS"
    },
    {
      name: "Telluride Regional Airport",
      city: "Telluride",
      country: "United States",
      value: "TEX",
      display: "TEX"
    },
    {
      name: "Yinchuan Airport",
      city: "Yinchuan",
      country: "China",
      value: "INC",
      display: "INC"
    },
    {
      name: "Mae Hong Son Airport",
      city: "Mae Hong Son",
      country: "Thailand",
      value: "HGN",
      display: "HGN"
    },
    {
      name: "Rapid City Regional Airport",
      city: "Rapid City",
      country: "United States",
      value: "RAP",
      display: "RAP"
    },
    {
      name: "Mc Clellan-Palomar Airport",
      city: "Carlsbad",
      country: "United States",
      value: "CLD",
      display: "CLD"
    },
    {
      name: "Bishop International Airport",
      city: "Flint",
      country: "United States",
      value: "FNT",
      display: "FNT"
    },
    {
      name: "Francisco Bangoy International Airport",
      city: "Davao",
      country: "Philippines",
      value: "DVO",
      display: "DVO"
    },
    {
      name: "Madeira Airport",
      city: "Funchal",
      country: "Portugal",
      value: "FNC",
      display: "FNC"
    },
    {
      name: "Maestro Wilson Fonseca Airport",
      city: "Santarem",
      country: "Brazil",
      value: "STM",
      display: "STM"
    },
    {
      name: "Sihanoukville International Airport",
      city: "Sihanoukville",
      country: "Cambodia",
      value: "KOS",
      display: "KOS"
    },
    {
      name: "Ekati Airport",
      city: "Ekati",
      country: "Canada",
      value: "YOA",
      display: "YOA"
    },
    {
      name: "Hawke's Bay Airport",
      city: "NAPIER",
      country: "New Zealand",
      value: "NPE",
      display: "NPE"
    },
    {
      name: "Levuka Airfield",
      city: "Levuka",
      country: "Fiji",
      value: "LEV",
      display: "LEV"
    },
    {
      name: "Lhasa Gonggar Airport",
      city: "Lhasa",
      country: "China",
      value: "LXA",
      display: "LXA"
    },
    {
      name: "Redding Municipal Airport",
      city: "Redding",
      country: "United States",
      value: "RDD",
      display: "RDD"
    },
    {
      name: "Mahlon Sweet Field",
      city: "Eugene",
      country: "United States",
      value: "EUG",
      display: "EUG"
    },
    {
      name: "Idaho Falls Regional Airport",
      city: "Idaho Falls",
      country: "United States",
      value: "IDA",
      display: "IDA"
    },
    {
      name: "Rogue Valley International Medford Airport",
      city: "Medford",
      country: "United States",
      value: "MFR",
      display: "MFR"
    },
    {
      name: "Kaikoura Airport",
      city: "Kaikoura",
      country: "New Zealand",
      value: "KBZ",
      display: "KBZ"
    },
    {
      name: "Roberts Field",
      city: "Redmond-Bend",
      country: "United States",
      value: "RDM",
      display: "RDM"
    },
    {
      name: "Picton Aerodrome",
      city: "Picton",
      country: "New Zealand",
      value: "PCN",
      display: "PCN"
    },
    {
      name: "Hosea Kutako International Airport",
      city: "Windhoek",
      country: "Namibia",
      value: "WDH",
      display: "WDH"
    },
    {
      name: "Victoria Harbour Seaplane Base",
      city: "Victoria",
      country: "Canada",
      value: "YWH",
      display: "YWH"
    },
    {
      name: "Yaoqiang Airport",
      city: "Jinan",
      country: "China",
      value: "TNA",
      display: "TNA"
    },
    {
      name: "Changzhou Benniu Airport",
      city: "Changzhou",
      country: "China",
      value: "CZX",
      display: "CZX"
    },
    {
      name: "Yibin Caiba Airport",
      city: "Yibin",
      country: "China",
      value: "YBP",
      display: "YBP"
    },
    {
      name: "Roshchino International Airport",
      city: "Tyumen",
      country: "Russia",
      value: "TJM",
      display: "TJM"
    },
    {
      name: "Akron Canton Regional Airport",
      city: "Akron",
      country: "United States",
      value: "CAK",
      display: "CAK"
    },
    {
      name: "Huntsville International Carl T Jones Field",
      city: "Huntsville",
      country: "United States",
      value: "HSV",
      display: "HSV"
    },
    {
      name: "Mid Ohio Valley Regional Airport",
      city: "PARKERSBURG",
      country: "United States",
      value: "PKB",
      display: "PKB"
    },
    {
      name: "Montgomery Regional (Dannelly Field) Airport",
      city: "MONTGOMERY",
      country: "United States",
      value: "MGM",
      display: "MGM"
    },
    {
      name: "Tri Cities Regional Tn Va Airport",
      city: "BRISTOL",
      country: "United States",
      value: "TRI",
      display: "TRI"
    },
    {
      name: "Barkley Regional Airport",
      city: "PADUCAH",
      country: "United States",
      value: "PAH",
      display: "PAH"
    },
    {
      name: "Djibouti-Ambouli Airport",
      city: "Djibouti",
      country: "Djibouti",
      value: "JIB",
      display: "JIB"
    },
    {
      name: "Haikou Meilan International Airport",
      city: "Haikou",
      country: "China",
      value: "HAK",
      display: "HAK"
    },
    {
      name: "Mafia Island Airport",
      city: "Mafia Island",
      country: "Tanzania",
      value: "MFA",
      display: "MFA"
    },
    {
      name: "Page Municipal Airport",
      city: "Page",
      country: "United States",
      value: "PGA",
      display: "PGA"
    },
    {
      name: "Utila Airport",
      city: "Utila",
      country: "Honduras",
      value: "UII",
      display: "UII"
    },
    {
      name: "Glacier Park International Airport",
      city: "Kalispell",
      country: "United States",
      value: "FCA",
      display: "FCA"
    },
    {
      name: "MBS International Airport",
      city: "Saginaw",
      country: "United States",
      value: "MBS",
      display: "MBS"
    },
    {
      name: "Greater Binghamton/Edwin A Link field",
      city: "Binghamton",
      country: "United States",
      value: "BGM",
      display: "BGM"
    },
    {
      name: "Baghdad International Airport",
      city: "Baghdad",
      country: "Iraq",
      value: "BGW",
      display: "BGW"
    },
    {
      name: "Nan Airport",
      city: "Nan",
      country: "Thailand",
      value: "NNT",
      display: "NNT"
    },
    {
      name: "Roi Et Airport",
      city: "Roi Et",
      country: "Thailand",
      value: "ROI",
      display: "ROI"
    },
    {
      name: "Buri Ram Airport",
      city: "Buri Ram",
      country: "Thailand",
      value: "BFV",
      display: "BFV"
    },
    {
      name: "Trat Airport",
      city: "Trat",
      country: "Thailand",
      value: "TDX",
      display: "TDX"
    },
    {
      name: "Blythe Airport",
      city: "Blythe",
      country: "United States",
      value: "BLH",
      display: "BLH"
    },
    {
      name: "Clark International Airport",
      city: "Angeles City",
      country: "Philippines",
      value: "CRK",
      display: "CRK"
    },
    {
      name: "Sandakan Airport",
      city: "Sandakan",
      country: "Malaysia",
      value: "SDK",
      display: "SDK"
    },
    {
      name: "Luang Namtha Airport",
      city: "Luang Namtha",
      country: "Laos",
      value: "LXG",
      display: "LXG"
    },
    {
      name: "Oudomsay Airport",
      city: "Muang Xay",
      country: "Laos",
      value: "ODY",
      display: "ODY"
    },
    {
      name: "Taoxian Airport",
      city: "Shenyang",
      country: "China",
      value: "SHE",
      display: "SHE"
    },
    {
      name: "John A. Osborne Airport",
      city: "Geralds",
      country: "Montserrat",
      value: "MNI",
      display: "MNI"
    },
    {
      name: "Petersburg James A Johnson Airport",
      city: "Petersburg",
      country: "United States",
      value: "PSG",
      display: "PSG"
    },
    {
      name: "Luoyang Airport",
      city: "Luoyang",
      country: "China",
      value: "LYA",
      display: "LYA"
    },
    {
      name: "Xuzhou Guanyin Airport",
      city: "Xuzhou",
      country: "China",
      value: "XUZ",
      display: "XUZ"
    },
    {
      name: "Magway Airport",
      city: "Magwe",
      country: "Burma",
      value: "MWQ",
      display: "MWQ"
    },
    {
      name: "Kanti Airport",
      city: "Khamti",
      country: "Burma",
      value: "KHM",
      display: "KHM"
    },
    {
      name: "Lien Khuong Airport",
      city: "Dalat",
      country: "Vietnam",
      value: "DLI",
      display: "DLI"
    },
    {
      name: "Rach Gia Airport",
      city: "Rach Gia",
      country: "Vietnam",
      value: "VKG",
      display: "VKG"
    },
    {
      name: "Ca Mau Airport",
      city: "Ca Mau",
      country: "Vietnam",
      value: "CAH",
      display: "CAH"
    },
    {
      name: "Chu Lai International Airport",
      city: "Chu Lai",
      country: "Vietnam",
      value: "VCL",
      display: "VCL"
    },
    {
      name: "Dong Tac Airport",
      city: "Tuy Hoa",
      country: "Vietnam",
      value: "TBB",
      display: "TBB"
    },
    {
      name: "Mae Hong Son Airport",
      city: "Pai",
      country: "Thailand",
      value: "PYY",
      display: "PYY"
    },
    {
      name: "Bol Airport",
      city: "Brac",
      country: "Croatia",
      value: "BWK",
      display: "BWK"
    },
    {
      name: "Yaounde Nsimalen International Airport",
      city: "Yaounde",
      country: "Cameroon",
      value: "NSI",
      display: "NSI"
    },
    {
      name: "Conakry International Airport",
      city: "Conakry",
      country: "Guinea",
      value: "CKY",
      display: "CKY"
    },
    {
      name: "Aachen-Merzbruck Airport",
      city: "Aachen",
      country: "Germany",
      value: "AAH",
      display: "AAH"
    },
    {
      name: "Karlsruhe Baden-Baden Airport",
      city: "Karlsruhe/Baden-Baden",
      country: "Germany",
      value: "FKB",
      display: "FKB"
    },
    {
      name: "Orlando Sanford International Airport",
      city: "Sanford",
      country: "United States",
      value: "SFB",
      display: "SFB"
    },
    {
      name: "John Murtha Johnstown Cambria County Airport",
      city: "Johnstown",
      country: "United States",
      value: "JST",
      display: "JST"
    },
    {
      name: "Lukla Airport",
      city: "Lukla",
      country: "Nepal",
      value: "LUA",
      display: "LUA"
    },
    {
      name: "Bhojpur Airport",
      city: "Bhojpur",
      country: "Nepal",
      value: "BHP",
      display: "BHP"
    },
    {
      name: "Lamidanda Airport",
      city: "Lamidanda",
      country: "Nepal",
      value: "LDN",
      display: "LDN"
    },
    {
      name: "Jomsom Airport",
      city: "Jomsom",
      country: "Nepal",
      value: "JMO",
      display: "JMO"
    },
    {
      name: "Manang Airport",
      city: "Manang",
      country: "Nepal",
      value: "NGX",
      display: "NGX"
    },
    {
      name: "Phaplu Airport",
      city: "Phaplu",
      country: "Nepal",
      value: "PPL",
      display: "PPL"
    },
    {
      name: "Rumjatar Airport",
      city: "Rumjatar",
      country: "Nepal",
      value: "RUM",
      display: "RUM"
    },
    {
      name: "Tulsipur Airport",
      city: "Dang",
      country: "Nepal",
      value: "DNP",
      display: "DNP"
    },
    {
      name: "Rukumkot Airport",
      city: "Rukumkot",
      country: "Nepal",
      value: "RUK",
      display: "RUK"
    },
    {
      name: "Jumla Airport",
      city: "Jumla",
      country: "Nepal",
      value: "JUM",
      display: "JUM"
    },
    {
      name: "Taplejung Airport",
      city: "Taplejung",
      country: "Nepal",
      value: "TPJ",
      display: "TPJ"
    },
    {
      name: "Tumling Tar Airport",
      city: "Tumling Tar",
      country: "Nepal",
      value: "TMI",
      display: "TMI"
    },
    {
      name: "Surkhet Airport",
      city: "Surkhet",
      country: "Nepal",
      value: "SKH",
      display: "SKH"
    },
    {
      name: "Simikot Airport",
      city: "Simikot",
      country: "Nepal",
      value: "IMK",
      display: "IMK"
    },
    {
      name: "Dolpa Airport",
      city: "Dolpa",
      country: "Nepal",
      value: "DOP",
      display: "DOP"
    },
    {
      name: "Bajhang Airport",
      city: "Bajhang",
      country: "Nepal",
      value: "BJH",
      display: "BJH"
    },
    {
      name: "Dhangarhi Airport",
      city: "Dhangarhi",
      country: "Nepal",
      value: "DHI",
      display: "DHI"
    },
    {
      name: "Muan International Airport",
      city: "Muan",
      country: "South Korea",
      value: "MWX",
      display: "MWX"
    },
    {
      name: "Astypalaia Airport",
      city: "Astypalaia",
      country: "Greece",
      value: "JTY",
      display: "JTY"
    },
    {
      name: "Ikaria Airport",
      city: "Ikaria",
      country: "Greece",
      value: "JIK",
      display: "JIK"
    },
    {
      name: "Kalymnos Airport",
      city: "Kalymnos",
      country: "Greece",
      value: "JKL",
      display: "JKL"
    },
    {
      name: "Milos Airport",
      city: "Milos",
      country: "Greece",
      value: "MLO",
      display: "MLO"
    },
    {
      name: "Naxos Airport",
      city: "Cyclades Islands",
      country: "Greece",
      value: "JNX",
      display: "JNX"
    },
    {
      name: "Paros Airport",
      city: "Paros",
      country: "Greece",
      value: "PAS",
      display: "PAS"
    },
    {
      name: "Kastelorizo Airport",
      city: "Kastelorizo",
      country: "Greece",
      value: "KZS",
      display: "KZS"
    },
    {
      name: "Marsa Alam International Airport",
      city: "Marsa Alam",
      country: "Egypt",
      value: "RMF",
      display: "RMF"
    },
    {
      name: "Weeze Airport",
      city: "Weeze",
      country: "Germany",
      value: "NRN",
      display: "NRN"
    },
    {
      name: "Francisco B. Reyes Airport",
      city: "Busuanga",
      country: "Philippines",
      value: "USU",
      display: "USU"
    },
    {
      name: "Bancasi Airport",
      city: "Butuan",
      country: "Philippines",
      value: "BXU",
      display: "BXU"
    },
    {
      name: "Dipolog Airport",
      city: "Dipolog",
      country: "Philippines",
      value: "DPL",
      display: "DPL"
    },
    {
      name: "Laoag International Airport",
      city: "Laoag",
      country: "Philippines",
      value: "LAO",
      display: "LAO"
    },
    {
      name: "Legazpi City International Airport",
      city: "Legazpi",
      country: "Philippines",
      value: "LGP",
      display: "LGP"
    },
    {
      name: "Labo Airport",
      city: "Ozamis",
      country: "Philippines",
      value: "OZC",
      display: "OZC"
    },
    {
      name: "Mactan Cebu International Airport",
      city: "Cebu",
      country: "Philippines",
      value: "CEB",
      display: "CEB"
    },
    {
      name: "Norden-Norddeich Airport",
      city: "Norden",
      country: "Germany",
      value: "NOE",
      display: "NOE"
    },
    {
      name: "Juist Airport",
      city: "Juist",
      country: "Germany",
      value: "JUI",
      display: "JUI"
    },
    {
      name: "Porto Seguro Airport",
      city: "Porto Seguro",
      country: "Brazil",
      value: "BPS",
      display: "BPS"
    },
    {
      name: "Brigadeiro Lysias Rodrigues Airport",
      city: "Palmas",
      country: "Brazil",
      value: "PMW",
      display: "PMW"
    },
    {
      name: "Nelson Ribeiro Guimaraes Airport",
      city: "Caldas Novas",
      country: "Brazil",
      value: "CLV",
      display: "CLV"
    },
    {
      name: "Missoula International Airport",
      city: "Missoula",
      country: "United States",
      value: "MSO",
      display: "MSO"
    },
    {
      name: "Blackall Airport",
      city: "Blackall",
      country: "Australia",
      value: "BKQ",
      display: "BKQ"
    },
    {
      name: "Bundaberg Airport",
      city: "Bundaberg",
      country: "Australia",
      value: "BDB",
      display: "BDB"
    },
    {
      name: "Grand Canyon National Park Airport",
      city: "Grand Canyon",
      country: "United States",
      value: "GCN",
      display: "GCN"
    },
    {
      name: "Sugar Land Regional Airport",
      city: "Sugar Land",
      country: "United States",
      value: "SGR",
      display: "SGR"
    },
    {
      name: "Hayman Island Heliport",
      city: "Hayman Island",
      country: "Australia",
      value: "HIS",
      display: "HIS"
    },
    {
      name: "Centennial Airport",
      city: "Denver",
      country: "United States",
      value: "APA",
      display: "APA"
    },
    {
      name: "Clovis Municipal Airport",
      city: "Clovis",
      country: "United States",
      value: "CVN",
      display: "CVN"
    },
    {
      name: "Fort Stockton Pecos County Airport",
      city: "Fort Stockton",
      country: "United States",
      value: "FST",
      display: "FST"
    },
    {
      name: "Las Vegas Municipal Airport",
      city: "Las Vegas",
      country: "United States",
      value: "LVS",
      display: "LVS"
    },
    {
      name: "West Houston Airport",
      city: "Houston",
      country: "United States",
      value: "IWS",
      display: "IWS"
    },
    {
      name: "La Junta Municipal Airport",
      city: "La Junta",
      country: "United States",
      value: "LHX",
      display: "LHX"
    },
    {
      name: "Las Cruces International Airport",
      city: "Las Cruces",
      country: "United States",
      value: "LRU",
      display: "LRU"
    },
    {
      name: "Stephens County Airport",
      city: "Breckenridge",
      country: "United States",
      value: "BKD",
      display: "BKD"
    },
    {
      name: "Draughon Miller Central Texas Regional Airport",
      city: "Temple",
      country: "United States",
      value: "TPL",
      display: "TPL"
    },
    {
      name: "Ozona Municipal Airport",
      city: "Ozona",
      country: "United States",
      value: "OZA",
      display: "OZA"
    },
    {
      name: "Kaadedhdhoo Airport",
      city: "Kaadedhdhoo",
      country: "Maldives",
      value: "KDM",
      display: "KDM"
    },
    {
      name: "Aklavik/Freddie Carmichael Airport",
      city: "Aklavik",
      country: "Canada",
      value: "LAK",
      display: "LAK"
    },
    {
      name: "Deline Airport",
      city: "Deline",
      country: "Canada",
      value: "YWJ",
      display: "YWJ"
    },
    {
      name: "Tulita Airport",
      city: "Tulita",
      country: "Canada",
      value: "ZFN",
      display: "ZFN"
    },
    {
      name: "Fort Good Hope Airport",
      city: "Fort Good Hope",
      country: "Canada",
      value: "YGH",
      display: "YGH"
    },
    {
      name: "Tanna Airport",
      city: "Tanna",
      country: "Vanuatu",
      value: "TAH",
      display: "TAH"
    },
    {
      name: "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
      city: "Paulatuk",
      country: "Canada",
      value: "YPC",
      display: "YPC"
    },
    {
      name: "El Trompillo Airport",
      city: "Santa Cruz",
      country: "Bolivia",
      value: "SRZ",
      display: "SRZ"
    },
    {
      name: "Juancho E. Yrausquin Airport",
      city: "Saba",
      country: "Netherlands Antilles",
      value: "SAB",
      display: "SAB"
    },
    {
      name: "Eagle County Regional Airport",
      city: "Vail",
      country: "United States",
      value: "EGE",
      display: "EGE"
    },
    {
      name: "Stokmarknes Skagen Airport",
      city: "Stokmarknes",
      country: "Norway",
      value: "SKN",
      display: "SKN"
    },
    {
      name: "Cuyahoga County Airport",
      city: "Richmond Heights",
      country: "United States",
      value: "CGF",
      display: "CGF"
    },
    {
      name: "Mansfield Lahm Regional Airport",
      city: "Mansfield",
      country: "United States",
      value: "MFD",
      display: "MFD"
    },
    {
      name: "Columbus Metropolitan Airport",
      city: "Columbus",
      country: "United States",
      value: "CSG",
      display: "CSG"
    },
    {
      name: "Lawton Fort Sill Regional Airport",
      city: "Lawton",
      country: "United States",
      value: "LAW",
      display: "LAW"
    },
    {
      name: "Fort Collins Loveland Municipal Airport",
      city: "Fort Collins",
      country: "United States",
      value: "FNL",
      display: "FNL"
    },
    {
      name: "Flagstaff Pulliam Airport",
      city: "Flagstaff",
      country: "United States",
      value: "FLG",
      display: "FLG"
    },
    {
      name: "Lake Tahoe Airport",
      city: "South Lake Tahoe",
      country: "United States",
      value: "TVL",
      display: "TVL"
    },
    {
      name: "Joslin Field Magic Valley Regional Airport",
      city: "Twin Falls",
      country: "United States",
      value: "TWF",
      display: "TWF"
    },
    {
      name: "Martha's Vineyard Airport",
      city: "Vineyard Haven MA",
      country: "United States",
      value: "MVY",
      display: "MVY"
    },
    {
      name: "Concord Municipal Airport",
      city: "Concord NH",
      country: "United States",
      value: "CON",
      display: "CON"
    },
    {
      name: "Groton New London Airport",
      city: "Groton CT",
      country: "United States",
      value: "GON",
      display: "GON"
    },
    {
      name: "St Cloud Regional Airport",
      city: "Saint Cloud",
      country: "United States",
      value: "STC",
      display: "STC"
    },
    {
      name: "Qinhuangdao Beidaihe Airport",
      city: "Bagan",
      country: "Burma",
      value: "BPE",
      display: "BPE"
    },
    {
      name: "Golden Triangle Regional Airport",
      city: "Columbus Mississippi",
      country: "United States",
      value: "GTR",
      display: "GTR"
    },
    {
      name: "Nizhny Novgorod Strigino International Airport",
      city: "Nizhniy Novgorod",
      country: "Russia",
      value: "GOJ",
      display: "GOJ"
    },
    {
      name: "Bowerman Airport",
      city: "Hoquiam",
      country: "United States",
      value: "HQM",
      display: "HQM"
    },
    {
      name: "Erie International Tom Ridge Field",
      city: "Erie",
      country: "United States",
      value: "ERI",
      display: "ERI"
    },
    {
      name: "Barnstable Municipal Boardman Polando Field",
      city: "Barnstable",
      country: "United States",
      value: "HYA",
      display: "HYA"
    },
    {
      name: "Sedona Airport",
      city: "Sedona",
      country: "United States",
      value: "SDX",
      display: "SDX"
    },
    {
      name: "Morgantown Municipal Walter L. Bill Hart Field",
      city: "Morgantown",
      country: "United States",
      value: "MGW",
      display: "MGW"
    },
    {
      name: "Yeager Airport",
      city: "Charleston",
      country: "United States",
      value: "CRW",
      display: "CRW"
    },
    {
      name: "Wilkes Barre Scranton International Airport",
      city: "Scranton",
      country: "United States",
      value: "AVP",
      display: "AVP"
    },
    {
      name: "Bemidji Regional Airport",
      city: "Bemidji",
      country: "United States",
      value: "BJI",
      display: "BJI"
    },
    {
      name: "Thangool Airport",
      city: "Biloela",
      country: "Australia",
      value: "THG",
      display: "THG"
    },
    {
      name: "Fagali'i Airport",
      city: "Apia",
      country: "Samoa",
      value: "FGI",
      display: "FGI"
    },
    {
      name: "Ballina Byron Gateway Airport",
      city: "Ballina Byron Bay",
      country: "Australia",
      value: "BNK",
      display: "BNK"
    },
    {
      name: "Hector International Airport",
      city: "Fargo",
      country: "United States",
      value: "FAR",
      display: "FAR"
    },
    {
      name: "Charles B. Wheeler Downtown Airport",
      city: "Kansas City",
      country: "United States",
      value: "MKC",
      display: "MKC"
    },
    {
      name: "Ratanakiri Airport",
      city: "Ratanakiri",
      country: "Cambodia",
      value: "RBE",
      display: "RBE"
    },
    {
      name: "Gillette Campbell County Airport",
      city: "Gillette",
      country: "United States",
      value: "GCC",
      display: "GCC"
    },
    {
      name: "Bogashevo Airport",
      city: "Tomsk",
      country: "Russia",
      value: "TOF",
      display: "TOF"
    },
    {
      name: "Phetchabun Airport",
      city: "Phetchabun",
      country: "Thailand",
      value: "PHY",
      display: "PHY"
    },
    {
      name: "Chumphon Airport",
      city: "Chumphon",
      country: "Thailand",
      value: "CJM",
      display: "CJM"
    },
    {
      name: "Jiuzhai Huanglong Airport",
      city: "Jiuzhaigou",
      country: "China",
      value: "JZH",
      display: "JZH"
    },
    {
      name: "Jieyang Chaoshan International Airport",
      city: "Shantou",
      country: "China",
      value: "SWA",
      display: "SWA"
    },
    {
      name: "Cheddi Jagan International Airport",
      city: "Georgetown",
      country: "Guyana",
      value: "GEO",
      display: "GEO"
    },
    {
      name: "Guarani International Airport",
      city: "Ciudad del Este",
      country: "Paraguay",
      value: "AGT",
      display: "AGT"
    },
    {
      name: "Ogle Airport",
      city: "Georgetown",
      country: "Guyana",
      value: "OGL",
      display: "OGL"
    },
    {
      name: "Kaieteur International Airport",
      city: "Kaieteur",
      country: "Guyana",
      value: "KAI",
      display: "KAI"
    },
    {
      name: "Dunhuang Airport",
      city: "Dunhuang",
      country: "China",
      value: "DNH",
      display: "DNH"
    },
    {
      name: "Ancona Falconara Airport",
      city: "Ancona",
      country: "Italy",
      value: "AOI",
      display: "AOI"
    },
    {
      name: "Chamonate Airport",
      city: "Copiapo",
      country: "Chile",
      value: "CPO",
      display: "CPO"
    },
    {
      name: "Taba International Airport",
      city: "Taba",
      country: "Egypt",
      value: "TCP",
      display: "TCP"
    },
    {
      name: "Edward Bodden Airfield",
      city: "Little Cayman",
      country: "Cayman Islands",
      value: "LYB",
      display: "LYB"
    },
    {
      name: "Milas Bodrum International Airport",
      city: "Bodrum",
      country: "Turkey",
      value: "BJV",
      display: "BJV"
    },
    {
      name: "Tabarka 7 Novembre Airport",
      city: "Tabarka",
      country: "Tunisia",
      value: "TBJ",
      display: "TBJ"
    },
    {
      name: "Sabiha Gokcen International Airport",
      city: "Istanbul",
      country: "Turkey",
      value: "SAW",
      display: "SAW"
    },
    {
      name: "University Park Airport",
      city: "State College Pennsylvania",
      country: "United States",
      value: "SCE",
      display: "SCE"
    },
    {
      name: "Broome International Airport",
      city: "Broome",
      country: "Australia",
      value: "BME",
      display: "BME"
    },
    {
      name: "Newcastle Airport",
      city: "Newcastle",
      country: "Australia",
      value: "NTL",
      display: "NTL"
    },
    {
      name: "Klagenfurt Airport",
      city: "Klagenfurt",
      country: "Austria",
      value: "KLU",
      display: "KLU"
    },
    {
      name: "Hammerfest Airport",
      city: "Hammerfest",
      country: "Norway",
      value: "HFT",
      display: "HFT"
    },
    {
      name: "Valan Airport",
      city: "Honningsvag",
      country: "Norway",
      value: "HVG",
      display: "HVG"
    },
    {
      name: "Mehamn Airport",
      city: "Mehamn",
      country: "Norway",
      value: "MEH",
      display: "MEH"
    },
    {
      name: "Vadso Airport",
      city: "Vadso",
      country: "Norway",
      value: "VDS",
      display: "VDS"
    },
    {
      name: "Imam Khomeini International Airport",
      city: "Tehran",
      country: "Iran",
      value: "IKA",
      display: "IKA"
    },
    {
      name: "Mashhad International Airport",
      city: "Mashhad",
      country: "Iran",
      value: "MHD",
      display: "MHD"
    },
    {
      name: "Ust-Ilimsk Airport",
      city: "Ust Ilimsk",
      country: "Russia",
      value: "UIK",
      display: "UIK"
    },
    {
      name: "Key Field",
      city: "Meridian",
      country: "United States",
      value: "MEI",
      display: "MEI"
    },
    {
      name: "Abraham Lincoln Capital Airport",
      city: "Springfield",
      country: "United States",
      value: "SPI",
      display: "SPI"
    },
    {
      name: "Cortez Municipal Airport",
      city: "Cortez",
      country: "United States",
      value: "CEZ",
      display: "CEZ"
    },
    {
      name: "Yampa Valley Airport",
      city: "Hayden",
      country: "United States",
      value: "HDN",
      display: "HDN"
    },
    {
      name: "Gallup Municipal Airport",
      city: "Gallup",
      country: "United States",
      value: "GUP",
      display: "GUP"
    },
    {
      name: "Liberal Mid-America Regional Airport",
      city: "Liberal",
      country: "United States",
      value: "LBL",
      display: "LBL"
    },
    {
      name: "Lamar Municipal Airport",
      city: "Lamar",
      country: "United States",
      value: "LAA",
      display: "LAA"
    },
    {
      name: "Renner Field-Goodland Municipal Airport",
      city: "Goodland",
      country: "United States",
      value: "GLD",
      display: "GLD"
    },
    {
      name: "Yellowstone Regional Airport",
      city: "Cody",
      country: "United States",
      value: "COD",
      display: "COD"
    },
    {
      name: "Orsta-Volda Airport, Hovden",
      city: "Orsta-Volda",
      country: "Norway",
      value: "HOV",
      display: "HOV"
    },
    {
      name: "St. Mary's Airport",
      city: "St Mary s",
      country: "United Kingdom",
      value: "ISC",
      display: "ISC"
    },
    {
      name: "Springfield Branson National Airport",
      city: "Springfield",
      country: "United States",
      value: "SGF",
      display: "SGF"
    },
    {
      name: "Narvik Framnes Airport",
      city: "Narvik",
      country: "Norway",
      value: "NVK",
      display: "NVK"
    },
    {
      name: "Berlevag Airport",
      city: "Berlevag",
      country: "Norway",
      value: "BVG",
      display: "BVG"
    },
    {
      name: "Torp, Sandefjord Airport",
      city: "Oslo",
      country: "Norway",
      value: "TRF",
      display: "TRF"
    },
    {
      name: "Norilsk-Alykel Airport",
      city: "Norilsk",
      country: "Russia",
      value: "NSK",
      display: "NSK"
    },
    {
      name: "Anapa Vityazevo Airport",
      city: "Anapa",
      country: "Russia",
      value: "AAQ",
      display: "AAQ"
    },
    {
      name: "Joplin Regional Airport",
      city: "Joplin",
      country: "United States",
      value: "JLN",
      display: "JLN"
    },
    {
      name: "Lehigh Valley International Airport",
      city: "Allentown",
      country: "United States",
      value: "ABE",
      display: "ABE"
    },
    {
      name: "Northwest Arkansas Regional Airport",
      city: "Bentonville",
      country: "United States",
      value: "XNA",
      display: "XNA"
    },
    {
      name: "Atyrau Airport",
      city: "Atyrau",
      country: "Kazakhstan",
      value: "GUW",
      display: "GUW"
    },
    {
      name: "Kzyl-Orda Southwest Airport",
      city: "Kzyl-Orda",
      country: "Kazakhstan",
      value: "KZO",
      display: "KZO"
    },
    {
      name: "South Bend Regional Airport",
      city: "South Bend",
      country: "United States",
      value: "SBN",
      display: "SBN"
    },
    {
      name: "Bykovo Airport",
      city: "Moscow",
      country: "Russia",
      value: "BKA",
      display: "BKA"
    },
    {
      name: "Talagi Airport",
      city: "Arkhangelsk",
      country: "Russia",
      value: "ARH",
      display: "ARH"
    },
    {
      name: "Saratov Central Airport",
      city: "Saratov",
      country: "Russia",
      value: "RTW",
      display: "RTW"
    },
    {
      name: "Novy Urengoy Airport",
      city: "Novy Urengoy",
      country: "Russia",
      value: "NUX",
      display: "NUX"
    },
    {
      name: "Noyabrsk Airport",
      city: "Noyabrsk",
      country: "Russia",
      value: "NOJ",
      display: "NOJ"
    },
    {
      name: "Aktau Airport",
      city: "Aktau",
      country: "Kazakhstan",
      value: "SCO",
      display: "SCO"
    },
    {
      name: "Ukhta Airport",
      city: "Ukhta",
      country: "Russia",
      value: "UCT",
      display: "UCT"
    },
    {
      name: "Usinsk Airport",
      city: "Usinsk",
      country: "Russia",
      value: "USK",
      display: "USK"
    },
    {
      name: "Pechora Airport",
      city: "Pechora",
      country: "Russia",
      value: "PEX",
      display: "PEX"
    },
    {
      name: "Naryan Mar Airport",
      city: "Naryan-Mar",
      country: "Russia",
      value: "NNM",
      display: "NNM"
    },
    {
      name: "Pskov Airport",
      city: "Pskov",
      country: "Russia",
      value: "PKV",
      display: "PKV"
    },
    {
      name: "Kogalym International Airport",
      city: "Kogalym",
      country: "Russia",
      value: "KGP",
      display: "KGP"
    },
    {
      name: "Yemelyanovo Airport",
      city: "Krasnoyarsk",
      country: "Russia",
      value: "KJA",
      display: "KJA"
    },
    {
      name: "Sary-Arka Airport",
      city: "Karaganda",
      country: "Kazakhstan",
      value: "KGF",
      display: "KGF"
    },
    {
      name: "Uray Airport",
      city: "Uraj",
      country: "Russia",
      value: "URJ",
      display: "URJ"
    },
    {
      name: "Ivanovo South Airport",
      city: "Ivanovo",
      country: "Russia",
      value: "IWA",
      display: "IWA"
    },
    {
      name: "Longjia Airport",
      city: "Changchun",
      country: "China",
      value: "CGQ",
      display: "CGQ"
    },
    {
      name: "Niigata Airport",
      city: "Niigata",
      country: "Japan",
      value: "KIJ",
      display: "KIJ"
    },
    {
      name: "Johnston Atoll Airport",
      city: "Johnston Island",
      country: "Johnston Atoll",
      value: "JON",
      display: "JON"
    },
    {
      name: "Smith Field",
      city: "Fort Wayne IN",
      country: "United States",
      value: "SMD",
      display: "SMD"
    },
    {
      name: "Arcata Airport",
      city: "Arcata CA",
      country: "United States",
      value: "ACV",
      display: "ACV"
    },
    {
      name: "Albert J Ellis Airport",
      city: "Jacksonville NC",
      country: "United States",
      value: "OAJ",
      display: "OAJ"
    },
    {
      name: "Tuscaloosa Regional Airport",
      city: "Tuscaloosa AL",
      country: "United States",
      value: "TCL",
      display: "TCL"
    },
    {
      name: "Dubuque Regional Airport",
      city: "Dubuque IA",
      country: "United States",
      value: "DBQ",
      display: "DBQ"
    },
    {
      name: "Uru Harbour Airport",
      city: "Atoifi",
      country: "Solomon Islands",
      value: "ATD",
      display: "ATD"
    },
    {
      name: "Auki Airport",
      city: "Auki",
      country: "Solomon Islands",
      value: "AKS",
      display: "AKS"
    },
    {
      name: "Ballalae Airport",
      city: "Ballalae",
      country: "Solomon Islands",
      value: "BAS",
      display: "BAS"
    },
    {
      name: "Fera/Maringe Airport",
      city: "Fera Island",
      country: "Solomon Islands",
      value: "FRE",
      display: "FRE"
    },
    {
      name: "Babanakira Airport",
      city: "Mbambanakira",
      country: "Solomon Islands",
      value: "MBU",
      display: "MBU"
    },
    {
      name: "Ngorangora Airport",
      city: "Kirakira",
      country: "Solomon Islands",
      value: "IRA",
      display: "IRA"
    },
    {
      name: "Santa Cruz/Graciosa Bay/Luova Airport",
      city: "Santa Cruz/Graciosa Bay/Luova",
      country: "Solomon Islands",
      value: "SCZ",
      display: "SCZ"
    },
    {
      name: "Munda Airport",
      city: "Munda",
      country: "Solomon Islands",
      value: "MUA",
      display: "MUA"
    },
    {
      name: "Nusatupe Airport",
      city: "Gizo",
      country: "Solomon Islands",
      value: "GZO",
      display: "GZO"
    },
    {
      name: "Mono Airport",
      city: "Stirling Island",
      country: "Solomon Islands",
      value: "MNY",
      display: "MNY"
    },
    {
      name: "Rennell/Tingoa Airport",
      city: "Rennell Island",
      country: "Solomon Islands",
      value: "RNL",
      display: "RNL"
    },
    {
      name: "Marau Airport",
      city: "Marau",
      country: "Solomon Islands",
      value: "RUS",
      display: "RUS"
    },
    {
      name: "Suavanao Airport",
      city: "Suavanao",
      country: "Solomon Islands",
      value: "VAO",
      display: "VAO"
    },
    {
      name: "Kaghau Airport",
      city: "Kagau Island",
      country: "Solomon Islands",
      value: "KGE",
      display: "KGE"
    },
    {
      name: "Ramata Airport",
      city: "Ramata",
      country: "Solomon Islands",
      value: "RBV",
      display: "RBV"
    },
    {
      name: "Buka Airport",
      city: "Buka Island",
      country: "Papua New Guinea",
      value: "BUA",
      display: "BUA"
    },
    {
      name: "Chimbu Airport",
      city: "Kundiawa",
      country: "Papua New Guinea",
      value: "CMU",
      display: "CMU"
    },
    {
      name: "Daru Airport",
      city: "Daru",
      country: "Papua New Guinea",
      value: "DAU",
      display: "DAU"
    },
    {
      name: "Gurney Airport",
      city: "Gurney",
      country: "Papua New Guinea",
      value: "GUR",
      display: "GUR"
    },
    {
      name: "Girua Airport",
      city: "Girua",
      country: "Papua New Guinea",
      value: "PNP",
      display: "PNP"
    },
    {
      name: "Kimbe Airport",
      city: "Hoskins",
      country: "Papua New Guinea",
      value: "HKN",
      display: "HKN"
    },
    {
      name: "Kiunga Airport",
      city: "Kiunga",
      country: "Papua New Guinea",
      value: "UNG",
      display: "UNG"
    },
    {
      name: "Kikori Airport",
      city: "Kikori",
      country: "Papua New Guinea",
      value: "KRI",
      display: "KRI"
    },
    {
      name: "Kerema Airport",
      city: "Kerema",
      country: "Papua New Guinea",
      value: "KMA",
      display: "KMA"
    },
    {
      name: "Kavieng Airport",
      city: "Kavieng",
      country: "Papua New Guinea",
      value: "KVG",
      display: "KVG"
    },
    {
      name: "Mendi Airport",
      city: "Mendi",
      country: "Papua New Guinea",
      value: "MDU",
      display: "MDU"
    },
    {
      name: "Momote Airport",
      city: "Momote",
      country: "Papua New Guinea",
      value: "MAS",
      display: "MAS"
    },
    {
      name: "Moro Airport",
      city: "Moro",
      country: "Papua New Guinea",
      value: "MXH",
      display: "MXH"
    },
    {
      name: "Misima Island Airport",
      city: "Misima Island",
      country: "Papua New Guinea",
      value: "MIS",
      display: "MIS"
    },
    {
      name: "Tari Airport",
      city: "Tari",
      country: "Papua New Guinea",
      value: "TIZ",
      display: "TIZ"
    },
    {
      name: "Tabubil Airport",
      city: "Tabubil",
      country: "Papua New Guinea",
      value: "TBG",
      display: "TBG"
    },
    {
      name: "Tokua Airport",
      city: "Tokua",
      country: "Papua New Guinea",
      value: "RAB",
      display: "RAB"
    },
    {
      name: "Vanimo Airport",
      city: "Vanimo",
      country: "Papua New Guinea",
      value: "VAI",
      display: "VAI"
    },
    {
      name: "Wapenamanda Airport",
      city: "Wapenamanda",
      country: "Papua New Guinea",
      value: "WBM",
      display: "WBM"
    },
    {
      name: "Alluitsup Paa Heliport",
      city: "Alluitsup Paa",
      country: "Greenland",
      value: "LLU",
      display: "LLU"
    },
    {
      name: "Neerlerit Inaat Airport",
      city: "Neerlerit Inaat",
      country: "Greenland",
      value: "CNP",
      display: "CNP"
    },
    {
      name: "Paamiut Heliport",
      city: "Paamiut",
      country: "Greenland",
      value: "JFR",
      display: "JFR"
    },
    {
      name: "Qeqertarsuaq Heliport",
      city: "Qeqertarsuaq Airport",
      country: "Greenland",
      value: "JGO",
      display: "JGO"
    },
    {
      name: "Qaqortoq Heliport",
      city: "Qaqortoq",
      country: "Greenland",
      value: "JJU",
      display: "JJU"
    },
    {
      name: "Maniitsoq Airport",
      city: "Maniitsoq",
      country: "Greenland",
      value: "JSU",
      display: "JSU"
    },
    {
      name: "Nanortalik Heliport",
      city: "Nanortalik",
      country: "Greenland",
      value: "JNN",
      display: "JNN"
    },
    {
      name: "Narsaq Heliport",
      city: "Narsaq",
      country: "Greenland",
      value: "JNS",
      display: "JNS"
    },
    {
      name: "Qaanaaq Airport",
      city: "Qaanaaq",
      country: "Greenland",
      value: "NAQ",
      display: "NAQ"
    },
    {
      name: "Sisimiut Airport",
      city: "Sisimiut",
      country: "Greenland",
      value: "JHS",
      display: "JHS"
    },
    {
      name: "Upernavik Airport",
      city: "Upernavik",
      country: "Greenland",
      value: "JUV",
      display: "JUV"
    },
    {
      name: "Qaarsut Airport",
      city: "Uummannaq",
      country: "Greenland",
      value: "JQA",
      display: "JQA"
    },
    {
      name: "Grimsey Airport",
      city: "Grimsey",
      country: "Iceland",
      value: "GRY",
      display: "GRY"
    },
    {
      name: "Thorshofn Airport",
      city: "Thorshofn",
      country: "Iceland",
      value: "THO",
      display: "THO"
    },
    {
      name: "Vopnafjorour Airport",
      city: "Vopnafjorour",
      country: "Iceland",
      value: "VPN",
      display: "VPN"
    },
    {
      name: "Whistler/Green Lake Water Aerodrome",
      city: "Whistler",
      country: "Canada",
      value: "YWS",
      display: "YWS"
    },
    {
      name: "Anahim Lake Airport",
      city: "Anahim Lake",
      country: "Canada",
      value: "YAA",
      display: "YAA"
    },
    {
      name: "Williams Harbour Airport",
      city: "Williams Harbour",
      country: "Canada",
      value: "YWM",
      display: "YWM"
    },
    {
      name: "St. Lewis (Fox Harbour) Airport",
      city: "St. Lewis",
      country: "Canada",
      value: "YFX",
      display: "YFX"
    },
    {
      name: "Port Hope Simpson Airport",
      city: "Port Hope Simpson",
      country: "Canada",
      value: "YHA",
      display: "YHA"
    },
    {
      name: "Rigolet Airport",
      city: "Rigolet",
      country: "Canada",
      value: "YRG",
      display: "YRG"
    },
    {
      name: "Colville Lake Airport",
      city: "Colville Lake",
      country: "Canada",
      value: "YCK",
      display: "YCK"
    },
    {
      name: "Whati Airport",
      city: "Whati",
      country: "Canada",
      value: "YLE",
      display: "YLE"
    },
    {
      name: "Summer Beaver Airport",
      city: "Summer Beaver",
      country: "Canada",
      value: "SUR",
      display: "SUR"
    },
    {
      name: "Wapekeka Airport",
      city: "Angling Lake",
      country: "Canada",
      value: "YAX",
      display: "YAX"
    },
    {
      name: "Wunnumin Lake Airport",
      city: "Wunnumin Lake",
      country: "Canada",
      value: "WNN",
      display: "WNN"
    },
    {
      name: "North Spirit Lake Airport",
      city: "North Spirit Lake",
      country: "Canada",
      value: "YNO",
      display: "YNO"
    },
    {
      name: "Bearskin Lake Airport",
      city: "Bearskin Lake",
      country: "Canada",
      value: "XBE",
      display: "XBE"
    },
    {
      name: "Kingfisher Lake Airport",
      city: "Kingfisher Lake",
      country: "Canada",
      value: "KIF",
      display: "KIF"
    },
    {
      name: "Ogoki Post Airport",
      city: "Ogoki Post",
      country: "Canada",
      value: "YOG",
      display: "YOG"
    },
    {
      name: "Poplar Hill Airport",
      city: "Poplar Hill",
      country: "Canada",
      value: "YHP",
      display: "YHP"
    },
    {
      name: "Chisasibi Airport",
      city: "Chisasibi",
      country: "Canada",
      value: "YKU",
      display: "YKU"
    },
    {
      name: "Tete-a-la-Baleine Airport",
      city: "Tete-a-la-Baleine",
      country: "Canada",
      value: "ZTB",
      display: "ZTB"
    },
    {
      name: "La Tabatiere Airport",
      city: "La Tabatiere",
      country: "Canada",
      value: "ZLT",
      display: "ZLT"
    },
    {
      name: "Cat Lake Airport",
      city: "Cat Lake",
      country: "Canada",
      value: "YAC",
      display: "YAC"
    },
    {
      name: "Fort Frances Municipal Airport",
      city: "Fort Frances",
      country: "Canada",
      value: "YAG",
      display: "YAG"
    },
    {
      name: "Kasabonika Airport",
      city: "Kasabonika",
      country: "Canada",
      value: "XKS",
      display: "XKS"
    },
    {
      name: "Kangirsuk Airport",
      city: "Kangirsuk",
      country: "Canada",
      value: "YKG",
      display: "YKG"
    },
    {
      name: "Attawapiskat Airport",
      city: "Attawapiskat",
      country: "Canada",
      value: "YAT",
      display: "YAT"
    },
    {
      name: "Uranium City Airport",
      city: "Uranium City",
      country: "Canada",
      value: "YBE",
      display: "YBE"
    },
    {
      name: "Lourdes de Blanc Sablon Airport",
      city: "Lourdes-De-Blanc-Sablon",
      country: "Canada",
      value: "YBX",
      display: "YBX"
    },
    {
      name: "Cartwright Airport",
      city: "Cartwright",
      country: "Canada",
      value: "YRF",
      display: "YRF"
    },
    {
      name: "Chesterfield Inlet Airport",
      city: "Chesterfield Inlet",
      country: "Canada",
      value: "YCS",
      display: "YCS"
    },
    {
      name: "Nain Airport",
      city: "Nain",
      country: "Canada",
      value: "YDP",
      display: "YDP"
    },
    {
      name: "Fort Severn Airport",
      city: "Fort Severn",
      country: "Canada",
      value: "YER",
      display: "YER"
    },
    {
      name: "Fort Albany Airport",
      city: "Fort Albany",
      country: "Canada",
      value: "YFA",
      display: "YFA"
    },
    {
      name: "Fort Hope Airport",
      city: "Fort Hope",
      country: "Canada",
      value: "YFH",
      display: "YFH"
    },
    {
      name: "Makkovik Airport",
      city: "Makkovik",
      country: "Canada",
      value: "YMN",
      display: "YMN"
    },
    {
      name: "Texada Gillies Bay Airport",
      city: "Texada",
      country: "Canada",
      value: "YGB",
      display: "YGB"
    },
    {
      name: "Gods Lake Narrows Airport",
      city: "Gods Lake Narrows",
      country: "Canada",
      value: "YGO",
      display: "YGO"
    },
    {
      name: "Igloolik Airport",
      city: "Igloolik",
      country: "Canada",
      value: "YGT",
      display: "YGT"
    },
    {
      name: "Kuujjuarapik Airport",
      city: "Kuujjuarapik",
      country: "Canada",
      value: "YGW",
      display: "YGW"
    },
    {
      name: "Gillam Airport",
      city: "Gillam",
      country: "Canada",
      value: "YGX",
      display: "YGX"
    },
    {
      name: "Grise Fiord Airport",
      city: "Grise Fiord",
      country: "Canada",
      value: "YGZ",
      display: "YGZ"
    },
    {
      name: "Quaqtaq Airport",
      city: "Quaqtaq",
      country: "Canada",
      value: "YQC",
      display: "YQC"
    },
    {
      name: "Vancouver Harbour Water Aerodrome",
      city: "Vancouver",
      country: "Canada",
      value: "CXH",
      display: "CXH"
    },
    {
      name: "Nemiscau Airport",
      city: "Nemiscau",
      country: "Canada",
      value: "YNS",
      display: "YNS"
    },
    {
      name: "Hopedale Airport",
      city: "Hopedale",
      country: "Canada",
      value: "YHO",
      display: "YHO"
    },
    {
      name: "Chevery Airport",
      city: "Chevery",
      country: "Canada",
      value: "YHR",
      display: "YHR"
    },
    {
      name: "Ivujivik Airport",
      city: "Ivujivik",
      country: "Canada",
      value: "YIK",
      display: "YIK"
    },
    {
      name: "Island Lake Airport",
      city: "Island Lake",
      country: "Canada",
      value: "YIV",
      display: "YIV"
    },
    {
      name: "Akulivik Airport",
      city: "Akulivik",
      country: "Canada",
      value: "AKV",
      display: "AKV"
    },
    {
      name: "Waskaganish Airport",
      city: "Waskaganish",
      country: "Canada",
      value: "YKQ",
      display: "YKQ"
    },
    {
      name: "Aupaluk Airport",
      city: "Aupaluk",
      country: "Canada",
      value: "YPJ",
      display: "YPJ"
    },
    {
      name: "Kimmirut Airport",
      city: "Kimmirut",
      country: "Canada",
      value: "YLC",
      display: "YLC"
    },
    {
      name: "Lansdowne House Airport",
      city: "Lansdowne House",
      country: "Canada",
      value: "YLH",
      display: "YLH"
    },
    {
      name: "St Georges Airport",
      city: "Lutselk e",
      country: "Canada",
      value: "YSG",
      display: "YSG"
    },
    {
      name: "Kangiqsualujjuaq (Georges River) Airport",
      city: "Kangiqsualujjuaq",
      country: "Canada",
      value: "XGR",
      display: "XGR"
    },
    {
      name: "Mary's Harbour Airport",
      city: "Mary s Harbour",
      country: "Canada",
      value: "YMH",
      display: "YMH"
    },
    {
      name: "Chapais Airport",
      city: "Chibougamau",
      country: "Canada",
      value: "YMT",
      display: "YMT"
    },
    {
      name: "Umiujaq Airport",
      city: "Umiujaq",
      country: "Canada",
      value: "YUD",
      display: "YUD"
    },
    {
      name: "Wemindji Airport",
      city: "Wemindji",
      country: "Canada",
      value: "YNC",
      display: "YNC"
    },
    {
      name: "Norway House Airport",
      city: "Norway House",
      country: "Canada",
      value: "YNE",
      display: "YNE"
    },
    {
      name: "Points North Landing Airport",
      city: "Points North Landing",
      country: "Canada",
      value: "YNL",
      display: "YNL"
    },
    {
      name: "Oxford House Airport",
      city: "Oxford House",
      country: "Canada",
      value: "YOH",
      display: "YOH"
    },
    {
      name: "Inukjuak Airport",
      city: "Inukjuak",
      country: "Canada",
      value: "YPH",
      display: "YPH"
    },
    {
      name: "Pikangikum Airport",
      city: "Pikangikum",
      country: "Canada",
      value: "YPM",
      display: "YPM"
    },
    {
      name: "Peawanuck Airport",
      city: "Peawanuck",
      country: "Canada",
      value: "YPO",
      display: "YPO"
    },
    {
      name: "Powell River Airport",
      city: "Powell River",
      country: "Canada",
      value: "YPW",
      display: "YPW"
    },
    {
      name: "The Pas Airport",
      city: "The Pas",
      country: "Canada",
      value: "YQD",
      display: "YQD"
    },
    {
      name: "Nakina Airport",
      city: "Nakina",
      country: "Canada",
      value: "YQN",
      display: "YQN"
    },
    {
      name: "Rae Lakes Airport",
      city: "Gameti",
      country: "Canada",
      value: "YRA",
      display: "YRA"
    },
    {
      name: "Red Lake Airport",
      city: "Red Lake",
      country: "Canada",
      value: "YRL",
      display: "YRL"
    },
    {
      name: "Stony Rapids Airport",
      city: "Stony Rapids",
      country: "Canada",
      value: "YSF",
      display: "YSF"
    },
    {
      name: "Sanikiluaq Airport",
      city: "Sanikiluaq",
      country: "Canada",
      value: "YSK",
      display: "YSK"
    },
    {
      name: "St. Theresa Point Airport",
      city: "St. Theresa Point",
      country: "Canada",
      value: "YST",
      display: "YST"
    },
    {
      name: "Big Trout Lake Airport",
      city: "Big Trout Lake",
      country: "Canada",
      value: "YTL",
      display: "YTL"
    },
    {
      name: "Deer Lake Airport",
      city: "Deer Lake",
      country: "Canada",
      value: "YVZ",
      display: "YVZ"
    },
    {
      name: "Webequie Airport",
      city: "Webequie",
      country: "Canada",
      value: "YWP",
      display: "YWP"
    },
    {
      name: "Whale Cove Airport",
      city: "Whale Cove",
      country: "Canada",
      value: "YXN",
      display: "YXN"
    },
    {
      name: "Salluit Airport",
      city: "Salluit",
      country: "Canada",
      value: "YZG",
      display: "YZG"
    },
    {
      name: "York Landing Airport",
      city: "York Landing",
      country: "Canada",
      value: "ZAC",
      display: "ZAC"
    },
    {
      name: "Ilford Airport",
      city: "Ilford",
      country: "Canada",
      value: "ILF",
      display: "ILF"
    },
    {
      name: "Bathurst Airport",
      city: "Bathurst",
      country: "Canada",
      value: "ZBF",
      display: "ZBF"
    },
    {
      name: "Eastmain River Airport",
      city: "Eastmain River",
      country: "Canada",
      value: "ZEM",
      display: "ZEM"
    },
    {
      name: "Fond-Du-Lac Airport",
      city: "Fond-Du-Lac",
      country: "Canada",
      value: "ZFD",
      display: "ZFD"
    },
    {
      name: "Gods River Airport",
      city: "Gods River",
      country: "Canada",
      value: "ZGI",
      display: "ZGI"
    },
    {
      name: "Swan River Airport",
      city: "Swan River",
      country: "Canada",
      value: "ZJN",
      display: "ZJN"
    },
    {
      name: "Kashechewan Airport",
      city: "Kashechewan",
      country: "Canada",
      value: "ZKE",
      display: "ZKE"
    },
    {
      name: "Muskrat Dam Airport",
      city: "Muskrat Dam",
      country: "Canada",
      value: "MSA",
      display: "MSA"
    },
    {
      name: "Masset Airport",
      city: "Masset",
      country: "Canada",
      value: "ZMT",
      display: "ZMT"
    },
    {
      name: "Sachigo Lake Airport",
      city: "Sachigo Lake",
      country: "Canada",
      value: "ZPB",
      display: "ZPB"
    },
    {
      name: "Round Lake (Weagamow Lake) Airport",
      city: "Round Lake",
      country: "Canada",
      value: "ZRJ",
      display: "ZRJ"
    },
    {
      name: "Sandy Lake Airport",
      city: "Sandy Lake",
      country: "Canada",
      value: "ZSJ",
      display: "ZSJ"
    },
    {
      name: "Shamattawa Airport",
      city: "Shamattawa",
      country: "Canada",
      value: "ZTM",
      display: "ZTM"
    },
    {
      name: "Churchill Falls Airport",
      city: "Churchill Falls",
      country: "Canada",
      value: "ZUM",
      display: "ZUM"
    },
    {
      name: "Wollaston Lake Airport",
      city: "Wollaston Lake",
      country: "Canada",
      value: "ZWL",
      display: "ZWL"
    },
    {
      name: "Batna Airport",
      city: "Batna",
      country: "Algeria",
      value: "BLJ",
      display: "BLJ"
    },
    {
      name: "Bechar Boudghene Ben Ali Lotfi Airport",
      city: "Bechar",
      country: "Algeria",
      value: "CBH",
      display: "CBH"
    },
    {
      name: "Bordj Badji Mokhtar Airport",
      city: "Bordj Badji Mokhtar",
      country: "Algeria",
      value: "BMW",
      display: "BMW"
    },
    {
      name: "Guemar Airport",
      city: "Guemar",
      country: "Algeria",
      value: "ELU",
      display: "ELU"
    },
    {
      name: "Kumasi Airport",
      city: "Kumasi",
      country: "Ghana",
      value: "KMS",
      display: "KMS"
    },
    {
      name: "Heringsdorf Airport",
      city: "Heringsdorf",
      country: "Germany",
      value: "HDF",
      display: "HDF"
    },
    {
      name: "Heide-Busum Airport",
      city: "Busum",
      country: "Germany",
      value: "HEI",
      display: "HEI"
    },
    {
      name: "Helgoland-Dune Airport",
      city: "Helgoland",
      country: "Germany",
      value: "HGL",
      display: "HGL"
    },
    {
      name: "Seinajoki Airport",
      city: "Seinajoki / Ilmajoki",
      country: "Finland",
      value: "SJY",
      display: "SJY"
    },
    {
      name: "Nottingham Airport",
      city: "Nottingham",
      country: "United Kingdom",
      value: "NQT",
      display: "NQT"
    },
    {
      name: "Robin Hood Doncaster Sheffield Airport",
      city: "Doncaster, Sheffield",
      country: "United Kingdom",
      value: "DSA",
      display: "DSA"
    },
    {
      name: "Campbeltown Airport",
      city: "Campbeltown",
      country: "United Kingdom",
      value: "CAL",
      display: "CAL"
    },
    {
      name: "Eday Airport",
      city: "Eday",
      country: "United Kingdom",
      value: "EOI",
      display: "EOI"
    },
    {
      name: "Fair Isle Airport",
      city: "Fair Isle",
      country: "United Kingdom",
      value: "FIE",
      display: "FIE"
    },
    {
      name: "North Ronaldsay Airport",
      city: "North Ronaldsay",
      country: "United Kingdom",
      value: "NRL",
      display: "NRL"
    },
    {
      name: "Papa Westray Airport",
      city: "Papa Westray",
      country: "United Kingdom",
      value: "PPW",
      display: "PPW"
    },
    {
      name: "Stronsay Airport",
      city: "Stronsay",
      country: "United Kingdom",
      value: "SOY",
      display: "SOY"
    },
    {
      name: "Sanday Airport",
      city: "Sanday",
      country: "United Kingdom",
      value: "NDY",
      display: "NDY"
    },
    {
      name: "Lerwick / Tingwall Airport",
      city: "Lerwick",
      country: "United Kingdom",
      value: "LWK",
      display: "LWK"
    },
    {
      name: "Westray Airport",
      city: "Westray",
      country: "United Kingdom",
      value: "WRY",
      display: "WRY"
    },
    {
      name: "Land's End Airport",
      city: "Land s End",
      country: "United Kingdom",
      value: "LEQ",
      display: "LEQ"
    },
    {
      name: "Penzance Heliport",
      city: "Penzance",
      country: "United Kingdom",
      value: "PZE",
      display: "PZE"
    },
    {
      name: "Anglesey Airport",
      city: "Angelsey",
      country: "United Kingdom",
      value: "VLY",
      display: "VLY"
    },
    {
      name: "Barra Airport",
      city: "Barra",
      country: "United Kingdom",
      value: "BRR",
      display: "BRR"
    },
    {
      name: "Donegal Airport",
      city: "Dongloe",
      country: "Ireland",
      value: "CFN",
      display: "CFN"
    },
    {
      name: "Sindal Airport",
      city: "Sindal",
      country: "Denmark",
      value: "CNL",
      display: "CNL"
    },
    {
      name: "Leknes Airport",
      city: "Leknes",
      country: "Norway",
      value: "LKN",
      display: "LKN"
    },
    {
      name: "Namsos HÃ¸knesÃ¸ra Airport",
      city: "Namsos",
      country: "Norway",
      value: "OSY",
      display: "OSY"
    },
    {
      name: "Mo i Rana Airport, RÃ¸ssvoll",
      city: "Mo i Rana",
      country: "Norway",
      value: "MQN",
      display: "MQN"
    },
    {
      name: "RÃ¸rvik Airport, Ryum",
      city: "Rorvik",
      country: "Norway",
      value: "RVK",
      display: "RVK"
    },
    {
      name: "RÃ¸st Airport",
      city: "Rost",
      country: "Norway",
      value: "RET",
      display: "RET"
    },
    {
      name: "Sandane Airport (Anda)",
      city: "Sandane",
      country: "Norway",
      value: "SDN",
      display: "SDN"
    },
    {
      name: "Sogndal Airport",
      city: "Sogndal",
      country: "Norway",
      value: "SOG",
      display: "SOG"
    },
    {
      name: "SvolvÃ¦r Helle Airport",
      city: "Svolvaer",
      country: "Norway",
      value: "SVJ",
      display: "SVJ"
    },
    {
      name: "SÃ¸rkjosen Airport",
      city: "Sorkjosen",
      country: "Norway",
      value: "SOJ",
      display: "SOJ"
    },
    {
      name: "VardÃ¸ Airport, Svartnes",
      city: "Vardo",
      country: "Norway",
      value: "VAW",
      display: "VAW"
    },
    {
      name: "Bydgoszcz Ignacy Jan Paderewski Airport",
      city: "Bydgoszcz",
      country: "Poland",
      value: "BZG",
      display: "BZG"
    },
    {
      name: "ÅÃ³dÅº WÅ‚adysÅ‚aw Reymont Airport",
      city: "Lodz",
      country: "Poland",
      value: "LCJ",
      display: "LCJ"
    },
    {
      name: "Are Ostersund Airport",
      city: "Ostersund",
      country: "Sweden",
      value: "OSD",
      display: "OSD"
    },
    {
      name: "Hagfors Airport",
      city: "Hagfors",
      country: "Sweden",
      value: "HFS",
      display: "HFS"
    },
    {
      name: "Karlstad Airport",
      city: "Karlstad",
      country: "Sweden",
      value: "KSD",
      display: "KSD"
    },
    {
      name: "Torsby Airport",
      city: "Torsby",
      country: "Sweden",
      value: "TYF",
      display: "TYF"
    },
    {
      name: "Angelholm-Helsingborg Airport",
      city: "Angelholm",
      country: "Sweden",
      value: "AGH",
      display: "AGH"
    },
    {
      name: "Storuman Airport",
      city: "Mohed",
      country: "Sweden",
      value: "SQO",
      display: "SQO"
    },
    {
      name: "Hemavan Airport",
      city: "Hemavan",
      country: "Sweden",
      value: "HMV",
      display: "HMV"
    },
    {
      name: "Ventspils International Airport",
      city: "Ventspils",
      country: "Latvia",
      value: "VTS",
      display: "VTS"
    },
    {
      name: "Rand Airport",
      city: "Johannesburg",
      country: "South Africa",
      value: "QRA",
      display: "QRA"
    },
    {
      name: "Kruger Mpumalanga International Airport",
      city: "Mpumalanga",
      country: "South Africa",
      value: "MQP",
      display: "MQP"
    },
    {
      name: "Malamala Airport",
      city: "Malamala",
      country: "South Africa",
      value: "AAM",
      display: "AAM"
    },
    {
      name: "Mmabatho International Airport",
      city: "Mafeking",
      country: "South Africa",
      value: "MBD",
      display: "MBD"
    },
    {
      name: "Ghanzi Airport",
      city: "Ghanzi",
      country: "Botswana",
      value: "GNZ",
      display: "GNZ"
    },
    {
      name: "Orapa Airport",
      city: "Orapa",
      country: "Botswana",
      value: "ORP",
      display: "ORP"
    },
    {
      name: "Shakawe Airport",
      city: "Shakawe",
      country: "Botswana",
      value: "SWX",
      display: "SWX"
    },
    {
      name: "Limpopo Valley Airport",
      city: "Tuli Lodge",
      country: "Botswana",
      value: "TLD",
      display: "TLD"
    },
    {
      name: "Ngot Nzoungou Airport",
      city: "Loubomo",
      country: "Congo (Brazzaville)",
      value: "DIS",
      display: "DIS"
    },
    {
      name: "Chipata Airport",
      city: "Chipata",
      country: "Zambia",
      value: "CIP",
      display: "CIP"
    },
    {
      name: "Los Alamitos Army Air Field",
      city: "Solwesi",
      country: "Zambia",
      value: "SLI",
      display: "SLI"
    },
    {
      name: "Iconi Airport",
      city: "Moroni",
      country: "Comoros",
      value: "YVA",
      display: "YVA"
    },
    {
      name: "Antsalova Airport",
      city: "Antsalova",
      country: "Madagascar",
      value: "WAQ",
      display: "WAQ"
    },
    {
      name: "Ankavandra Airport",
      city: "Ankavandra",
      country: "Madagascar",
      value: "JVA",
      display: "JVA"
    },
    {
      name: "Belo sur Tsiribihina Airport",
      city: "Belo sur Tsiribihina",
      country: "Madagascar",
      value: "BMD",
      display: "BMD"
    },
    {
      name: "Maintirano Airport",
      city: "Maintirano",
      country: "Madagascar",
      value: "MXT",
      display: "MXT"
    },
    {
      name: "Morafenobe Airport",
      city: "Morafenobe",
      country: "Madagascar",
      value: "TVA",
      display: "TVA"
    },
    {
      name: "Tambohorano Airport",
      city: "Tambohorano",
      country: "Madagascar",
      value: "WTA",
      display: "WTA"
    },
    {
      name: "Tsiroanomandidy Airport",
      city: "Tsiroanomandidy",
      country: "Madagascar",
      value: "WTS",
      display: "WTS"
    },
    {
      name: "Ambatondrazaka Airport",
      city: "Ambatondrazaka",
      country: "Madagascar",
      value: "WAM",
      display: "WAM"
    },
    {
      name: "Port Berge Airport",
      city: "Port Berge",
      country: "Madagascar",
      value: "WPB",
      display: "WPB"
    },
    {
      name: "Soalala Airport",
      city: "Soalala",
      country: "Madagascar",
      value: "DWB",
      display: "DWB"
    },
    {
      name: "Mampikony Airport",
      city: "Mampikony",
      country: "Madagascar",
      value: "WMP",
      display: "WMP"
    },
    {
      name: "Nasa Shuttle Landing Facility Airport",
      city: "Tsaratanana",
      country: "Madagascar",
      value: "TTS",
      display: "TTS"
    },
    {
      name: "Mandritsara Airport",
      city: "Mandritsara",
      country: "Madagascar",
      value: "WMA",
      display: "WMA"
    },
    {
      name: "Manja Airport",
      city: "Manja",
      country: "Madagascar",
      value: "MJA",
      display: "MJA"
    },
    {
      name: "Catumbela Airport",
      city: "Catumbela",
      country: "Angola",
      value: "CBT",
      display: "CBT"
    },
    {
      name: "Dundo Airport",
      city: "Dundo",
      country: "Angola",
      value: "DUE",
      display: "DUE"
    },
    {
      name: "Ngjiva Pereira Airport",
      city: "Ondjiva",
      country: "Angola",
      value: "VPE",
      display: "VPE"
    },
    {
      name: "Namibe Airport",
      city: "Mocamedes",
      country: "Angola",
      value: "MSZ",
      display: "MSZ"
    },
    {
      name: "Koulamoutou Mabimbi Airport",
      city: "Koulamoutou",
      country: "Gabon",
      value: "KOU",
      display: "KOU"
    },
    {
      name: "Mouilla Ville Airport",
      city: "Mouila",
      country: "Gabon",
      value: "MJL",
      display: "MJL"
    },
    {
      name: "Tchibanga Airport",
      city: "Tchibanga",
      country: "Gabon",
      value: "TCH",
      display: "TCH"
    },
    {
      name: "Chimoio Airport",
      city: "Chimoio",
      country: "Mozambique",
      value: "VPY",
      display: "VPY"
    },
    {
      name: "Sarh Airport",
      city: "Sarh",
      country: "Chad",
      value: "SRH",
      display: "SRH"
    },
    {
      name: "Club Makokola Airport",
      city: "Club Makokola",
      country: "Malawi",
      value: "CMK",
      display: "CMK"
    },
    {
      name: "Luderitz Airport",
      city: "Luderitz",
      country: "Namibia",
      value: "LUD",
      display: "LUD"
    },
    {
      name: "Ondangwa Airport",
      city: "Ondangwa",
      country: "Namibia",
      value: "OND",
      display: "OND"
    },
    {
      name: "Oranjemund Airport",
      city: "Oranjemund",
      country: "Namibia",
      value: "OMD",
      display: "OMD"
    },
    {
      name: "Swakopmund Airport",
      city: "Swakopmund",
      country: "Namibia",
      value: "SWP",
      display: "SWP"
    },
    {
      name: "Eros Airport",
      city: "Windhoek",
      country: "Namibia",
      value: "ERS",
      display: "ERS"
    },
    {
      name: "Boma Airport",
      city: "Boma",
      country: "Congo (Kinshasa)",
      value: "BOA",
      display: "BOA"
    },
    {
      name: "Tshimpi Airport",
      city: "Matadi",
      country: "Congo (Kinshasa)",
      value: "MAT",
      display: "MAT"
    },
    {
      name: "Inongo Airport",
      city: "Inongo",
      country: "Congo (Kinshasa)",
      value: "INO",
      display: "INO"
    },
    {
      name: "Nioki Airport",
      city: "Nioki",
      country: "Congo (Kinshasa)",
      value: "NIO",
      display: "NIO"
    },
    {
      name: "Basango Mboliasa Airport",
      city: "Kiri",
      country: "Congo (Kinshasa)",
      value: "KRZ",
      display: "KRZ"
    },
    {
      name: "Basankusu Airport",
      city: "Basankusu",
      country: "Congo (Kinshasa)",
      value: "BSU",
      display: "BSU"
    },
    {
      name: "Tshikapa Airport",
      city: "Tshikapa",
      country: "Congo (Kinshasa)",
      value: "TSH",
      display: "TSH"
    },
    {
      name: "Lodja Airport",
      city: "Lodja",
      country: "Congo (Kinshasa)",
      value: "LJA",
      display: "LJA"
    },
    {
      name: "Ilebo Airport",
      city: "Ilebo",
      country: "Congo (Kinshasa)",
      value: "PFR",
      display: "PFR"
    },
    {
      name: "La Gomera Airport",
      city: "La Gomera",
      country: "Spain",
      value: "GMZ",
      display: "GMZ"
    },
    {
      name: "Sherbro International Airport",
      city: "Bonthe",
      country: "Sierra Leone",
      value: "BTE",
      display: "BTE"
    },
    {
      name: "Bo Airport",
      city: "Bo",
      country: "Sierra Leone",
      value: "KBS",
      display: "KBS"
    },
    {
      name: "Kenema Airport",
      city: "Kenema",
      country: "Sierra Leone",
      value: "KEN",
      display: "KEN"
    },
    {
      name: "Osvaldo Vieira International Airport",
      city: "Bissau",
      country: "Guinea-Bissau",
      value: "OXB",
      display: "OXB"
    },
    {
      name: "Smara Airport",
      city: "Smara",
      country: "Western Sahara",
      value: "SMW",
      display: "SMW"
    },
    {
      name: "Dakhla Airport",
      city: "Dakhla",
      country: "Western Sahara",
      value: "VIL",
      display: "VIL"
    },
    {
      name: "Essaouira-Mogador Airport",
      city: "Essaouira",
      country: "Morocco",
      value: "ESU",
      display: "ESU"
    },
    {
      name: "Hassan I Airport",
      city: "El Aaiun",
      country: "Western Sahara",
      value: "EUN",
      display: "EUN"
    },
    {
      name: "Nador International Airport",
      city: "El Aroui",
      country: "Morocco",
      value: "NDR",
      display: "NDR"
    },
    {
      name: "Praia International Airport",
      city: "Praia, Santiago Island",
      country: "Cape Verde",
      value: "RAI",
      display: "RAI"
    },
    {
      name: "Sao Filipe Airport",
      city: "Sao Filipe, Fogo Island",
      country: "Cape Verde",
      value: "SFL",
      display: "SFL"
    },
    {
      name: "Baco Airport",
      city: "Baco",
      country: "Ethiopia",
      value: "BCO",
      display: "BCO"
    },
    {
      name: "Beica Airport",
      city: "Beica",
      country: "Ethiopia",
      value: "BEI",
      display: "BEI"
    },
    {
      name: "Combolcha Airport",
      city: "Dessie",
      country: "Ethiopia",
      value: "DSE",
      display: "DSE"
    },
    {
      name: "Dembidollo Airport",
      city: "Dembidollo",
      country: "Ethiopia",
      value: "DEM",
      display: "DEM"
    },
    {
      name: "Gode Airport",
      city: "Gode",
      country: "Ethiopia",
      value: "GDE",
      display: "GDE"
    },
    {
      name: "Gore Airport",
      city: "Gore",
      country: "Ethiopia",
      value: "GOR",
      display: "GOR"
    },
    {
      name: "Kabri Dehar Airport",
      city: "Kabri Dehar",
      country: "Ethiopia",
      value: "ABK",
      display: "ABK"
    },
    {
      name: "Mizan Teferi Airport",
      city: "Mizan Teferi",
      country: "Ethiopia",
      value: "MTF",
      display: "MTF"
    },
    {
      name: "Tippi Airport",
      city: "Tippi",
      country: "Ethiopia",
      value: "TIE",
      display: "TIE"
    },
    {
      name: "Alula Airport",
      city: "Alula",
      country: "Somalia",
      value: "ALU",
      display: "ALU"
    },
    {
      name: "Bosaso Airport",
      city: "Bosaso",
      country: "Somalia",
      value: "BSA",
      display: "BSA"
    },
    {
      name: "Aden Adde International Airport",
      city: "Mogadishu",
      country: "Somalia",
      value: "MGQ",
      display: "MGQ"
    },
    {
      name: "Galcaio Airport",
      city: "Galcaio",
      country: "Somalia",
      value: "GLK",
      display: "GLK"
    },
    {
      name: "Burao Airport",
      city: "Burao",
      country: "Somalia",
      value: "BUO",
      display: "BUO"
    },
    {
      name: "El Arish International Airport",
      city: "El Arish",
      country: "Egypt",
      value: "AAC",
      display: "AAC"
    },
    {
      name: "Assiut International Airport",
      city: "Asyut",
      country: "Egypt",
      value: "ATZ",
      display: "ATZ"
    },
    {
      name: "Amboseli Airport",
      city: "Amboseli National Park",
      country: "Kenya",
      value: "ASV",
      display: "ASV"
    },
    {
      name: "Lokichoggio Airport",
      city: "Lokichoggio",
      country: "Kenya",
      value: "LKG",
      display: "LKG"
    },
    {
      name: "Malindi Airport",
      city: "Malindi",
      country: "Kenya",
      value: "MYD",
      display: "MYD"
    },
    {
      name: "Nanyuki Airport",
      city: "Nanyuki",
      country: "Kenya",
      value: "NYK",
      display: "NYK"
    },
    {
      name: "Gardabya Airport",
      city: "Sirt",
      country: "Libya",
      value: "SRX",
      display: "SRX"
    },
    {
      name: "Gamal Abdel Nasser Airport",
      city: "Tobruk",
      country: "Libya",
      value: "TOB",
      display: "TOB"
    },
    {
      name: "Mitiga Airport",
      city: "Tripoli",
      country: "Libya",
      value: "MJI",
      display: "MJI"
    },
    {
      name: "La Abraq Airport",
      city: "Al Bayda",
      country: "Libya",
      value: "LAQ",
      display: "LAQ"
    },
    {
      name: "Atbara Airport",
      city: "Atbara",
      country: "Sudan",
      value: "ATB",
      display: "ATB"
    },
    {
      name: "Nyala Airport",
      city: "Nyala",
      country: "Sudan",
      value: "UYL",
      display: "UYL"
    },
    {
      name: "Port Sudan New International Airport",
      city: "Port Sudan",
      country: "Sudan",
      value: "PZU",
      display: "PZU"
    },
    {
      name: "Bukoba Airport",
      city: "Bukoba",
      country: "Tanzania",
      value: "BKZ",
      display: "BKZ"
    },
    {
      name: "Kigoma Airport",
      city: "Kigoma",
      country: "Tanzania",
      value: "TKQ",
      display: "TKQ"
    },
    {
      name: "Kikwetu Airport",
      city: "Lindi",
      country: "Tanzania",
      value: "LDI",
      display: "LDI"
    },
    {
      name: "Musoma Airport",
      city: "Musoma",
      country: "Tanzania",
      value: "MUZ",
      display: "MUZ"
    },
    {
      name: "Shinyanga Airport",
      city: "Shinyanga",
      country: "Tanzania",
      value: "SHY",
      display: "SHY"
    },
    {
      name: "Tabora Airport",
      city: "Tabora",
      country: "Tanzania",
      value: "TBO",
      display: "TBO"
    },
    {
      name: "Arua Airport",
      city: "Arua",
      country: "Uganda",
      value: "RUA",
      display: "RUA"
    },
    {
      name: "Gulu Airport",
      city: "Gulu",
      country: "Uganda",
      value: "ULU",
      display: "ULU"
    },
    {
      name: "Diu Airport",
      city: "Diu",
      country: "India",
      value: "DIU",
      display: "DIU"
    },
    {
      name: "Aberdeen Regional Airport",
      city: "Aberdeen",
      country: "United States",
      value: "ABR",
      display: "ABR"
    },
    {
      name: "Southwest Georgia Regional Airport",
      city: "Albany",
      country: "United States",
      value: "ABY",
      display: "ABY"
    },
    {
      name: "Athens Ben Epps Airport",
      city: "Athens",
      country: "United States",
      value: "AHN",
      display: "AHN"
    },
    {
      name: "Alamogordo White Sands Regional Airport",
      city: "Alamogordo",
      country: "United States",
      value: "ALM",
      display: "ALM"
    },
    {
      name: "Waterloo Regional Airport",
      city: "Waterloo",
      country: "United States",
      value: "ALO",
      display: "ALO"
    },
    {
      name: "Walla Walla Regional Airport",
      city: "Walla Walla",
      country: "United States",
      value: "ALW",
      display: "ALW"
    },
    {
      name: "Alpena County Regional Airport",
      city: "Alpena",
      country: "United States",
      value: "APN",
      display: "APN"
    },
    {
      name: "Watertown Regional Airport",
      city: "Watertown",
      country: "United States",
      value: "ATY",
      display: "ATY"
    },
    {
      name: "Bradford Regional Airport",
      city: "Bradford",
      country: "United States",
      value: "BFD",
      display: "BFD"
    },
    {
      name: "Western Neb. Rgnl/William B. Heilig Airport",
      city: "Scottsbluff",
      country: "United States",
      value: "BFF",
      display: "BFF"
    },
    {
      name: "Raleigh County Memorial Airport",
      city: "Beckley",
      country: "United States",
      value: "BKW",
      display: "BKW"
    },
    {
      name: "Brunswick Golden Isles Airport",
      city: "Brunswick",
      country: "United States",
      value: "BQK",
      display: "BQK"
    },
    {
      name: "Southeast Iowa Regional Airport",
      city: "Burlington",
      country: "United States",
      value: "BRL",
      display: "BRL"
    },
    {
      name: "Jack Mc Namara Field Airport",
      city: "Crescent City",
      country: "United States",
      value: "CEC",
      display: "CEC"
    },
    {
      name: "Cape Girardeau Regional Airport",
      city: "Cape Girardeau",
      country: "United States",
      value: "CGI",
      display: "CGI"
    },
    {
      name: "Chippewa County International Airport",
      city: "Sault Ste Marie",
      country: "United States",
      value: "CIU",
      display: "CIU"
    },
    {
      name: "North Central West Virginia Airport",
      city: "Clarksburg",
      country: "United States",
      value: "CKB",
      display: "CKB"
    },
    {
      name: "William R Fairchild International Airport",
      city: "Port Angeles",
      country: "United States",
      value: "CLM",
      display: "CLM"
    },
    {
      name: "Houghton County Memorial Airport",
      city: "Hancock",
      country: "United States",
      value: "CMX",
      display: "CMX"
    },
    {
      name: "Dodge City Regional Airport",
      city: "Dodge City",
      country: "United States",
      value: "DDC",
      display: "DDC"
    },
    {
      name: "DuBois Regional Airport",
      city: "Du Bois",
      country: "United States",
      value: "DUJ",
      display: "DUJ"
    },
    {
      name: "Chippewa Valley Regional Airport",
      city: "Eau Claire",
      country: "United States",
      value: "EAU",
      display: "EAU"
    },
    {
      name: "Elko Regional Airport",
      city: "Elko",
      country: "United States",
      value: "EKO",
      display: "EKO"
    },
    {
      name: "New Bedford Regional Airport",
      city: "New Bedford",
      country: "United States",
      value: "EWB",
      display: "EWB"
    },
    {
      name: "Fayetteville Regional Grannis Field",
      city: "Fayetteville",
      country: "United States",
      value: "FAY",
      display: "FAY"
    },
    {
      name: "Wokal Field Glasgow International Airport",
      city: "Glasgow",
      country: "United States",
      value: "GGW",
      display: "GGW"
    },
    {
      name: "Central Nebraska Regional Airport",
      city: "Grand Island",
      country: "United States",
      value: "GRI",
      display: "GRI"
    },
    {
      name: "Memorial Field",
      city: "Hot Springs",
      country: "United States",
      value: "HOT",
      display: "HOT"
    },
    {
      name: "Tri-State/Milton J. Ferguson Field",
      city: "Huntington",
      country: "United States",
      value: "HTS",
      display: "HTS"
    },
    {
      name: "Kili Airport",
      city: "Kili Island",
      country: "Marshall Islands",
      value: "KIO",
      display: "KIO"
    },
    {
      name: "Kirksville Regional Airport",
      city: "Kirksville",
      country: "United States",
      value: "IRK",
      display: "IRK"
    },
    {
      name: "Jamestown Regional Airport",
      city: "Jamestown",
      country: "United States",
      value: "JMS",
      display: "JMS"
    },
    {
      name: "Laramie Regional Airport",
      city: "Laramie",
      country: "United States",
      value: "LAR",
      display: "LAR"
    },
    {
      name: "Arnold Palmer Regional Airport",
      city: "Latrobe",
      country: "United States",
      value: "LBE",
      display: "LBE"
    },
    {
      name: "North Platte Regional Airport Lee Bird Field",
      city: "North Platte",
      country: "United States",
      value: "LBF",
      display: "LBF"
    },
    {
      name: "Lebanon Municipal Airport",
      city: "Lebanon",
      country: "United States",
      value: "LEB",
      display: "LEB"
    },
    {
      name: "Klamath Falls Airport",
      city: "Klamath Falls",
      country: "United States",
      value: "LMT",
      display: "LMT"
    },
    {
      name: "Lancaster Airport",
      city: "Lancaster",
      country: "United States",
      value: "LNS",
      display: "LNS"
    },
    {
      name: "Lewistown Municipal Airport",
      city: "Lewistown",
      country: "United States",
      value: "LWT",
      display: "LWT"
    },
    {
      name: "Lynchburg Regional Preston Glenn Field",
      city: "Lynchburg",
      country: "United States",
      value: "LYH",
      display: "LYH"
    },
    {
      name: "Muskegon County Airport",
      city: "Muskegon",
      country: "United States",
      value: "MKG",
      display: "MKG"
    },
    {
      name: "Frank Wiley Field",
      city: "Miles City",
      country: "United States",
      value: "MLS",
      display: "MLS"
    },
    {
      name: "Northwest Alabama Regional Airport",
      city: "Muscle Shoals",
      country: "United States",
      value: "MSL",
      display: "MSL"
    },
    {
      name: "Southwest Oregon Regional Airport",
      city: "North Bend",
      country: "United States",
      value: "OTH",
      display: "OTH"
    },
    {
      name: "Owensboro Daviess County Airport",
      city: "Owensboro",
      country: "United States",
      value: "OWB",
      display: "OWB"
    },
    {
      name: "Hattiesburg Laurel Regional Airport",
      city: "Hattiesburg/Laurel",
      country: "United States",
      value: "PIB",
      display: "PIB"
    },
    {
      name: "Pocatello Regional Airport",
      city: "Pocatello",
      country: "United States",
      value: "PIH",
      display: "PIH"
    },
    {
      name: "Pierre Regional Airport",
      city: "Pierre",
      country: "United States",
      value: "PIR",
      display: "PIR"
    },
    {
      name: "Pellston Regional Airport of Emmet County Airport",
      city: "Pellston",
      country: "United States",
      value: "PLN",
      display: "PLN"
    },
    {
      name: "Portsmouth International at Pease Airport",
      city: "Portsmouth",
      country: "United States",
      value: "PSM",
      display: "PSM"
    },
    {
      name: "Reading Regional Carl A Spaatz Field",
      city: "Reading",
      country: "United States",
      value: "RDG",
      display: "RDG"
    },
    {
      name: "Rhinelander Oneida County Airport",
      city: "Rhinelander",
      country: "United States",
      value: "RHI",
      display: "RHI"
    },
    {
      name: "Rock Springs Sweetwater County Airport",
      city: "Rock Springs",
      country: "United States",
      value: "RKS",
      display: "RKS"
    },
    {
      name: "Rutland - Southern Vermont Regional Airport",
      city: "Rutland",
      country: "United States",
      value: "RUT",
      display: "RUT"
    },
    {
      name: "San Luis County Regional Airport",
      city: "San Luis Obispo",
      country: "United States",
      value: "SBP",
      display: "SBP"
    },
    {
      name: "Sheridan County Airport",
      city: "Sheridan",
      country: "United States",
      value: "SHR",
      display: "SHR"
    },
    {
      name: "Adirondack Regional Airport",
      city: "Saranac Lake",
      country: "United States",
      value: "SLK",
      display: "SLK"
    },
    {
      name: "Salina Municipal Airport",
      city: "Salina",
      country: "United States",
      value: "SLN",
      display: "SLN"
    },
    {
      name: "Santa Maria Pub/Capt G Allan Hancock Field",
      city: "Santa Maria",
      country: "United States",
      value: "SMX",
      display: "SMX"
    },
    {
      name: "Tupelo Regional Airport",
      city: "Tupelo",
      country: "United States",
      value: "TUP",
      display: "TUP"
    },
    {
      name: "Quincy Regional Baldwin Field",
      city: "Quincy",
      country: "United States",
      value: "UIN",
      display: "UIN"
    },
    {
      name: "Victoria Regional Airport",
      city: "Victoria",
      country: "United States",
      value: "VCT",
      display: "VCT"
    },
    {
      name: "Valdosta Regional Airport",
      city: "Valdosta",
      country: "United States",
      value: "VLD",
      display: "VLD"
    },
    {
      name: "Worland Municipal Airport",
      city: "Worland",
      country: "United States",
      value: "WRL",
      display: "WRL"
    },
    {
      name: "Yakima Air Terminal McAllister Field",
      city: "Yakima",
      country: "United States",
      value: "YKM",
      display: "YKM"
    },
    {
      name: "Ercan International Airport",
      city: "Nicosia",
      country: "Cyprus",
      value: "ECN",
      display: "ECN"
    },
    {
      name: "Logrono-Agoncillo Airport",
      city: "Logrono-Agoncillo",
      country: "Spain",
      value: "RJL",
      display: "RJL"
    },
    {
      name: "Ile d'Yeu Airport",
      city: "Ile d Yeu",
      country: "France",
      value: "IDY",
      display: "IDY"
    },
    {
      name: "Angers-Loire Airport",
      city: "Angers/Marce",
      country: "France",
      value: "ANE",
      display: "ANE"
    },
    {
      name: "La Mole Airport",
      city: "La Mole",
      country: "France",
      value: "LTT",
      display: "LTT"
    },
    {
      name: "Syros Airport",
      city: "Syros Island",
      country: "Greece",
      value: "JSY",
      display: "JSY"
    },
    {
      name: "Pecs-Pogany Airport",
      city: "Pecs-Pogany",
      country: "Hungary",
      value: "PEV",
      display: "PEV"
    },
    {
      name: "Gyor-Per International Airport",
      city: "Gyor",
      country: "Hungary",
      value: "QGY",
      display: "QGY"
    },
    {
      name: "Sarmellek International Airport",
      city: "Sarmellek",
      country: "Hungary",
      value: "SOB",
      display: "SOB"
    },
    {
      name: "Aosta Airport",
      city: "Aosta",
      country: "Italy",
      value: "AOT",
      display: "AOT"
    },
    {
      name: "Salerno Costa d'Amalfi Airport",
      city: "Salerno",
      country: "Italy",
      value: "QSR",
      display: "QSR"
    },
    {
      name: "Corvo Airport",
      city: "Corvo",
      country: "Portugal",
      value: "CVU",
      display: "CVU"
    },
    {
      name: "Banja Luka International Airport",
      city: "Banja Luka",
      country: "Bosnia and Herzegovina",
      value: "BNX",
      display: "BNX"
    },
    {
      name: "Usak Airport",
      city: "Usak",
      country: "Turkey",
      value: "USQ",
      display: "USQ"
    },
    {
      name: "Kars Airport",
      city: "Kars",
      country: "Turkey",
      value: "KSY",
      display: "KSY"
    },
    {
      name: "Sanl?urfa Airport",
      city: "Sanliurfa",
      country: "Turkey",
      value: "SFQ",
      display: "SFQ"
    },
    {
      name: "Kahramanmaras Airport",
      city: "Kahramanmaras",
      country: "Turkey",
      value: "KCM",
      display: "KCM"
    },
    {
      name: "Agr? Airport",
      city: "Agri",
      country: "Turkey",
      value: "AJI",
      display: "AJI"
    },
    {
      name: "Ad?yaman Airport",
      city: "Adiyaman",
      country: "Turkey",
      value: "ADF",
      display: "ADF"
    },
    {
      name: "Suleyman Demirel International Airport",
      city: "Isparta",
      country: "Turkey",
      value: "ISE",
      display: "ISE"
    },
    {
      name: "Bal?kesir Korfez Airport",
      city: "Balikesir Korfez",
      country: "Turkey",
      value: "EDO",
      display: "EDO"
    },
    {
      name: "Samsun Carsamba Airport",
      city: "Samsun",
      country: "Turkey",
      value: "SZF",
      display: "SZF"
    },
    {
      name: "Zilina Airport",
      city: "Zilina",
      country: "Slovakia",
      value: "ILZ",
      display: "ILZ"
    },
    {
      name: "JAGS McCartney International Airport",
      city: "Cockburn Town",
      country: "Turks and Caicos Islands",
      value: "GDT",
      display: "GDT"
    },
    {
      name: "Middle Caicos Airport",
      city: "Middle Caicos",
      country: "Turks and Caicos Islands",
      value: "MDS",
      display: "MDS"
    },
    {
      name: "Salt Cay Airport",
      city: "Salt Cay",
      country: "Turks and Caicos Islands",
      value: "SLX",
      display: "SLX"
    },
    {
      name: "Samana El Catey International Airport",
      city: "Samana",
      country: "Dominican Republic",
      value: "AZS",
      display: "AZS"
    },
    {
      name: "La Isabela International Airport",
      city: "La Isabela",
      country: "Dominican Republic",
      value: "JBQ",
      display: "JBQ"
    },
    {
      name: "Puerto Barrios Airport",
      city: "Puerto Barrios",
      country: "Guatemala",
      value: "PBR",
      display: "PBR"
    },
    {
      name: "Quezaltenango Airport",
      city: "Quezaltenango",
      country: "Guatemala",
      value: "AAZ",
      display: "AAZ"
    },
    {
      name: "Utirik Airport",
      city: "Utirik Island",
      country: "Marshall Islands",
      value: "UTK",
      display: "UTK"
    },
    {
      name: "Ahuas Airport",
      city: "Ahuas",
      country: "Honduras",
      value: "AHS",
      display: "AHS"
    },
    {
      name: "Puerto Lempira Airport",
      city: "Puerto Lempira",
      country: "Honduras",
      value: "PEU",
      display: "PEU"
    },
    {
      name: "Mili Island Airport",
      city: "Mili Island",
      country: "Marshall Islands",
      value: "MIJ",
      display: "MIJ"
    },
    {
      name: "Captain Rogelio Castillo National Airport",
      city: "Celaya",
      country: "Mexico",
      value: "CYW",
      display: "CYW"
    },
    {
      name: "Ciudad Constitucion Airport",
      city: "Ciudad Constitucion",
      country: "Mexico",
      value: "CUA",
      display: "CUA"
    },
    {
      name: "Guerrero Negro Airport",
      city: "Guerrero Negro",
      country: "Mexico",
      value: "GUB",
      display: "GUB"
    },
    {
      name: "El Lencero Airport",
      city: "Jalapa",
      country: "Mexico",
      value: "JAL",
      display: "JAL"
    },
    {
      name: "Alonso Valderrama Airport",
      city: "Chitre",
      country: "Panama",
      value: "CTD",
      display: "CTD"
    },
    {
      name: "Enrique Adolfo Jimenez Airport",
      city: "Colon",
      country: "Panama",
      value: "ONX",
      display: "ONX"
    },
    {
      name: "Jaque Airport",
      city: "Jaque",
      country: "Panama",
      value: "JQE",
      display: "JQE"
    },
    {
      name: "Captain Ramon Xatruch Airport",
      city: "La Palma",
      country: "Panama",
      value: "PLP",
      display: "PLP"
    },
    {
      name: "Aerotortuguero Airport",
      city: "Roxana",
      country: "Costa Rica",
      value: "TTQ",
      display: "TTQ"
    },
    {
      name: "Barra del Colorado Airport",
      city: "Pococi",
      country: "Costa Rica",
      value: "BCL",
      display: "BCL"
    },
    {
      name: "Cabo Velas Airport",
      city: "Nicoya",
      country: "Costa Rica",
      value: "TNO",
      display: "TNO"
    },
    {
      name: "Islita Airport",
      city: "Nandayure",
      country: "Costa Rica",
      value: "PBP",
      display: "PBP"
    },
    {
      name: "Puerto Jimenez Airport",
      city: "Puerto Jimenez",
      country: "Costa Rica",
      value: "PJM",
      display: "PJM"
    },
    {
      name: "Tobias Bolanos International Airport",
      city: "San Jose",
      country: "Costa Rica",
      value: "SYQ",
      display: "SYQ"
    },
    {
      name: "Jeremie Airport",
      city: "Jeremie",
      country: "Haiti",
      value: "JEE",
      display: "JEE"
    },
    {
      name: "Port-de-Paix Airport",
      city: "Port-de-Paix",
      country: "Haiti",
      value: "PAX",
      display: "PAX"
    },
    {
      name: "Alberto Delgado Airport",
      city: "Trinidad",
      country: "Cuba",
      value: "TND",
      display: "TND"
    },
    {
      name: "Congo Town Airport",
      city: "Andros",
      country: "Bahamas",
      value: "COX",
      display: "COX"
    },
    {
      name: "Arthur's Town Airport",
      city: "Arthur s Town",
      country: "Bahamas",
      value: "ATC",
      display: "ATC"
    },
    {
      name: "New Bight Airport",
      city: "Cat Island",
      country: "Bahamas",
      value: "CAT",
      display: "CAT"
    },
    {
      name: "Colonel Hill Airport",
      city: "Colonel Hill",
      country: "Bahamas",
      value: "CRI",
      display: "CRI"
    },
    {
      name: "Nassau Paradise Island Airport",
      city: "Nassau",
      country: "Bahamas",
      value: "PID",
      display: "PID"
    },
    {
      name: "Enua Airport",
      city: "Atiu Island",
      country: "Cook Islands",
      value: "AIU",
      display: "AIU"
    },
    {
      name: "Mangaia Island Airport",
      city: "Mangaia Island",
      country: "Cook Islands",
      value: "MGS",
      display: "MGS"
    },
    {
      name: "Manihiki Island Airport",
      city: "Manihiki Island",
      country: "Cook Islands",
      value: "MHX",
      display: "MHX"
    },
    {
      name: "Mauke Airport",
      city: "Mauke Island",
      country: "Cook Islands",
      value: "MUK",
      display: "MUK"
    },
    {
      name: "Mitiaro Island Airport",
      city: "Mitiaro Island",
      country: "Cook Islands",
      value: "MOI",
      display: "MOI"
    },
    {
      name: "Tongareva Airport",
      city: "Penrhyn Island",
      country: "Cook Islands",
      value: "PYE",
      display: "PYE"
    },
    {
      name: "Cicia Airport",
      city: "Cicia",
      country: "Fiji",
      value: "ICI",
      display: "ICI"
    },
    {
      name: "Malolo Lailai Island Airport",
      city: "Malolo Lailai Island",
      country: "Fiji",
      value: "PTF",
      display: "PTF"
    },
    {
      name: "Vunisea Airport",
      city: "Vunisea",
      country: "Fiji",
      value: "KDV",
      display: "KDV"
    },
    {
      name: "Mana Island Airport",
      city: "Mana Island",
      country: "Fiji",
      value: "MNF",
      display: "MNF"
    },
    {
      name: "Moala Airport",
      city: "Moala",
      country: "Fiji",
      value: "MFJ",
      display: "MFJ"
    },
    {
      name: "Ngau Airport",
      city: "Ngau",
      country: "Fiji",
      value: "NGI",
      display: "NGI"
    },
    {
      name: "Lakeba Island Airport",
      city: "Lakeba Island",
      country: "Fiji",
      value: "LKB",
      display: "LKB"
    },
    {
      name: "Labasa Airport",
      city: "Lambasa",
      country: "Fiji",
      value: "LBS",
      display: "LBS"
    },
    {
      name: "Matei Airport",
      city: "Matei",
      country: "Fiji",
      value: "TVU",
      display: "TVU"
    },
    {
      name: "Koro Island Airport",
      city: "Koro Island",
      country: "Fiji",
      value: "KXF",
      display: "KXF"
    },
    {
      name: "Rotuma Airport",
      city: "Rotuma",
      country: "Fiji",
      value: "RTA",
      display: "RTA"
    },
    {
      name: "Savusavu Airport",
      city: "Savusavu",
      country: "Fiji",
      value: "SVU",
      display: "SVU"
    },
    {
      name: "Kaufana Airport",
      city: "Eua Island",
      country: "Tonga",
      value: "EUA",
      display: "EUA"
    },
    {
      name: "Lifuka Island Airport",
      city: "Lifuka",
      country: "Tonga",
      value: "HPA",
      display: "HPA"
    },
    {
      name: "Mata'aho Airport",
      city: "Angaha, Niuafo ou Island",
      country: "Tonga",
      value: "NFO",
      display: "NFO"
    },
    {
      name: "Kuini Lavenia Airport",
      city: "Niuatoputapu",
      country: "Tonga",
      value: "NTT",
      display: "NTT"
    },
    {
      name: "Vanua Balavu Airport",
      city: "Vanua Balavu",
      country: "Fiji",
      value: "VBV",
      display: "VBV"
    },
    {
      name: "Niue International Airport",
      city: "Alofi",
      country: "Niue",
      value: "IUE",
      display: "IUE"
    },
    {
      name: "Pointe Vele Airport",
      city: "Futuna Island",
      country: "Wallis and Futuna",
      value: "FUT",
      display: "FUT"
    },
    {
      name: "Maota Airport",
      city: "Savaii Island",
      country: "Samoa",
      value: "MXS",
      display: "MXS"
    },
    {
      name: "Apataki Airport",
      city: "Apataki",
      country: "French Polynesia",
      value: "APK",
      display: "APK"
    },
    {
      name: "Ahe Airport",
      city: "Ahe",
      country: "French Polynesia",
      value: "AHE",
      display: "AHE"
    },
    {
      name: "Hiva Oa-Atuona Airport",
      city: "Hiva-oa",
      country: "French Polynesia",
      value: "AUQ",
      display: "AUQ"
    },
    {
      name: "Ua Pou Airport",
      city: "Ua Pou",
      country: "French Polynesia",
      value: "UAP",
      display: "UAP"
    },
    {
      name: "Ua Huka Airport",
      city: "Ua Huka",
      country: "French Polynesia",
      value: "UAH",
      display: "UAH"
    },
    {
      name: "Mota Lava Airport",
      city: "Ablow",
      country: "Vanuatu",
      value: "MTV",
      display: "MTV"
    },
    {
      name: "Sola Airport",
      city: "Sola",
      country: "Vanuatu",
      value: "SLH",
      display: "SLH"
    },
    {
      name: "Torres Airstrip",
      city: "Loh/Linua",
      country: "Vanuatu",
      value: "TOH",
      display: "TOH"
    },
    {
      name: "Siwo Airport",
      city: "Sangafa",
      country: "Vanuatu",
      value: "EAE",
      display: "EAE"
    },
    {
      name: "Craig Cove Airport",
      city: "Craig Cove",
      country: "Vanuatu",
      value: "CCV",
      display: "CCV"
    },
    {
      name: "Longana Airport",
      city: "Longana",
      country: "Vanuatu",
      value: "LOD",
      display: "LOD"
    },
    {
      name: "Sara Airport",
      city: "Pentecost Island",
      country: "Vanuatu",
      value: "SSR",
      display: "SSR"
    },
    {
      name: "Tavie Airport",
      city: "Paama Island",
      country: "Vanuatu",
      value: "PBJ",
      display: "PBJ"
    },
    {
      name: "Lamap Airport",
      city: "Lamap",
      country: "Vanuatu",
      value: "LPM",
      display: "LPM"
    },
    {
      name: "Lamen Bay Airport",
      city: "Lamen Bay",
      country: "Vanuatu",
      value: "LNB",
      display: "LNB"
    },
    {
      name: "Maewo-Naone Airport",
      city: "Maewo Island",
      country: "Vanuatu",
      value: "MWF",
      display: "MWF"
    },
    {
      name: "Lonorore Airport",
      city: "Lonorore",
      country: "Vanuatu",
      value: "LNE",
      display: "LNE"
    },
    {
      name: "Norsup Airport",
      city: "Norsup",
      country: "Vanuatu",
      value: "NUS",
      display: "NUS"
    },
    {
      name: "Gaua Island Airport",
      city: "Gaua Island",
      country: "Vanuatu",
      value: "ZGU",
      display: "ZGU"
    },
    {
      name: "Redcliffe Airport",
      city: "Redcliffe",
      country: "Vanuatu",
      value: "RCL",
      display: "RCL"
    },
    {
      name: "Santo Pekoa International Airport",
      city: "Santo",
      country: "Vanuatu",
      value: "SON",
      display: "SON"
    },
    {
      name: "Tongoa Airport",
      city: "Tongoa Island",
      country: "Vanuatu",
      value: "TGH",
      display: "TGH"
    },
    {
      name: "Ulei Airport",
      city: "Ambryn Island",
      country: "Vanuatu",
      value: "ULB",
      display: "ULB"
    },
    {
      name: "Valesdir Airport",
      city: "Valesdir",
      country: "Vanuatu",
      value: "VLS",
      display: "VLS"
    },
    {
      name: "Southwest Bay Airport",
      city: "Malekula Island",
      country: "Vanuatu",
      value: "SWJ",
      display: "SWJ"
    },
    {
      name: "North West Santo Airport",
      city: "Olpoi",
      country: "Vanuatu",
      value: "OLZ",
      display: "OLZ"
    },
    {
      name: "Aneityum Airport",
      city: "Anelghowhat",
      country: "Vanuatu",
      value: "AUY",
      display: "AUY"
    },
    {
      name: "Aniwa Airport",
      city: "Aniwa",
      country: "Vanuatu",
      value: "AWD",
      display: "AWD"
    },
    {
      name: "Dillon's Bay Airport",
      city: "Dillon s Bay",
      country: "Vanuatu",
      value: "DLY",
      display: "DLY"
    },
    {
      name: "Futuna Airport",
      city: "Futuna Island",
      country: "Vanuatu",
      value: "FTA",
      display: "FTA"
    },
    {
      name: "Ipota Airport",
      city: "Ipota",
      country: "Vanuatu",
      value: "IPA",
      display: "IPA"
    },
    {
      name: "Tiga Airport",
      city: "Tiga",
      country: "New Caledonia",
      value: "TGJ",
      display: "TGJ"
    },
    {
      name: "Ile Art - Waala Airport",
      city: "Waala",
      country: "New Caledonia",
      value: "BMY",
      display: "BMY"
    },
    {
      name: "Ile des Pins Airport",
      city: "Ile des Pins",
      country: "New Caledonia",
      value: "ILP",
      display: "ILP"
    },
    {
      name: "Fayzabad Airport",
      city: "Faizabad",
      country: "Afghanistan",
      value: "FBD",
      display: "FBD"
    },
    {
      name: "Dawadmi Domestic Airport",
      city: "Dawadmi",
      country: "Saudi Arabia",
      value: "DWD",
      display: "DWD"
    },
    {
      name: "Al-Jawf Domestic Airport",
      city: "Al-Jawf",
      country: "Saudi Arabia",
      value: "AJF",
      display: "AJF"
    },
    {
      name: "Wadi Al Dawasir Airport",
      city: "Wadi-al-dawasir",
      country: "Saudi Arabia",
      value: "EWD",
      display: "EWD"
    },
    {
      name: "Khoram Abad Airport",
      city: "Khorram Abad",
      country: "Iran",
      value: "KHD",
      display: "KHD"
    },
    {
      name: "Bam Airport",
      city: "Bam",
      country: "Iran",
      value: "BXR",
      display: "BXR"
    },
    {
      name: "Rafsanjan Airport",
      city: "Rafsanjan",
      country: "Iran",
      value: "RJN",
      display: "RJN"
    },
    {
      name: "Bojnord Airport",
      city: "Bojnourd",
      country: "Iran",
      value: "BJB",
      display: "BJB"
    },
    {
      name: "Sabzevar National Airport",
      city: "Sabzevar",
      country: "Iran",
      value: "AFZ",
      display: "AFZ"
    },
    {
      name: "Noshahr Airport",
      city: "Noshahr",
      country: "Iran",
      value: "NSH",
      display: "NSH"
    },
    {
      name: "Dasht-e Naz Airport",
      city: "Dasht-e-naz",
      country: "Iran",
      value: "SRY",
      display: "SRY"
    },
    {
      name: "Lar Airport",
      city: "Lar",
      country: "Iran",
      value: "LRR",
      display: "LRR"
    },
    {
      name: "Ardabil Airport",
      city: "Ardabil",
      country: "Iran",
      value: "ADU",
      display: "ADU"
    },
    {
      name: "Urmia Airport",
      city: "Uromiyeh",
      country: "Iran",
      value: "OMH",
      display: "OMH"
    },
    {
      name: "Al Ain International Airport",
      city: "Al Ain",
      country: "United Arab Emirates",
      value: "AAN",
      display: "AAN"
    },
    {
      name: "Bannu Airport",
      city: "Bannu",
      country: "Pakistan",
      value: "BNP",
      display: "BNP"
    },
    {
      name: "Bahawalpur Airport",
      city: "Bahawalpur",
      country: "Pakistan",
      value: "BHV",
      display: "BHV"
    },
    {
      name: "Chitral Airport",
      city: "Chitral",
      country: "Pakistan",
      value: "CJL",
      display: "CJL"
    },
    {
      name: "Dalbandin Airport",
      city: "Dalbandin",
      country: "Pakistan",
      value: "DBA",
      display: "DBA"
    },
    {
      name: "Dera Ghazi Khan Airport",
      city: "Dera Ghazi Khan",
      country: "Pakistan",
      value: "DEA",
      display: "DEA"
    },
    {
      name: "Dera Ismael Khan Airport",
      city: "Dera Ismael Khan",
      country: "Pakistan",
      value: "DSK",
      display: "DSK"
    },
    {
      name: "Jiwani Airport",
      city: "Jiwani",
      country: "Pakistan",
      value: "JIW",
      display: "JIW"
    },
    {
      name: "Hyderabad Airport",
      city: "Hyderabad",
      country: "Pakistan",
      value: "HDD",
      display: "HDD"
    },
    {
      name: "Khuzdar Airport",
      city: "Khuzdar",
      country: "Pakistan",
      value: "KDD",
      display: "KDD"
    },
    {
      name: "Ormara Airport",
      city: "Ormara Raik",
      country: "Pakistan",
      value: "ORW",
      display: "ORW"
    },
    {
      name: "Parachinar Airport",
      city: "Parachinar",
      country: "Pakistan",
      value: "PAJ",
      display: "PAJ"
    },
    {
      name: "Skardu Airport",
      city: "Skardu",
      country: "Pakistan",
      value: "KDU",
      display: "KDU"
    },
    {
      name: "Sehwan Sharif Airport",
      city: "Sehwan Sharif",
      country: "Pakistan",
      value: "SYW",
      display: "SYW"
    },
    {
      name: "Turbat International Airport",
      city: "Turbat",
      country: "Pakistan",
      value: "TUK",
      display: "TUK"
    },
    {
      name: "Sulaymaniyah International Airport",
      city: "Sulaymaniyah",
      country: "Iraq",
      value: "ISU",
      display: "ISU"
    },
    {
      name: "Kamishly Airport",
      city: "Kamishly",
      country: "Syria",
      value: "KAC",
      display: "KAC"
    },
    {
      name: "Sayun International Airport",
      city: "Sayun Intl",
      country: "Yemen",
      value: "GXF",
      display: "GXF"
    },
    {
      name: "Adak Airport",
      city: "Adak Island",
      country: "United States",
      value: "ADK",
      display: "ADK"
    },
    {
      name: "Gustavus Airport",
      city: "Gustavus",
      country: "United States",
      value: "GST",
      display: "GST"
    },
    {
      name: "Skagway Airport",
      city: "Skagway",
      country: "United States",
      value: "SGY",
      display: "SGY"
    },
    {
      name: "Holy Cross Airport",
      city: "Holy Cross",
      country: "United States",
      value: "HCR",
      display: "HCR"
    },
    {
      name: "Haines Airport",
      city: "Haines",
      country: "United States",
      value: "HNS",
      display: "HNS"
    },
    {
      name: "Kalskag Airport",
      city: "Kalskag",
      country: "United States",
      value: "KLG",
      display: "KLG"
    },
    {
      name: "McGrath Airport",
      city: "Mcgrath",
      country: "United States",
      value: "MCG",
      display: "MCG"
    },
    {
      name: "Mountain Village Airport",
      city: "Mountain Village",
      country: "United States",
      value: "MOU",
      display: "MOU"
    },
    {
      name: "Aniak Airport",
      city: "Aniak",
      country: "United States",
      value: "ANI",
      display: "ANI"
    },
    {
      name: "Chevak Airport",
      city: "Chevak",
      country: "United States",
      value: "VAK",
      display: "VAK"
    },
    {
      name: "Wrangell Airport",
      city: "Wrangell",
      country: "United States",
      value: "WRG",
      display: "WRG"
    },
    {
      name: "Kalaupapa Airport",
      city: "Molokai",
      country: "United States",
      value: "LUP",
      display: "LUP"
    },
    {
      name: "Eniwetok Airport",
      city: "Eniwetok Atoll",
      country: "Marshall Islands",
      value: "ENT",
      display: "ENT"
    },
    {
      name: "Matsu Nangan Airport",
      city: "Matsu Islands",
      country: "Taiwan",
      value: "LZN",
      display: "LZN"
    },
    {
      name: "Hengchun Airport",
      city: "Hengchun",
      country: "Taiwan",
      value: "HCN",
      display: "HCN"
    },
    {
      name: "Matsu Beigan Airport",
      city: "Matsu Islands",
      country: "Taiwan",
      value: "MFK",
      display: "MFK"
    },
    {
      name: "Kushiro Airport",
      city: "Kushiro",
      country: "Japan",
      value: "KUH",
      display: "KUH"
    },
    {
      name: "Okadama Airport",
      city: "Sapporo",
      country: "Japan",
      value: "OKD",
      display: "OKD"
    },
    {
      name: "Saga Airport",
      city: "Saga",
      country: "Japan",
      value: "HSG",
      display: "HSG"
    },
    {
      name: "Nagoya Airport",
      city: "Nagoya",
      country: "Japan",
      value: "NKM",
      display: "NKM"
    },
    {
      name: "Iwami Airport",
      city: "Iwami",
      country: "Japan",
      value: "IWJ",
      display: "IWJ"
    },
    {
      name: "Fukushima Airport",
      city: "Fukushima",
      country: "Japan",
      value: "FKS",
      display: "FKS"
    },
    {
      name: "Odate Noshiro Airport",
      city: "Odate Noshiro",
      country: "Japan",
      value: "ONJ",
      display: "ONJ"
    },
    {
      name: "Shonai Airport",
      city: "Shonai",
      country: "Japan",
      value: "SYO",
      display: "SYO"
    },
    {
      name: "Miyakejima Airport",
      city: "Miyakejima",
      country: "Japan",
      value: "MYE",
      display: "MYE"
    },
    {
      name: "Kunsan Air Base",
      city: "Kunsan",
      country: "South Korea",
      value: "KUV",
      display: "KUV"
    },
    {
      name: "Mokpo Heliport",
      city: "Mokpo",
      country: "South Korea",
      value: "MPK",
      display: "MPK"
    },
    {
      name: "Wonju Airport",
      city: "Wonju",
      country: "South Korea",
      value: "WJU",
      display: "WJU"
    },
    {
      name: "Yangyang International Airport",
      city: "Sokcho / Gangneung",
      country: "South Korea",
      value: "YNY",
      display: "YNY"
    },
    {
      name: "Sacheon Air Base",
      city: "Sacheon",
      country: "South Korea",
      value: "HIN",
      display: "HIN"
    },
    {
      name: "Cheongju International Airport",
      city: "Chongju",
      country: "South Korea",
      value: "CJJ",
      display: "CJJ"
    },
    {
      name: "Subic Bay International Airport",
      city: "Olongapo City",
      country: "Philippines",
      value: "SFS",
      display: "SFS"
    },
    {
      name: "Cuyo Airport",
      city: "Cuyo",
      country: "Philippines",
      value: "CYU",
      display: "CYU"
    },
    {
      name: "Camiguin Airport",
      city: "Camiguin",
      country: "Philippines",
      value: "CGM",
      display: "CGM"
    },
    {
      name: "Jolo Airport",
      city: "Jolo",
      country: "Philippines",
      value: "JOL",
      display: "JOL"
    },
    {
      name: "Sanga Sanga Airport",
      city: "Sanga Sanga",
      country: "Philippines",
      value: "SGS",
      display: "SGS"
    },
    {
      name: "Surigao Airport",
      city: "Sangley Point",
      country: "Philippines",
      value: "SUG",
      display: "SUG"
    },
    {
      name: "Tandag Airport",
      city: "Tandag",
      country: "Philippines",
      value: "TDG",
      display: "TDG"
    },
    {
      name: "Naga Airport",
      city: "Naga",
      country: "Philippines",
      value: "WNP",
      display: "WNP"
    },
    {
      name: "Basco Airport",
      city: "Basco",
      country: "Philippines",
      value: "BSO",
      display: "BSO"
    },
    {
      name: "San Fernando Airport",
      city: "San Fernando",
      country: "Philippines",
      value: "SFE",
      display: "SFE"
    },
    {
      name: "Tuguegarao Airport",
      city: "Tuguegarao",
      country: "Philippines",
      value: "TUG",
      display: "TUG"
    },
    {
      name: "Virac Airport",
      city: "Virac",
      country: "Philippines",
      value: "VRC",
      display: "VRC"
    },
    {
      name: "Calbayog Airport",
      city: "Calbayog City",
      country: "Philippines",
      value: "CYP",
      display: "CYP"
    },
    {
      name: "Catarman National Airport",
      city: "Catarman",
      country: "Philippines",
      value: "CRM",
      display: "CRM"
    },
    {
      name: "Moises R. Espinosa Airport",
      city: "Masbate",
      country: "Philippines",
      value: "MBT",
      display: "MBT"
    },
    {
      name: "Roxas Airport",
      city: "Roxas City",
      country: "Philippines",
      value: "RXS",
      display: "RXS"
    },
    {
      name: "General Enrique Mosconi Airport",
      city: "Tartagal",
      country: "Argentina",
      value: "TTG",
      display: "TTG"
    },
    {
      name: "Las Heras Airport",
      city: "Las Heras",
      country: "Argentina",
      value: "LHS",
      display: "LHS"
    },
    {
      name: "Antoine De St Exupery Airport",
      city: "San Antonio Oeste",
      country: "Argentina",
      value: "OES",
      display: "OES"
    },
    {
      name: "Lago Argentino Airport",
      city: "El Calafate",
      country: "Argentina",
      value: "ING",
      display: "ING"
    },
    {
      name: "Gobernador Gregores Airport",
      city: "Gobernador Gregores",
      country: "Argentina",
      value: "GGS",
      display: "GGS"
    },
    {
      name: "Santa Teresita Airport",
      city: "Santa Teresita",
      country: "Argentina",
      value: "SST",
      display: "SST"
    },
    {
      name: "Necochea Airport",
      city: "Necochea",
      country: "Argentina",
      value: "NEC",
      display: "NEC"
    },
    {
      name: "Orlando Bezerra de Menezes Airport",
      city: "Juazeiro Do Norte",
      country: "Brazil",
      value: "JDO",
      display: "JDO"
    },
    {
      name: "Coronel Horacio de Mattos Airport",
      city: "Lencois",
      country: "Brazil",
      value: "LEC",
      display: "LEC"
    },
    {
      name: "Macae Airport",
      city: "Macae",
      country: "Brazil",
      value: "MEA",
      display: "MEA"
    },
    {
      name: "Frank Miloye Milenkowichi-Marilia State Airport",
      city: "Marilia",
      country: "Brazil",
      value: "MII",
      display: "MII"
    },
    {
      name: "Vitoria da Conquista Airport",
      city: "Vitoria Da Conquista",
      country: "Brazil",
      value: "VDC",
      display: "VDC"
    },
    {
      name: "Santa Maria Airport",
      city: "Santa Maria",
      country: "Brazil",
      value: "RIA",
      display: "RIA"
    },
    {
      name: "Toledo Airport",
      city: "Toledo",
      country: "Brazil",
      value: "TOW",
      display: "TOW"
    },
    {
      name: "Ricardo Garcia Posada Airport",
      city: "El Salvador",
      country: "Chile",
      value: "ESR",
      display: "ESR"
    },
    {
      name: "Pucon Airport",
      city: "Pucon",
      country: "Chile",
      value: "ZPC",
      display: "ZPC"
    },
    {
      name: "Sorocaba Airport",
      city: "Sorocaba",
      country: "Brazil",
      value: "SOD",
      display: "SOD"
    },
    {
      name: "San Cristobal Airport",
      city: "San Cristobal",
      country: "Ecuador",
      value: "SCY",
      display: "SCY"
    },
    {
      name: "Camilo Ponce Enriquez Airport",
      city: "La Toma (Catamayo)",
      country: "Ecuador",
      value: "LOH",
      display: "LOH"
    },
    {
      name: "General Rivadeneira Airport",
      city: "Esmeraldas",
      country: "Ecuador",
      value: "ESM",
      display: "ESM"
    },
    {
      name: "Port Stanley Airport",
      city: "Stanley",
      country: "Falkland Islands",
      value: "PSY",
      display: "PSY"
    },
    {
      name: "Santa Ana Airport",
      city: "Cartago",
      country: "Colombia",
      value: "CRC",
      display: "CRC"
    },
    {
      name: "La Jagua Airport",
      city: "Garzon",
      country: "Colombia",
      value: "GLJ",
      display: "GLJ"
    },
    {
      name: "Caucaya Airport",
      city: "Puerto Leguizamo",
      country: "Colombia",
      value: "LQM",
      display: "LQM"
    },
    {
      name: "La Pedrera Airport",
      city: "La Pedrera",
      country: "Colombia",
      value: "LPD",
      display: "LPD"
    },
    {
      name: "Reyes Murillo Airport",
      city: "Nuqui",
      country: "Colombia",
      value: "NQU",
      display: "NQU"
    },
    {
      name: "Obando Airport",
      city: "Puerto Inirida",
      country: "Colombia",
      value: "PDA",
      display: "PDA"
    },
    {
      name: "El Yopal Airport",
      city: "Yopal",
      country: "Colombia",
      value: "EYP",
      display: "EYP"
    },
    {
      name: "Capitan de Av. Emilio Beltran Airport",
      city: "Guayaramerin",
      country: "Bolivia",
      value: "GYA",
      display: "GYA"
    },
    {
      name: "Puerto Rico Airport",
      city: "Puerto Rico/Manuripi",
      country: "Bolivia",
      value: "PUR",
      display: "PUR"
    },
    {
      name: "Capitan Av. Selin Zeitun Lopez Airport",
      city: "Riberalta",
      country: "Bolivia",
      value: "RIB",
      display: "RIB"
    },
    {
      name: "Reyes Airport",
      city: "Reyes",
      country: "Bolivia",
      value: "REY",
      display: "REY"
    },
    {
      name: "Capitan Av. German Quiroga G. Airport",
      city: "San Borja",
      country: "Bolivia",
      value: "SRJ",
      display: "SRJ"
    },
    {
      name: "Zorg en Hoop Airport",
      city: "Paramaribo",
      country: "Suriname",
      value: "ORG",
      display: "ORG"
    },
    {
      name: "Mucuri Airport",
      city: "Mucuri",
      country: "Brazil",
      value: "MVS",
      display: "MVS"
    },
    {
      name: "Caballococha Airport",
      city: "Caballococha",
      country: "Peru",
      value: "LHC",
      display: "LHC"
    },
    {
      name: "Mayor General FAP Armando Revoredo Iglesias Airport",
      city: "Cajamarca",
      country: "Peru",
      value: "CJA",
      display: "CJA"
    },
    {
      name: "Alferez Fap David Figueroa Fernandini Airport",
      city: "Huanuco",
      country: "Peru",
      value: "HUU",
      display: "HUU"
    },
    {
      name: "Maria Reiche Neuman Airport",
      city: "Nazca",
      country: "Peru",
      value: "NZC",
      display: "NZC"
    },
    {
      name: "Santa Rosa Airport",
      city: "Santa Rosa",
      country: "Brazil",
      value: "SRA",
      display: "SRA"
    },
    {
      name: "El Jaguel / Punta del Este Airport",
      city: "Maldonado",
      country: "Uruguay",
      value: "MDO",
      display: "MDO"
    },
    {
      name: "Escuela Mariscal Sucre Airport",
      city: "Maracay",
      country: "Venezuela",
      value: "MYC",
      display: "MYC"
    },
    {
      name: "Juan Pablo Perez Alfonso Airport",
      city: "El Vigia",
      country: "Venezuela",
      value: "VIG",
      display: "VIG"
    },
    {
      name: "Ji-Parana Airport",
      city: "Ji-Parana",
      country: "Brazil",
      value: "JPR",
      display: "JPR"
    },
    {
      name: "Codrington Airport",
      city: "Codrington",
      country: "Antigua and Barbuda",
      value: "BBQ",
      display: "BBQ"
    },
    {
      name: "La Desirade Airport",
      city: "Grande Anse",
      country: "Guadeloupe",
      value: "DSD",
      display: "DSD"
    },
    {
      name: "Baillif Airport",
      city: "Basse Terre",
      country: "Guadeloupe",
      value: "BBR",
      display: "BBR"
    },
    {
      name: "St-Francois Airport",
      city: "St-Francois",
      country: "Guadeloupe",
      value: "SFC",
      display: "SFC"
    },
    {
      name: "Les Bases Airport",
      city: "Grand Bourg",
      country: "Guadeloupe",
      value: "GBJ",
      display: "GBJ"
    },
    {
      name: "Vance W. Amory International Airport",
      city: "Charlestown",
      country: "Saint Kitts and Nevis",
      value: "NEV",
      display: "NEV"
    },
    {
      name: "Virgin Gorda Airport",
      city: "Spanish Town",
      country: "British Virgin Islands",
      value: "VIJ",
      display: "VIJ"
    },
    {
      name: "J F Mitchell Airport",
      city: "Bequia",
      country: "Saint Vincent and the Grenadines",
      value: "BQU",
      display: "BQU"
    },
    {
      name: "Union Island International Airport",
      city: "Union Island",
      country: "Saint Vincent and the Grenadines",
      value: "UNI",
      display: "UNI"
    },
    {
      name: "Kokshetau Airport",
      city: "Kokshetau",
      country: "Kazakhstan",
      value: "KOV",
      display: "KOV"
    },
    {
      name: "Petropavlosk South Airport",
      city: "Petropavlosk",
      country: "Kazakhstan",
      value: "PPK",
      display: "PPK"
    },
    {
      name: "Zhezkazgan Airport",
      city: "Zhezkazgan",
      country: "Kazakhstan",
      value: "DZN",
      display: "DZN"
    },
    {
      name: "Ust-Kamennogorsk Airport",
      city: "Ust Kamenogorsk",
      country: "Kazakhstan",
      value: "UKK",
      display: "UKK"
    },
    {
      name: "Kostanay West Airport",
      city: "Kostanay",
      country: "Kazakhstan",
      value: "KSN",
      display: "KSN"
    },
    {
      name: "Ganja Airport",
      city: "Ganja",
      country: "Azerbaijan",
      value: "KVD",
      display: "KVD"
    },
    {
      name: "Nakhchivan Airport",
      city: "Nakhchivan",
      country: "Azerbaijan",
      value: "NAJ",
      display: "NAJ"
    },
    {
      name: "Chulman Airport",
      city: "Neryungri",
      country: "Russia",
      value: "CNN",
      display: "CNN"
    },
    {
      name: "Polyarny Airport",
      city: "Yakutia",
      country: "Russia",
      value: "PYJ",
      display: "PYJ"
    },
    {
      name: "Chokurdakh Airport",
      city: "Chokurdah",
      country: "Russia",
      value: "CKH",
      display: "CKH"
    },
    {
      name: "Cherskiy Airport",
      city: "Cherskiy",
      country: "Russia",
      value: "CYX",
      display: "CYX"
    },
    {
      name: "Tiksi Airport",
      city: "Tiksi",
      country: "Russia",
      value: "IKS",
      display: "IKS"
    },
    {
      name: "Komsomolsk-on-Amur Airport",
      city: "Komsomolsk-on-Amur",
      country: "Russia",
      value: "KXK",
      display: "KXK"
    },
    {
      name: "Ugolny Airport",
      city: "Anadyr",
      country: "Russia",
      value: "DYR",
      display: "DYR"
    },
    {
      name: "Okhotsk Airport",
      city: "Okhotsk",
      country: "Russia",
      value: "OHO",
      display: "OHO"
    },
    {
      name: "Ujae Atoll Airport",
      city: "Ujae Atoll",
      country: "Marshall Islands",
      value: "UJE",
      display: "UJE"
    },
    {
      name: "Mariupol International Airport",
      city: "Mariupol International",
      country: "Ukraine",
      value: "MPW",
      display: "MPW"
    },
    {
      name: "Luhansk International Airport",
      city: "Lugansk",
      country: "Ukraine",
      value: "VSG",
      display: "VSG"
    },
    {
      name: "Zaporizhzhia International Airport",
      city: "Zaporozhye",
      country: "Ukraine",
      value: "OZH",
      display: "OZH"
    },
    {
      name: "Kryvyi Rih International Airport",
      city: "Krivoy Rog",
      country: "Ukraine",
      value: "KWG",
      display: "KWG"
    },
    {
      name: "Kharkiv International Airport",
      city: "Kharkov",
      country: "Ukraine",
      value: "HRK",
      display: "HRK"
    },
    {
      name: "Ivano-Frankivsk International Airport",
      city: "Ivano-Frankivsk",
      country: "Ukraine",
      value: "IFO",
      display: "IFO"
    },
    {
      name: "Chernivtsi International Airport",
      city: "Chernovtsk",
      country: "Ukraine",
      value: "CWC",
      display: "CWC"
    },
    {
      name: "Rivne International Airport",
      city: "Rivne",
      country: "Ukraine",
      value: "RWN",
      display: "RWN"
    },
    {
      name: "Uzhhorod International Airport",
      city: "Uzhgorod",
      country: "Ukraine",
      value: "UDJ",
      display: "UDJ"
    },
    {
      name: "Solovki Airport",
      city: "Solovetsky Islands",
      country: "Russia",
      value: "CSH",
      display: "CSH"
    },
    {
      name: "Cherepovets Airport",
      city: "Cherepovets",
      country: "Russia",
      value: "CEE",
      display: "CEE"
    },
    {
      name: "Amderma Airport",
      city: "Amderma",
      country: "Russia",
      value: "AMV",
      display: "AMV"
    },
    {
      name: "Kotlas Airport",
      city: "Kotlas",
      country: "Russia",
      value: "KSZ",
      display: "KSZ"
    },
    {
      name: "Petrozavodsk Airport",
      city: "Petrozavodsk",
      country: "Russia",
      value: "PES",
      display: "PES"
    },
    {
      name: "Hrodna Airport",
      city: "Hrodna",
      country: "Belarus",
      value: "GNA",
      display: "GNA"
    },
    {
      name: "Mogilev Airport",
      city: "Mogilev",
      country: "Belarus",
      value: "MVQ",
      display: "MVQ"
    },
    {
      name: "Yeniseysk Airport",
      city: "Yeniseysk",
      country: "Russia",
      value: "EIE",
      display: "EIE"
    },
    {
      name: "Kyzyl Airport",
      city: "Kyzyl",
      country: "Russia",
      value: "KYZ",
      display: "KYZ"
    },
    {
      name: "Spichenkovo Airport",
      city: "Novokuznetsk",
      country: "Russia",
      value: "NOZ",
      display: "NOZ"
    },
    {
      name: "Khatanga Airport",
      city: "Khatanga",
      country: "Russia",
      value: "HTG",
      display: "HTG"
    },
    {
      name: "Igarka Airport",
      city: "Igarka",
      country: "Russia",
      value: "IAA",
      display: "IAA"
    },
    {
      name: "Khankala Air Base",
      city: "Grozny",
      country: "Russia",
      value: "GRV",
      display: "GRV"
    },
    {
      name: "Nalchik Airport",
      city: "Nalchik",
      country: "Russia",
      value: "NAL",
      display: "NAL"
    },
    {
      name: "Beslan Airport",
      city: "Beslan",
      country: "Russia",
      value: "OGZ",
      display: "OGZ"
    },
    {
      name: "Elista Airport",
      city: "Elista",
      country: "Russia",
      value: "ESL",
      display: "ESL"
    },
    {
      name: "Aleknagik / New Airport",
      city: "Aleknagik",
      country: "United States",
      value: "WKK",
      display: "WKK"
    },
    {
      name: "Mercer County Airport",
      city: "Bluefield",
      country: "United States",
      value: "BLF",
      display: "BLF"
    },
    {
      name: "Mid Delta Regional Airport",
      city: "Greenville",
      country: "United States",
      value: "GLH",
      display: "GLH"
    },
    {
      name: "Tri Cities Airport",
      city: "Pasco",
      country: "United States",
      value: "PSC",
      display: "PSC"
    },
    {
      name: "Akutan Seaplane Base",
      city: "Akutan",
      country: "United States",
      value: "KQA",
      display: "KQA"
    },
    {
      name: "Lopez Island Airport",
      city: "Lopez",
      country: "United States",
      value: "LPS",
      display: "LPS"
    },
    {
      name: "Salekhard Airport",
      city: "Salekhard",
      country: "Russia",
      value: "SLY",
      display: "SLY"
    },
    {
      name: "Khanty Mansiysk Airport",
      city: "Khanty-Mansiysk",
      country: "Russia",
      value: "HMA",
      display: "HMA"
    },
    {
      name: "Nyagan Airport",
      city: "Nyagan",
      country: "Russia",
      value: "NYA",
      display: "NYA"
    },
    {
      name: "Sovetskiy Airport",
      city: "Sovetskiy",
      country: "Russia",
      value: "OVS",
      display: "OVS"
    },
    {
      name: "Izhevsk Airport",
      city: "Izhevsk",
      country: "Russia",
      value: "IJK",
      display: "IJK"
    },
    {
      name: "Pobedilovo Airport",
      city: "Kirov",
      country: "Russia",
      value: "KVX",
      display: "KVX"
    },
    {
      name: "Nadym Airport",
      city: "Nadym",
      country: "Russia",
      value: "NYM",
      display: "NYM"
    },
    {
      name: "Raduzhny Airport",
      city: "Raduzhnyi",
      country: "Russia",
      value: "RAT",
      display: "RAT"
    },
    {
      name: "Nefteyugansk Airport",
      city: "Nefteyugansk",
      country: "Russia",
      value: "NFG",
      display: "NFG"
    },
    {
      name: "Kurgan Airport",
      city: "Kurgan",
      country: "Russia",
      value: "KRO",
      display: "KRO"
    },
    {
      name: "Khudzhand Airport",
      city: "Khudzhand",
      country: "Tajikistan",
      value: "LBD",
      display: "LBD"
    },
    {
      name: "Andizhan Airport",
      city: "Andizhan",
      country: "Uzbekistan",
      value: "AZN",
      display: "AZN"
    },
    {
      name: "Fergana International Airport",
      city: "Fergana",
      country: "Uzbekistan",
      value: "FEG",
      display: "FEG"
    },
    {
      name: "Namangan Airport",
      city: "Namangan",
      country: "Uzbekistan",
      value: "NMA",
      display: "NMA"
    },
    {
      name: "Nukus Airport",
      city: "Nukus",
      country: "Uzbekistan",
      value: "NCU",
      display: "NCU"
    },
    {
      name: "Urgench Airport",
      city: "Urgench",
      country: "Uzbekistan",
      value: "UGC",
      display: "UGC"
    },
    {
      name: "Karshi Khanabad Airport",
      city: "Khanabad",
      country: "Uzbekistan",
      value: "KSQ",
      display: "KSQ"
    },
    {
      name: "Termez Airport",
      city: "Termez",
      country: "Uzbekistan",
      value: "TMJ",
      display: "TMJ"
    },
    {
      name: "Staroselye Airport",
      city: "Rybinsk",
      country: "Russia",
      value: "RYB",
      display: "RYB"
    },
    {
      name: "Belgorod International Airport",
      city: "Belgorod",
      country: "Russia",
      value: "EGO",
      display: "EGO"
    },
    {
      name: "Kursk East Airport",
      city: "Kursk",
      country: "Russia",
      value: "URS",
      display: "URS"
    },
    {
      name: "Lipetsk Airport",
      city: "Lipetsk",
      country: "Russia",
      value: "LPK",
      display: "LPK"
    },
    {
      name: "Vorkuta Airport",
      city: "Vorkuta",
      country: "Russia",
      value: "VKT",
      display: "VKT"
    },
    {
      name: "Bugulma Airport",
      city: "Bugulma",
      country: "Russia",
      value: "UUA",
      display: "UUA"
    },
    {
      name: "Yoshkar-Ola Airport",
      city: "Yoshkar-Ola",
      country: "Russia",
      value: "JOK",
      display: "JOK"
    },
    {
      name: "Cheboksary Airport",
      city: "Cheboksary",
      country: "Russia",
      value: "CSY",
      display: "CSY"
    },
    {
      name: "Ulyanovsk East Airport",
      city: "Ulyanovsk",
      country: "Russia",
      value: "ULY",
      display: "ULY"
    },
    {
      name: "Orsk Airport",
      city: "Orsk",
      country: "Russia",
      value: "OSW",
      display: "OSW"
    },
    {
      name: "Penza Airport",
      city: "Penza",
      country: "Russia",
      value: "PEZ",
      display: "PEZ"
    },
    {
      name: "Saransk Airport",
      city: "Saransk",
      country: "Russia",
      value: "SKX",
      display: "SKX"
    },
    {
      name: "Balakovo Airport",
      city: "Balakovo",
      country: "Russia",
      value: "BWO",
      display: "BWO"
    },
    {
      name: "Hubli Airport",
      city: "Hubli",
      country: "India",
      value: "HBX",
      display: "HBX"
    },
    {
      name: "Koggala Airport",
      city: "Koggala",
      country: "Sri Lanka",
      value: "KCT",
      display: "KCT"
    },
    {
      name: "Weerawila Airport",
      city: "Wirawila",
      country: "Sri Lanka",
      value: "WRZ",
      display: "WRZ"
    },
    {
      name: "Battambang Airport",
      city: "Battambang",
      country: "Cambodia",
      value: "BBM",
      display: "BBM"
    },
    {
      name: "Shillong Airport",
      city: "Shillong",
      country: "India",
      value: "SHL",
      display: "SHL"
    },
    {
      name: "Lokpriya Gopinath Bordoloi International Airport",
      city: "Guwahati",
      country: "India",
      value: "GAU",
      display: "GAU"
    },
    {
      name: "Dimapur Airport",
      city: "Dimapur",
      country: "India",
      value: "DMU",
      display: "DMU"
    },
    {
      name: "Tezpur Airport",
      city: "Tezpur",
      country: "India",
      value: "TEZ",
      display: "TEZ"
    },
    {
      name: "Barisal Airport",
      city: "Barisal",
      country: "Bangladesh",
      value: "BZL",
      display: "BZL"
    },
    {
      name: "Ban Huoeisay Airport",
      city: "Huay Xai",
      country: "Laos",
      value: "OUI",
      display: "OUI"
    },
    {
      name: "Bharatpur Airport",
      city: "Bharatpur",
      country: "Nepal",
      value: "BHR",
      display: "BHR"
    },
    {
      name: "Bhadrapur Airport",
      city: "Chandragarhi",
      country: "Nepal",
      value: "BDP",
      display: "BDP"
    },
    {
      name: "Meghauli Airport",
      city: "Meghauli",
      country: "Nepal",
      value: "MEY",
      display: "MEY"
    },
    {
      name: "Nepalgunj Airport",
      city: "Nepalgunj",
      country: "Nepal",
      value: "KEP",
      display: "KEP"
    },
    {
      name: "Gan International Airport",
      city: "Gan Island",
      country: "Maldives",
      value: "GAN",
      display: "GAN"
    },
    {
      name: "Hanimaadhoo Airport",
      city: "Haa Dhaalu Atoll",
      country: "Maldives",
      value: "HAQ",
      display: "HAQ"
    },
    {
      name: "Kadhdhoo Airport",
      city: "Laamu Atoll",
      country: "Maldives",
      value: "KDO",
      display: "KDO"
    },
    {
      name: "Mae Sot Airport",
      city: "Tak",
      country: "Thailand",
      value: "MAQ",
      display: "MAQ"
    },
    {
      name: "Buon Ma Thuot Airport",
      city: "Buonmethuot",
      country: "Vietnam",
      value: "BMV",
      display: "BMV"
    },
    {
      name: "Cat Bi International Airport",
      city: "Haiphong",
      country: "Vietnam",
      value: "HPH",
      display: "HPH"
    },
    {
      name: "Cam Ranh Airport",
      city: "Nha Trang",
      country: "Vietnam",
      value: "CXR",
      display: "CXR"
    },
    {
      name: "Co Ong Airport",
      city: "Conson",
      country: "Vietnam",
      value: "VCS",
      display: "VCS"
    },
    {
      name: "Can Tho International Airport",
      city: "Can Tho",
      country: "Vietnam",
      value: "VCA",
      display: "VCA"
    },
    {
      name: "Dien Bien Phu Airport",
      city: "Dienbienphu",
      country: "Vietnam",
      value: "DIN",
      display: "DIN"
    },
    {
      name: "Phu Cat Airport",
      city: "Phucat",
      country: "Vietnam",
      value: "UIH",
      display: "UIH"
    },
    {
      name: "Pleiku Airport",
      city: "Pleiku",
      country: "Vietnam",
      value: "PXU",
      display: "PXU"
    },
    {
      name: "Vinh Airport",
      city: "Vinh",
      country: "Vietnam",
      value: "VII",
      display: "VII"
    },
    {
      name: "Banmaw Airport",
      city: "Banmaw",
      country: "Burma",
      value: "BMO",
      display: "BMO"
    },
    {
      name: "Dawei Airport",
      city: "Dawei",
      country: "Burma",
      value: "TVY",
      display: "TVY"
    },
    {
      name: "Kawthoung Airport",
      city: "Kawthoung",
      country: "Burma",
      value: "KAW",
      display: "KAW"
    },
    {
      name: "Loikaw Airport",
      city: "Loikaw",
      country: "Burma",
      value: "LIW",
      display: "LIW"
    },
    {
      name: "Mawlamyine Airport",
      city: "Mawlamyine",
      country: "Burma",
      value: "MNU",
      display: "MNU"
    },
    {
      name: "Pathein Airport",
      city: "Pathein",
      country: "Burma",
      value: "BSX",
      display: "BSX"
    },
    {
      name: "Pakhokku Airport",
      city: "Pakhokku",
      country: "Burma",
      value: "PKK",
      display: "PKK"
    },
    {
      name: "Sumbawa Besar Airport",
      city: "Sumbawa Island",
      country: "Indonesia",
      value: "SWQ",
      display: "SWQ"
    },
    {
      name: "Tambolaka Airport",
      city: "Waikabubak-Sumba Island",
      country: "Indonesia",
      value: "TMC",
      display: "TMC"
    },
    {
      name: "Bokondini Airport",
      city: "Bokondini-Papua Island",
      country: "Indonesia",
      value: "BUI",
      display: "BUI"
    },
    {
      name: "Senggeh Airport",
      city: "Senggeh-Papua Island",
      country: "Indonesia",
      value: "SEH",
      display: "SEH"
    },
    {
      name: "Tanjung Harapan Airport",
      city: "Tanjung Selor-Borneo Island",
      country: "Indonesia",
      value: "TJS",
      display: "TJS"
    },
    {
      name: "Datadawai Airport",
      city: "Datadawai-Borneo Island",
      country: "Indonesia",
      value: "DTD",
      display: "DTD"
    },
    {
      name: "Barau(Kalimaru) Airport",
      city: "Tanjung Redep-Borneo Island",
      country: "Indonesia",
      value: "BEJ",
      display: "BEJ"
    },
    {
      name: "Warukin Airport",
      city: "Tanjung-Borneo Island",
      country: "Indonesia",
      value: "TJG",
      display: "TJG"
    },
    {
      name: "Sampit(Hasan) Airport",
      city: "Sampit-Borneo Island",
      country: "Indonesia",
      value: "SMQ",
      display: "SMQ"
    },
    {
      name: "Dumatumbun Airport",
      city: "Langgur-Kei Islands",
      country: "Indonesia",
      value: "LUV",
      display: "LUV"
    },
    {
      name: "Mali Airport",
      city: "Alor Island",
      country: "Indonesia",
      value: "ARD",
      display: "ARD"
    },
    {
      name: "Belaga Airport",
      city: "Belaga",
      country: "Malaysia",
      value: "BLG",
      display: "BLG"
    },
    {
      name: "Long Lellang Airport",
      city: "Long Datih",
      country: "Malaysia",
      value: "LGL",
      display: "LGL"
    },
    {
      name: "Long Seridan Airport",
      city: "Long Seridan",
      country: "Malaysia",
      value: "ODN",
      display: "ODN"
    },
    {
      name: "Mukah Airport",
      city: "Mukah",
      country: "Malaysia",
      value: "MKM",
      display: "MKM"
    },
    {
      name: "Bakalalan Airport",
      city: "Bakalalan",
      country: "Malaysia",
      value: "BKM",
      display: "BKM"
    },
    {
      name: "Lawas Airport",
      city: "Lawas",
      country: "Malaysia",
      value: "LWY",
      display: "LWY"
    },
    {
      name: "Bario Airport",
      city: "Bario",
      country: "Malaysia",
      value: "BBN",
      display: "BBN"
    },
    {
      name: "Tomanggong Airport",
      city: "Tomanggong",
      country: "Malaysia",
      value: "TMG",
      display: "TMG"
    },
    {
      name: "Kudat Airport",
      city: "Kudat",
      country: "Malaysia",
      value: "KUD",
      display: "KUD"
    },
    {
      name: "Radin Inten II (Branti) Airport",
      city: "Bandar Lampung-Sumatra Island",
      country: "Indonesia",
      value: "TKG",
      display: "TKG"
    },
    {
      name: "Halim Perdanakusuma International Airport",
      city: "Jakarta",
      country: "Indonesia",
      value: "HLP",
      display: "HLP"
    },
    {
      name: "Ranai Airport",
      city: "Ranai-Natuna Besar Island",
      country: "Indonesia",
      value: "NTX",
      display: "NTX"
    },
    {
      name: "Pangsuma Airport",
      city: "Putussibau-Borneo Island",
      country: "Indonesia",
      value: "PSU",
      display: "PSU"
    },
    {
      name: "Sintang(Susilo) Airport",
      city: "Sintang-Borneo Island",
      country: "Indonesia",
      value: "SQG",
      display: "SQG"
    },
    {
      name: "Pendopo Airport",
      city: "Talang Gudang-Sumatra Island",
      country: "Indonesia",
      value: "PDO",
      display: "PDO"
    },
    {
      name: "Malikus Saleh Airport",
      city: "Lhok Seumawe-Sumatra Island",
      country: "Indonesia",
      value: "LSW",
      display: "LSW"
    },
    {
      name: "Pulau Pangkor Airport",
      city: "Pangkor Island",
      country: "Malaysia",
      value: "PKG",
      display: "PKG"
    },
    {
      name: "Long Bawan Airport",
      city: "Long Bawan-Borneo Island",
      country: "Indonesia",
      value: "LBW",
      display: "LBW"
    },
    {
      name: "Nunukan Airport",
      city: "Nunukan-Nunukan Island",
      country: "Indonesia",
      value: "NNX",
      display: "NNX"
    },
    {
      name: "Long Apung Airport",
      city: "Long Apung-Borneo Island",
      country: "Indonesia",
      value: "LPU",
      display: "LPU"
    },
    {
      name: "Albany Airport",
      city: "Albany",
      country: "Australia",
      value: "ALH",
      display: "ALH"
    },
    {
      name: "Argyle Airport",
      city: "Argyle",
      country: "Australia",
      value: "GYL",
      display: "GYL"
    },
    {
      name: "Aurukun Airport",
      city: "Aurukun",
      country: "Australia",
      value: "AUU",
      display: "AUU"
    },
    {
      name: "Barcaldine Airport",
      city: "Barcaldine",
      country: "Australia",
      value: "BCI",
      display: "BCI"
    },
    {
      name: "Badu Island Airport",
      city: "Badu Island",
      country: "Australia",
      value: "BDD",
      display: "BDD"
    },
    {
      name: "Birdsville Airport",
      city: "Birdsville",
      country: "Australia",
      value: "BVI",
      display: "BVI"
    },
    {
      name: "Broken Hill Airport",
      city: "Broken Hill",
      country: "Australia",
      value: "BHQ",
      display: "BHQ"
    },
    {
      name: "Hamilton Island Airport",
      city: "Hamilton Island",
      country: "Australia",
      value: "HTI",
      display: "HTI"
    },
    {
      name: "Bedourie Airport",
      city: "Bedourie",
      country: "Australia",
      value: "BEU",
      display: "BEU"
    },
    {
      name: "Bourke Airport",
      city: "Bourke",
      country: "Australia",
      value: "BRK",
      display: "BRK"
    },
    {
      name: "Burketown Airport",
      city: "Burketown",
      country: "Australia",
      value: "BUC",
      display: "BUC"
    },
    {
      name: "Boigu Airport",
      city: "Boigu",
      country: "Australia",
      value: "GIC",
      display: "GIC"
    },
    {
      name: "Oakey Airport",
      city: "Oakey",
      country: "Australia",
      value: "OKY",
      display: "OKY"
    },
    {
      name: "Boulia Airport",
      city: "Boulia",
      country: "Australia",
      value: "BQL",
      display: "BQL"
    },
    {
      name: "Bathurst Airport",
      city: "Bathurst",
      country: "Australia",
      value: "BHS",
      display: "BHS"
    },
    {
      name: "Blackwater Airport",
      city: "Blackwater",
      country: "Australia",
      value: "BLT",
      display: "BLT"
    },
    {
      name: "Carnarvon Airport",
      city: "Carnarvon",
      country: "Australia",
      value: "CVQ",
      display: "CVQ"
    },
    {
      name: "Cobar Airport",
      city: "Cobar",
      country: "Australia",
      value: "CAZ",
      display: "CAZ"
    },
    {
      name: "Coober Pedy Airport",
      city: "Coober Pedy",
      country: "Australia",
      value: "CPD",
      display: "CPD"
    },
    {
      name: "Coconut Island Airport",
      city: "Coconut Island",
      country: "Australia",
      value: "CNC",
      display: "CNC"
    },
    {
      name: "Cloncurry Airport",
      city: "Cloncurry",
      country: "Australia",
      value: "CNJ",
      display: "CNJ"
    },
    {
      name: "Ceduna Airport",
      city: "Ceduna",
      country: "Australia",
      value: "CED",
      display: "CED"
    },
    {
      name: "Cooktown Airport",
      city: "Cooktown",
      country: "Australia",
      value: "CTN",
      display: "CTN"
    },
    {
      name: "Cunnamulla Airport",
      city: "Cunnamulla",
      country: "Australia",
      value: "CMA",
      display: "CMA"
    },
    {
      name: "Coonamble Airport",
      city: "Coonamble",
      country: "Australia",
      value: "CNB",
      display: "CNB"
    },
    {
      name: "Coen Airport",
      city: "Coen",
      country: "Australia",
      value: "CUQ",
      display: "CUQ"
    },
    {
      name: "Cooma Snowy Mountains Airport",
      city: "Cooma",
      country: "Australia",
      value: "OOM",
      display: "OOM"
    },
    {
      name: "Doomadgee Airport",
      city: "Doomadgee",
      country: "Australia",
      value: "DMD",
      display: "DMD"
    },
    {
      name: "Darnley Island Airport",
      city: "Darnley Island",
      country: "Australia",
      value: "NLF",
      display: "NLF"
    },
    {
      name: "Devonport Airport",
      city: "Devonport",
      country: "Australia",
      value: "DPO",
      display: "DPO"
    },
    {
      name: "Elcho Island Airport",
      city: "Elcho Island",
      country: "Australia",
      value: "ELC",
      display: "ELC"
    },
    {
      name: "Esperance Airport",
      city: "Esperance",
      country: "Australia",
      value: "EPR",
      display: "EPR"
    },
    {
      name: "Flinders Island Airport",
      city: "Flinders Island",
      country: "Australia",
      value: "FLS",
      display: "FLS"
    },
    {
      name: "Geraldton Airport",
      city: "Geraldton",
      country: "Australia",
      value: "GET",
      display: "GET"
    },
    {
      name: "Gladstone Airport",
      city: "Gladstone",
      country: "Australia",
      value: "GLT",
      display: "GLT"
    },
    {
      name: "Groote Eylandt Airport",
      city: "Groote Eylandt",
      country: "Australia",
      value: "GTE",
      display: "GTE"
    },
    {
      name: "Griffith Airport",
      city: "Griffith",
      country: "Australia",
      value: "GFF",
      display: "GFF"
    },
    {
      name: "Horn Island Airport",
      city: "Horn Island",
      country: "Australia",
      value: "HID",
      display: "HID"
    },
    {
      name: "Hooker Creek Airport",
      city: "Hooker Creek",
      country: "Australia",
      value: "HOK",
      display: "HOK"
    },
    {
      name: "Mount Hotham Airport",
      city: "Mount Hotham",
      country: "Australia",
      value: "MHU",
      display: "MHU"
    },
    {
      name: "Hughenden Airport",
      city: "Hughenden",
      country: "Australia",
      value: "HGD",
      display: "HGD"
    },
    {
      name: "Julia Creek Airport",
      city: "Julia Creek",
      country: "Australia",
      value: "JCK",
      display: "JCK"
    },
    {
      name: "Kalbarri Airport",
      city: "Kalbarri",
      country: "Australia",
      value: "KAX",
      display: "KAX"
    },
    {
      name: "King Island Airport",
      city: "King Island",
      country: "Australia",
      value: "KNS",
      display: "KNS"
    },
    {
      name: "Kalkgurung Airport",
      city: "Kalkgurung",
      country: "Australia",
      value: "KFG",
      display: "KFG"
    },
    {
      name: "Karumba Airport",
      city: "Karumba",
      country: "Australia",
      value: "KRB",
      display: "KRB"
    },
    {
      name: "Kowanyama Airport",
      city: "Kowanyama",
      country: "Australia",
      value: "KWM",
      display: "KWM"
    },
    {
      name: "Kubin Airport",
      city: "Kubin",
      country: "Australia",
      value: "KUG",
      display: "KUG"
    },
    {
      name: "Leonora Airport",
      city: "Leonora",
      country: "Australia",
      value: "LNO",
      display: "LNO"
    },
    {
      name: "Lake Evella Airport",
      city: "Lake Evella",
      country: "Australia",
      value: "LEL",
      display: "LEL"
    },
    {
      name: "Lord Howe Island Airport",
      city: "Lord Howe Island",
      country: "Australia",
      value: "LDH",
      display: "LDH"
    },
    {
      name: "Lockhart River Airport",
      city: "Lockhart River",
      country: "Australia",
      value: "IRG",
      display: "IRG"
    },
    {
      name: "Lismore Airport",
      city: "Lismore",
      country: "Australia",
      value: "LSY",
      display: "LSY"
    },
    {
      name: "Lightning Ridge Airport",
      city: "Lightning Ridge",
      country: "Australia",
      value: "LHG",
      display: "LHG"
    },
    {
      name: "Longreach Airport",
      city: "Longreach",
      country: "Australia",
      value: "LRE",
      display: "LRE"
    },
    {
      name: "Leinster Airport",
      city: "Leinster",
      country: "Australia",
      value: "LER",
      display: "LER"
    },
    {
      name: "Laverton Airport",
      city: "Laverton",
      country: "Australia",
      value: "LVO",
      display: "LVO"
    },
    {
      name: "Mabuiag Island Airport",
      city: "Mabuiag Island",
      country: "Australia",
      value: "UBB",
      display: "UBB"
    },
    {
      name: "Meekatharra Airport",
      city: "Meekatharra",
      country: "Australia",
      value: "MKR",
      display: "MKR"
    },
    {
      name: "Merimbula Airport",
      city: "Merimbula",
      country: "Australia",
      value: "MIM",
      display: "MIM"
    },
    {
      name: "Milingimbi Airport",
      city: "Milingimbi",
      country: "Australia",
      value: "MGT",
      display: "MGT"
    },
    {
      name: "Maningrida Airport",
      city: "Maningrida",
      country: "Australia",
      value: "MNG",
      display: "MNG"
    },
    {
      name: "McArthur River Mine Airport",
      city: "McArthur River Mine",
      country: "Australia",
      value: "MCV",
      display: "MCV"
    },
    {
      name: "Mildura Airport",
      city: "Mildura",
      country: "Australia",
      value: "MQL",
      display: "MQL"
    },
    {
      name: "Mount Magnet Airport",
      city: "Mount Magnet",
      country: "Australia",
      value: "MMG",
      display: "MMG"
    },
    {
      name: "Moree Airport",
      city: "Moree",
      country: "Australia",
      value: "MRZ",
      display: "MRZ"
    },
    {
      name: "Moranbah Airport",
      city: "Moranbah",
      country: "Australia",
      value: "MOV",
      display: "MOV"
    },
    {
      name: "Moruya Airport",
      city: "Moruya",
      country: "Australia",
      value: "MYA",
      display: "MYA"
    },
    {
      name: "Mount Gambier Airport",
      city: "Mount Gambier",
      country: "Australia",
      value: "MGB",
      display: "MGB"
    },
    {
      name: "Mornington Island Airport",
      city: "Mornington Island",
      country: "Australia",
      value: "ONG",
      display: "ONG"
    },
    {
      name: "Murray Island Airport",
      city: "Murray Island",
      country: "Australia",
      value: "MYI",
      display: "MYI"
    },
    {
      name: "Maryborough Airport",
      city: "Maryborough",
      country: "Australia",
      value: "MBH",
      display: "MBH"
    },
    {
      name: "Narrandera Airport",
      city: "Narrandera",
      country: "Australia",
      value: "NRA",
      display: "NRA"
    },
    {
      name: "Narrabri Airport",
      city: "Narrabri",
      country: "Australia",
      value: "NAA",
      display: "NAA"
    },
    {
      name: "Normanton Airport",
      city: "Normanton",
      country: "Australia",
      value: "NTN",
      display: "NTN"
    },
    {
      name: "Newman Airport",
      city: "Newman",
      country: "Australia",
      value: "ZNE",
      display: "ZNE"
    },
    {
      name: "Olympic Dam Airport",
      city: "Olympic Dam",
      country: "Australia",
      value: "OLP",
      display: "OLP"
    },
    {
      name: "Port Augusta Airport",
      city: "Argyle",
      country: "Australia",
      value: "PUG",
      display: "PUG"
    },
    {
      name: "Palm Island Airport",
      city: "Palm Island",
      country: "Australia",
      value: "PMK",
      display: "PMK"
    },
    {
      name: "Paraburdoo Airport",
      city: "Paraburdoo",
      country: "Australia",
      value: "PBO",
      display: "PBO"
    },
    {
      name: "Cocos (Keeling) Islands Airport",
      city: "Cocos Keeling Island",
      country: "Cocos (Keeling) Islands",
      value: "CCK",
      display: "CCK"
    },
    {
      name: "Gove Airport",
      city: "Gove",
      country: "Australia",
      value: "GOV",
      display: "GOV"
    },
    {
      name: "Parkes Airport",
      city: "Parkes",
      country: "Australia",
      value: "PKE",
      display: "PKE"
    },
    {
      name: "Port Lincoln Airport",
      city: "Port Lincoln",
      country: "Australia",
      value: "PLO",
      display: "PLO"
    },
    {
      name: "Pormpuraaw Airport",
      city: "Pormpuraaw",
      country: "Australia",
      value: "EDR",
      display: "EDR"
    },
    {
      name: "Port Macquarie Airport",
      city: "Port Macquarie",
      country: "Australia",
      value: "PQQ",
      display: "PQQ"
    },
    {
      name: "Portland Airport",
      city: "Portland",
      country: "Australia",
      value: "PTJ",
      display: "PTJ"
    },
    {
      name: "Quilpie Airport",
      city: "Quilpie",
      country: "Australia",
      value: "ULP",
      display: "ULP"
    },
    {
      name: "Ramingining Airport",
      city: "Ramingining",
      country: "Australia",
      value: "RAM",
      display: "RAM"
    },
    {
      name: "Roma Airport",
      city: "Roma",
      country: "Australia",
      value: "RMA",
      display: "RMA"
    },
    {
      name: "St George Airport",
      city: "St George",
      country: "Australia",
      value: "SGO",
      display: "SGO"
    },
    {
      name: "Shark Bay Airport",
      city: "Shark Bay",
      country: "Australia",
      value: "MJK",
      display: "MJK"
    },
    {
      name: "Saibai Island Airport",
      city: "Saibai Island",
      country: "Australia",
      value: "SBR",
      display: "SBR"
    },
    {
      name: "Strahan Airport",
      city: "Strahan",
      country: "Australia",
      value: "SRN",
      display: "SRN"
    },
    {
      name: "Thargomindah Airport",
      city: "Thargomindah",
      country: "Australia",
      value: "XTG",
      display: "XTG"
    },
    {
      name: "Tennant Creek Airport",
      city: "Tennant Creek",
      country: "Australia",
      value: "TCA",
      display: "TCA"
    },
    {
      name: "Victoria River Downs Airport",
      city: "Victoria River Downs",
      country: "Australia",
      value: "VCD",
      display: "VCD"
    },
    {
      name: "Warraber Island Airport",
      city: "Sue Islet",
      country: "Australia",
      value: "SYU",
      display: "SYU"
    },
    {
      name: "Windorah Airport",
      city: "Windorah",
      country: "Australia",
      value: "WNR",
      display: "WNR"
    },
    {
      name: "Whyalla Airport",
      city: "Whyalla",
      country: "Australia",
      value: "WYA",
      display: "WYA"
    },
    {
      name: "Wiluna Airport",
      city: "Wiluna",
      country: "Australia",
      value: "WUN",
      display: "WUN"
    },
    {
      name: "Wollongong Airport",
      city: "Wollongong",
      country: "Australia",
      value: "WOL",
      display: "WOL"
    },
    {
      name: "Winton Airport",
      city: "Winton",
      country: "Australia",
      value: "WIN",
      display: "WIN"
    },
    {
      name: "Wynyard Airport",
      city: "Burnie",
      country: "Australia",
      value: "BWT",
      display: "BWT"
    },
    {
      name: "Yorke Island Airport",
      city: "Yorke Island",
      country: "Australia",
      value: "OKR",
      display: "OKR"
    },
    {
      name: "Yam Island Airport",
      city: "Yam Island",
      country: "Australia",
      value: "XMY",
      display: "XMY"
    },
    {
      name: "Beijing Nanyuan Airport",
      city: "Beijing",
      country: "China",
      value: "NAY",
      display: "NAY"
    },
    {
      name: "Beijing Daxing International Airport",
      city: "Beijing",
      country: "China",
      value: "PKX",
      display: "PKX"
    },
    {
      name: "Chifeng Airport",
      city: "Chifeng",
      country: "China",
      value: "CIF",
      display: "CIF"
    },
    {
      name: "Changzhi Airport",
      city: "Changzhi",
      country: "China",
      value: "CIH",
      display: "CIH"
    },
    {
      name: "Datong Airport",
      city: "Datong",
      country: "China",
      value: "DAT",
      display: "DAT"
    },
    {
      name: "Baita International Airport",
      city: "Hohhot",
      country: "China",
      value: "HET",
      display: "HET"
    },
    {
      name: "Baotou Airport",
      city: "Baotou",
      country: "China",
      value: "BAV",
      display: "BAV"
    },
    {
      name: "Shijiazhuang Daguocun International Airport",
      city: "Shijiazhuang",
      country: "China",
      value: "SJW",
      display: "SJW"
    },
    {
      name: "Tongliao Airport",
      city: "Tongliao",
      country: "China",
      value: "TGO",
      display: "TGO"
    },
    {
      name: "Ulanhot Airport",
      city: "Ulanhot",
      country: "China",
      value: "HLH",
      display: "HLH"
    },
    {
      name: "Xilinhot Airport",
      city: "Xilinhot",
      country: "China",
      value: "XIL",
      display: "XIL"
    },
    {
      name: "Beihai Airport",
      city: "Beihai",
      country: "China",
      value: "BHY",
      display: "BHY"
    },
    {
      name: "Changde Airport",
      city: "Changde",
      country: "China",
      value: "CGD",
      display: "CGD"
    },
    {
      name: "Dayong Airport",
      city: "Dayong",
      country: "China",
      value: "DYG",
      display: "DYG"
    },
    {
      name: "Meixian Airport",
      city: "Meixian",
      country: "China",
      value: "MXZ",
      display: "MXZ"
    },
    {
      name: "Zhuhai Jinwan Airport",
      city: "Zhuhai",
      country: "China",
      value: "ZUH",
      display: "ZUH"
    },
    {
      name: "Liuzhou Bailian Airport",
      city: "Liuzhou",
      country: "China",
      value: "LZH",
      display: "LZH"
    },
    {
      name: "Zhanjiang Airport",
      city: "Zhanjiang",
      country: "China",
      value: "ZHA",
      display: "ZHA"
    },
    {
      name: "Enshi Airport",
      city: "Enshi",
      country: "China",
      value: "ENH",
      display: "ENH"
    },
    {
      name: "Nanyang Jiangying Airport",
      city: "Nanyang",
      country: "China",
      value: "NNY",
      display: "NNY"
    },
    {
      name: "Xiangyang Liuji Airport",
      city: "Xiangfan",
      country: "China",
      value: "XFN",
      display: "XFN"
    },
    {
      name: "Yichang Sanxia Airport",
      city: "Yichang",
      country: "China",
      value: "YIH",
      display: "YIH"
    },
    {
      name: "Ankang Wulipu Airport",
      city: "Ankang",
      country: "China",
      value: "AKA",
      display: "AKA"
    },
    {
      name: "Golmud Airport",
      city: "Golmud",
      country: "China",
      value: "GOQ",
      display: "GOQ"
    },
    {
      name: "Hanzhong Chenggu Airport",
      city: "Hanzhong",
      country: "China",
      value: "HZG",
      display: "HZG"
    },
    {
      name: "Qingyang Airport",
      city: "Qingyang",
      country: "China",
      value: "IQN",
      display: "IQN"
    },
    {
      name: "Xining Caojiabu Airport",
      city: "Xining",
      country: "China",
      value: "XNN",
      display: "XNN"
    },
    {
      name: "Yan'an Ershilipu Airport",
      city: "Yan an",
      country: "China",
      value: "ENY",
      display: "ENY"
    },
    {
      name: "Yulin Yuyang Airport",
      city: "Yulin",
      country: "China",
      value: "UYN",
      display: "UYN"
    },
    {
      name: "Arvaikheer Airport",
      city: "Arvaikheer",
      country: "Mongolia",
      value: "AVK",
      display: "AVK"
    },
    {
      name: "Altai Airport",
      city: "Altai",
      country: "Mongolia",
      value: "LTI",
      display: "LTI"
    },
    {
      name: "Bayankhongor Airport",
      city: "Bayankhongor",
      country: "Mongolia",
      value: "BYN",
      display: "BYN"
    },
    {
      name: "Dalanzadgad Airport",
      city: "Dalanzadgad",
      country: "Mongolia",
      value: "DLZ",
      display: "DLZ"
    },
    {
      name: "Khovd Airport",
      city: "Khovd",
      country: "Mongolia",
      value: "HVD",
      display: "HVD"
    },
    {
      name: "Moron Airport",
      city: "Muren",
      country: "Mongolia",
      value: "MXV",
      display: "MXV"
    },
    {
      name: "Diqing Airport",
      city: "Shangri-La",
      country: "China",
      value: "DIG",
      display: "DIG"
    },
    {
      name: "Mangshi Airport",
      city: "Luxi",
      country: "China",
      value: "LUM",
      display: "LUM"
    },
    {
      name: "Pu'er Simao Airport",
      city: "Simao",
      country: "China",
      value: "SYM",
      display: "SYM"
    },
    {
      name: "Zhaotong Airport",
      city: "Zhaotong",
      country: "China",
      value: "ZAT",
      display: "ZAT"
    },
    {
      name: "Ganzhou Airport",
      city: "Ganzhou",
      country: "China",
      value: "KOW",
      display: "KOW"
    },
    {
      name: "Jingdezhen Airport",
      city: "Jingdezhen",
      country: "China",
      value: "JDZ",
      display: "JDZ"
    },
    {
      name: "Jiujiang Lushan Airport",
      city: "Jiujiang",
      country: "China",
      value: "JIU",
      display: "JIU"
    },
    {
      name: "Quzhou Airport",
      city: "Quzhou",
      country: "China",
      value: "JUZ",
      display: "JUZ"
    },
    {
      name: "Lianyungang Airport",
      city: "Lianyungang",
      country: "China",
      value: "LYG",
      display: "LYG"
    },
    {
      name: "Huangyan Luqiao Airport",
      city: "Huangyan",
      country: "China",
      value: "HYN",
      display: "HYN"
    },
    {
      name: "Shubuling Airport",
      city: "Linyi",
      country: "China",
      value: "LYI",
      display: "LYI"
    },
    {
      name: "Quanzhou Jinjiang International Airport",
      city: "Quanzhou",
      country: "China",
      value: "JJN",
      display: "JJN"
    },
    {
      name: "Tunxi International Airport",
      city: "Huangshan",
      country: "China",
      value: "TXN",
      display: "TXN"
    },
    {
      name: "Weifang Airport",
      city: "Weifang",
      country: "China",
      value: "WEF",
      display: "WEF"
    },
    {
      name: "Weihai Airport",
      city: "Weihai",
      country: "China",
      value: "WEH",
      display: "WEH"
    },
    {
      name: "Sunan Shuofang International Airport",
      city: "Wuxi",
      country: "China",
      value: "WUX",
      display: "WUX"
    },
    {
      name: "Nanping Wuyishan Airport",
      city: "Wuyishan",
      country: "China",
      value: "WUS",
      display: "WUS"
    },
    {
      name: "Wenzhou Yongqiang Airport",
      city: "Wenzhou",
      country: "China",
      value: "WNZ",
      display: "WNZ"
    },
    {
      name: "Yancheng Airport",
      city: "Yancheng",
      country: "China",
      value: "YNZ",
      display: "YNZ"
    },
    {
      name: "Yiwu Airport",
      city: "Yiwu",
      country: "China",
      value: "YIW",
      display: "YIW"
    },
    {
      name: "Zhoushan Airport",
      city: "Zhoushan",
      country: "China",
      value: "HSN",
      display: "HSN"
    },
    {
      name: "Qamdo Bangda Airport",
      city: "Bangda",
      country: "China",
      value: "BPX",
      display: "BPX"
    },
    {
      name: "Dachuan Airport",
      city: "Dazhou",
      country: "China",
      value: "DAX",
      display: "DAX"
    },
    {
      name: "Guangyuan Airport",
      city: "Guangyuan",
      country: "China",
      value: "GYS",
      display: "GYS"
    },
    {
      name: "Luzhou Airport",
      city: "Luzhou",
      country: "China",
      value: "LZO",
      display: "LZO"
    },
    {
      name: "Mianyang Airport",
      city: "Mianyang",
      country: "China",
      value: "MIG",
      display: "MIG"
    },
    {
      name: "Nanchong Airport",
      city: "Nanchong",
      country: "China",
      value: "NAO",
      display: "NAO"
    },
    {
      name: "Nyingchi Airport",
      city: "Nyingchi",
      country: "China",
      value: "LZY",
      display: "LZY"
    },
    {
      name: "Wanxian Airport",
      city: "Wanxian",
      country: "China",
      value: "WXN",
      display: "WXN"
    },
    {
      name: "Aksu Airport",
      city: "Aksu",
      country: "China",
      value: "AKU",
      display: "AKU"
    },
    {
      name: "Qiemo Airport",
      city: "Qiemo",
      country: "China",
      value: "IQM",
      display: "IQM"
    },
    {
      name: "Kuqa Airport",
      city: "Kuqa",
      country: "China",
      value: "KCA",
      display: "KCA"
    },
    {
      name: "Korla Airport",
      city: "Korla",
      country: "China",
      value: "KRL",
      display: "KRL"
    },
    {
      name: "Karamay Airport",
      city: "Karamay",
      country: "China",
      value: "KRY",
      display: "KRY"
    },
    {
      name: "Yining Airport",
      city: "Yining",
      country: "China",
      value: "YIN",
      display: "YIN"
    },
    {
      name: "Heihe Airport",
      city: "Heihe",
      country: "China",
      value: "HEK",
      display: "HEK"
    },
    {
      name: "Jiamusi Airport",
      city: "Jiamusi",
      country: "China",
      value: "JMU",
      display: "JMU"
    },
    {
      name: "Jinzhou Airport",
      city: "Jinzhou",
      country: "China",
      value: "JNZ",
      display: "JNZ"
    },
    {
      name: "Qiqihar Sanjiazi Airport",
      city: "Qiqihar",
      country: "China",
      value: "NDG",
      display: "NDG"
    },
    {
      name: "Yanji Chaoyangchuan Airport",
      city: "Yanji",
      country: "China",
      value: "YNJ",
      display: "YNJ"
    },
    {
      name: "Waikoloa Heliport",
      city: "Waikoloa Village",
      country: "United States",
      value: "WKL",
      display: "WKL"
    },
    {
      name: "Mount Keith Airport",
      city: "Mount Keith",
      country: "Australia",
      value: "WME",
      display: "WME"
    },
    {
      name: "Los Roques Airport",
      city: "Los Roques",
      country: "Venezuela",
      value: "LRV",
      display: "LRV"
    },
    {
      name: "Inishmore Aerodrome",
      city: "Inis Mor",
      country: "Ireland",
      value: "IOR",
      display: "IOR"
    },
    {
      name: "Connemara Regional Airport",
      city: "Indreabhan",
      country: "Ireland",
      value: "NNR",
      display: "NNR"
    },
    {
      name: "Rugen Airport",
      city: "Ruegen",
      country: "Germany",
      value: "GTI",
      display: "GTI"
    },
    {
      name: "Berezovo Airport",
      city: "Berezovo",
      country: "Russia",
      value: "NBB",
      display: "NBB"
    },
    {
      name: "Worcester Regional Airport",
      city: "Worcester",
      country: "United States",
      value: "ORH",
      display: "ORH"
    },
    {
      name: "Anqing Tianzhushan Airport",
      city: "Anqing",
      country: "China",
      value: "AQG",
      display: "AQG"
    },
    {
      name: "Shanhaiguan Airport",
      city: "Qinhuangdao",
      country: "China",
      value: "SHP",
      display: "SHP"
    },
    {
      name: "Yuncheng Guangong Airport",
      city: "Yuncheng",
      country: "China",
      value: "YCU",
      display: "YCU"
    },
    {
      name: "Jiayuguan Airport",
      city: "Jiayuguan",
      country: "China",
      value: "JGN",
      display: "JGN"
    },
    {
      name: "Ordos Ejin Horo Airport",
      city: "Dongsheng",
      country: "China",
      value: "DSN",
      display: "DSN"
    },
    {
      name: "Bremerton National Airport",
      city: "Bremerton",
      country: "United States",
      value: "PWT",
      display: "PWT"
    },
    {
      name: "Spencer Municipal Airport",
      city: "Spencer",
      country: "United States",
      value: "SPW",
      display: "SPW"
    },
    {
      name: "Jefferson City Memorial Airport",
      city: "Jefferson City",
      country: "United States",
      value: "JEF",
      display: "JEF"
    },
    {
      name: "Unst Airport",
      city: "Unst",
      country: "United Kingdom",
      value: "UNT",
      display: "UNT"
    },
    {
      name: "Provincetown Municipal Airport",
      city: "Provincetown",
      country: "United States",
      value: "PVC",
      display: "PVC"
    },
    {
      name: "Gustaf III Airport",
      city: "Gustavia",
      country: "France",
      value: "SBH",
      display: "SBH"
    },
    {
      name: "Kostroma Sokerkino Airport",
      city: "Kostroma",
      country: "Russia",
      value: "KMW",
      display: "KMW"
    },
    {
      name: "Sukhumi Dranda Airport",
      city: "Sukhumi",
      country: "Georgia",
      value: "SUI",
      display: "SUI"
    },
    {
      name: "Donskoye Airport",
      city: "Tambow",
      country: "Russia",
      value: "TBW",
      display: "TBW"
    },
    {
      name: "Oban Airport",
      city: "North Connel",
      country: "United Kingdom",
      value: "OBN",
      display: "OBN"
    },
    {
      name: "Erechim Airport",
      city: "Erechim",
      country: "Brazil",
      value: "ERM",
      display: "ERM"
    },
    {
      name: "Courchevel Airport",
      city: "Courcheval",
      country: "France",
      value: "CVF",
      display: "CVF"
    },
    {
      name: "Fullerton Municipal Airport",
      city: "Fullerton",
      country: "United States",
      value: "FUL",
      display: "FUL"
    },
    {
      name: "Navoi Airport",
      city: "Navoi",
      country: "Uzbekistan",
      value: "NVI",
      display: "NVI"
    },
    {
      name: "Ain Arnat Airport",
      city: "Setif",
      country: "Algeria",
      value: "QSF",
      display: "QSF"
    },
    {
      name: "La Rochelle-Ile de Re Airport",
      city: "La Rochelle",
      country: "France",
      value: "LRH",
      display: "LRH"
    },
    {
      name: "Friedman Memorial Airport",
      city: "Hailey",
      country: "United States",
      value: "SUN",
      display: "SUN"
    },
    {
      name: "Mason City Municipal Airport",
      city: "Mason City",
      country: "United States",
      value: "MCW",
      display: "MCW"
    },
    {
      name: "Phoenix-Mesa-Gateway Airport",
      city: "Mesa",
      country: "United States",
      value: "AZA",
      display: "AZA"
    },
    {
      name: "Saul Airport",
      city: "Saul",
      country: "French Guiana",
      value: "XAU",
      display: "XAU"
    },
    {
      name: "Anaktuvuk Pass Airport",
      city: "Anaktuvuk Pass",
      country: "United States",
      value: "AKP",
      display: "AKP"
    },
    {
      name: "Anvik Airport",
      city: "Anvik",
      country: "United States",
      value: "ANV",
      display: "ANV"
    },
    {
      name: "Atqasuk Edward Burnell Sr Memorial Airport",
      city: "Atqasuk",
      country: "United States",
      value: "ATK",
      display: "ATK"
    },
    {
      name: "Gambell Airport",
      city: "Gambell",
      country: "United States",
      value: "GAM",
      display: "GAM"
    },
    {
      name: "Hooper Bay Airport",
      city: "Hooper Bay",
      country: "United States",
      value: "HPB",
      display: "HPB"
    },
    {
      name: "Kaltag Airport",
      city: "Kaltag",
      country: "United States",
      value: "KAL",
      display: "KAL"
    },
    {
      name: "St Mary's Airport",
      city: "St Mary s",
      country: "United States",
      value: "KSM",
      display: "KSM"
    },
    {
      name: "Kivalina Airport",
      city: "Kivalina",
      country: "United States",
      value: "KVL",
      display: "KVL"
    },
    {
      name: "Mekoryuk Airport",
      city: "Mekoryuk",
      country: "United States",
      value: "MYU",
      display: "MYU"
    },
    {
      name: "Ruby Airport",
      city: "Ruby",
      country: "United States",
      value: "RBY",
      display: "RBY"
    },
    {
      name: "Shishmaref Airport",
      city: "Shishmaref",
      country: "United States",
      value: "SHH",
      display: "SHH"
    },
    {
      name: "Savoonga Airport",
      city: "Savoonga",
      country: "United States",
      value: "SVA",
      display: "SVA"
    },
    {
      name: "Noatak Airport",
      city: "Noatak",
      country: "United States",
      value: "WTK",
      display: "WTK"
    },
    {
      name: "Ormoc Airport",
      city: "Ormoc City",
      country: "Philippines",
      value: "OMC",
      display: "OMC"
    },
    {
      name: "Puvirnituq Airport",
      city: "Puvirnituq",
      country: "Canada",
      value: "YPX",
      display: "YPX"
    },
    {
      name: "Tasiujaq Airport",
      city: "Tasiujaq",
      country: "Canada",
      value: "YTQ",
      display: "YTQ"
    },
    {
      name: "Arctic Village Airport",
      city: "Arctic Village",
      country: "United States",
      value: "ARC",
      display: "ARC"
    },
    {
      name: "Sam Mbakwe International Airport",
      city: "Imo",
      country: "Nigeria",
      value: "QOW",
      display: "QOW"
    },
    {
      name: "Arenal Airport",
      city: "La Fortuna/San Carlos",
      country: "Costa Rica",
      value: "FON",
      display: "FON"
    },
    {
      name: "Tambor Airport",
      city: "Nicoya",
      country: "Costa Rica",
      value: "TMU",
      display: "TMU"
    },
    {
      name: "Cauayan Airport",
      city: "Cauayan",
      country: "Philippines",
      value: "CYZ",
      display: "CYZ"
    },
    {
      name: "Kirovsk-Apatity Airport",
      city: "Apatity",
      country: "Russia",
      value: "KVK",
      display: "KVK"
    },
    {
      name: "Coronel Altino Machado de Oliveira Airport",
      city: "Governador Valadares",
      country: "Brazil",
      value: "GVR",
      display: "GVR"
    },
    {
      name: "Pajala Airport",
      city: "Pajala",
      country: "Sweden",
      value: "PJA",
      display: "PJA"
    },
    {
      name: "Bella Coola Airport",
      city: "Bella Coola",
      country: "Canada",
      value: "QBC",
      display: "QBC"
    },
    {
      name: "Hagerstown Regional Richard A Henson Field",
      city: "Hagerstown",
      country: "United States",
      value: "HGR",
      display: "HGR"
    },
    {
      name: "Araracuara Airport",
      city: "Araracuara",
      country: "Colombia",
      value: "ACR",
      display: "ACR"
    },
    {
      name: "Gorakhpur Airport",
      city: "Gorakhpur",
      country: "India",
      value: "GOP",
      display: "GOP"
    },
    {
      name: "Sand Point Airport",
      city: "Sand Point",
      country: "United States",
      value: "SDP",
      display: "SDP"
    },
    {
      name: "Hami Airport",
      city: "Hami",
      country: "China",
      value: "HMI",
      display: "HMI"
    },
    {
      name: "Wuzhou Changzhoudao Airport",
      city: "Wuzhou",
      country: "China",
      value: "WUZ",
      display: "WUZ"
    },
    {
      name: "Tugdan Airport",
      city: "Romblon",
      country: "Philippines",
      value: "TBH",
      display: "TBH"
    },
    {
      name: "Sahand Airport",
      city: "Maragheh",
      country: "Iran",
      value: "ACP",
      display: "ACP"
    },
    {
      name: "Gorgan Airport",
      city: "Gorgan",
      country: "Iran",
      value: "GBT",
      display: "GBT"
    },
    {
      name: "Ilam Airport",
      city: "Ilam",
      country: "Iran",
      value: "IIL",
      display: "IIL"
    },
    {
      name: "Parsabade Moghan Airport",
      city: "Parsabad",
      country: "Iran",
      value: "PFQ",
      display: "PFQ"
    },
    {
      name: "Tacheng Airport",
      city: "Tacheng",
      country: "China",
      value: "TCG",
      display: "TCG"
    },
    {
      name: "Mardin Airport",
      city: "Mardin",
      country: "Turkey",
      value: "MQM",
      display: "MQM"
    },
    {
      name: "Sugraly Airport",
      city: "Zarafshan",
      country: "Uzbekistan",
      value: "AFS",
      display: "AFS"
    },
    {
      name: "Deering Airport",
      city: "Deering",
      country: "United States",
      value: "DRG",
      display: "DRG"
    },
    {
      name: "Leon Airport",
      city: "Leon",
      country: "Spain",
      value: "LEN",
      display: "LEN"
    },
    {
      name: "Burgos Airport",
      city: "Burgos",
      country: "Spain",
      value: "RGS",
      display: "RGS"
    },
    {
      name: "Sege Airport",
      city: "Sege",
      country: "Solomon Islands",
      value: "EGM",
      display: "EGM"
    },
    {
      name: "Shahrekord Airport",
      city: "Shahre Kord",
      country: "Iran",
      value: "CQD",
      display: "CQD"
    },
    {
      name: "Kangra Airport",
      city: "Kangra",
      country: "India",
      value: "DHM",
      display: "DHM"
    },
    {
      name: "Nanded Airport",
      city: "Nanded",
      country: "India",
      value: "NDC",
      display: "NDC"
    },
    {
      name: "Shimla Airport",
      city: "Shimla",
      country: "India",
      value: "SLV",
      display: "SLV"
    },
    {
      name: "Igiugig Airport",
      city: "Igiugig",
      country: "United States",
      value: "IGG",
      display: "IGG"
    },
    {
      name: "New Stuyahok Airport",
      city: "New Stuyahok",
      country: "United States",
      value: "KNW",
      display: "KNW"
    },
    {
      name: "King Cove Airport",
      city: "King Cove",
      country: "United States",
      value: "KVC",
      display: "KVC"
    },
    {
      name: "Port Heiden Airport",
      city: "Port Heiden",
      country: "United States",
      value: "PTH",
      display: "PTH"
    },
    {
      name: "Togiak Airport",
      city: "Togiak Village",
      country: "United States",
      value: "TOG",
      display: "TOG"
    },
    {
      name: "Geneina Airport",
      city: "Geneina",
      country: "Sudan",
      value: "EGN",
      display: "EGN"
    },
    {
      name: "Long Akah Airport",
      city: "Long Akah",
      country: "Malaysia",
      value: "LKH",
      display: "LKH"
    },
    {
      name: "Walaha Airport",
      city: "Walaha",
      country: "Vanuatu",
      value: "WLH",
      display: "WLH"
    },
    {
      name: "Chaoyang Airport",
      city: "Chaoyang",
      country: "China",
      value: "CHG",
      display: "CHG"
    },
    {
      name: "Samburu South Airport",
      city: "Samburu South",
      country: "Kenya",
      value: "UAS",
      display: "UAS"
    },
    {
      name: "Brus Laguna Airport",
      city: "Brus Laguna",
      country: "Honduras",
      value: "BHG",
      display: "BHG"
    },
    {
      name: "Bonaventure Airport",
      city: "Bonaventure",
      country: "Canada",
      value: "YVB",
      display: "YVB"
    },
    {
      name: "Sialkot Airport",
      city: "Sialkot",
      country: "Pakistan",
      value: "SKT",
      display: "SKT"
    },
    {
      name: "Capitan Corbeta CA Curbelo International Airport",
      city: "Punta del Este",
      country: "Uruguay",
      value: "PDP",
      display: "PDP"
    },
    {
      name: "Walvis Bay Airport",
      city: "Walvis Bay",
      country: "Namibia",
      value: "WVB",
      display: "WVB"
    },
    {
      name: "Katima Mulilo Airport",
      city: "Mpacha",
      country: "Namibia",
      value: "MPA",
      display: "MPA"
    },
    {
      name: "Anadolu Airport",
      city: "Eskissehir",
      country: "Turkey",
      value: "AOE",
      display: "AOE"
    },
    {
      name: "Canakkale Airport",
      city: "Canakkale",
      country: "Turkey",
      value: "CKZ",
      display: "CKZ"
    },
    {
      name: "Mus Airport",
      city: "Mus",
      country: "Turkey",
      value: "MSR",
      display: "MSR"
    },
    {
      name: "Sinop Airport",
      city: "Sinop",
      country: "Turkey",
      value: "SIC",
      display: "SIC"
    },
    {
      name: "Tekirdag Corlu Airport",
      city: "Corlu",
      country: "Turkey",
      value: "TEQ",
      display: "TEQ"
    },
    {
      name: "Bursa Yenisehir Airport",
      city: "Yenisehir",
      country: "Turkey",
      value: "YEI",
      display: "YEI"
    },
    {
      name: "Terre-de-Haut Airport",
      city: "Les Saintes",
      country: "Guadeloupe",
      value: "LSS",
      display: "LSS"
    },
    {
      name: "Kalay Airport",
      city: "Kalemyo",
      country: "Myanmar",
      value: "KMV",
      display: "KMV"
    },
    {
      name: "Vieques Airport",
      city: "Vieques Island",
      country: "Puerto Rico",
      value: "VQS",
      display: "VQS"
    },
    {
      name: "St Augustin Airport",
      city: "St-Augustin",
      country: "Canada",
      value: "YIF",
      display: "YIF"
    },
    {
      name: "Hamadan Airport",
      city: "Hamadan",
      country: "Iran",
      value: "HDM",
      display: "HDM"
    },
    {
      name: "Marinduque Airport",
      city: "Gasan",
      country: "Philippines",
      value: "MRQ",
      display: "MRQ"
    },
    {
      name: "Grafton Airport",
      city: "Grafton",
      country: "Australia",
      value: "GFN",
      display: "GFN"
    },
    {
      name: "Orange Airport",
      city: "Orange",
      country: "Australia",
      value: "OAG",
      display: "OAG"
    },
    {
      name: "Taree Airport",
      city: "Taree",
      country: "Australia",
      value: "TRO",
      display: "TRO"
    },
    {
      name: "Choibalsan Airport",
      city: "Choibalsan",
      country: "Mongolia",
      value: "COQ",
      display: "COQ"
    },
    {
      name: "Hohenems-Dornbirn Airport",
      city: "Hohenems",
      country: "Austria",
      value: "HOJ",
      display: "HOJ"
    },
    {
      name: "Delta County Airport",
      city: "Escanaba",
      country: "United States",
      value: "ESC",
      display: "ESC"
    },
    {
      name: "Yakutat Airport",
      city: "Yakutat",
      country: "United States",
      value: "YAK",
      display: "YAK"
    },
    {
      name: "Goulburn Airport",
      city: "Goulburn",
      country: "Australia",
      value: "GUL",
      display: "GUL"
    },
    {
      name: "Cessnock Airport",
      city: "Cessnock",
      country: "Australia",
      value: "CES",
      display: "CES"
    },
    {
      name: "Scone Airport",
      city: "Scone",
      country: "Australia",
      value: "NSO",
      display: "NSO"
    },
    {
      name: "Mudgee Airport",
      city: "Mudgee",
      country: "Australia",
      value: "DGE",
      display: "DGE"
    },
    {
      name: "Maitland Airport",
      city: "Maitland",
      country: "Australia",
      value: "MTL",
      display: "MTL"
    },
    {
      name: "Benjamin Rivera Noriega Airport",
      city: "Culebra Island",
      country: "Puerto Rico",
      value: "CPX",
      display: "CPX"
    },
    {
      name: "Williamson County Regional Airport",
      city: "Marion",
      country: "United States",
      value: "MWA",
      display: "MWA"
    },
    {
      name: "Burnet Municipal Kate Craddock Field",
      city: "Bamburi",
      country: "Kenya",
      value: "BMQ",
      display: "BMQ"
    },
    {
      name: "Oceanside Municipal Airport",
      city: "Fraser Island",
      country: "Australia",
      value: "OKB",
      display: "OKB"
    },
    {
      name: "Kirkuk Air Base",
      city: "Kirkuk",
      country: "Iraq",
      value: "KIK",
      display: "KIK"
    },
    {
      name: "Hamad International Airport",
      city: "Doha",
      country: "Qatar",
      value: "DOH",
      display: "DOH"
    },
    {
      name: "Great Barrier Aerodrome",
      city: "Claris",
      country: "New Zealand",
      value: "GBZ",
      display: "GBZ"
    },
    {
      name: "Ford Airport",
      city: "Iron Mountain",
      country: "United States",
      value: "IMT",
      display: "IMT"
    },
    {
      name: "Allakaket Airport",
      city: "Allakaket",
      country: "United States",
      value: "AET",
      display: "AET"
    },
    {
      name: "Michigan City Municipal Airport",
      city: "Michigan City",
      country: "United States",
      value: "MGC",
      display: "MGC"
    },
    {
      name: "Seward Airport",
      city: "Seward",
      country: "United States",
      value: "SWD",
      display: "SWD"
    },
    {
      name: "Grand Marais Cook County Airport",
      city: "Grand Marais",
      country: "United States",
      value: "GRM",
      display: "GRM"
    },
    {
      name: "Wausau Downtown Airport",
      city: "Wausau",
      country: "United States",
      value: "AUW",
      display: "AUW"
    },
    {
      name: "Mary Airport",
      city: "Mary",
      country: "Turkmenistan",
      value: "MYP",
      display: "MYP"
    },
    {
      name: "Sazena Airport",
      city: "Sazena",
      country: "Czech Republic",
      value: "LKS",
      display: "LKS"
    },
    {
      name: "Reykjahlio Airport",
      city: "Myvatn",
      country: "Iceland",
      value: "MVA",
      display: "MVA"
    },
    {
      name: "Sabadell Airport",
      city: "Sabadell",
      country: "Spain",
      value: "QSA",
      display: "QSA"
    },
    {
      name: "Whitsunday Island Airport",
      city: "Airlie Beach",
      country: "Australia",
      value: "WSY",
      display: "WSY"
    },
    {
      name: "Delaware County Johnson Field",
      city: "Muncie",
      country: "United States",
      value: "MIE",
      display: "MIE"
    },
    {
      name: "Purdue University Airport",
      city: "Lafayette",
      country: "United States",
      value: "LAF",
      display: "LAF"
    },
    {
      name: "North Las Vegas Airport",
      city: "Las Vegas",
      country: "United States",
      value: "VGT",
      display: "VGT"
    },
    {
      name: "Kenosha Regional Airport",
      city: "Kenosha",
      country: "United States",
      value: "ENW",
      display: "ENW"
    },
    {
      name: "Montrose Regional Airport",
      city: "Montrose CO",
      country: "United States",
      value: "MTJ",
      display: "MTJ"
    },
    {
      name: "Riverton Regional Airport",
      city: "Riverton WY",
      country: "United States",
      value: "RIW",
      display: "RIW"
    },
    {
      name: "Eastern Oregon Regional At Pendleton Airport",
      city: "Pendleton",
      country: "United States",
      value: "PDT",
      display: "PDT"
    },
    {
      name: "Lympne Airport",
      city: "Lympne",
      country: "United Kingdom",
      value: "LYM",
      display: "LYM"
    },
    {
      name: "Porto Cheli Airport",
      city: "Porto Heli",
      country: "Greece",
      value: "PKH",
      display: "PKH"
    },
    {
      name: "Tindal Airport",
      city: "Katherine",
      country: "Australia",
      value: "KTR",
      display: "KTR"
    },
    {
      name: "Nowra Airport",
      city: "Nowra",
      country: "Australia",
      value: "NOA",
      display: "NOA"
    },
    {
      name: "Lutsk Airport",
      city: "Lutsk",
      country: "Ukraine",
      value: "UKC",
      display: "UKC"
    },
    {
      name: "Chernihiv Shestovitsa Airport",
      city: "Chernigov",
      country: "Ukraine",
      value: "CEJ",
      display: "CEJ"
    },
    {
      name: "Ternopil International Airport",
      city: "Ternopol",
      country: "Ukraine",
      value: "TNL",
      display: "TNL"
    },
    {
      name: "Brest Airport",
      city: "Brest",
      country: "Belarus",
      value: "BQT",
      display: "BQT"
    },
    {
      name: "Wittman Regional Airport",
      city: "Oshkosh",
      country: "United States",
      value: "OSH",
      display: "OSH"
    },
    {
      name: "Wangerooge Airport",
      city: "Wangerooge",
      country: "Germany",
      value: "AGE",
      display: "AGE"
    },
    {
      name: "Pangborn Memorial Airport",
      city: "Wenatchee",
      country: "United States",
      value: "EAT",
      display: "EAT"
    },
    {
      name: "Antonio Nery Juarbe Pol Airport",
      city: "Arecibo",
      country: "Puerto Rico",
      value: "ARE",
      display: "ARE"
    },
    {
      name: "Ringi Cove Airport",
      city: "Ringi Cove",
      country: "Solomon Islands",
      value: "RIN",
      display: "RIN"
    },
    {
      name: "Ust-Kut Airport",
      city: "Ust-Kut",
      country: "Russia",
      value: "UKX",
      display: "UKX"
    },
    {
      name: "Rimatara Airport",
      city: "Rimatara",
      country: "French Polynesia",
      value: "RMT",
      display: "RMT"
    },
    {
      name: "Lausanne-Blecherette Airport",
      city: "Lausanne",
      country: "Switzerland",
      value: "QLS",
      display: "QLS"
    },
    {
      name: "Locarno Airport",
      city: "Locarno",
      country: "Switzerland",
      value: "ZJI",
      display: "ZJI"
    },
    {
      name: "Neuchatel Airport",
      city: "Neuchatel",
      country: "Switzerland",
      value: "QNC",
      display: "QNC"
    },
    {
      name: "Gelendzhik Airport",
      city: "Gelendzhik",
      country: "Russia",
      value: "GDZ",
      display: "GDZ"
    },
    {
      name: "Tunoshna Airport",
      city: "Yaroslavl",
      country: "Russia",
      value: "IAR",
      display: "IAR"
    },
    {
      name: "Gu-Lian Airport",
      city: "Mohe County",
      country: "China",
      value: "OHE",
      display: "OHE"
    },
    {
      name: "Jining Qufu Airport",
      city: "Jining",
      country: "China",
      value: "JNG",
      display: "JNG"
    },
    {
      name: "Drake Bay Airport",
      city: "Puntarenas",
      country: "Costa Rica",
      value: "DRK",
      display: "DRK"
    },
    {
      name: "Naypyidaw Airport",
      city: "Naypyidaw",
      country: "Burma",
      value: "NYT",
      display: "NYT"
    },
    {
      name: "Jan Mayensfield",
      city: "Jan Mayen",
      country: "Norway",
      value: "ZXB",
      display: "ZXB"
    },
    {
      name: "Wuhai Airport",
      city: "Wuhai",
      country: "China",
      value: "WUA",
      display: "WUA"
    },
    {
      name: "Gary Chicago International Airport",
      city: "Gary",
      country: "United States",
      value: "GYY",
      display: "GYY"
    },
    {
      name: "Brainerd Lakes Regional Airport",
      city: "Brainerd",
      country: "United States",
      value: "BRD",
      display: "BRD"
    },
    {
      name: "Greenbrier Valley Airport",
      city: "Lewisburg",
      country: "United States",
      value: "LWB",
      display: "LWB"
    },
    {
      name: "Pitt Greenville Airport",
      city: "Greenville",
      country: "United States",
      value: "PGV",
      display: "PGV"
    },
    {
      name: "Chefornak Airport",
      city: "Chefornak",
      country: "United States",
      value: "CYF",
      display: "CYF"
    },
    {
      name: "Oxnard Airport",
      city: "Oxnard",
      country: "United States",
      value: "OXR",
      display: "OXR"
    },
    {
      name: "Tongren Fenghuang Airport",
      city: "Tongren",
      country: "China",
      value: "TEN",
      display: "TEN"
    },
    {
      name: "Naiu Airport",
      city: "Niau",
      country: "French Polynesia",
      value: "NIU",
      display: "NIU"
    },
    {
      name: "Schenectady County Airport",
      city: "Scotia NY",
      country: "United States",
      value: "SCH",
      display: "SCH"
    },
    {
      name: "Begishevo Airport",
      city: "Nizhnekamsk",
      country: "Russia",
      value: "NBC",
      display: "NBC"
    },
    {
      name: "Siargao Airport",
      city: "Siargao",
      country: "Philippines",
      value: "IAO",
      display: "IAO"
    },
    {
      name: "Langeoog Airport",
      city: "Langeoog",
      country: "Germany",
      value: "LGO",
      display: "LGO"
    },
    {
      name: "Nelspruit Airport",
      city: "Nelspruit",
      country: "South Africa",
      value: "NLP",
      display: "NLP"
    },
    {
      name: "Cherkasy International Airport",
      city: "Cherkassy",
      country: "Ukraine",
      value: "CKC",
      display: "CKC"
    },
    {
      name: "Northeast Florida Regional Airport",
      city: "St. Augustine Airport",
      country: "United States",
      value: "UST",
      display: "UST"
    },
    {
      name: "Mykolaiv International Airport",
      city: "Nikolayev",
      country: "Ukraine",
      value: "NLV",
      display: "NLV"
    },
    {
      name: "Ramechhap Airport",
      city: "Ramechhap",
      country: "Nepal",
      value: "RHP",
      display: "RHP"
    },
    {
      name: "Charles M. Schulz Sonoma County Airport",
      city: "Santa Rosa",
      country: "United States",
      value: "STS",
      display: "STS"
    },
    {
      name: "Kissimmee Gateway Airport",
      city: "Kissimmee",
      country: "United States",
      value: "ISM",
      display: "ISM"
    },
    {
      name: "Lake City Gateway Airport",
      city: "Lake City",
      country: "United States",
      value: "LCQ",
      display: "LCQ"
    },
    {
      name: "Logan-Cache Airport",
      city: "Logan",
      country: "United States",
      value: "LGU",
      display: "LGU"
    },
    {
      name: "Brigham City Airport",
      city: "Brigham City",
      country: "United States",
      value: "BMC",
      display: "BMC"
    },
    {
      name: "Malad City Airport",
      city: "Malad City",
      country: "United States",
      value: "MLD",
      display: "MLD"
    },
    {
      name: "Aspen-Pitkin Co/Sardy Field",
      city: "Aspen",
      country: "United States",
      value: "ASE",
      display: "ASE"
    },
    {
      name: "Ulyanovsk Baratayevka Airport",
      city: "Ulyanovsk",
      country: "Russia",
      value: "ULV",
      display: "ULV"
    },
    {
      name: "Kerrville Municipal Louis Schreiner Field",
      city: "Kerrville",
      country: "United States",
      value: "ERV",
      display: "ERV"
    },
    {
      name: "Sussex County Airport",
      city: "Georgetown",
      country: "United States",
      value: "GED",
      display: "GED"
    },
    {
      name: "Prince Rupert/Seal Cove Seaplane Base",
      city: "Prince Rupert",
      country: "Canada",
      value: "ZSW",
      display: "ZSW"
    },
    {
      name: "Great Bend Municipal Airport",
      city: "Great Bend",
      country: "United States",
      value: "GBN",
      display: "GBN"
    },
    {
      name: "Hays Regional Airport",
      city: "Hays",
      country: "United States",
      value: "HYS",
      display: "HYS"
    },
    {
      name: "Spirit of St Louis Airport",
      city: "Null",
      country: "United States",
      value: "SUS",
      display: "SUS"
    },
    {
      name: "Ely Municipal Airport",
      city: "Ely",
      country: "United States",
      value: "LYU",
      display: "LYU"
    },
    {
      name: "Grand Rapids Itasca Co-Gordon Newstrom field",
      city: "Grand Rapids MN",
      country: "United States",
      value: "GPZ",
      display: "GPZ"
    },
    {
      name: "Thief River Falls Regional Airport",
      city: "Thief River Falls",
      country: "United States",
      value: "TVF",
      display: "TVF"
    },
    {
      name: "Eagle River Union Airport",
      city: "Eagle River",
      country: "United States",
      value: "EGV",
      display: "EGV"
    },
    {
      name: "Lakeland-Noble F. Lee Memorial field",
      city: "Minocqua - Woodruff",
      country: "United States",
      value: "ARV",
      display: "ARV"
    },
    {
      name: "Ankeny Regional Airport",
      city: "Ankeny",
      country: "United States",
      value: "IKV",
      display: "IKV"
    },
    {
      name: "Berens River Airport",
      city: "Berens River",
      country: "Canada",
      value: "YBV",
      display: "YBV"
    },
    {
      name: "Corpus Christi Naval Air Station/Truax Field",
      city: "Corpus Christi",
      country: "United States",
      value: "NGP",
      display: "NGP"
    },
    {
      name: "Catalina Airport",
      city: "Catalina Island",
      country: "United States",
      value: "AVX",
      display: "AVX"
    },
    {
      name: "Mojave Airport",
      city: "Mojave",
      country: "United States",
      value: "MHV",
      display: "MHV"
    },
    {
      name: "Interlaken Air Base",
      city: "Interlaken",
      country: "Switzerland",
      value: "ZIN",
      display: "ZIN"
    },
    {
      name: "Inisheer Aerodrome",
      city: "Inisheer",
      country: "Ireland",
      value: "INQ",
      display: "INQ"
    },
    {
      name: "Strezhevoy Airport",
      city: "Strezhevoy",
      country: "Russia",
      value: "SWT",
      display: "SWT"
    },
    {
      name: "Hutchinson Municipal Airport",
      city: "Hutchinson",
      country: "United States",
      value: "HUT",
      display: "HUT"
    },
    {
      name: "Rosecrans Memorial Airport",
      city: "Rosecrans",
      country: "United States",
      value: "STJ",
      display: "STJ"
    },
    {
      name: "Whiting Field Naval Air Station South Airport",
      city: "Cuxhaven",
      country: "Germany",
      value: "NDZ",
      display: "NDZ"
    },
    {
      name: "Volk Field",
      city: "Camp Douglas",
      country: "United States",
      value: "VOK",
      display: "VOK"
    },
    {
      name: "Gunnison Crested Butte Regional Airport",
      city: "Gunnison",
      country: "United States",
      value: "GUC",
      display: "GUC"
    },
    {
      name: "Xi'an Xiguan Airport",
      city: "Xi an",
      country: "China",
      value: "SIA",
      display: "SIA"
    },
    {
      name: "Zamperini Field",
      city: "Torrance",
      country: "United States",
      value: "TOA",
      display: "TOA"
    },
    {
      name: "Manistee Co Blacker Airport",
      city: "Manistee",
      country: "United States",
      value: "MBL",
      display: "MBL"
    },
    {
      name: "Charlotte County Airport",
      city: "Punta Gorda",
      country: "United States",
      value: "PGD",
      display: "PGD"
    },
    {
      name: "Northern Aroostook Regional Airport",
      city: "Frenchville",
      country: "United States",
      value: "WFK",
      display: "WFK"
    },
    {
      name: "Chautauqua County-Jamestown Airport",
      city: "Jamestown",
      country: "United States",
      value: "JHW",
      display: "JHW"
    },
    {
      name: "La Macaza / Mont-Tremblant International Inc Airport",
      city: "Mont-Tremblant",
      country: "Canada",
      value: "YTM",
      display: "YTM"
    },
    {
      name: "Lake Cumberland Regional Airport",
      city: "Somerset",
      country: "United States",
      value: "SME",
      display: "SME"
    },
    {
      name: "Shenandoah Valley Regional Airport",
      city: "Weyers Cave",
      country: "United States",
      value: "SHD",
      display: "SHD"
    },
    {
      name: "Devils Lake Regional Airport",
      city: "Devils Lake",
      country: "United States",
      value: "DVL",
      display: "DVL"
    },
    {
      name: "Dickinson Theodore Roosevelt Regional Airport",
      city: "Dickinson",
      country: "United States",
      value: "DIK",
      display: "DIK"
    },
    {
      name: "Sidney Richland Municipal Airport",
      city: "Sidney",
      country: "United States",
      value: "SDY",
      display: "SDY"
    },
    {
      name: "Chadron Municipal Airport",
      city: "Chadron",
      country: "United States",
      value: "CDR",
      display: "CDR"
    },
    {
      name: "Alliance Municipal Airport",
      city: "Alliance",
      country: "United States",
      value: "AIA",
      display: "AIA"
    },
    {
      name: "Mc Cook Ben Nelson Regional Airport",
      city: "McCook",
      country: "United States",
      value: "MCK",
      display: "MCK"
    },
    {
      name: "The Florida Keys Marathon Airport",
      city: "Marathon",
      country: "United States",
      value: "MTH",
      display: "MTH"
    },
    {
      name: "Dawson Community Airport",
      city: "Glendive",
      country: "United States",
      value: "GDV",
      display: "GDV"
    },
    {
      name: "L M Clayton Airport",
      city: "Wolf Point",
      country: "United States",
      value: "OLF",
      display: "OLF"
    },
    {
      name: "Yellowstone Airport",
      city: "West Yellowstone",
      country: "United States",
      value: "WYS",
      display: "WYS"
    },
    {
      name: "San Luis Valley Regional Bergman Field",
      city: "Alamosa",
      country: "United States",
      value: "ALS",
      display: "ALS"
    },
    {
      name: "Canyonlands Field",
      city: "Moab",
      country: "United States",
      value: "CNY",
      display: "CNY"
    },
    {
      name: "Ely Airport Yelland Field",
      city: "Ely",
      country: "United States",
      value: "ELY",
      display: "ELY"
    },
    {
      name: "Vernal Regional Airport",
      city: "Vernal",
      country: "United States",
      value: "VEL",
      display: "VEL"
    },
    {
      name: "Sierra Blanca Regional Airport",
      city: "Ruidoso",
      country: "United States",
      value: "SRR",
      display: "SRR"
    },
    {
      name: "Show Low Regional Airport",
      city: "Show Low",
      country: "United States",
      value: "SOW",
      display: "SOW"
    },
    {
      name: "McCall Municipal Airport",
      city: "McCall",
      country: "United States",
      value: "MYL",
      display: "MYL"
    },
    {
      name: "Lemhi County Airport",
      city: "Salmon",
      country: "United States",
      value: "SMN",
      display: "SMN"
    },
    {
      name: "Mammoth Yosemite Airport",
      city: "Mammoth Lakes",
      country: "United States",
      value: "MMH",
      display: "MMH"
    },
    {
      name: "Friday Harbor Airport",
      city: "Friday Harbor",
      country: "United States",
      value: "FRD",
      display: "FRD"
    },
    {
      name: "Orcas Island Airport",
      city: "Eastsound",
      country: "United States",
      value: "ESD",
      display: "ESD"
    },
    {
      name: "Astoria Regional Airport",
      city: "Astoria",
      country: "United States",
      value: "AST",
      display: "AST"
    },
    {
      name: "Newport Municipal Airport",
      city: "Newport",
      country: "United States",
      value: "ONP",
      display: "ONP"
    },
    {
      name: "Emmonak Airport",
      city: "Emmonak",
      country: "United States",
      value: "EMK",
      display: "EMK"
    },
    {
      name: "Unalakleet Airport",
      city: "Unalakleet",
      country: "United States",
      value: "UNK",
      display: "UNK"
    },
    {
      name: "Ugnu-Kuparuk Airport",
      city: "Kuparuk",
      country: "United States",
      value: "UUK",
      display: "UUK"
    },
    {
      name: "Shageluk Airport",
      city: "Shageluk",
      country: "United States",
      value: "SHX",
      display: "SHX"
    },
    {
      name: "Nuiqsut Airport",
      city: "Nuiqsut",
      country: "United States",
      value: "NUI",
      display: "NUI"
    },
    {
      name: "Eek Airport",
      city: "Eek",
      country: "United States",
      value: "EEK",
      display: "EEK"
    },
    {
      name: "Kasigluk Airport",
      city: "Kasigluk",
      country: "United States",
      value: "KUK",
      display: "KUK"
    },
    {
      name: "Kwethluk Airport",
      city: "Kwethluk",
      country: "United States",
      value: "KWT",
      display: "KWT"
    },
    {
      name: "Kwigillingok Airport",
      city: "Kwigillingok",
      country: "United States",
      value: "KWK",
      display: "KWK"
    },
    {
      name: "Marshall Don Hunter Sr Airport",
      city: "Marshall",
      country: "United States",
      value: "MLL",
      display: "MLL"
    },
    {
      name: "Russian Mission Airport",
      city: "Russian Mission",
      country: "United States",
      value: "RSH",
      display: "RSH"
    },
    {
      name: "Koliganek Airport",
      city: "Koliganek",
      country: "United States",
      value: "KGK",
      display: "KGK"
    },
    {
      name: "Manokotak Airport",
      city: "Manokotak",
      country: "United States",
      value: "KMO",
      display: "KMO"
    },
    {
      name: "Chalkyitsik Airport",
      city: "Chalkyitsik",
      country: "United States",
      value: "CIK",
      display: "CIK"
    },
    {
      name: "Eagle Airport",
      city: "Eagle",
      country: "United States",
      value: "EAA",
      display: "EAA"
    },
    {
      name: "Hughes Airport",
      city: "Hughes",
      country: "United States",
      value: "HUS",
      display: "HUS"
    },
    {
      name: "Huslia Airport",
      city: "Huslia",
      country: "United States",
      value: "HSL",
      display: "HSL"
    },
    {
      name: "Nulato Airport",
      city: "Nulato",
      country: "United States",
      value: "NUL",
      display: "NUL"
    },
    {
      name: "Venetie Airport",
      city: "Venetie",
      country: "United States",
      value: "VEE",
      display: "VEE"
    },
    {
      name: "Beaver Airport",
      city: "Beaver",
      country: "United States",
      value: "WBQ",
      display: "WBQ"
    },
    {
      name: "Central Airport",
      city: "Central",
      country: "United States",
      value: "CEM",
      display: "CEM"
    },
    {
      name: "Shungnak Airport",
      city: "Shungnak",
      country: "United States",
      value: "SHG",
      display: "SHG"
    },
    {
      name: "Inyokern Airport",
      city: "Inyokern",
      country: "United States",
      value: "IYK",
      display: "IYK"
    },
    {
      name: "Visalia Municipal Airport",
      city: "Visalia",
      country: "United States",
      value: "VIS",
      display: "VIS"
    },
    {
      name: "Merced Regional Macready Field",
      city: "Merced",
      country: "United States",
      value: "MCE",
      display: "MCE"
    },
    {
      name: "Laguna de Los Patos International Airport",
      city: "Colonia",
      country: "Uruguay",
      value: "CYR",
      display: "CYR"
    },
    {
      name: "Amarais Airport",
      city: "Campinas",
      country: "Brazil",
      value: "CPQ",
      display: "CPQ"
    },
    {
      name: "Toowoomba Airport",
      city: "Toowoomba",
      country: "Australia",
      value: "TWB",
      display: "TWB"
    },
    {
      name: "Arkalyk North Airport",
      city: "Arkalyk",
      country: "Kazakhstan",
      value: "AYK",
      display: "AYK"
    },
    {
      name: "Angoon Seaplane Base",
      city: "Angoon",
      country: "United States",
      value: "AGN",
      display: "AGN"
    },
    {
      name: "Elfin Cove Seaplane Base",
      city: "Elfin Cove",
      country: "United States",
      value: "ELV",
      display: "ELV"
    },
    {
      name: "Funter Bay Seaplane Base",
      city: "Funter Bay",
      country: "United States",
      value: "FNR",
      display: "FNR"
    },
    {
      name: "Hoonah Airport",
      city: "Hoonah",
      country: "United States",
      value: "HNH",
      display: "HNH"
    },
    {
      name: "Kake Airport",
      city: "Kake",
      country: "United States",
      value: "AFE",
      display: "AFE"
    },
    {
      name: "Metlakatla Seaplane Base",
      city: "Metakatla",
      country: "United States",
      value: "MTM",
      display: "MTM"
    },
    {
      name: "Hydaburg Seaplane Base",
      city: "Hydaburg",
      country: "United States",
      value: "HYG",
      display: "HYG"
    },
    {
      name: "Egegik Airport",
      city: "Egegik",
      country: "United States",
      value: "EGX",
      display: "EGX"
    },
    {
      name: "Perryville Airport",
      city: "Perryville",
      country: "United States",
      value: "KPV",
      display: "KPV"
    },
    {
      name: "Pilot Point Airport",
      city: "Pilot Point",
      country: "United States",
      value: "PIP",
      display: "PIP"
    },
    {
      name: "South Naknek Nr 2 Airport",
      city: "South Naknek",
      country: "United States",
      value: "WSN",
      display: "WSN"
    },
    {
      name: "Akhiok Airport",
      city: "Akhiok",
      country: "United States",
      value: "AKK",
      display: "AKK"
    },
    {
      name: "Karluk Airport",
      city: "Karluk",
      country: "United States",
      value: "KYK",
      display: "KYK"
    },
    {
      name: "Larsen Bay Airport",
      city: "Larsen Bay",
      country: "United States",
      value: "KLN",
      display: "KLN"
    },
    {
      name: "Ambler Airport",
      city: "Ambler",
      country: "United States",
      value: "ABL",
      display: "ABL"
    },
    {
      name: "Buckland Airport",
      city: "Buckland",
      country: "United States",
      value: "BKC",
      display: "BKC"
    },
    {
      name: "Bob Baker Memorial Airport",
      city: "Kiana",
      country: "United States",
      value: "IAN",
      display: "IAN"
    },
    {
      name: "Kobuk Airport",
      city: "Kobuk",
      country: "United States",
      value: "OBU",
      display: "OBU"
    },
    {
      name: "Robert (Bob) Curtis Memorial Airport",
      city: "Noorvik",
      country: "United States",
      value: "ORV",
      display: "ORV"
    },
    {
      name: "Selawik Airport",
      city: "Selawik",
      country: "United States",
      value: "WLK",
      display: "WLK"
    },
    {
      name: "Brevig Mission Airport",
      city: "Brevig Mission",
      country: "United States",
      value: "KTS",
      display: "KTS"
    },
    {
      name: "Elim Airport",
      city: "Elim",
      country: "United States",
      value: "ELI",
      display: "ELI"
    },
    {
      name: "Golovin Airport",
      city: "Golovin",
      country: "United States",
      value: "GLV",
      display: "GLV"
    },
    {
      name: "Teller Airport",
      city: "Teller",
      country: "United States",
      value: "TLA",
      display: "TLA"
    },
    {
      name: "Wales Airport",
      city: "Wales",
      country: "United States",
      value: "WAA",
      display: "WAA"
    },
    {
      name: "White Mountain Airport",
      city: "White Mountain",
      country: "United States",
      value: "WMO",
      display: "WMO"
    },
    {
      name: "Koyuk Alfred Adams Airport",
      city: "Koyuk",
      country: "United States",
      value: "KKA",
      display: "KKA"
    },
    {
      name: "St Michael Airport",
      city: "St. Michael",
      country: "United States",
      value: "SMK",
      display: "SMK"
    },
    {
      name: "Shaktoolik Airport",
      city: "Shaktoolik",
      country: "United States",
      value: "SKK",
      display: "SKK"
    },
    {
      name: "Tin City Long Range Radar Station Airport",
      city: "Tin City",
      country: "United States",
      value: "TNC",
      display: "TNC"
    },
    {
      name: "Atka Airport",
      city: "Atka",
      country: "United States",
      value: "AKB",
      display: "AKB"
    },
    {
      name: "Yakataga Airport",
      city: "Yakataga",
      country: "United States",
      value: "CYT",
      display: "CYT"
    },
    {
      name: "Alakanuk Airport",
      city: "Alakanuk",
      country: "United States",
      value: "AUK",
      display: "AUK"
    },
    {
      name: "Kipnuk Airport",
      city: "Kipnuk",
      country: "United States",
      value: "KPN",
      display: "KPN"
    },
    {
      name: "False Pass Airport",
      city: "False Pass",
      country: "United States",
      value: "KFP",
      display: "KFP"
    },
    {
      name: "Nelson Lagoon Airport",
      city: "Nelson Lagoon",
      country: "United States",
      value: "NLG",
      display: "NLG"
    },
    {
      name: "Port Moller Airport",
      city: "Cold Bay",
      country: "United States",
      value: "PML",
      display: "PML"
    },
    {
      name: "Klawock Airport",
      city: "Klawock",
      country: "United States",
      value: "KLW",
      display: "KLW"
    },
    {
      name: "Quinhagak Airport",
      city: "Quinhagak",
      country: "United States",
      value: "KWN",
      display: "KWN"
    },
    {
      name: "Kotlik Airport",
      city: "Kotlik",
      country: "United States",
      value: "KOT",
      display: "KOT"
    },
    {
      name: "Koyukuk Airport",
      city: "Koyukuk",
      country: "United States",
      value: "KYU",
      display: "KYU"
    },
    {
      name: "Scammon Bay Airport",
      city: "Scammon Bay",
      country: "United States",
      value: "SCM",
      display: "SCM"
    },
    {
      name: "Nondalton Airport",
      city: "Nondalton",
      country: "United States",
      value: "NNL",
      display: "NNL"
    },
    {
      name: "Kongiganak Airport",
      city: "Kongiganak",
      country: "United States",
      value: "KKH",
      display: "KKH"
    },
    {
      name: "Nikolai Airport",
      city: "Nikolai",
      country: "United States",
      value: "NIB",
      display: "NIB"
    },
    {
      name: "Akiak Airport",
      city: "Akiak",
      country: "United States",
      value: "AKI",
      display: "AKI"
    },
    {
      name: "Wainwright Airport",
      city: "Wainwright",
      country: "United States",
      value: "AIN",
      display: "AIN"
    },
    {
      name: "Zapala Airport",
      city: "ZAPALA",
      country: "Argentina",
      value: "APZ",
      display: "APZ"
    },
    {
      name: "Rincon De Los Sauces Airport",
      city: "Rincon de los Sauces",
      country: "Argentina",
      value: "RDS",
      display: "RDS"
    },
    {
      name: "Tte. Julio Gallardo Airport",
      city: "Puerto Natales",
      country: "Chile",
      value: "PNT",
      display: "PNT"
    },
    {
      name: "Sierra Grande Airport",
      city: "Sierra Grande",
      country: "Argentina",
      value: "SGV",
      display: "SGV"
    },
    {
      name: "Cabo F.A.A. H. R. Bordon Airport",
      city: "Ingeniero Jacobacci",
      country: "Argentina",
      value: "IGB",
      display: "IGB"
    },
    {
      name: "Chenega Bay Airport",
      city: "Chenega",
      country: "United States",
      value: "NCN",
      display: "NCN"
    },
    {
      name: "Tok Junction Airport",
      city: "Tok",
      country: "United States",
      value: "TKJ",
      display: "TKJ"
    },
    {
      name: "Circle City /New/ Airport",
      city: "Circle",
      country: "United States",
      value: "IRC",
      display: "IRC"
    },
    {
      name: "Sleetmute Airport",
      city: "Sleetmute",
      country: "United States",
      value: "SLQ",
      display: "SLQ"
    },
    {
      name: "Healy River Airport",
      city: "Healy",
      country: "United States",
      value: "HKB",
      display: "HKB"
    },
    {
      name: "Klawock Seaplane Base",
      city: "Klawock",
      country: "United States",
      value: "AQC",
      display: "AQC"
    },
    {
      name: "Minchumina Airport",
      city: "Lake Minchumina",
      country: "United States",
      value: "MHM",
      display: "MHM"
    },
    {
      name: "Manley Hot Springs Airport",
      city: "Manley Hot Springs",
      country: "United States",
      value: "MLY",
      display: "MLY"
    },
    {
      name: "Postville Airport",
      city: "Postville",
      country: "Canada",
      value: "YSO",
      display: "YSO"
    },
    {
      name: "Kangiqsujuaq (Wakeham Bay) Airport",
      city: "Kangiqsujuaq",
      country: "Canada",
      value: "YWB",
      display: "YWB"
    },
    {
      name: "Alma Airport",
      city: "Alma",
      country: "Canada",
      value: "YTF",
      display: "YTF"
    },
    {
      name: "Havre St Pierre Airport",
      city: "Havre-Saint-Pierre",
      country: "Canada",
      value: "YGV",
      display: "YGV"
    },
    {
      name: "Rimouski Airport",
      city: "Rimouski",
      country: "Canada",
      value: "YXK",
      display: "YXK"
    },
    {
      name: "Tadoule Lake Airport",
      city: "Tadoule Lake",
      country: "Canada",
      value: "XTL",
      display: "XTL"
    },
    {
      name: "Lac Brochet Airport",
      city: "Lac Brochet",
      country: "Canada",
      value: "XLB",
      display: "XLB"
    },
    {
      name: "South Indian Lake Airport",
      city: "South Indian Lake",
      country: "Canada",
      value: "XSI",
      display: "XSI"
    },
    {
      name: "Brochet Airport",
      city: "Brochet",
      country: "Canada",
      value: "YBT",
      display: "YBT"
    },
    {
      name: "Little Grand Rapids Airport",
      city: "Little Grand Rapids",
      country: "Canada",
      value: "ZGR",
      display: "ZGR"
    },
    {
      name: "Cross Lake (Charlie Sinclair Memorial) Airport",
      city: "Cross Lake",
      country: "Canada",
      value: "YCR",
      display: "YCR"
    },
    {
      name: "Red Sucker Lake Airport",
      city: "Red Sucker Lake",
      country: "Canada",
      value: "YRS",
      display: "YRS"
    },
    {
      name: "Rainbow Lake Airport",
      city: "Rainbow Lake",
      country: "Canada",
      value: "YOP",
      display: "YOP"
    },
    {
      name: "Bonnyville Airport",
      city: "Bonnyville",
      country: "Canada",
      value: "YBY",
      display: "YBY"
    },
    {
      name: "Nanaimo Harbour Water Airport",
      city: "Nanaimo",
      country: "Canada",
      value: "ZNA",
      display: "ZNA"
    },
    {
      name: "Ganges Seaplane Base",
      city: "Ganges",
      country: "Canada",
      value: "YGG",
      display: "YGG"
    },
    {
      name: "Fort St James Airport",
      city: "Fort St. James",
      country: "Canada",
      value: "YJM",
      display: "YJM"
    },
    {
      name: "Boundary Bay Airport",
      city: "Boundary Bay",
      country: "Canada",
      value: "YDT",
      display: "YDT"
    },
    {
      name: "Denny Island Airport",
      city: "Bella Bella",
      country: "Canada",
      value: "ZEL",
      display: "ZEL"
    },
    {
      name: "Wekweeti Airport",
      city: "Wekweeti",
      country: "Canada",
      value: "YFJ",
      display: "YFJ"
    },
    {
      name: "Corn Island",
      city: "Corn Island",
      country: "Nicaragua",
      value: "RNI",
      display: "RNI"
    },
    {
      name: "San Pedro Airport",
      city: "Bonanza",
      country: "Nicaragua",
      value: "BZA",
      display: "BZA"
    },
    {
      name: "Rosita Airport",
      city: "Rosita",
      country: "Nicaragua",
      value: "RFS",
      display: "RFS"
    },
    {
      name: "Siuna",
      city: "Siuna",
      country: "Nicaragua",
      value: "SIU",
      display: "SIU"
    },
    {
      name: "Waspam Airport",
      city: "Waspam",
      country: "Nicaragua",
      value: "WSP",
      display: "WSP"
    },
    {
      name: "Playa Samara/Carrillo Airport",
      city: "Carrillo",
      country: "Costa Rica",
      value: "PLD",
      display: "PLD"
    },
    {
      name: "Constanza - Expedicion 14 de Junio National Airport",
      city: "Constanza",
      country: "Dominican Republic",
      value: "COZ",
      display: "COZ"
    },
    {
      name: "Negril Airport",
      city: "Negril",
      country: "Jamaica",
      value: "NEG",
      display: "NEG"
    },
    {
      name: "Jose Aponte de la Torre Airport",
      city: "Ceiba",
      country: "Puerto Rico",
      value: "RVR",
      display: "RVR"
    },
    {
      name: "Charlotte Amalie Harbor Seaplane Base",
      city: "Charlotte Amalie",
      country: "Virgin Islands",
      value: "SPB",
      display: "SPB"
    },
    {
      name: "D. Casimiro Szlapelis Airport",
      city: "Alto Rio Senguer",
      country: "Argentina",
      value: "ARR",
      display: "ARR"
    },
    {
      name: "Jose De San Martin Airport",
      city: "Jose de San Martin",
      country: "Argentina",
      value: "JSM",
      display: "JSM"
    },
    {
      name: "Uyuni Airport",
      city: "Uyuni",
      country: "Bolivia",
      value: "UYU",
      display: "UYU"
    },
    {
      name: "Abaiang Airport",
      city: "Abaiang Atoll",
      country: "Kiribati",
      value: "ABF",
      display: "ABF"
    },
    {
      name: "Albina Airport",
      city: "Albina",
      country: "Suriname",
      value: "ABN",
      display: "ABN"
    },
    {
      name: "Drietabbetje Airport",
      city: "Drietabbetje",
      country: "Suriname",
      value: "DRJ",
      display: "DRJ"
    },
    {
      name: "Nieuw Nickerie Airport",
      city: "Nieuw Nickerie",
      country: "Suriname",
      value: "ICK",
      display: "ICK"
    },
    {
      name: "Vincent Fayks Airport",
      city: "Paloemeu",
      country: "Suriname",
      value: "OEM",
      display: "OEM"
    },
    {
      name: "Stoelmanseiland Airport",
      city: "Stoelmans Eiland",
      country: "Suriname",
      value: "SMZ",
      display: "SMZ"
    },
    {
      name: "Totness Airport",
      city: "Totness",
      country: "Suriname",
      value: "TOT",
      display: "TOT"
    },
    {
      name: "Wageningen Airstrip",
      city: "Wageningen",
      country: "Suriname",
      value: "AGI",
      display: "AGI"
    },
    {
      name: "Codela Airport",
      city: "Guapiles",
      country: "Costa Rica",
      value: "CSC",
      display: "CSC"
    },
    {
      name: "Orinduik Airport",
      city: "Orinduik",
      country: "Guyana",
      value: "ORJ",
      display: "ORJ"
    },
    {
      name: "Annai Airport",
      city: "Annai",
      country: "Guyana",
      value: "NAI",
      display: "NAI"
    },
    {
      name: "Imbaimadai Airport",
      city: "Imbaimadai",
      country: "Guyana",
      value: "IMB",
      display: "IMB"
    },
    {
      name: "Kamarang Airport",
      city: "Kamarang",
      country: "Guyana",
      value: "KAR",
      display: "KAR"
    },
    {
      name: "Mabaruma Airport",
      city: "Mabaruma",
      country: "Guyana",
      value: "USI",
      display: "USI"
    },
    {
      name: "Mahdia Airport",
      city: "Mahdia",
      country: "Guyana",
      value: "MHA",
      display: "MHA"
    },
    {
      name: "Dr Augusto Roberto Fuster International Airport",
      city: "Pedro Juan Caballero",
      country: "Paraguay",
      value: "PJC",
      display: "PJC"
    },
    {
      name: "Alcides Fernandez Airport",
      city: "Acandi",
      country: "Colombia",
      value: "ACD",
      display: "ACD"
    },
    {
      name: "Los Colonizadores Airport",
      city: "Saravena",
      country: "Colombia",
      value: "RVE",
      display: "RVE"
    },
    {
      name: "Villa Garzon Airport",
      city: "Villa Garzon",
      country: "Colombia",
      value: "VGZ",
      display: "VGZ"
    },
    {
      name: "El Bagre Airport",
      city: "El Bagre",
      country: "Colombia",
      value: "EBG",
      display: "EBG"
    },
    {
      name: "Juan H White Airport",
      city: "Caucasia",
      country: "Colombia",
      value: "CAQ",
      display: "CAQ"
    },
    {
      name: "Mandinga Airport",
      city: "Condoto",
      country: "Colombia",
      value: "COG",
      display: "COG"
    },
    {
      name: "Golfo de Morrosquillo Airport",
      city: "Tolu",
      country: "Colombia",
      value: "TLU",
      display: "TLU"
    },
    {
      name: "Cabo Frio Airport",
      city: "Cabo Frio",
      country: "Brazil",
      value: "CFB",
      display: "CFB"
    },
    {
      name: "Presidente Joao Batista Figueiredo Airport",
      city: "Sinop",
      country: "Brazil",
      value: "OPS",
      display: "OPS"
    },
    {
      name: "Gurupi Airport",
      city: "Gurupi",
      country: "Brazil",
      value: "GRP",
      display: "GRP"
    },
    {
      name: "Santana do Araguaia Airport",
      city: "Santana do Araguaia",
      country: "Brazil",
      value: "CMP",
      display: "CMP"
    },
    {
      name: "Breves Airport",
      city: "Breves",
      country: "Brazil",
      value: "BVS",
      display: "BVS"
    },
    {
      name: "Soure Airport",
      city: "Soure",
      country: "Brazil",
      value: "SFK",
      display: "SFK"
    },
    {
      name: "Parintins Airport",
      city: "Parintins",
      country: "Brazil",
      value: "PIN",
      display: "PIN"
    },
    {
      name: "Barreiras Airport",
      city: "Barreiras",
      country: "Brazil",
      value: "BRA",
      display: "BRA"
    },
    {
      name: "Santa Terezinha Airport",
      city: "Santa Terezinha",
      country: "Brazil",
      value: "STZ",
      display: "STZ"
    },
    {
      name: "Minacu Airport",
      city: "Minacu",
      country: "Brazil",
      value: "MQH",
      display: "MQH"
    },
    {
      name: "Araguaina Airport",
      city: "Araguaina",
      country: "Brazil",
      value: "AUX",
      display: "AUX"
    },
    {
      name: "Novo Aripuana Airport",
      city: "Novo Aripuana",
      country: "Brazil",
      value: "NVP",
      display: "NVP"
    },
    {
      name: "Fazenda Colen Airport",
      city: "Lucas do Rio Verde",
      country: "Brazil",
      value: "LVR",
      display: "LVR"
    },
    {
      name: "Franca Airport",
      city: "Franca",
      country: "Brazil",
      value: "FRC",
      display: "FRC"
    },
    {
      name: "Dourados Airport",
      city: "Dourados",
      country: "Brazil",
      value: "DOU",
      display: "DOU"
    },
    {
      name: "Labrea Airport",
      city: "Labrea",
      country: "Brazil",
      value: "LBR",
      display: "LBR"
    },
    {
      name: "Maestro Marinho Franco Airport",
      city: "Rondonopolis",
      country: "Brazil",
      value: "ROO",
      display: "ROO"
    },
    {
      name: "Tancredo Thomas de Faria Airport",
      city: "Guarapuava",
      country: "Brazil",
      value: "GPB",
      display: "GPB"
    },
    {
      name: "Santa Terezinha Airport",
      city: "Joacaba",
      country: "Brazil",
      value: "JCB",
      display: "JCB"
    },
    {
      name: "General Leite de Castro Airport",
      city: "Rio Verde",
      country: "Brazil",
      value: "RVD",
      display: "RVD"
    },
    {
      name: "Romeu Zema Airport",
      city: "Araxa",
      country: "Brazil",
      value: "AAX",
      display: "AAX"
    },
    {
      name: "Maues Airport",
      city: "Maues",
      country: "Brazil",
      value: "MBZ",
      display: "MBZ"
    },
    {
      name: "Borba Airport",
      city: "Borba",
      country: "Brazil",
      value: "RBB",
      display: "RBB"
    },
    {
      name: "Coari Airport",
      city: "Coari",
      country: "Brazil",
      value: "CIZ",
      display: "CIZ"
    },
    {
      name: "Barcelos Airport",
      city: "Barcelos",
      country: "Brazil",
      value: "BAZ",
      display: "BAZ"
    },
    {
      name: "Diamantino Airport",
      city: "Diamantino",
      country: "Brazil",
      value: "DMT",
      display: "DMT"
    },
    {
      name: "Guanambi Airport",
      city: "Guanambi",
      country: "Brazil",
      value: "GNM",
      display: "GNM"
    },
    {
      name: "Tsletsi Airport",
      city: "Djelfa",
      country: "Algeria",
      value: "QDJ",
      display: "QDJ"
    },
    {
      name: "Nzagi Airport",
      city: "Nzagi",
      country: "Angola",
      value: "NZA",
      display: "NZA"
    },
    {
      name: "Lucapa Airport",
      city: "Lucapa",
      country: "Angola",
      value: "LBZ",
      display: "LBZ"
    },
    {
      name: "Am Timan Airport",
      city: "Am Timan",
      country: "Chad",
      value: "AMC",
      display: "AMC"
    },
    {
      name: "Shark El Oweinat International Airport",
      city: "Sharq Al-Owainat",
      country: "Egypt",
      value: "GSQ",
      display: "GSQ"
    },
    {
      name: "Eastern WV Regional Airport/Shepherd Field",
      city: "Martinsburg",
      country: "United States",
      value: "MRB",
      display: "MRB"
    },
    {
      name: "Awassa Airport",
      city: "Awasa",
      country: "Ethiopia",
      value: "AWA",
      display: "AWA"
    },
    {
      name: "Wilwal International Airport",
      city: "Jijiga",
      country: "Ethiopia",
      value: "JIJ",
      display: "JIJ"
    },
    {
      name: "Mekane Selam Airport",
      city: "Mekane Selam",
      country: "Ethiopia",
      value: "MKS",
      display: "MKS"
    },
    {
      name: "Debra Marcos Airport",
      city: "Debre Marqos",
      country: "Ethiopia",
      value: "DBM",
      display: "DBM"
    },
    {
      name: "Debre Tabor Airport",
      city: "Debre Tabor",
      country: "Ethiopia",
      value: "DBT",
      display: "DBT"
    },
    {
      name: "Harar Meda Airport",
      city: "Debre Zeyit",
      country: "Ethiopia",
      value: "QHR",
      display: "QHR"
    },
    {
      name: "Robe Airport",
      city: "Goba",
      country: "Ethiopia",
      value: "GOB",
      display: "GOB"
    },
    {
      name: "Mayumba Airport",
      city: "Mayumba",
      country: "Gabon",
      value: "MYB",
      display: "MYB"
    },
    {
      name: "Mara Serena Lodge Airstrip",
      city: "Maasai Mara",
      country: "Kenya",
      value: "MRE",
      display: "MRE"
    },
    {
      name: "Angama Airstrip",
      city: "Maasai Mara",
      country: "Kenya",
      value: "ANA",
      display: "ANA"
    },
    {
      name: "Rumbek Airport",
      city: "Rumbek",
      country: "Sudan",
      value: "RBX",
      display: "RBX"
    },
    {
      name: "Cape Palmas Airport",
      city: "Greenville",
      country: "Liberia",
      value: "CPA",
      display: "CPA"
    },
    {
      name: "Ouro Sogui Airport",
      city: "Matam",
      country: "Senegal",
      value: "MAX",
      display: "MAX"
    },
    {
      name: "Bird Island Airport",
      city: "Bird Island",
      country: "Seychelles",
      value: "BDI",
      display: "BDI"
    },
    {
      name: "Wadi Halfa Airport",
      city: "Wadi Halfa",
      country: "Sudan",
      value: "WHF",
      display: "WHF"
    },
    {
      name: "Bugungu Airport",
      city: "Pakuba",
      country: "Uganda",
      value: "PAF",
      display: "PAF"
    },
    {
      name: "Hatay Airport",
      city: "Hatay",
      country: "Turkey",
      value: "HTY",
      display: "HTY"
    },
    {
      name: "Raivavae Airport",
      city: "Raivavae",
      country: "French Polynesia",
      value: "RVV",
      display: "RVV"
    },
    {
      name: "Lleida-Alguaire Airport",
      city: "Lleida",
      country: "Spain",
      value: "ILD",
      display: "ILD"
    },
    {
      name: "Bildudalur Airport",
      city: "Bildudalur",
      country: "Iceland",
      value: "BIU",
      display: "BIU"
    },
    {
      name: "Gjogur Airport",
      city: "Gjogur",
      country: "Iceland",
      value: "GJR",
      display: "GJR"
    },
    {
      name: "Sauoarkrokur Airport",
      city: "Saudarkrokur",
      country: "Iceland",
      value: "SAK",
      display: "SAK"
    },
    {
      name: "Inishmaan Aerodrome",
      city: "Inishmaan",
      country: "Ireland",
      value: "IIA",
      display: "IIA"
    },
    {
      name: "Ak Bashat Airport",
      city: "Taldykorgan",
      country: "Kazakhstan",
      value: "TDK",
      display: "TDK"
    },
    {
      name: "Ulgii Mongolei Airport",
      city: "Olgii",
      country: "Mongolia",
      value: "ULG",
      display: "ULG"
    },
    {
      name: "Vologda Airport",
      city: "Vologda",
      country: "Russia",
      value: "VGD",
      display: "VGD"
    },
    {
      name: "Leshukonskoye Airport",
      city: "Arkhangelsk",
      country: "Russia",
      value: "LDG",
      display: "LDG"
    },
    {
      name: "Huesca/Pirineos Airport",
      city: "Huesca",
      country: "Spain",
      value: "HSK",
      display: "HSK"
    },
    {
      name: "Ciudad Real Central Airport",
      city: "Ciudad Real",
      country: "Spain",
      value: "CQM",
      display: "CQM"
    },
    {
      name: "Al Najaf International Airport",
      city: "Najaf",
      country: "Iraq",
      value: "NJF",
      display: "NJF"
    },
    {
      name: "Colonsay Airstrip",
      city: "Colonsay",
      country: "United Kingdom",
      value: "CSA",
      display: "CSA"
    },
    {
      name: "Rock Hill - York County Airport",
      city: "Rock Hill",
      country: "United States",
      value: "RKH",
      display: "RKH"
    },
    {
      name: "Allegheny County Airport",
      city: "Pittsburgh",
      country: "United States",
      value: "AGC",
      display: "AGC"
    },
    {
      name: "Fulton County Airport Brown Field",
      city: "Atlanta",
      country: "United States",
      value: "FTY",
      display: "FTY"
    },
    {
      name: "Tresco Heliport",
      city: "Tresco",
      country: "United Kingdom",
      value: "TSO",
      display: "TSO"
    },
    {
      name: "Tarin Kowt Airport",
      city: "Tarin Kowt",
      country: "Afghanistan",
      value: "TII",
      display: "TII"
    },
    {
      name: "Zaranj Airport",
      city: "Zaranj",
      country: "Afghanistan",
      value: "ZAJ",
      display: "ZAJ"
    },
    {
      name: "Chakcharan Airport",
      city: "Chaghcharan",
      country: "Afghanistan",
      value: "CCN",
      display: "CCN"
    },
    {
      name: "Fuyang Xiguan Airport",
      city: "Fuyang",
      country: "China",
      value: "FUG",
      display: "FUG"
    },
    {
      name: "Longyan Guanzhishan Airport",
      city: "Longyan",
      country: "China",
      value: "LCX",
      display: "LCX"
    },
    {
      name: "Xingyi Airport",
      city: "Xingyi",
      country: "China",
      value: "ACX",
      display: "ACX"
    },
    {
      name: "Liping Airport",
      city: "Liping",
      country: "China",
      value: "HZH",
      display: "HZH"
    },
    {
      name: "Ohio State University Airport",
      city: "Columbus",
      country: "United States",
      value: "OSU",
      display: "OSU"
    },
    {
      name: "Addison Airport",
      city: "Addison",
      country: "United States",
      value: "ADS",
      display: "ADS"
    },
    {
      name: "Destin Executive Airport",
      city: "Destin",
      country: "United States",
      value: "DTS",
      display: "DTS"
    },
    {
      name: "Chernobayevka Airport",
      city: "Kherson",
      country: "Ukraine",
      value: "KHE",
      display: "KHE"
    },
    {
      name: "Ryans Creek Aerodrome",
      city: "Stewart Island",
      country: "New Zealand",
      value: "SZS",
      display: "SZS"
    },
    {
      name: "Zhijiang Airport",
      city: "Zhijiang",
      country: "China",
      value: "HJJ",
      display: "HJJ"
    },
    {
      name: "Yarmouth Airport",
      city: "Yarmouth",
      country: "Canada",
      value: "YQI",
      display: "YQI"
    },
    {
      name: "Kinston Regional Jetport At Stallings Field",
      city: "Kinston",
      country: "United States",
      value: "ISO",
      display: "ISO"
    },
    {
      name: "First Flight Airport",
      city: "Kill Devil Hills",
      country: "United States",
      value: "FFA",
      display: "FFA"
    },
    {
      name: "Carajas Airport",
      city: "Parauapebas",
      country: "Brazil",
      value: "CKS",
      display: "CKS"
    },
    {
      name: "Tarempa Airport",
      city: "Anambas Islands",
      country: "Indonesia",
      value: "MWK",
      display: "MWK"
    },
    {
      name: "Persian Gulf International Airport",
      city: "Khalije Fars",
      country: "Iran",
      value: "PGU",
      display: "PGU"
    },
    {
      name: "Yasouj Airport",
      city: "Yasuj",
      country: "Iran",
      value: "YES",
      display: "YES"
    },
    {
      name: "Mosul International Airport",
      city: "Mosul",
      country: "Iraq",
      value: "OSB",
      display: "OSB"
    },
    {
      name: "Tajima Airport",
      city: "Toyooka",
      country: "Japan",
      value: "TJH",
      display: "TJH"
    },
    {
      name: "Amakusa Airport",
      city: "Amakusa",
      country: "Japan",
      value: "AXJ",
      display: "AXJ"
    },
    {
      name: "Kikai Airport",
      city: "Kikai",
      country: "Japan",
      value: "KKX",
      display: "KKX"
    },
    {
      name: "Aguni Airport",
      city: "Aguni",
      country: "Japan",
      value: "AGJ",
      display: "AGJ"
    },
    {
      name: "Bulgan Airport",
      city: "Bulgan",
      country: "Mongolia",
      value: "UGA",
      display: "UGA"
    },
    {
      name: "Ulaangom Airport",
      city: "Ulaangom",
      country: "Mongolia",
      value: "ULO",
      display: "ULO"
    },
    {
      name: "Borongan Airport",
      city: "Borongan",
      country: "Philippines",
      value: "BPR",
      display: "BPR"
    },
    {
      name: "Lubang Airport",
      city: "Lubang",
      country: "Philippines",
      value: "LBX",
      display: "LBX"
    },
    {
      name: "Kulob Airport",
      city: "Kulyab",
      country: "Tajikistan",
      value: "TJU",
      display: "TJU"
    },
    {
      name: "Dasoguz Airport",
      city: "Dasoguz",
      country: "Turkmenistan",
      value: "TAZ",
      display: "TAZ"
    },
    {
      name: "Barrow Island Airport",
      city: "Barrow Island",
      country: "Australia",
      value: "BWB",
      display: "BWB"
    },
    {
      name: "Derby Airport",
      city: "Derby",
      country: "Australia",
      value: "DRB",
      display: "DRB"
    },
    {
      name: "Walgett Airport",
      city: "Walgett",
      country: "Australia",
      value: "WGE",
      display: "WGE"
    },
    {
      name: "Bathurst Island Airport",
      city: "Bathurst Island",
      country: "Australia",
      value: "BRT",
      display: "BRT"
    },
    {
      name: "Dunk Island Airport",
      city: "Dunk Island",
      country: "Australia",
      value: "DKI",
      display: "DKI"
    },
    {
      name: "Lizard Island Airport",
      city: "Lizard Island",
      country: "Australia",
      value: "LZR",
      display: "LZR"
    },
    {
      name: "Hamilton Airport",
      city: "Hamilton",
      country: "Australia",
      value: "HLT",
      display: "HLT"
    },
    {
      name: "Halls Creek Airport",
      city: "Halls Creek",
      country: "Australia",
      value: "HCQ",
      display: "HCQ"
    },
    {
      name: "Fitzroy Crossing Airport",
      city: "Fitzroy Crossing",
      country: "Australia",
      value: "FIZ",
      display: "FIZ"
    },
    {
      name: "Ravensthorpe Airport",
      city: "Ravensthorpe",
      country: "Australia",
      value: "RVT",
      display: "RVT"
    },
    {
      name: "Provo Municipal Airport",
      city: "Provo",
      country: "United States",
      value: "PVU",
      display: "PVU"
    },
    {
      name: "Steamboat Springs Bob Adams Field",
      city: "Steamboat Springs",
      country: "United States",
      value: "SBS",
      display: "SBS"
    },
    {
      name: "Delta Municipal Airport",
      city: "Delta",
      country: "United States",
      value: "DTA",
      display: "DTA"
    },
    {
      name: "Richfield Municipal Airport",
      city: "Richfield",
      country: "United States",
      value: "RIF",
      display: "RIF"
    },
    {
      name: "Carbon County Regional/Buck Davis Field",
      city: "Price",
      country: "United States",
      value: "PUC",
      display: "PUC"
    },
    {
      name: "Los Alamos Airport",
      city: "Los Alamos",
      country: "United States",
      value: "LAM",
      display: "LAM"
    },
    {
      name: "Lake Havasu City Airport",
      city: "Lake Havasu City",
      country: "United States",
      value: "HII",
      display: "HII"
    },
    {
      name: "Winslow Lindbergh Regional Airport",
      city: "Winslow",
      country: "United States",
      value: "INW",
      display: "INW"
    },
    {
      name: "Douglas Municipal Airport",
      city: "Douglas",
      country: "United States",
      value: "DGL",
      display: "DGL"
    },
    {
      name: "Marakei Airport",
      city: "Marakei",
      country: "Kiribati",
      value: "MZK",
      display: "MZK"
    },
    {
      name: "Abemama Atoll Airport",
      city: "Abemama",
      country: "Kiribati",
      value: "AEA",
      display: "AEA"
    },
    {
      name: "Buariki Airport",
      city: "Buariki",
      country: "Kiribati",
      value: "AAK",
      display: "AAK"
    },
    {
      name: "Kuria Airport",
      city: "Kuria",
      country: "Kiribati",
      value: "KUC",
      display: "KUC"
    },
    {
      name: "Arorae Island Airport",
      city: "Arorae",
      country: "Kiribati",
      value: "AIS",
      display: "AIS"
    },
    {
      name: "Tamana Island Airport",
      city: "Tamana",
      country: "Kiribati",
      value: "TMN",
      display: "TMN"
    },
    {
      name: "Beru Airport",
      city: "Beru Island",
      country: "Kiribati",
      value: "BEZ",
      display: "BEZ"
    },
    {
      name: "Nikunau Airport",
      city: "Nikunau",
      country: "Kiribati",
      value: "NIG",
      display: "NIG"
    },
    {
      name: "Butaritari Atoll Airport",
      city: "Butaritari",
      country: "Kiribati",
      value: "BBG",
      display: "BBG"
    },
    {
      name: "Makin Island Airport",
      city: "Makin",
      country: "Kiribati",
      value: "MTK",
      display: "MTK"
    },
    {
      name: "Maiana Airport",
      city: "Maiana",
      country: "Kiribati",
      value: "MNK",
      display: "MNK"
    },
    {
      name: "Nonouti Airport",
      city: "Nonouti",
      country: "Kiribati",
      value: "NON",
      display: "NON"
    },
    {
      name: "Tabiteuea South Airport",
      city: "Tabiteuea",
      country: "Kiribati",
      value: "TSU",
      display: "TSU"
    },
    {
      name: "Whitianga Airport",
      city: "Whitianga",
      country: "New Zealand",
      value: "WTZ",
      display: "WTZ"
    },
    {
      name: "Takaka Airport",
      city: "Takaka",
      country: "New Zealand",
      value: "KTF",
      display: "KTF"
    },
    {
      name: "Afutara Aerodrome",
      city: "Afutara",
      country: "Solomon Islands",
      value: "AFT",
      display: "AFT"
    },
    {
      name: "Ulawa Airport",
      city: "Ulawa",
      country: "Solomon Islands",
      value: "RNA",
      display: "RNA"
    },
    {
      name: "Choiseul Bay Airport",
      city: "Choiseul Bay",
      country: "Solomon Islands",
      value: "CHY",
      display: "CHY"
    },
    {
      name: "Santa Ana Airport",
      city: "Santa Ana",
      country: "Solomon Islands",
      value: "NNB",
      display: "NNB"
    },
    {
      name: "Yandina Airport",
      city: "Yandina",
      country: "Solomon Islands",
      value: "XYA",
      display: "XYA"
    },
    {
      name: "Bartow Municipal Airport",
      city: "Bartow",
      country: "United States",
      value: "BOW",
      display: "BOW"
    },
    {
      name: "Livermore Municipal Airport",
      city: "Livermore",
      country: "United States",
      value: "LVK",
      display: "LVK"
    },
    {
      name: "Mariposa Yosemite Airport",
      city: "Mariposa",
      country: "United States",
      value: "RMY",
      display: "RMY"
    },
    {
      name: "Grootfontein Airport",
      city: "Grootfontein",
      country: "Namibia",
      value: "GFY",
      display: "GFY"
    },
    {
      name: "Rundu Airport",
      city: "Rundu",
      country: "Namibia",
      value: "NDU",
      display: "NDU"
    },
    {
      name: "Tasiilaq Heliport",
      city: "Angmagssalik",
      country: "Greenland",
      value: "AGM",
      display: "AGM"
    },
    {
      name: "Jacqueline Cochran Regional Airport",
      city: "Palm Springs",
      country: "United States",
      value: "TRM",
      display: "TRM"
    },
    {
      name: "Santa Monica Municipal Airport",
      city: "Santa Monica",
      country: "United States",
      value: "SMO",
      display: "SMO"
    },
    {
      name: "Bermuda Dunes Airport",
      city: "Palm Springs",
      country: "United States",
      value: "UDD",
      display: "UDD"
    },
    {
      name: "Scottsdale Airport",
      city: "Scottsdale",
      country: "United States",
      value: "ZSY",
      display: "ZSY"
    },
    {
      name: "Olympia Regional Airport",
      city: "Olympia",
      country: "United States",
      value: "OLM",
      display: "OLM"
    },
    {
      name: "Yolo County Davis Woodland Winters Airport",
      city: "Davis-Woodland-Winters",
      country: "United States",
      value: "DWA",
      display: "DWA"
    },
    {
      name: "Garfield County Regional Airport",
      city: "Rifle",
      country: "United States",
      value: "RIL",
      display: "RIL"
    },
    {
      name: "Shively Field",
      city: "SARATOGA",
      country: "United States",
      value: "SAA",
      display: "SAA"
    },
    {
      name: "DeKalb Peachtree Airport",
      city: "Atlanta",
      country: "United States",
      value: "PDK",
      display: "PDK"
    },
    {
      name: "Monroe County Airport",
      city: "Bloomington",
      country: "United States",
      value: "BMG",
      display: "BMG"
    },
    {
      name: "Witham Field",
      city: "Stuart",
      country: "United States",
      value: "SUA",
      display: "SUA"
    },
    {
      name: "Morristown Municipal Airport",
      city: "Morristown",
      country: "United States",
      value: "MMU",
      display: "MMU"
    },
    {
      name: "Napa County Airport",
      city: "Napa",
      country: "United States",
      value: "APC",
      display: "APC"
    },
    {
      name: "Brown Field Municipal Airport",
      city: "San Diego",
      country: "United States",
      value: "SDM",
      display: "SDM"
    },
    {
      name: "Venice Municipal Airport",
      city: "Venice",
      country: "United States",
      value: "VNC",
      display: "VNC"
    },
    {
      name: "Palm Beach County Glades Airport",
      city: "Pahokee",
      country: "United States",
      value: "PHK",
      display: "PHK"
    },
    {
      name: "Northwest Florida Beaches International Airport",
      city: "Panama City",
      country: "United States",
      value: "ECP",
      display: "ECP"
    },
    {
      name: "San Bernardino International Airport",
      city: "San Bernardino",
      country: "United States",
      value: "SBD",
      display: "SBD"
    },
    {
      name: "Valenca Airport",
      city: "Valenca",
      country: "Brazil",
      value: "VAL",
      display: "VAL"
    },
    {
      name: "Caruaru Airport",
      city: "Caruaru",
      country: "Brazil",
      value: "CAU",
      display: "CAU"
    },
    {
      name: "Wake Island Airfield",
      city: "Wake island",
      country: "Wake Island",
      value: "AWK",
      display: "AWK"
    },
    {
      name: "Aeroclube Airport",
      city: "Nova Iguacu",
      country: "Brazil",
      value: "QNV",
      display: "QNV"
    },
    {
      name: "San Carlos Airport",
      city: "San Carlos",
      country: "United States",
      value: "SQL",
      display: "SQL"
    },
    {
      name: "Koszalin Zegrze Pomorskie Air Base",
      city: "Koszalin",
      country: "Poland",
      value: "OSZ",
      display: "OSZ"
    },
    {
      name: "Rocky Mount Wilson Regional Airport",
      city: "Rocky Mount",
      country: "United States",
      value: "RWI",
      display: "RWI"
    },
    {
      name: "Soldotna Airport",
      city: "Soldotna",
      country: "United States",
      value: "SXQ",
      display: "SXQ"
    },
    {
      name: "Gillespie Field",
      city: "El Cajon",
      country: "United States",
      value: "SEE",
      display: "SEE"
    },
    {
      name: "Phan Rang Airport",
      city: "Phan Rang",
      country: "Vietnam",
      value: "PHA",
      display: "PHA"
    },
    {
      name: "Na-San Airport",
      city: "Son-La",
      country: "Vietnam",
      value: "SQH",
      display: "SQH"
    },
    {
      name: "Truckee Tahoe Airport",
      city: "Truckee",
      country: "United States",
      value: "TKF",
      display: "TKF"
    },
    {
      name: "Frejus Airport",
      city: "Frejus",
      country: "France",
      value: "FRJ",
      display: "FRJ"
    },
    {
      name: "Geelong Airport",
      city: "Geelong",
      country: "Australia",
      value: "GEX",
      display: "GEX"
    },
    {
      name: "Cobb County-Mc Collum Field",
      city: "Atlanta",
      country: "United States",
      value: "RYY",
      display: "RYY"
    },
    {
      name: "Dell Flight Strip",
      city: "Dell",
      country: "United States",
      value: "4U9",
      display: "4U9"
    },
    {
      name: "Mission Field",
      city: "Livingston-Montana",
      country: "United States",
      value: "LVM",
      display: "LVM"
    },
    {
      name: "Big Timber Airport",
      city: "Big Timber",
      country: "United States",
      value: "6S0",
      display: "6S0"
    },
    {
      name: "West Michigan Regional Airport",
      city: "Holland",
      country: "United States",
      value: "BIV",
      display: "BIV"
    },
    {
      name: "Hernesaari Heliport",
      city: "Helsinki",
      country: "Finland",
      value: "HEN",
      display: "HEN"
    },
    {
      name: "Lakeland Linder Regional Airport",
      city: "Lakeland",
      country: "United States",
      value: "LAL",
      display: "LAL"
    },
    {
      name: "Syangboche Airport",
      city: "Syangboche",
      country: "Nepal",
      value: "SYH",
      display: "SYH"
    },
    {
      name: "Indianola Municipal Airport",
      city: "New York",
      country: "United States",
      value: "IDL",
      display: "IDL"
    },
    {
      name: "French Valley Airport",
      city: "Murrieta-Temecula",
      country: "United States",
      value: "RBK",
      display: "RBK"
    },
    {
      name: "Oristano-Fenosu Airport",
      city: "Oristano",
      country: "Italy",
      value: "FNU",
      display: "FNU"
    },
    {
      name: "Mysore Airport",
      city: "Mysore",
      country: "India",
      value: "MYQ",
      display: "MYQ"
    },
    {
      name: "Carl R Keller Field",
      city: "Port Clinton",
      country: "United States",
      value: "PCW",
      display: "PCW"
    },
    {
      name: "Dayton-Wright Brothers Airport",
      city: "Dayton",
      country: "United States",
      value: "MGY",
      display: "MGY"
    },
    {
      name: "Richmond Municipal Airport",
      city: "Richmond",
      country: "United States",
      value: "RID",
      display: "RID"
    },
    {
      name: "Findlay Airport",
      city: "Findley",
      country: "United States",
      value: "FDY",
      display: "FDY"
    },
    {
      name: "Penneshaw Airport",
      city: "Penneshaw",
      country: "Australia",
      value: "PEA",
      display: "PEA"
    },
    {
      name: "Engels heliport",
      city: "Ebenhofen",
      country: "Germany",
      value: "EBE",
      display: "EBE"
    },
    {
      name: "Emporia Municipal Airport",
      city: "Kempten",
      country: "Germany",
      value: "EMP",
      display: "EMP"
    },
    {
      name: "Ska-Edeby Airport",
      city: "Essen",
      country: "Germany",
      value: "ESX",
      display: "ESX"
    },
    {
      name: "Bembridge Airport",
      city: "Bembridge",
      country: "United Kingdom",
      value: "BBP",
      display: "BBP"
    },
    {
      name: "Black Hills Airport-Clyde Ice Field",
      city: "Spearfish-South Dakota",
      country: "United States",
      value: "SPF",
      display: "SPF"
    },
    {
      name: "Oksywie Military Air Base",
      city: "Gdynia",
      country: "Poland",
      value: "QYD",
      display: "QYD"
    },
    {
      name: "Olive Branch Airport",
      city: "Olive Branch",
      country: "United States",
      value: "OLV",
      display: "OLV"
    },
    {
      name: "Zonguldak Airport",
      city: "Zonguldak",
      country: "Turkey",
      value: "ONQ",
      display: "ONQ"
    },
    {
      name: "Rocky Mountain Metropolitan Airport",
      city: "Broomfield-CO",
      country: "United States",
      value: "BJC",
      display: "BJC"
    },
    {
      name: "Salem Municipal Airport/McNary Field",
      city: "Salem",
      country: "United States",
      value: "SLE",
      display: "SLE"
    },
    {
      name: "Tunica Municipal Airport",
      city: "Tunica",
      country: "United States",
      value: "UTM",
      display: "UTM"
    },
    {
      name: "Kasaba Bay Airport",
      city: "Kasaba Bay",
      country: "Zambia",
      value: "ZKB",
      display: "ZKB"
    },
    {
      name: "Hunt Field",
      city: "Lindau",
      country: "Germany",
      value: "LND",
      display: "LND"
    },
    {
      name: "Lawrence J Timmerman Airport",
      city: "Milwaukee",
      country: "United States",
      value: "MWC",
      display: "MWC"
    },
    {
      name: "Southern Wisconsin Regional Airport",
      city: "Janesville",
      country: "United States",
      value: "JVL",
      display: "JVL"
    },
    {
      name: "Arlington Municipal Airport",
      city: "Arlington",
      country: "United States",
      value: "GKY",
      display: "GKY"
    },
    {
      name: "Gwinnett County Briscoe Field",
      city: "Lawrenceville",
      country: "United States",
      value: "LZU",
      display: "LZU"
    },
    {
      name: "Bowling Green Warren County Regional Airport",
      city: "Bowling Green",
      country: "United States",
      value: "BWG",
      display: "BWG"
    },
    {
      name: "Richard Lloyd Jones Jr Airport",
      city: "Tulsa",
      country: "United States",
      value: "RVS",
      display: "RVS"
    },
    {
      name: "Al Minhad Air Base",
      city: "Minhad AB",
      country: "United Arab Emirates",
      value: "NHD",
      display: "NHD"
    },
    {
      name: "Kirovograd Airport",
      city: "Kirovograd",
      country: "Ukraine",
      value: "KGO",
      display: "KGO"
    },
    {
      name: "El Alamein International Airport",
      city: "Dabaa City",
      country: "Egypt",
      value: "DBB",
      display: "DBB"
    },
    {
      name: "Bryce Canyon Airport",
      city: "Bryce Canyon",
      country: "United States",
      value: "BCE",
      display: "BCE"
    },
    {
      name: "Chkalovskiy Airport",
      city: "Shchyolkovo",
      country: "Russia",
      value: "CKL",
      display: "CKL"
    },
    {
      name: "Tengchong Tuofeng Airport",
      city: "Tengchong",
      country: "China",
      value: "TCZ",
      display: "TCZ"
    },
    {
      name: "Belbek Airport",
      city: "Sevastopol",
      country: "Ukraine",
      value: "UKS",
      display: "UKS"
    },
    {
      name: "New Century Aircenter Airport",
      city: "Olathe",
      country: "United States",
      value: "JCI",
      display: "JCI"
    },
    {
      name: "Easton Newnam Field",
      city: "Easton",
      country: "United States",
      value: "ESN",
      display: "ESN"
    },
    {
      name: "Stafsberg Airport",
      city: "Hamar",
      country: "Norway",
      value: "HMR",
      display: "HMR"
    },
    {
      name: "Yuba County Airport",
      city: "Yuba City",
      country: "United States",
      value: "MYV",
      display: "MYV"
    },
    {
      name: "Halliburton Field",
      city: "Duncan",
      country: "United States",
      value: "DUC",
      display: "DUC"
    },
    {
      name: "Garner Field",
      city: "Uvalde",
      country: "United States",
      value: "UVA",
      display: "UVA"
    },
    {
      name: "Lewis University Airport",
      city: "Lockport",
      country: "United States",
      value: "LOT",
      display: "LOT"
    },
    {
      name: "Buchanan Field",
      city: "Concord",
      country: "United States",
      value: "CCR",
      display: "CCR"
    },
    {
      name: "Ocean Reef Club Airport",
      city: "Ocean Reef Club Airport",
      country: "United States",
      value: "OCA",
      display: "OCA"
    },
    {
      name: "Yushu Batang Airport",
      city: "Yushu",
      country: "China",
      value: "YUS",
      display: "YUS"
    },
    {
      name: "Oshawa Airport",
      city: "Oshawa",
      country: "Canada",
      value: "YOO",
      display: "YOO"
    },
    {
      name: "Lahr Airport",
      city: "Lahr",
      country: "Germany",
      value: "LHA",
      display: "LHA"
    },
    {
      name: "Springfield-Beckley Municipal Airport",
      city: "Springfield",
      country: "United States",
      value: "SGH",
      display: "SGH"
    },
    {
      name: "Fazenda Palmital Airport",
      city: "South Aari Atoll",
      country: "Maldives",
      value: "MSI",
      display: "MSI"
    },
    {
      name: "Herrera Airport",
      city: "Santo Domingo",
      country: "Dominican Republic",
      value: "HEX",
      display: "HEX"
    },
    {
      name: "Cooinda Airport",
      city: "Cooinda",
      country: "Australia",
      value: "CDA",
      display: "CDA"
    },
    {
      name: "Jabiru Airport",
      city: "Jabiru",
      country: "Australia",
      value: "JAB",
      display: "JAB"
    },
    {
      name: "Hastings Airport",
      city: "Freetown",
      country: "Sierra Leone",
      value: "HGS",
      display: "HGS"
    },
    {
      name: "Philip Billard Municipal Airport",
      city: "Topeka",
      country: "United States",
      value: "TOP",
      display: "TOP"
    },
    {
      name: "Ngari Gunsa Airport",
      city: "Shiquanhe",
      country: "China",
      value: "NGQ",
      display: "NGQ"
    },
    {
      name: "Cochstedt Airport",
      city: "Cochstedt",
      country: "Germany",
      value: "CSO",
      display: "CSO"
    },
    {
      name: "Collin County Regional At Mc Kinney Airport",
      city: "DALLAS",
      country: "United States",
      value: "TKI",
      display: "TKI"
    },
    {
      name: "Chicago Executive Airport",
      city: "Chicago-Wheeling",
      country: "United States",
      value: "PWK",
      display: "PWK"
    },
    {
      name: "Southwest Washington Regional Airport",
      city: "Kelso",
      country: "United States",
      value: "KLS",
      display: "KLS"
    },
    {
      name: "Tureia Airport",
      city: "Tureia",
      country: "French Polynesia",
      value: "ZTA",
      display: "ZTA"
    },
    {
      name: "Puerto Obaldia Airport",
      city: "Puerto Obaldia",
      country: "Panama",
      value: "PUE",
      display: "PUE"
    },
    {
      name: "Kerch Airport",
      city: "Kerch",
      country: "Ukraine",
      value: "KHC",
      display: "KHC"
    },
    {
      name: "Ukunda Airstrip",
      city: "Ukunda",
      country: "Kenya",
      value: "UKA",
      display: "UKA"
    },
    {
      name: "Wilmington Airpark",
      city: "Wilmington",
      country: "United States",
      value: "ILN",
      display: "ILN"
    },
    {
      name: "Marana Regional Airport",
      city: "Tucson",
      country: "United States",
      value: "AVW",
      display: "AVW"
    },
    {
      name: "Casa Grande Municipal Airport",
      city: "Casa Grande",
      country: "United States",
      value: "CGZ",
      display: "CGZ"
    },
    {
      name: "Buckeye Municipal Airport",
      city: "Buckeye",
      country: "United States",
      value: "BXK",
      display: "BXK"
    },
    {
      name: "Gila Bend Municipal Airport",
      city: "Gila Bend",
      country: "United States",
      value: "E63",
      display: "E63"
    },
    {
      name: "McMinn County Airport",
      city: "Athens",
      country: "United States",
      value: "MMI",
      display: "MMI"
    },
    {
      name: "Sterling Municipal Airport",
      city: "Sterling",
      country: "United States",
      value: "STK",
      display: "STK"
    },
    {
      name: "Rawlins Municipal Airport/Harvey Field",
      city: "Rawlins",
      country: "United States",
      value: "RWL",
      display: "RWL"
    },
    {
      name: "Mackenzie Airport",
      city: "Mackenzie British Columbia",
      country: "Canada",
      value: "YZY",
      display: "YZY"
    },
    {
      name: "Essex County Airport",
      city: "Caldwell",
      country: "United States",
      value: "CDW",
      display: "CDW"
    },
    {
      name: "Lee C Fine Memorial Airport",
      city: "Kaiser Lake Ozark",
      country: "United States",
      value: "AIZ",
      display: "AIZ"
    },
    {
      name: "Thomasville Regional Airport",
      city: "Thomasville",
      country: "United States",
      value: "TVI",
      display: "TVI"
    },
    {
      name: "Henderson Executive Airport",
      city: "Henderson",
      country: "United States",
      value: "HSH",
      display: "HSH"
    },
    {
      name: "Gostomel Airport",
      city: "Kiev",
      country: "Ukraine",
      value: "GML",
      display: "GML"
    },
    {
      name: "Henry Tift Myers Airport",
      city: "Tifton",
      country: "United States",
      value: "TMA",
      display: "TMA"
    },
    {
      name: "Radom Airport",
      city: "RADOM",
      country: "Poland",
      value: "QXR",
      display: "QXR"
    },
    {
      name: "Phoenix Deer Valley Airport",
      city: "Phoenix",
      country: "United States",
      value: "DVT",
      display: "DVT"
    },
    {
      name: "Golden Airport",
      city: "Golden",
      country: "Canada",
      value: "YGE",
      display: "YGE"
    },
    {
      name: "Revelstoke Airport",
      city: "Revelstoke",
      country: "Canada",
      value: "YRV",
      display: "YRV"
    },
    {
      name: "South Texas Regional Airport at Hondo",
      city: "Hondo",
      country: "United States",
      value: "HDO",
      display: "HDO"
    },
    {
      name: "Zhongwei Shapotou Airport",
      city: "Zhongwei",
      country: "China",
      value: "ZHY",
      display: "ZHY"
    },
    {
      name: "McKinley National Park Airport",
      city: "McKinley Park",
      country: "United States",
      value: "MCL",
      display: "MCL"
    },
    {
      name: "Lake Hood Seaplane Base",
      city: "Anchorage",
      country: "United States",
      value: "LHD",
      display: "LHD"
    },
    {
      name: "Prospect Creek Airport",
      city: "Prospect Creek",
      country: "United States",
      value: "PPC",
      display: "PPC"
    },
    {
      name: "Khwai River Lodge Airport",
      city: "Khwai River",
      country: "Botswana",
      value: "KHW",
      display: "KHW"
    },
    {
      name: "Taichung Airport",
      city: "Taichung",
      country: "Taiwan",
      value: "TXG",
      display: "TXG"
    },
    {
      name: "Wheeling Ohio County Airport",
      city: "Wheeling",
      country: "United States",
      value: "HLG",
      display: "HLG"
    },
    {
      name: "Fitzgerald Municipal Airport",
      city: "Fitzgerald",
      country: "United States",
      value: "FZG",
      display: "FZG"
    },
    {
      name: "Ye Airport",
      city: "Ye",
      country: "Burma",
      value: "XYE",
      display: "XYE"
    },
    {
      name: "Al Maktoum International Airport",
      city: "Dubai",
      country: "United Arab Emirates",
      value: "DWC",
      display: "DWC"
    },
    {
      name: "Aransas County Airport",
      city: "Rockport",
      country: "United States",
      value: "RKP",
      display: "RKP"
    },
    {
      name: "Megeve Airport",
      city: "Verdun",
      country: "France",
      value: "MVV",
      display: "MVV"
    },
    {
      name: "Meribel Altiport",
      city: "Ajaccio",
      country: "France",
      value: "MFX",
      display: "MFX"
    },
    {
      name: "Okaukuejo Airport",
      city: "Okaukuejo",
      country: "Namibia",
      value: "OKF",
      display: "OKF"
    },
    {
      name: "Mokuti Lodge Airport",
      city: "Mokuti Lodge",
      country: "Namibia",
      value: "OKU",
      display: "OKU"
    },
    {
      name: "St. Peter-Ording Airport",
      city: "Sankt Peter-Ording",
      country: "Germany",
      value: "PSH",
      display: "PSH"
    },
    {
      name: "Crisp County Cordele Airport",
      city: "Cordele",
      country: "United States",
      value: "CKF",
      display: "CKF"
    },
    {
      name: "Ormond Beach Municipal Airport",
      city: "Ormond Beach",
      country: "United States",
      value: "OMN",
      display: "OMN"
    },
    {
      name: "Portland Troutdale Airport",
      city: "Troutdale",
      country: "United States",
      value: "TTD",
      display: "TTD"
    },
    {
      name: "Portland Hillsboro Airport",
      city: "Hillsboro",
      country: "United States",
      value: "HIO",
      display: "HIO"
    },
    {
      name: "Khost Airport",
      city: "Khost",
      country: "Afghanistan",
      value: "KHT",
      display: "KHT"
    },
    {
      name: "Montgomery County Airpark",
      city: "Gaithersburg",
      country: "United States",
      value: "GAI",
      display: "GAI"
    },
    {
      name: "Sharana Airstrip",
      city: "Sharona",
      country: "Afghanistan",
      value: "AZ3",
      display: "AZ3"
    },
    {
      name: "Pembroke Airport",
      city: "Pembroke",
      country: "Canada",
      value: "YTA",
      display: "YTA"
    },
    {
      name: "Tsumeb Airport",
      city: "Tsumeb",
      country: "Namibia",
      value: "TSB",
      display: "TSB"
    },
    {
      name: "Suffield Heliport",
      city: "Suffield",
      country: "Canada",
      value: "YSD",
      display: "YSD"
    },
    {
      name: "Blumenau Airport",
      city: "BLUMENAU",
      country: "Brazil",
      value: "BNU",
      display: "BNU"
    },
    {
      name: "Charlevoix Municipal Airport",
      city: "Charelvoix",
      country: "United States",
      value: "CVX",
      display: "CVX"
    },
    {
      name: "Cornwall Regional Airport",
      city: "Cornwall",
      country: "Canada",
      value: "YCC",
      display: "YCC"
    },
    {
      name: "Zona da Mata Regional Airport",
      city: "Juiz de Fora",
      country: "Brazil",
      value: "IZA",
      display: "IZA"
    },
    {
      name: "Flagler County Airport",
      city: "Flagler",
      country: "United States",
      value: "XFL",
      display: "XFL"
    },
    {
      name: "Morrisville Stowe State Airport",
      city: "Morrisville",
      country: "United States",
      value: "MVL",
      display: "MVL"
    },
    {
      name: "Dallas Executive Airport",
      city: "Dallas",
      country: "United States",
      value: "RBD",
      display: "RBD"
    },
    {
      name: "Westerly State Airport",
      city: "Washington County",
      country: "United States",
      value: "WST",
      display: "WST"
    },
    {
      name: "Block Island State Airport",
      city: "Block Island",
      country: "United States",
      value: "BID",
      display: "BID"
    },
    {
      name: "Nightmute Airport",
      city: "Nightmute",
      country: "United States",
      value: "NME",
      display: "NME"
    },
    {
      name: "Toksook Bay Airport",
      city: "Toksook Bay",
      country: "United States",
      value: "OOK",
      display: "OOK"
    },
    {
      name: "Ittoqqortoormiit Heliport",
      city: "Ittoqqortoormiit",
      country: "Greenland",
      value: "OBY",
      display: "OBY"
    },
    {
      name: "Vinnytsia/Gavyryshivka Airport",
      city: "Vinnitsa",
      country: "Ukraine",
      value: "VIN",
      display: "VIN"
    },
    {
      name: "Decatur County Industrial Air Park",
      city: "Bainbridge",
      country: "United States",
      value: "BGE",
      display: "BGE"
    },
    {
      name: "Kegaska Airport",
      city: "Kegaska",
      country: "Canada",
      value: "ZKG",
      display: "ZKG"
    },
    {
      name: "Black Tickle Airport",
      city: "Black Tickle",
      country: "Canada",
      value: "YBI",
      display: "YBI"
    },
    {
      name: "Silver Springs Airport",
      city: "Silver Springs",
      country: "United States",
      value: "SPZ",
      display: "SPZ"
    },
    {
      name: "Whiteman Airport",
      city: "Los Angeles",
      country: "United States",
      value: "WHP",
      display: "WHP"
    },
    {
      name: "Madera Municipal Airport",
      city: "Madera",
      country: "United States",
      value: "MAE",
      display: "MAE"
    },
    {
      name: "Trail Airport",
      city: "Trail",
      country: "Canada",
      value: "YZZ",
      display: "YZZ"
    },
    {
      name: "Old Arctic Bay Airport",
      city: "Arctic Bay",
      country: "Canada",
      value: "YAB",
      display: "YAB"
    },
    {
      name: "Birchwood Airport",
      city: "Belmopan",
      country: "Belize",
      value: "BCV",
      display: "BCV"
    },
    {
      name: "Maripasoula Airport",
      city: "Maripasoula",
      country: "French Guiana",
      value: "MPY",
      display: "MPY"
    },
    {
      name: "Saint-Laurent-du-Maroni Airport",
      city: "Saint-Laurent-du-Maroni",
      country: "French Guiana",
      value: "LDX",
      display: "LDX"
    },
    {
      name: "Kanas Airport",
      city: "Burqin",
      country: "China",
      value: "KJI",
      display: "KJI"
    },
    {
      name: "Capurgana Airport",
      city: "Capurgana",
      country: "Colombia",
      value: "CPB",
      display: "CPB"
    },
    {
      name: "Sohag International Airport",
      city: "Sohag",
      country: "Egypt",
      value: "HMB",
      display: "HMB"
    },
    {
      name: "Presidente General Don Oscar D. Gestido International Airport",
      city: "Rivera",
      country: "Uruguay",
      value: "RVY",
      display: "RVY"
    },
    {
      name: "Patos de Minas Airport",
      city: "Patos de Minas",
      country: "Brazil",
      value: "POJ",
      display: "POJ"
    },
    {
      name: "Bauru - Arealva Airport",
      city: "Bauru",
      country: "Brazil",
      value: "JTC",
      display: "JTC"
    },
    {
      name: "Ourilandia do Norte Airport",
      city: "Ourilandia do Norte",
      country: "Brazil",
      value: "OIA",
      display: "OIA"
    },
    {
      name: "Redencao Airport",
      city: "Redencao",
      country: "Brazil",
      value: "RDC",
      display: "RDC"
    },
    {
      name: "Sao Felix do Xingu Airport",
      city: "Sao Felix do Xingu",
      country: "Brazil",
      value: "SXX",
      display: "SXX"
    },
    {
      name: "Bonito Airport",
      city: "Bointo",
      country: "Brazil",
      value: "BYO",
      display: "BYO"
    },
    {
      name: "Sao Felix do Araguaia Airport",
      city: "Sao Felix do Araguaia",
      country: "Brazil",
      value: "SXO",
      display: "SXO"
    },
    {
      name: "Cacador Airport",
      city: "Cacador",
      country: "Brazil",
      value: "CFC",
      display: "CFC"
    },
    {
      name: "Carauari Airport",
      city: "Carauari",
      country: "Brazil",
      value: "CAF",
      display: "CAF"
    },
    {
      name: "Eirunepe Airport",
      city: "Eirunepe",
      country: "Brazil",
      value: "ERN",
      display: "ERN"
    },
    {
      name: "Concordia Airport",
      city: "Concordia",
      country: "Brazil",
      value: "CCI",
      display: "CCI"
    },
    {
      name: "Francisco Beltrao Airport",
      city: "Francisco Beltrao",
      country: "Brazil",
      value: "FBE",
      display: "FBE"
    },
    {
      name: "Confresa Airport",
      city: "Confresa",
      country: "Brazil",
      value: "CFO",
      display: "CFO"
    },
    {
      name: "Apalachicola Regional Airport",
      city: "Apalachicola",
      country: "United States",
      value: "AAF",
      display: "AAF"
    },
    {
      name: "Umuarama Airport",
      city: "Umuarama",
      country: "Brazil",
      value: "UMU",
      display: "UMU"
    },
    {
      name: "Diamantina Airport",
      city: "Diamantina",
      country: "Brazil",
      value: "DTI",
      display: "DTI"
    },
    {
      name: "Fonte Boa Airport",
      city: "Fonte Boa",
      country: "Brazil",
      value: "FBA",
      display: "FBA"
    },
    {
      name: "Senadora Eunice Micheles Airport",
      city: "Sao Paulo de Olivenca",
      country: "Brazil",
      value: "OLC",
      display: "OLC"
    },
    {
      name: "Humaita Airport",
      city: "Humaita",
      country: "Brazil",
      value: "HUW",
      display: "HUW"
    },
    {
      name: "Tapuruquara Airport",
      city: "Santa Isabel do Rio Negro",
      country: "Brazil",
      value: "IRZ",
      display: "IRZ"
    },
    {
      name: "Oriximina Airport",
      city: "Oriximina",
      country: "Brazil",
      value: "ORX",
      display: "ORX"
    },
    {
      name: "Hotel Transamerica Airport",
      city: "Una",
      country: "Brazil",
      value: "UNA",
      display: "UNA"
    },
    {
      name: "Telfer Airport",
      city: "Telfer",
      country: "Australia",
      value: "TEF",
      display: "TEF"
    },
    {
      name: "Gazipasa Airport",
      city: "Alanya",
      country: "Turkey",
      value: "GZP",
      display: "GZP"
    },
    {
      name: "Douglas Municipal Airport",
      city: "Douglas",
      country: "United States",
      value: "DQH",
      display: "DQH"
    },
    {
      name: "St Lucie County International Airport",
      city: "Fort Pierce",
      country: "United States",
      value: "FRP",
      display: "FRP"
    },
    {
      name: "Taunton Municipal King Field",
      city: "Taunton",
      country: "United States",
      value: "TAN",
      display: "TAN"
    },
    {
      name: "Plymouth Municipal Airport",
      city: "Plymouth",
      country: "United States",
      value: "PYM",
      display: "PYM"
    },
    {
      name: "Quonset State Airport",
      city: "North Kingstown",
      country: "United States",
      value: "OQU",
      display: "OQU"
    },
    {
      name: "Norwood Memorial Airport",
      city: "Norwood",
      country: "United States",
      value: "OWD",
      display: "OWD"
    },
    {
      name: "Barnes Municipal Airport",
      city: "Westfield",
      country: "United States",
      value: "BAF",
      display: "BAF"
    },
    {
      name: "Windham Airport",
      city: "Willimantic",
      country: "United States",
      value: "IJD",
      display: "IJD"
    },
    {
      name: "Orange County Airport",
      city: "Montgomery",
      country: "United States",
      value: "MGJ",
      display: "MGJ"
    },
    {
      name: "Capital City Airport",
      city: "Harrisburg",
      country: "United States",
      value: "CXY",
      display: "CXY"
    },
    {
      name: "Marshfield Municipal George Harlow Field",
      city: "Marshfield",
      country: "United States",
      value: "GHG",
      display: "GHG"
    },
    {
      name: "Danbury Municipal Airport",
      city: "Danbury",
      country: "United States",
      value: "DXR",
      display: "DXR"
    },
    {
      name: "Boire Field",
      city: "Nashua",
      country: "United States",
      value: "ASH",
      display: "ASH"
    },
    {
      name: "Lawrence Municipal Airport",
      city: "Lawrence",
      country: "United States",
      value: "LWM",
      display: "LWM"
    },
    {
      name: "Waterbury Oxford Airport",
      city: "Oxford",
      country: "United States",
      value: "OXC",
      display: "OXC"
    },
    {
      name: "Fitchburg Municipal Airport",
      city: "Fitchburg",
      country: "United States",
      value: "FIT",
      display: "FIT"
    },
    {
      name: "Cartersville Airport",
      city: "Cartersville",
      country: "United States",
      value: "VPC",
      display: "VPC"
    },
    {
      name: "Centre-Piedmont-Cherokee County Regional Airport",
      city: "Centre",
      country: "United States",
      value: "PYP",
      display: "PYP"
    },
    {
      name: "Richard B Russell Airport",
      city: "Rome",
      country: "United States",
      value: "RMG",
      display: "RMG"
    },
    {
      name: "Northeast Alabama Regional Airport",
      city: "Gadsden",
      country: "United States",
      value: "GAD",
      display: "GAD"
    },
    {
      name: "Knoxville Downtown Island Airport",
      city: "Knoxville",
      country: "United States",
      value: "DKX",
      display: "DKX"
    },
    {
      name: "Barrow County Airport",
      city: "Winder",
      country: "United States",
      value: "WDR",
      display: "WDR"
    },
    {
      name: "Plantation Airpark",
      city: "Sylvania",
      country: "United States",
      value: "JYL",
      display: "JYL"
    },
    {
      name: "Dalton Municipal Airport",
      city: "Dalton",
      country: "United States",
      value: "DNN",
      display: "DNN"
    },
    {
      name: "West Georgia Regional O V Gray Field",
      city: "Carrollton",
      country: "United States",
      value: "CTJ",
      display: "CTJ"
    },
    {
      name: "Lagrange Callaway Airport",
      city: "LaGrange",
      country: "United States",
      value: "LGC",
      display: "LGC"
    },
    {
      name: "Baldwin County Airport",
      city: "Milledgeville",
      country: "United States",
      value: "MLJ",
      display: "MLJ"
    },
    {
      name: "Harris County Airport",
      city: "Pine Mountain",
      country: "United States",
      value: "PIM",
      display: "PIM"
    },
    {
      name: "Peachtree City Falcon Field",
      city: "Atlanta",
      country: "United States",
      value: "FFC",
      display: "FFC"
    },
    {
      name: "Lee Gilmer Memorial Airport",
      city: "Gainesville",
      country: "United States",
      value: "GVL",
      display: "GVL"
    },
    {
      name: "Harry Clever Field",
      city: "New Philadelpha",
      country: "United States",
      value: "PHD",
      display: "PHD"
    },
    {
      name: "Darlington County Jetport Airport",
      city: "Darlington",
      country: "United States",
      value: "UDG",
      display: "UDG"
    },
    {
      name: "Hilton Head Airport",
      city: "Hilton Head Island",
      country: "United States",
      value: "HHH",
      display: "HHH"
    },
    {
      name: "Daniel Field",
      city: "Augusta",
      country: "United States",
      value: "DNL",
      display: "DNL"
    },
    {
      name: "Foothills Regional Airport",
      city: "Morganton",
      country: "United States",
      value: "MRN",
      display: "MRN"
    },
    {
      name: "Pike County-Hatcher Field",
      city: "Pikeville",
      country: "United States",
      value: "PBX",
      display: "PBX"
    },
    {
      name: "Toccoa Airport - R.G. Letourneau Field",
      city: "Toccoa",
      country: "United States",
      value: "TOC",
      display: "TOC"
    },
    {
      name: "Suprunovka Airport",
      city: "Poltava",
      country: "Ukraine",
      value: "PLV",
      display: "PLV"
    },
    {
      name: "Wau Airport",
      city: "Wau",
      country: "Sudan",
      value: "WUU",
      display: "WUU"
    },
    {
      name: "Humera Airport",
      city: "Humera",
      country: "Ethiopia",
      value: "HUE",
      display: "HUE"
    },
    {
      name: "Moyale Airport",
      city: "Moyale",
      country: "Kenya",
      value: "OYL",
      display: "OYL"
    },
    {
      name: "Yengema Airport",
      city: "Yengema",
      country: "Sierra Leone",
      value: "WYE",
      display: "WYE"
    },
    {
      name: "Gbangbatok Airport",
      city: "Gbangbatok",
      country: "Sierra Leone",
      value: "GBK",
      display: "GBK"
    },
    {
      name: "Fort Worth Alliance Airport",
      city: "Fort Worth",
      country: "United States",
      value: "AFW",
      display: "AFW"
    },
    {
      name: "East Troy Municipal Airport",
      city: "East Troy",
      country: "United States",
      value: "57C",
      display: "57C"
    },
    {
      name: "Renmark Airport",
      city: "Renmark",
      country: "Australia",
      value: "RMK",
      display: "RMK"
    },
    {
      name: "Leigh Creek Airport",
      city: "Leigh Creek",
      country: "Australia",
      value: "LGH",
      display: "LGH"
    },
    {
      name: "Rottnest Island Airport",
      city: "Rottnest Island",
      country: "Australia",
      value: "RTS",
      display: "RTS"
    },
    {
      name: "Keewaywin Airport",
      city: "Keewaywin",
      country: "Canada",
      value: "KEW",
      display: "KEW"
    },
    {
      name: "Marathon Airport",
      city: "Marathon",
      country: "Canada",
      value: "YSP",
      display: "YSP"
    },
    {
      name: "Hearst Rene Fontaine Municipal Airport",
      city: "Hearst",
      country: "Canada",
      value: "YHF",
      display: "YHF"
    },
    {
      name: "Hornepayne Municipal Airport",
      city: "Hornepayne",
      country: "Canada",
      value: "YHN",
      display: "YHN"
    },
    {
      name: "Kirkland Lake Airport",
      city: "Kirkland Lake",
      country: "Canada",
      value: "YKX",
      display: "YKX"
    },
    {
      name: "Manitouwadge Airport",
      city: "Manitouwadge",
      country: "Canada",
      value: "YMG",
      display: "YMG"
    },
    {
      name: "Wawa Airport",
      city: "Wawa",
      country: "Canada",
      value: "YXZ",
      display: "YXZ"
    },
    {
      name: "Manitoulin East Municipal Airport",
      city: "Manitowaning",
      country: "Canada",
      value: "YEM",
      display: "YEM"
    },
    {
      name: "Brantford Municipal Airport",
      city: "Brantford",
      country: "Canada",
      value: "YFD",
      display: "YFD"
    },
    {
      name: "Lawrence Municipal Airport",
      city: "Lawrence",
      country: "United States",
      value: "LWC",
      display: "LWC"
    },
    {
      name: "Wellington Municipal Airport",
      city: "Wellington",
      country: "United States",
      value: "EGT",
      display: "EGT"
    },
    {
      name: "Pompano Beach Airpark",
      city: "Pompano Beach",
      country: "United States",
      value: "PMP",
      display: "PMP"
    },
    {
      name: "Mallacoota Airport",
      city: "Mallacoota",
      country: "Australia",
      value: "XMC",
      display: "XMC"
    },
    {
      name: "Shelby County Airport",
      city: "Alabaster",
      country: "United States",
      value: "EET",
      display: "EET"
    },
    {
      name: "Yuendumu Airport",
      city: "Yuendumu",
      country: "Australia",
      value: "YUE",
      display: "YUE"
    },
    {
      name: "Lombok International Airport",
      city: "Praya",
      country: "Indonesia",
      value: "LOP",
      display: "LOP"
    },
    {
      name: "South Cariboo Region / 108 Mile Airport",
      city: "108 Mile Ranch",
      country: "Canada",
      value: "ZML",
      display: "ZML"
    },
    {
      name: "Handan Airport",
      city: "Handan",
      country: "China",
      value: "HDG",
      display: "HDG"
    },
    {
      name: "Indianapolis Metropolitan Airport",
      city: "Indianapolis",
      country: "United States",
      value: "UMP",
      display: "UMP"
    },
    {
      name: "London-Corbin Airport/Magee Field",
      city: "London",
      country: "United States",
      value: "LOZ",
      display: "LOZ"
    },
    {
      name: "Simmons Army Air Field",
      city: "Fredericksburg",
      country: "United States",
      value: "FBG",
      display: "FBG"
    },
    {
      name: "Modlin Airport",
      city: "Warsaw",
      country: "Poland",
      value: "WMI",
      display: "WMI"
    },
    {
      name: "Jixi Xingkaihu Airport",
      city: "Jixi",
      country: "China",
      value: "JXA",
      display: "JXA"
    },
    {
      name: "Gimli Industrial Park Airport",
      city: "Gimli",
      country: "Canada",
      value: "YGM",
      display: "YGM"
    },
    {
      name: "Beloyarskiy Airport",
      city: "Beloyarsky",
      country: "Russia",
      value: "EYK",
      display: "EYK"
    },
    {
      name: "John H Batten Airport",
      city: "Racine",
      country: "United States",
      value: "RAC",
      display: "RAC"
    },
    {
      name: "Cesar Lim Rodriguez Airport",
      city: "Taytay",
      country: "Philippines",
      value: "RZP",
      display: "RZP"
    },
    {
      name: "Redlands Municipal Airport",
      city: "Redlands",
      country: "United States",
      value: "REI",
      display: "REI"
    },
    {
      name: "Flabob Airport",
      city: "Riverside",
      country: "United States",
      value: "RIR",
      display: "RIR"
    },
    {
      name: "Tacoma Narrows Airport",
      city: "Tacoma",
      country: "United States",
      value: "TIW",
      display: "TIW"
    },
    {
      name: "Jack Edwards Airport",
      city: "Gulf Shores",
      country: "United States",
      value: "JKA",
      display: "JKA"
    },
    {
      name: "Khmelnytskyi Airport",
      city: "Khmeinitskiy",
      country: "Ukraine",
      value: "HMJ",
      display: "HMJ"
    },
    {
      name: "Hiroshimanishi Airport",
      city: "Hiroshima",
      country: "Japan",
      value: "HIW",
      display: "HIW"
    },
    {
      name: "Hazleton Municipal Airport",
      city: "Hazleton",
      country: "United States",
      value: "HZL",
      display: "HZL"
    },
    {
      name: "Greater Cumberland Regional Airport",
      city: "Cumberland",
      country: "United States",
      value: "CBE",
      display: "CBE"
    },
    {
      name: "Bob Quinn Lake Airport",
      city: "Bob Quinn Lake",
      country: "Canada",
      value: "YBO",
      display: "YBO"
    },
    {
      name: "Grabtsevo Airport",
      city: "Kaluga",
      country: "Russia",
      value: "KLF",
      display: "KLF"
    },
    {
      name: "Tri-County Regional Airport",
      city: "Lone Rock",
      country: "United States",
      value: "LNR",
      display: "LNR"
    },
    {
      name: "Joliet Regional Airport",
      city: "Joliet",
      country: "United States",
      value: "JOT",
      display: "JOT"
    },
    {
      name: "Illinois Valley Regional Airport-Walter A Duncan Field",
      city: "Peru",
      country: "United States",
      value: "VYS",
      display: "VYS"
    },
    {
      name: "Jackson County Reynolds Field",
      city: "Jackson",
      country: "United States",
      value: "JXN",
      display: "JXN"
    },
    {
      name: "Wings Field",
      city: "Philadelphia",
      country: "United States",
      value: "BBX",
      display: "BBX"
    },
    {
      name: "Okeechobee County Airport",
      city: "Okeechobee",
      country: "United States",
      value: "OBE",
      display: "OBE"
    },
    {
      name: "Sebring Regional Airport",
      city: "Sebring",
      country: "United States",
      value: "SEF",
      display: "SEF"
    },
    {
      name: "Avon Park Executive Airport",
      city: "Avon Park",
      country: "United States",
      value: "AVO",
      display: "AVO"
    },
    {
      name: "Winter Haven Municipal Airport - Gilbert Field",
      city: "Winter Haven",
      country: "United States",
      value: "GIF",
      display: "GIF"
    },
    {
      name: "Zephyrhills Municipal Airport",
      city: "Zephyrhills",
      country: "United States",
      value: "ZPH",
      display: "ZPH"
    },
    {
      name: "Ocala International Airport - Jim Taylor Field",
      city: "Ocala",
      country: "United States",
      value: "OCF",
      display: "OCF"
    },
    {
      name: "Jesup Wayne County Airport",
      city: "Jesup",
      country: "United States",
      value: "JES",
      display: "JES"
    },
    {
      name: "Madison Municipal Airport",
      city: "Madison",
      country: "United States",
      value: "52A",
      display: "52A"
    },
    {
      name: "Newnan Coweta County Airport",
      city: "Newnan",
      country: "United States",
      value: "CCO",
      display: "CCO"
    },
    {
      name: "Thomson-McDuffie County Airport",
      city: "Thomson",
      country: "United States",
      value: "HQU",
      display: "HQU"
    },
    {
      name: "Aiken Municipal Airport",
      city: "Aiken",
      country: "United States",
      value: "AIK",
      display: "AIK"
    },
    {
      name: "Woodward Field",
      city: "Camden",
      country: "United States",
      value: "CDN",
      display: "CDN"
    },
    {
      name: "Lumberton Regional Airport",
      city: "Lumberton",
      country: "United States",
      value: "LBT",
      display: "LBT"
    },
    {
      name: "Moore County Airport",
      city: "Pinehurst-Southern Pines",
      country: "United States",
      value: "SOP",
      display: "SOP"
    },
    {
      name: "Richmond County Airport",
      city: "Rockingham",
      country: "United States",
      value: "RCZ",
      display: "RCZ"
    },
    {
      name: "Baraboo Wisconsin Dells Airport",
      city: "Baraboo",
      country: "United States",
      value: "DLL",
      display: "DLL"
    },
    {
      name: "Statesville Regional Airport",
      city: "Statesville",
      country: "United States",
      value: "SVH",
      display: "SVH"
    },
    {
      name: "Burlington Municipal Airport",
      city: "Burlington",
      country: "United States",
      value: "BUU",
      display: "BUU"
    },
    {
      name: "William T. Piper Memorial Airport",
      city: "Lock Haven",
      country: "United States",
      value: "LHV",
      display: "LHV"
    },
    {
      name: "Lorain County Regional Airport",
      city: "Lorain-Elyria",
      country: "United States",
      value: "LPR",
      display: "LPR"
    },
    {
      name: "Burke Lakefront Airport",
      city: "Cleveland",
      country: "United States",
      value: "BKL",
      display: "BKL"
    },
    {
      name: "Chautauqua County-Dunkirk Airport",
      city: "Dunkirk",
      country: "United States",
      value: "DKK",
      display: "DKK"
    },
    {
      name: "South Jersey Regional Airport",
      city: "Mount Holly",
      country: "United States",
      value: "VAY",
      display: "VAY"
    },
    {
      name: "Linden Airport",
      city: "Linden",
      country: "United States",
      value: "LDJ",
      display: "LDJ"
    },
    {
      name: "Tri State Steuben County Airport",
      city: "Angola",
      country: "United States",
      value: "ANQ",
      display: "ANQ"
    },
    {
      name: "Van Wert County Airport",
      city: "Van Wert",
      country: "United States",
      value: "VNW",
      display: "VNW"
    },
    {
      name: "Genesee County Airport",
      city: "Batavia",
      country: "United States",
      value: "GVQ",
      display: "GVQ"
    },
    {
      name: "Clearwater Air Park",
      city: "Clearwater",
      country: "United States",
      value: "CLW",
      display: "CLW"
    },
    {
      name: "Chicago Meigs Airport",
      city: "Chicago",
      country: "United States",
      value: "CGX",
      display: "CGX"
    },
    {
      name: "Pickens County Airport",
      city: "Jasper",
      country: "United States",
      value: "JZP",
      display: "JZP"
    },
    {
      name: "Grand Strand Airport",
      city: "North Myrtle Beach",
      country: "United States",
      value: "CRE",
      display: "CRE"
    },
    {
      name: "Lansing Municipal Airport",
      city: "Lansing",
      country: "United States",
      value: "IGQ",
      display: "IGQ"
    },
    {
      name: "Ramona Airport",
      city: "Ramona",
      country: "United States",
      value: "RNM",
      display: "RNM"
    },
    {
      name: "Buochs Airport",
      city: "Buochs",
      country: "Switzerland",
      value: "BXO",
      display: "BXO"
    },
    {
      name: "Branch County Memorial Airport",
      city: "Coldwater",
      country: "United States",
      value: "OEB",
      display: "OEB"
    },
    {
      name: "Wilkes Barre Wyoming Valley Airport",
      city: "Wilkes-Barre",
      country: "United States",
      value: "WBW",
      display: "WBW"
    },
    {
      name: "Willoughby Lost Nation Municipal Airport",
      city: "Willoughby",
      country: "United States",
      value: "LNN",
      display: "LNN"
    },
    {
      name: "Uummannaq Heliport",
      city: "Uummannaq",
      country: "Greenland",
      value: "UMD",
      display: "UMD"
    },
    {
      name: "Bayannur Tianjitai Airport",
      city: "Bayannur",
      country: "China",
      value: "RLK",
      display: "RLK"
    },
    {
      name: "Capital City Airport",
      city: "Frankfort",
      country: "United States",
      value: "FFT",
      display: "FFT"
    },
    {
      name: "Auburn Lewiston Municipal Airport",
      city: "Lewiston",
      country: "United States",
      value: "LEW",
      display: "LEW"
    },
    {
      name: "Marco Island Airport",
      city: "Marco Island Airport",
      country: "United States",
      value: "MRK",
      display: "MRK"
    },
    {
      name: "Drummond Island Airport",
      city: "Drummond Island",
      country: "United States",
      value: "DRM",
      display: "DRM"
    },
    {
      name: "Gladwin Zettel Memorial Airport",
      city: "Gladwin",
      country: "United States",
      value: "GDW",
      display: "GDW"
    },
    {
      name: "South Haven Area Regional Airport",
      city: "South Haven",
      country: "United States",
      value: "LWA",
      display: "LWA"
    },
    {
      name: "Marshfield Municipal Airport",
      city: "Marshfield",
      country: "United States",
      value: "MFI",
      display: "MFI"
    },
    {
      name: "Alexander Field South Wood County Airport",
      city: "Wisconsin Rapids",
      country: "United States",
      value: "ISW",
      display: "ISW"
    },
    {
      name: "Clinton Municipal Airport",
      city: "Clinton",
      country: "United States",
      value: "CWI",
      display: "CWI"
    },
    {
      name: "Beverly Municipal Airport",
      city: "Beverly",
      country: "United States",
      value: "BVY",
      display: "BVY"
    },
    {
      name: "Trois-Rivieres Airport",
      city: "Trois Rivieres",
      country: "Canada",
      value: "YRQ",
      display: "YRQ"
    },
    {
      name: "Poplar Bluff Municipal Airport",
      city: "Poplar Bluff",
      country: "United States",
      value: "POF",
      display: "POF"
    },
    {
      name: "Eastport Municipal Airport",
      city: "Eastport",
      country: "United States",
      value: "EPM",
      display: "EPM"
    },
    {
      name: "Keokuk Municipal Airport",
      city: "Keokuk",
      country: "United States",
      value: "EOK",
      display: "EOK"
    },
    {
      name: "Perth/Scone Airport",
      city: "Perth",
      country: "United Kingdom",
      value: "PSL",
      display: "PSL"
    },
    {
      name: "St Paul Downtown Holman Field",
      city: "St. Paul",
      country: "United States",
      value: "STP",
      display: "STP"
    },
    {
      name: "Soderhamn Airport",
      city: "Soderhamn",
      country: "Sweden",
      value: "SOO",
      display: "SOO"
    },
    {
      name: "Saravane Airport",
      city: "Saravane",
      country: "Laos",
      value: "VNA",
      display: "VNA"
    },
    {
      name: "Dikson Airport",
      city: "Dikson",
      country: "Russia",
      value: "DKS",
      display: "DKS"
    },
    {
      name: "Bantry Aerodrome",
      city: "Bantry",
      country: "Ireland",
      value: "BYT",
      display: "BYT"
    },
    {
      name: "Alldays Airport",
      city: "Alldays",
      country: "South Africa",
      value: "ADY",
      display: "ADY"
    },
    {
      name: "Garissa Airport",
      city: "Garissa",
      country: "Kenya",
      value: "GAS",
      display: "GAS"
    },
    {
      name: "Hola Airport",
      city: "Hola",
      country: "Kenya",
      value: "HOA",
      display: "HOA"
    },
    {
      name: "Kericho Airport",
      city: "Kericho",
      country: "Kenya",
      value: "KEY",
      display: "KEY"
    },
    {
      name: "Kilaguni Airport",
      city: "Kilaguni",
      country: "Kenya",
      value: "ILU",
      display: "ILU"
    },
    {
      name: "Antsirabe Airport",
      city: "Antsirabe",
      country: "Madagascar",
      value: "ATJ",
      display: "ATJ"
    },
    {
      name: "Bekily Airport",
      city: "Bekily",
      country: "Madagascar",
      value: "OVA",
      display: "OVA"
    },
    {
      name: "Gorno-Altaysk Airport",
      city: "Gorno-Altaysk",
      country: "Russia",
      value: "RGK",
      display: "RGK"
    },
    {
      name: "Fond du Lac County Airport",
      city: "Fond du Lac",
      country: "United States",
      value: "FLD",
      display: "FLD"
    },
    {
      name: "Waupaca Municipal Airport",
      city: "Waupaca",
      country: "United States",
      value: "PCZ",
      display: "PCZ"
    },
    {
      name: "Stevens Point Municipal Airport",
      city: "Stevens Point",
      country: "United States",
      value: "STE",
      display: "STE"
    },
    {
      name: "Luce County Airport",
      city: "Newberry",
      country: "United States",
      value: "ERY",
      display: "ERY"
    },
    {
      name: "Peenemunde Airport",
      city: "Peenemunde",
      country: "Germany",
      value: "PEF",
      display: "PEF"
    },
    {
      name: "Galion Municipal Airport",
      city: "Galion",
      country: "United States",
      value: "GQQ",
      display: "GQQ"
    },
    {
      name: "Tiputini Airport",
      city: "Tiputini",
      country: "Ecuador",
      value: "TPN",
      display: "TPN"
    },
    {
      name: "Rio Amazonas Airport",
      city: "Pastaza",
      country: "Ecuador",
      value: "PTZ",
      display: "PTZ"
    },
    {
      name: "Clarksville-Montgomery County Regional Airport",
      city: "Clarksville",
      country: "United States",
      value: "CKV",
      display: "CKV"
    },
    {
      name: "Lompoc Airport",
      city: "Lompoc",
      country: "United States",
      value: "LPC",
      display: "LPC"
    },
    {
      name: "Chester County G O Carlson Airport",
      city: "Coatesville",
      country: "United States",
      value: "CTH",
      display: "CTH"
    },
    {
      name: "Bost Airport",
      city: "Lashkar Gah",
      country: "Afghanistan",
      value: "BST",
      display: "BST"
    },
    {
      name: "Lankaran International Airport",
      city: "Lankaran",
      country: "Azerbaijan",
      value: "LLK",
      display: "LLK"
    },
    {
      name: "Gabala International Airport",
      city: "Qabala",
      country: "Azerbaijan",
      value: "GBB",
      display: "GBB"
    },
    {
      name: "Zaqatala International Airport",
      city: "Zaqatala",
      country: "Azerbaijan",
      value: "ZTU",
      display: "ZTU"
    },
    {
      name: "Lake Placid Airport",
      city: "Lake Placid",
      country: "United States",
      value: "LKP",
      display: "LKP"
    },
    {
      name: "Cooma Hospital Helipad",
      city: "Yuzhno-Kurilsk",
      country: "Russia",
      value: "DEE",
      display: "DEE"
    },
    {
      name: "Lima Allen County Airport",
      city: "Lima",
      country: "United States",
      value: "AOH",
      display: "AOH"
    },
    {
      name: "Sondok Airport",
      city: "Hamhung",
      country: "North Korea",
      value: "DSO",
      display: "DSO"
    },
    {
      name: "Malcolm McKinnon Airport",
      city: "Brunswick",
      country: "United States",
      value: "SSI",
      display: "SSI"
    },
    {
      name: "Beaver County Airport",
      city: "Beaver Falls",
      country: "United States",
      value: "BFP",
      display: "BFP"
    },
    {
      name: "Georgetown County Airport",
      city: "Georgetown",
      country: "United States",
      value: "GGE",
      display: "GGE"
    },
    {
      name: "Hardwick Field",
      city: "Cleveland",
      country: "United States",
      value: "HDI",
      display: "HDI"
    },
    {
      name: "Renton Municipal Airport",
      city: "Renton",
      country: "United States",
      value: "RNT",
      display: "RNT"
    },
    {
      name: "Brackett Field",
      city: "La Verne",
      country: "United States",
      value: "POC",
      display: "POC"
    },
    {
      name: "Cross City Airport",
      city: "Cross City",
      country: "United States",
      value: "CTY",
      display: "CTY"
    },
    {
      name: "Oconee County Regional Airport",
      city: "Clemson",
      country: "United States",
      value: "CEU",
      display: "CEU"
    },
    {
      name: "Beech Factory Airport",
      city: "Wichita",
      country: "United States",
      value: "BEC",
      display: "BEC"
    },
    {
      name: "Tabing Airport",
      city: "Padang",
      country: "Indonesia",
      value: "PDG",
      display: "PDG"
    },
    {
      name: "Georgetown Municipal Airport",
      city: "Georgetown",
      country: "United States",
      value: "GTU",
      display: "GTU"
    },
    {
      name: "Duxford Airport",
      city: "Duxford",
      country: "United Kingdom",
      value: "QFO",
      display: "QFO"
    },
    {
      name: "Sidney Municipal-Lloyd W Carr Field",
      city: "Sidney",
      country: "United States",
      value: "SNY",
      display: "SNY"
    },
    {
      name: "Great Keppel Is Airport",
      city: "Great Keppel Island",
      country: "Australia",
      value: "GKL",
      display: "GKL"
    },
    {
      name: "Roper Bar Airport",
      city: "Roper Bar",
      country: "Australia",
      value: "RPB",
      display: "RPB"
    },
    {
      name: "Innisfail Airport",
      city: "Innisfail",
      country: "Australia",
      value: "IFL",
      display: "IFL"
    },
    {
      name: "Bamiyan Airport",
      city: "Bamyan",
      country: "Afghanistan",
      value: "BIN",
      display: "BIN"
    },
    {
      name: "Moomba Airport",
      city: "Moomba",
      country: "Australia",
      value: "MOO",
      display: "MOO"
    },
    {
      name: "Iosco County Airport",
      city: "East Tawas",
      country: "United States",
      value: "ECA",
      display: "ECA"
    },
    {
      name: "Leesburg Executive Airport",
      city: "Leesburg",
      country: "United States",
      value: "JYO",
      display: "JYO"
    },
    {
      name: "Villa Airport",
      city: "Maamigili",
      country: "Maldives",
      value: "VAM",
      display: "VAM"
    },
    {
      name: "Lingling Airport",
      city: "Yongzhou",
      country: "China",
      value: "LLF",
      display: "LLF"
    },
    {
      name: "Losinj Island Airport",
      city: "Mali Losinj",
      country: "Croatia",
      value: "LSZ",
      display: "LSZ"
    },
    {
      name: "Onslow Airport",
      city: "Onslow",
      country: "Australia",
      value: "ONS",
      display: "ONS"
    },
    {
      name: "Theodore Airport",
      city: "Theodore",
      country: "Australia",
      value: "TDR",
      display: "TDR"
    },
    {
      name: "Williamson Sodus Airport",
      city: "Williamson",
      country: "United States",
      value: "SDC",
      display: "SDC"
    },
    {
      name: "Boulder Municipal Airport",
      city: "Boulder",
      country: "United States",
      value: "WBU",
      display: "WBU"
    },
    {
      name: "Bitburg Airport",
      city: "Birburg",
      country: "Germany",
      value: "BBJ",
      display: "BBJ"
    },
    {
      name: "Palo Alto Airport of Santa Clara County",
      city: "Palo Alto",
      country: "United States",
      value: "PAO",
      display: "PAO"
    },
    {
      name: "Falcon Field",
      city: "Mesa",
      country: "United States",
      value: "FFZ",
      display: "FFZ"
    },
    {
      name: "Coolidge Municipal Airport",
      city: "Cooldige",
      country: "United States",
      value: "P08",
      display: "P08"
    },
    {
      name: "Cottonwood Airport",
      city: "Cottonwood",
      country: "United States",
      value: "P52",
      display: "P52"
    },
    {
      name: "Ak-Chin Regional Airport",
      city: "Phoenix",
      country: "United States",
      value: "A39",
      display: "A39"
    },
    {
      name: "Wickenburg Municipal Airport",
      city: "Wickenburg",
      country: "United States",
      value: "E25",
      display: "E25"
    },
    {
      name: "Yangzhou Taizhou Airport",
      city: "Yangzhou",
      country: "China",
      value: "YTY",
      display: "YTY"
    },
    {
      name: "Oakland County International Airport",
      city: "Pontiac",
      country: "United States",
      value: "PTK",
      display: "PTK"
    },
    {
      name: "Kissidougou Airport",
      city: "Kissidougou",
      country: "Guinea",
      value: "KSI",
      display: "KSI"
    },
    {
      name: "Dillant Hopkins Airport",
      city: "Keene",
      country: "United States",
      value: "EEN",
      display: "EEN"
    },
    {
      name: "Kooddoo Airport",
      city: "Kooddoo",
      country: "Maldives",
      value: "GKK",
      display: "GKK"
    },
    {
      name: "Rochester Airport",
      city: "Rochester",
      country: "United Kingdom",
      value: "RCS",
      display: "RCS"
    },
    {
      name: "Termas de Rio Hondo international Airport",
      city: "Rio Hondo",
      country: "Argentina",
      value: "RHD",
      display: "RHD"
    },
    {
      name: "Keetmanshoop Airport",
      city: "Keetmanshoop",
      country: "Namibia",
      value: "KMP",
      display: "KMP"
    },
    {
      name: "Iowa City Municipal Airport",
      city: "Iowa City",
      country: "United States",
      value: "IOW",
      display: "IOW"
    },
    {
      name: "Turpan Jiaohe Airport",
      city: "Turpan",
      country: "China",
      value: "TLQ",
      display: "TLQ"
    },
    {
      name: "Windom Municipal Airport",
      city: "Windom",
      country: "United States",
      value: "MWM",
      display: "MWM"
    },
    {
      name: "Lee Airport",
      city: "Annapolis",
      country: "United States",
      value: "ANP",
      display: "ANP"
    },
    {
      name: "Cuamba Airport",
      city: "Cuamba",
      country: "Mozambique",
      value: "FXO",
      display: "FXO"
    },
    {
      name: "Bodaybo Airport",
      city: "Bodaibo",
      country: "Russia",
      value: "ODO",
      display: "ODO"
    },
    {
      name: "Zhytomyr Airport",
      city: "Zhytomyr",
      country: "Ukraine",
      value: "ZTR",
      display: "ZTR"
    },
    {
      name: "Mattala Rajapaksa International Airport",
      city: "Mattala",
      country: "Sri Lanka",
      value: "HRI",
      display: "HRI"
    },
    {
      name: "Pecos Municipal Airport",
      city: "Pecos",
      country: "United States",
      value: "PEQ",
      display: "PEQ"
    },
    {
      name: "Hattiesburg Bobby L Chain Municipal Airport",
      city: "Hattiesburg",
      country: "United States",
      value: "HBG",
      display: "HBG"
    },
    {
      name: "Botucatu - Tancredo de Almeida Neves Airport",
      city: "Botucatu",
      country: "Brazil",
      value: "QCJ",
      display: "QCJ"
    },
    {
      name: "Sao Carlos Airport",
      city: "Sao Carlos",
      country: "Brazil",
      value: "QSC",
      display: "QSC"
    },
    {
      name: "Chan Gurney Municipal Airport",
      city: "Yankton",
      country: "United States",
      value: "YKN",
      display: "YKN"
    },
    {
      name: "Sir Bani Yas Airport",
      city: "Sir Bani Yas Island",
      country: "United Arab Emirates",
      value: "XSB",
      display: "XSB"
    },
    {
      name: "Bromont (Roland Desourdy) Airport",
      city: "Bromont",
      country: "Canada",
      value: "ZBM",
      display: "ZBM"
    },
    {
      name: "Kratie Airport",
      city: "Kratie",
      country: "Cambodia",
      value: "KTI",
      display: "KTI"
    },
    {
      name: "Guyuan Liupanshan Airport",
      city: "Guyuan",
      country: "China",
      value: "GYU",
      display: "GYU"
    },
    {
      name: "Changhai Airport",
      city: "Changhai",
      country: "China",
      value: "CNI",
      display: "CNI"
    },
    {
      name: "Redhill Aerodrome",
      city: "Redhill",
      country: "United Kingdom",
      value: "KRH",
      display: "KRH"
    },
    {
      name: "Chinchilla Airport",
      city: "Chinchilla",
      country: "Australia",
      value: "CCL",
      display: "CCL"
    },
    {
      name: "Hayward Executive Airport",
      city: "Hayward",
      country: "United States",
      value: "HWD",
      display: "HWD"
    },
    {
      name: "Motueka Airport",
      city: "Motueka",
      country: "New Zealand",
      value: "MZP",
      display: "MZP"
    },
    {
      name: "Shute Harbour Airport",
      city: "Shute Harbour",
      country: "Australia",
      value: "JHQ",
      display: "JHQ"
    },
    {
      name: "Ann Arbor Municipal Airport",
      city: "Ann Arbor",
      country: "United States",
      value: "ARB",
      display: "ARB"
    },
    {
      name: "Shepparton Airport",
      city: "Shepparton",
      country: "Australia",
      value: "SHT",
      display: "SHT"
    },
    {
      name: "Temora Airport",
      city: "Temora",
      country: "Australia",
      value: "TEM",
      display: "TEM"
    },
    {
      name: "Gayndah Airport",
      city: "Gayndah",
      country: "Australia",
      value: "GAH",
      display: "GAH"
    },
    {
      name: "Wilcannia Airport",
      city: "Wilcannia",
      country: "Australia",
      value: "WIO",
      display: "WIO"
    },
    {
      name: "Bijie Feixiong Airport",
      city: "Bijie",
      country: "China",
      value: "BFJ",
      display: "BFJ"
    },
    {
      name: "Lensk Airport",
      city: "Lensk",
      country: "Russia",
      value: "ULK",
      display: "ULK"
    },
    {
      name: "Sanl?urfa GAP Airport",
      city: "Sanliurfa",
      country: "Turkey",
      value: "GNY",
      display: "GNY"
    },
    {
      name: "Zafer Airport",
      city: "Kutahya",
      country: "Turkey",
      value: "KZR",
      display: "KZR"
    },
    {
      name: "Velikiye Luki Airport",
      city: "Velikiye Luki",
      country: "Russia",
      value: "VLU",
      display: "VLU"
    },
    {
      name: "Lake Macquarie Airport",
      city: "Lake Macquarie",
      country: "Australia",
      value: "BEO",
      display: "BEO"
    },
    {
      name: "Atlanta South Regional Airport/Tara Field",
      city: "Hampton",
      country: "United States",
      value: "4A7",
      display: "4A7"
    },
    {
      name: "Brampton Island Airport",
      city: "Brampton Island",
      country: "Australia",
      value: "BMP",
      display: "BMP"
    },
    {
      name: "Cochrane Airport",
      city: "Cochrane",
      country: "Canada",
      value: "YCN",
      display: "YCN"
    },
    {
      name: "Estadual Arthur Siqueira Airport",
      city: "Braganca Paulista",
      country: "Brazil",
      value: "BJP",
      display: "BJP"
    },
    {
      name: "Busselton Regional Airport",
      city: "Brusselton",
      country: "Australia",
      value: "BQB",
      display: "BQB"
    },
    {
      name: "Srednekolymsk Airport",
      city: "Srednekolymsk",
      country: "Russia",
      value: "SEK",
      display: "SEK"
    },
    {
      name: "Inverell Airport",
      city: "Inverell",
      country: "Australia",
      value: "IVR",
      display: "IVR"
    },
    {
      name: "Glen Innes Airport",
      city: "Glen Innes",
      country: "Australia",
      value: "GLI",
      display: "GLI"
    },
    {
      name: "Immokalee Regional Airport",
      city: "Immokalee",
      country: "United States",
      value: "IMM",
      display: "IMM"
    },
    {
      name: "Yichun Mingyueshan Airport",
      city: "Yichun",
      country: "China",
      value: "YIC",
      display: "YIC"
    },
    {
      name: "Dinwiddie County Airport",
      city: "Petersburg",
      country: "United States",
      value: "PTB",
      display: "PTB"
    },
    {
      name: "Kasongo Airport",
      city: "Kasongo",
      country: "Congo (Kinshasa)",
      value: "KGN",
      display: "KGN"
    },
    {
      name: "Sheboygan County Memorial Airport",
      city: "Sheboygan",
      country: "United States",
      value: "SBM",
      display: "SBM"
    },
    {
      name: "Fortescue - Dave Forrest Aerodrome",
      city: "Cloudbreak",
      country: "Australia",
      value: "KFE",
      display: "KFE"
    },
    {
      name: "Bajura Airport",
      city: "Bajura",
      country: "Nepal",
      value: "BJU",
      display: "BJU"
    },
    {
      name: "Pinal Airpark",
      city: "Marana",
      country: "United States",
      value: "MZJ",
      display: "MZJ"
    },
    {
      name: "Glendale Municipal Airport",
      city: "Glendale",
      country: "United States",
      value: "GEU",
      display: "GEU"
    },
    {
      name: "Safford Regional Airport",
      city: "Safford",
      country: "United States",
      value: "SAD",
      display: "SAD"
    },
    {
      name: "Kerama Airport",
      city: "Kerama",
      country: "Japan",
      value: "KJP",
      display: "KJP"
    },
    {
      name: "Sikeston Memorial Municipal Airport",
      city: "Sikeston",
      country: "United States",
      value: "SIK",
      display: "SIK"
    },
    {
      name: "Tetiaroa Airport",
      city: "Tetiaroa",
      country: "French Polynesia",
      value: "TTI",
      display: "TTI"
    },
    {
      name: "Floyd Bennett Memorial Airport",
      city: "Queensbury",
      country: "United States",
      value: "GFL",
      display: "GFL"
    },
    {
      name: "Saratoga County Airport",
      city: "Ballston Spa",
      country: "United States",
      value: "5B2",
      display: "5B2"
    },
    {
      name: "Crystal River Airport",
      city: "Crystal River",
      country: "United States",
      value: "CGC",
      display: "CGC"
    },
    {
      name: "Martin State Airport",
      city: "Baltimore",
      country: "United States",
      value: "MTN",
      display: "MTN"
    },
    {
      name: "Lincoln Regional Karl Harder Field",
      city: "Lincoln",
      country: "United States",
      value: "LHM",
      display: "LHM"
    },
    {
      name: "Fostoria Metropolitan Airport",
      city: "Fostoria",
      country: "United States",
      value: "FZI",
      display: "FZI"
    },
    {
      name: "Eastern Slopes Regional Airport",
      city: "Fryeburg",
      country: "United States",
      value: "IZG",
      display: "IZG"
    },
    {
      name: "Lakefront Airport",
      city: "New Orleans",
      country: "United States",
      value: "NEW",
      display: "NEW"
    },
    {
      name: "Coeur D'Alene - Pappy Boyington Field",
      city: "Coeur d Alene",
      country: "United States",
      value: "COE",
      display: "COE"
    },
    {
      name: "Beaumont Municipal Airport",
      city: "Beaumont",
      country: "United States",
      value: "BMT",
      display: "BMT"
    },
    {
      name: "Vermilion Regional Airport",
      city: "Danville",
      country: "United States",
      value: "DNV",
      display: "DNV"
    },
    {
      name: "Coonabarabran Airport",
      city: "Coonabarabran",
      country: "Australia",
      value: "COJ",
      display: "COJ"
    },
    {
      name: "Space Coast Regional Airport",
      city: "Titusville",
      country: "United States",
      value: "TIX",
      display: "TIX"
    },
    {
      name: "Nyeri Airport",
      city: "NYERI",
      country: "Kenya",
      value: "NYE",
      display: "NYE"
    },
    {
      name: "Andrau Airpark",
      city: "Houston",
      country: "United States",
      value: "AAP",
      display: "AAP"
    },
    {
      name: "Flying Cloud Airport",
      city: "Eden Prairie",
      country: "United States",
      value: "FCM",
      display: "FCM"
    },
    {
      name: "Likoma Island Airport",
      city: "Likoma Island",
      country: "Malawi",
      value: "LIX",
      display: "LIX"
    },
    {
      name: "Johnson County Executive Airport",
      city: "Olathe",
      country: "United States",
      value: "OJC",
      display: "OJC"
    },
    {
      name: "Sigiriya Air Force Base",
      city: "Sigiriya",
      country: "Sri Lanka",
      value: "GIU",
      display: "GIU"
    },
    {
      name: "Neumunster Airport",
      city: "Neumuenster",
      country: "Germany",
      value: "EUM",
      display: "EUM"
    },
    {
      name: "Tak Airport",
      city: "Tak",
      country: "Thailand",
      value: "TKT",
      display: "TKT"
    },
    {
      name: "Barrie-Orillia (Lake Simcoe Regional Airport)",
      city: "Barrie-Orillia",
      country: "Canada",
      value: "YLK",
      display: "YLK"
    },
    {
      name: "Huronia Airport",
      city: "Midland",
      country: "Canada",
      value: "YEE",
      display: "YEE"
    },
    {
      name: "Markham Airport",
      city: "Markham",
      country: "Canada",
      value: "NU8",
      display: "NU8"
    },
    {
      name: "Stanhope Municipal Airport",
      city: "Haliburton",
      country: "Canada",
      value: "ND4",
      display: "ND4"
    },
    {
      name: "Lindsay Airport",
      city: "Lindsay",
      country: "Canada",
      value: "NF4",
      display: "NF4"
    },
    {
      name: "Niagara District Airport",
      city: "Saint Catherines",
      country: "Canada",
      value: "YCM",
      display: "YCM"
    },
    {
      name: "Parry Sound Area Municipal Airport",
      city: "Parry Sound",
      country: "Canada",
      value: "YPD",
      display: "YPD"
    },
    {
      name: "Brandywine Airport",
      city: "West Goshen Township",
      country: "United States",
      value: "OQN",
      display: "OQN"
    },
    {
      name: "Manassas Regional Airport/Harry P. Davis Field",
      city: "Manassas",
      country: "United States",
      value: "MNZ",
      display: "MNZ"
    },
    {
      name: "Kastamonu Airport",
      city: "Kastamonu",
      country: "Turkey",
      value: "KFS",
      display: "KFS"
    },
    {
      name: "Shelby County Airport",
      city: "Shelbyville",
      country: "United States",
      value: "2H0",
      display: "2H0"
    },
    {
      name: "Gannan Xiahe Airport",
      city: "Xiahe city",
      country: "China",
      value: "GXH",
      display: "GXH"
    },
    {
      name: "Comiso Airport",
      city: "Comiso",
      country: "Italy",
      value: "CIY",
      display: "CIY"
    },
    {
      name: "Markovo Airport",
      city: "Markovo",
      country: "Russia",
      value: "KVM",
      display: "KVM"
    },
    {
      name: "Zyryanka Airport",
      city: "Zyryanka",
      country: "Russia",
      value: "ZKP",
      display: "ZKP"
    },
    {
      name: "Ust-Maya Airport",
      city: "Ust-Maya",
      country: "Russia",
      value: "UMS",
      display: "UMS"
    },
    {
      name: "Aldan Airport",
      city: "Aldan",
      country: "Russia",
      value: "ADH",
      display: "ADH"
    },
    {
      name: "Xinyuan Nalati Airport",
      city: "Xinyuan",
      country: "China",
      value: "NLT",
      display: "NLT"
    },
    {
      name: "Fontaine Airport",
      city: "Belfort",
      country: "France",
      value: "BOR",
      display: "BOR"
    },
    {
      name: "Fairfield County Airport",
      city: "Winnsboro",
      country: "United States",
      value: "FDW",
      display: "FDW"
    },
    {
      name: "Obock Airport",
      city: "Obock",
      country: "Djibouti",
      value: "OBC",
      display: "OBC"
    },
    {
      name: "Tadjoura Airport",
      city: "Tadjoura",
      country: "Djibouti",
      value: "TDJ",
      display: "TDJ"
    },
    {
      name: "Santa Cruz del Quiche Airport",
      city: "Santa Cruz des Quiche",
      country: "Guatemala",
      value: "AQB",
      display: "AQB"
    },
    {
      name: "Norofjorour Airport",
      city: "Nordfjordur",
      country: "Iceland",
      value: "NOR",
      display: "NOR"
    },
    {
      name: "Bursa Airport",
      city: "Bursa",
      country: "Turkey",
      value: "BTZ",
      display: "BTZ"
    },
    {
      name: "Skyhaven Airport",
      city: "Rochester",
      country: "United States",
      value: "DAW",
      display: "DAW"
    },
    {
      name: "Waris Airport",
      city: "Waris-Papua Island",
      country: "Indonesia",
      value: "WAR",
      display: "WAR"
    },
    {
      name: "Newton City-County Airport",
      city: "Newton",
      country: "United States",
      value: "EWK",
      display: "EWK"
    },
    {
      name: "Bairnsdale Airport",
      city: "Bairnsdale",
      country: "Australia",
      value: "BSJ",
      display: "BSJ"
    },
    {
      name: "Taszar Air Base",
      city: "Columbus",
      country: "United States",
      value: "TZR",
      display: "TZR"
    },
    {
      name: "Fort Bridger Airport",
      city: "Fort Bridger",
      country: "United States",
      value: "FBR",
      display: "FBR"
    },
    {
      name: "Prosser Airport",
      city: "Prosser",
      country: "United States",
      value: "S40",
      display: "S40"
    },
    {
      name: "Chehalis Centralia Airport",
      city: "Chehalis",
      country: "United States",
      value: "CLS",
      display: "CLS"
    },
    {
      name: "Desert Aire Airport",
      city: "Mattawa",
      country: "United States",
      value: "M94",
      display: "M94"
    },
    {
      name: "Evanston-Uinta County Airport-Burns Field",
      city: "Evanston",
      country: "United States",
      value: "EVW",
      display: "EVW"
    },
    {
      name: "Sabetha Municipal Airport",
      city: "Sabetha",
      country: "United States",
      value: "K83",
      display: "K83"
    },
    {
      name: "Mt Pleasant Regional-Faison field",
      city: "Mount Pleasant",
      country: "United States",
      value: "LRO",
      display: "LRO"
    },
    {
      name: "Weedon Field",
      city: "Eufala",
      country: "United States",
      value: "EUF",
      display: "EUF"
    },
    {
      name: "Saluda County Airport",
      city: "Saluda",
      country: "United States",
      value: "6J4",
      display: "6J4"
    },
    {
      name: "Dare County Regional Airport",
      city: "Manteo",
      country: "United States",
      value: "MEO",
      display: "MEO"
    },
    {
      name: "Auburn Opelika Robert G. Pitts Airport",
      city: "Auburn",
      country: "United States",
      value: "AUO",
      display: "AUO"
    },
    {
      name: "Tri Cities Airport",
      city: "Endicott",
      country: "United States",
      value: "CZG",
      display: "CZG"
    },
    {
      name: "Bessemer Airport",
      city: "Bessemer",
      country: "United States",
      value: "EKY",
      display: "EKY"
    },
    {
      name: "Colorado Springs East Airport",
      city: "Ellicott",
      country: "United States",
      value: "A50",
      display: "A50"
    },
    {
      name: "Crystal Airport",
      city: "Crystal",
      country: "United States",
      value: "MIC",
      display: "MIC"
    },
    {
      name: "Clarke County Airport",
      city: "Quitman",
      country: "United States",
      value: "23M",
      display: "23M"
    },
    {
      name: "W H 'Bud' Barron Airport",
      city: "Dublin",
      country: "United States",
      value: "DBN",
      display: "DBN"
    },
    {
      name: "Pukarua Airport",
      city: "Pukarua",
      country: "French Polynesia",
      value: "PUK",
      display: "PUK"
    },
    {
      name: "Corvallis Municipal Airport",
      city: "Corvallis",
      country: "United States",
      value: "CVO",
      display: "CVO"
    },
    {
      name: "Prominent Hill Airport",
      city: "Prominent Hill",
      country: "Australia",
      value: "PXH",
      display: "PXH"
    },
    {
      name: "Cowra Airport",
      city: "Chatsworth",
      country: "United States",
      value: "CWT",
      display: "CWT"
    },
    {
      name: "Ogden Hinckley Airport",
      city: "Ogden",
      country: "United States",
      value: "OGD",
      display: "OGD"
    },
    {
      name: "Lake Country Regional Airport",
      city: "Clarksville",
      country: "United Arab Emirates",
      value: "W63",
      display: "W63"
    },
    {
      name: "Robert S Kerr Airport",
      city: "Poteau",
      country: "United States",
      value: "RKR",
      display: "RKR"
    },
    {
      name: "Colorado Plains Regional Airport",
      city: "Akron",
      country: "United States",
      value: "AKO",
      display: "AKO"
    },
    {
      name: "Sanderson Field",
      city: "Shelton",
      country: "United States",
      value: "SHN",
      display: "SHN"
    },
    {
      name: "Napakiak Airport",
      city: "Napakiak",
      country: "United States",
      value: "WNA",
      display: "WNA"
    },
    {
      name: "Napaskiak Airport",
      city: "Napaskiak",
      country: "United States",
      value: "PKA",
      display: "PKA"
    },
    {
      name: "Bedwell Harbour Seaplane Base",
      city: "Bedwell Harbour",
      country: "Canada",
      value: "YBW",
      display: "YBW"
    },
    {
      name: "Causey Airport",
      city: "Liberty",
      country: "United States",
      value: "2A5",
      display: "2A5"
    },
    {
      name: "Abaco I Walker C Airport",
      city: "Walker s Cay",
      country: "Bahamas",
      value: "WKR",
      display: "WKR"
    },
    {
      name: "Bartica A Airport",
      city: "Bartica",
      country: "Guyana",
      value: "GFO",
      display: "GFO"
    },
    {
      name: "Doylestown Airport",
      city: "Doylestown",
      country: "United States",
      value: "DYL",
      display: "DYL"
    },
    {
      name: "Tingo Maria Airport",
      city: "Tingo Maria",
      country: "Peru",
      value: "TGI",
      display: "TGI"
    },
    {
      name: "Plinio Alarcom Airport",
      city: "Tres Lagoas",
      country: "Brazil",
      value: "TJL",
      display: "TJL"
    },
    {
      name: "Cacoal Airport",
      city: "Cacoal",
      country: "Brazil",
      value: "OAL",
      display: "OAL"
    },
    {
      name: "Warren Field",
      city: "Washington",
      country: "United States",
      value: "OCW",
      display: "OCW"
    },
    {
      name: "Hyde County Airport",
      city: "Engelhard",
      country: "United States",
      value: "7W6",
      display: "7W6"
    },
    {
      name: "Mocopulli Airport",
      city: "Castro",
      country: "Chile",
      value: "MHC",
      display: "MHC"
    },
    {
      name: "Elliot Lake Municipal Airport",
      city: "ELLIOT LAKE",
      country: "Canada",
      value: "YEL",
      display: "YEL"
    },
    {
      name: "Wilkes County Airport",
      city: "North Wilkesboro",
      country: "United States",
      value: "UKF",
      display: "UKF"
    },
    {
      name: "Charleston Executive Airport",
      city: "Charleston",
      country: "United States",
      value: "JZI",
      display: "JZI"
    },
    {
      name: "Danville Regional Airport",
      city: "Danville",
      country: "United States",
      value: "DAN",
      display: "DAN"
    },
    {
      name: "Brookneal/Campbell County Airport",
      city: "Brookneal",
      country: "United States",
      value: "0V4",
      display: "0V4"
    },
    {
      name: "Yerbogachen Airport",
      city: "Yerbogachen",
      country: "Russia",
      value: "ERG",
      display: "ERG"
    },
    {
      name: "Cheraw Municipal Airport/Lynch Bellinger Field",
      city: "Cheraw",
      country: "United States",
      value: "CQW",
      display: "CQW"
    },
    {
      name: "Beni Mellal Airport",
      city: "Beni Mellal",
      country: "Morocco",
      value: "BEM",
      display: "BEM"
    },
    {
      name: "S?rnak Serafettin Elci Airport",
      city: "Cizre",
      country: "Turkey",
      value: "NKT",
      display: "NKT"
    },
    {
      name: "Suntar Airport",
      city: "Suntar",
      country: "Russia",
      value: "SUY",
      display: "SUY"
    },
    {
      name: "Tazadit Airport",
      city: "Zouerat",
      country: "Mauritania",
      value: "OUZ",
      display: "OUZ"
    },
    {
      name: "Akwa Ibom International Airport",
      city: "Uyo",
      country: "Nigeria",
      value: "QUO",
      display: "QUO"
    },
    {
      name: "Kasama Airport",
      city: "Kasama",
      country: "Zambia",
      value: "KAA",
      display: "KAA"
    },
    {
      name: "Mbeya Airport",
      city: "Mbeya",
      country: "Tanzania",
      value: "MBI",
      display: "MBI"
    },
    {
      name: "Songea Airport",
      city: "Songea",
      country: "Tanzania",
      value: "SGX",
      display: "SGX"
    },
    {
      name: "Anshan Air Base",
      city: "Anshan",
      country: "China",
      value: "AOG",
      display: "AOG"
    },
    {
      name: "Zunyi Xinzhou Airport",
      city: "Zunyi",
      country: "China",
      value: "ZYI",
      display: "ZYI"
    },
    {
      name: "Conway Horry County Airport",
      city: "Conway",
      country: "United States",
      value: "HYW",
      display: "HYW"
    },
    {
      name: "Lindu Airport",
      city: "Yinchun",
      country: "China",
      value: "LDS",
      display: "LDS"
    },
    {
      name: "Anshun Huangguoshu Airport",
      city: "Anshun",
      country: "China",
      value: "AVA",
      display: "AVA"
    },
    {
      name: "Sikasso Airport",
      city: "Sikasso",
      country: "Mali",
      value: "KSS",
      display: "KSS"
    },
    {
      name: "Brisbane West Wellcamp Airport",
      city: "Toowoomba",
      country: "Australia",
      value: "WTB",
      display: "WTB"
    },
    {
      name: "Tonghua Sanyuanpu Airport",
      city: "Tonghua",
      country: "China",
      value: "TNH",
      display: "TNH"
    },
    {
      name: "Suzhou Guangfu Airport",
      city: "Suzhou",
      country: "China",
      value: "SZV",
      display: "SZV"
    },
    {
      name: "Mulia Airport",
      city: "Mulia",
      country: "Indonesia",
      value: "LII",
      display: "LII"
    },
    {
      name: "Stenkol Airport",
      city: "Bintuni",
      country: "Indonesia",
      value: "NTI",
      display: "NTI"
    },
    {
      name: "Wasior Airport",
      city: "Wasior",
      country: "Indonesia",
      value: "WSR",
      display: "WSR"
    },
    {
      name: "Silangit Airport",
      city: "Siborong-Borong",
      country: "Indonesia",
      value: "DTB",
      display: "DTB"
    },
    {
      name: "Lasikin Airport",
      city: "Sinabang",
      country: "Indonesia",
      value: "SSV",
      display: "SSV"
    },
    {
      name: "Seunagan Airport",
      city: "Nagan Raya",
      country: "Indonesia",
      value: "MEQ",
      display: "MEQ"
    },
    {
      name: "Betoambari Airport",
      city: "Bau-Bau",
      country: "Indonesia",
      value: "BUW",
      display: "BUW"
    },
    {
      name: "Kao Airport",
      city: "Kao",
      country: "Indonesia",
      value: "KAZ",
      display: "KAZ"
    },
    {
      name: "Melangguane Airport",
      city: "Melonguane",
      country: "Indonesia",
      value: "MNA",
      display: "MNA"
    },
    {
      name: "Sanggata/Sangkimah Airport",
      city: "Sanggata",
      country: "Indonesia",
      value: "SGQ",
      display: "SGQ"
    },
    {
      name: "Oksibil Airport",
      city: "Oksibil",
      country: "Indonesia",
      value: "OKL",
      display: "OKL"
    },
    {
      name: "Kokonau Airport",
      city: "Kokonau",
      country: "Indonesia",
      value: "KOX",
      display: "KOX"
    },
    {
      name: "Clermont Airport",
      city: "Clermont",
      country: "Australia",
      value: "CMQ",
      display: "CMQ"
    },
    {
      name: "Warrnambool Airport",
      city: "Warrnambool",
      country: "Australia",
      value: "WMB",
      display: "WMB"
    },
    {
      name: "Richmond Airport",
      city: "Richmond",
      country: "Australia",
      value: "RCM",
      display: "RCM"
    },
    {
      name: "RAAF Base Curtin",
      city: "Derby",
      country: "Australia",
      value: "DCN",
      display: "DCN"
    },
    {
      name: "Kualanamu International Airport",
      city: "Medan",
      country: "Indonesia",
      value: "KNO",
      display: "KNO"
    },
    {
      name: "Asheboro Regional Airport",
      city: "Asheboro",
      country: "United States",
      value: "HBI",
      display: "HBI"
    },
    {
      name: "Emporia Greensville Regional Airport",
      city: "Emporia",
      country: "United States",
      value: "EMV",
      display: "EMV"
    },
    {
      name: "El Monte Airport",
      city: "El Monte",
      country: "United States",
      value: "EMT",
      display: "EMT"
    },
    {
      name: "Farah Airport",
      city: "Farah",
      country: "Afghanistan",
      value: "FAH",
      display: "FAH"
    },
    {
      name: "Pasighat Airport",
      city: "Pasighat",
      country: "India",
      value: "IXT",
      display: "IXT"
    },
    {
      name: "Kee Field",
      city: "Pineville",
      country: "United States",
      value: "I16",
      display: "I16"
    },
    {
      name: "Kramatorsk Airport",
      city: "Kramatorsk",
      country: "Ukraine",
      value: "KRQ",
      display: "KRQ"
    },
    {
      name: "Stinson Municipal Airport",
      city: "Stinson",
      country: "United States",
      value: "SSF",
      display: "SSF"
    },
    {
      name: "Sallisaw Municipal Airport",
      city: "Sallisaw",
      country: "United States",
      value: "JSV",
      display: "JSV"
    },
    {
      name: "Jasper County Airport-Bell Field",
      city: "Jasper",
      country: "United States",
      value: "JAS",
      display: "JAS"
    },
    {
      name: "El Dorado Springs Memorial Airport",
      city: "El dorado springs",
      country: "United States",
      value: "87K",
      display: "87K"
    },
    {
      name: "Marfa Municipal Airport",
      city: "Marfa",
      country: "United States",
      value: "MRF",
      display: "MRF"
    },
    {
      name: "Alpine Casparis Municipal Airport",
      city: "Alpine",
      country: "United States",
      value: "E38",
      display: "E38"
    },
    {
      name: "Bubaque Airport",
      city: "Bubaque",
      country: "Guinea-Bissau",
      value: "BQE",
      display: "BQE"
    },
    {
      name: "Chichen Itza International Airport",
      city: "Chichen Itza",
      country: "Mexico",
      value: "CZA",
      display: "CZA"
    },
    {
      name: "Bunbury Airport",
      city: "Bunbury",
      country: "Australia",
      value: "BUY",
      display: "BUY"
    },
    {
      name: "Cable Airport",
      city: "Upland",
      country: "United States",
      value: "CCB",
      display: "CCB"
    },
    {
      name: "Mount Sterling Montgomery County Airport",
      city: "Mount Sterling",
      country: "United States",
      value: "IOB",
      display: "IOB"
    },
    {
      name: "Elkhart Municipal Airport",
      city: "Elkhart",
      country: "United States",
      value: "EKI",
      display: "EKI"
    },
    {
      name: "Nappanee Municipal Airport",
      city: "Nappanee",
      country: "United States",
      value: "C03",
      display: "C03"
    },
    {
      name: "Jim Hamilton L.B. Owens Airport",
      city: "Columbia",
      country: "United States",
      value: "CUB",
      display: "CUB"
    },
    {
      name: "Grove Municipal Airport",
      city: "Grove",
      country: "United States",
      value: "GMJ",
      display: "GMJ"
    },
    {
      name: "Mc Pherson Airport",
      city: "Mc Pherson",
      country: "United States",
      value: "MPR",
      display: "MPR"
    },
    {
      name: "Donaldson Center Airport",
      city: "Greenville",
      country: "United States",
      value: "GYH",
      display: "GYH"
    },
    {
      name: "Perry Houston County Airport",
      city: "Perry",
      country: "United States",
      value: "PXE",
      display: "PXE"
    },
    {
      name: "Hartsville Regional Airport",
      city: "Hartsville",
      country: "United States",
      value: "HVS",
      display: "HVS"
    },
    {
      name: "Horace Williams Airport",
      city: "Chapel Hill",
      country: "United States",
      value: "IGX",
      display: "IGX"
    },
    {
      name: "San Cristobal de las Casas Airport",
      city: "San Cristobal de las Casas",
      country: "Mexico",
      value: "SZT",
      display: "SZT"
    },
    {
      name: "Dunnville Airport",
      city: "Dunnville",
      country: "Canada",
      value: "DU9",
      display: "DU9"
    },
    {
      name: "Brunswick County Airport",
      city: "Oak Island",
      country: "United States",
      value: "SUT",
      display: "SUT"
    },
    {
      name: "Chesterfield County Airport",
      city: "Richmond",
      country: "United States",
      value: "FCI",
      display: "FCI"
    },
    {
      name: "Henderson Oxford Airport",
      city: "Oxford",
      country: "United States",
      value: "HNZ",
      display: "HNZ"
    },
    {
      name: "Leesburg International Airport",
      city: "Leesburg",
      country: "United States",
      value: "LEE",
      display: "LEE"
    },
    {
      name: "Pouso Alegre Airport",
      city: "Pouso Alegre",
      country: "Brazil",
      value: "PPY",
      display: "PPY"
    },
    {
      name: "Brigadeiro Cabral Airport",
      city: "Divinopolis",
      country: "Brazil",
      value: "DIQ",
      display: "DIQ"
    },
    {
      name: "Berdyansk Airport",
      city: "Berdyansk",
      country: "Ukraine",
      value: "ERD",
      display: "ERD"
    },
    {
      name: "Erenhot Saiwusu International Airport",
      city: "Erenhot",
      country: "China",
      value: "ERL",
      display: "ERL"
    },
    {
      name: "Chino Airport",
      city: "Chino",
      country: "United States",
      value: "CNO",
      display: "CNO"
    },
    {
      name: "Hateruma Airport",
      city: "Taketomi",
      country: "Japan",
      value: "HTR",
      display: "HTR"
    },
    {
      name: "Madison County Airport",
      city: "London",
      country: "United States",
      value: "UYF",
      display: "UYF"
    },
    {
      name: "Las Brujas Airport",
      city: "Cayo Santa Maria",
      country: "Cuba",
      value: "BWW",
      display: "BWW"
    },
    {
      name: "Paso Robles Municipal Airport",
      city: "Paso Robles",
      country: "United States",
      value: "PRB",
      display: "PRB"
    },
    {
      name: "Half Moon Bay Airport",
      city: "Half Moon Bay",
      country: "United States",
      value: "HAF",
      display: "HAF"
    },
    {
      name: "Hechi Jinchengjiang Airport",
      city: "Hechi",
      country: "China",
      value: "HCJ",
      display: "HCJ"
    },
    {
      name: "General WM J Fox Airfield",
      city: "Lancaster",
      country: "United States",
      value: "WJF",
      display: "WJF"
    },
    {
      name: "Coondewanna Airport",
      city: "Coondewanna",
      country: "Australia",
      value: "CJF",
      display: "CJF"
    },
    {
      name: "Guarapari Airport",
      city: "Guarapari",
      country: "Brazil",
      value: "GUZ",
      display: "GUZ"
    },
    {
      name: "Ubatuba Airport",
      city: "Ubatuba",
      country: "Brazil",
      value: "UBT",
      display: "UBT"
    },
    {
      name: "Borroloola Airport",
      city: "Borroloola",
      country: "Australia",
      value: "BOX",
      display: "BOX"
    },
    {
      name: "Dongji Aiport",
      city: "Fuyuan",
      country: "China",
      value: "FYJ",
      display: "FYJ"
    },
    {
      name: "Person County Airport",
      city: "Roxboro",
      country: "United States",
      value: "TDF",
      display: "TDF"
    },
    {
      name: "Zulu Inyala Airport",
      city: "Phinda",
      country: "South Africa",
      value: "PZL",
      display: "PZL"
    },
    {
      name: "Kaili Airport",
      city: "Kaili",
      country: "China",
      value: "KJH",
      display: "KJH"
    },
    {
      name: "Shennongjia Hongping Airport",
      city: "Shennongjia",
      country: "China",
      value: "HPG",
      display: "HPG"
    },
    {
      name: "Arxan Yi'ershi Airport",
      city: "Arxan",
      country: "China",
      value: "YIE",
      display: "YIE"
    },
    {
      name: "Hengyang Nanyue Airport",
      city: "Hengyang",
      country: "China",
      value: "HNY",
      display: "HNY"
    },
    {
      name: "Wonsan Kalma International Airport",
      city: "Wonsan",
      country: "North Korea",
      value: "WOS",
      display: "WOS"
    },
    {
      name: "Magas Airport",
      city: "Magas",
      country: "Russia",
      value: "IGT",
      display: "IGT"
    },
    {
      name: "Talladega Municipal Airport",
      city: "Talladega",
      country: "United States",
      value: "ASN",
      display: "ASN"
    },
    {
      name: "Greenville Downtown Airport",
      city: "Greenville",
      country: "United States",
      value: "GMU",
      display: "GMU"
    },
    {
      name: "Mankato Regional Airport",
      city: "Mankato",
      country: "United States",
      value: "MKT",
      display: "MKT"
    },
    {
      name: "Captain Auguste George Airport",
      city: "Anegada",
      country: "British Virgin Islands",
      value: "NGD",
      display: "NGD"
    },
    {
      name: "Troy Municipal Airport",
      city: "Troy",
      country: "United States",
      value: "TOI",
      display: "TOI"
    },
    {
      name: "Merkel Field Sylacauga Municipal Airport",
      city: "Sylacauga",
      country: "United States",
      value: "SCD",
      display: "SCD"
    },
    {
      name: "Enterprise Municipal Airport",
      city: "Enterprise",
      country: "United States",
      value: "EDN",
      display: "EDN"
    },
    {
      name: "Thomas C Russell Field",
      city: "Alexander City",
      country: "United States",
      value: "ALX",
      display: "ALX"
    },
    {
      name: "Port Keats Airport",
      city: "Wadeye",
      country: "Australia",
      value: "PKT",
      display: "PKT"
    },
    {
      name: "Garden Point Airport",
      city: "Pirlangimpi",
      country: "Australia",
      value: "GPN",
      display: "GPN"
    },
    {
      name: "Fort Mackay / Horizon Airport",
      city: "Wood Buffalo",
      country: "Canada",
      value: "HZP",
      display: "HZP"
    },
    {
      name: "New Castle Henry Co. Municipal Airport",
      city: "New Castle",
      country: "United States",
      value: "UWL",
      display: "UWL"
    },
    {
      name: "Scott City Municipal Airport",
      city: "Scott City",
      country: "United States",
      value: "TQK",
      display: "TQK"
    },
    {
      name: "Brewster Field",
      city: "Holdredge",
      country: "United States",
      value: "HDE",
      display: "HDE"
    },
    {
      name: "Pratt Regional Airport",
      city: "Pratt",
      country: "United States",
      value: "PTT",
      display: "PTT"
    },
    {
      name: "Wahoo Municipal Airport",
      city: "Wahoo",
      country: "United States",
      value: "AHQ",
      display: "AHQ"
    },
    {
      name: "Jim Kelly Field",
      city: "Lexington",
      country: "United States",
      value: "LXN",
      display: "LXN"
    },
    {
      name: "Sublette Municipal Airport",
      city: "Sublette",
      country: "United States",
      value: "19S",
      display: "19S"
    },
    {
      name: "Council Bluffs Municipal Airport",
      city: "Council Bluffs",
      country: "United States",
      value: "CBF",
      display: "CBF"
    },
    {
      name: "Kokomo Municipal Airport",
      city: "Kokomo",
      country: "United States",
      value: "OKK",
      display: "OKK"
    },
    {
      name: "Neodesha Municipal Airport",
      city: "Neodesha",
      country: "United States",
      value: "2K7",
      display: "2K7"
    },
    {
      name: "Elkhart Morton County Airport",
      city: "Elkhart",
      country: "United States",
      value: "EHA",
      display: "EHA"
    },
    {
      name: "Front Range Airport",
      city: "Denver",
      country: "United States",
      value: "FTG",
      display: "FTG"
    },
    {
      name: "Galesburg Municipal Airport",
      city: "Galesburg",
      country: "United States",
      value: "GBG",
      display: "GBG"
    },
    {
      name: "Guymon Municipal Airport",
      city: "Guymon",
      country: "United States",
      value: "GUY",
      display: "GUY"
    },
    {
      name: "Meade Municipal Airport",
      city: "Meade",
      country: "United States",
      value: "MEJ",
      display: "MEJ"
    },
    {
      name: "Ulysses Airport",
      city: "Ulysses",
      country: "United States",
      value: "ULS",
      display: "ULS"
    },
    {
      name: "Independence Municipal Airport",
      city: "Independence",
      country: "United States",
      value: "IDP",
      display: "IDP"
    },
    {
      name: "Augusta Municipal Airport",
      city: "Augusta",
      country: "United States",
      value: "3AU",
      display: "3AU"
    },
    {
      name: "Larned Pawnee County Airport",
      city: "Larned",
      country: "United States",
      value: "LQR",
      display: "LQR"
    },
    {
      name: "Lampasas Airport",
      city: "Lampasas",
      country: "United States",
      value: "LZZ",
      display: "LZZ"
    },
    {
      name: "Bay City Municipal Airport",
      city: "Bay City",
      country: "United States",
      value: "BYY",
      display: "BYY"
    },
    {
      name: "Cox Field",
      city: "Paris",
      country: "United States",
      value: "PRX",
      display: "PRX"
    },
    {
      name: "Coffeyville Municipal Airport",
      city: "Coffeyville",
      country: "United States",
      value: "CFV",
      display: "CFV"
    },
    {
      name: "Greeley-Weld County Airport",
      city: "Greeley",
      country: "United States",
      value: "GXY",
      display: "GXY"
    },
    {
      name: "Oryol Yuzhny Airport",
      city: "Oakley",
      country: "United States",
      value: "OEL",
      display: "OEL"
    },
    {
      name: "Fremont Municipal Airport",
      city: "Fremont",
      country: "United States",
      value: "FET",
      display: "FET"
    },
    {
      name: "La Grande/Union County Airport",
      city: "La Grande",
      country: "United States",
      value: "LGD",
      display: "LGD"
    },
    {
      name: "Szczytno-Szymany International Airport",
      city: "Szczytno-Szymany",
      country: "Poland",
      value: "SZY",
      display: "SZY"
    },
    {
      name: "Pocono Mountains Municipal Airport",
      city: "Mount Pocono",
      country: "United States",
      value: "MPO",
      display: "MPO"
    },
    {
      name: "Quakertown Airport",
      city: "Quakertown",
      country: "United States",
      value: "UKT",
      display: "UKT"
    },
    {
      name: "Banff Airport",
      city: "Banff",
      country: "Canada",
      value: "YBA",
      display: "YBA"
    },
    {
      name: "Banning Municipal Airport",
      city: "Banning",
      country: "United States",
      value: "BNG",
      display: "BNG"
    },
    {
      name: "Karl Stefan Memorial Airport",
      city: "Norfolk  Nebraska",
      country: "United States",
      value: "OFK",
      display: "OFK"
    },
    {
      name: "Arlington Municipal Airport",
      city: "Arlington",
      country: "United States",
      value: "AWO",
      display: "AWO"
    },
    {
      name: "Juscelino Kubitscheck Airport",
      city: "Teofilo Otoni",
      country: "Brazil",
      value: "TFL",
      display: "TFL"
    },
    {
      name: "Peter O Knight Airport",
      city: "Tampa",
      country: "United States",
      value: "TPF",
      display: "TPF"
    },
    {
      name: "Umberto Modiano Airport",
      city: "Buzios",
      country: "Brazil",
      value: "BZC",
      display: "BZC"
    },
    {
      name: "Itaperuna Airport",
      city: "Itaperuna",
      country: "Brazil",
      value: "ITP",
      display: "ITP"
    },
    {
      name: "Tunta Airport",
      city: "Kabinda",
      country: "Congo (Kinshasa)",
      value: "KBN",
      display: "KBN"
    },
    {
      name: "Ikela Airport",
      city: "Ikela",
      country: "Congo (Kinshasa)",
      value: "IKL",
      display: "IKL"
    },
    {
      name: "Aripuana Airport",
      city: "Aripuana",
      country: "Brazil",
      value: "AIR",
      display: "AIR"
    },
    {
      name: "Juruena Airport",
      city: "Juruena",
      country: "Brazil",
      value: "JRN",
      display: "JRN"
    },
    {
      name: "Juina Airport",
      city: "Juina",
      country: "Brazil",
      value: "JIA",
      display: "JIA"
    },
    {
      name: "Vila Rica Airport",
      city: "Vila Rica",
      country: "Brazil",
      value: "VLP",
      display: "VLP"
    },
    {
      name: "Inacio Luis do Nascimento Airport",
      city: "Juara",
      country: "Brazil",
      value: "JUA",
      display: "JUA"
    },
    {
      name: "Caceres Airport",
      city: "Caceres",
      country: "Brazil",
      value: "CCX",
      display: "CCX"
    },
    {
      name: "Bumbar Airport",
      city: "Bumba",
      country: "Congo (Kinshasa)",
      value: "BMB",
      display: "BMB"
    },
    {
      name: "Sobral Airport",
      city: "Sobral",
      country: "Brazil",
      value: "QBX",
      display: "QBX"
    },
    {
      name: "Arapiraca Airport",
      city: "Arapiraca",
      country: "Brazil",
      value: "APQ",
      display: "APQ"
    },
    {
      name: "Cangapara Airport",
      city: "Floriano",
      country: "Brazil",
      value: "FLB",
      display: "FLB"
    },
    {
      name: "Picos Airport",
      city: "Picos",
      country: "Brazil",
      value: "PCS",
      display: "PCS"
    },
    {
      name: "Beni Airport",
      city: "Beni",
      country: "Congo (Kinshasa)",
      value: "BNC",
      display: "BNC"
    },
    {
      name: "Boende Airport",
      city: "Boende",
      country: "Congo (Kinshasa)",
      value: "BNB",
      display: "BNB"
    },
    {
      name: "Montauk Airport",
      city: "Montauk",
      country: "United States",
      value: "MTP",
      display: "MTP"
    },
    {
      name: "Porter County Municipal Airport",
      city: "Valparaiso IN",
      country: "United States",
      value: "VPZ",
      display: "VPZ"
    },
    {
      name: "Dharavandhoo Airport",
      city: "DHARAVANDHOO",
      country: "Maldives",
      value: "DRV",
      display: "DRV"
    },
    {
      name: "Saumlaki/Olilit Airport",
      city: "Saumlaki",
      country: "Indonesia",
      value: "SXK",
      display: "SXK"
    },
    {
      name: "Cerro Largo International Airport",
      city: "Melo",
      country: "Uruguay",
      value: "MLZ",
      display: "MLZ"
    },
    {
      name: "Tydeo Larre Borges Airport",
      city: "Paysandu",
      country: "Uruguay",
      value: "PDU",
      display: "PDU"
    },
    {
      name: "Artigas International Airport",
      city: "Artigas",
      country: "Uruguay",
      value: "ATI",
      display: "ATI"
    },
    {
      name: "Horsham Airport",
      city: "Horsham",
      country: "Australia",
      value: "HSM",
      display: "HSM"
    },
    {
      name: "Swan Hill Airport",
      city: "Swan Hill",
      country: "Australia",
      value: "SWH",
      display: "SWH"
    },
    {
      name: "Turtle Island Seaplane Base",
      city: "Turtle Island",
      country: "Fiji",
      value: "TTL",
      display: "TTL"
    },
    {
      name: "Dewadaru - Kemujan Island",
      city: "Karimunjawa",
      country: "Indonesia",
      value: "KWB",
      display: "KWB"
    },
    {
      name: "Kongolo Airport",
      city: "Kongolo",
      country: "Congo (Kinshasa)",
      value: "KOO",
      display: "KOO"
    },
    {
      name: "Attopeu Airport",
      city: "Attopeu",
      country: "Laos",
      value: "AOU",
      display: "AOU"
    },
    {
      name: "Francisco de Miranda Airport",
      city: "Caracas",
      country: "Venezuela",
      value: "N/A",
      display: "N/A"
    },
    {
      name: "Sao Miguel do Oeste Airport",
      city: "Sao Miguel do Oeste",
      country: "Brazil",
      value: "SQX",
      display: "SQX"
    },
    {
      name: "Mason County Airport",
      city: "Ludington",
      country: "United States",
      value: "LDM",
      display: "LDM"
    },
    {
      name: "Reid-Hillview Airport of Santa Clara County",
      city: "San Jose",
      country: "United States",
      value: "RHV",
      display: "RHV"
    },
    {
      name: "Sohar Airport",
      city: "Sohar",
      country: "Oman",
      value: "OHS",
      display: "OHS"
    },
    {
      name: "Sahiwal Airport",
      city: "Sindh",
      country: "Pakistan",
      value: "RZS",
      display: "RZS"
    },
    {
      name: "Thimarafushi Airport",
      city: "Thimarafushi",
      country: "Maldives",
      value: "TMF",
      display: "TMF"
    },
    {
      name: "Ifuru Airport",
      city: "Ifuru",
      country: "Maldives",
      value: "IFU",
      display: "IFU"
    },
    {
      name: "Kieta Airport",
      city: "Kieta",
      country: "Papua New Guinea",
      value: "KIE",
      display: "KIE"
    },
    {
      name: "Hakkari Yuksekova Airport",
      city: "Hakkari",
      country: "Turkey",
      value: "YKO",
      display: "YKO"
    },
    {
      name: "Bathpalathang Airport",
      city: "Jakar",
      country: "Bhutan",
      value: "BUT",
      display: "BUT"
    },
    {
      name: "Sultan Bantilan Airport",
      city: "Toli-Toli",
      country: "Indonesia",
      value: "TLI",
      display: "TLI"
    },
    {
      name: "Tarko-Sale Airport",
      city: "Tarko-Sale",
      country: "Russia",
      value: "TQL",
      display: "TQL"
    },
    {
      name: "Alashankou Bole (Bortala) airport",
      city: "Bole",
      country: "China",
      value: "BPL",
      display: "BPL"
    },
    {
      name: "Achinsk Airport",
      city: "Achinsk",
      country: "Russia",
      value: "ACS",
      display: "ACS"
    },
    {
      name: "Watsonville Municipal Airport",
      city: "Watsonville",
      country: "United States",
      value: "WVI",
      display: "WVI"
    },
    {
      name: "Gelephu Airport",
      city: "Gelephu",
      country: "Bhutan",
      value: "GLU",
      display: "GLU"
    },
    {
      name: "Williamsburg Jamestown Airport",
      city: "Williamsburg",
      country: "United States",
      value: "JGG",
      display: "JGG"
    },
    {
      name: "Dulkaninna Airport",
      city: "Dulkaninna",
      country: "Australia",
      value: "DLK",
      display: "DLK"
    },
    {
      name: "Opapimiskan Lake Airport",
      city: "Musselwhite Mine",
      country: "Canada",
      value: "KM8",
      display: "KM8"
    },
    {
      name: "Taroom Airport",
      city: "Taroom",
      country: "Australia",
      value: "XTO",
      display: "XTO"
    },
    {
      name: "Squamish Airport",
      city: "Squamish",
      country: "Canada",
      value: "YSE",
      display: "YSE"
    },
    {
      name: "Union County Airport",
      city: "Marysville",
      country: "United States",
      value: "MRT",
      display: "MRT"
    },
    {
      name: "La Grande Airport",
      city: "La Grande",
      country: "Canada",
      value: "YAH",
      display: "YAH"
    },
    {
      name: "Alert Bay Airport",
      city: "Alert Bay",
      country: "Canada",
      value: "YAL",
      display: "YAL"
    },
    {
      name: "Nipawin Airport",
      city: "Nipawin",
      country: "Canada",
      value: "YBU",
      display: "YBU"
    },
    {
      name: "James T. Field Memorial Aerodrome",
      city: "Centralia",
      country: "Canada",
      value: "YCE",
      display: "YCE"
    },
    {
      name: "Blue River Airport",
      city: "Blue River",
      country: "Canada",
      value: "YCP",
      display: "YCP"
    },
    {
      name: "Chetwynd Airport",
      city: "Chetwynd",
      country: "Canada",
      value: "YCQ",
      display: "YCQ"
    },
    {
      name: "Ross River Airport",
      city: "Ross River",
      country: "Canada",
      value: "XRR",
      display: "XRR"
    },
    {
      name: "Dolbeau St Felicien Airport",
      city: "Dolbeau-St-Felicien",
      country: "Canada",
      value: "YDO",
      display: "YDO"
    },
    {
      name: "Amos Magny Airport",
      city: "Amos",
      country: "Canada",
      value: "YEY",
      display: "YEY"
    },
    {
      name: "Goderich Airport",
      city: "Goderich",
      country: "Canada",
      value: "YGD",
      display: "YGD"
    },
    {
      name: "Hope Airport",
      city: "Hope",
      country: "Canada",
      value: "YHE",
      display: "YHE"
    },
    {
      name: "Haines Junction Airport",
      city: "Haines Junction",
      country: "Canada",
      value: "YHT",
      display: "YHT"
    },
    {
      name: "Digby / Annapolis Regional Airport",
      city: "Digby",
      country: "Canada",
      value: "YDG",
      display: "YDG"
    },
    {
      name: "Fort Liard Airport",
      city: "Fort Liard",
      country: "Canada",
      value: "YJF",
      display: "YJF"
    },
    {
      name: "Key Lake Airport",
      city: "Key Lake",
      country: "Canada",
      value: "YKJ",
      display: "YKJ"
    },
    {
      name: "Leaf Rapids Airport",
      city: "Leaf Rapids",
      country: "Canada",
      value: "YLR",
      display: "YLR"
    },
    {
      name: "Matane Airport",
      city: "Matane",
      country: "Canada",
      value: "YME",
      display: "YME"
    },
    {
      name: "Charlevoix Airport",
      city: "Charlevoix",
      country: "Canada",
      value: "YML",
      display: "YML"
    },
    {
      name: "Owen Sound / Billy Bishop Regional Airport",
      city: "Owen Sound",
      country: "Canada",
      value: "YOS",
      display: "YOS"
    },
    {
      name: "Port Hawkesbury Airport",
      city: "Port Hawkesbury",
      country: "Canada",
      value: "YPS",
      display: "YPS"
    },
    {
      name: "St Thomas Municipal Airport",
      city: "St Thomas",
      country: "Canada",
      value: "YQS",
      display: "YQS"
    },
    {
      name: "Ottawa / Rockcliffe Airport",
      city: "Ottawa",
      country: "Canada",
      value: "YRO",
      display: "YRO"
    },
    {
      name: "Ottawa / Carp Airport",
      city: "Ottawa",
      country: "Canada",
      value: "YRP",
      display: "YRP"
    },
    {
      name: "Smiths Falls-Montague (Russ Beach) Airport",
      city: "Smiths Falls",
      country: "Canada",
      value: "YSH",
      display: "YSH"
    },
    {
      name: "St Leonard Airport",
      city: "St Leonard",
      country: "Canada",
      value: "YSL",
      display: "YSL"
    },
    {
      name: "Virden/R.J. (Bob) Andrew Field Regional Aerodrome",
      city: "Virden",
      country: "Canada",
      value: "YVD",
      display: "YVD"
    },
    {
      name: "Vernon Airport",
      city: "Vernon",
      country: "Canada",
      value: "YVE",
      display: "YVE"
    },
    {
      name: "Beaver Creek Airport",
      city: "Beaver Creek",
      country: "Canada",
      value: "YXQ",
      display: "YXQ"
    },
    {
      name: "Salmon Arm Airport",
      city: "Salmon Arm",
      country: "Canada",
      value: "YSN",
      display: "YSN"
    },
    {
      name: "Kelsey Airport",
      city: "Kelsey",
      country: "Canada",
      value: "KES",
      display: "KES"
    },
    {
      name: "Pukatawagan Airport",
      city: "Pukatawagan",
      country: "Canada",
      value: "XPK",
      display: "XPK"
    },
    {
      name: "Grand Forks Airport",
      city: "Grand Forks",
      country: "Canada",
      value: "ZGF",
      display: "ZGF"
    },
    {
      name: "Jenpeg Airport",
      city: "Jenpeg",
      country: "Canada",
      value: "ZJG",
      display: "ZJG"
    },
    {
      name: "Thicket Portage Airport",
      city: "Thicket Portage",
      country: "Canada",
      value: "YTD",
      display: "YTD"
    },
    {
      name: "Pikwitonei Airport",
      city: "Pikwitonei",
      country: "Canada",
      value: "PIW",
      display: "PIW"
    },
    {
      name: "Poplar River Airport",
      city: "Poplar River",
      country: "Canada",
      value: "XPP",
      display: "XPP"
    },
    {
      name: "Pincher Creek Airport",
      city: "Pincher Creek",
      country: "Canada",
      value: "WPC",
      display: "WPC"
    },
    {
      name: "Stewart Airport",
      city: "Stewart",
      country: "Canada",
      value: "ZST",
      display: "ZST"
    },
    {
      name: "Ignace Municipal Airport",
      city: "Ignace",
      country: "Canada",
      value: "ZUC",
      display: "ZUC"
    },
    {
      name: "Neubrandenburg Airport",
      city: "Neubrandenburg",
      country: "Germany",
      value: "FNB",
      display: "FNB"
    },
    {
      name: "RAF Kinloss",
      city: "Kinloss",
      country: "United Kingdom",
      value: "FSS",
      display: "FSS"
    },
    {
      name: "Biala Podlaska Airfield",
      city: "Biala Podlaska",
      country: "Poland",
      value: "BXP",
      display: "BXP"
    },
    {
      name: "Daugavpils Intrenational Airport",
      city: "Daugavpils",
      country: "Latvia",
      value: "DGP",
      display: "DGP"
    },
    {
      name: "Lime Acres Finsch Mine Airport",
      city: "Lime Acres",
      country: "South Africa",
      value: "LMR",
      display: "LMR"
    },
    {
      name: "Sua Pan Airport",
      city: "Sowa",
      country: "Botswana",
      value: "SXN",
      display: "SXN"
    },
    {
      name: "Sumbe Airport",
      city: "Sumbe",
      country: "Angola",
      value: "NDD",
      display: "NDD"
    },
    {
      name: "Mangochi Airport",
      city: "Mangochi",
      country: "Malawi",
      value: "MAI",
      display: "MAI"
    },
    {
      name: "Arandis Airport",
      city: "Arandis",
      country: "Namibia",
      value: "ADI",
      display: "ADI"
    },
    {
      name: "Merowe New Airport",
      city: "Merowe",
      country: "Sudan",
      value: "MWE",
      display: "MWE"
    },
    {
      name: "St Louis Regional Airport",
      city: "Alton/St Louis",
      country: "United States",
      value: "ALN",
      display: "ALN"
    },
    {
      name: "Chandler Field",
      city: "Alexandria",
      country: "United States",
      value: "AXN",
      display: "AXN"
    },
    {
      name: "Columbus Municipal Airport",
      city: "Columbus",
      country: "United States",
      value: "CLU",
      display: "CLU"
    },
    {
      name: "Curtis Field",
      city: "Brady",
      country: "United States",
      value: "BBD",
      display: "BBD"
    },
    {
      name: "Eastern Sierra Regional Airport",
      city: "Bishop",
      country: "United States",
      value: "BIH",
      display: "BIH"
    },
    {
      name: "Baker City Municipal Airport",
      city: "Baker City",
      country: "United States",
      value: "BKE",
      display: "BKE"
    },
    {
      name: "Miley Memorial Field",
      city: "Big Piney",
      country: "United States",
      value: "BPI",
      display: "BPI"
    },
    {
      name: "Ozark Regional Airport",
      city: "Mountain Home",
      country: "United States",
      value: "WMH",
      display: "WMH"
    },
    {
      name: "W K Kellogg Airport",
      city: "Battle Creek",
      country: "United States",
      value: "BTL",
      display: "BTL"
    },
    {
      name: "Burley Municipal Airport",
      city: "Burley",
      country: "United States",
      value: "BYI",
      display: "BYI"
    },
    {
      name: "Northeast Iowa Regional Airport",
      city: "Charles City",
      country: "United States",
      value: "CCY",
      display: "CCY"
    },
    {
      name: "Chanute Martin Johnson Airport",
      city: "Chanute",
      country: "United States",
      value: "CNU",
      display: "CNU"
    },
    {
      name: "Jacksonville Executive at Craig Airport",
      city: "Jacksonville",
      country: "United States",
      value: "CRG",
      display: "CRG"
    },
    {
      name: "Crossville Memorial Whitson Field",
      city: "Crossville",
      country: "United States",
      value: "CSV",
      display: "CSV"
    },
    {
      name: "Davison Army Air Field",
      city: "Fort Belvoir",
      country: "United States",
      value: "DAA",
      display: "DAA"
    },
    {
      name: "Barstow Daggett Airport",
      city: "Daggett",
      country: "United States",
      value: "DAG",
      display: "DAG"
    },
    {
      name: "Deming Municipal Airport",
      city: "Deming",
      country: "United States",
      value: "DMN",
      display: "DMN"
    },
    {
      name: "Desert Rock Airport",
      city: "Mercury",
      country: "United States",
      value: "DRA",
      display: "DRA"
    },
    {
      name: "Needles Airport",
      city: "Needles",
      country: "United States",
      value: "EED",
      display: "EED"
    },
    {
      name: "Duke Field",
      city: "Crestview",
      country: "United States",
      value: "EGI",
      display: "EGI"
    },
    {
      name: "Murray Field",
      city: "Eureka",
      country: "United States",
      value: "EKA",
      display: "EKA"
    },
    {
      name: "San Marcos Municipal Airport",
      city: "San Marcos",
      country: "United States",
      value: "HYI",
      display: "HYI"
    },
    {
      name: "Sawyer County Airport",
      city: "Hayward",
      country: "United States",
      value: "HYR",
      display: "HYR"
    },
    {
      name: "Kimble County Airport",
      city: "Junction",
      country: "United States",
      value: "JCT",
      display: "JCT"
    },
    {
      name: "Monticello Municipal Ellis Field",
      city: "Monticello",
      country: "United States",
      value: "LLQ",
      display: "LLQ"
    },
    {
      name: "Derby Field",
      city: "Lovelock",
      country: "United States",
      value: "LOL",
      display: "LOL"
    },
    {
      name: "Mobridge Municipal Airport",
      city: "Mobridge",
      country: "United States",
      value: "MBG",
      display: "MBG"
    },
    {
      name: "Mc Comb/Pike County Airport/John E Lewis Field",
      city: "Mc Comb",
      country: "United States",
      value: "MCB",
      display: "MCB"
    },
    {
      name: "Southern Illinois Airport",
      city: "Carbondale/Murphysboro",
      country: "United States",
      value: "MDH",
      display: "MDH"
    },
    {
      name: "Mc Entire Joint National Guard Base",
      city: "Eastover",
      country: "United States",
      value: "MMT",
      display: "MMT"
    },
    {
      name: "Brunswick Executive Airport",
      city: "Brunswick",
      country: "United States",
      value: "NHZ",
      display: "NHZ"
    },
    {
      name: "Naval Station Mayport (Admiral David L. Mcdonald Field)",
      city: "Mayport",
      country: "United States",
      value: "NRB",
      display: "NRB"
    },
    {
      name: "Orangeburg Municipal Airport",
      city: "Orangeburg",
      country: "United States",
      value: "OGB",
      display: "OGB"
    },
    {
      name: "Columbus Municipal Airport",
      city: "Columbus",
      country: "United States",
      value: "OLU",
      display: "OLU"
    },
    {
      name: "Ottumwa Regional Airport",
      city: "Ottumwa",
      country: "United States",
      value: "OTM",
      display: "OTM"
    },
    {
      name: "Cairns AAF (Fort Rucker) Air Field",
      city: "Fort Rucker/Ozark",
      country: "United States",
      value: "OZR",
      display: "OZR"
    },
    {
      name: "Ralph Wenz Field",
      city: "Pinedale",
      country: "United States",
      value: "PWY",
      display: "PWY"
    },
    {
      name: "Dutchess County Airport",
      city: "Poughkeepsie",
      country: "United States",
      value: "POU",
      display: "POU"
    },
    {
      name: "New Richmond Regional Airport",
      city: "New Richmond",
      country: "United States",
      value: "RNH",
      display: "RNH"
    },
    {
      name: "Russell Municipal Airport",
      city: "Russell",
      country: "United States",
      value: "RSL",
      display: "RSL"
    },
    {
      name: "Redwood Falls Municipal Airport",
      city: "Redwood Falls",
      country: "United States",
      value: "RWF",
      display: "RWF"
    },
    {
      name: "Salinas Municipal Airport",
      city: "Salinas",
      country: "United States",
      value: "SNS",
      display: "SNS"
    },
    {
      name: "Sonora Municipal Airport",
      city: "Sonora",
      country: "United States",
      value: "SOA",
      display: "SOA"
    },
    {
      name: "Saline County Regional Airport",
      city: "Benton",
      country: "United States",
      value: "SUZ",
      display: "SUZ"
    },
    {
      name: "Tonopah Airport",
      city: "Tonopah",
      country: "United States",
      value: "TPH",
      display: "TPH"
    },
    {
      name: "Aurora State Airport",
      city: "Aurora",
      country: "United States",
      value: "UAO",
      display: "UAO"
    },
    {
      name: "Ukiah Municipal Airport",
      city: "Ukiah",
      country: "United States",
      value: "UKI",
      display: "UKI"
    },
    {
      name: "University Oxford Airport",
      city: "Oxford",
      country: "United States",
      value: "UOX",
      display: "UOX"
    },
    {
      name: "Huntsville Regional Airport",
      city: "Huntsville",
      country: "United States",
      value: "HTV",
      display: "HTV"
    },
    {
      name: "Miller Field",
      city: "Valentine",
      country: "United States",
      value: "VTN",
      display: "VTN"
    },
    {
      name: "Winnemucca Municipal Airport",
      city: "Winnemucca",
      country: "United States",
      value: "WMC",
      display: "WMC"
    },
    {
      name: "West Woodward Airport",
      city: "Woodward",
      country: "United States",
      value: "WWR",
      display: "WWR"
    },
    {
      name: "Cape Canaveral AFS Skid Strip",
      city: "Cocoa Beach",
      country: "United States",
      value: "XMR",
      display: "XMR"
    },
    {
      name: "Zanesville Municipal Airport",
      city: "Zanesville",
      country: "United States",
      value: "ZZV",
      display: "ZZV"
    },
    {
      name: "Cuatro Vientos Airport",
      city: "Madrid",
      country: "Spain",
      value: "ECV",
      display: "ECV"
    },
    {
      name: "Castellon-Costa Azahar Airport",
      city: "Castellon de la Plana",
      country: "Spain",
      value: "CDT",
      display: "CDT"
    },
    {
      name: "Teruel Airport",
      city: "Teruel",
      country: "Spain",
      value: "TEV",
      display: "TEV"
    },
    {
      name: "Trento-Mattarello Airport",
      city: "Trento (TN)",
      country: "Italy",
      value: "TN0",
      display: "TN0"
    },
    {
      name: "Sarzana-Luni Air Base",
      city: "Sarzana (SP)",
      country: "Italy",
      value: "QLP",
      display: "QLP"
    },
    {
      name: "Batajnica Air Base",
      city: "Batajnica",
      country: "Serbia",
      value: "BJY",
      display: "BJY"
    },
    {
      name: "Rubelsanto Airport",
      city: "Rubelsanto",
      country: "Guatemala",
      value: "RUV",
      display: "RUV"
    },
    {
      name: "Coronel Enrique Soto Cano Air Base",
      city: "Comayagua",
      country: "Honduras",
      value: "XPL",
      display: "XPL"
    },
    {
      name: "Upala Airport",
      city: "Upala",
      country: "Costa Rica",
      value: "UPL",
      display: "UPL"
    },
    {
      name: "San Nicolas De Bari Airport",
      city: "San Nicolas",
      country: "Cuba",
      value: "QSN",
      display: "QSN"
    },
    {
      name: "San Julian Air Base",
      city: "Pinar Del Rio",
      country: "Cuba",
      value: "SNJ",
      display: "SNJ"
    },
    {
      name: "Jam Airport",
      city: "Kangan",
      country: "Iran",
      value: "KNR",
      display: "KNR"
    },
    {
      name: "Payam International Airport",
      city: "Karaj",
      country: "Iran",
      value: "PYK",
      display: "PYK"
    },
    {
      name: "Ahmed Al Jaber Air Base",
      city: "Ahmed Al Jaber AB",
      country: "Kuwait",
      value: "XIJ",
      display: "XIJ"
    },
    {
      name: "Mirpur Khas Air Base",
      city: "Mirpur Khas",
      country: "Pakistan",
      value: "MPD",
      display: "MPD"
    },
    {
      name: "Minhas Air Base",
      city: "Kamra",
      country: "Pakistan",
      value: "ATG",
      display: "ATG"
    },
    {
      name: "Mushaf Air Base",
      city: "Sargodha",
      country: "Pakistan",
      value: "SGI",
      display: "SGI"
    },
    {
      name: "Qayyarah West Airport",
      city: "Qayyarah",
      country: "Iraq",
      value: "RQW",
      display: "RQW"
    },
    {
      name: "Nenana Municipal Airport",
      city: "Nenana",
      country: "United States",
      value: "ENN",
      display: "ENN"
    },
    {
      name: "Wasilla Airport",
      city: "Wasilla",
      country: "United States",
      value: "WWA",
      display: "WWA"
    },
    {
      name: "Ie Jima Airport",
      city: "Ie",
      country: "Japan",
      value: "IEJ",
      display: "IEJ"
    },
    {
      name: "Allah Valley Airport",
      city: "Surallah",
      country: "Philippines",
      value: "AAV",
      display: "AAV"
    },
    {
      name: "Presidente Nestor Kirchner Regionsl Airport",
      city: "Villa Maria",
      country: "Argentina",
      value: "VMR",
      display: "VMR"
    },
    {
      name: "Chafei Amsei Airport",
      city: "Barretos",
      country: "Brazil",
      value: "BAT",
      display: "BAT"
    },
    {
      name: "Base de Aviacao de Taubate Airport",
      city: "Taubate",
      country: "Brazil",
      value: "QHP",
      display: "QHP"
    },
    {
      name: "Barriles Airport",
      city: "Tocopilla",
      country: "Chile",
      value: "TOQ",
      display: "TOQ"
    },
    {
      name: "Cochrane Airport",
      city: "Cochrane",
      country: "Chile",
      value: "LGR",
      display: "LGR"
    },
    {
      name: "Chanaral Airport",
      city: "Chanaral",
      country: "Chile",
      value: "CNR",
      display: "CNR"
    },
    {
      name: "Panguilemo Airport",
      city: "Talca",
      country: "Chile",
      value: "TLX",
      display: "TLX"
    },
    {
      name: "Victoria Airport",
      city: "Victoria",
      country: "Chile",
      value: "ZIC",
      display: "ZIC"
    },
    {
      name: "Las Breas Airport",
      city: "Taltal",
      country: "Chile",
      value: "TTC",
      display: "TTC"
    },
    {
      name: "Gomez Nino Apiay Air Base",
      city: "Apiay",
      country: "Colombia",
      value: "API",
      display: "API"
    },
    {
      name: "Covenas Airport",
      city: "Covenas",
      country: "Colombia",
      value: "CVE",
      display: "CVE"
    },
    {
      name: "German Olano Air Base",
      city: "La Dorada",
      country: "Colombia",
      value: "PAL",
      display: "PAL"
    },
    {
      name: "Paz De Ariporo Airport",
      city: "Paz De Ariporo",
      country: "Colombia",
      value: "PZA",
      display: "PZA"
    },
    {
      name: "Tres Esquinas Air Base",
      city: "Tres Esquinas",
      country: "Colombia",
      value: "TQS",
      display: "TQS"
    },
    {
      name: "Marandua Air Base",
      city: "Marandua",
      country: "Colombia",
      value: "MQZ",
      display: "MQZ"
    },
    {
      name: "Juan Simons Vela Airport",
      city: "Rioja",
      country: "Peru",
      value: "RIJ",
      display: "RIJ"
    },
    {
      name: "Shumba Airport",
      city: "Jaen",
      country: "Peru",
      value: "JAE",
      display: "JAE"
    },
    {
      name: "Issyk-Kul International Airport",
      city: "Tamchy",
      country: "Kyrgyzstan",
      value: "IKU",
      display: "IKU"
    },
    {
      name: "Vilyuisk Airport",
      city: "Vilyuisk",
      country: "Russia",
      value: "VYI",
      display: "VYI"
    },
    {
      name: "Maygatka Airport.",
      city: "Sovetskaya Gavan",
      country: "Russia",
      value: "GVN",
      display: "GVN"
    },
    {
      name: "Nikolayevsk-na-Amure Airport",
      city: "Nikolayevsk-na-Amure Airport",
      country: "Russia",
      value: "NLI",
      display: "NLI"
    },
    {
      name: "Inta Airport",
      city: "Inta",
      country: "Russia",
      value: "INA",
      display: "INA"
    },
    {
      name: "Zhigansk Airport",
      city: "Zhigansk",
      country: "Russia",
      value: "ZIX",
      display: "ZIX"
    },
    {
      name: "Hingurakgoda Air Force Base",
      city: "Polonnaruwa Town",
      country: "Sri Lanka",
      value: "HIM",
      display: "HIM"
    },
    {
      name: "Kazi Nazrul Islam Airport",
      city: "Durgapur",
      country: "India",
      value: "RDP",
      display: "RDP"
    },
    {
      name: "Sri Sathya Sai Airport",
      city: "Puttaparthi",
      country: "India",
      value: "PUT",
      display: "PUT"
    },
    {
      name: "Alpha Airport",
      city: "Alpha",
      country: "Australia",
      value: "ABH",
      display: "ABH"
    },
    {
      name: "West Sale Airport",
      city: "Sale",
      country: "Australia",
      value: "SXE",
      display: "SXE"
    },
    {
      name: "West Wyalong Airport",
      city: "West Wyalong",
      country: "Australia",
      value: "WWY",
      display: "WWY"
    },
    {
      name: "Guangzhou MR Air Base",
      city: "Guanghua",
      country: "China",
      value: "LHK",
      display: "LHK"
    },
    {
      name: "Shiyan Wudangshan Airport",
      city: "Shiyan",
      country: "China",
      value: "WDS",
      display: "WDS"
    },
    {
      name: "Huatugou Airport",
      city: "Mengnai",
      country: "China",
      value: "HTT",
      display: "HTT"
    },
    {
      name: "Bengbu Airport",
      city: "Bengbu",
      country: "China",
      value: "BFU",
      display: "BFU"
    },
    {
      name: "Rugao Air Base",
      city: "Rugao",
      country: "China",
      value: "RUG",
      display: "RUG"
    },
    {
      name: "Wuhu Air Base",
      city: "Wuhu",
      country: "China",
      value: "WHU",
      display: "WHU"
    },
    {
      name: "Shanshan Airport",
      city: "Shanshan",
      country: "China",
      value: "SXJ",
      display: "SXJ"
    },
    {
      name: "Yingkou Lanqi Airport",
      city: "Yingkou",
      country: "China",
      value: "YKH",
      display: "YKH"
    }
  ];
  
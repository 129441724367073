import React from 'react';
import ItinerarySingleDayCard from '@commons/ItinerarySingleDayCard/ItinerarySingleDayCard';
import { getUpdatedAndRemovedDayCardsData } from '@utils/DayPlansUtils';

function ItineraryDiffComponent({
	updatedDayPlans,
	originalDayPlans,
	gMapsData,
}) {
	const { addedDayCardsDataList, removedDayCardsDataList } =
		getUpdatedAndRemovedDayCardsData(
			updatedDayPlans,
			originalDayPlans,
			gMapsData,
		);

	return (
		<div className="flex flex-row w-full">
			<div className="flex flex-col w-1/3 px-2">
				<div className="font-medium w-full px-2 bg-primary-200 text-primary-600">
					ADDED
				</div>
				<div className="mt-2 flex flex-col space-y-2">
					{addedDayCardsDataList?.map((data, index) => (
						<ItinerarySingleDayCard
							dayCardData={data}
							index={index}
							onPlaceClicked={() => {}}
						/>
					))}
				</div>
			</div>

			<div className="flex flex-col w-1/3 px-2">
				<div className="font-medium w-full px-2 bg-primary-200 text-primary-600">
					REMOVED
				</div>
				<div className="mt-2 flex flex-col space-y-2">
					{removedDayCardsDataList?.map((data, index) => (
						<ItinerarySingleDayCard
							dayCardData={data}
							index={index}
							onPlaceClicked={() => {}}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

export default ItineraryDiffComponent;

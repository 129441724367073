import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { HotelSortCriterion } from '@helpers/Enums';
import startCase from '@utils/startCase';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';
import { useLocation } from 'react-router-dom';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';

function HotelsSortSelector() {
	const dispatch = useDispatch();
	const location = useLocation();
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const {
		hotelFilters = [],
		hotelReqs = [],
		hotelSets,
		hotelSortCriterion,
		numTravellers,
	} = tripLocationDetails;

	const handleOptionChange = (e) => {
		sendMessageEvent({
			event: GoogleEventTypes.CHANGE_HOTELS_SORT_CRITERIA,
			chat_session_id: chatSessionId,
		});

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: hotelSets,
				hotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: e.target.value,
			},
			tabIndex: activeTabIndex,
			gMapsData,
			chatSessionId,
		});
	};

	return (
		<div className="flex flex-row items-center space-x-2 text-sm gap-2">
			<div>Sort</div>

			<FormControl sx={{ minWidth: 120 }} size="small">
				<Select
					labelId="demo-select-small-label"
					id="demo-select-small"
					value={hotelSortCriterion}
					onChange={handleOptionChange}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
				>
					{Object.keys(HotelSortCriterion).map((key) => (
						<MenuItem value={HotelSortCriterion[key]}>
							{startCase(key)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}

export default HotelsSortSelector;

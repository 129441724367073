function EditPencil({ size = '22' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M13.4644 5.03674L5.25444 13.7267C4.94444 14.0567 4.64444 14.7067 4.58444 15.1567L4.21444 18.3967C4.08444 19.5667 4.92444 20.3667 6.08444 20.1667L9.30444 19.6167C9.75444 19.5367 10.3844 19.2067 10.6944 18.8667L18.9044 10.1767C20.3244 8.67674 20.9644 6.96674 18.7544 4.87674C16.5544 2.80674 14.8844 3.53674 13.4644 5.03674Z"
				stroke="#7750E5"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.0938 6.49609C12.5238 9.25609 14.7638 11.3661 17.5438 11.6461"
				stroke="#7750E5"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default EditPencil;

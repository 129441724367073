import { Slider, Box } from '@mui/material';

function DurationFilter({ durationFilter, setDurationFilter }) {
	return (
		<div className="flex flex-col gap-4">
			<div className="text-sm mt-1">
				<Box sx={{ width: 300, padding: '0 4px' }}>
					<Slider
						defaultValue={durationFilter}
						aria-label="Default"
						valueLabelDisplay="auto"
						min={0}
						max={38}
						onChange={(_, value) => {
							setDurationFilter(value);
						}}
					/>
				</Box>
			</div>
		</div>
	);
}

export default DurationFilter;

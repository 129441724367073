import FlightIcon from '@commons/icons/FlightIcon';
import TrainIcon from '@commons/icons/TrainIcon';
import CarIcon from '@commons/icons/CarIcon';
import {
	DirectionsBoatOutlined,
	DirectionsBusOutlined,
} from '@mui/icons-material';
import HailIcon from '@mui/icons-material/Hail';

export const TRANSITION_ICON_MAPPING = {
	FLIGHT: FlightIcon,
	CAR: CarIcon,
	FERRY: DirectionsBoatOutlined,
	TRAIN: TrainIcon,
	BUS: DirectionsBusOutlined,
	INTERNAL_TRASNPORT: HailIcon,
	BOAT: DirectionsBoatOutlined,
};

export const getTransportIcon = (transport) => {
	return TRANSITION_ICON_MAPPING[transport.toUpperCase()];
};

import AutoCompleteLocationSelect from '@commons/forms/page-components/components/AutoCompleteLocationSelect';
import TimeRangePicker from '@commons/forms/page-components/components/TimeRangePicker/TimeRangePicker';
import { convertTo24HrFormat } from '@helpers/DateUtils';
import { TripUpdateType } from '@helpers/Enums';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { getPlacesData } from '@helpers/ModelApiUtils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, TextField, TextareaAutosize } from '@mui/material';
import isEmpty from '@utils/isEmpty';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function AddActivity({ addActivityDetails, setAddActivityDetails = () => {} }) {
	const dispatch = useDispatch();

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const { location, index } = addActivityDetails;

	const [newActivityDetails, setNewActivityDetails] = useState({
		name: '',
		description: '',
		placeName: '',
		timeStart: '',
		timeEnd: '',
		hasNewTag: true,
		transport: '',
		type: '',
		coordinates: '',
	});

	const [startTime, setStartTime] = useState({
		minutes: '',
		hours: '',
		period: '',
	});
	const [endTime, setEndTime] = useState({
		minutes: '',
		hours: '',
		period: '',
	});

	const handleSelectLocation = async (option) => {
		if (!option) {
			return;
		}

		const { value = '' } = option || {};

		setNewActivityDetails((prev) => ({ ...prev, placeName: value }));
	};

	const onClickAddActivity = async () => {
		const finalStartTime = convertTo24HrFormat(startTime);
		const finalEndTime = convertTo24HrFormat(endTime);

		const { placeName = '', name } = newActivityDetails;

		let lat;
		let lng;

		const placesWithData = (await getPlacesData(placeName)) || {};

		const { geometry = null } = placesWithData;

		if (!isEmpty(geometry)) {
			lat = geometry?.location?.lat() || undefined;
			lng = geometry?.location?.lng() || undefined;
		}

		const activityData = {
			DESCRIPTION: newActivityDetails.description,
			NAME: name,
			PLACE_NAME: placeName,
			TIME_START: finalStartTime,
			TIME_END: finalEndTime,
			COORDINATES: lat && lng ? `${lat}, ${lng}` : undefined,
			hasNewTag: true,
		};

		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_CONFIRM_ADD_ACTIVITY,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'ADD_SINGLE_DAY_CARD',
			payload: {
				activityDetails: activityData,
				tabIndex: activeTabIndex,
				dayIndex: index,
			},
		});

		// dispatch({
		// 	type: 'UPDATE_GMAPS_DATA',
		// 	payload: {
		// 		placeName,
		// 		data: placesWithData,
		// 	},
		// });

		dispatch({
			type: 'CHECKPOINT',
			payload: TripUpdateType.SINGLE_DAY_CARD_UPDATED,
		});

		setAddActivityDetails({});
	};

	const buttonDisabled =
		!newActivityDetails.name ||
		!newActivityDetails.placeName ||
		!startTime.minutes ||
		!startTime.hours ||
		!startTime.period ||
		!endTime.minutes ||
		!endTime.hours ||
		!endTime.period;

	return (
		<div className="flex flex-col" style={{ height: '100vh' }}>
			<div
				className="flex gap-2 items-center cursor-pointer text-2xl py-4 px-6"
				style={{
					borderBottom: '1px solid #E1DEE3',
				}}
				role="presentation"
				onClick={() => setAddActivityDetails({})}
			>
				<ArrowBackIosIcon fontSize="small" />

				<div style={{ color: '#36174D', fontWeight: '600' }}>Add Activity</div>
			</div>

			<div
				className="px-6 py-6 flex flex-col justify-between"
				style={{ height: 'calc(100vh - 66px)' }}
			>
				<div className="flex flex-col gap-6">
					<div className="flex justify-between">
						<div className="font-semibold" style={{ color: '#DC1F90' }}>
							Day {index + 1}
						</div>

						<div style={{ color: '#301345' }}>{location}</div>
					</div>

					<div className="text-xl">Add New</div>

					<div className="flex flex-col gap-1 text-sm">
						<div style={{ color: '#7F6D8B' }}>Activity Title</div>

						<TextField
							size="small"
							value={newActivityDetails.name}
							onChange={(e) =>
								setNewActivityDetails((prev) => ({
									...prev,
									name: e.target.value,
								}))
							}
						/>
					</div>

					<div className="flex flex-col gap-2 my-1">
						<div style={{ color: '#7F6D8B' }} className="text-sm">
							Location
						</div>

						<AutoCompleteLocationSelect
							handleSelect={handleSelectLocation}
							value={newActivityDetails.placeName}
						/>
					</div>

					<div className="flex flex-col gap-1 text-sm">
						<div style={{ color: '#7F6D8B' }}>Description</div>

						<TextareaAutosize
							value={newActivityDetails.description}
							onChange={(e) =>
								setNewActivityDetails((prev) => ({
									...prev,
									description: e.target.value,
								}))
							}
							minRows={2}
							maxRows={4}
							placeholder="Activity Description"
							className="w-full p-2 overflow-y-auto focus:outline-none border rounded-lg flex-grow"
						/>
					</div>

					<TimeRangePicker
						setEndTime={setEndTime}
						startTime={startTime}
						setStartTime={setStartTime}
						endTime={endTime}
					/>
				</div>

				<Button
					variant="contained"
					type="button"
					style={{
						textTransform: 'unset',
						fontSize: '16px',
						background: buttonDisabled
							? '#E1DEE3'
							: 'linear-gradient(270deg, #DF74B1 0.36%, #7E6DF1 99.64%',
						borderRadius: '32px',
					}}
					disabled={buttonDisabled}
					onClick={onClickAddActivity}
				>
					Add to Trip
				</Button>
			</div>
		</div>
	);
}

export default AddActivity;

import ImageSlider from '@commons/ImageSlider';
import LocationIcon from '@commons/icons/LocationIcon';
import ClockIcon from '@commons/icons/ClockIcon';
import { useEffect, useState } from 'react';
import { getTimeString } from '@utils/DateTimeUtils';
import CrossIcon from '@commons/icons/CrossIcon';
import { Button } from '@mui/material';
import AISparkIconPlain from '@commons/icons/AISparkIconPlain';
import { useDispatch, useSelector } from 'react-redux';
import { TripUpdateType } from '@helpers/Enums';
import TimeRangePicker from '@commons/forms/page-components/components/TimeRangePicker/TimeRangePicker';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import isEmpty from '@utils/isEmpty';
import styles from './styles.module.css';
import DirectionsOverview from './DirectionsOverview/DirectionsOverview';

function convertTo12HrFormat(hour) {
	let period = 'AM';
	let hour12 = hour;

	if (hour === 0) {
		hour12 = 12;
	} else if (hour === 12) {
		period = 'PM';
	} else if (hour > 12) {
		hour12 = hour - 12;
		period = 'PM';
	}

	return {
		hours: hour12,
		period,
	};
}

function convertTo24HrFormat({ minutes, hours, period }) {
	let Finalhours = Number(hours);

	if (period === 'AM') {
		if (hours === 12) {
			Finalhours = 0; // Midnight case (12 AM)
		}
	} else if (period === 'PM') {
		if (hours !== 12) {
			Finalhours += 12; // Afternoon/evening case (1 PM to 11 PM)
		}
	}

	return `${Finalhours}:${minutes}`;
}

function checkImage(url) {
	return new Promise((resolve) => {
		const img = new Image();
		img.onload = () => resolve(true);
		img.onerror = () => resolve(false);
		img.src = url;
	});
}

function SingleItineraryDetails({
	// TODO: Need to integrate the edit activity
	dayCardData,
	gMapsData = {},
	onAttachActivity = () => {},
	onClose = () => {},
	dayIndex,
	index,
	TransitionIcon,
}) {
	const dispatch = useDispatch();

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const {
		timeStart = null,
		timeEnd = null,
		description = '',
		name = '',
		routeInfo = null,
	} = dayCardData;

	const isTransportCard = !!TransitionIcon;

	const [timeStartHours, timeStartMins] = timeStart.split(':');
	const [timeEndHours, timeEndMins] = timeEnd.split(':');

	const [hotelImages, setHotelImages] = useState(null);
	const [removeActivity, setRemoveActivity] = useState(false);
	const [editActivity, setEditActivity] = useState(false);
	const [startTime, setStartTime] = useState({
		...convertTo12HrFormat(timeStartHours),
		minutes: timeStartMins,
	});
	const [endTime, setEndTime] = useState({
		...convertTo12HrFormat(timeEndHours),
		minutes: timeEndMins,
	});

	const handleUpdateTime = () => {
		const finalStartTime = convertTo24HrFormat(startTime);
		const finalEndTime = convertTo24HrFormat(endTime);

		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_UPDATE_ACTIVITY,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'UPDATE_SINGLE_DAY_CARD',
			payload: {
				updateDetails: {
					TIME_START: finalStartTime,
					TIME_END: finalEndTime,
				},
				tabIndex: activeTabIndex,
				dayIndex,
				cardIndex: index,
			},
		});

		dispatch({
			type: 'CHECKPOINT',
			payload: TripUpdateType.SINGLE_DAY_CARD_UPDATED,
		});

		onClose();
	};

	const handleRemoveActivity = () => {
		sendMessageEvent({
			event: GoogleEventTypes.ITINERARY_REMOVE_ACTIVITY,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'DELETE_SINGLE_DAY_CARD',
			payload: {
				tabIndex: activeTabIndex,
				dayIndex,
				cardIndex: index,
			},
		});

		dispatch({
			type: 'CHECKPOINT',
			payload: TripUpdateType.SINGLE_DAY_CARD_UPDATED,
		});

		onClose();
	};

	const { photos_data = [], full_address = '' } = gMapsData || {};

	useEffect(() => {
		if (isEmpty(photos_data)) {
			return;
		}

		const dataPromises = photos_data
			.filter((item) => item)
			.map(
				async (
					{ original_photo_url, photo_url_big, photo_id, photo_url },
					imgIndex,
				) => {
					const alt = `photo${imgIndex + 1}`;

					const value1 = await checkImage(photo_url_big).then((loaded) => ({
						src: photo_url_big,
						alt,
						loaded,
					}));

					if (value1.loaded) {
						return value1;
					}

					const value2 = await checkImage(original_photo_url || photo_url).then(
						(loaded) => ({
							src: original_photo_url || photo_url,
							alt,
							loaded,
						}),
					);

					if (value2.loaded) {
						return value2;
					}

					const value3 = await checkImage(photo_id).then((loaded) => ({
						src: photo_id,
						alt,
						loaded,
					}));

					return value3;
				},
			);

		Promise.all(dataPromises).then((data) => {
			const filteredData = data.filter((photo) => photo.loaded);
			setHotelImages(filteredData);
		});
	}, [photos_data]);

	const isImagesLoaded = !!hotelImages || isTransportCard;

	const isImagesEmpty =
		(!!hotelImages && isEmpty(hotelImages)) || isTransportCard;

	return (
		<div className="flex flex-col px-5 py-4 gap-5" style={{ color: '#301345' }}>
			<ImageSlider
				data={hotelImages || []}
				width="100%"
				height="184px"
				isImagesLoaded={isImagesLoaded}
				isImagesEmpty={isImagesEmpty}
				TransitionIcon={TransitionIcon}
				isTransportCard={isTransportCard}
			/>

			<div
				className="font-semibold text-xl"
				style={{ borderBottom: '1px solid #36174d28', paddingBottom: '16px' }}
			>
				{name}
			</div>

			{full_address ? (
				<div
					className="flex gap-2 items-center"
					style={{ borderBottom: '1px solid #36174d28', paddingBottom: '20px' }}
				>
					<LocationIcon stroke="#7750E5" width="18" height="18" />

					<div className="text-sm">{full_address}</div>
				</div>
			) : null}

			<div
				className="flex justify-between items-center"
				style={{ borderBottom: '1px solid #36174d28', paddingBottom: '20px' }}
			>
				<div className="flex gap-2 items-center font-medium">
					<ClockIcon stroke="#7750E5" width="18" height="18" />

					<div className="text-sm" style={{ color: '#7750E5' }}>
						{getTimeString({ timeStart, timeEnd })}
					</div>
				</div>

				<div
					className="flex items-center cursor-pointer text-sm min-w-[73px]"
					style={{ color: '#7750E5', gap: '6px' }}
					role="presentation"
					onClick={() => setEditActivity((prev) => !prev)}
				>
					{editActivity ? 'Cancel' : 'Change Time'}
				</div>
			</div>

			{editActivity ? (
				<div
					className="flex flex-col gap-6 pb-5"
					style={{ borderBottom: '1px solid #36174d28' }}
				>
					<TimeRangePicker
						setEndTime={setEndTime}
						startTime={startTime}
						setStartTime={setStartTime}
						endTime={endTime}
					/>

					<Button
						className={styles.remove_button}
						style={{ padding: '6px' }}
						onClick={handleUpdateTime}
					>
						Update Time
					</Button>
				</div>
			) : null}

			<div className="overflow-hidden">
				<div
					className={`${styles.description_controls} ${removeActivity && styles.hidden}`}
				>
					<div className="text-sm">{description}</div>

					<DirectionsOverview routeInfo={routeInfo} />

					<div className="mt-4 flex flex-col gap-4 items-start m-auto w-full">
						<div
							className="flex items-center cursor-pointer mt-2 gap-2 min-w-[73px] font-semibold"
							style={{ color: '#6C577A' }}
							onClick={() => setRemoveActivity(true)}
							role="presentation"
						>
							<CrossIcon />
							Remove Activity
						</div>

						<div
							className="flex items-center cursor-pointer mt-2 gap-2 min-w-[73px] font-semibold"
							style={{ color: '#6C577A' }}
							role="presentation"
							onClick={() => {
								onClose();
								onAttachActivity();
							}}
						>
							<AISparkIconPlain /> Ask AI
						</div>
					</div>
				</div>

				<div
					className={`${styles.remove_component} ${removeActivity && styles.show}`}
				>
					<div className="font-semibold">Remove Activity</div>

					<div>Are you sure you want to remove this activity?</div>

					<div className="flex flex-col gap-4 items-center">
						<Button
							className={styles.remove_button}
							onClick={handleRemoveActivity}
						>
							Yes, Remove
						</Button>

						<div
							className="cursor-pointer"
							style={{ color: '#7750E5' }}
							role="presentation"
							onClick={() => setRemoveActivity(false)}
						>
							Cancel
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SingleItineraryDetails;

function LeftIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
		>
			<path
				d="M13.6667 16.334L8.33334 11.0007"
				stroke="#7750E5"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M13.6667 5.66699L8.33334 11.0003"
				stroke="#7750E5"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default LeftIcon;

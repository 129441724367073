function CalenderIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="14"
			viewBox="0 0 12 14"
			fill="none"
		>
			<path
				d="M3.6001 1V2.8"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.3999 1V2.8"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M0.899902 5.25391H11.0999"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.4001 4.90039V10.0004C11.4001 11.8004 10.5001 13.0004 8.4001 13.0004H3.6001C1.5001 13.0004 0.600098 11.8004 0.600098 10.0004V4.90039C0.600098 3.10039 1.5001 1.90039 3.6001 1.90039H8.4001C10.5001 1.90039 11.4001 3.10039 11.4001 4.90039Z"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.21687 8.01973H8.22226"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.21687 9.82051H8.22226"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.99739 8.01973H6.00278"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.99739 9.82051H6.00278"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.77668 8.01973H3.78207"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.77668 9.82051H3.78207"
				stroke="#574068"
				strokeWidth="0.675"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default CalenderIcon;

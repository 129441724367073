import { useState } from 'react';
import TravelDates from '@commons/TravelDates/TravelDates';
import { get_id } from '@helpers/TooltipHelper';
import { Tooltip } from 'react-tooltip';
import Button from '@mui/material/Button';
import { formatDates } from '@helpers/DateUtils';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
	getTotalPriceObject,
	handleFindHotels,
	handleSelectHotels,
} from '@helpers/hotelsQueryHelper';
import { LoadingState, MainTripPageView } from '@helpers/Enums';
import hotelDateSetHelper from '@helpers/hotelDateSetHelper';
import { useLocation } from 'react-router-dom';
import { fetchDayPlansAndDispatch } from '@helpers/dayPlansQueryHelper';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import TransitionOverviewWidget from './TransitionOverview/TransitionOverviewWidget';
import ResultsOverview from './OverallResults/ResultsOverview';
import Flights from './Flights/Flights';
import DayPlans from './DayPlans/DayPlans';
import styles from './styles.module.css';
import HotelsV2 from './HotelsV2/HotelsV2';
import Hotels from './Hotels/Hotels';

const MAPPING = {
	[MainTripPageView.OVERVIEW]: ResultsOverview,
	[MainTripPageView.FLIGHTS]: Flights,
	[MainTripPageView.HOTELS]: Hotels,
	[MainTripPageView.ITINERARY]: DayPlans,
};

function OverviewPage({ setShowBookNowScreen = () => {} }) {
	const dispatch = useDispatch();
	const location = useLocation();

	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const currentView = useSelector(
		(state) => state.ui_states.mainTripPageView ?? MainTripPageView.OVERVIEW,
	);
	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const hotelResults = useSelector((state) => state.ui_states.hotel_results);
	const dayPlans = useSelector((state) => state.ui_states.day_plans);

	const loadingState = useSelector((state) => state.ui_states.loadingState);
	const { itineraryLoading = LoadingState.INIT } = loadingState;

	const selectedFlightByDateOption = useSelector(
		(state) => state.ui_states.selectedFlightByDateOption,
	);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const selectedHotels = useSelector((state) => state.ui_states.selectedHotels);

	const { travelSets = [] } = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		hotelFilters = [],
		hotelReqs = [],
		numTravellers,
	} = tripLocationDetails;

	const [tabChanged, setTabChanged] = useState(false);

	// TODO: No need to pass this function around everywhere, now that currentView is added to Redux.
	const setCurrentView = (value) => {
		dispatch({
			type: 'UPDATE_MAIN_TRIP_PAGE_VIEW',
			payload: value,
		});
	};

	const handleTabClick = (index) => {
		sendMessageEvent({
			event: GoogleEventTypes.CHANGE_DATE_OPTION,
			chat_session_id: chatSessionId,
		});

		dispatch({ type: 'UPDATE_ACTIVE_TAB_INDEX', payload: index });

		const myDiv = document.getElementById('main-div'); // scrolling to top on date change

		myDiv.scrollTop = 0;

		if (!hotelResults[index] || !dayPlans[index]) {
			setTabChanged(true);
			return;
		}

		setTabChanged(false);

		const hotelDateSets = hotelDateSetHelper(
			places,
			hotelReqs,
			selectedFlightByDateOption[index]?.legs || [],
			travelSets[index],
		);

		handleSelectHotels({
			response: hotelResults[index],
			reduxStates: { hotelSets: [hotelDateSets] },
			hotelRequirement: hotelReqs,
			dispatch,
		});
	};

	const handleUpdateHotelsAndItinerary = () => {
		setTabChanged(false);

		sendMessageEvent({
			event: GoogleEventTypes.UPDATE_HOTELS_AND_ITINERARY_FOR_DATE_OPTION,
			chat_session_id: chatSessionId,
		});

		if (!hotelResults[active_tab_index]) {
			const hotelDateSets = hotelDateSetHelper(
				places,
				hotelReqs,
				selectedFlightByDateOption[active_tab_index]?.legs || [],
				travelSets[active_tab_index],
			);

			handleFindHotels({
				dispatch,
				location,
				hotel_set_data: {
					hotel_datesets: [hotelDateSets],
					hotelFilters,
					NUM_TRAVELLERS: numTravellers,
					HOTEL_REQUIREMENT: hotelReqs,
					sortCriterion: hotelSortCriterion,
				},
				tabIndex: active_tab_index,
				gMapsData,
				chatSessionId,
			});
		}

		if (!dayPlans[active_tab_index]) {
			const dayPlansQuery = {
				locations: {
					startLocation,
					endLocation,
					places,
				},
				chat_history: llmInteractionHistory,
			};

			fetchDayPlansAndDispatch(
				dispatch,
				{
					...dayPlansQuery,
					travelSets: travelSets[active_tab_index],
					flightSelections: (
						selectedFlightByDateOption[active_tab_index]?.legs || []
					).map(
						({
							arrivalDate,
							arrivalTime,
							destination,
							source,
							departureTime,
							departureDate,
						}) => ({
							fromAirport: source,
							toAirport: destination,
							departureDate,
							departureTime,
							arrivalDate,
							arrivalTime,
						}),
					),
				},
				chatSessionId,
				active_tab_index,
			);
		}
	};

	const travelDates = travelSets.map((travelSet) => {
		return travelSet.reduce(
			(acc, curr) => {
				let dateOfJourney;
				if (['FLIGHT', 'flight'].includes(curr.type) && curr.flightDetails) {
					dateOfJourney = curr.flightDetails.dateOfJourney;
				} else if (curr.nonFlightDetails) {
					dateOfJourney = curr.nonFlightDetails.dateOfJourney;
				}

				if (!dateOfJourney) return acc;

				if (!acc.startDate || dayjs(dateOfJourney).isBefore(acc.startDate)) {
					acc.startDate = dateOfJourney;
				}
				if (!acc.endDate || dayjs(dateOfJourney).isAfter(acc.endDate)) {
					acc.endDate = dateOfJourney;
				}
				return acc;
			},
			{ startDate: null, endDate: null },
		);
	});

	const travelDatesInString = travelDates.map(({ startDate, endDate }) =>
		formatDates(startDate, endDate, true),
	);

	const filteredSelectedHotels = selectedHotels.filter(
		(hotelData) => !!Object.keys(hotelData || {}).length,
	);
	const totalHotelPriceObject = getTotalPriceObject(filteredSelectedHotels);
	const totalHotelPrice = totalHotelPriceObject.price;

	const flightPricePerPerson =
		(selectedFlightByDateOption[active_tab_index] ?? {})?.totalPrice ?? 0;

	const ActiveComponent = MAPPING[currentView];
	const overflowClass = ['itinerary'].includes(currentView)
		? 'overflow-hidden'
		: 'overflow-auto';

	return (
		<div className="flex flex-col flex-1 overflow-hidden h-full">
			<TransitionOverviewWidget />

			<TravelDates
				travelDatesInString={travelDatesInString}
				handleTabClick={handleTabClick}
				activeTabIndex={active_tab_index}
			/>

			<div
				className={`flex flex-1 flex-col ${overflowClass} px-6 pt-2`}
				id="main-div"
			>
				<ActiveComponent
					setCurrentView={setCurrentView}
					tabChanged={tabChanged}
					handleUpdateHotelsAndItinerary={handleUpdateHotelsAndItinerary}
				/>
			</div>

			{itineraryLoading === LoadingState.LOADED ? (
				<div
					style={{
						width: '100%',
						boxShadow: '0px -9px 34px 0px rgba(0, 0, 0, 0.08)',
						zIndex: '9',
						padding: '12px 24px',
					}}
					className="flex gap-4 justify-between bg-white items-center mt-1"
				>
					<div className="flex gap-2 items-center font-semibold text-2xl">
						<div style={{ color: '#301345' }}>Entire trip price :</div>
						<div
							className="flex gap-2 items-end"
							style={{ color: '#7750E5', fontWeight: '500' }}
						>
							<div>
								{' '}
								{`$${parseInt(totalHotelPrice / numTravellers + flightPricePerPerson)}`}{' '}
							</div>
							<div className="text-sm pb-[3px]">/person</div>
						</div>
					</div>

					<Button
						variant="outlined"
						type="button"
						className={styles.book_button}
						onClick={() => setShowBookNowScreen(true)}
					>
						Book
					</Button>
				</div>
			) : null}

			<Tooltip id={get_id()} />
		</div>
	);
}

export default OverviewPage;

import { Skeleton } from '@mui/material';

function ItineraryLoadingComponent({ length = 3 }) {
	return (
		<div className="flex gap-3 flex-col mt-3">
			{[...Array(length)].map((number) => {
				return (
					<Skeleton
						key={number}
						variant="rectangular"
						height={100}
						style={{ borderRadius: '8px' }}
					/>
				);
			})}
		</div>
	);
}

export default ItineraryLoadingComponent;

import Filter from './Filter';
import FlightsSortSelector from './FlightsSortSelector';

import './DateTabs.css';

function FiltersSection({ filtersData = [], tabsData = {} }) {
	return (
		<div className="flex flex-row w-full justify-between mb-2 items-center">
			<div className="tabs-container">
				<div className="tabs-scrollable">
					<div className="flex flex-row space-x-2 items-center">
						<div className="text-sm">Filters</div>
						{filtersData.map((filterName) => (
							<Filter filterName={filterName} />
						))}
					</div>
				</div>
			</div>

			<div className="self-end">
				<FlightsSortSelector tabsData={tabsData} />
			</div>
		</div>
	);
}

export default FiltersSection;

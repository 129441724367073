import { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function SignUpPage() {
    const [areSlotsAvailable, setAreSlotsAvailable] = useState(null);
    const navigate = useNavigate();

    const getAreSlotsAvailable = async () => {
        const res = await axios.get('/api/get_are_slots_available');
        setAreSlotsAvailable(res?.data?.are_slots_available);
    }

    useEffect(() => {
        getAreSlotsAvailable();
    }, []);

    const slotsAvailableUx = <div className='text-center'>
        <div className={styles.slots_available_text}>{"Slots Available"}</div>
        <div className={styles.slots_subtext}>{"Congratulations, you can get instant access to the future of travel booking!"}</div>
        <div className={styles.slots_subtext}>{"AiRial is in closed beta. Only a few slots open up every week. Grab yours now!"}</div>
    </div>;
    const slotsUnavailableUx = <div className='text-center'>
        <div className={styles.slots_unavailable_text}>{"No Slots Available"}</div>
        <div className={styles.slots_subtext}>{"Apologies, there are no slots available right now. Please join the wailist!"}</div>
        <div className={styles.slots_subtext}>{"AiRial is in closed beta. New slots open every Wednesday for those on the waitlist."}</div>
    </div>;
    return (
        <div className={styles.main_component}>
            {areSlotsAvailable == null ? null : <>
                {areSlotsAvailable ? slotsAvailableUx : slotsUnavailableUx}
                <Button
                    variant="contained"
                    className={styles.submit_button}
                    type="button"
                    onClick={() => {
                        if (areSlotsAvailable) {
                            navigate(`/signup-form`);
                        } else {
                            window.open('https://www.coinvent.ai/waitlist');
                        }
                    }}
                >
                    {areSlotsAvailable ? 'Sign Up Now' : 'Join the Waitlist'}
                </Button>
            </>}
        </div>
    );
}
function LocationIcon({ stroke = '', width, height }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '10'}
			height={height || '14'}
			viewBox="0 0 10 14"
			fill="none"
		>
			<path
				d="M9.13491 5.12523C9.13491 2.84023 7.28491 0.990234 4.99991 0.990234C2.71491 0.990234 0.864913 2.84023 0.864913 5.12523C0.864913 5.12523 0.654912 9.26023 4.99991 13.0102C9.34491 9.26023 9.13491 5.12523 9.13491 5.12523Z"
				stroke={stroke || '#574068'}
				strokeWidth="0.75"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.00516 6.84039C5.88605 6.84039 6.60016 6.12628 6.60016 5.24539C6.60016 4.3645 5.88605 3.65039 5.00516 3.65039C4.12426 3.65039 3.41016 4.3645 3.41016 5.24539C3.41016 6.12628 4.12426 6.84039 5.00516 6.84039Z"
				stroke={stroke || '#574068'}
				strokeWidth="0.75"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default LocationIcon;

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import { useSelector } from 'react-redux';

function TravellersDetails({
	numTravellers,
	setNumTravellers,
	setTripModificationDetails = () => {},
}) {
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	return (
		<div
			style={{ border: '1px solid #E1DEE3', color: '##301345' }}
			className="p-4 flex flex-col gap-4 rounded-lg"
		>
			<div className="font-semibold">Travellers’ Details</div>

			<div className="flex justify-between items-center">
				<div className="font-normal">Adults</div>

				<div
					style={{ border: '1px solid #F0ECFC', padding: '6px 12px' }}
					className="rounded-lg flex gap-5 items-center"
				>
					<RemoveIcon
						style={{ color: '#7750E5', cursor: 'pointer' }}
						fontSize="small"
						onClick={() => {
							if (numTravellers <= 1) {
								return;
							}

							sendMessageEvent({
								event: GoogleEventTypes.TRIP_CONTROLS_DECREASE_TRAVELLERS,
								chat_session_id: chatSessionId,
							});

							setNumTravellers((prev) => {
								if (prev === 1) {
									return prev;
								}

								return prev - 1;
							});

							setTripModificationDetails((prev) => ({
								...prev,
								modified: true,
								transportChanged: true,
							}));
						}}
					/>

					{numTravellers}

					<AddIcon
						style={{ color: '#7750E5', cursor: 'pointer' }}
						fontSize="small"
						onClick={() => {
							sendMessageEvent({
								event: GoogleEventTypes.TRIP_CONTROLS_INCREASE_TRAVELLERS,
								chat_session_id: chatSessionId,
							});

							setNumTravellers((prev) => prev + 1);
							setTripModificationDetails((prev) => ({
								...prev,
								modified: true,
								transportChanged: true,
							}));
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default TravellersDetails;

import { Button } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';

function InfoBannerContent({
	mainText = '',
	subText = '',
	title = '',
	handleClose = () => {},
	popoverName = '',
}) {
	const dispatch = useDispatch();

	const showUserEducationPopover =
		useSelector(
			(state) => state.profile?.user_preferences?.show_user_education_popover,
		) || {};

	const onClickDontShowAgain = async () => {
		await axios.post('/auth/update_user_data', {
			user_preferences: {
				show_user_education_popover: {
					...showUserEducationPopover,
					[popoverName]: false,
				},
			},
		});

		const res = await axios.get('/auth/get_user_session');

		dispatch({
			type: 'UPDATE_PROFILE_REDUCER',
			payload: res?.data,
		});

		handleClose();
	};

	const BUTTONS = [
		{
			label: "Don't show again",
			name: 'button-1',
			size: 'small',
			onClick: onClickDontShowAgain,
		},
		{
			label: 'Okay',
			name: 'button-2',
			variant: 'contained',
			size: 'small',
			onClick: handleClose,
		},
	];

	return (
		<div className={styles.container}>
			<div className={styles.heading}>{title}</div>
			<div className={styles.content}>{mainText}</div>
			{subText ? <div className={styles.content}>{subText}</div> : null}

			<div className={styles.button_container}>
				{BUTTONS.map((item) => {
					const { label, name, ...restProps } = item;

					return (
						<Button
							key={name}
							{...restProps}
							style={{ textTransform: 'unset' }}
						>
							{label}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default InfoBannerContent;

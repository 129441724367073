import { useDispatch, useSelector } from 'react-redux';
import { Flight } from '@mui/icons-material';
import startCase from '@utils/startCase';
import { getTransportIcon } from '@utils/IconUtils';
import styles from './styles.module.css';

function TransitionOverviewWidget({ view = '' }) {
	const dispatch = useDispatch();
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);
	const isHeaderAnimationCompleted = useSelector(
		(state) => state.ui_states.isHeaderAnimationCompleted,
	);
	const initialAnimationCompleted = useSelector(
		(state) => state.ui_states.initialAnimationCompleted,
	);

	const { transitionReqs } = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
	} = tripLocationDetails;

	const transitionIcons = transitionReqs.map(
		(x) => getTransportIcon(x.toUpperCase()) || Flight,
	);
	const finalData = [
		{ location: startLocation, id: 'place', type: 'start' },
		...places
			.filter((place) => ![startLocation, endLocation].includes(place))
			.map((place) => ({ location: place, id: 'place', type: 'stop' })),
		{ location: endLocation, id: 'place', type: 'end' },
	].reduce((acc, cur) => {
		const { type = '' } = cur;
		if (['start', 'end'].includes(type)) {
			return [...acc, { ...cur, text: startCase(type) }];
		}
		return [...acc, { ...cur, text: null }];
	}, []);

	const onFinalAnimationEnd = () => {
		dispatch({
			type: 'UPDATE_HEADER_ANIMATION',
			payload: true,
		});

		setTimeout(() => {
			dispatch({
				type: 'INITIAL_ANIMATION_COMPLETED',
				payload: true,
			});
		}, 500);
	};

	const handleAnimationEnd = (event) => {
		if (event.target === event.currentTarget) {
			onFinalAnimationEnd();
		}
	};

	if (initialAnimationCompleted) {
		return null;
	}

	return (
		<div
			className={`${styles.container} ${isHeaderAnimationCompleted && styles.hide}`}
			style={{
				backgroundColor: '#F7F6FC',
				width: '100%',
				borderRadius: view !== 'book_now' ? '12px' : '0',
				padding: view !== 'book_now' ? '8px' : '12px',
			}}
		>
			{finalData.map(({ location, text }, index) => {
				const ActiveIcon = transitionIcons[index - 1] || Flight;

				return (
					<div key={`${location}_${index}`} className={styles.card}>
						{index ? (
							<div
								className={styles.icon}
								style={{ animationDelay: `${index * 2}s` }}
							>
								<ActiveIcon stroke="#DC1F90" />
							</div>
						) : null}

						<div
							className={styles.location_card}
							style={{ animationDelay: `${index * 2}s` }}
							onAnimationEnd={
								index === finalData.length - 1 ? handleAnimationEnd : undefined
							}
						>
							<div
								className="text-sm font-semibold"
								style={{ color: '#301345', whiteSpace: 'nowrap' }}
							>
								{location.split(',')[0]}
							</div>
							<div
								style={{
									fontSize: '10px',
									color: text === null ? '#fff' : '#9789a2',
									fontWeight: 400,
								}}
							>
								{text === null ? ';)' : text}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default TransitionOverviewWidget;

/* eslint-disable default-param-last */
import locationsReducer from './locationsReducer';
import profileReducer from './profileReducer';
import transitionsReducer from './transitionsReducer';
import uiReducer from './uiReducer';

const initialState = {
	ui_states: uiReducer(undefined, {}),
	tripTransitions: transitionsReducer(undefined, {}),
	tripLocationDetails: locationsReducer(undefined, {}),
	profile: profileReducer(undefined, {}),
};

const rootReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ENTIRE_STATE':
			return action.payload;
		default:
			const { ui_states, tripTransitions, tripLocationDetails, profile } =
				state;

			return {
				ui_states: uiReducer(ui_states, action),
				tripTransitions: transitionsReducer(tripTransitions, action),
				tripLocationDetails: locationsReducer(tripLocationDetails, action),
				profile: profileReducer(profile, action),
			};
	}
};

export default rootReducer;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collapsible from 'react-collapsible';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { LoadingState } from '@helpers/Enums';
import SearchIcon from '@mui/icons-material/Search';
// import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CollapsibleFlightFormShimmerComponent from '@commons/CollapsibleFlightFormShimmerComponent';
// import AISparkFilled from '@commons/icons/AISparkFilled';
import FlightForm from './forms/FlightForm';
import { TripType, TripClass } from './forms/TripOptionsBar';

export default function CollapsibleFlightForm() {
	const dispatch = useDispatch();

	const tripType = useSelector((state) => state.tripTransitions.tripType);
	const tripClass = useSelector((state) => state.tripTransitions.tripClass);
	const travelSets = useSelector((state) => state.tripTransitions.travelSets);
	const numTravellers = useSelector(
		(state) => state.tripLocationDetails.numTravellers,
	);
	const loadingState = useSelector((state) => state.ui_states.loadingState);
	const { flightsLoading = LoadingState.INIT } = loadingState;
	const isCollapsibleOpen = useSelector(
		(state) => state.ui_states.flightFormCollapisbleOpen,
	);
	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const handleToggleCollapsibleV2 = () => {
		dispatch({ type: 'TOGGLE_FLIGHT_FORM_COLLAPSIBLE' });
	};

	const [flightFormState, setFlightFormState] = useState({
		modified: false,
		numTravellers,
		tripClass,
		travelSets,
	});

	const flightSets = flightFormState.travelSets.map((travelSet) => {
		return travelSet.reduce((acc, { type, flightDetails = {} }) => {
			if (['flight', 'FLIGHT'].includes(type)) {
				return [
					...acc,
					{
						DATE_OF_JOURNEY: flightDetails?.dateOfJourney,
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}

			return acc;
		}, []);
	});

	const getTitle = () => {
		const flightSet = flightSets[activeTabIndex];
		const first_flight = flightSet[0];
		const last_flight = flightSet[flightSet.length - 1];
		if (tripType === 'ONE_WAY' || tripType === 'ROUND_TRIP') {
			return `${first_flight.FROM_AIRPORT} to ${first_flight.TO_AIRPORT}`;
		}
		return `${first_flight.FROM_AIRPORT} to ${
			last_flight.TO_AIRPORT
		}${tripType === 'MULTI_CITY' ? ` (${flightSet.length} flights)` : ''}`;
	};

	const getRouteString = () => {
		return getTitle().replace(/,([^ ])/g, ', $1');
	};

	if (flightsLoading === LoadingState.LOADING) {
		return (
			<div className="mb-6">
				<CollapsibleFlightFormShimmerComponent />
			</div>
		);
	}

	return (
		<Collapsible
			contentInnerClassName="bg-white"
			open={isCollapsibleOpen}
			trigger={
				<div
					role="presentation"
					className="flex justify-between rounded-lg text-sm mb-4 p-4 gap-3 w-full items-center"
					onClick={() => handleToggleCollapsibleV2()}
					style={{ backgroundColor: '#FAFAFA', width: '100%' }}
				>
					<div
						style={{ border: '1px solid #E3DAFA', color: '#301345' }}
						className="bg-white p-2 rounded-lg flex justify-between w-full items-center"
					>
						<div className="flex gap-2">
							<SearchIcon fontSize="small" />

							<div>
								<strong>{TripType[tripType]}: </strong> {getRouteString()}
							</div>
						</div>

						<div className="flex gap-3 items-center">
							<div>
								{flightFormState.numTravellers} Adult
								{flightFormState.numTravellers > 1 ? 's' : ''} |
								<strong style={{ marginLeft: '4px' }}>
									{TripClass[flightFormState.tripClass]}
								</strong>
							</div>

							{isCollapsibleOpen ? (
								<ExpandLessIcon fontSize="small" style={{ color: '#7750E5' }} />
							) : (
								<ExpandMoreIcon fontSize="small" style={{ color: '#7750E5' }} />
							)}
						</div>
					</div>

					{/* <ModeEditOutlinedIcon /> */}

					{/* <AISparkFilled /> */}
				</div>
			}
		>
			<FlightForm
				flightSets={flightSets}
				flightFormState={flightFormState}
				setFlightFormState={setFlightFormState}
				textColor="black"
			/>
		</Collapsible>
	);
}

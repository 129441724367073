import styles from './styles.module.css';

function MobileWarning() {
	return (
		<div className={styles.mobile_warning}>
			Please use a desktop device to access this website.
		</div>
	);
}

export default MobileWarning;

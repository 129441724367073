import airportsMap from '@helpers/TooltipHelper';
import TooltipWrapper from '@helpers/TooltipWrapper';
import Logos from './Logos';
import StopsInfo from './StopsInfo';

function FlightRowComponent({ leg, numLegs, itineraryIndex, legIndex }) {
	const print_num_stops = (stops) => {
		if (stops === 0) {
			return 'Nonstop';
		}
		if (stops === 1) {
			return '1 stop';
		}
		return `${stops} stops`;
	};

	const stopDots = Array.from({ length: leg.stops }, () => (
		<div className="relative h-2 w-2 bg-red-500 rounded-lg red-dot mx-1" />
	));

	const formatDate = (inputDate) => {
		const [year, month, day] = inputDate.split('-').map(Number);
		const dateObject = new Date(year, month - 1, day);
		const options = { weekday: 'short', month: 'short', day: 'numeric' };
		return dateObject.toLocaleDateString('en-US', options);
	};

	let direction = null;

	if (numLegs === 2) {
		if (legIndex === 0) {
			direction = 'Outbound';
		} else if (itineraryIndex === 1) {
			direction = 'Inbound';
		}
	}

	const { routes } = leg;

	let airlines = leg.routes.map((route) => route.airline);
	airlines = [...new Set(airlines)].slice(0, 4);

	return (
		<div className="flex flex-col space-y-2">
			<div className="flex flex-row items-center self-start">
				<p className="text-gray-500">{formatDate(leg.departureDate)}</p>
				{direction && (
					<>
						<div className="w-1 h-1 bg-gray-500 rounded-full self-center" />
						<p className="text-gray-500">{direction}</p>
					</>
				)}
			</div>
			<div className="flex justify-between">
				<div className="flex items-center space-x-1">
					<Logos airlines={airlines} />
					<div className="flex flex-col items-center">
						<p className="text-black">{leg.departureTime}</p>
						<TooltipWrapper tooltipContent={airportsMap[leg.source]}>
							<p className="text-gray-500">{leg.source}</p>
						</TooltipWrapper>
					</div>
				</div>
				<div className="flex flex-col items-center basis-1/3">
					<p style={{ color: '#301345', fontWeight: 400 }}>{leg.duration}</p>
					<div
						className="flex flex-row items-center my-1 w-full justify-center"
						style={{ backgroundColor: '#7F6D8B', height: '2px' }}
					>
						{stopDots}
					</div>
					<div className="flex justify-center items-center">
						<p
							className="mx-1"
							style={{ color: leg.stops ? '#DC1F90' : 'green' }}
						>
							{print_num_stops(leg.stops)}
						</p>
						<StopsInfo routes={routes} />
					</div>
				</div>
				<div className="flex flex-col items-center">
					<p className="text-black">
						{leg.arrivalTime}
						<sup
							className={`text-gray-500 mb-2 -top-3 ${
								leg.dateChange > 0 ? 'visible' : 'invisible'
							}`}
						>
							{`+${leg.dateChange.toString()}`}
						</sup>
					</p>
					<TooltipWrapper tooltipContent={airportsMap[leg.destination]}>
						<p className="text-gray-500">{leg.destination}</p>
					</TooltipWrapper>
				</div>
			</div>
		</div>
	);
}

export default FlightRowComponent;

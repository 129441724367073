function AISparkIconPlain() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<g clipPath="url(#clip0_9_1895)">
				<path
					d="M19 9.985L13.945 12.7375C13.4425 13.015 13.03 13.4275 12.7525 13.93L10.015 19L7.2625 13.945C6.985 13.4425 6.5725 13.03 6.07 12.7525L1 10.015L6.055 7.2625C6.5575 6.985 6.97 6.5725 7.2475 6.07L9.985 1L12.7375 6.055C13.015 6.5575 13.4275 6.97 13.93 7.2475L18.9925 9.985H19Z"
					stroke="#6C577A"
					strokeWidth="1.125"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M19 3.40001L17.65 4.13501C17.515 4.21001 17.4025 4.32251 17.335 4.45001L16.6075 5.80001L15.8725 4.45001C15.7975 4.31501 15.685 4.20251 15.5575 4.13501L14.2075 3.40751L15.5575 2.67251C15.6925 2.59751 15.805 2.48501 15.8725 2.35751L16.6 1.00751L17.335 2.35751C17.41 2.49251 17.515 2.60501 17.65 2.67251L19 3.40001Z"
					stroke="#6C577A"
					strokeWidth="1.125"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9_1895">
					<rect
						width="19.125"
						height="19.125"
						fill="white"
						transform="translate(0.4375 0.4375)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default AISparkIconPlain;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CssBaseline from '@mui/material/CssBaseline';
import { get_id } from '@helpers/TooltipHelper';
import { Tooltip } from 'react-tooltip';
import Layout from '@commons/core/components/Layout/Layout';
import BrowserCheck from '@commons/core/BrowserCheck/BrowserCheck';
import { BrowserRouter as Router } from 'react-router-dom';
import MobileWarning from '@commons/core/DeviceCheck/MobileWarning';
import SessionCheck from '@commons/core/SessionCheck';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import 'react-tooltip/dist/react-tooltip.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
	palette: {
		primary: {
			main: '#6f00ff',
		},
		secondary: {
			main: '#11cb5f',
		},
	},
	typography: {
		fontFamily: 'General Sans, sans-serif',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontFamily: 'General Sans, sans-serif',
				},
			},
		},
	},
});

root.render(
	// <React.StrictMode>
	<DndProvider backend={HTML5Backend}>
		<Provider store={store}>
			<Router>
				<MobileWarning />
				<BrowserCheck />
				<SessionCheck>
					<ThemeProvider theme={theme}>
						<Layout>
							<CssBaseline />
							<App />

							<Tooltip id={get_id()} />
						</Layout>
					</ThemeProvider>
				</SessionCheck>
			</Router>
		</Provider>
	</DndProvider>,
	// </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
	ChatMessageSender,
	InteractionStage,
	InspirationDrawerSource,
	TripUpdateType,
} from '@helpers/Enums';
import { handleFetchPreLC } from '@helpers/ModelApiUtils';
// import Switch from '@mui/material/Switch';
// import { styled } from '@mui/material/styles';
import directOverviewState from '@helpers/directOverviewState';
import directOverviewState2 from '@helpers/directOverviewState2';
import {
	maybePopulateMessage,
	populateCurrentChatMessageWithAnimation,
} from '@utils/DemoMessageHotkeyUtils';
import { useNavigate } from 'react-router-dom';
import { dispatchAddChatMessage } from '@helpers/ChatUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';

import AddInspirationButton from '@commons/Inspiration/AddInspirationButton';
import CardsSection from './CardsSection';
import styles from './styles.module.css';
import AuthForm from './Authentication/AuthForm';

const TEXT_INPUT_PLACEHOLDER = 'Ask AiRial anything...';

// const Android12Switch = styled(Switch)(({ theme }) => ({
// 	padding: 8,
// 	'& .MuiSwitch-track': {
// 		borderRadius: 22 / 2,
// 		'&::before, &::after': {
// 			content: '""',
// 			position: 'absolute',
// 			top: '50%',
// 			transform: 'translateY(-50%)',
// 			width: 16,
// 			height: 16,
// 		},
// 		'&::before': {
// 			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
// 				theme.palette.getContrastText(theme.palette.primary.main),
// 			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
// 			left: 12,
// 		},
// 		'&::after': {
// 			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
// 				theme.palette.getContrastText(theme.palette.primary.main),
// 			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
// 			right: 12,
// 		},
// 	},
// 	'& .MuiSwitch-thumb': {
// 		boxShadow: 'none',
// 		width: 16,
// 		height: 16,
// 		margin: 2,
// 	},
// }));

function RightArrow({ color = '#92849D' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M9.62 3.95334L13.6667 8.00001L9.62 12.0467"
				stroke={color}
				strokeWidth="1.2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.33334 8H13.5533"
				stroke={color}
				strokeWidth="1.2"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

function LandingPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const reduxState = useSelector((state) => state);
	const currentChatMessage = useSelector(
		(state) => state.ui_states.currentChatMessage,
	);

	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);

	const { _initialized, ...profile } = useSelector((state) => state.profile);

	const isProfilePresent = !!profile?.email;

	// const [isOneShotSearch, setIsOneShotSearch] = useState(false);
	const [showLoginLayover, setShowLoginLayover] = useState(false);
	const [isCurrentChatMessageInvalid, setIsCurrentChatMessageInvalid] =
		useState(false);

	useEffect(() => {
		dispatch({ type: 'RESET_STATE' });
	}, [dispatch]);

	const handleChatMessageChange = (event) => {
		dispatch({
			type: 'UPDATE_CURRENT_CHAT_MESSAGE',
			payload: event.target.value,
		});
	};

	const handleDirectLoadState = async () => {
		try {
			dispatch({
				type: 'SET_ENTIRE_STATE',
				payload: directOverviewState,
			});
			navigate(`/chat`);
		} catch (error) {
			console.error('Error loading state:', error);
		}
	};

	const handleDirectLoadState2 = async () => {
		try {
			dispatch({
				type: 'SET_ENTIRE_STATE',
				payload: directOverviewState2,
			});
			navigate(`/chat`);
		} catch (error) {
			console.error('Error loading state:', error);
		}
	};

	// const handleToggle = () => {
	// 	setIsOneShotSearch(!isOneShotSearch);
	// };

	const handleSubmit = async () => {
		const alphanumericRegex = /[A-Za-z0-9]/;
		if (!alphanumericRegex.test(currentChatMessage) || chatMessages.is_typing) {
			setIsCurrentChatMessageInvalid(true);

			setTimeout(() => {
				setIsCurrentChatMessageInvalid(false);
			}, 500); // Adjust the delay as needed
			return;
		}

		setIsCurrentChatMessageInvalid(false);

		if (!isProfilePresent) {
			setShowLoginLayover(true);
			return;
		}

		const userMessage = {
			sender: ChatMessageSender.USER,
			message: currentChatMessage,
		};

		const postFormData = new FormData();

		postFormData.append('user_message', currentChatMessage);
		postFormData.append('chat_history', JSON.stringify(llmInteractionHistory));
		postFormData.append(
			'interaction_stage',
			InteractionStage.PRE_CLARIFICATION_WIDGET,
		);
		postFormData.append('initial_state', JSON.stringify(reduxState));

		navigate(`/chat`);
		dispatch({ type: 'UPDATE_PAGE_ID', payload: 1 });
		// Use null sessionId to indicate that this is a new chat session
		const chatSessionId = null;
		dispatch({ type: 'UPDATE_CURRENT_CHAT_MESSAGE', payload: '' });
		dispatchAddChatMessage(dispatch, TripUpdateType.PRE_LC_USER_SUBMITTED, {
			messages: [userMessage],
			isTyping: true,
		});

		await handleFetchPreLC(postFormData, dispatch, navigate, chatSessionId);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault(); // Prevent newline character from being inserted
			handleSubmit();
		}
		if (event.key === 'd' && event.metaKey) {
			event.preventDefault();
			handleDirectLoadState();
		}
		if (event.key === 's' && event.metaKey) {
			event.preventDefault();
			handleDirectLoadState2();
		}
		const populate = (message) =>
			populateCurrentChatMessageWithAnimation(message, dispatch);

		maybePopulateMessage(event, populate);
	};

	const onAddInspirationClicked = () => {
		if (!isProfilePresent) {
			setShowLoginLayover(true);
			return;
		}

		dispatch({
			type: 'UPDATE_ADD_INSPIRATION_DRAWER',
			payload: {
				show: true,
				source: InspirationDrawerSource.LANDING_PAGE,
			},
		});

		sendMessageEvent({
			event: GoogleEventTypes.LANDING_PAGE_ADD_INSPIRATION,
		});
	};

	const isChatBoxFilled = !!currentChatMessage;

	const composeBox = (
		<div
			className={styles.search_component}
			style={{ border: isChatBoxFilled ? '1px solid #7750E5' : 'none' }}
		>
			<Box
				component="form"
				sx={{
					'& > :not(style)': { m: 0 },
					width: '100%',
				}}
				noValidate
				autoComplete="off"
			>
				<TextareaAutosize
					value={currentChatMessage}
					onKeyDown={handleKeyDown}
					onChange={handleChatMessageChange}
					minRows={2}
					maxRows={8}
					placeholder={TEXT_INPUT_PLACEHOLDER}
					style={{ width: '100%' }}
					className={`rounded-lg w-full resize-none overflow-y-auto focus:outline-transparent
				${isCurrentChatMessageInvalid ? styles.shake : ''}`}
				/>
			</Box>

			{showLoginLayover && (
				<div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
					<AuthForm setShowLoginLayover={setShowLoginLayover} />
				</div>
			)}

			<div className="flex justify-end items-center text-sm">
				<div className="flex text-sm items-center">
					{/* <FormControlLabel
						style={{
							color: isOneShotSearch ? '#7750E5' : '#9E9E9E',
						}}
						control={
							<Android12Switch
								checked={isOneShotSearch}
								onChange={handleToggle}
							/>
						}
						label="One-shot"
					/> */}

					<div
						role="presentation"
						className={`${styles.submit_button} ${isChatBoxFilled ? styles.active : styles.inactive}`}
						onClick={handleSubmit}
					>
						<div
							className={`${styles.submit_text} ${isChatBoxFilled ? styles.active : styles.inactive}`}
						>
							Submit
						</div>

						<div
							className={`${styles.submit_icon} ${isChatBoxFilled ? styles.active : styles.inactive}`}
						>
							<RightArrow color={isChatBoxFilled ? '#7750E5' : '#6C577A'} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className={styles.main_component}>
			<div className={styles.top_section}>
				<div className="flex gap-2 justify-center">
					<div className={styles.header}>Create dream trips</div>
					<div className={styles.header_italics}>instantly</div>
				</div>
				{composeBox}
				<AddInspirationButton
					onAddInspirationClicked={onAddInspirationClicked}
					tiktokText="TikTok to Trip"
					igText="IG Reel to Trip"
				/>
			</div>

			<div
				className="flex items-center text-sm font-medium"
				style={{ color: '#6C577A' }}
			>
				<hr
					className="w-64"
					style={{
						borderWidth: '1px',
						borderTopStyle: 'solid',
						borderTopColor: '#6C577A',
					}}
				/>
				<span className="w-[160px] text-center">What you can ask</span>
				<hr
					className="w-64"
					style={{
						borderWidth: '1px',
						borderTopStyle: 'solid',
						borderTopColor: '#6C577A',
					}}
				/>
			</div>
			<CardsSection />
		</div>
	);
}

export default LandingPage;

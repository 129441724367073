function AISparkIcon() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_38_2051)">
				<path
					d="M19 9.985L13.945 12.7375C13.4425 13.015 13.03 13.4275 12.7525 13.93L10.015 19L7.2625 13.945C6.985 13.4425 6.5725 13.03 6.07 12.7525L1 10.015L6.055 7.2625C6.5575 6.985 6.97 6.5725 7.2475 6.07L9.985 1L12.7375 6.055C13.015 6.5575 13.4275 6.97 13.93 7.2475L18.9925 9.985H19Z"
					fill="url(#paint0_linear_38_2051)"
					stroke="url(#paint1_linear_38_2051)"
					strokeWidth="1.125"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M18.9995 3.39982L17.6495 4.13482C17.5145 4.20982 17.402 4.32232 17.3345 4.44982L16.607 5.79982L15.872 4.44982C15.797 4.31482 15.6845 4.20232 15.557 4.13482L14.207 3.40732L15.557 2.67232C15.692 2.59732 15.8045 2.48482 15.872 2.35732L16.5995 1.00732L17.3345 2.35732C17.4095 2.49232 17.5145 2.60482 17.6495 2.67232L18.9995 3.39982Z"
					fill="url(#paint2_linear_38_2051)"
					stroke="url(#paint3_linear_38_2051)"
					strokeWidth="1.125"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M17.5 15.6232L16.8662 15.9683C16.8028 16.0035 16.75 16.0563 16.7183 16.1162L16.3768 16.75L16.0317 16.1162C15.9965 16.0528 15.9437 16 15.8838 15.9683L15.25 15.6268L15.8838 15.2817C15.9472 15.2465 16 15.1937 16.0317 15.1338L16.3732 14.5L16.7183 15.1338C16.7535 15.1972 16.8028 15.25 16.8662 15.2817L17.5 15.6232Z"
					fill="url(#paint4_linear_38_2051)"
					stroke="url(#paint5_linear_38_2051)"
					strokeWidth="0.375"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_38_2051"
					x1="19"
					y1="10"
					x2="1.00097"
					y2="9.86795"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DF74B1" />
					<stop offset="1" stopColor="#7E6DF1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_38_2051"
					x1="19"
					y1="10"
					x2="1.00097"
					y2="9.86795"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DF74B1" />
					<stop offset="1" stopColor="#7E6DF1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_38_2051"
					x1="18.9995"
					y1="3.40357"
					x2="14.2073"
					y2="3.36842"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DF74B1" />
					<stop offset="1" stopColor="#7E6DF1" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_38_2051"
					x1="18.9995"
					y1="3.40357"
					x2="14.2073"
					y2="3.36842"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DF74B1" />
					<stop offset="1" stopColor="#7E6DF1" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_38_2051"
					x1="17.5"
					y1="15.625"
					x2="15.2501"
					y2="15.6085"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DF74B1" />
					<stop offset="1" stopColor="#7E6DF1" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_38_2051"
					x1="17.5"
					y1="15.625"
					x2="15.2501"
					y2="15.6085"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DF74B1" />
					<stop offset="1" stopColor="#7E6DF1" />
				</linearGradient>
				<clipPath id="clip0_38_2051">
					<rect
						width="19.125"
						height="19.125"
						fill="white"
						transform="translate(0.4375 0.4375)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default AISparkIcon;

import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import isEmpty from '@utils/isEmpty';
import FiltersDropdown from './FiltersDropdown';

import './Filters.css';
import PriceFilter from './PriceFilter';
import DurationFilter from './DurationFilter';
import AddStopsFilter from './AddStopsFilter';
import ComingSoon from './ComingSoon';
import TimesFilter from './TimesFilter';

function formatTime(timeString) {
	const [hours] = timeString.split(':');

	const parsedHours = parseInt(hours, 10);

	return parsedHours;
}

function Filter({ filterName }) {
	const { flight_results, maxPossibleFlightPrice, minPossibleFlightPrice } =
		useSelector(({ ui_states = {} }) => ({
			flight_results: ui_states.flight_results,
			minPossibleFlightPrice: ui_states.minPossibleFlightPrice,
			maxPossibleFlightPrice: ui_states.maxPossibleFlightPrice,
		}));

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const tripTransitionsDetails = useSelector((state) => state.tripTransitions);

	const { travelSets, maxPrice } = tripTransitionsDetails;

	const FLIGHTSETS = travelSets.map((travelSet) => {
		return travelSet.reduce((acc, { type, flightDetails = {} }) => {
			if (['flight', 'FLIGHT'].includes(type)) {
				return [
					...acc,
					{
						DATE_OF_JOURNEY: flightDetails?.dateOfJourney,
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}

			return acc;
		}, []);
	});

	const dispatch = useDispatch();

	const [maxPriceFilter, setMaxPriceFilter] = useState(
		maxPrice <= 0 ? maxPossibleFlightPrice : maxPrice,
	);
	const [durantionFilter, setDurantionFilter] = useState(
		FLIGHTSETS[activeTabIndex]?.map(({ MAX_DURATION }) => {
			if (MAX_DURATION <= 0) {
				return 38;
			}

			return MAX_DURATION;
		}),
	);
	const [stopsFilter, setStopsFilter] = useState(
		FLIGHTSETS[activeTabIndex]?.map(({ MAX_STOPS }) => MAX_STOPS),
	);
	const [timeFilter, setTimeFilter] = useState(
		FLIGHTSETS[activeTabIndex]?.map(
			({
				DEP_TIME_RANGE_START,
				DEP_TIME_RANGE_END,
				ARR_TIME_RANGE_START,
				ARR_TIME_RANGE_END,
			}) => [
				[
					formatTime(DEP_TIME_RANGE_START || '00:00'),
					formatTime(DEP_TIME_RANGE_END || '24:00'),
				],
				[
					formatTime(ARR_TIME_RANGE_START || '00:00'),
					formatTime(ARR_TIME_RANGE_END || '24:00'),
				],
			],
		),
	);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);
		setMaxPriceFilter(maxPrice);

		setDurantionFilter(
			FLIGHTSETS[activeTabIndex]?.map(({ MAX_DURATION }) => {
				if (MAX_DURATION <= 0) {
					return 38;
				}

				return MAX_DURATION;
			}),
		);

		setTimeFilter(
			FLIGHTSETS[activeTabIndex]?.map(
				({
					DEP_TIME_RANGE_START,
					DEP_TIME_RANGE_END,
					ARR_TIME_RANGE_START,
					ARR_TIME_RANGE_END,
				}) => [
					[
						formatTime(DEP_TIME_RANGE_START || '00:00'),
						formatTime(DEP_TIME_RANGE_END || '24:00'),
					],
					[
						formatTime(ARR_TIME_RANGE_START || '00:00'),
						formatTime(ARR_TIME_RANGE_END || '24:00'),
					],
				],
			),
		);

		setStopsFilter(
			FLIGHTSETS[activeTabIndex]?.map(({ MAX_STOPS }) => MAX_STOPS),
		);
	};

	useEffect(() => {
		if (isEmpty(flight_results)) {
			return;
		}

		const { max, min } = flight_results
			.filter((item) => !!item)
			.reduce(
				(acc, { itineraries }) => {
					const { maxP, minPrice } = itineraries.reduce(
						(accu, flight) => {
							return {
								maxP: Math.max(accu.maxP, flight.totalPrice),
								minPrice: Math.min(accu.minPrice, flight.totalPrice),
							};
						},
						{ maxP: -Infinity, minPrice: Infinity },
					);

					return {
						max: Math.max(acc.max, maxP),
						min: Math.min(acc.min, minPrice),
					};
				},
				{ max: 0, min: Infinity },
			);

		if (min < minPossibleFlightPrice) {
			dispatch({ type: 'UPDATE_MIN_FLIGHT_PRICE', payload: min });
		}

		if (max > maxPossibleFlightPrice) {
			dispatch({ type: 'UPDATE_MAX_FLIGHT_PRICE', payload: max });
		}
	}, [flight_results]);

	useEffect(() => {
		setTimeFilter(
			FLIGHTSETS[activeTabIndex]?.map(
				({
					DEP_TIME_RANGE_START,
					DEP_TIME_RANGE_END,
					ARR_TIME_RANGE_START,
					ARR_TIME_RANGE_END,
				}) => [
					[
						formatTime(DEP_TIME_RANGE_START || '00:00'),
						formatTime(DEP_TIME_RANGE_END || '24:00'),
					],
					[
						formatTime(ARR_TIME_RANGE_START || '00:00'),
						formatTime(ARR_TIME_RANGE_END || '24:00'),
					],
				],
			),
		);
	}, [activeTabIndex]);

	const get_filter_widget = () => {
		if (filterName === 'Stops') {
			return (
				<AddStopsFilter
					handleClose={handleClose}
					setAnchorEl={setAnchorEl}
					stopsFilter={stopsFilter}
					setStopsFilter={setStopsFilter}
					activeTabIndex={activeTabIndex}
				/>
			);
		}
		if (filterName === 'Price') {
			return (
				<PriceFilter
					handleClose={handleClose}
					setAnchorEl={setAnchorEl}
					maxPriceFilter={maxPriceFilter}
					setMaxPriceFilter={setMaxPriceFilter}
					activeTabIndex={activeTabIndex}
				/>
			);
		}
		if (filterName === 'Times') {
			return (
				<TimesFilter
					handleClose={handleClose}
					setAnchorEl={setAnchorEl}
					activeTabIndex={activeTabIndex}
					timeFilter={timeFilter}
					setTimeFilter={setTimeFilter}
				/>
			);
		}
		if (filterName === 'Duration') {
			return (
				<DurationFilter
					handleClose={handleClose}
					setAnchorEl={setAnchorEl}
					durantionFilter={durantionFilter}
					setDurantionFilter={setDurantionFilter}
					activeTabIndex={activeTabIndex}
				/>
			);
		}
		return <ComingSoon handleClose={handleClose} />;
	};

	const is_time_filter_populated_for_flightset = (flightset) => {
		for (let i = 0; i < flightset.length; i += 1) {
			const inner_element = flightset[i];
			const {
				DEP_TIME_RANGE_START = '',
				DEP_TIME_RANGE_END = '',
				ARR_TIME_RANGE_START = '',
				ARR_TIME_RANGE_END = '',
			} = inner_element;

			if (
				DEP_TIME_RANGE_START ||
				DEP_TIME_RANGE_END ||
				ARR_TIME_RANGE_START ||
				ARR_TIME_RANGE_END
			) {
				return true;
			}
		}
		return false;
	};

	const get_filter_populated = () => {
		if (filterName === 'Stops') {
			for (let i = 0; i < FLIGHTSETS[activeTabIndex]?.length; i += 1) {
				if (FLIGHTSETS[activeTabIndex]?.[i].MAX_STOPS !== -1) {
					return true;
				}
			}
			return false;
		}
		if (filterName === 'Price') {
			return maxPrice > 0;
		}
		if (filterName === 'Times') {
			if (activeTabIndex >= 0) {
				return is_time_filter_populated_for_flightset(
					FLIGHTSETS[activeTabIndex],
				);
			}

			for (let i = 0; i < FLIGHTSETS.length; i += 1) {
				if (is_time_filter_populated_for_flightset(FLIGHTSETS[i])) {
					return true;
				}
			}
			return false;
		}
		if (filterName === 'Duration') {
			for (let i = 0; i < FLIGHTSETS[activeTabIndex]?.length; i += 1) {
				if (
					'MAX_DURATION' in FLIGHTSETS[activeTabIndex][i] &&
					FLIGHTSETS[activeTabIndex][i]?.MAX_DURATION !== -1
				) {
					return true;
				}
			}
			return false;
		}
		return false;
	};

	return (
		<FiltersDropdown
			filterName={filterName}
			filterWidget={get_filter_widget()}
			filterPopulated={get_filter_populated()}
			anchorEl={anchorEl}
			setAnchorEl={setAnchorEl}
			handleClose={handleClose}
		/>
	);
}

export default Filter;

import { useEffect } from 'react';
import startCase from '@utils/startCase';
import { cutoffWithEllpsis } from '@utils/TextUtils';

function ImageLoader({
	sixImages = [],
	activeImage,
	setActiveImage = () => {},
}) {
	useEffect(() => {
		const interval = setInterval(() => {
			setActiveImage((prev) => (prev + 1) % sixImages.length);
		}, 4000);

		return () => clearInterval(interval);
	}, [activeImage]);

	return (
		<div
			style={{
				width: '502px',
				height: '360px',
				boxShadow: '0px 4px 24px 0px rgba(119, 80, 229, 0.24)',
				zIndex: '9',
			}}
			className="p-4 rounded-2xl bg-white"
		>
			<div className="flex gap-4">
				<div className="flex flex-col gap-2">
					{sixImages.map((image, index) => (
						<img
							key={index}
							role="presentation"
							src={image.url ?? image.getUrl()}
							alt={`Thumbnail ${index + 1}`}
							className={`rounded-lg cursor-pointer ${activeImage !== index && 'opacity-50'}`}
							onClick={() => setActiveImage(index)}
							style={{ width: '70px', height: '48px', objectFit: 'cover' }}
						/>
					))}
				</div>
				<div className="flex flex-col gap-2">
					<img
						src={
							sixImages[activeImage]?.url ?? sixImages[activeImage]?.getUrl()
						}
						alt={`Place ${activeImage + 1}`}
						style={{
							width: '384px',
							height: '272px',
							objectFit: 'cover',
							borderRadius: '8px',
						}}
					/>
					<div
						className="flex flex-row justify-between p-1 px-2 items-center"
						style={{
							width: '384px',
							height: '48px',
							backgroundColor: 'rgba(119, 80, 229, 0.05)',
							borderRadius: '8px',
						}}
					>
						<div className="text-sm w-28 font-medium h-fit">
							{cutoffWithEllpsis(
								startCase(sixImages[activeImage]?.placeName ?? ''),
								20,
							)}
						</div>
						<div className="text-sm max-w-56 h-fit">
							{cutoffWithEllpsis(
								startCase(sixImages[activeImage]?.title ?? ''),
								60,
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageLoader;

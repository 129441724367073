import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleFindFlights } from '@helpers/FlightsQueryHelper';
import {
	Button,
	MenuItem,
	InputLabel,
	Select,
	FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const StopsOptions = Object.freeze({
	ANY: 'Any',
	NON_STOP: 'Non-stop only',
	ONE_OR_FEWER: '1 stop or fewer',
	TWO_OR_FEWER: '2 stops or fewer',
});

function AddStopsFilter({
	handleClose = () => {},
	setAnchorEl = () => {},
	stopsFilter,
	activeTabIndex,
	setStopsFilter = () => {},
}) {
	const { tripTransitionsDetails } = useSelector(
		({ tripTransitions = {} }) => ({
			tripTransitionsDetails: tripTransitions,
		}),
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);

	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);

	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);
	const flightSortOption = useSelector((state) => state.ui_states.sort_option);

	const { tripLocationDetails } = useSelector(
		({ tripLocationDetails: tripLocations = {} }) => ({
			tripLocationDetails: tripLocations,
		}),
	);

	const {
		travelSets = [],
		maxPrice,
		tripType,
		tripClass,
	} = tripTransitionsDetails;

	const {
		places = [],
		startLocation = '',
		endLocation = '',
		hotelFilters = [],
		hotelReqs = [],
		numTravellers,
	} = tripLocationDetails;

	const FLIGHTSETS = travelSets.map((travelSet) => {
		return travelSet.reduce((acc, { type, flightDetails = {} }) => {
			if (['flight', 'FLIGHT'].includes(type)) {
				return [
					...acc,
					{
						DATE_OF_JOURNEY: flightDetails?.dateOfJourney,
						FROM_AIRPORT: flightDetails?.fromAirport,
						TO_AIRPORT: flightDetails?.toAirport,
						MAX_STOPS: flightDetails?.maxStops || -1,
						MAX_DURATION: flightDetails?.maxDuration || -1,
						DEP_TIME_RANGE_START: flightDetails?.depTimeRangeStart || undefined,
						DEP_TIME_RANGE_END: flightDetails?.depTimeRangeEnd || undefined,
						ARR_TIME_RANGE_START: flightDetails?.arrTimeRangeStart || undefined,
						ARR_TIME_RANGE_END: flightDetails?.arrTimeRangeEnd || undefined,
					},
				];
			}

			return acc;
		}, []);
	});

	const data_v2 = {
		NUM_PASSENGERS: numTravellers,
		MAX_PRICE: maxPrice,
		FLIGHTSETS,
		TRIP_TYPE: tripType,
		TRIP_CLASS: tripClass,
	};

	const dispatch = useDispatch();
	const location = useLocation();

	const handleClearFilter = () => {
		const updatedFlightSetsData = data_v2.FLIGHTSETS.map((flightSet) => {
			return flightSet.map((item) => {
				return {
					...item,
					MAX_STOPS: -1,
				};
			});
		});

		const updatedTravelSets = travelSets.map((travelSet) => {
			return travelSet.reduce((acc, cur) => {
				const { type } = cur || {};

				if (['flight', 'FLIGHT'].includes(type)) {
					return [
						...acc,
						{
							...cur,
							flightDetails: {
								...cur.flightDetails,
								maxStops: -1,
							},
						},
					];
				}

				return [...acc, cur];
			}, []);
		});

		dispatch({
			type: 'UPDATE_TRANSITIONS_REDUCER',
			payload: {
				travelSets: updatedTravelSets,
			},
		});

		const flightSetData = {
			...data_v2,
			FLIGHTSETS: updatedFlightSetsData,
		};

		handleClose();

		handleFindFlights({
			flightQuery: flightSetData,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery: {
				locations: {
					startLocation,
					endLocation,
					places,
				},
				chat_history: llmInteractionHistory,
			},
			shouldUpdateFlightsAndDayPlans: true,
			tripData: {
				NUM_TRAVELLERS: numTravellers,
				hotelFilters,
				travelSets: updatedTravelSets,
				LOCATIONS: places,
				HOTEL_REQUIREMENT: hotelReqs,
				hotelSortCriterion,
			},
			sortOption: flightSortOption,
			gMapsData,
			curActiveTabIndex: activeTabIndex,
		});
	};
	return (
		<div className="flex flex-col gap-4">
			<div className="flex justify-between">
				<div className="text-lg">Duration</div>
				<CloseIcon onClick={handleClose} />
			</div>

			{FLIGHTSETS[0].map((_, ix) => {
				return (
					<div className="text-sm mt-1">
						<FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
							<InputLabel id="demo-simple-select-standard-label">
								{`Flight ${(ix + 1).toString()}`}
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={stopsFilter[ix]}
								onChange={(event) => {
									setStopsFilter((prev) =>
										prev.map((val, index) => {
											if (index === ix) {
												return event.target.value;
											}

											return val;
										}),
									);
								}}
								label={`Flight ${(ix + 1).toString()}`}
							>
								<MenuItem value={-1}>
									<em>{StopsOptions.ANY}</em>
								</MenuItem>
								<MenuItem value={0}>{StopsOptions.NON_STOP}</MenuItem>
								<MenuItem value={1}>{StopsOptions.ONE_OR_FEWER}</MenuItem>
								<MenuItem value={2}>{StopsOptions.TWO_OR_FEWER}</MenuItem>
							</Select>
						</FormControl>
					</div>
				);
			})}

			<div className="flex ml-auto mt-2">
				<Button
					sx={{ textTransform: 'unset' }}
					onClick={handleClearFilter}
					variant="text"
					size="small"
				>
					Clear
				</Button>

				<Button
					sx={{ textTransform: 'unset', backgroundColor: '#5b21b6' }}
					variant="contained"
					size="small"
					onClick={() => {
						const updatedFlightSetsData = data_v2.FLIGHTSETS.map(
							(flightSet) => {
								return flightSet.map((item, index) => {
									return {
										...item,
										MAX_STOPS: stopsFilter[index],
									};
								});
							},
						);

						const updatedTravelSets = travelSets.map((travelSet) => {
							let i = 0;
							return travelSet.reduce((acc, cur) => {
								const { type } = cur || {};

								if (['flight', 'FLIGHT'].includes(type)) {
									const curIndex = i;
									i += 1;

									return [
										...acc,
										{
											...cur,
											flightDetails: {
												...cur.flightDetails,
												maxStops: stopsFilter[curIndex],
											},
										},
									];
								}

								return [...acc, cur];
							}, []);
						});

						dispatch({
							type: 'UPDATE_TRANSITIONS_REDUCER',
							payload: {
								travelSets: updatedTravelSets,
							},
						});

						const flightSetData = {
							...data_v2,
							FLIGHTSETS: updatedFlightSetsData,
						};

						handleFindFlights({
							flightQuery: flightSetData,
							dispatch,
							chatSessionId,
							location,
							dayPlansQuery: {
								locations: {
									startLocation,
									endLocation,
									places,
								},
								chat_history: llmInteractionHistory,
							},
							shouldUpdateFlightsAndDayPlans: true,
							tripData: {
								NUM_TRAVELLERS: numTravellers,
								hotelFilters,
								travelSets: updatedTravelSets,
								LOCATIONS: places,
								HOTEL_REQUIREMENT: hotelReqs,
								hotelSortCriterion,
							},
							sortOption: flightSortOption,
							gMapsData,
							curActiveTabIndex: activeTabIndex,
						});

						setAnchorEl(null);
					}}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

export default AddStopsFilter;

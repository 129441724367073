import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

function ReviewStars({ rating, user_ratings_total = 400 }) {
	const fullStars = Math.floor(rating);
	const hasHalfStar = rating - fullStars >= 0.5;

	return (
		<div className="flex items-center">
			<div style={{ color: '#70757a', marginRight: '6px' }} className="text-sm">
				{rating !== 0 ? `${rating}` : 'No rating'}
			</div>

			{[...Array(5)].map((_, index) => {
				if (index < fullStars) {
					return (
						<StarIcon
							key={index}
							sx={{ color: '#FFC107' }}
							style={{ fontSize: 16 }}
						/>
					);
				}

				if (index === fullStars && hasHalfStar) {
					return (
						<StarHalfIcon
							key={index}
							sx={{ color: '#FFC107' }}
							style={{ fontSize: 16 }}
						/>
					);
				}

				return (
					<StarIcon
						key={index}
						sx={{ color: '#E0E0E0' }}
						style={{ fontSize: 16 }}
					/>
				);
			})}

			<div className="ml-1 text-sm" style={{ color: '#70757a' }}>
				({user_ratings_total})
			</div>
		</div>
	);
}

export default ReviewStars;

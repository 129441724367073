import { useEffect, useState } from 'react';
import styles from './styles.module.css';

const NOT_ALLOWED_BROWSERS = ['Safari', 'Firefox']; // add browsers which you want to restrict

function BrowserCheck() {
	const [isBrowserAllowed, setIsBrowserAllowed] = useState(true);

	useEffect(() => {
		const getBrowserInfo = () => {
			const { userAgent } = navigator;
			let browserName = 'Unknown';

			if (userAgent.match(/chrome|chromium|crios/i)) {
				browserName = 'Chrome';
			} else if (userAgent.match(/firefox|fxios/i)) {
				browserName = 'Firefox';
			} else if (userAgent.match(/safari/i)) {
				browserName = 'Safari';
			} else if (userAgent.match(/opr\//i)) {
				browserName = 'Opera';
			} else if (userAgent.match(/edg/i)) {
				browserName = 'Edge';
			} else if (userAgent.match(/msie|trident/i)) {
				browserName = 'Internet Explorer';
			}

			return browserName;
		};

		const currentBrowser = getBrowserInfo();

		setIsBrowserAllowed(!NOT_ALLOWED_BROWSERS.includes(currentBrowser));
	}, []);

	const browserCheckEnabled = process.env.REACT_APP_BROWSER_CHECK === 'true';
	if (isBrowserAllowed || !browserCheckEnabled) {
		return true;
	}

	return (
		<div className={styles.warning_container}>
			<div>Safari and Firefox are not supported yet, for technical reasons. We will fix this soon.</div>
			<div>Please use a different browser to access this website. Recommended: Chrome.</div>
		</div>
	);
}
export default BrowserCheck;

import React from 'react'

export default function Header() {
    return (
        <header className="flex justify-between items-center pl-4 bg-header_background">
            <div className='flex flex-row items-center h-20'>
                <img
                    src="https://ik.imagekit.io/mew0x2ztv/logo.png?updatedAt=1697314509019"
                    className="w-14 h-14"
                    alt=""
                />
                <h1 className="text-2xl font-bold text-neutrals-dark_background_text">AiRial Travel</h1>
                <div className="ml-2 p-1 rounded-md border border-neutrals-dark_background_text hover:border-neutrals-hover_dark_background_text text-sm font-bold text-neutrals-dark_background_text hover:text-neutrals-hover_dark_background_text cursor-pointer">
                    BETA
                </div>
            </div>
            <div className="flex space-x-5 mr-6 text-sm items-center">
                <a href="#about" className="text-neutrals-dark_background_text hover:text-neutrals-hover_dark_background_text cursor-pointer">My Trips</a>
                <a href="#about" className="text-neutrals-dark_background_text hover:text-neutrals-hover_dark_background_text cursor-pointer">My Collections</a>
                <a href="#about" className="text-neutrals-dark_background_text hover:text-neutrals-hover_dark_background_text cursor-pointer">About</a>
            </div>
        </header>
    );
}

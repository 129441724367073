import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export default function NumPassengersDropdown({ flightFormState, setFlightFormState, textColor }) {
	const { numTravellers: numPassengers } = flightFormState;
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const onNumPassengersChanged = (newNumPassengers) => {
		setFlightFormState(
			(prevFlightFormState) => ({
				...prevFlightFormState,
				modified: true,
				numTravellers: newNumPassengers,
			})
		);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div
			style={{
				border: '1px solid #CEC8D2',
				borderRadius: '32px',
				padding: '0 8px',
			}}
		>
			<Button
				id="num_passengers_dropdown"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={<ExpandMoreIcon />}
				startIcon={<PersonOutlineOutlinedIcon />}
				sx={{
					color: textColor,
					textTransform: 'none',
					fontSize: 13,
				}}
			>
				{numPassengers}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'trip-type-dropdown-button',
				}}
			>
				<MenuItem
					key="Adults"
					disableRipple
					sx={{
						'&:hover': {
							backgroundColor: 'initial',
							cursor: 'default',
						},
					}}
				>
					<div className="flex flex-row space-x-4 items-center text-sm">
						<div>Adults</div>
						<div className="flex flex-row space-x-2 items-center">
							<Button
								id="num_passengers_dropdown_minus"
								size="small"
								variant="contained"
								disabled={numPassengers <= 1}
								onClick={() => onNumPassengersChanged(numPassengers - 1)}
								className="bg-primary-500"
								sx={{ minWidth: '28px', height: '28px', padding: '0px' }}
							>
								<HorizontalRuleOutlinedIcon fontSize="small" />
							</Button>
							<div>{numPassengers}</div>
							<Button
								id="num_passengers_dropdown_plus"
								size="small"
								variant="contained"
								onClick={() => onNumPassengersChanged(numPassengers + 1)}
								className="bg-primary-500"
								sx={{ minWidth: '28px', height: '28px', padding: '0px' }}
							>
								<AddOutlinedIcon fontSize="small" />
							</Button>
						</div>
					</div>
				</MenuItem>
			</Menu>
		</div>
	);
}

import { Slider, Box, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleFindHotels } from '@helpers/hotelsQueryHelper';

function RatingFilter({ handleClose = () => {} }) {
	const dispatch = useDispatch();

	const location = useLocation();
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const tripLocationDetails = useSelector((state) => state.tripLocationDetails);
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const activeTabIndex = useSelector(
		(state) => state.ui_states.active_tab_index,
	);

	const hotelSortCriterion = useSelector(
		(state) => state.tripLocationDetails.hotelSortCriterion,
	);

	const {
		hotelFilters = [],
		hotelReqs = [],
		hotelSets,
		numTravellers,
	} = tripLocationDetails;

	const selectedRating =
		(hotelFilters.filter((hotelFilter) => hotelFilter)[0] || {})
			.MIN_STAR_RATING || -1;

	const [minStars, setMinStars] = useState(selectedRating);

	const handleChange = (_, newValue) => {
		setMinStars(newValue);
	};

	const handleChangeFilter = ({ action }) => {
		const updatedHotelFilters = hotelFilters.map((item) => {
			if (!item) {
				return null;
			}

			return {
				...item,
				MIN_STAR_RATING: action === 'apply' ? minStars : -1,
			};
		});

		dispatch({
			type: 'UPDATE_HOTEL_FILTERS',
			payload: updatedHotelFilters,
		});

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: hotelSets,
				hotelFilters: updatedHotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: hotelSortCriterion,
			},
			tabIndex: activeTabIndex,
			gMapsData,
			chatSessionId,
		});

		handleClose();
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex justify-between">
				<div className="text-lg">Minimum Rating</div>
				<CloseIcon onClick={handleClose} />
			</div>

			<Box sx={{ width: 300, padding: '0 8px' }}>
				<Slider
					value={minStars}
					onChange={handleChange}
					aria-labelledby="stars-slider"
					valueLabelDisplay="auto"
					min={1}
					max={5}
					marks={[
						{ value: 1, label: <StarIcon /> },
						{ value: 2, label: <StarIcon /> },
						{ value: 3, label: <StarIcon /> },
						{ value: 4, label: <StarIcon /> },
						{ value: 5, label: <StarIcon /> },
					]}
				/>
			</Box>

			<div className="flex ml-auto mt-2">
				<Button
					sx={{ textTransform: 'unset' }}
					onClick={() => handleChangeFilter({ action: 'clear' })}
					variant="text"
					size="small"
				>
					Clear
				</Button>

				<Button
					sx={{ textTransform: 'unset', backgroundColor: '#5b21b6' }}
					variant="contained"
					size="small"
					disabled={selectedRating === minStars}
					onClick={() => handleChangeFilter({ action: 'apply' })}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}

export default RatingFilter;

import React, { useState } from 'react';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import Collapsible from 'react-collapsible';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { checkLink } from '@utils/LinkUtils';

function InstagramComponent({ url, scrape_results, isExpandable = true }) {
	const [collapsed, setCollapsed] = useState(true);
	const [fullyClosed, setFullyClosed] = useState(true);

	const toggleCollapsed = () => {
		if (collapsed) {
			setFullyClosed(false);
		}
		setCollapsed(!collapsed);
	};
	const linkCheck = checkLink(url);
	let linkText = 'Link';

	if (linkCheck.isInstagram) {
		linkText = 'Instagram';
	} else if (linkCheck.isTikTok) {
		linkText = 'TikTok';
	}
	const { username = '' } = scrape_results;

	const getCollapsedCard = () => {
		const cursorClass = isExpandable ? 'cursor-pointer' : '';
		return (
			<div
				className={`flex flex-col ${cursorClass}`}
				onClick={isExpandable ? toggleCollapsed : null}
			>
				<div className="flex space-x-2">
					{scrape_results?.azure_display_url ? (
						<div>
							<img
								src={scrape_results?.azure_display_url}
								alt=""
								referrerPolicy="no-referrer"
								className="h-20 w-20 object-cover max-w-none rounded-sm"
							/>
						</div>
					) : (
						<div className="h-20 w-20 bg-gray-100" />
					)}
					<div className="flex flex-col py-2 flex-1">
						<div className="flex flex-row space-x-1">
							<div className="text-sm font-medium">{username}</div>
							<div className="text-sm">•</div>
							<div className="text-sm font-medium">{linkText}</div>
						</div>
						<p
							className="overflow-hidden text-sm h-10"
							style={{
								whiteSpace: 'normal',
								display: '-webkit-box',
								WebkitLineClamp: '2',
								WebkitBoxOrient: 'vertical',
							}}
						>
							{scrape_results?.caption}
						</p>
					</div>
					{isExpandable && (
						<div className="flex m-2">
							<ExpandMoreIcon fontSize="small" className="flex-none" />
						</div>
					)}
				</div>
			</div>
		);
	};

	const getExpandedTrigger = () => {
		return (
			<div
				className="flex p-2 justify-between cursor-pointer items-center"
				onClick={toggleCollapsed}
			>
				<div className="flex-grow text-center truncate text-sm font-medium">
					{linkText}
				</div>
				<ExpandLessIcon fontSize="small" className="flex-none" />
			</div>
		);
	};
	return (
		<div className="rounded-lg border border-card_border">
			<Collapsible
				open={!collapsed}
				trigger={fullyClosed ? getCollapsedCard() : getExpandedTrigger()}
				onClose={() => {
					setFullyClosed(true);
				}}
			>
				{!collapsed && (
					<div className="flex justify-center py-4">
						{url && linkCheck.isInstagram && (
							<InstagramEmbed url={url} key={url} captioned width={328} />
						)}

						{url && linkCheck.isTikTok && (
							<TikTokEmbed url={url} key={url} captioned width={328} />
						)}
					</div>
				)}
			</Collapsible>
		</div>
	);
}

export default InstagramComponent;

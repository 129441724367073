import {
	getFlightQueryFromTripTransitions,
	handleFindFlights,
} from './FlightsQueryHelper';
import { fetchDayPlansAndDispatch } from './dayPlansQueryHelper';
import hotelDateSetHelper from './hotelDateSetHelper';
import { handleFindHotels } from './hotelsQueryHelper';

export const postLocationConfirmationReport = (
	reduxState,
	dispatch,
	location,
) => {
	const flightQuery = getFlightQueryFromTripTransitions(
		reduxState.tripTransitions,
		reduxState.tripLocationDetails.numTravellers,
	);
	const isFlightTransportationPresent =
		reduxState.tripTransitions.transitionReqs.includes('flight');
	try {
		afterLocationConfirmationReport(
			dispatch,
			location,
			reduxState.ui_states.chatSessionId,
			reduxState.tripLocationDetails.numTravellers,
			reduxState.ui_states.inspirationContentIds,
			reduxState.tripLocationDetails.hotelReqs,
			reduxState.tripLocationDetails.places,
			reduxState.tripLocationDetails.startLocation,
			reduxState.tripLocationDetails.endLocation,
			reduxState.tripLocationDetails.hotelFilters,
			flightQuery,
			reduxState.ui_states.llmInteractionHistory,
			isFlightTransportationPresent,
			reduxState.tripLocationDetails.hotelSortCriterion,
			reduxState.ui_states.sort_option,
			reduxState.tripTransitions.travelSets,
			reduxState.ui_states.gMapsData,
		);
	} catch (error) {}
};

export const afterLocationConfirmationReport = (
	dispatch,
	location,
	chatSessionId,
	numTravellers,
	inspirationContentIds,
	hotelReqs,
	places,
	startLocation,
	endLocation,
	hotelFilters,
	FLIGHT_QUERY,
	chatHistory,
	isFlightTransportationPresent,
	hotelSortCriterion,
	flightSortOption,
	travelSets,
	gMapsData,
) => {
	dispatch({
		type: 'UPDATE_OVERALL_TRIP_LOAD_STATUS', // making it true for both LC widget and post report flow
		payload: true,
	});

	const dayPlansQuery = {
		locations: {
			startLocation,
			endLocation,
			places,
		},
		chat_history: chatHistory,
		contentIds: inspirationContentIds,
	};

	if (isFlightTransportationPresent) {
		handleFindFlights({
			flightQuery: FLIGHT_QUERY,
			dispatch,
			chatSessionId,
			location,
			dayPlansQuery,
			shouldUpdateFlightsAndDayPlans: true,
			tripData: {
				NUM_TRAVELLERS: numTravellers,
				hotelFilters,
				travelSets,
				LOCATIONS: places,
				HOTEL_REQUIREMENT: hotelReqs,
				hotelSortCriterion,
			},
			sortOption: flightSortOption,
			gMapsData,
		});
	} else {
		const hotelDateSets = hotelDateSetHelper(
			places,
			hotelReqs,
			[],
			travelSets[0],
		);

		handleFindHotels({
			dispatch,
			location,
			hotel_set_data: {
				hotel_datesets: [hotelDateSets],
				hotelFilters,
				NUM_TRAVELLERS: numTravellers,
				HOTEL_REQUIREMENT: hotelReqs,
				sortCriterion: hotelSortCriterion,
			},
			tabIndex: 0,
			gMapsData,
			chatSessionId,
		});

		fetchDayPlansAndDispatch(
			dispatch,
			{
				...dayPlansQuery,
				travelSets: travelSets[0],
				flightSelections: [],
			},
			chatSessionId,
			0,
		);
	}
};

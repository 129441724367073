import startCase from '@utils/startCase';
import Chip from '@mui/material/Chip';

function PlaceDetails({
	data = [],
	activeMarker,
	setActiveMarker = () => {},
	setZoomLevel = () => {},
	gmaps_data = {},
	setCenterPosition = () => {},
}) {
	const handleActiveMarker = (marker) => {
		setActiveMarker(marker === activeMarker ? null : marker);

		if (marker !== activeMarker) {
			setCenterPosition(gmaps_data?.[marker]?.position);

			setZoomLevel(12);
		}
	};

	if (!data.length) {
		return null;
	}

	return (
		<div className="w-full flex flex-col" style={{ gap: '24px' }}>
			{data.map((item, index) => {
				const {
					name = '',
					description = '',
					one_liner = '',
					complete_results = {},
				} = item;

				const { photos = [], types = [] } = complete_results;

				return (
					<div
						key={index}
						role="presentation"
						className="bg-white flex w-full justify-between rounded-lg p-2 gap-2 cursor-pointer hover:bg-gray-200"
						style={{
							border: activeMarker === index ? '1px solid #adb5bd' : 'none',
						}}
						onClick={() => handleActiveMarker(index)}
					>
						<div className="flex gap-3 items-start">
							<div
								className="border border-purple-600 rounded-full flex items-center justify-center text-purple-600 text-xxs"
								style={{
									minWidth: '15px',
									height: '15px',
									marginTop: '2px',
								}}
							>
								{index + 1}
							</div>

							<div className="flex flex-col gap-1">
								<div className="flex gap-20 mr-4 text-sm font-medium">
									{name}
								</div>

								<div className="text-xs">{description || one_liner}</div>

								<div className="flex gap-2 mt-1 mb-1">
									{types.map((type, typeIndex) => {
										if (typeIndex < 2) {
											return (
												<Chip
													style={{
														color: '#9353F2',
														backgroundColor: '#EEE2FF',
														fontSize: '10px',
														borderRadius: '4px',
														padding: '0px',
														height: '20px',
													}}
													label={startCase(type)}
													size="small"
												/>
											);
										}

										return null;
									})}
								</div>
							</div>
						</div>
						{/* 
						<div>
							<img
								src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photos[0]?.photo_reference}
                                &key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
								alt=""
								style={{
									height: '80px',
									width: '100px',
									objectFit: 'cover',
									borderRadius: '4px',
									maxWidth: 'none',
								}}
							/>
						</div> */}
					</div>
				);
			})}
		</div>
	);
}

export default PlaceDetails;

import { memo, useState, useCallback, useEffect } from 'react';
import {
	GoogleMap,
	MarkerF,
	useJsApiLoader,
	InfoWindowF,
} from '@react-google-maps/api';
import InfoComponent from '@commons/MapComponent/InfoComponent';

const containerStyle = {
	width: '100%',
	height: '100%',
};

const MapComponent = memo(
	function MapComponent({
		gMapsDataMap = {},
		styles,
		activeMarker,
		setActiveMarker = (activeMarker) => {},
		fallbackCenter,
	}) {
		const { isLoaded } = useJsApiLoader({
			id: 'google-map-script',
			googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		});

		const [map, setMap] = useState(null);

		const fitBounds = (map) => {
			if (map && gMapsDataMap) {
				try {
					const bounds = new window.google.maps.LatLngBounds();
					let boundsSet = false;
					Object.values(gMapsDataMap).forEach((property) => {
						if (
							property.position &&
							property.position.lat &&
							property.position.lng
						) {
							bounds.extend(property.position);
							boundsSet = true;
						}
					});

					if (!boundsSet && fallbackCenter) {
						bounds.extend(fallbackCenter);
					}

					map.fitBounds(bounds);
					window.google.maps.event.addListenerOnce(map, 'idle', () => {
						if (map.getZoom() > 14) {
							// Set a maximum zoom level
							map.setZoom(14);
						}
					});
				} catch (e) {
					console.log('EXC: Suppressed exception in fitBounds', e);
				}
			}
		};

		useEffect(() => {
			if (map && gMapsDataMap) {
				fitBounds(map, gMapsDataMap); // Assuming fitBounds can use gMapsDataMap now
			}
		}, [gMapsDataMap, map]); // Depend on both map instance and gMapsDataMap

		const onLoad = useCallback(
			function callback(loadedMap) {
				fitBounds(loadedMap);
				setMap(loadedMap);
				loadedMap.addListener('click', mapClicked);
			},
			[gMapsDataMap],
		);

		const onUnmount = useCallback(function callback() {
			setMap(null);
		}, []);

		const mapClicked = (event) => {
			setActiveMarker(null);
		};

		if (!isLoaded) {
			return null;
		}

		return (
			<GoogleMap
				mapContainerStyle={styles || containerStyle}
				zoom={4}
				onLoad={onLoad}
				onUnmount={onUnmount}
				options={{
					mapId: '3759016687888015',
					disableInfoWindows: true,
					streetViewControl: false,
					mapTypeControl: false,
					scaleControl: false,
					rotateControl: false,
					fullscreenControl: false,
				}}
			>
				{Object.entries(gMapsDataMap).map(([placeName, details], index) => {
					if (!details.position) {
						return null;
					}

					return (
						<MarkerF
							position={details.position}
							onClick={() => setActiveMarker(placeName)}
							label={details.label || `${index + 1}`}
							key={`${details.label}_${placeName}`}
						>
							{activeMarker === placeName ? (
								<InfoWindowF onCloseClick={() => setActiveMarker(null)}>
									<InfoComponent
										setActiveMarker={setActiveMarker}
										// setZoomLevel={setZoomLevel}
										complete_results={details}
									/>
								</InfoWindowF>
							) : null}
						</MarkerF>
					);
				})}
			</GoogleMap>
		);
	},
	(prevProps, nextProps) => {
		const eq = aregMapsDataMapEqual(
			prevProps.gMapsDataMap,
			nextProps.gMapsDataMap,
		);
		return eq && prevProps.activeMarker === nextProps.activeMarker;
	},
);

const aregMapsDataMapEqual = (prev, next) => {
	const keys1 = Object.keys(prev);
	const keys2 = Object.keys(next);
	if (keys1.length !== keys2.length) return false;
	for (const key of keys1) {
		if (!next.hasOwnProperty(key)) return false;
		const field1 = prev[key];
		const field2 = next[key];
		if (JSON.stringify(field1) !== JSON.stringify(field2)) return false;
	}
	return true;
};

export default MapComponent;

export default [{
        "id": "MBU",
        "lcc": "",
        "name": "Marabu",
        "type": "airline"
    }, {
        "id": "N9",
        "lcc": "",
        "name": "Novair",
        "type": "airline"
    }, {
        "id": "YST",
        "lcc": "",
        "name": "Yesmin Tours",
        "type": "airline"
    }, {
        "id": "MR",
        "lcc": 0,
        "name": "Hunnu Air",
        "type": "airline"
    }, {
        "id": "SY",
        "lcc": 1,
        "name": "Sun Country Airlines",
        "type": "airline"
    }, {
        "id": "H3",
        "lcc": 0,
        "name": "Thomas Cook Aviation",
        "type": "airline"
    }, {
        "id": "KTB",
        "lcc": "",
        "name": "Transaviabaltika",
        "type": "airline"
    }, {
        "id": "D2",
        "lcc": 1,
        "name": "Severstal Air Company",
        "type": "airline"
    }, {
        "id": "RQ",
        "lcc": 0,
        "name": "Kam Air",
        "type": "airline"
    }, {
        "id": "WG",
        "lcc": 1,
        "name": "Sunwing",
        "type": "airline"
    }, {
        "id": "2I",
        "lcc": 1,
        "name": "Star Peru",
        "type": "airline"
    }, {
        "id": "DV",
        "lcc": 1,
        "name": "Scat Airlines",
        "type": "airline"
    }, {
        "id": "VO",
        "lcc": 0,
        "name": "Voyage Air",
        "type": "airline"
    }, {
        "id": "8Y",
        "lcc": "",
        "name": "Pan Pacific Airlines",
        "type": "airline"
    }, {
        "id": "LH",
        "lcc": 0,
        "name": "Lufthansa",
        "type": "airline"
    }, {
        "id": "SI",
        "lcc": 0,
        "name": "Blue Islands",
        "type": "airline"
    }, {
        "id": "UA",
        "lcc": 0,
        "name": "United Airlines",
        "type": "airline"
    }, {
        "id": "FI",
        "lcc": 0,
        "name": "Icelandair",
        "type": "airline"
    }, {
        "id": "5G",
        "lcc": 0,
        "name": "MAYAir",
        "type": "airline"
    }, {
        "id": "RM",
        "lcc": 0,
        "name": "Armenia Aircompany",
        "type": "airline"
    }, {
        "id": "XX",
        "lcc": 0,
        "name": "Greenfly",
        "type": "airline"
    }, {
        "id": "WR",
        "lcc": 1,
        "name": "WestJet Encore",
        "type": "airline"
    }, {
        "id": "2D",
        "lcc": 0,
        "name": "Eastern Airlines",
        "type": "airline"
    }, {
        "id": "8Q",
        "lcc": 1,
        "name": "Onur Air",
        "type": "airline"
    }, {
        "id": "A5",
        "lcc": 1,
        "name": "HOP!",
        "type": "airline"
    }, {
        "id": "QT",
        "lcc": 0,
        "name": "Avianca Cargo",
        "type": "airline"
    }, {
        "id": "XT",
        "lcc": 0,
        "name": "Indonesia AirAsia X",
        "type": "airline"
    }, {
        "id": "YK",
        "lcc": 0,
        "name": "Avia Traffic Airline",
        "type": "airline"
    }, {
        "id": "SU",
        "lcc": 0,
        "name": "Aeroflot Russian Airlines",
        "type": "airline"
    }, {
        "id": "D7",
        "lcc": 0,
        "name": "AirAsia X",
        "type": "airline"
    }, {
        "id": "XJ",
        "lcc": 0,
        "name": "Thai AirAsia X",
        "type": "airline"
    }, {
        "id": "0B",
        "lcc": 1,
        "name": "Blue Air",
        "type": "airline"
    }, {
        "id": "9C",
        "lcc": 1,
        "name": "Spring Airlines",
        "type": "airline"
    }, {
        "id": "9Q",
        "lcc": 0,
        "name": "PB Air",
        "type": "airline"
    }, {
        "id": "6E",
        "lcc": 1,
        "name": "IndiGo Airlines",
        "type": "airline"
    }, {
        "id": "DX",
        "lcc": 0,
        "name": "DAT Danish Air Transport",
        "type": "airline"
    }, {
        "id": "__",
        "lcc": 0,
        "name": "FakeAirline",
        "type": "airline"
    }, {
        "id": "P8",
        "lcc": 0,
        "name": "Sprint Air",
        "type": "airline"
    }, {
        "id": "EQ",
        "lcc": 0,
        "name": "TAME",
        "type": "airline"
    }, {
        "id": "VB",
        "lcc": 1,
        "name": "VivaAerobus",
        "type": "airline"
    }, {
        "id": "VJ",
        "lcc": 1,
        "name": "VietJet Air",
        "type": "airline"
    }, {
        "id": "JQ",
        "lcc": 1,
        "name": "Jetstar Airways",
        "type": "airline"
    }, {
        "id": "GOTLAND",
        "lcc": "",
        "name": "Air Gotland",
        "type": "airline"
    }, {
        "id": "FOPSOLOVI",
        "lcc": "",
        "name": "Carrier XX",
        "type": "airline"
    }, {
        "id": "P7",
        "lcc": 0,
        "name": "Small Planet Airline",
        "type": "airline"
    }, {
        "id": "OP",
        "lcc": "",
        "name": "PassionAir",
        "type": "airline"
    }, {
        "id": "DS",
        "lcc": 0,
        "name": "EasyJet (DS)",
        "type": "airline"
    }, {
        "id": "LUEBECK",
        "lcc": "",
        "name": "Luebeck Air",
        "type": "airline"
    }, {
        "id": "M9",
        "lcc": 0,
        "name": "Motor Sich",
        "type": "airline"
    }, {
        "id": "CPHAIRTAXI",
        "lcc": "",
        "name": "Copenhagen Airtaxi",
        "type": "airline"
    }, {
        "id": "BTQ",
        "lcc": "",
        "name": "Boutique Flights",
        "type": "airline"
    }, {
        "id": "LIZ",
        "lcc": "",
        "name": "Air Liaison",
        "type": "airline"
    }, {
        "id": "TT",
        "lcc": 1,
        "name": "Tiger Airways Australia",
        "type": "airline"
    }, {
        "id": "GREENAIR",
        "lcc": "",
        "name": "Green Airlines",
        "type": "airline"
    }, {
        "id": "IB",
        "lcc": 0,
        "name": "Iberia Airlines",
        "type": "airline"
    }, {
        "id": "I0",
        "lcc": "",
        "name": "LEVEL operated by Iberia",
        "type": "airline"
    }, {
        "id": "RER",
        "lcc": "",
        "name": "Aeroregional",
        "type": "airline"
    }, {
        "id": "DU",
        "lcc": "",
        "name": "Air Liaison",
        "type": "airline"
    }, {
        "id": "BT",
        "lcc": 1,
        "name": "airBaltic",
        "type": "airline"
    }, {
        "id": "XR",
        "lcc": 0,
        "name": "Corendon Airlines Europe",
        "type": "airline"
    }, {
        "id": "BTF",
        "lcc": "",
        "name": "Boutique Flights",
        "type": "airline"
    }, {
        "id": "BAY",
        "lcc": "",
        "name": "Fly Erbil",
        "type": "airline"
    }, {
        "id": 11,
        "lcc": 0,
        "name": "TUIfly (X3)",
        "type": "airline"
    }, {
        "id": "8W",
        "lcc": "",
        "name": "Fly Allways",
        "type": "airline"
    }, {
        "id": "TRA",
        "lcc": "",
        "name": "Tara Air",
        "type": "airline"
    }, {
        "id": "6B",
        "lcc": 0,
        "name": "TUI fly Nordic",
        "type": "airline"
    }, {
        "id": "SR",
        "lcc": 0,
        "name": "Sundair",
        "type": "airline"
    }, {
        "id": "6R",
        "lcc": 1,
        "name": "Alrosa",
        "type": "airline"
    }, {
        "id": "Q2",
        "lcc": 0,
        "name": "Maldivian",
        "type": "airline"
    }, {
        "id": "DW",
        "lcc": "",
        "name": "Great Dane Airlines",
        "type": "airline"
    }, {
        "id": "YD",
        "lcc": 0,
        "name": "Gomelavia",
        "type": "airline"
    }, {
        "id": 99,
        "lcc": 0,
        "name": "Ciao Air",
        "type": "airline"
    }, {
        "id": "LC",
        "lcc": "",
        "name": "Skyway CR",
        "type": "airline"
    }, {
        "id": "LY",
        "lcc": 1,
        "name": "El Al Israel Airlines",
        "type": "airline"
    }, {
        "id": "6D",
        "lcc": 0,
        "name": "Pelita Air",
        "type": "airline"
    }, {
        "id": "QQ",
        "lcc": 0,
        "name": "Alliance Airlines",
        "type": "airline"
    }, {
        "id": "ARYSTAN",
        "lcc": "",
        "name": "FlyArystan",
        "type": "airline"
    }, {
        "id": "DK",
        "lcc": 0,
        "name": "Sunclass Airlines",
        "type": "airline"
    }, {
        "id": "WST",
        "lcc": "",
        "name": "Western Air",
        "type": "airline"
    }, {
        "id": "QM",
        "lcc": 0,
        "name": "Monacair",
        "type": "airline"
    }, {
        "id": "GDE",
        "lcc": "",
        "name": "Great Dane Airlines",
        "type": "airline"
    }, {
        "id": "BUTAAIR",
        "lcc": 1,
        "name": "Buta Airways",
        "type": "airline"
    }, {
        "id": "E8",
        "lcc": "",
        "name": "Tayaranjet",
        "type": "airline"
    }, {
        "id": "TS",
        "lcc": 0,
        "name": "Air Transat",
        "type": "airline"
    }, {
        "id": "4Z",
        "lcc": 0,
        "name": "Airlink (SAA)",
        "type": "airline"
    }, {
        "id": "IN",
        "lcc": 0,
        "name": "Nam Air",
        "type": "airline"
    }, {
        "id": "FE",
        "lcc": "",
        "name": "748 Air Services",
        "type": "airline"
    }, {
        "id": "IW",
        "lcc": 0,
        "name": "Wings Air",
        "type": "airline"
    }, {
        "id": "LA",
        "lcc": 0,
        "name": "LATAM Airlines",
        "type": "airline"
    }, {
        "id": "8E",
        "lcc": 0,
        "name": "Bering Air",
        "type": "airline"
    }, {
        "id": "IA",
        "lcc": 0,
        "name": "Iraqi Airways",
        "type": "airline"
    }, {
        "id": "UM",
        "lcc": 0,
        "name": "Air Zimbabwe",
        "type": "airline"
    }, {
        "id": "KA",
        "lcc": 0,
        "name": "Cathay Dragon",
        "type": "airline"
    }, {
        "id": "SP",
        "lcc": 0,
        "name": "SATA Air Acores",
        "type": "airline"
    }, {
        "id": "6G",
        "lcc": 0,
        "name": "Air Wales",
        "type": "airline"
    }, {
        "id": "6K",
        "lcc": 0,
        "name": "Asian Spirit",
        "type": "airline"
    }, {
        "id": "NU",
        "lcc": 0,
        "name": "Japan Transocean Air",
        "type": "airline"
    }, {
        "id": "UL",
        "lcc": 0,
        "name": "SriLankan Airlines",
        "type": "airline"
    }, {
        "id": "4M",
        "lcc": 0,
        "name": "LATAM Argentina",
        "type": "airline"
    }, {
        "id": "XL",
        "lcc": 0,
        "name": "LATAM Ecuador",
        "type": "airline"
    }, {
        "id": "JC",
        "lcc": 0,
        "name": "JAL Express",
        "type": "airline"
    }, {
        "id": "BI",
        "lcc": 0,
        "name": "Royal Brunei Airlines",
        "type": "airline"
    }, {
        "id": "KQ",
        "lcc": 0,
        "name": "Kenya Airways",
        "type": "airline"
    }, {
        "id": "AR",
        "lcc": 0,
        "name": "Aerolineas Argentinas",
        "type": "airline"
    }, {
        "id": "AI",
        "lcc": 0,
        "name": "Air India Limited",
        "type": "airline"
    }, {
        "id": "BR",
        "lcc": 1,
        "name": "EVA Air",
        "type": "airline"
    }, {
        "id": "SV",
        "lcc": 0,
        "name": "Saudi Arabian Airlines",
        "type": "airline"
    }, {
        "id": "NM",
        "lcc": 0,
        "name": "Air Moana",
        "type": "airline"
    }, {
        "id": "BN",
        "lcc": 0,
        "name": "Luxwing",
        "type": "airline"
    }, {
        "id": "RO",
        "lcc": 0,
        "name": "Tarom",
        "type": "airline"
    }, {
        "id": "QI",
        "lcc": 0,
        "name": "Ibom Air",
        "type": "airline"
    }, {
        "id": "TR",
        "lcc": 1,
        "name": "Scoot",
        "type": "airline"
    }, {
        "id": "MU",
        "lcc": 0,
        "name": "China Eastern Airlines",
        "type": "airline"
    }, {
        "id": "4P",
        "lcc": 0,
        "name": "Regional sky",
        "type": "airline"
    }, {
        "id": "MS",
        "lcc": 0,
        "name": "Egyptair",
        "type": "airline"
    }, {
        "id": "CAT",
        "lcc": "",
        "name": "Copehagen Air Taxi",
        "type": "airline"
    }, {
        "id": "8L",
        "lcc": 1,
        "name": "Lucky air",
        "type": "airline"
    }, {
        "id": "2Q",
        "lcc": 0,
        "name": "Air Cargo Carriers",
        "type": "airline"
    }, {
        "id": "P9",
        "lcc": 1,
        "name": "Peruvian Airlines",
        "type": "airline"
    }, {
        "id": "RJ",
        "lcc": 0,
        "name": "Royal Jordanian",
        "type": "airline"
    }, {
        "id": "ZH",
        "lcc": 0,
        "name": "Shenzhen Airlines",
        "type": "airline"
    }, {
        "id": "EZ",
        "lcc": 0,
        "name": "Sun Air of Scandinavia",
        "type": "airline"
    }, {
        "id": "M4",
        "lcc": 0,
        "name": "Mistral Air",
        "type": "airline"
    }, {
        "id": "IS",
        "lcc": 0,
        "name": "Fly Ais Airlines",
        "type": "airline"
    }, {
        "id": "JW",
        "lcc": 1,
        "name": "Vanilla Air",
        "type": "airline"
    }, {
        "id": "VN",
        "lcc": 0,
        "name": "Vietnam Airlines",
        "type": "airline"
    }, {
        "id": "CX",
        "lcc": 0,
        "name": "Cathay Pacific",
        "type": "airline"
    }, {
        "id": "QF",
        "lcc": 0,
        "name": "Qantas",
        "type": "airline"
    }, {
        "id": "JL",
        "lcc": 0,
        "name": "Japan Airlines",
        "type": "airline"
    }, {
        "id": "UO",
        "lcc": 1,
        "name": "Hong Kong Express Airways",
        "type": "airline"
    }, {
        "id": "CM",
        "lcc": 0,
        "name": "Copa Airlines",
        "type": "airline"
    }, {
        "id": "UX",
        "lcc": 0,
        "name": "Air Europa",
        "type": "airline"
    }, {
        "id": "OU",
        "lcc": 0,
        "name": "Croatia Airlines",
        "type": "airline"
    }, {
        "id": "ET",
        "lcc": 0,
        "name": "Ethiopian Airlines",
        "type": "airline"
    }, {
        "id": "AA",
        "lcc": 0,
        "name": "American Airlines",
        "type": "airline"
    }, {
        "id": "TA",
        "lcc": 0,
        "name": "Avianca El Salvador",
        "type": "airline"
    }, {
        "id": "H8",
        "lcc": 0,
        "name": "Sky Airline Peru",
        "type": "airline"
    }, {
        "id": "AC",
        "lcc": 0,
        "name": "Air Canada",
        "type": "airline"
    }, {
        "id": "SQ",
        "lcc": 0,
        "name": "Singapore Airlines",
        "type": "airline"
    }, {
        "id": "OS",
        "lcc": 0,
        "name": "Austrian Airlines",
        "type": "airline"
    }, {
        "id": 20,
        "lcc": 0,
        "name": "Air Salone",
        "type": "airline"
    }, {
        "id": "Z8",
        "lcc": 1,
        "name": "Amaszonas",
        "type": "airline"
    }, {
        "id": "4X",
        "lcc": 0,
        "name": "Red Jet Mexico",
        "type": "airline"
    }, {
        "id": "JP",
        "lcc": 0,
        "name": "Adria Airways",
        "type": "airline"
    }, {
        "id": "OZ",
        "lcc": 0,
        "name": "Asiana Airlines",
        "type": "airline"
    }, {
        "id": "ME",
        "lcc": 0,
        "name": "Middle East Airlines",
        "type": "airline"
    }, {
        "id": "DG",
        "lcc": 0,
        "name": "CebGo",
        "type": "airline"
    }, {
        "id": "SC",
        "lcc": 0,
        "name": "Shandong Airlines",
        "type": "airline"
    }, {
        "id": "LX",
        "lcc": 0,
        "name": "Swiss International Air Lines",
        "type": "airline"
    }, {
        "id": "BG",
        "lcc": 1,
        "name": "Biman Bangladesh Airlines",
        "type": "airline"
    }, {
        "id": "SN",
        "lcc": 0,
        "name": "Brussels Airlines",
        "type": "airline"
    }, {
        "id": "QR",
        "lcc": 0,
        "name": "Qatar Airways",
        "type": "airline"
    }, {
        "id": "SK",
        "lcc": 0,
        "name": "SAS",
        "type": "airline"
    }, {
        "id": "QU",
        "lcc": "",
        "name": "Azur Air Ukraine",
        "type": "airline"
    }, {
        "id": "DD",
        "lcc": 1,
        "name": "Nok Air",
        "type": "airline"
    }, {
        "id": "CI",
        "lcc": 0,
        "name": "China Airlines",
        "type": "airline"
    }, {
        "id": "A3",
        "lcc": 1,
        "name": "Aegean",
        "type": "airline"
    }, {
        "id": "UQ",
        "lcc": 0,
        "name": "Urumqi Airlines",
        "type": "airline"
    }, {
        "id": "AM",
        "lcc": 0,
        "name": "AeroMéxico",
        "type": "airline"
    }, {
        "id": "GA",
        "lcc": 0,
        "name": "Garuda Indonesia",
        "type": "airline"
    }, {
        "id": "AEH",
        "lcc": "",
        "name": "Aero4M",
        "type": "airline"
    }, {
        "id": "LF",
        "lcc": 0,
        "name": "Contour Airlines",
        "type": "airline"
    }, {
        "id": "OK",
        "lcc": 0,
        "name": "Czech Airlines",
        "type": "airline"
    }, {
        "id": "PN",
        "lcc": 1,
        "name": "West Air China",
        "type": "airline"
    }, {
        "id": "NZ",
        "lcc": 1,
        "name": "Air New Zealand",
        "type": "airline"
    }, {
        "id": "3Q",
        "lcc": 0,
        "name": "Yunnan Airlines",
        "type": "airline"
    }, {
        "id": "KP",
        "lcc": 0,
        "name": "ASKY Airlines",
        "type": "airline"
    }, {
        "id": "6W",
        "lcc": 1,
        "name": "FlyBosnia",
        "type": "airline"
    }, {
        "id": "RT",
        "lcc": 0,
        "name": "JSC UVT Aero",
        "type": "airline"
    }, {
        "id": "S9",
        "lcc": 0,
        "name": "Starbow Airlines",
        "type": "airline"
    }, {
        "id": "D4",
        "lcc": 0,
        "name": "Aerodart",
        "type": "airline"
    }, {
        "id": "SX",
        "lcc": 0,
        "name": "SkyWork Airlines",
        "type": "airline"
    }, {
        "id": "QA",
        "lcc": 0,
        "name": "Click (Mexicana)",
        "type": "airline"
    }, {
        "id": "V0",
        "lcc": 1,
        "name": "Conviasa",
        "type": "airline"
    }, {
        "id": "ZB",
        "lcc": 1,
        "name": "Air Albania",
        "type": "airline"
    }, {
        "id": "WD",
        "lcc": 0,
        "name": "Amsterdam Airlines",
        "type": "airline"
    }, {
        "id": "Y8",
        "lcc": 0,
        "name": "Suparna Airlines",
        "type": "airline"
    }, {
        "id": "DI",
        "lcc": 0,
        "name": "Norwegian Air UK",
        "type": "airline"
    }, {
        "id": "5W",
        "lcc": 0,
        "name": "Wizz Air Abu Dhabi",
        "type": "airline"
    }, {
        "id": "CU",
        "lcc": 0,
        "name": "Cubana de Aviación",
        "type": "airline"
    }, {
        "id": "PW",
        "lcc": 0,
        "name": "Precision Air",
        "type": "airline"
    }, {
        "id": "RS",
        "lcc": 0,
        "name": "Air Seoul",
        "type": "airline"
    }, {
        "id": "F3",
        "lcc": 0,
        "name": "Flyadeal",
        "type": "airline"
    }, {
        "id": "QV",
        "lcc": 0,
        "name": "Lao Airlines",
        "type": "airline"
    }, {
        "id": "7H",
        "lcc": 1,
        "name": "Ravn Alaska",
        "type": "airline"
    }, {
        "id": "X4",
        "lcc": 0,
        "name": "Air Excursions",
        "type": "airline"
    }, {
        "id": "HZ",
        "lcc": 0,
        "name": "Aurora Airlines",
        "type": "airline"
    }, {
        "id": "FD",
        "lcc": 0,
        "name": "Thai AirAsia",
        "type": "airline"
    }, {
        "id": "IP",
        "lcc": 0,
        "name": "Pelita Air",
        "type": "airline"
    }, {
        "id": "BJ",
        "lcc": 0,
        "name": "NouvelAir",
        "type": "airline"
    }, {
        "id": "JY",
        "lcc": 0,
        "name": "interCaribbean Airways",
        "type": "airline"
    }, {
        "id": "W3",
        "lcc": 0,
        "name": "Arik Air",
        "type": "airline"
    }, {
        "id": "J5",
        "lcc": 0,
        "name": "Alaska Seaplane Service",
        "type": "airline"
    }, {
        "id": "OV",
        "lcc": 1,
        "name": "SalamAir",
        "type": "airline"
    }, {
        "id": "PE",
        "lcc": 0,
        "name": "People's Viennaline PE",
        "type": "airline"
    }, {
        "id": "8B",
        "lcc": 0,
        "name": "TransNusa",
        "type": "airline"
    }, {
        "id": "KB",
        "lcc": 0,
        "name": "Druk Air",
        "type": "airline"
    }, {
        "id": "JI",
        "lcc": 0,
        "name": "Meraj Air",
        "type": "airline"
    }, {
        "id": "9J",
        "lcc": 0,
        "name": "Dana Airlines Limited",
        "type": "airline"
    }, {
        "id": "SF",
        "lcc": 0,
        "name": "Tassili Airlines",
        "type": "airline"
    }, {
        "id": "XU",
        "lcc": 1,
        "name": "African Express",
        "type": "airline"
    }, {
        "id": "A2",
        "lcc": 0,
        "name": "Animawings",
        "type": "airline"
    }, {
        "id": "GU",
        "lcc": 1,
        "name": "Avianca Guatemala",
        "type": "airline"
    }, {
        "id": "2P",
        "lcc": 1,
        "name": "PAL Express",
        "type": "airline"
    }, {
        "id": "8G",
        "lcc": 0,
        "name": "Mid Africa Aviation",
        "type": "airline"
    }, {
        "id": "3I",
        "lcc": 0,
        "name": "Air Comet Chile",
        "type": "airline"
    }, {
        "id": "MV",
        "lcc": 0,
        "name": "Air Mediterranean",
        "type": "airline"
    }, {
        "id": "Y2",
        "lcc": 0,
        "name": "AirCentury",
        "type": "airline"
    }, {
        "id": "L7",
        "lcc": 0,
        "name": "Lugansk Airlines",
        "type": "airline"
    }, {
        "id": "A6",
        "lcc": 0,
        "name": "Air Travel",
        "type": "airline"
    }, {
        "id": "S1",
        "lcc": 0,
        "name": "Serbian Airlines",
        "type": "airline"
    }, {
        "id": "PT",
        "lcc": 0,
        "name": "Red Jet Andes",
        "type": "airline"
    }, {
        "id": "NN",
        "lcc": 1,
        "name": "VIM Airlines",
        "type": "airline"
    }, {
        "id": "JON",
        "lcc": "",
        "name": "Jonair",
        "type": "airline"
    }, {
        "id": "NY",
        "lcc": 1,
        "name": "Air Iceland Connect",
        "type": "airline"
    }, {
        "id": "Z4",
        "lcc": 0,
        "name": "Ibom Air",
        "type": "airline"
    }, {
        "id": "II",
        "lcc": 0,
        "name": "IBC Airways",
        "type": "airline"
    }, {
        "id": "OO",
        "lcc": 0,
        "name": "SkyWest",
        "type": "airline"
    }, {
        "id": "SE",
        "lcc": 1,
        "name": "XL Airways France",
        "type": "airline"
    }, {
        "id": "FL",
        "lcc": 0,
        "name": "Air Leap",
        "type": "airline"
    }, {
        "id": "3U",
        "lcc": 0,
        "name": "Sichuan Airlines",
        "type": "airline"
    }, {
        "id": "Y7",
        "lcc": 1,
        "name": "NordStar Airlines",
        "type": "airline"
    }, {
        "id": "LT",
        "lcc": 0,
        "name": "LongJiang Airlines",
        "type": "airline"
    }, {
        "id": "HN",
        "lcc": 0,
        "name": "Equair",
        "type": "airline"
    }, {
        "id": "AD",
        "lcc": 1,
        "name": "Azul",
        "type": "airline"
    }, {
        "id": 0,
        "lcc": 1,
        "name": "Anadolujet",
        "type": "airline"
    }, {
        "id": "9U",
        "lcc": 0,
        "name": "Air Moldova",
        "type": "airline"
    }, {
        "id": "HG",
        "lcc": 0,
        "name": "Hibernian Airlines",
        "type": "airline"
    }, {
        "id": "AP",
        "lcc": 0,
        "name": "AlbaStar",
        "type": "airline"
    }, {
        "id": "XQ",
        "lcc": 1,
        "name": "SunExpress",
        "type": "airline"
    }, {
        "id": "2U",
        "lcc": 0,
        "name": "Sun d'Or International Airlines",
        "type": "airline"
    }, {
        "id": "4D",
        "lcc": 0,
        "name": "Aero Dirrekt",
        "type": "airline"
    }, {
        "id": "M2",
        "lcc": 0,
        "name": "Rhein-Neckar Air",
        "type": "airline"
    }, {
        "id": "VV",
        "lcc": 0,
        "name": "Viva Air",
        "type": "airline"
    }, {
        "id": "KS",
        "lcc": 0,
        "name": "Air Connect",
        "type": "airline"
    }, {
        "id": "VRG",
        "lcc": 1,
        "name": "Voyage Air",
        "type": "airline"
    }, {
        "id": "CD",
        "lcc": 0,
        "name": "Corendon Dutch Airlines B.V.",
        "type": "airline"
    }, {
        "id": "MK",
        "lcc": 0,
        "name": "Air Mauritius",
        "type": "airline"
    }, {
        "id": "M1",
        "lcc": 0,
        "name": "Maryland Air",
        "type": "airline"
    }, {
        "id": "OF",
        "lcc": 0,
        "name": "Overland Airways",
        "type": "airline"
    }, {
        "id": "DJ",
        "lcc": 1,
        "name": "AirAsia Japan",
        "type": "airline"
    }, {
        "id": "ZM",
        "lcc": 0,
        "name": "Air Manas",
        "type": "airline"
    }, {
        "id": "B4",
        "lcc": 0,
        "name": "ZanAir",
        "type": "airline"
    }, {
        "id": "T0",
        "lcc": 0,
        "name": "Avianca Peru",
        "type": "airline"
    }, {
        "id": "YW",
        "lcc": 0,
        "name": "Air Nostrum",
        "type": "airline"
    }, {
        "id": "XM",
        "lcc": 0,
        "name": "Alitalia Express",
        "type": "airline"
    }, {
        "id": "FK",
        "lcc": 0,
        "name": "Africa West",
        "type": "airline"
    }, {
        "id": "GG",
        "lcc": 0,
        "name": "Air Guyane",
        "type": "airline"
    }, {
        "id": "TE",
        "lcc": 0,
        "name": "FlyLal",
        "type": "airline"
    }, {
        "id": "WI",
        "lcc": 0,
        "name": "White Airways",
        "type": "airline"
    }, {
        "id": "H1",
        "lcc": 0,
        "name": "Hahn Air",
        "type": "airline"
    }, {
        "id": "PP",
        "lcc": 0,
        "name": "Air Indus",
        "type": "airline"
    }, {
        "id": "KJ",
        "lcc": 0,
        "name": "British Mediterranean Airways",
        "type": "airline"
    }, {
        "id": "U1",
        "lcc": 0,
        "name": "Aviabus",
        "type": "airline"
    }, {
        "id": "VL",
        "lcc": 0,
        "name": "Med-View Airline",
        "type": "airline"
    }, {
        "id": "R7",
        "lcc": 0,
        "name": "Aserca Airlines",
        "type": "airline"
    }, {
        "id": "O7",
        "lcc": 0,
        "name": "Orenburzhye Airline",
        "type": "airline"
    }, {
        "id": "KO",
        "lcc": 0,
        "name": "Komiaviatrans",
        "type": "airline"
    }, {
        "id": "W8",
        "lcc": 0,
        "name": "Cargojet Airways",
        "type": "airline"
    }, {
        "id": "3J",
        "lcc": 0,
        "name": "Jubba Airways",
        "type": "airline"
    }, {
        "id": "3E",
        "lcc": 0,
        "name": "Air Choice One",
        "type": "airline"
    }, {
        "id": "7E",
        "lcc": 0,
        "name": "Sylt Air",
        "type": "airline"
    }, {
        "id": "UI",
        "lcc": 0,
        "name": "Auric Air",
        "type": "airline"
    }, {
        "id": "EI",
        "lcc": 1,
        "name": "Aer Lingus",
        "type": "airline"
    }, {
        "id": "LP",
        "lcc": 0,
        "name": "LATAM Peru",
        "type": "airline"
    }, {
        "id": "I8",
        "lcc": 0,
        "name": "Izhavia",
        "type": "airline"
    }, {
        "id": "IQ",
        "lcc": 0,
        "name": "Qazaq Air",
        "type": "airline"
    }, {
        "id": "LI",
        "lcc": 0,
        "name": "Leeward Islands Air Transport",
        "type": "airline"
    }, {
        "id": "BU",
        "lcc": 0,
        "name": "Compagnie Africaine d'Aviation CAA",
        "type": "airline"
    }, {
        "id": "PM",
        "lcc": 0,
        "name": "Canary Fly",
        "type": "airline"
    }, {
        "id": "W7",
        "lcc": 0,
        "name": "Wings of Lebanon",
        "type": "airline"
    }, {
        "id": "VU",
        "lcc": 0,
        "name": "Vietravel Airlines",
        "type": "airline"
    }, {
        "id": "ZE",
        "lcc": 1,
        "name": "Eastar Jet",
        "type": "airline"
    }, {
        "id": "9P",
        "lcc": 1,
        "name": "Air Arabia Jordan",
        "type": "airline"
    }, {
        "id": "6P",
        "lcc": 0,
        "name": "Gryphon Airlines",
        "type": "airline"
    }, {
        "id": "U8",
        "lcc": 0,
        "name": "TUS Airways",
        "type": "airline"
    }, {
        "id": "NP",
        "lcc": 0,
        "name": "Nile Air",
        "type": "airline"
    }, {
        "id": "LG",
        "lcc": 0,
        "name": "Luxair",
        "type": "airline"
    }, {
        "id": "TC",
        "lcc": 0,
        "name": "Air Tanzania",
        "type": "airline"
    }, {
        "id": "NE",
        "lcc": 0,
        "name": "Nesma Airlines",
        "type": "airline"
    }, {
        "id": "N7",
        "lcc": 0,
        "name": "Nordic Regional Airlines",
        "type": "airline"
    }, {
        "id": "MD",
        "lcc": 0,
        "name": "Air Madagascar",
        "type": "airline"
    }, {
        "id": "0X",
        "lcc": 0,
        "name": "Copenhagen Express",
        "type": "airline"
    }, {
        "id": "GH",
        "lcc": 0,
        "name": "Globus",
        "type": "airline"
    }, {
        "id": "WO",
        "lcc": 0,
        "name": "Swoop",
        "type": "airline"
    }, {
        "id": "RC",
        "lcc": 0,
        "name": "Atlantic Airways",
        "type": "airline"
    }, {
        "id": "FJ",
        "lcc": 0,
        "name": "Fiji Airways",
        "type": "airline"
    }, {
        "id": "ZT",
        "lcc": 0,
        "name": "Titan Airways",
        "type": "airline"
    }, {
        "id": "CT",
        "lcc": 0,
        "name": "Alitalia Cityliner",
        "type": "airline"
    }, {
        "id": "B9",
        "lcc": 0,
        "name": "Air Bangladesh",
        "type": "airline"
    }, {
        "id": "5Y",
        "lcc": 0,
        "name": "Atlas Air",
        "type": "airline"
    }, {
        "id": "OT",
        "lcc": 0,
        "name": "Aeropelican Air Services",
        "type": "airline"
    }, {
        "id": "5L",
        "lcc": 0,
        "name": "Aerosur",
        "type": "airline"
    }, {
        "id": "Z3",
        "lcc": 0,
        "name": "Avient Aviation",
        "type": "airline"
    }, {
        "id": "JS",
        "lcc": 0,
        "name": "Air Koryo",
        "type": "airline"
    }, {
        "id": "RE",
        "lcc": 0,
        "name": "Stobart Air",
        "type": "airline"
    }, {
        "id": "EO",
        "lcc": 0,
        "name": "Pegas Fly",
        "type": "airline"
    }, {
        "id": "8N",
        "lcc": 0,
        "name": "Regional Air Services",
        "type": "airline"
    }, {
        "id": "FV",
        "lcc": 0,
        "name": "Rossiya-Russian Airlines",
        "type": "airline"
    }, {
        "id": "NF",
        "lcc": 0,
        "name": "Air Vanuatu",
        "type": "airline"
    }, {
        "id": "GS",
        "lcc": 0,
        "name": "Tianjin Airlines",
        "type": "airline"
    }, {
        "id": "EN",
        "lcc": 0,
        "name": "Air Dolomiti",
        "type": "airline"
    }, {
        "id": "IO",
        "lcc": 1,
        "name": "IrAero",
        "type": "airline"
    }, {
        "id": "E1",
        "lcc": 0,
        "name": "Usa Sky Cargo",
        "type": "airline"
    }, {
        "id": "3R",
        "lcc": 0,
        "name": "Divi Divi Air",
        "type": "airline"
    }, {
        "id": "BS",
        "lcc": 0,
        "name": "US-Bangla Airlines",
        "type": "airline"
    }, {
        "id": "DQ",
        "lcc": 0,
        "name": "Coastal Air",
        "type": "airline"
    }, {
        "id": "B8",
        "lcc": 0,
        "name": "Eritrean Airlines",
        "type": "airline"
    }, {
        "id": "BD",
        "lcc": 0,
        "name": "Cambodia Bayon Airlines",
        "type": "airline"
    }, {
        "id": "LU",
        "lcc": 0,
        "name": "LATAM Express",
        "type": "airline"
    }, {
        "id": "2T",
        "lcc": 0,
        "name": "TruJet",
        "type": "airline"
    }, {
        "id": "7G",
        "lcc": 1,
        "name": "Star Flyer",
        "type": "airline"
    }, {
        "id": "E3",
        "lcc": 0,
        "name": "EGO Airways",
        "type": "airline"
    }, {
        "id": "DJB",
        "lcc": "",
        "name": "Air Djibouti",
        "type": "airline"
    }, {
        "id": "ZL",
        "lcc": 1,
        "name": "Regional Express",
        "type": "airline"
    }, {
        "id": "SS",
        "lcc": 0,
        "name": "Corsair International",
        "type": "airline"
    }, {
        "id": "OI",
        "lcc": 0,
        "name": "Hinterland Aviation",
        "type": "airline"
    }, {
        "id": "PD",
        "lcc": 1,
        "name": "Porter Airlines",
        "type": "airline"
    }, {
        "id": "KR",
        "lcc": 0,
        "name": "Cambodia Airways",
        "type": "airline"
    }, {
        "id": "DO",
        "lcc": 0,
        "name": "Sky High Aviation Services",
        "type": "airline"
    }, {
        "id": "QH",
        "lcc": 0,
        "name": "Bamboo Airways",
        "type": "airline"
    }, {
        "id": "8D",
        "lcc": 0,
        "name": "FitsAir",
        "type": "airline"
    }, {
        "id": "AS",
        "lcc": 0,
        "name": "Alaska Airlines",
        "type": "airline"
    }, {
        "id": "J8",
        "lcc": 0,
        "name": "Berjaya Air",
        "type": "airline"
    }, {
        "id": "2L",
        "lcc": 0,
        "name": "Helvetic Airways",
        "type": "airline"
    }, {
        "id": "WX",
        "lcc": 0,
        "name": "CityJet",
        "type": "airline"
    }, {
        "id": "LR",
        "lcc": 0,
        "name": "Avianca Costa Rica",
        "type": "airline"
    }, {
        "id": "2K",
        "lcc": 0,
        "name": "Avianca Ecuador",
        "type": "airline"
    }, {
        "id": "AJ",
        "lcc": 0,
        "name": "Aero Contractors",
        "type": "airline"
    }, {
        "id": "CH",
        "lcc": 0,
        "name": "Bemidji Airlines",
        "type": "airline"
    }, {
        "id": "L8",
        "lcc": 0,
        "name": "Line Blue",
        "type": "airline"
    }, {
        "id": "GF",
        "lcc": 0,
        "name": "Gulf Air Bahrain",
        "type": "airline"
    }, {
        "id": "TY",
        "lcc": 0,
        "name": "Air Caledonie",
        "type": "airline"
    }, {
        "id": "G0",
        "lcc": 0,
        "name": "Ghana International Airlines",
        "type": "airline"
    }, {
        "id": "IC",
        "lcc": 0,
        "name": "Indian Airlines",
        "type": "airline"
    }, {
        "id": "D6",
        "lcc": 0,
        "name": "Interair South Africa",
        "type": "airline"
    }, {
        "id": "KV",
        "lcc": 0,
        "name": "Kavminvodyavia",
        "type": "airline"
    }, {
        "id": "7K",
        "lcc": 0,
        "name": "Kogalymavia Air Company",
        "type": "airline"
    }, {
        "id": "NG",
        "lcc": 0,
        "name": "Lauda Air",
        "type": "airline"
    }, {
        "id": "6A",
        "lcc": 0,
        "name": "Armenia Airways",
        "type": "airline"
    }, {
        "id": "MZ",
        "lcc": 0,
        "name": "Merpati Nusantara Airlines",
        "type": "airline"
    }, {
        "id": "PV",
        "lcc": 0,
        "name": "St Barth Commuter",
        "type": "airline"
    }, {
        "id": "FW",
        "lcc": 0,
        "name": "Ibex Airlines",
        "type": "airline"
    }, {
        "id": "4G",
        "lcc": 0,
        "name": "Gazpromavia",
        "type": "airline"
    }, {
        "id": "M5",
        "lcc": 0,
        "name": "Kenmore Air",
        "type": "airline"
    }, {
        "id": "LN",
        "lcc": 0,
        "name": "Libyan Arab Airlines",
        "type": "airline"
    }, {
        "id": "T3",
        "lcc": 0,
        "name": "Eastern Airways",
        "type": "airline"
    }, {
        "id": "UZ",
        "lcc": 0,
        "name": "El-Buraq Air Transport",
        "type": "airline"
    }, {
        "id": "2N",
        "lcc": 1,
        "name": "NextJet",
        "type": "airline"
    }, {
        "id": "KF",
        "lcc": 0,
        "name": "Air Belgium",
        "type": "airline"
    }, {
        "id": "EH",
        "lcc": 0,
        "name": "ANA Wings",
        "type": "airline"
    }, {
        "id": "MP",
        "lcc": 0,
        "name": "Martinair",
        "type": "airline"
    }, {
        "id": "YV",
        "lcc": 0,
        "name": "Mesa Airlines",
        "type": "airline"
    }, {
        "id": "NC",
        "lcc": 0,
        "name": "National Jet Systems",
        "type": "airline"
    }, {
        "id": "8J",
        "lcc": 0,
        "name": "Eco Jet",
        "type": "airline"
    }, {
        "id": "PI",
        "lcc": 0,
        "name": "Polar Airlines",
        "type": "airline"
    }, {
        "id": "3P",
        "lcc": 0,
        "name": "Tiara Air",
        "type": "airline"
    }, {
        "id": "U7",
        "lcc": 0,
        "name": "Northern Dene Airways",
        "type": "airline"
    }, {
        "id": "O2",
        "lcc": 0,
        "name": "Linear Air",
        "type": "airline"
    }, {
        "id": "LITOR",
        "lcc": "",
        "name": "LitorAli Airlines",
        "type": "airline"
    }, {
        "id": "RL",
        "lcc": 0,
        "name": "Royal Flight Airlines",
        "type": "airline"
    }, {
        "id": "8I",
        "lcc": 0,
        "name": "MyAir",
        "type": "airline"
    }, {
        "id": "Y9",
        "lcc": 0,
        "name": "Lynx Air",
        "type": "airline"
    }, {
        "id": "MX",
        "lcc": 0,
        "name": "MX",
        "type": "airline"
    }, {
        "id": "EA",
        "lcc": 0,
        "name": "Emerald Airlines",
        "type": "airline"
    }, {
        "id": "5U",
        "lcc": 0,
        "name": "Transportes Aéreos Guatemaltecos",
        "type": "airline"
    }, {
        "id": "UD",
        "lcc": 0,
        "name": "UR Airlines",
        "type": "airline"
    }, {
        "id": "H7",
        "lcc": 0,
        "name": "HiSky",
        "type": "airline"
    }, {
        "id": "RZ",
        "lcc": 0,
        "name": "Sansa Air",
        "type": "airline"
    }, {
        "id": "SZ",
        "lcc": 0,
        "name": "Somon Air",
        "type": "airline"
    }, {
        "id": "A9",
        "lcc": 0,
        "name": "Georgian Airways",
        "type": "airline"
    }, {
        "id": "J4",
        "lcc": 0,
        "name": "Badr Airlines",
        "type": "airline"
    }, {
        "id": "5M",
        "lcc": 0,
        "name": "Sibaviatrans",
        "type": "airline"
    }, {
        "id": "ZS",
        "lcc": 0,
        "name": "Sama Airlines",
        "type": "airline"
    }, {
        "id": "8P",
        "lcc": 0,
        "name": "Pacific Coastal Airline",
        "type": "airline"
    }, {
        "id": "JH",
        "lcc": 0,
        "name": "Fuji Dream Airlines",
        "type": "airline"
    }, {
        "id": "J3",
        "lcc": 0,
        "name": "Northwestern Air",
        "type": "airline"
    }, {
        "id": "U4",
        "lcc": 0,
        "name": "Buddha Air",
        "type": "airline"
    }, {
        "id": "GZ",
        "lcc": 0,
        "name": "Air Rarotonga",
        "type": "airline"
    }, {
        "id": "QB",
        "lcc": 0,
        "name": "Qeshm Air",
        "type": "airline"
    }, {
        "id": "RA",
        "lcc": 0,
        "name": "Nepal Airlines",
        "type": "airline"
    }, {
        "id": "KE",
        "lcc": 0,
        "name": "Korean Air",
        "type": "airline"
    }, {
        "id": "IK",
        "lcc": 0,
        "name": "Air Kiribati",
        "type": "airline"
    }, {
        "id": "BB",
        "lcc": 0,
        "name": "Seaborne Airlines",
        "type": "airline"
    }, {
        "id": "GM",
        "lcc": 0,
        "name": "Chair Airlines",
        "type": "airline"
    }, {
        "id": "MQ",
        "lcc": 0,
        "name": "Envoy Air as American Eagle",
        "type": "airline"
    }, {
        "id": "2M",
        "lcc": 0,
        "name": "Maya Island Air",
        "type": "airline"
    }, {
        "id": "I9",
        "lcc": 0,
        "name": "Air Italy",
        "type": "airline"
    }, {
        "id": "8F",
        "lcc": 0,
        "name": "STP Airways",
        "type": "airline"
    }, {
        "id": "I7",
        "lcc": 0,
        "name": "Int'Air Iles",
        "type": "airline"
    }, {
        "id": "MY",
        "lcc": 0,
        "name": "MASwings",
        "type": "airline"
    }, {
        "id": "VX",
        "lcc": 1,
        "name": "Virgin America",
        "type": "airline"
    }, {
        "id": "ON",
        "lcc": 0,
        "name": "Nauru Air Corporation",
        "type": "airline"
    }, {
        "id": "RH",
        "lcc": 0,
        "name": "Republic Express Airlines",
        "type": "airline"
    }, {
        "id": "EJ",
        "lcc": 0,
        "name": "New England Airlines",
        "type": "airline"
    }, {
        "id": "P2",
        "lcc": 1,
        "name": "Air Kenya",
        "type": "airline"
    }, {
        "id": "S5",
        "lcc": 0,
        "name": "Shuttle America",
        "type": "airline"
    }, {
        "id": "G6",
        "lcc": 0,
        "name": "Air Volga",
        "type": "airline"
    }, {
        "id": "KW",
        "lcc": 0,
        "name": "Carnival Air Lines",
        "type": "airline"
    }, {
        "id": "3T",
        "lcc": 0,
        "name": "Turan Air",
        "type": "airline"
    }, {
        "id": "UF",
        "lcc": 0,
        "name": "UM Airlines",
        "type": "airline"
    }, {
        "id": "G7",
        "lcc": 0,
        "name": "GoJet Airlines",
        "type": "airline"
    }, {
        "id": "9E",
        "lcc": 0,
        "name": "Endeavor Air",
        "type": "airline"
    }, {
        "id": "YX",
        "lcc": 0,
        "name": "Republic Airlines",
        "type": "airline"
    }, {
        "id": "US",
        "lcc": 0,
        "name": "US Airways",
        "type": "airline"
    }, {
        "id": "CG",
        "lcc": 0,
        "name": "PNG Air",
        "type": "airline"
    }, {
        "id": "GE",
        "lcc": 0,
        "name": "LIFT airline",
        "type": "airline"
    }, {
        "id": "FS",
        "lcc": 0,
        "name": "Flyr",
        "type": "airline"
    }, {
        "id": "XZ",
        "lcc": 0,
        "name": "Aeroitalia",
        "type": "airline"
    }, {
        "id": "PH",
        "lcc": 0,
        "name": "Polynesian Airlines",
        "type": "airline"
    }, {
        "id": "EK",
        "lcc": 0,
        "name": "Emirates",
        "type": "airline"
    }, {
        "id": "PU",
        "lcc": 0,
        "name": "Plus Ultra Lineas Aereas",
        "type": "airline"
    }, {
        "id": "WY",
        "lcc": 0,
        "name": "Oman Air",
        "type": "airline"
    }, {
        "id": "V7",
        "lcc": 1,
        "name": "Volotea",
        "type": "airline"
    }, {
        "id": "8O",
        "lcc": 0,
        "name": "West Coast Air",
        "type": "airline"
    }, {
        "id": "FT",
        "lcc": 0,
        "name": "FlyEgypt FT",
        "type": "airline"
    }, {
        "id": "RB",
        "lcc": 0,
        "name": "Syrian Arab Airlines",
        "type": "airline"
    }, {
        "id": 47,
        "lcc": 0,
        "name": 88,
        "type": "airline"
    }, {
        "id": 69,
        "lcc": 0,
        "name": "Royal European Airlines",
        "type": "airline"
    }, {
        "id": "4L",
        "lcc": 0,
        "name": "Euroline",
        "type": "airline"
    }, {
        "id": "KT",
        "lcc": 0,
        "name": "VickJet",
        "type": "airline"
    }, {
        "id": "D1",
        "lcc": 0,
        "name": "Domenican Airlines",
        "type": "airline"
    }, {
        "id": "VG",
        "lcc": 0,
        "name": "VLM Airlines",
        "type": "airline"
    }, {
        "id": "V6",
        "lcc": 0,
        "name": "VIP Ecuador",
        "type": "airline"
    }, {
        "id": "NB",
        "lcc": 0,
        "name": "Sterling Airlines",
        "type": "airline"
    }, {
        "id": "B7",
        "lcc": 0,
        "name": "Uni Air",
        "type": "airline"
    }, {
        "id": "7Y",
        "lcc": 0,
        "name": "Mann Yadanarpon Airlines",
        "type": "airline"
    }, {
        "id": "JR",
        "lcc": 0,
        "name": "Joy Air",
        "type": "airline"
    }, {
        "id": "G5",
        "lcc": 0,
        "name": "China Express Airlines",
        "type": "airline"
    }, {
        "id": "SD",
        "lcc": 0,
        "name": "Sudan Airways",
        "type": "airline"
    }, {
        "id": "IY",
        "lcc": 0,
        "name": "Yemenia",
        "type": "airline"
    }, {
        "id": "AX",
        "lcc": 0,
        "name": "Trans States Airlines",
        "type": "airline"
    }, {
        "id": "9Y",
        "lcc": 0,
        "name": "Air Kazakhstan",
        "type": "airline"
    }, {
        "id": "PZ",
        "lcc": 0,
        "name": "LATAM Paraguay",
        "type": "airline"
    }, {
        "id": "S6",
        "lcc": 0,
        "name": "Sunrise Airways",
        "type": "airline"
    }, {
        "id": "O6",
        "lcc": 0,
        "name": "Avianca Brazil",
        "type": "airline"
    }, {
        "id": "TI",
        "lcc": 0,
        "name": "Tailwind Airlines",
        "type": "airline"
    }, {
        "id": "SZS",
        "lcc": "",
        "name": "Scandinavian Airlines Ireland",
        "type": "airline"
    }, {
        "id": "1T",
        "lcc": 0,
        "name": "Hitit Bilgisayar Hizmetleri",
        "type": "airline"
    }, {
        "id": "6Y",
        "lcc": 0,
        "name": "SmartLynx Airlines",
        "type": "airline"
    }, {
        "id": "4Q",
        "lcc": 0,
        "name": "Safi Airlines",
        "type": "airline"
    }, {
        "id": "XO",
        "lcc": 0,
        "name": "LTE International Airways",
        "type": "airline"
    }, {
        "id": "JK",
        "lcc": 0,
        "name": "Spanair",
        "type": "airline"
    }, {
        "id": "S3",
        "lcc": 0,
        "name": "SBA Airlines",
        "type": "airline"
    }, {
        "id": "V9",
        "lcc": 0,
        "name": "Star1 Airlines",
        "type": "airline"
    }, {
        "id": "3G",
        "lcc": 0,
        "name": "Atlant-Soyuz Airlines",
        "type": "airline"
    }, {
        "id": "DT",
        "lcc": 0,
        "name": "TAAG Angola Airlines",
        "type": "airline"
    }, {
        "id": "7Q",
        "lcc": 0,
        "name": "Elite Airways",
        "type": "airline"
    }, {
        "id": "YM",
        "lcc": 0,
        "name": "Montenegro Airlines",
        "type": "airline"
    }, {
        "id": "IV",
        "lcc": 0,
        "name": "GP Aviation",
        "type": "airline"
    }, {
        "id": "HT",
        "lcc": 0,
        "name": "Air Horizont",
        "type": "airline"
    }, {
        "id": "TD",
        "lcc": 0,
        "name": "Tbilisi Airways",
        "type": "airline"
    }, {
        "id": "2W",
        "lcc": 0,
        "name": "World2fly",
        "type": "airline"
    }, {
        "id": "BY",
        "lcc": 1,
        "name": "TUI Airways",
        "type": "airline"
    }, {
        "id": "OC",
        "lcc": 0,
        "name": "Oriental Air Bridge",
        "type": "airline"
    }, {
        "id": "NO",
        "lcc": 1,
        "name": "Neos Air",
        "type": "airline"
    }, {
        "id": "7Z",
        "lcc": 0,
        "name": "EZ Air",
        "type": "airline"
    }, {
        "id": "HK",
        "lcc": 0,
        "name": "Yangon Airways",
        "type": "airline"
    }, {
        "id": "6J",
        "lcc": 0,
        "name": "Solaseed Air",
        "type": "airline"
    }, {
        "id": "G4",
        "lcc": 1,
        "name": "Allegiant Air",
        "type": "airline"
    }, {
        "id": "ZF",
        "lcc": 0,
        "name": "Azur Air",
        "type": "airline"
    }, {
        "id": "D3",
        "lcc": 0,
        "name": "Daallo Airlines",
        "type": "airline"
    }, {
        "id": "GY",
        "lcc": 0,
        "name": "Colorful Guizhou Airlines",
        "type": "airline"
    }, {
        "id": "QD",
        "lcc": 0,
        "name": "JC International Airlines",
        "type": "airline"
    }, {
        "id": "L6",
        "lcc": 0,
        "name": "Mauritania Airlines International",
        "type": "airline"
    }, {
        "id": "HC",
        "lcc": 0,
        "name": "Air Senegal",
        "type": "airline"
    }, {
        "id": "1I",
        "lcc": 0,
        "name": "NetJets",
        "type": "airline"
    }, {
        "id": "SB",
        "lcc": 0,
        "name": "Aircalin",
        "type": "airline"
    }, {
        "id": "NX",
        "lcc": 0,
        "name": "Air Macau",
        "type": "airline"
    }, {
        "id": "2B",
        "lcc": 0,
        "name": "AlbaWings",
        "type": "airline"
    }, {
        "id": "7L",
        "lcc": 0,
        "name": "Sun D'Or",
        "type": "airline"
    }, {
        "id": "VI",
        "lcc": 0,
        "name": "Volga-Dnepr Airlines",
        "type": "airline"
    }, {
        "id": "YO",
        "lcc": 0,
        "name": "TransHolding System",
        "type": "airline"
    }, {
        "id": "QY",
        "lcc": 0,
        "name": "Red Jet Canada",
        "type": "airline"
    }, {
        "id": "H5",
        "lcc": 0,
        "name": "I-Fly",
        "type": "airline"
    }, {
        "id": "R5",
        "lcc": 0,
        "name": "Jordan Aviation",
        "type": "airline"
    }, {
        "id": 12,
        "lcc": 0,
        "name": "12 North",
        "type": "airline"
    }, {
        "id": "1B",
        "lcc": 0,
        "name": "Abacus International",
        "type": "airline"
    }, {
        "id": "CE",
        "lcc": 0,
        "name": "Chalair",
        "type": "airline"
    }, {
        "id": "DZ",
        "lcc": 0,
        "name": "Donghai Airlines",
        "type": "airline"
    }, {
        "id": "MB",
        "lcc": 0,
        "name": "MNG Airlines",
        "type": "airline"
    }, {
        "id": "C4",
        "lcc": 0,
        "name": "LionXpress",
        "type": "airline"
    }, {
        "id": "A4",
        "lcc": 0,
        "name": "Azimuth",
        "type": "airline"
    }, {
        "id": "AH",
        "lcc": 0,
        "name": "Air Algerie",
        "type": "airline"
    }, {
        "id": "L3",
        "lcc": 0,
        "name": "LTU Austria",
        "type": "airline"
    }, {
        "id": "NW",
        "lcc": 0,
        "name": "Northwest Airlines",
        "type": "airline"
    }, {
        "id": "O8",
        "lcc": 0,
        "name": "Siam Air",
        "type": "airline"
    }, {
        "id": "QO",
        "lcc": 0,
        "name": "Origin Pacific Airways",
        "type": "airline"
    }, {
        "id": "RD",
        "lcc": 0,
        "name": "Ryan International Airlines",
        "type": "airline"
    }, {
        "id": "FM",
        "lcc": 0,
        "name": "Shanghai Airlines",
        "type": "airline"
    }, {
        "id": "WQ",
        "lcc": 0,
        "name": "Swiftair",
        "type": "airline"
    }, {
        "id": 78,
        "lcc": 0,
        "name": "Southjet cargo",
        "type": "airline"
    }, {
        "id": "K1",
        "lcc": 0,
        "name": "Kostromskie avialinii",
        "type": "airline"
    }, {
        "id": "L4",
        "lcc": 0,
        "name": "LASA Argentina",
        "type": "airline"
    }, {
        "id": "ZX",
        "lcc": 0,
        "name": "Japan Regio",
        "type": "airline"
    }, {
        "id": "BQ",
        "lcc": 0,
        "name": "Buquebus Líneas Aéreas",
        "type": "airline"
    }, {
        "id": "YY",
        "lcc": 0,
        "name": "Virginwings",
        "type": "airline"
    }, {
        "id": "BH",
        "lcc": 0,
        "name": "Hawkair",
        "type": "airline"
    }, {
        "id": "8H",
        "lcc": 0,
        "name": "Heli France",
        "type": "airline"
    }, {
        "id": "T4",
        "lcc": 0,
        "name": "Hellas Jet",
        "type": "airline"
    }, {
        "id": "ZA",
        "lcc": 0,
        "name": "Sky Angkor Airlines",
        "type": "airline"
    }, {
        "id": "JB",
        "lcc": 0,
        "name": "Helijet",
        "type": "airline"
    }, {
        "id": "EV",
        "lcc": 0,
        "name": "EV",
        "type": "airline"
    }, {
        "id": "OG",
        "lcc": 0,
        "name": "Play",
        "type": "airline"
    }, {
        "id": "UH",
        "lcc": 0,
        "name": "AtlasGlobal Ukraine",
        "type": "airline"
    }, {
        "id": "R8",
        "lcc": 0,
        "name": "AirRussia",
        "type": "airline"
    }, {
        "id": "ZN",
        "lcc": 0,
        "name": "Zenith International Airline",
        "type": "airline"
    }, {
        "id": "XV",
        "lcc": 0,
        "name": "BVI Airways",
        "type": "airline"
    }, {
        "id": "3F",
        "lcc": 0,
        "name": "FLYONE ARMENIA",
        "type": "airline"
    }, {
        "id": "4N",
        "lcc": 0,
        "name": "Air North",
        "type": "airline"
    }, {
        "id": "CQ",
        "lcc": 0,
        "name": "Coastal Aviation",
        "type": "airline"
    }, {
        "id": "HI",
        "lcc": 0,
        "name": "Papillon Grand Canyon Helicopters",
        "type": "airline"
    }, {
        "id": "YR",
        "lcc": 0,
        "name": "SENIC AIRLINES",
        "type": "airline"
    }, {
        "id": "QC",
        "lcc": 0,
        "name": "Camair-co",
        "type": "airline"
    }, {
        "id": "8U",
        "lcc": 0,
        "name": "Afriqiyah Airways",
        "type": "airline"
    }, {
        "id": "FG",
        "lcc": 0,
        "name": "Ariana Afghan Airlines",
        "type": "airline"
    }, {
        "id": "GV",
        "lcc": 0,
        "name": "Grant Aviation",
        "type": "airline"
    }, {
        "id": "HF",
        "lcc": 0,
        "name": "Air Cote d'Ivoire",
        "type": "airline"
    }, {
        "id": "9T",
        "lcc": 0,
        "name": "ACT Airlines",
        "type": "airline"
    }, {
        "id": "8Z",
        "lcc": 0,
        "name": "Wizz Air Hungary",
        "type": "airline"
    }, {
        "id": "CV",
        "lcc": 0,
        "name": "Cargolux",
        "type": "airline"
    }, {
        "id": "M7",
        "lcc": 0,
        "name": "MasAir",
        "type": "airline"
    }, {
        "id": "4K",
        "lcc": 0,
        "name": "Askari Aviation",
        "type": "airline"
    }, {
        "id": "JF",
        "lcc": 0,
        "name": "Jetairfly",
        "type": "airline"
    }, {
        "id": "PO",
        "lcc": 0,
        "name": "Polar Airlines",
        "type": "airline"
    }, {
        "id": "HE",
        "lcc": 0,
        "name": "Luftfahrtgesellschaft Walter",
        "type": "airline"
    }, {
        "id": "QJ",
        "lcc": 0,
        "name": "Jet Airways",
        "type": "airline"
    }, {
        "id": "FU",
        "lcc": 0,
        "name": "Fuzhou Airlines",
        "type": "airline"
    }, {
        "id": "KG",
        "lcc": 0,
        "name": "Key Lime Air",
        "type": "airline"
    }, {
        "id": "HW",
        "lcc": 0,
        "name": "Hello",
        "type": "airline"
    }, {
        "id": "L5",
        "lcc": 0,
        "name": "Atlas Atlantique Airlines",
        "type": "airline"
    }, {
        "id": "MA",
        "lcc": 0,
        "name": "Malév",
        "type": "airline"
    }, {
        "id": "ZC",
        "lcc": 0,
        "name": "Korongo Airlines",
        "type": "airline"
    }, {
        "id": "CB",
        "lcc": 0,
        "name": "CCML Airlines",
        "type": "airline"
    }, {
        "id": "GB",
        "lcc": 0,
        "name": "BRAZIL AIR",
        "type": "airline"
    }, {
        "id": "N1",
        "lcc": 0,
        "name": "N1",
        "type": "airline"
    }, {
        "id": "G1",
        "lcc": 0,
        "name": "Indya Airline Group",
        "type": "airline"
    }, {
        "id": "M3",
        "lcc": 0,
        "name": "Air Norway",
        "type": "airline"
    }, {
        "id": 24,
        "lcc": 0,
        "name": "Euro Jet",
        "type": "airline"
    }, {
        "id": "ZY",
        "lcc": 0,
        "name": "Ada Air",
        "type": "airline"
    }, {
        "id": "C1",
        "lcc": 0,
        "name": "CanXpress",
        "type": "airline"
    }, {
        "id": "OM",
        "lcc": 0,
        "name": "MIAT Mongolian Airlines",
        "type": "airline"
    }, {
        "id": "0D",
        "lcc": 0,
        "name": "Darwin Airline",
        "type": "airline"
    }, {
        "id": "W5",
        "lcc": 0,
        "name": "Mahan Air",
        "type": "airline"
    }, {
        "id": "W1",
        "lcc": 0,
        "name": "World Experience Airline",
        "type": "airline"
    }, {
        "id": "AN",
        "lcc": 0,
        "name": "Advanced Air",
        "type": "airline"
    }, {
        "id": "F1",
        "lcc": 0,
        "name": "Fly Brasil",
        "type": "airline"
    }, {
        "id": "TQ",
        "lcc": 0,
        "name": "Tandem Aero",
        "type": "airline"
    }, {
        "id": "RF",
        "lcc": 0,
        "name": "Florida West International Airways",
        "type": "airline"
    }, {
        "id": "N5",
        "lcc": 0,
        "name": "Nolinor",
        "type": "airline"
    }, {
        "id": "YS",
        "lcc": 0,
        "name": "Flightlink",
        "type": "airline"
    }, {
        "id": "Y1",
        "lcc": 0,
        "name": "Yellowstone Club Private Shuttle",
        "type": "airline"
    }, {
        "id": "WA",
        "lcc": 0,
        "name": "KLM Cityhopper",
        "type": "airline"
    }, {
        "id": "9S",
        "lcc": 0,
        "name": "Southern Air",
        "type": "airline"
    }, {
        "id": "N0",
        "lcc": 0,
        "name": "Norse Atlantic Airways",
        "type": "airline"
    }, {
        "id": "YH",
        "lcc": 0,
        "name": "Air Thanlwin",
        "type": "airline"
    }, {
        "id": "WU",
        "lcc": 0,
        "name": "WesternAir",
        "type": "airline"
    }, {
        "id": "CL",
        "lcc": 0,
        "name": "Lufthansa CityLine",
        "type": "airline"
    }, {
        "id": "DM",
        "lcc": 0,
        "name": "Arajet",
        "type": "airline"
    }, {
        "id": "2F",
        "lcc": 0,
        "name": "Azul Conecta",
        "type": "airline"
    }, {
        "id": "WJ",
        "lcc": 0,
        "name": "JetSMART Argentina",
        "type": "airline"
    }, {
        "id": "AU",
        "lcc": 0,
        "name": "AU",
        "type": "airline"
    }, {
        "id": "Q3",
        "lcc": 0,
        "name": "Anguilla Air Services",
        "type": "airline"
    }, {
        "id": "TU",
        "lcc": 0,
        "name": "Tunisair",
        "type": "airline"
    }, {
        "id": "T7",
        "lcc": 0,
        "name": "Twin Jet",
        "type": "airline"
    }, {
        "id": "PA",
        "lcc": 1,
        "name": "Airblue",
        "type": "airline"
    }, {
        "id": "TJ",
        "lcc": 0,
        "name": "Tradewind Aviation",
        "type": "airline"
    }, {
        "id": "W2",
        "lcc": 0,
        "name": "Flexflight",
        "type": "airline"
    }, {
        "id": "NA",
        "lcc": 0,
        "name": "Nesma Air",
        "type": "airline"
    }, {
        "id": "9I",
        "lcc": 0,
        "name": "Alliance Air",
        "type": "airline"
    }, {
        "id": "EM",
        "lcc": 0,
        "name": "Empire Airlines",
        "type": "airline"
    }, {
        "id": "CS",
        "lcc": 0,
        "name": "Continental Micronesia",
        "type": "airline"
    }, {
        "id": "RG",
        "lcc": 0,
        "name": "Rotana Jet",
        "type": "airline"
    }, {
        "id": "TH",
        "lcc": 0,
        "name": "Raya Airways",
        "type": "airline"
    }, {
        "id": "H9",
        "lcc": 0,
        "name": "Himalaya Airlines",
        "type": "airline"
    }, {
        "id": "XB",
        "lcc": 0,
        "name": "NEXT Brasil",
        "type": "airline"
    }, {
        "id": "GN",
        "lcc": 0,
        "name": "GNB Linhas Aereas",
        "type": "airline"
    }, {
        "id": "SO",
        "lcc": 0,
        "name": "Salsa dHaiti",
        "type": "airline"
    }, {
        "id": "C5",
        "lcc": 0,
        "name": "CommutAir",
        "type": "airline"
    }, {
        "id": "T6",
        "lcc": 0,
        "name": "Airswift",
        "type": "airline"
    }, {
        "id": "HM",
        "lcc": 0,
        "name": "Air Seychelles",
        "type": "airline"
    }, {
        "id": "VF",
        "lcc": 0,
        "name": "Valuair",
        "type": "airline"
    }, {
        "id": "1C",
        "lcc": 0,
        "name": "OneChina",
        "type": "airline"
    }, {
        "id": "HQ",
        "lcc": 0,
        "name": "Thomas Cook Belgium",
        "type": "airline"
    }, {
        "id": "DA",
        "lcc": 0,
        "name": "Aerolinea de Antioquia",
        "type": "airline"
    }, {
        "id": "ZZ",
        "lcc": 0,
        "name": "Zz",
        "type": "airline"
    }, {
        "id": "5E",
        "lcc": 0,
        "name": "SGA Airlines",
        "type": "airline"
    }, {
        "id": "LB",
        "lcc": 1,
        "name": "Air Costa",
        "type": "airline"
    }, {
        "id": "TX",
        "lcc": 0,
        "name": "Air Caraïbes",
        "type": "airline"
    }, {
        "id": "K8",
        "lcc": 0,
        "name": "Kan Air",
        "type": "airline"
    }, {
        "id": "IR",
        "lcc": 0,
        "name": "Iran Air",
        "type": "airline"
    }, {
        "id": "K6",
        "lcc": 0,
        "name": "Cambodia Angkor Air",
        "type": "airline"
    }, {
        "id": "AE",
        "lcc": 0,
        "name": "Mandarin Airlines",
        "type": "airline"
    }, {
        "id": "CJ",
        "lcc": 0,
        "name": "BA CityFlyer",
        "type": "airline"
    }, {
        "id": "XE",
        "lcc": 0,
        "name": "JetSuiteX",
        "type": "airline"
    }, {
        "id": "DN",
        "lcc": 0,
        "name": "Norwegian Air Argentina",
        "type": "airline"
    }, {
        "id": "5Q",
        "lcc": 0,
        "name": "Holiday Europe",
        "type": "airline"
    }, {
        "id": "XG",
        "lcc": 0,
        "name": "SunExpress",
        "type": "airline"
    }, {
        "id": "9K",
        "lcc": 1,
        "name": "Cape Air",
        "type": "airline"
    }, {
        "id": "6U",
        "lcc": 0,
        "name": "Air Cargo Germany",
        "type": "airline"
    }, {
        "id": "1X",
        "lcc": 0,
        "name": "Branson Air Express",
        "type": "airline"
    }, {
        "id": "4H",
        "lcc": 0,
        "name": "HiSky",
        "type": "airline"
    }, {
        "id": "ZQ",
        "lcc": 0,
        "name": "German Airways",
        "type": "airline"
    }, {
        "id": "YE",
        "lcc": 0,
        "name": "Yan Air",
        "type": "airline"
    }, {
        "id": "C3",
        "lcc": 0,
        "name": "Trade Air",
        "type": "airline"
    }, {
        "id": "IZ",
        "lcc": 1,
        "name": "Arkia",
        "type": "airline"
    }, {
        "id": "2O",
        "lcc": 0,
        "name": "Island Air Kodiak",
        "type": "airline"
    }, {
        "id": "VP",
        "lcc": 0,
        "name": "Villa Air",
        "type": "airline"
    }, {
        "id": "FH",
        "lcc": 0,
        "name": "Freebird Airlines",
        "type": "airline"
    }, {
        "id": "HD",
        "lcc": 1,
        "name": "Air Do",
        "type": "airline"
    }, {
        "id": "VC",
        "lcc": 0,
        "name": "Via Air",
        "type": "airline"
    }, {
        "id": "A0",
        "lcc": 1,
        "name": "Avianca Argentina",
        "type": "airline"
    }, {
        "id": 76,
        "lcc": 0,
        "name": "Southjet",
        "type": "airline"
    }, {
        "id": 77,
        "lcc": 0,
        "name": "Southjet connect",
        "type": "airline"
    }, {
        "id": "AO",
        "lcc": 0,
        "name": "Avianova (Russia)",
        "type": "airline"
    }, {
        "id": "9L",
        "lcc": 0,
        "name": "Colgan Air",
        "type": "airline"
    }, {
        "id": "OH",
        "lcc": 0,
        "name": "Comair",
        "type": "airline"
    }, {
        "id": "9X",
        "lcc": 1,
        "name": "Southern Airways Express",
        "type": "airline"
    }, {
        "id": "TN",
        "lcc": 0,
        "name": "Air Tahiti Nui",
        "type": "airline"
    }, {
        "id": "WW",
        "lcc": 1,
        "name": "WOW air",
        "type": "airline"
    }, {
        "id": "5H",
        "lcc": 1,
        "name": "Fly540",
        "type": "airline"
    }, {
        "id": "JM",
        "lcc": 0,
        "name": "Jambojet",
        "type": "airline"
    }, {
        "id": "OE",
        "lcc": 1,
        "name": "LaudaMotion",
        "type": "airline"
    }, {
        "id": "WV",
        "lcc": 0,
        "name": "Westair Aviation",
        "type": "airline"
    }, {
        "id": "3C",
        "lcc": "",
        "name": "Air Chathams Limited",
        "type": "airline"
    }, {
        "id": "NJ",
        "lcc": 0,
        "name": "Nordic Global Airlines",
        "type": "airline"
    }, {
        "id": "6T",
        "lcc": 1,
        "name": "Air Mandalay",
        "type": "airline"
    }, {
        "id": "7N",
        "lcc": 0,
        "name": "Pawa Dominicana",
        "type": "airline"
    }, {
        "id": "CC",
        "lcc": 0,
        "name": "CM Airlines",
        "type": "airline"
    }, {
        "id": "GR",
        "lcc": 0,
        "name": "Aurigny Air Services",
        "type": "airline"
    }, {
        "id": "QL",
        "lcc": 1,
        "name": "Laser Air",
        "type": "airline"
    }, {
        "id": "C7",
        "lcc": 1,
        "name": "Cinnamon Air",
        "type": "airline"
    }, {
        "id": "P0",
        "lcc": 0,
        "name": "Proflight Zambia",
        "type": "airline"
    }, {
        "id": "JG",
        "lcc": 1,
        "name": "JetGo",
        "type": "airline"
    }, {
        "id": "ES",
        "lcc": 0,
        "name": "Estelar Latinoamerica",
        "type": "airline"
    }, {
        "id": "2J",
        "lcc": 0,
        "name": "Air Burkina",
        "type": "airline"
    }, {
        "id": "ENZ",
        "lcc": "",
        "name": "Jota Aviation",
        "type": "airline"
    }, {
        "id": "LPA",
        "lcc": "",
        "name": "Air Leap",
        "type": "airline"
    }, {
        "id": "9M",
        "lcc": 0,
        "name": "Central Mountain Air",
        "type": "airline"
    }, {
        "id": "QX",
        "lcc": 0,
        "name": "Horizon Air",
        "type": "airline"
    }, {
        "id": "YU",
        "lcc": 0,
        "name": "EuroAtlantic Airways",
        "type": "airline"
    }, {
        "id": "5D",
        "lcc": 0,
        "name": "Aerolitoral",
        "type": "airline"
    }, {
        "id": "V3",
        "lcc": 0,
        "name": "Carpatair",
        "type": "airline"
    }, {
        "id": "ATV",
        "lcc": "",
        "name": "Avanti Air",
        "type": "airline"
    }, {
        "id": "SW",
        "lcc": 0,
        "name": "Air Namibia",
        "type": "airline"
    }, {
        "id": "KM",
        "lcc": 0,
        "name": "Air Malta",
        "type": "airline"
    }, {
        "id": "R4",
        "lcc": 0,
        "name": "Real Tonga",
        "type": "airline"
    }, {
        "id": "KI",
        "lcc": 1,
        "name": "SKS Airways",
        "type": "airline"
    }, {
        "id": "EE",
        "lcc": 0,
        "name": "Xfly",
        "type": "airline"
    }, {
        "id": "U6",
        "lcc": 1,
        "name": "Ural Airlines",
        "type": "airline"
    }, {
        "id": "A1",
        "lcc": 0,
        "name": "A.P.G. Distribution System",
        "type": "airline"
    }, {
        "id": "Z9",
        "lcc": 0,
        "name": "MYAirline",
        "type": "airline"
    }, {
        "id": "X5",
        "lcc": "",
        "name": "Air Europa express",
        "type": "airline"
    }, {
        "id": "M8",
        "lcc": 1,
        "name": "SkyJet Airlines",
        "type": "airline"
    }, {
        "id": "6F",
        "lcc": 1,
        "name": "Primera Air Nordic",
        "type": "airline"
    }, {
        "id": "RRV",
        "lcc": "",
        "name": "Mombasa Air Safari",
        "type": "airline"
    }, {
        "id": "STEWISLAND",
        "lcc": "",
        "name": "Stewart Island Flights",
        "type": "airline"
    }, {
        "id": "SKYPASADA",
        "lcc": "",
        "name": "Sky Pasada",
        "type": "airline"
    }, {
        "id": "OB",
        "lcc": 1,
        "name": "Boliviana de Aviación",
        "type": "airline"
    }, {
        "id": "UNIAIR",
        "lcc": "",
        "name": "Unity Air",
        "type": "airline"
    }, {
        "id": "ASSALAAM",
        "lcc": "",
        "name": "As Salaam Air",
        "type": "airline"
    }, {
        "id": "GRUMAIR",
        "lcc": "",
        "name": "Grumeti Air",
        "type": "airline"
    }, {
        "id": "EF",
        "lcc": 1,
        "name": "EF",
        "type": "airline"
    }, {
        "id": "EMETEBE",
        "lcc": "",
        "name": "Emetebe Airlines",
        "type": "airline"
    }, {
        "id": "FLYSAFARI",
        "lcc": "",
        "name": "Fly Safari Airlink",
        "type": "airline"
    }, {
        "id": "P6",
        "lcc": 0,
        "name": "Pascan Aviation",
        "type": "airline"
    }, {
        "id": "RY",
        "lcc": 1,
        "name": "Air Jiangxi",
        "type": "airline"
    }, {
        "id": "OW",
        "lcc": "",
        "name": "Skyward Express Limited",
        "type": "airline"
    }, {
        "id": "M0",
        "lcc": 1,
        "name": "Aero Mongolia",
        "type": "airline"
    }, {
        "id": "SBK",
        "lcc": "",
        "name": "Blue Sky Aviation",
        "type": "airline"
    }, {
        "id": "F4",
        "lcc": "",
        "name": "Air Flamenco",
        "type": "airline"
    }, {
        "id": "O4",
        "lcc": "",
        "name": "Orange2Fly",
        "type": "airline"
    }, {
        "id": "BP",
        "lcc": 0,
        "name": "Air Botswana",
        "type": "airline"
    }, {
        "id": "V8",
        "lcc": 0,
        "name": "ATRAN Cargo Airlines",
        "type": "airline"
    }, {
        "id": "GP",
        "lcc": 0,
        "name": "APG Airlines",
        "type": "airline"
    }, {
        "id": "MW",
        "lcc": 0,
        "name": "Mokulele Flight Service",
        "type": "airline"
    }, {
        "id": "K5",
        "lcc": 0,
        "name": "Silverstone Air",
        "type": "airline"
    }, {
        "id": "Y6",
        "lcc": "",
        "name": "AB Aviation",
        "type": "airline"
    }, {
        "id": "Y5",
        "lcc": 1,
        "name": "Golden Myanmar Airlines",
        "type": "airline"
    }, {
        "id": "6S",
        "lcc": 0,
        "name": "SaudiGulf Airlines",
        "type": "airline"
    }, {
        "id": "RLY",
        "lcc": "",
        "name": "Air Loyaute",
        "type": "airline"
    }, {
        "id": "CW",
        "lcc": 0,
        "name": "Air Marshall Islands",
        "type": "airline"
    }, {
        "id": "ZJ",
        "lcc": 0,
        "name": "Zambezi Airlines (ZMA)",
        "type": "airline"
    }, {
        "id": "C0",
        "lcc": 0,
        "name": "Centralwings",
        "type": "airline"
    }, {
        "id": "9H",
        "lcc": 0,
        "name": "Air Changan",
        "type": "airline"
    }, {
        "id": "CRAVIATION",
        "lcc": "",
        "name": "CR Aviation",
        "type": "airline"
    }, {
        "id": "BZ",
        "lcc": 0,
        "name": "Blue Bird Airways",
        "type": "airline"
    }, {
        "id": "P5",
        "lcc": 0,
        "name": "Wingo airlines",
        "type": "airline"
    }, {
        "id": "JE",
        "lcc": 1,
        "name": "Mango",
        "type": "airline"
    }, {
        "id": "DVR",
        "lcc": "",
        "name": "Divi Divi Air",
        "type": "airline"
    }, {
        "id": "WT",
        "lcc": "",
        "name": "SwiftAir",
        "type": "airline"
    }, {
        "id": "FR",
        "lcc": 1,
        "name": "Ryanair",
        "type": "airline"
    }, {
        "id": "YT",
        "lcc": 0,
        "name": "Yeti Airways",
        "type": "airline"
    }, {
        "id": "SM",
        "lcc": 1,
        "name": "Air Cairo",
        "type": "airline"
    }, {
        "id": "B3",
        "lcc": 0,
        "name": "Bhutan Airlines",
        "type": "airline"
    }, {
        "id": "DF",
        "lcc": 0,
        "name": "Michael Airlines",
        "type": "airline"
    }, {
        "id": "QN",
        "lcc": "",
        "name": "Skytrans",
        "type": "airline"
    }, {
        "id": "ULENDO",
        "lcc": "",
        "name": "Fly Ulendo",
        "type": "airline"
    }, {
        "id": "FP",
        "lcc": 0,
        "name": "Pelican Airlines",
        "type": "airline"
    }, {
        "id": "A8",
        "lcc": "",
        "name": "Aerolink Uganda",
        "type": "airline"
    }, {
        "id": "XLL",
        "lcc": "",
        "name": "Air Excel Limited",
        "type": "airline"
    }, {
        "id": "IF",
        "lcc": 0,
        "name": "Fly Baghdad Airlines",
        "type": "airline"
    }, {
        "id": "GT",
        "lcc": 1,
        "name": "Air Guilin",
        "type": "airline"
    }, {
        "id": "C2",
        "lcc": 0,
        "name": "Ceiba Intercontinental",
        "type": "airline"
    }, {
        "id": "GCS",
        "lcc": "",
        "name": "Skyway CR",
        "type": "airline"
    }, {
        "id": "IG",
        "lcc": 1,
        "name": "Air Italy",
        "type": "airline"
    }, {
        "id": "RV",
        "lcc": 0,
        "name": "Air Canada Rouge",
        "type": "airline"
    }, {
        "id": "GRENADINE",
        "lcc": "",
        "name": "Grenadine Airways",
        "type": "airline"
    }, {
        "id": "R3",
        "lcc": 1,
        "name": "Yakutia Airlines",
        "type": "airline"
    }, {
        "id": "Q5",
        "lcc": 0,
        "name": "40-Mile Air",
        "type": "airline"
    }, {
        "id": "EP",
        "lcc": 0,
        "name": "Iran Aseman Airlines",
        "type": "airline"
    }, {
        "id": "IE",
        "lcc": 0,
        "name": "Solomon Airlines",
        "type": "airline"
    }, {
        "id": "V5",
        "lcc": 0,
        "name": "Aerovías DAP",
        "type": "airline"
    }, {
        "id": "T5",
        "lcc": 0,
        "name": "Turkmenistan Airlines",
        "type": "airline"
    }, {
        "id": "6H",
        "lcc": 0,
        "name": "Israir",
        "type": "airline"
    }, {
        "id": "7D",
        "lcc": "",
        "name": "Madagasikara Airways",
        "type": "airline"
    }, {
        "id": "U3",
        "lcc": 0,
        "name": "Avies",
        "type": "airline"
    }, {
        "id": "T2",
        "lcc": 0,
        "name": "Thai Air Cargo",
        "type": "airline"
    }, {
        "id": "XF",
        "lcc": 0,
        "name": "Vladivostok Air",
        "type": "airline"
    }, {
        "id": "HP",
        "lcc": 0,
        "name": "Amapola Flyg",
        "type": "airline"
    }, {
        "id": "5T",
        "lcc": 0,
        "name": "Canadian North",
        "type": "airline"
    }, {
        "id": "WC",
        "lcc": 0,
        "name": "Avianca Honduras",
        "type": "airline"
    }, {
        "id": "EG",
        "lcc": 0,
        "name": "Ernest Airlines",
        "type": "airline"
    }, {
        "id": "7V",
        "lcc": 0,
        "name": "Federal Airlines",
        "type": "airline"
    }, {
        "id": "VD",
        "lcc": 0,
        "name": "Air Libert",
        "type": "airline"
    }, {
        "id": "ML",
        "lcc": 1,
        "name": "Air Mediterranee",
        "type": "airline"
    }, {
        "id": "B1",
        "lcc": 0,
        "name": "Baltic Air lines",
        "type": "airline"
    }, {
        "id": "J1",
        "lcc": 0,
        "name": "One Jet",
        "type": "airline"
    }, {
        "id": "P1",
        "lcc": 0,
        "name": "Regional Sky",
        "type": "airline"
    }, {
        "id": "VH",
        "lcc": 0,
        "name": "Viva Air",
        "type": "airline"
    }, {
        "id": "Z7",
        "lcc": 1,
        "name": "Amaszonas Uruguay",
        "type": "airline"
    }, {
        "id": "MT",
        "lcc": 1,
        "name": "Malta MedAir",
        "type": "airline"
    }, {
        "id": "6I",
        "lcc": 0,
        "name": "Alsie Express",
        "type": "airline"
    }, {
        "id": "FLZ",
        "lcc": "",
        "name": "Flightlink",
        "type": "airline"
    }, {
        "id": "ST",
        "lcc": 1,
        "name": "Air Thanlwin",
        "type": "airline"
    }, {
        "id": "JZ",
        "lcc": 0,
        "name": "JetSMART Peru",
        "type": "airline"
    }, {
        "id": "0V",
        "lcc": 0,
        "name": "VASCO",
        "type": "airline"
    }, {
        "id": "N6",
        "lcc": 0,
        "name": "Nomad Aviation",
        "type": "airline"
    }, {
        "id": "7J",
        "lcc": 1,
        "name": "Tajik Air",
        "type": "airline"
    }, {
        "id": "Z6",
        "lcc": 1,
        "name": "Dniproavia",
        "type": "airline"
    }, {
        "id": "R6",
        "lcc": 0,
        "name": "DAT LT",
        "type": "airline"
    }, {
        "id": "4A",
        "lcc": 0,
        "name": "ATSA Airlines",
        "type": "airline"
    }, {
        "id": "SH",
        "lcc": 0,
        "name": "Sharp Airlines",
        "type": "airline"
    }, {
        "id": "F7",
        "lcc": 0,
        "name": "iFly Airlines",
        "type": "airline"
    }, {
        "id": "NQ",
        "lcc": 0,
        "name": "Air Japan",
        "type": "airline"
    }, {
        "id": "TZ",
        "lcc": 1,
        "name": "Tsaradia",
        "type": "airline"
    }, {
        "id": "S2",
        "lcc": 0,
        "name": "Jet Konnect",
        "type": "airline"
    }, {
        "id": "DB",
        "lcc": 0,
        "name": "Brit Air",
        "type": "airline"
    }, {
        "id": "7I",
        "lcc": 1,
        "name": "Insel Air",
        "type": "airline"
    }, {
        "id": "1F",
        "lcc": 0,
        "name": "CB Airways UK ( Interliging Flights )",
        "type": "airline"
    }, {
        "id": "8T",
        "lcc": 0,
        "name": "Air Tindi",
        "type": "airline"
    }, {
        "id": "JD",
        "lcc": 0,
        "name": "Beijing Capital Airlines",
        "type": "airline"
    }, {
        "id": "UB",
        "lcc": 0,
        "name": "Myanmar National Airlines",
        "type": "airline"
    }, {
        "id": "YN",
        "lcc": 0,
        "name": "Air Creebec",
        "type": "airline"
    }, {
        "id": "TM",
        "lcc": 0,
        "name": "LAM Mozambique Airlines",
        "type": "airline"
    }, {
        "id": "PX",
        "lcc": 0,
        "name": "Air Niugini",
        "type": "airline"
    }, {
        "id": "ZD",
        "lcc": 0,
        "name": "EWA Air",
        "type": "airline"
    }, {
        "id": "4W",
        "lcc": 0,
        "name": "Allied Air",
        "type": "airline"
    }, {
        "id": "ER",
        "lcc": "",
        "name": "SereneAir",
        "type": "airline"
    }, {
        "id": "VT",
        "lcc": 0,
        "name": "Air Tahiti",
        "type": "airline"
    }, {
        "id": "V4",
        "lcc": 0,
        "name": "Vieques Air Link",
        "type": "airline"
    }, {
        "id": "JO",
        "lcc": 0,
        "name": "Jonika airlines",
        "type": "airline"
    }, {
        "id": "F2",
        "lcc": 0,
        "name": "Safarilink Aviation",
        "type": "airline"
    }, {
        "id": "ZW",
        "lcc": 0,
        "name": "Air Wisconsin",
        "type": "airline"
    }, {
        "id": "GI",
        "lcc": 0,
        "name": "Itek Air",
        "type": "airline"
    }, {
        "id": "ZV",
        "lcc": 1,
        "name": "V Air",
        "type": "airline"
    }, {
        "id": "U9",
        "lcc": 0,
        "name": "Tatarstan Airlines",
        "type": "airline"
    }, {
        "id": "5Z",
        "lcc": 1,
        "name": "Cem Air",
        "type": "airline"
    }, {
        "id": "5C",
        "lcc": 1,
        "name": "Nature Air",
        "type": "airline"
    }, {
        "id": "9N",
        "lcc": 0,
        "name": "Tropic Air Limited",
        "type": "airline"
    }, {
        "id": "8V",
        "lcc": 0,
        "name": "Wright Air Service",
        "type": "airline"
    }, {
        "id": "FA",
        "lcc": 1,
        "name": "Fly Safair",
        "type": "airline"
    }, {
        "id": "6O",
        "lcc": 0,
        "name": "Orbest",
        "type": "airline"
    }, {
        "id": "K9",
        "lcc": 1,
        "name": "KrasAvia (old iata)",
        "type": "airline"
    }, {
        "id": "PB",
        "lcc": 0,
        "name": "PAL Airlines",
        "type": "airline"
    }, {
        "id": "RW",
        "lcc": 0,
        "name": "Royal Air Philippines",
        "type": "airline"
    }, {
        "id": "HY",
        "lcc": 0,
        "name": "Uzbekistan Airways",
        "type": "airline"
    }, {
        "id": "AB",
        "lcc": 1,
        "name": "Bonza",
        "type": "airline"
    }, {
        "id": "ID",
        "lcc": 1,
        "name": "Batik Air",
        "type": "airline"
    }, {
        "id": "E2",
        "lcc": 0,
        "name": "Eurowings Europe Austria",
        "type": "airline"
    }, {
        "id": "J7",
        "lcc": 1,
        "name": "Afrijet Business Service",
        "type": "airline"
    }, {
        "id": "AW",
        "lcc": 1,
        "name": "Africa World Airlines",
        "type": "airline"
    }, {
        "id": "PF",
        "lcc": 1,
        "name": "Primera Air",
        "type": "airline"
    }, {
        "id": "EL",
        "lcc": 1,
        "name": "Ellinair",
        "type": "airline"
    }, {
        "id": "DR",
        "lcc": 1,
        "name": "Ruili Airlines",
        "type": "airline"
    }, {
        "id": "EB",
        "lcc": 1,
        "name": "Wamos Air",
        "type": "airline"
    }, {
        "id": "S8",
        "lcc": 1,
        "name": "Sounds Air",
        "type": "airline"
    }, {
        "id": "V2",
        "lcc": 0,
        "name": "Vision Airlines",
        "type": "airline"
    }, {
        "id": "JX",
        "lcc": 1,
        "name": "Starlux Airlines",
        "type": "airline"
    }, {
        "id": "I2",
        "lcc": 0,
        "name": "Iberia Express",
        "type": "airline"
    }, {
        "id": "MJ",
        "lcc": 1,
        "name": "Myway Airlines",
        "type": "airline"
    }, {
        "id": "JV",
        "lcc": 0,
        "name": "Perimeter Aviation",
        "type": "airline"
    }, {
        "id": "NL",
        "lcc": 1,
        "name": "Amelia",
        "type": "airline"
    }, {
        "id": "9V",
        "lcc": 0,
        "name": "Avior Airlines",
        "type": "airline"
    }, {
        "id": "G9",
        "lcc": 1,
        "name": "Air Arabia",
        "type": "airline"
    }, {
        "id": "9W",
        "lcc": 0,
        "name": "Jet Airways",
        "type": "airline"
    }, {
        "id": "YZ",
        "lcc": 0,
        "name": "Alas Uruguay",
        "type": "airline"
    }, {
        "id": "8A",
        "lcc": 0,
        "name": "Atlas Blue",
        "type": "airline"
    }, {
        "id": "CO",
        "lcc": 0,
        "name": "Cobalt Air",
        "type": "airline"
    }, {
        "id": "MO",
        "lcc": 1,
        "name": "Calm Air",
        "type": "airline"
    }, {
        "id": "VQ",
        "lcc": 1,
        "name": "Novoair",
        "type": "airline"
    }, {
        "id": "WS",
        "lcc": 1,
        "name": "WestJet",
        "type": "airline"
    }, {
        "id": "5O",
        "lcc": 0,
        "name": "ASL Airlines France",
        "type": "airline"
    }, {
        "id": "KX",
        "lcc": 0,
        "name": "Cayman Airways",
        "type": "airline"
    }, {
        "id": "CF",
        "lcc": 0,
        "name": "City Airline",
        "type": "airline"
    }, {
        "id": "KD",
        "lcc": 0,
        "name": "Kalstar Aviation",
        "type": "airline"
    }, {
        "id": "RX",
        "lcc": 1,
        "name": "Regent Airways",
        "type": "airline"
    }, {
        "id": "2G",
        "lcc": 1,
        "name": "Angara airlines",
        "type": "airline"
    }, {
        "id": "7R",
        "lcc": 1,
        "name": "Rusline",
        "type": "airline"
    }, {
        "id": "WP",
        "lcc": 0,
        "name": "Air Antwerp",
        "type": "airline"
    }, {
        "id": "BC",
        "lcc": 1,
        "name": "Skymark Airlines",
        "type": "airline"
    }, {
        "id": "BM",
        "lcc": 0,
        "name": "flybmi",
        "type": "airline"
    }, {
        "id": "G3",
        "lcc": 1,
        "name": "Gol Transportes Aéreos",
        "type": "airline"
    }, {
        "id": "WB",
        "lcc": 0,
        "name": "Rwandair Express",
        "type": "airline"
    }, {
        "id": "OR",
        "lcc": 1,
        "name": "TUI fly Netherlands",
        "type": "airline"
    }, {
        "id": "I4",
        "lcc": 0,
        "name": "Island Air Express",
        "type": "airline"
    }, {
        "id": "BX",
        "lcc": 1,
        "name": "Air Busan",
        "type": "airline"
    }, {
        "id": "XY",
        "lcc": 1,
        "name": "flynas",
        "type": "airline"
    }, {
        "id": "5N",
        "lcc": 1,
        "name": "Smartavia",
        "type": "airline"
    }, {
        "id": "DP",
        "lcc": 1,
        "name": "Pobeda",
        "type": "airline"
    }, {
        "id": "IX",
        "lcc": 1,
        "name": "Air India Express",
        "type": "airline"
    }, {
        "id": "KK",
        "lcc": 1,
        "name": "Leav Aviation",
        "type": "airline"
    }, {
        "id": "3S",
        "lcc": 0,
        "name": "Air Antilles Express",
        "type": "airline"
    }, {
        "id": "ZI",
        "lcc": 0,
        "name": "Aigle Azur",
        "type": "airline"
    }, {
        "id": "K7",
        "lcc": 0,
        "name": "Air KBZ",
        "type": "airline"
    }, {
        "id": "VW",
        "lcc": 0,
        "name": "Aeromar",
        "type": "airline"
    }, {
        "id": "QW",
        "lcc": 0,
        "name": "Qingdao Airlines",
        "type": "airline"
    }, {
        "id": "HO",
        "lcc": 1,
        "name": "Juneyao Airlines",
        "type": "airline"
    }, {
        "id": "XN",
        "lcc": 0,
        "name": "Xpressair",
        "type": "airline"
    }, {
        "id": "8R",
        "lcc": 1,
        "name": "Sol Líneas Aéreas",
        "type": "airline"
    }, {
        "id": "5K",
        "lcc": 0,
        "name": "Hi Fly",
        "type": "airline"
    }, {
        "id": "QK",
        "lcc": 0,
        "name": "Air Canada Jazz",
        "type": "airline"
    }, {
        "id": "KC",
        "lcc": 0,
        "name": "Air Astana",
        "type": "airline"
    }, {
        "id": "TF",
        "lcc": 0,
        "name": "Braathens Regional Aviation",
        "type": "airline"
    }, {
        "id": "8M",
        "lcc": 0,
        "name": "Myanmar Airways",
        "type": "airline"
    }, {
        "id": "TL",
        "lcc": 1,
        "name": "Airnorth",
        "type": "airline"
    }, {
        "id": "UE",
        "lcc": 0,
        "name": "Nasair",
        "type": "airline"
    }, {
        "id": "DH",
        "lcc": 0,
        "name": "Dennis Sky",
        "type": "airline"
    }, {
        "id": "Q7",
        "lcc": 0,
        "name": "Sky Bahamas",
        "type": "airline"
    }, {
        "id": "7M",
        "lcc": 0,
        "name": "MAP Linhas Aéreas",
        "type": "airline"
    }, {
        "id": "1D",
        "lcc": 1,
        "name": "Aerolinea de Antioquia",
        "type": "airline"
    }, {
        "id": "FC",
        "lcc": 1,
        "name": "Link Airways",
        "type": "airline"
    }, {
        "id": "2Z",
        "lcc": 1,
        "name": "Voepass",
        "type": "airline"
    }, {
        "id": "WM",
        "lcc": 0,
        "name": "Windward Islands Airways",
        "type": "airline"
    }, {
        "id": "OD",
        "lcc": 1,
        "name": "Malindo Air",
        "type": "airline"
    }, {
        "id": "3H",
        "lcc": 1,
        "name": "Air Inuit",
        "type": "airline"
    }, {
        "id": "TB",
        "lcc": 1,
        "name": "TUI fly Belgium",
        "type": "airline"
    }, {
        "id": "B0",
        "lcc": 0,
        "name": "La Compagnie",
        "type": "airline"
    }, {
        "id": "OJ",
        "lcc": 0,
        "name": "NyxAir",
        "type": "airline"
    }, {
        "id": "WF",
        "lcc": 0,
        "name": "Widerøe",
        "type": "airline"
    }, {
        "id": "FO",
        "lcc": 1,
        "name": "Flybondi",
        "type": "airline"
    }, {
        "id": "EW",
        "lcc": 1,
        "name": "Eurowings",
        "type": "airline"
    }, {
        "id": "YJ",
        "lcc": 1,
        "name": "Asian Wings Air",
        "type": "airline"
    }, {
        "id": "TW",
        "lcc": 1,
        "name": "Tway Airlines",
        "type": "airline"
    }, {
        "id": "LK",
        "lcc": 0,
        "name": "Lao Skyway",
        "type": "airline"
    }, {
        "id": "SG",
        "lcc": 1,
        "name": "Spicejet",
        "type": "airline"
    }, {
        "id": "PG",
        "lcc": 1,
        "name": "Bangkok Airways",
        "type": "airline"
    }, {
        "id": "RP",
        "lcc": 0,
        "name": "Aeroexpress Regional",
        "type": "airline"
    }, {
        "id": "B6",
        "lcc": 1,
        "name": "JetBlue Airways",
        "type": "airline"
    }, {
        "id": "3M",
        "lcc": 1,
        "name": "Silver Airways",
        "type": "airline"
    }, {
        "id": "KU",
        "lcc": 0,
        "name": "Kuwait Airways",
        "type": "airline"
    }, {
        "id": "DY",
        "lcc": 1,
        "name": "Norwegian",
        "type": "airline"
    }, {
        "id": "H2",
        "lcc": 1,
        "name": "Sky Airline",
        "type": "airline"
    }, {
        "id": "R2",
        "lcc": 1,
        "name": "Orenburg Airlines",
        "type": "airline"
    }, {
        "id": "UN",
        "lcc": 1,
        "name": "Transaero Airlines",
        "type": "airline"
    }, {
        "id": "YB",
        "lcc": 1,
        "name": "Harbour Air (Priv)",
        "type": "airline"
    }, {
        "id": "W4",
        "lcc": 1,
        "name": "Ulendo Airlink",
        "type": "airline"
    }, {
        "id": "FKK",
        "lcc": "",
        "name": "Unity Air",
        "type": "airline"
    }, {
        "id": "PC",
        "lcc": 1,
        "name": "Pegasus",
        "type": "airline"
    }, {
        "id": "CP",
        "lcc": 0,
        "name": "Compass Airlines",
        "type": "airline"
    }, {
        "id": "FY",
        "lcc": 1,
        "name": "Firefly",
        "type": "airline"
    }, {
        "id": "7F",
        "lcc": 0,
        "name": "First Air",
        "type": "airline"
    }, {
        "id": "J9",
        "lcc": 1,
        "name": "Jazeera Airways",
        "type": "airline"
    }, {
        "id": "BW",
        "lcc": 0,
        "name": "Caribbean Airlines",
        "type": "airline"
    }, {
        "id": "C9",
        "lcc": 1,
        "name": "SkyWise",
        "type": "airline"
    }, {
        "id": "EMT",
        "lcc": "",
        "name": "Emetebe Airlines",
        "type": "airline"
    }, {
        "id": "PJ",
        "lcc": 0,
        "name": "Air Saint Pierre",
        "type": "airline"
    }, {
        "id": "ZO",
        "lcc": "",
        "name": "Zagros Airlines",
        "type": "airline"
    }, {
        "id": "B5",
        "lcc": 0,
        "name": "East African",
        "type": "airline"
    }, {
        "id": "K2",
        "lcc": 0,
        "name": "EuroLot",
        "type": "airline"
    }, {
        "id": "OL",
        "lcc": 0,
        "name": "Samoa Airways",
        "type": "airline"
    }, {
        "id": "TK",
        "lcc": 0,
        "name": "Turkish Airlines",
        "type": "airline"
    }, {
        "id": "Q9",
        "lcc": 0,
        "name": "Green Africa Airways",
        "type": "airline"
    }, {
        "id": "BV",
        "lcc": 1,
        "name": "Luke Air",
        "type": "airline"
    }, {
        "id": "9R",
        "lcc": 1,
        "name": "SATENA",
        "type": "airline"
    }, {
        "id": "AK",
        "lcc": 1,
        "name": "AirAsia",
        "type": "airline"
    }, {
        "id": "QZ",
        "lcc": 0,
        "name": "Indonesia AirAsia",
        "type": "airline"
    }, {
        "id": "UK",
        "lcc": 0,
        "name": "Air Vistara",
        "type": "airline"
    }, {
        "id": "UJ",
        "lcc": 0,
        "name": "AlMasria Universal Airlines",
        "type": "airline"
    }, {
        "id": "SL",
        "lcc": 1,
        "name": "Thai Lion Air",
        "type": "airline"
    }, {
        "id": "GL",
        "lcc": 0,
        "name": "Air Greenland",
        "type": "airline"
    }, {
        "id": "DE",
        "lcc": 1,
        "name": "Condor",
        "type": "airline"
    }, {
        "id": "4O",
        "lcc": 1,
        "name": "Interjet",
        "type": "airline"
    }, {
        "id": "D8",
        "lcc": 0,
        "name": "Norwegian International",
        "type": "airline"
    }, {
        "id": "DL",
        "lcc": 0,
        "name": "Delta Air Lines",
        "type": "airline"
    }, {
        "id": "BF",
        "lcc": 0,
        "name": "French Bee",
        "type": "airline"
    }, {
        "id": "FN",
        "lcc": 1,
        "name": "Fastjet",
        "type": "airline"
    }, {
        "id": "3K",
        "lcc": 0,
        "name": "Jetstar Asia Airways",
        "type": "airline"
    }, {
        "id": "F9",
        "lcc": 1,
        "name": "Frontier Airlines",
        "type": "airline"
    }, {
        "id": "3L",
        "lcc": 1,
        "name": "Air Arabia Abu Dhabi",
        "type": "airline"
    }, {
        "id": "W6",
        "lcc": 1,
        "name": "Wizz Air",
        "type": "airline"
    }, {
        "id": "Y4",
        "lcc": 1,
        "name": "Volaris",
        "type": "airline"
    }, {
        "id": "FZ",
        "lcc": 1,
        "name": "Fly Dubai",
        "type": "airline"
    }, {
        "id": "LW",
        "lcc": 0,
        "name": "Latin American Wings",
        "type": "airline"
    }, {
        "id": "CN",
        "lcc": 0,
        "name": "Grand China Air",
        "type": "airline"
    }, {
        "id": "UP",
        "lcc": 1,
        "name": "Bahamasair",
        "type": "airline"
    }, {
        "id": "PL",
        "lcc": 0,
        "name": "Southern Air Charter",
        "type": "airline"
    }, {
        "id": "S0",
        "lcc": 1,
        "name": "Aerolineas Sosa",
        "type": "airline"
    }, {
        "id": "AG",
        "lcc": 0,
        "name": "Aruba Airlines",
        "type": "airline"
    }, {
        "id": "HR",
        "lcc": 0,
        "name": "Hahn Airlines",
        "type": "airline"
    }, {
        "id": "HU",
        "lcc": 0,
        "name": "Hainan Airlines",
        "type": "airline"
    }, {
        "id": "PY",
        "lcc": 0,
        "name": "Surinam Airways",
        "type": "airline"
    }, {
        "id": "Q8",
        "lcc": 0,
        "name": "Trans Air Congo",
        "type": "airline"
    }, {
        "id": "I3",
        "lcc": "",
        "name": "ATA Airlines (Iran)",
        "type": "airline"
    }, {
        "id": "HH",
        "lcc": 0,
        "name": "Taban Airlines",
        "type": "airline"
    }, {
        "id": "ED",
        "lcc": 1,
        "name": "AirExplore",
        "type": "airline"
    }, {
        "id": "SHA",
        "lcc": "",
        "name": "Shree Airlines",
        "type": "airline"
    }, {
        "id": "VE",
        "lcc": 0,
        "name": "VE",
        "type": "airline"
    }, {
        "id": "ZK",
        "lcc": 0,
        "name": "Great Lakes Airlines",
        "type": "airline"
    }, {
        "id": "RI",
        "lcc": 0,
        "name": "Mandala Airlines",
        "type": "airline"
    }, {
        "id": "GX",
        "lcc": 1,
        "name": "GX airlines",
        "type": "airline"
    }, {
        "id": "TROPOCEAN",
        "lcc": "",
        "name": "Tropic Ocean Airways",
        "type": "airline"
    }, {
        "id": "GOVERNORS",
        "lcc": "",
        "name": "Governors Aviation",
        "type": "airline"
    }, {
        "id": "FLYTRISTAR",
        "lcc": "",
        "name": "Fly Tristar Services",
        "type": "airline"
    }, {
        "id": "DC",
        "lcc": 1,
        "name": "Braathens Regional Airways",
        "type": "airline"
    }, {
        "id": "GQ",
        "lcc": 0,
        "name": "Sky Express",
        "type": "airline"
    }, {
        "id": "LV",
        "lcc": 0,
        "name": "Level",
        "type": "airline"
    }, {
        "id": "WE",
        "lcc": 1,
        "name": "Thai Smile",
        "type": "airline"
    }, {
        "id": "7B",
        "lcc": 0,
        "name": "Bees Airline",
        "type": "airline"
    }, {
        "id": "BL",
        "lcc": 0,
        "name": "Jetstar Pacific",
        "type": "airline"
    }, {
        "id": "PR",
        "lcc": 0,
        "name": "Philippine Airlines",
        "type": "airline"
    }, {
        "id": "OX",
        "lcc": 1,
        "name": "Canarian Airways",
        "type": "airline"
    }, {
        "id": "AQ",
        "lcc": 1,
        "name": "9 Air",
        "type": "airline"
    }, {
        "id": "D9",
        "lcc": 0,
        "name": "Daallo Somalia",
        "type": "airline"
    }, {
        "id": "G8",
        "lcc": 1,
        "name": "Go First",
        "type": "airline"
    }, {
        "id": "IJ",
        "lcc": "",
        "name": "Spring Airlines Japan",
        "type": "airline"
    }, {
        "id": "UG",
        "lcc": 0,
        "name": "TunisAir Express",
        "type": "airline"
    }, {
        "id": "JT",
        "lcc": 1,
        "name": "Lion Air",
        "type": "airline"
    }, {
        "id": "OPENSKIES",
        "lcc": 1,
        "name": "Openskies",
        "type": "airline"
    }, {
        "id": "EX",
        "lcc": "",
        "name": "Regional Express Americas",
        "type": "airline"
    }, {
        "id": "E4",
        "lcc": 0,
        "name": "Enter Air",
        "type": "airline"
    }, {
        "id": "LE",
        "lcc": "",
        "name": "Norwegian Air Sweden LE",
        "type": "airline"
    }, {
        "id": "MN",
        "lcc": 1,
        "name": "Kulula",
        "type": "airline"
    }, {
        "id": "JJ",
        "lcc": 0,
        "name": "LATAM Brasil",
        "type": "airline"
    }, {
        "id": "XW",
        "lcc": 1,
        "name": "NokScoot",
        "type": "airline"
    }, {
        "id": "NR",
        "lcc": "",
        "name": "Manta Air",
        "type": "airline"
    }, {
        "id": "IT",
        "lcc": 0,
        "name": "Tigerair Taiwan",
        "type": "airline"
    }, {
        "id": "X3",
        "lcc": 1,
        "name": "TUI fly Deutschland",
        "type": "airline"
    }, {
        "id": "LM",
        "lcc": 0,
        "name": "LoganAir LM",
        "type": "airline"
    }, {
        "id": "UT",
        "lcc": 1,
        "name": "UTair",
        "type": "airline"
    }, {
        "id": "VR",
        "lcc": 0,
        "name": "Cabo Verde Airlines",
        "type": "airline"
    }, {
        "id": "LQ",
        "lcc": 1,
        "name": "Lanmei Airlines",
        "type": "airline"
    }, {
        "id": "VZ",
        "lcc": 0,
        "name": "Thai Vietjet",
        "type": "airline"
    }, {
        "id": "GW",
        "lcc": 0,
        "name": "Costa Rica Green Airways",
        "type": "airline"
    }, {
        "id": "BE",
        "lcc": 1,
        "name": "flybe",
        "type": "airline"
    }, {
        "id": "4C",
        "lcc": 0,
        "name": "LATAM Colombia",
        "type": "airline"
    }, {
        "id": "AL",
        "lcc": 0,
        "name": "Malta Air",
        "type": "airline"
    }, {
        "id": "GJ",
        "lcc": 0,
        "name": "Loong Air",
        "type": "airline"
    }, {
        "id": "OQ",
        "lcc": 0,
        "name": "Chongqing Airlines",
        "type": "airline"
    }, {
        "id": "UU",
        "lcc": 0,
        "name": "Air Austral",
        "type": "airline"
    }, {
        "id": "EU",
        "lcc": 0,
        "name": "Chengdu Airlines",
        "type": "airline"
    }, {
        "id": "NS",
        "lcc": 0,
        "name": "Hebei Airlines",
        "type": "airline"
    }, {
        "id": "NI",
        "lcc": 0,
        "name": "Portugalia",
        "type": "airline"
    }, {
        "id": "KY",
        "lcc": 0,
        "name": "Kunming Airlines",
        "type": "airline"
    }, {
        "id": "N4",
        "lcc": 0,
        "name": "Nordwind Airlines",
        "type": "airline"
    }, {
        "id": "RR",
        "lcc": "",
        "name": "Buzz",
        "type": "airline"
    }, {
        "id": "UR",
        "lcc": 0,
        "name": "Uganda Airlines",
        "type": "airline"
    }, {
        "id": "BK",
        "lcc": 0,
        "name": "Okay Airways",
        "type": "airline"
    }, {
        "id": "MI",
        "lcc": 0,
        "name": "Freebird Airlines Europe",
        "type": "airline"
    }, {
        "id": "RVP",
        "lcc": "",
        "name": "Sevenair",
        "type": "airline"
    }, {
        "id": "JAMAIR",
        "lcc": "",
        "name": "Jam Air express",
        "type": "airline"
    }, {
        "id": "H6",
        "lcc": 0,
        "name": "Bulgarian Air Charter",
        "type": "airline"
    }, {
        "id": "EAF",
        "lcc": "",
        "name": "Electra Airways EAF",
        "type": "airline"
    }, {
        "id": "GO",
        "lcc": 0,
        "name": "ULS Airlines Cargo",
        "type": "airline"
    }, {
        "id": "5P",
        "lcc": 0,
        "name": "Small Planet Airlines",
        "type": "airline"
    }, {
        "id": "9A",
        "lcc": "",
        "name": "GCA Airlines",
        "type": "airline"
    }, {
        "id": "FLYBAIR",
        "lcc": "",
        "name": "flyBAIR",
        "type": "airline"
    }, {
        "id": "E9",
        "lcc": 0,
        "name": "Iberojet Airlines",
        "type": "airline"
    }, {
        "id": "LO",
        "lcc": 0,
        "name": "LOT Polish Airlines",
        "type": "airline"
    }, {
        "id": "WK",
        "lcc": 0,
        "name": "Edelweiss Air",
        "type": "airline"
    }, {
        "id": "OY",
        "lcc": 0,
        "name": "Andes Líneas Aéreas",
        "type": "airline"
    }, {
        "id": "VK",
        "lcc": 0,
        "name": "LEVEL EUROPE",
        "type": "airline"
    }, {
        "id": "3B",
        "lcc": 0,
        "name": "Binter Cabo Verde",
        "type": "airline"
    }, {
        "id": "FQ",
        "lcc": 0,
        "name": "Thomas Cook Airlines",
        "type": "airline"
    }, {
        "id": "JN",
        "lcc": 0,
        "name": "JOON",
        "type": "airline"
    }, {
        "id": "HA",
        "lcc": 0,
        "name": "Hawaiian Airlines",
        "type": "airline"
    }, {
        "id": "VS",
        "lcc": 0,
        "name": "Virgin Atlantic Airways",
        "type": "airline"
    }, {
        "id": "P4",
        "lcc": 0,
        "name": "Air Peace Limited",
        "type": "airline"
    }, {
        "id": "Z2",
        "lcc": 0,
        "name": "Philippines AirAsia",
        "type": "airline"
    }, {
        "id": "QG",
        "lcc": 1,
        "name": "Citilink",
        "type": "airline"
    }, {
        "id": "5J",
        "lcc": 1,
        "name": "Cebu Pacific",
        "type": "airline"
    }, {
        "id": "4U",
        "lcc": 1,
        "name": "germanwings",
        "type": "airline"
    }, {
        "id": "LZ",
        "lcc": 0,
        "name": "belleair",
        "type": "airline"
    }, {
        "id": "F8",
        "lcc": 0,
        "name": "Flair Airlines",
        "type": "airline"
    }, {
        "id": "X9",
        "lcc": 0,
        "name": "Avion Express",
        "type": "airline"
    }, {
        "id": "PS",
        "lcc": 0,
        "name": "Ukraine International Airlines",
        "type": "airline"
    }, {
        "id": "VY",
        "lcc": 1,
        "name": "Vueling",
        "type": "airline"
    }, {
        "id": "TP",
        "lcc": 0,
        "name": "TAP Portugal",
        "type": "airline"
    }, {
        "id": "ND",
        "lcc": 0,
        "name": "Nordica",
        "type": "airline"
    }, {
        "id": "I5",
        "lcc": 0,
        "name": "AirAsia India",
        "type": "airline"
    }, {
        "id": "LS",
        "lcc": 1,
        "name": "Jet2",
        "type": "airline"
    }, {
        "id": "NT",
        "lcc": 1,
        "name": "Binter Canarias",
        "type": "airline"
    }, {
        "id": "JA",
        "lcc": 0,
        "name": "JetSMART",
        "type": "airline"
    }, {
        "id": "Q6",
        "lcc": 0,
        "name": "Volaris Costa Rica",
        "type": "airline"
    }, {
        "id": "NK",
        "lcc": 1,
        "name": "Spirit Airlines",
        "type": "airline"
    }, {
        "id": "LNH",
        "lcc": "",
        "name": "Lanhsa Airlines",
        "type": "airline"
    }, {
        "id": "PQ",
        "lcc": 0,
        "name": "SkyUp Airlines",
        "type": "airline"
    }, {
        "id": "OGN",
        "lcc": "",
        "name": "Origin Air",
        "type": "airline"
    }, {
        "id": "IL",
        "lcc": "",
        "name": "Trigana Air",
        "type": "airline"
    }, {
        "id": "LTR",
        "lcc": "",
        "name": "Lufttransport",
        "type": "airline"
    }, {
        "id": "NIS",
        "lcc": "",
        "name": "La Costena",
        "type": "airline"
    }, {
        "id": "HHI",
        "lcc": "",
        "name": "Air41",
        "type": "airline"
    }, {
        "id": "TEZ",
        "lcc": "",
        "name": "Tez Jet",
        "type": "airline"
    }, {
        "id": "PNP",
        "lcc": "",
        "name": "Pineapple Air",
        "type": "airline"
    }, {
        "id": "IOS",
        "lcc": "",
        "name": "Scilly Skybus",
        "type": "airline"
    }, {
        "id": "SMM",
        "lcc": "",
        "name": "Summit Air",
        "type": "airline"
    }, {
        "id": "U2",
        "lcc": 1,
        "name": "easyJet",
        "type": "airline"
    }, {
        "id": "ACK",
        "lcc": "",
        "name": "Nantucket Airlines",
        "type": "airline"
    }, {
        "id": "E7",
        "lcc": "",
        "name": "Equaflight Services",
        "type": "airline"
    }, {
        "id": "SJ",
        "lcc": 0,
        "name": "Sriwijaya Air",
        "type": "airline"
    }, {
        "id": "LJ",
        "lcc": 1,
        "name": "Jin Air",
        "type": "airline"
    }, {
        "id": "7P",
        "lcc": 1,
        "name": "AirPanama",
        "type": "airline"
    }, {
        "id": "AZ",
        "lcc": 0,
        "name": "ITA Airways",
        "type": "airline"
    }, {
        "id": "KL",
        "lcc": 0,
        "name": "KLM Royal Dutch Airlines",
        "type": "airline"
    }, {
        "id": "CY",
        "lcc": 0,
        "name": "Cyprus Airways",
        "type": "airline"
    }, {
        "id": "RK",
        "lcc": 0,
        "name": "Ryanair UK",
        "type": "airline"
    }, {
        "id": "7C",
        "lcc": 1,
        "name": "Jeju Air",
        "type": "airline"
    }, {
        "id": "4T",
        "lcc": 0,
        "name": "4T",
        "type": "airline"
    }, {
        "id": "J2",
        "lcc": 0,
        "name": "Azerbaijan Airlines",
        "type": "airline"
    }, {
        "id": "VA",
        "lcc": 1,
        "name": "Virgin Australia Airlines",
        "type": "airline"
    }, {
        "id": "AMX",
        "lcc": "",
        "name": "Amakusa Airlines",
        "type": "airline"
    }, {
        "id": "AY",
        "lcc": 0,
        "name": "Finnair",
        "type": "airline"
    }, {
        "id": "WN",
        "lcc": 1,
        "name": "Southwest Airline",
        "type": "airline"
    }, {
        "id": "HV",
        "lcc": 1,
        "name": "Transavia",
        "type": "airline"
    }, {
        "id": "TO",
        "lcc": 0,
        "name": "Transavia France",
        "type": "airline"
    }, {
        "id": "E5",
        "lcc": 0,
        "name": "Air Arabia Egypt",
        "type": "airline"
    }, {
        "id": "MM",
        "lcc": 1,
        "name": "Peach Aviation",
        "type": "airline"
    }, {
        "id": "5F",
        "lcc": 1,
        "name": "FLY ONE",
        "type": "airline"
    }, {
        "id": "AF",
        "lcc": 0,
        "name": "Air France",
        "type": "airline"
    }, {
        "id": "X1",
        "lcc": "",
        "name": "Hahn Air Technologies",
        "type": "airline"
    }, {
        "id": "U5",
        "lcc": 0,
        "name": "United Nigeria",
        "type": "airline"
    }, {
        "id": "1L",
        "lcc": "",
        "name": "CitizenPlane",
        "type": "airline"
    }, {
        "id": "YL",
        "lcc": 0,
        "name": "Libyan Wings",
        "type": "airline"
    }, {
        "id": "XC",
        "lcc": 1,
        "name": "Corendon",
        "type": "airline"
    }, {
        "id": "CA",
        "lcc": 0,
        "name": "Air China",
        "type": "airline"
    }, {
        "id": "AT",
        "lcc": 0,
        "name": "Royal Air Maroc",
        "type": "airline"
    }, {
        "id": "MF",
        "lcc": 0,
        "name": "Xiamen Airlines",
        "type": "airline"
    }, {
        "id": "QS",
        "lcc": 1,
        "name": "Smartwings",
        "type": "airline"
    }, {
        "id": "4B",
        "lcc": 0,
        "name": "BoutiqueAir",
        "type": "airline"
    }, {
        "id": "IHO",
        "lcc": "",
        "name": "748 Air Services",
        "type": "airline"
    }, {
        "id": "TG",
        "lcc": 0,
        "name": "Thai Airways International",
        "type": "airline"
    }, {
        "id": "ZP",
        "lcc": 0,
        "name": "Paranair",
        "type": "airline"
    }, {
        "id": "4Y",
        "lcc": "",
        "name": "Eurowings Discover",
        "type": "airline"
    }, {
        "id": "AV",
        "lcc": 0,
        "name": "Avianca",
        "type": "airline"
    }, {
        "id": "EC",
        "lcc": 0,
        "name": "Easyjet Europe",
        "type": "airline"
    }, {
        "id": "FB",
        "lcc": 0,
        "name": "Bulgaria Air",
        "type": "airline"
    }, {
        "id": "S7",
        "lcc": 0,
        "name": "S7 Airlines",
        "type": "airline"
    }, {
        "id": "YQ",
        "lcc": 0,
        "name": "TAR Aerolineas",
        "type": "airline"
    }, {
        "id": "B2",
        "lcc": 0,
        "name": "Belavia Belarusian Airlines",
        "type": "airline"
    }, {
        "id": "G2",
        "lcc": "",
        "name": "GullivAir",
        "type": "airline"
    }, {
        "id": "H4",
        "lcc": "",
        "name": "HiSky Europe",
        "type": "airline"
    }, {
        "id": "GK",
        "lcc": 0,
        "name": "Jetstar Japan",
        "type": "airline"
    }, {
        "id": "NH",
        "lcc": 0,
        "name": "All Nippon Airways",
        "type": "airline"
    }, {
        "id": "UBE",
        "lcc": "",
        "name": "Bees Airline",
        "type": "airline"
    }, {
        "id": "FTO",
        "lcc": "",
        "name": "Tropic Ocean Airways",
        "type": "airline"
    }, {
        "id": "L9",
        "lcc": "",
        "name": "Lumiwings",
        "type": "airline"
    }, {
        "id": "3O",
        "lcc": 0,
        "name": "Air Arabia Maroc",
        "type": "airline"
    }, {
        "id": "JU",
        "lcc": 0,
        "name": "Air Serbia",
        "type": "airline"
    }, {
        "id": "CZ",
        "lcc": 0,
        "name": "China Southern Airlines",
        "type": "airline"
    }, {
        "id": "KN",
        "lcc": 0,
        "name": "China United",
        "type": "airline"
    }, {
        "id": "SQS",
        "lcc": "",
        "name": "Susi Air",
        "type": "airline"
    }, {
        "id": "WH",
        "lcc": 0,
        "name": "WDL Aviation",
        "type": "airline"
    }, {
        "id": "7W",
        "lcc": 0,
        "name": "Windrose Airlines",
        "type": "airline"
    }, {
        "id": "LEVELSPAIN",
        "lcc": "",
        "name": "IBERIA for LEVEL SPAIN",
        "type": "airline"
    }, {
        "id": "PRM",
        "lcc": "",
        "name": "Premier Airlines",
        "type": "airline"
    }, {
        "id": "SKYALPS",
        "lcc": "",
        "name": "Sky Alps",
        "type": "airline"
    }, {
        "id": "RNG",
        "lcc": "",
        "name": "Renegade Air",
        "type": "airline"
    }, {
        "id": "YC",
        "lcc": 1,
        "name": "Yamal Air",
        "type": "airline"
    }, {
        "id": "SLD",
        "lcc": "",
        "name": "Silver Air",
        "type": "airline"
    }, {
        "id": "3Z",
        "lcc": "",
        "name": "Smartwings Poland",
        "type": "airline"
    }, {
        "id": "WSG",
        "lcc": "",
        "name": "Wasaya Airways",
        "type": "airline"
    }, {
        "id": "UEPFLY",
        "lcc": "",
        "name": "UEP Fly",
        "type": "airline"
    }, {
        "id": "EZZ",
        "lcc": "",
        "name": "ETF Airways",
        "type": "airline"
    }, {
        "id": "SA",
        "lcc": 0,
        "name": "South African Airways",
        "type": "airline"
    }, {
        "id": "PRJ",
        "lcc": "",
        "name": "Prishtina JET",
        "type": "airline"
    }, {
        "id": "XK",
        "lcc": 0,
        "name": "Air Corsica",
        "type": "airline"
    }, {
        "id": "XP",
        "lcc": "",
        "name": "Avelo",
        "type": "airline"
    }, {
        "id": "A7",
        "lcc": 0,
        "name": "Calafia Airlines",
        "type": "airline"
    }, {
        "id": "MNE",
        "lcc": "",
        "name": "Air Montenegro",
        "type": "airline"
    }, {
        "id": "WZ",
        "lcc": 1,
        "name": "Red Wings",
        "type": "airline"
    }, {
        "id": "FHM",
        "lcc": "",
        "name": "Freebird Airlines Europe",
        "type": "airline"
    }, {
        "id": "GLX",
        "lcc": "",
        "name": "Global crossing airlines",
        "type": "airline"
    }, {
        "id": "GXA",
        "lcc": "",
        "name": "Global Crossing Airlines",
        "type": "airline"
    }, {
        "id": "9D",
        "lcc": "",
        "name": "Genghis Khan Airlines",
        "type": "airline"
    }, {
        "id": "SJB",
        "lcc": "",
        "name": "SKS Airways",
        "type": "airline"
    }, {
        "id": "AOA",
        "lcc": "",
        "name": "Air Ocean Airlines",
        "type": "airline"
    }, {
        "id": "OA",
        "lcc": 0,
        "name": "Olympic Air",
        "type": "airline"
    }, {
        "id": "TELAVIVAIR",
        "lcc": "",
        "name": "Tel Aviv Air",
        "type": "airline"
    }, {
        "id": "HELLOFLY",
        "lcc": "",
        "name": "HelloFly",
        "type": "airline"
    }, {
        "id": "LIT",
        "lcc": "",
        "name": "FlyleOne",
        "type": "airline"
    }, {
        "id": "N3",
        "lcc": "",
        "name": "Volaris El Salvador",
        "type": "airline"
    }, {
        "id": "Q4",
        "lcc": "",
        "name": "Euroairlines",
        "type": "airline"
    }, {
        "id": "SSJ",
        "lcc": "",
        "name": "KrasAvia",
        "type": "airline"
    }, {
        "id": "SARPA",
        "lcc": "",
        "name": "Sarpa",
        "type": "airline"
    }, {
        "id": "O1",
        "lcc": 0,
        "name": "Orbit Airlines Azerbaijan",
        "type": "airline"
    }, {
        "id": "GJT",
        "lcc": "",
        "name": "GetJet",
        "type": "airline"
    }, {
        "id": "NICEAIR",
        "lcc": "",
        "name": "Niceair",
        "type": "airline"
    }, {
        "id": "HST",
        "lcc": "",
        "name": "Heston Airlines",
        "type": "airline"
    }, {
        "id": "4J",
        "lcc": "",
        "name": "Jetair Caribbean",
        "type": "airline"
    }, {
        "id": "BAR",
        "lcc": "",
        "name": "BAR Aviation",
        "type": "airline"
    }, {
        "id": "LOD",
        "lcc": "",
        "name": "L'Odyssey",
        "type": "airline"
    }, {
        "id": "2R",
        "lcc": "",
        "name": "Sunlight Air",
        "type": "airline"
    }, {
        "id": "RLB",
        "lcc": "",
        "name": "Sunlight Air",
        "type": "airline"
    }, {
        "id": "EY",
        "lcc": 0,
        "name": "Etihad Airways",
        "type": "airline"
    }, {
        "id": "IU",
        "lcc": "",
        "name": "Super Air Jet",
        "type": "airline"
    }, {
        "id": "ZG",
        "lcc": 0,
        "name": "ZIPAIR",
        "type": "airline"
    }, {
        "id": "FNA",
        "lcc": "",
        "name": "Norlandair",
        "type": "airline"
    }, {
        "id": "ORIGIN",
        "lcc": "",
        "name": "Origin Air",
        "type": "airline"
    }, {
        "id": "QP",
        "lcc": "",
        "name": "Akasa Air",
        "type": "airline"
    }, {
        "id": "PK",
        "lcc": 0,
        "name": "Pakistan International Airlines",
        "type": "airline"
    }, {
        "id": "4F",
        "lcc": "",
        "name": "Freedom Airline Express",
        "type": "airline"
    }, {
        "id": "4V",
        "lcc": "",
        "name": "Fly Gangwon",
        "type": "airline"
    }, {
        "id": "HB",
        "lcc": "",
        "name": "Greater Bay Airlines",
        "type": "airline"
    }, {
        "id": "YI",
        "lcc": "",
        "name": "Fly OYA",
        "type": "airline"
    }, {
        "id": "YP",
        "lcc": "",
        "name": "Air Premia",
        "type": "airline"
    }, {
        "id": "MTO",
        "lcc": "",
        "name": "Marathon Airlines",
        "type": "airline"
    }, {
        "id": "X8",
        "lcc": "",
        "name": "Avion Express Malta",
        "type": "airline"
    }, {
        "id": "2S",
        "lcc": "",
        "name": "Southwind Airlines",
        "type": "airline"
    }, {
        "id": "U0",
        "lcc": "",
        "name": "Ultra Air",
        "type": "airline"
    }, {
        "id": "ALS",
        "lcc": "",
        "name": "Fly ALS",
        "type": "airline"
    }, {
        "id": "W9",
        "lcc": 0,
        "name": "Wizz Air UK",
        "type": "airline"
    }, {
        "id": "BA",
        "lcc": 0,
        "name": "British Airways",
        "type": "airline"
    }, {
        "id": "S4",
        "lcc": 0,
        "name": "SATA Azores Airlines",
        "type": "airline"
    }, {
        "id": "HX",
        "lcc": 1,
        "name": "Hong Kong Airlines",
        "type": "airline"
    }, {
        "id": "TV",
        "lcc": 0,
        "name": "Tibet Airlines",
        "type": "airline"
    }, {
        "id": "MH",
        "lcc": 1,
        "name": "Malaysia Airlines",
        "type": "airline"
    }, {
        "id": "Z0",
        "lcc": "",
        "name": "Norse Atlantic UK",
        "type": "airline"
    }, {
        "id": "E6",
        "lcc": "",
        "name": "Eurowings Europe Malta",
        "type": "airline"
    }];
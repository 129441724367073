import { Slider } from '@mui/material';

function TimesFilter({
	timeFilter,
	setTimeFilter,
	tripDetails,
	index,
	localActiveTabIndex,
}) {
	const { travelData = {} } = tripDetails[localActiveTabIndex][index];

	const { flightDetails } = travelData;

	const handleChange = (newValue, ix) => {
		setTimeFilter((prev) =>
			prev.map((item, ind) => {
				if (ind === ix) {
					return newValue;
				}

				return item;
			}),
		);
	};

	const formatHour = (hour) => {
		if (hour === 0) {
			return '12AM';
		}
		if (hour === 12) {
			return '12PM';
		}
		if (hour < 12) {
			return `${hour}AM`;
		}
		return `${hour - 12}PM`;
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="text-sm">
				{flightDetails?.fromAirport?.join(', ')} to{' '}
				{flightDetails?.toAirport?.join(', ')}
			</div>

			{[...Array(2).keys()].map((ix) => {
				return (
					<div key={ix} className="text-sm mt-1">
						{!ix ? 'Departure' : 'Arrival'}

						<div style={{ width: 300, padding: '0 12px' }}>
							<Slider
								min={0}
								max={24}
								value={timeFilter[ix]}
								onChange={(_, newValue) => handleChange(newValue, ix)}
								valueLabelDisplay="auto"
								aria-labelledby="time-range-slider"
								getAriaValueText={(val) => formatHour(val)}
								valueLabelFormat={(val) => formatHour(val)}
								sx={{
									'& .MuiSlider-valueLabel': {
										backgroundColor: '#007bff',
										color: '#fff',
										fontSize: '14px',
										padding: '8px',
										borderRadius: '4px',
									},
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default TimesFilter;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	HotelReviewSummaryStages,
	LoadingState,
	MainTripPageView,
} from '@helpers/Enums';
import FlightsLoadingComponent from '@commons/FlightsLoadingComponent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import isEmpty from '@utils/isEmpty';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import {
	fetchPhotosBatch,
	getPlaceNameForHotel,
} from '@helpers/PhotosQueryHelper';
import TabChangeInfoComponent from '../OverallResults/TabChangeInfoComponent';
import HotelCard from './HotelCard/HotelCard';
import HotelFilters from './FiltersSection/Filter';
import HotelTabs from './HotelTabs';
import styles from './styles.module.css';
import HelpMeChoose from './HelpMeChoose';

const POSSIBLE_FILTERS = ['amenities', 'price', 'rating'];

const ITEMS_PER_PAGE = 10;

const INITIAL_HOTELS = 4;

function checkImage(url) {
	return new Promise((resolve) => {
		const img = new Image();
		img.onload = () => resolve(true); // Image loaded successfully
		img.onerror = () => resolve(false); // Image failed to load
		img.src = url;
	});
}

function findActualIndex(arr, i) {
	let count = 0;
	for (let index = 0; index < arr.length; index += 1) {
		if (arr[index]) {
			if (count === i) {
				return index;
			}
			count += 1;
		}
	}
	return -1;
}

const appendHotelImages = ({ gMapsData, placeName, setHotelImages }) => {
	const dataPromises = gMapsData[placeName]?.[0]?.[0]?.photos_data.map(
		async (
			{ original_photo_url, photo_url, photo_url_big, photo_id },
			index,
		) => {
			const alt = `photo${index + 1}`;

			const value1 = await checkImage(photo_url_big).then((loaded) => ({
				src: photo_url_big,
				alt,
				loaded,
			}));

			if (value1.loaded) {
				return value1;
			}

			const value2 = await checkImage(original_photo_url || photo_url).then(
				(loaded) => ({
					src: original_photo_url || photo_url,
					alt,
					loaded,
				}),
			);

			if (value2.loaded) {
				return value2;
			}

			const value3 = await checkImage(photo_id).then((loaded) => ({
				src: photo_id,
				alt,
				loaded,
			}));

			return value3;
		},
	);

	if (dataPromises) {
		Promise.all(dataPromises).then((data) => {
			const filteredData = data.filter((photo) => photo.loaded);
			setHotelImages((prev) => ({ ...prev, [placeName]: filteredData }));
		});
	}
};

function Hotels({
	setCurrentView = () => {},
	tabChanged = false,
	handleUpdateHotelsAndItinerary = () => {},
}) {
	const { hotel_results = [], loadingState } = useSelector(
		({ ui_states = {} }) => ({
			hotel_results: ui_states.hotel_results,
			loadingState: ui_states.loadingState,
		}),
	);
	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const { hotelsLoading = LoadingState.INIT } = loadingState;

	const curHotelResults = hotel_results[active_tab_index];
	const dispatch = useDispatch();
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);

	const hotelSets = useSelector((state) => state.tripLocationDetails.hotelSets);
	const selectedHotels = useSelector((state) => state.ui_states.selectedHotels);
	const hotelFilters = useSelector(
		(state) => state.tripLocationDetails.hotelFilters,
	);
	const hotelReqs = useSelector((state) => state.tripLocationDetails.hotelReqs);
	const numTravellers = useSelector(
		(state) => state.tripLocationDetails.numTravellers,
	);
	const hotelReviewSummaryLoadingState = useSelector(
		(state) => state.ui_states.hotelReviewSummaryLoadingState,
	);
	const requiredHotelFilters = hotelFilters.filter(
		(hotelFilter) => hotelFilter,
	);

	const { HOTEL_OFFERSETS = [] } = curHotelResults || {};

	const [currentLoc, setCurrentLoc] = useState(0);

	const currLoadingState = hotelReviewSummaryLoadingState?.[currentLoc]?.state;
	const [hotelImages, setHotelImages] = useState({});
	const [page, setPage] = useState(0);
	const [
		isFinalLoadingCompletedForReviews,
		setIsFinalLoadingCompletedForReviews,
	] = useState(currLoadingState === HotelReviewSummaryStages.REPORT);

	const hotelsDataLoading = {};

	const currentHotelSet = HOTEL_OFFERSETS[0] || [];

	const handleChange = (newValue) => {
		sendMessageEvent({
			event: GoogleEventTypes.HOTELS_VIEW_SWITCH_LOCATION,
			chat_session_id: chatSessionId,
		});

		setPage(0);
		setCurrentLoc(newValue);
	};

	const hotelsOfLocation = (currentHotelSet?.[currentLoc] || []).slice(
		0,
		INITIAL_HOTELS + page * ITEMS_PER_PAGE,
	);

	const firstFourHotelsWithPlaceName = currentHotelSet.map(
		(allHotelsOfLocation, index) => {
			return allHotelsOfLocation.slice(0, INITIAL_HOTELS).map((hotelData) => {
				const { hotel = {}, offers = [] } = hotelData;

				const placeName = getPlaceNameForHotel(hotel, hotelFilters, index);

				return {
					price_per_night: Number(
						offers[0].price?.variations?.average?.base?.split('.')?.[0] ||
							offers[0].price?.variations?.average?.total?.split('.')?.[0] ||
							offers[0].price?.total?.split('.')?.[0], // check this condition
					),
					name: placeName,
					description: '',
				};
			});
		},
	);

	const isLastPage =
		hotelsOfLocation?.length === (currentHotelSet?.[currentLoc] || []).length;

	const processHotels = (callback) => {
		(currentHotelSet || []).forEach((allHotelsOfLocation, index) => {
			const hotelsToLoad =
				index === currentLoc
					? page * ITEMS_PER_PAGE + INITIAL_HOTELS
					: INITIAL_HOTELS;

			allHotelsOfLocation.slice(0, hotelsToLoad).forEach((hotelData) => {
				if (hotelData && hotelData.hotel && hotelData.hotel.name) {
					const placeName = getPlaceNameForHotel(
						hotelData.hotel,
						hotelFilters,
						index,
					);
					callback(placeName, index);
				}
			});
		});
	};

	useEffect(() => {
		processHotels((placeName) => {
			if (gMapsData[placeName] && !hotelImages[placeName]) {
				appendHotelImages({ gMapsData, placeName, setHotelImages });
			}
		});
	}, [gMapsData]);

	useEffect(() => {
		const placesToFetch = [];

		processHotels((placeName) => {
			if (!hotelsDataLoading[placeName] && !gMapsData[placeName]) {
				placesToFetch.push(placeName);
				hotelsDataLoading[placeName] = 'loading';
			}
		});

		fetchPhotosBatch(placesToFetch, dispatch);
	}, [currentLoc, page]);

	const requiredHotelDateSets = hotelSets[0].filter((dateSet) => dateSet);

	return (
		<div className="flex flex-col w-full gap-3 pr-6 mb-4">
			<div className="flex justify-between items-center w-full">
				<div
					className="flex gap-1 items-center cursor-pointer"
					role="presentation"
					onClick={() => {
						sendMessageEvent({
							event: GoogleEventTypes.CHANGE_VIEW_FROM_HOTELS_TO_MAIN_PAGE,
							chat_session_id: chatSessionId,
						});
						setCurrentView(MainTripPageView.OVERVIEW);
					}}
				>
					<ArrowBackIosIcon fontSize="small" />

					<div style={{ color: '#301345', fontSize: '20px', fontWeight: 600 }}>
						Hotels
					</div>
				</div>

				<HotelFilters POSSIBLE_FILTERS={POSSIBLE_FILTERS} />
			</div>

			<div className="flex flex-col gap-4 w-full">
				<HotelTabs
					hotel_datesets={requiredHotelDateSets}
					hotelFilters={hotelFilters}
					value={currentLoc}
					onChange={handleChange}
				/>

				<HelpMeChoose
					firstFourHotelsWithPlaceName={firstFourHotelsWithPlaceName}
					currentLoc={currentLoc}
					setIsFinalLoadingCompletedForReviews={
						setIsFinalLoadingCompletedForReviews
					}
				/>

				{hotelsLoading !== LoadingState.LOADING ? (
					<div className="w-full flex flex-col" style={{ gap: '24px' }}>
						{hotelsOfLocation.map((hotelObject) => {
							const { hotel } = hotelObject;
							const { hotelId } = hotel;
							const placeName = getPlaceNameForHotel(
								hotel,
								hotelFilters,
								currentLoc,
							);
							const locationIndex = findActualIndex(hotelReqs, currentLoc);
							const isHotelSelected =
								hotelId === selectedHotels[locationIndex]?.hotel?.hotelId;
							const googleHotelData = gMapsData[placeName]?.[0]?.[0] || {};
							const isImagesLoaded = !!hotelImages?.[placeName];
							const isImagesEmpty =
								!!hotelImages?.[placeName] && isEmpty(hotelImages?.[placeName]);

							return (
								<HotelCard
									key={placeName}
									locationIndex={locationIndex}
									isHotelSelected={isHotelSelected}
									numTravellers={numTravellers}
									hotelDateSetDetails={requiredHotelDateSets[currentLoc] || {}}
									hotelFilterDetails={requiredHotelFilters[currentLoc]}
									hotelImages={hotelImages?.[placeName]}
									placeName={placeName}
									googleHotelData={googleHotelData}
									hotelObject={hotelObject}
									isImagesEmpty={isImagesEmpty}
									isImagesLoaded={isImagesLoaded}
									isFinalLoadingCompletedForReviews={
										isFinalLoadingCompletedForReviews
									}
								/>
							);
						})}
					</div>
				) : (
					<FlightsLoadingComponent />
				)}

				{!hotelsOfLocation.length &&
				!tabChanged &&
				hotelsLoading !== LoadingState.LOADING ? (
					<div className="font-normal text-red-900 w-full text-center">
						Hotels Not Found
					</div>
				) : null}

				{!hotelsOfLocation.length &&
				tabChanged &&
				hotelsLoading !== LoadingState.LOADING ? (
					<div className="font-normal text-red-900 w-full text-center">
						<TabChangeInfoComponent
							handleUpdateHotelsAndItinerary={handleUpdateHotelsAndItinerary}
						/>
					</div>
				) : null}

				{!isLastPage ? (
					<button
						type="button"
						className={styles.button}
						onClick={() => {
							sendMessageEvent({
								event: GoogleEventTypes.HOTELS_VIEW_SEE_MORE_HOTELS_OF_LOCATION,
								chat_session_id: chatSessionId,
							});
							setPage((prev) => prev + 1);
						}}
					>
						See More
					</button>
				) : null}
			</div>
		</div>
	);
}

export default Hotels;

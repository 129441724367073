function ArrowRight() {
	return (
		<div
			style={{
				width: '32px',
				height: '32px',
				borderRadius: '16px',
				backgroundColor: '#F0ECFC',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				transition:
					'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
			}}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="28"
				height="28"
				viewBox="0 0 32 32"
				fill="none"
				style={{
					transition: 'stroke 0.3s ease-in-out',
				}}
			>
				<path
					d="M11 26L21 16L11 6"
					stroke="#36174D"
					strokeWidth="1"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default ArrowRight;

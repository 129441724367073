import { SortOptions } from '@helpers/Enums';
import isEmpty from '@utils/isEmpty';

const durationToMinutes = (duration) => {
	const regex = /(\d+d)?(\d+h)?(\d+m)?/;
	const matches = duration.match(regex);

	const d = matches[1] ? parseInt(matches[1].replace('d', ''), 10) : 0;
	const h = matches[2] ? parseInt(matches[2].replace('h', ''), 10) : 0;
	const m = matches[3] ? parseInt(matches[3].replace('m', ''), 10) : 0;
	return d * 24 * 60 + h * 60 + m;
};

const totalDurationForItinerary = (itinerary) => {
	return itinerary.legs.reduce(
		(sum, leg) => sum + durationToMinutes(leg.duration),
		0,
	);
};

// export const sortFlights = (tabsData, sort_option) => {
// 	if (sort_option === SortOptions.BEST) {
// 		tabsData.map((tabData) => {
// 			const minDuration = Math.min(
// 				...tabData.content.map((itinerary) =>
// 					totalDurationForItinerary(itinerary),
// 				),
// 			);
// 			const minPrice = Math.min(
// 				...tabData.content.map((itinerary) => itinerary.totalPrice),
// 			);

// 			return tabData.content.sort((a, b) => {
// 				const getStopsMultiplier = (itinerary) => {
// 					const stopsWeights = [1, 1.5, 2.5, 4];
// 					return itinerary.legs.reduce(
// 						(multiplier, leg) =>
// 							multiplier * stopsWeights[Math.min(parseInt(leg.stops, 10), 3)],
// 						1,
// 					);
// 				};

// 				const getDurationMultiplier = (itinerary) => {
// 					const durationPenalty = 1.25;
// 					return (
// 						1 +
// 						(durationPenalty *
// 							(totalDurationForItinerary(itinerary) - minDuration)) /
// 							minDuration
// 					);
// 				};

// 				const getPriceMultiplier = (itinerary) => {
// 					const pricePenalty = 1;
// 					return (
// 						1 + (pricePenalty * (itinerary.totalPrice - minPrice)) / minPrice
// 					);
// 				};

// 				const getScore = (itinerary) =>
// 					itinerary.totalPrice *
// 					getPriceMultiplier(itinerary) *
// 					getStopsMultiplier(itinerary) *
// 					getDurationMultiplier(itinerary);

// 				return getScore(a) - getScore(b);
// 			});
// 		});
// 	} else if (!sort_option || sort_option === SortOptions.PRICE) {
// 		tabsData.map((tabData) =>
// 			tabData.content.sort((a, b) => a.totalPrice - b.totalPrice),
// 		);
// 	} else if (sort_option === SortOptions.DURATION) {
// 		tabsData.map((tabData) =>
// 			tabData.content.sort((a, b) => {
// 				const totDurationA = totalDurationForItinerary(a);
// 				const totDurationB = totalDurationForItinerary(b);
// 				return totDurationA - totDurationB;
// 			}),
// 		);
// 	}
// };

const calculateScore = (itinerary, minDuration, minPrice) => {
	const stopsMultiplier = () => {
		const stopsWeights = [1, 1.5, 2.5, 4];
		return itinerary.legs.reduce((multiplier, leg) => {
			const stops = Math.min(parseInt(leg.stops, 10), 3);
			return multiplier * stopsWeights[stops];
		}, 1);
	};

	const durationMultiplier = () => {
		const durationPenalty = 1.25;
		const durationDifference =
			totalDurationForItinerary(itinerary) - minDuration;
		return 1 + (durationPenalty * durationDifference) / minDuration;
	};

	const priceMultiplier = () => {
		const pricePenalty = 1;
		const priceDifference = itinerary.totalPrice - minPrice;
		return 1 + (pricePenalty * priceDifference) / minPrice;
	};

	return (
		itinerary.totalPrice *
		stopsMultiplier() *
		durationMultiplier() *
		priceMultiplier()
	);
};

export const sortFlights = (tabsData, sortOption) => {
	const sortedTabsData = tabsData.map((tabData) => ({
		...tabData,
		content: [...tabData.content],
	}));

	sortedTabsData.forEach((tabData) => {
		if (sortOption === SortOptions.BEST) {
			const minDuration = Math.min(
				...tabData.content.map((itinerary) =>
					totalDurationForItinerary(itinerary),
				),
			);
			const minPrice = Math.min(
				...tabData.content.map((itinerary) => itinerary.totalPrice),
			);

			tabData.content.sort((a, b) => {
				const scoreA = calculateScore(a, minDuration, minPrice);
				const scoreB = calculateScore(b, minDuration, minPrice);
				return scoreA - scoreB;
			});
		} else if (sortOption === SortOptions.PRICE) {
			tabData.content.sort((a, b) => a.totalPrice - b.totalPrice);
		} else if (sortOption === SortOptions.DURATION) {
			tabData.content.sort((a, b) => {
				const durationA = totalDurationForItinerary(a);
				const durationB = totalDurationForItinerary(b);
				return durationA - durationB;
			});
		}
	});

	return sortedTabsData;
};

// Function to find the best flight among all date options (only pass sorted tabData)
export const findBestFlight = (tabsData) => {
	let bestFlight = null;
	let bestScore = Infinity;
	let tabIndex = null;

	// TODO: This implementation is flawed. The minPrice is the not the price of the first flight in the list.
	// For now, we're using the min-price flight to pick the best date option after sorting by sort criterion with each date option.
	/*tabsData.forEach((tabData, index) => {
		if (isEmpty(tabData.content)) {
			return;
		}

		const minDuration = totalDurationForItinerary(tabData.content[0]);
		const minPrice = tabData.content[0].totalPrice;

		const score = calculateScore(tabData.content[0], minDuration, minPrice);

		if (score < bestScore) {
			bestScore = score;
			bestFlight = tabData?.content?.[0];
			tabIndex = index;
		}
	});*/

	tabsData.forEach((tabData, index) => {
		if (isEmpty(tabData.content)) {
			return;
		}

		if (tabData.content[0].totalPrice < bestScore) {
			bestScore = tabData.content[0].totalPrice;
			bestFlight = tabData?.content?.[0];
			tabIndex = index;
		}
	});

	return { bestFlight: bestFlight || {}, tabIndex: tabIndex || 0 };
};

import CallIcon from '@mui/icons-material/Call';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import startCase from '@utils/startCase';
import ReviewStars from '@commons/ReviewStars';
import ImageSlider from './ImageSlider';
import OpeningHours from './OpeningHours';
import GoogleMapsSvg from './GoogleMapsSvg';

function InfoComponent({
	setActiveMarker = () => {},
	setZoomLevel = () => {},
	complete_results,
}) {

	const {
		name = '',
		adr_address = '',
		types = [],
		rating = 0,
		user_ratings_total = 0,
		website = '',
		opening_hours = {},
		international_phone_number = '',
		url = '',
		photoUrls = [],
		description = ''
	} = complete_results || {};

	const [websiteHovered, setWebsiteHovered] = useState(false);
	const [numberHovered, setNumberHovered] = useState(false);
	const [showToast, setShowToast] = useState(false);

	const mainPart = website ? new URL(website).hostname : '';

	const handleRedirect = () => {
		window.open(website, '_blank');
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(website);

		setShowToast(true);
	};

	const photosData = photoUrls.map(url => ({
		src: url,
		alt: name,
	}));

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setShowToast(false);
	};

	if (!complete_results) {
		return null;
	}

	return (
		<div className="w-72 bg-white rounded-md pb-1 relative">
			<ImageSlider data={photosData} />

			<div
				className="mt-3 mx-3"
				style={{ marginBottom: '4px', fontSize: '18px', fontWeight: '700' }}
			>
				{name}
			</div>

			{/* <div
				className="absolute cursor-pointer"
				style={{ right: '4px', top: '4px' }}
			>
				<CancelRoundedIcon
					fontSize="small"
					style={{ color: '#fff' }}
					onClick={() => {
						setActiveMarker(null);
						setZoomLevel(12);
					}}
				/>
			</div> */}

			<div
				className="text-xs mb-2 mx-3"
				style={{ fontWeight: '500' }}
				dangerouslySetInnerHTML={{ __html: adr_address }}
			/>

			<div className="ml-3">
				<ReviewStars rating={rating} user_ratings_total={user_ratings_total} />
			</div>

			<div className="flex gap-1 mt-1 mb-1 mx-3">
				{types.map((item, index) => {
					if (item && index < 2) {
						return (
							<Chip
								style={{
									color: '#9353F2',
									backgroundColor: '#EEE2FF',
									fontSize: '11px',
									borderRadius: '4px',
									padding: '0px',
									height: '22px',
								}}
								label={startCase(item)}
								size="small"
							/>
						);
					}

					return null;
				})}
			</div>

			{description ? (
				<div
					className="text-xs mb-2 mt-3 font-normal mx-3"
					style={{ color: '#495057' }}
				>
					{description}
				</div>
			) : null}

			<div
				className="flex  items-center py-1 justify-between hover:bg-gray-100 cursor-pointer px-3 h-8"
				onMouseEnter={() => setWebsiteHovered(true)}
				onMouseLeave={() => setWebsiteHovered(false)}
			>
				<div className="flex items-center" style={{ gap: '10px' }}>
					<LanguageOutlinedIcon fontSize="small" style={{ color: '#1B72E8' }} />

					<div
						className="text-xs font-normal hover:underline"
						dangerouslySetInnerHTML={{ __html: mainPart }}
						style={{ fontWeight: '400' }}
					/>
				</div>

				{websiteHovered ? (
					<div className="flex space-x-1">
						<IconButton onClick={handleRedirect}>
							<LaunchOutlinedIcon style={{ fontSize: 18 }} />
						</IconButton>
						<IconButton onClick={handleCopy}>
							<ContentCopyOutlinedIcon style={{ fontSize: 18 }} />
						</IconButton>
					</div>
				) : null}
			</div>

			<div
				className="flex items-center py-1 justify-between hover:bg-gray-100 cursor-pointer px-3 h-8"
				onMouseEnter={() => setNumberHovered(true)}
				onMouseLeave={() => setNumberHovered(false)}
			>
				<div className="flex items-center" style={{ gap: '10px' }}>
					<CallIcon fontSize="small" style={{ color: '#1B72E8' }} />

					<div
						className="text-xs font-normal hover:underline"
						dangerouslySetInnerHTML={{ __html: international_phone_number }}
						style={{ fontWeight: '400' }}
					/>
				</div>

				{numberHovered ? (
					<div className="flex space-x-1">
						<IconButton onClick={handleCopy}>
							<ContentCopyOutlinedIcon style={{ fontSize: 18 }} />
						</IconButton>
					</div>
				) : null}
			</div>

			{opening_hours &&
				<OpeningHours openingHours={opening_hours} />
			}

			<div className="flex items-center py-2 px-3">
				<a
					target="_blank"
					href={url}
					rel="noreferrer"
					className="cursor-pointer rounded-xl"
					style={{
						borderWidth: '1px',
						padding: '2px 6px 2px 4px',
						fontSize: '10px',
						border: '1px solid #bb9aec',
					}}
				>
					<div className="flex items-center gap-1">
						<GoogleMapsSvg />

						<div>Google Maps</div>
					</div>
				</a>
			</div>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={showToast}
				autoHideDuration={2000}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Copied Successfully!
				</Alert>
			</Snackbar>
		</div>
	);
}

export default InfoComponent;

/* eslint-disable max-len */
function EditIcon({ onClick = () => {}, width, height }) {
	return (
		<div role="presentation" className="cursor-pointer" onClick={onClick}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={width || '20'}
				height={height || '20'}
				viewBox="0 0 24 24"
				fill="none"
			>
				<rect
					x="0.3"
					y="0.3"
					width="23.4"
					height="23.4"
					rx="11.7"
					stroke="#7750E5"
					strokeWidth="1"
				/>
				<path
					d="M13.1473 6.75148L6.98817 13.2708C6.7556 13.5183 6.53054 14.006 6.48553 14.3435L6.20795 16.7742C6.11043 17.6519 6.7406 18.2521 7.61083 18.1021L10.0265 17.6895C10.3641 17.6294 10.8367 17.3819 11.0693 17.1268L17.2285 10.6075C18.2937 9.48222 18.7739 8.19938 17.1159 6.63145C15.4655 5.07853 14.2126 5.62618 13.1473 6.75148Z"
					fill="white"
					stroke="#7750E5"
					strokeWidth="1"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.1206 7.8418C12.4432 9.91236 14.1237 11.4953 16.2092 11.7053L12.1206 7.8418Z"
					fill="white"
				/>
				<path
					d="M12.1206 7.8418C12.4432 9.91236 14.1237 11.4953 16.2092 11.7053"
					stroke="#7750E5"
					strokeWidth="1"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}

export default EditIcon;

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FiltersDropdown({
	filterName,
	filterWidget,
	filterPopulated,
	anchorEl,
	setAnchorEl,
	handleClose,
}) {
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget, open);
	};

	return (
		<div>
			<Button
				id={`${filterName}_filter_dropdown_button`}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={<ExpandMoreIcon />}
				variant="outlined"
				style={{
					color: '#301345',
					border: filterPopulated
						? '1px solid rgb(109 40 217)'
						: '1px solid #CEC8D2',
					textTransform: 'unset',
					fontSize: '12px',
					backgroundColor: filterPopulated ? '#ddd6fe' : '#fff',
					borderRadius: '32px',
				}}
			>
				{filterName}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'filters-dropdown-button',
				}}
				sx={{ marginTop: '5px' }}
			>
				<MenuItem key="Adults">{filterWidget}</MenuItem>
			</Menu>
		</div>
	);
}

import classNames from 'classnames';
import startCase from '@utils/startCase';
import styles from './styles.module.css';

function TimeRangePicker({
	startTime = {},
	endTime = {},
	setStartTime = () => {},
	setEndTime = () => {},
}) {
	const handleInputChange = (e, type, timeType) => {
		const { value } = e.target;

		switch (type) {
			case 'hours':
				if (Number(value) > 12 || Number(value) < 0) {
					return;
				}

				if (timeType === 'start') {
					setStartTime((prev) => ({ ...prev, hours: value }));
				} else {
					setEndTime((prev) => ({ ...prev, hours: value }));
				}
				break;
			case 'minutes':
				if (Number(value) > 60 || Number(value) < 0) {
					return;
				}

				if (timeType === 'start') {
					setStartTime((prev) => ({ ...prev, minutes: value }));
				} else {
					setEndTime((prev) => ({ ...prev, minutes: value }));
				}
				break;
			case 'period':
				if (timeType === 'start') {
					setStartTime((prev) => ({ ...prev, period: value }));
				} else {
					setEndTime((prev) => ({ ...prev, period: value }));
				}
				break;
			default:
				break;
		}
	};

	return (
		<div className="flex flex-col gap-6">
			{['start', 'end'].map((timeType) => {
				const values = timeType === 'start' ? startTime : endTime;

				return (
					<div key={timeType} className="flex justify-between items-center">
						<div style={{ color: '#7F6D8B' }} className="font-normal">
							{startCase(timeType)} Time
						</div>

						<div className="flex gap-2 items-center">
							<input
								className={styles.input}
								value={values.hours}
								onChange={(e) => handleInputChange(e, 'hours', timeType)}
								placeholder="HH"
								max={12}
								min={0}
								type="number"
							/>

							<span className={styles.separator}>:</span>

							<input
								className={styles.input}
								value={values.minutes}
								max={60}
								min={0}
								onChange={(e) => handleInputChange(e, 'minutes', timeType)}
								placeholder="MM"
								type="number"
							/>

							<div className={styles.period_container}>
								<button
									type="button"
									className={classNames(
										styles.period_button,
										values.period === 'AM' && styles.active,
									)}
									onClick={() =>
										handleInputChange(
											{ target: { value: 'AM' } },
											'period',
											timeType,
										)
									}
								>
									AM
								</button>

								<button
									type="button"
									className={classNames(
										styles.period_button,
										values.period === 'PM' && styles.active,
									)}
									onClick={() =>
										handleInputChange(
											{ target: { value: 'PM' } },
											'period',
											timeType,
										)
									}
								>
									PM
								</button>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default TimeRangePicker;

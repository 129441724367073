import { useSelector, useDispatch } from 'react-redux';
import { ChatMessageWidgetType } from '@helpers/Enums';
import { TRIP_GENERATION_MESSAGE } from '@commons/LocationConfirmation/LocationConfirmationWidget';
import PostReportChatSuggestions from './instances/PostReportChatSuggestions';
import styles from './styles.module.css';

export default function ChatMessageSuggestions() {
	const dispatch = useDispatch();
	const currentChatMessage = useSelector(
		(state) => state.ui_states.currentChatMessage,
	);
	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const { messages } = chatMessages;

	const handleSuggestionClick = (message) => () => {
		dispatch({ type: 'UPDATE_CURRENT_CHAT_MESSAGE', payload: message });
	};

	if (
		messages.length >= 2 &&
		messages[messages.length - 2]?.widget?.widgetType ===
			ChatMessageWidgetType.TRIP_GENERATION_UPDATES_WIDGET &&
		messages[messages.length - 2].message === TRIP_GENERATION_MESSAGE
	) {
		return (
			<div
				style={{ borderTop: '1px solid #CEC8D2' }}
				className={`${styles.container} ${!currentChatMessage ? styles.show : styles.hide}`}
			>
				<PostReportChatSuggestions
					handleSuggestionClick={handleSuggestionClick}
				/>
			</div>
		);
	}

	return null;
}

import { useSelector } from 'react-redux';

const useDayPlansQuery = (contentIds = []) => {
    const tripLocationDetails = useSelector(state => state.tripLocationDetails || {});
    const tripTransitionsDetails = useSelector(state => state.tripTransitions || {});
    const selectedFlight = useSelector(state => state.ui_states?.selectedFlight);
    const oldDayPlansForServer = useSelector(state => state.ui_states?.oldDayPlansForServer || []);
    const llmInteractionHistory = useSelector((state) => state.ui_states.llmInteractionHistory);
    const activeTabIndex = useSelector((state) => state.ui_states.active_tab_index);
    const { travelSets = [] } = tripTransitionsDetails;

	const dayPlansQuery = {
        locations: {...tripLocationDetails},
        travelSets: travelSets[activeTabIndex],
        contentIds,
        flightSelections: (
            selectedFlight?.legs || []
        ).map(
            ({
                arrivalDate,
                arrivalTime,
                destination,
                source,
                departureTime,
                departureDate,
            }) => ({
                fromAirport: source,
                toAirport: destination,
                departureDate,
                departureTime,
                arrivalDate,
                arrivalTime,
            }),
        ),
        'chat_history': llmInteractionHistory,
    };

    if (contentIds.length > 0) {
        dayPlansQuery.oldDayPlans = oldDayPlansForServer;
    }

    return {dayPlansQuery}
};

export default useDayPlansQuery;

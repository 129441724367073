/* eslint-disable no-inner-declarations */
import { useSelector, useDispatch } from 'react-redux';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { BsPlusSquare } from 'react-icons/bs';
import { IoSendOutline, IoMicOutline } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { Drawer, Popover } from '@mui/material';
import {
	ChatMessageSender,
	InspirationDrawerSource,
	InteractionStage,
	ChatMessageWidgetType,
	TripUpdateType,
} from '@helpers/Enums';
import {
	handleLookupPlacesOfInterest,
	getPostReportDayPlansContext,
} from '@helpers/ModelApiUtils';
import {
	maybePopulateMessage,
	maybeFetchDayPlans,
	populateCurrentChatMessageWithAnimation,
	maybeTriggerDemoAttachCardFlow,
} from '@utils/DemoMessageHotkeyUtils';
import { IoIosLink } from 'react-icons/io';
import useDayPlansQuery from '@hooks/dayPlansQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import ItinerarySingleDayCard from '@commons/ItinerarySingleDayCard/ItinerarySingleDayCard';
import AttachmentResponseDrawerOverview from '@commons/AttachmentResponseDrawer/AttachmentResponseDrawerOverview';
import {
	dispatchAddChatMessage,
	sendMessage,
	sendMessagePostReport,
} from '@helpers/ChatUtils';
import {
	GoogleEventTypes,
	sendMessageEvent,
} from '@helpers/GoogleAnalyticsHelper';
import isEmpty from '@utils/isEmpty';
import InfoBannerContent from '@commons/InfoBannerContent';
import LoadingDots from '../LoadingDots';
import styles from './styles.module.css';

const TEXT_INPUT_PLACEHOLDER = 'Ask AiRial ...';
const AIRIAL_DISCLAIMER =
	'AiRial is in beta and can make mistakes. Please check important info.';

export default function ChatBox() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const currentChatMessage = useSelector(
		(state) => state.ui_states.currentChatMessage,
	);
	const chatSessionId = useSelector((state) => state.ui_states.chatSessionId);
	const chatMessages = useSelector((state) => state.ui_states.chatMessages);
	const attachmentCardData = useSelector(
		(state) => state.ui_states.attachmentCardData,
	);
	const currentReduxState = useSelector((state) => state);
	const llmInteractionHistory = useSelector(
		(state) => state.ui_states.llmInteractionHistory,
	);
	const interactionStage = useSelector(
		(state) => state.ui_states.interactionStage,
	);
	const isChatViewCompressed = useSelector(
		(state) => state.ui_states.isChatViewCompressed,
	);

	const tripLocations = useSelector((state) => state.tripLocationDetails);
	const tripTransitions = useSelector((state) => state.tripTransitions);
	const majorUpdateConfirmationWidgets = useSelector(
		(state) => state.ui_states.majorUpdateConfirmationWidgets,
	);
	const showChatBoxAttachmentPopover = useSelector(
		(state) => state.ui_states.showChatBoxAttachmentPopover,
	);
	const showDayPlansPopover = useSelector(
		(state) => state.ui_states.showDayPlansPopover,
	);
	const showUserEducationPopoverForAttachment =
		useSelector(
			(state) =>
				state.profile?.user_preferences?.show_user_education_popover
					?.show_chat_box_attachment_popover,
		) ?? true;

	const { isTyping, messages = [] } = chatMessages;
	const pageId = useSelector((state) => state.ui_states.pageId);
	const location = useLocation();
	const { dayPlansQuery } = useDayPlansQuery();

	const [attachmentAiResponse, setAttachmentAiResponse] = useState([]);

	const {
		type: attachmentType = '',
		data: attachmentData,
		show: attachmentShow,
	} = attachmentCardData || {};

	const [isCurrentChatMessageInvalid, setIsCurrentChatMessageInvalid] =
		useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);

		dispatch({
			type: 'UPDATE_CHAT_BOX_ATTACHMENT_POPOVER',
			payload: false,
		});
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleChatMessageChange = (event) => {
		dispatch({
			type: 'UPDATE_CURRENT_CHAT_MESSAGE',
			payload: event.target.value,
		});
	};

	const dayPlans = useSelector((state) => state.ui_states.day_plans) || [];
	const active_tab_index = useSelector(
		(state) => state.ui_states.active_tab_index,
	);
	const curDayPlanResults = dayPlans[active_tab_index] || [];
	const activeItineraryDayTab =
		useSelector((state) => state.ui_states.activeItineraryDayTab) || 0;
	const postReportDayPlansContext = getPostReportDayPlansContext(
		curDayPlanResults,
		activeItineraryDayTab,
	);

	const handleSubmit = async () => {
		const alphanumericRegex = /[A-Za-z0-9]/;

		const lastChatMessage = messages[messages.length - 1];

		const { widget = {} } = lastChatMessage || {};

		const { widgetType = '' } = widget || {};

		if (
			!alphanumericRegex.test(currentChatMessage) ||
			isTyping ||
			(lastChatMessage.sender === ChatMessageSender.AIRIAL &&
				!isEmpty(widget) &&
				[
					ChatMessageWidgetType.PREFERENCES_WIDGET,
					ChatMessageWidgetType.LOCATION_WIDGET,
				].includes(widgetType))
		) {
			setIsCurrentChatMessageInvalid(true);

			setTimeout(() => {
				setIsCurrentChatMessageInvalid(false);
			}, 500);
			return;
		}

		setIsCurrentChatMessageInvalid(false);
		if (
			[
				InteractionStage.POST_REPORT,
				InteractionStage.POST_REPORT_MAJOR_UPDATE_CONFIRMATION,
			].includes(interactionStage)
		) {
			await sendMessagePostReport(
				dispatch,
				currentChatMessage,
				messages,
				chatSessionId,
				llmInteractionHistory,
				attachmentShow ? attachmentCardData : null,
				tripLocations,
				tripTransitions,
				postReportDayPlansContext,
				active_tab_index,
				location,
				majorUpdateConfirmationWidgets,
				currentReduxState,
			);
		} else {
			await sendMessage(
				dispatch,
				navigate,
				currentChatMessage,
				chatSessionId,
				llmInteractionHistory,
			);
		}
	};

	const demoAttachCardFlow = async () => {
		if (attachmentType) {
			const { coordinates: COORDINATES } = attachmentData;

			const response = await handleLookupPlacesOfInterest({
				data: { COORDINATES },
			});

			setAttachmentAiResponse(response);
			const chatMessage = {
				sender: ChatMessageSender.USER,
				message: currentChatMessage,
				attachment: {
					attachmentType,
					attachmentData: attachmentCardData,
					shouldShow: true,
				},
			};

			dispatch({
				type: 'UPDATE_ATTACHMENT_CARD_DATA',
				payload: {
					...attachmentCardData,
					show: false,
				},
			});

			dispatch({
				type: 'UPDATE_CURRENT_CHAT_MESSAGE',
				payload: '',
			});

			dispatchAddChatMessage(dispatch, TripUpdateType.DEMO_ATTACH_FLOW, {
				messages: [chatMessage],
				isTyping: false,
			});
		}
	};

	const handleKeyDown = async (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault(); // Prevent newline character from being inserted
			handleSubmit();
		}
		const populate = (message) =>
			populateCurrentChatMessageWithAnimation(message, dispatch);

		maybePopulateMessage(event, populate);
		maybeFetchDayPlans(
			event,
			dispatch,
			dayPlansQuery,
			chatSessionId,
			active_tab_index,
		);
		maybeTriggerDemoAttachCardFlow(event, demoAttachCardFlow);
	};

	const handleAddInspirationDrawer = (value) => {
		dispatch({
			type: 'UPDATE_ADD_INSPIRATION_DRAWER',
			payload: {
				show: value,
				source: InspirationDrawerSource.CHAT,
			},
		});
		sendMessageEvent({
			event: GoogleEventTypes.OVERVIEW_PAGE_ADD_INSPIRATION,
			chat_session_id: chatSessionId,
		});
	};

	const handleCloseAttachmentDrawer = () => {
		setAttachmentAiResponse([]);

		dispatch({
			type: 'UPDATE_ATTACHMENT_CARD_DATA',
			payload: { show: false },
		});
	};

	useEffect(() => {
		if (!showUserEducationPopoverForAttachment && !showDayPlansPopover) {
			dispatch({
				type: 'UPDATE_CHAT_BOX_ATTACHMENT_POPOVER',
				payload: false,
			});
		}

		if (
			!showChatBoxAttachmentPopover ||
			!showUserEducationPopoverForAttachment ||
			showDayPlansPopover ||
			!attachmentShow
		) {
			return;
		}

		setTimeout(() => {
			const defaultAnchorEl = document.getElementById('attachment-card');

			setAnchorEl(defaultAnchorEl);
		}, 500);
	}, [attachmentShow]);

	if (pageId === 0) {
		return (
			<div className="flex flex-row items-center mt-3 w-11/12 min-h-12 bg-white rounded-lg text-sm border border-solid py-1 border-landing_page-text_input_border/30">
				<BsPlusSquare className="w-4 h-4 mx-5 text-neutrals-text/70" />
				<TextareaAutosize
					minRows={1}
					maxRows={4}
					value={currentChatMessage}
					onChange={handleChatMessageChange}
					onKeyDown={handleKeyDown}
					placeholder={TEXT_INPUT_PLACEHOLDER}
					className="w-full pr-2 overflow-y-auto focus:outline-none"
				/>
				<IoMicOutline className="h-5.5 w-5.5 mx-1" />
				<IoSendOutline
					className="h-9 w-9 p-2 mx-1 bg-landing_page-send_icon_bg text-white rounded-md cursor-pointer"
					onClick={handleSubmit}
				/>
			</div>
		);
	}

	return (
		<div
			className="flex flex-col text-center items-center gap-4"
			style={
				attachmentShow
					? {
							padding: '20px 16px 16px',
							backgroundColor: '#E7E4E4',
							borderTop: '1px solid #BCB3B3',
						}
					: { padding: '0 16px 8px' }
			}
		>
			{attachmentShow && (
				<div id="attachment-card" className="w-full">
					<ItinerarySingleDayCard
						dayCardData={attachmentData}
						view="chat_box"
						highlightCardForUserEducation={open}
						marginRequired={false}
					/>
				</div>
			)}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				slotProps={{
					paper: {
						style: { marginLeft: 12 },
					},
				}}
			>
				<InfoBannerContent
					mainText="You can edit, remove or add activity based on the context of the selected activity"
					subText={null}
					title="Edit your Itinerary from here."
					handleClose={handleClose}
					popoverName="show_chat_box_attachment_popover"
				/>
			</Popover>

			<div className="relative w-full max-w-full flex flex-col">
				<TextareaAutosize
					value={currentChatMessage}
					onKeyDown={handleKeyDown}
					onChange={handleChatMessageChange}
					minRows={1}
					maxRows={8}
					placeholder={TEXT_INPUT_PLACEHOLDER}
					style={{
						boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
					}}
					className={`border rounded-lg w-full p-2 pr-16 resize-none box-border overflow-y-auto focus:outline-gray-300
					 ${isCurrentChatMessageInvalid ? styles.shake : null}`}
				/>
				<div className="absolute bottom-0.5 right-1 flex flex-row items-center gap-1">
					{isChatViewCompressed ? (
						<IoIosLink
							className="w-5 h-5 cursor-pointer"
							style={{ color: '#7750E5' }}
							onClick={() => handleAddInspirationDrawer(true)}
						/>
					) : null}

					{isTyping ? (
						<LoadingDots />
					) : (
						<IconButton size="small" onClick={handleSubmit}>
							<SendIcon style={{ color: '#8b5cf6' }} />
						</IconButton>
					)}
				</div>
			</div>

			<Drawer
				anchor="right"
				open={!!(attachmentAiResponse || []).length}
				onClose={handleCloseAttachmentDrawer}
				PaperProps={{
					sx: { width: '66%' },
				}}
			>
				<AttachmentResponseDrawerOverview
					attachmentAiResponse={attachmentAiResponse}
					attachmentCardData={attachmentCardData}
					handleCloseAttachmentDrawer={handleCloseAttachmentDrawer}
				/>
			</Drawer>

			<div className="text-xs" style={{ color: '#36174D' }}>
				{AIRIAL_DISCLAIMER}
			</div>
		</div>
	);
}

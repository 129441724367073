function AISparkIconItinerary({ color = '#7750E5' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<g clipPath="url(#clip0_1710_21248)">
				<path
					d="M19 9.985L13.945 12.7375C13.4425 13.015 13.03 13.4275 12.7525 13.93L10.015 19L7.2625 13.945C6.985 13.4425 6.5725 13.03 6.07 12.7525L1 10.015L6.055 7.2625C6.5575 6.985 6.97 6.5725 7.2475 6.07L9.985 1L12.7375 6.055C13.015 6.5575 13.4275 6.97 13.93 7.2475L18.9925 9.985H19Z"
					fill={color}
					stroke={color}
					strokeWidth="1.125"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M18.9995 3.40031L17.6495 4.13531C17.5145 4.21031 17.402 4.32281 17.3345 4.45031L16.607 5.80031L15.872 4.45031C15.797 4.31531 15.6845 4.20281 15.557 4.13531L14.207 3.40781L15.557 2.67281C15.692 2.59781 15.8045 2.48531 15.872 2.35781L16.5995 1.00781L17.3345 2.35781C17.4095 2.49281 17.5145 2.60531 17.6495 2.67281L18.9995 3.40031Z"
					fill={color}
					stroke={color}
					strokeWidth="1.125"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M17.5 15.6232L16.8662 15.9683C16.8028 16.0035 16.75 16.0563 16.7183 16.1162L16.3768 16.75L16.0317 16.1162C15.9965 16.0528 15.9437 16 15.8838 15.9683L15.25 15.6268L15.8838 15.2817C15.9472 15.2465 16 15.1937 16.0317 15.1338L16.3732 14.5L16.7183 15.1338C16.7535 15.1972 16.8028 15.25 16.8662 15.2817L17.5 15.6232Z"
					fill={color}
					stroke={color}
					strokeWidth="0.375"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1710_21248">
					<rect
						width="19.125"
						height="19.125"
						fill="white"
						transform="translate(0.4375 0.4375)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default AISparkIconItinerary;

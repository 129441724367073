import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextInputController from '@commons/forms/page-components/controlled/TextInputController';
import EditIcon from '@commons/icons/EditIcon';
import Alert from '@mui/material/Alert';
import styles from './styles.module.css';
import useHandleSignUpForm from './useHandleSignUpForm';

function SignUpForm() {
	const {
		controls,
		onSubmitForm,
		errors,
		navigate,
		control,
		loading = false,
		handleSubmit,
		currentStage = '',
		setCurrentStage = () => {},
		watch = () => {},
		setErrorMessage = () => {},
		errorMessage = '',
	} = useHandleSignUpForm();

	const handleFormSubmit = async (event) => {
		event.preventDefault();

		if (currentStage === 'email') {
			setCurrentStage('password');
		} else {
			handleSubmit(onSubmitForm)();
		}
	}
	
	const handleKeyDown = async (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();

			if (currentStage === 'email') {
				setCurrentStage('password');
			} else {
				handleSubmit(onSubmitForm)();
			}
		}
	}

	const buttonTitle = currentStage === 'email' ? 'Sign Up' : 'Submit';

	const subHeading =
		currentStage === 'email' ? (
			'Enter your email to sign up'
		) : (
			<div className="flex items-center gap-1 text-sm">
				Create password for{' '}
				<div
					style={{ color: '#7750E5' }}
					role="presentation"
					className="flex gap-1 items-center cursor-pointer"
					onClick={() => {
						setCurrentStage('email');
						setErrorMessage('');
					}}
				>
					{watch('email')}
					<EditIcon width="16" height="16" />
				</div>
			</div>
		);

	return (
		<form className={styles.form}>
			<div className={styles.title}>Create AiRial Account</div>

			<div className={styles.sub_heading}>{subHeading}</div>

			{controls.map((controlItems) => {
				const { icon: ActiveIcon, show, ...restControls } = controlItems;

				if (!show) {
					return null;
				}

				return (
					<div
						className="flex flex-col mb-3"
						style={{ width: '360px' }}
						key={restControls.name}
					>
						<TextInputController
							{...restControls}
							control={control}
							error={errors[restControls.name]}
							onKeyDown={handleKeyDown}
							size="sm"
						/>
					</div>
				);
			})}

			<Button
				variant="contained"
				className={styles.submit_button}
				type="button"
				onClick={handleFormSubmit}
				disabled={loading}
				style={{ marginTop: '-8px' }}
			>
				{loading ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<CircularProgress size={20} color="inherit" />
						<span style={{ marginLeft: '8px' }}>Loading...</span>
					</Box>
				) : (
					<div>{buttonTitle}</div>
				)}
			</Button>

			{errorMessage ? (
				<div
					className="flex flex-col gap-2 items-center mb-2"
					style={{ width: '360px' }}
				>
					<Alert severity="info">{errorMessage}</Alert>
					{errorMessage.includes('waitlist') ? (
						<a
							href="https://www.coinvent.ai/waitlist"
							className="text-sm cursor-pointer underline font-bold"
							style={{ color: '#7750E5' }}
							target="_blank"
							rel="noreferrer"
						>
							Join waitlist
						</a>
					) : null}
				</div>
			) : null}

			<div className={styles.privacy_policy}>
				By Signing Up, you agree to our{' '}
				<div className={styles.bold_text}>Terms of Service</div> and{' '}
				<div className={styles.bold_text} style={{ margin: 'auto' }}>
					Privacy Policy
				</div>
			</div>

			<div
				className="flex gap-3 items-center mt-8"
				style={{ fontSize: '15px' }}
			>
				<div style={{ color: '#442A57', fontWeight: '400' }}>Back to</div>

				<div
					style={{ color: '#7750E5' }}
					className="cursor-pointer font-semibold"
					role="presentation"
					onClick={() => navigate('/login')}
				>
					Log In
				</div>
			</div>
		</form>
	);
}

export default SignUpForm;

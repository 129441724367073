export function formatDate(dateString, format = 'usual') {
	if (format === 'MMM dd') {
		const date = new Date(dateString);
		const options = { month: 'short', day: '2-digit', timeZone: 'UTC' };
		return date.toLocaleDateString('en-US', options);
	}
	const date = new Date(dateString);
	return date.toISOString().split('T')[0];
}

export function incrementOneDay(dateString) {
	if (!dateString) {
		return null;
	}
	const date = new Date(dateString);
	date.setDate(date.getDate() + 1);
	return date.toISOString().split('T')[0];
}

export function decrementOneDay(dateString) {
	if (!dateString) {
		return null;
	}
	const date = new Date(dateString);
	date.setDate(date.getDate() - 1);
	return date.toISOString().split('T')[0];
}

function isMoreThanAYearApart(date1, date2) {
	// Calculate the difference in years
	const yearDifference = date2.getFullYear() - date1.getFullYear();

	if (yearDifference > 1) {
		return true;
	}
	if (!yearDifference) {
		return false;
	}

	// If the difference is within the same year, compare the day of the year
	const dayOfYear1 = Math.floor(
		(date1 - new Date(date1.getFullYear(), 0, 0)) / 86400000,
	); // Number of milliseconds in a day
	const dayOfYear2 = Math.floor(
		(date2 - new Date(date2.getFullYear(), 0, 0)) / 86400000,
	);

	return dayOfYear2 >= dayOfYear1;
}

export function formatDateOptionTitle(inputString) {
	// Regular expression to match "YYYY-MM-DD" date patterns
	const datePattern = /\d{4}-\d{2}-\d{2}/g;

	// Function to convert a date from "YYYY-MM-DD" to "Mon DD [YYYY]"
	const convertDate = (dateStr) => {
		const [year, month, day] = dateStr.split('-');
		const date = new Date(year, month - 1, day);
		const options = { month: 'short', day: 'numeric' };
		const formattedDate = date.toLocaleDateString(undefined, options);

		// Check if the date is more than one year out from today
		const currentDate = new Date();
		if (isMoreThanAYearApart(currentDate, date)) {
			return `${formattedDate} ${year}`;
		}

		return formattedDate;
	};

	const convertDay = (dateStr) => {
		const [year, month, day] = dateStr.split('-');
		const date = new Date(year, month - 1, day);
		const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		return daysOfWeek[date.getDay()];
	};

	// Find all date patterns in the input string
	const dateMatches = inputString.match(datePattern);

	if (!dateMatches) {
		// If no date patterns are found, return the input string as is
		return inputString;
	}

	const inputStringMod = inputString.replace('to', '-');
	const dateFormattedString = inputStringMod.replace(datePattern, (match) =>
		convertDate(match),
	);
	const dayFormattedString = inputStringMod.replace(datePattern, (match) =>
		convertDay(match),
	);

	return {
		dateBasedString: dateFormattedString,
		dayBasedString: dayFormattedString,
	};
}

export function getTitleForFlightset(flightset, version) {
	const dateStrs = flightset.map(
		(inner_element) => inner_element.DATE_OF_JOURNEY,
	);
	// Is this correct? Verify.
	const daysOfWeek = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];
	const dateObjs = dateStrs.map((dateStr) => new Date(dateStr));
	const dayNames = dateObjs.map((date) => daysOfWeek[date.getDay()]);

	if (version !== 'v4' && version !== 'v5') {
		if (dateStrs.length === 1) {
			return `Trip on ${dayNames[0]}, ${dateStrs[0]}`;
		}
		return `Trip from ${dayNames[0]}, ${dateStrs[0]} to ${dayNames[dateStrs.length - 1]}, ${dateStrs[dateStrs.length - 1]}`;
	}
	if (dateStrs.length === 1) {
		return `${dateStrs[0]}`;
	}
	return `${dateStrs[0]} to ${dateStrs[dateStrs.length - 1]}`;
}

export const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export function formatDates(
	checkInDate,
	checkOutDate,
	includeEndMonth = false,
) {
	const checkIn = new Date(checkInDate);
	const checkOut = new Date(checkOutDate);

	const checkInMonth = checkIn.toLocaleString('en-US', {
		month: 'short',
		timeZone: 'UTC',
	});
	const checkOutMonth = checkOut.toLocaleString('en-US', {
		month: 'short',
		timeZone: 'UTC',
	});

	const checkInDay = checkIn.getUTCDate();
	const checkOutDay = checkOut.getUTCDate();

	let formattedDates;
	if (checkInMonth === checkOutMonth && !includeEndMonth) {
		formattedDates = `${checkInMonth} ${checkInDay} - ${checkOutDay}`;
	} else {
		formattedDates = `${checkInMonth} ${checkInDay} - ${checkOutMonth} ${checkOutDay}`;
	}

	return formattedDates;
}

export function convertTo12HrFormat(hour) {
	let period = 'AM';
	let hour12 = hour;

	if (hour === 0) {
		hour12 = 12;
	} else if (hour === 12) {
		period = 'PM';
	} else if (hour > 12) {
		hour12 = hour - 12;
		period = 'PM';
	}

	return {
		hours: hour12,
		period,
	};
}

export function convertTo24HrFormat({ minutes, hours, period }) {
	let Finalhours = Number(hours);

	if (period === 'AM') {
		if (hours === 12) {
			Finalhours = 0; // Midnight case (12 AM)
		}
	} else if (period === 'PM') {
		if (hours !== 12) {
			Finalhours += 12; // Afternoon/evening case (1 PM to 11 PM)
		}
	}

	const paddedHours = String(Finalhours).padStart(2, '0');
	const paddedMinutes = String(minutes).padStart(2, '0');

	return `${paddedHours}:${paddedMinutes}`;
}

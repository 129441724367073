/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-inner-declarations */
import isEmpty from '@utils/isEmpty';
import { HotelSortCriterion, LoadingState, TripUpdateType } from './Enums';
import { GoogleEventTypes, sendMessageEvent } from './GoogleAnalyticsHelper';
import { fetchPhotosBatch, getPlaceNameForHotel } from './PhotosQueryHelper';

export const handleSelectHotels = ({
	response,
	reduxStates = {},
	hotelRequirement,
	dispatch,
}) => {
	const { HOTEL_OFFERSETS = [] } = response || {};

	const currentHotelSet = HOTEL_OFFERSETS[0] || [];

	let num = 0;

	const selectedHotels = hotelRequirement.map((req) => {
		if (!req) {
			return null;
		}

		const hotel = currentHotelSet[num]?.[0] || {};
		num += 1;
		return hotel;
	});

	dispatch({
		type: 'UPDATE_LOCATIONS_REDUCER',
		payload: reduxStates,
	});

	dispatch({
		type: 'UPDATE_LOADING_STATE',
		payload: {
			hotelsLoading: LoadingState.LOADED,
		},
	});

	dispatch({
		type: 'UPDATE_SELECTED_HOTELS',
		payload: selectedHotels,
	});

	dispatch({
		type: 'CHECKPOINT',
		payload: TripUpdateType.OVERVIEW_HOTEL_SELECTED,
	});
};

// TODO: This function is a mess and is causing a lot of tech debt; replace with a cleanly designed one.
export const handleFindHotels = ({
	dispatch,
	location,
	hotel_set_data = {},
	tabIndex = 0,
	gMapsData = {},
	chatSessionId,
	hotelsList = null,
	actualHotelsList = null,
}) => {
	const backendURL = process.env.REACT_APP_BACKEND_URL;
	const {
		hotel_datesets,
		hotelFilters,
		NUM_TRAVELLERS,
		HOTEL_REQUIREMENT: hotelRequirement = [],
		sortCriterion = HotelSortCriterion.BEST,
	} = hotel_set_data;

	const reduxStates = {
		hotelReqs: hotelRequirement,
		hotelFilters,
		hotelSets: hotel_datesets,
		hotelSortCriterion: sortCriterion,
		numTravellers: NUM_TRAVELLERS,
	};

	dispatch({
		type: 'UPDATE_LOADING_STATE',
		payload: {
			hotelsLoading: LoadingState.LOADING,
		},
	});

	const queryParams = new URLSearchParams(location.search);
	queryParams.append(
		'hotel_reqs',
		JSON.stringify(hotelFilters.filter((filter) => filter)),
	);
	queryParams.append('num_travellers', NUM_TRAVELLERS);
	queryParams.append('sort_criterion', sortCriterion);
	queryParams.append(
		'hotel_datesets',
		JSON.stringify(
			hotel_datesets.map((hotelSets) => hotelSets.filter((dateSet) => dateSet)),
		),
	);

	if (!isEmpty(hotelsList)) {
		queryParams.append('hotel_lists', JSON.stringify(hotelsList));
	}

	const apiToHit = isEmpty(hotelsList)
		? 'search_hotels'
		: 'search_hotel_offersets';

	fetch(`${backendURL}/api/${apiToHit}?${queryParams.toString()}`)
		.then((response) => {
			const reader = response.body.getReader();

			return new ReadableStream({
				start(controller) {
					function pump() {
						reader
							.read()
							.then(({ done, value }) => {
								if (!done) {
									controller.enqueue(value);
									pump();
								} else {
									controller.close();
								}
							})
							.catch((error) => {
								controller.error(error);
							});
					}

					pump();
				},
			});
		})
		.then((stream) => {
			return new Response(stream);
		})
		.then((response) => {
			return response.json();
		})
		.then(async (response) => {
			dispatch({
				type: 'UPDATE_HOTELS_FOR_DATE_TAB',
				payload: {
					hotelResults: isEmpty(actualHotelsList)
						? response
						: { ...response, HOTEL_LISTS: actualHotelsList },
					tabIndex,
				},
			});
			handleSelectHotels({
				response,
				reduxStates,
				hotelRequirement,
				dispatch,
			});

			const { HOTEL_OFFERSETS = [] } = response || {};
			const currentHotelSet = HOTEL_OFFERSETS[0] || [];
			const firstSixhotels = (currentHotelSet?.[0] || []).slice(0, 6);
			const placesToFetch = [];

			firstSixhotels?.forEach((hotelData) => {
				if (hotelData && hotelData.name) {
					const placeName = getPlaceNameForHotel(hotelData, hotelFilters, 0);
					if (!gMapsData[placeName]) {
						placesToFetch.push(placeName);
					}
				}
			});
			fetchPhotosBatch(placesToFetch, dispatch, () => {
				sendMessageEvent({
					event: GoogleEventTypes.HOTELS_LOADED,
					chat_session_id: chatSessionId,
				});
			});
		})
		.catch((error) => {
			dispatch({
				type: 'UPDATE_LOADING_STATE',
				payload: {
					hotelsLoading: LoadingState.ERROR,
				},
			});
			dispatch({
				type: 'UPDATE_LOCATIONS_REDUCER',
				payload: reduxStates,
			});
			// console.log(error);
		});
};

export const getTotalPriceObject = (filteredSelectedHotels) =>
	filteredSelectedHotels.reduce(
		(acc, cur) => {
			const { offers } = cur || {};

			if (!offers) {
				return acc;
			}

			const firstOffer = offers[0];

			const {
				price: { total, currency = '' },
			} = firstOffer;

			const finalPrice = Number(total?.split('.')?.[0]);

			return {
				price: Number(acc.price + finalPrice),
				curr: currency,
			};
		},
		{
			price: 0,
			curr: '',
		},
	);

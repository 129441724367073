import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import * as Yup from 'yup';

const schema = Yup.object().shape({
	email: Yup.string().required('Email is required'),
	password: Yup.string().required('Password is required'),
});

axios.defaults.withCredentials = true;

const useHandleSignUpForm = ({ setActiveTab = () => {} }) => {
	const {
		control,
		formState: { errors },
		watch,
		handleSubmit = () => {},
	} = useForm({
		resolver: yupResolver(schema),
	});

	const [loading, setLoading] = useState(false);
	const [currentStage, setCurrentStage] = useState('email');
	const [errorMessage, setErrorMessage] = useState('');

	const INPUT_MAPPING = [
		{
			type: 'text',
			id: 'name',
			name: 'name',
			label: 'Name',
			placeholder: 'Enter name',
			show: currentStage === 'email',
		},
		{
			type: 'email',
			id: 'email',
			name: 'email',
			label: 'Email',
			placeholder: 'email@domain.com',
			show: currentStage === 'email',
			autoComplete: 'username',
		},
		{
			type: 'password',
			id: 'password',
			name: 'password',
			label: 'Password',
			placeholder: 'Enter password',
			show: currentStage === 'password',
		},
	];

	const onSubmitForm = async (values) => {
		setLoading(true);

		try {
			await axios.post(`/api/register_user`, values);

			setLoading(false);

			setActiveTab('login');
		} catch (error) {
			setLoading(false);

			if (error?.response?.data?.error) {
				setErrorMessage(error?.response?.data?.error);
			}
		}
	};

	return {
		controls: INPUT_MAPPING,
		onSubmitForm,
		errors,
		control,
		loading,
		handleSubmit,
		currentStage,
		setCurrentStage,
		watch,
		errorMessage,
		setErrorMessage,
	};
};

export default useHandleSignUpForm;

import axios from 'axios';

function levenshteinDistance(a, b) {
	const matrix = [];
	let i;
	let j;

	if (a.length === 0) return b.length;
	if (b.length === 0) return a.length;

	// Increment along the first column of each row
	for (i = 0; i <= b.length; i++) {
		matrix[i] = [i];
	}

	// Increment each column in the first row
	for (j = 0; j <= a.length; j++) {
		matrix[0][j] = j;
	}

	// Fill in the rest of the matrix
	for (i = 1; i <= b.length; i++) {
		for (j = 1; j <= a.length; j++) {
			if (b.charAt(i - 1) === a.charAt(j - 1)) {
				matrix[i][j] = matrix[i - 1][j - 1];
			} else {
				matrix[i][j] = Math.min(
					matrix[i - 1][j - 1] + 1, // substitution
					Math.min(
						matrix[i][j - 1] + 1, // insertion
						matrix[i - 1][j] + 1,
					),
				); // deletion
			}
		}
	}

	return matrix[b.length][a.length];
}

function findSimilarActivityName(allActivitiesMap, name, threshold = 5) {
	for (const [key, value] of allActivitiesMap.entries()) {
		if (levenshteinDistance(key, name) <= threshold) {
			return key;
		}
	}
	return null;
}

function updateDayPlans(dayPlans, dayPlanChanges, dispatch) {
	const allActivitiesMap = new Map();
	dayPlans.forEach((day) => {
		day.SCHEDULE.forEach((activity) => {
			allActivitiesMap.set(activity.NAME, { ...activity });
		});
	});

	const dayPlanMap = new Map(dayPlans.map((day) => [day.DATE, day]));
	const promiseQueue = [];
	dayPlanChanges.forEach((change) => {
		const newSchedule = change.SCHEDULE.map((activity) => {
			const [TIME_START, TIME_END] = activity.TIME.split('-');
			const updatedActivity = {
				NAME: activity.NAME,
				TIME_START,
				TIME_END,
				COORDINATES: '',
				TYPE: '',
				DESCRIPTION: '',
			};

			const similarName = findSimilarActivityName(
				allActivitiesMap,
				activity.NAME,
			);

			if (similarName) {
				const existingDetails = allActivitiesMap.get(similarName);
				Object.assign(updatedActivity, existingDetails, {
					TIME_START,
					TIME_END,
				});
			} else {
				updatedActivity.hasNewTag = true;
				const backendURL = process.env.REACT_APP_BACKEND_URL;
				const dayLocation = dayPlans.find(
					(day) => day.DATE === change.DATE,
				)?.location;
				let nameLocation = activity.NAME;
				if (dayLocation) {
					nameLocation += `, Location: ${dayLocation}`;
				}
				const requestPromise = axios
					.get(`${backendURL}/api/get_day_card_details`, {
						params: { name_location: nameLocation },
					})
					.then((response) => {
						// Use the response to populate the activity details
						Object.assign(updatedActivity, response.data, {
							NAME: activity.NAME,
							TIME_START,
							TIME_END,
							hasNewTag: true,
						});
						return updatedActivity;
					})
					.catch((error) => {
						console.error('Error:', error);
						return updatedActivity; // Return the activity with default fields if the request fails
					});
				promiseQueue.push(requestPromise);
				requestPromise.then((activityWithDetails) => {
					const date = change.DATE;
					dispatch({
						type: 'UPDATE_SINGLE_ACTIVITY',
						payload: { date, activity: activityWithDetails },
					});
				});
			}

			return updatedActivity;
		});

		if (dayPlanMap.has(change.DATE)) {
			dayPlanMap.get(change.DATE).SCHEDULE = newSchedule;
		} else {
			dayPlanMap.set(change.DATE, { DATE: change.DATE, SCHEDULE: newSchedule });
		}
	});

	return Array.from(dayPlanMap.values());
}

export function processDayPlansChanges(
	activeTabIndex,
	dayPlans,
	dayPlanChanges,
	dispatch,
) {
	const updatedDayPlans = updateDayPlans(
		[...dayPlans],
		dayPlanChanges,
		dispatch,
	);

	dispatch({
		type: 'UPDATE_DAY_PLANS_FOR_DATE_TAB',
		payload: {
			dayPlans: updatedDayPlans,
			tabIndex: activeTabIndex,
		},
	});
}

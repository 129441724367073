function StyledFlightIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 15 15"
			fill="none"
		>
			<path
				d="M11.5472 2.02342C11.5487 2.025 11.5488 2.02755 11.5473 2.02919L9.309 4.457C9.1744 4.60299 8.97279 4.6672 8.77855 4.62594L2.6912 3.33283C2.50491 3.29325 2.31124 3.35063 2.17657 3.4853L1.79637 3.8655C1.53507 4.12681 1.59242 4.5648 1.9122 4.75L5.96754 7.09874C6.27815 7.27863 6.34309 7.69949 6.10115 7.96464L3.73807 10.5544C3.60474 10.7006 3.40446 10.7659 3.21061 10.7265L1.99107 10.4789C1.80618 10.4413 1.61478 10.499 1.48138 10.6324L1.26523 10.8485C1.00466 11.1091 1.06084 11.5456 1.37889 11.7318L2.67045 12.4875C2.75375 12.5363 2.8231 12.6056 2.87185 12.6889L3.62762 13.9805C3.81373 14.2985 4.25029 14.3547 4.51086 14.0941L4.727 13.878C4.8604 13.7446 4.91805 13.5532 4.8805 13.3683L4.63284 12.1488C4.59347 11.9549 4.65882 11.7546 4.80494 11.6213L7.39474 9.25823C7.65989 9.01629 8.08074 9.08122 8.26063 9.39183L10.6094 13.4472C10.7946 13.7669 11.2326 13.8243 11.4939 13.563L11.8741 13.1828C12.0087 13.0481 12.0661 12.8545 12.0265 12.6682L10.7334 6.58082C10.6922 6.38658 10.7564 6.18498 10.9024 6.05038L13.3331 3.80938C13.8225 3.365 14.1037 2.72937 14.1037 2.06563C14.1037 1.61563 13.7381 1.25 13.2881 1.25C12.6257 1.25 11.9913 1.52453 11.547 2.01768C11.5455 2.01933 11.5456 2.02185 11.5472 2.02342V2.02342Z"
				stroke="url(#paint0_linear_279_67797)"
				strokeWidth="0.84375"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_279_67797"
					x1="7.42687"
					y1="1.25"
					x2="7.42687"
					y2="14.6094"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DC1F90" />
					<stop offset="1" stopColor="#7750E5" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default StyledFlightIcon;

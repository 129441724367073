import ImageLoader from '@commons/Itinerary/ImageLoader';
import CalenderIcon from '@commons/icons/CalenderIcon';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { formatDates } from '@helpers/DateUtils';
import { Button, Box } from '@mui/material';
import isEmpty from '@utils/isEmpty';
import useGetHotelBookingLink from '@hooks/useGetHotelBookingLink';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.css';

function HotelsSection() {
	const dispatch = useDispatch();
	const gMapsData = useSelector((state) => state.ui_states.gMapsData);
	const selectedHotels = useSelector((state) => state.ui_states.selectedHotels);
	const hotelFilters = useSelector(
		(state) => state.tripLocationDetails.hotelFilters,
	);
	const hotelSets = useSelector((state) => state.tripLocationDetails.hotelSets);
	const numTravellers = useSelector(
		(state) => state.tripLocationDetails.numTravellers,
	);

	const filteredSelectedHotels = selectedHotels.filter(
		(hotelData) => !!Object.keys(hotelData || {}).length,
	);

	const processedPlaces = new Set();

	useEffect(() => {
		const promises = [];

		selectedHotels.forEach((hotelData, index) => {
			if (isEmpty(hotelData)) {
				return;
			}

			const { hotel = {} } = hotelData;
			const { name = null } = hotel;

			if (name && !gMapsData[name] && !processedPlaces.has(name)) {
				processedPlaces.add(name);

				const hotelLocation =
					hotelFilters[index]?.COORDINATES_LABEL.split(',')[0];

				const promise = axios
					.get(`${process.env.REACT_APP_BACKEND_URL}/api/get_photos`, {
						params: { query: `${name}, ${hotelLocation}` },
					})
					.then((response) => {
						dispatchUpdate(name, response.data);
					})
					.catch((error) => {
						console.error('Error fetching place data:', error);
						dispatchUpdate(name, {});
					});

				promises.push(promise);
			}
		});

		if (promises.length > 0) {
			Promise.all(promises).finally(() => {
				console.log('All hotel fetch operations are complete.');
			});
		}

		function dispatchUpdate(placeName, data) {
			dispatch({
				type: 'UPDATE_GMAPS_DATA',
				payload: {
					placeName,
					data,
				},
			});
		}
	}, [filteredSelectedHotels]);

	const { handleBookHotel, loading } = useGetHotelBookingLink({});

	const handleHotelBooking = (index, name) => {
		const { CHECK_IN_DATE = '', CHECK_OUT_DATE = '' } = (hotelSets?.[0] || [])[
			index
		];

		const hotelDateSetDetails = {
			CHECK_OUT_DATE,
			CHECK_IN_DATE,
			NUM_TRAVELLERS: numTravellers,
		};

		handleBookHotel({
			name,
			hotelDateSetDetails,
			hotelFilterDetails: hotelFilters[index],
			numTravellers,
		});
	};

	return (
		<div className="flex flex-col gap-4 mt-8" style={{ color: '#301345' }}>
			<div className="font-medium text-xl flex items-center justify-between">
				Hotels
			</div>

			<div className="flex gap-3 overflow-auto">
				{selectedHotels.map((hotelData, index) => {
					if (!Object.keys(hotelData || {}).length) {
						return null;
					}

					const { hotel = {}, offers = [] } = hotelData;
					const { name = '', hotelId = '' } = hotel;

					const photoUrl =
						gMapsData[name]?.[0]?.[0]?.photos_data[0]?.original_photo_url;

					const firstOffer = offers[0];

					const {
						price: { total },
					} = firstOffer;

					return (
						<div
							key={`${hotelId}_${index}`}
							className={styles.card}
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '16px',
							}}
						>
							{photoUrl ? (
								<ImageLoader
									gMapsData={gMapsData}
									placeName={name}
									width="308px"
									height="120px"
									usePhotoId
									onError={() => {}}
								/>
							) : null}

							<div className="flex flex-col gap-3">
								<div className="flex justify-between items-center">
									<div
										style={{
											color: '#7750E5',
											fontWeight: '600',
											maxWidth: '212px',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{name || gMapsData?.name}
									</div>

									<div
										className="flex gap-1 items-center"
										style={{
											color: '#DC1F90',
											fontSize: '12px',
										}}
									>
										<LocationOnOutlinedIcon style={{ fontSize: '16' }} />
										{hotelFilters[index]?.COORDINATES_LABEL.split(',')[0]}
									</div>
								</div>

								{/* <div className="text-xs">
									Quests love the pool, sauna and beach access: Perfect relax
									place
								</div> */}
								<div
									className="ml-0.5 flex gap-1.5 items-center"
									style={{
										color: '#574068',
										fontSize: '12px',
									}}
								>
									<CalenderIcon />
									{formatDates(
										(hotelSets?.[0] || [])[index].CHECK_IN_DATE,
										(hotelSets?.[0] || [])[index].CHECK_OUT_DATE,
									)}
								</div>

								<div className="flex gap-4 items-center justify-between mt-2">
									<div className="flex gap-1 items-center font-semibold">
										<div>${parseInt(total)}</div>
									</div>

									<Button
										variant="outlined"
										type="button"
										className={styles.book_button}
										onClick={() => handleHotelBooking(index, name)}
									>
										{loading ? (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													margin: 'auto',
												}}
											>
												<CircularProgress size={20} color="inherit" />
												<span style={{ marginLeft: '8px' }}>Loading...</span>
											</Box>
										) : (
											<div className="m-auto">Book Now</div>
										)}
									</Button>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default HotelsSection;

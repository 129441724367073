function LeftArrow() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<rect
				width="24"
				height="24"
				rx="12"
				transform="matrix(-1 0 0 1 24 0)"
				fill="#F0ECFC"
			/>
			<path
				d="M15 18L9 12L15 6"
				stroke="#36174D"
				strokeWidth="0.9"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default LeftArrow;

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const NON_FLIGHT_TRANSPORTATION_OPTIONS = ['CAR', 'TRAIN', 'BOAT', 'BUS'];

const DEFAULT_TIME_DIFFERENCE = 4;

const COMPONENT_MAPPING = {
	true: DesktopDatePicker,
	false: DateTimePicker,
};

function NonFlightTransit({
	nonFlightSchedule,
	setNonFlightSchedule,
	isChooseTimeChecked,
	setIsChooseTimeChecked,
	minDate,
	maxDate,
}) {
	const handleChange = (event) => {
		setIsChooseTimeChecked(event.target.checked);
	};

	const ActiveDateComponent = COMPONENT_MAPPING[isChooseTimeChecked];

	const { dateOfJourney, dateOfArrival } = nonFlightSchedule;

	return (
		<div
			className="rounded-lg flex flex-col p-4 gap-2 mt-4"
			style={{
				border: '1px solid #7750E5',
			}}
		>
			<div className="flex flex-col gap-2 my-4 font-semibold text-sm">
				<div>Select Mode of Transport</div>

				<FormControl sx={{ minWidth: 120 }} size="small">
					<Select
						labelId="demo-select-small-label"
						id="demo-select-small"
						value={nonFlightSchedule.modeOfTransport}
						inputProps={{ 'aria-label': 'Without label' }}
						onChange={(event) =>
							setNonFlightSchedule((prev) => ({
								...prev,
								modeOfTransport: event.target.value,
							}))
						}
					>
						{NON_FLIGHT_TRANSPORTATION_OPTIONS.map((item) => (
							<MenuItem key={item} value={item}>
								{item}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<FormControlLabel
				control={
					<Checkbox checked={isChooseTimeChecked} onChange={handleChange} />
				}
				label="Choose time for me"
			/>

			<div className="flex flex-col gap-2 my-4 font-semibold text-sm">
				<div>Departure</div>

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<ActiveDateComponent
						value={dateOfJourney ? dayjs(dateOfJourney) : undefined}
						onChange={(newValue) => {
							const dateObject = new Date(newValue);

							setNonFlightSchedule((prev) => ({
								...prev,
								dateOfJourney: dateObject,
								dateOfArrival:
									dateObject > prev?.dateOfArrival
										? new Date(
												dateObject.getTime() +
													DEFAULT_TIME_DIFFERENCE * 60 * 60 * 1000,
											)
										: prev?.dateOfArrival,
							}));
						}}
						minDate={dayjs(new Date(minDate))}
						maxDate={maxDate ? dayjs(new Date(maxDate)) : undefined}
						sx={{
							width: '100%',
							'& .MuiInputBase-input': {
								padding: '8px 0px 8px 8px',
								height: '24px',
							},
							'& .MuiInputAdornment-root': { margin: '0px' },
						}}
					/>
				</LocalizationProvider>
			</div>

			<div className="flex flex-col gap-2 my-4 font-semibold text-sm">
				<div>Arrival</div>

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<ActiveDateComponent
						value={dateOfArrival ? dayjs(dateOfArrival) : undefined}
						onChange={(newValue) => {
							const dateObject = new Date(newValue);

							setNonFlightSchedule((prev) => ({
								...prev,
								dateOfArrival: dateObject,
								dateOfJourney:
									dateObject < prev?.dateOfJourney
										? new Date(
												dateObject.getTime() -
													DEFAULT_TIME_DIFFERENCE * 60 * 60 * 1000,
											)
										: prev?.dateOfJourney,
							}));
						}}
						minDate={dayjs(new Date(dateOfJourney || minDate))}
						maxDate={maxDate ? dayjs(new Date(maxDate)) : undefined}
						sx={{
							width: '100%',
							'& .MuiInputBase-input': {
								padding: '8px 0px 8px 8px',
								height: '24px',
							},
							'& .MuiInputAdornment-root': { margin: '0px' },
						}}
					/>
				</LocalizationProvider>
			</div>
		</div>
	);
}

export default NonFlightTransit;

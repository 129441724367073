import React from 'react';
import '../../css/LoadingDots.css';

const LoadingDots = () => {
    return (
        <div className="loading pb-2">
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export default LoadingDots;
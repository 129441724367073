function InstagramIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<rect width="24" height="24" fill="white" />
			<path
				d="M7 2H17C19.76 2 22 4.24 22 7V17C22 19.76 19.76 22 17 22H7C4.24 22 2 19.76 2 17V7C2 4.24 4.24 2 7 2Z"
				stroke="url(#insta-gradient)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 11.37A4 4 0 1 1 12 7.37 4 4 0 0 1 16 11.37Z"
				stroke="url(#insta-gradient)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.5 6.5H17.51"
				stroke="url(#insta-gradient)"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="insta-gradient"
					x1="12"
					y1="2"
					x2="12"
					y2="22"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DC1F90" />
					<stop offset="1" stopColor="#7750E5" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export default InstagramIcon;

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import startCase from '@utils/startCase';
import { useState } from 'react';
import isEmpty from '@utils/isEmpty';
import { useSelector } from 'react-redux';
import Amenities from './Amenities';

import './FiltersDropdown.css';
import PriceFilter from './PriceFilter';
import RatingFilter from './RatingFilter';

const MAPPING = {
	amenities: Amenities,
	price: PriceFilter,
	rating: RatingFilter,
};

export default function FiltersDropdown({ filterName }) {
	const { hotelFilters = [] } = useSelector(
		(state) => state.tripLocationDetails,
	);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const ActiveFilter = MAPPING[filterName];

	const maxPrice = (hotelFilters.filter((hotelFilter) => hotelFilter)[0] || {})
		.MAX_PRICE;

	const minStarRating = (
		hotelFilters.filter((hotelFilter) => hotelFilter)[0] || {}
	).MIN_STAR_RATING;

	const activeFilterMapping = {
		amenities: !isEmpty(
			(hotelFilters.filter((hotelFilter) => hotelFilter)[0] || {}).AMENITIES,
		),
		price: maxPrice && maxPrice !== -1,
		rating: minStarRating && minStarRating !== -1,
	};

	return (
		<div>
			<Button
				id={`${filterName}_filter_dropdown_button`}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				endIcon={<ExpandMoreIcon />}
				variant="outlined"
				style={{
					color: '#301345',
					border: activeFilterMapping[filterName]
						? '1px solid rgb(109 40 217)'
						: '1px solid #CEC8D2',
					textTransform: 'unset',
					fontSize: '12px',
					backgroundColor: activeFilterMapping[filterName] ? '#ddd6fe' : '#fff',
					borderRadius: '32px',
				}}
			>
				{startCase(filterName)}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					'aria-labelledby': 'filters-dropdown-button',
				}}
				sx={{ marginTop: '5px' }}
			>
				<MenuItem key="Adults">
					<ActiveFilter handleClose={handleCloseMenu} />
				</MenuItem>
			</Menu>
		</div>
	);
}

import ChatBox from '@commons/ChatBoxV1/ChatBox';
import ChatFeed from './ChatFeed';

export default function ChatPanel() {
	return (
		<div className="flex flex-col h-full text-neutrals-text text-sm relative">
			<ChatFeed />
			<ChatBox />
		</div>
	);
}

/* eslint-disable prefer-promise-reject-errors */
import { json } from 'react-router-dom';
import { parse } from 'partial-json';
import { add } from 'date-fns';
import { LoadingState, TripUpdateType } from './Enums';
import { GoogleEventTypes, sendMessageEvent } from './GoogleAnalyticsHelper';

export const fetchDayPlansAndDispatch = async (
	dispatch,
	dayPlansQuery,
	chatSessionId,
	tabIndex = 0,
) => {
	dispatch({ type: 'UPDATE_ACTIVE_ITINERARY_DAY_TAB', payload: 0 });

	const handleResponse = (response) => {
		dispatch({
			type: 'UPDATE_DAY_PLANS_FOR_DATE_TAB',
			payload: {
				dayPlans: response.DAYS,
				tabIndex,
			},
		});

		dispatch({
			type: 'UPDATE_LOADING_STATE',
			payload: {
				itineraryLoading: LoadingState.LOADED,
			},
		});
	};
	const onFinish = (serverFormatDayPlans) => {
		if (serverFormatDayPlans) {
			dispatch({
				type: 'UPDATE_OLD_DAY_PLANS_FOR_SERVER',
				payload: serverFormatDayPlans || [],
			});
		} else {
			dispatch({
				type: 'UPDATE_LOADING_STATE',
				payload: {
					itineraryLoading: LoadingState.ERROR,
				},
			});
		}

		sendMessageEvent({
			event: GoogleEventTypes.DAY_PLANS_LOADED,
			chat_session_id: chatSessionId,
		});

		dispatch({
			type: 'CHECKPOINT',
			payload: TripUpdateType.DAY_PLANS_LOADED,
		});
	};

	dispatch({
		type: 'UPDATE_LOADING_STATE',
		payload: {
			itineraryLoading: LoadingState.LOADING,
		},
	});
	fetchDayPlans(
		dispatch,
		dayPlansQuery,
		chatSessionId,
		handleResponse,
		onFinish,
	);
};

export const fetchDayPlans = async (
	dispatch,
	dayPlansQuery,
	chatSessionId,
	handleResponse,
	onFinish = (a) => {},
) => {
	const backendURL = process.env.REACT_APP_BACKEND_URL;
	const data = {
		day_plans_query: dayPlansQuery,
		chat_session_id: chatSessionId,
	};
	streamDayPlansChunks(backendURL, data, dispatch, handleResponse, onFinish);
};

const streamDayPlansChunks = async (
	backendURL,
	data,
	dispatch,
	handleResponse,
	onFinish = (a) => {},
) => {
	data.stream_chunks = true;
	fetch(`${backendURL}/api/get_day_plans_stream`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then((response) => {
			if (response.ok) {
				const reader = response.body.getReader();
				let buffer = '';

				function readChunk() {
					reader.read().then(({ done, value }) => {
						if (done) {
							if (buffer.trim()) {
								const serverFormatDayPlans = parse(buffer);
								onFinish(serverFormatDayPlans);
								return;
							}
							onFinish(null);
							return;
						}

						const chunkText = new TextDecoder().decode(value, { stream: true });
						buffer += chunkText;
						const jsonObj = parse(buffer);
						// jsonObj is a list of day Plan objects for each location that need to be merged
						const merged = mergeAll(jsonObj);

						if (jsonObj !== null) {
							handleResponse(merged, dispatch);
						}

						readChunk();
					});
				}

				readChunk();
			} else {
				console.error('Network response was not ok.');
			}
		})
		.catch((error) => {
			console.error('Fetch error:', error);
		});
};

function removeWrap(jsonObj) {
	if (jsonObj.WRAP) {
		const location = jsonObj.LOCATION || '';
		return {
			...jsonObj.WRAP,
			location,
		};
	}
	return jsonObj;
}

function addLocation(jsonObj) {
	if (jsonObj.DAYS) {
		jsonObj.DAYS = jsonObj.DAYS.map((day) => {
			day.location = jsonObj.location || '';
			day.location = day.location.split(',')[0];
			return day;
		});
	}
	return jsonObj;
}

function mergeAll(jsonObj) {
	jsonObj = jsonObj.map(removeWrap);
	jsonObj = jsonObj.map(addLocation);

	if (jsonObj.length === 0) {
		return {};
	}
	if (jsonObj.length === 1) {
		return jsonObj[0];
	}
	let merged = jsonObj[0];
	for (let i = 1; i < jsonObj.length; i++) {
		merged = merge(merged, jsonObj[i]);
	}
	return merged;
}

function applyGuardRails(dayPlans) {
	removeInvalidActivities(dayPlans);
	removeExtraTransfers(dayPlans);
	return dayPlans;
}

function removeExtraTransfers(dayPlans) {
	if (dayPlans.DAYS) {
		dayPlans.DAYS.forEach((day) => {
			if (day.SCHEDULE) {
				for (let i = day.SCHEDULE.length - 1; i >= 0; i--) {
					const current = day.SCHEDULE[i];
					const next = day.SCHEDULE[i + 1];
					if (
						next &&
						next.TYPE &&
						next.TYPE === 'inter_city_transfer' &&
						current.TYPE &&
						current.TYPE === 'transfer' &&
						next.TRANSPORT &&
						next.TRANSPORT === 'CAR'
					) {
						day.SCHEDULE.splice(i, 1);
					}
				}
			}
		});
	}
	return dayPlans;
}

function removeInvalidActivities(dayPlans) {
	if (dayPlans.DAYS) {
		dayPlans.DAYS.forEach((dayPlan) => {
			const schedule = dayPlan.SCHEDULE;

			// Find the first transfer in the day's schedule
			if (schedule) {
				const transferIndex = schedule.findIndex(
					(activity) => activity.TYPE === 'inter_city_transfer',
				);

				if (transferIndex !== -1) {
					const transferActivity = schedule[transferIndex];
					const transferStartTime = new Date(
						`${dayPlan.DATE}T${transferActivity.TIME_START}`,
					);

					dayPlan.SCHEDULE = schedule.filter((activity, index) => {
						if (index >= transferIndex) return true; // Keep activities after the transfer
						const activityEndTime = new Date(
							`${dayPlan.DATE}T${activity.TIME_END}`,
						);
						return activityEndTime <= transferStartTime;
					});
				}
			}
		});
	}
	return dayPlans;
}

function merge(currentDayPlans, addedDayPlans) {
	if (currentDayPlans.DAYS && addedDayPlans.DAYS) {
		currentDayPlans.DAYS = currentDayPlans.DAYS.concat(addedDayPlans.DAYS);
		for (let day = 0; day < currentDayPlans.DAYS.length - 1; day++) {
			const currentDay = currentDayPlans.DAYS[day];
			const nextDay = currentDayPlans.DAYS[day + 1];
			if (currentDay.DATE == nextDay.DATE) {
				currentDay.SCHEDULE = currentDay.SCHEDULE.concat(nextDay.SCHEDULE);
				if (
					currentDay.location &&
					currentDay.location !== nextDay.location &&
					currentDay.location !== 'inter_city_transfer' &&
					nextDay.location !== 'inter_city_transfer'
				) {
					currentDay.location = `${currentDay.location}, ${nextDay.location}`;
				}

				if (currentDay.location === 'inter_city_transfer') {
					currentDay.location = nextDay.location;
				}
				currentDayPlans.DAYS.splice(day + 1, 1);
			}
		}
	}
	return applyGuardRails(currentDayPlans);
}

const streamDayPlansCompleteResponsesInEachChunk = async (
	backendURL,
	queryParams,
	dispatch,
) => {
	queryParams.append('stream_chunks', false);
	fetch(`${backendURL}/api/get_day_plans_stream?${queryParams.toString()}`)
		.then((response) => {
			if (response.ok) {
				const reader = response.body.getReader();
				let buffer = ''; // Initialize a buffer to accumulate chunks

				function readChunk() {
					reader.read().then(({ done, value }) => {
						if (done) {
							// Handle any remaining JSON text after the last chunk
							if (buffer.trim()) {
								try {
									const jsonObj = JSON.parse(buffer);
									console.log('Received final JSON object:', jsonObj);
									handleResponse(jsonObj, dispatch);
								} catch (e) {
									console.error('Error parsing JSON in final buffer:', e);
								}
							}
							return;
						}

						const chunkText = new TextDecoder().decode(value, { stream: true });
						buffer += chunkText;

						// Process complete JSON objects in the buffer
						let boundary = buffer.indexOf('}{');

						while (boundary !== -1) {
							const jsonPiece = buffer.substring(0, boundary + 1);
							buffer = buffer.substring(boundary + 1);
							try {
								const jsonObj = JSON.parse(jsonPiece);
								handleResponse(jsonObj, dispatch);
							} catch (e) {
								console.error('Error parsing JSON:', e);
								// In case of parsing error, prepend the broken piece back to the buffer
								buffer = jsonPiece + buffer;
								break;
							}
							boundary = buffer.indexOf('}{');
						}

						readChunk();
					});
				}

				readChunk();
			} else {
				console.error('Network response was not ok.');
			}
		})
		.catch((error) => {
			console.error('Fetch error:', error);
		});
};
